import React, { useState } from 'react';
import { useAppSelector } from '../../hooks';
import { Collection, CollectionsHeader, Dispatch } from 'src/components-bl';
import { CollectionPage } from './CollectionPage.config';
import { CollectionsMain } from '../../containers/Collections/components/CollectionsMain/CollectionsMain';
import { EditCollectionRulesVariantsContainer } from './EditCollectionRules/CollectionRules/CollectionRules/EditCollectionRulesVariantsContainer';
import { useGetDefaultCollection } from '../../containers/CreateCollection/useGetDefaultCollection';
import { editCollectionActionMethods } from '../../containers/EditCollection/actions/editCollectionActions';
import {
  EditMerchandisingRulesContainer,
  EditRankingStrategyContainer,
} from '../../containers/EditCollection/components';
import { EditCollectionRulesVariantsPage } from './EditCollectionRulesVariantsPage';

interface CollectionsManagementProps {
  shopId: number;
  dispatch: Dispatch;
  currentPage: CollectionPage;
  selectedVariantId?: string;
  formHeaderElementRef?: React.RefObject<HTMLDivElement | null>;
}

export const CollectionsManagement = ({
  shopId,
  dispatch,
  currentPage,
  selectedVariantId,
  formHeaderElementRef,
}: CollectionsManagementProps): JSX.Element | null => {
  const collection =
    useAppSelector(state => state.collections.currentCollection) || ({} as Collection);
  const { defaultCollection } = useGetDefaultCollection({ shopId, variantId: selectedVariantId });

  const [page, setPage] = useState(currentPage);

  const onPageChange = (collectionPage: CollectionPage) => {
    setPage(collectionPage);
  };

  const onCreateDefaultCollection = () => {
    onPageChange(CollectionPage.Create);
  };

  const onEditCollection = (collectionId: string) => {
    onPageChange(CollectionPage.Edit);
    dispatch(
      editCollectionActionMethods.getCollection({
        shopId,
        collectionId,
        variantId: selectedVariantId,
      })
    );
  };

  switch (page) {
    case CollectionPage.List:
      return (
        <>
          <CollectionsHeader onCreateDefaultCollection={onCreateDefaultCollection} />
          <CollectionsMain
            shopId={shopId}
            dispatch={dispatch}
            onCreateDefaultCollection={onCreateDefaultCollection}
            onEditCollection={onEditCollection}
            variantId={selectedVariantId}
          />
        </>
      );
    case CollectionPage.Edit:
      return (
        <EditCollectionRulesVariantsPage onPageChange={onPageChange}>
          <EditCollectionRulesVariantsContainer
            shopId={shopId}
            dispatch={dispatch}
            currentCollection={collection}
            onPageChange={onPageChange}
            isCreateMode={false}
            variantId={selectedVariantId}
            formHeaderElementRef={formHeaderElementRef}
          />
        </EditCollectionRulesVariantsPage>
      );
    case CollectionPage.EditMerchRules:
      return (
        <EditCollectionRulesVariantsPage onPageChange={onPageChange}>
          <EditMerchandisingRulesContainer
            shopId={shopId}
            currentCollection={collection}
            formHeaderRef={formHeaderElementRef}
            dispatch={dispatch}
            selectedVariantId={selectedVariantId}
          />
        </EditCollectionRulesVariantsPage>
      );
    case CollectionPage.EditRanking:
      return (
        <EditCollectionRulesVariantsPage onPageChange={onPageChange}>
          <EditRankingStrategyContainer
            shopId={shopId}
            currentCollection={collection}
            formHeaderRef={formHeaderElementRef}
            dispatch={dispatch}
            selectedVariantId={selectedVariantId}
          />
        </EditCollectionRulesVariantsPage>
      );
    case CollectionPage.Create:
      return (
        <EditCollectionRulesVariantsContainer
          shopId={shopId}
          dispatch={dispatch}
          currentCollection={defaultCollection}
          onPageChange={onPageChange}
          isCreateMode={true}
          variantId={selectedVariantId}
          formHeaderElementRef={formHeaderElementRef}
        />
      );
    default:
      return null;
  }
};
