import React from 'react';
import { Tooltip } from 'src/components-dummy';
import { LinkStyled } from './image-url.styles';

interface ImageLinkProps {
  imageUrl: string;
}

export const ImageLink = ({ imageUrl }: ImageLinkProps): JSX.Element => {
  return (
    <Tooltip value={imageUrl}>
      <LinkStyled href={imageUrl}>Image URL</LinkStyled>
    </Tooltip>
  );
};
