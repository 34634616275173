import React from 'react';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

interface DeleteRuleConfirmationDialogProps {
  onCloseDeleteDialog: () => void;
  onDelete: (event: React.MouseEvent<HTMLElement>) => void;
}

export const DeleteRuleConfirmationDialog = ({
  onCloseDeleteDialog,
  onDelete,
}: DeleteRuleConfirmationDialogProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onCloseDeleteDialog}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Delete Rule</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>Are you sure you want to delete rule?</ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onDelete}>
            Delete
          </Button>
          <Button variant='outlined' onClick={onCloseDeleteDialog}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
