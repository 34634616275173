import { ValidatorsDict, ValidatorSuite } from 'src/components-bl/helpers/form-validation';
import { FilterSetSettings } from 'src/services/src/service/types/filters';

const noValidation: ValidatorSuite = { isRequired: false, tests: [] };

export const validatorsDict: ValidatorsDict<FilterSetSettings> = {
  expandCount: {
    isRequired: true,
    tests: [
      {
        testIsValid: inputVal => Number(inputVal) > 0,
        errorMsg: () => "'Expand Filters' shall be set between 1 to max filters",
      },
    ],
  },
  collapseListAfter: {
    isRequired: true,
    tests: [
      {
        testIsValid: inputVal => Number(inputVal) > 3,
        errorMsg: () => "'Show More / Less' shall be greater than 3 filters",
      },
    ],
  },
  collapseBucketsAfter: {
    isRequired: true,
    tests: [
      {
        testIsValid: inputVal => Number(inputVal) > 0,
        errorMsg: () => "'Show More / Less' should be set with at least 1 filter value",
      },
    ],
  },
  name: {
    isRequired: true,
    tests: [
      {
        testIsValid: inputVal => /^[a-zA-Z0-9-_]+$/.test(String(inputVal)),
        errorMsg: () =>
          "'Configuration name' cannot include special characters, please use only letters, numbers and dashes.",
      },
      {
        testIsValid: inputVal => Boolean(String(inputVal).length) && String(inputVal).length <= 30,
        errorMsg: () => "'Configuration name' exceeds the maximum length of 30 characters.",
      },
    ],
  },
  expand: noValidation,
  collapseList: noValidation,
  enableStickyFilters: noValidation,
  collapseBuckets: noValidation,
  segment: noValidation,
};
