import React from 'react';
import { EllipsisWithTooltip } from 'src/components-dummy/EllipsisWithToolTip';

import { Typography, TypographyType, TypographyVariant } from '../../Typography';

export const TableBodyRowColumnText = ({ children }: { children: string }): JSX.Element => (
  <EllipsisWithTooltip tooltipText={children || ''}>
    <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
      {children}
    </Typography>
  </EllipsisWithTooltip>
);
