import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Icon } from 'src/components-dummy/Icon';

export const LexiconsPageLayoutStyled = styled.div`
  height: 100%;
  overflow: auto;
  padding-left: 160px;
  padding-top: 56px;
`;

export const LexiconsTitleStyled = styled.span`
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const LexiconsPageDescriptionStyled = styled.p`
  font-size: 14px;
  color: #878787;
  margin-bottom: 24px;
  line-height: 22px;
`;

export const learnMoreCollapseStyle = css`
  margin-top: 2em;
  max-width: 558px;
  background-color: #f8f8f8;
`;

export const LearnMoreHeaderStyled = styled.span`
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  & > svg {
    margin-right: 10px;
    margin-bottom: 2px;
  }
  width: 100%;
`;

export const LearnMoreContentStyled = styled.div`
  padding-left: 30px;
  padding-right: 10px;
  padding-bottom: 16px;
`;

export const LearnMoreSubTitleStyled = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
`;

export const LearnMoreParagraphStyled = styled.p`
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 24px;
`;

export const LexiconListSectionStyled = styled.div`
  margin-top: 40px;
  padding-bottom: 20px;
`;

export const LexiconListTitleStyled = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 16px !important;
`;

export const IconStyled = styled(Icon)(({ isRotated }: { isRotated: boolean }) => {
  return `
  transition: 0.3s transform;
  transform: rotate(${isRotated ? '0' : '-90'}deg);
`;
});
