import { useCallback, useEffect, useState } from 'react';
import { ShopTypes } from 'src/services';
import {
  ShopFeatureToggleBase,
  WhiteListShopFeatureToggle,
} from 'src/services/src/service/types/shops';
import { useValidateSchema } from 'src/hooks';
import { isEqual } from 'lodash';
import { shopFeatureTogglesActionMethods } from './Actions';
import { UseFeatureTogglesSettingsParams } from './types';

export function useFeatureTogglesSettings({
  dispatch,
  featureToggles: originalFeatureToggles,
  shopId,
  validationSchema,
}: UseFeatureTogglesSettingsParams) {
  const [featureTogglesDraft, setFeatureTogglesDraft] = useState(originalFeatureToggles);
  const [isDirty, setIsDirty] = useState(false);

  const { validate, isValid } = useValidateSchema<Partial<ShopTypes.ShopFeatureToggles>>({
    schema: validationSchema,
  });

  const onChange = useCallback(
    (
      featureKey: keyof ShopTypes.ShopFeatureToggles,
      updateData: ShopFeatureToggleBase | WhiteListShopFeatureToggle
    ) => {
      const updatedState = {
        ...featureTogglesDraft,
        [featureKey]: updateData,
      } as ShopTypes.ShopFeatureToggles;

      setFeatureTogglesDraft(updatedState);

      const newIsDirty = !isEqual(originalFeatureToggles, updatedState);
      if (newIsDirty !== isDirty) {
        setIsDirty(newIsDirty);
      }

      validate({ dataToValidate: updatedState });
    },
    [
      featureTogglesDraft,
      originalFeatureToggles,
      isDirty,
      setIsDirty,
      setFeatureTogglesDraft,
      validate,
    ]
  );

  const onSubmit = useCallback(() => {
    if (isValid && featureTogglesDraft) {
      dispatch(
        shopFeatureTogglesActionMethods.updateFeatureToggles({
          shopId,
          featureToggles: featureTogglesDraft,
        })
      );
    }
  }, [featureTogglesDraft, shopId, isValid, dispatch]);

  useEffect(() => {
    setFeatureTogglesDraft(originalFeatureToggles);
    setIsDirty(false);
  }, [shopId, originalFeatureToggles, setFeatureTogglesDraft, setIsDirty]);

  useEffect(() => {
    dispatch(shopFeatureTogglesActionMethods.notifyIsDirty({ isDirty }));
  }, [isDirty]);

  return {
    featureTogglesDraft,
    isDirty,
    isValid,
    onChange,
    onSubmit,
  };
}
