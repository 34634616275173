import React, { useMemo } from 'react';
import { ShopDataField } from 'src/services';
import { Dispatch } from 'src/components-bl';
import { TableV2, TableV2InstanceProps } from 'src/components-dummy/TableV2';
import { formatDateToNow } from 'src/utils/format-date-to-now';
import { RouteSettings } from 'src/app-routes/app-routes';
import { isArray } from 'lodash';
import { MerchandisingRuleWithExtraIndications } from '../MerchandisingRulesFilters';
import { tableColumns } from './MerchandisingRulesList.config';
import { RuleItemRow } from './Components/RuleItemRow';
import { IMerchandisingRuleTableBodyRow } from './types';
import { TableBodyStyled, TableWrapperStyled } from './MerchandisingRulesList.styles';

const NO_VALUE = '';

interface MerchandisingRulesListProps {
  shopId: number;
  rules?: MerchandisingRuleWithExtraIndications[];
  selectedVariantId?: string;
  dataFields?: ShopDataField[];
  dispatch: Dispatch;
  onCreateRuleButtonClick: () => void;
  //   for experiment support
  onRuleSelection?: (ruleId: string) => void;
  merchandisingRulesEditPageRoute?: RouteSettings;
  isReadOnly?: boolean;
  areFiltersApplied: boolean;
}

const columns = Object.values(tableColumns);

export const MerchandisingRulesList = ({
  rules,
  shopId,
  selectedVariantId,
  dispatch,
  onRuleSelection,
  onCreateRuleButtonClick,
  merchandisingRulesEditPageRoute,
  isReadOnly,
  areFiltersApplied,
}: MerchandisingRulesListProps): JSX.Element => {
  const rulesDataMap = useMemo(() => {
    return (rules || []).reduce(
      (accumulator, rule) => {
        accumulator[rule.id] = rule;
        return accumulator;
      },
      {} as Record<string, MerchandisingRuleWithExtraIndications>
    );
  }, [rules]);

  const data: IMerchandisingRuleTableBodyRow[] = useMemo(() => {
    return (rules || []).map(rule => {
      return {
        id: rule.id,
        active: rule.active,
        isPublished: rule.isPublished,
        isUsingInvalidDataField: rule.isUsingInvalidDataField,
        title: rule.name,
        kpi: rule.kpi || NO_VALUE,
        product: rule.product as string,
        type: rule.action || NO_VALUE,
        updatedAt: rule.updatedAt ? formatDateToNow(rule.updatedAt) : NO_VALUE,
        rowActions: true,
        original: rule,
        isUsingInvalidRegion: !rule.hasOnlyValidRegions,
        isInvalidEmptyRegions: rule.isInvalidEmptyRegions,
      };
    });
  }, [rules]);

  const tableOptions = useMemo(() => ({ columns, data }), [data]);

  const renderBody = ({
    rows,
    prepareRow,
  }: Pick<TableV2InstanceProps<any>, 'rows' | 'prepareRow'>) => {
    if (isArray(rules) && !rules.length && !areFiltersApplied) {
      return (
        <TableV2.EmptyList
          message={
            isReadOnly
              ? 'No merchandising rules were configured'
              : 'Create merchandising rules to improve Syte Recommendations'
          }
          width={291}
          marginTop={64}
          marginBottom={92}
          buttonText='Create new rule'
          onButtonClick={isReadOnly ? undefined : onCreateRuleButtonClick}
        />
      );
    }

    if (!rules?.length && areFiltersApplied) {
      return <TableV2.NoResults width={291} />;
    }

    return rows.map(row => {
      prepareRow(row);
      return (
        <RuleItemRow
          key={row.original.id}
          row={row}
          itemOriginalData={rulesDataMap[row.original.id]}
          onRuleSelection={onRuleSelection}
          navigateToRuleCreationPage={onCreateRuleButtonClick}
          merchandisingRulesEditPageRoute={merchandisingRulesEditPageRoute}
          selectedVariantId={selectedVariantId}
          shopId={shopId}
          dispatch={dispatch}
          isReadOnly={isReadOnly}
        />
      );
    });
  };

  return (
    <TableWrapperStyled>
      <TableV2<IMerchandisingRuleTableBodyRow>
        options={tableOptions}
        customOptions={{
          shouldUseFlexLayout: true,
          shouldUseResizeColumns: true,
        }}
      >
        {({ getTableBodyProps, headerGroups, rows, prepareRow }) => {
          const headerGroup = headerGroups[0];

          return (
            <>
              <TableV2.Head {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableV2.HeadRow
                    {...column.getHeaderProps()}
                    key={column.id}
                    isResizable={column.id !== tableColumns.active.accessor}
                  >
                    <TableV2.HeadRowCellText>{column.render('Header')}</TableV2.HeadRowCellText>
                    {column.id !== tableColumns.active.accessor && (
                      <TableV2.HeaderRowCellResizableCursor column={column} />
                    )}
                  </TableV2.HeadRow>
                ))}
              </TableV2.Head>
              <TableBodyStyled {...getTableBodyProps()}>
                {renderBody({ rows, prepareRow })}
              </TableBodyStyled>
            </>
          );
        }}
      </TableV2>
    </TableWrapperStyled>
  );
};
