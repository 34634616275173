import {
  DeleteUiTemplateArguments,
  GetUiTemplateArguments,
} from '../../../../services/src/service/ui-templates';
import { UiTemplate } from '../../types';

export enum UiTemplateActionTypes {
  GetUiTemplateRequest = 'UiTemplate/GetRequest',
  GetUiTemplateSuccess = 'UiTemplate/GetSuccess',
  GetUiTemplateError = 'UiTemplate/GetError',

  SaveUiTemplateRequest = 'UiTemplate/SaveRequest',
  SaveUiTemplateSuccess = 'UiTemplate/SaveSuccess',
  SaveUiTemplateError = 'UiTemplate/SaveError',

  DeleteUiTemplateRequest = 'UiTemplate/DeleteRequest',
  DeleteUiTemplateSuccess = 'UiTemplate/DeleteSuccess',
  DeleteUiTemplateError = 'UiTemplate/DeleteError',
}

export type GetUiTemplateActionPayload = GetUiTemplateArguments;
export type DeleteUiTemplateActionPayload = DeleteUiTemplateArguments;
export type UiTemplateActionPayload = UiTemplate;
export type UiTemplateSuccessActionPayload = UiTemplate;
export interface UiTemplateDeleteSuccessActionPayload {
  templates: UiTemplate[];
}
export interface UiTemplateErrorActionPayload {
  error: unknown;
}

export interface UiTemplateGetRequestAction {
  type: typeof UiTemplateActionTypes.GetUiTemplateRequest;
  payload: GetUiTemplateActionPayload;
}
export interface UiTemplateGetSuccessAction {
  type: typeof UiTemplateActionTypes.GetUiTemplateSuccess;
  payload: UiTemplateSuccessActionPayload;
}
export interface UiTemplateGetErrorAction {
  type: typeof UiTemplateActionTypes.GetUiTemplateError;
  payload: UiTemplateErrorActionPayload;
}
export type UiTemplateGetAction =
  | UiTemplateGetRequestAction
  | UiTemplateGetSuccessAction
  | UiTemplateGetErrorAction;

export interface UiTemplateSaveRequestAction {
  type: typeof UiTemplateActionTypes.SaveUiTemplateRequest;
  payload: UiTemplateActionPayload;
}
export interface UiTemplateSaveSuccessAction {
  type: typeof UiTemplateActionTypes.SaveUiTemplateSuccess;
  payload: UiTemplateSuccessActionPayload;
}
export interface UiTemplateSaveErrorAction {
  type: typeof UiTemplateActionTypes.SaveUiTemplateError;
  payload: UiTemplateErrorActionPayload;
}
export type UiTemplateSaveAction =
  | UiTemplateSaveRequestAction
  | UiTemplateSaveSuccessAction
  | UiTemplateSaveErrorAction;

export interface UiTemplateDeleteRequestAction {
  type: typeof UiTemplateActionTypes.DeleteUiTemplateRequest;
  payload: DeleteUiTemplateActionPayload;
}
export interface UiTemplateDeleteSuccessAction {
  type: typeof UiTemplateActionTypes.DeleteUiTemplateSuccess;
  payload: UiTemplateDeleteSuccessActionPayload;
}
export interface UiTemplateDeleteErrorAction {
  type: typeof UiTemplateActionTypes.DeleteUiTemplateError;
  payload: UiTemplateErrorActionPayload;
}
export type UiTemplateDeleteAction =
  | UiTemplateDeleteRequestAction
  | UiTemplateDeleteSuccessAction
  | UiTemplateDeleteErrorAction;

export type UiTemplateAction = UiTemplateGetAction | UiTemplateSaveAction | UiTemplateDeleteAction;
