import React, { MutableRefObject, useCallback, useRef, useState } from 'react';
import { PaperProps } from '@mui/material';
import { LexiconTagField } from 'src/services';
import { AutoCompleteOption, AutoCompleteSingleValue } from 'src/components-dummy';
import { Dispatch } from 'src/components-bl';
import { debounce, DebouncedFunc } from 'lodash';
import { isAbortError } from 'src/utils/error-helpers';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';
import { lexiconItemAutoCompleteActions } from './LexiconItemAutoComplete.actions';

export interface LexiconItemEditAutoCompleteProps {
  onChange: (values: string) => void;
  selectedValue?: string;
  placeholder?: string;
  dispatch: Dispatch;
  shopId: number;
  tagType: LexiconTagType;
  tagField: LexiconTagField;
  locale: string;
  inputRef: MutableRefObject<any> | null;
  onBlur?: () => void;
  paperProps?: PaperProps;
  error?: boolean;
  minimumInputLength?: number;
  fetchMode?: 'keys' | 'translations';
  parentFieldsFilters?: {
    categories?: string[];
    attributes?: string[];
    values?: string[];
  };
}

export const LexiconItemEditAutoComplete = ({
  onChange,
  selectedValue,
  placeholder = 'Select or add your own...',
  dispatch,
  shopId,
  tagType,
  tagField,
  locale,
  inputRef,
  onBlur,
  paperProps,
  error,
  minimumInputLength = 2,
  fetchMode = 'translations',
  parentFieldsFilters = {},
}: LexiconItemEditAutoCompleteProps): JSX.Element => {
  const [options, setOptions] = useState<AutoCompleteOption[]>([]);

  /** Used to prevent async operation / debounce to set options if we already close the auto complete with ESC  */
  const refIsOpen = useRef(false);
  const [loading, setIsLoading] = useState(false);

  const thunkPromiseRef = useRef(null as { abort: () => void } | null);

  const fetchTranslationsDebounced = useRef<
    DebouncedFunc<
      (
        args: {
          shopId: number;
          locale: string;
          tagField: LexiconTagField;
          tagType: LexiconTagType;
          searchTerm: string;
          dispatch: Dispatch;
        } & Pick<LexiconItemEditAutoCompleteProps, 'fetchMode' | 'parentFieldsFilters'>
      ) => void
    >
  >(null as any);

  if (!fetchTranslationsDebounced.current) {
    fetchTranslationsDebounced.current = debounce((args): void => {
      let aborted = false;

      const shouldFetchTranslations = args.fetchMode === 'translations';

      const actionArguments = {
        shopId: args.shopId,
        tagType: args.tagType,
        tagField: args.tagField,
        locale: args.locale,
        searchTerm: args.searchTerm,
        ...args.parentFieldsFilters,
      };

      const thunkPromise: any = dispatch(
        shouldFetchTranslations
          ? lexiconItemAutoCompleteActions.getAvailableTranslations(actionArguments)
          : lexiconItemAutoCompleteActions.getAvailableKeys(actionArguments)
      );

      thunkPromiseRef.current = thunkPromise;

      thunkPromise
        .unwrap()
        .then(({ availableValues }: { availableValues: string[] }) => {
          setOptions(
            availableValues.map(option => {
              return {
                value: option,
                title: option,
              };
            })
          );
        })
        .catch((apiError: Error) => {
          aborted = isAbortError(apiError);

          if (!aborted) {
            console.error(apiError);
            setOptions([]);
          }
        })
        .finally(() => {
          if (!aborted) {
            setIsLoading(false);
          }
        });
    }, 500);
  }

  const onTextBoxChange = useCallback(
    (searchValue: string): void => {
      onChange(searchValue);

      const searchValueTrimmed = (searchValue || '').trim();

      thunkPromiseRef.current?.abort();

      if (searchValueTrimmed.length < minimumInputLength) {
        fetchTranslationsDebounced.current.cancel();
        setOptions([]);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);

      fetchTranslationsDebounced.current({
        shopId,
        locale,
        tagType,
        tagField,
        searchTerm: searchValueTrimmed,
        dispatch,
        fetchMode,
        parentFieldsFilters,
      });
    },
    [shopId, locale, tagType, tagField, dispatch, fetchMode, parentFieldsFilters]
  );

  const onClose = useCallback(() => {
    refIsOpen.current = false;
  }, []);

  const onOpen = useCallback(() => {
    refIsOpen.current = true;
  }, []);

  return (
    <AutoCompleteSingleValue
      selectedValue={selectedValue}
      errored={error}
      onSelectOption={onTextBoxChange as any}
      onChange={onTextBoxChange}
      options={options}
      placeholder={placeholder}
      freeSolo
      onBlur={onBlur}
      inputRef={inputRef}
      paperProps={paperProps}
      onClose={onClose}
      onOpen={onOpen}
      loading={loading}
    />
  );
};
