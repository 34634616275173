import { createAction } from '@reduxjs/toolkit';
import { ToastNotification } from 'src/services/src/service/social-discovery/types';

export const createToastNotification = createAction<ToastNotification>('Stories/CreateToast');

export const notifyIsDirty = (isDirty: boolean) => {
  return {
    type: 'Stories/NotifyIsDirty',
    payload: { isDirty },
  };
};
