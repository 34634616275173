import React from 'react';
import { ParseJoiValidateResponse } from 'src/utils';
import { ErrorLabel, Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { CustomOrderValue, FilterDraft } from '../../types';
import { SectionBoxStyled } from '../shared.styles';
import { ValuesTable } from './components';
import { SubTitleTypography } from './ValuesCustomOrderSection.styles';

interface ValuesCustomOrderSectionProps {
  onChange: (updatedValues: CustomOrderValue[]) => void;
  values: CustomOrderValue[];
  selectedSourceField: string;
  errors: ParseJoiValidateResponse<Omit<FilterDraft, 'id'>>;
  shopId: number;
}

export const ValuesCustomOrderSection = ({
  onChange,
  values,
  selectedSourceField,
  errors,
  shopId,
}: ValuesCustomOrderSectionProps): JSX.Element => {
  const showValuesTable = values.length > 0;

  const tableError = errors?.['valuesSorting.customOrder']?.message;

  return (
    <SectionBoxStyled>
      <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
        Filter Values: Custom Order
      </Typography>
      <SubTitleTypography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
        Click “Add” to add values, “Delete” to remove them, or drag and drop to adjust the display
        order.
        <br />
        Only values with results will be displayed on your website.
      </SubTitleTypography>
      {showValuesTable && (
        <ValuesTable
          values={values}
          onChange={onChange}
          shopId={shopId}
          selectedSourceField={selectedSourceField}
          errors={errors}
        />
      )}
      {tableError && <ErrorLabel value={tableError} />}
    </SectionBoxStyled>
  );
};
