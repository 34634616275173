import React, { ReactNode, forwardRef, MouseEvent } from 'react';
import { isString } from 'lodash';
import classNames from 'classnames';
import { EllipsisWithTooltip } from '../EllipsisWithToolTip';
import { ComponentError } from '../types';
import { InputBaseStyled, MuiInputLabelStyled, InputWrapperStyled } from './TextBox.styles';

export interface TextBoxBaseProps {
  label?: string | ReactNode;
  disabled?: boolean;
  children: ReactNode;
  className?: string;
  error?: ComponentError;
  success?: boolean;
  fullWidth?: boolean;
  onClick?: (e: MouseEvent) => void;
  multiline?: boolean;
}

export const TextBoxBase = forwardRef<HTMLInputElement, TextBoxBaseProps>(
  (
    { label, disabled, children, className, error, fullWidth, success, onClick, multiline },
    ref
  ): JSX.Element => {
    return (
      <InputBaseStyled
        fullWidth={fullWidth}
        className={classNames('input-base', className)}
        ref={ref}
        onClick={onClick}
      >
        {label && <MuiInputLabelStyled shrink>{label}</MuiInputLabelStyled>}
        <InputWrapperStyled
          className='input-base-wrapper'
          disabled={disabled}
          isSuccess={!error && success}
          error={!!error}
          multiline={multiline}
        >
          {children}
        </InputWrapperStyled>
        {isString(error) && (
          <EllipsisWithTooltip tooltipText={error} className='text-box-error-message'>
            {error}
          </EllipsisWithTooltip>
        )}
      </InputBaseStyled>
    );
  }
);
