import _ from 'lodash';

export const ErrorHandlerTypes = { ERROR_NOTIFICATION: 'ERROR_NOTIFICATION' };

export const errorNotificationAction = payload => {
  return { type: ErrorHandlerTypes.ERROR_NOTIFICATION, payload };
};

export default class ErrorHandlerNavigator {
  onStateChange = store => {
    const state = store.getState();
    const errorResponse = _.get(state, 'global.error.response');
    if (errorResponse && (errorResponse.code === 401 || errorResponse.code === 302)) {
      store.dispatch(errorNotificationAction('Asd'));
      // history.push('/login');
    }
  };
}
