import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon } from '../Icon';

/**
 * Table
 */
export const TableStyled = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.custom.white};
`;

/**
 * Head
 */
export const TableHeadHeaderStyled = styled.div<{
  width?: string;
  minWidth?: number;
  maxWidth?: number;
  isResizable?: boolean;
}>`
  display: flex;

  ${({ width, isResizable }) =>
    !isResizable &&
    width &&
    css`
      width: ${width};
    `};

  ${({ minWidth, isResizable }) =>
    !isResizable &&
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `};

  ${({ maxWidth, isResizable }) =>
    !isResizable &&
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `};
`;

export const TableHeaderCellResizableCursorStyled = styled.div`
  display: inline-block;
  position: absolute;
  height: 100%;
  width: 10;
  right: 0;
  top: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.palette.custom['primary-light']};
  cursor: col-resize;

  /* prevent scroll on touch devices */
  touch-action: none;

  &.isResizing {
    width: 5px;
    background-color: ${({ theme }) => theme.palette.custom['primary-80']};
  }
`;

export const TableHeadStyled = styled.div<{
  isSticky?: boolean;
}>`
  display: flex;
  overflow-y: auto;
  scrollbar-gutter: unset;
  overflow-x: hidden;

  ${({ isSticky, theme }) =>
    isSticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 2;
      background: ${theme.palette.common.white};
      border-radius: 8px 8px 0 0;
      border-bottom: 1px solid ${theme.palette.custom['gray-70']};
      border-top: 1px solid ${theme.palette.custom['gray-70']};
      box-shadow: 0px -7px 0px 0px ${theme.palette.custom['gray-90']};
    `}

  & > div {
    padding: 16px 12px;

    &:hover {
      .resizer {
        width: 10px;
      }
    }

    /* &:not(:first-child) {
      // May need to be added - in the future
      border-left: 1px solid ${({ theme }) => theme.palette.custom['gray-70']}; 
    }  */
  }
`;

export const TableHeadCellStyled = styled.div`
  display: flex;
`;

/**
 * Body
 */
export const TableBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};
  overflow-y: auto;
  scrollbar-gutter: auto;
`;

export const TableBodyRowStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  border-bottom: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};

  &:hover {
    background: ${({ theme }) => theme.palette.custom['gray-90']};
  }
`;

export const TableBodyRowCellStyled = styled.div<{
  width?: string;
  minWidth?: number;
  maxWidth?: number;
  isResizable?: boolean;
}>`
  ${({ width, isResizable }) =>
    !isResizable &&
    width &&
    css`
      width: ${width};
    `};

  padding: 3.5px 12px;

  ${({ minWidth, isResizable }) =>
    !isResizable &&
    minWidth &&
    css`
      min-width: ${minWidth}px;
    `};

  ${({ maxWidth, isResizable }) =>
    !isResizable &&
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `};

  /*
    &:not(:first-child) {
      // May need to be added - in the future
      border-left: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};
    } 
    */
`;

export const TableEmptyListStyled = styled.div<{
  width: number;
  marginTop?: number;
  marginBottom?: number;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: ${({ width }) => `${width}px`};
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin: 64px 0 92px 0;
    ${({ marginTop }) =>
      marginTop &&
      css`
        margin-top: ${marginTop};
      `}

    ${({ marginBottom }) =>
      marginBottom &&
      css`
        margin-top: ${marginBottom};
      `}
  }
`;

export const TableEmptyListMessage = styled.div`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  margin-bottom: 12px;
  text-align: center;
`;

export const TableSortIconStyled = styled(Icon)<{ sortAscending: boolean; isActive: boolean }>`
  margin-left: 10px;
  align-self: center;

  rotate: ${({ sortAscending }) => (sortAscending ? '270deg' : '90deg')};
  transition: rotate 0.1s;

  path {
    transition: stroke 0.1s;
    stroke: ${({ isActive, theme }) => (isActive ? theme.palette.common.black : 'transparent')};
  }
  &:hover {
    path {
      stroke: ${({ theme }) => theme.palette.common.black};
    }
  }
`;

export const TableHeadRowCellTextStyled = styled.div<{ isClickable: boolean }>`
  width: 100%;
  display: flex;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'initial')};
  &:hover {
    svg path {
      stroke: ${({ theme }) => theme.palette.common.black};
    }
  }
`;

export const DraggableBodyRowStyled = styled(TableBodyRowStyled)<{ isDragging?: boolean }>`
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
`;
