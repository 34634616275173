import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const PaginationButtonStyled = styled.div<{ isLeft?: boolean }>`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin: auto;
  padding: 12px;
  background: ${({ theme }) => theme.palette.common.white};
  border-radius: 100px;
  top: 0;
  bottom: 0;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom['gray-60']};
  }

  &:active {
    background-color: ${({ theme }) => theme.palette.custom['gray-50']};
  }

  ${({ isLeft }) =>
    isLeft
      ? css`
          left: 0;
          transform: rotate(180deg);
        `
      : css`
          right: 0;
        `}
`;

export const ModalContentWrapperStyled = styled.div`
  height: 100%;
  padding: 0 90px;
  position: relative;
  border: none;
  overflow: hidden;
  box-shadow: none;
`;

export const ModalContentStyled = styled.div`
  background: white;
  position: relative;
  overflow: hidden;
  border: none;
  border-radius: 16px;
  /* height: 90vh; */
`;
