import { ValidationErrors } from './types';

export enum FieldsToTriggerReset {
  enableMinBuckets = 'enableMinBuckets',
}
enum FieldsToApplyReset {
  minBuckets = 'minBuckets',
}

const fieldDependencyMap: Record<FieldsToTriggerReset, FieldsToApplyReset> = {
  [FieldsToTriggerReset.enableMinBuckets]: FieldsToApplyReset.minBuckets,
};

export const shouldResetValue = (
  updatedField: FieldsToTriggerReset,
  updatedValue: boolean,
  errors: ValidationErrors
): FieldsToApplyReset | undefined => {
  const fieldToMaybeReset: FieldsToApplyReset = fieldDependencyMap[updatedField];
  if (fieldToMaybeReset && updatedValue === false && errors[fieldToMaybeReset]) {
    return fieldToMaybeReset;
  }
  return undefined;
};
