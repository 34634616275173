import React from 'react';
import { Typography, TypographyVariant } from '../Typography';
import { ConfigurationVariant } from './configuration-variant';

export function ConfigurationNote({ variant }: { variant: ConfigurationVariant }) {
  return (
    <div style={{ color: '#404040', marginTop: 20 }}>
      <Typography variant={TypographyVariant.MediumRegular}>{variant}</Typography>
    </div>
  );
}
