import React from 'react';
import WelcomeImage from 'src/assets/galleries-welcome-image.gif';
import { Button, Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import {
  CreateGalleriesWrapperStyled,
  GalleriesEmptyTableStyled,
  WelcomeImageStyled,
  CreateGalleriesSubTitleStyled,
  CreateGalleriesActionButtonsWrapperStyled,
  GalleriesEmptyTableContentStyled,
} from './GalleriesTable.styles';

interface GalleriesEmptyTableProps {
  navigateToCreateGalleryPage: () => void;
}

export const GalleriesEmptyTable = ({
  navigateToCreateGalleryPage,
}: GalleriesEmptyTableProps): JSX.Element => {
  return (
    <GalleriesEmptyTableStyled>
      <GalleriesEmptyTableContentStyled>
        <WelcomeImageStyled src={WelcomeImage} alt='Galleries welcome image' />
        <CreateGalleriesWrapperStyled>
          <Typography variant={TypographyVariant.LargeBold} type={TypographyType.Body}>
            Create your first galleries
          </Typography>

          <CreateGalleriesSubTitleStyled
            variant={TypographyVariant.MediumRegular}
            type={TypographyType.Paragraph}
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          </CreateGalleriesSubTitleStyled>
          <CreateGalleriesActionButtonsWrapperStyled>
            <Button onClick={navigateToCreateGalleryPage}>Get Started</Button>
          </CreateGalleriesActionButtonsWrapperStyled>
        </CreateGalleriesWrapperStyled>
      </GalleriesEmptyTableContentStyled>
    </GalleriesEmptyTableStyled>
  );
};
