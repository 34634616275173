import React from 'react';
import styled from '@emotion/styled';
import {
  Typography,
  TypographyProps,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy/Typography';

export const ConfirmationDialogStyled = styled.div`
  min-width: 520px;
  overflow: hidden;
`;

/**
 * Header
 */
export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 16px 40px;
`;

/**
 * Sub title
 */
export const SubTitleStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
`;

export const SwitchIsIncludeLabeledStyled = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 8px;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

/**
 * Content
 */
export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 40px;
  min-height: 104px;
  max-height: calc(100vh - 225px);
  overflow: auto;
`;

/**
 * Footer (action buttons)
 */
export const FooterStyled = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 25px 40px 24px 40px;
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};
`;

export const ButtonsContainerStyled = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 4px;
`;

export const FrameContentStyled = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid var(--greys-grey-60-container-stroke, #dcdcdc);
  background: ${({ theme }) => theme.palette.custom.white};

  header {
    margin-bottom: 34px;
  }
`;

export const TypographyTextStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

/**
 * Errors
 */

export const ErrorsMessagesWrapperStyled = styled.div`
  margin-bottom: 16px;
`;

export const ErrorLabelStyled = styled((props: TypographyProps) => (
  <Typography {...props} type={TypographyType.Paragraph} variant={TypographyVariant.SmallRegular} />
))`
  color: ${({ theme }) => theme.palette.custom.red};
  margin-top: 4px;
`;

/**
 * Radio buttons
 */
export const RadioWrapperStyled = styled.div`
  width: 100%;
`;

export const RadioButtonsStyled = styled.div`
  margin-bottom: 24px;
  width: 100%;

  & > div:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const RadioOptionWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
  }
`;
