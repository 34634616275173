/* eslint-disable no-continue */
import { isArray, isString } from 'lodash';
import { differenceInCalendarDays } from 'date-fns';
import { AppliedFilters } from 'src/components-dummy';
import { MerchandisingRuleStatus } from 'src/components-bl/FilterCard/types';
import { MerchandisingRuleWithExtraIndications } from './MerchandisingRulesFilters.types';

export function getSearchableStringFromRule(rule: MerchandisingRuleWithExtraIndications): string {
  const resultStrings: string[] = [rule.name, rule.product, rule.action];

  if (rule.kpi) {
    resultStrings.push(rule.kpi);
  }

  for (const subRule of rule.subRules) {
    resultStrings.push(subRule.field);

    if (isArray(subRule.values)) {
      for (const value of subRule.values) {
        if (isString(value)) {
          resultStrings.push(value as string);
        }
      }
    }
  }

  for (const subRule of rule.sourceCondition) {
    if (subRule.field) {
      resultStrings.push(subRule.field);
    }

    if (isArray(subRule.values)) {
      for (const value of subRule.values) {
        if (isString(value)) {
          resultStrings.push(value as string);
        }
      }
    }
  }

  return resultStrings.map(string => string.toLowerCase().trim()).join('');
}
export function filterMerchandisingRules(
  rules: MerchandisingRuleWithExtraIndications[],
  filters: AppliedFilters = {}
): MerchandisingRuleWithExtraIndications[] {
  const filtersAreEmpty = Object.keys(filters).length === 0;

  if (filtersAreEmpty) {
    return rules;
  }

  const now = new Date();

  const resultRules = [];

  const KPIsSet = new Set((filters.kpi as string[]) || []);

  for (const rule of rules) {
    const filterRuleMatches: boolean[] = [];

    // filter by free text search
    if (filters.search) {
      const searchableString = getSearchableStringFromRule(rule);

      const searchValue = (filters.search as string).toLowerCase().trim();

      const ruleMatchesSearch = searchableString.includes(searchValue);

      if (ruleMatchesSearch) {
        filterRuleMatches.push(true);
      } else {
        continue;
      }
    }

    // filter by KPI
    if (filters.kpi) {
      if (rule.kpi && KPIsSet.has(rule.kpi)) {
        filterRuleMatches.push(true);
      } else {
        continue;
      }
    }

    // filter by status
    if (filters.status) {
      switch (filters.status) {
        case MerchandisingRuleStatus.Active:
          if (rule.active) {
            filterRuleMatches.push(true);
          } else {
            continue;
          }
          break;
        case MerchandisingRuleStatus.Inactive:
          if (!rule.active) {
            filterRuleMatches.push(true);
          } else {
            continue;
          }
          break;
        case MerchandisingRuleStatus.InValid:
          if (rule.isUsingInvalidDataField) {
            filterRuleMatches.push(true);
          } else {
            continue;
          }
          break;
        case MerchandisingRuleStatus.IsInDraft:
        default:
          if (!rule.isPublished) {
            filterRuleMatches.push(true);
          } else {
            continue;
          }
          break;
      }
    }

    // filter by syteProduct
    if (filters.syteProducts) {
      if ((filters.syteProducts as string[]).includes(rule.product)) {
        filterRuleMatches.push(true);
      } else {
        continue;
      }
    }

    // filter by action
    if (filters.action) {
      if ((filters.action as string[]).includes(rule.action)) {
        filterRuleMatches.push(true);
      } else {
        continue;
      }
    }

    // filter by lastEdited date
    if (filters.lastEdited !== undefined) {
      if (rule.updatedAt) {
        const diffInDays = differenceInCalendarDays(now, rule.updatedAt);

        if (diffInDays < (filters.lastEdited as number)) {
          filterRuleMatches.push(true);
        } else {
          continue;
        }
      } else {
        continue;
      }
    }

    if (filterRuleMatches.every(isMatch => isMatch === true)) {
      resultRules.push(rule);
    }
  }
  return resultRules;
}
