import { AxiosResponse } from 'axios';
import {
  ShopDataField,
  ShopDataFieldsSupportedFeatures,
  ShopDataFieldFormat,
  FeedDataFieldType,
  FeedsMetaDataTypes,
  FieldType,
} from '../types';

const serviceTypeToFormatType = (serviceType: string[]): ShopDataFieldFormat => {
  if (serviceType.includes('text') || serviceType.includes('keyword')) {
    return ShopDataFieldFormat.String;
  }
  if (serviceType.includes('float') || serviceType.includes('integer')) {
    return ShopDataFieldFormat.Numeric;
  }
  return ShopDataFieldFormat.Numeric;
};
type EntryResponse = {
  name: string;
  types: FeedDataFieldType[];
  features?: ShopDataFieldsSupportedFeatures[];
  catalogNames: string[];
  textSearchFieldNames?: string[];
};
function mapToDataFieldsStructure(raw: EntryResponse): ShopDataField {
  const dataField: ShopDataField = {
    id: raw.name,
    name: raw.name,
    displayName: raw.name,
    format: serviceTypeToFormatType(raw.types),
    types: raw.types,
    sample: '',
    supportedFeatures: raw.features || [],
    catalogNames: raw.catalogNames,
    fieldType: FieldType.AiTag,
    textSearchFieldNames: raw.textSearchFieldNames || [],
  };
  return dataField;
}

export function mapPartialToDataFieldsStructure(
  raw: AxiosResponse
): FeedsMetaDataTypes.FeedMetadataPartial {
  const mapped: FeedsMetaDataTypes.FeedMetadataPartial = {
    feeds: raw.data.feeds,
    items: raw.data.items.map(mapToDataFieldsStructure),
  };
  return mapped;
}
