import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { Dispatch } from 'src/components-bl';
import { lexiconService } from 'src/services';
import {
  UseValidateLocaleNavigateToActionPayload,
  UseValidateLocaleNavigateToAction,
  UseValidateLocaleActionTypes,
  UseValidateLocaleGetLexiconSettingsActionPayload,
} from './types';

export const useValidateLocaleActions = {
  navigateTo(payload: UseValidateLocaleNavigateToActionPayload): UseValidateLocaleNavigateToAction {
    return {
      type: UseValidateLocaleActionTypes.NavigateTo,
      payload,
    };
  },

  getLexiconSettings: createAsyncThunk(
    'useValidateLocale/getLexiconSettings',
    ({ shopId }: UseValidateLocaleGetLexiconSettingsActionPayload, { rejectWithValue }) => {
      try {
        return lexiconService.getShopLexiconsSettings({ shopId });
      } catch (error: unknown) {
        return rejectWithValue({ error });
      }
    }
  ),

  localeDisabledErrorNotification: createAction(
    `useValidateLocale/localeDisabledErrorNotification`
  ),
};

export const useValidateLocaleActionMethods = {
  navigateTo(payload: UseValidateLocaleNavigateToActionPayload) {
    return (dispatch: Dispatch): void => {
      dispatch(useValidateLocaleActions.navigateTo(payload));
    };
  },
};
