import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { AxiosProgressEvent } from 'axios';
import { DeepTagReportsService, DeepTagsReportsRecognitionType } from 'src/services';

const deepTagReportsService = new DeepTagReportsService();

export const deepTagReportFileUploadModalActions = {
  createReport: createAsyncThunk(
    'DeepTagReportFileUploadModal/CreateReport',
    async (
      {
        data,
        cancellationSignal,
        onUploadProgress,
      }: {
        data: {
          shopId: number;
          locale: string;
          recognitionType: DeepTagsReportsRecognitionType;
          productsFile: File;
        };
        cancellationSignal?: AbortSignal;
        onUploadProgress: (progressEvent: AxiosProgressEvent) => void;
      },
      { rejectWithValue }
    ) => {
      try {
        const createdReport = await deepTagReportsService.createReport({
          data,
          onUploadProgress,
          cancellationSignal,
        });

        const reports = await deepTagReportsService.getReports(data.shopId);

        return { reports, createdReport };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateIsDirty: createAction<{ isDirty?: boolean }>('DeepTagReportFileUploadModal/NotifyIsDirty'),
};
