import { DomainEntityPath, FilterSet } from '../../../../../services';
import { DomainGroup, ParentDomainBase, SubDomainGroupBase } from '../../types';
import { AvailableIcons } from '../../../../../components-dummy';
import { filtersSetsFilterDomainSchema } from './filters';

interface EntityType extends Omit<FilterSet, 'filters'> {
  filtersIds: string[];
  filtersNames: string[];
}

export const filtersSetsDomainSchema: DomainGroup & SubDomainGroupBase<EntityType> = {
  domainKey: DomainEntityPath.FiltersSets,
  title: 'Filters Sets',
  icon: AvailableIcons.AngleRightLight,
  getCardTitle: (): string => {
    return 'Filters List';
  },
  fieldsSchema: {
    filtersIds: {
      displayName: 'Filter Position',
      getValue({ entity }: { entity: EntityType }): string {
        return entity.filtersNames.join(', ');
      },
    },
  },
};

export const filtersSetsDomainGroupSchema: ParentDomainBase = {
  domainKey: DomainEntityPath.FiltersSets,
  title: 'Results Modal Filters',
  icon: AvailableIcons.AngleRightLight,
  subDomains: [filtersSetsDomainSchema, filtersSetsFilterDomainSchema],
};
