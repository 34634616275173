/* eslint-disable no-param-reassign */
import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { ShopSFTPSettingsReducerState } from 'src/app-state-types';
import { deepTagReportSFTPSettingsModalContainerActions } from 'src/containers';
import { deepTagReportHowToUploadActions } from 'src/components-bl';
import { catalogFormContainerActions } from 'src/containers/CatalogManager/CatalogFormContainer';

const initialState: ShopSFTPSettingsReducerState = {
  settings: undefined,
};

export const shopSFTPSettingsReducer = createReducer(initialState, builder => {
  builder
    .addCase(deepTagReportHowToUploadActions.getSFTPSettings.fulfilled, (state, action) => {
      state.settings = action.payload.settings;
    })
    .addCase(deepTagReportHowToUploadActions.resetSFTPSettings, (state, _) => {
      state.settings = initialState.settings;
    })
    .addCase(deepTagReportSFTPSettingsModalContainerActions.resetSFTPSettings, state => {
      state.settings = initialState.settings;
    })
    .addMatcher(
      isAnyOf(
        deepTagReportSFTPSettingsModalContainerActions.getShopSFTPSettings.fulfilled,
        catalogFormContainerActions.getShopSFTPSettings.fulfilled
      ),
      (state, action) => {
        state.settings = action.payload.settings;
      }
    )
    .addMatcher(
      isAnyOf(
        deepTagReportSFTPSettingsModalContainerActions.getShopSFTPSettings.pending,
        catalogFormContainerActions.getShopSFTPSettings.pending
      ),
      state => {
        state.settings = undefined;
      }
    );
});
