import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/hooks';
import { ApiKeysSettings, JsSnippetSection } from 'src/components-bl/ApiKeysSettings';
import { AppRoute, AppSwitch, RoutedComponentProps } from 'src/app-routes';
import { shopApiKeysSettingsActions } from './Actions';
import { ShopApiKeysSettingsModalContainer } from './ShopApiKeysSettingsModalContainer';
import { ShopApiKeysUpdateSettingsModalContainer } from './ShopApiKeysUpdateSettingsModalContainer';

export const ShopApiKeysSettings = ({ permittedRouteMap }: RoutedComponentProps): JSX.Element => {
  const dispatch = useDispatch();
  const currentShopId = useAppSelector(state => state.shop.current?.shopId);
  const apiKeys = useAppSelector(state => state.apiKeys.apiKeys);

  useEffect(() => {
    if (currentShopId) {
      dispatch(shopApiKeysSettingsActions.getApiKeys({ shopId: currentShopId }));
    }
  }, [currentShopId]);

  return (
    <>
      <AppSwitch>
        <AppRoute
          route={permittedRouteMap.shopSettingsGeneralUpdateApiKey}
          Component={ShopApiKeysUpdateSettingsModalContainer}
          componentProps={{ permittedRouteMap }}
        />
        <AppRoute
          route={permittedRouteMap.shopSettingsGeneralCreateApiKey}
          Component={ShopApiKeysSettingsModalContainer}
          componentProps={{ permittedRouteMap }}
        />
      </AppSwitch>
      <ApiKeysSettings
        apiKeys={apiKeys ?? []}
        dispatch={dispatch}
        shopId={currentShopId}
        permittedRouteMap={permittedRouteMap}
      />
      <JsSnippetSection apiKeys={apiKeys ?? []} dispatch={dispatch} shopId={currentShopId} />
    </>
  );
};
