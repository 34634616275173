import React, { useMemo, useEffect } from 'react';
import { VariantPanel } from 'src/components-bl';
import { shallowEqual, useDispatch } from 'react-redux';
import { ShopFeature } from 'src/app-types';
import { useAppSelector, useIsFeaturePermitted } from 'src/hooks';
import { editExperimentContainerActionMethods } from '../Actions/Actions';

export const ConnectedVariantPanel = (): JSX.Element => {
  const dispatch = useDispatch();

  const relevantState = useAppSelector(
    state => ({
      shopId: state.shop.current?.shopId,
      dataFields: state.dataFields.dataFields,
      selectedExperiment: state.experiments.selectedExperiment,
      selectedVariantId: state.experiments.selectedVariantId,
      updatedVariant: state.experiments.updatedVariant,
      selectedSyteProduct: state.rankingStrategy.selectedSyteProduct,
      weights: state.rankingStrategy.weights,
      type: state.rankingStrategy.type,
      shouldRefetchRankingStrategy: state.rankingStrategy.shouldRefetch,
      selectedVariantFeatureId: state.experiments.selectedVariantFeatureId,
      shopTheLookSettings: state.shopTheLookSettings,
      shouldRefetchShopTheLookSettings: state.shopTheLookSettings.shouldRefetch,
      boughtTogetherSettings: state.boughtTogetherSettings,
      shouldRefetchRecentlyViewedSettings: state.recentlyViewedSettings.shouldRefetch,
      recentlyViewedSettings: state.recentlyViewedSettings,
      shouldRefetchBoughtTogetherSettings: state.boughtTogetherSettings.shouldRefetch,
      personalizationSettings: state.personalizationSettings,
      shouldRefetchPersonalizationSettings: state.personalizationSettings.shouldRefetch,
      recEnginesGeneralSettings: state.recEnginesGeneralSettings,
      shouldRefetchRecEnginesGeneralSettings: state.recEnginesGeneralSettings.shouldRefetch,
      loggedInUser: state.global.loggedInUser,
      similarItemsSettings: state.similarItemsSettings,
      shouldRefetchSimilarItemsSettings: state.similarItemsSettings.shouldRefetch,
      enableUiTest: state.enableUiTest.enableUiTest,
      shouldRefetchUiTestToggle: state.enableUiTest.shouldRefetch,
      useKnn: state.useKnn.useKnn,
      shouldRefetchUseKnnToggle: state.useKnn.shouldRefetch,
      discoveryBannerSettings: state.discoveryBannerSettings,
      shouldRefetchDiscoveryBannerSettings: state.discoveryBannerSettings.shouldRefetch,
      featureToggles: state.shop.featureToggles,
      availableRegions: state.shop.availableRegions,
      categoryFilterRule: state.visualEditor.categoryFilterRule,
    }),
    shallowEqual
  );

  const merchandisingRulesProps = useAppSelector(
    state => ({
      selectedRule: state.merchandisingRules.selectedRule,
      rules: state.merchandisingRules.rules,
      currentPage: state.merchandisingRules.currentPage,
      duplicateRuleDraft: state.merchandisingRules.duplicateRuleDraft,
      overview: state.merchandisingRules.overview,
      totalRulesCount: state.merchandisingRules.totalCount,
    }),
    shallowEqual
  );

  const {
    shopId,
    dataFields,
    selectedExperiment,
    selectedVariantId,
    selectedVariantFeatureId,
    updatedVariant,
    selectedSyteProduct,
    weights,
    type,
    shouldRefetchRankingStrategy,
    shopTheLookSettings,
    shouldRefetchShopTheLookSettings,
    boughtTogetherSettings,
    shouldRefetchBoughtTogetherSettings,
    recentlyViewedSettings,
    shouldRefetchRecentlyViewedSettings,
    personalizationSettings,
    shouldRefetchPersonalizationSettings,
    recEnginesGeneralSettings,
    shouldRefetchRecEnginesGeneralSettings,
    loggedInUser,
    similarItemsSettings,
    shouldRefetchSimilarItemsSettings,
    enableUiTest,
    shouldRefetchUiTestToggle,
    useKnn,
    shouldRefetchUseKnnToggle,
    discoveryBannerSettings,
    shouldRefetchDiscoveryBannerSettings,
    featureToggles,
    availableRegions,
    categoryFilterRule,
  } = relevantState;

  const isMerchRulesAiTagsEnabled = useIsFeaturePermitted(ShopFeature.MerchandisingRulesAiTags);

  const currentVariant = useMemo(
    () => selectedExperiment?.variants.find(v => v.id === selectedVariantId),
    [selectedExperiment?.variants, selectedVariantId]
  );

  const rankingProps = useMemo(() => {
    return {
      loggedInUser,
      weights: weights[selectedSyteProduct],
      type,
      selectedSyteProduct,
      shouldRefetch: shouldRefetchRankingStrategy,
      featureToggles,
    };
  }, [selectedSyteProduct, weights, shouldRefetchRankingStrategy, featureToggles]);

  const shopTheLookSettingsProps = useMemo(
    () => ({
      shopTheLookSettings,
      shouldRefetch: shouldRefetchShopTheLookSettings,
    }),
    [shopTheLookSettings, shouldRefetchShopTheLookSettings]
  );

  const boughtTogetherSettingsProps = useMemo(
    () => ({
      boughtTogetherSettings,
      shouldRefetch: shouldRefetchBoughtTogetherSettings,
    }),
    [boughtTogetherSettings, shouldRefetchBoughtTogetherSettings]
  );

  const recentlyViewedSettingsProps = useMemo(
    () => ({
      recentlyViewedSettings,
      shouldRefetch: shouldRefetchRecentlyViewedSettings,
    }),
    [recentlyViewedSettings, shouldRefetchRecentlyViewedSettings]
  );

  const personalizationSettingsProps = useMemo(
    () => ({
      personalizationSettings,
      shouldRefetch: shouldRefetchPersonalizationSettings,
    }),
    [personalizationSettings, shouldRefetchPersonalizationSettings]
  );

  const recEnginesGeneralSettingsProps = useMemo(
    () => ({
      generalSettings: recEnginesGeneralSettings,
      shouldRefetch: shouldRefetchRecEnginesGeneralSettings,
    }),
    [recEnginesGeneralSettings, shouldRefetchRecEnginesGeneralSettings]
  );

  const similarItemsSettingsProps = useMemo(
    () => ({
      similarItemsSettings,
      shouldRefetch: shouldRefetchSimilarItemsSettings,
    }),
    [similarItemsSettings, shouldRefetchSimilarItemsSettings]
  );
  const discoveryBannerSettingsProps = useMemo(
    () => ({
      discoveryBannerSettings,
      shouldRefetch: shouldRefetchDiscoveryBannerSettings,
    }),
    [discoveryBannerSettings, shouldRefetchDiscoveryBannerSettings]
  );
  const enableUiTestProps = useMemo(
    () => ({
      enableUiTest,
      shouldRefetch: shouldRefetchUiTestToggle,
    }),
    [enableUiTest, shouldRefetchUiTestToggle]
  );
  const useKnnProps = useMemo(
    () => ({
      useKnn,
      shouldRefetch: shouldRefetchUseKnnToggle,
    }),
    [useKnn, shouldRefetchUseKnnToggle]
  );

  useEffect(() => {
    if (updatedVariant && shopId && selectedExperiment) {
      dispatch(
        editExperimentContainerActionMethods.getExperiment({
          shopId,
          id: selectedExperiment.id,
        })
      );
    }
  }, [updatedVariant]);

  return (
    <VariantPanel
      {...{
        availableRegions,
        boughtTogetherSettingsProps,
        categoryFilterRule,
        dataFields,
        discoveryBannerSettingsProps,
        dispatch,
        enableUiTestProps,
        experiment: selectedExperiment,
        featureToggles,
        isMerchRulesAiTagsEnabled,
        loggedInUserRole: loggedInUser?.role,
        merchandisingRulesProps,
        personalizationSettingsProps,
        rankingProps,
        recEnginesGeneralSettingsProps,
        recentlyViewedSettingsProps,
        selectedVariantFeatureId,
        shopId: shopId as number,
        shopTheLookSettingsProps,
        similarItemsSettingsProps,
        useKnnProps,
        variant: currentVariant,
      }}
    />
  );
};
