import { DomainEntityPath } from '../types';
import { DomainEventsWebSocketServiceBase } from '../domain-events';
import {
  UserLocationEventTopicNames,
  IUserLocation,
  IUserLocationUpdateEvent,
  IUserOnlineUpdateEvent,
} from './user.location.types';

class UserLocationWebSocketService extends DomainEventsWebSocketServiceBase<UserLocationEventTopicNames> {
  domainEntity = DomainEntityPath.UserLocation;

  updateUserLocation(location: IUserLocation | null) {
    this.domainEventsWebSocketService.postMessage({
      topicKey: UserLocationEventTopicNames.SetUserLocation,
      data: { location },
    });
  }

  subscribeToLocationUpdates(onLocationUpdate: (payload: IUserLocationUpdateEvent) => void): void {
    this.subscribeToEvents({
      topicKey: UserLocationEventTopicNames.LocationUpdate,
      callback: onLocationUpdate,
    });
  }

  subscribeToUserOnlineStatusUpdate(onUserUpdate: (payload: IUserOnlineUpdateEvent) => void): void {
    this.subscribeToEvents({
      topicKey: UserLocationEventTopicNames.UserOnlineStatusUpdate,
      callback: onUserUpdate,
    });
  }
}

export const userLocationWebSocketService = new UserLocationWebSocketService();
