import React from 'react';
import { Row } from 'react-table';
import { TableV2 } from 'src/components-dummy';
import { AdminSearchShopResult } from '../../../../services/src/service/types/admin-search/admin-search-results';
import { TableBodyRowCell } from '../../AdminSearchModal.styles';

interface ShopsTableRowProps {
  row: Row<AdminSearchShopResult>;
  onClick: VoidFunction;
}

export const TableRow = ({ row, onClick }: ShopsTableRowProps): JSX.Element => {
  return (
    <>
      <TableV2.BodyRow {...row.getRowProps()} key={row.original.shopId}>
        {row.cells.map(cell => {
          const propName = cell.column.id;

          return (
            <TableBodyRowCell
              {...cell.getCellProps()}
              key={propName}
              width={cell.column.width as string}
              minWidth={cell.column.minWidth}
              data-id={`${row.original.shopName}-${propName}`}
              onClick={onClick}
            >
              <TableV2.BodyRowCellText>{cell.value}</TableV2.BodyRowCellText>
            </TableBodyRowCell>
          );
        })}
      </TableV2.BodyRow>
    </>
  );
};
