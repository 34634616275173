import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ShopLexiconSettings } from 'src/components-bl/ShopLexiconSettings';
import { useAppSelector } from 'src/hooks';
import { DomainEntityPath } from 'src/services';
import { lexiconSettingsContainerActions } from './Actions/LexiconSettingsContainer.actions';
import { LexiconSettingsContainerStyled } from './LexiconSettingsContainer.styles';

/** Settings for all shop's lexicons, set by Syte Admins */
export const LexiconsSettingsContainer = (): JSX.Element => {
  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const lexiconLanguages = useAppSelector(state => state.lexicon.allLexiconSupportedLanguages);
  const [lexiconSettings, setLexiconSettings] = useState<{ locales: string[] } | undefined>(
    undefined
  );

  const lexiconAvailableLanguages = useMemo(() => {
    return lexiconLanguages?.filter(language =>
      language.supportedDomains.includes(DomainEntityPath.Lexicon)
    );
  }, [lexiconLanguages]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (shopId) {
      (dispatch(lexiconSettingsContainerActions.getLexiconSettings({ shopId })) as any)
        .unwrap()
        .then((settings: { locales: string[] }) => {
          setLexiconSettings(settings);
        })
        .catch((error: unknown) => {
          console.error(error);
        });
    }
  }, [shopId]);

  return (
    <LexiconSettingsContainerStyled>
      {shopId && (
        <ShopLexiconSettings
          shopId={shopId}
          availableLexiconLanguages={lexiconAvailableLanguages}
          dispatch={dispatch}
          selectedLocales={lexiconSettings?.locales}
          onSettingsSaved={setLexiconSettings}
        />
      )}
    </LexiconSettingsContainerStyled>
  );
};
