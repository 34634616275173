import { ShopTheLookGeneralSettings } from 'src/services/src/service/shop-the-look/shop-the-look';
import { Dispatch } from 'src/components-bl/types';
import {
  UpdateShopTheLookSettingsPayload,
  GetShopTheLookSettingsArgs,
  GetShopTheLookSettingsRequestPayload,
  GetShopTheLookSettingsSuccessPayload,
  GetShopTheLookSettingsErrorPayload,
  UpdateShopTheLookSettingsRequestPayload,
  UpdateShopTheLookSettingsSuccessPayload,
  UpdateShopTheLookSettingsErrorPayload,
  ShopTheLookActionTypes,
  GetShopTheLookSettingsRequestAction,
  GetShopTheLookSettingsErrorAction,
  GetShopTheLookSettingsSuccessAction,
  UpdateShopTheLookSettingsRequestAction,
  UpdateShopTheLookSettingsSuccessAction,
  UpdateShopTheLookSettingsErrorAction,
  NotifyShopTheLookSettingsIsDirtyAction,
  NotifyShopTheLookSettingsIsDirtyActionPayload,
} from './types';

const shopTheLookService = new ShopTheLookGeneralSettings();

/* --= Actions =-- */
const shopTheLookSettingsActions = {
  getShopTheLookSettingsRequest: (
    payload: GetShopTheLookSettingsRequestPayload
  ): GetShopTheLookSettingsRequestAction => {
    return {
      type: ShopTheLookActionTypes.GetShopTheLookSettingsRequest,
      payload,
    };
  },
  getShopTheLookSettingsSuccess: (
    payload: GetShopTheLookSettingsSuccessPayload
  ): GetShopTheLookSettingsSuccessAction => {
    return {
      type: ShopTheLookActionTypes.GetShopTheLookSettingsSuccess,
      payload,
    };
  },
  getShopTheLookSettingsError: ({
    error,
  }: GetShopTheLookSettingsErrorPayload): GetShopTheLookSettingsErrorAction => {
    return {
      type: ShopTheLookActionTypes.GetShopTheLookSettingsError,
      payload: { error },
    };
  },
  updateShopTheLookSettingsRequest: (
    payload: UpdateShopTheLookSettingsRequestPayload
  ): UpdateShopTheLookSettingsRequestAction => {
    return {
      type: ShopTheLookActionTypes.UpdateShopTheLookSettingsRequest,
      payload,
    };
  },
  updateShopTheLookSettingsSuccess: (
    payload: UpdateShopTheLookSettingsSuccessPayload
  ): UpdateShopTheLookSettingsSuccessAction => {
    return {
      type: ShopTheLookActionTypes.UpdateShopTheLookSettingsSuccess,
      payload,
    };
  },
  updateShopTheLookSettingsError: (
    payload: UpdateShopTheLookSettingsErrorPayload
  ): UpdateShopTheLookSettingsErrorAction => {
    return {
      type: ShopTheLookActionTypes.UpdateShopTheLookSettingsError,
      payload,
    };
  },

  notifyIsDirtyState: (
    payload: NotifyShopTheLookSettingsIsDirtyActionPayload
  ): NotifyShopTheLookSettingsIsDirtyAction => {
    return {
      type: ShopTheLookActionTypes.NotifyShopTheLookSettingsIsDirty,
      payload,
    };
  },
};

/* --= Methods =-- */
export const shopTheLookSettingsActionsMethods = {
  notifyIsDirty:
    ({ isDirty }: NotifyShopTheLookSettingsIsDirtyActionPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(shopTheLookSettingsActions.notifyIsDirtyState({ isDirty }));
    },

  fetchShopTheLookSettings:
    ({ shopId, variantId }: GetShopTheLookSettingsArgs) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(shopTheLookSettingsActions.getShopTheLookSettingsRequest({ shopId }));
        const {
          numberOfResultsToDisplay,
          includeMainProductInCarousel,
          shuffleResults,
          completeTheLookFallbackMethod,
          addToCart,
        } = await shopTheLookService.get({
          shopId,
          variantId,
        });
        dispatch(
          shopTheLookSettingsActions.getShopTheLookSettingsSuccess({
            shopId,
            numberOfResultsToDisplay,
            includeMainProductInCarousel,
            shuffleResults,
            completeTheLookFallbackMethod,
            addToCart,
          })
        );
      } catch (error) {
        dispatch(shopTheLookSettingsActions.getShopTheLookSettingsError({ error }));
      }
    },

  updateShopTheLookSettings:
    ({
      shopId,
      variantId,
      numberOfResultsToDisplay,
      includeMainProductInCarousel,
      shuffleResults,
      completeTheLookFallbackMethod,
      addToCart,
    }: UpdateShopTheLookSettingsPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(
          shopTheLookSettingsActions.updateShopTheLookSettingsRequest({
            shopId,
            numberOfResultsToDisplay,
            includeMainProductInCarousel,
            shuffleResults,
            completeTheLookFallbackMethod,
            addToCart,
          })
        );

        await shopTheLookService.update({
          shopId,
          variantId,
          numberOfResultsToDisplay,
          includeMainProductInCarousel,
          shuffleResults,
          completeTheLookFallbackMethod,
          addToCart,
        });

        dispatch(
          shopTheLookSettingsActions.updateShopTheLookSettingsSuccess({
            shopId,
            numberOfResultsToDisplay,
            includeMainProductInCarousel,
            shuffleResults,
            completeTheLookFallbackMethod,
            addToCart,
          })
        );
      } catch (error) {
        dispatch(shopTheLookSettingsActions.updateShopTheLookSettingsError({ error }));
      }
    },
};
