import { DropDownOption } from 'src/components-dummy';
import { SyteProductType } from 'src/services/src/service/types/constants/syte-product-types';
import { getProductName } from '../MerchandisingRules/merchandisingRules.helpers';

export const getSyteProductDropdownOptions = (entityId?: string) => {
  return Object.values(SyteProductType).map(syteProductType => {
    return {
      value: syteProductType,
      text: getProductName(syteProductType, entityId),
    } as DropDownOption<SyteProductType>;
  });
};

export const allSyteProducts = Object.values(SyteProductType);
