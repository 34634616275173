export enum SyteProductType {
  AugmentedSearch = 'augmented_search',
  SimilarItems = 'similar_items',
  ShopTheLook = 'shop_the_look',
  VisualSearch = 'visual_search',
  DiscoveryButton = 'discovery_button',
  Personalization = 'personalization',
  Collections = 'collections',
  UiTemplates = 'ui_templates',
}
