import React, { useCallback } from 'react';
import { ShopFeature } from 'src/app-types';
import {
  AvailableIcons,
  Button,
  FormCard,
  Icon,
  Tooltip,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { ShopTypes } from 'src/services';
import {
  apiWhiteListDetails,
  imageDownloadWhiteListDetails,
  featureKeyToTitleMap,
  allowlistFormSchema,
} from './constants';
import { useFeatureTogglesSettings } from './useFeatureTogglesSettings';
import { FeatureToggle } from './FeatureToggle';
import { AllowlistInputSectionTitleWrapper, TagsInputStyled } from './AllowlistForm.styles';

import { FeatureToggleFormsProps } from './types';
import { FeatureToggleStyled } from './FeaturePermissionsForm.styles';

export function AllowlistForm({
  dispatch,
  featureToggles,
  shopId,
}: Omit<FeatureToggleFormsProps, 'runningExperimentId'>): JSX.Element {
  const { featureTogglesDraft, isDirty, isValid, onChange, onSubmit } = useFeatureTogglesSettings({
    dispatch,
    featureToggles,
    shopId,
    validationSchema: allowlistFormSchema,
  });

  const onToggle = useCallback(
    (featureKey: keyof ShopTypes.ShopFeatureToggles, updateData: { enabled: boolean }) => {
      onChange(featureKey, {
        ...featureTogglesDraft?.[featureKey],
        ...updateData,
      });
    },
    [featureTogglesDraft]
  );

  return (
    <FormCard>
      <FormCard.Title>IP Allowlist</FormCard.Title>
      <FormCard.Button>
        <Button
          onClick={onSubmit}
          variant='primary'
          disabled={!isDirty || !isValid || !featureTogglesDraft}
        >
          Save changes
        </Button>
      </FormCard.Button>
      <FormCard.Content>
        <FeatureToggleStyled
          onChange={onToggle}
          featureKey={ShopFeature.apiDomainsWhitelist}
          featureTogglesDraft={featureTogglesDraft}
        >
          <FeatureToggle.Title>
            <AllowlistInputSectionTitleWrapper>
              <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Body}>
                {featureKeyToTitleMap[ShopFeature.apiDomainsWhitelist]}
              </Typography>
              <Tooltip value={apiWhiteListDetails}>
                <Icon name={AvailableIcons.TooltipQuestionmark} />
              </Tooltip>
            </AllowlistInputSectionTitleWrapper>
            <TagsInputStyled
              tags={featureTogglesDraft?.apiDomainsWhitelist?.whitelist || []}
              disabled={!featureTogglesDraft?.apiDomainsWhitelist?.enabled}
              placeholder='Enter domain URL'
              onChange={value =>
                onChange(ShopFeature.apiDomainsWhitelist, {
                  ...featureTogglesDraft[ShopFeature.apiDomainsWhitelist],
                  whitelist: value,
                })
              }
            />
          </FeatureToggle.Title>
        </FeatureToggleStyled>
        <FeatureToggleStyled
          onChange={onToggle}
          featureKey={ShopFeature.imageDownloadWhitelist}
          featureTogglesDraft={featureTogglesDraft}
        >
          <FeatureToggle.Title>
            <AllowlistInputSectionTitleWrapper>
              <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Body}>
                {featureKeyToTitleMap[ShopFeature.imageDownloadWhitelist]}
              </Typography>
              <Tooltip value={imageDownloadWhiteListDetails}>
                <Icon name={AvailableIcons.TooltipQuestionmark} />
              </Tooltip>
            </AllowlistInputSectionTitleWrapper>
            <TagsInputStyled
              tags={featureTogglesDraft?.imageDownloadWhitelist?.whitelist || []}
              disabled={!featureTogglesDraft?.imageDownloadWhitelist?.enabled}
              placeholder='62.149.124.22'
              onChange={value =>
                onChange(ShopFeature.imageDownloadWhitelist, {
                  ...featureTogglesDraft[ShopFeature.imageDownloadWhitelist],
                  whitelist: value,
                })
              }
            />
          </FeatureToggle.Title>
        </FeatureToggleStyled>
      </FormCard.Content>
    </FormCard>
  );
}
