/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { isEqual } from 'lodash';
import { ShopCatalogVertical } from 'src/services';
import { Button, ConfirmationDialog, Switch, Tooltip } from 'src/components-dummy';
import { useValidateSchema } from 'src/hooks';
import { editExistingCatalogFormActions } from './ExistingCatalogForm.actions';
import {
  ButtonStyled,
  ContentStyled,
  ExistingCatalogFormStyled,
  TextBoxStyled,
  SkeletonStyled,
  TogglesSection,
} from './ExistingCatalogForm.styles';
import { validationSchema } from './validationSchema';
import * as mapper from './mapper';
import { VerticalsSection } from './VerticalsSection';
import { IShopCatalogDraft, ExistingCatalogFormProps, FormStateValidation } from './types';
import { ChangePrimaryConfirmationDialog } from './ChangePrimaryConfirmationDialog';

const initialFormState: IShopCatalogDraft = {
  verticals: new Set(),
  name: '',
  isPrimary: false,
  isAugmentedSearchCatalog: false,
};

export const ExistingCatalogForm = ({
  onCancel,
  shopId,
  dispatch,
  catalog,
  mode,
  allCatalogs,
}: ExistingCatalogFormProps): JSX.Element => {
  const [showReplacePrimaryDialog, setShowReplacePrimaryDialog] = useState(false);

  const { nonPrimaryVerticals, canSetIsAugmentedSearch } = useMemo(
    () =>
      mapper.getCatalogsContext({
        catalogs: allCatalogs,
        currentCatalogId: catalog?.id,
      }),
    [catalog, allCatalogs]
  );

  const isConnectMode = useMemo(() => mode === 'connect', [mode]);

  const isLoading = useMemo(() => !isConnectMode && !catalog, [isConnectMode, catalog]);

  const initialDraftMapped = useMemo(() => {
    return catalog ? mapper.mapCatalogToDraft(catalog) : initialFormState;
  }, [catalog]);

  const [formState, setFormState] = useState(initialDraftMapped);
  const [isInProgress, setIsInProgress] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const { errors, validate, isValid } = useValidateSchema<FormStateValidation>({
    schema: validationSchema,
    validateOnStart: false,
  });

  const canSubmit = useMemo(() => isDirty && isValid, [isDirty, isValid]);

  const onFormStateChange = useCallback(
    (partial: Partial<IShopCatalogDraft>) => {
      const updatedState = { ...formState, ...partial };

      const dataToValidate: FormStateValidation = {
        ...updatedState,
        verticals: [...updatedState.verticals],
        isAugmentedSearchCatalog:
          updatedState.isAugmentedSearchCatalog !== undefined
            ? updatedState.isAugmentedSearchCatalog
            : !!formState.isAugmentedSearchCatalog,
      };

      validate({ dataToValidate });

      const newIsDirty = !isEqual(updatedState, initialDraftMapped);
      setIsDirty(newIsDirty);

      setFormState(updatedState);
    },
    [initialDraftMapped, formState]
  );

  const onNameChange = useCallback(
    (newName: string) => {
      onFormStateChange({ name: newName });
    },
    [onFormStateChange]
  );

  const onIsPrimaryChange = useCallback(
    (isPrimary: boolean) => {
      const updatedState: Partial<IShopCatalogDraft> = { isPrimary };

      if (showReplacePrimaryDialog) {
        setShowReplacePrimaryDialog(false);
      }

      if (isPrimary) {
        updatedState.verticals = formState.verticals;
        updatedState.isPrimary = isPrimary;
      }

      onFormStateChange(updatedState);
    },
    [onFormStateChange, formState, showReplacePrimaryDialog]
  );

  const onIsAugmentedSearchChange = useCallback(
    (isAugmentedSearchCatalog: boolean) => {
      const updatedState: Partial<IShopCatalogDraft> = { isAugmentedSearchCatalog };

      onFormStateChange(updatedState);
    },
    [onFormStateChange]
  );

  const onVerticalsChange = useCallback(
    (verticals: Set<ShopCatalogVertical>) => {
      onFormStateChange({ verticals });
    },
    [onFormStateChange]
  );

  const onSubmit = useCallback(() => {
    if (!canSubmit) return;

    const submit = async () => {
      setIsInProgress(true);

      let isSuccess = false;

      if (mode === 'connect') {
        try {
          await (
            dispatch(
              editExistingCatalogFormActions.connectCatalog({
                ...mapper.mapDraftToConnectCatalogPayload(formState),
                shopId,
              })
            ) as any
          ).unwrap();

          isSuccess = true;
        } catch (error: unknown) {
          console.error(error);
        }
      } else if (catalog?.id) {
        try {
          await (
            dispatch(
              editExistingCatalogFormActions.updateCatalog({
                ...mapper.mapDraftToConnectUpdatePayload(formState),
                shopId,
                id: catalog.id,
              })
            ) as any
          ).unwrap();

          isSuccess = true;
        } catch (error: unknown) {
          console.error(error);
        }
      }

      if (isSuccess) {
        dispatch(editExistingCatalogFormActions.updateIsDirty({ isDirty: false }));
        setIsDirty(false);
        onCancel();
      }

      setIsInProgress(false);
    };

    submit();
  }, [shopId, formState, canSubmit, mode, catalog?.id]);

  const onCheckSubmit = () => {
    if (formState.isPrimary) {
      const hasOccupiedVerticalByOtherPrimary = Array.from(formState.verticals).some(
        vertical => !nonPrimaryVerticals.has(vertical)
      );

      if (hasOccupiedVerticalByOtherPrimary) {
        setShowReplacePrimaryDialog(true);
        return;
      }

      onSubmit();
    } else {
      onSubmit();
    }
  };

  useEffect(() => {
    dispatch(editExistingCatalogFormActions.updateIsDirty({ isDirty }));
  }, [isDirty]);

  useEffect(() => {
    setFormState(initialDraftMapped);
    setIsDirty(false);
  }, [initialDraftMapped]);

  useEffect(() => {
    if (formState.isPrimary && formState.isAugmentedSearchCatalog && !canSetIsAugmentedSearch) {
      onIsAugmentedSearchChange(false);
    }
  }, [formState.isPrimary]);

  const disableSetAsAugmentedCatalogTooltip = !(formState.isPrimary && !canSetIsAugmentedSearch);

  const getOccupiedVerticals = () => {
    return Array.from(formState.verticals).filter(vertical => !nonPrimaryVerticals.has(vertical));
  };

  return (
    <>
      {showReplacePrimaryDialog && (
        <ChangePrimaryConfirmationDialog
          onCloseDialog={() => setShowReplacePrimaryDialog(false)}
          onApproveSubmit={onSubmit}
          occupiedVerticals={getOccupiedVerticals()}
        />
      )}
      <ExistingCatalogFormStyled onCancel={onCancel}>
        <ConfirmationDialog.Header>
          <ConfirmationDialog.Title>
            {isConnectMode ? 'Connect Existing' : 'Edit'} Catalogue
          </ConfirmationDialog.Title>
        </ConfirmationDialog.Header>

        <ConfirmationDialog.Content>
          <ContentStyled>
            {isLoading ? (
              <>
                <SkeletonStyled height={40} width={150} variant='rounded' />
                <SkeletonStyled height={100} variant='rounded' />
                <SkeletonStyled height={100} variant='rounded' />
              </>
            ) : (
              <>
                <TextBoxStyled
                  value={formState.name}
                  onChange={onNameChange}
                  placeholder='Write here'
                  label='Existing catalogue full name'
                  error={errors?.name?.message}
                  disabled={!isConnectMode}
                />

                <TogglesSection>
                  <Switch checked={formState.isPrimary} onChange={onIsPrimaryChange}>
                    <Switch.TitleTemplate>Set catalogue as primary </Switch.TitleTemplate>
                  </Switch>

                  <Tooltip
                    value='Text Search supports a single catalogue, please review your catalogue list'
                    disabled={disableSetAsAugmentedCatalogTooltip}
                  >
                    <Switch
                      checked={formState.isAugmentedSearchCatalog}
                      onChange={onIsAugmentedSearchChange}
                      disabled={formState.isPrimary && !canSetIsAugmentedSearch}
                    >
                      <Switch.TitleTemplate>
                        Is this catalogue used for Text Search?
                      </Switch.TitleTemplate>
                    </Switch>
                  </Tooltip>
                </TogglesSection>

                <VerticalsSection
                  selectedVerticals={formState.verticals}
                  onChange={onVerticalsChange}
                  errorMessage={errors.verticals?.message}
                  nonPrimaryVerticals={nonPrimaryVerticals}
                  isPrimaryCatalog={formState.isPrimary}
                />
              </>
            )}
          </ContentStyled>
        </ConfirmationDialog.Content>

        <ConfirmationDialog.Footer>
          <Button
            variant='primary'
            onClick={onCheckSubmit}
            disabled={!canSubmit}
            loading={isInProgress}
          >
            {isConnectMode ? 'Connect' : 'Save'}
          </Button>

          <ButtonStyled variant='tertiary' onClick={onCancel}>
            Cancel
          </ButtonStyled>
        </ConfirmationDialog.Footer>
      </ExistingCatalogFormStyled>
    </>
  );
};
