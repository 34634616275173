import { flatten } from 'lodash';
import { IThematicTagConditionsGroupDraft } from '../ThematicTagForm.types';

export function getUniqueCategoriesFromConditionsGroups(
  conditionsGroups: IThematicTagConditionsGroupDraft[]
) {
  return [
    ...new Set(
      flatten(
        conditionsGroups.map(conditionGroup =>
          conditionGroup.conditions.map(condition => condition.categoryKey)
        )
      )
    ),
  ];
}
