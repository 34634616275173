import { ValidationErrorItem } from 'joi';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import { useValidateSchema } from 'src/hooks';
import { RankingStrategyTypes, FeedDataFieldType, ShopDataField } from 'src/services';
import { DeleteTableRow, UpdateDataArgs } from 'src/components-dummy';
import { productWeightsDefault, orderOptions } from '../rankingDefaults';
import {
  UseProductWeightsDraftArgs,
  UseProductWeightsDraft,
  RankingWeightRecord,
  WeightsValidation,
  GetCellErrorArguments,
  RankingRowKey,
} from '../types';
import { syteCoreValuesSet, rankingSyteCoreConfigMap } from '../constants';
import { rankingValidationSchema } from '../schema';
import { rankingStrategyActionsMethods } from '../../Actions';

function getErrorKey({ propertyName, index }: { index: number; propertyName: string }): string {
  return `weights.${index}.${propertyName}`;
}

export function useProductWeightsDraft({
  weights,
  type,
  rowRef,
  dispatch,
  selectedSyteProduct,
  shopId,
  selectedVariantId,
  onDirtyChange,
  dataFields,
  disabled: featureDisabled,
  entityId,
  closeRankingStrategyDrawer,
}: UseProductWeightsDraftArgs): UseProductWeightsDraft {
  const [draftWeights, setDraftWeights] = useState(weights);
  const [draftType, setDraftType] = useState(type);
  const [scrollNewRowIntoView, setScrollNewRowIntoView] = useState(false);

  useEffect(() => {
    const newWeights = weights;
    setDraftWeights(newWeights);
  }, [weights]);

  useEffect(() => {
    const newType = type;
    setDraftType(newType);
  }, [type]);

  const validationData = useMemo(() => {
    return { weights: draftWeights };
  }, [draftWeights]);

  const dataFieldsLookupTable = useMemo(() => {
    return dataFields?.reduce(
      (result, current) => {
        const dataFieldTypesSet = new Set(current.types);
        const isNumericDataField =
          dataFieldTypesSet.has(FeedDataFieldType.Integer) ||
          dataFieldTypesSet.has(FeedDataFieldType.Float) ||
          dataFieldTypesSet.has(FeedDataFieldType.Long);

        if (isNumericDataField) {
          // eslint-disable-next-line no-param-reassign
          result[current.name] = current;
        }
        return result;
      },
      {} as Record<string, ShopDataField>
    );
  }, [dataFields]);

  const { errors, validate, isValid } = useValidateSchema<WeightsValidation>({
    schema: rankingValidationSchema,
  });

  const isDirty = useMemo(() => {
    return !isEqual(weights, draftWeights) || type !== draftType;
  }, [weights, draftWeights, type, draftType]);

  const canCancel = isDirty || Boolean(closeRankingStrategyDrawer);

  const addTableRow = (): void => {
    if (!draftWeights) return;

    const updatedState = [...draftWeights, productWeightsDefault];
    setScrollNewRowIntoView(true);
    setDraftWeights(updatedState);
  };

  const deleteTableRow = ({ rowIndex, tableData }: DeleteTableRow<RankingWeightRecord>): void => {
    const old = [...tableData];
    old.splice(rowIndex, 1);
    setDraftWeights(old);
  };

  const updateTableRow = ({
    rowIndex,
    columnId,
    value,
  }: UpdateDataArgs<RankingWeightRecord>): void => {
    if (!draftWeights) return;

    const newDraft = [...draftWeights];
    newDraft[rowIndex] = {
      ...draftWeights[rowIndex],
      [columnId]: value,
    };

    setDraftWeights(newDraft);
  };

  const dataFieldsDropdownOptions = useMemo(() => {
    return dataFieldsLookupTable
      ? Object.keys(dataFieldsLookupTable).map(name => ({
          value: name,
          text: dataFieldsLookupTable[name].displayName,
        }))
      : [];
  }, [dataFieldsLookupTable]);

  const getCellError = useCallback(
    ({ rowIndex, propertyName }: GetCellErrorArguments): ValidationErrorItem | undefined => {
      const cellKey = getErrorKey({
        index: rowIndex,
        propertyName,
      });
      const cellError = errors[cellKey];
      return cellError;
    },
    [errors]
  );

  const getCellProps = useCallback(
    (cell: any) => {
      let disabled = featureDisabled;
      let placeholder;
      let options;

      const { index: rowIndex } = cell.row;
      const propertyName = cell.column.id;

      let error: string | ValidationErrorItem | undefined | boolean = getCellError({
        rowIndex,
        propertyName,
      });

      const dataFieldValue = cell?.row?.original?.field;
      let isUsingInvalidDataField = false;

      const isSyteCore = syteCoreValuesSet.has(dataFieldValue);

      if (isSyteCore) {
        const syteCoreFieldConfig =
          rankingSyteCoreConfigMap[dataFieldValue as RankingStrategyTypes.SyteCoreRankingFields][
            propertyName as RankingRowKey
          ];
        disabled = featureDisabled || !syteCoreFieldConfig?.editable;
      }

      switch (propertyName) {
        case 'field': {
          placeholder = isSyteCore ? 'Syte Core' : 'Select data field';

          const mappedSelectedDataFields = cell.tableData.reduce(
            (accumulator: Record<string, boolean>, row: any) => {
              if (row.field !== dataFieldValue) accumulator[row.field] = true;
              return accumulator;
            },
            {}
          );

          options = dataFieldsDropdownOptions.map(option => ({
            ...option,
            isDisabled: !!mappedSelectedDataFields[option.value],
          }));

          break;
        }
        case 'order': {
          options = orderOptions;
          placeholder = 'Select order';
          break;
        }
        case 'name': {
          placeholder = 'Type name';
          const fieldErrorKey = getErrorKey({
            index: rowIndex,
            propertyName: 'field',
          });
          isUsingInvalidDataField = errors[fieldErrorKey]?.type === 'any.custom';
          break;
        }
        default:
          break;
      }

      if ((error as ValidationErrorItem)?.type === 'any.custom') {
        error = (error as ValidationErrorItem)?.message;
      } else {
        error = !!error;
      }

      return { error, disabled, placeholder, options, isUsingInvalidDataField };
    },
    [getCellError, dataFieldsDropdownOptions, dataFieldsLookupTable]
  );

  const onSubmit = (): void => {
    if (!draftWeights) return;
    if (isValid) {
      dispatch(
        rankingStrategyActionsMethods.updateWeights({
          shopId,
          syteProduct: selectedSyteProduct,
          weights: draftWeights,
          variantId: selectedVariantId,
          entityId,
          type: draftType,
        })
      );
      closeRankingStrategyDrawer?.();
    }
  };

  const onDiscard = (): void => {
    setDraftWeights(weights);
    closeRankingStrategyDrawer?.();
  };

  useEffect((): void => {
    if (scrollNewRowIntoView) {
      rowRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      setScrollNewRowIntoView(false);
    }
  }, [scrollNewRowIntoView]);

  useEffect(() => {
    validate({
      dataToValidate: validationData,
      context: { dataFieldsLookupTable },
    });
  }, [validate, dataFieldsLookupTable, validationData]);

  useEffect((): void => {
    onDirtyChange?.({ isDirty });
  }, [isDirty]);

  return {
    draftWeights,
    setDraftWeights,
    draftType,
    setDraftType,
    addTableRow,
    deleteTableRow,
    updateTableRow,
    errors,
    isValid,
    getCellProps,
    onSubmit,
    isDirty,
    dataFieldsDropdownOptions,
    onDiscard,
    canCancel,
  };
}
