import { Dispatch } from '../../types';

/* --= Action Types =-- */
export enum ProfileMenuActionTypes {
  LogoutRequest = 'ProfileMenu/LogoutRequest',
  NavigateTo = 'ProfileMenu/NavigateTo',
}

/* --= Action Interfaces =-- */
interface LogoutRequestAction {
  type: typeof ProfileMenuActionTypes.LogoutRequest;
}

interface NavigateToAction {
  type: typeof ProfileMenuActionTypes.NavigateTo;
  payload: { navigateTo: string };
}

export type ProfileMenuAction = LogoutRequestAction | NavigateToAction;

/* --= Actions =-- */
export const profileMenuActions = {
  logoutRequest: (): LogoutRequestAction => {
    return {
      type: ProfileMenuActionTypes.LogoutRequest,
    };
  },
  navToAction: (path: string): NavigateToAction => {
    return {
      type: ProfileMenuActionTypes.NavigateTo,
      payload: { navigateTo: path },
    };
  },
};

/* --= Methods =-- */
export const profileMenuActionMethods = {
  logOut() {
    return async (dispatch: Dispatch): Promise<void> => {
      dispatch(profileMenuActions.logoutRequest());
    };
  },
  navigateTo: (path: string) => (dispatch: Dispatch) => {
    dispatch(profileMenuActions.navToAction(path));
  },
};
