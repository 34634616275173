import { TableHeadRow } from '../types/table-head-row';

interface TableColumns {
  [key: string]: TableHeadRow;
}

export const convertArrayToObject = (
  inputArray: Array<{ [key: string]: TableHeadRow | undefined }>
): TableColumns => {
  const convertedObject: TableColumns = {};

  inputArray.forEach(item => {
    const keys = Object.keys(item);
    keys.forEach(key => {
      const value = item[key];
      if (!value) return;

      convertedObject[key] = {
        header: key,
        accessor: value.accessor,
        width: value.width,
        minWidth: value.minWidth,
        maxWidth: value.maxWidth,
      };
    });
  });

  return convertedObject;
};
