import styled from '@emotion/styled';
import { ActionIcon, Select, TextBox, Typography } from 'src/components-dummy';

export const TagRowStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-bottom: 40px;
  position: relative;

  .text-box-error-message {
    position: absolute;
  }
`;

export const TagFieldSelectStyled = styled(Select)`
  margin-right: 4px;
`;

export const TagRowTypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  white-space: nowrap;
`;

export const TrashIconStyled = styled(ActionIcon)`
  margin-left: auto;
`;

export const CustomTranslationTextBox = styled(TextBox)`
  max-width: 294px;
  width: 100%;
`;
