import { MutableRefObject, useCallback, useRef, useState } from 'react';
import { generatePath } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch } from 'src/components-bl/types';
import { galleryFormActions } from '../Actions';
import { GalleryFormApiRef } from '../GalleryForm';

interface UseGalleryFormProps extends RoutedComponentProps {
  shopId: number;
  dispatch: Dispatch;
}

interface UseGalleryFormReturnType {
  formApiRef: MutableRefObject<GalleryFormApiRef | null>;
  isSubmitInProgress: boolean;
  onSubmit: () => Promise<void>;
  navigateToList: () => void;
}

export function useGalleryForm({
  shopId,
  dispatch,
  permittedRouteMap,
}: UseGalleryFormProps): UseGalleryFormReturnType {
  const formApiRef = useRef<GalleryFormApiRef | null>(null);
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

  const navigateToList = useCallback(() => {
    if (permittedRouteMap.galleriesList) {
      dispatch(
        galleryFormActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.galleriesList.path, { shopId }),
        })
      );
    }
  }, [dispatch, shopId, permittedRouteMap.galleriesList]);

  const onSubmit = useCallback(async () => {
    if (!formApiRef.current) {
      return;
    }

    setIsSubmitInProgress(true);

    try {
      await formApiRef.current.submit();

      navigateToList();
    } catch (error) {
      setIsSubmitInProgress(false);
      console.error('CreateGalleryPage', error);
    }
  }, [navigateToList]);

  return {
    formApiRef,
    isSubmitInProgress,
    onSubmit,
    navigateToList,
  };
}
