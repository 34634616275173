import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { SyteLayout, filtersService } from 'src/services';

export const editFilterContainerActions = {
  navigateTo: createAction<{ navigateTo: string }>('EditFilterContainer/NavigateTo'),
  errorNotification: createAction<{ customMessage: string }>(`EditFilterContainer/error`),
  getFilter: createAsyncThunk(
    'EditFilterContainer/GetFilter',
    async (
      {
        shopId,
        syteLayout,
        filterId,
      }: { shopId: number; syteLayout: SyteLayout; filterId: string },
      { rejectWithValue }
    ) => {
      try {
        const filter = await filtersService.getFilter({ shopId, syteLayout, filterId });
        return filter;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getFilterSet: createAsyncThunk(
    'EditFilterContainer/GetFilterSet',
    async (
      { shopId, syteLayout }: { shopId: number; syteLayout: SyteLayout },
      { rejectWithValue }
    ) => {
      try {
        const filterSet = await filtersService.getFilterSet({ shopId, syteLayout });
        return { filterSet };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
