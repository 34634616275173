import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import './NavButton.scss';

export interface NavButtonProps {
  onBeforeNavigation?: (href: string) => void;
  href?: string;
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
}

export const NavButton = ({
  href = '',
  onBeforeNavigation,
  disabled,
  className,
  children,
}: NavButtonProps): JSX.Element => {
  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled || onBeforeNavigation) {
      event.preventDefault();
    }
    if (onBeforeNavigation) {
      onBeforeNavigation?.(href);
    }
  };

  return (
    <NavLink
      className={classNames('syte-nav-button', className, disabled && 'syte-nav-button-disabled')}
      activeClassName='syte-nav-button-active'
      to={href}
      onClick={handleOnClick}
    >
      {children}
    </NavLink>
  );
};
