import React from 'react';
import {
  AvailableIcons,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { NotificationContentStyled, NotificationStyled } from './ProductDetails.styled';

export function NoTags() {
  return (
    <NotificationStyled>
      <Icon name={AvailableIcons.CircleWarning} />
      <NotificationContentStyled>
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
          Visual AI tags are currently unavailable
        </Typography>
      </NotificationContentStyled>
    </NotificationStyled>
  );
}
