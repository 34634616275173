import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const UnreadCountStyled = styled.div<{ disabled: boolean }>`
  transition: opacity 0.2s;
  opacity: ${props => (props.disabled ? '0' : '1')};
  user-select: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.custom.red};
  border-radius: 9px;
  padding: 0 5px;

  align-content: center;
  display: flex;
  justify-content: center;
  outline: ${({ theme }) => theme.palette.common.white};

  position: absolute;
  top: -9px;
  left: 6px;
`;

export const TypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
`;
