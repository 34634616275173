import React, { useEffect, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { NumericInputRangeProps, OnInputChangeArguments } from './types';
import { TypographyType, TypographyVariant } from '../Typography';
import {
  NumericInputRangeStyled,
  NumericInputBaseStyled,
  SymbolTypographyStyled,
} from './NumericInputRange.styles';

export const NumericInputRange = ({
  className,
  staticSymbol,
  decimals,
  values,
  onChange,
  disabled,
  error,
  success,
  onClick,
  label,
  autoFocus,
  onBlur,
}: NumericInputRangeProps): JSX.Element => {
  const fromValueRef = useRef<HTMLInputElement | null>(null);

  const onFocus = useCallback(() => {
    const [fromValue, toValue] = values;
    if (autoFocus && !fromValue && !toValue) {
      fromValueRef?.current?.focus();
    }
  }, [autoFocus, values]);

  const onInputChange = ({ value, index }: OnInputChangeArguments): void => {
    const valuesCopy = [...values];
    valuesCopy[index] = value;
    onChange?.(valuesCopy);
    onFocus();
  };

  useEffect(() => {
    onFocus();
  }, [onFocus]);

  return (
    <NumericInputRangeStyled
      className={classNames('syte-numeric-input-range', className)}
      error={error}
      success={success}
      onClick={onClick}
      label={label}
      disabled={disabled}
    >
      <NumericInputBaseStyled
        onChange={value => onInputChange({ index: 0, value })}
        value={values[0]}
        decimals={decimals}
        disabled={disabled}
        autoFocus={autoFocus}
        inputRef={fromValueRef}
        onBlur={onBlur}
      />

      <SymbolTypographyStyled
        type={TypographyType.Paragraph}
        variant={TypographyVariant.MediumRegular}
      >
        to
      </SymbolTypographyStyled>
      <NumericInputBaseStyled
        onChange={value => onInputChange({ index: 1, value })}
        value={values[1]}
        staticSymbol={staticSymbol}
        decimals={decimals}
        disabled={disabled}
        onBlur={onBlur}
      />
    </NumericInputRangeStyled>
  );
};
