import { RefObject } from 'react';
import { UpdateReqStatus } from 'src/app-state-types';
import {
  FilterSetConfiguration,
  UpdateFilterSetSegmentPayload,
} from 'src/services/src/service/types/filters';
import { ShopDataField } from 'src/services/src/service/types';
import { Dispatch } from '../types';
import { FormApiRef } from '../AugmentedSearch';

export * from 'src/services/src/service/types/filters';
export * from 'src/components-bl/FiltersList/types';

export interface DeepTagsFiltersConfigProps {
  onSubmitDispatcher: (payload: UpdateFilterSetSegmentPayload) => void;
  shopId: number;
  filterSet: FilterSetConfiguration;
  isDirtyDispatcher: (isDirty: boolean) => void;
  rawDataFields: ShopDataField[];
  unSavedDataFields: ShopDataField[];
  dispatch: Dispatch;
  updateStatus: UpdateReqStatus;
  formApiRef: RefObject<FormApiRef>;
  onSubmitStatusChange: (canSubmit: boolean) => void;
}

export enum FieldToValidate {
  minBuckets = 'minBuckets',
}

export type ValidationErrors = Record<FieldToValidate, string[]>;
