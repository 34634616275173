import React, { ReactNode } from 'react';
import './SectionBox.scss';
import classNames from 'classnames';
import { Typography, TypographyType, TypographyVariant } from '../Typography';

export interface SectionBoxProps {
  title?: string;
  children: ReactNode | ReactNode[];
  divider?: boolean;
  className?: string;
  onClick?: () => void;
}

export const SectionBox = ({
  title,
  children,
  className,
  divider = true,
  onClick,
}: SectionBoxProps): JSX.Element => {
  return (
    <div className={classNames('syte-section-box', className)} onClick={onClick}>
      {title && (
        <div
          className={classNames('syte-section-box-header', {
            'syte-section-box-header-divider': divider,
          })}
        >
          <Typography
            className='syte-section-box-title'
            type={TypographyType.Heading}
            variant={TypographyVariant.ExtraSmallBold}
          >
            {title}
          </Typography>
        </div>
      )}
      {children}
    </div>
  );
};
