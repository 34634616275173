import { Constants } from '../../services';

const translations: Record<Constants.Vertical, string> = {
  [Constants.Vertical.General]: 'General',
  [Constants.Vertical.Fashion]: 'Fashion',
  [Constants.Vertical.HomeDecor]: 'Home Decor',
  [Constants.Vertical.DIY]: 'DIY',
  [Constants.Vertical.Jewelry]: 'Jewelry',
};

export const translateVertical = (vertical: Constants.Vertical): string => {
  return translations[vertical] || vertical || '(No Vertical)';
};
