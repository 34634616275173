import { NotificationType } from '../../../../components-dummy';
import { AppNotification } from '../../../../app-types';

export enum SocialDiscoveryActionTypes {
  ConnectSocialCollectionError = 'SocialDiscovery/ConnectSocialCollectionError',
  GeneralError = 'SocialDiscovery/GeneralError',
  SavePublicPosts = 'SocialDiscovery/SavePublicPosts',
  DisconnectSocialCollectionSuccess = 'SocialDiscovery/DisconnectCollection',
}

export interface ToastNotification {
  type: NotificationType;
  messageKey: AppNotification['messageKey'];
  timestamp?: number;
}

export const socialDiscoveryToasts = {
  [SocialDiscoveryActionTypes.ConnectSocialCollectionError]:
    "We couldn't find a connected Instagram account. Please go back to step 1 and connect your Instagram account.",
  [SocialDiscoveryActionTypes.GeneralError]:
    'Something went wrong. Please, reload page and try again.',
  [SocialDiscoveryActionTypes.SavePublicPosts]: 'Changes have been saved successfully!',
  [SocialDiscoveryActionTypes.DisconnectSocialCollectionSuccess]:
    'Disconnected social source successfully!',
};

export enum CollectionStatus {
  ConnectedCollection = 'connected_collection',
  UnConnectedCollection = 'unconnected_collection',
  NoCollection = 'no_collection',
}

export type ConnectionStatusResponse =
  | { status: CollectionStatus.ConnectedCollection }
  | { status: CollectionStatus.UnConnectedCollection; connectionLink: string }
  | { status: CollectionStatus.NoCollection };

export type ConnectionLinkResponse = { connectionLink: string; socialCollectionId: number };
