import React from 'react';
import classNames from 'classnames';
import { WarningIndication } from 'src/components-dummy';
import { SystemChangeIndicationIconProps } from '../types';

export const SystemChangeIndicationIcon = ({
  isSystemChange,
  className,
}: SystemChangeIndicationIconProps): JSX.Element | null => {
  return isSystemChange ? (
    <div className={classNames('syte-system-change-indication-icon', className)}>
      <WarningIndication
        tooltipValue='Turned off by the system since it is invalid'
        disableTooltip={!isSystemChange}
        show={isSystemChange}
        className='syte-system-change-indication-icon-tooltip'
        position='top left'
      />
    </div>
  ) : null;
};
