import React, { useMemo } from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { CreateFilterPage } from 'src/components-bl/Filters/components/CreateFilterPage';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { FilterDataSource, SyteLayout, ShopDataFieldsSupportedFeatures } from 'src/services';
import { useDataFieldsBySupportedFeature } from 'src/components-bl/hooks';

interface CreateFilterContainerProps extends RoutedComponentProps {
  syteLayout: SyteLayout;
  hasReachedMaxAllowedFiltersLimit: boolean | undefined;
  hasReachedMaxAllowedCustomOrderFiltersLimit?: boolean;
}

export const CreateFilterContainer = ({
  syteLayout,
  permittedRouteMap,
  hasReachedMaxAllowedFiltersLimit,
  hasReachedMaxAllowedCustomOrderFiltersLimit,
}: CreateFilterContainerProps): JSX.Element => {
  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const filters = useAppSelector(state => state.filters?.filters);
  const dataFields = useAppSelector(state => state.dataFields?.dataFields);

  const dispatch = useAppDispatch();

  const { supportedDataFields } = useDataFieldsBySupportedFeature({
    dataFields,
    feature: ShopDataFieldsSupportedFeatures.Filters,
  });

  const dataFieldsFromOtherFilters = useMemo(() => {
    const usedDataFields = filters
      ?.filter(filter => filter.dataSource === FilterDataSource.Catalog)
      .map(filter => filter.sourceField);
    return usedDataFields ? new Set(usedDataFields) : undefined;
  }, [filters]);

  return (
    <CreateFilterPage
      shopId={shopId}
      syteLayout={syteLayout}
      dispatch={dispatch}
      permittedRouteMap={permittedRouteMap}
      availableDataFields={supportedDataFields}
      hasReachedMaxAllowedFiltersLimit={hasReachedMaxAllowedFiltersLimit}
      hasReachedMaxAllowedCustomOrderFiltersLimit={hasReachedMaxAllowedCustomOrderFiltersLimit}
      dataFieldsFromOtherFilters={dataFieldsFromOtherFilters}
    />
  );
};
