import { AvailableIcons } from 'src/components-dummy';
import { DomainEntityPath, ShopDataField } from 'src/services';
import { flatten, sortBy, uniqBy } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import {
  ChangedEntity,
  DomainNode,
  GetOrCreateDomainGroupArguments,
  SubDomainGroup,
  ParentDomain,
  FieldValueWithEntity,
} from './types';

import { DiffsCardChanges, DiffsCardProps } from '../DiffsCard';
import { getDomainGroupMap } from './domain-changes-settings/domain-groups';
import { diffValueDisplayHelpers } from './domain-changes-settings';

const sortSubDomainByField: keyof DiffsCardProps = 'title';

const getFallbackSubDomainSettings = (domainKey: DomainEntityPath) => {
  const subDomain: SubDomainGroup = {
    changedEntities: [],
    domainKey,
    getCardTitle() {
      return domainKey;
    },
    pathToFieldSchemaMap: {},
    fieldsSchema: {},
  };

  return subDomain;
};

const findOrAddSubDomain = (domainParent: ParentDomain, domainKey: DomainEntityPath) => {
  const domainGroupKey = domainParent.domainKey;

  let subDomainObject = domainParent.subDomains.find(
    subDomain => subDomain.domainKey === domainKey
  );

  if (!subDomainObject) {
    console.warn(
      `ChangesPreview: could not find sub domain ${domainKey} settings for domain group ${domainGroupKey}`
    );

    subDomainObject = getFallbackSubDomainSettings(domainKey);
    domainParent.subDomains.push(subDomainObject);
  }

  return subDomainObject;
};

const findOrAddDomainGroup = ({
  domainGroupMap,
  domainKey,
  domainGroupKey,
}: GetOrCreateDomainGroupArguments): DomainNode => {
  let domainGroup = domainGroupMap.get(domainGroupKey);
  if (!domainGroup) {
    console.warn(
      `ChangesPreview: could not find domain settings for domain group ${domainGroupKey}`
    );

    const isDomainWithEntities = domainKey === domainGroupKey;
    if (isDomainWithEntities) {
      const defaultSubDomainSettings = getFallbackSubDomainSettings(domainKey);
      domainGroup = {
        title: domainKey,
        icon: AvailableIcons.Setting,
        ...defaultSubDomainSettings,
      } as SubDomainGroup;
    } else {
      domainGroup = {
        domainKey,
        title: domainKey,
        icon: AvailableIcons.Setting,
        subDomains: [],
      } as ParentDomain;
    }

    domainGroupMap.set(domainKey, domainGroup);
  }

  return domainGroup;
};

export const convertEntityChangesToDomainGroups = (
  entitiesChanges: ChangedEntity[]
): DomainNode[] => {
  const domainGroupMap = getDomainGroupMap();

  entitiesChanges.forEach(changedEntity => {
    const { domainGroupKey, domain: domainKey } = changedEntity;

    const domainGroup = findOrAddDomainGroup({
      domainGroupMap,
      domainKey,
      domainGroupKey,
    });

    if ('subDomains' in domainGroup) {
      const subDomainObject = findOrAddSubDomain(domainGroup, domainKey);
      subDomainObject.changedEntities.push(changedEntity);
    } else {
      domainGroup.changedEntities.push(changedEntity);
    }
  });

  const domainGroups = Array.from(domainGroupMap.values());

  return domainGroups;
};

// Prepare entity's old and new values for display by using relevant schema.
// Only relevant for update/changed action (not insert / delete)
// Some fields are grouped and displayed as single changes (schema relevantFieldPaths)
const convertEntityChangesToDiffCardChanges = (
  changedEntity: ChangedEntity,
  subDomain: SubDomainGroup,
  dataFields?: ShopDataField[]
) => {
  const { changes: groupedChanges, oldEntity, newEntity } = changedEntity;

  const allKeys = Object.keys(subDomain.pathToFieldSchemaMap).sort((a, b) => a.length + b.length);

  const mappedChanges = groupedChanges
    .reduce((prev, groupedChange) => {
      const { changes } = groupedChange;

      const changesWithSchemaRaw = changes.map(change => {
        const fieldKey =
          allKeys.find(key => new RegExp(key).test(change.valuePath)) || change.valuePath;

        let fieldSchema = subDomain.pathToFieldSchemaMap[fieldKey];

        if (!fieldSchema) {
          fieldSchema = {
            displayName: change.valuePath,
          };
        }

        return { change, fieldSchema };
      });

      // some fields are grouped and displayed together, configured by relevantFieldPaths in schema
      const changesWithSchemaGrouped = uniqBy(changesWithSchemaRaw, change => change.fieldSchema);

      const convertedChanges = changesWithSchemaGrouped.map(({ change, fieldSchema }) => {
        if (fieldSchema.shouldSkipField?.(change)) {
          return null;
        }

        const {
          valuePath,
          oldValue: originalOldValue,
          newValue: originalNewValue,
          changedAt,
          changedBy,
          isSystemChange,
        } = change;

        const oldEntityParameters: FieldValueWithEntity = {
          entity: oldEntity,
          value: originalOldValue,
          valuePath,
        };

        const newEntityParameters: FieldValueWithEntity = {
          entity: newEntity,
          value: originalNewValue,
          valuePath,
        };

        const oldValueJsx = diffValueDisplayHelpers.getFieldDisplayValue(
          fieldSchema,
          oldEntityParameters,
          false,
          dataFields
        );

        const newValueJsx = diffValueDisplayHelpers.getFieldDisplayValue(
          fieldSchema,
          newEntityParameters,
          true,
          dataFields
        );

        const name = diffValueDisplayHelpers.getFieldDisplayName(
          fieldSchema,
          change,
          oldEntity,
          newEntity,
          dataFields
        );

        const result: DiffsCardChanges = {
          id: uuidV4(),
          name,
          oldValue: oldValueJsx,
          newValue: newValueJsx,
          changedBy,
          changedAt: new Date(changedAt),
          isSystemChange,
        };

        return result;
      });
      return prev.concat(convertedChanges as any);
    }, [] as DiffsCardChanges[])
    .filter(Boolean);

  const sortByField: keyof DiffsCardChanges = 'name';

  const sortedConvertedChanges = sortBy(mappedChanges, sortByField);

  return sortedConvertedChanges;
};

const convertEntityChangesToDiffCardProps = (
  changedEntity: ChangedEntity,
  subDomain: SubDomainGroup,
  dataFields?: ShopDataField[]
): Partial<DiffsCardProps> => {
  const { actionType, changedAt, lastChangedBy: changedBy, totalChangesCount } = changedEntity;

  const title = subDomain.getCardTitle(changedEntity, dataFields);

  const convertedChanges = convertEntityChangesToDiffCardChanges(
    changedEntity,
    subDomain,
    dataFields
  );

  return {
    title,
    actionType,
    changedAt,
    changedBy,
    changes: convertedChanges,
    totalChangesCount,
  };
};

export const convertDomainGroupChangesToDiffCardProps = (
  domainGroup: DomainNode | SubDomainGroup,
  dataFields?: ShopDataField[]
): Array<Partial<DiffsCardProps>> => {
  let subDomainsToRender: SubDomainGroup[];

  // render entities and their changes either directly in the domain group (section with multiple cards)
  // or under sub domains (the cards / entities / independent fields), depends on requirement.
  if ('subDomains' in domainGroup) {
    subDomainsToRender = domainGroup.subDomains;
  } else {
    subDomainsToRender = [domainGroup];
  }

  const allDiffCardsProps = subDomainsToRender.flatMap(subDomain => {
    const subDomainCardsProps = subDomain.changedEntities.map(changedEntity => {
      try {
        const props = convertEntityChangesToDiffCardProps(changedEntity, subDomain, dataFields);
        return props;
      } catch (error) {
        console.error('Failed to render entity change', error, changedEntity);
        return {
          title: 'Failed to present this change, please contact administrator',
        };
      }
    });
    return sortBy(subDomainCardsProps, sortSubDomainByField);
  });

  return allDiffCardsProps;
};

export function combineRelatedDomainKeysToString(domainGroup: DomainNode): string {
  const parentDomainKey = domainGroup.domainKey;
  const subDomainKeys =
    (domainGroup as ParentDomain).subDomains?.map(subDomainGroup => subDomainGroup.domainKey) || [];
  return [...new Set([parentDomainKey, ...subDomainKeys])].join(',');
}

export function separateRelatedDomainKeysToDomainEntityPath(
  commaSeparatedDomainsSubdomains: string[]
): DomainEntityPath[] {
  return flatten(
    commaSeparatedDomainsSubdomains.map(domain => domain.split(',') as DomainEntityPath[])
  );
}
