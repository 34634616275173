import styled from '@emotion/styled';
import { LexiconItemEditAutoComplete } from 'src/components-bl/Lexicon/components/LexiconTable/Components/LexiconItemAutoComplete';
import { Typography } from 'src/components-dummy';

export const ThematicTagRowStyled = styled.div`
  display: flex;
  gap: 4px;
  padding-bottom: 30px;
`;

export const LexiconItemEditAutoCompleteStyled = styled(LexiconItemEditAutoComplete)``;

export const TagFieldAutocompleteContainer = styled.div`
  width: 100%;
`;

export const TagFieldAutocompleteLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;
