import { AvailableAttributeKeysByCategoryMap } from '../ThematicTagForm.types';

export function addNewCategoriesAndAttributesToMap({
  attributesPerCategoryArray,
  categories,
  availableAttributeKeysByCategory: currentAvailableAttributeKeysByCategory,
}: {
  attributesPerCategoryArray: string[][];
  categories: string[];
  availableAttributeKeysByCategory: AvailableAttributeKeysByCategoryMap;
}): AvailableAttributeKeysByCategoryMap {
  return categories.reduce(
    (updatedAvailableAttributeKeysByCategoryMap, categoryKey, index) => {
      return {
        ...updatedAvailableAttributeKeysByCategoryMap,
        [categoryKey]: attributesPerCategoryArray[index],
      };
    },
    { ...currentAvailableAttributeKeysByCategory }
  );
}
