import styled from '@emotion/styled';

export const StyledBlurContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
`;

export const StyledBlur = styled.div`
  width: 100%;
  height: 100%;
  backdrop-filter: blur(50px);
  position: absolute;
  border-radius: 24px;
`;

export const StyledImageContainer = styled.div<{
  isLandscape: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: crosshair;
  border-radius: ${props => (props.isLandscape ? '0' : '24px')};
  position: absolute;
  width: 100%;
  height: ${props => (props.isLandscape ? 'fit-content' : '100%')};
`;
