import { useEffect } from 'react';
import { featureTogglesWebSocketService, FeatureTogglesUpdateEvent } from 'src/services';
import { Dispatch } from 'src/components-bl';
import { useFeatureTogglesWSActions } from './useFeatureTogglesWS.actions';

export const useFeatureTogglesWS = ({
  dispatch,
  shopId,
}: {
  dispatch: Dispatch;
  shopId: number | undefined;
}): void => {
  const onFeatureTogglesUpdate = (payload: FeatureTogglesUpdateEvent) => {
    if (payload.data.shopId === shopId) {
      dispatch(useFeatureTogglesWSActions.featureTogglesUpdate(payload.data));
    }
  };

  useEffect(() => {
    if (shopId) {
      featureTogglesWebSocketService.subscribeToUpdateEvents(onFeatureTogglesUpdate);
    }

    return () => {
      featureTogglesWebSocketService.clearListeners();
    };
  }, [shopId]);
};
