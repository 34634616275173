import { useCallback, useMemo } from 'react';
import {
  DataFieldLookupTable,
  getDataFieldConditionOperators,
} from 'src/components-bl/MerchandisingRules/components/useDataFieldsLookupTable';
import { UseRuleValueProps, UseRuleValueReturnType } from './types';
import { SubRuleOptionalValues } from '../../../../../services/src/service/types/shops';
import { getFieldFromDataFields } from '../helpers/getFieldFromDataFields';

export const useRuleValue = ({
  data,
  onChange,
  dataFields,
}: UseRuleValueProps): UseRuleValueReturnType => {
  const { field, operator } = data;

  const conditionData = {
    conditionType: operator,
    conditionValue: data.values,
    dataField: field,
  };
  const selectedDataField = useMemo(
    () => getFieldFromDataFields(data?.field, dataFields),
    [data?.field, dataFields]
  );

  const dataFieldLookupTable = useMemo(() => {
    const result: DataFieldLookupTable = {};

    const conditionTypes = getDataFieldConditionOperators({
      types: selectedDataField?.types || [],
    });
    if (!selectedDataField) {
      return {};
    }
    result[selectedDataField.name] = { ...selectedDataField, conditionTypes };
    return result;
  }, [data.field]);

  const getDataFieldByName = useCallback(
    (name?: string) => {
      if (!name) {
        return undefined;
      }
      return dataFieldLookupTable[name];
    },
    [dataFieldLookupTable]
  );

  const onValuesChange = useCallback(
    (selectedValues: SubRuleOptionalValues) => {
      onChange({ ...data, values: selectedValues });
    },
    [operator, onChange]
  );

  return {
    getDataFieldByName,
    selectedDataField,
    conditionData,
    onValuesChange,
  };
};
