import React, { useRef } from 'react';
import { Page, Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { ConnectedRankingStrategy } from './components';
import './RankingContainer.scss';

const COMPONENT_NAME = 'RankingContainer';

export const RankingContainer = (): JSX.Element | null => {
  const formHeaderRef = useRef<HTMLDivElement>(null);

  return (
    <Page className='ranking-container'>
      <Page.Header>
        <Page.Title>
          <Typography
            className='ranking-container-title'
            type={TypographyType.Heading}
            variant={TypographyVariant.ExtraSmallBold}
          >
            Ranking Strategy
          </Typography>
        </Page.Title>
        <div className='ranking-container-buttons-section' ref={formHeaderRef} />
      </Page.Header>
      <Page.Content>
        <ConnectedRankingStrategy prefixId={COMPONENT_NAME} formHeaderElementRef={formHeaderRef} />
      </Page.Content>
    </Page>
  );
};
