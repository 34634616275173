import React, { useState } from 'react';
import './ModerationModal.scss';
import {
  Button,
  OverlayModal,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { SocialPostTile } from '../SocialPostTile/SocialPostTile';
import { useModerationModalState } from './useModerationModalState';
import { ModerationModalProps } from './types';
import { DisconnectConfirmationModal } from '../DisconnectConfirmationModal/DisconnectConfirmationModal';

export const ModerationModal = ({
  show,
  onClose,
  onSubmit,
  moderatedPosts,
}: ModerationModalProps): JSX.Element => {
  const { state, hasSelectedPost, handleSelect, handleSubmit } = useModerationModalState({
    show,
    moderatedPosts,
    onSubmit,
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  return (
    <>
      <OverlayModal show={show} className='syte-dialog'>
        <div className='moderation-modal' data-testid='moderation-modal'>
          <div className='modal-header'>
            <Typography type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
              Select post to add
            </Typography>
            <div onClick={() => setShowConfirmationModal(true)} className='disconnect'>
              <Typography>Disconnect</Typography>
            </div>
          </div>
          <div className='modal-body'>
            <div className='tiles'>
              {moderatedPosts.map(post => (
                <SocialPostTile
                  key={post.id}
                  imageSrc={post.imageUrl}
                  account={post.source.termValue}
                  date={new Date(post.publishedAt)}
                  isSelected={state.selectedPosts?.includes(post.id)}
                  onClick={() => handleSelect(post.id)}
                />
              ))}
            </div>
          </div>
          <div className='modal-footer'>
            <Button variant='tertiary' onClick={onClose}>
              Close
            </Button>
            <Button variant='primary' disabled={!hasSelectedPost} onClick={handleSubmit}>
              Select
            </Button>
          </div>
        </div>
      </OverlayModal>
      <DisconnectConfirmationModal
        show={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
      />
    </>
  );
};
