import React from 'react';
import { EllipsisWithTooltip, TypographyVariant } from '../../../../../components-dummy';
import { AdData } from '../../../../../services';
import {
  DiscountPrice,
  DiscountPriceWrapper,
  OriginalPrice,
  ProductDescriptionText,
} from './ProductTile.styles';

export function ProductPrice({
  price,
  originalPrice,
}: Pick<AdData, 'price' | 'originalPrice'>): JSX.Element {
  if (price === originalPrice)
    return (
      <ProductDescriptionText variant={TypographyVariant.SmallRegular}>
        <EllipsisWithTooltip tooltipText={price}>{price}</EllipsisWithTooltip>
      </ProductDescriptionText>
    );
  return (
    <EllipsisWithTooltip tooltipText={`${price} ${originalPrice}`}>
      <DiscountPriceWrapper>
        <DiscountPrice variant={TypographyVariant.SmallRegular}>{price}</DiscountPrice>
        <OriginalPrice variant={TypographyVariant.SmallRegular}>{originalPrice}</OriginalPrice>
      </DiscountPriceWrapper>
    </EllipsisWithTooltip>
  );
}
