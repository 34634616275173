import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dispatch } from 'src/components-bl/types';
import { SlimShopVersion, systemUser } from 'src/services';
import { orderBy } from 'lodash';
import { format } from 'date-fns';
import { TableV2 } from 'src/components-dummy';
import { versionsTableActions } from './Actions';
import { IVersionsTableRow } from './types';
import { StyledSkeleton, TableWrapperStyled } from './VersionsTable.styles';
import { tableColumns, tableColumnsArray } from './tableColumns';
import { VersionsTableRow } from './components';

export interface VersionsTableProps {
  versions: SlimShopVersion[] | undefined;
  shopId: number;
  dispatch: Dispatch;
}

const DATE_FORMAT = 'HH:mm dd-MM-yyyy';

export const VersionsTable = ({ shopId, versions, dispatch }: VersionsTableProps): JSX.Element => {
  const isLoading = versions === undefined;

  const [sortAscending, setSortAscending] = useState(false);

  const tableData: IVersionsTableRow[] = useMemo(() => {
    const mappedTableData = versions?.map(version => {
      const formattedPublishedAt = format(version.publishedAt, DATE_FORMAT);

      const publishedBy =
        version.publishedBy === systemUser.userId
          ? systemUser.email
          : version.publisher?.email || 'N/A';

      return {
        id: version.id,
        publishedAtFormatted: formattedPublishedAt,
        publishedAt: version.publishedAt,
        publicationVersion: version.publicationVersion,
        publishedBy,
        isProduction: !!version.isProduction,
        rowActions: true,
        hasChangesSummary: version.hasChangesSummary,
      };
    });

    return orderBy(mappedTableData || [], ['publishedAt'], [sortAscending ? 'asc' : 'desc']);
  }, [versions, sortAscending]);

  const onSortChange = useCallback(() => {
    setSortAscending(state => !state);
  }, [setSortAscending]);

  useEffect(() => {
    if (shopId) {
      dispatch(versionsTableActions.getShopVersions(shopId));
    }
    return () => {
      dispatch(versionsTableActions.resetVersions());
    };
  }, [shopId]);

  return (
    <TableWrapperStyled>
      <TableV2<IVersionsTableRow>
        options={{ columns: tableColumnsArray, data: tableData }}
        customOptions={{
          shouldUseResizeColumns: true,
          shouldUseFlexLayout: true,
        }}
      >
        {({ getTableBodyProps, headerGroups, rows, prepareRow, state }) => {
          const headerGroup = headerGroups[0];

          const isResizingColumn = !!state.columnResizing.isResizingColumn;

          return (
            <>
              <TableV2.Head {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableV2.HeadRow {...column.getHeaderProps()} key={column.id}>
                    <TableV2.HeadRowCellText
                      key={`${isResizingColumn}`}
                      isSortAble={tableColumns[column.id].canSort === true}
                      sortAscending={sortAscending}
                      onClick={onSortChange}
                    >
                      {column.render('Header')}
                    </TableV2.HeadRowCellText>
                  </TableV2.HeadRow>
                ))}
              </TableV2.Head>
              <TableV2.Body
                {...getTableBodyProps()}
                css={{
                  overflow: 'initial',
                }}
              >
                {isLoading ? (
                  <>
                    <StyledSkeleton height={60} variant='rounded' />
                    <StyledSkeleton height={60} variant='rounded' />
                    <StyledSkeleton height={60} variant='rounded' />
                  </>
                ) : (
                  rows.map(row => {
                    prepareRow(row);
                    return (
                      <VersionsTableRow
                        key={row.original.id}
                        row={row}
                        shopId={shopId}
                        dispatch={dispatch}
                      />
                    );
                  })
                )}
              </TableV2.Body>
            </>
          );
        }}
      </TableV2>
    </TableWrapperStyled>
  );
};
