import React from 'react';
import './Tabs.scss';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useFindComponentsByType } from '../hooks';
import { Tab } from './Tab';
import { Typography, TypographyType, TypographyVariant } from '../Typography';

export interface TabsProps {
  children: JSX.Element[];
  className?: string;
  selectedTabId?: string;
  onSelectedTabChange?: (tabId: string) => void;
  onBeforeNavigation?: (href: string) => void;
}

export const Tabs = ({
  className,
  children,
  selectedTabId,
  onSelectedTabChange,
  onBeforeNavigation,
}: TabsProps): JSX.Element => {
  const innerTabs = useFindComponentsByType({ type: Tab, children });

  const wrappedTabs = innerTabs.map(tab => {
    const { children: tabText, href, uniqueId } = tab.props;
    const isActive = selectedTabId === uniqueId;

    return (
      <li
        key={uniqueId}
        className={classNames('syte-tabs-item', className, {
          'syte-tabs-item-active': isActive,
        })}
        onClick={() => onSelectedTabChange?.(uniqueId)}
      >
        <NavLink
          className='syte-tabs-link'
          activeClassName='syte-tabs-active-tab'
          to={href}
          onClick={ev => {
            ev.preventDefault();
            onBeforeNavigation?.(href);
          }}
        >
          <Typography
            className='syte-tabs-link-label'
            type={TypographyType.Body}
            variant={TypographyVariant.MediumRegular}
          >
            {tabText}
          </Typography>
          <span className='syte-tabs-active-line' />
        </NavLink>
      </li>
    );
  });

  return <div className={classNames('syte-tabs', className)}>{wrappedTabs}</div>;
};
