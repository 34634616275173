import styled from '@emotion/styled';
import { Icon, Typography } from 'src/components-dummy';

export const pinSize = 32;

export const ProductPin = styled.div`
  width: ${pinSize}px;
  height: ${pinSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const ProductPinOuterCircle = styled.div<{
  isApplied: boolean;
  isAIDetected: boolean;
  isError: boolean;
}>`
  background-color: ${({ theme, isError }) =>
    isError ? theme.palette.custom.red : theme.palette.common.white};
  opacity: 0.3;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;

  ${({ theme, isApplied, isAIDetected }) =>
    isApplied === false && isAIDetected
      ? `border: ${theme.palette.custom['primary-main']} dashed 2px;`
      : ''}
`;

export const ProductPinInnerCircle = styled.div<{
  isApplied: boolean;
  isAIDetected: boolean;
  isError: boolean;
}>`
  background-color: ${({ theme, isAIDetected }) =>
    isAIDetected ? theme.palette.custom['primary-light'] : theme.palette.common.white};
  width: 50%;
  height: 50%;
  border-radius: 50%;
  position: absolute;

  ${({ theme, isApplied, isAIDetected, isError }) => {
    if (isError) {
      return `border: 2px solid ${theme.palette.custom.red};`;
    }

    if (isApplied === false && isAIDetected) {
      return `border: ${theme.palette.custom['primary-main']} dashed 2px;`;
    }
  }}
`;

export const AiTagIconStyled = styled(Icon)<{ isApplied: boolean }>`
  min-width: 12px;
  max-width: 12px;

  path {
    fill: ${({ theme, isApplied }) =>
      isApplied ? theme.palette.custom['gray-10'] : theme.palette.custom['primary-main']};
  }
`;

export const TagTypographyStyled = styled(Typography)``;
