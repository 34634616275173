// eslint-disable-next-line @typescript-eslint/ban-types
export type CrossBrowserApiExecutor = Record<string, Function>;

const MessageEvent = 'message';

export class CrossBrowserApi {
  private listener?: (event: MessageEvent) => void;

  constructor(
    private apiName: string,
    private api: CrossBrowserApiExecutor
  ) {}

  private async proceedMessage(
    responsePort?: MessagePort,
    targetAPI?: string,
    targetMethod = '',
    args: [] = []
  ): Promise<void> {
    if (!responsePort) {
      return;
    }

    if (targetAPI !== this.apiName) {
      responsePort.postMessage({ succeed: false, error: 'Incorrect API name' });
      return;
    }
    if (!(targetMethod in this.api)) {
      responsePort.postMessage({ succeed: false, error: 'Incorrect method name' });
      return;
    }

    try {
      const result = await this.api[targetMethod].apply(null, args);
      responsePort.postMessage({ succeed: true, result });
    } catch (error) {
      responsePort.postMessage({ succeed: false, error });
    }
  }

  public stop(): void {
    if (this.listener) {
      window.removeEventListener(MessageEvent, this.listener);
    }
  }

  public start(): void {
    this.stop();
    this.listener = e => {
      this.proceedMessage(e.ports[0], e.data?.API, e.data?.method, e.data?.args);
    };
    window.addEventListener(MessageEvent, this.listener);
  }
}
