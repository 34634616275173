/* eslint-disable no-param-reassign */
import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { DeepTagReportsReducerState } from 'src/app-state-types';
import {
  deepTagReportsListActions,
  deepTagReportFiltersActions,
  deepTagReportProductPaginationActions,
  deepTagsReportProductPageHeaderActions,
  deepTagReportsListItemActions,
  deepTagReportSFTPSettingsModalActions,
} from 'src/components-bl';
import { deepTagReportFileUploadModalActions } from 'src/components-bl/DeepTagReports/components/DeepTagReportFileUploadModal';
import { PaginationState } from 'src/components-bl/DeepTagReports/components/DeepTagReportProductPagination/types';
import { deepTagsReportProductsBulkLabelActions } from 'src/components-bl/DeepTagReports/components/DeepTagReportProductsBulkLabelModal';
import { DeepTagsReportsProductLabelAutoCompleteActions } from 'src/components-bl/DeepTagReports/components/DeepTagsReportsProductLabelAutoComplete/Actions/DeepTagsReportsProductLabelAutoComplete.actions';
import {
  deepTagReportProductsContainerActions,
  deepTagReportsContainerActions,
  useDeepTagReportsWSActions,
} from 'src/containers';
import { deepTagsProductContainerActions } from 'src/containers/DeepTagReports/components/DeepTagsProductContainer/Actions';
import { deepTagReportsSettingsContainerActions } from 'src/containers/DeepTagReportsSettingsContainer/DeepTagReportsSettings.container.actions';
import { IDeepTagReportProduct } from 'src/services';

const initialState: DeepTagReportsReducerState = {
  reports: undefined,
  selectedReport: undefined,
  productPagination: undefined,
  paginationFilters: { skip: 0, limit: 10 },
  filters: {},
  configuration: undefined,
  generalConfigurationLabels: [],
  newReport: undefined,
};

export const deepTagReportsReducer = createReducer(initialState, builder => {
  builder
    .addCase(deepTagReportsListActions.getReports.fulfilled, (state, action) => {
      state.reports = action.payload.tagsReports;
    })
    .addCase(useDeepTagReportsWSActions.newReport, (state, action) => {
      const existingReport = state.reports?.find(report => report.id === action.payload.report.id);

      if (existingReport) {
        if (existingReport.status !== action.payload.report.status) {
          state.reports = state.reports?.map(report => {
            if (report.id === action.payload.report.id) {
              return action.payload.report;
            }
            return report;
          });
        }
      } else {
        state.reports?.unshift(action.payload.report);
      }
    })
    .addCase(useDeepTagReportsWSActions.reportUpdated, (state, action) => {
      const existingReport = state.reports?.find(report => report.id === action.payload.report.id);

      if (existingReport && existingReport?.status !== action.payload.report.status) {
        state.reports = state.reports?.map(report => {
          if (report.id === action.payload.report.id) {
            return action.payload.report;
          }
          return report;
        });
      }

      if (state.selectedReport?.id === action.payload.report.id) {
        state.selectedReport = action.payload.report;
      }
    })
    .addCase(useDeepTagReportsWSActions.reportDeleted, (state, action) => {
      const existingReport = state.reports?.find(report => report.id === action.payload.reportId);

      if (existingReport) {
        state.reports = state.reports?.filter(report => {
          return report.id !== action.payload.reportId;
        });
      }
    })
    .addCase(deepTagsReportProductPageHeaderActions.updateReport.fulfilled, (state, action) => {
      if (state.selectedReport) {
        state.selectedReport.status = action.payload.status;
      }
      if (state.reports) {
        const updatedReports = state.reports.map(report => {
          if (report.id === action.payload.id) {
            return {
              ...report,
              status: action.payload.status,
            };
          }
          return report;
        });

        state.reports = updatedReports;
      }
    })
    .addCase(deepTagReportProductsContainerActions.getReport.fulfilled, (state, action) => {
      state.selectedReport = action.payload.report;
    })
    .addCase(deepTagReportProductsContainerActions.getReport.rejected, (state, _) => {
      state.selectedReport = initialState.selectedReport;
      state.productPagination = initialState.productPagination;
    })
    .addCase(deepTagReportFiltersActions.updateFilters, (state, action) => {
      state.filters = action.payload;
      state.paginationFilters.skip = 0;
    })
    .addCase(
      deepTagReportProductPaginationActions.updatePagination,
      (state, action: { type: string; payload: Partial<PaginationState> }) => {
        if (action.payload.skip !== undefined) {
          state.paginationFilters.skip = action.payload.skip;
        }
        if (action.payload.limit !== undefined) {
          state.paginationFilters.limit = action.payload.limit;
        }
      }
    )
    .addCase(deepTagReportProductPaginationActions.resetPagination, (state, _) => {
      state.paginationFilters.limit = initialState.paginationFilters.limit;
      state.paginationFilters.skip = initialState.paginationFilters.skip;
    })
    .addCase(deepTagReportProductsContainerActions.initFilters, (state, action) => {
      state.filters = action.payload;
    })
    .addCase(deepTagReportsListItemActions.deleteReport.fulfilled, (state, action) => {
      if (state.reports) {
        const filteredReports = state.reports.filter(report => {
          return report.id !== action.payload.deepTagReportId;
        });

        state.reports = filteredReports;
      }
    })
    .addCase(deepTagReportsListItemActions.updateReport.fulfilled, (state, action) => {
      if (state.reports) {
        const updatedReports = state.reports.map(report => {
          if (report.id === action.payload.updatedReport.id) {
            return action.payload.updatedReport;
          }
          return report;
        });

        state.reports = updatedReports;
      }

      if (state.selectedReport?.id === action.payload.updatedReport.id) {
        state.selectedReport.status = action.payload.updatedReport.status;
      }
    })
    .addCase(deepTagReportProductsContainerActions.resetCurrentReport, (state, _) => {
      state.selectedReport = initialState.selectedReport;
      state.productPagination = initialState.productPagination;
      state.paginationFilters = initialState.paginationFilters;
      state.filters = initialState.filters;
    })
    .addCase(deepTagReportsContainerActions.getConfiguration.fulfilled, (state, action) => {
      state.configuration = action.payload;
    })
    .addCase(deepTagReportsContainerActions.resetConfiguration, (state, _) => {
      state.configuration = initialState.configuration;
    })
    .addCase(
      deepTagReportSFTPSettingsModalActions.updateConfiguration.fulfilled,
      (state, action) => {
        state.configuration = action.payload;
      }
    )
    .addCase(deepTagReportFileUploadModalActions.createReport.fulfilled, (state, action) => {
      state.reports = action.payload.reports;
    })
    .addCase(deepTagReportsListActions.resetReports, (state, _) => {
      state.reports = initialState.reports;
    })
    .addMatcher(
      isAnyOf(
        deepTagReportProductsContainerActions.getReportProducts.fulfilled,
        deepTagsReportProductsBulkLabelActions.getReportProducts.fulfilled
      ),
      (state, action) => {
        state.productPagination = action.payload;
      }
    )
    .addMatcher(
      isAnyOf(
        deepTagReportProductsContainerActions.getLabelsGeneralConfiguration.fulfilled,
        deepTagsReportProductsBulkLabelActions.getLabelsGeneralConfiguration.fulfilled,
        DeepTagsReportsProductLabelAutoCompleteActions.getLabelsGeneralConfiguration.fulfilled
      ),
      (state, action) => {
        state.generalConfigurationLabels = action.payload.generalConfigurationLabels;
      }
    )
    .addMatcher(
      isAnyOf(
        deepTagReportProductsContainerActions.getLabelsGeneralConfiguration.rejected,
        DeepTagsReportsProductLabelAutoCompleteActions.getLabelsGeneralConfiguration.rejected
      ),
      (state, _) => {
        state.generalConfigurationLabels = [];
      }
    )
    .addMatcher(
      isAnyOf(
        deepTagsProductContainerActions.getGeneratedDataSettings.fulfilled,
        deepTagsProductContainerActions.updateGeneratedDataSettings.fulfilled,
        deepTagReportsSettingsContainerActions.getGeneratedDataSettings.fulfilled,
        deepTagReportsSettingsContainerActions.updateGeneratedDataSettings.fulfilled
      ),
      (state, action) => {
        state.generatedDataSettings = action.payload.shopGeneratedDataSettings;
      }
    )
    .addMatcher(
      isAnyOf(
        deepTagsProductContainerActions.updateProduct.fulfilled,
        deepTagsProductContainerActions.resetProductChanges.fulfilled
      ),
      (state, action) => {
        const mappedProducts = state.productPagination?.data.products.map(listProduct => {
          return listProduct.id === action.payload.product.id
            ? action.payload.product
            : listProduct;
        }) as IDeepTagReportProduct[];

        if (state.productPagination?.data) {
          state.productPagination.data.products = mappedProducts;
        }
      }
    );
});
