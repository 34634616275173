import React, { useCallback } from 'react';
import { ParseJoiValidateResponse } from 'src/utils';
import { MenuItem, SelectType, TypographyType, TypographyVariant } from 'src/components-dummy';
import { AttributeTagPart } from '../../../LexiconRuleForm.types';
import { LexiconTagSelectStyled } from '../LexiconChangeRuleSection.styles';
import { CustomTranslationTextBox, TagRowTypographyStyled } from './TagRow.styles';

interface AttributeRowProps {
  tag: AttributeTagPart;
  errors: ParseJoiValidateResponse<AttributeTagPart>;
  onChange: (updatedTag: AttributeTagPart) => void;
  availableAttributeKeys: string[];
}

export const AttributeRow = ({
  tag,
  errors,
  onChange,
  availableAttributeKeys,
}: AttributeRowProps): JSX.Element => {
  const onTagChange = useCallback(
    (updatedCategory: Partial<AttributeTagPart>) => {
      const updatedState = { ...tag, ...updatedCategory };
      onChange(updatedState);
    },
    [tag, onChange]
  );

  const onAttributeKeyChange = useCallback(
    event => {
      onTagChange({ attributeKey: event.target.value, customTranslation: '' });
    },
    [onTagChange]
  );

  const onAttributeTranslationChange = useCallback(
    newTranslation => {
      onTagChange({ customTranslation: newTranslation });
    },
    [onTagChange]
  );
  return (
    <>
      <LexiconTagSelectStyled
        type={SelectType.Menu}
        placeholder='Attribute'
        value={tag.attributeKey}
        onChange={onAttributeKeyChange}
        errorMessage={errors.attributeKey?.message}
        autoFocus
      >
        {availableAttributeKeys.map(attributeKey => (
          <MenuItem key={attributeKey} value={attributeKey}>
            {attributeKey}
          </MenuItem>
        ))}
      </LexiconTagSelectStyled>
      {tag.attributeKey && !errors.attributeKey && (
        <>
          <TagRowTypographyStyled
            type={TypographyType.Body}
            variant={TypographyVariant.MediumMedium}
          >
            to
          </TagRowTypographyStyled>
          <CustomTranslationTextBox
            placeholder='type attribute'
            onChange={onAttributeTranslationChange}
            value={tag.customTranslation}
            error={errors.customTranslation?.message}
          />
        </>
      )}
    </>
  );
};
