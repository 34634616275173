import React, { KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { EllipsisWithTooltip, MenuItem, SelectOnChangeEvent } from 'src/components-dummy';
import { EllipsisWithTooltipWrapperStyled } from 'src/components-dummy/Select/Select.style';
import { useFilterItems } from 'src/hooks';
import { DataFieldSelectStyled } from '../DataFieldsCondition.styles';
import { ListSubheaderStyled, SearchInputStyled } from './DataFieldsSearchableSelect.styles';

interface DataFieldsSearchableSelectProps {
  dataField?: string;
  disabled?: boolean;
  isError: boolean;
  options: { value: string; text: string }[];
  onChange: SelectOnChangeEvent;
}

export function DataFieldsSearchableSelect({
  dataField,
  disabled,
  isError,
  options,
  onChange,
}: DataFieldsSearchableSelectProps): JSX.Element {
  const [searchInput, setSearchInput] = useState('');

  const resetSearchInput = useCallback(() => setSearchInput(''), [setSearchInput]);

  const { filteredItems: filteredDataFields } = useFilterItems({
    items: options,
    searchableFields: ['text'],
    idField: 'text',
    filterText: searchInput,
  });

  const onSearchKeydown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Escape') {
      // Prevents auto selecting item while typing (default Select behavior)
      event.stopPropagation();
    }
  }, []);

  const isSelectedDataFieldFilteredOut = useMemo(
    () => filteredDataFields.every(filteredDataField => filteredDataField.value !== dataField),
    [dataField, filteredDataFields]
  );

  const renderMissingValue = useCallback(
    (value: unknown) => {
      const selectedDataFieldOption = options.find(option => option.value === value);

      if (!selectedDataFieldOption) {
        return null;
      }

      return (
        <EllipsisWithTooltipWrapperStyled>
          <EllipsisWithTooltip
            tooltipPosition='top center'
            tooltipText={selectedDataFieldOption.text}
          >
            {selectedDataFieldOption.text}
          </EllipsisWithTooltip>
        </EllipsisWithTooltipWrapperStyled>
      );
    },
    [options]
  );

  return (
    <DataFieldSelectStyled
      value={dataField}
      renderValue={dataField && isSelectedDataFieldFilteredOut ? renderMissingValue : undefined}
      key={dataField}
      disabled={disabled}
      placeholder='Select ...'
      isError={isError}
      onChange={onChange}
      onClose={resetSearchInput}
      autoFocus
      MenuProps={{
        autoFocus: false,
        anchorOrigin: {
          horizontal: 'left',
        },
        transformOrigin: {
          horizontal: 'left',
        },
        sx: {
          paddingTop: '0px',
        },
        PaperProps: {
          sx: {
            maxWidth: '208px',
          },
        },
      }}
    >
      <ListSubheaderStyled>
        <SearchInputStyled
          value={searchInput}
          onChange={setSearchInput}
          placeholder='Search...'
          onKeyDown={onSearchKeydown}
        />
      </ListSubheaderStyled>
      {filteredDataFields.map((option: { value: string; text: string }) => (
        <MenuItem key={option.value} value={option.value}>
          {option.text}
        </MenuItem>
      ))}
    </DataFieldSelectStyled>
  );
}
