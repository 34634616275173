import React from 'react';
import {
  AvailableIcons,
  ListSelector,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { ShopTypes } from 'src/services';
import { Dispatch } from '../types';
import { shopSelectorActions } from './Actions';

import './ShopSelector.scss';

const ShopItem = ({ shop }: { shop: ShopTypes.Shop }): JSX.Element => {
  return (
    <>
      <div className='syte-shop-selector-item'>
        <div className='syte-shop-selector-item-details'>
          <div className='syte-shop-selector-item-title'>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              {shop.shopName}
            </Typography>
          </div>
          <div className='syte-shop-selector-item-splitter' />
          <div className='syte-shop-selector-item-sub-title'>
            <Typography
              type={TypographyType.Body}
              variant={TypographyVariant.SmallRegular}
            >{`ID ${shop.shopId}`}</Typography>
          </div>
        </div>
      </div>
      <div className='syte-shop-selector-divider' />
    </>
  );
};

export interface ShopSelectorProps {
  dispatch: Dispatch;
  shops: ShopTypes.Shop[];
  selectedShop: ShopTypes.Shop | undefined;
}

export const ShopSelector = ({ dispatch, shops, selectedShop }: ShopSelectorProps): JSX.Element => {
  const onShopClick = (shop: ShopTypes.Shop) => {
    dispatch(shopSelectorActions.navigateToShop({ shopId: shop.shopId }));
  };

  function prepareShopName(shop: ShopTypes.Shop, amountOfRemainingLetters = 18) {
    return shop && shop?.shopName.length > 20
      ? `${shop?.shopName.slice(0, amountOfRemainingLetters)}...`
      : shop.shopName;
  }
  return (
    <ListSelector
      items={shops.map(s => ({
        ...s,
        shopName: prepareShopName(s),
      }))}
      selectedItem={
        selectedShop
          ? {
              ...selectedShop,
              shopName: prepareShopName(selectedShop, 20),
            }
          : selectedShop
      }
      idField='shopId'
      searchableFields={['shopId', 'shopName']}
      onItemSelected={onShopClick}
      className='syte-shop-selector'
      searchPlaceholder='Search shops...'
      selectPlaceHolder='Select a shop...'
      childClassName='syte-shop-selector-selected-item'
      selectCaretIconClassName='select-shop-caret-icon'
      searchIconName={AvailableIcons.SearchShop}
    >
      <ListSelector.Item>{(s: ShopTypes.Shop) => <ShopItem shop={s} />}</ListSelector.Item>
    </ListSelector>
  );
};
