import React from 'react';
import { ButtonStyled } from './EllipsisMenuButton.style';
import { AvailableIcons, Icon } from '../Icon';

export interface EllipsisMenuButtonProps {
  isActive: boolean;
  isVisible?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const EllipsisMenuButton = ({
  isActive,
  isVisible = true,
  onClick,
}: EllipsisMenuButtonProps): JSX.Element => {
  return (
    <ButtonStyled onClick={onClick} isActive={isActive} isVisible={isVisible}>
      <Icon name={AvailableIcons.EllipsisMenu} />
    </ButtonStyled>
  );
};
