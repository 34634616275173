import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { visualEditorActions } from '../../state';
import { useCollectionSettings } from '../../hooks';
import { useCollection } from '../../../Collections/EditCollectionRules/CollectionRules/useCollection';
import { EditCollectionRulesFooter } from '../../../Collections/EditCollectionRules/CollectionRules/CollectionRules/EditCollectionRulesFooter';
import { useNavigateToVisualEditorRoute } from '../../hooks/useNavigateToVisualEditorRoute';
import {
  AvailableIcons,
  Icon,
  Link,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { Collection, EditCollectionRules } from '../../../Collections';
import { Dispatch } from '../../../../components-dummy/types';
import { PermittedRouteMap } from '../../../../app-routes';
import { Container, Header } from './EditCollectionRulesContainer.styles';
import { editCollectionRulesContainerActions } from '../../../Collections/EditCollectionRules/CollectionRules/CollectionRules/EditCollectionRulesContainerActions';

interface Props {
  shopId: number;
  appDispatch: Dispatch;
  permittedRouteMap: PermittedRouteMap;
  currentCollection: Collection;
  isCreateMode?: boolean;
}

export const EditCollectionRulesContainer = ({
  shopId,
  appDispatch,
  permittedRouteMap,
  currentCollection,
  isCreateMode,
}: Props): JSX.Element => {
  const { navigateToVisualEditorRoute } = useNavigateToVisualEditorRoute({
    route: permittedRouteMap?.visualEditor,
  });

  const closeCollectionDrawer = useCallback(() => {
    appDispatch(visualEditorActions.notifyIsDirty({ isDirty: false }));
    navigateToVisualEditorRoute({ shopId });
  }, [appDispatch, navigateToVisualEditorRoute, shopId]);

  const currentCollectionTitle = isCreateMode ? 'Create New Collection' : 'Edit Collection';

  const {
    draftCollection,
    canSubmit,
    errors,
    onAddRuleGroup,
    onDeleteRuleGroup,
    onAddRule,
    onDeleteRule,
    onEditRule,
    onSaveChanges,
    onCreateCollection,
    onCancel,
    handleFieldOnChange,
    dataFields,
  } = useCollection({
    shopId,
    currentCollection,
    dispatch: appDispatch,
    isCreateMode,
  });
  const { onCreateCollectionCallback } = useCollectionSettings(shopId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (shopId) {
      dispatch(
        editCollectionRulesContainerActions.getDataFields({
          shopId,
        })
      );
    }
  }, [shopId]);

  const onCreate = () => {
    onCreateCollection(onCreateCollectionCallback);
    closeCollectionDrawer();
  };

  const onCancelChanges = () => {
    onCancel(closeCollectionDrawer);
  };

  const onSave = () => {
    onSaveChanges();
    closeCollectionDrawer();
  };

  return (
    <Container>
      <Header>
        <Typography type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
          {currentCollectionTitle}
        </Typography>
        <Link href='https://support.syte.ai/space/ET/2696970265'>
          <Typography variant={TypographyVariant.MediumMedium} type={TypographyType.Button}>
            What are Syte Collections?
          </Typography>
          <Icon name={AvailableIcons.ArrowRec} />
        </Link>
      </Header>
      <EditCollectionRules
        shopId={shopId}
        dispatch={dispatch}
        draftCollection={draftCollection}
        isCreateMode={isCreateMode}
        onDeleteRule={onDeleteRule}
        onEditRule={onEditRule}
        onAddRule={onAddRule}
        onAddRuleGroup={onAddRuleGroup}
        onDeleteRuleGroup={onDeleteRuleGroup}
        handleFieldOnChange={handleFieldOnChange}
        dataFields={dataFields}
        errors={errors}
        withoutBorder
      />
      <EditCollectionRulesFooter
        isCreateMode={isCreateMode}
        canSubmit={canSubmit}
        onCancel={onCancelChanges}
        onSaveChanges={onSave}
        onCreate={onCreate}
      />
    </Container>
  );
};
