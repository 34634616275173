/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cloneDeep } from 'lodash';
import { IndexedOffer, IndexedOffers, PreviewMerchRule } from '../types';
import { UpdateMerchRuleArgs } from '../../MerchandisingRules/components/MerchandisingRuleForm';
import {
  RuleDraft,
  RuleDraftCondition,
} from '../../MerchandisingRules/components/MerchandisingRuleForm/MerchandisingRuleForm.config';
import { ruleFormMappers } from '../../MerchandisingRules/components/MerchandisingRuleForm/MerchandisingRuleForm.mappers';
import { updateRuleConditions } from '../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormConditionsSection/PinToPosition/helpers';
import { UniqueByField } from '../consts/uniqueByField';

interface DragAndDropRef {
  conditions: RuleDraftCondition[];
  draggedItemTempId?: number | string;
}

export const useDragAndDropItems = (
  items: IndexedOffers,
  selectedRule: PreviewMerchRule | null,
  submitStrategyForm: (payload: UpdateMerchRuleArgs) => void,
  shopId: number,
  uniqueByField?: string
): {
  draggedAndDroppedItems: IndexedOffers;
  onDrag: (sourceIndex: number, targetIndex: number) => void;
  onDrop: VoidFunction;
} => {
  const [draggedAndDroppedItems, setDraggedAndDroppedItems] = useState<IndexedOffers>([]);

  const conditions: RuleDraftCondition[] = useMemo(
    () =>
      [...(selectedRule?.subRules || [])].map(subRule => {
        return {
          field: subRule.field,
          fieldType: subRule.fieldType,
          subType: subRule.subType,
          values: subRule.values,
          position: subRule.position,
          tempId: subRule.values[0] as string,
        };
      }),
    [selectedRule]
  );

  const dragAndDropRef = useRef<DragAndDropRef>({
    conditions,
  });

  const updateDragAndDropRefByProperty = useCallback(
    <T extends keyof DragAndDropRef>(property: T, value: DragAndDropRef[T]) => {
      dragAndDropRef.current[property] = value;
    },
    []
  );

  useEffect(() => {
    updateDragAndDropRefByProperty('conditions', conditions);
  }, [conditions, updateDragAndDropRefByProperty]);

  useEffect(() => {
    setDraggedAndDroppedItems(items);
  }, [items]);

  const uniqueBy = uniqueByField || UniqueByField.Sku;

  const onDrag = useCallback(
    (sourceIndex: number, targetIndex: number) => {
      setDraggedAndDroppedItems((previousItems: IndexedOffers) => {
        const rearrangedItems = [...previousItems].map(item => {
          return {
            ...item,
            tempId: item[uniqueBy as keyof IndexedOffer],
          };
        });
        const elementToMove = rearrangedItems[sourceIndex];

        rearrangedItems.splice(sourceIndex, 1);
        rearrangedItems.splice(targetIndex, 0, elementToMove);

        updateDragAndDropRefByProperty('draggedItemTempId', elementToMove.tempId as string);
        updateDragAndDropRefByProperty('conditions', [
          {
            ...ruleFormMappers.createEmptyPinToPositionCondition(),
            values: [elementToMove[uniqueBy as keyof IndexedOffer] as string],
            position: targetIndex + 1,
            tempId: elementToMove[uniqueBy as keyof IndexedOffer] as string,
          },
          ...conditions,
        ]);

        return rearrangedItems;
      });
    },
    [conditions, uniqueBy, updateDragAndDropRefByProperty]
  );

  const onDrop = useCallback(() => {
    setDraggedAndDroppedItems([...draggedAndDroppedItems]);

    const renumberedConditions = updateRuleConditions({
      movedConditionId: dragAndDropRef.current.draggedItemTempId,
      previousConditions: cloneDeep(conditions),
      rearrangedConditions: cloneDeep(dragAndDropRef.current.conditions),
    });

    submitStrategyForm({
      shopId,
      ruleDraft: {
        ...selectedRule,
        subRules: renumberedConditions,
      } as Required<RuleDraft>,
    });
  }, [draggedAndDroppedItems, selectedRule, conditions, shopId, submitStrategyForm]);

  return { draggedAndDroppedItems, onDrag, onDrop };
};
