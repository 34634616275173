import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { ExportLexiconModal } from 'src/components-bl/Lexicon';
import { useAppSelector } from 'src/hooks';
import { useValidateLocale } from '../../hooks';
import { exportLexiconContainerActions } from './ExportLexiconContainer.actions';

export type ExportLexiconContainerProps = RoutedComponentProps;

export const ExportLexiconContainer = ({
  permittedRouteMap,
}: ExportLexiconContainerProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const progress = useAppSelector(state => state.lexicon.importExportFile)?.progress;
  const tagType = useAppSelector(state => state.lexicon.currentSelectedTagType);

  const locale = useValidateLocale({ permittedRouteMap, dispatch, shopId })?.locale;

  const navigateToLexiconTable = useCallback(() => {
    if (permittedRouteMap.lexiconCategories?.path && shopId && locale) {
      dispatch(
        exportLexiconContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.lexiconCategories.path, {
            shopId,
            locale,
            tagType,
          }),
        })
      );
    }
  }, [permittedRouteMap.lexiconCategories, locale, shopId, dispatch, tagType]);

  if (!shopId || !locale) {
    return null;
  }

  return shopId ? (
    <ExportLexiconModal
      shopId={shopId}
      locale={locale}
      dispatch={dispatch}
      onClose={navigateToLexiconTable}
      progress={progress}
    />
  ) : null;
};
