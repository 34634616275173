import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const TitleMainStyled = styled(Typography)`
  display: inline;
`;

export const TitleContextStyled = styled.span`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;
