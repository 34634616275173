import React, { useMemo } from 'react';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import {
  IStoriesService,
  StoriesService,
} from '../../services/src/service/stories/stories-service';
import { StoriesProvider } from './StoriesProvider';
import { StoriesPage } from './StoriesPage';

interface StoriesProps {
  shopId: number;
}

export const StoriesContainer = ({ shopId }: StoriesProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const service = useMemo(() => new StoriesService(shopId), [shopId]);
  return (
    <StoriesProvider service={service as IStoriesService} appDispatch={dispatch}>
      <StoriesPage shopId={shopId} />
    </StoriesProvider>
  );
};
