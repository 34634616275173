import { experimentService } from 'src/services';
import { Dispatch } from 'src/components-bl/types';

export enum SideNavContainerActionTypes {
  GetExperimentSettingsRequest = 'SideNavContainer/GetExperimentSettingsRequest',
  GetExperimentSettingsSuccess = 'SideNavContainer/GetExperimentSettingsSuccess',
  GetExperimentSettingsError = 'SideNavContainer/GetExperimentSettingsError',
}

export interface SideNavContainerGetExperimentSettingsRequestActionPayload {
  shopId: number | undefined;
}

export interface SideNavContainerGetExperimentSettingsSuccessActionPayload {
  experimentsEnabled: boolean;
}

export interface SideNavContainerGetExperimentSettingsErrorActionPayload {
  error: unknown;
}

export interface SideNavContainerGetExperimentSettingsRequestAction {
  type: typeof SideNavContainerActionTypes.GetExperimentSettingsRequest;
  payload: SideNavContainerGetExperimentSettingsRequestActionPayload;
}

export interface SideNavContainerGetExperimentSettingsSuccessAction {
  type: typeof SideNavContainerActionTypes.GetExperimentSettingsSuccess;
  payload: SideNavContainerGetExperimentSettingsSuccessActionPayload;
}

export interface SideNavContainerGetExperimentSettingsErrorAction {
  type: typeof SideNavContainerActionTypes.GetExperimentSettingsError;
  payload: SideNavContainerGetExperimentSettingsErrorActionPayload;
}

export type SideNavContainerAction =
  | SideNavContainerGetExperimentSettingsRequestAction
  | SideNavContainerGetExperimentSettingsSuccessAction
  | SideNavContainerGetExperimentSettingsErrorAction;

const sideNavContainerActions = {
  getExperimentSettingsRequest(
    payload: SideNavContainerGetExperimentSettingsRequestActionPayload
  ): SideNavContainerGetExperimentSettingsRequestAction {
    return {
      type: SideNavContainerActionTypes.GetExperimentSettingsRequest,
      payload,
    };
  },

  getExperimentSettingsSuccess(
    payload: SideNavContainerGetExperimentSettingsSuccessActionPayload
  ): SideNavContainerGetExperimentSettingsSuccessAction {
    return {
      type: SideNavContainerActionTypes.GetExperimentSettingsSuccess,
      payload,
    };
  },

  getExperimentSettingsError(
    payload: SideNavContainerGetExperimentSettingsErrorActionPayload
  ): SideNavContainerGetExperimentSettingsErrorAction {
    return {
      type: SideNavContainerActionTypes.GetExperimentSettingsError,
      payload,
    };
  },
};

export const sideNavContainerActionMethods = {
  getExperimentSettings({ shopId }: SideNavContainerGetExperimentSettingsRequestActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(sideNavContainerActions.getExperimentSettingsRequest({ shopId }));
        if (shopId) {
          const result = await experimentService.getExperimentSettings({ shopId });
          dispatch(
            sideNavContainerActions.getExperimentSettingsSuccess({
              experimentsEnabled: result.enabled,
            })
          );
        }
      } catch (error) {
        dispatch(sideNavContainerActions.getExperimentSettingsError({ error }));
      }
    };
  },
};
