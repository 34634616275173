import React from 'react';
import styled from '@emotion/styled';
import { Button, TableV2, Typography } from 'src/components-dummy';
import { tableColumns } from './tableColumns';

export const GalleriesEmptyTableStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GalleriesEmptyTableContentStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  padding: 32px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-80']};
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const WelcomeImageStyled = styled.img`
  width: 100%;
  max-width: 690px;
`;

export const CreateGalleriesWrapperStyled = styled.div`
  justify-content: center;
  align-items: center;
  max-width: 350px;

  margin-right: 35px;
`;

export const CreateGalleriesSubTitleStyled = styled(Typography)`
  margin: 16px 0;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const CreateGalleriesActionButtonsWrapperStyled = styled.div`
  display: flex;
  gap: 12px;
  justify-content: right;
`;

export const TableBodyRowCellStyled = styled(TableV2.BodyRowCell)<{ cellName: string }>`
  color: ${({ cellName, theme }) =>
    cellName === tableColumns.galleryName.accessor
      ? theme.palette.custom['primary-main']
      : theme.palette.common.black};

  min-height: 64px;
  align-items: center;
  display: flex;

  ${({ cellName }) =>
    cellName === tableColumns.rowActions.accessor ? 'justify-content: flex-end;' : ''}
`;

export const TableWrapperStyled = styled.div`
  min-width: 560px;
`;

export const TableCellLinkStyled = styled((props: { children: string }) => (
  <TableV2.BodyRowCellText {...props} />
))`
  cursor: pointer;
  color: red !important;
`;

export const GalleryNameCell = styled(Button)`
  width: 100%;
  padding: unset;
  text-align: start;
  color: ${({ theme }) => theme.palette.custom['primary-main']};
  background-color: transparent;
  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
  & > span {
    width: 100%;
    height: 100%;

    display: flex;
    align-content: center;

    .ellipsis-with-tooltip {
      align-items: center;
      display: fe;
      display: flex;
    }
  }
`;
