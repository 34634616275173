import React from 'react';
import { Typography, TypographyType, TypographyVariant } from '../../Typography';
import { TableEmptyListMessage, TableEmptyListStyled } from '../TableV2.style';

interface TableNoResultsProps {
  width: number;
  marginTop?: number;
  marginBottom?: number;
  message?: string | JSX.Element;
}

export const TableNoResults = ({
  message = 'No results',
  width,
  marginTop,
  marginBottom,
}: TableNoResultsProps): JSX.Element => (
  <TableEmptyListStyled width={width} marginTop={marginTop} marginBottom={marginBottom}>
    <div>
      {typeof message === 'string' ? (
        <TableEmptyListMessage>
          <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
            {message}
          </Typography>
        </TableEmptyListMessage>
      ) : (
        message
      )}
    </div>
  </TableEmptyListStyled>
);
