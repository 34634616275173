import { experimentService, Experiment } from 'src/services';
import { Dispatch } from '../../../types';

import {
  ExperimentFormActionTypes,
  ExperimentFormUpdateExperimentRequestActionPayload,
  ExperimentFormUpdateExperimentRequestAction,
  ExperimentFormUpdateExperimentSuccessActionPayload,
  ExperimentFormUpdateExperimentSuccessAction,
  ExperimentFormUpdateExperimentErrorActionPayload,
  ExperimentFormUpdateExperimentErrorAction,
  ExperimentFormNotifyIsDirtyActionPayload,
  ExperimentFormNotifyIsDirtyAction,
} from './types';

const experimentFormActions = {
  updateExperimentRequest(
    payload: ExperimentFormUpdateExperimentRequestActionPayload
  ): ExperimentFormUpdateExperimentRequestAction {
    return {
      type: ExperimentFormActionTypes.UpdateExperimentRequest,
      payload,
    };
  },

  updateExperimentSuccess(
    payload: ExperimentFormUpdateExperimentSuccessActionPayload
  ): ExperimentFormUpdateExperimentSuccessAction {
    return {
      type: ExperimentFormActionTypes.UpdateExperimentSuccess,
      payload,
    };
  },

  updateExperimentError(
    payload: ExperimentFormUpdateExperimentErrorActionPayload
  ): ExperimentFormUpdateExperimentErrorAction {
    return {
      type: ExperimentFormActionTypes.UpdateExperimentError,
      payload,
    };
  },
  notifyIsDirty(
    payload: ExperimentFormNotifyIsDirtyActionPayload
  ): ExperimentFormNotifyIsDirtyAction {
    return {
      type: ExperimentFormActionTypes.NotifyIsDirty,
      payload,
    };
  },
};

export const experimentFormActionMethods = {
  updateExperiment({ shopId, experiment }: ExperimentFormUpdateExperimentRequestActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(experimentFormActions.updateExperimentRequest({ shopId, experiment }));
        const updatedExperiment = (await experimentService.updateExperiment({
          shopId,
          experiment,
        })) as Experiment;
        dispatch(experimentFormActions.updateExperimentSuccess({ experiment: updatedExperiment }));
      } catch (error) {
        dispatch(experimentFormActions.updateExperimentError({ error }));
      }
    };
  },
  notifyIsDirty(payload: ExperimentFormNotifyIsDirtyActionPayload) {
    return (dispatch: Dispatch): void => {
      dispatch(experimentFormActions.notifyIsDirty(payload));
    };
  },
};
