import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const TabsWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 0px 40px 16px 40px;
`;

export const TabButtonWrapperStyled = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;

  button {
    height: 24px;
    &:hover {
      background: ${({ theme }) => `${theme.palette.custom['primary-light']}`};
    }
  }

  ${({ theme, isSelected }) => {
    return isSelected
      ? css`
          button {
            background-color: ${theme.palette.custom['primary-light']};
            color: ${theme.palette.custom['primary-main']};
          }
        `
      : css`
          button {
            background-color: ${theme.palette.custom['gray-70']} !important;
            color: ${theme.palette.custom['gray-10']};
          }
        `;
  }}
`;
