import React from 'react';
import { Button, Page } from '../../../../components-dummy';

interface HeaderProps {
  disabled: boolean;
  onSave: () => void;
}

export const EditPostsHeader = ({ disabled, onSave }: HeaderProps): JSX.Element => {
  return (
    <>
      <Page.Title className='stories-settings-container-title'>Social Discovery</Page.Title>
      <div className='button-section'>
        <Button onClick={onSave} disabled={disabled} variant='primary'>
          Save and publish
        </Button>
      </div>
    </>
  );
};
