import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Page, BackButtonTitle } from 'src/components-dummy';
import { useAppSelector } from 'src/hooks';
import { RecEnginesGeneralSettings } from '../../components-bl';
import { ShopRoutes } from '../../types/enums/shop-routes';
import './RecEnginesContainer.scss';

export const RecEnginesContainer = (): JSX.Element | null => {
  const formHeaderRef = useRef<HTMLDivElement>(null);
  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const generalSettings = useAppSelector(state => state.recEnginesGeneralSettings);
  const dispatch = useDispatch();
  const navigationPath = `/shops/${shopId}/${ShopRoutes.ShopperExperiences}`;

  return (
    <Page className='rec-engines-settings-container'>
      <Page.Header>
        <BackButtonTitle path={navigationPath}>Carousels settings</BackButtonTitle>
        <div className='rec-engines-settings-buttons-section' ref={formHeaderRef} />
      </Page.Header>
      <Page.Content>
        {shopId && (
          <RecEnginesGeneralSettings
            generalSettings={generalSettings}
            shopId={shopId}
            formHeaderElementRef={formHeaderRef}
            dispatch={dispatch}
          />
        )}
      </Page.Content>
    </Page>
  );
};
