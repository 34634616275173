import React, { MouseEvent, useCallback, useState } from 'react';
import { Row } from 'react-table';
import { TableV2, TableV2InstanceProps } from 'src/components-dummy';
import { GalleryType } from 'src/services';
import { IGalleryTableRow } from '../../types';
import { RowActionsMenu } from './RowActionsMenu';
import { tableColumns } from '../../tableColumns';
import { GalleryNameCell, TableBodyRowCellStyled } from '../../GalleriesTable.styles';
import { DeleteGalleryConfirmationDialog } from './DeleteGalleryConfirmationDialog';

interface GalleryTableRowProps {
  row: Row<IGalleryTableRow>;
  navigateToEditGalleryPage: (galleryId: string, galleryType: GalleryType) => void;
  deleteGallery: (galleryId: string, galleryType: GalleryType) => void;
  navigateToGalleryPage: (galleryId: string, galleryType: GalleryType) => void;
}

export const GalleryTableRow = ({
  row,
  navigateToEditGalleryPage,
  deleteGallery,
  navigateToGalleryPage,
}: GalleryTableRowProps): JSX.Element => {
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState<boolean>(false);

  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);

  const onMenuClicked = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setMenuAnchorElement(event.currentTarget);
    },
    [setMenuAnchorElement]
  );

  const onMenuClose = useCallback(() => {
    setMenuAnchorElement(null);
  }, [setMenuAnchorElement]);

  const onEditGalleryClick = useCallback(() => {
    navigateToEditGalleryPage(row.original.id, row.original.galleryType);
  }, [navigateToEditGalleryPage, row.original.id, row.original.galleryType]);

  const onViewGalleryClick = useCallback(() => {
    navigateToGalleryPage(row.original.id, row.original.galleryType);
  }, [navigateToGalleryPage, row.original.id, row.original.galleryType]);

  const onGalleryDeleteConfirm = useCallback(() => {
    deleteGallery(row.original.id, row.original.galleryType);
    setShowDeleteConfirmationDialog(false);
  }, [deleteGallery, row.original.id, row.original.galleryType]);

  const onDeleteGalleryClick = useCallback(() => {
    onMenuClose();
    setShowDeleteConfirmationDialog(true);
  }, [onMenuClose]);

  const onCloseDeleteDialog = useCallback(() => {
    setShowDeleteConfirmationDialog(false);
  }, []);

  const renderCell = ({
    cell,
  }: {
    cell: TableV2InstanceProps<IGalleryTableRow>['rows'][0]['cells'][0];
  }) => {
    switch (cell.column.id) {
      case tableColumns.rowActions.accessor: {
        return (
          <RowActionsMenu
            menuAnchorElement={menuAnchorElement}
            onMenuClicked={onMenuClicked}
            onMenuClose={onMenuClose}
            onEditGalleryClick={onEditGalleryClick}
            onDeleteGalleryClick={onDeleteGalleryClick}
            onViewGalleryClick={onViewGalleryClick}
          />
        );
      }
      case tableColumns.galleryName.accessor: {
        return (
          <GalleryNameCell variant='primary' onClick={onViewGalleryClick}>
            <TableV2.BodyRowCellText key={cell.column.id}>{cell.value}</TableV2.BodyRowCellText>
          </GalleryNameCell>
        );
      }
      default:
        return <TableV2.BodyRowCellText key={cell.column.id}>{cell.value}</TableV2.BodyRowCellText>;
    }
  };

  return (
    <>
      {showDeleteConfirmationDialog && (
        <DeleteGalleryConfirmationDialog
          onCloseDeleteDialog={onCloseDeleteDialog}
          onDelete={onGalleryDeleteConfirm}
          galleryName={row.original.galleryName}
        />
      )}
      <TableV2.BodyRow {...row.getRowProps()} key={row.original.id} className='catalog-table-row'>
        {row.cells.map(cell => {
          const propName = cell.column.id;

          return (
            <TableBodyRowCellStyled
              {...cell.getCellProps()}
              key={`${cell.column.id}-${cell.value}`}
              className='catalog-table-cell'
              data-id={`${row.original.galleryName}-${propName}`}
              cellName={propName}
              isResizable
            >
              {renderCell({ cell })}
            </TableBodyRowCellStyled>
          );
        })}
      </TableV2.BodyRow>
    </>
  );
};
