import React, { useCallback, useEffect } from 'react';
import { Dispatch } from 'src/components-bl';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { ValidationErrorKey } from 'src/containers/ToastManager/ActionsNotifications/validation-error-key';
import { FormStyled, LinkTypographyStyled, TitleTypographyStyled } from '../Login.styles';
import {
  TypographyWrapperStyled,
  HelpWrapperStyled,
  HelpTypographyStyled,
} from './VerificationEmailSentForm.styled';
import { loginActions } from '../Actions';

interface VerificationEmailSentFormProps {
  dispatch: Dispatch;
  goToLoginForm: () => void;
}

export const VerificationEmailSentForm = ({
  dispatch,
  goToLoginForm,
}: VerificationEmailSentFormProps): JSX.Element => {
  const onResendEmailClick = useCallback(() => {
    (dispatch(loginActions.resendTwoFactorAuthLink()) as any)
      .unwrap()
      .catch((error: { error?: { errorKey?: string } }) => {
        if (error.error?.errorKey !== ValidationErrorKey.AuthResendLinkRequestCoolDownPeriod) {
          goToLoginForm();
          dispatch(loginActions.resetTwoFactorAuthRequired());
        }
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(loginActions.setLoading(false));
  }, []);

  return (
    <FormStyled>
      <TitleTypographyStyled type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
        One More Step
      </TitleTypographyStyled>

      <TypographyWrapperStyled>
        <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
          To help secure your account, we need to verify your identity.
        </Typography>
        <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
          Click the verification link sent to your email.
        </Typography>
      </TypographyWrapperStyled>

      <HelpWrapperStyled>
        <HelpTypographyStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
          Didn&apos;t receive the email?
          <span onClick={onResendEmailClick}>
            <LinkTypographyStyled
              type={TypographyType.Body}
              variant={TypographyVariant.SmallMedium}
            >
              Resend it here
            </LinkTypographyStyled>
          </span>
        </HelpTypographyStyled>
        <HelpTypographyStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
          Having trouble logging in?
          <a
            href='https://syte-ai.atlassian.net/servicedesk/customer/portal/5'
            target='_blank'
            rel='noopener noreferrer'
          >
            <LinkTypographyStyled
              type={TypographyType.Body}
              variant={TypographyVariant.SmallMedium}
            >
              Contact us
            </LinkTypographyStyled>
          </a>
        </HelpTypographyStyled>
      </HelpWrapperStyled>
    </FormStyled>
  );
};
