import React from 'react';
import { SideNavItem } from './SideNavItem';
import { ISideNavItem } from '../types';

interface ISideNavListItem {
  route: ISideNavItem;
  isMinimized: boolean;
  handleNavigate: (url: string) => void;
  setMinimized: () => void;
}

export const SideNavListItem = ({
  route,
  isMinimized,
  handleNavigate,
  setMinimized,
}: ISideNavListItem) => (
  <li key={route.title}>
    <SideNavItem
      item={route}
      onNavigate={url => {
        handleNavigate(url);
      }}
      isMinimized={isMinimized}
      onRouteGroupClick={() => {
        if (isMinimized) {
          setMinimized();
        }
      }}
    />
  </li>
);
