import React, { useEffect, useState } from 'react';
import {
  SelectModalProps,
  ChildrenArguments,
  SelectModalContent,
} from 'src/components-dummy/SelectModal';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy/Typography';
import { DeepTagLexiconItem, ILexiconValue } from 'src/services';
import { convertSyteLocaleToStandardLocale } from 'src/utils/convert-syte-locale-to-standard-locale/convert-syte-locale-to-standard-locale';

interface DeepTagsProductSelectValueModalProps {
  onCancel?: SelectModalProps['onCancel'];
  onSelect: (value: ILexiconValue) => void;
  title?: string;
  lexiconAttributeValues?: Record<string, ILexiconValue>;
  originalAttributeValue: DeepTagLexiconItem;
  currentAttributeValue: DeepTagLexiconItem;
  locale: string;
}

export const DeepTagsProductSelectValueModal = ({
  onCancel,
  onSelect,
  title,
  lexiconAttributeValues,
  originalAttributeValue,
  currentAttributeValue,
  locale,
}: DeepTagsProductSelectValueModalProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  const handleSelect = (value: ILexiconValue) => () => {
    onSelect(value);
  };

  const fixedLocale = convertSyteLocaleToStandardLocale(locale);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 150);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <SelectModalContent
      title={title}
      onCancel={onCancel}
      valueSelectionText={!lexiconAttributeValues ? '' : 'Select value:'}
      errorMessage={
        !lexiconAttributeValues ? (
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
            No supported values for this attribute
          </Typography>
        ) : undefined
      }
      itemsLoading={isLoading}
    >
      {({ filterTerm }: ChildrenArguments) => {
        if (!lexiconAttributeValues) return null;

        return Object.keys(lexiconAttributeValues).reduce((accumulator: any, valueKey: string) => {
          const valueItem: ILexiconValue = lexiconAttributeValues[valueKey];
          const valueItemText = valueItem.customTranslation || valueItem.translation;

          const filterTermLowerCase = filterTerm.toLocaleLowerCase(fixedLocale);

          // Filter out items (according to filterTerm)
          if (
            filterTerm &&
            !valueItemText?.toLocaleLowerCase(fixedLocale).includes(filterTermLowerCase)
          )
            return accumulator;

          const isOriginalSelectedValue = originalAttributeValue?.translation === valueItemText;

          accumulator.push(
            <SelectModalContent.SelectItem
              key={valueItem.key}
              onClick={handleSelect(valueItem)}
              isSelected={currentAttributeValue?.translation === valueItemText}
            >
              <SelectModalContent.SelectItemText>
                {isOriginalSelectedValue ? `${valueItemText} (original value)` : valueItemText}
              </SelectModalContent.SelectItemText>
            </SelectModalContent.SelectItem>
          );

          return accumulator;
        }, []);
      }}
    </SelectModalContent>
  );
};
