import React, { useCallback, useState } from 'react';
import { useTheme } from '@emotion/react';
import { SelectOnChangeEvent, Button } from 'src/components-dummy';
import { DeepTagProductStatusType } from 'src/services';
import { DeepTagProductStatusWithButtonStyled } from './DeepTagProductStatusSelect.styles';
import { getColorByStatus, textByStatusMap } from './DeepTagProductStatusSelect.config';
import { DeepTagProductStatusSelect } from './DeepTagProductStatusSelect';

const MenuProps = {
  autoFocus: false,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  PaperProps: {
    sx: {
      width: '153px',
      marginTop: `-4px`,
    },
  },
};

export type OnStatusButtonClickArguments = { status: DeepTagProductStatusType };

interface DeepTagProductStatusSelectProps {
  isDisabled?: boolean;
  onButtonClick: ({ status }: OnStatusButtonClickArguments) => void;
}

/**
 * Main
 */
export const DeepTagProductStatusSelectWithButton = ({
  isDisabled = false,
  onButtonClick,
}: DeepTagProductStatusSelectProps): JSX.Element => {
  const [selectedStatus, setSelectedStatus] = useState(DeepTagProductStatusType.Done);

  const theme = useTheme();

  const selectedColor = getColorByStatus(theme, selectedStatus);

  const onStatusSelectChange: SelectOnChangeEvent = useCallback(event => {
    const newStatusValue = event.target.value as DeepTagProductStatusType;
    setSelectedStatus(newStatusValue);
  }, []);

  const handleButtonClick = useCallback(() => {
    onButtonClick({ status: selectedStatus });
  }, [onButtonClick, selectedStatus]);

  return (
    <DeepTagProductStatusWithButtonStyled color={selectedColor} isDisabled={isDisabled}>
      <Button variant='primary' disabled={isDisabled} onClick={handleButtonClick}>
        {textByStatusMap[selectedStatus]}
      </Button>
      <DeepTagProductStatusSelect
        value={selectedStatus}
        showText={false}
        onChange={onStatusSelectChange}
        MenuProps={MenuProps}
        isDisabled={isDisabled}
      />
    </DeepTagProductStatusWithButtonStyled>
  );
};
