import styled from '@emotion/styled';

export const DeepTagReportsContainerPageHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const DeepTagReportsContainerPageTitlesStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ConfigurationButtonWrapperStyled = styled.div`
  button:first-of-type {
    margin-right: 8px;
  }
`;
