type ObjectToFlatten = Record<string, any>;

export const flattenObject = (obj: ObjectToFlatten, prefix = ''): ObjectToFlatten => {
  return Object.keys(obj).reduce((acc: ObjectToFlatten, key: string) => {
    const prefixedKey = prefix ? `${prefix}.${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      const flattened = flattenObject(obj[key], prefixedKey);
      Object.keys(flattened).forEach(nestedKey => {
        acc[nestedKey] = flattened[nestedKey];
      });
    } else {
      acc[prefixedKey] = obj[key];
    }
    return acc;
  }, {});
};
