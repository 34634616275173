import styled from '@emotion/styled';

export const MerchandisingRuleCard = styled.div<{ active: boolean; isApplied: boolean }>`
  padding: 8px 12px;
  border-radius: 4px;
  background-color: ${props => (props.isApplied ? '#FAF5FF' : '#f2f2f2')};
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  gap: 12px;

  &:hover {
    cursor: pointer;
  }

  &.selected-rule {
    border: 1px solid #9333ea;
  }

  .rule-title {
    color: ${props => (props.active ? '#1E1E1E' : '#AFAFAF')};
  }

  .rule-action {
    color: ${props => (props.active ? '#6E6E71' : '#AFAFAF')};
  }
`;
