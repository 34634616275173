import {
  ErrorBoundaryActionType,
  ErrorBoundaryUncaughtErrorAction,
  ErrorBoundaryUncaughtErrorActionPayload,
} from './types';

export const errorBoundaryActions = {
  uncaughtError: ({
    error,
    errorInfo,
  }: ErrorBoundaryUncaughtErrorActionPayload): ErrorBoundaryUncaughtErrorAction => {
    return {
      type: ErrorBoundaryActionType.UncaughtError,
      payload: { error, errorInfo },
    };
  },
};
