import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetRulesOverviewArguments,
  GetRulesArguments,
} from 'src/services/src/service/merchandising-rules/types';
import { MerchandisingRules, MerchandisingRuleTypes } from 'src/services';
import { RuleDraft } from './MerchandisingRuleForm.config';
import { ruleFormMappers } from './MerchandisingRuleForm.mappers';

export interface PartialRuleWithId
  extends Partial<Omit<MerchandisingRuleTypes.MerchandiseRule, 'id'>>,
    Pick<MerchandisingRuleTypes.MerchandiseRule, 'id'> {}

const merchandisingRulesService = new MerchandisingRules();

export interface UpdateMerchRuleArgs {
  ruleDraft: Required<RuleDraft>;
  shopId: number;
  variantId?: string;
}

export const merchandisingRulesFormActions = {
  reset: createAction('MerchandisingRuleForm/reset'),
  updateMerchandisingRule: createAsyncThunk(
    'MerchandisingRuleForm/updateMerchandisingRule',
    async (
      {
        ruleDraft,
        shopId,
        variantId,
      }: {
        ruleDraft: Required<RuleDraft>;
        shopId: number;
        variantId?: string;
      },
      { rejectWithValue }
    ) => {
      try {
        const rule = ruleFormMappers.mapRuleDraftToApiRule(
          ruleDraft
        ) as MerchandisingRuleTypes.MerchandiseRule; // TODO Typing
        const updatedRule = await merchandisingRulesService.update({
          shopId,
          rule,
          variantId,
        });

        return {
          rule: updatedRule,
        };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  updateAndGetMerchRule: createAsyncThunk(
    'MerchandisingRuleForm/UpdateAndGetMerchRule',
    async ({ ruleDraft, shopId, variantId }: UpdateMerchRuleArgs, { rejectWithValue }) => {
      try {
        const rule = ruleFormMappers.mapRuleDraftToApiRule(
          ruleDraft
        ) as MerchandisingRuleTypes.MerchandiseRule; // TODO Typing

        const updatedRule = await merchandisingRulesService.update({
          shopId,
          rule,
          variantId,
        });

        return {
          rule: updatedRule,
        };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  createMerchandisingRule: createAsyncThunk(
    'MerchandisingRuleForm/createMerchandisingRule',
    async (
      {
        ruleDraft,
        shopId,
        variantId,
      }: {
        ruleDraft: Required<RuleDraft>;
        shopId: number;
        variantId?: string;
      },
      { rejectWithValue }
    ) => {
      try {
        const rule = ruleFormMappers.mapRuleDraftToApiRule(ruleDraft);
        const createdRule = await merchandisingRulesService.create({ shopId, rule, variantId });

        return {
          rule: createdRule,
        };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getRulesOverview: createAsyncThunk(
    'MerchandisingRuleForm/GetRulesOverview',
    async (parameters: GetRulesOverviewArguments, { rejectWithValue }) => {
      try {
        const overview = await merchandisingRulesService.getRulesOverview(parameters);
        return overview;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  notifyIsDirty: createAction<{ isDirty: boolean }>('MerchandisingRuleForm/NotifyIsDirty'),

  getRulesCountForValidation: createAsyncThunk(
    'MerchandisingRuleForm/GetRulesCountForValidation',
    async (parameters: GetRulesArguments, { rejectWithValue }) => {
      try {
        const { pagination } = await merchandisingRulesService.getRules(parameters);
        return pagination.totalCount;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
