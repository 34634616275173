import React, { useEffect, useState, useRef, useCallback } from 'react';

/** *
 * Constants
 */

const ESCAPE = [27, 'Escape'];
const ENTER = [13, 'Enter'];

/**
 * Main
 */
type InputRefType = React.RefObject<HTMLInputElement | null>;
type ContainerRefType = React.RefObject<HTMLDivElement | null>;

export interface UseEditableTextResponse {
  onToggleEditMode: (isEditMode: boolean) => void;
  onChangeHandler: (newValue: string) => void;
  isInEditMode: boolean;
  editedText?: string; // Final - onblur / revert
  tempText?: string;
  inputRef?: InputRefType;
  setInputRef: (textElement: HTMLInputElement | null) => InputRefType;
  setContainerRef: (element: HTMLDivElement | null) => ContainerRefType;
  setIsValid: (isValid: boolean) => void;
  resetValue: () => void;
  isValid: boolean;
}

interface UseEditableTextProps {
  currentText?: string;
  onValidateTextInput?: (inputValue: string) => boolean;
}

export const useEditableText = ({
  currentText,
  onValidateTextInput,
}: UseEditableTextProps): UseEditableTextResponse => {
  const [tempText, setTempText] = useState<string | undefined>(currentText);
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [editedText, setEditedText] = useState<string | undefined>(currentText);
  const [isValid, setIsValid] = useState<boolean>(true);

  /**
   * Invoked on blur / revert (escape)/ toggle edit mode
   */
  const onFinalTextResult = (changedValue: string) => {
    setEditedText(changedValue);
  };

  const setContainerRef = (element: HTMLDivElement | null) => {
    containerRef.current = element;
    return containerRef;
  };

  const setInputRef = (inputElem: HTMLInputElement | null) => {
    inputRef.current = inputElem;
    return inputRef;
  };

  const onChangeHandler = useCallback((newValue: string) => {
    setTempText(newValue);
  }, []);

  const onToggleEditMode = (isEditMode: boolean) => {
    /* Update value when value is not empty */
    if (isValid && tempText && tempText !== currentText) {
      onFinalTextResult(tempText);
    }

    if (!isValid) {
      inputRef.current?.focus();
    } else {
      setIsInEditMode(isEditMode);
    }
  };

  useEffect(() => {
    setTempText(currentText);
    setEditedText(currentText);
  }, [currentText]);

  useEffect(() => {
    if (isInEditMode) {
      inputRef.current?.focus();
    }
  }, [isInEditMode]);

  const resetValue = useCallback(() => {
    onFinalTextResult(currentText as string);
    setTempText(currentText);

    if (inputRef?.current?.value) {
      inputRef.current.value = currentText || '';
    }

    setIsInEditMode(false);
  }, [currentText]);

  const resetValueWithBackToReadOnly = useCallback(() => {
    resetValue();
    setIsInEditMode(false);
  }, [resetValue]);

  const onKeyDown = useCallback(
    event => {
      event.stopPropagation();

      if (ESCAPE.includes(event.code) && document.activeElement === inputRef.current) {
        resetValueWithBackToReadOnly();
        setIsValid(true);
        onValidateTextInput?.(currentText || '');
      }

      if (isValid && inputRef?.current && ENTER.includes(event.code)) {
        inputRef?.current?.blur();
      }
    },
    [resetValue, currentText, isValid]
  );

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    if (isInEditMode) containerRef.current.addEventListener('keydown', onKeyDown);
    else containerRef.current.removeEventListener('keydown', onKeyDown);
    return () => containerRef.current?.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown, isInEditMode]);

  return {
    onToggleEditMode,
    onChangeHandler,
    isInEditMode,
    editedText,
    tempText,
    setInputRef,
    setContainerRef,
    inputRef,
    setIsValid,
    resetValue,
    isValid,
  };
};
