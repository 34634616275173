import styled from '@emotion/styled';
import { ImageButtonStyled } from './shared.styles';

export const DeleteImageButtonStyled = styled(ImageButtonStyled)`
  right: 10px;
  top: 10px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom['gray-60']};
  }
`;
