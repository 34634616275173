import { useCallback, useMemo, useState } from 'react';
import { IDeepTagReportProduct, IDeepTagsProductDataItem } from 'src/services';

type OnTabIndexChange = (index: number) => void;

export interface UseDeepTagsSubProductsListTabsResponse {
  selectedTabIndex: number;
  onTabIndexChange: OnTabIndexChange;
  tabNames: string[];
}

interface UseDeepTagsSubProductsListTabsProps {
  initSelectedTabIndex: number;
  productDataList: IDeepTagReportProduct['data'];
}

export const useDeepTagsSubProductsListTabs = ({
  initSelectedTabIndex = 0,
  productDataList,
}: UseDeepTagsSubProductsListTabsProps): UseDeepTagsSubProductsListTabsResponse => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(initSelectedTabIndex);

  const onTabIndexChange: OnTabIndexChange = useCallback(index => {
    setSelectedTabIndex(index);
  }, []);

  const categoryNames = useMemo(() => {
    return productDataList.map((dataItem: IDeepTagsProductDataItem) => {
      return dataItem.category.translation || 'No Category';
    });
  }, [productDataList]);

  return {
    selectedTabIndex,
    onTabIndexChange,
    tabNames: categoryNames,
  };
};
