import styled from '@emotion/styled';
import { MenuItem } from 'src/components-dummy';

export const MenuActionsStyled = styled.div`
  display: flex;
  justify-content: end;

  .MuiButtonBase-root {
    height: 32px;
    align-items: end !important;
  }
`;

interface MenuItemStyledProps {
  disabled?: boolean;
  shouldHighlightRed?: boolean;
}

export const MenuItemStyled = styled(MenuItem)<MenuItemStyledProps>`
  color: ${({ theme, disabled = false, shouldHighlightRed = false }) => {
    if (disabled) {
      return theme.palette.custom['gray-20'];
    }

    if (shouldHighlightRed) {
      return theme.palette.custom.red;
    }

    return theme.palette.common.black;
  }};
`;
