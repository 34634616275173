import { Dispatch } from 'src/components-bl';

export enum FiltersSetsNavigationActionTypes {
  NavigateTo = 'FiltersSetsActions/NavigateTo',
  NotifyIsDirty = 'FiltersSetsActions/NotifyIsDirty',
}

interface FiltersSetsActionsNavigateToActionPayload {
  navigateTo: string;
}

type FiltersFormNotifyIsDirtyActionPayload = { isDirty: boolean };

interface FiltersSetsActionsNavigateToAction {
  type: typeof FiltersSetsNavigationActionTypes.NavigateTo;
  payload: FiltersSetsActionsNavigateToActionPayload;
}
interface CatalogFiltersFormNotifyIsDirtyAction {
  type: FiltersSetsNavigationActionTypes.NotifyIsDirty;
  payload: FiltersFormNotifyIsDirtyActionPayload;
}

export type FiltersSetsNavigationAction =
  | FiltersSetsActionsNavigateToAction
  | CatalogFiltersFormNotifyIsDirtyAction;

const filtersSetsNavigationActions = {
  navigateTo(payload: FiltersSetsActionsNavigateToActionPayload): FiltersSetsNavigationAction {
    return {
      type: FiltersSetsNavigationActionTypes.NavigateTo,
      payload,
    };
  },

  notifyIsDirty: (payload: FiltersFormNotifyIsDirtyActionPayload): FiltersSetsNavigationAction => {
    return {
      type: FiltersSetsNavigationActionTypes.NotifyIsDirty,
      payload,
    };
  },
};

export const filtersSetsNavigationActionMethods = {
  navigateTo(payload: FiltersSetsActionsNavigateToActionPayload) {
    return (dispatch: Dispatch): void => {
      dispatch(filtersSetsNavigationActions.navigateTo(payload));
    };
  },
  notifyIsDirty(payload: FiltersFormNotifyIsDirtyActionPayload) {
    return (dispatch: Dispatch): void => {
      dispatch(filtersSetsNavigationActions.notifyIsDirty(payload));
    };
  },
};
