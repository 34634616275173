import React from 'react';
import styled from '@emotion/styled';
import { Card, CardProps } from '../Card';

export const CardItemStyled = styled((props: CardProps) => <Card {...props} />)<{
  isClickable: boolean;
  isSelected: boolean;
}>`
  ${({ isClickable }) => {
    return isClickable ? 'cursor: pointer;' : '';
  }}

  border-color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.custom['primary-main'] : theme.palette.custom['gray-60']};
  transition: border-color 0.1s;

  &:hover {
    ${({ isClickable, theme }) => {
      return isClickable ? `border-color: ${theme.palette.custom['primary-main']}` : '';
    }}
  }
`;
