import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  BrowsePlpPayload,
  CollectionItemsPayload,
  DiscoveryButtonPayload,
  SimilarItemsPayload,
} from '../types';
import { RootReducerState } from '../../../app-state-types';
import { visualEditorService } from '../../../services';
import { VisualEditorMapper } from '../helpers';
import { TextSearchItemsPayload } from '../types/text-search';

export const visualEditorFetchOffersActions = {
  getCollectionItems: createAsyncThunk(
    'VisualEditor/GetCollections',
    async (payload: CollectionItemsPayload, { rejectWithValue, getState, signal }) => {
      try {
        const state = (getState() as RootReducerState).visualEditor;
        const { data } = await visualEditorService.getCollections(payload, signal);
        const loadedOffers = payload.from ? [...state.offers, ...data.offers] : data.offers;
        return VisualEditorMapper.mapOffersData({ ...data, offers: loadedOffers });
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getSimilarItems: createAsyncThunk(
    'VisualEditor/GetSimilarItems',
    async (payload: SimilarItemsPayload, { rejectWithValue, signal }) => {
      try {
        const { data } = await visualEditorService.getSimilarItems(payload, signal);
        return VisualEditorMapper.mapOffersData(data);
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getShopTheLookItems: createAsyncThunk(
    'VisualEditor/GetShopTheLook',
    async (payload: SimilarItemsPayload, { rejectWithValue, signal }) => {
      try {
        const { data } = await visualEditorService.getShopTheLook(payload, signal);
        return VisualEditorMapper.mapOffersData(data);
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getDiscoveryButtonItems: createAsyncThunk(
    'VisualEditor/GetDiscoveryButton',
    async (payload: DiscoveryButtonPayload, { rejectWithValue, signal }) => {
      try {
        const { data } = await visualEditorService.getDiscoveryButton(payload, signal);
        return VisualEditorMapper.mapOffersData(data);
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getBrowsePLPItems: createAsyncThunk(
    'VisualEditor/getBrowsePlpItems',
    async (payload: BrowsePlpPayload, { rejectWithValue, getState, signal }) => {
      try {
        const state = (getState() as RootReducerState).visualEditor;
        const { data } = await visualEditorService.getBrowsePlpItems(payload, signal);
        const loadedOffers = payload.from ? [...state.offers, ...data.offers] : data.offers;
        return VisualEditorMapper.mapOffersData({ ...data, offers: loadedOffers });
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getTextSearchItems: createAsyncThunk(
    'VisualEditor/GetTextSearchItems',
    async (payload: TextSearchItemsPayload, { rejectWithValue, getState, signal }) => {
      try {
        const state = (getState() as RootReducerState).visualEditor;
        const { data } = await visualEditorService.getTextSearch(payload, signal);
        const loadedOffers = payload.from ? [...state.offers, ...data.offers] : data.offers;
        return VisualEditorMapper.mapOffersData({ ...data, offers: loadedOffers });
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
