import styled from '@emotion/styled';
import { EllipsisWithTooltip, TableV2 } from 'src/components-dummy';

export const TableBodyRowCellStyled = styled(TableV2.BodyRowCell)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.black};
  padding-top: 10px;
  padding-bottom: 10px;
  max-height: 40px;

  a {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.common.black} !important;
    text-decoration: underline;
  }
`;

export const IndexedFullyStateStyled = styled.span``;

export const NumberOfIndexedProductsStyled = styled.span`
  min-width: 50px;
  margin-right: 8px;
`;

export const EllipsisWithTooltipStyled = styled(EllipsisWithTooltip)`
  &.ellipsis-with-tooltip {
    width: 100%;
  }
`;
