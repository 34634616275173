import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { AppRoute, AppSwitch, RoutedComponentProps } from 'src/app-routes';
import { getOldestNonExpiredApiKey } from 'src/components-bl/ApiKeysSettings';
import { EditCustomInspirationsGalleryPage } from 'src/components-bl/Galleries';
import { useHasDraft } from 'src/components-bl/hooks';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useCustomInspirationsGallerySelector } from './useCustomInspirationsGallerySelector';
import { AddImagesContainer } from './AddImagesContainer';
import { TagImageContainer } from './TagImageContainer';
import { useGalleriesWS } from './useGalleriesWS';
import { CustomInspirationsGallery, GalleryType } from 'src/services';
import { TagsDetectionBackdropLoader } from './TagsDetectionBackdropLoader/TagsDetectionBackdropLoader';
import { MAX_ALLOWED_IMAGES } from 'src/components-bl/Galleries/constants';

interface EditCustomInspirationsContainerProps extends RoutedComponentProps {
  shopId: number;
}

export const EditCustomInspirationsContainer = ({
  permittedRouteMap,
  shopId,
}: EditCustomInspirationsContainerProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { galleryId } = useParams<{ galleryId: string }>();

  const apiKeys = useAppSelector(state => state.apiKeys.apiKeys);

  const gallery = useCustomInspirationsGallerySelector();
  const hasDraft = useHasDraft();

  useGalleriesWS({ dispatch, selectedGalleryId: galleryId, shopId });

  const isDetectingTagsInGallery = useMemo(() => {
    if (gallery?.galleryType !== GalleryType.CustomInspirations || !gallery) {
      return false;
    }

    const customInspirationsGallery = gallery as CustomInspirationsGallery;

    return (
      customInspirationsGallery.images.some(image => image.isDetectingTags) ||
      customInspirationsGallery.isDetectingTags ||
      false
    );
  }, [gallery, shopId]);

  const allowedToAddImages: number | undefined = useMemo(() => {
    if (!gallery) {
      return undefined;
    }

    return Math.max(0, MAX_ALLOWED_IMAGES - gallery.images.length);
  }, [gallery]);

  return (
    <>
      <TagsDetectionBackdropLoader show={isDetectingTagsInGallery} />

      <AppSwitch>
        <AppRoute
          route={permittedRouteMap.addImagesToCustomInspirationsGallery}
          Component={AddImagesContainer}
          componentProps={{ permittedRouteMap, shopId, allowedToAddImages }}
          exact
        />
        <AppRoute
          route={permittedRouteMap.tagImageInCustomInspirationsGallery}
          Component={TagImageContainer}
          componentProps={{ permittedRouteMap, shopId }}
          exact
        />
      </AppSwitch>

      <EditCustomInspirationsGalleryPage
        dispatch={dispatch}
        gallery={gallery}
        galleryId={galleryId}
        shopId={shopId}
        permittedRouteMap={permittedRouteMap}
        hasDraft={hasDraft}
        shopApiKey={getOldestNonExpiredApiKey(apiKeys)}
        allowedToAddImages={allowedToAddImages}
      />
    </>
  );
};
