import React from 'react';
import { ICatalogProduct, ShopDataField } from 'src/services';
import { CatalogExplorerTable } from '../CatalogExplorerTable';
import { OnPaginationChange, PaginationState, PaginationToolBar } from 'src/components-dummy';
import { ProductsContainerStyled } from './CatalogExplorerProductsView.styles';
import { CatalogExplorerTableSkeleton } from '../CatalogExplorerTable/CatalogExplorerTableSkeleton';

interface CatalogExplorerProductsViewProps {
  shopId: number;
  catalogProducts?: ICatalogProduct[];
  totalCatalogProducts?: number;
  dataFieldsMapByName: Record<string, ShopDataField>;
  isPendingOnFetchProducts: boolean;
  paginationState: PaginationState;
  locale?: string;
  onPaginationChange: OnPaginationChange;
}

export const CatalogExplorerProductsView = React.memo(
  ({
    catalogProducts,
    totalCatalogProducts,
    dataFieldsMapByName,
    isPendingOnFetchProducts,
    paginationState,
    locale,
    onPaginationChange,
  }: CatalogExplorerProductsViewProps): JSX.Element => {
    if (!catalogProducts) {
      return <CatalogExplorerTableSkeleton />;
    }

    return (
      <>
        <ProductsContainerStyled>
          <CatalogExplorerTable
            dataFieldsMapByName={dataFieldsMapByName}
            catalogProducts={catalogProducts}
            paginationState={paginationState}
            isPendingOnFetchProducts={isPendingOnFetchProducts}
            locale={locale}
          />
        </ProductsContainerStyled>
        <PaginationToolBar
          skip={paginationState.skip}
          limit={paginationState.limit}
          totalItems={totalCatalogProducts || 0}
          onChange={onPaginationChange}
        />
      </>
    );
  }
);
