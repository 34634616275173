import React, { useEffect, useMemo } from 'react';
import './List.scss';
import { useIsInViewPort, UseInViewPortArguments } from 'src/hooks';
import classNames from 'classnames';
import { useFindComponentByType } from '../hooks';
import { ChildrenProp, GenericObject } from '../types';
import { EmptyTemplate } from './EmptyTemplate';
import { CardTemplate } from './CardTemplate';

export interface ListProps<T> extends ChildrenProp {
  items?: GenericObject<T>[];
  loading?: boolean;
  itemClass?: string;
  idField: keyof NonNullable<T>;
  selectedId?: number | string;
  onItemSelected?: (item: T) => void;
  className?: string;
  loadMore?: () => void;
  useInViewPortOptions?: UseInViewPortArguments;
}

export interface ListItemProps {
  index: number;
  isSelected: boolean;
}

export const List = <T extends GenericObject<T>>({
  items,
  itemClass,
  children,
  idField,
  selectedId,
  onItemSelected,
  className,
  loadMore,
  useInViewPortOptions = {},
}: ListProps<T>): JSX.Element => {
  const { isInView, setRef } = useIsInViewPort(useInViewPortOptions);

  const emptyTemplate = useFindComponentByType({ type: EmptyTemplate, children });
  const cardTemplate = useFindComponentByType({ type: CardTemplate, children })?.props.children;

  const NoResultsWrapped = (
    <div className='syte-list-no-results'>
      {emptyTemplate || <h1>Wow...so empty in here...</h1>}
    </div>
  );

  const mappedItems = useMemo(() => {
    return (
      cardTemplate &&
      items?.length &&
      items.map((item, index) => {
        const isSelected = item[idField as keyof T] === selectedId;
        const itemProps: ListItemProps = { index, isSelected };
        return (
          <li
            className={classNames('syte-list-item', itemClass)}
            key={item[idField as keyof T]}
            onClick={() => onItemSelected?.(item)}
            ref={setRef}
          >
            {cardTemplate(item, itemProps)}
          </li>
        );
      })
    );
  }, [cardTemplate, items, idField, setRef, onItemSelected, selectedId]);

  useEffect(() => {
    if (isInView) {
      loadMore?.();
    }
  }, [isInView]);

  return <ul className={classNames('syte-list', className)}>{mappedItems || NoResultsWrapped}</ul>;
};

List.EmptyTemplate = EmptyTemplate;
List.CardTemplate = CardTemplate;
