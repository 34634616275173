import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { DeepTagReportsService } from 'src/services';

const deepTagReportsService = new DeepTagReportsService();

/**
 * Actions
 */
export const deepTagReportsContainerActions = {
  getConfiguration: createAsyncThunk(
    'DeepTagReportsContainerActions/GetConfiguration',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const reportConfiguration = await deepTagReportsService.getReportConfiguration({
          shopId,
        });
        return reportConfiguration;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  resetConfiguration: createAction('DeepTagReportsContainerActions/ResetConfiguration'),
  navigateTo: createAction<{ navigateTo: string }>('DeepTagReportsContainerActions/navigateTo'),
};
