import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { CardStyled } from './Card.styles';

export interface CardProps {
  children: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
  className?: string;
}

export const Card = ({ children, onClick, onKeyDown, className }: CardProps): JSX.Element => {
  return (
    <CardStyled
      role='button'
      className={classNames('syte-card', className)}
      onClick={onClick}
      tabIndex={0}
      onKeyDown={onKeyDown}
    >
      {children}
    </CardStyled>
  );
};
