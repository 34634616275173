import {
  DownloaderTypesEnum,
  ICatalogPlanAPI,
  ICatalogPlanCreate,
  IShopSftpSettings,
  IsftpServerSettings,
  ShopCatalogVertical,
  genderTypes,
} from 'src/services';
import _omit from 'lodash/omit';
import {
  CatalogFormDraft,
  SFTP_DEFAULT_VALUES,
  DataSourceTypesEnum,
  ImportMethodTypesEnum,
  SFTPServerFormSettings,
  WGETServerFormSettings,
  DEFAULT_DAILY_TIME,
  SchedulingTypesEnum,
} from './CatalogForm.config';

const SFTP = 'SFTP';

export const buildInitialGendersList = (): string[] =>
  Object.keys(genderTypes).filter(gender => gender !== genderTypes.hd);

export const buildDefaultScheduling = (): CatalogFormDraft['scheduling'] => {
  return {
    dailyTime: {
      hour: DEFAULT_DAILY_TIME.getHours(),
      minutes: DEFAULT_DAILY_TIME.getMinutes(),
    },
  };
};

const getSelectedDataSourceByDownloaderType = ({ catalog }: { catalog: ICatalogPlanAPI }) => {
  if (
    catalog.downloaderType === DownloaderTypesEnum.SFTP ||
    catalog.downloaderType === DownloaderTypesEnum.FTP ||
    catalog.downloaderType === DownloaderTypesEnum.WGET
  ) {
    return DataSourceTypesEnum.use_product_feed;
  }

  return DataSourceTypesEnum.manual_add_products;
};

/**
 * Shop default sftp settings
 */
export const mapDefaultSFTPSettings = ({
  shopSFTPSettingsCredentials,
  sftpServerSettings,
}: {
  shopSFTPSettingsCredentials: IShopSftpSettings;
  sftpServerSettings?: IsftpServerSettings;
}): SFTPServerFormSettings => {
  return {
    type: DownloaderTypesEnum.SFTP,
    host: SFTP_DEFAULT_VALUES.hostname,
    port: SFTP_DEFAULT_VALUES.port,
    path: SFTP_DEFAULT_VALUES.path,
    fileName: sftpServerSettings?.fileName || '',
    authentication: true,
    username: shopSFTPSettingsCredentials.userName,
    password: shopSFTPSettingsCredentials.password,
  };
};

export const mapCustomSFTPSettings = ({
  sftpServerSettings,
}: {
  sftpServerSettings?: ICatalogPlanAPI['sftpServerSettings'];
}): SFTPServerFormSettings => {
  return {
    type: sftpServerSettings?.type || SFTP,
    host: sftpServerSettings?.host || '',
    port: sftpServerSettings?.port ? sftpServerSettings?.port.toString() : '',
    path: sftpServerSettings?.path || '',
    fileName: sftpServerSettings?.fileName || '',
    authentication: !!sftpServerSettings?.authentication,
    username: sftpServerSettings?.username || '',
    password: sftpServerSettings?.password || '',
  };
};

export const buildClearedCustomSFTPSettings = (): SFTPServerFormSettings => {
  return {
    type: DownloaderTypesEnum.SFTP,
    host: '',
    port: '',
    path: '',
    fileName: '',
    authentication: true,
    username: '',
    password: '',
  };
};

export const mapCustomWGETSettings = ({
  dataSourceURL,
  authentication,
  username,
  password,
}: {
  dataSourceURL?: string;
  authentication?: boolean;
  username?: string;
  password?: string;
}): WGETServerFormSettings => {
  return {
    dataSourceURL: dataSourceURL || '',
    authentication: !!authentication,
    username: username || '',
    password: password || '',
  };
};

const checkIsUsingCustomSftp = ({
  shopSFTPSettingsCredentials,
  sftpServerSettings,
}: {
  shopSFTPSettingsCredentials?: IShopSftpSettings;
  sftpServerSettings?: ICatalogPlanAPI['sftpServerSettings'];
}): boolean => {
  if (!shopSFTPSettingsCredentials) {
    // If user doesn't have credentials - only custom configuration will be available to him
    return true;
  }

  return sftpServerSettings?.host !== SFTP_DEFAULT_VALUES.hostname;
};

export const catalogFormMappers = {
  mapApiCatalogToCatalogDraft({
    catalog,
    shopSFTPSettingsCredentials,
  }: {
    catalog?: ICatalogPlanAPI;
    shopSFTPSettingsCredentials?: IShopSftpSettings;
  }): CatalogFormDraft {
    if (!catalog) {
      /**
       * Create Mode (new)
       */
      const isUsingCustomSftp = !shopSFTPSettingsCredentials;
      const initialCatalog = {
        name: '',
        verticals: [ShopCatalogVertical.Fashion],
        genders: buildInitialGendersList(), // Genders without HD
        isUsingCustomSftp,
        selectedDataSource: DataSourceTypesEnum.manual_add_products,
        sftpServerSettings: shopSFTPSettingsCredentials
          ? mapDefaultSFTPSettings({
              shopSFTPSettingsCredentials,
            })
          : mapCustomSFTPSettings({
              sftpServerSettings: undefined,
            }),
      };

      return initialCatalog;
    }

    /**
     * Edit Mode
     */
    const catalogToEdit: CatalogFormDraft = {
      name: catalog.name,
      verticals: catalog.verticals,
      genders: catalog.genders || Object.keys(genderTypes),
      selectedDataSource: getSelectedDataSourceByDownloaderType({ catalog }),
    };

    /// SFTP map settings according to default/custom
    if (
      catalog.downloaderType === DownloaderTypesEnum.SFTP ||
      catalog.downloaderType === DownloaderTypesEnum.FTP
    ) {
      catalogToEdit.isUsingCustomSftp = checkIsUsingCustomSftp({
        sftpServerSettings: catalog?.sftpServerSettings,
        shopSFTPSettingsCredentials,
      });

      // Set "sftpServerSettings" to be default
      if (!catalogToEdit.isUsingCustomSftp && shopSFTPSettingsCredentials) {
        catalogToEdit.sftpServerSettings = mapDefaultSFTPSettings({
          shopSFTPSettingsCredentials,
          sftpServerSettings: catalog?.sftpServerSettings,
        });
      } else {
        catalogToEdit.sftpServerSettings = mapCustomSFTPSettings({
          sftpServerSettings: catalog.sftpServerSettings,
        });
      }
    }

    // Set "selectedImportMethod" according to downloaderType (SFTP/WGET)
    if (catalogToEdit.selectedDataSource === DataSourceTypesEnum.use_product_feed) {
      if (
        catalog.downloaderType === DownloaderTypesEnum.SFTP ||
        catalog.downloaderType === DownloaderTypesEnum.FTP
      ) {
        catalogToEdit.selectedImportMethod = ImportMethodTypesEnum.upload_sftp_server;
      }

      if (catalog.downloaderType === DownloaderTypesEnum.WGET) {
        catalogToEdit.selectedImportMethod = ImportMethodTypesEnum.upload_wget_server;
      }
    }

    // Set "wget" to be mapped incase "wgetServerSettings" defined for catalog
    if (catalog.wgetServerSettings) {
      catalogToEdit.wgetServerSettings = mapCustomWGETSettings(catalog.wgetServerSettings);
    }

    // Mapping scheduling for supported DownloaderTypesEnum (SFTP,FTP,WGET)
    if (
      [DownloaderTypesEnum.SFTP, DownloaderTypesEnum.FTP, DownloaderTypesEnum.WGET].includes(
        catalog.downloaderType
      )
    ) {
      if (catalog.scheduling) {
        if (catalog.scheduling.dailyTime) {
          catalogToEdit.selectedScheduleType = SchedulingTypesEnum.daily_time;
          catalogToEdit.scheduling = { dailyTime: catalog.scheduling.dailyTime };
        }

        if (catalog.scheduling.timeIntervals) {
          catalogToEdit.selectedScheduleType = SchedulingTypesEnum.time_intervals;
          catalogToEdit.scheduling = {
            timeIntervals: {
              hours: catalog.scheduling.timeIntervals.hours.toString(),
              minutes: catalog.scheduling.timeIntervals.minutes.toString().padStart(2, '0'),
            },
          };
        }
      } else {
        catalogToEdit.selectedScheduleType = SchedulingTypesEnum.none;
      }
    }

    return catalogToEdit;
  },
};

const mapDownloaderType = ({
  selectedDataSource,
  selectedImportMethod,
  sftpSettingsType,
}: {
  selectedDataSource: DataSourceTypesEnum;
  selectedImportMethod?: ImportMethodTypesEnum;
  sftpSettingsType?: string;
}): DownloaderTypesEnum => {
  if (selectedDataSource === DataSourceTypesEnum.manual_add_products) {
    return DownloaderTypesEnum.AWS_S3;
  }

  if (selectedImportMethod === ImportMethodTypesEnum.upload_sftp_server) {
    return sftpSettingsType as DownloaderTypesEnum;
  }

  return DownloaderTypesEnum.WGET;
};

const removeCredentialsForNoneAuth = (downloaderSettings: any): any => {
  if (!downloaderSettings?.authentication) {
    return _omit(downloaderSettings, ['password', 'username']);
  }

  return downloaderSettings;
};

const mapSFTPSettingsToAPI = (
  sftpSettings: SFTPServerFormSettings
): ICatalogPlanCreate['sftpServerSettings'] => {
  const mappedSftp = {
    ...sftpSettings,
    port: Number(sftpSettings.port),
  } as ICatalogPlanCreate['sftpServerSettings'];

  return mappedSftp;
};

/**
 * Map data for request POST/PUT
 */

export const mapFormDraftToAPI = (catalogFormDraft: CatalogFormDraft): ICatalogPlanCreate => {
  const {
    name,
    verticals,
    genders,
    productsFile,
    selectedDataSource,
    selectedImportMethod,
    sftpServerSettings,
    wgetServerSettings,
    scheduling,
  } = catalogFormDraft;

  const downloadType: DownloaderTypesEnum = mapDownloaderType({
    selectedDataSource,
    selectedImportMethod,
    sftpSettingsType: sftpServerSettings?.type,
  });

  const mappedData: Partial<ICatalogPlanCreate> = {
    name,
    verticals,
    genders,
    downloaderType: downloadType,
  };

  if (downloadType === DownloaderTypesEnum.AWS_S3) {
    mappedData.productsFile = productsFile;
  }

  if (downloadType === DownloaderTypesEnum.FTP) {
    const authMapped = removeCredentialsForNoneAuth(sftpServerSettings);
    mappedData.sftpServerSettings = mapSFTPSettingsToAPI(authMapped);
    mappedData.downloaderType = DownloaderTypesEnum.FTP;
  }

  if (downloadType === DownloaderTypesEnum.SFTP) {
    const authMapped = removeCredentialsForNoneAuth(sftpServerSettings);
    mappedData.sftpServerSettings = mapSFTPSettingsToAPI(authMapped);
    mappedData.downloaderType = DownloaderTypesEnum.SFTP;
  }

  if (downloadType === DownloaderTypesEnum.WGET) {
    mappedData.wgetServerSettings = removeCredentialsForNoneAuth(wgetServerSettings);
    mappedData.downloaderType = DownloaderTypesEnum.WGET;
  }

  if (scheduling) {
    if (scheduling.timeIntervals) {
      mappedData.scheduling = {
        timeIntervals: {
          hours: Number(scheduling.timeIntervals.hours),
          minutes: Number(scheduling.timeIntervals.minutes),
        },
      };
    } else if (scheduling.dailyTime) {
      mappedData.scheduling = {
        dailyTime: {
          hour: Number(scheduling.dailyTime?.hour),
          minutes: Number(scheduling.dailyTime?.minutes),
        },
      };
    }
  }

  return mappedData as ICatalogPlanCreate;
};
