import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AppNotification } from 'src/app-types';
import { useAppSelector } from '../../hooks';
import { Toast } from '../../components-dummy';
import { toastActions } from './Actions';
import { toastExpiryTimesSeconds } from './constants';
import './ToastManager.scss';
import { getNotificationMessage } from './ActionsNotifications';

export const ToastManager = (): JSX.Element => {
  const toasts = useAppSelector(state => state.toast.toasts);
  const dispatch = useDispatch();

  const removeToast = (toast: AppNotification): void => {
    dispatch(toastActions.remove({ toast }));
  };

  const toastsTags = useMemo(() => {
    const mappedToasts = toasts.map(toast => {
      const message = getNotificationMessage(toast);
      return (
        <Toast
          key={toast.timestamp}
          mode={toast.type}
          expireInSeconds={toastExpiryTimesSeconds[toast.type]}
          onClose={() => removeToast(toast)}
        >
          {message}
        </Toast>
      );
    });
    return mappedToasts;
  }, [toasts]);

  return <div className='syte-toast-manager'>{toastsTags}</div>;
};
