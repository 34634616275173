import React from 'react';
import { CatalogUploadLogsStatusEnum } from 'src/services';
import {
  FiltersRow,
  FilterVariant,
  FilterState,
  DateRangeFilterTagConfigValues,
} from 'src/components-dummy';
import { FiltersRowWrapperStyled } from './CatalogFeedUploadLogListFilters.styles';

export const CatalogUploadLogStatusTextByValue = {
  [CatalogUploadLogsStatusEnum.Success]: 'Successful',
  [CatalogUploadLogsStatusEnum.Processed]: 'Processed',
  [CatalogUploadLogsStatusEnum.Failed]: 'Failed',
  [CatalogUploadLogsStatusEnum.Warning]: 'Warning',
};

export type CatalogFeedUploadLogListFiltersState = Partial<{
  dateRange: FilterState<DateRangeFilterTagConfigValues> | undefined;
  status: FilterState | undefined;
}>;

export interface CatalogFeedUploadLogListFiltersProps {
  filters: CatalogFeedUploadLogListFiltersState;
  onChange: (partialFilters: CatalogFeedUploadLogListFiltersState) => void;
}

export const CatalogFeedUploadLogListFilters = ({
  filters,
  onChange,
}: CatalogFeedUploadLogListFiltersProps): JSX.Element => {
  const filterComponents: JSX.Element[] = [
    <FiltersRow.Filter
      type={FilterVariant.Menu}
      key='status'
      uniqueFilterKey='status'
      menuName='Status'
      tagPrefix='Status is'
      componentConfig={{
        options: [
          {
            value: CatalogUploadLogsStatusEnum.Success,
            text: CatalogUploadLogStatusTextByValue.success,
          },
          {
            value: CatalogUploadLogsStatusEnum.Processed,
            text: CatalogUploadLogStatusTextByValue.processed,
          },
          {
            value: CatalogUploadLogsStatusEnum.Failed,
            text: CatalogUploadLogStatusTextByValue.failed,
          },
          {
            value: CatalogUploadLogsStatusEnum.Warning,
            text: CatalogUploadLogStatusTextByValue.warning,
          },
        ],
      }}
    />,

    <FiltersRow.Filter
      type={FilterVariant.DateRange}
      key='dateRange'
      uniqueFilterKey='dateRange'
      menuName='Date Range'
      tagPrefix='Date range is:'
      componentConfig={{
        values: {
          startDate: null,
          endDate: null,
        },
      }}
    />,
  ];

  return (
    <FiltersRowWrapperStyled>
      <FiltersRow onChange={onChange} filters={filters}>
        {filterComponents}
      </FiltersRow>
    </FiltersRowWrapperStyled>
  );
};
