import React, { ReactNode } from 'react';
import { FooterStyled } from './GalleryFormFooter.styles';

export interface GalleryFormFooterProps {
  children: ReactNode | ReactNode[];
}

export function GalleryFormFooter({ children }: GalleryFormFooterProps): JSX.Element {
  return <FooterStyled>{children}</FooterStyled>;
}
