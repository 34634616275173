/* eslint-disable no-param-reassign */
import { useCallback, useMemo } from 'react';
import { ShopTypes, FeedDataFieldType, ShopDataField } from 'src/services';
import { dataFieldFormToConditionTypeMap } from './constants';

export interface UseDataFieldsLookupTableArguments {
  dataFields?: ShopDataField[];
}

export interface DataFieldLookupTableValue extends ShopDataField {
  conditionTypes: ShopTypes.MerchandisingRuleSubType[];
}

export type DataFieldLookupTable = Record<string, DataFieldLookupTableValue>;

export interface UseDataFieldsLookupTableArgumentsReturnType {
  getDataFieldByName: (name: string | undefined) => DataFieldLookupTableValue | undefined;
  dataFieldsLookupTable: DataFieldLookupTable | undefined;
}

export function getDataFieldConditionOperators({ types }: { types: FeedDataFieldType[] }) {
  return types.reduce((result, currentType) => {
    result.push(...dataFieldFormToConditionTypeMap[currentType]);
    return result;
  }, [] as ShopTypes.MerchandisingRuleSubType[]);
}

export const useDataFieldsLookupTable = ({
  dataFields,
}: UseDataFieldsLookupTableArguments): UseDataFieldsLookupTableArgumentsReturnType => {
  const dataFieldsLookupTable = useMemo(() => {
    return dataFields?.reduce((result, current) => {
      const conditionTypes = getDataFieldConditionOperators({ types: current.types });

      result[current.name] = { ...current, conditionTypes };
      return result;
    }, {} as DataFieldLookupTable);
  }, [dataFields]);

  const getDataFieldByName = useCallback(
    (name: string | undefined) => {
      if (!name) {
        return undefined;
      }
      return dataFieldsLookupTable?.[name];
    },
    [dataFieldsLookupTable]
  );

  return {
    getDataFieldByName,
    dataFieldsLookupTable,
  };
};
