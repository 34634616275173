import { css } from '@emotion/react';
import React, { useCallback, useEffect, useState, useMemo, FocusEvent } from 'react';
import {
  TextBox,
  Typography,
  TypographyType,
  TypographyVariant,
  AutoCompleteSingleValue,
  AutoCompleteOption,
} from 'src/components-dummy';
import { MerchandisingRuleTypes } from 'src/services';
import { MerchandiseRule } from 'src/services/src/service/types/shops';
import { getStringEnumValues } from 'src/utils';
import { RuleFormField } from '../RuleFormField/RuleFormField';
import { RuleFormSection } from '../RuleFormSection/RuleFormSection';
import { ErrorLabelStyled } from '../../MerchandisingRuleForm.styles';

export type RuleFormGeneralSectionFields = Pick<MerchandiseRule, 'name' | 'kpi'>;

export interface RuleFormGeneralSectionProps extends RuleFormGeneralSectionFields {
  onFieldChange: (partialData: Partial<RuleFormGeneralSectionFields>) => void;
  isReadOnly?: boolean;
  errors: any;
  rulesKpiOptions?: string[];
}

const enumKPIOptions = getStringEnumValues(MerchandisingRuleTypes.MerchandisingRuleKPIEnum).map(
  value => {
    return {
      value,
      title: value,
    };
  }
);

const setOfEnumKpi = new Set<string>(
  getStringEnumValues(MerchandisingRuleTypes.MerchandisingRuleKPIEnum)
);

export const RuleFormGeneralSection = React.memo(
  ({
    kpi,
    name,
    onFieldChange,
    isReadOnly,
    errors,
    rulesKpiOptions = [],
  }: RuleFormGeneralSectionProps): JSX.Element => {
    const [nameInputValue, setNameInputValue] = useState(name);
    const [kpiSelectedValue, setKpiSelectedValue] = useState(kpi);
    const [kpisOptions, setKpisOptions] = useState<AutoCompleteOption[]>([]);

    const allKpiOptions = useMemo(() => {
      const kpiOptions = rulesKpiOptions
        .filter(kpiValue => !setOfEnumKpi.has(kpiValue))
        .map(kpiValue => {
          return {
            value: kpiValue,
            title: kpiValue,
          };
        });

      const result = [...enumKPIOptions, ...kpiOptions];

      return result;
    }, [rulesKpiOptions]);

    const nameErrorMessage = errors?.name?.message;

    const onNameInputBlur = useCallback(
      (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const updatedNameValue = event.target.value;
        if (updatedNameValue !== name) {
          onFieldChange({ name: updatedNameValue });
        }
      },
      [name, onFieldChange]
    );

    const onKpiChange = useCallback(
      (newKpi: string) => {
        setKpiSelectedValue(newKpi);
      },
      [setKpiSelectedValue]
    );

    const onKpiFocus = useCallback(() => {
      if (kpi === undefined || kpi.length === 0) {
        setKpisOptions(allKpiOptions);
      }
    }, [kpi, allKpiOptions]);

    const onKpiBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        setKpisOptions([]);

        const newKpi = event.target.value;

        const noChangeOccurred = newKpi === '' && kpi === undefined;
        if (noChangeOccurred) {
          return;
        }

        onFieldChange({ kpi: newKpi });
        console.log('runing');
      },
      [kpi, setKpisOptions, onFieldChange]
    );

    useEffect(() => {
      if (!kpi) {
        setKpisOptions(allKpiOptions);
        return;
      }

      const newOptions = allKpiOptions.filter(kpiOption => {
        return kpiOption.value.indexOf(kpi) !== -1;
      });

      setKpisOptions(newOptions);
    }, [kpi]);

    return (
      <RuleFormSection>
        <RuleFormSection.Header>General</RuleFormSection.Header>

        {nameErrorMessage ? <ErrorLabelStyled> {nameErrorMessage} </ErrorLabelStyled> : null}

        <RuleFormField
          css={css`
            margin-bottom: 32px;
            margin-top: 6px;
          `}
        >
          <RuleFormField.LabelText>
            Rule title (What is the rule objective?)
          </RuleFormField.LabelText>
          <TextBox
            value={nameInputValue || ''}
            onChange={setNameInputValue}
            error={!!errors.name}
            disabled={isReadOnly}
            autoFocus
            onBlur={onNameInputBlur}
          />
        </RuleFormField>

        <RuleFormField>
          <RuleFormField.LabelText>
            {`KPI `}
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
              (optional)
            </Typography>
          </RuleFormField.LabelText>
          <AutoCompleteSingleValue
            selectedValue={kpiSelectedValue}
            onChange={setKpiSelectedValue}
            onSelectOption={onKpiChange as any}
            options={kpisOptions}
            placeholder='Select or add your own...'
            freeSolo
            onFocus={onKpiFocus}
            onBlur={onKpiBlur}
            disabled={isReadOnly}
            errored={!!errors.kpi}
            openPopUp
          />
        </RuleFormField>
      </RuleFormSection>
    );
  }
);
