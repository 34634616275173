import { RuleGroup } from 'src/components-bl/Collections';
import { RuleGroupWithShopId } from './types';

function addShopIdToRuleGroup(
  ruleGroup: RuleGroup,
  shopId: number,
  collectionId?: string
): RuleGroupWithShopId {
  return collectionId ? { ...ruleGroup, shopId, collectionId } : { ...ruleGroup, shopId };
}

export function convertRuleGroupsToRuleGroupsWithShopId(
  ruleGroups: RuleGroup[],
  shopId: number,
  collectionId?: string
): RuleGroupWithShopId[] {
  return (ruleGroups || []).map(ruleGroup => addShopIdToRuleGroup(ruleGroup, shopId, collectionId));
}
