import styled from '@emotion/styled';
import { Icon } from '../Icon';
import { MenuItem } from '../MenuItem';

export const MenuItemWrapperStyled = styled.div`
  display: flex;

  li {
    width: 100%;
    &:hover {
      background-color: unset !important;
    }
  }
`;

export const MenuItemStyled = styled(MenuItem)`
  margin: 0 0 0 46px;
`;

export const SelectCaretIconStyled = styled(Icon)`
  align-self: center;
  margin: 0 0 0 8px;
  stroke: #1e1e1e;
`;
