import React from 'react';
import { useDispatch } from 'react-redux';
import { RoutedComponentProps, AppSwitch, AppRoute } from 'src/app-routes';
import { CatalogsListPage } from 'src/components-bl/CatalogManager';
import { useAppSelector } from 'src/hooks';
import { ConnectExistingCatalogModalContainer } from '../ConnectExistingCatalogModalContainer';
import { EditExistingCatalogModalContainer } from '../EditExistingCatalogModalContainer';

type CatalogManagerContainerProps = RoutedComponentProps;

export const CatalogManagerListContainer = ({
  permittedRouteMap,
}: CatalogManagerContainerProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const catalogs = useAppSelector(state => state.catalogManager.catalogs);
  const loggedInUser = useAppSelector(state => state.global.loggedInUser);

  return shopId ? (
    <>
      <AppSwitch>
        <AppRoute
          route={permittedRouteMap.connectExistingCatalog}
          Component={ConnectExistingCatalogModalContainer}
          componentProps={{ permittedRouteMap }}
          exact
        />
        <AppRoute
          route={permittedRouteMap.editExistingCatalog}
          Component={EditExistingCatalogModalContainer}
          componentProps={{ permittedRouteMap }}
          exact
        />
      </AppSwitch>
      <CatalogsListPage
        catalogs={catalogs}
        dispatch={dispatch}
        shopId={shopId}
        permittedRouteMap={permittedRouteMap}
        loggedInUser={loggedInUser}
      />
    </>
  ) : null;
};
