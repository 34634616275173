/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars-ts */
import React, { useCallback, useRef, useState } from 'react';
import { Dispatch } from 'src/components-bl/types';
import {
  DeepTagsGenerativeAISubProduct,
  IDeepTagReportProduct,
  IShopGeneratedDataSettings,
  UserTypes,
} from 'src/services';
import {
  DeepTag,
  DeepTagProductStatusType,
  DeepTagReportsGeneralConfigurationLabel,
  DeepTagStatusEnum,
  IDeepTagsProductDataItem,
} from 'src/services/src/service/types/deep-tag-reports';
import {
  AvailableIcons,
  Box,
  EllipsisWithTooltip,
  Icon,
  SelectOnChangeEvent,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { formatDateToNow } from 'src/utils/format-date-to-now';
import {
  DeepTagsProductStyled,
  MainHeaderProductStyled,
  HeaderSeparatorStyled,
  HeaderStyled,
  ImageWrapperStyled,
  ImagesContainerStyled,
  ImagesContainerTitleStyled,
  ImagesListStyled,
  MainStyled,
  TagsContainerStyled,
  ProductActionsAndDetailsStyled,
  CloseXButtonStyled,
  DeepTagProductEditableTitleWrapper,
} from './DeepTagsProductForm.style';
import { DeepTagsMainListFormProps } from './components/DeepTagsMainListForm/DeepTagsMainListForm';
import {
  DeepTagsGenerativeAI,
  OnGeneratedText,
} from './components/DeepTagGenerativeAIForm/DeepTagsGenerativeAIForm';
import { DeepTagProductStatusSelect } from '../DeepTagProductStatusSelect';
import { DeepTagProductEditableTitle } from '../DeepTagProductEditableTitle/DeepTagProductEditableTitle';
import { DeepTagProductCommentsWithButton } from './components/DeepTagProductComments/DeepTagProductCommentsWithButton';
import { DeepTagsProductReviewAndEditTagsSection } from './components/DeepTagsProductReviewAndEditTagsSection/DeepTagsProductReviewAndEditTagsSection';
import { OnChangeProductStatus, OnSaveProductChanges } from './DeepTagsProductForm.types';
import {
  DeepTagsSubProductsListTabs,
  useDeepTagsSubProductsListTabs,
} from './components/DeepTagsSubProductsListTabs';
import { deepTagsProductActionMethods } from './Actions';
import { createTagsForGeneratedText } from './components/DeepTagGenerativeAIForm/DeepTagsGenerativeAIForm.helpers';
import { DeepTagsReportsProductLabelAutoComplete } from '../DeepTagsReportsProductLabelAutoComplete/DeepTagsReportsProductLabelAutoComplete';

const isTagNotDeleted = (tag: DeepTag) => tag.status !== DeepTagStatusEnum.Deleted;

enum ScreenTypeEnum {
  REGULAR_TAGS = 'REGULAR_TAGS',
  GENERATE_TAGS = 'GENERATE_TAGS',
}

/**
 * Types
 */
export interface DeepTagsProductFormProps {
  deepTagProduct: IDeepTagReportProduct;
  reportLocale: string;
  currentUser: UserTypes.User;
  lexiconCategories?: DeepTagsMainListFormProps['lexiconCategories'];
  isDirty: boolean;
  dispatch: Dispatch;
  onCancel: () => void;
  onSaveChanges: OnSaveProductChanges;
  onChangeStatus: OnChangeProductStatus;
  onChangeTitle: ({ title, locale }: { title: string; locale: string }) => void;
  onSaveGeneratedDataSettings: ({
    shopGeneratedDataSettings,
  }: {
    shopGeneratedDataSettings: IShopGeneratedDataSettings;
  }) => void;
  getPaginatedReportsWithFilters: () => void;
  onGeneratedText: OnGeneratedText;
  reportId: string;
  shopId: number;
  generalConfigurationLabels: DeepTagReportsGeneralConfigurationLabel[];
  isLexiconEnabled: boolean | undefined;
  isLoadingLexicon: boolean;
  generatedDataSettings?: IShopGeneratedDataSettings;
  isContentGenerationPermitted: boolean;
  shouldHideEditIndication: DeepTagsMainListFormProps['shouldHideEditIndication'];
}

/**
 * Component
 */
export const DeepTagsProductForm = React.memo(
  ({
    deepTagProduct,
    isDirty,
    lexiconCategories,
    reportLocale,
    reportId,
    shopId,
    generalConfigurationLabels,
    currentUser,
    dispatch,
    onSaveChanges,
    onChangeStatus,
    onChangeTitle,
    onSaveGeneratedDataSettings,
    onGeneratedText: onGetGeneratedText,
    onCancel,
    getPaginatedReportsWithFilters,
    isLexiconEnabled,
    generatedDataSettings,
    isLoadingLexicon,
    isContentGenerationPermitted,
    shouldHideEditIndication,
  }: DeepTagsProductFormProps): JSX.Element => {
    const [screenType, setScreenType] = useState<ScreenTypeEnum>(ScreenTypeEnum.REGULAR_TAGS);
    const [isInProcess, setIsInProcess] = useState(false);

    const customGeneratedAISelectionTabRef = useRef<string | undefined>(undefined);

    const resetCustomGeneratedAISelectionTab = () => {
      customGeneratedAISelectionTabRef.current = undefined;
    };

    const { selectedTabIndex, onTabIndexChange, tabNames } = useDeepTagsSubProductsListTabs({
      initSelectedTabIndex: 0,
      productDataList: deepTagProduct.data,
    });

    const humanizedUpdatedAt = `Edited ${formatDateToNow(deepTagProduct.updatedAt)}`;

    const gotToGenerationAITagsScreen = useCallback(() => {
      setScreenType(ScreenTypeEnum.GENERATE_TAGS);
    }, []);

    const goToMainScreen = useCallback(() => {
      setScreenType(ScreenTypeEnum.REGULAR_TAGS);
    }, []);

    const onProductStatusChange: SelectOnChangeEvent = useCallback(
      event => {
        const newStatus = event.target.value as DeepTagProductStatusType;
        onChangeStatus({ status: newStatus, locale: reportLocale });
      },
      [onChangeStatus, reportLocale]
    );

    const onTitleEditChange = useCallback(
      async ({ value }: { value: string }): Promise<unknown> => {
        return onChangeTitle({ title: value, locale: reportLocale });
      },
      [reportLocale, onChangeTitle]
    );

    const mapSubProductsForGenerativeAI = useCallback(() => {
      return deepTagProduct.data.reduce(
        (prev: DeepTagsGenerativeAISubProduct[], next: IDeepTagsProductDataItem) => {
          const tagsWithoutDeleteStatus = next.tags.filter(isTagNotDeleted);

          prev.push({
            category: next.category.translation,
            tags: createTagsForGeneratedText(tagsWithoutDeleteStatus),
          });

          return prev;
        },
        [] as DeepTagsGenerativeAISubProduct[]
      );
    }, [deepTagProduct.data]);

    const filterMainProductTags = useCallback(() => {
      return deepTagProduct.tags.filter(isTagNotDeleted);
    }, [deepTagProduct.tags]);

    const onGeneratedAttributeEdit = useCallback(
      attributeName => {
        gotToGenerationAITagsScreen();
        customGeneratedAISelectionTabRef.current = attributeName;
      },
      [gotToGenerationAITagsScreen]
    );

    const onIsDirtyChange = useCallback((isDirtyNewValue: boolean) => {
      dispatch(deepTagsProductActionMethods.notifyIsDirty({ isDirty: isDirtyNewValue }));
    }, []);

    const renderPageContent = () => {
      switch (screenType) {
        case ScreenTypeEnum.GENERATE_TAGS: {
          return (
            <DeepTagsGenerativeAI
              backToMainScreen={goToMainScreen}
              onGeneratedText={onGetGeneratedText}
              onSaveChanges={onSaveChanges}
              onSaveGeneratedDataSettings={onSaveGeneratedDataSettings}
              subProducts={mapSubProductsForGenerativeAI()}
              mainProductTags={filterMainProductTags()}
              generatedDataSettings={generatedDataSettings}
              resetCustomGeneratedAISelectionTab={resetCustomGeneratedAISelectionTab}
              customGeneratedAITabSelection={customGeneratedAISelectionTabRef.current}
              onIsDirtyChange={onIsDirtyChange}
            />
          );
        }
        case ScreenTypeEnum.REGULAR_TAGS:
        default: {
          const { status, labels, title, updatedAt, comments, ...restDeepTagProduct } =
            deepTagProduct;

          return (
            <DeepTagsProductReviewAndEditTagsSection
              deepTagProduct={restDeepTagProduct}
              dispatch={dispatch}
              onSaveChanges={onSaveChanges}
              onCancel={onCancel}
              setIsInProcess={setIsInProcess}
              selectedTabIndex={selectedTabIndex}
              onTabIndexChange={onTabIndexChange}
              isInProcess={isInProcess}
              isLexiconEnabled={isLexiconEnabled}
              isLoadingLexicon={isLoadingLexicon}
              onGenerateButtonClick={
                isContentGenerationPermitted ? gotToGenerationAITagsScreen : undefined
              }
              onGeneratedAttributeEdit={onGeneratedAttributeEdit}
              onIsDirtyChange={onIsDirtyChange}
              shouldHideEditIndication={shouldHideEditIndication}
              isDirty={isDirty}
              lexiconCategories={lexiconCategories}
              reportLocale={reportLocale}
            />
          );
        }
      }
    };

    return (
      <DeepTagsProductStyled>
        <HeaderStyled>
          <MainHeaderProductStyled>
            <div>
              <header>
                <DeepTagProductEditableTitleWrapper>
                  <DeepTagProductEditableTitle
                    title={deepTagProduct.title || '(no title)'}
                    onChange={onTitleEditChange}
                    dynamicWidthMultiplier={8}
                    renderText={({ editedText }) => {
                      return (
                        <EllipsisWithTooltip tooltipText={deepTagProduct.title || ''}>
                          <Typography
                            type={TypographyType.Body}
                            variant={TypographyVariant.MediumBold}
                          >
                            {editedText}
                          </Typography>
                        </EllipsisWithTooltip>
                      );
                    }}
                  />
                </DeepTagProductEditableTitleWrapper>
              </header>
              <footer>
                <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
                  {deepTagProduct.sku}
                </Typography>
                <HeaderSeparatorStyled>&#183;</HeaderSeparatorStyled>
                <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
                  {humanizedUpdatedAt}
                </Typography>
              </footer>
            </div>
            <ProductActionsAndDetailsStyled>
              <DeepTagProductCommentsWithButton
                shopId={shopId}
                reportId={reportId}
                productId={deepTagProduct.id}
                comments={deepTagProduct.comments || []}
                currentUser={currentUser}
                getPaginatedReportsWithFilters={getPaginatedReportsWithFilters}
                dispatch={dispatch}
              />
              <DeepTagProductStatusSelect
                value={deepTagProduct.status}
                onChange={onProductStatusChange}
              />
              <Icon name={AvailableIcons.DotSeparatorBig} />
              <Typography type={TypographyType.Body} variant={TypographyVariant.ExtraSmallRegular}>
                Label:{' '}
              </Typography>
              <DeepTagsReportsProductLabelAutoComplete
                reportId={reportId}
                shopId={shopId}
                productId={deepTagProduct.id}
                locale={reportLocale}
                key={deepTagProduct.id}
                productCurrentLabelIds={deepTagProduct.labels}
                generalConfigurationLabels={generalConfigurationLabels}
                dispatch={dispatch}
              />
              <CloseXButtonStyled name={AvailableIcons.Close} onClick={onCancel} />
            </ProductActionsAndDetailsStyled>
          </MainHeaderProductStyled>
        </HeaderStyled>
        <MainStyled>
          {/* IMAGES - LEFT SIDE */}
          <ImagesContainerStyled>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <ImagesContainerTitleStyled>
                <Typography type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
                  {`${deepTagProduct.imageUrls.length} images`}
                </Typography>
              </ImagesContainerTitleStyled>
              <DeepTagsSubProductsListTabs
                isInProcess={isInProcess}
                names={tabNames}
                selectedTabIndex={selectedTabIndex}
                onTabChange={onTabIndexChange}
              />
              <ImagesListStyled>
                {deepTagProduct.imageUrls.map(
                  imageUrl =>
                    imageUrl && (
                      <ImageWrapperStyled key={imageUrl}>
                        <img src={imageUrl} alt={imageUrl} loading='lazy' />
                      </ImageWrapperStyled>
                    )
                )}
              </ImagesListStyled>
            </Box>
          </ImagesContainerStyled>
          {/* FORM (Tags / Generative) - RIGHT SIDE */}
          <TagsContainerStyled>{renderPageContent()}</TagsContainerStyled>
        </MainStyled>
      </DeepTagsProductStyled>
    );
  }
);
