import { BoughtTogetherSettingsTypes } from '../types';

export const buildBoughtTogetherSettingsPayload = (
  payload: Partial<BoughtTogetherSettingsTypes.BoughtTogetherSettings>
): Partial<BoughtTogetherSettingsTypes.BoughtTogetherSettings> => {
  const payloadData = {
    numberOfResultsToDisplay: payload.numberOfResultsToDisplay,
    modelType: payload.modelType,
    boughtTogetherFallbackMethod: payload.boughtTogetherFallbackMethod,
    addToCart: payload.addToCart,
    boughtTogetherFallbackField: payload.boughtTogetherFallbackField,
    sortingOrder: payload.sortingOrder,
  };

  return payloadData;
};
