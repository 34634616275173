import { FiltersConfigTypes } from 'src/services';
import { EntityDiffSchema } from '../../types';
import { GroupedValuePathsEnum } from '../../../../../app-types/enums/versioning';

type FilterSetItemDiffSchema = FiltersConfigTypes.FilterItemDBStructure;

export const filterSetItemsDiffSchema: EntityDiffSchema<
  FilterSetItemDiffSchema | GroupedValuePathsEnum,
  FiltersConfigTypes.CatalogFiltersSettings | FiltersConfigTypes.FilterItemDBStructure
> = {
  enabled: {
    displayName: 'Enabled',
    getValue({ entity }: { entity: any }): string {
      return entity.enabled === true ? 'on' : 'off';
    },
  },
  bucketsOrderSortType: {
    displayName: 'Order filter values by',
    relevantFieldPaths: ['bucketsOrder.type', 'bucketsOrder.sort'],
    getValue({ entity }: { entity: any }) {
      const sortType = entity.bucketsOrder.type;
      const { sort } = entity.bucketsOrder;
      if (sortType === 'name') {
        return sort === 'asc' ? 'A to Z' : 'Z to A';
      }
      return 'Number Of Results';
    },
  },
};
