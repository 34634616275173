import React, { useRef } from 'react';
import {
  SelectType,
  MenuItem,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { ActionsMenuSelectStyled } from './CatalogExplorerPageHeader.styles';

interface CatalogExplorerActionsMenuProps {
  onExportToCSV: () => void;
}

export const CatalogExplorerActionsMenu = ({
  onExportToCSV,
}: CatalogExplorerActionsMenuProps): JSX.Element => {
  const elementRef = useRef(null);

  return (
    <div>
      <ActionsMenuSelectStyled
        type={SelectType.Menu}
        placeholder='Actions'
        value=''
        MenuProps={{
          autoFocus: false,
          anchorEl: elementRef.current,
          transitionDuration: 0,
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          transformOrigin: { vertical: 'top', horizontal: 'right' },
          PaperProps: {
            sx: {
              border: '1px solid #DCDCDC',
              borderRadius: '8px',
              marginTop: '4px',
              maxHeight: 292.5,
              width: '182px',
            },
          },
          sx: { paddingTop: '0px' },
        }}
      >
        <MenuItem key='exportCSV' value='exportCSV' onClick={onExportToCSV}>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
            Export to CSV
          </Typography>
        </MenuItem>
      </ActionsMenuSelectStyled>
      <div ref={elementRef}></div>
    </div>
  );
};
