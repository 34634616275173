import { ShopsTableHeadRow } from '../types';

export const tableColumns: Record<string, ShopsTableHeadRow> = {
  accountId: {
    header: 'Account Id',
    accessor: 'accountId',
    width: '20%',
  },
  accountName: {
    header: 'Account Name',
    accessor: 'accountName',
    width: '20%',
  },
  isArchived: {
    header: 'Archived',
    accessor: 'isArchived',
    width: '20%',
  },
  createdAt: {
    header: 'Created At',
    accessor: 'createdAt',
    width: '20%',
  },
};
