import React from 'react';
import BoxMUI, { BoxProps as BoxMUIProps } from '@mui/material/Box';
import { SxProps, Theme } from '@mui/material/styles';

/**
 * Flex box props
 * https://mui.com/system/flexbox/
 */

interface BoxProps {
  sx?: SxProps<Theme>;
  children: React.ReactElement | React.ReactElement[] | React.ReactNode;
  onClick?: BoxMUIProps['onClick'];
}

export const Box = ({ children, sx, ...rest }: BoxProps): JSX.Element => {
  return (
    <BoxMUI sx={sx} {...rest}>
      {children}
    </BoxMUI>
  );
};
