import React from 'react';
import { Page } from 'src/components-dummy';
import { DeepTagReportsSettingsContainer } from 'src/containers/DeepTagReportsSettingsContainer';
import { AIModelSettingsContainer } from '../../AIModelContainer';
import { APISettingsContainer } from '../../APISettingsContainer';
import { LexiconsSettingsContainer } from '../../LexiconSettingsContainer';

export function AITaggingPage(): JSX.Element {
  return (
    <>
      <Page.Header>
        <Page.Title>AI Tagging</Page.Title>
        <Page.SubTitle>
          Manage feature access and admin configurations for this anything AI Tagging related
        </Page.SubTitle>
      </Page.Header>
      <Page.Content>
        <LexiconsSettingsContainer />
        <APISettingsContainer />
        <AIModelSettingsContainer />
        <DeepTagReportsSettingsContainer />
      </Page.Content>
    </>
  );
}
