import styled from '@emotion/styled';
import { Typography } from '../../../../components-dummy';

export const SalePriceContainer = styled.div`
  display: grid;
  grid-template-areas: 'salePrice saleOriginalPrice';
  grid-template-columns: repeat(2, auto);
  column-gap: 5px;
  grid-area: price;
`;

export const SalePrice = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom.red};
  grid-area: salePrice;
`;

export const SaleOriginalPrice = styled(Typography)`
  text-decoration: line-through;
  grid-area: saleOriginalPrice;
`;

export const Price = styled(Typography)`
  grid-area: price;
`;
