import React, { useState } from 'react';
import { useFilterItems } from 'src/hooks';
import { SearchInputStyled, MultiSelectMenuWithSearchStyled } from './MultiSelectMenu.styles';
import { NestedMultiSelectMenuWithSearchProps } from './types';
import { NestedMultiSelectMenu } from './NestedMultiSelectMenu';

export const NestedMultiSelectMenuWithSearch = ({
  searchPlaceholder = 'Search items...',
  options,
  searchValue = '',
  searchInputRef,
  ...rest
}: NestedMultiSelectMenuWithSearchProps): JSX.Element => {
  const [searchInputState, setSearchInputState] = useState(searchValue);

  const handleSearchChange = (text: string) => {
    setSearchInputState(text);
  };

  const { filteredItems: filteredOptions } = useFilterItems({
    items: options,
    searchableFields: ['text', 'value'],
    idField: 'value',
    filterText: searchInputState,
  });

  return (
    <MultiSelectMenuWithSearchStyled>
      <SearchInputStyled
        placeholder={searchPlaceholder}
        value={searchInputState}
        onChange={handleSearchChange}
        ref={searchInputRef}
      />
      <NestedMultiSelectMenu options={filteredOptions} {...rest} />
    </MultiSelectMenuWithSearchStyled>
  );
};
