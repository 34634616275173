import { useEffect, useState } from 'react';
import { isEqual, omit } from 'lodash';
import { Dispatch } from '../../../../../types';
import { AvailableAttributeKeysByCategoryMap, ILexiconRuleDraft } from '../LexiconRuleForm.types';
import { addNewCategoriesAndAttributesToMap } from './add-new-categories-and-attributes-to-map';
import { fetchAttributesByCategory } from './fetch-attributes-by-category';
import { getUniqueCategoriesFromConditionsGroups } from './get-unique-categories-from-conditions-groups';
import { getUnselectedCategoriesWithAttributes } from './get-unselected-categories-with-attributes';

export function useAvailableAttributes({
  dispatch,
  locale,
  shopId,
  lexiconRuleDraftState,
}: {
  dispatch: Dispatch;
  locale: string;
  shopId: number;
  lexiconRuleDraftState: ILexiconRuleDraft;
}): {
  availableAttributeKeysByCategory: AvailableAttributeKeysByCategoryMap;
} {
  const [availableAttributeKeysByCategory, setAvailableAttributeKeysByCategory] =
    useState<AvailableAttributeKeysByCategoryMap>({});
  const [selectedCategories, setSelectedCategoriesState] = useState<string[]>([]);

  useEffect(() => {
    const selectedCategoriesAfterStateUpdate = getUniqueCategoriesFromConditionsGroups(
      lexiconRuleDraftState.conditionsGroups
    );

    const didSelectedCategoriesChange = !isEqual(
      selectedCategories,
      selectedCategoriesAfterStateUpdate
    );

    if (didSelectedCategoriesChange) {
      setSelectedCategoriesState(selectedCategoriesAfterStateUpdate);
    }
  }, [lexiconRuleDraftState.conditionsGroups, selectedCategories, setSelectedCategoriesState]);

  useEffect(() => {
    const buildAvailableAttributeKeysByCategory = async () => {
      const selectedCategoriesWithoutAvailableAttributes = selectedCategories.filter(
        category => !availableAttributeKeysByCategory[category]
      );

      const hasSelectedCategoriesWithoutAttributes =
        selectedCategoriesWithoutAvailableAttributes.length > 0;
      if (hasSelectedCategoriesWithoutAttributes) {
        const attributesPerCategoryArray = await fetchAttributesByCategory({
          categories: selectedCategoriesWithoutAvailableAttributes,
          dispatch,
          locale,
          shopId,
        });

        const updatedAvailableAttributeKeysByCategory = addNewCategoriesAndAttributesToMap({
          attributesPerCategoryArray,
          categories: selectedCategoriesWithoutAvailableAttributes,
          availableAttributeKeysByCategory,
        });

        setAvailableAttributeKeysByCategory(updatedAvailableAttributeKeysByCategory);
        return;
      }

      const unselectedCategoriesWithAttributes = getUnselectedCategoriesWithAttributes({
        categoriesWithAttributes: Object.keys(availableAttributeKeysByCategory),
        selectedCategories,
      });
      const hasAttributesForUnselectedCategory = unselectedCategoriesWithAttributes.length > 0;
      if (hasAttributesForUnselectedCategory) {
        const updatedAvailableAttributeKeysByCategory = omit(
          availableAttributeKeysByCategory,
          unselectedCategoriesWithAttributes
        );
        setAvailableAttributeKeysByCategory(updatedAvailableAttributeKeysByCategory);
      }
    };

    buildAvailableAttributeKeysByCategory();
  }, [
    availableAttributeKeysByCategory,
    dispatch,
    locale,
    selectedCategories,
    setAvailableAttributeKeysByCategory,
    shopId,
  ]);

  return { availableAttributeKeysByCategory };
}
