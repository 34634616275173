import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { EllipsisWithTooltip } from '../EllipsisWithToolTip';

export const EllipsisWithTooltipStyled = styled(EllipsisWithTooltip)`
  max-width: 80%;
`;

export const MenuListStyled = styled.ul<{ enableScroll: boolean }>`
  width: 100%;
  padding: 0;

  ${({ enableScroll }) =>
    enableScroll &&
    css`
      height: 310px;
      overflow-y: hidden;
      overflow-x: hidden;

      &:hover {
        overflow-y: scroll;
      }
    `}

  list-style-type: none;
  margin: 0;

  li {
    margin-top: 2px;
    padding: 9px !important;
    padding-top: 10px !important;
    border-radius: 4px;
  }

  li:hover {
    background: ${({ theme }) => theme.palette.custom['gray-90']} !important;
  }
`;
