import React from 'react';
import {
  Button,
  ConfirmationDialog,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { ButtonStyled } from './ResetDraftConfirmationDialog.styles';

interface ResetDraftConfirmationDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export function ResetDraftConfirmationDialog({
  onConfirm,
  onCancel,
}: ResetDraftConfirmationDialogProps): JSX.Element {
  return (
    <ConfirmationDialog onCancel={onCancel}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Discard changes</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        <Typography type={TypographyType.Paragraph} variant={TypographyVariant.LargeRegular}>
          Are you sure you want to discard the changes for this image?
        </Typography>
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonStyled variant='primary' onClick={onConfirm}>
          Discard
        </ButtonStyled>
        <Button variant='tertiary' onClick={onCancel}>
          Cancel
        </Button>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
}
