import { useCallback, useState } from 'react';
import { DropzoneOptions, ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { UseFileUploaderProps, UseFileUploaderReturnType } from './types';

const DEFAULT_MAX_FILES = 1;
const BYTES_PER_MEGABYTE = 1048576;

export function useFileUploader({
  accept,
  disabled,
  maxFiles: maxFilesProp,
  maxSizeMb,
  onChange,
  onError: onErrorProp,
  selectedFiles,
}: UseFileUploaderProps): UseFileUploaderReturnType {
  const maxFiles = maxFilesProp || DEFAULT_MAX_FILES;
  const multiple = maxFiles ? maxFiles > 1 : undefined;

  const [fileErrorMessage, setFileErrorMessage] = useState('');

  const onDrop: DropzoneOptions['onDrop'] = useCallback(
    (acceptedFiles: File[]) => {
      onChange(acceptedFiles);
      setFileErrorMessage('');
    },
    [onChange, setFileErrorMessage]
  );

  const onFileGeneralError = useCallback(
    (error: Error) => {
      setFileErrorMessage(error.message);
      onErrorProp?.(error);
    },
    [setFileErrorMessage, onErrorProp]
  );

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]) => {
      const fileRejection = fileRejections[0];
      const errorObject = fileRejection.errors[0];

      let errorMessage;

      switch (errorObject.code) {
        case ErrorCode.FileInvalidType: {
          const allowedFileExtensions = accept
            ? Object.values(accept)
                .flatMap(extensions => extensions.map(extension => extension.toUpperCase()))
                .join()
            : 'Any';

          errorMessage = `Can't upload. Use one of these formats: ${allowedFileExtensions}`;
          break;
        }
        case ErrorCode.FileTooLarge:
          errorMessage = `Can't upload. Max file size is ${maxSizeMb}MB`;
          break;
        case ErrorCode.FileTooSmall:
          errorMessage = `Can't upload. File size is too small`;
          break;
        case ErrorCode.TooManyFiles:
          errorMessage = `Can't upload. Can upload up to ${maxFiles} files`;
          break;
        default:
          errorMessage = errorObject.message;
      }

      setFileErrorMessage(errorMessage);
      onErrorProp?.(errorObject);
    },
    [setFileErrorMessage, onErrorProp, accept, maxFiles, maxSizeMb]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept,
    disabled,
    maxFiles,
    maxSize: maxSizeMb ? maxSizeMb * BYTES_PER_MEGABYTE : undefined,
    multiple,
    noClick: true,
    onDrop,
    onDropRejected,
    onError: onFileGeneralError,
  });

  const showUploader = !selectedFiles || selectedFiles?.length === 0;

  return {
    fileErrorMessage,
    getInputProps,
    getRootProps,
    open,
    showUploader,
  };
}
