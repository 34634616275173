import React, { useRef } from 'react';
import { Page } from 'src/components-dummy';
import { MyProfileForm } from 'src/components-bl';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import './MyProfileContainer.scss';

export const MyProfileContainer = (): JSX.Element => {
  const { loggedInUser } = useAppSelector(state => state.global);
  const dispatch = useAppDispatch();

  const formButtonRef = useRef<HTMLDivElement>(null);

  return (
    <Page className='syte-my-profile-container'>
      <Page.Header>
        <Page.Title>My Profile</Page.Title>
        <div className='ml-auto syte-my-profile-container-header-buttons' ref={formButtonRef} />
      </Page.Header>
      <Page.Content>
        <MyProfileForm user={loggedInUser} dispatch={dispatch} formSaveButtonRef={formButtonRef} />
      </Page.Content>
    </Page>
  );
};
