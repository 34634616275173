import React from 'react';
import { useDispatch } from 'react-redux';
import { APISettingsForm } from 'src/components-bl';
import { useAppSelector } from 'src/hooks';

export function APISettingsContainer(): JSX.Element | null {
  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const { productsAPIv2Toggles } = useAppSelector(state => state.shop);

  const dispatch = useDispatch();

  return shopId ? (
    <APISettingsForm
      dispatch={dispatch}
      productsAPIv2Toggles={productsAPIv2Toggles}
      shopId={shopId}
    />
  ) : null;
}
