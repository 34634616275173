import React from 'react';
import { Link, Typography, TypographyType, TypographyVariant } from '../../../../components-dummy';

interface Props {
  shopId: number;
}

export function NoActiveCollection({ shopId }: Props): JSX.Element {
  return (
    <Typography
      className='visual-editor-content-text-no-active-collection'
      variant={TypographyVariant.LargeBold}
      type={TypographyType.Body}
    >
      <Link href={`/shops/${shopId}/collections`}>Go to Collections</Link> to create a new
      collection
    </Typography>
  );
}
