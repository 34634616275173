import { useState, useRef, useEffect } from 'react';
import { useFilterItems } from 'src/hooks';
import { useContainerDimensions } from '../hooks';
import { UseListSelectorArguments, UseListSelectorReturnType } from './types';

const shadowMargin = 12;

export const useListSelector = <T>({
  items = [],
  idField,
  onItemSelected,
  searchableFields = [],
}: UseListSelectorArguments<T>): UseListSelectorReturnType<T> => {
  const [popUpIsOpened, setPopUpIsOpened] = useState(false);
  const selectedItemBarRef = useRef<HTMLHeadingElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const onSearchInputChange = (value: string): void => setSearchInputValue(value);

  const { dimensions: selectedItemDimensions } = useContainerDimensions({
    ref: selectedItemBarRef,
  });

  const onItemClick = (item: T) => {
    onItemSelected?.(item);
    setPopUpIsOpened(false);
  };

  const { filteredItems } = useFilterItems({
    items: items as NonNullable<T>[],
    searchableFields,
    idField,
    filterText: searchInputValue,
  });

  const popupDimensions = {
    width: selectedItemDimensions.width + shadowMargin * 2,
    top: selectedItemDimensions.bottom,
    left: selectedItemDimensions.left - shadowMargin,
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (!popUpIsOpened) return;

    const clickInsideInput =
      selectedItemBarRef.current && (selectedItemBarRef.current as any).contains(event.target);
    if (clickInsideInput) return;

    const clickOutsideOfPopup =
      popupRef.current && !(popupRef.current as any).contains(event.target);

    if (clickOutsideOfPopup) {
      setPopUpIsOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return {
    items: filteredItems,
    onItemClick,
    showSearch: !!searchableFields.length,
    searchInputValue,
    onSearchInputChange,
    popUpIsOpened,
    setPopUpIsOpened,
    popupDimensions,
    selectedItemBarRef,
    popupRef,
  };
};
