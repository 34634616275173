import React, { MouseEvent, useCallback, useState } from 'react';
import {
  EllipsisMenuButton,
  Menu,
  Typography,
  TypographyType,
  TypographyVariant,
  MenuItem,
} from 'src/components-dummy';
import { MenuActionsStyled } from './CardActionMenu.styles';

interface CardActionsMenuProps {
  className?: string;
  onReadClick?: () => void;
  onDeleteClick: () => void;
}

export function CardActionsMenu({
  className,
  onReadClick,
  onDeleteClick,
}: CardActionsMenuProps): JSX.Element {
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);

  const stopPropagationOnClickEvent = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
  }, []);

  const onMenuClicked = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      stopPropagationOnClickEvent(event);

      setMenuAnchorElement(event.currentTarget);
    },
    [setMenuAnchorElement, stopPropagationOnClickEvent]
  );

  const onMenuClose = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      stopPropagationOnClickEvent(event);

      setMenuAnchorElement(null);
    },
    [setMenuAnchorElement, stopPropagationOnClickEvent]
  );

  const onItemClick = useCallback(
    (event: MouseEvent<HTMLElement>, onClick) => {
      onClick();
      onMenuClose(event);
    },
    [onMenuClose]
  );

  const onReadItemClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      onItemClick(event, onReadClick);
    },
    [onItemClick, onReadClick]
  );

  const onDeleteItemClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      onItemClick(event, onDeleteClick);
    },
    [onItemClick, onDeleteClick]
  );

  const isOpen = Boolean(menuAnchorElement);

  return (
    <MenuActionsStyled className={className}>
      <EllipsisMenuButton onClick={onMenuClicked} isActive={isOpen} />
      <Menu
        onClick={stopPropagationOnClickEvent}
        open={isOpen}
        anchorEl={menuAnchorElement}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onReadItemClick} disabled={!onReadClick}>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            Mark as read
          </Typography>
        </MenuItem>
        <MenuItem onClick={onDeleteItemClick}>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>
    </MenuActionsStyled>
  );
}
