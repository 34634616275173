import { HTTPService } from './httpService';
import { configuration } from '../common/configuration';

export class ApiServiceBase {
  serviceBaseUri: string;

  httpService: HTTPService;

  constructor(serviceBaseUri: string) {
    this.httpService = new HTTPService(configuration);
    this.serviceBaseUri = serviceBaseUri;
  }

  getBaseURL(): string {
    return new URL(this.httpService.baseUrl).href;
  }
}
