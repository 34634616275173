import React, { useCallback } from 'react';
import { FormGroup } from '../Form';
import {
  TextBox,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { ExternalLink } from '../ExternalLink';
import { useBrowsePlpSettings } from './useBrowsePlpSettings';
import { useNavigateToVisualEditorRoute } from '../../hooks/useNavigateToVisualEditorRoute';
import { StateController } from '../SideMenu/ShopperExperience/useStateControllerRef';
import { PreviewFeature } from '../../../../services';
import { PermittedRouteMap } from '../../../../app-routes';
import { FlexContainer } from '../VisualEditor.styles';

interface Props {
  shopId: number;
  stateController: StateController<string>;
  selectedExperience?: PreviewFeature;
  permittedRouteMap: PermittedRouteMap;
}

export function BrowsePlpSettings({
  shopId,
  stateController,
  selectedExperience,
  permittedRouteMap,
}: Props): JSX.Element {
  const { category, changeCategory } = useBrowsePlpSettings(
    shopId,
    stateController,
    permittedRouteMap,
    selectedExperience
  );

  const { navigateToVisualEditorRoute } = useNavigateToVisualEditorRoute({
    route: permittedRouteMap?.visualEditorEditCategoryPage,
  });

  const editCategoryPage = useCallback(() => {
    navigateToVisualEditorRoute({ shopId });
  }, [navigateToVisualEditorRoute, shopId]);

  return (
    <>
      <FormGroup>
        <FlexContainer>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            Category page is
          </Typography>
          <ExternalLink onClick={editCategoryPage}>Edit Category Filter</ExternalLink>
        </FlexContainer>
        <TextBox value={category} onChange={changeCategory} placeholder='Type category here...' />
      </FormGroup>
    </>
  );
}
