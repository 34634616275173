import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { useValidateSchema } from 'src/hooks';
import { Dispatch } from 'src/components-bl/types';
import { isEqual } from 'lodash';
import { Skeleton } from 'src/components-dummy';
import { Filter, FilterSortBy, ShopDataField, SyteLayout } from 'src/services';
import { formValidationSchema } from './constants';
import { CustomOrderValue, FilterDraft } from './types';
import { filtersFormActions } from './Actions';
import { DataSourceSection, ValuesCustomOrderSection, DisplayOptionsSection } from './components';
import { FilterFormMapper } from './mapper';
import { FilterFormStyled } from './FilterForm.styles';

interface FilterFormProps extends RoutedComponentProps {
  shopId?: number;
  syteLayout: SyteLayout;
  filter?: Filter;
  mode: 'create' | 'edit';
  onFormStatusChange: (canSubmit: boolean) => void;
  dispatch: Dispatch;
  availableDataFields: ShopDataField[] | undefined;
  dataFieldsFromOtherFilters?: Set<string>;
  hasReachedMaxAllowedCustomOrderFiltersLimit?: boolean;
}

export interface FilterFormApiRef {
  submit(): Promise<void>;
}

export const formSkeleton = <Skeleton variant='rectangular' height={500} />;

export const FilterForm = forwardRef<FilterFormApiRef, FilterFormProps>(
  (
    {
      shopId,
      filter,
      mode,
      dispatch,
      syteLayout,
      availableDataFields,
      onFormStatusChange,
      dataFieldsFromOtherFilters,
      hasReachedMaxAllowedCustomOrderFiltersLimit,
    },
    ref
  ): JSX.Element => {
    const isInEditMode = mode === 'edit';
    const isLoadingFilter = isInEditMode && filter === undefined;

    const initialDraftStateMapped: FilterDraft = useMemo(
      () => FilterFormMapper.mapFilterToDraft(filter),
      [filter]
    );

    const validationContext = useMemo(() => {
      return {
        validCatalogSourceFields: availableDataFields?.map(dataField => dataField.name) || [],
        dataFieldsFromOtherFilters,
      };
    }, [availableDataFields, dataFieldsFromOtherFilters]);

    const [filterDraft, setFilterDraft] = useState(initialDraftStateMapped);
    const [isDirty, setIsDirty] = useState(false);

    const customOrderOptionDisabled = useMemo(
      () =>
        Boolean(
          hasReachedMaxAllowedCustomOrderFiltersLimit &&
            filter?.valuesSorting?.sortBy !== FilterSortBy.CustomOrder
        ),
      [hasReachedMaxAllowedCustomOrderFiltersLimit, filter?.valuesSorting?.sortBy]
    );

    const updateIsDirty = useCallback(
      (newIsDirty: boolean) => {
        setIsDirty(newIsDirty);
        dispatch(filtersFormActions.notifyIsDirty({ isDirty: newIsDirty }));
      },
      [dispatch, setIsDirty]
    );

    const { errors, validate, isValid, resetValidationState } = useValidateSchema<
      Omit<FilterDraft, 'id'>
    >({
      schema: formValidationSchema,
      validateOnStart: false,
      initialData: initialDraftStateMapped,
    });

    const onDraftChange = useCallback(
      (updatedDraft: Partial<FilterDraft>) => {
        const updatedState = { ...filterDraft, ...updatedDraft };

        FilterFormMapper.addEmptyCustomValueIfNeeded(updatedState.valuesSorting);

        setFilterDraft(updatedState);
        validate({ dataToValidate: updatedState, context: validationContext });

        const newIsDirty = !isEqual(updatedState, filterDraft);
        updateIsDirty(newIsDirty);
      },
      [validate, filterDraft, validationContext, updateIsDirty]
    );

    const onCustomValuesChange = useCallback(
      (newValues: CustomOrderValue[]) => {
        const updatedValuesSorting = { sortBy: FilterSortBy.CustomOrder, customOrder: newValues };
        onDraftChange({ valuesSorting: updatedValuesSorting });
      },
      [onDraftChange]
    );

    useEffect(() => {
      setFilterDraft(initialDraftStateMapped);
      resetValidationState();
    }, [initialDraftStateMapped]);

    useImperativeHandle(
      ref,
      () => {
        return {
          async submit() {
            if (!isValid || !shopId) {
              return;
            }

            if (mode === 'create') {
              await (
                dispatch(
                  filtersFormActions.createFilter({
                    shopId,
                    syteLayout,
                    filterPayload: FilterFormMapper.mapDraftToFilter(filterDraft),
                  })
                ) as any
              ).unwrap();
            } else {
              await (
                dispatch(
                  filtersFormActions.updateFilter({
                    shopId,
                    syteLayout,
                    filterId: filter.id as string,
                    filterPayload: FilterFormMapper.mapDraftToFilter(filterDraft),
                  })
                ) as any
              ).unwrap();
            }

            updateIsDirty(false);
          },
        };
      },
      [mode, filterDraft, shopId, syteLayout, isValid, updateIsDirty]
    );

    useEffect(() => {
      const canSubmit = isDirty && isValid;
      onFormStatusChange(canSubmit);
    }, [isDirty, isValid]);

    useEffect(() => {
      return () => {
        dispatch(filtersFormActions.resetSelectedFilter());
        dispatch(filtersFormActions.notifyIsDirty({ isDirty: false }));
      };
    }, [dispatch]);

    const isCustomOrderSortBy = filterDraft.valuesSorting?.sortBy === FilterSortBy.CustomOrder;

    return isLoadingFilter ? (
      formSkeleton
    ) : (
      <FilterFormStyled>
        <DataSourceSection
          onChange={onDraftChange}
          data={filterDraft}
          availableDataFields={availableDataFields}
          dispatch={dispatch}
          errors={errors}
          disabled={isInEditMode}
        />
        {filterDraft.sourceField && filterDraft.valuesSorting && (
          <DisplayOptionsSection
            onChange={onDraftChange}
            customOrderOptionDisabled={customOrderOptionDisabled}
            data={filterDraft}
            errors={errors}
          />
        )}
        {isCustomOrderSortBy && filterDraft.sourceField && shopId && (
          <ValuesCustomOrderSection
            onChange={onCustomValuesChange}
            values={filterDraft.valuesSorting?.customOrder || []}
            selectedSourceField={filterDraft.sourceField}
            errors={errors}
            shopId={shopId}
          />
        )}
      </FilterFormStyled>
    );
  }
);
