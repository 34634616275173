import styled from '@emotion/styled';
import { Select, Typography } from 'src/components-dummy';
import { LocaleItem } from './components/LocaleItem';

export const ShopLexiconSettingsStyled = styled.div`
  max-width: 752px;
`;

export const LocalesContainerStyled = styled.div`
  padding: 10px 10px 20px 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SelectLocaleItemStyled = styled(LocaleItem)`
  background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  height: 3em;
  padding: 0 12px 0 8px;
`;

export const LanguageSelectStyled = styled(Select)`
  width: 25em;
  margin-bottom: 1em;
`;

export const SupportedLanguagesHeaderStyled = styled(Typography)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
`;

export const SupportedLanguagesSubHeaderStyled = styled(Typography)``;
