import React, { useState } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import {
  CardItem,
  InitialLetter,
  Icon,
  AvailableIcons,
  PopupMenu,
  PopUpMenuItem,
  Badge,
  BadgeBackground,
  Button,
  Typography,
  TypographyType,
  TypographyVariant,
  ConfirmationDialog,
} from 'src/components-dummy';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';
import { AccountTypes } from 'src/services';
import { Dispatch } from '../../../types';
import { updateAccountStatus } from './Actions';
import './AccountCard.scss';

export interface AccountCardProps {
  account: Omit<AccountTypes.Account, 'shops'>;
  className?: string;
  onClick?: () => void;
  dispatch: Dispatch;
  isSelected: boolean;
}

const UPDATE_AT_DATE_FORMAT = 'dd-MM-yy HH:mm';

const accountStatusToClassMap: Record<AccountTypes.AccountStatus, string> = {
  [AccountTypes.AccountStatus.Active]: 'active',
  [AccountTypes.AccountStatus.Archived]: 'archived',
  [AccountTypes.AccountStatus.Disabled]: 'disabled',
};

const accountStatusToBadgeBackground: Record<AccountTypes.AccountStatus, BadgeBackground> = {
  [AccountTypes.AccountStatus.Active]: 'primary',
  [AccountTypes.AccountStatus.Archived]: 'disabled',
  [AccountTypes.AccountStatus.Disabled]: 'secondary',
};

const accountStatusToDialogText: Partial<{
  [key in AccountTypes.AccountStatus]: { body: string; actionButton: string };
}> = {
  [AccountTypes.AccountStatus.Active]: {
    body: 'Are you sure you want to archive this account?',
    actionButton: 'Archive',
  },
  [AccountTypes.AccountStatus.Archived]: {
    body: 'Are you sure you want to restore this account?',
    actionButton: 'Restore',
  },
};

export const AccountCard = ({
  account,
  className,
  onClick,
  dispatch,
  isSelected,
}: AccountCardProps): JSX.Element => {
  const [showDeleteFeedDialog, setShowDeleteFeedDialog] = useState(false);

  const onDeleteIconClick = () => {
    setShowDeleteFeedDialog(true);
  };

  const onDialogClose = () => {
    setShowDeleteFeedDialog(false);
  };

  let archiveMenuItemText = 'Archive';

  if (account.accountStatus === AccountTypes.AccountStatus.Archived) {
    archiveMenuItemText = 'Restore';
  }

  const onStatusChange = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (account.accountStatus === AccountTypes.AccountStatus.Active) {
      dispatch(
        updateAccountStatus({
          accountId: account.accountId,
          status: AccountTypes.AccountStatus.Archived,
        })
      );
    } else if (account.accountStatus === AccountTypes.AccountStatus.Archived) {
      dispatch(
        updateAccountStatus({
          accountId: account.accountId,
          status: AccountTypes.AccountStatus.Active,
        })
      );
    }
    setShowDeleteFeedDialog(false);
  };

  return (
    <CardItem
      className={classNames(
        'syte-account-card',
        className,
        accountStatusToClassMap[account.accountStatus]
      )}
      onClick={onClick}
      selected={isSelected}
      clickable
    >
      <>
        <div className='syte-account-card-left'>
          <InitialLetter value={account.accountName} className='syte-account-profile-icon' />
          <div className='syte-account-info'>
            <Typography
              className='syte-account-info-name'
              type={TypographyType.Paragraph}
              variant={TypographyVariant.LargeMedium}
            >
              {account.accountName}
            </Typography>
            <div className='syte-account-info-meta'>
              <Typography
                className='syte-account-info-meta-id'
                type={TypographyType.Body}
                variant={TypographyVariant.SmallRegular}
              >
                {account.accountId}
              </Typography>

              {account.updatedAt && (
                <>
                  <Icon
                    name={AvailableIcons.DottedCircle}
                    className='syte-account-info-meta-icon'
                  />
                  <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
                    {format(new Date(account.updatedAt), UPDATE_AT_DATE_FORMAT)}
                  </Typography>
                </>
              )}
              <Badge
                text={accountStatusToClassMap[account.accountStatus]}
                className='syte-account-info-status'
                background={accountStatusToBadgeBackground[account.accountStatus]}
              />
            </div>
          </div>
        </div>
        <PopupMenu position='left center' closeOnContentClick>
          <PopUpMenuItem onClick={onDeleteIconClick}>{archiveMenuItemText}</PopUpMenuItem>
          <></>
        </PopupMenu>
        {showDeleteFeedDialog && (
          <ConfirmationDialog onCancel={onDialogClose}>
            <ConfirmationDialog.Header>
              <ConfirmationDialog.Title>Hold on</ConfirmationDialog.Title>
            </ConfirmationDialog.Header>
            <ConfirmationDialog.Content>
              {accountStatusToDialogText[account.accountStatus]?.body || 'Are you sure?'}
            </ConfirmationDialog.Content>
            <ConfirmationDialog.Footer>
              <ButtonsContainer>
                <Button variant='primary' onClick={onStatusChange}>
                  {accountStatusToDialogText[account.accountStatus]?.actionButton || 'Proceed'}
                </Button>
                <Button variant='outlined' onClick={onDialogClose}>
                  Cancel
                </Button>
              </ButtonsContainer>
            </ConfirmationDialog.Footer>
          </ConfirmationDialog>
        )}
      </>
    </CardItem>
  );
};
