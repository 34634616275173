import { AccountTypes, APITypes } from 'src/services';

export interface SelectAccountActionPayload {
  account: AccountTypes.Account;
}

export interface GetAccountRequestActionPayload {
  accountId: number;
}

export interface GetAccountErrorActionPayload {
  error: APITypes.ApiError;
}

export interface GetAccountSuccessActionPayload {
  account: AccountTypes.Account;
}

/* --= Action Types =-- */
export enum AccountListActionTypes {
  SelectAccount = 'AccountList/SelectAccount',
  GetAccountRequest = 'AccountList/GetAccountRequest',
  GetAccountError = 'AccountList/GetAccountError',
  GetAccountSuccess = 'AccountList/SelectAccountSuccess',
}

/* --= Action Interfaces =-- */
export interface SelectAccountAction {
  type: typeof AccountListActionTypes.SelectAccount;
  payload: SelectAccountActionPayload;
}

export interface GetAccountRequestAction {
  type: typeof AccountListActionTypes.GetAccountRequest;
  payload: GetAccountRequestActionPayload;
}

export interface GetAccountErrorAction {
  type: typeof AccountListActionTypes.GetAccountError;
  payload: GetAccountErrorActionPayload;
}

export interface GetAccountSuccessAction {
  type: typeof AccountListActionTypes.GetAccountSuccess;
  payload: GetAccountSuccessActionPayload;
}

export type AccountListAction =
  | SelectAccountAction
  | GetAccountRequestAction
  | GetAccountErrorAction
  | GetAccountSuccessAction;

export interface SelectAccountArguments extends GetAccountRequestActionPayload {
  routeToPush?: string;
}
