import { createAsyncThunk } from '@reduxjs/toolkit';
import { notificationsService } from 'src/services';

export const notificationsActions = {
  getNotifications: createAsyncThunk(
    'Notifications/GetNotifications',
    async (
      {
        shopId,
        skip,
        limit,
        viewed,
      }: { shopId?: number; skip?: number; limit?: number; viewed?: boolean },
      { rejectWithValue }
    ) => {
      try {
        const { notifications, total, unreadCount } = await notificationsService.getNotifications({
          shopId,
          skip,
          limit,
          viewed,
        });
        return { notifications, total, unreadCount, isUnreadOnly: viewed === false };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
