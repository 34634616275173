import React from 'react';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

interface TurnOffConfirmationDialogProps {
  onClose: () => void;
  onConfirmedAction: (event: React.MouseEvent<HTMLElement>) => void;
}

export const RestoreConfirmationDialog = ({
  onClose,
  onConfirmedAction,
}: TurnOffConfirmationDialogProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onClose}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Restore tag</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        Are you sure you want to restore this tag?
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onConfirmedAction}>
            Restore tag
          </Button>
          <Button variant='outlined' onClick={onClose}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
