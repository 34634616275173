import React from 'react';
import { useDispatch, shallowEqual } from 'react-redux';
import { useAppSelector } from 'src/hooks';
import { EditRankingStrategy } from 'src/components-bl';
import { SyteProductType } from 'src/services/src/service/types/syte-products';
import { ComponentProps } from '../types';

const COMPONENT_NAME = 'EditRankingStrategyContainer';

export const EditRankingStrategyContainer = ({
  formHeaderRef,
  currentCollection,
  selectedVariantId,
}: ComponentProps): JSX.Element | null => {
  const {
    rankingStrategy: { weights, type },
    shopId,
    dataFields,
    featureToggles,
  } = useAppSelector(state => {
    return {
      rankingStrategy: state.rankingStrategy,
      shopId: state.shop.current?.shopId,
      dataFields: state.dataFields.dataFields,
      featureToggles: state.shop.featureToggles,
    };
  }, shallowEqual);

  const dispatch = useDispatch();

  const selectedSyteProduct = SyteProductType.Collections;

  return shopId && currentCollection?.id ? (
    <EditRankingStrategy
      entityId={currentCollection.id}
      dispatch={dispatch}
      weights={weights[selectedSyteProduct]}
      type={type}
      selectedSyteProduct={selectedSyteProduct}
      shopId={shopId}
      formHeaderElementRef={formHeaderRef}
      dataFields={dataFields}
      prefixId={COMPONENT_NAME}
      featureToggles={featureToggles}
      selectedVariantId={selectedVariantId}
    />
  ) : null;
};
