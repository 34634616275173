import React, { useEffect } from 'react';
import { EditCollectionRules } from './EditCollectionRules';
import { EditCollectionRulesFooter } from './EditCollectionRulesFooter';
import { useCollection } from '../useCollection';
import { EditCollectionRulesContainerProps } from '../types';
import { editCollectionRulesContainerActions } from './EditCollectionRulesContainerActions';

export const EditCollectionRulesContainer = ({
  shopId,
  currentCollection,
  dispatch,
  isCreateMode,
  variantId,
}: EditCollectionRulesContainerProps): JSX.Element => {
  const {
    draftCollection,
    canSubmit,
    errors,
    onAddRuleGroup,
    onDeleteRuleGroup,
    onAddRule,
    onDeleteRule,
    onEditRule,
    onSaveChanges,
    onCreateCollection,
    onCancel,
    handleFieldOnChange,
    navigateToList,
    dataFields,
  } = useCollection({
    shopId,
    currentCollection,
    dispatch,
    isCreateMode,
    variantId,
  });

  useEffect(() => {
    if (shopId) {
      dispatch(
        editCollectionRulesContainerActions.getDataFields({
          shopId,
        })
      );
    }
  }, [shopId]);

  const onCreate = () => {
    onCreateCollection(navigateToList);
  };

  const onCancelChanges = () => {
    onCancel(navigateToList);
  };

  return (
    <>
      <EditCollectionRules
        shopId={shopId}
        dispatch={dispatch}
        draftCollection={draftCollection}
        isCreateMode={isCreateMode}
        onDeleteRule={onDeleteRule}
        onEditRule={onEditRule}
        onAddRule={onAddRule}
        onAddRuleGroup={onAddRuleGroup}
        onDeleteRuleGroup={onDeleteRuleGroup}
        handleFieldOnChange={handleFieldOnChange}
        dataFields={dataFields}
        errors={errors}
      />
      <EditCollectionRulesFooter
        isCreateMode={isCreateMode}
        canSubmit={canSubmit}
        onCancel={onCancelChanges}
        onSaveChanges={onSaveChanges}
        onCreate={onCreate}
      />
    </>
  );
};
