import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  TypographyType,
  TypographyVariant,
  AvailableIcons,
  Menu,
  MenuItem,
  EllipsisWithTooltip,
} from 'src/components-dummy';
import { FilterTagStyled, RemoveFilterTagIconStyled } from '../FiltersRow.styles';
import { MenuFilterTagProps } from '../types';

export const MenuFilterTag = ({
  filterKey,
  onRemoveFilter,
  options,
  filterState,
  onChange,
  shouldTriggerOpen,
  onCloseFilter,
  tagPrefix,
}: MenuFilterTagProps): JSX.Element => {
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(null);

  const selectedOption = options.find(option => option.value === filterState?.value);

  const menuOpened = !!menuAnchorEl;

  const onTriggerClick = () => {
    setMenuAnchorEl(triggerRef.current);
  };

  const onClose = () => {
    setMenuAnchorEl(null);

    if (filterState?.isApplied === false) {
      onRemoveFilter?.(filterKey);
    } else {
      onCloseFilter?.();
    }
  };

  const onOptionClick = (value: string | number) => {
    onClose();
    onChange({ [filterKey]: { value, isApplied: true } });
  };

  useEffect(() => {
    if (shouldTriggerOpen) {
      setMenuAnchorEl(triggerRef.current);
    }
  }, [shouldTriggerOpen]);

  return (
    <>
      <FilterTagStyled isClickable onClick={onTriggerClick} ref={triggerRef}>
        <>
          <EllipsisWithTooltip tooltipText={selectedOption?.text || ''}>
            <>
              <Typography type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                {tagPrefix || 'Value is'}
              </Typography>

              {filterState?.isApplied && (
                <Typography type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
                  {selectedOption?.text}
                </Typography>
              )}
            </>
          </EllipsisWithTooltip>
          <RemoveFilterTagIconStyled
            name={AvailableIcons.Close}
            onClick={() => onRemoveFilter?.(filterKey)}
          />
        </>
      </FilterTagStyled>
      <Menu open={menuOpened} anchorEl={menuAnchorEl} onClose={onClose}>
        {(options || []).map(option => {
          return (
            <MenuItem
              key={option.text}
              value={`${option.value}`}
              onClick={() => onOptionClick(option.value)}
            >
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                {option.text}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
