import React, { createContext, useContext } from 'react';
import { AppDispatch } from 'src/app-types';
import { ISocialCollectionService } from '../../services/src/service/social-discovery/social-collection-service';

const SocialDiscoveryContext = createContext<{
  service: ISocialCollectionService;
  appDispatch: AppDispatch;
} | null>(null);

export const SocialDiscoveryProvider: React.FC<{
  service: ISocialCollectionService;
  appDispatch: AppDispatch;
}> = ({ service, appDispatch, children }) => {
  return (
    <SocialDiscoveryContext.Provider value={{ service, appDispatch }}>
      {children}
    </SocialDiscoveryContext.Provider>
  );
};

export const useSocialDiscoveryProvider = () => {
  const context = useContext(SocialDiscoveryContext);
  if (!context) {
    throw new Error('useSocialDiscoveryProvider should be used within a SocialDiscoveryProvider');
  }
  return context;
};
