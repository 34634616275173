import { isArray, isNumber, isString } from 'lodash';

export function isArrayOfStrings(values: unknown): boolean {
  const arrayCheck = isArray(values);
  return arrayCheck && (values as unknown[]).every(v => isString(v));
}

export function isArrayOfNumbers(values: unknown): boolean {
  const arrayCheck = isArray(values);
  return arrayCheck && (values as unknown[]).every(v => isNumber(v));
}

export function isArrayOfValueOrNumbers(values: unknown, compareToValue: unknown): boolean {
  const arrayCheck = isArray(values);
  return arrayCheck && (values as unknown[]).every(v => v === compareToValue || isNumber(v));
}
