import React from 'react';
import { MenuItem } from 'src/components-dummy/MenuItem';
import { Select, SelectOnChangeEvent, SelectType } from '../Select';
import { PaginationItemsPerPageStyled } from './Pagination.styles';

const DEFAULT_OPTIONS = [10, 15, 25, 50];

interface PaginationItemsPerPageProps {
  value: number;
  optionsPerPage?: number[];
  text: string;
  onChange: (value: number) => void;
}

export const PaginationItemsPerPage = ({
  value,
  optionsPerPage = DEFAULT_OPTIONS,
  text,
  onChange,
}: PaginationItemsPerPageProps): JSX.Element => {
  const onLimitChange: SelectOnChangeEvent = event => {
    const newValue = Number(event?.target.value);
    onChange(newValue);
  };

  return (
    <PaginationItemsPerPageStyled>
      <span>{text}:</span>
      <Select type={SelectType.Menu} value={value} onChange={onLimitChange}>
        {optionsPerPage.map((optionValue: number) => (
          <MenuItem key={optionValue} value={optionValue} text={`${optionValue}`}>
            {`${optionValue}`}
          </MenuItem>
        ))}
      </Select>
    </PaginationItemsPerPageStyled>
  );
};
