import { IEnhancedStrategy } from '../../../types';

export enum StoriesSettingsActions {
  ResolveStoriesSettings = 'Stories/ResolveStoriesSettings',
  GetStoriesSettingsError = 'Stories/GetStoriesSettingsError',
  UpdateStoriesSettings = 'Stories/UpdateStoriesSettings',
}

export interface StoriesSettingsPayload {
  numberOfResultsToDisplay: number;
  storiesStrategy: IEnhancedStrategy | null;
}

export interface ResolveStoriesSettingsAction {
  type: typeof StoriesSettingsActions.ResolveStoriesSettings;
  payload: StoriesSettingsPayload;
}

export interface UpdateStoriesSettingsSuccessAction {
  type: typeof StoriesSettingsActions.UpdateStoriesSettings;
  payload: StoriesSettingsPayload;
}

export interface UpdateStoriesSettingsErrorAction {
  type: typeof StoriesSettingsActions.GetStoriesSettingsError;
  payload: Error;
}

export type StoriesSettingsAction =
  | ResolveStoriesSettingsAction
  | UpdateStoriesSettingsSuccessAction;

export const storiesSettingsActions = {
  resolveStoriesSettings: (payload: StoriesSettingsPayload): ResolveStoriesSettingsAction => ({
    type: StoriesSettingsActions.ResolveStoriesSettings,
    payload,
  }),
  getStoriesSettingsError: (payload: Error): UpdateStoriesSettingsErrorAction => ({
    type: StoriesSettingsActions.GetStoriesSettingsError,
    payload,
  }),
  updateStoriesSettingsSuccess: (
    payload: StoriesSettingsPayload
  ): UpdateStoriesSettingsSuccessAction => ({
    type: StoriesSettingsActions.UpdateStoriesSettings,
    payload,
  }),
};
