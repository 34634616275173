import React, { useCallback, useImperativeHandle } from 'react';
import { generatePath } from 'react-router-dom';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';
import { NotificationCardMessageTypography } from '../common';
import { NotificationCardMessageProps } from '../types';
import { getUserDisplayName } from '../utils';

export function LexiconPublishMessage({
  notification,
  shopId,
  permittedRouteMap,
  navigateTo,
  cardRef,
}: NotificationCardMessageProps<{ locale: string }>): JSX.Element {
  const onNotificationClick = useCallback(() => {
    if (permittedRouteMap.lexiconCategories?.path) {
      const catalogUploadLogsPath = generatePath(permittedRouteMap.lexiconCategories.path, {
        shopId,
        locale: notification.content.locale,
        tagType: LexiconTagType.LexiconTags,
      });
      navigateTo(catalogUploadLogsPath);
    }
  }, [shopId, notification.content.locale, navigateTo, permittedRouteMap.lexiconCategories?.path]);

  useImperativeHandle(cardRef, () => ({
    onNotificationClick,
  }));

  return (
    <NotificationCardMessageTypography>
      {getUserDisplayName(notification.createdByUser)} published a new Lexicon version of{' '}
      {notification.content.locale}.
    </NotificationCardMessageTypography>
  );
}
