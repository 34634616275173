import React, { HTMLAttributes, ReactNode } from 'react';
import { useDragAndDrop } from 'src/hooks/useDragAndDrop';
import { DraggableGridItemStyled } from '../ImageGrid.styles';

const GRID_ITEM_DRAG_DROP_TYPE = 'GRID_ITEM';

interface DraggableGridItemProps {
  children: ReactNode | ReactNode[];
  index: number;
  disableDrag?: boolean;
  onGridItemDrag: (sourceIndex: number, targetIndex: number) => void;
  onGridItemDrop: () => void;
}

export const DraggableGridItem = ({
  children,
  index: gridItemIndex,
  disableDrag = false,
  onGridItemDrag,
  onGridItemDrop,
  ...props
}: DraggableGridItemProps & HTMLAttributes<HTMLDivElement>): JSX.Element => {
  const { dragElementRef: gridItemRef, isDragging } = useDragAndDrop<HTMLDivElement>({
    acceptKey: GRID_ITEM_DRAG_DROP_TYPE,
    index: gridItemIndex,
    disableDrag,
    onDrag: onGridItemDrag,
    onDrop: onGridItemDrop,
  });

  return (
    <DraggableGridItemStyled
      ref={gridItemRef}
      isDragging={isDragging}
      disableDrag={disableDrag}
      {...props}
    >
      {children}
    </DraggableGridItemStyled>
  );
};
