import React from 'react';
import { AppRoute, AppSwitch, RoutedComponentProps } from 'src/app-routes';
import { DeepTagsProductContainer } from '../DeepTagsProductContainer';
import { DeepTagReportSFTPSettingsModalContainer } from '../DeepTagReportSFTPSettingsModalContainer';
import { DeepTagReportFileUploadModalContainer } from '../DeepTagReportFileUploadModalContainer';
import { DeepTagReportHowTodModalContainer } from '../DeepTagReportHowToModalContainer';

export type DeepTagReportsModalsSwitchProps = RoutedComponentProps;

export const DeepTagReportsModalsSwitch = ({
  permittedRouteMap,
}: DeepTagReportsModalsSwitchProps): JSX.Element => {
  return (
    <AppSwitch>
      <AppRoute
        route={permittedRouteMap.deepTagReportsSFTPsettings}
        Component={DeepTagReportHowTodModalContainer}
        componentProps={{ permittedRouteMap }}
        exact
      />
      <AppRoute
        route={permittedRouteMap.deepTagReportsFileUpload}
        Component={DeepTagReportFileUploadModalContainer}
        componentProps={{ permittedRouteMap }}
        exact
      />
      <AppRoute
        route={permittedRouteMap.deepTagReportsConfiguration}
        Component={DeepTagReportSFTPSettingsModalContainer}
        componentProps={{ permittedRouteMap }}
        exact
      />
      <AppRoute
        route={permittedRouteMap.deepTagReportProduct}
        Component={DeepTagsProductContainer}
        componentProps={{ permittedRouteMap }}
        exact={false}
      />
    </AppSwitch>
  );
};
