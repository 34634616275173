import styled from '@emotion/styled';

export const EditRankingStrategy = styled.div`
  padding: 24px;
  .ranking-strategy-container-products-dropdown {
    display: none;
  }
  .ranking-button-wrapper {
    border: none;
  }
`;
