import { FeedDataFieldType, ShopDataField } from '../../services/src/service/types';

export const getNumericFields = (fields: ShopDataField[]): ShopDataField[] => {
  return fields.filter(({ types }) =>
    types.some(
      t =>
        t === FeedDataFieldType.Long ||
        t === FeedDataFieldType.Integer ||
        t === FeedDataFieldType.Float
    )
  );
};
