import React, { ReactNode } from 'react';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import { MenuItem } from '../MenuItem';
import { useFindComponentByType } from '../hooks';
import {
  MultiSelectMenuListStyled,
  EllipsisWithTooltipStyled,
  CheckboxStyled,
} from './MultiSelectMenu.styles';
import { MultiSelectMenuProps } from './types';
import { useMultiSelect, ITEMS_TO_SHOW_WITHOUT_SCROLL } from './use-multi-select';

const OptionTemplate = ({ children }: { children: ReactNode[] | ReactNode }): JSX.Element => (
  <>{children}</>
);

export const MultiSelectMenu = ({
  options,
  children,
  enableSelectAll,
  selectAllText,
  onChange,
  selectedOptions,
  className,
}: MultiSelectMenuProps): JSX.Element => {
  const customOptionTemplate = useFindComponentByType({ type: OptionTemplate, children })?.props
    .children;

  const {
    handleToggleAllClick,
    selectAllOptionState,
    selectedAllOption,
    selectedOptionsState,
    handleItemClick,
  } = useMultiSelect({
    options,
    enableSelectAll,
    selectAllText,
    onChange,
    selectedOptions,
  });

  return (
    <MultiSelectMenuListStyled
      className={className}
      enableScroll={options.length > ITEMS_TO_SHOW_WITHOUT_SCROLL}
    >
      {enableSelectAll && (
        <MenuItem onClick={handleToggleAllClick} selected={selectAllOptionState}>
          {customOptionTemplate ? (
            customOptionTemplate(selectedAllOption, selectAllOptionState)
          ) : (
            <>
              <CheckboxStyled checked={selectAllOptionState} />
              <EllipsisWithTooltipStyled tooltipText={selectedAllOption.text}>
                <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                  {selectedAllOption.text}
                </Typography>
              </EllipsisWithTooltipStyled>
            </>
          )}
        </MenuItem>
      )}
      {options.map((option, index) => {
        const isSelected = selectedOptionsState.has(option.value);
        return (
          <MenuItem
            key={option.value}
            onClick={() => handleItemClick(option.value)}
            selected={isSelected}
          >
            {customOptionTemplate ? (
              customOptionTemplate(option, isSelected, index)
            ) : (
              <>
                <CheckboxStyled checked={isSelected} />
                <EllipsisWithTooltipStyled tooltipText={option.text}>
                  <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                    {option.text}
                  </Typography>
                </EllipsisWithTooltipStyled>
              </>
            )}
          </MenuItem>
        );
      })}
    </MultiSelectMenuListStyled>
  );
};

MultiSelectMenu.OptionTemplate = OptionTemplate;
