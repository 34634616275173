import { isEqual } from 'lodash';
import React, { useCallback, useState } from 'react';
import {
  NumericInputRangeProps,
  NumericInputRange as NumericInputRangeBase,
} from 'src/components-dummy';

export function NumericInputRange({
  values,
  onChange,
  ...rest
}: NumericInputRangeProps): JSX.Element {
  const [inputValues, setInputValues] = useState(values);

  const onBlur = useCallback(() => {
    if (!isEqual(inputValues, values)) {
      onChange?.(inputValues);
    }
  }, [inputValues, values, onChange]);

  return (
    <NumericInputRangeBase
      values={inputValues}
      onChange={setInputValues}
      onBlur={onBlur}
      {...rest}
    />
  );
}
