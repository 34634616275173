import { IFilterTableHeadRow } from './types';

export const tableColumns: Record<string, IFilterTableHeadRow> = {
  positionHandle: {
    Header: '',
    accessor: 'positionHandle',
    minWidth: 50,
    width: '5%',
  },
  sourceName: {
    Header: 'Source Name',
    accessor: 'sourceName',
    minWidth: 115,
    width: '32%',
  },
  dataSource: {
    Header: 'Source Type',
    accessor: 'dataSource',
    width: '28%',
  },
  valuesSorting: {
    Header: 'Values Arrangement ',
    accessor: 'valuesSorting',
    width: '28%',
  },
  rowActions: {
    Header: '',
    accessor: 'rowActions',
    width: '5%',
  },
};
