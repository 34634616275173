import { createAsyncThunk } from '@reduxjs/toolkit';

import { resultsModalService } from '../../../services/src/service/results-modal';

import { ResultsModalState } from '../../../app-state-types';

type UpdateResultsModalGeneralSettingsArgs = {
  shopId: number;
  variantId?: string;
  payload: ResultsModalState;
};

export const ResultsModalActions = {
  getResultsModalGeneralSettings: createAsyncThunk(
    'ResultsModal/GetGeneralSettings',
    async ({ shopId, variantId }: { shopId: number; variantId?: string }, { rejectWithValue }) => {
      const response = await resultsModalService.getGeneralSettings({ shopId, variantId });
      if (response.error !== null) {
        return rejectWithValue({ error: response.error });
      }
      return response.data.resultsModal;
    }
  ),
  updateResultsModalGeneralSettings: createAsyncThunk(
    'ResultsModal/UpdateGeneralSettings',
    async (
      { shopId, variantId, payload }: UpdateResultsModalGeneralSettingsArgs,
      { rejectWithValue }
    ) => {
      const response = await resultsModalService.updateGeneralSettings({
        shopId,
        variantId,
        generalSettings: payload.generalSettings,
      });
      if (response.error !== null) {
        return rejectWithValue({ error: response.error });
      }
      return response.data.resultsModal;
    }
  ),
};
