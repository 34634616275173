import { AsyncThunk } from '@reduxjs/toolkit/src/createAsyncThunk';
import { AnyAction } from '@reduxjs/toolkit';
import { RootReducerState } from '../../app-state-types';
import { PreviewFeature } from '../../services';
import { visualEditorActions } from '../../components-bl';

interface QueryParamConfig<T> {
  action: AsyncThunk<any, T, Record<string, unknown>> | ((payload: T) => AnyAction);
  payloadGenerator: (param: string, state: RootReducerState) => T;
}

export const queryParamConfig: Record<string, QueryParamConfig<any>> = {
  shopperExperience: {
    action: visualEditorActions.changeExperience,
    payloadGenerator: param => param as PreviewFeature,
  },
  collectionName: {
    action: visualEditorActions.fetchCollection,
    payloadGenerator: (param, state) => {
      return {
        shopId: state.shop.current?.shopId,
        collectionName: param,
      };
    },
  },
  search: {
    action: visualEditorActions.fetchSearchResults,
    payloadGenerator: (param, state) => ({
      shopId: state.shop.current?.shopId,
      sku: param,
    }),
  },
};
