import React, { useEffect, useState, useCallback } from 'react';
import { ShopTypes } from 'src/services';
import {
  Button,
  Typography,
  TypographyType,
  TypographyVariant,
  Switch,
} from 'src/components-dummy';
import { useValidateSchema } from 'src/hooks';
import { useKnnActionsMethods, NotifyUseKnnIsDirtyActionPayload } from './Actions';
import { Dispatch } from '../types';
import { useKnnValidationSchema } from './validators';
import './UseKnn.scss';
import { createOptionalPortal } from '../helpers';
import { isDataDirty } from '../../utils';

export interface UseKnnFormProps {
  shopId: number;
  selectedVariantId?: string;
  useKnn: boolean;
  formHeaderElementRef?: React.RefObject<HTMLElement | null>;
  disabled?: boolean;
  dispatch: Dispatch;
  shouldRefetch?: boolean;
  onIsDirty?: (payload: NotifyUseKnnIsDirtyActionPayload) => void;
}

export const UseKnn = ({
  useKnn: originalUseKnn,
  shopId,
  selectedVariantId,
  formHeaderElementRef,
  disabled,
  dispatch,
  shouldRefetch = false,
  onIsDirty,
}: UseKnnFormProps): JSX.Element | null => {
  const [useKnnDraft, setUseKnnDraft] = useState(originalUseKnn);

  const isDirty = isDataDirty(originalUseKnn, useKnnDraft);

  const { validate, isValid } = useValidateSchema<ShopTypes.UseKnn>({
    schema: useKnnValidationSchema,
  });

  const fetchUseKnn = useCallback(() => {
    dispatch(
      useKnnActionsMethods.fetchUseKnn({
        shopId,
        selectedVariantId,
      })
    );
  }, [shopId, selectedVariantId]);

  useEffect((): void => {
    setUseKnnDraft(originalUseKnn);
  }, [originalUseKnn]);

  useEffect(() => {
    const payload = { isDirty: !!isDirty };
    if (onIsDirty) {
      onIsDirty(payload);
    } else {
      dispatch(useKnnActionsMethods.notifyIsDirty(payload));
    }
  }, [isDirty]);

  useEffect(() => {
    if (shopId) {
      dispatch(
        useKnnActionsMethods.fetchUseKnn({
          shopId,
          selectedVariantId,
        })
      );
    }
  }, [shopId]);

  useEffect(() => {
    dispatch(useKnnActionsMethods.notifyIsDirty({ isDirty }));
  }, [isDirty]);

  useEffect(() => {
    fetchUseKnn();
  }, [fetchUseKnn]);

  useEffect(() => {
    if (shouldRefetch) {
      fetchUseKnn();
    }
  }, [shouldRefetch]);

  const onChange = (partialData: Partial<ShopTypes.UseKnn>) => {
    const updatedState = { useKnnDraft, ...partialData } as ShopTypes.UseKnn;
    validate({ dataToValidate: updatedState });
    setUseKnnDraft(updatedState.useKnn);
  };

  const onSubmit = () => {
    if (isValid) {
      dispatch(
        useKnnActionsMethods.updateUseKnn({
          shopId,
          selectedVariantId,
          useKnn: useKnnDraft,
        })
      );
    }
  };

  const onDiscard = (): void => {
    setUseKnnDraft(originalUseKnn);
  };

  const formButtons = (
    <>
      <Button onClick={onDiscard} variant='secondary' disabled={!isDirty}>
        Discard
      </Button>
      <Button onClick={onSubmit} variant='primary' disabled={!(isValid && isDirty)}>
        {selectedVariantId ? 'Apply' : 'Save'}
      </Button>
    </>
  );

  const formButtonsSection = createOptionalPortal({
    portalContent: disabled ? <></> : formButtons,
    targetContainerRef: formHeaderElementRef,
    fallback: <div className='use-knn-button-wrapper'>{formButtons}</div>,
  });

  return (
    <div className='use-knn-container'>
      {formButtonsSection}
      <Switch
        checked={useKnnDraft}
        onChange={() => {
          onChange({
            useKnn: !useKnnDraft,
          });
        }}
        disabled={disabled}
        className='use-knn-toggle'
      >
        <Switch.TitleTemplate className='use-knn-toggle-text'>
          <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
            Use OpenSearch KNN
          </Typography>
        </Switch.TitleTemplate>
      </Switch>
    </div>
  );
};
