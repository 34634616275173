import { ErrorInfo } from 'react';

export enum ErrorBoundaryActionType {
  UncaughtError = 'ErrorBoundary/UncaughtError',
}

export interface ErrorBoundaryUncaughtErrorAction {
  type: ErrorBoundaryActionType.UncaughtError;
  payload: ErrorBoundaryUncaughtErrorActionPayload;
}

export interface ErrorBoundaryUncaughtErrorActionPayload {
  error: Error;
  errorInfo: ErrorInfo;
}
