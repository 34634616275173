import React from 'react';
import { AvailableIcons } from 'src/components-dummy/Icon';
import { DeleteRowCellProps } from '../types';
import { ActionIcon } from '../../ActionIcon';

const COMPONENT_NAME = 'DeleteRowCell';

export const DeleteRowCell = <T extends Record<string, unknown>>({
  row: { index },
  column: { cellProps },
  deleteTableRow,
  data,
}: DeleteRowCellProps<T>): JSX.Element => {
  const dataId =
    cellProps && cellProps.prefixId ? `${cellProps.prefixId}_${COMPONENT_NAME}_index_${index}` : '';

  if (deleteTableRow && data) {
    return (
      <ActionIcon
        prefixId={dataId}
        className='delete-row-icon'
        iconName={AvailableIcons.TrashCan}
        size='small'
        onClick={() => deleteTableRow({ rowIndex: index, tableData: data })}
      />
    );
  }
  return <></>;
};
