import React, { useCallback } from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { generatePath } from 'react-router';
import { ThematicTagModal } from 'src/components-bl/Lexicon/components/ThematicTags/ThematicTagModal';
import { ILexiconTagsPaginationAPI } from 'src/services';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';
import { useValidateLocale } from '../../hooks';
import { createThematicTagContainerActions } from './CreateThematicTagContainer.actions';

export type CreateThematicTagContainerProps = RoutedComponentProps & {
  refetchLexiconTags?: () => Promise<ILexiconTagsPaginationAPI>;
};

export const CreateThematicTagContainer = ({
  permittedRouteMap,
  refetchLexiconTags,
}: CreateThematicTagContainerProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const shopId = useAppSelector(state => state.shop.current?.shopId);

  const locale = useValidateLocale({ permittedRouteMap, dispatch, shopId })?.locale;

  const onModalClose = useCallback(() => {
    if (permittedRouteMap.lexiconCategories?.path && shopId && locale) {
      dispatch(
        createThematicTagContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.lexiconCategories.path, {
            shopId,
            locale,
            tagType: LexiconTagType.ThematicTags,
          }),
        })
      );
    }
  }, [dispatch, permittedRouteMap.lexiconCategories, locale, shopId]);

  const onSuccess = useCallback(() => {
    refetchLexiconTags?.();
  }, [refetchLexiconTags]);

  if (!shopId || !locale) {
    return null;
  }

  return shopId ? (
    <ThematicTagModal
      dispatch={dispatch}
      mode='create'
      onClose={onModalClose}
      onSuccess={onSuccess}
      shopId={shopId}
      locale={locale}
      thematicTag={undefined}
    />
  ) : null;
};
