import styled from '@emotion/styled';
import { TableV2 } from '../../../../../components-dummy';

export const TableWrapperStyled = styled.div`
  width: fit-content;
  min-width: 100%;
`;

export const HeadRowStyled = styled(TableV2.HeadRow)`
  align-items: center;
`;

export const TableBodyRowCellAlignedCenterStyled = styled(TableV2.BodyRowCell)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableBodyRowCellStyled = styled(TableV2.BodyRowCell)`
  min-width: 165px;
`;

export const ProductImageStyled = styled.img`
  width: 64px;
  height: 64px;
  object-fit: contain;
`;

export const RowStyled = styled.div`
  border: 1px solid transparent;
  &:hover {
    cursor: pointer;
    border: ${({ theme }) => `1px solid ${theme.palette.custom['primary-main']}}`};
  }
`;
