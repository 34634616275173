import { Dispatch } from '../../../../types';
import { VariantFeature } from '../constants';

export enum VariantPanelActionTypes {
  NotifyIsDirty = 'VariantPanel/NotifyIsDirty',
  SetSelectedVariantFeature = 'VariantPanel/SetSelectedVariantFeature',
}

export interface VariantPanelNotifyIsDirtyActionPayload {
  isDirty: boolean;
}
export interface VariantPanelSetSelectedVariantFeatureActionPayload {
  selectedVariantFeatureId: string;
}

export interface VariantPanelResetFeatureErrorActionPayload {
  error: unknown;
}

export interface VariantPanelResetFeatureRequestActionPayload {
  shopId: number;
  variantId: string;
  experimentId: string;
  feature: VariantFeature;
}

export type VariantPanelResetFeatureSuccessActionPayload =
  VariantPanelResetFeatureRequestActionPayload;

export interface VariantPanelNotifyIsDirtyAction {
  type: typeof VariantPanelActionTypes.NotifyIsDirty;
  payload: VariantPanelNotifyIsDirtyActionPayload;
}

export interface VariantPanelSetSelectedVariantFeatureAction {
  type: typeof VariantPanelActionTypes.SetSelectedVariantFeature;
  payload: VariantPanelSetSelectedVariantFeatureActionPayload;
}

export type VariantPanelAction =
  | VariantPanelNotifyIsDirtyAction
  | VariantPanelSetSelectedVariantFeatureAction;

const variantPanelActions = {
  notifyIsDirty(payload: VariantPanelNotifyIsDirtyActionPayload): VariantPanelNotifyIsDirtyAction {
    return {
      type: VariantPanelActionTypes.NotifyIsDirty,
      payload,
    };
  },
  setSelectedVariantFeature(
    payload: VariantPanelSetSelectedVariantFeatureActionPayload
  ): VariantPanelSetSelectedVariantFeatureAction {
    return {
      type: VariantPanelActionTypes.SetSelectedVariantFeature,
      payload,
    };
  },
};

export const variantPanelActionMethods = {
  notifyIsDirty(payload: VariantPanelNotifyIsDirtyActionPayload) {
    return (dispatch: Dispatch): void => {
      dispatch(variantPanelActions.notifyIsDirty(payload));
    };
  },
  setSelectedVariantFeature: variantPanelActions.setSelectedVariantFeature,
};
