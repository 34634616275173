import { useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
import { FormData } from './CatalogExplorerFilterAutoComplete.types';
import { CatalogAvailableFilterOptions } from 'src/services/src/service/types/catalog-manager';
import { CatalogManagerService } from 'src/services/src/service/catalog-manager';
import { usePreviousState } from 'src/hooks';

const catalogManagerService = new CatalogManagerService();

interface UseManageFilterOptionsCountUpdate {
  shopId: number;
  catalogName: string;
  formData: FormData;
  field: string;
  mainSearchFilterTerm?: string;
  showPopUp: boolean;
  filters: Record<string, string[] | number[]>;
  lastChangeOnFilterType?: string;
  onSelectedValuesCountUpdate: ({
    updatedValuesCount,
  }: {
    updatedValuesCount: CatalogAvailableFilterOptions;
  }) => void;
}

export const useManageFilterOptionsCountUpdate = ({
  shopId,
  catalogName,
  formData,
  filters,
  mainSearchFilterTerm,
  field,
  showPopUp,
  lastChangeOnFilterType,
  onSelectedValuesCountUpdate,
}: UseManageFilterOptionsCountUpdate) => {
  const previousFilters = usePreviousState(filters) || {};
  const previousMainSearchFilterTerm = usePreviousState(mainSearchFilterTerm) || '';

  const fetchValuesCancellationRef = useRef(null as AbortController | null);

  const cancelFetchCounterUpdate = () => {
    if (!fetchValuesCancellationRef.current) {
      return;
    }

    try {
      fetchValuesCancellationRef.current.abort();
    } catch (error) {
      console.error(error);
    } finally {
      fetchValuesCancellationRef.current = null;
    }
  };

  const debouncedFetchCounterUpdate = useMemo(() => {
    return debounce(async () => {
      cancelFetchCounterUpdate();
      const abortController = new AbortController();
      fetchValuesCancellationRef.current = abortController;

      try {
        const data: CatalogAvailableFilterOptions =
          await catalogManagerService.getCatalogExplorerFilterOptionsCounterUpdate({
            shopId,
            catalogName,
            field,
            searchAfter: formData.searchAfter,
            filters,
            mainSearchFilterTerm,
            cancellationSignal: abortController.signal,
          });

        onSelectedValuesCountUpdate({ updatedValuesCount: data });
      } catch (error) {
        console.error("Couldn't get count update for selected values", error);
      }
    }, 500);
  }, [
    shopId,
    catalogName,
    field,
    filters,
    mainSearchFilterTerm,
    formData.searchTerm,
    formData.options,
    formData.searchAfter,
  ]);

  useEffect(() => {
    const prevFiltersKeys = Object.keys(previousFilters);
    const filtersKeys = Object.keys(filters);

    if (field === lastChangeOnFilterType || showPopUp) {
      return;
    }

    // Empty field filters (nothing to update)
    if (
      (filtersKeys.length === 0 && prevFiltersKeys.length === 0) ||
      (filters[field]?.length || 0) === 0
    ) {
      return;
    }

    // Changes in filters array (check reference changes)
    const hasSomeChanged = filtersKeys.some(key => {
      return filters[key] !== previousFilters[key];
    });

    if (hasSomeChanged) {
      debouncedFetchCounterUpdate();
    }
  }, [filters]);

  useEffect(() => {
    const hasFiltersForCountUpdate = !!filters[field]?.length;

    if (hasFiltersForCountUpdate && mainSearchFilterTerm !== previousMainSearchFilterTerm) {
      debouncedFetchCounterUpdate();
    }
  }, [mainSearchFilterTerm]);

  useEffect(() => {
    return () => {
      debouncedFetchCounterUpdate.cancel();
      cancelFetchCounterUpdate();
    };
  }, []);

  return {
    debouncedFetchCounterUpdate,
    cancelFetchCounterUpdate,
  };
};
