import React from 'react';
import {
  PluginHook,
  Row,
  useFilters,
  UseFiltersOptions,
  useFlexLayout,
  useGlobalFilter,
  UseGlobalFiltersInstanceProps,
  useResizeColumns,
  UseResizeColumnsState,
  useTable,
  UseTableInstanceProps,
  UseTableOptions,
} from 'react-table';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DraggableBodyRow } from './Components/DraggableBodyRow';
import { TableBodyRowColumnText as TableBodyRowCellText } from './Components/TableBodyRowDataText';
import { TableEmptyList } from './Components/TableEmptyList';
import { TableHeadRowCellText } from './Components/TableHeadHeaderText';
import { TableNoResults } from './Components/TableNoResults';
import {
  TableStyled,
  TableHeadHeaderStyled,
  TableHeadStyled,
  TableBodyStyled,
  TableBodyRowStyled,
  TableBodyRowCellStyled,
} from './TableV2.style';
import { TableHeaderCellResizableCursor } from './Components/TableHeaderCellResizableCursor';

export type TableRow<T extends Record<string, any>> = Row<T>;

/**
 * Types (exposed)
 */
export interface TableV2InstanceProps<TRowData extends Record<string, any>> {
  getTableProps: UseTableInstanceProps<TRowData>['getTableProps'];
  getTableBodyProps: UseTableInstanceProps<TRowData>['getTableBodyProps'];
  headerGroups: UseTableInstanceProps<any>['headerGroups'];
  rows: UseTableInstanceProps<TRowData>['rows'];
  prepareRow: UseTableInstanceProps<TRowData>['prepareRow'];
  state: UseTableInstanceProps<TRowData>['state'] & UseResizeColumnsState<TRowData>;
  visibleColumns: UseTableInstanceProps<TRowData>['visibleColumns'];
  preGlobalFilteredRows: UseGlobalFiltersInstanceProps<TRowData>['preGlobalFilteredRows'];
  setGlobalFilter: UseGlobalFiltersInstanceProps<TRowData>['setGlobalFilter'];
}

interface TableOptions<TRowData extends Record<string, any>> {
  columns: UseTableOptions<any>['columns'];
  defaultColumn?: UseTableOptions<any>['defaultColumn'];
  data: UseTableOptions<TRowData>['data'];
  filterTypes?: UseFiltersOptions<TRowData>['filterTypes'];
}

/**
 * Internal options use for component (not react-table props)
 */
interface TableCustomOptions {
  shouldUseFilters?: boolean;
  shouldGlobalFilters?: boolean;
  shouldUseResizeColumns?: boolean;
  shouldUseFlexLayout?: boolean;
}

/**
 * Main
 */
export interface TableV2Props<TRowData extends Record<string, any>> {
  children: (tableInstanceProps: TableV2InstanceProps<TRowData>) => JSX.Element | JSX.Element[];
  options: TableOptions<TRowData>;
  customOptions?: TableCustomOptions;
}

export const TableV2 = <TRowData extends Record<string, any>>({
  options,
  customOptions,
  children,
}: TableV2Props<TRowData>): any => {
  const plugins = [
    customOptions?.shouldUseFilters ? useFilters : undefined,
    customOptions?.shouldGlobalFilters ? useGlobalFilter : undefined,
    customOptions?.shouldUseResizeColumns ? useResizeColumns : undefined,
    customOptions?.shouldUseFlexLayout ? useFlexLayout : undefined,
  ].filter(hook => !!hook) as PluginHook<any>[];

  const defaultColumn = React.useMemo(
    () => ({
      // When using flex plugin- "useFlexLayout":
      minWidth: 20,
      width: 50, // width is used for both the flex-basis and flex-grow
      maxWidth: 400,
    }),
    []
  );

  const tableInstance: TableV2InstanceProps<TRowData> = useTable.apply(null, [
    { ...options, defaultColumn },
    ...plugins,
  ]) as unknown as TableV2InstanceProps<TRowData>;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  return (
    <DndProvider backend={HTML5Backend}>
      <TableStyled {...getTableProps()}>
        {children({
          getTableProps,
          getTableBodyProps,
          headerGroups,
          rows,
          prepareRow,
          state,
          visibleColumns,
          preGlobalFilteredRows,
          setGlobalFilter,
        })}
      </TableStyled>
    </DndProvider>
  );
};

TableV2.Head = TableHeadStyled;
TableV2.HeadRow = TableHeadHeaderStyled;
TableV2.HeaderRowCellResizableCursor = TableHeaderCellResizableCursor;
TableV2.HeadRowCellText = TableHeadRowCellText;
TableV2.Body = TableBodyStyled;
TableV2.BodyRow = TableBodyRowStyled;
TableV2.BodyRowCell = TableBodyRowCellStyled;
TableV2.BodyRowCellText = TableBodyRowCellText;
TableV2.EmptyList = TableEmptyList;
TableV2.NoResults = TableNoResults;
TableV2.DraggableBodyRow = DraggableBodyRow;
