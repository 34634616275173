import React from 'react';
import { AvailableIcons, Icon, SearchInput } from 'src/components-dummy';
import { SearchInputWrapperStyled } from './CatalogExplorerFilters.styles';

interface CatalogExplorerSearchInputProps {
  onChange: (value: string) => void;
  onClear: () => void;
  value: string;
}

export const CatalogExplorerSearchInput = ({
  onChange,
  onClear,
  value,
}: CatalogExplorerSearchInputProps) => {
  return (
    <SearchInputWrapperStyled>
      <SearchInput
        placeholder='Search SKU, Title or Category'
        value={value}
        onChange={onChange}
      ></SearchInput>
      {value && <Icon className='clear-button' name={AvailableIcons.Close} onClick={onClear} />}
    </SearchInputWrapperStyled>
  );
};
