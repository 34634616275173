import React, { useState } from 'react';
import classNames from 'classnames';
import { Icon, AvailableIcons } from '../Icon';
import { ActionIcon } from '../ActionIcon';
import { NotificationProps, NotificationType, notificationIcons } from './types';
import './Notification.scss';
import { Typography, TypographyType, TypographyVariant } from '../Typography';

export const Notification = ({
  children,
  mode = NotificationType.Error,
  className,
  closeable,
  onClose,
  icon,
  onClick,
}: NotificationProps): JSX.Element => {
  const [show, setShow] = useState<boolean>(true);

  function handleClose() {
    setShow(false);
    if (onClose) {
      setTimeout(() => {
        onClose();
      }, 1000);
    }
  }

  return (
    <div
      className={classNames(
        'syte-notification',
        `syte-notification-${mode}`,
        !show && 'syte-notification-hidden',
        closeable && 'syte-notification-closeable',
        className
      )}
      onClick={onClick}
    >
      <div className='syte-notification-icon'>
        <Icon name={icon || notificationIcons[mode]} />
      </div>
      <div className='syte-notification-content'>
        <Typography
          className='syte-notification-content-typography'
          type={TypographyType.Body}
          variant={TypographyVariant.SmallRegular}
        >
          {children}
        </Typography>
      </div>
      {closeable && (
        <ActionIcon
          iconName={AvailableIcons.Close}
          onClick={handleClose}
          className='syte-notification-close'
          size='tiny'
        />
      )}
    </div>
  );
};
