import React, { useCallback } from 'react';
import { EllipsisWithTooltip, MenuList, MenuListOption } from 'src/components-dummy';
import { DataFieldValueStyled, MenuListStyled } from './DataFieldValuesList.styles';

interface DataFieldSingleMenuListProps {
  options: { value: string; text: string }[];
  onChange: (values: string[]) => void;
  setRef: (el: Element | null) => void;
}
export function DataFieldSingleMenuList({
  options,
  onChange,
  setRef,
}: DataFieldSingleMenuListProps): JSX.Element {
  const onItemClick = useCallback(
    (selectedValue: string) => {
      onChange([selectedValue]);
    },
    [onChange]
  );

  return (
    <MenuListStyled options={options} onItemClick={onItemClick}>
      <MenuList.OptionTemplate>
        {(option: MenuListOption) => (
          <DataFieldValueStyled key={option.value} ref={setRef}>
            <EllipsisWithTooltip tooltipText={option.value}>{option.text}</EllipsisWithTooltip>
          </DataFieldValueStyled>
        )}
      </MenuList.OptionTemplate>
    </MenuListStyled>
  );
}
