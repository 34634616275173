import styled from '@emotion/styled';
import { Card } from 'src/components-dummy/Card';

export const CollectionSectionStyled = styled(Card)<{ withoutBorder?: boolean }>`
  padding: 32px;
  margin: 0 24px 24px 0;
  width: 100%;
  ${({ withoutBorder }) =>
    withoutBorder &&
    `
      border: none;
      box-shadow: none;
      padding: 0 32px;
    `};
`;
