import {
  EditableFilterItem,
  FilterItemDBStructure,
  FilterStatusOption,
  FilterStatusPropertyName,
} from '../types';

export const generateNewItem = (): EditableFilterItem => ({
  enabled: true,
  position: 0,
  displayNameKey: undefined,
  dataSource: undefined,
  type: undefined,
  displayType: undefined,
  bucketsOrder: undefined,
  isTypeNumeric: undefined,
  numBuckets: 4,
  minBucketDelta: 5,
  showRangeInput: true,
  key: `temp_${new Date().getTime()}`,
  [FilterStatusPropertyName]: FilterStatusOption.New,
});

export const isNewlyCreated = (key: string): boolean => key.startsWith('temp');

export const determineFilterStatus = (
  item: EditableFilterItem | FilterItemDBStructure
): FilterStatusOption => {
  if (!item.enabled) {
    return FilterStatusOption.Hidden;
  }
  return isNewlyCreated(item.key) ? FilterStatusOption.New : FilterStatusOption.Visible;
};

export function addNewItem(
  listDraft: EditableFilterItem[],
  cb: (newItem: EditableFilterItem) => void
): EditableFilterItem[] {
  const newItem = generateNewItem();
  const updatedList = [...listDraft];
  updatedList.unshift(newItem);
  cb(newItem);
  return updatedList;
}
