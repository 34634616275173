import React from 'react';
import { ErrorLabelStyled } from './components/MerchandisingRuleForm/MerchandisingRuleForm.styles';

export const mapErrorMessages = (errors: any): JSX.Element[] => {
  const rowErrorMessages: string[] = Object.keys(errors)
    .map(errorKey => errors[errorKey].message)
    .filter(message => !!message);

  return rowErrorMessages.map(errorMessage => {
    return <ErrorLabelStyled key={errorMessage}>{errorMessage}</ErrorLabelStyled>;
  });
};
