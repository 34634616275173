import React, { ReactNode, forwardRef } from 'react';
import ReactDOM from 'react-dom';
import './OverlayModal.scss';
import classnames from 'classnames';

export interface OverlayModalProps {
  children: ReactNode[] | ReactNode;
  className?: string;
  show: boolean;
}

export const OverlayModal = forwardRef<HTMLDivElement, OverlayModalProps>(
  ({ children, className, show }, ref): React.ReactPortal | null => {
    const component = (
      <div className={classnames('syte-overlay-modal', className)} ref={ref}>
        {children}
      </div>
    );
    return show
      ? ReactDOM.createPortal(
          show ? component : null,
          document.querySelector('[id="root"], body') as Element
        )
      : null;
  }
);
