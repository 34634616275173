import React from 'react';
import { ShopCreationForm } from 'src/components-bl';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { RoutedComponentProps } from 'src/app-routes';

type CreateShopModalContainerProps = RoutedComponentProps;

export const CreateShopModalContainer = ({
  permittedRouteMap,
}: CreateShopModalContainerProps): JSX.Element => {
  const accountId = useAppSelector(state => state.account.current?.accountId);

  const dispatch = useAppDispatch();

  const shopsListPath = permittedRouteMap.shopList?.path;

  return accountId && shopsListPath ? (
    <ShopCreationForm accountId={accountId} dispatch={dispatch} shopsListPath={shopsListPath} />
  ) : null;
};
