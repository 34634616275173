/* eslint-disable react/no-unescaped-entities */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { Box, TextBox } from 'src/components-dummy';
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AUTHENTICATION, CatalogFormDraft } from '../../CatalogForm.config';
import { AuthenticationSelect } from './AuthenticationSelect';

export interface CatalogFormWGETSettingsProps {
  onFieldChange: (partialData: Partial<CatalogFormDraft>) => void;
  errors?: any;
  wgetServerSettings?: CatalogFormDraft['wgetServerSettings'];
}

export const CatalogFormWGETSettings = React.memo(
  ({ onFieldChange, wgetServerSettings, errors }: CatalogFormWGETSettingsProps): JSX.Element => {
    const [shouldShowPassword, setShouldShowPassword] = useState(false);
    const onFormStateChange =
      (propName: string) =>
      (propValue: string | boolean): void => {
        onFieldChange({
          wgetServerSettings: {
            ...wgetServerSettings,
            [propName]: typeof propValue === 'string' ? propValue.trim() : propValue,
          },
        } as Pick<CatalogFormDraft, 'wgetServerSettings'>);
      };

    const onAuthenticationChange = (event: any) => {
      const key = event.target.value as keyof typeof AUTHENTICATION;
      const { value } = AUTHENTICATION[key];

      onFormStateChange('authentication')(value);
    };

    const onChangeShowPassword = () => {
      setShouldShowPassword(isPassword => !isPassword);
    };

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: '32px',
            gap: '16px',
          }}
        >
          <TextBox
            label='Feed URL'
            value={wgetServerSettings?.dataSourceURL}
            placeholder='Enter feed source Url'
            error={!!errors['wgetServerSettings.dataSourceURL']}
            onChange={onFormStateChange('dataSourceURL')}
            css={css`
              flex: 4;
            `}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: '32px',
            gap: '16px',
          }}
        >
          <AuthenticationSelect
            value={!!wgetServerSettings?.authentication}
            onChange={onAuthenticationChange}
            css={css`
              flex: 1;
            `}
          />

          {wgetServerSettings?.authentication && (
            <>
              <TextBox
                label='User Name'
                key='wget-username'
                value={wgetServerSettings?.username || ''}
                autoComplete='new-user-name'
                error={!!errors['wgetServerSettings.username']}
                placeholder='Username'
                onChange={onFormStateChange('username')}
                disabled={!wgetServerSettings?.authentication}
                css={css`
                  flex: 2;
                  max-width: 217px;
                `}
              />
              <TextBox
                label='Password'
                key='wget-password'
                type={shouldShowPassword ? 'text' : 'password'}
                value={wgetServerSettings?.password || ''}
                error={!!errors['wgetServerSettings.password']}
                placeholder='Password'
                autoComplete='new-password'
                onChange={onFormStateChange('password')}
                disabled={!wgetServerSettings?.authentication}
                css={css`
                  flex: 2;
                  max-width: 217px;
                `}
              >
                {!wgetServerSettings?.authentication ? null : (
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={onChangeShowPassword}
                    onMouseDown={onChangeShowPassword}
                    css={css`
                      margin-left: -7px;
                    `}
                  >
                    {shouldShowPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                )}
              </TextBox>
            </>
          )}
        </Box>
      </Box>
    );
  }
);
