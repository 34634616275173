import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { useIsInViewPort } from 'src/hooks';
import {
  AccountOptionStyled,
  BackdropStyled,
  PaginatedListStyled,
  NotFoundTypography,
  SearchInputStyled,
  SpinnerStyled,
  ValuesWrapper,
} from './styles';
import { SalesforceAccount } from 'src/services/src/service/salesforce/types';
import { MenuList, MenuListOption } from 'src/components-dummy';
import { salesforceService } from 'src/services/src/service/salesforce/salesforce.service';
import { AccountPreview } from './AccountPreview';

const NotFound = (): JSX.Element => {
  return <NotFoundTypography>Could not find any accounts</NotFoundTypography>;
};

interface PaginatedListProps {
  onSelectAccount: (account: SalesforceAccount) => void;
}

const PAGE_SIZE = 50;

const debouncedMethodCall = debounce(func => {
  func();
}, 500);

export function accountToDisplayText(account?: SalesforceAccount): string | undefined {
  if (!account) return undefined;

  return `${account.accountName} (${account.salesforceAccountId}), ${account.accountStatus}`;
}

export const PaginatedList = ({ onSelectAccount }: PaginatedListProps): JSX.Element => {
  const fetchValuesCancellationRef = useRef(null as AbortController | null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const { isInView, setRef } = useIsInViewPort({});

  const [searchText, setSearchText] = useState('');
  const [accounts, setAccounts] = useState<SalesforceAccount[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [needToLoadMore, setNeedToLoadMore] = useState(true);

  const showNotFoundIndication = useMemo(
    () => accounts.length === 0 && isLoading === false && searchText.length > 0,
    [accounts, isLoading, searchText]
  );

  const accountsOptionsMapped: MenuListOption<SalesforceAccount>[] = useMemo(() => {
    return accounts.map(account => ({
      text: accountToDisplayText(account),
      value: account,
      key: account.salesforceAccountId,
    }));
  }, [accounts]);

  const cancelFetchValues = () => {
    if (!fetchValuesCancellationRef.current) {
      return;
    }

    try {
      fetchValuesCancellationRef.current.abort();
    } catch (error) {
      console.error(error);
    } finally {
      fetchValuesCancellationRef.current = null;
    }
  };

  const loadAccounts = useCallback(
    ({
      skip = accounts.length,
      shouldLoadMore = needToLoadMore,
      term = searchText,
      previousAccounts = accounts,
    }: {
      term?: string;
      skip?: number;
      shouldLoadMore?: boolean;
      previousAccounts?: SalesforceAccount[];
    }) => {
      debouncedMethodCall(async () => {
        if (!shouldLoadMore) {
          return;
        }

        setIsLoading(true);
        cancelFetchValues();

        fetchValuesCancellationRef.current = new AbortController();

        try {
          const {
            salesforceAccounts: newAccounts,
            pagination: { totalCount },
          } = await salesforceService.searchAccounts({
            skip,
            limit: PAGE_SIZE,
            term,
            cancellationSignal: fetchValuesCancellationRef.current.signal,
          });

          const updatedAccountsState = [...previousAccounts, ...newAccounts];

          setAccounts(updatedAccountsState);

          const hasReachedEnd = updatedAccountsState.length === totalCount;
          setNeedToLoadMore(!hasReachedEnd);
        } catch (error) {
          console.error(error);
        }
        setIsLoading(false);
      });
    },
    [accounts, setIsLoading, setAccounts, searchText, needToLoadMore, setNeedToLoadMore]
  );

  useEffect(() => {
    setAccounts([]);
    setNeedToLoadMore(true);

    loadAccounts({
      previousAccounts: [],
      shouldLoadMore: true,
    });
  }, [searchText, setNeedToLoadMore, setAccounts]);

  useEffect(() => {
    if (isInView) {
      loadAccounts?.({});
    }
  }, [isInView]);

  useEffect(() => {
    searchInputRef.current?.focus();

    return () => cancelFetchValues();
  }, []);

  return (
    <PaginatedListStyled>
      <SearchInputStyled
        value={searchText}
        onChange={setSearchText}
        placeholder='Search...'
        inputRef={searchInputRef}
      />
      {isLoading && <SpinnerStyled />}

      <ValuesWrapper>
        <MenuList<SalesforceAccount>
          options={accountsOptionsMapped}
          onItemClick={onSelectAccount as any}
        >
          <MenuList.OptionTemplate>
            {(option: MenuListOption<SalesforceAccount>) => (
              <AccountOptionStyled key={option.text} ref={setRef}>
                <AccountPreview account={option.value} />
              </AccountOptionStyled>
            )}
          </MenuList.OptionTemplate>
        </MenuList>
        {showNotFoundIndication && <NotFound />}
        {isLoading && <BackdropStyled />}
      </ValuesWrapper>
    </PaginatedListStyled>
  );
};
