import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Page, BackButtonTitle } from 'src/components-dummy';
import { useAppSelector } from 'src/hooks';
import { BoughtTogetherSettings } from 'src/components-bl';
import { ShopRoutes } from '../../types/enums/shop-routes';
import './BoughtTogetherContainer.scss';

export const BoughtTogetherContainer = (): JSX.Element | null => {
  const formHeaderRef = useRef<HTMLDivElement>(null);
  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const boughtTogetherSettings = useAppSelector(state => state.boughtTogetherSettings);
  const dataFieldsState = useAppSelector(state => state.dataFields);
  const dispatch = useDispatch();
  const navigationPath = `/shops/${shopId}/${ShopRoutes.ShopperExperiences}`;
  return (
    <Page className='bought-together-container'>
      <Page.Header>
        <BackButtonTitle path={navigationPath}>Bought Together</BackButtonTitle>
        <div className='bought-together-buttons-section' ref={formHeaderRef} />
      </Page.Header>
      <Page.Content>
        {shopId && (
          <BoughtTogetherSettings
            boughtTogetherSettings={boughtTogetherSettings}
            dataFields={dataFieldsState.dataFields || []}
            shopId={shopId}
            formHeaderElementRef={formHeaderRef}
            dispatch={dispatch}
          />
        )}
      </Page.Content>
    </Page>
  );
};
