import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { Dispatch } from 'src/components-bl/types';
import { TableV2 } from 'src/components-dummy';
import { Filter, SyteLayout, FilterDataSource, sortByTranslations } from 'src/services';
import { DataFieldLookupTable } from 'src/components-bl/hooks/useDataFieldsLookupTable';
import { filtersListPageActions } from '../../Actions';
import { tableColumns } from './tableColumns';
import { IFilterTableBodyRow } from './types';
import { FilterTableRow } from './FiltersTableRow';
import { TableHeaderStyled, TableWrapperStyled } from './FiltersTableRow.styles';
import { dataSourceTranslations } from './constants';

interface FiltersListProps {
  filters: Filter[];
  dataFieldsLookupTable: DataFieldLookupTable | undefined;
  shopId?: number;
  syteLayout: SyteLayout;
  onEnableFilter: (filterId: string, enabled: boolean) => void;
  onDeleteFilter: (catalogId: string) => void;
  navigateToEditFilter: (filterId: string) => void;
  dispatch: Dispatch;
}

export const FiltersList = ({
  filters,
  dataFieldsLookupTable,
  shopId,
  syteLayout,
  onEnableFilter,
  onDeleteFilter,
  navigateToEditFilter,
  dispatch,
}: FiltersListProps): JSX.Element => {
  const [draggedAndDroppedFilters, setDraggedAndDroppedFilters] = useState([...filters]);
  const [isSaveFilterPositionInProgress, setIsSaveFilterPositionInProgress] = useState(false);

  useEffect(() => {
    setDraggedAndDroppedFilters(filters);
  }, [filters, setDraggedAndDroppedFilters]);

  const tableData: IFilterTableBodyRow[] = useMemo(() => {
    return draggedAndDroppedFilters.map(filter => {
      const { id, sourceField, dataSource, valuesSorting, enabled } = filter;
      return {
        id,
        sourceName: sourceField,
        dataSource: dataSourceTranslations[dataSource],
        valuesSorting: valuesSorting?.sortBy ? sortByTranslations[valuesSorting.sortBy] : '',
        enabled,
        originalFilter: filter,
        isUsingInvalidDataField:
          dataSource === FilterDataSource.Catalog && dataFieldsLookupTable
            ? !dataFieldsLookupTable[sourceField]
            : false,
      };
    });
  }, [draggedAndDroppedFilters, dataFieldsLookupTable]);

  const columnsArr = useMemo(() => Object.values(tableColumns), [tableColumns]);

  const onRowDrag = useCallback(
    (sourceIndex: number, targetIndex: number) => {
      setDraggedAndDroppedFilters(previousFilters => {
        const rearrangedFilters = [...previousFilters];
        const elementToMove = rearrangedFilters[sourceIndex];

        rearrangedFilters.splice(sourceIndex, 1);
        rearrangedFilters.splice(targetIndex, 0, elementToMove);

        return rearrangedFilters;
      });
    },
    [setDraggedAndDroppedFilters]
  );

  const onRowDrop = useCallback(() => {
    if (!shopId || isEqual(filters, draggedAndDroppedFilters)) {
      return;
    }

    setIsSaveFilterPositionInProgress(true);

    (
      dispatch(
        filtersListPageActions.rearrangeFiltersOrder({
          shopId,
          syteLayout,
          filters: draggedAndDroppedFilters,
        })
      ) as any
    )
      .unwrap()
      .catch(() => {
        setDraggedAndDroppedFilters([...filters]);
      })
      .finally(() => {
        setIsSaveFilterPositionInProgress(false);
      });
  }, [
    shopId,
    syteLayout,
    filters,
    draggedAndDroppedFilters,
    setDraggedAndDroppedFilters,
    setIsSaveFilterPositionInProgress,
    dispatch,
  ]);

  return (
    <TableWrapperStyled>
      <TableV2<IFilterTableBodyRow> options={{ columns: columnsArr, data: tableData }}>
        {({ getTableBodyProps, headerGroups, rows, prepareRow }) => {
          const headerGroup = headerGroups[0];

          return (
            <>
              <TableHeaderStyled {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <TableV2.HeadRow
                    {...column.getHeaderProps()}
                    width={columnsArr[index].width}
                    minWidth={columnsArr[index].minWidth}
                    key={column.id}
                  >
                    <TableV2.HeadRowCellText>{column.render('Header')}</TableV2.HeadRowCellText>
                  </TableV2.HeadRow>
                ))}
              </TableHeaderStyled>
              <TableV2.Body {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <FilterTableRow
                      key={row.original.id}
                      row={row}
                      disableDrag={isSaveFilterPositionInProgress}
                      onEnableClick={onEnableFilter}
                      onDeleteFilter={onDeleteFilter}
                      navigateToEditFilter={navigateToEditFilter}
                      onRowDrag={onRowDrag}
                      onRowDrop={onRowDrop}
                    />
                  );
                })}
              </TableV2.Body>
            </>
          );
        }}
      </TableV2>
    </TableWrapperStyled>
  );
};
