import styled from '@emotion/styled';
import { Link } from '../Link';

export const LinkStyled = styled(Link)`
  display: initial;
`;

export const ObjectStyled = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #f5f5f5;
  padding: 8px;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  border-radius: 4px;
`;

export const TextOverflowContainer = styled.div<{ contentHeight: string }>`
  max-height: ${({ contentHeight }) => contentHeight};
  overflow: hidden;
  transition: max-height 0.3s ease;
  cursor: pointer;
  line-height: 16px;
`;

export const TextOverflowStyled = styled.div<{ expanded: boolean }>`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${({ expanded }) => (expanded ? 'normal' : 'nowrap')};
  line-break: anywhere;
`;
