import React, { useCallback } from 'react';
import {
  AvailableIcons,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../components-dummy';
import { handleKeyboardEvents } from '../../../utils/key-handlers';
import {
  RecentSearchesContainer,
  RecentSearchesHeader,
  RecentSearchesResult,
  RecentSearchesResults,
} from '../AdminSearchModal.styles';

interface RecentSearchesProps {
  recent: string[];
  handleRecentClick: (value: string) => void;
  modalRef: React.MutableRefObject<HTMLElement | null>;
}

export const RecentSearches = ({
  recent,
  handleRecentClick,
  modalRef,
}: RecentSearchesProps): JSX.Element => {
  const onKeyDownCallback = useCallback(
    (value: string) => (e: React.KeyboardEvent) => {
      handleKeyboardEvents(e, handleRecentClick, value, modalRef?.current);
    },
    [handleRecentClick]
  );

  return (
    <RecentSearchesContainer>
      <RecentSearchesHeader>
        <Icon name={AvailableIcons.Clock} />
        <Typography type={TypographyType.Body} variant={TypographyVariant.LargeMedium}>
          Recently searched
        </Typography>
      </RecentSearchesHeader>
      <RecentSearchesResults>
        {recent.map((value: string) => {
          return (
            <RecentSearchesResult
              key={value}
              onClick={() => handleRecentClick(value)}
              onKeyDown={onKeyDownCallback(value)}
              tabIndex={0}
            >
              <Icon name={AvailableIcons.Search} />
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                {value}
              </Typography>
            </RecentSearchesResult>
          );
        })}
      </RecentSearchesResults>
    </RecentSearchesContainer>
  );
};
