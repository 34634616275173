import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dispatch } from 'src/components-bl';
import {
  Button,
  DialogModalHeader,
  DialogModalTitle,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { v4 } from 'uuid';
import { useAnimateNumber } from 'src/hooks/useAnimateNumber';
import { exportLexiconModalActions } from './ExportLexiconModal.actions';
import {
  DialogModalContentStyled,
  DialogModalFooterStyled,
  ExportLexiconModalStyled,
} from './ExportLexiconModal.styles';

export interface ExportLexiconModalProps {
  shopId: number;
  locale: string;
  dispatch: Dispatch;
  onClose: () => void;
  progress?: number;
}

export const ExportLexiconModal = ({
  shopId,
  locale,
  dispatch,
  onClose,
  progress = 0,
}: ExportLexiconModalProps): JSX.Element => {
  const [isInProgress, setIsInProgress] = useState(false);

  const exportCancellationRef = useRef(null as AbortController | null);

  const count = useAnimateNumber({ totalSteps: 100, value: progress, initialValue: 0 });

  const onCancelExport = useCallback(() => {
    if (exportCancellationRef.current) {
      try {
        exportCancellationRef.current.abort();
      } catch (error) {
        console.error(error);
      }
    }

    exportCancellationRef.current = null;
    setIsInProgress(false);
    onClose();
  }, [setIsInProgress, onClose]);

  const onExport = useCallback(async () => {
    setIsInProgress(true);

    exportCancellationRef.current = new AbortController();

    try {
      await (
        dispatch(
          exportLexiconModalActions.exportLexicon({
            shopId,
            locale,
            requestId: v4().toString(),
            cancellationSignal: exportCancellationRef.current.signal,
          })
        ) as any
      ).unwrap();
    } catch (error) {
      console.error(error);
      onClose();
    }
  }, [shopId, locale, dispatch]);

  useEffect(() => {
    if (count >= 100) {
      setIsInProgress(false);
      onClose();
    }
  }, [count]);

  return (
    <ExportLexiconModalStyled open onClose={onCancelExport}>
      <>
        <DialogModalHeader>
          <DialogModalTitle>Export Lexicon File</DialogModalTitle>
        </DialogModalHeader>
        <DialogModalContentStyled>
          <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Paragraph}>
            Click below to download the content of the lexicon
          </Typography>
        </DialogModalContentStyled>
        <DialogModalFooterStyled>
          <Button
            variant='primary'
            onClick={onExport}
            loading={isInProgress}
            progress={`${count}%`}
          >
            Export File
          </Button>
          <Button variant='tertiary' onClick={onCancelExport}>
            Cancel
          </Button>
        </DialogModalFooterStyled>
      </>
    </ExportLexiconModalStyled>
  );
};
