import React, { useMemo } from 'react';
import classNames from 'classnames';
import {
  CollapsableEntityCard,
  TextBox,
  TooltipedFormField,
  ListSelector,
} from 'src/components-dummy';
import { FiltersDisplayConfig, FilterValuesOrder } from 'src/components-bl';
import { FilterStatusOption } from 'src/services/src/service/types/filters';
import { ShopDataField } from 'src/services/src/service/types/data-fields';
import { FilterCardProps } from './types';
import { FilterCardHeader } from './components';
import './FilterCard.scss';

export const FilterCard = ({
  filterItem,
  status,
  className,
  isDraggable = false,
  onItemChanged,
  saveItem,
  dismissChanges,
  toggleItemVisibility,
  setIsFormOpen,
  deleteItem,
  isFormExpended,
  isHiddenFilterSoftAlertShown,
  dataFields,
  messages,
  shouldIncludeDisplayName,
  errors = {},
}: FilterCardProps): JSX.Element => {
  const selectedDataSourceOption = useMemo(
    (): ShopDataField | undefined =>
      dataFields?.find((opt: ShopDataField) => opt.name === filterItem.dataSource),
    [dataFields, filterItem]
  );
  const showHiddenSoftAlert = useMemo(
    (): boolean => isHiddenFilterSoftAlertShown(filterItem.key),
    [filterItem]
  );
  return (
    <CollapsableEntityCard
      entityId={filterItem.key}
      additionalClassName={classNames('syte-filter-card', className)}
      status={status}
      opened={isFormExpended}
      preventDefaultCollapseOnClick
    >
      <CollapsableEntityCard.Header>
        <FilterCardHeader
          filterItem={filterItem}
          isDraggable={isDraggable}
          dismissChanges={() => dismissChanges(filterItem.key)}
          saveItem={() => saveItem(filterItem.key)}
          setIsFormOpen={setIsFormOpen}
          toggleVisibility={toggleItemVisibility}
          deleteItem={deleteItem}
          isFormExpended={isFormExpended}
          messages={messages}
          isHiddenFilterSoftAlertShown={showHiddenSoftAlert}
        />
      </CollapsableEntityCard.Header>

      <CollapsableEntityCard.Content>
        <div className='left-column'>
          <TooltipedFormField
            label='Data Source'
            className='data-source'
            error={errors.dataSource?.required || errors.dataSource?.valueValidation}
          >
            {status === FilterStatusOption.New ? (
              <ListSelector
                items={dataFields}
                idField='name'
                searchableFields={['name']}
                onItemSelected={newValue =>
                  onItemChanged({ changedProperty: 'dataSource', newValue })
                }
                selectedItem={selectedDataSourceOption}
                popupClassName='data-source-selector'
              >
                <ListSelector.Item>{(item: ShopDataField) => item.name}</ListSelector.Item>
              </ListSelector>
            ) : (
              <TextBox value={filterItem.dataSource} disabled />
            )}
          </TooltipedFormField>
          {shouldIncludeDisplayName && (
            <TooltipedFormField
              label='Display Name'
              error={errors.displayNameKey?.required || errors.displayNameKey?.valueValidation}
              className='filter-name'
              additionalInfo='Check with your account manager regarding translations in different languages'
            >
              <TextBox
                name='displayNameKey'
                value={filterItem.displayNameKey || ''}
                onChange={newValue =>
                  onItemChanged({ changedProperty: 'displayNameKey', newValue })
                }
              />
            </TooltipedFormField>
          )}
          {!filterItem.isTypeNumeric ? (
            <FilterValuesOrder
              bucketsOrder={filterItem.bucketsOrder}
              dispatcher={newValue => onItemChanged({ changedProperty: 'bucketsOrder', newValue })}
              error={errors.bucketsOrder?.required || errors.bucketsOrder?.valueValidation}
            />
          ) : null}
        </div>
        {filterItem.dataSource ? (
          <FiltersDisplayConfig
            isNumericType={Boolean(filterItem.isTypeNumeric)}
            displayType={filterItem.displayType}
            showRangeInput={filterItem.showRangeInput}
            numBuckets={filterItem.numBuckets}
            minBucketDelta={filterItem.minBucketDelta}
            colorShape={filterItem.colorShape}
            dispatcher={onItemChanged}
            error={errors.displayType?.required || errors.displayType?.valueValidation}
          />
        ) : null}
      </CollapsableEntityCard.Content>
    </CollapsableEntityCard>
  );
};
