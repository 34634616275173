/**
 * Downloads file from the specified path with a custom display name
 * Internally uses a temp anchor (<a>) tag
 */
export function downloadFile({
  filePath,
  fileDisplayName,
}: {
  filePath: string;
  fileDisplayName: string;
}): void {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = filePath;
  a.download = fileDisplayName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
