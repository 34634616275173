import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useRef,
  ReactNode,
  MutableRefObject,
  MouseEvent,
} from 'react';
import { PaperProps } from '@mui/material';
import {
  AutocompleteCloseReason,
  AutocompleteRenderGetTagProps as AutocompleteRenderGetTagPropsMui,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import { isString } from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';
import { useScrollUtils } from 'src/utils/scroll-utils';
import {
  AutocompleteStyled,
  TextFieldStyled,
  ListboxComponentStyled,
  StyledPaper,
  NewOptionTypography,
} from './AutoComplete.styles';
import { MenuItem } from '../MenuItem';
import { TypographyType, TypographyVariant } from '../Typography';
import { AutoCompletePopper } from './AutoCompletePopper';
import { AutoCompleteExpandableChip } from './AutoCompleteExpandableChip';
import { CircularProgress, CircularProgressSizes } from '../CircularProgress';
import { useFindComponentByType } from '../hooks';

const ListboxComponent = forwardRef((props: any, ref) => {
  return <ListboxComponentStyled {...props} ref={ref} />;
});

export interface AutoCompleteOption<Value = string, Data = unknown> {
  value: Value;
  title: string;
  data?: Data;
}

export type OptionProps<V = string, D = unknown> = React.HTMLAttributes<HTMLLIElement> & {
  // eslint-disable-next-line react/no-unused-prop-types
  option: AutoCompleteOption<V, D> & { isOptionInitialRender?: boolean };
};

export function Option({ children }: { children: ReactNode }): JSX.Element {
  return <>{children}</>;
}

export const InputStartAdornment = ({ children }: { children: ReactNode }): JSX.Element => (
  <>{children}</>
);

export type AutocompleteRenderGetTagProps = AutocompleteRenderGetTagPropsMui;

export type RenderTagsType = (
  value: unknown[],
  getTagProps: AutocompleteRenderGetTagProps
) => ReactNode;

const ESCAPE = [27, 'Escape'];

export interface AutoCompleteProps
  extends Pick<React.HTMLAttributes<HTMLDivElement>, 'onBlur' | 'onFocus' | 'onKeyDown'> {
  options: AutoCompleteOption[];
  freeSolo?: boolean;
  children?: JSX.Element | JSX.Element[];
  multiple?: boolean;
  placeholder?: string;
  textBoxValue?: string;
  onTextBoxChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  filterOptions?: (options: unknown[]) => unknown[];
  errored?: boolean;
  onChange?: (value: string[], option?: Array<AutoCompleteOption | string> | string) => void;
  selectedValues?: string[];
  disabled?: boolean;
  className?: string;
  limitTags?: number;
  inputRef?: MutableRefObject<any> | null | undefined;
  paperProps?: PaperProps;
  loading?: boolean;
  onClose?: (event: React.SyntheticEvent, reason: AutocompleteCloseReason) => void;
  onOpen?: (event: React.SyntheticEvent<Element, Event>) => void;
  openPopUp?: boolean;
  hasTransparentBackground?: boolean;
  closeOnSingleMatch?: boolean;
  renderTags?: RenderTagsType;
  onTagClick?: (tagValue: string, event?: MouseEvent) => void;
  autoFocus?: boolean;
  readOnly?: boolean;
  allowDeleteTagInReadOnly?: boolean;
}

export const AutoComplete = ({
  errored,
  options,
  onTextBoxChange,
  freeSolo,
  multiple,
  selectedValues,
  onChange,
  textBoxValue: textBoxValueExternal = '',
  disabled,
  className,
  placeholder = 'Type here...',
  onFocus: onFocusExternal,
  onBlur: onBlurExternal,
  limitTags,
  inputRef,
  paperProps,
  loading,
  onClose,
  onOpen,
  onKeyDown: onKeyDownExternal,
  openPopUp = false,
  hasTransparentBackground,
  closeOnSingleMatch,
  renderTags,
  onTagClick,
  filterOptions,
  autoFocus,
  readOnly,
  allowDeleteTagInReadOnly,
  children,
}: AutoCompleteProps): JSX.Element => {
  const { lockScroll, unlockScroll } = useScrollUtils();
  const [textBoxValue, setTextBoxValue] = useState(textBoxValueExternal);
  const [popUpShouldBeOpened, setPopUpShouldBeOpened] = useState(false);
  const [isInFocus, setIsInFocus] = useState(false);
  const elementRef = useRef<null | HTMLDivElement>(null);
  const [valueIsSelected, setValueIsSelected] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [ignoreUseEffect, setIgnoreUseEffect] = useState(false);

  const optionComponent = useFindComponentByType({ type: Option, children });
  const inputStartAdornmentComponent = useFindComponentByType({
    type: InputStartAdornment,
    children,
  });

  const executeScroll = () => {
    if (elementRef && elementRef.current) {
      setTimeout(
        () =>
          elementRef?.current?.scrollIntoView?.({
            behavior: 'smooth',
            block: 'center',
          }),
        0
      );
    }
  };

  const selectedValuesSet = useMemo(() => {
    return Array.isArray(selectedValues) ? new Set(selectedValues) : new Set();
  }, [selectedValues]);

  const handleRenderInput = (props: AutocompleteRenderInputParams) => {
    // eslint-disable-next-line no-param-reassign
    props.inputProps.value = textBoxValue;

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      props.inputProps.onChange?.(event);
      onTextBoxChange?.(event);
      setTextBoxValue(event.target.value);
      setValueIsSelected(false);

      if (!isDirty) {
        setIsDirty(true);
      }
    };

    const onKeyDown = (event: any) => {
      onKeyDownExternal?.(event);
      const shouldStopPropagation = textBoxValue?.length && event.key === 'Backspace';

      if (shouldStopPropagation) {
        event.stopPropagation();
      }
    };

    const inputProps = {
      ...props.InputProps,
      endAdornment: (
        <>
          {loading ? (
            <CircularProgress color='inherit' size={CircularProgressSizes.ExtraSmall} />
          ) : null}
          {props.InputProps.endAdornment}
        </>
      ),
      ...(!multiple && inputStartAdornmentComponent
        ? { startAdornment: inputStartAdornmentComponent }
        : {}),
    };

    return (
      <TextFieldStyled
        inputRef={inputRef}
        {...props}
        placeholder={placeholder}
        onChange={onInputChange}
        onKeyDown={onKeyDown}
        InputProps={inputProps}
      />
    );
  };

  const onDeleteTag = useCallback(
    (index: number) => {
      const updatedValues = (selectedValues || []).filter((_value, i) => i !== index);
      onChange?.(updatedValues);
    },
    [selectedValues, onChange]
  );

  const handleRenderTags: RenderTagsType = useCallback(
    (tagOptions, getTagProps) => {
      return (tagOptions as AutoCompleteOption[]).map((tagOption, index) => (
        <AutoCompleteExpandableChip
          {...getTagProps({ index })}
          onDelete={readOnly && !allowDeleteTagInReadOnly ? undefined : () => onDeleteTag(index)}
          key={tagOption.value}
          label={tagOption.title}
          deleteIcon={<ClearIcon fontSize='small' />}
          onTagClick={onTagClick}
        />
      ));
    },
    [onDeleteTag]
  );

  let actualOptions: Array<AutoCompleteOption & { isOptionInitialRender?: boolean }> =
    useMemo(() => {
      let shouldCreateNewOption = false;

      if (multiple && freeSolo) {
        shouldCreateNewOption =
          textBoxValue !== undefined &&
          textBoxValue.length > 0 &&
          options.length === 0 &&
          selectedValuesSet.has(textBoxValue) === false;
      } else {
        shouldCreateNewOption = !!textBoxValue?.length;
      }

      if (shouldCreateNewOption && freeSolo) {
        const filteredOptions = options.filter(option => option.value !== textBoxValue);

        // If we have less options, it means the option exist
        const optionExists = options.length > 0 && filteredOptions.length < options.length;

        const isNewOption = loading || !optionExists;

        const newOption = {
          value: textBoxValue,
          title: textBoxValue,
          isOptionInitialRender: isNewOption,
        } as AutoCompleteOption & { isOptionInitialRender?: boolean };

        // Show always search option at top. while loading only show whats written as an option.
        return loading ? [newOption] : [newOption, ...filteredOptions];
      }

      return options;
    }, [options, textBoxValue, selectedValuesSet, loading, multiple, freeSolo]);

  const optionsLookupTable = useMemo(() => {
    return actualOptions.reduce(
      (accumulator, current: AutoCompleteOption) => {
        accumulator[current.value] = current;
        return accumulator;
      },
      {} as Record<string, AutoCompleteOption>
    );
  }, [actualOptions]);

  const selectedOptions = useMemo(() => {
    try {
      const result = isString(selectedValues)
        ? selectedValues
        : (selectedValues?.map(value => {
            return optionsLookupTable[value] || { value, title: value };
          }) as AutoCompleteOption[]);

      return result;
    } catch (error) {
      console.error(error);
      return [];
    }
  }, [selectedValues, optionsLookupTable]);

  const clearTextInput = useCallback(() => {
    if (!isDirty) {
      setIsDirty(true);
    }

    onTextBoxChange?.({ target: { value: '' } } as any);
    setTextBoxValue('');
  }, [isDirty, onTextBoxChange, setTextBoxValue]);

  const onSelectedChange = (
    newValue: Array<AutoCompleteOption | string> | string,
    isInBlur?: boolean
  ) => {
    let targetValue: any = newValue;

    if (Array.isArray(newValue)) {
      const stringOptions = (newValue as Array<AutoCompleteOption | string>).map(selectedOption =>
        isString(selectedOption) ? selectedOption : selectedOption?.value
      ) as string[];

      targetValue = multiple ? stringOptions : stringOptions[0];
    } else if (newValue === null) {
      targetValue = multiple ? [] : undefined;
    } else if (typeof newValue === 'object') {
      targetValue = (newValue as AutoCompleteOption).value;
    }

    if (isInBlur) {
      setValueIsSelected(false);
      actualOptions = [];
    } else {
      setValueIsSelected(true);
      onChange?.(targetValue, newValue);
    }

    setIsDirty(false);
    setPopUpShouldBeOpened(false);

    if (multiple) {
      clearTextInput();
    }
  };

  const onSelectedValuesChange = (
    event: React.SyntheticEvent,
    newValue: Array<AutoCompleteOption | string> | string
  ) => {
    onSelectedChange(newValue);
  };

  const onFocus = useCallback(
    (event?: React.FocusEvent<HTMLDivElement>) => {
      if (event) {
        onFocusExternal?.(event);
      }

      inputRef?.current?.focus();
      setIsInFocus(true);
      executeScroll();
      lockScroll('.contentContainer');
      if (openPopUp) {
        setPopUpShouldBeOpened(true);
        setIgnoreUseEffect(true);
      }
    },
    [onFocusExternal, inputRef]
  );

  const onBlur = useCallback(
    (event: any) => {
      setIsDirty(false);
      onBlurExternal?.(event);
      setIsInFocus(false);
      unlockScroll('.contentContainer');
      if (textBoxValue) {
        if (multiple) {
          clearTextInput();

          if (!selectedValuesSet.has(textBoxValue) && freeSolo) {
            onSelectedChange([...(selectedValues || []), textBoxValue], true);
          }
        } else if (!selectedValuesSet.has(textBoxValue[0]) && freeSolo) {
          onSelectedChange([textBoxValue], true);
        } else if (freeSolo) {
          onSelectedChange([...(selectedValues || []), textBoxValue], true);
        }
      }
    },
    [onBlurExternal, textBoxValue, setIsDirty]
  );

  const handleRenderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: unknown) => {
    const optionCasted = option as AutoCompleteOption & { isOptionInitialRender?: boolean };

    const existingOption = optionsLookupTable[optionCasted.value] as AutoCompleteOption & {
      isOptionInitialRender?: boolean;
    };

    if (optionComponent?.props?.children) {
      return optionComponent?.props?.children?.({ ...props, option: existingOption });
    }

    return (
      <MenuItem {...(props as any)}>
        {existingOption?.isOptionInitialRender ? (
          <NewOptionTypography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
            Select: {optionCasted.title}
          </NewOptionTypography>
        ) : (
          existingOption.title
        )}
      </MenuItem>
    );
  };

  const getOptionLabel = (option: unknown) => {
    const optionCasted = option as AutoCompleteOption | string;

    if (isString(optionCasted)) return optionCasted;

    if (optionCasted && optionCasted?.title) {
      return optionCasted.title;
    }
    return '';
  };

  // sync text box internal state with external
  useEffect(() => {
    if (textBoxValue !== textBoxValueExternal) {
      setTextBoxValue(textBoxValueExternal);
    }
  }, [textBoxValueExternal]);

  useEffect(() => {
    if (ignoreUseEffect) {
      setIgnoreUseEffect(false);
      return;
    }

    let newPopUpShouldBeOpened = false;

    if (!isInFocus) {
      newPopUpShouldBeOpened = false;
    } else if (!isDirty) {
      // don't show options popup if user didn't type anything
      newPopUpShouldBeOpened = false;
    } else if (options.length > 0) {
      newPopUpShouldBeOpened = true;
    } else if (options.length === 0 && !freeSolo && isDirty && textBoxValue?.length && !loading) {
      // open popup to show "no results" as we type (isDirty), and after loading
      newPopUpShouldBeOpened = true;
    } else if (actualOptions.length !== options.length && !valueIsSelected) {
      newPopUpShouldBeOpened = true;
      setValueIsSelected(false);
    }

    if (newPopUpShouldBeOpened !== popUpShouldBeOpened) {
      setPopUpShouldBeOpened(newPopUpShouldBeOpened);
    }
  }, [actualOptions, options, isInFocus, textBoxValue, isDirty, loading]);

  useEffect(() => {
    if (multiple) return;

    const valuesCasted = selectedValues as unknown as string;

    if (valuesCasted !== textBoxValue) {
      setTextBoxValue(valuesCasted);
      setPopUpShouldBeOpened(false);
    }
  }, [selectedValues]);

  const onKeyDownHandler = (code: any) => {
    if (ESCAPE.includes(code.key)) {
      setPopUpShouldBeOpened(false);
    }
  };

  useEffect(() => {
    if (multiple || !closeOnSingleMatch || actualOptions.length !== 1) return;
    if (selectedOptions === actualOptions[0].value) {
      setPopUpShouldBeOpened(false);
    }
  }, [multiple, closeOnSingleMatch, selectedOptions, actualOptions]);

  useEffect(() => {
    if (autoFocus && !textBoxValueExternal && !selectedValues?.length) {
      onFocus();
    }
  }, [autoFocus, textBoxValueExternal, onFocus, selectedValues]);

  return (
    <>
      <AutocompleteStyled
        hasTransparentBackground={hasTransparentBackground}
        onKeyDown={onKeyDownHandler}
        ref={elementRef}
        popupIcon=''
        value={selectedOptions}
        options={actualOptions}
        open={popUpShouldBeOpened}
        errored={errored}
        multiple={multiple}
        freeSolo={freeSolo}
        disablePortal
        componentsProps={{
          popper: {
            sx: {
              zIndex: 2000,
            },
          },
          paper: paperProps || {
            style: { width: '100%' },
          },
        }}
        PaperComponent={StyledPaper}
        PopperComponent={AutoCompletePopper}
        ChipProps={{ deleteIcon: <ClearIcon fontSize='small' /> }}
        id='auto-complete'
        ListboxComponent={ListboxComponent}
        sx={{ width: 300 }}
        renderInput={handleRenderInput}
        onChange={onSelectedValuesChange as any}
        disabled={disabled}
        className={className}
        renderOption={handleRenderOption}
        getOptionLabel={getOptionLabel}
        onFocus={onFocus}
        onBlur={onBlur}
        filterOptions={filterOptions}
        limitTags={limitTags}
        onClose={onClose}
        onOpen={onOpen}
        noOptionsText={textBoxValue?.length && !loading ? 'No Results' : undefined}
        loading={loading}
        renderTags={renderTags || handleRenderTags}
        readOnly={readOnly}
      />
    </>
  );
};

AutoComplete.Option = Option;
AutoComplete.InputStartAdornment = InputStartAdornment;
