import React from 'react';
import { Skeleton } from 'src/components-dummy/Skeleton';

export const SideNavItemSkeleton = (): JSX.Element => {
  return (
    <div className='syte-side-navigation-item-skeleton'>
      <Skeleton variant='rounded' height='24px' width='24px' sx={{ marginRight: '15px' }} />
      <Skeleton variant='rectangular' height='24px' width='140px' />
    </div>
  );
};
