/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { userFormActions, UserListAction, UserListActionTypes } from 'src/components-bl';
import { adminSearchModalActions } from 'src/components-bl/AdminSearchModal/AdminSearchModal.actions';
import { rootContainerActions } from 'src/root-container/Actions';
import { UserTypes } from 'src/services';
import { UserListReducerState } from '../app-state-types';

const defaultState: UserListReducerState = {
  current: undefined,
  users: [],
};

function getCurrentUser(
  users: UserTypes.User[] | undefined,
  userId: number | undefined
): UserTypes.User | undefined {
  const currentUser = users?.find(user => user.userId === userId);
  return currentUser;
}

function deleteUser(
  users: UserTypes.User[] | undefined,
  userId: number | undefined
): UserTypes.User[] {
  const newUsers = users?.filter(user => user.userId !== userId);
  return newUsers ?? [];
}

function updateUser(
  users: UserTypes.User[] | undefined,
  updatedUser: Partial<UserTypes.User>
): UserTypes.User[] {
  const updateUsers = users?.map(user => {
    if (user.userId === updatedUser.userId) {
      return { ...user, ...updatedUser };
    }
    return user;
  });
  return updateUsers ?? [];
}

function getUserByUserIdOrDefault(
  users: UserTypes.User[] | undefined,
  userId: number | undefined
): UserTypes.User | undefined {
  const currentUser = getCurrentUser(users, userId) ?? users?.[0];
  return currentUser;
}

/**
 * If you want to use redux toolkit createAction: use createReducer + addCase (or other toolkit functions)
 * If you want to also keep an existing switch case that holds actions that are not created from toolkit, wrap the switch in addDefaultCase
 */
export const userListReducer = createReducer(defaultState, builder => {
  builder
    .addCase(userFormActions.updateUser.fulfilled, (state, action) => {
      const updatedUsers = updateUser(state.users, action.payload.user);
      const currentUser = getUserByUserIdOrDefault(updatedUsers, action.payload.user.userId);

      return { ...state, current: currentUser, users: updatedUsers };
    })
    .addCase(userFormActions.createUser.fulfilled, (state, action) => {
      return { ...state, current: undefined, users: [action.payload.user, ...state.users] };
    })
    .addCase(adminSearchModalActions.navigateToUser.fulfilled, (state, action) => {
      const {
        account: { users = [] },
        userId,
      } = action.payload;

      return { ...state, current: getCurrentUser(users, userId), users };
    })
    .addCase(rootContainerActions.setUserOnlineStatus, (state, action) => {
      const updatedUsers = updateUser(state.users, action.payload);
      const currentUser = { ...state.current };

      if (state.current?.userId === action.payload.userId) {
        currentUser.online = action.payload.online;
      }

      return { ...state, users: updatedUsers, currentUser };
    })
    .addDefaultCase((state, toolkitAction) => {
      const action = toolkitAction as UserListAction;
      switch (action.type) {
        case UserListActionTypes.UserSelected:
          return {
            ...state,
            current: getCurrentUser(state.users, action.payload.userId),
          };
        case UserListActionTypes.DeleteUserSuccess: {
          const current =
            state.current?.userId === action.payload.userId ? defaultState.current : state.current;
          return {
            ...state,
            current,
            users: deleteUser(state.users, action.payload.userId),
          };
        }
        case UserListActionTypes.GetAllUsersSuccess: {
          const { users } = action.payload;
          return {
            ...state,
            users,
            current: getUserByUserIdOrDefault(users, state.current?.userId),
          };
        }
        default:
          return state;
      }
    });
});
