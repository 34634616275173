import { AvailableIcons } from 'src/components-dummy';
import { DomainEntityPath } from 'src/services';
import { ParentDomainBase } from '../../types';
import { rankingSubDomainGroup } from '../ranking-sub-domain-group';

export const visualSearchDomainGroup: ParentDomainBase = {
  domainKey: DomainEntityPath.VisualSearch,
  title: 'Visual Search',
  icon: AvailableIcons.Star,
  subDomains: [{ ...rankingSubDomainGroup, domainKey: DomainEntityPath.VisualSearchRanking }],
};
