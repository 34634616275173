export enum StateType {
  fetchingStatus = 'fetching',
  connected = 'connected',
  unConnected = 'unConnected',
  noCollection = 'noCollection',
  fetchingLink = 'fetchingLink',
  reFetchingStatus = 'reFetching',
  disconnecting = 'disconnecting',
}

export enum ErrorType {
  fetchCollectionError = 'fetchCollectionError',
  fetchConnectionLinkError = 'fetchConnectionLinkError',
  invalidConnectionError = 'invalidConnectionError',
}

export type State = {
  type: StateType;
  connectionLink: string | null;
  error: { type: ErrorType } | null;
};
