import React, { useMemo } from 'react';
import { useAppSelector } from 'src/hooks';
import { AppRoute, AppSwitch, RouteSettings, RoutedComponentProps } from 'src/app-routes';
import {
  CreateThematicTagContainer,
  CreateThematicTagContainerProps,
} from '../CreateThematicTagContainer';
import { CreateLexiconRuleContainer } from '../CreateLexiconRuleContainer';
import { EditThematicTagContainer } from '../EditThematicTagContainer';
import { ImportLexiconContainer } from '../ImportLexiconContainer';
import { ExportLexiconContainer } from '../ExportLexiconContainer';
import { EditLexiconRuleContainer } from '../EditLexiconRuleContainer';

export type LexiconTablePageModalsSwitchProps = RoutedComponentProps &
  Pick<CreateThematicTagContainerProps, 'refetchLexiconTags'>;

interface ComponentRoute {
  route: RouteSettings;
  Component: () => JSX.Element | null;
}

export const LexiconTablePageModalsSwitch = ({
  permittedRouteMap,
  refetchLexiconTags,
}: LexiconTablePageModalsSwitchProps): JSX.Element => {
  const shopId = useAppSelector(state => state.shop.current?.shopId);

  const relevantRoutes = useMemo(
    () =>
      [
        {
          route: permittedRouteMap.importLexicon,
          Component: ImportLexiconContainer,
        },
        {
          route: permittedRouteMap.exportLexicon,
          Component: ExportLexiconContainer,
        },
        {
          route: permittedRouteMap.createThematicTag,
          Component: CreateThematicTagContainer,
        },
        {
          route: permittedRouteMap.editThematicTag,
          Component: EditThematicTagContainer,
        },
        {
          route: permittedRouteMap.createLexiconRule,
          Component: CreateLexiconRuleContainer,
        },
        {
          route: permittedRouteMap.editLexiconRule,
          Component: EditLexiconRuleContainer,
        },
      ].filter(routeComponent => !!routeComponent.route) as ComponentRoute[],
    [
      permittedRouteMap.createThematicTag,
      permittedRouteMap.editThematicTag,
      permittedRouteMap.importLexicon,
      permittedRouteMap.exportLexicon,
      permittedRouteMap.createLexiconRule,
      permittedRouteMap.editLexiconRule,
    ]
  );

  const routeComponents = useMemo(
    () =>
      relevantRoutes.map(({ route, Component }) => (
        <AppRoute
          key={route.path}
          route={route}
          Component={Component}
          componentProps={{ permittedRouteMap, shopId, refetchLexiconTags }}
          exact
        />
      )),
    [relevantRoutes, permittedRouteMap, shopId, refetchLexiconTags]
  );

  return <AppSwitch>{routeComponents}</AppSwitch>;
};
