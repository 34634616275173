import styled from '@emotion/styled';
import Popover from '@mui/material/Popover';

export const DatePickerTitleStyled = styled.div`
  color: #212121;
  font-weight: 600;
  padding: 5px 17px 0px 24px;
  position: relative;
  top: 11px;
`;

export const CustomPopoverStyled = styled(Popover)({
  '& .MuiPaper-root': {
    maxHeight: 'none',
  },
});
