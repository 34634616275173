import {
  DomainEntityPath,
  ShopDraft,
  GetShopDraftArguments,
  ShopVersion,
  FieldType,
  ShopDataField,
} from 'src/services';

export enum PublishStatusIndicationActionTypes {
  GetDraftRequest = 'PublishStatusIndication/GetDraftRequest',
  GetDraftSuccess = 'PublishStatusIndication/GetDraftSuccess',
  GetDraftError = 'PublishStatusIndication/GetDraftError',
  PublishDraftRequest = 'PublishStatusIndication/PublishDraftRequest',
  PublishDraftSuccess = 'PublishStatusIndication/PublishDraftSuccess',
  PublishDraftError = 'PublishStatusIndication/PublishDraftError',
  DiscardDraftRequest = 'PublishStatusIndication/DiscardDraftRequest',
  DiscardDraftSuccess = 'PublishStatusIndication/DiscardDraftSuccess',
  DiscardDraftError = 'PublishStatusIndication/DiscardDraftError',
}

export interface PublishNotificationBannerGetDataFieldsRequestActionPayload {
  shopId: number;
  fieldType: FieldType;
}

export interface PublishNotificationBannerGetDataFieldsSuccessActionPayload {
  shopId: number;
  dataFields: ShopDataField[];
}
export interface DiscardDraftArguments
  extends PublishStatusIndicationPublishDraftRequestActionPayload {
  isAllSelected: boolean;
  totalChanges: number;
  resetPage: () => void;
}
export type PublishStatusIndicationGetDraftRequestActionPayload = GetShopDraftArguments;

export interface PublishStatusIndicationGetDraftSuccessActionPayload {
  draft: ShopDraft | undefined;
}

export interface PublishStatusIndicationGetDraftErrorActionPayload {
  error: unknown;
}

export interface PublishStatusIndicationGetDraftRequestAction {
  type: typeof PublishStatusIndicationActionTypes.GetDraftRequest;
  payload: PublishStatusIndicationGetDraftRequestActionPayload;
}

export interface PublishStatusIndicationGetDraftSuccessAction {
  type: typeof PublishStatusIndicationActionTypes.GetDraftSuccess;
  payload: PublishStatusIndicationGetDraftSuccessActionPayload;
}

export interface PublishStatusIndicationGetDraftErrorAction {
  type: typeof PublishStatusIndicationActionTypes.GetDraftError;
  payload: PublishStatusIndicationGetDraftErrorActionPayload;
}

export interface PublishStatusIndicationPublishDraftRequestActionPayload {
  shopId: number;
  selectedDomainGroups: DomainEntityPath[];
}

export interface PublishDraftArguments
  extends PublishStatusIndicationPublishDraftRequestActionPayload {
  isAllSelected: boolean;
  totalChanges: number;
  closeModal: () => void;
}

export interface PublishStatusIndicationPublishDraftSuccessActionPayload {
  customMessage: string;
  publishedVersion: ShopVersion;
}

export interface PublishStatusIndicationPublishDraftErrorActionPayload {
  error: unknown;
}

export interface PublishStatusIndicationPublishDraftRequestAction {
  type: typeof PublishStatusIndicationActionTypes.PublishDraftRequest;
  payload: PublishStatusIndicationPublishDraftRequestActionPayload;
}

export interface PublishStatusIndicationPublishDraftSuccessAction {
  type: typeof PublishStatusIndicationActionTypes.PublishDraftSuccess;
  payload: PublishStatusIndicationPublishDraftSuccessActionPayload;
}

export interface PublishStatusIndicationPublishDraftErrorAction {
  type: typeof PublishStatusIndicationActionTypes.PublishDraftError;
  payload: PublishStatusIndicationPublishDraftErrorActionPayload;
}

export interface PublishStatusIndicationDiscardDraftRequestActionPayload {
  shopId: number;
}

export interface PublishStatusIndicationDiscardDraftErrorActionPayload {
  customMessage: string;
  error: unknown;
}

export interface PublishStatusIndicationDiscardDraftRequestAction {
  type: typeof PublishStatusIndicationActionTypes.DiscardDraftRequest;
  payload: PublishStatusIndicationDiscardDraftRequestActionPayload;
}

export interface PublishStatusIndicationDiscardDraftSuccessActionPayload {
  customMessage: string;
}

export interface PublishStatusIndicationDiscardDraftSuccessAction {
  type: typeof PublishStatusIndicationActionTypes.DiscardDraftSuccess;
  payload: PublishStatusIndicationDiscardDraftSuccessActionPayload;
}

export interface PublishStatusIndicationDiscardDraftErrorAction {
  type: typeof PublishStatusIndicationActionTypes.DiscardDraftError;
  payload: PublishStatusIndicationDiscardDraftErrorActionPayload;
}

export type PublishStatusIndicationAction =
  | PublishStatusIndicationGetDraftRequestAction
  | PublishStatusIndicationGetDraftSuccessAction
  | PublishStatusIndicationGetDraftErrorAction
  | PublishStatusIndicationPublishDraftRequestAction
  | PublishStatusIndicationPublishDraftSuccessAction
  | PublishStatusIndicationPublishDraftErrorAction
  | PublishStatusIndicationDiscardDraftRequestAction
  | PublishStatusIndicationDiscardDraftSuccessAction
  | PublishStatusIndicationDiscardDraftErrorAction;
