import { Rule, RuleGroup } from '../../../types';

export const defaultRule: Rule = {
  fieldSource: undefined,
  field: undefined,
  operator: undefined,
  values: [],
};

export const buildDefaultRuleGroup = (collectionId: string, collectionName: string): RuleGroup => {
  return {
    id: Date.now().toString(),
    collectionId,
    collectionName,
    rules: [defaultRule],
  };
};
