import React, { useCallback, useEffect } from 'react';
import { generatePath, useParams } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { EditFilterPage } from 'src/components-bl/Filters';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useDataFieldsBySupportedFeature } from 'src/components-bl/hooks';
import { ShopDataFieldsSupportedFeatures, SyteLayout } from 'src/services';
import { editFilterContainerActions } from './Actions';

interface EditFilterContainerProps extends RoutedComponentProps {
  syteLayout: SyteLayout;
  hasReachedMaxAllowedCustomOrderFiltersLimit?: boolean;
}

export const EditFilterContainer = ({
  syteLayout,
  permittedRouteMap,
  hasReachedMaxAllowedCustomOrderFiltersLimit,
}: EditFilterContainerProps): JSX.Element => {
  const { filterId: filterIdParam } = useParams<{ filterId: string }>();

  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const selectedFilter = useAppSelector(state => state.filters.selectedFilter);
  const dataFields = useAppSelector(state => state.dataFields?.dataFields);

  const dispatch = useAppDispatch();

  const { supportedDataFields } = useDataFieldsBySupportedFeature({
    dataFields,
    feature: ShopDataFieldsSupportedFeatures.Filters,
  });

  const navigateToList = useCallback(() => {
    if (permittedRouteMap.filters && shopId) {
      dispatch(
        editFilterContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.filters.path, { shopId, syteLayout }),
        })
      );
    }
  }, [shopId, syteLayout, dispatch, permittedRouteMap.filters]);

  useEffect(() => {
    if (!filterIdParam) {
      navigateToList();
      return;
    }

    if (!shopId) {
      return;
    }

    dispatch(
      editFilterContainerActions.getFilterSet({
        shopId,
        syteLayout,
      }) as any
    );

    dispatch(
      editFilterContainerActions.getFilter({ shopId, syteLayout, filterId: filterIdParam }) as any
    )
      .unwrap()
      .catch((error: Error) => {
        console.error(error);
        navigateToList();
      });
  }, [filterIdParam]);

  return (
    <EditFilterPage
      shopId={shopId}
      syteLayout={syteLayout}
      dispatch={dispatch}
      permittedRouteMap={permittedRouteMap}
      availableDataFields={supportedDataFields}
      filter={selectedFilter}
      hasReachedMaxAllowedCustomOrderFiltersLimit={hasReachedMaxAllowedCustomOrderFiltersLimit}
    />
  );
};
