import styled from '@emotion/styled';
import LinkMui from '@mui/material/Link';

export const LinkStyled = styled(LinkMui)`
  display: inline-flex;
  align-items: center;
  gap: 4px;

  color: ${({ theme }) => {
    return theme.palette.custom['primary-main'];
  }} !important;

  text-decoration: none;
  vertical-align: unset; /* override MUI internal css MuiTypography */

  :hover {
    text-decoration: underline;
  }

  svg {
    path {
      stroke: ${({ theme }) => {
        return theme.palette.custom['primary-main'];
      }};
    }
  }
` as typeof LinkMui;
