import styled from '@emotion/styled';
import { Skeleton } from 'src/components-dummy';

export const FiltersRowWrapperStyled = styled.div`
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  border-radius: 8px;
`;

export const StyledSkeleton = styled(Skeleton)`
  height: 32px;
`;

export const SkeletonsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 57px;
  gap: 15px;
  padding: 0 6px;
`;
