import styled from '@emotion/styled';
import { TagsInput } from 'src/components-dummy';

export const AllowlistInputSectionTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  width: 324px;
`;

export const TagsInputStyled = styled(TagsInput)`
  &:focus-within,
  &:focus,
  &:hover {
    &:not(.disabled) {
      border-color: ${({ theme }) => theme.palette.custom['primary-main']} !important;
    }
  }
`;
