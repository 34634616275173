import React, { ReactNode } from 'react';
import classNames from 'classnames';

export interface FormCardContentProps {
  children: ReactNode | ReactNode[];
  className?: string;
}

export const FormCardContent = ({ children, className }: FormCardContentProps): JSX.Element => {
  return <div className={classNames('syte-form-card-content', className)}>{children}</div>;
};
