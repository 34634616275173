import React from 'react';
import { Button } from 'src/components-dummy/Button';

import { Typography, TypographyType, TypographyVariant } from '../../Typography';
import { TableEmptyListMessage, TableEmptyListStyled } from '../TableV2.style';

interface TableEmptyListProps {
  width: number;
  marginTop?: number;
  marginBottom?: number;
  message?: string | JSX.Element;
  onButtonClick?: () => void;
  buttonText?: string;
}

export const TableEmptyList = ({
  message,
  buttonText,
  width,
  marginTop,
  marginBottom,
  onButtonClick,
}: TableEmptyListProps): JSX.Element => (
  <TableEmptyListStyled width={width} marginTop={marginTop} marginBottom={marginBottom}>
    <div>
      {typeof message === 'string' ? (
        <TableEmptyListMessage>
          <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
            {message}
          </Typography>
        </TableEmptyListMessage>
      ) : (
        message
      )}
      {buttonText && onButtonClick ? (
        <Button variant='primary' onClick={onButtonClick}>
          {buttonText}
        </Button>
      ) : null}
    </div>
  </TableEmptyListStyled>
);
