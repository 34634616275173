import React, { useCallback, useEffect, useState } from 'react';
import { Dispatch } from 'src/components-bl/types';
import { useMerchRulesIndicators } from './useMerchRulesIndicators';
import { useNavigateToVisualEditorRoute } from './useNavigateToVisualEditorRoute';
import {
  getSearchableStringFromRule,
  MerchandisingRuleWithExtraIndications,
} from '../../MerchandisingRules/components/MerchandisingRulesListPage/components';
import { useTimeout } from '../../../components-dummy';
import { merchandisingRulesManagementActions } from '../../MerchandisingRules/components/MerchandisingRulesManagement/MerchandisingRulesManagement.actions';
import { merchandisingRulesActions } from '../../MerchandisingRules/merchandisingRules.actions';
import { MerchandisingRulesListActions } from '../../MerchandisingRules/components/MerchandisingRulesListPage/components/MerchandisingRulesList/Actions/MerchandisingRulesList.actions';
import { visualEditorActions } from '../state';
import { useAppSelector } from '../../../hooks';
import { ContentType, PreviewMerchRule } from '../types';
import { MerchandisingRuleTypes } from '../../../services';
import { RuleAction } from '../../../services/src/service/types/shops';
import { PermittedRouteMap } from '../../../app-routes';
import { ShopFeature } from 'src/app-types';

function filterMerchandisingRules(
  rules: PreviewMerchRule[],
  searchValueTrimmed: string
): PreviewMerchRule[] {
  return rules.filter(rule => {
    const searchableString = getSearchableStringFromRule(rule);
    return searchableString.includes(searchValueTrimmed);
  });
}

export const useMerchRulesList = ({
  shopId,
  onRuleSaved,
  dispatch,
  permittedRouteMap,
}: {
  shopId: number;
  onRuleSaved: VoidFunction;
  dispatch: Dispatch;
  permittedRouteMap: PermittedRouteMap;
}) => {
  const { contentType, selectedExperience } = useAppSelector(({ visualEditor }) => visualEditor);
  const { loading, merchandisingRules } = useMerchRulesIndicators(selectedExperience);
  const [ruleFilterValue, setRuleFilterValue] = useState<string>('');
  const [filteredRules, setFilteredRules] = useState<PreviewMerchRule[]>([]);
  const showFilteredRules = !!merchandisingRules?.length && !!ruleFilterValue.length;

  const delayedOnRuleSavedCallback = useTimeout(onRuleSaved || (() => {}), 500);

  const applyFilterValue = useCallback((rules: PreviewMerchRule[] | null, filterValue: string) => {
    const filterValueTrimmed = filterValue.toLowerCase().trim();
    if (!rules || !filterValueTrimmed.length) {
      setFilteredRules([]);
      return;
    }
    const filteredMerchRules = filterMerchandisingRules(rules, filterValueTrimmed);
    setFilteredRules(filteredMerchRules);
  }, []);

  const changeFilterValue = useCallback((value: string) => {
    setRuleFilterValue(value);
  }, []);

  const navigateToMerchRulesPage = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      window.open(`/shops/${shopId}/merchandising-rules`, '_blank');
    },
    [shopId]
  );

  useEffect(() => {
    applyFilterValue(merchandisingRules, ruleFilterValue);
  }, [ruleFilterValue, merchandisingRules]);

  useEffect(() => {
    dispatch(
      merchandisingRulesManagementActions.getDataFields({
        shopId,
      })
    );
    dispatch(
      merchandisingRulesActions.getShopLocales({
        shopId,
      })
    );
  }, []);

  const toggleRuleAndRefresh = useCallback(
    (rule: MerchandisingRuleWithExtraIndications) => {
      (
        dispatch(
          MerchandisingRulesListActions.updateRule({
            shopId,
            rule,
          })
        ) as any
      )
        .unwrap()
        .then(
          ({
            merchandisingRule,
          }: {
            merchandisingRule: MerchandisingRuleTypes.MerchandiseRule;
          }) => {
            dispatch(visualEditorActions.findAndUpdateRule(merchandisingRule));
            delayedOnRuleSavedCallback();
          }
        );
    },
    [shopId, delayedOnRuleSavedCallback]
  );

  const showMerchRules = [ContentType.NoResults, ContentType.ShowOffers].includes(contentType);

  const { navigateToVisualEditorRoute: navigateToEditMerchandisingRule } =
    useNavigateToVisualEditorRoute({
      route: permittedRouteMap?.visualEditorEditMerchandisingRule,
    });

  const { navigateToVisualEditorRoute: navigateToVisualEditorEditMode } =
    useNavigateToVisualEditorRoute({
      route: permittedRouteMap?.visualEditorEdit,
    });

  const onRuleClick = (rule: PreviewMerchRule) => {
    dispatch(visualEditorActions.setSelectedRule(rule));
    return rule.action === RuleAction.PinToPosition && selectedExperience === ShopFeature.BrowsePLP
      ? navigateToVisualEditorEditMode({ shopId, ruleId: rule.id })
      : navigateToEditMerchandisingRule({ shopId, ruleId: rule.id });
  };

  return {
    loading,
    showMerchRules,
    filteredRules,
    ruleFilterValue,
    showFilteredRules,
    changeFilterValue,
    navigateToMerchRulesPage,
    merchandisingRules: showFilteredRules ? filteredRules : merchandisingRules,
    toggleRuleAndRefresh,
    onRuleClick,
  };
};
