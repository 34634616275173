import React from 'react';
import TabsMUI, { TabsProps as TabsMUIProps } from '@mui/material/Tabs';

/**
 * Custom Props (extends TabsMUIProps)
 */
type TabsProps = TabsMUIProps;

export const TabsV2 = ({ children, onChange, value, ...rest }: TabsProps): JSX.Element => {
  return (
    <TabsMUI onChange={onChange} value={value} {...rest}>
      {children}
    </TabsMUI>
  );
};
