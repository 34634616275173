import { IExperience } from '../../../services';

export enum ShopperExperiencesActionTypes {
  GetShopperExperiencesRequest = 'ShopperExperiences/GetShopperExperiencesRequest',
  GetShopperExperiencesSuccess = 'ShopperExperiences/GetShopperExperiencesSuccess',
  GetShopperExperiencesError = 'ShopperExperiences/GetShopperExperiencesError',
}

export interface GetShopperExperiencesRequestPayload {
  shopId: number;
}

export interface GetShopperExperiencesSuccessPayload {
  shopId: number;
  experiences: IExperience[];
}

export interface GetShopperExperiencesErrorPayload {
  error: unknown;
}

export interface GetShopperExperiencesRequestAction {
  type: typeof ShopperExperiencesActionTypes.GetShopperExperiencesRequest;
  payload: GetShopperExperiencesRequestPayload;
}

export interface GetShopperExperiencesSuccessAction {
  type: typeof ShopperExperiencesActionTypes.GetShopperExperiencesSuccess;
  payload: GetShopperExperiencesSuccessPayload;
}

export interface GetShopperExperiencesErrorAction {
  type: typeof ShopperExperiencesActionTypes.GetShopperExperiencesError;
  payload: GetShopperExperiencesErrorPayload;
}

export type ShopperExperiencesAction =
  | GetShopperExperiencesRequestAction
  | GetShopperExperiencesSuccessAction
  | GetShopperExperiencesErrorAction;
