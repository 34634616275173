import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UserTypes } from '../../services';
import { rootContainerActions } from '../Actions';

interface UseAuthorizeUserArguments {
  user: UserTypes.User | undefined;
}

export const useAuthorizeUser = ({ user }: UseAuthorizeUserArguments): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      dispatch(rootContainerActions.me());
    }
  }, [user]);
};
