/* eslint-disable react/no-unescaped-entities */
import { css } from '@emotion/react';
import React, { useMemo } from 'react';
import { Box, TimePicker, TimeZoneDropdown } from 'src/components-dummy';
import {
  CatalogFormDraft,
  DEFAULT_DAILY_TIME,
  ICatalogFormScheduling,
} from '../../CatalogForm.config';

const MINUTES_STEP = 5;
const UTC_TZ = 'Etc/UTC';

export interface CatalogFormDailyTimeScheduleProps {
  onFieldChange: (partialData: Partial<CatalogFormDraft>) => void;
  dailyTime: ICatalogFormScheduling['dailyTime'];
}

export const CatalogFormDailyTimeSchedule = React.memo(
  ({ onFieldChange, dailyTime }: CatalogFormDailyTimeScheduleProps): JSX.Element => {
    const dailyTimeDate: Date | null = useMemo(() => {
      return new Date(0, 0, 0, dailyTime.hour, dailyTime.minutes);
    }, [dailyTime]);

    const onDailyTimeScheduleChange = (selectedDate?: Date | null) => {
      onFieldChange({
        scheduling: {
          dailyTime: {
            hour: (selectedDate || DEFAULT_DAILY_TIME).getHours(),
            minutes: (selectedDate || DEFAULT_DAILY_TIME).getMinutes(),
          },
        },
      });
    };

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <div
          css={css`
            & > div {
              width: 217px;
            }
          `}
        >
          <TimePicker
            label='Schedule'
            isError={false}
            value={dailyTimeDate}
            minutesStep={MINUTES_STEP}
            inputFormat='HH:mm'
            popperPlacement='top-start'
            onChange={onDailyTimeScheduleChange}
          />
        </div>

        <TimeZoneDropdown
          label='Timezone'
          value={UTC_TZ}
          disabled
          css={css`
            width: 217px;
          `}
        />
      </Box>
    );
  }
);
