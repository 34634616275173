import React, { useState } from 'react';
import classNames from 'classnames';
import { UserTypes } from 'src/services';
import {
  CardItem,
  Icon,
  AvailableIcons,
  Button,
  Typography,
  TypographyType,
  TypographyVariant,
  ConfirmationDialog,
} from 'src/components-dummy';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';
import { format } from 'date-fns';
import { UserCardProps } from './types';
import { LAST_LOGIN_DATE_FORMAT } from './constants';
import './UserCard.scss';
import { UserProfileThumbnail } from '../UserProfileThumbnail';

export const UserCard = ({
  user,
  className,
  onDelete,
  loggedInUser,
  isSelected,
}: UserCardProps): JSX.Element => {
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);

  const onDeleteIconClick = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    setShowDeleteUserDialog(true);
  };

  const onDialogClose = () => {
    setShowDeleteUserDialog(false);
  };

  const deleteUserClick = (userToDelete: UserTypes.User) => {
    onDelete(userToDelete);
    setShowDeleteUserDialog(false);
  };

  const showDeleteIcon =
    loggedInUser && loggedInUser.userId !== user.userId && loggedInUser?.role <= user.role;

  return (
    <CardItem className={classNames('syte-user-card', className)} selected={isSelected} clickable>
      <div className='syte-user-card-content'>
        <UserProfileThumbnail displayName={user.email} online={user.online} />

        <div className='syte-user-card-content-info'>
          <div>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
              {`${user.firstName} ${user.lastName}`}
            </Typography>
          </div>
          <div>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
              {user.email}
            </Typography>
          </div>
        </div>
        <div className='syte-user-card-content-delete'>
          {showDeleteIcon && <Icon name={AvailableIcons.Delete} onClick={onDeleteIconClick} />}

          {showDeleteUserDialog && (
            <ConfirmationDialog onCancel={onDialogClose}>
              <ConfirmationDialog.Header>
                <ConfirmationDialog.Title>Hold on</ConfirmationDialog.Title>{' '}
              </ConfirmationDialog.Header>
              <ConfirmationDialog.Content>
                Are you sure you want to delete this user?
              </ConfirmationDialog.Content>
              <ConfirmationDialog.Footer>
                <ButtonsContainer>
                  <Button variant='primary' onClick={() => deleteUserClick(user)}>
                    Delete
                  </Button>
                  <Button variant='outlined' onClick={onDialogClose}>
                    Cancel
                  </Button>
                </ButtonsContainer>
              </ConfirmationDialog.Footer>
            </ConfirmationDialog>
          )}
        </div>
      </div>
      {user?.lastLoginDate && (
        <div className='syte-user-card-date'>
          <>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              Last Login:
            </Typography>
            <em>
              <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
                {format(user.lastLoginDate, LAST_LOGIN_DATE_FORMAT)}
              </Typography>
            </em>
          </>
        </div>
      )}
    </CardItem>
  );
};
