import React from 'react';
import {
  FormBody,
  FormContainer,
  FormItem,
  FormRadioGroup,
  FormSelect,
  FormSwitch,
  FormTitle,
  StyledContainer,
  Tooltip,
} from '../../components-dummy/SettingsForm';
import { RecEnginesGeneralSettingsType } from '../../services';
import { sizeVariantsTypes } from './types/sizeVariantsTypes';
import { colorVariantsShapes } from './types/colorVariantsShapes';
import {
  SizeVariantsDisplayType,
  AddToCartVariants,
} from '../../app-state-types/reducer-state-types/add-to-cart';
import { useAppSelector } from '../../hooks';

interface AddToCartFormProps {
  generalSettings: RecEnginesGeneralSettingsType.RecEnginesGeneralSettings;
  onChange: (args: Partial<AddToCartVariants>) => void;
  shopId: number;
}

export function AddToCartVariantsForm({
  generalSettings,
  onChange,
  shopId,
}: AddToCartFormProps): JSX.Element | null {
  const addToCartEnabled = useAppSelector(({ shop }) => !!shop.featureToggles?.addToCart?.enabled);
  const { colorVariants, sizeVariants } = generalSettings.addToCart;

  if (!addToCartEnabled) return null;
  const path = `/shops/${shopId}/shopper-experiences`;
  const tip = (
    <Tooltip.Text>
      To enable Add to cart per product, navigate to the Recommendation configuration page from
      the&nbsp;
      <Tooltip.Link path={path}>Shopper experiences table</Tooltip.Link>
    </Tooltip.Text>
  );
  return (
    <FormContainer>
      <FormTitle tip={tip}>Add to cart UI</FormTitle>
      <FormBody divider>
        <FormItem column>
          <FormSwitch
            checked={colorVariants.active}
            onChange={active =>
              onChange({ colorVariants: { ...colorVariants, active }, sizeVariants })
            }
            label='Show color variants on results'
          />
          {colorVariants.active && (
            <StyledContainer nested>
              <FormRadioGroup
                label='Display:'
                name='color-variants-radio-group'
                selectedValue={colorVariants.displayShape}
                onChange={displayShape =>
                  onChange({ colorVariants: { ...colorVariants, displayShape }, sizeVariants })
                }
                options={colorVariantsShapes}
              />
            </StyledContainer>
          )}
        </FormItem>
        <FormItem separator column>
          <FormSwitch
            label='Add size options per color'
            checked={sizeVariants.active}
            onChange={active =>
              onChange({ sizeVariants: { ...sizeVariants, active }, colorVariants })
            }
          />
          {sizeVariants.active && (
            <StyledContainer nested>
              <FormSelect
                label='Display:'
                items={sizeVariantsTypes}
                value={sizeVariants.displayType}
                onChange={displayType =>
                  onChange({
                    sizeVariants: {
                      ...sizeVariants,
                      displayType: displayType as SizeVariantsDisplayType,
                    },
                    colorVariants,
                  })
                }
              />
            </StyledContainer>
          )}
        </FormItem>
      </FormBody>
    </FormContainer>
  );
}
