/* eslint-disable react/no-unescaped-entities */
import { css } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { AvailableIcons, TypographyType, TypographyVariant, Box } from 'src/components-dummy';
import { ShopCatalogVertical } from 'src/services/src/service/types/catalog-manager';
import { CatalogFormSection } from '../CatalogFormSection/CatalogFormSection';
import {
  ErrorLabelStyled,
  ErrorsMessagesWrapperStyled,
  SubTitleStyled,
} from '../../CatalogForm.styles';
import { CatalogFormDraft } from '../../CatalogForm.config';
import { CatalogFormVerticalItem } from './CatalogFormVerticalItem';

export interface CatalogFormVerticalsSectionProps {
  onFieldChange: (partialData: Pick<CatalogFormDraft, 'verticals'>) => void;
  errors?: any;
  verticals: ShopCatalogVertical[];
}

export const CatalogFormVerticalsSection = React.memo(
  ({ verticals, onFieldChange, errors }: CatalogFormVerticalsSectionProps): JSX.Element => {
    const [verticalsState, setVerticalsState] = useState(new Set(verticals));
    const nameErrorMessage = errors?.message;

    const onVerticalsSelect = useCallback(
      (verticalType: ShopCatalogVertical) => () => {
        const newVerticalsState = new Set(verticalsState);

        if (newVerticalsState.has(verticalType) && newVerticalsState) {
          newVerticalsState.delete(verticalType);
        } else {
          newVerticalsState.add(verticalType);
        }

        setVerticalsState(newVerticalsState);
        onFieldChange({ verticals: [...newVerticalsState] });
      },
      [onFieldChange, verticalsState]
    );

    useEffect(() => {
      setVerticalsState(new Set(verticals));
    }, [verticals]);

    return (
      <CatalogFormSection>
        <CatalogFormSection.Header>
          What type of products are you selling in this catalogue?
        </CatalogFormSection.Header>

        {nameErrorMessage ? (
          <ErrorsMessagesWrapperStyled>
            <ErrorLabelStyled> {nameErrorMessage} </ErrorLabelStyled>
          </ErrorsMessagesWrapperStyled>
        ) : null}

        <SubTitleStyled
          css={css`
            margin-bottom: 6px;
          `}
          type={TypographyType.Body}
          variant={TypographyVariant.MediumRegular}
        >
          Syte applies proprietary AI models catered to your product type to optimize accuracy
          results
        </SubTitleStyled>

        <Box sx={{ display: 'flex', marginTop: '48px', gap: 2 }}>
          <CatalogFormVerticalItem
            key={ShopCatalogVertical.Fashion}
            title='Apparel & Jewelry'
            description='From dresses to ski jackets necklaces to charms - anything you can wear.'
            iconName={AvailableIcons.TShirt}
            isChecked={verticalsState.has(ShopCatalogVertical.Fashion)}
            onClick={onVerticalsSelect(ShopCatalogVertical.Fashion)}
          />

          <CatalogFormVerticalItem
            key={ShopCatalogVertical.Home}
            title='Home Decor'
            description='Sofas, outdoor tables, ceiling lamps, bathroom pieces - all for the home'
            iconName={AvailableIcons.HomeDecor}
            isChecked={verticalsState.has(ShopCatalogVertical.Home)}
            onClick={onVerticalsSelect(ShopCatalogVertical.Home)}
          />

          <CatalogFormVerticalItem
            key={ShopCatalogVertical.DIY}
            title='Carpentry & DIY'
            description='Drills, nuts, bolts, plumbing, power, tools and more'
            iconName={AvailableIcons.carpentryAndDIY}
            isChecked={verticalsState.has(ShopCatalogVertical.DIY)}
            onClick={onVerticalsSelect(ShopCatalogVertical.DIY)}
          />

          <CatalogFormVerticalItem
            key={ShopCatalogVertical.General}
            title='Other'
            description='Electronics, beauty products, fabrics, tiles, consumer products'
            isChecked={verticalsState.has(ShopCatalogVertical.General)}
            onClick={onVerticalsSelect(ShopCatalogVertical.General)}
          />
        </Box>
      </CatalogFormSection>
    );
  }
);
