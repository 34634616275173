import {
  CollectionActionBody,
  CollectionActionParams,
} from '../../../../services/src/service/collections';
import { Collection } from '../../types';

export enum CollectionActionTypes {
  DeleteCollectionRequest = 'CollectionList/DeleteCollectionRequest',
  DeleteCollectionSuccess = 'CollectionList/DeleteCollectionSuccess',
  DeleteCollectionError = 'CollectionList/DeleteCollectionError',
}

export interface CollectionActionPayload {
  params: CollectionActionParams;
  body?: CollectionActionBody;
  collections?: Collection[];
  variantId?: string;
}

export interface CollectionDeleteSuccessActionPayload {
  collections: Collection[];
}

export interface CollectionErrorActionPayload {
  error: unknown;
}

export interface CollectionDeleteRequestAction {
  type: typeof CollectionActionTypes.DeleteCollectionRequest;
  payload: CollectionActionPayload;
}

export interface CollectionDeleteSuccessAction {
  type: typeof CollectionActionTypes.DeleteCollectionSuccess;
  payload: CollectionDeleteSuccessActionPayload;
}

export interface CollectionDeleteErrorAction {
  type: typeof CollectionActionTypes.DeleteCollectionError;
  payload: CollectionErrorActionPayload;
}

export type CollectionAction =
  | CollectionDeleteRequestAction
  | CollectionDeleteSuccessAction
  | CollectionDeleteErrorAction;
