import React from 'react';
import { ErrorBoundaryProps, ErrorBoundaryState } from './types';
import { errorBoundaryActions } from './Actions';

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    const { dispatch } = this.props;
    dispatch(errorBoundaryActions.uncaughtError({ error, errorInfo }));
  }

  render(): JSX.Element | React.ReactNode {
    const { children, errorChildren } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <>{errorChildren}</>;
    }

    return <>{children}</>;
  }
}
