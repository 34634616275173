import React from 'react';
import { Button, Page, TypographyType, TypographyVariant } from '../../../../components-dummy';
import { ExternalLink } from '../ExternalLink';
import { FieldDropdown } from './FieldDropdown';
import { OperatorDropdown } from './OperatorDropdown';
import {
  ButtonsContainer,
  ConditionRowStyled,
  ConditionsGroupContent,
  ConditionsGroupStyled,
  ConditionsGroupSubTitle,
  ConditionsGroupTitle,
  ConditionText,
  PageTitleWrapper,
} from './EditCategoryPage.styles';
import { CategoryFilterRule } from '../../types/category-filter-rule';

interface EditCategoryPageProps {
  rule?: CategoryFilterRule;
  onChange: (rule: CategoryFilterRule) => void;
  onSave: VoidFunction;
  onDiscard: VoidFunction;
  isDirty: boolean;
}

export const EditCategoryPageContainer = ({
  rule,
  onChange,
  onSave,
  onDiscard,
  isDirty,
}: EditCategoryPageProps): JSX.Element => {
  return (
    <Page layout='default'>
      <Page.Header>
        <PageTitleWrapper>
          <Page.Title variant={TypographyVariant.SmallBold}>
            Edit Category Page Identifier
          </Page.Title>
          <ExternalLink
            showIcon
            onClick={() => {
              window.open(
                'https://support.syte.ai/space/ET/2814115841/Category+Pages+(Filtered+Collections)#What-is-a-category-page-identifier?',
                '_blank'
              );
            }}
          >
            What is a category page identifier?
          </ExternalLink>
        </PageTitleWrapper>
      </Page.Header>
      <Page.Content>
        <ConditionsGroupStyled>
          <ConditionsGroupTitle type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
            Category Page Facet
          </ConditionsGroupTitle>
          <ConditionsGroupSubTitle
            type={TypographyType.Body}
            variant={TypographyVariant.SmallRegular}
          >
            Define filter criteria for the shop&apos;s category page displays.
          </ConditionsGroupSubTitle>
          <ConditionsGroupContent>
            <ConditionRowStyled>
              <ConditionText type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                Filter by
              </ConditionText>
              <FieldDropdown data={rule} onChange={onChange} />
              <OperatorDropdown data={rule} onChange={onChange} />
            </ConditionRowStyled>
          </ConditionsGroupContent>
        </ConditionsGroupStyled>
        <ButtonsContainer>
          <Button variant='tertiary' onClick={onDiscard}>
            Cancel
          </Button>
          <Button variant='primary' onClick={onSave} disabled={!isDirty}>
            Save
          </Button>
        </ButtonsContainer>
      </Page.Content>
    </Page>
  );
};
