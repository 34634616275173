export enum AppHeaderActionTypes {
  NavigateTo = 'AppHeader/NavigateTo',
}

export interface AppHeaderNavigateToActionPayload {
  navigateTo: string;
}

export interface AppHeaderNavigateToAction {
  type: typeof AppHeaderActionTypes.NavigateTo;
  payload: AppHeaderNavigateToActionPayload;
}

export type AppHeaderAction = AppHeaderNavigateToAction;
