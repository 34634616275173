import React from 'react';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { MerchandisingRuleTypes } from 'src/services';
import { Dispatch } from 'src/components-bl';
import { UseDataFieldsLookupTableArgumentsReturnType } from '../../../useDataFieldsLookupTable';
import { RuleDraft } from '../../MerchandisingRuleForm.config';
import { RuleFormSection } from '../RuleFormSection/RuleFormSection';
import { ActionAndConditionsIncludeGroup } from './ActionAndConditionsIncludeGroup/ActionAndConditionsIncludeGroup';
import { ConditionsApplyWhenGroup } from './ConditionsApplyWhenGroup/ConditionsApplyWhenGroup';
import { ConditionRowType, ConditionTypesOptions } from '../../../types';
import { ConditionSearchTermsGroup } from './ConditionSearchTermsGroup/ConditionSearchTermsGroup';
import { ConditionsFilterByGroup } from './ConditionsFilterByGroup/ConditionsFilterByGroup';
import { CategoryFilterRule } from '../../../../../VisualEditor/types/category-filter-rule';

export type RuleFormConditionsSectionFields = Pick<
  RuleDraft,
  | 'searchCondition'
  | 'subRules'
  | 'sourceCondition'
  | 'filterByCondition'
  | 'redirectRule'
  | 'action'
  | 'weight'
>;
export interface RuleFormConditionsSectionProps
  extends RuleFormConditionsSectionFields,
    UseDataFieldsLookupTableArgumentsReturnType {
  subRulesConditionTypes: MerchandisingRuleTypes.MerchandisingRuleSubType[];
  conditionTypesOptions: ConditionTypesOptions;
  actions: MerchandisingRuleTypes.RuleAction[];
  conditionTypes: ConditionRowType[] | null | undefined;
  shopId: number;
  errors: any; // TODO
  isReadOnly?: boolean;
  isAiTagsEnabled: boolean;
  onFieldChange: (partialData: Partial<RuleFormConditionsSectionFields>) => void;
  dispatch: Dispatch;
  entityId?: string;
  isCategoryPage: boolean;
  categoryFilterRule?: CategoryFilterRule;
}

export const RuleFormConditionsSection = React.memo(
  ({
    shopId,
    action,
    weight,
    searchCondition,
    sourceCondition,
    filterByCondition,
    subRules,
    redirectRule,
    subRulesConditionTypes,
    conditionTypesOptions,
    conditionTypes,
    dataFieldsLookupTable,
    errors,
    isReadOnly,
    isAiTagsEnabled,
    getDataFieldByName,
    onFieldChange,
    dispatch,
    actions,
    entityId,
    isCategoryPage,
    categoryFilterRule,
  }: RuleFormConditionsSectionProps): JSX.Element => {
    // TODO search condition / augmented search

    const isRedirectRule = action === MerchandisingRuleTypes.RuleAction.Redirect;

    const showAddButton = !isRedirectRule;
    return (
      <RuleFormSection>
        <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
          Action and conditions
        </Typography>

        {conditionTypes?.includes(ConditionRowType.FilterByCondition) && !entityId && (
          <ConditionsFilterByGroup
            conditions={filterByCondition}
            dataFieldsLookupTable={dataFieldsLookupTable}
            getDataFieldByName={getDataFieldByName}
            onFieldChange={onFieldChange}
            errors={errors} // TODO
            dispatch={dispatch}
            shopId={shopId}
            isReadOnly={isReadOnly}
            categoryFilterRule={categoryFilterRule}
          />
        )}

        <ActionAndConditionsIncludeGroup
          action={action}
          weight={weight}
          shopId={shopId}
          conditions={subRules}
          dataFieldsLookupTable={dataFieldsLookupTable}
          getDataFieldByName={getDataFieldByName}
          conditionTypes={subRulesConditionTypes}
          showAddButton={showAddButton}
          onFieldChange={onFieldChange}
          dispatch={dispatch}
          errors={errors} // TODO
          isReadOnly={isReadOnly}
          actions={actions}
          redirectRule={redirectRule}
          isAiTagsEnabled={isAiTagsEnabled}
        />

        {conditionTypes?.includes(ConditionRowType.ApplyWhenCondition) && !isCategoryPage && (
          <ConditionsApplyWhenGroup
            shopId={shopId}
            conditions={sourceCondition}
            dataFieldsLookupTable={dataFieldsLookupTable}
            getDataFieldByName={getDataFieldByName}
            conditionTypesOptions={conditionTypesOptions[ConditionRowType.ApplyWhenCondition] || []}
            onFieldChange={onFieldChange}
            dispatch={dispatch}
            errors={errors} // TODO
            isReadOnly={isReadOnly}
            isAiTagsEnabled={isAiTagsEnabled}
          />
        )}

        {conditionTypes?.includes(ConditionRowType.SearchCondition) && (
          <ConditionSearchTermsGroup
            searchCondition={searchCondition}
            shopId={shopId}
            errors={errors}
            onFieldChange={onFieldChange}
            isReadOnly={isReadOnly}
            isRedirectModeEnabled={isRedirectRule}
          />
        )}
      </RuleFormSection>
    );
  }
);
