import styled from '@emotion/styled';
import { DialogModal, DialogModalContent, DialogModalFooter } from 'src/components-dummy';
import { LinkButton } from 'src/components-dummy/LinkButton';

export const ImportLexiconModalStyled = styled(DialogModal)`
  .MuiPaper-root {
    width: 500px;
    min-width: 500px;
    height: 375px;
  }
`;

export const DialogModalContentStyled = styled(DialogModalContent)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const DownloadExampleLinkStyled = styled(LinkButton)`
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: fit-content;

  svg {
    margin-right: 5.5px;
    path {
      stroke: ${({ theme }) => {
        return theme.palette.custom['primary-main'];
      }} !important;
    }
  }
`;

export const DialogModalFooterStyled = styled(DialogModalFooter)`
  gap: 3px;
`;
