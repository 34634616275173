import styled from '@emotion/styled';
import { InitialLetter } from 'src/components-dummy';

export const UserProfileThumbnailStyled = styled.div<{ size: number }>`
  position: relative;
  width: ${props => props.size}px;
  min-width: ${props => props.size}px;
  height: ${props => props.size}px;
  user-select: none;
`;

export const InitialLetterStyled = styled(InitialLetter)<{
  size: number;
  shouldHighlight: boolean;
}>`
  border-radius: 50%;
  width: ${props => props.size}px !important;
  min-width: ${props => props.size}px !important;
  height: ${props => props.size}px !important;
  line-height: ${props => props.size}px !important;

  font-size: 80%;

  outline: ${({ theme, shouldHighlight }) =>
    shouldHighlight ? `2px solid ${theme.palette.common.white}` : 'none'};

  box-shadow: ${({ theme, shouldHighlight }) =>
    shouldHighlight ? `0 0 0 4px ${theme.palette.custom['primary-main']}` : 'none'};
`;

export const OnlineStatusIndication = styled.div<{ show: boolean }>`
  width: 7px;
  height: 7px;
  background-color: ${({ show, theme }) =>
    show ? theme.palette.custom['green-success'] : theme.palette.custom['gray-60']};
  transition:
    background-color,
    opacity 1s;
  border-radius: 50%;
  border: ${({ theme, show }) => (show ? 'none' : `1px solid ${theme.palette.custom['gray-70']}`)};
  opacity: 1;
  bottom: 1px;
  right: 2px;
  position: absolute;
`;

export const ProfileOnlineStatusIndication = styled(OnlineStatusIndication)`
  box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.custom.white};
  width: 14px;
  height: 14px;
  right: -5px;
  bottom: 2px;
`;

export const ProfileOfflineStatusIndication = styled(ProfileOnlineStatusIndication)`
  background-color: ${({ theme }) => theme.palette.custom.red};
`;
