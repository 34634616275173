import { useCallback, useMemo } from 'react';
import { UseRulesDropdownProps, UseRulesDropdownReturnType } from './types';
import { Operator } from '../../../types/operator';
import { MerchandisingRuleSubType } from '../../../../../services/src/service/types/shops';

export const useOperatorDropdown = ({
  data,
  onChange,
}: UseRulesDropdownProps): UseRulesDropdownReturnType => {
  const conditionDropDownValues = useMemo(() => {
    return [
      {
        value: MerchandisingRuleSubType.Equals,
        text: 'Exactly matches',
      },
      {
        value: MerchandisingRuleSubType.Contains,
        text: 'Contains either',
      },
    ];
  }, []);

  const onOperatorChange = useCallback(
    (selectedOperator?: Operator) => {
      onChange({ ...data, operator: selectedOperator });
    },
    [onChange]
  );

  return {
    conditionDropDownValues,
    onOperatorChange,
  };
};
