import React from 'react';
import { Row } from 'react-table';
import { TableV2 } from 'src/components-dummy';
import { DragHandleCell } from 'src/components-bl/Filters/components/FiltersListPage/components/FiltersList/DragHandleCell';
import { CustomOrderValue } from 'src/components-bl/Filters/components/FilterForm/types';
import { tableColumns } from '../../tableColumns';
import { IValuesTableBodyRow } from '../../types';
import { TableRowCellStyled } from './styles';
import { ValueCell, DeleteCell } from './components';

interface ValueTableRowProps {
  row: Row<IValuesTableBodyRow>;
  onDeleteRow: (id: string) => void;
  onAddNewValues: (newValues: CustomOrderValue[]) => void;
  shopId: number;
  onRowDrag: (sourceIndex: number, targetIndex: number) => void;
  onRowDrop: () => void;
  selectedValues: string[];
}

export const ValueTableRow = ({
  row,
  onDeleteRow,
  onAddNewValues,
  shopId,
  onRowDrag,
  onRowDrop,
  selectedValues,
}: ValueTableRowProps): JSX.Element => {
  const rowHasValue = !!row.original.value;

  const onDeleteClick = () => onDeleteRow(row.original.id);

  const renderCell = ({ propName }: { propName: keyof IValuesTableBodyRow }) => {
    if (propName === tableColumns.positionHandle.accessor) {
      return rowHasValue ? (
        <DragHandleCell disabled={false} isUsingInvalidDataField={false} />
      ) : null;
    }
    if (propName === tableColumns.deleteButton.accessor) {
      return rowHasValue ? <DeleteCell onClick={onDeleteClick} /> : null;
    }

    return (
      <ValueCell
        row={row.original}
        onAddNewValues={onAddNewValues}
        shopId={shopId}
        selectedValues={selectedValues}
      />
    );
  };

  return (
    <TableV2.DraggableBodyRow
      {...row.getRowProps()}
      key={row.original.id}
      index={row.index}
      onRowDrag={onRowDrag}
      onRowDrop={onRowDrop}
      disableDrag={!rowHasValue}
    >
      {row.cells.map(cell => {
        const propName = cell.column.id;

        return (
          <TableRowCellStyled
            {...cell.getCellProps()}
            key={propName}
            width={tableColumns[propName].width}
            minWidth={cell.column.minWidth}
            data-id={`${row.original.sourceField}-${propName}`}
          >
            {renderCell({ propName: propName as keyof IValuesTableBodyRow })}
          </TableRowCellStyled>
        );
      })}
    </TableV2.DraggableBodyRow>
  );
};
