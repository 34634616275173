import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useIsAnimating } from 'src/hooks';
import { useFindComponentByType } from '../hooks';
import './Collapse.scss';
import { CollapseContent, CollapseHeader, CollapseTrigger } from './components';
import { CollapseProps } from './types';
import { findComponentsExceptOfType } from '../helpers';

/** Note: Collapse complexity: when it needs to fit content (not static height):
 *  CSS cannot animate/transition with `height: fit-content`
 *  Using large max-height instead makes the animation too fast, as it works with the large max-height given
 *  The solution is to use scrollHeight.
 *  To get actual scrollHeight, need to wait for content to render in DOM (wait for ref)
 */
export const Collapse = ({
  children,
  expanded,
  className,
  disabled,
  observeHeight,
  onExpandChange,
}: CollapseProps): JSX.Element => {
  const expandedInput = expanded ?? false;
  const [isExpanded, setIsExpanded] = useState(expandedInput ?? false);
  const [shouldRenderContent, setShouldRenderContent] = useState(isExpanded);
  const [contentHeight, setContentHeight] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);

  const header = useFindComponentByType({ type: CollapseHeader, children });
  const content = useFindComponentByType({ type: CollapseContent, children });
  const trigger = useFindComponentByType({
    type: CollapseTrigger,
    children: header?.props?.children,
  });

  const hasCustomTrigger = !!trigger;

  const changeExpand = () => {
    if (!disabled) {
      const newValue = !isExpanded;
      if (onExpandChange) {
        onExpandChange(newValue);
      } else {
        setIsExpanded(newValue);
      }
    }
  };

  const onTriggerClick = hasCustomTrigger ? changeExpand : undefined;
  const onHeaderClick = hasCustomTrigger ? undefined : changeExpand;

  const resizeObserver = useRef(
    observeHeight
      ? new ResizeObserver(() => {
          const height = contentRef.current?.scrollHeight || 0;
          setContentHeight(height);
        })
      : null
  );

  useEffect(() => {
    setIsExpanded(expandedInput);

    if (resizeObserver.current && contentRef.current) {
      // always clear all observers for any case.
      resizeObserver.current.disconnect();

      // observe the panel child, as panel height stays the same due to overflow
      if (expanded) {
        resizeObserver.current.observe(contentRef.current.children[0]);
      }
    }
  }, [expanded]);

  const headerChildren = findComponentsExceptOfType({
    children: header?.props.children,
    type: CollapseTrigger,
  });

  useIsAnimating({
    ref: contentRef,
    onAnimationEnd: () => {
      if (!isExpanded) {
        setShouldRenderContent(false);
      }
    },
  });

  useEffect(() => {
    const height = (isExpanded && shouldRenderContent && contentRef.current?.scrollHeight) || 0;
    setContentHeight(height);
  }, [shouldRenderContent, isExpanded, contentRef.current?.scrollHeight, content]);

  useEffect(() => {
    return () => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
        resizeObserver.current = null;
      }
    };
  }, []);

  return (
    <div
      className={classNames('syte-collapse', { 'syte-collapse-collapsed': !isExpanded }, className)}
    >
      <div
        className={classNames(
          'syte-collapse-header',
          { 'syte-collapse-trigger': !hasCustomTrigger, disabled },
          header?.props?.className
        )}
        aria-expanded={isExpanded}
        onClick={onHeaderClick}
      >
        {headerChildren}
        {trigger && (
          <div
            className={classNames('syte-collapse-trigger', trigger.props?.className)}
            onClick={ev => {
              trigger.props.onClick?.(ev);
              onTriggerClick?.();
            }}
          >
            {trigger.props?.children}
          </div>
        )}
      </div>
      <div
        className={classNames('syte-collapse-content', content?.props?.className)}
        ref={contentRef}
        role='region'
        tabIndex={-1}
        style={{ maxHeight: contentHeight }}
      >
        <div
          className='syte-collapse-content-inner-wrapper'
          ref={() => {
            if (isExpanded) {
              setShouldRenderContent(true);
            }
          }}
        >
          {shouldRenderContent && content?.props?.children}
        </div>
      </div>
    </div>
  );
};

Collapse.Header = CollapseHeader;
Collapse.Content = CollapseContent;
