import React, { useCallback, useState } from 'react';
import { v4 } from 'uuid';
import { CustomOrderValue } from 'src/components-bl/Filters/components/FilterForm/types';
import { DataFieldsValuesList } from 'src/components-bl/DataFields';
import { PopUp } from 'src/components-dummy';
import { EllipsisWithTooltipStyled, ValueCellStyled } from '../../styles';
import { IValuesTableBodyRow } from '../../../../types';

interface ValueCellProps {
  row: IValuesTableBodyRow;
  onAddNewValues: (newValues: CustomOrderValue[]) => void;
  shopId: number;
  selectedValues: string[];
}

export const ValueCell = ({
  row: { value, sourceField, id },
  onAddNewValues,
  shopId,
  selectedValues,
}: ValueCellProps): JSX.Element => {
  const [showPopUp, setShowPopUp] = useState(false);

  const closePopUp = useCallback(() => {
    setShowPopUp(false);
  }, [setShowPopUp]);

  const onValuesChange = useCallback(
    (newValues: string[]) => {
      const newValuesMapped = newValues.map(v => ({ value: v, id: v4() }));
      onAddNewValues(newValuesMapped);
      closePopUp();
    },
    [id, onAddNewValues, closePopUp]
  );

  const cellContent = (
    <EllipsisWithTooltipStyled shouldBeGrayedOut={value === undefined} tooltipText={value || ''}>
      {value || '+ Select value'}
    </EllipsisWithTooltipStyled>
  );

  return (
    <ValueCellStyled>
      {!value ? (
        <PopUp show={showPopUp} onShow={setShowPopUp} hideOnScroll={false} position='bottom left'>
          <PopUp.Trigger>{cellContent}</PopUp.Trigger>
          <PopUp.Content>
            <DataFieldsValuesList
              dataField={sourceField}
              onSelect={onValuesChange}
              shopId={shopId}
              onCancel={closePopUp}
              selectedValues={selectedValues}
              isMultiSelect
            />
          </PopUp.Content>
        </PopUp>
      ) : (
        cellContent
      )}
    </ValueCellStyled>
  );
};
