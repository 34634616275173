export enum UiTemplatesShopUrlActionTypes {
  SetShopUrl = 'UiTemplates/SetShopUrl',
}

export interface UiTemplatesShopUrlActionPayload {
  shopUrl?: string;
}

export interface UiTemplatesSetShopUrlAction {
  type: typeof UiTemplatesShopUrlActionTypes.SetShopUrl;
  payload: UiTemplatesShopUrlActionPayload;
}

export type UiTemplatesShopUrlAction = UiTemplatesSetShopUrlAction;
