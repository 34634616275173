import styled from '@emotion/styled';
import { FileUploadButton } from 'src/components-dummy/FileUploader';

export const FileUploadButtonStyled = styled(FileUploadButton)`
  button {
    height: 48px;
    width: 223px;
  }

  .syte-file-upload-card {
    max-width: 275px;
  }
`;

export const ButtonContentStyled = styled.span`
  display: flex;
  gap: 10px;

  svg {
    path {
      stroke: ${({ theme }) => theme.palette.common.white};
    }
  }
`;
