import styled from '@emotion/styled';
import { Typography } from '../Typography';

export const BreadcrumbsContainerStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const BreadcrumbLinkStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  user-select: none;
  text-decoration: underline;
`;

export const BreadcrumbCurrentStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['black-10']};
`;

export const BreadcrumbsSeparatorStyled = styled(Typography)`
  user-select: none;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  margin-left: 0.2em;
  margin-right: 0.2em;
`;
