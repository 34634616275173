export function arrayToDotNotationPath(array: Array<string | number>): string {
  const res = array.reduce((result, current) => {
    const stringValue = current.toString().trim();

    if (stringValue.length) {
      if ((result as string).length) {
        return `${result}.${current}` as string;
      }
      return stringValue;
    }

    return result;
  }, '');

  return res as string;
}
