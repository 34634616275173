import { IValuesTableHeadRow } from './types';

export const tableColumns: Record<string, IValuesTableHeadRow> = {
  positionHandle: {
    Header: '',
    accessor: 'positionHandle',
    width: '10%',
  },
  value: {
    Header: 'Filter value',
    accessor: 'value',
    minWidth: 115,
    width: '80%',
  },
  deleteButton: {
    Header: '',
    accessor: 'deleteButton',
    width: '10%',
  },
};

export const columnsArray = Object.values(tableColumns);
