import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ImageTag } from 'src/services';
import { ImageSize } from 'src/components-dummy';
import { PopUpWithOffset } from '../PopUpWithOffset';
import {
  ProductPin,
  ProductPinInnerCircle,
  ProductPinOuterCircle,
  pinSize,
} from './ProductTagPopUp.styles';
import { calculatePosition } from '../utils';
import { ProductCard, SkuConfiguration } from 'src/components-bl/ProductCard/ProductCard';

export type TagWithAppliedSuggestion = ImageTag & { isApplied: boolean };

export interface ProductTagPopUpProps {
  tag: TagWithAppliedSuggestion;
  onRemove: () => void;
  imageSize: ImageSize | null;
  enableDelete: boolean;
  showPopUp: boolean;
  loading: boolean;
  productInformationLookup: Record<string, SkuConfiguration>;
}

export const ProductTagPopUp = ({
  tag,
  imageSize,
  enableDelete,
  onRemove,
  showPopUp,
  productInformationLookup,
  loading,
}: ProductTagPopUpProps): JSX.Element | null => {
  const skuConfiguration = useMemo(
    () => productInformationLookup[tag.sku],
    [productInformationLookup, tag]
  );

  const [isProductError, setIsProductError] = useState(!skuConfiguration && !loading);

  useEffect(() => {
    if (!loading) {
      setIsProductError(!skuConfiguration);
    }
  }, [skuConfiguration, loading]);

  const [showPopup, setShowPopup] = useState(showPopUp);

  const coordinates = useMemo(() => {
    if (!imageSize) return null;

    const offsetX = imageSize.offsetLeft;
    const offsetY = imageSize.offsetTop;

    const imageRenderedHeight = imageSize.height;
    const imageRenderedWidth = imageSize.width;

    const x = (imageRenderedWidth / 100) * tag.coordinates.x - pinSize / 2;
    const y = (imageRenderedHeight / 100) * tag.coordinates.y - pinSize / 2;

    const maxPossibleXValue = imageRenderedWidth + offsetX - pinSize / 2;
    const minPossibleXValue = offsetX;

    const maxPossibleYValue = imageRenderedHeight + offsetY - pinSize / 2;
    const minPossibleYValue = offsetY;

    const result = {
      x: calculatePosition({
        position: x + offsetX,
        min: minPossibleXValue,
        max: maxPossibleXValue,
      }),
      y: calculatePosition({
        position: y + offsetY,
        min: minPossibleYValue,
        max: maxPossibleYValue,
      }),
    };

    return result;
  }, [imageSize, tag.coordinates]);

  const onPinClick = useCallback(() => {
    setShowPopup(true);
  }, [setShowPopup]);

  useEffect(() => {
    setShowPopup(showPopUp);
  }, [showPopUp]);

  return coordinates ? (
    <PopUpWithOffset
      triggerWidth={pinSize}
      triggerHeight={pinSize}
      offsetTop={coordinates.y}
      offsetLeft={coordinates.x}
      show={showPopup}
      onPopupShow={setShowPopup}
      closeOnClickOutside={false}
      hoverable
      triggerEvent='hover'
    >
      <PopUpWithOffset.Trigger>
        <ProductPin onClick={onPinClick}>
          <ProductPinOuterCircle
            isApplied={tag.isApplied}
            isAIDetected={tag.isAIDetected}
            isError={isProductError}
          />
          <ProductPinInnerCircle
            isApplied={tag.isApplied}
            isAIDetected={tag.isAIDetected}
            isError={isProductError}
          />
        </ProductPin>
      </PopUpWithOffset.Trigger>
      <PopUpWithOffset.Content>
        <ProductCard
          loading={loading}
          skuConfiguration={skuConfiguration}
          isApplied={tag.isApplied}
          isAIDetected={tag.isAIDetected}
          enableDelete={enableDelete}
          onRemove={onRemove}
          isError={isProductError}
        />
      </PopUpWithOffset.Content>
    </PopUpWithOffset>
  ) : null;
};
