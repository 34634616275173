import { DomainEventsWebSocketServiceBase, DomainEvent } from '../domain-events';
import { Notification } from '../types';
import { NotificationsMapper } from './notifications.mapper';
import { NotificationApi } from './types';

export enum NotificationEventTopicNames {
  Create = 'notification:create',
  Viewed = 'notifications:viewed',
  Deleted = 'notifications:deleted',
  UnreadTotalCount = 'notifications:unread-total-count',
}

export interface NotificationIds {
  notificationIds: string[];
}

export interface NotificationsUnreadCount {
  unreadCount: number;
}

export type NotificationCreateEvent = DomainEvent<Notification, NotificationEventTopicNames>;
export type NotificationCreateApiEvent = DomainEvent<NotificationApi, NotificationEventTopicNames>;

export type NotificationDeleteEvent = DomainEvent<NotificationIds, NotificationEventTopicNames>;

export type NotificationViewedEvent = DomainEvent<NotificationIds, NotificationEventTopicNames>;

export type NotificationUnreadTotalCountEvent = DomainEvent<
  NotificationsUnreadCount,
  NotificationEventTopicNames
>;

export class NotificationsWebSocketService extends DomainEventsWebSocketServiceBase<NotificationEventTopicNames> {
  subscribeToNotificationCreateEvents(
    onNotificationCreate: (payload: NotificationCreateEvent) => void
  ): void {
    this.subscribeToEvents({
      topicKey: NotificationEventTopicNames.Create,
      callback: (response: NotificationCreateApiEvent) => {
        const mappedNotification = NotificationsMapper.mapNotification(response.data);

        const result = { ...response, data: mappedNotification };

        onNotificationCreate(result);
      },
    });
  }

  subscribeToNotificationViewEvents(
    onNotificationUpdate: (payload: NotificationViewedEvent) => void
  ): void {
    this.subscribeToEvents({
      topicKey: NotificationEventTopicNames.Viewed,
      callback: onNotificationUpdate,
    });
  }

  subscribeToNotificationDeleteEvents(
    onNotificationDelete: (payload: NotificationDeleteEvent) => void
  ): void {
    this.subscribeToEvents({
      topicKey: NotificationEventTopicNames.Deleted,
      callback: onNotificationDelete,
    });
  }

  subscribeToUnreadCountEvents(
    onViewCount: (payload: NotificationUnreadTotalCountEvent) => void
  ): void {
    this.subscribeToEvents({
      topicKey: NotificationEventTopicNames.UnreadTotalCount,
      callback: onViewCount,
    });
  }
}

export const notificationsWebSocketService = new NotificationsWebSocketService();
