import React, { useState, useEffect, forwardRef } from 'react';
import classNames from 'classnames';
import { OverlayModal } from '../OverlayModal';
import './Modal.scss';

export interface ModalProps {
  children: JSX.Element;
  onClickOutside?: () => void;
  className?: string;
  isOpened?: boolean;
  closeOnClickOutside?: boolean;
}

const rootClassName = 'syte-modal';

export const Modal = forwardRef<HTMLDivElement, ModalProps>(
  (
    { children, className, isOpened = false, onClickOutside, closeOnClickOutside = true },
    ref
  ): JSX.Element => {
    const [show, setShow] = useState<boolean>(isOpened);

    const onModalClose = () => {
      if (closeOnClickOutside) {
        if (onClickOutside) {
          onClickOutside();
        } else {
          setShow(false);
        }
      }
    };

    useEffect(() => {
      setShow(isOpened);
    }, [isOpened]);

    return (
      <OverlayModal show={show} className={classNames(rootClassName, className)}>
        <div onClick={onModalClose} className={`${rootClassName}-backdrop`} />
        <div className={`${rootClassName}-content`} tabIndex={-1} role='dialog' ref={ref}>
          {children}
        </div>
      </OverlayModal>
    );
  }
);
