import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { visualEditorActions } from '../state';
import { SuggestedOffer } from '../types';

export const useEditSelectedOffer = () => {
  const selectedOffer = useAppSelector(({ visualEditor }) => visualEditor.selectedOffer);
  const appDispatch = useAppDispatch();

  const changeOffer = useCallback((value: SuggestedOffer | null) => {
    appDispatch(visualEditorActions.setSelectedOffer(value));
  }, []);

  return {
    selectedOffer,
    changeOffer,
  };
};
