import { FilterSetSettings } from 'src/services/src/service/types/filters';

enum FilterSettingsFormActionTypes {
  Init = 'FilterSettingsForm/InitForm',
  PartialUpdate = 'FilterSettingsForm/PartialUpdate',
}
interface FilterSettingsFormInitAction {
  type: FilterSettingsFormActionTypes.Init;
  payload: FilterSetSettings;
}
interface FilterSettingsPartialUpdateAction {
  type: FilterSettingsFormActionTypes.PartialUpdate;
  payload: Partial<FilterSetSettings>;
}

export type FilterSettingsFormAction =
  | FilterSettingsFormInitAction
  | FilterSettingsPartialUpdateAction;

export const filterSettingsFormActions = {
  formInit: (payload: FilterSetSettings): FilterSettingsFormAction => {
    return { type: FilterSettingsFormActionTypes.Init, payload };
  },
  formUpdate: (payload: Partial<FilterSetSettings>): FilterSettingsFormAction => {
    return { type: FilterSettingsFormActionTypes.PartialUpdate, payload };
  },
};
