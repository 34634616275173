import React, { useRef } from 'react';
import './DeleteConfirmationModal.scss';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { OverlayModal } from '../../../../components-dummy/OverlayModal';
import {
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy/Typography';
import { Button } from '../../../../components-dummy/Button';

export interface DeleteConfirmationModalProps {
  titleText: string;
  modalBodyText: string;
  show: boolean;
  onClose: () => void;
  onDelete: () => void;
  onClickOutside?: () => void;
}
export const DeleteConfirmationModal = ({
  titleText,
  modalBodyText,
  show,
  onClose,
  onDelete,
  onClickOutside,
}: DeleteConfirmationModalProps) => {
  const ref = useRef(null);

  const handleClickOutside = () => {
    onClickOutside?.();
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <OverlayModal show={show} className='syte-dialog'>
      <div className='delete-confirmation-modal' data-testid='delete-confirmation-modal' ref={ref}>
        <div className='modal-header'>
          <Typography type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
            {titleText}
          </Typography>
        </div>
        <div className='modal-body'>{modalBodyText}</div>
        <div className='modal-footer'>
          <Button onClick={onClose} variant='tertiary'>
            Close
          </Button>
          <Button variant='destructive' onClick={onDelete}>
            Delete
          </Button>
        </div>
      </div>
    </OverlayModal>
  );
};
