import { UserRoles } from '../services/src/service/types/users';
import { useAppSelector } from './index';
import { ShopFeature } from '../app-types';
import { ShopFeatureToggleWithPermittedRoles } from '../services/src/service/types/shops';

export const useSetForInternalUseOnly = (feature: ShopFeature): { isInternal: boolean } => {
  const featureToggles = useAppSelector(state => state.shop.featureToggles);

  return {
    isInternal: !(
      featureToggles?.[feature] as ShopFeatureToggleWithPermittedRoles
    )?.permittedRoles?.includes(UserRoles.ClientAdmin),
  };
};
