import React, { useCallback, useMemo } from 'react';
import { ThematicTagConditionOperatorsEnum } from 'src/services';
import { ParseJoiValidateResponse, getStringEnumValues, reduceErrorsByPrefix } from 'src/utils';
import {
  TypographyType,
  TypographyVariant,
  AvailableIcons,
  SelectType,
  SelectOnChangeEvent,
  MenuItem,
} from 'src/components-dummy';
import { Dispatch } from '../../../../../../types';
import {
  IThematicTagConditionsGroupDraft,
  IThematicTagConditionsDraft,
  IThematicTagDraft,
  AvailableAttributeKeysByCategoryMap,
} from '../../ThematicTagForm.types';
import { generateEmptyCondition } from '../../constants';
import {
  ConditionRuleStyled,
  ConditionRulePrefixTypographyStyled,
  LexiconTagSelectStyled,
  ConditionActionIconWrapperStyled,
} from './ConditionGroups.styles';
import { ValuesInputFactory } from './ValuesInputFactory';

const operatorToTranslationMap: Record<ThematicTagConditionOperatorsEnum, string> = {
  [ThematicTagConditionOperatorsEnum.HasAnyValue]: 'Has any value',
  [ThematicTagConditionOperatorsEnum.ValueIs]: 'Is',
  [ThematicTagConditionOperatorsEnum.ValueIsNot]: 'Is not',
  [ThematicTagConditionOperatorsEnum.ValueIsEither]: 'Is either',
  [ThematicTagConditionOperatorsEnum.HasNoValue]: 'Has no value',
};

interface ConditionRuleProps {
  condition: IThematicTagConditionsDraft;
  onChange: ({
    type,
    updatedCondition,
  }: {
    type: 'category' | 'attribute' | 'value' | 'operator';
    updatedCondition: IThematicTagConditionsDraft;
  }) => void;
  onRemove: (id: string) => void;
  onAddNew: () => void;
  index: number;
  errors: ParseJoiValidateResponse<IThematicTagDraft>;
  availableAttributeKeys?: string[];
  availableCategoryKeys: string[];
  isFirstGroup: boolean;
  shopId: number;
  locale: string;
  dispatch: Dispatch;
  isTheOnlyGroup: boolean;
  totalConditionsCountInGroup: number;
}

const ConditionRule = ({
  condition,
  onChange,
  onRemove,
  onAddNew,
  index,
  availableCategoryKeys,
  availableAttributeKeys,
  shopId,
  dispatch,
  locale,
  errors,
  isTheOnlyGroup,
  totalConditionsCountInGroup,
}: ConditionRuleProps): JSX.Element => {
  const ruleErrors = useMemo(
    () => reduceErrorsByPrefix({ errors, prefix: `conditions.${index}` }),
    [errors]
  );

  const categoryErrorMessage = ruleErrors.categoryKey?.message;
  const attributeErrorMessage = ruleErrors.attributeKey?.message;

  const isFirstCondition = index === 0;
  const isTheOnlyCondition =
    isTheOnlyGroup && isFirstCondition && totalConditionsCountInGroup === 1;

  const canBeDeleted = !isTheOnlyCondition;

  const isAttributeInputDisabled = !!categoryErrorMessage || condition.categoryKey.length === 0;
  const isOperatorInputDisabled = !!attributeErrorMessage || condition.attributeKey.length === 0;

  const onTrashcanClick = useCallback(() => {
    onRemove(condition.id);
  }, [onRemove, condition.id]);

  const onCategoryChange: SelectOnChangeEvent = useCallback(
    selectChangeEvent => {
      const updatedCondition = { ...condition, categoryKey: selectChangeEvent.target.value };

      onChange({ type: 'category', updatedCondition });
    },
    [onChange, condition]
  );

  const onAttributeChange: SelectOnChangeEvent = useCallback(
    selectChangeEvent => {
      const updatedCondition: IThematicTagConditionsDraft = {
        ...condition,
        attributeKey: selectChangeEvent.target.value,
        valuesKeys: [],
      };

      onChange({ type: 'attribute', updatedCondition });
    },
    [onChange, condition]
  );

  const onOperatorChange: SelectOnChangeEvent = useCallback(
    selectChangeEvent => {
      const updatedCondition: IThematicTagConditionsDraft = {
        ...condition,
        operator: selectChangeEvent.target.value as ThematicTagConditionOperatorsEnum,
        valuesKeys: [],
      };

      onChange({ type: 'operator', updatedCondition });
    },
    [onChange, condition]
  );

  const onValuesChange = useCallback(
    (newValues: string[]) => {
      const updatedCondition: IThematicTagConditionsDraft = {
        ...condition,
        valuesKeys: newValues,
      };

      onChange({ type: 'value', updatedCondition });
    },
    [onChange, condition]
  );

  return (
    <ConditionRuleStyled>
      <ConditionRulePrefixTypographyStyled
        type={TypographyType.Body}
        variant={TypographyVariant.MediumMedium}
      >
        {isFirstCondition ? 'Where' : 'and'}
      </ConditionRulePrefixTypographyStyled>
      {isFirstCondition && (
        <LexiconTagSelectStyled
          type={SelectType.Menu}
          value={condition.categoryKey}
          onChange={onCategoryChange}
          placeholder='Category'
          errorMessage={categoryErrorMessage}
          isError={!!categoryErrorMessage}
        >
          {availableCategoryKeys.map(categoryKey => (
            <MenuItem key={categoryKey} value={categoryKey}>
              {categoryKey}
            </MenuItem>
          ))}
        </LexiconTagSelectStyled>
      )}
      <LexiconTagSelectStyled
        type={SelectType.Menu}
        value={condition.attributeKey}
        onChange={onAttributeChange}
        placeholder='Attribute'
        disabled={isAttributeInputDisabled}
        errorMessage={attributeErrorMessage}
      >
        {(availableAttributeKeys || []).map(attributeKey => (
          <MenuItem key={attributeKey} value={attributeKey}>
            {attributeKey}
          </MenuItem>
        ))}
      </LexiconTagSelectStyled>
      <LexiconTagSelectStyled
        type={SelectType.Menu}
        value={condition.operator}
        onChange={onOperatorChange}
        placeholder='Select operator'
        errorMessage={ruleErrors.operator?.message}
        disabled={isOperatorInputDisabled}
      >
        {getStringEnumValues(ThematicTagConditionOperatorsEnum).map(operator => (
          <MenuItem key={operator} value={operator}>
            {operatorToTranslationMap[operator]}
          </MenuItem>
        ))}
      </LexiconTagSelectStyled>
      <ValuesInputFactory
        attributeKey={condition.attributeKey}
        categoryKey={condition.categoryKey}
        valuesKeys={condition.valuesKeys}
        shopId={shopId}
        locale={locale}
        errors={ruleErrors}
        operator={condition.operator}
        dispatch={dispatch}
        onChange={onValuesChange}
        disabled={isOperatorInputDisabled}
      />
      <ConditionActionIconWrapperStyled onClick={onAddNew} iconName={AvailableIcons.Plus} />
      <ConditionActionIconWrapperStyled
        onClick={onTrashcanClick}
        iconName={AvailableIcons.TrashCan}
        disabled={!canBeDeleted}
      />
    </ConditionRuleStyled>
  );
};

interface ConditionRulesProps extends Pick<ConditionRuleProps, 'shopId' | 'locale' | 'dispatch'> {
  conditions: IThematicTagConditionsDraft[];
  onChange: (newConditions: Pick<IThematicTagConditionsGroupDraft, 'conditions'>) => void;
  availableCategoryKeys: string[];
  availableAttributeKeysByCategory: AvailableAttributeKeysByCategoryMap;
  isFirstGroup: boolean;
  errors: ParseJoiValidateResponse<IThematicTagDraft>;
  isTheOnlyGroup: boolean;
}

export const ConditionRules = ({
  conditions,
  onChange,
  availableCategoryKeys,
  isFirstGroup,
  availableAttributeKeysByCategory,
  shopId,
  locale,
  dispatch,
  errors,
  isTheOnlyGroup,
}: ConditionRulesProps): JSX.Element => {
  const onAddNewCondition = useCallback(() => {
    const newCondition = { ...generateEmptyCondition(), categoryKey: conditions[0].categoryKey };
    const updatedConditions = [...conditions, newCondition];

    onChange({ conditions: updatedConditions });
  }, [conditions, onChange]);

  const onConditionChange = useCallback(
    ({
      type,
      updatedCondition,
    }: {
      type: 'category' | 'attribute' | 'value' | 'operator';
      updatedCondition: IThematicTagConditionsDraft;
    }) => {
      const updatedConditions = conditions.map(condition => {
        if (condition.id === updatedCondition.id) {
          return updatedCondition;
        }

        // Update all the other conditions in the group to have the same chosen category as the first condition.
        if (type === 'category') {
          return { ...condition, categoryKey: updatedCondition.categoryKey };
        }

        return condition;
      });

      onChange({ conditions: updatedConditions });
    },
    [conditions, onChange]
  );

  const onConditionRemove = useCallback(
    (id: string) => {
      const updatedConditions: IThematicTagConditionsDraft[] = conditions.filter(
        condition => condition.id !== id
      );

      onChange({ conditions: updatedConditions });
    },
    [conditions, onChange]
  );

  return (
    <>
      {conditions.map((condition, index) => (
        <ConditionRule
          key={condition.id}
          condition={condition}
          onChange={onConditionChange}
          onRemove={onConditionRemove}
          onAddNew={onAddNewCondition}
          index={index}
          availableAttributeKeys={availableAttributeKeysByCategory[condition.categoryKey]}
          availableCategoryKeys={availableCategoryKeys}
          isFirstGroup={isFirstGroup}
          shopId={shopId}
          locale={locale}
          dispatch={dispatch}
          errors={errors}
          isTheOnlyGroup={isTheOnlyGroup}
          totalConditionsCountInGroup={conditions.length}
        />
      ))}
    </>
  );
};
