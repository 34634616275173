import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ParseJoiValidateResponse } from 'src/utils';
import { ThematicTagConditionOperatorsEnum } from 'src/services';
import { MenuItem, SelectOnChangeEvent, SelectType } from 'src/components-dummy';
import { Dispatch } from '../../../../../../types';
import { IThematicTagDraft } from '../../ThematicTagForm.types';
import { thematicTagFormActions } from '../../ThematicTagForm.actions';
import { LexiconTagAutoCompleteStyled, LexiconTagSelectStyled } from './ConditionGroups.styles';

export interface ValuesInputFactoryProps {
  shopId: number;
  locale: string;
  categoryKey: string;
  attributeKey: string;
  valuesKeys: string[];
  errors: ParseJoiValidateResponse<IThematicTagDraft>;
  operator: ThematicTagConditionOperatorsEnum;
  dispatch: Dispatch;
  onChange: (newValues: string[]) => void;
  disabled: boolean;
}

export const ValuesInputFactory = ({
  shopId,
  locale,
  categoryKey,
  attributeKey,
  valuesKeys,
  errors,
  operator,
  dispatch,
  onChange,
  disabled,
}: ValuesInputFactoryProps): JSX.Element | null => {
  const [availableValueKeys, setAvailableValueKeys] = useState<string[]>([]);

  const mappedValueKeyOptions = useMemo(() => {
    return availableValueKeys.map(value => ({
      title: value,
      value,
    }));
  }, [availableValueKeys]);

  const onSelectChange: SelectOnChangeEvent = useCallback(
    event => {
      onChange([event.target.value]);
    },
    [onChange]
  );

  let inputElement = null;

  switch (operator) {
    case ThematicTagConditionOperatorsEnum.ValueIsEither: {
      inputElement = (
        <LexiconTagAutoCompleteStyled
          multiple
          selectedValues={valuesKeys}
          onChange={onChange}
          options={mappedValueKeyOptions}
          disabled={disabled}
          errored={!!errors.valuesKeys}
        />
      );

      break;
    }
    case ThematicTagConditionOperatorsEnum.ValueIsNot:
    case ThematicTagConditionOperatorsEnum.ValueIs: {
      inputElement = (
        <LexiconTagSelectStyled
          type={SelectType.Menu}
          value={valuesKeys[0]}
          onChange={onSelectChange}
          placeholder='Value'
          disabled={disabled}
          errorMessage={errors.valuesKeys?.message}
        >
          {availableValueKeys.map(valueKey => (
            <MenuItem key={valueKey} value={valueKey}>
              {valueKey}
            </MenuItem>
          ))}
        </LexiconTagSelectStyled>
      );
      break;
    }
    default:
      break;
  }

  // Fetch available attribute keys when main category changes
  useEffect(() => {
    if (categoryKey.length && attributeKey.length) {
      (
        dispatch(
          thematicTagFormActions.getAvailableKeys({
            shopId,
            locale,
            searchTerm: '',
            tagField: 'value',
            categories: [categoryKey],
            attributes: [attributeKey],
          })
        ) as any
      )
        .unwrap()
        .then(({ availableValues }: { availableValues: string[] }) => {
          setAvailableValueKeys(availableValues);
        })
        .catch((error: unknown) => {
          console.error(error);
          setAvailableValueKeys([]);
        });
    } else {
      setAvailableValueKeys([]);
    }
  }, [shopId, locale, categoryKey, attributeKey]);

  return inputElement;
};
