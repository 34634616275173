import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import { visualEditorActions } from '../state';
import { Dispatch } from '../../../components-dummy/types';
import { useGetQueryParam } from '../../../hooks/use-get-query-param';

export const useSupportedExperience = (appDispatch: Dispatch) => {
  const { experiences } = useAppSelector(({ visualEditor }) => visualEditor);

  const location = useLocation();
  const history = useHistory();

  const experienceParam = useGetQueryParam('shopperExperience');
  const isSupportedExperience = experiences?.some(
    experience => experience.feature === experienceParam && experience.supported
  );

  useEffect(() => {
    if (!isSupportedExperience && experiences && experienceParam) {
      appDispatch(visualEditorActions.changeExperience(null));
      history.replace(location.pathname);
    }
  }, [isSupportedExperience, experiences, experienceParam, location, history]);
};
