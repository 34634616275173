import React from 'react';
import { RoutedComponentProps } from 'src/app-routes';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { UiTemplatesHeader, StartEditPopup } from '../../components-bl';
import { Page } from '../../components-dummy';
import { UiTemplatesMain } from './PageContent';
import { useStartEditPopup } from '../../components-bl/UiTemplates/StartEditPopup/useStartEditPopup';

import './uiTemplates.scss';

export const UiTemplates: React.FC<RoutedComponentProps> = () => {
  const dispatch = useAppDispatch();
  const shopId = useAppSelector(state => state.shop.current?.shopId);

  const { isPopupOpen, openPopup, closePopup } = useStartEditPopup();

  return shopId ? (
    <>
      {isPopupOpen ? (
        <StartEditPopup
          shopId={shopId}
          dispatch={dispatch}
          isPopupOpen={isPopupOpen}
          closePopup={closePopup}
        />
      ) : (
        <Page className='uiTemplates'>
          <Page.Header>
            <UiTemplatesHeader shopId={shopId} dispatch={dispatch} openPopup={openPopup} />
          </Page.Header>
          <Page.Content className='uiTemplates'>
            <UiTemplatesMain shopId={shopId} dispatch={dispatch} openPopup={openPopup} />
          </Page.Content>
        </Page>
      )}
    </>
  ) : null;
};
