import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const EllipsisWithToolTipStyled = styled.div<{ maxWidth?: number }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth}px;
    `};

  .syte-popup-trigger {
    width: 100%;
  }

  .ellipsis-with-tooltip-content {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .ellipsis-with-tooltip-popup {
    z-index: 999999 !important;
    white-space: pre-wrap;
    word-break: break-word;
  }
`;
