import Joi from 'joi';
import { isNumber, isString } from 'lodash';
import { MerchandisingRuleTypes, SyteProductTypes } from 'src/services';
import { getStringEnumValues, isArrayOfNumbers, isArrayOfStrings } from 'src/utils';
import { ConditionRowType, MerchandisingRuleSettings } from '../types';
import { DataFieldLookupTable } from '../useDataFieldsLookupTable';
import { defaultRuleSettings } from '../constants';

type ValidationContextRuleSettings = Pick<
  MerchandisingRuleSettings,
  'conditionTypesOptions' | 'subRulesConditionTypes'
>;

export interface GetSubTypeSchemaArguments {
  ruleSettingsKey: keyof ValidationContextRuleSettings;
  conditionType?: ConditionRowType;
}

export interface ValidationContext {
  dataFieldsLookupTable: DataFieldLookupTable;
  ruleSettings: ValidationContextRuleSettings;
}

const containsSchema = Joi.array()
  .min(1)
  .required()
  .custom((value, helpers) => {
    const { original: tagValues } = helpers;
    const isValid = isArrayOfStrings(tagValues);
    if (!isValid) {
      throw new Error('Invalid Contains value');
    }
    return value;
  }, 'validate Contains')
  .messages({ 'array.min': 'At least one tag should be added' });

const conditionTypeToConditionValueValidationSchema: Partial<
  Record<MerchandisingRuleTypes.MerchandisingRuleSubType, Joi.ArraySchema>
> = {
  [MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain]: containsSchema,
  [MerchandisingRuleTypes.MerchandisingRuleSubType.Contains]: containsSchema,
  [MerchandisingRuleTypes.MerchandisingRuleSubType.Equals]: Joi.array()
    .length(1)
    .required()
    .custom((value, helpers) => {
      const { original } = helpers;
      const [equalsValue] = original;
      const isValid =
        (isString(equalsValue) && equalsValue.trim().length) || typeof equalsValue === 'boolean';
      if (!isValid) {
        throw new Error('Invalid Equals value');
      }
      return value;
    }, 'validate Equals'),
  [MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan]: Joi.array()
    .length(2)
    .required()
    .custom((value, helpers) => {
      const { original } = helpers;
      const [firstValue, secondValue] = original;
      const isValid = isNumber(firstValue) && secondValue === null;
      if (!isValid) {
        throw new Error('Invalid HigherThan value');
      }
      return value;
    }, 'validate HigherThan'),
  [MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan]: Joi.array()
    .length(2)
    .required()
    .custom((value, helpers) => {
      const { original } = helpers;
      const [firstValue, secondValue] = original;
      const isValid = firstValue === null && isNumber(secondValue);
      if (!isValid) {
        throw new Error('Invalid LowerThan value');
      }
      return value;
    }, 'validate LowerThan'),

  [MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween]: Joi.array()
    .length(2)
    .required()
    .custom((value, helpers) => {
      const { original } = helpers;
      const [firstValue, secondValue] = original;
      const isValid = isArrayOfNumbers(original) && firstValue < secondValue;
      if (!isValid) {
        throw new Error('Invalid IsBetween value');
      }
      return value;
    }, 'validate IsBetween '),
  [MerchandisingRuleTypes.MerchandisingRuleSubType.RelativeRange]: Joi.array()
    .length(2)
    .required()
    .custom((value, helpers) => {
      const { original } = helpers;
      const [firstValue, secondValue] = original;
      const isValid = isArrayOfNumbers(original) && firstValue < secondValue;
      if (!isValid) {
        throw new Error('Invalid RelativeRange value');
      }
      return value;
    }, 'validate RelativeRange '),
};

const searchConditionValidationSchema = Joi.object().keys({
  enabled: Joi.boolean().required(),
  terms: Joi.alternatives().conditional('enabled', {
    is: true,
    then: Joi.array().items(Joi.string()).min(1).required(),
    otherwise: Joi.array().required(),
  }),
});

const conditionValueValidationSchema = (
  Object.keys(
    conditionTypeToConditionValueValidationSchema
  ) as MerchandisingRuleTypes.MerchandisingRuleSubType[]
).reduce((result, current) => {
  return result.when('subType', {
    is: current,
    then: conditionTypeToConditionValueValidationSchema[current],
  });
}, Joi.array().items().required());

const getContextValues = ({ context }: { context: Joi.Context | undefined }): ValidationContext => {
  const dataFieldsLookupTable = context?.dataFieldsLookupTable || {};
  const ruleSettings = context?.ruleSettings || defaultRuleSettings;

  return {
    dataFieldsLookupTable,
    ruleSettings,
  };
};

const getConditionTypes = (
  ruleSettings: ValidationContextRuleSettings,
  ruleSettingsKey: keyof ValidationContextRuleSettings,
  conditionType?: ConditionRowType
): Set<MerchandisingRuleTypes.MerchandisingRuleSubType> => {
  const isSubRulesConditionTypes = ruleSettingsKey === 'subRulesConditionTypes';
  const typesArray = isSubRulesConditionTypes
    ? ruleSettings.subRulesConditionTypes
    : conditionType && ruleSettings.conditionTypesOptions?.[conditionType];
  return new Set(typesArray);
};

const getSubTypeSchema = ({
  ruleSettingsKey,
  conditionType,
}: GetSubTypeSchemaArguments): Joi.StringSchema => {
  return Joi.string().when('field', {
    not: undefined,
    then: Joi.required()
      .custom((value, helpers) => {
        const {
          original: operator,
          state: { ancestors },
          prefs: { context },
        } = helpers;

        const dataFieldNameFromSubRule = ancestors[0].field;

        const { dataFieldsLookupTable, ruleSettings } = getContextValues({ context });
        const conditionTypesSetForFiltering = getConditionTypes(
          ruleSettings,
          ruleSettingsKey,
          conditionType
        );

        const validConditionTypesSet = new Set(
          dataFieldsLookupTable[dataFieldNameFromSubRule]?.conditionTypes.filter(type =>
            conditionTypesSetForFiltering.has(type)
          ) || []
        );

        const isValid = validConditionTypesSet.has(operator);

        if (!isValid) {
          throw new Error('Operator is no longer valid');
        }
        return value;
      }, 'validate sub type')
      .messages({ 'any.custom': 'Operator is no longer valid' }),
    otherwise: Joi.optional(),
  });
};

const dataFieldValidationSchema = Joi.string().when('enabled', {
  is: true,
  then: Joi.required()
    .custom((dataFieldName, { prefs: { context } }) => {
      const { dataFieldsLookupTable } = getContextValues({ context });

      const exists = !!dataFieldsLookupTable[dataFieldName];
      if (!exists) {
        throw new Error(`Data field doesn't exist`);
      }
      return dataFieldName;
    }, 'validate data field')
    .messages({ 'any.custom': 'The data field was removed because it is invalid' }),
  otherwise: Joi.optional(),
});

const sourceConditionValidationSchema = {
  enabled: Joi.boolean().required(),
  type: Joi.string().required().valid(MerchandisingRuleTypes.RuleAction.Include),
  field: dataFieldValidationSchema,
  subType: getSubTypeSchema({
    ruleSettingsKey: 'conditionTypesOptions',
    conditionType: ConditionRowType.ApplyWhenCondition,
  }),
  values: conditionValueValidationSchema,
};

const filterByConditionValidationSchema = {
  type: Joi.string().required().valid(MerchandisingRuleTypes.RuleAction.Include),
  field: dataFieldValidationSchema,
  subType: getSubTypeSchema({
    ruleSettingsKey: 'conditionTypesOptions',
    conditionType: ConditionRowType.FilterByCondition,
  }),
  values: conditionValueValidationSchema,
};

const subRuleValidationSchema = {
  enabled: Joi.boolean().required(),
  type: Joi.string()
    .required()
    .valid(...getStringEnumValues(MerchandisingRuleTypes.RuleAction)),
  field: dataFieldValidationSchema,
  subType: getSubTypeSchema({ ruleSettingsKey: 'subRulesConditionTypes' }),
  values: Joi.alternatives().conditional('subType', {
    not: undefined,
    then: conditionValueValidationSchema,
    otherwise: Joi.array().required(),
  }),
};

const appliedDateRangeSchema = {
  startDate: Joi.date().required(),
  endDate: Joi.date().greater(Joi.ref('startDate')).required(),
  timeZone: Joi.string().valid().required(),
};

export const formValidationSchema = {
  action: Joi.string()
    .required()
    .valid(...getStringEnumValues(MerchandisingRuleTypes.RuleAction)),
  active: Joi.boolean(),
  name: Joi.string().required().trim().min(3),
  product: Joi.string()
    .required()
    .valid(...getStringEnumValues(SyteProductTypes.SyteProductType)),
  weight: Joi.number().required().min(0).max(100),
  appliedDateRange: Joi.object().allow(null).keys(appliedDateRangeSchema).optional(),
  searchCondition: searchConditionValidationSchema,
  sourceCondition: Joi.array().items(Joi.object().keys(sourceConditionValidationSchema)).min(1),
  filterByCondition: Joi.array().items(Joi.object().keys(filterByConditionValidationSchema)),
  subRules: Joi.array().items(subRuleValidationSchema).min(1),
};
