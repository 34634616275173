import { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';
import { isNaN } from 'lodash';
import {
  CatalogUploadLogsStatusEnum,
  CatalogUploadLogsStatusEnumAPI,
  ICatalogFeedUploadLog,
  ICatalogFeedUploadLogAPI,
  ICatalogPlanAPI,
  ICatalogUploadErrorsPaginated,
  IShopCatalog,
  IShopCatalogAPI,
} from '../types';

function isValidDate(date: Date): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}

export class CatalogManagerMapper {
  static mapCatalog(catalog: IShopCatalogAPI): IShopCatalog {
    const { lastUpdated } = catalog;
    let parsedLastUpdated;

    if (lastUpdated) {
      // Adding UTC to force JS to treat it as a UTC
      const timeStampWithUTCSuffix = new Date(`${lastUpdated} UTC`);

      if (isValidDate(timeStampWithUTCSuffix)) {
        parsedLastUpdated = timeStampWithUTCSuffix;
      } else {
        const formattedTimestamp = `${lastUpdated.replace(' ', 'T')}Z`;

        const localizedTimestamp = new Date(formattedTimestamp);

        if (isValidDate(localizedTimestamp)) {
          parsedLastUpdated = localizedTimestamp;
        } else {
          parsedLastUpdated = new Date(lastUpdated);
        }
      }
    }

    return {
      ...catalog,
      lastUpdated: parsedLastUpdated,
    };
  }

  static mapCatalogs({ data }: AxiosResponse<{ catalogs: IShopCatalogAPI[] }>): IShopCatalog[] {
    return data.catalogs.map(catalog => this.mapCatalog(catalog));
  }

  static mapSingleCatalog({ data }: AxiosResponse): ICatalogPlanAPI {
    return data;
  }

  static mapCatalogFeedUploadLogs({ data }: AxiosResponse): ICatalogFeedUploadLog[] {
    /**
     * Map API status to VM status - add "processed" status - success with errors
     * statuses: failed / success / processed
     */
    const mapStatusAPIToVM = ({
      status,
      numberOfFailedProducts,
    }: {
      status: ICatalogFeedUploadLogAPI['status'];
      numberOfFailedProducts: ICatalogFeedUploadLogAPI['numberOfFailedProducts'];
    }): CatalogUploadLogsStatusEnum => {
      if (status === CatalogUploadLogsStatusEnumAPI.Failed) {
        return CatalogUploadLogsStatusEnum.Failed;
      }

      if (status === CatalogUploadLogsStatusEnumAPI.Warning) {
        return CatalogUploadLogsStatusEnum.Warning;
      }

      // Incase - indexed (success) feed with errors - status will be - "processed"
      if (numberOfFailedProducts) {
        return CatalogUploadLogsStatusEnum.Processed;
      }

      return CatalogUploadLogsStatusEnum.Success;
    };

    return data.map((feedUploadLog: ICatalogFeedUploadLogAPI): ICatalogFeedUploadLog => {
      const startDate = new Date(parseISO(feedUploadLog.startDate));
      const endDate = new Date(parseISO(feedUploadLog.endDate));

      const statusValue = mapStatusAPIToVM({
        status: feedUploadLog.status,
        numberOfFailedProducts: feedUploadLog.numberOfFailedProducts,
      });

      return {
        ...feedUploadLog,
        status: statusValue,
        startDate,
        endDate,
      };
    });
  }

  static mapCatalogUploadErrorReport({ data }: AxiosResponse): ICatalogUploadErrorsPaginated {
    return data;
  }
}
