import React, { useCallback, useEffect, useState, useRef } from 'react';
import { IDeepTagReportProductComment } from 'src/services/src/service/types/deep-tag-reports';
import { UserTypes } from 'src/services/src/service/types';
import { Dispatch } from 'src/components-bl/types';
import { GetDeepTagReportProductArguments } from 'src/services/src/service/deep-tag-reports';
import { v4 as uuid } from 'uuid';
import { Button } from 'src/components-dummy';
import { DeepTagProductCommentItem } from './DeepTagProductCommentItem';
import {
  CommentsFormButtonsSectionStyled,
  CommentsFormListStyled,
  CommentsFormStyled,
} from './DeepTagProductComments.styles';
import { deepTagsProductCommentsActions } from './DeepTagsProductComments.actions';
import { sortCommentsByCreatedAt } from './DeepTagProductComments.helpers';
import { IProductFormCommentItem } from './DeepTagProductComments.types';

interface DeepTagProductCommentsListProps extends GetDeepTagReportProductArguments {
  comments: IDeepTagReportProductComment[];
  currentUser: UserTypes.User;
  shopId: number;
  reportId: string;
  productId: string;
  onClose: () => void;
  getPaginatedReportsWithFilters: () => void;
  dispatch: Dispatch;
}

export const DeepTagProductCommentsList = ({
  shopId,
  reportId,
  productId,
  currentUser,
  comments,
  onClose,
  getPaginatedReportsWithFilters,
  dispatch,
}: DeepTagProductCommentsListProps): JSX.Element => {
  const listRef = useRef<HTMLDivElement>(null);
  const [formData, setFormData] = useState<Array<IProductFormCommentItem>>([]);
  const [initialScrollDone, setInitialScrollDone] = useState(false); // State to track initial scrolling

  const createNewComment = useCallback(() => {
    return {
      id: uuid(),
      text: '',
      userEmail: currentUser.email,
      userId: currentUser.userId,
      userFullName: `${currentUser.firstName} ${currentUser.lastName}`,
    };
  }, [currentUser]);

  const onChange = useCallback(() => {
    return getPaginatedReportsWithFilters();
  }, []);

  const onCreateComment = useCallback(
    async comment => {
      const result = (await dispatch(
        deepTagsProductCommentsActions.createProductComment({
          comment,
          shopId,
          reportId,
          productId,
        })
      )) as any;

      if (!result?.error && result?.payload?.createdComment) {
        setFormData(prevState => {
          // Previous state without last item (form created item)
          const stateWithoutNew = [...prevState.slice(0, prevState.length - 1)];

          const newState = [...stateWithoutNew, result.payload.createdComment, createNewComment()];
          return newState;
        });
      }
    },
    [shopId, reportId, productId, createNewComment]
  );

  const onUpdateComment = useCallback(
    async comment => {
      const result = (await dispatch(
        deepTagsProductCommentsActions.updateProductComment({
          comment,
          shopId,
          reportId,
          productId,
        })
      )) as any;

      if (!result?.error && result?.payload?.updatedComment) {
        setFormData(prevState => {
          const newState = prevState.map(stateComment => {
            return stateComment.id === comment.id ? result.payload.updatedComment : stateComment;
          });

          return newState;
        });
      }

      return result;
    },
    [shopId, reportId, productId]
  );

  const onDeleteComment = useCallback(
    async ({ id }) => {
      const result = (await dispatch(
        deepTagsProductCommentsActions.deleteProductComment({
          commentId: id,
          shopId,
          reportId,
          productId,
        })
      )) as any;

      if (!result?.error) {
        setFormData(prevState => {
          const newState = prevState.filter(comment => comment.id !== id);
          return newState;
        });
      }

      return result;
    },
    [shopId, reportId, productId]
  );

  useEffect(() => {
    if (!formData.length) {
      setFormData([...comments, createNewComment()]);

      if (!initialScrollDone && listRef.current) {
        listRef.current.scrollTop = listRef.current?.scrollHeight;
        setInitialScrollDone(true); // Mark initial scroll as done
      }
    } else if (formData.length !== comments.length) {
      const otherUsersComments = comments.filter(comment => comment.userId !== currentUser.userId);

      setFormData(prevState => {
        const currentUserComments = prevState.filter(
          comment => comment.userId === currentUser.userId
        );

        // Current user's comments with new comments from other users
        const allComments = otherUsersComments.concat(currentUserComments);
        return sortCommentsByCreatedAt(allComments);
      });
    }
  }, [comments]);

  useEffect(() => {
    if (initialScrollDone && listRef.current) {
      listRef.current.scrollTop = listRef.current?.scrollHeight;
    }
  }, [initialScrollDone]);

  return (
    <CommentsFormStyled>
      <CommentsFormListStyled ref={listRef}>
        <>
          {formData.map(comment => {
            return (
              <DeepTagProductCommentItem
                key={comment.id}
                comment={comment}
                currentUser={currentUser}
                onCreateComment={onCreateComment}
                onUpdateComment={onUpdateComment}
                onDeleteComment={onDeleteComment}
                onChange={onChange}
              />
            );
          })}
        </>
      </CommentsFormListStyled>
      <CommentsFormButtonsSectionStyled>
        <Button variant='secondary' onClick={onClose}>
          Cancel
        </Button>
      </CommentsFormButtonsSectionStyled>
    </CommentsFormStyled>
  );
};
