import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AvailableIcons,
  Button,
  EllipsisWithTooltip,
  MultiSelectOption,
  PopUp,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { isEqual } from 'lodash';
import {
  CatalogExplorerFilterAutoCompleteStyled,
  DeleteButtonWrapperStyled,
  FilterItemReadOnlyWrapperStyled,
  IconTrashStyled,
  MultiSelectMenuWithSearchWrapperStyled,
  MultiSelectMenuWithVirtualScrollWrapperStyled,
  SpinnerStyled,
  TagsInputStyled,
} from './CatalogExplorerFilterAutoComplete.styles';
import {
  MultiSelectMenuWithSearchStyled,
  SearchInputStyled,
} from 'src/components-dummy/MultiSelectMenu/MultiSelectMenu.styles';
import { MultiSelectMenuWithVirtualScroll } from 'src/components-dummy/MultiSelectMenu/MultiSelectMenuWithVirtualScroll';
import {
  MultiSelectFooterStyled,
  MultiSelectWrapperStyled,
  TagMultiSelectContentStyled,
} from '../CatalogExplorerFilters/CatalogExplorerFilters.styles';
import { MultiSelectTagLabelStyled } from 'src/components-dummy/FiltersRow/components';
import { useManageFilterOptions } from './useManageFilterOptions';
import { useManageFilterOptionsCountUpdate } from './useManageFilterOptionsCountUpdate';
import { buildSelectedOptionObject } from './CatalogExplorerFilterAutoComplete.helpers';
import { CatalogAvailableFilterOptions, FeedDataFieldType, ShopDataField } from 'src/services';

interface CatalogExplorerFilterAutoCompleteProps {
  onChange: (values: string[]) => void;
  onRemoveFilter: (filterKey: string) => void;
  shopId: number;
  catalogName: string;
  field: string;
  filters: Record<string, string[] | number[]>;
  mainSearchFilterTerm?: string;
  fieldDisplayName: string;
  fieldDataTypes: ShopDataField['types'];
  lastChangeOnFilterType?: string;
}

interface FormData {
  searchAfter?: Record<string, unknown>;
  options: MultiSelectOption[];
  totalItems: number;
  searchTerm: string;
  isLoading: boolean;
}

const InitialFormData: FormData = {
  searchAfter: undefined,
  options: [],
  searchTerm: '',
  isLoading: false,
  totalItems: 0,
};

export const CatalogExplorerFilterAutoComplete = React.memo(
  ({
    onChange,
    onRemoveFilter,
    filters,
    mainSearchFilterTerm,
    lastChangeOnFilterType,
    fieldDisplayName,
    shopId,
    catalogName,
    fieldDataTypes,
    field,
  }: CatalogExplorerFilterAutoCompleteProps): JSX.Element => {
    const selectedValuesMap = useRef(new Map());
    const searchInputRef = useRef<HTMLInputElement>(null);

    const selectedValues = useMemo(() => {
      return (filters[field] || []) as string[];
    }, [filters, field]);

    const [formData, setFormData] = useState<FormData>(InitialFormData);

    const [showPopUp, setShowPopUp] = useState(false);
    const [triggerUpdateTags, setTriggerUpdateTags] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(new Set(selectedValues));

    const { debouncedFetchData } = useManageFilterOptions({
      shopId,
      catalogName,
      formData,
      filters,
      mainSearchFilterTerm,
      field,
      setFormData,
    });

    const onSelectedValuesCountUpdate = useCallback(
      ({ updatedValuesCount }: { updatedValuesCount: CatalogAvailableFilterOptions }) => {
        updatedValuesCount.options.forEach(({ value, total }) => {
          if (selectedValuesMap.current.has(value)) {
            selectedValuesMap.current.set(value, buildSelectedOptionObject({ value, total }));
          }
        });

        setTriggerUpdateTags(prevState => !prevState);
      },
      [selectedValuesMap.current]
    );

    useManageFilterOptionsCountUpdate({
      shopId,
      catalogName,
      formData,
      filters,
      mainSearchFilterTerm,
      field,
      showPopUp,
      lastChangeOnFilterType,
      onSelectedValuesCountUpdate,
    });

    const canApplySelection = useMemo(() => {
      return !isEqual(Array.from(selectedOptions), selectedValues);
    }, [selectedOptions, selectedValues]);

    const resetState = useCallback(() => {
      setFormData({ ...InitialFormData });
      setSelectedOptions(new Set([]));
    }, []);

    const toggleShowPopUp = useCallback(
      (shouldShowPopUp: boolean) => {
        setShowPopUp(shouldShowPopUp);

        if (!shouldShowPopUp) {
          resetState();
        }
      },
      [resetState]
    );

    const onCancel = useCallback(() => {
      setSelectedOptions(new Set(selectedValues));
      toggleShowPopUp(false);
    }, [selectedValues, toggleShowPopUp]);

    const onConfirmSelection = useCallback(() => {
      if (canApplySelection) {
        onChange(Array.from(selectedOptions));
        onCancel();
      }
    }, [onChange, selectedOptions, canApplySelection, onCancel]);

    const handleSearchChange = useCallback((text?: string) => {
      setFormData(() => {
        return {
          ...InitialFormData,
          isLoading: true,
          searchTerm: text || '',
        };
      });
    }, []);

    const onLoadMoreItems = useCallback(() => {
      setFormData(prevState => ({ ...prevState, isLoading: true }));
      debouncedFetchData();
    }, [debouncedFetchData]);

    const updateSelectedOptions = () => {
      setSelectedOptions(new Set(selectedValues));
    };

    const onSelectOption = useCallback((option: MultiSelectOption) => {
      setSelectedOptions(prevState => {
        const updatedSet = new Set(Array.from(prevState));

        if (updatedSet.has(option.value)) {
          updatedSet.delete(option.value);
          selectedValuesMap.current.delete(option.value);
        } else {
          updatedSet.add(option.value.toString());
          selectedValuesMap.current.set(option.value.toString(), option);
        }

        return updatedSet;
      });
    }, []);

    const onTagsChange = useCallback(options => {
      const cleanedOptions = options.map(option => {
        const countTextIndex = option.lastIndexOf(' (');
        const value = option.substring(0, countTextIndex);
        return value;
      });

      onChange(cleanedOptions);
    }, []);

    const onDeleteFieldFilters = useCallback(() => {
      setSelectedOptions(new Set());
      onRemoveFilter(field);
    }, [onRemoveFilter, field]);

    useEffect(() => {
      if (showPopUp) {
        searchInputRef.current?.focus();
      }
    }, [showPopUp]);

    useEffect(() => {
      const updatedOptions = new Set(selectedValues);

      // Remove deleted option in map - check against previous selectedOptions state
      selectedValuesMap.current.forEach(option => {
        if (!updatedOptions.has(option.value)) {
          selectedValuesMap.current.delete(option.value.toString());
        }
      });

      setSelectedOptions(new Set(selectedValues));
    }, [selectedValues]);

    useEffect(() => {
      if (showPopUp) {
        setFormData(prevState => ({ ...prevState, isLoading: true }));
        updateSelectedOptions();
        debouncedFetchData();
      }

      return () => {
        debouncedFetchData.cancel();
      };
    }, [formData.searchTerm, showPopUp]);

    const tagsOptions = useMemo(() => {
      const tagsOptionsUpdated = [];

      selectedValues.forEach(option => {
        const selectedOptionObject = selectedValuesMap.current.get(option);

        if (selectedOptionObject) {
          tagsOptionsUpdated.push(selectedOptionObject.text);
        }
      });

      return tagsOptionsUpdated;
    }, [selectedValues, selectedValuesMap, triggerUpdateTags]);

    const hideTagsInput = selectedValues.length > 0;

    const filterTitle = `Filter by ${fieldDisplayName}`;

    const shouldIncludeSearch = useMemo(() => {
      if (fieldDataTypes.includes(FeedDataFieldType.Boolean)) {
        return false;
      }
      return true;
    }, [fieldDataTypes]);

    return (
      <CatalogExplorerFilterAutoCompleteStyled>
        <PopUp
          show={showPopUp}
          onShow={toggleShowPopUp}
          closeOnTriggerClick={true}
          hideOnScroll={false}
          position='bottom left'
        >
          <PopUp.Trigger>
            <FilterItemReadOnlyWrapperStyled>
              <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
                {fieldDisplayName} is
              </Typography>
              <TagsInputStyled
                tags={tagsOptions as string[]}
                onChange={onTagsChange}
                maxTagsToDisplay={10}
                placeholder='Select value...'
                hideInput={hideTagsInput}
              />
              <DeleteButtonWrapperStyled>
                <IconTrashStyled name={AvailableIcons.TrashCan} onClick={onDeleteFieldFilters} />
              </DeleteButtonWrapperStyled>
            </FilterItemReadOnlyWrapperStyled>
          </PopUp.Trigger>
          <PopUp.Content>
            <MultiSelectMenuWithSearchWrapperStyled>
              <MultiSelectWrapperStyled width={248}>
                <TagMultiSelectContentStyled>
                  <MultiSelectTagLabelStyled
                    type={TypographyType.Paragraph}
                    variant={TypographyVariant.SmallRegular}
                  >
                    <EllipsisWithTooltip tooltipText={filterTitle}>
                      {filterTitle}
                    </EllipsisWithTooltip>
                  </MultiSelectTagLabelStyled>
                  <MultiSelectMenuWithSearchStyled>
                    {shouldIncludeSearch && (
                      <SearchInputStyled
                        placeholder={`Search ${field}`}
                        value={formData.searchTerm}
                        onChange={handleSearchChange}
                        inputRef={searchInputRef}
                      />
                    )}
                    <MultiSelectMenuWithVirtualScrollWrapperStyled>
                      <MultiSelectMenuWithVirtualScroll
                        hasNextPage={formData.options.length < formData.totalItems}
                        onLoadMoreItems={onLoadMoreItems}
                        options={formData.options}
                        selectedOptions={selectedOptions}
                        onChange={onSelectOption}
                      />
                      {formData.isLoading ? <SpinnerStyled size={35} /> : null}
                    </MultiSelectMenuWithVirtualScrollWrapperStyled>
                  </MultiSelectMenuWithSearchStyled>
                </TagMultiSelectContentStyled>
                <MultiSelectFooterStyled>
                  <Button variant='tertiary' onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button
                    variant='primary'
                    onClick={onConfirmSelection}
                    disabled={!canApplySelection}
                  >
                    Apply
                  </Button>
                </MultiSelectFooterStyled>
              </MultiSelectWrapperStyled>
            </MultiSelectMenuWithSearchWrapperStyled>
          </PopUp.Content>
        </PopUp>
      </CatalogExplorerFilterAutoCompleteStyled>
    );
  }
);
