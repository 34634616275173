import React from 'react';
import {
  Button,
  DialogModalContent,
  DialogModalHeader,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../../components-dummy';
import { DataFieldsList } from '../DataFieldsList/DataFieldsList';
import { SpecialDataFieldsList } from '../SpecialDataFieldsList/SpecialDataFieldsList';
import { NumberOfItemsPerRow } from '../NumberOfItemsPerRow/NumberOfItemsPerRow';
import { useDisplayPreferences } from './useDisplayPreferences';
import { DialogModalFooterStyled, DialogModalStyled } from './DisplayPreferences.styles';
import { ErrorLabelStyled } from '../DataFieldsList/DataFieldsList.styles';

interface Props {
  shopId: number;
  onCloseModal: VoidFunction;
}

export const DisplayPreferences = ({ shopId, onCloseModal }: Props): JSX.Element => {
  const {
    dataFieldsToDisplay,
    specialDataFieldsToDisplay,
    onSpecialDataFieldChange,
    onOrderChanged,
    dataFieldsDropdownOptions,
    handleSelectChange,
    itemsCountPerRow,
    onItemsCountPerRowChanged,
    onRemove,
    onAdd,
    onSave,
    onDiscard,
    errors,
    canSubmit,
    isEmpty,
  } = useDisplayPreferences({ shopId, onCloseModal });

  return (
    <DialogModalStyled open onClose={onCloseModal}>
      <>
        <DialogModalHeader>
          <Typography type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
            Configure Display Preferences
          </Typography>
        </DialogModalHeader>
        <DialogModalContent>
          <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumBold}>
            Customize your product card layout by selecting which data fields you want to display.
          </Typography>
          <SpecialDataFieldsList
            specialDataFieldsToDisplay={specialDataFieldsToDisplay}
            onSpecialDataFieldChange={onSpecialDataFieldChange}
          />
          <DataFieldsList
            dataFieldsToDisplay={dataFieldsToDisplay}
            onOrderChanged={onOrderChanged}
            dataFieldsDropdownOptions={dataFieldsDropdownOptions}
            handleSelectChange={handleSelectChange}
            onRemove={onRemove}
            onAdd={onAdd}
            errors={errors}
          />
          <NumberOfItemsPerRow
            itemsCountPerRow={itemsCountPerRow}
            onItemsCountPerRowChanged={onItemsCountPerRowChanged}
          />
          {isEmpty ? (
            <ErrorLabelStyled>At least 1 data field needs to be selected</ErrorLabelStyled>
          ) : null}
        </DialogModalContent>
        <DialogModalFooterStyled>
          <Button variant='primary' disabled={!canSubmit} onClick={onSave}>
            Save changes
          </Button>
          <Button variant='tertiary' onClick={onDiscard}>
            Cancel
          </Button>
        </DialogModalFooterStyled>
      </>
    </DialogModalStyled>
  );
};
