import { createAction } from '@reduxjs/toolkit';
import { Accounts } from 'src/services';
import { createAsync } from 'src/utils/redux-helpers/create-async';

/* --= Methods =-- */
export const shopListActions = {
  navigateToShop: createAction<{ shopId: number }>('ShopList/NavigateToShop'),

  getAllShops: createAsync(
    'ShopList/GetAllShops',
    async ({ accountId }: { accountId: number }, { rejectWithValue }) => {
      try {
        const accountService = new Accounts();
        const accountResult = await accountService.getAccount(accountId);
        return { shops: accountResult?.shops };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
