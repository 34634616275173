import { ShopTypes } from '../types';
import { ApiServiceBase } from '../api-service-base';
import { ShopsMapper } from './shops-mapper';
import { Accounts } from '../accounts/accounts';
import { ShopFeatureTogglesFormGetProductsDeepTagPermissionToggleActionPayload } from '../types/shops';

export class Shops extends ApiServiceBase {
  accountService: Accounts;

  constructor() {
    super('shops');
    this.accountService = new Accounts();
  }

  async getShop(shopId: number): Promise<ShopTypes.Shop> {
    const url = `${this.serviceBaseUri}/${shopId}`;
    const response = await this.httpService.get({ url });
    return ShopsMapper.map(response);
  }

  async getShops(accountId: number): Promise<ShopTypes.Shop[]> {
    const account = await this.accountService.getAccount(accountId);
    const { shops = [] } = account || {};
    return shops;
  }

  async createShop({
    shopName,
    accountId,
    salesforceAccountId,
    shopType,
  }: ShopTypes.CreateShopArgs): Promise<ShopTypes.Shop> {
    const payload = {
      shopName,
      accountId,
      salesforceAccountId,
      shopType,
    };

    const response = await this.httpService.post({ url: this.serviceBaseUri, data: payload });
    return ShopsMapper.map(response);
  }

  async deleteShop(shopId: number): Promise<void> {
    const url = `${this.serviceBaseUri}/${shopId}`;
    await this.httpService.delete({ url });
  }

  async updateGeneralSettings({
    shopId,
    generalSettings,
  }: ShopTypes.UpdateShopArgs): Promise<ShopTypes.Shop> {
    const url = `${this.serviceBaseUri}/${shopId}/general`;
    const response = await this.httpService.patch({ url, data: generalSettings });
    return response.data;
  }

  async getGeneralSettings({ shopId }: ShopTypes.GetShopArgs): Promise<ShopTypes.Shop> {
    const url = `${this.serviceBaseUri}/${shopId}/general`;
    const response = await this.httpService.get({ url });
    return response.data;
  }

  async getFeatureToggles(shopId: number): Promise<ShopTypes.ShopFeatureToggles> {
    const response = await this.httpService.get({
      url: `${this.serviceBaseUri}/${shopId}/feature-toggles`,
    });

    return response.data;
  }

  async getProductsDeepTagsPermissionsToggle(
    shopId: number
  ): Promise<ShopFeatureTogglesFormGetProductsDeepTagPermissionToggleActionPayload> {
    const response = await this.httpService.get({
      url: `${this.serviceBaseUri}/${shopId}/enable-products-deep-tags`,
    });

    return response.data;
  }

  async updateProductsDeepTagsPermissionsToggle({
    shopId,
    isDeepTagsPermitted,
    isTaggingEnabled,
  }: {
    shopId: number;
    isDeepTagsPermitted: boolean;
    isTaggingEnabled: boolean;
  }): Promise<ShopFeatureTogglesFormGetProductsDeepTagPermissionToggleActionPayload> {
    const response = await this.httpService.put({
      url: `${this.serviceBaseUri}/${shopId}/enable-products-deep-tags`,
      data: { isDeepTagsPermitted, isTaggingEnabled },
    });
    return response.data;
  }

  async updateFeatureToggles({
    shopId,
    featureToggles,
  }: ShopTypes.UpdateFeatureToggles): Promise<ShopTypes.ShopFeatureToggles> {
    const response = await this.httpService.patch({
      url: `${this.serviceBaseUri}/${shopId}/feature-toggles`,
      data: featureToggles,
    });
    return response.data;
  }

  async getEnableUiTest(shopId: number, variantId?: string): Promise<ShopTypes.EnableUiTest> {
    const response = await this.httpService.get({
      url: `${this.serviceBaseUri}/${shopId}/enable-ui-test${
        variantId ? `?variantId=${variantId}` : ''
      }`,
    });
    return response.data;
  }

  async updateEnableUiTest({
    shopId,
    selectedVariantId,
    enableUiTest,
  }: ShopTypes.UpdateEnableUiTest): Promise<ShopTypes.EnableUiTest> {
    const response = await this.httpService.patch({
      url: `${this.serviceBaseUri}/${shopId}/enable-ui-test${
        selectedVariantId ? `?variantId=${selectedVariantId}` : ''
      }`,
      data: { enableUiTest },
    });
    return response.data;
  }

  async getUseKnn(shopId: number, variantId?: string): Promise<ShopTypes.UseKnn> {
    const response = await this.httpService.get({
      url: `${this.serviceBaseUri}/${shopId}/use-knn${variantId ? `?variantId=${variantId}` : ''}`,
    });
    return response.data;
  }

  async updateUseKnn({
    shopId,
    selectedVariantId,
    useKnn,
  }: ShopTypes.UpdateUseKnn): Promise<ShopTypes.UseKnn> {
    const response = await this.httpService.patch({
      url: `${this.serviceBaseUri}/${shopId}/use-knn${
        selectedVariantId ? `?variantId=${selectedVariantId}` : ''
      }`,
      data: { useKnn },
    });
    return response.data;
  }
}
