import * as mapper from './mapper';
import * as builder from './builder';
import { MerchandisingRuleTypes } from '../types';
import { ApiServiceBase } from '../api-service-base';
import {
  GetRulesArguments,
  GetRuleArguments,
  GetRulesReturnType,
  UpdateRuleArguments,
  CreateRuleArguments,
  DeleteRuleArguments,
  GetRulesOverviewArguments,
} from './types';
import { buildQueryParams } from '../../common/utils';

const mainRouteParam = 'merchandising-rules';

export class MerchandisingRules extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  async getRules({
    shopId,
    variantId,
    skip,
    limit,
    ...parameters
  }: GetRulesArguments): Promise<GetRulesReturnType> {
    const { entityId, ...rest } = parameters;

    let queryStringParams = buildQueryParams({ variantId, skip, limit, ...rest });

    if ('entityId' in parameters) {
      queryStringParams = queryStringParams === '' ? '?' : `${queryStringParams}&`;

      if (entityId) {
        queryStringParams = `${queryStringParams}entityId=${entityId}`;
      } else {
        queryStringParams = `${queryStringParams}entityId=`;
      }
    }

    const url = `${this.serviceBaseUri}/${shopId}/${mainRouteParam}${queryStringParams}`;
    const response = await this.httpService.get({ url });
    return mapper.rulesResponse(response);
  }

  async getRulesOverview({
    shopId,
    variantId,
    entityId,
  }: GetRulesOverviewArguments): Promise<MerchandisingRuleTypes.Overview> {
    const queryStringParams = buildQueryParams({ variantId, entityId });
    const url = `${this.serviceBaseUri}/${shopId}/${mainRouteParam}/overview${queryStringParams}`;
    const response = await this.httpService.get({ url });
    return mapper.mapOverview(response);
  }

  async getRule({
    shopId,
    ruleId,
    variantId,
  }: GetRuleArguments): Promise<MerchandisingRuleTypes.MerchandiseRule> {
    const url = `${this.serviceBaseUri}/${shopId}/${mainRouteParam}/${ruleId}${
      variantId ? `?variantId=${variantId}` : ''
    }`;
    const response = await this.httpService.get({ url });
    return mapper.ruleResponse(response);
  }

  async update({
    shopId,
    rule,
    variantId,
  }: UpdateRuleArguments): Promise<MerchandisingRuleTypes.MerchandiseRule> {
    const url = `${this.serviceBaseUri}/${shopId}/${mainRouteParam}/${rule.id}${
      variantId ? `?variantId=${variantId}` : ''
    }`;
    const data = builder.buildPartialRulePayload(rule);
    const response = await this.httpService.patch({ url, data });
    return mapper.ruleResponse(response);
  }

  async create({
    shopId,
    rule,
    variantId,
  }: CreateRuleArguments): Promise<MerchandisingRuleTypes.MerchandiseRule> {
    const url = `${this.serviceBaseUri}/${shopId}/${mainRouteParam}${
      variantId ? `?variantId=${variantId}` : ''
    }`;
    const data = builder.buildRulePayload(rule);
    const response = await this.httpService.post({ url, data });
    return mapper.ruleResponse(response);
  }

  async delete({ shopId, ruleId, variantId }: DeleteRuleArguments): Promise<void> {
    const url = `${this.serviceBaseUri}/${shopId}/${mainRouteParam}/${ruleId}${
      variantId ? `?variantId=${variantId}` : ''
    }`;
    await this.httpService.delete({ url });
  }
}

export const merchandisingRulesService = new MerchandisingRules();
