import styled from '@emotion/styled';
import { ColorValue } from 'src/styles';

export const BulletStyled = styled.div<{
  size: number;
  fillColor?: ColorValue;
}>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  background: ${props => props.fillColor || props.theme.palette.custom['gray-60']};
  margin-right: ${props => props.size}px;
`;
