import React, { useCallback } from 'react';
import { AvailableIcons, Button, ConfirmationDialog, Icon } from 'src/components-dummy';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

export interface UnsavedChangesModalProps {
  handleConfirm: (confirm: boolean) => void;
}

export const UnsavedChangesModal = ({ handleConfirm }: UnsavedChangesModalProps): JSX.Element => {
  const handleConfirmClick = useCallback(
    (hasConfirmed: boolean) => () => {
      handleConfirm(hasConfirmed);
    },
    []
  );

  return (
    <ConfirmationDialog onCancel={handleConfirmClick(false)}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Hold on</ConfirmationDialog.Title>
        <Icon name={AvailableIcons.Close} onClick={handleConfirmClick(false)} />
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        <ConfirmationDialog.ContentMainText>
          You have some unsaved changes <br />
          Are you sure you want to leave this page?
        </ConfirmationDialog.ContentMainText>
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={handleConfirmClick(true)}>
            Leave
          </Button>
          <Button variant='outlined' onClick={handleConfirmClick(false)}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
