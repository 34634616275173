import { AugmentedSearchGeneralSettings } from 'src/services';
import { EntityDiffSchema } from '../../types';

export const augmentedSearchGeneralSettingsDiffSchema: EntityDiffSchema<
  AugmentedSearchGeneralSettings,
  AugmentedSearchGeneralSettings
> = {
  redirectOnItems: {
    displayName: 'Apply Auto Redirect',
    getValue({ entity }: { entity: AugmentedSearchGeneralSettings }): string {
      return entity.redirectOnItems ? 'Auto Suggest Modal And Results Page' : 'Auto Suggest Modal';
    },
  },
};
