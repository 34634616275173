import React from 'react';

import { Rule, RuleGroup } from '../../../types';

import { FieldSourceDropdown } from '../FieldSourceDropdown';
import { FieldDropdown } from '../FieldDropdown';
import { OperatorDropdown } from '../OperatorDropdown';
import { RuleValue } from '../RuleValue';
import { AvailableIcons } from '../../../../../components-dummy';

import { ParseJoiValidateResponse } from '../../../../../utils';

import { OnDeleteRuleGroupCallback } from '../types';

import { IconStyled } from './Icon.styles';
import { IconWrapperStyled } from './IconWrapper.styles';
import { CollectionRuleStyled } from './CollectionRule.styles';
import { IconsWrapperStyled } from './IconsWrapper.styles';
import { ShopDataField } from '../../../../../services';

interface CollectionRuleProps {
  shopId: number;
  index: number;
  data: Rule;
  ruleGroupId: string;
  onDeleteRuleGroup: OnDeleteRuleGroupCallback;
  addRule: () => void;
  deleteRule: (index: number) => void;
  onChange: (ruleIndex: number, rule: Rule) => void;
  dataFields: ShopDataField[] | undefined;
  isOnlyOneRule: boolean;
  errors: ParseJoiValidateResponse<{ ruleGroups: RuleGroup[] }>;
}

export const CollectionRule: React.FC<CollectionRuleProps> = ({
  index,
  data,
  ruleGroupId,
  onDeleteRuleGroup,
  addRule,
  deleteRule,
  onChange,
  dataFields,
  shopId,
  isOnlyOneRule,
  errors,
}) => {
  const onDeleteRule = (e: React.MouseEvent) => {
    e.stopPropagation();
    return isOnlyOneRule ? onDeleteRuleGroup(ruleGroupId) : deleteRule(index);
  };

  const onChangeRule = (rule: Rule) => {
    onChange(index, rule);
  };

  return (
    <CollectionRuleStyled>
      <FieldSourceDropdown data={data} onChange={onChangeRule} errors={errors} />
      <FieldDropdown data={data} onChange={onChangeRule} dataFields={dataFields} errors={errors} />
      <OperatorDropdown
        data={data}
        onChange={onChangeRule}
        dataFields={dataFields}
        errors={errors}
      />
      <RuleValue
        data={data}
        shopId={shopId}
        onChange={onChangeRule}
        dataFields={dataFields}
        errors={errors}
      />
      <IconsWrapperStyled>
        <IconWrapperStyled onClick={addRule}>
          <IconStyled name={AvailableIcons.Plus} />
        </IconWrapperStyled>
        <IconWrapperStyled onClick={onDeleteRule}>
          <IconStyled name={AvailableIcons.TrashCan} />
        </IconWrapperStyled>
      </IconsWrapperStyled>
    </CollectionRuleStyled>
  );
};
