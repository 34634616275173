import React, { useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import { ParseJoiValidateResponse } from 'src/utils';
import {
  TextBox,
  Typography,
  TypographyType,
  TypographyVariant,
  Icon,
  InfoModal,
  Switch,
} from 'src/components-dummy';
import { AvailableIcons } from 'src/components-dummy/Icon/AvailableIcons';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { DiscoveryBannerGeneralSettings } from 'src/services/src/service/types/syte-products/discovery-banner';
import cameraIcon from '../../Assets/images/cameraIcon.jpg';
import { DiscoveryBannerSettingsRecord } from '../../types';
import { handleUpdateColor } from '../../colorToHex';

import '../../DiscoveryBannerSettings.scss';

export interface CameraIconProps {
  discoveryBannerSettings: DiscoveryBannerGeneralSettings['generalSettings'];
  onChange: (args: Partial<DiscoveryBannerGeneralSettings['generalSettings']['icon']>) => void;
  disabled?: boolean;
  errors: ParseJoiValidateResponse<DiscoveryBannerSettingsRecord>;
}

export const CameraIcon = ({
  discoveryBannerSettings,
  onChange,
  disabled = false,
  errors,
}: CameraIconProps): JSX.Element | null => {
  const [showIconColorPicker, setShowIconColorPicker] = useState(false);
  const [showCameraIcon, setModalCameraIconIsOpened] = useState(false);
  const onCameraIconModalClose = () => {
    setModalCameraIconIsOpened(false);
  };
  const iconColorRef = useRef(null);
  useOnClickOutside(iconColorRef, () => {
    setShowIconColorPicker(false);
  });
  return (
    <div className='discovery-banner-settings-container-wrapper'>
      <div className='discovery-banner-settings-container-title'>
        <Typography variant={TypographyVariant.MediumBold} type={TypographyType.Body}>
          Camera icon
        </Typography>
        <button
          type='button'
          className='discovery-banner-camera-icon-modal'
          onClick={() => setModalCameraIconIsOpened(true)}
        >
          <Typography
            className='discovery-banner-camera-icon-tooltip'
            variant={TypographyVariant.SmallRegular}
          >
            What is camera icon
          </Typography>
          <Icon
            className='discovery-banner-camera-icon-tooltip-icon'
            name={AvailableIcons.TooltipQuestionmarkBlue}
          />
        </button>
        <InfoModal
          isOpened={showCameraIcon}
          className='camera-icon-example-modal'
          closeBtnTxt='Close'
          onClickOutside={onCameraIconModalClose}
          title='What is camera icon?'
        >
          <img
            className='camera-icon-example-modal-image'
            src={cameraIcon}
            alt='cameraIconExample'
          />
        </InfoModal>
      </div>
      <div className='discovery-banner-settings-container icon-settings'>
        <div className='camera-icon-show-toggle'>
          <Switch
            checked={discoveryBannerSettings.icon.showIcon}
            onChange={() => {
              onChange({
                showIcon: !discoveryBannerSettings.icon.showIcon,
              });
            }}
            className='enable-camera-icon-toggle'
            disabled={disabled}
          >
            <Switch.TitleTemplate className='enable-camera-icon-toggle-text'>
              <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
                Show Camera Icon
              </Typography>
            </Switch.TitleTemplate>
          </Switch>
        </div>
        <div className='icon-color' ref={iconColorRef}>
          <TextBox
            label='Icon Color'
            placeholder={discoveryBannerSettings.icon.iconColor}
            error={!!errors.name}
            className='banner-background-color'
            value={discoveryBannerSettings.icon.iconColor}
            fullWidth
            onChange={iconColor => {
              onChange({ iconColor });
            }}
            disabled={
              discoveryBannerSettings.icon.showIcon
                ? disabled
                : !discoveryBannerSettings.icon.showIcon
            }
          >
            <button
              type='button'
              aria-label='icon-color-mock'
              className='icon-color-mock'
              onClick={() => setShowIconColorPicker(!showIconColorPicker)}
              style={{
                backgroundColor: discoveryBannerSettings.icon.iconColor,
              }}
              disabled={
                discoveryBannerSettings.icon.showIcon
                  ? disabled
                  : !discoveryBannerSettings.icon.showIcon
              }
            />
            {showIconColorPicker && (
              <ChromePicker
                className='icon-color-picker'
                color={discoveryBannerSettings.icon.iconColor}
                onChange={updatedColor => {
                  handleUpdateColor(updatedColor, newColor => {
                    onChange({ iconColor: newColor });
                  });
                }}
              />
            )}
          </TextBox>
        </div>
      </div>
    </div>
  );
};
