import React, { forwardRef, useImperativeHandle, useCallback } from 'react';
import { Button } from '../Button';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import { FileCards } from './components/FileCards';
import { DEFAULT_MAX_SIZE_MB } from './constants';
import { UploadButtonErrorMessageStyled } from './FileUploader.styles';
import { FileUploadProps } from './types';
import { useFileUploader } from './useFileUploader';

export interface FileUploadButtonApiRef {
  open(): void;
}

export const FileUploadButton = forwardRef<FileUploadButtonApiRef, FileUploadProps>(
  (
    {
      accept,
      children,
      className,
      disabled,
      fileCardIconName,
      maxFiles,
      maxSizeMb: maxSizeMbProp,
      onChange,
      onError: onErrorProp,
      selectedFiles,
      onClick: onClickExternal,
    }: FileUploadProps,
    ref
  ): JSX.Element => {
    const maxSizeMb = maxSizeMbProp || DEFAULT_MAX_SIZE_MB;

    const { showUploader, fileErrorMessage, getRootProps, getInputProps, open } = useFileUploader({
      accept,
      disabled,
      maxFiles,
      maxSizeMb,
      onChange,
      onError: onErrorProp,
      selectedFiles,
    });

    const onClick = useCallback(
      () => (onClickExternal ? onClickExternal() : open()),
      [onClickExternal, open]
    );

    useImperativeHandle(
      ref,
      () => ({
        open,
      }),
      [open]
    );

    return (
      <div className={className}>
        {showUploader ? (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Button onClick={onClick}>{children}</Button>
            <UploadButtonErrorMessageStyled visible={!!fileErrorMessage}>
              <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Body}>
                {fileErrorMessage}
              </Typography>
            </UploadButtonErrorMessageStyled>
          </div>
        ) : (
          <FileCards
            disabled={disabled}
            fileCardIconName={fileCardIconName}
            onChange={onChange}
            selectedFiles={selectedFiles}
          />
        )}
      </div>
    );
  }
);
