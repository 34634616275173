import React from 'react';
import { TypographyType, TypographyVariant } from '../../../../components-dummy';
import { ProductSection } from './ProductSection';
import { ValueToDisplayByDataType } from './ValueToDisplayByDataType';
import { ProductDataStyled, TitleStyled } from './ProductDetails.styled';

const accordionId = 'product-details';

interface Props {
  details?: Record<string, any>;
}

export const ProductDetailsSection = ({ details }: Props) => {
  return (
    <ProductSection title='Product Details' accordionId={accordionId}>
      {Object.entries(details).map(([key, value]) => (
        <ProductDataStyled key={key}>
          <TitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
            {key}
          </TitleStyled>
          <ValueToDisplayByDataType value={value} />
        </ProductDataStyled>
      ))}
    </ProductSection>
  );
};
