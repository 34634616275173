import React from 'react';
import classNames from 'classnames';
import { useFindComponentByType } from '../hooks';
import { FormCardButton } from './components/FormCardButton';
import { FormCardContent } from './components/FormCardContent';
import { FormCardTitle } from './components/FormCardTitle';
import { FormCardContainerStyled, FormCardHeaderStyled } from './FormCard.styles';

interface FormCardProps {
  children: JSX.Element[];
  className?: string;
  stickyHeader?: boolean;
}

export function FormCard({ children, className, stickyHeader }: FormCardProps): JSX.Element {
  const cardTitle = useFindComponentByType({ type: FormCardTitle, children });
  const cardButton = useFindComponentByType({ type: FormCardButton, children });
  const cardContent = useFindComponentByType({ type: FormCardContent, children });

  return (
    <FormCardContainerStyled
      className={classNames('syte-form-card', className)}
      stickyHeader={!!stickyHeader}
    >
      <FormCardHeaderStyled sticky={!!stickyHeader}>
        {cardTitle}
        {cardButton}
      </FormCardHeaderStyled>
      {cardContent}
    </FormCardContainerStyled>
  );
}

FormCard.Title = FormCardTitle;
FormCard.Button = FormCardButton;
FormCard.Content = FormCardContent;
