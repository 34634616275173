import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { useAppSelector } from 'src/hooks';
import { DeepTagReportSFTPSettingsModal } from 'src/components-bl';
import { RoutedComponentProps } from 'src/app-routes';
import { deepTagReportSFTPSettingsModalContainerActions } from './DeepTagReportSFTPSettingsModalContainer.actions';

type DeepTagReportsContainerProps = RoutedComponentProps;

type DeepTagReportSFTPSettingsModalContainerProps = DeepTagReportsContainerProps;

export const DeepTagReportSFTPSettingsModalContainer = ({
  permittedRouteMap,
}: DeepTagReportSFTPSettingsModalContainerProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const { shopId, configuration, sftpSettings, lexiconsMetadataList } = useAppSelector(state => {
    return {
      shopId: state.shop.current?.shopId,
      configuration: state.deepTagReports.configuration,
      sftpSettings: state.shopSFTPSettings.settings,
      lexiconsMetadataList: state.lexicon.lexiconsMetadataList,
    };
  }, shallowEqual);

  const onModalClose = () => {
    if (permittedRouteMap.deepTagReports && shopId) {
      dispatch(
        deepTagReportSFTPSettingsModalContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.deepTagReports.path, { shopId }),
        })
      );
    }
  };

  useEffect(() => {
    if (shopId) {
      dispatch(
        deepTagReportSFTPSettingsModalContainerActions.getShopSFTPSettings({
          shopId,
        })
      );
    }
  }, [shopId]);

  useEffect(() => {
    return () => {
      dispatch(deepTagReportSFTPSettingsModalContainerActions.resetSFTPSettings());
    };
  }, []);

  return shopId && configuration && lexiconsMetadataList ? (
    <DeepTagReportSFTPSettingsModal
      shopId={shopId}
      dispatch={dispatch}
      onCancel={onModalClose}
      configuration={configuration}
      sftpSettings={sftpSettings}
      languages={lexiconsMetadataList}
    />
  ) : null;
};
