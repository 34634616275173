import {
  StoriesSettingsAction,
  StoriesSettingsActions,
} from '../components-bl/Stories/Actions/storiesSettingsActions';
import { IEnhancedStrategy } from '../types';

interface StoriesSettingsState {
  numberOfResultsToDisplay: number | null;
  storiesStrategy: IEnhancedStrategy | null;
}

const initialState: StoriesSettingsState = {
  numberOfResultsToDisplay: null,
  storiesStrategy: null,
};

export function storiesSettingsReducer(
  state: StoriesSettingsState = initialState,
  action?: StoriesSettingsAction
): StoriesSettingsState {
  switch (action?.type) {
    case StoriesSettingsActions.UpdateStoriesSettings:
    case StoriesSettingsActions.ResolveStoriesSettings:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
