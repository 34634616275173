import { useMemo } from 'react';
import { useAppSelector } from '../../hooks';
import { StoriesStrategy } from '../../types';
import { Dispatch } from '../../components-dummy/types';
import { StoriesService } from '../../services/src/service/stories/stories-service';
import { useGetStoriesSettings } from '../../components-bl/Stories/useGetStoriesSettings';

interface Props {
  shopId: number;
  dispatch: Dispatch;
}

export const useSocialDiscoveryPageGuard = ({ shopId, dispatch }: Props) => {
  const currentStrategy = useAppSelector(state => state.storiesSettings.storiesStrategy);
  const service = useMemo(() => new StoriesService(shopId), [shopId]);
  useGetStoriesSettings({ service, dispatch });
  return currentStrategy?.name === StoriesStrategy.socialContent;
};
