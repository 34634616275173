import { useCallback, useState } from 'react';
import { Dispatch } from 'src/components-bl/types';
import { useValidateMerchandisingRuleLimitActions } from './use-validate-merchandising-rule-limit.actions';
import { MAX_RULES } from './constants';

export function useValidateMerchandisingRuleLimit({
  shopId,
  selectedVariantId,
  createMerchandisingRule,
  dispatch,
}: {
  shopId: number;
  selectedVariantId?: string;
  createMerchandisingRule: () => void;
  dispatch: Dispatch;
}): {
  showRuleLimitReachedDialog: boolean;
  onRuleLimitDialogClose: () => void;
  onCreateRuleButtonClick: () => void;
  validateMerchandisingRuleLimit: () => void;
} {
  const [isMaxRuleLimitReached, setIsMaxRuleLimitReached] = useState(false);
  const [showRuleLimitReachedDialog, setShowRuleLimitReachedDialog] = useState(false);

  const validateMerchandisingRuleLimit = useCallback(async () => {
    const totalCount = await (
      dispatch(
        useValidateMerchandisingRuleLimitActions.getRulesCountForValidation({
          shopId,
          variantId: selectedVariantId,
          skip: 0,
          limit: 1,
        })
      ) as any
    ).unwrap();

    setIsMaxRuleLimitReached(totalCount >= MAX_RULES);
  }, [shopId, selectedVariantId, dispatch, setIsMaxRuleLimitReached]);

  const onRuleLimitDialogClose = useCallback(() => {
    setShowRuleLimitReachedDialog(false);
  }, [setShowRuleLimitReachedDialog]);

  const onCreateRuleButtonClick = useCallback(() => {
    if (isMaxRuleLimitReached) {
      setShowRuleLimitReachedDialog(true);
    } else {
      createMerchandisingRule();
    }
  }, [isMaxRuleLimitReached, createMerchandisingRule]);

  return {
    showRuleLimitReachedDialog,
    onRuleLimitDialogClose,
    onCreateRuleButtonClick,
    validateMerchandisingRuleLimit,
  };
}
