import { experimentService } from 'src/services';
import { Dispatch } from '../../../types';
import {
  ExperimentListActionTypes,
  ExperimentListActionPayload,
  ExperimentListErrorActionPayload,
  ExperimentListGetExperimentsRequestAction,
  ExperimentListGetExperimentsSuccessAction,
  ExperimentListGetExperimentsErrorAction,
} from './types';

const experimentListActions = {
  getExperimentsRequest(
    payload: ExperimentListActionPayload
  ): ExperimentListGetExperimentsRequestAction {
    return {
      type: ExperimentListActionTypes.GetExperimentsRequest,
      payload,
    };
  },

  getExperimentsSuccess(
    payload: ExperimentListActionPayload
  ): ExperimentListGetExperimentsSuccessAction {
    return {
      type: ExperimentListActionTypes.GetExperimentsSuccess,
      payload,
    };
  },

  getExperimentsError(
    payload: ExperimentListErrorActionPayload
  ): ExperimentListGetExperimentsErrorAction {
    return {
      type: ExperimentListActionTypes.GetExperimentsError,
      payload,
    };
  },
};

export const experimentListActionMethods = {
  getExperiments(payload: ExperimentListActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(experimentListActions.getExperimentsRequest(payload));
        const experiments = await experimentService.getExperiments({ ...payload, slim: true });
        dispatch(experimentListActions.getExperimentsSuccess({ ...payload, experiments }));
      } catch (error) {
        dispatch(experimentListActions.getExperimentsError({ error }));
      }
    };
  },
};
