import React, { KeyboardEvent, useMemo, useState } from 'react';
import { ListSubheader } from '@mui/material';
import { useFilterItems } from 'src/hooks';
import { MenuItem, SelectOnChangeEvent, SelectType } from 'src/components-dummy';
import { ShopDataField } from 'src/services';
import { DataFieldSelectStyled } from './DataSourceSection.styles';
import { SearchInputStyled } from '../shared.styles';

interface DataFieldSelectProps {
  sourceField: string | undefined;
  onChange: (newSourceField: string) => void;
  availableDataFields: ShopDataField[] | undefined;
  error: string | undefined;
  disabled: boolean;
}

export const DataFieldSelect = ({
  sourceField,
  onChange,
  availableDataFields,
  error,
  disabled,
}: DataFieldSelectProps): JSX.Element => {
  const [searchText, setSearchText] = useState('');

  const resetSearchText = () => setSearchText('');

  const onSourceFieldChange: SelectOnChangeEvent = event => {
    onChange(event.target.value);
  };

  const { filteredItems: filteredDataFields } = useFilterItems({
    items: availableDataFields || [],
    searchableFields: ['name'],
    idField: 'name',
    filterText: searchText,
  });

  const dataFieldsOptions = useMemo(
    () =>
      filteredDataFields.map(dataField => ({
        text: dataField.displayName,
        value: dataField.name,
      })) || [],
    [filteredDataFields]
  );
  const onSearchKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Escape') {
      // Prevents autoselecting item while typing (default Select behaviour)
      event.stopPropagation();
    }
  };

  return (
    <DataFieldSelectStyled
      type={SelectType.Primary}
      label='Choose catalogue field'
      placeholder='Select field...'
      value={sourceField}
      onChange={onSourceFieldChange}
      isError={!!error}
      errorMessage={error}
      onClose={resetSearchText}
      autoFocus={false}
      disabled={disabled}
    >
      <ListSubheader>
        <SearchInputStyled
          value={searchText}
          onChange={setSearchText}
          placeholder='Search Syte Field'
          className='data-fields-search-input'
          onKeyDown={onSearchKeydown}
        />
      </ListSubheader>
      {dataFieldsOptions.map(({ text, value }) => (
        <MenuItem key={value} value={value}>
          {text}
        </MenuItem>
      ))}
    </DataFieldSelectStyled>
  );
};
