import React, { ReactNode } from 'react';
import { useAppSelector } from 'src/hooks';
import syteIcon from '../../images/syteIcon.svg';
import { DynamicContent } from './components';
import {
  LoginLayoutStyled,
  FormSideWrapperStyled,
  DynamicContentSideWrapperStyled,
  LogoWrapperStyled,
  LogoImgStyled,
  FormWrapperStyled,
} from './Login.styles';

export interface LoginLayoutProps {
  children: ReactNode | ReactNode[];
  // eslint-disable-next-line react/require-default-props
  className?: string;
}

export const LoginLayout = ({ children, className }: LoginLayoutProps): JSX.Element => {
  const metadata = useAppSelector(state => state.meta.public);

  return (
    <LoginLayoutStyled className={className}>
      <FormSideWrapperStyled>
        <LogoWrapperStyled>
          <a href='http://syte.ai/'>
            <LogoImgStyled src={syteIcon} alt='Syte Logo' />
          </a>
        </LogoWrapperStyled>
        <FormWrapperStyled>{children}</FormWrapperStyled>
      </FormSideWrapperStyled>
      <DynamicContentSideWrapperStyled>
        <DynamicContent metadata={metadata} />
      </DynamicContentSideWrapperStyled>
    </LoginLayoutStyled>
  );
};
