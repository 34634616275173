export enum IndicatorColor {
  SyteBlue = 'syte-blue',
  Orange = 'syte-orange',
  Violet = 'syte-violet',
  Black = 'black-text',
  Gray = 'main_gray',
  White = 'white',
  DarkPurple = 'experiments-color-1',
  Blue = 'experiments-color-2',
  Lilac = 'experiments-color-3',
  Red = 'experiments-color-4',
  Yellow = 'experiments-color-5',
  Pink = 'experiments-color-6',
  Green = 'syte-positive',
}
