import { ApiServiceBase } from '../api-service-base';
import { SearchAccountsResponse } from './types';

export class SalesforceService extends ApiServiceBase {
  constructor() {
    super('salesforce');
  }

  async searchAccounts({
    cancellationSignal,
    ...params
  }: {
    term: string;
    skip: number;
    limit: number;
    cancellationSignal?: AbortSignal;
  }): Promise<SearchAccountsResponse> {
    const url = `${this.serviceBaseUri}/accounts`;

    const response = await this.httpService.get({
      url,
      requestConfig: { params, signal: cancellationSignal },
    });

    return response.data;
  }
}

export const salesforceService = new SalesforceService();
