import React, { useCallback, useMemo, useRef, useState } from 'react';
import { startCase, toLower } from 'lodash';
import { MerchandisingRuleTypes } from 'src/services';
import {
  Select,
  TypographyType,
  TypographyVariant,
  SelectType,
  Icon,
  AvailableIcons,
  SelectOnChangeEvent,
  MenuItem,
} from 'src/components-dummy';

import { LinkButton } from 'src/components-dummy/LinkButton';
import { Dispatch } from 'src/components-bl';
import { UseDataFieldsLookupTableArgumentsReturnType } from 'src/components-bl/MerchandisingRules/components/useDataFieldsLookupTable';
import { DataFieldConditionDataType } from 'src/components-bl/MerchandisingRules/components/DataFieldsCondition/DataFieldsCondition.types';
import { ruleActionsWithWeight } from 'src/components-bl/MerchandisingRules/components/constants';
import { ConditionsGroup } from '../../ConditionsGroup/ConditionsGroup';
import { ConditionFirstRowStyled, ConditionText } from '../RuleFormConditionsSection.styles';
import { RuleDraft, RuleDraftCondition } from '../../../MerchandisingRuleForm.config';
import { ruleFormMappers } from '../../../MerchandisingRuleForm.mappers';
import { WeightSliderPopover } from '../WeightSliderPopover/WeightSliderPopover';
import { ErrorLabelStyled } from '../../../MerchandisingRuleForm.styles';
import { Conditions } from './Conditions';
import { DeleteAllButtonStyled } from './ActionAndConditionsIncludeGroup.styles';
import { PinMultipleItemsModal } from '../PinToPosition/PinMultipleItemsModal/PinMultipleItemsModal';
import { insertAndReorderPins } from '../PinToPosition/helpers';
import { MAX_POSITION_VALUE } from '../PinToPosition/constants';
import { PinToPositionApiHandle } from '../PinToPosition/PinToPositionRulesInputs';

export interface ActionAndConditionsIncludeGroupProps
  extends UseDataFieldsLookupTableArgumentsReturnType {
  weight: number;
  action: MerchandisingRuleTypes.RuleAction;
  actions: MerchandisingRuleTypes.RuleAction[];
  conditions: RuleDraftCondition[];
  conditionTypes: MerchandisingRuleTypes.MerchandisingRuleSubType[];
  shopId: number;
  showAddButton?: boolean;
  isAiTagsEnabled: boolean;
  onFieldChange: (
    data: Partial<Pick<RuleDraft, 'action' | 'weight' | 'redirectRule' | 'subRules'>>
  ) => void;
  dispatch: Dispatch;
  errors: any; // TODO
  isReadOnly?: boolean;
  redirectRule?: MerchandisingRuleTypes.IMerchandisingRuleRedirectRule | null;
}

export const ActionAndConditionsIncludeGroup = React.memo(
  ({
    actions,
    weight,
    action,
    shopId,
    conditions,
    dataFieldsLookupTable,
    getDataFieldByName,
    conditionTypes,
    errors,
    showAddButton,
    isReadOnly,
    isAiTagsEnabled,
    onFieldChange,
    dispatch,
    redirectRule,
  }: ActionAndConditionsIncludeGroupProps): JSX.Element => {
    const [isModalOpened, setIsModalOpened] = useState(false);

    const p2pApiHandle = useRef<PinToPositionApiHandle | null>(null);

    const isRedirectRuleEnabled = action === MerchandisingRuleTypes.RuleAction.Redirect;
    const isPinToPositionRuleEnabled = action === MerchandisingRuleTypes.RuleAction.PinToPosition;

    const actionOptions = useMemo(() => {
      const options = actions.map(actionValue => {
        return (
          <MenuItem key={actionValue} value={actionValue}>
            {startCase(toLower(actionValue))}
          </MenuItem>
        );
      });

      return options;
    }, [actions]);

    const skipSkus = useMemo(() => {
      if (!isPinToPositionRuleEnabled) {
        return [];
      }

      return conditions
        .filter(condition => !!condition.values[0])
        .map(condition => condition.values[0]) as string[];
    }, [conditions, isPinToPositionRuleEnabled]);

    const onConditionChange = (changedCondition: DataFieldConditionDataType) => {
      const changedIndex = conditions.findIndex(
        condition => condition.tempId === changedCondition.tempId
      );

      const updatedConditions = [...conditions];
      updatedConditions[changedIndex] = changedCondition;

      onFieldChange({ subRules: updatedConditions });
    };

    const updateActionAndInitialRules = useCallback(
      (newAction: MerchandisingRuleTypes.RuleAction) => {
        const isNewActionRedirect = newAction === MerchandisingRuleTypes.RuleAction.Redirect;
        const isNewActionPinToPosition =
          newAction === MerchandisingRuleTypes.RuleAction.PinToPosition;

        let updatedState: Partial<RuleDraft> = {};

        if (!(isNewActionRedirect || isNewActionPinToPosition)) {
          updatedState = {
            action: newAction,
            subRules: [ruleFormMappers.createEmptyCondition()],
            redirectRule: null,
          };
        }

        if (isNewActionRedirect) {
          updatedState = {
            action: MerchandisingRuleTypes.RuleAction.Redirect,
            subRules: [],
          };
        }
        if (isNewActionPinToPosition) {
          updatedState = {
            action: MerchandisingRuleTypes.RuleAction.PinToPosition,
            subRules: [ruleFormMappers.createEmptyPinToPositionCondition()],
            redirectRule: null,
          };
        }

        onFieldChange(updatedState);
      },
      [onFieldChange]
    );

    const onActionChange: SelectOnChangeEvent = event => {
      const newAction = event.target.value as MerchandisingRuleTypes.RuleAction;

      updateActionAndInitialRules(newAction);
    };

    const onAddNewCondition = () => {
      const updatedConditions = isPinToPositionRuleEnabled
        ? [...conditions, ruleFormMappers.createEmptyPinToPositionCondition()]
        : [...conditions, ruleFormMappers.createEmptyCondition()];
      onFieldChange({ subRules: updatedConditions });
    };

    const onDeleteCondition = (conditionToDelete: RuleDraftCondition) => {
      const updatedConditions = conditions.filter(
        condition => condition.tempId !== conditionToDelete.tempId
      );

      onFieldChange({ subRules: updatedConditions });
    };

    const onDeleteAllConditions = useCallback(() => {
      updateActionAndInitialRules(MerchandisingRuleTypes.RuleAction.PinToPosition);
    }, [updateActionAndInitialRules]);

    const closeModal = useCallback(() => {
      setIsModalOpened(false);
    }, [setIsModalOpened]);

    const openModal = useCallback(() => {
      setIsModalOpened(true);
    }, [setIsModalOpened]);

    const onAddMultipleItems = useCallback(
      (items: RuleDraftCondition[]) => {
        const conditionsWithValues = conditions.filter(
          ({ values }) => values.length === 1 && (values[0] as string).length > 0
        );

        const updatedConditions = insertAndReorderPins({
          pinsToInsert: items,
          previousConditions: conditionsWithValues,
          maxLimitOfItems: MAX_POSITION_VALUE,
        });

        onFieldChange({ subRules: updatedConditions });

        p2pApiHandle.current?.fetchProducts?.(updatedConditions);
        closeModal();
      },
      [conditions, closeModal, onFieldChange]
    );

    const showWeightInput = ruleActionsWithWeight.has(action);

    const shouldShowAddButton = !isReadOnly && showAddButton;

    return (
      <>
        <ConditionsGroup>
          <ConditionsGroup.Title>Action</ConditionsGroup.Title>
          <ConditionsGroup.SubTitle>What should this rule do</ConditionsGroup.SubTitle>

          {errors?.subRules?.message && (
            <ErrorLabelStyled>{errors?.subRules?.message}</ErrorLabelStyled>
          )}

          <ConditionsGroup.Content>
            <ConditionFirstRowStyled>
              <ConditionText type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                I want to
              </ConditionText>
              <Select
                type={SelectType.Menu}
                value={action}
                placeholder='Select action'
                onChange={onActionChange}
                disabled={isReadOnly}
              >
                {actionOptions}
              </Select>

              {showWeightInput && (
                <WeightSliderPopover
                  onFieldChange={onFieldChange}
                  weight={weight}
                  key={weight}
                  isReadOnly={isReadOnly}
                />
              )}

              {!isReadOnly && isPinToPositionRuleEnabled && (
                <DeleteAllButtonStyled
                  disabled={conditions.length === 0}
                  onClick={onDeleteAllConditions}
                  startIcon={AvailableIcons.TrashCan}
                  variant='tertiary'
                >
                  Delete All
                </DeleteAllButtonStyled>
              )}
            </ConditionFirstRowStyled>
            <Conditions
              conditions={conditions}
              errors={errors}
              shopId={shopId}
              dataFieldsLookupTable={dataFieldsLookupTable}
              getDataFieldByName={getDataFieldByName}
              conditionTypes={conditionTypes}
              dispatch={dispatch}
              isReadOnly={isReadOnly}
              onConditionChange={onConditionChange}
              onDeleteCondition={onDeleteCondition}
              isRedirectRuleEnabled={isRedirectRuleEnabled}
              isPinToPositionRuleEnabled={isPinToPositionRuleEnabled}
              onFieldChange={onFieldChange}
              redirectRule={redirectRule}
              isAiTagsEnabled={isAiTagsEnabled}
              p2pApiRef={p2pApiHandle}
            />

            {shouldShowAddButton && (
              <LinkButton onClick={onAddNewCondition}>
                <Icon name={AvailableIcons.Plus} /> Add
              </LinkButton>
            )}
            {shouldShowAddButton && isPinToPositionRuleEnabled && (
              <LinkButton onClick={openModal}>
                <Icon name={AvailableIcons.Plus} /> Add multiple items
              </LinkButton>
            )}
          </ConditionsGroup.Content>
        </ConditionsGroup>
        <PinMultipleItemsModal
          open={isModalOpened}
          onClose={closeModal}
          onAddItems={onAddMultipleItems}
          skipSkus={skipSkus}
        />
      </>
    );
  }
);
