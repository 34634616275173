import React from 'react';
import { AvailableIcons } from 'src/components-dummy';
import {
  IconStyled,
  LexiconCardContainerStyled,
  LexiconCardContentStyled,
  LexiconCardFooterStyled,
} from './LexiconCard.style';

export interface LexiconCardProps {
  displayName: string;
  iconName?: string;
  color: string;
  onClick: () => void;
}

export const LexiconCard = ({
  displayName,
  iconName,
  color,
  onClick,
}: LexiconCardProps): JSX.Element => {
  return (
    <LexiconCardContainerStyled onClick={onClick}>
      <LexiconCardContentStyled color={color}>
        {iconName && <IconStyled name={iconName as AvailableIcons} />}
      </LexiconCardContentStyled>
      <LexiconCardFooterStyled>{displayName || 'Not supported locale'}</LexiconCardFooterStyled>
    </LexiconCardContainerStyled>
  );
};
