import { Dispatch } from 'src/components-bl/types';
import { Experiment, experimentService, HttpStatusCode, APITypes } from 'src/services';
import {
  EditExperimentContainerActionTypes,
  EditExperimentContainerGetExperimentRequestActionPayload,
  EditExperimentContainerGetExperimentRequestAction,
  EditExperimentContainerGetExperimentSuccessActionPayload,
  EditExperimentContainerGetExperimentSuccessAction,
  EditExperimentContainerGetExperimentErrorActionPayload,
  EditExperimentContainerGetExperimentErrorAction,
  EditExperimentContainerVariantPanelClosePanelAction,
  EditExperimentContainerNavigateToActionPayload,
  EditExperimentContainerNavigateToAction,
  EditExperimentContainerClearCurrentExperimentAction,
} from './types';

const editExperimentContainerActions = {
  getExperimentRequest(
    payload: EditExperimentContainerGetExperimentRequestActionPayload
  ): EditExperimentContainerGetExperimentRequestAction {
    return {
      type: EditExperimentContainerActionTypes.GetExperimentRequest,
      payload,
    };
  },

  getExperimentSuccess(
    payload: EditExperimentContainerGetExperimentSuccessActionPayload
  ): EditExperimentContainerGetExperimentSuccessAction {
    return {
      type: EditExperimentContainerActionTypes.GetExperimentSuccess,
      payload,
    };
  },

  getExperimentError(
    payload: EditExperimentContainerGetExperimentErrorActionPayload
  ): EditExperimentContainerGetExperimentErrorAction {
    return {
      type: EditExperimentContainerActionTypes.GetExperimentError,
      payload,
    };
  },

  closeVariantPanel(): EditExperimentContainerVariantPanelClosePanelAction {
    return {
      type: EditExperimentContainerActionTypes.CloseVariantPanel,
      payload: undefined,
    };
  },

  navigateTo(
    payload: EditExperimentContainerNavigateToActionPayload
  ): EditExperimentContainerNavigateToAction {
    return {
      type: EditExperimentContainerActionTypes.NavigateTo,
      payload,
    };
  },
  clearCurrentExperiment(): EditExperimentContainerClearCurrentExperimentAction {
    return {
      type: EditExperimentContainerActionTypes.ClearCurrentExperiment,
      payload: undefined,
    };
  },
};

export const editExperimentContainerActionMethods = {
  getExperiment({
    shopId,
    id,
    pathToNavigateOnFail,
  }: EditExperimentContainerGetExperimentRequestActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(editExperimentContainerActions.getExperimentRequest({ shopId, id }));
        const experiment = (await experimentService.getExperiment({
          shopId,
          id,
          slim: false,
        })) as Experiment;
        dispatch(editExperimentContainerActions.getExperimentSuccess({ experiment }));
      } catch (error) {
        const apiError = error as APITypes.ApiError;
        if (
          (apiError.code === HttpStatusCode.NotFound ||
            apiError.code === HttpStatusCode.BadRequest) &&
          pathToNavigateOnFail
        ) {
          dispatch(editExperimentContainerActions.navigateTo({ navigateTo: pathToNavigateOnFail }));
        }

        dispatch(editExperimentContainerActions.getExperimentError({ error }));
      }
    };
  },
  closeVariantPanel() {
    return (dispatch: Dispatch): void => {
      dispatch(editExperimentContainerActions.closeVariantPanel());
    };
  },

  navigateTo(payload: EditExperimentContainerNavigateToActionPayload) {
    return (dispatch: Dispatch): void => {
      dispatch(editExperimentContainerActions.navigateTo(payload));
    };
  },
  clearCurrentExperiment() {
    return (dispatch: Dispatch): void => {
      dispatch(editExperimentContainerActions.clearCurrentExperiment());
    };
  },
};
