import React from 'react';
import { CustomInspirationsGallery, GalleryType, InspirationalImagesGallery } from 'src/services';
import { GalleriesEmptyTable } from './GalleriesEmptyTable';
import { GalleriesList } from './GalleriesList';

interface GalleriesTableProps {
  galleries: (InspirationalImagesGallery | CustomInspirationsGallery)[];
  navigateToCreateGalleryPage: () => void;
  navigateToEditGalleryPage: (galleryId: string, galleryType: GalleryType) => void;
  deleteGallery: (galleryId: string, galleryType: GalleryType) => void;
  navigateToGalleryPage: (galleryId: string, galleryType: GalleryType) => void;
}

export const GalleriesTable = ({
  galleries,
  navigateToCreateGalleryPage,
  navigateToEditGalleryPage,
  deleteGallery,
  navigateToGalleryPage,
}: GalleriesTableProps): JSX.Element => {
  if (galleries.length === 0) {
    return <GalleriesEmptyTable navigateToCreateGalleryPage={navigateToCreateGalleryPage} />;
  }

  return (
    <GalleriesList
      galleries={galleries}
      navigateToEditGalleryPage={navigateToEditGalleryPage}
      deleteGallery={deleteGallery}
      navigateToGalleryPage={navigateToGalleryPage}
    />
  );
};
