import styled from '@emotion/styled';
import { Icon, Typography } from 'src/components-dummy';

export const ContainerStyled = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: max-content max-content;
  column-gap: 9px;
  row-gap: 2px;
  padding: 16px 5px;
  cursor: pointer;
`;

export const TitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['primary-main']};
`;

export const SubTitleStyled = styled(Typography)`
  grid-column: 2;
  color: ${({ theme }) => theme.palette.custom['gray-20']};
`;

export const IconStyled = styled(Icon)`
  align-self: center;
  grid-row: 1 / span 2;
  path {
    stroke: ${({ theme }) => theme.palette.custom['primary-main']};
  }
`;
