import React, { useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import { ParseJoiValidateResponse } from 'src/utils';
import {
  NumericInput,
  TextBox,
  Typography,
  TypographyType,
  TypographyVariant,
  Icon,
  InfoModal,
} from 'src/components-dummy';
import { AvailableIcons } from 'src/components-dummy/Icon/AvailableIcons';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { DiscoveryBannerGeneralSettings } from 'src/services/src/service/types/syte-products/discovery-banner';
import heading from '../../Assets/images/heading.jpg';
import { DiscoveryBannerSettingsRecord } from '../../types';
import { handleUpdateColor } from '../../colorToHex';

import '../../DiscoveryBannerSettings.scss';

export interface HeadingProps {
  discoveryBannerSettings: DiscoveryBannerGeneralSettings['generalSettings'];
  onChange: (args: Partial<DiscoveryBannerGeneralSettings['generalSettings']['banner']>) => void;
  disabled?: boolean;
  errors: ParseJoiValidateResponse<DiscoveryBannerSettingsRecord>;
}

export const Heading = ({
  discoveryBannerSettings,
  onChange,
  disabled = false,
  errors,
}: HeadingProps): JSX.Element | null => {
  const [showBannerFontColorPicker, setShowBannerFontColorPicker] = useState(false);
  const [showHeading, setHeadingModalIsOpened] = useState(false);
  const onHeadingModalClose = () => {
    setHeadingModalIsOpened(false);
  };
  const headingFontColorRef = useRef(null);
  useOnClickOutside(headingFontColorRef, () => {
    setShowBannerFontColorPicker(false);
  });
  return (
    <div className='discovery-banner-settings-container-wrapper'>
      <div className='discovery-banner-settings-container-title'>
        <Typography variant={TypographyVariant.MediumBold} type={TypographyType.Body}>
          Heading
        </Typography>
        <button
          type='button'
          className='discovery-banner-heading-modal'
          onClick={() => setHeadingModalIsOpened(true)}
        >
          <Typography
            className='discovery-banner-heading-tooltip'
            variant={TypographyVariant.SmallRegular}
          >
            What is heading
          </Typography>
          <Icon
            className='discovery-banner-heading-tooltip-icon'
            name={AvailableIcons.TooltipQuestionmarkBlue}
          />
        </button>
        <InfoModal
          isOpened={showHeading}
          className='heading-example-modal'
          closeBtnTxt='Close'
          onClickOutside={onHeadingModalClose}
          title='What is heading?'
        >
          <img className='heading-example-modal-image' src={heading} alt='headingExample' />
        </InfoModal>
      </div>
      <div className='discovery-banner-settings-container banner-settings'>
        <div className='heading-font-color' ref={headingFontColorRef}>
          <TextBox
            label='Heading Text Color'
            placeholder={discoveryBannerSettings.banner.bannerColor}
            error={!!errors.name}
            className='heading-font-color'
            value={discoveryBannerSettings.banner.bannerColor}
            fullWidth
            onChange={fontColor => {
              onChange({ bannerColor: fontColor });
            }}
            disabled={disabled}
          >
            <button
              type='button'
              aria-label='font-color-mock'
              className='font-color-mock'
              onClick={() => setShowBannerFontColorPicker(!showBannerFontColorPicker)}
              style={{
                backgroundColor: discoveryBannerSettings.banner.bannerColor,
              }}
              disabled={disabled}
            />

            {showBannerFontColorPicker && (
              <ChromePicker
                className='font-color-picker'
                color={discoveryBannerSettings.banner.bannerColor}
                onChange={updatedColor => {
                  handleUpdateColor(updatedColor, newColor => {
                    onChange({ bannerColor: newColor });
                  });
                }}
              />
            )}
          </TextBox>
        </div>
        <div className='heading-font-family'>
          <TextBox
            label='Heading Font Family'
            placeholder='sans-serif'
            error={!!errors.name}
            className='heading-font-family'
            value={discoveryBannerSettings.banner.bannerFontFamily}
            fullWidth
            onChange={fontFamily => {
              onChange({ bannerFontFamily: fontFamily });
            }}
            disabled={disabled}
          />
        </div>
        <div className='heading-font-size'>
          <span className='heading-font-size-title'>
            <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
              Heading Font Size
            </Typography>
          </span>
          <div className='heading-font-size-input-wrapper'>
            <NumericInput
              placeholder='24'
              error={!!errors.numberOfResultsToDisplay}
              value={discoveryBannerSettings.banner.bannerFontSize}
              onChange={value => {
                onChange({ bannerFontSize: value });
              }}
              className='heading-font-size-input'
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
