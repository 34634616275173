import styled from '@emotion/styled';
import { Icon, NotificationType } from 'src/components-dummy';
import { REVIEW_BEFORE_PUBLISH_BANNER_HEIGHT_PX } from './constants';

export const PublishedNotificationBannerContainer = styled('div')<{
  notificationType: NotificationType;
}>`
  width: 100%;
  height: ${REVIEW_BEFORE_PUBLISH_BANNER_HEIGHT_PX}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ notificationType }) => {
    switch (notificationType) {
      case NotificationType.Warning:
        return '#f5e8ca';
      case NotificationType.Success:
      default:
        return '#eefbee';
    }
  }};
`;

export const PublishedNotificationBannerIcon = styled(Icon)`
  display: block;
  margin: auto;
  height: 100%;
`;

export const PublishedNotificationBannerCloseIconContainer = styled('div')`
  position: absolute;
  right: 24px;
  height: 16px;

  svg {
    width: 12px;
    height: 12px;
  }
`;

export const PublishedNotificationBannerReviewPublishContainer = styled('div')`
  padding: 7px 5px;
`;

export const PublishedNotificationBannerReviewPublish = styled('span')`
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
`;
