import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ICatalogPlanCreate, CatalogManagerService } from 'src/services';

const catalogManagerService = new CatalogManagerService();

export const catalogFormActions = {
  createCatalogPlan: createAsyncThunk(
    'CatalogFormActions/CreateCatalogPlanConfig',
    async (
      {
        shopId,
        data,
        cancellationSignal,
      }: {
        shopId: number;
        data: ICatalogPlanCreate;
        cancellationSignal?: AbortSignal;
      },
      { rejectWithValue }
    ) => {
      try {
        await catalogManagerService.createCatalog({
          shopId,
          data,
          cancellationSignal,
        });

        return {};
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateCatalogPlan: createAsyncThunk(
    'CatalogFormActions/updateCatalogPlanConfig',
    async (
      {
        shopId,
        data,
        cancellationSignal,
      }: {
        shopId: number;
        data: ICatalogPlanCreate;
        cancellationSignal?: AbortSignal;
      },
      { rejectWithValue }
    ) => {
      try {
        await catalogManagerService.updateCatalogPlanConfig({
          shopId,
          data,
          cancellationSignal,
        });

        return {};
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  notifyIsDirty: createAction<{ isDirty: boolean }>('CatalogForm/NotifyIsDirty'),
  resetSelectedCatalog: createAction('CatalogForm/resetSelectedCatalog'),
};
