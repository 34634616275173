import { useRef } from 'react';

let counter = 0;

export const useCounterId = (): string => {
  const counterRef = useRef('');
  if (!counterRef.current) {
    const nextId = counter++;
    counterRef.current = nextId.toString();
  }

  return counterRef.current;
};
