import { faker } from '@faker-js/faker';
import { v4 as uuid } from 'uuid';
import { Constants } from '../types';

export const mockLexiconLanguages: string[] = ['en_US', 'en_GB'];

// Random boolean
const isRandomTrue = () => Math.random() < 0.5;

const getRandomByArray = (array: any) => array[Math.floor(Math.random() * array.length)];

const getRandomAttributeValueColor = () => {
  const randomColor = [
    'Blue',
    'Green',
    'Pink',
    'Yellow',
    'Black',
    'Silver',
    'White',
    'Orange',
    'Gold',
  ];

  const randomValue = getRandomByArray(randomColor);

  return {
    attribute: {
      key: 'Color',
      defaultTranslation: 'Color',
      translation: 'Color',
    },
    value: {
      key: randomValue,
      defaultTranslation: randomValue,
      translation: Math.random() % 2 === 0 ? getRandomByArray(randomColor) : undefined,
    },
  };
};

const getRandomAttributeValuePattern = () => {
  const randomColor = [
    'AnimalPrint',
    'Camouflage',
    'Checked',
    'ColorBlock',
    'Floral',
    'Geometric',
    'Gingham',
    'Leopard',
    'Text',
    'Python',
    'Stripes',
  ];

  const randomValue = getRandomByArray(randomColor);

  return {
    attribute: {
      key: 'Pattern',
      defaultTranslation: 'Pattern',
      translation: 'Pattern',
    },
    value: {
      key: randomValue,
      defaultTranslation: randomValue,
      translation: isRandomTrue() ? getRandomByArray(randomColor) : undefined,
    },
  };
};

const getMock = (): any => {
  const category = faker.commerce.product();

  const attributeValue = getRandomByArray([
    getRandomAttributeValuePattern(),
    getRandomAttributeValueColor(),
  ]);

  return {
    tagKey: uuid(),
    category: {
      key: category,
      defaultTranslation: category,
      translation: undefined,
    },
    ...attributeValue,
    include: isRandomTrue(),
  };
};

export const getMockLexiconItems = () => {
  return Array.from({ length: 10000 }, () => {
    return getMock();
  });
};

export const mockLexicon = {
  items: getMockLexiconItems(),
  isPublished: false,
  verticals: [Constants.Vertical.Fashion],
};
