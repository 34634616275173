import styled from '@emotion/styled';
import { ImageGrid } from 'src/components-dummy';

export const GalleryGridContainerStyled = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  justify-content: center;
  width: 100%;
`;

export const ImageGridStyled = styled(ImageGrid)<{ columns: number }>`
  grid-template-columns: repeat(${({ columns }) => columns}, minmax(auto, 250px)) !important;
  overflow: hidden;
`;
