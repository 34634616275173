import React, { FC, ReactNode } from 'react';
import { Route, Switch } from 'react-router';

import { isChildComponentOfType } from 'src/components-dummy/helpers';

export interface AppRouteProps<TInterface = Record<string, unknown>> {
  route: { path: string | undefined } | undefined;
  Component: FC<TInterface>;
  componentProps?: TInterface;
  exact?: boolean;
}

export interface AppSwitchProps {
  children: Array<JSX.Element | ReactNode> | ReactNode;
}

export const AppRoute = <T,>(_props: AppRouteProps<T>): JSX.Element | null => {
  return null;
};

export const AppSwitch = ({ children }: AppSwitchProps): JSX.Element => {
  const parsedChildren = React.Children.map(children, child => {
    const appRoute = child as JSX.Element;

    if (appRoute && appRoute.props && isChildComponentOfType(appRoute, AppRoute)) {
      const { route, componentProps, Component, exact = true } = appRoute.props as AppRouteProps;
      const path = route?.path;

      return path ? (
        <Route exact={exact} path={path} render={() => <Component {...componentProps} />} />
      ) : null;
    }

    return child;
  });

  return <Switch>{parsedChildren}</Switch>;
};
