import { createReducer } from '@reduxjs/toolkit';
import {
  UpdateFilterSetActionTypes,
  UpdateFilterSetAction,
  filterSetsActions,
} from 'src/containers';
import { FiltersSetsState, UpdateReqStatus } from '../app-state-types';

const initialState: FiltersSetsState = {
  sets: undefined,
  updateStatus: UpdateReqStatus.None,
};

export const filtersSetsReducer = createReducer(initialState, builder => {
  builder
    .addCase(filterSetsActions.resetFiltersSetsState, () => {
      return { ...initialState };
    })
    .addCase(filterSetsActions.getFilters.pending, () => {
      return { ...initialState };
    })
    .addCase(filterSetsActions.getFilters.rejected, () => {
      return { ...initialState };
    })
    .addCase(filterSetsActions.getFilters.fulfilled, (state, action) => {
      return { ...state, sets: action.payload, updateStatus: UpdateReqStatus.None };
    })
    .addDefaultCase((state, toolkitAction) => {
      const action = toolkitAction as UpdateFilterSetAction;

      switch (action.type) {
        case UpdateFilterSetActionTypes.UpdateRequest:
          return {
            ...state,
            updateStatus: UpdateReqStatus.Pending,
          };
        case UpdateFilterSetActionTypes.UpdateSuccess:
          return {
            ...state,
            sets: action.payload,
            updateStatus: UpdateReqStatus.Success,
          };
        case UpdateFilterSetActionTypes.UpdateError:
          return {
            ...state,
            updateStatus: UpdateReqStatus.Failed,
          };
        default:
          return state;
      }
    });
});
