import {
  FeedsMetadataActionTypes,
  FeedsMetadataAction,
} from 'src/components-bl/DeepTagsFilters/feed-metadata-actions';
import { FeedsMetadataReducerState } from '../app-state-types';

const initialState: FeedsMetadataReducerState = {
  deeptags: {
    feeds: undefined,
    items: undefined,
  },
};

export function feedsMetadataReducer(
  state: FeedsMetadataReducerState = initialState,
  action?: FeedsMetadataAction
): FeedsMetadataReducerState {
  switch (action?.type) {
    case FeedsMetadataActionTypes.DeepTagsSuccess:
      return {
        ...state,
        deeptags: action.payload,
      };
    default:
      return state;
  }
}
