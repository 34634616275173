import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TextBoxBase } from '../TextBox';

export const TimePickerTextBoxWrapperStyled = styled.div<{ shouldShowAsValidSelection: boolean }>`
  width: 137px;

  label.MuiFormLabel-root.MuiInputLabel-root {
    color: ${({ theme }) => theme.palette.common.black};
    margin-bottom: 8px;
    font-size: 12px;
  }

  ${({ shouldShowAsValidSelection, theme }) =>
    shouldShowAsValidSelection &&
    css`
      .input-base-wrapper {
        box-shadow:
          0px 0px 0px 1.5px ${theme.palette.custom['primary-main']},
          0px 0px 0px 4px ${theme.palette.custom['primary-90']};
      }
    `}
`;

export const TextBoxBaseStyled = styled(TextBoxBase)<{ disabled: boolean }>`
  ${({ disabled, theme }) =>
    disabled
      ? `
          user-select: none;
          color: ${theme.palette.custom['gray-20']};
        `
      : `          
          &:hover {
            cursor: pointer;
          }
        `};
`;
