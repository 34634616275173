import React from 'react';
import { NavLink } from 'react-router-dom';
import { LabelCell } from './LabelCell';

interface Props {
  value: string;
  row: {
    original: {
      route: string;
    };
  };
}

export const LinkCell = ({ value, row }: Props): JSX.Element => {
  const { route } = row.original;
  if (!route) return <LabelCell value={value} />;
  return (
    <NavLink to={{ pathname: route }} className='syte-table-link-cell'>
      {value}
    </NavLink>
  );
};
