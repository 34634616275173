import React from 'react';
import {
  CircularProgress,
  CircularProgressProps,
  CircularProgressSizes,
} from '../CircularProgress';

import { BackdropProps, Backdrop } from './Backdrop';

const sx = { zIndex: 100 };

interface BackdropWithLoaderProps extends Omit<BackdropProps, 'children'> {
  spinnerProps?: CircularProgressProps;
  children?: React.ReactChild | React.ReactChild[] | null;
}

export const BackDropWithLoader = ({
  spinnerProps,
  children,
  ...rest
}: BackdropWithLoaderProps): JSX.Element => {
  return (
    <Backdrop sx={sx} {...rest}>
      <>
        <CircularProgress size={CircularProgressSizes.ExtraLarge} {...spinnerProps} />
        {children || null}
      </>
    </Backdrop>
  );
};
