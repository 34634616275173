import React from 'react';
import { Button } from '../../../../../components-dummy';
import { ButtonStyled } from '../../../../../containers/MerchandisingRules/EditMerchandisingRuleContainer/EditMerchandisingRuleContainer.styles';
import { RuleFormFooter } from './RuleFormFooter';

interface Props {
  disabled: boolean;
  onCancel: VoidFunction;
  onSave: VoidFunction;
  mode?: 'edit' | 'create';
  isSubmitting?: boolean;
}

export function FooterActionForm({
  disabled,
  onCancel,
  onSave,
  mode = 'edit',
  isSubmitting = false,
}: Props): JSX.Element {
  return (
    <RuleFormFooter>
      <Button variant='tertiary' onClick={onCancel}>
        Cancel
      </Button>
      <ButtonStyled variant='primary' disabled={disabled} onClick={onSave} loading={isSubmitting}>
        {mode === 'edit' ? 'Save' : 'Create'}
      </ButtonStyled>
    </RuleFormFooter>
  );
}
