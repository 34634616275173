import React from 'react';
import { SelectChangeEvent } from '@mui/material';
import { PreviewExperience, PreviewFeature } from '../../../services';
import { PermittedRouteMap } from '../../../app-routes';
import {
  MenuItem,
  Select,
  SelectType,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../components-dummy';
import { FormGroup } from './Form';
import { useAddParamsToRoute } from '../hooks/useAddParamsToRoute';

interface Props {
  shopId: number;
  value?: PreviewFeature;
  experiences: PreviewExperience[] | null;
  onChange: (feature: PreviewFeature) => void;
  permittedRouteMap: PermittedRouteMap;
}

export function ShopperExperienceSelect({
  shopId,
  experiences,
  value,
  onChange,
  permittedRouteMap,
}: Props): JSX.Element {
  const { addParamsToRoute } = useAddParamsToRoute({
    shopId,
    route: permittedRouteMap?.visualEditor,
  });
  const selectedExperience = value || '';
  const changeExperience = (e: SelectChangeEvent<string>) => {
    onChange(e.target.value as PreviewFeature);
    addParamsToRoute({ shopperExperience: e.target.value as PreviewFeature });
  };

  return (
    <FormGroup>
      <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
        Select shopper experience
      </Typography>
      <Select
        type={SelectType.Menu}
        value={selectedExperience}
        onChange={changeExperience}
        placeholder='Select experience'
      >
        {experiences?.map(({ feature, content, supported }) =>
          !supported ? (
            <MenuItem key={feature} value={feature} className='disabled-item' disabled>
              <>
                <div className='item-title'>{content}</div>
                <div className='disabled-text'>Coming soon</div>
              </>
            </MenuItem>
          ) : (
            <MenuItem key={feature} value={feature}>
              {content}
            </MenuItem>
          )
        )}
      </Select>
    </FormGroup>
  );
}
