import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  DeepTagReportProductFilters,
  DeepTagsReportsGenerativeAIFieldType,
  DescriptionTextLengthEnum,
  IDeepTagReportProductPatchAPI,
  TitleTextLengthEnum,
  ToneOfVoiceTypes,
} from 'src/services/src/service/types/deep-tag-reports';
import {
  DeepTagReportsGeneralConfigurationService,
  DeepTagReportsService,
  GetDeepTagReportProductsArguments,
  UpdateReportProductArguments,
} from 'src/services/src/service/deep-tag-reports';
import { lexiconService } from 'src/services/src/service/lexicon';
import {
  DeepTagsGenerativeAISubProduct,
  IShopGeneratedDataSettings,
  ShopGeneratedDataSettings,
} from 'src/services';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';

const deepTagReportsService = new DeepTagReportsService();
const shopGeneratedDataService = new ShopGeneratedDataSettings();
const deepTagReportsGeneralConfigurationService = new DeepTagReportsGeneralConfigurationService();

/**
 * Types
 */
export interface GetDeepTagReportProductsActionArguments {
  shopId: number;
  reportId: string;
  locale: string;
}

/**
 * Actions
 */
export const deepTagsProductContainerActions = {
  navigateTo: createAction<{ navigateTo: string }>('DeepTagsProductContainer/NavigateTo'),
  setIsDirty: createAction<{ isDirty?: boolean }>('DeepTagReportFileUploadModal/NotifyIsDirty'),
  generateText: createAsyncThunk(
    'DeepTagsProductContainer/GetGeneratedText',
    async (
      {
        fieldType,
        subProducts,
        toneOfVoice,
        textLength,
        shopId,
        reportId,
        productId,
        locale,
      }: {
        fieldType: DeepTagsReportsGenerativeAIFieldType;
        subProducts: DeepTagsGenerativeAISubProduct[];
        toneOfVoice?: ToneOfVoiceTypes;
        textLength: DescriptionTextLengthEnum | TitleTextLengthEnum;
        shopId: number;
        locale: string;
        reportId: string;
        productId: string;
      },
      { rejectWithValue }
    ) => {
      try {
        const generatedText = await deepTagReportsService.generatedText({
          subProducts,
          fieldType,
          toneOfVoice,
          textLength,
          shopId,
          locale,
          reportId,
          productId,
        });

        return { generatedText };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateProduct: createAsyncThunk(
    'DeepTagsProductContainer/UpdateProduct',
    async (
      {
        shopId,
        locale,
        reportId,
        productId,
        partialProduct,
      }: {
        shopId: number;
        reportId: string;
        productId: string;
        locale: string;
        partialProduct: Partial<IDeepTagReportProductPatchAPI>;
      },
      { rejectWithValue }
    ) => {
      try {
        const productToUpdateRequest: UpdateReportProductArguments = {
          shopId,
          locale,
          reportId,
          productId,
          ...partialProduct,
        };

        const product = await deepTagReportsService.updateReportProduct(productToUpdateRequest);

        return { product };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getShopLexiconByLocale: createAsyncThunk(
    'DeepTagReportProductsContainerList/GetShopLexicon',
    async ({ shopId, locale }: { shopId: number; locale: string }, { rejectWithValue }) => {
      try {
        const lexiconTagsAPIModelPromise = lexiconService.getLexiconTags({
          shopId,
          locale,
          tagType: LexiconTagType.LexiconTags,
          filters: {
            include: true,
          },
        });

        const lexiconTagsAPIModel = await lexiconTagsAPIModelPromise;
        const allTags = lexiconTagsAPIModel.data.tags;

        const payload = {
          data: {
            ...lexiconTagsAPIModel,
            tags: allTags,
          },
          pagination: {
            totalCount: allTags.length,
          },
        };

        return payload;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  resetProductChanges: createAsyncThunk(
    'DeepTagsProductContainer/ResetProductChanges',
    async (
      {
        shopId,
        locale,
        reportId,
        productId,
      }: { shopId: number; locale: string; reportId: string; productId: string },
      { rejectWithValue }
    ) => {
      try {
        const product = await deepTagReportsService.resetReportProduct({
          shopId,
          locale,
          reportId,
          productId,
        });

        return { product };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getReportProducts: createAsyncThunk(
    'DeepTagReportProductsContainerActions/GetReportProducts',
    async (
      {
        shopId,
        reportId,
        locale,
        ...filters
      }: GetDeepTagReportProductsActionArguments &
        Pick<GetDeepTagReportProductsArguments, 'skip' | 'limit'> &
        DeepTagReportProductFilters,
      { rejectWithValue }
    ) => {
      try {
        const productsPagination = await deepTagReportsService.getReportProducts({
          shopId,
          locale,
          reportId,
          ...filters,
        });
        return productsPagination;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getGeneratedDataSettings: createAsyncThunk(
    'DeepTagReportProductsContainerActions/GetGeneratedDataSettings',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const shopGeneratedDataSettings = await shopGeneratedDataService.getGeneratedDataSettings({
          shopId,
        });

        return { shopGeneratedDataSettings };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateGeneratedDataSettings: createAsyncThunk(
    'DeepTagReportProductsContainerActions/UpdateGeneratedDataSettings',
    async (
      {
        shopId,
        shopGeneratedDataSettings,
      }: { shopId: number; shopGeneratedDataSettings: IShopGeneratedDataSettings },
      { rejectWithValue }
    ) => {
      try {
        const shopGeneratedDataSettingsResult =
          await shopGeneratedDataService.updateGeneratedDataSettings({
            shopId,
            shopGeneratedDataSettings,
          });

        return { shopGeneratedDataSettings: shopGeneratedDataSettingsResult };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getLabelsGeneralConfiguration: createAsyncThunk(
    'DeepTagReportProductsContainerActions/GetGeneralConfigurationLabels',
    async (some, { rejectWithValue }) => {
      try {
        const generalConfigurationLabels =
          await deepTagReportsGeneralConfigurationService.getLabels();
        return { generalConfigurationLabels };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
