import React from 'react';
import Popper, { PopperProps } from '@mui/material/Popper';

export type AutoCompletePopperProps = PopperProps;

const POPPER_OPTIONS: PopperProps['popperOptions'] = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [2, 3],
      },
    },
  ],
};

/** Overrides autocomplete's popper. by default the autocomplete's popper disables portal.
 *  When it's disabled it renders inside the parent component instead of body.
 *  This caused issues such as dropdown getting cut off by overflow, flicking on bottom edge and more */
export const AutoCompletePopper = (props: AutoCompletePopperProps): JSX.Element => {
  return <Popper {...(props as any)} disablePortal={false} popperOptions={POPPER_OPTIONS} />;
};
