import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react';

import { uiTemplatesShopUrlActionMethods } from './actions';
import { AvailableIcons, Button, Icon, TextBox } from '../../../components-dummy';
import { useWysiwyg } from '../Item/useWysiwyg';

import type { Dispatch } from '../../types';
import { Keys } from '../../../types';

import startEditPopupImage from '../images/start-edit-popup.jpg';

import './StartEditPopup.scss';

interface Props {
  shopId: number;
  dispatch: Dispatch;
  isPopupOpen: boolean;
  closePopup: () => void;
}

export const StartEditPopup: React.FC<Props> = ({ shopId, dispatch, isPopupOpen, closePopup }) => {
  const [value, setValue] = useState('');

  const { openEditor } = useWysiwyg({ dispatch, shopId });

  const onChangeCallback = useCallback(
    (text: string) => {
      setValue(text);
    },
    [setValue]
  );

  const onClickCallback = useCallback(() => {
    openEditor();
    closePopup();
  }, [openEditor, closePopup]);

  const onKeyDownCallback = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === Keys.Enter) {
        openEditor();
        closePopup();
      }
    },
    [openEditor, closePopup]
  );

  useEffect(() => {
    dispatch(uiTemplatesShopUrlActionMethods.setShopUrl({ shopUrl: value }));
  }, [value]);

  useEffect(() => {
    if (!isPopupOpen) {
      closePopup();
    }
  }, [isPopupOpen, closePopup]);

  return (
    <>
      {isPopupOpen ? (
        <div className='start-edit-popup'>
          <div className='start-edit-popup-header'>
            <h2>UI editor instructions</h2>
            <Icon
              className='start-edit-popup-close-btn'
              name={AvailableIcons.Close}
              onClick={closePopup}
            />
          </div>
          <img src={startEditPopupImage} alt='start-edit-popup' />
          <div className='start-edit-popup-store-url-container'>
            <h3>Store URL</h3>
            <span className='start-edit-popup-store-url-text'>
              To start edit your website enter the store URL and then navigate to the relevant
              carousel
            </span>
            <TextBox
              className='start-edit-popup-store-url-input'
              placeholder='http://my-store.com'
              value={value}
              onChange={onChangeCallback}
              onKeyDown={onKeyDownCallback}
            />
            <Button
              className='start-edit-button'
              variant='primary'
              endIcon={AvailableIcons.ArrowRightLight}
              onClick={onClickCallback}
            >
              Go to website and start design
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};
