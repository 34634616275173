import {
  AssignedProduct,
  NotIndexedProduct,
  PublicPost,
  UnpublishedPost,
} from '../../types/entities.types';

export enum StateType {
  idle,
  fetchingModeratedPosts,
  moderationModalOpen,
}

export type State = {
  type: StateType;
  editedPosts: PublicPost[];
  moderatedPosts: UnpublishedPost[];
  selectedPostIndex: number | null;
  error: null | Error;
};

export type AssignableProduct = Exclude<AssignedProduct, NotIndexedProduct>;
