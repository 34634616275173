import { getAllTimezones, TimezoneName, Timezone } from 'countries-and-timezones';
import { orderBy } from 'lodash';
import { DropDownOption } from '../Dropdown';

export function buildTimeZoneOptions(): DropDownOption<TimezoneName>[] {
  let timeZones = Object.values(getAllTimezones());
  timeZones = orderBy(timeZones, ['utcOffset']);

  const timeZoneOptions = timeZones.map((timezone: Timezone) => {
    return {
      value: timezone.name as TimezoneName,
      text: `(UTC${timezone.utcOffsetStr}) ${timezone.name}`,
    };
  });

  return timeZoneOptions;
}
