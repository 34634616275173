import React from 'react';
import { GalleryType } from 'src/services';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { SectionBoxStyled } from '../shared.styles';
import { InspirationalImagesUpload } from './components/InspirationalImagesUpload';

interface GallerySourceSectionProps {
  galleryType: GalleryType;
  inspirationalImagesFiles: File[];
  onInspirationalImagesFileUpload: (selectedFiles: File[]) => void;
  enableUploadConfirmationDialog: boolean;
}

export function GallerySourceSection({
  galleryType,
  inspirationalImagesFiles,
  onInspirationalImagesFileUpload,
  enableUploadConfirmationDialog,
}: GallerySourceSectionProps): JSX.Element {
  const isInspirationalImagesGallery = galleryType === GalleryType.InspirationalImage;

  return (
    <SectionBoxStyled>
      <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
        Gallery Source
      </Typography>
      {isInspirationalImagesGallery && (
        <InspirationalImagesUpload
          inspirationalImagesFiles={inspirationalImagesFiles}
          onUpload={onInspirationalImagesFileUpload}
          enableConfirmationDialog={enableUploadConfirmationDialog}
        />
      )}
    </SectionBoxStyled>
  );
}
