import React from 'react';
import { TypographyProps, TypographyType } from './types';
import { H1Styled, SpanStyled, ParagraphStyled, defaultType } from './Typography.style';

const typeToElement = {
  [TypographyType.Body]: SpanStyled,
  [TypographyType.Button]: SpanStyled,
  [TypographyType.Heading]: H1Styled,
  [TypographyType.Paragraph]: ParagraphStyled,
};

export const Typography = ({
  children,
  variant,
  type = defaultType,
  className,
}: TypographyProps): JSX.Element => {
  const StyledTag = typeToElement[type];
  return (
    <StyledTag variant={variant} type={type} className={className}>
      {children}
    </StyledTag>
  );
};
