import { useCallback, useEffect, useRef } from 'react';

export const useInterval = ({
  callback,
  isEnabled,
  shouldRunImmediately = true,
  timeOutInMilliseconds,
}: {
  callback: () => void;
  isEnabled: boolean;
  shouldRunImmediately: boolean;
  timeOutInMilliseconds: number;
}): void => {
  if (!isEnabled) return;

  const timeout = useRef<number>();

  const run = useCallback(() => {
    if (!isEnabled) {
      window.clearTimeout(timeout.current);
      return;
    }

    if (timeout.current || shouldRunImmediately) {
      callback();
    }

    timeout.current = window.setTimeout(run, timeOutInMilliseconds);
  }, [callback, timeOutInMilliseconds, shouldRunImmediately, isEnabled]);

  useEffect(() => {
    run();
    return () => {
      window.clearTimeout(timeout.current);
    };
  }, [run]);
};
