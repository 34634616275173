import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Page, BackButtonTitle } from 'src/components-dummy';
import { useAppSelector } from 'src/hooks';
import { PersonalizationSettings } from 'src/components-bl';
import { ShopRoutes } from '../../types/enums/shop-routes';
import './PersonalizationContainer.scss';

export const PersonalizationContainer = (): JSX.Element | null => {
  const formHeaderRef = useRef<HTMLDivElement>(null);

  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const personalizationSettings = useAppSelector(state => state.personalizationSettings);
  const dataFieldsState = useAppSelector(state => state.dataFields);
  const dispatch = useDispatch();
  const navigationPath = `/shops/${shopId}/${ShopRoutes.ShopperExperiences}`;
  return (
    <Page className='personalization-container'>
      <Page.Header>
        <BackButtonTitle path={navigationPath}>Personalization</BackButtonTitle>
        <div className='personalization-buttons-section' ref={formHeaderRef} />
      </Page.Header>
      <Page.Content>
        {shopId && (
          <PersonalizationSettings
            personalizationSettings={personalizationSettings}
            dataFields={dataFieldsState.dataFields || []}
            shopId={shopId}
            formHeaderElementRef={formHeaderRef}
            dispatch={dispatch}
          />
        )}
      </Page.Content>
    </Page>
  );
};
