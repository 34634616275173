import React from 'react';
import { PlusButton } from '../AddPost/PlusButton';
import { PostTabsProps, PostsTabs } from '../PostsTabs/PostsTabs';
import './PostsBar.scss';

export type IPostsBarProps = PostTabsProps & { onAddPosts: () => void };

export const PostsBar = ({ onAddPosts, ...postTabProps }: IPostsBarProps): JSX.Element => {
  return (
    <div className='posts-bar' data-testid='posts-bar'>
      <PlusButton onClick={onAddPosts} />
      <PostsTabs {...postTabProps} />
    </div>
  );
};
