import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LexiconTagField } from 'src/services';
import { Button, MultiSelectOption, PopUp, MultiSelectMenuWithSearch } from 'src/components-dummy';
import { Dispatch } from 'src/components-bl';
import { debounce, DebouncedFunc, isEqual } from 'lodash';
import { isAbortError } from 'src/utils/error-helpers';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';
import { lexiconItemAutoCompleteActions } from './LexiconItemAutoComplete.actions';
import {
  PopUpContentFooterStyled,
  PopUpContentStyled,
  SpinnerStyled,
  TagsInputStyled,
} from './LexiconItemAutoComplete.styles';

interface LexiconItemAutoCompleteProps {
  onChange: (values: string[]) => void;
  selectedValues?: string[];
  dispatch: Dispatch;
  shopId: number;
  tagType: LexiconTagType;
  tagField: LexiconTagField;
  locale: string;
  parentFieldsFilters?: {
    categories?: string[];
    attributes?: string[];
    values?: string[];
    verticals?: string[];
    masterTag?: string;
  };
}

export const LexiconItemAutoComplete = ({
  onChange,
  selectedValues = [],
  dispatch,
  shopId,
  tagType,
  tagField,
  locale,
  parentFieldsFilters,
}: LexiconItemAutoCompleteProps): JSX.Element => {
  const [options, setOptions] = useState<MultiSelectOption[]>([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState(selectedValues);

  const canApplySelection = useMemo(() => {
    return !isEqual(selectedOptions, selectedValues);
  }, [selectedOptions, selectedValues]);

  const resetState = useCallback(() => {
    setIsLoading(false);
    setSelectedOptions(selectedValues);
  }, [setIsLoading, selectedValues, setSelectedOptions]);

  const toggleShowPopUp = useCallback(
    (shouldShowPopUp: boolean) => {
      setShowPopUp(shouldShowPopUp);

      if (shouldShowPopUp === false) {
        resetState();
      }
    },
    [setShowPopUp, resetState]
  );

  const closePopUp = useCallback(() => {
    toggleShowPopUp(false);
  }, [toggleShowPopUp]);

  const onTagInputChange = useCallback(
    (tags: string[]) => {
      const updatedOptions = selectedValues.filter(selectedOption => tags.includes(selectedOption));
      onChange(updatedOptions);
      closePopUp();
    },
    [onChange, selectedValues, closePopUp]
  );

  const thunkPromiseRef = useRef(null as { abort: () => void } | null);

  const fetchTranslationsDebounced = useRef<
    DebouncedFunc<
      (args: {
        shopId: number;
        tagType: LexiconTagType;
        locale: string;
        tagField: LexiconTagField;
        searchTerm: string;
        parentFilters?: {
          categories?: string[];
          attributes?: string[];
          verticals?: string[];
          masterTag?: string;
        };
        dispatch: Dispatch;
      }) => void
    >
  >(null as any);

  if (!fetchTranslationsDebounced.current) {
    fetchTranslationsDebounced.current = debounce((args): void => {
      let aborted = false;

      const thunkPromise: any = dispatch(
        lexiconItemAutoCompleteActions.getAvailableTranslations({
          shopId: args.shopId,
          tagField: args.tagField,
          tagType: args.tagType,
          locale: args.locale,
          searchTerm: args.searchTerm,
          ...args.parentFilters,
        })
      );

      thunkPromiseRef.current = thunkPromise;

      thunkPromise
        .unwrap()
        .then(({ availableValues }: { availableValues: string[] }) => {
          setOptions(
            availableValues.map(option => {
              return {
                value: option,
                text: option,
              };
            })
          );
        })
        .catch((apiError: Error) => {
          aborted = isAbortError(apiError);

          if (!aborted) {
            console.error(apiError);
            setOptions([]);
          }
        })
        .finally(() => {
          if (!aborted) {
            setIsLoading(false);
          }
        });
    }, 500);
  }

  const onConfirmSelection = useCallback(() => {
    if (canApplySelection) {
      onChange(selectedOptions);
      closePopUp();
    }
  }, [onChange, selectedOptions, canApplySelection, closePopUp]);

  const fetchValues = useCallback((): void => {
    thunkPromiseRef.current?.abort();

    setOptions([]);
    setIsLoading(true);

    fetchTranslationsDebounced.current({
      shopId,
      locale,
      tagType,
      tagField,
      searchTerm: '',
      parentFilters: parentFieldsFilters,
      dispatch,
    });
  }, [shopId, locale, tagType, tagField, parentFieldsFilters, dispatch]);

  useEffect(() => {
    fetchValues();

    return () => {
      fetchTranslationsDebounced.current.cancel();
      thunkPromiseRef.current?.abort();
    };
  }, [fetchValues]);

  useEffect(() => {
    setSelectedOptions(selectedValues);
  }, [selectedValues, setSelectedOptions]);

  const hideInput = selectedValues.length > 0;

  return (
    <PopUp
      show={showPopUp}
      onShow={toggleShowPopUp}
      closeOnTriggerClick={false}
      hideOnScroll={false}
      position='bottom left'
    >
      <PopUp.Trigger>
        <TagsInputStyled
          tags={selectedValues}
          onChange={onTagInputChange}
          maxTagsToDisplay={1}
          placeholder='Select'
          hideInput={hideInput}
        />
      </PopUp.Trigger>

      <PopUp.Content>
        <PopUpContentStyled>
          <MultiSelectMenuWithSearch
            searchPlaceholder={`Search ${tagField}`}
            options={options}
            selectedOptions={selectedOptions}
            onChange={setSelectedOptions}
            enableSelectAll={false}
          />
          {loading ? <SpinnerStyled /> : null}
          <PopUpContentFooterStyled>
            <Button variant='secondary' onClick={closePopUp}>
              Cancel
            </Button>
            <Button variant='primary' onClick={onConfirmSelection} disabled={!canApplySelection}>
              OK
            </Button>
          </PopUpContentFooterStyled>
        </PopUpContentStyled>
      </PopUp.Content>
    </PopUp>
  );
};
