import styled from '@emotion/styled';
import { Button, Typography } from 'src/components-dummy';
import { BlurredImageContainerStyled } from '../../../common/styles';

export const TaggableImageBackgroundStyled = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.custom['gray-10']};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const TaggableImageWrapperStyled = styled(BlurredImageContainerStyled)<{
  isClickable: boolean;
}>`
  width: 450px;
  height: 450px;
  .syte-blurred-image {
    cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'initial')};
  }
`;

export const ImageFooterStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
  gap: 10px;
`;

export const EditModeTypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  user-select: none;
  font-style: italic;
`;

export const AIButtonStyled = styled(Button)`
  width: 200px;
  gap: 8px;

  path {
    fill: ${({ theme }) => theme.palette.common.white};
  }

  &[disabled=''] {
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const AcceptSuggestionsButtonStyled = styled(Button)`
  gap: 8px;
  width: 200px;
  background-color: ${({ theme }) => theme.palette.custom['primary-light']};
  color: ${({ theme }) => theme.palette.custom['primary-main']};

  svg {
    width: auto;
    height: 10px;
  }

  path {
    stroke: ${({ theme }) => theme.palette.custom['primary-main']};
  }
`;
