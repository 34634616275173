import React, { useCallback } from 'react';
import { ShopList, ShopListProps } from 'src/components-bl';
import { AvailableIcons, Button, Page } from 'src/components-dummy';
import './ShopListContainer.scss';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { UserRoles } from 'src/services/src/service/types/users';
import { RoutedComponentProps } from 'src/app-routes';
import { shopListContainerActions } from './ShopListContainer.actions';

type ConnectedShopListProps = Pick<ShopListProps, 'canCreateShop' | 'canDeleteShop'>;

const ConnectedShopList = ({
  canCreateShop,
  canDeleteShop,
}: ConnectedShopListProps): JSX.Element => {
  const shops = useAppSelector(state => state.shop.shops);
  const selectedShopId = useAppSelector(state => state.shop.current?.shopId);
  const dispatch = useAppDispatch();

  return (
    <ShopList
      shops={shops}
      selectedShopId={selectedShopId}
      dispatch={dispatch}
      canCreateShop={canCreateShop}
      canDeleteShop={canDeleteShop}
    />
  );
};
type ShopListContainerProps = RoutedComponentProps;

export const ShopListContainer = ({ permittedRouteMap }: ShopListContainerProps): JSX.Element => {
  const accountId = useAppSelector(state => state.account.current?.accountId);
  const userRole = useAppSelector(state => state.global.loggedInUser?.role);
  const isSyteAdmin = userRole === UserRoles.SyteAdmin;

  const dispatch = useAppDispatch();

  const onCreateShopClick = useCallback(() => {
    if (!permittedRouteMap.createNewShop) return;

    dispatch(
      shopListContainerActions.navigateTo({
        navigateTo: permittedRouteMap.createNewShop.path,
      })
    );
  }, [permittedRouteMap.createNewShop, dispatch]);

  return (
    <Page>
      <Page.Header>
        <Page.Title>Shops</Page.Title>
        {accountId && !!permittedRouteMap.createNewShop && (
          <div className='ml-auto'>
            <Button
              startIcon={AvailableIcons.Add}
              variant='secondary'
              className='create-rule-button'
              onClick={onCreateShopClick}
            >
              Create Shop
            </Button>
          </div>
        )}
      </Page.Header>
      <Page.Content>
        <ConnectedShopList
          canCreateShop={!!permittedRouteMap.createNewShop}
          canDeleteShop={isSyteAdmin}
        />
      </Page.Content>
    </Page>
  );
};
