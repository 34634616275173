import styled from '@emotion/styled';

export const ProductListStyled = styled.ul<{ isLoading: boolean }>`
  list-style-type: none;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  padding-inline-start: unset;

  max-height: 100%;
  height: 100%;

  li:not(:first-of-type) {
    margin-top: 16px;
    margin-top: 16px;
  }

  opacity: ${({ isLoading }) => {
    if (isLoading) {
      return '0';
    }
    return '1';
  }};

  transition: opacity 0.3s;
`;
