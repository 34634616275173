export const IsIncludedIndicator = '_isIncluded';

export interface IndexedListItem {
  [key: string]: unknown;
  [IsIncludedIndicator]?: boolean;
}

export interface FilterBy {
  property: string;
  excludedValues?: string[];
}

export interface ListActionBarProps<T> {
  addButton?: {
    label: string;
    cb: () => void;
  };
  searchableTargetFields?: string[];
  filterBy: FilterBy;
  itemsList: T[];
  handleFilterChange?: (filteredList: T[]) => void;
}
