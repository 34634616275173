import styled from '@emotion/styled';
import { SearchInput, Typography } from 'src/components-dummy';
import { Card } from 'src/components-dummy/Card';

export const DataFieldAutoSuggestionStyled = styled.div`
  width: 320px;
`;

export const SearchInputStyled = styled(SearchInput)`
  .input-base-wrapper {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const CardStyled = styled(Card)`
  margin-top: 4px;
  padding: 8px;
  max-height: 182px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const RowStyled = styled.div<{ isClickable: boolean }>`
  min-height: 40px;
  border-radius: 4px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 12px;

  ${({ isClickable, theme }) => {
    if (isClickable) {
      return `
        cursor: pointer;

        &:hover {
          background-color: ${theme.palette.custom['gray-90']};
        }
      `;
    }
    return '';
  }}
`;

export const NoResultsTypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  user-select: none;
`;
