import React from 'react';

import { TableV2 } from 'src/components-dummy';
import { SortOption } from '../../../utils';

import { Collection } from '../types';
import { CollectionsSortingOptionValue, TableSortState } from './types';

interface Props {
  column: any;
  sortAscending?: boolean;
  onSortChange: (properties: TableSortState) => void;
  handleSortingValue: (sortingValue: CollectionsSortingOptionValue) => void;
}

export const CollectionsTableSortableColumnHeader: React.FC<Props> = ({
  column,
  sortAscending,
  onSortChange,
  handleSortingValue,
}) => {
  const onSortChangeInternal = () => {
    handleSortingValue({
      property: column.id as keyof Collection,
      option: sortAscending ? SortOption.Asc : SortOption.Desc,
    });
    onSortChange({
      columnName: column.id,
      sortAscending: !sortAscending,
    });
  };

  return (
    <TableV2.HeadRowCellText
      isSortAble
      sortAscending={sortAscending}
      onClick={onSortChangeInternal}
    >
      {column.render('header')}
    </TableV2.HeadRowCellText>
  );
};
