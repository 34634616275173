import styled from '@emotion/styled';
import { Page } from 'src/components-dummy';

export const GalleriesTablePageStyled = styled(Page)`
  min-height: 100%;
  background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  padding: 0 40px 0;

  .syte-page-header {
    padding: 10px 0 32px 0;
  }

  .syte-page-content {
    padding-bottom: 48px;
  }
`;

export const PageTitlesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;

  flex-wrap: nowrap;

  border-bottom: unset;
  min-height: 50px;

  .syte-page-sub-title {
    white-space: nowrap;

    a {
      color: ${props => props.theme.palette.custom['primary-main']};
    }
  }
`;
