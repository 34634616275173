import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AvailableIcons,
  Button,
  MultiSelectMenuWithSearch,
  MultiSelectOption,
  PopUp,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import {
  AddFilterButtonStyled,
  TagMultiSelectContentStyled,
  MultiSelectWrapperStyled,
  MultiSelectFooterStyled,
} from './CatalogExplorerFilters.styles';

import { isEqual } from 'lodash';
import { getLanguageCodeByLocale, sortArrayByLocale } from 'src/utils';
import { MultiSelectTagLabelStyled } from 'src/components-dummy/FiltersRow/components';

export type FilterOption = { key: string; displayName: string };

export interface CatalogExplorerAddFilterProps {
  onChange: (selectedFilterKeys: string[]) => void;
  filterOptions: MultiSelectOption[];
  selectedFilterKeys: string[];
  locale: string;
}

export const CatalogExplorerAddFilter = ({
  onChange,
  filterOptions,
  selectedFilterKeys,
  locale,
}: CatalogExplorerAddFilterProps): JSX.Element => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [formSelectedOptions, setFormSelectedOptions] = useState([]);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const menuOpened = !!menuAnchorEl;

  const canApplySelection = useMemo(() => {
    return !isEqual(filterOptions, formSelectedOptions);
  }, [formSelectedOptions, filterOptions]);

  const sortedFilterOptions = useMemo(() => {
    return Object.values(filterOptions || []).sort((a, b) => {
      return sortArrayByLocale({
        a: a.text,
        b: b.text,
        languageCode: getLanguageCodeByLocale(locale || 'en_US'),
        isAscending: true,
      });
    });
  }, [locale, filterOptions]);

  const onTriggerClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleOptionsChange = (selectedOptions: string[]) => {
    setFormSelectedOptions(selectedOptions);
  };

  useEffect(() => {
    setFormSelectedOptions(selectedFilterKeys);
  }, [selectedFilterKeys]);

  const toggleShowPopUp = useCallback(
    (shouldShowPopUp: boolean) => {
      setShowPopUp(shouldShowPopUp);

      if (!shouldShowPopUp) {
        setMenuAnchorEl(null);
      }
    },
    [setShowPopUp]
  );

  const closePopUp = useCallback(() => {
    toggleShowPopUp(false);
  }, [toggleShowPopUp]);

  const onConfirmSelection = useCallback(() => {
    if (canApplySelection) {
      onChange(formSelectedOptions);
      closePopUp();
    }
  }, [onChange, formSelectedOptions, canApplySelection, closePopUp]);

  useEffect(() => {
    if (showPopUp) {
      searchInputRef.current?.focus();
    }
  }, [showPopUp]);

  return (
    <PopUp
      show={showPopUp}
      onShow={toggleShowPopUp}
      closeOnTriggerClick={false}
      hideOnScroll={false}
      position='bottom left'
    >
      <PopUp.Trigger>
        <AddFilterButtonStyled
          startIcon={AvailableIcons.Add}
          variant='tertiary'
          size='small'
          onClick={onTriggerClick}
          clicked={menuOpened}
        >
          Filter
        </AddFilterButtonStyled>
      </PopUp.Trigger>
      <PopUp.Content>
        <MultiSelectWrapperStyled>
          <TagMultiSelectContentStyled>
            <MultiSelectTagLabelStyled
              type={TypographyType.Paragraph}
              variant={TypographyVariant.SmallRegular}
            >
              Filter by
            </MultiSelectTagLabelStyled>
            <MultiSelectMenuWithSearch
              searchPlaceholder='Search...'
              options={sortedFilterOptions}
              selectedOptions={selectedFilterKeys}
              onChange={handleOptionsChange}
              enableSelectAll={true}
              searchInputRef={searchInputRef}
            />
          </TagMultiSelectContentStyled>
          <MultiSelectFooterStyled>
            <Button variant='tertiary' onClick={closePopUp}>
              Cancel
            </Button>
            <Button variant='primary' onClick={onConfirmSelection} disabled={!canApplySelection}>
              Apply
            </Button>
          </MultiSelectFooterStyled>
        </MultiSelectWrapperStyled>
      </PopUp.Content>
    </PopUp>
  );
};
