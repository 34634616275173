import React from 'react';
import classNames from 'classnames';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { HeaderCellProps } from '../types';

export const HeaderCell = ({
  id,
  cellClassName,
  label,
  callback,
  children,
}: HeaderCellProps): JSX.Element => {
  return (
    <div key={id} className={classNames('syte-entity-card-cell', cellClassName)} onClick={callback}>
      {label ? (
        <div className='syte-entity-card-cell-label'>
          <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
            {label}
          </Typography>
        </div>
      ) : null}
      {children}
    </div>
  );
};
