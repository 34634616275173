import { ApiServiceBase } from '../api-service-base';
import { DeepTagReportsGeneralConfigurationLabel } from '../types';

export class DeepTagReportsGeneralConfigurationService extends ApiServiceBase {
  constructor() {
    super('deep-tag-reports/general-configuration');
  }

  /**
   * General configuration - Labels
   */
  async getLabels(): Promise<DeepTagReportsGeneralConfigurationLabel[]> {
    const url = `${this.serviceBaseUri}/labels`;

    const response = await this.httpService.get({ url });

    return response.data;
  }

  async createLabel(label: string): Promise<DeepTagReportsGeneralConfigurationLabel> {
    const url = `${this.serviceBaseUri}/labels`;

    const response = await this.httpService.post({ url, data: { label } });

    return response.data;
  }
}
