import { useEffect, useMemo } from 'react';
import { UpdateFilterSetSegmentPayload } from 'src/components-bl';
import { useAppSelector } from 'src/hooks';
import { useDataFieldsBySupportedFeature } from 'src/components-bl/hooks';
import { FieldType, ShopDataFieldsSupportedFeatures } from 'src/services';
import { BLComponentProps, ContainerComponentProps } from '../augmented-search-filters.types';
import { filterSetsActions, filtersSetsNavigationActionMethods, updateFilterSet } from '../Actions';

export function useFiltersManagement({
  filterSetId,
  ref,
  onSubmitStatusChange,
  filterSets,
  shopId,
  dispatch,
}: ContainerComponentProps): {
  filterProps: BLComponentProps | null;
} {
  const updateStatus = useAppSelector(state => state.filtersSets.updateStatus);
  const allDataFields = useAppSelector(state =>
    state.dataFields?.dataFields?.filter(
      dataField => dataField.fieldType === FieldType.CatalogField
    )
  );
  const userRole = useAppSelector(state => state.global.loggedInUser?.role);

  const selectedFilterSet = useMemo(() => {
    if (filterSets === undefined) return undefined;

    const selectedByParam = filterSets.filter(
      availableFilter => availableFilter.id === filterSetId
    )?.[0];

    if (selectedByParam) return selectedByParam;

    return filterSets?.[0];
  }, [filterSetId, filterSets]);

  const isLoading = useMemo(() => {
    return filterSets === undefined;
  }, [filterSets]);

  const dataIsReady = shopId && !isLoading && selectedFilterSet;

  const { supportedDataFields: dataFields } = useDataFieldsBySupportedFeature({
    dataFields: allDataFields,
    feature: ShopDataFieldsSupportedFeatures.Filters,
  });

  useEffect(() => {
    if (shopId) {
      dispatch(filterSetsActions.getDataFields({ shopId, fieldType: FieldType.CatalogField }));
    }
  }, [shopId, dispatch]);

  const props = useMemo(() => {
    if (!dataIsReady) {
      return null;
    }

    const savedDataSources = [
      ...(selectedFilterSet?.filters?.items || []),
      ...(selectedFilterSet?.deepTags?.items || []),
    ].map(item => item.dataSource.toLowerCase());

    const unSavedDataFields = dataFields?.filter(
      ({ name }) => !savedDataSources.includes(name.toLowerCase())
    );

    return {
      filterSet: selectedFilterSet,
      shopId,
      onSubmitDispatcher: (payload: UpdateFilterSetSegmentPayload) =>
        dispatch(updateFilterSet(payload)),
      isDirtyDispatcher: (isDirty: boolean) =>
        dispatch(filtersSetsNavigationActionMethods.notifyIsDirty({ isDirty })),
      rawDataFields: dataFields,
      unSavedDataFields,
      dispatch,
      updateStatus,
      userRole,
      formApiRef: ref,
      onSubmitStatusChange,
    };
  }, [
    dataIsReady,
    dispatch,
    dataFields,
    selectedFilterSet,
    shopId,
    updateStatus,
    userRole,
    ref,
    onSubmitStatusChange,
  ]);

  return {
    filterProps: props as BLComponentProps,
  };
}
