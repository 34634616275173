import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { lexiconService } from 'src/services';

export const lexiconRuleFormActions = {
  createLexiconRule: createAsyncThunk(
    'LexiconRuleForm/CreateLexiconRule',
    async (
      parameters: Parameters<typeof lexiconService.createLexiconRule>[0],
      { rejectWithValue }
    ) => {
      try {
        const res = await lexiconService.createLexiconRule(parameters);
        return res;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateLexiconRule: createAsyncThunk(
    'LexiconRuleForm/UpdateLexiconRule',
    async (
      parameters: Parameters<typeof lexiconService.updateLexiconRule>[0],
      { rejectWithValue }
    ) => {
      try {
        const res = await lexiconService.updateLexiconRule(parameters);
        return res;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  // for the first row
  getAvailableKeys: createAsyncThunk(
    'LexiconRuleForm/GetAvailableKeys',
    async (
      parameters: Parameters<typeof lexiconService.getAvailableKeys>[0],
      { rejectWithValue }
    ) => {
      try {
        const res = await lexiconService.getAvailableKeys(parameters);
        return res;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  notifyIsDirty: createAction<{ isDirty: boolean }>('LexiconRuleForm/NotifyIsDirty'),
};
