import { useEffect, useReducer } from 'react';
import { useSocialDiscoveryProvider } from 'src/containers/SocialDiscovery/SocialDiscoveryProvider';
import {
  SocialDiscoveryActionTypes,
  ToastNotification,
} from '../../../../services/src/service/social-discovery/types';
import { socialCollectionSlice } from './reducer';
import { NotificationType } from '../../../../components-dummy';
import { ErrorType } from './types';
import { useInitialMount } from '../../../../hooks/useInitialMount';
import { createToastNotification } from '../../Actions/SocialDiscoveryActions';

const { reducer, actions, getInitialState } = socialCollectionSlice;

export const useSocialConnectionState = () => {
  const { service: socialCollectionService, appDispatch } = useSocialDiscoveryProvider();
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const isInitialMount = useInitialMount();

  const notify = ({ type, messageKey }: ToastNotification) => {
    const toast = {
      type,
      messageKey,
      timestamp: new Date().getTime(),
    };
    appDispatch(createToastNotification(toast));
  };

  useEffect(() => {
    if (!isInitialMount) dispatch(actions.resetState());
    socialCollectionService
      .getConnectionStatus()
      .then(res => dispatch(actions.resolveConnectionStatus(res)));
  }, [socialCollectionService]);

  useEffect(() => {
    if (state.error === null) return;
    if (state.error.type === ErrorType.invalidConnectionError)
      notify({
        type: NotificationType.Error,
        messageKey: SocialDiscoveryActionTypes.ConnectSocialCollectionError,
      });
    else
      notify({
        type: NotificationType.Error,
        messageKey: SocialDiscoveryActionTypes.GeneralError,
      });
  }, [state.error]);

  const openConnectionLink = async () => {
    if (state.connectionLink) {
      window.open(state.connectionLink, '_blank');
      return;
    }

    dispatch(actions.fetchConnectionLink());
    const connectionLinkResp = await socialCollectionService.createConnectionLink();
    dispatch(actions.resolveConnectionLink(connectionLinkResp));
    if (!connectionLinkResp.error) {
      window.open(connectionLinkResp.data.connectionLink, '_blank');
    }
  };

  const checkConnectionStatus = async () => {
    if (!state.connectionLink) return;
    dispatch(actions.reFetchStatus());
    const status = await socialCollectionService.getConnectionStatus();
    dispatch(actions.resolveConnectionStatus(status));
  };
  const disconnectSocialCollection = async () => {
    dispatch(actions.setDisconnecting());
    const response = await socialCollectionService.disconnectSocialCollection();
    dispatch(actions.resolveDisconnect(response));
    if (!response.error) {
      notify({
        type: NotificationType.Success,
        messageKey: SocialDiscoveryActionTypes.DisconnectSocialCollectionSuccess,
      });
    }
  };

  return {
    state,
    openConnectionLink,
    checkConnectionStatus,
    disconnectSocialCollection,
  };
};
