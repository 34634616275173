import React from 'react';
import {
  Typography,
  TypographyType,
  TypographyVariant,
  AutoCompleteSingleValue,
  AutoCompleteSingleValueProps,
  OptionProps,
  EllipsisWithTooltip,
} from '../../../../components-dummy';
import { FormGroup } from '../Form';
import { Image, MenuItemStyled } from './AutoComplete.styles';
import { SuggestedOffer } from '../../types';

export interface AutoCompleteFormProps extends AutoCompleteSingleValueProps {
  title: string;
}

export function Option({ option, ...rest }: OptionProps<string, SuggestedOffer>): JSX.Element {
  const { imageUrl = '', title = '' } = option.data || {};
  const description = `${title}, ${option.title}`;

  return (
    <MenuItemStyled {...rest}>
      <Image src={imageUrl} alt={title} />
      <EllipsisWithTooltip tooltipText={description}>{description}</EllipsisWithTooltip>
    </MenuItemStyled>
  );
}

export function AutoCompleteForm({
  title,
  placeholder,
  selectedValue,
  onChange,
  options,
  ...rest
}: AutoCompleteFormProps): JSX.Element {
  return (
    <FormGroup>
      <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
        {title}
      </Typography>
      <AutoCompleteSingleValue
        closeOnSingleMatch
        placeholder={placeholder}
        selectedValue={selectedValue}
        onChange={onChange}
        options={options}
        freeSolo={false}
        {...rest}
      >
        <AutoCompleteSingleValue.Option>{Option}</AutoCompleteSingleValue.Option>
      </AutoCompleteSingleValue>
    </FormGroup>
  );
}
