import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { lexiconService } from 'src/services';

export const shopLexiconSettingsActions = {
  notifyIsDirty: createAction<{ isDirty: boolean }>('ShopLexiconSettings/NotifyIsDirty'),

  updateLexiconSettings: createAsyncThunk(
    'ShopLexiconSettings/updateLexiconSettings',
    async ({ shopId, locales }: { shopId: number; locales: string[] }, { rejectWithValue }) => {
      try {
        await lexiconService.updateShopLexiconsSettings({ shopId, locales });

        const settings = await lexiconService.getShopLexiconsSettings({ shopId });

        return settings;
      } catch (error: unknown) {
        return rejectWithValue({ error });
      }
    }
  ),
};
