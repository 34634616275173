import React from 'react';
import { CollapseTrigger } from './CollapseTrigger';
import { CollapseHeaderProps } from '../types';
import { CollapseIcon } from './CollapseIcon';

export const CollapseHeader = ({ children }: CollapseHeaderProps): JSX.Element => {
  return <>{children}</>;
};

CollapseHeader.Trigger = CollapseTrigger;
CollapseHeader.Icon = CollapseIcon;
