import React from 'react';
import { Typography, TypographyType, TypographyVariant, Button } from 'src/components-dummy';
import InstagramPreview from '../../../../assets/connect-ig.jpg';
import './ConnectIG.scss';

export interface IConnectIGProps {
  onConnect: () => void;
  onStart: () => void;
  isLoading: boolean;
  hasConnectionLink: boolean;
}

export const ConnectIG = ({
  onConnect,
  onStart,
  isLoading,
  hasConnectionLink,
}: IConnectIGProps): JSX.Element => {
  return (
    <div className='ig-container' data-testid='connect-to-ig'>
      <img src={InstagramPreview} alt='Connect IG account' />
      <div className='feature-description'>
        <Typography variant={TypographyVariant.MediumBold} type={TypographyType.Heading}>
          Social Discovery
        </Typography>
        <Typography variant={TypographyVariant.MediumRegular}>
          Feature your Instagram posts as Stories on your website, tag your products, and start
          selling!
        </Typography>
      </div>
      <div className='stepper'>
        <div className='step'>
          <div className='circle'>
            <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Button}>
              1
            </Typography>
          </div>
          <div className='card'>
            <div className='content'>
              <Typography variant={TypographyVariant.MediumRegular}>
                Connect your Instagram account
              </Typography>
            </div>
            <Button onClick={onConnect} disabled={isLoading} variant='primary'>
              Connect
            </Button>
          </div>
        </div>
        <div className='step'>
          <div className='circle'>
            <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Button}>
              2
            </Typography>
          </div>
          <div className='card'>
            <div className='content'>
              <Typography variant={TypographyVariant.MediumRegular}>I’m connected</Typography>
              <Typography variant={TypographyVariant.SmallRegular}>
                <span className='text-muted'>After successful connection</span>
              </Typography>
            </div>
            <Button onClick={onStart} variant='primary' disabled={isLoading || !hasConnectionLink}>
              Let’s start!
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
