import styled from '@emotion/styled';
import { Tooltip } from 'src/components-dummy';

export const NotificationsIconWrapperStyled = styled.div`
  margin-left: 24px;
  display: flex;
  cursor: pointer;

  .syte-popup-trigger {
    position: relative;
  }
`;

export const TooltipNotificationsStyled = styled(Tooltip)`
  .syte-popup-content {
    height: 38px;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0;
    }
  }
`;
