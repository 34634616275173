import { ApiServiceBase } from '../api-service-base';
import { ShopGeneratedDataMapper } from './shop-generated-data-settings.mapper';
import { IShopGeneratedDataSettings } from '../types';

export class ShopGeneratedDataSettings extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl(shopId: number): string {
    return `${this.serviceBaseUri}/${shopId}/generated-data-settings`;
  }

  async getGeneratedDataSettings({
    shopId,
  }: {
    shopId: number;
  }): Promise<IShopGeneratedDataSettings> {
    const url = this.getUrl(shopId);

    try {
      const response = await this.httpService.get({ url });
      return ShopGeneratedDataMapper.mapGeneratedDataSettings(response);
    } catch (error: any) {
      if (error.code === 404) {
        return {};
      }
      throw error;
    }
  }

  async updateGeneratedDataSettings({
    shopId,
    shopGeneratedDataSettings,
  }: {
    shopId: number;
    shopGeneratedDataSettings: IShopGeneratedDataSettings;
  }): Promise<IShopGeneratedDataSettings> {
    const url = this.getUrl(shopId);
    const response = await this.httpService.patch({ url, data: shopGeneratedDataSettings });
    return ShopGeneratedDataMapper.mapGeneratedDataSettings(response);
  }
}
