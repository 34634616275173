import { ShopDataField } from '../../../../../services';

export const getFieldFromDataFields = (
  fieldName: string | undefined,
  dataFields: ShopDataField[] = []
): ShopDataField | null => {
  if (!fieldName) {
    return null;
  }
  return dataFields.find((field: ShopDataField) => field.name === fieldName) ?? null;
};
