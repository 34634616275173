import Joi from 'joi';
import { ExperimentMetric, VariantType } from 'src/services';
import { getStringEnumValues } from 'src/utils';
import { experimentMetricTranslations } from '../constants';

export const metricsDropDownOptions = getStringEnumValues(ExperimentMetric).map(metric => {
  return {
    value: metric,
    text: experimentMetricTranslations[metric],
  };
});

export const variantValidationSchema = {
  name: Joi.string().trim().min(3),
  type: Joi.string().valid(...getStringEnumValues(VariantType)),
  trafficAllocation: Joi.number().required().min(0).max(99),
};

export const experimentValidationSchema = {
  name: Joi.string().trim().min(3),
  metric: Joi.string().valid(...getStringEnumValues(ExperimentMetric)),
  variants: Joi.array().items(Joi.object().keys(variantValidationSchema)).min(2),
};
