import React from 'react';
import {
  TypographyVariant,
  TypographyType,
  Tooltip,
  EllipsisWithTooltip,
} from 'src/components-dummy';
import { ILocationUser } from 'src/services';
import {
  UsersListStyled,
  UserRowStyled,
  UserDisplayNameTypography,
} from '../UserAvatarList.styles';
import { UserProfileThumbnail } from '../../UserManagement/components/UserProfileThumbnail';
import { getUserDisplayName } from './UserThumbnailElement';

export interface IUsersListProps {
  users: ILocationUser[];
}

const UserRow = ({ user }: { user: ILocationUser }): JSX.Element => {
  const isEditingTooltipValue = user.isMe ? 'You are in edit mode' : 'This user is in edit mode';

  const displayName = getUserDisplayName(user);

  return (
    <UserRowStyled>
      <Tooltip value={isEditingTooltipValue} disabled={!user.isEditing} hoverable={false}>
        <UserProfileThumbnail
          displayName={displayName}
          isEditing={user.isEditing}
          className='user-profile-thumb'
        />
      </Tooltip>
      <EllipsisWithTooltip tooltipText={user.email}>
        <UserDisplayNameTypography
          variant={TypographyVariant.SmallRegular}
          type={TypographyType.Body}
        >
          {displayName}
          {user.isMe ? ' (you)' : null}
        </UserDisplayNameTypography>
      </EllipsisWithTooltip>
    </UserRowStyled>
  );
};

export const UsersList = ({ users }: IUsersListProps): JSX.Element => {
  return (
    <UsersListStyled>
      {users.map(user => (
        <UserRow key={user.userId} user={user} />
      ))}
    </UsersListStyled>
  );
};
