import styled from '@emotion/styled';

export const FormContainer = styled.div`
  width: 100%;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 20px 15px;
  margin: 20px 0;
`;
