import { useEffect } from 'react';
import { Dispatch } from 'src/components-bl';
import { domainEventsWSService } from 'src/services';
import { rootContainerActions } from './Actions';

// Used for initializing and starting listeners for domain events
export const useDomainEventsWS = ({ dispatch }: { dispatch: Dispatch }): void => {
  useEffect(() => {
    domainEventsWSService.initOnMessage({
      topicKey: 'connect',
      onMessage: () => {
        dispatch(rootContainerActions.domainEventsWebSocketConnection({ connected: true }));
      },
    });

    domainEventsWSService.initOnMessage({
      topicKey: 'disconnect',
      onMessage: () => {
        dispatch(rootContainerActions.domainEventsWebSocketConnection({ connected: false }));
      },
    });

    domainEventsWSService.connect();

    return () => {
      dispatch(rootContainerActions.domainEventsWebSocketConnection({ connected: false }));
      domainEventsWSService.disconnect();
    };
  }, []);
};
