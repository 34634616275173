import { useState, useEffect, useMemo } from 'react';
import { isEqual } from 'lodash';
import { useValidateSchema } from 'src/hooks';
import { ShopTypes } from 'src/services';
import { shopGeneralSettingsMethods } from './Actions';
import { generalSettingsFromValidationSchema } from './constants';
import { mapShopToGeneralSettings } from './util';
import {
  UseGeneralSettingsArguments,
  UseGeneralSettingsReturnType,
  GeneralSettingsFormState,
} from './types';

function getInitialState(shop: ShopTypes.ShopBase | undefined): GeneralSettingsFormState {
  return {
    shopName: shop?.shopName || '',
    shopUrl: shop?.shopUrl || '',
  };
}
export const useGeneralSettings = ({
  shop,
  dispatch,
}: Omit<UseGeneralSettingsArguments, 'currentModel'>): UseGeneralSettingsReturnType => {
  const initialState = getInitialState(shop);
  const [state, setState] = useState(initialState);

  const { errors, validate, isValid } = useValidateSchema<GeneralSettingsFormState>({
    schema: generalSettingsFromValidationSchema,
  });

  useEffect(() => {
    if (shop?.shopId) {
      dispatch(
        shopGeneralSettingsMethods.get({
          shopId: shop?.shopId,
        })
      );
    }
  }, [shop?.shopId]);

  useEffect(() => {
    setState(initialState);
  }, [shop]);

  const onChange = (partialData: Partial<GeneralSettingsFormState>) => {
    const updatedState = { ...state, ...partialData };
    setState(updatedState);
    validate({ dataToValidate: updatedState });
  };

  const onSubmit = () => {
    if (isValid && shop?.shopId) {
      dispatch(
        shopGeneralSettingsMethods.submit({
          shopId: shop.shopId,
          generalSettings: mapShopToGeneralSettings({ ...shop, ...state }),
        })
      );
    }
  };

  const isDirty = useMemo(() => {
    return !isEqual(state, initialState);
  }, [state, initialState]);

  useEffect(() => {
    dispatch(shopGeneralSettingsMethods.notifyIsDirty({ isDirty }));
  }, [isDirty]);

  return {
    formState: state,
    isValid,
    isDirty,
    onChange,
    errors,
    onSubmit,
  };
};
