import { UseKnn } from 'src/services/src/service/types/shops/shop';
import { EntityDiffSchema } from '../../types';

type UseKnnDiffSchema = UseKnn;

export const useKnnDiffSchema: EntityDiffSchema<UseKnnDiffSchema, UseKnn> = {
  useKnn: {
    displayName: 'Use OpenSearch KNN',
  },
};
