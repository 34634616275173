import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { baseAppRouteMap } from 'src/app-routes';
import { whiteTheme } from 'src/styles/theme';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { useRoleBasedRoutes, useAuthorizeUser } from './hooks';
import { Loader } from '../components-dummy';
import { RootReducerState } from '../app-state-types';
import { GlobalErrorBoundary, WindowEventCatcher } from '../containers';
import { AppContent } from './AppContent';
import 'semantic-ui-css/semantic.min.css';
import '../i18next';
import './RootContainer.scss';
import { rootContainerActions } from './Actions';
import { UnauthorizedUserComponents } from './UnauthorizedUserComponents/UnauthorizedUserComponents';

export const RootContainer = ({ history }: { history: History }): JSX.Element => {
  const dispatch = useDispatch();

  const loggedInUser = useSelector((state: RootReducerState) => state.global.loggedInUser);
  const twoFactorAuthRequired = useSelector(
    (state: RootReducerState) => state.global.twoFactorAuthRequired
  );
  const appLoading = useSelector((state: RootReducerState) => state.global.appLoading);
  const isFinishedAuthorizingUser = useSelector(
    (state: RootReducerState) => state.global.isFinishedAuthorizingUser
  );
  useAuthorizeUser({ user: loggedInUser });

  const { shopId, featureToggles } = useSelector(
    (state: RootReducerState) => ({
      shopId: state.shop.current?.shopId,
      featureToggles: state.shop.featureToggles,
    }),
    shallowEqual
  );

  const { permittedRoutes, permittedRouteMap, fallback, enabledShopFeatures } = useRoleBasedRoutes({
    user: loggedInUser,
    shopId,
    baseAppRouteMap,
    featureToggles,
  });

  useEffect(() => {
    dispatch(rootContainerActions.setPermittedRoutes({ permittedRoutes, permittedRouteMap }));
  }, [permittedRoutes, permittedRouteMap]);

  const loggedInUserComponents = loggedInUser ? (
    <AppContent
      user={loggedInUser}
      permittedRouteMap={permittedRouteMap}
      fallback={fallback}
      rootRoutes={permittedRoutes}
      enabledShopFeatures={enabledShopFeatures}
    />
  ) : (
    <UnauthorizedUserComponents dispatch={dispatch} twoFactorAuthRequired={twoFactorAuthRequired} />
  );

  useEffect(() => {
    // save first route to store, and redirect to it after login
    dispatch(rootContainerActions.setFirstRoute({ url: history.location.pathname }));

    dispatch(rootContainerActions.getPublicMetadata());
  }, []);

  return (
    <div>
      <MuiThemeProvider theme={whiteTheme}>
        <ThemeProvider theme={whiteTheme}>
          <ConnectedRouter history={history}>
            <GlobalErrorBoundary>
              {isFinishedAuthorizingUser && loggedInUserComponents}
            </GlobalErrorBoundary>
          </ConnectedRouter>
          <Loader show={appLoading} />
          <WindowEventCatcher />
        </ThemeProvider>
      </MuiThemeProvider>
    </div>
  );
};
