import Joi from 'joi';
import { ShopCreationFormState } from './types';
import { ShopTypes } from 'src/services';
import { getStringEnumValues } from 'src/utils';

export const initialFormState: ShopCreationFormState = {
  shopName: '',
  salesforceAccount: undefined,
  shopType: undefined,
};

export const shopTypeDisplayName: Record<ShopTypes.ShopType, string> = {
  [ShopTypes.ShopType.Production]: 'Production',
  [ShopTypes.ShopType.Sandbox]: 'Sandbox',
  [ShopTypes.ShopType.Internal]: 'Internal Syte Account',
};

export const shopTypeSortIndex: Record<ShopTypes.ShopType, number> = {
  [ShopTypes.ShopType.Production]: 0,
  [ShopTypes.ShopType.Sandbox]: 1,
  [ShopTypes.ShopType.Internal]: 2,
};

export const shopTypeDropdownOptions = Object.values(ShopTypes.ShopType)
  .sort((a, b) => (shopTypeSortIndex[a] < shopTypeSortIndex[b] ? -1 : 1))
  .map(shopType => {
    return {
      value: shopType,
      text: shopTypeDisplayName[shopType],
    };
  });

export const formValidationSchema = {
  shopName: Joi.string().min(3).required().messages({
    'string.empty': 'Please type the shop name',
    'string.min': `Name is too short`,
  }),
  salesforceAccount: Joi.alternatives().conditional('shopType', {
    is: ShopTypes.ShopType.Production,
    then: Joi.object().required().messages({
      'any.required': 'Please select the Salesforce account ID',
    }),
    otherwise: Joi.optional(),
  }),
  shopType: Joi.string()
    .valid(...getStringEnumValues(ShopTypes.ShopType))
    .required()
    .messages({
      'any.required': 'Please select the shop type',
    }),
};

export const NO_SALESFORCE_ID = 'No Salesforce ID';
