import { startCase } from 'lodash';
import { ChangesSummaryLookupTable, DomainEntityPath, MerchandisingRuleTypes } from 'src/services';
import { RULE_WEIGHT_STEPS } from './merchandisingRules.config';
import { RuleActionText } from './components/MerchandisingRulesListPage/components/MerchandisingRulesList/MerchandisingRulesList.config';
import { CategoryPages } from '../../services/src/service/types/syte-products/category-pages';
import { MerchandiseRule, RuleAction } from '../../services/src/service/types/shops';
import { SyteProductType } from '../../services/src/service/types/constants';

export const getWeightStepsByValue = (weight: number | null | undefined): string[] => {
  if (weight === null || weight === undefined || weight < 0) {
    return [];
  }

  let previousStep = null as (typeof RULE_WEIGHT_STEPS)[0] | null;

  for (const step of RULE_WEIGHT_STEPS) {
    if (weight === step.maxValue) {
      return [step.name];
    }

    if (!previousStep && weight < step.maxValue) {
      return [step.name];
    }

    if (previousStep && weight > previousStep.maxValue && weight < step.maxValue) {
      return [previousStep.name, step.name];
    }

    previousStep = step;
  }

  console.warn(`WeightLevelByValue - weight is not in range - weight: ${weight}`);

  return [];
};

export const getWeightTranslation = (weight: number): string => {
  const [step, nextStep] = getWeightStepsByValue(weight);

  if (step && nextStep) {
    return `${startCase(step)} - ${startCase(nextStep)}`;
  }

  return startCase(step) || '';
};

export function validateRuleRegionsAreAvailable({
  selectedRegions,
  availableRegions,
}: {
  selectedRegions: MerchandisingRuleTypes.MerchandiseRule['regions'];
  availableRegions: string[];
}): boolean {
  return selectedRegions.every(region => availableRegions.includes(region));
}

export const isObjEmpty = (obj: any) => {
  if (!obj) return true;
  return Object.values(obj).length === 0;
};

export const matchObjByErrorType = (errors: any, errorType: string, index: number) => {
  return Object.values(errors).find(
    (error: any) => error.type === errorType && error.path[1] === index
  );
};

export const matchObjByErrorPath = (errors: any, errorPath: string, index: number) => {
  return Object.values(errors).find(
    (error: any) => error.context.path === errorPath && error.path[1] === index
  );
};

export const combineActionAndWeightText = ({
  action,
  weight,
}: {
  action: string;
  weight: number;
}) => {
  if (!action) return '';

  const actionText = RuleActionText[action as RuleAction];

  // Return only action text for include/exclude/redirect/p2p
  if (
    action === RuleAction.Include ||
    action === RuleAction.Exclude ||
    action === RuleAction.Redirect ||
    action === RuleAction.PinToPosition
  ) {
    return actionText;
  }

  const weightStepText = weight > 0 ? getWeightTranslation(weight) : '';

  return weightStepText ? `${actionText}: ${weightStepText}` : actionText;
};

export function isRulePublished({
  ruleId,
  lookupTable = {},
}: {
  ruleId: string;
  lookupTable?: ChangesSummaryLookupTable;
}): boolean {
  return !lookupTable[DomainEntityPath.MerchandisingRules]?.[ruleId];
}

export function merchRuleComparator(ruleA: MerchandiseRule, ruleB: MerchandiseRule) {
  if (ruleA.updatedAt && !ruleB.updatedAt) {
    return -1;
  }
  if (ruleB.updatedAt && !ruleA.updatedAt) {
    return 1;
  }
  if (ruleA.updatedAt && ruleB.updatedAt) {
    return ruleA.updatedAt < ruleB.updatedAt ? 1 : -1;
  }
  return ruleA.name.toLowerCase() > ruleB.name.toLowerCase() ? 1 : -1;
}

export const getProductName = (product: SyteProductType, entityId?: string): string => {
  return product === SyteProductType.Collections && !entityId
    ? CategoryPages
    : startCase(product) || product;
};
