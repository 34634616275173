import styled from '@emotion/styled';

export const SkeletonHeader = styled.div`
  padding: 16px 24px;
`;

export const SkeletonContent = styled.div`
  display: flex;
  height: 660px;
`;
