import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { LinkButton } from 'src/components-dummy/LinkButton';
import { RuleFormSectionStyled } from '../RuleFormSection/RuleFormSection.styles';
import { DateTimeRowStyled } from './components/RuleDateTimePicker.styles';

export const DateSectionContainerStyled = styled(RuleFormSectionStyled)`
  position: relative;
  margin-bottom: 200px;
  @media (min-height: 1600px) {
    margin-bottom: 400px;
  }

  ${DateTimeRowStyled}:first-of-type {
    margin-bottom: 32px;
  }
`;

export const DeleteDateButtonStyled = styled(LinkButton)`
  position: absolute;
  top: 32px;
  right: 32px;
`;

export const timeZoneCss = css`
  margin-bottom: 16px;
`;
