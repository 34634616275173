import React from 'react';
import { Backdrop } from '@mui/material';
import styled from '@emotion/styled';
import { CollapseStyled } from './CollapseDrawer.styles';

const StyledBackdrop = styled(Backdrop)`
  position: absolute;
  display: flex;
  justify-content: flex-start;
  z-index: 3;
`;

const StyledContainer = styled.div`
  position: relative;
  z-index: 10;
  background: white;
  height: 100dvh;
  &.preview-draft {
    height: calc(100dvh - 32px);
  }

  .merchandising-rules-form-page {
    .syte-page-header,
    form > .syte-card {
      width: 100%;
    }
  }
`;

interface Props {
  open: boolean;
  onClose: VoidFunction;
  children: React.ReactNode;
  className?: string;
}

export function CollapseDrawer({ open, onClose, children, className }: Props): JSX.Element {
  return (
    <>
      <CollapseStyled orientation='horizontal' in={open}>
        <StyledContainer className={className}>{children}</StyledContainer>
      </CollapseStyled>
      <StyledBackdrop open={open} onClick={onClose} />
    </>
  );
}
