import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';

export interface PageSubTitleProps {
  children: ReactNode | ReactNode[];
  className?: string;
}

export const PageSubTitle = ({ children, className }: PageSubTitleProps): JSX.Element => {
  return (
    <div className={classNames('syte-page-sub-title', className)}>
      <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
        {children}
      </Typography>
    </div>
  );
};
