import React, { useCallback, useEffect } from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import { TimePicker as TimePickerMUI } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { debounce } from 'lodash';
import { PopperPlacementType } from '@mui/material';
import { TextBoxBaseStyled, TimePickerTextBoxWrapperStyled } from './TimePicker.style';
import { isValueValid } from './TimePicker.helpers';

const DEBOUNCE_TIME_IN_MS = 350;

export interface TimePickerProps {
  value?: Date | null;
  isOpen?: boolean;
  minTime?: Date;
  maxTime?: Date;
  disabled?: boolean;
  isError?: boolean;
  minutesStep?: number;
  ampm?: boolean;
  label?: string;
  inputFormat?: string;
  popperPlacement?: PopperPlacementType;
  sx?: SxProps<Theme>;
  onClose?: () => void;
  onOpen?: () => void;
  onChange: (selectedDate?: Date | null) => void;
}

export const TimePicker = ({
  value,
  isOpen: isOpenInitial = false,
  minTime,
  maxTime,
  disabled,
  isError,
  minutesStep,
  label,
  ampm,
  inputFormat = 'HH:mm',
  sx,
  popperPlacement,
  onChange,
  onOpen,
  onClose,
}: TimePickerProps): JSX.Element => {
  const [currentValue, setCurrentValue] = React.useState<Date | null | undefined>(value);
  const [isOpen, setIsOpen] = React.useState<boolean>(!!isOpenInitial);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = useCallback(() => {
    if (!disabled) {
      setIsOpen(true);
    }
  }, [setIsOpen, disabled]);

  const handleClose = () => {
    setIsOpen(false);
    onClose?.();
  };

  const handleOpen = () => {
    setIsOpen(true);
    onOpen?.();
  };

  const handleChange = debounce((selectedDate: Date | null) => {
    const isValidDate = isValueValid({ selectedDate });

    const newValue = isValidDate ? selectedDate : null;

    setCurrentValue(newValue);
    onChange?.(newValue);
  }, DEBOUNCE_TIME_IN_MS);

  const setAnchorRef = (element: HTMLDivElement) => {
    setAnchorEl(element);
  };

  useEffect(() => {
    setIsOpen(!!isOpenInitial);
  }, [isOpenInitial]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (!currentValue && value) {
      setCurrentValue(value);
    }
  }, [currentValue]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePickerMUI
        ampm={ampm}
        open={isOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        value={currentValue}
        onChange={handleChange}
        minTime={minTime}
        disabled={disabled}
        maxTime={maxTime}
        minutesStep={minutesStep}
        inputFormat={inputFormat}
        label={label}
        PopperProps={{
          placement: popperPlacement || 'bottom-start',
          style: { zIndex: 2000 },
          anchorEl,
          sx: {
            '& .MuiPickersDay-root': {
              '&.Mui-selected': {
                backgroundColor: theme => theme.palette.custom['primary-main'],
                cursor: 'pointer',
              },
            },
            '& .MuiPickersPopper-paper': {
              borderRadius: '8px',
              marginTop: '4px',
              marginBottom: '4px',
              border: theme => `1px solid  ${theme.palette.custom['gray-50']}`,
            },
            ...sx,
          },
        }}
        renderInput={params => {
          return (
            <TimePickerTextBoxWrapperStyled
              shouldShowAsValidSelection={isOpen && !isError}
              ref={setAnchorRef}
            >
              <TextBoxBaseStyled
                className='syte-text-box'
                label={label}
                disabled={!!disabled}
                error={isError}
                fullWidth
                onClick={handleClick}
                multiline={false}
              >
                <>{params.inputProps?.value}</>
              </TextBoxBaseStyled>
            </TimePickerTextBoxWrapperStyled>
          );
        }}
      />
    </LocalizationProvider>
  );
};
