import { ApiServiceBase } from '../api-service-base';

import {
  GetGeneralSettingsArguments,
  IResultsModal,
  UpdateGeneralSettingsArguments,
} from './types';
import { Response } from '../../../../components-bl/socialDiscovery/types/common.types';

export class ResultsModal extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getResultsModalPath(shopId: number, variantId?: string): string {
    return `${this.serviceBaseUri}/${shopId}/results-modal/general-settings${
      variantId ? `?variantId=${variantId}` : ''
    }`;
  }

  async getGeneralSettings({
    shopId,
    variantId,
  }: GetGeneralSettingsArguments): Response<IResultsModal> {
    const url = `${this.getResultsModalPath(shopId, variantId)}`;
    try {
      const { data } = await this.httpService.get({ url });
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  async updateGeneralSettings({
    shopId,
    variantId,
    generalSettings,
  }: UpdateGeneralSettingsArguments): Response<IResultsModal> {
    const url = `${this.getResultsModalPath(shopId, variantId)}`;
    try {
      const { data } = await this.httpService.patch({ url, data: { generalSettings } });
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
}

export const resultsModalService = new ResultsModal();
