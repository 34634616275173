import styled from '@emotion/styled';

export const PageCenteredStyle = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: min-content 1fr;
  justify-content: center;
  padding-left: unset;
  padding-right: unset;
  background: #f8f8f8;
  overflow-y: auto;
  padding-top: 40px;

  .syte-page-header {
    border-bottom: unset;
  }

  .syte-page-header,
  .syte-page-content {
    width: 1077px;
  }

  .syte-page-content {
    height: 100%;
    overflow: auto;
  }
`;

export const PageDefaultStyled = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.palette.custom['gray-80']};
  padding: 24px;

  overflow: auto;

  .syte-page-header {
    border-bottom: unset;
    margin-bottom: 40px;
  }
`;

export const PageSideBarStyled = styled(PageDefaultStyled)`
  padding: 44px 24px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  overflow: hidden;

  .syte-page-sub-title {
    width: 100%;
    color: ${({ theme }) => theme.palette.custom['gray-20']};
  }

  .syte-page-content {
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .sidebar {
    padding: 0 24px;
  }

  .main {
    height: 83vh;
    padding: 0 24px;

    & > .syte-page-content:not(.collections) {
      height: 100%;
    }
  }
`;
