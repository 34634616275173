import { useMemo } from 'react';
import { ShopDataField, ShopDataFieldsSupportedFeatures } from 'src/services';

export interface UseDataFieldsBySupportedFeatureArguments {
  dataFields?: ShopDataField[];
  feature: ShopDataFieldsSupportedFeatures;
}

export interface UseDataFieldsBySupportedFeatureReturnType {
  supportedDataFields?: ShopDataField[];
}

export const useDataFieldsBySupportedFeature = ({
  dataFields,
  feature,
}: UseDataFieldsBySupportedFeatureArguments): UseDataFieldsBySupportedFeatureReturnType => {
  const supportedDataFields = useMemo(() => {
    return dataFields?.filter(dataField => dataField.supportedFeatures.includes(feature));
  }, [dataFields]);

  return { supportedDataFields };
};
