import React, { ReactNode } from 'react';
import classnames from 'classnames';
import './Tooltip.scss';
import { PopUp, PopupProps } from '../PopUp';
import { Typography, TypographyType, TypographyVariant } from '../Typography';

export interface TooltipProps
  extends Pick<
    PopupProps,
    | 'position'
    | 'disabled'
    | 'closeOnTriggerClick'
    | 'hoverable'
    | 'openOnTriggerClick'
    | 'intervalHideMillisecond'
    | 'contentClickPreventDefault'
  > {
  children: ReactNode | ReactNode[];
  value: string | ReactNode;
  className?: string;
}

export const Tooltip = ({
  value,
  children,
  position = 'top center',
  disabled = undefined,
  className = '',
  closeOnTriggerClick = false,
  hoverable = true,
  openOnTriggerClick = false,
  intervalHideMillisecond = undefined,
  contentClickPreventDefault,
}: TooltipProps): JSX.Element => {
  return (
    <PopUp
      triggerEvent={openOnTriggerClick ? 'click' : 'hover'}
      hoverable={hoverable}
      position={position}
      className={classnames('syte-tooltip', className)}
      triggerClickPreventDefault={false}
      disabled={disabled}
      closeOnTriggerClick={closeOnTriggerClick}
      closeOnContentClick={false}
      openOnTriggerClick={openOnTriggerClick}
      intervalHideMillisecond={intervalHideMillisecond}
      contentClickPreventDefault={contentClickPreventDefault}
    >
      <PopUp.Trigger>{children}</PopUp.Trigger>
      <PopUp.Content>
        <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
          {value}
        </Typography>
      </PopUp.Content>
    </PopUp>
  );
};
