import React from 'react';
import { visualEditorActions } from '../../state';
import { useAppDispatch } from '../../../../hooks';
import {
  AvailableIcons,
  EllipsisWithTooltip,
  Tooltip,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { PreviewMerchRule } from '../../types';
import { PermittedRouteMap } from '../../../../app-routes';
import { useNavigateToVisualEditorRoute } from '../../hooks/useNavigateToVisualEditorRoute';
import {
  HighlightedText,
  IconStyled,
  SubRule,
  SubRulesContainer,
  SubRuleText,
} from './SideMenu.styles';

export const Strategy = ({
  selectedRule,
  shopId,
  permittedRouteMap,
}: {
  selectedRule: PreviewMerchRule;
  shopId: number;
  permittedRouteMap: PermittedRouteMap;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { subRules } = selectedRule;

  const { navigateToVisualEditorRoute } = useNavigateToVisualEditorRoute({
    route: permittedRouteMap?.visualEditorEditStrategy,
  });

  const onEditStrategy = (rule: PreviewMerchRule) => {
    dispatch(visualEditorActions.setSelectedRule(rule));
    navigateToVisualEditorRoute({ shopId, ruleId: rule.id });
  };
  return (
    <>
      <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
        Strategy
      </Typography>
      <SubRulesContainer>
        {subRules.map(({ position, values }) => {
          return (
            <SubRule key={position}>
              <SubRuleText type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
                Pin
                <EllipsisWithTooltip tooltipPosition='top right' tooltipText={values.toString()}>
                  <HighlightedText
                    type={TypographyType.Body}
                    variant={TypographyVariant.SmallMedium}
                  >
                    {values.toString()}
                  </HighlightedText>
                </EllipsisWithTooltip>
                to position
                <HighlightedText type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                  {position}
                </HighlightedText>
              </SubRuleText>
              <Tooltip value='Edit'>
                <IconStyled
                  name={AvailableIcons.EditV2}
                  onClick={() => onEditStrategy(selectedRule)}
                />
              </Tooltip>
            </SubRule>
          );
        })}
      </SubRulesContainer>
    </>
  );
};
