import { ApiServiceBase } from '../api-service-base';
import { Response } from '../../../../components-bl/socialDiscovery/types/common.types';
import { IStoriesSettings } from '../../../../components-bl/Stories/storiesPage.types';
import { StoriesStrategy } from '../../../../types';

export type IStoriesSettingsResponse = { settings: IStoriesSettings };

type IUpdateStoriesSettings = {
  numberOfResultsToDisplay: number;
  storiesStrategy: StoriesStrategy;
};

type IUpdateStoriesSettingPayload = {
  generalSettings: IUpdateStoriesSettings;
};

export interface IStoriesService {
  getStoriesSettings: () => Response<IStoriesSettingsResponse>;
  updateStoriesSettings: (
    settings: IUpdateStoriesSettingPayload
  ) => Response<IStoriesSettingsResponse>;
}

export class StoriesService extends ApiServiceBase implements IStoriesService {
  constructor(private shopId: number) {
    super('shops');
  }

  async getStoriesSettings(): Response<IStoriesSettingsResponse> {
    try {
      const { data } = await this.httpService.get({
        url: `${this.serviceBaseUri}/${this.shopId}/stories/settings`,
      });
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  async updateStoriesSettings(
    settings: IUpdateStoriesSettingPayload
  ): Response<IStoriesSettingsResponse> {
    try {
      const { data } = await this.httpService.patch({
        url: `${this.serviceBaseUri}/${this.shopId}/stories/settings`,
        data: settings,
      });
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
}
