import React from 'react';
import { StyledSkeleton } from './GalleriesTableSkeleton.styles';

export const GalleriesTableSkeleton = (): JSX.Element => (
  <>
    <StyledSkeleton height={60} variant='rounded' />
    <StyledSkeleton height={60} variant='rounded' />
    <StyledSkeleton height={60} variant='rounded' />
  </>
);
