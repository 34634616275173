import { EditableFilterItem, FilterItemDBStructure } from './types';

export function mergeAllAffectedListItems(
  formOutput: EditableFilterItem[],
  diff: FilterItemDBStructure[],
  initialList: FilterItemDBStructure[]
): FilterItemDBStructure[] {
  type HighestAcc = {
    itemPos: number;
    item: FilterItemDBStructure;
  };
  const initialHighest: HighestAcc = { itemPos: diff[0].position, item: diff[0] };
  const highestPositionInDiff: HighestAcc = diff.reduce(
    (highest, diffItem) =>
      diffItem?.position && diffItem?.position > highest.itemPos
        ? { itemPos: diffItem.position, item: diffItem }
        : highest,
    initialHighest
  );
  if (highestPositionInDiff.itemPos) {
    const slicedFormList = formOutput
      .filter(item => item.position <= highestPositionInDiff.itemPos)
      .map(
        affectedItem =>
          diff.find(affectedDiffEntry => affectedDiffEntry.key === affectedItem.key) || affectedItem
      );
    if (slicedFormList.length < initialList.length) {
      const remainingSavedItems = initialList.slice(highestPositionInDiff.itemPos);
      return [...slicedFormList, ...remainingSavedItems] as FilterItemDBStructure[];
    }
    return slicedFormList as FilterItemDBStructure[];
  }
  return initialList;
}

export function patchOnlyDirectlyChangedItems(
  formOutput: EditableFilterItem[],
  diff: FilterItemDBStructure[],
  initialList: FilterItemDBStructure[]
): FilterItemDBStructure[] {
  const mergedChanges = formOutput
    .map(item => {
      const foundDiff = diff.find(di => di.key === item.key);
      const foundInitialDataEntry = initialList.find(il => il.key === item.key);
      return foundDiff || foundInitialDataEntry;
    })
    .filter(Boolean) as FilterItemDBStructure[];
  return mergedChanges;
}

export function processListFormOutput(
  formOutput: EditableFilterItem[],
  diff: FilterItemDBStructure[],
  isListOrderManual: boolean,
  savedItemsData: FilterItemDBStructure[]
): FilterItemDBStructure[] {
  if (!diff.length) {
    return savedItemsData;
  }
  return isListOrderManual
    ? mergeAllAffectedListItems(formOutput, diff, savedItemsData)
    : patchOnlyDirectlyChangedItems(formOutput, diff, savedItemsData);
}
