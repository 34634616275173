import { experimentService, ExperimentSlim } from 'src/services';
import { Dispatch } from 'src/components-bl/types';
import {
  ExperimentCardActionTypes,
  ExperimentCardActionPayload,
  ExperimentCardErrorActionPayload,
  ExperimentCardSelectExperimentAction,
  ExperimentCardDeleteExperimentRequestAction,
  ExperimentCardDeleteExperimentSuccessAction,
  ExperimentCardDeleteExperimentErrorAction,
  ExperimentCardNavigateToActionPayload,
  ExperimentCardNavigateToAction,
} from './types';

const experimentCardActions = {
  selectExperiment(payload: ExperimentCardActionPayload): ExperimentCardSelectExperimentAction {
    return {
      type: ExperimentCardActionTypes.SelectExperiment,
      payload,
    };
  },

  deleteExperimentRequest(
    payload: ExperimentCardActionPayload
  ): ExperimentCardDeleteExperimentRequestAction {
    return {
      type: ExperimentCardActionTypes.DeleteExperimentRequest,
      payload,
    };
  },

  deleteExperimentSuccess(
    payload: ExperimentCardActionPayload
  ): ExperimentCardDeleteExperimentSuccessAction {
    return {
      type: ExperimentCardActionTypes.DeleteExperimentSuccess,
      payload,
    };
  },

  deleteExperimentError(
    payload: ExperimentCardErrorActionPayload
  ): ExperimentCardDeleteExperimentErrorAction {
    return {
      type: ExperimentCardActionTypes.DeleteExperimentError,
      payload,
    };
  },

  navigateTo(payload: ExperimentCardNavigateToActionPayload): ExperimentCardNavigateToAction {
    return {
      type: ExperimentCardActionTypes.NavigateTo,
      payload,
    };
  },
};

export const experimentCardActionMethods = {
  selectExperiment(payload: ExperimentCardActionPayload) {
    return (dispatch: Dispatch): void => {
      dispatch(experimentCardActions.selectExperiment(payload));
    };
  },

  deleteExperiment(payload: ExperimentCardActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(experimentCardActions.deleteExperimentRequest(payload));
        await experimentService.deleteExperiment(payload);
        const experiments: ExperimentSlim[] = await experimentService.getExperiments({
          shopId: payload.shopId,
          slim: true,
        });
        dispatch(experimentCardActions.deleteExperimentSuccess({ ...payload, experiments }));
      } catch (error) {
        dispatch(experimentCardActions.deleteExperimentError({ error }));
      }
    };
  },

  navigateTo(payload: ExperimentCardNavigateToActionPayload) {
    return (dispatch: Dispatch): void => {
      dispatch(experimentCardActions.navigateTo(payload));
    };
  },
};
