import React, { useCallback } from 'react';

import {
  AvailableIcons,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import {
  DeleteIconStyled,
  LocaleIconStyled,
  LocaleItemStyled,
  LocaleTextStyled,
} from './LocaleItem.styles';

export interface LocaleItemProps {
  locale: string;
  displayName: string;
  iconName: string;
  className?: string;
  onDeleteCLick?: (locale: string) => void;
}

export const LocaleItem = ({
  locale,
  displayName,
  iconName,
  className,
  onDeleteCLick,
}: LocaleItemProps): JSX.Element => {
  const onDeleteButtonClick = useCallback(() => {
    onDeleteCLick?.(locale);
  }, [locale, onDeleteCLick]);

  return (
    <LocaleItemStyled className={className}>
      <LocaleIconStyled name={iconName as AvailableIcons} />
      <div>
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
          {displayName}
        </Typography>
        <LocaleTextStyled type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
          {` (Locale : ${locale})`}
        </LocaleTextStyled>
      </div>
      {onDeleteCLick && (
        <DeleteIconStyled name={AvailableIcons.Close} onClick={onDeleteButtonClick} />
      )}
    </LocaleItemStyled>
  );
};
