import { AccountTypes, APITypes } from 'src/services';

interface ApiErrorPayload {
  error: APITypes.ApiError;
}

export interface UpdateAccountStatusRequestActionPayload {
  accountId: number;
  status: AccountTypes.AccountStatus;
}

export interface Account {
  account: AccountTypes.Account;
}

export type UpdateAccountStatusErrorActionPayload = ApiErrorPayload;
export type UpdateAccountStatusSuccessActionPayload = Account;

/* --= Action Types =-- */
export enum AccountCardActionTypes {
  UpdateAccountStatusRequest = 'AccountCard/UpdateAccountStatusRequest',
  UpdateAccountStatusError = 'AccountCard/UpdateAccountStatusError',
  UpdateAccountStatusSuccess = 'AccountCard/UpdateAccountStatusSuccess',
}

/* --= Action Interfaces =-- */
export interface UpdateAccountStatusRequestAction {
  type: typeof AccountCardActionTypes.UpdateAccountStatusRequest;
  payload: UpdateAccountStatusRequestActionPayload;
}

export interface UpdateAccountStatusErrorAction {
  type: typeof AccountCardActionTypes.UpdateAccountStatusError;
  payload: UpdateAccountStatusErrorActionPayload;
}

export interface UpdateAccountStatusSuccessAction {
  type: typeof AccountCardActionTypes.UpdateAccountStatusSuccess;
  payload: UpdateAccountStatusSuccessActionPayload;
}

export type AccountCardAction =
  | UpdateAccountStatusRequestAction
  | UpdateAccountStatusErrorAction
  | UpdateAccountStatusSuccessAction;
