import React, { forwardRef } from 'react';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import { Icon } from '../Icon';
import {
  ButtonPrimaryStyled,
  ButtonSecondaryStyled,
  ButtonTertiaryStyled,
  ButtonDestructiveStyled,
  ButtonOutlinedStyled,
  SpinnerStyled,
  SpinnerWrapperStyled,
} from './Button.styles';
import { ButtonProps, ButtonSize } from './Button.types';

const COMPONENT_NAME = 'Button';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      onClick,
      onMouseDown,
      size = 'medium',
      startIcon,
      endIcon,
      disabled,
      loading,
      className,
      variant,
      prefixId,
      progress,
    },
    ref
  ): JSX.Element => {
    const dataId = prefixId ? `${prefixId}_${COMPONENT_NAME}` : '';

    const onButtonClick = (event: React.MouseEvent<HTMLElement>) => {
      if (!disabled && !loading) {
        onClick?.(event);
      }
    };

    const onMouseDownClick = (event: React.MouseEvent<HTMLElement>) => {
      if (!disabled && !loading) {
        onMouseDown?.(event);
      }
    };

    const baseProps: Pick<
      MuiButtonProps,
      'startIcon' | 'endIcon' | 'disabled' | 'onClick' | 'className' | 'ref' | 'onMouseDown'
    > & {
      loading?: boolean;
      'data-id': string;
      buttonSize: ButtonSize;
    } = {
      startIcon: startIcon && <Icon name={startIcon} />,
      endIcon: endIcon && <Icon name={endIcon} />,
      disabled,
      className,
      ref,
      onClick: onButtonClick,
      onMouseDown: onMouseDownClick,
      // spinner is hidden with CSS and shown when loading is true
      loading,
      'data-id': dataId,
      buttonSize: size,
    };

    let typographyVariant: TypographyVariant;

    switch (size) {
      case 'tiny':
      case 'small':
        typographyVariant = TypographyVariant.SmallMedium;
        break;
      case 'medium':
      default:
        typographyVariant = TypographyVariant.MediumMedium;
    }

    const content = (
      <>
        <SpinnerWrapperStyled loading={loading} buttonSize={size}>
          <SpinnerStyled loading={loading} buttonSize={size} />
          {progress}
        </SpinnerWrapperStyled>
        <Typography
          type={TypographyType.Button}
          variant={typographyVariant}
          className='syte-button-text'
        >
          {children}
        </Typography>
      </>
    );

    switch (variant) {
      case 'secondary':
        return (
          <ButtonSecondaryStyled {...baseProps} variant='contained'>
            {content}
          </ButtonSecondaryStyled>
        );
      case 'tertiary':
        return (
          <ButtonTertiaryStyled {...baseProps} variant='text'>
            {content}
          </ButtonTertiaryStyled>
        );
      case 'destructive':
        return (
          <ButtonDestructiveStyled {...baseProps} variant='contained'>
            {content}
          </ButtonDestructiveStyled>
        );
      case 'outlined':
        return (
          <ButtonOutlinedStyled {...baseProps} variant='outlined'>
            {content}
          </ButtonOutlinedStyled>
        );
      case 'primary':
      default:
        return (
          <ButtonPrimaryStyled {...baseProps} variant='contained'>
            {content}
          </ButtonPrimaryStyled>
        );
    }
  }
);
