import React, { createContext, useContext } from 'react';
import { State } from './useSocialConnectionState/types';

interface SocialConnectionProviderProps {
  state: State;
  disconnect: () => Promise<void>;
}

export const SocialConnectionContext = createContext<{
  state: State;
  disconnect: () => Promise<void>;
} | null>(null);

export const ConnectionStateProvider: React.FC<SocialConnectionProviderProps> = ({
  state,
  disconnect,
  children,
}) => {
  return (
    <SocialConnectionContext.Provider value={{ state, disconnect }}>
      {children}
    </SocialConnectionContext.Provider>
  );
};

export const useConnectionStateProvider = () => {
  const context = useContext(SocialConnectionContext);
  if (!context) {
    throw new Error('useConnectionStateProvider should be used within a ConnectionStateProvider');
  }
  return context;
};
