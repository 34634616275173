import Joi from 'joi';
import { PersonalizationFallbackMethod } from '../../types/enums/personalization-fallback-method';
import { SortingOrder } from '../../types/enums/sorting-order';

export const personalizationSettingsValidationSchema = {
  numberOfResultsToDisplay: Joi.number().required().min(3).max(20),
  personalizationFallbackMethod: Joi.string().required(),
  useSessionSkusFromPageViewEvent: Joi.boolean().required(),
  addToCart: Joi.object({ active: Joi.boolean() }).required(),
  dataFields: Joi.array(),
  personalizationFallbackField: Joi.alternatives().conditional('personalizationFallbackMethod', {
    is: PersonalizationFallbackMethod.CustomFallback,
    then: Joi.string().required(),
    otherwise: Joi.string(),
  }),
  sortingOrder: Joi.string()
    .optional()
    .valid(...Object.values(SortingOrder)),
};
