import styled from '@emotion/styled';

export const SettingsForm = styled.div`
  flex-direction: column;
  overflow-y: auto;
  flex-grow: 1;
  padding: 8px;
  display: flex;
  gap: 24px;
`;
