import { EditExperimentContainerAction } from 'src/containers';
import { UseKnnReducerState } from '../app-state-types';
import { UseKnnAction, UseKnnActionTypes, VariantPanelAction } from '../components-bl';

const initialState: UseKnnReducerState = {
  useKnn: false,
  shouldRefetch: false,
};

export function useKnnReducer(
  state: UseKnnReducerState = initialState,
  action?: UseKnnAction | EditExperimentContainerAction | VariantPanelAction
): UseKnnReducerState {
  switch (action?.type) {
    case UseKnnActionTypes.GetUseKnnSuccess: {
      const { useKnn } = action.payload;
      return {
        ...state,
        useKnn,
        shouldRefetch: false,
      };
    }
    case UseKnnActionTypes.GetUseKnnRequest:
      return { ...state };
    case UseKnnActionTypes.GetUseKnnError:
      return { ...state };
    case UseKnnActionTypes.UpdateUseKnnSuccess: {
      const { useKnn } = action.payload;
      return {
        ...state,
        useKnn,
      };
    }
    default:
      return state;
  }
}
