import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { ImportLexiconModal } from 'src/components-bl/Lexicon';
import { useAppSelector } from 'src/hooks';
import { ILexiconTagsPaginationAPI } from 'src/services';
import { useValidateLocale } from '../../hooks';
import { importLexiconContainerActions } from './ImportLexiconContainer.actions';

export interface ImportLexiconContainerContainerProps extends RoutedComponentProps {
  refetchLexiconTags?: () => Promise<ILexiconTagsPaginationAPI>;
}

export function ImportLexiconContainer({
  permittedRouteMap,
  refetchLexiconTags,
}: ImportLexiconContainerContainerProps): JSX.Element | null {
  const dispatch = useDispatch();

  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const progress = useAppSelector(state => state.lexicon.importExportFile)?.progress;
  const tagType = useAppSelector(state => state.lexicon.currentSelectedTagType);

  const locale = useValidateLocale({ permittedRouteMap, dispatch, shopId })?.locale;

  const navigateToLexiconTable = useCallback(() => {
    if (permittedRouteMap.lexiconCategories?.path && shopId && locale) {
      dispatch(
        importLexiconContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.lexiconCategories.path, {
            shopId,
            locale,
            tagType,
          }),
        })
      );
    }
  }, [permittedRouteMap.lexiconCategories, locale, shopId, dispatch, tagType]);

  const onSuccess = useCallback(() => {
    navigateToLexiconTable();
    refetchLexiconTags?.();
  }, [refetchLexiconTags, navigateToLexiconTable]);

  if (!shopId || !locale) {
    return null;
  }

  return shopId ? (
    <ImportLexiconModal
      shopId={shopId}
      locale={locale}
      progress={progress}
      dispatch={dispatch}
      onClose={navigateToLexiconTable}
      onSuccess={onSuccess}
    />
  ) : null;
}
