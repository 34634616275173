import React, { ReactNode } from 'react';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { ContentStyled, RuleConditionsGroupStyled, SubtitleStyled } from './ConditionsGroup.styled';

export interface RuleConditionsGroupProps {
  children: ReactNode | ReactNode[];
}

export const RuleConditionsGroupTitle = ({ children }: RuleConditionsGroupProps): JSX.Element => {
  return (
    <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
      {children}
    </Typography>
  );
};

export const RuleConditionsGroupSubTitle = ({
  children,
}: RuleConditionsGroupProps): JSX.Element => {
  return (
    <SubtitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
      {children}
    </SubtitleStyled>
  );
};

export const ConditionsGroup = ({ children }: RuleConditionsGroupProps): JSX.Element => {
  return <RuleConditionsGroupStyled>{children}</RuleConditionsGroupStyled>;
};

ConditionsGroup.Title = RuleConditionsGroupTitle;
ConditionsGroup.SubTitle = RuleConditionsGroupSubTitle;
ConditionsGroup.Content = ContentStyled;
