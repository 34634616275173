import React, { useCallback, useState } from 'react';
import { AvailableIcons } from 'src/components-dummy';
import { BaseBulkActionProps } from '../BulkActions.types';
import { ActionButtonStyled } from '../BulkActions.styles';
import { lexiconBulkActions } from '../LexiconBulkAction.actions';
import { RestoreTagsModal } from './RestoreTagsModal';

type LexiconBulkActionRestoreProps = Omit<BaseBulkActionProps, 'tagType'>;

export const LexiconBulkActionRestore = ({
  targetItemsCounts,
  shopId,
  locale,
  matchTags,
  excludeTags,
  filters,
  dispatch,
  targetAllTags,
  onActionSuccess,
  onActionFail,
  onActionStart,
}: LexiconBulkActionRestoreProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onRestoreClick = useCallback(() => {
    onActionStart();

    if (targetAllTags) {
      (
        dispatch(
          lexiconBulkActions.restoreAll({
            shopId,
            locale,
            excludeTags: [...excludeTags],
            filters,
          })
        ) as any
      )
        .unwrap()
        .then(() => {
          onActionSuccess();
        })
        .catch((error: Error) => {
          console.error(error);
          onActionFail();
        });
    } else {
      (
        dispatch(
          lexiconBulkActions.restore({
            shopId,
            locale,
            matchTags: [...matchTags],
          })
        ) as any
      )
        .unwrap()
        .then(() => {
          onActionSuccess();
        })
        .catch((error: Error) => {
          console.error(error);
          onActionFail();
        });
    }

    setShowModal(false);
  }, [
    shopId,
    locale,
    targetAllTags,
    matchTags,
    excludeTags,
    filters,
    onActionSuccess,
    onActionStart,
    onActionFail,
    dispatch,
  ]);

  return (
    <>
      <ActionButtonStyled
        marginRight={0}
        variant='secondary'
        onClick={openModal}
        clicked={showModal}
        startIcon={AvailableIcons.ResetArrow}
      >
        Restore to source
      </ActionButtonStyled>

      {showModal && (
        <RestoreTagsModal
          onCancel={closeModal}
          tagsCount={targetItemsCounts}
          onRestoreClick={onRestoreClick}
        />
      )}
    </>
  );
};
