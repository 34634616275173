import React, { useCallback } from 'react';
import { Pagination } from 'src/components-dummy/Pagination';
import { PaginationItemsPerPage } from './PaginationItemsPerPage';
import { PaginationStatusText } from './PaginationStatusText';
import { PaginationWithItemsPerPageStyled } from './Pagination.styles';

const DEFAULT_OPTIONS_PER_PAGE = [10, 15, 25, 50];

export interface PaginationWithItemsPerPageProps {
  skip?: number;
  limit?: number;
  totalItemsCount: number;
  onPaginationChange: ({ skip, limit }: { skip: number; limit: number }) => void;
  optionsPerPage?: number[];
  pagesToShow?: number;
  itemsPerPageText?: string;
}

/**
 * Includes:
 * - Pagination (page - skip selection)
 * - Items per page (limit selection)
 * - Status text - example: "1-10 of 55"
 */
export const PaginationWithItemsPerPage = ({
  skip = 0,
  limit = 10,
  totalItemsCount,
  optionsPerPage = DEFAULT_OPTIONS_PER_PAGE,
  pagesToShow = 5,
  itemsPerPageText = 'Items per page',
  onPaginationChange,
}: PaginationWithItemsPerPageProps): JSX.Element => {
  const onPageChange = useCallback(
    (newSkip: number) => {
      onPaginationChange({ skip: newSkip, limit });
    },
    [skip, limit]
  );

  const onLimitChange = useCallback(
    (newLimit: number) => {
      onPaginationChange({
        skip: 0, // Reset on change limit
        limit: newLimit,
      });
    },
    [skip, limit]
  );

  return (
    <PaginationWithItemsPerPageStyled>
      <PaginationItemsPerPage
        value={limit}
        optionsPerPage={optionsPerPage}
        text={itemsPerPageText}
        onChange={onLimitChange}
      />
      <PaginationStatusText skip={skip} limit={limit} totalItems={totalItemsCount} />
      <Pagination
        skip={skip}
        itemsPerPage={limit} // limit
        totalItemsCount={totalItemsCount}
        pagesToShow={pagesToShow} // total pages range numbers in pagination nav
        onPageChange={onPageChange}
      />
    </PaginationWithItemsPerPageStyled>
  );
};
