import { generatePath } from 'react-router-dom';
import { collectionNavigationActionMethods, Dispatch } from '../../components-bl';
import { collectionsRoutes } from '../../app-routes';

interface UseCreateDefaultCollection {
  shopId: number;
  dispatch: Dispatch;
}

type CreateDefaultCollectionCallback = (dispatch: Dispatch) => void;

interface UseCreateDefaultCollectionReturnType {
  onCreateDefaultCollection: VoidFunction;
}

export const useCreateDefaultCollection = ({
  shopId,
  dispatch,
}: UseCreateDefaultCollection): UseCreateDefaultCollectionReturnType => {
  const createDefaultCollection = (cb: CreateDefaultCollectionCallback) => {
    cb(dispatch);
  };

  const navigateToCreateCollection = () => {
    dispatch(
      collectionNavigationActionMethods.navigateTo({
        navigateTo: generatePath(collectionsRoutes.createCollection?.path, { shopId }),
      })
    );
  };

  const onCreateDefaultCollection = () => {
    createDefaultCollection(navigateToCreateCollection);
  };

  return {
    onCreateDefaultCollection,
  };
};
