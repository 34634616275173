import { SimilarItemsGeneralSettings } from 'src/services/src/service/similar-items/similarItems';
import { Dispatch } from 'src/components-bl/types';
import {
  UpdateSimilarItemsSettingsPayload,
  GetSimilarItemsSettingsArgs,
  GetSimilarItemsSettingsRequestPayload,
  GetSimilarItemsSettingsSuccessPayload,
  GetSimilarItemsSettingsErrorPayload,
  UpdateSimilarItemsSettingsRequestPayload,
  UpdateSimilarItemsSettingsSuccessPayload,
  UpdateSimilarItemsSettingsErrorPayload,
  SimilarItemsActionTypes,
  GetSimilarItemsSettingsRequestAction,
  GetSimilarItemsSettingsErrorAction,
  GetSimilarItemsSettingsSuccessAction,
  UpdateSimilarItemsSettingsRequestAction,
  UpdateSimilarItemsSettingsSuccessAction,
  UpdateSimilarItemsSettingsErrorAction,
  NotifySimilarItemsSettingsIsDirtyAction,
  NotifySimilarItemsSettingsIsDirtyActionPayload,
} from './types';

const similarItemsService = new SimilarItemsGeneralSettings();

/* --= Actions =-- */
const similarItemsSettingsActions = {
  getSimilarItemsSettingsRequest: (
    payload: GetSimilarItemsSettingsRequestPayload
  ): GetSimilarItemsSettingsRequestAction => {
    return {
      type: SimilarItemsActionTypes.GetSimilarItemsSettingsRequest,
      payload,
    };
  },
  getSimilarItemsSettingsSuccess: (
    payload: GetSimilarItemsSettingsSuccessPayload
  ): GetSimilarItemsSettingsSuccessAction => {
    return {
      type: SimilarItemsActionTypes.GetSimilarItemsSettingsSuccess,
      payload,
    };
  },
  getSimilarItemsSettingsError: ({
    error,
  }: GetSimilarItemsSettingsErrorPayload): GetSimilarItemsSettingsErrorAction => {
    return {
      type: SimilarItemsActionTypes.GetSimilarItemsSettingsError,
      payload: { error },
    };
  },
  updateSimilarItemsSettingsRequest: (
    payload: UpdateSimilarItemsSettingsRequestPayload
  ): UpdateSimilarItemsSettingsRequestAction => {
    return {
      type: SimilarItemsActionTypes.UpdateSimilarItemsSettingsRequest,
      payload,
    };
  },
  updateSimilarItemsSettingsSuccess: (
    payload: UpdateSimilarItemsSettingsSuccessPayload
  ): UpdateSimilarItemsSettingsSuccessAction => {
    return {
      type: SimilarItemsActionTypes.UpdateSimilarItemsSettingsSuccess,
      payload,
    };
  },
  updateSimilarItemsSettingsError: (
    payload: UpdateSimilarItemsSettingsErrorPayload
  ): UpdateSimilarItemsSettingsErrorAction => {
    return {
      type: SimilarItemsActionTypes.UpdateSimilarItemsSettingsError,
      payload,
    };
  },

  notifyIsDirtyState: (
    payload: NotifySimilarItemsSettingsIsDirtyActionPayload
  ): NotifySimilarItemsSettingsIsDirtyAction => {
    return {
      type: SimilarItemsActionTypes.NotifySimilarItemsSettingsIsDirty,
      payload,
    };
  },
};

export const similarItemsSettingsActionsMethods = {
  notifyIsDirty:
    ({ isDirty }: NotifySimilarItemsSettingsIsDirtyActionPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(similarItemsSettingsActions.notifyIsDirtyState({ isDirty }));
    },

  fetchSimilarItemsSettings:
    ({ shopId, variantId }: GetSimilarItemsSettingsArgs) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(similarItemsSettingsActions.getSimilarItemsSettingsRequest({ shopId }));
        const { numberOfResultsToDisplay, addToCart, enableTextSimilarity } =
          await similarItemsService.get({
            shopId,
            variantId,
          });
        dispatch(
          similarItemsSettingsActions.getSimilarItemsSettingsSuccess({
            shopId,
            numberOfResultsToDisplay,
            addToCart,
            enableTextSimilarity,
          })
        );
      } catch (error) {
        dispatch(similarItemsSettingsActions.getSimilarItemsSettingsError({ error }));
      }
    },

  updateSimilarItemsSettings:
    ({
      shopId,
      variantId,
      numberOfResultsToDisplay,
      addToCart,
      enableTextSimilarity,
    }: UpdateSimilarItemsSettingsPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(
          similarItemsSettingsActions.updateSimilarItemsSettingsRequest({
            shopId,
            numberOfResultsToDisplay,
            addToCart,
            enableTextSimilarity,
          })
        );

        await similarItemsService.update({
          shopId,
          variantId,
          numberOfResultsToDisplay,
          addToCart,
          enableTextSimilarity,
        });

        dispatch(
          similarItemsSettingsActions.updateSimilarItemsSettingsSuccess({
            shopId,
            numberOfResultsToDisplay,
            addToCart,
            enableTextSimilarity,
          })
        );
      } catch (error) {
        dispatch(similarItemsSettingsActions.updateSimilarItemsSettingsError({ error }));
      }
    },
};
