import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Page, BackButtonTitle } from 'src/components-dummy';
import { useAppSelector } from 'src/hooks';
import { SimilarItemsSettings } from 'src/components-bl';
import { UserRoles } from 'src/services/src/service/types/users';
import { ShopRoutes } from '../../types/enums/shop-routes';
import './SimilarItemsContainer.scss';

export const SimilarItemsContainer = (): JSX.Element | null => {
  const formHeaderRef = useRef<HTMLDivElement>(null);

  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const similarItemsSettings = useAppSelector(state => state.similarItemsSettings);
  const role = useAppSelector(state => state.global.loggedInUser?.role);
  const dispatch = useDispatch();
  const navigationPath = `/shops/${shopId}/${ShopRoutes.ShopperExperiences}`;

  return (
    <Page className='similar-items-container'>
      <Page.Header>
        <BackButtonTitle path={navigationPath}>Similar Items</BackButtonTitle>
        <div className='similar-items-buttons-section' ref={formHeaderRef} />
      </Page.Header>
      <Page.Content>
        {shopId && (
          <SimilarItemsSettings
            similarItemsSettings={similarItemsSettings}
            shopId={shopId}
            formHeaderElementRef={formHeaderRef}
            isUserAdmin={role === UserRoles.SyteAdmin}
            dispatch={dispatch}
          />
        )}
      </Page.Content>
    </Page>
  );
};
