import classNames from 'classnames';
import React from 'react';
import { DiffActionType } from 'src/app-types/enums/versioning';
import { DiffsCardProps } from './types';
import {
  StatusCard,
  StatusCardStatusColor,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../components-dummy';
import { ChangedByTooltip, DiffsCardChangesTable } from './components';
import './DiffsCard.scss';

export const DiffsCard = ({
  title,
  className,
  actionType,
  changes,
  changedAt,
  changedBy,
  previewType,
}: DiffsCardProps): JSX.Element => {
  const isExpandedMode = actionType === DiffActionType.Changed;

  let statusColor: StatusCardStatusColor | undefined;
  switch (actionType) {
    case DiffActionType.Created:
      statusColor = StatusCardStatusColor.Green;
      break;
    case DiffActionType.Deleted:
      statusColor = StatusCardStatusColor.Red;
      break;
    default:
      statusColor = StatusCardStatusColor.Yellow;
      break;
  }

  return (
    <div
      className={classNames('syte-diffs-card', className, {
        'syte-diffs-card-expanded': isExpandedMode,
      })}
    >
      <StatusCard
        className={classNames('syte-diffs-card-header')}
        statusText={actionType}
        statusColor={statusColor}
      >
        <Typography
          type={TypographyType.Body}
          variant={TypographyVariant.LargeMedium}
          className='syte-diffs-card-title'
        >
          {title}
        </Typography>
        {!isExpandedMode && <ChangedByTooltip changedBy={changedBy} changedAt={changedAt} />}
      </StatusCard>
      {isExpandedMode && (
        <div className='syte-diffs-card-content'>
          <DiffsCardChangesTable changes={changes} previewType={previewType} />
        </div>
      )}
    </div>
  );
};
