import { useCallback, useEffect, useMemo } from 'react';
import md5 from 'crypto-js/md5';
import { Dispatch } from 'src/components-bl';
import {
  userLocationWebSocketService,
  IUserLocation,
  IUserLocationUpdateEvent,
  IUserOnlineUpdateEvent,
} from 'src/services';
import { useInterval } from 'src/hooks/useInterval';
import { useAppSelector } from 'src/hooks';
import { shallowEqual } from 'react-redux';
import { useLocation, matchPath } from 'react-router';
import { baseAppRouteMap } from 'src/app-routes';
import { rootContainerActions } from './Actions';

const UPDATE_USER_LOCATION_IN_SEC = 3;

const pathBlackList = new Set<string>([baseAppRouteMap.myProfile.path]);

function calculateLocationHash(location: Omit<IUserLocation, 'hash'>): string {
  const hash = md5(`${location.uri}${location.context.accountId}${location.context.shopId}`);
  return hash.toString();
}

export const useUserLocationWS = ({ dispatch }: { dispatch: Dispatch }): void => {
  const { currentUser, isEditing, shopId, accountId, permittedRoutes } = useAppSelector(store => {
    return {
      shopId: store.shop.current?.shopId,
      accountId: store.account.current?.accountId,
      isEditing: store.global.isDirty,
      permittedRoutes: store.global.permittedRoutes,
      currentUser: store.global.loggedInUser,
    };
  }, shallowEqual);

  const location = useLocation();

  const currentPathTemplate = useMemo(() => {
    const matchedPathArray =
      permittedRoutes
        ?.filter(route => matchPath(location.pathname, route.path))
        .sort((a, b) => b.path.length - a.path.length) || [];

    const resultPath = matchedPathArray?.[0]?.path;
    return resultPath;
  }, [location.pathname, permittedRoutes]);

  const userLocation: IUserLocation | null = useMemo(() => {
    const shouldResetLocation = !currentPathTemplate || pathBlackList.has(currentPathTemplate);

    const partialLocation = {
      uri: location.pathname,
      isEditing,
      context: {
        accountId,
        shopId,
      },
    };
    const hash = calculateLocationHash(partialLocation);

    return shouldResetLocation ? null : { ...partialLocation, hash };
  }, [shopId, accountId, currentPathTemplate, isEditing]);

  const updateUserLocation = useCallback(() => {
    userLocationWebSocketService.updateUserLocation(userLocation);
  }, [userLocation]);

  const onLocationUpdate = useCallback(
    (locationEvent: IUserLocationUpdateEvent) => {
      if (userLocation === null) {
        dispatch(rootContainerActions.setUserLocation({ users: [] }));
        return;
      }
      if (userLocation.hash === locationEvent.data.locationHash) {
        dispatch(rootContainerActions.setUserLocation({ users: locationEvent.data.users }));
      }
    },
    [userLocation]
  );

  const onUserOnlineUpdate = useCallback(
    (userOnlineEvent: IUserOnlineUpdateEvent) => {
      dispatch(rootContainerActions.setUserOnlineStatus(userOnlineEvent.data));
    },
    [userLocation]
  );

  useInterval({
    isEnabled: true,
    callback: updateUserLocation,
    shouldRunImmediately: true,
    timeOutInMilliseconds: UPDATE_USER_LOCATION_IN_SEC * 1000,
  });

  useEffect(() => {
    if (!currentUser || !accountId) return () => {};

    userLocationWebSocketService.subscribeToLocationUpdates(onLocationUpdate);
    userLocationWebSocketService.subscribeToUserOnlineStatusUpdate(onUserOnlineUpdate);

    return () => {
      userLocationWebSocketService.clearListeners();
    };
  }, [onLocationUpdate, currentUser, accountId]);
};
