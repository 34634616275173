import styled from '@emotion/styled';
import { Page } from 'src/components-dummy';

export const AccountSettingsPageStyled = styled(Page)`
  margin: 20px;

  .syte-page-content {
    padding: 24px 0;
  }
`;
