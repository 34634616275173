import { useState, useEffect } from 'react';
import {
  MultiSelectOption,
  MultiSelectValue,
  UseMultiSelectMenuArguments,
  UseMultiSelectMenuReturnType,
} from './types';

export const ITEMS_TO_SHOW_WITHOUT_SCROLL = 6;

export const selectAllOption: MultiSelectOption = {
  text: 'All',
  value: 'select-all-option',
};

export function useMultiSelect({
  options,
  enableSelectAll,
  selectAllText,
  onChange,
  selectedOptions,
}: UseMultiSelectMenuArguments): UseMultiSelectMenuReturnType {
  const [selectAllOptionState, setSelectAllOptionState] = useState(false);

  const selectedAllOption = { ...selectAllOption, text: selectAllText || selectAllOption.text };

  const [selectedOptionsState, setSelectedOptionsState] = useState<Set<MultiSelectValue>>(
    new Set(selectedOptions)
  );

  const handleToggleAllClick = () => {
    const newState = (
      selectAllOptionState ? new Set() : new Set(options.map(option => option.value))
    ) as Set<MultiSelectValue>;
    setSelectAllOptionState(!selectAllOptionState);

    setSelectedOptionsState(newState as Set<MultiSelectValue>);
    onChange?.([...newState], selectAllText);
  };

  const handleItemClick = (value: MultiSelectValue) => {
    const stateCopy = new Set(selectedOptionsState);

    if (stateCopy.has(value)) {
      stateCopy.delete(value);
    } else {
      stateCopy.add(value);
    }
    setSelectedOptionsState(stateCopy);

    onChange?.([...stateCopy], selectAllText);
  };

  // Sync internal state with external
  useEffect(() => {
    const newState = new Set(selectedOptions);

    const stateIsEqual =
      newState.size === selectedOptionsState.size &&
      [...newState].every(option => selectedOptionsState.has(option));

    if (!stateIsEqual) {
      setSelectedOptionsState(newState);
    }
  }, [selectedOptions]);

  // Handle select all automated checking
  useEffect(() => {
    if (enableSelectAll && selectedAllOption) {
      const allOptionsAreChecked = options.every(option => selectedOptionsState.has(option.value));

      if (!selectAllOptionState && allOptionsAreChecked) {
        setSelectAllOptionState(true);
      } else if (selectAllOptionState && !allOptionsAreChecked) {
        setSelectAllOptionState(false);
      }
    }
  }, [enableSelectAll, options, selectedOptionsState, selectedAllOption]);

  return {
    selectedOptionsState,
    selectAllOptionState,
    selectedAllOption,
    handleItemClick,
    handleToggleAllClick,
  };
}
