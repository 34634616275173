import React from 'react';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy/Typography';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';

export interface ExportCSVConfirmationDialogProps {
  onClose: () => void;
  onExportCatalog: () => void;
}

export const ExportCSVConfirmationDialog = ({
  onExportCatalog,
  onClose,
}: ExportCSVConfirmationDialogProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onClose}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Export Catalog Feed</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content css={{ paddingBottom: '24px', gap: '15px' }}>
        <Typography type={TypographyType.Paragraph} variant={TypographyVariant.LargeRegular}>
          Click below to download the Catalog feed in CSV version
        </Typography>
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <Button variant='primary' onClick={onExportCatalog}>
          Export Catalog
        </Button>
        <Button variant='tertiary' onClick={onClose}>
          Cancel
        </Button>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
