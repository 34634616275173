import React from 'react';
import { DialogModal } from '../DialogModal';
import { EllipsisWithTooltip } from '../EllipsisWithToolTip';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import {
  ContentStyled,
  HeaderStyled,
  ConfirmationDialogStyled,
  FooterStyled,
  ButtonsContainer,
  HeaderCloseButtonWithIconStyled,
} from './ConfirmationDialog.style';
import { AvailableIcons } from '../Icon';

export const Title = ({
  children,
}: {
  children: React.ReactChild | React.ReactChild[];
}): JSX.Element => (
  <EllipsisWithTooltip tooltipPosition='top center' tooltipText={children as string}>
    <Typography type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
      {children}
    </Typography>
  </EllipsisWithTooltip>
);

export const ContentMainText = ({
  children,
}: {
  children: React.ReactChild | React.ReactChild[] | string;
}): JSX.Element => (
  <Typography type={TypographyType.Paragraph} variant={TypographyVariant.ExtraLargeRegular}>
    {children}
  </Typography>
);

export const HeaderCloseButtonWithIcon = ({ onCancel }: { onCancel: () => void }): JSX.Element => (
  <HeaderCloseButtonWithIconStyled name={AvailableIcons.Close} onClick={onCancel} />
);

/**
 * Main component
 */
export interface ConfirmationDialogProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
  onCancel?: () => void;
}

const onConfirmationDialogClick = (ev: React.MouseEvent) => {
  ev.stopPropagation();
};

export const ConfirmationDialog = ({
  children,
  className,
  onCancel,
}: ConfirmationDialogProps): JSX.Element => {
  return (
    <DialogModal open onClose={onCancel}>
      <ConfirmationDialogStyled onClick={onConfirmationDialogClick} className={className}>
        {children}
      </ConfirmationDialogStyled>
    </DialogModal>
  );
};

ConfirmationDialog.Header = HeaderStyled;
ConfirmationDialog.Title = Title;
ConfirmationDialog.Content = ContentStyled;
ConfirmationDialog.ContentMainText = ContentMainText;
ConfirmationDialog.Footer = FooterStyled;
ConfirmationDialog.ButtonsContainer = ButtonsContainer;
ConfirmationDialog.HeaderCloseButtonWithIcon = HeaderCloseButtonWithIcon;
