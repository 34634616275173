import React from 'react';
import classNames from 'classnames';
import { NavTab, NavTabProps } from './NavTab';
import './NavTabs.scss';

export interface NavTabsProps {
  selected: string;
  onSelect?: (name: string) => void;
  children?: React.ReactElement<NavTabProps> | Array<React.ReactElement<NavTabProps>>;
}

export const NavTabs = ({ selected, onSelect, children }: NavTabsProps): JSX.Element => {
  function onTabClick(props: NavTabProps) {
    onSelect?.(props.name);
    props.onClick?.();
  }

  function mapTabs() {
    if (!children) {
      return <></>;
    }

    const tabs = children instanceof Array ? children : [children];
    return tabs.map(elem => {
      const { name, href, className } = elem.props;
      const isSelected = name === selected;
      return (
        <li
          className={classNames(
            'syte-nav-tabs-tab',
            isSelected && 'syte-nav-tabs-tab-selected',
            className
          )}
          key={name}
        >
          <a href={href} onClick={() => onTabClick(elem.props)}>
            {elem.props.children}
          </a>
          {isSelected && <div className='syte-nav-tab-highlight' />}
        </li>
      );
    });
  }

  return <ul className='syte-nav-tabs'>{mapTabs()}</ul>;
};

NavTabs.NavTab = NavTab;
