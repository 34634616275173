import styled from '@emotion/styled';

export const GridLayoutSectionStyled = styled.div`
  margin-top: 16px;
  padding: 24px 16px 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-80']};
  background: ${({ theme }) => theme.palette.custom['gray-120']};

  & > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.custom['gray-80']};
  }

  & > div {
    padding-bottom: 24px;
  }
`;

export const GridLayoutTypesRow = styled.div`
  display: flex;
  gap: 16px;
`;
