import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../hooks';
import { EditCollectionRulesContainer } from './EditCollectionRulesContainer';
import { CollapseDrawer } from '../CollapseDrawer/CollapseDrawer';
import { useNavigateToVisualEditorRoute } from '../../hooks/useNavigateToVisualEditorRoute';
import { PermittedRouteMap } from '../../../../app-routes';

interface Props {
  shopId: number;
  permittedRouteMap: PermittedRouteMap;
  className: string;
}

export const EditCollectionRules = ({
  shopId,
  permittedRouteMap,
  className,
}: Props): JSX.Element | null => {
  const { currentCollection } = useAppSelector(state => state.collections);
  const appDispatch = useDispatch();
  const { navigateToVisualEditorRoute } = useNavigateToVisualEditorRoute({
    route: permittedRouteMap?.visualEditor,
  });

  return (
    <CollapseDrawer
      open
      className={className}
      onClose={() => navigateToVisualEditorRoute({ shopId })}
    >
      <EditCollectionRulesContainer
        shopId={shopId}
        appDispatch={appDispatch}
        currentCollection={currentCollection}
        permittedRouteMap={permittedRouteMap}
      />
    </CollapseDrawer>
  );
};
