import React from 'react';

import './ShopperExperiencesContent.scss';
import { Column, LabelCell, Table } from '../../../components-dummy';
import { LinkCell } from '../../../components-dummy/Table/Components/LinkCell';
import { IRoutedExperience } from '../../../services';

interface Props {
  shopperExperiences: IRoutedExperience[];
}

export const ShopperExperiencesContent = ({ shopperExperiences }: Props) => {
  if (!shopperExperiences.length) return null;
  return (
    <Table data={shopperExperiences} className='shopper-experiences-table' updateData={() => {}}>
      <Column Header='Product' accessor='content' className='product-cell' Cell={LinkCell} />
      <Column Header='Layout' accessor='layout' Cell={LabelCell} />
    </Table>
  );
};
