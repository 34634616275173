import { createAsyncThunk } from '@reduxjs/toolkit';
import { dataFieldsService } from 'src/services';

export const dataFieldSingleAutoSuggestActions = {
  getValues: createAsyncThunk(
    'DataFieldValuesAutoSuggest/GetDataFieldValues',
    async (
      {
        shopId,
        searchTerm,
        dataField,
        limit,
      }: {
        shopId: number;
        searchTerm: string;
        dataField: string;
        limit: number;
      },
      { rejectWithValue }
    ) => {
      try {
        const { values: dataFieldValues } = await dataFieldsService.searchFeedDataFieldValues({
          shopId,
          primaryDataField: dataField,
          searchTerm,
          limit,
        });
        return dataFieldValues.map(dataFieldValue => dataFieldValue[dataField]).filter(Boolean);
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
