/* --= Action Types =-- */
import { AddToCart } from '../../../app-state-types/reducer-state-types/add-to-cart';

export enum SimilarItemsActionTypes {
  GetSimilarItemsSettingsRequest = 'SimilarItems/GetSimilarItemsSettingsRequest',
  GetSimilarItemsSettingsSuccess = 'SimilarItems/GetSimilarItemsSettingsSuccessAction',
  GetSimilarItemsSettingsError = 'SimilarItems/GetSimilarItemsSettingsError',
  UpdateSimilarItemsSettingsRequest = 'SimilarItems/UpdateSimilarItemsSettingsRequest',
  UpdateSimilarItemsSettingsSuccess = 'SimilarItems/UpdateSimilarItemsSettingsSuccess',
  UpdateSimilarItemsSettingsError = 'SimilarItems/UpdateSimilarItemsSettingsError',
  NotifySimilarItemsSettingsIsDirty = 'SimilarItems/NotifyIsDirty',
  PartialSimilarItemsSettingsUpdate = 'SimilarItems/PartialSimilarItemsSettingsUpdate',
  NavigateTo = 'SimilarItems/NavigateTo',
}
export interface UpdateSimilarItemsSettingsPayload {
  shopId: number;
  variantId?: string;
  numberOfResultsToDisplay: number;
  addToCart: AddToCart;
  enableTextSimilarity: boolean;
}

export interface GetSimilarItemsSettingsArgs {
  shopId: number;
  variantId?: string;
}

export interface GetSimilarItemsSettingsRequestPayload {
  shopId: number;
}

export interface GetSimilarItemsSettingsSuccessPayload {
  shopId: number;
  numberOfResultsToDisplay: number;
  addToCart: AddToCart;
  enableTextSimilarity: boolean;
}

export interface GetSimilarItemsSettingsErrorPayload {
  error: unknown;
}
export interface UpdateSimilarItemsSettingsRequestPayload {
  shopId: number;
  numberOfResultsToDisplay: number;
  addToCart: AddToCart;
  enableTextSimilarity: boolean;
}

export interface UpdateSimilarItemsSettingsSuccessPayload {
  shopId: number;
  numberOfResultsToDisplay: number;
  addToCart: AddToCart;
  enableTextSimilarity: boolean;
}

export interface UpdateSimilarItemsSettingsErrorPayload {
  error: unknown;
}

export interface NotifySimilarItemsSettingsIsDirtyActionPayload {
  isDirty: boolean;
}

/* --= Action Interfaces =-- */

export interface GetSimilarItemsSettingsRequestAction {
  type: typeof SimilarItemsActionTypes.GetSimilarItemsSettingsRequest;
  payload: GetSimilarItemsSettingsRequestPayload;
}

export interface GetSimilarItemsSettingsSuccessAction {
  type: typeof SimilarItemsActionTypes.GetSimilarItemsSettingsSuccess;
  payload: GetSimilarItemsSettingsSuccessPayload;
}

export interface GetSimilarItemsSettingsErrorAction {
  type: typeof SimilarItemsActionTypes.GetSimilarItemsSettingsError;
  payload: GetSimilarItemsSettingsErrorPayload;
}

export interface UpdateSimilarItemsSettingsRequestAction {
  type: typeof SimilarItemsActionTypes.UpdateSimilarItemsSettingsRequest;
  payload: UpdateSimilarItemsSettingsRequestPayload;
}

export interface UpdateSimilarItemsSettingsSuccessAction {
  type: typeof SimilarItemsActionTypes.UpdateSimilarItemsSettingsSuccess;
  payload: UpdateSimilarItemsSettingsSuccessPayload;
}

export interface UpdateSimilarItemsSettingsErrorAction {
  type: typeof SimilarItemsActionTypes.UpdateSimilarItemsSettingsError;
  payload: UpdateSimilarItemsSettingsErrorPayload;
}

export interface NotifySimilarItemsSettingsIsDirtyAction {
  type: typeof SimilarItemsActionTypes.NotifySimilarItemsSettingsIsDirty;
  payload: NotifySimilarItemsSettingsIsDirtyActionPayload;
}

export type SimilarItemsSettingsAction =
  | GetSimilarItemsSettingsRequestAction
  | GetSimilarItemsSettingsSuccessAction
  | GetSimilarItemsSettingsErrorAction
  | UpdateSimilarItemsSettingsRequestAction
  | UpdateSimilarItemsSettingsSuccessAction
  | UpdateSimilarItemsSettingsErrorAction
  | NotifySimilarItemsSettingsIsDirtyAction;
