import styled from '@emotion/styled';
import { FormCard, Typography } from 'src/components-dummy';

export const JsSnippetSectionStyled = styled(FormCard)`
  max-width: 752px;
  margin-top: 24px;
`;

export const JsSnippetSubTitleStyled = styled(Typography)``;

export const JsSnippetCaptionStyled = styled(Typography)`
  margin: 10px 0;
`;
