import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  Typography,
  TypographyType,
  TypographyVariant,
  AvailableIcons,
} from 'src/components-dummy';
import { DateRangePickerWithButtonsContentProps } from 'src/components-dummy/DateRangePicker/DateRangePickerWithButtonsContent';
import { format } from 'date-fns';
import { DateRangePickerWithButtonsModal } from 'src/components-dummy/DateRangePicker';
import { FilterTagStyled, RemoveFilterTagIconStyled } from '../FiltersRow.styles';
import { DateRangeFilterTagProps } from '../types';
import { anchorOriginDefault, transformOriginDefault } from '../FilterRow.config';

export const DATE_FORMAT = 'dd/MM/yyyy';

const formatDate = (date: Date) => format(date, DATE_FORMAT);

export const DateRangeFilterTag = ({
  filterKey,
  onRemoveFilter,
  values,
  filterState,
  onChange,
  shouldTriggerOpen,
  onCloseFilter,
  tagPrefix,
}: DateRangeFilterTagProps): JSX.Element => {
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const [selectedDates, setSelectedDates] = useState(values);

  const onTriggerClick = () => {
    setMenuAnchorEl(triggerRef.current);
  };

  const onClose = () => {
    setMenuAnchorEl(null);

    if (filterState?.isApplied === false) {
      onRemoveFilter?.(filterKey);
    } else {
      onCloseFilter?.();
    }
  };

  const onApplyClick: DateRangePickerWithButtonsContentProps['onApply'] = ({
    startDate,
    endDate,
  }) => {
    onClose();
    if (startDate === null || endDate === null) {
      setSelectedDates({ startDate: null, endDate: null });
      onChange({ [filterKey]: { value: { startDate: null, endDate: null }, isApplied: false } });
    } else {
      setSelectedDates({ startDate, endDate });
      onChange({ [filterKey]: { value: { startDate, endDate }, isApplied: true } });
    }
  };

  useEffect(() => {
    if (shouldTriggerOpen) {
      setMenuAnchorEl(triggerRef.current);
    }
  }, [shouldTriggerOpen]);

  const dateRangeText = useMemo((): string => {
    if (selectedDates.startDate instanceof Date && selectedDates.endDate instanceof Date) {
      return `${formatDate(selectedDates.startDate)} - ${formatDate(selectedDates.endDate)}`;
    }

    return '';
  }, [selectedDates]);

  return (
    <>
      <FilterTagStyled isClickable onClick={onTriggerClick} ref={triggerRef}>
        <Typography type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
          {tagPrefix || 'Value is'}
        </Typography>

        {filterState?.isApplied && (
          <Typography type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
            {dateRangeText}
          </Typography>
        )}
        <RemoveFilterTagIconStyled
          name={AvailableIcons.Close}
          onClick={() => onRemoveFilter?.(filterKey)}
        />
      </FilterTagStyled>
      <DateRangePickerWithButtonsModal
        onApply={onApplyClick}
        anchorEl={menuAnchorEl}
        onClose={onClose}
        startDate={selectedDates.startDate}
        endDate={selectedDates.endDate}
        anchorOrigin={anchorOriginDefault}
        transformOrigin={transformOriginDefault}
      />
    </>
  );
};
