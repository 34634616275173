import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { GalleriesReducerState } from 'src/app-state-types/reducer-state-types';
import {
  addImagesModalFormActions,
  editCustomInspirationsGalleryPageActions,
  editInspirationalImagesGalleryPageActions,
  galleriesTablePageActions,
  viewInspirationalImagesGalleryPageActions,
  tagImageModalActions,
} from 'src/components-bl/Galleries';
import { useGalleriesWSActions } from 'src/containers/Galleries/components/CustomInspirations/useGalleriesWS';
import { CustomInspirationsGallery } from 'src/services';

const initialState: GalleriesReducerState = {
  galleries: undefined,
};

export const galleriesReducer = createReducer(initialState, builder => {
  builder
    .addCase(galleriesTablePageActions.getGalleries.pending, (state, _) => {
      return { ...state, galleries: initialState.galleries };
    })

    .addCase(galleriesTablePageActions.getGalleries.fulfilled, (state, action) => {
      return { ...state, galleries: action.payload };
    })

    .addCase(galleriesTablePageActions.deleteGallery.fulfilled, (state, action) => {
      return {
        ...state,
        galleries: state.galleries?.filter(
          gallery => gallery.id !== action.payload.deletedGalleryId
        ),
      };
    })

    .addCase(useGalleriesWSActions.imageTagsDetectionStarted, (state, action) => {
      if (!state.currentGallery || state.currentGallery.id !== action.payload.galleryId) {
        return state;
      }

      state.currentGallery.images = (state.currentGallery as CustomInspirationsGallery).images.map(
        image => {
          if (image.id === action.payload.imageId) {
            return {
              ...image,
              isDetectingTags: true,
              aiSuggestedTags: [],
            };
          }
          return image;
        }
      );

      return state;
    })

    .addCase(useGalleriesWSActions.galleryTagsDetectionStarted, (state, action) => {
      if (!state.currentGallery || state.currentGallery.id !== action.payload.galleryId) {
        return state;
      }

      state.currentGallery.images = (state.currentGallery as CustomInspirationsGallery).images.map(
        image => ({
          ...image,
          aiSuggestedTags: [],
        })
      );

      (state.currentGallery as CustomInspirationsGallery).isDetectingTags = true;

      return state;
    })

    .addCase(useGalleriesWSActions.galleryTagsDetectionEnded, (state, action) => {
      if (!state.currentGallery || state.currentGallery.id !== action.payload.galleryId) {
        return state;
      }

      (state.currentGallery as CustomInspirationsGallery).isDetectingTags = false;

      return state;
    })

    .addCase(useGalleriesWSActions.imageTagsDetectionEnded, (state, action) => {
      if (!state.currentGallery || state.currentGallery.id !== action.payload.galleryId) {
        return state;
      }

      state.currentGallery.images = (state.currentGallery as CustomInspirationsGallery).images.map(
        image => {
          if (image.id === action.payload.imageId) {
            return {
              ...image,
              isDetectingTags: false,
              aiSuggestedTags: action.payload.aiSuggestedTags,
            };
          }
          return image;
        }
      );

      return state;
    })

    .addMatcher(
      isAnyOf(
        editInspirationalImagesGalleryPageActions.getGallery.fulfilled,
        viewInspirationalImagesGalleryPageActions.getGallery.fulfilled,
        editCustomInspirationsGalleryPageActions.getGallery.fulfilled,
        useGalleriesWSActions.getGallery.fulfilled,
        addImagesModalFormActions.updateGallery.fulfilled,
        editCustomInspirationsGalleryPageActions.updateGallery.fulfilled,
        tagImageModalActions.updateGallery.fulfilled
      ),
      (state, action) => {
        return { ...state, currentGallery: action.payload };
      }
    )
    .addMatcher(
      isAnyOf(
        editInspirationalImagesGalleryPageActions.resetCurrentGallery,
        viewInspirationalImagesGalleryPageActions.resetCurrentGallery,
        editCustomInspirationsGalleryPageActions.resetCurrentGallery
      ),
      state => {
        return { ...state, currentGallery: undefined };
      }
    );
});
