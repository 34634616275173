import React, { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { SyteLayout } from 'src/services/src/service/types/syte-layout';
import { Dispatch } from 'src/components-bl/types';
import { MenuItem, Page, Select, SelectType } from '../../../components-dummy';
import { ShopRoutes } from '../../../types/enums/shop-routes';
import { shopperExperiencesHeaderActions } from './Actions';

import './ShopperExperiencesHeader.scss';

const settingsPagesOptions = [
  {
    text: 'Results modal settings',
    value: 'results_modal_settings',
    route: { to: 'results-modal/general-settings', from: ShopRoutes.ShopperExperiences },
  },
  {
    text: 'Carousels settings',
    value: 'carousels_settings',
    route: { to: 'recommendation-engines/general-settings', from: ShopRoutes.ShopperExperiences },
  },
];

interface ShopperExperiencesHeaderProps extends RoutedComponentProps {
  dispatch: Dispatch;
  shopId?: number;
}

export const ShopperExperiencesHeader = ({
  permittedRouteMap,
  dispatch,
  shopId,
}: ShopperExperiencesHeaderProps): JSX.Element => {
  const history = useHistory();
  const areFiltersEnabled = !!permittedRouteMap.filters;

  const navigateToFilters = useCallback(() => {
    if (permittedRouteMap.filters && shopId) {
      dispatch(
        shopperExperiencesHeaderActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.filters.path, {
            shopId,
            syteLayout: SyteLayout.ResultsModal,
          }),
        })
      );
    }
  }, [dispatch, permittedRouteMap.filters, shopId]);

  return (
    <header className='shopper-experiences-header'>
      <Page.Title>Shopper Experiences</Page.Title>
      <div className='header-button-group'>
        <Select
          type={SelectType.Menu}
          className='settings-dropdown'
          value='General settings'
          onChange={() => {}}
        >
          {settingsPagesOptions.map(({ text, value, route }) => (
            <MenuItem
              key={value}
              value={value}
              onClick={() => history.push({ pathname: route.to, state: { from: route.from } })}
            >
              {text}
            </MenuItem>
          ))}

          {areFiltersEnabled && (
            <MenuItem value='results-modal-filters' onClick={navigateToFilters}>
              Results modal filters settings
            </MenuItem>
          )}
        </Select>
      </div>
    </header>
  );
};
