import React, { useState, useRef, useEffect } from 'react';
import { isValidURL } from 'src/utils/is-valid-url';
import {
  LinkStyled,
  ObjectStyled,
  TextOverflowContainer,
  TextOverflowStyled,
} from './CollapsibleEllipsisTextOverflow.styles';

export const CollapsibleEllipsisTextOverflow = ({ value }: { value: any }) => {
  const [expanded, setExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState('auto');
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      const fullHeight = textRef.current.scrollHeight;
      setContentHeight(expanded ? `${fullHeight}px` : '32px');
    }
  }, [expanded]);

  const toggleExpanded = () => {
    setExpanded(prev => !prev);
  };

  const renderLink = (fieldValue: string) => {
    return (
      <>
        {isValidURL(fieldValue) ? (
          <LinkStyled href={fieldValue}>{fieldValue}</LinkStyled>
        ) : (
          fieldValue
        )}
      </>
    );
  };

  const renderContentByType = (val: any) => {
    if (Array.isArray(val)) {
      return (
        <ObjectStyled>
          <span>{'[ '}</span>
          {expanded && <br />}
          {val.map((displayValue, index) => {
            return (
              <>
                {expanded && <>&nbsp;&nbsp;&nbsp;&nbsp;</>}
                {renderLink(displayValue)}
                {index < val.length - 1 && <>,&nbsp;</>}
                {expanded && index < val.length - 1 && <br />}
              </>
            );
          })}
          {expanded && <br />}
          <span>{' ]'}</span>
        </ObjectStyled>
      );
    } else if (typeof val === 'object') {
      return (
        <ObjectStyled>
          <span>{'{ '}</span>
          {expanded && <br />}
          {Object.entries(val).map(([displayName, displayValue]: any, index) => {
            return (
              <>
                {expanded && <>&nbsp;&nbsp;&nbsp;&nbsp;</>}
                <span>{displayName}</span>
                <>:&nbsp;</>
                {renderLink(displayValue)}
                {index < Object.keys(val).length - 1 && <>,&nbsp;</>}
                {expanded && index < Object.keys(val).length - 1 && <br />}
              </>
            );
          })}
          {expanded && <br />}
          <span>{' }'}</span>
        </ObjectStyled>
      );
    } else {
      return renderLink(val);
    }
  };

  return (
    <TextOverflowContainer contentHeight={contentHeight} onClick={toggleExpanded} ref={textRef}>
      <TextOverflowStyled expanded={expanded}>{renderContentByType(value)}</TextOverflowStyled>
    </TextOverflowContainer>
  );
};
