import React, { CSSProperties, useCallback } from 'react';
import { Dispatch } from 'src/components-bl/types/dispatch';
import { TableV2, TableV2InstanceProps, VirtualScrollList } from 'src/components-dummy';
import { ILexiconTagsPaginationAPI } from 'src/services/src/service/types/lexicon';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';
import { ILexiconTableBodyRow } from '../types';
import { LexiconItemRow } from './LexiconItemRow';
import { SkeletonStyled, SkeletonsWrapper } from './LexiconTableBody.styled';
import { LexiconTableColumnType } from '../LexiconTable.config';

export type OnSelectRow = ({
  lexiconItemId,
  isSelected,
}: {
  lexiconItemId: string;
  isSelected: boolean;
}) => void;

/**
 * Main
 */
interface LexiconTableBodyProps {
  rows: TableV2InstanceProps<ILexiconTableBodyRow>['rows'];
  prepareRow: TableV2InstanceProps<ILexiconTableBodyRow>['prepareRow'];
  dispatch: Dispatch;
  shopId: number;
  tagType: LexiconTagType;
  hasNextPage: boolean;
  isLoading: boolean;
  onSelectRow: OnSelectRow;
  onLoadMoreItems: () => void;
  onItemChange: () => Promise<ILexiconTagsPaginationAPI>;
  locale: string;
  showApplyAll: boolean;
  editThematicTagRoute?: string;
  editLexiconRuleRoute?: string;
  isSyteAdmin: boolean;
  tableColumns: LexiconTableColumnType;
}

export const LexiconTableBody = React.memo(
  ({
    rows,
    prepareRow,
    dispatch,
    shopId,
    tagType,
    hasNextPage,
    isLoading,
    onSelectRow,
    onLoadMoreItems,
    onItemChange,
    locale,
    showApplyAll,
    editThematicTagRoute,
    editLexiconRuleRoute,
    isSyteAdmin,
    tableColumns,
  }: LexiconTableBodyProps): JSX.Element => {
    if (!isLoading && !rows?.length) {
      return <TableV2.NoResults width={291} />;
    }

    if (isLoading) {
      return (
        <SkeletonsWrapper>
          <SkeletonStyled height={40} variant='rounded' />
          <SkeletonStyled height={40} variant='rounded' />
          <SkeletonStyled height={40} variant='rounded' />
          <SkeletonStyled height={40} variant='rounded' />
          <SkeletonStyled height={40} variant='rounded' />
          <SkeletonStyled height={40} variant='rounded' />
          <SkeletonStyled height={40} variant='rounded' />
          <SkeletonStyled height={40} variant='rounded' />
        </SkeletonsWrapper>
      );
    }

    const renderVirtualizedBodyRow = useCallback(
      ({
        rowData,
        rowStyle,
      }: {
        rowData: TableV2InstanceProps<ILexiconTableBodyRow>['rows'][0];
        rowStyle: CSSProperties;
        index: number;
      }): JSX.Element => {
        prepareRow(rowData);

        return (
          <div style={rowStyle}>
            <LexiconItemRow
              key={rowData.original.originalData.tagKey}
              row={rowData}
              locale={locale}
              onSelect={onSelectRow}
              shopId={shopId}
              tagType={tagType}
              dispatch={dispatch}
              showApplyAll={showApplyAll}
              onItemChange={onItemChange}
              editThematicTagRoute={editThematicTagRoute}
              editLexiconRuleRoute={editLexiconRuleRoute}
              isSyteAdmin={isSyteAdmin}
              tableColumns={tableColumns}
            />
          </div>
        );
      },
      [rows, showApplyAll, editThematicTagRoute, editLexiconRuleRoute, tagType]
    );

    return (
      <VirtualScrollList
        data={rows}
        itemHeight={48}
        hasNextPage={hasNextPage}
        renderBodyRow={renderVirtualizedBodyRow}
        onLoadMoreItems={onLoadMoreItems}
      />
    );
  }
);
