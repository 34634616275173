import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DialogModalContentStyled,
  DialogModalFooterStyled,
} from 'src/components-bl/Lexicon/components/ImportLexiconModal/ImportLexiconModal.styles';
import {
  Button,
  DialogModalHeader,
  DialogModalTitle,
  NumericInput,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { isEqual } from 'lodash';
import { useValidateSchema } from 'src/hooks';
import { reduceErrorsByPrefix, sleep } from 'src/utils';
import { RuleDraftCondition } from '../../../../MerchandisingRuleForm.config';
import {
  MultilineTextBoxStyled,
  PinMultipleItemsModalStyled,
  TypographyStyled,
} from './PinMultipleItemsModal.styles';
import { ruleFormMappers } from '../../../../MerchandisingRuleForm.mappers';
import { FormState } from './types';
import { formValidationSchema } from './constants';
import { MAX_POSITION_VALUE } from '../constants';

const separators = /,|\s|\n/;

function parseSkus(inputString: string): string[] {
  const skusArray = inputString.trim().split(separators);

  const validUrls = skusArray.filter(input => input.trim().length > 0).map(sku => sku.trim());

  return validUrls;
}

const INITIAL_FORM_STATE: FormState = {
  skus: [],
  startIndex: 1,
  maxItemsLimit: MAX_POSITION_VALUE,
};

function getMaxItemsLimit(startingPosition = INITIAL_FORM_STATE.startIndex): number {
  return Math.min(MAX_POSITION_VALUE - startingPosition + 1, MAX_POSITION_VALUE);
}

const INITIAL_TEXT_BOX_STATE = '';

interface PinMultipleItemsModalProps {
  onClose: () => void;
  open: boolean;
  onAddItems: (items: RuleDraftCondition[]) => void;
  skipSkus: string[];
}

export const PinMultipleItemsModal = ({
  open,
  onClose,
  onAddItems,
  skipSkus,
}: PinMultipleItemsModalProps): JSX.Element => {
  const [textBoxState, setTextBoxState] = useState(INITIAL_TEXT_BOX_STATE);
  const [formState, setFormState] = useState<FormState>({ ...INITIAL_FORM_STATE });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { errors, validate, isValid, resetValidationState } = useValidateSchema<FormState>({
    schema: formValidationSchema,
    validateOnStart: false,
    initialData: INITIAL_FORM_STATE,
  });

  const onStateChange = useCallback(
    (partialState: Partial<FormState>) => {
      const updatedState = { ...formState, ...partialState };

      updatedState.maxItemsLimit = getMaxItemsLimit(updatedState.startIndex);

      setFormState(updatedState);

      validate({ dataToValidate: updatedState });
    },
    [formState, setFormState, validate]
  );

  const onIndexChange = useCallback(
    newIndex => {
      onStateChange({ startIndex: newIndex });
    },
    [onStateChange]
  );

  const onTextAreaChange = useCallback(
    (input: string) => {
      setTextBoxState(input);

      const allParsedSkus = parseSkus(input);

      const skus = allParsedSkus.filter(sku => !skipSkus.includes(sku));

      onStateChange({ skus });
    },
    [setTextBoxState, onStateChange, skipSkus]
  );

  const isDirty = useMemo(() => {
    return !isEqual(formState, INITIAL_FORM_STATE);
  }, [formState]);

  const canApply = useMemo(() => {
    return isValid && isDirty;
  }, [isDirty, isValid]);

  const onSubmitClick = useCallback(() => {
    setIsSubmitting(true);
  }, [setIsSubmitting]);

  useEffect(() => {
    const createConditionsFromSkus = async () => {
      await sleep(100);

      if (isSubmitting) {
        const conditions = [];

        for (let i = 0; i < formState.skus.length; i++) {
          const targetSku = formState.skus[i];

          const newCondition = ruleFormMappers.createEmptyPinToPositionCondition();

          newCondition.values = [targetSku];

          newCondition.position = formState.startIndex + i;

          conditions.push(newCondition);
        }

        onAddItems(conditions);
      }
    };

    createConditionsFromSkus();
  }, [isSubmitting]);

  useEffect(() => {
    if (open === false) {
      setFormState(INITIAL_FORM_STATE);
      resetValidationState();
      setTextBoxState(INITIAL_TEXT_BOX_STATE);
      setIsSubmitting(false);
    }
  }, [open]);

  const textAreaError = useMemo(() => {
    const reducedErrors = reduceErrorsByPrefix({ errors, prefix: 'skus' });

    if (!reducedErrors) {
      return undefined;
    }

    const errorKeys = Object.keys(reducedErrors);

    if (errorKeys.length === 0) {
      return undefined;
    }

    return reducedErrors[errorKeys[0]];
  }, [errors]);

  return (
    <PinMultipleItemsModalStyled open={open} onClose={onClose}>
      <>
        <DialogModalHeader>
          <DialogModalTitle>Pin multiple items</DialogModalTitle>
        </DialogModalHeader>
        <DialogModalContentStyled>
          <TypographyStyled
            variant={TypographyVariant.MediumRegular}
            type={TypographyType.Paragraph}
          >
            To pin multiple items, simply input their SKUs in the space provided.
            <br />
            &#x2022; Enter each SKU on its own line, or separated by a comma or a space
            <br />
            &#x2022; The items will be pinned in the order they&apos;re listed.
            <br />
            &#x2022; When bulk pinning, new items will be inserted at their chosen position, pushing
            existing pinned items down the list.
          </TypographyStyled>
          <MultilineTextBoxStyled
            value={textBoxState}
            error={textAreaError?.message}
            onChange={onTextAreaChange}
            placeholder='Type a SKU here...'
            multiline
            maxRows={7}
          />

          <NumericInput
            label="Pin item's starting position:"
            value={formState.startIndex}
            onChange={onIndexChange}
            error={errors?.startIndex?.message}
            minNumericValue={1}
            maxNumericValue={MAX_POSITION_VALUE}
            autoComplete='off'
          />
          <TypographyStyled variant={TypographyVariant.SmallMedium} type={TypographyType.Body}>
            Items will be pinned starting from this position (default is 1)
          </TypographyStyled>
        </DialogModalContentStyled>
        <DialogModalFooterStyled>
          <Button
            variant='primary'
            onClick={onSubmitClick}
            disabled={!canApply}
            loading={isSubmitting}
          >
            Apply
          </Button>
          <Button variant='tertiary' onClick={onClose}>
            Close
          </Button>
        </DialogModalFooterStyled>
      </>
    </PinMultipleItemsModalStyled>
  );
};
