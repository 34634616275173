import React, { useCallback, useRef } from 'react';
import { MerchandisingRuleTypes, FeedDataFieldType } from 'src/services';
import { SelectOnChangeEvent } from 'src/components-dummy';
import { Dispatch } from 'src/components-bl';
import {
  ConditionValueInnerType,
  DataFieldConditionDataInnerType,
  DataFieldConditionErrors,
} from './DataFieldsCondition.types';
import { DataFieldValuesAutoSuggest } from './DataFieldValuesAutoSuggest';
import {
  isBooleanDataField,
  convertConditionValueToRaw,
  convertConditionValue,
  isNumericDataField,
} from './DataFieldsCondition.helpers';
import { UseDataFieldsLookupTableArgumentsReturnType } from '../useDataFieldsLookupTable';
import { DataFieldSingleAutoSuggestion } from './DataFieldSingleAutoSuggestion';
import { NumericInput, NumericInputRange } from './NumericInputs';
import { INPUT_CLASS_NAME } from './consts';
import { DataFieldsBooleanSelect } from './DataFieldsBooleanSelect';

const NUMERIC_INPUT_DECIMAL_PLACES = 3;

export interface ConditionValueInputProps
  extends Pick<UseDataFieldsLookupTableArgumentsReturnType, 'getDataFieldByName'> {
  conditionData: DataFieldConditionDataInnerType;
  onChange: (values: MerchandisingRuleTypes.SubRuleOptionalValues) => void;
  errors: DataFieldConditionErrors;
  dataFieldTypes?: FeedDataFieldType[];
  disabled?: boolean;
  dispatch: Dispatch;
  shopId: number;
}

export const ConditionValueInput = ({
  getDataFieldByName,
  conditionData,
  dataFieldTypes,
  onChange: onValuesChange,
  disabled,
  errors,
  dispatch,
  shopId,
}: ConditionValueInputProps): JSX.Element => {
  const { conditionType, conditionValue, dataField: dataFieldName } = conditionData;
  const selectedDataField = getDataFieldByName(dataFieldName);
  let conditionValueInput: JSX.Element | null = null;
  const numericInputRef = useRef<HTMLInputElement | null>(null);

  const onChange = useCallback(
    (value: ConditionValueInnerType) => {
      const rawValues = convertConditionValueToRaw(conditionType, value, selectedDataField?.types);
      onValuesChange?.(rawValues);
    },
    [conditionType, onValuesChange]
  );

  const onBooleanChange: SelectOnChangeEvent = event =>
    onChange(String(true) === event.target.value);

  switch (conditionType) {
    case MerchandisingRuleTypes.MerchandisingRuleSubType.Equals: {
      const convertedValue = convertConditionValue(
        MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
        conditionValue,
        dataFieldTypes
      );

      if (isBooleanDataField({ types: selectedDataField?.types })) {
        conditionValueInput = (
          <DataFieldsBooleanSelect
            disabled={disabled}
            isError={Boolean(errors.values)}
            value={`${convertedValue}`}
            onChange={onBooleanChange}
          />
        );
      } else if (isNumericDataField({ types: selectedDataField?.types })) {
        conditionValueInput = (
          <DataFieldSingleAutoSuggestion
            error={!!errors.values}
            selectedValue={convertedValue as string}
            onChange={onChange}
            disabled={disabled}
            dispatch={dispatch}
            shopId={shopId}
            dataFieldName={dataFieldName}
          />
        );
      } else {
        conditionValueInput = (
          <DataFieldValuesAutoSuggest
            error={!!errors.values}
            selectedValues={convertedValue as string[]}
            onChange={onChange}
            disabled={disabled}
            dispatch={dispatch}
            shopId={shopId}
            dataFieldName={dataFieldName}
          />
        );
      }
      break;
    }

    case MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain: {
      const convertedValue = convertConditionValue(
        MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain,
        conditionValue,
        dataFieldTypes
      );

      if (isBooleanDataField({ types: selectedDataField?.types })) {
        conditionValueInput = (
          <DataFieldsBooleanSelect
            disabled={disabled}
            isError={Boolean(errors.values)}
            value={`${convertedValue}`}
            onChange={onBooleanChange}
          />
        );
      } else if (isNumericDataField({ types: selectedDataField?.types })) {
        conditionValueInput = (
          <DataFieldSingleAutoSuggestion
            error={!!errors.values}
            selectedValue={convertedValue as string}
            onChange={onChange}
            disabled={disabled}
            dispatch={dispatch}
            shopId={shopId}
            dataFieldName={dataFieldName}
          />
        );
      } else {
        conditionValueInput = (
          <DataFieldValuesAutoSuggest
            error={!!errors.values}
            selectedValues={convertedValue as string[]}
            onChange={onChange}
            disabled={disabled}
            dispatch={dispatch}
            shopId={shopId}
            dataFieldName={dataFieldName}
            isMultiSelect
          />
        );
      }
      break;
    }
    case MerchandisingRuleTypes.MerchandisingRuleSubType.Contains: {
      const convertedValue = convertConditionValue(
        MerchandisingRuleTypes.MerchandisingRuleSubType.Contains,
        conditionValue
      );

      conditionValueInput = (
        <DataFieldValuesAutoSuggest
          error={!!errors.values}
          selectedValues={convertedValue}
          onChange={onChange}
          disabled={disabled}
          dispatch={dispatch}
          shopId={shopId}
          dataFieldName={dataFieldName}
          isMultiSelect
        />
      );
      break;
    }
    case MerchandisingRuleTypes.MerchandisingRuleSubType.RelativeRange: {
      const convertedValue = convertConditionValue(
        MerchandisingRuleTypes.MerchandisingRuleSubType.RelativeRange,
        conditionValue
      );

      conditionValueInput = (
        <NumericInputRange
          error={!!errors.values}
          values={convertedValue}
          onChange={onChange}
          className={INPUT_CLASS_NAME}
          staticSymbol='%'
          decimals={NUMERIC_INPUT_DECIMAL_PLACES}
          disabled={disabled}
          autoFocus
        />
      );
      break;
    }
    case MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween: {
      const convertedValue = convertConditionValue(
        MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween,
        conditionValue
      );

      conditionValueInput = (
        <NumericInputRange
          error={!!errors.values}
          values={convertedValue}
          onChange={onChange}
          className={INPUT_CLASS_NAME}
          decimals={NUMERIC_INPUT_DECIMAL_PLACES}
          disabled={disabled}
          autoFocus
        />
      );
      break;
    }
    case MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan:
      {
        const convertedValue = convertConditionValue(
          MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan,
          conditionValue
        );

        conditionValueInput = (
          <NumericInput
            error={!!errors.values}
            value={convertedValue}
            onChange={onChange}
            className={INPUT_CLASS_NAME}
            decimals={NUMERIC_INPUT_DECIMAL_PLACES}
            disabled={disabled}
            autoFocus
            inputRef={numericInputRef}
          />
        );
      }
      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan: {
      const convertedValue = convertConditionValue(
        MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan,
        conditionValue
      );

      conditionValueInput = (
        <NumericInput
          error={!!errors.values}
          value={convertedValue}
          onChange={onChange}
          className={INPUT_CLASS_NAME}
          decimals={NUMERIC_INPUT_DECIMAL_PLACES}
          disabled={disabled}
          autoFocus
          inputRef={numericInputRef}
        />
      );
      break;
    }
    default:
      break;
  }

  return <>{conditionValueInput}</>;
};
