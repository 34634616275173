import { ValidatorsDict, ItemValidityMap, GenericExtendsFallback } from '../base';
import { BasicValidator } from '../base/base-validator';

export class FormValidator<T extends GenericExtendsFallback> extends BasicValidator<T> {
  private validationState: ItemValidityMap<T> = {};

  constructor(validatorsDict: ValidatorsDict<T>) {
    super(validatorsDict);
    this.init();
  }

  private init(): void {
    this.validationState = Object.keys(this.validatorsDict).reduce((acc, property) => {
      return { ...acc, [property]: {} };
    }, {});
  }

  get state(): ItemValidityMap<T> {
    return this.validationState;
  }

  reset(property?: keyof T): void {
    if (property) {
      this.validationState = {
        ...this.validationState,
        [property]: {},
      };
    } else {
      this.init();
    }
  }

  validate(property: keyof T, val: unknown): void {
    this.validationState[property] = this.validateProperty(property, val);
  }

  validateAll(data: Partial<T>): void {
    Object.keys(this.validatorsDict).forEach(property => {
      this.validate(property, data[property]);
    });
  }

  get isValid(): boolean {
    const haveErrors = Object.values(this.state).some(
      entry => entry?.required || entry?.valueValidation?.length
    );
    return !haveErrors;
  }
}
