import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import {
  AccountListAction,
  AccountListActionTypes,
  ShopGeneralSettingsAction,
  ShopGeneralSettingsActionTypes,
  ShopCardActionTypes,
  ShopCardAction,
  ShopFeatureTogglesFormAction,
  ShopFeatureTogglesFormActionTypes,
  useFeatureTogglesWSActions,
} from 'src/components-bl';
import { adminSearchModalActions } from 'src/components-bl/AdminSearchModal/AdminSearchModal.actions';
import { merchandisingRulesActions } from 'src/components-bl/MerchandisingRules/merchandisingRules.actions';
import { shopRoutesActions } from 'src/containers';
import { Shop } from 'src/services/src/service/types/shops';
import { shopFeatureTogglesDeepTagsToggleActions } from 'src/components-bl/APISettings/Actions/ShopFeatureTogglesProductsDeepTagsActions';
import { ShopReducerState } from '../app-state-types';
import { rootContainerActions } from '../root-container/Actions';

const defaultState: ShopReducerState = {
  current: undefined,
  shops: [],
  featureToggles: undefined,
  productsAPIv2Toggles: undefined,
  isShopLoading: false,
  availableRegions: undefined,
};

function editShopById(shops: Shop[], shopId: number, fieldsToMerge: Partial<Shop>) {
  return shops?.map(shop => {
    if (shop.shopId === shopId) {
      return { ...shop, ...fieldsToMerge };
    }
    return shop;
  });
}

type ExpectedActions =
  | AccountListAction
  | ShopCardAction
  | ShopGeneralSettingsAction
  | ShopFeatureTogglesFormAction;

export const shopReducer = createReducer(defaultState, builder => {
  builder
    .addCase(shopRoutesActions.selectShop, (state, action) => {
      const { shop, featureToggles } = action.payload;

      return {
        ...state,
        current: shop,
        featureToggles,
      };
    })
    .addCase(useFeatureTogglesWSActions.featureTogglesUpdate, (state, action) => {
      return { ...state, featureToggles: action.payload.featureToggles };
    })
    .addCase(shopRoutesActions.selectAccount, (state, action) => {
      return { ...state, shops: action.payload.account.shops };
    })
    .addCase(shopRoutesActions.selectShopAndAccount, (state, action) => {
      const { shop, featureToggles, account } = action.payload;
      return { ...state, current: shop, featureToggles, shops: account.shops };
    })
    .addCase(shopRoutesActions.loadShop.rejected, state => {
      return {
        ...state,
        current: undefined,
        featureToggles: undefined,
        availableRegions: undefined,
      };
    })
    .addCase(rootContainerActions.initApp.fulfilled, (state, action) => {
      const { shops } = action.payload.account;

      return {
        ...state,
        shops,
        current: undefined, // current shop handled by shopRoutes
      };
    })
    .addCase(shopRoutesActions.clearCurrentShop, state => {
      return {
        ...state,
        current: undefined,
        featureToggles: undefined,
        availableRegions: undefined,
      };
    })
    .addCase(shopRoutesActions.setIsShopLoading, (state, action) => {
      return { ...state, isShopLoading: action.payload.isShopLoading };
    })
    .addCase(shopRoutesActions.setAppLoading, (state, action) => {
      return { ...state, isShopLoading: action.payload.appLoading };
    })
    .addCase(
      shopFeatureTogglesDeepTagsToggleActions.getShopProductsDeepTagsPermission.pending,
      state => {
        return {
          ...state,
          productsAPIv2Toggles: { isDeepTagsPermitted: undefined },
        };
      }
    )
    .addCase(merchandisingRulesActions.getShopLocales.fulfilled, (state, action) => {
      return { ...state, availableRegions: action.payload.regions };
    })
    .addMatcher(
      isAnyOf(
        shopFeatureTogglesDeepTagsToggleActions.getShopProductsDeepTagsPermission.fulfilled,
        shopFeatureTogglesDeepTagsToggleActions.updateShopProductsDeepTagsPermission.fulfilled
      ),
      (state, action) => {
        const { isDeepTagsPermitted, isTaggingEnabled } = action.payload;
        return {
          ...state,
          productsAPIv2Toggles: { isDeepTagsPermitted, isTaggingEnabled },
        };
      }
    )
    .addMatcher(
      isAnyOf(
        adminSearchModalActions.navigateToAccount.fulfilled,
        adminSearchModalActions.navigateToUser.fulfilled
      ),
      (state, action) => {
        const { shops } = action.payload.account;

        return {
          ...state,
          ...action.payload,
          shops,
          current: undefined,
          featureToggles: undefined,
          availableRegions: undefined,
        };
      }
    )

    .addDefaultCase((state, toolkitAction) => {
      const action = toolkitAction as ExpectedActions;

      switch (action.type) {
        case ShopCardActionTypes.DeleteShopSuccess: {
          const { shops } = action.payload;
          return {
            ...state,
            shops,
            current: undefined,
            featureToggles: undefined,
            availableRegions: undefined,
          };
        }
        case AccountListActionTypes.GetAccountSuccess: {
          const { shops } = action.payload.account;
          return {
            ...state,
            shops,
            current: undefined,
            featureToggles: undefined,
            availableRegions: undefined,
          };
        }
        case ShopGeneralSettingsActionTypes.GetSuccess: {
          const { generalSettings } = action.payload;
          return {
            ...state,
            current: state.current ? { ...state.current, ...generalSettings } : state.current,
          };
        }
        // updates the shop data, doesn't change shop.
        case ShopGeneralSettingsActionTypes.SubmitSuccess: {
          const { shopId, generalSettings } = action.payload;
          return {
            ...state,
            current: state.current ? { ...state.current, ...generalSettings } : state.current,
            shops: editShopById(state.shops, shopId, generalSettings),
          };
        }
        case ShopFeatureTogglesFormActionTypes.GetFeatureTogglesSuccess:
        case ShopFeatureTogglesFormActionTypes.UpdateFeatureTogglesSuccess: {
          const { featureToggles } = action.payload;
          return { ...state, featureToggles };
        }
        default:
          return state;
      }
    });
});
