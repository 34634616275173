import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { galleriesService } from 'src/services/src/service/galleries';

export const addImagesModalFormActions = {
  navigateTo: createAction<{ navigateTo: string }>('AddImagesModalForm/NavigateTo'),
  notifyIsDirty: createAction<{ isDirty: boolean }>('AddImagesModalForm/NotifyIsDirty'),
  updateGallery: createAsyncThunk(
    'AddImagesModalForm/UpdateGallery',
    async (
      {
        shopId,
        galleryId,
        payload,
      }: Parameters<typeof galleriesService.updateCustomInspirationsGallery>[0],
      { rejectWithValue }
    ) => {
      try {
        const gallery = await galleriesService.updateCustomInspirationsGallery({
          shopId,
          galleryId,
          payload,
        });
        return gallery;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
