/* eslint-disable no-param-reassign */
import { useMemo } from 'react';
import { uniq } from 'lodash';
import { deepTagBulkCustomFormItemSchema } from './DeepTagBulkCustomFormItem.schema';
import { CustomLabelBulkFormItem } from './DeepTagBulkCustomFormItem';

interface UseCustomBulkItemsValidationProps {
  formData: CustomLabelBulkFormItem[];
  generalConfigurationNameById: Record<string, string>;
  totalAvailableProductsToAssign: number;
}

interface UseCustomBulkItemsValidationResult {
  uniqueItemsErrorMessages: string[];
  validationErrorsPerItem: {
    [id: string]: Pick<CustomLabelBulkFormItem, 'labelId' | 'totalProducts'>;
  };
  totalFormAvailableProductsToAssign: number;
  exceededItemsNumberErrorMessage: string;
  isValid: boolean;
}

export const useCustomBulkItemsValidation = ({
  formData,
  generalConfigurationNameById,
  totalAvailableProductsToAssign,
}: UseCustomBulkItemsValidationProps): UseCustomBulkItemsValidationResult => {
  const validationErrorsPerItem = useMemo(() => {
    const labelSet = new Set();

    const validationErrors = formData.reduce(
      (errors, itemData) => {
        const { error } = deepTagBulkCustomFormItemSchema.validate(itemData, { abortEarly: false });
        const itemId = itemData.id;

        errors[itemId] = {};

        if (error) {
          error.details.forEach(detail => {
            const fieldName = detail.path[0];
            errors[itemId][fieldName] = detail.message;
          });
        } else if (itemData.labelId) {
          const { labelId } = itemData;

          if (labelSet.has(labelId)) {
            const labelName = generalConfigurationNameById[labelId];
            errors[itemId].label = `The label "${labelName}" is already being used in the list`;
          } else {
            labelSet.add(labelId);
          }
        }

        return errors;
      },
      {} as { [key: string]: { [key: string]: string } }
    );

    return validationErrors;
  }, [formData, generalConfigurationNameById]);

  const uniqueItemsErrorMessages = useMemo(() => {
    const allMessages = Object.values(validationErrorsPerItem).reduce(
      (prev: string[], next: { [key: string]: string }) => {
        const itemMessageValues = Object.values(next);

        return prev.concat(itemMessageValues);
      },
      []
    );

    return uniq(allMessages);
  }, [validationErrorsPerItem]);

  /**
   * Free total products validation
   */
  const totalProductsAssignedInForm = useMemo(() => {
    return formData.reduce((prev, next) => {
      return prev + (next.totalProducts || 0);
    }, 0);
  }, [formData]);

  const totalFormAvailableProductsToAssign: number = useMemo(() => {
    const totalAvailableProducts = totalAvailableProductsToAssign - totalProductsAssignedInForm;

    return totalAvailableProducts;
  }, [totalAvailableProductsToAssign, totalProductsAssignedInForm]);

  const exceededItemsNumberErrorMessage = useMemo((): string => {
    if (totalFormAvailableProductsToAssign < 0) {
      return `There are more items assigned (${Math.abs(
        totalFormAvailableProductsToAssign
      )}) than there are available free ones.`;
    }
    return '';
  }, [totalFormAvailableProductsToAssign]);

  return {
    uniqueItemsErrorMessages,
    validationErrorsPerItem,
    totalFormAvailableProductsToAssign,
    exceededItemsNumberErrorMessage,
    isValid: !uniqueItemsErrorMessages.length && !exceededItemsNumberErrorMessage,
  };
};
