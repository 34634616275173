import React from 'react';
import { ShopCatalogVertical } from 'src/services';
import { ErrorLabel, Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { SectionBoxStyled } from './ExistingCatalogForm.styles';
import { VerticalSwitchRow } from './VerticalSwitchRow';

interface VerticalsSectionProps {
  isPrimaryCatalog: boolean;
  onChange: (verticals: Set<ShopCatalogVertical>) => void;
  selectedVerticals: Set<ShopCatalogVertical>;
  nonPrimaryVerticals: Set<ShopCatalogVertical>;
  errorMessage?: string;
}

const allShopVerticalKeys = [
  ShopCatalogVertical.Fashion,
  ShopCatalogVertical.Home,
  ShopCatalogVertical.DIY,
  ShopCatalogVertical.General,
];

export const VerticalsSection = ({
  onChange,
  selectedVerticals,
  errorMessage,
  isPrimaryCatalog,
  nonPrimaryVerticals,
}: VerticalsSectionProps): JSX.Element => {
  const onVerticalsChange = ({
    vertical,
    checked,
  }: {
    vertical: ShopCatalogVertical;
    checked: boolean;
  }) => {
    const verticalsStateCopy = new Set(selectedVerticals);

    if (checked) {
      verticalsStateCopy.add(vertical);
    } else {
      verticalsStateCopy.delete(vertical);
    }

    onChange(verticalsStateCopy);
  };

  return (
    <SectionBoxStyled>
      <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumMedium}>
        Supported Departments
      </Typography>

      {allShopVerticalKeys.map(verticalKey => {
        const isChecked = selectedVerticals.has(verticalKey);

        const onVerticalChange = (checked: boolean) => {
          onVerticalsChange({ vertical: verticalKey, checked });
        };

        const showBelongsToOtherPrimary =
          !!isPrimaryCatalog && !nonPrimaryVerticals.has(verticalKey);

        return (
          <VerticalSwitchRow
            showBelongsToOtherPrimary={showBelongsToOtherPrimary}
            key={verticalKey}
            isChecked={isChecked}
            vertical={verticalKey}
            onChange={onVerticalChange}
          />
        );
      })}
      {errorMessage && <ErrorLabel value={errorMessage} />}
    </SectionBoxStyled>
  );
};
