import React from 'react';
import { ColumnProps } from '../types';

export const Column = <T extends Record<string, unknown>>(args: ColumnProps<T>): JSX.Element => (
  <>{args.children}</>
);

Column.defaultProps = {
  disableSortBy: true,
  disableResizing: false,
  disableFilters: true,
  disabled: false,
};
