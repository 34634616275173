import React, { ReactNode } from 'react';
import { FooterStyled } from './FilterFormFooter.styles';

export interface FilterFormFooterProps {
  children: ReactNode | ReactNode[];
}

export const FilterFormFooter = ({ children }: FilterFormFooterProps): JSX.Element => {
  return <FooterStyled>{children}</FooterStyled>;
};
