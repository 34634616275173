import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import { Button } from 'src/components-dummy';
import { IDeepTagReportProductComment } from 'src/services/src/service/types/deep-tag-reports';
import { UserTypes } from 'src/services/src/service/types';
import { Dispatch } from 'src/components-bl/types';
import { DeepTagProductCommentsList } from './DeepTagProductCommentsList';
import { CommentsFormButtonWrapperStyled } from './DeepTagProductComments.styles';
import { CommentsCountWithBadge } from './CommentsCountWithBadge';

interface DeepTagProductCommentsWithButtonProps {
  comments: IDeepTagReportProductComment[];
  currentUser: UserTypes.User;
  shopId: number;
  reportId: string;
  productId: string;
  getPaginatedReportsWithFilters: () => void;
  dispatch: Dispatch;
}
export const DeepTagProductCommentsWithButton = ({
  currentUser,
  comments,
  shopId,
  reportId,
  productId,
  getPaginatedReportsWithFilters,
  dispatch,
}: DeepTagProductCommentsWithButtonProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = !!anchorEl;

  return (
    <div>
      <CommentsFormButtonWrapperStyled isActive={isPopoverOpen}>
        <Button onClick={handleClick} variant='secondary' size='tiny'>
          <CommentsCountWithBadge total={comments.length} />
          Comment
        </Button>
      </CommentsFormButtonWrapperStyled>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{ style: { marginTop: '8px', borderRadius: '8px' } }}
      >
        <DeepTagProductCommentsList
          shopId={shopId}
          reportId={reportId}
          productId={productId}
          currentUser={currentUser}
          comments={comments}
          getPaginatedReportsWithFilters={getPaginatedReportsWithFilters}
          onClose={handleClose}
          dispatch={dispatch}
        />
      </Popover>
    </div>
  );
};
