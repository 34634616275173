import { ApiServiceBase } from '../api-service-base';
import { IApiKey } from '../types/shops/api-keys';
import { mapApiKey } from './mapper';

export class ApiKeys extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  async getApiKeys(shopId: number): Promise<{ apiKeys: IApiKey[] }> {
    const response = await this.httpService.get({
      url: `${this.serviceBaseUri}/${shopId}/api-keys`,
    });

    return { apiKeys: response.data.apiKeys.map(mapApiKey) };
  }

  async getApiKey(shopId: number, apiKeyId: string): Promise<IApiKey> {
    const response = await this.httpService.get({
      url: `${this.serviceBaseUri}/${shopId}/api-keys/${apiKeyId}`,
    });
    return mapApiKey(response.data);
  }

  async createApiKey(shopId: number, data: { label: string; expiration?: Date }): Promise<IApiKey> {
    const response = await this.httpService.post({
      url: `${this.serviceBaseUri}/${shopId}/api-keys`,
      data,
    });
    return mapApiKey(response.data);
  }

  async updateApiKey(
    shopId: number,
    apiKeyId: string,
    data: { label?: string; expiration?: Date | null }
  ): Promise<IApiKey> {
    const response = await this.httpService.patch({
      url: `${this.serviceBaseUri}/${shopId}/api-keys/${apiKeyId}`,
      data,
    });
    return mapApiKey(response.data);
  }

  async deleteApiKey(shopId: number, apiKeyId: string): Promise<void> {
    await this.httpService.delete({ url: `${this.serviceBaseUri}/${shopId}/api-keys/${apiKeyId}` });
  }
}
