import Joi, { AnySchema } from 'joi';
import {
  CatalogFormDraft,
  DataSourceTypesEnum,
  ImportMethodTypesEnum,
  SchedulingTypesEnum,
  formModesEnum,
} from './CatalogForm.config';

const buildAuthenticationValidation = (authentication: boolean) => {
  const baseAuthentication = {
    authentication: Joi.boolean().required().label('authentication'),
  };

  if (!authentication) {
    return baseAuthentication;
  }

  return {
    ...baseAuthentication,
    username: Joi.string().label('username').required().trim().min(1).max(50).messages({
      'string.empty': 'User name can not be empty',
      'string.min': 'User name should be at least 1 characters long',
      'string.max': 'Maximum 50 characters long',
    }),
    password: Joi.string().label('password').required().trim().min(1).max(50).messages({
      'string.empty': 'Password can not be empty',
      'string.min': 'Password should be at least 1 characters long',
      'string.max': 'Maximum 50 characters long',
    }),
  };
};

export const catalogFormValidationSchema = ({
  mode,
  formState,
  existedCatalogNames,
}: {
  mode: 'edit' | 'create';
  formState?: CatalogFormDraft;
  existedCatalogNames?: Record<string, boolean>;
}): Record<keyof Partial<Omit<CatalogFormDraft, 'id' | 'createdAt' | 'updatedAt'>>, AnySchema> => {
  const validationSchema = {
    name: Joi.string()
      .required()
      .trim()
      .min(3)
      .max(25)
      .regex(/^[a-z0-9][a-z0-9_-]*[a-z0-9]$/)
      .custom((value, helpers) => {
        // Edit mode already in disable mode (pass)
        if (mode === 'edit') return true;

        if (!existedCatalogNames) return true;
        const isAlreadyExists = existedCatalogNames[value];
        const isValid = !isAlreadyExists;

        if (!isValid) {
          return helpers.error('string.existedCatalogName');
        }

        return isValid;
      })
      .messages({
        'string.empty': 'Name can not be empty',
        'string.min': 'Name should be at least 3 characters long',
        'string.pattern.base': `catalog name must consist of lower case alphanumeric characters, '-' or '_', and must start and end with an alphanumeric character`,
        'string.max': 'Maximum 25 characters long',
        'string.existedCatalogName': 'Name already exists',
      }),
    verticals: Joi.array().required().min(1).messages({
      'array.min': 'At least 1 vertical needs to be selected',
    }),
    genders: Joi.array().required().min(1).messages({
      'array.min': 'At least 1 gender needs to be selected',
    }),
    productsFile: Joi.object(),
  };

  // Add validation for product file
  if (
    mode === formModesEnum.create &&
    formState?.selectedDataSource === DataSourceTypesEnum.manual_add_products
  ) {
    Object.assign(validationSchema, {
      productsFile: Joi.object().required().min(1).messages({
        'any.required': 'Missing products file',
      }),
    });
  }

  if (formState?.selectedImportMethod) {
    switch (formState?.selectedImportMethod) {
      case ImportMethodTypesEnum.upload_sftp_server: {
        const authenticationSchema = buildAuthenticationValidation(
          !!formState?.sftpServerSettings?.authentication
        );

        Object.assign(validationSchema, {
          sftpServerSettings: Joi.object({
            type: Joi.string().trim().label('Method').required().messages({
              'string.empty': 'Method can not be empty',
            }),
            host: Joi.string().hostname().trim().label('Hostname').required().messages({
              'string.host': 'Invalid host name',
              'string.empty': 'Hostname can not be empty',
            }),
            port: Joi.required()
              .custom((value, helpers) => {
                if (typeof value === 'string') {
                  if (!/^\d+$/.test(value)) {
                    return helpers.error('string.base');
                  }
                  const numericValue = parseInt(value, 10);
                  if (Number.isNaN(numericValue) || numericValue < 0 || numericValue > 65535) {
                    return helpers.error('string.base');
                  }
                }
                return value;
              }, 'custom validation')
              .label('Port')
              .messages({
                'number.integer': 'Invalid port',
                'number.base': 'Invalid port',
                'string.base': 'Invalid port',
              }),
            fileName: Joi.string()
              .trim()
              .regex(/^[\w\d]+\.csv$/i)
              .required()
              .label('File Name')
              .messages({
                'string.pattern.base': 'Invalid file name',
              }),
            path: Joi.string()
              .trim()
              .regex(/^\/?[\w.-]+(\/[\w.-]+)*\/?$/i)
              .required()
              .label('Folder')
              .messages({
                'string.pattern.base': 'Invalid path',
              }),

            ...authenticationSchema,
          }).required(),
        });

        break;
      }
      case ImportMethodTypesEnum.upload_wget_server: {
        const authenticationSchema = buildAuthenticationValidation(
          !!formState?.wgetServerSettings?.authentication
        );

        Object.assign(validationSchema, {
          wgetServerSettings: {
            dataSourceURL: Joi.string()
              .trim()
              .required()
              .label('Feed URL')
              .regex(/^https?:\/\/[^\s/$.?#].[^\s]*$/)
              .uri()
              .messages({
                'string.empty': 'Feed url can not be empty',
                'string.pattern.base': 'Invalid Feed URL',
              }),
            ...authenticationSchema,
          },
        });

        break;
      }
      default:
        break;
    }
  }

  if (formState?.scheduling) {
    if (formState.selectedScheduleType === SchedulingTypesEnum.time_intervals) {
      Object.assign(validationSchema, {
        scheduling: {
          timeIntervals: {
            hours: Joi.number().required().label('Hours').min(6).max(23).messages({
              'string.empty': 'Hours can not be empty',
              'string.min': 'Minimum hours is 6',
              'string.max': 'Max hours is 23',
            }),
          },
        },
      });
    }
  }

  const result = validationSchema as unknown as Record<
    keyof Partial<Omit<CatalogFormDraft, 'id' | 'createdAt' | 'updatedAt'>>,
    AnySchema
  >;

  return result;
};
