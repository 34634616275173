import React from 'react';
import { BackButtonTitle, Button } from '../../../components-dummy';
import { ShopRoutes } from '../../../types/enums/shop-routes';

interface Props {
  shopId: number;
  disabled: boolean;
  onSave: () => void;
}

export function StoriesHeader({ shopId, disabled, onSave }: Props): JSX.Element | null {
  const navigationPath = `/shops/${shopId}/${ShopRoutes.ShopperExperiences}`;
  return (
    <>
      <BackButtonTitle path={navigationPath}>Discovery Stories</BackButtonTitle>
      <Button disabled={disabled} onClick={onSave} variant='primary'>
        Save changes
      </Button>
    </>
  );
}
