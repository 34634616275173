import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Page } from 'src/components-dummy';
import { useAppSelector } from 'src/hooks';
import { UseKnn } from 'src/components-bl';

export const UseKnnContainer = (): JSX.Element | null => {
  const formHeaderRef = useRef<HTMLDivElement>(null);
  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const useKnn = useAppSelector(state => state.shop.current?.useKnn);

  const dispatch = useDispatch();
  return (
    <Page className='use-knn-container'>
      <Page.Header>
        <Page.Title>Use OpenSearch KNN</Page.Title>
        <div className='use-knn-buttons-section' ref={formHeaderRef} />
      </Page.Header>
      <Page.Content>
        {shopId && (
          <UseKnn
            useKnn={useKnn}
            shopId={shopId}
            formHeaderElementRef={formHeaderRef}
            dispatch={dispatch}
            shouldRefetch={false}
          />
        )}
      </Page.Content>
    </Page>
  );
};
