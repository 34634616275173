import { AxiosResponse } from 'axios';
import { SimilarItemsSettingsTypes } from '../types';

export const similarItemsSettingsResponse = ({
  data,
}: AxiosResponse): SimilarItemsSettingsTypes.SimilarItemsSettings => {
  return {
    numberOfResultsToDisplay: data.numberOfResultsToDisplay,
    addToCart: data.addToCart,
    enableTextSimilarity: data.enableTextSimilarity,
  };
};
