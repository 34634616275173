import { IProductFormCommentItem } from './DeepTagProductComments.types';

export const formatDateTime = (dateTimeString: string): string => {
  const inputDate = new Date(dateTimeString);
  const now = new Date();

  // Check if it's today
  const isToday =
    inputDate.getDate() === now.getDate() &&
    inputDate.getMonth() === now.getMonth() &&
    inputDate.getFullYear() === now.getFullYear();

  // Format the time
  const hours = inputDate.getHours().toString().padStart(2, '0');
  const minutes = inputDate.getMinutes().toString().padStart(2, '0');
  const time = `${hours}:${minutes} ${Number(hours) >= 12 ? 'PM' : 'AM'}`;

  // Format the date
  const date = isToday
    ? 'Today'
    : `${inputDate.getDate()}.${inputDate.getMonth() + 1}.${inputDate.getFullYear()}`;

  return `${time} ${date}`;
};

const getDate = (date?: string) => (date ? new Date(date) : new Date());

export const sortCommentsByCreatedAt = (
  comments: IProductFormCommentItem[]
): IProductFormCommentItem[] => {
  return comments.sort((a, b) => {
    const dateA = getDate(a.createdAt);
    const dateB = getDate(b.createdAt);

    if (dateA > dateB) {
      return 1;
    }
    if (dateA < dateB) {
      return -1;
    }
    return 0;
  });
};
