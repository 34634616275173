import React from 'react';
import { MenuItem, SelectType } from 'src/components-dummy';
import { DeepTagsReportsRecognitionType, MetaTypes } from 'src/services';
import { SelectStyled } from './DeepTagReportsSettingsSection.styles';

export interface DeepTagReportsUploadSettingsSectionProps {
  locale: string;
  recognitionType: DeepTagsReportsRecognitionType;
  languages: Pick<MetaTypes.Language, 'locale' | 'displayName'>[];
  disabled?: boolean;
  onChange: (
    data: { locale: string } | { recognitionType: DeepTagsReportsRecognitionType }
  ) => void;
}

export const DeepTagReportSettingsSection = ({
  locale,
  recognitionType,
  languages,
  disabled,
  onChange,
}: DeepTagReportsUploadSettingsSectionProps): JSX.Element => {
  const emptyLexiconsErrorMessage =
    languages.length === 0 ? 'This shop has no lexicons' : undefined;

  const recognitionTypeToTranslation: Record<DeepTagsReportsRecognitionType, string> = {
    [DeepTagsReportsRecognitionType.ImageToDeepTags]: 'Deep Tagging',
    // [DeepTagsReportsRecognitionType.DominantColors]: 'Color Palette', // TODO: uncomment when VMR/Generator supports it
  };

  const recognitionTypeOptions = Object.values(DeepTagsReportsRecognitionType).map(
    (recognitionTypeValue: DeepTagsReportsRecognitionType) => {
      return {
        text: recognitionTypeToTranslation[recognitionTypeValue],
        value: recognitionTypeValue,
      };
    }
  );

  return (
    <div>
      <SelectStyled
        type={SelectType.Primary}
        value={locale}
        label='Lexicon'
        disabled={disabled || !!emptyLexiconsErrorMessage}
        onChange={event => onChange({ locale: event.target.value })} // onLexiconChange(event.target.value)}
        errorMessage={emptyLexiconsErrorMessage}
      >
        {languages.map(language => (
          <MenuItem key={language.locale} value={language.locale}>
            {language.displayName}
          </MenuItem>
        ))}
      </SelectStyled>
      <SelectStyled
        label='Recognition type'
        type={SelectType.Primary}
        value={recognitionType}
        disabled={disabled}
        onChange={event =>
          onChange({ recognitionType: event.target.value as DeepTagsReportsRecognitionType })
        }
        // onRecognitionTypeChange(event.target.value as DeepTagsReportsRecognitionType)
      >
        {recognitionTypeOptions.map(({ text, value }) => (
          <MenuItem key={value} value={value}>
            {text}
          </MenuItem>
        ))}
      </SelectStyled>
    </div>
  );
};
