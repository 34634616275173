import { useEffect } from 'react';
import { Dispatch, FilterSetConfiguration } from 'src/components-bl';
import { useAppSelector, usePreviousState } from 'src/hooks';
import { filterSetsActions } from '../Actions';

export const useFetchFiltersSets = ({
  currentShopId,
  dispatch,
}: {
  currentShopId?: number;
  dispatch: Dispatch;
}): { filterSets?: FilterSetConfiguration[] } => {
  const prevShopId = usePreviousState(currentShopId);

  const filterSets = useAppSelector(state => state.filtersSets.sets);

  useEffect(() => {
    if (currentShopId && currentShopId !== prevShopId) {
      dispatch(filterSetsActions.getFilters({ shopId: currentShopId }));
    }
  }, [currentShopId, prevShopId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(filterSetsActions.resetFiltersSetsState());
    };
  }, [dispatch]);

  return { filterSets };
};
