import { InspirationalImagesGallery } from 'src/services';
import { initialDraftState } from './constants';
import { GalleryDetailsDraft } from './types';

export class GalleryFormMapper {
  static mapGalleryDetailsToDraft(gallery?: InspirationalImagesGallery): GalleryDetailsDraft {
    if (!gallery) {
      return initialDraftState;
    }
    return {
      galleryName: gallery.galleryName,
      galleryType: gallery.galleryType,
    };
  }
}
