import Joi from 'joi';
import { IDataFieldsTableHeadRow } from './types';

export const dataFieldValidationSchema = {
  id: Joi.string().required(),
  name: Joi.string().required(),
  displayName: Joi.string().required().trim().messages({
    'string.empty': 'Display name can not be empty',
  }),
};

export const dataFieldsValidationSchema = {
  dataFields: Joi.array().items(dataFieldValidationSchema),
};

export const getTableColumns = (isSyteAdmin: boolean): Record<string, IDataFieldsTableHeadRow> => {
  return {
    displayName: {
      Header: 'Display Name',
      accessor: 'displayName',
      width: isSyteAdmin ? '35%' : '40%',
    },
    name: {
      Header: 'Original Name',
      accessor: 'name',
      width: isSyteAdmin ? '30%' : '40%',
    },
    types: {
      Header: 'Data Type',
      accessor: 'types',
      width: isSyteAdmin ? '17%' : '20%',
    },
    ...(isSyteAdmin
      ? {
          catalogNames: {
            Header: 'Catalog Names',
            accessor: 'catalogNames',
            width: '18%',
          },
        }
      : undefined),
  };
};
