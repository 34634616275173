import React from 'react';
import { Button } from 'src/components-dummy';

const DASHBOARD_URL =
  'https://eu-west-1a.online.tableau.com/#/site/syteanalytics/views/ABtestresults/ABTestOverview';

const onGoToDashboard = (): void => {
  window.open(DASHBOARD_URL, '_blank');
};

export const ExperimentGoToDashboardButton = (): JSX.Element => {
  return (
    <Button variant='secondary' onClick={onGoToDashboard} className='experiments-btn-dashboard'>
      Go to Dashboard
    </Button>
  );
};
