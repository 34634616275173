import React, { useCallback, useState } from 'react';
import { groupBy } from 'lodash';
import {
  Accordion,
  AvailableIcons,
  Button,
  ConfirmationDialog,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { RelevancyTuningItem } from 'src/services';
import {
  AccordionStyled,
  ConfirmationDialogContentStyled,
  ExpandableTriggerStyled,
  ExpandedContentStyled,
  TriggerIconStyled,
  TriggerTitleStyled,
} from './RelevancyTuningTable.styles';

const EXPANDED_DETAILS_ID = 'EXPANDED_DETAILS_ID';

interface ResetDefaultConfirmationDialogProps {
  open: boolean;
  confirmInProgress: boolean;
  relevancyTuning: RelevancyTuningItem[];
  onConfirm: () => void;
  onCancel: () => void;
}

export function ResetDefaultConfirmationDialog({
  open,
  confirmInProgress,
  relevancyTuning,
  onConfirm,
  onCancel,
}: ResetDefaultConfirmationDialogProps): JSX.Element {
  const [areDetailsExpanded, setAreDetailsExpanded] = useState(false);

  const onExpandDetails = useCallback(() => {
    setAreDetailsExpanded(prev => !prev);
  }, [setAreDetailsExpanded]);

  const getDefaultsDetailsMarkup = useCallback(() => {
    const tuningsGroupedByDefaults = groupBy(relevancyTuning, 'defaultPriority');
    const defaultPrioritiesSortedDescending = Object.keys(tuningsGroupedByDefaults).sort(
      (a, b) => Number(b) - Number(a)
    );

    return defaultPrioritiesSortedDescending.reduce(
      (markupValue, defaultPriority) => {
        const relevantTunings: RelevancyTuningItem[] = tuningsGroupedByDefaults[defaultPriority];
        const displayNames = relevantTunings.map(tuning => tuning.displayName);

        return (
          <>
            {markupValue}
            <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Paragraph}>
              {displayNames.join(', ')}: {defaultPriority}
            </Typography>
          </>
        );
      },
      <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Paragraph}>
        Defaults:
      </Typography>
    );
  }, [relevancyTuning]);

  return (
    <>
      {open && (
        <ConfirmationDialog onCancel={onCancel}>
          <ConfirmationDialog.Header>
            <ConfirmationDialog.Title>Reset Relevancy Tuning to Default</ConfirmationDialog.Title>
          </ConfirmationDialog.Header>
          <ConfirmationDialogContentStyled>
            <Typography>
              You are about to reset your relevancy tuning settings to Syte&apos;s default
              configuration.
            </Typography>
            <Typography>Please note that any unsaved changes will be lost.</Typography>
            <AccordionStyled
              onSelectionChanged={onExpandDetails}
              expandedIds={areDetailsExpanded ? EXPANDED_DETAILS_ID : undefined}
            >
              <Accordion.Item id={EXPANDED_DETAILS_ID} observeHeight>
                <Accordion.Item.Header>
                  <ExpandableTriggerStyled>
                    <TriggerTitleStyled
                      variant={TypographyVariant.MediumMedium}
                      type={TypographyType.Body}
                    >
                      Learn more about your shop&apos;s default tuning
                    </TriggerTitleStyled>
                    <TriggerIconStyled
                      name={AvailableIcons.SelectCaret}
                      rotate={areDetailsExpanded}
                    />
                  </ExpandableTriggerStyled>
                </Accordion.Item.Header>
                <Accordion.Item.Content>
                  <ExpandedContentStyled>{getDefaultsDetailsMarkup()}</ExpandedContentStyled>
                </Accordion.Item.Content>
              </Accordion.Item>
            </AccordionStyled>
          </ConfirmationDialogContentStyled>
          <ConfirmationDialog.Footer>
            <ConfirmationDialog.ButtonsContainer>
              <Button variant='primary' onClick={onConfirm} loading={confirmInProgress}>
                Reset to Default
              </Button>
              <Button variant='outlined' onClick={onCancel}>
                Cancel
              </Button>
            </ConfirmationDialog.ButtonsContainer>
          </ConfirmationDialog.Footer>
        </ConfirmationDialog>
      )}
    </>
  );
}
