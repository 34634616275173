import React from 'react';
import { Row } from 'react-table';
import { TableV2 } from 'src/components-dummy';
import { tableColumns } from './tableColumns';
import { AdminSearchUser } from '../types';
import { TableBodyRowCell } from '../../AdminSearchModal.styles';

interface ShopsTableRowProps {
  row: Row<AdminSearchUser>;
  onClick: VoidFunction;
}

export const TableRow = ({ row, onClick }: ShopsTableRowProps): JSX.Element => {
  return (
    <>
      <TableV2.BodyRow {...row.getRowProps()} key={row.original.userId}>
        {row.cells.map(cell => {
          const propName = cell.column.id;

          if (propName === tableColumns.online.accessor) {
            const cellValue = cell.value ? 'yes' : '';
            return (
              <TableBodyRowCell
                {...cell.getCellProps()}
                key={propName}
                width={cell.column.width as string}
                minWidth={cell.column.minWidth}
                data-id={`${row.original.accountName}-${propName}`}
                onClick={onClick}
              >
                <TableV2.BodyRowCellText>{cellValue}</TableV2.BodyRowCellText>
              </TableBodyRowCell>
            );
          }

          return (
            <TableBodyRowCell
              {...cell.getCellProps()}
              key={propName}
              width={cell.column.width as string}
              minWidth={cell.column.minWidth}
              data-id={`${row.original.userId}-${propName}`}
              onClick={onClick}
            >
              <TableV2.BodyRowCellText>{cell.value}</TableV2.BodyRowCellText>
            </TableBodyRowCell>
          );
        })}
      </TableV2.BodyRow>
    </>
  );
};
