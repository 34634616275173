import styled from '@emotion/styled';
import { Badge, Tooltip } from 'src/components-dummy';

export const BadgeStyled = styled(Badge)``;

export const TooltipStyled = styled(Tooltip)`
  .syte-popup-content {
    height: 38px;
    align-items: center;
    min-width: 375px;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0;
    }
  }
`;

export const InternalFeatureIndicationStyled = styled.div<{ show: boolean }>`
  transition: opacity 0.2s;
  opacity: ${props => (props.show ? '100' : '0')};
  user-select: none;
  margin-right: 24px;
`;
