import React from 'react';
import { Button } from '../../../components-dummy';

interface CreateCollectionButtonProps {
  onCreateDefaultCollection?: VoidFunction;
  buttonText?: string;
}

export const CreateCollectionButton = ({
  onCreateDefaultCollection,
  buttonText,
}: CreateCollectionButtonProps): JSX.Element => {
  return <Button onClick={onCreateDefaultCollection}>{buttonText}</Button>;
};
