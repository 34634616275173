import React, { createContext, useContext } from 'react';
import { AppDispatch } from 'src/app-types';
import { IStoriesService } from '../../services/src/service/stories/stories-service';

const StoriesContext = createContext<{
  service: IStoriesService;
  appDispatch: AppDispatch;
} | null>(null);

export const StoriesProvider: React.FC<{
  service: IStoriesService;
  appDispatch: AppDispatch;
}> = ({ service, appDispatch, children }) => {
  return (
    <StoriesContext.Provider value={{ service, appDispatch }}>{children}</StoriesContext.Provider>
  );
};

export const useStoriesProvider = () => {
  const context = useContext(StoriesContext);
  if (!context) {
    throw new Error('useStoriesProvider should be used within a StoriesProvider');
  }
  return context;
};
