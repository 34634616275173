import { RuleAction } from 'src/components-bl/FilterCard/types';
import { IMerchandisingRuleTableBodyRow, IMerchandisingRuleTableHeadRow } from './types';

export const tableColumns: Record<
  keyof Omit<IMerchandisingRuleTableBodyRow, 'id'>,
  IMerchandisingRuleTableHeadRow
> = {
  active: {
    Header: 'Active',
    accessor: 'active',
    minWidth: 40,
    maxWidth: 40,
  },

  title: {
    Header: 'Title',
    accessor: 'title',
    minWidth: 75,
  },
  kpi: {
    Header: 'KPI',
    accessor: 'kpi',
    minWidth: 45,
  },
  product: {
    Header: 'Product',
    accessor: 'product',
    minWidth: 75,
  },
  type: {
    Header: 'Type',
    accessor: 'type',
    minWidth: 70,
  },
  updatedAt: {
    Header: 'Last edit',
    accessor: 'updatedAt',
    minWidth: 75,
  },
  rowActions: {
    Header: '',
    accessor: 'rowActions',
    minWidth: 40,
  },
};

export const RuleActionText: Record<RuleAction, string> = {
  [RuleAction.Demote]: 'Demote',
  [RuleAction.Promote]: 'Promote',
  [RuleAction.Exclude]: 'Exclude',
  [RuleAction.Include]: 'Include',
  [RuleAction.Redirect]: 'Redirect',
  [RuleAction.PinToPosition]: 'Pin to position',
};
