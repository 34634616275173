import { useCallback } from 'react';
import { useAppSelector } from '../../../../../hooks';
import { PreviewFeature } from '../../../../../services';
import { visualEditorActions } from '../../../state';
import { useVisualEditorProvider } from '../../../context';

export const useChangeShopperExperience = (callback?: VoidFunction) => {
  const { experiences, selectedExperience } = useAppSelector(({ visualEditor }) => visualEditor);
  const { appDispatch } = useVisualEditorProvider();

  const changeExperience = useCallback((newExperience: PreviewFeature) => {
    if (!newExperience) return;
    appDispatch(visualEditorActions.resetPartialState());
    appDispatch(visualEditorActions.changeExperience(newExperience));
    callback?.();
  }, []);

  return {
    experiences,
    selectedExperience: selectedExperience || undefined,
    changeExperience,
  };
};
