import { AvailableIcons, NotificationType } from 'src/components-dummy';
import { PublishStatusIndicationMode, ModeConfig } from './types';

export const modeToConfigMap: Record<PublishStatusIndicationMode, ModeConfig> = {
  [PublishStatusIndicationMode.AutomaticPublish]: {
    notificationType: NotificationType.Success,
    icon: AvailableIcons.CloudSuccess,
    text: 'All saved changes will be published',
  },
  [PublishStatusIndicationMode.CanPublish]: {
    notificationType: NotificationType.Warning,
    icon: AvailableIcons.CloudError1,
    text: 'Unpublished changes',
  },
  [PublishStatusIndicationMode.UpToDate]: {
    notificationType: NotificationType.Success,
    icon: AvailableIcons.CloudSuccess,
    text: 'This shop is up to date',
  },
};

export const REVIEW_BEFORE_PUBLISH_BANNER_HEIGHT_PX = 32;
