import React from 'react';
import classNames from 'classnames';
import { CardItemStyled } from './CardItem.styles';
import { CardProps } from '../Card';

export interface CardItemProps extends CardProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
  selected?: boolean;
  clickable?: boolean;
}

export const CardItem = ({
  children,
  onClick,
  onKeyDown,
  className,
  selected = false,
  clickable = false,
}: CardItemProps): JSX.Element => {
  return (
    <CardItemStyled
      className={classNames('syte-card-item', className)}
      onClick={onClick}
      onKeyDown={onKeyDown}
      isClickable={!!(clickable || onClick)}
      isSelected={selected}
    >
      {children}
    </CardItemStyled>
  );
};
