import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { RoutedComponentProps } from 'src/app-routes';
import { useAppSelector, usePreviousState } from 'src/hooks';
import { CatalogUploadErrorReportsPage } from 'src/components-bl/CatalogManager/CatalogUploadErrorReport';
import {
  CatalogUploadErrorsPaginationWithFilters,
  ICatalogFeedUploadLog,
} from 'src/services/src/service/types/catalog-manager';
import { UserRoles } from 'src/services/src/service/types/users';
import { CatalogUploadErrorReportListContainerActions } from './CatalogUploadErrorReportListContainer.actions';
import { PAGINATION_DEFAULT_CONFIG } from './CatalogUploadErrorReportListContainer.config';

export const CatalogUploadErrorReportListContainer = ({
  permittedRouteMap,
}: RoutedComponentProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const { shopId } = useAppSelector(state => state.shop.current) || {};

  const { catalogUploadErrorReports } = useAppSelector(state => state.catalogManager) || {};
  const { catalogFeedUploadLogs } = useAppSelector(state => state.catalogManager) || {};

  const userRole = useAppSelector(state => state.global.loggedInUser?.role);

  const previousShopId = usePreviousState(shopId);
  const history = useHistory();

  const { catalogName, jobId } = useParams<{
    catalogName: string;
    jobId: string;
  }>();

  const navigateToUploadLogsList = useCallback(() => {
    if (permittedRouteMap.catalogManagerFeedUploadLog && shopId) {
      const path = generatePath(permittedRouteMap.catalogManagerFeedUploadLog?.path, {
        shopId,
        catalogName,
      });

      dispatch(
        CatalogUploadErrorReportListContainerActions.navigateTo({
          navigateTo: path,
        })
      );
    }
  }, [permittedRouteMap, shopId, catalogName]);

  const onFetchPaginatedFilteredData = useCallback(
    async (paginationWithFilters: CatalogUploadErrorsPaginationWithFilters): Promise<void> => {
      if (!shopId) return;

      await dispatch(
        CatalogUploadErrorReportListContainerActions.getCatalogUploadErrorReport({
          shopId,
          catalogName,
          jobId,
          ...paginationWithFilters,
        })
      );
    },
    [shopId, catalogName, jobId, dispatch]
  );

  const navigatePreviousPage = () => {
    history.goBack();
  };

  useEffect(() => {
    if (previousShopId && shopId !== previousShopId && shopId) {
      navigateToUploadLogsList();
    }
  }, [shopId]);

  useEffect(() => {
    if (shopId) {
      if (catalogName) {
        const fetch = async () => {
          try {
            await (
              dispatch(
                CatalogUploadErrorReportListContainerActions.getCatalogUploadErrorReport({
                  shopId,
                  catalogName,
                  jobId,
                  skip: PAGINATION_DEFAULT_CONFIG.skip,
                  limit: PAGINATION_DEFAULT_CONFIG.limit,
                })
              ) as any
            ).unwrap();
          } catch (error) {
            console.error(error);
            navigatePreviousPage();
          }
        };

        fetch();
      }
    }
  }, [catalogName, shopId]);

  useEffect(() => {
    return () => {
      dispatch(CatalogUploadErrorReportListContainerActions.resetData());
    };
  }, []);

  useEffect(() => {
    if (shopId) {
      if (catalogName) {
        const fetch = async () => {
          try {
            // TODO: Instead fetch only feed run by "job_id" (need to add API in platform BE)
            await (
              dispatch(
                CatalogUploadErrorReportListContainerActions.getCatalogFeedUploadFileContent({
                  shopId,
                  catalogName,
                })
              ) as any
            ).unwrap();
          } catch (error) {
            console.error(error);
          }
        };

        fetch();
      }
    }
  }, [catalogName, shopId]);

  if (!shopId) {
    return null;
  }

  const isAdminUser = userRole === UserRoles.SyteAdmin;

  const selectedFeedRunUpload = catalogFeedUploadLogs?.find(
    (feedRun: ICatalogFeedUploadLog) => feedRun.jobId === jobId
  );

  return (
    <CatalogUploadErrorReportsPage
      catalogUploadErrorReports={catalogUploadErrorReports}
      navigateToUploadLogsList={navigateToUploadLogsList}
      onFiltersOrPaginationChange={onFetchPaginatedFilteredData}
      paginationDefaultConfig={PAGINATION_DEFAULT_CONFIG}
      feedRunStartDate={selectedFeedRunUpload?.startDate}
      isAdminUser={isAdminUser}
      catalogName={catalogName}
    />
  );
};
