import React from 'react';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog';
import { Button } from 'src/components-dummy/Button';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

interface IncludeTagsModalModalProps {
  onCancel: () => void;
  onIncludeClick: () => void;
  mode: 'off' | 'on';
  tagsCount: number;
}

export const IncludeTagsModal = ({
  onCancel,
  onIncludeClick,
  mode,
  tagsCount,
}: IncludeTagsModalModalProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onCancel}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Turn {mode} tags</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        <Typography type={TypographyType.Body} variant={TypographyVariant.LargeMedium}>
          You are turning {mode} {tagsCount} tags
        </Typography>
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onIncludeClick}>
            Turn {mode} tags
          </Button>
          <Button variant='outlined' onClick={onCancel}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
