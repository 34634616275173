import { css } from '@emotion/react';
import React from 'react';
import { AvailableIcons, Icon, Tooltip } from 'src/components-dummy';

export const CatalogUnavailableAlert = (): JSX.Element => {
  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        justify-content: center;
      `}
    >
      <Tooltip value='This catalog is unavailable'>
        <Icon name={AvailableIcons.CircleWarning} />
      </Tooltip>
    </div>
  );
};
