import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';
import { buildQueryParams } from '../../../services/src/common/utils';
import { RouteSettings } from '../../../app-routes';
import { VisualEditorParams } from '../types/params';

interface UseAddParamsToRoute {
  shopId: number;
  route?: RouteSettings;
}

export const useAddParamsToRoute = ({ route, shopId }: UseAddParamsToRoute) => {
  const history = useHistory();

  const addParamsToRoute = (params: VisualEditorParams) => {
    if (route) {
      const path = generatePath(route.path, { shopId });
      const queryStringParams = buildQueryParams({
        ...params,
      });
      history.push(`${path}${queryStringParams}`);
    }
  };

  return { addParamsToRoute };
};
