import { Stack, SxProps } from '@mui/material';
import React from 'react';
import { Skeleton, SkeletonProps } from './Skeleton';

interface ItemProps extends SkeletonProps {
  height?: number;
}

export interface SkeletonCompositionProps {
  itemProps?: ItemProps;
  spacing?: number;
  sx?: SxProps; // container sx (style)
}

export interface SkeletonCompositionWithTitleProps extends SkeletonCompositionProps {
  titleItemProps?: ItemProps;
}

/**
 * Default content
 */
export const SkeletonDefaultContent = ({
  itemProps,
  spacing = 1,
  ...rest
}: SkeletonCompositionProps): JSX.Element => {
  const { height = 32, ...itemRest } = itemProps || {};
  return (
    <Stack sx={{ width: '100%' }} spacing={spacing} {...rest}>
      <Skeleton {...itemRest} height={height * 2} width='40%' />
      <Skeleton {...itemRest} height={height} />
      <Skeleton {...itemRest} height={height} />
      <Skeleton {...itemRest} height={height} />
    </Stack>
  );
};

/**
 * Vertical menu
 */
export const SkeletonVerticalBlocks = ({
  itemProps,
  spacing = 1,
  ...rest
}: SkeletonCompositionProps): JSX.Element => {
  const { height = 32, ...itemRest } = itemProps || {};

  return (
    <Stack sx={{ width: '100%' }} spacing={spacing} {...rest}>
      <Skeleton variant='rounded' {...itemRest} height={height} />
      <Skeleton variant='rounded' {...itemRest} height={height} />
      <Skeleton variant='rounded' {...itemRest} height={height} />
    </Stack>
  );
};

export const SkeletonVerticalBlocksWithTitle = (
  props: SkeletonCompositionWithTitleProps
): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const { spacing, itemProps, titleItemProps, ...rest } = props;

  return (
    <Stack spacing={2} sx={{ width: '100%' }} {...rest}>
      <Skeleton variant='text' width='20%' {...titleItemProps} />
      <SkeletonVerticalBlocks {...props} sx={{ width: '100%' }} />
    </Stack>
  );
};

/**
 * Horizontal blocks/cards
 */
export const SkeletonHorizontalBlocks = ({
  itemProps,
  spacing = 1,
  ...rest
}: SkeletonCompositionProps): JSX.Element => {
  const { height = 32, ...itemRest } = itemProps || {};

  return (
    <Stack sx={{ width: '100%' }} spacing={spacing} {...rest}>
      <Skeleton variant='rounded' {...itemRest} height={height} />
      <Skeleton variant='rounded' {...itemRest} height={height} />
      <Skeleton variant='rounded' {...itemRest} height={height} />
    </Stack>
  );
};

export const SkeletonHorizontalBlocksWithTitle = (
  props: SkeletonCompositionWithTitleProps
): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const { spacing, titleItemProps, ...rest } = props;

  return (
    <Stack spacing={2} {...rest}>
      <Skeleton variant='text' width='10%' {...titleItemProps} />
      <SkeletonHorizontalBlocks {...props} />
    </Stack>
  );
};
