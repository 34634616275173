import styled from '@emotion/styled';
import { Icon, Typography } from '../../../../components-dummy';

export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CloseButtonStyled = styled(Icon)`
  width: 16px;
  height: 16px;

  path {
    stroke: ${({ theme }) => theme.palette.common.black};
  }
`;

export const HeaderSeparatorStyled = styled.div`
  margin: 0 4px;
`;

export const ContentStyled = styled.div`
  display: flex;
  height: calc(100vh - 163px);
`;

export const ImagesContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 5;
  background-color: ${({ theme }) => theme.palette.common.black};
  color: ${({ theme }) => theme.palette.common.white};
  padding: 16px 0;
  overflow-y: auto;
  width: 50%;
`;

export const ImagesTitleStyled = styled(Typography)`
  padding: 0 16px;
`;

export const ImagesListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ImageWrapperStyled = styled.div`
  min-width: 200px;
  padding: 0 16px;
`;

export const ImageStyled = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

export const ProductDataContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 5;
  width: 50%;
  padding-bottom: 16px;
`;

export const TabsContainerStyled = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
`;

export const ScrollableSection = styled.div`
  overflow-y: auto;
  padding: 0 16px 8px;
`;

export const AccordionHeaderStyled = styled.div`
  display: flex;
  flex-grow: 1;
  border-bottom: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  padding: 8px 0;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const TitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const ProductDataGroupStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ProductDataStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BooleanDataStyled = styled(Typography)<{ color: string }>`
  color: ${({ color }) => color};
`;

export const ImageNotFoundWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 0 16px;
  background-color: ${({ theme }) => theme.palette.custom['gray-70']};
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  height: 480px;
  border-radius: 8px;
`;

export const NotificationStyled = styled.div`
  display: flex;
  padding: 16px;
  margin: 16px;
  gap: 16px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 -1px 6px -2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`;

export const NotificationContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
