import { Product } from '../../types/entities.types';

export enum SkuState {
  NoSku = 'noSku',
  HasSku = 'hasSku',
  Loading = 'loading',
  Error = 'error',
  Resolved = 'resolvedSku',
}

export type State = {
  sku: string;
  type: SkuState;
  error: Error | null;
  product: Product | null;
};
