import { createAsyncThunk } from '@reduxjs/toolkit';
import { lexiconService } from 'src/services';

export const lexiconSettingsContainerActions = {
  getLexiconSettings: createAsyncThunk(
    'ShopLexiconSettings/getLexiconSettings',
    ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        return lexiconService.getShopLexiconsSettings({ shopId });
      } catch (error: unknown) {
        return rejectWithValue({ error });
      }
    }
  ),
};
