import { createAsyncThunk } from '@reduxjs/toolkit';
import { merchandisingRulesService } from 'src/services';
import { GetRulesArguments } from 'src/services/src/service/merchandising-rules/types';

export const useValidateMerchandisingRuleLimitActions = {
  getRulesCountForValidation: createAsyncThunk(
    'UseValidateMerchandisingRuleLimit/GetRulesCountForValidation',
    async (parameters: GetRulesArguments, { rejectWithValue }) => {
      try {
        const { pagination } = await merchandisingRulesService.getRules(parameters);
        return pagination.totalCount;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
