import styled from '@emotion/styled';
import { Page, Skeleton } from 'src/components-dummy';

export const PageStyled = styled(Page)`
  height: 100%;
  width: 100%;
  overflow-y: hidden !important;

  .syte-page-header {
    border-bottom: none;
  }

  .syte-page-title {
    margin-right: auto;
  }

  .syte-page-content {
    flex-direction: column;
    padding-top: unset;
  }

  background: ${({ theme }) => theme.palette.custom['gray-90']};
`;

export const StyledSkeleton = styled(Skeleton)`
  margin: 10px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`;
