import { Accounts } from 'src/services';
import { Dispatch } from '../../../../types';
import {
  SearchAccountsRequestAction,
  SearchAccountsRequestActionPayload,
  SearchAccountsErrorAction,
  SearchAccountsErrorActionPayload,
  SearchAccountsSuccessAction,
  SearchAccountsSuccessActionPayload,
  AccountListSectionActionTypes,
} from './types';

const accountService = new Accounts();

/* --= Actions =-- */
export const accountListSectionActions = {
  searchAccountsRequest: (
    payload: SearchAccountsRequestActionPayload
  ): SearchAccountsRequestAction => {
    return {
      type: AccountListSectionActionTypes.SearchAccountsRequest,
      payload,
    };
  },
  searchAccountsError: (payload: SearchAccountsErrorActionPayload): SearchAccountsErrorAction => {
    return {
      type: AccountListSectionActionTypes.SearchAccountsError,
      payload,
    };
  },
  searchAccountsSuccess: (
    payload: SearchAccountsSuccessActionPayload
  ): SearchAccountsSuccessAction => {
    return {
      type: AccountListSectionActionTypes.SearchAccountsSuccess,
      payload,
    };
  },
};

/* --= Methods =-- */
export const searchAccounts =
  ({
    searchTerm,
    skip,
    limit,
    includeArchived,
    isLoadMore = false,
  }: SearchAccountsRequestActionPayload) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(
      accountListSectionActions.searchAccountsRequest({ searchTerm, skip, limit, includeArchived })
    );
    try {
      const accounts = await accountService.searchAccounts({
        searchTerm,
        skip,
        limit,
        includeArchived,
      });
      dispatch(accountListSectionActions.searchAccountsSuccess({ accounts, isLoadMore }));
    } catch (error) {
      dispatch(accountListSectionActions.searchAccountsError({ error }));
    }
  };
