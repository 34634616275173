import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const NotificationsCardDividerStyled = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
`;

export const NotificationsTabStyled = styled.div`
  overflow-y: auto;
  max-height: 496px;
`;

export const EmptyNotificationsMessageStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-20']};
  padding: 30px 0;
`;
