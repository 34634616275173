import React, { useEffect, useState, useCallback } from 'react';
import { Dispatch } from 'src/components-bl';
import { Page, TabV2, Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { ILexiconItem } from 'src/services';
import { UserRoles } from 'src/services/src/service/types/users';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';
import { generatePath, useParams } from 'react-router';
import { LexiconTable, LexiconTableApiRef } from '../LexiconTable/LexiconTable';
import { LexiconPageHeader, LexiconPageHeaderProps } from '../LexiconPageHeader';
import { PageStyled, TabsStyled } from './LexiconTablePage.styles';
import { lexiconTablePageActions } from './LexiconTablePage.actions';

export interface LexiconTablePageProps extends Omit<LexiconPageHeaderProps, 'shopId' | 'locale'> {
  shopId: number;
  dispatch: Dispatch;
  locale: string;
  lexiconItems?: ILexiconItem[];
  lexiconTotalItems?: number;
  displayName?: string;
  iconName?: string;
  tableRef: React.MutableRefObject<LexiconTableApiRef | null>;
  editThematicTagRoute?: string;
  editLexiconRuleRoute?: string;
  lexiconTagsRoute?: string;
  userRole?: UserRoles;
}

const tabs = [
  { key: LexiconTagType.LexiconTags, name: 'Lexicon' },
  { key: LexiconTagType.ThematicTags, name: 'Thematic tag' },
  { key: LexiconTagType.RenameRules, name: 'Rename Rule' },
];

const tagTypeToTabIndexMap = tabs.reduce(
  (accumulator, current, index) => {
    accumulator[current.key] = index;
    return accumulator;
  },
  {} as Record<LexiconTagType, number>
);

const validTagTypes = Object.values(LexiconTagType);

export const LexiconTablePage = ({
  shopId,
  locale,
  dispatch,
  lexiconItems,
  lexiconTotalItems,
  showApplyAll,
  lexiconMetadata,
  lexiconsRoute,
  lexiconTagsRoute,
  createThematicTagRoute,
  createLexiconRuleRoute,
  editLexiconRuleRoute,
  importLexiconRoute,
  exportLexiconRoute,
  displayName,
  iconName,
  tableRef,
  editThematicTagRoute,
  userRole,
  tagType,
}: LexiconTablePageProps): JSX.Element => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(tagTypeToTabIndexMap[tagType]);

  const { tagType: tagTypeParameter } = useParams<{ tagType: LexiconTagType }>();

  const navigateToTab = useCallback(
    (newTagType: LexiconTagType) => {
      if (lexiconTagsRoute) {
        const url = generatePath(lexiconTagsRoute, {
          shopId,
          locale,
          tagType: newTagType,
        });

        dispatch(
          lexiconTablePageActions.navigateTo({
            navigateTo: url,
          })
        );
      }
    },
    [dispatch, shopId, locale, lexiconTagsRoute]
  );

  const onTabChange = (_: any, newTabIndex: number) => {
    setSelectedTabIndex(newTabIndex);

    const nextTab = tabs[newTabIndex];

    if (nextTab) {
      navigateToTab(nextTab.key);
    }
  };

  useEffect(() => {
    const isValid = validTagTypes.includes(tagTypeParameter);

    if (!isValid) {
      navigateToTab(LexiconTagType.LexiconTags);
    } else {
      const newTabIndex = tagTypeToTabIndexMap[tagTypeParameter];

      if (selectedTabIndex !== newTabIndex) {
        dispatch(lexiconTablePageActions.setCurrentTagType({ tagType: tagTypeParameter }));
      }
    }
  }, [tagTypeParameter]);

  useEffect(() => {
    const newTabIndex = tagTypeToTabIndexMap[tagType];

    if (newTabIndex !== selectedTabIndex) {
      setSelectedTabIndex(newTabIndex);
    }
  }, [tagType]);

  return (
    <PageStyled>
      <Page.Header>
        <LexiconPageHeader
          shopId={shopId}
          locale={locale}
          dispatch={dispatch}
          lexiconMetadata={lexiconMetadata}
          showApplyAll={showApplyAll}
          lexiconsRoute={lexiconsRoute}
          createThematicTagRoute={createThematicTagRoute}
          createLexiconRuleRoute={createLexiconRuleRoute}
          importLexiconRoute={importLexiconRoute}
          exportLexiconRoute={exportLexiconRoute}
          refetchLexiconTags={tableRef.current?.refetchTagsOnCurrentPage}
          displayName={displayName}
          iconName={iconName}
          userRole={userRole}
          tagType={tagTypeParameter}
        />
      </Page.Header>
      <Page.Content>
        <TabsStyled value={selectedTabIndex} onChange={onTabChange}>
          {tabs.map(({ name, key }, index) => {
            const isSelected = selectedTabIndex === index;

            return (
              <TabV2
                key={key}
                label={
                  <Typography
                    type={TypographyType.Body}
                    variant={
                      isSelected ? TypographyVariant.MediumBold : TypographyVariant.MediumMedium
                    }
                  >
                    {name}
                  </Typography>
                }
              />
            );
          })}
        </TabsStyled>
        {selectedTabIndex === tagTypeToTabIndexMap[LexiconTagType.LexiconTags] && (
          <LexiconTable
            ref={tableRef}
            shopId={shopId}
            locale={locale}
            dispatch={dispatch}
            tagType={LexiconTagType.LexiconTags}
            lexiconItems={lexiconItems}
            lexiconTotalItems={lexiconTotalItems}
            showApplyAll={showApplyAll}
            editThematicTagRoute={editThematicTagRoute}
            userRole={userRole}
          />
        )}
        {selectedTabIndex === tagTypeToTabIndexMap[LexiconTagType.ThematicTags] && (
          <LexiconTable
            ref={tableRef}
            shopId={shopId}
            locale={locale}
            dispatch={dispatch}
            tagType={LexiconTagType.ThematicTags}
            lexiconItems={lexiconItems}
            lexiconTotalItems={lexiconTotalItems}
            showApplyAll={showApplyAll}
            editThematicTagRoute={editThematicTagRoute}
            userRole={userRole}
          />
        )}
        {selectedTabIndex === tagTypeToTabIndexMap[LexiconTagType.RenameRules] && (
          <LexiconTable
            ref={tableRef}
            shopId={shopId}
            locale={locale}
            dispatch={dispatch}
            tagType={LexiconTagType.RenameRules}
            lexiconItems={lexiconItems}
            lexiconTotalItems={lexiconTotalItems}
            showApplyAll={showApplyAll}
            editLexiconRuleRoute={editLexiconRuleRoute}
            userRole={userRole}
          />
        )}
      </Page.Content>
    </PageStyled>
  );
};
