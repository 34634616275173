import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Collapse } from '../Collapse';
import { findComponentByType, findComponentsExceptOfType } from '../helpers';
import { useFindComponentsByType } from '../hooks';
import './Accordion.scss';
import {
  AccordionItem,
  AccordionItemContent,
  AccordionItemHeader,
  AccordionItemTrigger,
} from './components/AccordionItem';
import { AccordionItemProps, AccordionProps } from './types';

export const Accordion = ({
  children,
  canExpandMultiple,
  expandedIds: expandedIdsProp,
  className,
  disabled,
  onSelectionChanged,
}: AccordionProps): JSX.Element => {
  let ExpandedIdsPropParsed: string[] = [];
  if (expandedIdsProp !== undefined) {
    ExpandedIdsPropParsed = Array.isArray(expandedIdsProp) ? expandedIdsProp : [expandedIdsProp];
  }

  const [expandedItems, setExpandedItems] = useState(ExpandedIdsPropParsed);

  const expandedItemsSet = useMemo(() => new Set(expandedItems), [expandedItems]);

  const accordionItems = useFindComponentsByType({ type: AccordionItem, children });

  useEffect(() => {
    setExpandedItems(ExpandedIdsPropParsed);
  }, [expandedIdsProp]);

  const onItemExpandChange = ({
    itemExpanded,
    itemId,
  }: {
    itemExpanded: boolean;
    itemId: string;
  }) => {
    let newSelectedIds: string[] | undefined;
    const isItemInSet = expandedItemsSet.has(itemId);
    if (itemExpanded) {
      if (!isItemInSet) {
        if (canExpandMultiple) {
          newSelectedIds = [...expandedItems, itemId];
        } else {
          newSelectedIds = [itemId];
        }
      }
    } else if (isItemInSet) {
      newSelectedIds = expandedItems.filter(iterateId => iterateId !== itemId);
    }

    if (newSelectedIds) {
      if (onSelectionChanged) {
        onSelectionChanged?.(newSelectedIds);
      } else {
        setExpandedItems(newSelectedIds);
      }
    }
  };

  const collapses = accordionItems.map(accordionItem => {
    const {
      id: itemId,
      children: itemChildren,
      className: itemClassName,
      observeHeight,
    } = accordionItem.props as AccordionItemProps;

    const header = findComponentByType({
      type: AccordionItemHeader,
      children: itemChildren,
    });

    const trigger = findComponentByType({
      type: AccordionItemTrigger,
      children: header?.props.children,
    });

    const content = findComponentByType({
      type: AccordionItemContent,
      children: itemChildren,
    });

    const itemExpanded = expandedItemsSet.has(itemId);

    const headerChildren = findComponentsExceptOfType({
      children: header?.props.children,
      type: AccordionItemTrigger,
    });

    return (
      <Collapse
        className={itemClassName}
        expanded={itemExpanded}
        onExpandChange={() => onItemExpandChange({ itemId, itemExpanded: !itemExpanded })}
        key={itemId}
        disabled={disabled}
        observeHeight={observeHeight}
      >
        <Collapse.Header className={header?.props?.className}>
          {headerChildren}
          {trigger ? <Collapse.Header.Trigger {...trigger.props} /> : null}
        </Collapse.Header>
        <Collapse.Content className={content?.props?.className}>
          {content?.props?.children}
        </Collapse.Content>
      </Collapse>
    );
  });

  return <div className={classnames('syte-accordion', className)}>{collapses}</div>;
};

Accordion.Item = AccordionItem;
