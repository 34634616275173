import { AugmentedSearchGeneralSettings } from 'src/services';
import { DomainForRendering } from '../../types';
import { augmentedSearchGeneralSettingsDiffSchema } from './augmented-search-general-settings-diff-schema';

export const augmentedSearchGeneralSettingsSubDomainGroup: DomainForRendering<AugmentedSearchGeneralSettings> =
  {
    getCardTitle: () => {
      return 'Search Settings';
    },
    fieldsSchema: augmentedSearchGeneralSettingsDiffSchema,
  };
