import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { OverlayModal } from '../OverlayModal';
import { Button } from '../Button';
import { ChildrenProp } from '../types';
import { useFindComponentByType } from '../hooks';
import './Dialog.scss';
import { DialogActionsFooter } from './DialogActionsFooter';
import { DialogBody } from './DialogBody';
import { DialogFooter } from './DialogFooter';
import { DialogHeader } from './DialogHeader';
import { AvailableIcons } from '../Icon';

export interface DialogProps extends ChildrenProp {
  show: boolean;
  onClose: () => void;
  className?: string;
}

const DialogComponent = forwardRef<HTMLDivElement, DialogProps>(
  ({ children, show, onClose, className }, ref): JSX.Element => {
    const header = useFindComponentByType({ type: DialogHeader, children });
    const body = useFindComponentByType({ type: DialogBody, children });
    const footer =
      useFindComponentByType({ type: DialogFooter, children }) ||
      useFindComponentByType({ type: DialogActionsFooter, children });

    return (
      <OverlayModal className={classNames('syte-dialog', className)} show={show} ref={ref}>
        <div className='syte-dialog-content' onClick={ev => ev.stopPropagation()}>
          <Button
            className='syte-dialog-close-button'
            onClick={onClose}
            startIcon={AvailableIcons.Close}
            variant='tertiary'
          />
          {header}
          {body}
          {footer}
        </div>
      </OverlayModal>
    );
  }
);

export const Dialog = Object.assign(DialogComponent, {
  Header: DialogHeader,
  Body: DialogBody,
  Footer: DialogFooter,
  ActionsFooter: DialogActionsFooter,
});
