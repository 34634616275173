import { useState, useEffect } from 'react';
import { useSettingsChangeTracker } from 'src/components-bl';
import { UpdateReqStatus } from 'src/app-state-types';
import { fieldsToValidate, getInitialValidationsState, validations } from './form-validations';
import { shouldResetValue, FieldsToTriggerReset } from './fields-dependency-sync';
import {
  FilterSetConfiguration,
  DeepTagsSettings,
  ValidationErrors,
  OrderMethod,
  FieldToValidate,
} from './types';

type DeepTagsSettingsField = keyof DeepTagsSettings;
type DeepTagsSettingsValue = OrderMethod | boolean | number | undefined;

interface UseDeeptagsSettingsFormReturns {
  isDirty: boolean;
  onChange: (field: DeepTagsSettingsField, value: DeepTagsSettingsValue) => void;
  settingsForm: DeepTagsSettings;
  errors: ValidationErrors;
}
export const useDeeptagsSettingsForm = (
  filterSet: FilterSetConfiguration,
  updateStatus: UpdateReqStatus
): UseDeeptagsSettingsFormReturns => {
  const [settingsForm, setSettingsForm] = useState<DeepTagsSettings>(filterSet?.deepTags.settings);
  const { isDirty, registerSettingsChange, resetChangeLog } =
    useSettingsChangeTracker<DeepTagsSettings>(settingsForm);
  const [errors, setErrors] = useState<ValidationErrors>(getInitialValidationsState(settingsForm));
  useEffect(() => {
    if ([UpdateReqStatus.Pending, UpdateReqStatus.Failed].includes(updateStatus)) return;
    if (filterSet?.deepTags.settings) {
      setSettingsForm(filterSet.deepTags.settings);
      resetChangeLog(filterSet.deepTags.settings);
      setErrors(getInitialValidationsState(filterSet.deepTags.settings));
    }
  }, [filterSet, updateStatus]);
  const onChange = (field: DeepTagsSettingsField, value: DeepTagsSettingsValue) => {
    const updates = { ...settingsForm, [field]: value };
    const updatedErrors = { ...errors };
    if (fieldsToValidate.includes(field as string) && typeof value === 'number') {
      const fieldToValidate = field as FieldToValidate;
      updatedErrors[fieldToValidate] = validations(fieldToValidate, value);
    }
    const fieldToReset = shouldResetValue(field as FieldsToTriggerReset, value as boolean, errors);
    if (fieldToReset) {
      updates[fieldToReset] = filterSet?.deepTags.settings[fieldToReset];
      updatedErrors[fieldToReset] = [];
    }
    setErrors(updatedErrors);
    setSettingsForm(updates);
    registerSettingsChange(updates);
  };
  return { isDirty, onChange, settingsForm, errors };
};
