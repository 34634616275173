import React from 'react';
import classnames from 'classnames';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import {
  Badge,
  EditableCellProps,
  Indicator,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { VariantType } from 'src/services';
import { PRODUCTION_FIELD_NAME, variantTypeProperties } from './constants';
import './ExperimentVariantNameCell.scss';

export const ExperimentVariantNameCell = <
  T extends Record<string, unknown>,
  V extends VariantType | string,
>({
  value,
  row,
}: EditableCellProps<T, V>): JSX.Element => {
  const variantType = (row as any).original.type as VariantType | string;
  const isProd = variantType === PRODUCTION_FIELD_NAME;
  const isControl = variantType === VariantType.Control;
  const showBadge = isProd || isControl;

  return (
    <div className='syte-variant-name-cell'>
      <Indicator color={variantTypeProperties[variantType].color}>
        <>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
            {variantTypeProperties[variantType].name ?? value}
          </Typography>
          {showBadge && (
            <Badge
              text={isControl ? 'CONTROL' : ''}
              icon={isProd ? faLock : undefined}
              background='disabled'
              className={classnames({ production: isProd })}
            />
          )}
        </>
      </Indicator>
      <div className='syte-variant-cell-description'>
        {variantTypeProperties[variantType].subTitle}
      </div>
    </div>
  );
};
