import React from 'react';
import { TypographyType, TypographyVariant } from '../../../../components-dummy';
import { ProductSection } from './ProductSection';
import { ValueToDisplayByDataType } from './ValueToDisplayByDataType';
import { ProductDataStyled, TitleStyled } from './ProductDetails.styled';

const accordionId = 'product-images';

interface Props {
  imagesToDisplay?: { displayName: string; value: string | string[] }[];
}

export const ProductImagesSection = ({ imagesToDisplay }: Props) => {
  return (
    <ProductSection title='Product Images' accordionId={accordionId}>
      {imagesToDisplay.map(({ displayName, value }) => {
        return (
          <ProductDataStyled key={displayName}>
            <TitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
              {displayName}
            </TitleStyled>
            <ValueToDisplayByDataType value={value} />
          </ProductDataStyled>
        );
      })}
    </ProductSection>
  );
};
