/* eslint-disable no-param-reassign */
export const displayVariants = (toggle: HTMLDivElement): void => {
  const parent = toggle?.parentNode;
  toggle.style.width = 'auto';
  toggle.remove();
  const total = parent?.children.length || 0;

  let tgtIdx = 0;
  let maxWidth = 0;
  const childrenWidths = [];
  for (let idx = 0; idx < total; idx++) {
    if (!(parent?.children[idx] as HTMLDivElement).offsetTop) {
      childrenWidths.push((parent?.children[idx] as HTMLDivElement).offsetWidth);
      maxWidth = Math.max(...childrenWidths);
      tgtIdx++;
    }
  }

  if (tgtIdx >= total) {
    parent?.append(toggle);
    return;
  }

  const prev = parent?.children[tgtIdx - 1];

  toggle.innerText = `+${total - tgtIdx + 1}`;
  toggle.style.minWidth = `${Math.max(maxWidth, toggle.offsetWidth)}px`;
  parent?.insertBefore(toggle, prev as HTMLDivElement);
};
