import { RecentlyViewedSettingsTypes } from '../types';

export const buildRecentlyViewedSettingsPayload = (
  payload: Partial<RecentlyViewedSettingsTypes.RecentlyViewedSettings>
): Partial<RecentlyViewedSettingsTypes.RecentlyViewedSettings> => {
  const payloadData = {
    numberOfRecentDaysToDisplay: payload.numberOfRecentDaysToDisplay,
    numberOfResultsToDisplay: payload.numberOfResultsToDisplay,
    showOutOfStockItems: payload.showOutOfStockItems,
    showPreviouslyPurchasedItems: payload.showPreviouslyPurchasedItems,
    addToCart: payload.addToCart,
  };
  return payloadData;
};
