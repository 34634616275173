import { SerializedStyles } from '@emotion/react';
import React, { ReactNode } from 'react';
import { TypographyProps, TypographyType, TypographyVariant } from 'src/components-dummy';
import { LabelStyled, RuleFormFieldTextStyled } from './RuleFormField.styles';

export interface RuleFormFieldProps {
  children: JSX.Element | JSX.Element[] | ReactNode | ReactNode[];
  className?: string;
  css?: SerializedStyles;
}

export const RuleFormField = ({ children, className, css }: RuleFormFieldProps): JSX.Element => {
  // associated with given children
  return (
    <LabelStyled className={className} css={css}>
      {children}
    </LabelStyled>
  );
};

export type RuleFormFieldTextProps = TypographyProps;

export const RuleFormFieldText = ({ children, ...rest }: RuleFormFieldTextProps): JSX.Element => {
  return (
    <RuleFormFieldTextStyled
      type={TypographyType.Body}
      variant={TypographyVariant.SmallMedium}
      {...rest}
    >
      {children}
    </RuleFormFieldTextStyled>
  );
};

RuleFormField.LabelText = RuleFormFieldText;
