import React from 'react';
import styled from '@emotion/styled';
import {
  Typography,
  TypographyProps,
  TypographyType,
  TypographyVariant,
  Modal,
  TextBox,
} from 'src/components-dummy';
import { LinkButton } from 'src/components-dummy/LinkButton';

export const ModalStyled = styled(Modal)`
  .syte-modal-content {
    max-width: 520px;
  }
`;

export const ModalTitleStyled = styled.div`
  padding: 24px 40px;
`;

export const ModalContentStyled = styled.div`
  padding: 24px 40px;
`;

export const ModalContentDeleteStyled = styled.div`
  padding: 12px 40px;
`;

export const ModalContentDescriptionStyled = styled.div`
  margin-top: 24px;
`;

export const ModalFooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-50']};
  padding: 24px 40px;
`;

export const LinkButtonAddExpirationStyled = styled(LinkButton)`
  margin-top: 24px;
`;

export const TextBoxApiKeyStyled = styled(TextBox)`
  margin-top: 24px;
`;

export const LinkButtonRemoveExpirationStyled = styled(LinkButton)`
  margin-top: 16px;
`;

export const ExpirationSectionStyled = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  border-radius: 12px;
  padding: 24px;
  margin-top: 16px;
  flex-direction: column;
`;

export const RemoveExpirationSpanStyled = styled.span`
  line-height: 16px;
`;

export const ErrorsMessagesWrapperStyled = styled.div`
  margin-bottom: 16px;
`;

export const ErrorLabelStyled = styled((props: TypographyProps) => (
  <Typography {...props} type={TypographyType.Paragraph} variant={TypographyVariant.SmallRegular} />
))`
  color: ${({ theme }) => theme.palette.custom.red};
  margin-top: 4px;
`;
