import React, { MouseEventHandler } from 'react';
import { AvailableIcons } from 'src/components-dummy';
import { DeleteImageButtonStyled } from './DeleteImageButton.styles';
import { ButtonIconStyled } from './shared.styles';

interface DeleteImageButtonProps {
  onClick: MouseEventHandler<HTMLDivElement>;
}

export function DeleteImageButton({ onClick }: DeleteImageButtonProps): JSX.Element {
  return (
    <DeleteImageButtonStyled onClick={onClick}>
      <ButtonIconStyled name={AvailableIcons.Close} />
    </DeleteImageButtonStyled>
  );
}
