import React from 'react';
import { SpecialDataFieldToggle } from './SpecialDataFieldToggle';
import { Typography, TypographyType, TypographyVariant } from '../../../../../components-dummy';
import { SpecialDataFieldToDisplay } from '../../../types/special-data-field-to-display';
import {
  DataFieldsListContainer,
  DataFieldsListStyled,
} from '../DataFieldsList/DataFieldsList.styles';

interface SpecialDataFieldsListProps {
  specialDataFieldsToDisplay: SpecialDataFieldToDisplay[];
  onSpecialDataFieldChange: (field: SpecialDataFieldToDisplay, index: number) => void;
}

export const SpecialDataFieldsList = ({
  specialDataFieldsToDisplay,
  onSpecialDataFieldChange,
}: SpecialDataFieldsListProps): JSX.Element => {
  return (
    <DataFieldsListContainer>
      <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
        Fields to Display
      </Typography>
      <DataFieldsListStyled>
        {specialDataFieldsToDisplay.map((field, index) => {
          const { text, isActive, isDisabled } = field;
          return (
            <SpecialDataFieldToggle
              key={text}
              label={text}
              checked={isActive}
              disabled={isDisabled}
              onChange={checked => onSpecialDataFieldChange({ ...field, isActive: checked }, index)}
            />
          );
        })}
      </DataFieldsListStyled>
    </DataFieldsListContainer>
  );
};
