import React from 'react';
import styled from '@emotion/styled';
import { Typography, TypographyType, TypographyVariant } from '../../../../components-dummy';

export const NoPosts = () => {
  return (
    <div className='no-posts' data-testid='no-posts'>
      <Typography
        className='color-black'
        type={TypographyType.Body}
        variant={TypographyVariant.LargeMedium}
      >
        Please add post
      </Typography>
      <Typography
        className='color-grey'
        type={TypographyType.Body}
        variant={TypographyVariant.MediumRegular}
      >
        Add a post first by clicking the blue plus button
      </Typography>
    </div>
  );
};

const EditPostContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 40px 56px;
  width: 100%;
  height: 701px;
  background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  border-radius: 0 0 16px 16px;
`;

export const EditPost: React.FC<{ currentIndex: number | null }> = ({ currentIndex, children }) => {
  if (currentIndex === null) return <NoPosts />;
  return <EditPostContainer>{children}</EditPostContainer>;
};
