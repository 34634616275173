import type { Dispatch } from '../../../types';
import { uiTemplatesService } from '../../../../services/src/service/ui-templates';

import {
  UiTemplatesListActionPayload,
  UiTemplatesListActionTypes,
  UiTemplatesListErrorActionPayload,
  UiTemplatesListGetUiTemplatesErrorAction,
  UiTemplatesListGetUiTemplatesRequestAction,
  UiTemplatesListGetUiTemplatesSuccessAction,
  UiTemplatesListUpdateUiTemplatesErrorAction,
  UpdateUiTemplatesListErrorActionPayload,
} from './types';

const uiTemplatesListActions = {
  getUiTemplatesRequest(
    payload: UiTemplatesListActionPayload
  ): UiTemplatesListGetUiTemplatesRequestAction {
    return {
      type: UiTemplatesListActionTypes.GetUiTemplatesRequest,
      payload,
    };
  },

  getUiTemplatesSuccess(
    payload: UiTemplatesListActionPayload
  ): UiTemplatesListGetUiTemplatesSuccessAction {
    return {
      type: UiTemplatesListActionTypes.GetUiTemplatesSuccess,
      payload,
    };
  },

  getUiTemplatesError(
    payload: UiTemplatesListErrorActionPayload
  ): UiTemplatesListGetUiTemplatesErrorAction {
    return {
      type: UiTemplatesListActionTypes.GetUiTemplatesError,
      payload,
    };
  },

  updateUiTemplatesError(
    payload: UpdateUiTemplatesListErrorActionPayload
  ): UiTemplatesListUpdateUiTemplatesErrorAction {
    return {
      type: UiTemplatesListActionTypes.UpdateUiTemplatesError,
      payload,
    };
  },
};

export const uiTemplatesListActionMethods = {
  getUiTemplatesList(payload: UiTemplatesListActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(uiTemplatesListActions.getUiTemplatesRequest(payload));
        const templates = await uiTemplatesService.getUiTemplates({
          shopId: payload.shopId,
        });
        dispatch(
          uiTemplatesListActions.getUiTemplatesSuccess({
            ...payload,
            templates,
          })
        );
      } catch (error) {
        dispatch(uiTemplatesListActions.getUiTemplatesError({ error }));
      }
    };
  },

  updateUiTemplatesError(
    payload: UpdateUiTemplatesListErrorActionPayload
  ): UiTemplatesListUpdateUiTemplatesErrorAction {
    return uiTemplatesListActions.updateUiTemplatesError(payload);
  },
};
