import { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';
import { UiTemplate } from '../../../../components-bl/UiTemplates/types';

function parseDates(uiTemplateRaw: UiTemplate): UiTemplate {
  const uiTemplate = { ...uiTemplateRaw };
  ['createdAt', 'updatedAt', 'startedAt', 'endedAt'].forEach(keyString => {
    const key = keyString as keyof UiTemplate;
    if (uiTemplate[key]) {
      uiTemplate[key] = parseISO(uiTemplate[key] as string) as never;
    }
  });

  return uiTemplate;
}

export function mapUiTemplatesResponse({ data }: AxiosResponse): UiTemplate[] {
  return data.templates.map((uiTemplate: UiTemplate) => parseDates(uiTemplate));
}
export function mapUiTemplateResponse({ data }: AxiosResponse<UiTemplate>): UiTemplate {
  return parseDates(data);
}
