import { MetaTypes } from '../types';
import { ApiServiceBase } from '../api-service-base';
import { metaMapper } from './meta-mapper';

export class Meta extends ApiServiceBase {
  constructor() {
    super('meta');
  }

  async getLanguages(): Promise<MetaTypes.Language[]> {
    const url = `${this.serviceBaseUri}/languages`;
    const response = await this.httpService.get({ url });
    return metaMapper.mapLanguages(response);
  }

  async getSyteProductTypes(): Promise<MetaTypes.MetaSyteProductType[]> {
    const url = `${this.serviceBaseUri}/syte-products`;
    const response = await this.httpService.get({ url });
    return metaMapper.mapSyteProductTypes(response);
  }

  async getPublicMetadata(): Promise<MetaTypes.IMetaPublic> {
    const url = `${this.serviceBaseUri}/public`;
    const response = await this.httpService.get({ url });
    return metaMapper.mapPublicMetadata(response);
  }
}
