/* eslint-disable unused-imports/no-unused-vars-ts */
/* eslint-disable react/no-unescaped-entities */
import React, { useCallback } from 'react';
import {
  AvailableIcons,
  Button,
  CircularProgress,
  CircularProgressSizes,
  CircularWrapperStyled,
  Icon,
} from 'src/components-dummy';
import { TitleTextLengthEnum } from 'src/services/src';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { FooterActionsStyled } from '../../DeepTagsProductForm.style';
import {
  ActionsAndParametersStyled,
  ButtonEditStyled,
  ButtonWithIconWrapper,
  EditableTextSectionStyled,
  EditableContentStyled,
  EditableContentWrapperStyled,
  MainStyled,
  TabPanelActionsStyled,
  GenericContainerStyled,
} from './DeepTagsGenerativeAIForm.style';
import { GenerativeAIParameterMenu } from './GenerativeAIParameterMenu';
import { titleTextLengthMenuOptions } from './DeepTagsGenerativeAIForm.config';
import { TEXT_LENGTH_DROP_DOWN_LABEL } from './DeepTagsGenerativeForm.config';

const SpinnerWithWrapper = () => (
  <CircularWrapperStyled height={100}>
    <CircularProgress size={CircularProgressSizes.Medium} />
  </CircularWrapperStyled>
);

interface TitleGeneratedFormProps {
  title: string;
  textLength: TitleTextLengthEnum;
  isInGenerateProcess: boolean;
  isInEditMode: boolean;
  setIsInEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  backToMainScreen: () => void;
  onRegenerateButtonClick: () => void;
  onTitleEditKeepEditedState: (value: string) => void;
  onTextLengthChange: (value: string) => void;
  onSaveChanges: () => void;
  onToggleShowRequestPrompt?: () => void;
}

export const TitleGeneratedForm = ({
  title,
  textLength,
  onSaveChanges,
  onTitleEditKeepEditedState,
  backToMainScreen,
  isInGenerateProcess,
  isInEditMode,
  setIsInEditMode,
  onRegenerateButtonClick,
  onTextLengthChange,
  onToggleShowRequestPrompt,
}: TitleGeneratedFormProps): JSX.Element => {
  const titleTempText = React.useRef(''); // for edit mode

  const onEditButtonClick = () => {
    setIsInEditMode(prevState => {
      const isInEditModeNewState = !prevState;

      if (isInEditModeNewState === false) {
        // Edit button can't exit edit mode (only from editable EditableContent - save/cancel)
        return prevState;
      }

      titleTempText.current = title;

      return isInEditModeNewState;
    });
  };

  const onEditChange = useCallback((event: ContentEditableEvent) => {
    titleTempText.current = event.currentTarget.textContent as string;
  }, []);

  const onCancelEdit = () => {
    titleTempText.current = '';
    setIsInEditMode(false);
  };

  const onKeepEditedStateClick = () => {
    onTitleEditKeepEditedState(titleTempText.current.trim());
    titleTempText.current = '';
  };

  return (
    <GenericContainerStyled>
      <MainStyled>
        <EditableTextSectionStyled>
          {isInGenerateProcess ? (
            <SpinnerWithWrapper />
          ) : (
            <EditableContentWrapperStyled isInEditMode={isInEditMode}>
              <EditableContentStyled>
                <ContentEditable
                  html={isInEditMode ? titleTempText.current : title}
                  disabled={!isInEditMode}
                  onChange={onEditChange}
                  onKeyDown={e => {
                    e.stopPropagation();
                  }}
                  tagName='div'
                />
              </EditableContentStyled>
              {isInEditMode && (
                <TabPanelActionsStyled>
                  <Button variant='tertiary' onClick={onCancelEdit}>
                    Cancel
                  </Button>
                  <Button variant='primary' onClick={onKeepEditedStateClick}>
                    Save
                  </Button>
                </TabPanelActionsStyled>
              )}
            </EditableContentWrapperStyled>
          )}
        </EditableTextSectionStyled>
        <ActionsAndParametersStyled>
          <GenerativeAIParameterMenu
            key='textLength'
            label={TEXT_LENGTH_DROP_DOWN_LABEL}
            disabled={isInEditMode}
            options={titleTextLengthMenuOptions}
            selectedValue={textLength}
            onChange={onTextLengthChange}
          />
          <ButtonWithIconWrapper
            key='regenerateButton'
            onClick={onRegenerateButtonClick}
            disabled={isInEditMode}
            variant='secondary'
            size='tiny'
          >
            <Icon name={AvailableIcons.RefreshRegenerate} />
            Regenerate
          </ButtonWithIconWrapper>
          <ButtonEditStyled
            key='editButton'
            onMouseDown={onEditButtonClick}
            variant='secondary'
            size='tiny'
            isInEditMode={isInEditMode}
          >
            <Icon name={AvailableIcons.EditV2} />
            Edit
          </ButtonEditStyled>
          {onToggleShowRequestPrompt && (
            <ButtonEditStyled
              key='prompt'
              onMouseDown={onToggleShowRequestPrompt}
              variant='secondary'
              size='tiny'
              isInEditMode={isInEditMode}
            >
              <Icon name={AvailableIcons.Prompt} />
              Prompt
            </ButtonEditStyled>
          )}
        </ActionsAndParametersStyled>
      </MainStyled>
      {!isInEditMode && (
        <FooterActionsStyled>
          <Button variant='secondary' onClick={backToMainScreen}>
            Cancel
          </Button>
          <Button variant='primary' disabled={!title} onClick={onSaveChanges}>
            Save Changes
          </Button>
        </FooterActionsStyled>
      )}
    </GenericContainerStyled>
  );
};
