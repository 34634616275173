import React from 'react';
import { Button, Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import {
  ModalStyled,
  ModalTitleStyled,
  ModalFooterStyled,
  ModalContentDeleteStyled,
  ModalContentDescriptionStyled,
} from './ShopApiKeysSettingsModal.styled';
import { ShopApiKeysSettingsDeleteModalProps } from './types';

export const ShopApiKeysDeleteSettingsModal = ({
  onModalClose,
  onDeleteKeyClicked,
  apiKeyLabel,
}: ShopApiKeysSettingsDeleteModalProps): JSX.Element => {
  return (
    <ModalStyled isOpened onClickOutside={onModalClose}>
      <>
        <ModalTitleStyled>
          <Typography type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
            Delete API Key?
          </Typography>
        </ModalTitleStyled>
        <ModalContentDeleteStyled>
          <div>
            Are you sure you want to delete{' '}
            <Typography variant={TypographyVariant.MediumBold} type={TypographyType.Body}>
              {apiKeyLabel}
            </Typography>{' '}
            ?
          </div>
          <ModalContentDescriptionStyled>
            Deleting an API key is irreversible and can disrupt linked services.
          </ModalContentDescriptionStyled>
        </ModalContentDeleteStyled>
        <ModalFooterStyled>
          <Button variant='tertiary' onClick={onModalClose}>
            Cancel
          </Button>
          <Button variant='destructive' onClick={onDeleteKeyClicked}>
            Delete key
          </Button>
        </ModalFooterStyled>
      </>
    </ModalStyled>
  );
};
