import { useEffect } from 'react';
import type { Dispatch } from '../../types';
import { uiTemplatesListActionMethods } from './actions';
import { shopGeneralSettingsMethods } from '../../GeneralSettings';

interface Props {
  shopId?: number;
  dispatch: Dispatch;
}

export const fetchData = ({ shopId, dispatch }: Props): void => {
  useEffect(() => {
    if (shopId) {
      dispatch(uiTemplatesListActionMethods.getUiTemplatesList({ shopId }));
      dispatch(shopGeneralSettingsMethods.get({ shopId }));
    }
  }, [shopId]);
};
