import { DomainEntityPath, sortByTranslations } from '../../../../../services';
import { DomainGroup, SubDomainGroupBase } from '../../types';
import { AvailableIcons } from '../../../../../components-dummy';
import { Filter } from '../../../../../services/src/service/types/syte-layout/index';

type EntityType = Filter;

export const filtersSetsFilterDomainSchema: DomainGroup & SubDomainGroupBase<EntityType> = {
  domainKey: DomainEntityPath.FiltersSetsFilter,
  title: 'Results Modal Filters',
  icon: AvailableIcons.AngleRightLight,
  getCardTitle: ({ newEntity, oldEntity }): string => {
    const filter = newEntity ?? oldEntity;

    return `Filters > ${(filter as Filter).sourceField}`;
  },
  fieldsSchema: {
    enabled: {
      displayName: 'Filter Visibility',
      getValue({ entity }: { entity: Filter }): string {
        return entity.enabled ? 'Show' : 'Hide';
      },
    },
    ['valuesSorting.sortBy' as any]: {
      displayName: 'Arrange values by',
      getValue({ entity }: { entity: Filter }): string {
        return (
          (entity.valuesSorting?.sortBy && sortByTranslations[entity.valuesSorting.sortBy]) || ''
        );
      },
    },
    ['valuesSorting.customOrder' as any]: {
      displayName: 'Values Order',
      getValue({ entity }: { entity: Filter }): string {
        return entity.valuesSorting?.customOrder?.join(', ') || '';
      },
    },
  },
};
