import React from 'react';
import {
  TypographyType,
  TypographyVariant,
  CollapsibleEllipsisTextOverflow,
} from '../../../../components-dummy';
import { colors } from '../../../../styles';
import { BooleanDataStyled } from './ProductDetails.styled';

const convertStringValue = (value: any) => {
  if (typeof value !== 'string') return value;

  const lowerVal = value.toLowerCase();
  if (lowerVal === 'true') return true;
  if (lowerVal === 'false') return false;

  try {
    const parsed = JSON.parse(value);
    if (typeof parsed === 'object') return parsed;
  } catch (e) {
    return value;
  }

  return value;
};

export const ValueToDisplayByDataType = ({ value }: { value: any }) => {
  const convertedValue = convertStringValue(value);
  switch (typeof convertedValue) {
    case 'object':
      return <CollapsibleEllipsisTextOverflow value={convertedValue} />;
    case 'boolean':
      const color = convertedValue ? colors['green-success'] : colors.red;
      return (
        <BooleanDataStyled
          type={TypographyType.Body}
          variant={TypographyVariant.MediumRegular}
          color={color}
        >
          {value}
        </BooleanDataStyled>
      );
    default:
      return <CollapsibleEllipsisTextOverflow value={convertedValue} />;
  }
};
