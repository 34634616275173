import React from 'react';
import { Row } from 'react-table';
import { EllipsisWithTooltip, TableV2, TableV2InstanceProps } from 'src/components-dummy';
import { tableColumnsClient } from './CatalogUploadErrorReportList.config';
import { ICatalogUploadErrorReportBodyRow } from './types';
import { TableBodyRowCellStyled } from './CatalogUploadErrorReportTableRow.styles';

interface CatalogUploadErrorReportTableRowProps {
  row: Row<ICatalogUploadErrorReportBodyRow>;
  isResizingColumn: boolean;
}

export const CatalogUploadErrorReportTableRow = ({
  row,
  isResizingColumn,
}: CatalogUploadErrorReportTableRowProps): JSX.Element => {
  const renderCells = (
    propName: keyof ICatalogUploadErrorReportBodyRow,
    cell: TableV2InstanceProps<ICatalogUploadErrorReportBodyRow>['rows'][0]['cells'][0]
  ) => {
    if (propName === tableColumnsClient.imageUrl.accessor) {
      return (
        <a href={cell.value} target='_blank' rel='noreferrer'>
          <EllipsisWithTooltip key={`${isResizingColumn}`} tooltipText={cell.value}>
            {cell.value}
          </EllipsisWithTooltip>
        </a>
      );
    }

    return (
      <EllipsisWithTooltip key={`${isResizingColumn}`} tooltipText={cell.value}>
        {cell.value}
      </EllipsisWithTooltip>
    );
  };

  return (
    <>
      <TableV2.BodyRow {...row.getRowProps()} css={{ maxHeight: '40px' }}>
        {row.cells.map(cell => {
          const propName = cell.column.id;

          return (
            <TableBodyRowCellStyled {...cell.getCellProps()} key={propName} isResizable>
              {renderCells(propName as keyof ICatalogUploadErrorReportBodyRow, cell)}
            </TableBodyRowCellStyled>
          );
        })}
      </TableV2.BodyRow>
    </>
  );
};
