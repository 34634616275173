import { Filters } from 'src/services';
import { FilterSetConfiguration, Types, UpdateFilterSetSegmentPayload } from 'src/components-bl';

const filtersService = new Filters();

type ErrorPayload = {
  error: unknown;
};

export enum UpdateFilterSetActionTypes {
  UpdateRequest = 'FiltersSets/UpdateRequest',
  UpdateSuccess = 'FiltersSets/UpdateSuccess',
  UpdateError = 'FiltersSets/UpdateError',
}

interface UpdateRequestAction {
  type: typeof UpdateFilterSetActionTypes.UpdateRequest;
  payload: UpdateFilterSetSegmentPayload;
}

interface UpdateSuccessAction {
  type: typeof UpdateFilterSetActionTypes.UpdateSuccess;
  payload: FilterSetConfiguration[];
}

interface UpdateErrorAction {
  type: typeof UpdateFilterSetActionTypes.UpdateError;
  payload: ErrorPayload;
}

export type UpdateFilterSetAction = UpdateRequestAction | UpdateSuccessAction | UpdateErrorAction;

const updateFilterSetActions = {
  updateRequest: (payload: UpdateFilterSetSegmentPayload): UpdateFilterSetAction => {
    return {
      type: UpdateFilterSetActionTypes.UpdateRequest,
      payload,
    };
  },
  updateSuccess: (payload: FilterSetConfiguration[]): UpdateFilterSetAction => {
    return {
      type: UpdateFilterSetActionTypes.UpdateSuccess,
      payload,
    };
  },
  updateError: (payload: ErrorPayload): UpdateFilterSetAction => {
    return {
      type: UpdateFilterSetActionTypes.UpdateError,
      payload,
    };
  },
};

export const updateFilterSet =
  ({ shopId, data }: UpdateFilterSetSegmentPayload) =>
  async (dispatch: Types.Dispatch): Promise<void> => {
    if (!shopId) {
      dispatch(updateFilterSetActions.updateError({ error: 'No shopId Provided' }));
      return;
    }
    try {
      const updateData: FilterSetConfiguration[] = await filtersService.updateFilterSetSegment(
        shopId,
        data
      );
      dispatch(updateFilterSetActions.updateSuccess(updateData));
    } catch (error) {
      // TODO >> handle server errors (cross tab duplications)
      dispatch(updateFilterSetActions.updateError({ error }));
    }
  };
