import { useCallback, useEffect, useRef } from 'react';

import { CrossBrowserApi } from '../../../services/src/service/cross-browser-api';

import type { Dispatch } from '../../types';

import { ApiName, createUiEditorApi } from './uiEditorApi';
import { useAppSelector } from '../../../hooks';

interface Params {
  dispatch: Dispatch;
  shopId: number;
  targetTemplate?: string;
}

interface ReturnType {
  openEditor: () => void;
}

export function useWysiwyg({ dispatch, shopId, targetTemplate }: Params): ReturnType {
  const editor = useRef<Window | null>(null);
  const browserApi = useRef<CrossBrowserApi | null>(null);

  const shopUrlFromSettings = useAppSelector(state => state.shop.current?.shopUrl);
  const shopUrlFromPopup = useAppSelector(state => state.uiTemplates.shopUrl);
  const shopUrl = shopUrlFromSettings || shopUrlFromPopup;

  const close = useCallback(() => {
    browserApi.current?.stop();
    editor.current?.close();

    editor.current = null;
  }, []);

  useEffect(() => {
    close();
    const api = createUiEditorApi({
      dispatch,
      shopId,
      targetTemplate,
    });
    browserApi.current = new CrossBrowserApi(ApiName, api);
  }, [close, dispatch, shopId, targetTemplate]);

  const openEditor = useCallback(() => {
    close();
    if (!browserApi.current || !shopUrl) {
      return;
    }
    editor.current = window.open(shopUrl);
    browserApi.current.start();
  }, [shopUrl]);

  return {
    openEditor,
  };
}
