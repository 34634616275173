import styled from '@emotion/styled';

export const FormActionButtonsStyled = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  button {
    margin-left: 8px;
  }
`;
