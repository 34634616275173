import { AxiosResponse } from 'axios';
import { RankingStrategyTypes } from '../types';

export const syteCoreValuesSet = new Set(Object.values(RankingStrategyTypes.SyteCoreRankingFields));
const coreDisplayNameValuesMap: Record<RankingStrategyTypes.SyteCoreRankingFields, string> = {
  [RankingStrategyTypes.SyteCoreRankingFields.BestMatch]: 'Similarity',
  [RankingStrategyTypes.SyteCoreRankingFields.BestSellers]: 'Best sellers',
  [RankingStrategyTypes.SyteCoreRankingFields.Personalization]: 'Behavioral match',
  [RankingStrategyTypes.SyteCoreRankingFields.TrendingItems]: 'Trending items',
};

function processCoreWeights(
  weights: RankingStrategyTypes.RankingStrategyWeight[]
): RankingStrategyTypes.RankingStrategyWeight[] {
  return weights.map(weight => {
    const dataField = weight.field as RankingStrategyTypes.SyteCoreRankingFields;
    if (syteCoreValuesSet.has(dataField)) {
      return { ...weight, name: coreDisplayNameValuesMap[dataField] };
    }
    return weight;
  });
}

export const rankingResponse = ({ data }: AxiosResponse): RankingStrategyTypes.RankingStrategy => {
  const convertedWeights = processCoreWeights(data?.weights || []);
  return { weights: convertedWeights, type: data.type };
};
