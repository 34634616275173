import { AxiosResponse } from 'axios';
import { RelevancyTuningItem } from '../types';

export class AugmentedSearchMapper {
  static mapRelevancyTuning(
    response: AxiosResponse<{ tunings: RelevancyTuningItem[] }>
  ): RelevancyTuningItem[] {
    return response.data?.tunings || [];
  }
}
