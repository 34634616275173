import styled from '@emotion/styled';
import { DialogModal } from 'src/components-dummy';

export const ThematicTagModalStyled = styled(DialogModal)`
  .MuiPaper-root {
    width: 60vw;
    min-width: 800px;
    max-width: 90vw;
    height: 90%;
  }
`;
