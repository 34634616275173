import { useMemo, useState, useEffect } from 'react';
import { Keys } from '../../types';
import {
  Tag,
  CreateTagsArguments,
  CreateTagArguments,
  UseTagsInput,
  UseTagsInputReturnType,
} from './types';

const createTag = ({ value, index, caseSensitive }: CreateTagArguments): Tag => {
  const trimmedInput = value.trim();
  const id = caseSensitive ? trimmedInput.toLowerCase() : trimmedInput;
  return { id, value: trimmedInput, index };
};

const createTags = ({ values, allowDuplicates, caseSensitive }: CreateTagsArguments): Tag[] => {
  const tags = values.map((value, index) => createTag({ value, index, caseSensitive }));
  return allowDuplicates ? tags : [...new Set(tags)];
};

const defaultInputState = '';

export const useTagsInput = ({
  values,
  allowDuplicates,
  caseSensitive,
  delimiters,
  onChange,
  disabled,
  maxTagsToDisplay,
}: UseTagsInput): UseTagsInputReturnType => {
  const initialState = useMemo(
    () => createTags({ values, allowDuplicates, caseSensitive }),
    [values]
  );

  const [tags, setTags] = useState(initialState);
  const [inputValue, setInputValue] = useState(defaultInputState);

  const tagsToDisplay = useMemo(() => {
    if (maxTagsToDisplay) {
      const tagsClone = [...tags];
      tagsClone.splice(maxTagsToDisplay);
      return tagsClone;
    }
    return tags;
  }, [tags, maxTagsToDisplay]);

  useEffect(() => {
    setTags(createTags({ values, allowDuplicates, caseSensitive }));
  }, [values]);

  const tagsSet = useMemo(() => new Set(tags.map(tag => tag.id)), [tags]);

  const delimitersSet = new Set(delimiters);

  const clearTagInput = (): void => setInputValue(defaultInputState);

  const processInput = (
    str: string
  ): {
    trimmedValue: string;
    isEmpty: boolean;
    id: string;
  } => {
    const trimmedInput = str.trim();
    const id = caseSensitive ? trimmedInput.toLowerCase() : trimmedInput;
    const isEmpty = !(str && trimmedInput.length);
    return {
      trimmedValue: trimmedInput,
      id,
      isEmpty,
    };
  };

  const addTag = (tagToAdd: Omit<Tag, 'index'>): void => {
    const shouldAddTag = allowDuplicates || !tagsSet.has(tagToAdd.id);

    if (shouldAddTag) {
      const newTag = { ...tagToAdd, index: tags.length };
      const updatedState = [...tags, newTag];
      setTags(updatedState);
      onChange?.(updatedState.map(tag => tag.value));
      clearTagInput();
    }
  };

  const deleteTag = (index: number): void => {
    if (disabled) {
      return;
    }
    const updatedState = tags.filter(tag => tag.index !== index);
    setTags(updatedState);
    onChange?.(updatedState.map(tag => tag.value));
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setInputValue(value);
  };

  const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const key = e.key as Keys;

    if (key === Keys.Enter) {
      e.preventDefault();
    }

    const { trimmedValue, id, isEmpty } = processInput(inputValue);

    const shouldDeleteLastTag = key === Keys.Backspace && isEmpty;

    if (shouldDeleteLastTag) {
      deleteTag(tags.length - 1);
      return;
    }

    if (!delimitersSet.has(key)) {
      return;
    }

    if (!isEmpty) {
      addTag({ id, value: trimmedValue });
    }
  };

  const onInputBlur = (_: React.FocusEvent<HTMLInputElement>): void => {
    const { trimmedValue, id, isEmpty } = processInput(inputValue);

    if (!isEmpty) {
      addTag({ id, value: trimmedValue });
    }
  };

  return {
    onInputChange,
    onInputKeyDown,
    onInputBlur,
    tags: tagsToDisplay,
    deleteTag,
    inputValue,
  };
};
