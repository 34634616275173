import { Vertical } from '../constants';
import { PaginationResult } from '../common';

export enum DeepTagReportStatus {
  EnrichmentInProgress = 'enrichment_in_progress',
  New = 'new',
  InReview = 'in_review',
  Done = 'done',
  Error = 'error',
}

export enum DeepTagScoreType {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
}

export enum DeepTagReportError {
  NonSupportedFileExtension = 'file_extension_not_supported',
  EnrichmentProductsProcessError = 'enrichment_products_process_error',
}

export enum DeepTagProductStatusType {
  New = 'new',
  InReview = 'in_review',
  Done = 'done',
  DoneWithIssues = 'done_with_issues',
}

export interface DeepTagReport {
  id: string;
  customName?: string;
  shopId: number;
  status: DeepTagReportStatus;
  locale: string;
  reviewer?: string;
  approver?: string;
  sourceFileName: string;
  totalProducts: number;
  createdAt: Date;
  updatedAt: Date;
  error?: {
    code: DeepTagReportError;
    message?: string;
  };
}

export interface DeepTagReportAPI extends Omit<DeepTagReport, 'createdAt' | 'updatedAt'> {
  createdAt: string;
  updatedAt: string;
}

export enum DeepTagReportDelimiterType {
  Comma = 'comma',
  Tab = 'tab',
  Semicolon = 'semicolon',
  Pipe = 'pipe',
}

export enum DeepTagStatusEnum {
  New = 'new',
  Edited = 'edited',
  Original = 'original',
  Deleted = 'deleted',
}

export enum DeepTagSourceEnum {
  Thematic = 'thematic',
  Lexicon = 'lexicon',
  Generated = 'generated',
}

export interface DeepTagReportWithMetaData extends DeepTagReport {
  verticals: Vertical[];
  locale: string;
}

export interface ExportDeepTagReportFileOptions {
  delimiterType: DeepTagReportDelimiterType;
  includeProductsWithEmptyTags: boolean;
  customExportLocale?: string;
}

export interface DeepTagLexiconItem {
  key: string;
  translation: string;
}
export interface OriginalDataTagAPI {
  attribute: DeepTagLexiconItem;
  value: DeepTagLexiconItem;
  source: DeepTagSourceEnum;
  score?: number;
  scoreType?: DeepTagScoreType;
}

export type ProductOriginalDataTag = OriginalDataTagAPI;

export interface DeepTagAPI extends OriginalDataTagAPI {
  status: DeepTagStatusEnum;
}
export type DeepTag = DeepTagAPI;

export interface DeepTagReportProductDataCategory {
  name: string | undefined;
  translation: string | undefined;
  isEdited: boolean;
}

export interface DeepTagReportProductDataParentCategory {
  name: string | undefined;
  translation: string | undefined;
  isEdited: boolean;
}

export interface ProductOriginalData {
  tags: {
    [attributeName: string]: ProductOriginalDataTag;
  };
}

export interface ProductDataOriginalData extends ProductOriginalData {
  category?: string;
  categoryTranslation?: string;
}

export interface IDeepTagReportProductCommentAPI {
  id: string;
  text: string;
  userEmail: string;
  userId: number;
  createdAt: string;
  updatedAt: string;
}

export interface IDeepTagReportProductComment {
  id: string;
  text: string;
  userEmail: string;
  userFullName?: string;
  userId: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface IDeepTagsProductDataItem {
  id: string;
  category: DeepTagReportProductDataCategory;
  parentCategory: DeepTagReportProductDataParentCategory;
  tags: DeepTag[];
  originalData: ProductDataOriginalData;
}

export interface IDeepTagReportProduct {
  id: string;
  sku: string;
  title: string;
  status: DeepTagProductStatusType;
  labels: string[];
  comments: IDeepTagReportProductComment[];
  imageUrls: string[];
  verticals: Vertical[];
  tags: DeepTag[];
  data: IDeepTagsProductDataItem[];
  customAttributes?: Record<string, string>;
  originalData: ProductOriginalData;
  lastUpdatedBy: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IDeepTagReportProductPatchAPI {
  status?: DeepTagProductStatusType;
  label?: string;
  comments?: IDeepTagReportProductComment[];
  tags?: DeepTag[];
  data?: IDeepTagsProductDataItemPatchAPI[];
  title?: string;
}

export interface IFilterKeyObject {
  name: string;
  count: number;
}

export interface AvailableFilterKeysMap {
  categories: Record<string, IFilterKeyObject>;
  attributes: Record<string, IFilterKeyObject>;
  values: Record<string, IFilterKeyObject>;
  attributeValuePairs: Record<string, IFilterKeyObject>;
  statuses: Record<string, IFilterKeyObject>;
  labels: Record<string, IFilterKeyObject>;
  verticals: Record<string, IFilterKeyObject>;
  editedProductsTags: { totalProductsWithEditedTags?: IFilterKeyObject };
}

type DeepTagReportProductDataCategoryAPI = DeepTagReportProductDataCategory;
type DeepTagReportProductDataParentCategoryAPI = DeepTagReportProductDataParentCategory;

// Sub products object
export interface IDeepTagsProductDataItemAPI {
  id: string;
  category: DeepTagReportProductDataCategoryAPI;
  parentCategory: DeepTagReportProductDataParentCategoryAPI;
  tags: DeepTag[];
  originalData: {
    category: string;
    categoryTranslation?: string;
    tags: DeepTag[];
  };
}

export interface IDeepTagsProductDataItemPatchAPI {
  id: string;
  category: Omit<DeepTagReportProductDataCategoryAPI, 'isEdited'>;
  tags: DeepTag[];
}

export interface DeepTagReportProductAPI {
  id: string;
  sku: string;
  title: string;
  status: DeepTagProductStatusType;
  labels: string[];
  imageUrls: string[];
  verticals: Vertical[];
  tags: DeepTagAPI[];
  customAttributes?: Record<string, string>;
  lastUpdatedBy: string;
  data: IDeepTagsProductDataItemAPI[];
  originalData: {
    tags: OriginalDataTagAPI[];
  };
  comments: IDeepTagReportProductCommentAPI[];
  createdAt: string;
  updatedAt: string;
}

export type DeepTagReportProductPagination = PaginationResult<{
  products: IDeepTagReportProduct[];
  availableFilterKeysMap: AvailableFilterKeysMap;
}>;

export type DeepTagReportProductPaginationAPI = PaginationResult<{
  products: DeepTagReportProductAPI[];
}>;

export interface DeepTagReportProductFilters {
  search?: string;
  editedOnly?: boolean;
  emptyTagsOnly?: boolean;
  tagScoreTypes?: DeepTagScoreType[];
  attributes?: string[];
  missingAttributes?: string[];
  attributeValuePairs?: string[];
  missingAttributeValuePairs?: string[];
  categories?: string[];
  statuses?: string[];
  labels?: string[];
  productStatusTypes?: string[];
  values?: string[];
  verticals?: Vertical[];
}

/**
 * General Configuration
 */

export interface DeepTagReportsGeneralConfigurationLabel {
  id: string;
  name: string;
}

export enum DeepTagsReportsRecognitionType {
  ImageToDeepTags = 'image2dt',
  // DominantColors = 'dominant_colors', // TODO: uncomment when VMR/Generator supports it
}

export interface DeepTagsReportsConfiguration {
  shopId: number;
  locale: string;
  recognitionType: DeepTagsReportsRecognitionType;
}

export interface DeepTagsReportsEvenBulkAssignLabels {
  id: string;
}

export interface DeepTagsReportsCustomBulkAssignLabels {
  labelId: string;
  totalProducts: number;
}
export interface DeepTagsReportsGeneratedText {
  id?: number;
  createdAt: string;
  text: string;
}

export enum DeepTagsReportsGenerativeAIFieldType {
  Description = 'description',
  Title = 'title',
}

export interface DeepTagsGenerativeAISubProduct {
  category?: string;
  tags: Record<string, string>;
}

export type ToneOfVoiceTypes =
  | 'formal_tone'
  | 'informal_tone'
  | 'factual_tone'
  | 'directive_tone'
  | 'assertion_tone'
  | 'friendly_tone';

/**
 * Text length
 */
export enum DescriptionTextLengthEnum {
  TwoSentences = 'two_sentences',
  OneParagraph = 'one_paragraph',
  TwoParagraphs = 'two_paragraphs',
  ThreeParagraphs = 'three_paragraphs',
}

export enum TitleTextLengthEnum {
  UpTo5Words = 'up_to_5_words',
  UpTo10Words = 'up_to_10_words',
  UpTo15Words = 'up_to_15_words',
}

export enum DeepTagReportsValidationErrorKey {
  DeepTagReportsBulkSomeGeneralLabelsNotExisted = 'DEEP_TAG_REPORTS_BULK_SOME_GENERAL_LABELS_NOT_EXISTED',
  DeepTagReportsCustomBulkNumberMismatch = 'DEEP_TAG_REPORTS_CUSTOM_BULK_PRODUCTS_NUMBER_MISMATCH',
  DeepTagReportsBulkNoUnlabeledProductsToAssign = 'DEEP_TAG_REPORTS_BULK_NO_UNLABLED_PRODUCTS_TO_ASSIGN',
}
