import { sumBy } from 'lodash';
import { ValuePathByGroupName, DiffActionType } from 'src/app-types/enums/versioning';
import {
  EntityChangesDetails,
  DiffAPIResponseDiffChange,
  GroupedDiffChanges,
  ChangesSummaryDetailsBase,
} from '../../service/types';
import { systemUser } from '../../service/users';

/**
 * Map changes and group them by valuePaths pairs (GroupedValuePathsEnum)
 */
const mapChanges = (changes: DiffAPIResponseDiffChange[]): GroupedDiffChanges[] => {
  const mapChange = (change: DiffAPIResponseDiffChange) => {
    const isSystemChange = change.changedBy?.userId === systemUser.userId;
    return {
      ...change,
      changedAt: new Date(change.changedAt),
      isSystemChange,
    };
  };

  const groupedChanges = changes.reduce(
    (prev: { [groupKey: string]: GroupedDiffChanges }, next: DiffAPIResponseDiffChange) => {
      const groupedValuePathName =
        ValuePathByGroupName[next.valuePath as keyof typeof ValuePathByGroupName];

      const groupKey = groupedValuePathName || next.valuePath;

      if (!prev[groupKey]) {
        // eslint-disable-next-line no-param-reassign
        prev[groupKey] = { groupName: groupKey as string, changes: [] };
      }
      const mappedChange = mapChange(next);
      prev[groupKey].changes.push(mappedChange);

      return prev;
    },
    {}
  );

  return Object.values(groupedChanges);
};

function mapDiffChange(changedEntity: any): EntityChangesDetails {
  const DEFAULT_CHANGES_COUNT = 1;

  const shouldMapChanges = changedEntity.actionType === DiffActionType.Changed;

  const mappedChanges = shouldMapChanges ? mapChanges(changedEntity.changes) : [];

  const totalChangesCount = mappedChanges.length || DEFAULT_CHANGES_COUNT;

  return {
    ...changedEntity,
    domainGroupKey: changedEntity.parentDomain || changedEntity.domain,
    changedAt: new Date(changedEntity.changedAt),
    totalChangesCount,
    changes: mappedChanges,
  };
}

const mapChangesSummaryFromResponse = (
  changesSummary: any[] | undefined
): ChangesSummaryDetailsBase => {
  const mappedChangedEntities =
    changesSummary?.map((changedEntity: any) => mapDiffChange(changedEntity)) || [];

  const totalChanges = sumBy(
    mappedChangedEntities,
    changedEntity => changedEntity.totalChangesCount
  );

  const mappedChangesSummary = {
    changedEntities: mappedChangedEntities,
    totalChanges,
  };

  return mappedChangesSummary;
};

export const ChangesMapper = {
  mapChangesSummaryFromResponse,
  mapDiffChange,
};
