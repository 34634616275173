import React from 'react';
import styled from '@emotion/styled';
import { StyledContainer } from './StyledContainer';

interface Props {
  column?: boolean;
  separator?: boolean;
  gap?: number;
  children: React.ReactNode;
}

export const FormItem = styled(StyledContainer)<Props>`
  display: flex;
  ${({ column, separator, gap, paddingY }) => `
    flex-direction: ${column ? 'column' : 'row'};
    ${column ? 'justify-content: center;' : 'align-items: center;'}
    gap: ${gap || 15}px;
    ${
      separator &&
      `border-top: 1px dashed #dedede;
       padding-top: ${paddingY || 15}px;`
    }
  `}
`;
