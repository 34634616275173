import {
  CollectionsListActionTypes,
  CollectionsListSortCollectionsSuccessAction,
  SortCollectionsSuccessActionPayload,
} from './types';

export const sortCollectionsMethods = {
  sortCollectionsSuccess(
    payload: SortCollectionsSuccessActionPayload
  ): CollectionsListSortCollectionsSuccessAction {
    return { type: CollectionsListActionTypes.SortCollectionsSuccess, payload };
  },
};
