import React, { useEffect, useRef, useState } from 'react';
import {
  MenuPosition,
  OPTION_HEIGHT,
  MAX_OPTIONS_TO_SHOW,
  MENU_TOP_BOTTOM_PADDINGS,
} from './Select.config';

export interface SelectInputDimensions {
  height: number;
  width: number;
}

interface UseSelectReturnObject {
  onOpen: (event: React.SyntheticEvent) => void;
  selectRef: React.MutableRefObject<HTMLDivElement | undefined>;
  inputRef: React.MutableRefObject<HTMLDivElement | undefined>;
  menuPosition: MenuPosition;
  selectInputDimensions: SelectInputDimensions;
  menuRefTopPosition: number;
  hasOptions: boolean;
  onClose: (event: React.SyntheticEvent) => void;
  isListOpen: boolean;
}

interface UseSelectProps {
  totalOptions: number;
  autoFocus?: boolean;
  value?: string | number | readonly string[] | undefined;
  onCloseExternal?: (event: React.SyntheticEvent<Element, Event>) => void;
}

export const UseSelect = ({
  totalOptions,
  autoFocus,
  value,
  onCloseExternal,
}: UseSelectProps): UseSelectReturnObject => {
  const selectRef = useRef<HTMLDivElement | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement | undefined>(undefined);
  const [isListOpen, setIsListOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState(MenuPosition.Bottom);
  const [selectInputDimensions, setSelectInputDimensions] = useState<SelectInputDimensions>({
    height: 0,
    width: 0,
  });

  const [menuRefTopPosition, setMenuRefTopPosition] = useState<number>(0);

  const hasOptions = totalOptions > 0;

  const onClose = (event: React.SyntheticEvent<Element, Event>) => {
    setIsListOpen(false);
    onCloseExternal?.(event);
  };

  const onOpen = (event: React.SyntheticEvent) => {
    const selectInputHeight = event.currentTarget.clientHeight;
    const selectInputWidth = event.currentTarget.clientHeight;

    const documentHeight = document.documentElement.clientHeight;

    const totalOptionsToShow =
      totalOptions > MAX_OPTIONS_TO_SHOW ? MAX_OPTIONS_TO_SHOW : totalOptions;

    const menuHeight = totalOptionsToShow * OPTION_HEIGHT + MENU_TOP_BOTTOM_PADDINGS;

    const selectRefTopPosition = selectRef.current?.getBoundingClientRect().top || 0;
    const selectFullHeight = selectRefTopPosition + menuHeight + selectInputHeight;

    const spaceBellowMenu = documentHeight - selectFullHeight;

    setMenuPosition(spaceBellowMenu < OPTION_HEIGHT ? MenuPosition.Top : MenuPosition.Bottom);
    setMenuRefTopPosition(menuHeight * -1);
    setSelectInputDimensions({ width: selectInputWidth, height: selectInputHeight });

    setIsListOpen(true);
  };

  useEffect(() => {
    if (autoFocus && value === undefined) {
      inputRef.current?.focus();
    }
  }, [autoFocus, value]);

  return {
    onOpen,
    menuPosition,
    selectInputDimensions,
    menuRefTopPosition,
    selectRef,
    inputRef,
    hasOptions,
    onClose,
    isListOpen,
  };
};
