import React from 'react';
import { useDispatch, shallowEqual } from 'react-redux';
import { useAppSelector } from 'src/hooks';
import { RankingStrategy } from 'src/components-bl';

interface ConnectedRankingStrategyArgs {
  formHeaderElementRef: React.RefObject<HTMLElement | null>;
  prefixId: string;
}

export const ConnectedRankingStrategy = ({
  formHeaderElementRef,
  prefixId,
}: ConnectedRankingStrategyArgs): JSX.Element | null => {
  const {
    rankingStrategy: { weights, type, selectedSyteProduct },
    shopId,
    dataFields,
    featureToggles,
  } = useAppSelector(state => {
    return {
      rankingStrategy: state.rankingStrategy,
      shopId: state.shop.current?.shopId,
      dataFields: state.dataFields.dataFields,
      featureToggles: state.shop.featureToggles,
    };
  }, shallowEqual);

  const dispatch = useDispatch();

  return shopId ? (
    <RankingStrategy
      dispatch={dispatch}
      weights={weights[selectedSyteProduct]}
      type={type}
      selectedSyteProduct={selectedSyteProduct}
      shopId={shopId}
      formHeaderElementRef={formHeaderElementRef}
      dataFields={dataFields}
      prefixId={prefixId}
      hasRankingType
      featureToggles={featureToggles}
    />
  ) : null;
};
