import React, { useCallback, useMemo, useState } from 'react';
import { FilterDataSource, ShopDataField, FeedDataFieldType, FilterType } from 'src/services';
import { ParseJoiValidateResponse } from 'src/utils';
import { Dispatch } from 'src/components-bl/types';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { defaultValuesSorting } from '../../constants';
import { FilterDraft } from '../../types';
import { SectionBoxStyled, SubTitleTypography } from '../shared.styles';
import { SelectionsWrapper } from './DataSourceSection.styles';
import { SourceRadioSelection } from './SourceRadioSelection';
import { DataFieldSelect } from './DataFieldSelect';
import { SourceFieldChangeConfirmationDialog } from './SourceFieldChangeConfirmationDialog';

type DataSourceSectionData = Pick<
  FilterDraft,
  'dataSource' | 'sourceField' | 'valuesSorting' | 'type'
>;

interface DataSourceSectionProps {
  onChange: (updatedData: Partial<DataSourceSectionData>) => void;
  data: DataSourceSectionData;
  availableDataFields: ShopDataField[] | undefined;
  errors: ParseJoiValidateResponse<Omit<FilterDraft, 'id'>>;
  dispatch: Dispatch;
  disabled: boolean;
}

export const DataSourceSection = ({
  onChange,
  data,
  availableDataFields,
  errors,
  dispatch,
  disabled,
}: DataSourceSectionProps): JSX.Element => {
  const dataFieldsWithStringTypes = useMemo(() => {
    return availableDataFields
      ?.filter(
        (dataField: ShopDataField) =>
          dataField.types.includes(FeedDataFieldType.Keyword) ||
          dataField.types.includes(FeedDataFieldType.Text)
      )
      .map((dataField: ShopDataField) => dataField.name);
  }, [availableDataFields]);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [newSourceField, setSourceField] = useState('');

  const onDataSourceChange = (updatedDataSource: FilterDataSource) => {
    onChange({ dataSource: updatedDataSource });
  };

  const onSourceFieldChange = useCallback(
    (newDataField: string) => {
      const isSourceFieldStringType = dataFieldsWithStringTypes?.includes(newDataField);
      const valuesSorting = isSourceFieldStringType ? defaultValuesSorting : undefined;
      const type = isSourceFieldStringType ? FilterType.Text : FilterType.Range;

      onChange({ sourceField: newDataField, valuesSorting, type });
    },
    [onChange, dataFieldsWithStringTypes]
  );

  const showConfirmDialogOnSourceFieldChange = useCallback(
    (updatedSourceField: string) => {
      const prevSourceFieldIsEmpty = data.sourceField === undefined;

      if (prevSourceFieldIsEmpty) {
        onSourceFieldChange(updatedSourceField);
      } else {
        setShowConfirmDialog(true);
        setSourceField(updatedSourceField);
      }
    },
    [setShowConfirmDialog, setSourceField, onSourceFieldChange, data.sourceField]
  );

  const onConfirmDialogCancel = useCallback(() => {
    setShowConfirmDialog(false);
    setSourceField('');
  }, [setShowConfirmDialog, setSourceField]);

  const onConfirmDialog = useCallback(() => {
    onConfirmDialogCancel();
    onSourceFieldChange(newSourceField);
  }, [onConfirmDialogCancel, newSourceField, onSourceFieldChange]);

  const isCatalogSource = data.dataSource === FilterDataSource.Catalog;

  return (
    <>
      <SectionBoxStyled>
        <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
          Data source
        </Typography>
        <SubTitleTypography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
          Define the data source for your filter
        </SubTitleTypography>
        <SelectionsWrapper>
          <SourceRadioSelection
            dataSource={FilterDataSource.Catalog}
            checked={isCatalogSource}
            onChange={() => onDataSourceChange(FilterDataSource.Catalog)}
            disabled={disabled}
            dispatch={dispatch}
          />
          <SourceRadioSelection
            dataSource={FilterDataSource.DeepTags}
            checked={data.dataSource === FilterDataSource.DeepTags}
            onChange={() => onDataSourceChange(FilterDataSource.DeepTags)}
            dispatch={dispatch}
            disabled
            enableNotificationBell
          />
        </SelectionsWrapper>
        {isCatalogSource && (
          <DataFieldSelect
            availableDataFields={availableDataFields}
            error={errors?.sourceField?.message}
            onChange={showConfirmDialogOnSourceFieldChange}
            sourceField={data.sourceField}
            disabled={disabled}
          />
        )}
      </SectionBoxStyled>

      {showConfirmDialog ? (
        <SourceFieldChangeConfirmationDialog
          onCancel={onConfirmDialogCancel}
          onConfirm={onConfirmDialog}
        />
      ) : null}
    </>
  );
};
