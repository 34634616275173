import { AvailableIcons } from 'src/components-dummy';
import { DomainEntityPath } from 'src/services';
import { ParentDomainBase } from '../../types';
import { boughtTogetherSubDomainGroup } from './bought-together-settings-sub-domain';

const baseTitle = 'Bought Together';

export const boughtTogetherDomainGroup: ParentDomainBase = {
  domainKey: DomainEntityPath.BoughtTogether,
  title: baseTitle,
  icon: AvailableIcons.HollowStar,
  subDomains: [
    { ...boughtTogetherSubDomainGroup, domainKey: DomainEntityPath.BoughtTogetherSettings },
  ],
};
