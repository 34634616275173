import styled from '@emotion/styled';
import { BadgeBackground } from './Badge.types';

export const BadgeStyled = styled.div<{ background: BadgeBackground }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: 5px;
  padding: 0px 8px 1px 8px;
  text-transform: none;

  ${({ background, theme }) => {
    switch (background) {
      case 'alert':
        return `background: ${theme.palette.custom.red};`;
      case 'secondary':
        return `background: ${theme.palette.custom['gray-20']};`;
      case 'disabled':
        return `background: ${theme.palette.custom['gray-20']};
            &-content {
              color: ${theme.palette.custom['gray-20']};
            }`;
      default:
        return `background-color: ${theme.palette.custom['primary-main']};`;
    }
  }}

  &-icon {
    margin-right: 6px;
    max-height: 10px;
  }

  &-icon + &-content {
    padding-right: 10px;
  }
`;
