import { RequestUpdateOneLexiconBody } from 'src/services/src/service/lexicon/types';
import {
  ChangesFilterValues,
  ILexiconItem,
  ILexiconItemAPI,
  ILexiconItemBase,
  ILexiconTagsFilters,
  ILexiconTagsQueryAPI,
} from 'src/services/src/service/types/lexicon';
import { CHANGES_FILTER_ALL_VALUES } from './LexiconTable.config';
import { LexiconTableQueryState } from './types';

export const hasItemBaseRenamed = (itemBase: ILexiconItemBase): boolean => {
  return itemBase.translation !== undefined && itemBase.translation !== itemBase.defaultTranslation;
};

export const mapRowLexiconItemBase = (
  itemBase: ILexiconItemBase
): { text: string; hasChanged: boolean } => {
  return {
    text: itemBase.translation,
    hasChanged: hasItemBaseRenamed(itemBase),
  };
};

export const mapTableRowChangesText = ({
  hasRenamed,
  include,
}: {
  hasRenamed: boolean;
  include: boolean;
}): string => {
  if (include === false) return 'Turned off';
  if (hasRenamed) return 'Renamed';
  return '';
};

/** Skip changes filter if all values are selected, or unselected */
export function isChangesFilterActive(changesFilter: ChangesFilterValues[]): boolean {
  const isActive =
    changesFilter &&
    changesFilter.length > 0 &&
    changesFilter.length < CHANGES_FILTER_ALL_VALUES.length;
  return isActive;
}

export function mapTableQueryToApiQueryOptions({
  headerTagFieldFilters,
  menuFilters,
  pagination,
  sortOptions,
}: LexiconTableQueryState): Required<ILexiconTagsQueryAPI> {
  const categoryApiFilter = headerTagFieldFilters.categoriesTranslations;
  const attributesApiFilter = headerTagFieldFilters.attributesTranslations;
  const valuesApiFilter = headerTagFieldFilters.valuesTranslations;
  const masterTagApiFilter = headerTagFieldFilters.masterTag;
  const verticalsApiFilter = headerTagFieldFilters.verticals;
  const changesFilter = menuFilters.changes;

  const apiFilters: Partial<ILexiconTagsFilters> = {};

  if (categoryApiFilter.length) {
    apiFilters.categoriesTranslations = categoryApiFilter;
  }

  if (attributesApiFilter.length) {
    apiFilters.attributesTranslations = attributesApiFilter;
  }

  if (valuesApiFilter.length) {
    apiFilters.valuesTranslations = valuesApiFilter;
  }

  if (masterTagApiFilter.length) {
    apiFilters.masterTag = masterTagApiFilter;
  }

  if (verticalsApiFilter.length) {
    apiFilters.verticals = verticalsApiFilter;
  }

  if (isChangesFilterActive(changesFilter)) {
    apiFilters.changes = changesFilter;
  }

  const apiQueryOptions: Required<ILexiconTagsQueryAPI> = {
    filters: apiFilters,
    pagination: {
      limit: pagination.limit,
      skip: pagination.skip,
    },
    sortOptions: {
      sortAscending: sortOptions.sortAscending,
      sortBy: sortOptions.columnName,
    },
  };

  return apiQueryOptions;
}

export const mapLexiconItemVMToAPIModel = (
  item: ILexiconItem
): Omit<ILexiconItemAPI, 'isThematic' | 'isLexiconRule'> => {
  return {
    tagKey: item.tagKey,
    verticals: item.verticals,
    category: item.category,
    attribute: item.attribute,
    value: item.value,
    include: item.include,
    hasUnpublishedChanges: item.hasUnpublishedChanges,
  };
};

export const mapLexiconItemVMToAPIRestoredModel = (
  item: ILexiconItem
): RequestUpdateOneLexiconBody => {
  return {
    category: {
      key: item.category.key,
    },
    attribute: {
      key: item.attribute.key,
    },
    value: {
      key: item.value.key,
    },
    include: true,
  };
};

export const mapLexiconTagUpdateToRequest = (tag: ILexiconItemAPI): RequestUpdateOneLexiconBody => {
  const body: RequestUpdateOneLexiconBody = {
    category: { key: tag.category.key, customTranslation: tag.category?.customTranslation },
    attribute: { key: tag.attribute.key, customTranslation: tag.attribute?.customTranslation },
    value: { key: tag.value.key, customTranslation: tag.value?.customTranslation },
    include: tag.include,
  };
  return body;
};
