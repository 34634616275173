import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useParams } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { ExistingCatalogForm } from 'src/components-bl/CatalogManager/';
import { useAppSelector } from 'src/hooks';
import { editExistingCatalogModalContainerActions } from './EditExistingCatalogModalContainer.actions';

type EditExistingCatalogModalContainerProps = RoutedComponentProps;

export const EditExistingCatalogModalContainer = ({
  permittedRouteMap,
}: EditExistingCatalogModalContainerProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const { id: catalogIdParam } = useParams<{ id: string }>();

  const selectedShopId = useAppSelector(state => state.shop.current?.shopId);
  const selectedCatalog = useAppSelector(state => state.catalogManager.selectedConnectedCatalog);
  const catalogs = useAppSelector(state => state.catalogManager.catalogs);

  const onModalClose = useCallback(() => {
    if (permittedRouteMap.catalogManagerList && selectedShopId) {
      dispatch(
        editExistingCatalogModalContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.catalogManagerList.path, {
            shopId: selectedShopId,
          }),
        })
      );
    }
  }, [permittedRouteMap.catalogManagerList, selectedShopId]);

  useEffect(() => {
    if (!selectedShopId || !catalogIdParam) return;

    const fetch = async () => {
      if (selectedCatalog?.id !== catalogIdParam) {
        try {
          await (
            dispatch(
              editExistingCatalogModalContainerActions.getCatalog({
                shopId: selectedShopId,
                id: catalogIdParam,
              })
            ) as any
          ).unwrap();
        } catch (error) {
          console.error(error);
          onModalClose();
        }
      }
    };
    fetch();
  }, [catalogIdParam, selectedShopId, onModalClose]);

  useEffect(() => {
    return () => {
      dispatch(editExistingCatalogModalContainerActions.resetCatalog());
    };
  }, []);

  return selectedShopId ? (
    <ExistingCatalogForm
      onCancel={onModalClose}
      shopId={selectedShopId}
      dispatch={dispatch}
      mode='edit'
      catalog={selectedCatalog}
      allCatalogs={catalogs}
    />
  ) : null;
};
