import React, {
  useRef,
  useEffect,
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useAppSelector } from 'src/hooks';
import {
  DeepTagReportProductsList,
  DeepTagReportFilters,
  DeepTagReportProductPageHeaderProps,
} from 'src/components-bl';
import { DeepTagScoreType } from 'src/services';
import { SkeletonVerticalBlocks } from 'src/components-dummy/Skeleton/skeletonCompositions';
import { UserRoles } from 'src/services/src/service/types/users';
import { matchPath } from 'react-router';
import { deepTagReportsRoutes } from 'src/app-routes';
import { deepTagReportProductsContainerActions } from '../Actions';
import { ProductRouteFilters, ConnectedDeepTagReportProductsPageContentProps } from './types';
import { DeepTagReportContentStyled } from '../DeepTagReportProductsContainer.styles';
import { ConnectedDeepTagReportProductPagination } from './ConnectedDeepTagReportProductPagination';
import { getCleanedFiltersWithPagination } from '../../../DeepTagReports.helpers';
import { deepTagsProductContainerActions } from '../../DeepTagsProductContainer/Actions';

export interface ConnectedDeepTagReportProductsPageContentRefMethods {
  getReportProductsWithFilters: () => void;
  getCleanedFiltersWithPagination: DeepTagReportProductPageHeaderProps['getCleanedFiltersWithPagination'];
}

export const ConnectedDeepTagReportProductsPageContent = forwardRef<
  ConnectedDeepTagReportProductsPageContentRefMethods,
  ConnectedDeepTagReportProductsPageContentProps
>(({ permittedRouteMap }, ref): JSX.Element | null => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();
  const {
    selectedReport,
    shopId,
    productPagination,
    filters,
    paginationFilters,
    generalConfigurationLabels,
    userRole,
    routerLocation,
  } = useAppSelector(state => {
    return {
      selectedReport: state.deepTagReports.selectedReport,
      productPagination: state.deepTagReports.productPagination,
      shopId: state.shop.current?.shopId,
      filters: state.deepTagReports.filters,
      paginationFilters: state.deepTagReports.paginationFilters,
      generalConfigurationLabels: state.deepTagReports.generalConfigurationLabels,
      userRole: state.global.loggedInUser?.role,
      routerLocation: state.router.location,
    };
  }, shallowEqual);

  const isCurrentRoute = useMemo(() => {
    return !!matchPath(routerLocation.pathname, {
      path: deepTagReportsRoutes.deepTagReportProducts.path,
      exact: true,
      strict: false,
    });
  }, [routerLocation.pathname]);

  // Temporary
  const shouldHideEditIndication = userRole === UserRoles.ClientAdmin;

  const selectedScoreTypes: DeepTagScoreType[] = useMemo(() => {
    return filters.tagScoreTypes?.isApplied && filters.tagScoreTypes.value
      ? filters.tagScoreTypes.value
      : ([] as DeepTagScoreType[]);
  }, [filters.tagScoreTypes]);

  const getReportProductsWithFilters = useCallback(async (): Promise<unknown> => {
    if (shopId && selectedReport) {
      await dispatch(deepTagReportProductsContainerActions.getLabelsGeneralConfiguration());
      const enabledFiltersWithValue = getCleanedFiltersWithPagination({
        filters,
        paginationFilters,
      });

      return dispatch(
        deepTagReportProductsContainerActions.getReportProducts({
          shopId,
          locale: selectedReport.locale,
          reportId: selectedReport.id,
          ...enabledFiltersWithValue,
        })
      );
    }

    return null;
  }, [filters, paginationFilters, selectedReport, shopId, dispatch]);

  const getShopLexiconByLocale = useCallback(async () => {
    if (shopId && selectedReport?.locale) {
      await dispatch(
        deepTagsProductContainerActions.getShopLexiconByLocale({
          shopId,
          locale: selectedReport.locale,
        })
      );
    }
  }, [shopId, selectedReport?.locale, dispatch]);

  const fetchFilteredProducts = useCallback(() => {
    getReportProductsWithFilters();
  }, [getReportProductsWithFilters]);

  useEffect(() => {
    getReportProductsWithFilters();
  }, [fetchFilteredProducts]);

  useEffect(() => {
    if (isCurrentRoute) {
      fetchFilteredProducts();
    }
  }, [isCurrentRoute]);

  useImperativeHandle(ref, () => ({
    getReportProductsWithFilters,
    getCleanedFiltersWithPagination: (): ProductRouteFilters => {
      return getCleanedFiltersWithPagination({ filters, paginationFilters });
    },
  }));

  useEffect(() => {
    containerRef?.current?.scrollIntoView();
  }, [paginationFilters]);

  useEffect(() => {
    return () => {
      dispatch(deepTagReportProductsContainerActions.resetCurrentReport());
    };
  }, []);

  useEffect(() => {
    getShopLexiconByLocale();
  }, [getShopLexiconByLocale]);

  const labelNameById: Record<string, string> = useMemo(() => {
    return generalConfigurationLabels.reduce(
      (prev, next) => {
        // eslint-disable-next-line no-param-reassign
        prev[next.id] = next.name;
        return prev;
      },
      {} as { [id: string]: string }
    );
  }, [generalConfigurationLabels]);

  return shopId && selectedReport ? (
    <DeepTagReportContentStyled ref={containerRef}>
      <DeepTagReportFilters
        shopId={shopId}
        report={selectedReport}
        labelNameById={labelNameById}
        availableFilterKeysMap={productPagination?.data?.availableFilterKeysMap || {}}
        dispatch={dispatch}
        filters={filters}
        totalProducts={productPagination?.totalCount || 0}
        shouldHideEditIndication={shouldHideEditIndication}
      />
      {productPagination?.data?.products ? (
        <DeepTagReportProductsList
          shopId={shopId}
          reportId={selectedReport.id}
          locale={selectedReport.locale}
          dispatch={dispatch}
          products={productPagination?.data?.products}
          generalConfigurationLabels={generalConfigurationLabels}
          getReportProductsWithFilters={getReportProductsWithFilters}
          productRoute={(permittedRouteMap.deepTagReportProduct as any).path} // TODO
          selectedScoreTypes={selectedScoreTypes}
          shouldHideEditIndication={shouldHideEditIndication}
        />
      ) : (
        <SkeletonVerticalBlocks spacing={3} itemProps={{ height: 198 }} />
      )}
      <ConnectedDeepTagReportProductPagination />
    </DeepTagReportContentStyled>
  ) : null;
});
