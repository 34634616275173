import { AxiosProgressEvent, AxiosResponse } from 'axios';
import { ApiServiceBase } from '../api-service-base';
import { DeepTagReportsMapper } from './deep-tag-reports.mapper';
import {
  DeepTagReport,
  DeepTagReportWithMetaData,
  DeepTagReportProductPagination,
  IDeepTagReportProduct,
  DeepTagReportStatus,
  DeepTagReportDelimiterType,
  DeepTagsReportsConfiguration,
  DeepTagsReportsGeneratedText,
  DeepTagsReportsGenerativeAIFieldType,
  ToneOfVoiceTypes,
  DescriptionTextLengthEnum,
  TitleTextLengthEnum,
  DeepTagsReportsEvenBulkAssignLabels,
  DeepTagsReportsCustomBulkAssignLabels,
  DeepTagReportProductFilters,
  IDeepTagReportProductComment,
  IDeepTagReportProductCommentAPI,
  DeepTagsGenerativeAISubProduct,
} from '../types';
import { downloadResponseFile } from '../../common/utils';
import {
  ExportDeepTagReportArguments,
  GetDeepTagReportArguments,
  GetDeepTagReportProductArguments,
  GetDeepTagReportProductsArguments,
  RegenerateProductsTagsArguments,
  UpdateReportProductArguments,
} from './types';

const defaultDelimiterType = DeepTagReportDelimiterType.Comma;
const defaultIncludeProductsWithEmptyTags = true;

export class DeepTagReportsService extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl(shopId: number): string {
    return `${this.serviceBaseUri}/${shopId}/deep-tag-reports`;
  }

  async getReports(shopId: number): Promise<DeepTagReport[]> {
    const url = this.getUrl(shopId);
    const response = await this.httpService.get({ url });
    return DeepTagReportsMapper.mapReports(response);
  }

  // TODO: this method to download file (With anchor trick), seem to first download everything and only then instantly download it. need native download
  async exportReportToFile({
    shopId,
    delimiterType = defaultDelimiterType,
    includeProductsWithEmptyTags = defaultIncludeProductsWithEmptyTags,
    customExportLocale,
    regenerateTitles,
    regenerateDescriptions,
    regenerateThematics,
    reportId,
  }: ExportDeepTagReportArguments): Promise<AxiosResponse<any, any>> {
    const url = `${this.getUrl(shopId)}/${reportId}/export/file`;
    const data = {
      delimiterType,
      includeProductsWithEmptyTags,
      customExportLocale,
      regenerateTitles,
      regenerateDescriptions,
      regenerateThematics,
    };

    const response = await this.httpService.post({
      url,
      data,
      requestConfig: {
        responseType: 'blob',
      },
    });

    downloadResponseFile({ response, defaultName: 'ai_tagging_report.csv' });

    return response;
  }

  async exportReportToSFTP({
    shopId,
    delimiterType = defaultDelimiterType,
    includeProductsWithEmptyTags = defaultIncludeProductsWithEmptyTags,
    reportId,
  }: ExportDeepTagReportArguments): Promise<void> {
    const url = `${this.getUrl(shopId)}/${reportId}/export/sftp`;
    const data = {
      delimiterType,
      includeProductsWithEmptyTags,
    };
    await this.httpService.post({ url, data });
  }

  async deleteReport({
    shopId,
    reportId: deepTagReportId,
  }: GetDeepTagReportArguments): Promise<void> {
    const url = `${this.getUrl(shopId)}/${deepTagReportId}`;
    await this.httpService.delete({ url });
  }

  async getReport({
    shopId,
    reportId: deepTagReportId,
  }: GetDeepTagReportArguments): Promise<DeepTagReportWithMetaData> {
    const url = `${this.getUrl(shopId)}/${deepTagReportId}`;
    const response = await this.httpService.get({ url });
    return DeepTagReportsMapper.mapReportWithMetaData(response);
  }

  async getReportProducts({
    shopId,
    reportId,
    limit,
    skip,
    search,
    editedOnly,
    emptyTagsOnly,
    tagScoreTypes,
    attributes,
    attributeValuePairs,
    missingAttributeValuePairs,
    missingAttributes,
    categories,
    verticals,
    values,
    statuses,
    labels,
    locale,
  }: GetDeepTagReportProductsArguments): Promise<DeepTagReportProductPagination> {
    const url = `${this.getUrl(shopId)}/${reportId}/products`;
    const params = {
      limit,
      skip,
      editedOnly,
      search,
      emptyTagsOnly,
      tagScoreTypes: tagScoreTypes?.join(','),
      attributes: attributes?.join(','),
      missingAttributes: missingAttributes?.join(','),
      attributeValuePairs: attributeValuePairs?.join(','),
      missingAttributeValuePairs: missingAttributeValuePairs?.join(','),
      categories: categories?.join(','),
      verticals: verticals?.join(','),
      statuses: statuses?.join(','),
      labels: labels?.join(','),
      values: values?.join(','),
    };
    const response = await this.httpService.get({ url, requestConfig: { params } });

    return DeepTagReportsMapper.mapProductsPagination(response, locale);
  }

  async updateReportProduct({
    shopId,
    reportId,
    productId,
    locale,
    ...partialProductToUpdate
  }: UpdateReportProductArguments): Promise<IDeepTagReportProduct> {
    const url = `${this.getUrl(shopId)}/${reportId}/products/${productId}`;

    const response = await this.httpService.patch({ url, data: partialProductToUpdate });

    return DeepTagReportsMapper.mapReportProduct(response.data, locale);
  }

  async resetReportProduct({
    shopId,
    reportId,
    productId,
    locale,
  }: {
    shopId: number;
    reportId: string;
    productId: string;
    locale: string;
  }): Promise<IDeepTagReportProduct> {
    const url = `${this.getUrl(shopId)}/${reportId}/products/${productId}/reset-changes`;

    const response = await this.httpService.post({ url });

    return DeepTagReportsMapper.mapReportProduct(response.data, locale);
  }

  async updateReport({
    shopId,
    deepTagReportId,
    status,
    customName,
  }: {
    shopId: number;
    deepTagReportId: string;
    status?: DeepTagReportStatus;
    customName?: string;
  }): Promise<DeepTagReportWithMetaData> {
    const url = `${this.getUrl(shopId)}/${deepTagReportId}`;

    const data = {
      shopId,
      deepTagReportId,
      status,
      customName,
    };

    const response = await this.httpService.patch({ url, data });

    return DeepTagReportsMapper.mapReportWithMetaData(response);
  }

  async bulkEvenAssignProductLabels({
    shopId,
    reportId,
    labels,
    filters,
    shouldOnlyIncludeUnlabeled,
  }: {
    shopId: number;
    reportId: string;
    labels: DeepTagsReportsEvenBulkAssignLabels[];
    filters: DeepTagReportProductFilters;
    shouldOnlyIncludeUnlabeled: boolean;
  }): Promise<void> {
    const url = `${this.getUrl(shopId)}/${reportId}/bulkEvenAssignProductLabels`;

    const data = {
      labels,
      filters,
      shouldOnlyIncludeUnlabeled,
    };

    await this.httpService.post({ url, data });
  }

  async bulkCustomAssignProductLabels({
    shopId,
    reportId,
    totalProductsPerLabel,
    filters,
    shouldOnlyIncludeUnlabeled,
  }: {
    shopId: number;
    reportId: string;
    totalProductsPerLabel: DeepTagsReportsCustomBulkAssignLabels[];
    filters: DeepTagReportProductFilters;
    shouldOnlyIncludeUnlabeled: boolean;
  }): Promise<void> {
    const url = `${this.getUrl(shopId)}/${reportId}/bulkCustomAssignProductLabels`;

    const data = {
      totalProductsPerLabel,
      filters,
      shouldOnlyIncludeUnlabeled,
    };

    await this.httpService.post({ url, data });
  }

  async getReportConfiguration({
    shopId,
  }: {
    shopId: number;
  }): Promise<DeepTagsReportsConfiguration> {
    const url = `${this.getUrl(shopId)}/configuration`;

    const response = await this.httpService.get({ url });

    return DeepTagReportsMapper.mapReportConfiguration(response);
  }

  async updateReportConfiguration({
    shopId,
    ...payload
  }: { shopId: number } & Partial<
    Omit<DeepTagsReportsConfiguration, 'shopId'>
  >): Promise<DeepTagsReportsConfiguration> {
    const url = `${this.getUrl(shopId)}/configuration`;

    const response = await this.httpService.patch({ url, data: payload });

    return DeepTagReportsMapper.mapReportConfiguration(response);
  }

  /**
   * Creates Report along with file upload
   */
  async createReport({
    data,
    cancellationSignal,
    onUploadProgress,
  }: {
    data: DeepTagsReportsConfiguration & { productsFile: File };
    cancellationSignal?: AbortSignal;
    onUploadProgress: (progressEvent: AxiosProgressEvent) => void;
  }): Promise<DeepTagsReportsConfiguration> {
    const { shopId, recognitionType, locale, productsFile } = data;

    const url = `${this.getUrl(shopId)}`;

    const formData = new FormData();
    formData.append('file', productsFile);
    formData.append('locale', locale);
    formData.append('recognitionType', recognitionType);

    const response = await this.httpService.post({
      url,
      data: formData,
      requestConfig: {
        onUploadProgress,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        signal: cancellationSignal,
      },
    });

    return DeepTagReportsMapper.mapReportConfiguration(response);
  }

  async generatedText({
    subProducts,
    fieldType,
    toneOfVoice,
    textLength,
    shopId,
    locale,
    reportId,
    productId,
  }: {
    subProducts: DeepTagsGenerativeAISubProduct[];
    fieldType: DeepTagsReportsGenerativeAIFieldType;
    toneOfVoice?: ToneOfVoiceTypes;
    textLength: DescriptionTextLengthEnum | TitleTextLengthEnum;
    shopId: number;
    locale: string;
    reportId: string;
    productId: string;
  }): Promise<DeepTagsReportsGeneratedText> {
    const data = {
      subProducts,
      locale,
      fieldType,
      toneOfVoice,
      textLength,
    };

    const url = `${this.getUrl(shopId)}/${reportId}/products/${productId}/generate-text`;

    const response = await this.httpService.post({ url, data });

    return response.data;
  }

  /**
   * Product comments
   */
  async getProductCommentsList({
    shopId,
    reportId,
    productId,
  }: GetDeepTagReportProductArguments): Promise<IDeepTagReportProductCommentAPI[]> {
    const url = `${this.getUrl(shopId)}/${reportId}/products/${productId}/comments`;

    const response = await this.httpService.get({
      url,
    });

    return response?.data;
  }

  async createProductComment({
    comment,
    shopId,
    reportId,
    productId,
  }: {
    comment: IDeepTagReportProductComment;
  } & GetDeepTagReportProductArguments): Promise<IDeepTagReportProductCommentAPI> {
    const url = `${this.getUrl(shopId)}/${reportId}/products/${productId}/comments`;

    const response = await this.httpService.post({
      url,
      data: comment,
    });

    return response.data;
  }

  async updateProductComment({
    comment,
    shopId,
    reportId,
    productId,
  }: {
    comment: IDeepTagReportProductComment;
  } & GetDeepTagReportProductArguments): Promise<IDeepTagReportProductCommentAPI> {
    const url = `${this.getUrl(shopId)}/${reportId}/products/${productId}/comments/${comment.id}`;

    const response = await this.httpService.patch({
      url,
      data: { text: comment.text },
    });

    return response.data;
  }

  async deleteProductComment({
    commentId,
    shopId,
    reportId,
    productId,
  }: {
    commentId: IDeepTagReportProductComment['id'];
  } & GetDeepTagReportProductArguments): Promise<void> {
    const url = `${this.getUrl(shopId)}/${reportId}/products/${productId}/comments/${commentId}`;

    const response = await this.httpService.delete({
      url,
    });

    return response.data;
  }

  async regenerateProductsTags({
    shopId,
    locale,
    filters,
    reportId,
    data,
  }: RegenerateProductsTagsArguments): Promise<AxiosResponse<any, any>> {
    const url = `${this.getUrl(shopId)}/${reportId}/bulkRegenerateTags`;
    const bodyRequestData = {
      locale,
      filters,
      ...data,
    };

    const response = await this.httpService.post({
      url,
      data: bodyRequestData,
    });

    return response;
  }
}
