import React from 'react';
import { ProductDescriptionItem } from './ProductDescriptionItem';
import { ProductPrice } from './ProductPrice';
import { SizeVariants } from './SizeVariants';
import { ColorVariants } from './ColorVariants';
import { EllipsisWithTooltip, TypographyVariant } from '../../../../../components-dummy';
import { SpecialDataFieldToDisplay } from '../../../types/special-data-field-to-display';
import { AdData, ColorVariant } from '../../../../../services';
import {
  ProductDescriptionHeader,
  ProductDescriptionItemWrapper,
  ProductDescriptionKey,
  ProductDescriptionValue,
} from './ProductTile.styles';

interface RenderSpecialDataFieldsProps {
  offer: AdData;
  specialDataFieldsToDisplay: SpecialDataFieldToDisplay[];
  variants: ColorVariant[];
  selectedColorIndex: number;
  onColorVariantClick: (index: number) => void;
  selectedSizeIndex: number;
  onSizeVariantClick: (index: number) => void;
}

export const renderSpecialDataFields = ({
  offer,
  specialDataFieldsToDisplay,
  variants,
  selectedColorIndex,
  onColorVariantClick,
  selectedSizeIndex,
  onSizeVariantClick,
}: RenderSpecialDataFieldsProps): JSX.Element[] => {
  return specialDataFieldsToDisplay
    .filter(data => data.isActive)
    .map((field, index) => {
      switch (field.text) {
        case 'Description':
          const description = offer.description || offer.title;
          if (index === 0) {
            return (
              <ProductDescriptionHeader variant={TypographyVariant.MediumRegular}>
                <EllipsisWithTooltip tooltipText={description}>{description}</EllipsisWithTooltip>
              </ProductDescriptionHeader>
            );
          }
          return <ProductDescriptionItem key={field.text} text={field.text} value={description} />;
        case 'SKU':
          if (index === 0) {
            return (
              <ProductDescriptionHeader variant={TypographyVariant.MediumRegular}>
                <EllipsisWithTooltip tooltipText={offer.sku}>{offer.sku}</EllipsisWithTooltip>
              </ProductDescriptionHeader>
            );
          }
          return <ProductDescriptionItem key={field.text} text={field.text} value={offer.sku} />;
        case 'Price':
          if (index === 0) {
            return <ProductPrice price={offer.price} originalPrice={offer.originalPrice} />;
          }
          return (
            <ProductDescriptionItemWrapper>
              <ProductDescriptionKey variant={TypographyVariant.SmallRegular}>
                <EllipsisWithTooltip tooltipText={field.text}>{field.text}</EllipsisWithTooltip>
              </ProductDescriptionKey>
              <ProductDescriptionValue variant={TypographyVariant.SmallRegular}>
                <ProductPrice price={offer.price} originalPrice={offer.originalPrice} />
              </ProductDescriptionValue>
            </ProductDescriptionItemWrapper>
          );
        case 'Size Variants':
          return (
            <SizeVariants
              variants={variants}
              selectedColorIndex={selectedColorIndex}
              selectedSizeIndex={selectedSizeIndex}
              onVariantClick={onSizeVariantClick}
            />
          );
        case 'Color Variants':
          return (
            <ColorVariants
              variants={variants}
              selectedIndex={selectedColorIndex}
              onVariantClick={onColorVariantClick}
            />
          );
        default:
          return <></>;
      }
    });
};
