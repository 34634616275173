import React, { PropsWithChildren, Children, ReactChild } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

type ListItem = ReactChild;
export type ReOrderedList = { key: string; item: ListItem }[];
interface DraggableListCallback {
  onChange: (updatedList: ReOrderedList) => void;
  isDragDisabled?: boolean;
}
export type DraggableListProps = PropsWithChildren<DraggableListCallback>;

export const DraggableList = ({
  onChange,
  children,
  isDragDisabled = false,
}: DraggableListProps): JSX.Element => {
  const getKeyFromItem = (item: unknown, index: number): string => {
    const keyProp = (item as JSX.Element).key;
    return keyProp?.toString() || index.toString();
  };

  const reorder = (initialIndex: number, targetIndex: number): ReOrderedList => {
    const updated = Children.map(children, (item, index) => {
      const key = getKeyFromItem(item, index);
      return { key, item: item as ListItem };
    }) as ReOrderedList;
    const droppedItem = updated.splice(initialIndex, 1)[0];
    updated.splice(targetIndex, 0, droppedItem);
    return updated;
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (destination && destination.index !== source.index) {
      onChange(reorder(source.index, destination.index));
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='syte-droppable-list'>
        {droppableProvided => (
          <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
            {Children.map(children, (item, index) => {
              const key = getKeyFromItem(item, index);
              return (
                <Draggable
                  key={key}
                  draggableId={String(key)}
                  index={index}
                  isDragDisabled={isDragDisabled}
                >
                  {draggableProvided => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      {item}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
