import React from 'react';
import { TooltipWithLink } from 'src/components-dummy/SettingsForm/TooltipWithLink';

export function AITagsTooltip() {
  return (
    <TooltipWithLink.Text>
      <TooltipWithLink.Link path={'https://syte-ai.atlassian.net/servicedesk/customer/portals'}>
        Upgrade
      </TooltipWithLink.Link>
      &nbsp;to access Visual AI Tags
    </TooltipWithLink.Text>
  );
}
