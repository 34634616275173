/* eslint-disable react/jsx-no-target-blank */
import React, { MouseEvent, useState } from 'react';
import './ProductTag.scss';
import {
  AssignedProduct,
  InStockProduct,
  OutOfStockProduct,
  ProductType,
} from 'src/components-bl/socialDiscovery/types/entities.types';
import {
  Typography,
  TypographyType,
  TypographyVariant,
  Icon,
  AvailableIcons,
  Tooltip,
} from 'src/components-dummy';

import {
  StyledProductTagBody,
  StyledProductTagPrice,
  StyledProductSKUContainer,
  StyledProductSKU,
  StyledProductOOS,
  StyledProductTag,
  StyledProductTagTitle,
  StyledIconContainer,
} from './styledComponents';
import { DeleteConfirmationModal } from '../DeleteConfirmationModal/DeleteConfirmationModal';

interface ProductTagSKUProps {
  sku: string;
  onDelete: () => void;
}

const ProductTagSKU = ({ sku, onDelete }: ProductTagSKUProps): JSX.Element => {
  const handleDeleteClick = (e: MouseEvent) => {
    e.stopPropagation();
    onDelete();
  };

  return (
    <StyledProductSKUContainer className='sku'>
      <StyledProductSKU>
        <Tooltip value={sku} position='bottom center'>
          <Typography type={TypographyType.Body} variant={TypographyVariant.ExtraSmallRegular}>
            {sku}
          </Typography>
        </Tooltip>
        <StyledIconContainer>
          <Icon name={AvailableIcons.Trash} onClick={handleDeleteClick} />
        </StyledIconContainer>
      </StyledProductSKU>
    </StyledProductSKUContainer>
  );
};

interface ProductTagProps {
  product: AssignedProduct;
  onDelete: () => void;
}

export const ProductTag = ({ product, onDelete }: ProductTagProps): JSX.Element => {
  const { type, sku } = product;

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const handleDeleteConfirm = () => {
    setDeleteModalOpen(false);
    onDelete();
  };

  const DeleteModal = (
    <DeleteConfirmationModal
      show={deleteModalOpen}
      titleText='Delete tag'
      modalBodyText={`Are you sure you want to delete tag ${sku} ?`}
      onDelete={handleDeleteConfirm}
      onClose={() => setDeleteModalOpen(false)}
      onClickOutside={() => setDeleteModalOpen(false)}
    />
  );

  if (type === ProductType.notIndexed) {
    return (
      <>
        {DeleteModal}
        <StyledProductTag className='product-tag not-indexed'>
          <ProductTagSKU sku={sku} onDelete={() => setDeleteModalOpen(true)} />
          <StyledProductOOS>
            <Typography type={TypographyType.Body} variant={TypographyVariant.ExtraSmallRegular}>
              Not in feed
            </Typography>
          </StyledProductOOS>
        </StyledProductTag>
      </>
    );
  }

  const { title, formattedPrice, offerLink } = product as InStockProduct | OutOfStockProduct;

  const handleRedirectClick = (_: MouseEvent) => window.open(offerLink, '_blank');

  return (
    <>
      {DeleteModal}
      <StyledProductTag className='product-tag'>
        <StyledProductTagBody>
          <StyledProductTagTitle onClick={handleRedirectClick}>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              {title}
            </Typography>
          </StyledProductTagTitle>

          <StyledProductTagPrice>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              {formattedPrice}
            </Typography>
            <a href={offerLink} target='_blank'>
              <Icon name={AvailableIcons.AngleRightLight} />
            </a>
          </StyledProductTagPrice>
        </StyledProductTagBody>

        <ProductTagSKU sku={sku} onDelete={() => setDeleteModalOpen(true)} />

        {type === ProductType.outOfStock && (
          <StyledProductOOS>
            <Typography type={TypographyType.Body} variant={TypographyVariant.ExtraSmallRegular}>
              Out of stock
            </Typography>
          </StyledProductOOS>
        )}
      </StyledProductTag>
    </>
  );
};
