import React, { useEffect, useState, useMemo } from 'react';
import { getStringEnumValues, SortOption, sortArray } from 'src/utils';
import { useFilterItems } from 'src/hooks';
import {
  Icon,
  List,
  TextBox,
  MenuItem,
  Select,
  SelectType,
  SelectOnChangeEvent,
  Typography,
  TypographyType,
  TypographyVariant,
  ListItemProps,
  AvailableIcons,
} from '../../components-dummy';
import { ShopTypes } from '../../services';
import { ShopCard } from '../ShopCard';
import { ShopEmptyResults } from '../ShopEmptyResults';
import './ShopList.scss';
import { Dispatch } from '../types';
import { shopListActions } from './Actions';

export interface ShopListProps {
  accountId?: number;
  shops?: ShopTypes.ShopBase[];
  selectedShopId?: number;
  canCreateShop: boolean;
  canDeleteShop: boolean;
  dispatch: Dispatch;
}

const sortOptionTranslations: Record<SortOption, string> = {
  [SortOption.Asc]: 'A-Z',
  [SortOption.Desc]: 'Z-A',
};

const sortDropdownOptions = getStringEnumValues(SortOption).map(option => {
  return {
    value: option,
    text: sortOptionTranslations[option],
  };
});

export const ShopList = ({
  accountId,
  shops,
  selectedShopId,
  canCreateShop,
  canDeleteShop,
  dispatch,
}: ShopListProps): JSX.Element => {
  const [sort, setSort] = useState<SortOption>(SortOption.Asc);
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const onSearchInputChange = (value: string): void => setSearchInputValue(value);

  const { filteredItems } = useFilterItems({
    items: shops || [],
    searchableFields: ['shopName', 'shopId'],
    idField: 'shopId',
    filterText: searchInputValue,
  });

  const sortedShops = useMemo(() => {
    return sortArray({
      items: filteredItems,
      sortBy: shop => shop.shopName.toLowerCase().trim(),
      option: sort,
    });
  }, [sort, filteredItems]);

  useEffect(() => {
    if (accountId) {
      dispatch(shopListActions.getAllShops({ accountId }));
    }
  }, [accountId]);

  const onDropDownChange: SelectOnChangeEvent = event => setSort(event?.target.value as SortOption);

  return (
    <div className='syte-shop-list open'>
      <div className='shop-list-filter-options'>
        <div>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            Sort By
          </Typography>
          <Select type={SelectType.Menu} value={sort} onChange={onDropDownChange}>
            {sortDropdownOptions.map((option: { value?: string; text: string }) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </div>
        <TextBox
          placeholder='Search a Shop'
          value={searchInputValue}
          onChange={onSearchInputChange}
        >
          <Icon name={AvailableIcons.Search} />
        </TextBox>
      </div>
      <div className='shopListWrapper'>
        <List
          idField='shopId'
          items={sortedShops}
          selectedId={selectedShopId}
          onItemSelected={shop => {
            dispatch(shopListActions.navigateToShop({ shopId: shop.shopId }));
          }}
        >
          <List.CardTemplate>
            {(shop: ShopTypes.ShopBase, { isSelected }: ListItemProps): JSX.Element => (
              <ShopCard
                {...shop}
                isSelected={isSelected}
                canDeleteShop={canDeleteShop}
                dispatch={dispatch}
              />
            )}
          </List.CardTemplate>
          <List.EmptyTemplate>
            <ShopEmptyResults canCreateShop={canCreateShop} />
          </List.EmptyTemplate>
        </List>
      </div>
    </div>
  );
};
