import {
  CollectionActionPayload,
  CollectionDeleteSuccessActionPayload,
  CollectionErrorActionPayload,
  CollectionActionTypes,
  CollectionDeleteErrorAction,
  CollectionDeleteRequestAction,
  CollectionDeleteSuccessAction,
} from './types';
import { Dispatch } from '../../../types';
import { collectionsService } from '../../../../services/src/service/collections';

const collectionActions = {
  deleteCollectionRequest(payload: CollectionActionPayload): CollectionDeleteRequestAction {
    return {
      type: CollectionActionTypes.DeleteCollectionRequest,
      payload,
    };
  },
  deleteCollectionSuccess(
    payload: CollectionDeleteSuccessActionPayload
  ): CollectionDeleteSuccessAction {
    return {
      type: CollectionActionTypes.DeleteCollectionSuccess,
      payload,
    };
  },
  deleteCollectionError(payload: CollectionErrorActionPayload): CollectionDeleteErrorAction {
    return {
      type: CollectionActionTypes.DeleteCollectionError,
      payload,
    };
  },
};

export const collectionActionMethods = {
  deleteCollection(payload: CollectionActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      const {
        params: { shopId, collectionId, variantId },
      } = payload;

      try {
        dispatch(collectionActions.deleteCollectionRequest({ ...payload }));
        const collections = await collectionsService.deleteCollection({
          shopId,
          collectionId,
          variantId,
        });
        dispatch(
          collectionActions.deleteCollectionSuccess({
            collections,
          })
        );
      } catch (error) {
        dispatch(collectionActions.deleteCollectionError({ error }));
      }
    };
  },
};
