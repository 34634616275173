import {
  EditCollectionActionTypes,
  EditCollectionActionPayload,
  EditCollectionSuccessActionPayload,
  EditCollectionErrorActionPayload,
  EditCollectionGetCollectionRequestAction,
  EditCollectionGetCollectionSuccessAction,
  EditCollectionGetCollectionErrorAction,
} from './types';
import { Dispatch } from '../../../components-bl';
import { collectionsService } from '../../../services/src/service/collections';

const editCollectionActions = {
  getCollectionRequest(
    payload: EditCollectionActionPayload
  ): EditCollectionGetCollectionRequestAction {
    return {
      type: EditCollectionActionTypes.GetCollectionRequest,
      payload,
    };
  },
  getCollectionSuccess(
    payload: EditCollectionSuccessActionPayload
  ): EditCollectionGetCollectionSuccessAction {
    return {
      type: EditCollectionActionTypes.GetCollectionSuccess,
      payload,
    };
  },
  getCollectionError(
    payload: EditCollectionErrorActionPayload
  ): EditCollectionGetCollectionErrorAction {
    return {
      type: EditCollectionActionTypes.GetCollectionError,
      payload,
    };
  },
};

export const editCollectionActionMethods = {
  getCollection(payload: EditCollectionActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(editCollectionActions.getCollectionRequest({ ...payload }));
        const collection = await collectionsService.getCollection(payload);
        dispatch(editCollectionActions.getCollectionSuccess({ ...collection }));
      } catch (error) {
        dispatch(editCollectionActions.getCollectionError({ error }));
      }
    };
  },
};
