import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppNotification } from 'src/app-types';
import { CatalogManagerService } from 'src/services';

const catalogManagerService = new CatalogManagerService();

export const catalogFeedUploadLogActions = {
  downloadUploadLogFeedFile: createAsyncThunk(
    'catalogFeedUploadLog/DownloadUploadLog',
    async (
      {
        shopId,
        catalogName,
        jobId,
      }: {
        shopId: number;
        catalogName: string;
        jobId: string;
      },
      { rejectWithValue }
    ): Promise<unknown> => {
      try {
        await catalogManagerService.downloadUploadLogFeedFile({
          shopId,
          catalogName,
          jobId,
        });
        return {};
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  notification: createAction<Pick<AppNotification, 'customMessage' | 'type'>>(
    'catalogFeedUploadLog/CreateToast'
  ),
};
