import React, { useCallback } from 'react';
import { useFindComponentByType } from '../hooks';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import { SubTitleTemplate } from './SubTitleTemplate';
import {
  SwitchContainerStyled,
  SwitchLabelStyled,
  SwitchStatusStyled,
  SwitchStyled,
} from './Switch.styles';
import { TitleTemplate } from './TitleTemplate';
import { SwitchSizes } from './Switch.config';

export interface SwitchProps {
  checked: boolean;
  defaultChecked?: boolean;
  children?: JSX.Element | JSX.Element[];
  disabled?: boolean;
  size?: SwitchSizes;
  onChange?: (checked: boolean, event: MouseEvent) => void;
  className?: string;
  inputRef?: React.RefObject<HTMLElement>;
}
export const Switch = ({
  checked,
  defaultChecked,
  disabled = false,
  size,
  onChange,
  className,
  inputRef,
  children,
}: SwitchProps): JSX.Element => {
  const switchSize = size || SwitchSizes.Medium;

  const onCheckboxClick = useCallback(
    event => {
      if (!disabled && onChange) {
        onChange(event.currentTarget.checked, event);
      }
    },
    [checked, disabled, defaultChecked, onChange]
  );

  const switchChangeFromLabel = useCallback(
    event => {
      if (!disabled && onChange) {
        onChange(!checked, event);
        event.stopPropagation();
      }
    },
    [checked, disabled, defaultChecked, onChange]
  );

  const titleComponent = useFindComponentByType({ type: TitleTemplate, children });
  const subTitleComponent = useFindComponentByType({ type: SubTitleTemplate, children });

  const shouldShowLabel = !!(titleComponent || subTitleComponent);

  return (
    <SwitchContainerStyled className={className}>
      <div>
        <SwitchStyled
          disabled={disabled}
          checked={checked}
          defaultChecked={defaultChecked}
          onChange={onCheckboxClick}
          onClick={event => event.stopPropagation()}
          size={switchSize}
          ref={inputRef}
        />
        {switchSize === SwitchSizes.Large && (
          <SwitchStatusStyled size={switchSize}>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              Off
            </Typography>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              On
            </Typography>
          </SwitchStatusStyled>
        )}
      </div>
      {shouldShowLabel && (
        <SwitchLabelStyled disabled={disabled} onClick={switchChangeFromLabel}>
          <Typography
            className='syte-toggle-titles-wrapper'
            type={TypographyType.Body}
            variant={TypographyVariant.SmallBold}
          >
            {titleComponent && titleComponent}
            {subTitleComponent && subTitleComponent}
          </Typography>
        </SwitchLabelStyled>
      )}
    </SwitchContainerStyled>
  );
};

Switch.TitleTemplate = TitleTemplate;
Switch.SubTitleTemplate = SubTitleTemplate;
