import styled from '@emotion/styled';
import { MenuItem } from 'src/components-dummy';

export const Image = styled.img`
  width: 16px;
  height: 16px;
`;

export const MenuItemStyled = styled(MenuItem)`
  gap: 5px;
  width: 100%;
`;
