import styled from '@emotion/styled';

export const IconWrapperStyled = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.custom['gray-100']};

    path {
      stroke: ${({ theme }) => theme.palette.common.black};
    }
  }
`;
