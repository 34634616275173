import React, { useMemo } from 'react';
import { generatePath, Redirect } from 'react-router';
import { AppRoute, AppSwitch, RouteSettings, RoutedComponentProps } from 'src/app-routes';
import { LexiconTablePageContainer } from '../LexiconTablePageContainer';
import { LexiconsContainer } from '../LexiconsContainer';

export type LexiconContainerSwitchProps = RoutedComponentProps & { shopId: number | undefined };

interface ComponentRoute {
  route: RouteSettings;
  Component: () => JSX.Element | null;
}

export const LexiconContainerSwitch = ({
  permittedRouteMap,
  shopId,
}: LexiconContainerSwitchProps): JSX.Element => {
  const relevantRoutes = useMemo(
    () =>
      [
        { route: permittedRouteMap.lexiconCategories, Component: LexiconTablePageContainer },
        { route: permittedRouteMap.lexiconLanguages, Component: LexiconsContainer },
      ].filter(routeComponent => !!routeComponent.route) as (ComponentRoute & { exact: boolean })[],
    [permittedRouteMap.lexiconCategory, permittedRouteMap.lexiconCategories]
  );

  const routeComponents = useMemo(
    () =>
      relevantRoutes.map(({ route, Component }) => (
        <AppRoute
          key={route.path}
          route={route}
          Component={Component}
          componentProps={{ permittedRouteMap, shopId }}
          exact={false}
        />
      )),
    [relevantRoutes, permittedRouteMap, shopId]
  );

  return (
    <AppSwitch>
      {routeComponents}
      {permittedRouteMap.lexiconLanguages && shopId && (
        <Redirect to={generatePath(permittedRouteMap.lexiconLanguages.path, { shopId })} />
      )}
    </AppSwitch>
  );
};
