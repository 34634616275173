import React from 'react';
import { TextBox, Button, FormCard, Select, SelectType, MenuItem } from 'src/components-dummy';
import { GeneralSettingsProps } from './types';
import { useGeneralSettings } from './useGeneralSettings';
import {
  GeneralSettingsCardContentStyled,
  GeneralSettingsContainerStyled,
} from './GeneralSettings.styles';
import { shopTypeDropdownOptions } from '../ShopCreationForm/constants';
import { SalesforceAccountAutocomplete } from '../ShopCreationForm/components/SalesforceAccountAutocomplete/SalesforceAccountAutocomplete';

export const GeneralSettings = ({ shop, dispatch, isAdmin }: GeneralSettingsProps): JSX.Element => {
  const { errors, isDirty, isValid, onChange, formState, onSubmit } = useGeneralSettings({
    shop,
    dispatch,
    isAdmin,
  });

  return (
    <GeneralSettingsContainerStyled>
      <FormCard>
        <FormCard.Title>Shop Settings</FormCard.Title>
        <FormCard.Button>
          <Button onClick={onSubmit} variant='primary' disabled={!(isValid && isDirty)}>
            Save changes
          </Button>
        </FormCard.Button>
        <FormCard.Content>
          <GeneralSettingsCardContentStyled>
            <TextBox
              label='Shop Name'
              value={formState.shopName}
              error={Boolean(errors.shopName)}
              onChange={shopName => onChange({ shopName })}
            />
            <TextBox
              label='Shop URL'
              placeholder='https://my-store.com'
              value={formState.shopUrl}
              error={Boolean(errors.shopUrl)}
              onChange={shopUrl => onChange({ shopUrl })}
            />

            {isAdmin && (
              <>
                <Select
                  type={SelectType.Primary}
                  label='Shop type'
                  value={shop.shopType}
                  isError={Boolean(errors.shopType)}
                  disabled
                  className='shop-type-select'
                >
                  {shopTypeDropdownOptions.map(({ text, value }) => (
                    <MenuItem key={value} value={value}>
                      {text}
                    </MenuItem>
                  ))}
                </Select>
                <SalesforceAccountAutocomplete
                  disabled
                  selectedAccount={{
                    salesforceAccountId: shop.salesforceAccountId,
                  }}
                  error={undefined}
                  onSelectAccount={() => {}}
                />
              </>
            )}
          </GeneralSettingsCardContentStyled>
        </FormCard.Content>
      </FormCard>
    </GeneralSettingsContainerStyled>
  );
};
