import { Dispatch } from '../../types';

export enum CollectionNavigationActionTypes {
  NavigateTo = 'EditCollection/NavigateTo',
  NotifyIsDirty = 'EditCollection/NotifyIsDirty',
}

interface CollectionNavigateToActionPayload {
  navigateTo: string;
}

interface CollectionNotifyIsDirtyActionPayload {
  isDirty: boolean;
}

interface CollectionNavigateToAction {
  type: typeof CollectionNavigationActionTypes.NavigateTo;
  payload: CollectionNavigateToActionPayload;
}

interface CollectionNotifyIsDirtyToAction {
  type: typeof CollectionNavigationActionTypes.NotifyIsDirty;
  payload: CollectionNotifyIsDirtyActionPayload;
}

const collectionNavigationActions = {
  navigateTo(payload: CollectionNavigateToActionPayload): CollectionNavigateToAction {
    return {
      type: CollectionNavigationActionTypes.NavigateTo,
      payload,
    };
  },
  notifyIsDirty: (
    payload: CollectionNotifyIsDirtyActionPayload
  ): CollectionNotifyIsDirtyToAction => {
    return {
      type: CollectionNavigationActionTypes.NotifyIsDirty,
      payload,
    };
  },
};

export const collectionNavigationActionMethods = {
  navigateTo(payload: CollectionNavigateToActionPayload) {
    return (dispatch: Dispatch): void => {
      dispatch(collectionNavigationActions.navigateTo(payload));
    };
  },
  notifyIsDirty(payload: CollectionNotifyIsDirtyActionPayload) {
    return (dispatch: Dispatch): void => {
      dispatch(collectionNavigationActions.notifyIsDirty(payload));
    };
  },
};
