import React from 'react';
import { ShopCatalogVertical } from 'src/services';
import {
  Switch,
  Tooltip,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { VerticalSwitchRowStyled, VerticalChipStyled } from './ExistingCatalogForm.styles';

const verticalToDisplayConfig: Record<ShopCatalogVertical, string> = {
  [ShopCatalogVertical.DIY]: '🔨 DIY',
  [ShopCatalogVertical.Fashion]: '👗 Fashion',
  [ShopCatalogVertical.General]: '📦 General',
  [ShopCatalogVertical.Home]: '🛋 Home Decor',
};

interface VerticalSwitchRowProps {
  onChange: (checked: boolean) => void;
  isChecked: boolean;
  showBelongsToOtherPrimary: boolean;
  vertical: ShopCatalogVertical;
}

export const VerticalSwitchRow = ({
  onChange,
  isChecked,
  vertical,
  showBelongsToOtherPrimary,
}: VerticalSwitchRowProps): JSX.Element => {
  const content = (
    <Switch checked={isChecked} onChange={onChange}>
      <Switch.TitleTemplate>
        <VerticalChipStyled>
          <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
            {verticalToDisplayConfig[vertical]}
          </Typography>
        </VerticalChipStyled>
      </Switch.TitleTemplate>
    </Switch>
  );

  return (
    <VerticalSwitchRowStyled>
      {showBelongsToOtherPrimary ? (
        <Tooltip value='Vertical is selected in other primary catalog'>{content}</Tooltip>
      ) : (
        <>{content}</>
      )}
    </VerticalSwitchRowStyled>
  );
};
