import { DomainEntityPath } from 'src/services';
import { ParentDomainBase } from '../types';

export interface BuildDomainSettingsLookupTableArguments {
  settings: ParentDomainBase[];
}

export type BuildDomainSettingsLookupTableReturnType = Record<DomainEntityPath, ParentDomainBase>;

export function buildDomainSettingsLookupTable({
  settings,
}: BuildDomainSettingsLookupTableArguments): BuildDomainSettingsLookupTableReturnType {
  return settings.reduce((accumulator, item) => {
    accumulator[item.domainKey] = item;
    return accumulator;
  }, {} as BuildDomainSettingsLookupTableReturnType);
}
