import styled from '@emotion/styled';
import DialogMUI from '@mui/material/Dialog';

export const DialogMUIStyled = styled(DialogMUI)`
  .MuiPaper-root {
    border-radius: 16px;
  }
`;

/**
 * Header
 */
export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 16px 40px;
`;

/**
 * Content
 */
export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 40px 32px 40px;
  min-height: 104px;
  max-height: Calc(100vh - 230px);
  overflow: auto;
`;

/**
 * Footer (action buttons)
 */
export const FooterStyled = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: 25px 40px 24px 40px;
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 4px;
`;
