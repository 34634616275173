import { useEffect } from 'react';
import { PreviewFeature } from '../../../services';
import { visualEditorActions } from '../state';
import { Dispatch } from '../../types';
import { ShopFeature } from '../../../app-types';

interface Props {
  shopId: number;
  appDispatch: Dispatch;
  experience: PreviewFeature | null;
  entityId?: string;
}

const getExperienceValue = (experience: PreviewFeature): PreviewFeature =>
  experience === ShopFeature.BrowsePLP ? ShopFeature.Collections : experience;

export const useGetMerchRules = ({ shopId, experience, appDispatch, entityId }: Props) => {
  useEffect(() => {
    if (!experience) return;
    appDispatch(
      visualEditorActions.getMerchRules({ shopId, experience: getExperienceValue(experience) })
    );
  }, [experience]);

  useEffect(() => {
    if (!experience || !entityId) return;
    appDispatch(visualEditorActions.getCollectionMerchRules({ shopId, experience, entityId }));
  }, [entityId]);
};
