import React, { useEffect, useMemo, useState } from 'react';
import { matchPath } from 'react-router';
import { UserRoles } from 'src/services/src/service/types/users';
import {
  ShopFeatureToggles,
  ShopFeatureToggleWithPermittedRoles,
} from 'src/services/src/service/types/shops';
import { baseAppRoutesByPath, baseAppRoutesPaths } from 'src/app-routes';
import {
  BadgeStyled,
  InternalFeatureIndicationStyled,
  TooltipStyled,
} from './InternalFeatureIndication.styles';

interface InternalFeatureIndicationProps {
  featureToggles?: ShopFeatureToggles;
}

export const InternalFeatureIndication = ({
  featureToggles,
}: InternalFeatureIndicationProps): JSX.Element => {
  const [show, setShow] = useState(true);

  const currentRouteConfig = useMemo(() => {
    const currentRoute = matchPath(window.location.pathname, baseAppRoutesPaths);
    return currentRoute ? baseAppRoutesByPath[currentRoute.path] : undefined;
  }, [window.location.pathname]);

  useEffect(() => {
    if (!currentRouteConfig || !featureToggles || !currentRouteConfig.feature) {
      setShow(false);
      return;
    }

    const routeToggle = featureToggles[currentRouteConfig.feature];

    if (!routeToggle) {
      setShow(false);
      return;
    }

    const castedRouteToggle = routeToggle as ShopFeatureToggleWithPermittedRoles;

    const isInternal =
      castedRouteToggle.permittedRoles?.length === 1 &&
      castedRouteToggle.permittedRoles[0] === UserRoles.SyteAdmin;

    setShow(isInternal);
  }, [featureToggles, currentRouteConfig]);

  return (
    <InternalFeatureIndicationStyled show={show}>
      <TooltipStyled
        value='This page is internal only and not visible to clients yet'
        disabled={!show}
        position='bottom center'
      >
        <BadgeStyled text='Internal' background='primary' className='internal-feature-indication' />
      </TooltipStyled>
    </InternalFeatureIndicationStyled>
  );
};
