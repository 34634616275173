import { User } from 'src/services/src/service/types/users';

export function getUserDisplayName(user?: User): string {
  if (user?.firstName && user?.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }

  if (user?.firstName) {
    return user.firstName;
  }

  return 'A user';
}
