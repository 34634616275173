import { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';
import { GetRulesReturnType } from './types';
import { MerchandisingRuleTypes } from '../types';

export function mapAPIRule(
  rule: MerchandisingRuleTypes.MerchandiseRuleAPIResponse
):
  | MerchandisingRuleTypes.MerchandiseRule
  | (MerchandisingRuleTypes.MerchandiseRule & MerchandisingRuleTypes.ExtraIndications) {
  const { filterByCondition, ...restData } = rule;

  return {
    ...restData,
    ...(filterByCondition && { filterByCondition }),
    createdAt: restData.createdAt ? parseISO(restData.createdAt) : undefined,
    updatedAt: restData.updatedAt ? parseISO(restData.updatedAt) : undefined,
  };
}

export const rulesResponse = ({ data }: AxiosResponse): GetRulesReturnType => {
  const merchandisingRulesMapped = data.merchandisingRules.map(mapAPIRule);
  return {
    merchandisingRules: merchandisingRulesMapped,
    pagination: {
      totalCount: data.pagination.totalCount || 0,
    },
  };
};

export const mapOverview = ({ data }: AxiosResponse): MerchandisingRuleTypes.Overview => {
  return {
    kpis: data.kpis || [],
    products: data.products || [],
    actions: data.actions || [],
  };
};

export const ruleResponse = ({ data }: AxiosResponse): MerchandisingRuleTypes.MerchandiseRule => {
  return mapAPIRule(data);
};
