import { Dispatch } from '../types';

export interface SideNavRoute {
  title: string;
  url: string;
  pathTemplate: string;
  icon?: JSX.Element;
  className?: string;
  wrapperClassName?: string;
  group?: SideNavGroups;
}

export enum SideNavGroups {
  WebsiteExperiences = 'Experiences',
  Merchandiser = 'Merchandiser',
  Experiments = 'Experiments',
  TagsManagement = 'Data Management',
  ShopSettings = 'Shop Settings',
}

export interface SideNavRouteGroup extends SideNavRoute {
  subRoutes?: SideNavRoute[];
}

export type ISideNavItem = SideNavRoute | SideNavRouteGroup;

export interface SideNavItemProps {
  item: ISideNavItem;
  onNavigate?: (url: string) => void;
  onRouteGroupClick?: () => void;
  isMinimized?: boolean;
}
export interface SideNavArguments {
  dispatch: Dispatch;
  routes: ISideNavItem[];
  className?: string;
  showShopSearch?: boolean;
  isMinimized: boolean;
  setMinimized: () => void;
  children?: JSX.Element;
  isLoading?: boolean;
}
