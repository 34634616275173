import { ExperimentSlim } from 'src/services';

export enum ExperimentListActionTypes {
  GetExperimentsRequest = 'ExperimentList/GetExperimentsRequest',
  GetExperimentsSuccess = 'ExperimentList/GetExperimentsSuccess',
  GetExperimentsError = 'ExperimentList/GetExperimentsError',
}

export interface ExperimentListActionPayload {
  shopId: number;
  experiments?: ExperimentSlim[];
}

export interface ExperimentListErrorActionPayload {
  error: unknown;
}

export interface ExperimentListGetExperimentsRequestAction {
  type: typeof ExperimentListActionTypes.GetExperimentsRequest;
  payload: ExperimentListActionPayload;
}

export interface ExperimentListGetExperimentsSuccessAction {
  type: typeof ExperimentListActionTypes.GetExperimentsSuccess;
  payload: ExperimentListActionPayload;
}

export interface ExperimentListGetExperimentsErrorAction {
  type: typeof ExperimentListActionTypes.GetExperimentsError;
  payload: ExperimentListErrorActionPayload;
}

export type ExperimentListAction =
  | ExperimentListGetExperimentsRequestAction
  | ExperimentListGetExperimentsSuccessAction
  | ExperimentListGetExperimentsErrorAction;
