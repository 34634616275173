import React from 'react';
import { Button, ConfirmationDialog } from 'src/components-dummy';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

interface DirtyFormConfirmationDialogProps {
  isOpened: boolean;
  close: () => void;
  isLoading: boolean;
  onSaveAndProceed: () => void;
  onResetDraftAndProceed: () => void;
  action: Action;
}

export enum Action {
  Generate = 'generate',
  AcceptTags = 'accept-tags',
  RejectTags = 'reject-tags',
}

const actionToText: Record<Action, string> = {
  [Action.Generate]: 'generating new suggestions',
  [Action.AcceptTags]: 'applying suggestions',
  [Action.RejectTags]: 'removing suggestions',
};

export const DirtyFormConfirmationDialog = ({
  isLoading,
  onResetDraftAndProceed,
  onSaveAndProceed,
  isOpened,
  close,
  action,
}: DirtyFormConfirmationDialogProps): JSX.Element | null => {
  return isOpened ? (
    <ConfirmationDialog onCancel={close}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Hold on</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        You have unsaved changes in your gallery that will be lost if you proceed. Do you want to
        save before {actionToText[action]}?
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onSaveAndProceed} loading={isLoading}>
            Save and Continue
          </Button>
          <Button variant='outlined' onClick={onResetDraftAndProceed}>
            Proceed Without Saving
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  ) : null;
};
