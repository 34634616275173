import io from 'socket.io-client';
import { WS_CONFIG } from '../common/configuration';

export type ClearListener = () => void;

export class WebSocketService {
  private socket: ReturnType<typeof io>;

  isConnected = false;

  constructor(namespace: string) {
    const targetUri = `${WS_CONFIG.BACKEND_WS_DOMAIN}/${namespace}`;

    this.socket = io(targetUri, {
      path: WS_CONFIG.BACKEND_WS_GATEWAY,
      reconnection: true,
      transports: ['websocket'],
      secure: WS_CONFIG.IS_SECURE,
      autoConnect: false,
    });

    this.socket.on('connect', this.onConnect);
    this.socket.on('disconnect', this.onDisconnect);
  }

  private onConnect(): void {
    this.isConnected = !!this.socket?.connected;
  }

  public getClientId(): string {
    return this.socket.id;
  }

  private onDisconnect(): void {
    this.isConnected = false;
  }

  connect(): void {
    this.socket.connect();
  }

  disconnect(): void {
    this.socket.off();
    this.socket.close();
  }

  initOnMessage<Data = unknown>({
    topicKey,
    onMessage,
  }: {
    topicKey: string;
    onMessage: (data: Data) => void;
  }): ClearListener {
    const listener = (data: Data) => {
      try {
        onMessage(data);
      } catch (error) {
        console.error(error);
      }
    };
    this.socket.on(topicKey as string, listener);

    return () => {
      this.removeListener(topicKey, listener);
    };
  }

  removeListenersByNames(eventNames: string[]): void {
    eventNames.forEach(eventName => {
      this.socket.off(eventName);
    });
  }

  removeListener<T = unknown>(eventName: string, listener: (payload: T) => void): void {
    this.socket.off(eventName, listener);
  }

  postMessage<T = unknown>({ topicKey, data }: { topicKey: string; data: T }): void {
    this.socket.volatile.emit(topicKey, data);
  }
}
