import { AxiosResponse } from 'axios';
import { RecentlyViewedSettingsTypes } from '../types';

export const recentlyViewedSettingsResponse = ({
  data,
}: AxiosResponse): RecentlyViewedSettingsTypes.RecentlyViewedSettings => {
  return {
    numberOfResultsToDisplay: data.numberOfResultsToDisplay,
    numberOfRecentDaysToDisplay: data.numberOfRecentDaysToDisplay,
    showOutOfStockItems: data.showOutOfStockItems,
    showPreviouslyPurchasedItems: data.showPreviouslyPurchasedItems,
    addToCart: data.addToCart,
  };
};
