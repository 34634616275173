import { Store } from 'redux';
import { NotificationType } from 'src/components-dummy';
import { toastActions } from 'src/containers';
import { successActionsBlackList } from './actions-blacklist';
import { AnySuccessAction } from './types';
import { getSuccessMessageKey } from './util';

export function successActionsHandler(action: AnySuccessAction, store: Store): void {
  const shouldCreateToast = !successActionsBlackList.has(action.type);
  const messageKey = shouldCreateToast ? getSuccessMessageKey(action) : '';
  if (messageKey) {
    store.dispatch(
      toastActions.enqueue({
        toast: {
          messageKey,
          type: NotificationType.Success,
          timestamp: new Date().getTime(),
          customMessage: action.payload?.customMessage,
        },
      })
    );
  }
}
