import React, { useMemo } from 'react';
import {
  FiltersList,
  EditableFilterItem,
  FilterItemDBStructure,
  SelectFiltersOrderMethod,
} from 'src/components-bl';
import { AvailableIcons, NumericInput, Switch, TooltipedFormField } from 'src/components-dummy';
import { DeepTagsFiltersConfigProps, DeepTagsFiltersConfig, OrderMethod } from './types';
import './DeepTagsFilters.scss';
import { useMergedDeepTagItems } from './use-merged-deeptag-items';
import { useDeeptagsSettingsForm } from './use-deeptags-settings-form';
import { processListFormOutput } from './process-form-output';

export const DeepTagsFilters = ({
  onSubmitDispatcher,
  shopId,
  filterSet,
  isDirtyDispatcher,
  rawDataFields,
  unSavedDataFields,
  dispatch,
  updateStatus,
  formApiRef,
  onSubmitStatusChange,
}: DeepTagsFiltersConfigProps): JSX.Element => {
  const { isDirty, settingsForm, onChange, errors } = useDeeptagsSettingsForm(
    filterSet,
    updateStatus
  );
  const mergedDeepTagItems = useMergedDeepTagItems(filterSet?.deepTags.items, shopId, dispatch);
  const onSubmit = (formOutput: EditableFilterItem[], diff: FilterItemDBStructure[]) => {
    const hasErrors = Object.values(errors).some(err => err.length);
    if (hasErrors) return;
    const deepTags: DeepTagsFiltersConfig = {
      settings: settingsForm,
      items: processListFormOutput(
        formOutput,
        diff,
        settingsForm?.order === OrderMethod.manual,
        filterSet.deepTags.items
      ),
    };
    onSubmitDispatcher({
      shopId,
      data: { ...filterSet, deepTags },
    });
  };
  const generatedFiltersWarningText = useMemo(() => {
    return (
      <span>
        Show Syte generated filters is set to off.
        <br />
        These filters won&apos;t be visible on your website.
      </span>
    );
  }, []);

  return mergedDeepTagItems.length ? (
    <FiltersList
      onSubmit={onSubmit}
      itemsList={mergedDeepTagItems}
      isDragAndDropEnabled={settingsForm?.order === OrderMethod.manual}
      messages={{
        mainTitle: {
          text: 'Syte Generated Filters',
          tooltip: {
            enabled: !settingsForm?.enabled,
            text: generatedFiltersWarningText,
            icon: AvailableIcons.Warning,
          },
        },
        disable: { title: 'Syte Generated Filter Visibility' },
      }}
      isDirtyDispatcher={isDirtyDispatcher}
      updateStatus={updateStatus}
      hasExternalDiff={isDirty}
      listActionOptions={{
        allowAddItem: false,
        allowDeleteItem: false,
        allowDisableAll: true,
        uniqueFields: ['displayNameKey'],
      }}
      externalChanges={settingsForm}
      counterFormatter={(total, active) =>
        `Total syte generated filters: ${total} (${active} enabled)`
      }
      rawDataFields={rawDataFields}
      unSavedDataFields={unSavedDataFields}
      shouldIncludeDisplayName={false}
      formApiRef={formApiRef}
      onSubmitStatusChange={onSubmitStatusChange}
    >
      <FiltersList.AdditionalSettings>
        <Switch
          className='enable-deep-tags-toggle'
          checked={settingsForm?.enabled}
          onChange={enabled => onChange('enabled', enabled)}
        >
          <Switch.TitleTemplate>Show Syte Generated Filters</Switch.TitleTemplate>
        </Switch>
        <SelectFiltersOrderMethod
          orderMethod={settingsForm?.order}
          dispatcher={order => onChange('order', order)}
          listType='deeptags'
        />
        <Switch
          className='enable-min-buckets-toggle'
          checked={settingsForm?.enableMinBuckets}
          onChange={enableMinBuckets => onChange('enableMinBuckets', enableMinBuckets)}
        >
          <Switch.TitleTemplate>
            Limit number of displayed filters by results count
          </Switch.TitleTemplate>
        </Switch>
        {settingsForm?.enableMinBuckets ? (
          <TooltipedFormField
            label='Minimum results count to display filter'
            className='min-buckets-count'
            error={errors.minBuckets}
          >
            <NumericInput
              value={settingsForm?.minBuckets}
              onChange={minBuckets => onChange('minBuckets', minBuckets)}
            />
          </TooltipedFormField>
        ) : null}
      </FiltersList.AdditionalSettings>
    </FiltersList>
  ) : (
    <></>
  );
};
