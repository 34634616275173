import React from 'react';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

interface DeleteGalleryConfirmationDialogProps {
  onCloseDeleteDialog: () => void;
  onDelete: () => void;
  galleryName: string;
}

export const DeleteGalleryConfirmationDialog = ({
  onCloseDeleteDialog,
  onDelete,
  galleryName,
}: DeleteGalleryConfirmationDialogProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onCloseDeleteDialog}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Delete Gallery</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        {`Are you sure you want to delete \`${galleryName}\` gallery?`}
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='destructive' onClick={onDelete}>
            Delete
          </Button>
          <Button variant='tertiary' onClick={onCloseDeleteDialog}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
