import styled from '@emotion/styled';
import { Icon, Typography } from 'src/components-dummy';

export const LocaleItemStyled = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 1em;
  width: fit-content;
`;

export const LocaleIconStyled = styled(Icon)`
  width: 2em !important;
`;

export const LocaleTextStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-30']};
`;

export const DeleteIconStyled = styled(Icon)`
  width: 0.8em;
`;
