import React, { useMemo } from 'react';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

const PREFIX_TEXT = 'Are you sure you want to replace the current Primary catalog for';

interface ChangePrimaryConfirmationDialogProps {
  onCloseDialog: () => void;
  onApproveSubmit: () => void;
  occupiedVerticals: string[];
}

export const ChangePrimaryConfirmationDialog = ({
  onCloseDialog,
  onApproveSubmit,
  occupiedVerticals,
}: ChangePrimaryConfirmationDialogProps): JSX.Element => {
  const text = useMemo(() => {
    if (occupiedVerticals.length === 1) {
      return (
        <div>
          {PREFIX_TEXT} <b>{occupiedVerticals[0]}</b> department?
        </div>
      );
    }

    const elements = occupiedVerticals.map((vertical, index) => (
      <React.Fragment key={vertical}>
        {index > 0 && ', '}
        <b>{vertical}</b>
      </React.Fragment>
    ));

    return (
      <div>
        {PREFIX_TEXT} {elements} departments?
      </div>
    );
  }, [occupiedVerticals]);

  return (
    <ConfirmationDialog onCancel={onCloseDialog}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Hold on</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>{text}</ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onApproveSubmit}>
            Continue
          </Button>
          <Button variant='outlined' onClick={onCloseDialog}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
