import React from 'react';
import { usePreviewModal } from './hooks';
import { AvailableIcons, Button } from '../../components-dummy';
import { PermittedRouteMap } from '../../app-routes';

interface Props {
  shopId?: number;
  permittedRouteMap: PermittedRouteMap;
}

export const VisualEditorButton = ({ shopId, permittedRouteMap }: Props): JSX.Element | null => {
  const { openModal } = usePreviewModal({ shopId, permittedRouteMap });

  if (!shopId) return null;

  return (
    <Button
      variant='secondary'
      className='preview-button'
      startIcon={AvailableIcons.PreviewButton}
      onClick={openModal}
    >
      Visual Editor
    </Button>
  );
};
