import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { DeepTagReportsService } from 'src/services';

const deepTagReportsService = new DeepTagReportsService();

export interface GetDeepTagReportsActionArguments {
  shopId: number;
}

export const deepTagReportsListActions = {
  getReports: createAsyncThunk(
    'DeepTagReportsList/GetReports',
    async ({ shopId }: GetDeepTagReportsActionArguments, { rejectWithValue }) => {
      try {
        const tagsReports = await deepTagReportsService.getReports(shopId);
        return { tagsReports };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  resetReports: createAction('DeepTagReportsList/ResetReports'),
};
