import { EditExperimentContainerAction } from 'src/containers';
import { SyteProductType } from 'src/services/src/service/types/syte-products/syte-products-types';
import { RankingStrategyReducerState } from '../app-state-types';
import {
  RankingStrategyAction,
  RankingStrategyActionTypes,
  VariantPanelAction,
} from '../components-bl';

import { RankingType } from '../components-bl/RankingStrategy/RankingStrategyType/types';

const initialState: RankingStrategyReducerState = {
  weights: {
    [SyteProductType.AugmentedSearch]: undefined,
    [SyteProductType.SimilarItems]: undefined,
    [SyteProductType.ShopTheLook]: undefined,
    [SyteProductType.VisualSearch]: undefined,
    [SyteProductType.DiscoveryButton]: undefined,
    [SyteProductType.Personalization]: undefined,
    [SyteProductType.Collections]: undefined,
  },
  selectedSyteProduct: undefined,
  type: RankingType.WeightedRanking,
  shouldRefetch: false,
};

export function rankingStrategyReducer(
  state: RankingStrategyReducerState = initialState,
  action?: RankingStrategyAction | EditExperimentContainerAction | VariantPanelAction
): RankingStrategyReducerState {
  switch (action?.type) {
    case RankingStrategyActionTypes.GetWeightsSuccess: {
      return {
        ...state,
        weights: { ...state.weights, [action.payload.syteProduct]: action.payload.weights },
        ...(action.payload?.type && { type: action.payload.type }),
        shouldRefetch: false,
      };
    }
    case RankingStrategyActionTypes.SetSelectedSyteProduct:
      return {
        ...state,
        selectedSyteProduct: action.payload,
        weights: {
          ...state.weights,
          [action.payload]: undefined,
        },
      };
    case RankingStrategyActionTypes.GetWeightsRequest:
      return {
        ...state,
        weights: {
          ...state.weights,
          [state.selectedSyteProduct]: undefined,
        },
      };
    case RankingStrategyActionTypes.GetWeightsError:
      return { ...state, weights: { ...state.weights } };
    case RankingStrategyActionTypes.UpdateRankingWeightsSuccess: {
      const { weights, syteProduct, type } = action.payload;
      const updatedWeights = { ...state.weights, [syteProduct]: weights };
      return { ...state, weights: updatedWeights, ...(type && { type }) };
    }
    default:
      return state;
  }
}
