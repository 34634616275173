import styled from '@emotion/styled';
import { Icon, MenuItem } from 'src/components-dummy';

export const MenuActionsStyled = styled.div`
  display: flex;
  justify-content: end;

  .MuiButtonBase-root {
    height: 32px;
    align-items: end !important;
  }
`;

export const MenuItemStyled = styled(MenuItem)``;

export const ActionIconStyled = styled(Icon)`
  margin-right: 10px;
  width: 15px;
`;
