import React from 'react';
import { MultipleSelectFilterTag } from './MultipleSelectFilterTag';
import { BooleanFilterTag } from './BooleanFilterTag';
import { MenuFilterTag } from './MenuFilterTag';
import { SearchInputStyled } from '../FiltersRow.styles';
import {
  FilterTagFactoryProps,
  SearchInputFilterTagConfig,
  BooleanFilterTagConfig,
  MultiSelectFilterTagConfig,
  MultiSelectFilterTagProps,
  MenuFilterTagConfig,
  MenuFilterTagProps,
  FilterVariant,
  DateRangeFilterTagConfig,
  DateRangeFilterTagProps,
} from '../types';
import { DateRangeFilterTag } from './DateRangeFilterTag';

export const FilterTagFactory = ({
  componentConfig,
  type,
  filterKey,
  filterState,
  onChange,
  onRemoveFilter,
  onCloseFilter,
  shouldTriggerOpen,
  menuName,
  tagPrefix,
}: FilterTagFactoryProps): JSX.Element | null => {
  if (!filterState) {
    return null;
  }

  const baseProps = {
    onRemoveFilter,
    onCloseFilter,
    shouldTriggerOpen,
    filterKey,
    filterState,
    onChange,
    menuName,
    tagPrefix,
  };

  let component = null;

  if (type === FilterVariant.SearchInput) {
    const searchInputConfig = componentConfig as SearchInputFilterTagConfig;

    const onSearchChange = (input: string) => {
      onChange({
        [filterKey]: {
          isApplied: true,
          value: input,
        },
      });
    };

    component = (
      <SearchInputStyled
        value={(filterState.value as any) || ''}
        onChange={onSearchChange}
        placeholder={searchInputConfig.placeholder}
      />
    );
  } else if (type === FilterVariant.Boolean) {
    const booleanConfig = componentConfig as BooleanFilterTagConfig;

    const booleanProps = { ...booleanConfig, ...baseProps };

    component = <BooleanFilterTag {...booleanProps} />;
  } else if (type === FilterVariant.MultiSelect) {
    const multiSelectConfig = componentConfig as MultiSelectFilterTagConfig;

    const multiSelectProps = { ...multiSelectConfig, ...baseProps } as MultiSelectFilterTagProps;

    component = <MultipleSelectFilterTag {...multiSelectProps} />;
  } else if (type === FilterVariant.Menu) {
    const menuConfig = componentConfig as MenuFilterTagConfig;

    const menuProps = { ...menuConfig, ...baseProps } as MenuFilterTagProps;

    component = <MenuFilterTag {...menuProps} />;
  } else if (type === FilterVariant.DateRange) {
    const menuConfig = componentConfig as DateRangeFilterTagConfig;

    const menuProps = { ...menuConfig, ...baseProps } as DateRangeFilterTagProps;

    component = <DateRangeFilterTag {...menuProps} />;
  }

  return component;
};
