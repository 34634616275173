import styled from '@emotion/styled';
import { ConfirmationDialog, Typography } from 'src/components-dummy';
import { FileUploader } from 'src/components-dummy/FileUploader';

export const FileUploaderCSVStyled = styled(FileUploader.CSV)`
  margin-top: 48px;
`;

export const UploadProgressStyled = styled(Typography)`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const UploadConfirmationDialogStyled = styled(ConfirmationDialog)`
  width: 600px;
`;
