import React, { ReactElement } from 'react';
import { useAppSelector } from '../../../../hooks';
import { ExperienceFeature } from './Experience';

type ExperienceChild = ReactElement<ExperienceFeature>;

interface SwitchProps {
  children: ExperienceChild[];
}

export const Switch: React.FC<SwitchProps> = ({ children }): JSX.Element | null => {
  const experience = useAppSelector(({ visualEditor }) => visualEditor.selectedExperience);
  const childrenArray = React.Children.toArray(children) as ExperienceChild[];

  for (const child of childrenArray) {
    if (React.isValidElement(child) && child.props.feature === experience) {
      return child;
    }
  }

  return null;
};
