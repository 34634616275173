import Joi from 'joi';

export const validationSchema = {
  dataFieldsToDisplay: Joi.array().items(
    Joi.object({
      id: Joi.string().allow(''),
      value: Joi.string().min(1).messages({ 'string.empty': 'value should not be empty' }),
      position: Joi.number(),
    })
  ),
  specialDataFieldsToDisplay: Joi.array(),
  uniqueByField: Joi.string(),
  itemsCountPerRow: Joi.number(),
};
