import React, { useCallback, useState } from 'react';
import { AvailableIcons, TypographyType, TypographyVariant } from 'src/components-dummy';
import { RadioButton } from 'src/components-dummy/RadioGroup';
import { Dispatch } from 'src/components-bl/types';
import { FilterDataSource } from 'src/services';
import {
  BellIconStyled,
  DataSourceSelectionTitlesWrapper,
  SectionBoxStyled,
  SelectionTypography,
  BellTooltipStyled,
} from './DataSourceSection.styles';
import { filtersFormActions } from '../../Actions';

interface SourceRadioSelectionProps {
  checked: boolean;
  dataSource: FilterDataSource;
  onChange: (newChecked: boolean) => void;
  disabled: boolean;
  enableNotificationBell?: boolean;
  dispatch: Dispatch;
}

const titleTranslations: Record<FilterDataSource, { title: string; subTitle: string }> = {
  [FilterDataSource.Catalog]: {
    title: 'Use Catalog Fields',
    subTitle: 'Select this option to base the filter on your catalogue’s indexed fields',
  },
  [FilterDataSource.DeepTags]: {
    title: 'Syte’s AI Tags',
    subTitle: 'Big things are coming. Stay tuned!',
  },
};

const notificationMessage = 'We will remember you soon!';
export const SourceRadioSelection = ({
  checked,
  onChange,
  dataSource,
  disabled,
  enableNotificationBell = false,
  dispatch,
}: SourceRadioSelectionProps): JSX.Element => {
  const [notificationClicked, setNotificationClicked] = useState(false);

  const onClick = () => {
    if (!disabled) {
      onChange(!checked);
    }
  };

  const onNotificationClick = useCallback(() => {
    if (!notificationClicked) {
      dispatch(filtersFormActions.notification({ customMessage: notificationMessage }));
    }
    setNotificationClicked(true);
  }, [dispatch, setNotificationClicked, notificationClicked]);

  return (
    <SectionBoxStyled onClick={onClick} disabled={disabled}>
      <RadioButton checked={checked} value={dataSource} disabled={disabled} />
      <DataSourceSelectionTitlesWrapper>
        <SelectionTypography
          type={TypographyType.Button}
          variant={TypographyVariant.MediumMedium}
          grayedOut={disabled}
        >
          {titleTranslations[dataSource].title}
        </SelectionTypography>
        <SelectionTypography
          type={TypographyType.Paragraph}
          variant={TypographyVariant.SmallRegular}
          grayedOut
        >
          {titleTranslations[dataSource].subTitle}
        </SelectionTypography>
      </DataSourceSelectionTitlesWrapper>

      {enableNotificationBell && (
        <BellTooltipStyled value={notificationClicked ? notificationMessage : 'Notify me'}>
          <BellIconStyled
            onClick={onNotificationClick}
            name={AvailableIcons.Bell}
            notificationClicked={notificationClicked}
          />
        </BellTooltipStyled>
      )}
    </SectionBoxStyled>
  );
};
