export const sliderMarks = [
  { value: 50, label: '50' },
  { value: 100 },
  { value: 150 },
  { value: 200 },
  { value: 250, label: '250' },
  { value: 300 },
  { value: 350 },
  { value: 400 },
  { value: 450 },
  { value: 500, label: '500' },
  { value: 550 },
  { value: 600 },
  { value: 650 },
  { value: 700 },
  { value: 750, label: '750' },
  { value: 800 },
  { value: 850 },
  { value: 900 },
  { value: 950 },
  { value: 1000, label: '1000' },
];
