import Joi from 'joi';
import { getStringEnumValues } from 'src/utils';
import { ShopCatalogVertical } from 'src/services';

export const validationSchema = {
  name: Joi.string().required().trim().min(2).max(500).messages({
    'string.empty': 'Name can not be empty',
    'string.min': 'Name should be at least 2 characters long',
    'string.max': 'Name should be less then 500 characters long',
  }),
  isPrimary: Joi.boolean().required(),
  isAugmentedSearchCatalog: Joi.boolean().required(),
  verticals: Joi.array()
    .items(Joi.string().valid(...getStringEnumValues(ShopCatalogVertical)))
    .min(1)
    .messages({
      'array.min': 'Please select at least one supported vertical',
    }),
};
