import {
  GetShopperExperiencesErrorAction,
  GetShopperExperiencesErrorPayload,
  GetShopperExperiencesRequestAction,
  GetShopperExperiencesRequestPayload,
  GetShopperExperiencesSuccessAction,
  GetShopperExperiencesSuccessPayload,
  ShopperExperiencesActionTypes,
} from './types';
import type { Dispatch } from '../../types';

import { shopperExperiencesService } from '../../../services/src/service/shopper-experiences';

const ShopperExperiencesActions = {
  getShopperExperiencesRequest(
    payload: GetShopperExperiencesRequestPayload
  ): GetShopperExperiencesRequestAction {
    return {
      type: ShopperExperiencesActionTypes.GetShopperExperiencesRequest,
      payload,
    };
  },

  getShopperExperiencesSuccess(
    payload: GetShopperExperiencesSuccessPayload
  ): GetShopperExperiencesSuccessAction {
    return {
      type: ShopperExperiencesActionTypes.GetShopperExperiencesSuccess,
      payload,
    };
  },

  getShopperExperiencesError(
    payload: GetShopperExperiencesErrorPayload
  ): GetShopperExperiencesErrorAction {
    return {
      type: ShopperExperiencesActionTypes.GetShopperExperiencesError,
      payload,
    };
  },
};

export const shopperExperiencesActionsMethods = {
  getShopperExperiences(payload: GetShopperExperiencesRequestPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(ShopperExperiencesActions.getShopperExperiencesRequest(payload));
        const experiences = await shopperExperiencesService.getShopperExperiences(payload);
        dispatch(
          ShopperExperiencesActions.getShopperExperiencesSuccess({
            shopId: payload.shopId,
            experiences,
          })
        );
      } catch (error) {
        dispatch(ShopperExperiencesActions.getShopperExperiencesError({ error }));
      }
    };
  },
};
