import React, { ReactNode } from 'react';
import { useFindComponentByType } from '../hooks';
import { MenuItem } from '../MenuItem';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import { MenuListStyled, EllipsisWithTooltipStyled } from './MenuList.styles';
import { MenuListOption } from './types';

const ITEMS_TO_SHOW_WITHOUT_SCROLL = 6;

const OptionTemplate = ({ children }: { children: ReactNode[] | ReactNode }): JSX.Element => (
  <>{children}</>
);

interface MenuListProps<T = string> {
  options: MenuListOption<T>[];
  onItemClick?: (selectedValue: T) => void;
  children?: JSX.Element | JSX.Element[];
  className?: string;
}

export function MenuList<T = string>({
  options,
  children,
  onItemClick,
  className,
}: MenuListProps<T>): JSX.Element {
  const customOptionTemplate = useFindComponentByType({ type: OptionTemplate, children })?.props
    .children;

  return (
    <MenuListStyled
      className={className}
      enableScroll={options.length > ITEMS_TO_SHOW_WITHOUT_SCROLL}
    >
      {options.map(option => {
        return (
          <MenuItem
            key={option.key || option.value.toString()}
            onClick={() => onItemClick?.(option.value)}
          >
            {customOptionTemplate ? (
              customOptionTemplate(option)
            ) : (
              <>
                <EllipsisWithTooltipStyled tooltipText={option.text}>
                  <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                    {option.text}
                  </Typography>
                </EllipsisWithTooltipStyled>
              </>
            )}
          </MenuItem>
        );
      })}
    </MenuListStyled>
  );
}

MenuList.OptionTemplate = OptionTemplate;
