import { ICatalogTableHeadRow } from './types';

export const tableColumns: Record<string, ICatalogTableHeadRow> = {
  name: {
    Header: 'Catalogue Name',
    accessor: 'name',
    minWidth: 75,
  },
  shouldShowAlert: {
    Header: '',
    accessor: 'shouldShowAlert',
    width: 20,
    maxWidth: 20,
    canResize: false,
  },
  lastUpdated: {
    Header: 'Last Updated',
    accessor: 'lastUpdated',
  },
  vertical: {
    Header: 'Vertical',
    accessor: 'vertical',
  },
  isPrimary: {
    Header: 'Feed Type',
    accessor: 'isPrimary',
  },
  numberOfIndexedProducts: {
    Header: 'Unique SKUs',
    accessor: 'numberOfIndexedProducts',
  },
  rowActions: {
    Header: '',
    accessor: 'rowActions',
    width: 20,
    maxWidth: 20,
    canResize: false,
  },
};
