import { ItemValidityMap } from 'src/components-bl/helpers';
import { AutoExpandFilters, FilterSetSettings } from 'src/services/src/service/types/filters';

export enum FieldsToTriggerReset {
  collapseBuckets = 'collapseBuckets',
  collapseList = 'collapseList',
  expand = 'expand',
}
enum FieldsToApplyReset {
  collapseBucketsAfter = 'collapseBucketsAfter',
  collapseListAfter = 'collapseListAfter',
  expandCount = 'expandCount',
}

const fieldDependencyMap: Record<FieldsToTriggerReset, FieldsToApplyReset> = {
  [FieldsToTriggerReset.collapseBuckets]: FieldsToApplyReset.collapseBucketsAfter,
  [FieldsToTriggerReset.collapseList]: FieldsToApplyReset.collapseListAfter,
  [FieldsToTriggerReset.expand]: FieldsToApplyReset.expandCount,
};

export type ValueTypeToTriggerReset = boolean | AutoExpandFilters;
const valuesToTriggerReset: ValueTypeToTriggerReset[] = [
  false,
  AutoExpandFilters.All,
  AutoExpandFilters.None,
];

export const shouldResetValue = (
  updatedField: FieldsToTriggerReset,
  updatedValue: ValueTypeToTriggerReset,
  errors: ItemValidityMap<FilterSetSettings>
): FieldsToApplyReset | undefined => {
  const fieldToMaybeReset: FieldsToApplyReset = fieldDependencyMap[updatedField];
  if (
    fieldToMaybeReset &&
    valuesToTriggerReset.includes(updatedValue) &&
    errors[fieldToMaybeReset]
  ) {
    return fieldToMaybeReset;
  }
  return undefined;
};
