import { DiffActionType } from 'src/app-types/enums/versioning';
import { DiffAPIResponse, DomainEntityPath, ShopTypes, RankingStrategyTypes } from '../types';
import { mockMerchandisingRule } from '../merchandising-rules';
import { mockRankingWeight } from '../ranking';

const mockNewMerchandisingRule = {
  ...mockMerchandisingRule,
  subRules: [
    {
      enabled: true,
      field: 'inStock',
      subType: ShopTypes.MerchandisingRuleSubType.HasValue,
      values: [],
    },
    {
      enabled: true,
      field: 'gender',
      subType: ShopTypes.MerchandisingRuleSubType.Equals,
      values: ['woman'],
    },
    {
      enabled: true,
      field: 'brand',
      subType: ShopTypes.MerchandisingRuleSubType.Contains,
      values: ['Adidas', 'Nike'],
    },
    {
      enabled: true,
      field: 'amount',
      subType: ShopTypes.MerchandisingRuleSubType.HigherThan,
      values: [100, null],
    },
    {
      enabled: true,
      field: 'price',
      subType: ShopTypes.MerchandisingRuleSubType.IsBetween,
      values: [50, 100],
    },
  ],
} as ShopTypes.MerchandiseRule;

const mockNewRankingWeight = {
  ...mockRankingWeight,
  weight: mockRankingWeight.weight + 10,
  order: RankingStrategyTypes.RankingOrder.Desc,
} as RankingStrategyTypes.RankingStrategyWeight;

const userObject = {
  email: 'nadav.y@syte-vc.com',
  userId: 123,
};

export const mockMerchandisingRuleDiffs: DiffAPIResponse[] = [
  {
    domain: DomainEntityPath.MerchandisingRules,
    parentDomain: DomainEntityPath.MerchandisingRules,
    entityId: mockMerchandisingRule.id,
    actionType: DiffActionType.Changed,
    changedAt: '2022-03-08T08:18:14.083Z',
    lastChangedBy: userObject,
    oldEntity: mockMerchandisingRule,
    newEntity: mockNewMerchandisingRule,
    changes: [
      {
        valuePath: 'weight',
        oldValue: mockMerchandisingRule.weight,
        newValue: mockMerchandisingRule.weight - 10,
        changedBy: userObject,
        changedAt: '2022-03-04T03:04:01.083Z',
      },
      {
        valuePath: 'name',
        oldValue: mockMerchandisingRule.name,
        newValue: 'Augmented rule',
        changedBy: userObject,
        changedAt: '2022-03-04T03:04:01.083Z',
      },
      {
        valuePath: 'subRules',
        // oldValue: `Include products where Brand Equals “Adidas” AND Except for products where Price Equals “100” AND Include products where Brand Equals “Nike” AND Except for products where Price Higher Than “100”`,
        // newValue:
        //   'Include products where Brand Equals “Adidas” AND Except for products where Brand Equals “200” AND Include products where Brand Equals “Nike” AND Except for products where Price Higher Than “100”',
        oldValue: mockMerchandisingRule.subRules,
        newValue: mockNewMerchandisingRule.subRules,
        changedBy: userObject,
        changedAt: '2022-03-04T03:04:01.083Z',
      },
    ],
  },
  {
    domain: DomainEntityPath.MerchandisingRules,
    parentDomain: DomainEntityPath.MerchandisingRules,
    entityId: mockMerchandisingRule.id,
    actionType: DiffActionType.Deleted,
    changedAt: '2022-03-08T08:18:14.083Z',
    lastChangedBy: userObject,
    newEntity: mockMerchandisingRule,
    oldEntity: mockMerchandisingRule,
    changes: [],
  },
];

export const mockRankingDiffs: DiffAPIResponse[] = [
  {
    domain: DomainEntityPath.AugmentedSearchRanking,
    parentDomain: DomainEntityPath.AugmentedSearch,
    entityId: mockRankingWeight.field,
    actionType: DiffActionType.Changed,
    changedAt: '2021-03-08T08:18:14.083Z',
    lastChangedBy: userObject,
    oldEntity: mockRankingWeight,
    newEntity: mockNewRankingWeight,
    changes: [
      {
        valuePath: 'weight',
        oldValue: mockRankingWeight.weight,
        newValue: mockNewRankingWeight.weight,
        changedBy: userObject,
        changedAt: '2022-03-04T03:04:01.083Z',
      },
      {
        valuePath: 'order',
        oldValue: mockRankingWeight.order,
        newValue: mockNewRankingWeight.order,
        changedBy: userObject,
        changedAt: '2022-03-04T03:04:01.083Z',
      },
    ],
  },
  {
    domain: DomainEntityPath.AugmentedSearchRanking,
    parentDomain: DomainEntityPath.AugmentedSearch,
    entityId: mockRankingWeight.field,
    actionType: DiffActionType.Created,
    changedAt: '2021-03-08T08:18:14.083Z',
    lastChangedBy: userObject,
    newEntity: mockNewRankingWeight,
    changes: [],
  },
];
