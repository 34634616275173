import React, { useCallback } from 'react';
import { ConfirmationDialog, Button } from 'src/components-dummy';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';
import { MAX_RULES } from '../../constants';

const CONTACT_SUPPORT_URL = 'https://syte-ai.atlassian.net/servicedesk/customer/portal/5';

interface MaxMerchandisingRulesLimitDialogProps {
  onClose: () => void;
}

export function MaxMerchandisingRulesLimitDialog({
  onClose,
}: MaxMerchandisingRulesLimitDialogProps): JSX.Element {
  const onContactSupportButtonClick = useCallback(() => {
    window.open(CONTACT_SUPPORT_URL, '_blank');
  }, []);

  return (
    <ConfirmationDialog onCancel={onClose}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Rule Limit Reached</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        You have reached the maximum limit of {MAX_RULES} rules.
        <br />
        <br />
        To create more rules, you can either delete inactive rules or contact our support team to
        explore options for increasing the limit.
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onContactSupportButtonClick}>
            Contact Support
          </Button>
          <Button variant='outlined' onClick={onClose}>
            Close
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
}
