import { SideNavNames } from './types';

interface GetSideNavNameByPathArguments {
  pathname: string | undefined;
  accountsPath: string[];
}

const defaultSyteNavName = SideNavNames.Shop;

export const getSideNavNameByPath = ({
  pathname,
  accountsPath,
}: GetSideNavNameByPathArguments): SideNavNames => {
  if (!pathname) {
    return defaultSyteNavName;
  }

  if (accountsPath.some(path => pathname.startsWith(path))) {
    return SideNavNames.Account;
  }

  return defaultSyteNavName;
};
