import React, { useRef, useState, useEffect, useCallback, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { useIsAnimating } from 'src/hooks';
import './SidePanel.scss';
import { AvailableIcons, Icon } from '../Icon';

export interface SidePanelProps {
  className?: string;
  children: ReactNode;
  opened: boolean;
  onClose?: () => void;
  wide?: boolean;
  closeOnClickOutside?: boolean;
}

export const SidePanel = ({
  className,
  children,
  opened = false,
  wide,
  closeOnClickOutside,
  onClose,
}: SidePanelProps): JSX.Element | null => {
  const [panelIsMounted, setPanelIsMounted] = useState(opened);
  const ref = useRef<HTMLDivElement | null>(null);
  const [showContent, setShowContent] = useState(false);

  const onAnimationEnd = useCallback(() => {
    if (panelIsMounted && !showContent) {
      // close when animation ended and content is closed
      setPanelIsMounted(false);
    }
  }, [panelIsMounted, showContent]);

  useIsAnimating({
    ref,
    onAnimationEnd,
  });

  const onBackDropClick = closeOnClickOutside ? onClose : undefined;

  useEffect(() => {
    let timer: NodeJS.Timeout | null;

    if (opened) {
      // activate content state with small delay to trigger the transition
      setPanelIsMounted(true);
      timer = setTimeout(() => setShowContent(opened), 5);
    } else {
      // triggers animation
      setShowContent(false);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [opened]);

  const component = (
    <div
      className={classNames('syte-side-panel', className, { opened: showContent }, { wide })}
      ref={ref}
    >
      <div className='syte-side-panel-backdrop' onClick={onBackDropClick} />
      <div className='syte-side-panel-content'>
        <Icon
          name={AvailableIcons.Close}
          className='syte-side-panel-close-icon'
          onClick={onClose}
        />
        {children}
      </div>
    </div>
  );

  return panelIsMounted
    ? ReactDOM.createPortal(component, document.querySelector('[id="root"], body') as Element)
    : null;
};
