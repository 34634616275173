import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { cloneDeep } from 'lodash';

import { PinToPositionRule } from './PinToPositionRule';
import {
  isObjEmpty,
  matchObjByErrorPath,
  matchObjByErrorType,
} from '../../../../../merchandisingRules.helpers';
import { reduceErrorsByPrefix } from '../../../../../../../utils';
import { mapErrorMessages } from '../../../../../mapErrorMessages';

import { Dispatch } from '../../../../../../types';
import { RuleDraft, RuleDraftCondition } from '../../../MerchandisingRuleForm.config';
import { DataFieldConditionDataType } from '../../../../DataFieldsCondition/DataFieldsCondition.types';
import { DataFieldLookupTableValue } from '../../../../useDataFieldsLookupTable';
import { findPositionIndexToInsertAt, renumberPinnedPositions } from './helpers';
import { useFetchProducts } from './SkuAutoSuggestion/useFetchProducts';
import { MAX_POSITION_VALUE } from './constants';
import { SkuConfiguration } from 'src/components-bl/ProductCard/ProductCard';

interface DragAndDropRef {
  conditions: RuleDraftCondition[];
  requestedFrame: number | null;
  draggedItemTempId?: number | string;
}

interface Props {
  conditions: RuleDraftCondition[];
  onConditionChange: (changedCondition: DataFieldConditionDataType) => void;
  onDeleteCondition: (conditionToDelete: RuleDraftCondition) => void;
  getDataFieldByName: (name: string | undefined) => DataFieldLookupTableValue | undefined;
  onFieldChange: (
    data: Partial<Pick<RuleDraft, 'action' | 'weight' | 'subRules' | 'redirectRule'>>
  ) => void;
  dispatch: Dispatch;
  shopId: number;
  errors: any; // TODO
}

export interface PinToPositionApiHandle {
  fetchProducts: (ruleConditions: RuleDraftCondition[]) => void;
}

export const PinToPositionRulesInputs = forwardRef<PinToPositionApiHandle, Props>(
  (
    {
      conditions,
      onConditionChange,
      onDeleteCondition,
      getDataFieldByName,
      onFieldChange,
      dispatch,
      shopId,
      errors,
    },
    ref
  ): JSX.Element => {
    const { options, loading, uniqueByField, fetchProductsDebounced, resetOptions } =
      useFetchProducts({
        shopId,
        dispatch,
        initialLoading: true,
      });

    const productInformationLookup = useMemo(() => {
      if (!uniqueByField) {
        return {};
      }

      return options.reduce(
        (accumulator, current) => {
          const key = current.data?.[uniqueByField];

          if (key) {
            accumulator[key] = current.data as SkuConfiguration;
          }

          return accumulator;
        },
        {} as Record<string, SkuConfiguration>
      );
    }, [options, uniqueByField]);

    const [, forceRender] = useState({});
    const [selectedConditionId, setSelectedConditionId] = useState<number | string | undefined>();

    const dragAndDropRef = useRef<DragAndDropRef>({
      conditions: [...conditions],
      requestedFrame: null,
    });

    useEffect(() => {
      return () => {
        const { requestedFrame } = dragAndDropRef.current;

        if (requestedFrame !== null) {
          cancelAnimationFrame(requestedFrame);
        }
      };
    }, []);

    const updateDragAndDropRefByProperty = useCallback(
      <T extends keyof DragAndDropRef>(property: T, value: DragAndDropRef[T]) => {
        dragAndDropRef.current[property] = value;
      },
      []
    );

    const drawFrame = useCallback(() => {
      forceRender({});
      updateDragAndDropRefByProperty('requestedFrame', null);
    }, [forceRender, updateDragAndDropRefByProperty]);

    const scheduleUpdate = useCallback(() => {
      const { requestedFrame } = dragAndDropRef.current;

      if (!requestedFrame) {
        const animationFrame = requestAnimationFrame(drawFrame);
        updateDragAndDropRefByProperty('requestedFrame', animationFrame);
      }
    }, [drawFrame, updateDragAndDropRefByProperty]);

    useEffect(() => {
      updateDragAndDropRefByProperty('conditions', [...conditions]);
      scheduleUpdate();
    }, [conditions, updateDragAndDropRefByProperty, scheduleUpdate]);

    const onConditionBlur = useCallback(
      (conditionId?: string | number) => {
        if (conditionId === undefined) return;

        const targetConditionIndex = conditions.findIndex(
          condition => condition.tempId === conditionId
        );

        const targetCondition = conditions[targetConditionIndex];

        if (targetCondition.position === undefined) return;

        const conditionsCopy = cloneDeep(conditions);

        const newConditions = cloneDeep(conditions);

        newConditions.splice(targetConditionIndex, 1);

        const previousCondition = conditions[targetConditionIndex - 1];

        const isPositionDecreasing =
          previousCondition?.position !== undefined &&
          targetCondition.position < previousCondition.position;

        const insertAtIndex = findPositionIndexToInsertAt({
          conditions: newConditions,
          position: targetCondition.position,
          matchInclusive: isPositionDecreasing,
          fallbackIndex: targetConditionIndex,
        });

        newConditions.splice(insertAtIndex, 0, targetCondition);

        const renumberedConditions = renumberPinnedPositions({
          movedConditionId: conditionId,
          previousConditions: conditionsCopy,
          rearrangedConditions: newConditions,
          maxItemsLimit: MAX_POSITION_VALUE,
        });

        onFieldChange({ subRules: renumberedConditions });
      },
      [conditions, onFieldChange]
    );

    const onDrag = useCallback(
      (sourceIndex: number, targetIndex: number) => {
        const { conditions: rearrangedConditions } = dragAndDropRef.current;
        const conditionToMove = rearrangedConditions[sourceIndex];

        rearrangedConditions.splice(sourceIndex, 1);
        rearrangedConditions.splice(targetIndex, 0, conditionToMove);

        updateDragAndDropRefByProperty('draggedItemTempId', conditionToMove.tempId);

        scheduleUpdate();
      },
      [scheduleUpdate, updateDragAndDropRefByProperty]
    );

    const onDrop = useCallback(() => {
      const renumberedConditions = renumberPinnedPositions({
        movedConditionId: dragAndDropRef.current.draggedItemTempId,
        previousConditions: cloneDeep(conditions),
        rearrangedConditions: cloneDeep(dragAndDropRef.current.conditions),
        maxItemsLimit: MAX_POSITION_VALUE,
      });

      onFieldChange({ subRules: renumberedConditions });
    }, [conditions, onFieldChange]);

    const onDragEnd = useCallback(
      ({ didDrop }: { didDrop: boolean }) => {
        if (didDrop) {
          onDrop();
        } else {
          updateDragAndDropRefByProperty('conditions', [...conditions]);
          scheduleUpdate();
        }
      },
      [conditions, onDrop, updateDragAndDropRefByProperty, scheduleUpdate]
    );

    const fetchProductsFromConditions = useCallback(
      (ruleConditions: RuleDraftCondition[]) => {
        const searchTerms = ruleConditions
          .map(({ values }) => (values[0] as string | undefined)?.trim())
          .filter(value => !!value) as string[];

        fetchProductsDebounced({
          searchTerms,
        });
      },
      [fetchProductsDebounced]
    );

    useEffect(() => {
      resetOptions();
      fetchProductsFromConditions(conditions);
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        fetchProducts: fetchProductsFromConditions,
      }),
      [fetchProductsFromConditions]
    );

    return (
      <>
        {dragAndDropRef.current.conditions.map((condition, index) => {
          const subRulesErrors = reduceErrorsByPrefix({
            errors,
            // eslint-disable-next-line no-useless-escape
            prefix: `subRules\\.${index}(?!\\d)`,
            errorKey: 'pinToPositionValues',
          });

          const rowErrorLabels = mapErrorMessages(subRulesErrors);

          const isSkuValid = isObjEmpty(matchObjByErrorType(subRulesErrors, 'any.custom', index));
          const isPositionValid = isObjEmpty(
            matchObjByErrorType(subRulesErrors, 'any.required', index)
          );
          const isSkuUnique = isObjEmpty(matchObjByErrorPath(subRulesErrors, 'values', index));
          const isPositionUnique = isObjEmpty(
            matchObjByErrorPath(subRulesErrors, 'position', index)
          );

          const isValid = {
            isSkuValid,
            isPositionValid,
            isSkuUnique,
            isPositionUnique,
          };

          let skuConfiguration;
          if (productInformationLookup[condition.values?.[0] as string]) {
            skuConfiguration = productInformationLookup[condition.values?.[0] as string];
          } else {
            skuConfiguration = !loading ? null : undefined;
          }

          return (
            <PinToPositionRule
              key={condition.tempId}
              rule={condition}
              onConditionChange={onConditionChange}
              onDeleteCondition={onDeleteCondition}
              getDataFieldByName={getDataFieldByName}
              dispatch={dispatch}
              shopId={shopId}
              rowErrorLabels={rowErrorLabels}
              isValid={isValid}
              index={index}
              onBlur={onConditionBlur}
              onDrag={onDrag}
              onDragEnd={onDragEnd}
              isSelected={selectedConditionId === condition.tempId}
              onSelected={setSelectedConditionId}
              skuConfiguration={skuConfiguration}
            />
          );
        })}
      </>
    );
  }
);
