export enum UseValidateLocaleActionTypes {
  NavigateTo = 'UseValidateLocale/NavigateTo',
}

export interface UseValidateLocaleNavigateToActionPayload {
  navigateTo: string;
}

export interface UseValidateLocaleGetLexiconSettingsActionPayload {
  shopId: number;
}

export interface UseValidateLocaleNavigateToAction {
  type: typeof UseValidateLocaleActionTypes.NavigateTo;
  payload: UseValidateLocaleNavigateToActionPayload;
}

export type UseValidateLocaleAction = UseValidateLocaleNavigateToAction;
