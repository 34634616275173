import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useAppSelector } from 'src/hooks';
import { CollapsableEntityCard, Page } from 'src/components-dummy';
import { RoutedComponentProps } from 'src/app-routes';
import { useFetchFiltersSets } from './hooks';
import { filtersSetsNavigationActionMethods } from './Actions';
import './AugSearchFilterSets.scss';

type FilterSetsContainerProps = RoutedComponentProps;

export const FilterSetsContainer = ({
  permittedRouteMap,
}: FilterSetsContainerProps): JSX.Element => {
  const dispatch = useDispatch();

  const shopId = useAppSelector(state => state.shop.current?.shopId);

  const { filterSets } = useFetchFiltersSets({ currentShopId: shopId, dispatch });

  const navigateToSetConfig = useCallback(
    (setId: string): void => {
      if (!permittedRouteMap?.filtersSettings || !shopId) return;

      const navigateTo = generatePath(permittedRouteMap.filtersSettings.path, {
        filterSetId: setId,
        shopId,
      });

      dispatch(
        filtersSetsNavigationActionMethods.navigateTo({
          navigateTo,
        })
      );
    },
    [dispatch, permittedRouteMap?.filtersSettings, shopId]
  );

  /*
   * perform auto-redirect to default set
   * until adding sets feature will be implemented
   * [listing page is post MVP]
   */
  useEffect(() => {
    if (filterSets?.length === 1) {
      navigateToSetConfig(filterSets[0].id);
    }
  }, [filterSets, navigateToSetConfig]);

  return (
    <Page className='aug-search-filter-sets'>
      <Page.Header>
        <Page.Title>Filters</Page.Title>
      </Page.Header>
      <Page.Content>
        <ul className='aug-search-filter-sets__list'>
          {filterSets?.length
            ? filterSets.map(set => (
                <li key={set.id} className='aug-search-filter-sets__list-item'>
                  <CollapsableEntityCard
                    entityId={set.id}
                    onToggleExpend={() => navigateToSetConfig(set.id)}
                  >
                    <CollapsableEntityCard.Header>
                      <CollapsableEntityCard.HeaderCell
                        id={`${set.id}-name`}
                        label='Filter Set Name'
                        cellClassName='filter-set-name'
                      >
                        {set.settings.name}
                      </CollapsableEntityCard.HeaderCell>
                    </CollapsableEntityCard.Header>
                  </CollapsableEntityCard>
                </li>
              ))
            : null}
        </ul>
      </Page.Content>
    </Page>
  );
};
