import { createAsyncThunk } from '@reduxjs/toolkit';
import { DeepTagReportsGeneralConfigurationService, DeepTagReportsService } from 'src/services';

const deepTagReportsGeneralConfigurationService = new DeepTagReportsGeneralConfigurationService();

const deepTagReportsService = new DeepTagReportsService();

export const DeepTagsReportsProductLabelAutoCompleteActions = {
  getLabelsGeneralConfiguration: createAsyncThunk(
    'DeepTagsReportsBulkAssignLabelAutoCompleteActions/GetGeneralConfigurationLabels',
    async (some, { rejectWithValue }) => {
      try {
        const generalConfigurationLabels =
          await deepTagReportsGeneralConfigurationService.getLabels();
        return { generalConfigurationLabels };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  updateProduct: createAsyncThunk(
    'DeepTagsProductContainer/UpdateProduct',
    async (
      {
        shopId,
        locale,
        reportId,
        productId,
        labels,
      }: {
        shopId: number;
        reportId: string;
        productId: string;
        locale: string;
        labels: string[];
      },
      { rejectWithValue }
    ) => {
      try {
        const productToUpdateRequest = {
          shopId,
          locale,
          reportId,
          productId,
          labels,
        };

        const product = await deepTagReportsService.updateReportProduct(productToUpdateRequest);

        return { product };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
