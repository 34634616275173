import React, { ReactNode } from 'react';
import classnames from 'classnames';

export interface PageHeaderProps {
  children: ReactNode | ReactNode[];
  className?: string;
}

export const PageHeader = ({ children, className }: PageHeaderProps): JSX.Element => (
  <div className={classnames('syte-page-header', className)}>{children}</div>
);
