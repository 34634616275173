import React, { useCallback, useImperativeHandle } from 'react';
import { generatePath } from 'react-router-dom';
import { MerchandisingRuleTypes } from 'src/services';
import { NotificationCardMessageTypography } from '../common';
import { NotificationCardMessageProps } from '../types';

function getTagDisplayName(name: string): string {
  return name.split('.')[1];
}

export function InvalidAiTagMessage({
  notification,
  shopId,
  permittedRouteMap,
  navigateTo,
  cardRef,
}: NotificationCardMessageProps<
  MerchandisingRuleTypes.MerchandiseRule & {
    invalidAiTags: string[];
    source: MerchandisingRuleTypes.MerchandisingRulesSource;
  }
>): JSX.Element {
  const onNotificationClick = useCallback(() => {
    const path =
      notification.content.source === MerchandisingRuleTypes.MerchandisingRulesSource.Version
        ? permittedRouteMap.editMerchandisingRule?.path
        : permittedRouteMap.experiments?.path;

    if (path) {
      const merchandisingRuleFormPath = generatePath(path, {
        shopId,
        ruleId: notification.content.id,
      });
      navigateTo(merchandisingRuleFormPath);
    }
  }, [
    shopId,
    notification.content.id,
    notification.content.source,
    navigateTo,
    permittedRouteMap.editMerchandisingRule?.path,
    permittedRouteMap.experiments?.path,
  ]);

  useImperativeHandle(cardRef, () => ({
    onNotificationClick,
  }));

  const areMultipleTagsInvalid = notification.content.invalidAiTags.length > 1;

  const aiTagDisplayNames = notification.content.invalidAiTags.map(getTagDisplayName);

  return (
    <NotificationCardMessageTypography>
      Tag{areMultipleTagsInvalid && 's'} &apos;{aiTagDisplayNames.join(', ')}&apos;{' '}
      {areMultipleTagsInvalid ? 'have' : 'has'} been removed from the catalog, therefore the rule
      &apos;{notification.content.name}&apos; is invalid in the {notification.content.source}.
    </NotificationCardMessageTypography>
  );
}
