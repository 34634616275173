import React from 'react';
import { Column, EditableCellProps, Table } from 'src/components-dummy';
import { VariantType } from 'src/services';
import { ExperimentVariantNameCell, PRODUCTION_FIELD_NAME } from '../../ExperimentVariantNameCell';
import { ExperimentCardVariantTableProps } from '../types';

const TrafficAllocationCell = ({
  value,
}: EditableCellProps<
  Record<string, unknown>,
  VariantType | typeof PRODUCTION_FIELD_NAME
>): JSX.Element => {
  return (
    <div className='variant-traffic-allocation-cell'>
      <span>{`${value}%`}</span>
    </div>
  );
};

export const ExperimentCardVariantTable = ({
  experimentItem,
}: ExperimentCardVariantTableProps): JSX.Element => {
  return (
    <Table
      data={experimentItem.variantsDisplayList}
      updateData={() => {}}
      className='experiment-card-variant-table'
    >
      <Column Header='VARIANTS' id='name' accessor='name' Cell={ExperimentVariantNameCell} />
      <Column
        Header='ALLOCATION'
        id='trafficAllocation'
        accessor='trafficAllocation'
        Cell={TrafficAllocationCell}
      />
    </Table>
  );
};
