import { Dispatch, Store } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { visualEditorActions } from '../../components-bl';
import { Location } from 'src/components-bl/VisualEditor/types/location';
import { queryParamConfig } from './query-param-config';

export const syncParamsWithStateMiddleware =
  (store: Store) => (next: Dispatch) => (action: PayloadAction<Location>) => {
    if (visualEditorActions.locationChange.match(action)) {
      const { search } = action.payload;
      const params = new URLSearchParams(search);

      Object.keys(queryParamConfig).forEach(paramName => {
        const paramValue = params.get(paramName);
        if (paramValue) {
          const { action: configAction, payloadGenerator } = queryParamConfig[paramName];
          const payload = payloadGenerator(paramValue, store.getState());
          store.dispatch(configAction(payload) as any);
        }
      });
    }

    return next(action);
  };
