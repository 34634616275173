import { useMemo, useRef, useState } from 'react';
import { Dispatch } from 'src/components-bl';
import { RuleApiRef } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm';
import { RulePages } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesManagement';
import { merchandisingRulesManagementActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesManagement/MerchandisingRulesManagement.actions';

/** Intended to use for management component since it doesn't support routing */
export const useMerchandisingRulesManagement = ({ dispatch }: { dispatch: Dispatch }) => {
  const formApiRef = useRef(null as RuleApiRef | null);

  const [formStatus, setFormStatus] = useState<{
    canSubmit: boolean;
    isDirty?: boolean;
    isValid?: boolean;
    isSubmitting?: boolean;
  }>({ canSubmit: false, isSubmitting: false });

  const merchandisingRulesSettings = useMemo(() => {
    const onPageChange = async (newPage: RulePages) => {
      dispatch(merchandisingRulesManagementActions.changePage({ page: newPage }));
    };

    const onCancel = () => {
      onPageChange(RulePages.List);
    };

    const onSubmit = async () => {
      if (!formApiRef.current) {
        return;
      }

      setFormStatus(status => ({ ...status, isSubmitting: true }));

      try {
        await formApiRef.current.submit();

        onPageChange(RulePages.List);
      } catch (error) {
        console.error('useMerchandisingRules', error);
      }

      setFormStatus(status => ({ ...status, isSubmitting: false }));
    };

    const onFormStatusChange = ({ canSubmit }: { canSubmit: boolean }) => {
      setFormStatus({ canSubmit });
    };

    return {
      formStatus,
      formApiRef,
      onPageChange,
      onCancel,
      onSubmit,
      onFormStatusChange,
    };
  }, [formApiRef, formStatus, setFormStatus]);

  return merchandisingRulesSettings;
};
