import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AvailableIcons,
  Button,
  Link,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { isEqual } from 'lodash';
import { useValidateSchema } from 'src/hooks';
import { BackDropWithLoader } from 'src/components-dummy/Backdrop/BackdropWithLoader';
import { lexiconService } from 'src/services';
import {
  ThematicTagFormStyled,
  HeaderStyled,
  MainStyled,
  HeaderActionButtonsStyled,
  DescriptionStyled,
  LinkStyled,
  LinkIconStyled,
} from './LexiconRuleForm.styles';
import { FormSection } from './components/FormSection';
import * as mapper from './mapper';
import {
  LexiconRuleFormProps,
  ILexiconRuleDraft,
  TagFields,
  ValueTagPart,
} from './LexiconRuleForm.types';
import { lexiconRuleFormActions } from './LexiconRuleForm.actions';
import { lexiconRuleValidationSchema, getInitialLexiconRuleDraft } from './constants';
import { LexiconChangeRuleSection } from './components';
import { useAvailableAttributes } from './use-available-attributes';
import { ConditionGroups } from '../../ThematicTags/ThematicTagForm/components';
import { ChangeCategoryDialog } from './components/ChangeCategoryDialog/ChangeCategoryDialog';

export const LexiconRuleForm = React.memo(
  ({
    shopId,
    mode,
    onClose,
    lexiconRule,
    dispatch,
    locale,
    onSuccess,
  }: LexiconRuleFormProps): JSX.Element => {
    const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);
    const [nextCategoryKey, setNextCategoryKey] = useState<string | undefined>(undefined);

    const [availableCategoryKeys, setAvailableCategoryKeys] = useState<string[]>([]);
    const [availableAttributeKeys, setAvailableAttributeKeys] = useState<string[]>([]);
    const [availableValueKeys, setAvailableValueKeys] = useState<string[]>([]);

    const [isDirty, setIsDirty] = useState(false);

    const mappedRuleDraftInitial: ILexiconRuleDraft = useMemo(() => {
      return lexiconRule ? mapper.mapLexiconRuleDraft(lexiconRule) : getInitialLexiconRuleDraft();
    }, [lexiconRule]);

    const [lexiconRuleDraftState, setLexiconRuleDraftState] = useState(mappedRuleDraftInitial);

    const [selectedCategoryKey, selectedAttributeKey] = useMemo(() => {
      const categoryKey: string | undefined =
        lexiconRuleDraftState.conditionsGroups[0]?.conditions[0]?.categoryKey;

      const valueTagPart = mapper.getTagPartByField<ValueTagPart>(
        lexiconRuleDraftState.tagParts,
        TagFields.Value
      );

      const attributeKey = valueTagPart?.attributeKey;

      return [categoryKey, attributeKey];
    }, [lexiconRuleDraftState]);

    const { availableAttributeKeysByCategory } = useAvailableAttributes({
      dispatch,
      locale,
      shopId,
      lexiconRuleDraftState,
    });

    const { errors, validate, isValid } = useValidateSchema({
      schema: lexiconRuleValidationSchema,
      validateOnStart: false,
      initialData: mappedRuleDraftInitial,
    });

    const onChange = useCallback(
      (partialNewState: Partial<ILexiconRuleDraft>, skipNextCategoryKeyCheck?: boolean) => {
        setLexiconRuleDraftState((currentState: ILexiconRuleDraft) => {
          const { updatedState, resetStateToCategoryKey } = mapper.calculateNewRuleDraftState({
            currentState,
            partialNewState,
            skipNextCategoryKeyCheck,
          });

          if (resetStateToCategoryKey) {
            setNextCategoryKey(resetStateToCategoryKey);
            return currentState;
          }

          validate({ dataToValidate: updatedState });

          const newIsDirty = !isEqual(mappedRuleDraftInitial, updatedState);
          setIsDirty(newIsDirty);

          return updatedState;
        });
      },
      [validate, mappedRuleDraftInitial]
    );

    const clearNextCategoryKey = useCallback(() => {
      setNextCategoryKey(undefined);
    }, [setNextCategoryKey]);

    const applyNextCategoryKey = useCallback(() => {
      if (!nextCategoryKey) {
        return;
      }

      const newState = mapper.applyNextCategoryKeyToEmptyRule({
        previousDraft: lexiconRuleDraftState,
        nextCategoryKey,
      });

      onChange(newState, true);
      clearNextCategoryKey();
    }, [lexiconRuleDraftState, nextCategoryKey, onChange, clearNextCategoryKey]);

    const canSubmit = useMemo(() => isDirty && isValid, [isValid, isDirty]);

    const isCreateMode = mode === 'create';

    const onSubmit = useCallback(() => {
      if (!canSubmit || !lexiconRuleDraftState) return;

      setIsSubmitInProgress(true);

      if (isCreateMode) {
        (
          dispatch(
            lexiconRuleFormActions.createLexiconRule({
              shopId,
              locale,
              payload: mapper.mapLexiconRuleDraftToApi({ ...lexiconRuleDraftState, include: true }),
            })
          ) as any
        )
          .unwrap()
          .then(() => {
            setIsDirty(false);

            dispatch(lexiconRuleFormActions.notifyIsDirty({ isDirty: false }));

            setIsSubmitInProgress(false);
            onClose();

            onSuccess();
          })
          .catch((error: unknown) => {
            console.error(error);
            setIsSubmitInProgress(false);
          });
      } else if (lexiconRule?.id) {
        (
          dispatch(
            lexiconRuleFormActions.updateLexiconRule({
              shopId,
              locale,
              id: lexiconRule.id,
              partialRuleToUpdate: mapper.mapLexiconRuleDraftToPartialApi({
                ...lexiconRuleDraftState,
                include: lexiconRule.include,
              }),
            })
          ) as any
        )
          .unwrap()
          .then(() => {
            setIsDirty(false);

            dispatch(lexiconRuleFormActions.notifyIsDirty({ isDirty: false }));

            setIsSubmitInProgress(false);
            onClose();

            onSuccess();
          })
          .catch((error: unknown) => {
            console.error(error);
            setIsSubmitInProgress(false);
          });
      }
    }, [
      lexiconRuleDraftState,
      canSubmit,
      isCreateMode,
      shopId,
      locale,
      onClose,
      onSuccess,
      dispatch,
      lexiconRule?.id,
      lexiconRule?.include,
    ]);

    useEffect(() => {
      if (!lexiconRule) return;

      const mappedRule = mapper.mapLexiconRuleDraft(lexiconRule);

      setLexiconRuleDraftState(mappedRule);
      setIsDirty(false);
    }, [lexiconRule]);

    useEffect(() => {
      dispatch(lexiconRuleFormActions.notifyIsDirty({ isDirty }));
    }, [isDirty]);

    useEffect(() => {
      (
        dispatch(
          lexiconRuleFormActions.getAvailableKeys({
            shopId,
            locale,
            searchTerm: '',
            tagField: 'category',
          })
        ) as any
      )
        .unwrap()
        .then(({ availableValues }: { availableValues: string[] }) => {
          setAvailableCategoryKeys(availableValues);
        })
        .catch((error: unknown) => {
          console.error(error);
          setAvailableCategoryKeys([]);
        });
    }, [shopId, locale]);

    useEffect(() => {
      const additionalArguments = selectedCategoryKey ? { categories: [selectedCategoryKey] } : {};

      setAvailableAttributeKeys([]);

      (
        dispatch(
          lexiconRuleFormActions.getAvailableKeys({
            shopId,
            locale,
            searchTerm: '',
            tagField: 'attribute',
            ...additionalArguments,
          })
        ) as any
      )
        .unwrap()
        .then(({ availableValues }: { availableValues: string[] }) => {
          setAvailableAttributeKeys(availableValues);
        })
        .catch((error: unknown) => {
          console.error(error);
          setAvailableAttributeKeys([]);
        });
    }, [shopId, locale, selectedCategoryKey]);

    useEffect(() => {
      const additionalArguments: Partial<Parameters<typeof lexiconService.getAvailableKeys>[0]> =
        selectedAttributeKey ? { attributes: [selectedAttributeKey] } : {};

      if (selectedCategoryKey) {
        additionalArguments.categories = [selectedCategoryKey];
      }

      setAvailableValueKeys([]);

      (
        dispatch(
          lexiconRuleFormActions.getAvailableKeys({
            shopId,
            locale,
            searchTerm: '',
            tagField: 'value',
            ...additionalArguments,
          })
        ) as any
      )
        .unwrap()
        .then(({ availableValues }: { availableValues: string[] }) => {
          setAvailableValueKeys(availableValues);
        })
        .catch((error: unknown) => {
          console.error(error);
          setAvailableValueKeys([]);
        });
    }, [shopId, locale, selectedAttributeKey, selectedCategoryKey]);

    return (
      <>
        {isSubmitInProgress && <BackDropWithLoader />}

        <ThematicTagFormStyled>
          <HeaderStyled>
            <Typography type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
              {isCreateMode ? 'Create' : 'Edit'} Rename Rule
            </Typography>
            <HeaderActionButtonsStyled>
              <Button variant='tertiary' onClick={onClose}>
                Cancel
              </Button>
              <Button variant='primary' onClick={onSubmit} disabled={!canSubmit}>
                {isCreateMode ? 'Create and close' : 'Save and close'}
              </Button>
            </HeaderActionButtonsStyled>
          </HeaderStyled>
          <MainStyled>
            <DescriptionStyled>
              <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
                Rename rules, how to create them?
              </Typography>
              <Typography type={TypographyType.Paragraph} variant={TypographyVariant.LargeBold}>
                To create a new rename rule, define a rule set based on existing sources tags.
              </Typography>
              <LinkStyled href='https://syte-ai.atlassian.net/wiki/spaces/SI/pages/3213492391/How+to+Use+the+Rename+Rules'>
                <Link.Text type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                  Learn more about Rename Rules
                  <LinkIconStyled name={AvailableIcons.ArrowRec} />
                </Link.Text>
              </LinkStyled>
            </DescriptionStyled>

            <FormSection
              title='1. Define the rules for creating your rename rules:'
              subTitle='The values indicated within are the original source tags. Changes made by the user to category, attribute or value names are not updated here.'
            >
              <ConditionGroups
                errors={errors}
                conditionsGroups={lexiconRuleDraftState.conditionsGroups}
                onChange={onChange}
                availableCategoryKeys={availableCategoryKeys}
                availableAttributeKeysByCategory={availableAttributeKeysByCategory}
                shopId={shopId}
                locale={locale}
                dispatch={dispatch}
                enableMultipleGroups={false}
              />
            </FormSection>
            <FormSection
              title='2. What to update?'
              subTitle='You can change category, attribute or value. Values that you will not insert will remain as is.'
            >
              <LexiconChangeRuleSection
                errors={errors}
                draftRule={lexiconRuleDraftState}
                conditionsGroups={lexiconRuleDraftState.conditionsGroups}
                availableCategoryKeys={availableCategoryKeys}
                availableAttributeKeys={availableAttributeKeys}
                availableValueKeys={availableValueKeys}
                onChange={onChange}
              />
            </FormSection>
          </MainStyled>
        </ThematicTagFormStyled>

        {nextCategoryKey && (
          <ChangeCategoryDialog onCancel={clearNextCategoryKey} onApply={applyNextCategoryKey} />
        )}
      </>
    );
  }
);
