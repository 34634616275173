import styled from '@emotion/styled';
import { Skeleton } from 'src/components-dummy/Skeleton';

export const SkeletonStyled = styled(Skeleton)`
  margin-bottom: 30px;
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;

  padding: 30px;
`;

export const SectionHeaderStyled = styled.div`
  margin-bottom: 10px;
`;
