import React from 'react';
import styled from '@emotion/styled';
import {
  Typography,
  TypographyProps,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';

export const DeepTagProductEditableTitleStyled = styled.div`
  max-width: 100%;
  display: inline-block;

  .syte-popup-trigger {
    max-width: 100%;
  }
`;

export const ErrorsMessagesWrapperStyled = styled.div``;

export const ErrorLabelStyled = styled((props: TypographyProps) => (
  <Typography {...props} type={TypographyType.Paragraph} variant={TypographyVariant.SmallRegular} />
))`
  color: ${({ theme }) => theme.palette.custom.red};
  margin-top: 4px;
`;
