import { useCallback, useMemo } from 'react';
import { useRulesWithExtraIndicators } from '../../hooks/useRulesWithExtraIndicators';
import { useAppSelector } from '../../../hooks';
import { useDataFieldsBySupportedFeature } from '../../hooks';
import { PreviewFeature, ShopDataFieldsSupportedFeatures } from '../../../services';
import { ShopFeature } from '../../../app-types';
import { useDataFieldsLookupTable } from '../../MerchandisingRules/components/useDataFieldsLookupTable';
import { PreviewMerchRule } from '../types';
import { isRuleInvalid, VisualEditorMapper } from '../helpers';
import { MerchandiseRule } from '../../../services/src/service/types/shops';

export const useMerchRulesIndicators = (
  selectedExperience: PreviewFeature | null
): {
  loading: boolean;
  merchandisingRules: PreviewMerchRule[] | null;
} => {
  const { loading, global, currentCollection, debug } = useAppSelector(
    state => state.visualEditor.merchandisingRules
  );
  const dataFields = useAppSelector(state => state.dataFields.dataFields);
  const availableRegions = useAppSelector(state => state.shop.availableRegions);
  const draft = useAppSelector(state => state.versioning.draft);

  const appliedRuleIds = useMemo(
    () =>
      new Set(debug?.map(rule => (rule.isApplied ? rule.id : null)).filter(Boolean)) as Set<string>,
    [debug]
  );

  const isRuleApplied = useCallback(
    (rule: MerchandiseRule): boolean => {
      return appliedRuleIds.has(rule.id);
    },
    [appliedRuleIds]
  );

  const merchandisingRules = useMemo(() => {
    if (selectedExperience === ShopFeature.Collections) {
      return [...(currentCollection || [])];
    }
    if (global || currentCollection) {
      return [...(currentCollection || []), ...(global || [])];
    }
    return null;
  }, [selectedExperience, global, currentCollection]);

  const { supportedDataFields } = useDataFieldsBySupportedFeature({
    dataFields,
    feature: ShopDataFieldsSupportedFeatures.MerchandisingRules,
  });

  const { dataFieldsLookupTable } = useDataFieldsLookupTable({ dataFields: supportedDataFields });

  const rulesWithIndicators = useRulesWithExtraIndicators({
    merchandisingRules,
    availableRegions,
    dataFieldsLookupTable,
    draft,
  });

  const transformedRules = useMemo(() => {
    if (rulesWithIndicators)
      return VisualEditorMapper.addPreviewIndicators(
        rulesWithIndicators,
        isRuleInvalid,
        isRuleApplied
      );

    if (merchandisingRules)
      return VisualEditorMapper.addDefaultIndicators(merchandisingRules, isRuleApplied);

    return null;
  }, [merchandisingRules, rulesWithIndicators, isRuleApplied]);

  return { loading, merchandisingRules: transformedRules };
};
