import classNames from 'classnames';
import React, { MouseEvent, useEffect, useMemo, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import { useFindComponentByType } from '../hooks';
import './Popup.scss';
import { PopUpContent, PopUpTrigger } from './templates';
import { PopupProps } from './types';

export const PopUp = ({
  show,
  children,
  className,
  triggerEvent = 'click',
  hoverable,
  closeOnContentClick,
  position = 'top center',
  hideOnScroll = true,
  closeOnTriggerClick = true,
  fitContent,
  triggerClickPreventDefault = true,
  contentClickPreventDefault = true,
  closeOnClickOutside = true,
  disabled,
  onShow,
  intervalHideMillisecond,
  elementsToFreezeOnActive = [],
  enforceShowOnTriggerOnly = false,
}: PopupProps): JSX.Element => {
  const [open, setOpen] = useState(show);

  const { children: triggerChildren, className: triggerClassName } = useMemo(() => {
    return (
      useFindComponentByType({
        type: PopUpTrigger,
        children,
      })?.props || {}
    );
  }, [children]);

  const trigger = useMemo(
    () => (
      <div
        className={classNames('syte-popup-trigger', triggerClassName)}
        onClick={ev => {
          if (closeOnTriggerClick) {
            setOpen(!open);
            if (triggerClickPreventDefault) {
              ev.stopPropagation();
            }
          }
        }}
      >
        {triggerChildren}
      </div>
    ),
    [closeOnTriggerClick, triggerChildren, triggerClickPreventDefault, triggerClassName, open]
  );

  const popupContentChildren = useFindComponentByType({ type: PopUpContent, children })?.props
    .children;

  const onPopOpClose = () => {
    setOpen(false);
    onShow?.(false);
  };

  const onPopOpOpen = (event: MouseEvent) => {
    if (enforceShowOnTriggerOnly) {
      const wasTriggerActivated = (event.target as HTMLElement).closest('.syte-popup-trigger');
      if (!wasTriggerActivated) {
        return;
      }
    }

    setOpen(true);
    onShow?.(true);
    if (intervalHideMillisecond) {
      setTimeout(() => setOpen(false), intervalHideMillisecond);
    }
  };

  useEffect(() => {
    setOpen(show);
  }, [show]);

  useEffect(() => {
    if (elementsToFreezeOnActive.length) {
      const sytePageElements = document.querySelectorAll<HTMLDivElement>(
        elementsToFreezeOnActive.join(',')
      );
      for (const element of sytePageElements) {
        element.style.overflow = open ? 'hidden' : '';
      }
    }
  }, [elementsToFreezeOnActive, open]);

  return (
    <Popup
      open={open}
      onOpen={onPopOpOpen}
      onClose={onPopOpClose}
      wide
      trigger={trigger}
      on={triggerEvent}
      hoverable={hoverable}
      position={position}
      className={classNames('syte-popup', className, {
        'syte-popup-fit-content': fitContent,
        'hide-popup-content-arrow': !popupContentChildren,
      })}
      hideOnScroll={hideOnScroll}
      closeOnTriggerClick={closeOnTriggerClick}
      disabled={disabled}
      closeOnDocumentClick={closeOnClickOutside}
    >
      <div
        className='syte-popup-content'
        onClick={ev => {
          ev.stopPropagation();

          if (contentClickPreventDefault) {
            ev.preventDefault();
          }

          if (closeOnContentClick) {
            setOpen(false);
          }
        }}
      >
        {popupContentChildren}
      </div>
    </Popup>
  );
};

PopUp.Trigger = PopUpTrigger;
PopUp.Content = PopUpContent;
