import React, { useState } from 'react';
import { MenuItem, Menu, AvailableIcons } from 'src/components-dummy';
import { AddFilterButtonStyled } from '../FiltersRow.styles';
import { FilterOption } from '../types';

export interface AddFilterProps {
  onAddFilter: (filterKeyToAdd: string) => void;
  filtersOptions: FilterOption[];
}

export const AddFilter = ({ onAddFilter, filtersOptions }: AddFilterProps): JSX.Element => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const menuOpened = !!menuAnchorEl;

  const onTriggerClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const onOptionClick = (filterKey: string) => {
    onAddFilter(filterKey);
    setMenuAnchorEl(null);
    onCloseMenu();
  };

  return (
    <>
      <AddFilterButtonStyled
        startIcon={AvailableIcons.Add}
        variant='tertiary'
        size='small'
        onClick={onTriggerClick}
        clicked={menuOpened}
      >
        Filter
      </AddFilterButtonStyled>
      <Menu
        open={menuOpened}
        anchorEl={menuAnchorEl}
        label='Filter by'
        onClose={onCloseMenu}
        transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
      >
        {filtersOptions.map(option => (
          <MenuItem key={option.key} onClick={() => onOptionClick(option.key)}>
            {option.displayName}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
