import React, { RefObject } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'src/hooks';
import { RankingStrategy } from 'src/components-bl';
import { VisualEditorMapper } from '../../helpers';
import { Dispatch } from '../../../../components-dummy/types';
import { ShopFeatureToggles } from '../../../../services/src/service/types/shops';
import { Typography, TypographyType, TypographyVariant } from '../../../../components-dummy';
import { EditRankingStrategy } from './EditRankingStrategyContainer.styles';

const COMPONENT_NAME = 'EditRankingStrategyContainerVE';

interface ComponentProps {
  shopId: number;
  dispatch: Dispatch;
  formHeaderRef?: RefObject<HTMLDivElement | null>;
  closeRankingStrategyDrawer?: VoidFunction;
  featureToggles: ShopFeatureToggles;
}

export const EditRankingStrategyContainer = ({
  shopId,
  dispatch,
  formHeaderRef,
  closeRankingStrategyDrawer,
  featureToggles,
}: ComponentProps): JSX.Element | null => {
  const { selectedExperience, collection } = useAppSelector(({ visualEditor }) => visualEditor);

  const {
    rankingStrategy: { weights, type },
    dataFields,
  } = useAppSelector(state => {
    return {
      rankingStrategy: state.rankingStrategy,
      shopId: state.shop.current?.shopId,
      dataFields: state.dataFields.dataFields,
    };
  }, shallowEqual);

  const selectedSyteProduct = VisualEditorMapper.convertFeatureToMerchProduct(selectedExperience);

  return shopId && selectedSyteProduct ? (
    <EditRankingStrategy>
      <Typography type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
        Edit Ranking Strategy
      </Typography>
      <RankingStrategy
        dispatch={dispatch}
        weights={weights[selectedSyteProduct]}
        type={type}
        selectedSyteProduct={selectedSyteProduct}
        shopId={shopId}
        formHeaderElementRef={formHeaderRef}
        dataFields={dataFields}
        entityId={collection?.id}
        prefixId={COMPONENT_NAME}
        closeRankingStrategyDrawer={closeRankingStrategyDrawer}
        featureToggles={featureToggles}
      />
    </EditRankingStrategy>
  ) : null;
};
