import React, { useCallback, useMemo } from 'react';
import { generatePath, Redirect } from 'react-router-dom';
import { Page, PageSideNav } from 'src/components-dummy';
import { useDispatch } from 'react-redux';
import { AppRoute, AppSwitch, RouteSettings, RoutedComponentProps } from 'src/app-routes';
import { useAppSelector } from 'src/hooks';
import { UserRoles } from 'src/services/src/service/types/users';
import { shopSettingsContainerActionMethods } from './Actions';
import {
  AdminSettingsPage,
  AITaggingPage,
  GeneralSettingsPage,
  DataFieldsPage,
  VersionsListPage,
} from './components';
import { ShopSettingsPageStyled } from './ShopSettings.styles';

type ShopSettingsProps = RoutedComponentProps;

interface ComponentRoute {
  route: RouteSettings;
  Component: () => JSX.Element | null;
  exact?: boolean;
}

export const ShopSettings = ({ permittedRouteMap }: ShopSettingsProps): JSX.Element => {
  const dispatch = useDispatch();

  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const role = useAppSelector(state => state.global.loggedInUser?.role || UserRoles.ClientAdmin);

  const generalSettingsRoutesGroup = useMemo(
    () =>
      [
        {
          route: permittedRouteMap.shopSettingsGeneral,
          Component: GeneralSettingsPage,
          exact: false,
        },
        {
          route: permittedRouteMap.dataFields,
          Component: DataFieldsPage,
        },
      ].filter(routeComponent => !!routeComponent.route?.permissions?.[role]) as ComponentRoute[],
    [permittedRouteMap.shopSettingsGeneral, permittedRouteMap.dataFields, role]
  );

  const advancedSettingsRoutesGroup = useMemo(
    () =>
      [
        {
          route: permittedRouteMap.adminSettings,
          Component: AdminSettingsPage,
        },
        {
          route: permittedRouteMap.aiTagging,
          Component: AITaggingPage,
        },
        {
          route: permittedRouteMap.versionsList,
          Component: VersionsListPage,
        },
      ].filter(routeComponent => !!routeComponent.route?.permissions?.[role]) as ComponentRoute[],
    [
      permittedRouteMap.adminSettings,
      permittedRouteMap.aiTagging,
      permittedRouteMap.versionsList,
      role,
    ]
  );

  const sideNavRoutes = useMemo(
    () => [
      {
        routes: generalSettingsRoutesGroup.map(({ route }) => ({
          url: generatePath(route.path, { shopId }),
          title: route.title,
          pathTemplate: route.path,
        })),
      },
      {
        routes: advancedSettingsRoutesGroup.map(({ route }) => ({
          url: generatePath(route.path, { shopId }),
          title: route.title,
          pathTemplate: route.path,
        })),
      },
    ],
    [generalSettingsRoutesGroup, advancedSettingsRoutesGroup, shopId]
  );

  const routeComponents = useMemo(
    () =>
      [...generalSettingsRoutesGroup, ...advancedSettingsRoutesGroup].map(
        ({ route, Component, exact = true }) => (
          <AppRoute
            key={route.path}
            route={route}
            Component={Component}
            componentProps={{ permittedRouteMap }}
            exact={exact}
          />
        )
      ),
    [generalSettingsRoutesGroup, advancedSettingsRoutesGroup]
  );

  const onNavigate = useCallback(
    url => {
      dispatch(shopSettingsContainerActionMethods.navigateTo({ navigateTo: url }));
    },
    [dispatch]
  );

  return (
    <ShopSettingsPageStyled layout='sidebar'>
      <Page.SideBar>
        <PageSideNav
          pageTitle='Shop Settings'
          routeGroups={sideNavRoutes}
          handleNavigate={onNavigate}
        />
      </Page.SideBar>
      <Page.Content>
        <AppSwitch>
          {routeComponents}
          {permittedRouteMap.shopSettingsGeneral && (
            <Redirect to={permittedRouteMap.shopSettingsGeneral.path} />
          )}
        </AppSwitch>
      </Page.Content>
    </ShopSettingsPageStyled>
  );
};
