import { AuthTypes } from '../types';
import { ApiServiceBase } from '../api-service-base';
import { Buffer } from 'buffer';
import { AuthMapper } from './auth.mapper';
import { AuthResponse } from '../types/auth/auth';

export class Auth extends ApiServiceBase {
  constructor() {
    super('auth');
  }

  async me(): Promise<AuthResponse> {
    const url = `${this.serviceBaseUri}/me`;
    const response = await this.httpService.get({ url });
    return AuthMapper.mapAuthResponse(response.data);
  }

  async login({ email, password }: AuthTypes.LoginArgs): Promise<AuthResponse> {
    const payload = {
      email,
      password: Buffer.from(password, 'utf8').toString('base64'),
    };

    const url = `${this.serviceBaseUri}/login`;
    const response = await this.httpService.post({ url, data: payload });

    return AuthMapper.mapAuthResponse(response.data);
  }

  async logout(): Promise<string> {
    const url = `${this.serviceBaseUri}/logout`;
    const response = await this.httpService.post({ url });
    return response.data;
  }

  async verifyToken(token: string): Promise<AuthResponse> {
    const payload = { token };

    const url = `${this.serviceBaseUri}/verify/link`;
    const response = await this.httpService.post({ url, data: payload });

    return AuthMapper.mapAuthResponse(response.data);
  }

  async resendTwoFactorAuthLink(): Promise<void> {
    const url = `${this.serviceBaseUri}/resend-link`;
    await this.httpService.post({ url });
  }
}
