import { useState, useCallback, useRef } from 'react';

import { Dispatch } from '../../types';

import { UiTemplate } from '../types';

import { uiTemplateActionMethods } from './actions';
import { useOpenEditor } from '../useOpenEditor';

interface Args {
  dispatch: Dispatch;
  template: UiTemplate;
  openPopup: () => void;
}

interface Result<T> {
  ref: React.MutableRefObject<T | null>;
  closeDeleteDialog: () => void;
  onDelete: () => void;
  onDeleteClicked: () => void;
  onEditClicked: () => void;
  showDeleteDialog: boolean;
}

export const useLogic = <T extends HTMLElement>({
  template,
  dispatch,
  openPopup,
}: Args): Result<T> => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const ref = useRef<T>(null);

  const { open } = useOpenEditor({
    shopId: template.shopId,
    dispatch,
    openPopup,
    targetTemplate: template.name,
  });

  const closeDeleteDialog = useCallback(() => {
    setShowDeleteDialog(false);
  }, []);

  const onDeleteClicked = useCallback(() => {
    ref.current?.click();
    setShowDeleteDialog(true);
  }, []);

  const onEditClicked = useCallback(() => {
    ref.current?.click();
    open();
  }, [open]);

  const onDelete = useCallback(() => {
    dispatch(uiTemplateActionMethods.deleteUiTemplate(template));
  }, [template]);

  return {
    ref,
    closeDeleteDialog,
    onDelete,
    onDeleteClicked,
    onEditClicked,
    showDeleteDialog,
  };
};
