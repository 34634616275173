import styled from '@emotion/styled';
import { ExpandableSectionTrigger } from '../../../../../ExpandableSectionTrigger';

export const ExpandableOptionsStyled = styled.div``;

export const ExpandableSectionTriggerStyled = styled(ExpandableSectionTrigger)`
  margin-top: 0;
`;

export const ProductTagsSectionTriggerStyled = styled(ExpandableSectionTrigger)`
  cursor: initial;
  user-select: none;

  & > .syte-basic-icon {
    display: none;
  }
`;
