import React, { useCallback, useState } from 'react';
import {
  FormCard,
  Icon,
  AvailableIcons,
  TypographyType,
  TypographyVariant,
  Tooltip,
} from 'src/components-dummy';
import { RoutedComponentProps } from 'src/app-routes';
import { copyToClipboard } from 'src/utils';
import { generatePath } from 'react-router';
import { shopApiKeysSettingsActions } from 'src/containers';
import { LinkButton } from 'src/components-dummy/LinkButton';
import { IApiKey } from 'src/services/src/service/types/shops/api-keys';
import { getDateNow } from 'src/services/src/common/utils';
import {
  ApiKeyRowStyled,
  ApiKeysContainerStyled,
  IconCopyStyled,
  IconDotSplitterStyled,
  IconLockStyled,
  ApiKeyLabelStyled,
  ApiKeyCreatedStyled,
  CopyDeleteIconsDivStyled,
  ApiKeyMetaDataStyled,
  IconTrashStyled,
  ApiKeyLabelWithPointerStyled,
  AddApiKeyBtnDisabledStyled,
} from './ApiKeysSettings.styles';
import { Dispatch } from '../types';
import { apiKeysSettingsActions } from './Actions/ApiKeysSettingsActions';
import { ShopApiKeysDeleteSettingsModal } from './ShopApiKeysDeleteSettingsModal';

type ApiKeysSettingsProps = RoutedComponentProps & {
  apiKeys: IApiKey[];
  shopId: number | undefined;
  dispatch: Dispatch;
};

export const ApiKeysSettings = ({
  apiKeys,
  shopId,
  dispatch,
  permittedRouteMap,
}: ApiKeysSettingsProps): JSX.Element => {
  const [apiKeyMetaForDeleting, setApiKeyMetaForDeleting] = useState<{
    apiKeyId: string;
    apiKeyLabel: string;
  } | null>(null);
  const onAddNewApiKeyButtonClick = () => {
    if (permittedRouteMap.shopSettingsGeneralCreateApiKey && shopId && apiKeys.length < 5) {
      dispatch(
        apiKeysSettingsActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.shopSettingsGeneralCreateApiKey.path, {
            shopId,
          }),
        })
      );
    }
  };

  const onApiKeyCardItemClick = async (apiKeyId: string) => {
    if (permittedRouteMap.shopSettingsGeneralUpdateApiKey && shopId) {
      dispatch(
        apiKeysSettingsActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.shopSettingsGeneralUpdateApiKey.path, {
            shopId,
            apiKeyId,
          }),
        })
      );
    }
  };

  const onDeleteKeyClicked = async () => {
    if (shopId && apiKeyMetaForDeleting?.apiKeyId) {
      await dispatch(
        shopApiKeysSettingsActions.deleteApiKey({
          shopId,
          apiKeyId: apiKeyMetaForDeleting.apiKeyId,
        })
      );
      await dispatch(shopApiKeysSettingsActions.getApiKeys({ shopId }));
      setApiKeyMetaForDeleting(null);
    }
  };

  const onDeleteApiKeyIconClick = useCallback((apiKeyId: string, apiKeyLabel: string) => {
    setApiKeyMetaForDeleting({ apiKeyId, apiKeyLabel });
  }, []);

  const onCloseDeleteDialog = useCallback(() => {
    setApiKeyMetaForDeleting(null);
  }, []);

  const getExpirationLabel = useCallback((expirationDate: Date | null | undefined) => {
    const now = getDateNow();
    if (!expirationDate) {
      return 'Never expires';
    }
    return expirationDate > now
      ? `Expires at ${expirationDate.toLocaleDateString('he-IL')}`
      : 'Expired';
  }, []);

  const onCopyToClipboard = (apiKey: string) => {
    copyToClipboard(apiKey);
    dispatch(shopApiKeysSettingsActions.copyToClipboard());
  };

  return (
    <ApiKeysContainerStyled>
      {apiKeyMetaForDeleting && apiKeyMetaForDeleting?.apiKeyLabel && (
        <ShopApiKeysDeleteSettingsModal
          onModalClose={onCloseDeleteDialog}
          onDeleteKeyClicked={onDeleteKeyClicked}
          apiKeyLabel={apiKeyMetaForDeleting.apiKeyLabel}
        />
      )}
      <FormCard>
        <FormCard.Title>API Keys</FormCard.Title>
        <FormCard.Button>
          {apiKeys.length >= 5 ? (
            <Tooltip value='You can add up to 5 API keys' hoverable={false}>
              <AddApiKeyBtnDisabledStyled>
                <Icon name={AvailableIcons.Plus} /> Add new API Key
              </AddApiKeyBtnDisabledStyled>
            </Tooltip>
          ) : (
            <LinkButton onClick={onAddNewApiKeyButtonClick}>
              <Icon name={AvailableIcons.Plus} /> Add new API Key
            </LinkButton>
          )}
        </FormCard.Button>
        {apiKeys?.length > 0 ? (
          <FormCard.Content>
            {apiKeys.map((key: IApiKey) => {
              const expirationLabel = getExpirationLabel(key.expiration);
              return (
                <ApiKeyRowStyled key={key.id}>
                  <ApiKeyMetaDataStyled>
                    {expirationLabel === 'Expired' ? (
                      <ApiKeyLabelStyled
                        color='#878787'
                        type={TypographyType.Body}
                        variant={TypographyVariant.SmallMedium}
                      >
                        <ApiKeyLabelWithPointerStyled onClick={() => onApiKeyCardItemClick(key.id)}>
                          {key.label}
                        </ApiKeyLabelWithPointerStyled>
                        <IconLockStyled name={AvailableIcons.Lock} />
                      </ApiKeyLabelStyled>
                    ) : (
                      <ApiKeyLabelStyled color='#1e1e1e'>
                        <ApiKeyLabelWithPointerStyled onClick={() => onApiKeyCardItemClick(key.id)}>
                          {key.label}
                        </ApiKeyLabelWithPointerStyled>
                        <IconLockStyled name={AvailableIcons.Lock} />
                      </ApiKeyLabelStyled>
                    )}
                    <ApiKeyCreatedStyled
                      type={TypographyType.Body}
                      variant={TypographyVariant.ExtraSmallRegular}
                    >
                      📆 Created at {new Date(key.createdAt).toLocaleDateString('he-IL')}
                      <IconDotSplitterStyled name={AvailableIcons.Dot} />
                      {expirationLabel}
                    </ApiKeyCreatedStyled>
                  </ApiKeyMetaDataStyled>
                  <CopyDeleteIconsDivStyled>
                    <IconCopyStyled
                      name={AvailableIcons.Duplicate}
                      color={expirationLabel === 'Expired' ? '#6e6e71' : '#1e1e1e'}
                      onClick={() => onCopyToClipboard(key.key)}
                    />
                    <IconTrashStyled
                      name={AvailableIcons.TrashCan}
                      onClick={() => onDeleteApiKeyIconClick(key.id, key.label)}
                    />
                  </CopyDeleteIconsDivStyled>
                </ApiKeyRowStyled>
              );
            })}
          </FormCard.Content>
        ) : (
          <></>
        )}
      </FormCard>
    </ApiKeysContainerStyled>
  );
};
