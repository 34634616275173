import styled from '@emotion/styled';
import { colors } from 'src/styles';
import { Icon } from '../Icon';

export const SelectBaseStyled = styled.div`
  position: relative;

  .MuiInputBase-root {
    width: 100%;
    max-height: 40px;
    line-height: 1;
  }

  .MuiSelect-select[aria-expanded='true'] ~ .select-caret {
    transform: rotate(180deg);
  }

  .Mui-disabled {
    background: ${({ theme }) => theme.palette.custom['gray-80']};
    & ~ .select-caret path {
      stroke: ${({ theme }) => theme.palette.custom['gray-50']};
    }
  }

  .MuiInputLabel-root {
    color: ${({ theme }) => theme.palette.common.black};
    margin-bottom: 8px;
    font-size: 12px;
  }

  .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
    padding: 10px 32px 10px 16px;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 14px;
  }

  .select-placeholder {
    padding: 0;

    &.Mui-disabled {
      background: none;
    }
  }

  li.MuiMenuItem-root.Mui-disabled.MuiMenuItem-gutters.MuiButtonBase-root {
    padding: 0;
  }

  .MuiSvgIcon-root {
    right: 12px;
  }

  li.MuiMenuItem-root {
    font-size: 14px;
    &.Mui-disabled.MuiMenuItem-gutters.MuiButtonBase-root.Mui-disabled.placeholder {
      padding: 0;
    }
  }
`;

export const PrimarySelectStyled = styled(SelectBaseStyled)`
  .MuiInputBase-root {
    border-radius: 8px;
    &:not(.Mui-focused, :hover) > svg {
      stroke: ${({ theme }) => theme.palette.custom['gray-10']};
    }
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
    box-shadow: ${({ theme }) =>
      `0px 0px 0px 1.5px ${theme.palette.custom['primary-main']}, 0px 0px 0px 2.5px ${theme.palette.custom['primary-90']}`};
  }

  .Mui-error .MuiOutlinedInput-notchedOutline {
    border: none;
    box-shadow: ${({ theme: { palette } }) =>
      `0px 0px 0px 1px ${palette.custom.red}, 0px 0px 0px 2px ${palette.custom['red-90']}
    `};
  }

  .select-placeholder {
    color: ${({ theme }) => theme.palette.custom['gray-10']};
  }
`;

export const MenuSelectStyled = styled(SelectBaseStyled)`
  .MuiInputBase-root {
    border-radius: 4px;
  }

  .MuiInputBase-colorPrimary {
    background: ${({ theme }) => theme.palette.custom['gray-80']};
    .Mui-focused {
      background-color: ${({ theme }) => theme.palette.custom['primary-80']};
    }
  }

  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary:hover {
    background: ${({ theme }) => theme.palette.custom['primary-90']};
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
    color: ${({ theme }) => theme.palette.common.black};
  }

  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-focused {
    background: ${({ theme }) => theme.palette.custom['primary-80']};
  }

  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-error {
    border-color: none;

    &.Mui-focused {
      .MuiSelect-select.MuiSelect-outlined {
        border: 1px solid ${({ theme }) => theme.palette.custom.red};
      }
    }

    .MuiSelect-select.MuiSelect-outlined {
      background: ${({ theme }) => theme.palette.custom['red-90']};
    }
  }

  .select-placeholder {
    color: ${({ theme }) => theme.palette.common.black};
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export const EllipsisWithTooltipWrapperStyled = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  .ellipsis-with-tooltip {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export const SelectCaretIconStyled = styled(Icon)`
  position: absolute;
  pointer-events: none;
  right: 13px;
  stroke: #1e1e1e;
`;

export const BaseMenuStyled = {
  border: `1px solid ${colors['gray-60']}`,
  borderRadius: '8px',
};
