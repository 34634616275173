import React from 'react';
import styled from '@emotion/styled';
import NumberFormat from 'react-number-format';
import { TextBoxBase, TextBoxBaseProps } from '../TextBox';
import { Typography } from '../Typography';

export const NumericInputStyled = styled((props: TextBoxBaseProps) => <TextBoxBase {...props} />)``;

export const NumberFormatStyled = styled(NumberFormat)`
  border: none;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme }) => theme.palette.common.black};
  background: transparent;
`;

export const SymbolTypographyStyled = styled(Typography)`
  margin-left: 4px;
  color: ${({ theme }) => theme.palette.custom['gray-50']};
`;
