import React from 'react';
import { MenuItem, Select, SelectType } from '../../../../components-dummy';
import { RuleFormField } from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormField/RuleFormField';
import { RuleFormFieldStyled } from '../CollectionDetails/RuleFormField.styles';
import { ShopDataField } from '../../../../services';
import { useMappedSelectValues } from '../../../hooks/useMappedSelectValues';

interface ICollectionUniqueByFieldProps {
  dataFields: ShopDataField[] | undefined;
  uniqueByField: string | undefined;
  handleFieldOnChange: (fieldName: string, value: string) => void;
}

export const CollectionUniqueByField: React.FC<ICollectionUniqueByFieldProps> = ({
  dataFields,
  uniqueByField,
  handleFieldOnChange,
}) => {
  const dataFieldsDropdownOptions = dataFields ? useMappedSelectValues(dataFields) : [];

  return (
    <RuleFormFieldStyled>
      <RuleFormField.LabelText>Group products by:</RuleFormField.LabelText>
      <Select
        type={SelectType.Primary}
        className='setting-input'
        value={uniqueByField ?? undefined}
        onChange={e => handleFieldOnChange('uniqueByField', e.target.value)}
        hasNoneOption
      >
        {dataFieldsDropdownOptions.map((option: { value: string; text: string }) => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </RuleFormFieldStyled>
  );
};

export default CollectionUniqueByField;
