import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Page, BackButtonTitle } from 'src/components-dummy';
import { useAppSelector } from 'src/hooks';
import { ShopTheLookSettings } from 'src/components-bl';
import { ShopRoutes } from '../../types/enums/shop-routes';
import './ShopTheLookContainer.scss';

export const ShopTheLookContainer = (): JSX.Element | null => {
  const formHeaderRef = useRef<HTMLDivElement>(null);

  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const shopTheLookSettings = useAppSelector(state => state.shopTheLookSettings);
  const dispatch = useDispatch();
  const navigationPath = `/shops/${shopId}/${ShopRoutes.ShopperExperiences}`;
  return (
    <Page className='shop-the-look-container'>
      <Page.Header>
        <BackButtonTitle path={navigationPath}>Shop The Look</BackButtonTitle>
        <div className='shop-the-look-buttons-section' ref={formHeaderRef} />
      </Page.Header>
      <Page.Content>
        {shopId && (
          <ShopTheLookSettings
            shopTheLookSettings={shopTheLookSettings}
            shopId={shopId}
            formHeaderElementRef={formHeaderRef}
            dispatch={dispatch}
          />
        )}
      </Page.Content>
    </Page>
  );
};
