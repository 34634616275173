import { ShopAIModel } from '../ai-models/types';
import { ApiServiceBase } from '../api-service-base';

export class AIModelShopService extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl(shopId: number): string {
    return `${this.serviceBaseUri}/${shopId}`;
  }

  async getAIModelPerShop({ shopId }: { shopId: number }): Promise<ShopAIModel> {
    const url = `${this.getUrl(shopId)}/ai-model`;

    const response = await this.httpService.get({ url });
    const aiModel = {
      id: response.data.shopAIModel.id,
      aiModelId: response.data.shopAIModel.aiModelId,
      isUsingDefaultAIModel: response.data.shopAIModel.isUsingDefaultAIModel,
    } as ShopAIModel;

    return aiModel;
  }

  async updateAIModelPerShop({
    shopId,
    aiModelId,
    isUsingDefaultAIModel,
  }: {
    shopId: number;
    aiModelId: string;
    isUsingDefaultAIModel: boolean;
  }): Promise<ShopAIModel> {
    const url = `${this.getUrl(shopId)}/ai-model`;

    const response = await this.httpService.put({
      url,
      data: { aiModelId, isUsingDefaultAIModel },
    });
    const aiModel = {
      id: response.data.shopAIModel.id,
      aiModelId: response.data.shopAIModel.aiModelId,
      isUsingDefaultAIModel: response.data.shopAIModel.isUsingDefaultAIModel,
    } as ShopAIModel;

    return aiModel;
  }
}
