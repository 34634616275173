import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { galleriesService } from 'src/services/src/service/galleries';

export const editInspirationalImagesGalleryPageActions = {
  resetCurrentGallery: createAction('EditInspirationalImagesGalleryPage/ResetCurrentGallery'),
  getGallery: createAsyncThunk(
    'EditInspirationalImagesGalleryPage/GetGallery',
    async (
      { shopId, galleryId, galleryType }: Parameters<typeof galleriesService.getGallery>[0],
      { rejectWithValue }
    ) => {
      try {
        const gallery = await galleriesService.getGallery({
          shopId,
          galleryId,
          galleryType,
        });
        return gallery;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
