import { useCallback, useState } from 'react';

interface UseCollapseReturnType {
  expandedId: string;
  expand?: (selectedIds: string[]) => void;
}

export const useCollapse = (accordionId: string): UseCollapseReturnType => {
  const [expandedId, setExpandedId] = useState<string>(accordionId);

  const expand = useCallback(([selectedId]: string[]) => {
    setExpandedId(selectedId);
  }, []);

  return {
    expandedId,
    expand,
  };
};
