import styled from '@emotion/styled';
import {
  DialogModal,
  DialogModalContent,
  DialogModalFooter,
  TextBox,
  Typography,
} from 'src/components-dummy';

export const PinMultipleItemsModalStyled = styled(DialogModal)`
  .MuiPaper-root {
    width: 710px;
    min-width: 710px;
  }
`;

export const PinMultipleItemsModalContentStyled = styled(DialogModalContent)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const PinMultipleItemsModalFooterStyled = styled(DialogModalFooter)`
  gap: 3px;
`;

export const TypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  margin-top: 6px;
`;

export const MultilineTextBoxStyled = styled(TextBox)`
  margin: 24px 0;
`;

export const SubTitleTypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-20']};
`;
