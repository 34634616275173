import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MerchandisingRuleTypes, ShopDataFieldsSupportedFeatures } from 'src/services';
import { useDispatch } from 'react-redux';
import { startCase, toLower } from 'lodash';
import { RuleFormFooter } from '../../../MerchandisingRules/components/MerchandisingRuleForm/RuleFormFooter';
import { useAppSelector, useValidateSchema } from '../../../../hooks';
import { UpdateMerchRuleArgs } from '../../../MerchandisingRules/components/MerchandisingRuleForm';
import { useDataFieldsBySupportedFeature } from '../../../hooks';
import { useDataFieldsLookupTable } from '../../../MerchandisingRules/components/useDataFieldsLookupTable';
import {
  AvailableIcons,
  Button,
  Icon,
  MenuItem,
  Page,
  Select,
  SelectOnChangeEvent,
  SelectType,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { ruleFormMappers } from '../../../MerchandisingRules/components/MerchandisingRuleForm/MerchandisingRuleForm.mappers';
import {
  ruleActionsWithWeight,
  syteProductToRuleSettingsMap,
} from '../../../MerchandisingRules/components/constants';
import {
  RuleDraft,
  RuleDraftCondition,
} from '../../../MerchandisingRules/components/MerchandisingRuleForm/MerchandisingRuleForm.config';
import { formValidationSchema } from '../../../MerchandisingRules/components/MerchandisingRuleForm/validation-schema';
import { ConditionsGroup } from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/ConditionsGroup/ConditionsGroup';
import { ErrorLabelStyled } from '../../../MerchandisingRules/components/MerchandisingRuleForm/MerchandisingRuleForm.styles';
import {
  ConditionFirstRowStyled,
  ConditionText,
} from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormConditionsSection/RuleFormConditionsSection.styles';
import { WeightSliderPopover } from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormConditionsSection/WeightSliderPopover/WeightSliderPopover';
import { DeleteAllButtonStyled } from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormConditionsSection/ActionAndConditionsIncludeGroup/ActionAndConditionsIncludeGroup.styles';
import { Conditions } from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormConditionsSection/ActionAndConditionsIncludeGroup/Conditions';
import { LinkButton } from '../../../../components-dummy/LinkButton';
import { PinMultipleItemsModal } from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormConditionsSection/PinToPosition/PinMultipleItemsModal/PinMultipleItemsModal';
import { DataFieldConditionDataType } from '../../../MerchandisingRules/components/DataFieldsCondition/DataFieldsCondition.types';
import { insertAndReorderPins } from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormConditionsSection/PinToPosition/helpers';
import { MAX_POSITION_VALUE } from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormConditionsSection/PinToPosition/constants';
import { ButtonStyled } from '../../../../containers/MerchandisingRules/EditMerchandisingRuleContainer/EditMerchandisingRuleContainer.styles';
import { PageStyled } from './EditStrategyContainer.styles';
import { PinToPositionApiHandle } from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormConditionsSection/PinToPosition/PinToPositionRulesInputs';
import { visualEditorActions } from '../../state';

interface Props {
  shopId: number;
  onClose: VoidFunction;
  onSave: (payload: UpdateMerchRuleArgs) => void;
  rule?: MerchandisingRuleTypes.MerchandiseRule;
  entityId?: string;
  predefinedProduct?: MerchandisingRuleTypes.MerchandisingRuleProduct;
  isReadOnly?: boolean;
  isAiTagsEnabled: boolean;
}

export const EditStrategyContainer = memo(
  ({
    shopId,
    rule,
    onClose,
    onSave,
    entityId,
    predefinedProduct,
    isReadOnly,
    isAiTagsEnabled,
  }: Props): JSX.Element | null => {
    const dispatch = useDispatch();
    const dataFields = useAppSelector(state => state.dataFields.dataFields);
    const availableRegions = useAppSelector(state => state.shop.availableRegions);
    const [formStatus, setFormStatus] = useState({ canSubmit: false });

    const p2pApiHandle = useRef<PinToPositionApiHandle | null>(null);

    const { supportedDataFields } = useDataFieldsBySupportedFeature({
      dataFields,
      feature: ShopDataFieldsSupportedFeatures.MerchandisingRules,
    });

    const { dataFieldsLookupTable, getDataFieldByName } = useDataFieldsLookupTable({
      dataFields: supportedDataFields,
    });

    const mappedRuleInitial = useMemo(() => {
      return ruleFormMappers.mapApiRuleToRuleDraft({
        rule,
        predefinedProduct,
        entityId,
        availableRegions,
      });
    }, [rule, predefinedProduct]);

    const [ruleDraft, setRuleDraft] = useState(mappedRuleInitial);

    const [isDirty, setIsDirty] = useState(false);

    const ruleSettings = ruleDraft.product
      ? syteProductToRuleSettingsMap[ruleDraft.product]
      : undefined;

    const { errors, validate, isValid } = useValidateSchema<
      Omit<RuleDraft, 'id' | 'entityId' | 'createdAt' | 'updatedAt'>
    >({
      schema: formValidationSchema,
      validateOnStart: false,
      initialData: mappedRuleInitial,
    });

    const canSubmit = isDirty && isValid;

    const validateRuleForm = useCallback(
      (ruleToValidate: RuleDraft) => {
        validate({
          dataToValidate: ruleToValidate,
          context: {
            dataFieldsLookupTable,
            ruleSettings,
            availableRegions,
          },
        });
      },
      [validate, dataFieldsLookupTable, ruleSettings, availableRegions]
    );

    useEffect(() => {
      setRuleDraft(mappedRuleInitial);

      validateRuleForm(mappedRuleInitial);
    }, [shopId, mappedRuleInitial, setRuleDraft, dataFieldsLookupTable]);

    useEffect(() => {
      setFormStatus({ canSubmit });
    }, [canSubmit]);

    const updateIsDirty = (dirty: boolean) => {
      setIsDirty(dirty);
    };

    const onFieldChange = useCallback(
      (partialData: Partial<RuleDraft>) => {
        const newDraftState = { ...ruleDraft, ...partialData };

        setRuleDraft(newDraftState);

        updateIsDirty(true);

        validate({
          dataToValidate: newDraftState,
          context: {
            dataFieldsLookupTable,
            ruleSettings,
            availableRegions,
          },
        });
      },
      [
        ruleDraft,
        dataFieldsLookupTable,
        ruleSettings,
        setRuleDraft,
        updateIsDirty,
        validate,
        availableRegions,
      ]
    );

    const [isModalOpened, setIsModalOpened] = useState(false);

    const isRedirectRuleEnabled = ruleDraft.action === MerchandisingRuleTypes.RuleAction.Redirect;
    const isPinToPositionRuleEnabled =
      ruleDraft.action === MerchandisingRuleTypes.RuleAction.PinToPosition;

    const actionOptions = useMemo(() => {
      const options = ruleSettings?.actions?.map(actionValue => (
        <MenuItem key={actionValue} value={actionValue}>
          {startCase(toLower(actionValue))}
        </MenuItem>
      ));

      return options;
    }, [ruleSettings?.actions]);

    const skipSkus = useMemo(() => {
      if (!isPinToPositionRuleEnabled) {
        return [];
      }

      return ruleDraft.subRules
        .filter(condition => !!condition.values[0])
        .map(condition => condition.values[0]) as string[];
    }, [ruleDraft.subRules, isPinToPositionRuleEnabled]);

    const onConditionChange = (changedCondition: DataFieldConditionDataType) => {
      const changedIndex = ruleDraft.subRules.findIndex(
        condition => condition.tempId === changedCondition.tempId
      );

      const updatedConditions = [...ruleDraft.subRules];
      updatedConditions[changedIndex] = changedCondition;

      onFieldChange({ subRules: updatedConditions });
    };

    const updateActionAndInitialRules = useCallback(
      (newAction: MerchandisingRuleTypes.RuleAction) => {
        const isNewActionRedirect = newAction === MerchandisingRuleTypes.RuleAction.Redirect;
        const isNewActionPinToPosition =
          newAction === MerchandisingRuleTypes.RuleAction.PinToPosition;

        let updatedState: Partial<RuleDraft> = {};

        if (!(isNewActionRedirect || isNewActionPinToPosition)) {
          updatedState = {
            action: newAction,
            subRules: [ruleFormMappers.createEmptyCondition()],
            redirectRule: null,
          };
        }

        if (isNewActionRedirect) {
          updatedState = {
            action: MerchandisingRuleTypes.RuleAction.Redirect,
            subRules: [],
          };
        }
        if (isNewActionPinToPosition) {
          updatedState = {
            action: MerchandisingRuleTypes.RuleAction.PinToPosition,
            subRules: [ruleFormMappers.createEmptyPinToPositionCondition()],
            redirectRule: null,
          };
        }

        onFieldChange(updatedState);
      },
      [onFieldChange]
    );

    const onActionChange: SelectOnChangeEvent = event => {
      const newAction = event.target.value as MerchandisingRuleTypes.RuleAction;

      updateActionAndInitialRules(newAction);
    };

    const onAddNewCondition = () => {
      const updatedConditions = isPinToPositionRuleEnabled
        ? [...ruleDraft.subRules, ruleFormMappers.createEmptyPinToPositionCondition()]
        : [...ruleDraft.subRules, ruleFormMappers.createEmptyCondition()];
      onFieldChange({ subRules: updatedConditions });
    };

    const onDeleteCondition = (conditionToDelete: RuleDraftCondition) => {
      const updatedConditions = ruleDraft.subRules.filter(
        condition => condition.tempId !== conditionToDelete.tempId
      );

      onFieldChange({ subRules: updatedConditions });
    };

    const onDeleteAllConditions = useCallback(() => {
      updateActionAndInitialRules(MerchandisingRuleTypes.RuleAction.PinToPosition);
    }, [updateActionAndInitialRules]);

    const closeModal = useCallback(() => {
      setIsModalOpened(false);
    }, [setIsModalOpened]);

    const openModal = useCallback(() => {
      setIsModalOpened(true);
    }, [setIsModalOpened]);

    const onAddMultipleItems = useCallback(
      (items: RuleDraftCondition[]) => {
        const updatedConditions = insertAndReorderPins({
          pinsToInsert: items,
          previousConditions: ruleDraft.subRules,
          maxLimitOfItems: MAX_POSITION_VALUE,
        });

        onFieldChange({ subRules: updatedConditions });
        closeModal();
      },
      [ruleDraft.subRules, closeModal, onFieldChange]
    );

    const showWeightInput = ruleActionsWithWeight.has(ruleDraft.action);

    const shouldShowAddButton = !isReadOnly && !ruleDraft.redirectRule;

    useEffect(() => {
      dispatch(visualEditorActions.notifyIsDirty({ isDirty }));
    }, [dispatch, isDirty]);

    return (
      <>
        <PageStyled layout='default'>
          <Page.Header>
            <Page.Title>Choose products to pin</Page.Title>
          </Page.Header>
          <Page.Content>
            <form>
              <>
                <ConditionsGroup>
                  {errors?.subRules?.message && (
                    <ErrorLabelStyled>{errors?.subRules?.message}</ErrorLabelStyled>
                  )}

                  <ConditionsGroup.Content>
                    <ConditionFirstRowStyled>
                      <ConditionText
                        type={TypographyType.Body}
                        variant={TypographyVariant.SmallMedium}
                      >
                        I want to
                      </ConditionText>
                      <Select
                        type={SelectType.Menu}
                        value={ruleDraft.action}
                        placeholder='Select action'
                        onChange={onActionChange}
                        disabled
                      >
                        {actionOptions}
                      </Select>

                      {showWeightInput && (
                        <WeightSliderPopover
                          onFieldChange={onFieldChange}
                          weight={ruleDraft.weight}
                          key={ruleDraft.weight}
                          isReadOnly={isReadOnly}
                        />
                      )}

                      {!isReadOnly && isPinToPositionRuleEnabled && (
                        <DeleteAllButtonStyled
                          disabled={ruleDraft.subRules.length === 0}
                          onClick={onDeleteAllConditions}
                          startIcon={AvailableIcons.TrashCan}
                          variant='tertiary'
                        >
                          Delete All
                        </DeleteAllButtonStyled>
                      )}
                    </ConditionFirstRowStyled>
                    <Conditions
                      conditions={ruleDraft.subRules}
                      errors={errors}
                      shopId={shopId}
                      dataFieldsLookupTable={dataFieldsLookupTable}
                      getDataFieldByName={getDataFieldByName}
                      conditionTypes={ruleSettings?.subRulesConditionTypes || []}
                      dispatch={dispatch}
                      isReadOnly={isReadOnly}
                      onConditionChange={onConditionChange}
                      onDeleteCondition={onDeleteCondition}
                      isRedirectRuleEnabled={isRedirectRuleEnabled}
                      isPinToPositionRuleEnabled={isPinToPositionRuleEnabled}
                      onFieldChange={onFieldChange}
                      redirectRule={ruleDraft.redirectRule}
                      isAiTagsEnabled={isAiTagsEnabled}
                      p2pApiRef={p2pApiHandle}
                    />

                    {shouldShowAddButton && (
                      <LinkButton onClick={onAddNewCondition}>
                        <Icon name={AvailableIcons.Plus} /> Add
                      </LinkButton>
                    )}
                    {shouldShowAddButton && isPinToPositionRuleEnabled && (
                      <LinkButton onClick={openModal}>
                        <Icon name={AvailableIcons.Plus} /> Add multiple items
                      </LinkButton>
                    )}
                  </ConditionsGroup.Content>
                </ConditionsGroup>
                <PinMultipleItemsModal
                  open={isModalOpened}
                  onClose={closeModal}
                  onAddItems={onAddMultipleItems}
                  skipSkus={skipSkus}
                />
              </>
            </form>
          </Page.Content>
        </PageStyled>
        <RuleFormFooter>
          <Button variant='tertiary' onClick={onClose}>
            Cancel
          </Button>
          <ButtonStyled
            variant='primary'
            disabled={!formStatus.canSubmit}
            onClick={() => onSave({ shopId, ruleDraft: ruleDraft as Required<RuleDraft> })}
          >
            Apply
          </ButtonStyled>
        </RuleFormFooter>
      </>
    );
  }
);
