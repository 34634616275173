import styled from '@emotion/styled';

export const FooterStyled = styled.footer`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  width: 100%;
  height: 65px;
  bottom: 0;
  left: 0;
  padding: 0 24px;
  z-index: 10;
  background: ${({ theme }) => theme.palette.common.white};
  > button + button {
    margin-left: 8px;
  }
  &.experiments-rule-form-footer {
    position: unset;
  }
`;
