import React, { PropsWithChildren, ReactNode } from 'react';
import classnames from 'classnames';
import {
  Tooltip,
  Icon,
  AvailableIcons,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import './TooltipedFormField.scss';

export interface TooltipedFormFieldProps {
  label: string;
  additionalInfo?: string | ReactNode;
  className?: string;
  error?: string | string[];
}

export const TooltipedFormField = ({
  label,
  additionalInfo,
  className,
  children,
  error,
}: PropsWithChildren<TooltipedFormFieldProps>): JSX.Element => (
  <div className={classnames('with-tooltiped-label', className, error?.length && 'error')}>
    <div className='label-wrapper'>
      <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
        {label}
      </Typography>
      {additionalInfo ? (
        <Tooltip value={additionalInfo} className={className ? `${className}__tooltip` : ''}>
          <Icon name={AvailableIcons.Error} />
        </Tooltip>
      ) : null}
    </div>
    {children}
    <span className='syte-error-label'>
      {Array.isArray(error)
        ? error.map(err => (
            <span key={err}>
              {err}
              <br />
            </span>
          ))
        : error}
    </span>
  </div>
);
