import styled from '@emotion/styled';
import { BlurredImageContainerStyled } from '../../../../../common/styles';
import { DeleteImageButtonStyled } from './DeleteImageButton.styles';

export const GalleryImageContainerStyled = styled(BlurredImageContainerStyled)<{
  alwaysShowDeleteButton: boolean;
}>`
  max-width: 250px;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  border: 2px solid transparent;

  ${DeleteImageButtonStyled} {
    opacity: ${({ alwaysShowDeleteButton }) => (alwaysShowDeleteButton ? 1 : 0)};
    transition: opacity 0.2s;
  }

  &:hover {
    border-color: ${({ theme }) => theme.palette.primary.main};

    ${DeleteImageButtonStyled} {
      opacity: 1;
    }
  }
`;
