import React from 'react';

import { AvailableIcons, Button } from '../../../components-dummy';
import { useOpenEditor } from '../useOpenEditor';

import type { UiTemplatesProps } from '../types';

import startEdit from '../images/start-edit.jpg';

import './Empty.scss';

export const Empty: React.FC<UiTemplatesProps> = ({ shopId, dispatch, openPopup }) => {
  const { open } = useOpenEditor({ shopId, dispatch, openPopup });

  return (
    <div className='empty-ui-templates-list'>
      <div className='start-edit-container'>
        <h2>Welcome to the UI editor</h2>
        <span className='start-edit-text'>
          Quickly design, preview, test and publish a Syte features with the UI Editor. Syte&apos;s
          no-code editor lets you change your Syte features appearance and functionality without
          touching a line of code.
        </span>
        <Button
          className='start-edit-button'
          variant='primary'
          endIcon={AvailableIcons.ArrowRightLight}
          onClick={open}
        >
          Let&apos;s start
        </Button>
      </div>
      <img src={startEdit} alt='start-edit-preview' />
    </div>
  );
};
