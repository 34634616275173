import { AvailableIcons } from 'src/components-dummy';
import { DomainEntityPath } from 'src/services';
import { ParentDomainBase } from '../../types';
import { rankingSubDomainGroup } from '../ranking-sub-domain-group';

export const discoveryButtonDomainGroup: ParentDomainBase = {
  domainKey: DomainEntityPath.DiscoveryButton,
  title: 'Discovery Button',
  icon: AvailableIcons.Star,
  subDomains: [{ ...rankingSubDomainGroup, domainKey: DomainEntityPath.DiscoveryButtonRanking }],
};
