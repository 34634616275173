import React, { useMemo, useRef, useState } from 'react';
import { Button } from '../Button/Button';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import { CircularProgress, CircularProgressSizes } from '../CircularProgress';
import {
  ContentStyled,
  HeaderStyled,
  ItemStyled,
  SearchInputStyled,
  ItemsListStyled,
  SelectValueTextStyled,
  SelectModalContentStyled,
  MessageStyled,
  SpinnerWrapperStyled,
} from './SelectModal.style';

export const SelectModalContentItemText = ({ children }: { children: string }): JSX.Element => (
  <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
    {children}
  </Typography>
);

/**
 * Main component
 */
export interface ChildrenArguments {
  filterTerm: string;
}

export interface SelectModalContentProps {
  title?: string | JSX.Element;
  searchPlaceholder?: string;
  valueSelectionText?: string;
  errorMessage?: string | JSX.Element;
  onCancel?: () => void;
  children: ({ filterTerm }: ChildrenArguments) => any; // callback function to map item text
  itemsLoading?: boolean;
}

export const SelectModalContent = ({
  title = 'Select',
  searchPlaceholder = 'Search...',
  valueSelectionText = 'Select value:',
  errorMessage,
  onCancel,
  children,
  itemsLoading,
}: SelectModalContentProps): JSX.Element => {
  const [filterTerm, setFilterTerm] = useState('');
  const hasItems = useRef(false);

  const handleSearchChange = (value: string) => {
    setFilterTerm(value);
  };

  const itemsResult = useMemo(() => {
    const items = children({ filterTerm });

    hasItems.current = !!items?.length;

    return items?.length ? (
      items
    ) : (
      <MessageStyled>
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
          No results found
        </Typography>
        {filterTerm && (
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            Try a different search term
          </Typography>
        )}
      </MessageStyled>
    );
  }, [filterTerm, children]);

  let itemsContent;

  if (errorMessage) {
    itemsContent = <MessageStyled>{errorMessage}</MessageStyled>;
  } else if (itemsLoading) {
    itemsContent = (
      <SpinnerWrapperStyled>
        <CircularProgress size={CircularProgressSizes.Medium} />
      </SpinnerWrapperStyled>
    );
  } else {
    const isScrollEnabled = itemsResult.length > 4;

    itemsContent = <ItemsListStyled enableScroll={isScrollEnabled}>{itemsResult}</ItemsListStyled>;
  }

  return (
    <SelectModalContentStyled>
      {onCancel && (
        <HeaderStyled>
          {typeof title === 'string' ? (
            <Typography type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
              {title}
            </Typography>
          ) : (
            title
          )}
          <Button variant='tertiary' onClick={onCancel}>
            Cancel
          </Button>
        </HeaderStyled>
      )}
      <ContentStyled>
        <SearchInputStyled
          placeholder={searchPlaceholder}
          value={filterTerm}
          onChange={handleSearchChange}
        />
        <SelectValueTextStyled>
          <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
            {hasItems.current ? valueSelectionText : ''}
          </Typography>
        </SelectValueTextStyled>
        {itemsContent}
      </ContentStyled>
    </SelectModalContentStyled>
  );
};

SelectModalContent.SelectItemText = SelectModalContentItemText;
SelectModalContent.SelectItem = ItemStyled;
