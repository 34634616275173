import React from 'react';
import styled from '@emotion/styled';
import {
  Typography,
  TypographyProps,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy/Typography';

export const ConfirmationDialogStyled = styled.div`
  min-width: 520px;
  overflow: hidden;
`;

export const TypographyTextStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const AutoCompleteWrapperStyled = styled.div`
  width: 100%;
  & > div {
    width: 100%;
    max-width: none;
    padding-right: 16px;
  }

  .Mui-disabled {
    background-color: ${({ theme }) => theme.palette.custom['gray-80']};
    input {
      background-color: ${({ theme }) => theme.palette.custom['gray-80']} !important;
    }
  }
`;

export const ErrorsMessagesWrapperStyled = styled.div`
  margin-bottom: 16px;
`;

export const TextWithAutoCompleteStyled = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;

  & > p:first-of-type {
    white-space: nowrap;
  }
`;

export const ErrorLabelStyled = styled((props: TypographyProps) => (
  <Typography {...props} type={TypographyType.Paragraph} variant={TypographyVariant.SmallRegular} />
))`
  color: ${({ theme }) => theme.palette.custom.red};
  margin-top: 4px;
`;
