import React from 'react';
import { MenuItem, SelectOnChangeEvent, SelectType } from '../../../../../components-dummy';
import { ParseJoiValidateResponse } from '../../../../../utils';
import { VisualEditorSettings } from '../../../types/visual-editor-settings';
import { DataFieldToDisplay } from '../../../types/data-field-to-display';
import { SelectDataFieldStyled } from './SelectDataField.styles';

interface SelectDataFieldProps {
  options: DataFieldToDisplay[];
  value: string;
  onChange: (value: DataFieldToDisplay) => void;
  errors: ParseJoiValidateResponse<VisualEditorSettings>;
}

export const SelectDataField: React.FC<SelectDataFieldProps> = ({
  options,
  value,
  onChange,
  errors,
}) => {
  const handleChange: SelectOnChangeEvent = event => {
    const targetOption = options.find(opt => opt.value === event.target.value);
    if (!targetOption) return;
    onChange({ ...targetOption, value: event.target.value });
  };

  return (
    <SelectDataFieldStyled
      type={SelectType.Menu}
      value={value}
      onChange={handleChange}
      isError={!!errors.value}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.text}
        </MenuItem>
      ))}
    </SelectDataFieldStyled>
  );
};
