import styled from '@emotion/styled';
import { Icon, Tooltip } from 'src/components-dummy';

export const DomainEventsWebSocketConnectionIndicator = styled.div<{ connected: boolean }>`
  width: 7px;
  height: 7px;
  background-color: ${({ connected, theme }) =>
    connected ? theme.palette.custom['green-success'] : theme.palette.custom.red};
  transition:
    background-color,
    opacity 0.5s;
  border-radius: 50%;
  opacity: 1;

  ${({ connected }) =>
    connected
      ? ''
      : `
  animation: blink-animation 2s linear infinite;

  @keyframes blink-animation {
    50% {
      opacity: 0.3;
    }
  }`}
`;

export const AdminSearchTriggerStyled = styled(Icon)`
  width: 20px;
  height: 20px;
  margin-right: 24px;
  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-10']};
    fill: ${({ theme }) => theme.palette.custom['gray-10']};
  }
`;

export const ContactSupportIconStyled = styled(Icon)`
  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-10']};
  }
`;

export const UserProfileAndSupportDivider = styled.div`
  margin-right: 24px;
`;

export const WebSocketConnectionIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  margin-right: 35px;
`;

export const TooltipSupportStyled = styled(Tooltip)`
  .syte-popup-content {
    height: 38px;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0;
    }
  }
`;

export const TooltipSelectedShopStyled = styled(Tooltip)`
  .syte-popup-content {
    height: 38px;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0;
    }
  }
`;

export const TooltipAccountListStyled = styled(Tooltip)`
  .syte-popup-content {
    height: 38px;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0;
    }
  }
`;

export const TooltipBackToAccountListStyled = styled(Tooltip)`
  .syte-popup-content {
    height: 38px;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0;
    }
  }
`;
