import React from 'react';
import {
  EditableTextBoxCell,
  EditableTextBoxCellProps,
  WarningIndication,
} from 'src/components-dummy';

export interface RankingNameCellProps<T extends Record<string, unknown>>
  extends EditableTextBoxCellProps<T> {
  isUsingInvalidDataField: boolean;
}
const COMPONENT_NAME = 'RankingNameCell';

export const RankingNameCell = <T extends Record<string, unknown>>({
  isUsingInvalidDataField,
  row,
  column,
  ...rest
}: RankingNameCellProps<T>): JSX.Element => {
  const dataId =
    column.cellProps && column.cellProps.prefixId
      ? `${column.cellProps.prefixId}_${COMPONENT_NAME}_index_${row.index}`
      : '';

  return (
    <div className='name-cell' data-id={dataId}>
      <EditableTextBoxCell {...rest} row={row} column={column} />
      <WarningIndication
        tooltipValue='The parameter is using an invalid data field'
        disableTooltip={!isUsingInvalidDataField}
        show={isUsingInvalidDataField}
        className='indication-icon'
      />
    </div>
  );
};
