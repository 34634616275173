import { AvailableIcons } from 'src/components-dummy';
import { DomainEntityPath } from 'src/services';
import { ParentDomainBase } from '../../types';
import { filterSetItemsSubDomainGroup } from '../filter-set-sub-domain';
import { augmentedSearchTuningSubDomainGroup } from './augmented-search-tuning-sub-domain-group';
import { rankingSubDomainGroup } from '../ranking-sub-domain-group';
import { augmentedSearchGeneralSettingsSubDomainGroup } from './augmented-search-general-settings-sub-domain-group';

export const augmentedSearchDomainGroup: ParentDomainBase = {
  domainKey: DomainEntityPath.AugmentedSearch,
  title: 'Augmented Search',
  icon: AvailableIcons.Search,
  subDomains: [
    { ...rankingSubDomainGroup, domainKey: DomainEntityPath.AugmentedSearchRanking },
    {
      ...augmentedSearchGeneralSettingsSubDomainGroup,
      domainKey: DomainEntityPath.AugmentedSearchGeneralSettings,
    },
    { ...filterSetItemsSubDomainGroup, domainKey: DomainEntityPath.AugmentedSearchFilterSet },
    { ...augmentedSearchTuningSubDomainGroup, domainKey: DomainEntityPath.AugmentedSearchTuning },
  ],
};
