import React, { useCallback, useRef, useState } from 'react';
import { generatePath } from 'react-router';
import { Filter, ShopDataField, SyteLayout } from 'src/services';
import { RoutedComponentProps } from 'src/app-routes';
import { AvailableIcons, Page, TypographyType, TypographyVariant } from 'src/components-dummy';
import { Dispatch } from 'src/components-bl/types';
import { FilterForm, FilterFormFooter, FilterFormApiRef } from '../FilterForm';
import {
  ButtonStyled,
  EditFilterPageStyled,
  GrayedOutTitleStyled,
  LearnMoreLink,
  LinkIconStyled,
  TitlesWrapper,
} from './EditFilterPage.styles';
import { editFilterPageActions } from './Actions';
import { BackToFiltersListStyled } from '../CreateFilterPage/CreateFilterPage.styles';

const learnMoreLink = 'https://support.syte.ai/space/ET/2938339368/Define+Filters+Settings';

interface EditFilterPageProps extends RoutedComponentProps {
  shopId?: number;
  syteLayout: SyteLayout;
  dispatch: Dispatch;
  availableDataFields: ShopDataField[] | undefined;
  filter: Filter | undefined;
  hasReachedMaxAllowedCustomOrderFiltersLimit?: boolean;
}

export const EditFilterPage = ({
  shopId,
  syteLayout,
  permittedRouteMap,
  dispatch,
  availableDataFields,
  filter,
  hasReachedMaxAllowedCustomOrderFiltersLimit,
}: EditFilterPageProps): JSX.Element => {
  const formApiRef = useRef<FilterFormApiRef | null>(null);
  const [canSubmitForm, setCanSubmitForm] = useState(false);

  const navigateToList = useCallback(() => {
    if (permittedRouteMap.filtersList && shopId) {
      dispatch(
        editFilterPageActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.filtersList.path, { shopId, syteLayout }),
        })
      );
    }
  }, [dispatch, shopId, syteLayout, permittedRouteMap.filtersList]);

  const onSave = useCallback(async () => {
    if (!formApiRef.current) {
      return;
    }

    try {
      await formApiRef.current.submit();

      navigateToList();
    } catch (error) {
      console.error('EditFilterPage', error);
    }
  }, [formApiRef.current, navigateToList]);

  return (
    <>
      <EditFilterPageStyled>
        <Page.Header>
          <TitlesWrapper>
            <GrayedOutTitleStyled
              variant={TypographyVariant.ExtraSmallBold}
              type={TypographyType.Heading}
            >
              <BackToFiltersListStyled onClick={navigateToList}>Filters</BackToFiltersListStyled>{' '}
              &gt;
            </GrayedOutTitleStyled>
            <Page.Title> Edit Filter</Page.Title>
          </TitlesWrapper>
          <LearnMoreLink>
            <a href={learnMoreLink} target='_blank' rel='noreferrer'>
              Learn more about our filtering options
              <LinkIconStyled name={AvailableIcons.ArrowRec} />
            </a>
          </LearnMoreLink>
        </Page.Header>
        <Page.Content>
          <FilterForm
            ref={formApiRef}
            mode='edit'
            shopId={shopId}
            syteLayout={syteLayout}
            dispatch={dispatch}
            filter={filter}
            permittedRouteMap={permittedRouteMap}
            availableDataFields={availableDataFields}
            onFormStatusChange={setCanSubmitForm}
            hasReachedMaxAllowedCustomOrderFiltersLimit={
              hasReachedMaxAllowedCustomOrderFiltersLimit
            }
          />
        </Page.Content>
      </EditFilterPageStyled>

      <FilterFormFooter>
        <ButtonStyled variant='tertiary' onClick={navigateToList}>
          Cancel
        </ButtonStyled>
        <ButtonStyled disabled={!canSubmitForm} variant='primary' onClick={onSave}>
          Save
        </ButtonStyled>
      </FilterFormFooter>
    </>
  );
};
