import React from 'react';
import styled from '@emotion/styled';
import {
  Badge,
  Typography,
  TypographyType,
  TypographyVariant,
  Icon,
  AvailableIcons,
} from 'src/components-dummy';

const TitleContainer = styled.div`
  display: flex;
  padding: 8px 8px 16px;
  align-items: center;

  .preview-badge {
    margin-left: 10px;
  }

  .internal-badge {
    margin-left: 10px;
  }
`;

const CloseButton = styled.div`
  margin-left: auto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #0073ff;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  height: 12px;
  width: 12px;
  margin-right: 2px;
`;

export function EditorTitle({
  setForInternalUseOnly,
  onClose,
  title,
  backButtonText,
}: {
  setForInternalUseOnly: boolean;
  onClose: VoidFunction;
  title: string;
  backButtonText: string;
}): JSX.Element {
  return (
    <TitleContainer>
      <Typography variant={TypographyVariant.LargeBold} type={TypographyType.Body}>
        {title}
      </Typography>
      <Badge text='Beta' className='preview-badge' background='primary' />
      {setForInternalUseOnly && (
        <Badge text='Internal' className='internal-badge' background='primary' />
      )}
      <CloseButton onClick={onClose}>
        <StyledIcon name={AvailableIcons.BackSmall} />
        {backButtonText}
      </CloseButton>
    </TitleContainer>
  );
}
