import { RankingType } from '../../../../../components-bl/RankingStrategy/RankingStrategyType/types';

export interface RankingStrategyWeight {
  field: SyteCoreRankingFields | string;
  weight: number;
  order: RankingOrder;
  name: string;
  enabled: boolean;
}

export interface RankingStrategy {
  weights: RankingStrategyWeight[];
  type?: RankingType;
}

export enum SyteCoreRankingFields {
  BestMatch = 'similarity',
  BestSellers = 'sales_count',
  TrendingItems = 'relevance',
  Personalization = 'personalization',
}

export enum RankingOrder {
  Desc = 'desc',
  Asc = 'asc',
}
