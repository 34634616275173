import styled from '@emotion/styled';
import { Typography, SearchInput, SectionBox } from 'src/components-dummy';

export const SectionBoxStyled = styled(SectionBox)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 40px 32px;
`;

export const SearchInputStyled = styled(SearchInput)`
  margin-bottom: 8px;
  width: 100%;

  .input-base-wrapper,
  .input-base-wrapper: focus,
  .input-base-wrapper: focus-within {
    border: unset !important;
    outline: unset !important;

    background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  }
`;

export const SubTitleTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  margin: 10px 0;
`;
