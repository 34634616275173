import { EditExperimentContainerAction } from 'src/containers';
import { DiscoveryBannerSettingsReducerState } from '../app-state-types';
import {
  DiscoveryBannerSettingsAction,
  DiscoveryBannerActionTypes,
  VariantPanelAction,
} from '../components-bl';
import { ImageLayout, BannerLayout } from '../app-state-types/reducer-state-types/discovery-banner';

const initialState: DiscoveryBannerSettingsReducerState = {
  general: {
    bannerLayout: BannerLayout.LEFT_TO_RIGHT,
    imageLayout: ImageLayout.GRID,
    bannerBackgroundColor: '#F8F8F8',
  },
  banner: {
    bannerColor: '#000',
    bannerFontFamily: 'sans-serif',
    bannerFontSize: 18,
  },
  button: {
    buttonBackgroundColor: '#000',
    buttonColor: '#fff',
    buttonFontFamily: 'sans-serif',
    buttonFontSize: 15,
  },
  icon: {
    showIcon: true,
    iconColor: '#000',
  },

  shouldRefetch: false,
};

export function discoveryBannerSettingsReducer(
  state: DiscoveryBannerSettingsReducerState = initialState,
  action?: DiscoveryBannerSettingsAction | EditExperimentContainerAction | VariantPanelAction
): DiscoveryBannerSettingsReducerState {
  switch (action?.type) {
    case DiscoveryBannerActionTypes.GetDiscoveryBannerSettingsSuccess: {
      const { general, banner, button, icon } = action.payload;
      return {
        ...state,
        general,
        banner,
        button,
        icon,
        shouldRefetch: false,
      };
    }
    case DiscoveryBannerActionTypes.GetDiscoveryBannerSettingsRequest:
      return { ...state };
    case DiscoveryBannerActionTypes.GetDiscoveryBannerSettingsError:
      return { ...state };
    case DiscoveryBannerActionTypes.UpdateDiscoveryBannerSettingsSuccess: {
      const { general, banner, button, icon } = action.payload;
      const updatedSettings = {
        ...state,
        general,
        banner,
        button,
        icon,
      };
      return {
        ...state,
        general: updatedSettings.general,
        banner: updatedSettings.banner,
        button: updatedSettings.button,
        icon: updatedSettings.icon,
      };
    }
    default:
      return state;
  }
}
