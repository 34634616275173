/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import {
  Button,
  DialogModal,
  RadioGroup,
  Switch,
  SwitchSizes,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import {
  DeepTagReportsGeneralConfigurationLabel,
  DeepTagReportsValidationErrorKey,
  DeepTagsReportsCustomBulkAssignLabels,
  DeepTagsReportsEvenBulkAssignLabels,
  IFilterKeyObject,
} from 'src/services/src/service/types/deep-tag-reports';
import { Dispatch } from 'src/components-bl';
import { BackDropWithLoader } from 'src/components-dummy/Backdrop/BackdropWithLoader';
import { ProductRouteFilters } from 'src/containers/DeepTagReports/components/DeepTagReportProductsContainer/components/types';
import { omit } from 'lodash';
import {
  ButtonsContainerStyled,
  ContentStyled,
  FrameContentStyled,
  HeaderStyled,
  SubTitleStyled,
  RadioWrapperStyled,
  RadioOptionWrapperStyled,
  RadioButtonsStyled,
  TypographyTextStyled,
  SwitchIsIncludeLabeledStyled,
} from './DeepTagsReportProductsBulkLabelModal.style';
import { deepTagsReportProductsBulkLabelActions } from './DeepTagsReportProductsBulkLabelModal.actions';
import {
  DeepTagReportProductsCustomLabelBulk,
  DeepTagReportProductsCustomLabelBulkProps,
} from './components/DeepTagReportProductsCustomLabelBulk/DeepTagReportProductsCustomLabelBulk';
import { CustomLabelBulkFormItem } from './components/DeepTagReportProductsCustomLabelBulk/DeepTagBulkCustomFormItem';
import { DeepTagReportProductsEvenLabelBulk } from './components/DeepTagReportProductsEvenLabelBulk/DeepTagReportProductsEvenLabelBulk';

const MODAL_STYLE = {
  '& .MuiDialog-container': {
    '& .MuiPaper-root': {
      width: '61%',
      maxWidth: '879px',
      minWidth: '500px',
      overflow: 'hidden',
    },
  },
};

export enum BulkTypeEnum {
  even_bulk = 'even_bulk',
  custom_bulk = 'custom_bulk',
}

/**
 * Main component
 */
export interface DeepTagsReportProductsBulkLabelModalProps {
  generalConfigurationLabels?: DeepTagReportsGeneralConfigurationLabel[];
  totalPerProductLabel: Record<string, IFilterKeyObject>;
  reportId: string;
  shopId: number;
  locale: string;
  getCleanedFiltersWithPagination: () => ProductRouteFilters;
  dispatch: Dispatch;
  onCancel: () => void;
  onContinue: () => void;
}

export const DeepTagsReportProductsBulkLabelModal = ({
  generalConfigurationLabels,
  totalPerProductLabel,
  reportId,
  shopId,
  locale,
  dispatch,
  getCleanedFiltersWithPagination,
  onCancel,
  onContinue,
}: DeepTagsReportProductsBulkLabelModalProps): JSX.Element => {
  const [selectedBulkType, setSelectedBulkType] = useState<BulkTypeEnum>(BulkTypeEnum.even_bulk);
  const [isInProcess, setIsInProcess] = useState<boolean>(false);
  const [isIncludeLabeledItems, setIsIncludeLabeledItems] = useState<boolean>(false);
  const isMountedRef = useRef<boolean>(true);

  /**
   * Main component
   */
  const totalProducts = useMemo(() => {
    return Object.values(totalPerProductLabel).reduce((prev, labelObject: IFilterKeyObject) => {
      return prev + labelObject.count;
    }, 0);
  }, [totalPerProductLabel]);

  const totalUnassignedLabels = useMemo(() => {
    const unassignedProductsToLabel = Object.values(totalPerProductLabel).find(
      filterObject => filterObject.name === null
    );

    return unassignedProductsToLabel?.count || 0;
  }, [totalPerProductLabel]);

  const getReportProductsWithFilters = useCallback(async (): Promise<unknown> => {
    if (shopId && reportId) {
      await dispatch(deepTagsReportProductsBulkLabelActions.getLabelsGeneralConfiguration());
      const filtersWithPagination = getCleanedFiltersWithPagination();

      return dispatch(
        deepTagsReportProductsBulkLabelActions.getReportProducts({
          shopId,
          locale,
          reportId,
          ...filtersWithPagination,
        })
      );
    }

    return null;
  }, [getCleanedFiltersWithPagination, locale, reportId, shopId, dispatch]);

  /**
   * Even Bulk
   */
  const [evenBulkSelectedLabelIds, setEvenBulkSelectedLabelIds] = useState<string[]>([]);
  const [isEvenBulkFormValid, setIsEvenBulkFormValid] = useState<boolean>(false);

  const onIsEvenBulkFormValidChange = useCallback((isValid: boolean) => {
    setIsEvenBulkFormValid(isValid);
  }, []);

  const onSelectedValuesChange = useCallback((selectedLabelIds: string[]) => {
    setEvenBulkSelectedLabelIds(selectedLabelIds);
  }, []);

  const onAssignEvenBulk = async () => {
    const currentGeneralLabelsConfiguration = await (
      dispatch(deepTagsReportProductsBulkLabelActions.getLabelsGeneralConfiguration()) as any
    ).unwrap();

    const labelsMap = (
      currentGeneralLabelsConfiguration.generalConfigurationLabels as DeepTagReportsGeneralConfigurationLabel[]
    ).reduce(
      (
        prev: {
          labelByName: Record<string, DeepTagReportsGeneralConfigurationLabel>;
          labelById: Record<string, DeepTagReportsGeneralConfigurationLabel>;
        },
        next
      ) => {
        prev.labelByName[next.name] = next;
        prev.labelById[next.id] = next;
        return prev;
      },
      { labelByName: {}, labelById: {} }
    );

    const labelsToAssign: DeepTagsReportsEvenBulkAssignLabels[] = evenBulkSelectedLabelIds.map(
      selectedValue => {
        const labelId =
          labelsMap.labelById[selectedValue]?.id || labelsMap.labelByName[selectedValue].id;

        return { id: labelId };
      }
    );

    try {
      setIsInProcess(true);

      const filtersWithPagination = getCleanedFiltersWithPagination();
      const filters = omit(filtersWithPagination, ['skip', 'limit']);

      await (
        dispatch(
          deepTagsReportProductsBulkLabelActions.bulkEvenAssignProductLabels({
            labels: labelsToAssign,
            filters,
            reportId,
            shopId,
            shouldOnlyIncludeUnlabeled: !isIncludeLabeledItems,
          })
        ) as any
      ).unwrap();

      getReportProductsWithFilters();
      onContinue();
    } catch (error: any) {
      console.error(error);

      if (
        error?.error?.errorKey ===
        DeepTagReportsValidationErrorKey.DeepTagReportsBulkSomeGeneralLabelsNotExisted
      ) {
        // Fetch list - updated available labels
        dispatch(deepTagsReportProductsBulkLabelActions.getLabelsGeneralConfiguration());
      }

      // fetch latest updated products
      getReportProductsWithFilters();
    } finally {
      if (isMountedRef.current) {
        setIsInProcess(false);
      }
    }
  };

  /**
   * Custom Bulk
   */
  const [isCustomBulkFormValid, setIsCustomBulkFormValid] = useState<boolean>(false);
  const [customBulkFormData, setCustomBulkFormData] = useState<CustomLabelBulkFormItem[]>([]);

  const onIsCustomBulkFormValidChange = useCallback((isValid: boolean) => {
    setIsCustomBulkFormValid(isValid);
  }, []);

  const onAssignCustomBulk = async () => {
    const mappedData = customBulkFormData.map(item => {
      return {
        labelId: item.labelId,
        totalProducts: item.totalProducts,
      };
    });

    try {
      setIsInProcess(true);

      const filtersWithPagination = getCleanedFiltersWithPagination();
      const filters = omit(filtersWithPagination, ['skip', 'limit']);

      await (
        dispatch(
          deepTagsReportProductsBulkLabelActions.bulkCustomAssignProductLabels({
            totalProductsPerLabel: mappedData as DeepTagsReportsCustomBulkAssignLabels[],
            reportId,
            shopId,
            filters,
            shouldOnlyIncludeUnlabeled: !isIncludeLabeledItems,
          })
        ) as any
      ).unwrap();

      getReportProductsWithFilters();
      onContinue();
    } catch (error: any) {
      console.error(error);

      if (
        error?.error?.errorKey ===
        DeepTagReportsValidationErrorKey.DeepTagReportsBulkSomeGeneralLabelsNotExisted
      ) {
        // Fetch list - updated available labels
        dispatch(deepTagsReportProductsBulkLabelActions.getLabelsGeneralConfiguration());
      }

      // fetch latest updated products
      getReportProductsWithFilters();
    } finally {
      if (isMountedRef.current) {
        setIsInProcess(false);
      }
    }
  };

  const onCustomBulkFormDataChange: DeepTagReportProductsCustomLabelBulkProps['onChange'] =
    useCallback(formData => {
      setCustomBulkFormData(formData);
    }, []);

  /**
   * Radio Buttons & state clear
   */

  const clearAllState = () => {
    // Even bulk
    setEvenBulkSelectedLabelIds([]);
    setIsEvenBulkFormValid(false);

    // Custom bulk
    setCustomBulkFormData([]);
    setIsCustomBulkFormValid(false);
  };

  const onRadioButtonChange = (type: BulkTypeEnum) => {
    clearAllState();
    setSelectedBulkType(type);
    getReportProductsWithFilters();
  };

  useEffect(() => {
    return () => {
      // Component unmount cleanup
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    getReportProductsWithFilters();
  }, []);

  const totalAvailableProductsToAssign = isIncludeLabeledItems
    ? totalProducts
    : totalUnassignedLabels;

  return (
    <>
      <DialogModal open onClose={onCancel} sx={MODAL_STYLE}>
        <div>
          {isInProcess && <BackDropWithLoader />}
          <HeaderStyled>
            <ConfirmationDialog.Title>Bulk Assign Products</ConfirmationDialog.Title>
          </HeaderStyled>
          <ContentStyled>
            <SubTitleStyled>
              <TypographyTextStyled
                type={TypographyType.Paragraph}
                variant={TypographyVariant.MediumRegular}
              >
                <span>The batch contains </span>
                <b>{totalProducts}</b>
                <span> products, with </span>
                <b>{totalAvailableProductsToAssign}</b>
                &nbsp;
                <span>currently unlabeled products.</span>
                <br />
                Utilize the form below to apply labels to all the unlabeled products.
              </TypographyTextStyled>
            </SubTitleStyled>
            <SwitchIsIncludeLabeledStyled>
              <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
                Include labeled items (reassign)
              </Typography>
              <Switch
                size={SwitchSizes.Medium}
                checked={isIncludeLabeledItems}
                onChange={setIsIncludeLabeledItems}
              />
            </SwitchIsIncludeLabeledStyled>

            <RadioButtonsStyled>
              <FrameContentStyled>
                <RadioWrapperStyled>
                  <RadioGroup
                    selectedValue={selectedBulkType}
                    onChange={onRadioButtonChange}
                    disabled={false}
                    options={[
                      {
                        value: BulkTypeEnum.even_bulk,
                        label: (
                          <RadioOptionWrapperStyled>
                            <Typography
                              type={TypographyType.Body}
                              variant={TypographyVariant.MediumMedium}
                            >
                              Even Bulk Label Products
                            </Typography>
                          </RadioOptionWrapperStyled>
                        ),
                      },
                    ]}
                  />
                  {selectedBulkType === BulkTypeEnum.even_bulk && (
                    <>
                      <DeepTagReportProductsEvenLabelBulk
                        totalAvailableProductsToAssign={totalAvailableProductsToAssign}
                        selectedValues={evenBulkSelectedLabelIds}
                        onSelectedValuesChange={onSelectedValuesChange}
                        onIsFormValidChange={onIsEvenBulkFormValidChange}
                        isIncludeLabeledItems={isIncludeLabeledItems}
                        dispatch={dispatch}
                      />
                    </>
                  )}
                </RadioWrapperStyled>
              </FrameContentStyled>
              <FrameContentStyled>
                <RadioWrapperStyled>
                  <RadioGroup
                    selectedValue={selectedBulkType}
                    onChange={onRadioButtonChange}
                    disabled={false}
                    options={[
                      {
                        value: BulkTypeEnum.custom_bulk,
                        label: (
                          <RadioOptionWrapperStyled>
                            <Typography
                              type={TypographyType.Body}
                              variant={TypographyVariant.MediumMedium}
                            >
                              Custom Bulk Label Products
                            </Typography>
                          </RadioOptionWrapperStyled>
                        ),
                      },
                    ]}
                  />
                  {selectedBulkType === BulkTypeEnum.custom_bulk && (
                    <>
                      <DeepTagReportProductsCustomLabelBulk
                        freeSolo
                        multiple
                        disabled={totalAvailableProductsToAssign === 0}
                        totalAvailableProductsToAssign={totalAvailableProductsToAssign}
                        selectedValues={evenBulkSelectedLabelIds}
                        generalConfigurationLabels={generalConfigurationLabels || []}
                        setSelectedValues={setEvenBulkSelectedLabelIds}
                        onIsFormValidChange={onIsCustomBulkFormValidChange}
                        onChange={onCustomBulkFormDataChange}
                        dispatch={dispatch}
                      />
                    </>
                  )}
                </RadioWrapperStyled>
              </FrameContentStyled>
            </RadioButtonsStyled>
          </ContentStyled>
          <ConfirmationDialog.Footer>
            <ButtonsContainerStyled>
              {selectedBulkType === BulkTypeEnum.even_bulk ? (
                <Button
                  variant='primary'
                  onClick={onAssignEvenBulk}
                  disabled={!isEvenBulkFormValid || !evenBulkSelectedLabelIds.length}
                >
                  Continue
                </Button>
              ) : (
                <Button
                  variant='primary'
                  onClick={onAssignCustomBulk}
                  disabled={!isCustomBulkFormValid || !customBulkFormData.length}
                >
                  Continue
                </Button>
              )}
              <Button variant='outlined' onClick={onCancel}>
                Cancel
              </Button>
            </ButtonsContainerStyled>
          </ConfirmationDialog.Footer>
        </div>
      </DialogModal>
    </>
  );
};
