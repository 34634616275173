import styled from '@emotion/styled';
import { EllipsisWithTooltip } from '../../EllipsisWithToolTip';

export const FileCardStyled = styled.div`
  display: grid;
  grid-template-columns: 24px minmax(0, 1fr) min-content; /* file icon, file name, close button */
  justify-items: start;
  align-items: center;
  column-gap: 12px;

  padding: 0px 16px;
  height: 48px;
  width: 372px;
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.custom['gray-80']};
`;

export const FileNameWithTooltipStyled = styled(EllipsisWithTooltip)`
  width: 100%;
`;
