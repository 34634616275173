import React from 'react';
import { RadioGroup as RadioGroupMui } from '@mui/material';
import { RadioButton, RadioProps } from './RadioButton';
import { RadioValue } from './types';

export interface RadioGroupParameters {
  options: RadioProps[];
  onChange: (value: any) => void;
  disabled?: boolean;
  selectedValue: RadioValue;
  name?: string;
}
export const RadioGroup = ({
  options,
  disabled,
  onChange,
  selectedValue,
  name,
}: RadioGroupParameters): JSX.Element => {
  return (
    <RadioGroupMui name={name} onChange={event => onChange(event.target.value)}>
      {options.map((option: RadioProps) => {
        return (
          <RadioButton
            key={option.value}
            checked={option.value === selectedValue}
            value={option.value}
            label={option.label}
            disabled={disabled}
          />
        );
      })}
    </RadioGroupMui>
  );
};
