import { createAsyncThunk, AsyncThunkPayloadCreator } from '@reduxjs/toolkit';
import { ThunkOptions, IAsyncAction } from './types';

// use createAsyncTask in case you might use it in a switch reducer. normally use createReducer + addCase for toolkit actions
export function createAsync<
  TypePrefix extends string,
  ReturnObject,
  ThunkArguments,
  ErrorObject = unknown,
>(
  typePrefix: TypePrefix,
  payloadCreator: AsyncThunkPayloadCreator<ReturnObject, ThunkArguments, ThunkOptions<ErrorObject>>
): IAsyncAction<TypePrefix, ThunkArguments, ReturnObject, ErrorObject> {
  const thunk = createAsyncThunk(typePrefix, payloadCreator);
  return thunk as IAsyncAction<TypePrefix, ThunkArguments, ReturnObject, ErrorObject>;
}
