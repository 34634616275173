import styled from '@emotion/styled';
import { Icon, Page } from 'src/components-dummy';
import { Skeleton } from 'src/components-dummy/Skeleton';

export const PageStyled = styled(Page)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .syte-page-header {
    border-bottom: none;
    display: flex;
    justify-content: space-between;
  }

  .syte-page-sub-title {
    a {
      color: ${props => props.theme.palette.custom['primary-main']};
    }
  }

  .syte-page-content {
    display: flex;
    flex-direction: column;
    padding-top: unset;
    height: 100%;
    justify-content: space-between;
  }

  background: ${({ theme }) => theme.palette.custom['gray-90']};
`;

export const StyledSkeleton = styled(Skeleton)`
  margin: 10px;
`;

export const PageTitlesWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const BackIconStyled = styled(Icon)`
  width: 10px;
  height: 10px;
  transform: rotate(180deg);
  margin-right: 20px;

  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-20']};
  }

  &:hover path {
    stroke: ${({ theme }) => theme.palette.common.black};
  }
`;
