import React, { useState } from 'react';
import { NavTabs } from '../../../../components-dummy';
import { PostThumbnail } from '../PostThumbnail/PostThumbnail';
import { DeleteConfirmationModal } from '../DeleteConfirmationModal/DeleteConfirmationModal';
import { PublicPost } from '../../../../components-bl/socialDiscovery/types/entities.types';
import './PostsTabs.scss';

export interface PostTabsProps {
  selectedPost: number | null;
  socialPosts: PublicPost[];
  onDeletePost: (index: number) => void;
  onSelectPost: (tabName: string) => void;
}

export const PostsTabs = ({
  socialPosts,
  selectedPost,
  onSelectPost,
  onDeletePost,
}: PostTabsProps): JSX.Element => {
  const selectedTab = selectedPost !== null ? selectedPost.toString() : '';
  const [deleteIndex, setDeleteIndex] = useState<null | number>(null);
  const handleDeletePost = () => {
    onDeletePost(deleteIndex);
    setDeleteIndex(null);
  };
  return (
    <>
      <NavTabs selected={selectedTab} onSelect={onSelectPost}>
        {socialPosts.map((post, index) => {
          const tabName = `${index}`;
          return (
            <NavTabs.NavTab name={tabName} key={post.id} className='story-thumbnail-nav'>
              <PostThumbnail
                imgSrc={post.imageUrl}
                isSelected={selectedTab === tabName}
                onDelete={() => setDeleteIndex(index)}
              />
            </NavTabs.NavTab>
          );
        })}
      </NavTabs>
      <DeleteConfirmationModal
        titleText='Delete Story'
        modalBodyText='Are you sure you want to delete this story?'
        show={deleteIndex !== null}
        onClose={() => setDeleteIndex(null)}
        onDelete={handleDeletePost}
      />
    </>
  );
};
