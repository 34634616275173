import { AvailableIcons } from 'src/components-dummy';
import { DomainEntityPath, RecEnginesGeneralSettingsType } from 'src/services';
import { DomainGroup, SubDomainGroupBase } from '../../types';
import { recEnginesGeneralSettingsDiffSchema } from './rec-engines-general-settings-diff-schema';

export const recEnginesGeneralSettingsDomainGroup: DomainGroup &
  SubDomainGroupBase<RecEnginesGeneralSettingsType.RecEnginesGeneralSettingsSchema> = {
  domainKey: DomainEntityPath.RecEnginesGeneralSettings,
  title: 'Recommendation Engines General Settings',
  icon: AvailableIcons.HollowStar,
  getCardTitle: ({ entityPath }) => {
    const titleResult = [''];
    switch (entityPath) {
      case 'shouldOpenPDPInNewTab': {
        titleResult.push('Open PDP In New Tab');
        break;
      }
      case 'overrideImageUrl': {
        titleResult.push('Override div Image URL');
        break;
      }
      case 'removeDuplicates': {
        titleResult.push('Remove Duplicate Results');
        break;
      }
      case 'addToCart': {
        titleResult.push('Add To Cart');
        break;
      }
      default:
        break;
    }
    return titleResult.join('');
  },
  fieldsSchema: recEnginesGeneralSettingsDiffSchema,
};
