import styled from '@emotion/styled';
import { Icon } from 'src/components-dummy';
import { Bullet } from '../Bullet';
import { DeepTagReportStatusSelectStyled } from '../DeepTagReportStatusSelect';

export const BackIconStyled = styled(Icon)`
  width: 10px;
  height: 10px;
  transform: rotate(180deg);
  margin-right: 20px;

  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-20']};
  }

  &:hover path {
    stroke: ${({ theme }) => theme.palette.common.black};
  }
`;

export const TitleRowStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const SubTitleRowStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
`;

export const MetaDataTextStyled = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.custom['gray-20']};
  padding-top: 4px;

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const BulletStyled = styled(Bullet)`
  margin-left: 8px;
`;

export const ButtonsContainerStyled = styled.div`
  display: flex;
  align-items: center;

  ${DeepTagReportStatusSelectStyled} {
    width: 100px !important;
    > .MuiOutlinedInput-root {
      width: 100px !important;
    }
  }
`;

export const DeepTagReportEditableNameWrapper = styled.div`
  overflow: auto;

  input {
    height: 32px;
    max-width: 680px;
    font-style: normal;
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    font-family: 'Inter' !important;
    letter-spacing: -0.02em !important;
  }
`;
