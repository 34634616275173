import React from 'react';
import {
  AvailableIcons,
  Button,
  CardItem,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { ActionButtonsWrapperStyled, CardWrapperStyled } from './EmptyStateCard.styles';

interface EmptyStateCardProps {
  navigateToCreateFilter: () => void;
}

export const EmptyStateCard = ({ navigateToCreateFilter }: EmptyStateCardProps): JSX.Element => {
  return (
    <CardWrapperStyled className='empty-state'>
      <CardItem className='filters-list-card'>
        <Typography
          className='title'
          type={TypographyType.Heading}
          variant={TypographyVariant.SmallBold}
        >
          First time here ? Well, your filter list is empty
        </Typography>
        <Typography
          className='subtitle'
          type={TypographyType.Body}
          variant={TypographyVariant.LargeRegular}
        >
          Let’s start by adding the very first filter to your list. We recommend these filters as
          your starting point - Price, Brand, Gender and Size
        </Typography>
        <ActionButtonsWrapperStyled>
          <Button onClick={navigateToCreateFilter} startIcon={AvailableIcons.Add}>
            Add New Filter
          </Button>
        </ActionButtonsWrapperStyled>
      </CardItem>
    </CardWrapperStyled>
  );
};
