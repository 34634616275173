import styled from '@emotion/styled';

export const CardWrapperStyled = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;

  .filters-list-card {
    padding: 43px;
    width: 100%;
    text-align: center;

    .title {
      line-height: 24px;
      margin-bottom: 8px;
    }

    .subtitle {
      color: #6e6e71;
    }
  }
`;

export const ActionButtonsWrapperStyled = styled.div`
  display: flex;
  justify-content: center;

  margin-top: 20px;

  path {
    fill: ${({ theme }) => theme.palette.common.white};
    stroke: ${({ theme }) => theme.palette.common.white};
  }
`;
