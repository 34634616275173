import { ShopsTableHeadRow } from '../types';

export const tableColumns: Record<string, ShopsTableHeadRow> = {
  name: {
    header: 'Catalog Name',
    accessor: 'name',
    width: '25%',
  },
  shopId: {
    header: 'Shop Id',
    accessor: 'shopId',
    width: '10%',
  },
  shopName: {
    header: 'Shop Name',
    accessor: 'shopName',
    width: '15%',
  },
  isPrimary: {
    header: 'Primary',
    accessor: 'isPrimary',
    width: '10%',
  },
  isAugmentedSearchCatalog: {
    header: 'Text Search',
    accessor: 'isAugmentedSearchCatalog',
    width: '10%',
  },
  isMultiLocale: {
    header: 'Multi Locale',
    accessor: 'isMultiLocale',
    width: '10%',
  },
  createdAt: {
    header: 'Created At',
    accessor: 'createdAt',
    width: '20%',
  },
};
