import React, { ReactNode } from 'react';
import classNames from 'classnames';

export interface FormCardButtonProps {
  children: ReactNode | ReactNode[];
  className?: string;
}

export const FormCardButton = ({ children, className }: FormCardButtonProps): JSX.Element => {
  return <div className={classNames('syte-form-card-button', className)}>{children}</div>;
};
