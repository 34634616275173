import React from 'react';
import { Button } from 'src/components-dummy/Button';
import { FooterActionsStyled } from '../../DeepTagsProductForm.style';
import {
  DeepTagProductStatusSelectWithButton,
  OnStatusButtonClickArguments,
} from '../../../DeepTagProductStatusSelect/DeepTagProductStatusWithButton';

interface DeepTagsProductFormFooterActionsProps {
  isInProcess: boolean;
  isFormValid: boolean;
  onCancelClick: () => void;
  onSaveChanges: (args: OnStatusButtonClickArguments) => void;
}

export const DeepTagsProductFormFooterActions = ({
  isInProcess,
  isFormValid,
  onSaveChanges,
  onCancelClick,
}: DeepTagsProductFormFooterActionsProps): JSX.Element => {
  const handleSaveButtonClick = ({ status }: OnStatusButtonClickArguments) => {
    onSaveChanges({ status });
  };

  return (
    <FooterActionsStyled>
      <Button variant='tertiary' onClick={onCancelClick}>
        Cancel
      </Button>
      <DeepTagProductStatusSelectWithButton
        isDisabled={isInProcess || !isFormValid}
        onButtonClick={handleSaveButtonClick}
      />
    </FooterActionsStyled>
  );
};
