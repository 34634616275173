import React, { useCallback, useEffect } from 'react';
import {
  IDeepTagReportProduct,
  IDeepTagsProductDataItemPatchAPI,
  ILexiconCategories,
} from 'src/services';
import { Dispatch } from 'src/components-bl/types';
import { AvailableIcons, Button, Icon, Typography, TypographyVariant } from 'src/components-dummy';
import isEqual from 'lodash/isEqual';
import { useDeepTagsSubProductsForm } from './useDeepTagsSubProductsForm';
import {
  DeepTagsMainListForm,
  DeepTagsMainListFormProps,
} from '../DeepTagsMainListForm/DeepTagsMainListForm';
import { useDeepTagsMainProductForm } from './useDeepTagsMainProductForm';
import { DeepTagsProductFormFooterActions } from '../DeepTagsProductFormFooter/DeepTagsProductFormFooter';
import {
  DeepTagsProductReviewAndEditTagsSectionStyled,
  GenerateButtonWrapperStyled,
} from './DeepTagsProductReviewAndEditTagsSection.style';
import { UseDeepTagsSubProductsListTabsResponse } from '../DeepTagsSubProductsListTabs';
import { filterAndMapTags } from './DeepTagsProductReviewAndEditTagsSection.helpers';
import {
  OnGeneratedAttributeEdit,
  OnIsDirtyChange,
  OnSaveProductChanges,
} from '../../DeepTagsProductForm.types';

export interface DeepTagsProductReviewAndEditTagsSectionProps {
  deepTagProduct: Omit<
    IDeepTagReportProduct,
    'status' | 'labels' | 'title' | 'comments' | 'updatedAt'
  >;
  lexiconCategories?: ILexiconCategories;
  reportLocale: string;
  onCancel: DeepTagsMainListFormProps['onCancel'];
  onSaveChanges: OnSaveProductChanges;
  onGenerateButtonClick?: () => void;
  isLoadingLexicon: DeepTagsMainListFormProps['isLoadingLexicon'];
  isLexiconEnabled: DeepTagsMainListFormProps['isLexiconEnabled'];
  isDirty: boolean;
  shouldHideEditIndication: DeepTagsMainListFormProps['shouldHideEditIndication'];
  setIsInProcess: React.Dispatch<React.SetStateAction<boolean>>;
  isInProcess: boolean;
  onTabIndexChange: UseDeepTagsSubProductsListTabsResponse['onTabIndexChange'];
  selectedTabIndex: UseDeepTagsSubProductsListTabsResponse['selectedTabIndex'];
  onGeneratedAttributeEdit: OnGeneratedAttributeEdit;
  onIsDirtyChange: OnIsDirtyChange;
  dispatch: Dispatch;
}

export const DeepTagsProductReviewAndEditTagsSection = React.memo(
  ({
    deepTagProduct,
    lexiconCategories,
    reportLocale,
    onCancel,
    onSaveChanges,
    onGenerateButtonClick,
    dispatch,
    setIsInProcess,
    onGeneratedAttributeEdit,
    onIsDirtyChange,
    selectedTabIndex,
    isInProcess,
    isDirty,
    isLexiconEnabled,
    isLoadingLexicon,
    shouldHideEditIndication,
  }: DeepTagsProductReviewAndEditTagsSectionProps): JSX.Element => {
    const productDataList = deepTagProduct.data;
    const productTagsList = deepTagProduct.tags;

    const {
      subProductItemsFormData,
      isSubProductsFormValid,
      onChangeSubProductTagsFormData,
      onChangeSubProductCategoryFormData,
      onSetSubProductCategoryHasChangedFormData,
    } = useDeepTagsSubProductsForm({ productDataListFromAPI: productDataList, lexiconCategories });

    const { productTagItemsFormData, onChangeMainProductTagsFormData, isMainProductTagsFormDirty } =
      useDeepTagsMainProductForm({ productMainTagsFromAPI: productTagsList });

    const onSaveChangesClick = useCallback(
      async ({ status }) => {
        setIsInProcess(true);

        /**
         * Map Data list (sub products) for update
         */
        const mappedDataListForUpdate = Object.values(subProductItemsFormData).map(dataObject => {
          const filteredAndMappedTags = filterAndMapTags(dataObject.tags);

          return {
            id: dataObject.id,
            tags: filteredAndMappedTags,
            category: {
              name: dataObject.category.name,
              translation: dataObject.category.translation,
            },
          };
        }, {} as IDeepTagsProductDataItemPatchAPI[]);

        /**
         * Map Product main for update
         */
        const filteredAndMappedTags = filterAndMapTags(productTagItemsFormData);

        const productToUpdate = {
          data: mappedDataListForUpdate,
          tags: filteredAndMappedTags,
          status,
        };

        const response = (await onSaveChanges({
          partialProduct: productToUpdate,
          shouldGoToNextItem: true,
        })) as any;

        setIsInProcess(false);

        if (response.error) {
          console.error('Unable to update due to:', response.error);
          return;
        }

        onIsDirtyChange(false);
      },
      [
        subProductItemsFormData,
        productTagItemsFormData,
        setIsInProcess,
        onSaveChanges,
        onIsDirtyChange,
      ]
    );

    const selectedSubProductOriginal = productDataList[selectedTabIndex];
    const selectedSubProductFormData = subProductItemsFormData[selectedSubProductOriginal.id];

    const hasTags = !!selectedSubProductFormData?.tags?.length; // relevant for generative AI - incase there are no tags - no use in generating title/description

    const isGenerateButtonDisabled = !onGenerateButtonClick || isDirty || isInProcess || !hasTags;

    useEffect(() => {
      const subProductsIsDirtyList = Object.values(subProductItemsFormData).map(subProduct => {
        return !!subProduct.isDirty;
      });

      const isSomeSubProductDirty = subProductsIsDirtyList.some(
        isItemDirty => isItemDirty === true
      );

      const isProductTagsOrSubItemsDirty = isSomeSubProductDirty || isMainProductTagsFormDirty;

      if (isProductTagsOrSubItemsDirty !== isDirty) {
        onIsDirtyChange(isProductTagsOrSubItemsDirty);
      }
    }, [onIsDirtyChange, isDirty, isMainProductTagsFormDirty, subProductItemsFormData]);

    return (
      <DeepTagsProductReviewAndEditTagsSectionStyled>
        <>
          {selectedSubProductFormData?.id && (
            <DeepTagsMainListForm
              reportLocale={reportLocale}
              subProductOriginal={selectedSubProductOriginal}
              subProductFormData={selectedSubProductFormData}
              productMainTagsOriginal={productTagsList}
              productFormDataTags={productTagItemsFormData}
              isInProcess={isInProcess}
              lexiconCategories={lexiconCategories}
              dispatch={dispatch}
              onChangeSubProductTags={onChangeSubProductTagsFormData(selectedSubProductOriginal.id)}
              onChangeMainProductTags={onChangeMainProductTagsFormData}
              onChangeCategory={onChangeSubProductCategoryFormData(selectedSubProductOriginal.id)}
              onSetCategoryHasChanged={onSetSubProductCategoryHasChangedFormData(
                selectedSubProductOriginal.id
              )}
              onCancel={onCancel}
              onGeneratedAttributeEdit={
                isGenerateButtonDisabled ? undefined : onGeneratedAttributeEdit
              }
              isLexiconEnabled={isLexiconEnabled}
              isLoadingLexicon={isLoadingLexicon}
              isDirty={isDirty}
              shouldHideEditIndication={shouldHideEditIndication}
            />
          )}
        </>
        <GenerateButtonWrapperStyled>
          <Button
            variant='primary'
            disabled={isGenerateButtonDisabled}
            onClick={onGenerateButtonClick}
          >
            <Icon name={AvailableIcons.GenerateStars} />
            <Typography variant={TypographyVariant.MediumRegular}>Content Generation</Typography>
          </Button>
        </GenerateButtonWrapperStyled>
        <DeepTagsProductFormFooterActions
          onSaveChanges={onSaveChangesClick}
          isFormValid={isSubProductsFormValid && isDirty}
          isInProcess={isInProcess}
          onCancelClick={onCancel}
        />
      </DeepTagsProductReviewAndEditTagsSectionStyled>
    );
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps);
  }
);
