import { useEffect, useState, useCallback } from 'react';
import { useValidateSchema } from 'src/hooks';
import { ShopTheLookSettingsReducerState } from 'src/app-state-types';
import {
  UseShopTheLookSettingsDraftArgs,
  UseShopTheLookSettingsDraft,
  ShopTheLookSettingsRecord,
} from './types';
import { shopTheLookSettingsValidationSchema } from './constants';
import { shopTheLookSettingsActionsMethods } from './Actions';
import { isDataDirty } from '../../utils';
import { DEFAULT_ADD_MULTIPLE_TO_CART } from '../AddToCartSettings/defaults/default-add-multiple-to-cart';

export function useShopTheLookSettingsDraft({
  shopTheLookSettings,
  dispatch,
  shopId,
  selectedVariantId,
  onIsDirty,
  shouldRefetch = false,
}: UseShopTheLookSettingsDraftArgs): UseShopTheLookSettingsDraft {
  const [draftShopTheLookSettings, setDraftShopTheLookSettings] = useState(shopTheLookSettings);

  const { errors, validate, isValid } = useValidateSchema<ShopTheLookSettingsRecord>({
    schema: shopTheLookSettingsValidationSchema,
  });

  const isDirty = isDataDirty(shopTheLookSettings, draftShopTheLookSettings);

  const onSubmit = (): void => {
    if (isValid) {
      dispatch(
        shopTheLookSettingsActionsMethods.updateShopTheLookSettings({
          shopId,
          numberOfResultsToDisplay: draftShopTheLookSettings.numberOfResultsToDisplay,
          includeMainProductInCarousel: draftShopTheLookSettings.includeMainProductInCarousel,
          shuffleResults: draftShopTheLookSettings.shuffleResults,
          completeTheLookFallbackMethod: draftShopTheLookSettings.completeTheLookFallbackMethod,
          variantId: selectedVariantId,
          addToCart: draftShopTheLookSettings.addToCart,
        })
      );
    }
  };

  const onDiscard = (): void => {
    setDraftShopTheLookSettings(shopTheLookSettings);
  };

  const onChange = (partialSettings: Partial<ShopTheLookSettingsReducerState>) => {
    const updatedState = { ...draftShopTheLookSettings, ...partialSettings };
    validate({ dataToValidate: updatedState });
    setDraftShopTheLookSettings(updatedState);
    const { active } = updatedState?.addToCart;
    if (!active) {
      setDraftShopTheLookSettings({
        ...updatedState,
        addToCart: DEFAULT_ADD_MULTIPLE_TO_CART,
      });
    }
  };

  const fetchShopTheLookSettings = useCallback(() => {
    dispatch(
      shopTheLookSettingsActionsMethods.fetchShopTheLookSettings({
        shopId,
        variantId: selectedVariantId,
      })
    );
  }, [shopId, selectedVariantId]);

  useEffect((): void => {
    setDraftShopTheLookSettings(shopTheLookSettings);
  }, [shopTheLookSettings]);

  useEffect(() => {
    const payload = { isDirty: !!isDirty };
    if (onIsDirty) {
      onIsDirty(payload);
    } else {
      dispatch(shopTheLookSettingsActionsMethods.notifyIsDirty(payload));
    }
  }, [isDirty]);

  useEffect(() => {
    validate({ dataToValidate: draftShopTheLookSettings });
  }, [draftShopTheLookSettings]);

  useEffect(() => {
    fetchShopTheLookSettings();
  }, [fetchShopTheLookSettings]);

  useEffect(() => {
    if (shouldRefetch) {
      fetchShopTheLookSettings();
    }
  }, [shouldRefetch]);

  return {
    draftShopTheLookSettings,
    errors,
    isValid,
    isDirty,
    onSubmit,
    onDiscard,
    onChange,
  };
}
