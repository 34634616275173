import React from 'react';
import { Button } from 'src/components-dummy';
import { TabButtonWrapperStyled, TabsWrapperStyled } from './DeepTagsSubProductsListTabs.style';

interface DeepTagsSubProductsListTabsProps {
  names: string[];
  isInProcess: boolean;
  onTabChange: (index: number) => void;
  selectedTabIndex: number;
}

export const DeepTagsSubProductsListTabs = ({
  onTabChange,
  selectedTabIndex,
  names,
  isInProcess,
}: DeepTagsSubProductsListTabsProps): JSX.Element => {
  return (
    <TabsWrapperStyled>
      {names.map((name: string, index: number) => {
        const isSelected = selectedTabIndex === index;
        return (
          <TabButtonWrapperStyled key={name} isSelected={isSelected}>
            <Button
              onClick={() => onTabChange(index)}
              variant={isSelected ? 'primary' : 'secondary'}
              disabled={isInProcess && !isSelected}
            >
              {name}
            </Button>
          </TabButtonWrapperStyled>
        );
      })}
    </TabsWrapperStyled>
  );
};
