import React from 'react';

import {
  FormBody,
  FormContainer,
  FormItem,
  FormRadioGroup,
  FormSelect,
  FormSwitch,
  FormTitle,
  StyledContainer,
} from '../../components-dummy/SettingsForm';

import { sizeVariantsTypes } from './types/sizeVariantsTypes';
import { colorVariantsShapes } from './types/colorVariantsShapes';
import { SizeVariantsDisplayType } from '../../app-state-types/reducer-state-types/add-to-cart';
import {
  AddToCartGeneral,
  ResultsModalGeneralSettings,
} from '../../services/src/service/results-modal/types';

interface AddToCartGeneralFormProps {
  generalSettings: ResultsModalGeneralSettings;
  onChange: (args: Partial<AddToCartGeneral>) => void;
}

export function AddToCartGeneralForm({
  generalSettings,
  onChange,
}: AddToCartGeneralFormProps): JSX.Element | null {
  const { active: isAddToCartActive, colorVariants, sizeVariants } = generalSettings.addToCart;
  const tip =
    'To enable Add to cart, please verify with your customer representative that your catalogue variants are indexed and supported properly';

  return (
    <FormContainer>
      <FormTitle tip={tip}>Add to cart</FormTitle>
      <FormBody divider>
        <FormSwitch
          label='Enable add to cart'
          checked={isAddToCartActive}
          onChange={active => onChange({ active })}
        />
        <StyledContainer>
          <FormItem separator column paddingY={20}>
            <FormSwitch
              checked={colorVariants.active}
              onChange={active =>
                onChange({ colorVariants: { ...colorVariants, active }, sizeVariants })
              }
              label='Show color variants on results'
            />
            {colorVariants.active && (
              <StyledContainer nested>
                <FormRadioGroup
                  label='Display:'
                  name='color-variants-radio-group'
                  selectedValue={colorVariants.displayShape}
                  onChange={displayShape =>
                    onChange({ colorVariants: { ...colorVariants, displayShape }, sizeVariants })
                  }
                  options={colorVariantsShapes}
                />
                <StyledContainer paddingY={10}>
                  <FormSwitch
                    checked={colorVariants.shouldDisplayAllVariants}
                    onChange={shouldDisplayAllVariants =>
                      onChange({
                        colorVariants: {
                          ...colorVariants,
                          shouldDisplayAllVariants,
                        },
                        sizeVariants,
                      })
                    }
                    label='Should display all variants'
                  />
                </StyledContainer>
              </StyledContainer>
            )}
          </FormItem>
          <FormItem separator column paddingY={20}>
            <FormSwitch
              label='Add size options per color'
              checked={sizeVariants.active}
              onChange={active =>
                onChange({ sizeVariants: { ...sizeVariants, active }, colorVariants })
              }
            />
            {sizeVariants.active && (
              <StyledContainer nested>
                <FormSelect
                  label='Display:'
                  items={sizeVariantsTypes}
                  value={sizeVariants.displayType}
                  onChange={displayType =>
                    onChange({
                      sizeVariants: {
                        ...sizeVariants,
                        displayType: displayType as SizeVariantsDisplayType,
                      },
                      colorVariants,
                    })
                  }
                />
              </StyledContainer>
            )}
          </FormItem>
        </StyledContainer>
      </FormBody>
    </FormContainer>
  );
}
