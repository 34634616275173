import React from 'react';
import { Dispatch } from 'src/components-bl';
import { AdminSearchResults } from 'src/services/src/service/types/admin-search/admin-search-results';
import {
  ACCOUNTS_TABLE_ACCORDION,
  SHOPS_TABLE_ACCORDION,
  USERS_TABLE_ACCORDION,
  CATALOGS_TABLE_ACCORDION,
} from '../consts';
import { ShopsTable } from './ShopsTable/ShopsTable';
import { AccountsTable } from './AccountsTable/AccountsTable';
import { UsersTable } from './UsersTable/UsersTable';
import { CatalogsTable } from './CatalogsTable/CatalogsTable';
import {
  Accordion,
  AvailableIcons,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../components-dummy';
import { AccordionTriggerGroup, SearchResults } from '../AdminSearchModal.styles';

export interface AdminSearchResultsSectionProps {
  shops: AdminSearchResults['shops'] | undefined;
  accounts: AdminSearchResults['accounts'] | undefined;
  users: AdminSearchResults['users'] | undefined;
  catalogs: AdminSearchResults['catalogs'] | undefined;
  shopDefaultRoute: string;
  accountDefaultRoute: string;
  userDefaultRoute: string;
  catalogsDefaultRoute: string;
  dispatch: Dispatch;
  onSelect: () => void;
}

export const AdminSearchResultsSection = ({
  shops,
  accounts,
  users,
  catalogs,
  accountDefaultRoute,
  userDefaultRoute,
  catalogsDefaultRoute,
  dispatch,
  onSelect,
}: AdminSearchResultsSectionProps): JSX.Element => {
  const expandedIds = [
    SHOPS_TABLE_ACCORDION,
    ACCOUNTS_TABLE_ACCORDION,
    USERS_TABLE_ACCORDION,
    CATALOGS_TABLE_ACCORDION,
  ];
  const isExpanded = (id: string) => expandedIds?.includes(id) || false;

  return (
    <SearchResults>
      {shops?.length ? (
        <Accordion expandedIds={expandedIds}>
          <Accordion.Item id={SHOPS_TABLE_ACCORDION} observeHeight>
            <Accordion.Item.Header>
              <AccordionTriggerGroup className='shops'>
                <Icon name={AvailableIcons.Cart} />
                <Typography type={TypographyType.Body} variant={TypographyVariant.LargeRegular}>
                  Shops
                </Typography>
                <Icon
                  className={isExpanded(SHOPS_TABLE_ACCORDION) ? 'closed' : 'open'}
                  name={AvailableIcons.RightArrow}
                />
              </AccordionTriggerGroup>
            </Accordion.Item.Header>
            <Accordion.Item.Content>
              <ShopsTable shops={shops} dispatch={dispatch} onSelect={onSelect} />
            </Accordion.Item.Content>
          </Accordion.Item>
        </Accordion>
      ) : (
        <></>
      )}

      {accounts?.length ? (
        <Accordion expandedIds={expandedIds}>
          <Accordion.Item id={ACCOUNTS_TABLE_ACCORDION} observeHeight>
            <Accordion.Item.Header>
              <AccordionTriggerGroup className='accounts'>
                <Icon name={AvailableIcons.Team} />
                <Typography type={TypographyType.Body} variant={TypographyVariant.LargeRegular}>
                  Accounts
                </Typography>
                <Icon
                  className={isExpanded(ACCOUNTS_TABLE_ACCORDION) ? 'closed' : 'open'}
                  name={AvailableIcons.RightArrow}
                />
              </AccordionTriggerGroup>
            </Accordion.Item.Header>
            <Accordion.Item.Content>
              <AccountsTable
                accounts={accounts}
                dispatch={dispatch}
                onSelect={onSelect}
                accountDefaultRoute={accountDefaultRoute}
              />
            </Accordion.Item.Content>
          </Accordion.Item>
        </Accordion>
      ) : (
        <></>
      )}
      {users?.length ? (
        <Accordion expandedIds={expandedIds}>
          <Accordion.Item id={USERS_TABLE_ACCORDION} observeHeight>
            <Accordion.Item.Header>
              <AccordionTriggerGroup className='users'>
                <Icon name={AvailableIcons.UserCircle} />
                <Typography type={TypographyType.Body} variant={TypographyVariant.LargeRegular}>
                  Users
                </Typography>
                <Icon
                  className={isExpanded(USERS_TABLE_ACCORDION) ? 'closed' : 'open'}
                  name={AvailableIcons.RightArrow}
                />
              </AccordionTriggerGroup>
            </Accordion.Item.Header>
            <Accordion.Item.Content>
              <UsersTable
                users={users}
                dispatch={dispatch}
                onSelect={onSelect}
                userDefaultRoute={userDefaultRoute}
              />
            </Accordion.Item.Content>
          </Accordion.Item>
        </Accordion>
      ) : (
        <></>
      )}

      {catalogs?.length ? (
        <Accordion expandedIds={expandedIds}>
          <Accordion.Item id={CATALOGS_TABLE_ACCORDION} observeHeight>
            <Accordion.Item.Header>
              <AccordionTriggerGroup className='catalogs'>
                <Icon name={AvailableIcons.CatalogManager} />
                <Typography type={TypographyType.Body} variant={TypographyVariant.LargeRegular}>
                  Catalogs
                </Typography>
                <Icon
                  className={isExpanded(CATALOGS_TABLE_ACCORDION) ? 'closed' : 'open'}
                  name={AvailableIcons.RightArrow}
                />
              </AccordionTriggerGroup>
            </Accordion.Item.Header>
            <Accordion.Item.Content>
              <CatalogsTable
                catalogs={catalogs}
                dispatch={dispatch}
                onSelect={onSelect}
                catalogsDefaultRoute={catalogsDefaultRoute}
              />
            </Accordion.Item.Content>
          </Accordion.Item>
        </Accordion>
      ) : (
        <></>
      )}
    </SearchResults>
  );
};
