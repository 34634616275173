/* eslint no-param-reassign:0 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  calculateProductTagPercentageCoordinates,
  calculateProductTagPixelPosition,
} from 'src/containers/SocialDiscovery/components/PostPreview/calculateProductTagPosition';
import { Size, State, StateType } from './types';
import { AssignedProduct, Coordinates, Product } from '../../types/entities.types';

const createInitialState = (assignedProducts: AssignedProduct[]): State => ({
  postSize: null,
  type: StateType.loadingImage,
  assignedProducts,
  tagCoordinates: null,
});

export const createReducer = (assignedProducts: AssignedProduct[]) => {
  return createSlice({
    name: 'SocialDiscovery/ProductTags',
    initialState: createInitialState(assignedProducts),
    reducers: {
      dragStart: state => {
        state.type = StateType.dragging;
      },
      openInput: (state, action: PayloadAction<Coordinates>) => {
        if (!state.postSize) return;

        state.tagCoordinates = calculateProductTagPercentageCoordinates(
          state.postSize,
          action.payload
        );
        state.type = StateType.inputActive;
      },
      closeInput: state => {
        state.tagCoordinates = null;
        state.type = StateType.idle;
      },
      addProduct: (state, action: PayloadAction<Product>) => {
        const assignedProduct: AssignedProduct = {
          ...action.payload,
          coordinates: state.tagCoordinates,
        };

        state.assignedProducts.push(assignedProduct);
        state.type = StateType.idle;
        state.tagCoordinates = null;
      },
      removeProduct: (state, action: PayloadAction<number>) => {
        state.assignedProducts.splice(action.payload, 1);
      },
      setCoordinates: (
        state,
        action: PayloadAction<{ productIndex: number; coordinates: Coordinates }>
      ) => {
        state.assignedProducts[action.payload.productIndex].coordinates =
          calculateProductTagPercentageCoordinates(state.postSize, action.payload.coordinates);
        state.type = StateType.idle;
      },
      fixTagCoordinates: (
        state,
        action: PayloadAction<{ productIndex: number; tagSize: Size }>
      ) => {
        const { productIndex, tagSize } = action.payload;

        const assignedProduct = state.assignedProducts[productIndex];
        const { width: postWidth, height: postHeight } = state.postSize;
        const { width: tagWidth, height: tagHeight } = tagSize;

        const { x, y } = calculateProductTagPixelPosition(
          state.postSize,
          assignedProduct.coordinates
        );

        let fixedX = x;
        let fixedY = y;

        if (x + tagWidth > postWidth) {
          fixedX = postWidth - tagWidth;
        }

        if (y + tagHeight > postHeight) {
          fixedY = postHeight - tagHeight;
        }

        if (fixedX !== x || fixedY !== y) {
          assignedProduct.coordinates = calculateProductTagPercentageCoordinates(state.postSize, {
            x: fixedX,
            y: fixedY,
          });
        }
      },

      setPostSize: (state, action: PayloadAction<Size>) => {
        state.postSize = action.payload;
        state.type = StateType.idle;
      },
    },
  });
};
