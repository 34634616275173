import { AppNotification } from 'src/app-types';
import { ToastAction, ToastActionType, loginActions } from '../containers';
import { ToastReducerState } from '../app-state-types';

const combineMessageAndErrorKeys = ({ messageKey, errorKey }: AppNotification) =>
  `${messageKey}:${errorKey}`;

const initialState: ToastReducerState = { toasts: [] };

export function toastReducer(
  state: ToastReducerState = initialState,
  action?: ToastAction
): ToastReducerState {
  switch (action?.type) {
    case ToastActionType.Enqueue: {
      const existingToastsSet = new Set(
        state.toasts.map(toast => combineMessageAndErrorKeys(toast))
      );

      if (existingToastsSet.has(combineMessageAndErrorKeys(action.payload))) {
        return state;
      }

      if (state.toasts.length >= 5) {
        return { toasts: [...state.toasts.slice(1), action.payload] };
      }

      return { toasts: [...state.toasts, action.payload] };
    }
    case ToastActionType.Dequeue:
      return { toasts: state.toasts.slice(1) };
    case ToastActionType.Remove:
      return {
        toasts: state.toasts.filter(toastItem => toastItem.timestamp !== action.payload.timestamp),
      };
    case ToastActionType.Clear:
    case loginActions.login.fulfilled.type:
      return { toasts: [] };
    default:
      return state;
  }
}
