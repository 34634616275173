import { RefObject, useRef } from 'react';
import {
  ConnectDragPreview,
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
  XYCoord,
} from 'react-dnd';

interface DragDropItem {
  index: number;
}

interface UseDragAndDropParameters {
  acceptKey: string;
  disableDrag?: boolean;
  index: number;
  onDrag: (sourceIndex: number, targetIndex: number) => void;
  onDrop?: () => void;
  onDragEnd?: (params: { didDrop: boolean }) => void;
}

export function useDragAndDrop<DragElementType extends HTMLElement>({
  acceptKey,
  disableDrag = false,
  index,
  onDrag,
  onDragEnd,
  onDrop,
}: UseDragAndDropParameters): {
  dragElementRef: RefObject<DragElementType>;
  dragPreviewRef: ConnectDragPreview;
  isDragging: boolean;
} {
  const dragElementRef = useRef<DragElementType>(null);

  const [, drop] = useDrop(
    {
      accept: acceptKey,
      hover(draggedItem: DragDropItem, monitor: DropTargetMonitor<DragDropItem>) {
        if (!dragElementRef.current) {
          return;
        }

        const sourceIndex = draggedItem.index;
        const targetIndex = index;

        const isDroppingOnSelf = sourceIndex === targetIndex;
        if (isDroppingOnSelf) {
          return;
        }

        const hoveredElementBoundingRect = dragElementRef.current.getBoundingClientRect();
        const hoveredElementMiddlePoint =
          (hoveredElementBoundingRect.bottom - hoveredElementBoundingRect.top) / 2;

        const currentMousePosition = monitor.getClientOffset();

        const currentDistanceToTopOfHoveredElement =
          (currentMousePosition as XYCoord).y - hoveredElementBoundingRect.top;

        const isDraggingDownwards = sourceIndex < targetIndex;
        const elementMovedDownHalfOfHoveredElement =
          currentDistanceToTopOfHoveredElement >= hoveredElementMiddlePoint;

        if (isDraggingDownwards && elementMovedDownHalfOfHoveredElement) {
          return;
        }

        const isDraggingUpwards = sourceIndex > targetIndex;
        const elementMovedUpHalfOfHoveredElement =
          currentDistanceToTopOfHoveredElement <= hoveredElementMiddlePoint;

        if (isDraggingUpwards && !elementMovedUpHalfOfHoveredElement) {
          return;
        }

        onDrag(sourceIndex, targetIndex);

        // eslint-disable-next-line no-param-reassign
        draggedItem.index = targetIndex;
      },
      drop: onDrop,
    },
    [index, onDrop, onDrag]
  );

  const [{ isDragging }, drag, dragPreviewRef] = useDrag(
    () => ({
      type: acceptKey,
      item: { index },
      collect: monitor => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
      canDrag: () => !disableDrag,
      end: (_draggedItem: DragDropItem, monitor: DragSourceMonitor<DragDropItem>) => {
        if (!onDrop && onDragEnd) {
          onDragEnd({ didDrop: monitor.didDrop() });
        }
      },
    }),
    [index, disableDrag]
  );

  drag(drop(dragElementRef));

  return { dragElementRef, dragPreviewRef, isDragging };
}
