import React, { MouseEvent, useCallback } from 'react';
import {
  EllipsisMenuButton,
  Menu,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { MenuActionsStyled, MenuItemStyled } from './RowActionsMenu.styles';

interface RowActionMenuProps {
  menuAnchorElement: HTMLElement | null;
  onMenuClicked: (event: MouseEvent<HTMLElement>) => void;
  onMenuClose: () => void;
  onRestoreVersionClick?: () => void;
  onReviewVersionClick?: () => void;
}

export const RowActionsMenu = ({
  menuAnchorElement,
  onMenuClicked,
  onMenuClose,
  onRestoreVersionClick,
  onReviewVersionClick,
}: RowActionMenuProps): JSX.Element => {
  const isOpen = Boolean(menuAnchorElement);

  const onItemClick = useCallback(
    onClick => {
      onClick();
      onMenuClose();
    },
    [onMenuClose]
  );

  return (
    <MenuActionsStyled>
      <EllipsisMenuButton onClick={onMenuClicked} isActive={isOpen} />
      <Menu
        open={isOpen}
        anchorEl={menuAnchorElement}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: 'right',
        }}
      >
        {!!onRestoreVersionClick && (
          <MenuItemStyled key='restore' onClick={() => onItemClick(onRestoreVersionClick)}>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Restore
            </Typography>
          </MenuItemStyled>
        )}
        {!!onReviewVersionClick && (
          <MenuItemStyled key='review' onClick={onReviewVersionClick}>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Review version
            </Typography>
          </MenuItemStyled>
        )}
      </Menu>
    </MenuActionsStyled>
  );
};
