import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { lexiconService } from 'src/services';
import {
  GetLexiconTagsArguments,
  UpdateLexiconRuleArguments,
  UpdateLexiconTagsArguments,
  UpdateLexiconTagsBulkArguments,
  UpdateThematicTagArguments,
} from 'src/services/src/service/lexicon/types';

const updateLexiconTagAsyncThunk = async (
  { shopId, locale, tagToUpdate, tagKey }: UpdateLexiconTagsArguments,
  { rejectWithValue }: { rejectWithValue: ({ error }: { error: Error }) => void }
) => {
  try {
    const updatedTag = await lexiconService.updateOneLexiconTag({
      shopId,
      locale,
      tagToUpdate,
      tagKey,
    });

    return updatedTag;
  } catch (error) {
    return rejectWithValue({ error: error as Error });
  }
};

const updateThematicTagAsyncThunk = async (
  { shopId, locale, partialTagToUpdate, id }: UpdateThematicTagArguments,
  { rejectWithValue }: { rejectWithValue: ({ error }: { error: Error }) => void }
) => {
  try {
    const updatedTag = await lexiconService.updateOneThematicTag({
      shopId,
      locale,
      partialTagToUpdate,
      id,
    });

    return updatedTag;
  } catch (error) {
    return rejectWithValue({ error: error as Error });
  }
};

const updateLexiconRuleTagAsyncThunk = async (
  { shopId, locale, partialRuleToUpdate, id }: UpdateLexiconRuleArguments,
  { rejectWithValue }: { rejectWithValue: ({ error }: { error: Error }) => void }
) => {
  try {
    const updatedTag = await lexiconService.updateLexiconRule({
      shopId,
      locale,
      partialRuleToUpdate,
      id,
    });

    return updatedTag;
  } catch (error) {
    return rejectWithValue({ error: error as Error });
  }
};

export const lexiconTableActions = {
  getLexiconTags: createAsyncThunk(
    'LexiconTable/GetLexiconTags',
    async (serviceArguments: GetLexiconTagsArguments, { rejectWithValue, signal }) => {
      try {
        const tagsPagination = await lexiconService.getLexiconTags(serviceArguments, {
          abortSignal: signal,
        });

        return tagsPagination;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  getLexiconTagsSummary: createAsyncThunk(
    'LexiconTable/GetLexiconTagsSummary',
    async (serviceArguments: GetLexiconTagsArguments, { rejectWithValue }) => {
      try {
        const tagsPagination = await lexiconService.getLexiconTagsSummary(serviceArguments);

        return tagsPagination;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  restoreLexiconTag: createAsyncThunk('LexiconTable/RestoreLexiconTag', updateLexiconTagAsyncThunk),

  updateLexiconTag: createAsyncThunk('LexiconTable/UpdateLexiconTag', updateLexiconTagAsyncThunk),

  toggleLexiconTag: createAsyncThunk('LexiconTable/ToggleLexiconTag', updateLexiconTagAsyncThunk),

  updateLexiconBulkTag: createAsyncThunk(
    'LexiconTable/UpdateLexiconTag',
    async (serviceArguments: UpdateLexiconTagsBulkArguments, { rejectWithValue }) => {
      try {
        const updatedTags = await lexiconService.bulkActionRenameAllTags(serviceArguments);

        return updatedTags;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  deleteThematicTag: createAsyncThunk(
    'LexiconTable/DeleteLexiconTag',
    async (
      {
        shopId,
        locale,
        tagKey,
      }: {
        shopId: number;
        locale: string;
        tagKey: string;
      },
      { rejectWithValue }
    ) => {
      try {
        const hasDeletedSuccessfully = await lexiconService.deleteOneThematicTag({
          shopId,
          locale,
          tagKey,
        });

        return hasDeletedSuccessfully;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  deleteLexiconRule: createAsyncThunk(
    'LexiconTable/DeleteLexiconRule',
    async (
      {
        shopId,
        locale,
        tagKey,
      }: {
        shopId: number;
        locale: string;
        tagKey: string;
      },
      { rejectWithValue }
    ) => {
      try {
        const hasDeletedSuccessfully = await lexiconService.deleteOneLexiconRule({
          shopId,
          locale,
          tagKey,
        });

        return hasDeletedSuccessfully;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateThematicTag: createAsyncThunk(
    'LexiconTable/UpdateThematicTag',
    updateThematicTagAsyncThunk
  ),
  updateLexiconRule: createAsyncThunk(
    'LexiconTable/UpdateLexiconRule',
    updateLexiconRuleTagAsyncThunk
  ),
  toggleThematicTag: createAsyncThunk(
    'LexiconTable/ToggleThematicTag',
    updateThematicTagAsyncThunk
  ),
  toggleLexiconRule: createAsyncThunk(
    'LexiconTable/ToggleLexiconRule',
    updateLexiconRuleTagAsyncThunk
  ),
  navigateTo: createAction<{ navigateTo: string }>('lexiconTableActions/navigateTo'),
  reset: createAction('LexiconTableActions/Reset'),
};
