import React from 'react';
import { PopUp, Button, TextBox, AvailableIcons } from 'src/components-dummy';
import { CreateExperimentFormProps } from './types';
import { useCreateExperimentForm } from './useCreateExperimentForm';
import './CreateExperimentForm.scss';

export const CreateExperimentForm = ({
  shopId,
  dispatch,
  editExperimentFormRoute,
}: CreateExperimentFormProps): JSX.Element => {
  const { errors, showPopUp, setShowPopUp, setPartialState, state, onSubmit, isValid } =
    useCreateExperimentForm({
      shopId,
      dispatch,
      editExperimentFormRoute,
    });
  return (
    <div className='syte-create-experiment-form'>
      <PopUp show={showPopUp} onShow={setShowPopUp} hideOnScroll={false} position='bottom left'>
        <PopUp.Trigger>
          <Button
            startIcon={AvailableIcons.Add}
            variant='secondary'
            className='create-experiment-button'
          >
            Create Experiment
          </Button>
        </PopUp.Trigger>
        <PopUp.Content>
          <div className='syte-create-experiment-form-popup-content'>
            <TextBox
              label='Experiment Name*'
              value={state.name}
              error={!!errors.name}
              onChange={name => setPartialState({ name })}
              className='syte-create-experiment-form-popup-content-name-input'
            />
            <Button
              onClick={onSubmit}
              variant='primary'
              disabled={!isValid}
              className='syte-create-experiment-form-apply-button'
            >
              Save
            </Button>
          </div>
        </PopUp.Content>
      </PopUp>
    </div>
  );
};
