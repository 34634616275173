import React from 'react';
import { generatePath } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { AccountsListSection, CreateAccountForm } from 'src/components-bl';
import { Page, Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import './AccountListContainer.scss';
import { RoutedComponentProps } from 'src/app-routes';

type AccountListContainerProps = RoutedComponentProps;
type ConnectedAccountListSectionProps = AccountListContainerProps;

const ConnectedAccountListSection = ({ permittedRouteMap }: ConnectedAccountListSectionProps) => {
  const dispatch = useAppDispatch();
  const { accounts, current } = useAppSelector(state => {
    return {
      accounts: state.account.accounts,
      current: state.account.current,
    };
  });

  const routeToPushOnSelectAccount =
    permittedRouteMap.shopList?.path && generatePath(permittedRouteMap.shopList?.path);

  return (
    <AccountsListSection
      accounts={accounts}
      dispatch={dispatch}
      selectedAccountId={current?.accountId}
      routeToPushOnSelectAccount={routeToPushOnSelectAccount}
    />
  );
};
export const AccountListContainer = ({
  permittedRouteMap,
}: AccountListContainerProps): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <div className='account-list-container'>
      <Page>
        <Page.Header>
          <Page.Title>
            <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
              Accounts
            </Typography>
          </Page.Title>
          <CreateAccountForm dispatch={dispatch} className='ml-auto' />
        </Page.Header>
        <Page.Content>
          <ConnectedAccountListSection permittedRouteMap={permittedRouteMap} />
        </Page.Content>
      </Page>
    </div>
  );
};
