import React, { useCallback, useImperativeHandle } from 'react';
import { generatePath } from 'react-router-dom';
import { NotificationCardMessageTypography } from '../common';
import { NotificationCardMessageProps } from '../types';
import { ValidationErrorKey } from 'src/containers/ToastManager/ActionsNotifications/validation-error-key';

export function TagsDetectionFinished({
  notification,
  shopId,
  permittedRouteMap,
  navigateTo,
  cardRef,
}: NotificationCardMessageProps<{
  galleryId: string;
  aiSuggestedTagsCount: number;
  galleryName: string;
  errorKey?: ValidationErrorKey;
}>): JSX.Element {
  const onNotificationClick = useCallback(() => {
    if (permittedRouteMap.editCustomInspirationsGallery?.path) {
      const galleryPath = generatePath(permittedRouteMap.editCustomInspirationsGallery.path, {
        shopId,
        galleryId: notification.content.galleryId,
      });

      navigateTo(galleryPath);
    }
  }, [
    shopId,
    navigateTo,
    permittedRouteMap.editCustomInspirationsGallery?.path,
    notification.content.galleryId,
  ]);

  useImperativeHandle(cardRef, () => ({
    onNotificationClick,
  }));

  let text = `${notification.content.aiSuggestedTagsCount} suggestions were generated for the '${notification.content.galleryName}' gallery. Click to view.`;

  if (notification.content.errorKey) {
    text = `We were unable to generate suggestions for the '${notification.content.galleryName}' gallery. Please try again later.`;
  } else if (notification.content.aiSuggestedTagsCount === 0) {
    text = `The process is complete, but no tags were detected for the '${notification.content.galleryName}' gallery.`;
  } else if (notification.content.aiSuggestedTagsCount === 1) {
    text = `One suggestion was generated for the '${notification.content.galleryName}' gallery. Click to view.`;
  }

  return <NotificationCardMessageTypography>{text}</NotificationCardMessageTypography>;
}
