import React, { useCallback, useImperativeHandle } from 'react';
import { generatePath } from 'react-router-dom';
import { IShopCatalog } from 'src/services';
import { NotificationCardMessageTypography } from '../common';
import { NotificationCardMessageProps } from '../types';
import { getUserDisplayName } from '../utils';

export function ShopCatalogCreateMessage({
  notification,
  shopId,
  permittedRouteMap,
  navigateTo,
  cardRef,
}: NotificationCardMessageProps<IShopCatalog>): JSX.Element {
  const onNotificationClick = useCallback(() => {
    if (permittedRouteMap.catalogManager?.path) {
      const catalogUploadLogsPath = generatePath(permittedRouteMap.catalogManager.path, {
        shopId,
      });

      navigateTo(catalogUploadLogsPath);
    }
  }, [shopId, navigateTo, permittedRouteMap.catalogManager?.path]);

  useImperativeHandle(cardRef, () => ({
    onNotificationClick,
  }));

  return (
    <NotificationCardMessageTypography>
      {getUserDisplayName(notification.createdByUser)} created {notification.content.name} catalog.
    </NotificationCardMessageTypography>
  );
}
