import React from 'react';
import {
  SearchInput,
  Checkbox,
  MenuItem,
  Select,
  SelectType,
  SelectOnChangeEvent,
} from 'src/components-dummy';
import { getStringEnumValues, SortOption } from 'src/utils';
import { AccountsList } from '../AccountsList';
import { AccountsListSectionProps } from './types';
import { useAccountListSection } from './useAccountListSection';
import './AccountsListSection.scss';

const sortOptionTranslations: Record<SortOption, string> = {
  [SortOption.Asc]: 'A-Z',
  [SortOption.Desc]: 'Z-A',
};

const sortDropdownOptions = getStringEnumValues(SortOption).map(option => {
  return {
    value: option,
    text: sortOptionTranslations[option],
  };
});

export const AccountsListSection = ({
  accounts = [],
  selectedAccountId,
  dispatch,
  routeToPushOnSelectAccount,
}: AccountsListSectionProps): JSX.Element => {
  const {
    accounts: sortedAccounts,
    onSearchStateChange,
    loadMoreAccounts,
    searchState,
  } = useAccountListSection({
    accounts,
    dispatch,
  });

  const handleStatusChange: SelectOnChangeEvent = event =>
    onSearchStateChange({ sort: event.target.value as SortOption });

  return (
    <div className='syte-accounts-list-section'>
      <div className='syte-accounts-list-section-header'>
        <Select
          className='sort-input'
          type={SelectType.Menu}
          value={searchState.sort}
          onChange={handleStatusChange}
        >
          {sortDropdownOptions.map((option: { value?: string; text: string }) => (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </Select>
        <Checkbox
          label='Include archived accounts'
          checked={!searchState.includeArchived}
          onChange={() => onSearchStateChange({ includeArchived: !searchState.includeArchived })}
          className='include-archived-input'
        />
        <SearchInput
          value={searchState.searchQuery}
          onChange={value => onSearchStateChange({ searchQuery: value })}
          placeholder='Search'
          className='search-input'
        />
      </div>
      <AccountsList
        dispatch={dispatch}
        accounts={sortedAccounts}
        selectedAccountId={selectedAccountId}
        searchValue={searchState.searchQuery}
        className='account-list'
        routeToPushOnSelectAccount={routeToPushOnSelectAccount}
        loadMoreAccounts={loadMoreAccounts}
      />
    </div>
  );
};
