import React, { useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { AccountTypes } from 'src/services';
import {
  List,
  ListItemProps,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { AccountCard } from '../AccountCard';
import { AccountsListProps, EmptyListProps } from './types';
import { selectAccount } from './Actions';
import './AccountsList.scss';

function pickDefaultAccount(accounts: Omit<AccountTypes.Account, 'shops'>[]) {
  return accounts.length && accounts[0].accountId;
}
const EmptyList = ({ searchValue }: EmptyListProps): JSX.Element => {
  const subTitle = searchValue
    ? 'There are no results for your search terms'
    : 'There are no accounts';

  return (
    <div className='no-accounts'>
      <Typography
        className='title'
        type={TypographyType.Heading}
        variant={TypographyVariant.SmallBold}
      >
        Wow... so empty in here
      </Typography>
      <Typography
        className='sub-title'
        type={TypographyType.Paragraph}
        variant={TypographyVariant.SmallBold}
      >
        {subTitle}
      </Typography>
    </div>
  );
};

export const AccountsList = ({
  accounts = [],
  selectedAccountId,
  dispatch,
  searchValue,
  className,
  routeToPushOnSelectAccount,
  loadMoreAccounts,
}: AccountsListProps): JSX.Element => {
  const onSelectAccount = useCallback((account: Omit<AccountTypes.Account, 'shops'>) => {
    dispatch(
      selectAccount({ accountId: account.accountId, routeToPush: routeToPushOnSelectAccount })
    );
  }, []);

  useEffect(() => {
    const defaultAccountId = pickDefaultAccount(accounts);

    if (!selectedAccountId && defaultAccountId) {
      dispatch(selectAccount({ accountId: defaultAccountId }));
    }
  }, [selectedAccountId, accounts]);

  return (
    <List
      className={classNames('syte-accounts-list', className)}
      idField='accountId'
      items={accounts}
      onItemSelected={onSelectAccount}
      selectedId={selectedAccountId}
      loadMore={loadMoreAccounts}
    >
      <List.CardTemplate>
        {(
          account: Omit<AccountTypes.Account, 'shops'>,
          { isSelected }: ListItemProps
        ): JSX.Element => (
          <AccountCard account={account} dispatch={dispatch} isSelected={isSelected} />
        )}
      </List.CardTemplate>
      <List.EmptyTemplate>
        <EmptyList searchValue={searchValue} />
      </List.EmptyTemplate>
    </List>
  );
};
