import React from 'react';
import styled from '@emotion/styled';
import { Typography, TypographyType, TypographyVariant } from '../../../../components-dummy';

const StyledActionContainer = styled.div`
  display: flex;
  align-items: center;
  .rule-action {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 210px;
    font-size: 11px;
    font-weight: 400;
    color: #6e6e71;
  }
`;

export function MerchandisingRuleAction({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <StyledActionContainer>
      <Typography
        className='rule-action'
        type={TypographyType.Body}
        variant={TypographyVariant.SmallMedium}
      >
        {children}
      </Typography>
    </StyledActionContainer>
  );
}
