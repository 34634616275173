import React from 'react';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { AvailableIcons, Icon } from '../../Icon';
import { FileCardStyled, FileNameWithTooltipStyled } from './FileCard.styles';

export interface FileCardProps {
  fileName: string;
  fileCardIconName?: AvailableIcons;
  disabled?: boolean;
  onCloseClick?: () => void;
}

export const FileCard = ({
  fileName,
  disabled,
  fileCardIconName,
  onCloseClick,
}: FileCardProps): JSX.Element => {
  return (
    <FileCardStyled className='syte-file-upload-card'>
      <div>{fileCardIconName && <Icon name={fileCardIconName} />}</div>
      <FileNameWithTooltipStyled tooltipText={fileName}>
        <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
          {fileName}
        </Typography>
      </FileNameWithTooltipStyled>
      <div>{disabled ? <></> : <Icon name={AvailableIcons.Close} onClick={onCloseClick} />}</div>
    </FileCardStyled>
  );
};
