import React from 'react';
import { Skeleton } from 'src/components-dummy/Skeleton';
import { SkeletonVerticalBlocksWithTitle } from 'src/components-dummy/Skeleton/skeletonCompositions';
import { SkeletonContent, SkeletonHeader } from './ProductDetails.skeleton.styled';

export const ProductDetailsSkeleton = (): JSX.Element => {
  return (
    <>
      <SkeletonHeader>
        <Skeleton width='20%' />
        <Skeleton width='40%' sx={{ marginTop: '8px' }} />
      </SkeletonHeader>
      <SkeletonContent>
        <SkeletonVerticalBlocksWithTitle
          itemProps={{ height: 500, width: '90%' }}
          sx={{ padding: '16px 0 16px 24px', flex: 5 }}
        />
        <SkeletonVerticalBlocksWithTitle
          spacing={2}
          sx={{ padding: '16px 24px 16px 0', flex: 5 }}
        />
      </SkeletonContent>
    </>
  );
};
