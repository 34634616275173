import styled from '@emotion/styled';
import { Icon, Typography } from '../../../../components-dummy';

export const SettingsContainer = styled.div`
  position: relative;
  z-index: 100;
  box-shadow:
    0 6px 12px -6px rgba(0, 0, 0, 0.12),
    0 8px 24px -4px rgba(0, 0, 0, 0.08);
  width: 400px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 16px;

  .ellipsis-with-tooltip {
    display: inline-block;
    line-height: 20px;
    max-width: 18ch;
  }
`;

export const Line = styled.div`
  border-bottom: 1px solid #dcdcdc;
`;

export const LightPurpleBackground = styled.div`
  background-color: #f8f0ff;
  border-radius: 4px;
`;

export const RuleDescriptionContainer = styled(LightPurpleBackground)`
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin-top: 8px;
`;

export const LastEdited = styled(Typography)`
  color: #6e6e71;
`;

export const CategoryPage = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 4px;
  border-left: 2px solid #005ccc;
  padding: 0 4px;
  margin: 0 8px;
`;

export const HighlightedText = styled(Typography)`
  color: #0073ff;
  background-color: #e5f1ff;
  padding: 2px 6px;
  border-radius: 4px;
`;

export const Info = styled(LightPurpleBackground)`
  display: flex;
  align-items: center;
  padding: 0 8px;
`;

export const SubRulesContainer = styled.div`
  min-height: 64px;
  overflow-y: auto;
`;

export const SubRule = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background-color: #e5f1ff;
    cursor: pointer;
    border-radius: 4px;
    svg path {
      stroke: #6e6e71;
    }
  }
`;

export const SubRuleText = styled(Typography)`
  display: flex;
  align-items: center;
  gap: 4px;
  border-left: 2px solid #9333ea;
  padding: 0 4px;

  .ellipsis-with-tooltip {
    max-width: 24ch;
  }
`;

export const IconStyled = styled(Icon)`
  path {
    stroke: transparent;
  }
`;
