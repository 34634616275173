import React from 'react';
import { Tooltip } from 'src/components-dummy';
import {
  UserProfileThumbnailStyled,
  OnlineStatusIndication,
  ProfileOnlineStatusIndication,
  ProfileOfflineStatusIndication,
  InitialLetterStyled,
} from './UserProfileThumbnail.styles';

export interface UserProfileThumbnailProps {
  displayName: string;
  online?: boolean;
  isEditing?: boolean;
  thumbnailSize?: number;
  className?: string;
  lettersCountToShow?: number;
  showTooltip?: boolean;
  isSystemUser?: boolean;
}

export const UserProfileThumbnail = ({
  displayName,
  online,
  className,
  isEditing,
  thumbnailSize = 35,
  lettersCountToShow = 2,
  showTooltip = true,
  isSystemUser,
}: UserProfileThumbnailProps): JSX.Element => {
  let showStatusWithTooltip = false;
  let showStatusWithoutTooltip = false;
  if (online !== undefined) {
    if (showTooltip) {
      showStatusWithTooltip = true;
    } else {
      showStatusWithoutTooltip = true;
    }
  }

  return (
    <UserProfileThumbnailStyled size={thumbnailSize} className={className}>
      <InitialLetterStyled
        value={displayName}
        size={thumbnailSize}
        shouldHighlight={!!isEditing}
        lettersCountToShow={lettersCountToShow}
        isSystem={isSystemUser}
      />
      {showStatusWithTooltip && (
        <Tooltip value='User is online' disabled={!online}>
          <OnlineStatusIndication show={!!online} />
        </Tooltip>
      )}
      {showStatusWithoutTooltip && online === false && (
        <ProfileOfflineStatusIndication show={false} />
      )}
      {showStatusWithoutTooltip && online !== false && (
        <ProfileOnlineStatusIndication show={!!online} />
      )}
    </UserProfileThumbnailStyled>
  );
};
