import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router';
import { isEqual } from 'lodash';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch } from 'src/components-bl/types';
import { Button } from 'src/components-dummy';
import { CustomInspirationsGallery, GalleryType } from 'src/services';
import { IApiKey } from 'src/services/src/service/types/shops/api-keys';
import { editCustomInspirationsGalleryPageActions } from './Actions';
import { GalleryPreview, EditGallerySideBar } from './components';
import {
  EditCustomInspirationsGalleryPageStyled,
  PreviewFooter,
  PreviewLayout,
  SideBarLayout,
  OverlayModalStyled,
} from './EditCustomInspirationsGalleryPage.styles';
import { GalleryMapper } from './mapper';
import { CustomInspirationGalleryDraft } from './types';
import { ExpandableSection } from '../TagImageModal/components/ImageSettings/components';
import { getQueryStringParams } from '../common/utils';
import {
  Action,
  DirtyFormConfirmationDialog,
} from '../components/DirtyFormConfirmationDialog/DirtyFormConfirmationDialog';
import { useConfirmationDialog } from '../components/DirtyFormConfirmationDialog/useConfirmationDialog';

interface EditCustomInspirationsGalleryPageProps extends RoutedComponentProps {
  shopId: number;
  dispatch: Dispatch;
  galleryId: string;
  gallery?: CustomInspirationsGallery;
  hasDraft: boolean;
  shopApiKey?: IApiKey;
  allowedToAddImages: number | undefined;
}

export function EditCustomInspirationsGalleryPage({
  shopId,
  dispatch,
  galleryId,
  gallery,
  hasDraft,
  shopApiKey,
  permittedRouteMap,
  allowedToAddImages,
}: EditCustomInspirationsGalleryPageProps): JSX.Element {
  const mappedInitialGallery = useMemo(() => GalleryMapper.mapGalleryToDraft(gallery), [gallery]);

  const [draftGallery, setDraftGallery] = useState<CustomInspirationGalleryDraft | undefined>(
    mappedInitialGallery
  );
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const { showDirtyModal, closeModal, triggerTheModalIfDirtyOrCallback, currentActionConfig } =
    useConfirmationDialog({ isDirty });

  useEffect(() => {
    const isDraftEqualToSource = isEqual(mappedInitialGallery, draftGallery);
    setIsDirty(!isDraftEqualToSource);
  }, [draftGallery, mappedInitialGallery]);

  useEffect(() => {
    dispatch(editCustomInspirationsGalleryPageActions.notifyIsDirty({ isDirty }));
  }, [isDirty, dispatch]);

  const onChange = useCallback(
    (partialGallery: Partial<CustomInspirationGalleryDraft>) => {
      if (draftGallery) {
        setDraftGallery({ ...draftGallery, ...partialGallery });
      }
    },
    [draftGallery, setDraftGallery]
  );

  const navigateToList = useCallback(() => {
    if (!permittedRouteMap.galleriesList) return;

    dispatch(
      editCustomInspirationsGalleryPageActions.navigateTo({
        navigateTo: generatePath(permittedRouteMap.galleriesList.path, { shopId }),
      })
    );
  }, [shopId, permittedRouteMap.galleriesList, dispatch]);

  const navigateToAddImages = useCallback(() => {
    if (!permittedRouteMap.addImagesToCustomInspirationsGallery) return;

    dispatch(
      editCustomInspirationsGalleryPageActions.navigateTo({
        navigateTo: generatePath(permittedRouteMap.addImagesToCustomInspirationsGallery.path, {
          shopId,
          galleryId,
        }),
      })
    );
  }, [shopId, permittedRouteMap.addImagesToCustomInspirationsGallery, dispatch, galleryId]);

  const navigateToImageSettings = useCallback(
    (imageId: string, openedSections: ExpandableSection[]) => {
      if (!permittedRouteMap.tagImageInCustomInspirationsGallery) {
        return;
      }

      const baseUrl = generatePath(permittedRouteMap.tagImageInCustomInspirationsGallery.path, {
        shopId,
        galleryId,
        imageId,
      });

      const queryParams = getQueryStringParams(openedSections);

      const targetUrl = queryParams ? `${baseUrl}?${queryParams}` : baseUrl;

      dispatch(
        editCustomInspirationsGalleryPageActions.navigateTo({
          navigateTo: targetUrl,
        })
      );
    },
    [dispatch, permittedRouteMap.tagImageInCustomInspirationsGallery, galleryId, shopId]
  );

  useEffect(() => {
    const fetchGallery = async () => {
      try {
        await (
          dispatch(
            editCustomInspirationsGalleryPageActions.getGallery({
              shopId,
              galleryId,
              galleryType: GalleryType.CustomInspirations,
            })
          ) as any
        ).unwrap();
      } catch (error) {
        console.error(error);
        navigateToList();
      }
    };

    fetchGallery();
    dispatch(editCustomInspirationsGalleryPageActions.getApiKeys({ shopId }));

    return () => {
      dispatch(editCustomInspirationsGalleryPageActions.resetCurrentGallery());
      dispatch(editCustomInspirationsGalleryPageActions.resetApiKeys());
    };
  }, [shopId, galleryId, dispatch, navigateToList]);

  const resetDraftToInitialState = useCallback(() => {
    setDraftGallery(mappedInitialGallery);
  }, [setDraftGallery, mappedInitialGallery]);

  useEffect(() => {
    resetDraftToInitialState();
  }, [resetDraftToInitialState]);

  const onSaveGallery = useCallback(
    async galleryToSave => {
      let updatedGallery;

      try {
        setIsSaveInProgress(true);

        updatedGallery = await (
          dispatch(
            editCustomInspirationsGalleryPageActions.updateGallery({
              shopId,
              galleryId,
              payload: galleryToSave,
            })
          ) as any
        ).unwrap();
      } catch (error) {
        console.error(error);
      }

      setIsSaveInProgress(false);

      if (updatedGallery) {
        return GalleryMapper.mapGalleryToDraft(updatedGallery);
      }
    },
    [dispatch, shopId, galleryId, setDraftGallery]
  );

  const onSaveDraftGallery = useCallback(async () => {
    if (draftGallery) {
      return onSaveGallery(draftGallery);
    }
  }, [onSaveGallery, draftGallery]);

  const startTagsDetection = useCallback(async () => {
    try {
      await (
        dispatch(
          editCustomInspirationsGalleryPageActions.startTagsDetection({ shopId, galleryId })
        ) as any
      ).unwrap();
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, shopId, galleryId]);

  const saveDraftAndCallback = useCallback(async () => {
    const updatedGallery = await onSaveDraftGallery();
    closeModal();

    currentActionConfig?.cb(updatedGallery);
  }, [onSaveDraftGallery]);

  const resetDraftAndCallback = useCallback(async () => {
    closeModal();

    currentActionConfig?.cb();
    resetDraftToInitialState();
  }, [resetDraftToInitialState, closeModal]);

  const applyAllSuggestedTags = useCallback(
    (updatedGallery?: CustomInspirationGalleryDraft) => {
      const updatedImages = (updatedGallery || mappedInitialGallery).images.map(image => ({
        ...image,
        tags: [
          ...image.tags,
          ...image.aiSuggestedTags.map(tag => ({ ...tag, isAIDetected: true })),
        ],
        aiSuggestedTags: [],
      }));

      onSaveGallery({ images: updatedImages });
    },
    [onSaveGallery, mappedInitialGallery]
  );

  const removeAllSuggestedTags = useCallback(
    (updatedGallery?: CustomInspirationGalleryDraft) => {
      const updatedImages = (updatedGallery || mappedInitialGallery).images.map(image => ({
        ...image,
        aiSuggestedTags: [],
      }));

      onSaveGallery({ images: updatedImages });
    },
    [onSaveGallery, mappedInitialGallery]
  );

  return (
    <OverlayModalStyled show fullscreen={!hasDraft}>
      <EditCustomInspirationsGalleryPageStyled>
        <SideBarLayout>
          <EditGallerySideBar
            gallery={draftGallery}
            onChange={onChange}
            galleryId={galleryId}
            dispatch={dispatch}
            navigateToList={navigateToList}
          />
        </SideBarLayout>
        <PreviewLayout>
          <GalleryPreview
            gallery={draftGallery}
            galleryId={galleryId}
            onChange={onChange}
            navigateToImageSettings={navigateToImageSettings}
            navigateToAddImages={navigateToAddImages}
            dispatch={dispatch}
            shopId={shopId}
            shopApiKey={shopApiKey}
            allowedToAddImages={allowedToAddImages}
            startTagsDetection={() =>
              triggerTheModalIfDirtyOrCallback(startTagsDetection, Action.Generate)
            }
            applyAllSuggestedTags={() =>
              triggerTheModalIfDirtyOrCallback(applyAllSuggestedTags, Action.AcceptTags)
            }
            removeAllSuggestedTags={() =>
              triggerTheModalIfDirtyOrCallback(removeAllSuggestedTags, Action.RejectTags)
            }
          />
          <PreviewFooter>
            <Button variant='secondary' onClick={navigateToList}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={onSaveDraftGallery}
              disabled={!isDirty}
              loading={isSaveInProgress}
            >
              Save Gallery
            </Button>
          </PreviewFooter>
        </PreviewLayout>
      </EditCustomInspirationsGalleryPageStyled>
      <DirtyFormConfirmationDialog
        action={currentActionConfig?.action}
        close={closeModal}
        isOpened={showDirtyModal}
        isLoading={isSaveInProgress}
        onResetDraftAndProceed={resetDraftAndCallback}
        onSaveAndProceed={saveDraftAndCallback}
      />
    </OverlayModalStyled>
  );
}
