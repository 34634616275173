import { useEffect, useReducer } from 'react';
import { ISocialCollectionService } from '../../../../services/src/service/social-discovery/social-collection-service';
import { Product } from '../../types/entities.types';
import { slice, initialState } from './reducer';
import { SkuState } from './types';

const { reducer, actions } = slice;

export const useSKUState = (
  service: ISocialCollectionService,
  onProductFetched: (product: Product) => void
) => {
  const [SKUState, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (SKUState.product && SKUState.type === SkuState.Resolved) {
      onProductFetched(SKUState.product);
    }
  }, [SKUState.type, SKUState.product]);

  const fetchSKU = async () => {
    if (SKUState.type === SkuState.HasSku) {
      dispatch(actions.fetchSKU());
      const productResponse = await service.getProductBySKU(SKUState.sku);
      dispatch(actions.resolveSKU(productResponse));
    }
  };

  const editSKU = (sku: string) => {
    if (SKUState.type === SkuState.Loading || SKUState.type === SkuState.Resolved) return;
    dispatch(actions.editSKU(sku));
  };

  return {
    SKUState,
    editSKU,
    fetchSKU,
  };
};
