import { IVersionsTableHeadRow } from './types';

export const tableColumns: Record<string, IVersionsTableHeadRow> = {
  id: {
    Header: 'Version ID',
    accessor: 'id',
    minWidth: 50,
  },
  publishedAtFormatted: {
    Header: 'Published At',
    accessor: 'publishedAtFormatted',
    width: 50,
    maxWidth: 50,
    canSort: true,
  },
  publishedBy: {
    Header: 'Published By',
    accessor: 'publishedBy',
    width: 50,
    maxWidth: 50,
  },
  rowActions: {
    Header: '',
    accessor: 'rowActions',
    width: 20,
    maxWidth: 20,
  },
};

export const tableColumnsArray = Object.values(tableColumns);
