import React from 'react';
import { TypographyProps, TypographyType, TypographyVariant } from 'src/components-dummy';
import { RuleFormSectionStyled, RuleFormSectionHeaderStyled } from './RuleFormSection.styles';

export type RuleFormSectionHeaderProps = TypographyProps;

export const RuleFormSectionHeader = ({
  children,
  ...rest
}: RuleFormSectionHeaderProps): JSX.Element => {
  return (
    <RuleFormSectionHeaderStyled
      type={TypographyType.Body}
      variant={TypographyVariant.LargeBold}
      {...rest}
    >
      {children}
    </RuleFormSectionHeaderStyled>
  );
};

export const RuleFormSection = RuleFormSectionStyled as typeof RuleFormSectionStyled & {
  Header: typeof RuleFormSectionHeader;
};

RuleFormSection.Header = RuleFormSectionHeader;
