import { PermittedRouteMap, RouteSettings } from 'src/app-routes';
import { UserTypes } from 'src/services';

export const getUserDefaultRoute = (
  role: UserTypes.UserRoles,
  permittedRouteMap: PermittedRouteMap
): RouteSettings | undefined => {
  switch (role) {
    case UserTypes.UserRoles.SyteAdmin:
    case UserTypes.UserRoles.ClientAdmin:
      return permittedRouteMap.shopList;
    default:
      return undefined;
  }
};
