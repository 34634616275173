import {
  ILexiconTagsFilters,
  ILexiconTagsQueryAPI,
  LexiconTagField,
  IThematicTag,
  ILexiconRule,
} from '../types/lexicon';

export interface BaseServiceArguments {
  shopId: number;
  locale: string;
  tagType: LexiconTagType;
}
export interface ExcludeOrMatchTagKeys {
  excludeTags: string[];
  matchTags: string[];
}
export interface GetLexiconTagsArguments extends ILexiconTagsQueryAPI, BaseServiceArguments {}

export interface UpdateLexiconTagsArguments {
  shopId: number;
  locale: string;
  tagKey: string;
  tagToUpdate: RequestUpdateOneLexiconBody;
}
interface LexiconBaseTagRequest {
  key: string;
  customTranslation?: string;
}

export interface RequestUpdateOneLexiconBody {
  category: LexiconBaseTagRequest;
  attribute: LexiconBaseTagRequest;
  value: LexiconBaseTagRequest;
  include: boolean;
}

export interface UpdateLexiconTagsBulkArguments extends BaseServiceArguments {
  tagField: LexiconTagField;
  customTranslation: string;
  excludeTags?: ExcludeOrMatchTagKeys['excludeTags'];
  filters: Partial<ILexiconTagsFilters>;
}

/**
 * Lexicon thematic tag
 */

export interface UpdateThematicTagArguments {
  shopId: number;
  locale: string;
  id: string;
  partialTagToUpdate: Partial<
    Pick<IThematicTag, 'attribute' | 'category' | 'conditionsGroups' | 'value' | 'include'>
  >;
}

type PartialOrNull<T> = {
  [P in keyof T]?: T[P] | null;
};

export interface UpdateLexiconRuleArguments {
  shopId: number;
  locale: string;
  id: string;
  partialRuleToUpdate: Partial<Pick<ILexiconRule, 'conditionsGroups' | 'include'>> &
    PartialOrNull<Pick<ILexiconRule, 'attribute' | 'category' | 'value'>>;
}

export interface CreateThematicTagArguments {
  shopId: number;
  locale: string;
  payload: Omit<IThematicTag, 'id' | 'createdAt' | 'updatedAt'>;
}

export interface CreateLexiconRuleArguments {
  shopId: number;
  locale: string;
  payload: Omit<ILexiconRule, 'id' | 'createdAt' | 'updatedAt'>;
}

export interface GetLexiconRuleArguments {
  shopId: number;
  locale: string;
  id: string;
}

export enum LexiconTagType {
  LexiconTags = 'lexicon-tags',
  ThematicTags = 'thematic-tags',
  RenameRules = 'lexicon-rules',
}
