import React from 'react';
import { addDays, roundToNearestMinutes, differenceInDays } from 'date-fns';
import {
  AvailableIcons,
  Icon,
  SelectOnChangeEvent,
  TimeZoneDropdown,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { LinkButton } from 'src/components-dummy/LinkButton';
import { MerchandisingRuleTypes } from 'src/services';
import { TimezoneName } from 'countries-and-timezones';
import {
  addLocalTimeZone,
  subtractLocalTimeZone,
} from 'src/components-dummy/TimePicker/TimePicker.helpers';
import { MINUTE_STEP, RuleDateTimePicker } from './components/RuleDateTimePicker';
import {
  DateSectionContainerStyled,
  DeleteDateButtonStyled,
  timeZoneCss,
} from './RuleFormScheduleDateSection.styles';
import { RuleFormSection } from '../RuleFormSection/RuleFormSection';
import { RuleFormField } from '../RuleFormField/RuleFormField';

/**
 * config & utils
 */
const DEFAULT_RANGE_DAYS = 7;
const ONE_MINUTE_IN_MS = 60_000;

const getDateWithAddedMinutes = (date: Date, minutes: number): Date =>
  new Date(date.getTime() + minutes * ONE_MINUTE_IN_MS);

export const getDateWithAdded5Minutes = (date: Date): Date => getDateWithAddedMinutes(date, 5);

/**
 * Main
 */
export interface RuleFormScheduleDateSectionProps {
  appliedDateRange: MerchandisingRuleTypes.DateRange | null;
  onChange: (data: { appliedDateRange: MerchandisingRuleTypes.DateRange | null }) => void;
  isReadOnly?: boolean;
}

export const RuleFormScheduleDateSection = React.memo(
  ({ appliedDateRange, onChange, isReadOnly }: RuleFormScheduleDateSectionProps): JSX.Element => {
    const getDefaultDateTime = () => {
      // when roundToNearestMinutes - To make the date ceil (up) and not floor (e.g: 10:02 => 10:05, 10:00 => 10:05)
      let date = getDateWithAddedMinutes(new Date(), 3);

      date.setSeconds(0, 0);

      date = subtractLocalTimeZone(date);

      date = roundToNearestMinutes(date, { nearestTo: MINUTE_STEP });
      return date;
    };

    const onStartDateChanged = (date: Date | null) => {
      if (!appliedDateRange) {
        return;
      }

      const { endDate } = appliedDateRange;

      const startDate = date || getDefaultDateTime();
      const isEndDateBiggerThanStart = endDate > startDate;

      const endDateNewValue = isEndDateBiggerThanStart
        ? endDate
        : addDays(startDate, DEFAULT_RANGE_DAYS);

      onChange({
        appliedDateRange: {
          ...appliedDateRange,
          startDate,
          endDate: endDateNewValue,
        },
      });
    };

    const onEndDateChanged = (date: Date | null) => {
      if (!appliedDateRange) {
        return;
      }

      onChange({
        appliedDateRange: { ...appliedDateRange, endDate: date || getDefaultDateTime() },
      });
    };

    const onTimeZoneChanged: SelectOnChangeEvent = event => {
      if (!appliedDateRange) {
        return;
      }

      onChange({
        appliedDateRange: { ...appliedDateRange, timeZone: event.target.value as TimezoneName },
      });
    };

    const onAddAppliedDateRange = () => {
      const startDate = getDefaultDateTime();
      const endDate = addDays(startDate, DEFAULT_RANGE_DAYS);

      const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const defaultRange: MerchandisingRuleTypes.DateRange = {
        timeZone: localTimeZone as TimezoneName,
        startDate,
        endDate,
      };

      onChange({
        appliedDateRange: defaultRange,
      });
    };

    const onDeleteAppliedDateRange = () => {
      onChange({ appliedDateRange: null });
    };

    const startMinDate = addLocalTimeZone(getDateWithAdded5Minutes(new Date()));

    const endMinDate = addLocalTimeZone(
      // min 5 minutes more than 'startDate'
      getDateWithAdded5Minutes(appliedDateRange?.startDate || new Date())
    );

    const isSameStartDayAsMinDate =
      !!appliedDateRange?.startDate &&
      differenceInDays(startMinDate, appliedDateRange.startDate) === 0;

    const isEndDaySameDayAsStartDay =
      appliedDateRange?.startDate &&
      appliedDateRange.endDate &&
      differenceInDays(appliedDateRange.startDate, appliedDateRange.endDate) === 0;

    return (
      <DateSectionContainerStyled>
        <RuleFormSection.Header>Schedule rule</RuleFormSection.Header>
        {appliedDateRange ? (
          <>
            {!isReadOnly && (
              <DeleteDateButtonStyled onClick={onDeleteAppliedDateRange}>
                <Icon name={AvailableIcons.TrashCan} />
                <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                  Remove schedule
                </Typography>
              </DeleteDateButtonStyled>
            )}
            <RuleFormField>
              <TimeZoneDropdown
                css={timeZoneCss}
                value={appliedDateRange.timeZone}
                onChange={onTimeZoneChanged}
                disabled={isReadOnly}
              />
            </RuleFormField>
            <RuleDateTimePicker
              value={appliedDateRange.startDate}
              minDate={startMinDate}
              minTime={isSameStartDayAsMinDate ? startMinDate : undefined} // for same day - limit min time
              onChange={onStartDateChanged}
              dateTitle='Start date'
              timeTitle='Time (24 hour format)'
              disabled={isReadOnly}
            />
            <RuleDateTimePicker
              value={appliedDateRange.endDate}
              minTime={isEndDaySameDayAsStartDay ? endMinDate : undefined} // for same day - limit min time
              minDate={endMinDate}
              onChange={onEndDateChanged}
              dateTitle='End date'
              timeTitle='Time (24 hour format)'
              disabled={isReadOnly}
            />
          </>
        ) : (
          !isReadOnly && <LinkButton onClick={onAddAppliedDateRange}>Set start/end date</LinkButton>
        )}
      </DateSectionContainerStyled>
    );
  }
);
