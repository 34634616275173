import React, { useEffect, useState, useMemo, useCallback, MouseEvent } from 'react';
import { FieldType, MerchandisingRuleTypes } from 'src/services';
import { getStringEnumValues } from 'src/utils';
import { MenuItem, SelectOnChangeEvent, Tooltip } from 'src/components-dummy';
import {
  DataFieldsConditionProps,
  DataFieldConditionDataInnerType,
} from './DataFieldsCondition.types';
import {
  partialSubRuleToDataFields,
  getDefaultConditionValue,
  dataFieldsToPartialSubRule,
} from './DataFieldsCondition.helpers';
import {
  DataFieldsConditionStyled,
  DataFieldConditionSelectStyled,
  DataFieldSelectStyled,
  AiTagMenuItemStyled,
} from './DataFieldsCondition.styles';
import { ConditionValueInput } from './ConditionValueInput';
import { DataFieldsSearchableSelect } from './DataFieldsSearchableSelect';

const subTypeToDisplayTextMap: Record<MerchandisingRuleTypes.MerchandisingRuleSubType, string> = {
  [MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain]: 'Is not',
  [MerchandisingRuleTypes.MerchandisingRuleSubType.Contains]: 'Contains either',
  [MerchandisingRuleTypes.MerchandisingRuleSubType.Equals]: 'Exactly matches',
  [MerchandisingRuleTypes.MerchandisingRuleSubType.EqualsSource]: 'Is equal to source SKU',
  [MerchandisingRuleTypes.MerchandisingRuleSubType.HasValue]: 'Is not empty',
  [MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotHaveValue]: 'Is empty',
  [MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan]: 'Higher than',
  [MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan]: 'Lower than',
  [MerchandisingRuleTypes.MerchandisingRuleSubType.RelativeRange]: 'In Relative Range of',
  [MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween]: 'Is between',
};

const fieldTypeToDisplayTextMap: Record<FieldType, string> = {
  [FieldType.CatalogField]: 'Catalog Field',
  [FieldType.AiTag]: 'AI Tags',
};

export const allConditionTypeDropDownValues = getStringEnumValues(
  MerchandisingRuleTypes.MerchandisingRuleSubType
).map(value => {
  return {
    value,
    text: subTypeToDisplayTextMap[value],
  };
});

export const DataFieldsCondition = ({
  conditionTypes,
  data,
  onChange,
  errors,
  disabled,
  getDataFieldByName,
  dataFieldsLookupTable,
  dispatch,
  shopId,
  isAiTagsEnabled,
}: DataFieldsConditionProps): JSX.Element => {
  const initialSelectedDataField = getDataFieldByName(data.field);

  const [selectedDataField, setSelectedDataField] = useState(initialSelectedDataField);

  const [dataFieldChangeLoading, setDataFieldChangeLoading] = useState(false);

  const conditionSelectValues = useMemo(() => {
    const relevantConditionTypesSet = new Set(
      selectedDataField?.conditionTypes.filter(type => conditionTypes.includes(type)) || []
    );

    return allConditionTypeDropDownValues.filter(item => {
      return relevantConditionTypesSet.has(item.value);
    });
  }, [selectedDataField?.conditionTypes, conditionTypes]);

  const conditionData = useMemo(() => partialSubRuleToDataFields(data), [data]);

  const dataFieldsSelectOptions = useMemo(() => {
    const result = dataFieldsLookupTable
      ? Object.keys(dataFieldsLookupTable)
          .filter(name => dataFieldsLookupTable[name].fieldType === conditionData.fieldType)
          .sort()
          .map(name => {
            return { value: name, text: dataFieldsLookupTable[name].displayName };
          })
      : [];

    return result;
  }, [dataFieldsLookupTable, conditionData.fieldType]);

  const dataFieldIsInvalid = errors.field?.type === 'any.custom';

  const dataFieldError = dataFieldIsInvalid ? errors.field.message : !!errors.field;

  const dataFieldOperatorIsInvalid = errors.subType?.type === 'any.custom';

  const dataFieldOperatorError = dataFieldOperatorIsInvalid
    ? errors.subType.message
    : !!errors.subType;

  const onStateChange = (partialData: Partial<DataFieldConditionDataInnerType>) => {
    const updatedState = { ...conditionData, ...partialData };
    onChange?.(dataFieldsToPartialSubRule(updatedState));
  };

  const onFieldTypeChange: SelectOnChangeEvent = useCallback(
    event => {
      onStateChange({
        fieldType: event.target.value as FieldType,
        dataField: undefined,
        conditionType: undefined,
        conditionValue: undefined,
      });
    },
    [onStateChange]
  );

  const onAiTagFieldTypeClickCapture = useCallback(
    (event: MouseEvent) => {
      if (!isAiTagsEnabled) {
        event.stopPropagation();
      }
    },
    [isAiTagsEnabled]
  );

  const onDataFieldChange: SelectOnChangeEvent = event => {
    setDataFieldChangeLoading(true);
    onStateChange({
      dataField: event.target.value as string,
      conditionType: undefined,
      conditionValue: undefined,
    });
  };

  const onConditionTypeChange: SelectOnChangeEvent = event => {
    const conditionType = event.target.value as MerchandisingRuleTypes.MerchandisingRuleSubType;

    onStateChange({
      conditionType,
      conditionValue: getDefaultConditionValue(conditionType, selectedDataField?.types),
    });
  };

  const onValuesChange = (values: MerchandisingRuleTypes.SubRuleOptionalValues) => {
    onStateChange({ conditionValue: values });
  };

  useEffect(() => {
    if (dataFieldChangeLoading) {
      setDataFieldChangeLoading(false);
    }
  }, [dataFieldChangeLoading, setDataFieldChangeLoading]);

  const dataField = dataFieldsLookupTable?.[conditionData?.dataField || ''] || undefined;
  const conditionValueInput = !dataFieldOperatorIsInvalid && (
    <ConditionValueInput
      getDataFieldByName={getDataFieldByName}
      dataFieldTypes={dataField?.types}
      conditionData={conditionData}
      onChange={onValuesChange}
      disabled={disabled}
      errors={errors}
      dispatch={dispatch}
      shopId={shopId}
    />
  );

  useEffect(() => {
    if (data.field && selectedDataField?.name !== data.field) {
      const newSelectedDataField = getDataFieldByName(data.field);
      setSelectedDataField(newSelectedDataField);
    }
  }, [data.field, getDataFieldByName]);

  return (
    <DataFieldsConditionStyled>
      <DataFieldSelectStyled
        value={conditionData.fieldType}
        key={conditionData.fieldType}
        disabled={disabled}
        placeholder='Select field source'
        isError={Boolean(errors.fieldType)}
        onChange={onFieldTypeChange}
        autoFocus
      >
        <MenuItem key={FieldType.CatalogField} value={FieldType.CatalogField}>
          {fieldTypeToDisplayTextMap[FieldType.CatalogField]}
        </MenuItem>

        <AiTagMenuItemStyled
          key={FieldType.AiTag}
          value={FieldType.AiTag}
          isDisabled={!isAiTagsEnabled}
          onClickCapture={onAiTagFieldTypeClickCapture}
        >
          <Tooltip
            value='Contact your account manager to enable this option'
            position='bottom center'
            disabled={isAiTagsEnabled}
          >
            {fieldTypeToDisplayTextMap[FieldType.AiTag]}
          </Tooltip>
        </AiTagMenuItemStyled>
      </DataFieldSelectStyled>
      {conditionData.fieldType && (
        <DataFieldsSearchableSelect
          dataField={conditionData.dataField}
          disabled={disabled}
          isError={Boolean(dataFieldError)}
          options={dataFieldsSelectOptions}
          onChange={onDataFieldChange}
        />
      )}
      {conditionData.dataField && !dataFieldError && !dataFieldChangeLoading && (
        <>
          <DataFieldConditionSelectStyled
            value={conditionData.conditionType || undefined}
            placeholder='Select ...'
            isError={!!dataFieldOperatorError}
            errorMessage={
              typeof dataFieldOperatorError === 'string' ? dataFieldOperatorError : undefined
            }
            disabled={disabled}
            onChange={onConditionTypeChange}
            autoFocus
          >
            {conditionSelectValues.map((option: { value: string; text: string }) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </DataFieldConditionSelectStyled>
          {conditionValueInput}
        </>
      )}
    </DataFieldsConditionStyled>
  );
};
