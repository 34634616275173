import React from 'react';
import { Row } from 'react-table';

import { Button, ConfirmationDialog, TableV2 } from 'src/components-dummy';
import { CollectionMenu } from '../Collection/CollectionMenu';
import { useCollectionMenu } from '../Collection/useCollectionMenu';

import { tableColumns } from './consts';

import { Dispatch } from '../../types';
import { CollectionsTableBodyRow } from './types';

import { TableBodyRowCellStyled } from '../../../components-dummy/TableV2/TableV2.style';
import { ButtonsContainer } from '../../../components-dummy/ConfirmationDialog/ConfirmationDialog.style';
import { CollectionNameStyled } from './CollectionsList.styles';

interface CollectionsTableRowProps {
  shopId: number;
  dispatch: Dispatch;
  row: Row<CollectionsTableBodyRow>;
  onEditCollection?: (collectionId: string) => void;
  variantId?: string;
}

export const CollectionsTableRow = ({
  shopId,
  dispatch,
  row,
  onEditCollection,
  variantId,
}: CollectionsTableRowProps): JSX.Element => {
  const {
    showDeleteDialog,
    closeDeleteDialog,
    onDeleteCollectionClicked,
    onDeleteCollection,
    onDuplicateCollectionClicked,
    onEditCollectionClicked,
    menuAnchorElement,
    onMenuClicked,
    onMenuClose,
  } = useCollectionMenu({
    shopId,
    collectionId: row.original.id,
    dispatch,
    onEditCollection,
    variantId,
  });

  const onConfirmDeleteCollection = (): void => {
    onDeleteCollection();
    closeDeleteDialog();
  };

  return (
    <>
      {showDeleteDialog && (
        <ConfirmationDialog onCancel={closeDeleteDialog}>
          <ConfirmationDialog.Header>
            <ConfirmationDialog.Title>Delete Collection?</ConfirmationDialog.Title>
          </ConfirmationDialog.Header>
          <ConfirmationDialog.Content>
            Are you sure you want to delete the collection &apos;{row.original.name}&apos;?
            <br /> This collection is published in production and will become unavailable.
          </ConfirmationDialog.Content>
          <ConfirmationDialog.Footer>
            <ButtonsContainer>
              <Button variant='destructive' onClick={onConfirmDeleteCollection}>
                Delete Collection
              </Button>
              <Button variant='tertiary' onClick={closeDeleteDialog}>
                Close
              </Button>
            </ButtonsContainer>
          </ConfirmationDialog.Footer>
        </ConfirmationDialog>
      )}
      <TableV2.BodyRow {...row.getRowProps()} key={row.original.id}>
        {row.cells.map(cell => {
          const propName = cell.column.id;

          if (propName === tableColumns.name.accessor) {
            return (
              <CollectionNameStyled
                variant='primary'
                onClick={onEditCollectionClicked}
                key={propName}
              >
                <TableV2.BodyRowCellText>{cell.value}</TableV2.BodyRowCellText>
              </CollectionNameStyled>
            );
          }

          if (propName === tableColumns.rowActions.accessor) {
            return (
              <CollectionMenu
                key={propName}
                menuAnchorElement={menuAnchorElement}
                onMenuClicked={onMenuClicked}
                onMenuClose={onMenuClose}
                onDeleteCollectionClicked={onDeleteCollectionClicked}
                onDuplicateCollectionClicked={onDuplicateCollectionClicked}
                onEditCollectionClicked={onEditCollectionClicked}
              />
            );
          }

          return (
            <TableBodyRowCellStyled
              {...cell.getCellProps()}
              key={propName}
              width={cell.column.width as string}
              minWidth={cell.column.minWidth}
              data-id={`${row.original.name}-${propName}`}
            >
              <TableV2.BodyRowCellText>{cell.value}</TableV2.BodyRowCellText>
            </TableBodyRowCellStyled>
          );
        })}
      </TableV2.BodyRow>
    </>
  );
};
