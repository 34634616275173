import { ShopTypes } from 'src/services';

export enum ShopCardActionTypes {
  DeleteShopRequest = 'ShopCard/DeleteShopRequest',
  DeleteShopSuccess = 'ShopCard/DeleteShopSuccess',
  DeleteShopError = 'ShopCard/DeleteShopError',
  // ArchiveShopRequest = 'ShopCard/ArchiveShopRequest',
  // ArchiveShopSuccess = 'ShopCard/ArchiveShopSuccess',
  // ArchiveShopError = 'ShopCard/ArchiveShopError',
  // RestoreShopRequest = 'ShopCard/RestoreShopRequest',
  // RestoreShopSuccess = 'ShopCard/RestoreShopSuccess',
  // RestoreShopError = 'ShopCard/RestoreShopError',
}

export interface ShopCardDeleteShopRequestActionPayload {
  accountId: number;
  shopId: number;
}

export interface ShopCardDeleteShopSuccessActionPayload {
  deletedShopId: number;
  shops: ShopTypes.Shop[];
}

export interface ShopCardDeleteShopErrorActionPayload {
  error: unknown;
}

// export interface ShopCardArchiveShopRequestActionPayload {
//   accountId: number;
//   shopId: number;
// }

// export interface ShopCardArchiveShopSuccessActionPayload {
//   updatedShop: ShopTypes.Shop;
//   shops: ShopTypes.Shop[];
// }

// export interface ShopCardArchiveShopErrorActionPayload {
//   error: unknown;
// }

// export interface ShopCardRestoreShopRequestActionPayload {
//   accountId: number;
//   shopId: number;
// }

// export interface ShopCardRestoreShopSuccessActionPayload {
//   updatedShop: ShopTypes.Shop;
//   shops: ShopTypes.Shop[];
// }

// export interface ShopCardRestoreShopErrorActionPayload {
//   error: unknown;
// }

export interface ShopCardDeleteShopRequestAction {
  type: typeof ShopCardActionTypes.DeleteShopRequest;
  payload: ShopCardDeleteShopRequestActionPayload;
}

export interface ShopCardDeleteShopSuccessAction {
  type: typeof ShopCardActionTypes.DeleteShopSuccess;
  payload: ShopCardDeleteShopSuccessActionPayload;
}

export interface ShopCardDeleteShopErrorAction {
  type: typeof ShopCardActionTypes.DeleteShopError;
  payload: ShopCardDeleteShopErrorActionPayload;
}

// export interface ShopCardArchiveShopRequestAction {
//   type: typeof ShopCardActionTypes.ArchiveShopRequest;
//   payload: ShopCardArchiveShopRequestActionPayload;
// }

// export interface ShopCardArchiveShopSuccessAction {
//   type: typeof ShopCardActionTypes.ArchiveShopSuccess;
//   payload: ShopCardArchiveShopSuccessActionPayload;
// }

// export interface ShopCardArchiveShopErrorAction {
//   type: typeof ShopCardActionTypes.ArchiveShopError;
//   payload: ShopCardArchiveShopErrorActionPayload;
// }

// export interface ShopCardRestoreShopRequestAction {
//   type: typeof ShopCardActionTypes.RestoreShopRequest;
//   payload: ShopCardRestoreShopRequestActionPayload;
// }

// export interface ShopCardRestoreShopSuccessAction {
//   type: typeof ShopCardActionTypes.RestoreShopSuccess;
//   payload: ShopCardRestoreShopSuccessActionPayload;
// }

// export interface ShopCardRestoreShopErrorAction {
//   type: typeof ShopCardActionTypes.RestoreShopError;
//   payload: ShopCardRestoreShopErrorActionPayload;
// }

export type ShopCardAction =
  | ShopCardDeleteShopRequestAction
  | ShopCardDeleteShopSuccessAction
  | ShopCardDeleteShopErrorAction;
// | ShopCardArchiveShopRequestAction
// | ShopCardArchiveShopSuccessAction
// | ShopCardArchiveShopErrorAction
// | ShopCardRestoreShopRequestAction
// | ShopCardRestoreShopSuccessAction
// | ShopCardRestoreShopErrorAction
