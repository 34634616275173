import { useCallback, useEffect } from 'react';
import { shopVersionWebSocketService, ShopVersionDraftUpdateEvent } from 'src/services';
import { Dispatch } from 'src/components-bl';
import { useShopVersionsWSActions } from './useShopVersionsWS.actions';

export const useShopVersionsWS = ({
  dispatch,
  shopId,
}: {
  dispatch: Dispatch;
  shopId: number | undefined;
}): void => {
  const issueToastNotification = ({
    eventIssuedByCurrentUser,
    customMessage,
  }: Pick<
    ShopVersionDraftUpdateEvent,
    'eventIssuedByCurrentUser' | 'topicKey' | 'isSystemEvent'
  > & { customMessage: string }) => {
    if (eventIssuedByCurrentUser) return;

    dispatch(useShopVersionsWSActions.useShopVersionsWSNotification({ customMessage }));
  };

  const onDraftUpdate = useCallback(
    (payload: ShopVersionDraftUpdateEvent) => {
      if (payload.data.shopId === shopId) {
        dispatch(useShopVersionsWSActions.getDraft({ shopId, slim: false }));
      }
    },
    [shopId]
  );

  const onDraftDiscard = useCallback(
    (payload: ShopVersionDraftUpdateEvent) => {
      if (payload.data.shopId === shopId) {
        dispatch(useShopVersionsWSActions.getDraft({ shopId, slim: false }));

        issueToastNotification({
          ...payload,
          customMessage: 'The draft was discarded. Please, refresh the page',
        });
      }
    },
    [shopId]
  );

  useEffect(() => {
    if (shopId) {
      dispatch(useShopVersionsWSActions.getDraft({ shopId, slim: false }));

      shopVersionWebSocketService.subscribeToDraftUpdateEvents(onDraftUpdate);
      shopVersionWebSocketService.subscribeToDraftDiscardEvents(onDraftDiscard);
    }

    return () => {
      shopVersionWebSocketService.clearListeners();
    };
  }, [shopId]);
};
