import React from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useAppSelector } from 'src/hooks';
import { DeepTagReportProductPagination } from 'src/components-bl';

export const ConnectedDeepTagReportProductPagination = (): JSX.Element => {
  const dispatch = useDispatch();
  const { selectedReport, shopId, skip, limit, totalProducts } = useAppSelector(state => {
    return {
      selectedReport: state.deepTagReports.selectedReport,
      shopId: state.shop.current?.shopId,
      skip: state.deepTagReports.paginationFilters.skip,
      limit: state.deepTagReports.paginationFilters.limit,
      totalProducts: state.deepTagReports.productPagination?.totalCount,
    };
  }, shallowEqual);

  return (
    <DeepTagReportProductPagination
      key={`${shopId}${selectedReport?.id}`}
      totalProducts={totalProducts}
      dispatch={dispatch}
      skip={skip}
      limit={limit}
    />
  );
};
