import {
  DeepTag,
  DeepTagAPI,
  DeepTagStatusEnum,
} from 'src/services/src/service/types/deep-tag-reports';
import { getTagsDiff } from '../../DeepTagsProductForm.helpers';
import { DeepTagForm } from '../../DeepTagsProductForm.types';

export const filterAndMapTags = (tags: DeepTagForm[]): DeepTagAPI[] =>
  (tags || []).reduce((prev, tag) => {
    // Exclude tags with `deleted` status
    if (tag.status === DeepTagStatusEnum.Deleted) {
      return prev;
    }

    const tagAPI = {
      attribute: tag.attribute,
      value: tag.value,
      source: tag.source,
      score: tag.score,
    } as DeepTagAPI;

    prev.push(tagAPI);

    return prev;
  }, [] as DeepTagAPI[]);

export const isTagsFormDirty = ({
  newFormData,
  apiTagsByAttributeMap,
  apiTagsList,
}: {
  newFormData: DeepTagForm[];
  apiTagsByAttributeMap: Record<string, DeepTag>;
  apiTagsList: DeepTag[];
}): boolean => {
  const changedTags = getTagsDiff({
    newFormData,
    apiTagsByAttributeMap,
  });

  if (changedTags.length) {
    return true;
  }

  const originalTagsWithoutDeletedStatus =
    apiTagsList.filter(tag => tag.status !== DeepTagStatusEnum.Deleted) || [];

  const hasTotalTagsChanged = newFormData.length !== originalTagsWithoutDeletedStatus.length;

  return hasTotalTagsChanged;
};
