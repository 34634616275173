import {
  GetDiscoveryBannerSettingsArgs,
  UpdateDiscoveryBannerSettingsPayload,
} from 'src/components-bl/DiscoveryBanner/Actions/types';
import * as builder from './builder';
import { DiscoveryBannerSettingsTypes } from '../types';
import { ApiServiceBase } from '../api-service-base';

export class DiscoveryBannerGeneralSettings extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl({ shopId, variantId }: GetDiscoveryBannerSettingsArgs): string {
    return `${this.serviceBaseUri}/${shopId}/syte-products/discovery-banner/general-settings${
      variantId ? `?variantId=${variantId}` : ''
    }`;
  }

  async get({
    shopId,
    variantId,
  }: GetDiscoveryBannerSettingsArgs): Promise<DiscoveryBannerSettingsTypes.DiscoveryBannerSettings> {
    const url = this.getUrl({ shopId, variantId });
    const response = await this.httpService.get({ url });
    return response.data;
  }

  async update({
    shopId,
    variantId,
    ...payload
  }: UpdateDiscoveryBannerSettingsPayload): Promise<DiscoveryBannerSettingsTypes.DiscoveryBannerSettings> {
    const url = this.getUrl({ shopId, variantId });
    const data = builder.buildDiscoveryBannerSettingsPayload(payload);
    const response = await this.httpService.patch({ url, data });
    return response.data;
  }
}
