import Joi from 'joi';
import { BoughtTogetherFallbackMethod } from 'src/types/enums/bought-together-fallback-method';
import { SortingOrder } from 'src/types/enums/sorting-order';

export const boughtTogetherSettingsValidationSchema = {
  numberOfResultsToDisplay: Joi.number().required().min(3).max(20),
  modelType: Joi.string().required(),
  boughtTogetherFallbackMethod: Joi.string().required(),
  addToCart: Joi.object({ active: Joi.boolean().required() }),
  dataFields: Joi.array(),
  boughtTogetherFallbackField: Joi.alternatives().conditional('boughtTogetherFallbackMethod', {
    is: BoughtTogetherFallbackMethod.CustomFallback,
    then: Joi.string().required(),
    otherwise: Joi.string(),
  }),
  sortingOrder: Joi.string()
    .optional()
    .valid(...Object.values(SortingOrder)),
};
