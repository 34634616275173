import { RelevancyTuningItem } from 'src/services';
import { DomainForRendering } from '../../types';
type TuningEntity = Pick<RelevancyTuningItem, 'active' | 'priority' | 'dataField'>;

export const augmentedSearchTuningSubDomainGroup: DomainForRendering<TuningEntity> = {
  getCardTitle: ({ newEntity, oldEntity }, dataField) => {
    const tuning = newEntity ?? oldEntity;
    const displayName = dataField?.find(({ name }) => name === tuning?.dataField)?.displayName;
    return `Relevancy Tuning > ${displayName || tuning?.dataField || 'N/A'}`;
  },
  fieldsSchema: {
    priority: {
      displayName: 'Priority',
      getValue({ entity }: { entity: TuningEntity }): string {
        return `${entity.priority}`;
      },
    },
    active: {
      displayName: 'Enabled',
      getValue({ entity }: { entity: TuningEntity }): string {
        return entity.active ? 'True' : 'False';
      },
    },
  },
};
