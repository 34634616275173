import React from 'react';
import { AvailableIcons, Icon } from '../Icon';
import { Tooltip } from '../Tooltip';

export const IconIndication = ({
  tooltipValue,
  iconName,
}: {
  tooltipValue: string;
  iconName: AvailableIcons;
}): JSX.Element => {
  return (
    <Tooltip value={tooltipValue}>
      <Icon name={iconName} />
    </Tooltip>
  );
};
