import React, { useMemo } from 'react';
import {
  MenuItem,
  Select,
  SelectType,
  MenuSelectProps,
  PrimarySelectProps,
} from 'src/components-dummy';
import './TimeZoneDropdown.scss';
import classNames from 'classnames';
import { buildTimeZoneOptions } from './helpers';

export type TimeZoneSelectProps = MenuSelectProps &
  PrimarySelectProps & {
    className?: string;
  };

const timeZoneOptions = buildTimeZoneOptions();

export const TimeZoneDropdown = ({
  value,
  className,
  ...dropdownProps
}: TimeZoneSelectProps): JSX.Element => {
  const options = useMemo(() => {
    return timeZoneOptions.map(option => (
      <MenuItem key={option.value} value={option.value}>
        {option.text}
      </MenuItem>
    ));
  }, []);

  return (
    <Select
      type={SelectType.Primary}
      {...dropdownProps}
      value={value}
      className={classNames(
        'syte-drop-down syte-time-zone-dropdown syte-date-time-range-picker-time-zone-dropdown',
        className
      )}
    >
      {options}
    </Select>
  );
};
