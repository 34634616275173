import React, { useCallback, useMemo } from 'react';
import { ParseJoiValidateResponse, reduceErrorsByPrefix } from 'src/utils';
import { AvailableIcons, ErrorLabel } from 'src/components-dummy';
import { Dispatch } from '../../../../../../types';
import {
  AddNewConditionGroupButtonStyled,
  ConditionGroupsStyled,
  OrIndicationButtonStyled,
  ConditionGroupStyled,
} from './ConditionGroups.styles';
import {
  IThematicTagDraft,
  IThematicTagConditionsGroupDraft,
  AvailableAttributeKeysByCategoryMap,
} from '../../ThematicTagForm.types';
import { generateEmptyConditionGroup } from '../../constants';
import { ConditionRules } from './ConditionRules';

interface ConditionsGroupProps {
  onChange: (updatedGroup: IThematicTagConditionsGroupDraft) => void;
  conditionsGroup: IThematicTagConditionsGroupDraft;
  index: number;
  totalGroupsCount: number;
  onRemoveConditionGroup: (id: string) => void;
  availableCategoryKeys: string[];
  availableAttributeKeysByCategory: AvailableAttributeKeysByCategoryMap;
  shopId: number;
  locale: string;
  dispatch: Dispatch;
  errors: ParseJoiValidateResponse<IThematicTagDraft>;
}

export const ConditionsGroup = React.memo(
  ({
    conditionsGroup,
    index,
    onRemoveConditionGroup,
    onChange,
    availableCategoryKeys,
    availableAttributeKeysByCategory,
    dispatch,
    shopId,
    locale,
    errors,
    totalGroupsCount,
  }: ConditionsGroupProps): JSX.Element => {
    const conditionGroupErrors = useMemo(
      () => reduceErrorsByPrefix({ errors, prefix: `conditionsGroups.${index}` }),
      [errors]
    );

    const isFirstGroup = index === 0;
    const isTheOnlyGroup = totalGroupsCount === 1;

    const onRemove = useCallback(
      () => onRemoveConditionGroup(conditionsGroup.id),
      [onRemoveConditionGroup, conditionsGroup.id]
    );

    const onConditionsChange = useCallback(
      ({ conditions }: Pick<IThematicTagConditionsGroupDraft, 'conditions'>) => {
        onChange({ id: conditionsGroup.id, conditions });
      },
      [onChange, conditionsGroup.id]
    );

    return (
      <ConditionGroupStyled>
        {!isFirstGroup && (
          <OrIndicationButtonStyled
            onClick={onRemove}
            variant='primary'
            endIcon={AvailableIcons.Close}
            size='tiny'
          >
            OR
          </OrIndicationButtonStyled>
        )}
        <ConditionRules
          conditions={conditionsGroup.conditions}
          onChange={onConditionsChange}
          availableCategoryKeys={availableCategoryKeys}
          availableAttributeKeysByCategory={availableAttributeKeysByCategory}
          isFirstGroup={isFirstGroup}
          dispatch={dispatch}
          shopId={shopId}
          locale={locale}
          errors={conditionGroupErrors}
          isTheOnlyGroup={isTheOnlyGroup}
        />
      </ConditionGroupStyled>
    );
  }
);

export interface ConditionGroupsProps extends Pick<IThematicTagDraft, 'conditionsGroups'> {
  errors: ParseJoiValidateResponse<IThematicTagDraft>;
  onChange: (partialState: Partial<IThematicTagDraft>) => void;
  availableAttributeKeysByCategory: AvailableAttributeKeysByCategoryMap;
  availableCategoryKeys: string[];
  shopId: number;
  locale: string;
  dispatch: Dispatch;
  enableMultipleGroups?: boolean;
}

export const ConditionGroups = React.memo(
  ({
    conditionsGroups,
    errors,
    onChange,
    availableCategoryKeys,
    availableAttributeKeysByCategory,
    dispatch,
    shopId,
    locale,
    enableMultipleGroups = true,
  }: ConditionGroupsProps): JSX.Element => {
    const conditionGroupsErrorMessage = useMemo(
      () => errors.conditionsGroups?.message,
      [errors.conditionsGroups]
    );

    const onAddNewConditionsGroup = useCallback(() => {
      const newConditionGroup = generateEmptyConditionGroup();
      if (conditionsGroups.length > 0) {
        // categoryKey will be the same in first condition of the first condition rule
        newConditionGroup.conditions[0].categoryKey = conditionsGroups[0].conditions[0].categoryKey;
      }

      const updatedGroups = [...conditionsGroups, newConditionGroup];

      onChange({ conditionsGroups: updatedGroups });
    }, [conditionsGroups, onChange]);

    const onRemoveConditionGroup = useCallback(
      (groupId: string) => {
        const updatedGroups = conditionsGroups.filter(group => group.id !== groupId);
        onChange({ conditionsGroups: updatedGroups });
      },
      [conditionsGroups, onChange]
    );

    const onConditionGroupChange = useCallback(
      (updatedGroup: IThematicTagConditionsGroupDraft) => {
        const updatedGroups = conditionsGroups.map(group => {
          if (group.id === updatedGroup.id) {
            return updatedGroup;
          }
          return group;
        });

        onChange({ conditionsGroups: updatedGroups });
      },
      [conditionsGroups, onChange]
    );

    return (
      <ConditionGroupsStyled>
        {conditionGroupsErrorMessage && <ErrorLabel value={conditionGroupsErrorMessage} />}
        {conditionsGroups.map((group, index) => {
          return (
            <ConditionsGroup
              key={group.id}
              conditionsGroup={group}
              index={index}
              onRemoveConditionGroup={onRemoveConditionGroup}
              onChange={onConditionGroupChange}
              availableCategoryKeys={availableCategoryKeys}
              availableAttributeKeysByCategory={availableAttributeKeysByCategory}
              dispatch={dispatch}
              shopId={shopId}
              locale={locale}
              errors={errors}
              totalGroupsCount={conditionsGroups.length}
            />
          );
        })}
        {enableMultipleGroups && (
          <AddNewConditionGroupButtonStyled
            onClick={onAddNewConditionsGroup}
            variant='tertiary'
            startIcon={AvailableIcons.Plus}
          >
            Add OR group
          </AddNewConditionGroupButtonStyled>
        )}
      </ConditionGroupsStyled>
    );
  }
);
