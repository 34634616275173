import { AxiosResponse } from 'axios';
import { ShopTheLookSettingsTypes } from '../types';

export const shopTheLookSettingsResponse = ({
  data,
}: AxiosResponse): ShopTheLookSettingsTypes.ShopTheLookSettings => {
  return {
    numberOfResultsToDisplay: data.numberOfResultsToDisplay,
    includeMainProductInCarousel: data.includeMainProductInCarousel,
    shuffleResults: data.shuffleResults,
    completeTheLookFallbackMethod: data.completeTheLookFallbackMethod,
    addToCart: data.addToCart,
  };
};
