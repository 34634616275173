import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { lexiconService } from 'src/services';

export const lexiconPageHeaderActions = {
  getLexiconMetadata: createAsyncThunk(
    'LexiconPageHeader/GetShopLexiconMetadata',
    async ({ shopId, locale }: { shopId: number; locale: string }, { rejectWithValue }) => {
      try {
        const lexiconMetadata = await lexiconService.getLexiconMetadata({
          shopId,
          locale,
        });

        return lexiconMetadata;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  publishLexicon: createAsyncThunk(
    'LexiconPageHeader/PublishLexicon',
    async ({ shopId, locale }: { shopId: number; locale: string }, { rejectWithValue }) => {
      try {
        const updatedMetadata = await lexiconService.publishLexicon({
          shopId,
          locale,
        });

        return updatedMetadata;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  resetLexiconMetadata: createAction('LexiconPageHeader/ResetMetadata'),
  updateShowApplyAll: createAction<{ shouldShow: boolean }>('LexiconPageHeader/UpdateShowApplyALl'),
  navigateTo: createAction<{ navigateTo: string }>('LexiconPageHeader/NavigateTo'),
};
