import { DeepTagProductStatusType } from 'src/services/src/service/types/deep-tag-reports';
import { Theme } from '@emotion/react';

export const statusDropDownValues = [
  { value: DeepTagProductStatusType.New, text: 'New' },
  { value: DeepTagProductStatusType.InReview, text: 'In Review' },
  { value: DeepTagProductStatusType.Done, text: 'Done' },
  { value: DeepTagProductStatusType.DoneWithIssues, text: 'Done With Issues' },
];

export const textByStatusMap = statusDropDownValues.reduce(
  (prev, next) => {
    // eslint-disable-next-line no-param-reassign
    prev[next.value] = next.text;
    return prev;
  },
  {} as Record<DeepTagProductStatusType, string>
);

export function getColorByStatus(theme: Theme, status: DeepTagProductStatusType): string {
  switch (status) {
    case DeepTagProductStatusType.InReview:
      return theme.palette.custom.yellow;
    case DeepTagProductStatusType.Done:
      return theme.palette.custom.success;
    case DeepTagProductStatusType.DoneWithIssues:
      return '#44BCFF';
    case DeepTagProductStatusType.New:
    default:
      return theme.palette.custom['primary-main'];
  }
}
