import { SyteProductType } from 'src/services/src/service/types/constants/syte-product-types';

export enum RuleWeightLevelEnum {
  Lowest = 'lowest',
  Low = 'low',
  Moderate = 'moderate',
  High = 'high',
  Highest = 'highest',
}

export const MAX_LOWEST = 20;
export const MAX_LOW = 40;
export const MAX_MODERATE = 60;
export const MAX_HIGH = 80;
export const MAX_HIGHEST = 100;

export const SUPPORTED_SYTE_PRODUCTS: SyteProductType[] = [
  SyteProductType.AugmentedSearch,
  SyteProductType.SimilarItems,
  SyteProductType.ShopTheLook,
  SyteProductType.VisualSearch,
  SyteProductType.DiscoveryButton,
  SyteProductType.Personalization,
  SyteProductType.Collections,
];

export const RULE_WEIGHT_STEPS = [
  { maxValue: MAX_LOWEST, name: RuleWeightLevelEnum.Lowest },
  { maxValue: MAX_LOW, name: RuleWeightLevelEnum.Low },
  { maxValue: MAX_MODERATE, name: RuleWeightLevelEnum.Moderate },
  { maxValue: MAX_HIGH, name: RuleWeightLevelEnum.High },
  { maxValue: MAX_HIGHEST, name: RuleWeightLevelEnum.Highest },
];
