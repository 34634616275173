import React from 'react';

import { Typography } from 'src/components-dummy/Typography/Typography';
import { TypographyType, TypographyVariant } from 'src/components-dummy/Typography/types';
import styled from '@emotion/styled';

export const StyledNoTagsInfo = styled.div`
  position: absolute;
  z-index: 1;
  background-color: white;
  padding: 12px 20px;
  border-radius: 8px;
  width: fit-content;
  margin: auto;
  left: 0px;
  right: 0px;
  top: 24px;
  user-select: none;
`;

export const NoTagsInfo = (): JSX.Element => {
  return (
    <StyledNoTagsInfo>
      <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
        Tap the image to add product tags (SKU)
      </Typography>
    </StyledNoTagsInfo>
  );
};
