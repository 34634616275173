import { SimilarItemsSettingsTypes } from '../types';

export const buildSimilarItemsSettingsPayload = (
  payload: Partial<SimilarItemsSettingsTypes.SimilarItemsSettings>
): Partial<SimilarItemsSettingsTypes.SimilarItemsSettings> => {
  const payloadData = {
    numberOfResultsToDisplay: payload.numberOfResultsToDisplay,
    addToCart: payload.addToCart,
    enableTextSimilarity: payload.enableTextSimilarity,
  };

  return payloadData;
};
