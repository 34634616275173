import React, { useCallback, useMemo, useState } from 'react';
import { Dispatch } from 'src/components-bl/types';
import {
  Accordion,
  AvailableIcons,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { SyteProductPlacement } from 'src/services/src/service/types/syte-products';
import { ExpandableSectionTrigger } from '../../../ExpandableSectionTrigger';
import {
  EditGallerySideBarStyled,
  SideBarHeader,
  StyledSkeleton,
  BackButtonStyled,
  AccordionStyled,
} from './EditGallerySideBar.styles';
import {
  GridLayoutSection,
  ImplementationSection,
  SettingsSection,
  TextSection,
} from './components';
import { CustomInspirationGalleryDraft } from '../../types';

function LoadingSkeleton() {
  return (
    <>
      <StyledSkeleton height={40} variant='rounded' />
      <StyledSkeleton height={40} variant='rounded' />
      <StyledSkeleton height={40} variant='rounded' />
    </>
  );
}

export enum ExpendableSection {
  Layout = 'layout',
  Settings = 'settings',
  Text = 'text',
  Implementation = 'implementation',
}

interface EditGallerySideBarProps {
  gallery: CustomInspirationGalleryDraft | undefined;
  galleryId: string;
  onChange: (partialDraft: Partial<CustomInspirationGalleryDraft>) => void;
  dispatch: Dispatch;
  navigateToList: () => void;
}

export const EditGallerySideBar = ({
  gallery,
  onChange,
  galleryId,
  dispatch,
  navigateToList,
}: EditGallerySideBarProps): JSX.Element => {
  const [expendedSection, setExpendedSection] = useState<ExpendableSection | null>(null);

  const isLoading = useMemo(() => !gallery, [gallery]);

  const isEditEnabled = useMemo(
    () => gallery?.images && gallery.images.length > 0,
    [gallery?.images]
  );

  const onExpendSelectionChange = useCallback(
    (sections: string[]): void => {
      const newSection = (sections?.[0] as ExpendableSection) || null;

      setExpendedSection(newSection);
    },
    [setExpendedSection]
  );

  const onDisplayNameChange = useCallback(
    (displayTitle: string): void => {
      onChange({ displayTitle });
    },
    [onChange]
  );

  const onPlacementChange = useCallback(
    (newPlacement: SyteProductPlacement): void => {
      onChange({ placement: newPlacement });
    },
    [onChange]
  );

  return (
    <EditGallerySideBarStyled>
      <SideBarHeader>
        <Typography variant={TypographyVariant.LargeBold} type={TypographyType.Body}>
          Shoppable Galleries
        </Typography>

        <BackButtonStyled
          variant='tertiary'
          startIcon={AvailableIcons.ChevronLeft}
          onClick={navigateToList}
        >
          Back
        </BackButtonStyled>
      </SideBarHeader>

      {isLoading ? (
        <LoadingSkeleton />
      ) : (
        <AccordionStyled
          onSelectionChanged={onExpendSelectionChange}
          expandedIds={expendedSection as string}
          disabled={!isEditEnabled}
        >
          <Accordion.Item id={ExpendableSection.Layout} observeHeight>
            <Accordion.Item.Header>
              <ExpandableSectionTrigger
                title='Gallery Layouts'
                isExpanded={expendedSection === ExpendableSection.Layout}
                isDisabled={!isEditEnabled}
              />
            </Accordion.Item.Header>
            <Accordion.Item.Content>
              <GridLayoutSection gallery={gallery} onChange={onChange} />
            </Accordion.Item.Content>
          </Accordion.Item>

          <Accordion.Item id={ExpendableSection.Settings} observeHeight>
            <Accordion.Item.Header>
              <ExpandableSectionTrigger
                title='Gallery Settings'
                isExpanded={expendedSection === ExpendableSection.Settings}
                isDisabled={!isEditEnabled}
              />
            </Accordion.Item.Header>
            <Accordion.Item.Content>
              <SettingsSection gallery={gallery} onChange={onChange} />
            </Accordion.Item.Content>
          </Accordion.Item>

          <Accordion.Item id={ExpendableSection.Text} observeHeight>
            <Accordion.Item.Header>
              <ExpandableSectionTrigger
                title='Gallery Text'
                isExpanded={expendedSection === ExpendableSection.Text}
                isDisabled={!isEditEnabled}
              />
            </Accordion.Item.Header>
            <Accordion.Item.Content>
              <TextSection value={gallery?.displayTitle} onChange={onDisplayNameChange} />
            </Accordion.Item.Content>
          </Accordion.Item>

          <Accordion.Item id={ExpendableSection.Implementation} observeHeight>
            <Accordion.Item.Header>
              <ExpandableSectionTrigger
                title='Gallery Implementation'
                isExpanded={expendedSection === ExpendableSection.Implementation}
                isDisabled={!isEditEnabled}
              />
            </Accordion.Item.Header>
            <Accordion.Item.Content>
              <ImplementationSection
                galleryId={galleryId}
                onPlacementChange={onPlacementChange}
                galleryTitle={gallery?.displayTitle}
                placement={gallery?.placement}
                dispatch={dispatch}
              />
            </Accordion.Item.Content>
          </Accordion.Item>
        </AccordionStyled>
      )}
    </EditGallerySideBarStyled>
  );
};
