import React, { useCallback, useState } from 'react';
import { LexiconTagField } from 'src/services';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy/Typography';
import { Box, Checkbox, CircularProgress, CircularProgressSizes } from 'src/components-dummy';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { RenameTagConfirmationFooter } from './RenameTagConfirmationDialog.styled';

export interface RenameTagConfirmationDialogResult {
  tagFieldValue: string;
  tagFieldName: LexiconTagField;
  shouldShowApplyAllDialogOnEdit: boolean;
}
export interface RenameBulkConfirmationDialogProps {
  onClose: () => void;
  onConfirmBulkRename: (args: RenameTagConfirmationDialogResult) => void;
  onRejectBulkRename: (args: RenameTagConfirmationDialogResult) => void;
  tagFieldNewValue: string;
  tagFieldName: LexiconTagField;
  tagFieldOriginalValue: string;
  changesCount: number | undefined;
}

export const RenameConfirmationDialog = ({
  tagFieldName,
  tagFieldNewValue,
  tagFieldOriginalValue,
  changesCount,
  onConfirmBulkRename,
  onRejectBulkRename,
  onClose,
}: RenameBulkConfirmationDialogProps): JSX.Element => {
  const [shouldShowDialogOnEdit, setShouldShowDialogOnEdit] = useState(true);

  const isLoadingTotalCount = changesCount === undefined;

  const onConfirmClick = useCallback(() => {
    onConfirmBulkRename({
      tagFieldName,
      tagFieldValue: tagFieldNewValue,
      shouldShowApplyAllDialogOnEdit: shouldShowDialogOnEdit,
    });
  }, [onConfirmBulkRename, shouldShowDialogOnEdit]);

  const onRejectClick = useCallback(() => {
    onRejectBulkRename({
      tagFieldValue: tagFieldNewValue,
      tagFieldName,
      shouldShowApplyAllDialogOnEdit: shouldShowDialogOnEdit,
    });
  }, [onRejectBulkRename, shouldShowDialogOnEdit]);

  const onDontShowDialogAgainChecked = useCallback(
    (checked: boolean) => {
      setShouldShowDialogOnEdit(!checked);
    },
    [setShouldShowDialogOnEdit]
  );

  return (
    <ConfirmationDialog onCancel={onClose}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>
          Rename all &quot;{tagFieldOriginalValue}&quot;
        </ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content css={{ paddingBottom: '24px', gap: '15px' }}>
        <Box sx={{ height: '1em' }}>
          {isLoadingTotalCount ? (
            <CircularProgress
              size={CircularProgressSizes.Small}
              text='Calculating affected tags...'
              layout='horizontal'
            />
          ) : (
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
              You can apply and replace all{' '}
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
                {changesCount}{' '}
              </Typography>
              existing &quot;
              {tagFieldOriginalValue}&quot;{' '}
              {tagFieldName === 'category' ? `categories` : `${tagFieldName}s`}
            </Typography>
          )}
        </Box>

        <br />
        <br />
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
          {tagFieldOriginalValue} {' -> '} {tagFieldNewValue}
        </Typography>
        <br />
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
            Note:{' '}
          </Typography>
          The changes are applied to this tab only. Please update values in the other two tabs if
          necessary.
        </Typography>
        <br />
        <Checkbox
          checked={!shouldShowDialogOnEdit}
          onChange={onDontShowDialogAgainChecked}
          label='Don’t ask me again'
        />
      </ConfirmationDialog.Content>
      <RenameTagConfirmationFooter>
        <Button variant='tertiary' onClick={onClose}>
          Cancel
        </Button>
        <ConfirmationDialog.ButtonsContainer>
          <Button variant='secondary' onClick={onRejectClick}>
            Rename One Tag
          </Button>
          <Button variant='primary' onClick={onConfirmClick} disabled={isLoadingTotalCount}>
            Rename All
          </Button>
        </ConfirmationDialog.ButtonsContainer>
      </RenameTagConfirmationFooter>
    </ConfirmationDialog>
  );
};
