import { createAsyncThunk } from '@reduxjs/toolkit';
import { CatalogManagerService } from 'src/services';

const catalogManagerService = new CatalogManagerService();

export const textSimilarityToggleActions = {
  getTextSimilarity: createAsyncThunk(
    'TextSimilarityToggle/GetTextSimilarity',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const { isTextSimilarityAvailable } = await catalogManagerService.getTextSimilarity({
          shopId,
        });
        return isTextSimilarityAvailable;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
