import React from 'react';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { TextBoxStyled, TextSectionHeader, TextSectionStyled } from './TextSection.styles';

interface TextSectionProps {
  value: string | undefined;
  onChange: (newTitle: string) => void;
}

export const TextSection = ({ value, onChange }: TextSectionProps): JSX.Element => {
  return (
    <TextSectionStyled>
      <TextSectionHeader>
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
          Heading
        </Typography>
      </TextSectionHeader>
      <TextBoxStyled
        label='Title'
        value={value || ''}
        onChange={onChange}
        placeholder='My Gallery'
      />
    </TextSectionStyled>
  );
};
