import React from 'react';

export const PageNotFoundContainer = (): JSX.Element => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          textAlign: 'center',
        }}
      >
        <h1 style={{ fontSize: '24px' }}>
          Page Not Found<small style={{ display: 'block' }}>or</small>You have no access permission
        </h1>
      </div>
    </>
  );
};
