import React, { useCallback, useState } from 'react';
import {
  AvailableIcons,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { startCase } from 'lodash';
import {
  ProductCardStyled,
  ImageStyled,
  ImageWrapperStyled,
  BottomRowStyled,
  OriginalPriceTypography,
  PriceTypography,
  FirstRowStyled,
  SkuTypography,
  TagIconStyled,
  ErrorWrapper,
  DeleteTagIconStyled,
  SpinnerStyled,
  ImageSkeleton,
} from './ProductCard.styles';

export interface SkuConfiguration {
  imageUrl: string;
  title: string;
  brand: string;
  sku: string;
  parent_sku: string;
  gender: string;
  categories: string;
  currency: string;
  originalPrice: string;
  price: string;
  adUrl: string;
}

interface ProductCardProps {
  skuConfiguration: SkuConfiguration | undefined;
  loading: boolean;
  isAIDetected: boolean;
  isApplied: boolean;
  enableDelete: boolean;
  onRemove?: () => void;
  isError: boolean;
}

function formatPrice({ price, currency }: Pick<SkuConfiguration, 'price' | 'currency'>) {
  return `${currency}${price}`;
}

export const ProductCard = ({
  skuConfiguration,
  loading,
  isAIDetected,
  isApplied,
  enableDelete,
  onRemove,
  isError,
}: ProductCardProps): JSX.Element => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const onImageLoad = useCallback(() => {
    setIsImageLoaded(true);
  }, []);

  let content = <></>;

  if (loading) {
    content = <SpinnerStyled />;
  } else if (isError) {
    content = (
      <ErrorWrapper>
        <Icon name={AvailableIcons.CircleWarning} />
        <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
          Product not found
        </Typography>
        {enableDelete && <DeleteTagIconStyled name={AvailableIcons.TrashCan} onClick={onRemove} />}
      </ErrorWrapper>
    );
  } else if (skuConfiguration) {
    content = (
      <>
        <ImageWrapperStyled>
          {isImageLoaded && isAIDetected && (
            <TagIconStyled
              name={AvailableIcons.Stars}
              isApplied={isApplied}
              isAIDetected={isAIDetected}
              alignRight={false}
              targetSvgAttribute='fill'
            />
          )}

          {isImageLoaded && enableDelete && (
            <TagIconStyled
              name={AvailableIcons.TrashCan}
              onClick={onRemove}
              isApplied={isApplied}
              isAIDetected={isAIDetected}
              alignRight
              targetSvgAttribute='stroke'
            />
          )}
          <ImageStyled
            src={skuConfiguration.imageUrl}
            onLoad={onImageLoad}
            onError={onImageLoad}
            opacity={isImageLoaded ? 1 : 0}
          />

          {!isImageLoaded && <ImageSkeleton height='200px' width='200px' />}
        </ImageWrapperStyled>

        <a href={skuConfiguration.adUrl} target='_blank' rel='noopener noreferrer'>
          <Typography type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
            {skuConfiguration.title}
          </Typography>
        </a>

        <FirstRowStyled>
          <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
            {skuConfiguration.brand}
          </Typography>

          <SkuTypography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
            {skuConfiguration.sku}
          </SkuTypography>
        </FirstRowStyled>

        <BottomRowStyled>
          <PriceTypography type={TypographyType.Body} variant={TypographyVariant.ExtraSmallRegular}>
            {formatPrice({ price: skuConfiguration.price, currency: skuConfiguration.currency })}{' '}
          </PriceTypography>

          {skuConfiguration.originalPrice && (
            <OriginalPriceTypography
              type={TypographyType.Body}
              variant={TypographyVariant.ExtraSmallRegular}
            >
              {formatPrice({
                price: skuConfiguration.originalPrice,
                currency: skuConfiguration.currency,
              })}
            </OriginalPriceTypography>
          )}

          <Typography type={TypographyType.Body} variant={TypographyVariant.ExtraSmallRegular}>
            {' '}
            | {startCase(skuConfiguration.gender)}
            {skuConfiguration.categories[0] && ` | ${startCase(skuConfiguration.categories[0])}`}
          </Typography>
        </BottomRowStyled>
      </>
    );
  }
  return (
    <ProductCardStyled isAIDetected={isAIDetected} isApplied={isApplied} isError={isError}>
      {content}
    </ProductCardStyled>
  );
};
