import React from 'react';
import styled from '@emotion/styled';
import { Switch, SwitchProps } from '../Switch';
import { Typography, TypographyType, TypographyVariant } from '../Typography';

interface Props extends SwitchProps {
  titleTemplateClassName?: string;
  label: string;
}

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export function FormSwitch({
  label,
  disabled,
  checked,
  onChange,
  className,
  titleTemplateClassName,
}: Props) {
  return (
    <SwitchContainer>
      <Switch disabled={disabled} checked={checked} onChange={onChange} className={className}>
        <Switch.TitleTemplate className={titleTemplateClassName}>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            {label}
          </Typography>
        </Switch.TitleTemplate>
      </Switch>
    </SwitchContainer>
  );
}
