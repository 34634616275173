import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import {
  Button,
  FormCard,
  Switch,
  SwitchSizes,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { useValidateSchema } from 'src/hooks';
import {
  Account,
  TwoFactorAuthenticationConfig,
} from 'src/services/src/service/types/accounts/account';
import { Dispatch } from '../types';
import { formValidationSchema } from './constants';
import { account2FAActions } from './account-2fa.actions';
import { Account2FAFormStyled, ToggleWrapperStyled } from './Account2FAForm.styles';

export interface Account2FaFormProps {
  account: Account;
  dispatch: Dispatch;
}

export function Account2FAForm({ account, dispatch }: Account2FaFormProps): JSX.Element {
  const [twoFactorAuthenticationDraft, setTwoFactorAuthenticationDraft] =
    useState<TwoFactorAuthenticationConfig>(account.twoFactorAuthentication);

  const isDirty = useMemo(
    () => !isEqual(account.twoFactorAuthentication, twoFactorAuthenticationDraft),
    [account.twoFactorAuthentication, twoFactorAuthenticationDraft]
  );

  const { validate, isValid } = useValidateSchema<TwoFactorAuthenticationConfig>({
    schema: formValidationSchema,
  });

  useEffect(() => {
    dispatch(account2FAActions.notifyIsDirty({ isDirty }));

    return () => {
      dispatch(account2FAActions.notifyIsDirty({ isDirty: false }));
    };
  }, [isDirty]);

  useEffect(() => {
    const { twoFactorAuthentication } = account;
    setTwoFactorAuthenticationDraft({ ...twoFactorAuthentication });
    validate({ dataToValidate: twoFactorAuthentication });
  }, [account, setTwoFactorAuthenticationDraft]);

  const onSave = useCallback(() => {
    if (!isValid || !twoFactorAuthenticationDraft) {
      return;
    }

    dispatch(
      account2FAActions.updateAccount2Fa({
        accountId: account.accountId,
        twoFactorAuthentication: twoFactorAuthenticationDraft,
      })
    );
  }, [account, twoFactorAuthenticationDraft, isValid, dispatch]);

  const onEnableToggle = useCallback(() => {
    setTwoFactorAuthenticationDraft({
      ...twoFactorAuthenticationDraft,
      enabled: !twoFactorAuthenticationDraft.enabled,
    });
  }, [twoFactorAuthenticationDraft]);

  return (
    <Account2FAFormStyled>
      <FormCard>
        <FormCard.Title>2 Step Verification</FormCard.Title>
        <FormCard.Button>
          <Button onClick={onSave} variant='primary' disabled={!(isValid && isDirty)}>
            Save changes
          </Button>
        </FormCard.Button>
        <FormCard.Content>
          <ToggleWrapperStyled>
            <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Body}>
              Enable 2 Step Verification
            </Typography>
            <Switch
              size={SwitchSizes.Large}
              onChange={onEnableToggle}
              checked={twoFactorAuthenticationDraft.enabled}
            />
          </ToggleWrapperStyled>
        </FormCard.Content>
      </FormCard>
    </Account2FAFormStyled>
  );
}
