import { isNumber } from 'lodash';

export const getStringEnumValues = <T extends string = string>(enumObject: {
  [fieldKey: string]: T;
}): T[] => {
  return Object.values(enumObject) as T[];
};

export const getNumericEnumValues = <T>(enumObject: { [fieldKey: string]: T }): T[] => {
  return Object.values(enumObject).filter(value => isNumber(value)) as T[];
};
