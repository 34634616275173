import { useEffect } from 'react';
import { Dispatch } from '../types';
import { storiesSettingsActionMethods } from './Actions/storiesSettingsActionMethods';
import { IStoriesService } from '../../services/src/service/stories/stories-service';

interface Props {
  service: IStoriesService;
  dispatch: Dispatch;
}

export const useGetStoriesSettings = ({ service, dispatch }: Props) => {
  useEffect(() => {
    dispatch(storiesSettingsActionMethods.getStoriesSettings(service));
  }, [service]);
};
