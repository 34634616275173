import { useCallback } from 'react';
import { FieldType } from 'src/services';
import { dataFieldHasTypes } from 'src/utils/data-field-has-types';
import { useAppSelector } from '../../../../../hooks';
import { useMappedSelectValues } from '../../../../hooks/useMappedSelectValues';
import { UseFieldDropdownProps, UseFieldDropdownReturnType } from './types';

export const useFieldDropdown = ({
  data,
  onChange,
}: UseFieldDropdownProps): UseFieldDropdownReturnType => {
  const { dataFields } = useAppSelector(state => state.dataFields);

  const dataFieldsDropdownOptions = useMappedSelectValues(
    dataFields?.filter(
      dataField => dataField.fieldType === FieldType.CatalogField && dataFieldHasTypes(dataField)
    ) || []
  );

  const onFieldChange = useCallback(
    (fieldName?: string) => {
      onChange({ ...data, field: dataFields?.find(f => f.name === fieldName)?.name });
    },
    [dataFields, onChange]
  );

  return {
    dataFieldsDropdownOptions,
    onFieldChange,
  };
};
