import { css } from '@emotion/react';
import styled from '@emotion/styled';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';
import { SwitchSizes, SwitchProportionsBySize } from './Switch.config';

export const SwitchStyled = styled(SwitchUnstyled)<{
  checked?: boolean;
  disabled?: boolean;
  size: SwitchSizes;
}>`
  display: flex;
  position: relative;
  font-size: 0;
  align-items: center;
  width: ${({ size }) => `${SwitchProportionsBySize[size].width}px`};
  height: ${({ size }) => `${SwitchProportionsBySize[size].height}px`};
  cursor: 'pointer';
  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `};

  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;

    &.${switchUnstyledClasses.checked} {
      .${switchUnstyledClasses.track} {
        background-color: ${({ theme }) => theme.palette.custom['primary-80']};
      }
    }
  }

  & .${switchUnstyledClasses.track} {
    background-color: ${({ theme }) => theme.palette.custom['gray-30']};
    border-radius: 100px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchUnstyledClasses.thumb} {
    display: block;
    position: absolute;
    margin: 3px;
    width: ${({ size }) => `${SwitchProportionsBySize[size].thumbSize}px`};
    height: ${({ size }) => `${SwitchProportionsBySize[size].thumbSize}px`};
    border-radius: 100px;
    background-color: ${({ theme }) => theme.palette.custom.white};
  }

  &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
    background-color: ${({ theme }) => theme.palette.custom.white};
  }

  &:hover {
    &.${switchUnstyledClasses.checked} {
      &:not(.${switchUnstyledClasses.disabled}) {
        .${switchUnstyledClasses.track} {
          background-color: ${({ theme }) => theme.palette.custom['primary-dark']};
        }
        .${switchUnstyledClasses.thumb} {
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      right: 0;
      background-color: ${({ theme }) => theme.palette.custom.white};
    }

    .${switchUnstyledClasses.track} {
      background-color: ${({ theme }) => theme.palette.custom['primary-main']};
    }
  }

  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;

export const SwitchContainerStyled = styled.div`
  display: flex;
  justify-content: left;
  align-content: center;
  align-items: center;
  flex-direction: row;

  & > div {
    position: relative;
  }
`;

export const TitleTemplateStyled = styled.span`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const SubTitleTemplateStyled = styled.span`
  color: ${({ theme }) => theme.palette.custom['gray-20']};
  display: block;
`;

export const SwitchLabelStyled = styled.div<{ disabled: boolean }>`
  width: auto;
  margin-left: 12px;
  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `};
`;

export const SwitchStatusStyled = styled.span<{ size: SwitchSizes }>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.custom.white};
  line-height: ${({ size }) => `${SwitchProportionsBySize[size].height}px`};
  margin: 0 11px;

  & > span {
    position: absolute;
  }
  & > :nth-of-type(0) {
    left: 0;
  }
  & > :nth-of-type(1) {
    right: 0;
  }
`;
