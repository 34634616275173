import React from 'react';
import { StyledSkeleton } from './GalleryTableSkeleton.styles';

export const GalleryTableSkeleton = (): JSX.Element => (
  <>
    <StyledSkeleton height={60} variant='rounded' />
    <StyledSkeleton height={60} variant='rounded' />
    <StyledSkeleton height={60} variant='rounded' />
  </>
);
