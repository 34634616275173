import {
  CollectionSettingsAction,
  CollectionSettingsActionTypes,
  CollectionSettingsErrorActionPayload,
  SaveSettingsActionPayload,
  SaveSettingsSuccessActionPayload,
} from './types';
import { Dispatch } from '../../../../types';
import { collectionsService } from '../../../../../services/src/service/collections';

const collectionSettingsActions = {
  saveRequest(payload: SaveSettingsActionPayload): CollectionSettingsAction {
    return {
      type: CollectionSettingsActionTypes.SaveRequest,
      payload,
    };
  },

  saveSuccess(payload: SaveSettingsSuccessActionPayload): CollectionSettingsAction {
    return {
      type: CollectionSettingsActionTypes.SaveSuccess,
      payload,
    };
  },

  saveError(payload: CollectionSettingsErrorActionPayload): CollectionSettingsAction {
    return {
      type: CollectionSettingsActionTypes.SaveError,
      payload,
    };
  },
};

export const collectionSettingsMethods = {
  save(payload: SaveSettingsActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(collectionSettingsActions.saveRequest(payload));
        const { name, placement, uniqueByField } = await collectionsService.updateCollection({
          shopId: payload.shopId,
          collectionId: payload.collectionId,
          body: {
            name: payload.name,
            placement: payload.placement,
            uniqueByField: payload.uniqueByField,
          },
          variantId: payload?.variantId,
        });
        dispatch(collectionSettingsActions.saveSuccess({ name, placement, uniqueByField }));
      } catch (error) {
        dispatch(collectionSettingsActions.saveError({ error }));
      }
    };
  },
};
