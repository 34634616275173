import React from 'react';
import { IndexedOffers } from '../../../types';
import { ListLayoutContainer } from './ListLayout.styles';
import { VisualEditorTable } from '../VisualEditorTable';
import { DataFieldToDisplay } from '../../../types/data-field-to-display';
import { SpecialDataFieldToDisplay } from '../../../types/special-data-field-to-display';

export function ListLayout({
  offers,
  dataFieldsToDisplay,
  specialDataFieldsToDisplay,
  openProductDetailsModal,
}: {
  offers: IndexedOffers;
  dataFieldsToDisplay: DataFieldToDisplay[];
  specialDataFieldsToDisplay: SpecialDataFieldToDisplay[];
  openProductDetailsModal: (sku?: string) => void;
}): JSX.Element {
  return (
    <ListLayoutContainer>
      <VisualEditorTable
        offers={offers}
        dataFieldsToDisplay={dataFieldsToDisplay}
        specialDataFieldsToDisplay={specialDataFieldsToDisplay}
        openProductDetailsModal={openProductDetailsModal}
      />
    </ListLayoutContainer>
  );
}
