import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Select } from 'src/components-dummy';

const dropdownStyle = `
color: white !important;

.syte-basic-icon.select-caret {
  right: 6px;
  stroke: #fff !important;
}
& > div {
  padding: 0px 26px 0px 16px !important;
  height: 24px !important;
`;

export const DeepTagProductStatusSelectStyled = styled(Select)<{ color: string }>`
  .MuiInputBase-root {
    width: auto !important;
  }
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary {
    background: ${props => props.color} !important;
    ${dropdownStyle}
  }
  .Mui-focused.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary {
    background: ${props => props.color};
    ${dropdownStyle}
  }

  .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
    font-family: Inter !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
  }
`;

/**
 * DeepTagProductStatusWithButton
 */

export const DeepTagProductStatusWithButtonStyled = styled.div<{
  color: string;
  isDisabled: boolean;
}>`
  display: flex;
  flex-direction: row;

  button {
    background: ${({ color }) => color};
    border-radius: 5px 0 0 5px;

    &:hover {
      background: ${({ color }) => color};
    }
  }

  & > div:nth-of-type(1) {
    .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-disabled,
    .MuiSelect-select.MuiSelect-outlined.Mui-disabled.MuiInputBase-input.MuiOutlinedInput-input {
      background: #e0e0e0 !important;
    }

    .MuiSelect-select.MuiSelect-outlined.Mui-disabled.MuiInputBase-input.MuiOutlinedInput-input {
      padding: 0 !important;
    }

    .MuiInputBase-root {
      height: 100%;
      width: 28px !important;
      border-radius: 0 5px 5px 0;

      .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary {
        ${({ isDisabled }) =>
          isDisabled &&
          css`
            background: #e0e0e000;
          `};
      }
    }

    .MuiSelect-select {
      position: relative;
      padding: 0 !important;

      &::before {
        content: '';
        display: inline-block;
        height: 14px;
        width: 1px;
        position: absolute;
        left: 0;
        background: white;
      }
    }
  }
`;
