import {
  GetBoughtTogetherSettingsArgs,
  UpdateBoughtTogetherSettingsPayload,
} from 'src/components-bl/BoughtTogether/Actions/types';
import * as mapper from './mapper';
import * as builder from './builder';
import { BoughtTogetherSettingsTypes } from '../types';
import { ApiServiceBase } from '../api-service-base';

export class BoughtTogetherGeneralSettings extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl({ shopId, variantId }: GetBoughtTogetherSettingsArgs): string {
    return `${this.serviceBaseUri}/${shopId}/syte-products/bought-together/general-settings${
      variantId ? `?variantId=${variantId}` : ''
    }`;
  }

  async get({
    shopId,
    variantId,
  }: GetBoughtTogetherSettingsArgs): Promise<BoughtTogetherSettingsTypes.BoughtTogetherSettings> {
    const url = this.getUrl({ shopId, variantId });
    const response = await this.httpService.get({ url });
    return mapper.boughtTogetherSettingsResponse(response);
  }

  async update({
    shopId,
    variantId,
    ...payload
  }: UpdateBoughtTogetherSettingsPayload): Promise<BoughtTogetherSettingsTypes.BoughtTogetherSettings> {
    const url = this.getUrl({ shopId, variantId });

    const data = builder.buildBoughtTogetherSettingsPayload(payload);
    const response = await this.httpService.patch({ url, data });
    return mapper.boughtTogetherSettingsResponse(response);
  }
}
