import { UserTypes } from '../../services';
import { ShopSelectorProps } from '../ShopSelector';
import { SideNavArguments, ISideNavItem } from '../../components-dummy';

export interface RoleBasedSideNavProps
  extends Omit<
      SideNavArguments,
      'isMinimized' | 'setMinimized' | 'showShopSearch' | 'routes' | 'isLoading'
    >,
    ShopSelectorProps {
  role: UserTypes.UserRoles;
  accountRoutes: ISideNavItem[];
  shopRoutes: ISideNavItem[];
  location: LocationBase;
  isShopLoading?: boolean;
}

export enum SideNavNames {
  Account = 'account',
  Shop = 'shop',
}

export interface LocationBase {
  pathname: string;
}
