import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  Select,
  PrimarySelectProps,
  SelectType,
  MenuSelectProps,
  MenuItem,
} from 'src/components-dummy';

export const DataFieldsConditionStyled = styled.div`
  display: flex;
  width: 83%;

  .syte-popup-trigger {
    flex-grow: 1;
    flex-basis: 0;
    overflow: hidden;
  }
`;

export const DataFieldConditionSelectStyled = styled((props: PrimarySelectProps) => (
  <Select {...props} type={SelectType.Menu} />
))`
  margin: 0 4px;
  flex-shrink: 0.5;
`;

export const DataFieldSelectStyled = styled((props: MenuSelectProps) => (
  <Select {...props} type={SelectType.Menu} />
))`
  max-width: 30%;
  margin: 0 2px;
`;

export const AiTagMenuItemStyled = styled(MenuItem)<{ isDisabled: boolean }>`
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.38;
      cursor: unset;
    `}

  .syte-popup-trigger {
    width: 100%;
  }
`;
