import { Dispatch } from '../../types';

export enum RoleBasedSideNavActionTypes {
  NavigateTo = 'RoleBasedSideNav/NavigateTo',
}

export interface RoleBasedSideNavNavigateToActionPayload {
  navigateTo: string;
}

export interface RoleBasedSideNavNavigateToAction {
  type: typeof RoleBasedSideNavActionTypes.NavigateTo;
  payload: RoleBasedSideNavNavigateToActionPayload;
}

export type RoleBasedSideNavAction = RoleBasedSideNavNavigateToAction;

const roleBasedSideNavActions = {
  navigateTo(payload: RoleBasedSideNavNavigateToActionPayload): RoleBasedSideNavNavigateToAction {
    return {
      type: RoleBasedSideNavActionTypes.NavigateTo,
      payload,
    };
  },
};

export const roleBasedSideNavActionMethods = {
  navigateTo(payload: RoleBasedSideNavNavigateToActionPayload) {
    return (dispatch: Dispatch): void => {
      dispatch(roleBasedSideNavActions.navigateTo(payload));
    };
  },
};
