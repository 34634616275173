/* eslint-disable class-methods-use-this */
import { AxiosResponse } from 'axios';
import { IShopSftpSettings } from '../types';

export class ShopSFTPSettingsMapper {
  static mapSFTPSettings({ data }: AxiosResponse): IShopSftpSettings {
    const castedSettings = data as IShopSftpSettings;
    return {
      userName: castedSettings.userName,
      password: atob(castedSettings.password),
    };
  }
}
