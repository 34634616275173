import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'src/hooks';
import { ShopDeepTagReportsSettings } from 'src/components-bl/ShopDeepTagReportsSettings';
import { IShopGeneratedDataSettings } from 'src/services';
import { deepTagReportsSettingsContainerActions } from './DeepTagReportsSettings.container.actions';

export const DeepTagReportsSettingsContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const { current: currentShop } = useAppSelector(state => state.shop);
  const generatedDataSettings = useAppSelector(state => state.deepTagReports.generatedDataSettings);

  useEffect(() => {
    if (currentShop) {
      dispatch(
        deepTagReportsSettingsContainerActions.getGeneratedDataSettings({
          shopId: currentShop.shopId,
        })
      );
    }
  }, [currentShop?.shopId]);

  const onUpdateState = useCallback(
    async (data: IShopGeneratedDataSettings): Promise<any> => {
      if (currentShop?.shopId) {
        const response = await dispatch(
          deepTagReportsSettingsContainerActions.updateGeneratedDataSettings({
            shopId: currentShop?.shopId,
            shopGeneratedDataSettings: data,
          })
        );
        return response;
      }
      return undefined;
    },
    [currentShop?.shopId]
  );

  return (
    <>
      {currentShop && (
        <ShopDeepTagReportsSettings
          titleIsAutoGenerate={generatedDataSettings?.title?.isAutoGenerate}
          descriptionIsAutoGenerate={generatedDataSettings?.description?.isAutoGenerate}
          isLoading={generatedDataSettings === undefined}
          shop={currentShop}
          onUpdate={onUpdateState}
        />
      )}
    </>
  );
};
