import React, { useMemo } from 'react';
import {
  DropDownOption,
  TooltipedFormField,
  Select,
  SelectType,
  SelectOnChangeEvent,
  MenuItem,
} from 'src/components-dummy';
import { OrderMethod } from 'src/services/src/service/types/filters';

export interface SelectFiltersOrderMethodProps {
  orderMethod: OrderMethod | undefined;
  dispatcher: (newValue: OrderMethod) => void;
  listType?: 'catalog' | 'deeptags';
}

const orderMethodOptions: DropDownOption<OrderMethod>[] = [
  { text: 'Manual Position', value: OrderMethod.manual },
  { text: 'Results count per filter', value: OrderMethod.results_desc },
];

export const SelectFiltersOrderMethod = ({
  orderMethod,
  dispatcher,
  listType,
}: SelectFiltersOrderMethodProps): JSX.Element => {
  const additionalInfoMsg = (
    <>
      <p>Choosing the &quot;manual positions&quot; option,</p>
      <p>will allow you to &quot;drag & drop&quot; filters manually, to set their order.</p>
    </>
  );
  const label = useMemo(() => {
    if (listType) {
      return listType === 'catalog'
        ? 'Arrange catalog filters by'
        : 'Arrange Syte generated filters by';
    }
    return 'Arrange filters by';
  }, [listType]);

  const convertDispatcher: SelectOnChangeEvent = event =>
    dispatcher(event.target.value as OrderMethod);

  return (
    <TooltipedFormField
      label={label}
      additionalInfo={additionalInfoMsg}
      className='order-method-field'
    >
      <Select onChange={convertDispatcher} value={orderMethod} type={SelectType.Menu}>
        {orderMethodOptions.map((option: { value: string; text: string }) => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </TooltipedFormField>
  );
};
