import styled from '@emotion/styled';
import LinkMui from '@mui/material/Link';

export const LinkButtonStyled = styled(LinkMui)`
  display: flex;
  align-items: center;

  color: ${({ theme }) => {
    return theme.palette.custom['primary-main'];
  }};

  text-decoration: none;
  vertical-align: unset; /* override MUI internal css MuiTypography */

  :hover {
    text-decoration: underline;
  }

  svg {
    margin-right: 4px;

    path {
      stroke: ${({ theme }) => {
        return theme.palette.custom['primary-main'];
      }};
    }
  }
` as typeof LinkMui;
