import styled from '@emotion/styled';
import { TextBox } from 'src/components-dummy';

export const TextSectionStyled = styled.div`
  margin-top: 4px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-80']};
  background: ${({ theme }) => theme.palette.custom['gray-120']};
`;

export const TextSectionHeader = styled.div`
  padding: 16px;
  background: ${({ theme }) => theme.palette.custom['primary-light-transparent']};
`;

export const TextBoxStyled = styled(TextBox)`
  padding: 16px;
`;
