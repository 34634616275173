import React from 'react';
import { NumericInput } from '../../NumericInput';
import { EditableNumericInputCellProps } from '../types';
import { useEditableCell } from '../hooks';

const COMPONENT_NAME = 'EditableNumericInputCell';

export const EditableNumericInputCell = <T extends Record<string, unknown>>({
  value,
  row: { index },
  column: { id, cellProps },
  updateData,
  placeholder,
  decimals,
  maxNumericValue,
  ...rest
}: EditableNumericInputCellProps<T>): JSX.Element => {
  const { currentValue, onChange } = useEditableCell<number | undefined>({
    rowIndex: index,
    columnId: id as string,
    value,
    updateData,
  });
  const dataId =
    cellProps && cellProps.prefixId ? `${cellProps.prefixId}_${COMPONENT_NAME}_index_${index}` : '';

  return (
    <NumericInput
      className='syte-table-editable-cell'
      placeholder={placeholder}
      value={currentValue}
      onChange={onChange}
      decimals={decimals}
      maxNumericValue={maxNumericValue}
      {...rest}
      prefixId={dataId}
    />
  );
};
