import styled from '@emotion/styled';

export const LexiconListLayoutStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
  width: fit-content;
`;

export const cardColors = [
  '#F58F29',
  '#B1EDE8',
  '#23D160',
  '#5438DC',
  '#F3B61F',
  '#E84855',
  '#2C80FF',
];
