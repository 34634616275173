import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { lexiconService } from 'src/services';

export const useLexiconWSActions = {
  setImportExportProgress: createAction<{ progress: number }>(
    'UseLexiconWS/SetImportExportProgress'
  ),
  getLexiconMetadata: createAsyncThunk(
    'UseLexiconWS/GetShopLexiconMetadata',
    async ({ shopId, locale }: { shopId: number; locale: string }, { rejectWithValue }) => {
      try {
        const lexiconMetadata = await lexiconService.getLexiconMetadata({
          shopId,
          locale,
        });

        return lexiconMetadata;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
