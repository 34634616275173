import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { lexiconService } from 'src/services';

export const thematicTagFormActions = {
  createThematicTag: createAsyncThunk(
    'ThematicTagForm/CreateThematicTag',
    async (
      parameters: Parameters<typeof lexiconService.createOneThematicTag>[0],
      { rejectWithValue }
    ) => {
      try {
        const res = await lexiconService.createOneThematicTag(parameters);
        return res;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateThematicTag: createAsyncThunk(
    'ThematicTagForm/UpdateThematicTag',
    async (
      parameters: Parameters<typeof lexiconService.updateOneThematicTag>[0],
      { rejectWithValue }
    ) => {
      try {
        const res = await lexiconService.updateOneThematicTag(parameters);
        return res;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  // for the first row
  getAvailableTranslations: createAsyncThunk(
    'ThematicTagForm/GetAvailableTranslations',
    async (
      parameters: Parameters<typeof lexiconService.getAvailableTranslations>[0],
      { rejectWithValue }
    ) => {
      try {
        const res = await lexiconService.getAvailableTranslations(parameters);
        return res;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  // for the first row
  getAvailableKeys: createAsyncThunk(
    'ThematicTagForm/GetAvailableKeys',
    async (
      parameters: Parameters<typeof lexiconService.getAvailableKeys>[0],
      { rejectWithValue }
    ) => {
      try {
        const res = await lexiconService.getAvailableKeys(parameters);
        return res;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
  notifyIsDirty: createAction<{ isDirty: boolean }>('ThematicTagForm/NotifyIsDirty'),
};
