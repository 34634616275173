import styled from '@emotion/styled';
import { Button } from 'src/components-dummy';

export const DeleteAllButtonStyled = styled(Button)`
  color: ${({ theme }) => theme.palette.custom['primary-main']};
  display: flex;
  gap: 4px;
  margin-left: auto !important;

  svg {
    path {
      stroke: ${({ disabled, theme }) =>
        disabled ? theme.palette.custom['gray-disabled'] : theme.palette.custom['primary-main']};
    }
  }
`;
