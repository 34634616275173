import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const DeepTagReportHowToUploadStyled = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const TypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['primary-main']};
`;

export const TemplateLinkStyled = styled.a`
  color: ${({ theme }) => theme.palette.custom['primary-main']} !important;
`;
