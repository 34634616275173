import { EditableFilterItem, FilterStatusOption, FilterStatusPropertyName } from '../types';

type FormSubmitState = {
  shouldPerformSubmit: boolean;
  formData: EditableFilterItem[];
};
export function onFormSubmit(
  listDraftItems: EditableFilterItem[],
  allowDisableAll: boolean,
  isValid: () => boolean
): FormSubmitState {
  const haveNewlyCreatedItems = listDraftItems.some(
    item => item[FilterStatusPropertyName] === FilterStatusOption.New
  );
  const formData = haveNewlyCreatedItems
    ? listDraftItems.map(item => {
        if (item[FilterStatusPropertyName] === FilterStatusOption.New && item.dataSource) {
          return { ...item, key: item.dataSource };
        }
        return item;
      })
    : [...listDraftItems];
  const activeItemsCounter = formData.filter(item => item.enabled).length;
  return {
    shouldPerformSubmit: isValid() && (Boolean(activeItemsCounter) || allowDisableAll),
    formData,
  };
}
