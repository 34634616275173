import React, { useState, useEffect } from 'react';
import { Redirect, Route, generatePath } from 'react-router';
import { RoutedComponentProps, AppSwitch, AppRoute } from 'src/app-routes';
import { useAppSelector } from 'src/hooks';
import { MerchandisingRulesFiltersState } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesListPage/components';
import { MerchandisingRulesListContainer } from '../MerchandisingRulesListContainer';
import { CreateMerchandisingRuleContainer } from '../CreateMerchandisingRuleContainer';
import { EditMerchandisingRuleContainer } from '../EditMerchandisingRuleContainer';

export const initialFiltersState: MerchandisingRulesFiltersState = {
  searchTerm: {
    isApplied: true,
    value: undefined,
  },
};

export const MerchandisingRulesMainContainer = ({
  permittedRouteMap,
}: RoutedComponentProps): JSX.Element => {
  const shopId = useAppSelector(state => state.shop.current?.shopId);

  const [filtersState, setFiltersState] =
    useState<MerchandisingRulesFiltersState>(initialFiltersState);

  useEffect(() => {
    setFiltersState(initialFiltersState);
  }, [shopId]);

  return (
    <AppSwitch>
      <AppRoute
        route={permittedRouteMap.merchandisingRules}
        Component={MerchandisingRulesListContainer}
        componentProps={{ permittedRouteMap, setFiltersState, filtersState }}
        exact
      />
      <AppRoute
        route={permittedRouteMap.createMerchandisingRule}
        Component={CreateMerchandisingRuleContainer}
        componentProps={{ permittedRouteMap }}
        exact
      />
      <AppRoute
        route={permittedRouteMap.editMerchandisingRule}
        Component={EditMerchandisingRuleContainer}
        componentProps={{ permittedRouteMap }}
        exact
      />
      <Route
        render={() => (
          <Redirect
            to={generatePath(permittedRouteMap.merchandisingRules?.path as string, { shopId })}
          />
        )}
      />
    </AppSwitch>
  );
};
