import { generatePath } from 'react-router';
import { PermittedRouteMap } from '../../../app-routes';
import { useAppNavigation } from '../../../hooks/useAppNavigation';

interface Props {
  shopId?: number;
  permittedRouteMap: PermittedRouteMap;
}

export const usePreviewModal = ({ shopId, permittedRouteMap }: Props) => {
  const navigateTo = useAppNavigation();

  const openModal = () => {
    if (permittedRouteMap?.visualEditor && shopId) {
      navigateTo(generatePath(permittedRouteMap?.visualEditor.path, { shopId }));
    }
  };

  const closeModal = () => {
    if (permittedRouteMap?.shopperExperiences && shopId) {
      navigateTo(generatePath(permittedRouteMap?.shopperExperiences.path, { shopId }));
    }
  };

  return {
    openModal,
    closeModal,
  };
};
