import React from 'react';
import {
  EllipsisWithTooltip,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { ProductDataGroupStyled, ProductDataStyled, TitleStyled } from './ProductDetails.styled';

interface AITagsTabProps {
  aiTags: Record<string, string[]>;
}

export const AITagsTab = ({ aiTags }: AITagsTabProps) => {
  return (
    <ProductDataGroupStyled>
      {Object.entries(aiTags).map(([key, value]) => {
        const aiTagValue = value.join(', ');
        return (
          <ProductDataStyled key={key}>
            <TitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
              {key}
            </TitleStyled>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              <EllipsisWithTooltip tooltipText={aiTagValue}>{aiTagValue}</EllipsisWithTooltip>
            </Typography>
          </ProductDataStyled>
        );
      })}
    </ProductDataGroupStyled>
  );
};
