import React from 'react';
import { LinkProps as MuiLinkProps } from '@mui/material/Link';
import { LinkStyled } from './Link.styles';
import { Typography, TypographyProps, TypographyType, TypographyVariant } from '../Typography';

export type LinkProps = MuiLinkProps;

export const Link = (props: LinkProps): JSX.Element => {
  // target to open in new tab,  rel for security

  return <LinkStyled target='_blank' rel='noopener noreferrer' {...props} />;
};

export type LinkTextProps = TypographyProps;

export const LinkText = ({ children, ...rest }: LinkTextProps): JSX.Element => {
  return (
    <Typography type={TypographyType.Button} variant={TypographyVariant.MediumMedium} {...rest}>
      {children}
    </Typography>
  );
};

Link.Text = LinkText;
