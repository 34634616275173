import { SyteProductType } from 'src/services/src/service/types/syte-products/syte-products-types';
import { FieldType, ShopDataField } from 'src/services';
import { RankingWeightRecord } from '../RankingStrategyTable/types';

import { RankingType } from '../RankingStrategyType/types';

/* --= Action Types =-- */
export enum RankingStrategyActionTypes {
  SetSelectedSyteProduct = 'RankingStrategy/SetSelectedSyteProduct',
  GetWeightsRequest = 'RankingStrategy/GetWeightsRequest',
  GetWeightsSuccess = 'RankingStrategy/GetWeightsSuccess',
  GetWeightsError = 'RankingStrategy/GetWeightsError',
  UpdateRankingWeightsRequest = 'RankingStrategy/UpdateRankingWeightsRequest',
  UpdateRankingWeightsSuccess = 'RankingStrategy/UpdateRankingWeightsSuccess',
  UpdateRankingWeightsError = 'RankingStrategy/UpdateRankingWeightsError',
  NotifyIsDirty = 'RankingStrategy/NotifyIsDirty',
  GetDataFieldsRequest = 'RankingStrategy/GetDataFieldsRequest',
  GetDataFieldsSuccess = 'RankingStrategy/GetDataFieldsSuccess',
  GetDataFieldsError = 'RankingStrategy/GetDataFieldsError',
}
export interface UpdateWeightsPayload {
  shopId: number;
  variantId?: string;
  syteProduct: SyteProductType;
  weights: RankingWeightRecord[];
  entityId?: string;
  type?: RankingType;
}

export interface GetRankingStrategyArgs {
  shopId: number;
  variantId?: string;
  syteProduct: SyteProductType;
  entityId?: string;
}

export interface GetWeightsRequestPayload {
  shopId: number;
  syteProduct: SyteProductType;
}

export interface GetWeightsSuccessPayload {
  shopId: number;
  syteProduct: SyteProductType;
  weights: RankingWeightRecord[];
  type?: RankingType;
}

export interface GetWeightsErrorPayload {
  error: unknown;
}
export interface UpdateRankingWeightsRequestPayload {
  shopId: number;
  syteProduct: SyteProductType;
  weights: RankingWeightRecord[];
}

export interface UpdateRankingWeightsSuccessPayload {
  shopId: number;
  syteProduct: SyteProductType;
  weights: RankingWeightRecord[];
  type?: RankingType;
}

export interface UpdateRankingWeightsErrorPayload {
  error: unknown;
}

export interface NotifyIsDirtyActionPayload {
  isDirty: boolean;
}

export interface GetDataFieldsRequestActionPayload {
  shopId: number;
  fieldType: FieldType;
}

export interface GetDataFieldsSuccessActionPayload {
  shopId: number;
  dataFields: ShopDataField[];
}

export interface GetDataFieldsErrorActionPayload {
  error: unknown;
}

/* --= Action Interfaces =-- */
export interface SetSelectedSyteProductAction {
  type: typeof RankingStrategyActionTypes.SetSelectedSyteProduct;
  payload: SyteProductType;
}

export interface GetWeightsRequestAction {
  type: typeof RankingStrategyActionTypes.GetWeightsRequest;
  payload: GetWeightsRequestPayload;
}

export interface GetWeightsSuccessAction {
  type: typeof RankingStrategyActionTypes.GetWeightsSuccess;
  payload: GetWeightsSuccessPayload;
}

export interface GetWeightsErrorAction {
  type: typeof RankingStrategyActionTypes.GetWeightsError;
  payload: GetWeightsErrorPayload;
}

export interface UpdateRankingWeightsRequestAction {
  type: typeof RankingStrategyActionTypes.UpdateRankingWeightsRequest;
  payload: UpdateRankingWeightsRequestPayload;
}

export interface UpdateRankingWeightsSuccessAction {
  type: typeof RankingStrategyActionTypes.UpdateRankingWeightsSuccess;
  payload: UpdateRankingWeightsSuccessPayload;
}

export interface UpdateRankingWeightsErrorAction {
  type: typeof RankingStrategyActionTypes.UpdateRankingWeightsError;
  payload: UpdateRankingWeightsErrorPayload;
}

export interface NotifyIsDirtyAction {
  type: typeof RankingStrategyActionTypes.NotifyIsDirty;
  payload: NotifyIsDirtyActionPayload;
}

export interface GetDataFieldsRequestAction {
  type: typeof RankingStrategyActionTypes.GetDataFieldsRequest;
  payload: GetDataFieldsRequestActionPayload;
}

export interface GetDataFieldsSuccessAction {
  type: typeof RankingStrategyActionTypes.GetDataFieldsSuccess;
  payload: GetDataFieldsSuccessActionPayload;
}

export interface GetDataFieldsErrorAction {
  type: typeof RankingStrategyActionTypes.GetDataFieldsError;
  payload: GetDataFieldsErrorActionPayload;
}

export type RankingStrategyAction =
  | GetWeightsRequestAction
  | GetWeightsSuccessAction
  | GetWeightsErrorAction
  | SetSelectedSyteProductAction
  | UpdateRankingWeightsRequestAction
  | UpdateRankingWeightsSuccessAction
  | UpdateRankingWeightsErrorAction
  | NotifyIsDirtyAction
  | GetDataFieldsRequestAction
  | GetDataFieldsSuccessAction
  | GetDataFieldsErrorAction;
