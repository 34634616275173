import { createTheme, ThemeOptions, Theme as MuiTheme } from '@mui/material/styles';
import { colors } from './colors';

export type Colors = { [key in keyof typeof colors]: (typeof colors)[key] };
export type ColorName = keyof Colors;
export type ColorValue = Colors[keyof Colors];

/**
 * Override Palette - add custom (material ui has it's own palette structure)
 */
declare module '@mui/material/styles' {
  interface Palette {
    custom: Colors;
  }

  export type PaletteColorOptions = Colors;
}

declare module '@emotion/react' {
  // eslint-disable-next-line
  export interface Theme extends MuiTheme {}
}

/**
 * Default theme should be explored with UX/UI designer - to pass some of the custom colors to other sections:
 * (for example: grey / warning (red) etc.)
 * https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette
 */

/**
 * Create white theme colors
 */
export const whiteTheme: MuiTheme = createTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  components: {
    ButtonBase: {
      defaultProps: { disableRipple: true },
    },
    MuiButtonBase: {
      defaultProps: { disableRipple: true },
    },
  },
  palette: {
    mode: 'light',
    custom: colors,
    common: {
      black: '#1E1E1E',
      white: '#fff',
    },
  },
} as ThemeOptions);
