/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import { ResultsModalState } from '../app-state-types';

import { ResultsModalActions } from '../containers/ResultsModalSettings/components/Actions';

import { DEFAULT_SIZE_VARIANTS } from '../components-bl/AddToCartSettings/defaults/default-size-variants';
import { DEFAULT_COLOR_VARIANTS_WITH_DISPLAY_ALL_VARIANTS } from '../components-bl/AddToCartSettings/defaults/default-color-variants-with-display-all-variants';

const initialState: ResultsModalState = {
  generalSettings: {
    addToCart: {
      active: false,
      colorVariants: DEFAULT_COLOR_VARIANTS_WITH_DISPLAY_ALL_VARIANTS,
      sizeVariants: DEFAULT_SIZE_VARIANTS,
    },
    enableTextSimilarity: false,
  },
};

export const resultsModalReducer = createReducer(initialState, builder => {
  builder.addCase(ResultsModalActions.getResultsModalGeneralSettings.fulfilled, (state, action) => {
    state.generalSettings = action.payload.generalSettings;
  });
  builder.addCase(
    ResultsModalActions.updateResultsModalGeneralSettings.fulfilled,
    (state, action) => {
      state.generalSettings = action.payload.generalSettings;
    }
  );
});
