import classNames from 'classnames';
import React from 'react';
import './Header.scss';

export interface HeaderProps {
  children?: (false | JSX.Element)[] | JSX.Element | false;
  className?: string;
}

export const Header = ({ className, children }: HeaderProps): JSX.Element => {
  return <header className={classNames('syte-header', className)}>{children}</header>;
};
