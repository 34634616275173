import React from 'react';
import { Button, Typography, TypographyType, TypographyVariant } from 'src/components-dummy';

import { TemplateLinkStyled } from '../DeepTagReportHowToUpload.styles';
import {
  ModalStyled,
  ModalFooterStyled,
  ModalContentStyled,
  ModalTitleStyled,
  InfoRowStyled,
  InfoRowsNumberStyled,
} from './DeepTagReportHowToUploadModal.styles';

export interface DeepTagReportHowToUploadModalProps {
  onModalClose: () => void;
}

const exampleFilePath = `${process.env.PUBLIC_URL}/files/deep-tags-reports/upload_file_example.csv`;

export const DeepTagReportHowToUploadModal = ({
  onModalClose,
}: DeepTagReportHowToUploadModalProps): JSX.Element => {
  return (
    <ModalStyled isOpened onClickOutside={onModalClose}>
      <>
        <ModalTitleStyled>
          <Typography type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
            Start enriching your products
          </Typography>
        </ModalTitleStyled>
        <ModalContentStyled>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
            Tag & enrich your products using Visual AI simply by uploading a file, or automate the
            enrichment flow with SFTP connection.
          </Typography>
          <br />
          <br />
          <Typography
            css={{ textDecoration: 'underline' }}
            type={TypographyType.Body}
            variant={TypographyVariant.MediumMedium}
          >
            Using SFTP:
          </Typography>

          <br />
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
            You can place the catalog file automatically or manually in your Syte’s dedicated SFTP
            server.
          </Typography>
          <br />
          <br />
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
            Each new file will be automatically set to be enriched with AI Tagging.
          </Typography>
          <br />
          <br />
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
            To start using the SFTP method to upload your products:
          </Typography>
          <InfoRowsNumberStyled>
            <InfoRowStyled>
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                Use your preferred SFTP client and log in using the credentials listed below.
              </Typography>
            </InfoRowStyled>
            <InfoRowStyled>
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                Place a file under the /tagging/input folder.
              </Typography>
            </InfoRowStyled>
            <InfoRowStyled>
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                The file should be of type CSV (comma delimited) , UTF-8 format.{' '}
                <TemplateLinkStyled key='link' href={exampleFilePath}>
                  (See template)
                </TemplateLinkStyled>
              </Typography>
            </InfoRowStyled>
            <InfoRowStyled>
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                Once the file is placed, Syte will begin tagging the file. We will notify you by
                mail once the products have been enriched.
              </Typography>
            </InfoRowStyled>
          </InfoRowsNumberStyled>
        </ModalContentStyled>
        <ModalFooterStyled>
          <Button variant='primary' onClick={onModalClose}>
            Close
          </Button>
        </ModalFooterStyled>
      </>
    </ModalStyled>
  );
};
