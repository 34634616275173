import React, { CSSProperties, useCallback } from 'react';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import { MenuItem } from '../MenuItem';
import {
  MultiSelectMenuListStyled,
  EllipsisWithTooltipStyled,
  CheckboxStyled,
} from './MultiSelectMenu.styles';
import { MultiSelectOption } from './types';
import { VirtualScrollList } from '../VirtualScrollList';

interface MultiSelectMenuWithVirtualScrollProps {
  options: MultiSelectOption[];
  onChange: (option) => void;
  selectedOptions: Set<string | number>;
  className?: string;
  hasNextPage: boolean;
  onLoadMoreItems: () => void;
}
export const MultiSelectMenuWithVirtualScroll = ({
  options,
  onChange,
  selectedOptions,
  className,
  hasNextPage,
  onLoadMoreItems,
}: MultiSelectMenuWithVirtualScrollProps): JSX.Element => {
  const onItemClick = value => () => {
    onChange(value);
  };

  const renderVirtualizedBodyRow = useCallback(
    ({
      rowData,
      rowStyle,
    }: {
      rowData: any;
      rowStyle: CSSProperties;
      index: number;
    }): JSX.Element => {
      const isSelected = selectedOptions.has(rowData.value);

      return (
        <div style={rowStyle}>
          <MenuItem key={rowData.value} onClick={onItemClick(rowData)} selected={isSelected}>
            <>
              <CheckboxStyled checked={isSelected} />
              <EllipsisWithTooltipStyled tooltipText={rowData.text}>
                <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                  {rowData.text}
                </Typography>
              </EllipsisWithTooltipStyled>
            </>
          </MenuItem>
        </div>
      );
    },
    [selectedOptions, options]
  );

  return (
    <MultiSelectMenuListStyled className={className} enableScroll={true}>
      <VirtualScrollList
        data={options}
        itemHeight={40}
        hasNextPage={hasNextPage}
        renderBodyRow={renderVirtualizedBodyRow}
        onLoadMoreItems={onLoadMoreItems}
      />
    </MultiSelectMenuListStyled>
  );
};
