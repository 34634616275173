import React from 'react';
import { AvailableIcons, Icon, Switch, Tooltip } from 'src/components-dummy';

interface ToggleUseFiltersDisplayNameProps {
  enabled: boolean;
  onChange: (enabled: boolean) => void;
}

export function ToggleUseFiltersDisplayName({
  enabled,
  onChange,
}: ToggleUseFiltersDisplayNameProps): JSX.Element {
  return (
    <div className='toggle-use-display-name'>
      <Switch checked={enabled} onChange={onChange}>
        <Switch.TitleTemplate>Use filter name for website presentation</Switch.TitleTemplate>
      </Switch>
      <Tooltip value='Use this option, only if you support a single language, for more languages, approach your account manager for translation options.'>
        <Icon name={AvailableIcons.TooltipQuestionmark} />
      </Tooltip>
    </div>
  );
}
