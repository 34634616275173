import Joi from 'joi';
import { RankingStrategyTypes } from 'src/services';
import { getStringEnumValues } from 'src/utils';
import { syteCoreValuesSet } from './constants';

const weightValidationSchema = {
  enabled: Joi.boolean().required(),
  name: Joi.string().required(),
  field: Joi.string()
    .required()
    .custom((dataFieldName, { prefs: { context } }) => {
      const dataFieldsLookupTable = context?.dataFieldsLookupTable || {};

      const isValid =
        syteCoreValuesSet.has(dataFieldName as RankingStrategyTypes.SyteCoreRankingFields) ||
        !!dataFieldsLookupTable[dataFieldName];

      if (!isValid) {
        throw new Error(`Data field is not valid`);
      }
      return dataFieldName;
    }, 'validate data field')
    .messages({
      'any.custom': 'The data field was removed because it is invalid',
    }),
  order: Joi.string()
    .required()
    .valid(...getStringEnumValues(RankingStrategyTypes.RankingOrder)),
  weight: Joi.number().min(0).max(100).required(),
};

export const rankingValidationSchema = {
  weights: Joi.array().items(Joi.object().keys(weightValidationSchema)).min(1).required(),
};
