import styled from '@emotion/styled';
import { Skeleton } from 'src/components-dummy';

export const SkeletonsWrapper = styled.div`
  display: flex;
  padding: 4px;
  gap: 6px;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const SkeletonStyled = styled(Skeleton)`
  width: 100%;
  align-self: center;
`;
