import { ShopTheLookSettingsTypes } from 'src/services';
import { DomainForRendering } from '../../types';
import { shopTheLookDiffSchema } from './shop-the-look-settings-diff-schema';

export const shopTheLookSettingsSubDomainGroup: DomainForRendering<ShopTheLookSettingsTypes.ShopTheLook> =
  {
    getCardTitle: () => {
      return 'General Settings';
    },
    fieldsSchema: shopTheLookDiffSchema,
  };
