import { IShopCatalog } from 'src/services';

export enum CatalogType {
  Primary = 'Primary',
  NonPrimary = '',
}

export interface ICatalogTableBodyRow {
  id: string;
  name: IShopCatalog['name'];
  addedMethod: IShopCatalog['addedMethod'];
  lastUpdated: string;
  isPrimary: string;
  vertical: string;
  rowActions: boolean;
  shouldShowAlert: boolean;
  originalCatalog: IShopCatalog;
  canBeSetAsPrimary: boolean;
}

export interface ICatalogTableHeadRow {
  Header: string;
  accessor: string;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  canResize?: boolean;
}
