import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { AugmentedSearchReducerState } from 'src/app-state-types';
import { augmentedSearchSettingsActions, relevancyTuningActions } from 'src/components-bl';

const initialState: AugmentedSearchReducerState = {
  generalSettings: undefined,
  relevancyTuning: undefined,
};

export const augmentedSearchReducer = createReducer(initialState, builder => {
  builder
    .addCase(augmentedSearchSettingsActions.resetGeneralSettings, state => {
      return { ...state, generalSettings: undefined };
    })
    .addCase(relevancyTuningActions.resetRelevancyTuning, state => {
      return { state, relevancyTuning: undefined };
    })
    .addMatcher(
      isAnyOf(
        augmentedSearchSettingsActions.getGeneralSettings.fulfilled,
        augmentedSearchSettingsActions.updateGeneralSettings.fulfilled
      ),
      (state, action) => {
        return {
          ...state,
          generalSettings: action.payload,
        };
      }
    )
    .addMatcher(
      isAnyOf(
        relevancyTuningActions.getRelevancyTuning.fulfilled,
        relevancyTuningActions.updateRelevancyTuning.fulfilled
      ),
      (state, action) => {
        return { ...state, relevancyTuning: action.payload };
      }
    );
});
