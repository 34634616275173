import React from 'react';
import { Modal } from '../Modal';
import { Button } from '../Button';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import './InfoModal.scss';

export interface InfoModalProps {
  title?: string;
  onModalClose?: () => void;
  className?: string;
  isOpened: boolean;
  closeOnClickOutside?: boolean;
  onClickOutside?: () => void;
  closeBtnTxt: string;
  description?: string;
  children?: JSX.Element;
}
const rootClassName = 'syte-info-modal';
export const InfoModal = ({
  title,
  className,
  children,
  closeBtnTxt,
  description,
  isOpened,
  onClickOutside,
}: InfoModalProps): JSX.Element => {
  return (
    <Modal
      className={`${className} info-modal`}
      onClickOutside={onClickOutside}
      isOpened={isOpened}
    >
      <>
        {(title || description) && (
          <div className={`${rootClassName}-header`}>
            {title && (
              <Typography
                className={`${rootClassName}-title`}
                variant={TypographyVariant.LargeBold}
                type={TypographyType.Body}
              >
                {title}
              </Typography>
            )}
            {description && (
              <Typography
                className={`${rootClassName}-description`}
                variant={TypographyVariant.MediumRegular}
                type={TypographyType.Body}
              >
                {description}
              </Typography>
            )}
          </div>
        )}
        <div className={`${rootClassName}-content`}>{children}</div>
        <div className={`${rootClassName}-button-wrapper`}>
          <Button className={`${rootClassName}-button`} onClick={onClickOutside}>
            {closeBtnTxt}
          </Button>
        </div>
      </>
    </Modal>
  );
};
