import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { lexiconService } from 'src/services';

export const editLexiconRuleContainerActions = {
  navigateTo: createAction<{ navigateTo: string }>('EditLexiconRuleContainer/NavigateTo'),
  resetLexiconRule: createAction('EditLexiconRuleContainer/ResetLexiconRule'),
  getLexiconRule: createAsyncThunk(
    'EditLexiconRuleContainer/GetLexiconRule',
    async (
      parameters: Parameters<typeof lexiconService.getLexiconRule>[0],
      { rejectWithValue }
    ) => {
      try {
        const res = await lexiconService.getLexiconRule(parameters);
        return res;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
};
