import { useEffect } from 'react';
import { matchPath } from 'react-router';
import { baseAppRoutesByPath, baseAppRoutesPaths } from 'src/app-routes';
import { getDisplayCount } from 'src/components-bl/Notifications/utils';
import { useAppSelector } from 'src/hooks';

const BASE_APP_TITLE = 'Syte Console';

export const useAppTitle = (): void => {
  const unreadNotificationsCount = useAppSelector(state => state.notifications.unreadCount);

  useEffect(() => {
    const currentRoute = matchPath(window.location.pathname, baseAppRoutesPaths);

    if (!currentRoute) {
      document.title = BASE_APP_TITLE;
      return;
    }

    const routeSpecificTitle =
      baseAppRoutesByPath[currentRoute.path]?.title ||
      baseAppRoutesByPath[currentRoute.path]?.getRootRoute?.()?.title;

    const unreadCountDisplayName = getDisplayCount(unreadNotificationsCount);

    if (!routeSpecificTitle) {
      document.title = BASE_APP_TITLE;
    } else if (unreadCountDisplayName) {
      document.title = `(${unreadCountDisplayName}) ${routeSpecificTitle} | ${BASE_APP_TITLE}`;
    } else {
      document.title = `${routeSpecificTitle} | ${BASE_APP_TITLE}`;
    }

    // eslint-disable-next-line consistent-return
    return () => {
      document.title = BASE_APP_TITLE;
    };
  }, [window.location.pathname, unreadNotificationsCount]);
};
