import React from 'react';
import { PreviewFeature } from '../../../../services';
import { useAppSelector } from '../../../../hooks';

export type ExperienceFeature = {
  feature: PreviewFeature;
};

interface ExperiencePropsWithComponent<TInterface = Record<string, unknown>>
  extends ExperienceFeature {
  component: React.FC<TInterface>;
  componentProps?: TInterface;
}

interface ExperiencePropsWithChildren extends ExperienceFeature {
  children: React.ReactNode;
}

export type ExperienceProps = ExperiencePropsWithComponent | ExperiencePropsWithChildren;

/* eslint-disable react/destructuring-assignment */
export function Experience(props: ExperiencePropsWithComponent): JSX.Element | null;
export function Experience(props: ExperiencePropsWithChildren): JSX.Element | null;
export function Experience(props: ExperienceProps): JSX.Element | null {
  const selectedExperience = useAppSelector(({ visualEditor }) => visualEditor.selectedExperience);

  if (selectedExperience !== props.feature) return null;

  if ('component' in props) {
    return React.createElement(props.component, props.componentProps);
  }

  return <>{props.children}</>;
}
