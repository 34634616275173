import { GetRankingStrategyArgs } from 'src/components-bl/RankingStrategy/Actions/types';
import * as mapper from './mapper';
import * as builder from './builder';
import { UpdateRankingArguments } from './types';
import { RankingStrategyTypes } from '../types';
import { ApiServiceBase } from '../api-service-base';
import { buildQueryParams } from '../../common/utils';

export class Ranking extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl({ shopId, syteProduct, variantId, entityId }: GetRankingStrategyArgs): string {
    const queryStringParams = buildQueryParams({ variantId, entityId });
    return `${this.serviceBaseUri}/${shopId}/syte-products/${syteProduct}/ranking-strategy${queryStringParams}`;
  }

  async get({
    shopId,
    variantId,
    syteProduct,
    entityId,
  }: GetRankingStrategyArgs): Promise<RankingStrategyTypes.RankingStrategy> {
    const url = this.getUrl({ shopId, syteProduct, variantId, entityId });
    const response = await this.httpService.get({ url });
    return mapper.rankingResponse(response);
  }

  async update({
    shopId,
    variantId,
    syteProduct,
    entityId,
    ...payload
  }: UpdateRankingArguments): Promise<RankingStrategyTypes.RankingStrategy> {
    const url = this.getUrl({ shopId, syteProduct, variantId, entityId });
    const data = builder.buildRankingPayload(payload);
    const response = await this.httpService.patch({ url, data });
    return mapper.rankingResponse(response);
  }
}
