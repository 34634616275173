import styled from '@emotion/styled';
import { Page, TabsV2 } from 'src/components-dummy';

export const PageStyled = styled(Page)`
  height: 100%;
  width: 100%;

  .syte-page-header {
    border-bottom: none;
  }

  .syte-page-title {
    margin-right: auto;
  }

  .syte-page-content {
    flex-direction: column;
    padding-top: unset;
  }

  background: ${({ theme }) => theme.palette.custom['gray-90']};
`;

export const TabsStyled = styled(TabsV2)`
  margin-bottom: 8px;
`;
