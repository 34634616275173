import React, { useCallback, useMemo } from 'react';
import {
  AutoCompleteOption,
  Tooltip,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { DeepTagReportsGeneralConfigurationLabel } from 'src/services/src/service/types/deep-tag-reports';
import { ReadOnlyTextStyled } from '../DeepTagsReportsProductLabelAutoComplete.style';
import { MAX_TAGS_TO_SHOW } from '../DeepTagsReportsProductLabelAutoComplete.config';
import { TagValue } from '../DeepTagsReportsProductLabelAutoComplete.types';

interface DeepTagsReportsProductLabelReadOnlyProps {
  selectedValues: string[];
  generalConfigurationLabels: DeepTagReportsGeneralConfigurationLabel[];
  onToggleReadOnly: () => void;
}

export const DeepTagsReportsProductLabelReadOnly = ({
  selectedValues,
  generalConfigurationLabels,
  onToggleReadOnly,
}: DeepTagsReportsProductLabelReadOnlyProps): JSX.Element => {
  const generalConfigLabelsMap = useMemo(() => {
    return generalConfigurationLabels.reduce(
      (prev: Record<string, DeepTagReportsGeneralConfigurationLabel>, next) => {
        // eslint-disable-next-line no-param-reassign
        prev[next.id] = next;
        return prev;
      },
      {}
    );
  }, [generalConfigurationLabels]);

  const buildLabelTagsData = (tags: TagValue[]) => {
    return tags.reduce(
      (prev: { labelNamesToShow: string[]; totalRestOfLabels: number }, tag: TagValue, index) => {
        if (index <= MAX_TAGS_TO_SHOW - 1) {
          // eslint-disable-next-line no-param-reassign
          prev.labelNamesToShow.push(tag.title);
        } else {
          // eslint-disable-next-line no-param-reassign
          prev.totalRestOfLabels += 1;
        }

        return prev;
      },
      { labelNamesToShow: [] as string[], totalRestOfLabels: 0 }
    );
  };

  const buildLabelTagsDataText = (textData: {
    labelNamesToShow: string[];
    totalRestOfLabels: number;
  }) => {
    return `${textData.labelNamesToShow.join(', ')}${
      textData.totalRestOfLabels ? `, +${textData.totalRestOfLabels}` : ''
    }`;
  };

  const selectedValueWithTextList = useMemo(() => {
    return selectedValues.reduce((prev, selectedValue: string) => {
      if (generalConfigLabelsMap[selectedValue]) {
        const { id, name } = generalConfigLabelsMap[selectedValue] || {};
        prev.push({ value: id, title: name });
      }

      return prev;
    }, [] as AutoCompleteOption[]);
  }, [generalConfigLabelsMap, selectedValues]);

  const readOnlyText = useMemo(() => {
    return buildLabelTagsDataText(buildLabelTagsData(selectedValueWithTextList));
  }, [selectedValueWithTextList]);

  const onReadOnlyClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      onToggleReadOnly();
    },
    [onToggleReadOnly]
  );

  const readOnlyComponent = useMemo(
    () => (
      <ReadOnlyTextStyled onClick={onReadOnlyClick}>
        <Typography type={TypographyType.Body} variant={TypographyVariant.ExtraSmallRegular}>
          {readOnlyText}
        </Typography>
      </ReadOnlyTextStyled>
    ),
    [onReadOnlyClick, readOnlyText]
  );

  const toolTipValue = useMemo(() => {
    return selectedValueWithTextList.map(item => <div key={item.title}>{item.title}</div>);
  }, [selectedValueWithTextList]);

  const shouldIncludeToolTip = selectedValues.length > MAX_TAGS_TO_SHOW;

  return shouldIncludeToolTip ? (
    <Tooltip
      value={<div style={{ textAlign: 'center' }}>{toolTipValue}</div>}
      position='bottom center'
    >
      {readOnlyComponent}
    </Tooltip>
  ) : (
    readOnlyComponent
  );
};
