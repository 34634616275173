import React from 'react';
import styled from '@emotion/styled';
import { Skeleton } from 'src/components-dummy/Skeleton';
import { Button, ButtonProps, ConfirmationDialog, TextBox, SectionBox } from 'src/components-dummy';

export const ExistingCatalogFormStyled = styled(ConfirmationDialog)`
  width: 600px;
`;

export const SkeletonStyled = styled(Skeleton)`
  margin-bottom: 30px;
`;

export const ContentStyled = styled.div`
  min-height: 330px;
`;

export const TextBoxStyled = styled(TextBox)`
  margin-bottom: 20px;
`;

export const ButtonStyled = styled((props: ButtonProps) => <Button {...props} />)`
  margin-right: 5px;
`;

export const TogglesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const VerticalSwitchRowStyled = styled.div`
  display: flex;
  margin-top: 10px;
`;

export const SectionBoxStyled = styled(SectionBox)`
  flex-direction: column;
  margin-top: 20px;
  padding: 15px;

  .syte-error-label {
    margin-top: 5px;
  }
`;

export const VerticalChipStyled = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  padding: 5px 10px;
`;
