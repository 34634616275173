import styled from '@emotion/styled';
import { Button, TableV2 } from '../../../components-dummy';

export const CollectionNameStyled = styled(Button)`
  width: 80%;
  text-align: start;
  color: ${({ theme }) => theme.palette.custom['primary-main']};
  background-color: transparent;
  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
  & > span {
    width: 100%;
  }
`;

export const TableBodyStyled = styled(TableV2.Body)`
  max-height: calc(100vh - 350px);
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};
`;
