import { Coordinates } from 'src/components-bl/socialDiscovery/types/entities.types';

const convertPercentageToPx = (size: number, percentageValue: number): number => {
  return (size / 100) * percentageValue;
};

const convertPxToPercentage = (size: number, pxValue: number): number => {
  return Math.round((pxValue / size) * 100);
};

export const calculateProductTagPixelPosition = (
  parentSize: { height: number; width: number },
  coordinates: Coordinates
): Coordinates => {
  return {
    x: convertPercentageToPx(parentSize.width, coordinates.x),
    y: convertPercentageToPx(parentSize.height, coordinates.y),
  };
};

export const calculateProductTagPercentageCoordinates = (
  parentSize: { height: number; width: number },
  position: Coordinates
): Coordinates => {
  return {
    x: convertPxToPercentage(parentSize.width, position.x),
    y: convertPxToPercentage(parentSize.height, position.y),
  };
};
