import styled from '@emotion/styled';

export const DeepTagsProductReviewAndEditTagsSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const GenerateButtonWrapperStyled = styled.div`
  display: flex;
  padding: 8px 20px 20px 40px;

  & button {
    &:not(:disabled) {
      background: linear-gradient(91deg, #1aaeff 1.89%, rgba(0, 115, 255, 0.9) 100%);
    }
    > span {
      display: flex;
      align-items: center;
    }
  }
`;
