import {
  EditableFilterItem,
  FilterItemDBStructure,
  FilterStatusPropertyName,
  FilterType,
  BucketsOrderType,
  BucketsOrderSort,
} from '../types';
import { determineFilterStatus } from './add-item-utils';

const defaults = {
  bucketsOrder: { type: BucketsOrderType.count, sort: BucketsOrderSort.desc },
  numBuckets: 4,
  minBucketDelta: 5,
  showRangeInput: true,
};

export function getFormDraftFromSavedData(input: FilterItemDBStructure[]): EditableFilterItem[] {
  const formData: EditableFilterItem[] = input.map(dbItem => ({
    ...defaults,
    ...dbItem,
    [FilterStatusPropertyName]: determineFilterStatus(dbItem),
    isTypeNumeric: dbItem.type !== FilterType.text,
  }));
  return formData;
}
