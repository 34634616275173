import React, { useCallback, useEffect } from 'react';
import { SyteProductType } from 'src/services/src/service/types/syte-products';
import {
  FieldType,
  ShopDataField,
  ShopDataFieldsSupportedFeatures,
  SyteProductTypes,
} from 'src/services';
import { useDataFieldsBySupportedFeature } from '../hooks';
import { RankingStrategyTable } from './RankingStrategyTable';
import './RankingStrategy.scss';
import { Dispatch } from '../types';
import { NotifyIsDirtyActionPayload, rankingStrategyActionsMethods } from './Actions';
import { SyteProductTypeDropDown } from '../SyteProductTypeDropDown';
import { RankingWeightRecord } from './RankingStrategyTable/types';
import { RankingType } from './RankingStrategyType/types';
import { ShopFeatureToggles } from '../../services/src/service/types/shops';
import { getSupportedSyteProducts } from '../helpers/get-supported-syte-products';
import { SUPPORTED_RANKING_PRODUCTS } from './supported-syte-products';

export interface RankingStrategyProps {
  shopId: number;
  selectedSyteProduct: SyteProductType;
  selectedVariantId?: string;
  weights: RankingWeightRecord[] | undefined;
  type: RankingType | undefined;
  formHeaderElementRef?: React.RefObject<HTMLElement | null>;
  dataFields?: ShopDataField[];
  dispatch: Dispatch;
  onIsDirty?: (payload: NotifyIsDirtyActionPayload) => void;
  disabled?: boolean;
  shouldRefetch?: boolean;
  entityId?: string;
  prefixId?: string;
  hasRankingType?: boolean;
  closeRankingStrategyDrawer?: VoidFunction;
  featureToggles: ShopFeatureToggles;
}
const COMPONENT_NAME = 'rankingStrategy';

export const RankingStrategy = ({
  shopId,
  weights,
  type,
  selectedSyteProduct,
  selectedVariantId,
  formHeaderElementRef,
  dataFields,
  dispatch,
  onIsDirty,
  disabled = false,
  shouldRefetch = false,
  entityId,
  prefixId,
  hasRankingType = false,
  closeRankingStrategyDrawer,
  featureToggles,
}: RankingStrategyProps): JSX.Element => {
  const dataId = `${prefixId}_${COMPONENT_NAME}`;

  const { supportedDataFields } = useDataFieldsBySupportedFeature({
    dataFields,
    feature: ShopDataFieldsSupportedFeatures.RankingStrategy,
  });

  useEffect(() => {
    dispatch(rankingStrategyActionsMethods.setSyteProduct(undefined));
  }, []);

  useEffect(() => {
    if (shopId) {
      // TODO  handle loading with also data fields.
      dispatch(
        rankingStrategyActionsMethods.getDataFields({ shopId, fieldType: FieldType.CatalogField })
      );
    }
  }, [shopId]);

  const fetchWeights = useCallback(() => {
    if (shopId && selectedSyteProduct) {
      dispatch(
        rankingStrategyActionsMethods.fetchWeights({
          shopId,
          syteProduct: selectedSyteProduct,
          variantId: selectedVariantId,
          entityId,
        })
      );
    }
  }, [shopId, selectedSyteProduct, selectedVariantId, entityId]);

  useEffect(() => {
    fetchWeights();
  }, [fetchWeights]);

  useEffect(() => {
    if (shouldRefetch) {
      fetchWeights();
    }
  }, [shouldRefetch]);

  const onSyteProductChange = (syteProduct: SyteProductTypes.SyteProductType | undefined) => {
    dispatch(rankingStrategyActionsMethods.setSyteProduct(syteProduct));
  };

  const onDirtyChange = (payload: NotifyIsDirtyActionPayload) => {
    if (onIsDirty) {
      onIsDirty(payload);
    } else {
      dispatch(rankingStrategyActionsMethods.notifyIsDirty(payload));
    }
  };

  const hasType = hasRankingType && selectedSyteProduct === SyteProductType.Collections;

  const supportedSyteProducts = getSupportedSyteProducts(
    featureToggles,
    SUPPORTED_RANKING_PRODUCTS
  );

  return (
    <div className='ranking-strategy-container'>
      <SyteProductTypeDropDown
        prefixId={dataId}
        onChange={onSyteProductChange}
        value={selectedSyteProduct}
        className='ranking-strategy-container-products-dropdown'
        supportedSyteProducts={supportedSyteProducts}
        entityId={entityId}
      />
      <RankingStrategyTable
        shopId={shopId}
        selectedVariantId={selectedVariantId}
        weights={weights}
        type={type}
        selectedSyteProduct={selectedSyteProduct}
        dispatch={dispatch}
        formHeaderElementRef={formHeaderElementRef}
        onDirtyChange={onDirtyChange}
        dataFields={supportedDataFields}
        disabled={disabled}
        entityId={entityId}
        prefixId={prefixId}
        hasRankingType={hasType}
        closeRankingStrategyDrawer={closeRankingStrategyDrawer}
      />
    </div>
  );
};
