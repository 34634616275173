import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { visualEditorActions } from '../state';

interface Props {
  shopId: number;
  appDispatch: Dispatch<any>;
}

export const useGetPreviewExperiences = ({ appDispatch, shopId }: Props) => {
  useEffect(() => {
    appDispatch(visualEditorActions.getPreviewExperiences({ shopId }));
  }, [shopId]);
};
