import { css } from '@emotion/react';
import React, { useEffect } from 'react';
import { AvailableIcons, Page, TypographyVariant } from 'src/components-dummy';
import { MerchandisingRuleTypes } from 'src/services';
import { merchandisingRulesActions } from '../../merchandisingRules.actions';
import {
  MerchandisingRuleForm,
  MerchandisingRuleFormProps,
  RuleApiRef,
  ruleFormSkeleton,
} from '../MerchandisingRuleForm';
import { RuleFormPage } from '../RuleFormPage/RuleFormPage';
import { RuleGuideLink } from '../RuleGuideLink/RuleGuideLink';

import { UseDataFieldsLookupTableArgumentsReturnType } from '../useDataFieldsLookupTable';
import { BackIconStyled } from './EditMerchandisingRulePage.style';
import { CategoryFilterRule } from '../../../VisualEditor/types/category-filter-rule';
import { ShopFeatureToggles } from '../../../../services/src/service/types/shops';

export interface EditMerchandisingRulePageProps
  extends Omit<MerchandisingRuleFormProps, 'mode' | 'dataFieldsLookupTable' | 'getDataFieldByName'>,
    UseDataFieldsLookupTableArgumentsReturnType {
  rule?: MerchandisingRuleTypes.MerchandiseRule;
  formApiRef: React.Ref<RuleApiRef>;
  onBackToList: () => void;
  categoryFilterRule?: CategoryFilterRule;
  featureToggles: ShopFeatureToggles;
}

export const EditMerchandisingRulePage = ({
  shopId,
  variantId,
  dispatch,
  entityId,
  dataFieldsLookupTable,
  getDataFieldByName,
  rule,
  formApiRef,
  isReadOnly,
  predefinedProduct,
  onFormStatusChange,
  onBackToList,
  kpiOptions,
  availableRegions,
  isAiTagsEnabled,
  categoryFilterRule,
  featureToggles,
}: EditMerchandisingRulePageProps): JSX.Element => {
  useEffect(() => {
    if (shopId) {
      dispatch(
        merchandisingRulesActions.getShopLocales({
          shopId,
        })
      );
    }
  }, [shopId]);

  return (
    <RuleFormPage layout='default' className='merchandising-rules-form-page'>
      <Page.Header>
        <Page.Title
          variant={TypographyVariant.SmallBold}
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <BackIconStyled name={AvailableIcons.ArrowRounded} onClick={onBackToList} />
          Edit rule
        </Page.Title>
        <RuleGuideLink />
      </Page.Header>
      <Page.Content>
        {rule && shopId && dataFieldsLookupTable ? (
          <MerchandisingRuleForm
            mode='edit'
            rule={rule}
            shopId={shopId}
            ref={formApiRef}
            dispatch={dispatch}
            dataFieldsLookupTable={dataFieldsLookupTable}
            getDataFieldByName={getDataFieldByName}
            entityId={entityId}
            variantId={variantId}
            onFormStatusChange={onFormStatusChange}
            isReadOnly={isReadOnly}
            kpiOptions={kpiOptions}
            predefinedProduct={predefinedProduct}
            availableRegions={availableRegions}
            isAiTagsEnabled={isAiTagsEnabled}
            categoryFilterRule={categoryFilterRule}
            featureToggles={featureToggles}
          />
        ) : (
          ruleFormSkeleton
        )}
      </Page.Content>
    </RuleFormPage>
  );
};
