import React from 'react';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import { UnreadCountStyled, TypographyStyled } from './UnreadCount.styles';
import { getDisplayCount } from '../../utils';

interface UnreadCountProps {
  count: number | undefined;
}
export const UnreadCount = ({ count = 0 }: UnreadCountProps): JSX.Element => {
  return (
    <UnreadCountStyled disabled={count === 0}>
      <TypographyStyled type={TypographyType.Body} variant={TypographyVariant.ExtraSmallRegular}>
        {getDisplayCount(count)}
      </TypographyStyled>
    </UnreadCountStyled>
  );
};
