import React, { useState } from 'react';
import { AvailableIcons, Button, Dialog, Icon, Tooltip } from 'src/components-dummy';

interface QuickActionsProps {
  isVisible: boolean;
  toggleVisibility: () => void;
  setIsFormOpen: (isOpen: boolean) => void;
  deleteItem: (() => void) | undefined;
  messages: {
    delete?: {
      title: string;
      body?: string;
    };
    disable: {
      title: string;
      body?: string;
    };
  };
  isHiddenFilterSoftAlertShown: boolean;
}
export const QuickActions = ({
  isVisible,
  toggleVisibility,
  setIsFormOpen,
  deleteItem,
  messages,
  isHiddenFilterSoftAlertShown,
}: QuickActionsProps): JSX.Element => {
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [showDisableDialog, setShowDisableDialog] = useState<boolean>(false);

  const onDeleteItemClick = () => {
    setShowDeleteDialog(true);
  };
  const onConfirmDelete = () => {
    if (!deleteItem) return;
    deleteItem();
    setShowDeleteDialog(false);
  };

  const onDeleteDialogClose = () => {
    setShowDeleteDialog(false);
  };

  const onDiableItemClick = () => {
    if (isVisible) {
      setShowDisableDialog(true);
    } else {
      toggleVisibility();
    }
  };
  const onConfirmDisable = () => {
    toggleVisibility();
    setShowDisableDialog(false);
    setIsFormOpen(false);
  };

  const onDiableDialogClose = () => {
    setShowDisableDialog(false);
  };

  return (
    <>
      {isHiddenFilterSoftAlertShown ? (
        <Tooltip
          value='The last changes won’t be visible on your website, until the filter is set as visible'
          position='bottom center'
        >
          <Icon name={AvailableIcons.Warning} className='hidden-card-tooltip' />
        </Tooltip>
      ) : null}
      <Tooltip value={isVisible ? 'Hide' : 'Change to visible'} position='bottom center'>
        <Icon
          name={isVisible ? AvailableIcons.Eye : AvailableIcons.EyeCrossed}
          onClick={onDiableItemClick}
        />
      </Tooltip>
      <Dialog show={showDisableDialog} onClose={onDiableDialogClose}>
        <Dialog.Header>{messages?.disable?.title}</Dialog.Header>
        <Dialog.Body>
          {messages?.disable?.body ||
            'Hiding this filter will remove it from your website. Do you wish to proceed?'}
        </Dialog.Body>
        <Dialog.ActionsFooter>
          <Button variant='secondary' onClick={onDiableDialogClose}>
            No, Go Back
          </Button>
          <Button variant='tertiary' onClick={onConfirmDisable}>
            Yes, Hide Filter
          </Button>
        </Dialog.ActionsFooter>
      </Dialog>
      <Tooltip value='Edit' position='bottom center'>
        <Icon name={AvailableIcons.Edit} onClick={() => setIsFormOpen(true)} />
      </Tooltip>
      {deleteItem ? (
        <>
          <Tooltip value='Delete' position='bottom center'>
            <Icon name={AvailableIcons.Delete} onClick={onDeleteItemClick} />
          </Tooltip>
          <Dialog show={showDeleteDialog} onClose={onDeleteDialogClose}>
            <Dialog.Header>{messages?.delete?.title}</Dialog.Header>
            <Dialog.Body>
              {messages?.delete?.body ||
                `Deleting a filter from the list will remove it from the filter bar on your website. Do
              you wish to proceed?`}
            </Dialog.Body>
            <Dialog.ActionsFooter>
              <Button variant='secondary' onClick={onDeleteDialogClose}>
                No, Go Back
              </Button>
              <Button variant='tertiary' onClick={onConfirmDelete}>
                Yes, Delete Filter
              </Button>
            </Dialog.ActionsFooter>
          </Dialog>
        </>
      ) : null}
    </>
  );
};
