import React from 'react';
import styled from '@emotion/styled';
import { TextBox, TextBoxProps } from '../TextBox';

export const TextBoxStyled = styled((props: TextBoxProps) => <TextBox {...props} />)`
  margin-top: 24px;

  .ellipsis-with-tooltip {
    position: absolute;
  }
`;
