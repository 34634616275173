import { InspirationalImagesGalleryTableHeadRow } from './types';

export const tableColumns: Record<string, InspirationalImagesGalleryTableHeadRow> = {
  skus: {
    Header: 'SKUs',
    accessor: 'skus',
  },
  imageUrl: {
    Header: 'Image URL',
    accessor: 'imageUrl',
  },
};

export const tableColumnsArray = Object.values(tableColumns);
