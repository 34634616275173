import { EditExperimentContainerAction } from 'src/containers';
import { BoughtTogetherFallbackMethod } from 'src/types/enums/bought-together-fallback-method';
import { SortingOrder } from 'src/types/enums/sorting-order';
import { BoughtTogetherSettingsReducerState } from '../app-state-types';
import {
  BoughtTogetherSettingsAction,
  BoughtTogetherActionTypes,
  VariantPanelAction,
} from '../components-bl';
import { DEFAULT_ADD_MULTIPLE_TO_CART } from '../components-bl/AddToCartSettings/defaults/default-add-multiple-to-cart';

const initialState: BoughtTogetherSettingsReducerState = {
  numberOfResultsToDisplay: 12,
  modelType: 'Session based',
  boughtTogetherFallbackMethod: BoughtTogetherFallbackMethod.None,
  shouldRefetch: false,
  addToCart: DEFAULT_ADD_MULTIPLE_TO_CART,
  boughtTogetherFallbackField: undefined,
  sortingOrder: SortingOrder.Desc,
};

export function boughtTogetherSettingsReducer(
  state: BoughtTogetherSettingsReducerState = initialState,
  action?: BoughtTogetherSettingsAction | EditExperimentContainerAction | VariantPanelAction
): BoughtTogetherSettingsReducerState {
  switch (action?.type) {
    case BoughtTogetherActionTypes.GetBoughtTogetherSettingsSuccess: {
      const {
        numberOfResultsToDisplay,
        modelType,
        boughtTogetherFallbackMethod,
        addToCart,
        boughtTogetherFallbackField,
        sortingOrder,
      } = action.payload;
      return {
        ...state,
        numberOfResultsToDisplay,
        modelType,
        boughtTogetherFallbackMethod,
        addToCart,
        boughtTogetherFallbackField,
        sortingOrder,
        shouldRefetch: false,
      };
    }
    case BoughtTogetherActionTypes.GetBoughtTogetherSettingsRequest:
      return { ...state };
    case BoughtTogetherActionTypes.GetBoughtTogetherSettingsError:
      return { ...state };
    case BoughtTogetherActionTypes.UpdateBoughtTogetherSettingsSuccess: {
      const {
        numberOfResultsToDisplay,
        modelType,
        boughtTogetherFallbackMethod,
        addToCart,
        boughtTogetherFallbackField,
        sortingOrder,
      } = action.payload;
      const updatedSettings = {
        ...state,
        numberOfResultsToDisplay,
        modelType,
        boughtTogetherFallbackMethod,
        addToCart,
        boughtTogetherFallbackField,
        sortingOrder,
      };
      return {
        ...state,
        numberOfResultsToDisplay: updatedSettings.numberOfResultsToDisplay,
        modelType: updatedSettings.modelType,
        boughtTogetherFallbackMethod: updatedSettings.boughtTogetherFallbackMethod,
        addToCart: updatedSettings.addToCart,
        boughtTogetherFallbackField: updatedSettings.boughtTogetherFallbackField,
        sortingOrder: updatedSettings.sortingOrder,
      };
    }
    default:
      return state;
  }
}
