import styled from '@emotion/styled';
import { SectionBox, Typography } from 'src/components-dummy';
import { FileUploadButton } from 'src/components-dummy/FileUploader';
import { LinkButton } from 'src/components-dummy/LinkButton';

export const SectionBoxStyled = styled(SectionBox)`
  background-color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 16px;
  padding: 28px 24px;
`;

export const DescriptionContainerStyled = styled.div`
  p {
    color: ${({ theme }) => theme.palette.custom['gray-20']};
  }
`;

export const ErrorMessageStyled = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.custom.red};
  }
`;

export const FileUploadButtonStyled = styled(FileUploadButton)`
  margin-top: 14px;
`;

export const ButtonContentStyled = styled.span`
  display: flex;
  gap: 10px;

  svg {
    path {
      stroke: ${({ theme }) => theme.palette.common.white};
    }
  }
`;

export const DownloadExampleLinkButtonStyled = styled(LinkButton)`
  display: flex;
  align-items: center;
  margin-top: 10px;

  svg {
    margin-right: 5.5px;
    path {
      stroke: ${({ theme }) => {
        return theme.palette.custom['primary-main'];
      }} !important;
    }
  }
`;
