import React, { useMemo } from 'react';
import { Item } from '../Item/Item';
import { fetchData } from './fetchData';
import { Empty } from '../Empty';
import type { UiTemplate } from '../types';
import { Dispatch } from '../../types';
import './List.scss';

interface Props {
  dispatch: Dispatch;
  list: UiTemplate[];
  shopId: number;
  openPopup: () => void;
}

export const UiTemplatesList: React.FC<Props> = ({ dispatch, list, shopId, openPopup }) => {
  fetchData({ shopId, dispatch });

  const isListEmpty = !list.length;

  const items = useMemo(
    () =>
      list.map(template => (
        <Item dispatch={dispatch} template={template} key={template.name} openPopup={openPopup} />
      )),
    [shopId, list]
  );

  return (
    <>
      {isListEmpty ? (
        <Empty shopId={shopId} dispatch={dispatch} openPopup={openPopup} />
      ) : (
        <div className='ui-templates-list'>{items}</div>
      )}
    </>
  );
};
