import styled from '@emotion/styled';
import { LexiconItemEditAutoComplete } from 'src/components-bl/Lexicon/components/LexiconTable/Components/LexiconItemAutoComplete';
import { Typography, Select, Button } from 'src/components-dummy';

export const LexiconChangeRuleSectionStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  margin-bottom: 40px;
`;

export const LexiconItemEditAutoCompleteStyled = styled(LexiconItemEditAutoComplete)``;

export const RuleFieldAutocompleteContainer = styled.div`
  width: 100%;
`;

export const RuleFieldAutocompleteLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const LexiconTagSelectStyled = styled(Select)`
  margin-right: 4px;

  .MuiInputBase-root {
    display: flex;
  }

  .syte-error-label {
    position: absolute;
    white-space: nowrap;
  }
`;

export const AddNewRowButtonStyled = styled(Button)`
  color: ${({ theme }) => theme.palette.custom['primary-main']};
  max-width: fit-content;

  .syte-basic-icon {
    path {
      stroke: ${({ theme }) => theme.palette.custom['primary-main']};
    }
  }
`;
