import React, { useEffect } from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import { DatePicker as DatePickerMUI } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { debounce } from 'lodash';
import { PopperPlacementType } from '@mui/base';
import { TextBox } from '../TextBox';
import { DatePickerTextBoxWrapperStyled } from './DatePicker.style';
import { checkIsValidDate } from './DatePicker.helpers';

const DEBOUNCE_TIME_IN_MS = 350;

const DATE_FORMAT = 'MM/dd/yyyy';

export const getFormattedDate = (date: Date): string => {
  return format(date, DATE_FORMAT);
};

export interface DatePickerProps {
  value?: Date | null;
  isOpen?: boolean;
  minDate?: Date;
  maxDate?: Date;
  sx?: SxProps<Theme>;
  popperPlacement?: PopperPlacementType;
  isError: boolean;
  disabled?: boolean;
  onClick?: (event: MouseEvent) => void;
  onClose?: () => void;
  onOpen?: () => void;
  onChange: (selectedDate?: Date, formattedDate?: string | null) => void;
}

export const DatePicker = ({
  value,
  isOpen: isOpenInitial = false,
  minDate,
  maxDate,
  popperPlacement,
  isError,
  sx,
  disabled,
  onChange,
  onOpen,
  onClose,
}: DatePickerProps): JSX.Element => {
  const [currentValue, setCurrentValue] = React.useState<string | null>(
    value ? getFormattedDate(value) : null
  );
  const [isOpen, setIsOpen] = React.useState<boolean>(!!isOpenInitial);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose?.();
  };

  const handleOpen = () => {
    setIsOpen(true);
    onOpen?.();
  };

  const handleChange = debounce((selectedDate: any) => {
    const isValidDate = checkIsValidDate(selectedDate);

    // set time to date if value (date) has passed (otherwise it would have been 00:00:00)
    const selectedDateWithTime =
      value && selectedDate
        ? new Date(selectedDate.setHours(value.getHours(), value.getMinutes(), value.getSeconds()))
        : selectedDate;

    const formattedDateString = isValidDate ? format(selectedDateWithTime, DATE_FORMAT) : null;

    const newValue = isValidDate ? selectedDateWithTime : null;

    setCurrentValue(formattedDateString);
    onChange?.(newValue, formattedDateString);
  }, DEBOUNCE_TIME_IN_MS);

  useEffect(() => {
    setIsOpen(!!isOpenInitial);
  }, [isOpenInitial]);

  useEffect(() => {
    const formattedDate = value ? getFormattedDate(value) : null;
    setCurrentValue(formattedDate);
  }, [value]);

  useEffect(() => {
    // Set currentValue as value if `currentValue` is null
    if (!currentValue) {
      const formattedDate = value ? getFormattedDate(value) : null;
      setCurrentValue(formattedDate);
    }
  }, [currentValue]);

  const setAnchorRef = (element: HTMLDivElement) => {
    setAnchorEl(element);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePickerMUI
          disabled={disabled}
          open={isOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          value={currentValue}
          onChange={handleChange}
          minDate={minDate}
          maxDate={maxDate}
          PopperProps={{
            placement: popperPlacement || 'bottom-start',
            anchorEl,
            style: { zIndex: 2000 },
            sx: {
              '& .MuiPickersDay-root': {
                '&.Mui-selected': {
                  backgroundColor: theme => theme.palette.custom['primary-main'],
                  cursor: 'pointer',
                },
              },
              '& .MuiPickersPopper-paper': {
                borderRadius: '8px',
                marginTop: '4px',
                marginBottom: '4px',
                border: theme => `1px solid  ${theme.palette.custom['gray-50']}`,
              },
              ...sx,
            },
          }}
          renderInput={params => {
            return (
              <DatePickerTextBoxWrapperStyled
                shouldShowAsValidSelection={isOpen && !isError}
                ref={setAnchorRef}
              >
                <TextBox
                  {...(params.inputProps as any)}
                  ref={params.inputRef}
                  onClick={handleClick}
                  error={isError}
                  onChange={(text, event) => params.inputProps?.onChange?.(event as any)}
                />
              </DatePickerTextBoxWrapperStyled>
            );
          }}
        />
      </LocalizationProvider>
    </>
  );
};
