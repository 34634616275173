import { NotificationType } from '../../components-dummy';
import { IEnhancedStrategy } from '../../types';

export enum StoriesActionTypes {
  GeneralError = 'Stories/GeneralError',
  SaveSettings = 'Stories/SaveSettings',
  ActionWarning = 'Stories/ActionWarning',
}

export const storiesActionToasts = {
  [StoriesActionTypes.ActionWarning]: 'Please, save current configuration before proceeding',
  [StoriesActionTypes.GeneralError]: 'Something went wrong. Please, reload page and try again.',
  [StoriesActionTypes.SaveSettings]: 'Changes have been saved successfully!',
};

export interface IEditableStoriesSettings {
  storiesStrategy: IEnhancedStrategy | null;
  numberOfResultsToDisplay: number;
}

export interface IStoriesSettings extends IEditableStoriesSettings {
  strategies: IEnhancedStrategy[];
}

export enum StoriesStateType {
  noChanges,
  fetching,
  saving,
  error,
  dirty,
  leaving,
}

export type StoriesState = {
  type: StoriesStateType;
  generalSettings: IStoriesSettings;
  editedSettings: IEditableStoriesSettings;
  message: {
    type: NotificationType;
    messageKey: StoriesActionTypes;
  } | null;
};
