import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RelevancyTuningItem, augmentedSearchService } from 'src/services';

export const relevancyTuningActions = {
  notifyIsDirty: createAction<{ isDirty: boolean }>('RelevancyTuning/NotifyIsDirty'),
  resetRelevancyTuning: createAction('RelevancyTuning/ResetRelevancyTuning'),
  getRelevancyTuning: createAsyncThunk(
    'RelevancyTuning/GetRelevancyTuning',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const relevancyTuning = await augmentedSearchService.getRelevancyTuning({ shopId });

        return relevancyTuning;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateRelevancyTuning: createAsyncThunk(
    'RelevancyTuning/UpdateRelevancyTuning',
    async (
      { shopId, relevancyTuning }: { shopId: number; relevancyTuning: RelevancyTuningItem[] },
      { rejectWithValue }
    ) => {
      try {
        const updatedRelevancyTuning = await augmentedSearchService.updateRelevancyTuning({
          shopId,
          relevancyTuning,
        });

        return updatedRelevancyTuning;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
