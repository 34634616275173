import React from 'react';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { FormSectionStyled, TitleWrapperStyled, SubTitleStyled } from './FormSection.styles';

export interface FormSectionProps {
  title: string;
  subTitle?: string;
  children: JSX.Element | JSX.Element[];
}

export const FormSection = ({ title, subTitle, children }: FormSectionProps): JSX.Element => {
  return (
    <FormSectionStyled>
      <TitleWrapperStyled>
        <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
          {title}
        </Typography>

        {subTitle && (
          <SubTitleStyled type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            {subTitle}
          </SubTitleStyled>
        )}
      </TitleWrapperStyled>
      {children}
    </FormSectionStyled>
  );
};
