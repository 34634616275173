import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, Redirect } from 'react-router';
import { AppRoute, AppSwitch, RouteSettings, RoutedComponentProps } from 'src/app-routes';
import { useAppSelector } from 'src/hooks';
import { DeepTagReportProductsContainer } from '../DeepTagReportProductsContainer';
import { DeepTagReportsContainer } from '../DeepTagReportsContainer';
import { deepTagReportsContainerSwitchActions } from './DeepTagReportsContainerSwitch.actions';

export type DeepTagReportsContainerSwitchProps = RoutedComponentProps;

interface ComponentRoute {
  route: RouteSettings;
  Component: () => JSX.Element | null;
  exact: boolean;
}

export const DeepTagReportsContainerSwitch = ({
  permittedRouteMap,
}: DeepTagReportsContainerSwitchProps): JSX.Element => {
  const shopId = useAppSelector(state => state.shop.current?.shopId);

  const lexiconLanguages = useAppSelector(state => state.lexicon.allLexiconSupportedLanguages);

  const dispatch = useDispatch();

  const relevantRoutes = useMemo(
    () =>
      [
        {
          route: permittedRouteMap.deepTagReportProducts,
          Component: DeepTagReportProductsContainer,
        },
        {
          route: permittedRouteMap.deepTagReports,
          Component: DeepTagReportsContainer,
        },
      ].filter(routeComponent => !!routeComponent.route) as ComponentRoute[],
    [permittedRouteMap.deepTagReports, permittedRouteMap.deepTagReportProducts]
  );

  const routeComponents = useMemo(
    () =>
      relevantRoutes.map(({ route, Component }) => (
        <AppRoute
          key={route.path}
          route={route}
          Component={Component}
          componentProps={{ permittedRouteMap }}
          exact={false}
        />
      )),
    [relevantRoutes, permittedRouteMap]
  );

  // The lexicon metadata list are used in the deep tag reports modals. one place to call it.
  useEffect(() => {
    if (shopId && lexiconLanguages) {
      dispatch(
        deepTagReportsContainerSwitchActions.getLexiconsMetadataList({ shopId, lexiconLanguages })
      );
    }
  }, [shopId]);

  return (
    <>
      <AppSwitch>
        {routeComponents}
        {permittedRouteMap.deepTagReports && shopId && (
          <Redirect to={generatePath(permittedRouteMap.deepTagReports.path, { shopId })} />
        )}
      </AppSwitch>
    </>
  );
};
