import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';
import { ButtonIconStyled, ImageButtonStyled } from './shared.styles';

export const TypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  white-space: nowrap;
  overflow: hidden;
`;

export const AddTagsButtonStyled = styled(ImageButtonStyled)`
  bottom: 10px;
  left: 10px;
  padding-right: 0;
  justify-content: flex-start;

  height: 24px;
  min-width: 24px;

  ${ButtonIconStyled} {
    min-width: 16px;
  }

  ${TypographyStyled} {
    width: 0;
    opacity: 0;
    margin-left: 4px;
    transition:
      width 0.3s,
      opacity 0.2s;
  }

  &:hover {
    ${TypographyStyled} {
      margin-right: 6px;
      opacity: 1;
      width: 100%;
    }
  }
`;
