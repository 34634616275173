import React from 'react';
import { AvailableIcons, Icon } from '../Icon';
import { LinkButton } from '../LinkButton';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import { FileCards } from './components/FileCards';
import { DEFAULT_MAX_SIZE_MB, fileAcceptTypes } from './constants';
import {
  FileUploaderStyled,
  UploaderTitleStyled,
  FileErrorMessageStyled,
  DownloadExampleLinkButtonStyled,
} from './FileUploader.styles';
import { FileUploaderProps } from './types';
import { useFileUploader } from './useFileUploader';

export const FileUploader = ({
  selectedFiles,
  accept,
  maxFiles,
  maxSizeMb: maxSizeMbProp,
  className,
  title,
  fileCardIconName,
  children,
  disabled,
  onChange,
  onError: onErrorProp,
}: FileUploaderProps): JSX.Element => {
  const maxSizeMb = maxSizeMbProp || DEFAULT_MAX_SIZE_MB;

  const { showUploader, fileErrorMessage, getRootProps, getInputProps, open } = useFileUploader({
    accept,
    disabled,
    maxFiles,
    maxSizeMb,
    onChange,
    onError: onErrorProp,
    selectedFiles,
  });

  const browseLinkButton = (
    <LinkButton onClick={open}>
      <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
        browse
      </Typography>
    </LinkButton>
  );

  return (
    <div className={className}>
      {showUploader ? (
        <FileUploaderStyled {...getRootProps()}>
          <input {...getInputProps()} />
          <FileErrorMessageStyled visible={!!fileErrorMessage}>
            <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Body}>
              {fileErrorMessage}
            </Typography>
          </FileErrorMessageStyled>

          <Icon name={AvailableIcons.PageLarge} />
          <UploaderTitleStyled variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
            {title}
          </UploaderTitleStyled>
          <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
            {`Drag & Drop or `}
            {browseLinkButton}
            {` max (${maxSizeMb}MB)`}
          </Typography>
          {children}
        </FileUploaderStyled>
      ) : (
        <FileCards
          disabled={disabled}
          fileCardIconName={fileCardIconName}
          onChange={onChange}
          selectedFiles={selectedFiles}
        />
      )}
    </div>
  );
};

export interface FileUploaderCSVProps extends FileUploaderProps {
  onExampleClick?: () => void;
}

const FileUploaderCSV = ({
  onExampleClick,
  fileCardIconName,
  title,
  ...fileUploaderProps
}: Omit<FileUploaderCSVProps, 'accept'>): JSX.Element => {
  return (
    <FileUploader
      {...fileUploaderProps}
      accept={fileAcceptTypes.CSV}
      title={title || 'Upload CSV file'}
      fileCardIconName={fileCardIconName || AvailableIcons.CsvFile}
    >
      <DownloadExampleLinkButtonStyled onClick={onExampleClick}>
        <Icon name={AvailableIcons.Download} />
        <Typography variant={TypographyVariant.MediumMedium} type={TypographyType.Body}>
          Download CSV example
        </Typography>
      </DownloadExampleLinkButtonStyled>
    </FileUploader>
  );
};

FileUploader.CSV = FileUploaderCSV;
