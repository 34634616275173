import styled from '@emotion/styled';
import { DialogModal, DialogModalFooter } from '../../../../../components-dummy';

export const DialogModalStyled = styled(DialogModal)`
  .MuiPaper-root {
    max-width: 520px;
  }
`;

export const DialogModalFooterStyled = styled(DialogModalFooter)`
  gap: 5px;
`;
