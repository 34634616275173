import { createAction } from '@reduxjs/toolkit';
import { DeepTagReportWithMetaData } from 'src/services';

/**
 * Actions
 */
export const useDeepTagReportsWSActions = {
  newReport: createAction<{ report: DeepTagReportWithMetaData }>('UseDeepTagReportsWS/NewReport'),
  reportUpdated: createAction<{ report: DeepTagReportWithMetaData }>(
    'UseDeepTagReportsWS/ReportUpdated'
  ),
  reportDeleted: createAction<{ reportId: string }>('UseDeepTagReportsWS/ReportDeleted'),
  reportNotification: createAction<{ customMessage: string }>(
    'UseDeepTagReportsWS/ReportNotification'
  ),
};
