import styled from '@emotion/styled';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';

export const InputChildrenStyled = styled.div`
  max-height: 25px;
  display: flex;
  margin-right: 4px;
  position: relative;
`;

export const MuiInputStyled = styled(Input)`
  &::before,
  &::after {
    display: none;
  }
`;

export const InputWrapperStyled = styled.div<{
  isSuccess?: boolean;
  disabled?: boolean;
  hasChildren?: boolean;
  error?: boolean;
  multiline?: boolean;
}>`
  display: flex;

  ${({ multiline }) => {
    if (!multiline) {
      return 'height: 40px;';
    }
    return '';
  }}

  border: 1px solid
    ${({ theme, isSuccess, error }) => {
    if (error) {
      return theme.palette.custom.red;
    }
    if (isSuccess) {
      return theme.palette.custom.success;
    }
    return theme.palette.custom['gray-50'];
  }};

  border-radius: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
  padding-left: ${({ hasChildren }) => (hasChildren ? '8' : '16')}px;

  background: ${({ disabled, theme }) =>
    disabled ? theme.palette.custom['gray-90'] : 'transparent'};

  &:focus,
  &:focus-within {
    border: 1px solid
      ${({ theme, error, isSuccess }) => {
        if (error) {
          return theme.palette.custom.red;
        }
        if (isSuccess) {
          return theme.palette.custom.success;
        }
        return theme.palette.custom['primary-main'];
      }} !important;

    ${({ error, theme, isSuccess }) => {
      if (!error && !isSuccess) {
        return `
        outline: ${theme.palette.custom['primary-90']} solid 2px;
        `;
      }
      return '';
    }}
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  &:hover {
    border: 1px solid
      ${({ theme, isSuccess, error }) => {
        if (isSuccess) {
          return theme.palette.custom.success;
        }
        if (error) {
          return theme.palette.custom.red;
        }
        return theme.palette.custom['primary-main'];
      }};
  }
`;

export const MuiInputLabelStyled = styled(InputLabel)``;

export const InputBaseStyled = styled.div<{ fullWidth?: boolean }>`
  .MuiInputLabel-root:hover,
  .MuiInputLabel-root:focus,
  .MuiInputLabel-root:focus-within {
    color: ${({ theme }) => theme.palette.common.black} !important;
  }

  .MuiInputLabel-root {
    margin-bottom: 5px;
    color: ${({ theme }) => theme.palette.common.black};
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  ${({ fullWidth }) => fullWidth && 'width: 100%;'}

  .ellipsis-with-tooltip {
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${({ theme }) => theme.palette.custom.red};
  }
`;
