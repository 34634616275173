import React, { useMemo } from 'react';
import { TableV2, TableV2InstanceProps } from 'src/components-dummy';
import { ICatalogUploadErrorsPaginated } from 'src/services';
import { css } from '@emotion/react';
import { tableColumnsClient, tableColumnsAdmin } from './CatalogUploadErrorReportList.config';
import {
  ICatalogUploadErrorReportBodyRow,
  ICatalogUploadErrorReportBodyRowAdmin,
  ICatalogUploadErrorReportBodyRowClient,
} from './types';
import { CatalogUploadErrorReportTableRow } from './CatalogUploadErrorReportTableRow';

const NOT_AVAILABLE = '-';

interface CatalogUploadErrorReportListProps {
  catalogUploadErrorReports: ICatalogUploadErrorsPaginated;
  isAdminUser: boolean;
}

export const CatalogUploadErrorReportList = ({
  catalogUploadErrorReports,
  isAdminUser,
}: CatalogUploadErrorReportListProps): JSX.Element => {
  const tableData: ICatalogUploadErrorReportBodyRow[] = useMemo(() => {
    return catalogUploadErrorReports.data.map(catalogUploadErrorReport => {
      const {
        sku,
        title,
        category,
        syteCategory,
        imageUrl,
        errorDescription,
        errorDescriptionInternal,
        actions,
        actionsInternal,
      } = catalogUploadErrorReport;

      const clientRowData: ICatalogUploadErrorReportBodyRowClient = {
        sku,
        title,
        category,
        syteCategory: syteCategory || NOT_AVAILABLE,
        imageUrl,
        errorReason: errorDescription || NOT_AVAILABLE,
        actions: actions || NOT_AVAILABLE,
      };

      if (isAdminUser) {
        const adminBodyRow: ICatalogUploadErrorReportBodyRowAdmin = {
          ...clientRowData,
          errorReasonInternal: errorDescriptionInternal || NOT_AVAILABLE,
          actionsInternal: actionsInternal || NOT_AVAILABLE,
        };

        return adminBodyRow;
      }

      return clientRowData;
    });
  }, [catalogUploadErrorReports, isAdminUser]);

  const tableColumnsConfig = useMemo(() => {
    const columnsConfig = isAdminUser ? tableColumnsAdmin : tableColumnsClient;
    return Object.values(columnsConfig);
  }, [isAdminUser]);

  return (
    <>
      <TableV2<ICatalogUploadErrorReportBodyRow>
        options={{ columns: tableColumnsConfig, data: tableData }}
        customOptions={{
          shouldUseResizeColumns: true, // Needs to be added here
          shouldUseFlexLayout: true,
        }}
      >
        {({
          getTableBodyProps,
          state,
          headerGroups,
          rows,
          prepareRow,
        }: TableV2InstanceProps<ICatalogUploadErrorReportBodyRow>) => {
          const headerGroup = headerGroups[0];

          const isResizingColumn = !!state.columnResizing.isResizingColumn;

          return (
            <>
              <TableV2.Head {...headerGroup.getHeaderGroupProps()} isSticky>
                {headerGroup.headers.map(column => (
                  <TableV2.HeadRow {...column.getHeaderProps()} isResizable key={column.id}>
                    <TableV2.HeadRowCellText key={`${isResizingColumn}`}>
                      {column.render('Header')}
                    </TableV2.HeadRowCellText>
                    <TableV2.HeaderRowCellResizableCursor column={column} />
                  </TableV2.HeadRow>
                ))}
              </TableV2.Head>
              <TableV2.Body
                {...getTableBodyProps()}
                css={css`
                  overflow-y: auto;
                  scrollbar-gutter: auto;
                  height: 100%;
                `}
              >
                {!rows?.length ? (
                  <TableV2.NoResults width={291} />
                ) : (
                  rows.map(row => {
                    prepareRow(row);
                    return (
                      <CatalogUploadErrorReportTableRow
                        key={`${row.original.sku}-${row.original.errorReason}`}
                        isResizingColumn={isResizingColumn}
                        row={row}
                      />
                    );
                  })
                )}
              </TableV2.Body>
            </>
          );
        }}
      </TableV2>
    </>
  );
};
