import { ApiServiceBase } from '../api-service-base';
import {
  DeleteUiTemplateArguments,
  GetUiTemplateArguments,
  GetUiTemplatesArguments,
  SaveUiTemplateArguments,
} from './types';
import { UiTemplate } from '../../../../components-bl/UiTemplates/types';
import { mapUiTemplateResponse, mapUiTemplatesResponse } from './uiTemplatesMapper';

export class UiTemplates extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getEndpoint(shopId: number): string {
    return `${this.serviceBaseUri}/${shopId}/ui-templates`;
  }

  async getUiTemplates({ shopId }: GetUiTemplatesArguments): Promise<UiTemplate[]> {
    const url = `${this.getEndpoint(shopId)}`;
    const response = await this.httpService.get({ url });

    return mapUiTemplatesResponse(response);
  }

  async getUiTemplate({ shopId, name }: GetUiTemplateArguments): Promise<UiTemplate> {
    const url = `${this.getEndpoint(shopId)}/${encodeURIComponent(name)}`;
    const response = await this.httpService.get({ url });

    return mapUiTemplateResponse(response);
  }

  async saveUiTemplate({ shopId, name, template }: SaveUiTemplateArguments): Promise<UiTemplate> {
    const url = `${this.getEndpoint(shopId)}/${encodeURIComponent(name)}`;
    const response = await this.httpService.patch({ url, data: { template } });

    return mapUiTemplateResponse(response);
  }

  async deleteUiTemplate({ shopId, name }: DeleteUiTemplateArguments): Promise<UiTemplate[]> {
    const url = `${this.getEndpoint(shopId)}/${encodeURIComponent(name)}`;
    const response = await this.httpService.delete({ url });

    return mapUiTemplatesResponse(response);
  }
}

export const uiTemplatesService = new UiTemplates();
