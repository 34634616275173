import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Dispatch } from 'src/components-dummy/types';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import { DeepTagReport } from 'src/services';
import { RoutedComponentProps } from 'src/app-routes';
import { SkeletonVerticalBlocks } from 'src/components-dummy/Skeleton/skeletonCompositions';
import { deepTagReportsListActions } from './Actions/DeepTagReportsListActions';
import { DeepTagReportsListItem } from './DeepTagReportsListItem';
import { DeepTagReportHowToUploadTrigger } from '../DeepTagReportHowToUpload';
import {
  DeepTagReportsListStyled,
  EmptyListStateStyled,
  TypographyStyled,
} from './DeepTagReportsList.styles';

const POLLING_REPORTS_TIME_IN_MS = 15_000;

interface DeepTagReportsListProps extends RoutedComponentProps {
  dispatch: Dispatch;
  reports?: DeepTagReport[];
  shopId: number | undefined;
}

const EmptyListState = (): JSX.Element => {
  return (
    <EmptyListStateStyled>
      <TypographyStyled variant={TypographyVariant.ExtraSmallBold} type={TypographyType.Heading}>
        No report found
      </TypographyStyled>
      <DeepTagReportHowToUploadTrigger />
    </EmptyListStateStyled>
  );
};

export const DeepTagReportsList = ({
  dispatch,
  reports,
  shopId,
  permittedRouteMap,
}: DeepTagReportsListProps): JSX.Element => {
  const pollingTimeoutId = useRef<number | null>(null);

  const fetchReports = useCallback(() => {
    if (shopId) {
      dispatch(deepTagReportsListActions.getReports({ shopId }));
    }
  }, [shopId]);

  const pollReports = useCallback(() => {
    fetchReports();
    pollingTimeoutId.current = window.setTimeout(pollReports, POLLING_REPORTS_TIME_IN_MS);
  }, [fetchReports]);

  useEffect(() => {
    pollReports();

    return () => {
      if (pollingTimeoutId.current) {
        clearTimeout(pollingTimeoutId.current);
      }
    };
  }, [pollReports, fetchReports]);

  useEffect(() => {
    return () => {
      dispatch(deepTagReportsListActions.resetReports());
    };
  }, []);

  const reportsItems = useMemo(() => {
    return shopId
      ? (reports || []).map(report => {
          return (
            <DeepTagReportsListItem
              key={report.id}
              dispatch={dispatch}
              report={report}
              permittedRouteMap={permittedRouteMap}
              shopId={shopId}
            />
          );
        })
      : [];
  }, [reports, shopId]);

  const hasNoReports = reports?.length === 0;

  if (!reports) {
    return <SkeletonVerticalBlocks itemProps={{ height: 96, width: 912 }} spacing={2.5} />;
  }

  return (
    <DeepTagReportsListStyled>
      {hasNoReports && shopId ? <EmptyListState /> : reportsItems}
    </DeepTagReportsListStyled>
  );
};
