import { Dispatch, Store } from 'redux';
import { toastActions } from 'src/containers';
import { AppNotification } from 'src/app-types';
import { specificErrorActions, toastTypesRegex } from './contants';
import { AnyErrorAction, AnySuccessAction } from './types';
import { successActionsHandler } from './success-actions-handler';
import { errorActionsHandler } from './error-actions-handler';

export const toastMiddleware =
  (store: Store) =>
  (next: Dispatch) =>
  (action: AnyErrorAction | AnySuccessAction): void => {
    try {
      if (toastTypesRegex.error.test(action.type) || specificErrorActions.has(action.type)) {
        errorActionsHandler(action as AnyErrorAction, store);
      } else if (toastTypesRegex.success.test(action.type)) {
        successActionsHandler(action as AnySuccessAction, store);
      } else if (toastTypesRegex.toastNotification.test(action.type)) {
        const { payload } = action as unknown as { payload: AppNotification };
        store.dispatch(
          toastActions.enqueue({
            toast: {
              messageKey: payload.messageKey,
              type: payload.type,
              timestamp: payload.timestamp || new Date().getTime(),
              customMessage: payload.customMessage,
            },
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      next(action);
    }
  };
