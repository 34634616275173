export interface ShopDataField {
  id: string;
  name: string;
  displayName: string;
  format: ShopDataFieldFormat;
  types: FeedDataFieldType[];
  sample: string;
  supportedFeatures: Array<ShopDataFieldsSupportedFeatures>;
  catalogNames: string[];
  fieldType: FieldType;
  textSearchFieldNames: string[];
}

export enum ShopDataFieldsSupportedFeatures {
  MerchandisingRules = 'merchandisingRules',
  RankingStrategy = 'rankingStrategy',
  Filters = 'filters',
  Sorting = 'sorting',
  Boosting = 'boosting',
}

export enum ShopDataFieldFormat {
  String = 'string',
  StringArray = 'stringArray',
  Numeric = 'numeric',
  Boolean = 'boolean',
  Date = 'date',
}

export enum FeedDataFieldType {
  Text = 'text',
  Keyword = 'keyword',
  Integer = 'integer',
  Long = 'long',
  Float = 'float',
  Boolean = 'boolean',
}

export enum FieldType {
  AiTag = 'ai_tag',
  CatalogField = 'catalog_field',
}

export interface FeedDataFieldValuesResult {
  values: Record<string, string>[];
  after: Record<string, string>;
  uniqueByField: string;
}

export interface FeedProductsResponse {
  products: Record<string, unknown>[];
  uniqueByField: string;
}
