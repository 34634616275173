import { AppDispatch } from 'src/app-types';

export enum StoriesContainerActionTypes {
  NavigateTo = 'StoriesContainerActions/NavigateTo',
}

export interface StoriesContainerActionsNavigateToActionPayload {
  navigateTo: string;
}

export interface StoriesContainerActionsNavigateToAction {
  type: typeof StoriesContainerActionTypes.NavigateTo;
  payload: StoriesContainerActionsNavigateToActionPayload;
}

export type StoriesContainerAction = StoriesContainerActionsNavigateToAction;

const storiesContainerActions = {
  navigateTo(
    payload: StoriesContainerActionsNavigateToActionPayload
  ): StoriesContainerActionsNavigateToAction {
    return {
      type: StoriesContainerActionTypes.NavigateTo,
      payload,
    };
  },
};

export const storiesContainerActionMethods = {
  navigateTo(payload: StoriesContainerActionsNavigateToActionPayload) {
    return (dispatch: AppDispatch): void => {
      dispatch(storiesContainerActions.navigateTo(payload));
    };
  },
};
