import React from 'react';
import {
  AvailableIcons,
  Icon,
  Link,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { SectionHeaderStyled } from './AugSearch.styles';

export function AugmentedSearchFiltersSectionHeader({ title }: { title?: string }): JSX.Element {
  const suffix = title ? ` - ${title}` : '';
  return (
    <SectionHeaderStyled>
      <Typography type={TypographyType.Heading} variant={TypographyVariant.ExtraSmallBold}>
        Filters Management{suffix}
      </Typography>
      <Link href='https://support.syte.ai/space/ET/2697888341/Augmented+Search+Filter+Management'>
        Learn more about Filters
        <Icon name={AvailableIcons.ArrowRec} />
      </Link>
    </SectionHeaderStyled>
  );
}
