/* eslint-disable react/no-unescaped-entities */
import React, { useCallback, useMemo } from 'react';
import { Dispatch } from 'src/components-bl/types';
import {
  MenuItem,
  Select,
  SelectType,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { CodeSectionProps } from 'src/components-dummy/CodeSection';
import {
  SyteProductPlacement,
  syteProductPlacementToDisplayName,
} from 'src/services/src/service/types/syte-products';
import {
  CodeSectionStyled,
  ImplementationSectionStyled,
  LinkStyled,
  MainTypographyStyled,
  SubTypographyStyled,
} from './ImplementationSection.styles';
import { implementationSectionActions } from './Actions';

interface ImplementationSectionProps {
  galleryId: string;
  galleryTitle: string | undefined;
  placement?: SyteProductPlacement;
  onPlacementChange: (newPlacement: SyteProductPlacement) => void;
  dispatch: Dispatch;
}

const guidelinesLink = 'https://support.syte.ai/space/ET/3149037814';

export const ImplementationSection = ({
  galleryId,
  galleryTitle = '',
  dispatch,
  placement,
  onPlacementChange,
}: ImplementationSectionProps): JSX.Element => {
  const codeSnippetParts: CodeSectionProps['codeParts'] = useMemo(() => {
    return [
      { text: '<div ', color: 'green' },
      { text: 'class="', color: 'pink' },
      { text: 'syte-shopper-experiences-galleries', color: 'blue' },
      { text: '" data-gallery-id="', color: 'pink' },
      { text: galleryId, color: 'blue' },
      { text: '" data-title="', color: 'pink' },
      { text: galleryTitle, color: 'blue' },
      { text: '" data-placement="', color: 'pink' },
      { text: `${placement || ''}`, color: 'blue' },
      { text: '"', color: 'pink' },
      { text: ' ></div> ', color: 'green' },
    ];
  }, [galleryId, galleryTitle, placement]);

  const onCopySnippetClick = useCallback(() => {
    dispatch(
      implementationSectionActions.notification({ customMessage: 'Snippet copied to clipboard' })
    );
  }, [dispatch]);

  const onSelectChange = useCallback(
    e => {
      onPlacementChange(e.target.value);
    },
    [onPlacementChange]
  );

  return (
    <ImplementationSectionStyled>
      <MainTypographyStyled
        variant={TypographyVariant.MediumRegular}
        type={TypographyType.Paragraph}
      >
        To add your custom gallery to your website, copy and paste the following code into your web
        page's code.
      </MainTypographyStyled>

      <LinkStyled href={guidelinesLink}>Learn More</LinkStyled>

      <Select
        type={SelectType.Primary}
        value={placement}
        onChange={onSelectChange}
        label='Select gallery placement'
      >
        {Object.values(SyteProductPlacement).map(key => (
          <MenuItem key={key} value={key}>
            {syteProductPlacementToDisplayName[key]}
          </MenuItem>
        ))}
      </Select>

      <CodeSectionStyled codeParts={codeSnippetParts} enableCopy onCopyClick={onCopySnippetClick} />

      <SubTypographyStyled variant={TypographyVariant.SmallRegular} type={TypographyType.Paragraph}>
        Ensure that Syte snippet, implemented in your website, before adding the gallery code -
        checkout our <LinkStyled href={guidelinesLink}>guidelines</LinkStyled>
      </SubTypographyStyled>
    </ImplementationSectionStyled>
  );
};
