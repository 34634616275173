import React from 'react';
import { CaretIconStyled, PlaceholderTypography, TextBoxBaseStyled } from './styles';
import { SalesforceAccount } from 'src/services/src/service/salesforce/types';
import { NO_SALESFORCE_ID } from '../../constants';
import { AccountPreview } from './AccountPreview';
import { AvailableIcons } from 'src/components-dummy';

interface AccountTextBoxProps {
  disabled: boolean;
  error: string;
  account?: SalesforceAccount;
}

export const AccountTextBox = ({ disabled, error, account }: AccountTextBoxProps): JSX.Element => {
  const showNoSalesForceId = disabled && (!account || !account.salesforceAccountId);

  return (
    <TextBoxBaseStyled label='Salesforce account ID' fullWidth disabled={disabled} error={error}>
      {showNoSalesForceId ? (
        <PlaceholderTypography>{NO_SALESFORCE_ID}</PlaceholderTypography>
      ) : (
        <AccountPreview account={account} />
      )}
      <CaretIconStyled name={AvailableIcons.SelectCaret} />
    </TextBoxBaseStyled>
  );
};
