import { MenuProps } from 'src/components-dummy';

export const anchorOriginDefault: MenuProps['anchorOrigin'] = {
  vertical: 'bottom',
  horizontal: 0,
};

export const transformOriginDefault: MenuProps['transformOrigin'] = {
  vertical: -4,
  horizontal: 'left',
};
