import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TableV2 } from 'src/components-dummy';
import { ParseJoiValidateResponse } from 'src/utils';
import { columnsArray } from './tableColumns';
import { IValuesTableBodyRow } from './types';
import { CustomOrderValue, FilterDraft } from '../../../../types';
import { TableHeaderStyled, TableWrapperStyled } from './ValuesTable.styles';
import { ValueTableRow } from './components';

interface ValuesTableProps {
  values: CustomOrderValue[];
  onChange: (updatedValues: CustomOrderValue[]) => void;
  shopId: number;
  selectedSourceField: string;
  errors: ParseJoiValidateResponse<Omit<FilterDraft, 'id'>>;
}

export const ValuesTable = ({
  values,
  onChange,
  shopId,
  selectedSourceField,
}: ValuesTableProps): JSX.Element => {
  const [draggedAndDroppedValues, setDraggedAndDroppedValues] = useState([...values]);
  const selectedValues = useMemo(
    () => values.map(option => option.value).filter(value => !!value) as string[],
    [values]
  );

  const tableData: IValuesTableBodyRow[] = useMemo(() => {
    return draggedAndDroppedValues.map(customValue => {
      const { id, value } = customValue;
      return {
        id,
        value,
        sourceField: selectedSourceField,
      };
    });
  }, [draggedAndDroppedValues, selectedSourceField]);

  const onRemoveRow = useCallback(
    (id: string) => {
      const updatedValues = values.filter(customValue => customValue.id !== id);

      onChange(updatedValues);
    },
    [onChange, values]
  );

  const onAddNewValues = useCallback(
    (newValues: CustomOrderValue[]) => {
      onChange(newValues);
    },
    [onChange]
  );

  const onRowDrag = useCallback(
    (sourceIndex: number, targetIndex: number) => {
      setDraggedAndDroppedValues(previousValues => {
        const rearrangedValues = [...previousValues];
        const elementToMove = rearrangedValues[sourceIndex];

        rearrangedValues.splice(sourceIndex, 1);
        rearrangedValues.splice(targetIndex, 0, elementToMove);

        return rearrangedValues;
      });
    },
    [setDraggedAndDroppedValues]
  );

  const onRowDrop = useCallback(() => {
    onChange(draggedAndDroppedValues);
  }, [draggedAndDroppedValues, onChange]);

  useEffect(() => {
    setDraggedAndDroppedValues(values);
  }, [values, setDraggedAndDroppedValues]);

  return (
    <TableWrapperStyled>
      <TableV2<IValuesTableBodyRow> options={{ columns: columnsArray, data: tableData }}>
        {({ getTableBodyProps, headerGroups, rows, prepareRow }) => {
          const headerGroup = headerGroups[0];

          return (
            <>
              <TableHeaderStyled {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <TableV2.HeadRow
                    {...column.getHeaderProps()}
                    width={columnsArray[index].width}
                    minWidth={columnsArray[index].minWidth}
                    key={column.id}
                  >
                    <TableV2.HeadRowCellText>{column.render('Header')}</TableV2.HeadRowCellText>
                  </TableV2.HeadRow>
                ))}
              </TableHeaderStyled>
              <TableV2.Body {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);

                  return (
                    <ValueTableRow
                      key={row.original.id}
                      row={row}
                      onDeleteRow={onRemoveRow}
                      onAddNewValues={onAddNewValues}
                      shopId={shopId}
                      onRowDrag={onRowDrag}
                      onRowDrop={onRowDrop}
                      selectedValues={selectedValues}
                    />
                  );
                })}
              </TableV2.Body>
            </>
          );
        }}
      </TableV2>
    </TableWrapperStyled>
  );
};
