import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ButtonStyled = styled.div<{ isActive: boolean; isVisible: boolean }>`
  display: flex;
  padding: 8px;
  border-radius: 4px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  cursor: pointer;

  ${({ isActive, theme }) =>
    isActive &&
    css`
      background: ${theme.palette.custom['gray-70']};
    `}
`;
