import React, { useMemo } from 'react';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy/Typography';
import {
  PageSideNavContainerStyled,
  PageSideNavGroupDivider,
  PageSideNavListStyled,
  PageSideNavTitleStyled,
} from './components/PageSideNav/PageSideNav.styled';
import { PageSideNavGroup, RouteGroup } from './components/PageSideNav/PageSideNavGroup';

interface PageSideNavProps {
  pageTitle?: string;
  routeGroups: RouteGroup[];
  handleNavigate: (url: string) => void;
}

export function PageSideNav({
  routeGroups,
  pageTitle,
  handleNavigate,
}: PageSideNavProps): JSX.Element {
  const populatedRouteGroups = useMemo(
    () => routeGroups.filter(routeGroup => routeGroup.routes.length > 0),
    [routeGroups]
  );

  return (
    <PageSideNavContainerStyled>
      {pageTitle && (
        <PageSideNavTitleStyled>
          <Typography variant={TypographyVariant.MediumBold} type={TypographyType.Body}>
            {pageTitle}
          </Typography>
        </PageSideNavTitleStyled>
      )}
      <PageSideNavListStyled>
        {pageTitle && <PageSideNavGroupDivider />}
        {populatedRouteGroups.map((routeGroup, index) => (
          <PageSideNavGroup
            key={index.toString()}
            isFinalGroup={index === populatedRouteGroups.length - 1}
            routeGroup={routeGroup}
            handleNavigate={handleNavigate}
          />
        ))}
      </PageSideNavListStyled>
    </PageSideNavContainerStyled>
  );
}
