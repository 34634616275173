/* eslint-disable react/no-unescaped-entities */
import { css } from '@emotion/react';
import React, { useCallback, useState } from 'react';
import {
  MenuItem,
  Select,
  SelectType,
  TypographyType,
  TypographyVariant,
  SelectOnChangeEvent,
  Typography,
} from 'src/components-dummy';
import { genderTypes } from 'src/services/src/service/types/catalog-manager';
import { Box } from '@mui/material';
import { CatalogFormField } from '../CatelogFormField/CatalogFormField';
import { CatalogFormSection } from '../CatalogFormSection/CatalogFormSection';
import { ErrorLabelStyled, InfoStyled } from '../../CatalogForm.styles';
import { CatalogFormDraft, genderTypeToText } from '../../CatalogForm.config';

const genderTypesListWithoutHD = Object.keys(genderTypes).filter(
  gender => gender !== genderTypes.hd
);

export interface CatalogFormGendersAndLocaleSectionProps {
  onFieldChange: (partialData: Pick<CatalogFormDraft, 'genders'>) => void;
  errors?: any;
  genders: string[];
}

export const CatalogFormGendersAndLocaleSection = React.memo(
  ({ genders, onFieldChange, errors }: CatalogFormGendersAndLocaleSectionProps): JSX.Element => {
    const [gendersState, setVGendersState] = useState(genders);
    const errorMessage = errors?.message;

    const onGenderSelect: SelectOnChangeEvent = useCallback(
      event => {
        const newGendersState = event.target.value;

        setVGendersState([...newGendersState]);
        onFieldChange({ genders: [...newGendersState] });
      },
      [onFieldChange, gendersState]
    );

    return (
      <CatalogFormSection>
        <CatalogFormSection.Header>Genders and Locale</CatalogFormSection.Header>

        {errorMessage ? <ErrorLabelStyled> {errorMessage} </ErrorLabelStyled> : null}
        <Box sx={{ maxWidth: '550px', marginBottom: '16px' }}>
          <Typography
            type={TypographyType.Body}
            variant={TypographyVariant.MediumBold}
            css={css`
              margin-top: 40px;
              display: inline-block;
            `}
          >
            What gender groups are your products aimed at?
          </Typography>
          <InfoStyled
            css={css`
              margin-top: 6px;
              margin-bottom: 16px;
            `}
            type={TypographyType.Paragraph}
            variant={TypographyVariant.SmallRegular}
          >
            Specifying the gender group your products cater to can help the Syte recommend more
            relevant products to your customers
          </InfoStyled>
        </Box>
        <CatalogFormField
          css={css`
            margin-top: 6px;
          `}
        >
          <Select
            type={SelectType.Primary}
            placeholder='Select gender'
            onChange={onGenderSelect}
            value={genders}
            multiple
          >
            {genderTypesListWithoutHD.map((option: string) => (
              <MenuItem key={option} value={option} text={(genderTypeToText as any)[option]}>
                {(genderTypeToText as any)[option]}
              </MenuItem>
            ))}
          </Select>
        </CatalogFormField>
      </CatalogFormSection>
    );
  }
);
