import React, { useCallback, useRef, useState } from 'react';
import { Dispatch } from 'src/components-bl/types/dispatch';
import { TableV2, TableRow, TableV2InstanceProps } from 'src/components-dummy/TableV2';
import { isEqual } from 'lodash';
import { Checkbox } from 'src/components-dummy/CheckBox';
import { Box } from 'src/components-dummy/Box';
import { ILexiconItem, ILexiconItemBaseAPI, LexiconTagField } from 'src/services/src/service/types';
import {
  LexiconTagType,
  RequestUpdateOneLexiconBody,
  UpdateLexiconRuleArguments,
  UpdateThematicTagArguments,
} from 'src/services/src/service/lexicon/types';
import { ILexiconTagsFilters, ILexiconTagsPaginationAPI } from 'src/services';
import { BackDropWithLoader } from 'src/components-dummy/Backdrop/BackdropWithLoader';
import ReactDOM from 'react-dom';
import { useMountRef } from 'src/components-dummy/hooks/use-mount-ref';
import { generatePath } from 'react-router-dom';
import { ILexiconTableBodyRow, IReplaceTag } from '../types';
import { LexiconItemRowActionsMenu } from './LexiconItemActionsMenu';
import { ToggleOffConfirmationDialog } from './ToggleOffConfirmationDialog';
import { RestoreConfirmationDialog } from './RestoreTagConfirmationDialog';
import { MasterTagStyled, TableBodyRowStyled } from '../LexiconTable.styles';
import {
  mapLexiconItemVMToAPIModel,
  mapLexiconItemVMToAPIRestoredModel,
  mapLexiconTagUpdateToRequest,
  mapTableRowChangesText,
} from '../LexiconTable.helpers';
import { LexiconDataEditAPIRef, LexiconDataTextEdit } from './LexiconDataTextEdit';
import { lexiconTableActions } from '../LexiconTable.actions';
import {
  RenameConfirmationDialog,
  RenameTagConfirmationDialogResult,
} from './RenameTagConfirmationDialog';
import { lexiconPageHeaderActions } from '../../LexiconPageHeader';
import { LexiconStatusText } from './LexiconStatusText';
import { ToggleDeleteTagConfirmationDialog } from './ToggleDeleteTagConfirmationDialog';
import { LexiconTableColumnType } from '../LexiconTable.config';

/**
 * Helpers
 */

const buildTagFilterByTagNameAndValueForBulkRename = ({
  tagFieldName,
  tagFieldFilterValue,
}: {
  tagFieldName: LexiconTagField;
  tagFieldFilterValue: string;
}): Partial<
  Pick<
    ILexiconTagsFilters,
    'categoriesTranslations' | 'attributesTranslations' | 'valuesTranslations'
  >
> => {
  switch (tagFieldName) {
    case 'attribute':
      return { attributesTranslations: [tagFieldFilterValue] };
    case 'value':
      return { valuesTranslations: [tagFieldFilterValue] };
    case 'category':
    default:
      return { categoriesTranslations: [tagFieldFilterValue] };
  }
};

/**
 * Row that represent the lexicon items.
 * It contains multiple columns/cells:
 *  - "tag field" columns/cells for category, attribute and value translations.
 *     - Has inline editing.
 *     - While typing there is auto complete.
 *     - "Apply All" Dialog
 *        - If "Apply All" is checked, after editing a translation the "apply all" dialog appear.
 *        - Confirming will create a bulk operation to update all tags with same translation (Before edited), to the new translation
 *        - Rejecting will update only the edited tag
 *        - Closing the popup (clicking outside), will change the tag's text back to the original
 * - Master tag: shows the "key" of the tags. currently it uses default translation but should be keys from model. example : "Shirts:Color:Green"
 * - "Changes" :  shows if the item was changed : Turned off or Renamed (turn off if both)
 * - Action: menu that allows turning on/off an item or restore a tag back to its original data.
 * - Note currently the lexicon row is unmounted and then mounting when refetching data.
 */
interface RuleItemRowProps<T extends Record<string, any>> {
  row: TableRow<T>;
  shopId: number;
  locale: string;
  tagType: LexiconTagType;
  showApplyAll: boolean;
  dispatch: Dispatch;
  onSelect: ({ lexiconItemId, isSelected }: { lexiconItemId: string; isSelected: boolean }) => void;
  onItemChange: () => Promise<ILexiconTagsPaginationAPI>;
  editThematicTagRoute?: string;
  editLexiconRuleRoute?: string;
  isSyteAdmin: boolean;
}

export const LexiconItemRow = React.memo(
  ({
    row,
    onSelect,
    dispatch,
    shopId,
    locale,
    tagType,
    onItemChange,
    showApplyAll,
    editThematicTagRoute,
    editLexiconRuleRoute,
    isSyteAdmin,
    tableColumns,
  }: RuleItemRowProps<ILexiconTableBodyRow> & {
    tableColumns: LexiconTableColumnType;
  }): JSX.Element => {
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | undefined>(undefined);
    const [showToggleOffConfirmDialog, setShowToggleOffConfirmDialog] = useState<boolean>(false);
    const [showRestoreConfirmDialog, setShowRestoreConfirmDialog] = useState<boolean>(false);
    const [showRenameApplyAllDialog, setShowRenameApplyAllDialog] = useState(false);
    const [showDeleteThematicConfirmDialog, setShowDeleteThematicConfirmDialog] = useState(false);
    const [showDeleteLexiconRuleConfirmDialog, setShowDeleteLexiconRuleConfirmDialog] =
      useState(false);
    const [renameBulkTag, setRenameBulkTag] = useState<IReplaceTag>({} as IReplaceTag);
    const [isInProcess, setIsInProcess] = useState(false);

    const lexiconFieldRef = useRef<LexiconDataEditAPIRef | null>();

    /** Currently when refetching tags after update, the rows become unmounted and setting locale (isInProcess) after the unmount throws error */
    const mountRef = useMountRef();

    const menuOpened = !!menuAnchorEl;

    const closeActionsMenu = () => setMenuAnchorEl(undefined);

    const onShowApplyAll = (checked: boolean) => {
      dispatch(lexiconPageHeaderActions.updateShowApplyAll({ shouldShow: checked }));
    };

    /** Ref to be able to reset the edited text when closing dialog */
    const onTagFieldEditModeChange = useCallback(
      ({ ref }: { ref: LexiconDataEditAPIRef | null }) => {
        lexiconFieldRef.current = ref;
      },
      []
    );

    const updateTag = useCallback(
      ({
        tagKey,
        tagToUpdate,
      }: {
        tagKey: string;
        tagToUpdate: RequestUpdateOneLexiconBody;
      }): any => {
        return (dispatch as any)(
          lexiconTableActions.updateLexiconTag({
            shopId,
            locale,
            tagKey,
            tagToUpdate,
          })
        ).unwrap();
      },
      [shopId, locale, dispatch]
    );

    const restoreTag = useCallback(
      ({
        tagKey,
        tagToUpdate,
      }: {
        tagKey: string;
        tagToUpdate: RequestUpdateOneLexiconBody;
      }): any => {
        return (dispatch as any)(
          lexiconTableActions.restoreLexiconTag({
            shopId,
            locale,
            tagKey,
            tagToUpdate,
          })
        ).unwrap();
      },
      [shopId, locale, dispatch]
    );

    const toggleTag = useCallback(
      ({
        tagKey,
        tagToUpdate,
      }: {
        tagKey: string;
        tagToUpdate: RequestUpdateOneLexiconBody;
      }): any => {
        return (dispatch as any)(
          lexiconTableActions.toggleLexiconTag({
            shopId,
            locale,
            tagKey,
            tagToUpdate,
          })
        ).unwrap();
      },
      [shopId, locale, dispatch]
    );

    const updateThematicTag = useCallback(
      ({
        tagKey,
        partialTagToUpdate,
      }: {
        tagKey: string;
      } & Pick<UpdateThematicTagArguments, 'partialTagToUpdate'>): any => {
        return (dispatch as any)(
          lexiconTableActions.updateThematicTag({
            shopId,
            locale,
            id: tagKey,
            partialTagToUpdate,
          })
        ).unwrap();
      },
      [shopId, locale, dispatch]
    );

    const updateLexiconRule = useCallback(
      ({
        tagKey,
        partialRuleToUpdate,
      }: {
        tagKey: string;
      } & Pick<UpdateLexiconRuleArguments, 'partialRuleToUpdate'>): any => {
        return (dispatch as any)(
          lexiconTableActions.updateLexiconRule({
            shopId,
            locale,
            id: tagKey,
            partialRuleToUpdate,
          })
        ).unwrap();
      },
      [shopId, locale, dispatch]
    );

    const toggleThematicTag = useCallback(
      ({
        tagKey,
        partialTagToUpdate,
      }: {
        tagKey: string;
      } & Pick<UpdateThematicTagArguments, 'partialTagToUpdate'>): any => {
        return (dispatch as any)(
          lexiconTableActions.toggleThematicTag({
            shopId,
            locale,
            id: tagKey,
            partialTagToUpdate,
          })
        ).unwrap();
      },
      [shopId, locale, dispatch]
    );

    const toggleLexiconRule = useCallback(
      ({
        tagKey,
        partialRuleToUpdate,
      }: {
        tagKey: string;
      } & Pick<UpdateLexiconRuleArguments, 'partialRuleToUpdate'>): any => {
        return (dispatch as any)(
          lexiconTableActions.toggleLexiconRule({
            shopId,
            locale,
            id: tagKey,
            partialRuleToUpdate,
          })
        ).unwrap();
      },
      [shopId, locale, dispatch]
    );

    /** Rename callbacks */
    const renameTagField = useCallback(
      async ({
        tagFieldName,
        tagFieldNewValue,
      }: {
        tagFieldName: LexiconTagField;
        tagFieldNewValue: string;
      }) => {
        switch (tagType) {
          case LexiconTagType.ThematicTags: {
            try {
              setIsInProcess(true);

              await updateThematicTag({
                partialTagToUpdate: {
                  [tagFieldName]: {
                    customTranslation: tagFieldNewValue,
                  },
                },
                tagKey: row.original.originalData.tagKey,
              });

              await onItemChange();
            } finally {
              if (!mountRef.current.hasUnmounted) {
                setIsInProcess(false);
              }
            }
            break;
          }
          case LexiconTagType.RenameRules: {
            try {
              setIsInProcess(true);

              await updateLexiconRule({
                partialRuleToUpdate: {
                  [tagFieldName]: {
                    // TODO missing information here, such as categoryKey, attributeKey, valueKey
                    customTranslation: tagFieldNewValue,
                  },
                },
                tagKey: row.original.originalData.tagKey,
              });

              await onItemChange();
            } finally {
              if (!mountRef.current.hasUnmounted) {
                setIsInProcess(false);
              }
            }
            break;
          }
          case LexiconTagType.LexiconTags:
          default: {
            const tagFieldItem = row.original.originalData[tagFieldName];

            const changedItem: ILexiconItemBaseAPI = {
              ...tagFieldItem,
              customTranslation: tagFieldNewValue,
            };

            const rowChanged: ILexiconItem = {
              ...row.original.originalData,
              [tagFieldName]: changedItem,
            };

            const mappedItem = mapLexiconTagUpdateToRequest(rowChanged);

            try {
              setIsInProcess(true);

              await updateTag({
                tagToUpdate: mappedItem,
                tagKey: row.original.originalData.tagKey,
              });

              await onItemChange();
            } finally {
              if (!mountRef.current.hasUnmounted) {
                setIsInProcess(false);
              }
            }
            break;
          }
        }
      },
      [
        mountRef,
        updateTag,
        updateThematicTag,
        row.original.originalData,
        setIsInProcess,
        onItemChange,
        tagType,
      ]
    );

    // After editing the tag field translation / blur, show the apply all dialog.
    // The dialog also requires the number of tags that will be affected
    const onTagFieldChanged = useCallback(
      async ({
        tagFieldName,
        tagFieldNewValue,
      }: {
        tagFieldName: LexiconTagField;
        tagFieldNewValue: string;
      }) => {
        if (!showApplyAll) {
          renameTagField({ tagFieldName, tagFieldNewValue });
          return;
        }

        const originalValue = row.original.originalData[tagFieldName].translation;

        const tagFieldTranslationFilter = buildTagFilterByTagNameAndValueForBulkRename({
          tagFieldName,
          tagFieldFilterValue: originalValue,
        });

        const renameDialogData: IReplaceTag = {
          value: tagFieldNewValue,
          tagField: tagFieldName,
          originValue: originalValue,
          changesCount: undefined,
        };

        setRenameBulkTag(renameDialogData);

        setShowRenameApplyAllDialog(true);

        // TODO: need API that returns only total count, without
        try {
          const tagsSummary = (await (
            dispatch(
              lexiconTableActions.getLexiconTagsSummary({
                shopId,
                locale,
                tagType,
                filters: tagFieldTranslationFilter,
              })
            ) as any
          ).unwrap()) as ReturnType<
            typeof lexiconTableActions.getLexiconTagsSummary.fulfilled
          >['payload'];

          // TODO: use abortcontroller instead of useMountRef
          if (!mountRef.current.hasUnmounted) {
            setRenameBulkTag({
              ...renameDialogData,
              changesCount: tagsSummary.totalTags,
            } as IReplaceTag);
          }
        } catch (error) {
          console.error(error);
        }
      },
      [
        dispatch,
        mountRef,
        shopId,
        renameTagField,
        showApplyAll,
        locale,
        tagType,
        row.original.originalData,
        setRenameBulkTag,
        setShowRenameApplyAllDialog,
      ]
    );

    const onDialogConfirmBulkRename = async ({
      shouldShowApplyAllDialogOnEdit,
      tagFieldName,
      tagFieldValue,
    }: RenameTagConfirmationDialogResult) => {
      setShowRenameApplyAllDialog(false);

      if (!shouldShowApplyAllDialogOnEdit) {
        onShowApplyAll(false);
      }

      const tagItemFilter = buildTagFilterByTagNameAndValueForBulkRename({
        tagFieldName,
        tagFieldFilterValue: row.original.originalData[tagFieldName].translation,
      });

      setIsInProcess(true);

      try {
        await dispatch(
          lexiconTableActions.updateLexiconBulkTag({
            shopId,
            locale,
            tagType,
            customTranslation: tagFieldValue,
            tagField: tagFieldName,
            excludeTags: [],
            filters: tagItemFilter,
          })
        );

        await onItemChange();
      } finally {
        if (!mountRef.current.hasUnmounted) {
          setIsInProcess(false);
        }
      }
    };

    // When rejecting bulk rename, updating only the edited tag
    const onDialogRejectBulkRename = ({
      shouldShowApplyAllDialogOnEdit: shouldPromptBulkDialog,
      tagFieldName,
      tagFieldValue,
    }: RenameTagConfirmationDialogResult) => {
      setShowRenameApplyAllDialog(false);

      if (!shouldPromptBulkDialog) {
        onShowApplyAll(false);
      }

      renameTagField({ tagFieldName, tagFieldNewValue: tagFieldValue });
    };

    const onTriggerClick = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setMenuAnchorEl(event.currentTarget);
    };

    const onMenuClose = useCallback((event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      closeActionsMenu();
    }, []);

    // When closing (click outside) the rename dialog without confirm/reject, revert back to original translation
    const onCloseRenameApplyAllDialog = useCallback(() => {
      setShowRenameApplyAllDialog(false);
      lexiconFieldRef.current?.reset();
    }, [setShowRenameApplyAllDialog]);

    /** Restore callback */
    const onRestore = useCallback(
      async event => {
        event.stopPropagation();
        closeActionsMenu();
        setShowRestoreConfirmDialog(false);

        const item = row.original.originalData;
        const itemAPIModel = mapLexiconItemVMToAPIRestoredModel(item);

        setIsInProcess(true);

        try {
          await restoreTag({ tagToUpdate: itemAPIModel, tagKey: item.tagKey });
          await onItemChange();
        } finally {
          if (!mountRef.current.hasUnmounted) {
            setIsInProcess(false);
          }
        }
      },
      [row.original.originalData.tagKey, onItemChange]
    );

    const onEditThematicTag = useCallback(
      event => {
        event.stopPropagation();
        closeActionsMenu();
        setShowRestoreConfirmDialog(false);

        if (editThematicTagRoute) {
          dispatch(
            lexiconTableActions.navigateTo({
              navigateTo: generatePath(editThematicTagRoute, {
                locale,
                id: row.original.originalData.tagKey,
                shopId,
              }),
            })
          );
        }
      },
      [row.original.originalData.tagKey, shopId, locale]
    );

    const onEditLexiconRuleTag = useCallback(
      event => {
        event.stopPropagation();
        closeActionsMenu();
        setShowRestoreConfirmDialog(false);

        if (editLexiconRuleRoute) {
          dispatch(
            lexiconTableActions.navigateTo({
              navigateTo: generatePath(editLexiconRuleRoute, {
                locale,
                id: row.original.originalData.tagKey,
                shopId,
              }),
            })
          );
        }
      },
      [dispatch, editLexiconRuleRoute, row.original.originalData.tagKey, shopId, locale]
    );

    const onDeleteThematicTag = useCallback(
      async event => {
        event.stopPropagation();
        closeActionsMenu();
        setShowRestoreConfirmDialog(false);

        setIsInProcess(true);

        try {
          await (dispatch as any)(
            lexiconTableActions.deleteThematicTag({
              shopId,
              locale,
              tagKey: row.original.originalData.tagKey,
            })
          ).unwrap();
          await onItemChange();
        } finally {
          if (!mountRef.current.hasUnmounted) {
            setIsInProcess(false);
          }
        }
      },
      [row.original.originalData.tagKey, onItemChange]
    );

    const onDeleteLexiconRule = useCallback(
      async event => {
        event.stopPropagation();
        closeActionsMenu();
        setShowRestoreConfirmDialog(false);

        setIsInProcess(true);

        try {
          await (dispatch as any)(
            lexiconTableActions.deleteLexiconRule({
              shopId,
              locale,
              tagKey: row.original.originalData.tagKey,
            })
          ).unwrap();
          await onItemChange();
        } finally {
          if (!mountRef.current.hasUnmounted) {
            setIsInProcess(false);
          }
        }
      },
      [dispatch, shopId, locale, row.original.originalData.tagKey, onItemChange]
    );

    /**  Toggles callbacks */
    const onToggleOff = useCallback(
      async event => {
        event.stopPropagation();
        closeActionsMenu();
        setShowToggleOffConfirmDialog(false);

        const updatedItem: ILexiconItem = {
          ...row.original.originalData,
          include: !row.original.originalData.include,
        };

        const itemAPIModel = mapLexiconItemVMToAPIModel(updatedItem);

        setIsInProcess(true);

        switch (tagType) {
          case LexiconTagType.ThematicTags: {
            try {
              await toggleThematicTag({
                partialTagToUpdate: { include: !row.original.originalData.include },
                tagKey: row.original.originalData.tagKey,
              });
              await onItemChange();
            } finally {
              if (!mountRef.current.hasUnmounted) {
                setIsInProcess(false);
              }
            }

            break;
          }
          case LexiconTagType.RenameRules: {
            try {
              await toggleLexiconRule({
                partialRuleToUpdate: { include: !row.original.originalData.include },
                tagKey: row.original.originalData.tagKey,
              });
              await onItemChange();
            } finally {
              if (!mountRef.current.hasUnmounted) {
                setIsInProcess(false);
              }
            }
            break;
          }
          case LexiconTagType.LexiconTags:
          default: {
            try {
              await toggleTag({
                tagToUpdate: itemAPIModel,
                tagKey: row.original.originalData.tagKey,
              });
              await onItemChange();
            } finally {
              if (!mountRef.current.hasUnmounted) {
                setIsInProcess(false);
              }
            }
          }
        }
      },
      [row.original.originalData.tagKey, updateThematicTag, toggleLexiconRule, tagType]
    );

    const onToggleOffConfirmDialog = () =>
      setShowToggleOffConfirmDialog(state => {
        closeActionsMenu();
        return !state;
      });

    const onToggleRestoreConfirmDialog = () =>
      setShowRestoreConfirmDialog(state => {
        closeActionsMenu();
        return !state;
      });

    const onToggleDeleteThematicTagConfirmDialog = () =>
      setShowDeleteThematicConfirmDialog(state => {
        closeActionsMenu();
        return !state;
      });

    const onToggleDeleteLexiconRuleConfirmDialog = () =>
      setShowDeleteLexiconRuleConfirmDialog(state => {
        closeActionsMenu();
        return !state;
      });

    const renderRowDataContent = useCallback(
      (cell: TableV2InstanceProps<ILexiconTableBodyRow>['rows'][0]['cells'][0]) => {
        const propName = cell.column.id;
        const cellValue = cell.value;
        const { isThematic, isLexiconRule } = row.original.originalData;

        switch (propName) {
          case tableColumns.actions.accessor:
            return (
              <LexiconItemRowActionsMenu
                isMenuOpened={!!menuOpened}
                menuAnchorElement={menuAnchorEl}
                onTriggerClick={onTriggerClick}
                onMenuClose={onMenuClose}
                onToggleOff={onToggleOffConfirmDialog}
                isThematic={isThematic}
                isOn={row.original.changes.isOn}
                onEditThematicTag={onEditThematicTag}
                onDeleteThematicTag={onToggleDeleteThematicTagConfirmDialog}
                isLexiconRule={isLexiconRule}
                onEditLexiconRule={onEditLexiconRuleTag}
                onDeleteLexiconRule={onToggleDeleteLexiconRuleConfirmDialog}
                onRestore={
                  row.original.changes.hasRenamed || !row.original.changes.isOn
                    ? onToggleRestoreConfirmDialog
                    : undefined
                }
                isSyteAdmin={isSyteAdmin}
              />
            );
          case tableColumns.selected.accessor:
            return (
              <Checkbox
                checked={row.original.selected}
                onChange={(isChecked: boolean) => {
                  onSelect({
                    lexiconItemId: row.original.originalData.tagKey,
                    isSelected: isChecked,
                  });
                }}
              />
            );
          case tableColumns.category.accessor:
          case tableColumns.attribute.accessor:
          case tableColumns.value.accessor: {
            const tagFieldName = propName as LexiconTagField;
            const tagFieldValue = cell.row.original.originalData[tagFieldName].translation;

            const isOriginallyTurnedOn = cell.row.original.originalData.include === true;

            return (
              <Box
                key={cell.column.id + cell.row.id}
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                <LexiconDataTextEdit
                  shopId={shopId}
                  locale={locale}
                  tagType={tagType}
                  tagFieldName={tagFieldName}
                  tagFieldValue={tagFieldValue}
                  dispatch={dispatch}
                  hasChanged={!row.original.originalData.isThematic && cellValue.hasChanged}
                  isEditable={isOriginallyTurnedOn}
                  onChange={onTagFieldChanged}
                  onEditModeChange={onTagFieldEditModeChange}
                />
              </Box>
            );
          }
          case tableColumns.masterTag.accessor: {
            return (
              <MasterTagStyled>
                <TableV2.BodyRowCellText key={cell.column.id}>{cellValue}</TableV2.BodyRowCellText>
              </MasterTagStyled>
            );
          }
          case tableColumns.changes.accessor: {
            const changesValue: ILexiconTableBodyRow['changes'] = cellValue;

            const isOriginallyTurnedOn = row.original.originalData.include === true;
            const isTurnedOffAndOnlyInDraft =
              !row.original.originalData.include && isOriginallyTurnedOn;

            const text = mapTableRowChangesText({
              hasRenamed: changesValue.hasRenamed,
              include: row.original.originalData.include,
            });

            return (
              <Box key={cell.column.id} sx={{ display: 'flex', flexDirection: 'row' }}>
                <TableV2.BodyRowCellText>{text}</TableV2.BodyRowCellText>
                {isTurnedOffAndOnlyInDraft && <LexiconStatusText status='draft' />}
              </Box>
            );
          }
          default:
            return (
              <TableV2.BodyRowCellText key={cell.column.id}>{cellValue}</TableV2.BodyRowCellText>
            );
        }
      },
      [row, !!menuOpened, row.original.originalData]
    );

    return (
      <>
        {isInProcess && ReactDOM.createPortal(<BackDropWithLoader />, document.body)}
        {showDeleteThematicConfirmDialog && (
          <ToggleDeleteTagConfirmationDialog
            onConfirmedAction={onDeleteThematicTag}
            onClose={onToggleDeleteThematicTagConfirmDialog}
            tagType={LexiconTagType.ThematicTags}
          />
        )}
        {showDeleteLexiconRuleConfirmDialog && (
          <ToggleDeleteTagConfirmationDialog
            onConfirmedAction={onDeleteLexiconRule}
            onClose={onToggleDeleteLexiconRuleConfirmDialog}
            tagType={LexiconTagType.RenameRules}
          />
        )}
        {showRenameApplyAllDialog && (
          <RenameConfirmationDialog
            tagFieldName={renameBulkTag.tagField}
            tagFieldNewValue={renameBulkTag.value}
            tagFieldOriginalValue={renameBulkTag.originValue}
            changesCount={renameBulkTag.changesCount}
            onConfirmBulkRename={onDialogConfirmBulkRename}
            onRejectBulkRename={onDialogRejectBulkRename}
            onClose={onCloseRenameApplyAllDialog}
          />
        )}
        {showToggleOffConfirmDialog && (
          <ToggleOffConfirmationDialog
            onConfirmedAction={onToggleOff}
            onClose={onToggleOffConfirmDialog}
            isOn={row.original.originalData.include}
          />
        )}
        {showRestoreConfirmDialog && (
          <RestoreConfirmationDialog
            onConfirmedAction={onRestore}
            onClose={onToggleRestoreConfirmDialog}
          />
        )}
        <TableBodyRowStyled
          {...row.getRowProps()}
          key={row.original.originalData.tagKey}
          isOff={!row.original.originalData.include}
          isSelected={row.original.selected || row.original.originalData.hasUnpublishedChanges}
        >
          {row.cells.map(cell => {
            return (
              <TableV2.BodyRowCell
                {...cell.getCellProps()}
                className={cell.column.id}
                width={cell.column.width as string}
                minWidth={cell.column.minWidth}
                maxWidth={cell.column.maxWidth}
                key={cell.column.id}
              >
                {renderRowDataContent(cell)}
              </TableV2.BodyRowCell>
            );
          })}
        </TableBodyRowStyled>
      </>
    );
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps.row.original, nextProps.row.original);
  }
);
