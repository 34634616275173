import { UnpublishedPost } from '../../../../components-bl/socialDiscovery/types/entities.types';

export enum StateType {
  hasNoSelectedPosts = 'noSelectedPost',
  hasSelectedPosts = 'hasSelectedPost',
}

export enum ActionType {
  removePost = 'removePost',
  addPost = 'addPost',
  resetState = 'resetState',
}

export type RemovePostAction = {
  type: ActionType.removePost;
  payload: number;
};

export type AddPostAction = {
  type: ActionType.addPost;
  payload: number;
};

export type ResetStateAction = {
  type: ActionType.resetState;
};

export type SelectPostsAction = AddPostAction | RemovePostAction | ResetStateAction;

export type NoSelectionsState = { type: StateType.hasNoSelectedPosts; selectedPosts: null };
export type HasSelectionsState = { type: StateType.hasSelectedPosts; selectedPosts: Array<number> };

export type SelectPostsState = HasSelectionsState | NoSelectionsState;

export type ModerationModalReducer = (
  state: SelectPostsState,
  action: SelectPostsAction
) => SelectPostsState;

export interface BaseModerationModalProps {
  show: boolean;
  onSubmit: (selectedPosts: UnpublishedPost[]) => void;
  moderatedPosts: UnpublishedPost[];
}

export interface ModerationModalProps extends BaseModerationModalProps {
  onClose: () => void;
}
