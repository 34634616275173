import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { adminSearchModalActions } from 'src/components-bl/AdminSearchModal/AdminSearchModal.actions';
import { shopRoutesActions } from 'src/containers';
import { AccountReducerState } from '../app-state-types';
import {
  AccountListActionTypes,
  AccountListAction,
  AccountListSectionAction,
  AccountListSectionActionTypes,
  AccountCardActionTypes,
  AccountCardAction,
  CreateAccountFormAction,
  CreateAccountFormActionTypes,
  account2FAActions,
} from '../components-bl';
import { rootContainerActions } from '../root-container/Actions';

const defaultState: AccountReducerState = {
  current: undefined,
  accounts: [],
};

export const accountReducer = createReducer(defaultState, builder => {
  builder
    .addCase(shopRoutesActions.selectShopAndAccount, (state, action) => {
      return { ...state, current: action.payload.account };
    })
    .addCase(rootContainerActions.initApp.fulfilled, (state, action) => {
      const { account } = action.payload;

      return {
        ...state,
        current: account,
      };
    })
    .addCase(account2FAActions.updateAccount2Fa.fulfilled, (state, action) => {
      const { account } = action.payload;

      return {
        ...state,
        current: account,
      };
    })
    .addMatcher(
      isAnyOf(
        adminSearchModalActions.navigateToAccount.fulfilled,
        adminSearchModalActions.navigateToUser.fulfilled
      ),
      (state, action) => {
        const { account } = action.payload;

        return {
          ...state,
          current: account,
        };
      }
    )
    .addDefaultCase((state, toolkitAction) => {
      const action = toolkitAction as
        | AccountListAction
        | AccountListSectionAction
        | AccountCardAction
        | CreateAccountFormAction;
      switch (action.type) {
        case AccountListActionTypes.SelectAccount: {
          const { account } = action.payload;

          return {
            ...state,
            current: account,
          };
        }
        case AccountListSectionActionTypes.SearchAccountsSuccess: {
          const { accounts, isLoadMore } = action.payload;

          const updatedAccounts = isLoadMore ? [...state.accounts, ...accounts] : accounts;

          return {
            ...state,
            accounts: updatedAccounts,
          };
        }
        case AccountCardActionTypes.UpdateAccountStatusSuccess: {
          const updatedAccount = action.payload.account;
          const updatedAccounts = state.accounts.map(account => {
            if (account.accountId === updatedAccount.accountId) {
              return updatedAccount;
            }
            return account;
          });
          return { ...state, accounts: updatedAccounts };
        }
        case CreateAccountFormActionTypes.CreateAccountSuccess: {
          const updatedAccount = action.payload.account;
          const updatedAccounts = [updatedAccount, ...state.accounts];
          return { ...state, accounts: updatedAccounts };
        }
        default:
          return state;
      }
    });
});
