import React from 'react';
import {
  AvailableIcons,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { ProductCardPrice } from './ProductCardPrice';
import { SuggestedOffer } from '../../types';
import {
  ButtonGroup,
  Divider,
  Image,
  ImageContainer,
  ProductCardContainer,
  ProductContent,
  ProductDetails,
  ProductDetailsDivider,
  Title,
  Category,
  Gender,
} from './ProductCard.styled';

interface Props {
  offer: SuggestedOffer;
  onClose: (e: React.MouseEvent) => void;
  openProductDetailsModal: (sku?: string) => void;
}

export function ProductCard({ onClose, offer, openProductDetailsModal }: Props): JSX.Element {
  const { imageUrl, title, brand, gender, category, currency, price, originalPrice, sku } = offer;
  const shouldRenderProductPrice = Boolean(currency && (originalPrice || price));
  const shouldRenderProductDetails = Boolean(gender || category) || shouldRenderProductPrice;
  return (
    <ProductCardContainer onClick={() => openProductDetailsModal(sku)}>
      <ButtonGroup>
        <Icon name={AvailableIcons.Close} onClick={onClose} />
      </ButtonGroup>
      <ProductContent isMarginTop={!shouldRenderProductDetails}>
        {title && (
          <Title tooltipText={title}>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
              {title}
            </Typography>
          </Title>
        )}
        {brand && (
          <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
            {brand}
          </Typography>
        )}
        {shouldRenderProductDetails && (
          <>
            <ProductDetails>
              {shouldRenderProductPrice && <ProductCardPrice offer={offer} />}
              {gender && shouldRenderProductPrice && <Divider />}
              {gender && (
                <Gender type={TypographyType.Body} variant={TypographyVariant.ExtraSmallRegular}>
                  {gender}
                </Gender>
              )}
              {category && (
                <>
                  <Divider />
                  <Category tooltipText={category}>
                    <Typography
                      type={TypographyType.Body}
                      variant={TypographyVariant.ExtraSmallRegular}
                    >
                      {category}
                    </Typography>
                  </Category>
                </>
              )}
            </ProductDetails>
            <ProductDetailsDivider />
          </>
        )}
        <ImageContainer>
          <Image src={imageUrl} alt={title} />
        </ImageContainer>
      </ProductContent>
    </ProductCardContainer>
  );
}
