import React from 'react';
import { Page } from 'src/components-dummy';
import { AdminSettings } from '../../AdminSettingsContainer';

export function AdminSettingsPage(): JSX.Element {
  return (
    <>
      <Page.Header>
        <Page.Title>Admin Settings</Page.Title>
        <Page.SubTitle>Internal Shop Configurations and Feature Management</Page.SubTitle>
      </Page.Header>
      <Page.Content>
        <AdminSettings />
      </Page.Content>
    </>
  );
}
