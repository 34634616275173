import React from 'react';
import { SkeletonVerticalBlocksWithTitle } from 'src/components-dummy/Skeleton/skeletonCompositions';

export const LexiconRuleFormSkeleton = (): JSX.Element => {
  return (
    <SkeletonVerticalBlocksWithTitle
      itemProps={{ height: 300, width: '90%' }}
      sx={{ padding: '20px 25px 20px 50px', flex: 6 }}
    />
  );
};
