import { EditableFilterItem } from '../types';
import { isNewlyCreated } from './add-item-utils';

export function saveItem(
  listDraft: EditableFilterItem[],
  { item, cb }: { item: EditableFilterItem; cb: (savedItemKey: string) => void }
): EditableFilterItem[] {
  const positionModifier = {
    lastItemPosition: listDraft[0].position,
    shouldPushDown: false,
  };
  const updatedList: EditableFilterItem[] = listDraft.map(listItem => {
    positionModifier.lastItemPosition =
      listItem.position + (positionModifier.shouldPushDown ? 1 : 0);
    if (listItem.key === item.key) {
      const isNewItem = isNewlyCreated(item.key);
      const updatedItem = { ...item };
      if (isNewItem) {
        updatedItem.position = positionModifier.lastItemPosition;
        positionModifier.shouldPushDown = true;
      } else {
        updatedItem.position = positionModifier.lastItemPosition;
      }
      cb(updatedItem.key);
      return updatedItem;
    }
    return listItem;
  });
  return updatedList;
}
