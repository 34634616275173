import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { orderBy } from 'lodash';
import { Button, FormCard, SelectType, MenuItem } from 'src/components-dummy';
import { AIModel } from 'src/services/src/service/ai-models/types';
import { DEFAULT_MODEL, shopAiModelSettingsActions } from 'src/containers/ShopSettings/Actions';
import {
  ShopAIModelSettingsContainerStyled,
  SelectLexiconLanguagesStyled,
  SectionBoxStyled,
} from './ShopAIModelSettings.styles';
import { ShopAIModelSettingsProps } from './types';
import { UpdateModelConfirmationDialog } from './UpdateModelConfirmationDialog';

export const ShopAIModelSettings = ({
  shop,
  dispatch,
  currentModel,
  aiModelList,
}: ShopAIModelSettingsProps): JSX.Element => {
  const [aiModel, setAiModel] = useState(currentModel);
  const [showUpdateModelDialog, setShowUpdateModelDialog] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const setModelIsDirty = useCallback(
    (newIsDirty: boolean) => {
      if (newIsDirty !== isDirty) {
        setIsDirty(newIsDirty);
        dispatch(shopAiModelSettingsActions.notifyIsDirty({ isDirty: newIsDirty }));
      }
    },
    [isDirty]
  );

  const aiModelsSorted = useMemo(() => {
    return orderBy(aiModelList, 'createdAt', 'desc');
  }, [aiModelList]);

  useEffect(() => {
    if (currentModel) {
      setAiModel(currentModel);
    }
  }, [currentModel]);

  const onToggleUpdateModel = useCallback(
    (show: boolean) => {
      setShowUpdateModelDialog(show);
    },
    [setShowUpdateModelDialog]
  );

  const onUpdateModel = useCallback(() => {
    setShowUpdateModelDialog(false);
    if (shop && aiModel) {
      dispatch(
        shopAiModelSettingsActions.updateAiModel({
          shopId: shop?.shopId,
          aiModelId: aiModel?.id,
          isUsingDefaultAIModel: aiModel?.id === DEFAULT_MODEL.id,
          aiModelList,
        }) as any
      );
      setModelIsDirty(false);
    }
  }, [setShowUpdateModelDialog, shop, aiModel, setModelIsDirty, dispatch]);

  const handleModelChange = useCallback(
    (event: any) => {
      if (event.target.value === DEFAULT_MODEL.id) {
        setAiModel({ id: DEFAULT_MODEL.id, name: 'default' } as AIModel);
        setModelIsDirty(true);
      } else {
        const findModel = aiModelList?.find(model => model.id === event.target.value);
        if (findModel) {
          setAiModel({ id: findModel.id, name: findModel.name } as AIModel);
          setModelIsDirty(findModel.id !== currentModel?.id);
        }
      }
    },
    [aiModelList, setAiModel, setModelIsDirty]
  );

  return (
    <ShopAIModelSettingsContainerStyled>
      <FormCard>
        <FormCard.Title>Shop AI Model</FormCard.Title>
        <FormCard.Button>
          <Button
            onClick={() => onToggleUpdateModel(true)}
            variant='primary'
            disabled={!!(aiModel?.id === currentModel?.id)}
          >
            Save Changes
          </Button>
        </FormCard.Button>
        <FormCard.Content>
          <SectionBoxStyled>
            <SelectLexiconLanguagesStyled
              type={SelectType.Menu}
              value={aiModel?.id}
              onChange={handleModelChange}
            >
              <MenuItem key={DEFAULT_MODEL.id} value={DEFAULT_MODEL.id}>
                {DEFAULT_MODEL.name}
              </MenuItem>
              {aiModelsSorted?.map((option: { id?: string; name: string }) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </SelectLexiconLanguagesStyled>
          </SectionBoxStyled>
        </FormCard.Content>
      </FormCard>
      {showUpdateModelDialog && (
        <UpdateModelConfirmationDialog
          previousModel={currentModel?.name}
          modelName={aiModel?.name}
          onUpdateModel={onUpdateModel}
          onClose={() => onToggleUpdateModel(false)}
        />
      )}
    </ShopAIModelSettingsContainerStyled>
  );
};
