import { useState, useEffect } from 'react';
import { UpdateDataInterface } from '../types';

export interface UseEditableCellProps<T> extends UpdateDataInterface<T> {
  rowIndex: number;
  columnId: string;
  value: T;
}

export interface UseEditableCellHook<T> {
  onChange: (v: T) => void;
  currentValue: T;
}

export const useEditableCell = <T,>({
  rowIndex,
  columnId,
  value,
  updateData,
}: UseEditableCellProps<T>): UseEditableCellHook<T> => {
  const [currentValue, setCurrentValue] = useState(value);

  const updateTableDebounced = (v: T) => {
    if (!updateData) return;
    updateData({ rowIndex, columnId, value: v });
  };

  const onChange = (v: T) => {
    setCurrentValue(v);
    updateTableDebounced(v);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return {
    onChange,
    currentValue,
  };
};
