import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  DeepTagReportsService,
  DeepTagReportStatus,
  ExportDeepTagReportArguments,
} from 'src/services';

const deepTagReportsService = new DeepTagReportsService();
export const deepTagReportsListItemActions = {
  navigateTo: createAction<{ navigateTo: string }>('DeepTagReportsListItem/NavigateTo'),
  exportReportToFile: createAsyncThunk(
    'DeepTagReportsListItem/exportReportToFile',
    async (
      {
        shopId,
        reportId,
        regenerateTitles,
        regenerateDescriptions,
        regenerateThematics,
      }: ExportDeepTagReportArguments,
      { rejectWithValue }
    ) => {
      try {
        const updatedReport = await deepTagReportsService.exportReportToFile({
          shopId,
          reportId,
          regenerateTitles,
          regenerateDescriptions,
          regenerateThematics,
        });

        return updatedReport;
      } catch (error) {
        console.error(error);
        return rejectWithValue({ shopId, deepTagReportId: reportId, error });
      }
    }
  ),
  deleteReport: createAsyncThunk(
    'DeepTagReportsListItem/deleteReport',
    async (
      {
        shopId,
        deepTagReportId,
      }: {
        shopId: number;
        deepTagReportId: string;
      },
      { rejectWithValue }
    ) => {
      try {
        await deepTagReportsService.deleteReport({
          shopId,
          reportId: deepTagReportId,
        });

        return { shopId, deepTagReportId };
      } catch (error) {
        return rejectWithValue({ shopId, deepTagReportId, error });
      }
    }
  ),

  exportReportToSFTP: createAsyncThunk(
    'DeepTagReportsListItem/exportReportToSFTP',
    async (
      {
        shopId,
        deepTagReportId,
      }: {
        shopId: number;
        deepTagReportId: string;
      },
      { rejectWithValue }
    ) => {
      try {
        const updatedReport = await deepTagReportsService.exportReportToSFTP({
          shopId,
          reportId: deepTagReportId,
          regenerateTitles: false,
          regenerateDescriptions: false,
          regenerateThematics: false,
        });

        return updatedReport;
      } catch (error) {
        console.error(error);
        return rejectWithValue({ shopId, deepTagReportId, error });
      }
    }
  ),
  updateReport: createAsyncThunk(
    'DeepTagReportsListItem/UpdateReport',
    async (
      {
        shopId,
        deepTagReportId,
        status,
        customName,
      }: {
        shopId: number;
        deepTagReportId: string;
        status?: DeepTagReportStatus;
        customName?: string;
      },
      { rejectWithValue }
    ) => {
      try {
        const updatedReport = await new DeepTagReportsService().updateReport({
          shopId,
          deepTagReportId,
          status,
          customName,
        });

        return { updatedReport };
      } catch (error) {
        console.error(error);
        return rejectWithValue({ shopId, deepTagReportId, status, error });
      }
    }
  ),
};
