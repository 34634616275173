import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { useTransitionedUnmount } from 'src/hooks';
import syteIcon from '../../images/syteIcon.svg';
import './Loader.scss';

export interface LoaderProps {
  show?: boolean;
  className?: string;
}

export const Loader = ({ show, className }: LoaderProps): React.ReactPortal | null => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { isMounted, isClosing } = useTransitionedUnmount({ ref, show });

  const component = (
    <div className={classNames('syte-loader', className, { 'is-closing': isClosing })} ref={ref}>
      <div className='icon-wrapper'>
        <img src={syteIcon} alt='SyteLoader' className='icon-wrapper-content' />
      </div>
    </div>
  );

  return isMounted
    ? ReactDOM.createPortal(
        isMounted ? component : null,
        document.querySelector('[id="root"], body') as Element
      )
    : null;
};
