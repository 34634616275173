import React, { useCallback, useState } from 'react';
import { Button, FormCard, TableV2 } from 'src/components-dummy';
import { RelevancyTuningItem } from 'src/services';
import { columnsArr } from './table-columns';
import { RelevancyTuningTableRow } from './RelevancyTuningTableRow';
import {
  ResetDefaultsTooltipStyled,
  TableHeaderRowCellStyled,
} from './RelevancyTuningTable.styles';
import { ResetDefaultConfirmationDialog } from './ResetDefaultConfirmationDialog';

interface RelevancyTuningTableProps {
  relevancyTuning: RelevancyTuningItem[];
  onRelevancyTuningChange: (updatedRelevancyTuning: RelevancyTuningItem[]) => void;
  onResetDefaults: () => Promise<void>;
}

export function RelevancyTuningTable({
  relevancyTuning,
  onRelevancyTuningChange,
  onResetDefaults,
}: RelevancyTuningTableProps): JSX.Element {
  const [confirmResetModalOpened, setConfirmResetModalOpened] = useState(false);
  const [resetDefaultsInProgress, setResetDefaultsInProgress] = useState(false);

  const onResetDefaultClick = useCallback(() => {
    setConfirmResetModalOpened(true);
  }, [setConfirmResetModalOpened]);

  const onResetDefaultModalClose = useCallback(() => {
    setConfirmResetModalOpened(false);
  }, [setConfirmResetModalOpened]);

  const onResetDefaultConfirm = useCallback(async () => {
    setResetDefaultsInProgress(true);

    await onResetDefaults();

    setResetDefaultsInProgress(false);
    onResetDefaultModalClose();
  }, [onResetDefaults, onResetDefaultModalClose]);

  const onRowChange = useCallback(
    (updatedTuningItem: RelevancyTuningItem) => {
      const updatedRelevancyTuning = relevancyTuning.map(tuningItem =>
        tuningItem.dataField === updatedTuningItem.dataField ? updatedTuningItem : tuningItem
      );

      onRelevancyTuningChange(updatedRelevancyTuning);
    },
    [relevancyTuning, onRelevancyTuningChange]
  );

  return (
    <>
      <FormCard>
        <FormCard.Title>Searchable Fields</FormCard.Title>
        <FormCard.Button>
          <ResetDefaultsTooltipStyled
            value='Default settings include higher priority for Title, Category, and Brand, with moderate and lower priority for other attributes.'
            position='top center'
          >
            <Button variant='secondary' onClick={onResetDefaultClick}>
              Reset to Default
            </Button>
          </ResetDefaultsTooltipStyled>
        </FormCard.Button>
        <FormCard.Content>
          <TableV2<RelevancyTuningItem> options={{ columns: columnsArr, data: relevancyTuning }}>
            {({ getTableBodyProps, headerGroups, rows, prepareRow }) => {
              const headerGroup = headerGroups[0];

              return (
                <>
                  <TableV2.Head {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <TableHeaderRowCellStyled
                        {...column.getHeaderProps()}
                        width={columnsArr[index].width}
                        minWidth={columnsArr[index].minWidth}
                        key={column.id}
                      >
                        <TableV2.HeadRowCellText>{column.render('header')}</TableV2.HeadRowCellText>
                      </TableHeaderRowCellStyled>
                    ))}
                  </TableV2.Head>
                  <TableV2.Body {...getTableBodyProps()}>
                    {rows.map(row => {
                      prepareRow(row);
                      return (
                        <RelevancyTuningTableRow
                          key={row.original.dataField}
                          row={row}
                          onChange={onRowChange}
                        />
                      );
                    })}
                  </TableV2.Body>
                </>
              );
            }}
          </TableV2>
        </FormCard.Content>
      </FormCard>
      <ResetDefaultConfirmationDialog
        open={confirmResetModalOpened}
        confirmInProgress={resetDefaultsInProgress}
        relevancyTuning={relevancyTuning}
        onConfirm={onResetDefaultConfirm}
        onCancel={onResetDefaultModalClose}
      />
    </>
  );
}
