import { EditExperimentContainerAction } from 'src/containers';
import { RecentlyViewedSettingsReducerState } from '../app-state-types';
import {
  RecentlyViewedSettingsAction,
  RecentlyViewedActionTypes,
  VariantPanelAction,
} from '../components-bl';

const initialState: RecentlyViewedSettingsReducerState = {
  showOutOfStockItems: true,
  showPreviouslyPurchasedItems: true,
  numberOfRecentDaysToDisplay: 30,
  numberOfResultsToDisplay: 12,
  shouldRefetch: false,
  addToCart: { active: false },
};

export function recentlyViewedSettingsReducer(
  state: RecentlyViewedSettingsReducerState = initialState,
  action?: RecentlyViewedSettingsAction | EditExperimentContainerAction | VariantPanelAction
): RecentlyViewedSettingsReducerState {
  switch (action?.type) {
    case RecentlyViewedActionTypes.GetRecentlyViewedSettingsSuccess: {
      const {
        showOutOfStockItems,
        showPreviouslyPurchasedItems,
        numberOfRecentDaysToDisplay,
        numberOfResultsToDisplay,
        addToCart,
      } = action.payload;
      return {
        ...state,
        showOutOfStockItems,
        showPreviouslyPurchasedItems,
        numberOfRecentDaysToDisplay,
        numberOfResultsToDisplay,
        addToCart,
      };
    }
    case RecentlyViewedActionTypes.GetRecentlyViewedSettingsRequest:
      return { ...state };
    case RecentlyViewedActionTypes.GetRecentlyViewedSettingsError:
      return { ...state };
    case RecentlyViewedActionTypes.UpdateRecentlyViewedSettingsSuccess: {
      const {
        showOutOfStockItems,
        showPreviouslyPurchasedItems,
        numberOfRecentDaysToDisplay,
        numberOfResultsToDisplay,
        addToCart,
      } = action.payload;
      return {
        ...state,
        ...{
          showOutOfStockItems,
          showPreviouslyPurchasedItems,
          numberOfRecentDaysToDisplay,
          numberOfResultsToDisplay,
          addToCart,
        },
      };
    }
    default:
      return state;
  }
}
