import React, { useCallback, useState, useRef } from 'react';
import { startCase } from 'lodash';
import {
  Typography,
  TypographyType,
  TypographyVariant,
  Menu,
  MenuItem,
} from 'src/components-dummy';
import { BaseBulkActionProps } from '../BulkActions.types';
import { ActionButtonStyled } from '../BulkActions.styles';
import { lexiconBulkActions } from '../LexiconBulkAction.actions';
import { RenameTagsModal, TagField } from './RenameTagsModal';

type LexiconBulkActionRenameProps = BaseBulkActionProps;

export const LexiconBulkActionRename = ({
  targetItemsCounts,
  shopId,
  locale,
  tagType,
  matchTags,
  excludeTags,
  filters,
  dispatch,
  targetAllTags,
  onActionSuccess,
  onActionFail,
  onActionStart,
}: LexiconBulkActionRenameProps): JSX.Element => {
  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);

  const menuOpened = !!menuAnchorEl;

  const [showModal, setShowModal] = useState(false);
  const [tagField, setTagFieldMode] = useState<TagField>('category');

  const allTagFields: TagField[] = ['category', 'attribute', 'value'];

  const onTriggerClick = () => {
    setMenuAnchorEl(triggerRef.current);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = (targetTagField: TagField) => {
    setTagFieldMode(targetTagField);
    setShowModal(true);
  };

  const onOptionClick = (targetField: TagField) => {
    closeMenu();
    openModal(targetField);
  };

  const onRenameClick = useCallback(
    (customTranslation: string) => {
      onActionStart();

      if (targetAllTags) {
        (
          dispatch(
            lexiconBulkActions.renameAll({
              shopId,
              locale,
              tagType,
              excludeTags: [...excludeTags],
              tagField,
              customTranslation,
              filters,
            })
          ) as any
        )
          .unwrap()
          .then(() => {
            onActionSuccess();
          })
          .catch((error: Error) => {
            console.error(error);
            onActionFail();
          });
      } else {
        (
          dispatch(
            lexiconBulkActions.rename({
              shopId,
              locale,
              tagType,
              matchTags: [...matchTags],
              tagField,
              customTranslation,
            })
          ) as any
        )
          .unwrap()
          .then(() => {
            onActionSuccess();
          })
          .catch((error: Error) => {
            console.error(error);
            onActionFail();
          });
      }

      setShowModal(false);
    },
    [
      dispatch,
      shopId,
      locale,
      tagType,
      targetAllTags,
      matchTags,
      excludeTags,
      filters,
      tagField,
      onActionFail,
      onActionStart,
      onActionSuccess,
    ]
  );

  return (
    <>
      <ActionButtonStyled
        marginRight={4}
        variant='secondary'
        onClick={onTriggerClick}
        clicked={false}
        ref={triggerRef}
      >
        Rename
      </ActionButtonStyled>

      <Menu open={menuOpened} anchorEl={menuAnchorEl} onClose={closeMenu} label='Select column'>
        {allTagFields.map(field => {
          return (
            <MenuItem key={field} value={startCase(field)} onClick={() => onOptionClick(field)}>
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                {startCase(field)}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>

      {showModal && (
        <RenameTagsModal
          onCancel={closeModal}
          tagsCount={targetItemsCounts}
          onRenameClick={onRenameClick}
          tagField={tagField}
        />
      )}
    </>
  );
};
