import React from 'react';
import { Typography, TypographyType, TypographyVariant } from '../../../../components-dummy';

interface Props {
  label: string;
}

export function TabLabel({ label }: Props) {
  return (
    <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
      {label}
    </Typography>
  );
}
