import {
  GetRecentlyViewedSettingsRequestPayload,
  GetRecentlyViewedSettingsSuccessPayload,
  GetRecentlyViewedSettingsErrorPayload,
  UpdateRecentlyViewedSettingsRequestPayload,
  UpdateRecentlyViewedSettingsSuccessPayload,
  UpdateRecentlyViewedSettingsErrorPayload,
  RecentlyViewedActionTypes,
  GetRecentlyViewedSettingsRequestAction,
  GetRecentlyViewedSettingsErrorAction,
  GetRecentlyViewedSettingsSuccessAction,
  UpdateRecentlyViewedSettingsRequestAction,
  UpdateRecentlyViewedSettingsSuccessAction,
  UpdateRecentlyViewedSettingsErrorAction,
  NotifyRecentlyViewedSettingsIsDirtyAction,
  NotifyRecentlyViewedSettingsIsDirtyActionPayload,
} from './types';

export const recentlyViewedSettingsActions = {
  getRecentlyViewedSettingsRequest: (
    payload: GetRecentlyViewedSettingsRequestPayload
  ): GetRecentlyViewedSettingsRequestAction => {
    return {
      type: RecentlyViewedActionTypes.GetRecentlyViewedSettingsRequest,
      payload,
    };
  },
  getRecentlyViewedSettingsSuccess: (
    payload: GetRecentlyViewedSettingsSuccessPayload
  ): GetRecentlyViewedSettingsSuccessAction => {
    return {
      type: RecentlyViewedActionTypes.GetRecentlyViewedSettingsSuccess,
      payload,
    };
  },
  getRecentlyViewedSettingsError: ({
    error,
  }: GetRecentlyViewedSettingsErrorPayload): GetRecentlyViewedSettingsErrorAction => {
    return {
      type: RecentlyViewedActionTypes.GetRecentlyViewedSettingsError,
      payload: { error },
    };
  },
  updateRecentlyViewedSettingsRequest: (
    payload: UpdateRecentlyViewedSettingsRequestPayload
  ): UpdateRecentlyViewedSettingsRequestAction => {
    return {
      type: RecentlyViewedActionTypes.UpdateRecentlyViewedSettingsRequest,
      payload,
    };
  },
  updateRecentlyViewedSettingsSuccess: (
    payload: UpdateRecentlyViewedSettingsSuccessPayload
  ): UpdateRecentlyViewedSettingsSuccessAction => {
    return {
      type: RecentlyViewedActionTypes.UpdateRecentlyViewedSettingsSuccess,
      payload,
    };
  },
  updateRecentlyViewedSettingsError: (
    payload: UpdateRecentlyViewedSettingsErrorPayload
  ): UpdateRecentlyViewedSettingsErrorAction => {
    return {
      type: RecentlyViewedActionTypes.UpdateRecentlyViewedSettingsError,
      payload,
    };
  },

  notifyIsDirtyState: (
    payload: NotifyRecentlyViewedSettingsIsDirtyActionPayload
  ): NotifyRecentlyViewedSettingsIsDirtyAction => {
    return {
      type: RecentlyViewedActionTypes.NotifyRecentlyViewedSettingsIsDirty,
      payload,
    };
  },
};
