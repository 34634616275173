import { useAppDispatch } from './useAppDispatch';

export enum UseAppNavigationActionTypes {
  NavigateTo = 'UseNavigation/NavigateTo',
}

export const useAppNavigation = () => {
  const appDispatch = useAppDispatch();
  const navigateAction = (path: string) => ({
    payload: { navigateTo: path },
    type: 'UseNavigation/NavigateTo',
  });

  return (path: string) => {
    appDispatch(navigateAction(path));
  };
};
