import { DiscoveryBannerGeneralSettings } from 'src/services/src/service/discovery-banner/discovery-banner';
import { Dispatch } from 'src/components-bl/types';
import {
  UpdateDiscoveryBannerSettingsPayload,
  GetDiscoveryBannerSettingsArgs,
  GetDiscoveryBannerSettingsRequestPayload,
  GetDiscoveryBannerSettingsSuccessPayload,
  GetDiscoveryBannerSettingsErrorPayload,
  UpdateDiscoveryBannerSettingsRequestPayload,
  UpdateDiscoveryBannerSettingsSuccessPayload,
  UpdateDiscoveryBannerSettingsErrorPayload,
  DiscoveryBannerActionTypes,
  GetDiscoveryBannerSettingsRequestAction,
  GetDiscoveryBannerSettingsErrorAction,
  GetDiscoveryBannerSettingsSuccessAction,
  UpdateDiscoveryBannerSettingsRequestAction,
  UpdateDiscoveryBannerSettingsSuccessAction,
  UpdateDiscoveryBannerSettingsErrorAction,
  NotifyDiscoveryBannerSettingsIsDirtyAction,
  NotifyDiscoveryBannerSettingsIsDirtyActionPayload,
} from './types';

const discoveryBannerService = new DiscoveryBannerGeneralSettings();

/* --= Actions =-- */
const discoveryBannerSettingsActions = {
  getDiscoveryBannerSettingsRequest: (
    payload: GetDiscoveryBannerSettingsRequestPayload
  ): GetDiscoveryBannerSettingsRequestAction => {
    return {
      type: DiscoveryBannerActionTypes.GetDiscoveryBannerSettingsRequest,
      payload,
    };
  },
  getDiscoveryBannerSettingsSuccess: (
    payload: GetDiscoveryBannerSettingsSuccessPayload
  ): GetDiscoveryBannerSettingsSuccessAction => {
    return {
      type: DiscoveryBannerActionTypes.GetDiscoveryBannerSettingsSuccess,
      payload,
    };
  },
  getDiscoveryBannerSettingsError: ({
    error,
  }: GetDiscoveryBannerSettingsErrorPayload): GetDiscoveryBannerSettingsErrorAction => {
    return {
      type: DiscoveryBannerActionTypes.GetDiscoveryBannerSettingsError,
      payload: { error },
    };
  },
  updateDiscoveryBannerSettingsRequest: (
    payload: UpdateDiscoveryBannerSettingsRequestPayload
  ): UpdateDiscoveryBannerSettingsRequestAction => {
    return {
      type: DiscoveryBannerActionTypes.UpdateDiscoveryBannerSettingsRequest,
      payload,
    };
  },
  updateDiscoveryBannerSettingsSuccess: (
    payload: UpdateDiscoveryBannerSettingsSuccessPayload
  ): UpdateDiscoveryBannerSettingsSuccessAction => {
    return {
      type: DiscoveryBannerActionTypes.UpdateDiscoveryBannerSettingsSuccess,
      payload,
    };
  },
  updateDiscoveryBannerSettingsError: (
    payload: UpdateDiscoveryBannerSettingsErrorPayload
  ): UpdateDiscoveryBannerSettingsErrorAction => {
    return {
      type: DiscoveryBannerActionTypes.UpdateDiscoveryBannerSettingsError,
      payload,
    };
  },

  notifyIsDirtyState: (
    payload: NotifyDiscoveryBannerSettingsIsDirtyActionPayload
  ): NotifyDiscoveryBannerSettingsIsDirtyAction => {
    return {
      type: DiscoveryBannerActionTypes.NotifyDiscoveryBannerSettingsIsDirty,
      payload,
    };
  },
};

/* --= Methods =-- */
export const discoveryBannerSettingsActionsMethods = {
  notifyIsDirty:
    ({ isDirty }: NotifyDiscoveryBannerSettingsIsDirtyActionPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(discoveryBannerSettingsActions.notifyIsDirtyState({ isDirty }));
    },

  fetchDiscoveryBannerSettings:
    ({ shopId, variantId }: GetDiscoveryBannerSettingsArgs) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(discoveryBannerSettingsActions.getDiscoveryBannerSettingsRequest({ shopId }));
        const { general, banner, button, icon } = await discoveryBannerService.get({
          shopId,
          variantId,
        });

        dispatch(
          discoveryBannerSettingsActions.getDiscoveryBannerSettingsSuccess({
            shopId,
            general,
            banner,
            button,
            icon,
          })
        );
      } catch (error) {
        dispatch(discoveryBannerSettingsActions.getDiscoveryBannerSettingsError({ error }));
      }
    },

  updateDiscoveryBannerSettings:
    ({ shopId, variantId, general, banner, button, icon }: UpdateDiscoveryBannerSettingsPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(
          discoveryBannerSettingsActions.updateDiscoveryBannerSettingsRequest({
            shopId,
            general,
            banner,
            button,
            icon,
          })
        );

        await discoveryBannerService.update({
          shopId,
          variantId,
          general,
          banner,
          button,
          icon,
        });

        dispatch(
          discoveryBannerSettingsActions.updateDiscoveryBannerSettingsSuccess({
            shopId,
            general,
            banner,
            button,
            icon,
          })
        );
      } catch (error) {
        dispatch(discoveryBannerSettingsActions.updateDiscoveryBannerSettingsError({ error }));
      }
    },
};
