import type { ShopperExperiencesState } from '../app-state-types';
import {
  ShopperExperiencesAction,
  ShopperExperiencesActionTypes,
} from '../components-bl/ShopperExperiences/Actions';

const initialState: ShopperExperiencesState = {
  experiences: [],
};

export function shopperExperiencesReducer(
  state: ShopperExperiencesState = initialState,
  action?: ShopperExperiencesAction
): ShopperExperiencesState {
  switch (action?.type) {
    case ShopperExperiencesActionTypes.GetShopperExperiencesSuccess: {
      return {
        ...state,
        experiences: action.payload.experiences ?? [],
      };
    }
    case ShopperExperiencesActionTypes.GetShopperExperiencesError:
      return { ...state, experiences: [] };
    default:
      return state;
  }
}
