import { ShopDataField } from 'src/services/src/service/types';
import { IndexedListItem } from 'src/components-dummy';
import {
  TextTypeDisplayOptions,
  NumericTypeDisplayOptions,
  BucketsOrderType,
  BucketsOrderSort,
  FilterType,
  FilterStatusOption,
  SwatchesShapeOptions,
} from './enums';

export type DisplayType = TextTypeDisplayOptions | NumericTypeDisplayOptions;

export type BucketsOrder = {
  type: BucketsOrderType;
  sort: BucketsOrderSort;
};

export interface FilterItemBase {
  key: string;
  enabled: boolean;
  position: number;
  showRangeInput?: boolean;
  colorShape?: SwatchesShapeOptions;
  numBuckets: number;
  minBucketDelta: number;
}
export interface FilterItemDBStructure extends FilterItemBase {
  displayNameKey: string;
  dataSource: string;
  type: FilterType;
  displayType: DisplayType;
  bucketsOrder: BucketsOrder;
}

export type FilterItemProperty = keyof (FilterItemDBStructure & EditableFilterItem);

export type FilterItemPropertyValue =
  | string
  | number
  | boolean
  | FilterType
  | DisplayType
  | BucketsOrder
  | SwatchesShapeOptions
  | ShopDataField
  | undefined;

export const FilterStatusPropertyName = 'filterStatus';

export interface EditableFilterItem extends FilterItemBase, IndexedListItem {
  displayNameKey?: string | undefined;
  dataSource: string | undefined;
  type: FilterType | undefined;
  displayType: DisplayType | undefined;
  bucketsOrder: BucketsOrder | undefined;
  isTypeNumeric: boolean | undefined;
  [FilterStatusPropertyName]: FilterStatusOption;
}
