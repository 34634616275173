import { useMemo } from 'react';

type MappedSelectValues = Array<{ value: string; text: string }>;

type MappedSelectValuesProps = Array<{
  name: string;
  displayName: string;
}>;

export const useMappedSelectValues = (items: MappedSelectValuesProps): MappedSelectValues => {
  return useMemo(() => {
    return items?.map(item => ({ value: item.name, text: item.displayName })) || [];
  }, [items]);
};
