import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { CatalogManagerService } from 'src/services';

const catalogManagerService = new CatalogManagerService();

export const CatalogFeedUploadLogContainerActions = {
  navigateTo: createAction<{ navigateTo: string }>('CatalogFormContainer/NavigateTo'),
  getCatalogFeedUploadFileContent: createAsyncThunk(
    'CatalogFeedUploadLogContainerActions/getCatalogFeedUploadFileContent',
    async (
      { shopId, catalogName }: { shopId: number; catalogName: string },
      { rejectWithValue }
    ) => {
      try {
        const catalogFeedUploadLog = await catalogManagerService.getCatalogFeedUploadLog({
          shopId,
          catalogName,
        });

        return { catalogFeedUploadLog };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
