import React, { MouseEvent, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy/Typography';
import { SideNavRoute } from '../../types';
import { NavLinkStyled, PageSideNavListItemStyled } from './PageSideNav.styled';

interface PageSideNavItemLinkProps {
  route: SideNavRoute;
  handleNavigate: (url: string) => void;
}

export function PageSideNavItemLink({
  route,
  handleNavigate,
}: PageSideNavItemLinkProps): JSX.Element {
  const { title, url, icon, pathTemplate, className } = route;

  const routeMatch = useRouteMatch(pathTemplate);
  const isActive = Boolean(routeMatch);
  const isDisabled = className === 'disabled';

  const onClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      if (isDisabled) {
        return;
      }
      handleNavigate(url);
    },
    [url, handleNavigate, className]
  );

  return (
    <PageSideNavListItemStyled key={route.title} isActive={isActive} isDisabled={isDisabled}>
      <NavLinkStyled key={title} to={url} onClick={onClick} isDisabled={isDisabled}>
        {icon || null}
        <Typography
          type={TypographyType.Body}
          variant={isActive ? TypographyVariant.MediumBold : TypographyVariant.MediumRegular}
        >
          {title}
        </Typography>
      </NavLinkStyled>
    </PageSideNavListItemStyled>
  );
}
