import React from 'react';
import { AvailableIcons, Icon } from 'src/components-dummy';
import { Link } from 'src/components-dummy/Link';

export const RuleGuideLink = (): JSX.Element => {
  return (
    <Link href='https://guidelines.syte.ai/5c8a9b7e5/p/51d541-merchandising-rules'>
      <Link.Text>How to set a new rule</Link.Text>
      <Icon name={AvailableIcons.ExternalLink} />
    </Link>
  );
};
