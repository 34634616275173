import React from 'react';
import styled from '@emotion/styled';
import { Typography, TypographyType, TypographyVariant } from '../../../../components-dummy';

const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  .rules-subtitle {
    line-height: 16px;
    color: #878787;
  }
`;

export function RulesSubtitle({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <StyledSubtitleContainer>
      <Typography
        className='rules-subtitle'
        type={TypographyType.Body}
        variant={TypographyVariant.MediumMedium}
      >
        {children}
      </Typography>
    </StyledSubtitleContainer>
  );
}
