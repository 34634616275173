import React from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { ExistingCatalogForm } from 'src/components-bl/CatalogManager/';
import { useAppSelector } from 'src/hooks';
import { connectExistingCatalogModalContainerActions } from './ConnectExistingCatalogModalContainer.actions';

type ConnectExistingCatalogModalContainerProps = RoutedComponentProps;

export const ConnectExistingCatalogModalContainer = ({
  permittedRouteMap,
}: ConnectExistingCatalogModalContainerProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const selectedShopId = useAppSelector(state => state.shop.current?.shopId);
  const catalogs = useAppSelector(state => state.catalogManager.catalogs);

  const onModalClose = () => {
    if (permittedRouteMap.catalogManagerList && selectedShopId) {
      dispatch(
        connectExistingCatalogModalContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.catalogManagerList.path, {
            shopId: selectedShopId,
          }),
        })
      );
    }
  };

  return selectedShopId ? (
    <ExistingCatalogForm
      onCancel={onModalClose}
      shopId={selectedShopId}
      dispatch={dispatch}
      mode='connect'
      catalog={undefined}
      allCatalogs={catalogs}
    />
  ) : null;
};
