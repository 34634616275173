import React from 'react';
import { useAppSelector } from '../../../../hooks';
import { StateController } from './ShopperExperience/useStateControllerRef';
import { AppRoute, AppSwitch, PermittedRouteMap } from '../../../../app-routes';
import { SideMenuPreview } from './SideMenuPreview';
import { SideMenuEdit } from './SideMenuEdit';
import { useNavigateToVisualEditorRoute } from '../../hooks/useNavigateToVisualEditorRoute';
import { SettingsContainer } from './SideMenu.styles';

interface Props {
  shopId: number;
  setForInternalUseOnly: boolean;
  closeModal: VoidFunction;
  stateController: StateController<any>;
  permittedRouteMap: PermittedRouteMap;
  resultsScreenRef: React.MutableRefObject<HTMLDivElement>;
  openProductDetailsModal: (sku?: string) => void;
}

export function SideMenuSettings({
  shopId,
  setForInternalUseOnly,
  closeModal,
  stateController,
  permittedRouteMap,
  resultsScreenRef,
  openProductDetailsModal,
}: Props): JSX.Element {
  const { selectedRule } = useAppSelector(state => state.visualEditor.merchandisingRules);
  const selectedExperience = useAppSelector(state => state.visualEditor.selectedExperience);

  const { navigateToVisualEditorRoute: navigateToEditRankingStrategy } =
    useNavigateToVisualEditorRoute({
      route: permittedRouteMap?.visualEditorEditRankingStrategy,
    });

  const { navigateToVisualEditorRoute: navigateToEditRelevancyTuning } =
    useNavigateToVisualEditorRoute({
      route: permittedRouteMap?.visualEditorEditRelevancyTuning,
    });

  const { navigateToVisualEditorRoute } = useNavigateToVisualEditorRoute({
    route: permittedRouteMap?.visualEditor,
  });

  return (
    <SettingsContainer>
      <AppSwitch>
        <AppRoute
          route={permittedRouteMap.visualEditorEdit}
          Component={SideMenuEdit}
          componentProps={{
            setForInternalUseOnly,
            onClose: () => navigateToVisualEditorRoute({ shopId }),
            selectedRule,
            shopId,
            stateController,
            permittedRouteMap,
          }}
          exact={false}
        />
        <SideMenuPreview
          shopId={shopId}
          stateController={stateController}
          setForInternalUseOnly={setForInternalUseOnly}
          onClose={closeModal}
          selectedRule={selectedRule}
          editRankingStrategy={() => navigateToEditRankingStrategy({ shopId })}
          navigateToEditRelevancyTuning={() => navigateToEditRelevancyTuning({ shopId })}
          permittedRouteMap={permittedRouteMap}
          resultsScreenRef={resultsScreenRef}
          selectedExperience={selectedExperience}
          openProductDetailsModal={openProductDetailsModal}
        />
      </AppSwitch>
    </SettingsContainer>
  );
}
