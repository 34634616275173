import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiKeys } from 'src/services';

const apiKeysSettingsService = new ApiKeys();

export const shopApiKeysSettingsModalContainerActions = {
  navigateTo: createAction<{ navigateTo: string }>('ShopApiKeysSettingsModalContainer/navigateTo'),
  getApiKey: createAsyncThunk(
    'ShopApiKeysSettingsModalContainer/GetApiKey',
    async ({ shopId, apiKeyId }: { shopId: number; apiKeyId: string }, { rejectWithValue }) => {
      try {
        const apiKey = await apiKeysSettingsService.getApiKey(shopId, apiKeyId);
        return apiKey;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  resetSelectedApiKey: createAction('ShopApiKeysSettingsModalContainer/resetSelectedApiKey'),
};
