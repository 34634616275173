import { AppNotification } from 'src/app-types';

export enum ToastActionType {
  Enqueue = 'Toast/Enqueue',
  Dequeue = 'Toast/Dequeue',
  Remove = 'Toast/Remove',
  Clear = 'Toast/Clear',
}

export interface ToastEnqueueAction {
  type: ToastActionType.Enqueue;
  payload: AppNotification;
}

export interface ToastEnqueueActionPayload {
  toast: AppNotification;
}

export interface ToastDequeueAction {
  type: ToastActionType.Dequeue;
  payload: undefined;
}

export interface ToastRemoveAction {
  type: ToastActionType.Remove;
  payload: AppNotification;
}

export interface ToastRemoveActionPayload {
  toast: AppNotification;
}

export interface ToastClearAction {
  type: ToastActionType.Clear;
  payload: undefined;
}

export type ToastAction =
  | ToastEnqueueAction
  | ToastDequeueAction
  | ToastRemoveAction
  | ToastClearAction;
