import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { orderBy } from 'lodash';
import { ILocationUser } from 'src/services';
import { Dispatch } from 'src/components-bl/types';
import { UserThumbnailElementWrapper } from './UserThumbnailElementWrapper';
import { UserThumbnailElement } from './UserThumbnailElement';

export interface UserThumbnailsListProps {
  users: ILocationUser[];
  hasMoreUsers: boolean;
  globalShouldShow: boolean;
  isOnline?: boolean;
  myProfilePath: string | undefined;
  dispatch: Dispatch;
}

const ListElement = ({
  user,
  index,
  hasMoreUsers,
  show,
  onRemoveUser,
  isOnline,
  myProfilePath,
  dispatch,
  isMenuOpen,
  setIsMenuOpen,
}: {
  user: ILocationUser;
  index: number;
  hasMoreUsers: boolean;
  show: boolean;
  onRemoveUser: (userKey: number) => void;
  isOnline?: boolean;
  myProfilePath: string | undefined;
  dispatch: Dispatch;
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
}) => {
  const tooltipShouldBeHidden = user.isMe && !hasMoreUsers;

  const onUnMount = () => onRemoveUser(user.userId);

  return (
    <UserThumbnailElementWrapper index={index} key={user.userId} show={show} onUnmount={onUnMount}>
      <UserThumbnailElement
        user={user}
        enableTooltip={!tooltipShouldBeHidden || show}
        isOnline={isOnline}
        myProfilePath={myProfilePath}
        dispatch={dispatch}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
      />
    </UserThumbnailElementWrapper>
  );
};

export function sortUsers(users: ILocationUser[]): ILocationUser[] {
  return orderBy(users, ['isMe', 'displayName'], 'asc');
}

export const UserThumbnailsList = ({
  users,
  hasMoreUsers,
  globalShouldShow,
  isOnline,
  myProfilePath,
  dispatch,
}: UserThumbnailsListProps): JSX.Element => {
  const [usersToRender, setUsersToRender] = useState(users);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const usersToRemoveSet = useMemo(() => {
    const inComingUserIds = new Set(users.map(user => user.userId));

    const toRemove = new Set(
      usersToRender.map(user => user.userId).filter(userId => !inComingUserIds.has(userId))
    );

    return toRemove;
  }, [users, usersToRender]);

  const removeUserById = useCallback(
    (userId: number) => {
      setUsersToRender(oldState => {
        return oldState.filter(user => user.userId !== userId);
      });
    },
    [usersToRender]
  );

  useEffect(() => {
    const renderedUsersLookup = usersToRender.reduce(
      (accumulator, current) => {
        accumulator[current.userId] = current;
        return accumulator;
      },
      {} as Record<string, ILocationUser>
    );

    const incomingUsersLookup = users.reduce(
      (accumulator, current) => {
        accumulator[current.userId] = current;
        return accumulator;
      },
      {} as Record<string, ILocationUser>
    );

    const updatedState = usersToRender.map(user => {
      const incomingUser = incomingUsersLookup[user.userId];
      if (incomingUser && incomingUser.isEditing !== user.isEditing) {
        return incomingUser;
      }
      return user;
    });

    for (const user of users) {
      if (!renderedUsersLookup[user.userId]) {
        updatedState.push(user);
      }
    }

    setUsersToRender(sortUsers(updatedState));
  }, [users]);

  return (
    <>
      {usersToRender.map((user, index) => (
        <ListElement
          user={user}
          index={index}
          hasMoreUsers={hasMoreUsers}
          key={user.userId}
          show={!usersToRemoveSet.has(user.userId) && globalShouldShow}
          onRemoveUser={removeUserById}
          isOnline={isOnline}
          myProfilePath={myProfilePath}
          dispatch={dispatch}
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
        />
      ))}
    </>
  );
};
