import Joi from 'joi';
import { v4 as uuidV4 } from 'uuid';
import { getStringEnumValues } from 'src/utils';
import { ThematicTagConditionOperatorsEnum } from 'src/services';
import {
  ILexiconRuleConditionsGroupDraft,
  ILexiconRuleDraft,
  ILexiconRuleConditionsDraft,
  TagFields,
} from './LexiconRuleForm.types';

const ConditionSchema = Joi.object({
  id: Joi.string(),
  operator: Joi.string()
    .valid(...getStringEnumValues(ThematicTagConditionOperatorsEnum))
    .required()
    .messages({
      'string.empty': 'Operator can not be empty',
    }),
  categoryKey: Joi.string().trim().min(1).required().messages({
    'string.empty': 'Category can not be empty',
  }),
  attributeKey: Joi.string().trim().min(1).required().messages({
    'string.empty': 'Attribute can not be empty',
  }),
  valuesKeys: Joi.when('operator', {
    is: Joi.valid(
      ThematicTagConditionOperatorsEnum.HasNoValue,
      ThematicTagConditionOperatorsEnum.HasAnyValue
    ),
    then: Joi.array().items().length(0),
    otherwise: Joi.array().items(Joi.string().trim().min(1)).min(1).required().messages({
      'array.min': 'Value can not be empty',
    }),
  }),
});

const TagPartSchema = Joi.object({
  field: Joi.string()
    .valid(...getStringEnumValues(TagFields))
    .required()
    .messages({
      'string.empty': 'Operator can not be empty',
    }),
  categoryKey: Joi.when('field', {
    is: Joi.valid(TagFields.Category),
    then: Joi.string().trim().min(1).required().messages({
      'string.empty': 'Please, select category',
      'any.required': 'Category is required',
    }),
    otherwise: Joi.string().optional(),
  }),
  attributeKey: Joi.when('field', {
    is: Joi.valid(TagFields.Attribute, TagFields.Value),
    then: Joi.string().trim().min(1).required().messages({
      'string.empty': 'Please, select attribute',
      'any.required': 'Attribute is required',
    }),
    otherwise: Joi.string().optional(),
  }),

  valueKey: Joi.when('field', {
    is: Joi.valid(TagFields.Value),
    then: Joi.string().trim().min(1).required().messages({
      'string.empty': 'Please, select value',
      'any.required': 'Value is required',
    }),
    otherwise: Joi.string().optional(),
  }),
  customTranslation: Joi.string().trim().min(1).max(100).required().messages({
    'string.empty': 'Translation can not be empty',
    'any.required': 'Translation is required',
    'string.max': 'Translation should be 1-100 characters long.',
  }),
});

const ConditionGroupSchema = Joi.object({
  id: Joi.string(),
  conditions: Joi.array().items(ConditionSchema).min(1).required(),
});

export const lexiconRuleValidationSchema = {
  conditionsGroups: Joi.array().items(ConditionGroupSchema).min(1).required().messages({
    'array.min': 'Please, add at least one group of rules',
  }),
  tagParts: Joi.array().items(TagPartSchema).min(1).required().messages({
    'array.min': 'Category, attribute or value are required',
  }),
};

export function generateEmptyCondition(): ILexiconRuleConditionsDraft {
  return {
    id: uuidV4().toString(),
    categoryKey: '',
    attributeKey: '',
    valuesKeys: [],
    operator: ThematicTagConditionOperatorsEnum.ValueIs,
  };
}

export function generateEmptyConditionGroup(): ILexiconRuleConditionsGroupDraft {
  return {
    id: uuidV4().toString(),
    conditions: [generateEmptyCondition()],
  };
}

export function getInitialLexiconRuleDraft(): ILexiconRuleDraft {
  return {
    tagParts: [{ field: undefined, id: Math.random() }],
    conditionsGroups: [generateEmptyConditionGroup()],
  };
}
