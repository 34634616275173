import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { DeepTagReportsList, DeepTagReportHowToUploadTrigger } from 'src/components-bl';
import { Page, Button } from 'src/components-dummy';
import { RoutedComponentProps } from 'src/app-routes';
import './DeepTagReportsContainer.scss';
import {
  DeepTagReportsContainerPageHeaderStyled,
  DeepTagReportsContainerPageTitlesStyled,
  ConfigurationButtonWrapperStyled,
} from './DeepTagReportsContainer.styles';
import { deepTagReportsContainerActions } from './Actions';

type DeepTagReportsContainerProps = RoutedComponentProps;

type ConnectedDeepTagReportListProps = DeepTagReportsContainerProps;

const ConnectedDeepTagReportsConfigurationButton = ({
  permittedRouteMap,
}: ConnectedDeepTagReportListProps): JSX.Element => {
  const dispatch = useDispatch();

  const { shopId, userRole, deepTagUploadCsvFeature } = useAppSelector(state => {
    return {
      shopId: state.shop.current?.shopId,
      userRole: state.global.loggedInUser?.role,
      deepTagUploadCsvFeature: state.shop?.featureToggles?.deepTagReportsUploadCsv,
    };
  });

  const isUploadCsvPermitted = !!(
    userRole &&
    deepTagUploadCsvFeature?.enabled &&
    deepTagUploadCsvFeature?.permittedRoles?.includes(userRole)
  );

  const onSFTPSettingsButtonClick = () => {
    if (permittedRouteMap.deepTagReportsConfiguration && shopId) {
      dispatch(
        deepTagReportsContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.deepTagReportsConfiguration.path, { shopId }),
        })
      );
    }
  };

  const onFileUploadClick = () => {
    if (permittedRouteMap.deepTagReportsFileUpload) {
      dispatch(
        deepTagReportsContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.deepTagReportsFileUpload.path, { shopId }),
        })
      );
    }
  };

  useEffect(() => {
    if (shopId) {
      dispatch(deepTagReportsContainerActions.getConfiguration({ shopId }));
    }
  }, [shopId]);

  return (
    <ConfigurationButtonWrapperStyled>
      <Button
        variant='secondary'
        size='medium'
        onClick={isUploadCsvPermitted ? onSFTPSettingsButtonClick : undefined}
        disabled={!isUploadCsvPermitted}
      >
        SFTP Settings
      </Button>
      <Button
        variant='primary'
        size='medium'
        onClick={isUploadCsvPermitted ? onFileUploadClick : undefined}
        disabled={!isUploadCsvPermitted}
      >
        Upload CSV file
      </Button>
    </ConfigurationButtonWrapperStyled>
  );
};

const ConnectedDeepTagReportList = ({
  permittedRouteMap,
}: ConnectedDeepTagReportListProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { reports, shopId } = useAppSelector(state => {
    return {
      reports: state.deepTagReports.reports,
      shopId: state.shop.current?.shopId,
    };
  });

  return shopId ? (
    <DeepTagReportsList
      shopId={shopId}
      reports={reports}
      dispatch={dispatch}
      permittedRouteMap={permittedRouteMap}
    />
  ) : (
    <></>
  );
};

const ConnectedHowToUpload = (): JSX.Element => {
  return <DeepTagReportHowToUploadTrigger />;
};

export const DeepTagReportsContainer = ({
  permittedRouteMap,
}: DeepTagReportsContainerProps): JSX.Element => {
  return (
    <Page className='syte-page-clean'>
      <Page.Header>
        <DeepTagReportsContainerPageHeaderStyled>
          <DeepTagReportsContainerPageTitlesStyled>
            <Page.Title>Tags Editor</Page.Title>
            <Page.SubTitle>Tag & Review your products enriched by Syte.</Page.SubTitle>
            <ConnectedHowToUpload />
          </DeepTagReportsContainerPageTitlesStyled>
          <ConnectedDeepTagReportsConfigurationButton permittedRouteMap={permittedRouteMap} />
        </DeepTagReportsContainerPageHeaderStyled>
      </Page.Header>
      <Page.Content>
        <ConnectedDeepTagReportList permittedRouteMap={permittedRouteMap} />
      </Page.Content>
    </Page>
  );
};
