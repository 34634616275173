import styled from '@emotion/styled';

export const AutoCompleteWrapperStyled = styled.div`
  width: auto;

  .MuiAutocomplete-root .MuiChip-filled {
    height: 20px;
    margin-bottom: 8px;
    border-radius: 4px;
  }

  .MuiInputBase-root.MuiOutlinedInput-root .MuiAutocomplete-input {
    height: 20px;
    margin-bottom: 6px;
    background-color: inherit;
  }

  & > div {
    min-width: 100px;
  }

  .MuiFormControl-root > label {
    display: none;
  }

  .MuiFormControl-root legend {
    display: none;
  }

  .MuiInputBase-root {
    width: auto !important;
  }

  .MuiOutlinedInput-root {
    padding-top: 3px;
    padding-bottom: 2px;
    padding-left: 1px;
    background: ${({ theme }) => theme.palette.custom['gray-80']};
  }

  button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator {
    padding: 2px;
    top: 3px;
  }

  input::placeholder {
    color: ${({ theme }) => theme.palette.common.white};
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    height: 24px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 4px;

    .MuiAutocomplete-input {
      padding: 0 0 0 6px;
    }
  }

  .fieldset {
    border-color: transparent;
    background: ${({ theme }) => theme.palette.custom['gray-60']};
  }

  fieldset.MuiOutlinedInput-notchedOutline {
    top: 0;
    border-color: transparent;
  }

  .MuiInputBase-input {
    font-style: normal;
    font-size: 10px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    font-family: 'Inter' !important;
    letter-spacing: 0em;
  }

  .MuiInputLabel-root {
    color: ${({ theme }) => theme.palette.common.black};
    margin-bottom: 8px;
    padding: 0;
  }
`;

export const ReadOnlyTextStyled = styled.div`
  padding: 2px 9px;
  border-radius: 5px;
  min-height: 24px;
  background: ${({ theme }) => theme.palette.custom['gray-80']};
`;
