import { debounce, DebouncedFunc, DebounceSettings } from 'lodash';
import { useRef } from 'react';

type DebounceCallback = Parameters<typeof debounce>[0];

export const useDebounce = <T extends DebounceCallback>(
  func: T,
  wait?: number,
  options?: DebounceSettings
): DebouncedFunc<T> => {
  const debounceFn = useRef<DebouncedFunc<T> | null>(null);
  if (!debounceFn.current) {
    debounceFn.current = debounce(func, wait, options);
  }

  return debounceFn.current;
};
