import { FieldType, MerchandisingRuleTypes } from 'src/services';
import {
  RuleDraft,
  RuleDraftCondition,
  rulesFormInitialData,
} from './MerchandisingRuleForm.config';
import { CategoryFilterRule } from '../../../VisualEditor/types/category-filter-rule';

// Mappers from draft rule to Api Structure and back.
// normally should be in service but need to support old merchandising rules components (for now) as well.
export const ruleFormMappers = {
  mapApiRuleToRuleDraft({
    rule,
    predefinedProduct,
    entityId,
    availableRegions,
  }: {
    rule: MerchandisingRuleTypes.MerchandiseRule | undefined;
    predefinedProduct?: MerchandisingRuleTypes.MerchandisingRuleProduct;
    entityId?: string;
    availableRegions?: string[];
  }): RuleDraft {
    // MAP FOR CREATE
    if (!rule) {
      const initialRule = { ...rulesFormInitialData, regions: availableRegions || [] };

      // always use predefined product if specified. relevant when creating a rule.
      if (predefinedProduct) {
        initialRule.product = predefinedProduct;
      }

      if (entityId) {
        initialRule.entityId = entityId;
      }

      initialRule.subRules = [this.createEmptyCondition()];
      return initialRule;
    }

    // MAP FOR EDIT
    const subRules = rule.subRules.map(subRule => this.mapApiRuleConditionToRuleDraft(subRule));

    const appliedDateRange = rule.appliedDateRange
      ? {
          startDate: new Date(rule.appliedDateRange?.startDate),
          endDate: new Date(rule.appliedDateRange?.endDate),
          timeZone: rule.appliedDateRange?.timeZone,
        }
      : null;

    const sourceCondition = (rule.sourceCondition || [])
      .filter(condition => condition.field && condition.subType)
      .map(condition => this.mapApiRuleConditionToRuleDraft(condition));

    const filterByCondition = rule.filterByCondition?.map(subRule =>
      this.mapFilterByConditionToRuleDraft(subRule)
    );

    const ruleDraft: RuleDraft = {
      id: rule.id,
      entityId: rule.entityId,
      name: rule.name,
      kpi: rule.kpi,
      product: rule.product,
      action: rule.action,
      weight: rule.weight,
      active: rule.active,
      searchCondition: rule.searchCondition, // TODO
      redirectRule: rule.redirectRule,
      sourceCondition,
      appliedDateRange,
      subRules,
      createdAt: rule.createdAt,
      updatedAt: rule.updatedAt,
      regions: rule.regions,
      ...(filterByCondition && { filterByCondition }),
    };

    return ruleDraft;
  },

  mapRuleDraftToApiRule(
    ruleDraft: Required<RuleDraft>
  ): MerchandisingRuleTypes.MerchandiseRule | Omit<MerchandisingRuleTypes.MerchandiseRule, 'id'> {
    const subRules: MerchandisingRuleTypes.SubRule[] = ruleDraft.subRules.map(condition =>
      this.mapDraftConditionToSubRule(condition as Required<RuleDraftCondition>)
    );

    const sourceCondition = this.mapDraftSourceConditionToApi(
      ruleDraft.sourceCondition as Required<RuleDraftCondition>[] // TODO: typing?
    );

    const filterByCondition = ruleDraft.filterByCondition?.map(subRule =>
      this.mapFilterByConditionToRuleDraft(subRule)
    );

    const appliedDateRange = ruleDraft.appliedDateRange
      ? {
          startDate: new Date(ruleDraft.appliedDateRange?.startDate),
          endDate: new Date(ruleDraft.appliedDateRange?.endDate),
          timeZone: ruleDraft.appliedDateRange?.timeZone,
        }
      : null;

    const rule: MerchandisingRuleTypes.MerchandiseRule = {
      id: ruleDraft.id,
      entityId: ruleDraft.entityId,
      name: ruleDraft.name,
      kpi: ruleDraft.kpi,
      product: ruleDraft.product,
      action: ruleDraft.action,
      weight: ruleDraft.weight,
      active: ruleDraft.active,
      searchCondition: ruleDraft.searchCondition,
      redirectRule: ruleDraft.redirectRule,
      sourceCondition,
      subRules,
      appliedDateRange,
      createdAt: ruleDraft.createdAt,
      updatedAt: ruleDraft.updatedAt,
      regions: ruleDraft.regions,
      ...(filterByCondition && { filterByCondition }),
    };

    return rule;
  },

  mapApiRuleConditionToRuleDraft(
    condition: MerchandisingRuleTypes.RootCondition
  ): RuleDraftCondition {
    return {
      field: condition.field,
      fieldType: condition.fieldType,
      subType: condition.subType,
      values: condition.values,
      position: condition.position,
      tempId: Math.random(),
    };
  },

  mapFilterByConditionToRuleDraft(
    condition: MerchandisingRuleTypes.FilterByCondition
  ): RuleDraftCondition {
    return {
      field: condition.field,
      fieldType: condition.fieldType,
      subType: condition.subType,
      values: condition.values,
      tempId: Math.random(),
    };
  },

  mapDraftConditionToSubRule(
    condition: Required<RuleDraftCondition>
  ): MerchandisingRuleTypes.MerchandiseRule['subRules'][0] {
    return {
      enabled: true, // unused
      field: condition.field,
      fieldType: condition.fieldType,
      subType: condition.subType,
      values: condition.values,
      position: condition.position,
    };
  },

  mapDraftSourceConditionToApi(
    sourceConditions: Required<RuleDraftCondition>[]
  ): MerchandisingRuleTypes.RootCondition[] {
    if (sourceConditions.length === 0) {
      return [
        {
          enabled: false,
          values: [],
          fieldType: FieldType.CatalogField,
        },
      ];
    }

    return sourceConditions.map(condition => ({
      enabled: true, // Set to true or adjust based on your logic
      values: condition.values,
      field: condition.field,
      fieldType: condition.fieldType,
      subType: condition.subType,
    }));
  },

  createEmptyCondition(): RuleDraftCondition {
    return {
      field: '',
      fieldType: FieldType.CatalogField,
      subType: undefined,
      values: [],
      tempId: Math.random(),
    };
  },

  createEmptyCategoryPageCondition(categoryFilterRule?: CategoryFilterRule): RuleDraftCondition {
    return {
      field: categoryFilterRule?.field || 'categories',
      fieldType: FieldType.CatalogField,
      subType:
        categoryFilterRule?.operator || MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
      values: [],
      tempId: Math.random(),
    };
  },

  createEmptyPinToPositionCondition(): RuleDraftCondition {
    return {
      fieldType: FieldType.CatalogField,
      field: 'sku',
      subType: MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
      values: [],
      position: undefined,
      tempId: Math.random(),
    };
  },
};
