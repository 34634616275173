import React from 'react';
import { useCollapse } from '../../hooks/useCollapse';
import {
  Accordion,
  AvailableIcons,
  Icon,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import {
  AccordionHeaderStyled,
  ProductDataGroupStyled,
  TitleStyled,
} from './ProductDetails.styled';
import { AccordionTriggerGroup } from '../VisualEditor.styles';

export const ProductSection = ({
  title,
  accordionId,
  children,
}: {
  title: string;
  accordionId: string;
  children: React.ReactNode;
}) => {
  const { expandedId, expand } = useCollapse(accordionId);

  return (
    <Accordion expandedIds={expandedId} onSelectionChanged={expand}>
      <Accordion.Item id={accordionId} observeHeight>
        <Accordion.Item.Header>
          <AccordionHeaderStyled>
            <AccordionTriggerGroup>
              <TitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                {title}
              </TitleStyled>
              <Icon className={!expandedId ? 'closed' : ''} name={AvailableIcons.RightArrow} />
            </AccordionTriggerGroup>
          </AccordionHeaderStyled>
        </Accordion.Item.Header>
        <Accordion.Item.Content>
          <ProductDataGroupStyled>{children}</ProductDataGroupStyled>
        </Accordion.Item.Content>
      </Accordion.Item>
    </Accordion>
  );
};
