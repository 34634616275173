export enum FashionGranularityType {
  ParentSKU = 'parent_sku',
  SizeSKU = 'size_sku',
  ColorSKU = 'color_sku',
}

export const granularityTypes = {
  ...FashionGranularityType,
};

export type GranularityType = FashionGranularityType;

export enum SyteProductPlacement {
  PDP = 'pdp',
  PLP = 'plp',
  Cart = 'cart',
  Homepage = 'homepage',
  Checkout = 'checkout',
  Search = 'search',
  LandingPage = 'landing_page',
  Page404 = 'page404',
  Blog = 'blog',
  Email = 'email',
}

export const syteProductPlacementToDisplayName: Record<SyteProductPlacement, string> = {
  [SyteProductPlacement.PDP]: 'PDP',
  [SyteProductPlacement.PLP]: 'PLP',
  [SyteProductPlacement.Cart]: 'Cart',
  [SyteProductPlacement.Homepage]: 'Homepage',
  [SyteProductPlacement.Checkout]: 'Thank you / Checkout',
  [SyteProductPlacement.Search]: 'Search Results Page',
  [SyteProductPlacement.LandingPage]: 'Landing Page',
  [SyteProductPlacement.Page404]: '404 Page',
  [SyteProductPlacement.Blog]: 'Blog',
  [SyteProductPlacement.Email]: 'Email',
};
