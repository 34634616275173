/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { isEqual } from 'lodash';
import { AxiosProgressEvent } from 'axios';
import {
  DeepTagsReportsConfiguration,
  DeepTagsReportsRecognitionType,
  MetaTypes,
} from 'src/services';
import { downloadFile } from 'src/utils/download-file';
import {
  Button,
  ConfirmationDialog,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { Dispatch } from '../../../types';
import { deepTagReportFileUploadModalActions } from './DeepTagReportFileUploadModal.actions';
import { DeepTagReportSettingsSection } from '../DeepTagReportsSettingsSection/DeepTagReportSettingsSection';
import {
  FileUploaderCSVStyled,
  UploadProgressStyled,
  UploadConfirmationDialogStyled,
} from './DeepTagReportFileUploadModal.styles';

const MAX_FILE_SIZE_MB = 100;

interface UploadFormState extends Pick<DeepTagsReportsConfiguration, 'locale' | 'recognitionType'> {
  productsFiles: File[];
}

export interface DeepTagReportFileUploadModalProps {
  shopId: number;
  languages: Pick<MetaTypes.Language, 'locale' | 'displayName'>[];
  exampleFilePath: string;
  onCancel: () => void;
  dispatch: Dispatch;
}

export const DeepTagReportFileUploadModal = ({
  onCancel,
  shopId,
  dispatch,
  languages,
  exampleFilePath,
}: DeepTagReportFileUploadModalProps): JSX.Element => {
  const initialFormState = useMemo(
    () =>
      ({
        locale: languages?.[0]?.locale || 'en_US',
        recognitionType: DeepTagsReportsRecognitionType.ImageToDeepTags,
        productsFiles: [],
      }) as UploadFormState,
    [languages]
  );

  const hasNoEnabledLexicons = languages.length === 0;

  const [formState, setFormState] = useState(initialFormState);

  const uploadCancellationRef = useRef(null as AbortController | null);

  const [uploadProgress, setUploadProgress] = useState(undefined as number | undefined);

  const isInProgress = uploadProgress !== undefined;

  const disableInputs = isInProgress || hasNoEnabledLexicons;

  const productsFile = formState.productsFiles?.[0];

  const isDirty = useMemo(() => {
    return !isEqual(formState, initialFormState) || !!productsFile;
  }, [formState]);

  const isValid = isDirty && !!productsFile && !hasNoEnabledLexicons;

  const onFormStateChange = (partial: Partial<UploadFormState>) => {
    setFormState({ ...formState, ...partial });
  };

  const cancelUploadProgress = () => {
    if (!uploadCancellationRef.current) {
      return;
    }

    try {
      uploadCancellationRef.current.abort();
    } catch (error) {
      console.error(error);
    } finally {
      uploadCancellationRef.current = null;
    }
  };

  const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
    if (
      !progressEvent.upload ||
      progressEvent.progress === undefined ||
      !uploadCancellationRef.current || // component might be  unmounting
      uploadCancellationRef.current.signal.aborted
    ) {
      return;
    }

    const PERCENT_MULTIPLIER = 100;

    const progressRatio = Math.ceil(progressEvent.progress * PERCENT_MULTIPLIER);

    setUploadProgress(progressRatio);
  };

  const onApply = useCallback(async () => {
    if (isValid) {
      setUploadProgress(0);

      // in case somehow previous cancellation didn't clear properly the ref.
      cancelUploadProgress();

      uploadCancellationRef.current = new AbortController();

      const result = (await dispatch(
        deepTagReportFileUploadModalActions.createReport({
          data: {
            locale: formState.locale,
            recognitionType: formState.recognitionType,
            productsFile,
            shopId,
          },
          cancellationSignal: uploadCancellationRef.current.signal,
          onUploadProgress,
        })
      )) as any; // TODO: improve typing

      if (result?.error) {
        setUploadProgress(undefined);
        return;
      }

      dispatch(deepTagReportFileUploadModalActions.updateIsDirty({ isDirty: false }));
      onCancel();
    }
  }, [isValid, formState, shopId]);

  const onExampleClick = () => {
    downloadFile({
      filePath: exampleFilePath,
      fileDisplayName: 'tags_enrichment_upload_example.csv',
    });
  };

  useEffect(() => {
    dispatch(deepTagReportFileUploadModalActions.updateIsDirty({ isDirty }));
  }, [isDirty]);

  useEffect(() => {
    return () => {
      cancelUploadProgress();
    };
  }, []);

  return (
    <UploadConfirmationDialogStyled onCancel={onCancel}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Upload CSV File</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        <ConfirmationDialog.ContentMainText>
          Uploading a file can take time, depending on file size
        </ConfirmationDialog.ContentMainText>
        <DeepTagReportSettingsSection
          locale={formState.locale}
          recognitionType={formState.recognitionType}
          languages={languages}
          onChange={onFormStateChange}
          disabled={disableInputs}
        />
        <FileUploaderCSVStyled
          selectedFiles={formState.productsFiles}
          onChange={files => onFormStateChange({ productsFiles: files })}
          maxSizeMb={MAX_FILE_SIZE_MB}
          onExampleClick={onExampleClick}
          disabled={disableInputs}
        />
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <Button variant='primary' onClick={onApply} disabled={!isValid} loading={isInProgress}>
          Apply
        </Button>
        <Button variant='tertiary' onClick={onCancel}>
          Cancel
        </Button>
        {uploadProgress !== undefined && (
          <UploadProgressStyled
            type={TypographyType.Body}
            variant={TypographyVariant.MediumRegular}
          >
            {`Uploading ${uploadProgress}%...`}
          </UploadProgressStyled>
        )}
      </ConfirmationDialog.Footer>
    </UploadConfirmationDialogStyled>
  );
};
