import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IDeepTagReportProductComment,
  DeepTagReportsService,
  GetDeepTagReportProductArguments,
} from 'src/services';

const deepTagReportsService = new DeepTagReportsService();

export const deepTagsProductCommentsActions = {
  getProductCommentsList: createAsyncThunk(
    'DeepTagsProductCommentsActions/GetCommentsList',
    async (productArgs: GetDeepTagReportProductArguments, { rejectWithValue }) => {
      try {
        const productComments = await deepTagReportsService.getProductCommentsList(productArgs);
        return { productComments };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  createProductComment: createAsyncThunk(
    'DeepTagsProductCommentsActions/CreateComment',
    async (
      {
        comment,
        ...productArgs
      }: { comment: IDeepTagReportProductComment } & GetDeepTagReportProductArguments,
      { rejectWithValue }
    ) => {
      try {
        const createdComment = await deepTagReportsService.createProductComment({
          comment,
          ...productArgs,
        });
        return createdComment;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateProductComment: createAsyncThunk(
    'DeepTagsProductCommentsActions/UpdateComment',
    async (
      {
        comment,
        ...productArgs
      }: { comment: IDeepTagReportProductComment } & GetDeepTagReportProductArguments,
      { rejectWithValue }
    ) => {
      try {
        const updatedComment = await deepTagReportsService.updateProductComment({
          comment,
          ...productArgs,
        });
        return updatedComment;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  deleteProductComment: createAsyncThunk(
    'DeepTagsProductCommentsActions/DeleteComment',
    async (
      {
        commentId,
        ...productArgs
      }: { commentId: IDeepTagReportProductComment['id'] } & GetDeepTagReportProductArguments,
      { rejectWithValue }
    ) => {
      try {
        await deepTagReportsService.deleteProductComment({
          commentId,
          ...productArgs,
        });

        return {};
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
