import { AxiosResponse } from 'axios';
import { ApiServiceBase } from '../api-service-base';
import { NotificationsMapper } from './notifications.mapper';
import { MappedNotificationsResponse, NotificationsResponse } from './types';

export class NotificationsService extends ApiServiceBase {
  constructor() {
    super('notifications');
  }

  async getNotifications({
    shopId,
    skip,
    limit,
    viewed,
  }: {
    shopId?: number;
    skip?: number;
    limit?: number;
    viewed?: boolean;
  }): Promise<MappedNotificationsResponse> {
    const url = `${this.serviceBaseUri}`;

    const response: AxiosResponse<NotificationsResponse> = await this.httpService.get({
      url,
      requestConfig: {
        params: {
          shopId,
          skip,
          limit,
          viewed,
        },
      },
    });

    return NotificationsMapper.mapNotificationsResponse(response.data);
  }

  async deleteNotificationById({
    shopId,
    notificationId,
  }: {
    shopId?: number;
    notificationId: string;
  }): Promise<void> {
    const url = `${this.serviceBaseUri}/${notificationId}`;

    await this.httpService.delete({
      url,
      requestConfig: {
        params: {
          shopId,
        },
      },
    });
  }

  async updateNotifications({ shopId }: { shopId?: number }): Promise<void> {
    const url = `${this.serviceBaseUri}`;

    await this.httpService.patch({
      url,
      requestConfig: {
        params: {
          shopId,
        },
      },
    });
  }

  async markAllAsRead({ shopId }: { shopId?: number }): Promise<void> {
    const url = `${this.serviceBaseUri}`;

    await this.httpService.patch({
      url,
      data: { isViewed: true },
      requestConfig: {
        params: {
          shopId,
        },
      },
    });
  }

  async updateNotificationById({
    shopId,
    notificationId,
    isViewed,
  }: {
    shopId?: number;
    notificationId: string;
    isViewed: boolean;
  }): Promise<void> {
    const url = `${this.serviceBaseUri}/${notificationId}`;

    await this.httpService.patch({
      url,
      data: { isViewed },
      requestConfig: { params: { shopId } },
    });
  }
}

export const notificationsService = new NotificationsService();
