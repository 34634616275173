import { generatePath } from 'react-router-dom';
import { experimentCardActionMethods } from 'src/components-bl';
import { experimentService } from 'src/services';
import { Dispatch } from '../../../types';
import {
  CreateExperimentFormActionTypes,
  CreateExperimentFormCreateExperimentRequestActionPayload,
  CreateExperimentFormCreateExperimentRequestAction,
  CreateExperimentFormCreateExperimentSuccessActionPayload,
  CreateExperimentFormCreateExperimentSuccessAction,
  CreateExperimentFormCreateExperimentErrorActionPayload,
  CreateExperimentFormCreateExperimentErrorAction,
  CreateExperimentFormCreateExperimentActionPayload,
} from './types';

const createExperimentFormActions = {
  createExperimentRequest(
    payload: CreateExperimentFormCreateExperimentRequestActionPayload
  ): CreateExperimentFormCreateExperimentRequestAction {
    return {
      type: CreateExperimentFormActionTypes.CreateExperimentRequest,
      payload,
    };
  },

  createExperimentSuccess(
    payload: CreateExperimentFormCreateExperimentSuccessActionPayload
  ): CreateExperimentFormCreateExperimentSuccessAction {
    return {
      type: CreateExperimentFormActionTypes.CreateExperimentSuccess,
      payload,
    };
  },

  createExperimentError(
    payload: CreateExperimentFormCreateExperimentErrorActionPayload
  ): CreateExperimentFormCreateExperimentErrorAction {
    return {
      type: CreateExperimentFormActionTypes.CreateExperimentError,
      payload,
    };
  },
};

export const createExperimentFormActionMethods = {
  createExperiment({
    shopId,
    name,
    editExperimentFormRoute,
  }: CreateExperimentFormCreateExperimentActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(
          createExperimentFormActions.createExperimentRequest({
            shopId,
            name,
          })
        );
        const experiment = await experimentService.createExperiment({ shopId, name });

        dispatch(createExperimentFormActions.createExperimentSuccess({ experiment }));

        dispatch(
          experimentCardActionMethods.navigateTo({
            navigateTo: generatePath(editExperimentFormRoute, {
              experimentId: experiment.id,
              shopId,
            }),
          })
        );
      } catch (error) {
        dispatch(createExperimentFormActions.createExperimentError({ error }));
      }
    };
  },
};
