/* eslint-disable class-methods-use-this */
import { parseISO } from 'date-fns';
import { AxiosResponse } from 'axios';
import { sortArrayByLocale } from 'src/utils/sort-array-by-locale';
import { getLanguageCodeByLocale } from 'src/utils';
import {
  DeepTagReport,
  DeepTagReportAPI,
  DeepTagReportWithMetaData,
  DeepTagReportProductPagination,
  DeepTagReportProductAPI,
  IDeepTagReportProduct,
  ProductOriginalData,
  ProductOriginalDataTag,
  OriginalDataTagAPI,
  DeepTagsReportsConfiguration,
  DeepTagAPI,
  IDeepTagsProductDataItemAPI,
  IDeepTagsProductDataItem,
  DeepTagProductStatusType,
} from '../types';
import { generateMockReport } from './deep-tag-reports.mock';

export class DeepTagReportsMapper {
  static mockReports(): DeepTagReport[] {
    return [generateMockReport()];
  }

  static mapReport(report: DeepTagReportAPI): DeepTagReport {
    return {
      ...report,
      createdAt: parseISO(report.createdAt),
      updatedAt: parseISO(report.updatedAt),
    } as DeepTagReport;
  }

  static mapReports({ data }: AxiosResponse): DeepTagReport[] {
    return data.reports.map((report: DeepTagReportAPI) => this.mapReport(report));
  }

  static mapReportWithMetaData({ data }: AxiosResponse): DeepTagReportWithMetaData {
    return this.mapReport(data) as DeepTagReportWithMetaData;
  }

  static mapReportConfiguration({ data }: AxiosResponse): DeepTagsReportsConfiguration {
    return data as DeepTagsReportsConfiguration;
  }

  static mapOriginalDataTags = (
    originalDataTags: OriginalDataTagAPI[]
  ): ProductOriginalData['tags'] => {
    return originalDataTags.reduce(
      (accumulator: ProductOriginalData['tags'], tag: ProductOriginalDataTag) => {
        accumulator[tag.attribute.key] = tag;
        return accumulator;
      },
      {}
    );
  };

  static mapReportProduct(product: DeepTagReportProductAPI, locale: string): IDeepTagReportProduct {
    const { originalData } = product;

    const sortTags = (tags: DeepTagAPI[]) => {
      return tags.sort((a: DeepTagAPI, b: DeepTagAPI) => {
        return sortArrayByLocale({
          a: a.attribute.translation,
          b: b.attribute.translation,
          languageCode: getLanguageCodeByLocale(locale),
          isAscending: true,
        });
      });
    };

    const productOriginalDataTagsMap = DeepTagReportsMapper.mapOriginalDataTags(originalData.tags);
    const sortedProductTags = sortTags(product.tags);

    const mappedProductDataList: IDeepTagsProductDataItem[] = product.data.map(
      (dataObject: IDeepTagsProductDataItemAPI): IDeepTagsProductDataItem => {
        const sortedDataObjectTags = sortTags(dataObject.tags);

        return {
          ...dataObject,
          tags: sortedDataObjectTags,
          originalData: {
            ...dataObject.originalData,
            tags: DeepTagReportsMapper.mapOriginalDataTags(dataObject.originalData.tags),
          },
        };
      }
    );

    return {
      ...product,
      originalData: {
        ...originalData,
        tags: productOriginalDataTagsMap,
      },
      status: product.status || DeepTagProductStatusType.New,
      tags: sortedProductTags,
      data: mappedProductDataList,
      createdAt: parseISO(product.createdAt),
      updatedAt: parseISO(product.updatedAt),
    };
  }

  static mapProductsPagination(
    { data }: AxiosResponse,
    locale: string
  ): DeepTagReportProductPagination {
    const convertedProducts = data.data.products.map((product: DeepTagReportProductAPI) => {
      return this.mapReportProduct(product, locale);
    });

    return {
      ...data,
      data: {
        products: convertedProducts,
        availableFilterKeysMap: data.data.availableFilterKeysMap,
      },
    };
  }
}
