import React, { useCallback } from 'react';
import { startCase } from 'lodash';
import { Row } from 'react-table';
import { ParseJoiValidateResponse } from 'src/utils';
import { EllipsisWithTooltip, TableV2 } from 'src/components-dummy';
import { ShopDataField } from 'src/services';
import {
  DataFieldTableRowStyled,
  OriginalNameCellTextStyled,
} from '../../DataFieldsTableForm.styles';
import { IDataFieldsTableHeadRow } from '../../types';
import { DisplayNameCell } from './DisplayNameCell';

export interface DataFieldTableRowProps {
  row: Row<ShopDataField>;
  tableColumns: Record<string, IDataFieldsTableHeadRow>;
  onChange: (updatedDataField: ShopDataField) => void;
  errors?: ParseJoiValidateResponse<ShopDataField>;
}

export const DataFieldTableRow = React.memo(
  ({ row, tableColumns, onChange, errors }: DataFieldTableRowProps): JSX.Element => {
    const onDisplayNameChange = useCallback(
      (newDisplayName: string) => {
        onChange({ ...row.original, displayName: newDisplayName });
      },
      [onChange, row]
    );

    const renderCellContent = useCallback(
      cell => {
        const propName = cell.column.id;

        switch (propName) {
          case tableColumns.types.accessor: {
            const displayTypes = (cell.value as string[]).map(type => startCase(type)).join(', ');

            return <TableV2.BodyRowCellText>{displayTypes}</TableV2.BodyRowCellText>;
          }
          case tableColumns.catalogNames?.accessor: {
            const catalogNames = (cell.value as string[]).join(', ');

            return (
              <EllipsisWithTooltip tooltipText={catalogNames}>{catalogNames}</EllipsisWithTooltip>
            );
          }
          case tableColumns.name.accessor: {
            return <OriginalNameCellTextStyled>{cell.value}</OriginalNameCellTextStyled>;
          }
          case tableColumns.displayName.accessor: {
            return (
              <DisplayNameCell
                value={cell.value}
                onChange={onDisplayNameChange}
                error={errors?.displayName?.message}
              />
            );
          }
          default:
            return <TableV2.BodyRowCellText>{cell.value}</TableV2.BodyRowCellText>;
        }
      },
      [errors, onDisplayNameChange]
    );

    return (
      <DataFieldTableRowStyled
        {...row.getRowProps()}
        className='data-field-table-row'
        key={row.original.id}
      >
        {row.cells.map(cell => {
          const propName = cell.column.id;
          const cellKey = `${row.original.id}-${propName}-${cell.value}`;

          return (
            <TableV2.BodyRowCell
              {...cell.getCellProps()}
              key={cellKey}
              width={tableColumns[propName].width}
            >
              {renderCellContent(cell)}
            </TableV2.BodyRowCell>
          );
        })}
      </DataFieldTableRowStyled>
    );
  }
);
