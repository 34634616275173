import styled from '@emotion/styled';

export const Container = styled.div`
  height: calc(100dvh + 32px);
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px;
`;
