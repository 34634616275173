import React, { useCallback, useMemo } from 'react';
import { FormCard, SelectType, MenuItem, SelectOnChangeEvent } from 'src/components-dummy';
import { AugmentedSearchGeneralSettings } from 'src/services';
import { getStringEnumValues } from 'src/utils';
import { AutoRedirectSelectStyled } from './RedirectRulesCard.styles';

enum AutoRedirectValues {
  AutoSuggestModalOnly = 'auto_suggest_modal',
  AutoSuggestModalWithResultsPage = 'auto_suggest_modal_results_page',
}

const autoRedirectValuesToDisplayText: Record<AutoRedirectValues, string> = {
  [AutoRedirectValues.AutoSuggestModalOnly]: 'Auto Suggest Modal',
  [AutoRedirectValues.AutoSuggestModalWithResultsPage]: 'Auto Suggest Modal And Results Page',
};

const autoRedirectSelectOptions = getStringEnumValues(AutoRedirectValues).map(value => ({
  value,
  text: autoRedirectValuesToDisplayText[value],
}));

interface RedirectRulesCardProps {
  augmentedSearchSettingsDraft: AugmentedSearchGeneralSettings;
  onChange: (payload: Partial<AugmentedSearchGeneralSettings>) => void;
}

export function RedirectRulesCard({
  augmentedSearchSettingsDraft,
  onChange,
}: RedirectRulesCardProps): JSX.Element {
  const autoRedirectSelectValue = useMemo(
    () =>
      augmentedSearchSettingsDraft.redirectOnItems
        ? AutoRedirectValues.AutoSuggestModalWithResultsPage
        : AutoRedirectValues.AutoSuggestModalOnly,
    [augmentedSearchSettingsDraft.redirectOnItems]
  );

  const onSelect: SelectOnChangeEvent = useCallback(
    event => {
      const { value } = event.target;

      const redirectOnItems = value === AutoRedirectValues.AutoSuggestModalWithResultsPage;

      onChange({ redirectOnItems });
    },
    [onChange]
  );

  return (
    <FormCard>
      <FormCard.Title>Redirect Rules</FormCard.Title>
      <FormCard.Content>
        <AutoRedirectSelectStyled
          label='Apply Auto Redirect'
          value={autoRedirectSelectValue}
          type={SelectType.Primary}
          placeholder='Select'
          onChange={onSelect}
        >
          {autoRedirectSelectOptions.map(({ value, text }) => (
            <MenuItem key={value} value={value}>
              {text}
            </MenuItem>
          ))}
        </AutoRedirectSelectStyled>
      </FormCard.Content>
    </FormCard>
  );
}
