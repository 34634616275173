import { DomainEntityPath, IShopCatalog, IShopCatalogAPI } from '../types';
import { CatalogManagerMapper } from './catalog-manager.mapper';
import { DomainEventsWebSocketServiceBase, DomainEvent } from '../domain-events';

export enum CatalogManagerEventTopicNames {
  ShopCatalogUpdate = 'shop_catalogs:update',
  ShopCatalogCreate = 'shop_catalogs:create',
  ShopCatalogDelete = 'shop_catalogs:delete',
}

export interface IShopCatalogUpdateEventDataRaw {
  catalog: IShopCatalogAPI;
  shopId: number;
}

export interface IShopCatalogUpdateEventData {
  catalog: IShopCatalog;
  shopId: number;
}

export interface IShopCatalogDeleteEventData {
  id: string;
  shopId: number;
}

export type IShopCatalogUpdateEvent = DomainEvent<
  IShopCatalogUpdateEventData,
  CatalogManagerEventTopicNames
>;

export type IShopCatalogDeleteEvent = DomainEvent<
  IShopCatalogDeleteEventData,
  CatalogManagerEventTopicNames
>;

class CatalogManagerWebSocketService extends DomainEventsWebSocketServiceBase<CatalogManagerEventTopicNames> {
  domainEntity = DomainEntityPath.ShopCatalogs;

  subscribeToCatalogCreateEvents(
    onCatalogCreate: (payload: IShopCatalogUpdateEvent) => void
  ): void {
    this.subscribeToEvents({
      topicKey: CatalogManagerEventTopicNames.ShopCatalogCreate,
      callback: (response: IShopCatalogUpdateEvent) => {
        const mappedCatalog = CatalogManagerMapper.mapCatalog(response.data.catalog as any);

        response.data.catalog = mappedCatalog;

        onCatalogCreate(response);
      },
    });
  }

  subscribeToCatalogUpdateEvents(
    onCatalogUpdate: (payload: IShopCatalogUpdateEvent) => void
  ): void {
    this.subscribeToEvents({
      topicKey: CatalogManagerEventTopicNames.ShopCatalogUpdate,
      callback: (response: IShopCatalogUpdateEvent) => {
        const mappedCatalog = CatalogManagerMapper.mapCatalog(response.data.catalog as any);

        response.data.catalog = mappedCatalog;

        onCatalogUpdate(response);
      },
    });
  }

  subscribeToCatalogDeleteEvents(
    onCatalogDelete: (payload: IShopCatalogDeleteEvent) => void
  ): void {
    this.subscribeToEvents({
      topicKey: CatalogManagerEventTopicNames.ShopCatalogDelete,
      callback: onCatalogDelete,
    });
  }
}

export const catalogManagerWebSocketService = new CatalogManagerWebSocketService();
