/* eslint-disable react/no-unescaped-entities */
import { css } from '@emotion/react';
import React, { useMemo, useState } from 'react';
import {
  Box,
  TextBox,
  Switch,
  Icon,
  AvailableIcons,
  Tooltip,
  Select,
  SelectType,
  MenuItem,
} from 'src/components-dummy';
import { IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { copyToClipboard } from 'src/utils';
import { DownloaderTypesEnum } from 'src/services';
import { AUTHENTICATION, CatalogFormDraft } from '../../CatalogForm.config';
import { ButtonCopyClipboardStyled } from './CatalogFormImportMethodSection.styles';
import { AuthenticationSelect } from './AuthenticationSelect';

export interface CatalogFormSFTPSettingsProps {
  onFieldChange: (partialData: Partial<CatalogFormDraft>) => void;
  errors?: any;
  isCustomerHasSFTPCredentials: boolean;
  onNotifyCopyOnClipboard: () => void;
  sftpServerSettings?: CatalogFormDraft['sftpServerSettings'];
  isUsingCustomSftp: boolean;
}

export const CatalogFormSFTPSettings = React.memo(
  ({
    onFieldChange,
    sftpServerSettings,
    isCustomerHasSFTPCredentials,
    onNotifyCopyOnClipboard,
    isUsingCustomSftp,
    errors,
  }: CatalogFormSFTPSettingsProps): JSX.Element => {
    const [shouldShowPassword, setShouldShowPassword] = useState(false);

    const onFormStateChange =
      (propName: string) =>
      (propValue: string | boolean): void => {
        onFieldChange({
          sftpServerSettings: {
            ...sftpServerSettings,
            [propName]: typeof propValue === 'string' ? propValue.trim() : propValue,
          },
        } as Pick<CatalogFormDraft, 'sftpServerSettings'>);
      };

    const onFormIsUsingCustomSftpChange = (value: boolean) => {
      onFieldChange({
        isUsingCustomSftp: value,
      });
    };

    const onAuthenticationChange = (event: any) => {
      const key = event.target.value as keyof typeof AUTHENTICATION;
      const { value } = AUTHENTICATION[key];

      onFormStateChange('authentication')(value);
    };

    const onTypeChange = (event: any) => {
      onFormStateChange('type')(event.target.value);
    };

    const onChangeShowPassword = () => {
      setShouldShowPassword(isPassword => !isPassword);
    };

    const onCopyToClipboard = () => {
      const { username, password, host, port, path, type, authentication } =
        sftpServerSettings || {};
      let url;

      if (authentication) {
        url = `${type}://${username}:${password}@${host}:${port}${path}`;
      } else {
        url = `${type}://${host}:${port}${path}`;
      }

      copyToClipboard(url);
      onNotifyCopyOnClipboard();
    };

    const isDisabled = !isUsingCustomSftp;

    const shouldCopyToClipboardBeAvailable = useMemo(() => {
      const baseCondition =
        !!sftpServerSettings?.host && !!sftpServerSettings?.port && !!sftpServerSettings?.path;

      if (sftpServerSettings?.authentication) {
        return (
          !!baseCondition &&
          !errors['sftpServerSettings.username'] &&
          !errors['sftpServerSettings.password']
        );
      }

      return baseCondition;
    }, [sftpServerSettings, errors]);

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '16px',
          }}
        >
          <Select
            type={SelectType.Primary}
            label='Method'
            value={sftpServerSettings?.type || 'SFTP'}
            onChange={onTypeChange}
            disabled={!isUsingCustomSftp}
            css={css`
              flex: 2;
            `}
          >
            <MenuItem key={DownloaderTypesEnum.SFTP} value='sftp'>
              SFTP
            </MenuItem>
            <MenuItem key={DownloaderTypesEnum.FTP} value='ftp'>
              FTP
            </MenuItem>
          </Select>
          <TextBox
            label='Hostname'
            value={sftpServerSettings?.host}
            placeholder='Add host'
            error={!!errors['sftpServerSettings.host']}
            onChange={onFormStateChange('host')}
            disabled={isDisabled}
            css={css`
              flex: 4;
            `}
          />
          <TextBox
            label='Port'
            value={sftpServerSettings?.port || ''}
            error={!!errors['sftpServerSettings.port']}
            placeholder='Port'
            onChange={onFormStateChange('port')}
            disabled={isDisabled}
            css={css`
              flex: 1;
            `}
          />
          <TextBox
            label='Folder'
            value={sftpServerSettings?.path || ''}
            error={!!errors['sftpServerSettings.path']}
            placeholder='/folder'
            onChange={onFormStateChange('path')}
            disabled={isDisabled}
            css={css`
              flex: 3;
            `}
          />
          <div
            css={css`
              flex: 3;
              position: relative;
            `}
          >
            <TextBox
              label={
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                  `}
                >
                  File Name &nbsp;
                  <Tooltip value='You can add a datetime suffix to the filename if needed'>
                    <Icon name={AvailableIcons.Information} />
                  </Tooltip>
                </div>
              }
              value={sftpServerSettings?.fileName || ''}
              error={!!errors['sftpServerSettings.fileName']}
              placeholder='syte_productfeed.csv'
              onChange={onFormStateChange('fileName')}
              css={css`
                flex: 3;
              `}
            />
          </div>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginBottom: '8px',
            gap: '16px',
          }}
        >
          <AuthenticationSelect
            value={!!sftpServerSettings?.authentication}
            onChange={onAuthenticationChange}
            isDisabled={isDisabled}
            css={css`
              flex: 1;
            `}
          />
          {sftpServerSettings?.authentication && (
            <>
              <TextBox
                label='Username'
                key='sftp-username'
                value={sftpServerSettings?.username || ''}
                error={!!errors['sftpServerSettings.username']}
                placeholder='Username'
                onChange={onFormStateChange('username')}
                disabled={isDisabled}
                autoComplete='new-user-name'
                css={css`
                  flex: 2;
                  max-width: 324px;
                `}
              />
              <TextBox
                label='Password'
                key='sftp-password'
                type={shouldShowPassword ? 'text' : 'password'}
                value={sftpServerSettings?.password || ''}
                error={!!errors['sftpServerSettings.password']}
                placeholder='Password'
                onChange={onFormStateChange('password')}
                disabled={isDisabled}
                autoComplete='new-password'
                css={css`
                  flex: 2;
                  max-width: 324px;
                `}
              >
                {isDisabled ? null : (
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={onChangeShowPassword}
                    onMouseDown={onChangeShowPassword}
                    css={css`
                      margin-left: -7px;
                    `}
                  >
                    {shouldShowPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                )}
              </TextBox>
            </>
          )}

          <ButtonCopyClipboardStyled
            variant='primary'
            disabled={!shouldCopyToClipboardBeAvailable}
            onClick={onCopyToClipboard}
            css={css`
              width: 163px;
              max-width: 163px;
            `}
          >
            <Icon name={AvailableIcons.Copy} />
            <span
              css={css`
                margin-left: 4px;
              `}
            >
              Copy to clipboard
            </span>
          </ButtonCopyClipboardStyled>
        </Box>
        <Switch
          className='enable-deep-tags-toggle'
          checked={!!isUsingCustomSftp}
          disabled={!isCustomerHasSFTPCredentials}
          onChange={onFormIsUsingCustomSftpChange}
        >
          <Switch.SubTitleTemplate>Use Custom SFTP Server</Switch.SubTitleTemplate>
        </Switch>
      </Box>
    );
  }
);
