import React from 'react';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import './ShopEmptyResults.scss';

export interface ShopEmptyResultsProps {
  canCreateShop: boolean;
}

export const ShopEmptyResults = ({ canCreateShop }: ShopEmptyResultsProps): JSX.Element => {
  return (
    <div className='syte-shop-empty-results'>
      <h2 className='syte-shop-empty-results-title'>Wow... so empty in here...</h2>
      <p className='syte-shop-empty-results-text'>
        <Typography type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
          This account has no assigned shops that you can view
          <br />
          {canCreateShop
            ? 'but hey, we can easily fix that. Just create one!'
            : 'to create one, please contact your Success Manager'}
        </Typography>
      </p>
    </div>
  );
};
