import { createAsyncThunk } from '@reduxjs/toolkit';
import { notificationsService } from 'src/services';

export const notificationsPopupActions = {
  markAllAsRead: createAsyncThunk(
    'NotificationsPopup/MarkAllAsRead',
    async ({ shopId }: { shopId?: number }, { rejectWithValue }) => {
      try {
        await notificationsService.markAllAsRead({
          shopId,
        });

        return null;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
