import { isString, isInteger } from 'lodash';
import { DeepTagScoreType } from 'src/services';
import { Vertical } from 'src/services/src/service/types/constants';

export function parseBoolean(value: unknown): boolean | undefined {
  if (value === 'true' || value === 'false') {
    return Boolean(value);
  }

  return undefined;
}

export function parseString(value: unknown): string | undefined {
  return isString(value) ? value : undefined;
}

export function parseInteger(value: unknown): number | undefined {
  const stringValue = (value as string).toString();
  const result = parseInt(stringValue, 10);
  return isInteger(result) ? result : undefined;
}

export function parseArrayOfStrings(value: unknown): string[] | undefined {
  const result = (value as string).toString().split(',');
  return result.length ? result : undefined;
}

const allVerticalsSet = new Set(Object.values(Vertical));

export function parseVerticals(value: unknown): Vertical[] | undefined {
  const result = ((value as string).toString().split(',') as Vertical[]).filter(v =>
    allVerticalsSet.has(v)
  );
  return result.length ? result : undefined;
}

const allTagScoreTypes = new Set(Object.values(DeepTagScoreType));

export function parseTagScoreTypes(value: unknown): DeepTagScoreType[] | undefined {
  const result = ((value as string).toString().split(',') as DeepTagScoreType[]).filter(v =>
    allTagScoreTypes.has(v)
  );
  return result.length ? result : undefined;
}
