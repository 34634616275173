import styled from '@emotion/styled';
import { Button, Icon, Menu, Typography } from '../../../../components-dummy';

export const VisualEditorContentResults = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0 16px 0;
  height: 100%;
  width: 95%;
`;

export const LoaderContainer = styled.div`
  display: flex;
  padding-top: 16px;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #dedede;
`;

export const RefreshButton = styled(Button)`
  svg {
    path {
      stroke: #ffffff;
    }
  }
`;

export const ItemsCount = styled(Typography)<{ shouldDisplayItemsCount: boolean }>`
  span {
    color: #878787;
  }
  visibility: ${({ shouldDisplayItemsCount }) => (shouldDisplayItemsCount ? 'visible' : 'hidden')};
`;

export const VisualEditorResults = styled.div`
  border-radius: 4px;
  margin-top: 24px;
  height: calc(100% - 70px);
  overflow-y: auto;
`;

export const VisualEditorTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ToolbarContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const ToolBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const LayoutSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledIcon = styled(Icon)`
  color: #6e6e71;
  cursor: pointer;
`;

export const LayoutViewButton = styled(Button)<{ active?: boolean }>`
  ${props => props.active && 'background: #E5F1FF;'}
  padding: 8px;
  min-width: 32px;
  width: 32px;
  height: 32px;
  span {
    display: flex;
  }
`;

export const MenuStyled = styled(Menu)`
  .MuiPaper-root {
    width: 172px;
    border-radius: 8px;
  }
  .MuiMenuItem-root {
    padding-left: 24px;
  }
`;

export const VisualEditorContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ResultsScreenContainer = styled.div`
  background: #f8f8f8;
  position: relative;
  flex-grow: 7;
  flex-basis: 0;
  overflow: hidden;

  ${VisualEditorContent} {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;
