/* eslint no-param-reassign:0 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SkuState, State } from './types';
import { Product, ProductType } from '../../types/entities.types';
import { ResponseValue } from '../../types/common.types';

export const initialState: State = {
  sku: '',
  type: SkuState.NoSku,
  error: null,
  product: null,
};

export const slice = createSlice({
  name: 'socialDiscovery/editSKU',
  initialState,
  reducers: {
    editSKU: (state, action: PayloadAction<string>) => {
      state.sku = action.payload;
      state.type = action.payload.trim().length ? SkuState.HasSku : SkuState.NoSku;
      state.error = null;
    },
    fetchSKU: state => {
      state.type = SkuState.Loading;
    },
    resolveSKU: (state, action: PayloadAction<ResponseValue<Product, Error | null>>) => {
      if (action.payload.error) {
        state.type = SkuState.Error;
        state.error = action.payload.error;
        return;
      }

      state.type = SkuState.Resolved;
      state.product = action.payload.data || { type: ProductType.notIndexed, sku: state.sku };
    },
  },
});
