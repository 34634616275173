import { isEqual, partition } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Typography,
  TypographyType,
  TypographyVariant,
  Button,
  SelectType,
  MenuItem,
  SelectOnChangeEvent,
  FormCard,
  SectionBox,
} from 'src/components-dummy';
import { Skeleton } from 'src/components-dummy/Skeleton';
import { Dispatch } from '../types/dispatch';
import { shopLexiconSettingsActions } from './Actions/ShopLexiconSettings.actions';
import { LocaleItem, LocaleItemProps } from './components/LocaleItem';
import {
  LanguageSelectStyled,
  LocalesContainerStyled,
  SelectLocaleItemStyled,
  ShopLexiconSettingsStyled,
  SupportedLanguagesHeaderStyled,
  SupportedLanguagesSubHeaderStyled,
} from './ShopLexiconSettings.styles';
import './ShopLexiconSettings.scss';

export type LocaleData = Pick<LocaleItemProps, 'locale' | 'displayName' | 'iconName'>;

export interface ShopLexiconSettingsProps {
  shopId: number;
  selectedLocales: string[] | undefined;
  availableLexiconLanguages:
    | Array<{ locale: string; displayName: string; iconName: string }>
    | undefined;
  onSettingsSaved(settings: { locales: string[] }): void;
  dispatch: Dispatch;
}

export const ShopLexiconSettings = ({
  shopId,
  selectedLocales: originalSelectedLocales,
  availableLexiconLanguages,
  onSettingsSaved,
  dispatch,
}: ShopLexiconSettingsProps): JSX.Element => {
  const [selectedLocales, setSelectedLocales] = useState<string[]>(() =>
    originalSelectedLocales ? [...originalSelectedLocales].sort() : []
  );

  const [isDirty, setIsDirty] = useState(false);

  const updateIsDirty = useCallback(
    ({ newIsDirty }: { newIsDirty: boolean }) => {
      if (newIsDirty !== isDirty) {
        setIsDirty(newIsDirty);
        dispatch(shopLexiconSettingsActions.notifyIsDirty({ isDirty: newIsDirty }));
      }
    },
    [isDirty]
  );

  useEffect(() => {
    setSelectedLocales(originalSelectedLocales || []);
    updateIsDirty({ newIsDirty: false });
  }, [originalSelectedLocales]);

  const updateLocales = useCallback(
    (newLocales: string[]) => {
      setSelectedLocales(newLocales.sort());

      const localesEqual = isEqual(originalSelectedLocales, newLocales);

      const newIsDirty = !localesEqual;

      updateIsDirty({ newIsDirty });
    },
    [selectedLocales]
  );

  const onDeleteClick = useCallback(
    (localeToRemove: string) => {
      const newLocales = selectedLocales?.filter(locale => locale !== localeToRemove) || [];
      updateLocales(newLocales);
    },
    [selectedLocales, updateLocales]
  );

  const onAddLanguage: SelectOnChangeEvent = useCallback(
    event => {
      const localeToAdd = event.target.value;
      const localeAlreadyExists = selectedLocales.includes(localeToAdd);

      if (selectedLocales && !localeAlreadyExists) {
        const newLocales = [...selectedLocales, localeToAdd];
        updateLocales(newLocales);
      }
    },
    [selectedLocales, updateLocales]
  );

  const { selectedLocaleItems, uncheckedLocaleItems } = useMemo(
    function splitLocaleItems() {
      const selectedLocalesSet = new Set(selectedLocales);

      const [selectedItems, uncheckedItems = []] = partition(availableLexiconLanguages, item =>
        selectedLocalesSet.has(item.locale)
      );

      return {
        selectedLocaleItems: selectedItems || [],
        uncheckedLocaleItems: uncheckedItems || [],
      };
    },
    [selectedLocales, availableLexiconLanguages]
  );

  const canAddLanguage = uncheckedLocaleItems.length > 0;

  const isValid = !!selectedLocales;

  const onSubmit = useCallback(async () => {
    if (!selectedLocales || !isValid) {
      return;
    }

    const newSettings = await (
      dispatch(
        shopLexiconSettingsActions.updateLexiconSettings({ shopId, locales: selectedLocales })
      ) as any
    ).unwrap(); // TODO typing

    onSettingsSaved(newSettings);
  }, [dispatch, shopId, selectedLocales]);

  const isLoading = !availableLexiconLanguages || !originalSelectedLocales;

  return (
    <ShopLexiconSettingsStyled>
      <FormCard>
        <FormCard.Title>Lexicon Manager</FormCard.Title>
        <FormCard.Button>
          <Button onClick={onSubmit} variant='primary' disabled={!(isValid && isDirty)}>
            Save Changes
          </Button>
        </FormCard.Button>
        <FormCard.Content>
          <SectionBox>
            <div>
              <SupportedLanguagesHeaderStyled
                type={TypographyType.Paragraph}
                variant={TypographyVariant.SmallBold}
              >
                Supported Languages
              </SupportedLanguagesHeaderStyled>

              <SupportedLanguagesSubHeaderStyled
                type={TypographyType.Paragraph}
                variant={TypographyVariant.SmallRegular}
              >
                Please be aware that choosing a language will duplicate this lexicon into your shop
                and sever the connection to ongoing updates from Syte&apos;s base lexicon. We kindly
                request that you utilize this option solely if you intend to revise the lexicon and
                tailor it to your business&apos;s specific terminology. It is important to note that
                deleting the language selection will not reestablish the shop&apos;s connection to
                updates.
              </SupportedLanguagesSubHeaderStyled>

              <LocalesContainerStyled>
                {isLoading ? (
                  <Skeleton width={400} height={200} />
                ) : (
                  <>
                    {canAddLanguage && (
                      <LanguageSelectStyled
                        type={SelectType.Primary}
                        onChange={onAddLanguage}
                        placeholder='Add Language'
                      >
                        {uncheckedLocaleItems.map(localeItem => (
                          <MenuItem key={localeItem.locale} value={localeItem.locale}>
                            <LocaleItem
                              locale={localeItem.locale}
                              displayName={localeItem.displayName}
                              iconName={localeItem.iconName}
                            />
                          </MenuItem>
                        ))}
                      </LanguageSelectStyled>
                    )}
                    {selectedLocaleItems.length === 0 && (
                      <Typography type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
                        No locales were selected
                      </Typography>
                    )}
                    {selectedLocaleItems.map(localeItem => (
                      <SelectLocaleItemStyled
                        key={localeItem.locale}
                        locale={localeItem.locale}
                        displayName={localeItem.displayName}
                        iconName={localeItem.iconName}
                        onDeleteCLick={onDeleteClick}
                      />
                    ))}
                  </>
                )}
              </LocalesContainerStyled>
            </div>
          </SectionBox>
        </FormCard.Content>
      </FormCard>
    </ShopLexiconSettingsStyled>
  );
};
