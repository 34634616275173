import React, { useMemo } from 'react';
import {
  Select,
  SelectType,
  SelectOnChangeEvent,
  MenuItem,
  DropDownOption,
  TooltipedFormField,
} from 'src/components-dummy';
import {
  BucketsOrderType,
  BucketsOrderSort,
  BucketsOrder,
} from 'src/services/src/service/types/filters';

type BucketsOrderKey = 'desc' | 'asc' | 'count';
export interface FilterValuesOrderProps {
  isNumericType: boolean;
  bucketsOrder: BucketsOrder | undefined;
  dispatcher: (newValue: BucketsOrder) => void;
  error: string[] | undefined;
}

const mapUISelectToValue: Record<BucketsOrderKey, BucketsOrder> = {
  desc: { sort: BucketsOrderSort.desc, type: BucketsOrderType.name },
  asc: { sort: BucketsOrderSort.asc, type: BucketsOrderType.name },
  count: { sort: BucketsOrderSort.desc, type: BucketsOrderType.count },
};

const getBucketsOrderSelectionValue = (
  bucketsOrderVal: BucketsOrder | undefined
): BucketsOrderKey | undefined => {
  let foundKey;
  if (bucketsOrderVal) {
    Object.entries(mapUISelectToValue).forEach(([key, value]: [string, BucketsOrder]) => {
      if (value.type === bucketsOrderVal.type && value.sort === bucketsOrderVal.sort) {
        foundKey = key;
      }
    });
  }
  return foundKey;
};

const stringTypeBucketsOrderOptions: DropDownOption<BucketsOrderKey>[] = [
  { text: 'By Number of Results', value: 'count' },
  { text: 'A to Z', value: 'asc' },
  { text: 'Z to A', value: 'desc' },
];

export const FilterValuesOrder = ({
  bucketsOrder,
  dispatcher,
  error = [],
}: {
  bucketsOrder: BucketsOrder | undefined;
  dispatcher: (newValue: BucketsOrder) => void;
  error: string | string[] | undefined;
}): JSX.Element => {
  const selection = useMemo(() => getBucketsOrderSelectionValue(bucketsOrder), [bucketsOrder]);

  const convertDispatcherSelection: SelectOnChangeEvent = event =>
    dispatcher(mapUISelectToValue[event.target.value as BucketsOrderKey]);

  return (
    <TooltipedFormField label='Order filter values by' error={error} className='buckets-order'>
      <Select
        isError={Boolean(error.length)}
        value={selection}
        onChange={convertDispatcherSelection}
        type={SelectType.Menu}
        className='syte-drop-down'
      >
        {stringTypeBucketsOrderOptions.map((option: { value: string; text: string }) => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </TooltipedFormField>
  );
};
