import { createAction } from '@reduxjs/toolkit';
import { ShopDataField } from 'src/services';

/**
 * Actions
 */
export const useDataFieldsWSActions = {
  dataFieldsUpdated: createAction<{ dataFields: ShopDataField[] }>(
    'UseDeepTagReportsWS/DataFieldsUpdated'
  ),
  dataFieldsNotification: createAction<{ customMessage: string }>(
    'UseDeepTagReportsWS/DataFieldsNotification'
  ),
};
