import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { FiltersReducerState } from 'src/app-state-types';
import { createFilterPageActions } from 'src/components-bl/Filters/components/CreateFilterPage/Actions';
import { filtersFormActions } from 'src/components-bl/Filters/components/FilterForm/Actions';
import { filtersListPageActions } from 'src/components-bl/Filters/components/FiltersListPage/Actions';
import { editFilterContainerActions } from 'src/containers/Filters';

const initialState: FiltersReducerState = {
  filters: undefined,
  selectedFilter: undefined,
};

export const filtersReducer = createReducer(initialState, builder => {
  builder
    .addCase(filtersListPageActions.updateFilter.fulfilled, (state, action) => {
      const updatedFilters = (state.filters || []).map(filter => {
        if (filter.id === action.payload.id) {
          return action.payload;
        }

        return filter;
      });
      return {
        ...state,
        filters: updatedFilters,
      };
    })
    .addCase(filtersListPageActions.resetFilters, (state, _) => {
      return {
        ...state,
        filters: initialState.filters,
      };
    })
    .addCase(filtersFormActions.createFilter.fulfilled, (state, action) => {
      const filtersCopy = [...(state.filters || []), action.payload];
      return {
        ...state,
        filters: filtersCopy,
      };
    })
    .addCase(editFilterContainerActions.getFilter.fulfilled, (state, action) => {
      return {
        ...state,
        selectedFilter: action.payload,
      };
    })
    .addCase(filtersListPageActions.deleteFilter.fulfilled, (state, action) => {
      return {
        ...state,
        filters: state.filters?.filter(filter => filter.id !== action.payload.deletedFilter.id),
      };
    })
    .addCase(filtersListPageActions.rearrangeFiltersOrder.fulfilled, (state, action) => {
      return {
        ...state,
        filters: action.payload.filterSet.filters,
      };
    })
    .addMatcher(
      isAnyOf(
        editFilterContainerActions.getFilter.rejected,
        filtersFormActions.resetSelectedFilter
      ),
      (state, _) => {
        return {
          ...state,
          selectedFilter: initialState.selectedFilter,
        };
      }
    )
    .addMatcher(
      isAnyOf(
        filtersListPageActions.getFilterSet.fulfilled,
        createFilterPageActions.getFilterSet.fulfilled,
        editFilterContainerActions.getFilterSet.fulfilled
      ),
      (state, action) => {
        return {
          ...state,
          filters: action.payload.filterSet.filters,
        };
      }
    )
    .addMatcher(
      isAnyOf(
        filtersListPageActions.getFilterSet.rejected,
        createFilterPageActions.getFilterSet.rejected
      ),
      (state, _) => {
        return {
          ...state,
          filters: initialState.filters,
        };
      }
    )
    .addMatcher(
      isAnyOf(
        filtersListPageActions.getFilterSet.pending,
        createFilterPageActions.getFilterSet.pending
      ),
      (state, _) => {
        return {
          ...state,
          filters: initialState.filters,
        };
      }
    );
});
