import { FeedsMetaDataTypes } from '../types';
import { ApiServiceBase } from '../api-service-base';
import { mapPartialToDataFieldsStructure } from './mappers';

export class FeedsMetadata extends ApiServiceBase {
  constructor() {
    super('feeds-metadata');
  }

  async deeptags(shopId: number): Promise<FeedsMetaDataTypes.FeedMetadataPartial> {
    const url = `${this.serviceBaseUri}/${FeedsMetaDataTypes.Route.DeepTags}?shopId=${shopId}`;
    const response = await this.httpService.get({ url });
    return mapPartialToDataFieldsStructure(response);
  }
}
