import { createReducer } from '@reduxjs/toolkit';
import { rootContainerActions } from '../root-container/Actions';
import { MetaReducerState } from '../app-state-types';

const initialState: MetaReducerState = {
  languages: undefined,
  syteProductTypes: undefined,
  public: undefined,
};

export const metaReducer = createReducer(initialState, builder => {
  builder.addCase(rootContainerActions.initApp.fulfilled, (state, action) => {
    return { ...state, languages: action.payload.languagesWithIcons };
  });
  builder.addCase(rootContainerActions.getPublicMetadata.fulfilled, (state, action) => {
    return { ...state, public: action.payload };
  });
});
