import React, { useEffect, useMemo, useState } from 'react';
import { isEqual, startCase } from 'lodash';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import {
  TextBox,
  Badge,
  Button,
  MenuItem,
  Select,
  SelectType,
  SelectOnChangeEvent,
} from 'src/components-dummy';
import { ExperimentMetric, ExperimentStatus } from 'src/services';
import { useValidateSchema } from 'src/hooks';
import { VariantsTable } from './VariantsTable';
import './ExperimentForm.scss';
import { getExperimentStatusClass } from '../experiment-helpers';
import { experimentFormActionMethods } from './Actions';
import { experimentValidationSchema, metricsDropDownOptions } from './constants';
import { ExperimentFormProps, EditExperimentForm } from './types';

export const ExperimentForm = ({
  experiment,
  dispatch,
  shopId,
  applyButtonElement,
  selectedVariantId,
}: ExperimentFormProps): JSX.Element => {
  const [draft, setDraft] = useState(experiment);

  const isExperimentEditable = experiment?.status === ExperimentStatus.Created;
  const isExperimentEnded = experiment?.status === ExperimentStatus.Ended;

  // validate & dirty
  const { errors, validate, isValid } = useValidateSchema<EditExperimentForm>({
    schema: experimentValidationSchema,
  });

  const isDirty = useMemo(() => {
    return !isEqual(draft, experiment);
  }, [draft, experiment]);

  useEffect(() => {
    if (!selectedVariantId) {
      dispatch(experimentFormActionMethods.notifyIsDirty({ isDirty }));
    }
  }, [isDirty]);

  const onSubmit = () => {
    if (!draft || !shopId) {
      return;
    }

    if (isValid) {
      dispatch(experimentFormActionMethods.updateExperiment({ shopId, experiment: draft }));
    }
  };

  // buttons portal for outer page
  const applyButton = (
    <Button
      onClick={onSubmit}
      variant='primary'
      disabled={!(isValid && isDirty)}
      className='syte-experiment-form-apply-button'
    >
      Save
    </Button>
  );

  const buttonsSection = applyButtonElement
    ? ReactDOM.createPortal(applyButton, applyButtonElement)
    : applyButtonElement;

  // reset & rest on variant change
  const reset = () => {
    setDraft(experiment);
  };

  useEffect(() => {
    reset();
  }, [experiment, selectedVariantId]);

  // draft updates
  const updateDraftPartial = (partialData: Partial<EditExperimentForm>) => {
    if (!draft) return;

    const updatedState = { ...draft, ...partialData };
    setDraft(updatedState);
    validate({ dataToValidate: updatedState });
  };

  const handleMetricChange: SelectOnChangeEvent = event => {
    updateDraftPartial({ metric: event.target.value as ExperimentMetric });
  };

  // return only buttons if not ready yet
  if (!shopId || !experiment || !draft) {
    return <div className='syte-experiment-form'>{buttonsSection}</div>;
  }

  return (
    <div className='syte-experiment-form'>
      <div className='syte-experiment-form-header'>
        <TextBox
          value={draft.name}
          error={!!errors.name}
          label='Experiment name*'
          placeholder='Experiment name'
          disabled={!isExperimentEditable}
          className='syte-experiment-form-header-name'
          onChange={name => updateDraftPartial({ name })}
        />
        <div className='syte-experiment-form-header-metric-wrapper'>
          <Select
            type={SelectType.Primary}
            value={draft.metric}
            isError={!!errors.metric}
            label='Metric*'
            placeholder='Experiment metric'
            disabled={!isExperimentEditable}
            className='syte-experiment-form-header-metric-wrapper-dropdown'
            onChange={handleMetricChange}
          >
            {metricsDropDownOptions.map((option: { value?: string; text: string }) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
          <span className='syte-experiment-form-header-metric-wrapper-sub-title'>
            Set the main objective for the test
          </span>
        </div>
        <div className='syte-experiment-form-header-status'>
          <span className='placeholder'>Status:</span>
          <Badge
            text={startCase(experiment.status)}
            className={classNames(
              'experiment-status-badge',
              getExperimentStatusClass(experiment.status)
            )}
          />
        </div>
      </div>

      <VariantsTable
        experiment={experiment}
        variantsDraft={draft.variants || []}
        dispatch={dispatch}
        shopId={shopId}
        errors={errors}
        updateVariantsState={({ variants }) => updateDraftPartial({ variants })}
        isExperimentEditable={isExperimentEditable}
        isExperimentEnded={isExperimentEnded}
        selectedVariantId={selectedVariantId}
      />

      {buttonsSection}
    </div>
  );
};
