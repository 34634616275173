import styled from '@emotion/styled';
import { PopUpMenuItem, Tooltip, Typography } from 'src/components-dummy';
import { UserProfileThumbnail } from '../UserManagement/components/UserProfileThumbnail';

export const UserAvatarListStyled = styled.div<{ width: number }>`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 24px;
  width: ${({ width }) => width}px;
  transition: width 0.3s;
`;

export const UserDisplayNameTypography = styled(Typography)`
  color: ${props => props.theme.palette.custom['gray-20']};
  whitespace: nowrap;
  margin-right: 10px;
`;

export const PopUpContentStyled = styled.div`
  background: ${props => props.theme.palette.common.white};
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.palette.custom['gray-100']};
`;

export const UsersListStyled = styled.div`
  max-height: 200px;
  min-width: 320px;
  overflow-y: auto;
`;

export const UserRowStyled = styled.div`
  display: flex;
  padding-left: 4px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;

  .user-profile-thumb {
    margin-right: 10px;
  }
`;

export const UserProfileThumbnailStyled = styled(UserProfileThumbnail)`
  position: relative;

  .syte-initial-letter {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.palette.custom.white};
    font-weight: initial;
    font-size: 14px;
  }
`;

export const UserThumbnailElementWrapperStyled = styled.div<{
  show: boolean;
  positionOffset: number;
}>`
  display: flex;
  position: relative;
  z-index: 0;

  right: ${({ positionOffset }) => positionOffset}px;
  bottom: 0;
  width: ${({ show }) => (show ? '100%' : '0%')};
  transition:
    right 0.1s,
    width 0.3s,
    bottom 0.1s;

  animation: ${({ show }) => (show ? 'onMount' : 'onUnMount')} 0.4s;

  &:hover {
    bottom: 4px;
    z-index: 1;
  }

  @keyframes onMount {
    from {
      opacity: 0;
      scale: 0;
    }
    to {
      opacity: 1;
      scale: 1;
    }
  }

  @keyframes onUnMount {
    from {
      opacity: 1;
      scale: 1;
    }
    to {
      opacity: 0;
      scale: 0;
    }
  }
`;

export const ProfileNameTooltipStyled = styled(Tooltip)`
  .syte-popup-content {
    height: 38px;
    align-items: center;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0;
    }
  }
`;

export const SytePopUpMenuContentStyled = styled.ul`
  background-color: ${({ theme }) => theme.palette.custom.white};
  border-radius: 4px;
  padding: 8px;
`;

export const PopUpMenuItemStyled = styled(PopUpMenuItem)`
  color: initial;
  padding: 12px;
  border-top: initial !important;
`;
