import styled from '@emotion/styled';

export const LabelWrapperStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const LabelTextStyled = styled.div`
  min-width: 80px;
  width: auto;
  max-width: 300px;
`;
