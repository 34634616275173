import styled from '@emotion/styled';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';

export const ConfirmationDialogHeaderStyled = styled(ConfirmationDialog.Header)`
  padding: 24px 40px 16px 40px;
`;

export const ConfirmationDialogTitleTextStyled = styled.span`
  white-space: break-spaces;
`;
