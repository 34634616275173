import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import { BadgeStyled } from './Badge.styles';
import { BadgeProps } from './Badge.types';

export const Badge = ({
  text,
  background = 'primary',
  icon,
  className,
}: BadgeProps): JSX.Element => {
  return (
    <BadgeStyled
      className={classNames(`syte-badge syte-badge-${background}`, className)}
      background={background}
    >
      {icon && <FontAwesomeIcon className='syte-badge-icon' icon={icon} />}
      <div className='syte-badge-content'>
        <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
          {text}
        </Typography>
      </div>
    </BadgeStyled>
  );
};
