import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { store } from './store';
import { history } from './history';
import { RootContainer } from './root-container/RootContainer';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <RootContainer history={history} />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
