import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { generatePath, useParams } from 'react-router';
import { useAppSelector } from 'src/hooks';
import { RoutedComponentProps } from 'src/app-routes';
import { DeepTagsProductFormModal } from 'src/components-bl/DeepTagReports/components/DeepTagsProductForm/DeepTagsProductFormModal';
import {
  IDeepTagReportProduct,
  IShopGeneratedDataSettings,
  mapLexiconCategoriesMap,
} from 'src/services';
import { OnGeneratedText } from 'src/components-bl/DeepTagReports/components/DeepTagsProductForm/components/DeepTagGenerativeAIForm/DeepTagsGenerativeAIForm';
import { UserRoles } from 'src/services/src/service/types/users';
import {
  OnChangeProductStatus,
  OnSaveProductChanges,
} from 'src/components-bl/DeepTagReports/components/DeepTagsProductForm/DeepTagsProductForm.types';
import { deepTagsProductContainerActions } from './Actions';
import { getCleanedFiltersWithPagination } from '../../DeepTagReports.helpers';

type DeepTagReportProductsContainerProps = RoutedComponentProps;

export const DeepTagsProductContainer = ({
  permittedRouteMap,
}: DeepTagReportProductsContainerProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const { productId, reportId } = useParams<{
    reportId: string;
    productId: string;
  }>();

  const {
    productPagination,
    paginationFilters,
    filters,
    isDirty,
    shopId,
    lexiconItems,
    selectedReport,
    lexiconsMetadataList,
    lexiconLocale,
    generatedDataSettings,
    generalConfigurationLabels,
    userRole,
    deepTagContentGenerationFeature,
    currentUser,
  } =
    useAppSelector(
      state => ({
        productPagination: state.deepTagReports.productPagination,
        selectedReport: state.deepTagReports.selectedReport,
        isDirty: state.global.isDirty,
        paginationFilters: state.deepTagReports.paginationFilters,
        shopId: state.shop.current?.shopId,
        lexiconItems: state.lexicon.items,
        lexiconLocale: state.lexicon.locale,
        filters: state.deepTagReports.filters,
        lexiconsMetadataList: state.lexicon.lexiconsMetadataList,
        generatedDataSettings: state.deepTagReports.generatedDataSettings,
        generalConfigurationLabels: state.deepTagReports.generalConfigurationLabels,
        userRole: state.global.loggedInUser?.role,
        currentUser: state.global.loggedInUser,
        deepTagContentGenerationFeature:
          state.shop?.featureToggles?.deepTagReportsContentGeneration,
      }),
      shallowEqual
    ) || {};

  const isContentGenerationPermitted = !!(
    userRole &&
    deepTagContentGenerationFeature?.enabled &&
    deepTagContentGenerationFeature?.permittedRoles?.includes(userRole)
  );

  // Temporary
  const shouldHideEditIndication = userRole === UserRoles.ClientAdmin;

  const [isLexiconEnabled, setIsLexiconEnabled] = useState<boolean | undefined>(undefined);
  const [isLoadingLexicon, setIsLoadingLexicon] = useState(false);

  const currentProductIndex = useMemo(() => {
    return (productPagination?.data?.products || []).findIndex(
      productItem => productItem.id === productId
    );
  }, [productId, productPagination?.data?.products]);

  const product = productPagination?.data?.products?.[currentProductIndex];

  const getShopLexiconByLocale = useCallback(async () => {
    try {
      if (shopId && selectedReport?.locale && lexiconsMetadataList) {
        if (selectedReport?.locale !== lexiconLocale) {
          setIsLoadingLexicon(true);
        }

        await dispatch(
          deepTagsProductContainerActions.getShopLexiconByLocale({
            shopId,
            locale: selectedReport.locale,
          })
        );
      }
    } finally {
      setIsLoadingLexicon(false);
    }
  }, [shopId, selectedReport?.locale, lexiconsMetadataList, lexiconLocale, dispatch]);

  useEffect(() => {
    if (shopId && selectedReport?.locale && lexiconsMetadataList) {
      const isLocaleInMetadataList = lexiconsMetadataList?.find(
        metadata => metadata.locale === selectedReport?.locale
      );

      if (isLocaleInMetadataList) {
        setIsLexiconEnabled(true);
        if (!lexiconItems) {
          getShopLexiconByLocale();
        }
      } else {
        setIsLexiconEnabled(false);
      }
    }
  }, [shopId, selectedReport?.locale, getShopLexiconByLocale, lexiconsMetadataList, lexiconItems]);

  useEffect(() => {
    dispatch(deepTagsProductContainerActions.getLabelsGeneralConfiguration());
  }, [dispatch]);

  /**
   * Get - generated data settings (toneOfVoice / textLength)
   */
  useEffect(() => {
    if (shopId) {
      dispatch(
        deepTagsProductContainerActions.getGeneratedDataSettings({
          shopId,
        })
      );
    }
  }, [shopId, dispatch]);

  const goToProductsListRoute = useCallback(() => {
    if (permittedRouteMap.deepTagReportProducts && shopId) {
      const url = generatePath(permittedRouteMap.deepTagReportProducts.path, {
        reportId,
        shopId,
      });

      dispatch(
        deepTagsProductContainerActions.navigateTo({
          navigateTo: url,
        })
      );
    }
  }, [dispatch, permittedRouteMap.deepTagReportProducts, reportId, shopId]);

  const onCloseModal = useCallback(() => {
    goToProductsListRoute();
  }, [goToProductsListRoute]);

  /**
   * Fetch reports with filters & pagination (refresh results due to changes in product tags - filters needs )
   */
  const getPaginatedReportsWithFilters = useCallback(async () => {
    const enabledFiltersWithValue = getCleanedFiltersWithPagination({
      filters,
      paginationFilters,
    });

    if (shopId && reportId && selectedReport?.locale) {
      await dispatch(
        deepTagsProductContainerActions.getReportProducts({
          shopId: shopId as number,
          reportId,
          locale: selectedReport.locale,
          ...enabledFiltersWithValue,
        })
      );
    }
  }, [filters, paginationFilters, shopId, reportId, selectedReport?.locale, dispatch]);

  const onSaveGeneratedDataSettings = useCallback(
    async ({
      shopGeneratedDataSettings,
    }: {
      shopGeneratedDataSettings: IShopGeneratedDataSettings;
    }) => {
      const result = (await dispatch(
        deepTagsProductContainerActions.updateGeneratedDataSettings({
          shopId: Number(shopId),
          shopGeneratedDataSettings,
        })
      )) as unknown;

      return result;
    },
    [dispatch, shopId]
  );

  const onGeneratedText: OnGeneratedText = useCallback(
    async ({ subProducts, fieldType, toneOfVoice, textLength }) => {
      const result = (await dispatch(
        deepTagsProductContainerActions.generateText({
          shopId: Number(shopId),
          locale: selectedReport?.locale || 'en_US',
          subProducts,
          fieldType,
          toneOfVoice,
          textLength,
          reportId,
          productId,
        })
      )) as any;

      if (!result.error) {
        return result.payload.generatedText;
      }
      return result;
    },
    [dispatch, shopId, selectedReport?.locale, reportId, productId]
  );

  const onResetChanges = useCallback(
    async (deepTagProductId: IDeepTagReportProduct['id'], locale: string) => {
      const result = (await dispatch(
        deepTagsProductContainerActions.resetProductChanges({
          shopId: Number(shopId),
          reportId,
          productId: deepTagProductId,
          locale,
        })
      )) as any;

      if (!result?.error) {
        await getPaginatedReportsWithFilters();
      }

      return result;
    },
    [dispatch, getPaginatedReportsWithFilters, reportId, shopId]
  );

  const gotToOtherProduct = useCallback(
    ({ newProductId, newReportId }: { newProductId: string; newReportId?: string }) => {
      if (!permittedRouteMap?.deepTagReportProduct || !shopId) return;

      const url = generatePath(permittedRouteMap.deepTagReportProduct.path, {
        reportId: newReportId || reportId,
        productId: newProductId,
        shopId,
      });

      dispatch(
        deepTagsProductContainerActions.navigateTo({
          navigateTo: url,
        })
      );
    },
    [dispatch, permittedRouteMap.deepTagReportProduct, reportId, shopId]
  );

  const onPaginationButtonClick = useCallback(
    ({ isLeft }: { isLeft: boolean }) =>
      () => {
        const newProductIndex = isLeft ? currentProductIndex - 1 : currentProductIndex + 1;

        const newProduct: IDeepTagReportProduct | undefined =
          productPagination?.data?.products[newProductIndex];

        if (newProduct) {
          gotToOtherProduct({ newProductId: newProduct.id });
        }
      },
    [productPagination?.data?.products, gotToOtherProduct, currentProductIndex]
  );

  const hasPreviousProduct = !!productPagination?.data?.products[currentProductIndex - 1];
  const hasNextProduct = !!productPagination?.data?.products[currentProductIndex + 1];

  const onSaveChanges: OnSaveProductChanges = useCallback(
    async ({ partialProduct: productToUpdate, shouldGoToNextItem = false }) => {
      if (!selectedReport?.locale) {
        return null;
      }

      const result = (await dispatch(
        deepTagsProductContainerActions.updateProduct({
          shopId: Number(shopId),
          reportId,
          productId,
          locale: selectedReport?.locale,
          partialProduct: productToUpdate,
        })
      )) as any;

      if (!result?.error) {
        dispatch(deepTagsProductContainerActions.setIsDirty({ isDirty: false }));
        await getPaginatedReportsWithFilters();
        if (shouldGoToNextItem && hasNextProduct) {
          onPaginationButtonClick({ isLeft: false })();
        }
      }

      return result;
    },
    [
      selectedReport?.locale,
      dispatch,
      shopId,
      reportId,
      productId,
      getPaginatedReportsWithFilters,
      hasNextProduct,
      onPaginationButtonClick,
    ]
  );

  const onChangeStatus: OnChangeProductStatus = useCallback(
    async ({ status, locale }) => {
      const result = (await dispatch(
        deepTagsProductContainerActions.updateProduct({
          shopId: Number(shopId),
          reportId,
          productId,
          partialProduct: { status },
          locale,
        })
      )) as any;

      if (!result?.error) {
        await getPaginatedReportsWithFilters();
      }

      return result;
    },
    [dispatch, shopId, reportId, productId, getPaginatedReportsWithFilters]
  );

  const onChangeTitle: ({ title, locale }: { title: string; locale: string }) => void = useCallback(
    async ({ title, locale }) => {
      const result = (await dispatch(
        deepTagsProductContainerActions.updateProduct({
          shopId: Number(shopId),
          reportId,
          productId,
          partialProduct: { title },
          locale,
        })
      )) as any;

      if (!result?.error) {
        await getPaginatedReportsWithFilters();
      }

      return result;
    },
    [dispatch, shopId, reportId, productId, getPaginatedReportsWithFilters]
  );

  const lexiconCategories = useMemo(() => {
    return lexiconItems ? mapLexiconCategoriesMap(lexiconItems) : undefined;
  }, [lexiconItems]);

  // Incase product id - not exists
  if (productPagination?.data?.products && !product) {
    goToProductsListRoute();
    return null;
  }

  return (
    <DeepTagsProductFormModal
      deepTagProduct={product}
      isDirty={isDirty}
      currentUser={currentUser}
      isLoading={!product && !lexiconItems}
      reportId={reportId}
      shopId={Number(shopId)}
      generalConfigurationLabels={generalConfigurationLabels}
      onPreviousProductClick={
        hasPreviousProduct ? onPaginationButtonClick({ isLeft: true }) : undefined
      }
      onNextProductClick={hasNextProduct ? onPaginationButtonClick({ isLeft: false }) : undefined}
      onCancel={onCloseModal}
      onSaveChanges={onSaveChanges}
      onSaveGeneratedDataSettings={onSaveGeneratedDataSettings}
      onChangeStatus={onChangeStatus}
      onChangeTitle={onChangeTitle}
      onResetChanges={onResetChanges}
      onGeneratedText={onGeneratedText}
      getPaginatedReportsWithFilters={getPaginatedReportsWithFilters}
      lexiconCategories={lexiconCategories}
      reportLocale={selectedReport?.locale as string}
      dispatch={dispatch}
      isLexiconEnabled={isLexiconEnabled}
      isLoadingLexicon={isLoadingLexicon}
      generatedDataSettings={generatedDataSettings}
      shouldHideEditIndication={shouldHideEditIndication}
      isContentGenerationPermitted={isContentGenerationPermitted}
    />
  );
};
