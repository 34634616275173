import styled from '@emotion/styled';

interface Props {
  nested?: boolean;
  paddingY?: number;
}

export const StyledContainer = styled.div<Props>`
  ${props => props.nested && `padding-left: 15px;`}
  ${props =>
    props.paddingY &&
    `padding-top: ${props.paddingY}px;
  padding-bottom: ${props.paddingY}px;`}
`;
