import { ApiServiceBase } from '../api-service-base';
import { ShopSFTPSettingsMapper } from './sftp-settings.mapper';
import { IShopSftpSettings } from '../types';

export class ShopSFTPSettingsService extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl(shopId: number): string {
    return `${this.serviceBaseUri}/${shopId}/sftp-settings`;
  }

  async getSettings(shopId: number): Promise<IShopSftpSettings> {
    const url = this.getUrl(shopId);
    const response = await this.httpService.get({ url });
    return ShopSFTPSettingsMapper.mapSFTPSettings(response);
  }
}
