import { Dispatch } from 'src/components-dummy/types';
import { Accounts } from 'src/services';
import {
  CreateAccountFormActionTypes,
  CreateAccountFormCreateAccountRequestActionPayload,
  CreateAccountFormCreateAccountRequestAction,
  CreateAccountFormCreateAccountSuccessActionPayload,
  CreateAccountFormCreateAccountSuccessAction,
  CreateAccountFormCreateAccountErrorActionPayload,
  CreateAccountFormCreateAccountErrorAction,
} from './types';

const accountService = new Accounts();

const createAccountFormActions = {
  createAccountRequest(
    payload: CreateAccountFormCreateAccountRequestActionPayload
  ): CreateAccountFormCreateAccountRequestAction {
    return {
      type: CreateAccountFormActionTypes.CreateAccountRequest,
      payload,
    };
  },

  createAccountSuccess(
    payload: CreateAccountFormCreateAccountSuccessActionPayload
  ): CreateAccountFormCreateAccountSuccessAction {
    return {
      type: CreateAccountFormActionTypes.CreateAccountSuccess,
      payload,
    };
  },

  createAccountError(
    payload: CreateAccountFormCreateAccountErrorActionPayload
  ): CreateAccountFormCreateAccountErrorAction {
    return {
      type: CreateAccountFormActionTypes.CreateAccountError,
      payload,
    };
  },
};

export const createAccountFormActionMethods = {
  createAccount({ name }: CreateAccountFormCreateAccountRequestActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(createAccountFormActions.createAccountRequest({ name }));
        const account = await accountService.createAccount(name);
        dispatch(createAccountFormActions.createAccountSuccess({ account }));
      } catch (error) {
        dispatch(createAccountFormActions.createAccountError({ error }));
      }
    };
  },
};
