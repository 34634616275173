import styled from '@emotion/styled';

export const MenuActionsStyled = styled.div`
  display: flex;
  justify-content: end;

  .MuiButtonBase-root {
    height: 32px;
    align-items: end !important;
  }
`;
