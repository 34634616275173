import { convertArrayToObject } from './convert-array-to-object';
import { renderSpecialDataFields } from './render-special-data-fields';
import { DataFieldToDisplay } from '../../../../types/data-field-to-display';
import { TableHeadRow } from '../types/table-head-row';
import { SpecialDataFieldToDisplay } from '../../../../types/special-data-field-to-display';

export const getTableColumns = (
  dataFieldsToDisplay: DataFieldToDisplay[],
  specialDataFieldsToDisplay: SpecialDataFieldToDisplay[]
): Record<string, TableHeadRow> => {
  const fields = dataFieldsToDisplay.map(field => {
    return {
      [field.text]: {
        header: field.text,
        accessor: field.value,
        width: '165px',
        minWidth: 165,
      },
    };
  });
  const convertedFields = convertArrayToObject(fields);

  const specialFields = renderSpecialDataFields(specialDataFieldsToDisplay);
  const convertedSpecialFields = convertArrayToObject(specialFields);

  return {
    index: {
      header: '',
      accessor: 'index',
      width: '70px',
      minWidth: 70,
    },
    imageUrl: {
      header: '',
      accessor: 'imageUrl',
      width: '70px',
      minWidth: 70,
    },
    ...convertedSpecialFields,
    ...convertedFields,
  };
};
