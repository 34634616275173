import { ILexiconRuleAPI, ILexiconConditions, ILexiconRule } from 'src/services';
import { Dispatch } from '../../../../types';

export interface ILexiconRuleConditionsDraft extends ILexiconConditions {
  id: string;
}

export interface ILexiconRuleConditionsGroupDraft {
  id: string;
  conditions: ILexiconRuleConditionsDraft[];
}

export type EmptyTagPart = {
  field?: TagFields;
  id: number;
};

export type CategoryTagPart = ILexiconRuleAPI['category'] & {
  field: typeof TagFields.Category;
  id: number;
};

export type AttributeTagPart = ILexiconRuleAPI['attribute'] & {
  field: typeof TagFields.Attribute;
  id: number;
};

export type ValueTagPart = ILexiconRuleAPI['value'] & {
  field: typeof TagFields.Value;
  id: number;
};

export type TagPart = EmptyTagPart | CategoryTagPart | AttributeTagPart | ValueTagPart;

export interface ILexiconRuleDraft {
  conditionsGroups: ILexiconRuleConditionsGroupDraft[];
  tagParts: TagPart[];
}

export interface LexiconRuleFormProps {
  shopId: number;
  locale: string;
  mode: 'create' | 'edit';
  onClose: () => void;
  onSuccess: () => void;
  dispatch: Dispatch;
  lexiconRule: ILexiconRule | undefined;
}

export type AvailableAttributeKeysByCategoryMap = Record<string, string[]>;

export enum TagFields {
  Category = 'Category',
  Attribute = 'Attribute',
  Value = 'Value',
}
