import { TimezoneName } from 'countries-and-timezones';
import { FieldType } from '../data-fields';
import { SyteProductType } from '../syte-products';

export type SubRuleOptionalValues = string[] | [number | null, number | null] | boolean[];

export enum MerchandisingRuleKPIEnum {
  AOV = 'AOV',
  CVR = 'CVR',
  ARPU = 'ARPU',
}

export type MerchandisingRuleProduct = Exclude<SyteProductType, SyteProductType.UiTemplates>;

export interface IMerchandisingRuleRedirectRule {
  targetUrl: string;
  displayName: string;
}

export interface IPinToPositionRule {
  id: string;
  sku: string;
  position?: number;
}

export interface MerchandiseRule {
  id: string;
  active: boolean;
  kpi?: MerchandisingRuleKPIEnum | string;
  appliedDateRange: DateRange | null;
  name: string;
  weight: number;
  action: RuleAction;
  product: MerchandisingRuleProduct;
  searchCondition: SearchCondition;
  sourceCondition: Array<RootCondition>;
  subRules: Array<SubRule>;
  regions: string[];
  filterByCondition?: Array<FilterByCondition>;
  redirectRule?: IMerchandisingRuleRedirectRule | null;
  entityId?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum MerchandisingRulesSource {
  Version = 'version',
  Experiment = 'experiment',
}

export interface MerchandiseRuleAPIResponse
  extends Omit<MerchandiseRule, 'createdAt' | 'updatedAt'> {
  createdAt?: string;
  updatedAt?: string;
}

export interface DateRange {
  startDate: Date;
  endDate: Date;
  timeZone: TimezoneName;
}

export interface SubRule {
  tempId?: number;
  enabled: boolean;
  field: string;
  fieldType: FieldType;
  subType: MerchandisingRuleSubType;
  values: SubRuleOptionalValues;
  position?: number;
}

export interface SearchCondition {
  enabled: boolean;
  terms: Array<string>;
}

export interface RootCondition {
  enabled: boolean;
  field?: string;
  fieldType: FieldType;
  subType?: MerchandisingRuleSubType;
  values: SubRuleOptionalValues;
  position?: number;
}

export interface FilterByCondition {
  values: SubRuleOptionalValues;
  field?: string;
  fieldType?: FieldType;
  subType?: MerchandisingRuleSubType;
}

export interface EntityMetadata {
  lastUpdatedOn: Date;
  lastUpdatedBy: number;
}

export enum MerchandisingRuleSubType {
  HasValue = 'HAS_VALUE',
  DoesNotHaveValue = 'DOES_NOT_HAVE_VALUE',
  Contains = 'CONTAINS',
  DoesNotContain = 'DOES_NOT_CONTAIN',
  Equals = 'EQUALS',
  EqualsSource = 'EQUALS_SOURCE',
  HigherThan = 'HIGHER_THAN',
  LowerThan = 'LOWER_THAN',
  IsBetween = 'IS_BETWEEN',
  RelativeRange = 'RELATIVE_RANGE',
}

export enum RuleAction {
  Demote = 'DEMOTE',
  Promote = 'PROMOTE',
  Include = 'INCLUDE',
  Exclude = 'EXCLUDE',
  Redirect = 'REDIRECT',
  PinToPosition = 'PIN_TO_POSITION',
}

export enum MerchandisingRuleStatus {
  Active = 'active',
  Inactive = 'inactive',
  IsInDraft = 'is-in-draft',
  InValid = 'invalid',
}

export interface Overview {
  kpis: string[];
  products: SyteProductType[];
  actions: RuleAction[];
}

export interface ExtraIndications {
  isUsingInvalidDataField: boolean;
  hasOnlyValidRegions: boolean;
  isInvalidEmptyRegions: boolean;
  isPublished: boolean;
}
