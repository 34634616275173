import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PermittedRouteMap } from 'src/app-routes';
import { RelevancyTuningContainer } from 'src/containers/AugmentedSearch/components';
import { useAugmentedSearchForm } from 'src/containers/AugmentedSearch/hooks';
import { ShopFeatureToggles } from 'src/services/src/service/types/shops';
import { CollapseDrawer } from '../CollapseDrawer/CollapseDrawer';
import { useNavigateToVisualEditorRoute } from '../../hooks/useNavigateToVisualEditorRoute';
import {
  ButtonStyled,
  EditRelevancyTuningWrapperStyled,
  FooterStyled,
} from './EditRelevancyTuning.styles';

interface EditRelevancyTuningProps {
  className: string;
  featureToggles: ShopFeatureToggles;
  permittedRouteMap: PermittedRouteMap;
  shopId: number;
}

export function EditRelevancyTuning({
  className,
  featureToggles,
  permittedRouteMap,
  shopId,
}: EditRelevancyTuningProps): JSX.Element {
  const dispatch = useDispatch();

  const { navigateToVisualEditorRoute } = useNavigateToVisualEditorRoute({
    route: permittedRouteMap?.visualEditor,
  });

  const onCloseRelevancyTuningDrawer = useCallback(
    () => navigateToVisualEditorRoute({ shopId }),
    [shopId, navigateToVisualEditorRoute]
  );

  useEffect(() => {
    if (!featureToggles.augmentedSearch.enabled) {
      onCloseRelevancyTuningDrawer();
    }
  }, []);

  const { onSubmit, formApiRef, canSubmit, setCanSubmit } = useAugmentedSearchForm();

  return (
    <CollapseDrawer open className={className} onClose={onCloseRelevancyTuningDrawer}>
      <EditRelevancyTuningWrapperStyled>
        <RelevancyTuningContainer
          dispatch={dispatch}
          onSubmitStatusChange={setCanSubmit}
          permittedRouteMap={permittedRouteMap}
          ref={formApiRef}
          shopId={shopId}
          showPreviewButton={false}
        />
        <FooterStyled>
          <ButtonStyled variant='tertiary' onClick={onCloseRelevancyTuningDrawer}>
            Cancel
          </ButtonStyled>
          <ButtonStyled variant='primary' disabled={!canSubmit} onClick={onSubmit}>
            Save
          </ButtonStyled>
        </FooterStyled>
      </EditRelevancyTuningWrapperStyled>
    </CollapseDrawer>
  );
}
