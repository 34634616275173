import React from 'react';
import {
  PopupMenu,
  PopUpMenuItem,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { QuickActionsMenuProps } from '../types';

const defaultProps = {
  closeOnContentClick: true,
  position: 'left center',
};

export const QuickActionsMenu = ({
  actions,
  closeOnContentClick = true,
  position = 'left center',
}: QuickActionsMenuProps): JSX.Element => (
  <PopupMenu
    position={position}
    closeOnContentClick={closeOnContentClick}
    className='syte-entity-card-cell__action-menu-trigger'
  >
    {actions.map(action => (
      <PopUpMenuItem onClick={action.callback} key={action.label.replace(' ', '-')}>
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
          {action.label}
        </Typography>
      </PopUpMenuItem>
    ))}
  </PopupMenu>
);

QuickActionsMenu.defaultProps = defaultProps;
