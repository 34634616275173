import { AxiosResponse } from 'axios';
import { ApiServiceBase } from '../api-service-base';
import { FiltersMapper } from './filters.mapper';
import { SyteLayout, FilterSet, FilterSetAPI, Filter, FilterAPI } from '../types';

export class FiltersService extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl({ shopId, syteLayout }: { shopId: number; syteLayout: SyteLayout }): string {
    return `${this.serviceBaseUri}/${shopId}/layouts/${syteLayout}/filter-sets`;
  }

  async getFilterSet({
    shopId,
    syteLayout,
  }: {
    shopId: number;
    syteLayout: SyteLayout;
  }): Promise<FilterSet> {
    const url = this.getUrl({ shopId, syteLayout });
    const response: AxiosResponse<FilterSetAPI> = await this.httpService.get({
      url,
    });
    return FiltersMapper.mapFilterSet(response);
  }

  async updateFilterSet({
    shopId,
    syteLayout,
    filterIds,
  }: {
    shopId: number;
    syteLayout: SyteLayout;
    filterIds: string[];
  }): Promise<FilterSet> {
    const url = this.getUrl({ shopId, syteLayout });
    const response: AxiosResponse<FilterSetAPI> = await this.httpService.patch({
      url,
      data: { filterIds },
    });
    return FiltersMapper.mapFilterSet(response);
  }

  async getFilter({
    shopId,
    syteLayout,
    filterId,
  }: {
    shopId: number;
    syteLayout: SyteLayout;
    filterId: string;
  }): Promise<Filter> {
    const url = `${this.getUrl({ shopId, syteLayout })}/filters/${filterId}`;
    const response: AxiosResponse<FilterAPI> = await this.httpService.get({
      url,
    });
    return FiltersMapper.mapFilter(response.data);
  }

  async createFilter({
    shopId,
    syteLayout,
    filterPayload,
  }: {
    shopId: number;
    syteLayout: SyteLayout;
    filterPayload: Pick<Filter, 'enabled' | 'dataSource' | 'sourceField' | 'valuesSorting'>;
  }): Promise<Filter> {
    const url = `${this.getUrl({ shopId, syteLayout })}/filters`;

    const response: AxiosResponse<FilterAPI> = await this.httpService.post({
      url,
      data: filterPayload,
    });
    return FiltersMapper.mapFilter(response.data);
  }

  async updateFilter({
    shopId,
    syteLayout,
    filterPayload,
    filterId,
  }: {
    shopId: number;
    syteLayout: SyteLayout;
    filterId: string;
    filterPayload: Partial<
      Pick<Filter, 'enabled' | 'dataSource' | 'sourceField' | 'valuesSorting'>
    >;
  }): Promise<Filter> {
    const url = `${this.getUrl({ shopId, syteLayout })}/filters/${filterId}`;

    const response: AxiosResponse<FilterAPI> = await this.httpService.patch({
      url,
      data: filterPayload,
    });
    return FiltersMapper.mapFilter(response.data);
  }

  async deleteFilter({
    shopId,
    syteLayout,
    filterId,
  }: {
    shopId: number;
    syteLayout: SyteLayout;
    filterId: string;
  }): Promise<Filter> {
    const url = `${this.getUrl({ shopId, syteLayout })}/filters/${filterId}`;
    const response: AxiosResponse<FilterAPI> = await this.httpService.delete({
      url,
    });
    return FiltersMapper.mapFilter(response.data);
  }
}

export const filtersService = new FiltersService();
