export enum MenuPosition {
  Top = 'top',
  Bottom = 'Bottom',
}

export enum SelectType {
  Primary = 'primary',
  Menu = 'menu',
}

export const OPTION_HEIGHT = 39.5;
export const MENU_TOP_BOTTOM_PADDINGS = 16;
export const MENU_MARGIN = 5;
export const MAX_OPTIONS_TO_SHOW = 7;
export const MENU_MAX_HEIGHT = OPTION_HEIGHT * MAX_OPTIONS_TO_SHOW + MENU_TOP_BOTTOM_PADDINGS;
export const MENU_TRANSITION = 0;
