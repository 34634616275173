import { useEffect, useReducer } from 'react';
import { useSocialDiscoveryProvider } from 'src/containers/SocialDiscovery/SocialDiscoveryProvider';
import { editPostsPageSlice, StateType } from './reducer';
import { createToastNotification, notifyIsDirty } from '../../Actions/SocialDiscoveryActions';
import { PublicPost } from '../../types/entities.types';

const { reducer, getInitialState, actions } = editPostsPageSlice;
export const useEditPostsPageState = () => {
  const { service, appDispatch } = useSocialDiscoveryProvider();
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const fetchPublicPosts = async () => {
    dispatch(actions.setIsFetching());
    const response = await service.getPublicPosts();
    dispatch(actions.resolvePublicPosts(response));
  };
  const savePosts = async () => {
    if (state.type !== StateType.dirty) return;
    dispatch(actions.setIsSaving());
    const response = await service.savePublicPosts(state.editedPosts);
    dispatch(actions.resolveSavedPosts(response));
  };
  const updatePosts = (updatedPosts: PublicPost[]) => {
    dispatch(actions.updatePosts(updatedPosts));
  };

  useEffect(() => {
    fetchPublicPosts();
  }, [service]);

  useEffect(() => {
    if (state.type === StateType.dirty || state.type === StateType.noChanges)
      appDispatch(notifyIsDirty(state.type === StateType.dirty));
  }, [state.type]);

  useEffect(() => {
    if (state.message) {
      appDispatch(
        createToastNotification({
          type: state.message.type,
          messageKey: state.message.messageKey,
          timestamp: new Date().getTime(),
        })
      );
    }
  }, [state.message]);

  return {
    state,
    updatePosts,
    savePosts,
  };
};
