import React from 'react';
import SkeletonMUI from '@mui/material/Skeleton';
import { SxProps, Theme } from '@mui/material/styles';
import { times } from 'lodash';
import { skeletonCss } from './Skeleton.style';

export interface SkeletonProps {
  animation?: 'pulse' | 'wave' | false;
  children?: React.ReactNode;
  height?: number | string;
  variant?: 'text' | 'rectangular' | 'rounded' | 'circular';
  width?: number | string;
  sx?: SxProps<Theme>;
  repeat?: number;
}

export const Skeleton = ({ repeat, ...rest }: SkeletonProps): JSX.Element => {
  return repeat === undefined ? (
    <SkeletonMUI animation='wave' {...rest} css={skeletonCss} />
  ) : (
    <>
      {times(repeat, index => (
        <SkeletonMUI animation='wave' {...rest} css={skeletonCss} key={index} />
      ))}
    </>
  );
};
