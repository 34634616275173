import { useState } from 'react';

interface ReturnType {
  isPopupOpen: boolean;
  openPopup: () => void;
  closePopup: () => void;
}
export const useStartEditPopup = (): ReturnType => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return { isPopupOpen, openPopup, closePopup };
};
