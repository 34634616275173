import { DomainEntityPath, ShopTypes } from '../types';
import { DomainEventsWebSocketServiceBase, DomainEvent } from '../domain-events';

type FeatureTogglesEventData = { featureToggles: ShopTypes.ShopFeatureToggles; shopId: number };

export enum IShopFeatureTogglesEventTopicNames {
  Update = 'feature_toggles:update',
}

export type FeatureTogglesUpdateEvent = DomainEvent<
  FeatureTogglesEventData,
  IShopFeatureTogglesEventTopicNames
>;

class FeatureTogglesWebSocketService extends DomainEventsWebSocketServiceBase<IShopFeatureTogglesEventTopicNames> {
  domainEntity = DomainEntityPath.FeatureToggles;

  subscribeToUpdateEvents(
    onFeatureTogglesUpdate: (payload: FeatureTogglesUpdateEvent) => void
  ): void {
    this.subscribeToEvents({
      topicKey: IShopFeatureTogglesEventTopicNames.Update,
      callback: onFeatureTogglesUpdate,
    });
  }
}

export const featureTogglesWebSocketService = new FeatureTogglesWebSocketService();
