import styled from '@emotion/styled';
import { Button } from 'src/components-dummy';

export const RuleActiveCellStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IconsWrapperStyled = styled.div`
  display: flex;

  svg {
    width: 16px;
    height: 16px;
    align-self: center;
  }
  .syte-popup-trigger {
    margin-left: 4px;
  }
`;

export const ButtonDescriptionStyled = styled(Button)`
  width: 100%;
  padding-left: 0px;
  text-align: start;
  color: ${({ theme }) => theme.palette.custom['primary-main']};
  background-color: transparent;
  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
  & > span {
    width: 100%;
  }
`;
