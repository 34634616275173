import { AvailableIcons } from 'src/components-dummy';
import { DomainEntityPath } from 'src/services';
import { ParentDomainBase } from '../../types';
import { recentlyViewedSubDomainGroup } from './recently-viewed-settings-sub-domain';

const baseTitle = 'Recently Viewed';

export const recentlyViewedDomainGroup: ParentDomainBase = {
  domainKey: DomainEntityPath.RecentlyViewed,
  title: baseTitle,
  icon: AvailableIcons.HollowStar,
  subDomains: [
    { ...recentlyViewedSubDomainGroup, domainKey: DomainEntityPath.RecentlyViewedSettings },
  ],
};
