import React, { MouseEvent, useCallback } from 'react';
import {
  EllipsisMenuButton,
  Menu,
  Tooltip,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { MenuActionsStyled, MenuItemStyled } from './RowActionsMenu.styles';

interface RowActionMenuProps {
  menuAnchorElement: HTMLElement | null;
  onMenuClicked: (event: MouseEvent<HTMLElement>) => void;
  onMenuClose: () => void;
  onDetailsClick?: () => void;
  onDownloadFeedFileClick?: () => void;
}

export const RowActionsMenu = ({
  menuAnchorElement,
  onMenuClicked,
  onMenuClose,
  onDetailsClick,
  onDownloadFeedFileClick,
}: RowActionMenuProps): JSX.Element => {
  const isOpen = Boolean(menuAnchorElement);

  const onItemClick = useCallback(
    onClick => {
      onClick();
      onMenuClose();
    },
    [onMenuClose]
  );

  return (
    <MenuActionsStyled>
      <EllipsisMenuButton onClick={onMenuClicked} isActive={isOpen} />
      <Menu
        open={isOpen}
        anchorEl={menuAnchorElement}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: 'right',
        }}
      >
        <MenuItemStyled
          key='feed-upload-log-details'
          onClick={() => onItemClick(onDetailsClick)}
          disabled={!onDetailsClick}
        >
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            Details
          </Typography>
        </MenuItemStyled>
        <MenuItemStyled
          key='feed-upload-log-download-link'
          onClick={() => onItemClick(onDownloadFeedFileClick)}
          disabled={!onDownloadFeedFileClick}
        >
          <Tooltip
            value='Click to download the original feed file, as received. The download can take a few minutes and will run in the background'
            position='bottom center'
            disabled={!onDownloadFeedFileClick}
          >
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Download
            </Typography>
          </Tooltip>
        </MenuItemStyled>
      </Menu>
    </MenuActionsStyled>
  );
};
