import { MutableRefObject, useLayoutEffect, useRef, useState } from 'react';
import { displayVariants } from './display-variants';

interface UseDisplayVariantsReturnType {
  toggleRef: MutableRefObject<HTMLDivElement | null>;
  isExpanded: boolean;
  onToggle: VoidFunction;
}

export const useDisplayVariants = (): UseDisplayVariantsReturnType => {
  const toggleRef = useRef<HTMLDivElement | null>(null);

  const [isExpanded, setIsExpanded] = useState(false);

  useLayoutEffect(() => {
    if (toggleRef?.current && !isExpanded) {
      displayVariants(toggleRef.current);
    }
  }, [isExpanded, toggleRef]);

  const onToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return {
    toggleRef,
    isExpanded,
    onToggle,
  };
};
