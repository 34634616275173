import React, { RefObject } from 'react';
import {
  SectionBox,
  TextBox,
  NumericInput,
  DropDownOption,
  TooltipedFormField,
  Typography,
  TypographyType,
  TypographyVariant,
  Switch,
  Select,
  SelectType,
  SelectOnChangeEvent,
  MenuItem,
} from 'src/components-dummy';
import {
  FilterSetConfiguration,
  FilterSetSettings,
  AutoExpandFilters,
} from 'src/services/src/service/types/filters';
import { useFilterSetSettingsForm } from './use-filter-set-settings-form';
import './FilterSetSettings.scss';
import { FormApiRef } from '../AugmentedSearch/types';

const autoExpandFiltersOptions: DropDownOption<AutoExpandFilters>[] = [
  { value: AutoExpandFilters.None, text: 'Do not expand filters' },
  { value: AutoExpandFilters.Custom, text: 'Expand top filters' },
  { value: AutoExpandFilters.All, text: 'Expand all filters' },
];
export interface FilterSetSettingsProps {
  filterSet: FilterSetConfiguration;
  shopId: number;
  onSubmitDispatcher: ({ shopId, data }: { shopId: number; data: FilterSetConfiguration }) => void;
  isDirtyDispatcher: (isDirty: boolean) => void;
  formApiRef: RefObject<FormApiRef>;
  onSubmitStatusChange: (canSubmit: boolean) => void;
}

export const FilterSetSettingsForm = ({
  filterSet,
  shopId,
  onSubmitDispatcher,
  isDirtyDispatcher,
  formApiRef,
  onSubmitStatusChange,
}: FilterSetSettingsProps): JSX.Element => {
  const doSubmit = (settings: FilterSetSettings) => {
    onSubmitDispatcher({ shopId, data: { ...filterSet, settings } });
  };
  const { formDraft, updateForm, errors } = useFilterSetSettingsForm(
    filterSet?.settings,
    isDirtyDispatcher,
    doSubmit,
    formApiRef,
    onSubmitStatusChange
  );

  const convertExpandAndUpdateForm: SelectOnChangeEvent = event =>
    updateForm({ expand: event.target.value as AutoExpandFilters });

  return formDraft ? (
    <div className='syte-filter-set'>
      <SectionBox title='Basic Settings' divider>
        <TooltipedFormField
          label='Configuration name'
          error={errors.name?.required || errors.name?.valueValidation}
          className='set-name'
        >
          <TextBox value={formDraft.name} onChange={name => updateForm({ name })} />
        </TooltipedFormField>
      </SectionBox>
      <SectionBox title='Display Settings' divider>
        <div className='display-settings'>
          <div className='toggle-section'>
            <Switch
              checked={formDraft.enableStickyFilters}
              onChange={enableStickyFilters => updateForm({ enableStickyFilters })}
            >
              <Switch.TitleTemplate>
                <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
                  Sticky filters display
                </Typography>
              </Switch.TitleTemplate>
            </Switch>
            <Switch
              checked={formDraft.collapseList}
              onChange={collapseList => updateForm({ collapseList })}
            >
              <Switch.TitleTemplate>
                <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
                  Add “Show More” for additional filters
                </Typography>
              </Switch.TitleTemplate>
            </Switch>
            {formDraft.collapseList ? (
              <TooltipedFormField
                label='Number of filters displayed before “show more”'
                error={
                  errors.collapseListAfter?.required || errors.collapseListAfter?.valueValidation
                }
              >
                <NumericInput
                  value={formDraft.collapseListAfter}
                  onChange={collapseListAfter => updateForm({ collapseListAfter })}
                />
              </TooltipedFormField>
            ) : null}
            <Switch
              checked={formDraft.collapseBuckets}
              onChange={collapseBuckets => updateForm({ collapseBuckets })}
            >
              <Switch.TitleTemplate>
                <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
                  Add “Show More” for additional filter values
                </Typography>
              </Switch.TitleTemplate>
            </Switch>
            {formDraft.collapseBuckets ? (
              <TooltipedFormField
                label='Number of filter values displayed before “show more”'
                error={
                  errors.collapseBucketsAfter?.required ||
                  errors.collapseBucketsAfter?.valueValidation
                }
              >
                <NumericInput
                  value={formDraft.collapseBucketsAfter}
                  onChange={collapseBucketsAfter => updateForm({ collapseBucketsAfter })}
                />
              </TooltipedFormField>
            ) : null}
          </div>
          <div className='auto-expand-section'>
            <Select
              value={formDraft.expand}
              type={SelectType.Menu}
              label='Expand filters'
              onChange={convertExpandAndUpdateForm}
              className='syte-drop-down'
            >
              {autoExpandFiltersOptions.map((option: { value: string; text: string }) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
            {formDraft.expand === AutoExpandFilters.Custom ? (
              <TooltipedFormField
                label='Number of expanded filters'
                error={errors.expandCount?.required || errors.expandCount?.valueValidation}
              >
                <NumericInput
                  value={formDraft.expandCount}
                  onChange={expandCount => updateForm({ expandCount })}
                />
              </TooltipedFormField>
            ) : null}
          </div>
        </div>
      </SectionBox>
    </div>
  ) : (
    <></>
  );
};
