import React, { useState } from 'react';
import { CollectionPage } from './CollectionPage.config';
import { collectionsRoutes } from '../../app-routes';
import { Page, Typography, TypographyType, TypographyVariant } from '../../components-dummy';
import {
  PageSideNavListItem,
  PageSideNavListStyled,
} from '../../components-dummy/SideNav/components/PageSideNav/PageSideNav.styled';
import { EditCollectionContainerStyled } from '../../containers/EditCollection/EditCollectionContainer.styles';

interface Props {
  children: JSX.Element;
  onPageChange: (page: CollectionPage) => void;
}

export const EditCollectionRulesVariantsPage = ({ children, onPageChange }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const sideNavItems = [
    {
      title: collectionsRoutes.editCollectionRules.title,
      action: () => onPageChange(CollectionPage.Edit),
    },
    {
      title: collectionsRoutes.editCollectionMerchandisingRules.title,
      action: () => onPageChange(CollectionPage.EditMerchRules),
    },
    {
      title: collectionsRoutes.editCollectionRankingStrategy.title,
      action: () => onPageChange(CollectionPage.EditRanking),
    },
  ];

  const onSideNavItemClick = (cb: VoidFunction, i: number) => {
    cb();
    setSelectedIndex(i);
  };

  return (
    <EditCollectionContainerStyled layout='sidebar'>
      <Page.SideBar>
        <PageSideNavListStyled>
          {sideNavItems.map(({ title, action }, index) => {
            const isActive = index === selectedIndex;
            return (
              <PageSideNavListItem
                key={index}
                onClick={() => onSideNavItemClick(action, index)}
                isActive={isActive}
                isDisabled={false}
              >
                <Typography
                  type={TypographyType.Body}
                  variant={
                    isActive ? TypographyVariant.MediumBold : TypographyVariant.MediumRegular
                  }
                >
                  {title}
                </Typography>
              </PageSideNavListItem>
            );
          })}
        </PageSideNavListStyled>
      </Page.SideBar>
      <Page.Content>{children}</Page.Content>
    </EditCollectionContainerStyled>
  );
};
