import React from 'react';
import { Row } from 'react-table';
import { TableV2 } from 'src/components-dummy';
import { AdminSearchCatalogResult } from '../../../../services/src/service/types/admin-search/admin-search-results';
import { TableBodyRowCell } from '../../AdminSearchModal.styles';
import { tableColumns } from './tableColumns';

interface CatalogsTableRowProps {
  row: Row<AdminSearchCatalogResult>;
  onClick: VoidFunction;
}

export function TableRow({ row, onClick }: CatalogsTableRowProps): JSX.Element {
  return (
    <>
      <TableV2.BodyRow {...row.getRowProps()} key={row.original.shopId}>
        {row.cells.map(cell => {
          const propName = cell.column.id;
          let cellValue = cell.value;

          if (
            propName === tableColumns.isPrimary.accessor ||
            propName === tableColumns.isAugmentedSearchCatalog.accessor ||
            propName === tableColumns.isMultiLocale.accessor
          ) {
            cellValue = cell.value ? 'true' : 'false';
          }

          return (
            <TableBodyRowCell
              {...cell.getCellProps()}
              key={propName}
              width={cell.column.width as string}
              minWidth={cell.column.minWidth}
              data-id={`${row.original.name}-${row.original.shopName}-${propName}`}
              onClick={onClick}
            >
              <TableV2.BodyRowCellText>{cellValue}</TableV2.BodyRowCellText>
            </TableBodyRowCell>
          );
        })}
      </TableV2.BodyRow>
    </>
  );
}
