import { FeedDataFieldType, ShopDataField } from 'src/services/src/service/types';

export function addSeparatorsToNumber(number) {
  return new Intl.NumberFormat('en-US').format(number);
}

/**
 * Currently we're supporting "catalog_field" data fields (without "tagging" / "tags").
 */
export const isAcceptedDataField = ({
  dataField,
  catalogName,
}: {
  dataField: ShopDataField;
  catalogName: string;
}) => {
  const supportsCatalogName = dataField.catalogNames.includes(catalogName);
  const isCatalogField = dataField.fieldType === 'catalog_field';
  const isTaggingField = dataField.name === 'tagging';
  const isTagsField = dataField.name === 'tags';

  return supportsCatalogName && isCatalogField && !isTaggingField && !isTagsField;
};

export const isAcceptedDataFieldForFilters = ({
  dataField,
  catalogName,
}: {
  dataField: ShopDataField;
  catalogName: string;
}) => {
  if (!isAcceptedDataField({ dataField, catalogName })) {
    return false;
  }

  const supportsTextFiltering = dataField.types.includes(FeedDataFieldType.Text);
  const supportsKeywordFiltering = dataField.types.includes(FeedDataFieldType.Keyword);

  // Ignore fields that only support text filtering and has no "keyword" filtering
  return !(supportsTextFiltering && !supportsKeywordFiltering);
};
