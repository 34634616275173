import { useCallback, useEffect, useState } from 'react';
import { useValidateSchema } from 'src/hooks';
import { PersonalizationSettingsReducerState } from 'src/app-state-types';
import { UsePersonalizationSettingsDraft, UsePersonalizationSettingsDraftArgs } from './types';
import { personalizationSettingsValidationSchema } from './constants';
import { personalizationSettingsActionsMethods } from './Actions';
import { isDataDirty } from '../../utils';
import { FieldType, PersonalizationSettingsTypes } from '../../services';
import { getNumericFields } from '../helpers/getNumericFields';
import { useMappedSelectValues } from '../hooks/useMappedSelectValues';
import { SortingOrder } from '../../types/enums/sorting-order';

export function usePersonalizationSettingsDraft({
  personalizationSettings,
  dispatch,
  shopId,
  dataFields,
  selectedVariantId,
  onIsDirty,
  shouldRefetch = false,
}: UsePersonalizationSettingsDraftArgs): UsePersonalizationSettingsDraft {
  const [draftPersonalizationSettings, setDraftPersonalizationSettings] =
    useState(personalizationSettings);

  const numericDataFields = getNumericFields(dataFields);

  const dataFieldsDropdownOptions = useMappedSelectValues(numericDataFields);

  const { errors, validate, isValid } =
    useValidateSchema<PersonalizationSettingsTypes.PersonalizationSettings>({
      schema: personalizationSettingsValidationSchema,
    });

  const isDirty = isDataDirty(personalizationSettings, draftPersonalizationSettings);

  const onSubmit = (): void => {
    if (isValid) {
      dispatch(
        personalizationSettingsActionsMethods.updatePersonalizationSettings({
          shopId,
          numberOfResultsToDisplay: draftPersonalizationSettings.numberOfResultsToDisplay,
          personalizationFallbackMethod: draftPersonalizationSettings.personalizationFallbackMethod,
          useSessionSkusFromPageViewEvent:
            draftPersonalizationSettings.useSessionSkusFromPageViewEvent,
          variantId: selectedVariantId,
          addToCart: draftPersonalizationSettings.addToCart,
          personalizationFallbackField: draftPersonalizationSettings.personalizationFallbackField,
          sortingOrder: draftPersonalizationSettings.sortingOrder || SortingOrder.Desc,
        })
      );
    }
  };

  const onDiscard = (): void => {
    setDraftPersonalizationSettings(personalizationSettings);
  };

  const onChange = (partialSettings: Partial<PersonalizationSettingsReducerState>) => {
    const updatedState = { ...draftPersonalizationSettings, ...partialSettings };
    validate({ dataToValidate: updatedState });
    setDraftPersonalizationSettings(updatedState);
  };

  const fetchPersonalizationSettings = useCallback(() => {
    dispatch(
      personalizationSettingsActionsMethods.fetchPersonalizationSettings({
        shopId,
        variantId: selectedVariantId,
      })
    );
  }, [shopId, selectedVariantId]);

  useEffect(() => {
    validate({ dataToValidate: draftPersonalizationSettings });
  }, [draftPersonalizationSettings]);

  useEffect((): void => {
    setDraftPersonalizationSettings(personalizationSettings);
  }, [personalizationSettings]);

  useEffect(() => {
    const payload = { isDirty: !!isDirty };
    if (onIsDirty) {
      onIsDirty(payload);
    } else {
      dispatch(personalizationSettingsActionsMethods.notifyIsDirty(payload));
    }
  }, [isDirty]);

  useEffect(() => {
    validate({ dataToValidate: draftPersonalizationSettings });
  }, [draftPersonalizationSettings]);

  useEffect(() => {
    fetchPersonalizationSettings();
  }, [fetchPersonalizationSettings]);

  useEffect(() => {
    if (shouldRefetch) {
      fetchPersonalizationSettings();
    }
  }, [shouldRefetch]);

  useEffect(() => {
    dispatch(
      personalizationSettingsActionsMethods.getDataFields({
        shopId,
        fieldType: FieldType.CatalogField,
      })
    );
  }, [shopId]);

  return {
    draftPersonalizationSettings,
    errors,
    isValid,
    isDirty,
    onSubmit,
    onDiscard,
    onChange,
    dataFieldsDropdownOptions,
  };
}
