import React, { useEffect, useState } from 'react';
import { IndexedOffers } from '../types';

interface UseToggleAllItemsReturn {
  items: IndexedOffers;
  toggleAllItems: (e: React.MouseEvent) => void;
}

export const useToggleAllItems = (offers: IndexedOffers): UseToggleAllItemsReturn => {
  const [items, setItems] = useState<IndexedOffers>([]);

  useEffect(() => {
    const expandedItems = offers.map(item => ({
      ...item,
      isOpen: true,
    }));
    setItems(expandedItems);
  }, [offers]);

  const toggleAllItems = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const isOpenAll = items.every(item => item.isOpen);
    setItems(prevItems =>
      prevItems.map(item => ({
        ...item,
        isOpen: !isOpenAll,
      }))
    );
  };

  return { items, toggleAllItems };
};
