import { useEffect } from 'react';
import {
  deepTagReportsWebSocketService,
  DeepTagReportDeleteEvent,
  DeepTagReportUpdateEvent,
  DeepTagReportEventTopicNames,
} from 'src/services';
import { Dispatch } from 'src/components-bl';
import { useDeepTagReportsWSActions } from './useDeepTagReportsWS.actions';

export const useDeepTagReportsWS = ({
  dispatch,
  shopId,
}: {
  dispatch: Dispatch;
  shopId: number | undefined;
}): void => {
  const issueToastNotification = ({
    eventIssuedByCurrentUser,
    topicKey,
    targetShopId,
  }: Pick<DeepTagReportUpdateEvent, 'eventIssuedByCurrentUser' | 'topicKey'> & {
    targetShopId: number;
  }) => {
    if (eventIssuedByCurrentUser) return;

    let customMessage;

    switch (topicKey) {
      case DeepTagReportEventTopicNames.Create: {
        customMessage = 'New deep tag report was created';

        break;
      }
      case DeepTagReportEventTopicNames.Update: {
        customMessage = 'Deep tag report updated';
        break;
      }
      case DeepTagReportEventTopicNames.IsReady: {
        customMessage = 'Deep tag report is ready';
        break;
      }
      case DeepTagReportEventTopicNames.Delete:
      default: {
        customMessage = 'New deep tag report deleted';

        break;
      }
    }

    const isSameShop = targetShopId === shopId;

    if (!isSameShop) {
      customMessage += ` in shop: ${targetShopId}`;
    }

    dispatch(useDeepTagReportsWSActions.reportNotification({ customMessage }));
  };

  const onReportCreate = (payload: DeepTagReportUpdateEvent) => {
    const targetShopId = payload.data.report.shopId;

    if (targetShopId === shopId) {
      dispatch(useDeepTagReportsWSActions.newReport(payload.data));

      issueToastNotification({ ...payload, targetShopId });
    }
  };

  const onReportUpdate = (payload: DeepTagReportUpdateEvent) => {
    const targetShopId = payload.data.report.shopId;

    if (targetShopId === shopId) {
      dispatch(useDeepTagReportsWSActions.reportUpdated(payload.data));

      issueToastNotification({ ...payload, targetShopId });
    } else if (payload.topicKey === DeepTagReportEventTopicNames.IsReady) {
      // Notify about new report even when on different shop
      issueToastNotification({ ...payload, targetShopId });
    }
  };

  const onReportDelete = (payload: DeepTagReportDeleteEvent) => {
    const targetShopId = payload.data.shopId;

    if (targetShopId === shopId) {
      dispatch(useDeepTagReportsWSActions.reportDeleted(payload.data));

      issueToastNotification({ ...payload, targetShopId });
    }
  };

  useEffect(() => {
    deepTagReportsWebSocketService.subscribeToReportCreateEvents(onReportCreate);
    deepTagReportsWebSocketService.subscribeToReportDeleteEvents(onReportDelete);
    deepTagReportsWebSocketService.subscribeToReportUpdateEvents(onReportUpdate);
    deepTagReportsWebSocketService.subscribeToReportReadyEvents(onReportUpdate);

    return () => {
      deepTagReportsWebSocketService.clearListeners();
    };
  }, [shopId]);
};
