import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const VerticalSelectContainerStyled = styled.div<{ isChecked: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 32px;
  height: 136px;
  min-width: 210px;
  align-items: flex-start;
  padding: 24px 16px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 12px;
  border: 1px solid;
  border-color: ${({ theme, isChecked }) =>
    isChecked ? theme.palette.custom['gray-60'] : theme.palette.custom['primary-80']};

  cursor: pointer;

  ${({ isChecked, theme }) =>
    isChecked &&
    css`box-shadow: 
    0px 0px 0px 1.5px ${theme.palette.custom['primary-main']}, 0px 0px 0px 2.5px ${theme.palette.custom['primary-90']}};`}
`;

export const DataSourceRadioButtonsContainerStyled = styled.div`
  display: flex;
  border-radius: 12px;
  width: 100%;

  .MuiFormControlLabel-root {
    align-items: flex-start;
    margin: 0;
  }

  .MuiFormGroup-root {
    width: 100%;
  }

  .MuiFormControlLabel-root {
    border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
    padding: 24px;
    border-radius: 12px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .MuiFormControlLabel-label {
    padding-left: 4px;
  }
`;
