import { ExperimentSlim } from 'src/services';

export enum CreateExperimentFormActionTypes {
  CreateExperimentRequest = 'CreateExperimentForm/CreateExperimentRequest',
  CreateExperimentSuccess = 'CreateExperimentForm/CreateExperimentSuccess',
  CreateExperimentError = 'CreateExperimentForm/CreateExperimentError',
}

export interface CreateExperimentFormCreateExperimentRequestActionPayload {
  shopId: number;
  name: string;
}

export interface CreateExperimentFormCreateExperimentSuccessActionPayload {
  experiment: ExperimentSlim;
}

export interface CreateExperimentFormCreateExperimentErrorActionPayload {
  error: unknown;
}

export interface CreateExperimentFormCreateExperimentRequestAction {
  type: typeof CreateExperimentFormActionTypes.CreateExperimentRequest;
  payload: CreateExperimentFormCreateExperimentRequestActionPayload;
}

export interface CreateExperimentFormCreateExperimentSuccessAction {
  type: typeof CreateExperimentFormActionTypes.CreateExperimentSuccess;
  payload: CreateExperimentFormCreateExperimentSuccessActionPayload;
}

export interface CreateExperimentFormCreateExperimentErrorAction {
  type: typeof CreateExperimentFormActionTypes.CreateExperimentError;
  payload: CreateExperimentFormCreateExperimentErrorActionPayload;
}
export interface CreateExperimentFormCreateExperimentActionPayload
  extends CreateExperimentFormCreateExperimentRequestActionPayload {
  editExperimentFormRoute: string;
}

export type CreateExperimentFormAction =
  | CreateExperimentFormCreateExperimentRequestAction
  | CreateExperimentFormCreateExperimentSuccessAction
  | CreateExperimentFormCreateExperimentErrorAction;
