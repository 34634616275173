import React from 'react';
import { useAppSelector } from '../../../../hooks';
import { ShopFeature } from '../../../../app-types';
import { Typography, TypographyType, TypographyVariant } from '../../../../components-dummy';
import { ExternalLink } from '../ExternalLink';
import { RankingStrategyContainer } from './RankingStrategy.styles';

export const RankingStrategy = ({
  editRankingStrategy,
}: {
  editRankingStrategy: VoidFunction;
}): JSX.Element | null => {
  const { selectedExperience, collection } = useAppSelector(({ visualEditor }) => visualEditor);

  const showRanking =
    selectedExperience === ShopFeature.Collections
      ? Boolean(collection)
      : selectedExperience !== null;

  if (!showRanking) return null;

  return (
    <RankingStrategyContainer>
      <Typography type={TypographyType.Body} variant={TypographyVariant.LargeRegular}>
        Ranking Strategy
      </Typography>
      <ExternalLink onClick={editRankingStrategy}>Edit Ranking</ExternalLink>
    </RankingStrategyContainer>
  );
};
