import { AvailableIcons } from 'src/components-dummy';
import { DomainEntityPath } from 'src/services';
import { EnableUiTest } from 'src/services/src/service/types/shops/shop';
import { DomainGroup, SubDomainGroupBase } from '../../types';
import { enableUiTestDiffSchema } from './enable-ui-test-diff-shcema';

export const enableUiTestDomainGroup: DomainGroup & SubDomainGroupBase<EnableUiTest> = {
  domainKey: DomainEntityPath.EnableUiTest,
  title: 'Ui Test',
  icon: AvailableIcons.Setting,
  getCardTitle: ({ entityPath }) => {
    const titleResult = [''];
    switch (entityPath) {
      case 'enableUiTest': {
        titleResult.push('Ui Test');
        break;
      }
      default:
        break;
    }
    return titleResult.join('');
  },
  fieldsSchema: enableUiTestDiffSchema,
};
