import React from 'react';
import { AvailableIcons, Tooltip, Icon } from 'src/components-dummy';
import {
  DragHandleStyled,
  IconsWrapperStyled,
  FilterActiveCellStyled,
  DragHandleWrapperStyled,
} from './FiltersTableRow.styles';

const IconIndication = ({
  tooltipValue,
  iconName,
}: {
  tooltipValue: string;
  iconName: AvailableIcons;
}): JSX.Element => {
  return (
    <Tooltip value={tooltipValue}>
      <Icon name={iconName} />
    </Tooltip>
  );
};

export const DragHandleCell = ({
  isUsingInvalidDataField,
  disabled,
}: {
  isUsingInvalidDataField: boolean;
  disabled: boolean;
}): JSX.Element => {
  return (
    <FilterActiveCellStyled>
      <DragHandleWrapperStyled>
        <DragHandleStyled name={AvailableIcons.DragHandle} disabled={disabled} />
      </DragHandleWrapperStyled>
      {isUsingInvalidDataField && (
        <IconsWrapperStyled>
          <IconIndication
            tooltipValue='The source field for this filter is not valid anymore'
            iconName={AvailableIcons.CircleWarning}
          />
        </IconsWrapperStyled>
      )}
    </FilterActiveCellStyled>
  );
};
