import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const VerticalSelectContainerStyled = styled.div<{ isChecked: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 136px;
  min-width: 210px;
  align-items: start;
  padding: 24px 16px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 12px;
  border: 1px solid;
  border-color: ${({ theme, isChecked }) =>
    isChecked ? theme.palette.custom['gray-60'] : theme.palette.custom['primary-80']};

  cursor: pointer;

  ${({ isChecked, theme }) =>
    isChecked &&
    css`box-shadow: 
    0px 0px 0px 1.5px ${theme.palette.custom['primary-main']}, 0px 0px 0px 2.5px ${theme.palette.custom['primary-90']}};`}
`;

export const VerticalSelectHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  svg:first-child {
    margin-right: 8px;
  }
`;
