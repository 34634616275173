const max = 20;

export function getDisplayCount(count?: number): string | undefined {
  const actualCount = count || 0;

  if (actualCount === 0) {
    return undefined;
  }

  if (actualCount > max) {
    return `${max}+`;
  }

  return `${actualCount}`;
}
