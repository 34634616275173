import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTheme } from '@emotion/react';
import { SHA256 } from 'crypto-js';
import { InitialLetterStyled, InitialLetterWrapperStyled } from './InitialLetter.styles';
import { AvailableIcons, Icon } from '../Icon';

export interface InitialLetterProps {
  value: string;
  className?: string;
  lettersCountToShow?: number;
  isSystem?: boolean;
}

function stringToInteger(str: string): number {
  const sha256Hash = SHA256(str).toString();

  const truncatedHash = sha256Hash.substring(0, 8);
  const integerHash = parseInt(truncatedHash, 16);

  return integerHash;
}

export const InitialLetter = ({
  value = '',
  className,
  lettersCountToShow = 1,
  isSystem,
}: InitialLetterProps): JSX.Element => {
  const theme = useTheme();

  const colorsArray = useMemo(() => {
    const systemColorConfig = {
      backgroundColor: theme.palette.custom['yellow-80'],
      textColor: theme.palette.custom.brown,
    };

    return [
      systemColorConfig,
      {
        backgroundColor: theme.palette.custom['pink-10'],
        textColor: theme.palette.custom['red-70'],
      },
      {
        backgroundColor: theme.palette.custom['pink-20'],
        textColor: theme.palette.custom['red-60'],
      },
      {
        backgroundColor: theme.palette.custom['green-40'],
        textColor: theme.palette.custom['green-30'],
      },
    ];
  }, [theme]);

  const [valueToDisplay, { backgroundColor, textColor }] = useMemo(() => {
    const trimmedValueWithoutSpaces = value.trim().replace(/\s/g, '');

    const displayValue = trimmedValueWithoutSpaces.slice(
      0,
      Math.min(lettersCountToShow, trimmedValueWithoutSpaces.length)
    );

    let colorConfig;

    if (isSystem) {
      [colorConfig] = colorsArray;
    } else {
      const hashedInteger = stringToInteger(value);

      const index = hashedInteger % colorsArray.length;

      colorConfig = colorsArray[index];
    }

    return [displayValue, colorConfig];
  }, [lettersCountToShow, value, colorsArray, isSystem]);

  return (
    <InitialLetterStyled className={classNames('syte-initial-letter', className)}>
      <InitialLetterWrapperStyled backgroundColor={backgroundColor} color={textColor}>
        {isSystem ? <Icon name={AvailableIcons.Setting} /> : valueToDisplay}
      </InitialLetterWrapperStyled>
    </InitialLetterStyled>
  );
};
