import React from 'react';
import { startCase } from 'lodash';
import { MerchandisingRuleTypes, ShopDataField } from 'src/services';
import { UNKNOWN_CONTINENT_VALUE } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormRegionSection/consts';
import { generateOptionsFromRegions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormRegionSection/helpers';
import { diffValueDisplayHelpers } from '../value-helpers';
import { DiffCardChangeValue } from '../../../DiffsCard';

const createTextFromCondition = (
  field: string | undefined,
  subType?: MerchandisingRuleTypes.MerchandisingRuleSubType,
  values: MerchandisingRuleTypes.SubRuleOptionalValues = [],
  dataFields?: ShopDataField[]
) => {
  const dataFieldMapping = dataFields?.find(({ name }) => name === field);
  const dataField = dataFieldMapping?.displayName || startCase(field);

  let operatorText: string;
  let valueText = '';
  switch (subType) {
    case MerchandisingRuleTypes.MerchandisingRuleSubType.Contains: {
      operatorText = 'Contains either';
      valueText = (values as string[]).map(value => `“${value?.toString?.() || ''}”`).join(' OR ');
      break;
    }
    case MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain: {
      operatorText = 'Does not contain';
      valueText = (values as string[]).map(value => `“${value?.toString?.() || ''}”`).join(' OR ');
      break;
    }
    case MerchandisingRuleTypes.MerchandisingRuleSubType.Equals:
      operatorText = 'Exactly matches';
      valueText = `“${values[0]}”`;
      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.HasValue:
      operatorText = 'Is not empty';
      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotHaveValue:
      operatorText = 'Is empty';
      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.EqualsSource:
      operatorText = 'Is equal to source SKU';
      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan:
      operatorText = 'Higher than';
      valueText = `${values[0]}`;
      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan:
      operatorText = 'Lower than';
      valueText = `${values[1]}`;
      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween:
      operatorText = 'Is between';
      valueText = `${values[0]} to ${values[1]}`;
      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.RelativeRange:
      operatorText = 'In Relative Range of';
      valueText = `${values[0]}% and ${values[1]}%`;
      break;
    default:
      operatorText = `<Invalid Condition>`;
  }

  const conditionText = (
    <>
      <strong>{`${dataField} ${operatorText}`}</strong> {valueText}
    </>
  );
  return conditionText;
};

const convertConditionToText = (
  condition: MerchandisingRuleTypes.RootCondition,
  dataFields?: ShopDataField[]
) => {
  const { values, field, subType, enabled } = condition;

  if (!enabled) {
    return diffValueDisplayHelpers.DEFAULT_EMPTY_VALUE;
  }

  return createTextFromCondition(field, subType, values, dataFields);
};

export const convertSubRulesToReadonlyJsx = (
  subRules: MerchandisingRuleTypes.SubRule[],
  dataFields?: ShopDataField[]
): DiffCardChangeValue => {
  if (subRules.length === 0) {
    return diffValueDisplayHelpers.DEFAULT_EMPTY_VALUE;
  }

  const subRuleItemsJsx = subRules.map((subRule, index) => {
    const conditionText = convertConditionToText(subRule, dataFields);
    const andJsx = index < subRules.length - 1 ? ' AND ' : '';
    const key = index; // can use index, as array is not sorted or changed while viewing chagnes preview
    const position = subRule?.position;
    const positionJsx = (
      <>
        {position ? (
          <span>
            {' '}
            that pinned to <strong>position</strong> {position}
          </span>
        ) : null}
      </>
    );

    const subRuleJsx = (
      <span key={key}>
        <strong>Include products</strong> where {conditionText}
        {positionJsx}
        {andJsx}
        <br />
      </span>
    );

    return subRuleJsx;
  });

  const jsx = <p>{subRuleItemsJsx}</p>;
  return jsx;
};

type ConditionType =
  | MerchandisingRuleTypes.RootCondition
  | MerchandisingRuleTypes.FilterByCondition;

export const convertConditionsToJSX = (conditions: ConditionType[] | undefined) => {
  if (!conditions?.length) {
    return diffValueDisplayHelpers.DEFAULT_EMPTY_VALUE;
  }
  const conditionsItemsJSX = conditions.map((condition: ConditionType, index: number) => {
    const { field, subType, values } = condition;
    const conditionItemText = createTextFromCondition(field, subType, values);
    const andJsx = index < conditions.length - 1 ? ' AND ' : '';
    const key = index;

    return (
      <span key={key}>
        {conditionItemText}
        {andJsx}
        <br />
      </span>
    );
  });
  return <p>{conditionsItemsJSX}</p>;
};

export const convertRedirectRuleToText = (
  redirectRule: MerchandisingRuleTypes.IMerchandisingRuleRedirectRule
): DiffCardChangeValue => {
  return (
    <p>
      Display name : {redirectRule.displayName}
      <br />
      Target URL : {redirectRule.targetUrl}
    </p>
  );
};

export function convertRegionCodesToNames(
  regions: MerchandisingRuleTypes.MerchandiseRule['regions']
): string {
  return generateOptionsFromRegions({ regions })
    .sort(region => (region.parentTitle === UNKNOWN_CONTINENT_VALUE ? 1 : -1))
    .map(locale => locale.text)
    .join(', ');
}
