import { RankingStrategyTypes } from 'src/services/src/service/types';
import { RankingOrder } from 'src/services/src/service/types/ranking-strategy';
import { RankingWeightRecord } from 'src/components-bl/RankingStrategy/RankingStrategyTable/types';

export const productWeightsDefault: RankingWeightRecord = {
  field: '',
  weight: 0,
  order: RankingStrategyTypes.RankingOrder.Desc,
  name: '',
  enabled: true,
};

export const orderOptions = [
  { value: RankingOrder.Asc, text: 'Ascending' },
  { value: RankingOrder.Desc, text: 'Descending' },
];
