import { useState, useCallback, MouseEvent } from 'react';
import type { UseCollectionReturnType, UseCollectionArguments } from './types';
import { collectionActionMethods } from './actions';
import { DuplicateCollectionActionMethods } from '../DuplicateCollection';

export const useCollectionMenu = ({
  shopId,
  collectionId,
  dispatch,
  onEditCollection,
  variantId,
}: UseCollectionArguments): UseCollectionReturnType => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);

  const onMenuClicked = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setMenuAnchorElement(event.currentTarget);
    },
    [setMenuAnchorElement]
  );

  const onMenuClose = useCallback(() => {
    setMenuAnchorElement(null);
  }, [setMenuAnchorElement]);

  const closeDeleteDialog = (): void => {
    setShowDeleteDialog(false);
  };

  const onDeleteCollectionClicked = () => {
    setShowDeleteDialog(true);
  };

  const onDeleteCollection = useCallback(() => {
    dispatch(
      collectionActionMethods.deleteCollection({
        params: { shopId, collectionId, variantId },
      })
    );
  }, [shopId, collectionId, variantId]);

  const onDuplicateCollection = useCallback(() => {
    dispatch(
      DuplicateCollectionActionMethods.DuplicateCollection({
        shopId,
        collectionId,
        variantId,
      })
    );
  }, [shopId, collectionId, variantId]);

  const onDuplicateCollectionClicked = () => {
    onDuplicateCollection();
    onEditCollection(collectionId);
  };

  const onEditCollectionClicked = () => {
    onEditCollection(collectionId);
  };

  return {
    showDeleteDialog,
    closeDeleteDialog,
    onDeleteCollectionClicked,
    onDeleteCollection,
    onDuplicateCollectionClicked,
    onEditCollectionClicked,
    menuAnchorElement,
    onMenuClicked,
    onMenuClose,
  };
};
