import { useEffect, useState } from 'react';
import { useValidateSchema } from 'src/hooks';
import { createAccountFormActionMethods } from './Actions';
import { initialFormState, formValidationSchema } from './constants';
import {
  UseCreateAccountFormArguments,
  UseCreateAccountFormReturnType,
  CreateAccountFormState,
} from './types';

export const useCreateAccountForm = ({
  dispatch,
}: UseCreateAccountFormArguments): UseCreateAccountFormReturnType => {
  const [formState, setFormState] = useState(initialFormState);
  const [shouldShowFormPopup, setShouldShowFormPopup] = useState(false);

  const { errors, isValid, resetValidationState, validatePartial } = useValidateSchema({
    schema: formValidationSchema,
  });

  function onChange(partialState: Partial<CreateAccountFormState>) {
    const updatedState = { ...formState, ...partialState };

    setFormState(updatedState);
    validatePartial({ dataToValidate: updatedState });
  }

  function onSubmit() {
    if (isValid) {
      dispatch(createAccountFormActionMethods.createAccount(formState));

      setShouldShowFormPopup(false);
    }
  }

  function reset() {
    setFormState(initialFormState);
    resetValidationState();
  }

  useEffect(() => {
    if (!shouldShowFormPopup) {
      reset();
    }
  }, [shouldShowFormPopup]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return {
    state: formState,
    onSubmit,
    errors,
    isValid,
    onChange,
    shouldShowFormPopup,
    setShouldShowFormPopup,
  };
};
