import styled from '@emotion/styled';
import {
  CardItem,
  Checkbox,
  MenuList,
  MultiSelectMenu,
  SearchInput,
  Spinner,
  Typography,
} from 'src/components-dummy';

export const MultiSelectMenuStyled = styled(MultiSelectMenu)`
  @media (max-height: 900px) {
    ${({ options }) => options.length > 1 && 'height: 160px;'}

    li {
      padding-top: 2px !important;
      padding-bottom: 2px !important;
    }
  }
`;

export const CheckboxStyled = styled(Checkbox)<{ checked: boolean }>`
  margin-top: -3px;
  margin-right: 8px;
`;

export const ValuesFooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: auto;
  gap: 10px;
  padding-top: 16px;
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};
`;

export const SearchInputStyled = styled(SearchInput)`
  margin-bottom: 8px;
  width: 100%;

  .input-base-wrapper,
  .input-base-wrapper: focus,
  .input-base-wrapper: focus-within {
    border: unset !important;
    outline: unset !important;

    background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  }
`;

export const NotFoundTypography = styled(Typography)`
  margin-top: 20px;
  text-align: center;
  color: ${({ theme }) => theme.palette.custom['gray-50']};
`;

export const ValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const SpinnerStyled = styled(Spinner)`
  position: absolute;
  top: 45%;
`;

export const BackdropStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.custom['gray-10']};
  opacity: 10%;
  top: 0;
  border-radius: 12px;
`;

export const MenuListStyled = styled(MenuList)`
  @media (max-height: 900px) {
    height: 212px;
  }
`;

export const DataFieldValueStyled = styled.div<{ highlight?: boolean }>`
  width: 100%;
  height: 31px;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 2px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${({ highlight, theme }) => highlight && `color: ${theme.palette.custom['primary-main']};`}

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom['gray-90']};
    cursor: pointer;
  }
`;

export const DataFieldsValuesListStyled = styled(CardItem)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 394px;
  width: 326px;
  padding: 16px 12px;

  @media (max-height: 900px) {
    height: 293px;
  }
`;
