import React from 'react';
import { ReviewVersion } from 'src/components-bl/Versioning/ReviewVersion';
import { useAppDispatch, useAppSelector } from 'src/hooks';

export const ReviewVersionContainer = (): JSX.Element | null => {
  const version = useAppSelector(state => state.versioning.selectedVersion);
  const showReviewVersionModal = useAppSelector(state => state.versioning.showReviewVersionModal);
  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const dispatch = useAppDispatch();

  if (!shopId) {
    return null;
  }

  if (showReviewVersionModal === false) {
    return null;
  }

  return <ReviewVersion shopId={shopId} version={version} dispatch={dispatch} />;
};
