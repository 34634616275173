import { Typography, TypographyType, TypographyVariant } from '../../../../../components-dummy';
import React, { useCallback } from 'react';
import { Slider } from '../../../../../components-dummy/Slider';
import {
  DataFieldsListContainer,
  DataFieldsListStyled,
} from '../DataFieldsList/DataFieldsList.styles';

const sliderMarks = [
  { value: 1, label: '1' },
  { value: 6, label: '6' },
];

interface NumberOfItemsPerRowProps {
  itemsCountPerRow: number;
  onItemsCountPerRowChanged: (value: number) => void;
}

export const NumberOfItemsPerRow = ({
  itemsCountPerRow,
  onItemsCountPerRowChanged,
}: NumberOfItemsPerRowProps) => {
  const onSliderChange = useCallback(
    (_event: Event, value: number | number[]) => {
      if (typeof value === 'number') {
        onItemsCountPerRowChanged(value);
      }
    },
    [onItemsCountPerRowChanged]
  );
  return (
    <DataFieldsListContainer>
      <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
        Number of images per row
      </Typography>
      <DataFieldsListStyled>
        <Slider
          value={itemsCountPerRow}
          step={1}
          min={1}
          max={6}
          marks={sliderMarks}
          valueLabelDisplay='auto'
          onChange={onSliderChange}
        />
      </DataFieldsListStyled>
    </DataFieldsListContainer>
  );
};
