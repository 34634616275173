import React from 'react';
import { AvailableIcons, MenuItem } from '../../../../../components-dummy';
import { DisplayPreferences } from '../DisplayPreferences/DisplayPreferences';
import { LayoutViewButton, MenuStyled, StyledIcon } from '../VisualEditorResultsScreen.styles';
import { useVisualEditorSettings } from './useVisualEditorSettings';

export const VisualEditorSettings = ({ shopId }: { shopId: number }): JSX.Element => {
  const {
    menuAnchorEl,
    isMenuOpen,
    onCloseMenu,
    onTriggerClick,
    isModalOpen,
    onCloseModal,
    onDisplayPreferencesClick,
  } = useVisualEditorSettings();

  return (
    <>
      <LayoutViewButton variant='tertiary' onClick={onTriggerClick}>
        <StyledIcon name={AvailableIcons.Gear} />
      </LayoutViewButton>
      <MenuStyled
        open={isMenuOpen}
        anchorEl={menuAnchorEl}
        label='Visual Editor Settings'
        onClose={onCloseMenu}
        transitionDuration={{ appear: 0, enter: 0, exit: 0 }}
      >
        <MenuItem onClick={onDisplayPreferencesClick}>Display Preferences</MenuItem>
      </MenuStyled>
      {isModalOpen && <DisplayPreferences shopId={shopId} onCloseModal={onCloseModal} />}
    </>
  );
};
