import styled from '@emotion/styled';
import { Button, Typography } from 'src/components-dummy';

// eslint-disable-next-line prettier/prettier
export const ActionButtonStyled = styled(Button)<{ marginRight: number; clicked: boolean }>`
  margin-right: ${({ marginRight }) => marginRight}px;
  color: ${({ theme, clicked }) => (clicked ? theme.palette.custom['primary-main'] : 'initial')};
  background-color: ${({ theme, clicked }) =>
    clicked ? theme.palette.custom['primary-light'] : theme.palette.custom['gray-80']};

  svg {
    width: 18px;
  }

  path {
    ${({ theme, clicked }) => (clicked ? `stroke: ${theme.palette.custom['primary-main']}` : '')}
  }
`;

export const DeleteButtonStyled = styled(Button)`
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.custom.red};
  padding: 8px 25px;

  svg {
    width: 18px;
  }

  path {
    stroke: ${({ theme }) => theme.palette.common.white};
  }
`;

export const DialogTypographyStyled = styled(Typography)`
  margin-bottom: 20px;
`;
