import React from 'react';
import './RecEnginesGenerslSettingsForm.scss';
import { MultipleRecPerPageSettings } from 'src/components-bl';
import { Button } from 'src/components-dummy';
import { OpenNewTabSettings } from '../OpenNewTabSettings';
import { OverrideSettings } from '../OverrideSettings';
import { createOptionalPortal } from '../../../helpers';
import { useRecEnginesGeneralSettingsDraft } from './useRecEnginesGeneralSettingsDraft';
import { RecEnginesGeneralSettingsProps } from './types';
import { ConfigurationNote } from '../../../../components-dummy/Labels/ConfigurationNote';
import { ConfigurationVariant } from '../../../../components-dummy/Labels/configuration-variant';
import { SyteAdminContent } from '../../../../containers/SyteAdminContent/SyteAdminContent';
import { AddToCartVariantsForm } from '../../../AddToCartSettings/AddToCartVariantsForm';

export const RecEnginesGeneralSettings = ({
  shopId,
  generalSettings,
  selectedVariantId,
  formHeaderElementRef,
  onIsDirty,
  dispatch,
  disabled = false,
  shouldRefetch,
}: RecEnginesGeneralSettingsProps): JSX.Element | null => {
  const recEnginesSettings = useRecEnginesGeneralSettingsDraft({
    generalSettings,
    dispatch,
    shopId,
    selectedVariantId,
    onIsDirty,
    shouldRefetch,
  });

  const {
    draftGeneralSettings,
    isValid,
    isDirty,
    onSubmit,
    onDiscard,
    onChange,
    onChangeAddToCart,
    onChangeOverrideImage,
    onChangeRemoveDuplicates,
  } = recEnginesSettings;

  const formButtons = !disabled && (
    <>
      <Button onClick={onDiscard} variant='tertiary' disabled={!isDirty}>
        Discard
      </Button>
      <Button onClick={onSubmit} disabled={!(isValid && isDirty)} variant='primary'>
        {selectedVariantId ? 'Apply' : 'Save'}
      </Button>
    </>
  );

  const formButtonsSection = createOptionalPortal({
    portalContent: disabled ? <></> : formButtons,
    targetContainerRef: formHeaderElementRef,
    fallback: <div className='ranking-button-wrapper'>{formButtons}</div>,
  });

  return (
    <div className='rec-engines-general-settings'>
      <div className='rec-engines-configuration-settings'>
        <OpenNewTabSettings
          generalSettings={draftGeneralSettings}
          onChange={onChange}
          disabled={disabled}
        />
        <SyteAdminContent>
          <OverrideSettings
            generalSettings={draftGeneralSettings}
            onChange={onChangeOverrideImage}
            disabled={disabled}
          />
        </SyteAdminContent>
      </div>
      <MultipleRecPerPageSettings
        generalSettings={draftGeneralSettings}
        onChange={onChangeRemoveDuplicates}
        disabled={disabled}
      />
      <ConfigurationNote variant={ConfigurationVariant.allAboveToNonApiUsers} />
      <AddToCartVariantsForm
        generalSettings={draftGeneralSettings}
        onChange={onChangeAddToCart}
        shopId={shopId}
      />
      {formButtonsSection}
    </div>
  );
};
