import { RankingStrategyTypes } from 'src/services';
import { getStringEnumValues } from 'src/utils';
import { RankingSyteCoreConfigMap, SyteCoreFieldConfig } from './types';

export const syteCoreValuesSet = new Set(
  getStringEnumValues(RankingStrategyTypes.SyteCoreRankingFields)
);

const defaultFieldsConfig: Record<
  keyof RankingStrategyTypes.RankingStrategyWeight,
  SyteCoreFieldConfig
> = {
  enabled: {
    editable: true,
  },
  weight: {
    editable: true,
  },
  name: {
    editable: false,
  },
  field: { editable: false },
  order: { editable: false },
};

export const rankingSyteCoreConfigMap: RankingSyteCoreConfigMap = {
  [RankingStrategyTypes.SyteCoreRankingFields.BestMatch]: {
    ...defaultFieldsConfig,
    enabled: { editable: false },
  },
  [RankingStrategyTypes.SyteCoreRankingFields.BestSellers]: defaultFieldsConfig,
  [RankingStrategyTypes.SyteCoreRankingFields.Personalization]: defaultFieldsConfig,
  [RankingStrategyTypes.SyteCoreRankingFields.TrendingItems]: defaultFieldsConfig,
};
