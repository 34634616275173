import React from 'react';
import { EllipsisWithTooltip, MultiSelectMenu, MultiSelectOption } from 'src/components-dummy';
import {
  CheckboxStyled,
  DataFieldValueStyled,
  MultiSelectMenuStyled,
} from './DataFieldValuesList.styles';

interface DataFieldMultiSelectMenuProps {
  allowFreeText: boolean;
  hasSearchValue: boolean;
  options: { value: string; text: string }[];
  selectedOptions: string[];
  onChange: (values: string[]) => void;
  setRef: (el: Element | null) => void;
}

export function DataFieldMultiSelectMenu({
  allowFreeText,
  hasSearchValue,
  options,
  selectedOptions,
  onChange,
  setRef,
}: DataFieldMultiSelectMenuProps): JSX.Element {
  return (
    <MultiSelectMenuStyled options={options} selectedOptions={selectedOptions} onChange={onChange}>
      <MultiSelectMenu.OptionTemplate>
        {(option: MultiSelectOption, isSelected: boolean, index: number) => (
          <DataFieldValueStyled
            key={option.value}
            highlight={allowFreeText && hasSearchValue && index === 0}
            ref={setRef}
          >
            <CheckboxStyled checked={isSelected} />
            <EllipsisWithTooltip tooltipText={option.value}>{option.text}</EllipsisWithTooltip>
          </DataFieldValueStyled>
        )}
      </MultiSelectMenu.OptionTemplate>
    </MultiSelectMenuStyled>
  );
}
