import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { CatalogManagerService, CatalogUploadErrorsPaginationWithFilters } from 'src/services';

const catalogManagerService = new CatalogManagerService();

export const CatalogUploadErrorReportListContainerActions = {
  navigateTo: createAction<{ navigateTo: string }>('CatalogFormContainer/NavigateTo'),
  resetData: createAction<void>('CatalogUploadFailureReportListContainerActions/resetData'),
  getCatalogFeedUploadFileContent: createAsyncThunk(
    'CatalogFeedUploadLogContainerActions/getCatalogFeedUploadFileContent',
    async (
      { shopId, catalogName }: { shopId: number; catalogName: string },
      { rejectWithValue }
    ) => {
      try {
        const catalogFeedUploadLog = await catalogManagerService.getCatalogFeedUploadLog({
          shopId,
          catalogName,
        });

        return { catalogFeedUploadLog };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getCatalogUploadErrorReport: createAsyncThunk(
    'CatalogUploadFailureReportListContainerActions/getCatalogUploadFailureReport',
    async (
      {
        shopId,
        catalogName,
        jobId,
        ...paginationWithFilters
      }: {
        shopId: number;
        catalogName: string;
        jobId: string;
      } & CatalogUploadErrorsPaginationWithFilters,
      { rejectWithValue }
    ) => {
      try {
        const catalogUploadErrorReports = await catalogManagerService.getCatalogUploadErrorReports({
          shopId,
          catalogName,
          jobId,
          ...paginationWithFilters,
        });

        return { catalogUploadErrorReports };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
