import { useCallback } from 'react';
import { MerchandisingRuleTypes } from '../../services';
import { syteProductToRuleSettingsMap } from '../MerchandisingRules/components/constants';
import { compileJoiSchema, validateSchema } from '../../hooks';
import { formValidationSchema } from '../MerchandisingRules/components/MerchandisingRulesListPage/validation-schema';
import { UseDataFieldsLookupTableArgumentsReturnType } from '../MerchandisingRules/components/useDataFieldsLookupTable';

export type ValidateFunction = (rule: MerchandisingRuleTypes.MerchandiseRule) => boolean;

export const useValidateMerchRuleDataFields = (
  dataFieldsLookupTable: UseDataFieldsLookupTableArgumentsReturnType['dataFieldsLookupTable']
): ValidateFunction => {
  return useCallback(
    (rule: MerchandisingRuleTypes.MerchandiseRule): boolean => {
      const ruleSettings = syteProductToRuleSettingsMap[rule.product];

      const compiledJoiSchema = compileJoiSchema({ schema: formValidationSchema });

      const ruleErrors = validateSchema({
        dataToValidate: rule,
        schema: compiledJoiSchema,
        context: { dataFieldsLookupTable, ruleSettings },
      });

      const errorKeys = Object.keys(ruleErrors);

      return errorKeys.some(errorKey => {
        // check data field still exists in all relevant fields: [ subRules, sourceCondition ]
        const regex = /(subRules|sourceCondition)\.\d+\.(field|subType)/;

        if (regex.test(errorKey)) {
          return ruleErrors[errorKey].type === 'any.custom';
        }
        return false;
      });
    },
    [dataFieldsLookupTable]
  );
};
