import { createAction } from '@reduxjs/toolkit';
import { ShopTypes } from 'src/services';

/**
 * Actions
 */
export const useFeatureTogglesWSActions = {
  featureTogglesUpdate: createAction<{ featureToggles: ShopTypes.ShopFeatureToggles }>(
    'UseFeatureTogglesWS/TogglesUpdate'
  ),
};
