import { AxiosResponse } from 'axios';
import { PersonalizationSettingsTypes } from '../types';

export const personalizationSettingsResponse = ({
  data,
}: AxiosResponse): PersonalizationSettingsTypes.PersonalizationSettings => {
  return {
    numberOfResultsToDisplay: data.numberOfResultsToDisplay,
    personalizationFallbackMethod: data.personalizationFallbackMethod,
    useSessionSkusFromPageViewEvent: data.useSessionSkusFromPageViewEvent,
    addToCart: data.addToCart,
    personalizationFallbackField: data.personalizationFallbackField,
    sortingOrder: data.sortingOrder,
  };
};
