import { PersonalizationGeneralSettings } from 'src/services/src/service/personalization/personalization';
import { Dispatch } from 'src/components-bl/types';
import { dataFieldsService } from 'src/services';
import {
  GetPersonalizationDataFieldsArgs,
  GetPersonalizationDataFieldsErrorAction,
  GetPersonalizationDataFieldsErrorPayload,
  GetPersonalizationDataFieldsRequestAction,
  GetPersonalizationDataFieldsRequestPayload,
  GetPersonalizationDataFieldsSuccessAction,
  GetPersonalizationDataFieldsSuccessPayload,
  GetPersonalizationSettingsArgs,
  GetPersonalizationSettingsErrorAction,
  GetPersonalizationSettingsErrorPayload,
  GetPersonalizationSettingsRequestAction,
  GetPersonalizationSettingsRequestPayload,
  GetPersonalizationSettingsSuccessAction,
  GetPersonalizationSettingsSuccessPayload,
  NotifyPersonalizationSettingsIsDirtyAction,
  NotifyPersonalizationSettingsIsDirtyActionPayload,
  PersonalizationActionTypes,
  UpdatePersonalizationSettingsErrorAction,
  UpdatePersonalizationSettingsErrorPayload,
  UpdatePersonalizationSettingsPayload,
  UpdatePersonalizationSettingsRequestAction,
  UpdatePersonalizationSettingsRequestPayload,
  UpdatePersonalizationSettingsSuccessAction,
  UpdatePersonalizationSettingsSuccessPayload,
} from './types';
import { SortingOrder } from '../../../types/enums/sorting-order';

const personalizationService = new PersonalizationGeneralSettings();

/* --= Actions =-- */
const personalizationSettingsActions = {
  getPersonalizationSettingsRequest: (
    payload: GetPersonalizationSettingsRequestPayload
  ): GetPersonalizationSettingsRequestAction => {
    return {
      type: PersonalizationActionTypes.GetPersonalizationSettingsRequest,
      payload,
    };
  },
  getPersonalizationSettingsSuccess: (
    payload: GetPersonalizationSettingsSuccessPayload
  ): GetPersonalizationSettingsSuccessAction => {
    return {
      type: PersonalizationActionTypes.GetPersonalizationSettingsSuccess,
      payload,
    };
  },
  getPersonalizationSettingsError: ({
    error,
  }: GetPersonalizationSettingsErrorPayload): GetPersonalizationSettingsErrorAction => {
    return {
      type: PersonalizationActionTypes.GetPersonalizationSettingsError,
      payload: { error },
    };
  },
  updatePersonalizationSettingsRequest: (
    payload: UpdatePersonalizationSettingsRequestPayload
  ): UpdatePersonalizationSettingsRequestAction => {
    return {
      type: PersonalizationActionTypes.UpdatePersonalizationSettingsRequest,
      payload,
    };
  },
  updatePersonalizationSettingsSuccess: (
    payload: UpdatePersonalizationSettingsSuccessPayload
  ): UpdatePersonalizationSettingsSuccessAction => {
    return {
      type: PersonalizationActionTypes.UpdatePersonalizationSettingsSuccess,
      payload,
    };
  },
  updatePersonalizationSettingsError: (
    payload: UpdatePersonalizationSettingsErrorPayload
  ): UpdatePersonalizationSettingsErrorAction => {
    return {
      type: PersonalizationActionTypes.UpdatePersonalizationSettingsError,
      payload,
    };
  },

  notifyIsDirtyState: (
    payload: NotifyPersonalizationSettingsIsDirtyActionPayload
  ): NotifyPersonalizationSettingsIsDirtyAction => {
    return {
      type: PersonalizationActionTypes.NotifyPersonalizationSettingsIsDirty,
      payload,
    };
  },

  getDataFieldsRequest: (
    payload: GetPersonalizationDataFieldsRequestPayload
  ): GetPersonalizationDataFieldsRequestAction => {
    return {
      type: PersonalizationActionTypes.GetDataFieldsRequest,
      payload,
    };
  },
  getDataFieldsSuccess: (
    payload: GetPersonalizationDataFieldsSuccessPayload
  ): GetPersonalizationDataFieldsSuccessAction => {
    return {
      type: PersonalizationActionTypes.GetDataFieldsSuccess,
      payload,
    };
  },
  getDataFieldsError: ({
    error,
  }: GetPersonalizationDataFieldsErrorPayload): GetPersonalizationDataFieldsErrorAction => {
    return {
      type: PersonalizationActionTypes.GetDataFieldsError,
      payload: { error },
    };
  },
};

export const personalizationSettingsActionsMethods = {
  notifyIsDirty:
    ({ isDirty }: NotifyPersonalizationSettingsIsDirtyActionPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(personalizationSettingsActions.notifyIsDirtyState({ isDirty }));
    },

  fetchPersonalizationSettings:
    ({ shopId, variantId }: GetPersonalizationSettingsArgs) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(personalizationSettingsActions.getPersonalizationSettingsRequest({ shopId }));
        const {
          numberOfResultsToDisplay,
          personalizationFallbackMethod,
          useSessionSkusFromPageViewEvent,
          addToCart,
          personalizationFallbackField,
          sortingOrder,
        } = await personalizationService.get({
          shopId,
          variantId,
        });
        dispatch(
          personalizationSettingsActions.getPersonalizationSettingsSuccess({
            shopId,
            numberOfResultsToDisplay,
            personalizationFallbackMethod,
            useSessionSkusFromPageViewEvent,
            addToCart,
            personalizationFallbackField,
            sortingOrder: sortingOrder || SortingOrder.Desc,
          })
        );
      } catch (error) {
        dispatch(personalizationSettingsActions.getPersonalizationSettingsError({ error }));
      }
    },

  updatePersonalizationSettings:
    ({
      shopId,
      variantId,
      numberOfResultsToDisplay,
      personalizationFallbackMethod,
      useSessionSkusFromPageViewEvent,
      addToCart,
      personalizationFallbackField,
      sortingOrder,
    }: UpdatePersonalizationSettingsPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(
          personalizationSettingsActions.updatePersonalizationSettingsRequest({
            shopId,
            numberOfResultsToDisplay,
            personalizationFallbackMethod,
            useSessionSkusFromPageViewEvent,
            addToCart,
            personalizationFallbackField,
            sortingOrder,
          })
        );

        await personalizationService.update({
          shopId,
          variantId,
          numberOfResultsToDisplay,
          personalizationFallbackMethod,
          useSessionSkusFromPageViewEvent,
          addToCart,
          personalizationFallbackField,
          sortingOrder,
        });

        dispatch(
          personalizationSettingsActions.updatePersonalizationSettingsSuccess({
            shopId,
            numberOfResultsToDisplay,
            personalizationFallbackMethod,
            useSessionSkusFromPageViewEvent,
            addToCart,
            personalizationFallbackField,
            sortingOrder,
          })
        );
      } catch (error) {
        dispatch(personalizationSettingsActions.updatePersonalizationSettingsError({ error }));
      }
    },

  getDataFields:
    ({ shopId, fieldType }: GetPersonalizationDataFieldsArgs) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(personalizationSettingsActions.getDataFieldsRequest({ shopId }));
        const dataFields = await dataFieldsService.getDataFields({ shopId, fieldType });
        dispatch(personalizationSettingsActions.getDataFieldsSuccess({ shopId, dataFields }));
      } catch (error) {
        dispatch(personalizationSettingsActions.getDataFieldsError({ error }));
      }
    },
};
