import { DiscoveryBannerSettingsTypes } from 'src/services';
import { EntityDiffSchema } from '../../types';

type DiscoveryBannerDiffSchema = DiscoveryBannerSettingsTypes.DiscoveryBannerSettings;

export const discoveryBannerDiffSchema: EntityDiffSchema<
  DiscoveryBannerDiffSchema,
  DiscoveryBannerSettingsTypes.DiscoveryBannerSettings
> = {
  general: {
    displayName: 'Banner Settings',
    relevantFieldPaths: [
      'general.bannerLayout',
      'general.imageLayout',
      'general.bannerBackgroundColor',
    ],
  },
  banner: {
    displayName: 'Heading',
    relevantFieldPaths: ['banner.bannerColor', 'banner.bannerFontFamily', 'banner.bannerFontSize'],
  },
  button: {
    displayName: 'CTA Button',
    relevantFieldPaths: [
      'button.buttonBackgroundColor',
      'button.buttonColor',
      'button.buttonFontFamily',
      'button.buttonFontSize',
    ],
  },
  icon: {
    displayName: 'Camera Icon',
    relevantFieldPaths: ['icon.showIcon', 'icon.iconColor'],
  },
};
