import { useEffect, useReducer } from 'react';
import { ActionType, BaseModerationModalProps, StateType } from './types';
import { moderationModalReducer } from './reducer';

export const useModerationModalState = ({
  show,
  moderatedPosts,
  onSubmit,
}: BaseModerationModalProps) => {
  const [state, dispatch] = useReducer(moderationModalReducer, {
    type: StateType.hasNoSelectedPosts,
    selectedPosts: null,
  });

  const removePost = (id: number) => dispatch({ type: ActionType.removePost, payload: id });
  const addPost = (id: number) => dispatch({ type: ActionType.addPost, payload: id });
  const resetState = () => dispatch({ type: ActionType.resetState });

  const handleSelect = (id: number) => {
    if (state.selectedPosts?.includes(id)) removePost(id);
    else addPost(id);
  };
  const handleSubmit = () => {
    if (state.type !== StateType.hasSelectedPosts) return;
    const selectedPosts = state.selectedPosts.map(postId =>
      moderatedPosts.find(post => post.id === postId)
    );
    onSubmit(selectedPosts);
  };

  const hasSelectedPost = state.type === StateType.hasSelectedPosts;

  useEffect(() => {
    if (!show && hasSelectedPost) {
      resetState();
    }
  }, [show]);

  return { state, hasSelectedPost, handleSelect, handleSubmit };
};
