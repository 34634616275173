import React from 'react';
import styled from '@emotion/styled';
import { ItemText } from './ItemText';
import { RadioGroup, RadioGroupParameters } from '../RadioGroup';

interface Props extends RadioGroupParameters {
  label: string;
}

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export function FormRadioGroup({ label, ...radioProps }: Props) {
  return (
    <FlexContainer>
      <ItemText>{label}</ItemText>
      <RadioGroup {...radioProps} />
    </FlexContainer>
  );
}
