import React from 'react';
import { Page } from 'src/components-dummy';
import { RoutedComponentProps } from 'src/app-routes';
import { ShopGeneralSettings } from '../../ShopGeneralSettings';
import { ShopApiKeysSettings } from '../../ShopApiKeysSettings';

export function GeneralSettingsPage({ permittedRouteMap }: RoutedComponentProps): JSX.Element {
  return (
    <>
      <Page.Header>
        <Page.Title>General</Page.Title>
        <Page.SubTitle>Manage your syte shop settings and API Keys</Page.SubTitle>
      </Page.Header>
      <Page.Content>
        <ShopGeneralSettings />
        <ShopApiKeysSettings permittedRouteMap={permittedRouteMap} />
      </Page.Content>
    </>
  );
}
