import { RelevancyTuningTableHeadRow } from './types';

export const tableColumns: Record<string, RelevancyTuningTableHeadRow> = {
  active: {
    header: 'Active',
    accessor: 'active',
    minWidth: 130,
    width: '20%',
  },
  displayName: {
    header: 'Catalog Field',
    accessor: 'displayName',
    width: '40%',
  },
  priority: {
    header: 'Priority',
    accessor: 'priority',
    width: '40%',
  },
};

export const columnsArr = Object.values(tableColumns);
