import React, { useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import { ParseJoiValidateResponse } from 'src/utils';
import {
  TextBox,
  Typography,
  TypographyType,
  TypographyVariant,
  Icon,
  InfoModal,
  Select,
  SelectType,
  SelectOnChangeEvent,
  MenuItem,
} from 'src/components-dummy';
import { AvailableIcons } from 'src/components-dummy/Icon/AvailableIcons';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { DiscoveryBannerGeneralSettings } from 'src/services/src/service/types/syte-products/discovery-banner';
import {
  ImageLayout,
  BannerLayout,
} from 'src/app-state-types/reducer-state-types/discovery-banner';
import { handleUpdateColor } from '../../colorToHex';
import { DiscoveryBannerSettingsRecord } from '../../types';
import alignToLeft from '../../Assets/images/alignToLeft.jpg';
import alignToRight from '../../Assets/images/alignToRight.jpg';
import grid from '../../Assets/images/grid.jpg';
import mixed from '../../Assets/images/mixed.jpg';
import overlay from '../../Assets/images/overlay.jpg';
import '../../DiscoveryBannerSettings.scss';

const bannerLayoutDropDownOptions = [
  { value: BannerLayout.LEFT_TO_RIGHT, text: 'Left To Right' },
  { value: BannerLayout.RIGHT_TO_LEFT, text: 'Right To Left' },
];
const imageLayoutDropDownOptions = [
  { value: ImageLayout.MIXED_SIZES, text: 'Mixed Sizes' },
  { value: ImageLayout.GRID, text: 'Grid' },
  { value: ImageLayout.OVERLAP, text: 'Overlapping' },
];

export interface BannerSettingsProps {
  discoveryBannerSettings: DiscoveryBannerGeneralSettings['generalSettings'];
  onChange: (args: Partial<DiscoveryBannerGeneralSettings['generalSettings']['general']>) => void;
  disabled?: boolean;
  errors: ParseJoiValidateResponse<DiscoveryBannerSettingsRecord>;
}

export const BannerSettings = ({
  discoveryBannerSettings,
  onChange,
  disabled = false,
  errors,
}: BannerSettingsProps): JSX.Element | null => {
  const [showBannerBackgroundColorPicker, setShowBannerBackgroundColorPicker] = useState(false);
  const [showGeneralSettings, setGeneralSettingsModalIsOpened] = useState(false);
  const handleBannerChange: SelectOnChangeEvent = event =>
    onChange({ bannerLayout: event.target.value as BannerLayout });
  const handleImageChange: SelectOnChangeEvent = event =>
    onChange({ imageLayout: event.target.value as ImageLayout });
  const onGeneralSettingsModalClose = () => {
    setGeneralSettingsModalIsOpened(false);
  };
  const bannerBackgroundColorRef = useRef(null);
  useOnClickOutside(bannerBackgroundColorRef, () => {
    setShowBannerBackgroundColorPicker(false);
  });
  return (
    <div className='discovery-banner-settings-container-wrapper'>
      <div className='discovery-banner-settings-container-title'>
        <Typography variant={TypographyVariant.MediumBold} type={TypographyType.Body}>
          Banner settings
        </Typography>

        <button
          type='button'
          className='discovery-banner-general-settings-modal'
          onClick={() => setGeneralSettingsModalIsOpened(true)}
        >
          <Typography
            className='discovery-banner-general-settings-tooltip'
            variant={TypographyVariant.SmallRegular}
          >
            What is it general settings
          </Typography>
          <Icon
            className='discovery-banner-general-settings-tooltip-icon'
            name={AvailableIcons.TooltipQuestionmarkBlue}
          />
        </button>

        <InfoModal
          isOpened={showGeneralSettings}
          className='discovery-banner-general-settings-info-modal'
          title='General settings'
          description='The Dialog component is used to show content on top of an overlay that requires user interaction.'
          closeBtnTxt='Close'
          onClickOutside={onGeneralSettingsModalClose}
        >
          <div className='general-settings-example'>
            <div className='alignment-settings'>
              <div className='alignment-to-left'>
                <Typography
                  className='alignment-to-left-title'
                  variant={TypographyVariant.SmallBold}
                  type={TypographyType.Body}
                >
                  Align to left
                </Typography>
                <img className='align-left-settings-image' src={alignToLeft} alt='' />
              </div>
              <div className='alignment-to-right'>
                <Typography
                  className='alignment-to-right-title'
                  variant={TypographyVariant.SmallBold}
                  type={TypographyType.Body}
                >
                  Align to right
                </Typography>
                <img className='align-right-settings-image' src={alignToRight} alt='' />
              </div>
            </div>

            <div className='layout-settings'>
              <Typography
                className='layout-settings-title'
                variant={TypographyVariant.MediumBold}
                type={TypographyType.Body}
              >
                Image layout grid
              </Typography>
              <div className='layout-grid'>
                <Typography
                  className='grid-title'
                  variant={TypographyVariant.SmallBold}
                  type={TypographyType.Body}
                >
                  Grid
                </Typography>
                <img className='grid-settings-image' src={grid} alt='' />
              </div>
              <div className='layout-mixed'>
                <Typography
                  className='mixed-title'
                  variant={TypographyVariant.SmallBold}
                  type={TypographyType.Body}
                >
                  Mixed
                </Typography>
                <img className='mixed-settings-image' src={mixed} alt='' />
              </div>
              <div className='layout-overlay'>
                <Typography
                  className='overlay-title'
                  variant={TypographyVariant.SmallBold}
                  type={TypographyType.Body}
                >
                  Overlay
                </Typography>
                <img className='overlay-settings-image' src={overlay} alt='' />
              </div>
            </div>
          </div>
        </InfoModal>
      </div>
      <div className='discovery-banner-settings-container general-settings'>
        <div className='banner-layout'>
          <span className='banner-layout-title'>
            <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
              Banner Alignment
            </Typography>
          </span>
          <Select
            type={SelectType.Menu}
            className='banner-layout-dropdown'
            onChange={handleBannerChange}
            disabled={disabled}
            value={
              bannerLayoutDropDownOptions.filter(
                layout => layout.text === discoveryBannerSettings.general.bannerLayout
              )[0].value
            }
          >
            {bannerLayoutDropDownOptions.map((option: { value: string; text: string }) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className='image-layout'>
          <span className='image-layout-title'>
            <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
              Images Display Layout
            </Typography>
          </span>
          <Select
            type={SelectType.Menu}
            value={
              imageLayoutDropDownOptions.filter(
                layout => layout.text === discoveryBannerSettings.general.imageLayout
              )[0].value
            }
            className='image-layout-dropdown'
            onChange={handleImageChange}
            disabled={disabled}
          >
            {imageLayoutDropDownOptions.map((option: { value: string; text: string }) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </div>
        <div className='banner-background-color' ref={bannerBackgroundColorRef}>
          <TextBox
            label='Banner Background Color'
            placeholder={discoveryBannerSettings.general.bannerBackgroundColor}
            error={!!errors.name}
            value={discoveryBannerSettings.general.bannerBackgroundColor}
            fullWidth
            onChange={backgroundColor => {
              onChange({ bannerBackgroundColor: backgroundColor });
            }}
            disabled={disabled}
          >
            <button
              type='button'
              aria-label='background-color-mock'
              className='background-color-mock'
              onClick={() => setShowBannerBackgroundColorPicker(!showBannerBackgroundColorPicker)}
              style={{
                backgroundColor: discoveryBannerSettings.general.bannerBackgroundColor,
              }}
              disabled={disabled}
            />
            {showBannerBackgroundColorPicker && (
              <ChromePicker
                className='background-color-picker'
                color={discoveryBannerSettings.general.bannerBackgroundColor}
                onChange={updatedColor => {
                  handleUpdateColor(updatedColor, newColor => {
                    onChange({ bannerBackgroundColor: newColor });
                  });
                }}
              />
            )}
          </TextBox>
        </div>
      </div>
    </div>
  );
};
