import React from 'react';
import {
  AvailableIcons,
  Icon,
  TabsV2,
  TabV2,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import {
  CloseButtonStyled,
  ContentStyled,
  HeaderStyled,
  ImageNotFoundWrapperStyled,
  ImagesContainerStyled,
  ImagesTitleStyled,
  NotificationContentStyled,
  NotificationStyled,
  ProductDataContainerStyled,
  ScrollableSection,
  TabsContainerStyled,
  TitleStyled,
  TitleWrapper,
} from './ProductDetails.styled';

interface ProductDetailsErrorProps {
  onClose: VoidFunction;
}

export const ProductDetailsError = ({ onClose }: ProductDetailsErrorProps) => {
  return (
    <>
      <HeaderStyled>
        <TitleWrapper>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
            Product Details
          </Typography>
        </TitleWrapper>
        <CloseButtonStyled name={AvailableIcons.Close} onClick={onClose} />
      </HeaderStyled>
      <ContentStyled>
        <ImagesContainerStyled>
          <ImagesTitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
            0 images
          </ImagesTitleStyled>
          <ImagesTitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
            Main image
          </ImagesTitleStyled>
          <ImageNotFoundWrapperStyled>
            <Icon name={AvailableIcons.Image} />
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              Image not found
            </Typography>
          </ImageNotFoundWrapperStyled>
        </ImagesContainerStyled>
        <ProductDataContainerStyled>
          <TabsContainerStyled>
            <TabsV2 value={0}>
              <TabV2
                label={
                  <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                    Summary
                  </Typography>
                }
              />
            </TabsV2>
          </TabsContainerStyled>
          <ScrollableSection>
            <NotificationStyled>
              <Icon name={AvailableIcons.CircleWarning} />
              <NotificationContentStyled>
                <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                  Unable to load product details
                </Typography>
                <TitleStyled type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                  Please try again or contact support if the issue persists
                </TitleStyled>
              </NotificationContentStyled>
            </NotificationStyled>
          </ScrollableSection>
        </ProductDataContainerStyled>
      </ContentStyled>
    </>
  );
};
