import { differenceInDays, differenceInYears, format, formatDistanceToNowStrict } from 'date-fns';

export const formatDateToNow = (date: Date): string => {
  const now = new Date();

  const yearsDifference = differenceInYears(now, date);
  if (yearsDifference > 1) {
    return format(date, 'MMMM d yyyy');
  }

  const daysDifference = differenceInDays(now, date);
  if (daysDifference > 7) {
    return format(date, 'MMMM d');
  }

  return `${formatDistanceToNowStrict(date)} ago`;
};
