import { createReducer } from '@reduxjs/toolkit';
import { IUserLocationReducerState } from 'src/app-state-types';
import { rootContainerActions } from 'src/root-container/Actions';

const initialState: IUserLocationReducerState = {
  users: [],
};

export const userLocationReducer = createReducer(initialState, builder => {
  builder.addCase(rootContainerActions.setUserLocation, (state, action) => {
    return {
      ...state,
      users: action.payload.users,
    };
  });
});
