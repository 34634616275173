import React, { MouseEvent } from 'react';

import {
  AvailableIcons,
  Icon,
  EllipsisMenuButton,
  Typography,
  TypographyVariant,
} from '../../../components-dummy';

import { MenuItemStyled, MenuStyled } from './CollectionMenu.styles';

interface CollectionMenuProps {
  menuAnchorElement: HTMLElement | null;
  onMenuClicked: (event: MouseEvent<HTMLElement>) => void;
  onMenuClose: () => void;
  onDeleteCollectionClicked: () => void;
  onDuplicateCollectionClicked: () => void;
  onEditCollectionClicked: () => void;
}

export const CollectionMenu: React.FC<CollectionMenuProps> = ({
  menuAnchorElement,
  onMenuClicked,
  onMenuClose,
  onDeleteCollectionClicked,
  onDuplicateCollectionClicked,
  onEditCollectionClicked,
}) => {
  const isOpen = Boolean(menuAnchorElement);
  return (
    <>
      <EllipsisMenuButton onClick={onMenuClicked} isActive={isOpen} />
      <MenuStyled
        open={isOpen}
        anchorEl={menuAnchorElement}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: 'right',
        }}
      >
        <MenuItemStyled onClick={onEditCollectionClicked}>
          <Icon name={AvailableIcons.Edit} />
          <Typography variant={TypographyVariant.MediumRegular}>Edit</Typography>
        </MenuItemStyled>
        <MenuItemStyled onClick={onDuplicateCollectionClicked}>
          <Icon name={AvailableIcons.Duplicate} />
          <Typography variant={TypographyVariant.MediumRegular}>Duplicate</Typography>
        </MenuItemStyled>
        <MenuItemStyled className='delete' onClick={onDeleteCollectionClicked}>
          <Icon name={AvailableIcons.TrashCan} />
          <Typography variant={TypographyVariant.MediumRegular}>Delete</Typography>
        </MenuItemStyled>
      </MenuStyled>
    </>
  );
};
