import Joi from 'joi';
import { ApiKeyValidationRuleType } from './types';

interface ValidationContext {
  existingLabels: string[];
}

const getContextValues = ({ context }: { context: Joi.Context | undefined }): ValidationContext => {
  return { existingLabels: context?.existingLabels };
};

export const apiKeyValidationSchema: ApiKeyValidationRuleType = {
  label: Joi.string()
    .required()
    .trim()
    .min(3)
    .max(20)
    .custom((newLabel, { prefs: { context } }) => {
      const { existingLabels } = getContextValues({ context });

      if (existingLabels.includes(newLabel)) throw new Error();
    })
    .messages({
      'string.empty': 'Label can not be empty',
      'string.min': 'Label should be at least 3 characters long',
      'string.max': 'Label should not be more than 20 characters long',
    }),
  expiration: Joi.date().optional().greater(Date.now()).allow(null),
};
