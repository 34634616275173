import { SubDomainGroupBase } from 'src/components-bl';
import { AvailableIcons } from 'src/components-dummy';
import {
  DomainEntityPath,
  MerchandisingRuleTypes,
  syteProductToDomainEntityMap,
} from 'src/services';
import { startCase } from 'lodash';
import { DomainGroup } from '../../types';
import { merchandisingRulesDiffsSchema } from './merchandising-rules-diff-schema';
import { augmentedSearchDomainGroup } from '../augmented-search';
import { discoveryButtonDomainGroup } from '../discovery-button';
import { personalizationDomainGroup } from '../personalization';
import { shopTheLookDomainGroup } from '../shop-the-look';
import { similarItemsDomainGroup } from '../similar-items';
import { visualSearchDomainGroup } from '../visual-search';
import { buildDomainSettingsLookupTable } from '../helpers';

type EntityType = MerchandisingRuleTypes.MerchandiseRule;

const syteProductsSettingsLookUpMap = buildDomainSettingsLookupTable({
  settings: [
    augmentedSearchDomainGroup,
    discoveryButtonDomainGroup,
    personalizationDomainGroup,
    shopTheLookDomainGroup,
    similarItemsDomainGroup,
    visualSearchDomainGroup,
  ],
});

export const merchandisingRulesDomainGroup: DomainGroup & SubDomainGroupBase<EntityType> = {
  domainKey: DomainEntityPath.MerchandisingRules,
  title: 'Merchandising Rules',
  icon: AvailableIcons.DottedCircle,
  getCardTitle: ({ newEntity, oldEntity }): string => {
    const rule = newEntity ?? oldEntity;
    let titleFirstPart = '(unknown)';
    const ruleProduct = rule?.product;
    if (ruleProduct) {
      const productDomainEntity = syteProductToDomainEntityMap[ruleProduct];
      const syteProductDomainSettings = syteProductsSettingsLookUpMap[productDomainEntity];
      titleFirstPart = syteProductDomainSettings?.title || startCase(productDomainEntity);
    }

    return `${titleFirstPart} > “${rule?.name}”`;
  },
  fieldsSchema: merchandisingRulesDiffsSchema,
};
