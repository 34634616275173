import styled from '@emotion/styled';
import { Icon } from '../Icon';
import { Typography } from '../Typography';

export const CheckboxStyled = styled.div<{ checked: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;
  border: 1.5px solid
    ${({ theme, checked }) => (checked ? 'none' : theme.palette.custom['gray-30'])};
  border-radius: 4px;
  background-color: ${({ checked, theme, disabled }) => {
    if (disabled && checked) {
      return theme.palette.custom['gray-30'];
    }
    if (disabled && !checked) {
      return theme.palette.custom['gray-70'];
    }
    if (checked) {
      return theme.palette.custom['primary-main'];
    }
    return theme.palette.common.white;
  }};
  transition: 0.2s background-color;
`;

export const CheckboxContainerStyled = styled.div<{ checked: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;

  &:hover {
    ${CheckboxStyled} {
      border: 1.5px solid
        ${({ theme, checked }) => (checked ? 'none' : theme.palette.custom['gray-20'])};
    }
  }
`;

export const IconStyled = styled(Icon)<{ checked: boolean }>`
  opacity: ${({ checked }) => (checked ? '1' : '0')};
  transition: 0.2s opacity;
`;

export const TypographyStyled = styled(Typography)<{ disabled: boolean }>`
  margin-left: 10px;

  &:hover {
    cursor: ${({ disabled }) => {
      if (disabled) {
        return 'initial';
      }
      return 'pointer';
    }};
  }

  ${({ theme, disabled }) => {
    if (disabled) {
      return `color: ${theme.palette.custom['gray-20']};`;
    }
    return '';
  }}
`;

export const InvisibleCheckboxInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  z-index: 1;

  &:hover {
    cursor: ${({ disabled }) => {
      if (disabled) {
        return 'initial';
      }
      return 'pointer';
    }};
  }
`;
