import { AvailableIcons } from 'src/components-dummy';
import { DomainEntityPath } from 'src/services';
import { ParentDomainBase } from '../../types';
import { discoveryBannerSubDomainGroup } from './discovery-banner-settings-sub-domain';

const baseTitle = 'Discovery Banner';

export const discoveryBannerDomainGroup: ParentDomainBase = {
  domainKey: DomainEntityPath.DiscoveryBanner,
  title: baseTitle,
  icon: AvailableIcons.HollowStar,
  subDomains: [
    { ...discoveryBannerSubDomainGroup, domainKey: DomainEntityPath.DiscoveryBannerSettings },
  ],
};
