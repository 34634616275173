import React, { MouseEventHandler, useCallback, useRef, useState } from 'react';
import { Dispatch } from 'src/components-bl/types';
import { BlurredImage, ImageSize } from 'src/components-dummy';
import { CustomInspirationsGalleryImage } from 'src/services';
import { AddTagsButton } from './AddTagsButton';
import { DeleteImageButton } from './DeleteImageButton';
import { DeleteImageConfirmationDialog } from './DeleteImageConfirmationDialog';
import { GalleryImageContainerStyled } from './GalleryImage.styles';
import { ProductTags } from '../../../../../components';
import { SkuConfiguration } from 'src/components-bl/ProductCard/ProductCard';

interface GalleryImageProps {
  image: CustomInspirationsGalleryImage;
  draggable?: boolean;
  onAddTags: (imageId: string) => void;
  onRemoveImage: (imageId: string) => void;
  dispatch: Dispatch;
  shopId: number;
  onImageLoaded?: () => void;
  loading: boolean;
  productInformationLookup: Record<string, SkuConfiguration>;
  removeTagById: (id: string) => void;
}

export function GalleryImage({
  image,
  draggable,
  onAddTags,
  onRemoveImage,
  removeTagById,
  dispatch,
  shopId,
  onImageLoaded,
  loading,
  productInformationLookup,
}: GalleryImageProps): JSX.Element {
  const imageRef = useRef(null);
  const [currentImageSize, setCurrentImageSize] = useState<ImageSize | null>(null);

  const [failedToLoadImage, setFailedToLoadImage] = useState(false);
  const [showConfirmImageDeleteDialog, setShowConfirmImageDeleteDialog] = useState(false);

  const onImageErrorStatusChange = useCallback(
    ({ hasError }: { hasError: boolean }) => {
      setFailedToLoadImage(hasError);
    },
    [setFailedToLoadImage]
  );

  const onRemoveImageClick: MouseEventHandler<HTMLDivElement> = useCallback(
    ev => {
      ev.preventDefault();
      ev.stopPropagation();
      setShowConfirmImageDeleteDialog(true);
    },
    [setShowConfirmImageDeleteDialog]
  );

  const onAddTagsClick: MouseEventHandler<HTMLDivElement> = useCallback(
    ev => {
      ev.preventDefault();
      ev.stopPropagation();
      onAddTags(image.id);
    },
    [image.id, onAddTags]
  );

  const onRemoveConfirm = useCallback(() => {
    onRemoveImage(image.id);
    setShowConfirmImageDeleteDialog(false);
  }, [image.id, onRemoveImage]);

  const onRemoveCancel = useCallback(() => {
    setShowConfirmImageDeleteDialog(false);
  }, [setShowConfirmImageDeleteDialog]);

  return (
    <>
      <GalleryImageContainerStyled alwaysShowDeleteButton={failedToLoadImage}>
        <ProductTags
          image={image}
          imageRef={imageRef}
          imageSize={currentImageSize}
          dispatch={dispatch}
          shopId={shopId}
          canAddNewTags={false}
          showTags
          canDeleteTags
          removeTagById={removeTagById}
          loading={loading}
          productInformationLookup={productInformationLookup}
        />
        <BlurredImage
          imageSrc={image.imageUrl}
          draggable={draggable}
          onImageErrorStatusChange={onImageErrorStatusChange}
          ref={imageRef}
          onImageSizeChange={setCurrentImageSize}
          onImageLoaded={onImageLoaded}
        />
        <DeleteImageButton onClick={onRemoveImageClick} />
        {!failedToLoadImage && <AddTagsButton onClick={onAddTagsClick} />}
      </GalleryImageContainerStyled>
      {showConfirmImageDeleteDialog && (
        <DeleteImageConfirmationDialog onConfirm={onRemoveConfirm} onCancel={onRemoveCancel} />
      )}
    </>
  );
}
