import React, { useMemo } from 'react';
import { TableV2 } from 'src/components-dummy';
import { InspirationalImagesGallery } from 'src/services';
import { GalleryTableRow } from './GalleryTableRow';
import { TableWrapperStyled } from './InspirationalImagesGalleryTable.styles';
import { tableColumnsArray } from './tableColumns';
import { InspirationalImagesGalleryTableRow } from './types';

interface InspirationalImagesGalleryTableProps {
  gallery: InspirationalImagesGallery;
}

export function InspirationalImagesGalleryTable({
  gallery,
}: InspirationalImagesGalleryTableProps): JSX.Element {
  const tableData: InspirationalImagesGalleryTableRow[] = useMemo(() => {
    return gallery.images.map(image => ({
      imageUrl: image.imageUrl,
      skus: image.productIds,
    }));
  }, [gallery.images]);

  return (
    <TableWrapperStyled>
      <TableV2<InspirationalImagesGalleryTableRow>
        options={{ columns: tableColumnsArray, data: tableData }}
        customOptions={{
          shouldUseFlexLayout: true,
        }}
      >
        {({ getTableBodyProps, headerGroups, rows, prepareRow }) => {
          const headerGroup = headerGroups[0];

          return (
            <>
              <TableV2.Head {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableV2.HeadRow {...column.getHeaderProps()} isResizable key={column.id}>
                    <TableV2.HeadRowCellText>{column.render('Header')}</TableV2.HeadRowCellText>
                  </TableV2.HeadRow>
                ))}
              </TableV2.Head>
              <TableV2.Body
                {...getTableBodyProps()}
                css={{
                  overflow: 'initial',
                }}
              >
                {rows.map((row, index) => {
                  prepareRow(row);
                  return (
                    <GalleryTableRow
                      key={`${row.original.imageUrl}-${index.toString()}`}
                      row={row}
                    />
                  );
                })}
              </TableV2.Body>
            </>
          );
        }}
      </TableV2>
    </TableWrapperStyled>
  );
}
