import { Shops, ShopTypes, APITypes } from 'src/services';
import { Dispatch } from 'src/components-bl/types';

const shopsService = new Shops();

export enum ShopGeneralSettingsActionTypes {
  SubmitRequest = 'ShopGeneralSettings/Submit.Request',
  SubmitSuccess = 'ShopGeneralSettings/Submit.Success',
  SubmitError = 'ShopGeneralSettings/Submit.Error',
  GetRequest = 'ShopGeneralSettings/Get.Request',
  GetSuccess = 'ShopGeneralSettings/Get.Success',
  GetError = 'ShopGeneralSettings/Get.Error',
  NotifyIsDirty = 'ShopGeneralSettings/NotifyIsDirty',
}

export interface ShopGeneralSettingsNotifyIsDirtyActionPayload {
  isDirty: boolean;
}

/* --= Action Interfaces =-- */
interface SubmitRequestAction {
  type: typeof ShopGeneralSettingsActionTypes.SubmitRequest;
  payload: ActionPayload;
}
interface SubmitSuccessAction {
  type: typeof ShopGeneralSettingsActionTypes.SubmitSuccess;
  payload: ActionPayload;
}
interface SubmitErrorAction {
  type: typeof ShopGeneralSettingsActionTypes.SubmitError;
  payload: { error: APITypes.ApiError };
}
interface GetRequestAction {
  type: typeof ShopGeneralSettingsActionTypes.GetRequest;
  payload: GetGeneralSettingsActionPayload;
}
interface GetSuccessAction {
  type: typeof ShopGeneralSettingsActionTypes.GetSuccess;
  payload: ActionPayload;
}
interface GetErrorAction {
  type: typeof ShopGeneralSettingsActionTypes.GetError;
  payload: { error: APITypes.ApiError };
}
export interface ShopGeneralSettingsNotifyIsDirtyAction {
  type: typeof ShopGeneralSettingsActionTypes.NotifyIsDirty;
  payload: ShopGeneralSettingsNotifyIsDirtyActionPayload;
}

interface ActionPayload {
  shopId: number;
  generalSettings: ShopTypes.GeneralSettings;
}

interface GetGeneralSettingsActionPayload {
  shopId: number;
}

export type ShopGeneralSettingsAction =
  | SubmitRequestAction
  | SubmitSuccessAction
  | SubmitErrorAction
  | GetRequestAction
  | GetSuccessAction
  | GetErrorAction
  | ShopGeneralSettingsNotifyIsDirtyAction;

/* --= Actions =-- */
const shopGeneralSettingsActions = {
  submitRequest: (payload: ActionPayload): SubmitRequestAction => {
    return {
      type: ShopGeneralSettingsActionTypes.SubmitRequest,
      payload,
    };
  },
  submitSuccess: (payload: ActionPayload): SubmitSuccessAction => {
    return {
      type: ShopGeneralSettingsActionTypes.SubmitSuccess,
      payload,
    };
  },
  submitError: (error: APITypes.ApiError): SubmitErrorAction => {
    return {
      type: ShopGeneralSettingsActionTypes.SubmitError,
      payload: { error },
    };
  },
  getRequest: (payload: GetGeneralSettingsActionPayload): GetRequestAction => {
    return {
      type: ShopGeneralSettingsActionTypes.GetRequest,
      payload,
    };
  },
  getSuccess: (payload: ActionPayload): GetSuccessAction => {
    return {
      type: ShopGeneralSettingsActionTypes.GetSuccess,
      payload,
    };
  },
  getError: (error: APITypes.ApiError): GetErrorAction => {
    return {
      type: ShopGeneralSettingsActionTypes.GetError,
      payload: { error },
    };
  },
  notifyIsDirty: (
    payload: ShopGeneralSettingsNotifyIsDirtyActionPayload
  ): ShopGeneralSettingsNotifyIsDirtyAction => {
    return {
      type: ShopGeneralSettingsActionTypes.NotifyIsDirty,
      payload,
    };
  },
};

/* --= Methods =-- */
export const shopGeneralSettingsMethods = {
  get(payload: GetGeneralSettingsActionPayload): Dispatch {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(shopGeneralSettingsActions.getRequest(payload));
        const generalSettings = await shopsService.getGeneralSettings({ shopId: payload.shopId });
        dispatch(shopGeneralSettingsActions.getSuccess({ ...payload, generalSettings }));
      } catch (error) {
        dispatch(shopGeneralSettingsActions.getError(error as APITypes.ApiError));
      }
    };
  },
  submit(payload: ActionPayload): Dispatch {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(shopGeneralSettingsActions.submitRequest(payload));
        const updatedGeneralSettings = await shopsService.updateGeneralSettings(payload);
        dispatch(
          shopGeneralSettingsActions.submitSuccess({
            shopId: payload.shopId,
            generalSettings: updatedGeneralSettings,
          })
        );
      } catch (error) {
        dispatch(shopGeneralSettingsActions.submitError(error as APITypes.ApiError));
      }
    };
  },
  notifyIsDirty:
    ({ isDirty }: ShopGeneralSettingsNotifyIsDirtyActionPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(shopGeneralSettingsActions.notifyIsDirty({ isDirty }));
    },
};
