import React from 'react';
import { ImageList, ImageListProps } from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import classNames from 'classnames';
import { DraggableGridItem, GridItem } from './components';

export function ImageGrid({ className, children, ...props }: ImageListProps): JSX.Element {
  return (
    <DndProvider backend={HTML5Backend}>
      <ImageList className={classNames('syte-image-grid', className)} {...props}>
        {children}
      </ImageList>
    </DndProvider>
  );
}

ImageGrid.GridItem = GridItem;
ImageGrid.DraggableGridItem = DraggableGridItem;
