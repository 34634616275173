import { AvailableIcons } from 'src/components-dummy';
import { DomainEntityPath } from 'src/services';
import { ParentDomainBase } from '../../types';
import { rankingSubDomainGroup } from '../ranking-sub-domain-group';
import { personalizationSettingsSubDomainGroup } from './personalization-settings-sub-domain-group';

export const personalizationDomainGroup: ParentDomainBase = {
  domainKey: DomainEntityPath.Personalization,
  title: 'Personalization',
  icon: AvailableIcons.HollowStar,
  subDomains: [
    { ...rankingSubDomainGroup, domainKey: DomainEntityPath.PersonalizationRanking },
    {
      ...personalizationSettingsSubDomainGroup,
      domainKey: DomainEntityPath.PersonalizationSettings,
    },
  ],
};
