import { Popover, PopoverProps as MuiPopoverProps } from '@mui/material';
import React, { MouseEvent } from 'react';
import { ButtonProps } from '../Button';
import { AvailableIcons } from '../Icon';
import { PopoverStyled, TriggerButtonStyled } from './PopoverInput.styles';

export interface PopoverInputProps<T = any> extends Omit<MuiPopoverProps, 'defaultValue' | 'open'> {
  defaultValue: T;
  onChanged: (value: T) => void;
  isReadOnly?: boolean;

  children: (popperActions: {
    open(event: MouseEvent<HTMLElement>): void;
    close(): void;
    isOpen: boolean;
    setInputValue: (value: T) => void;
    inputValue: T;
  }) => JSX.Element;
}

export const PopoverInputTriggerButton = (props: ButtonProps): JSX.Element => {
  return (
    <TriggerButtonStyled variant='secondary' endIcon={AvailableIcons.SelectCaret} {...props} />
  );
};

export const PopoverInput = <T,>({
  defaultValue,
  isReadOnly,
  onChanged,
  children: renderChildren,
}: PopoverInputProps<T>): JSX.Element => {
  const valueRef = React.useRef(defaultValue);

  const [anchorEl, setAnchorEl] = React.useState(null as EventTarget | null);

  const open = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const close = () => {
    setAnchorEl(null);
    onChanged(valueRef.current);
  };

  const setInputValue = (value: T) => {
    valueRef.current = value;
  };

  const isOpen = !!anchorEl;

  const [trigger, content] = renderChildren({
    open,
    close,
    isOpen,
    setInputValue,
    inputValue: valueRef.current,
  }).props.children as JSX.Element[];

  return (
    <>
      {trigger}
      {!isReadOnly && (
        <Popover
          style={{ marginTop: 6 }}
          open={isOpen}
          onClose={close}
          anchorEl={anchorEl as any}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {content}
        </Popover>
      )}
    </>
  );
};

PopoverInput.Content = PopoverStyled;
PopoverInput.TriggerButton = PopoverInputTriggerButton;
