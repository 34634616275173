import React from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useAppSelector } from 'src/hooks';
import { generatePath } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { DeepTagReportFileUploadModal } from 'src/components-bl/DeepTagReports/components/DeepTagReportFileUploadModal';
import { deepTagReportFileUploadModalContainerActions } from './DeepTagReportFileUploadModalContainer.actions';

const EXAMPLE_FILE_PATH =
  'https://syte-static-images.s3.eu-west-1.amazonaws.com/tags-editor/file_example.csv';

type DeepTagReportFileUploadModalContainerProps = RoutedComponentProps;

export const DeepTagReportFileUploadModalContainer = ({
  permittedRouteMap,
}: DeepTagReportFileUploadModalContainerProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const { shopId, lexiconsMetadataList } = useAppSelector(state => {
    return {
      shopId: state.shop.current?.shopId,
      lexiconsMetadataList: state.lexicon.lexiconsMetadataList,
    };
  }, shallowEqual);

  const onModalClose = () => {
    if (permittedRouteMap.deepTagReports && shopId) {
      dispatch(
        deepTagReportFileUploadModalContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.deepTagReports.path, { shopId }),
        })
      );
    }
  };

  return shopId && lexiconsMetadataList ? (
    <DeepTagReportFileUploadModal
      shopId={shopId}
      dispatch={dispatch}
      onCancel={onModalClose}
      languages={lexiconsMetadataList}
      exampleFilePath={EXAMPLE_FILE_PATH}
    />
  ) : null;
};
