import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppNotification } from 'src/app-types';
import {
  CatalogManagerService,
  CatalogExplorerProductsPagination,
  CatalogExplorerProductFilters,
} from 'src/services';

const catalogManagerService = new CatalogManagerService();

export const CatalogExplorerPageActions = {
  getProducts: createAsyncThunk(
    'catalogExplorer/GetProducts',
    async (
      {
        shopId,
        catalogName,
        dataFields,
        pagination,
        filters,
        mainSearchFilterTerm,
        cancellationSignal,
      }: {
        shopId: number;
        catalogName: string;
        dataFields: string[];
        pagination: CatalogExplorerProductsPagination;
        filters: CatalogExplorerProductFilters;
        mainSearchFilterTerm: string;
        cancellationSignal?: AbortSignal;
      },
      { rejectWithValue }
    ) => {
      try {
        const catalogProductsResult = await catalogManagerService.getCatalogExplorerProducts({
          shopId,
          catalogName,
          dataFields,
          pagination,
          filters,
          mainSearchFilterTerm,
          cancellationSignal,
        });

        return { catalogProductsResult };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  clearCatalogExplorerData: createAction('catalogExplorer/clearProductsData'),
  notification: createAction<Pick<AppNotification, 'customMessage' | 'type'>>(
    'catalogExplorer/CreateToast'
  ),
  downloadIndexedFeedFile: createAsyncThunk(
    'catalogExplorer/DownloadIndexedFeedFile',
    async (
      {
        shopId,
        catalogName,
      }: {
        shopId: number;
        catalogName: string;
      },
      { rejectWithValue }
    ): Promise<unknown> => {
      try {
        await catalogManagerService.downloadIndexedFeedFile({
          shopId,
          catalogName,
        });

        return {};
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
