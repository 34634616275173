import React from 'react';
import {
  Tooltip,
  Icon,
  AvailableIcons,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import {
  NumericTypeDisplayOptions,
  TextTypeDisplayOptions,
  DisplayType,
} from 'src/services/src/service/types/filters';
import './display-type-preview.scss';
import { LabelStyled } from './FiltersDisplayConfig.styles';

const assets = require.context('../../assets/preview/filters-display-types', true, /\.svg$/);
interface DisplayTypePreviewProps {
  displayTypeKey: DisplayType;
}

export const labelsTextDict: Record<DisplayType, string> = {
  [TextTypeDisplayOptions.list]: 'Values List',
  [TextTypeDisplayOptions.tags]: 'Tags',
  [TextTypeDisplayOptions.swatches]: 'Color Icons',
  [NumericTypeDisplayOptions.slider]: 'Slider',
  [NumericTypeDisplayOptions.rangeGroups]: 'Ranges',
};

export const DisplayTypePreview = ({ displayTypeKey }: DisplayTypePreviewProps): JSX.Element => {
  let previewImagePath;
  try {
    previewImagePath = assets(`./${displayTypeKey}.svg`);
  } catch (err) {
    console.warn((err as { message?: string }).message);
    previewImagePath = '';
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
      <LabelStyled>
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
          {labelsTextDict[displayTypeKey]}
        </Typography>
      </LabelStyled>
      {previewImagePath ? (
        <Tooltip
          className='display-type-preview__popup'
          value={
            <div className='display-type-preview__popup-image'>
              <img alt={displayTypeKey} src={previewImagePath} />
            </div>
          }
        >
          <Icon css={{ marginTop: -4 }} name={AvailableIcons.Preview} />
        </Tooltip>
      ) : null}
    </div>
  );
};
