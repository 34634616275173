import React from 'react';
import { ShopTheLookSettingsTypes } from 'src/services';
import { diffValueDisplayHelpers } from '../value-helpers';
import { EntityDiffSchema } from '../../types';

type ShopTheLookDiffSchema = ShopTheLookSettingsTypes.ShopTheLookSettings;

export const shopTheLookDiffSchema: EntityDiffSchema<
  ShopTheLookDiffSchema,
  ShopTheLookSettingsTypes.ShopTheLookSettings
> = {
  numberOfResultsToDisplay: {
    displayName: 'Number Of Results To Display',
  },
  includeMainProductInCarousel: {
    displayName: 'Include Main Product In Carousel',
  },
  shuffleResults: {
    displayName: 'Shuffle Results',
  },
  completeTheLookFallbackMethod: {
    displayName: 'Complete The Look Fallback Method',
  },
  addToCart: {
    displayName: 'Add To Cart',
    getValue({
      entity,
    }: {
      entity: ShopTheLookSettingsTypes.ShopTheLookSettings;
    }): string | JSX.Element {
      const isActive = entity?.addToCart?.active;
      const isBundle = entity?.addToCart?.isBundle;
      return isActive ? (
        <>
          Active: {String(isActive)}
          <br />
          {isBundle ? <p>Bundle: {String(isBundle)}</p> : null}
        </>
      ) : (
        diffValueDisplayHelpers.DEFAULT_EMPTY_VALUE
      );
    },
  },
};
