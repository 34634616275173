import React, { useMemo } from 'react';
import { ThematicTagForm, ThematicTagFormProps } from '../ThematicTagForm';
import { ThematicTagFormSkeleton } from '../ThematicTagForm/components';
import { ThematicTagModalStyled } from './ThematicTagModal.styles';

export interface ThematicTagModalProps
  extends Pick<ThematicTagFormProps, 'mode' | 'shopId' | 'locale' | 'dispatch' | 'thematicTag'> {
  onClose: () => void;
  onSuccess: () => void;
}

export const ThematicTagModal = React.memo(
  ({
    mode,
    shopId,
    locale,
    onClose,
    onSuccess,
    dispatch,
    thematicTag,
  }: ThematicTagModalProps): JSX.Element => {
    const isLoading = useMemo(() => mode === 'edit' && !thematicTag, [mode, thematicTag]);

    return (
      <ThematicTagModalStyled open onClose={onClose}>
        {isLoading ? (
          <ThematicTagFormSkeleton />
        ) : (
          <ThematicTagForm
            mode={mode}
            shopId={shopId}
            locale={locale}
            onClose={onClose}
            dispatch={dispatch}
            thematicTag={thematicTag}
            onSuccess={onSuccess}
          />
        )}
      </ThematicTagModalStyled>
    );
  }
);
