import React, { useMemo } from 'react';
import classnames from 'classnames';
import { MenuItem, SelectOnChangeEvent, Select, SelectType } from 'src/components-dummy';
import { SyteProductTypes } from '../../services';
import { getSyteProductDropdownOptions } from '../shared';

type SyteProductValueType = SyteProductTypes.SyteProductType | undefined;
export interface SyteProductTypeDropDownProps {
  value?: SyteProductValueType;
  onChange?: (value: SyteProductValueType) => void;
  selectedIndex?: number;
  className?: string;
  supportedSyteProducts?: SyteProductTypes.SyteProductType[];
  disabled?: boolean;
  prefixId?: string;
  entityId?: string;
}
const COMPONENT_NAME = 'SyteProductTypeDropDown';

export const SyteProductTypeDropDown = ({
  value,
  onChange,
  className,
  supportedSyteProducts,
  disabled,
  prefixId,
  entityId,
}: SyteProductTypeDropDownProps): JSX.Element => {
  const dataId = `${prefixId}_${COMPONENT_NAME}`;
  const dataIdMenu = `${prefixId}`;

  const filteredDropDownOptions = useMemo(() => {
    const supportedProductsSet = new Set(supportedSyteProducts);
    const syteProductDropdownOptions = getSyteProductDropdownOptions(entityId);
    return syteProductDropdownOptions.filter(option => supportedProductsSet.has(option.value));
  }, [supportedSyteProducts]);

  const handleChange: SelectOnChangeEvent = event => {
    if (onChange) onChange(event.target.value as SyteProductValueType);
  };

  return (
    <Select
      placeholder='Select product'
      type={SelectType.Menu}
      onChange={handleChange}
      value={value}
      disabled={disabled}
      className={classnames('syte-products-types-dropdown', className)}
      prefixId={dataId}
    >
      {filteredDropDownOptions.map((option: { value?: string; text: string }) => (
        <MenuItem
          prefixId={`${dataIdMenu}_${option.value}`}
          key={option.value}
          value={option.value}
        >
          {option.text}
        </MenuItem>
      ))}
    </Select>
  );
};
