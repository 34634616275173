import React, { ReactNode, useEffect } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { GenericObject } from '../types';
import { AvailableIcons } from '../Icon';
import { SearchInput } from '../SearchInput';
import { useFindComponentByType } from '../hooks';
import { ListSelectorProps } from './types';
import { useListSelector } from './useListSelector';
import { SelectCaretIconStyled } from './ListSelector.styles';
import './ListSelector.scss';

export const ItemTemplate = ({ children }: { children: ReactNode }): JSX.Element => <>{children}</>;
export const FooterTemplate = ({ children }: { children: ReactNode }): JSX.Element => (
  <>{children}</>
);

export const SelectCaretIcon = ({
  isOpened,
  className,
}: {
  isOpened: boolean;
  className?: string;
}) => (
  <SelectCaretIconStyled
    name={AvailableIcons.SelectCaret}
    className={classNames('select-caret', className)}
    isOpened={isOpened}
  />
);

export const ListSelector = <T extends GenericObject<T>>({
  children,
  items,
  idField,
  selectedItem,
  onItemSelected,
  searchPlaceholder,
  searchableFields,
  className,
  popupClassName,
  selectPlaceHolder,
  childClassName,
  selectCaretIconClassName,
  searchIconName = AvailableIcons.Search,
}: ListSelectorProps<T>): JSX.Element => {
  const itemTemplate = useFindComponentByType({ children, type: ItemTemplate })?.props.children;
  const footerTemplate = useFindComponentByType({ children, type: FooterTemplate })?.props.children;

  const {
    items: filteredItems,
    showSearch,
    searchInputValue,
    onSearchInputChange,
    popUpIsOpened,
    setPopUpIsOpened,
    popupDimensions,
    selectedItemBarRef,
    popupRef,
    onItemClick,
  } = useListSelector({
    items,
    idField,
    selectedItem,
    searchableFields,
    onItemSelected,
  });

  const handleItemClick = (item: T) => () => onItemClick(item);

  useEffect(() => {
    if (!popUpIsOpened && searchInputValue) onSearchInputChange('');
  }, [popUpIsOpened]);

  const component = (
    <div
      className={classNames('syte-list-selector-popup', popupClassName)}
      style={{ ...popupDimensions }}
      ref={popupRef}
    >
      <div className='syte-list-selector-popup-wrapper'>
        {showSearch && (
          <SearchInput
            value={searchInputValue}
            onChange={onSearchInputChange}
            className='syte-list-selector-popup-search'
            placeholder={searchPlaceholder}
            searchIconName={searchIconName}
          />
        )}
        <ul className='syte-list-selector-popup-list'>
          {filteredItems?.map((item: T) => {
            const keyField = idField as keyof T;
            const isSelected = selectedItem?.[keyField] === item?.[keyField];
            return (
              <li
                className={classNames('syte-list-selector-popup-item', {
                  selected: isSelected,
                })}
                key={item?.[keyField]}
                onClick={handleItemClick(item)}
              >
                {itemTemplate && itemTemplate(item)}
              </li>
            );
          })}
        </ul>
        {footerTemplate}
      </div>
    </div>
  );

  const popUpComponent = popUpIsOpened
    ? ReactDOM.createPortal(
        popUpIsOpened ? component : null,
        document.querySelector('[id="root"], body') as Element
      )
    : null;
  return (
    <>
      <div
        className={classNames('syte-list-selector', className, { isOpened: popUpIsOpened })}
        ref={selectedItemBarRef}
      >
        <div
          className={classNames(
            'syte-list-selector-selected-item-bar',
            'syte-input-base',
            childClassName
          )}
          onClick={() => setPopUpIsOpened(!popUpIsOpened)}
        >
          <div className='syte-list-selector-selected-item-wrapper'>
            {(itemTemplate && selectedItem && itemTemplate(selectedItem)) || selectPlaceHolder}
          </div>
          <SelectCaretIcon isOpened={popUpIsOpened} className={selectCaretIconClassName} />
        </div>
      </div>
      {popUpComponent}
    </>
  );
};

ListSelector.Item = ItemTemplate;
ListSelector.Footer = FooterTemplate;
