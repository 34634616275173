import React, { useMemo } from 'react';
import { ShopDataField } from 'src/services';
import { TableV2 } from 'src/components-dummy';
import { ParseJoiValidateResponse, reduceErrorsByPrefix } from 'src/utils';
import { DataFieldTableRowSkeletonStyled } from '../../DataFieldsTableForm.styles';
import { getTableColumns } from '../../constants';
import { DataFieldTableRow } from '../DataFieldsTableRow';
import { DataFieldsValidationType } from '../../types';

interface DataFieldTableProps {
  dataFields: ShopDataField[] | undefined;
  isSyteAdmin: boolean;
  onDataFieldChange: (updatedDataField: ShopDataField) => void;
  errors: ParseJoiValidateResponse<DataFieldsValidationType>;
}

export const DataFieldTable = React.memo(
  ({ dataFields, isSyteAdmin, onDataFieldChange, errors }: DataFieldTableProps): JSX.Element => {
    const tableColumns = useMemo(() => getTableColumns(isSyteAdmin), [isSyteAdmin]);
    const columnsArr = useMemo(() => Object.values(tableColumns), [tableColumns]);

    return (
      <>
        {!dataFields ? (
          <>
            <DataFieldTableRowSkeletonStyled variant='rounded' height={50} />
            <DataFieldTableRowSkeletonStyled variant='rounded' height={50} />
            <DataFieldTableRowSkeletonStyled variant='rounded' height={50} />
          </>
        ) : (
          <TableV2<ShopDataField> options={{ columns: columnsArr, data: dataFields || [] }}>
            {({ getTableBodyProps, headerGroups, rows, prepareRow }) => {
              const headerGroup = headerGroups[0];

              return (
                <>
                  <TableV2.Head {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <TableV2.HeadRow
                        {...column.getHeaderProps()}
                        width={columnsArr[index].width}
                        key={column.id}
                      >
                        <TableV2.HeadRowCellText>{column.render('Header')}</TableV2.HeadRowCellText>
                      </TableV2.HeadRow>
                    ))}
                  </TableV2.Head>
                  <TableV2.Body {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                      prepareRow(row);
                      const rowErrors = reduceErrorsByPrefix({
                        errors,
                        prefix: `dataFields.${index}`,
                      });
                      return (
                        <DataFieldTableRow
                          key={row.original.id}
                          row={row}
                          tableColumns={tableColumns}
                          onChange={onDataFieldChange}
                          errors={rowErrors}
                        />
                      );
                    })}
                  </TableV2.Body>
                </>
              );
            }}
          </TableV2>
        )}
      </>
    );
  }
);
