import { OverlayModal } from 'src/components-dummy';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { REVIEW_BEFORE_PUBLISH_BANNER_HEIGHT_PX } from 'src/components-bl/Versioning/PublishStatusIndication/constants';

export const EditCustomInspirationsGalleryPageStyled = styled.div`
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const SideBarLayout = styled.div`
  min-width: 400px;
  max-width: 400px;
  z-index: 1;
`;

export const PreviewLayout = styled.div`
  width: 100%;
  min-height: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const PreviewFooter = styled.div`
  min-height: 88px;

  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-80']};

  background-color: ${({ theme }) => theme.palette.common.white};

  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 40px;
  gap: 5px;
`;

export const OverlayModalStyled = styled(OverlayModal)<{ fullscreen: boolean }>`
  ${({ fullscreen }) => {
    if (fullscreen) {
      return css`
        height: 100%;
      `;
    }
    return css`
      height: calc(100% - ${REVIEW_BEFORE_PUBLISH_BANNER_HEIGHT_PX}px);
      top: ${REVIEW_BEFORE_PUBLISH_BANNER_HEIGHT_PX}px;
    `;
  }}
`;
