import { Theme } from '@emotion/react';
import { DeepTagScoreType } from 'src/services';
import { ColorValue } from 'src/styles';

export function getScoreTypeColor({
  theme,
  scoreType,
  isEdited,
}: {
  theme: Theme;
  scoreType: DeepTagScoreType | undefined;
  isEdited?: boolean;
}): ColorValue {
  if (isEdited) return theme.palette.custom['primary-90'];
  switch (scoreType) {
    case DeepTagScoreType.High:
      return theme.palette.custom['high-rank'];
    case DeepTagScoreType.Medium:
      return theme.palette.custom['medium-rank'];
    case DeepTagScoreType.Low:
      return theme.palette.custom['low-rank'];
    default:
      return theme.palette.custom['gray-90'];
  }
}

export const DeepTagScoreTypeToDisplayValue = {
  [DeepTagScoreType.High]: 'High',
  [DeepTagScoreType.Medium]: 'Medium',
  [DeepTagScoreType.Low]: 'Low',
};
