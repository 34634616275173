import { useEffect, useState, useCallback } from 'react';
import { useValidateSchema } from 'src/hooks';
import { SimilarItemsSettingsReducerState } from 'src/app-state-types';
import {
  UseSimilarItemsSettingsDraftArgs,
  UseSimilarItemsSettingsDraft,
  SimilarItemsSettingsRecord,
} from './types';
import { similarItemsSettingsValidationSchema } from './constants';
import { similarItemsSettingsActionsMethods } from './Actions';
import { AddToCart } from '../../app-state-types/reducer-state-types/add-to-cart';
import { isDataDirty } from '../../utils';

export function useSimilarItemsSettingsDraft({
  similarItemsSettings,
  dispatch,
  shopId,
  selectedVariantId,
  onIsDirty,
  shouldRefetch = false,
}: UseSimilarItemsSettingsDraftArgs): UseSimilarItemsSettingsDraft {
  const [draftSimilarItemsSettings, setDraftSimilarItemsSettings] = useState(similarItemsSettings);

  const { errors, validate, isValid } = useValidateSchema<SimilarItemsSettingsRecord>({
    schema: similarItemsSettingsValidationSchema,
  });

  const isDirty = isDataDirty(similarItemsSettings, draftSimilarItemsSettings);

  const onSubmit = (): void => {
    if (isValid) {
      dispatch(
        similarItemsSettingsActionsMethods.updateSimilarItemsSettings({
          shopId,
          numberOfResultsToDisplay: draftSimilarItemsSettings.numberOfResultsToDisplay,
          variantId: selectedVariantId,
          addToCart: draftSimilarItemsSettings.addToCart,
          enableTextSimilarity: draftSimilarItemsSettings.enableTextSimilarity,
        })
      );
    }
  };

  const onDiscard = (): void => {
    setDraftSimilarItemsSettings(similarItemsSettings);
  };

  const onChange = useCallback(
    (partialSettings: Partial<SimilarItemsSettingsReducerState>) => {
      const updatedState = { ...draftSimilarItemsSettings, ...partialSettings };
      validate({ dataToValidate: updatedState });
      setDraftSimilarItemsSettings(updatedState);
    },
    [draftSimilarItemsSettings, validate, setDraftSimilarItemsSettings]
  );

  const fetchSimilarItemsSettings = useCallback(() => {
    dispatch(
      similarItemsSettingsActionsMethods.fetchSimilarItemsSettings({
        shopId,
        variantId: selectedVariantId,
      })
    );
  }, [shopId, selectedVariantId]);

  const updateAddToCartConfig = (config: AddToCart) => {
    onChange({ addToCart: config });
  };

  const onUpdateTextSimilarity = useCallback(
    (enableTextSimilarity: boolean) => onChange({ enableTextSimilarity }),
    [onChange]
  );

  useEffect((): void => {
    setDraftSimilarItemsSettings(similarItemsSettings);
  }, [similarItemsSettings]);

  useEffect(() => {
    validate({ dataToValidate: draftSimilarItemsSettings });
  }, [draftSimilarItemsSettings]);

  useEffect(() => {
    const payload = { isDirty: !!isDirty };
    if (onIsDirty) {
      onIsDirty(payload);
    } else {
      dispatch(similarItemsSettingsActionsMethods.notifyIsDirty(payload));
    }
  }, [isDirty]);

  useEffect(() => {
    fetchSimilarItemsSettings();
  }, [fetchSimilarItemsSettings]);

  useEffect(() => {
    if (shouldRefetch) {
      fetchSimilarItemsSettings();
    }
  }, [shouldRefetch]);

  return {
    draftSimilarItemsSettings,
    errors,
    isValid,
    isDirty,
    onSubmit,
    onDiscard,
    onChange,
    updateAddToCartConfig,
    onUpdateTextSimilarity,
  };
}
