import React from 'react';
import classNames from 'classnames';
import { SwitchTemplateProps } from './types';
import { SubTitleTemplateStyled } from './Switch.styles';

export const SubTitleTemplate = ({ children, className }: SwitchTemplateProps): JSX.Element => (
  <SubTitleTemplateStyled className={classNames('syte-toggle-subtitle', className)}>
    {children}
  </SubTitleTemplateStyled>
);
