import { parseISO } from 'date-fns';
import { Notification } from '../types/notifications';
import { MappedNotificationsResponse, NotificationApi, NotificationsResponse } from './types';

export class NotificationsMapper {
  static mapNotificationsResponse(
    notificationsResponse: NotificationsResponse
  ): MappedNotificationsResponse {
    return {
      ...notificationsResponse,
      notifications: notificationsResponse.notifications.map(notification =>
        this.mapNotification(notification)
      ),
    };
  }

  static mapNotification(notification: NotificationApi): Notification {
    const { createdAt, ...restNotification } = notification;
    return { ...restNotification, createdAt: parseISO(createdAt) };
  }
}
