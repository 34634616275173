import {
  ICatalogUploadErrorReportBodyRowAdmin,
  ICatalogUploadErrorReportBodyRowClient,
  ICatalogUploadErrorReportTableHeadRow,
} from './types';

/**
 * Client columns settings
 */
export const tableColumnsClient: Record<
  keyof ICatalogUploadErrorReportBodyRowClient,
  ICatalogUploadErrorReportTableHeadRow
> = {
  syteCategory: {
    Header: 'Syte Category',
    accessor: 'syteCategory',
  },
  sku: {
    Header: 'SKU',
    accessor: 'sku',
  },
  title: {
    Header: 'Title',
    accessor: 'title',
  },
  category: {
    Header: 'Category',
    accessor: 'category',
  },
  imageUrl: {
    Header: 'Image URL',
    accessor: 'imageUrl',
    minWidth: 15,
  },
  errorReason: {
    Header: 'Error Reason',
    accessor: 'errorReason',
    width: 100,
  },
  actions: {
    Header: 'Actions',
    accessor: 'actions',
    width: 100,
  },
};

/**
 * Admin columns settings (added internal columns)
 */
export const tableColumnsAdmin: Record<
  keyof ICatalogUploadErrorReportBodyRowAdmin,
  ICatalogUploadErrorReportTableHeadRow
> = {
  ...tableColumnsClient,
  errorReasonInternal: {
    Header: 'Internal error message',
    accessor: 'errorReasonInternal',
    width: 50,
  },
  actionsInternal: {
    Header: 'Internal action',
    accessor: 'actionsInternal',
    width: 50,
  },
};
