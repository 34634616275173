import React, { useCallback } from 'react';
import { copyToClipboard } from 'src/utils';
import {
  CodeColorOption,
  CodeSectionStyled,
  CodeTypographyColored,
  CopyButtonStyled,
} from './CodeSection.styles';
import { AvailableIcons } from '../Icon';

export interface CodeSectionProps {
  enableCopy?: boolean;
  onCopyClick: (text: string) => void;
  codeParts: { text: string | number; color: CodeColorOption }[];
}

export const CodeSection = ({
  enableCopy = true,
  codeParts,
  onCopyClick,
}: CodeSectionProps): JSX.Element => {
  const onCopySnippetClick = useCallback(() => {
    const textToCopy = codeParts.map(codePart => codePart.text).join('');

    copyToClipboard(textToCopy);
    onCopyClick?.(textToCopy);
  }, [codeParts, onCopyClick]);

  return (
    <CodeSectionStyled>
      {enableCopy && (
        <CopyButtonStyled
          onClick={onCopySnippetClick}
          startIcon={AvailableIcons.Copy}
          variant='tertiary'
        >
          Copy code
        </CopyButtonStyled>
      )}

      {codeParts.map(({ text, color }) => {
        return (
          <CodeTypographyColored key={text} color={color}>
            {text}
          </CodeTypographyColored>
        );
      })}
    </CodeSectionStyled>
  );
};
