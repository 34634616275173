import styled from '@emotion/styled';
import { Select } from 'src/components-dummy';

const dropdownStyle = (props: { isSizeBig: boolean }) => `
color: white !important;

.syte-basic-icon.select-caret {
  right: 6px;
  stroke: #fff !important;
}
& > div {
  padding: 0px 26px 0px 16px !important;
  height: ${props.isSizeBig ? '40px !important' : '24px !important'};
`;

export const DeepTagReportStatusSelectStyled = styled(Select)<{
  color: string;
  isSizeBig: boolean;
}>`
  .MuiInputBase-root {
    width: auto !important;
    min-width: 105px;
  }
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary {
    background: ${props => props.color} !important;
    ${props => dropdownStyle(props)}
  }
  .Mui-focused.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary {
    background: ${props => props.color};
    ${dropdownStyle}
  }

  .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input {
    font-family: Inter !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
  }
`;
