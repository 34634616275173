import { useCallback, useEffect } from 'react';
import {
  lexiconWebSocketService,
  LexiconImportExportProgressMessage,
  LexiconMetadataChangedMessage,
} from 'src/services';
import { Dispatch } from 'src/components-bl';
import { useLexiconWSActions } from './useLexiconWS.actions';

export const useLexiconWS = ({
  dispatch,
  shopId,
  locale,
  requestId,
}: {
  dispatch: Dispatch;
  shopId: number | undefined;
  locale?: string;
  requestId?: string;
}): void => {
  const onImportExportProgressUpdate = useCallback(
    (payload: LexiconImportExportProgressMessage) => {
      if (requestId === payload.data.requestId && payload.data.shopId === shopId) {
        dispatch(useLexiconWSActions.setImportExportProgress({ progress: payload.data.progress }));
      }
    },
    [shopId, dispatch, requestId]
  );

  const onMetadataChange = useCallback(
    (payload: LexiconMetadataChangedMessage) => {
      const isRelevantEvent =
        locale && payload.data.locales.includes(locale) && payload.data.shopId === shopId;

      if (locale && shopId && isRelevantEvent) {
        dispatch(useLexiconWSActions.getLexiconMetadata({ shopId, locale }));
      }
    },
    [shopId, dispatch, locale]
  );

  useEffect(() => {
    if (shopId) {
      lexiconWebSocketService.subscribeToImportExportProgressEvents(onImportExportProgressUpdate);
      lexiconWebSocketService.subscribeToMetadataChangeEvents(onMetadataChange);
    }

    return () => {
      lexiconWebSocketService.clearListeners();
    };
  }, [shopId, onImportExportProgressUpdate, onMetadataChange]);
};
