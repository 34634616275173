import React, { useMemo } from 'react';
import { CustomInspirationsGalleryLayoutType } from 'src/services';
import { AvailableIcons, TypographyType, TypographyVariant } from 'src/components-dummy';
import {
  ComingSoonBadge,
  ComingSoonTypography,
  IconStyled,
  IconWrapper,
  TypographyStyled,
} from './LayoutTypeImage.styles';

interface LayoutTypeImageProps {
  currentSelected: CustomInspirationsGalleryLayoutType | undefined;
  type: CustomInspirationsGalleryLayoutType;
  disabled?: boolean;
  onSelect: (type: CustomInspirationsGalleryLayoutType) => void;
}

const layoutTypeToConfig: Record<
  CustomInspirationsGalleryLayoutType,
  { displayName: string; iconName: AvailableIcons }
> = {
  [CustomInspirationsGalleryLayoutType.Grid]: {
    displayName: 'Grid',
    iconName: AvailableIcons.GridLayout,
  },
  [CustomInspirationsGalleryLayoutType.Carousel]: {
    displayName: 'Carousel',
    iconName: AvailableIcons.CarouselLayout,
  },
  [CustomInspirationsGalleryLayoutType.Collage]: {
    displayName: 'Collage',
    iconName: AvailableIcons.CollageLayout,
  },
};

export const LayoutTypeImage = ({
  currentSelected,
  onSelect,
  type,
  disabled = false,
}: LayoutTypeImageProps): JSX.Element => {
  const { displayName, iconName } = useMemo(() => layoutTypeToConfig[type], [type]);

  return (
    <IconWrapper disabled={disabled} onClick={() => !disabled && onSelect(type)}>
      {disabled && (
        <ComingSoonBadge>
          <ComingSoonTypography
            variant={TypographyVariant.ExtraSmallRegular}
            type={TypographyType.Body}
          >
            Coming soon
          </ComingSoonTypography>
        </ComingSoonBadge>
      )}
      <IconStyled name={iconName} selected={currentSelected === type} disabled={disabled} />
      <TypographyStyled type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
        {displayName}
      </TypographyStyled>
    </IconWrapper>
  );
};
