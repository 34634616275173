import { useEffect, useMemo, useState } from 'react';
import { generatePath, useParams } from 'react-router';
import { useAppSelector } from 'src/hooks';
import { DomainEntityPath, ShopLexiconSettings } from 'src/services';
import { Dispatch } from 'src/components-bl';
import { RoutedComponentProps } from 'src/app-routes';
import { useValidateLocaleActionMethods, useValidateLocaleActions } from './Actions';
import { MetaLanguage } from '../../../../app-state-types/reducer-state-types';

export interface UseValidateLocaleParamArguments extends RoutedComponentProps {
  dispatch: Dispatch;
  shopId?: number;
}

// TODO use lexicon metadata
export const useValidateLocale = ({
  permittedRouteMap,
  dispatch,
  shopId,
}: UseValidateLocaleParamArguments): MetaLanguage | undefined => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const languages = useAppSelector(store => store.meta.languages);

  const { locale } = useParams<{ locale: string }>();

  const currLangMeta = useMemo(() => {
    const currLang = languages
      ?.filter((language: MetaLanguage) =>
        language.supportedDomains.includes(DomainEntityPath.Lexicon)
      )
      .find((filteredLang: MetaLanguage) => filteredLang.locale === locale);
    if (currLang) {
      return currLang;
    }
    return undefined;
  }, [languages, locale]);

  useEffect(() => {
    const validateLocale = async () => {
      if (!shopId) {
        return;
      }

      const lexiconSettings = (await (
        dispatch(useValidateLocaleActions.getLexiconSettings({ shopId })) as any
      ).unwrap()) as ShopLexiconSettings;

      const isLocaleEnabled = lexiconSettings.locales.includes(locale);
      const isLocaleParamValid = locale && currLangMeta && isLocaleEnabled;

      if (!isLocaleParamValid) {
        const navigateTo = generatePath(permittedRouteMap.lexicons?.path || '/', { shopId });
        dispatch(useValidateLocaleActionMethods.navigateTo({ navigateTo }));
        dispatch(useValidateLocaleActions.localeDisabledErrorNotification());
      }

      setIsValid(true);
    };

    validateLocale();
  }, [locale, permittedRouteMap, dispatch, currLangMeta, shopId, setIsValid]);

  return isValid ? currLangMeta : undefined;
};
