import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ParseJoiValidateResponse } from 'src/utils/parse-joi-validate-response';
import { isEqual } from 'lodash';
import { FieldType } from 'src/services';
import { dataFieldHasTypes } from 'src/utils/data-field-has-types';
import { useAppSelector, useValidateSchema } from '../../../../../hooks';
import { visualEditorActions } from '../../../state';
import { ReOrderedList } from '../../../../../components-dummy';
import { buildDefaultItem } from './helpers/buildDefaultItem';
import { filterArray } from './helpers/filterArray';
import { filterOutMandatoryDataFields } from './helpers/filterOutMandatoryDataFields';
import { validationSchema } from './validationSchema';
import { DataFieldToDisplay } from '../../../types/data-field-to-display';
import { VisualEditorSettings } from '../../../types/visual-editor-settings';
import { SpecialDataFieldToDisplay } from '../../../types/special-data-field-to-display';

interface UseDisplayPreferencesProps {
  shopId: number;
  onCloseModal: VoidFunction;
}

interface UseDisplayPreferencesReturn {
  dataFieldsToDisplay: DataFieldToDisplay[];
  specialDataFieldsToDisplay: SpecialDataFieldToDisplay[];
  onSpecialDataFieldChange: (field: SpecialDataFieldToDisplay, index: number) => void;
  onOrderChanged: (newList: ReOrderedList) => void;
  dataFieldsDropdownOptions: DataFieldToDisplay[];
  handleSelectChange: (index: number, item: DataFieldToDisplay) => void;
  itemsCountPerRow: number;
  onItemsCountPerRowChanged: (value: number) => void;
  onRemove: (id: string) => void;
  onAdd: VoidFunction;
  onSave: VoidFunction;
  onDiscard: VoidFunction;
  errors: ParseJoiValidateResponse<VisualEditorSettings>;
  canSubmit: boolean;
  isEmpty: boolean;
}

export const useDisplayPreferences = ({
  shopId,
  onCloseModal,
}: UseDisplayPreferencesProps): UseDisplayPreferencesReturn => {
  const dataFields = useAppSelector(state =>
    state.dataFields?.dataFields?.filter(
      dataField => dataField.fieldType === FieldType.CatalogField && dataFieldHasTypes(dataField)
    )
  );
  const {
    dataFieldsToDisplay: initialDataFieldsToDisplay,
    specialDataFieldsToDisplay: initialSpecialDataFieldsToDisplay,
    itemsCountPerRow: initialItemsCountPerRow,
  } = useAppSelector(state => state.visualEditor);

  const dispatch = useDispatch();

  const [dataFieldsToDisplay, setDataFieldsToDisplay] = useState<DataFieldToDisplay[]>(
    initialDataFieldsToDisplay
  );

  const [specialDataFieldsToDisplay, setSpecialDataFieldsToDisplay] = useState<
    SpecialDataFieldToDisplay[]
  >(initialSpecialDataFieldsToDisplay);

  const [itemsCountPerRow, setItemsCountPerRow] = useState(initialItemsCountPerRow);

  const { errors, validate, isValid } = useValidateSchema<VisualEditorSettings>({
    schema: validationSchema,
  });

  const handleSelectChange = (index: number, item: DataFieldToDisplay) => {
    const newSelectedOptions = [...(dataFieldsToDisplay || [])];
    newSelectedOptions[index] = item;
    setDataFieldsToDisplay(newSelectedOptions);
  };

  const filteredDataFields = filterOutMandatoryDataFields(dataFields);

  const restOfDataFields = filterArray(dataFieldsToDisplay, filteredDataFields);

  const dataFieldsDropdownOptions: DataFieldToDisplay[] = restOfDataFields.map(field => {
    return {
      id: field.id,
      value: field.name,
      text: field.displayName,
      position: dataFieldsToDisplay.length,
    };
  });

  const onOrderChanged = (newList: ReOrderedList) => {
    const updatedItems: DataFieldToDisplay[] = [];
    newList.forEach(({ key }, index) => {
      const nextListItem = dataFieldsToDisplay.find(item => item.value === key);
      if (nextListItem) {
        updatedItems.push({ ...nextListItem, position: index + 1 });
      }
    });
    setDataFieldsToDisplay(updatedItems);
  };

  const onRemove = (id: string) => {
    const updatedItems = dataFieldsToDisplay.filter(item => item.id !== id);
    setDataFieldsToDisplay(updatedItems);
  };

  const onAdd = () => {
    const updatedItems = [...dataFieldsToDisplay, buildDefaultItem(dataFieldsToDisplay)];
    setDataFieldsToDisplay(updatedItems);
  };

  const onSpecialDataFieldChange = (field: SpecialDataFieldToDisplay, index: number) => {
    const updatedFields = [...(specialDataFieldsToDisplay || [])];
    updatedFields[index] = field;
    setSpecialDataFieldsToDisplay(updatedFields);
  };

  const onItemsCountPerRowChanged = (itemsCount: number) => {
    setItemsCountPerRow(itemsCount);
  };

  useEffect(() => {
    validate({
      dataToValidate: { dataFieldsToDisplay, specialDataFieldsToDisplay, itemsCountPerRow },
    });
  }, [validate, dataFieldsToDisplay, specialDataFieldsToDisplay, itemsCountPerRow]);

  const onSave = () => {
    if (isValid) {
      dispatch(
        visualEditorActions.updateVisualEditorSettings({
          shopId,
          payload: { dataFieldsToDisplay, specialDataFieldsToDisplay, itemsCountPerRow },
        })
      );
      onCloseModal();
    }
  };

  const onDiscard = () => {
    setDataFieldsToDisplay(initialDataFieldsToDisplay);
    setSpecialDataFieldsToDisplay(initialSpecialDataFieldsToDisplay);
    setItemsCountPerRow(initialItemsCountPerRow);
    onCloseModal();
  };

  const isDirty = useMemo(() => {
    return !isEqual(
      [
        ...initialDataFieldsToDisplay,
        ...initialSpecialDataFieldsToDisplay,
        initialItemsCountPerRow,
      ],
      [...dataFieldsToDisplay, ...specialDataFieldsToDisplay, itemsCountPerRow]
    );
  }, [
    initialDataFieldsToDisplay,
    initialSpecialDataFieldsToDisplay,
    initialItemsCountPerRow,
    dataFieldsToDisplay,
    specialDataFieldsToDisplay,
    itemsCountPerRow,
  ]);

  const isEmpty =
    !dataFieldsToDisplay.length &&
    !specialDataFieldsToDisplay.filter(field => field.isActive).length;

  const canSubmit = isDirty && isValid && !isEmpty;

  return {
    dataFieldsToDisplay,
    specialDataFieldsToDisplay,
    onSpecialDataFieldChange,
    onOrderChanged,
    dataFieldsDropdownOptions,
    handleSelectChange,
    itemsCountPerRow,
    onItemsCountPerRowChanged,
    onRemove,
    onAdd,
    onSave,
    onDiscard,
    errors,
    canSubmit,
    isEmpty,
  };
};
