import React from 'react';
import BackdropMUI, { BackdropProps as BackdropMUIProps } from '@mui/material/Backdrop';
import { SxProps, Theme } from '@mui/material/styles';

export interface BackdropProps {
  sx?: SxProps<Theme>;
  open?: boolean;
  children?: React.ReactChild | React.ReactChild[];
  onClick?: BackdropMUIProps['onClick'];
}

export const Backdrop = ({ children, open = true, ...rest }: BackdropProps): JSX.Element => {
  return (
    <BackdropMUI open={open} {...rest}>
      {children}
    </BackdropMUI>
  );
};
