import { Dispatch } from '../../types';

/* --= Action Types =-- */
export enum NavBarActionTypes {
  NavigateTo = 'SideNav/NavigateTo',
}

/* --= Action Interfaces =-- */
interface NavigateToAction {
  type: typeof NavBarActionTypes.NavigateTo;
  payload: { navigateTo: string };
}

export type NavBarActions = NavigateToAction;

/* --= Actions =-- */
const navActions = {
  navToAction: (path: string): NavigateToAction => {
    return {
      type: NavBarActionTypes.NavigateTo,
      payload: { navigateTo: path },
    };
  },
};

/* --= Methods =-- */

export const navigateTo = (path: string) => (dispatch: Dispatch) => {
  dispatch(navActions.navToAction(path));
};
