import { DeepTagReportProductFilters, DeepTagScoreType } from 'src/services';
import { FilterState } from 'src/components-dummy';
import { Vertical } from 'src/services/src/service/types/constants';
import { FilterKey, DeepTagReportFiltersState, IArrayFilterState } from './types';
import {
  parseBoolean,
  parseArrayOfStrings,
  parseString,
  parseTagScoreTypes,
  parseVerticals,
  parseInteger,
} from './helpers';

interface CombinedFilters extends DeepTagReportProductFilters {
  skip: number;
  limit: number;
}

export const decodeFilterParamMap: {
  [K in keyof CombinedFilters]: (paramValue: unknown) => CombinedFilters[K] | undefined;
} = {
  search: parseString,
  editedOnly: parseBoolean,
  emptyTagsOnly: parseBoolean,
  tagScoreTypes: parseTagScoreTypes,
  attributes: parseArrayOfStrings,
  categories: parseArrayOfStrings,
  values: parseArrayOfStrings,
  attributeValuePairs: parseArrayOfStrings,
  missingAttributeValuePairs: parseArrayOfStrings,
  missingAttributes: parseArrayOfStrings,
  verticals: parseVerticals,
  statuses: parseArrayOfStrings,
  labels: parseArrayOfStrings,
  skip: parseInteger,
  limit: parseInteger,
};

export const filterKeyToDefaultFilter: {
  [K in keyof DeepTagReportProductFilters]: DeepTagReportFiltersState[K];
} = {
  attributes: { value: [], isApplied: false },
  values: { value: [], isApplied: false },
  attributeValuePairs: { value: [], isApplied: false },
  missingAttributeValuePairs: { value: [], isApplied: false },
  missingAttributes: { value: [], isApplied: false },
  categories: { value: [], isApplied: false },
  verticals: { value: [], isApplied: false },
  tagScoreTypes: { value: Object.values(DeepTagScoreType) as DeepTagScoreType[], isApplied: false },
  editedOnly: { value: true, isApplied: false },
  emptyTagsOnly: { value: true, isApplied: true },
  statuses: { value: [], isApplied: false },
  labels: { value: [], isApplied: false },
};

export function getDefaultFilter<Key extends FilterKey>(
  filterKey: Key,
  filtersOptions: IArrayFilterState<any>
): FilterState {
  if (filtersOptions?.[filterKey]?.length) {
    return {
      ...filterKeyToDefaultFilter[filterKey],
      value: filtersOptions?.[filterKey],
    } as FilterState;
  }
  return filterKeyToDefaultFilter[filterKey] as FilterState;
}

export const allScoreTypesOptions = [
  {
    text: 'High',
    value: DeepTagScoreType.High,
  },
  {
    text: 'Medium',
    value: DeepTagScoreType.Medium,
  },
  {
    text: 'Low',
    value: DeepTagScoreType.Low,
  },
] as Array<{ text: string; value: string }>;

export const allVerticalsOptions = [
  {
    text: 'DIY',
    value: Vertical.DIY,
  },
  {
    text: 'Fashion',
    value: Vertical.Fashion,
  },
  {
    text: 'General',
    value: Vertical.General,
  },
  {
    text: 'HomeDecor',
    value: Vertical.HomeDecor,
  },
  {
    text: 'Jewelry',
    value: Vertical.Jewelry,
  },
] as Array<{ text: string; value: string }>;
