import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const SubTitleStyled = styled(Typography)`
  display: block;
  margin-top: 4px;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const BottomSubTitleStyled = styled(SubTitleStyled)`
  margin-top: 15px;
`;
