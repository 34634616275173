export interface SortArrayByLocale {
  languageCode: string;
  collatorOptions?: Intl.CollatorOptions;
  isAscending?: boolean;
  a: any;
  b: any;
}

export const sortArrayByLocale = ({
  a,
  b,
  languageCode,
  collatorOptions = {},
  isAscending = true,
}: SortArrayByLocale): typeof a | typeof b => {
  if (isAscending) return new Intl.Collator(languageCode, collatorOptions).compare(a, b);
  return new Intl.Collator(languageCode).compare(b, a);
};
