import { EditExperimentContainerAction } from 'src/containers';
import { EnableUiTestReducerState } from '../app-state-types';
import { EnableUiTestAction, EnableUiTestActionTypes, VariantPanelAction } from '../components-bl';

const initialState: EnableUiTestReducerState = {
  enableUiTest: false,
  shouldRefetch: false,
};

export function enableUiTestReducer(
  state: EnableUiTestReducerState = initialState,
  action?: EnableUiTestAction | EditExperimentContainerAction | VariantPanelAction
): EnableUiTestReducerState {
  switch (action?.type) {
    case EnableUiTestActionTypes.GetEnableUiTestSuccess: {
      const { enableUiTest } = action.payload;
      return {
        ...state,
        enableUiTest,
        shouldRefetch: false,
      };
    }
    case EnableUiTestActionTypes.GetEnableUiTestRequest:
      return { ...state };
    case EnableUiTestActionTypes.GetEnableUiTestError:
      return { ...state };
    case EnableUiTestActionTypes.UpdateEnableUiTestSuccess: {
      const { enableUiTest } = action.payload;
      return {
        ...state,
        enableUiTest,
      };
    }
    default:
      return state;
  }
}
