import React, { useCallback, useEffect, useState } from 'react';
import { useDataFieldsBySupportedFeature } from 'src/components-bl/hooks';
import { ShopDataFieldsSupportedFeatures } from 'src/services';
import { initialFiltersState } from 'src/containers/MerchandisingRules/MerchandisingRulesMainContainer';
import {
  EditMerchandisingRulePage,
  EditMerchandisingRulePageProps,
} from '../EditMerchandisingRulePage';

import { CreateMerchandisingRulePage } from '../CreateMerchandisingRulePage';

import {
  MerchandisingRulesListPage,
  MerchandisingRulesListPageProps,
} from '../MerchandisingRulesListPage';
import { useDataFieldsLookupTable } from '../useDataFieldsLookupTable';

import { merchandisingRulesManagementActions } from './MerchandisingRulesManagement.actions';
import { RulePages } from './MerchandisingRulesManagement.config';
import { MerchandisingRulesFiltersState } from '../MerchandisingRulesListPage/components';
import { CategoryFilterRule } from '../../../VisualEditor/types/category-filter-rule';
import { ShopFeatureToggles } from '../../../../services/src/service/types/shops';

export interface MerchandisingRulesManagementProps
  extends Pick<
      EditMerchandisingRulePageProps,
      'isReadOnly' | 'formApiRef' | 'onFormStatusChange' | 'predefinedProduct' | 'isAiTagsEnabled'
    >,
    Pick<
      MerchandisingRulesListPageProps,
      | 'shopId'
      | 'selectedVariantId'
      | 'entityId'
      | 'dataFields'
      | 'dispatch'
      | 'overview'
      | 'rules'
      | 'availableRegions'
      | 'totalRulesCount'
    > {
  currentPage: RulePages;
  selectedRule: EditMerchandisingRulePageProps['rule'];
  duplicateRuleDraft: EditMerchandisingRulePageProps['rule'];
  onPageChange: (page: RulePages) => void;
  categoryFilterRule?: CategoryFilterRule;
  featureToggles: ShopFeatureToggles;
}

/**  Component to handle list, create rule and edit rule managed by useMerchandisingRulesManagement hook . intended for embedding in variant panel and Collections */
export const MerchandisingRulesManagement = ({
  shopId,
  selectedVariantId,
  entityId,
  dataFields,
  dispatch,

  // list props
  rules,
  overview,
  totalRulesCount,

  // form props
  isReadOnly,
  selectedRule,
  duplicateRuleDraft,
  formApiRef,
  currentPage: page,
  predefinedProduct,
  onPageChange,
  onFormStatusChange,
  availableRegions,
  isAiTagsEnabled,
  categoryFilterRule,
  featureToggles,
}: MerchandisingRulesManagementProps): JSX.Element | null => {
  const [filtersState, setFiltersState] =
    useState<MerchandisingRulesFiltersState>(initialFiltersState);
  const { supportedDataFields } = useDataFieldsBySupportedFeature({
    dataFields,
    feature: ShopDataFieldsSupportedFeatures.MerchandisingRules,
  });

  const { dataFieldsLookupTable, getDataFieldByName } = useDataFieldsLookupTable({
    dataFields: supportedDataFields,
  });

  const goBackToList = () => onPageChange(RulePages.List);

  const onCreateRule = useCallback(() => {
    onPageChange(RulePages.Create);
  }, [onPageChange]);

  const onRuleSelection = useCallback(
    (ruleId: string) => {
      onPageChange(RulePages.Edit);
      dispatch(
        merchandisingRulesManagementActions.getRule({
          shopId,
          ruleId,
          variantId: selectedVariantId,
        })
      );
    },
    [onPageChange, shopId]
  );

  useEffect(() => {
    if (shopId) {
      dispatch(
        merchandisingRulesManagementActions.getDataFields({
          shopId,
        })
      );
    }
  }, [shopId]);

  useEffect(() => {
    // back to list on unmount, so on next mounting it won't have last state
    return () => {
      goBackToList();
    };
  }, []);

  switch (page) {
    case RulePages.List:
      return (
        <MerchandisingRulesListPage
          onCreateRule={onCreateRule}
          shopId={shopId}
          rules={rules}
          overview={overview}
          selectedVariantId={selectedVariantId}
          entityId={entityId}
          dataFieldsLookupTable={dataFieldsLookupTable}
          dataFields={dataFields}
          onRuleSelection={onRuleSelection}
          dispatch={dispatch}
          isReadOnly={isReadOnly}
          availableRegions={availableRegions}
          filtersState={filtersState}
          setFiltersState={setFiltersState}
          totalRulesCount={totalRulesCount}
        />
      );
    case RulePages.Edit:
      return (
        <EditMerchandisingRulePage
          shopId={shopId}
          formApiRef={formApiRef}
          onFormStatusChange={onFormStatusChange}
          variantId={selectedVariantId}
          isReadOnly={isReadOnly}
          entityId={entityId}
          dispatch={dispatch}
          dataFieldsLookupTable={dataFieldsLookupTable}
          getDataFieldByName={getDataFieldByName}
          rule={selectedRule}
          kpiOptions={overview?.kpis}
          onBackToList={goBackToList}
          predefinedProduct={predefinedProduct}
          availableRegions={availableRegions}
          isAiTagsEnabled={isAiTagsEnabled}
          categoryFilterRule={categoryFilterRule}
          featureToggles={featureToggles}
        />
      );
    case RulePages.Create:
      return (
        <CreateMerchandisingRulePage
          shopId={shopId}
          formApiRef={formApiRef}
          onFormStatusChange={onFormStatusChange}
          variantId={selectedVariantId}
          isReadOnly={isReadOnly}
          rule={duplicateRuleDraft}
          entityId={entityId}
          dispatch={dispatch}
          dataFieldsLookupTable={dataFieldsLookupTable}
          getDataFieldByName={getDataFieldByName}
          kpiOptions={overview?.kpis}
          predefinedProduct={predefinedProduct}
          availableRegions={availableRegions}
          isAiTagsEnabled={isAiTagsEnabled}
          categoryFilterRule={categoryFilterRule}
          featureToggles={featureToggles}
        />
      );
    default:
      return null;
  }
};
