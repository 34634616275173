import React from 'react';
import classNames from 'classnames';
import { useFindComponentByType } from '../hooks';
import { HeaderTemplate, BodyTemplate } from './templates';
import './ListSection.scss';

export interface ListSectionProps {
  title?: string;
  className?: string;
  children: JSX.Element | JSX.Element[];
}

export const ListSection = ({ title, className, children }: ListSectionProps): JSX.Element => {
  const headerTemplate = useFindComponentByType({ type: HeaderTemplate, children })?.props.children;
  const bodyTemplate =
    (Array.isArray(children) &&
      useFindComponentByType({ type: BodyTemplate, children })?.props.children) ||
    children;

  const header = headerTemplate || title;

  return (
    <div className={classNames('syte-list-section', className)}>
      <div className={classNames('syte-list-section-header', { 'title-only': !!title })}>
        {header}
      </div>
      <div className='syte-list-section-body'>{bodyTemplate}</div>
    </div>
  );
};

ListSection.HeaderTemplate = HeaderTemplate;
ListSection.BodyTemplate = BodyTemplate;
