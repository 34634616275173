import React from 'react';
import { Dispatch } from 'redux';
import {
  DialogModalWithPagination,
  DialogModalWithPaginationProps,
} from 'src/components-dummy/DialogModalWithPagination/DialogModalWithPagination';
import { IDeepTagReportProduct } from 'src/services/src/service/types/deep-tag-reports';
import { IShopGeneratedDataSettings } from 'src/services';
import { DeepTagsProductFormSkeleton } from './components/DeepTagsProductForm.skeleton';
import { DeepTagsMainListFormProps } from './components/DeepTagsMainListForm/DeepTagsMainListForm';
import { DeepTagsProductForm, DeepTagsProductFormProps } from './DeepTagsProductForm';
import { OnGeneratedText } from './components/DeepTagGenerativeAIForm/DeepTagsGenerativeAIForm';
import { DeepTagsProductReviewAndEditTagsSectionProps } from './components/DeepTagsProductReviewAndEditTagsSection/DeepTagsProductReviewAndEditTagsSection';

export interface DeepTagsProductFormModalProps {
  isDirty: boolean;
  isLoading: boolean;
  reportLocale: string;
  reportId: DeepTagsProductFormProps['reportId'];
  shopId: DeepTagsProductFormProps['shopId'];
  generalConfigurationLabels: DeepTagsProductFormProps['generalConfigurationLabels'];
  currentUser: DeepTagsProductFormProps['currentUser'];
  dispatch: Dispatch;
  onCancel: () => void;
  onPreviousProductClick?: DialogModalWithPaginationProps['onBackButtonClick'];
  onNextProductClick?: DialogModalWithPaginationProps['onBackButtonClick'];
  onSaveChanges: DeepTagsProductReviewAndEditTagsSectionProps['onSaveChanges'];
  onChangeStatus: DeepTagsProductFormProps['onChangeStatus'];
  onChangeTitle: DeepTagsProductFormProps['onChangeTitle'];
  onSaveGeneratedDataSettings: ({
    shopGeneratedDataSettings,
  }: {
    shopGeneratedDataSettings: IShopGeneratedDataSettings;
  }) => void;
  getPaginatedReportsWithFilters: DeepTagsProductFormProps['getPaginatedReportsWithFilters'];
  onResetChanges: (productId: IDeepTagReportProduct['id'], locale: string) => void;
  onGeneratedText: OnGeneratedText;
  deepTagProduct?: IDeepTagReportProduct;
  lexiconCategories?: DeepTagsMainListFormProps['lexiconCategories'];
  isLexiconEnabled: DeepTagsMainListFormProps['isLexiconEnabled'];
  isLoadingLexicon: boolean;
  generatedDataSettings?: IShopGeneratedDataSettings;
  isContentGenerationPermitted: boolean;
  shouldHideEditIndication: DeepTagsProductFormProps['shouldHideEditIndication'];
}

export const DeepTagsProductFormModal = React.memo(
  ({
    isDirty,
    isLoading,
    reportLocale,
    reportId,
    shopId,
    generalConfigurationLabels,
    dispatch,
    onSaveChanges,
    onChangeStatus,
    onChangeTitle,
    onSaveGeneratedDataSettings,
    onCancel,
    onGeneratedText: onGetGeneratedText,
    onPreviousProductClick,
    onNextProductClick,
    getPaginatedReportsWithFilters,
    deepTagProduct,
    lexiconCategories,
    isLexiconEnabled,
    isLoadingLexicon,
    generatedDataSettings,
    isContentGenerationPermitted,
    shouldHideEditIndication,
    currentUser,
  }: DeepTagsProductFormModalProps): JSX.Element => {
    return (
      <DialogModalWithPagination
        open
        onClose={onCancel}
        onBackButtonClick={onPreviousProductClick}
        onNextButtonClick={onNextProductClick}
        sx={{
          zIndex: '1300 !important',
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '72%',
              maxWidth: '1280px',
              minWidth: '890px',
              overflow: 'hidden',
            },
          },
        }}
      >
        {!isLoading && deepTagProduct ? (
          <DeepTagsProductForm
            key={deepTagProduct.id}
            currentUser={currentUser}
            deepTagProduct={deepTagProduct}
            isDirty={isDirty}
            reportId={reportId}
            shopId={shopId}
            generalConfigurationLabels={generalConfigurationLabels}
            onSaveChanges={onSaveChanges}
            onChangeStatus={onChangeStatus}
            onChangeTitle={onChangeTitle}
            onSaveGeneratedDataSettings={onSaveGeneratedDataSettings}
            onCancel={onCancel}
            getPaginatedReportsWithFilters={getPaginatedReportsWithFilters}
            lexiconCategories={lexiconCategories}
            reportLocale={reportLocale}
            dispatch={dispatch}
            isLexiconEnabled={isLexiconEnabled}
            isLoadingLexicon={isLoadingLexicon}
            onGeneratedText={onGetGeneratedText}
            generatedDataSettings={generatedDataSettings}
            isContentGenerationPermitted={isContentGenerationPermitted}
            shouldHideEditIndication={shouldHideEditIndication}
          />
        ) : (
          <DeepTagsProductFormSkeleton />
        )}
      </DialogModalWithPagination>
    );
  }
);
