import React from 'react';
import {
  Switch,
  SwitchProps,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../../components-dummy';
import { SwitchContainer } from './SpecialDataFieldToggle.styled';

interface Props extends SwitchProps {
  label: string;
}

export const SpecialDataFieldToggle = ({
  label,
  disabled,
  checked,
  onChange,
}: Props): JSX.Element => {
  return (
    <SwitchContainer>
      <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
        {label}
      </Typography>
      <Switch disabled={disabled} checked={checked} onChange={onChange} />
    </SwitchContainer>
  );
};
