import React from 'react';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

interface ToggleOffConfirmationDialogProps {
  onClose: () => void;
  onConfirmedAction: (event: React.MouseEvent<HTMLElement>) => void;
  isOn: boolean;
}

export const ToggleOffConfirmationDialog = ({
  onClose,
  onConfirmedAction,
  isOn,
}: ToggleOffConfirmationDialogProps): JSX.Element => {
  const onOrOffTextValue = isOn ? `off` : 'on';

  return (
    <ConfirmationDialog onCancel={onClose}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Turn {onOrOffTextValue} tag</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        Are you sure you want to turn {onOrOffTextValue} this tag?
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onConfirmedAction}>
            Turn {onOrOffTextValue} tag
          </Button>
          <Button variant='outlined' onClick={onClose}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
