import React from 'react';
import styled from '@emotion/styled';
import { Skeleton } from '../../../../components-dummy';

const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const MerchRulesListSkeleton = () => {
  return (
    <SkeletonWrapper>
      <Skeleton repeat={3} variant='rounded' height={52} sx={{ margin: 0 }} />
    </SkeletonWrapper>
  );
};
