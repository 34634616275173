import React, { MouseEvent, useCallback } from 'react';
import {
  EllipsisMenuButton,
  Menu,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { MenuActionsStyled, MenuItemStyled } from './RowActionsMenu.styles';

interface RowActionMenuProps {
  enabled: boolean;
  menuAnchorElement: HTMLElement | null;
  onMenuClicked: (event: MouseEvent<HTMLElement>) => void;
  onMenuClose: () => void;
  onEditClick: () => void;
  onEnableClick: () => void;
  onDeleteClick: () => void;
}

export const RowActionsMenu = ({
  menuAnchorElement,
  onMenuClicked,
  onMenuClose,
  enabled,
  onEditClick,
  onEnableClick,
  onDeleteClick,
}: RowActionMenuProps): JSX.Element => {
  const isOpen = Boolean(menuAnchorElement);

  const onItemClick = useCallback(
    onClick => {
      onClick();
      onMenuClose();
    },
    [onMenuClose]
  );

  return (
    <MenuActionsStyled>
      <EllipsisMenuButton onClick={onMenuClicked} isActive={isOpen} />
      <Menu
        open={isOpen}
        anchorEl={menuAnchorElement}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: 'right',
        }}
      >
        <MenuItemStyled onClick={() => onItemClick(onEditClick)}>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            Edit
          </Typography>
        </MenuItemStyled>
        <MenuItemStyled onClick={() => onItemClick(onEnableClick)}>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            {enabled ? 'Hide from website' : 'Show on website'}
          </Typography>
        </MenuItemStyled>
        <MenuItemStyled onClick={onDeleteClick} shouldHighlightRed>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            Delete
          </Typography>
        </MenuItemStyled>
      </Menu>
    </MenuActionsStyled>
  );
};
