import { useEffect, useState, useCallback } from 'react';
import { useValidateSchema } from 'src/hooks';
import { BoughtTogetherSettingsReducerState } from 'src/app-state-types';
import { BoughtTogetherSettingsTypes, FieldType } from 'src/services';
import { UseBoughtTogetherSettingsDraftArgs, UseBoughtTogetherSettingsDraft } from './types';
import { boughtTogetherSettingsValidationSchema } from './constants';
import { boughtTogetherSettingsActionsMethods } from './Actions';
import { isDataDirty } from '../../utils';
import { DEFAULT_ADD_MULTIPLE_TO_CART } from '../AddToCartSettings/defaults/default-add-multiple-to-cart';
import { getNumericFields } from '../helpers/getNumericFields';
import { useMappedSelectValues } from '../hooks/useMappedSelectValues';
import { SortingOrder } from '../../types/enums/sorting-order';

export function useBoughtTogetherSettingsDraft({
  boughtTogetherSettings,
  dataFields,
  dispatch,
  shopId,
  selectedVariantId,
  onIsDirty,
  shouldRefetch = false,
}: UseBoughtTogetherSettingsDraftArgs): UseBoughtTogetherSettingsDraft {
  const [draftBoughtTogetherSettings, setDraftBoughtTogetherSettings] =
    useState(boughtTogetherSettings);

  const [draftModelTypeSettings, setDraftModelTypeSettings] = useState(
    boughtTogetherSettings.modelType
  );

  const numericDataFields = getNumericFields(dataFields);

  const dataFieldsDropdownOptions = useMappedSelectValues(numericDataFields);

  const { errors, validate, isValid } =
    useValidateSchema<BoughtTogetherSettingsTypes.BoughtTogetherSettings>({
      schema: boughtTogetherSettingsValidationSchema,
    });

  const isDirty = isDataDirty(boughtTogetherSettings, draftBoughtTogetherSettings);

  const onSubmit = (): void => {
    if (isValid) {
      dispatch(
        boughtTogetherSettingsActionsMethods.updateBoughtTogetherSettings({
          shopId,
          numberOfResultsToDisplay: draftBoughtTogetherSettings.numberOfResultsToDisplay,
          modelType: draftBoughtTogetherSettings.modelType,
          boughtTogetherFallbackMethod: draftBoughtTogetherSettings.boughtTogetherFallbackMethod,
          addToCart: draftBoughtTogetherSettings.addToCart,
          variantId: selectedVariantId,
          boughtTogetherFallbackField: draftBoughtTogetherSettings.boughtTogetherFallbackField,
          sortingOrder: draftBoughtTogetherSettings.sortingOrder || SortingOrder.Desc,
        })
      );
    }
  };

  const onDiscard = (): void => {
    setDraftBoughtTogetherSettings(boughtTogetherSettings);
  };

  const onChange = (partialSettings: Partial<BoughtTogetherSettingsReducerState>) => {
    const updatedState = { ...draftBoughtTogetherSettings, ...partialSettings };
    validate({ dataToValidate: updatedState });
    setDraftBoughtTogetherSettings(updatedState);
    const { active } = updatedState?.addToCart;
    if (!active) {
      setDraftBoughtTogetherSettings({
        ...updatedState,
        addToCart: DEFAULT_ADD_MULTIPLE_TO_CART,
      });
    }
  };

  const onModelChange = (modelType: Partial<BoughtTogetherSettingsReducerState['modelType']>) => {
    setDraftModelTypeSettings(modelType);
  };

  const fetchBoughtTogetherSettings = useCallback(() => {
    dispatch(
      boughtTogetherSettingsActionsMethods.fetchBoughtTogetherSettings({
        shopId,
        variantId: selectedVariantId,
      })
    );
  }, [shopId, selectedVariantId]);

  useEffect((): void => {
    setDraftBoughtTogetherSettings(boughtTogetherSettings);
  }, [boughtTogetherSettings]);

  useEffect(() => {
    const payload = { isDirty: !!isDirty };
    if (onIsDirty) {
      onIsDirty(payload);
    } else {
      dispatch(boughtTogetherSettingsActionsMethods.notifyIsDirty(payload));
    }
  }, [isDirty]);

  useEffect(() => {
    validate({ dataToValidate: draftBoughtTogetherSettings });
  }, [draftBoughtTogetherSettings]);

  useEffect(() => {
    fetchBoughtTogetherSettings();
  }, [fetchBoughtTogetherSettings]);

  useEffect(() => {
    if (shouldRefetch) {
      fetchBoughtTogetherSettings();
    }
  }, [shouldRefetch]);

  useEffect(() => {
    onChange({ modelType: draftModelTypeSettings });
  }, [draftModelTypeSettings]);

  useEffect(() => {
    dispatch(
      boughtTogetherSettingsActionsMethods.getDataFields({
        shopId,
        fieldType: FieldType.CatalogField,
      })
    );
  }, [shopId]);

  return {
    draftBoughtTogetherSettings,
    errors,
    isValid,
    isDirty,
    onSubmit,
    onDiscard,
    onChange,
    onModelChange,
    dataFieldsDropdownOptions,
  };
}
