import { SyteProductType } from 'src/services/src/service/types/constants/syte-product-types';

export const SUPPORTED_RANKING_PRODUCTS: SyteProductType[] = [
  SyteProductType.AugmentedSearch,
  SyteProductType.DiscoveryButton,
  SyteProductType.ShopTheLook,
  SyteProductType.SimilarItems,
  SyteProductType.VisualSearch,
  SyteProductType.Collections,
];
