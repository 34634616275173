import React from 'react';
import {
  AvailableIcons,
  DraggableList,
  Icon,
  ReOrderedList,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../../components-dummy';
import { LinkButton } from '../../../../../components-dummy/LinkButton';
import { SelectDataField } from '../SelectDataField/SelectDataField';
import { ParseJoiValidateResponse, reduceErrorsByPrefix } from '../../../../../utils';
import { mapErrorMessages } from '../../../../MerchandisingRules/mapErrorMessages';
import { DataFieldToDisplay } from '../../../types/data-field-to-display';
import { VisualEditorSettings } from '../../../types/visual-editor-settings';
import {
  DataFieldsItemRow,
  DataFieldsItemRowErrors,
  DataFieldsItemStyled,
  DataFieldsListContainer,
  DataFieldsListStyled,
  IconStyled,
} from './DataFieldsList.styles';

interface DataFieldsListProps {
  dataFieldsToDisplay: DataFieldToDisplay[];
  onOrderChanged: (newList: ReOrderedList) => void;
  dataFieldsDropdownOptions: DataFieldToDisplay[];
  handleSelectChange: (index: number, item: DataFieldToDisplay) => void;
  onRemove: (id: string) => void;
  onAdd: VoidFunction;
  errors: ParseJoiValidateResponse<VisualEditorSettings>;
}

export const DataFieldsList = ({
  dataFieldsToDisplay,
  onOrderChanged,
  dataFieldsDropdownOptions,
  handleSelectChange,
  onRemove,
  onAdd,
  errors,
}: DataFieldsListProps): JSX.Element => {
  return (
    <DataFieldsListContainer>
      <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
        Additional Fields to Display
      </Typography>
      <DataFieldsListStyled>
        <DraggableList onChange={onOrderChanged} isDragDisabled={false}>
          {dataFieldsToDisplay.map((option, index) => {
            const dataFieldsToDisplayErrors = reduceErrorsByPrefix({
              errors,
              prefix: `dataFieldsToDisplay.${index}.value`,
              errorKey: 'value',
            });

            const rowErrorLabels = mapErrorMessages(dataFieldsToDisplayErrors);

            return (
              <DataFieldsItemStyled key={option.value}>
                <DataFieldsItemRow>
                  <IconStyled name={AvailableIcons.DragHandleVerticalDouble} />
                  <SelectDataField
                    options={dataFieldsDropdownOptions}
                    value={option.text}
                    onChange={newOption => handleSelectChange(index, newOption)}
                    errors={dataFieldsToDisplayErrors}
                  />
                  <IconStyled name={AvailableIcons.TrashCan} onClick={() => onRemove(option.id)} />
                </DataFieldsItemRow>
                {rowErrorLabels.length > 0 ? (
                  <DataFieldsItemRowErrors>{rowErrorLabels}</DataFieldsItemRowErrors>
                ) : null}
              </DataFieldsItemStyled>
            );
          })}
        </DraggableList>
        <LinkButton onClick={onAdd}>
          <Icon name={AvailableIcons.Plus} />
          Add
        </LinkButton>
      </DataFieldsListStyled>
    </DataFieldsListContainer>
  );
};
