import React, { RefObject, forwardRef } from 'react';
import { CatalogFilters, CatalogFiltersConfigProps, FormApiRef } from 'src/components-bl';
import { useFiltersManagement } from './hooks';
import { LoadingContainer, SkeletonStyled } from './AugSearch.styles';
import { AugmentedSearchFiltersSectionHeader } from './AugmentedSearchFiltersSectionHeader';
import { ContainerComponentProps } from './augmented-search-filters.types';

export const AugmentedSearchCatalogFiltersContainer = forwardRef<
  FormApiRef,
  ContainerComponentProps
>(({ permittedRouteMap, ...props }, ref) => {
  const { filterProps } = useFiltersManagement({
    ...props,
    ref: ref as RefObject<FormApiRef>,
    permittedRouteMap,
  });

  return (
    <>
      {filterProps ? (
        <>
          <AugmentedSearchFiltersSectionHeader title={permittedRouteMap.catalogFilters?.title} />
          <CatalogFilters {...(filterProps as CatalogFiltersConfigProps)} />
        </>
      ) : (
        <LoadingContainer>
          <SkeletonStyled height={40} width={150} variant='rounded' />
          <SkeletonStyled height={100} variant='rounded' />
          <SkeletonStyled height={100} variant='rounded' />
        </LoadingContainer>
      )}
    </>
  );
});
