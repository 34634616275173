import React from 'react';
import { ConfirmationDialog } from 'src/components-dummy';
import * as DOMPurify from 'dompurify';
import { ContentPromptTextStyled } from './DeepTagsGenerativeAIForm.style';

const addBoldTagsToText = (inputText: string) => {
  let formattedText = '';
  let insideBrackets = false;

  for (let i = 0; i < inputText.length; i++) {
    const char = inputText[i];

    if (char === '{') {
      insideBrackets = true;
      formattedText += '<b>';
      formattedText += char;
    } else if (char === '}' && insideBrackets) {
      insideBrackets = false;
      formattedText += char;
      formattedText += '</b>';
    } else {
      formattedText += char;
    }
  }
  return formattedText;
};

interface DeepTagsGenerativeAIPromptModalProps {
  onCancel: () => void;
  promptText: string;
}

export const DeepTagsGenerativeAIPromptModal = ({
  promptText,
  onCancel,
}: DeepTagsGenerativeAIPromptModalProps): JSX.Element => {
  const formattedPromptText = addBoldTagsToText(promptText);
  const sanitizedHtmlContent = DOMPurify.sanitize(formattedPromptText);

  return (
    <ConfirmationDialog onCancel={onCancel}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Prompt</ConfirmationDialog.Title>
        <ConfirmationDialog.HeaderCloseButtonWithIcon onCancel={onCancel} />
      </ConfirmationDialog.Header>
      <ContentPromptTextStyled dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }} />
    </ConfirmationDialog>
  );
};
