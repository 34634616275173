import { useCallback, useRef, useState } from 'react';
import { Action } from './DirtyFormConfirmationDialog';

export const useConfirmationDialog = ({ isDirty }: { isDirty: boolean }) => {
  const [showDirtyModal, setShowDirtyModal] = useState(false);

  const currentActionConfigRef = useRef<{
    action: Action;
    cb: <T = unknown>(parameters?: T) => void;
  }>(null);

  const triggerTheModalIfDirtyOrCallback = useCallback(
    (cb: () => void, action: Action) => {
      if (isDirty) {
        setShowDirtyModal(true);
        currentActionConfigRef.current = { action, cb };
      } else {
        cb();
      }
    },
    [setShowDirtyModal, isDirty]
  );

  const closeModal = useCallback(() => setShowDirtyModal(false), [setShowDirtyModal]);

  return {
    closeModal,
    showDirtyModal,
    triggerTheModalIfDirtyOrCallback,
    currentActionConfig: currentActionConfigRef.current,
  };
};
