import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const DateSectionStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const TypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const CircleDividerStyled = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${({ theme }) => theme.palette.custom['gray-10']};
  border-radius: 50%;
`;
