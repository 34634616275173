import styled from '@emotion/styled';
import { Skeleton } from 'src/components-dummy';

export const APISettingsContainerStyled = styled.div`
  max-width: 752px;
  padding-top: 32px;
`;

export const SettingToggleStyled = styled.div`
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  padding: 20px;
`;

export const StyledSkeleton = styled(Skeleton)`
  margin: 10px;
`;
