import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const EmptyListStateStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 400px;
`;

export const TypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-30']};
`;

export const DeepTagReportsListStyled = styled.div`
  margin-top: 40px;
`;
