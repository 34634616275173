import React, { Fragment } from 'react';
import { useFindComponentByType, useFindComponentsByType } from '../hooks';
import { BreadcrumbsContainerStyled, BreadcrumbsSeparatorStyled } from './Breadcrumbs.styles';
import { BreadcrumbCurrent } from './components/BreadcrumbCurrent';
import { BreadcrumbLink, BreadcrumbLinkProps } from './components/BreadcrumbLink';
import { TypographyType, TypographyVariant } from '../Typography';

export interface BreadcrumbsProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export const Breadcrumbs = ({ children, className }: BreadcrumbsProps): JSX.Element => {
  const links = useFindComponentsByType({ type: BreadcrumbLink, children });
  const current = useFindComponentByType({ type: BreadcrumbCurrent, children });
  const totalItems = links.length + (current ? 1 : 0);
  return (
    <BreadcrumbsContainerStyled className={className}>
      {links.map((link, index) => {
        const shouldAddSeparator = index < totalItems - 1;
        const linkProps = link.props as BreadcrumbLinkProps;
        return (
          <Fragment key={`${linkProps.displayName}.${linkProps.href}`}>
            {link}
            {shouldAddSeparator ? (
              <BreadcrumbsSeparatorStyled
                variant={TypographyVariant.ExtraSmallBold}
                type={TypographyType.Heading}
              >
                &gt;
              </BreadcrumbsSeparatorStyled>
            ) : (
              <></>
            )}
          </Fragment>
        );
      })}
      {current}
    </BreadcrumbsContainerStyled>
  );
};

Breadcrumbs.Link = BreadcrumbLink;
Breadcrumbs.Current = BreadcrumbCurrent;
