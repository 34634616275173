import React, { ReactNode, useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Coordinates } from 'src/components-bl/socialDiscovery/types/entities.types';
import { Size } from 'src/components-bl/socialDiscovery/ProductTags/useProductTagsState/types';

const ProductTagContainerStyled = styled.div`
  height: fit-content;
  width: fit-content;
  position: absolute;
  top: 0px;
  left: 0px;
`;

export interface ProductTagContainerProps {
  children: ReactNode;
  position: Coordinates;
  onDragEnd: (coordinates: Coordinates) => void;
  onDragStart: () => void;
  onRender: (size: Size) => void;
}

export const ProductTagContainer: React.FC<ProductTagContainerProps> = ({
  children,
  position,
  onDragStart,
  onDragEnd,
  onRender,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleDragEnd = (_e: DraggableEvent, { x, y }: DraggableData) => {
    onDragEnd({ x, y });
  };

  useEffect(() => {
    if (ref.current) {
      const { width, height } = ref.current.getBoundingClientRect();
      onRender({ width, height });
    }
  }, [ref]);

  return (
    <Draggable bounds='parent' onStart={onDragStart} onStop={handleDragEnd} position={position}>
      <ProductTagContainerStyled ref={ref}>{children}</ProductTagContainerStyled>
    </Draggable>
  );
};
