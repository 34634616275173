import styled from '@emotion/styled';
import { CardItem, Spinner, TagsInput } from 'src/components-dummy';

export const TagsInputStyled = styled(TagsInput)<{ hideInput: boolean }>`
  display: flex;
  flex-wrap: nowrap;

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &:focus,
  &:focus-within {
    border-color: ${({ theme }) => theme.palette.custom['primary-main']} !important;
  }

  input {
    ${({ hideInput }) => {
      if (hideInput) {
        return 'display: none;';
      }
      return '';
    }}

    cursor: pointer;
    pointer-events: none;

    &::placeholder {
      color: ${({ theme, disabled }) =>
        disabled ? theme.palette.custom['gray-20'] : theme.palette.common.black};
    }
  }

  .tag-label {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.custom['gray-80']};
    padding: 2px 6px 2px 8px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    max-width: 70%;
  }

  .additional-items-tag {
    background-color: unset;
  }
`;

export const PopUpContentStyled = styled(CardItem)`
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  border-radius: 8px;
  height: 293px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul {
    max-height: 167px;
    overflow-y: scroll;
  }
`;

export const PopUpContentFooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding-top: 11px;
  margin-top: 11px;
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
`;

export const SpinnerStyled = styled(Spinner)`
  align-self: center;
`;
