import { PublishNotificationBannerGetDataFieldsRequestActionPayload } from './types';
import { dataFieldsService } from 'src/services';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const publishNotificationBannerActions = {
  getDataFields: createAsyncThunk(
    'PublishNotificationBanner/GetDataFields',
    async (
      payload: PublishNotificationBannerGetDataFieldsRequestActionPayload,
      { rejectWithValue }
    ) => {
      try {
        const dataFields = await dataFieldsService.getDataFields(payload);
        return { shopId: payload.shopId, dataFields };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
