import { Collection } from '../../../../Collections/types/Collection';
import { DomainForRendering } from '../../types';

export const collectionsSubDomainGroup: DomainForRendering<Collection> = {
  getCardTitle: ({ newEntity, oldEntity }) => {
    const collection = newEntity ?? oldEntity;
    return `Collection > “${collection?.name}”`;
  },
  fieldsSchema: {},
};
