import styled from '@emotion/styled';
import {
  Icon,
  Popover,
  TableV2,
  Tooltip,
  Typography,
  Accordion,
  ConfirmationDialog,
} from 'src/components-dummy';
import { Card } from 'src/components-dummy/Card';
import { Slider } from 'src/components-dummy/Slider';
import { tableColumns } from './table-columns';

export const TableHeaderRowCellStyled = styled(TableV2.HeadRow)`
  && {
    padding: 12px 24px;
  }
`;

export const TableBodyRowCellStyled = styled(TableV2.BodyRowCell)<{
  cellName: string;
  disabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px;
  user-select: none;
  color: ${({ theme }) => theme.palette.common.black};
  ${({ cellName }) => cellName === tableColumns.priority.accessor && 'max-width: 200px;'}
  &:hover {
    ${({ cellName, disabled }) =>
      cellName === tableColumns.priority.accessor &&
      !disabled &&
      `
        cursor: pointer;
      `}
  }
`;

export const PrioritySliderCaretIconStyled = styled(Icon)<{ isOpened: boolean }>`
  stroke: ${({ theme }) => theme.palette.common.black};

  ${({ isOpened }) => isOpened && 'transform: rotate(180deg);'}
`;

export const PopoverStyled = styled(Popover)`
  .MuiPaper-root {
    border-radius: 8px;
  }
`;

export const PrioritySliderContent = styled(Card)`
  width: 459px;
  height: 144px;
  border-radius: 8px;
  padding: 24px 0;
`;

export const SliderTitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  padding: 0 24px;
`;

export const SliderStyled = styled(Slider)`
  padding-top: 0;

  .MuiSlider-mark {
    display: unset;
    background-color: ${({ theme }) => theme.palette.common.white};
  }

  .MuiSlider-valueLabel,
  .MuiSlider-valueLabelCircle,
  .MuiSlider-valueLabelLabel {
    background-color: ${({ theme }) => theme.palette.common.black};
  }
`;

export const ResetDefaultsTooltipStyled = styled(Tooltip)`
  .syte-popup-content {
    max-width: 260px;
  }
`;

export const ConfirmationDialogContentStyled = styled(ConfirmationDialog.Content)`
  padding-bottom: 30px;
`;

export const AccordionStyled = styled(Accordion)`
  margin-top: 24px;
`;

export const ExpandableTriggerStyled = styled.div`
  display: flex;
`;

export const TriggerTitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const TriggerIconStyled = styled(Icon)<{
  rotate: boolean;
}>`
  margin-left: 4px;
  ${({ rotate }) => rotate && 'transform: rotate(180deg);'}
  * {
    stroke: ${({ theme }) => theme.palette.custom['gray-10']};
  }
`;

export const ExpandedContentStyled = styled.div`
  margin: 8px 0 0 16px;
  overflow-y: auto;
  max-height: 150px;

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.custom['gray-70']};
  }

  ::-webkit-scrollbar-track {
    background: rgba(235, 235, 235, 0.5);
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
`;
