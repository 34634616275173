import React, { RefObject } from 'react';
import { createPortal } from 'react-dom';
import { Button } from 'src/components-dummy';

interface SaveButtonProps {
  isDirty: boolean;
  onSaveChanges: () => void;
  formHeaderRef?: RefObject<HTMLDivElement | null>;
}

export const SaveButton: React.FC<SaveButtonProps> = ({
  isDirty,
  onSaveChanges,
  formHeaderRef,
}) => {
  const formButtons = (
    <Button onClick={onSaveChanges} variant='primary' disabled={!isDirty}>
      Save
    </Button>
  );

  return formHeaderRef?.current ? createPortal(formButtons, formHeaderRef.current) : null;
};
