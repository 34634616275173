import React, { MouseEvent, useCallback } from 'react';
import { Cell, Row } from 'react-table';
import { AvailableIcons, Switch, SwitchSizes, TableV2 } from 'src/components-dummy';
import { RelevancyTuningItem } from 'src/services';
import { tableColumns } from './table-columns';
import {
  PrioritySliderCaretIconStyled,
  TableBodyRowCellStyled,
} from './RelevancyTuningTable.styles';
import { PrioritySliderCell } from './PrioritySliderCell';

interface RelevancyTuningTableRowProps {
  row: Row<RelevancyTuningItem>;
  onChange: (tuningItem: RelevancyTuningItem) => void;
}

export const RelevancyTuningTableRow = ({
  row,
  onChange,
}: RelevancyTuningTableRowProps): JSX.Element => {
  const [prioritySliderAnchorEl, serPrioritySliderAnchorEl] = React.useState<HTMLDivElement | null>(
    null
  );

  const onOpenPrioritySlider = (event: MouseEvent<HTMLDivElement>) => {
    serPrioritySliderAnchorEl(event.currentTarget);
  };

  const onClosePrioritySlider = () => {
    serPrioritySliderAnchorEl(null);
  };

  const onUpdateRowData = useCallback(
    <T extends keyof RelevancyTuningItem>(
      propertyToUpdate: T,
      overrideValue: RelevancyTuningItem[T]
    ) => {
      onChange({
        active: row.original.active,
        dataField: row.original.dataField,
        priority: row.original.priority,
        displayName: row.original.displayName,
        defaultPriority: row.original.defaultPriority,
        [propertyToUpdate]: overrideValue,
      });
    },
    [row, onChange]
  );

  const onSwitchToggle = useCallback(
    (checked: boolean) => {
      onUpdateRowData<'active'>('active', checked);
    },
    [onUpdateRowData]
  );

  const onPriorityChange = useCallback(
    (value: number) => {
      onUpdateRowData<'priority'>('priority', value);
    },
    [onUpdateRowData]
  );

  const getDisplayText = useCallback(
    ({
      cell,
      isActiveField,
    }: {
      cell: Cell<RelevancyTuningItem>;
      isActiveField: boolean;
    }): string => {
      const propName = cell.column.id;

      if (propName !== tableColumns.priority.accessor) {
        return cell.value;
      }

      const isDefault = row.original.priority === row.original.defaultPriority;
      const defaultIndication = isDefault ? ` (default)` : '';

      return isActiveField ? `${cell.value}${defaultIndication}` : '';
    },
    [row]
  );

  return (
    <>
      <PrioritySliderCell
        row={row}
        prioritySliderAnchorEl={prioritySliderAnchorEl}
        onClose={onClosePrioritySlider}
        onCommitChange={onPriorityChange}
      />
      <TableV2.BodyRow {...row.getRowProps()} className='relevancy-tuning-table-row'>
        {row.cells.map(cell => {
          const propName = cell.column.id;
          const isActiveField = row.original.active;

          return (
            <TableBodyRowCellStyled
              {...cell.getCellProps()}
              key={propName}
              width={tableColumns[propName].width}
              minWidth={cell.column.minWidth}
              className='relevancy-tuning-cell'
              data-id={`${row.original.dataField}-${propName}`}
              cellName={propName}
              disabled={!isActiveField}
              onClick={
                propName === tableColumns.priority.accessor && isActiveField
                  ? onOpenPrioritySlider
                  : undefined
              }
            >
              {propName === tableColumns.active.accessor ? (
                <Switch
                  size={SwitchSizes.Small}
                  onChange={onSwitchToggle}
                  checked={isActiveField}
                />
              ) : (
                <>
                  <TableV2.BodyRowCellText>
                    {getDisplayText({ cell, isActiveField })}
                  </TableV2.BodyRowCellText>
                  {propName === tableColumns.priority.accessor && isActiveField && (
                    <PrioritySliderCaretIconStyled
                      name={AvailableIcons.SelectCaret}
                      isOpened={Boolean(prioritySliderAnchorEl)}
                    />
                  )}
                </>
              )}
            </TableBodyRowCellStyled>
          );
        })}
      </TableV2.BodyRow>
    </>
  );
};
