import { UiTemplatesReducerState } from '../app-state-types';
import {
  UiTemplateAction,
  UiTemplateActionTypes,
  UiTemplatesListAction,
  UiTemplatesListActionTypes,
  UiTemplatesShopUrlAction,
  UiTemplatesShopUrlActionTypes,
} from '../components-bl';
import { updateList } from './helpers';

const defaultState: UiTemplatesReducerState = {
  templates: [],
  shopUrl: '',
};

type Actions = UiTemplateAction | UiTemplatesListAction | UiTemplatesShopUrlAction;

export const uiTemplatesReducer = (
  state = defaultState,
  action?: Actions
): UiTemplatesReducerState => {
  switch (action?.type) {
    case UiTemplatesListActionTypes.GetUiTemplatesSuccess:
      return {
        ...state,
        templates: action.payload.templates ?? [],
      };
    case UiTemplateActionTypes.GetUiTemplateSuccess:
    case UiTemplateActionTypes.SaveUiTemplateSuccess:
      return action.payload?.name
        ? {
            ...state,
            templates: updateList(action.payload, state.templates, (a, b) => a.name === b.name),
          }
        : state;
    case UiTemplateActionTypes.DeleteUiTemplateSuccess:
      return {
        ...state,
        templates: action.payload.templates ?? [],
      };
    case UiTemplatesShopUrlActionTypes.SetShopUrl:
      return {
        ...state,
        shopUrl: action.payload.shopUrl,
      };
    default:
      return state;
  }
};
