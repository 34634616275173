import React from 'react';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

interface ChangePrimaryConfirmationDialogProps {
  onCloseDialog: () => void;
  onApproveSubmit: () => void;
}

export const DeepTagsChangeCategoryConfirmation = ({
  onCloseDialog,
  onApproveSubmit,
}: ChangePrimaryConfirmationDialogProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onCloseDialog}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Hold on</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        You are about to change the category and it will affect the rest of the labeling.
        <br />
        Are you sure you want to continue?
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onApproveSubmit}>
            Continue
          </Button>
          <Button variant='outlined' onClick={onCloseDialog}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
