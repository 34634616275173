const separators = /,|\s|\n/;

function parseUrl(input: string): string {
  // eslint-disable-next-line no-new
  new URL(input);

  return input;
}

export function parseUrls(inputString: string): string[] {
  const urlArray = inputString.trim().split(separators);

  const validUrls = urlArray
    .filter(input => input.trim().length > 0)
    .map(url => parseUrl(url.trim()));

  return validUrls;
}
