import styled from '@emotion/styled';

export const LexiconEditCellContainerStyled = styled.div`
  width: 100%;
`;

export const LexiconLabelStyled = styled.div`
  width: 100%;
  display: flex;
`;

export const LexiconTextBoxStyled = styled.div`
  width: 100%;
  display: flex;
`;
