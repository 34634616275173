import { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';
import { v4 as uuid } from 'uuid';
import { RuleGroup } from '../../../../components-bl/Collections/types';

function parseRuleGroupDates(ruleGroupRaw: RuleGroup): RuleGroup {
  const ruleGroup = { ...ruleGroupRaw };
  ['createdAt', 'updatedAt', 'startedAt', 'endedAt'].forEach(key => {
    if (ruleGroup[key as keyof RuleGroup]) {
      ruleGroup[key as keyof RuleGroup] = parseISO(
        ruleGroup[key as keyof RuleGroup] as string
      ) as never;
    }
  });

  return {
    ...ruleGroup,
    rules: ruleGroup.rules.map(rule => ({ ...rule, id: uuid() })),
  };
}

export function mapRuleGroupResponse({ data }: AxiosResponse): Array<RuleGroup> {
  return data.ruleGroups.map((ruleGroup: RuleGroup) => parseRuleGroupDates(ruleGroup));
}
