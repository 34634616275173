import React, { useCallback, useEffect, useState } from 'react';
import {
  EllipsisWithTooltip,
  FiltersRow,
  FilterState,
  FilterVariant,
  MultiSelectOption,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { ICatalogErrorReportFilterOptions, ICatalogUploadErrorsFilters } from 'src/services';
import { isEqual } from 'lodash';
import {
  FiltersWrapperStyled,
  FiltersInnerWrapperStyled,
  FiltersTotalItemsWrapperStyled,
} from 'src/components-bl/Filters/components/FiltersListPage/FiltersListPage.styles';
import { FiltersRowStyled } from './CatalogUploadErrorReportFilters.styles';

const createKeyWithCountText = ({
  name,
  count,
  none_name = 'None',
}: IFilterKeyObject & { none_name?: string }): string => `${!name ? none_name : name} (${count})`;

interface IFilterKeyObject {
  name: string | null;
  count: number;
}

export type CatalogUploadErrorReportFiltersState = {
  search?: FilterState | undefined;
  categories?: FilterState | undefined;
  syteCategories?: FilterState | undefined;
  errorDescriptions?: FilterState | undefined;
  actions?: FilterState | undefined;
};

const initialFiltersState: CatalogUploadErrorReportFiltersState = {
  search: {
    isApplied: true,
    value: undefined,
  },
};

interface CatalogUploadErrorReportFiltersProps {
  filterOptions: ICatalogErrorReportFilterOptions;
  totalProducts: number;
  onChange: (partialFilters: ICatalogUploadErrorsFilters) => void;
}

export const CatalogUploadErrorReportFilters = ({
  onChange,
  filterOptions,
  totalProducts,
}: CatalogUploadErrorReportFiltersProps): JSX.Element => {
  const [filtersState, setFiltersState] =
    useState<CatalogUploadErrorReportFiltersState>(initialFiltersState);

  const filterComponents: JSX.Element[] = [
    <FiltersRow.Filter
      type={FilterVariant.SearchInput}
      key='search'
      uniqueFilterKey='search'
      componentConfig={{ placeholder: 'Search...' }}
    />,
  ];

  /**
   * Categories
   */
  const categoryOptions: MultiSelectOption[] = (filterOptions?.categories || []).map(
    (item: IFilterKeyObject) => {
      return {
        text: createKeyWithCountText({ ...item, none_name: 'No category' }) as string,
        value: item.name || 'null',
      } as MultiSelectOption;
    }
  );

  /**
   * Syte Categories
   */
  const syteCategoryOptions: MultiSelectOption[] = (filterOptions?.syteCategories || []).map(
    (item: IFilterKeyObject) => {
      return {
        text: createKeyWithCountText({ ...item, none_name: 'No category' }) as string,
        value: item.name || 'null',
      } as MultiSelectOption;
    }
  );

  /**
   * Error Description
   */
  const errorDescriptionOptions: MultiSelectOption[] = (filterOptions?.errorDescriptions || []).map(
    (item: IFilterKeyObject) => {
      return {
        text: createKeyWithCountText(item),
        value: item.name || 'null',
      } as MultiSelectOption;
    }
  );

  /**
   * Actions
   */
  const actionsOptions: MultiSelectOption[] = (filterOptions?.actions || []).map(
    (item: IFilterKeyObject) => {
      return {
        text: createKeyWithCountText(item),
        value: item.name || 'null',
      } as MultiSelectOption;
    }
  );

  filterComponents.push(
    <FiltersRow.Filter
      type={FilterVariant.MultiSelect}
      key='syteCategory'
      uniqueFilterKey='syteCategories'
      menuName='Syte Category'
      tagPrefix='Syte Category is'
      componentConfig={{
        labelText: 'Filter by syte categories',
        options: syteCategoryOptions,
        enableSearch: true,
        enableToggleAll: false,
        shouldAlwaysShowSelectionItemsText: true,
        applyText: 'Apply',
      }}
    />
  );

  filterComponents.push(
    <FiltersRow.Filter
      type={FilterVariant.MultiSelect}
      key='category'
      uniqueFilterKey='categories'
      menuName='Category'
      tagPrefix='Category is'
      componentConfig={{
        labelText: 'Filter by categories',
        options: categoryOptions,
        enableSearch: true,
        enableToggleAll: false,
        shouldAlwaysShowSelectionItemsText: true,
        applyText: 'Apply',
      }}
    />
  );

  filterComponents.push(
    <FiltersRow.Filter
      type={FilterVariant.MultiSelect}
      key='errorDescription'
      uniqueFilterKey='errorDescriptions'
      menuName='Error Reason'
      tagPrefix='Error reason is'
      componentConfig={{
        labelText: 'Filter by error reason',
        options: errorDescriptionOptions,
        enableSearch: true,
        enableToggleAll: false,
        shouldAlwaysShowSelectionItemsText: true,
        applyText: 'Apply',
      }}
    />
  );

  filterComponents.push(
    <FiltersRow.Filter
      type={FilterVariant.MultiSelect}
      key='action'
      uniqueFilterKey='actions'
      menuName='Action'
      tagPrefix='Action is'
      componentConfig={{
        labelText: 'Filter by action',
        options: actionsOptions,
        enableSearch: true,
        enableToggleAll: false,
        shouldAlwaysShowSelectionItemsText: true,
        applyText: 'Apply',
      }}
    />
  );

  const onFiltersChange = useCallback(
    (selectedFiltersChanges: CatalogUploadErrorReportFiltersState) => {
      const newStateAppliedFiltersOnly = Object.keys(selectedFiltersChanges).reduce(
        (accumulator: any, filterKey: string) => {
          const field = (selectedFiltersChanges as any)[filterKey as string];

          if (field.isApplied) {
            accumulator[filterKey] = (selectedFiltersChanges as any)[filterKey as string];
          }
          return accumulator;
        },
        {}
      );

      setFiltersState(state => {
        const hasChanged = !isEqual(state, newStateAppliedFiltersOnly);

        if (hasChanged) {
          return { ...newStateAppliedFiltersOnly };
        }
        return state;
      });
    },
    []
  );

  useEffect(() => {
    const filterKeys = Object.keys(filtersState) as (keyof CatalogUploadErrorReportFiltersState)[];

    const selectedFilters = filterKeys.reduce(
      (accumulator: any, filterKey: keyof CatalogUploadErrorReportFiltersState) => {
        const selectedFilter = filtersState[filterKey];

        if (selectedFilter?.value) {
          accumulator[filterKey] = selectedFilter?.value;
        }

        return accumulator;
      },
      {} as CatalogUploadErrorReportFiltersState
    );

    onChange(selectedFilters);
  }, [filtersState]);

  const totalProductsText = `${totalProducts} Results`;

  return (
    <FiltersWrapperStyled>
      <FiltersInnerWrapperStyled>
        <FiltersRowStyled onChange={onFiltersChange} filters={filtersState}>
          {filterComponents}
        </FiltersRowStyled>
        <FiltersTotalItemsWrapperStyled>
          <EllipsisWithTooltip tooltipText={totalProductsText}>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
              {totalProductsText}
            </Typography>
          </EllipsisWithTooltip>
        </FiltersTotalItemsWrapperStyled>
      </FiltersInnerWrapperStyled>
    </FiltersWrapperStyled>
  );
};
