import styled from '@emotion/styled';
import { Button, TextBox } from 'src/components-dummy';

export const TextBoxStyled = styled(TextBox)`
  margin-bottom: 24px;
`;

export const ButtonStyled = styled(Button)`
  margin-left: 5px;
`;
