/* eslint no-param-reassign:0 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { ResponseValue } from '../../types/common.types';
import { PublicPost } from '../../types/entities.types';
import { NotificationType } from '../../../../components-dummy';
import { SocialDiscoveryActionTypes } from '../../../../services/src/service/social-discovery/types';

export enum StateType {
  fetching,
  saving,
  noChanges,
  dirty,
  error,
}

type State = {
  type: StateType;
  publicPosts: PublicPost[];
  editedPosts: PublicPost[];
  message: null | {
    type: NotificationType;
    messageKey: SocialDiscoveryActionTypes;
  };
};

const initialState: State = {
  type: StateType.fetching,
  editedPosts: [],
  publicPosts: [],
  message: null,
};

export const editPostsPageSlice = createSlice({
  name: 'socialDiscovery/editPostsPage',
  initialState,
  reducers: {
    resolvePublicPosts: (
      state: State,
      action: PayloadAction<ResponseValue<{ posts: PublicPost[] }>>
    ) => {
      if (action.payload.error !== null) {
        state.type = StateType.error;
        state.message = {
          type: NotificationType.Error,
          messageKey: SocialDiscoveryActionTypes.GeneralError,
        };
        return;
      }
      state.type = StateType.noChanges;
      const { posts } = action.payload.data;
      state.publicPosts = posts;
    },
    resolveSavedPosts: (state: State, { payload }: PayloadAction<ResponseValue<null>>) => {
      if (payload.error !== null) {
        state.type = StateType.dirty;
        state.message = {
          type: NotificationType.Error,
          messageKey: SocialDiscoveryActionTypes.GeneralError,
        };
        return;
      }
      state.publicPosts = state.editedPosts;
      state.type = StateType.noChanges;
      state.message = {
        type: NotificationType.Success,
        messageKey: SocialDiscoveryActionTypes.SavePublicPosts,
      };
    },
    updatePosts: (state: State, action: PayloadAction<PublicPost[]>) => {
      state.editedPosts = action.payload;
      state.type = isEqual(state.publicPosts, action.payload)
        ? StateType.noChanges
        : StateType.dirty;
    },
    setIsFetching: (state: State) => {
      state.type = StateType.fetching;
    },
    setIsSaving: (state: State) => {
      state.type = StateType.saving;
    },
  },
});
