import React from 'react';
import './StoriesContent.scss';
import { Select, MenuItem, SelectType, Button } from 'src/components-dummy';
import { AvailableIcons } from 'src/components-dummy/Icon/AvailableIcons';
import {
  IEditableStoriesSettings,
  IStoriesSettings,
} from '../../../components-bl/Stories/storiesPage.types';
import { useEditStoriesSettings } from '../../../components-bl/Stories/useEditStoriesSettings/useEditStoriesSettings';
import {
  FormBody,
  FormContainer,
  FormItem,
  FormTitle,
  ItemText,
} from '../../../components-dummy/SettingsForm';
import { StoriesStrategy } from '../../../types';

interface Props {
  settings: IStoriesSettings;
  onChange: (settings: IEditableStoriesSettings) => void;
  onLeave: () => void;
}

export function EditStoriesSettings({ settings, onChange, onLeave }: Props): JSX.Element | null {
  const { state, changeStrategy } = useEditStoriesSettings({
    settings,
    onChange,
  });
  const { storiesStrategy } = state;

  if (!settings.strategies.length) return null;
  return (
    <FormContainer className='edit-stories-container'>
      <FormTitle>Stories content</FormTitle>
      <FormBody divider>
        <FormItem column className='form-item'>
          <ItemText>Select content to apply</ItemText>
          <Select
            className='strategies-dropdown'
            type={SelectType.Menu}
            onChange={e => changeStrategy(e.target.value as StoriesStrategy)}
            value={storiesStrategy?.name}
          >
            {settings.strategies.map(({ name, content }) => (
              <MenuItem key={name} value={name}>
                {content}
              </MenuItem>
            ))}
          </Select>
        </FormItem>
        <FormItem>
          {storiesStrategy?.name === StoriesStrategy.socialContent && (
            <Button variant='primary' onClick={onLeave} endIcon={AvailableIcons.ArrowRight}>
              Click here to organize your Instagram posts
            </Button>
          )}
        </FormItem>
      </FormBody>
    </FormContainer>
  );
}
