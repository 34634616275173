import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { catalogManagerService, CatalogManagerService } from 'src/services';

export const editExistingCatalogFormActions = {
  connectCatalog: createAsyncThunk(
    'ExistingCatalogForm/ConnectCatalog',
    async (
      parameters: Parameters<CatalogManagerService['connectCatalog']>[0],
      { rejectWithValue }
    ) => {
      try {
        const createdCatalog = await catalogManagerService.connectCatalog(parameters);

        return createdCatalog;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateCatalog: createAsyncThunk(
    'ExistingCatalogForm/UpdateCatalog',
    async (
      parameters: Parameters<CatalogManagerService['updateCatalog']>[0],
      { rejectWithValue }
    ) => {
      try {
        const updatedCatalog = await catalogManagerService.updateCatalog(parameters);

        return updatedCatalog;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateIsDirty: createAction<{ isDirty?: boolean }>('ConnectExistingCatalogModal/NotifyIsDirty'),
};
