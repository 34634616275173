import { EditExperimentContainerAction } from 'src/containers';
import { PersonalizationSettingsReducerState } from '../app-state-types';
import {
  PersonalizationActionTypes,
  PersonalizationSettingsAction,
  VariantPanelAction,
} from '../components-bl';
import { PersonalizationFallbackMethod } from '../types/enums/personalization-fallback-method';
import { SortingOrder } from '../types/enums/sorting-order';

const initialState: PersonalizationSettingsReducerState = {
  numberOfResultsToDisplay: 12,
  personalizationFallbackMethod: PersonalizationFallbackMethod.BestSellers,
  useSessionSkusFromPageViewEvent: false,
  shouldRefetch: false,
  addToCart: { active: false },
  personalizationFallbackField: undefined,
  sortingOrder: SortingOrder.Desc,
};

export function personalizationSettingsReducer(
  state: PersonalizationSettingsReducerState = initialState,
  action?: PersonalizationSettingsAction | EditExperimentContainerAction | VariantPanelAction
): PersonalizationSettingsReducerState {
  switch (action?.type) {
    case PersonalizationActionTypes.GetPersonalizationSettingsSuccess: {
      const {
        numberOfResultsToDisplay,
        personalizationFallbackMethod,
        useSessionSkusFromPageViewEvent,
        addToCart,
        personalizationFallbackField,
        sortingOrder,
      } = action.payload;
      return {
        ...state,
        numberOfResultsToDisplay,
        personalizationFallbackMethod,
        useSessionSkusFromPageViewEvent,
        addToCart,
        shouldRefetch: false,
        personalizationFallbackField,
        sortingOrder,
      };
    }
    case PersonalizationActionTypes.GetPersonalizationSettingsRequest:
      return { ...state };
    case PersonalizationActionTypes.GetPersonalizationSettingsError:
      return { ...state };
    case PersonalizationActionTypes.UpdatePersonalizationSettingsSuccess: {
      const {
        numberOfResultsToDisplay,
        personalizationFallbackMethod,
        useSessionSkusFromPageViewEvent,
        addToCart,
        personalizationFallbackField,
        sortingOrder,
      } = action.payload;
      const updatedSettings = {
        ...state,
        numberOfResultsToDisplay,
        personalizationFallbackMethod,
        useSessionSkusFromPageViewEvent,
        addToCart,
        personalizationFallbackField,
        sortingOrder,
      };
      return {
        ...state,
        numberOfResultsToDisplay: updatedSettings.numberOfResultsToDisplay,
        personalizationFallbackMethod: updatedSettings.personalizationFallbackMethod,
        useSessionSkusFromPageViewEvent: updatedSettings.useSessionSkusFromPageViewEvent,
        addToCart: updatedSettings.addToCart,
        personalizationFallbackField: updatedSettings.personalizationFallbackField,
        sortingOrder: updatedSettings.sortingOrder,
      };
    }
    default:
      return state;
  }
}
