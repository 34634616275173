import { Theme, useTheme } from '@emotion/react';
import React from 'react';
import {
  SelectType,
  MenuItem,
  SelectOnChangeEvent,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { DeepTagReportStatus } from 'src/services';
import { DeepTagReportStatusSelectStyled } from './DeepTagReportStatusSelect.styles';

const statusDropDownValues = [
  { value: DeepTagReportStatus.New, text: 'New' },
  { value: DeepTagReportStatus.InReview, text: 'In Review' },
  { value: DeepTagReportStatus.Done, text: 'Done' },
];

function getColorByStatus(theme: Theme, status: DeepTagReportStatus) {
  switch (status) {
    case DeepTagReportStatus.InReview:
      return theme.palette.custom.yellow;
    case DeepTagReportStatus.Done:
      return theme.palette.custom.success;
    case DeepTagReportStatus.New:
    default:
      return theme.palette.custom['primary-main'];
  }
}

interface DeepTagReportStatusSelectProps {
  isSizeBig?: boolean;
  value: DeepTagReportStatus;
  onChange: SelectOnChangeEvent;
}

export const DeepTagReportStatusSelect = ({
  isSizeBig,
  value,
  onChange,
}: DeepTagReportStatusSelectProps): JSX.Element => {
  const theme = useTheme();

  const selectedColor = getColorByStatus(theme, value);

  return (
    <DeepTagReportStatusSelectStyled
      value={value}
      color={selectedColor}
      type={SelectType.Menu}
      isSizeBig={!!isSizeBig}
      onChange={onChange}
    >
      {statusDropDownValues.map((option: { value?: string; text: string }) => (
        <MenuItem key={option.value} value={option.value}>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
            {option.text}
          </Typography>
        </MenuItem>
      ))}
    </DeepTagReportStatusSelectStyled>
  );
};
