import React from 'react';
import { Button, ConfirmationDialog } from 'src/components-dummy';
import { ButtonsContainer } from './CreateFilterPage.styles';

interface CreateFilterConfirmationDialogProps {
  onCreate: () => void;
  onCancel: () => void;
}
export const CreateFilterConfirmationDialog = ({
  onCreate,
  onCancel,
}: CreateFilterConfirmationDialogProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onCancel}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Create Filter</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        Changes will be saved as a draft. To update on your website, publish the shop version at the
        top-left corner of this screen.{' '}
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onCreate}>
            Ok
          </Button>
          <Button variant='tertiary' onClick={onCancel}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
