/* eslint-disable react/no-unescaped-entities */
import { css } from '@emotion/react';
import React, { useMemo } from 'react';
import {
  TypographyType,
  TypographyVariant,
  Box,
  RadioGroup,
  Typography,
} from 'src/components-dummy';
import { CatalogFormSection } from '../CatalogFormSection/CatalogFormSection';
import {
  ErrorLabelStyled,
  ErrorsMessagesWrapperStyled,
  RadioButtonSectionStyled,
  RadioButtonsContainerStyled,
  SubTitleStyled,
} from '../../CatalogForm.styles';
import { CatalogFormDraft, ImportMethodTypesEnum } from '../../CatalogForm.config';
import { CatalogFormSFTPSettings } from './CatalogFormSFTPSettings';
import { CatalogFormWGETSettings } from './CatalogFormWGETSettings';

export interface CatalogFormImportMethodSectionProps {
  onFieldChange: (partialData: Partial<CatalogFormDraft>) => void;
  onNotifyCopyOnClipboard: () => void;
  errors?: any;
  shopName: string;
  selectedImportMethod?: ImportMethodTypesEnum;
  isUsingCustomSftp?: boolean;
  sftpServerSettings?: CatalogFormDraft['sftpServerSettings'];
  wgetServerSettings: CatalogFormDraft['wgetServerSettings'];
  isCustomerHasSFTPCredentials: boolean;
}

export const CatalogFormImportMethodSection = React.memo(
  ({
    onFieldChange,
    errors,
    selectedImportMethod,
    isUsingCustomSftp,
    sftpServerSettings,
    isCustomerHasSFTPCredentials,
    wgetServerSettings,
    onNotifyCopyOnClipboard,
  }: CatalogFormImportMethodSectionProps): JSX.Element => {
    const errorsMessagesList = useMemo(() => {
      return Object.keys(errors).map(errorKey => {
        const error = errors[errorKey];
        return <ErrorLabelStyled key={errorKey}> {error.message} </ErrorLabelStyled>;
      });
    }, [errors]);

    if (!selectedImportMethod) {
      onFieldChange({ selectedImportMethod: ImportMethodTypesEnum.upload_sftp_server });
    }

    return (
      <CatalogFormSection>
        <CatalogFormSection.Header>Import Method</CatalogFormSection.Header>
        {!!Object.keys(errorsMessagesList).length && (
          <ErrorsMessagesWrapperStyled>{errorsMessagesList}</ErrorsMessagesWrapperStyled>
        )}

        <SubTitleStyled
          css={css`
            margin-bottom: 6px;
          `}
          type={TypographyType.Body}
          variant={TypographyVariant.MediumRegular}
        >
          Choose the method you want to use to import your data
        </SubTitleStyled>
        <RadioButtonsContainerStyled>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {/** ********************* SFTP ************************** */}
            <RadioButtonSectionStyled>
              <RadioGroup
                selectedValue={selectedImportMethod}
                onChange={value => onFieldChange({ selectedImportMethod: value })}
                disabled={false}
                key={ImportMethodTypesEnum.upload_sftp_server}
                options={[
                  {
                    value: ImportMethodTypesEnum.upload_sftp_server,
                    label: (
                      <div>
                        <Typography
                          type={TypographyType.Body}
                          variant={TypographyVariant.MediumRegular}
                        >
                          Use SFTP Server
                        </Typography>
                        <SubTitleStyled
                          type={TypographyType.Body}
                          variant={TypographyVariant.SmallMedium}
                        >
                          Use a CSV file to create your feed. Recommended for up to 1,000 products.
                        </SubTitleStyled>
                      </div>
                    ),
                  },
                ]}
              />

              {selectedImportMethod === ImportMethodTypesEnum.upload_sftp_server && (
                <CatalogFormSFTPSettings
                  sftpServerSettings={sftpServerSettings}
                  onFieldChange={onFieldChange}
                  isUsingCustomSftp={!!isUsingCustomSftp}
                  isCustomerHasSFTPCredentials={isCustomerHasSFTPCredentials}
                  onNotifyCopyOnClipboard={onNotifyCopyOnClipboard}
                  errors={errors}
                />
              )}
            </RadioButtonSectionStyled>

            {/** ********************* WGET ************************** */}
            <RadioButtonSectionStyled>
              <RadioGroup
                selectedValue={selectedImportMethod}
                onChange={value => onFieldChange({ selectedImportMethod: value })}
                disabled={false}
                key={ImportMethodTypesEnum.upload_wget_server}
                options={[
                  {
                    value: ImportMethodTypesEnum.upload_wget_server,
                    label: (
                      <div>
                        <Typography
                          type={TypographyType.Body}
                          variant={TypographyVariant.MediumRegular}
                        >
                          Pull Product feed from URL
                        </Typography>

                        <SubTitleStyled
                          type={TypographyType.Body}
                          variant={TypographyVariant.SmallMedium}
                        >
                          Use a CSV file to create your feed. Recommended for up to 1,000 products.
                        </SubTitleStyled>
                      </div>
                    ),
                  },
                ]}
              />

              {selectedImportMethod === ImportMethodTypesEnum.upload_wget_server && (
                <CatalogFormWGETSettings
                  wgetServerSettings={wgetServerSettings}
                  onFieldChange={onFieldChange}
                  errors={errors}
                />
              )}
            </RadioButtonSectionStyled>
          </Box>
        </RadioButtonsContainerStyled>
      </CatalogFormSection>
    );
  }
);
