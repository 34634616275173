import React from 'react';
import {
  AvailableIcons,
  Button,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import GalleryImage from 'src/assets/custom-inspiration-gallery.png';
import {
  ButtonsWrapperStyled,
  EmptyStateStyled,
  ImageStyled,
  SubTitleStyled,
  TextWrapper,
} from './EmptyState.styles';
import { AddImagesButtonStyled } from '../../GalleryPreview.styles';

interface EmptyStateProps {
  hide: boolean;
  navigateToAddImages: () => void;
}

export const EmptyState = ({ hide, navigateToAddImages }: EmptyStateProps): JSX.Element => {
  const onLearnMoreClick = () => {
    console.log('onLearnMoreClick todo');
  };

  return (
    <EmptyStateStyled hide={hide}>
      <ImageStyled src={GalleryImage} alt='Custom Inspirational Gallery' />
      <TextWrapper>
        <Typography variant={TypographyVariant.LargeBold} type={TypographyType.Body}>
          Create Your First Galleries
        </Typography>

        <SubTitleStyled variant={TypographyVariant.MediumRegular} type={TypographyType.Paragraph}>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry.{' '}
        </SubTitleStyled>
        <ButtonsWrapperStyled>
          <Button onClick={onLearnMoreClick} variant='secondary'>
            Learn More
          </Button>
          <AddImagesButtonStyled
            onClick={navigateToAddImages}
            variant='primary'
            startIcon={AvailableIcons.Plus}
          >
            Add Images
          </AddImagesButtonStyled>
        </ButtonsWrapperStyled>
      </TextWrapper>
    </EmptyStateStyled>
  );
};
