import React from 'react';
import {
  SearchInput,
  MenuItem,
  Select,
  SelectType,
  SelectOnChangeEvent,
} from 'src/components-dummy';
import { ExperimentList } from '../ExperimentList';
import { useExperimentListSection } from './useExperimentListSection';
import { ExperimentListSectionProps } from './types';
import { sortDropdownOptions, SelectExperimentStatus } from './constants';
import './ExperimentListSection.scss';

export const ExperimentListSection = ({
  experiments,
  shopId,
  dispatch,
  selectedExperimentId,
  experimentFormRoute,
  runningExperimentId,
}: ExperimentListSectionProps): JSX.Element => {
  const {
    filteredExperiments,
    searchInputValue,
    onSearchInputChange,
    statusDropdownValue,
    onStatusDropdownChange,
  } = useExperimentListSection({ experiments });

  const handleStatusChange: SelectOnChangeEvent = event =>
    onStatusDropdownChange(event.target.value as SelectExperimentStatus);

  return (
    <div className='syte-experiment-list-section'>
      <div className='syte-experiment-list-section-header'>
        <Select
          type={SelectType.Menu}
          value={statusDropdownValue}
          key={statusDropdownValue}
          onChange={handleStatusChange}
          className='status-dropdown'
        >
          {sortDropdownOptions.map((option: { value?: string; text: string }) => (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </Select>
        <SearchInput
          value={searchInputValue}
          onChange={onSearchInputChange}
          placeholder='Search by name...'
          className='search-input'
        />
      </div>
      <ExperimentList
        experiments={filteredExperiments}
        shopId={shopId}
        dispatch={dispatch}
        selectedExperimentId={selectedExperimentId}
        runningExperimentId={runningExperimentId}
        searchQuery={searchInputValue}
        experimentFormRoute={experimentFormRoute}
      />
    </div>
  );
};
