import { AxiosResponse } from 'axios';
import { ApiServiceBase } from '../api-service-base';
import { AugmentedSearchGeneralSettings, SyteProductType, RelevancyTuningItem } from '../types';
import { AugmentedSearchMapper } from './augmented-search.mapper';

export class AugmentedSearchService extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl(shopId: number): string {
    return `${this.serviceBaseUri}/${shopId}/syte-products/${SyteProductType.AugmentedSearch}`;
  }

  async getGeneralSettings({
    shopId,
  }: {
    shopId: number;
  }): Promise<AugmentedSearchGeneralSettings> {
    const url = `${this.getUrl(shopId)}/general-settings`;

    const response: AxiosResponse<AugmentedSearchGeneralSettings> = await this.httpService.get({
      url,
    });

    return response.data;
  }

  async updateGeneralSettings({
    shopId,
    generalSettings,
  }: {
    shopId: number;
    generalSettings: AugmentedSearchGeneralSettings;
  }): Promise<AugmentedSearchGeneralSettings> {
    const url = `${this.getUrl(shopId)}/general-settings`;

    const response: AxiosResponse<AugmentedSearchGeneralSettings> = await this.httpService.patch({
      url,
      data: generalSettings,
    });

    return response.data;
  }

  async getRelevancyTuning({ shopId }: { shopId: number }): Promise<RelevancyTuningItem[]> {
    const url = `${this.getUrl(shopId)}/relevancy-tuning`;

    const response: AxiosResponse<{ tunings: RelevancyTuningItem[] }> = await this.httpService.get({
      url,
    });

    return AugmentedSearchMapper.mapRelevancyTuning(response);
  }

  async updateRelevancyTuning({
    shopId,
    relevancyTuning,
  }: {
    shopId: number;
    relevancyTuning: RelevancyTuningItem[];
  }): Promise<RelevancyTuningItem[]> {
    const url = `${this.getUrl(shopId)}/relevancy-tuning`;

    const response: AxiosResponse<{
      tunings: RelevancyTuningItem[];
    }> = await this.httpService.patch({
      url,
      data: {
        tunings: relevancyTuning.map(({ dataField, priority, active }) => ({
          dataField,
          priority,
          active,
        })),
      },
    });

    return AugmentedSearchMapper.mapRelevancyTuning(response);
  }
}

export const augmentedSearchService = new AugmentedSearchService();
