import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { EllipsisWithTooltip } from 'src/components-dummy/EllipsisWithToolTip';
import { isString } from 'lodash';
import { LexiconTagField } from 'src/services';
import { Dispatch } from 'src/components-bl';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy/Typography';

import { LexiconTagType } from 'src/services/src/service/lexicon/types';
import {
  LexiconEditCellContainerStyled,
  LexiconLabelStyled,
  LexiconTextBoxStyled,
} from './LexiconDataTextEdit.styles';
import { LexiconItemEditAutoComplete } from './LexiconItemAutoComplete';
import { LexiconStatusText } from './LexiconStatusText';

const ESCAPE = [27, 'Escape'];
const ENTER = [13, 'Enter'];
const NUMPADENTER = [13, 'NumpadEnter'];

export interface LexiconDataEditAPIRef {
  reset: () => void;
}

export interface LexiconDataEditProps {
  tagFieldName: LexiconTagField;
  tagFieldValue: string;
  isEditable: boolean;
  hasChanged: boolean;
  shopId: number;
  locale: string;
  tagType: LexiconTagType;
  dispatch: Dispatch;
  onChange: (args: { tagFieldName: LexiconTagField; tagFieldNewValue: string }) => void;
  onEditModeChange: (args: { ref: LexiconDataEditAPIRef | null; isInEditMode: boolean }) => void;
}

export function validateLexiconTranslation(translation: string): boolean {
  const isValid = isString(translation) && translation.trim().length > 0;
  return isValid;
}

export const LexiconDataTextEdit = ({
  tagFieldName,
  tagFieldValue: tagFieldValueProp,
  isEditable,
  hasChanged,
  shopId,
  locale,
  tagType,
  dispatch,
  onChange,
  onEditModeChange,
}: LexiconDataEditProps): JSX.Element => {
  const tagFieldOriginalValue = tagFieldValueProp;

  const [tagFieldValue, setTagFieldValue] = useState<string>(tagFieldOriginalValue);
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

  const isValid = useMemo(() => {
    return validateLexiconTranslation(tagFieldValue);
  }, [tagFieldValue]);

  const reset = useCallback(() => {
    setTagFieldValue(tagFieldOriginalValue);
  }, [setTagFieldValue]);

  const apiRef = useMemo(() => ({ reset }), [reset]);

  useEffect(() => {
    if (isInEditMode) {
      (inputRef?.current as HTMLInputElement)?.focus();
    }
  }, [isInEditMode]);

  const updateEditMode = ({ isEdit }: { isEdit: boolean }) => {
    setIsInEditMode(isEdit);
    onEditModeChange({ ref: apiRef as any, isInEditMode: isEdit });
  };

  const onBlur = useCallback(() => {
    if (!isValid) {
      setTagFieldValue(tagFieldOriginalValue);
      updateEditMode({ isEdit: false });
      return;
    }

    /* Update value when value is not empty */
    if (tagFieldValue && tagFieldValue !== tagFieldOriginalValue) {
      setTagFieldValue(tagFieldValue);
      onChange({ tagFieldName, tagFieldNewValue: tagFieldValue });
    }

    updateEditMode({ isEdit: false });
  }, [tagFieldValue, setTagFieldValue, tagFieldOriginalValue, onChange, isValid]);

  const onKeyDown = useCallback(
    ({ code }: { code: string }) => {
      if (!inputRef?.current) {
        return;
      }

      if (ESCAPE.includes(code)) {
        setTagFieldValue(tagFieldOriginalValue);
        updateEditMode({ isEdit: false });
      }

      if (ENTER.includes(code) || NUMPADENTER.includes(code)) {
        inputRef?.current?.blur();
      }
    },
    [setTagFieldValue, updateEditMode]
  );

  useEffect(() => {
    setTagFieldValue(tagFieldOriginalValue);
  }, [tagFieldOriginalValue]);

  const onChangeHandler = useCallback((newValue: string) => {
    setTagFieldValue(newValue);
  }, []);

  useEffect(() => {
    if (isInEditMode) {
      document.addEventListener('keydown', onKeyDown);
    } else document.removeEventListener('keydown', onKeyDown);

    return () => document.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown, isInEditMode]);

  const onEnterEditMode = () => {
    if (isEditable) {
      updateEditMode({ isEdit: true });
    }
  };

  return (
    <LexiconEditCellContainerStyled>
      {!isInEditMode ? (
        <LexiconLabelStyled onClick={onEnterEditMode}>
          <EllipsisWithTooltip tooltipPosition='top center' tooltipText={tagFieldValue as string}>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              {tagFieldValue}
            </Typography>
          </EllipsisWithTooltip>
          {hasChanged && <LexiconStatusText status='edited' />}
        </LexiconLabelStyled>
      ) : (
        <LexiconTextBoxStyled>
          <LexiconItemEditAutoComplete
            selectedValue={tagFieldValue}
            onChange={onChangeHandler}
            dispatch={dispatch}
            tagField={tagFieldName}
            tagType={tagType}
            shopId={shopId}
            locale={locale}
            inputRef={inputRef}
            error={!isValid}
            onBlur={onBlur}
            paperProps={{
              sx: {
                width: 'fit-content',
                minWidth: 200,
                maxWidth: 400,
              },
            }}
          />
        </LexiconTextBoxStyled>
      )}
    </LexiconEditCellContainerStyled>
  );
};
