import { ShopDataField } from 'src/services/src/service/types';
import {
  EditableFilterItem,
  FilterStatusPropertyName,
  FilterType,
  OnChangePayload,
  TextTypeDisplayOptions,
  NumericTypeDisplayOptions,
  DisplayType,
} from '../types';
import { determineFilterStatus } from './add-item-utils';
import { dataSourceChangeHandler } from './on-data-source-change';

const mapDisplayTypeToFilterType: Record<string, FilterType> = {
  [TextTypeDisplayOptions.list]: FilterType.text,
  [TextTypeDisplayOptions.tags]: FilterType.text,
  [TextTypeDisplayOptions.swatches]: FilterType.text,
  [NumericTypeDisplayOptions.slider]: FilterType.range,
  [NumericTypeDisplayOptions.rangeGroups]: FilterType.buckets,
};

function getChangeDependencies(
  item: EditableFilterItem,
  { changedProperty, newValue }: OnChangePayload
): EditableFilterItem {
  switch (changedProperty) {
    case 'displayType':
      return {
        ...item,
        [changedProperty]: newValue as DisplayType,
        type: mapDisplayTypeToFilterType[String(newValue)],
      };
    case 'dataSource':
      return dataSourceChangeHandler(item, newValue as ShopDataField);
    case 'enabled': {
      const updatedItem = {
        ...item,
        [changedProperty]: newValue as boolean,
      };
      return {
        ...updatedItem,
        [FilterStatusPropertyName]: determineFilterStatus(updatedItem),
      };
    }
    default:
      return {
        ...item,
        [changedProperty]: newValue,
      };
  }
}
export function onItemChange(
  listDraft: EditableFilterItem[],
  itemKey: string,
  changePayload: OnChangePayload
): { updatedList: EditableFilterItem[]; updatedItem?: EditableFilterItem } {
  const updatedList = [...listDraft];
  const itemPrevState = listDraft.find(listItem => listItem.key === itemKey);
  if (!itemPrevState) return { updatedList: listDraft };
  const updatedItem = getChangeDependencies(itemPrevState, changePayload);
  const itemToUpdatePosition = listDraft.indexOf(itemPrevState);
  updatedList[itemToUpdatePosition] = updatedItem;
  return { updatedList, updatedItem };
}
