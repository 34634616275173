import { RecentlyViewedSettingsTypes } from 'src/services';
import { EntityDiffSchema } from '../../types';
import { diffValueDisplayHelpers } from '../value-helpers';

type RecentlyViewedDiffSchema = RecentlyViewedSettingsTypes.RecentlyViewedSettings;

export const recentlyViewedDiffSchema: EntityDiffSchema<
  RecentlyViewedDiffSchema,
  RecentlyViewedSettingsTypes.RecentlyViewedSettings
> = {
  showPreviouslyPurchasedItems: {
    displayName: 'Show Previously Purchased Items',
  },
  showOutOfStockItems: {
    displayName: 'Show Out Of Stock Items',
  },
  numberOfResultsToDisplay: {
    displayName: 'Number Of Results To Display',
  },
  numberOfRecentDaysToDisplay: {
    displayName: 'Number Of Recent Days To Display',
  },
  addToCart: {
    displayName: 'Add To Cart',
    getValue({ entity }: { entity: RecentlyViewedSettingsTypes.RecentlyViewedSettings }): string {
      return entity?.addToCart?.active ? 'true' : diffValueDisplayHelpers.DEFAULT_EMPTY_VALUE;
    },
  },
};
