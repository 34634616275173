/* --= Action Types =-- */
import { BoughtTogetherFallbackMethod } from 'src/types/enums/bought-together-fallback-method';
import { SortingOrder } from 'src/types/enums/sorting-order';
import { AddMultipleToCart } from '../../../app-state-types/reducer-state-types/add-to-cart';
import { FieldType, ShopDataField } from '../../../services/src/service/types';

export enum BoughtTogetherActionTypes {
  GetBoughtTogetherSettingsRequest = 'BoughtTogether/GetBoughtTogetherSettingsRequest',
  GetBoughtTogetherSettingsSuccess = 'BoughtTogether/GetBoughtTogetherSettingsSuccessAction',
  GetBoughtTogetherSettingsError = 'BoughtTogether/GetBoughtTogetherSettingsError',
  UpdateBoughtTogetherSettingsRequest = 'BoughtTogether/UpdateBoughtTogetherSettingsRequest',
  UpdateBoughtTogetherSettingsSuccess = 'BoughtTogether/UpdateBoughtTogetherSettingsSuccess',
  UpdateBoughtTogetherSettingsError = 'BoughtTogether/UpdateBoughtTogetherSettingsError',
  NotifyBoughtTogetherSettingsIsDirty = 'BoughtTogether/NotifyIsDirty',
  PartialBoughtTogetherSettingsUpdate = 'BoughtTogether/PartialBoughtTogetherSettingsUpdate',
  NavigateTo = 'BoughtTogether/NavigateTo',
  GetDataFieldsRequest = 'BoughtTogether/GetDataFieldsRequest',
  GetDataFieldsSuccess = 'BoughtTogether/GetDataFieldsSuccess',
  GetDataFieldsError = 'BoughtTogether/GetDataFieldsError',
}

export interface UpdateBoughtTogetherSettingsPayload {
  shopId: number;
  variantId?: string;
  numberOfResultsToDisplay: number;
  modelType: string;
  boughtTogetherFallbackMethod: BoughtTogetherFallbackMethod;
  addToCart: AddMultipleToCart;
  boughtTogetherFallbackField?: string;
  sortingOrder?: SortingOrder;
}

export interface GetBoughtTogetherSettingsArgs {
  shopId: number;
  variantId?: string;
}

export interface GetBoughtTogetherDataFieldsArgs {
  shopId: number;
  fieldType: FieldType;
}

export interface GetBoughtTogetherSettingsRequestPayload {
  shopId: number;
}

export interface GetBoughtTogetherSettingsSuccessPayload {
  shopId: number;
  numberOfResultsToDisplay: number;
  modelType: string;
  boughtTogetherFallbackMethod: BoughtTogetherFallbackMethod;
  addToCart: AddMultipleToCart;
  boughtTogetherFallbackField?: string;
  sortingOrder?: SortingOrder;
}

export interface GetBoughtTogetherSettingsErrorPayload {
  error: unknown;
}

export interface UpdateBoughtTogetherSettingsRequestPayload {
  shopId: number;
  numberOfResultsToDisplay: number;
  modelType: string;
  boughtTogetherFallbackMethod: BoughtTogetherFallbackMethod;
  addToCart: AddMultipleToCart;
  boughtTogetherFallbackField?: string;
  sortingOrder?: SortingOrder;
}

export interface UpdateBoughtTogetherSettingsSuccessPayload {
  shopId: number;
  numberOfResultsToDisplay: number;
  modelType: string;
  boughtTogetherFallbackMethod: BoughtTogetherFallbackMethod;
  addToCart: AddMultipleToCart;
  boughtTogetherFallbackField?: string;
  sortingOrder?: SortingOrder;
}

export interface UpdateBoughtTogetherSettingsErrorPayload {
  error: unknown;
}

export interface NotifyBoughtTogetherSettingsIsDirtyActionPayload {
  isDirty: boolean;
}

export interface GetBoughtTogetherDataFieldsRequestPayload {
  shopId: number;
}

export interface GetBoughtTogetherDataFieldsSuccessPayload {
  shopId: number;
  dataFields: ShopDataField[];
}

export interface GetBoughtTogetherDataFieldsErrorPayload {
  error: unknown;
}

/* --= Action Interfaces =-- */

export interface GetBoughtTogetherSettingsRequestAction {
  type: typeof BoughtTogetherActionTypes.GetBoughtTogetherSettingsRequest;
  payload: GetBoughtTogetherSettingsRequestPayload;
}

export interface GetBoughtTogetherSettingsSuccessAction {
  type: typeof BoughtTogetherActionTypes.GetBoughtTogetherSettingsSuccess;
  payload: GetBoughtTogetherSettingsSuccessPayload;
}

export interface GetBoughtTogetherSettingsErrorAction {
  type: typeof BoughtTogetherActionTypes.GetBoughtTogetherSettingsError;
  payload: GetBoughtTogetherSettingsErrorPayload;
}

export interface UpdateBoughtTogetherSettingsRequestAction {
  type: typeof BoughtTogetherActionTypes.UpdateBoughtTogetherSettingsRequest;
  payload: UpdateBoughtTogetherSettingsRequestPayload;
}

export interface UpdateBoughtTogetherSettingsSuccessAction {
  type: typeof BoughtTogetherActionTypes.UpdateBoughtTogetherSettingsSuccess;
  payload: UpdateBoughtTogetherSettingsSuccessPayload;
}

export interface UpdateBoughtTogetherSettingsErrorAction {
  type: typeof BoughtTogetherActionTypes.UpdateBoughtTogetherSettingsError;
  payload: UpdateBoughtTogetherSettingsErrorPayload;
}

export interface NotifyBoughtTogetherSettingsIsDirtyAction {
  type: typeof BoughtTogetherActionTypes.NotifyBoughtTogetherSettingsIsDirty;
  payload: NotifyBoughtTogetherSettingsIsDirtyActionPayload;
}

export interface GetBoughtTogetherDataFieldsRequestAction {
  type: typeof BoughtTogetherActionTypes.GetDataFieldsRequest;
  payload: GetBoughtTogetherDataFieldsRequestPayload;
}

export interface GetBoughtTogetherDataFieldsSuccessAction {
  type: typeof BoughtTogetherActionTypes.GetDataFieldsSuccess;
  payload: GetBoughtTogetherDataFieldsSuccessPayload;
}

export interface GetBoughtTogetherDataFieldsErrorAction {
  type: typeof BoughtTogetherActionTypes.GetDataFieldsError;
  payload: GetBoughtTogetherDataFieldsErrorPayload;
}

export type BoughtTogetherSettingsAction =
  | GetBoughtTogetherSettingsRequestAction
  | GetBoughtTogetherSettingsSuccessAction
  | GetBoughtTogetherSettingsErrorAction
  | UpdateBoughtTogetherSettingsRequestAction
  | UpdateBoughtTogetherSettingsSuccessAction
  | UpdateBoughtTogetherSettingsErrorAction
  | NotifyBoughtTogetherSettingsIsDirtyAction
  | GetBoughtTogetherDataFieldsRequestAction
  | GetBoughtTogetherDataFieldsSuccessAction
  | GetBoughtTogetherDataFieldsErrorAction;
