import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { Page } from '../../components-dummy';
import {
  ShopperExperiencesHeader,
  ShopperExperiencesContent,
} from '../../components-bl/ShopperExperiences';

import { mapExperiencesWithRoutes } from '../../services/src/service/shopper-experiences/mapper';
import { useAppSelector } from '../../hooks';
import { ShopperExperiencesProps } from './types';
import { shopperExperiencesActionsMethods } from '../../components-bl/ShopperExperiences/Actions';

export const ShopperExperiences: React.FC<ShopperExperiencesProps> = ({
  enabledShopFeatures,
  permittedRouteMap,
  shopId,
}) => {
  const dispatch = useDispatch();
  const experiences = useAppSelector(state => state.shopperExperiences.experiences);

  const shopperExperiences = useMemo(() => {
    const mappedExperiences = mapExperiencesWithRoutes(experiences);
    return mappedExperiences.filter(experience => enabledShopFeatures.has(experience.feature));
  }, [experiences, shopId, enabledShopFeatures]);

  useEffect(() => {
    if (shopId) {
      dispatch(shopperExperiencesActionsMethods.getShopperExperiences({ shopId }));
    }
  }, [shopId]);

  return (
    <Page className='ShopperExperiencesHeader'>
      <Page.Header>
        <ShopperExperiencesHeader
          permittedRouteMap={permittedRouteMap}
          dispatch={dispatch}
          shopId={shopId}
        />
      </Page.Header>
      <Page.Content className='shopper-experiences'>
        <ShopperExperiencesContent shopperExperiences={shopperExperiences} />
      </Page.Content>
    </Page>
  );
};
