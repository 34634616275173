import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { MerchandisingRules, MerchandisingRuleTypes } from 'src/services';
import {
  CreateRuleArguments,
  DeleteRuleArguments,
  UpdateRuleArguments,
} from 'src/services/src/service/merchandising-rules/types';

const merchandisingRulesService = new MerchandisingRules();

export const MerchandisingRulesListActions = {
  updateRule: createAsyncThunk(
    'MerchandisingRulesListActions/UpdateRule',
    async ({ shopId, rule, variantId }: UpdateRuleArguments, { rejectWithValue }) => {
      try {
        const merchandisingRule = await merchandisingRulesService.update({
          shopId,
          rule,
          variantId,
        });

        return { merchandisingRule };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  createRule: createAsyncThunk(
    'MerchandisingRulesListActions/CreateRule',
    async ({ shopId, rule, variantId }: CreateRuleArguments, { rejectWithValue }) => {
      try {
        const merchandisingRule = await merchandisingRulesService.create({
          shopId,
          rule,
          variantId,
        });

        return { merchandisingRule };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  deleteRule: createAsyncThunk(
    'MerchandisingRulesListActions/DeleteRule',
    async ({ shopId, ruleId, variantId }: DeleteRuleArguments, { rejectWithValue }) => {
      try {
        await merchandisingRulesService.delete({
          shopId,
          ruleId,
          variantId,
        });

        return { ruleId };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  navigateToRule: createAction<{ navigateTo: string }>('MerchandisingRulesListActions/navigateTo'),
  setDuplicateRuleDraft: createAction<{
    rule: MerchandisingRuleTypes.MerchandiseRule;
  }>('MerchandisingRulesListActions/SetDuplicateRuleDraft'),
};
