import { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'src/app-state-types';
import { baseAppRouteMap } from '../../app-routes';
import { UserTypes } from '../../services';
import { rootContainerActions } from '../Actions';

interface UseInitAppArguments {
  user: UserTypes.User;
}

export const useInitApp = ({ user }: UseInitAppArguments): void => {
  const location = useLocation();

  const dispatch = useDispatch();
  const accountId = useSelector((state: RootReducerState) => state.account.current?.accountId);
  const firstRoute = useSelector((state: RootReducerState) => state.global.firstRoute);

  const result = matchPath<{ shopId?: string }>(
    firstRoute || location.pathname,
    baseAppRouteMap.shopRoutes.path
  );

  useEffect(() => {
    // shopId can be invalid / non number
    const isShopRoute = !!result?.params.shopId;
    dispatch(rootContainerActions.initApp({ user, accountId, isShopRoute }));
  }, []);
};
