import React from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { useAppSelector } from 'src/hooks';
import { ShopApiKeysSettingsModal } from 'src/components-bl/ApiKeysSettings/ShopApiKeysSettingsModal';
import { shopApiKeysSettingsModalContainerActions } from './Actions/ShopApiKeysSettingsModalContainerActions';

export const ShopApiKeysSettingsModalContainer = ({
  permittedRouteMap,
}: RoutedComponentProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const { apiKeys } = useAppSelector(state => state.apiKeys);

  const onModalClose = () => {
    if (permittedRouteMap.shopSettingsGeneral && shopId) {
      dispatch(
        shopApiKeysSettingsModalContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.shopSettingsGeneral.path, {
            shopId,
          }),
        })
      );
    }
  };

  return (
    <ShopApiKeysSettingsModal
      onModalClose={onModalClose}
      shopId={shopId}
      dispatch={dispatch}
      apiKeys={apiKeys}
    />
  );
};
