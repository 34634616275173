import React, { useCallback } from 'react';
import { ShopTypes } from 'src/services';
import {
  Switch,
  SwitchSizes,
  useFindComponentByType,
  MenuItem,
  SelectType,
  Typography,
  TypographyVariant,
  TypographyType,
} from 'src/components-dummy';
import { UserRoles } from 'src/services/src/service/types/users';
import { ShopFeatureToggleWithPermittedRoles } from 'src/services/src/service/types/shops';
import { PermissionsDropDownStyled, SwitchDropDownGroupStyled } from './FeatureToggle.styles';

const Title = ({
  children,
  className,
}: {
  children: JSX.Element | JSX.Element[];
  className?: string;
}): JSX.Element => <div className={className}>{children}</div>;

const PermissionsDropDown = ({
  featureTogglesDraft,
  onChange,
  featureKey,
  disabled = false,
}: {
  featureTogglesDraft: ShopTypes.ShopFeatureToggles | undefined;
  onChange: (
    featureKey: keyof ShopTypes.ShopFeatureToggles,
    updateData: ShopFeatureToggleWithPermittedRoles
  ) => void;
  featureKey: keyof ShopTypes.ShopFeatureToggles;
  disabled: boolean;
}): JSX.Element => {
  const onPermissionChanged = useCallback(
    event => {
      const selectedRole = event.target.value;
      onChange(featureKey, {
        permittedRoles:
          selectedRole === UserRoles.SyteAdmin
            ? [UserRoles.SyteAdmin]
            : [UserRoles.SyteAdmin, UserRoles.ClientAdmin],
        enabled: Boolean(featureTogglesDraft?.[featureKey]?.enabled),
      });
    },
    [featureKey, onChange, featureTogglesDraft]
  );

  const permittedRoles = (featureTogglesDraft?.[featureKey] as ShopFeatureToggleWithPermittedRoles)
    ?.permittedRoles;

  return (
    <PermissionsDropDownStyled
      type={SelectType.Primary}
      value={
        permittedRoles?.includes(UserRoles.ClientAdmin)
          ? UserRoles.ClientAdmin
          : UserRoles.SyteAdmin
      }
      onChange={onPermissionChanged}
      disabled={disabled}
    >
      <MenuItem value={UserRoles.ClientAdmin}>
        <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Body}>
          All
        </Typography>
      </MenuItem>
      <MenuItem value={UserRoles.SyteAdmin}>
        <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Body}>
          Syte Employees Only
        </Typography>
      </MenuItem>
    </PermissionsDropDownStyled>
  );
};

export interface FeatureToggleArgs {
  children?: JSX.Element | JSX.Element[];
  featureTogglesDraft: ShopTypes.ShopFeatureToggles | undefined;
  featureKey: keyof ShopTypes.ShopFeatureToggles;
  onChange: (
    featureKey: keyof ShopTypes.ShopFeatureToggles,
    updateData: ShopFeatureToggleWithPermittedRoles
  ) => void;
  className: string;
  disabled: boolean;
}

export const FeatureToggle = ({
  featureTogglesDraft,
  featureKey,
  onChange,
  children,
  className,
  disabled,
}: FeatureToggleArgs): JSX.Element => {
  const titleSection = useFindComponentByType({ type: Title, children });
  const dropDownSection = useFindComponentByType({ type: PermissionsDropDown, children });
  const featureData = featureTogglesDraft?.[featureKey];

  const onToggle = useCallback(
    (enabled: boolean) => onChange(featureKey, { ...featureData, enabled }),
    [featureKey, onChange, featureData]
  );

  return (
    <div className={className}>
      {titleSection}
      <SwitchDropDownGroupStyled>
        {dropDownSection}
        <Switch
          size={SwitchSizes.Large}
          onChange={onToggle}
          checked={Boolean(featureData?.enabled)}
          disabled={disabled}
        />
      </SwitchDropDownGroupStyled>
    </div>
  );
};

FeatureToggle.Title = Title;
FeatureToggle.PermissionsDropDown = PermissionsDropDown;
