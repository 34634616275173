import { Dispatch, SetStateAction, useEffect, useMemo, useRef } from 'react';
import { debounce, omit } from 'lodash';
import { FormData } from './CatalogExplorerFilterAutoComplete.types';
import { CatalogAvailableFilterOptions } from 'src/services/src/service/types/catalog-manager';
import { CatalogManagerService } from 'src/services/src/service/catalog-manager';
import { isAbortError } from 'src/utils/error-helpers';
import { buildSelectedOptionObject } from './CatalogExplorerFilterAutoComplete.helpers';

const catalogManagerService = new CatalogManagerService();

interface UseManageFilterOptions {
  shopId: number;
  catalogName: string;
  formData: FormData;
  field: string;
  mainSearchFilterTerm?: string;
  filters: Record<string, string[] | number[]>;
  setFormData: Dispatch<SetStateAction<FormData>>;
}

export const useManageFilterOptions = ({
  shopId,
  catalogName,
  formData,
  filters,
  mainSearchFilterTerm,
  field,
  setFormData,
}: UseManageFilterOptions) => {
  const fetchValuesCancellationRef = useRef(null as AbortController | null);

  const cancelFetchValues = () => {
    if (!fetchValuesCancellationRef.current) {
      return;
    }

    try {
      fetchValuesCancellationRef.current.abort();
    } catch (error) {
      console.error(error);
    } finally {
      fetchValuesCancellationRef.current = null;
    }
  };

  const debouncedFetchData = useMemo(() => {
    return debounce(async () => {
      cancelFetchValues();
      const abortController = new AbortController();
      fetchValuesCancellationRef.current = abortController;

      const filtersWithoutCurrentField = omit(filters, [field]);
      const filtersRequestArg = {
        ...filtersWithoutCurrentField,
        ...(formData.searchTerm ? { [field]: [(formData.searchTerm || '').trim()] } : {}),
      };

      try {
        const data: CatalogAvailableFilterOptions =
          await catalogManagerService.getCatalogExplorerAvailableFilterOptions({
            shopId,
            catalogName,
            field,
            searchAfter: formData.searchAfter,
            limit: 25,
            filters: filtersRequestArg,
            mainSearchFilterTerm,
            cancellationSignal: abortController.signal,
          });

        const newOptionsState = data.options.map(option => {
          return buildSelectedOptionObject({
            value: option.value,
            total: option.total,
          });
        });

        setFormData(prev => ({
          ...prev,
          searchAfter: data.afterKey,
          totalItems: data.totalItems,
          options: [...prev.options, ...newOptionsState],
          isLoading: false,
        }));
      } catch (error) {
        if (!isAbortError(error)) {
          console.error(error);

          setFormData(prev => ({
            ...prev,
            searchAfter: undefined,
            options: [],
            isLoading: false,
          }));
        }
      }
    }, 500);
  }, [
    shopId,
    catalogName,
    field,
    filters,
    mainSearchFilterTerm,
    formData.searchTerm,
    formData.searchAfter,
  ]);

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel();
      cancelFetchValues();
    };
  }, []);

  return {
    debouncedFetchData,
    cancelFetchValues,
  };
};
