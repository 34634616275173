import React from 'react';
import classNames from 'classnames';
import { TextBoxProps } from '../TextBox';
import { TextBoxStyled } from './Password.styles';

export type PasswordProps = Omit<TextBoxProps, 'type'>;

export const Password = ({
  value,
  placeholder,
  name,
  disabled,
  autoComplete,
  onChange,
  error,
  label,
  className,
  ...rest
}: PasswordProps): JSX.Element => {
  return (
    <TextBoxStyled
      className={classNames('syte-password', className)}
      value={value}
      type='password'
      name={name}
      placeholder={placeholder}
      disabled={disabled}
      autoComplete={autoComplete}
      onChange={onChange}
      error={error}
      label={label}
      {...rest}
    />
  );
};
