import styled from '@emotion/styled';
import { Page } from 'src/components-dummy';

export const CatalogFormPageStyled = styled(Page)`
  height: Calc(100% - 65px);
  .syte-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
  }
`;
