import React, { useEffect } from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch } from 'src/components-bl/types';
import { Page } from 'src/components-dummy';
import { GalleryType, InspirationalImagesGallery } from 'src/services';
import { GalleryBreadCrumbs } from '../../GalleryBreadCrumbs';
import { viewInspirationalImagesGalleryPageActions } from './Actions';
import { GalleryTableSkeleton, InspirationalImagesGalleryTable } from './components';
import { ViewGalleryPageStyled, PageTitlesWrapper } from './ViewGalleryPage.styles';

interface ViewInspirationalImagesGalleryPageProps extends RoutedComponentProps {
  shopId: number;
  dispatch: Dispatch;
  galleryId: string;
  gallery?: InspirationalImagesGallery;
}

export function ViewInspirationalImagesGalleryPage({
  shopId,
  dispatch,
  galleryId,
  gallery,
  permittedRouteMap,
}: ViewInspirationalImagesGalleryPageProps): JSX.Element {
  const isLoadingGallery = gallery === undefined;

  useEffect(() => {
    dispatch(
      viewInspirationalImagesGalleryPageActions.getGallery({
        shopId,
        galleryId,
        galleryType: GalleryType.InspirationalImage,
      })
    );

    return () => {
      dispatch(viewInspirationalImagesGalleryPageActions.resetCurrentGallery());
    };
  }, [shopId, galleryId, dispatch]);

  return (
    <ViewGalleryPageStyled>
      <Page.Header>
        <PageTitlesWrapper>
          <GalleryBreadCrumbs
            dispatch={dispatch}
            shopId={shopId}
            permittedRouteMap={permittedRouteMap}
            showGalleriesLink
          >
            {!isLoadingGallery && gallery?.galleryName}
          </GalleryBreadCrumbs>
          <Page.SubTitle>Manage and review your galleries setup and health.</Page.SubTitle>
        </PageTitlesWrapper>
      </Page.Header>
      <Page.Content>
        {isLoadingGallery ? (
          <GalleryTableSkeleton />
        ) : (
          <InspirationalImagesGalleryTable gallery={gallery as InspirationalImagesGallery} />
        )}
      </Page.Content>
    </ViewGalleryPageStyled>
  );
}
