import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon, Typography } from 'src/components-dummy';

export const DeepTagsProductStyled = styled.div`
  height: 100%;
`;

export const SubProductTagsListStyled = styled.ul`
  padding: 0px 24px 16px 26px;
  margin: 0;
  margin-right: 8px;
  overflow: auto;
  list-style-type: none;
`;

export const MainProductTagsListStyled = styled.ul`
  padding: 0px 24px 0px 26px;
  margin: 0;
  margin-right: 8px;
  overflow: auto;
  list-style-type: none;

  & > div:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  }
`;

export const MainProductTagsListSectionStyled = styled.div``;

export const AllTagsSectionsStyled = styled.ul`
  padding: 0px 24px 16px 26px;
  margin: 0;
  margin-right: 8px;
  overflow: auto;
  height: calc(100vh - 424px);
  list-style-type: none;
`;

/**
 * Tag
 */
export const RemoveButtonWrapperStyled = styled.div<{ shouldShow: boolean }>`
  display: flex;
  padding: 6px 16px;
  position: absolute;
  right: 0;
  gap: 10px;

  visibility: ${({ shouldShow }) => (shouldShow ? 'visible' : 'hidden')};

  .MuiButton-containedPrimary {
    ${({ shouldShow, theme }) =>
      shouldShow &&
      css`
        background-color: ${theme.palette.custom['red-90']};
      `};
  }

  &:hover {
    background: ${({ theme }) => theme.palette.custom['gray-90']};
  }
`;

export const ScoreTypeValueStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const ScoreTypeEditedStyled = styled.div`
  display: flex;
  justify-self: end;
  white-space: nowrap;
  overflow: hidden;
  color: ${({ theme }) => theme.palette.custom['gray-20']};
`;

export const ScoreTypeColorBarsStyled = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ScoreTypeColorBarStyled = styled.div<{ color: string }>`
  background-color: ${props => props.color};
  width: 16px;
  height: 4px;
  margin-left: 1px;
`;

export const AddTagButtonWrapper = styled.div`
  button {
    align-items: center;
    color: ${({ theme }) => theme.palette.custom['primary-main']};

    svg {
      margin-right: 3px;
      & > path {
        stroke: ${({ theme }) => theme.palette.custom['primary-main']};
        fill: ${({ theme }) => theme.palette.custom['primary-main']};
      }
    }

    span {
      display: flex;
      align-items: center;
    }
  }
`;

export const AttributeStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AttributeNameStyled = styled.div<{ isRemoved?: boolean }>`
  display: flex;
  ${({ theme, isRemoved }) => {
    return isRemoved
      ? css`
          color: ${theme.palette.custom['gray-50']};
          svg path {
            stroke: ${theme.palette.custom['gray-50']};
          }
        `
      : css`
          color: ${theme.palette.custom['gray-10']};
        `;
  }};

  margin-bottom: 2px;

  svg {
    margin-left: 4px;
  }
`;

export const ValueStyled = styled(Typography)<{ isRemoved?: boolean; isMissingValue?: boolean }>`
  color: ${({ theme, isRemoved, isMissingValue }) => {
    if (isRemoved) {
      return theme.palette.custom['gray-50'];
    }
    if (isMissingValue) {
      return theme.palette.custom['orange-10'];
    }
    return theme.palette.common.black;
  }};
`;

export const IconStyled = styled(Icon)`
  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-20']};
  }

  &:hover path {
    stroke: ${({ theme }) => theme.palette.common.black};
  }
`;

export const TagStyled = styled.li<{ isClickable?: boolean }>`
  display: grid;
  grid-template-columns: 10fr 1fr 1fr;
  column-gap: 12px;
  align-items: center;
  padding: 9px 15px;
  height: 50px;

  align-items: center;
  position: relative;

  & > div:first-of-type {
    min-width: 0px;
  }

  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'initial')};
  &:hover {
    background: ${({ theme }) => theme.palette.custom['gray-90']};

    ${RemoveButtonWrapperStyled} {
      visibility: visible;
      background: ${({ theme }) => theme.palette.custom['gray-90']};
    }

    ${ScoreTypeEditedStyled}, ${ScoreTypeValueStyled}, ${ScoreTypeColorBarsStyled} {
      display: none;
    }
  }

  & svg {
    display: none;
  }

  &:hover {
    & svg {
      display: inline-block;
    }
  }
`;

export const RemoveTagSubTitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const ProductActionsAndDetailsStyled = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const CloseXButtonStyled = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-left: 4px;

  path {
    stroke: ${({ theme }) => theme.palette.common.black};
  }
`;
