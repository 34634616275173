const RECENT_SEARCHES = 'RECENT_SEARCHES';

const read = () => {
  const item = localStorage.getItem(RECENT_SEARCHES);
  if (!item) return [];
  return JSON.parse(item);
};

const write = (inputValue: string) => {
  if (inputValue.length < 4) return;
  const items: string[] = read();
  const duplicate = items.find(
    item => item.trim().toLowerCase() === inputValue.trim().toLowerCase()
  );
  if (!duplicate) {
    items.unshift(inputValue);
  }
  if (items.length > 5) {
    localStorage.setItem(RECENT_SEARCHES, JSON.stringify(items.slice(0, 5)));
  } else {
    localStorage.setItem(RECENT_SEARCHES, JSON.stringify(items));
  }
};

export default { read, write };
