import { RankingStrategyTypes } from 'src/services';
import { DomainForRendering } from '../../types';
import { rankingDiffSchema } from './ranking-diff-schema';

export const baseTitle = 'Ranking Strategy';

export const rankingSubDomainGroup: DomainForRendering<RankingStrategyTypes.RankingStrategyWeight> =
  {
    getCardTitle: ({ newEntity, oldEntity, entityPath }) => {
      const entity = newEntity ?? oldEntity;
      const name = entityPath === 'type' ? 'Ranking Method' : entity?.name;
      return `${baseTitle}${name ? ` > ${name}` : ''}`;
    },
    fieldsSchema: rankingDiffSchema,
  };
