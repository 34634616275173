import React from 'react';
import './StoriesPage.scss';
import { Page } from 'src/components-dummy';
import { StoriesHeader } from './components/StoriesHeader';
import { EditStoriesSettings } from './components/EditStoriesSettings';
import { useStoriesPageState } from '../../components-bl/Stories/useStoriesPageState/useStoriesPageState';
import { StoriesStateType } from '../../components-bl/Stories/storiesPage.types';

interface Props {
  shopId: number;
}

export const StoriesPage = ({ shopId }: Props): JSX.Element | null => {
  const { state, updateSettings, saveSettings, saveSettingsAndLeave } = useStoriesPageState();
  if (state.type === StoriesStateType.fetching || state.type === StoriesStateType.error)
    return null;
  const disabled =
    state.type === StoriesStateType.noChanges ||
    state.type === StoriesStateType.saving ||
    state.type === StoriesStateType.leaving;
  return (
    <div className='syte-stories-page'>
      <Page>
        <Page.Header>
          <StoriesHeader shopId={shopId} disabled={disabled} onSave={saveSettings} />
        </Page.Header>
        <Page.Content>
          <EditStoriesSettings
            settings={state.generalSettings}
            onChange={updateSettings}
            onLeave={saveSettingsAndLeave}
          />
        </Page.Content>
      </Page>
    </div>
  );
};
