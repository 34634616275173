import { DomainEntityPath, ShopDataField } from '../types';
import { DomainEventsWebSocketServiceBase, DomainEvent } from '../domain-events';
import { DataFieldsMapper } from './data-fields.mapper';
import { ClearListener } from '../web-socket.service';

type UpdateDataFieldPayload = { dataFields: ShopDataField[]; shopId: number };

export enum DataFieldsEventTopicNames {
  Update = 'data_fields:update',
}

export type DataFieldUpdateMessage = DomainEvent<UpdateDataFieldPayload, DataFieldsEventTopicNames>;

class DataFieldsWebSocketService extends DomainEventsWebSocketServiceBase<DataFieldsEventTopicNames> {
  domainEntity = DomainEntityPath.DataFields;

  subscribeToDataFieldUpdateEvents(
    onDataFieldUpdate: (payload: DataFieldUpdateMessage) => void
  ): ClearListener {
    return this.subscribeToEvents({
      topicKey: DataFieldsEventTopicNames.Update,
      callback: (response: DataFieldUpdateMessage) => {
        const mappedDataFields = DataFieldsMapper.mapDataFields({
          data: response.data,
        } as any);

        response.data.dataFields = mappedDataFields;

        onDataFieldUpdate(response);
      },
    });
  }
}

export const dataFieldsWebSocketService = new DataFieldsWebSocketService();
