import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

export const PageSideNavContainerStyled = styled.div``;

export const PageSideNavTitleStyled = styled.p`
  padding-bottom: 6px;
`;

export const PageSideNavListStyled = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

export const PageSideNavListItemStyled = styled.li<{ isActive: boolean; isDisabled: boolean }>`
  padding: 10px 0;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  color: ${({ isActive, theme }) => isActive && theme.palette.primary.main};
  color: ${({ isDisabled, theme }) => isDisabled && theme.palette.custom['gray-50']};
`;

export const PageSideNavListItem = styled(PageSideNavListItemStyled)`
  cursor: pointer;
`;

export const NavLinkStyled = styled(NavLink)<{ isDisabled: boolean }>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
`;

export const PageSideNavGroupDivider = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};
  margin: 10px 0;
`;
