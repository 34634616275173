import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Accounts } from 'src/services';

const accountService = new Accounts();

export const adminSearchModalActions = {
  // handled in navigate middleware to generate path based on current URL
  navigateToShop: createAction<{ shopId: number }>('AdminSearchModal/NavigateToShop'),
  navigateTo: createAction<{ navigateTo: string }>('AdminSearchModal/NavigateTo'),

  navigateToAccount: createAsyncThunk(
    'AdminSearchModal/navigateToAccount',
    async (
      {
        accountId,
        navigateTo,
      }: {
        accountId: number;
        navigateTo: string;
      },
      { rejectWithValue }
    ) => {
      try {
        // TODO: Still gets account even if not confirming unsaved changes
        // can be easily solved if we use route param in accounts too.
        const account = await accountService.getAccount(accountId);
        return { account, navigateTo };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  navigateToUser: createAsyncThunk(
    'AdminSearchModal/navigateToUser',
    async (
      {
        userId,
        accountId,
        navigateTo,
      }: {
        userId: number;
        accountId: number;
        navigateTo: string;
      },
      { rejectWithValue }
    ) => {
      try {
        const account = await accountService.getAccount(accountId);
        return { account, userId, navigateTo };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
