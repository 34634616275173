import styled from '@emotion/styled';

export const SearchInputStyled = styled.div`
  .syte-input-base {
    border-radius: 6px;
    padding-left: 3px;
    border: 1px solid transparent;
    background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  }

  .syte-input-base:hover {
    border: 1px solid ${({ theme }) => theme.palette.custom['gray-50']} !important;
  }

  .syte-input-base:focus,
  .syte-input-base:focus-within {
    background-color: ${({ theme }) => theme.palette.common.white} !important;
    border: 1px solid ${({ theme }) => theme.palette.custom['primary-main']} !important;
  }

  .syte-text-box-actions {
    visibility: visible !important;
  }

  .syte-input-base input {
    color: ${({ theme }) => theme.palette.custom['gray-30']};
  }

  .syte-input-base input:focus {
    color: ${({ theme }) => theme.palette.common.black} !important;
  }

  .syte-action-icon {
    color: ${({ theme }) => theme.palette.custom['gray-30']};
  }
`;
