import { createReducer } from '@reduxjs/toolkit';
import { Notification } from 'src/services';
import { NotificationsReducerState } from 'src/app-state-types/reducer-state-types';
import { notificationsActions } from 'src/components-bl';
import { useNotificationsWSActions } from 'src/containers/NotificationsContainer/useNotificationsWS';

const initialState: NotificationsReducerState = {
  notifications: undefined,
  unreadNotifications: undefined,
  unreadCount: undefined,
  total: undefined,
};

function filterNotificationsOutsByIds(
  notifications: Notification[] | undefined = [],
  ids: string[] = []
): Notification[] {
  return notifications.filter(notification => !ids.includes(notification.id));
}

export const notificationsReducer = createReducer(initialState, builder => {
  builder.addCase(useNotificationsWSActions.unreadCount, (state, action) => {
    return { ...state, unreadCount: action.payload.unreadCount };
  });

  builder.addCase(useNotificationsWSActions.create, (state, action) => {
    const notifications = [action.payload.notification, ...(state.notifications || [])];

    let unreadNotifications;
    let newUnreadCount;

    if (action.payload.notification.isViewed === false) {
      unreadNotifications = [action.payload.notification, ...(state.unreadNotifications || [])];
      const oldUnreadCount = state.unreadCount || 0;
      newUnreadCount = oldUnreadCount + 1;
    } else {
      unreadNotifications = state.unreadNotifications;
      newUnreadCount = state.unreadCount;
    }

    const oldTotalCount = state.total || 0;
    const newTotalCount = oldTotalCount + 1;

    return {
      ...state,
      notifications,
      unreadNotifications,
      unreadCount: newUnreadCount,
      total: newTotalCount,
    };
  });

  builder.addCase(useNotificationsWSActions.view, (state, action) => {
    const mappedNotifications = (state.notifications || []).map(notification => {
      if (action.payload.notificationIds.includes(notification.id)) {
        return { ...notification, isViewed: true };
      }

      return notification;
    });

    const mappedUnreadNotifications = filterNotificationsOutsByIds(
      state.unreadNotifications,
      action.payload.notificationIds
    );

    return {
      ...state,
      notifications: mappedNotifications,
      unreadNotifications: mappedUnreadNotifications,
    };
  });

  builder.addCase(useNotificationsWSActions.delete, (state, action) => {
    const mappedNotifications = filterNotificationsOutsByIds(
      state.notifications,
      action.payload.notificationIds
    );

    const mappedUnreadNotifications = filterNotificationsOutsByIds(
      state.unreadNotifications,
      action.payload.notificationIds
    );

    return {
      ...state,
      notifications: mappedNotifications,
      unreadNotifications: mappedUnreadNotifications,
    };
  });

  builder.addCase(notificationsActions.getNotifications.fulfilled, (state, action) => {
    const { notifications, isUnreadOnly, ...restPayload } = action.payload;
    const notificationTypeToUpdate: keyof Pick<
      NotificationsReducerState,
      'notifications' | 'unreadNotifications'
    > = isUnreadOnly ? 'unreadNotifications' : 'notifications';
    return { ...state, ...restPayload, [notificationTypeToUpdate]: notifications };
  });
});
