import styled from '@emotion/styled';
import { Typography, Modal } from 'src/components-dummy';

export const ModalStyled = styled(Modal)`
  .syte-modal-content {
    max-width: 707px;
  }
`;
export const ModalTitleStyled = styled.div`
  padding: 24px 40px;
`;

export const ModalContentStyled = styled.div`
  padding: 24px 40px;
`;

export const ModalFooterStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-50']};
  padding: 24px 40px;
`;

export const SFTPCredentialsStyled = styled.div`
  margin-top: 32px;
  background-color: ${({ theme }) => theme.palette.custom['primary-light']};
  padding: 24px 16px;
  border-radius: 8px;
`;

export const InfoRowsStyled = styled.ul`
  list-style-type: none;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  padding-inline-start: unset;
`;

export const InfoRowStyled = styled.li`
  margin-top: 12px;
`;

export const InfoRowsNumberStyled = styled.ul`
  list-style-type: decimal;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: 30px;
  margin-inline-end: unset;
  padding-inline-start: unset;
`;

export const InfoRowLabelStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const InfoRowValueStyled = styled(Typography)`
  margin-right: 5px;
`;

export const UploadStepTypographyStyled = styled(Typography)`
  margin-top: 5px;
`;

export const InfoRowValueWrapperStyled = styled.div`
  display: flex;
`;

export const SFTPStepsContainerStyled = styled(Typography)`
  background: ${({ theme }) => theme.palette.custom['gray-90']};
  padding: 16px;
`;
