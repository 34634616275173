import type { UiTemplate } from '../../types';

export enum UiTemplatesListActionTypes {
  GetUiTemplatesRequest = 'UiTemplatesList/GetUiTemplatesRequest',
  GetUiTemplatesSuccess = 'UiTemplatesList/GetUiTemplatesSuccess',
  GetUiTemplatesError = 'UiTemplatesList/GetUiTemplatesError',
  UpdateUiTemplatesError = 'UiTemplatesList/UpdateUiTemplatesError',
}

export interface UiTemplatesListActionPayload {
  shopId: number;
  templates?: UiTemplate[];
}

export interface UiTemplatesListErrorActionPayload {
  error: unknown;
}

export type UpdateUiTemplatesListErrorActionPayload = UiTemplatesListUpdateUiTemplatesErrorAction;

export interface UiTemplatesListGetUiTemplatesRequestAction {
  type: typeof UiTemplatesListActionTypes.GetUiTemplatesRequest;
  payload: UiTemplatesListActionPayload;
}

export interface UiTemplatesListGetUiTemplatesSuccessAction {
  type: typeof UiTemplatesListActionTypes.GetUiTemplatesSuccess;
  payload: UiTemplatesListActionPayload;
}

export interface UiTemplatesListGetUiTemplatesErrorAction {
  type: typeof UiTemplatesListActionTypes.GetUiTemplatesError;
  payload: UiTemplatesListErrorActionPayload;
}

export interface UiTemplatesListUpdateUiTemplatesErrorAction {
  type: typeof UiTemplatesListActionTypes.UpdateUiTemplatesError;
  payload: UpdateUiTemplatesListErrorActionPayload;
}

export type UiTemplatesListAction =
  | UiTemplatesListGetUiTemplatesRequestAction
  | UiTemplatesListGetUiTemplatesSuccessAction
  | UiTemplatesListGetUiTemplatesErrorAction
  | UiTemplatesListUpdateUiTemplatesErrorAction;
