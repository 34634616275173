import React from 'react';
import {
  AvailableIcons,
  Typography,
  TypographyType,
  TypographyVariant,
  useFindComponentByType,
} from 'src/components-dummy';
import {
  ExpandableSectionTriggerStyled,
  IconStyled,
  TitleContainerStyled,
} from './ExpandableSectionTrigger.styles';

interface ExpandableSectionTriggerProps {
  title: string;
  isExpanded: boolean;
  isDisabled: boolean;
  className?: string;
  children?: JSX.Element;
}

const TitleIcon = ({ children }: { children: JSX.Element }): JSX.Element => <>{children}</>;

export const ExpandableSectionTrigger = ({
  title,
  isExpanded,
  isDisabled,
  className,
  children,
}: ExpandableSectionTriggerProps): JSX.Element => {
  const titleIconSection = useFindComponentByType({ type: TitleIcon, children });

  return (
    <ExpandableSectionTriggerStyled isDisabled={isDisabled} className={className}>
      <TitleContainerStyled>
        {titleIconSection}
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
          {title}
        </Typography>
      </TitleContainerStyled>
      <IconStyled name={AvailableIcons.RightArrow} rotateDegrees={isExpanded ? 90 : 0} />
    </ExpandableSectionTriggerStyled>
  );
};

ExpandableSectionTrigger.TitleIcon = TitleIcon;
