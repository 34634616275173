import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const DatePickerTextBoxWrapperStyled = styled.div<{ shouldShowAsValidSelection: boolean }>`
  width: 137px;

  ${({ shouldShowAsValidSelection, theme }) =>
    shouldShowAsValidSelection &&
    css`
      .input-base-wrapper {
        box-shadow:
          0px 0px 0px 1.5px ${theme.palette.custom['primary-main']},
          0px 0px 0px 4px ${theme.palette.custom['primary-90']};
      }
    `}
`;
