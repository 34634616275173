import { useMemo } from 'react';
import {
  PermittedRouteMap,
  RouteMap,
  RouteSettings,
  RoutedComponentProps,
  RouteSettingsBase,
} from 'src/app-routes';
import { ShopFeature } from 'src/app-types';
import { ShopFeatureToggleWithPermittedRoles } from 'src/services/src/service/types/shops';
import { UserTypes, ShopTypes } from '../../services';
import { getUserDefaultRoute } from '../user-role-default-routes';

interface FeatureConfigMapArgument {
  featureToggles?: ShopTypes.ShopFeatureToggles;
}

interface UseRoleBasedRoutesArguments extends FeatureConfigMapArgument {
  user: UserTypes.User | undefined;
  shopId: number | undefined;
  baseAppRouteMap: RouteMap;
}

interface FilterRoutesReturnType extends RoutedComponentProps {
  permittedRoutes: RouteSettings[];
  enabledShopFeatures: Set<ShopFeature>;
}

interface UseRoleBasedRoutesReturnType extends FilterRoutesReturnType {
  fallback: string;
}

interface FilterRoutesArguments extends FeatureConfigMapArgument {
  role: UserTypes.UserRoles | undefined;
  baseAppRouteMap: RouteMap;
  shopId: number | undefined;
}

interface BuildPermittedRouteMapArguments extends FeatureConfigMapArgument {
  baseAppRouteMap: RouteMap;
  role: UserTypes.UserRoles | undefined;
  shopId: number | undefined;
}

function buildPermittedRouteMap({
  baseAppRouteMap,
  role,
  featureToggles,
  shopId,
}: BuildPermittedRouteMapArguments): PermittedRouteMap {
  if (!role) {
    return {};
  }

  const permittedRouteMap = {} as PermittedRouteMap;

  Object.entries(baseAppRouteMap as RouteMap).forEach(
    ([mapKey, route]: [string, RouteSettingsBase]) => {
      const isSyteAdmin = role === UserTypes.UserRoles.SyteAdmin;
      const isRoutePermitted = isSyteAdmin || !!route.permissions?.[role];

      if (!isRoutePermitted) {
        return;
      }

      const shouldCheckRouteFeatureActive = !!route.feature;

      const featureToggle = route.feature ? featureToggles?.[route.feature] : undefined;

      if (featureToggles !== undefined && shouldCheckRouteFeatureActive && !featureToggle) {
        console.warn(
          `Route '${route.path}' is assigned to feature '${route.feature}' but has no corresponding feature toggle`
        );
      }
      const isRouteEnabled = !shouldCheckRouteFeatureActive || featureToggle?.enabled;

      const isShopBlackListed = shopId
        ? !isSyteAdmin && route.shopIdsBlackList?.includes(shopId)
        : false;

      const routeKey = mapKey as keyof PermittedRouteMap;

      if (featureToggle && 'permittedRoles' in featureToggle) {
        if (
          !(featureToggle as ShopFeatureToggleWithPermittedRoles).permittedRoles?.includes(role)
        ) {
          return;
        }
      }

      if (isRouteEnabled && !isShopBlackListed) {
        permittedRouteMap[routeKey] = { ...route, routeKey };
      }
    }
  );

  return permittedRouteMap;
}

export const filterRoutes = ({
  role,
  baseAppRouteMap,
  featureToggles,
  shopId,
}: FilterRoutesArguments): FilterRoutesReturnType => {
  const enabledShopFeatures = new Set<ShopFeature>([]);

  const permittedRouteMap = buildPermittedRouteMap({
    baseAppRouteMap,
    role,
    featureToggles,
    shopId,
  });
  const permittedRoutes = Object.values(permittedRouteMap) as RouteSettings[];

  for (const route of permittedRoutes) {
    if (route.feature) {
      enabledShopFeatures.add(route.feature);
    }
  }

  return {
    permittedRouteMap,
    permittedRoutes,
    enabledShopFeatures,
  };
};

export const useRoleBasedRoutes = ({
  user,
  shopId,
  baseAppRouteMap,
  featureToggles,
}: UseRoleBasedRoutesArguments): UseRoleBasedRoutesReturnType => {
  const result = useMemo(() => {
    const { permittedRouteMap, permittedRoutes, enabledShopFeatures } = filterRoutes({
      role: user?.role,
      baseAppRouteMap,
      featureToggles,
      shopId,
    });

    let userDefaultRoute = user ? getUserDefaultRoute(user.role, permittedRouteMap) : undefined;
    userDefaultRoute = userDefaultRoute ?? permittedRouteMap.login;

    const fallback = userDefaultRoute?.path as string;

    return {
      permittedRouteMap,
      permittedRoutes,
      fallback,
      enabledShopFeatures,
    };
  }, [user, shopId, featureToggles]);

  return result;
};
