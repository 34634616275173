import React from 'react';
import { Link } from 'react-router-dom';
import { TypographyType, TypographyVariant } from 'src/components-dummy/Typography';
import { BreadcrumbLinkStyled } from '../Breadcrumbs.styles';

export interface BreadcrumbLinkProps {
  href: string | undefined;
  displayName: string;
  className?: string;
  onNavigate?: (url: string) => void;
}

export const BreadcrumbLink = ({
  href,
  displayName,
  className,
  onNavigate,
}: BreadcrumbLinkProps): JSX.Element => {
  return (
    <BreadcrumbLinkStyled
      variant={TypographyVariant.ExtraSmallBold}
      type={TypographyType.Heading}
      className={className}
    >
      <Link
        key={displayName}
        to={href || ''}
        onClick={ev => {
          if (onNavigate) {
            ev.preventDefault();
            if (href) {
              onNavigate?.(href);
            }
          }
        }}
      >
        {displayName}
      </Link>
    </BreadcrumbLinkStyled>
  );
};
