import { DescriptionTextLengthEnum, TitleTextLengthEnum, ToneOfVoiceTypes } from 'src/services';

/**
 * General constants
 */
export const TITLE = 'TITLE';
export const DESCRIPTION = 'DESCRIPTION';
export const GENERATED_DESCRIPTION = 'GeneratedDescription';
export const GENERATED_TITLE = 'GeneratedTitle';

export const INDEX_BY_TAB_NAME: { [key: string]: number } = {
  [DESCRIPTION]: 0,
  [TITLE]: 1,
};

export const TAB_NAME_BY_INDEX: { [key: number]: string } = {
  0: DESCRIPTION,
  1: TITLE,
};

/**
 * Tone of voice
 */
export const toneOfVoiceValueToText: Record<
  ToneOfVoiceTypes,
  { value: ToneOfVoiceTypes; text: string }
> = {
  formal_tone: { value: 'formal_tone', text: 'Formal Tone' },
  informal_tone: { value: 'informal_tone', text: 'Informal Tone' },
  factual_tone: { value: 'factual_tone', text: 'Factual Tone' },
  directive_tone: { value: 'directive_tone', text: 'Direct Tone' },
  assertion_tone: { value: 'assertion_tone', text: 'Assertive Tone' },
  friendly_tone: { value: 'friendly_tone', text: 'Friendly Tone' },
};

export const toneOfVoiceMenuOptions = Object.values(toneOfVoiceValueToText);

/**
 * Text Length
 */

export const descriptionTextLengthValueToText: Record<
  DescriptionTextLengthEnum,
  { value: DescriptionTextLengthEnum; text: string }
> = {
  [DescriptionTextLengthEnum.TwoSentences]: {
    value: DescriptionTextLengthEnum.TwoSentences,
    text: 'Two Sentences',
  },
  [DescriptionTextLengthEnum.OneParagraph]: {
    value: DescriptionTextLengthEnum.OneParagraph,
    text: 'One Paragraph',
  },
  [DescriptionTextLengthEnum.TwoParagraphs]: {
    value: DescriptionTextLengthEnum.TwoParagraphs,
    text: 'Two Paragraphs',
  },
  [DescriptionTextLengthEnum.ThreeParagraphs]: {
    value: DescriptionTextLengthEnum.ThreeParagraphs,
    text: 'Three Paragraphs',
  },
};
export const descriptionTextLengthMenuOptions = Object.values(descriptionTextLengthValueToText);

export const titleTextLengthValueToText: Record<
  TitleTextLengthEnum,
  { value: TitleTextLengthEnum; text: string }
> = {
  [TitleTextLengthEnum.UpTo5Words]: {
    value: TitleTextLengthEnum.UpTo5Words,
    text: 'Up to 5 words',
  },
  [TitleTextLengthEnum.UpTo10Words]: {
    value: TitleTextLengthEnum.UpTo10Words,
    text: 'Up to 10 words',
  },
  [TitleTextLengthEnum.UpTo15Words]: {
    value: TitleTextLengthEnum.UpTo15Words,
    text: 'Up to 15 words',
  },
};
export const titleTextLengthMenuOptions = Object.values(titleTextLengthValueToText);
