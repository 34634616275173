import { Accounts } from 'src/services';
import { Dispatch } from '../../../../types';
import {
  UpdateAccountStatusRequestAction,
  UpdateAccountStatusRequestActionPayload,
  UpdateAccountStatusErrorAction,
  UpdateAccountStatusErrorActionPayload,
  UpdateAccountStatusSuccessAction,
  UpdateAccountStatusSuccessActionPayload,
  AccountCardActionTypes,
} from './types';

const accountService = new Accounts();

/* --= Actions =-- */
export const accountCardActions = {
  updateAccountStatusRequest: (
    payload: UpdateAccountStatusRequestActionPayload
  ): UpdateAccountStatusRequestAction => {
    return {
      type: AccountCardActionTypes.UpdateAccountStatusRequest,
      payload,
    };
  },
  updateAccountStatusError: (
    payload: UpdateAccountStatusErrorActionPayload
  ): UpdateAccountStatusErrorAction => {
    return {
      type: AccountCardActionTypes.UpdateAccountStatusError,
      payload,
    };
  },
  updateAccountStatusSuccess: (
    payload: UpdateAccountStatusSuccessActionPayload
  ): UpdateAccountStatusSuccessAction => {
    return {
      type: AccountCardActionTypes.UpdateAccountStatusSuccess,
      payload,
    };
  },
};

/* --= Methods =-- */
export const updateAccountStatus =
  ({ accountId, status }: UpdateAccountStatusRequestActionPayload) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(accountCardActions.updateAccountStatusRequest({ accountId, status }));
    try {
      const account = await accountService.updateAccount({
        accountId,
        data: { accountStatus: status },
      });
      dispatch(accountCardActions.updateAccountStatusSuccess({ account }));
    } catch (error) {
      dispatch(accountCardActions.updateAccountStatusError({ error }));
    }
  };
