import React, { useMemo } from 'react';
import { ShopFeature } from 'src/app-types';
import {
  AvailableIcons,
  Button,
  FormCard,
  HorizontalSeparator,
  Icon,
  Tooltip,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { featureKeyToTitleMap, featureSections, featureTogglesFormSchema } from './constants';
import { useFeatureTogglesSettings } from './useFeatureTogglesSettings';
import { FeatureToggle } from './FeatureToggle';
import { FeatureToggleFormsProps } from './types';
import { FeatureToggleStyled } from './FeaturePermissionsForm.styles';
import './FeaturePermissionsForm.scss';

export const FeaturePermissionsForm = ({
  dispatch,
  featureToggles,
  runningExperimentId,
  shopId,
}: FeatureToggleFormsProps): JSX.Element => {
  const { featureTogglesDraft, isDirty, isValid, onChange, onSubmit } = useFeatureTogglesSettings({
    dispatch,
    featureToggles,
    shopId,
    validationSchema: featureTogglesFormSchema,
  });

  const disabledFeatures: Set<ShopFeature> = useMemo(() => {
    const disabledFeaturesSet = new Set<ShopFeature>([]);
    const canChangeExperiments = runningExperimentId ? !featureToggles?.experiments?.enabled : true;

    if (!canChangeExperiments) {
      disabledFeaturesSet.add(ShopFeature.Experiments);
    }
    return disabledFeaturesSet;
  }, [runningExperimentId, featureToggles]);

  return (
    <FormCard stickyHeader>
      <FormCard.Title>Manage permissions</FormCard.Title>
      <FormCard.Button>
        <Button
          onClick={onSubmit}
          variant='primary'
          disabled={!isDirty || !isValid || !featureTogglesDraft}
        >
          Save changes
        </Button>
      </FormCard.Button>
      <FormCard.Content>
        {Object.values(featureSections).map(({ title, features, showPermissionsDropDown }) => (
          <React.Fragment key={title}>
            <HorizontalSeparator />
            <Typography type={TypographyType.Body} variant={TypographyVariant.LargeMedium}>
              {title}
            </Typography>
            {features.map(feature => {
              const disabled = disabledFeatures.has(feature);

              return (
                <FeatureToggleStyled
                  key={feature}
                  onChange={onChange}
                  featureKey={feature}
                  featureTogglesDraft={featureTogglesDraft}
                  disabled={disabled}
                >
                  <FeatureToggle.Title className='feature-name-container'>
                    <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Body}>
                      {featureKeyToTitleMap[feature]}
                    </Typography>
                    <>
                      {feature === ShopFeature.Experiments && (
                        <Tooltip
                          value='For API customers, please ensure that UUID is included in all Syte events.'
                          contentClickPreventDefault={false}
                        >
                          <Icon name={AvailableIcons.TooltipQuestionmark} />
                        </Tooltip>
                      )}
                      {feature === ShopFeature.MerchandisingRulesAiTags && (
                        <Tooltip
                          value={`If you're not seeing the AI Tags fields even after enabling this feature, please reach out to the Data Management team for assistance. They can help ensure that the feed is properly configured with VMR to make the AI Tags fields visible and functional.`}
                          contentClickPreventDefault={false}
                        >
                          <Icon name={AvailableIcons.TooltipQuestionmark} />
                        </Tooltip>
                      )}
                    </>
                  </FeatureToggle.Title>
                  {showPermissionsDropDown && (
                    <FeatureToggle.PermissionsDropDown
                      disabled={disabled}
                      onChange={onChange}
                      featureKey={feature}
                      featureTogglesDraft={featureTogglesDraft as any}
                    />
                  )}
                </FeatureToggleStyled>
              );
            })}
          </React.Fragment>
        ))}
      </FormCard.Content>
    </FormCard>
  );
};
