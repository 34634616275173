import React, { useCallback, useEffect, useState } from 'react';
import { LoginForm, ForgotPasswordForm, VerificationEmailSentForm } from './components';
import { FormNames } from './types';
import { LoginLayout } from './LoginLayout';
import { Dispatch } from 'src/components-bl';

interface LoginContainerProps {
  twoFactorAuthRequired?: boolean;
  dispatch: Dispatch;
}

export const LoginContainer = ({
  twoFactorAuthRequired,
  dispatch,
}: LoginContainerProps): JSX.Element => {
  const [forgotPassWordEmail, setForgotPasswordEmail] = useState('');
  const [selectedForm, setSelectedForm] = useState(FormNames.LoginForm);

  const goToLoginForm = () => setSelectedForm(FormNames.LoginForm);
  const goToForgotPasswordForm = (email: string | undefined) => {
    setSelectedForm(FormNames.ForgotPasswordForm);

    if (email) {
      setForgotPasswordEmail(email);
    }
  };

  useEffect(() => {
    setForgotPasswordEmail('');
  }, [selectedForm]);

  useEffect(() => {
    if (twoFactorAuthRequired) {
      setSelectedForm(FormNames.EmailLinkVerificationForm);
    }
  }, [twoFactorAuthRequired]);

  const FormSection = useCallback(() => {
    switch (selectedForm) {
      case FormNames.EmailLinkVerificationForm:
        return <VerificationEmailSentForm dispatch={dispatch} goToLoginForm={goToLoginForm} />;
      case FormNames.ForgotPasswordForm:
        return <ForgotPasswordForm goToLoginForm={goToLoginForm} email={forgotPassWordEmail} />;
      case FormNames.LoginForm:
      default:
        return <LoginForm goToForgotPasswordForm={goToForgotPasswordForm} />;
    }
  }, [selectedForm]);

  return (
    <LoginLayout>
      <FormSection />
    </LoginLayout>
  );
};
