import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import { UserTypes } from '../../services';
import { SideNav } from '../../components-dummy';
import { RoleBasedSideNavProps, SideNavNames } from './types';
import { getSideNavNameByPath } from './getSideNavNameByPath';
import './RoleBasedSideNav.scss';
import { allowedRoutesToArray } from './helpers';

export const RoleBasedSideNav = ({
  role,
  accountRoutes,
  shopRoutes,
  location,
  isShopLoading,
  ...rest
}: RoleBasedSideNavProps): JSX.Element => {
  const [currentMenuName, setCurrentMenuName] = useState(SideNavNames.Shop);

  const [isMinimized, setMinimized] = useState(false);

  const accountsPath = useMemo(() => allowedRoutesToArray(accountRoutes), [accountRoutes]);

  const { pathname } = useLocation();

  useEffect(() => {
    const menuName = getSideNavNameByPath({
      pathname: location.pathname,
      accountsPath,
    });

    if (menuName === SideNavNames.Shop && !rest.shops.length) {
      setCurrentMenuName(SideNavNames.Account);
    } else {
      setCurrentMenuName(menuName);
    }
  }, [accountRoutes, shopRoutes, rest.shops, pathname]);

  const shouldShowAccountSideNav =
    [UserTypes.UserRoles.SyteAdmin, UserTypes.UserRoles.ClientAdmin].includes(role) &&
    accountRoutes.length;

  const shopsSideNav = (
    <SideNav
      {...rest}
      setMinimized={() => setMinimized(!isMinimized)}
      isMinimized={isMinimized}
      showShopSearch
      routes={shopRoutes}
      className={classNames('syte-role-based-side-nav', {
        selected: currentMenuName === SideNavNames.Shop,
      })}
      isLoading={isShopLoading}
    />
  );

  const accountsSideNav = shouldShowAccountSideNav ? (
    <SideNav
      {...rest}
      isMinimized={isMinimized}
      setMinimized={() => setMinimized(!isMinimized)}
      routes={accountRoutes}
      showShopSearch={false}
      className={classNames('syte-role-based-side-nav', {
        selected: currentMenuName === SideNavNames.Account,
      })}
    />
  ) : null;

  return (
    <>
      {shopsSideNav}
      {accountsSideNav}
    </>
  );
};
