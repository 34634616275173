import styled from '@emotion/styled';

export const CardWrapperStyled = styled.div`
  .catalog-list-card {
    margin: 15% auto 0 auto;
    padding: 24px 75px 40px 24px;
    width: 520px;

    .title {
      line-height: 24px;
      margin-bottom: 8px;
    }

    .subtitle {
      color: #6e6e71;
    }
  }
`;

export const ActionButtonsWrapperStyled = styled.div`
  margin-top: 20px;

  button:first-child {
    margin-right: 5px;
  }
`;
