import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ShopSFTPSettingsService } from 'src/services';

/**
 * Actions
 */

export const deepTagReportSFTPSettingsModalContainerActions = {
  navigateTo: createAction<{ navigateTo: string }>(
    'DeepTagReportSFTPSettingsModalContainer/navigateTo'
  ),

  getShopSFTPSettings: createAsyncThunk(
    'DeepTagReportSFTPSettingsModalContainer/getShopSFTPSettings',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const settings = await new ShopSFTPSettingsService().getSettings(shopId);
        return { settings };
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),

  resetSFTPSettings: createAction('DeepTagReportSFTPSettingsModalContainer/resetSFTPSettings'),
};
