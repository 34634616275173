import { RecEnginesGeneralSettingsType } from '../types';

export const buildRecEnginesGeneralSettingsPayload = (
  payload: Partial<RecEnginesGeneralSettingsType.RecEnginesGeneralSettings>,
  shopId: number
): Partial<RecEnginesGeneralSettingsType.RecEnginesGeneralSettingsResponse> => {
  const payloadData = {
    shopId,
    shouldOpenPDPInNewTab: {
      desktop: payload.offerNavOpenResultsInNewTabDesktop,
      mobile: payload.offerNavOpenResultsInNewTabMobile,
    },
    overrideImageUrl: payload.overrideImageUrl,
    removeDuplicates: payload.removeDuplicates,
    addToCart: payload.addToCart,
  };

  return payloadData;
};
