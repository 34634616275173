import React from 'react';
import { Dispatch } from 'src/components-bl';
import {
  AvailableIcons,
  NumericInput,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { DeepTagReportsLabelAutoComplete } from '../DeepTagReportsLabelAutoComplete/DeepTagReportsLabelAutoComplete';
import {
  ItemContentStyled,
  ItemContentSectionStyled,
  ItemContentSectionLabelStyled,
  LabelItemStyled,
  IconTrashStyled,
  DeleteButtonWrapperStyled,
} from './DeepTagReportProductsCustomLabelBulk.style';
import { MIN_TOTAL_PRODUCTS } from './DeepTagBulkCustomFormItem.schema';

export interface CustomLabelBulkFormItem {
  labelId?: string;
  totalProducts?: number;
  id: string;
}

export interface DeepTagCustomLabelItemProps {
  disabled: boolean;
  totalFormAvailableProductsToAssign: number;
  labelId: CustomLabelBulkFormItem['labelId'];
  totalProducts: CustomLabelBulkFormItem['totalProducts'];
  hasExceededNumberOfItems: boolean;
  id: string;
  errors: Pick<CustomLabelBulkFormItem, 'labelId' | 'totalProducts'>;
  onDeleteItem: () => void;
  onChangeItem: (item: CustomLabelBulkFormItem) => void;
  dispatch: Dispatch;
}

export const DeepTagBulkCustomFormItem = React.memo(
  ({
    labelId,
    totalProducts,
    hasExceededNumberOfItems,
    id,
    totalFormAvailableProductsToAssign,
    errors,
    onDeleteItem,
    dispatch,
    onChangeItem,
  }: DeepTagCustomLabelItemProps): JSX.Element => {
    const handleNumberOfItemsChange = (selectedNumber?: number) => {
      onChangeItem({ labelId, totalProducts: selectedNumber, id });
    };

    const handleLabelChange = (labelsList: string[]) => {
      onChangeItem({ labelId: labelsList[0], totalProducts, id });
    };

    return (
      <LabelItemStyled>
        <ItemContentStyled>
          <ItemContentSectionStyled>
            <ItemContentSectionLabelStyled>
              <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
                Number of items
              </Typography>
            </ItemContentSectionLabelStyled>
            <NumericInput
              placeholder={`Type a number between ${MIN_TOTAL_PRODUCTS} to ${totalFormAvailableProductsToAssign}`}
              value={totalProducts}
              onChange={handleNumberOfItemsChange}
              minNumericValue={MIN_TOTAL_PRODUCTS}
              disabled={false}
              error={!!errors.totalProducts || hasExceededNumberOfItems}
            />
          </ItemContentSectionStyled>
          <ItemContentSectionStyled>
            <ItemContentSectionLabelStyled>
              <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
                to
              </Typography>
            </ItemContentSectionLabelStyled>
            <></>
            <DeepTagReportsLabelAutoComplete
              freeSolo
              multiple
              maxSelection={1}
              dispatch={dispatch}
              setSelectedValues={handleLabelChange}
              selectedValues={!labelId ? [] : [labelId]}
              placeholder={labelId ? ' ' : 'Type to select or insert a label'}
              isError={!!errors.labelId}
              disabled={false}
            />
          </ItemContentSectionStyled>
          <DeleteButtonWrapperStyled>
            <IconTrashStyled name={AvailableIcons.TrashCan} onClick={onDeleteItem} />
          </DeleteButtonWrapperStyled>
        </ItemContentStyled>
      </LabelItemStyled>
    );
  }
);
