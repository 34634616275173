import React from 'react';
import { Button, ConfirmationDialog } from 'src/components-dummy';
import { ButtonsContainer } from './ChangeCategoryDialog.styles';

interface ChangeCategoryDialogProps {
  onCancel: () => void;
  onApply: () => void;
}

export const ChangeCategoryDialog = ({
  onCancel,
  onApply,
}: ChangeCategoryDialogProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onCancel}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Changing a category</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        Before changing the category, please be aware that some settings in the rule and/or
        condition areas may be lost due to the category change.
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onApply}>
            Yes, change category
          </Button>
          <Button variant='outlined' onClick={onCancel}>
            No
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
