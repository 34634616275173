import { AxiosResponse } from 'axios';
import { RecEnginesGeneralSettingsType } from '../types';

export const recEnginesGeneralSettingsResponse = ({
  data,
}: AxiosResponse): RecEnginesGeneralSettingsType.RecEnginesGeneralSettings => {
  return {
    offerNavOpenResultsInNewTabDesktop: data.shouldOpenPDPInNewTab.desktop,
    offerNavOpenResultsInNewTabMobile: data.shouldOpenPDPInNewTab.mobile,
    overrideImageUrl: data.overrideImageUrl,
    removeDuplicates: data.removeDuplicates,
    addToCart: data.addToCart,
  };
};
