import React, { useCallback, useMemo, useState } from 'react';
import { AvailableIcons, Icon, TextBox, Tooltip, RadioGroup, Box } from 'src/components-dummy';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy/Typography';
import { ILexiconValue } from 'src/services/src/service/types/lexicon';
import {
  DeepTagLexiconItem,
  ProductDataOriginalData,
} from 'src/services/src/service/types/deep-tag-reports';
import { useValidateSchema } from 'src/hooks/useValidateSchema';
import { DeepTagsProductSelectValueModal } from '../../DeepTagsProductFormSelectValueModal';
import {
  CategoryContentStyled,
  CategoryLexiconStyled,
  ContentCustomStyled,
  ErrorLabelStyled,
  ErrorsMessagesWrapperStyled,
  RadioGroupWrapperStyled,
  RadioOptionWrapperStyled,
  SubTitleStyled,
} from './DeepTagsChangeCategory.style';
import {
  CategoryFreeTextDraft,
  DeepTagsChangeCategoryFormFreeTextSchema,
} from './DeepTagsChangeCategoryFormFreeTextSchema';

export enum CategoryTypeEnum {
  lexicon = 'lexicon',
  none = 'none',
  free_text = 'free_text',
}

export interface ICategory {
  name: string | undefined;
  translation: string | undefined;
}

interface DeepTagsChangeCategoryFormProps {
  onCloseDialog: () => void;
  onSubmit: (updatedCategory: ICategory) => void;
  originalData: ProductDataOriginalData;
  currentCategory: ICategory;
  reportLocale: string;
  lexiconCategories?: Record<string, ILexiconValue>;
}

export const DeepTagsChangeCategoryForm = ({
  onCloseDialog,
  onSubmit,
  originalData,
  currentCategory,
  reportLocale,
  lexiconCategories,
}: DeepTagsChangeCategoryFormProps): JSX.Element => {
  const [categoryType, setCategoryType] = useState<CategoryTypeEnum>(() => {
    if (currentCategory.name && currentCategory.translation) {
      return CategoryTypeEnum.lexicon;
    }

    if (!currentCategory.name && currentCategory.translation) {
      return CategoryTypeEnum.free_text;
    }

    return CategoryTypeEnum.none;
  });

  const [categoryState, setCategoryState] = useState<ICategory>(currentCategory);
  const [freeTextValue, setFreeTextValue] = useState<string>(currentCategory.translation || '');

  const { errors, validate } = useValidateSchema<CategoryFreeTextDraft>({
    schema: DeepTagsChangeCategoryFormFreeTextSchema({
      formState: { categoryName: freeTextValue || '' },
    }),
    validateOnStart: false,
  });

  const onFreeTextChange = useCallback(
    (value: string) => {
      setFreeTextValue(value);

      validate({
        dataToValidate: { categoryName: value },
      });
    },
    [validate]
  );

  const onSelectLexiconCategory = (selectedValueItem: ILexiconValue): void => {
    // Value changed
    setCategoryState({
      name: selectedValueItem.key,
      translation: selectedValueItem.translation,
    });
  };

  const onRadioButtonChange = (type: CategoryTypeEnum) => {
    setCategoryType(type);

    // eslint-disable-next-line default-case
    switch (type) {
      case CategoryTypeEnum.lexicon: {
        setCategoryState({
          name: currentCategory.name || undefined,
          translation: currentCategory.translation || undefined,
        });
        break;
      }
      case CategoryTypeEnum.free_text: {
        const value = currentCategory.translation || '';
        setCategoryState({
          name: undefined,
          translation: value,
        });

        setFreeTextValue(value);

        validate({
          dataToValidate: { categoryName: value },
        });
        break;
      }
      case CategoryTypeEnum.none: {
        setCategoryState({
          name: undefined,
          translation: undefined,
        });
      }
    }
  };

  const onSaveChanges = () => {
    // eslint-disable-next-line default-case
    switch (categoryType) {
      case CategoryTypeEnum.lexicon: {
        onSubmit({
          name: categoryState.name,
          translation: categoryState.translation,
        });
        break;
      }
      case CategoryTypeEnum.free_text: {
        onSubmit({
          name: undefined,
          translation: freeTextValue,
        });
        break;
      }
      case CategoryTypeEnum.none: {
        onSubmit({
          name: undefined,
          translation: undefined,
        });
      }
    }
  };

  const isCategoryValueValid = useMemo((): boolean => {
    if (categoryType === CategoryTypeEnum.lexicon) {
      const hasNameAndTranslation = !!categoryState.name && !!categoryState.translation;
      return hasNameAndTranslation;
    }

    if (categoryType === CategoryTypeEnum.free_text) {
      const isCategoryValid = !errors.categoryName && !!freeTextValue;
      return isCategoryValid;
    }

    return true;
  }, [categoryType, errors, freeTextValue, categoryState]);

  return (
    <ConfirmationDialog onCancel={onCloseDialog}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Category</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ContentCustomStyled>
        <SubTitleStyled>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            Choose if to replace the category with another lexicon category, leave the category
            empty or provide an unsupported category value:
          </Typography>
        </SubTitleStyled>
        <RadioGroupWrapperStyled>
          <RadioGroup
            selectedValue={categoryType}
            onChange={onRadioButtonChange}
            disabled={false}
            options={[
              {
                value: CategoryTypeEnum.lexicon,
                label: (
                  <RadioOptionWrapperStyled>
                    <Typography
                      type={TypographyType.Body}
                      variant={TypographyVariant.MediumRegular}
                    >
                      Lexicon Category
                    </Typography>
                    <Tooltip value='Choose an alternative category from the lexicon.'>
                      <Icon name={AvailableIcons.InformationV2} />
                    </Tooltip>
                  </RadioOptionWrapperStyled>
                ),
              },
              {
                value: CategoryTypeEnum.none,
                label: (
                  <RadioOptionWrapperStyled>
                    <Typography
                      type={TypographyType.Body}
                      variant={TypographyVariant.MediumRegular}
                    >
                      No Category
                    </Typography>
                    <Tooltip value='Leave the category empty. No tags can be added.'>
                      <Icon name={AvailableIcons.InformationV2} />
                    </Tooltip>
                  </RadioOptionWrapperStyled>
                ),
              },
              {
                value: CategoryTypeEnum.free_text,
                label: (
                  <RadioOptionWrapperStyled>
                    <Typography
                      type={TypographyType.Body}
                      variant={TypographyVariant.MediumRegular}
                    >
                      Unsupported Category
                    </Typography>
                    <Tooltip value='Provide a category that is not in the lexicon. No tags can be added.'>
                      <Icon name={AvailableIcons.InformationV2} />
                    </Tooltip>
                  </RadioOptionWrapperStyled>
                ),
              },
            ]}
          />
        </RadioGroupWrapperStyled>

        {categoryType === CategoryTypeEnum.lexicon && (
          <CategoryContentStyled>
            <CategoryLexiconStyled>
              <DeepTagsProductSelectValueModal
                locale={reportLocale}
                onSelect={onSelectLexiconCategory}
                originalAttributeValue={{
                  key: originalData.category || '',
                  translation: originalData?.categoryTranslation || '',
                }}
                currentAttributeValue={
                  {
                    key: categoryState.name || '',
                    translation: categoryState?.translation || '',
                  } as DeepTagLexiconItem
                }
                lexiconAttributeValues={lexiconCategories}
              />
            </CategoryLexiconStyled>
          </CategoryContentStyled>
        )}
        {categoryType === CategoryTypeEnum.none && null}
        {categoryType === CategoryTypeEnum.free_text && (
          <CategoryContentStyled>
            <Box
              sx={{
                marginBottom: '16px',
              }}
            >
              <>
                {!!Object.keys(errors).length && (
                  <ErrorsMessagesWrapperStyled>
                    {Object.keys(errors).map(errorKey => {
                      const error = errors[errorKey];
                      return <ErrorLabelStyled key={errorKey}> {error.message} </ErrorLabelStyled>;
                    })}
                  </ErrorsMessagesWrapperStyled>
                )}

                <TextBox
                  label='Category name'
                  value={freeTextValue}
                  error={!!errors.categoryName}
                  onChange={name => onFreeTextChange(name)}
                />
              </>
            </Box>
          </CategoryContentStyled>
        )}
      </ContentCustomStyled>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onSaveChanges} disabled={!isCategoryValueValid}>
            Save
          </Button>
          <Button variant='tertiary' onClick={onCloseDialog}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
