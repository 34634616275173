import { AvailableIcons } from 'src/components-dummy';
import { DomainEntityPath } from 'src/services';
import { ParentDomainBase } from '../../types';

export const storiesDomainGroup: ParentDomainBase = {
  domainKey: DomainEntityPath.Stories,
  title: 'Stories',
  icon: AvailableIcons.AddCircle,
  subDomains: [
    { getCardTitle: () => 'Stories', fieldsSchema: {}, domainKey: DomainEntityPath.Stories },
  ],
};
