import React, { Fragment, useState } from 'react';
import { faStoreAltSlash } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { format } from 'date-fns';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';
import {
  Badge,
  CardItem,
  PopupMenu,
  PopUpMenuItem,
  InitialLetter,
  Button,
  Typography,
  TypographyType,
  TypographyVariant,
  ConfirmationDialog,
} from '../../components-dummy';
import { ShopTypes } from '../../services';
import { Dispatch } from '../types';
import { shopCardActionMethods } from './Actions';
import clock from '../../images/clock.svg';
import './ShopCard.scss';

const UPDATE_AT_DATE_FORMAT = 'dd-MM-yy HH:mm';

export interface ShopCardProps extends ShopTypes.ShopBase {
  className?: string;
  accountId: number;
  enabled?: boolean;
  dispatch: Dispatch;
  onClick?: () => void;
  isSelected: boolean;
  canDeleteShop: boolean;
}

export const ShopCard = ({
  shopId,
  shopName,
  enabled = true,
  updatedAt,
  className,
  accountId,
  onClick,
  dispatch,
  isSelected,
  canDeleteShop,
}: ShopCardProps): JSX.Element => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const onConfirmDelete = () => {
    dispatch(shopCardActionMethods.deleteShop({ shopId, accountId }));
    setShowDeleteDialog(false);
  };

  const onDeleteItemClick = () => {
    setShowDeleteDialog(true);
  };

  const onDialogClose = () => {
    setShowDeleteDialog(false);
  };

  return (
    <CardItem
      className={classNames('syte-shop-card', { 'syte-shop-card-archived': !enabled }, className)}
      onClick={onClick}
      selected={isSelected}
      clickable
    >
      <>
        <div className='syte-shop-card-wrapper'>
          <InitialLetter value={shopName} />
          <div className='shop-card-content'>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              {shopName}
            </Typography>
            <div className='shop-card-details'>
              <div className='shopId'>{shopId}</div>
              <div className='shopCard-time'>
                <img src={clock} alt='last updated' />
                <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
                  {format(updatedAt, UPDATE_AT_DATE_FORMAT)}
                </Typography>
              </div>
            </div>
          </div>
          {/* if there will be more menu items, only hide the delete option instead of whole menu */}
          {canDeleteShop && (
            <PopupMenu position='left center' closeOnContentClick>
              <PopUpMenuItem onClick={() => onDeleteItemClick()}>Delete</PopUpMenuItem>
            </PopupMenu>
          )}
          {enabled || <Badge text='Not active' background='disabled' icon={faStoreAltSlash} />}

          {showDeleteDialog && (
            <ConfirmationDialog onCancel={() => onDialogClose()}>
              <ConfirmationDialog.Header>
                <ConfirmationDialog.Title>Hold on</ConfirmationDialog.Title>
              </ConfirmationDialog.Header>
              <ConfirmationDialog.Content>
                {`Are you sure you want to delete the shop "${shopName}"?`}
              </ConfirmationDialog.Content>
              <ConfirmationDialog.Footer>
                <ButtonsContainer>
                  <Button variant='primary' onClick={onConfirmDelete}>
                    Delete
                  </Button>
                  <Button variant='outlined' onClick={() => onDialogClose()}>
                    Cancel
                  </Button>
                </ButtonsContainer>
              </ConfirmationDialog.Footer>
            </ConfirmationDialog>
          )}
        </div>
      </>
    </CardItem>
  );
};
