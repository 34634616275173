import React from 'react';
import classNames from 'classnames';
import { SwitchTemplateProps } from './types';
import { TitleTemplateStyled } from './Switch.styles';

export const TitleTemplate = ({ children, className }: SwitchTemplateProps): JSX.Element => (
  <TitleTemplateStyled className={classNames('syte-toggle-title', className)}>
    {children}
  </TitleTemplateStyled>
);
