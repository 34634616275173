export const getDateWithoutTime = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const checkIsValidDate = (selectedDate?: Date | null): boolean => {
  if (!selectedDate) return false;
  const isTimeNaN = Number.isNaN(selectedDate?.getTime());
  return !isTimeNaN;
};

export const checkIsOutOfMinRange = ({
  selectedDate,
  minDate,
}: {
  selectedDate?: Date | null;
  minDate?: Date;
}): boolean => {
  const isOutOfRange =
    selectedDate && minDate && getDateWithoutTime(selectedDate) < getDateWithoutTime(minDate);

  return !!isOutOfRange;
};

export const checkIsOutOfMaxRange = ({
  selectedDate,
  maxDate,
}: {
  selectedDate?: Date | null;
  maxDate?: Date;
}): boolean => {
  const isOutOfRange =
    selectedDate && maxDate && getDateWithoutTime(selectedDate) > getDateWithoutTime(maxDate);

  return !!isOutOfRange;
};

export const isValueValid = ({
  selectedDate,
  minDate,
  maxDate,
}: {
  selectedDate?: Date | null;
  minDate?: Date;
  maxDate?: Date;
}): boolean => {
  const isValidDate = checkIsValidDate(selectedDate);
  const isOutOfMinRange = checkIsOutOfMinRange({ selectedDate, minDate });
  const isOutOfMaxRange = checkIsOutOfMaxRange({ selectedDate, maxDate });

  const isValid = isValidDate && !isOutOfMinRange && !isOutOfMaxRange;
  return isValid;
};
