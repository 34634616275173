import { AxiosResponse } from 'axios';

import { featuresRoutesMap } from '../../../../app-routes/features-routes-map';
import { IExperience, IRoutedExperience } from '../types';

export const shopperExperiencesResponse = ({ data }: AxiosResponse): IExperience[] => {
  return data.experiences;
};

export function mapExperiencesWithRoutes(experiences: IExperience[]): IRoutedExperience[] {
  return experiences.map(exp => {
    const routeTo = featuresRoutesMap[exp.feature] ?? '';

    if (!routeTo) {
      console.info(`Failed to create route for ${exp.feature} feature`);
    }

    return {
      route: routeTo,
      ...exp,
    };
  });
}
