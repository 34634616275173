import { useEffect, useMemo, useState } from 'react';
import { AvailableFilterKeysMap } from 'src/services/src/service/types/deep-tag-reports';
import { DeepTagReportFiltersState } from './types';

interface UseMissingAttributeFilterProps {
  attributes: AvailableFilterKeysMap['attributes'];
  attributesFilterState: DeepTagReportFiltersState['attributes'];
}

export const useMissingAttributesFilter = ({
  attributes,
  attributesFilterState,
}: UseMissingAttributeFilterProps): { value: string; text: string }[] => {
  const [attributeOptionsInitial, setAttributeOptionsInitial] = useState<
    { text: string; value: string }[]
  >([]);

  useEffect(() => {
    if (!attributeOptionsInitial.length && Object.keys(attributes).length) {
      const attributeKeys = Object.keys(attributes);

      const attributesInitial = attributeKeys.map(key => {
        const attributeKeyItem = attributes[key];
        const text = `${attributeKeyItem.name}`;

        return {
          text,
          value: key,
        };
      });

      setAttributeOptionsInitial(attributesInitial);
    }
  }, [attributes, attributeOptionsInitial.length]);

  const { value: attributeFilterValues } = attributesFilterState || {};

  const missingAttributeFilterOptions = useMemo(() => {
    const missingAttributeFilterStateSet = new Set(attributeFilterValues);

    return attributeOptionsInitial.filter(item => {
      const { value } = item;

      // Include only the new available `attributes` (FilterKeysMap) & items already in the state.
      return attributes[value] || missingAttributeFilterStateSet.has(value);
    });
  }, [attributes, attributeOptionsInitial, attributeFilterValues]);

  return missingAttributeFilterOptions;
};
