import styled from '@emotion/styled';

export const BlurContainerStyled = styled.div<{ imageSrc: string }>`
  display: flex;
  background-image: url(${({ imageSrc }) => imageSrc});
  justify-content: center;
`;

export const BlurStyled = styled.div`
  width: 100%;
  height: 100%;
  backdrop-filter: blur(50px);
  position: absolute;
`;

export const ImageContainerStyled = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  width: 100%;
  justify-content: center;
`;

export const ImageStyled = styled.img<{ isLandscape: boolean }>`
  ${({ isLandscape }) => {
    if (!isLandscape) {
      return `
        height: 100%;
        object-fit: contain;
      `;
    }

    return '';
  }}

  max-width: 100%;

  transition: width 0.1s;
`;
