import React from 'react';
import { AvailableIcons, Icon, TypographyVariant } from 'src/components-dummy';
import { ImageNotFoundCardStyled, TooltipStyled, TypographyStyled } from './ImageNotFound.styles';

export function ImageNotFound(): JSX.Element {
  return (
    <ImageNotFoundCardStyled className='syte-blurred-image-not-found'>
      <Icon name={AvailableIcons.Portrait} />
      <TooltipStyled
        value='Please delete this image and upload a new image'
        position='bottom center'
      >
        <TypographyStyled variant={TypographyVariant.SmallRegular}>
          Image not found
        </TypographyStyled>
      </TooltipStyled>
    </ImageNotFoundCardStyled>
  );
}
