import { AssignedProduct, Coordinates } from '../../types/entities.types';

export enum StateType {
  loadingImage,
  idle,
  dragging,
  inputActive,
}

export type Size = {
  width: number;
  height: number;
};

export type State = {
  postSize: null | Size;
  type: StateType;
  assignedProducts: AssignedProduct[];
  tagCoordinates: null | Coordinates;
};
