export const isValidURL = (urlString: string): boolean => {
  const urlPattern = new RegExp(
    /^(https?:\/\/)?((([a-zA-Z0-9$_.+!*'(),;?&=-])+(\.[a-zA-Z]{2,}))|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/\S*)?$/
  );
  try {
    if (!urlPattern.test(urlString)) {
      return false;
    }
    new URL(urlString);
    return true;
  } catch {
    return false;
  }
};
