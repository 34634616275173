import React, { ReactNode } from 'react';
import { AvailableIcons, TypographyType, TypographyVariant } from 'src/components-dummy';
import {
  ContainerStyled,
  IconStyled,
  SubTitleStyled,
  TitleStyled,
} from './RuleConditionsAddGroup.styles';

export interface RuleConditionsAddGroupProps {
  title: string | ReactNode | ReactNode[];
  subTitle: string | ReactNode | ReactNode[];
  className?: string;
  onClick: () => void;
}

export const RuleConditionsAddGroup = ({
  title,
  subTitle,
  onClick,
}: RuleConditionsAddGroupProps): JSX.Element => {
  return (
    <ContainerStyled onClick={onClick}>
      <IconStyled name={AvailableIcons.Plus} />
      <TitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
        {title}
      </TitleStyled>
      <SubTitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
        {subTitle}
      </SubTitleStyled>
    </ContainerStyled>
  );
};
