import { DeepTagReportStatus, DeepTagReport } from 'src/services';

export const statusToUserFieldMap: Partial<Record<DeepTagReportStatus, keyof DeepTagReport>> = {
  [DeepTagReportStatus.InReview]: 'reviewer',
  [DeepTagReportStatus.Done]: 'approver',
};

export const userFieldToTextMap: Partial<Record<keyof DeepTagReport, string>> = {
  reviewer: 'Reviewing by',
  approver: 'Reviewed by',
};
