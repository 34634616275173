import styled from '@emotion/styled';
import { AutoComplete } from './AutoComplete';

export const AutoCompleteSingleValueStyled = styled(AutoComplete)<{ errored?: boolean }>`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};

  border: solid 1px
    ${({ theme, errored }) =>
      errored ? theme.palette.custom.red : theme.palette.custom['gray-50']} !important;
  border-radius: 6px;

  &:hover {
    border-radius: 6px;
    border: solid 1px ${({ theme }) => theme.palette.custom['primary-main']};
  }

  .MuiInputBase-input {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;
