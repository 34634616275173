import { MouseEventHandler, useCallback, useRef, useState } from 'react';

interface UseOnLongClickParams {
  msForLongClick: number;
  onShortClick: MouseEventHandler;
  onLongClick?: MouseEventHandler;
}

export function useOnLongClick({
  msForLongClick,
  onShortClick,
  onLongClick,
}: UseOnLongClickParams): {
  isLongClick: boolean;
  onClick: MouseEventHandler;
  onMouseUp: MouseEventHandler;
  onMouseDown: MouseEventHandler;
} {
  const [isLongClick, setIsLongClick] = useState(false);

  const timeoutRef = useRef<number | null>(null);

  const clearTimer = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const handleShortClick: MouseEventHandler = useCallback(
    event => {
      if (isLongClick) {
        event.preventDefault();
        setIsLongClick(false);
        return;
      }

      onShortClick(event);
    },
    [isLongClick, setIsLongClick, onShortClick]
  );

  const handleLongClick: MouseEventHandler = useCallback(
    event => {
      setIsLongClick(true);
      onLongClick?.(event);
    },
    [setIsLongClick, onLongClick]
  );

  const onMouseDown: MouseEventHandler = useCallback(() => {
    timeoutRef.current = setTimeout(handleLongClick, msForLongClick);
  }, [msForLongClick, handleLongClick]);

  const onMouseUp: MouseEventHandler = useCallback(() => {
    clearTimer();
    setIsLongClick(false);
  }, [clearTimer]);

  return {
    isLongClick,
    onClick: handleShortClick,
    onMouseUp,
    onMouseDown,
  };
}
