import React, { useCallback, useState } from 'react';
import {
  AvailableIcons,
  Button,
  Icon,
  TextBox,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { shopApiKeysSettingsActions } from 'src/containers';
import { getDateTomorrow } from 'src/services/src/common/utils';
import { useValidateSchema } from 'src/hooks';
import { getUTCTimeForDatePicker } from 'src/components-dummy/TimePicker/TimePicker.helpers';
import {
  ModalContentStyled,
  ModalStyled,
  ModalTitleStyled,
  ModalFooterStyled,
  LinkButtonAddExpirationStyled,
  LinkButtonRemoveExpirationStyled,
  ExpirationSectionStyled,
  RemoveExpirationSpanStyled,
  ErrorsMessagesWrapperStyled,
  ErrorLabelStyled,
} from './ShopApiKeysSettingsModal.styled';
import { RuleDateTimePicker } from '../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormScheduleDateSection/components/RuleDateTimePicker';
import { ApiKeyFormRule, ShopApiKeysSettingsCreateModalProps } from './types';
import { apiKeyValidationSchema } from './validation-schema';

export const ShopApiKeysSettingsModal = ({
  onModalClose,
  shopId,
  dispatch,
  apiKeys,
}: ShopApiKeysSettingsCreateModalProps): JSX.Element => {
  const [label, setLabel] = useState('');
  const existingLabels = apiKeys.map(apiKey => apiKey.label);
  const [expirationDateTime, setExpirationDateTime] = useState<Date | null>(null);
  const { errors, validate, isValid } = useValidateSchema<ApiKeyFormRule>({
    schema: apiKeyValidationSchema,
    validateOnStart: false,
  });

  const onCreateApiKeyButtonClicked = useCallback(async () => {
    if (isValid && shopId) {
      const apiKeyData: { shopId: number; label: string; expiration?: Date } = {
        shopId,
        label,
      };

      if (expirationDateTime) apiKeyData.expiration = expirationDateTime;

      await dispatch(shopApiKeysSettingsActions.createApiKey(apiKeyData));
      await dispatch(shopApiKeysSettingsActions.getApiKeys({ shopId }));
      onModalClose();
    }
  }, [isValid, shopId, label, expirationDateTime]);

  const onAddExpirationDateToKeyButtonClick = useCallback(async () => {
    const dateTomorrow = getDateTomorrow();
    setExpirationDateTime(dateTomorrow);
    validate({
      dataToValidate: { label, expiration: dateTomorrow },
      context: { existingLabels },
    });
  }, [label, existingLabels, expirationDateTime]);

  const onRemoveExpirationDateButtonClick = useCallback(async () => {
    setExpirationDateTime(null);
    validate({
      dataToValidate: { label },
      context: { existingLabels },
    });
  }, [label, existingLabels, expirationDateTime]);

  const onExpiredDateTimeChanged = useCallback(
    async (dateTime: Date | null) => {
      if (dateTime) {
        setExpirationDateTime(dateTime);
        validate({
          dataToValidate: { label, expiration: dateTime },
          context: { existingLabels },
        });
      }
    },
    [label, existingLabels, setExpirationDateTime]
  );

  return (
    <ModalStyled isOpened onClickOutside={onModalClose}>
      <>
        <ModalTitleStyled>
          <Typography type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
            Create new API Key
          </Typography>
        </ModalTitleStyled>
        <ModalContentStyled>
          {errors.label ? (
            <ErrorsMessagesWrapperStyled>
              <ErrorLabelStyled> {errors.label.message} </ErrorLabelStyled>{' '}
            </ErrorsMessagesWrapperStyled>
          ) : null}
          <TextBox
            error={!!errors?.label}
            label='API Key Label'
            onChange={newLabel => {
              setLabel(newLabel);
              validate({
                dataToValidate: { label: newLabel, expiration: expirationDateTime },
                context: { existingLabels },
              });
            }}
            placeholder='Write here'
            value={label}
          />
          {expirationDateTime ? (
            <ExpirationSectionStyled>
              <RuleDateTimePicker
                value={expirationDateTime}
                minDate={getUTCTimeForDatePicker(expirationDateTime)}
                minTime={getUTCTimeForDatePicker(expirationDateTime)}
                onChange={onExpiredDateTimeChanged}
                dateTitle='Date'
                timeTitle='Time (UTC+0)'
              />
              <LinkButtonRemoveExpirationStyled onClick={onRemoveExpirationDateButtonClick}>
                <Icon name={AvailableIcons.TrashCan} />
                <RemoveExpirationSpanStyled>Remove expiration date</RemoveExpirationSpanStyled>
              </LinkButtonRemoveExpirationStyled>
            </ExpirationSectionStyled>
          ) : (
            <LinkButtonAddExpirationStyled onClick={onAddExpirationDateToKeyButtonClick}>
              <Icon name={AvailableIcons.Plus} /> Add expiration date to key
            </LinkButtonAddExpirationStyled>
          )}
        </ModalContentStyled>
        <ModalFooterStyled>
          <Button variant='tertiary' onClick={onModalClose}>
            Cancel
          </Button>
          <Button variant='primary' onClick={onCreateApiKeyButtonClicked} disabled={!isValid}>
            Create API Key
          </Button>
        </ModalFooterStyled>
      </>
    </ModalStyled>
  );
};
