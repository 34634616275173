import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { lexiconService } from 'src/services';

export const editThematicTagContainerActions = {
  getThematicTag: createAsyncThunk(
    'EditThematicTagContainer/GetThematicTag',
    async (
      parameters: Parameters<typeof lexiconService.getOneThematicTag>[0],
      { rejectWithValue }
    ) => {
      try {
        const res = await lexiconService.getOneThematicTag(parameters);
        return res;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),

  navigateTo: createAction<{ navigateTo: string }>('EditThematicTagContainer/NavigateTo'),
  resetThematicTag: createAction('EditThematicTagContainer/ResetThematicTag'),
};
