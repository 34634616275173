import React from 'react';
import {
  SelectType,
  MenuItem,
  Typography,
  TypographyType,
  TypographyVariant,
  AvailableIcons,
  Select,
} from 'src/components-dummy';
import { MenuItemIconStyled } from './DeepTagProductsPageActionsMenu.styles';

interface DeepTagProductsPageActionsMenuProps {
  onExportToCSV: () => void;
  onAssignToClick: () => void;
  onRegenerateAll: () => void;
}

export const DeepTagProductsPageActionsMenu = ({
  onExportToCSV,
  onAssignToClick,
  onRegenerateAll,
}: DeepTagProductsPageActionsMenuProps): JSX.Element => {
  return (
    <Select type={SelectType.Menu} placeholder='Actions' value=''>
      <MenuItem key='bulkLabeling' value='bulkLabeling' onClick={onAssignToClick}>
        <MenuItemIconStyled name={AvailableIcons.AssignTo} />
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
          Bulk Labeling
        </Typography>
      </MenuItem>
      <MenuItem key='exportCSV' value='exportCSV' onClick={onExportToCSV}>
        <MenuItemIconStyled name={AvailableIcons.Page} />
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
          Export to CSV
        </Typography>
      </MenuItem>
      <MenuItem key='regenerateAll' value='regenerateAll' onClick={onRegenerateAll}>
        <MenuItemIconStyled name={AvailableIcons.AssignTo} />
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
          Regenerate all
        </Typography>
      </MenuItem>
    </Select>
  );
};
