import { Dispatch, SetStateAction, useEffect } from 'react';
import { VisualEditorParams } from '../types/params';
import { PreviewFeature } from '../../../services';
import { useGetQueryParam } from '../../../hooks/use-get-query-param';

type Param = PreviewFeature | string;
type UseSyncParamsWithState = {
  paramName: keyof VisualEditorParams;
  setState?: Dispatch<SetStateAction<Param>>;
};

export const useSyncParamsWithState = ({ paramName, setState }: UseSyncParamsWithState): void => {
  const param = useGetQueryParam(paramName);

  useEffect(() => {
    if (!param) return;
    setState(param);
  }, [param]);
};
