import styled from '@emotion/styled';
import { Button, Icon, Popover } from 'src/components-dummy';
import { NotificationsTab } from '../NotificationsTab';

export const PopoverStyled = styled(Popover)`
  margin-top: 40px;

  .MuiPaper-root {
    border-radius: 8px;
  }
`;

export const HorizontalSeparatorStyled = styled.div`
  margin-right: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
`;

export const NotificationsPopupContentStyled = styled.div`
  max-height: 70vh;
  width: 436px;
  border-radius: 8px;
  padding: 8px 0 0 24px;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  height: 100%;
`;

export const NotificationsPopupHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  align-items: center;
  margin: 12px 24px 0 0;
  padding-bottom: 12px;
`;

export const MarkAllAsReadButtonStyled = styled(Button)`
  margin-left: auto;
  margin-right: 12px;

  ${({ disabled, theme }) => !disabled && `color: ${theme.palette.custom['primary-main']};`}
`;

export const CloseIconStyled = styled(Icon)`
  width: 12px;
`;

export const NotificationsTabStyled = styled(NotificationsTab)`
  padding-right: 24px;
`;
