import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { CatalogManagerService } from 'src/services';

const catalogManagerService = new CatalogManagerService();

/**
 * Actions
 */
export const editExistingCatalogModalContainerActions = {
  resetCatalog: createAction('EditExistingCatalogModalContainer/ResetCatalog'),
  navigateTo: createAction<{ navigateTo: string }>('EditExistingCatalogModalContainer/navigateTo'),
  getCatalog: createAsyncThunk(
    'EditExistingCatalogModalContainer/GetCatalog',
    async (
      parameters: Parameters<CatalogManagerService['getCatalogById']>[0],
      { rejectWithValue }
    ) => {
      try {
        const createdCatalog = await catalogManagerService.getCatalogById(parameters);

        return createdCatalog;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
