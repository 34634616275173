import { NestedMultiSelectOption } from 'src/components-dummy/MultiSelectMenu/types';
import { continents, localesByContinents, UNKNOWN_CONTINENT_VALUE } from './consts';

export function generateOptionsFromRegions({
  regions,
}: {
  regions: string[];
}): NestedMultiSelectOption[] {
  return regions.map(region => {
    for (const continent of continents) {
      const country = localesByContinents[continent][region];
      if (country) {
        return {
          text: country,
          value: region,
          parentTitle: continent,
        };
      }
    }
    return {
      text: region,
      value: region,
      parentTitle: UNKNOWN_CONTINENT_VALUE,
    };
  });
}

export function mapSelectedValuesToSelectedOptions(
  selectedValues: string[],
  parentTitle?: string
): NestedMultiSelectOption[] {
  return selectedValues.map(selectedValue => {
    const currentContinent =
      parentTitle ||
      continents.filter(continent => localesByContinents[continent]?.[selectedValue])[0] ||
      UNKNOWN_CONTINENT_VALUE;

    return {
      value: selectedValue,
      parentTitle: currentContinent,
      text: localesByContinents[currentContinent]?.[selectedValue] || selectedValue,
    };
  });
}

export function removeOptionsByParentTitle(
  options: NestedMultiSelectOption[],
  parentTitle: string
): NestedMultiSelectOption[] {
  return options.filter(option => option.parentTitle !== parentTitle);
}
