import React from 'react';
import { PopUpMenuItem, PopupMenu } from '../../../../components-dummy';
import './Menu.scss';

interface Props {
  onDeleteClicked: () => void;
  onEditClicked: () => void;
}

export const Menu: React.FC<Props> = ({ onDeleteClicked, onEditClicked }) => {
  return (
    <div className='ui-templates-card-menu'>
      <PopupMenu position='top left'>
        <PopUpMenuItem onClick={onEditClicked}>Edit</PopUpMenuItem>
        <PopUpMenuItem onClick={onDeleteClicked}>Delete</PopUpMenuItem>
      </PopupMenu>
    </div>
  );
};
