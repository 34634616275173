import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppNotification } from 'src/app-types';
import { versioningService } from 'src/services';

export const reviewVersionActions = {
  resetSelectedVersion: createAction('ReviewVersion/ResetSelectedVersion'),
  createToast: createAction<Pick<AppNotification, 'customMessage' | 'type'>>(
    'ReviewVersion/CreateToast'
  ),
  getVersion: createAsyncThunk(
    'ReviewVersion/GetVersion',
    async ({ versionId, shopId }: { shopId: number; versionId: string }, { rejectWithValue }) => {
      try {
        const version = await versioningService.getShopVersion({
          shopId,
          slim: false,
          id: versionId,
        });

        return version;
      } catch (error) {
        console.error(error);
        return rejectWithValue({ shopId, error });
      }
    }
  ),
};
