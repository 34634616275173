import { IGalleryTableHeadRow } from './types';

export const tableColumns: Record<string, IGalleryTableHeadRow> = {
  galleryName: {
    Header: 'Gallery Name',
    accessor: 'galleryName',
    minWidth: 50,
    canSort: true,
  },
  totalImages: {
    Header: 'Total Photos',
    accessor: 'totalImages',
    width: 50,
    maxWidth: 50,
  },
  galleryDisplayType: {
    Header: 'Gallery Type',
    accessor: 'galleryDisplayType',
    width: 50,
    maxWidth: 50,
  },
  lastUpdated: {
    Header: 'Last Edit',
    width: 70,
    accessor: 'lastUpdated',
  },
  rowActions: {
    Header: '',
    accessor: 'rowActions',
    width: 20,
    maxWidth: 20,
  },
};

export const tableColumnsArray = Object.values(tableColumns);
