import React from 'react';
import { UserForm, UserFormProps } from '../UserForm';
import './MyProfileForm.scss';

export type MyProfileFormProps = Pick<UserFormProps, 'user' | 'dispatch' | 'formSaveButtonRef'>;

export const MyProfileForm = ({
  user,
  dispatch,
  formSaveButtonRef,
}: MyProfileFormProps): JSX.Element => {
  return (
    <>
      {user && (
        <UserForm
          user={user}
          editingUser={user}
          dispatch={dispatch}
          accountId={user.accountId}
          formSaveButtonRef={formSaveButtonRef}
        />
      )}
    </>
  );
};
