import React, { RefObject } from 'react';

import { Dispatch, TextSimilarityToggle } from 'src/components-bl';
import { AddToCartGeneralForm } from 'src/components-bl/AddToCartSettings/AddToCartGeneralForm';
import { UserRoles } from 'src/services/src/service/types/users';
import { useResultsModalGeneralSettings } from './useResultsModalGeneralSettings';
import { SaveButton } from './SaveButton';

export interface ResultsModalGeneralSettingsProps {
  shopId: number;
  selectedVariantId?: string;
  formHeaderRef?: RefObject<HTMLDivElement | null>;
  role?: UserRoles;
  dispatch: Dispatch;
}

export const ResultsModalGeneralSettings: React.FC<ResultsModalGeneralSettingsProps> = ({
  shopId,
  selectedVariantId,
  formHeaderRef,
  role,
  dispatch,
}) => {
  const isUserAdmin = role === UserRoles.SyteAdmin;

  const { draftGeneralSettings, isDirty, onChangeAddToCart, onChangeTextSimilarity, onSubmit } =
    useResultsModalGeneralSettings({
      shopId,
      selectedVariantId,
      dispatch,
    });

  return (
    <>
      <SaveButton isDirty={isDirty} onSaveChanges={onSubmit} formHeaderRef={formHeaderRef} />
      <AddToCartGeneralForm generalSettings={draftGeneralSettings} onChange={onChangeAddToCart} />
      {isUserAdmin && (
        <TextSimilarityToggle
          enableTextSimilarity={draftGeneralSettings.enableTextSimilarity}
          shopId={shopId}
          dispatch={dispatch}
          onChange={onChangeTextSimilarity}
        />
      )}
    </>
  );
};
