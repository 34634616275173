import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  DeepTagReportsService,
  GetDeepTagReportProductsArguments,
  DeepTagReportProductFilters,
  GetDeepTagReportArguments,
  DeepTagReportsGeneralConfigurationService,
  lexiconService,
} from 'src/services';
import { DeepTagReportFiltersState } from 'src/components-bl/DeepTagReports/components/DeepTagReportFilters/types';

const deepTagReportsService = new DeepTagReportsService();
const deepTagReportsGeneralConfigurationService = new DeepTagReportsGeneralConfigurationService();
export interface GetDeepTagReportProductsActionArguments {
  shopId: number;
  reportId: string;
  locale: string;
}

/**
 * Actions
 */
export const deepTagReportProductsContainerActions = {
  getReport: createAsyncThunk(
    'DeepTagReportProductsContainerActions/GetReport',
    async ({ shopId, reportId }: GetDeepTagReportArguments, { rejectWithValue }) => {
      try {
        const report = await deepTagReportsService.getReport({
          shopId,
          reportId,
        });
        return { report };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getLabelsGeneralConfiguration: createAsyncThunk(
    'DeepTagReportProductsContainerActions/GetGeneralConfigurationLabels',
    async (some, { rejectWithValue }) => {
      try {
        const generalConfigurationLabels =
          await deepTagReportsGeneralConfigurationService.getLabels();
        return { generalConfigurationLabels };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getReportProducts: createAsyncThunk(
    'DeepTagReportProductsContainerActions/GetReportProducts',
    async (
      {
        shopId,
        reportId,
        locale,
        ...filters
      }: GetDeepTagReportProductsActionArguments &
        Pick<GetDeepTagReportProductsArguments, 'skip' | 'limit'> &
        DeepTagReportProductFilters,
      { rejectWithValue }
    ) => {
      try {
        const productsPagination = await deepTagReportsService.getReportProducts({
          shopId,
          locale,
          reportId,
          ...filters,
        });
        return productsPagination;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getLexiconSettings: createAsyncThunk(
    'DeepTagReportProductsContainerActions/getLexiconSettings',
    ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        return lexiconService.getShopLexiconsSettings({ shopId });
      } catch (error: unknown) {
        return rejectWithValue({ error });
      }
    }
  ),
  resetCurrentReport: createAction('DeepTagReportProductsContainerActions/ResetCurrentReport'),
  initFilters: createAction<DeepTagReportFiltersState>(
    'DeepTagReportProductsContainerActions/InitFilters'
  ),
  navigateTo: createAction<{ navigateTo: string }>(
    'DeepTagReportProductsContainerActions/NavigateTo'
  ),
};
