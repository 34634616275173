import React, { useCallback, useImperativeHandle } from 'react';
import { DomainEntityPath } from 'src/services';
import { useShowVersionReviewModal } from 'src/components-bl/Versioning/ReviewVersion';
import { NotificationCardMessageTypography } from '../common';
import { NotificationCardMessageProps } from '../types';
import { getUserDisplayName } from '../utils';
import { domainToDisplayNameMap } from './constants';

function buildDomainDisplayNames(domains: DomainEntityPath[]): string {
  let result = '';
  const hasOnlyOneDomain = domains.length === 1;

  for (let i = 0; i < domains.length; i++) {
    const domain = domains[i];

    const displayName = domainToDisplayNameMap[domain].toLowerCase();

    const isLast = i === domains.length;
    const isFirst = i === 0;

    if (hasOnlyOneDomain) {
      result += displayName;
    } else if (isLast) {
      result += ` and ${displayName}`;
    } else if (isFirst) {
      result += `${displayName}`;
    } else {
      result += `, ${displayName}`;
    }
  }

  return `${result}.`;
}

export function ShopVersionsDraftPublishMessage({
  notification,
  shopId,
  dispatch,
  cardRef,
}: NotificationCardMessageProps<{
  affectedResources: DomainEntityPath[];
  id: string;
}>): JSX.Element {
  const { getVersion } = useShowVersionReviewModal({ shopId, dispatch });

  const onNotificationClick = useCallback(() => {
    getVersion(notification.content.id);
  }, [notification.content.id, getVersion]);

  useImperativeHandle(cardRef, () => ({
    onNotificationClick,
  }));

  const domainsList = buildDomainDisplayNames(notification.content.affectedResources);

  return (
    <NotificationCardMessageTypography>
      {getUserDisplayName(notification.createdByUser)} published shop changes including{' '}
      {domainsList}
    </NotificationCardMessageTypography>
  );
}
