import styled from '@emotion/styled';
import { Page } from 'src/components-dummy';
import { Skeleton } from 'src/components-dummy/Skeleton';

export const PageStyled = styled(Page)`
  height: 100%;
  width: 100%;

  .syte-page-header {
    border-bottom: none;
    display: flex;
    justify-content: space-between;
  }

  .syte-page-sub-title {
    a {
      color: ${props => props.theme.palette.custom['primary-main']};
    }
  }

  .syte-page-content {
    flex-direction: column;
    padding-top: unset;
    margin-top: 40px;
  }

  background: ${({ theme }) => theme.palette.custom['gray-90']};
`;

export const StyledSkeleton = styled(Skeleton)`
  margin: 10px;
`;

export const ActionButtonsWrapper = styled.div`
  align-self: flex-end;

  button:first-child {
    margin-right: 20px;
  }
`;

export const PageTitlesWrapper = styled.div``;
