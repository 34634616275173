import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ShopSFTPSettingsService } from 'src/services';

const shopSFTPService = new ShopSFTPSettingsService();

export const deepTagReportHowToUploadActions = {
  navigateTo: createAction<{ navigateTo: string }>('DeepTagReportHowToUpload/navigateTo'),
  resetSFTPSettings: createAction('DeepTagReportHowToUpload/ResetSFTPSettings'),
  getSFTPSettings: createAsyncThunk(
    'DeepTagReportHowToUpload/GetSFTPSettings',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const settings = await shopSFTPService.getSettings(shopId);
        return { settings };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
