import React, { useState, useCallback } from 'react';
import { useTheme } from '@emotion/react';

import {
  AvailableIcons,
  Button,
  ConfirmationDialog,
  EllipsisMenuButton,
  EllipsisWithTooltip,
  Icon,
  Menu,
  MenuItem,
  SelectOnChangeEvent,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';

import { Dispatch } from 'src/components-dummy/types';
import { formatDateToNow } from 'src/utils/format-date-to-now';
import { RoutedComponentProps } from 'src/app-routes';
import { DeepTagReportStatus, DeepTagReport } from 'src/services';
import { generatePath } from 'react-router';
import { BackDropWithLoader } from 'src/components-dummy/Backdrop/BackdropWithLoader';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';
import {
  CardTagsReportActionsStyled,
  CardTagsReportItemLayoutStyled,
  CardTagsReportItemTitleStyled,
  CardTagsReportItemDetailsStyled,
  CardTagsReportStatusStyled,
  CardTagsReportSummaryStyled,
  IconReportMenuItemStyled,
  ErrorTitleTextStyled,
  MenuItemDeleteStyled,
  ProcessingReportTitleStyled,
  EllipsisWithTooltipStyled,
  ErrorTitleWrapperStyled,
  ErrorIconWrapperStyled,
  DeepTagEditableReportNameWrapperStyled,
} from './DeepTagReportsListItem.styles';
import { deepTagReportsListItemActions } from './Actions';

import { statusToUserFieldMap, userFieldToTextMap } from '../constants';
import { DeepTagReportStatusSelect } from '../../DeepTagReportStatusSelect/DeepTagReportStatusSelect';
import { Bullet } from '../../Bullet';
import { DeepTagReportEditableName } from '../../DeepTagReportEditableName/DeepTagReportEditableName';

/**
 * Utils
 */
function getUserTextByStatus(report: DeepTagReport): string | void {
  const userFieldByStatus = statusToUserFieldMap[report.status];
  const userValue = userFieldByStatus && report[userFieldByStatus];
  if (userValue && userFieldByStatus) {
    return `${userFieldToTextMap[userFieldByStatus]} ${userValue}`;
  }
  return undefined;
}

const menuConfiguration = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: -4,
    horizontal: 'right',
  },
};

/**
 * Main
 */
interface DeepTagReportsListItemProps extends RoutedComponentProps {
  dispatch: Dispatch;
  report: DeepTagReport;
  shopId: number;
}

export const DeepTagReportsListItem = ({
  dispatch,
  report,
  permittedRouteMap,
  shopId,
}: DeepTagReportsListItemProps): JSX.Element => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | undefined>(undefined);
  const [isInProcess, setIsInProcess] = useState<boolean>(false);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState<boolean>(false);

  const menuOpened = !!menuAnchorEl;

  const onTriggerClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const onOptionClick = () => {
    setMenuAnchorEl(undefined);
  };

  const theme = useTheme();

  const handleStatusChange: SelectOnChangeEvent = useCallback(
    event => {
      event.stopPropagation();

      const newStatus = event.target.value as DeepTagReportStatus;

      dispatch(
        deepTagReportsListItemActions.updateReport({
          shopId,
          deepTagReportId: report.id,
          status: newStatus,
        })
      );
    },
    [shopId, report.id]
  );

  const updateReportCustomName = useCallback(
    async ({ customName }: { customName: string }) => {
      return dispatch(
        deepTagReportsListItemActions.updateReport({
          shopId,
          deepTagReportId: report.id,
          customName,
        })
      );
    },
    [shopId, report.id]
  );

  const userTextByStatus = getUserTextByStatus(report);

  const onItemsClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    onOptionClick();
    if (permittedRouteMap.deepTagReportProducts && shopId) {
      dispatch(
        deepTagReportsListItemActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.deepTagReportProducts.path, {
            reportId: report.id,
            shopId,
          }),
        })
      );
    }
  };

  const onDeleteReport = useCallback(
    async event => {
      event.stopPropagation();
      onOptionClick();

      setShowDeleteConfirmationDialog(false);

      setIsInProcess(true);

      await dispatch(
        deepTagReportsListItemActions.deleteReport({
          shopId,
          deepTagReportId: report.id,
        })
      );

      setIsInProcess(false);
    },
    [shopId, report.id]
  );

  const onCloseDeleteDialog = useCallback(() => {
    setShowDeleteConfirmationDialog(false);
  }, []);

  const onOpenDeleteConfirmation = useCallback(
    event => {
      event.stopPropagation();
      onOptionClick();

      setShowDeleteConfirmationDialog(true);
    },
    [shopId, report.id]
  );

  // ATTENTION Do'nt Delete!!
  // Commented out due to unneeded at that moment.
  // Maybe will be used in the future.
  // const onExportSFTPClick = useCallback(
  //   event => {
  //     event.stopPropagation();
  //     onOptionClick();

  //     dispatch(
  //       deepTagReportsListItemActions.exportReportToSFTP({
  //         shopId,
  //         deepTagReportId: report.id,
  //       })
  //     );
  //   },
  //   [shopId, report.id]
  // );

  const onExportFileClick = useCallback(
    event => {
      event.stopPropagation();
      onOptionClick();

      dispatch(
        deepTagReportsListItemActions.exportReportToFile({
          shopId,
          reportId: report.id,
          regenerateTitles: false,
          regenerateDescriptions: false,
          regenerateThematics: false,
        })
      );
    },
    [dispatch, shopId, report.id]
  );

  const onMenuClose = useCallback((event: any) => {
    event.stopPropagation();
    setMenuAnchorEl(undefined);
  }, []);

  const onReportNameChange = useCallback(
    async ({ customName }: { customName: string }) => {
      return updateReportCustomName({ customName });
    },
    [updateReportCustomName]
  );

  const createReportNameComponent = () => {
    const reportName = report.customName || `${report.totalProducts.toLocaleString('en-US')} Items`;

    return (
      <DeepTagEditableReportNameWrapperStyled>
        <DeepTagReportEditableName
          name={reportName}
          onChange={onReportNameChange}
          dynamicWidthMultiplier={9}
          renderText={({ editedText }) => {
            return (
              <Typography type={TypographyType.Body} variant={TypographyVariant.LargeMedium}>
                {editedText}
              </Typography>
            );
          }}
        />
      </DeepTagEditableReportNameWrapperStyled>
    );
  };

  const getCardTagsReportItemsTitleContent = (): JSX.Element => {
    switch (report.status) {
      case DeepTagReportStatus.New: {
        return (
          <>
            <Bullet size={8} fillColor={theme.palette.custom['primary-main']} />
            {createReportNameComponent()}
          </>
        );
      }
      case DeepTagReportStatus.EnrichmentInProgress: {
        return (
          <>
            <ProcessingReportTitleStyled
              type={TypographyType.Body}
              variant={TypographyVariant.LargeMedium}
            >
              Processing Report...
            </ProcessingReportTitleStyled>
          </>
        );
      }

      case DeepTagReportStatus.Error: {
        return (
          <>
            <ErrorIconWrapperStyled>
              <Icon name={AvailableIcons.CircleWarning} />
            </ErrorIconWrapperStyled>
            <ErrorTitleWrapperStyled>
              <Typography type={TypographyType.Body} variant={TypographyVariant.LargeMedium}>
                <ErrorTitleTextStyled>Report processing failed</ErrorTitleTextStyled>
              </Typography>
              {report.error?.message && (
                <ErrorTitleTextStyled>
                  <Typography
                    type={TypographyType.Paragraph}
                    variant={TypographyVariant.SmallRegular}
                  >
                    <EllipsisWithTooltip tooltipText={report.error?.message}>
                      {report.error?.message}
                    </EllipsisWithTooltip>
                  </Typography>
                </ErrorTitleTextStyled>
              )}
            </ErrorTitleWrapperStyled>
          </>
        );
      }
      default:
        return (
          <Typography type={TypographyType.Body} variant={TypographyVariant.LargeMedium}>
            {createReportNameComponent()}
          </Typography>
        );
    }
  };

  const showStatusSelect =
    report.status !== DeepTagReportStatus.Error &&
    report.status !== DeepTagReportStatus.EnrichmentInProgress;

  const shouldShowExportMenuItems =
    report.status !== DeepTagReportStatus.Error &&
    report.status !== DeepTagReportStatus.EnrichmentInProgress;

  return (
    <>
      {isInProcess && <BackDropWithLoader />}
      {showDeleteConfirmationDialog && (
        <ConfirmationDialog onCancel={onCloseDeleteDialog}>
          <ConfirmationDialog.Header>
            <ConfirmationDialog.Title>
              {report.status === DeepTagReportStatus.EnrichmentInProgress
                ? 'Delete Processing Report'
                : 'Delete Report'}
            </ConfirmationDialog.Title>
          </ConfirmationDialog.Header>
          <ConfirmationDialog.Content>
            {report.status === DeepTagReportStatus.EnrichmentInProgress
              ? 'Are you sure? Processing a file can take some time'
              : 'Are you sure you want to delete report?'}
          </ConfirmationDialog.Content>
          <ConfirmationDialog.Footer>
            <ButtonsContainer>
              <Button variant='destructive' onClick={onDeleteReport}>
                Delete
              </Button>
              <Button variant='tertiary' onClick={onCloseDeleteDialog}>
                Cancel
              </Button>
            </ButtonsContainer>
          </ConfirmationDialog.Footer>
        </ConfirmationDialog>
      )}
      <CardTagsReportItemLayoutStyled
        shouldShowPointer={report.status !== DeepTagReportStatus.Error}
        onClick={
          report.status !== DeepTagReportStatus.Error &&
          report.status !== DeepTagReportStatus.EnrichmentInProgress
            ? onItemsClick
            : undefined
        }
      >
        <CardTagsReportSummaryStyled>
          <CardTagsReportItemTitleStyled>
            {getCardTagsReportItemsTitleContent()}
          </CardTagsReportItemTitleStyled>
          <CardTagsReportItemDetailsStyled>
            <Typography type={TypographyType.Body} variant={TypographyVariant.ExtraSmallRegular}>
              {formatDateToNow(report.createdAt)}
            </Typography>
            <Bullet size={4} className='deep-tag-reports-details-dot' />
            <EllipsisWithTooltipStyled tooltipText={`Source file name: ${report.sourceFileName}`}>
              <Typography type={TypographyType.Body} variant={TypographyVariant.ExtraSmallRegular}>
                {report.sourceFileName}
              </Typography>
            </EllipsisWithTooltipStyled>
            {userTextByStatus && (
              <>
                <Bullet size={4} className='deep-tag-reports-details-dot' />
                <Typography
                  type={TypographyType.Body}
                  variant={TypographyVariant.ExtraSmallRegular}
                >
                  {userTextByStatus}
                </Typography>
              </>
            )}
          </CardTagsReportItemDetailsStyled>
        </CardTagsReportSummaryStyled>
        <CardTagsReportStatusStyled>
          {showStatusSelect && (
            <DeepTagReportStatusSelect value={report.status} onChange={handleStatusChange} />
          )}
        </CardTagsReportStatusStyled>
        <CardTagsReportActionsStyled>
          <EllipsisMenuButton onClick={onTriggerClick} isActive={!!menuAnchorEl} />
          <Menu
            open={menuOpened}
            anchorEl={menuAnchorEl}
            onClose={onMenuClose}
            anchorOrigin={menuConfiguration.anchorOrigin as any}
            transformOrigin={menuConfiguration.transformOrigin as any}
          >
            {shouldShowExportMenuItems && (
              <MenuItem key='download' value='download' onClick={onExportFileClick}>
                <IconReportMenuItemStyled name={AvailableIcons.Download} />
                <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                  Download as CSV
                </Typography>
              </MenuItem>
            )}
            {/* ATTENTION Do'nt Delete!! maybe will be used in the future */}
            {/* {showExportMenuItems && (
              <MenuItem key='pushsftp' value='pushsftp' onClick={onExportSFTPClick}>
                <IconReportMenuItemStyled name={AvailableIcons.CloudPush} />
                <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                  Push to SFTP
                </Typography>
              </MenuItem>
            )} */}
            <MenuItemDeleteStyled key='delete' value='delete' onClick={onOpenDeleteConfirmation}>
              <IconReportMenuItemStyled name={AvailableIcons.TrashCan} />
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                Delete
              </Typography>
            </MenuItemDeleteStyled>
          </Menu>
        </CardTagsReportActionsStyled>
      </CardTagsReportItemLayoutStyled>
    </>
  );
};
