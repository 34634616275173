import React from 'react';
import { Button, ConfirmationDialog } from 'src/components-dummy';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

export const UseUuidModal = ({
  onRunExperiment,
  setShowUseUuidDialog,
}: {
  onRunExperiment: () => void;
  setShowUseUuidDialog: (newValue: boolean) => void;
}): JSX.Element => {
  return (
    <ConfirmationDialog>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Note</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        If utilizing Syte via API integration, kindly ensure inclusion of UUID in all Syte events
        before initiating experiments.
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onRunExperiment}>
            Run the test
          </Button>
          <Button variant='outlined' onClick={() => setShowUseUuidDialog(false)}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
