import React, { useCallback, useEffect, useState } from 'react';
import { TextBox, useDebounce } from 'src/components-dummy';

export const DisplayNameCell = ({
  value,
  onChange,
  error,
}: {
  value: string;
  onChange: (newValue: string) => void;
  error?: string;
}): JSX.Element => {
  const [displayName, setDisplayName] = useState(value);

  const debouncedOnChange = useDebounce(onChange, 500);

  const onDisplayNameChange = useCallback(
    (newName: string) => {
      setDisplayName(newName);

      debouncedOnChange(newName);
    },
    [debouncedOnChange, setDisplayName]
  );

  useEffect(() => {
    if (value !== displayName) {
      setDisplayName(value);
    }
  }, [value, setDisplayName]);

  return (
    <TextBox
      value={displayName}
      placeholder='Type...'
      onChange={onDisplayNameChange}
      error={error}
    />
  );
};
