import { DiscoveryBannerSettings } from 'src/services/src/service/types/syte-products/discovery-banner';
/* --= Action Types =-- */
export enum DiscoveryBannerActionTypes {
  GetDiscoveryBannerSettingsRequest = 'DiscoveryBanner/GetDiscoveryBannerSettingsRequest',
  GetDiscoveryBannerSettingsSuccess = 'DiscoveryBanner/GetDiscoveryBannerSettingsSuccessAction',
  GetDiscoveryBannerSettingsError = 'DiscoveryBanner/GetDiscoveryBannerSettingsError',
  UpdateDiscoveryBannerSettingsRequest = 'DiscoveryBanner/UpdateDiscoveryBannerSettingsRequest',
  UpdateDiscoveryBannerSettingsSuccess = 'DiscoveryBanner/UpdateDiscoveryBannerSettingsSuccess',
  UpdateDiscoveryBannerSettingsError = 'DiscoveryBanner/UpdateDiscoveryBannerSettingsError',
  NotifyDiscoveryBannerSettingsIsDirty = 'DiscoveryBanner/NotifyIsDirty',
  PartialDiscoveryBannerSettingsUpdate = 'DiscoveryBanner/PartialDiscoveryBannerSettingsUpdate',
  NavigateTo = 'DiscoveryBanner/NavigateTo',
}

export interface UpdateDiscoveryBannerSettingsPayload extends DiscoveryBannerSettings {
  shopId: number;
  variantId?: string;
}

export interface GetDiscoveryBannerSettingsArgs {
  shopId: number;
  variantId?: string;
}

export interface GetDiscoveryBannerSettingsRequestPayload {
  shopId: number;
}

export interface GetDiscoveryBannerSettingsSuccessPayload extends DiscoveryBannerSettings {
  shopId: number;
}

export interface GetDiscoveryBannerSettingsErrorPayload {
  error: unknown;
}

export interface UpdateDiscoveryBannerSettingsRequestPayload extends DiscoveryBannerSettings {
  shopId: number;
}

export interface UpdateDiscoveryBannerSettingsSuccessPayload extends DiscoveryBannerSettings {
  shopId: number;
}

export interface UpdateDiscoveryBannerSettingsErrorPayload {
  error: unknown;
}

export interface NotifyDiscoveryBannerSettingsIsDirtyActionPayload {
  isDirty: boolean;
}

/* --= Action Interfaces =-- */

export interface GetDiscoveryBannerSettingsRequestAction {
  type: typeof DiscoveryBannerActionTypes.GetDiscoveryBannerSettingsRequest;
  payload: GetDiscoveryBannerSettingsRequestPayload;
}

export interface GetDiscoveryBannerSettingsSuccessAction {
  type: typeof DiscoveryBannerActionTypes.GetDiscoveryBannerSettingsSuccess;
  payload: GetDiscoveryBannerSettingsSuccessPayload;
}

export interface GetDiscoveryBannerSettingsErrorAction {
  type: typeof DiscoveryBannerActionTypes.GetDiscoveryBannerSettingsError;
  payload: GetDiscoveryBannerSettingsErrorPayload;
}

export interface UpdateDiscoveryBannerSettingsRequestAction {
  type: typeof DiscoveryBannerActionTypes.UpdateDiscoveryBannerSettingsRequest;
  payload: UpdateDiscoveryBannerSettingsRequestPayload;
}

export interface UpdateDiscoveryBannerSettingsSuccessAction {
  type: typeof DiscoveryBannerActionTypes.UpdateDiscoveryBannerSettingsSuccess;
  payload: UpdateDiscoveryBannerSettingsSuccessPayload;
}

export interface UpdateDiscoveryBannerSettingsErrorAction {
  type: typeof DiscoveryBannerActionTypes.UpdateDiscoveryBannerSettingsError;
  payload: UpdateDiscoveryBannerSettingsErrorPayload;
}

export interface NotifyDiscoveryBannerSettingsIsDirtyAction {
  type: typeof DiscoveryBannerActionTypes.NotifyDiscoveryBannerSettingsIsDirty;
  payload: NotifyDiscoveryBannerSettingsIsDirtyActionPayload;
}

export type DiscoveryBannerSettingsAction =
  | GetDiscoveryBannerSettingsRequestAction
  | GetDiscoveryBannerSettingsSuccessAction
  | GetDiscoveryBannerSettingsErrorAction
  | UpdateDiscoveryBannerSettingsRequestAction
  | UpdateDiscoveryBannerSettingsSuccessAction
  | UpdateDiscoveryBannerSettingsErrorAction
  | NotifyDiscoveryBannerSettingsIsDirtyAction;
