import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { visualEditorActions } from '../../state';
import {
  MaxMerchandisingRulesLimitDialog,
  useValidateMerchandisingRuleLimit,
} from 'src/components-bl/MerchandisingRules/components/MaxMerchandisingRulesLimit';
import { MerchandisingRulesList } from './MerchandisingRulesList';
import {
  Accordion,
  AvailableIcons,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { ExternalLink } from '../ExternalLink';
import { RulesSubtitle } from './RulesSubtitle';
import { useMerchRulesList } from '../../hooks';
import { SearchInputStyled } from './SearchInput';
import { useVisualEditorProvider } from '../../context';
import { useCollapse } from '../../hooks/useCollapse';
import { useNavigateToVisualEditorRoute } from '../../hooks/useNavigateToVisualEditorRoute';
import { PreviewMerchRule } from '../../types';
import { PermittedRouteMap } from '../../../../app-routes';
import {
  AccordionTriggerGroup,
  FlexContainer,
  VisualEditorForm,
  VisualEditorSubmenu,
} from '../VisualEditor.styles';

const accordionId = 'merch-rules-menu';

interface Props {
  shopId: number;
  selectedRule: PreviewMerchRule | null;
  permittedRouteMap: PermittedRouteMap;
}

export function MerchandisingRules({
  shopId,
  selectedRule,
  permittedRouteMap,
}: Props): JSX.Element | null {
  const { fetchOffers } = useVisualEditorProvider();
  const { expandedId, expand } = useCollapse(accordionId);
  const dispatch = useDispatch();

  const { navigateToVisualEditorRoute } = useNavigateToVisualEditorRoute({
    route: permittedRouteMap?.visualEditorCreateMerchandisingRule,
  });

  const createMerchRule = useCallback(() => {
    dispatch(visualEditorActions.setSelectedRule(null));
    navigateToVisualEditorRoute({ shopId });
  }, [navigateToVisualEditorRoute, shopId]);

  const {
    loading,
    ruleFilterValue,
    showMerchRules,
    changeFilterValue,
    showFilteredRules,
    merchandisingRules,
    navigateToMerchRulesPage,
    toggleRuleAndRefresh,
    onRuleClick,
  } = useMerchRulesList({
    shopId,
    dispatch,
    onRuleSaved: fetchOffers,
    permittedRouteMap,
  });

  const {
    showRuleLimitReachedDialog,
    onRuleLimitDialogClose,
    onCreateRuleButtonClick,
    validateMerchandisingRuleLimit,
  } = useValidateMerchandisingRuleLimit({
    shopId,
    createMerchandisingRule: createMerchRule,
    dispatch,
  });

  useEffect(() => {
    validateMerchandisingRuleLimit();
  }, [merchandisingRules]);

  useEffect(() => {
    fetchOffers();
  }, []);

  if (!showMerchRules) return null;

  return (
    <VisualEditorForm>
      <Accordion expandedIds={expandedId} onSelectionChanged={expand}>
        <Accordion.Item id={accordionId}>
          <Accordion.Item.Header>
            <FlexContainer>
              <AccordionTriggerGroup>
                <Typography type={TypographyType.Body} variant={TypographyVariant.LargeRegular}>
                  Merchandising Rules
                </Typography>
                <Icon className={!expandedId ? 'closed' : ''} name={AvailableIcons.RightArrow} />
              </AccordionTriggerGroup>
              <ExternalLink showIcon onClick={navigateToMerchRulesPage}>
                View all rules
              </ExternalLink>
            </FlexContainer>
          </Accordion.Item.Header>
          <Accordion.Item.Content>
            <VisualEditorSubmenu>
              <FlexContainer>
                <RulesSubtitle>Displaying all relevant rules</RulesSubtitle>
                <ExternalLink onClick={onCreateRuleButtonClick}>Add new rule</ExternalLink>
              </FlexContainer>
              <SearchInputStyled
                value={ruleFilterValue}
                onChange={changeFilterValue}
                placeholder='Search...'
              />
              <MerchandisingRulesList
                loading={loading}
                selectedRuleId={selectedRule?.id}
                showFilteredRules={showFilteredRules}
                merchandisingRules={merchandisingRules}
                onRuleToggle={toggleRuleAndRefresh}
                onRuleClick={onRuleClick}
              />
              {showRuleLimitReachedDialog && (
                <MaxMerchandisingRulesLimitDialog onClose={onRuleLimitDialogClose} />
              )}
            </VisualEditorSubmenu>
          </Accordion.Item.Content>
        </Accordion.Item>
      </Accordion>
    </VisualEditorForm>
  );
}
