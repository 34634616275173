import styled from '@emotion/styled';
import { Button, Typography } from 'src/components-dummy';
import { Card } from 'src/components-dummy/Card';

export const CardStyled = styled(Card)`
  border: unset;
  box-shadow: 0px 8px 8px -4px #00000014;
  padding: 40px 0;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const ImageStyled = styled.img``;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
`;

export const TitleStyled = styled(Typography)`
  margin-bottom: 10px;
`;

export const SubtitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const ButtonStyled = styled(Button)`
  margin-top: 20px;
`;
