import React from 'react';
import styled from '@emotion/styled';

interface Props {
  children: React.ReactNode;
  divider?: boolean;
}

export const FormBody = styled.div<Props>`
  ${props => props.divider && `border-top: 1px solid #dedede;`}
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  margin-top: 24px;
  gap: 20px;
`;
