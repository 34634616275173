import React, { ReactNode } from 'react';

import { FindComponentByTypeArguments, FindComponentsByTypeArguments } from './types';

export function isChildComponentOfType(
  child: JSX.Element | ReactNode,
  componentType: { name: string }
): boolean {
  return (child as JSX.Element)?.type?.name === componentType.name;
}

export function findComponentsByType({
  type,
  children = [],
}: FindComponentsByTypeArguments): JSX.Element[] {
  const components = React.Children.toArray(children).filter(child =>
    isChildComponentOfType(child, type)
  ) as JSX.Element[];
  return components;
}

export function findComponentByType({
  type,
  children = [],
}: FindComponentByTypeArguments): JSX.Element | null {
  const component = React.Children.toArray(children).find(child =>
    isChildComponentOfType(child, type)
  ) as JSX.Element | undefined;
  return component || null;
}

export function findComponentsExceptOfType({
  type,
  children = [],
}: FindComponentsByTypeArguments): JSX.Element[] {
  const components = React.Children.toArray(children).filter(
    child => !isChildComponentOfType(child, type)
  ) as JSX.Element[];
  return components;
}
