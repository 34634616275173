import React, { useState } from 'react';
import { useFilterItems } from 'src/hooks';
import { SearchInputStyled, MultiSelectMenuWithSearchStyled } from './MultiSelectMenu.styles';
import { MultiSelectMenuWithSearchProps } from './types';
import { MultiSelectMenu } from './MultiSelectMenu';

export const MultiSelectMenuWithSearch = ({
  searchPlaceholder = 'Search items...',
  options,
  searchValue = '',
  searchInputRef,
  ...rest
}: MultiSelectMenuWithSearchProps): JSX.Element => {
  const [searchInputState, setSearchInputState] = useState(searchValue);

  const handleSearchChange = (text: string) => {
    setSearchInputState(text);
  };

  const { filteredItems: filteredOptions } = useFilterItems({
    items: options,
    searchableFields: ['text'],
    idField: 'value',
    filterText: searchInputState,
  });

  const showToggleAllOption = searchInputState.length === 0;

  return (
    <MultiSelectMenuWithSearchStyled>
      <SearchInputStyled
        placeholder={searchPlaceholder}
        value={searchInputState}
        onChange={handleSearchChange}
        ref={searchInputRef}
      />
      <MultiSelectMenu
        options={filteredOptions}
        {...rest}
        enableSelectAll={showToggleAllOption && rest.enableSelectAll}
      />
    </MultiSelectMenuWithSearchStyled>
  );
};
