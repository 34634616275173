import React from 'react';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

interface RestoreVersionConfirmationDialogProps {
  onCloseDialog: () => void;
  onRestore: () => void;
}

export const RestoreVersionConfirmationDialog = ({
  onCloseDialog,
  onRestore,
}: RestoreVersionConfirmationDialogProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onCloseDialog}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Hold on</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        Are you sure you want to restore from this version?
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onRestore}>
            Restore
          </Button>
          <Button variant='outlined' onClick={onCloseDialog}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
