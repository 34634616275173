import { CatalogUploadLogsStatusEnum } from 'src/services/src/service/types/catalog-manager/catalog-manager';
import {
  ICatalogFeedUploadLogTableBodyRowAdmin,
  ICatalogFeedUploadLogTableBodyRowBase,
  ICatalogFeedUploadLogTableBodyRowClient,
  ICatalogFeedUploadLogTableHeadRow,
} from './types';

export const CatalogUploadLogsTableStatusTextEnum = {
  [CatalogUploadLogsStatusEnum.Success]: 'Successful',
  [CatalogUploadLogsStatusEnum.Processed]: 'Processed',
  [CatalogUploadLogsStatusEnum.Failed]: 'Failed',
  [CatalogUploadLogsStatusEnum.Warning]: 'Warning',
};

export const constRowActionsColumnConfigObject = {
  rowActions: {
    Header: '',
    accessor: 'rowActions',
    width: 32,
    maxWidth: 32,
    canResize: false,
  },
};

export const tableColumnsBase: Record<
  keyof Omit<ICatalogFeedUploadLogTableBodyRowBase, 'originalData'>,
  ICatalogFeedUploadLogTableHeadRow
> = {
  startDate: {
    Header: 'Start Time',
    accessor: 'startDate',
  },
  endDate: {
    Header: 'End Time',
    accessor: 'endDate',
  },
  durationInMilliseconds: {
    Header: 'Run Duration',
    accessor: 'durationInMilliseconds',
  },
  status: {
    Header: 'Status',
    accessor: 'status',
  },
  numberOfIndexedProducts: {
    Header: 'Products Indexed',
    accessor: 'numberOfIndexedProducts',
  },
  numberOfFailedProducts: {
    Header: 'Products Failed',
    accessor: 'numberOfFailedProducts',
  },
  errorReason: {
    Header: 'Error Reason',
    accessor: 'errorReason',
  },
  actionRequired: {
    Header: 'Action',
    accessor: 'actionRequired',
  },
};

export const tableColumnsClient: Record<
  keyof Omit<ICatalogFeedUploadLogTableBodyRowClient, 'originalData'>,
  ICatalogFeedUploadLogTableHeadRow
> = {
  ...tableColumnsBase,
  ...constRowActionsColumnConfigObject,
};

export const tableColumnsAdmin: Record<
  keyof Omit<ICatalogFeedUploadLogTableBodyRowAdmin, 'originalData'>,
  ICatalogFeedUploadLogTableHeadRow
> = {
  ...tableColumnsBase,
  errorReasonInternal: {
    Header: 'Internal error message',
    accessor: 'errorReasonInternal',
    width: 12,
  },
  actionRequiredInternal: {
    Header: 'Internal action',
    accessor: 'actionRequiredInternal',
    width: 10,
  },
  ...constRowActionsColumnConfigObject,
};
