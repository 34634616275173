import styled from '@emotion/styled';
import { Select } from 'src/components-dummy';

export const PermissionsDropDownStyled = styled(Select)`
  margin-right: 10px;
  width: 185px;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    box-shadow: none;
  }
`;

export const SwitchDropDownGroupStyled = styled.div`
  display: flex;
`;
