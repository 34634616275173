import styled from '@emotion/styled';

export const SectionColorPalleteStyled = styled.div`
  display: flex;
  width: auto;
  flex-wrap: wrap;
  & :first-child {
    margin-right: 80px;
  }
`;

export const SectionColorPalleteTitleStyled = styled.div`
  margin-bottom: 6px;
`;
