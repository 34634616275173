import React from 'react';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

interface UploadNewFileConfirmationDialogProps {
  onCloseDialog: () => void;
  onConfirm: () => void;
}

export const UploadNewFileConfirmationDialog = ({
  onCloseDialog,
  onConfirm,
}: UploadNewFileConfirmationDialogProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onCloseDialog}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Upload a new Gallery file</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        You are about to upload a new gallery file.
        <br />
        Note, that this will override the existing gallery file.
        <br />
        Would you like to proceed?
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onConfirm}>
            Yes, upload a new file
          </Button>
          <Button variant='outlined' onClick={onCloseDialog}>
            No, let’s go back
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
