import styled from '@emotion/styled';
import { Typography } from '../../../../components-dummy';

export const PageTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ConditionsGroupStyled = styled.div`
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  border-radius: 12px;
  padding: 24px;
  margin-top: 16px;
`;

export const ConditionsGroupTitle = styled(Typography)``;

export const ConditionsGroupSubTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-20']};
  margin-bottom: 16px;
  margin-top: 3px;
  display: block;
`;

export const ConditionsGroupContent = styled.div``;

export const ConditionRowStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ConditionText = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-20']};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin: 16px 8px;
`;
