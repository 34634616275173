import { ShopFeature } from '../../app-types';
import { SyteProductType } from '../../services/src/service/types/constants';

export const convertFeatureToSyteProduct = (feature: ShopFeature): SyteProductType | undefined => {
  switch (feature) {
    case ShopFeature.AugmentedSearch:
      return SyteProductType.AugmentedSearch;
    case ShopFeature.SimilarItems:
      return SyteProductType.SimilarItems;
    case ShopFeature.ShopTheLook:
      return SyteProductType.ShopTheLook;
    case ShopFeature.Camera:
      return SyteProductType.VisualSearch;
    case ShopFeature.DiscoveryButton:
      return SyteProductType.DiscoveryButton;
    case ShopFeature.Personalization:
      return SyteProductType.Personalization;
    case ShopFeature.Collections:
      return SyteProductType.Collections;
    case ShopFeature.BrowsePLP:
      return SyteProductType.Collections;
    default:
      return undefined;
  }
};
