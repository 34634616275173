import Joi, { AnySchema } from 'joi';
import { CustomInspirationsGalleryImage } from 'src/services';

const MIN_CHARACTERS = 1;
const MAX_CHARACTERS = 150;

export const galleryImageSettingsValidationSchema: Record<
  keyof Pick<CustomInspirationsGalleryImage, 'altText' | 'caption'>,
  AnySchema
> = {
  altText: Joi.string()
    .optional()
    .min(MIN_CHARACTERS)
    .max(MAX_CHARACTERS)
    .messages({
      'string.min': `Alt text should be more than ${MIN_CHARACTERS} characters`,
      'string.max': `Alt text should be less than or equal to ${MAX_CHARACTERS} characters`,
    }),
  caption: Joi.string()
    .optional()
    .min(MIN_CHARACTERS)
    .max(MAX_CHARACTERS)
    .messages({
      'string.min': `Caption should be more than ${MIN_CHARACTERS} characters`,
      'string.max': `Caption should be less than or equal to ${MAX_CHARACTERS} characters`,
    }),
};
