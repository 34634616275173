import styled from '@emotion/styled';
import { Icon, Link } from 'src/components-dummy';

const headerHeight = 76;

export const ThematicTagFormStyled = styled.form`
  width: 100%;
  height: 100%;
`;

export const HeaderStyled = styled.div`
  height: ${headerHeight}px;
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};
`;

export const HeaderActionButtonsStyled = styled.div`
  margin-left: auto;
  display: flex;
  gap: 5px;
`;

export const MainStyled = styled.div`
  height: calc(100% - ${headerHeight}px);
  background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  padding: 32px 40px;
  overflow: auto;
`;

export const DescriptionStyled = styled.div`
  margin-bottom: 32px;

  p {
    margin: 10px 0px;
  }
`;

export const LinkStyled = styled(Link)`
  display: flex;
`;

export const LinkIconStyled = styled(Icon)`
  margin-left: 5px;
`;
