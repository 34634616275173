import React, { useState, useMemo, useCallback } from 'react';
import {
  TypographyType,
  TypographyVariant,
  MenuItem,
  Typography,
  Popover,
} from 'src/components-dummy';
import {
  ListV2Styled,
  MenuSingleSelectTagContentStyled,
  SearchInputStyled,
} from './DeepTagProductSingleMenuSelectStyle';

export interface DeepTagProductSingleMenuSelectProps {
  options: { value: string; text: string }[];
  setTriggerComponent: (onAnchorElementSet: (element: HTMLDivElement) => void) => JSX.Element;
  onChange: (selectedValue: string) => void;
  isMenuSelectEnabled: boolean;
  currentValue?: string;
}

export const DeepTagProductSingleMenuSelect = ({
  onChange,
  setTriggerComponent,
  options,
  currentValue,
}: DeepTagProductSingleMenuSelectProps): JSX.Element => {
  const [searchInputState, setSearchInputState] = useState('');
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleSearchChange = (text: string) => {
    setSearchInputState(text);
  };

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onOptionClick = (selectedValue: string) => {
    onChange(selectedValue);
    setSearchInputState('');
    onClose();
  };

  const filteredOptions = useMemo(
    () =>
      options.filter(option => {
        return option.text.toLowerCase().includes(searchInputState.toLowerCase());
      }),
    [searchInputState, options]
  );

  const onAnchorElementSet = useCallback(element => {
    setAnchorEl(element);
  }, []);

  const isOpen = !!anchorEl;

  return (
    <div>
      {setTriggerComponent(onAnchorElementSet)}
      <Popover
        open={isOpen}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{ marginTop: '8px' }}
      >
        <MenuSingleSelectTagContentStyled>
          <SearchInputStyled
            placeholder='Search...'
            value={searchInputState}
            onChange={handleSearchChange}
            disabled={!options.length}
          />
          <ListV2Styled>
            {filteredOptions.map(option => {
              return (
                <MenuItem
                  selected={option.value === currentValue}
                  key={option.text}
                  value={`${option.value}`}
                  onClick={() => onOptionClick(option.value)}
                >
                  <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                    {option.text}
                  </Typography>
                </MenuItem>
              );
            })}
          </ListV2Styled>
        </MenuSingleSelectTagContentStyled>
      </Popover>
    </div>
  );
};
