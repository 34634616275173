import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ShopAIModelSettings } from 'src/components-bl/ShopAIModelSettings';
import { useAppSelector } from 'src/hooks';
import { shopAiModelSettingsActions } from '../ShopSettings/Actions/ShopSettingsContainerActions';

export const AIModelSettingsContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const { current: currentShop } = useAppSelector(state => state.shop);
  const { aiModel } = useAppSelector(state => state.aiModel);
  const { aiModelList } = useAppSelector(state => state.aiModel);

  useEffect(() => {
    if (currentShop) {
      dispatch(
        shopAiModelSettingsActions.getAIModelListWithShopModel({ shopId: currentShop.shopId })
      );
    }
  }, [currentShop?.shopId]);

  return (
    <>
      {currentShop && (
        <ShopAIModelSettings
          currentModel={aiModel}
          aiModelList={aiModelList}
          shop={currentShop}
          dispatch={dispatch}
        />
      )}
    </>
  );
};
