import styled from '@emotion/styled';
import { TableV2 } from 'src/components-dummy';

export const TableBodyRowCellStyled = styled(TableV2.BodyRowCell)`
  color: ${({ theme }) => theme.palette.common.black};
  padding-top: 10px;
  padding-bottom: 10px;

  a {
    cursor: pointer;
    color: ${({ theme }) => theme.palette.custom['primary-main']};
    & > div > div {
      text-decoration: underline;
    }
  }
`;
