import React from 'react';
import { Checkbox } from '../../components-dummy';
import { AddMultipleToCart } from '../../app-state-types/reducer-state-types/add-to-cart';
import {
  FormContainer,
  FormTitle,
  FormBody,
  FormItem,
  FormSwitch,
  Tooltip,
} from '../../components-dummy/SettingsForm';

interface Props {
  showSettingsForm: boolean;
  addToCart: AddMultipleToCart;
  onChange: (config: AddMultipleToCart) => void;
  shopId: number;
}

export function AddMultipleToCartForm({
  showSettingsForm,
  addToCart,
  onChange,
  shopId,
}: Props): JSX.Element | null {
  if (!showSettingsForm) return null;
  const path = `/shops/${shopId}/recommendation-engines/general-settings`;
  const tip = (
    <Tooltip.Text>
      Navigate to the&nbsp;
      <Tooltip.Link path={path}>Carousel settings</Tooltip.Link>
      &nbsp;page to edit the Add to Cart UI layout
    </Tooltip.Text>
  );
  return (
    <FormContainer>
      <FormTitle tip={tip}>Add to cart</FormTitle>
      <FormBody divider>
        <FormItem column>
          <FormSwitch
            label='Enable add to cart'
            checked={addToCart.active}
            onChange={active => onChange({ ...addToCart, active })}
          />
          {addToCart.active && (
            <FormItem nested>
              <Checkbox
                label='Show bundle add to cart'
                checked={addToCart.isBundle}
                onChange={isBundle => onChange({ ...addToCart, isBundle })}
              />
            </FormItem>
          )}
        </FormItem>
      </FormBody>
    </FormContainer>
  );
}
