import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

interface CreateOptionalPortalArguments<T extends HTMLElement | null> {
  portalContent: ReactNode;
  targetContainerRef: React.RefObject<T> | undefined;
  fallback: ReactNode | undefined;
}

export const createOptionalPortal = <T extends HTMLElement | null>({
  portalContent,
  targetContainerRef,
  fallback,
}: CreateOptionalPortalArguments<T>): ReactNode | null => {
  const jsx = targetContainerRef
    ? (targetContainerRef.current &&
        ReactDOM.createPortal(portalContent, targetContainerRef.current as HTMLElement)) ||
      null
    : fallback;
  return jsx;
};
