import React from 'react';
import { useDispatch } from 'react-redux';
import { ErrorBoundary } from '../../components-bl';
import { Dispatch } from '../../components-bl/types';
import { GlobalErrorBoundaryProps } from './types';

export const GlobalErrorBoundary = ({
  children,
  errorChildren,
}: GlobalErrorBoundaryProps): JSX.Element => {
  const dispatch: Dispatch = useDispatch();
  return (
    <ErrorBoundary dispatch={dispatch} errorChildren={errorChildren}>
      {children}
    </ErrorBoundary>
  );
};
