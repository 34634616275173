import React, { useEffect } from 'react';
import {
  Checkbox,
  DropDownOption,
  NumericInput,
  TooltipedFormField,
  Select,
  SelectType,
  SelectOnChangeEvent,
  MenuItem,
  RadioGroup,
} from 'src/components-dummy';
import { RadioProps } from 'src/components-dummy/RadioGroup/RadioButton';
import {
  DisplayType,
  FilterItemPropertyValue,
  NumericTypeDisplayOptions,
  SwatchesShapeOptions,
  TextTypeDisplayOptions,
} from 'src/services/src/service/types/filters';
import { DisplayTypePreview } from './DisplayTypePreview';
import './filters-display-config.scss';

enum DisplayConfigField {
  displayType = 'displayType',
  showRangeInput = 'showRangeInput',
  colorShape = 'colorShape',
  numBuckets = 'numBuckets',
  minBucketDelta = 'minBucketDelta',
}
interface OnChangePayload {
  changedProperty: DisplayConfigField;
  newValue: FilterItemPropertyValue;
}
export interface FiltersDisplayConfigProps {
  isNumericType: boolean;
  displayType: DisplayType | undefined;
  showRangeInput: boolean | undefined;
  numBuckets: number | undefined;
  minBucketDelta: number | undefined;
  colorShape: SwatchesShapeOptions | undefined;
  dispatcher: (payload: OnChangePayload) => void;
  error: string | string[] | undefined;
}

const rangeGroupLimitOptions: DropDownOption<number>[] = [4, 5, 6, 7, 8].map((opt: number) => ({
  value: opt,
  text: `${opt} Ranges`,
}));
const defaultNumBucketsValue = 4;
const defaultMinBucketDeltaValue = 5;
const minimalRangeTooltipText = (
  <>
    <p>
      The minimum value between &quot;Low&quot; to &quot;High&quot;.
      <br />
      It is used to prevent ranges which are too small.
      <br />
      <br />
      <b>Example: </b>
      Min is 5, and results are between 1 to 11, then only 2 ranges will be displayed:
      <br />
      1-5 and 5-11.
      <br />
      Syte recommendation is to use 5.
    </p>
  </>
);

const swatchesShapeOptions: RadioProps[] = [
  { value: SwatchesShapeOptions.circle, label: 'Circle' },
  { value: SwatchesShapeOptions.square, label: 'Square' },
];

export const FiltersDisplayConfig = ({
  isNumericType,
  displayType,
  showRangeInput,
  numBuckets,
  minBucketDelta,
  colorShape,
  dispatcher,
  error = [],
}: FiltersDisplayConfigProps): JSX.Element => {
  const onColorShapeChange = (newVal: SwatchesShapeOptions) => {
    dispatcher({
      changedProperty: DisplayConfigField.colorShape,
      newValue: newVal,
    });
  };

  const onDisplayTypeChange = (newVal: DisplayType) => {
    dispatcher({
      changedProperty: DisplayConfigField.displayType,
      newValue: newVal,
    });
  };

  useEffect(() => {
    if (displayType === TextTypeDisplayOptions.swatches) {
      const newColorShapeVal = colorShape === undefined ? SwatchesShapeOptions.circle : colorShape;
      onColorShapeChange(newColorShapeVal);
    }
  }, [displayType]);

  const handleChange: SelectOnChangeEvent = event => {
    dispatcher({
      changedProperty: event.target.value as DisplayConfigField.numBuckets,
      newValue: event.target.value as DisplayType,
    });
  };

  return (
    <div className='display-type-section'>
      <TooltipedFormField label='Display Type' error={error}>
        <RadioGroup
          name='displayType'
          options={
            isNumericType
              ? [
                  {
                    value: NumericTypeDisplayOptions.slider,
                    label: <DisplayTypePreview displayTypeKey={NumericTypeDisplayOptions.slider} />,
                  },
                  // {
                  //   value: NumericTypeDisplayOptions.rangeGroups,
                  //   label: (
                  //     <DisplayTypePreview displayTypeKey={NumericTypeDisplayOptions.rangeGroups} />
                  //   ),
                  // },
                  // { value: NumericTypeDisplayOptions.rating, text: 'Stars Ranking' },
                ]
              : [
                  {
                    value: TextTypeDisplayOptions.list,
                    label: <DisplayTypePreview displayTypeKey={TextTypeDisplayOptions.list} />,
                  },
                  {
                    value: TextTypeDisplayOptions.tags,
                    label: <DisplayTypePreview displayTypeKey={TextTypeDisplayOptions.tags} />,
                  },
                  {
                    value: TextTypeDisplayOptions.swatches,
                    label: <DisplayTypePreview displayTypeKey={TextTypeDisplayOptions.swatches} />,
                  },
                ]
          }
          onChange={rawVal => onDisplayTypeChange(rawVal as DisplayType)}
          selectedValue={displayType}
        />
      </TooltipedFormField>
      {displayType === NumericTypeDisplayOptions.rangeGroups ? (
        <div>
          <TooltipedFormField label='Number of "Ranges" displayed'>
            <Select
              type={SelectType.Menu}
              value={numBuckets === undefined ? defaultNumBucketsValue : numBuckets}
              onChange={handleChange}
              className='number-of-range-input'
            >
              {rangeGroupLimitOptions.map((option: { value: number; text: string }) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          </TooltipedFormField>
          <TooltipedFormField
            label='Minimal range'
            className='minimal-range-input'
            additionalInfo={minimalRangeTooltipText}
          >
            <NumericInput
              value={minBucketDelta === undefined ? defaultMinBucketDeltaValue : minBucketDelta}
              onChange={newValue => {
                dispatcher({ changedProperty: DisplayConfigField.minBucketDelta, newValue });
              }}
            />
          </TooltipedFormField>
        </div>
      ) : null}
      {displayType === NumericTypeDisplayOptions.slider ? (
        <Checkbox
          label='Display custom range boxes'
          checked={showRangeInput === undefined ? true : showRangeInput}
          onChange={newValue => {
            dispatcher({ changedProperty: DisplayConfigField.showRangeInput, newValue });
          }}
        />
      ) : null}
      {displayType === TextTypeDisplayOptions.swatches ? (
        <TooltipedFormField label='Select the color icons shape'>
          <RadioGroup
            name='colorShape'
            selectedValue={colorShape === undefined ? SwatchesShapeOptions.circle : colorShape}
            options={swatchesShapeOptions}
            onChange={rawVal => onColorShapeChange(rawVal as SwatchesShapeOptions)}
          />
        </TooltipedFormField>
      ) : null}
    </div>
  );
};
