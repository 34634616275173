import React from 'react';
import { Icon, AvailableIcons, Tooltip } from 'src/components-dummy';

interface SubmitActionsProps {
  dismiss: () => void;
  save: () => void;
}
export const SubmitActions = ({ dismiss, save }: SubmitActionsProps): JSX.Element => (
  <>
    <Tooltip value='Cancel' position='bottom center'>
      <Icon name={AvailableIcons.Close} onClick={dismiss} />
    </Tooltip>
    <Tooltip value='Done' position='bottom center'>
      <Icon name={AvailableIcons.Check} onClick={save} />
    </Tooltip>
  </>
);
