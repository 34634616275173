import styled from '@emotion/styled';
import { Page, Icon, Button } from 'src/components-dummy';

export const EditCollectionContainerStyled = styled(Page)`
  padding: 24px 0;
  grid-template-columns: 1fr 5fr;
  .main {
    overflow-y: auto;
  }

  .syte-page-content {
    overflow: initial;
  }

  .syte-page-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    align-content: center;
    padding: 0px 2px;
    margin-bottom: 24px;
  }

  .collections {
    .merchandising-rules-form-page {
      .merchandising-rule-single-select-autocomplete {
        min-width: unset;
      }
    }
  }
`;

export const PreviewLinkWrapper = styled.div`
  justify-content: flex-end;
  display: inline-flex;
  align-items: center;
`;

export const PreviewButton = styled(Button)`
  margin-left: 24px;
`;

export const LearnMoreLink = styled(Page.SubTitle)`
  width: unset !important;
  margin-top: unset !important;
  color: ${({ theme }) => theme.palette.custom['primary-main']} !important;
  align-content: center;
  display: inline-flex;
`;

export const LinkIconStyled = styled(Icon)`
  margin-left: 4px;
`;
