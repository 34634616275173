import { RecentlyViewedGeneralSettingsService } from 'src/services/src/service/recently-viewed';
import { Dispatch } from 'src/components-bl/types';
import {
  UpdateRecentlyViewedSettingsPayload,
  GetRecentlyViewedSettingsArgs,
  NotifyRecentlyViewedSettingsIsDirtyActionPayload,
} from './types';

import { recentlyViewedSettingsActions } from './RecentlyViewedSettingsActions';

const recentlyViewedService = new RecentlyViewedGeneralSettingsService();

export const recentlyViewedSettingsActionsMethods = {
  notifyIsDirty:
    ({ isDirty }: NotifyRecentlyViewedSettingsIsDirtyActionPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(recentlyViewedSettingsActions.notifyIsDirtyState({ isDirty }));
    },

  fetchRecentlyViewedSettings:
    ({ shopId, variantId }: GetRecentlyViewedSettingsArgs) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(recentlyViewedSettingsActions.getRecentlyViewedSettingsRequest({ shopId }));
        const {
          showPreviouslyPurchasedItems,
          showOutOfStockItems,
          numberOfResultsToDisplay,
          numberOfRecentDaysToDisplay,
          addToCart,
        } = await recentlyViewedService.get({
          shopId,
          variantId,
        });
        dispatch(
          recentlyViewedSettingsActions.getRecentlyViewedSettingsSuccess({
            shopId,
            showPreviouslyPurchasedItems,
            showOutOfStockItems,
            numberOfResultsToDisplay,
            numberOfRecentDaysToDisplay,
            addToCart,
          })
        );
      } catch (error) {
        dispatch(recentlyViewedSettingsActions.getRecentlyViewedSettingsError({ error }));
      }
    },

  updateRecentlyViewedSettings:
    ({
      shopId,
      variantId,
      showPreviouslyPurchasedItems,
      showOutOfStockItems,
      numberOfResultsToDisplay,
      numberOfRecentDaysToDisplay,
      addToCart,
    }: UpdateRecentlyViewedSettingsPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(
          recentlyViewedSettingsActions.updateRecentlyViewedSettingsRequest({
            shopId,
            showPreviouslyPurchasedItems,
            showOutOfStockItems,
            numberOfResultsToDisplay,
            numberOfRecentDaysToDisplay,
            addToCart,
          })
        );

        await recentlyViewedService.update({
          shopId,
          variantId,
          showPreviouslyPurchasedItems,
          showOutOfStockItems,
          numberOfResultsToDisplay,
          numberOfRecentDaysToDisplay,
          addToCart,
        });

        dispatch(
          recentlyViewedSettingsActions.updateRecentlyViewedSettingsSuccess({
            shopId,
            showPreviouslyPurchasedItems,
            showOutOfStockItems,
            numberOfResultsToDisplay,
            numberOfRecentDaysToDisplay,
            addToCart,
          })
        );
      } catch (error) {
        dispatch(recentlyViewedSettingsActions.updateRecentlyViewedSettingsError({ error }));
      }
    },
};
