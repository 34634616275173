import styled from '@emotion/styled';
import { Button, Page, Skeleton } from 'src/components-dummy';

export const AddNewFilterButton = styled(Button)`
  path {
    fill: ${({ theme }) => theme.palette.common.white};
  }
`;

export const FiltersListPageStyled = styled(Page)`
  min-height: 100%;
  background-color: ${({ theme }) => theme.palette.custom['gray-80']};

  .syte-page-header {
    border-bottom: unset;
  }

  .syte-page-content {
    padding-bottom: 48px;
  }
`;

export const StyledSkeleton = styled(Skeleton)`
  margin: 10px;
`;

/**
 * Filters - Header - style
 */

export const FiltersWrapperStyled = styled.div`
  position: sticky;
  top: -40px;
  z-index: 2;
  width: 100%;
  padding: 16px 0;

  background-color: ${({ theme }) => theme.palette.custom['gray-90']};
`;

export const FiltersInnerWrapperStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 6px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.custom['gray-80']};
  background: ${({ theme }) => theme.palette.custom.white};

  & > div:first-of-type {
    width: Calc(100% - 85px);
    flex: 10;
    border-bottom: none;
    padding: 0;
  }

  .syte-popup-trigger {
    max-width: 100%;
  }
`;

export const FiltersTotalItemsWrapperStyled = styled.div`
  display: flex;
  align-self: baseline;
  margin-top: 12px;
  padding-right: 12px;
`;
