import React from 'react';

import { Link } from '../Link';
import { Typography, TypographyType, TypographyVariant } from '../Typography';

interface Props {
  path?: string;
  children?: React.ReactNode;
}

const TooltipLink = ({ path, children }: Props): JSX.Element => {
  return (
    <Link href={path}>
      <Link.Text type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
        {children}
      </Link.Text>
    </Link>
  );
};

const TooltipText = ({ children }: Props): JSX.Element => {
  return (
    <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
      {children}
    </Typography>
  );
};

export const TooltipWithLink = {
  Link: TooltipLink,
  Text: TooltipText,
};
