import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { collectionsService, PreviewFeature, visualEditorService } from 'src/services';
import { Collection } from 'src/components-bl/Collections';
import {
  ContentType,
  GetMerchRules,
  GetSuggestedOffer,
  PreviewMerchRule,
  SuggestedOffer,
} from '../types';
import { Location } from 'src/components-bl/VisualEditor/types/location';
import { MerchandiseRule } from '../../../services/src/service/types/shops';
import { merchRuleComparator } from '../../MerchandisingRules/merchandisingRules.helpers';
import { visualEditorFetchOffersActions } from './fetch-offers.actions';
import { UpdateDataFieldToDisplayPayload } from '../types/data-field-to-display';
import { UpdateCategoryFilterRulePayload } from '../types/category-filter-rule';
import { GetProduct } from '../types/product';
import { AutoCompleteOption } from '../../../components-dummy';
import { isAbortError } from '../../../utils/error-helpers';
import { minimumInputLength } from '../consts/minimum-input-length';

export const visualEditorActions = {
  locationChange: createAction<Location>('VisualEditor/LocationChange'),
  notifyIsDirty: createAction<{ isDirty: boolean }>('VisualEditor/NotifyIsDirty'),
  resetState: createAction('VisualEditor/ResetState'),
  resetPartialState: createAction('VisualEditor/ResetPartialState'),
  setContentType: createAction<ContentType>('VisualEditor/SetContentType'),
  setCollection: createAction<Collection>('VisualEditor/SetCollection'),
  setSelectedOffer: createAction<SuggestedOffer | null>('VisualEditor/SetOffer'),
  changeExperience: createAction<PreviewFeature>('VisualEditor/ChangeExperience'),
  findAndUpdateRule: createAction<MerchandiseRule>('VisualEditor/UpdateRule'),
  updateRule: createAction<MerchandiseRule>('VisualEditor/UpdateRule/fulfilled'),
  setSelectedRule: createAction<PreviewMerchRule>('VisualEditor/SetSelectedRule'),
  addNewRule: createAction<MerchandiseRule>('VisualEditor/AddNewRule'),
  setAutocompleteOptions: createAction<AutoCompleteOption[]>('VisualEditor/SetAutocompleteOptions'),
  setIsSearchLoading: createAction<boolean>('VisualEditor/SetIsSearchLoading'),
  getPreviewExperiences: createAsyncThunk(
    'VisualEditor/GetPreviewExperiences',
    async (payload: { shopId: number }, { rejectWithValue, signal }) => {
      try {
        const { data } = await visualEditorService.getPreviewExperiences(payload, signal);
        return data.experiences;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getSearchResults: createAsyncThunk(
    'VisualEditor/GetSearchResults',
    async (payload: GetSuggestedOffer, { rejectWithValue, signal }) => {
      try {
        const { data } = await visualEditorService.getSearchResults(payload, signal);
        return data.values;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getProduct: createAsyncThunk(
    'VisualEditor/GetProduct',
    async (payload: GetProduct, { rejectWithValue, signal }) => {
      try {
        const { data } = await visualEditorService.getProduct(payload, signal);
        return data;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  fetchSearchResults: createAsyncThunk(
    'VisualEditor/FetchSearchResults',
    async (payload: GetSuggestedOffer, { dispatch }) => {
      if (payload.sku.trim().length < minimumInputLength) {
        dispatch(visualEditorActions.setAutocompleteOptions([]));
        dispatch(visualEditorActions.setIsSearchLoading(false));
        return;
      }
      dispatch(visualEditorActions.setIsSearchLoading(true));

      let aborted = false;

      const thunkPromise = dispatch(visualEditorActions.getSearchResults(payload));

      thunkPromise
        .unwrap()
        .then((offers: SuggestedOffer[]) => {
          dispatch(
            visualEditorActions.setAutocompleteOptions(
              offers.map(offer => ({
                value: offer.sku,
                title: offer.sku,
                data: offer,
              }))
            )
          );
          const matchedOffer = offers.find(i => i.sku === payload.sku) || null;
          dispatch(visualEditorActions.setSelectedOffer(matchedOffer));
          return offers;
        })
        .catch((apiError: Error) => {
          aborted = isAbortError(apiError);
          if (!aborted) {
            console.error(apiError);
            dispatch(visualEditorActions.setAutocompleteOptions([]));
          }
        })
        .finally(() => {
          if (!aborted) {
            dispatch(visualEditorActions.setIsSearchLoading(false));
          }
        });
    }
  ),
  fetchCollection: createAsyncThunk(
    'VisualEditor/FetchCollection',
    async (
      { shopId, collectionName }: { shopId: number; collectionName: string },
      { dispatch, rejectWithValue }
    ) => {
      try {
        const collections = await collectionsService.getCollections({
          shopId,
        });
        const collection = collections.find(col => col.name === collectionName);
        dispatch(visualEditorActions.setCollection(collection));
        return collection;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getMerchRules: createAsyncThunk(
    'VisualEditor/GetMerchRules',
    async (payload: GetMerchRules, { rejectWithValue, signal }) => {
      try {
        const { data } = await visualEditorService.getMerchRules(payload, signal);
        return data.merchandisingRules.sort(merchRuleComparator);
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getCollectionMerchRules: createAsyncThunk(
    'VisualEditor/GetCollectionMerchRules',
    async (payload: GetMerchRules, { rejectWithValue, signal }) => {
      try {
        const { data } = await visualEditorService.getMerchRules(payload, signal);
        return data.merchandisingRules.sort(merchRuleComparator);
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getVisualEditorSettings: createAsyncThunk(
    'VisualEditor/GetVisualEditorSettings',
    async (payload: { shopId: number }, { rejectWithValue }) => {
      try {
        const { data } = await visualEditorService.getVisualEditorSettings(payload);
        return data;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateVisualEditorSettings: createAsyncThunk(
    'VisualEditor/UpdateVisualEditorSettings',
    async (
      { shopId, payload }: { shopId: number; payload: UpdateDataFieldToDisplayPayload },
      { rejectWithValue }
    ) => {
      try {
        const { data } = await visualEditorService.updateVisualEditorSettings({
          shopId,
          dataFieldsToDisplay: payload.dataFieldsToDisplay,
          specialDataFieldsToDisplay: payload.specialDataFieldsToDisplay,
          itemsCountPerRow: payload.itemsCountPerRow,
        });
        return data;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  getCategoryFilterRule: createAsyncThunk(
    'VisualEditor/GetCategoryFilterRule',
    async (payload: { shopId: number }, { rejectWithValue }) => {
      try {
        const { data } = await visualEditorService.getCategoryFilterRule(payload);
        return data.rule;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateCategoryFilterRule: createAsyncThunk(
    'VisualEditor/UpdateCategoryFilterRule',
    async (
      { shopId, payload }: { shopId: number; payload: UpdateCategoryFilterRulePayload },
      { rejectWithValue }
    ) => {
      try {
        const { data } = await visualEditorService.updateCategoryFilterRule({
          shopId,
          rule: payload.rule,
        });
        return data.rule;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};

// Hides "Success" toasts for GET requests
export const visualEditorGetRequestsActionsTypes = [
  visualEditorActions.getPreviewExperiences.fulfilled.type,
  visualEditorActions.getSearchResults.fulfilled.type,
  visualEditorActions.getProduct.fulfilled.type,
  visualEditorActions.getMerchRules.fulfilled.type,
  visualEditorActions.getCollectionMerchRules.fulfilled.type,
  visualEditorActions.getVisualEditorSettings.fulfilled.type,
  visualEditorActions.updateVisualEditorSettings.fulfilled.type,
  visualEditorActions.getCategoryFilterRule.fulfilled.type,
  visualEditorActions.updateCategoryFilterRule.fulfilled.type,
  visualEditorFetchOffersActions.getSimilarItems.fulfilled.type,
  visualEditorFetchOffersActions.getShopTheLookItems.fulfilled.type,
  visualEditorFetchOffersActions.getCollectionItems.fulfilled.type,
  visualEditorFetchOffersActions.getDiscoveryButtonItems.fulfilled.type,
  visualEditorFetchOffersActions.getBrowsePLPItems.fulfilled.type,
  visualEditorFetchOffersActions.getTextSearchItems.fulfilled.type,
  visualEditorActions.fetchSearchResults.fulfilled.type,
  visualEditorActions.fetchCollection.fulfilled.type,
];
