import React from 'react';
import styled from '@emotion/styled';
import { Button, Typography, TextBox, Password, PasswordProps } from 'src/components-dummy';

export const LoginLayoutStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
`;

export const FormSideWrapperStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  height: 100%;
  width: 30vw;
  padding: 0 48px;
  min-width: fit-content;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export const DynamicContentSideWrapperStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  height: 100%;
  width: 70vw;
`;

export const LogoWrapperStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  height: 181px;
  width: 100%;
  padding: 48px 0;
`;

export const FormWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 331px;

  height: 75%;
  padding: 48px 0;
`;

export const LogoImgStyled = styled.img`
  width: 62px;
`;

export const FormStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ActionButtonStyled = styled(Button)<{ disabled?: boolean }>`
  margin: 24px 0;
  width: 100%;
  height: 48px;

  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};

  background-color: ${({ theme, disabled }) =>
    disabled ? theme.palette.custom['gray-70'] : theme.palette.custom['primary-main']};

  transition: background-color 0.1s;

  border: none;

  color: ${({ theme, disabled }) =>
    disabled ? theme.palette.custom['gray-50'] : theme.palette.common.white};

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.palette.custom['gray-70'] : theme.palette.custom['primary-main']};
    border: none;
  }
`;

export const TitleTypographyStyled = styled(Typography)`
  align-self: self-start;
  margin-bottom: 16px;
`;

export const SubTitleTypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const LinkTypographyStyled = styled(Typography)`
  margin-left: 3px;
  color: ${({ theme }) => theme.palette.custom['primary-main']};

  &:hover {
    cursor: pointer;
  }
`;

export const InputActionTextStyled = styled.div<{
  align: 'center' | 'right' | 'left';
  marginTop?: number;
}>`
  margin-top: ${({ marginTop }) => (marginTop !== undefined ? `${marginTop}px` : 'none')};

  align-self: ${({ align }) => {
    if (align === 'right') {
      return 'self-end';
    }
    if (align === 'left') {
      return 'self-start';
    }
    return 'center';
  }};
`;

export const ContactUsTypographyStyled = styled(Typography)`
  margin-top: 4px;
  margin-bottom: 24px;
  align-self: center;
`;

export const TextBoxStyled = styled(TextBox)`
  margin-top: 24px;

  .syte-input-base {
    height: 48px;
    border: 1px solid ${({ theme }) => theme.palette.custom['gray-50']};
    background-color: unset;

    &:hover,
    &:focus-within,
    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.custom['primary-main']} !important;
    }
  }

  input:-webkit-autofill {
    background: transparent;
  }

  .syte-error-label,
  .ellipsis-with-tooltip {
    margin-top: 4px;
    position: absolute;
  }
`;

export const PasswordStyled = styled((props: PasswordProps) => <Password {...props} />)`
  margin-bottom: 12px;
`;

export const BackgroundImageStyled = styled.div<{
  mainImageUrl: string;
  secondaryImageUrl: string;
}>`
  width: 100%;
  height: 100%;
  background-image: url('${({ mainImageUrl }) => mainImageUrl}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media screen and (min-width: 1024px) and (max-width: 1360px) {
    background-image: url('${({ secondaryImageUrl }) => secondaryImageUrl}');
  }
`;
