import { DomainEntityPath, NotificationType } from 'src/services';
import { notificationCardMessageCustomComponentMap } from './NotificationCardMessageCustomComponentMap';
import { NotificationCardMessageComponent } from './types';

export function getNotificationCardMessageComponent({
  domainEntity,
  type,
}: {
  domainEntity?: DomainEntityPath;
  type?: NotificationType;
}): NotificationCardMessageComponent {
  if (!domainEntity) {
    return notificationCardMessageCustomComponentMap.default;
  }

  if (!type) {
    return (
      notificationCardMessageCustomComponentMap[domainEntity]?.default ||
      notificationCardMessageCustomComponentMap.default
    );
  }

  return (
    notificationCardMessageCustomComponentMap[domainEntity]?.[type] ||
    notificationCardMessageCustomComponentMap[domainEntity]?.default ||
    notificationCardMessageCustomComponentMap.default
  );
}
