import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Shops, ShopTypes } from 'src/services';

export const shopCreationFormActions = {
  navigateTo: createAction<{ navigateTo: string }>('ShopCreationForm/navigateTo'),
  navigateToShop: createAction<{ shopId: number }>('ShopList/NavigateToShop'),
  notifyIsDirty: createAction<{ isDirty: boolean }>('ShopCreationForm/NotifyIsDirty'),
  createShop: createAsyncThunk(
    'ShopCreationForm/createShop',
    async (
      payload: {
        accountId: number;
        shopName: string;
        salesforceAccountId: string;
        shopType: ShopTypes.ShopType;
      },
      { rejectWithValue }
    ) => {
      try {
        const service = new Shops();
        const createdShop = await service.createShop(payload);
        return createdShop;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
