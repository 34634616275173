/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { GalleryType, InspirationalImagesGallery } from 'src/services';
import { isEqual } from 'lodash';
import { generatePath } from 'react-router';
import {
  Button,
  ConfirmationDialog,
  MenuItem,
  Select,
  SelectOnChangeEvent,
  SelectType,
} from 'src/components-dummy';
import { useValidateSchema } from 'src/hooks';
import { getStringEnumValues } from 'src/utils';
import { Dispatch } from '../../types';
import { galleryDetailsValidationSchema, initialDraftState } from '../GalleryForm/constants';
import { GalleryDetailsDraft } from '../GalleryForm';
import { createGalleryFormActions } from './Actions';
import { galleryTypeTranslations } from '../constants';
import { ButtonStyled, TextBoxStyled } from './CreateGalleryModalForm.styles';

interface CreateGalleryModalFormProps extends RoutedComponentProps {
  shopId: number;
  dispatch: Dispatch;
}

export const CreateGalleryModalForm = ({
  shopId,
  dispatch,
  permittedRouteMap,
}: CreateGalleryModalFormProps): JSX.Element => {
  const [formState, setFormState] = useState(initialDraftState);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);

  const galleryTypeOptions = useMemo(() => {
    return getStringEnumValues(GalleryType).map(galleryType => (
      <MenuItem key={galleryType} value={galleryType}>
        {galleryTypeTranslations[galleryType]}
      </MenuItem>
    ));
  }, []);

  const isDirty = useMemo(() => {
    return !isEqual(formState, initialDraftState);
  }, [formState]);

  const { errors, isValid, validate } = useValidateSchema<GalleryDetailsDraft>({
    initialData: initialDraftState,
    schema: galleryDetailsValidationSchema,
    validateOnStart: false,
  });

  const onFormStateChange = useCallback(
    (partial: Partial<GalleryDetailsDraft>) => {
      const newState = { ...formState, ...partial };
      setFormState(newState);

      validate({ dataToValidate: newState });
    },
    [validate, setFormState, formState]
  );

  const onGalleryTypeChange: SelectOnChangeEvent = useCallback(
    event => {
      onFormStateChange({ galleryType: event.target.value as GalleryType });
    },
    [onFormStateChange]
  );

  const onModalCancel = useCallback(() => {
    if (permittedRouteMap.galleriesList) {
      dispatch(
        createGalleryFormActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.galleriesList.path, {
            shopId,
          }),
        })
      );
    }
  }, [dispatch, shopId, permittedRouteMap.galleriesList]);

  const canSave = useMemo(() => isValid && isDirty, [isValid, isDirty]);

  const onSave = useCallback(async () => {
    if (canSave) {
      switch (formState.galleryType) {
        case GalleryType.InspirationalImage: {
          setIsSaveInProgress(true);
          (
            dispatch(
              createGalleryFormActions.createGallery({
                shopId,
                galleryName: formState.galleryName,
                galleryType: GalleryType.InspirationalImage,
              })
            ) as any
          )
            .unwrap()
            .then((createdGallery: InspirationalImagesGallery) => {
              setIsSaveInProgress(false);
              dispatch(createGalleryFormActions.notifyIsDirty({ isDirty: false }));

              if (permittedRouteMap.editInspirationalGallery) {
                dispatch(
                  createGalleryFormActions.navigateTo({
                    navigateTo: generatePath(permittedRouteMap.editInspirationalGallery.path, {
                      shopId,
                      galleryId: createdGallery.id,
                    }),
                  })
                );
              }
            })
            .catch((error: unknown) => {
              console.error(error);
              setIsSaveInProgress(false);
            });
          break;
        }
        case GalleryType.CustomInspirations: {
          setIsSaveInProgress(true);
          (
            dispatch(
              createGalleryFormActions.createGallery({
                shopId,
                galleryName: formState.galleryName,
                galleryType: GalleryType.CustomInspirations,
              })
            ) as any
          )
            .unwrap()
            .then((createdGallery: InspirationalImagesGallery) => {
              setIsSaveInProgress(false);
              dispatch(createGalleryFormActions.notifyIsDirty({ isDirty: false }));

              if (permittedRouteMap.editCustomInspirationsGallery) {
                dispatch(
                  createGalleryFormActions.navigateTo({
                    navigateTo: generatePath(permittedRouteMap.editCustomInspirationsGallery.path, {
                      shopId,
                      galleryId: createdGallery.id,
                    }),
                  })
                );
              }
            })
            .catch((error: unknown) => {
              console.error(error);
              setIsSaveInProgress(false);
            });
          break;
        }
        default:
          break;
      }
    }
  }, [
    shopId,
    canSave,
    formState,
    dispatch,
    permittedRouteMap.editInspirationalGallery,
    setIsSaveInProgress,
  ]);

  useEffect(() => {
    dispatch(createGalleryFormActions.notifyIsDirty({ isDirty }));
  }, [dispatch, isDirty]);

  return (
    <ConfirmationDialog onCancel={onModalCancel}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Create new gallery</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        <TextBoxStyled
          value={formState.galleryName}
          onChange={galleryName => onFormStateChange({ galleryName })}
          placeholder='Write here'
          label='Gallery name'
          error={errors?.galleryName?.message}
        />
        <Select
          errorMessage={errors?.galleryType?.message}
          isError={Boolean(errors.galleryType)}
          onChange={onGalleryTypeChange}
          placeholder='Select type'
          type={SelectType.Primary}
          value={formState.galleryType}
          label='Gallery type'
        >
          {galleryTypeOptions}
        </Select>
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonStyled
          variant='primary'
          onClick={onSave}
          disabled={!isValid}
          loading={isSaveInProgress}
        >
          Create
        </ButtonStyled>
        <Button variant='tertiary' onClick={onModalCancel}>
          Close
        </Button>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
