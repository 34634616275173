import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  EllipsisWithTooltip,
  FiltersRow,
  FilterVariant,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { DeepTagProductStatusType, IFilterKeyObject } from 'src/services';
import { isEqual } from 'lodash';
import {
  FiltersWrapperStyled,
  FiltersInnerWrapperStyled,
  FiltersTotalItemsWrapperStyled,
} from 'src/components-bl/Filters/components/FiltersListPage/FiltersListPage.styles';
import { usePreviousState } from 'src/hooks/usePreviousState';
import { FiltersRowStyled } from './DeepTagReportFilters.styles';
import { DeepTagReportFiltersProps, FilterKey, DeepTagReportFiltersState } from './types';
import { getDefaultFilter } from './constants';
import { deepTagReportFiltersActions } from './Actions';
import { useMissingAttributeValuePairsFilter } from './useMissingAttributeValuePairsFilter';
import { useMissingAttributesFilter } from './useMissingAttributesFilter';

const createKeyWithCountText = ({ name, count }: IFilterKeyObject): string =>
  `${!name ? 'None' : name} (${count})`;

const productTypeStatusToText = {
  [DeepTagProductStatusType.New]: 'New',
  [DeepTagProductStatusType.Done]: 'Done',
  [DeepTagProductStatusType.DoneWithIssues]: 'Done with Issues',
  [DeepTagProductStatusType.InReview]: 'In Review',
};

const initialState: DeepTagReportFiltersState = {
  search: {
    isApplied: true,
    value: undefined,
  },
};

export const DeepTagReportFilters = React.memo(
  ({
    report,
    availableFilterKeysMap,
    dispatch,
    filters,
    labelNameById,
    totalProducts,
    shouldHideEditIndication,
  }: DeepTagReportFiltersProps): JSX.Element => {
    const [filtersState, setFiltersState] = useState(filters || initialState);
    const previousFilterState = usePreviousState(filters);

    const missingAttributeValuePairsFilterOptions = useMissingAttributeValuePairsFilter({
      attributeValuePairs: availableFilterKeysMap?.attributeValuePairs || {},
      missingAttributeFilterState: filtersState?.missingAttributeValuePairs,
    });

    const missingAttributesFilterOptions = useMissingAttributesFilter({
      attributes: availableFilterKeysMap?.attributes || {},
      attributesFilterState: filtersState?.missingAttributes,
    });

    const {
      categoryFilterOptions,
      attributeFilterOptions,
      valuesFilterOptions,
      attributeValuePairsFilterOptions,
      availableVerticals,
      verticalsFilterOptions,
      productStatusFilterOptions,
      productLabelFilterOptions,
      productsEditedTagsOptions,
    } = useMemo(() => {
      /**
       * Categories
       */
      const categories: string[] = Object.keys(availableFilterKeysMap?.categories || {});

      const categoryOptions = categories.map(key => {
        const categoryKeyItem = availableFilterKeysMap?.categories?.[key];
        return {
          text: createKeyWithCountText(categoryKeyItem as IFilterKeyObject),
          value: key,
        };
      });

      /**
       * Attributes
       */
      const attributes: string[] = Object.keys(availableFilterKeysMap?.attributes || {});

      const attributeOptions = attributes.map(key => {
        const attributeKeyItem = availableFilterKeysMap?.attributes?.[key];
        return {
          text: createKeyWithCountText(attributeKeyItem as IFilterKeyObject),
          value: key,
        };
      });

      /**
       * Values
       */
      const values: string[] = Object.keys(availableFilterKeysMap?.values || {});

      const valuesOptions = values.map(key => {
        const valueKeyItem = availableFilterKeysMap?.values?.[key];
        return {
          text: createKeyWithCountText(valueKeyItem as IFilterKeyObject),
          value: key,
        };
      });

      /**
       * Attribute - Value pairs
       */
      const attributeValuePairs: string[] = Object.keys(
        availableFilterKeysMap?.attributeValuePairs || {}
      );

      const attributeValuePairsOptions = attributeValuePairs.map(key => {
        const attributeKeyItem = availableFilterKeysMap?.attributeValuePairs?.[key];
        const [attributeName, valueName] = attributeKeyItem?.name?.split(':') || [];
        const attributeValueObjectForText = {
          name: `${attributeName} - ${valueName}`,
          count: attributeKeyItem?.count,
        };

        return {
          text: createKeyWithCountText(attributeValueObjectForText as IFilterKeyObject),
          value: key,
        };
      });

      /**
       * Verticals
       */
      const verticals: string[] = Object.keys(availableFilterKeysMap?.verticals || {});

      const verticalsOptions = verticals.map(key => {
        const valueKeyItem = availableFilterKeysMap?.verticals?.[key] as IFilterKeyObject;
        return {
          text: createKeyWithCountText({
            name: valueKeyItem.name,
            count: valueKeyItem.count,
          }),
          value: key,
        };
      });

      /**
       * Statuses
       */
      const productStatuses: string[] = Object.keys(availableFilterKeysMap?.statuses || {});

      const productStatusesOptions = productStatuses.map(key => {
        const valueKeyItem = availableFilterKeysMap?.statuses?.[key] as IFilterKeyObject;
        return {
          text: createKeyWithCountText({
            name: (productTypeStatusToText as any)[valueKeyItem.name],
            count: valueKeyItem.count,
          }),
          value: key,
        };
      });

      /**
       * Labels
       */
      const productLabels: string[] = Object.keys(availableFilterKeysMap?.labels || {});

      const productLabelOptions = productLabels.map(key => {
        const labelKeyItem = availableFilterKeysMap?.labels?.[key] as IFilterKeyObject;
        const labelName = labelNameById[labelKeyItem.name] || 'Unassign';

        return {
          text: createKeyWithCountText({ name: labelName, count: labelKeyItem.count }),
          value: key,
        };
      });

      /**
       * Edited Tags
       */
      const totalProductsWithEditedTags =
        availableFilterKeysMap?.editedProductsTags?.totalProductsWithEditedTags?.count || 0;

      const totalProductsWithNoneEditedTags = totalProducts - totalProductsWithEditedTags;

      const editedTagsOptions = [
        {
          value: false,
          text: createKeyWithCountText({
            name: 'Not edited',
            count: totalProductsWithNoneEditedTags,
          }),
        },
        {
          value: true,
          text: createKeyWithCountText({ name: 'Edited', count: totalProductsWithEditedTags }),
        },
      ];

      return {
        availableCategories: categories,
        categoryFilterOptions: categoryOptions,

        availableAttributes: attributes,
        attributeFilterOptions: attributeOptions,

        availableValues: values,
        valuesFilterOptions: valuesOptions,

        availableAttributeValuePairs: attributeValuePairs,
        attributeValuePairsFilterOptions: attributeValuePairsOptions,

        availableVerticals: verticals,
        verticalsFilterOptions: verticalsOptions,

        productStatusFilterOptions: productStatusesOptions,
        productLabelFilterOptions: productLabelOptions,

        productsEditedTagsOptions: editedTagsOptions,
      };
    }, [availableFilterKeysMap, labelNameById, totalProducts]);

    const getNewFilter = (key: string) =>
      getDefaultFilter(key as FilterKey, {
        verticals: availableVerticals,
      });

    useEffect(() => {
      const isPreviousStateInitial = Object.keys(previousFilterState || {}).length === 0;
      const filterWithEmptySearch =
        Object.keys(filtersState).length === 1 && !filtersState.search?.value;

      const shouldIgnore = isPreviousStateInitial && filterWithEmptySearch;

      if (!shouldIgnore) {
        dispatch(deepTagReportFiltersActions.updateFilters(filtersState));
      }
    }, [dispatch, filtersState, previousFilterState]);

    useEffect(() => {
      setFiltersState(initialState);
    }, [report.id]);

    const onChange = useCallback((selectedFiltersChanges: any) => {
      const newStateAppliedFiltersOnly = Object.keys(selectedFiltersChanges).reduce(
        (accumulator: any, filterKey: string) => {
          const field = (selectedFiltersChanges as any)[filterKey as string];

          if (field.isApplied) {
            accumulator[filterKey] = (selectedFiltersChanges as any)[filterKey as string];
          }
          return accumulator;
        },
        {}
      );

      setFiltersState(state => {
        const hasChanged = !isEqual(state, newStateAppliedFiltersOnly);

        if (hasChanged) {
          return { ...newStateAppliedFiltersOnly };
        }
        return state;
      });
    }, []);

    const totalProductsText = `${totalProducts} Results`;

    return (
      <FiltersWrapperStyled>
        <FiltersInnerWrapperStyled>
          <FiltersRowStyled onChange={onChange} filters={filtersState} getNewFilter={getNewFilter}>
            <FiltersRow.Filter
              type={FilterVariant.SearchInput}
              uniqueFilterKey='search'
              componentConfig={{ placeholder: 'Search...' }}
            />

            <FiltersRow.Filter
              type={FilterVariant.MultiSelect}
              uniqueFilterKey='verticals'
              menuName='Vertical'
              tagPrefix='Vertical is'
              componentConfig={{
                enableSearch: false,
                enableToggleAll: false,
                labelText: 'Select Vertical',
                options: verticalsFilterOptions,
                shouldAlwaysShowSelectionItemsText: true,
                applyText: 'Apply',
              }}
            />
            <FiltersRow.Filter
              type={FilterVariant.MultiSelect}
              uniqueFilterKey='statuses'
              menuName='Status'
              tagPrefix='Status is'
              componentConfig={{
                enableSearch: true,
                enableToggleAll: true,
                labelText: 'Select Status',
                shouldAlwaysShowSelectionItemsText: true,
                options: productStatusFilterOptions,
                applyText: 'Apply',
              }}
            />
            <FiltersRow.Filter
              type={FilterVariant.MultiSelect}
              uniqueFilterKey='labels'
              menuName='Label'
              tagPrefix='Label is'
              componentConfig={{
                enableSearch: true,
                enableToggleAll: true,
                labelText: 'Select Label',
                shouldAlwaysShowSelectionItemsText: true,
                options: productLabelFilterOptions,
                applyText: 'Apply',
              }}
            />
            <FiltersRow.Filter
              type={FilterVariant.MultiSelect}
              uniqueFilterKey='categories'
              menuName='Category'
              tagPrefix='Category is'
              componentConfig={{
                enableSearch: true,
                enableToggleAll: true,
                labelText: 'Filter by categories',
                shouldAlwaysShowSelectionItemsText: true,
                options: categoryFilterOptions,
                applyText: 'Apply',
                searchPlaceholder: 'Search categories',
              }}
            />
            <FiltersRow.Filter
              type={FilterVariant.MultiSelect}
              uniqueFilterKey='attributes'
              menuName='Attribute'
              tagPrefix='Attribute is'
              componentConfig={{
                enableSearch: true,
                enableToggleAll: true,
                labelText: 'Filter by attributes',
                shouldAlwaysShowSelectionItemsText: true,
                options: attributeFilterOptions,
                applyText: 'Apply',
                searchPlaceholder: 'Search attributes',
                selectedParentOptions: filtersState.categories?.value,
                headerTitle: 'Attribute',
              }}
            />
            <FiltersRow.Filter
              type={FilterVariant.MultiSelect}
              uniqueFilterKey='missingAttributes'
              menuName='Missing Attribute'
              tagPrefix='Missing Attribute is'
              componentConfig={{
                enableSearch: true,
                enableToggleAll: true,
                labelText: 'Filter by missing attribute',
                shouldAlwaysShowSelectionItemsText: true,
                options: missingAttributesFilterOptions,
                applyText: 'Apply',
                searchPlaceholder: 'Search missing attributes',
                selectedParentOptions: filtersState.categories?.value,
                headerTitle: 'Missing Attribute',
              }}
            />
            <FiltersRow.Filter
              type={FilterVariant.MultiSelect}
              uniqueFilterKey='values'
              menuName='Values'
              tagPrefix='Values is'
              componentConfig={{
                enableSearch: true,
                enableToggleAll: true,
                labelText: 'Filter by values',
                options: valuesFilterOptions,
                shouldAlwaysShowSelectionItemsText: true,
                applyText: 'Apply',
                searchPlaceholder: 'Search values',
                selectedParentOptions: filtersState.attributes?.value,
                headerTitle: 'Value',
              }}
            />
            <FiltersRow.Filter
              type={FilterVariant.MultiSelect}
              uniqueFilterKey='attributeValuePairs'
              menuName='Attribute - Value'
              tagPrefix='Attribute - Value is'
              componentConfig={{
                enableSearch: true,
                enableToggleAll: true,
                labelText: 'Filter by attribute - value',
                options: attributeValuePairsFilterOptions,
                shouldAlwaysShowSelectionItemsText: true,
                applyText: 'Apply',
                searchPlaceholder: 'Search Attribute - Value',
                headerTitle: 'Attribute - Value',
              }}
            />
            <FiltersRow.Filter
              type={FilterVariant.MultiSelect}
              uniqueFilterKey='missingAttributeValuePairs'
              menuName='Missing Attribute - Value'
              tagPrefix='Missing Attribute - Value is'
              componentConfig={{
                enableSearch: true,
                enableToggleAll: true,
                labelText: 'Filter by missing attribute - value',
                options: missingAttributeValuePairsFilterOptions,
                shouldAlwaysShowSelectionItemsText: true,
                applyText: 'Apply',
                searchPlaceholder: 'Search missing Attribute - Value',
                headerTitle: 'Missing Attribute - Value',
              }}
            />
            {!shouldHideEditIndication ? (
              <FiltersRow.Filter
                type={FilterVariant.Boolean}
                uniqueFilterKey='editedOnly'
                menuName='Tag edited'
                tagPrefix='Include'
                componentConfig={{
                  enabledTagText: 'edited tags',
                  disabledTagText: 'not edited tags',
                  options: productsEditedTagsOptions,
                  isEditable: true,
                }}
              />
            ) : (
              <></>
            )}
            {!shouldHideEditIndication ? (
              <FiltersRow.Filter
                type={FilterVariant.Boolean}
                uniqueFilterKey='emptyTagsOnly'
                menuName='No tags detected'
                tagPrefix='Show only'
                componentConfig={{
                  isEditable: false,
                  enabledTagText: 'No tags detected',
                }}
              />
            ) : (
              <></>
            )}
          </FiltersRowStyled>
          <FiltersTotalItemsWrapperStyled>
            <EllipsisWithTooltip tooltipText={totalProductsText}>
              <Typography type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
                {totalProductsText}
              </Typography>
            </EllipsisWithTooltip>
          </FiltersTotalItemsWrapperStyled>
        </FiltersInnerWrapperStyled>
      </FiltersWrapperStyled>
    );
  }
);
