import styled from '@emotion/styled';
import { Card } from '../Card';

const paddingSize = 32;

export const FormCardContainerStyled = styled(Card)<{ stickyHeader: boolean }>`
  padding: ${({ stickyHeader }) => (stickyHeader ? 0 : paddingSize)}px ${paddingSize}px
    ${paddingSize}px ${paddingSize}px;
  box-shadow: none;
`;

export const FormCardHeaderStyled = styled.div<{ sticky: boolean }>`
  ${({ sticky, theme }) => {
    if (sticky) {
      return `
        position: sticky;
        top: 0;
        padding: ${paddingSize}px 0;
        z-index: 2;
        background-color: ${theme.palette.common.white};
    `;
    }
    return `
    margin-bottom: 20px;
    `;
  }}

  display: flex;
  justify-content: space-between;
  align-items: center;
`;
