import React from 'react';
import { Dispatch } from 'src/components-bl/types';
import { Filter, SyteLayout } from 'src/services';
import { DataFieldLookupTable } from 'src/components-bl/hooks/useDataFieldsLookupTable';
import { FiltersList, EmptyStateCard } from './components';
import { StyledSkeleton } from './FiltersListPage.styles';

export interface FiltersListPageContentProps {
  filters: Filter[] | undefined;
  fetchFiltersInProgress: boolean;
  syteLayout: SyteLayout;
  shopId?: number;
  dataFieldsLookupTable: DataFieldLookupTable | undefined;
  onEnableFilter: (filterId: string, enabled: boolean) => void;
  onDeleteFilter: (filterId: string) => void;
  navigateToEditFilter: (filterId: string) => void;
  navigateToCreateFilter: () => void;
  dispatch: Dispatch;
}

export const FiltersListPageContent = ({
  filters,
  fetchFiltersInProgress,
  shopId,
  syteLayout,
  dataFieldsLookupTable,
  onEnableFilter,
  onDeleteFilter,
  navigateToEditFilter,
  navigateToCreateFilter,
  dispatch,
}: FiltersListPageContentProps): JSX.Element => {
  if (fetchFiltersInProgress) {
    return (
      <>
        <StyledSkeleton height={40} variant='rounded' />
        <StyledSkeleton height={20} variant='rounded' />
        <StyledSkeleton height={20} variant='rounded' />
      </>
    );
  }
  return filters?.length ? (
    <FiltersList
      filters={filters}
      onEnableFilter={onEnableFilter}
      onDeleteFilter={onDeleteFilter}
      navigateToEditFilter={navigateToEditFilter}
      dispatch={dispatch}
      shopId={shopId}
      syteLayout={syteLayout}
      dataFieldsLookupTable={dataFieldsLookupTable}
    />
  ) : (
    <EmptyStateCard navigateToCreateFilter={navigateToCreateFilter} />
  );
};
