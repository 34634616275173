import { Store } from 'redux';
import { generatePath, matchPath } from 'react-router';
import { navigationActions } from 'src/middleware-actions';
import { baseAppRoutesByPath, baseAppRoutes, baseAppRouteMap } from 'src/app-routes';

function isShopRoute({ path }: { path: string }) {
  return !!path?.includes('/shops/');
}

const shopRoutes = baseAppRoutes.filter(route => isShopRoute(route));

const shopPaths = shopRoutes
  .map(route => route.path)
  .sort()
  .reverse();

// For actions that navigate to shops.
// Handles replacing current url with new shopId params. also uses fallback (ranking)
export function navigateToShopHandler({
  store,
  action,
}: {
  store: Store;
  action: { type: string; payload?: unknown };
}): void {
  const { shopId, extraRouteParams } = (action.payload || {}) as {
    shopId?: number;
    extraRouteParams?: Record<string, string>;
  };

  // action invalid if it doesn't contain shopId
  if (!shopId) {
    console.error(`Cannot navigate to shop, no shopId given in action ${action.type}`);
    return;
  }

  // route info from react-router to get/set params, and get related app-route
  const currentShopRoute = matchPath(window.location.pathname, shopPaths);

  const shopAppRouteData = currentShopRoute
    ? baseAppRoutesByPath[currentShopRoute.path]
    : undefined;

  const rootRoute = shopAppRouteData?.getRootRoute?.();

  const newRouteTemplate = currentShopRoute
    ? // use root route if exists, otherwise current path template
      rootRoute?.path || currentShopRoute.path
    : // default ranking
      baseAppRouteMap.ranking?.path;

  // merge previous params with new params in ase the new ones, as the new might have missing params.
  // domain components responsible for navigation logic if previous params are not relevant
  const newParams = {
    ...currentShopRoute?.params,
    ...extraRouteParams,
    shopId,
  };

  // replace current url params with new ones
  const pathWithNewParams = newRouteTemplate
    ? generatePath(newRouteTemplate, newParams)
    : undefined;

  // add current query string
  const newPath = pathWithNewParams ? `${pathWithNewParams}${window.location.search}` : undefined;

  if (newPath) {
    store.dispatch(navigationActions.navigateToShop({ navigateTo: newPath, shopId }));
  } else {
    console.error(`Cannot navigate to shop to new path ${newPath} for shopId ${shopId}`);
  }
}
