import React, { memo } from 'react';
import { IndexedOffers } from '../../../types';
import { GridLayoutContainer } from './GridLayout.styles';
import { GridSkeleton } from './GridSkeleton';
import { ProductTile } from '../ProductTile';
import { DataFieldToDisplay } from '../../../types/data-field-to-display';
import { SpecialDataFieldToDisplay } from '../../../types/special-data-field-to-display';
import { useToggleAllItems } from '../../../hooks/useToggleAllItems';

export const GridLayout = memo(
  ({
    offers,
    dataFieldsToDisplay,
    specialDataFieldsToDisplay,
    itemsCountPerRow,
    openProductDetailsModal,
  }: {
    offers: IndexedOffers;
    dataFieldsToDisplay: DataFieldToDisplay[];
    specialDataFieldsToDisplay: SpecialDataFieldToDisplay[];
    itemsCountPerRow: number;
    openProductDetailsModal: (sku?: string) => void;
  }): JSX.Element => {
    const { items, toggleAllItems } = useToggleAllItems(offers);

    if (!items.length) return <GridSkeleton itemsCountPerRow={itemsCountPerRow} />;
    return (
      <GridLayoutContainer itemsCountPerRow={itemsCountPerRow}>
        {items.map((offer, index) => (
          <ProductTile
            key={offer.sku}
            offer={offer}
            index={index}
            dataFieldsToDisplay={dataFieldsToDisplay}
            specialDataFieldsToDisplay={specialDataFieldsToDisplay}
            isOpen={offer?.isOpen || false}
            toggleAllItems={toggleAllItems}
            openProductDetailsModal={openProductDetailsModal}
          />
        ))}
      </GridLayoutContainer>
    );
  }
);
