import React from 'react';
import { Typography, TypographyType, TypographyVariant } from '../../../../components-dummy';

export function SelectShopperExperience(): JSX.Element {
  return (
    <Typography
      className='visual-editor-content-text-default'
      variant={TypographyVariant.LargeBold}
      type={TypographyType.Body}
    >
      Select a shopper experience and then enter input to view results
    </Typography>
  );
}
