import React, { useCallback, useState, useRef } from 'react';
import {
  AvailableIcons,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { downloadFile } from 'src/utils/download-file';
import { fileAcceptTypes, FileUploadButtonApiRef } from 'src/components-dummy/FileUploader';
import {
  ButtonContentStyled,
  DescriptionContainerStyled,
  FileUploadButtonStyled,
  SectionBoxStyled,
  DownloadExampleLinkButtonStyled,
} from './InspirationalImagesUpload.styles';
import { UploadNewFileConfirmationDialog } from './UploadNewFileConfirmationDialog';

interface InspirationalImagesFileUploadProps {
  inspirationalImagesFiles: File[];
  onUpload: (selectedFiles: File[]) => void;
  enableConfirmationDialog: boolean;
}

const exampleFilePath = `${process.env.PUBLIC_URL}/files/galleries/inspirational-images-gallery-example.json`;

export function InspirationalImagesUpload({
  inspirationalImagesFiles,
  onUpload,
  enableConfirmationDialog,
}: InspirationalImagesFileUploadProps): JSX.Element {
  const [isConfirmUploadDialogOpen, setIsConfirmUploadDialogOpen] = useState(false);

  const uploadButtonApiRef = useRef<FileUploadButtonApiRef | null>(null);

  const onDownloadExampleClick = () => {
    downloadFile({
      filePath: exampleFilePath,
      fileDisplayName: 'inspirational-images-gallery-example.json',
    });
  };

  const onCloseConfirmationDialog = useCallback(() => {
    setIsConfirmUploadDialogOpen(false);
  }, [setIsConfirmUploadDialogOpen]);

  const onConfirmAndCloseDialog = useCallback(() => {
    onCloseConfirmationDialog();
    uploadButtonApiRef.current?.open();
  }, [onCloseConfirmationDialog, uploadButtonApiRef]);

  const onUploadButtonClick = useCallback(() => {
    if (enableConfirmationDialog) {
      setIsConfirmUploadDialogOpen(true);
    } else {
      uploadButtonApiRef.current?.open();
    }
  }, [enableConfirmationDialog, uploadButtonApiRef, setIsConfirmUploadDialogOpen]);

  return (
    <>
      <SectionBoxStyled>
        <Typography variant={TypographyVariant.LargeMedium} type={TypographyType.Body}>
          Manually Add Gallery
        </Typography>
        <DescriptionContainerStyled>
          <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Paragraph}>
            Use a JSON file to create your gallery.
          </Typography>
          <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Paragraph}>
            Make sure the file you upload contains images and linked SKUs.
          </Typography>
        </DescriptionContainerStyled>
        <FileUploadButtonStyled
          selectedFiles={inspirationalImagesFiles}
          accept={fileAcceptTypes.JSON}
          fileCardIconName={AvailableIcons.JsonFile}
          onChange={onUpload}
          onClick={onUploadButtonClick}
          ref={uploadButtonApiRef}
        >
          <ButtonContentStyled>
            <Icon name={AvailableIcons.Page} />{' '}
            <Typography variant={TypographyVariant.MediumMedium} type={TypographyType.Button}>
              Upload file
            </Typography>
          </ButtonContentStyled>
        </FileUploadButtonStyled>
        <DownloadExampleLinkButtonStyled onClick={onDownloadExampleClick}>
          <Icon name={AvailableIcons.Download} />
          <Typography variant={TypographyVariant.MediumMedium} type={TypographyType.Body}>
            Download JSON example
          </Typography>
        </DownloadExampleLinkButtonStyled>
      </SectionBoxStyled>

      {isConfirmUploadDialogOpen && (
        <UploadNewFileConfirmationDialog
          onCloseDialog={onCloseConfirmationDialog}
          onConfirm={onConfirmAndCloseDialog}
        />
      )}
    </>
  );
}
