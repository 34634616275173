import React from 'react';
import { Stack } from '@mui/material';
import { Skeleton } from 'src/components-dummy';

export const CatalogExplorerTableSkeleton = () => {
  return (
    <Stack sx={{ width: '100%' }} spacing={0.2}>
      <Skeleton height={54} variant='rounded' />
      <Skeleton height={77} variant='rectangular' />
      <Skeleton height={77} variant='rectangular' />
      <Skeleton height={77} variant='rectangular' />
      <Skeleton height={77} variant='rectangular' />
      <Skeleton height={77} variant='rounded' />
    </Stack>
  );
};

export const CatalogExplorerTableBodySkeleton = () => {
  return (
    <Stack sx={{ width: '100%' }} spacing={0.2}>
      <Skeleton height={77} variant='rectangular' />
      <Skeleton height={77} variant='rectangular' />
      <Skeleton height={77} variant='rectangular' />
      <Skeleton height={77} variant='rectangular' />
      <Skeleton height={77} variant='rounded' />
    </Stack>
  );
};
