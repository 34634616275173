import React from 'react';
import classnames from 'classnames';
import { HeaderCell } from 'src/components-dummy';
import { labelsTextDict } from 'src/components-bl';
import { FilterCardHeaderProps } from '../types';
import { DraggingHandle } from './DraggingHandle';
import { SubmitActions } from './SubmitActions';
import { QuickActions } from './QuickActions';

export const FilterCardHeader = ({
  filterItem,
  isDraggable = false,
  dismissChanges,
  saveItem,
  setIsFormOpen,
  toggleVisibility,
  deleteItem,
  isFormExpended,
  messages,
  isHiddenFilterSoftAlertShown,
}: FilterCardHeaderProps): JSX.Element => (
  <div className='syte-entity-card-content'>
    <HeaderCell
      id={`${filterItem.key}-dragging-handle`}
      cellClassName={classnames('dragging-handle', { active: isDraggable })}
    >
      <DraggingHandle />
    </HeaderCell>
    <HeaderCell id={`${filterItem.key}-name`} cellClassName='display-name'>
      {filterItem.displayNameKey}
    </HeaderCell>
    <HeaderCell id={`${filterItem.key}-display`} cellClassName='display-type' label='Display:'>
      {filterItem.displayType ? labelsTextDict[filterItem.displayType] : ''}
    </HeaderCell>
    {isFormExpended ? (
      <HeaderCell id={`${filterItem.key}-submit-form`} cellClassName='submit-form-filter'>
        <SubmitActions dismiss={dismissChanges} save={saveItem} />
      </HeaderCell>
    ) : (
      <HeaderCell id={`${filterItem.key}-quick-actions`} cellClassName='filter-item-quick-actions'>
        <QuickActions
          isVisible={filterItem.enabled}
          toggleVisibility={toggleVisibility}
          setIsFormOpen={setIsFormOpen}
          deleteItem={deleteItem}
          messages={messages}
          isHiddenFilterSoftAlertShown={isHiddenFilterSoftAlertShown}
        />
      </HeaderCell>
    )}
  </div>
);
