import React from 'react';
import styled from '@emotion/styled';
import { Button, ButtonProps } from 'src/components-dummy';

export const ButtonStyled = styled((props: ButtonProps) => <Button {...props} />)`
  width: 80px;

  svg > * {
    fill: ${({ theme, disabled }) =>
      disabled ? theme.palette.custom['gray-30'] : theme.palette.common.white};
  }
`;
