import React from 'react';
import styled from '@emotion/styled';
import {
  AvailableIcons,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';

const StyledImageContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  .textbox-title {
    line-height: 20px;
    color: #0073ff;
  }
  svg {
    height: 20px;
    width: 20px;
    & > * {
      stroke: #0073ff;
    }
  }
`;

export function AddCustomImage({ onClick }: { onClick: VoidFunction }): JSX.Element {
  return (
    <StyledImageContainer onClick={onClick}>
      <Icon name={AvailableIcons.Plus} />
      <Typography
        className='textbox-title'
        type={TypographyType.Body}
        variant={TypographyVariant.MediumRegular}
      >
        Enter optional image URL
      </Typography>
    </StyledImageContainer>
  );
}
