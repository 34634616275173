import React from 'react';
import styled from '@emotion/styled';
import { Typography, TypographyType, TypographyVariant } from '../Typography';

interface Props {
  muted?: boolean;
  children: React.ReactNode;
}

const StyledTextContainer = styled.div<Props>`
  ${props => props.muted && `color: #979797;`}
`;

export function ItemText({ muted, children }: Props) {
  return (
    <StyledTextContainer muted={muted}>
      <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
        {children}
      </Typography>
    </StyledTextContainer>
  );
}
