import React from 'react';
import { Icon, Modal, AvailableIcons, Skeleton } from 'src/components-dummy';
import { ChangesModalProps } from './types';
import './ChangesModal.scss';
import { ChangesPreview } from '../../FeatureChanges/ChangesPreview';
import { useFindComponentByType } from '../../../components-dummy';
import { ChangesModalHeader } from './ChangesModalHeader';
import { ChangesModalFooter } from './ChangesModalFooter';
import { SkeletonsWrapperStyled } from './ChangesModal.styles';

export const ChangesModal = ({
  children,
  domainGroupsSelection,
  onDomainGroupSelected,
  show,
  setModalIsOpened,
  changesSummary,
  previewType,
  loading,
  dataFields,
}: ChangesModalProps): JSX.Element => {
  const modalHeader = useFindComponentByType({ type: ChangesModalHeader, children });
  const modalFooter = useFindComponentByType({ type: ChangesModalFooter, children });

  const onModalClose = () => {
    setModalIsOpened(false);
  };

  return (
    <Modal className='syte-review-changes-dialog' isOpened={show} onClickOutside={onModalClose}>
      <>
        <div className='syte-review-changes-dialog-header'>
          {loading ? (
            <Skeleton height={40} width='40%' variant='rounded' />
          ) : (
            <>
              <div className='syte-review-changes-dialog-header-title'>{modalHeader}</div>
              <Icon
                className='syte-review-changes-dialog-header-icon'
                name={AvailableIcons.Close}
                onClick={onModalClose}
              />
            </>
          )}
        </div>
        <div className='syte-review-changes-dialog-content'>
          {loading ? (
            <SkeletonsWrapperStyled>
              <Skeleton height={60} width='100%' variant='rounded' />
              <Skeleton height={60} width='100%' variant='rounded' />
            </SkeletonsWrapperStyled>
          ) : (
            changesSummary && (
              <ChangesPreview
                changedEntities={changesSummary}
                domainGroupsSelection={domainGroupsSelection}
                onDomainGroupSelected={onDomainGroupSelected}
                dataFields={dataFields}
                previewType={previewType}
                readOnly={!modalFooter}
              />
            )
          )}
        </div>
        <div className='syte-review-changes-dialog-footer'>{modalFooter && modalFooter}</div>
      </>
    </Modal>
  );
};

ChangesModal.Header = ChangesModalHeader;
ChangesModal.Footer = ChangesModalFooter;
