import React, { useState } from 'react';

interface UseVisualEditorSettingsReturn {
  menuAnchorEl: HTMLElement | null;
  isMenuOpen: boolean;
  onCloseMenu: VoidFunction;
  onTriggerClick: (event: React.MouseEvent<HTMLElement>) => void;
  isModalOpen: boolean;
  onCloseModal: VoidFunction;
  onDisplayPreferencesClick: VoidFunction;
}

export const useVisualEditorSettings = (): UseVisualEditorSettingsReturn => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onOpenModal = () => {
    setIsModalOpen(true);
  };

  const onCloseModal = () => {
    setIsModalOpen(false);
  };

  const isMenuOpen = !!menuAnchorEl;

  const onTriggerClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const onDisplayPreferencesClick = () => {
    onCloseMenu();
    onOpenModal();
  };

  return {
    menuAnchorEl,
    isMenuOpen,
    onCloseMenu,
    onTriggerClick,
    isModalOpen,
    onCloseModal,
    onDisplayPreferencesClick,
  };
};
