import React, { useEffect, useState } from 'react';
import { UserThumbnailElementWrapperStyled } from '../UserAvatarList.styles';

export interface UserThumbnailElementWrapperProps {
  index: number;
  children: JSX.Element;
  show: boolean;
  onUnmount?: () => void;
}

const OVERLAP_PX = 7;

export const UserThumbnailElementWrapper = ({
  index,
  children,
  show,
  onUnmount,
}: UserThumbnailElementWrapperProps): JSX.Element | null => {
  const [isMounted, setIsMounted] = useState(show);

  const onAnimationStart = () => {};

  const onAnimationEnd = () => {
    if (!show) {
      setIsMounted(false);
      onUnmount?.();
    }
  };

  useEffect(() => {
    if (show && !isMounted) {
      setIsMounted(true);
    }
  }, [show]);

  return isMounted ? (
    <UserThumbnailElementWrapperStyled
      positionOffset={OVERLAP_PX * index}
      show={show}
      onAnimationEnd={onAnimationEnd}
      onAnimationStart={onAnimationStart}
    >
      {children}
    </UserThumbnailElementWrapperStyled>
  ) : null;
};
