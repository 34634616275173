import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { SyteLayout, filtersService, Filter } from 'src/services';

export const filtersListPageActions = {
  navigateTo: createAction<{ navigateTo: string }>('FiltersListPage/NavigateTo'),
  resetFilters: createAction('FiltersListPage/ResetFilters'),
  getFilterSet: createAsyncThunk(
    'FiltersListPage/GetFilterSet',
    async (
      { shopId, syteLayout }: { shopId: number; syteLayout: SyteLayout },
      { rejectWithValue }
    ) => {
      try {
        const filterSet = await filtersService.getFilterSet({ shopId, syteLayout });
        return { filterSet };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateFilter: createAsyncThunk(
    'FiltersListPage/UpdateFilter',
    async (
      {
        shopId,
        syteLayout,
        filterPayload,
        filterId,
      }: Parameters<typeof filtersService.updateFilter>[0],
      { rejectWithValue }
    ) => {
      try {
        const updatedFilter = await filtersService.updateFilter({
          shopId,
          syteLayout,
          filterPayload,
          filterId,
        });
        return updatedFilter;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  deleteFilter: createAsyncThunk(
    'FiltersListPage/DeleteFilter',
    async (
      { shopId, syteLayout, filterId }: Parameters<typeof filtersService.deleteFilter>[0],
      { rejectWithValue }
    ) => {
      try {
        const deletedFilter = await filtersService.deleteFilter({ shopId, syteLayout, filterId });
        return { deletedFilter };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  rearrangeFiltersOrder: createAsyncThunk(
    'FiltersListPage/RearrangeFiltersOrder',
    async (
      {
        shopId,
        syteLayout,
        filters,
      }: { shopId: number; syteLayout: SyteLayout; filters: Filter[] },
      { rejectWithValue }
    ) => {
      try {
        const filterSet = await filtersService.updateFilterSet({
          shopId,
          syteLayout,
          filterIds: filters.map(filter => filter.id),
        });
        return { filterSet };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
