import React from 'react';
import { LabelCellProps } from '../types';

export const LabelCell = ({ value }: LabelCellProps): JSX.Element => {
  return (
    <span title={(value as string) ?? ''} className='syte-table-label-cell'>
      {(value as string) ?? ''}
    </span>
  );
};
