export interface DiscoveryBannerSettingsReducerState {
  general: {
    bannerLayout: BannerLayout;
    imageLayout: ImageLayout;
    bannerBackgroundColor: string;
  };
  banner: {
    bannerColor: string;
    bannerFontFamily: string;
    bannerFontSize: number;
  };
  button: {
    buttonBackgroundColor: string;
    buttonColor: string;
    buttonFontFamily: string;
    buttonFontSize: number;
  };
  icon: {
    showIcon: boolean;
    iconColor: string;
  };
  shouldRefetch: boolean;
}

export enum ImageLayout {
  MIXED_SIZES = 'Mixed Sizes',
  GRID = 'Grid',
  OVERLAP = 'Overlapping',
}

export enum BannerLayout {
  RIGHT_TO_LEFT = 'Right To Left',
  LEFT_TO_RIGHT = 'Left To Right',
}
