import React, { useEffect, useRef, useState } from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { ShopFeature } from 'src/app-types';
import { useDispatch } from 'react-redux';
import { ShopDataFieldsSupportedFeatures } from 'src/services';
import { useDataFieldsBySupportedFeature } from 'src/components-bl/hooks';
import { useAppSelector, usePreviousState, useIsFeaturePermitted } from 'src/hooks';
import { generatePath, useParams } from 'react-router';
import { EditMerchandisingRulePage } from 'src/components-bl/MerchandisingRules/components/EditMerchandisingRulePage';
import { RuleApiRef } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm';
import { useDataFieldsLookupTable } from 'src/components-bl/MerchandisingRules/components/useDataFieldsLookupTable';
import { editMerchandisingRuleContainerActions } from './EditMerchandisingRuleContainer.actions';
import { FooterActionForm } from '../../../components-bl/MerchandisingRules/components/MerchandisingRuleForm/RuleFormFooter';

export const EditMerchandisingRuleContainer = ({
  permittedRouteMap,
}: RoutedComponentProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const previousShopId = usePreviousState(shopId);
  const selectedRule = useAppSelector(state => state.merchandisingRules.selectedRule);
  const overview = useAppSelector(state => state.merchandisingRules.overview);
  const dataFields = useAppSelector(state => state.dataFields.dataFields);
  const availableRegions = useAppSelector(state => state.shop.availableRegions);
  const isAiTagsEnabled = useIsFeaturePermitted(ShopFeature.MerchandisingRulesAiTags);
  const categoryFilterRule = useAppSelector(state => state.visualEditor.categoryFilterRule);
  const featureToggles = useAppSelector(state => state.shop.featureToggles);

  const { ruleId } = useParams<{ ruleId: string }>();
  const previousRuleId = usePreviousState(ruleId);

  const formApiRef = useRef(null as RuleApiRef | null);

  const [formStatus, setFormStatus] = useState<{
    canSubmit: boolean;
    isDirty?: boolean;
    isValid?: boolean;
    isSubmitting?: boolean;
  }>({ canSubmit: false, isSubmitting: false });

  const navigateToList = () => {
    if (permittedRouteMap.merchandisingRules?.path && shopId) {
      dispatch(
        editMerchandisingRuleContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.merchandisingRules?.path, { shopId }),
        })
      );
    }
  };

  useEffect(() => {
    if (
      previousShopId &&
      shopId !== previousShopId &&
      permittedRouteMap.merchandisingRules &&
      shopId
    ) {
      navigateToList();
    } else if (shopId && ruleId && ruleId !== previousRuleId) {
      (
        dispatch(
          editMerchandisingRuleContainerActions.getRule({
            shopId,
            ruleId,
          })
        ) as any
      )
        .unwrap()
        .catch(() => {
          if (permittedRouteMap.merchandisingRules?.path && shopId) {
            navigateToList();
          }
        });
    }
  }, [shopId, ruleId]);

  const { supportedDataFields } = useDataFieldsBySupportedFeature({
    dataFields,
    feature: ShopDataFieldsSupportedFeatures.MerchandisingRules,
  });

  const { dataFieldsLookupTable, getDataFieldByName } = useDataFieldsLookupTable({
    dataFields: supportedDataFields,
  });

  const handleSave = async () => {
    if (!formApiRef.current) {
      return;
    }

    setFormStatus(status => ({ ...status, isSubmitting: true }));

    try {
      await formApiRef.current.submit();

      navigateToList();
    } catch (error) {
      console.error('CreateMerchandisingRuleContainer', error);
    }

    setFormStatus(status => ({ ...status, isSubmitting: false }));
  };

  return shopId ? (
    <>
      <EditMerchandisingRulePage
        shopId={shopId}
        dispatch={dispatch}
        dataFieldsLookupTable={dataFieldsLookupTable}
        getDataFieldByName={getDataFieldByName}
        rule={selectedRule}
        onFormStatusChange={setFormStatus}
        formApiRef={formApiRef}
        kpiOptions={overview?.kpis}
        onBackToList={navigateToList}
        availableRegions={availableRegions}
        isAiTagsEnabled={isAiTagsEnabled}
        categoryFilterRule={categoryFilterRule}
        featureToggles={featureToggles}
      />
      <FooterActionForm
        disabled={!formStatus.canSubmit}
        onCancel={navigateToList}
        onSave={handleSave}
        isSubmitting={formStatus.isSubmitting}
      />
    </>
  ) : null;
};
