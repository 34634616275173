import { ExperimentSlim } from '../../../../services';

export enum ExperimentButtonsActionTypes {
  RunExperimentRequest = 'ExperimentButtons/RunExperimentRequest',
  RunExperimentSuccess = 'ExperimentButtons/RunExperimentSuccess',
  RunExperimentError = 'ExperimentButtons/RunExperimentError',
  StopExperimentRequest = 'ExperimentButtons/StopExperimentRequest',
  StopExperimentSuccess = 'ExperimentButtons/StopExperimentSuccess',
  StopExperimentError = 'ExperimentButtons/StopExperimentError',
}

export interface ExperimentButtonsActionPayload {
  id: string;
  shopId: number;
  experiment?: ExperimentSlim;
  experiments?: ExperimentSlim[];
}

export interface ExperimentButtonsErrorActionPayload {
  error: unknown;
}

export interface ExperimentButtonsRunExperimentRequestAction {
  type: typeof ExperimentButtonsActionTypes.RunExperimentRequest;
  payload: ExperimentButtonsActionPayload;
}

export interface ExperimentButtonsRunExperimentSuccessAction {
  type: typeof ExperimentButtonsActionTypes.RunExperimentSuccess;
  payload: ExperimentButtonsActionPayload;
}

export interface ExperimentButtonsRunExperimentErrorAction {
  type: typeof ExperimentButtonsActionTypes.RunExperimentError;
  payload: ExperimentButtonsErrorActionPayload;
}

export interface ExperimentButtonsStopExperimentRequestAction {
  type: typeof ExperimentButtonsActionTypes.StopExperimentRequest;
  payload: ExperimentButtonsActionPayload;
}

export interface ExperimentButtonsStopExperimentSuccessAction {
  type: typeof ExperimentButtonsActionTypes.StopExperimentSuccess;
  payload: ExperimentButtonsActionPayload;
}

export interface ExperimentButtonsStopExperimentErrorAction {
  type: typeof ExperimentButtonsActionTypes.StopExperimentError;
  payload: ExperimentButtonsErrorActionPayload;
}

export type ExperimentButtonsAction =
  | ExperimentButtonsRunExperimentRequestAction
  | ExperimentButtonsRunExperimentSuccessAction
  | ExperimentButtonsRunExperimentErrorAction
  | ExperimentButtonsStopExperimentRequestAction
  | ExperimentButtonsStopExperimentSuccessAction
  | ExperimentButtonsStopExperimentErrorAction;
