import { FiltersConfigTypes } from 'src/services';

export enum UpdateReqStatus {
  Success = 'success',
  Failed = 'failed',
  Pending = 'pending',
  None = 'none',
}
export interface FiltersSetsState {
  sets: FiltersConfigTypes.FilterSetConfiguration[] | undefined;
  updateStatus: UpdateReqStatus;
}
