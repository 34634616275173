import { useState, useEffect } from 'react';
import Joi from 'joi';
import { useValidateSchema } from 'src/hooks';
import { createExperimentFormActionMethods } from './Actions';
import {
  UseCreateExperimentFormArguments,
  UseCreateExperimentFormReturnType,
  CreateExperimentForm,
} from './types';

const initialFormState: CreateExperimentForm = {
  name: '',
};

const formValidationSchema = {
  name: Joi.string().trim().min(3).required(),
};

export const useCreateExperimentForm = ({
  shopId,
  dispatch,
  editExperimentFormRoute,
}: UseCreateExperimentFormArguments): UseCreateExperimentFormReturnType => {
  const [showPopUp, setShowPopUp] = useState(false);

  const [formState, setFormState] = useState(initialFormState);

  const { errors, isValid, resetValidationState, validate } = useValidateSchema({
    schema: formValidationSchema,
  });

  const reset = () => {
    setFormState(initialFormState);
    resetValidationState();
    setShowPopUp(false);
  };

  const onSubmit = () => {
    if (isValid && editExperimentFormRoute) {
      dispatch(
        createExperimentFormActionMethods.createExperiment({
          shopId,
          name: formState.name.trim(),
          editExperimentFormRoute,
        })
      );
      reset();
    }
  };

  const setPartialState = (partialState: Partial<CreateExperimentForm>) => {
    const updatedState = { ...formState, ...partialState };

    setFormState(updatedState);
    validate({ dataToValidate: updatedState });
  };

  useEffect(() => {
    return () => reset();
  }, []);

  return {
    showPopUp,
    setShowPopUp,
    state: formState,
    setPartialState,
    errors,
    onSubmit,
    isValid,
  };
};
