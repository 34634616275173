import React from 'react';
import {
  Button,
  MenuItem,
  NumericInput,
  RadioGroup,
  Select,
  SelectOnChangeEvent,
  SelectType,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import './BoughtTogetherSettings.scss';
import { BoughtTogetherFallbackMethod } from 'src/types/enums/bought-together-fallback-method';
import { SortingOrder } from 'src/types/enums/sorting-order';
import { BoughtTogetherSettingsProps } from './types';
import { useBoughtTogetherSettingsDraft } from './useBoughtTogetherSettingsDraft';
import { createOptionalPortal } from '../helpers';
import { ConfigurationNote } from '../../components-dummy/Labels/ConfigurationNote';
import { ConfigurationVariant } from '../../components-dummy/Labels/configuration-variant';
import { useAppSelector } from '../../hooks';
import { AddMultipleToCartForm } from '../AddToCartSettings/AddMultipleToCartForm';
import { SyteAdminContent } from '../../containers/SyteAdminContent/SyteAdminContent';

export const conditionValuesDropDownOptions = [
  { value: BoughtTogetherFallbackMethod.MostAddedToCart, text: 'Most added to cart' },
  { value: BoughtTogetherFallbackMethod.CustomFallback, text: 'Custom Fallback' },
  { value: BoughtTogetherFallbackMethod.None, text: 'None' },
];

export const modelVersionOptions = [
  { value: 'Session based', label: 'Session based' },
  { value: 'Association Rules', label: 'Association Rules' },
];

const orderOptions = [
  { value: SortingOrder.Desc, text: 'Descending' },
  { value: SortingOrder.Asc, text: 'Ascending' },
];

export const BoughtTogetherSettings = ({
  shopId,
  boughtTogetherSettings,
  dataFields,
  selectedVariantId,
  formHeaderElementRef,
  onIsDirty,
  dispatch,
  disabled = false,
  shouldRefetch,
}: BoughtTogetherSettingsProps): JSX.Element | null => {
  const addToCartEnabled = useAppSelector(({ shop }) => !!shop.featureToggles?.addToCart?.enabled);
  const boughtTogether = useBoughtTogetherSettingsDraft({
    boughtTogetherSettings,
    dataFields,
    dispatch,
    shopId,
    selectedVariantId,
    onIsDirty,
    shouldRefetch,
  });

  const {
    draftBoughtTogetherSettings,
    isValid,
    isDirty,
    onSubmit,
    onDiscard,
    onChange,
    onModelChange,
    errors,
    dataFieldsDropdownOptions,
  } = boughtTogether;

  const formButtons = !disabled && (
    <>
      <Button onClick={onDiscard} variant='tertiary' disabled={!isDirty}>
        Discard
      </Button>
      <Button onClick={onSubmit} disabled={!(isValid && isDirty)} variant='primary'>
        {selectedVariantId ? 'Apply' : 'Save'}
      </Button>
    </>
  );

  const formButtonsSection = createOptionalPortal({
    portalContent: disabled ? <></> : formButtons,
    targetContainerRef: formHeaderElementRef,
    fallback: <div className='ranking-button-wrapper'>{formButtons}</div>,
  });

  const handleDataFieldValueChange: SelectOnChangeEvent = event =>
    onChange({ boughtTogetherFallbackField: event.target.value });

  const handleSortingOrderChange: SelectOnChangeEvent = event => {
    onChange({ sortingOrder: event.target.value as SortingOrder });
  };

  const isCustomFallback =
    draftBoughtTogetherSettings.boughtTogetherFallbackMethod ===
    BoughtTogetherFallbackMethod.CustomFallback;

  const handleConditionValueChange: SelectOnChangeEvent = event => {
    onChange({
      boughtTogetherFallbackMethod: event.target.value as BoughtTogetherFallbackMethod,
      boughtTogetherFallbackField: !isCustomFallback
        ? undefined
        : draftBoughtTogetherSettings.boughtTogetherFallbackField,
      sortingOrder: !isCustomFallback ? undefined : draftBoughtTogetherSettings.sortingOrder,
    });
  };
  return (
    <div className='bought-together-settings'>
      <div className='bought-together-settings-container-wrapper'>
        <div className='bought-together-settings-container'>
          <Typography
            type={TypographyType.Body}
            variant={TypographyVariant.MediumRegular}
            className='display-title'
          >
            Results Display
          </Typography>
          <div className='bought-together-results-to-display'>
            <Typography
              type={TypographyType.Body}
              variant={TypographyVariant.MediumRegular}
              className='results-to-display-title'
            >
              Number of results to display (3-20)
            </Typography>
            <div className='results-to-display-input-wrapper'>
              <NumericInput
                disabled={disabled}
                error={!!errors.numberOfResultsToDisplay}
                value={draftBoughtTogetherSettings.numberOfResultsToDisplay}
                onChange={value => {
                  onChange({ numberOfResultsToDisplay: value });
                }}
                className='results-to-display-input'
              />
              {!!errors.numberOfResultsToDisplay && (
                <Typography
                  className='results-to-display-input-error'
                  type={TypographyType.Body}
                  variant={TypographyVariant.MediumRegular}
                >
                  Please enter a number between 3 to 20
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfigurationNote variant={ConfigurationVariant.theAboveToNonApiUsers} />

      <SyteAdminContent>
        <div className='bought-together-settings-container-wrapper fallback'>
          <div className='bought-together-settings-container'>
            <Typography
              type={TypographyType.Body}
              variant={TypographyVariant.MediumRegular}
              className='display-title'
            >
              Fallback and model
            </Typography>

            <div className='bought-together-fallback-method'>
              <Typography
                className='fallback-method-title'
                type={TypographyType.Body}
                variant={TypographyVariant.MediumRegular}
              >
                Fallback method (used in case of no results)
              </Typography>
              <Select
                type={SelectType.Menu}
                disabled={disabled}
                value={draftBoughtTogetherSettings.boughtTogetherFallbackMethod}
                className='fallback-method-dropdown'
                onChange={handleConditionValueChange}
              >
                {conditionValuesDropDownOptions.map((option: { value: string; text: string }) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
              {isCustomFallback && (
                <>
                  <Select
                    className='fallback-method-dropdown'
                    type={SelectType.Menu}
                    value={draftBoughtTogetherSettings.boughtTogetherFallbackField}
                    onChange={handleDataFieldValueChange}
                  >
                    {dataFieldsDropdownOptions.map((option: { value: string; text: string }) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.text}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    className='fallback-method-dropdown'
                    type={SelectType.Menu}
                    value={draftBoughtTogetherSettings.sortingOrder || SortingOrder.Desc}
                    onChange={handleSortingOrderChange}
                  >
                    {orderOptions.map((option: { value: string; text: string }) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.text}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </div>

            <div className='bought-together-model-version'>
              <Typography
                className='fallback-method-title'
                type={TypographyType.Body}
                variant={TypographyVariant.MediumRegular}
              >
                Model Type
              </Typography>
              <RadioGroup
                name='model-version-radio-group'
                selectedValue={
                  modelVersionOptions.filter(
                    model => model.value === draftBoughtTogetherSettings.modelType
                  )[0].value
                }
                options={modelVersionOptions}
                onChange={value => {
                  onModelChange(value.toString());
                }}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
        <ConfigurationNote variant={ConfigurationVariant.theAboveToAllClients} />
      </SyteAdminContent>
      <AddMultipleToCartForm
        showSettingsForm={addToCartEnabled}
        addToCart={draftBoughtTogetherSettings.addToCart}
        onChange={addToCart => onChange({ addToCart })}
        shopId={shopId}
      />
      {formButtonsSection}
    </div>
  );
};
