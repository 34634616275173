import styled from '@emotion/styled';
import { css } from '@emotion/react';
import MuiButton from '@mui/material/Button';
import { ButtonSize } from './Button.types';
import { Spinner } from '../Spinner';

const sizeToButtonPaddingMap: Record<
  ButtonSize,
  { padding: string; spinnerSize: number; height: number }
> = {
  tiny: {
    height: 28,
    padding: '6px 10px',
    spinnerSize: 20,
  },
  small: { padding: '8px 12px', spinnerSize: 20, height: 32 },
  medium: { padding: '12px 12px', spinnerSize: 20, height: 40 },
};

const buttonBaseStyle = ({
  buttonSize,
  loading,
}: {
  buttonSize: ButtonSize;
  loading?: boolean;
}) => css`
  padding: ${sizeToButtonPaddingMap[buttonSize].padding};
  height: ${sizeToButtonPaddingMap[buttonSize].height}px;
  pointer-events: ${loading ? 'none' : 'initial'};
  box-shadow: none;

  transition: none;

  &:hover {
    box-shadow: none;
  }

  .syte-button-text,
  .MuiButton-startIcon,
  .MuiButton-endIcon {
    opacity: ${loading ? 0 : 1};
  }

  .MuiButton-startIcon {
    margin-left: unset;
    margin-right: 5px;
  }
`;

export const SpinnerWrapperStyled = styled.div<{
  buttonSize: ButtonSize;
  loading?: boolean;
}>`
  position: absolute;
  display: ${({ loading }) => (loading ? 'flex' : 'none')};
  opacity: ${({ loading }) => (loading ? 1 : 0)};

  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;

export const SpinnerStyled = styled(Spinner)<{
  buttonSize: ButtonSize;
  loading?: boolean;
}>`
  width: ${({ buttonSize }) => sizeToButtonPaddingMap[buttonSize].spinnerSize}px !important;
  height: ${({ buttonSize }) => sizeToButtonPaddingMap[buttonSize].spinnerSize}px !important;
`;

export const ButtonPrimaryStyled = styled(MuiButton)`
  background-color: ${({ theme }) => theme.palette.custom['primary-main']};
  color: ${({ theme }) => theme.palette.common.white};
  ${buttonBaseStyle}

  .MuiCircularProgress-root {
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const ButtonSecondaryStyled = styled(MuiButton)`
  background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  color: ${({ theme }) => theme.palette.common.black};
  ${buttonBaseStyle}

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom['primary-light']};
  }

  .MuiCircularProgress-root {
    color: ${({ theme }) => theme.palette.custom['primary-main']};
  }
`;

export const ButtonTertiaryStyled = styled(MuiButton)`
  color: ${({ theme }) => theme.palette.common.black};
  ${buttonBaseStyle}

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom['primary-90']};
  }

  .MuiCircularProgress-root {
    color: ${({ theme }) => theme.palette.common.black};
  }
`;

export const ButtonDestructiveStyled = styled(MuiButton)`
  background-color: ${({ theme }) => theme.palette.custom.red};
  color: ${({ theme }) => theme.palette.common.white};
  ${buttonBaseStyle}

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom['red-80']};
  }

  .MuiCircularProgress-root {
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const ButtonOutlinedStyled = styled(MuiButton)`
  color: ${({ theme }) => theme.palette.custom['primary-main']};
  border-color: ${({ theme }) => theme.palette.custom['primary-main']};
  ${buttonBaseStyle}

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom['primary-90']};
  }

  .MuiCircularProgress-root {
    color: ${({ theme }) => theme.palette.common.black};
  }
`;
