import React, { useMemo, useCallback } from 'react';
import { CodeSection, CodeSectionProps } from 'src/components-dummy/CodeSection';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { RuleFormSection } from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormSection/RuleFormSection';
import { jsSnippetSectionActions } from '../../../ApiKeysSettings/JsSnippetSection/Actions';
import { CollectionSnippetProps } from './types';
import { SubTitleStyled } from './SubTitle.styled';
import { CollectionSnippetStyled } from './CollectionSnippet.styled';

export const CollectionSnippet: React.FC<CollectionSnippetProps> = ({
  draftCollection,
  dispatch,
}) => {
  const { dataCollectionId, placement } = draftCollection;

  const snippet = (): CodeSectionProps['codeParts'] => {
    return [
      { text: '<div ', color: 'green' },
      { text: 'id="', color: 'pink' },
      { text: 'syte-collections-container', color: 'black' },
      { text: '" data-collection-id="', color: 'pink' },
      { text: `${dataCollectionId}`, color: 'blue' },
      { text: '" data-placement="', color: 'pink' },
      { text: `${placement}`, color: 'blue' },
      { text: '" data-sku=""', color: 'pink' },
      { text: '></div>', color: 'green' },
    ];
  };
  const snippetParts: CodeSectionProps['codeParts'] = useMemo(
    () => snippet(),
    [placement, dataCollectionId]
  );
  const onCopySnippetClick = useCallback(() => {
    dispatch(
      jsSnippetSectionActions.notification({ customMessage: 'Snippet copied to clipboard' })
    );
  }, [dispatch]);

  return (
    <CollectionSnippetStyled>
      <RuleFormSection.Header>Collections Carousel JS Snippet</RuleFormSection.Header>
      <SubTitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
        Integrate the Collections Carousel feature on your website.
      </SubTitleStyled>
      <Typography type={TypographyType.Paragraph} variant={TypographyVariant.SmallRegular}>
        Place the snippet below in the appropriate section of your desired webpage. Place the
        currently viewed product SKU value under data-sku, when relevant.
      </Typography>
      <CodeSection codeParts={snippetParts} enableCopy onCopyClick={onCopySnippetClick} />
    </CollectionSnippetStyled>
  );
};
