import React from 'react';
import { GridLayoutContainer } from './GridLayout.styles';
import { Skeleton } from '../../../../../components-dummy';

export const GridSkeleton = ({ itemsCountPerRow }: { itemsCountPerRow: number }): JSX.Element => (
  <GridLayoutContainer itemsCountPerRow={itemsCountPerRow}>
    <Skeleton
      repeat={18}
      variant='rounded'
      height={388}
      sx={{ margin: 0, minWidth: '155px', maxWidth: '220px' }}
    />
  </GridLayoutContainer>
);
