import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dispatch } from 'src/components-bl';
import { AvailableIcons, Button, Icon, Tooltip } from 'src/components-dummy';
import { DeepTagReportsGeneralConfigurationLabel } from 'src/services/src/service/types';
import { v4 as uuid } from 'uuid';
import {
  ListItems,
  AddItemButtonWrapperStyled,
  DeepTagReportProductsCustomLabelBulkStyled,
} from './DeepTagReportProductsCustomLabelBulk.style';
import { CustomLabelBulkFormItem, DeepTagBulkCustomFormItem } from './DeepTagBulkCustomFormItem';
import { useCustomBulkItemsValidation } from './useCustomBulkItemsValidation';
import {
  ErrorLabelStyled,
  ErrorsMessagesWrapperStyled,
} from '../../DeepTagsReportProductsBulkLabelModal.style';

export interface DeepTagReportProductsCustomLabelBulkProps {
  freeSolo: boolean;
  multiple: boolean;
  selectedValues: string[];
  disabled: boolean;
  totalAvailableProductsToAssign: number;
  generalConfigurationLabels: DeepTagReportsGeneralConfigurationLabel[];
  onIsFormValidChange: (isValid: boolean) => void;
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
  onChange: (formData: CustomLabelBulkFormItem[]) => void;
  dispatch: Dispatch;
}

export const DeepTagReportProductsCustomLabelBulk = React.memo(
  ({
    generalConfigurationLabels,
    totalAvailableProductsToAssign,
    onIsFormValidChange,
    onChange,
    dispatch,
  }: DeepTagReportProductsCustomLabelBulkProps): JSX.Element => {
    const [formData, setFormData] = useState<Array<CustomLabelBulkFormItem>>([]);

    const generalConfigurationNameById = useMemo(() => {
      return generalConfigurationLabels.reduce(
        (prev: Record<string, string>, next: DeepTagReportsGeneralConfigurationLabel) => {
          // eslint-disable-next-line no-param-reassign
          prev[next.id] = next.name;
          return prev;
        },
        {}
      );
    }, [generalConfigurationLabels]);

    const {
      uniqueItemsErrorMessages,
      validationErrorsPerItem,
      totalFormAvailableProductsToAssign,
      exceededItemsNumberErrorMessage,
      isValid,
    } = useCustomBulkItemsValidation({
      generalConfigurationNameById,
      totalAvailableProductsToAssign,
      formData,
    });

    const onAddMore = useCallback(() => {
      setFormData(state => {
        return [...state, { labelId: '', totalProducts: undefined, id: uuid() }];
      });
    }, []);

    const onChangeItem = useCallback(async (updatedItem: CustomLabelBulkFormItem) => {
      setFormData((prevState: CustomLabelBulkFormItem[]) => {
        const updatedState = prevState.map(stateItem => {
          return stateItem.id === updatedItem.id ? updatedItem : stateItem;
        });

        return updatedState;
      });
    }, []);

    const onDeleteItem = useCallback(
      (itemId: string) => () => {
        setFormData((prevState: CustomLabelBulkFormItem[]) => {
          const updatedState = prevState.filter(stateItem => {
            return itemId !== stateItem.id;
          });

          return updatedState;
        });
      },
      []
    );

    useEffect(() => {
      onIsFormValidChange(!exceededItemsNumberErrorMessage);
    }, [exceededItemsNumberErrorMessage]);

    useEffect(() => {
      onIsFormValidChange(isValid);
    }, [isValid]);

    useEffect(() => {
      onChange(formData);
    }, [formData]);

    const shouldEnableAddTags = totalFormAvailableProductsToAssign > 0;

    return (
      <DeepTagReportProductsCustomLabelBulkStyled>
        {(exceededItemsNumberErrorMessage || uniqueItemsErrorMessages.length > 0) && (
          <ErrorsMessagesWrapperStyled>
            {exceededItemsNumberErrorMessage && (
              <ErrorLabelStyled key='generalError'>
                {exceededItemsNumberErrorMessage}
              </ErrorLabelStyled>
            )}
            {uniqueItemsErrorMessages.map(message => (
              <ErrorLabelStyled key={message}>{message}</ErrorLabelStyled>
            ))}
          </ErrorsMessagesWrapperStyled>
        )}
        <ListItems>
          {formData.map(({ labelId, totalProducts, id }) => {
            return (
              <DeepTagBulkCustomFormItem
                key={id}
                id={id}
                labelId={labelId}
                totalProducts={totalProducts}
                errors={validationErrorsPerItem[id]}
                totalFormAvailableProductsToAssign={totalFormAvailableProductsToAssign}
                hasExceededNumberOfItems={!!exceededItemsNumberErrorMessage}
                onChangeItem={onChangeItem}
                onDeleteItem={onDeleteItem(id)}
                dispatch={dispatch}
                disabled={false}
              />
            );
          })}
        </ListItems>
        <AddItemButtonWrapperStyled disabled={!shouldEnableAddTags}>
          <Tooltip value='No free items to assign' disabled={shouldEnableAddTags}>
            <Button
              variant='tertiary'
              size='small'
              onClick={onAddMore}
              disabled={!shouldEnableAddTags}
            >
              <Icon name={AvailableIcons.Plus} />
              Add more
            </Button>
          </Tooltip>
        </AddItemButtonWrapperStyled>
      </DeepTagReportProductsCustomLabelBulkStyled>
    );
  }
);
