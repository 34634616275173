import styled from '@emotion/styled';

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
`;

export const AccordionTriggerGroup = styled.div`
  color: #1e1e1e;
  display: flex;
  align-items: center;
  gap: 2px;

  svg {
    height: 16px;
    width: 16px;
    transform: rotate(-90deg);
    &.closed {
      transform: rotate(90deg);
    }
  }
`;

export const VisualEditorSubmenu = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

export const VisualEditorForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
