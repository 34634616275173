/* eslint no-param-reassign:0 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssignedProduct, PublicPost, UnpublishedPost } from '../../types/entities.types';
import { ResponseValue } from '../../types/common.types';
import { State, StateType } from './types';

const createInitialState = (posts: PublicPost[]): State => ({
  selectedPostIndex: posts.length ? 0 : null,
  type: StateType.idle,
  editedPosts: posts,
  moderatedPosts: [],
  error: null,
});

export const createReducer = (posts: PublicPost[]) => {
  return createSlice({
    name: 'socialDiscovery/editPosts',
    initialState: createInitialState(posts),
    reducers: {
      selectPost: (state, action: PayloadAction<number>) => {
        state.selectedPostIndex = action.payload;
      },
      addPosts: (state, action: PayloadAction<PublicPost[]>) => {
        state.editedPosts = [...action.payload, ...state.editedPosts];
        state.selectedPostIndex = 0;
        state.type = StateType.idle;
      },
      closeModerationModal: state => {
        state.type = StateType.idle;
      },
      removePost: (state, action: PayloadAction<number>) => {
        state.editedPosts.splice(action.payload, 1);
        switch (state.editedPosts.length) {
          case 0:
            state.selectedPostIndex = null;
            break;
          case 1:
            state.selectedPostIndex = 0;
            break;
          default:
            state.selectedPostIndex = Math.min(
              state.selectedPostIndex,
              state.editedPosts.length - 1
            );
        }
      },
      setAssignedProducts: (state, action: PayloadAction<AssignedProduct[]>) => {
        state.editedPosts[state.selectedPostIndex].assignedProducts = action.payload;
      },
      changeOrder: (state, action: PayloadAction<number>) => {
        const [post] = state.editedPosts.splice(state.selectedPostIndex, 1);
        state.editedPosts.splice(action.payload, 0, post);
        state.selectedPostIndex = action.payload;
      },
      setIsFetching: (state: State) => {
        state.type = StateType.fetchingModeratedPosts;
      },
      resolveModeratedPosts: (
        state: State,
        { payload }: PayloadAction<ResponseValue<{ unpublishedPosts: UnpublishedPost[] }>>
      ) => {
        if (payload.error !== null) {
          state.error = payload.error;
          state.type = StateType.idle;
          return;
        }
        state.moderatedPosts = payload.data.unpublishedPosts;
        state.type = StateType.moderationModalOpen;
        state.error = null;
      },
    },
  });
};
