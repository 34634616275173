import React, { useEffect } from 'react';
import { useAppSelector } from 'src/hooks';

export const WindowEventCatcher = (): JSX.Element => {
  const isDirty = useAppSelector(state => state.global.isDirty);

  function catchRefresh(prompt: boolean) {
    window.onbeforeunload = (event: BeforeUnloadEvent): null | string => {
      const e = event || window.event;
      if (prompt) {
        e.preventDefault();
        if (e) {
          e.returnValue = '';
        }
        return '';
      }

      return null;
    };
  }

  window.onload = function () {
    catchRefresh(isDirty);
  };

  useEffect(() => {
    catchRefresh(isDirty);
  }, [isDirty]);

  return <></>;
};
