import React from 'react';
import Joi from 'joi';
import { EditableText, EditableTextProps } from 'src/components-dummy';
import {
  DeepTagReportEditableNameStyled,
  ErrorLabelStyled,
  ErrorsMessagesWrapperStyled,
} from './DeepTagReportEditableName.style';

const MIN_CHARACTERS = 2;
const MAX_CHARACTERS = 50;

const MIN_CHARACTERS_MESSAGE = `Batch name should be at least ${MIN_CHARACTERS} characters long`;

const textValidator = () =>
  Joi.string()
    .required()
    .trim()
    .min(MIN_CHARACTERS)
    .max(MAX_CHARACTERS)
    .messages({
      'string.empty': MIN_CHARACTERS_MESSAGE,
      'string.min': MIN_CHARACTERS_MESSAGE,
      'string.max': `Batch name should be maximum ${MAX_CHARACTERS} characters long`,
    });

interface DeepTagReportEditableNameProps {
  name: string;
  dynamicWidthMultiplier: number;
  onChange: ({ customName }: { customName: string }) => void;
  renderText: EditableTextProps['renderText'];
}

export const DeepTagReportEditableName = ({
  name,
  onChange,
  dynamicWidthMultiplier,
  renderText,
}: DeepTagReportEditableNameProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
  const [isInProcess, setIsInProcess] = React.useState<boolean>(false);

  const onValidateTextInput = (inputText: string): boolean => {
    const validation = textValidator().validate(inputText || '');

    if (validation.error) {
      setErrorMessage(validation.error.message);
      return false;
    }

    /* Clear error message */
    if (errorMessage) {
      setErrorMessage(undefined);
    }

    return true;
  };

  const handleChange: EditableTextProps['onChange'] = async ({ value, resetValue }) => {
    setIsInProcess(true);

    try {
      const response = await (onChange({ customName: value }) as any);

      if (response.error) {
        resetValue();
      }
    } catch (error: unknown) {
      resetValue();
    } finally {
      setIsInProcess(false);
    }
  };

  return (
    <DeepTagReportEditableNameStyled>
      <EditableText
        key={name}
        currentText={name || ''}
        onChange={handleChange}
        onValidateTextInput={onValidateTextInput}
        isWithDynamicWidth
        readOnlyHoverTooltipText={isInProcess ? 'Updating value' : 'Rename'}
        renderText={renderText}
        dynamicWidthMultiplier={dynamicWidthMultiplier}
        isLoading={isInProcess}
      />
      {errorMessage && (
        <ErrorsMessagesWrapperStyled>
          <ErrorLabelStyled> {errorMessage}</ErrorLabelStyled>
        </ErrorsMessagesWrapperStyled>
      )}
    </DeepTagReportEditableNameStyled>
  );
};
