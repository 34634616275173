import { MerchandisingRuleTypes, FeedDataFieldType } from 'src/services';

export interface MerchandisingRuleSettings {
  conditionTypesOptions: ConditionTypesOptions;
  subRulesConditionTypes: MerchandisingRuleTypes.MerchandisingRuleSubType[];
  conditionTypes: ConditionRowType[] | null;
  actions: MerchandisingRuleTypes.RuleAction[];
}

export enum ConditionRowType {
  FilterByCondition = 'FilterByCondition',
  SearchCondition = 'SearchCondition',
  ApplyWhenCondition = 'ApplyWhenCondition',
}

export type ConditionTypesOptions = {
  [index in ConditionRowType]?: MerchandisingRuleTypes.MerchandisingRuleSubType[];
};

export type SyteProductToRuleSettingsMap = Record<
  MerchandisingRuleTypes.MerchandisingRuleProduct,
  MerchandisingRuleSettings
>;

export type DataFieldFormToConditionTypeMap = Record<
  FeedDataFieldType,
  MerchandisingRuleTypes.MerchandisingRuleSubType[]
>;
