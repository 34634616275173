import React, { ReactNode } from 'react';
import classNames from 'classnames';

export interface PageContentProps {
  children: ReactNode | ReactNode[];
  className?: string;
}

export const PageContent = ({ children, className }: PageContentProps): JSX.Element => (
  <div className={classNames('syte-page-content', className)}>{children}</div>
);
