import styled from '@emotion/styled';
import { ListSubheader } from '@mui/material';
import { SearchInput } from 'src/components-dummy';

export const ListSubheaderStyled = styled(ListSubheader)`
  padding: 14px 0 8px 0;
`;

export const SearchInputStyled = styled(SearchInput)`
  padding: 0 8px;
  margin-bottom: 8px;
  width: 100%;

  .input-base-wrapper,
  .input-base-wrapper: focus,
  .input-base-wrapper: focus-within {
    border: unset !important;
    outline: unset !important;

    background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  }
`;
