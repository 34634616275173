import { Experiment, MerchandisingRuleTypes, SyteProductTypes } from 'src/services';
import { RankingStrategyWeight } from 'src/services/src/service/types/ranking-strategy';

export enum EditExperimentContainerActionTypes {
  GetExperimentRequest = 'EditExperimentContainer/GetExperimentRequest',
  GetExperimentSuccess = 'EditExperimentContainer/GetExperimentSuccess',
  GetExperimentError = 'EditExperimentContainer/GetExperimentError',
  CloseVariantPanel = 'EditExperimentContainer/ClosePanel',
  NavigateTo = 'EditExperimentContainer/NavigateTo',
  ClearCurrentExperiment = 'EditExperimentContainer/ClearCurrentExperiment',
}

export interface EditExperimentContainerGetExperimentRequestActionPayload {
  shopId: number;
  id: string;
  pathToNavigateOnFail?: string;
}

export interface EditExperimentContainerGetVariantRankingsRequestActionPayload {
  weights: RankingStrategyWeight[];
  syteProduct: SyteProductTypes.SyteProductType;
}

export interface EditExperimentContainerGetVariantMerchRulesRequestActionPayload {
  merchandisingRules: MerchandisingRuleTypes.MerchandiseRule[];
}

export interface EditExperimentContainerGetExperimentSuccessActionPayload {
  experiment: Experiment;
}

export interface EditExperimentContainerGetExperimentErrorActionPayload {
  error: unknown;
}

export interface EditExperimentContainerNavigateToActionPayload {
  navigateTo: string;
}

export interface EditExperimentContainerGetExperimentRequestAction {
  type: typeof EditExperimentContainerActionTypes.GetExperimentRequest;
  payload: EditExperimentContainerGetExperimentRequestActionPayload;
}

export interface EditExperimentContainerGetExperimentSuccessAction {
  type: typeof EditExperimentContainerActionTypes.GetExperimentSuccess;
  payload: EditExperimentContainerGetExperimentSuccessActionPayload;
}

export interface EditExperimentContainerGetExperimentErrorAction {
  type: typeof EditExperimentContainerActionTypes.GetExperimentError;
  payload: EditExperimentContainerGetExperimentErrorActionPayload;
}

export interface EditExperimentContainerVariantPanelClosePanelAction {
  type: typeof EditExperimentContainerActionTypes.CloseVariantPanel;
  payload: undefined;
}
export interface EditExperimentContainerNavigateToAction {
  type: typeof EditExperimentContainerActionTypes.NavigateTo;
  payload: EditExperimentContainerNavigateToActionPayload;
}

export interface EditExperimentContainerClearCurrentExperimentAction {
  type: typeof EditExperimentContainerActionTypes.ClearCurrentExperiment;
  payload: undefined;
}

export type EditExperimentContainerAction =
  | EditExperimentContainerGetExperimentRequestAction
  | EditExperimentContainerGetExperimentSuccessAction
  | EditExperimentContainerGetExperimentErrorAction
  | EditExperimentContainerVariantPanelClosePanelAction
  | EditExperimentContainerNavigateToAction
  | EditExperimentContainerClearCurrentExperimentAction;
