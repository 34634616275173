import React from 'react';
import { AvailableIcons } from '../Icon';
import { ActionIconStyled, SpinnerStyled, IconStyled } from './ActionIcon.styles';
import { ActionIconProps, IconColorAttribute, SupportedIconsUnion } from './ActionIcon.types';

const iconToColorAttributes: Record<SupportedIconsUnion, IconColorAttribute[]> = {
  [AvailableIcons.TrashCan]: ['stroke'],
  [AvailableIcons.EllipsisMenu]: ['fill'],
  [AvailableIcons.Pencil]: ['fill'],
  [AvailableIcons.Search]: ['fill'],
  [AvailableIcons.Close]: ['fill'],
  [AvailableIcons.Plus]: ['stroke'],
};

export const ActionIcon = ({
  iconName,
  onClick,
  className,
  prefixId,
  disabled = false,
  size = 'medium',
  active,
  loading = false,
}: ActionIconProps): JSX.Element => {
  const iconColorAttributes = iconToColorAttributes[iconName];

  return (
    <ActionIconStyled
      data-id={prefixId}
      onClick={onClick}
      iconSize={size}
      disabled={disabled}
      loading={loading}
      className={className}
      isActive={active}
      iconColorAttributes={iconColorAttributes}
    >
      <IconStyled name={iconName} loading={loading} disabled={disabled} iconSize={size} />
      <SpinnerStyled disabled={disabled} iconSize={size} loading={loading} />
    </ActionIconStyled>
  );
};
