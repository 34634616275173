import { ApiServiceBase } from '../api-service-base';
import {
  FeedDataFieldValuesResult,
  FeedProductsResponse,
  FieldType,
  ShopDataField,
} from '../types';
import { DataFieldsMapper } from './data-fields.mapper';

export class DataFieldsService extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl({ shopId }: { shopId: number }): string {
    return `${this.serviceBaseUri}/${shopId}/data-fields`;
  }

  async getDataFields({
    shopId,
    fieldType,
  }: {
    shopId: number;
    fieldType?: FieldType;
  }): Promise<ShopDataField[]> {
    const queryParams = fieldType ? `?fieldType=${fieldType}` : '';
    const url = `${this.getUrl({ shopId })}${queryParams}`;

    const response = await this.httpService.get({ url });

    return DataFieldsMapper.mapDataFields(response);
  }

  async updateDataFields({
    dataFields,
    shopId,
  }: {
    shopId: number;
    dataFields: ShopDataField[];
  }): Promise<ShopDataField[]> {
    const url = this.getUrl({ shopId });

    const response = await this.httpService.put({
      url,
      data: { dataFields },
    });

    return DataFieldsMapper.mapDataFields(response);
  }

  async searchFeedDataFieldValues({
    shopId,
    searchTerm,
    primaryDataField,
    additionalDataFields,
    limit,
    after,
    omitValues,
    cancellationSignal,
    fieldsToReturn,
  }: {
    shopId: number;
    searchTerm?: string;
    primaryDataField?: string;
    additionalDataFields?: string[];
    omitValues?: string[];
    limit: number;
    after?: Record<string, string>;
    cancellationSignal?: AbortSignal;
    fieldsToReturn?: string[];
  }): Promise<FeedDataFieldValuesResult> {
    const url = `${this.getUrl({ shopId })}/search`;

    const params = {
      searchTerm,
      limit,
      omitValues,
      primaryDataField,
      additionalDataFields,
      fieldsToReturn,
    };

    const response = await this.httpService.post({
      url,
      requestConfig: { params, signal: cancellationSignal },
      data: { after },
    });

    return response.data as FeedDataFieldValuesResult;
  }

  async getFeedProducts({
    shopId,
    searchTerms,
    primaryDataField,
    limit,
    cancellationSignal,
    fieldsToReturn,
  }: {
    shopId: number;
    searchTerms: string[];
    primaryDataField?: string;
    limit?: number;
    cancellationSignal?: AbortSignal;
    fieldsToReturn?: string[];
  }): Promise<FeedProductsResponse> {
    const url = `${this.getUrl({ shopId })}/products`;

    const params = {
      limit,
      primaryDataField,
      fieldsToReturn,
    };

    const response = await this.httpService.post({
      url,
      requestConfig: { params, signal: cancellationSignal },
      data: { searchTerms },
    });

    return response.data as FeedProductsResponse;
  }
}

export const dataFieldsService = new DataFieldsService();
