/* eslint-disable unused-imports/no-unused-vars-ts */
/* eslint-disable react/no-unescaped-entities */
import React, { useCallback } from 'react';
import {
  AvailableIcons,
  Button,
  CircularProgress,
  CircularProgressSizes,
  CircularWrapperStyled,
  Icon,
} from 'src/components-dummy';
import { DescriptionTextLengthEnum, ToneOfVoiceTypes } from 'src/services/src';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { FooterActionsStyled } from '../../DeepTagsProductForm.style';
import {
  ActionsAndParametersStyled,
  ButtonEditStyled,
  ButtonWithIconWrapper,
  EditableTextSectionStyled,
  EditableContentStyled,
  EditableContentWrapperStyled,
  MainStyled,
  TabPanelActionsStyled,
  GenericContainerStyled,
} from './DeepTagsGenerativeAIForm.style';
import { GenerativeAIParameterMenu } from './GenerativeAIParameterMenu';
import {
  toneOfVoiceMenuOptions,
  descriptionTextLengthMenuOptions,
} from './DeepTagsGenerativeAIForm.config';
import {
  TEXT_LENGTH_DROP_DOWN_LABEL,
  TONE_OF_VOICE_DROP_DOWN_LABEL,
} from './DeepTagsGenerativeForm.config';

const cleanHTMLTags = (html: string): string => {
  let cleanedDIV = html.replace(/<div?\/>/g, '');
  cleanedDIV = cleanedDIV.replace(/<\/div>/g, '');
  cleanedDIV = cleanedDIV.replace(/<br\s*\/?>/g, '\n');

  return cleanedDIV;
};

const SpinnerWithWrapper = () => (
  <CircularWrapperStyled height={100}>
    <CircularProgress size={CircularProgressSizes.Medium} />
  </CircularWrapperStyled>
);

interface DescriptionGeneratedFormProps {
  description: string;
  toneOfVoice: ToneOfVoiceTypes;
  textLength: DescriptionTextLengthEnum;
  isInGenerateProcess: boolean;
  isInEditMode: boolean;
  requestPromptText?: string;
  setIsInEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  backToMainScreen: () => void;
  onRegenerateButtonClick: () => void;
  onDescriptionKeepEditedState: (value: string) => void;
  onToneOfVoiceChange: (value: string) => void;
  onTextLengthChange: (value: string) => void;
  onSaveChanges: () => void;
  onToggleShowRequestPrompt?: () => void;
}

export const DescriptionGeneratedForm = ({
  description,
  toneOfVoice,
  textLength,
  onSaveChanges,
  onDescriptionKeepEditedState,
  backToMainScreen,
  isInGenerateProcess,
  isInEditMode,
  setIsInEditMode,
  onRegenerateButtonClick,
  onToneOfVoiceChange,
  onTextLengthChange,
  onToggleShowRequestPrompt,
}: DescriptionGeneratedFormProps): JSX.Element => {
  const descriptionTempText = React.useRef(''); // for edit mode
  const editableComponent = React.useRef<ContentEditable>(null);

  const onEditButtonClick = () => {
    setIsInEditMode(prevState => {
      const isInEditModeNewState = !prevState;

      if (isInEditModeNewState === false) {
        // Edit button can't exit edit mode (only from editable EditableContent - save/cancel)
        return prevState;
      }

      descriptionTempText.current = editableComponent?.current?.el?.current.innerHTML || '';

      return isInEditModeNewState;
    });
  };

  const onEditChange = useCallback((event: ContentEditableEvent) => {
    descriptionTempText.current = event.currentTarget.innerHTML;
  }, []);

  const onCancelEdit = () => {
    descriptionTempText.current = '';
    setIsInEditMode(false);
  };

  const onKeepEditedStateClick = () => {
    const cleanedText = cleanHTMLTags(descriptionTempText.current.trim());
    onDescriptionKeepEditedState(cleanedText);
    descriptionTempText.current = '';
  };

  const convertNewlinesToBRElements = (inputString: string): string => {
    return inputString.replace(/\n/g, '<br/>');
  };

  return (
    <GenericContainerStyled>
      <MainStyled>
        <EditableTextSectionStyled>
          {isInGenerateProcess ? (
            <SpinnerWithWrapper />
          ) : (
            <EditableContentWrapperStyled isInEditMode={isInEditMode}>
              <EditableContentStyled>
                <ContentEditable
                  ref={editableComponent as any}
                  html={
                    isInEditMode
                      ? descriptionTempText.current
                      : convertNewlinesToBRElements(description)
                  }
                  disabled={!isInEditMode}
                  onChange={onEditChange}
                  onKeyDown={e => {
                    e.stopPropagation();
                  }}
                  tagName='div'
                />
              </EditableContentStyled>
              {isInEditMode && (
                <TabPanelActionsStyled>
                  <Button variant='tertiary' onClick={onCancelEdit}>
                    Cancel
                  </Button>
                  <Button variant='primary' onClick={onKeepEditedStateClick}>
                    Save
                  </Button>
                </TabPanelActionsStyled>
              )}
            </EditableContentWrapperStyled>
          )}
        </EditableTextSectionStyled>
        <ActionsAndParametersStyled>
          <GenerativeAIParameterMenu
            key='toneOfVoice'
            label={TONE_OF_VOICE_DROP_DOWN_LABEL}
            disabled={isInEditMode}
            options={toneOfVoiceMenuOptions}
            selectedValue={toneOfVoice}
            onChange={onToneOfVoiceChange}
          />
          <GenerativeAIParameterMenu
            key='textLength'
            label={TEXT_LENGTH_DROP_DOWN_LABEL}
            disabled={isInEditMode}
            options={descriptionTextLengthMenuOptions}
            selectedValue={textLength}
            onChange={onTextLengthChange}
          />
          <ButtonWithIconWrapper
            key='regenerateButton'
            onClick={onRegenerateButtonClick}
            disabled={isInEditMode}
            variant='secondary'
            size='tiny'
          >
            <Icon name={AvailableIcons.RefreshRegenerate} />
            Regenerate
          </ButtonWithIconWrapper>
          <ButtonEditStyled
            key='editButton'
            onMouseDown={onEditButtonClick}
            variant='secondary'
            size='tiny'
            isInEditMode={isInEditMode}
          >
            <Icon name={AvailableIcons.EditV2} />
            Edit
          </ButtonEditStyled>
          {onToggleShowRequestPrompt && (
            <ButtonEditStyled
              key='prompt'
              onMouseDown={onToggleShowRequestPrompt}
              variant='secondary'
              size='tiny'
              isInEditMode={isInEditMode}
            >
              <Icon name={AvailableIcons.Prompt} />
              Prompt
            </ButtonEditStyled>
          )}
        </ActionsAndParametersStyled>
      </MainStyled>
      {!isInEditMode && (
        <FooterActionsStyled>
          <Button variant='tertiary' onClick={backToMainScreen}>
            Cancel
          </Button>
          <Button variant='primary' disabled={!description} onClick={onSaveChanges}>
            Save Changes
          </Button>
        </FooterActionsStyled>
      )}
    </GenericContainerStyled>
  );
};
