import React, { useEffect, useMemo } from 'react';
import { Redirect, generatePath, useParams } from 'react-router';
import { AppRoute, AppSwitch, RoutedComponentProps } from 'src/app-routes';
import { MAX_ALLOWED_ACTIVE_CUSTOM_ORDER_FILTERS } from 'src/components-bl/Filters';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { FieldType, FilterSortBy, SyteLayout } from 'src/services';
import { filtersContainerActions } from './Actions';
import { FiltersListContainer, CreateFilterContainer } from './components';
import { EditFilterContainer } from './components/EditFilterContainer';

type FiltersContainerProps = RoutedComponentProps & { shopId: number | undefined };

const syteLayoutsOptions = Object.values(SyteLayout);

export const MAX_ALLOWED_ACTIVE_FILTERS = 100;

export const FiltersContainer = ({
  permittedRouteMap,
  shopId,
}: FiltersContainerProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(state => state.filters.filters);

  const activeFilters = useMemo(() => filters?.filter(filter => filter.enabled), [filters]);

  const hasReachedMaxAllowedFiltersLimit = useMemo(() => {
    if (!activeFilters) return undefined;

    return activeFilters.length >= MAX_ALLOWED_ACTIVE_FILTERS;
  }, [activeFilters]);

  const hasReachedMaxAllowedCustomOrderFiltersLimit = useMemo(() => {
    if (!activeFilters) return undefined;

    return (
      activeFilters.filter(filter => filter.valuesSorting?.sortBy === FilterSortBy.CustomOrder)
        .length >= MAX_ALLOWED_ACTIVE_CUSTOM_ORDER_FILTERS
    );
  }, [activeFilters]);

  const { syteLayout: syteLayoutParam } = useParams<{ syteLayout: SyteLayout }>();

  // Fallback to shopper experiences route if syte layout param is not valid
  useEffect(() => {
    const isValidSyteLayout = syteLayoutParam && syteLayoutsOptions.includes(syteLayoutParam);

    if (!isValidSyteLayout && permittedRouteMap.shopperExperiences && shopId) {
      dispatch(
        filtersContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.shopperExperiences.path, { shopId }),
        })
      );
      dispatch(
        filtersContainerActions.errorNotification({
          customMessage: 'Syte Layout is not valid. Please, check the url',
        })
      );
    }
  }, [syteLayoutParam]);

  const fallbackRoute = useMemo(() => {
    if (permittedRouteMap.filtersList && shopId) {
      return generatePath(permittedRouteMap.filtersList.path, {
        shopId,
        syteLayout: syteLayoutParam,
      });
    }
    return '';
  }, [shopId, permittedRouteMap.filtersList, syteLayoutParam]);

  useEffect(() => {
    if (shopId) {
      dispatch(
        filtersContainerActions.getDataFields({
          shopId,
          fieldType: FieldType.CatalogField,
        }) as any
      );
    }
  }, []);

  return (
    <AppSwitch>
      <AppRoute
        route={permittedRouteMap.filtersList}
        Component={FiltersListContainer}
        componentProps={{
          permittedRouteMap,
          syteLayout: syteLayoutParam,
          hasReachedMaxAllowedFiltersLimit,
        }}
        exact
      />
      <AppRoute
        route={permittedRouteMap.createNewFilter}
        Component={CreateFilterContainer}
        componentProps={{
          permittedRouteMap,
          syteLayout: syteLayoutParam,
          hasReachedMaxAllowedFiltersLimit,
          hasReachedMaxAllowedCustomOrderFiltersLimit,
        }}
        exact
      />
      <AppRoute
        route={permittedRouteMap.editFilter}
        Component={EditFilterContainer}
        componentProps={{
          permittedRouteMap,
          syteLayout: syteLayoutParam,
          hasReachedMaxAllowedCustomOrderFiltersLimit,
        }}
        exact
      />
      <Redirect to={fallbackRoute} />
    </AppSwitch>
  );
};
