import React from 'react';
import styled from '@emotion/styled';
import { Button, ButtonProps } from '../Button';
import { Icon } from '../Icon';
import { CardItem } from '../CardItem';
import { SearchInput, SearchInputProps } from '../SearchInput';

export const FiltersRowStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid ${({ theme }) => theme.palette.custom['gray-80']};

  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 8px;
  padding: 6px 12px;

  & > div {
    margin: 6px 5px 6px 0;
  }
`;

export const AddFilterButtonStyled = styled((props: ButtonProps) => <Button {...props} />)<{
  clicked?: boolean;
}>`
  color: ${({ theme }) => theme.palette.common.black};
  border: unset !important ;
  background-color: ${({ theme, clicked }) =>
    clicked ? theme.palette.custom['primary-90'] : theme.palette.common.white};

  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom['primary-90']};
  }
`;

export const FilterTagStyled = styled.div<{ isClickable?: boolean; clicked?: boolean }>`
  display: flex;
  flex-direction: row;
  height: 32px;
  max-width: 100%;
  align-items: center;
  background-color: ${({ theme, isClickable, clicked }) => {
    if (isClickable && clicked) {
      return theme.palette.custom['primary-90'];
    }
    return theme.palette.custom['gray-80'];
  }};
  transition: background-color 0.1s;
  border-radius: 4px;
  padding: 12px 8px;
  user-select: none;
  ${({ isClickable }) => {
    if (isClickable) {
      return `
        cursor: pointer;
      `;
    }
    return '';
  }}
  /* 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */

  &:hover {
    background-color: ${({ theme }) => {
      return theme.palette.custom['primary-90'];
    }};
  }

  span {
    white-space: nowrap;
    margin-right: 3px;
  }
`;

export const FilterTagFooterStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 12px;
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};

  button:first-of-type {
    margin-right: 5px;
  }
`;

export const FilterTagContentStyled = styled(CardItem)`
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  width: 260px;

  &:hover {
    border-color: ${({ theme }) => theme.palette.custom['gray-60']};
  }
`;

export const RemoveFilterTagIconStyled = styled(Icon)`
  width: 11px;
  height: 11px;
  margin-left: 4px;

  path {
    stroke-width: 1.5;
    stroke: ${({ theme }) => theme.palette.custom['gray-10']};
  }
`;

export const SearchInputStyled = styled((props: SearchInputProps) => <SearchInput {...props} />)`
  max-width: 176px;

  .input-base-wrapper {
    height: 32px;
  }

  .syte-text-box-content {
    align-self: center;
  }
`;
