import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { PopUp } from 'src/components-dummy';
import { ILocationUser } from 'src/services';
import { useAnimateNumber } from 'src/hooks/useAnimateNumber';
import { PopUpContentStyled, UserProfileThumbnailStyled } from '../UserAvatarList.styles';
import { UsersList } from './UsersList';
import { headerUserThumbnailSize, MAX_USERS_COUNT_TO_SHOW } from '../constants';
import { UserThumbnailElementWrapper } from './UserThumbnailElementWrapper';

export interface ICollapsedUserCountIndicationProps {
  hasMoreUsers: boolean;
  users: ILocationUser[];
  positionIndex: number;
  show: boolean;
}

export const CollapsedUserCountIndication = ({
  hasMoreUsers,
  users,
  positionIndex,
  show,
}: ICollapsedUserCountIndicationProps): JSX.Element | null => {
  const [shouldShowPopup, setShouldShowPopup] = useState(false);

  const { totalCountWithoutCurrent: totalUsersCountWithoutCurrentUser } = useMemo(() => {
    const totalCountWithoutCurrent = Math.max(users.length - MAX_USERS_COUNT_TO_SHOW, 1);

    return { totalCountWithoutCurrent };
  }, [hasMoreUsers, users]);

  const usersCountAnimated = useAnimateNumber({
    totalSteps: 20,
    value: totalUsersCountWithoutCurrentUser,
  });

  const onShowChange = useCallback((popupShow: boolean) => {
    setShouldShowPopup(popupShow);
  }, []);

  useEffect(() => {
    if (shouldShowPopup && !hasMoreUsers) {
      setShouldShowPopup(false);
    }
  }, [shouldShowPopup, hasMoreUsers]);

  return (
    <UserThumbnailElementWrapper index={positionIndex} show={show}>
      <PopUp
        triggerEvent='hover'
        closeOnTriggerClick={false}
        hoverable
        show={shouldShowPopup}
        onShow={onShowChange}
      >
        <PopUp.Trigger>
          <UserProfileThumbnailStyled
            displayName={`+${usersCountAnimated.toLocaleString()}`}
            isEditing={false}
            key='collapsed-users-count'
            thumbnailSize={headerUserThumbnailSize}
            lettersCountToShow={`+${usersCountAnimated.toLocaleString()}`.length}
            showTooltip={false}
          />
        </PopUp.Trigger>

        <PopUp.Content>
          <PopUpContentStyled>
            <UsersList users={users} />
          </PopUpContentStyled>
        </PopUp.Content>
      </PopUp>
    </UserThumbnailElementWrapper>
  );
};
