import styled from '@emotion/styled';
import { Button } from 'src/components-dummy';

export const ButtonStyled = styled(Button)`
  width: 200px;

  background-color: ${({ theme }) => theme.palette.custom['primary-light']};
  color: ${({ theme }) => theme.palette.custom['primary-main']};

  path {
    stroke: ${({ theme }) => theme.palette.custom['primary-main']};
    fill: ${({ theme }) => theme.palette.custom['primary-main']};
  }

  &.Mui-disabled {
    background-color: ${({ theme }) => theme.palette.common.white};
    color: ${({ theme }) => theme.palette.custom['gray-30']};

    border: 1px solid ${({ theme }) => theme.palette.custom['gray-30']};

    path {
      stroke: ${({ theme }) => theme.palette.custom['gray-30']};
      fill: ${({ theme }) => theme.palette.custom['gray-30']};
    }
  }
`;
