import styled from '@emotion/styled';

export const Account2FAFormStyled = styled.div`
  max-width: 752px;
`;

export const ToggleWrapperStyled = styled.div`
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  padding: 20px;
`;
