import React from 'react';
import classNames from 'classnames';
import { IndicatorColor } from './constants';
import './Indicator.scss';

export interface IndicatorProps {
  children: JSX.Element | string | number;
  color?: IndicatorColor;
  className?: string;
}

export const Indicator = ({ children, color, className }: IndicatorProps): JSX.Element => {
  return (
    <div className={classNames('syte-indicator', className)}>
      <div className={classNames('syte-indicator-dot', color)} />
      <div className='syte-indicator-content'>{children}</div>
    </div>
  );
};
