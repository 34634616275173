import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ActionIconSize, IconColorAttribute } from './ActionIcon.types';
import { Spinner, SpinnerProps } from '../Spinner';
import { Icon } from '../Icon';

const nonDomProps = ['loading', 'iconSize', 'isActive', 'iconColorAttributes'];

// By default emotion attaches given props to DOM. using this function so it won't.
const shouldForwardPropToDom = (prop: string) => !nonDomProps.includes(prop);

function getIconColorAttributeCss({
  attribute,
  color,
  disabledColor,
  isDisabled,
  isActive,
  activeColor,
}: {
  attribute: IconColorAttribute;
  color: string;
  disabledColor: string;
  activeColor: string;
  isActive: boolean;
  isDisabled: boolean;
}): string {
  let actualColor;

  if (isDisabled) {
    actualColor = disabledColor;
  } else if (isActive) {
    actualColor = activeColor;
  } else {
    actualColor = color;
  }
  return ` & > path  {
    ${attribute}: ${actualColor} !important;
  };`;
}

const sizeToStyleConfig: Record<
  ActionIconSize,
  { padding: number; innerIconSize: number; containerSize: number }
> = {
  tiny: {
    padding: 6,
    containerSize: 28,
    innerIconSize: 15,
  },
  small: { padding: 7, containerSize: 32, innerIconSize: 22 },
  medium: { padding: 11, containerSize: 40, innerIconSize: 25 },
};

const actionIconBaseStyle = ({
  iconSize,
  loading,
  disabled,
}: {
  iconSize: ActionIconSize;
  loading?: boolean;
  disabled?: boolean;
  isActive?: boolean;
  iconColorAttributes: Array<'fill' | 'stroke'>;
}) => css`
  height: ${sizeToStyleConfig[iconSize].containerSize}px;
  width: ${sizeToStyleConfig[iconSize].containerSize}px;
  min-height: ${sizeToStyleConfig[iconSize].containerSize}px;
  min-width: ${sizeToStyleConfig[iconSize].containerSize}px;
  pointer-events: ${loading || disabled ? 'none' : 'initial'};
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const ActionIconStyled = styled('div', { shouldForwardProp: shouldForwardPropToDom })`
  ${actionIconBaseStyle}

  &:hover {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.palette.custom['primary-90'] : theme.palette.custom['gray-70']};
  }

  background-color: ${({ theme, isActive, loading, disabled }) => {
    if (disabled) {
      return theme.palette.custom['gray-70'];
    }
    if (loading) {
      return theme.palette.custom['gray-80'];
    }

    if (isActive) {
      return theme.palette.custom['primary-90'];
    }
    return 'transparent';
  }};

  .syte-basic-icon {
    ${({ theme, disabled, iconColorAttributes, isActive }) => {
      return iconColorAttributes
        .map(attribute =>
          getIconColorAttributeCss({
            color: theme.palette.custom['gray-10'],
            disabledColor: theme.palette.custom['gray-50'],
            isDisabled: !!disabled,
            attribute,
            isActive: !!isActive,
            activeColor: theme.palette.common.black,
          })
        )
        .join('\n');
    }}
  }

  &:hover {
    cursor: ${({ loading, disabled }) => (loading || disabled ? 'initial' : 'pointer')};

    .syte-basic-icon {
      ${({ theme, disabled, iconColorAttributes, isActive }) => {
        return iconColorAttributes
          .map(attribute =>
            getIconColorAttributeCss({
              color: theme.palette.common.black,
              activeColor: theme.palette.common.black,
              disabledColor: theme.palette.custom['gray-50'],
              isDisabled: !!disabled,
              isActive: !!isActive,
              attribute,
            })
          )
          .join('\n');
      }}
    }
  }
`;

export const SpinnerStyled = styled((props: SpinnerProps) => <Spinner {...props} />, {
  shouldForwardProp: shouldForwardPropToDom,
})<{
  loading: boolean;
  disabled: boolean;
  iconSize: ActionIconSize;
}>`
  display: ${({ loading }) => (loading ? 'initial' : 'none')};

  width: ${({ iconSize }) => sizeToStyleConfig[iconSize].innerIconSize}px !important;
  height: ${({ iconSize }) => sizeToStyleConfig[iconSize].innerIconSize}px !important;

  .MuiCircularProgress-svg {
    color: ${({ theme, disabled }) =>
      disabled ? theme.palette.custom['gray-50'] : theme.palette.common.black};
  }
`;

export const IconStyled = styled(Icon, { shouldForwardProp: shouldForwardPropToDom })<{
  loading: boolean;
  disabled: boolean;
  iconSize: ActionIconSize;
}>`
  max-height: ${({ iconSize }) => sizeToStyleConfig[iconSize].innerIconSize}px;
  width: ${({ iconSize }) => sizeToStyleConfig[iconSize].innerIconSize}px;
  opacity: ${({ loading }) => (loading ? 0 : 1)};
  display: ${({ loading }) => (loading ? 'none' : 'initial')};
`;
