import React, { useEffect } from 'react';
import { generatePath, useParams } from 'react-router';
import { Dispatch, ExperimentForm } from 'src/components-bl';
import { useAppDispatch, useAppSelector, usePreviousState } from 'src/hooks';
import { RoutedComponentProps } from 'src/app-routes';
import { editExperimentContainerActionMethods } from '../Actions';

export interface ConnectedExperimentFormProps extends RoutedComponentProps {
  // eslint-disable-next-line react/require-default-props
  applyButtonElement?: Element | null;
}

export const ConnectedExperimentForm = ({
  applyButtonElement,
  permittedRouteMap,
}: ConnectedExperimentFormProps): JSX.Element | null => {
  const dispatch: Dispatch = useAppDispatch();
  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const { experimentId } = useParams<{ experimentId: string }>();
  const selectedExperiment = useAppSelector(state => state.experiments.selectedExperiment);
  const selectedVariantId = useAppSelector(state => state.experiments.selectedVariantId);
  const previousShopId = usePreviousState(shopId);

  const experimentListPath =
    permittedRouteMap.experiments?.path &&
    generatePath(permittedRouteMap.experiments?.path, { shopId });

  useEffect(() => {
    if (shopId && experimentId !== selectedExperiment?.id) {
      dispatch(
        editExperimentContainerActionMethods.getExperiment({
          shopId,
          id: experimentId,
          pathToNavigateOnFail: experimentListPath,
        })
      );
    }
  }, [experimentId, selectedExperiment?.id]);

  useEffect(() => {
    return () => {
      dispatch(editExperimentContainerActionMethods.clearCurrentExperiment());
    };
  }, []);

  useEffect(() => {
    if (
      shopId &&
      shopId !== previousShopId &&
      previousShopId !== undefined &&
      experimentListPath &&
      selectedExperiment
    ) {
      dispatch(editExperimentContainerActionMethods.navigateTo({ navigateTo: experimentListPath }));
    }
  }, [shopId, previousShopId]);

  return (
    <ExperimentForm
      shopId={shopId}
      experiment={selectedExperiment}
      dispatch={dispatch}
      applyButtonElement={applyButtonElement}
      selectedVariantId={selectedVariantId}
    />
  );
};
