import React from 'react';
import { EllipsisWithTooltip, TypographyVariant } from '../../../../../components-dummy';
import {
  ProductDescriptionItemWrapper,
  ProductDescriptionKey,
  ProductDescriptionValue,
} from './ProductTile.styles';

interface ProductDescriptionItemProps {
  text: string;
  value: string;
}

export const ProductDescriptionItem = ({
  text,
  value,
}: ProductDescriptionItemProps): JSX.Element => {
  return (
    <ProductDescriptionItemWrapper>
      <ProductDescriptionKey variant={TypographyVariant.SmallRegular}>
        <EllipsisWithTooltip tooltipText={text}>{text}</EllipsisWithTooltip>
      </ProductDescriptionKey>
      <ProductDescriptionValue variant={TypographyVariant.SmallRegular}>
        <EllipsisWithTooltip tooltipText={value}>{value}</EllipsisWithTooltip>
      </ProductDescriptionValue>
    </ProductDescriptionItemWrapper>
  );
};
