import React, { ReactNode, useCallback } from 'react';
import { generatePath } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch } from 'src/components-bl/types';
import { Breadcrumbs } from 'src/components-dummy';
import { galleryBreadCrumbsActions } from './GalleryBreadCrumbs.actions';

interface GalleryBreadCrumbsProps extends RoutedComponentProps {
  children: JSX.Element | JSX.Element[] | ReactNode | ReactNode[];
  dispatch: Dispatch;
  shopId: number;
  showGalleriesLink?: boolean;
}

export const GalleryBreadCrumbs = ({
  children,
  dispatch,
  permittedRouteMap,
  shopId,
  showGalleriesLink = false,
}: GalleryBreadCrumbsProps): JSX.Element => {
  const shopperExperiencesLink = permittedRouteMap.shopperExperiences
    ? generatePath(permittedRouteMap.shopperExperiences.path, { shopId })
    : '';

  const galleriesTableLink = permittedRouteMap.galleriesList
    ? generatePath(permittedRouteMap.galleriesList.path, { shopId })
    : '';

  const onLinkClick = useCallback(
    (link: string) => {
      dispatch(galleryBreadCrumbsActions.navigateTo({ navigateTo: link }));
    },
    [dispatch]
  );

  return (
    <Breadcrumbs>
      <Breadcrumbs.Link
        displayName='Shopper experience'
        href={shopperExperiencesLink}
        onNavigate={onLinkClick}
      />
      {showGalleriesLink ? (
        <Breadcrumbs.Link
          displayName='Shoppable Galleries'
          href={galleriesTableLink}
          onNavigate={onLinkClick}
        />
      ) : (
        <></>
      )}
      <Breadcrumbs.Current>{children}</Breadcrumbs.Current>
    </Breadcrumbs>
  );
};
