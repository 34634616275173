import React from 'react';
import {
  AvailableIcons,
  Icon,
  Tooltip,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { format } from 'date-fns';
import { ChangedByTooltipProps } from '../types';

export const ChangedByTooltip = ({ changedBy, changedAt }: ChangedByTooltipProps): JSX.Element => {
  const changedAtFormatted =
    changedAt instanceof Date ? format(changedAt, 'd MMM Y, h:mm aaa') : 'unknown'; // 1 Feb 2022, 7:06 am
  return (
    <Tooltip
      position='top left'
      className='syte-changed-by-tooltip'
      value={
        <Typography
          type={TypographyType.Body}
          variant={TypographyVariant.MediumRegular}
          className='syte-changed-by-tooltip-text'
        >
          Last modified by{' '}
          <span className='syte-changed-by-tooltip-highlighted-text'>
            {changedBy?.email || 'unknown'}
          </span>{' '}
          at <span className='syte-changed-by-tooltip-highlighted-text'>{changedAtFormatted}</span>
        </Typography>
      }
    >
      <Icon name={AvailableIcons.User} />
    </Tooltip>
  );
};
