import Joi from 'joi';
import { v4 as uuidV4 } from 'uuid';
import { getStringEnumValues } from 'src/utils';
import { ThematicTagConditionOperatorsEnum } from 'src/services';
import {
  IThematicTagConditionsGroupDraft,
  IThematicTagDraft,
  IThematicTagConditionsDraft,
} from './ThematicTagForm.types';

const ConditionSchema = Joi.object({
  operator: Joi.string()
    .valid(...getStringEnumValues(ThematicTagConditionOperatorsEnum))
    .required()
    .messages({
      'string.empty': 'Operator can not be empty',
    }),
  categoryKey: Joi.string().trim().min(1).required().messages({
    'string.empty': 'Category can not be empty',
  }),
  attributeKey: Joi.string().trim().min(1).required().messages({
    'string.empty': 'Attribute can not be empty',
  }),
  valuesKeys: Joi.when('operator', {
    is: Joi.valid(
      ThematicTagConditionOperatorsEnum.HasNoValue,
      ThematicTagConditionOperatorsEnum.HasAnyValue
    ),
    then: Joi.array().items().length(0),
    otherwise: Joi.array().items(Joi.string().trim().min(1)).min(1).required().messages({
      'array.min': 'Value can not be empty',
    }),
  }),
});

const ConditionGroupSchema = Joi.object({
  conditions: Joi.array().items(ConditionSchema).min(1).required(),
});

export const thematicTagValidationSchema = {
  category: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'string.empty': 'Category can not be empty' }),
  attribute: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'string.empty': 'Attribute can not be empty' }),
  value: Joi.string()
    .trim()
    .min(1)
    .required()
    .messages({ 'string.empty': 'Value can not be empty' }),
  conditionsGroups: Joi.array().items(ConditionGroupSchema).min(1).required().messages({
    'array.min': 'Please, add at least one group of rules',
  }),
};

export function generateEmptyCondition(): IThematicTagConditionsDraft {
  return {
    id: uuidV4().toString(),
    categoryKey: '',
    attributeKey: '',
    valuesKeys: [],
    operator: ThematicTagConditionOperatorsEnum.ValueIs,
  };
}

export function generateEmptyConditionGroup(): IThematicTagConditionsGroupDraft {
  return {
    id: uuidV4().toString(),
    conditions: [generateEmptyCondition()],
  };
}

export const initialThematicTagDraft: IThematicTagDraft = {
  category: '',
  attribute: '',
  value: '',
  conditionsGroups: [generateEmptyConditionGroup()],
};
