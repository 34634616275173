import React, { useMemo } from 'react';
import { formatDistanceToNow, format } from 'date-fns';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import { CircleDividerStyled, DateSectionStyled, TypographyStyled } from './DateSection.styles';

const dateFormat = 'dd MMM yyyy';

interface DateSectionProps {
  className?: string;
  createdAt: Date;
}

export function DateSection({ className, createdAt }: DateSectionProps): JSX.Element {
  const timeAgo = useMemo(() => formatDistanceToNow(createdAt, { addSuffix: true }), [createdAt]);
  const formattedDate = useMemo(() => format(createdAt, dateFormat), [createdAt]);

  return (
    <DateSectionStyled className={className}>
      <TypographyStyled type={TypographyType.Paragraph} variant={TypographyVariant.SmallRegular}>
        {timeAgo}
      </TypographyStyled>
      <CircleDividerStyled />
      <TypographyStyled type={TypographyType.Paragraph} variant={TypographyVariant.SmallRegular}>
        {formattedDate}
      </TypographyStyled>
    </DateSectionStyled>
  );
}
