import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import {
  AvailableIcons,
  Collapse,
  Link,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { useAppSelector } from 'src/hooks';
import { ConnectedLexiconList } from './components/ConnectedLexiconList';
import { lexiconsContainerActions } from './LexiconsContainer.actions';
import {
  IconStyled,
  learnMoreCollapseStyle,
  LearnMoreContentStyled,
  LearnMoreHeaderStyled,
  LearnMoreParagraphStyled,
  LearnMoreSubTitleStyled,
  LexiconListSectionStyled,
  LexiconListTitleStyled,
  LexiconsPageDescriptionStyled,
  LexiconsPageLayoutStyled,
  LexiconsTitleStyled,
} from './LexiconsContainer.styles';

export type LexiconsContainerProps = RoutedComponentProps;

export const LexiconsContainer = ({ permittedRouteMap }: LexiconsContainerProps): JSX.Element => {
  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const dispatch = useDispatch();

  const [isExpanded, setIsExpanded] = useState(false);

  const onLearnMoreClick = () => setIsExpanded(!isExpanded);

  const shopSettingsAiTaggingRoute = permittedRouteMap.aiTagging?.path;

  const lexiconSettingsUrl = useMemo(() => {
    return shopSettingsAiTaggingRoute && shopId
      ? generatePath(shopSettingsAiTaggingRoute, { shopId })
      : undefined;
  }, [shopId, shopSettingsAiTaggingRoute]);

  const onLexiconSettingsClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    event => {
      event.preventDefault();

      if (lexiconSettingsUrl) {
        dispatch(lexiconsContainerActions.navigateTo({ navigateTo: lexiconSettingsUrl }));
      }
    },
    [lexiconSettingsUrl]
  );

  return (
    <LexiconsPageLayoutStyled>
      <LexiconsTitleStyled>Lexicon</LexiconsTitleStyled>
      <LexiconsPageDescriptionStyled>
        Organize categories, attributes, and values. <br />
        Each language requires its own management.
      </LexiconsPageDescriptionStyled>
      {shopSettingsAiTaggingRoute && (
        <Typography variant={TypographyVariant.LargeBold} type={TypographyType.Body}>
          {`To set lexicon locales, click `}
          <Link href={shopSettingsAiTaggingRoute} onClick={onLexiconSettingsClick}>
            here
          </Link>
        </Typography>
      )}
      <Collapse css={learnMoreCollapseStyle}>
        <Collapse.Header>
          <LearnMoreHeaderStyled onClick={onLearnMoreClick}>
            <IconStyled isRotated={isExpanded} name={AvailableIcons.Triangle} />
            Learn more about lexicon
          </LearnMoreHeaderStyled>
        </Collapse.Header>
        <Collapse.Content>
          <LearnMoreContentStyled>
            <LearnMoreSubTitleStyled>What is lexicon?</LearnMoreSubTitleStyled>
            <LearnMoreParagraphStyled>
              Lexicon is a library of words, that our AI connect to your field.
            </LearnMoreParagraphStyled>
            <LearnMoreSubTitleStyled>Why use the lexicon?</LearnMoreSubTitleStyled>
            <LearnMoreParagraphStyled>
              Clients how use our lexicon and modify it increase the ARPU.
            </LearnMoreParagraphStyled>
          </LearnMoreContentStyled>
        </Collapse.Content>
      </Collapse>
      <LexiconListSectionStyled>
        <LexiconListTitleStyled>I want to organize the lexicon...</LexiconListTitleStyled>
        <ConnectedLexiconList categoriesRoute={permittedRouteMap.lexiconCategories?.path} />
      </LexiconListSectionStyled>
    </LexiconsPageLayoutStyled>
  );
};
