import styled from '@emotion/styled';
import { Typography } from '../Typography';

export const LoadingTextStyled = styled(Typography)`
  font-style: italic;
`;

export const CircularWrapperStyled = styled.div<{ height?: number }>`
  height: ${({ height }) => `${height || 200}px`};
  display: flex;
  justify-content: center;
  align-content: center;

  .MuiBox-root {
    justify-content: center;
  }
`;
