import React, { ReactNode } from 'react';

export interface TabProps {
  uniqueId: string;
  href?: string;
  className?: string;
  children?: ReactNode | ReactNode[];
}

export const Tab = ({ uniqueId, href, className, children }: TabProps): JSX.Element => (
  <a href={href} key={uniqueId} className={className}>
    {children}
  </a>
);
