import styled from '@emotion/styled';
import { Button, Icon, Page } from 'src/components-dummy';

export const GalleryFormStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ButtonStyled = styled(Button)`
  padding-left: 32px;
  padding-right: 32px;
`;

export const GalleryFormPageStyled = styled(Page)`
  background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  height: 100%;
  min-height: 100%;
  overflow: auto;
  width: 100%;

  padding: 0 40px 100px;

  .syte-page-header {
    border-bottom: unset;
    padding: 10px 0 32px 0;
  }
`;

export const LearnMoreLink = styled(Page.SubTitle)`
  margin-left: auto;
  width: unset !important;
  margin-top: unset !important;
  color: ${({ theme }) => theme.palette.custom['primary-main']} !important;

  a {
    display: flex;
  }
`;

export const LinkIconStyled = styled(Icon)`
  margin-left: 4px;
`;
