import { ChangeLogActions, ListReducerState } from './types';
import { FiltersListAction, FiltersListActionTypes } from './filters-list-actions';
import { discardItemChanges, addNewItem, saveItem } from './methods';

export const filtersListReducer = (
  state: ListReducerState,
  action: FiltersListAction
): ListReducerState => {
  switch (action.type) {
    case FiltersListActionTypes.Init:
      return { items: [...action.payload] };
    case FiltersListActionTypes.AddNewItem:
      return {
        ...state,
        items: addNewItem(state.items, action.payload),
        lastRegisteredAction: ChangeLogActions.AddItem,
      };
    case FiltersListActionTypes.SaveItem:
      return {
        ...state,
        items: saveItem(state.items, action.payload),
        lastRegisteredAction: ChangeLogActions.UpdateItem,
      };
    case FiltersListActionTypes.DismissItemForm:
      return { ...state, items: discardItemChanges(state.items, action.payload) };
    case FiltersListActionTypes.DeleteItem:
      return {
        ...state,
        items: state.items.filter(item => item.key !== action.payload),
        lastRegisteredAction: ChangeLogActions.DeleteItem,
      };
    case FiltersListActionTypes.UpdateList:
      return { ...state, items: action.payload, lastRegisteredAction: ChangeLogActions.UpdateList };
    default:
      return state;
  }
};
