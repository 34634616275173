import React from 'react';
import './NavTabs.scss';

export interface NavTabProps {
  name: string;
  href?: string;
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode | React.ReactNode[];
}
export const NavTab = (_props: NavTabProps): JSX.Element => {
  return <></>;
};
