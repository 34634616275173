import { MultiSelectOption } from '../MultiSelectMenu';

export enum FilterVariant {
  SearchInput = 'search-input',
  Boolean = 'boolean',
  MultiSelect = 'multi-select',
  Menu = 'menu',
  DateRange = 'dateRange',
}

export interface FilterConfig<T extends FilterVariant> {
  type: T;
  uniqueFilterKey: string;
  menuName?: string;
  tagPrefix?: string;
}

export interface FilterState<Value = unknown> {
  value?: Value;
  isApplied: boolean;
}

export type FiltersState = Record<string, FilterState | undefined>;

export type AppliedFilters<Value = unknown> = Record<string, Value>;

export interface FiltersRowProps {
  children: JSX.Element | JSX.Element[];
  onChange: (filters: FiltersState) => void;
  filters?: FiltersState;
  getNewFilter?: (key: string) => FilterState;
}

export interface BaseFilterTagProps extends Pick<FilterConfig<any>, 'menuName' | 'tagPrefix'> {
  filterKey: string;
  filterState?: FilterState;
  onChange: (partialState: FiltersState) => void;
  onRemoveFilter?: (filterToRemove: string) => void;
  onCloseFilter?: () => void;
  shouldTriggerOpen?: boolean;
}

/**
 * MultiSelectFilter
 */
export interface MultiSelectFilterTagConfig {
  enableSearch: boolean;
  enableToggleAll: boolean;
  searchPlaceholder?: string;
  labelText?: string;
  headerTitle?: string;
  applyText?: string;
  shouldAlwaysShowSelectionItemsText?: boolean;
  selectedParentOptions?: string[];
  options: MultiSelectOption[];
}

export interface MultiSelectFilterTagProps
  extends Omit<BaseFilterTagProps, 'filterState'>,
    MultiSelectFilterTagConfig {
  filterState?: FilterState<string[]>;
}

export interface MenuFilterTagConfig {
  placeholder?: string;
  options: { text: string; value: string | number }[];
}

export interface MenuFilterTagProps extends BaseFilterTagProps, MenuFilterTagConfig {}

/**
 * DateRangeFilter
 */
export interface DateRangeFilterTagConfigValues {
  startDate: Date | null;
  endDate: Date | null;
}
export interface DateRangeFilterTagConfig {
  placeholder?: string;
  values: DateRangeFilterTagConfigValues;
}

export interface DateRangeFilterTagProps
  extends Omit<BaseFilterTagProps, 'filterState'>,
    DateRangeFilterTagConfig {
  filterState?: FilterState<DateRangeFilterTagConfigValues>;
}

/**
 * BooleanFilter
 */
export interface BooleanFilterTagConfig {
  isEditable: boolean;
  enabledTagText?: string;
  disabledTagText?: string;
  options?: Array<{ text: string; value: boolean }>;
  shouldAlwaysShowSelectionItemsText?: boolean;
}

export type BooleanFilterTagProps = BaseFilterTagProps & BooleanFilterTagConfig;

export interface SearchInputFilterTagConfig {
  placeholder?: string;
}

export interface FilterTagFactoryProps extends BaseFilterTagProps {
  type: FilterVariant;
  componentConfig: SearchInputFilterTagConfig | BooleanFilterTagConfig | MultiSelectFilterTagConfig;
}

export interface FilterTagProps<V extends FilterVariant> extends FilterConfig<V> {
  componentConfig: V extends FilterVariant.SearchInput
    ? SearchInputFilterTagConfig
    : V extends FilterVariant.Boolean
      ? BooleanFilterTagConfig
      : V extends FilterVariant.Menu
        ? DateRangeFilterTagConfig
        : V extends FilterVariant.DateRange
          ? MenuFilterTagConfig
          : MultiSelectFilterTagConfig;
}

export type FilterOption = { key: string; displayName: string };

export type FilterConfigsMap = Record<string, FilterTagProps<any> & { index: number }>;
