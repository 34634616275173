import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useAppSelector } from '../../../hooks';
import { visualEditorActions } from '../state';
import {
  merchandisingRulesFormActions,
  UpdateMerchRuleArgs,
} from '../../MerchandisingRules/components/MerchandisingRuleForm';
import { useNavigateToVisualEditorRoute } from './useNavigateToVisualEditorRoute';
import { PreviewMerchRule } from '../types';
import { ShopFeature } from '../../../app-types';
import { VisualEditorMapper } from '../helpers';
import { PermittedRouteMap } from '../../../app-routes';

export const useEditMerchRule = (shopId: number, permittedRouteMap: PermittedRouteMap) => {
  const {
    collection,
    selectedExperience,
    merchandisingRules: { selectedRule },
  } = useAppSelector(({ visualEditor }) => visualEditor);
  const dispatch = useDispatch();

  const merchRuleFormKey = useMemo(() => uuid(), [selectedRule]);

  const { navigateToVisualEditorRoute } = useNavigateToVisualEditorRoute({
    route: permittedRouteMap?.visualEditor,
  });

  const closeMerchandisingRulesDrawer = useCallback(() => {
    dispatch(visualEditorActions.notifyIsDirty({ isDirty: false }));
    navigateToVisualEditorRoute({ shopId });
  }, [dispatch, navigateToVisualEditorRoute, shopId]);

  const [isMerchRuleFormSubmitting, setIsMerchRuleFormSubmitting] = useState(false);

  const submitEditMerchRuleForm = useCallback(
    (payload: UpdateMerchRuleArgs) => {
      setIsMerchRuleFormSubmitting(true);
      (dispatch(merchandisingRulesFormActions.updateAndGetMerchRule(payload)) as any)
        .unwrap()
        .then(({ rule }: { rule: PreviewMerchRule }) => {
          dispatch(visualEditorActions.findAndUpdateRule(rule));
          closeMerchandisingRulesDrawer();
          setIsMerchRuleFormSubmitting(false);
        });
    },
    [closeMerchandisingRulesDrawer, dispatch]
  );

  const submitCreateMerchRuleForm = useCallback(
    (payload: UpdateMerchRuleArgs) => {
      setIsMerchRuleFormSubmitting(true);
      (dispatch(merchandisingRulesFormActions.createMerchandisingRule(payload)) as any)
        .unwrap()
        .then(({ rule }: { rule: PreviewMerchRule }) => {
          dispatch(visualEditorActions.addNewRule(rule));
          closeMerchandisingRulesDrawer();
          setIsMerchRuleFormSubmitting(false);
        });
    },
    [closeMerchandisingRulesDrawer, dispatch]
  );

  const entityId =
    selectedExperience === ShopFeature.Collections && collection ? collection.id : undefined;

  const merchRuleProduct = VisualEditorMapper.convertFeatureToMerchProduct(selectedExperience);

  return {
    closeMerchandisingRulesDrawer,
    entityId,
    merchRuleProduct,
    submitEditMerchRuleForm,
    submitCreateMerchRuleForm,
    merchRuleFormKey,
    isMerchRuleFormSubmitting,
    selectedRule,
  };
};
