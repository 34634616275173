import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import {
  PublishStatusIndicationActionTypes,
  PublishStatusIndicationAction,
  versionsTableActions,
} from 'src/components-bl';
import { useShopVersionsWSActions } from 'src/containers';
import { reviewVersionActions } from 'src/components-bl/Versioning/ReviewVersion';
import { VersioningReducerState } from '../app-state-types';

const defaultState: VersioningReducerState = {
  draft: undefined,
  versions: undefined,
  selectedVersion: undefined,
  showReviewVersionModal: false,
};

export const versioningReducer = createReducer(defaultState, builder => {
  builder
    .addCase(useShopVersionsWSActions.getDraft.fulfilled, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(versionsTableActions.getShopVersions.fulfilled, (state, action) => {
      return { ...state, versions: action.payload };
    })
    .addCase(versionsTableActions.restoreFromVersion.fulfilled, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(versionsTableActions.resetVersions, (state, _) => {
      return { ...state, versions: defaultState.versions };
    })
    .addCase(reviewVersionActions.getVersion.pending, state => {
      return { ...state, showReviewVersionModal: true };
    })

    .addCase(reviewVersionActions.getVersion.fulfilled, (state, action) => {
      return { ...state, selectedVersion: action.payload };
    })
    .addMatcher(
      isAnyOf(reviewVersionActions.getVersion.rejected, reviewVersionActions.resetSelectedVersion),
      state => {
        return { ...state, selectedVersion: undefined, showReviewVersionModal: false };
      }
    )
    .addDefaultCase((state, toolkitAction) => {
      const action = toolkitAction as PublishStatusIndicationAction;
      switch (action.type) {
        case PublishStatusIndicationActionTypes.GetDraftSuccess: {
          return {
            ...state,
            draft: action.payload.draft,
          };
        }

        case PublishStatusIndicationActionTypes.PublishDraftSuccess: {
          const oldVersions =
            state.versions?.map(v => {
              const { isProduction, ...rest } = v;
              return rest;
            }) || [];

          const updatedVersions = [
            ...oldVersions,
            { ...action.payload.publishedVersion, isProduction: true },
          ];

          return {
            ...state,
            versions: updatedVersions,
          };
        }

        default:
          return state;
      }
    });
});
