import React, { useCallback, useRef } from 'react';
import { startCase } from 'lodash';
import { ParseJoiValidateResponse } from 'src/utils';
import { ErrorLabel, TypographyType, TypographyVariant } from 'src/components-dummy';
import { LexiconTagField } from 'src/services';
import { Dispatch } from 'src/components-bl';
import {
  LexiconItemEditAutoComplete,
  LexiconItemEditAutoCompleteProps,
} from 'src/components-bl/Lexicon/components/LexiconTable/Components/LexiconItemAutoComplete';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';
import {
  ThematicTagRowStyled,
  TagFieldAutocompleteLabel,
  TagFieldAutocompleteContainer,
} from './ThematicTagRow.styles';
import { IThematicTagDraft } from '../../ThematicTagForm.types';

interface TagFieldAutoCompleteProps
  extends Pick<LexiconItemEditAutoCompleteProps, 'parentFieldsFilters'> {
  tagField: LexiconTagField;
  errors: ParseJoiValidateResponse<IThematicTagDraft>;
  value: string;
  locale: string;
  shopId: number;
  dispatch: Dispatch;
  onChange: (tagFieldValue: LexiconTagField, newValue: string) => void;
}

const TagFieldAutoComplete = ({
  tagField,
  errors,
  value,
  locale,
  shopId,
  dispatch,
  onChange,
  parentFieldsFilters,
}: TagFieldAutoCompleteProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

  const fieldErrorMessage = errors[tagField]?.message;

  const onTagFieldValueChange = useCallback(
    (newValue: string) => onChange(tagField, newValue),
    [onChange, tagField]
  );

  return (
    <TagFieldAutocompleteContainer>
      <TagFieldAutocompleteLabel type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
        Select {tagField.toLowerCase()}
      </TagFieldAutocompleteLabel>
      <LexiconItemEditAutoComplete
        key={tagField}
        placeholder={`${startCase(tagField)}...`}
        onChange={onTagFieldValueChange}
        selectedValue={value}
        dispatch={dispatch}
        shopId={shopId}
        tagField={tagField}
        tagType={LexiconTagType.LexiconTags}
        locale={locale}
        inputRef={inputRef}
        error={!!errors[tagField]}
        fetchMode='translations'
        parentFieldsFilters={parentFieldsFilters}
        paperProps={{
          sx: {
            width: 'fit-content',
            minWidth: 200,
            maxWidth: 400,
          },
        }}
        minimumInputLength={2}
      />
      {fieldErrorMessage && <ErrorLabel value={fieldErrorMessage} />}
    </TagFieldAutocompleteContainer>
  );
};

export interface ThematicTagRowProps
  extends Pick<TagFieldAutoCompleteProps, 'dispatch' | 'locale' | 'shopId' | 'errors'> {
  category: string;
  attribute: string;
  value: string;
  onChange: (partialState: Partial<IThematicTagDraft>) => void;
}

export const ThematicTagRow = ({
  errors,
  onChange,
  dispatch,
  shopId,
  locale,
  ...selectedValues
}: ThematicTagRowProps): JSX.Element => {
  const onTagFieldChange = useCallback(
    (tagField: LexiconTagField, newValue: string) => {
      onChange({ [tagField]: newValue });
    },
    [onChange]
  );

  return (
    <ThematicTagRowStyled>
      <TagFieldAutoComplete
        shopId={shopId}
        locale={locale}
        dispatch={dispatch}
        errors={errors}
        onChange={onTagFieldChange}
        tagField='category'
        value={selectedValues.category}
      />
      <TagFieldAutoComplete
        shopId={shopId}
        locale={locale}
        dispatch={dispatch}
        errors={errors}
        onChange={onTagFieldChange}
        tagField='attribute'
        parentFieldsFilters={{
          categories: [selectedValues.category],
        }}
        value={selectedValues.attribute}
      />
      <TagFieldAutoComplete
        shopId={shopId}
        locale={locale}
        dispatch={dispatch}
        errors={errors}
        onChange={onTagFieldChange}
        tagField='value'
        value={selectedValues.value}
        parentFieldsFilters={{
          attributes: [selectedValues.attribute],
        }}
      />
    </ThematicTagRowStyled>
  );
};
