import styled from '@emotion/styled';

export const RedOnlyDisabled = styled.div`
  color: #c4c4c4;
  padding: 0 3px 0 15px;
  font-size: 14px;
  height: 40px;
  align-items: center;
  display: flex;
`;
