import { useState, useEffect } from 'react';
import { EditableFilterItem } from '../types';

type ItemKey = string;
export type ExpendedItemsMap = Record<ItemKey, boolean>;
interface UseExpendableListReturnedType {
  expendedItems: ExpendedItemsMap;
  openListItem: (itemKey: ItemKey) => void;
  closeListItem: (itemKey: ItemKey) => void;
  toggleIsItemExpended: (itemKey: ItemKey, isOpen: boolean) => void;
  closeAll: () => void;
}
export function useExpendableList(
  items: EditableFilterItem[],
  expended?: ItemKey[],
  openNewItems: boolean | undefined = true
): UseExpendableListReturnedType {
  const map: ExpendedItemsMap = items.reduce((acc, item) => {
    return { ...acc, [item.key]: Boolean(expended?.includes(item.key)) };
  }, {});
  const [expendedItems, setExpendedItems] = useState<ExpendedItemsMap>(map);
  useEffect(() => {
    if (items.length > Object.keys(expendedItems).length) {
      const updatedMap = { ...expendedItems };
      items.forEach(item => {
        if (expendedItems[item.key] === undefined) {
          updatedMap[item.key] = openNewItems;
        }
      });
    }
  }, [items]);
  const openListItem = (itemKey: ItemKey): void => {
    setExpendedItems(prevState => ({ ...prevState, [itemKey]: true }));
  };
  const closeListItem = (itemKey: ItemKey): void => {
    setExpendedItems(prevState => ({ ...prevState, [itemKey]: false }));
  };
  const toggleIsItemExpended = (itemKey: ItemKey, isOpen: boolean): void => {
    setExpendedItems(prevState => ({ ...prevState, [itemKey]: isOpen }));
  };
  const closeAll = () => {
    const allClosed: ExpendedItemsMap = {};
    items.forEach(item => {
      allClosed[item.key] = false;
    });
    setExpendedItems(allClosed);
  };
  return {
    expendedItems,
    openListItem,
    closeListItem,
    closeAll,
    toggleIsItemExpended,
  };
}
