import { createAction } from '@reduxjs/toolkit';
import { lexiconSharedActionCreators } from '../../shared-actions/lexicon-shared-actions';

export const lexiconsContainerActions = {
  navigateTo: createAction<{ navigateTo: string }>('LexiconList/NavigateTo'),

  getLexiconsMetadataList: lexiconSharedActionCreators.getLexiconMetadata(
    'LexiconsContainerActions/GetLexiconsMetadataList'
  ),
};
