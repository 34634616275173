import { SerializedStyles } from '@emotion/react';

export enum TypographyVariant {
  ExtraLargeRegular = 'extra-large-regular',
  ExtraLargeMedium = 'extra-large-medium',
  ExtraLargeBold = 'extra-large-bold',

  LargeRegular = 'large-regular',
  LargeMedium = 'large-medium',
  LargeBold = 'large-bold',

  MediumRegular = 'medium-regular',
  MediumMedium = 'medium-medium',
  MediumBold = 'medium-bold',

  SmallRegular = 'small-regular',
  SmallMedium = 'small-medium',
  SmallBold = 'small-bold',

  ExtraSmallRegular = 'extra-small-regular',
  ExtraSmallMedium = 'extra-small-medium',
  ExtraSmallBold = 'extra-small-bold',
}

export enum TypographyType {
  Heading = 'heading',
  Body = 'body',
  Paragraph = 'paragraph',
  Button = 'button',
}

export interface TypographyProps {
  type?: TypographyType;
  variant?: TypographyVariant;
  children?: JSX.Element | React.ReactNode;
  className?: string | undefined;
}

export type StyleByVariantMap = Partial<Record<TypographyVariant, SerializedStyles>>;
export type VariantStyleByTypeMap = Record<TypographyType, StyleByVariantMap>;

export interface StyledElementProps {
  type?: TypographyType;
  variant?: TypographyVariant;
  className?: string | undefined;
}

export interface GetTypographyConfigOrDefaultArguments {
  type: TypographyType;
  variant?: TypographyVariant;
  className?: string | undefined;
}
