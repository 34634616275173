import { createReducer } from '@reduxjs/toolkit';
import { AIModelsReducerState } from 'src/app-state-types/reducer-state-types/ai-models-state.types';
import { shopAiModelSettingsActions } from 'src/containers/ShopSettings/Actions';

const initialState: AIModelsReducerState = {
  aiModel: undefined,
  aiModelList: undefined,
};

export const aiModelsReducer = createReducer(initialState, builder => {
  builder
    .addCase(shopAiModelSettingsActions.updateAiModel.pending, (state, action) => {
      return { ...state, aiModel: action.payload };
    })
    .addCase(shopAiModelSettingsActions.updateAiModel.fulfilled, (state, action) => {
      return { ...state, aiModel: action.payload };
    })

    .addCase(shopAiModelSettingsActions.getAIModelListWithShopModel.fulfilled, (state, action) => {
      return { ...state, aiModelList: action.payload.aiModelList, aiModel: action.payload.aiModel };
    });
});
