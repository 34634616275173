import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Dispatch } from 'src/components-bl';
import { Skeleton } from 'src/components-dummy/Skeleton';
import { useValidateSchema } from 'src/hooks';
import {
  ICatalogPlanAPI,
  ICatalogPlanCreate,
  IShopSftpSettings,
  ShopCatalogVertical,
  genderTypes,
} from 'src/services';
import { css } from '@emotion/react';
import { BackDropWithLoader } from 'src/components-dummy/Backdrop/BackdropWithLoader';
import _pick from 'lodash/pick';
import { CatalogFormGeneralSection } from './components/CatalogFormGeneralSection/CatalogFormGeneralSection';
import { catalogFormActions } from './CatalogForm.actions';
import {
  CatalogFormDraft,
  DataSourceTypesEnum,
  ImportMethodTypesEnum,
  SchedulingTypesEnum,
} from './CatalogForm.config';
import {
  catalogFormMappers,
  buildClearedCustomSFTPSettings,
  mapCustomWGETSettings,
  mapDefaultSFTPSettings,
  mapFormDraftToAPI,
  buildDefaultScheduling,
  buildInitialGendersList,
} from './CatalogForm.mappers';
import { catalogFormValidationSchema } from './catalogFormValidationSchema';
import { CatalogFormVerticalsSection } from './components/CatalogFormVerticalsSection/CatalogFormVerticalsSection';
import { CatalogFormGendersAndLocaleSection } from './components/CatalogFormGendersAndLocaleSection/CatalogFormGendersAndLocaleSection';
import { CatalogFormDataSourceSection } from './components/CatalogFormDataSourceSection/CatalogFormDataSourceSection';
import { CatalogFormImportMethodSection } from './components/CatalogFormImportMethodSection/CatalogFormImportMethodSection';
import { CatalogFormSchedulingSection } from './components/CatalogFormSchedulingSection/CatalogFormSchedulingSection';

export interface CatalogApiRef {
  submit(): Promise<void>;
}

export interface CatalogFormProps {
  shopId: number;
  shopName?: string;
  mode: 'edit' | 'create';
  catalog?: ICatalogPlanAPI;
  shopSFTPSettingsCredentials?: IShopSftpSettings;
  onNotifyCopyOnClipboard: () => void;
  onFormStatusChange: (status: { canSubmit: boolean; isDirty: boolean; isValid: boolean }) => void;
  dispatch: Dispatch;
  existedCatalogNames?: Record<string, boolean>;
}

export const catalogFormSkeleton = <Skeleton variant='rectangular' height={500} />;

export const CatalogForm = forwardRef<CatalogApiRef, CatalogFormProps>(
  (
    {
      shopId,
      shopName,
      shopSFTPSettingsCredentials,
      catalog,
      mode,
      onFormStatusChange,
      onNotifyCopyOnClipboard,
      dispatch,
      existedCatalogNames,
    }: CatalogFormProps,
    ref
  ): JSX.Element => {
    const [isInProcess, setIsInProcess] = useState<boolean>(false);

    const mappedCatalogInitial = useMemo(() => {
      return catalogFormMappers.mapApiCatalogToCatalogDraft({
        catalog,
        shopSFTPSettingsCredentials,
      });
    }, [catalog, shopSFTPSettingsCredentials]);

    const [catalogDraft, setCatalogDraft] = useState(mappedCatalogInitial);
    const [isDirty, setIsDirty] = useState(false);

    const { errors, validate, isValid } = useValidateSchema<
      Omit<CatalogFormDraft, 'entityId' | 'createdAt' | 'updatedAt'>
    >({
      schema: catalogFormValidationSchema({ mode, formState: catalogDraft, existedCatalogNames }),
      validateOnStart: false,
      initialData: mappedCatalogInitial,
    });

    const canSubmit = isDirty && isValid;

    const validateCatalogForm = useCallback(
      (catalogToValidate: CatalogFormDraft) => {
        const skipValidation = mode === 'create' && !isDirty;

        if (skipValidation) return;

        validate({
          dataToValidate: catalogToValidate,
        });
      },
      [mode, isDirty, catalogDraft.sftpServerSettings?.authentication]
    );

    // Reset to the initial mapped catalog
    useEffect(() => {
      setCatalogDraft(mappedCatalogInitial);
      validateCatalogForm(mappedCatalogInitial);
    }, [shopId, mappedCatalogInitial, setCatalogDraft]);

    useEffect(() => {
      onFormStatusChange({ canSubmit, isDirty, isValid });
    }, [canSubmit]);

    const updateIsDirty = (dirty: boolean) => {
      setIsDirty(dirty);
      dispatch(catalogFormActions.notifyIsDirty({ isDirty: dirty }));
    };

    const onFieldChange = useCallback(
      (partialData: Partial<CatalogFormDraft>) => {
        let newDraftState = { ...catalogDraft, ...partialData };

        // Clean - "selectedImportMethod" when manual upload is selected
        if (
          partialData.selectedDataSource &&
          partialData.selectedDataSource === DataSourceTypesEnum.manual_add_products
        ) {
          newDraftState = {
            ...newDraftState,
            selectedImportMethod: undefined,
            selectedScheduleType: undefined,
            scheduling: undefined,
          };
        }

        // Setting "hd" for genders incase vertical fashion is not selected.
        if (partialData.verticals) {
          if (!partialData.verticals.includes(ShopCatalogVertical.Fashion)) {
            newDraftState = {
              ...newDraftState,
              genders: [genderTypes.hd],
            };
          } else {
            newDraftState = {
              ...newDraftState,
              genders: buildInitialGendersList(),
            };
          }
        }

        // Set scheduling daily scheduling as "default" when "upload_sftp_server" is selected OR reset
        if (partialData?.selectedDataSource) {
          const shouldSetDefaultSchedulingValue =
            partialData?.selectedDataSource === DataSourceTypesEnum.use_product_feed &&
            !catalogDraft.selectedScheduleType;

          newDraftState = {
            ...newDraftState,
            selectedScheduleType: shouldSetDefaultSchedulingValue
              ? SchedulingTypesEnum.daily_time
              : undefined,
            scheduling: buildDefaultScheduling(),
          };
        }

        if (
          partialData?.selectedImportMethod === ImportMethodTypesEnum.upload_sftp_server ||
          'isUsingCustomSftp' in partialData
        ) {
          // Custom SFTP
          if (newDraftState.isUsingCustomSftp) {
            newDraftState = {
              ...newDraftState,
              sftpServerSettings: buildClearedCustomSFTPSettings(),
            };
          } else {
            // Default shop SFTP settings credentials
            newDraftState = {
              ...newDraftState,
              sftpServerSettings: mapDefaultSFTPSettings({
                shopSFTPSettingsCredentials: shopSFTPSettingsCredentials as IShopSftpSettings, // TODO: CHECK WHY not all shops has docs in shop_sftp_settings collection
              }),
            };
          }
        }

        if (partialData?.selectedImportMethod === ImportMethodTypesEnum.upload_wget_server) {
          newDraftState = {
            ...newDraftState,
            wgetServerSettings: mapCustomWGETSettings({ authentication: true }),
          };
        }

        setCatalogDraft(newDraftState);

        updateIsDirty(true);
      },
      [catalogDraft, setCatalogDraft, updateIsDirty, validate]
    );

    const importMethodErrors = useMemo(() => {
      return _pick(errors, [
        'sftpServerSettings.host',
        'sftpServerSettings.port',
        'sftpServerSettings.port',
        'sftpServerSettings.path',
        'sftpServerSettings.authentication',
        'sftpServerSettings.username',
        'sftpServerSettings.password',
        'sftpServerSettings.fileName',
        'wgetServerSettings.dataSourceURL',
        'wgetServerSettings.authentication',
        'wgetServerSettings.username',
        'wgetServerSettings.password',
      ]);
    }, [errors]);

    // Validate on every change in catalog draft
    useEffect(() => {
      if (isDirty) {
        validate({
          dataToValidate: catalogDraft,
        });
      }
    }, [catalogDraft]);

    useImperativeHandle(
      ref,
      () => {
        return {
          async submit() {
            if (!isValid) {
              return;
            }

            if (mode === 'create') {
              setIsInProcess(true);
              try {
                const formAPIModel = mapFormDraftToAPI(catalogDraft);

                await (
                  dispatch(
                    catalogFormActions.createCatalogPlan({
                      shopId,
                      data: formAPIModel as Required<ICatalogPlanCreate>,
                    })
                  ) as any
                ).unwrap();

                updateIsDirty(false);
              } catch (error) {
                console.error(error);
                throw error;
              } finally {
                setIsInProcess(false);
              }
            } else {
              setIsInProcess(true);

              try {
                const formAPIModel = mapFormDraftToAPI(catalogDraft);

                await (
                  dispatch(
                    catalogFormActions.updateCatalogPlan({
                      shopId,
                      data: formAPIModel as Required<ICatalogPlanCreate>,
                    })
                  ) as any
                ).unwrap();

                updateIsDirty(false);
              } catch (error) {
                console.error(error);
                throw error;
              } finally {
                setIsInProcess(false);
              }
            }
          },
        };
      },
      [mode, catalogDraft, shopId, isValid]
    );

    useEffect(() => {
      if (isDirty) {
        dispatch(catalogFormActions.notifyIsDirty({ isDirty }));
      }
    }, [isDirty]);

    useEffect(() => {
      return () => {
        dispatch(catalogFormActions.resetSelectedCatalog());
      };
    }, []);

    const isFashionVerticalSelected = useMemo(() => {
      const result = catalogDraft.verticals.some(
        (vertical: ShopCatalogVertical) => vertical === ShopCatalogVertical.Fashion
      );
      return result;
    }, [catalogDraft.verticals]);

    return (
      <>
        {isInProcess && <BackDropWithLoader />}
        <form
          css={css`
            min-width: 955px;
          `}
        >
          <CatalogFormGeneralSection
            errors={errors?.name}
            name={catalogDraft.name}
            isNameReadOnly={mode === 'edit'}
            onFieldChange={onFieldChange}
          />
          <CatalogFormVerticalsSection
            errors={errors?.verticals}
            verticals={catalogDraft.verticals}
            onFieldChange={onFieldChange}
          />

          {/* Show genders only when fashion vertical is in one of the selected verticals */}
          {isFashionVerticalSelected && (
            <CatalogFormGendersAndLocaleSection
              errors={errors?.genders}
              genders={catalogDraft.genders}
              onFieldChange={onFieldChange}
            />
          )}

          <CatalogFormDataSourceSection
            errors={errors?.productsFile}
            onFieldChange={onFieldChange}
            selectedDataSource={catalogDraft.selectedDataSource}
          />
          {catalogDraft.selectedDataSource === DataSourceTypesEnum.use_product_feed && (
            <>
              <CatalogFormImportMethodSection
                errors={importMethodErrors}
                isUsingCustomSftp={catalogDraft.isUsingCustomSftp}
                onFieldChange={onFieldChange}
                onNotifyCopyOnClipboard={onNotifyCopyOnClipboard}
                shopName={shopName || 'N/A'}
                isCustomerHasSFTPCredentials={!!shopSFTPSettingsCredentials}
                sftpServerSettings={catalogDraft.sftpServerSettings}
                wgetServerSettings={catalogDraft.wgetServerSettings}
                selectedImportMethod={catalogDraft.selectedImportMethod}
              />

              <CatalogFormSchedulingSection
                selectedScheduleType={catalogDraft.selectedScheduleType as SchedulingTypesEnum}
                scheduling={catalogDraft.scheduling}
                errors={_pick(errors, ['scheduling.timeIntervals.hours'])}
                onFieldChange={onFieldChange}
              />
            </>
          )}
        </form>
      </>
    );
  }
);
