import { ClearListener, WebSocketService } from '../web-socket.service';

export interface DomainEvent<Data = unknown, TopicKey extends string = string> {
  data: Data;
  topicKey: TopicKey;
  eventIssuedByCurrentUser: boolean;
  isSystemEvent: boolean;
}

export const domainEventsWSService = new WebSocketService('domain-events');

// Wrapper class around managing domain events.
// You pass event name and call back for your event.
export abstract class DomainEventsWebSocketServiceBase<TopicKey extends string = string> {
  readonly domainEventsWebSocketService = domainEventsWSService;

  private domainEventNamesSet: Set<TopicKey> = new Set();

  // Start listening for events.
  // It will start to listen as soon as you initialize the event handler and connection is established in useDomainEventsWS().
  // Caution: If you call this method multiple times old listeners are not removed and still will be active!
  subscribeToEvents<Data = unknown>({
    callback,
    topicKey,
  }: {
    callback: (message: DomainEvent<Data, TopicKey>) => void;
    topicKey: TopicKey;
  }): ClearListener {
    this.domainEventNamesSet.add(topicKey);

    return this.domainEventsWebSocketService.initOnMessage({
      topicKey,
      onMessage: callback,
    });
  }

  // This method clears all registered listeners.
  // Use it if you need to pass to new callbacks to registered events
  clearListeners(): void {
    this.domainEventsWebSocketService.removeListenersByNames([...this.domainEventNamesSet]);
  }
}
