import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Page } from 'src/components-dummy';
import { useAppSelector } from 'src/hooks';
import { EnableUiTest } from 'src/components-bl';

export const EnableUiTestContainer = (): JSX.Element | null => {
  const formHeaderRef = useRef<HTMLDivElement>(null);
  const shopId = useAppSelector(state => state.shop.current?.shopId);

  const dispatch = useDispatch();
  return (
    <Page className='enable-ui-test-container'>
      <Page.Header>
        <Page.Title>Ui Test</Page.Title>
        <div className='enable-ui-test-buttons-section' ref={formHeaderRef} />
      </Page.Header>
      <Page.Content>
        {shopId && (
          <EnableUiTest
            enableUiTest={false}
            shopId={shopId}
            formHeaderElementRef={formHeaderRef}
            dispatch={dispatch}
            shouldRefetch={false}
          />
        )}
      </Page.Content>
    </Page>
  );
};
