import classNames from 'classnames';
import React from 'react';
import { AvailableIcons, Icon } from 'src/components-dummy';
import { CollapseIconProps } from '../types';

export const CollapseIcon = ({ className }: CollapseIconProps): JSX.Element => {
  return (
    <Icon name={AvailableIcons.Back} className={classNames('syte-collapse-icon', className)} />
  );
};
