import React, { useCallback, useState, useEffect } from 'react';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog';
import { Button } from 'src/components-dummy/Button';
import { TypographyType, TypographyVariant, TextBox } from 'src/components-dummy';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';
import { DialogTypographyStyled } from '../BulkActions.styles';

export type TagField = 'category' | 'attribute' | 'value';

interface RenameTagsModalModalProps {
  onCancel: () => void;
  onRenameClick: (customTranslation: string) => void;
  tagField: TagField;
  tagsCount: number;
}

const fieldToPluralText: Record<TagField, string> = {
  attribute: 'attributes',
  category: 'categories',
  value: 'values',
};

export const RenameTagsModal = ({
  onCancel,
  onRenameClick,
  tagField,
  tagsCount,
}: RenameTagsModalModalProps): JSX.Element => {
  const [customTranslation, setCustomTranslation] = useState('');

  const translationTrimmed = customTranslation.trim();

  const isValid = translationTrimmed.length > 2;

  const onSubmit = useCallback(() => {
    if (isValid) {
      onRenameClick(translationTrimmed);
    }
  }, [isValid, onRenameClick, translationTrimmed]);

  useEffect(() => {
    return () => {
      setCustomTranslation('');
    };
  }, []);

  return (
    <ConfirmationDialog onCancel={onCancel}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Rename {fieldToPluralText[tagField]}</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        <DialogTypographyStyled type={TypographyType.Body} variant={TypographyVariant.LargeMedium}>
          Insert new name into {tagsCount}{' '}
          {tagsCount === 1 ? tagField : fieldToPluralText[tagField]}
        </DialogTypographyStyled>
        <TextBox
          label='New name'
          value={customTranslation}
          onChange={setCustomTranslation}
          placeholder='Write here'
        />
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onSubmit} disabled={!isValid}>
            Rename all
          </Button>
          <Button variant='outlined' onClick={onCancel}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
