import React from 'react';
import { MenuProps as MuiMenuProps } from '@mui/material/Menu';
import { TypographyType, TypographyVariant } from '../Typography';
import { LabelStyled, MenuStyled } from './Menu.styles';
import './Menu.scss';

export interface MenuProps extends MuiMenuProps {
  children:
    | React.ReactChild
    | JSX.Element
    | null
    | boolean
    | Array<React.ReactChild | null | boolean | JSX.Element>;
  label?: string;
}

export const Menu = ({ children, label, ...rest }: MenuProps): JSX.Element => {
  return (
    <MenuStyled
      id='positioned-menu'
      aria-labelledby='positioned-button'
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 0,
      }}
      transformOrigin={{
        vertical: -4,
        horizontal: 'left',
      }}
      {...rest}
    >
      {label && (
        <LabelStyled type={TypographyType.Body} variant={TypographyVariant.ExtraSmallBold}>
          {label}
        </LabelStyled>
      )}
      {children}
    </MenuStyled>
  );
};
