import { startCase } from 'lodash';
import { RankingType } from './types';
import { getStringEnumValues } from '../../../utils';

interface RankingStrategyTypeMapper {
  [index: string]: {
    text: string;
  };
}

const convertEnumValueToText = (value: string) => {
  const splitValues = value.split('_');
  const splitCapitalizedValues = splitValues.map(startCase);

  return splitCapitalizedValues.join(' ');
};

const createRankingTypeMapper = (): RankingStrategyTypeMapper => {
  return getStringEnumValues(RankingType).reduce((res: RankingStrategyTypeMapper, key: string) => {
    res[key] = { text: convertEnumValueToText(key) };
    return res;
  }, {} as RankingStrategyTypeMapper);
};

export const rankingStrategyTypeMapper = createRankingTypeMapper();
