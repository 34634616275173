/* eslint-disable consistent-return */
import { AxiosResponse } from 'axios';
import { ApiServiceBase } from '../api-service-base';
import { GalleriesMapper } from './galleries.mapper';
import {
  CustomInspirationsGallery,
  GalleryType,
  InspirationalImagesGallery,
} from '../types/galleries';
import {
  CustomInspirationsApi,
  CustomInspirationsGalleryUpdatePayload,
  IGalleriesResponseGet,
  InspirationalImageApi,
} from './types';

const GalleryTypeToEndpointTranslation = {
  [GalleryType.InspirationalImage]: 'inspirational-images',
  [GalleryType.CustomInspirations]: 'custom-inspirations',
};

export class GalleriesService extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl({ shopId }: { shopId: number }): string {
    return `${this.serviceBaseUri}/${shopId}/galleries`;
  }

  private getInspirationalImagesUrl({ shopId }: { shopId: number }): string {
    return `${this.getUrl({ shopId })}/inspirational-images`;
  }

  async getGalleries({
    shopId,
  }: {
    shopId: number;
  }): Promise<(InspirationalImagesGallery | CustomInspirationsGallery)[]> {
    const url = this.getUrl({ shopId });
    const response: AxiosResponse<IGalleriesResponseGet> = await this.httpService.get({
      url,
    });
    return GalleriesMapper.mapGalleriesResponse(response.data);
  }

  async getGallery({
    shopId,
    galleryId,
    galleryType,
  }: {
    shopId: number;
    galleryId: string;
    galleryType: GalleryType;
  }): Promise<InspirationalImagesGallery | CustomInspirationsGallery> {
    const translatedGalleryType = GalleryTypeToEndpointTranslation[galleryType];

    const url = `${this.getUrl({ shopId })}/${translatedGalleryType}/${galleryId}`;
    const response: AxiosResponse<InspirationalImageApi | CustomInspirationsApi> =
      await this.httpService.get({
        url,
      });

    // eslint-disable-next-line default-case
    switch (galleryType) {
      case GalleryType.InspirationalImage:
        return GalleriesMapper.mapInspirationalImagesGallery(
          response.data as InspirationalImageApi
        );

      case GalleryType.CustomInspirations:
        return GalleriesMapper.mapCustomInspirationsGallery(response.data as CustomInspirationsApi);
    }
  }

  async createGallery({
    shopId,
    galleryName,
    galleryType,
  }: {
    shopId: number;
    galleryName: string;
    galleryType: GalleryType;
  }): Promise<InspirationalImagesGallery | CustomInspirationsGallery> {
    const translatedGalleryType = GalleryTypeToEndpointTranslation[galleryType];

    const url = `${this.getUrl({ shopId })}/${translatedGalleryType}`;

    const response: AxiosResponse<InspirationalImageApi | CustomInspirationsApi> =
      await this.httpService.post({
        url,
        data: {
          galleryName,
        },
      });

    // eslint-disable-next-line default-case
    switch (galleryType) {
      case GalleryType.InspirationalImage:
        return GalleriesMapper.mapInspirationalImagesGallery(
          response.data as InspirationalImageApi
        );

      case GalleryType.CustomInspirations:
        return GalleriesMapper.mapCustomInspirationsGallery(response.data as CustomInspirationsApi);
    }
  }

  async updateInspirationalImagesGallery({
    shopId,
    galleryId,
    inspirationalImagesFile,
  }: {
    shopId: number;
    galleryId: string;
    inspirationalImagesFile: File;
  }): Promise<InspirationalImagesGallery> {
    const url = `${this.getInspirationalImagesUrl({ shopId })}/${galleryId}`;

    const formData = new FormData();
    formData.append('file', inspirationalImagesFile);

    const response: AxiosResponse<InspirationalImageApi> = await this.httpService.patch({
      url,
      data: formData,
      requestConfig: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });

    return GalleriesMapper.mapInspirationalImagesGallery(response.data);
  }

  async deleteGallery({
    shopId,
    galleryId,
    galleryType,
  }: {
    shopId: number;
    galleryId: string;
    galleryType: GalleryType;
  }): Promise<boolean> {
    const translatedGalleryType = GalleryTypeToEndpointTranslation[galleryType];

    const url = `${this.getUrl({ shopId })}/${translatedGalleryType}/${galleryId}`;
    const { status }: AxiosResponse = await this.httpService.delete({ url });

    return status === 204;
  }

  async updateCustomInspirationsGallery({
    shopId,
    galleryId,
    payload,
  }: {
    shopId: number;
    galleryId: string;
    payload: CustomInspirationsGalleryUpdatePayload;
  }): Promise<CustomInspirationsGallery> {
    const url = `${this.getUrl({ shopId })}/${
      GalleryTypeToEndpointTranslation[GalleryType.CustomInspirations]
    }/${galleryId}`;

    const response: AxiosResponse<CustomInspirationsApi> = await this.httpService.patch({
      url,
      data: payload,
    });

    return GalleriesMapper.mapCustomInspirationsGallery(response.data);
  }

  async startImageTagsDetection({
    shopId,
    galleryId,
    imageId,
  }: {
    shopId: number;
    galleryId: string;
    imageId: string;
  }): Promise<void> {
    const url = `${this.getUrl({ shopId })}/${
      GalleryTypeToEndpointTranslation[GalleryType.CustomInspirations]
    }/${galleryId}/images/${imageId}/detect-tags`;

    await this.httpService.post({
      url,
    });
  }

  async startGalleryTagsDetection({
    shopId,
    galleryId,
  }: {
    shopId: number;
    galleryId: string;
  }): Promise<void> {
    const url = `${this.getUrl({ shopId })}/${
      GalleryTypeToEndpointTranslation[GalleryType.CustomInspirations]
    }/${galleryId}/detect-tags`;

    await this.httpService.post({
      url,
    });
  }
}

export const galleriesService = new GalleriesService();
