import styled from '@emotion/styled';
import { Icon } from 'src/components-dummy';

export const ButtonIconStyled = styled(Icon)`
  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-10']};
  }
`;

export const ImageButtonStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  border-radius: 16px;
  padding: 4px;

  height: 20px;
  min-width: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  z-index: 2;

  ${ButtonIconStyled} {
    width: 8px;
  }

  &:hover {
    cursor: pointer;
  }
`;
