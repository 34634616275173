import { useRef, useEffect, useCallback, useState } from 'react';

type StartStopMethod = () => void;
type Callback = (delta: number) => boolean | void;

export const useAnimationFrame = (
  callback: Callback
): { start: StartStopMethod; stop: StartStopMethod } => {
  const [shouldRun, setShouldRun] = useState(false);
  const requestRef = useRef<number | undefined>();
  const previousTimeRef = useRef<number | undefined>();

  const stop = useCallback(() => {
    if (requestRef.current) {
      cancelAnimationFrame(requestRef.current);
      requestRef.current = undefined;
      setShouldRun(false);
    }
  }, []);

  const animate = useCallback(
    (time: number) => {
      let shouldContinueExecution;

      if (previousTimeRef.current !== undefined) {
        const deltaTime = time - previousTimeRef.current;
        shouldContinueExecution = callback(deltaTime);
      }
      previousTimeRef.current = time;

      if (shouldContinueExecution === false) {
        setShouldRun(false);
      } else {
        requestRef.current = requestAnimationFrame(animate);
      }
    },
    [setShouldRun, callback]
  );

  const start = useCallback(() => {
    if (requestRef.current === undefined) {
      requestRef.current = requestAnimationFrame(animate);
      setShouldRun(true);
    }
  }, []);

  useEffect(() => {
    setShouldRun(true);
    return () => {
      setShouldRun(false);
      stop();
    };
  }, []);

  useEffect(() => {
    if (shouldRun === true) {
      start();
    } else {
      stop();
    }
  }, [shouldRun, start, stop]);

  return { start, stop };
};
