import React from 'react';
import { AvailableIcons } from 'src/components-dummy';
import { DeleteCellStyled } from '../../styles';

interface DeleteCellProps {
  onClick: () => void;
}

export const DeleteCell = ({ onClick }: DeleteCellProps): JSX.Element => {
  return <DeleteCellStyled name={AvailableIcons.TrashCan} onClick={onClick} />;
};
