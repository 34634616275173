import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const SliderRowStyled = styled.div`
  display: flex;
  gap: 13px;

  margin-top: 24px;

  & > div {
    padding: unset;
    display: flex;
  }

  .MuiSlider-thumb::before {
    background-color: unset;
  }

  .MuiSlider-root {
    align-self: center;
  }
`;

export const TypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  user-select: none;
  align-self: center;
`;
