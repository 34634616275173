import React, { useCallback } from 'react';
import { FilterSortBy } from 'src/services';
import { ParseJoiValidateResponse } from 'src/utils';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { FilterDraft } from '../../types';
import { SectionBoxStyled, SubTitleTypography } from '../shared.styles';
import { SortOptionsBySelect } from './SortOptionsBySelect';

type DisplayOptionsSectionData = Pick<FilterDraft, 'valuesSorting'>;

interface DisplayOptionsSectionProps {
  onChange: (updatedData: Partial<DisplayOptionsSectionData>) => void;
  customOrderOptionDisabled: boolean;
  data: DisplayOptionsSectionData;
  errors: ParseJoiValidateResponse<Omit<FilterDraft, 'id'>>;
}

export const DisplayOptionsSection = ({
  onChange,
  customOrderOptionDisabled,
  data,
  errors,
}: DisplayOptionsSectionProps): JSX.Element => {
  const onSortByChange = useCallback(
    (updatedSortBy: FilterSortBy) => {
      const isCustomOrderBy = updatedSortBy === FilterSortBy.CustomOrder;

      onChange({
        valuesSorting: {
          ...data.valuesSorting,
          sortBy: updatedSortBy,
          customOrder: isCustomOrderBy ? data.valuesSorting?.customOrder : undefined,
        },
      });
    },
    [onChange, data]
  );

  return (
    <SectionBoxStyled>
      <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
        Display Options
      </Typography>
      <SubTitleTypography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
        Define the display options for your filter
      </SubTitleTypography>
      <SortOptionsBySelect
        sortBy={data.valuesSorting?.sortBy}
        customOrderOptionDisabled={customOrderOptionDisabled}
        onChange={onSortByChange}
        error={errors?.sortBy?.message}
      />
    </SectionBoxStyled>
  );
};
