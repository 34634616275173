import React, { useCallback, useEffect, useRef, useState } from 'react';
import { generatePath } from 'react-router';
import { ShopDataField, SyteLayout } from 'src/services';
import { RoutedComponentProps } from 'src/app-routes';
import { AvailableIcons, Page, TypographyType, TypographyVariant } from 'src/components-dummy';
import { usePreviousState } from 'src/hooks';
import { Dispatch } from 'src/components-bl/types';
import { FilterForm, FilterFormFooter, FilterFormApiRef } from '../FilterForm';
import {
  BackToFiltersListStyled,
  ButtonStyled,
  CreateFilterPageStyled,
  GrayedOutTitleStyled,
  LearnMoreLink,
  LinkIconStyled,
  TitlesWrapper,
} from './CreateFilterPage.styles';
import { createFilterPageActions } from './Actions';
import { CreateFilterConfirmationDialog } from './CreateFilterConfirmationDialog';

const learnMoreLink = 'https://support.syte.ai/space/ET/2938339368/Define+Filters+Settings';

interface CreateFilterPageProps extends RoutedComponentProps {
  shopId?: number;
  syteLayout: SyteLayout;
  dispatch: Dispatch;
  availableDataFields: ShopDataField[] | undefined;
  hasReachedMaxAllowedFiltersLimit: boolean | undefined;
  hasReachedMaxAllowedCustomOrderFiltersLimit?: boolean;
  dataFieldsFromOtherFilters: Set<string> | undefined;
}

export const CreateFilterPage = ({
  shopId,
  syteLayout,
  permittedRouteMap,
  dispatch,
  availableDataFields,
  hasReachedMaxAllowedFiltersLimit,
  hasReachedMaxAllowedCustomOrderFiltersLimit,
  dataFieldsFromOtherFilters,
}: CreateFilterPageProps): JSX.Element => {
  const [showPopUp, setShowPopUp] = useState(false);
  const formApiRef = useRef<FilterFormApiRef | null>(null);
  const [canSubmitForm, setCanSubmitForm] = useState(false);

  const prevHasReachedMaxAllowedFiltersLimit = usePreviousState(hasReachedMaxAllowedFiltersLimit);

  const navigateToList = useCallback(() => {
    if (permittedRouteMap.filtersList && shopId) {
      dispatch(
        createFilterPageActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.filtersList.path, { shopId, syteLayout }),
        })
      );
    }
  }, [dispatch, shopId, syteLayout, permittedRouteMap.filtersList]);

  const onSubmit = useCallback(async () => {
    if (!formApiRef.current) {
      return;
    }

    try {
      await formApiRef.current.submit();

      navigateToList();
    } catch (error) {
      console.error('CreateFilterPage', error);
    }
  }, [formApiRef.current, navigateToList]);

  useEffect(() => {
    const shouldValidate =
      prevHasReachedMaxAllowedFiltersLimit === undefined &&
      hasReachedMaxAllowedFiltersLimit !== undefined;

    if (!shouldValidate) return;

    if (hasReachedMaxAllowedFiltersLimit === true) {
      dispatch(
        createFilterPageActions.errorNotification({
          customMessage: "You've reached the limit for active filters",
        })
      );

      navigateToList();
    }
  }, [hasReachedMaxAllowedFiltersLimit]);

  useEffect(() => {
    if (shopId) {
      dispatch(
        createFilterPageActions.getFilterSet({
          shopId,
          syteLayout,
        })
      );
    }
  }, [shopId, syteLayout]);

  return (
    <>
      {showPopUp && (
        <CreateFilterConfirmationDialog onCancel={() => setShowPopUp(false)} onCreate={onSubmit} />
      )}
      <CreateFilterPageStyled>
        <Page.Header>
          <TitlesWrapper>
            <GrayedOutTitleStyled
              variant={TypographyVariant.ExtraSmallBold}
              type={TypographyType.Heading}
            >
              <BackToFiltersListStyled onClick={navigateToList}>Filters</BackToFiltersListStyled>{' '}
              &gt;
            </GrayedOutTitleStyled>
            <Page.Title> Create New Filter</Page.Title>
          </TitlesWrapper>
          <LearnMoreLink>
            <a href={learnMoreLink} target='_blank' rel='noreferrer'>
              Learn more about our filtering options
              <LinkIconStyled name={AvailableIcons.ArrowRec} />
            </a>
          </LearnMoreLink>
        </Page.Header>
        <Page.Content>
          <FilterForm
            ref={formApiRef}
            mode='create'
            shopId={shopId}
            syteLayout={syteLayout}
            dispatch={dispatch}
            permittedRouteMap={permittedRouteMap}
            availableDataFields={availableDataFields}
            onFormStatusChange={setCanSubmitForm}
            dataFieldsFromOtherFilters={dataFieldsFromOtherFilters}
            hasReachedMaxAllowedCustomOrderFiltersLimit={
              hasReachedMaxAllowedCustomOrderFiltersLimit
            }
          />
        </Page.Content>
      </CreateFilterPageStyled>

      <FilterFormFooter>
        <ButtonStyled variant='tertiary' onClick={navigateToList}>
          Cancel
        </ButtonStyled>
        <ButtonStyled
          disabled={!canSubmitForm}
          variant='primary'
          onClick={() => setShowPopUp(true)}
        >
          Create
        </ButtonStyled>
      </FilterFormFooter>
    </>
  );
};
