import Joi from 'joi';

export const shopTheLookSettingsValidationSchema = {
  numberOfResultsToDisplay: Joi.number().required().min(3).max(50),
  includeMainProductInCarousel: Joi.boolean().required(),
  shuffleResults: Joi.boolean().required(),
  completeTheLookFallbackMethod: Joi.string().required(),
  addToCart: Joi.object({
    active: Joi.boolean(),
    isBundle: Joi.when('active', {
      is: Joi.boolean().valid('true'),
      then: Joi.boolean().required(),
    }),
  }).required(),
};
