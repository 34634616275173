import { AxiosResponse } from 'axios';
import { parseISODate } from 'src/utils/parse-iso-date';
import { Filter, FilterAPI, FilterSet, FilterSetAPI } from '../types';

export class FiltersMapper {
  static mapFilter(filter: FilterAPI): Filter {
    return {
      ...filter,
      createdAt: parseISODate(filter.createdAt),
      updatedAt: parseISODate(filter.updatedAt),
    };
  }

  static mapFilters({ data }: AxiosResponse<{ filters: FilterAPI[] }>): Filter[] {
    return data.filters.map(filter => this.mapFilter(filter));
  }

  static mapFilterSet({ data }: AxiosResponse<FilterSetAPI>): FilterSet {
    return {
      ...data,
      filters: data.filters.map(filter => this.mapFilter(filter)),
      createdAt: parseISODate(data.createdAt),
      updatedAt: parseISODate(data.updatedAt),
    };
  }
}
