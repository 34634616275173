import { Dispatch } from 'src/components-bl';

export enum DeepTagsProductActionsActionTypes {
  NotifyIsDirty = 'LexiconCategoryContentContainer/NotifyIsDirty',
}

/**
 * Types
 */
export interface DeepTagsProductActionsNotifyIsDirtyAction {
  type: typeof DeepTagsProductActionsActionTypes.NotifyIsDirty;
  payload: {
    isDirty: boolean;
  };
}

/**
 * Actions
 */
export const deepTagsProductActionMethods = {
  notifyIsDirty(payload: DeepTagsProductActionsNotifyIsDirtyAction['payload']) {
    return (dispatch: Dispatch): void => {
      dispatch({
        type: DeepTagsProductActionsActionTypes.NotifyIsDirty,
        payload,
      });
    };
  },
};
