import React, { useRef } from 'react';
import { MerchandisingRuleTypes } from 'src/services';
import { reduceErrorsByPrefix } from 'src/utils';
import {
  TypographyType,
  TypographyVariant,
  AvailableIcons,
  TextBox,
  Tooltip,
} from 'src/components-dummy';
import {
  ConditionRowDataStyled,
  ConditionRowStyled,
  ConditionText,
  ConditionRowErrors,
  InformationIconStyled,
} from '../RuleFormConditionsSection.styles';
import { ErrorLabelStyled } from '../../../MerchandisingRuleForm.styles';
import { RuleDraft } from '../../../MerchandisingRuleForm.config';

interface RedirectRulesInputsProps {
  isReadOnly?: boolean;

  onFieldChange: (
    data: Partial<Pick<RuleDraft, 'action' | 'weight' | 'subRules' | 'redirectRule'>>
  ) => void;

  redirectRule?: MerchandisingRuleTypes.IMerchandisingRuleRedirectRule | null;
  errors: any;
}

const rowWidth = '468px';

export const RedirectRulesInputs = ({
  errors,
  onFieldChange,
  redirectRule,
  isReadOnly,
}: RedirectRulesInputsProps): JSX.Element => {
  const toUrlRef = useRef<HTMLInputElement>(null);

  const actualRuleValue =
    redirectRule === null
      ? {
          targetUrl: '',
          displayName: '',
        }
      : redirectRule;

  const inputErrors = reduceErrorsByPrefix({
    errors,
    prefix: `redirectRule`,
  });

  const redirectRuleErrors: any = { ...inputErrors, redirectRule: errors.redirectRule };

  const rowErrorMessages: string[] = Object.keys(redirectRuleErrors)
    .map(errorKey => redirectRuleErrors[errorKey]?.message)
    .filter(message => !!message);

  const rowErrorLabels = rowErrorMessages.map(errorMessage => {
    return <ErrorLabelStyled key={errorMessage}>{errorMessage}</ErrorLabelStyled>;
  });

  const onRedirectRuleChange = (
    partialRule: Partial<MerchandisingRuleTypes.IMerchandisingRuleRedirectRule>
  ) => {
    const updatedRedirectRuleState = {
      ...actualRuleValue,
      ...partialRule,
    } as MerchandisingRuleTypes.IMerchandisingRuleRedirectRule;

    onFieldChange({ redirectRule: updatedRedirectRuleState });
  };

  const onTargetUrlChange = (targetUrl: string) => {
    onRedirectRuleChange({ targetUrl });
  };

  const onDisplayNameChange = (displayName: string) => {
    onRedirectRuleChange({ displayName });
  };
  return (
    <>
      <ConditionRowStyled width={rowWidth}>
        <ConditionRowDataStyled>
          <ConditionText
            type={TypographyType.Body}
            variant={TypographyVariant.SmallMedium}
            minWidth={2.5}
          >
            To
          </ConditionText>

          <TextBox
            fullWidth
            value={actualRuleValue?.targetUrl}
            placeholder='https://www.example.com/...'
            onChange={onTargetUrlChange}
            error={errors['redirectRule.targetUrl'] || errors.redirectRule}
            disabled={isReadOnly}
            autoFocus
            inputRef={toUrlRef}
          />
        </ConditionRowDataStyled>
      </ConditionRowStyled>
      <ConditionRowStyled width={rowWidth}>
        <ConditionRowDataStyled>
          <ConditionText
            type={TypographyType.Body}
            variant={TypographyVariant.SmallMedium}
            minWidth={7}
          >
            Display Name
          </ConditionText>

          <Tooltip value='Display name for the search term in recent and popular searches i.e for “returns” use “Delivery & Returns'>
            <InformationIconStyled name={AvailableIcons.Information} />
          </Tooltip>

          <TextBox
            fullWidth
            error={errors['redirectRule.displayName'] || errors.redirectRule}
            value={actualRuleValue?.displayName}
            placeholder='Type here...'
            onChange={onDisplayNameChange}
            disabled={isReadOnly}
          />
        </ConditionRowDataStyled>
        {rowErrorLabels.length > 0 ? (
          <ConditionRowErrors>{rowErrorLabels}</ConditionRowErrors>
        ) : null}
      </ConditionRowStyled>
    </>
  );
};
