import React from 'react';

import {
  Typography,
  TypographyVariant,
  TypographyType,
  SwitchSizes,
  Switch,
} from 'src/components-dummy';
import { SettingToggleStyled } from '../../APISettings/APISettingsForm.styles';

interface ShopDeepTagReportsSettingsTabContentProps {
  title: string;
  onChange: (isChecked: boolean) => void;
  isChecked: boolean;
}

export const ShopDeepTagReportsSettingsTabContent = ({
  title,
  onChange,
  isChecked,
}: ShopDeepTagReportsSettingsTabContentProps): JSX.Element => {
  return (
    <SettingToggleStyled>
      <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Body}>
        {title}
      </Typography>
      <section>
        <Switch size={SwitchSizes.Large} onChange={onChange} checked={isChecked} />
      </section>
    </SettingToggleStyled>
  );
};
