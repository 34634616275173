import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { editCollectionActionMethods } from 'src/containers/EditCollection/actions/editCollectionActions';
import { useSortedAndFilteredCollections } from '../../hooks';
import { useAddParamsToRoute } from './useAddParamsToRoute';
import { useAppSelector } from '../../../hooks';
import { visualEditorActions } from '../state';
import { ContentType } from '../types';
import { Dispatch } from '../../../components-dummy/types';
import { Collection } from '../../Collections';
import { PermittedRouteMap } from '../../../app-routes';
import { PreviewFeature } from '../../../services';

export const useCollectionSettings = (
  shopId: number,
  selectedExperience?: PreviewFeature,
  permittedRouteMap?: PermittedRouteMap
) => {
  const appDispatch = useDispatch();
  const collection = useAppSelector(({ visualEditor }) => visualEditor.collection);
  const { sortingValue, collections } = useAppSelector(rootState => rootState.collections);
  const { sortedCollections } = useSortedAndFilteredCollections({ sortingValue, collections });
  const { addParamsToRoute } = useAddParamsToRoute({
    shopId,
    route: permittedRouteMap?.visualEditor,
  });

  const changeCollection = useCallback(
    (collectionName: string) => {
      const selectedCollection = sortedCollections.find(col => col.name === collectionName);
      if (!selectedCollection) return;
      appDispatch(visualEditorActions.setCollection(selectedCollection));
      addParamsToRoute({ shopperExperience: selectedExperience, collectionName });
    },
    [appDispatch, selectedExperience, sortedCollections]
  );

  const navigateToCollection = useCallback(() => {
    if (!collection) return;
    const collectionLink = `/shops/${shopId}/collections/edit/${collection.id}/collection-rules`;
    window.open(collectionLink, '_blank');
  }, [shopId, collection]);

  useEffect(() => {
    if (!collections.length) {
      appDispatch(visualEditorActions.setContentType(ContentType.NoActiveCollection));
    }
  }, [collections]);

  const selectedCollectionName = collection?.name || '';

  useEffect(() => {
    if (shopId && collection) {
      appDispatch(
        editCollectionActionMethods.getCollection({
          shopId,
          collectionId: collection.id,
        })
      );
    }
  }, [shopId, collection]);

  const onCreateCollectionCallback = (dispatch: Dispatch, col?: Collection) => {
    if (!col) return;
    dispatch(visualEditorActions.setCollection(col));
  };

  return {
    changeCollection,
    navigateToCollection,
    collection,
    collections: sortedCollections,
    selectedCollectionName,
    onCreateCollectionCallback,
  };
};
