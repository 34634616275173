import React, { useCallback, useImperativeHandle } from 'react';
import { generatePath } from 'react-router-dom';
import { NotificationCardMessageTypography } from '../common';
import { NotificationCardMessageProps } from '../types';

export function TuningValidationChange({
  notification,
  shopId,
  permittedRouteMap,
  navigateTo,
  cardRef,
}: NotificationCardMessageProps<{
  createdFields: string[];
  removedFields: string[];
}>): JSX.Element {
  const onNotificationClick = useCallback(() => {
    if (permittedRouteMap.augmentedSearchRelevancyTuning?.path) {
      const tuningPath = generatePath(permittedRouteMap.augmentedSearchRelevancyTuning.path, {
        shopId,
      });
      navigateTo(tuningPath);
    }
  }, [shopId, navigateTo, permittedRouteMap.augmentedSearchRelevancyTuning?.path]);

  useImperativeHandle(cardRef, () => ({
    onNotificationClick,
  }));

  const hasCreatedFields = notification.content.createdFields.length > 0;

  const createdFieldsPart = `• Adding new searchable fields: ${notification.content.createdFields.join(
    ', '
  )}`;

  const hasRemovedFields = notification.content.removedFields.length > 0;

  const removedFieldsPart = `• Deleting existing attributes: ${notification.content.removedFields.join(
    ', '
  )}`;

  return (
    <NotificationCardMessageTypography>
      An admin has made changes to the text search fields, including:
      {hasCreatedFields && (
        <>
          <br />
          {createdFieldsPart}
        </>
      )}
      {hasRemovedFields && (
        <>
          <br />
          {removedFieldsPart}
        </>
      )}
      {permittedRouteMap.augmentedSearchRelevancyTuning?.path && (
        <>
          <br />
          Please review the updated settings on the Relevancy Tuning page
        </>
      )}
    </NotificationCardMessageTypography>
  );
}
