import React from 'react';
import { CircularProgress } from '@mui/material';
import { AvailableIcons, Icon } from '../../../../components-dummy/Icon';
import {
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy/Typography';
import { Button } from '../../../../components-dummy/Button';
import { SkuState } from '../../../../components-bl/socialDiscovery/AddSKU/useSKUState/types';

interface ButtonContentProps {
  skuState: SkuState;
  errorMessage: string | null;
  onClick: () => void;
}

export const SKUButton = ({ skuState, errorMessage, onClick }: ButtonContentProps) => {
  switch (skuState) {
    case SkuState.Error:
      return (
        <div className='sku-error'>
          <Icon name={AvailableIcons.Warning} />
          <Typography type={TypographyType.Body} variant={TypographyVariant.ExtraSmallBold}>
            {errorMessage}
          </Typography>
        </div>
      );
    case SkuState.HasSku:
      return (
        <Button onClick={onClick} variant='tertiary'>
          <Icon name={AvailableIcons.ArrowRight} />
        </Button>
      );
    case SkuState.Loading:
      return <CircularProgress size={16} />;
    default:
      return null;
  }
};
