import styled from '@emotion/styled';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { Typography } from '../Typography';

export const AutocompleteStyled = styled(Autocomplete, {
  shouldForwardProp: prop => prop !== 'errored' && prop !== 'loading',
})<{
  errored?: boolean;
  hasTransparentBackground?: boolean;
}>`
  border: solid 1px
    ${props =>
      props.errored
        ? ({ theme }) => theme.palette.custom.red
        : ({ theme, hasTransparentBackground }) =>
            hasTransparentBackground ? theme.palette.custom['gray-50'] : 'transparent'};

  background-color: ${({ theme, hasTransparentBackground }) =>
    hasTransparentBackground ? 'transparent' : theme.palette.custom['gray-80']};
  border-radius: 4px;
  .MuiAutocomplete-clearIndicator {
    display: none;
  }

  .MuiChip-deleteIcon {
    font-size: 12px !important;
  }
  &.Mui-focused {
    border: solid 1px
      ${({ theme, errored }) =>
        errored ? theme.palette.custom.red : theme.palette.custom['primary-main']};
  }
  .MuiAutocomplete-listbox {
  }
  .MuiOutlinedInput-root {
    padding-bottom: 4px;
    padding-top: 4px;
    border-radius: 4px;
    border: none !important;
  }
  :hover {
    border-radius: 4px;
    border: solid 1px ${({ theme }) => theme.palette.custom['primary-main']};
  }

  .MuiChip-label {
    font-family:
      Inter,
      Montserrat,
      Trebuchet MS,
      Lucida Grande,
      Lucida Sans Unicode;
    font-size: 12px;
    font-weight: 500;
  }
  .MuiChip-filled {
    height: 24px;
    border-radius: 8px;
    margin: 2px;
    background-color: ${({ theme }) => theme.palette.custom.white};
  }

  .MuiAutocomplete-input {
    background-color: ${({ theme, hasTransparentBackground }) =>
      hasTransparentBackground ? 'transparent' : theme.palette.custom['gray-80']};
    color: ${({ theme, hasTransparentBackground }) =>
      hasTransparentBackground
        ? theme.palette.custom['black-10']
        : theme.palette.custom['gray-20']};
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    font-family:
      Inter,
      Montserrat,
      Trebuchet MS,
      Lucida Grande,
      Lucida Sans Unicode;
  }
`;
export const TextFieldStyled = styled(TextField)({
  '& label.Mui-focused': {
    borderWidth: 0,
  },
  '& .MuiInput-underline:after': {
    borderWidth: 0,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: 0,
    },
    '&:hover fieldset': {
      borderWidth: 0,
    },
    '&.Mui-focused fieldset': {
      borderWidth: 0,
    },
  },
  /** Loader / Circular Progress. Position affected by parent padding, override with absolute */
  '& .MuiInputBase-root > .MuiBox-root': {
    position: 'absolute',
    right: '10px',
  },
});
export const ListboxComponentStyled = styled.ul`
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-100']};
  border-radius: 8px;
  li {
    height: 41px;
    font-weight: 500;
    font-family:
      Inter,
      Montserrat,
      Trebuchet MS,
      Lucida Grande,
      Lucida Sans Unicode;
    font-size: 14px;
    :hover {
      background-color: ${({ theme }) => theme.palette.custom['gray-80']};
    }
  }
`;

export const StyledPaper: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>> = styled(
  Paper
)`
  margin-left: -3px !important;
  left: -9px;
  box-shadow:
    0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%),
    0px 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 8px;
`;

export const NewOptionTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['primary-main']};
`;
