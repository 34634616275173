import React, { useMemo, useState, useCallback } from 'react';
import {
  TypographyType,
  TypographyVariant,
  PopUp,
  NestedMultiSelectOption,
  NestedMultiSelectMenuWithSearch,
} from 'src/components-dummy';
import { MerchandiseRule } from 'src/services/src/service/types/shops';

import { ErrorLabelStyled } from '../../MerchandisingRuleForm.styles';
import { RuleFormField } from '../RuleFormField/RuleFormField';
import { RuleFormSection } from '../RuleFormSection/RuleFormSection';
import { continentOrderPriority } from './consts';
import {
  generateOptionsFromRegions,
  mapSelectedValuesToSelectedOptions,
  removeOptionsByParentTitle,
} from './helpers';
import {
  RegionDropdownContentStyled,
  ruleFormFieldCss,
  SubTitleStyled,
  TagsInputStyled,
} from './RuleFormRegionSection.styles';

const MAX_TAGS_TO_DISPLAY = 3;

export type RuleFormSyteLocaleSectionFields = Partial<Pick<MerchandiseRule, 'regions'>>;

export interface RuleFormRegionSectionProps {
  availableRegions: string[];
  regions: string[];
  isReadOnly?: boolean;
  onFieldChange: (partialData: Partial<RuleFormSyteLocaleSectionFields>) => void;
  errors: any;
}

export const RuleFormRegionSection = React.memo(
  ({
    availableRegions,
    onFieldChange,
    errors,
    regions,
    isReadOnly,
  }: RuleFormRegionSectionProps): JSX.Element => {
    const [showPopup, setShowPopup] = useState(false);

    const availableOptions = useMemo(() => {
      return generateOptionsFromRegions({ regions: availableRegions });
    }, [availableRegions]);

    const [selectedOptions, setSelectedOptions] = useState(generateOptionsFromRegions({ regions }));

    const onSelectedOptionsUpdated = useCallback(
      (updatedOptions: NestedMultiSelectOption[]) => {
        onFieldChange({
          regions: updatedOptions.map(selectedOption => selectedOption.value),
        });
      },
      [onFieldChange]
    );

    const onChange = useCallback(
      (currentSelectedValues: string[], parentTitle?: string) => {
        const previousSelectedOptionsToRemain = parentTitle
          ? removeOptionsByParentTitle(selectedOptions, parentTitle)
          : [];

        const updatedOptions = [
          ...previousSelectedOptionsToRemain,
          ...mapSelectedValuesToSelectedOptions(currentSelectedValues, parentTitle),
        ];

        setSelectedOptions(updatedOptions);
        onSelectedOptionsUpdated(updatedOptions);
      },
      [selectedOptions, setSelectedOptions, onSelectedOptionsUpdated]
    );

    const onTagInputChange = useCallback(
      (tags: string[]) => {
        const updatedOptions = selectedOptions.filter(selectedOption =>
          tags.includes(selectedOption.text)
        );
        setSelectedOptions(updatedOptions);
        onSelectedOptionsUpdated(updatedOptions);
      },
      [selectedOptions, setSelectedOptions]
    );

    const toggleShowPopup = useCallback(
      (shouldShowPopup: boolean) => {
        setShowPopup(shouldShowPopup);
      },
      [setShowPopup]
    );

    const isAllOptionsSelected = availableOptions.length === selectedOptions.length;

    const tagsToDisplay = useMemo(() => {
      return isAllOptionsSelected ? [] : selectedOptions.map(option => option.text);
    }, [isAllOptionsSelected, selectedOptions]);

    const errorMessage = errors.regions?.message;
    const showError = Boolean(errorMessage) && Boolean(!isReadOnly);

    return (
      <RuleFormSection>
        <RuleFormSection.Header>
          Apply rule for regions
          <SubTitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
            You can apply this rule to all regions, or specific regions from the list
          </SubTitleStyled>
        </RuleFormSection.Header>
        <RuleFormField css={ruleFormFieldCss}>
          <PopUp
            show={showPopup}
            onShow={toggleShowPopup}
            closeOnTriggerClick={false}
            hideOnScroll={false}
            position='bottom left'
            elementsToFreezeOnActive={['.merchandising-rules-form-page', '.syte-accordion']}
            disabled={isReadOnly}
          >
            <PopUp.Trigger>
              <TagsInputStyled
                tags={tagsToDisplay}
                onChange={onTagInputChange}
                maxTagsToDisplay={MAX_TAGS_TO_DISPLAY}
                placeholder={isAllOptionsSelected ? 'All regions' : 'Select Regions'}
                error={showError}
                disabled={isReadOnly}
              />
            </PopUp.Trigger>

            <PopUp.Content>
              <RegionDropdownContentStyled>
                <NestedMultiSelectMenuWithSearch
                  selectAllText='Select All'
                  enableSelectAll
                  onChange={onChange}
                  options={availableOptions}
                  selectedOptions={selectedOptions}
                  parentOptionsOrderPriority={continentOrderPriority}
                />
              </RegionDropdownContentStyled>
            </PopUp.Content>
          </PopUp>
          {showError && <ErrorLabelStyled> {errorMessage} </ErrorLabelStyled>}
        </RuleFormField>
      </RuleFormSection>
    );
  }
);
