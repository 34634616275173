import React, { createContext, useContext } from 'react';
import { Dispatch } from 'redux';
import { DispatchOffersThunk, GetPayload } from '../types';

export interface IVisualEditorContext {
  appDispatch: Dispatch<any>;
  dispatchOffersThunk: DispatchOffersThunk;
  fetchOffers: VoidFunction;
  getPayload: GetPayload;
}

const VisualEditorContext = createContext<IVisualEditorContext | null>(null);

export const VisualEditorProvider: React.FC<IVisualEditorContext> = ({
  appDispatch,
  dispatchOffersThunk,
  fetchOffers,
  getPayload,
  children,
}) => {
  return (
    <VisualEditorContext.Provider
      value={{ appDispatch, dispatchOffersThunk, fetchOffers, getPayload }}
    >
      {children}
    </VisualEditorContext.Provider>
  );
};

export const useVisualEditorProvider = (): IVisualEditorContext => {
  const context = useContext(VisualEditorContext);

  if (!context) {
    throw new Error('useVisualEditorProvider should be used within VisualEditorProvider');
  }

  return context;
};
