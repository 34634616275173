import { SpecialDataFieldToDisplay } from '../../../../types/special-data-field-to-display';
import { TableHeadRow } from '../types/table-head-row';

export const renderSpecialDataFields = (
  specialDataFieldsToDisplay: SpecialDataFieldToDisplay[]
): Array<{ [key: string]: TableHeadRow | undefined }> => {
  return specialDataFieldsToDisplay
    .filter(data => data.isActive)
    .map(field => {
      switch (field.text) {
        case 'Description':
          return {
            Title: {
              header: 'Title',
              accessor: 'description',
              width: '165px',
              minWidth: 165,
            },
          };
        case 'SKU':
          return {
            SKU: {
              header: 'SKU',
              accessor: 'sku',
              width: '165px',
              minWidth: 165,
            },
          };
        case 'Price':
          return {
            Price: {
              header: 'Price',
              accessor: 'price',
              width: '165px',
              minWidth: 165,
            },
            'Original Price': {
              header: 'Original Price',
              accessor: 'originalPrice',
              width: '165px',
              minWidth: 165,
            },
          };
        default:
          return {};
      }
    });
};
