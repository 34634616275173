export enum FilterType {
  range = 'range',
  buckets = 'buckets',
  text = 'text',
}
export enum OrderMethod {
  results_desc = 'desc',
  manual = 'manual',
}
export enum BucketsOrderType {
  count = 'count',
  name = 'name',
}
export enum BucketsOrderSort {
  desc = 'desc',
  asc = 'asc',
}
export enum TextTypeDisplayOptions {
  list = 'list',
  tags = 'tags',
  swatches = 'swatches',
}
export enum NumericTypeDisplayOptions {
  slider = 'slider',
  rangeGroups = 'rangeGroups',
  // rating = 'rating',
}
export enum SwatchesShapeOptions {
  circle = 'circle',
  square = 'square',
}
export enum FilterSetSegment {
  All = 'all',
  Mobile = 'mobile',
  Desktop = 'desktop',
}

export enum FilterStatusOption {
  All = 'All',
  Visible = 'Visible',
  Hidden = 'Hidden',
  New = 'New',
}

export enum AutoExpandFilters {
  All = 'all',
  None = 'none',
  Custom = 'custom',
}
