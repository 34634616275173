import { Shops } from 'src/services/src/service/shops/shops';
import { Dispatch } from 'src/components-bl/types';
import {
  UpdateEnableUiTestPayload,
  GetEnableUiTestArgs,
  GetEnableUiTestRequestPayload,
  GetEnableUiTestSuccessPayload,
  GetEnableUiTestErrorPayload,
  UpdateEnableUiTestRequestPayload,
  UpdateEnableUiTestSuccessPayload,
  UpdateEnableUiTestErrorPayload,
  EnableUiTestActionTypes,
  GetEnableUiTestRequestAction,
  GetEnableUiTestErrorAction,
  GetEnableUiTestSuccessAction,
  UpdateEnableUiTestRequestAction,
  UpdateEnableUiTestSuccessAction,
  UpdateEnableUiTestErrorAction,
  NotifyEnableUiTestIsDirtyAction,
  NotifyEnableUiTestIsDirtyActionPayload,
} from './types';

const shopService = new Shops();

/* --= Actions =-- */
const enableUiTestActions = {
  getEnableUiTestRequest: (
    payload: GetEnableUiTestRequestPayload
  ): GetEnableUiTestRequestAction => {
    return {
      type: EnableUiTestActionTypes.GetEnableUiTestRequest,
      payload,
    };
  },
  getEnableUiTestSuccess: (
    payload: GetEnableUiTestSuccessPayload
  ): GetEnableUiTestSuccessAction => {
    return {
      type: EnableUiTestActionTypes.GetEnableUiTestSuccess,
      payload,
    };
  },
  getEnableUiTestError: ({ error }: GetEnableUiTestErrorPayload): GetEnableUiTestErrorAction => {
    return {
      type: EnableUiTestActionTypes.GetEnableUiTestError,
      payload: { error },
    };
  },
  updateEnableUiTestRequest: (
    payload: UpdateEnableUiTestRequestPayload
  ): UpdateEnableUiTestRequestAction => {
    return {
      type: EnableUiTestActionTypes.UpdateEnableUiTestRequest,
      payload,
    };
  },
  updateEnableUiTestSuccess: (
    payload: UpdateEnableUiTestSuccessPayload
  ): UpdateEnableUiTestSuccessAction => {
    return {
      type: EnableUiTestActionTypes.UpdateEnableUiTestSuccess,
      payload,
    };
  },
  updateEnableUiTestError: (
    payload: UpdateEnableUiTestErrorPayload
  ): UpdateEnableUiTestErrorAction => {
    return {
      type: EnableUiTestActionTypes.UpdateEnableUiTestError,
      payload,
    };
  },

  notifyIsDirtyState: (
    payload: NotifyEnableUiTestIsDirtyActionPayload
  ): NotifyEnableUiTestIsDirtyAction => {
    return {
      type: EnableUiTestActionTypes.NotifyEnableUiTestIsDirty,
      payload,
    };
  },
};

/* --= Methods =-- */
export const enableUiTestActionsMethods = {
  notifyIsDirty:
    ({ isDirty }: NotifyEnableUiTestIsDirtyActionPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(enableUiTestActions.notifyIsDirtyState({ isDirty }));
    },

  fetchEnableUiTest:
    ({ shopId, selectedVariantId }: GetEnableUiTestArgs) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(enableUiTestActions.getEnableUiTestRequest({ shopId }));
        const { enableUiTest } = await shopService.getEnableUiTest(shopId, selectedVariantId);
        dispatch(
          enableUiTestActions.getEnableUiTestSuccess({
            shopId,
            enableUiTest,
          })
        );
      } catch (error) {
        dispatch(enableUiTestActions.getEnableUiTestError({ error }));
      }
    },

  updateEnableUiTest:
    ({ shopId, selectedVariantId, enableUiTest }: UpdateEnableUiTestPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(
          enableUiTestActions.updateEnableUiTestRequest({
            shopId,
            enableUiTest,
          })
        );

        await shopService.updateEnableUiTest({
          shopId,
          selectedVariantId,
          enableUiTest,
        });

        dispatch(
          enableUiTestActions.updateEnableUiTestSuccess({
            shopId,
            enableUiTest,
          })
        );
      } catch (error) {
        dispatch(enableUiTestActions.updateEnableUiTestError({ error }));
      }
    },
};
