import React from 'react';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';
import {
  ConfirmationDialogTitleTextStyled,
  ConfirmationDialogHeaderStyled,
} from './UpdateModelConfirmationDialog.style';

interface UpdateModelConfirmationDialogProps {
  onClose: () => void;
  onUpdateModel: () => void;
  modelName: string | undefined;
  previousModel: string | undefined;
}

export const UpdateModelConfirmationDialog = ({
  onUpdateModel,
  onClose,
  modelName,
  previousModel,
}: UpdateModelConfirmationDialogProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onClose}>
      <ConfirmationDialogHeaderStyled>
        <ConfirmationDialog.Title>
          <ConfirmationDialogTitleTextStyled>
            {modelName ? `Update Shop Model from "${previousModel}" to "${modelName}"` : ''}
          </ConfirmationDialogTitleTextStyled>
        </ConfirmationDialog.Title>
      </ConfirmationDialogHeaderStyled>
      <ConfirmationDialog.Content>
        Updating the shop model will affect the lexicons connected to this shop and may lead to
        issues such as invalid tags or unexpected responses for deep tagging, text search filters
        and more!
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onUpdateModel}>
            Update
          </Button>
          <Button variant='outlined' onClick={onClose}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
