import { AvailableIcons } from 'src/components-dummy';
import { DomainEntityPath } from 'src/services';
import { UseKnn } from 'src/services/src/service/types/shops/shop';
import { DomainGroup, SubDomainGroupBase } from '../../types';
import { useKnnDiffSchema } from './use-knn-diff-schema';

export const useKnnDomainGroup: DomainGroup & SubDomainGroupBase<UseKnn> = {
  domainKey: DomainEntityPath.UseKnn,
  title: 'Use OpenSearch KNN',
  icon: AvailableIcons.ThematicTag,
  getCardTitle: ({ entityPath }) => {
    const titleResult = [''];
    switch (entityPath) {
      case 'useKnn': {
        titleResult.push('Use OpenSearch KNN');
        break;
      }
      default:
        break;
    }
    return titleResult.join('');
  },
  fieldsSchema: useKnnDiffSchema,
};
