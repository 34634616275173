import React, { useCallback, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { Dispatch } from 'src/components-bl';
import {
  AvailableIcons,
  Button,
  DialogModalHeader,
  DialogModalTitle,
  Icon,
  NotificationType,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { useAnimateNumber } from 'src/hooks/useAnimateNumber';
import { downloadFile } from 'src/utils/download-file';
import { UploadLexiconFileButton } from './components';
import { importLexiconModalActions } from './ImportLexiconModal.actions';
import {
  DialogModalContentStyled,
  DialogModalFooterStyled,
  DownloadExampleLinkStyled,
  ImportLexiconModalStyled,
} from './ImportLexiconModal.styles';

const exampleFilePath = `${process.env.PUBLIC_URL}/files/lexicon/import-file-example.xlsx`;

export const IMPORT_PROGRESS_CANCELLATION_THRESHOLD = 90;

export interface ImportLexiconModalProps {
  shopId: number;
  locale: string;
  progress?: number;
  dispatch: Dispatch;
  onClose: () => void;
  onSuccess: () => void;
}

export function ImportLexiconModal({
  shopId,
  locale,
  progress = 0,
  dispatch,
  onClose,
  onSuccess,
}: ImportLexiconModalProps): JSX.Element {
  const [lexiconFiles, setLexiconFiles] = useState<File[]>([]);
  const [isImportInProgress, setIsImportInProgress] = useState(false);

  const importCancellationRef = useRef(null as AbortController | null);

  const count = useAnimateNumber({ totalSteps: 100, value: progress, initialValue: 0 });

  const prohibitCancelImport = count >= IMPORT_PROGRESS_CANCELLATION_THRESHOLD;

  const isFileSelected = Boolean(lexiconFiles[0]);

  const onCancelImport = useCallback(() => {
    if (prohibitCancelImport) {
      return;
    }

    if (importCancellationRef.current) {
      try {
        importCancellationRef.current.abort();
      } catch (error) {
        console.error(error);
      }
    }

    importCancellationRef.current = null;
    setIsImportInProgress(false);
    onClose();
  }, [setIsImportInProgress, onClose]);

  const onSubmit = useCallback(async () => {
    setIsImportInProgress(true);

    importCancellationRef.current = new AbortController();

    try {
      const { isPartialImport } = await (
        dispatch(
          importLexiconModalActions.importLexicon({
            shopId,
            locale,
            requestId: v4().toString(),
            lexiconFile: lexiconFiles[0],
            cancellationSignal: importCancellationRef.current.signal,
          })
        ) as any
      ).unwrap();

      const toastNotification = isPartialImport
        ? {
            type: NotificationType.Warning,
            customMessage:
              'The file was imported. Import issues appear in the file that was downloaded',
          }
        : {
            type: NotificationType.Success,
            customMessage: 'The file was imported successfully',
          };
      dispatch(importLexiconModalActions.notification(toastNotification));
    } catch ({ error }) {
      if (error) {
        const missingHeaders = (error as any).data?.missingHeaders?.join(',') || null;
        const messageExplanation = missingHeaders
          ? `the following headers are missing: ${missingHeaders}`
          : 'please check the file format';

        dispatch(
          importLexiconModalActions.notification({
            type: NotificationType.Error,
            customMessage: `The file could not be imported, ${messageExplanation}`,
          })
        );
      }
      onClose();
    }
  }, [shopId, locale, lexiconFiles, dispatch, onSuccess]);

  const onDownloadExampleClick = useCallback(() => {
    downloadFile({
      filePath: exampleFilePath,
      fileDisplayName: 'lexicon-import-file-example.xlsx',
    });
  }, []);

  useEffect(() => {
    if (count >= 100) {
      setIsImportInProgress(false);
      onSuccess();
    }
  }, [count]);

  return (
    <ImportLexiconModalStyled open onClose={prohibitCancelImport ? undefined : onCancelImport}>
      <>
        <DialogModalHeader>
          <DialogModalTitle>Import Lexicon File</DialogModalTitle>
        </DialogModalHeader>
        <DialogModalContentStyled>
          <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Paragraph}>
            Upload a lexicon file to update the content of this lexicon locale
          </Typography>

          <Typography variant={TypographyVariant.MediumMedium} type={TypographyType.Body}>
            Upload XLSX file
          </Typography>

          <UploadLexiconFileButton lexiconFiles={lexiconFiles} onUpload={setLexiconFiles} />

          <DownloadExampleLinkStyled onClick={onDownloadExampleClick}>
            <Icon name={AvailableIcons.Download} />
            <Typography variant={TypographyVariant.MediumMedium} type={TypographyType.Body}>
              Download XLSX example
            </Typography>
          </DownloadExampleLinkStyled>
        </DialogModalContentStyled>
        <DialogModalFooterStyled>
          <Button
            variant='primary'
            onClick={onSubmit}
            loading={isImportInProgress}
            progress={`${count}%`}
            disabled={!isFileSelected}
          >
            Import File
          </Button>
          <Button variant='tertiary' onClick={onCancelImport} disabled={prohibitCancelImport}>
            Cancel
          </Button>
        </DialogModalFooterStyled>
      </>
    </ImportLexiconModalStyled>
  );
}
