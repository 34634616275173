export enum SelectExperimentStatus {
  All = 'all',
  Created = 'created',
  Running = 'running',
  Ended = 'ended',
}

export const sortDropdownOptions = [
  { value: SelectExperimentStatus.All, text: 'All' },
  { value: SelectExperimentStatus.Created, text: 'Created' },
  { value: SelectExperimentStatus.Running, text: 'Running' },
  { value: SelectExperimentStatus.Ended, text: 'Ended' },
];
