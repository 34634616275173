import {
  GetUiTemplateActionPayload,
  UiTemplateActionPayload,
  UiTemplateActionTypes,
  UiTemplateDeleteErrorAction,
  UiTemplateDeleteRequestAction,
  UiTemplateDeleteSuccessAction,
  UiTemplateDeleteSuccessActionPayload,
  UiTemplateErrorActionPayload,
  UiTemplateGetErrorAction,
  UiTemplateGetRequestAction,
  UiTemplateGetSuccessAction,
  UiTemplateSaveErrorAction,
  UiTemplateSaveRequestAction,
  UiTemplateSaveSuccessAction,
  UiTemplateSuccessActionPayload,
} from './types';
import { Dispatch } from '../../../types';
import { uiTemplatesService } from '../../../../services/src/service/ui-templates';

const uiTemplateActions = {
  getUiTemplateRequest(payload: GetUiTemplateActionPayload): UiTemplateGetRequestAction {
    return {
      type: UiTemplateActionTypes.GetUiTemplateRequest,
      payload,
    };
  },
  getUiTemplateSuccess(payload: UiTemplateSuccessActionPayload): UiTemplateGetSuccessAction {
    return {
      type: UiTemplateActionTypes.GetUiTemplateSuccess,
      payload,
    };
  },
  getUiTemplateError(payload: UiTemplateErrorActionPayload): UiTemplateGetErrorAction {
    return {
      type: UiTemplateActionTypes.GetUiTemplateError,
      payload,
    };
  },

  saveUiTemplateRequest(payload: UiTemplateActionPayload): UiTemplateSaveRequestAction {
    return {
      type: UiTemplateActionTypes.SaveUiTemplateRequest,
      payload,
    };
  },
  saveUiTemplateSuccess(payload: UiTemplateSuccessActionPayload): UiTemplateSaveSuccessAction {
    return {
      type: UiTemplateActionTypes.SaveUiTemplateSuccess,
      payload,
    };
  },
  saveUiTemplateError(payload: UiTemplateErrorActionPayload): UiTemplateSaveErrorAction {
    return {
      type: UiTemplateActionTypes.SaveUiTemplateError,
      payload,
    };
  },

  deleteUiTemplateRequest(payload: UiTemplateActionPayload): UiTemplateDeleteRequestAction {
    return {
      type: UiTemplateActionTypes.DeleteUiTemplateRequest,
      payload,
    };
  },
  deleteUiTemplateSuccess(
    payload: UiTemplateDeleteSuccessActionPayload
  ): UiTemplateDeleteSuccessAction {
    return {
      type: UiTemplateActionTypes.DeleteUiTemplateSuccess,
      payload,
    };
  },
  deleteUiTemplateError(payload: UiTemplateErrorActionPayload): UiTemplateDeleteErrorAction {
    return {
      type: UiTemplateActionTypes.DeleteUiTemplateError,
      payload,
    };
  },
};

export const uiTemplateActionMethods = {
  getUiTemplate(payload: GetUiTemplateActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(uiTemplateActions.getUiTemplateRequest({ ...payload }));
        const template = await uiTemplatesService.getUiTemplate({ ...payload });
        dispatch(uiTemplateActions.getUiTemplateSuccess(template));
      } catch (error) {
        dispatch(uiTemplateActions.getUiTemplateError({ error }));
      }
    };
  },

  saveUiTemplate(payload: UiTemplateActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(uiTemplateActions.saveUiTemplateRequest({ ...payload }));
        const template = await uiTemplatesService.saveUiTemplate({ ...payload });
        dispatch(uiTemplateActions.saveUiTemplateSuccess(template));
      } catch (error) {
        dispatch(uiTemplateActions.saveUiTemplateError({ error }));
      }
    };
  },

  deleteUiTemplate(payload: UiTemplateActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(uiTemplateActions.deleteUiTemplateRequest({ ...payload }));
        const templates = await uiTemplatesService.deleteUiTemplate({ ...payload });
        dispatch(uiTemplateActions.deleteUiTemplateSuccess({ templates }));
      } catch (error) {
        dispatch(uiTemplateActions.deleteUiTemplateError({ error }));
      }
    };
  },
};
