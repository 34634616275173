import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch } from 'src/components-bl/types';
import {
  AvailableIcons,
  TabsV2,
  TabV2,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { Notification } from 'src/services';
import {
  CloseIconStyled,
  HorizontalSeparatorStyled,
  MarkAllAsReadButtonStyled,
  NotificationsPopupContentStyled,
  NotificationsPopupHeaderStyled,
  NotificationsTabStyled,
  PopoverStyled,
} from './NotificationsPopup.styles';
import { notificationsPopupActions } from './notificationsPopup.actions';

enum TabNames {
  All = 0,
  Unread = 1,
}

const tabTranslationMap = {
  [TabNames.All]: 'All',
  [TabNames.Unread]: 'Unread',
};

const tabNames = Object.values(tabTranslationMap);

interface NotificationsPopupProps extends RoutedComponentProps {
  shopId?: number;
  notifications?: Notification[];
  unreadNotifications?: Notification[];
  popoverAnchorElement: HTMLDivElement | null;
  onClose: () => void;
  dispatch: Dispatch;
  unreadCount?: number;
}

export function NotificationsPopup({
  shopId,
  notifications,
  unreadNotifications,
  popoverAnchorElement,
  onClose,
  dispatch,
  unreadCount,
  permittedRouteMap,
}: NotificationsPopupProps): JSX.Element {
  const [selectedTab, setSelectedTab] = useState(TabNames.All);

  const hasUnreadMessages = !!(unreadCount && unreadCount > 0);

  const onMarkAllAsReadClick = useCallback(() => {
    if (hasUnreadMessages) {
      dispatch(notificationsPopupActions.markAllAsRead({ shopId }));
    }
  }, [dispatch, shopId, hasUnreadMessages]);

  const onTabChange = useCallback(
    (event: SyntheticEvent, targetIndex: number) => {
      setSelectedTab(targetIndex);
    },
    [setSelectedTab]
  );

  const tabs = useMemo(
    () =>
      tabNames.map(tabName => {
        const tabLabel = (
          <Typography type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
            {tabName}
          </Typography>
        );
        return <TabV2 key={tabName} label={tabLabel} />;
      }),
    []
  );

  const open = Boolean(popoverAnchorElement);

  return (
    <PopoverStyled
      open={open}
      onClose={onClose}
      anchorEl={popoverAnchorElement}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <NotificationsPopupContentStyled>
        <NotificationsPopupHeaderStyled>
          <Typography type={TypographyType.Body} variant={TypographyVariant.LargeMedium}>
            Notifications
          </Typography>
          <MarkAllAsReadButtonStyled
            variant='tertiary'
            disabled={!hasUnreadMessages}
            onClick={onMarkAllAsReadClick}
          >
            Mark all as read
          </MarkAllAsReadButtonStyled>
          <CloseIconStyled name={AvailableIcons.Close} onClick={onClose} />
        </NotificationsPopupHeaderStyled>
        <TabsV2 value={selectedTab} onChange={onTabChange}>
          {tabs}
        </TabsV2>
        <HorizontalSeparatorStyled />
        {selectedTab === TabNames.All && (
          <NotificationsTabStyled
            shopId={shopId}
            notifications={notifications}
            emptyNotificationsMessage='Hooray! You have no new messages &#129395;'
            dispatch={dispatch}
            permittedRouteMap={permittedRouteMap}
            onClosePopup={onClose}
          />
        )}
        {selectedTab === TabNames.Unread && (
          <NotificationsTabStyled
            shopId={shopId}
            notifications={unreadNotifications}
            emptyNotificationsMessage='Hooray! You have no unread messages &#129395;'
            dispatch={dispatch}
            permittedRouteMap={permittedRouteMap}
            onClosePopup={onClose}
          />
        )}
      </NotificationsPopupContentStyled>
    </PopoverStyled>
  );
}
