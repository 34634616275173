import React, { useCallback, useImperativeHandle } from 'react';
import { generatePath } from 'react-router-dom';
import { MerchandisingRuleTypes } from 'src/services';
import { NotificationCardMessageTypography } from '../common';
import { NotificationCardMessageProps } from '../types';

export function AutomaticRedirectRulesNotPublishedMessage({
  shopId,
  permittedRouteMap,
  navigateTo,
  cardRef,
}: NotificationCardMessageProps<{ rules: MerchandisingRuleTypes.MerchandiseRule[] }>): JSX.Element {
  const onNotificationClick = useCallback(() => {
    if (permittedRouteMap.merchandisingRules?.path) {
      navigateTo(
        generatePath(permittedRouteMap.merchandisingRules.path, {
          shopId,
        })
      );
    }
  }, [shopId, navigateTo, permittedRouteMap.merchandisingRules?.path]);

  useImperativeHandle(cardRef, () => ({
    onNotificationClick,
  }));

  return (
    <NotificationCardMessageTypography>
      New redirect rules were added.
      <br />
      Click to review the new rules added, and publish it.
    </NotificationCardMessageTypography>
  );
}
