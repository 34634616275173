import React, { useMemo } from 'react';
import { ILocationUser } from 'src/services';
import { UserAvatarListStyled } from './UserAvatarList.styles';
import { CollapsedUserCountIndication, UserThumbnailsList, sortUsers } from './components';
import { MAX_USERS_COUNT_TO_SHOW, headerUserThumbnailSize } from './constants';
import { Dispatch } from '../types';

export type IUserAvatarListProps = {
  myProfilePath: string | undefined;
  dispatch: Dispatch;
  users: ILocationUser[];
  isOnline?: boolean;
};

export const UserAvatarList = ({
  dispatch,
  myProfilePath,
  users,
  isOnline,
}: IUserAvatarListProps): JSX.Element => {
  const {
    sorted: allUsersSorted,
    hasMore: hasMoreUsers,
    toShow: usersToShow,
    containerSize: wrapperSize,
    shouldCollapse: shouldCollapseUsers,
  } = useMemo(() => {
    const sorted = sortUsers(users);
    const toShow = [...sorted].splice(0, MAX_USERS_COUNT_TO_SHOW);

    const hasMore = sorted.length > 1;

    const moreUsersCount = sorted.length - 1;

    const shouldCollapse = moreUsersCount >= MAX_USERS_COUNT_TO_SHOW;

    const containerSize = shouldCollapse
      ? headerUserThumbnailSize * (toShow.length + 1)
      : headerUserThumbnailSize * toShow.length;

    return { sorted, hasMore, toShow, containerSize, shouldCollapse };
  }, [users]);

  return (
    <div className='syte-profile-menu'>
      <UserAvatarListStyled width={wrapperSize}>
        <UserThumbnailsList
          users={usersToShow}
          hasMoreUsers={hasMoreUsers}
          globalShouldShow
          isOnline={isOnline}
          dispatch={dispatch}
          myProfilePath={myProfilePath}
        />
        <CollapsedUserCountIndication
          hasMoreUsers={hasMoreUsers}
          positionIndex={usersToShow.length}
          users={allUsersSorted}
          show={shouldCollapseUsers}
        />
      </UserAvatarListStyled>
    </div>
  );
};
