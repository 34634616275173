import styled from '@emotion/styled';
import { Button, ConfirmationDialog, Icon, MenuItem } from 'src/components-dummy';
import { css } from '@emotion/react';

export const DeepTagsProductStyled = styled.div`
  height: 100%;
`;

export const EditableContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
  display: flex;
  background: ${({ theme }) => theme.palette.common.white};
  max-height: calc(100vh - 496px);
  & > div {
    height: fit-content;
    padding: 0 24px 12px 24px;
    overflow: auto;
  }
`;

export const EditableContentWrapperStyled = styled.div<{ isInEditMode: boolean }>`
  padding: 12px 0px;
  border-radius: 6px;
  box-shadow:
    0px 2px 8px -2px rgba(0, 0, 0, 0.08),
    0px 2px 4px -2px rgba(0, 0, 0, 0.08);

  ${({ isInEditMode, theme }) =>
    isInEditMode &&
    css`
      border: 1px solid ${theme.palette.custom['primary-main']};
    `}
`;

export const MenuItemStyled = styled(MenuItem)<{ selected: boolean }>`
  ${({ selected, theme }) =>
    selected &&
    css`
      background-color: ${theme.palette.custom['primary-90']};
    `};
`;

export const ActionsAndParametersStyled = styled.section`
  padding: 16px 16px 8px 16px;
  display: flex;
  gap: 4px;
`;

export const MainStyled = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ButtonWithIconWrapper = styled(Button)`
  span {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;

export const ButtonEditStyled = styled(ButtonWithIconWrapper)<{ isInEditMode: boolean }>`
  ${({ isInEditMode, theme }) =>
    isInEditMode &&
    css`
      background: ${theme.palette.custom['primary-80']};
    `}
`;

export const EditableTextSectionStyled = styled.div`
  padding: 16px 16px 8px 16px;
`;

export const TabPanelActionsStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 16px 8px 16px;

  & > button {
    margin-left: 8px;
  }
`;

export const GenericContainerStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const BackIconStyled = styled(Icon)`
  width: 20px;
  height: 10px;
  transform: rotate(180deg);

  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-20']};
  }

  &:hover path {
    stroke: ${({ theme }) => theme.palette.common.black};
  }
`;

export const ContentPromptTextStyled = styled(ConfirmationDialog.Content)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;
