import React from 'react';
import styled from '@emotion/styled';
import { Typography, TypographyType, TypographyVariant } from '../../../../components-dummy';

const StyledTitleContainer = styled.div`
  display: flex;
  align-items: center;
  .rule-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 32ch;
  }
`;

export function MerchandisingRulesTitle({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <StyledTitleContainer>
      <Typography
        className='rule-title'
        type={TypographyType.Body}
        variant={TypographyVariant.SmallMedium}
      >
        {children}
      </Typography>
    </StyledTitleContainer>
  );
}
