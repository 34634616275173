import React, { useCallback } from 'react';
import { ShopFeature } from '../../../../app-types';
import { PreviewMerchRule } from '../../types';
import { PermittedRouteMap } from '../../../../app-routes';
import { EditStrategyContainer } from './EditStrategyContainer';
import { CollapseDrawer } from '../CollapseDrawer/CollapseDrawer';
import { useEditMerchRule } from '../../hooks';
import { useIsFeaturePermitted } from '../../../../hooks';
import { useEditStrategy } from '../../hooks/useEditStrategy';
import { useNavigateToVisualEditorRoute } from '../../hooks/useNavigateToVisualEditorRoute';
import { useGetSelectedMerchRule } from '../SideMenu/useGetSelectedMerchRule';

interface Props {
  shopId: number;
  permittedRouteMap: PermittedRouteMap;
  selectedRule: PreviewMerchRule | null;
  className: string;
}

export const EditStrategy = ({
  shopId,
  permittedRouteMap,
  selectedRule,
  className,
}: Props): JSX.Element | null => {
  const { entityId, merchRuleProduct } = useEditMerchRule(shopId, permittedRouteMap);

  const { selectedMerchRule } = useGetSelectedMerchRule({ selectedRule });

  const isMerchRulesAiTagsEnabled = useIsFeaturePermitted(ShopFeature.MerchandisingRulesAiTags);

  const { closeStrategyDrawer, submitStrategyForm } = useEditStrategy({
    shopId,
    selectedRule: selectedMerchRule,
    permittedRouteMap,
  });

  const { navigateToVisualEditorRoute } = useNavigateToVisualEditorRoute({
    route: permittedRouteMap?.visualEditor,
  });

  const closeWithNotification = useCallback(() => {
    if (!selectedMerchRule) return;
    navigateToVisualEditorRoute({ shopId });
  }, [selectedMerchRule, navigateToVisualEditorRoute, shopId]);

  if (!selectedMerchRule) {
    return null;
  }

  return (
    <CollapseDrawer open className={className} onClose={closeWithNotification}>
      <EditStrategyContainer
        shopId={shopId}
        entityId={entityId}
        rule={selectedMerchRule ?? undefined}
        predefinedProduct={merchRuleProduct}
        isAiTagsEnabled={isMerchRulesAiTagsEnabled}
        onSave={submitStrategyForm}
        onClose={closeStrategyDrawer}
      />
    </CollapseDrawer>
  );
};
