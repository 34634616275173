import styled from '@emotion/styled';
import { Icon } from 'src/components-dummy';

export const BackIconStyled = styled(Icon)`
  width: 10px;
  height: 10px;
  transform: rotate(180deg);
  margin-right: 20px;

  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-20']};
  }

  &:hover path {
    stroke: ${({ theme }) => theme.palette.common.black};
  }
`;
