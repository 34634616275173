import classNames from 'classnames';
import React from 'react';
import { TemplateProps } from '../types';

export interface FooterProps extends TemplateProps {
  className?: string;
}

export const DialogFooter = ({ children, className }: FooterProps): JSX.Element => (
  <div className={classNames('syte-dialog-footer', className)}>{children}</div>
);
