import React, { SyntheticEvent, useEffect, useRef } from 'react';
import './SKUInput.scss';
import { TextBox } from '../../../../components-dummy/TextBox';
import { Typography, TypographyType, TypographyVariant } from '../../../../components-dummy';
import { SkuState } from '../../../../components-bl/socialDiscovery/AddSKU/useSKUState/types';
import { SKUButton } from './SKUButton';

export type SKUInputProps = {
  skuState: // eslint-disable-next-line prettier/prettier
  SkuState.NoSku | SkuState.Loading | SkuState.Resolved | SkuState.HasSku | SkuState.Error;
  errorMessage: null | string;
  onSubmit: () => void;
  inputValue: string;
  onChange: (text: string) => void;
};

export const SKUInput = ({
  skuState,
  onSubmit,
  errorMessage,
  inputValue,
  onChange,
}: SKUInputProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    (inputRef.current?.firstElementChild as HTMLInputElement)?.focus();
  }, []);

  return (
    <div className='sku-input-container' onClick={(e: SyntheticEvent) => e.stopPropagation()}>
      <Typography type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
        SKU:
      </Typography>
      <form
        onSubmit={e => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <TextBox
          ref={inputRef}
          disabled={skuState === SkuState.Loading}
          value={inputValue}
          placeholder={skuState === SkuState.NoSku ? 'Enter Product SKU' : ''}
          onChange={onChange}
        />
      </form>
      <SKUButton skuState={skuState} errorMessage={errorMessage} onClick={onSubmit} />
    </div>
  );
};
