import React from 'react';
import { useFieldDropdown } from './useFieldDropdown';
import { Select, SelectType, MenuItem, SelectOnChangeEvent } from '../../../../../components-dummy';
import { Rule, RuleGroup } from '../../../types';
import { ParseJoiValidateResponse } from '../../../../../utils';
import { ShopDataField } from '../../../../../services';

interface FieldDropdownProps {
  data: Rule;
  onChange: (rule: Rule) => void;
  dataFields: ShopDataField[] | undefined;
  errors: ParseJoiValidateResponse<{ ruleGroups: RuleGroup[] }>;
}

export const FieldDropdown: React.FC<FieldDropdownProps> = ({
  data,
  onChange,
  dataFields,
  errors,
}) => {
  const { dataFieldsDropdownOptions, onFieldChange } = useFieldDropdown({
    data,
    onChange,
    dataFields,
  });

  const handleChange: SelectOnChangeEvent = event => {
    onFieldChange(event.target.value);
  };

  return (
    <Select
      type={SelectType.Menu}
      value={data.field}
      onChange={handleChange}
      disabled={!data?.fieldSource}
      className='syte-collection-rule-select'
      isError={Boolean(errors?.field && data?.fieldSource)}
    >
      {dataFieldsDropdownOptions.map((option: { value: string; text: string }) => (
        <MenuItem key={option.value} value={option.value}>
          {option.text}
        </MenuItem>
      ))}
    </Select>
  );
};
