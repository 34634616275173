import React, { useRef, useState } from 'react';
import { AutoCompleteOption, useDebounce } from 'src/components-dummy';
import { Dispatch } from 'src/components-bl';
import { dataFieldSingleAutoSuggestActions } from './DataFieldSingleAutoSuggest.actions';
import { AutoCompleteStyled } from './DataFieldSingleAutoSuggestion.styles';

interface DataFieldSingleAutoSuggestProps {
  error?: boolean;
  disabled?: boolean;
  onChange: (values: string[]) => void;
  selectedValue?: string;
  dispatch: Dispatch;
  shopId: number;
  dataFieldName?: string;
  hasTransparentBackground?: boolean;
}

export const DataFieldSingleAutoSuggestion = ({
  error,
  disabled,
  onChange,
  selectedValue,
  dispatch,
  dataFieldName,
  shopId,
  hasTransparentBackground,
}: DataFieldSingleAutoSuggestProps): JSX.Element => {
  const [options, setOptions] = useState<AutoCompleteOption[]>([]);
  const [textBoxValue, setTextBoxValue] = useState('');
  const inputRef = useRef();

  const fetchDataFieldOptionsDebounced = useDebounce((searchTerm: string) => {
    if (!dataFieldName) return;

    if (searchTerm.length < 2) {
      setOptions([]);
      return;
    }

    (
      dispatch(
        dataFieldSingleAutoSuggestActions.getValues({
          shopId,
          dataField: dataFieldName,
          searchTerm,
          limit: 50,
        })
      ) as any
    )
      .unwrap()
      .then((dataFieldOptions: string[]) => {
        setOptions(
          dataFieldOptions.map(option => {
            return {
              value: option,
              title: option,
            };
          })
        );
      })
      .catch((apiError: Error) => {
        console.error(apiError);
        setOptions([]);
      });
  }, 500);

  const onTextBoxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const searchValue = event.target.value;
    const searchValueTrimmed = event.target.value.trim();
    setTextBoxValue(searchValue);

    fetchDataFieldOptionsDebounced(searchValueTrimmed);
  };

  return (
    <AutoCompleteStyled
      className='merchandising-rule-single-select-autocomplete'
      hasTransparentBackground={hasTransparentBackground}
      errored={error}
      selectedValues={selectedValue as unknown as string[]}
      onChange={onChange}
      disabled={disabled}
      textBoxValue={textBoxValue}
      onTextBoxChange={onTextBoxChange}
      options={options}
      freeSolo
      multiple={false}
      autoFocus
      inputRef={inputRef}
    />
  );
};
