import React from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Page } from '../../components-dummy';
import { CollectionsHeader } from '../../components-bl';
import { CollectionsMain } from './components';
import { useCreateDefaultCollection } from '../CreateCollection/useCreateDefaultCollection';
import { useEditCollection } from '../EditCollection/useEditCollection';
import './Collections.scss';

export const Collections = () => {
  const dispatch = useAppDispatch();
  const shopId = useAppSelector(state => state.shop.current?.shopId);

  const { onCreateDefaultCollection } = useCreateDefaultCollection({
    shopId,
    dispatch,
  });

  const { onEditCollection } = useEditCollection({
    shopId,
    dispatch,
  });

  return shopId ? (
    <Page className='collections'>
      <Page.Header>
        <CollectionsHeader onCreateDefaultCollection={onCreateDefaultCollection} />
      </Page.Header>
      <Page.Content className='collections'>
        <CollectionsMain
          shopId={shopId}
          dispatch={dispatch}
          onCreateDefaultCollection={onCreateDefaultCollection}
          onEditCollection={onEditCollection}
        />
      </Page.Content>
    </Page>
  ) : null;
};
