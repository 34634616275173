import { ApiServiceBase } from '../api-service-base';
import { AIModel } from './types';

export class AIModelService extends ApiServiceBase {
  constructor() {
    super('ai-models');
  }

  private getUrl(): string {
    return `${this.serviceBaseUri}`;
  }

  async getAiModelsList(): Promise<AIModel[]> {
    const url = `${this.getUrl()}`;

    const response = await this.httpService.get({ url });

    return response.data.aiModels;
  }
}
