import React, { useMemo } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useAppSelector } from 'src/hooks';
import { DeepTagReportProductPageHeader } from 'src/components-bl';
import { RoutedComponentProps } from 'src/app-routes';
import { ProductRouteFilters } from './types';

interface ConnectedDeepTagReportProductsPageHeaderProps extends RoutedComponentProps {
  getCleanedFiltersWithPagination: () => ProductRouteFilters;
  getProductsWithFilters: () => void;
  lexiconSettings?: string[];
}
export const ConnectedDeepTagReportProductsPageHeader = ({
  permittedRouteMap,
  getCleanedFiltersWithPagination,
  getProductsWithFilters,
  lexiconSettings,
}: ConnectedDeepTagReportProductsPageHeaderProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const {
    selectedReport,
    shopId,
    languages,
    lexiconsMetadataList,
    totalPerProductLabel,
    generalConfigurationLabels,
    deepTagContentGenerationFeature,
    userRole,
  } = useAppSelector(state => {
    return {
      selectedReport: state.deepTagReports.selectedReport,
      totalPerProductLabel:
        state.deepTagReports.productPagination?.data?.availableFilterKeysMap?.labels || {},
      shopId: state.shop.current?.shopId,
      languages: state.lexicon.allLexiconSupportedLanguages, // in case the deep tag report uses locale of a disabled lexicon
      lexiconsMetadataList: state.lexicon.lexiconsMetadataList,
      generalConfigurationLabels: state.deepTagReports.generalConfigurationLabels,
      paginationFilters: state.deepTagReports.paginationFilters,
      deepTagContentGenerationFeature: state.shop?.featureToggles?.deepTagReportsContentGeneration,
      userRole: state.global.loggedInUser?.role,
    };
  }, shallowEqual);

  const isContentGenerationPermitted = !!(
    userRole &&
    deepTagContentGenerationFeature?.enabled &&
    deepTagContentGenerationFeature?.permittedRoles?.includes(userRole)
  );

  const localeToDisplayNameMap = useMemo(() => {
    const initialMap = {} as Record<string, string>;
    return (
      languages?.reduce((accumulator, language) => {
        accumulator[language.locale] = language.displayName;
        return accumulator;
      }, initialMap) || initialMap
    );
  }, [languages]);

  const supportedLanguagesInShop = useMemo(() => {
    const lexiconSettingsSet = new Set(lexiconSettings || []);

    return languages?.filter(language => lexiconSettingsSet.has(language.locale));
  }, [lexiconSettings, languages]);

  const isLexiconEnabled = useMemo(() => {
    if (!selectedReport || !lexiconsMetadataList) {
      return undefined;
    }

    return !!lexiconsMetadataList.find(metadata => metadata.locale === selectedReport.locale);
  }, [selectedReport, lexiconsMetadataList]);

  return selectedReport && shopId ? (
    <DeepTagReportProductPageHeader
      shopId={shopId}
      reportId={selectedReport.id}
      reportCustomName={selectedReport.customName}
      sourceFileName={selectedReport.sourceFileName}
      status={selectedReport.status}
      totalProducts={selectedReport.totalProducts}
      locale={selectedReport.locale}
      supportedLanguagesInShop={supportedLanguagesInShop}
      createdAt={selectedReport.createdAt}
      dispatch={dispatch}
      generalConfigurationLabels={generalConfigurationLabels}
      localeToDisplayNameMap={localeToDisplayNameMap}
      permittedRouteMap={permittedRouteMap}
      isLexiconEnabled={isLexiconEnabled}
      totalPerProductLabel={totalPerProductLabel}
      getCleanedFiltersWithPagination={getCleanedFiltersWithPagination}
      getProductsWithFilters={getProductsWithFilters}
      isContentGenerationPermitted={isContentGenerationPermitted}
    />
  ) : null;
};
