import React, { useCallback, useMemo } from 'react';
import { getStringEnumValues, ParseJoiValidateResponse } from 'src/utils';
import {
  ErrorLabel,
  MenuItem,
  Select,
  SelectOnChangeEvent,
  SelectType,
  TextBox,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { GalleryType } from 'src/services';
import { galleryTypeTranslations } from '../../../constants';
import { GalleryDetailsDraft } from '../../types';
import { SectionBoxStyled } from '../shared.styles';
import { GalleryDetailsField } from './GalleryDetailsField';

interface GalleryDetailsSectionProps {
  data: GalleryDetailsDraft;
  disabled: boolean;
  errors: ParseJoiValidateResponse<GalleryDetailsDraft>;
  onChange: (updatedData: Partial<GalleryDetailsDraft>) => void;
}

export function GalleryDetailsSection({
  data,
  disabled,
  errors,
  onChange,
}: GalleryDetailsSectionProps): JSX.Element {
  const galleryTypeOptions = useMemo(() => {
    return getStringEnumValues(GalleryType).map(galleryType => (
      <MenuItem key={galleryType} value={galleryType}>
        {galleryTypeTranslations[galleryType]}
      </MenuItem>
    ));
  }, []);

  const onGalleryNameChange = useCallback(
    (galleryName: string) => {
      onChange({ galleryName });
    },
    [onChange]
  );

  const onGalleryTypeChange: SelectOnChangeEvent = useCallback(
    event => {
      onChange({ galleryType: event.target.value as GalleryType });
    },
    [onChange]
  );

  const doesGalleryNameHaveErrors = Boolean(errors.galleryName);

  return (
    <SectionBoxStyled>
      <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
        Gallery Details
      </Typography>
      <GalleryDetailsField>
        <GalleryDetailsField.LabelText>Gallery name</GalleryDetailsField.LabelText>
        <TextBox
          disabled={disabled}
          error={doesGalleryNameHaveErrors}
          onChange={onGalleryNameChange}
          value={data.galleryName}
        />
        {doesGalleryNameHaveErrors && <ErrorLabel value={errors?.galleryName?.message} />}
      </GalleryDetailsField>
      <GalleryDetailsField>
        <GalleryDetailsField.LabelText>Gallery type</GalleryDetailsField.LabelText>
        <Select
          disabled={disabled}
          errorMessage={errors?.galleryType?.message}
          isError={Boolean(errors.galleryType)}
          onChange={onGalleryTypeChange}
          placeholder='Select type'
          type={SelectType.Primary}
          value={data.galleryType}
        >
          {galleryTypeOptions}
        </Select>
      </GalleryDetailsField>
    </SectionBoxStyled>
  );
}
