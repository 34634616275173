import styled from '@emotion/styled';
import { Radio } from '@mui/material';

export const RadioButtonStyled = styled(Radio)`
  &:hover {
    .MuiSvgIcon-root {
      fill: ${({ theme }) => theme.palette.custom['gray-10']};
    }
  }

  &.Mui-checked {
    .MuiSvgIcon-root {
      fill: ${({ theme }) => theme.palette.custom['primary-main']} !important;
    }
  }

  &.Mui-disabled {
    .MuiSvgIcon-root {
      fill: ${({ theme }) => theme.palette.custom['gray-60']} !important;
    }
  }
  .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.palette.custom['gray-20']};

    width: 16px;
    height: 16px;
  }
`;
