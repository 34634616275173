import { DomainEntityPath, MerchandisingRuleTypes } from '../types';
import * as mapper from './mapper';
import { DomainEventsWebSocketServiceBase, DomainEvent } from '../domain-events';
import { ClearListener } from '../web-socket.service';

export enum MerchandisingRuleEventTopicNames {
  Create = 'merchandising-rule:create',
  Delete = 'merchandising-rule:delete',
  Update = 'merchandising-rule:update',
  BulkUpdate = 'merchandising-rule:bulk-update',
}

type MerchandisingRuleUpdateEventPayload = {
  rule: MerchandisingRuleTypes.MerchandiseRule;
  shopId: number;
  variantId?: string;
};

type MerchandisingRuleDeleteEventPayload = {
  ruleId: string;
  shopId: number;
  variantId?: string;
};

export type MerchandisingRuleEventPayload =
  | MerchandisingRuleUpdateEventPayload
  | MerchandisingRuleDeleteEventPayload;

export type MerchandisingRuleUpdateEvent = DomainEvent<
  MerchandisingRuleUpdateEventPayload,
  MerchandisingRuleEventTopicNames
>;

export type MerchandisingRulesBulkUpdateEvent = DomainEvent<
  Omit<MerchandisingRuleUpdateEventPayload, 'rule'>,
  MerchandisingRuleEventTopicNames
>;

export type MerchandisingRuleDeleteEvent = DomainEvent<
  MerchandisingRuleDeleteEventPayload,
  MerchandisingRuleEventTopicNames
>;

export class MerchandisingRulesWebSocketService extends DomainEventsWebSocketServiceBase<MerchandisingRuleEventTopicNames> {
  domainEntity = DomainEntityPath.MerchandisingRules;

  subscribeToRuleCreateEvents(
    onRuleCreate: (payload: MerchandisingRuleUpdateEvent) => void
  ): ClearListener {
    return this.subscribeToEvents({
      topicKey: MerchandisingRuleEventTopicNames.Create,
      callback: (response: MerchandisingRuleUpdateEvent) => {
        const mappedRule = mapper.mapAPIRule(response.data.rule as any);

        response.data.rule = mappedRule;

        onRuleCreate(response);
      },
    });
  }

  subscribeToRuleUpdateEvents(
    onRuleUpdate: (payload: MerchandisingRuleUpdateEvent) => void
  ): ClearListener {
    return this.subscribeToEvents({
      topicKey: MerchandisingRuleEventTopicNames.Update,
      callback: (response: MerchandisingRuleUpdateEvent) => {
        const mappedRule = mapper.mapAPIRule(response.data.rule as any);

        response.data.rule = mappedRule;

        onRuleUpdate(response);
      },
    });
  }

  subscribeToRuleDeleteEvents(
    onRuleDelete: (payload: MerchandisingRuleDeleteEvent) => void
  ): ClearListener {
    return this.subscribeToEvents({
      topicKey: MerchandisingRuleEventTopicNames.Delete,
      callback: onRuleDelete,
    });
  }

  subscribeToRulesBulkUpdateEvents(
    onUpdate: (payload: MerchandisingRulesBulkUpdateEvent) => void
  ): ClearListener {
    return this.subscribeToEvents({
      topicKey: MerchandisingRuleEventTopicNames.BulkUpdate,
      callback: onUpdate,
    });
  }
}

export const merchandisingRulesWebSocketService = new MerchandisingRulesWebSocketService();
