import classNames from 'classnames';
import React from 'react';
import { ComponentError } from '../types';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import './ErrorLabel.scss';

export interface ErrorLabelProps {
  value: ComponentError;
  className?: string;
}

export const ErrorLabel = ({ value, className }: ErrorLabelProps): JSX.Element => {
  const showMessage = value && typeof value === 'string';
  return showMessage ? (
    <Typography
      className={classNames('syte-error-label', className)}
      type={TypographyType.Body}
      variant={TypographyVariant.SmallRegular}
    >
      {value}
    </Typography>
  ) : (
    <></>
  );
};
