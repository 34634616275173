import React from 'react';
import { VisualEditorResultsToolbar } from '../VisualEditorResultsScreen/VisualEditorResultsToolbar';
import { NoResultsFound } from './NoResultsFound';
import { Collection } from '../../../Collections';
import { Layout } from '../../consts/layout';
import { VisualEditorContentResults } from '../VisualEditorResultsScreen/VisualEditorResultsScreen.styles';

interface NoResultsProps {
  shopId: number;
  collection: Collection | null;
  layout: Layout;
  onLayoutChange: (view: Layout) => void;
  onRefresh?: VoidFunction;
  itemsCount: number;
  totalItemsCount: number;
  disableRefresh: boolean;
  shouldDisplayItemsCount: boolean;
}

export const NoResults = ({
  shopId,
  collection,
  layout,
  onLayoutChange,
  onRefresh,
  itemsCount,
  totalItemsCount,
  disableRefresh,
  shouldDisplayItemsCount,
}: NoResultsProps): JSX.Element => {
  return (
    <VisualEditorContentResults>
      <VisualEditorResultsToolbar
        shopId={shopId}
        layout={layout}
        onRefresh={onRefresh}
        itemsCount={itemsCount}
        totalItemsCount={totalItemsCount}
        disabled={disableRefresh}
        onLayoutChange={onLayoutChange}
        shouldDisplayItemsCount={shouldDisplayItemsCount}
      />
      <NoResultsFound shopId={shopId} collectionId={collection?.id} />
    </VisualEditorContentResults>
  );
};
