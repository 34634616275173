import React, { useState } from 'react';
import { Button, Menu } from 'src/components-dummy';
import { MenuItemStyled } from './DeepTagsGenerativeAIForm.style';

interface GenerativeAIParameterMenuProps {
  label: string;
  options: Array<{ value: string; text: string }>;
  disabled: boolean;
  selectedValue: string;
  onChange: (value: string) => void;
}

export const GenerativeAIParameterMenu = ({
  label,
  options,
  disabled,
  selectedValue,
  onChange,
}: GenerativeAIParameterMenuProps): JSX.Element => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);

  const menuOpened = !!menuAnchorEl;

  const onTriggerClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const onCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const onOptionClick = (value: string) => {
    onChange(value);
    setMenuAnchorEl(null);
    onCloseMenu();
  };

  return (
    <>
      <Button onClick={onTriggerClick} variant='secondary' size='tiny' disabled={disabled}>
        {label}
      </Button>
      <Menu
        open={menuOpened}
        anchorEl={menuAnchorEl}
        label={label}
        onClose={onCloseMenu}
        autoFocus={!disabled}
      >
        {options.map(option => (
          <MenuItemStyled
            selected={option.value === selectedValue}
            key={option.value}
            onClick={() => onOptionClick(option.value)}
          >
            {option.text}
          </MenuItemStyled>
        ))}
      </Menu>
    </>
  );
};
