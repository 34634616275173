import { DomainEvent } from '../domain-events';

export enum UserLocationEventTopicNames {
  SetUserLocation = 'user-location:set-user-location',
  LocationUpdate = 'user-location:location-update',
  UserOnlineStatusUpdate = 'user-location:online-status-update',
}

export interface ILocationUser {
  email: string;
  firstName: string;
  lastName: string;
  isEditing: boolean;
  userId: number;
  isMe: boolean;
}

export type IUserLocationUpdateEventData = {
  locationHash: string;
  users: ILocationUser[];
};

export type IUserLocationUpdateEvent = DomainEvent<
  IUserLocationUpdateEventData,
  UserLocationEventTopicNames
>;

export type IUserOnlineUpdateEventData = {
  userId: number;
  online: boolean;
};

export type IUserOnlineUpdateEvent = DomainEvent<
  IUserOnlineUpdateEventData,
  UserLocationEventTopicNames
>;

export interface IUserLocation {
  uri: string;
  isEditing: boolean;
  hash: string;
  context: Partial<{
    accountId: number;
    shopId: number;
  }>;
}
