import React from 'react';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog';
import { Button } from 'src/components-dummy/Button';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { RemoveTagSubTitleStyled } from './DeepTagsMainListForm/DeepTagProductTags/DeepTagProductTags.style';

interface RemoveTagModalProps {
  onCancel: () => void;
  onRemoveTag: () => void;
}

export const RemoveTagModal = ({ onCancel, onRemoveTag }: RemoveTagModalProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onCancel}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Remove tag?</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        <Typography type={TypographyType.Body} variant={TypographyVariant.LargeMedium}>
          Are you sure you want to remove this tag?
        </Typography>
        <RemoveTagSubTitleStyled
          type={TypographyType.Paragraph}
          variant={TypographyVariant.LargeRegular}
        >
          You can always reset the changes by clicking on the Reset changes button
        </RemoveTagSubTitleStyled>
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <Button variant='destructive' onClick={onRemoveTag}>
          Remove tag
        </Button>
        <Button variant='tertiary' onClick={onCancel}>
          Close
        </Button>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
