import React, { useMemo } from 'react';
import { UserTypes } from 'src/services';
import { RoleBasedSideNav } from 'src/components-bl';
import { useAppSelector, useAppDispatch } from 'src/hooks';
import { Dispatch } from 'src/components-bl/types';
import { PermittedRouteMap } from 'src/app-routes';
import {
  buildSideNavRoutes as buildAppSideNavConfig,
  convertToSideNavItems,
} from './side-nav-routes';

interface SideNavContainerProps {
  permittedRouteMap: PermittedRouteMap;
  role: UserTypes.UserRoles;
}

export const SideNavContainer = ({
  role,
  permittedRouteMap,
}: SideNavContainerProps): JSX.Element => {
  const location = useAppSelector(state => state.router.location);
  const shops = useAppSelector(state => state.shop.shops);
  const current = useAppSelector(state => state.shop.current);
  const isShopLoading = useAppSelector(state => state.shop.isShopLoading);
  const featureToggles = useAppSelector(state => state.shop.featureToggles);

  const sideNavConfig = useMemo(
    () => buildAppSideNavConfig(permittedRouteMap, featureToggles),
    [permittedRouteMap, featureToggles]
  );

  const dispatch: Dispatch = useAppDispatch();

  const permittedAccountRoutes = useMemo(
    () => convertToSideNavItems(sideNavConfig.account),
    [permittedRouteMap]
  );

  const permittedShopRoutes = useMemo(
    () =>
      current?.shopId ? convertToSideNavItems(sideNavConfig.shop, { shopId: current?.shopId }) : [],
    [permittedRouteMap]
  );

  return (
    <RoleBasedSideNav
      location={location}
      selectedShop={current}
      shops={shops || []}
      role={role}
      accountRoutes={permittedAccountRoutes}
      shopRoutes={permittedShopRoutes}
      dispatch={dispatch}
      isShopLoading={isShopLoading}
    />
  );
};
