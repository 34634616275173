import { AxiosResponse } from 'axios';
import { ShopTypes } from '../types';
import { parseDate } from '../../common/utils';

export class ShopsMapper {
  static map({ data }: AxiosResponse): ShopTypes.Shop {
    let shop;
    if (data.shopId || data.shopName) {
      shop = data;
    } else {
      shop = data.data.shop || data.data;
    }

    shop.updatedAt = parseDate(shop.updatedAt);
    shop.createdAt = parseDate(shop.createdAt);

    return shop;
  }
}
