import styled from '@emotion/styled';
import {
  AutoComplete,
  CardItem,
  Icon,
  PopUp,
  SearchInput,
  Select,
  Spinner,
  TextBoxBase,
  Typography,
} from 'src/components-dummy';

export const SearchInputStyled = styled(SearchInput)`
  margin-bottom: 8px;
  width: 100%;

  .input-base-wrapper,
  .input-base-wrapper: focus,
  .input-base-wrapper: focus-within {
    border: unset !important;
    outline: unset !important;

    background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  }
`;

export const NotFoundTypography = styled(Typography)`
  margin-top: 20px;
  text-align: center;
  color: ${({ theme }) => theme.palette.custom['gray-50']};
`;

export const ValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const SpinnerStyled = styled(Spinner)`
  position: absolute;
  top: 45%;
`;

export const BackdropStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.custom['gray-10']};
  opacity: 10%;
  top: 0;
  border-radius: 12px;
`;

export const AccountOptionStyled = styled.div<{ highlight?: boolean }>`
  width: 100%;
  height: 31px;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 2px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${({ highlight, theme }) => highlight && `color: ${theme.palette.custom['primary-main']};`}

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom['gray-90']};
    cursor: pointer;
  }
`;

export const PaginatedListStyled = styled(CardItem)`
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: flex-start;
  flex-direction: column;
  height: 394px;
  width: 520px;
  padding: 16px 12px;

  @media (max-height: 900px) {
    height: 293px;
  }
`;

export const SalesforceAccountAutocompleteStyled = styled(AutoComplete)`
  width: 100%;
  min-width: 35%;

  ${({ disabled, theme }) => !disabled && `color: ${theme.palette.common.black};`}
`;

export const SelectStyled = styled(Select)`
  width: 100%;
`;

export const TextBoxBaseStyled = styled(TextBoxBase)`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer;')};
  user-select: ${({ disabled }) => (disabled ? 'none' : 'initial;')};

  ${({ disabled, theme }) => (disabled ? `color: ${theme.palette.custom['gray-30']}` : '')};

  .input-base-wrapper {
    justify-content: space-between;
  }

  .input-base-wrapper:focus,
  .input-base-wrapper:focus-within {
    line-height: unset;
  }
`;

export const PlaceholderTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-30']};
  line-height: unset;
`;

export const AccountPreviewStyled = styled(Typography)`
  display: flex;
  line-height: unset;
  width: 100%;

  &:focus {
    line-height: unset;
  }

  .syte-popup-trigger {
    width: 100%;
  }
`;

export const CaretIconStyled = styled(Icon)`
  align-self: center;
  stroke: ${({ theme }) => theme.palette.custom['gray-30']};
`;

export const PopupStyled = styled(PopUp)`
  margin-top: 4px !important;
`;
