import { Shop } from '../shops/shop';
import { User } from '../users/user';
import { AccountBase } from './account-base';

export interface Account extends AccountBase {
  accountStatus: AccountStatus;
  shops: Shop[];
  createdAt: Date;
  users?: User[];
  twoFactorAuthentication: TwoFactorAuthenticationConfig;
}

export interface AccountSearchResult extends AccountBase {
  accountStatus: AccountStatus;
  twoFactorAuthentication: TwoFactorAuthenticationConfig;
  createdAt: Date;
}

export enum AccountStatus {
  Active = 1,
  Disabled,
  Archived,
}

export interface TwoFactorAuthenticationConfig {
  enabled: boolean;
}

export interface SearchAccountsArgs {
  searchTerm: string;
  includeArchived?: boolean;
  skip?: number;
  limit?: number;
}

export interface UpdateAccountArgs {
  accountId: number;
  data: Partial<Omit<Account, 'shops' | 'users' | 'createdAt'>>;
}
