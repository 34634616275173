import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { MerchandisingRules, dataFieldsService, FieldType } from 'src/services';
import { RulePages } from './MerchandisingRulesManagement.config';

const merchandisingRulesService = new MerchandisingRules();

/**
 * Actions
 */
export const merchandisingRulesManagementActions = {
  changePage: createAction<{ page: RulePages }>('MerchandisingRulesManagement/ChangePage'),
  getDataFields: createAsyncThunk(
    'MerchandisingRulesManagement/GetDataFields',
    async (
      { shopId, fieldType }: { shopId: number; fieldType?: FieldType },
      { rejectWithValue }
    ) => {
      try {
        const dataFields = await dataFieldsService.getDataFields({ shopId, fieldType });
        return { dataFields };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getRule: createAsyncThunk(
    'MerchandisingRulesManagement/GetRule',
    async (
      {
        shopId,
        ruleId,
        variantId,
      }: { shopId: number; ruleId: string; variantId: string | undefined },
      { rejectWithValue }
    ) => {
      try {
        const rule = await merchandisingRulesService.getRule({ shopId, ruleId, variantId });

        return { rule };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
