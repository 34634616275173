import { DomainEntityPath } from 'src/services';
import { DomainNodeBase } from '../types';
import { domainGroupsSettings } from './domain-groups';

export * from './value-helpers';

const domainGroupsSettingsMap = domainGroupsSettings.reduce(
  (accumulator, domainSettings) => {
    accumulator[domainSettings.domainKey] = domainSettings;
    return accumulator;
  },
  {} as { [key in DomainEntityPath]: DomainNodeBase }
);

export function getDomainGroupDiffSettings(
  domainGroupKey: DomainEntityPath
): DomainNodeBase | null {
  const group = domainGroupsSettingsMap[domainGroupKey] || null;
  return group;
}
