import styled from '@emotion/styled';
import { Button, TextBox, Typography } from 'src/components-dummy';

export const TypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const ButtonStyled = styled(Button)`
  margin-left: 5px;
`;

export const MultilineTextBoxStyled = styled(TextBox)<{ shouldStyle: boolean }>`
  margin-top: 24px;

  ${({ shouldStyle, theme }) => {
    if (shouldStyle) {
      return `.MuiInput-input {
        color: ${theme.palette.custom['primary-main']};
        text-decoration: underline;
      }`;
    }
    return '';
  }}
`;
