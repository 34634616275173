import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RoutedComponentProps } from 'src/app-routes';
import { generatePath, useParams } from 'react-router';
import { ThematicTagModal } from 'src/components-bl/Lexicon/components/ThematicTags/ThematicTagModal';
import { ILexiconTagsPaginationAPI } from 'src/services';
import { useAppSelector } from 'src/hooks';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';
import { useValidateLocale } from '../../hooks';
import { editThematicTagContainerActions } from './EditThematicTagContainer.actions';

export type EditThematicTagContainerProps = RoutedComponentProps & {
  refetchLexiconTags?: () => Promise<ILexiconTagsPaginationAPI>;
};

export const EditThematicTagContainer = ({
  permittedRouteMap,
  refetchLexiconTags,
}: EditThematicTagContainerProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const currentThematicTag = useAppSelector(state => state.lexicon.currentThematicTag);

  const { id: thematicParamId } = useParams<{ id: string }>();

  const locale = useValidateLocale({ permittedRouteMap, dispatch, shopId })?.locale;

  const onModalClose = useCallback(() => {
    if (permittedRouteMap.lexiconCategories?.path && shopId && locale) {
      dispatch(
        editThematicTagContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.lexiconCategories.path, {
            shopId,
            locale,
            tagType: LexiconTagType.ThematicTags,
          }),
        })
      );
    }
  }, [dispatch, permittedRouteMap.lexiconCategories, locale, shopId]);

  const onSuccess = useCallback(() => {
    refetchLexiconTags?.();
  }, [refetchLexiconTags]);

  useEffect(() => {
    if (!thematicParamId) {
      onModalClose();
      return;
    }

    if (shopId && locale && currentThematicTag?.id !== thematicParamId) {
      (
        dispatch(
          editThematicTagContainerActions.getThematicTag({
            shopId,
            locale,
            id: thematicParamId,
          })
        ) as any
      )
        .unwrap()
        .catch((error: unknown) => {
          console.error(error);
          onModalClose();
        });
    }
  }, [thematicParamId, shopId, locale]);

  useEffect(() => {
    return () => {
      dispatch(editThematicTagContainerActions.resetThematicTag());
    };
  }, []);

  if (!shopId || !locale) {
    return null;
  }

  return shopId ? (
    <ThematicTagModal
      dispatch={dispatch}
      mode='edit'
      onClose={onModalClose}
      onSuccess={onSuccess}
      shopId={shopId}
      locale={locale}
      thematicTag={currentThematicTag}
    />
  ) : null;
};
