import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { galleriesService, GalleriesService } from 'src/services/src/service/galleries';

export const galleriesTablePageActions = {
  getGalleries: createAsyncThunk(
    'GalleriesTablePage/GetGalleries',
    async (parameters: Parameters<GalleriesService['getGalleries']>[0], { rejectWithValue }) => {
      try {
        const galleries = await galleriesService.getGalleries(parameters);

        return galleries;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  deleteGallery: createAsyncThunk(
    'GalleriesTablePage/DeleteGallery',
    async (parameters: Parameters<GalleriesService['deleteGallery']>[0], { rejectWithValue }) => {
      try {
        const wasGalleryDeleted = await galleriesService.deleteGallery(parameters);
        if (!wasGalleryDeleted) {
          throw new Error("Gallery wasn't deleted");
        }
        return { deletedGalleryId: parameters.galleryId };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  navigateTo: createAction<{ navigateTo: string }>('GalleriesTablePage/NavigateTo'),
};
