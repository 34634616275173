import { AvailableIcons } from 'src/components-dummy';
import { DomainEntityPath } from 'src/services';
import { ParentDomainBase } from '../../types';
import { rankingSubDomainGroup } from '../ranking-sub-domain-group';
import { similarItemsSettingsSubDomainGroup } from './similar-items-settings-sub-domain-group';

export const similarItemsDomainGroup: ParentDomainBase = {
  domainKey: DomainEntityPath.SimilarItems,
  title: 'Similar Items',
  icon: AvailableIcons.HollowStar,
  subDomains: [
    { ...rankingSubDomainGroup, domainKey: DomainEntityPath.SimilarItemsRanking },
    { ...similarItemsSettingsSubDomainGroup, domainKey: DomainEntityPath.SimilarItemsSettings },
  ],
};
