import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AppNotification } from 'src/app-types';
import { lexiconService } from 'src/services';
import { requestWasCancelledByUser } from 'src/services/src/service/httpService';
import { ApiError } from 'src/services/src/service/httpService-types';

export const importLexiconModalActions = {
  importLexicon: createAsyncThunk(
    'ImportLexiconModal/ImportLexicon',
    async (
      {
        shopId,
        locale,
        requestId,
        lexiconFile,
        cancellationSignal,
      }: {
        shopId: number;
        locale: string;
        requestId: string;
        lexiconFile: File;
        cancellationSignal?: AbortSignal;
      },
      { rejectWithValue }
    ) => {
      try {
        const data = await lexiconService.importLexicon({
          shopId,
          locale,
          requestId,
          lexiconFile,
          cancellationSignal,
        });

        return data;
      } catch (error) {
        return requestWasCancelledByUser(error as ApiError)
          ? undefined
          : rejectWithValue({ error });
      }
    }
  ),
  notification: createAction<Pick<AppNotification, 'customMessage' | 'type'>>(
    'ImportLexiconModal/CreateToast'
  ),
};
