import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { dataFieldsService, FieldType, ShopDataField } from 'src/services';

export const dataFieldsTableFormActions = {
  getDataFields: createAsyncThunk(
    'DataFieldsTableForm/GetDataFields',
    async (
      { shopId, fieldType }: { shopId: number; fieldType: FieldType },
      { rejectWithValue }
    ) => {
      try {
        const dataFields = await dataFieldsService.getDataFields({ shopId, fieldType });

        return { shopId, dataFields };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateDataFields: createAsyncThunk(
    'DataFieldsTableForm/UpdateDataFields',
    async (
      {
        shopId,
        dataFields,
      }: {
        shopId: number;
        dataFields: ShopDataField[];
      },
      { rejectWithValue }
    ) => {
      try {
        const newDataFields = await dataFieldsService.updateDataFields({ shopId, dataFields });

        return { shopId, dataFields: newDataFields };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  notifyIsDirty: createAction<{ isDirty: boolean }>('DataFieldsTableForm/NotifyIsDirty'),
};
