import React, { useEffect, useMemo } from 'react';
import { startCase } from 'lodash';
import { FiltersRow, FilterVariant } from 'src/components-dummy';
import { MerchandisingRuleTypes } from 'src/services';
import {
  FiltersRowWrapperStyled,
  SkeletonsWrapper,
  StyledSkeleton,
} from './MerchandisingRulesFilters.styles';
import { MerchandisingRulesFiltersProps } from './MerchandisingRulesFilters.types';
import { merchandisingRulesListPageActions } from '../../MerchandisingRulesListPage.actions';
import { getProductName } from 'src/components-bl/MerchandisingRules/merchandisingRules.helpers';
import { SyteProductType } from 'src/services/src/service/types/constants';

export const MerchandisingRulesFilters = ({
  overview,
  filters,
  onChange,
  dispatch,
  shopId,
  selectedVariantId,
  entityId,
}: MerchandisingRulesFiltersProps): JSX.Element => {
  useEffect(() => {
    dispatch(
      merchandisingRulesListPageActions.getRulesOverview({
        shopId,
        variantId: selectedVariantId,
        entityId,
      })
    );
  }, [dispatch, shopId, selectedVariantId, entityId]);

  const settings = useMemo(() => {
    if (!overview) return undefined;

    const KPIFilterOptions = overview.kpis.map(kpi => {
      return {
        text: kpi,
        value: kpi,
      };
    });

    const syteProductsFilterOptions = overview.products.map(product => {
      return {
        text: getProductName(product as SyteProductType),
        value: product,
      };
    });

    const availableActionTypesFilterOptions = overview.actions.map(action => {
      return {
        text: startCase(action.toLowerCase()),
        value: action,
      };
    });

    return {
      KPIFilterOptions,
      syteProductsFilterOptions,
      availableActionTypesFilterOptions,
    };
  }, [overview]);

  const filterComponents: JSX.Element[] = [
    <FiltersRow.Filter
      type={FilterVariant.SearchInput}
      key='searchTerm'
      uniqueFilterKey='searchTerm'
      componentConfig={{ placeholder: 'Search...' }}
    />,
  ];

  filterComponents.push(
    <FiltersRow.Filter
      type={FilterVariant.Menu}
      key='status'
      uniqueFilterKey='status'
      menuName='Status'
      tagPrefix='Status is'
      componentConfig={{
        options: [
          { value: MerchandisingRuleTypes.MerchandisingRuleStatus.Active, text: 'Active' },
          { value: MerchandisingRuleTypes.MerchandisingRuleStatus.Inactive, text: 'Inactive' },
          {
            value: MerchandisingRuleTypes.MerchandisingRuleStatus.IsInDraft,
            text: 'Unpublished changes',
          },
          { value: MerchandisingRuleTypes.MerchandisingRuleStatus.InValid, text: 'Failure' },
        ],
      }}
    />
  );

  if (settings?.KPIFilterOptions.length) {
    filterComponents.push(
      <FiltersRow.Filter
        type={FilterVariant.MultiSelect}
        key='kpi'
        uniqueFilterKey='kpi'
        menuName='KPI'
        tagPrefix='KPI is'
        componentConfig={{
          enableSearch: true,
          enableToggleAll: true,
          labelText: 'Filter by KPI',
          options: settings.KPIFilterOptions,
          applyText: 'Apply',
          searchPlaceholder: `Search KPI's`,
          headerTitle: 'KPI',
        }}
      />
    );
  }

  filterComponents.push(
    <FiltersRow.Filter
      type={FilterVariant.MultiSelect}
      key='product'
      uniqueFilterKey='product'
      menuName='Product'
      tagPrefix='Product is'
      componentConfig={{
        enableSearch: false,
        enableToggleAll: true,
        options: settings?.syteProductsFilterOptions || [],
        applyText: 'Add',
      }}
    />
  );

  filterComponents.push(
    <FiltersRow.Filter
      type={FilterVariant.MultiSelect}
      key='action'
      uniqueFilterKey='action'
      menuName='Type'
      tagPrefix='Type is'
      componentConfig={{
        enableSearch: false,
        enableToggleAll: false,
        options: settings?.availableActionTypesFilterOptions || [],
        applyText: 'Add',
      }}
    />
  );

  filterComponents.push(
    <FiltersRow.Filter
      type={FilterVariant.Menu}
      key='daysSinceLastEdit'
      uniqueFilterKey='daysSinceLastEdit'
      menuName='Last edit'
      tagPrefix='Last edit'
      componentConfig={{
        options: [
          { value: 1, text: 'Today' },
          { value: 7, text: 'Last week' },
          { value: 31, text: 'Last month' },
        ],
      }}
    />
  );

  return (
    <FiltersRowWrapperStyled>
      {settings ? (
        <FiltersRow onChange={onChange} filters={filters}>
          {filterComponents}
        </FiltersRow>
      ) : (
        <SkeletonsWrapper>
          <StyledSkeleton width={200} />
          <StyledSkeleton width={100} />
        </SkeletonsWrapper>
      )}
    </FiltersRowWrapperStyled>
  );
};
