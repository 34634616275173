import React from 'react';
import { AvailableIcons, Icon, Skeleton } from 'src/components-dummy';
import { ImageStyled, StartAdornmentStyled } from './SkuAutoSuggestion.styles';

export const InputStartAdornment = ({
  imageUrl,
  loading,
  error,
}: {
  imageUrl: string | undefined;
  loading: boolean;
  error: boolean;
}): JSX.Element => {
  let content = null;

  if (loading) {
    content = <Skeleton css={{ height: '100%', width: '100%' }} />;
  } else if (error) {
    content = <Icon name={AvailableIcons.CircleWarning} />;
  } else if (imageUrl) {
    content = <ImageStyled src={imageUrl} />;
  }

  return <StartAdornmentStyled>{content} </StartAdornmentStyled>;
};
