import React from 'react';
import { formatDateToNow } from '../../../../utils/format-date-to-now';
import { Typography, TypographyType, TypographyVariant } from '../../../../components-dummy';
import { MerchandiseRule } from '../../../../services/src/service/types/shops';
import { RuleDescriptionContainer, LastEdited } from './SideMenu.styles';

export const RuleDescription = ({ selectedRule }: { selectedRule: MerchandiseRule }) => {
  const { name, updatedAt } = selectedRule;
  const lastEditedAt = updatedAt ? formatDateToNow(new Date(updatedAt)) : '';
  return (
    <RuleDescriptionContainer>
      <Typography type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
        {name}
      </Typography>
      <LastEdited type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
        {`Last edited ${lastEditedAt}`}
      </LastEdited>
    </RuleDescriptionContainer>
  );
};
