interface IdHolder {
  id: string;
}

export function updateList<T extends IdHolder>(
  updatedItem: T,
  list: T[],
  keyCompare: (target: T, source: T) => boolean = (a, b) => a.id === b.id
): T[] {
  const mapped = list.map(item => {
    return keyCompare(item, updatedItem) ? updatedItem : item;
  });
  return mapped.includes(updatedItem) ? mapped : [...mapped, updatedItem];
}
