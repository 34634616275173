import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { isEqual } from 'lodash';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch } from 'src/components-bl/types';
import { Skeleton } from 'src/components-dummy';
import { useValidateSchema } from 'src/hooks';
import { GalleryType, InspirationalImagesGallery } from 'src/services';
import { galleryFormActions } from './Actions';
import { GalleryDetailsSection, GallerySourceSection } from './components';
import { galleryDetailsValidationSchema } from './constants';
import { GalleryFormMapper } from './mapper';
import { GalleryDetailsDraft } from './types';
import { GalleryFormStyled } from './GalleryForm.styles';

interface GalleryFormProps extends RoutedComponentProps {
  shopId: number;
  dispatch: Dispatch;
  gallery?: InspirationalImagesGallery;
  onFormStatusChange: (canSubmit: boolean) => void;
}

export interface GalleryFormApiRef {
  submit(): Promise<void>;
}

export const GalleryForm = forwardRef<GalleryFormApiRef, GalleryFormProps>(
  ({ shopId, gallery, dispatch, onFormStatusChange }, ref): JSX.Element => {
    const isLoadingGallery = !gallery;

    const initialGalleryDetailsDraftStateMapped: GalleryDetailsDraft = useMemo(
      () => GalleryFormMapper.mapGalleryDetailsToDraft(gallery),
      [gallery]
    );

    const [galleryDetailsDraft, setGalleryDetailsDraft] = useState(
      initialGalleryDetailsDraftStateMapped
    );
    const [inspirationalImagesFiles, setInspirationalImagesFiles] = useState<File[]>([]);
    const [isDirty, setIsDirty] = useState(false);

    const isGallerySourceFieldsValid = useMemo(() => {
      const isInspirationalImagesFilesValid =
        galleryDetailsDraft.galleryType === GalleryType.InspirationalImage &&
        inspirationalImagesFiles.length > 0;

      return isInspirationalImagesFilesValid;
    }, [galleryDetailsDraft.galleryType, inspirationalImagesFiles]);

    const enableUploadConfirmationDialog = useMemo(
      () => gallery?.images.length !== 0,
      [gallery?.images]
    );

    const updateIsDirty = useCallback(
      (newIsDirty: boolean) => {
        setIsDirty(newIsDirty);
        dispatch(galleryFormActions.notifyIsDirty({ isDirty: newIsDirty }));
      },
      [dispatch, setIsDirty]
    );

    const {
      errors: galleryDetailsErrors,
      isValid: isGalleryDetailsValid,
      validate: validateGalleryDetails,
      resetValidationState: resetGalleryDetailsValidationState,
    } = useValidateSchema<GalleryDetailsDraft>({
      initialData: initialGalleryDetailsDraftStateMapped,
      schema: galleryDetailsValidationSchema,
      validateOnStart: Boolean(gallery),
    });

    useEffect(() => {
      setGalleryDetailsDraft(initialGalleryDetailsDraftStateMapped);
      resetGalleryDetailsValidationState();
    }, [initialGalleryDetailsDraftStateMapped, setGalleryDetailsDraft]);

    const onGalleryDetailsDraftChange = useCallback(
      (updatedDraft: Partial<GalleryDetailsDraft>) => {
        const updatedState = { ...galleryDetailsDraft, ...updatedDraft };

        setGalleryDetailsDraft(updatedState);
        validateGalleryDetails({ dataToValidate: updatedState });
      },
      [galleryDetailsDraft, validateGalleryDetails]
    );

    const updateGallery = useCallback(async () => {
      if (!gallery) {
        return;
      }

      switch (gallery.galleryType) {
        case GalleryType.InspirationalImage:
          await (
            dispatch(
              galleryFormActions.updateInspirationalImagesGallery({
                shopId,
                galleryId: gallery?.id,
                inspirationalImagesFile: inspirationalImagesFiles[0],
              })
            ) as any
          ).unwrap();
          break;
        default:
          break;
      }
    }, [dispatch, gallery, inspirationalImagesFiles, shopId]);

    useImperativeHandle(
      ref,
      () => {
        return {
          async submit() {
            await updateGallery();

            updateIsDirty(false);
          },
        };
      },
      [updateGallery, updateIsDirty]
    );

    useEffect(() => {
      const canSubmit = isGalleryDetailsValid && isGallerySourceFieldsValid;

      onFormStatusChange(canSubmit);
    }, [isDirty, isGalleryDetailsValid, isGallerySourceFieldsValid, onFormStatusChange]);

    const isGalleryDetailsDirty = useMemo(
      () => !isEqual(galleryDetailsDraft, initialGalleryDetailsDraftStateMapped),
      [galleryDetailsDraft, initialGalleryDetailsDraftStateMapped]
    );

    const isGallerySourceFieldsDirty = useMemo(() => {
      if (galleryDetailsDraft.galleryType === GalleryType.InspirationalImage) {
        return inspirationalImagesFiles.length !== 0;
      }
      return false;
    }, [galleryDetailsDraft.galleryType, inspirationalImagesFiles]);

    useEffect(() => {
      updateIsDirty(isGalleryDetailsDirty || isGallerySourceFieldsDirty);
    }, [isGalleryDetailsDirty, isGallerySourceFieldsDirty, updateIsDirty]);

    useEffect(() => {
      return () => {
        dispatch(galleryFormActions.notifyIsDirty({ isDirty: false }));
      };
    }, [dispatch]);

    return isLoadingGallery ? (
      <Skeleton variant='rectangular' height={500} />
    ) : (
      <GalleryFormStyled>
        <GalleryDetailsSection
          data={galleryDetailsDraft}
          disabled
          errors={galleryDetailsErrors}
          onChange={onGalleryDetailsDraftChange}
        />
        {Boolean(galleryDetailsDraft.galleryType) && (
          <GallerySourceSection
            galleryType={galleryDetailsDraft.galleryType as GalleryType}
            inspirationalImagesFiles={inspirationalImagesFiles}
            onInspirationalImagesFileUpload={setInspirationalImagesFiles}
            enableUploadConfirmationDialog={enableUploadConfirmationDialog}
          />
        )}
      </GalleryFormStyled>
    );
  }
);
