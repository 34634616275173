/* eslint no-param-reassign:0 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorType, State, StateType } from './types';

import {
  CollectionStatus,
  ConnectionLinkResponse,
  ConnectionStatusResponse,
} from '../../../../services/src/service/social-discovery/types';
import { ResponseValue } from '../../types/common.types';

const initialState: State = {
  type: StateType.fetchingStatus,
  connectionLink: null,
  error: null,
};

export const socialCollectionSlice = createSlice({
  name: 'socialConnection',
  initialState,
  reducers: {
    fetchConnectionLink: state => {
      state.type = StateType.fetchingLink;
    },
    reFetchStatus: state => {
      state.type = StateType.reFetchingStatus;
    },
    resolveConnectionLink: (
      state,
      { payload }: PayloadAction<ResponseValue<ConnectionLinkResponse>>
    ) => {
      if (payload.error !== null) {
        state.error = {
          type: ErrorType.fetchConnectionLinkError,
        };
        return;
      }
      state.type = StateType.unConnected;
      state.connectionLink = payload.data.connectionLink;
      state.error = null;
    },
    resolveConnectionStatus: (
      state,
      { payload }: PayloadAction<ResponseValue<ConnectionStatusResponse>>
    ) => {
      if (payload.error !== null) {
        state.error = {
          type: ErrorType.fetchCollectionError,
        };
        return;
      }

      switch (payload.data.status) {
        case CollectionStatus.ConnectedCollection:
          state.type = StateType.connected;
          return;
        case CollectionStatus.NoCollection:
          state.type = StateType.noCollection;
          state.connectionLink = null;
          return;
        case CollectionStatus.UnConnectedCollection:
        default:
          if (state.type === StateType.reFetchingStatus) {
            state.error = {
              type: ErrorType.invalidConnectionError,
            };
          }
          state.connectionLink = payload.data.connectionLink;
          state.type = StateType.unConnected;
      }
    },
    setDisconnecting: state => {
      state.type = StateType.disconnecting;
    },
    resolveDisconnect: (state, { payload }) => {
      if (payload.error) {
        state.error = {
          type: ErrorType.fetchConnectionLinkError,
        };
        return;
      }
      state.type = StateType.noCollection;
      state.connectionLink = null;
    },
    resetState: () => {
      return initialState;
    },
  },
});
