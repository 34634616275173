import { ApiServiceBase } from '../api-service-base';
import {
  InStockProduct,
  NotIndexedProduct,
  OutOfStockProduct,
  Product,
  ProductType,
  PublicPost,
  UnpublishedPost,
} from '../../../../components-bl/socialDiscovery/types/entities.types';
import { Response } from '../../../../components-bl/socialDiscovery/types/common.types';
import { ConnectionLinkResponse, ConnectionStatusResponse } from './types';

interface UnpublishedPostsParams {
  publicPostIds: Array<number>;
}

export interface ISocialCollectionService {
  getUnpublishedPosts: (params: UnpublishedPostsParams) => Response<{
    unpublishedPosts: UnpublishedPost[];
  }>;
  createConnectionLink: () => Response<ConnectionLinkResponse>;
  getProductBySKU: (sku: string) => Response<Product>;
  getConnectionStatus: () => Response<ConnectionStatusResponse>;
  getPublicPosts: () => Response<{ posts: PublicPost[] }>;
  savePublicPosts: (posts: PublicPost[]) => Response<null>;
  disconnectSocialCollection: () => Response<void>;
}

export class SocialCollectionService extends ApiServiceBase implements ISocialCollectionService {
  constructor(private shopId: number) {
    super('shops');
  }

  async getConnectionStatus() {
    try {
      const response = await this.httpService.get({
        url: `${this.serviceBaseUri}/${this.shopId}/social-discovery/social-collection/connection-status`,
      });
      return { data: response.data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  async getPublicPosts() {
    try {
      const publicPosts = await this.httpService.get({
        url: `${this.serviceBaseUri}/${this.shopId}/social-discovery/social-collection/posts`,
      });
      return { data: publicPosts.data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  async getUnpublishedPosts(params: UnpublishedPostsParams) {
    try {
      const moderatedPosts = await this.httpService.get({
        url: `${this.serviceBaseUri}/${this.shopId}/social-discovery/social-collection/posts/unpublished`,
        requestConfig: {
          params,
        },
      });
      return { data: moderatedPosts.data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  async createConnectionLink() {
    try {
      const response = await this.httpService.post({
        url: `${this.serviceBaseUri}/${this.shopId}/social-discovery/social-collection/connection-link`,
      });
      return { data: response.data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  async savePublicPosts(posts: PublicPost[]) {
    try {
      const updatedPosts = await this.httpService.post({
        url: `${this.serviceBaseUri}/${this.shopId}/social-discovery/social-collection/posts`,
        data: { posts },
      });
      return { data: updatedPosts.data, error: null };
    } catch (e) {
      return { data: null, error: e };
    }
  }

  async getProductBySKU(sku: string) {
    try {
      const productResponse = await this.httpService.get({
        url: `${this.serviceBaseUri}/${this.shopId}/social-discovery/social-collection/products?sku=${sku}`,
      });
      const product = productResponse.data;

      if (product.type === ProductType.inStock) {
        return {
          data: product as InStockProduct,
          error: null,
        };
      }
      if (product.type === ProductType.outOfStock) {
        return {
          data: product as OutOfStockProduct,
          error: null,
        };
      }
      return {
        data: product as NotIndexedProduct,
        error: null,
      };
    } catch (e) {
      return { data: null, error: e as Error };
    }
  }

  async disconnectSocialCollection() {
    try {
      const response = await this.httpService.delete({
        url: `${this.serviceBaseUri}/${this.shopId}/social-discovery/social-collection`,
      });
      return { data: response.data, error: null };
    } catch (e) {
      return { data: null, error: e };
    }
  }
}
