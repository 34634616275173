import { DomainGroup, SubDomainGroupBase } from '../../types';
import { UiTemplate } from '../../../../UiTemplates/types';
import { DomainEntityPath } from '../../../../../services';
import { AvailableIcons } from '../../../../../components-dummy';

type EntityType = UiTemplate;

export const uiTemplatesDomainGroup: DomainGroup & SubDomainGroupBase<EntityType> = {
  domainKey: DomainEntityPath.UiTemplates,
  title: 'Ui Templates',
  icon: AvailableIcons.UiTemplates,
  getCardTitle: ({ newEntity, oldEntity }): string => {
    const uiTemplate = newEntity ?? oldEntity;
    return `Ui Templates > “${uiTemplate?.name}”`;
  },
  fieldsSchema: {
    template: {
      displayName: 'Template',
      getValue: () => 'Preview is not available',
    },
  },
};
