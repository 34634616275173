import { ShopFeatureToggles } from '../../services/src/service/types/shops';
import { SyteProductType } from 'src/services/src/service/types/constants/syte-product-types';
import { ShopFeature } from '../../app-types';
import { convertFeatureToSyteProduct } from './convert-feature-to-syte-product';

export const getSupportedSyteProducts = (
  shopFeatureToggles: ShopFeatureToggles,
  supportedProducts: SyteProductType[]
): SyteProductType[] => {
  return supportedProducts.filter(productKey => {
    return Object.keys(shopFeatureToggles).every(feature => {
      const syteProduct = convertFeatureToSyteProduct(feature as ShopFeature);
      return syteProduct !== productKey || shopFeatureToggles[feature as ShopFeature].enabled;
    });
  });
};
