import React, { useMemo } from 'react';
import { TableV2, TableV2InstanceProps } from '../../../../../components-dummy';
import { IndexedOffer, IndexedOffers } from '../../../types';
import { DataFieldToDisplay } from '../../../types/data-field-to-display';
import { SpecialDataFieldToDisplay } from '../../../types/special-data-field-to-display';
import { TableHeaderColumns } from './TableHeaderColumns';
import { TableRow } from './TableRow';
import { getTableColumns } from './helpers/get-table-columns';
import { TableWrapperStyled } from './VisualEditorTable.styled';
import { TableHeadStyled } from '../../../../../components-dummy/TableV2/TableV2.style';

interface PreviewTableProps {
  offers: IndexedOffers;
  dataFieldsToDisplay: DataFieldToDisplay[];
  specialDataFieldsToDisplay: SpecialDataFieldToDisplay[];
  openProductDetailsModal: (sku?: string) => void;
}

export function VisualEditorTable({
  offers,
  dataFieldsToDisplay,
  specialDataFieldsToDisplay,
  openProductDetailsModal,
}: PreviewTableProps): JSX.Element {
  const tableColumns = getTableColumns(dataFieldsToDisplay, specialDataFieldsToDisplay);
  const columns = useMemo(() => Object.values(tableColumns), [tableColumns]);

  const renderBody = ({
    rows,
    prepareRow,
  }: Pick<TableV2InstanceProps<IndexedOffer>, 'rows' | 'prepareRow'>) => {
    return rows.map(row => {
      prepareRow(row);
      return (
        <TableRow
          key={row.original.id}
          row={row}
          tableColumns={tableColumns}
          openProductDetailsModal={openProductDetailsModal}
        />
      );
    });
  };

  return (
    <TableWrapperStyled>
      <TableV2<IndexedOffer> options={{ columns, data: offers }}>
        {({ getTableBodyProps, headerGroups, rows, prepareRow }) => {
          const headerGroup = headerGroups[0];

          return (
            <>
              <TableHeadStyled isSticky {...headerGroup.getHeaderGroupProps()}>
                <TableHeaderColumns headers={headerGroup.headers} columns={columns} />
              </TableHeadStyled>
              <TableV2.Body {...getTableBodyProps()}>
                {renderBody({ rows, prepareRow })}
              </TableV2.Body>
            </>
          );
        }}
      </TableV2>
    </TableWrapperStyled>
  );
}
