import { AxiosResponse } from 'axios';
import { AccountTypes, UserTypes } from '../types';
import { parseDate } from '../../common/utils';

export class AccountsMapper {
  static map({ data }: AxiosResponse): AccountTypes.Account {
    const account = data.account || data;

    account.updatedAt = parseDate(account.updatedAt);
    account.createdAt = parseDate(account.createdAt);
    account.users =
      (account.users &&
        account.users.map((u: UserTypes.UserBackEnd) => {
          const { userId, email, accessLevel: role, accounts, firstName, lastName, online } = u;
          return {
            userId,
            email,
            role,
            online,
            firstName,
            lastName,
            accountId: accounts?.[0] || 0,
            lastLoginDate: u.lastLoginDate ? parseDate(u.lastLoginDate) : undefined,
          };
        })) ||
      [];
    account.shops =
      account.shops &&
      account.shops.map((shop: any) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { _id, ...shopData } = shop;
        return { ...shopData, updatedAt: parseDate(shop.updatedAt), accountId: account.accountId };
      });
    return account;
  }

  static mapSearchResults({ data }: AxiosResponse): Array<AccountTypes.AccountSearchResult> {
    const searchResults = data.accounts;
    for (const account of searchResults) {
      account.updatedAt = parseDate(account.updatedAt);
      account.createdAt = parseDate(account.createdAt);
    }
    return searchResults;
  }
}
