import React, { ReactNode } from 'react';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import { NotificationCardMessageTypographyStyled } from './NotificationCardMessageTypography.styles';

export function NotificationCardMessageTypography({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}): JSX.Element {
  return (
    <NotificationCardMessageTypographyStyled
      type={TypographyType.Paragraph}
      variant={TypographyVariant.MediumRegular}
      className={className}
    >
      {children}
    </NotificationCardMessageTypographyStyled>
  );
}
