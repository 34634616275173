import { createAsyncThunk } from '@reduxjs/toolkit';
import { IShopGeneratedDataSettings, ShopGeneratedDataSettings } from 'src/services';

const shopGeneratedDataService = new ShopGeneratedDataSettings();

/**
 * Types
 */
export interface GetDeepTagReportProductsActionArguments {
  shopId: number;
  reportId: string;
  locale: string;
}

/**
 * Actions
 */
export const deepTagReportsSettingsContainerActions = {
  getGeneratedDataSettings: createAsyncThunk(
    'DeepTagReportProductsContainerActions/GetGeneratedDataSettings',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const shopGeneratedDataSettings = await shopGeneratedDataService.getGeneratedDataSettings({
          shopId,
        });

        return { shopGeneratedDataSettings };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateGeneratedDataSettings: createAsyncThunk(
    'DeepTagReportProductsContainerActions/UpdateGeneratedDataSettings',
    async (
      {
        shopId,
        shopGeneratedDataSettings,
      }: { shopId: number; shopGeneratedDataSettings: IShopGeneratedDataSettings },
      { rejectWithValue }
    ) => {
      try {
        const shopGeneratedDataSettingsResult =
          await shopGeneratedDataService.updateGeneratedDataSettings({
            shopId,
            shopGeneratedDataSettings,
          });

        return { shopGeneratedDataSettings: shopGeneratedDataSettingsResult };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
