import styled from '@emotion/styled';
import { Icon, Skeleton, Spinner, Typography } from 'src/components-dummy';

const CARD_WIDTH = 200;
const CARD_PADDING = 8;

export const ProductCardStyled = styled.div<{
  isApplied: boolean;
  isAIDetected: boolean;
  isError: boolean;
}>`
  display: flex;
  flex-direction: column;

  width: ${CARD_WIDTH}px;
  padding: ${CARD_PADDING}px;

  background-color: ${({ theme, isAIDetected, isApplied }) =>
    isAIDetected && !isApplied
      ? theme.palette.custom['primary-light']
      : theme.palette.common.white};

  border-radius: 8px;
  gap: 8px;
  overflow: hidden;

  ${({ theme, isAIDetected, isApplied, isError }) => {
    if (isError) {
      return `border: 2px solid ${theme.palette.custom.red}`;
    }

    if (isAIDetected && !isApplied) {
      return `border: ${theme.palette.custom['primary-main']} dashed 2px;`;
    }
  }}
`;

export const ImageWrapperStyled = styled.div`
  position: relative;
  width: 100%;
  min-width: ${CARD_WIDTH - CARD_PADDING * 2}px;
  min-height: ${CARD_WIDTH - CARD_PADDING}px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-self: center;
`;

export const ImageStyled = styled.img<{ opacity: number }>`
  max-height: 300px;
  height: auto;
  width: 100%;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.palette.custom['gray-60']};
  opacity: ${({ opacity }) => opacity};
`;

export const FirstRowStyled = styled.div`
  display: inline-flex;
  justify-content: space-between;

  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const BrandTypography = styled(Typography)`
  max-width: ${(CARD_WIDTH - CARD_PADDING) / 2}px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SkuTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-20']};

  max-width: ${(CARD_WIDTH - CARD_PADDING) / 2}px;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const BottomRowStyled = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const PriceTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom.red};
`;

export const OriginalPriceTypography = styled(Typography)`
  text-decoration: line-through;
`;

export const TagIconStyled = styled(Icon)<{
  isAIDetected: boolean;
  isApplied: boolean;
  alignRight: boolean;
  targetSvgAttribute: string;
}>`
  position: absolute;
  ${({ alignRight }) => (alignRight ? 'right' : 'left')}: 5px;
  top: 5px;

  min-width: 14px;
  max-width: 14px;

  path {
    ${({ targetSvgAttribute }) => targetSvgAttribute} : ${({ theme, isAIDetected, isApplied }) =>
      isAIDetected && isApplied === false
        ? theme.palette.custom['primary-main']
        : theme.palette.custom['gray-20']};
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export const DeleteTagIconStyled = styled(Icon)`
  margin-left: auto;
`;

export const SpinnerStyled = styled(Spinner)`
  align-self: center;
`;

export const ImageSkeleton = styled(Skeleton)`
  position: absolute;
`;
