import { useLocation } from 'react-router-dom';
import { generatePath } from 'react-router';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { RouteSettings } from '../../../app-routes';
import { VisualEditorParams } from '../types/params';

interface UseNavigateToVisualEditorRoute {
  route?: RouteSettings;
}

export const useNavigateToVisualEditorRoute = ({ route }: UseNavigateToVisualEditorRoute) => {
  const navigateTo = useAppNavigation();
  const location = useLocation();

  const navigateToVisualEditorRoute = (params: VisualEditorParams) => {
    if (route) {
      const path = generatePath(route.path, { ...params });
      const pathWithQueryParams = `${path}${location.search}`;
      navigateTo(pathWithQueryParams);
    }
  };

  return { navigateToVisualEditorRoute };
};
