import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AugmentedSearchGeneralSettings, augmentedSearchService } from 'src/services';

export const augmentedSearchSettingsActions = {
  notifyIsDirty: createAction<{ isDirty: boolean }>('AugmentedSearchSettings/NotifyIsDirty'),
  getGeneralSettings: createAsyncThunk(
    'AugmentedSearchSettings/GetGeneralSettings',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const augmentedSearchSettings = await augmentedSearchService.getGeneralSettings({ shopId });

        return augmentedSearchSettings;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateGeneralSettings: createAsyncThunk(
    'AugmentedSearchSettings/UpdateGeneralSettings',
    async (
      {
        shopId,
        generalSettings,
      }: { shopId: number; generalSettings: AugmentedSearchGeneralSettings },
      { rejectWithValue }
    ) => {
      try {
        const augmentedSearchSettings = await augmentedSearchService.updateGeneralSettings({
          shopId,
          generalSettings,
        });

        return augmentedSearchSettings;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  resetGeneralSettings: createAction('AugmentedSearchSettings/ResetGeneralSettings'),
};
