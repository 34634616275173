import React from 'react';
import { SuggestedOffer } from '../../types';
import { TypographyType, TypographyVariant } from '../../../../components-dummy';
import { Price, SalePriceContainer, SaleOriginalPrice, SalePrice } from './ProductCardPrice.styled';

export function ProductCardPrice({ offer }: { offer: SuggestedOffer }): JSX.Element {
  const { originalPrice, price, currency } = offer;
  const isOnSale = price !== originalPrice;
  return (
    <>
      {isOnSale ? (
        <SalePriceContainer>
          <SalePrice type={TypographyType.Body} variant={TypographyVariant.ExtraSmallMedium}>
            {`${currency} ${price}`}
          </SalePrice>
          <SaleOriginalPrice
            type={TypographyType.Body}
            variant={TypographyVariant.ExtraSmallRegular}
          >
            {`${currency} ${originalPrice}`}
          </SaleOriginalPrice>
        </SalePriceContainer>
      ) : (
        <Price type={TypographyType.Body} variant={TypographyVariant.ExtraSmallMedium}>
          {`${currency} ${price}`}
        </Price>
      )}
    </>
  );
}
