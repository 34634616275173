import { findComponentByType, findComponentsByType } from '../helpers';

interface UseFindComponentsByTypeArguments {
  type: { name: string };
  children?: JSX.Element | JSX.Element[];
}

type UseFindComponentByTypeArguments = UseFindComponentsByTypeArguments;

export function useFindComponentsByType(payload: UseFindComponentsByTypeArguments): JSX.Element[] {
  return findComponentsByType(payload);
}

export function useFindComponentByType(
  payload: UseFindComponentByTypeArguments
): JSX.Element | null {
  return findComponentByType(payload);
}
