import React from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { CreateGalleryModalForm } from 'src/components-bl/Galleries';
import { useAppDispatch } from 'src/hooks';

interface CreateGalleryContainerProps extends RoutedComponentProps {
  shopId: number;
}

export const CreateGalleryContainer = ({
  shopId,
  permittedRouteMap,
}: CreateGalleryContainerProps): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <CreateGalleryModalForm
      dispatch={dispatch}
      shopId={shopId}
      permittedRouteMap={permittedRouteMap}
    />
  );
};
