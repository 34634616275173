import React from 'react';
import styled from '@emotion/styled';
import { TextBoxBase, TextBoxBaseProps } from '../TextBox';
import { NumericInputBase, NumericInputBaseProps } from '../NumericInput';
import { Typography } from '../Typography';

export const NumericInputRangeStyled = styled((props: TextBoxBaseProps) => (
  <TextBoxBase {...props} />
))``;

export const NumericInputBaseStyled = styled((props: NumericInputBaseProps) => (
  <NumericInputBase {...props} />
))``;

export const SymbolTypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-50']};
  margin: 0 5px;
`;
