import React from 'react';
import {
  Button,
  ConfirmationDialog,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { ButtonStyled } from './DeleteImageConfirmationDialog.styles';

interface DeleteImageConfirmationDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export function DeleteImageConfirmationDialog({
  onConfirm,
  onCancel,
}: DeleteImageConfirmationDialogProps): JSX.Element {
  return (
    <ConfirmationDialog onCancel={onCancel}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Delete image</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        <Typography type={TypographyType.Paragraph} variant={TypographyVariant.LargeRegular}>
          Are you sure you want to delete this image?
        </Typography>
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonStyled variant='primary' onClick={onConfirm}>
          Delete
        </ButtonStyled>
        <Button variant='tertiary' onClick={onCancel}>
          Cancel
        </Button>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
}
