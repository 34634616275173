import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MenuItem, Select } from 'src/components-dummy';

export const SortOptionsBySelectStyled = styled(Select)`
  margin-top: 12px;
  max-width: 326px;
`;

export const MenuItemStyled = styled(MenuItem)<{ isDisabled: boolean }>`
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.38;
      cursor: unset;
    `}

  .syte-popup-trigger {
    width: 100%;
  }
`;
