import {
  GetRecentlyViewedSettingsArgs,
  UpdateRecentlyViewedSettingsPayload,
} from 'src/components-bl/RecentlyViewed/Actions/types';
import * as mapper from './mapper';
import * as builder from './builder';
import { RecentlyViewedSettingsTypes } from '../types';
import { ApiServiceBase } from '../api-service-base';

export class RecentlyViewedGeneralSettingsService extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl({ shopId, variantId }: GetRecentlyViewedSettingsArgs): string {
    return `${this.serviceBaseUri}/${shopId}/syte-products/recently-viewed/general-settings${
      variantId ? `?variantId=${variantId}` : ''
    }`;
  }

  async get({
    shopId,
    variantId,
  }: GetRecentlyViewedSettingsArgs): Promise<RecentlyViewedSettingsTypes.RecentlyViewedSettings> {
    const url = this.getUrl({ shopId, variantId });
    const response = await this.httpService.get({ url });
    return mapper.recentlyViewedSettingsResponse(response);
  }

  async update({
    shopId,
    variantId,
    ...payload
  }: UpdateRecentlyViewedSettingsPayload): Promise<RecentlyViewedSettingsTypes.RecentlyViewedSettings> {
    const url = this.getUrl({ shopId, variantId });

    const data = builder.buildRecentlyViewedSettingsPayload(payload);
    const response = await this.httpService.patch({ url, data });
    return mapper.recentlyViewedSettingsResponse(response);
  }
}
