import React from 'react';
import {
  AvailableIcons,
  Icon,
  TypographyVariant,
  TypographyType,
  Typography,
} from 'src/components-dummy';
import { CommentsFormButtonIconWithBadgeStyled } from './DeepTagProductComments.styles';

export const CommentsCountWithBadge = ({ total }: { total: number }): JSX.Element => {
  if (!total) {
    return (
      <CommentsFormButtonIconWithBadgeStyled>
        <Icon name={AvailableIcons.CommentsAdd} />
      </CommentsFormButtonIconWithBadgeStyled>
    );
  }

  return (
    <CommentsFormButtonIconWithBadgeStyled>
      <Icon name={AvailableIcons.Comments} />
      <Typography type={TypographyType.Body} variant={TypographyVariant.ExtraSmallRegular}>
        {total > 99 ? '99+' : total}
      </Typography>
    </CommentsFormButtonIconWithBadgeStyled>
  );
};
