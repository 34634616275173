import styled from '@emotion/styled';
import { Typography } from '../Typography';

export const AutoCompleteExpandableChipStyled = styled.div`
  max-width: calc(100% - 6px);

  .syte-popup-trigger {
    max-width: 100%;
  }

  .MuiAutocomplete-tag {
    max-width: 100%;
    cursor: pointer;
  }
`;

export const PopoverContentStyled = styled.div<{ contentWidth?: number }>`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 8px;
  display: flex;
  padding: 12px;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  ${({ contentWidth }) => contentWidth && `width: ${contentWidth}px;`}
  min-width: 150px;
`;

export const TypographyStyled = styled(Typography)`
  overflow-wrap: anywhere;
`;

export const PopoverIconWrapperStyled = styled.div`
  border-radius: 50%;
  border: 1px solid;
  display: flex;
  width: 20px;
  min-width: 20px;
  height: 20px;
  min-height: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 10px;
    height: 10px;
  }
`;
