export function mapObjectEntries<T>({
  obj,
  mapping,
}: {
  obj: T;
  mapping: Record<string, string>;
}): { displayName: string; value: string | string[] }[] {
  return Object.entries(obj)
    .map(([key, value]) => {
      if (!value.length) return;
      return {
        displayName: mapping[key as keyof typeof mapping],
        value,
      };
    })
    .filter(item => item);
}
