import React, { useCallback } from 'react';
import { ParseJoiValidateResponse } from 'src/utils';
import { AvailableIcons, TypographyType, TypographyVariant } from 'src/components-dummy';
import {
  AttributeTagPart,
  CategoryTagPart,
  ILexiconRuleDraft,
  TagFields,
  TagPart,
  ValueTagPart,
} from '../../../LexiconRuleForm.types';
import { CategoryRow } from './CategoryRow';
import { TagFieldSelect } from './TagFieldSelect';
import { TagRowStyled, TagRowTypographyStyled, TrashIconStyled } from './TagRow.styles';
import { AttributeRow } from './AttributeRow';
import { ValueRow } from './ValueRow';

export interface TagRowFactoryProps extends Pick<ILexiconRuleDraft, 'conditionsGroups'> {
  tag: TagPart;
  onDelete: (id: number) => void;
  onChange: (updatedTag: TagPart) => void;
  availableCategoryKeys: string[];
  availableAttributeKeys: string[];
  availableValueKeys: string[];
  errors: ParseJoiValidateResponse<TagPart>;
  availableFields: TagFields[];
  isFirstRow: boolean;
  isTheOnlyRow: boolean;
}

export const TagRowFactory = ({
  tag,
  onDelete,
  onChange,
  errors,
  availableFields,
  availableCategoryKeys,
  availableAttributeKeys,
  availableValueKeys,
  isFirstRow,
  isTheOnlyRow,
}: TagRowFactoryProps): JSX.Element => {
  const onTagFieldChange = useCallback(
    newField => {
      let updatedState: TagPart;

      switch (newField) {
        case TagFields.Value: {
          updatedState = {
            id: tag.id,
            field: TagFields.Value,
            attributeKey: '',
            valueKey: '',
            customTranslation: '',
          };
          break;
        }
        case TagFields.Attribute: {
          updatedState = {
            id: tag.id,
            field: TagFields.Attribute,
            attributeKey: '',
            customTranslation: '',
          };
          break;
        }
        case TagFields.Category:
        default: {
          updatedState = {
            id: tag.id,
            field: TagFields.Category,
            categoryKey: '',
            customTranslation: '',
          };
          break;
        }
      }
      onChange(updatedState);
    },
    [tag, onChange]
  );

  const onDeleteTag = useCallback(() => {
    onDelete(tag.id);
  }, [onDelete, tag.id]);

  let tagSpecificComponents;

  switch (tag.field) {
    case TagFields.Attribute: {
      tagSpecificComponents = (
        <AttributeRow
          tag={tag as AttributeTagPart}
          errors={errors}
          onChange={onChange}
          availableAttributeKeys={availableAttributeKeys}
        />
      );
      break;
    }
    case TagFields.Value: {
      tagSpecificComponents = (
        <ValueRow
          tag={tag as ValueTagPart}
          errors={errors}
          onChange={onChange}
          availableAttributeKeys={availableAttributeKeys}
          availableValueKeys={availableValueKeys}
        />
      );
      break;
    }
    case TagFields.Category: {
      tagSpecificComponents = (
        <CategoryRow
          tag={tag as CategoryTagPart}
          errors={errors}
          onChange={onChange}
          availableCategoryKeys={availableCategoryKeys}
        />
      );
      break;
    }
    default:
      break;
  }
  return (
    <TagRowStyled>
      {isFirstRow && (
        <TagRowTypographyStyled type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
          I want to update
        </TagRowTypographyStyled>
      )}
      <TagFieldSelect
        tagField={tag.field}
        onChange={onTagFieldChange}
        error={errors?.field?.message}
        availableFields={availableFields}
      />
      {tagSpecificComponents}
      {!isTheOnlyRow && (
        <TrashIconStyled iconName={AvailableIcons.TrashCan} onClick={onDeleteTag} />
      )}
    </TagRowStyled>
  );
};
