import React, { useMemo } from 'react';
import { DatePicker, TimePicker } from 'src/components-dummy';
import {
  addLocalTimeZone,
  subtractLocalTimeZone,
} from 'src/components-dummy/TimePicker/TimePicker.helpers';
import { RuleFormField } from '../../RuleFormField/RuleFormField';
import { DateFieldStyled, DateTimeRowStyled, TimePickerStyled } from './RuleDateTimePicker.styles';

export const MINUTE_STEP = 5;

export interface RuleDateTimePickerProps {
  value: Date;
  dateTitle: string | JSX.Element;
  timeTitle: string | JSX.Element;
  minDate: Date;
  maxDate?: Date;
  minTime?: Date;
  onChange: (date: Date | null) => void;
  disabled?: boolean;
}

export const RuleDateTimePicker = ({
  value,
  dateTitle,
  timeTitle,
  minDate,
  minTime,
  maxDate,
  onChange: onChangeFromProp,
  disabled,
}: RuleDateTimePickerProps): JSX.Element => {
  const onDateChange = (selectedDate: Date | undefined | null) => {
    let date;

    if (!selectedDate) {
      date = minDate;
    } else {
      const isSelectedLowerThanMinDate = selectedDate < minDate;
      date = isSelectedLowerThanMinDate ? minDate : selectedDate;
    }

    // merge original time with the new date
    const dateTime = new Date(date);
    date.setHours(value?.getHours(), value?.getMinutes(), 0, 0);

    // to send original UTC same as picked in UI
    const dateTimeSubtracted = subtractLocalTimeZone(dateTime);

    onChangeFromProp(dateTimeSubtracted || null);
  };

  const onTimeChange = (selectedTime: Date | undefined | null) => {
    let time;

    if (!selectedTime) {
      time = minDate;
    } else {
      const isSelectedLowerThanMinDate = minDate && selectedTime < minDate;
      time = isSelectedLowerThanMinDate ? minDate : selectedTime;
    }

    // merge original date with the new time
    const dateTime = new Date(value);
    dateTime.setHours(time?.getHours(), time?.getMinutes(), 0, 0);

    // to send original UTC same as picked in UI
    const dateTimeSubtracted = subtractLocalTimeZone(dateTime);

    onChangeFromProp(dateTimeSubtracted || null);
  };

  // add local timezone to show in UI properly as it's saved without it.
  const dateWithLocalTimeZone = useMemo(() => addLocalTimeZone(value), [value.getTime()]);

  return (
    <DateTimeRowStyled>
      <DateFieldStyled>
        <RuleFormField.LabelText>{dateTitle}</RuleFormField.LabelText>
        <DatePicker
          value={dateWithLocalTimeZone}
          onChange={onDateChange}
          popperPlacement='top-start'
          minDate={minDate}
          maxDate={maxDate}
          isError={false}
          disabled={disabled}
        />
      </DateFieldStyled>
      <DateFieldStyled>
        <RuleFormField.LabelText>{timeTitle}</RuleFormField.LabelText>
        {/* TODO: determine if AM/PM mode or 24h mode. currently always 24h */}
        <TimePickerStyled>
          <TimePicker
            value={dateWithLocalTimeZone}
            onChange={onTimeChange}
            isError={false}
            minTime={minTime}
            minutesStep={MINUTE_STEP}
            inputFormat='HH:mm'
            popperPlacement='top-start'
            disabled={disabled}
          />
        </TimePickerStyled>
      </DateFieldStyled>
    </DateTimeRowStyled>
  );
};
