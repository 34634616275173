/* eslint-disable react/no-unescaped-entities */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import {
  TypographyType,
  TypographyVariant,
  Box,
  RadioGroup,
  Typography,
} from 'src/components-dummy';
import { FileUploader } from 'src/components-dummy/FileUploader';
import { downloadFile } from 'src/utils/download-file';
import { CatalogFormSection } from '../CatalogFormSection/CatalogFormSection';
import {
  ErrorLabelStyled,
  ErrorsMessagesWrapperStyled,
  SubTitleStyled,
} from '../../CatalogForm.styles';
import { CatalogFormDraft, DataSourceTypesEnum } from '../../CatalogForm.config';
import { DataSourceRadioButtonsContainerStyled } from './CatalogFormDataSourceSection.styles';

export interface CatalogFormDataSourceSectionProps {
  onFieldChange: (
    partialData: Partial<Pick<CatalogFormDraft, 'productsFile' | 'selectedDataSource'>>
  ) => void;
  errors?: any;
  selectedDataSource: DataSourceTypesEnum;
}

interface FormDataSourceState {
  productsFile?: File;
  selectedDataSource?: DataSourceTypesEnum;
}

const MAX_FILE_SIZE_MB = 100;

const exampleFilePath = `${process.env.PUBLIC_URL}/files/catalog-manager/upload_catalog_file_example.csv`;

export const CatalogFormDataSourceSection = React.memo(
  ({
    onFieldChange,
    errors,
    selectedDataSource,
  }: CatalogFormDataSourceSectionProps): JSX.Element => {
    const [sectionFormState, setSectionFormState] = useState<FormDataSourceState | undefined>(
      undefined
    );

    const nameErrorMessage = errors?.message;

    const onFormStateChange = (data: Partial<FormDataSourceState>) => {
      setSectionFormState({ ...data });
      onFieldChange(data);
    };

    const onExampleClick = () => {
      downloadFile({
        filePath: exampleFilePath,
        fileDisplayName: 'upload_catalog_file_example.csv',
      });
    };

    return (
      <CatalogFormSection>
        <CatalogFormSection.Header>Data Source</CatalogFormSection.Header>

        {nameErrorMessage ? (
          <ErrorsMessagesWrapperStyled>
            <ErrorLabelStyled> {nameErrorMessage} </ErrorLabelStyled>
          </ErrorsMessagesWrapperStyled>
        ) : null}

        <SubTitleStyled
          css={css`
            margin-bottom: 16px;
          `}
          type={TypographyType.Body}
          variant={TypographyVariant.MediumRegular}
        >
          Choose how you want to import your product data
        </SubTitleStyled>

        <DataSourceRadioButtonsContainerStyled>
          <RadioGroup
            selectedValue={selectedDataSource}
            onChange={(type: DataSourceTypesEnum) => onFieldChange({ selectedDataSource: type })}
            disabled={false}
            options={[
              {
                value: DataSourceTypesEnum.manual_add_products,
                label: (
                  <div>
                    <Typography
                      type={TypographyType.Body}
                      variant={TypographyVariant.MediumRegular}
                    >
                      Manually Add Products
                    </Typography>

                    <SubTitleStyled
                      type={TypographyType.Body}
                      variant={TypographyVariant.SmallMedium}
                    >
                      <div>
                        Use a CSV file to create your feed. Recommended for up to 1,000 products.
                      </div>
                    </SubTitleStyled>
                    {selectedDataSource === DataSourceTypesEnum.manual_add_products && (
                      <>
                        <SubTitleStyled
                          type={TypographyType.Body}
                          variant={TypographyVariant.SmallMedium}
                        >
                          Check out the standard template (download) and make sure the file you
                          upload matches the template.
                        </SubTitleStyled>
                        <Box sx={{ marginTop: '16px' }}>
                          <FileUploader.CSV
                            selectedFiles={
                              sectionFormState?.productsFile
                                ? [sectionFormState?.productsFile]
                                : undefined
                            }
                            onChange={files => onFormStateChange({ productsFile: files?.[0] })}
                            maxSizeMb={MAX_FILE_SIZE_MB}
                            onExampleClick={onExampleClick}
                            disabled={false}
                          />
                        </Box>
                      </>
                    )}
                  </div>
                ),
              },
              {
                value: DataSourceTypesEnum.use_product_feed,
                label: (
                  <div>
                    <Typography
                      type={TypographyType.Body}
                      variant={TypographyVariant.MediumRegular}
                    >
                      Use Product Feed
                    </Typography>
                    <SubTitleStyled
                      type={TypographyType.Body}
                      variant={TypographyVariant.SmallMedium}
                    >
                      Automate catalogue updates by pushing scheduled feed based updates.
                    </SubTitleStyled>
                  </div>
                ),
              },
            ]}
          />
        </DataSourceRadioButtonsContainerStyled>
      </CatalogFormSection>
    );
  }
);
