import React, { useCallback, useMemo, useState, type SyntheticEvent } from 'react';

import { useIsFeaturePermitted } from '../../../../hooks';
import { ShopFeature } from '../../../../app-types';

import {
  AvailableIcons,
  TabsV2,
  TabV2,
  Tooltip,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';

import type { Product } from '../../types/product';

import { ExternalLink } from '../ExternalLink';

import { mapObjectEntries } from './mapObjectEntries';

import { AITagsTab } from './AITagsTab';
import { AITagsTooltip } from './AITagsTooltip';
import { NoTags } from './NoTags';
import { SummaryTab } from './SummaryTab';
import { TabLabel } from './TabLabel';

import {
  CloseButtonStyled,
  ContentStyled,
  HeaderSeparatorStyled,
  HeaderStyled,
  ImagesContainerStyled,
  ImagesListStyled,
  ImagesTitleStyled,
  ImageStyled,
  ImageWrapperStyled,
  ProductDataContainerStyled,
  ScrollableSection,
  SubtitleWrapper,
  TabsContainerStyled,
  TitleStyled,
  TitleWrapper,
} from './ProductDetails.styled';

enum ProductImages {
  imageUrl = 'Main Image',
  ctlImageUrl = 'Shop The Look Image',
  imageToIndex = 'High-Res Image',
  syteImages = 'All Images',
}

interface ProductDetailsProps {
  product: Product;
  onClose: VoidFunction;
}

export const ProductDetails = ({
  product: { adUrl, images, details, skus, originalData, aiTags },
  onClose,
}: ProductDetailsProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const onTabChange = useCallback(
    (_event: SyntheticEvent, newValue: number) => {
      setSelectedTabIndex(newValue);
    },
    [setSelectedTabIndex]
  );

  const imagesCountText = useMemo(() => {
    const { imageUrl, ctlImageUrl, imageToIndex, syteImages } = images;
    const imagesCount = [imageUrl, ctlImageUrl, imageToIndex, ...syteImages].filter(z =>
      Boolean(z)
    ).length;
    return `${imagesCount} ${imagesCount === 1 ? 'image' : 'images'}`;
  }, [images]);

  const imagesToDisplay = useMemo(
    () =>
      mapObjectEntries({
        obj: images,
        mapping: ProductImages,
      }),
    [images]
  );

  const isMerchRulesAiTagsEnabled = useIsFeaturePermitted(ShopFeature.MerchandisingRulesAiTags);
  const isAiTagsEmpty = useMemo(() => Object.keys(aiTags || {}).length === 0, [aiTags]);

  const tabs = [
    {
      condition: () => true,
      header: useMemo(() => <TabV2 label={<TabLabel label='Summary' />} />, []),
      content: useMemo(
        () => (
          <SummaryTab
            details={details}
            skus={skus}
            imagesToDisplay={imagesToDisplay}
            originalData={originalData}
          />
        ),
        [details, skus, imagesToDisplay, originalData]
      ),
    },
    {
      condition: () => isMerchRulesAiTagsEnabled && !isAiTagsEmpty,
      header: useMemo(() => <TabV2 label={<TabLabel label='Visual AI Tags' />} />, []),
      content: useMemo(() => <AITagsTab aiTags={aiTags} />, [aiTags]),
    },
    {
      condition: () => isMerchRulesAiTagsEnabled && isAiTagsEmpty,
      header: useMemo(() => <TabV2 label={<TabLabel label='Visual AI Tags' />} />, []),
      content: useMemo(() => <NoTags />, []),
    },
    {
      condition: () => !isMerchRulesAiTagsEnabled,
      header: useMemo(
        () => (
          <Tooltip value={<AITagsTooltip />} contentClickPreventDefault={false}>
            <TabV2 label={<TabLabel label='Visual AI Tags' />} disabled />
          </Tooltip>
        ),
        []
      ),
      content: useMemo(() => <NoTags />, []),
    },
  ];

  const renderTabs = tabs.filter(tab => tab.condition());

  return (
    <>
      <HeaderStyled>
        <TitleWrapper>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
            Product Details
          </Typography>
          <SubtitleWrapper>
            <TitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              {`SKU ${skus.sku}`}
            </TitleStyled>
            <HeaderSeparatorStyled>&#183;</HeaderSeparatorStyled>
            <ExternalLink showIcon onClick={() => window.open(adUrl, '_blank')}>
              Open Product Page
            </ExternalLink>
          </SubtitleWrapper>
        </TitleWrapper>
        <CloseButtonStyled name={AvailableIcons.Close} onClick={onClose} />
      </HeaderStyled>
      <ContentStyled>
        <ImagesContainerStyled>
          <ImagesTitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
            {imagesCountText}
          </ImagesTitleStyled>
          <ImagesListStyled>
            {imagesToDisplay.map(({ displayName, value }) => {
              const convertValueToArray = Array.isArray(value) ? value : [value];
              return (
                <>
                  <ImagesTitleStyled
                    type={TypographyType.Body}
                    variant={TypographyVariant.SmallBold}
                  >
                    {displayName}
                  </ImagesTitleStyled>
                  {convertValueToArray.map(url => (
                    <ImageWrapperStyled key={url}>
                      <ImageStyled src={url} alt={displayName} loading='lazy' />
                    </ImageWrapperStyled>
                  ))}
                </>
              );
            })}
          </ImagesListStyled>
        </ImagesContainerStyled>
        <ProductDataContainerStyled>
          <TabsContainerStyled>
            <TabsV2 value={selectedTabIndex} onChange={onTabChange}>
              {renderTabs.map(tab => tab.header)}
            </TabsV2>
          </TabsContainerStyled>
          <ScrollableSection>{renderTabs[selectedTabIndex].content}</ScrollableSection>
        </ProductDataContainerStyled>
      </ContentStyled>
    </>
  );
};
