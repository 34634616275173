import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const FormSectionStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 32px 32px 0 32px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};
  border-radius: 16px;
  margin-bottom: 32px;
`;

export const TitleWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 24px;
`;

export const SubTitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-20']};
`;
