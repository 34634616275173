import { parseISODate } from 'src/utils/parse-iso-date';
import { format } from 'date-fns';
import {
  ILexicon,
  ILexiconAPI,
  ILexiconCategories,
  ILexiconItem,
  ILexiconItemAPI,
  ILexiconTagsPaginationAPI,
  ILexiconTagsPagination,
  ILexiconMetadata,
  ILexiconMetadataAPI,
  ILexiconMetadataSlim,
  ILexiconMetadataSlimAPI,
  IThematicTagAPI,
  IThematicTag,
  ILexiconRuleAPI,
  ILexiconRule,
} from '../types';

export function mapThematicTag(thematicTag: IThematicTagAPI): IThematicTag {
  return {
    ...thematicTag,
    createdAt: thematicTag.createdAt ? parseISODate(thematicTag.createdAt) : undefined,
    updatedAt: thematicTag.updatedAt ? parseISODate(thematicTag.updatedAt) : undefined,
  };
}

export function mapLexiconRule(lexiconRule: ILexiconRuleAPI): ILexiconRule {
  return {
    ...lexiconRule,
    createdAt: lexiconRule.createdAt ? parseISODate(lexiconRule.createdAt) : undefined,
    updatedAt: lexiconRule.updatedAt ? parseISODate(lexiconRule.updatedAt) : undefined,
  };
}

export function mapLexiconMetadata(metadata: ILexiconMetadataAPI): ILexiconMetadata {
  return {
    ...metadata,
    publishedAt: parseISODate(metadata.publishedAt),
    createdAt: parseISODate(metadata.createdAt),
    updatedAt: parseISODate(metadata.updatedAt),
  };
}

export function mapLexiconMetadataSlimList(data: {
  lexiconsMetadataList: ILexiconMetadataSlimAPI[];
}): ILexiconMetadataSlim[] {
  const mappedMetadataList = data.lexiconsMetadataList.map(metadata => {
    const mappedMetadataSlim: ILexiconMetadataSlim = {
      shopId: metadata.shopId,
      locale: metadata.locale,
      publishedAt: parseISODate(metadata.publishedAt),
      createdAt: parseISODate(metadata.createdAt),
      updatedAt: parseISODate(metadata.updatedAt),
    };

    return mappedMetadataSlim;
  });

  return mappedMetadataList;
}

/**
 * Lexicon items structure (to be used for Lexicon feature)
 */
const mapLexiconItems = (lexiconItems: ILexiconItemAPI[]) => {
  const mappedLexiconItems = lexiconItems.map((item): ILexiconItem => {
    return {
      tagKey: item.tagKey,
      verticals: item.verticals,
      category: item.category,
      attribute: item.attribute,
      value: item.value,
      include: item.include,
      isThematic: item.isThematic,
      isLexiconRule: item.isLexiconRule,
      hasUnpublishedChanges: item.hasUnpublishedChanges,
    };
  });

  return mappedLexiconItems;
};

/**
 * CategoriesMap structure (to be used in DeepTagReports feature)
 */
export const mapLexiconCategoriesMap = (lexiconItems: ILexiconItemAPI[]): ILexiconCategories => {
  const categoriesMap = lexiconItems.reduce(
    (accumulator: ILexiconCategories, next: ILexiconItemAPI) => {
      const { category, attribute, value, isThematic } = next;

      if (!accumulator[category.key]) {
        accumulator[category.key] = { ...category, attributes: {} };
      }

      if (!accumulator[category.key].attributes[attribute.key]) {
        accumulator[category.key].attributes[attribute.key] = {
          ...attribute,
          values: {},
          isThematic,
        };
      }

      accumulator[category.key].attributes[attribute.key].values[value.key] = value;

      return accumulator;
    },
    {}
  );

  return categoriesMap;
};

export const mapLexicon = (data: ILexiconAPI): ILexicon => {
  const lexiconApi: ILexiconAPI = data;

  const lexiconMap: ILexicon = {
    items: mapLexiconItems(lexiconApi.items),
    categories: undefined,
    verticals: lexiconApi.verticals,
    isPublished: lexiconApi.isPublished,
  };

  return lexiconMap;
};

export const mapLexiconTagsPagination = (
  response: ILexiconTagsPaginationAPI
): ILexiconTagsPagination => {
  return {
    pagination: response?.pagination,
    data: {
      tags: mapLexiconItems(response.data),
    },
  };
};

export function getDefaultLexiconFileName({
  shopId,
  locale,
}: {
  shopId: number;
  locale: string;
}): string {
  return `Lexicon_${shopId}_${locale}_${format(new Date(), 'dd-MM-yyyy')}.xlsx`;
}
