import React, { useEffect, useState, useCallback } from 'react';
import { generatePath } from 'react-router';
import {
  DeepTagProductStatusType,
  IDeepTagReportProduct,
  DeepTagReportsGeneralConfigurationLabel,
  DeepTagScoreType,
  DeepTagStatusEnum,
} from 'src/services';
import { SelectOnChangeEvent } from 'src/components-dummy/Select';
import { Dispatch } from '../../../types';
import { ProductCard } from '../ProductCard';
import { DeepTagReportProductListActions } from './Actions/DeepTagReportProductListActions';
import { ProductListStyled } from './DeepTagReportProductList.style';

interface DeepTagReportProductsListProps {
  shopId: number;
  reportId: string;
  locale: string;
  products?: IDeepTagReportProduct[];
  getReportProductsWithFilters: () => void;
  selectedScoreTypes: DeepTagScoreType[];
  generalConfigurationLabels: DeepTagReportsGeneralConfigurationLabel[];
  productRoute: string;
  dispatch: Dispatch;
  shouldHideEditIndication: boolean;
}

// TODO: scroll bar to be on page, not on list itself.
// TODO: use dot instead of tag icon

export const DeepTagReportProductsList = ({
  reportId,
  shopId,
  locale,
  products,
  selectedScoreTypes,
  generalConfigurationLabels,
  getReportProductsWithFilters,
  productRoute,
  dispatch,
  shouldHideEditIndication,
}: DeepTagReportProductsListProps): JSX.Element | null => {
  const [isLoading, setIsLoading] = useState(products === undefined);
  const [productsState, setProductsState] = useState(products);

  const onEditProductClick = useCallback(
    ({ productId }: { productId: string }) => {
      dispatch(
        DeepTagReportProductListActions.navigateTo({
          navigateTo: generatePath(productRoute, {
            shopId,
            reportId,
            productId,
          }),
        })
      );
    },
    [reportId, productRoute, shopId]
  );

  const onProductStatusChanged = useCallback(
    (productId: string) =>
      (async event => {
        const newStatus = event.target.value as DeepTagProductStatusType;
        await dispatch(
          DeepTagReportProductListActions.updateProduct({
            shopId,
            productId,
            reportId,
            locale,
            status: newStatus,
          })
        );

        getReportProductsWithFilters();
      }) as SelectOnChangeEvent,
    [shopId, reportId, locale, getReportProductsWithFilters, dispatch]
  );

  useEffect(() => {
    const newIsLoading = products === undefined;

    if (newIsLoading && !isLoading) {
      setIsLoading(true);
    } else if (!newIsLoading && isLoading) {
      setIsLoading(false);
    }

    if (products) {
      setProductsState(products);
    }
  }, [isLoading, products]);

  if (products?.length === 0) {
    return <div>No Products</div>;
  }

  return (
    <>
      <ProductListStyled isLoading={isLoading}>
        {productsState?.map(product => (
          <li key={`${product.id}`}>
            <ProductCard
              key={product.id}
              dispatch={dispatch}
              reportId={reportId}
              shopId={shopId}
              locale={locale}
              productId={product.id}
              labels={product.labels}
              sku={product.sku}
              title={product.title}
              imageUrl={product.imageUrls?.[0]}
              data={product.data}
              tags={product.tags.filter(tag => tag.status !== DeepTagStatusEnum.Deleted)}
              updatedAt={product.updatedAt}
              createdAt={product.createdAt}
              generalConfigurationLabels={generalConfigurationLabels}
              onClick={() => {
                onEditProductClick({ productId: product.id });
              }}
              selectedScoreTypes={selectedScoreTypes}
              status={product.status}
              onProductStatusChange={onProductStatusChanged(product.id)}
              onLabelChangeSuccess={getReportProductsWithFilters}
              shouldHideEditIndication={shouldHideEditIndication}
            />
          </li>
        ))}
      </ProductListStyled>
    </>
  );
};
