import { ApiServiceBase } from '../api-service-base';

import type { GetShopperExperiencesArguments } from './types';

import * as mapper from './mapper';
import { IExperience } from '../types';

export class ShopperExperiences extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getShopperExperiencesPath(shopId: number): string {
    return `${this.serviceBaseUri}/${shopId}/experiences`;
  }

  async getShopperExperiences({ shopId }: GetShopperExperiencesArguments): Promise<IExperience[]> {
    const url = `${this.getShopperExperiencesPath(shopId)}`;
    const response = await this.httpService.get({ url });

    return mapper.shopperExperiencesResponse(response);
  }
}
export const shopperExperiencesService = new ShopperExperiences();
