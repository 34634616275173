import React, { useEffect } from 'react';

import {
  SyteProductPlacement,
  syteProductPlacementToDisplayName,
} from 'src/services/src/service/types/syte-products';
import {
  AvailableIcons,
  Icon,
  MenuItem,
  Select,
  SelectType,
  TextBox,
  Tooltip,
} from '../../../../components-dummy';
import { RuleFormSection } from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormSection/RuleFormSection';
import { RuleFormField } from '../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormField/RuleFormField';

import { ParseJoiValidateResponse } from '../../../../utils';

import { Collection } from '../../types';

import { RuleFormFieldStyled } from './RuleFormField.styles';
import { LabelStyled } from './Label.styles';
import { ErrorLabelStyled } from '../../../MerchandisingRules/components/MerchandisingRuleForm/MerchandisingRuleForm.styles';
import { CollectionSectionStyled } from '../../styles/CollectionSection.styles';
import { CollectionUniqueByField } from '../CollectionUniqueByField';
import { ShopDataField } from '../../../../services';
import { Dispatch } from '../../../types';
import { collectionDetailsActions } from './CollectionDetailsActions';

interface CollectionDetailsProps {
  draftCollection: Pick<Collection, 'name' | 'dataCollectionId' | 'placement' | 'uniqueByField'>;
  handleFieldOnChange: (fieldName: string, value: string | SyteProductPlacement) => void;
  dispatch: Dispatch;
  shopId: number;
  dataFields: ShopDataField[] | undefined;
  errors: ParseJoiValidateResponse<{ name: string }>;
  shouldRenderUniqueByField: boolean;
  isCreateMode?: boolean;
  withoutBorder?: boolean;
}

export const CollectionDetails = ({
  draftCollection,
  handleFieldOnChange,
  shopId,
  dispatch,
  dataFields,
  errors,
  shouldRenderUniqueByField,
  isCreateMode,
  withoutBorder,
}: CollectionDetailsProps): JSX.Element => {
  useEffect(() => {
    if (shopId) {
      dispatch(
        collectionDetailsActions.getDataFields({
          shopId,
        })
      );
    }
  }, [shopId]);

  return (
    <CollectionSectionStyled withoutBorder={withoutBorder}>
      <RuleFormSection.Header>Collection Details</RuleFormSection.Header>
      <RuleFormFieldStyled>
        <RuleFormField.LabelText>Collection name</RuleFormField.LabelText>
        <TextBox
          onChange={text => handleFieldOnChange('name', text)}
          placeholder='Enter text'
          value={draftCollection.name}
          error={!!errors.name}
        />
        {errors?.name?.message && <ErrorLabelStyled>{errors?.name?.message}</ErrorLabelStyled>}
      </RuleFormFieldStyled>

      {!isCreateMode ? (
        <RuleFormFieldStyled>
          <RuleFormField.LabelText>
            <LabelStyled>
              Collection ID
              <Tooltip value='The unique identifier of the collection.'>
                <Icon name={AvailableIcons.TooltipQuestionmark} />
              </Tooltip>
            </LabelStyled>
          </RuleFormField.LabelText>
          <TextBox placeholder={draftCollection.dataCollectionId} disabled />
        </RuleFormFieldStyled>
      ) : null}

      <RuleFormFieldStyled>
        <RuleFormField.LabelText>Collection Placement</RuleFormField.LabelText>
        <Select
          type={SelectType.Primary}
          value={draftCollection.placement}
          onChange={e => handleFieldOnChange('placement', e.target.value as SyteProductPlacement)}
          autoFocus={false}
        >
          {Object.values(SyteProductPlacement).map(placement => (
            <MenuItem key={placement} value={placement}>
              {syteProductPlacementToDisplayName[placement]}
            </MenuItem>
          ))}
        </Select>
      </RuleFormFieldStyled>
      {shouldRenderUniqueByField && (
        <CollectionUniqueByField
          uniqueByField={draftCollection.uniqueByField}
          dataFields={dataFields}
          handleFieldOnChange={handleFieldOnChange}
        />
      )}
    </CollectionSectionStyled>
  );
};
