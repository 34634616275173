import { AccountTypes, APITypes } from 'src/services';

export interface ApiErrorPayload {
  error: APITypes.ApiError;
}

export interface SearchAccountsRequestActionPayload extends AccountTypes.SearchAccountsArgs {
  isLoadMore?: boolean;
}

export type SearchAccountsErrorActionPayload = ApiErrorPayload;

export interface SearchAccountsSuccessActionPayload {
  accounts: AccountTypes.AccountSearchResult[];
  isLoadMore?: boolean;
}

/* --= Action Types =-- */
export enum AccountListSectionActionTypes {
  SearchAccountsRequest = 'AccountListSection/SearchAccountsRequest',
  SearchAccountsError = 'AccountListSection/SearchAccountsError',
  SearchAccountsSuccess = 'AccountListSection/SearchAccountsSuccess',
}

/* --= Action Interfaces =-- */
export interface SearchAccountsRequestAction {
  type: typeof AccountListSectionActionTypes.SearchAccountsRequest;
  payload: SearchAccountsRequestActionPayload;
}

export interface SearchAccountsErrorAction {
  type: typeof AccountListSectionActionTypes.SearchAccountsError;
  payload: SearchAccountsErrorActionPayload;
}

export interface SearchAccountsSuccessAction {
  type: typeof AccountListSectionActionTypes.SearchAccountsSuccess;
  payload: SearchAccountsSuccessActionPayload;
}

export type AccountListSectionAction =
  | SearchAccountsRequestAction
  | SearchAccountsErrorAction
  | SearchAccountsSuccessAction;
