export enum GroupedValuePathsEnum {
  actionWeight = 'actionWeight',
  bucketsOrderSortType = 'bucketsOrderSortType',
}

export enum ValuePathByGroupName {
  weight = GroupedValuePathsEnum.actionWeight,
  action = GroupedValuePathsEnum.actionWeight,
  'bucketsOrder.sort' = GroupedValuePathsEnum.bucketsOrderSortType,
  'bucketsOrder.type' = GroupedValuePathsEnum.bucketsOrderSortType,
}

export enum DiffActionType {
  Created = 'created',
  Changed = 'changed',
  Deleted = 'deleted',
}

export enum PreviewTypeEnum {
  REVIEW_BEFORE_PUBLISH = 'REVIEW_BEFORE_PUBLISH',
  REVIEW_VERSION = 'REVIEW_VERSION',
}
