import { useCallback, useEffect, useRef } from 'react';
import { Dispatch } from 'redux';
import { useAppSelector } from '../../../hooks';
import { useGetPreviewExperiences } from './useGetPreviewExperiences';
import { useGetCollectionsList } from '../../Collections/CollectionsList/useGetCollectionsList';
import { useGetMerchRules } from './useGetMerchRules';
import { AsyncDispatch, GetPayload, IndexedProductOffers, ProductOffersThunk } from '../types';
import { visualEditorActions } from '../state';
import { AsyncThunkPromise } from 'src/types';

type Ref = { action: any; payload: any } | null;

export const useFetchExperienceOffers = (shopId: number, appDispatch: Dispatch<any>) => {
  const { selectedExperience: experience, collection } = useAppSelector(
    ({ visualEditor }) => visualEditor
  );

  const fetchOffersData = useRef<Ref>(null);

  const dispatchOffersThunk = useCallback(
    <A extends ProductOffersThunk, P>(action: A, payload: P) => {
      fetchOffersData.current = { action, payload };
      return (appDispatch as AsyncDispatch)(action(payload)) as AsyncThunkPromise<
        IndexedProductOffers,
        P
      >;
    },
    [appDispatch]
  );

  const fetchOffers = useCallback(() => {
    if (!fetchOffersData.current) return;
    const { action, payload } = fetchOffersData.current;
    (appDispatch as AsyncDispatch)(action(payload));
  }, [appDispatch]);

  const getPayload: GetPayload = useCallback(() => {
    if (!fetchOffersData.current) return null;
    return fetchOffersData.current.payload;
  }, []);

  useGetPreviewExperiences({ shopId, appDispatch });
  useGetCollectionsList({ shopId, dispatch: appDispatch });
  useGetMerchRules({
    entityId: collection?.id,
    appDispatch,
    experience,
    shopId,
  });

  useEffect(() => {
    return () => {
      appDispatch(visualEditorActions.resetState());
    };
  }, []);

  return { dispatchOffersThunk, fetchOffers, getPayload };
};
