import React, { useCallback, useState } from 'react';
import { AvailableIcons } from 'src/components-dummy';
import { BaseBulkActionProps } from '../BulkActions.types';
import { ActionButtonStyled } from '../BulkActions.styles';
import { lexiconBulkActions } from '../LexiconBulkAction.actions';
import { IncludeTagsModal } from './IncludeTagsModal';

type LexiconBulkActionIncludeProps = BaseBulkActionProps;

export const LexiconBulkActionInclude = ({
  targetItemsCounts,
  matchTags,
  excludeTags,
  shopId,
  locale,
  tagType,
  filters,
  dispatch,
  targetAllTags,
  onActionSuccess,
  onActionFail,
  onActionStart,
}: LexiconBulkActionIncludeProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<'on' | 'off'>('on');

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = (mode: 'on' | 'off') => {
    setModalMode(mode);
    setShowModal(true);
  };

  const onIncludeClick = useCallback(() => {
    onActionStart();

    if (targetAllTags) {
      (
        dispatch(
          lexiconBulkActions.includeAll({
            shopId,
            locale,
            tagType,
            excludeTags: [...excludeTags],
            include: modalMode === 'on',
            filters,
          })
        ) as any
      )
        .unwrap()
        .then(() => {
          onActionSuccess();
        })
        .catch((error: Error) => {
          console.error(error);
          onActionFail();
        });
    } else {
      (
        dispatch(
          lexiconBulkActions.include({
            shopId,
            locale,
            tagType,
            matchTags: [...matchTags],
            include: modalMode === 'on',
          })
        ) as any
      )
        .unwrap()
        .then(() => {
          onActionSuccess();
        })
        .catch((error: Error) => {
          console.error(error);
          onActionFail();
        });
    }

    setShowModal(false);
  }, [
    dispatch,
    shopId,
    locale,
    tagType,
    matchTags,
    excludeTags,
    targetAllTags,
    filters,
    modalMode,
    onActionFail,
    onActionStart,
    onActionSuccess,
  ]);

  return (
    <>
      <ActionButtonStyled
        marginRight={1}
        variant='secondary'
        onClick={() => openModal('on')}
        startIcon={AvailableIcons.Eye}
        clicked={showModal && modalMode === 'on'}
      >
        Show tags
      </ActionButtonStyled>
      <ActionButtonStyled
        marginRight={24}
        variant='secondary'
        onClick={() => openModal('off')}
        startIcon={AvailableIcons.EyeCrossed}
        clicked={showModal && modalMode === 'off'}
      >
        Hide tags
      </ActionButtonStyled>

      {showModal && (
        <IncludeTagsModal
          onCancel={closeModal}
          tagsCount={targetItemsCounts}
          onIncludeClick={onIncludeClick}
          mode={modalMode}
        />
      )}
    </>
  );
};
