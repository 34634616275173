import styled from '@emotion/styled';
import { SectionBoxStyled } from './components/shared.styles';

export const FilterFormStyled = styled.form`
  ${SectionBoxStyled}:not(:first-of-type) {
    margin-top: 24px;
  }

  display: flex;
  flex-direction: column;
  gap: 16px;
`;
