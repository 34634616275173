import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import './StatusCard.scss';

export enum StatusCardStatusColor {
  Green = 'green',
  Yellow = 'yellow',
  Red = 'red',
  Black = 'black',
}

export interface StatusCardProps {
  children: ReactNode | ReactNode[];
  statusText?: string;
  statusColor?: StatusCardStatusColor;
  className?: string;
}

export const StatusCard = ({
  children,
  className,
  statusText,
  statusColor: statusType,
}: StatusCardProps): JSX.Element => {
  let statusJsx: JSX.Element | undefined;

  if (statusText) {
    const statusColorClass = statusType ? `syte-status-card-status--${statusType}` : undefined;

    statusJsx = (
      <div className={classNames('syte-status-card-status', statusColorClass)}>
        <div className='syte-status-card-status-text'>
          <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
            {statusText}
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('syte-status-card', className)}>
      {statusJsx}
      <div className='syte-status-card-content'>{children}</div>
    </div>
  );
};
