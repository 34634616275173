import { RecentlyViewedSettings } from 'src/services/src/service/types/syte-products/recently-viewed';

export enum RecentlyViewedActionTypes {
  GetRecentlyViewedSettingsRequest = 'RecentlyViewed/GetRecentlyViewedSettingsRequest',
  GetRecentlyViewedSettingsSuccess = 'RecentlyViewed/GetRecentlyViewedSettingsSuccessAction',
  GetRecentlyViewedSettingsError = 'RecentlyViewed/GetRecentlyViewedSettingsError',
  UpdateRecentlyViewedSettingsRequest = 'RecentlyViewed/UpdateRecentlyViewedSettingsRequest',
  UpdateRecentlyViewedSettingsSuccess = 'RecentlyViewed/UpdateRecentlyViewedSettingsSuccess',
  UpdateRecentlyViewedSettingsError = 'RecentlyViewed/UpdateRecentlyViewedSettingsError',
  NotifyRecentlyViewedSettingsIsDirty = 'RecentlyViewed/NotifyIsDirty',
  PartialRecentlyViewedSettingsUpdate = 'RecentlyViewed/PartialRecentlyViewedSettingsUpdate',
  NavigateTo = 'RecentlyViewed/NavigateTo',
}

export interface RecentlyViewedSettingsRequestBase {
  shopId: number;
  variantId?: string;
}

export interface UpdateRecentlyViewedSettingsPayload
  extends RecentlyViewedSettings,
    RecentlyViewedSettingsRequestBase {}

export type GetRecentlyViewedSettingsArgs = RecentlyViewedSettingsRequestBase;

export interface GetRecentlyViewedSettingsRequestPayload {
  shopId: number;
}

export interface GetRecentlyViewedSettingsSuccessPayload
  extends GetRecentlyViewedSettingsRequestPayload,
    RecentlyViewedSettings {}

export interface GetRecentlyViewedSettingsErrorPayload {
  error: unknown;
}

export interface UpdateRecentlyViewedSettingsRequestPayload extends RecentlyViewedSettings {
  shopId: number;
}

export type UpdateRecentlyViewedSettingsSuccessPayload = UpdateRecentlyViewedSettingsRequestPayload;

export interface UpdateRecentlyViewedSettingsErrorPayload {
  error: unknown;
}

export interface NotifyRecentlyViewedSettingsIsDirtyActionPayload {
  isDirty: boolean;
}

export interface GetRecentlyViewedSettingsRequestAction {
  type: typeof RecentlyViewedActionTypes.GetRecentlyViewedSettingsRequest;
  payload: GetRecentlyViewedSettingsRequestPayload;
}

export interface GetRecentlyViewedSettingsSuccessAction {
  type: typeof RecentlyViewedActionTypes.GetRecentlyViewedSettingsSuccess;
  payload: GetRecentlyViewedSettingsSuccessPayload;
}

export interface GetRecentlyViewedSettingsErrorAction {
  type: typeof RecentlyViewedActionTypes.GetRecentlyViewedSettingsError;
  payload: GetRecentlyViewedSettingsErrorPayload;
}

export interface UpdateRecentlyViewedSettingsRequestAction {
  type: typeof RecentlyViewedActionTypes.UpdateRecentlyViewedSettingsRequest;
  payload: UpdateRecentlyViewedSettingsRequestPayload;
}

export interface UpdateRecentlyViewedSettingsSuccessAction {
  type: typeof RecentlyViewedActionTypes.UpdateRecentlyViewedSettingsSuccess;
  payload: UpdateRecentlyViewedSettingsSuccessPayload;
}

export interface UpdateRecentlyViewedSettingsErrorAction {
  type: typeof RecentlyViewedActionTypes.UpdateRecentlyViewedSettingsError;
  payload: UpdateRecentlyViewedSettingsErrorPayload;
}

export interface NotifyRecentlyViewedSettingsIsDirtyAction {
  type: typeof RecentlyViewedActionTypes.NotifyRecentlyViewedSettingsIsDirty;
  payload: NotifyRecentlyViewedSettingsIsDirtyActionPayload;
}

export type RecentlyViewedSettingsAction =
  | GetRecentlyViewedSettingsRequestAction
  | GetRecentlyViewedSettingsSuccessAction
  | GetRecentlyViewedSettingsErrorAction
  | UpdateRecentlyViewedSettingsRequestAction
  | UpdateRecentlyViewedSettingsSuccessAction
  | UpdateRecentlyViewedSettingsErrorAction
  | NotifyRecentlyViewedSettingsIsDirtyAction;
