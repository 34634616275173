import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { DeepTagReportsService, DeepTagsReportsConfiguration } from 'src/services';

const deepTagReportsService = new DeepTagReportsService();

export interface UpdateReportConfigurationArguments
  extends Partial<Omit<DeepTagsReportsConfiguration, 'shopId'>> {
  shopId: number;
}

export const deepTagReportSFTPSettingsModalActions = {
  updateConfiguration: createAsyncThunk(
    'DeepTagReportSFTPSettingsModal/UpdateConfiguration',
    async ({ shopId, ...payload }: UpdateReportConfigurationArguments, { rejectWithValue }) => {
      try {
        const updatedConfiguration = await deepTagReportsService.updateReportConfiguration({
          shopId,
          ...payload,
        });
        return updatedConfiguration;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateIsDirty: createAction<{ isDirty?: boolean }>(
    'DeepTagReportSFTPSettingsModal/NotifyIsDirty'
  ),
};
