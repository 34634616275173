import styled from '@emotion/styled';

export const CardStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 12px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.custom['gray-60']};

  box-shadow:
    0px 6px 12px -6px #0000001f,
    0px 8px 24px -4px #00000014;
`;
