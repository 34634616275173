import styled from '@emotion/styled';
import { ListV2, SearchInput } from 'src/components-dummy';

export const SearchInputStyled = styled(SearchInput)`
  padding-bottom: 5px;
`;

export const MenuSingleSelectTagContentStyled = styled.div`
  padding: 8px;
  width: 182px;
  border-radius: 8px;

  .input-base-wrapper {
    background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  }

  p:first-of-type {
    padding-bottom: 10px;
    padding-left: 10px;
  }
`;

export const ListV2Styled = styled(ListV2)`
  width: 100%;
  padding: 0;

  max-height: 258px; // 6 items
  min-height: 20px;

  overflow: hidden;
  margin: 0;

  &:hover {
    overflow: auto;
  }

  li {
    margin-top: 2px;
    padding: 8px;
    border-radius: 4px;
  }

  li:hover {
    background: ${({ theme }) => theme.palette.custom['gray-90']};
    cursor: pointer;
  }
`;

export const DeepTagsMultiSelectTriggerButtonStyled = styled.div<{
  isClickable?: boolean;
  clicked?: boolean;
}>`
  background-color: ${({ theme, isClickable, clicked }) => {
    if (isClickable && clicked) {
      return theme.palette.custom['primary-90'];
    }
    return theme.palette.custom['gray-80'];
  }};
  transition: background-color 0.1s;
  user-select: none;
  ${({ isClickable }) => (isClickable ? 'cursor: pointer' : '')}
  &:hover {
    background-color: ${({ theme }) => {
      return theme.palette.custom['primary-90'];
    }};
  }
`;
