import Joi from 'joi';

export const MIN_TOTAL_PRODUCTS = 1;

export const deepTagBulkCustomFormItemSchema = Joi.object({
  id: Joi.string().optional(),
  labelId: Joi.string().required().messages({
    'string.empty': 'Label cannot be empty',
    'any.required': 'Label is required',
  }),
  totalProducts: Joi.number().min(MIN_TOTAL_PRODUCTS).required().messages({
    'number.base': 'Total must be a number',
    'any.required': 'Minimum amount to label is 1 product',
  }),
});
