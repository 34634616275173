import React from 'react';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy/Typography';
import { SideNavRoute } from '../../types';
import { PageSideNavGroupDivider, PageSideNavTitleStyled } from './PageSideNav.styled';
import { PageSideNavItemLink } from './PageSideNavItemLink';

export interface RouteGroup {
  title?: string;
  routes: SideNavRoute[];
}

interface PageSideNavGroupProps {
  isFinalGroup: boolean;
  routeGroup: RouteGroup;
  handleNavigate: (url: string) => void;
}

export function PageSideNavGroup({
  isFinalGroup,
  routeGroup,
  handleNavigate,
}: PageSideNavGroupProps): JSX.Element {
  const { routes, title } = routeGroup;
  return (
    <>
      {title && (
        <PageSideNavTitleStyled>
          <Typography variant={TypographyVariant.MediumBold} type={TypographyType.Body}>
            {title}
          </Typography>
        </PageSideNavTitleStyled>
      )}
      {routes.map(route => (
        <PageSideNavItemLink key={route.title} route={route} handleNavigate={handleNavigate} />
      ))}
      {!isFinalGroup && <PageSideNavGroupDivider />}
    </>
  );
}
