import { v4 as uuidV4 } from 'uuid';
import { IThematicTag } from 'src/services';
import { IThematicTagDraft } from './ThematicTagForm.types';
import { initialThematicTagDraft } from './constants';

export function mapThematicTagDraft(thematicTag: IThematicTag): IThematicTagDraft {
  return {
    category: thematicTag.category.customTranslation,
    attribute: thematicTag.attribute.customTranslation,
    value: thematicTag.value.customTranslation,
    conditionsGroups: thematicTag.conditionsGroups.map(group => {
      const groupId = uuidV4();
      return {
        id: groupId,
        conditions: group.conditions.map(condition => {
          const conditionId = uuidV4();
          return {
            ...condition,
            id: conditionId,
          };
        }),
      };
    }),
  };
}

export function mapThematicTagDraftToApi(
  thematicTagDraft: IThematicTagDraft & { include: boolean }
): Omit<IThematicTag, 'id' | 'createdAt' | 'updatedAt'> {
  return {
    include: thematicTagDraft.include,
    category: { customTranslation: thematicTagDraft.category },
    attribute: { customTranslation: thematicTagDraft.attribute },
    value: { customTranslation: thematicTagDraft.value },
    conditionsGroups: thematicTagDraft.conditionsGroups.map(({ conditions }) => {
      return {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        conditions: conditions.map(({ id, ...condition }) => {
          return condition;
        }),
      };
    }),
  };
}

export function calculateNewThematicDraftState({
  currentState,
  partialNewState,
}: {
  currentState: IThematicTagDraft;
  partialNewState: Partial<IThematicTagDraft>;
}): { updatedState: IThematicTagDraft } {
  const updatedState = { ...currentState, ...partialNewState };

  const isCategoryChange = updatedState.category !== currentState.category;

  // category change side effects
  if (isCategoryChange) {
    updatedState.attribute = initialThematicTagDraft.attribute;
  }

  const isAttributeChange = updatedState.attribute !== currentState.attribute;

  // attribute change side effects
  if (isAttributeChange) {
    updatedState.value = initialThematicTagDraft.value;
  }

  // clean up groups with no rules
  updatedState.conditionsGroups = updatedState.conditionsGroups.filter(
    conditionGroup => conditionGroup.conditions.length > 0
  );

  return { updatedState };
}
