import { SerializedStyles } from '@emotion/react';
import React, { ReactNode } from 'react';
import { TypographyProps, TypographyType, TypographyVariant } from 'src/components-dummy';
import { LabelStyled, CatalogFormFieldTextStyled } from './CatalogFormField.styles';

export interface CatalogFormFieldProps {
  children: JSX.Element | JSX.Element[] | ReactNode | ReactNode[];
  className?: string;
  css?: SerializedStyles;
}

export const CatalogFormField = ({
  children,
  className,
  css,
}: CatalogFormFieldProps): JSX.Element => {
  return (
    <LabelStyled className={className} css={css}>
      {children}
    </LabelStyled>
  );
};

export type CatalogFormFieldTextProps = TypographyProps;

export const CatalogFormFieldText = ({
  children,
  ...rest
}: CatalogFormFieldTextProps): JSX.Element => {
  return (
    <CatalogFormFieldTextStyled
      type={TypographyType.Body}
      variant={TypographyVariant.SmallMedium}
      {...rest}
    >
      {children}
    </CatalogFormFieldTextStyled>
  );
};

CatalogFormField.LabelText = CatalogFormFieldText;
