import React from 'react';
import { Typography, TypographyType, TypographyVariant } from '../../../../components-dummy';
import { ExternalLink } from '../ExternalLink';
import { SideBarLinkWrapperStyled } from './SideBarLink.styles';

interface SideBarLinkProps {
  navigateToEditRelevancyTuning: VoidFunction;
}

export function SideBarLink({ navigateToEditRelevancyTuning }: SideBarLinkProps): JSX.Element {
  return (
    <SideBarLinkWrapperStyled>
      <Typography type={TypographyType.Body} variant={TypographyVariant.LargeRegular}>
        Search Relevancy Tuning
      </Typography>
      <ExternalLink onClick={navigateToEditRelevancyTuning}>Edit Tuning</ExternalLink>
    </SideBarLinkWrapperStyled>
  );
}
