import { Users } from 'src/services';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';

const usersService = new Users();

export const resetPasswordActions = {
  invalidToken: createAction('ResetPassword/InvalidToken'),
  submit: createAsyncThunk(
    'ResetPassword/Reset',
    async (
      { newPassword, resetToken }: { newPassword: string; resetToken: string },
      { rejectWithValue }
    ) => {
      try {
        await usersService.resetPassword({ newPassword, resetToken });

        return true;
      } catch (error) {
        console.error(error);
        return rejectWithValue({ error });
      }
    }
  ),
};
