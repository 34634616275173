import { ApiServiceBase } from '../api-service-base';
import {
  mapDraftResponse,
  mapVersionResponse,
  mapVersionsResponse,
  mapCreateVersionResponse,
} from './versioning-mapper';
import { buildRestoreFromVersionPayload } from './versioning-builder';
import { DomainEntityPath, ShopDraft, ShopVersion, SlimShopVersion } from '../types';
import {
  GetShopDraftArguments,
  GetShopVersionsArguments,
  GetShopVersionArguments,
  RestoreFromVersionArguments,
} from './types';

export class Versioning extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getVersioningPath(shopId: number): string {
    return `${this.serviceBaseUri}/${shopId}/versions`;
  }

  async getShopDraft({ shopId, slim }: GetShopDraftArguments): Promise<ShopDraft> {
    const url = `${this.getVersioningPath(shopId)}/draft`;
    const params = { slim };
    const response = await this.httpService.get({ url, requestConfig: { params } });
    return mapDraftResponse(response);
  }

  async createVersion(
    shopId: number,
    selectedDomainGroups: DomainEntityPath[]
  ): Promise<ShopVersion> {
    const url = `${this.getVersioningPath(shopId)}`;
    const response = await this.httpService.post({
      url,
      data: { domainsToPublish: selectedDomainGroups },
    });
    return mapCreateVersionResponse(response);
  }

  async discardShopDraft(shopId: number, selectedDomainGroups: DomainEntityPath[]): Promise<void> {
    const url = `${this.getVersioningPath(shopId)}/draft`;
    const queryParams = { domainsToDiscard: selectedDomainGroups };
    await this.httpService.delete({ url, requestConfig: { params: queryParams } });
  }

  async getShopVersions({ shopId, slim }: GetShopVersionsArguments): Promise<SlimShopVersion[]> {
    const url = `${this.getVersioningPath(shopId)}`;
    const params = { slim };
    const response = await this.httpService.get({ url, requestConfig: { params } });
    return mapVersionsResponse(response);
  }

  async getShopVersion({ shopId, slim, id }: GetShopVersionArguments): Promise<ShopVersion> {
    const url = `${this.getVersioningPath(shopId)}/${id}`;
    const params = { slim };
    const response = await this.httpService.get({ url, requestConfig: { params } });
    return mapVersionResponse(response);
  }

  async restoreFromVersion({ shopId, id }: RestoreFromVersionArguments): Promise<ShopDraft> {
    const url = `${this.getVersioningPath(shopId)}/draft`;
    const payload = buildRestoreFromVersionPayload({ id });
    const response = await this.httpService.put({ url, data: payload });
    return mapDraftResponse(response);
  }
}

export const versioningService = new Versioning();
