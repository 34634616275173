import { DeepTag } from 'src/services/src';
import { GENERATED_DESCRIPTION, GENERATED_TITLE } from './DeepTagsGenerativeAIForm.config';

export const createTagsForGeneratedText = (tags: DeepTag[]): Record<string, string> => {
  const isNotGeneratedAttributes = (tag: DeepTag) =>
    tag.attribute.key !== GENERATED_DESCRIPTION && tag.attribute.key !== GENERATED_TITLE;

  const productCleanObject = tags
    .filter(tag => isNotGeneratedAttributes(tag))
    .reduce((prev: Record<string, string>, next): Record<string, string> => {
      const attributeKeyTranslation = next.attribute.translation || next.attribute.key;
      const attributeValueTranslation = next.value.translation || next.value.key;

      // eslint-disable-next-line no-param-reassign
      prev[attributeKeyTranslation] = attributeValueTranslation;
      return prev;
    }, {});

  return productCleanObject;
};
