/* --= Action Types =-- */
import { AddMultipleToCart } from '../../../app-state-types/reducer-state-types/add-to-cart';

export enum ShopTheLookActionTypes {
  GetShopTheLookSettingsRequest = 'ShopTheLook/GetShopTheLookSettingsRequest',
  GetShopTheLookSettingsSuccess = 'ShopTheLook/GetShopTheLookSettingsSuccessAction',
  GetShopTheLookSettingsError = 'ShopTheLook/GetShopTheLookSettingsError',
  UpdateShopTheLookSettingsRequest = 'ShopTheLook/UpdateShopTheLookSettingsRequest',
  UpdateShopTheLookSettingsSuccess = 'ShopTheLook/UpdateShopTheLookSettingsSuccess',
  UpdateShopTheLookSettingsError = 'ShopTheLook/UpdateShopTheLookSettingsError',
  NotifyShopTheLookSettingsIsDirty = 'ShopTheLook/NotifyIsDirty',
  PartialShopTheLookSettingsUpdate = 'ShopTheLook/PartialShopTheLookSettingsUpdate',
  NavigateTo = 'ShopTheLook/NavigateTo',
}

export interface UpdateShopTheLookSettingsPayload {
  shopId: number;
  variantId?: string;
  numberOfResultsToDisplay: number;
  includeMainProductInCarousel: boolean;
  shuffleResults: boolean;
  completeTheLookFallbackMethod: string;
  addToCart: AddMultipleToCart;
}

export interface GetShopTheLookSettingsArgs {
  shopId: number;
  variantId?: string;
}

export interface GetShopTheLookSettingsRequestPayload {
  shopId: number;
}

export interface GetShopTheLookSettingsSuccessPayload {
  shopId: number;
  numberOfResultsToDisplay: number;
  includeMainProductInCarousel: boolean;
  shuffleResults: boolean;
  completeTheLookFallbackMethod: string;
  addToCart: AddMultipleToCart;
}

export interface GetShopTheLookSettingsErrorPayload {
  error: unknown;
}
export interface UpdateShopTheLookSettingsRequestPayload {
  shopId: number;
  numberOfResultsToDisplay: number;
  includeMainProductInCarousel: boolean;
  shuffleResults: boolean;
  completeTheLookFallbackMethod: string;
  addToCart: AddMultipleToCart;
}

export interface UpdateShopTheLookSettingsSuccessPayload {
  shopId: number;
  numberOfResultsToDisplay: number;
  includeMainProductInCarousel: boolean;
  shuffleResults: boolean;
  completeTheLookFallbackMethod: string;
  addToCart: AddMultipleToCart;
}

export interface UpdateShopTheLookSettingsErrorPayload {
  error: unknown;
}

export interface NotifyShopTheLookSettingsIsDirtyActionPayload {
  isDirty: boolean;
}

/* --= Action Interfaces =-- */

export interface GetShopTheLookSettingsRequestAction {
  type: typeof ShopTheLookActionTypes.GetShopTheLookSettingsRequest;
  payload: GetShopTheLookSettingsRequestPayload;
}

export interface GetShopTheLookSettingsSuccessAction {
  type: typeof ShopTheLookActionTypes.GetShopTheLookSettingsSuccess;
  payload: GetShopTheLookSettingsSuccessPayload;
}

export interface GetShopTheLookSettingsErrorAction {
  type: typeof ShopTheLookActionTypes.GetShopTheLookSettingsError;
  payload: GetShopTheLookSettingsErrorPayload;
}

export interface UpdateShopTheLookSettingsRequestAction {
  type: typeof ShopTheLookActionTypes.UpdateShopTheLookSettingsRequest;
  payload: UpdateShopTheLookSettingsRequestPayload;
}

export interface UpdateShopTheLookSettingsSuccessAction {
  type: typeof ShopTheLookActionTypes.UpdateShopTheLookSettingsSuccess;
  payload: UpdateShopTheLookSettingsSuccessPayload;
}

export interface UpdateShopTheLookSettingsErrorAction {
  type: typeof ShopTheLookActionTypes.UpdateShopTheLookSettingsError;
  payload: UpdateShopTheLookSettingsErrorPayload;
}

export interface NotifyShopTheLookSettingsIsDirtyAction {
  type: typeof ShopTheLookActionTypes.NotifyShopTheLookSettingsIsDirty;
  payload: NotifyShopTheLookSettingsIsDirtyActionPayload;
}

export type ShopTheLookSettingsAction =
  | GetShopTheLookSettingsRequestAction
  | GetShopTheLookSettingsSuccessAction
  | GetShopTheLookSettingsErrorAction
  | UpdateShopTheLookSettingsRequestAction
  | UpdateShopTheLookSettingsSuccessAction
  | UpdateShopTheLookSettingsErrorAction
  | NotifyShopTheLookSettingsIsDirtyAction;
