import { ApiServiceBase } from '../api-service-base';
import { FiltersConfigTypes } from '../types';

type FilterSet = FiltersConfigTypes.FilterSetConfiguration;
export class Filters extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getFiltersSetsPath(shopId: number): string {
    return `${this.serviceBaseUri}/${shopId}/syte-products/augmented_search/filter_set`;
  }

  private getFiltersSegmentPath(shopId: number, segmentId: string): string {
    return `${this.serviceBaseUri}/${shopId}/syte-products/augmented_search/filter_set/segment/${segmentId}`;
  }

  async getFilterSets(shopId: number): Promise<FilterSet[]> {
    const url = this.getFiltersSetsPath(shopId);
    const response = await this.httpService.get({ url });
    return response.data.filterSet;
  }

  async updateFilterSetSegment(shopId: number, segmentPayload: FilterSet): Promise<FilterSet[]> {
    const url = this.getFiltersSegmentPath(shopId, segmentPayload.id);
    const response = await this.httpService.patch({ url, data: segmentPayload });
    return response.data.filterSet;
  }
}
