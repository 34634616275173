import { CollectionsTableHeadRow } from './types';

export const tableColumns: Record<string, CollectionsTableHeadRow> = {
  name: {
    header: 'Collection Name',
    accessor: 'name',
    width: '80%',
  },
  lastUpdated: {
    header: 'Last Edit',
    accessor: 'lastUpdated',
    width: '15%',
  },
  rowActions: {
    header: '',
    accessor: 'rowActions',
    width: '5%',
  },
};
