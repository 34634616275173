import React, { useCallback, useRef } from 'react';
import {
  AvailableIcons,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { fileAcceptTypes, FileUploadButtonApiRef } from 'src/components-dummy/FileUploader';
import { ButtonContentStyled, FileUploadButtonStyled } from './UploadLexiconFileButton.styles';

const MAX_IMPORT_FILE_SIZE_MB = 100;
const MAX_NUMBER_OF_FILES = 1;

interface UploadLexiconFileButtonProps {
  lexiconFiles: File[];
  onUpload: (selectedFiles: File[]) => void;
}

export function UploadLexiconFileButton({
  lexiconFiles,
  onUpload,
}: UploadLexiconFileButtonProps): JSX.Element {
  const uploadButtonApiRef = useRef<FileUploadButtonApiRef | null>(null);

  const onUploadButtonClick = useCallback(() => {
    uploadButtonApiRef.current?.open();
  }, []);

  return (
    <FileUploadButtonStyled
      accept={fileAcceptTypes.XLSX}
      fileCardIconName={AvailableIcons.XlsFile}
      maxFiles={MAX_NUMBER_OF_FILES}
      maxSizeMb={MAX_IMPORT_FILE_SIZE_MB}
      onChange={onUpload}
      onClick={onUploadButtonClick}
      ref={uploadButtonApiRef}
      selectedFiles={lexiconFiles}
    >
      <ButtonContentStyled>
        <Icon name={AvailableIcons.Page} />{' '}
        <Typography variant={TypographyVariant.MediumMedium} type={TypographyType.Button}>
          Upload file
        </Typography>
      </ButtonContentStyled>
    </FileUploadButtonStyled>
  );
}
