import styled from '@emotion/styled';
import React from 'react';
import {
  Typography,
  TypographyProps,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';

export const CategoryContentStyled = styled.div`
  padding-top: 16px;
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};
`;

export const CategoryLexiconStyled = styled.div`
  & > div > div {
    padding-left: 0;
    padding-top: 0;
  }
`;

export const RadioGroupWrapperStyled = styled.div`
  margin-bottom: 24px;
`;

export const RadioOptionWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
  }
`;

export const ContentCustomStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 40px 0px 40px;
`;

export const SubTitleStyled = styled.div`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  margin-bottom: 16px;
`;

export const ErrorsMessagesWrapperStyled = styled.div`
  margin-bottom: 32px;
`;

export const ErrorLabelStyled = styled((props: TypographyProps) => (
  <Typography {...props} type={TypographyType.Paragraph} variant={TypographyVariant.SmallRegular} />
))`
  color: ${({ theme }) => theme.palette.custom.red};
  margin-top: 4px;
`;
