import { useMemo } from 'react';
import {
  isRulePublished,
  validateRuleRegionsAreAvailable,
} from '../MerchandisingRules/merchandisingRules.helpers';
import { ChangesSummaryLookupTable, MerchandisingRuleTypes, ShopDraft } from '../../services';
import { useValidateMerchRuleDataFields } from './useValidateMerchRuleDataFields';
import { DataFieldLookupTable } from '../MerchandisingRules/components/useDataFieldsLookupTable';
import { MerchandisingRuleWithExtraIndications } from '../MerchandisingRules/components/MerchandisingRulesListPage/components';

export interface Params {
  availableRegions?: string[];
  merchandisingRules?: MerchandisingRuleTypes.MerchandiseRule[] | null;
  changesSummaryLookupTable?: ChangesSummaryLookupTable;
  dataFieldsLookupTable?: DataFieldLookupTable;
  draft?: ShopDraft;
}

export const useRulesWithExtraIndicators = ({
  dataFieldsLookupTable,
  merchandisingRules,
  availableRegions,
  draft,
}: Params): MerchandisingRuleWithExtraIndications[] | undefined => {
  const validateMerchandisingRuleDataFields = useValidateMerchRuleDataFields(dataFieldsLookupTable);

  return useMemo(() => {
    if (!availableRegions || !merchandisingRules) return undefined;

    return merchandisingRules.map(rule => {
      const isPublished = isRulePublished({
        ruleId: rule.id,
        lookupTable: draft?.changesSummary.changesSummaryLookupTable,
      });

      const isUsingInvalidDataField = validateMerchandisingRuleDataFields(rule);

      const hasOnlyValidRegions = validateRuleRegionsAreAvailable({
        selectedRegions: rule.regions || [],
        availableRegions,
      });

      const isInvalidEmptyRegions = rule.regions?.length === 0 && availableRegions.length > 0;

      return {
        ...rule,
        isPublished,
        isUsingInvalidDataField,
        hasOnlyValidRegions,
        isInvalidEmptyRegions,
      };
    });
  }, [
    draft?.changesSummary.changesSummaryLookupTable,
    merchandisingRules,
    validateMerchandisingRuleDataFields,
    availableRegions,
  ]);
};
