import React from 'react';
import { RulesSubtitle } from './RulesSubtitle';
import { MerchandisingRulesListContainer } from './MerchandisingRulesListContainer';
import { MerchandisingRule } from './MerchandisingRule';
import { MerchRulesListSkeleton } from './MerchRulesListSkeleton';
import { PreviewMerchRule } from '../../types';

interface Props {
  loading: boolean;
  showFilteredRules: boolean;
  merchandisingRules: PreviewMerchRule[] | null;
  onRuleClick: (rule: PreviewMerchRule) => void;
  onRuleToggle: (rule: PreviewMerchRule) => void;
  selectedRuleId?: string;
}

export const MerchandisingRulesList = ({
  loading,
  showFilteredRules,
  merchandisingRules,
  onRuleClick,
  onRuleToggle,
  selectedRuleId,
}: Props): JSX.Element => {
  if (loading) return <MerchRulesListSkeleton />;

  if (showFilteredRules && !merchandisingRules?.length)
    return <RulesSubtitle>No rules found</RulesSubtitle>;

  if (!merchandisingRules?.length)
    return <RulesSubtitle>No rules yet. Add a new rule to tailor your results</RulesSubtitle>;

  return (
    <MerchandisingRulesListContainer>
      {merchandisingRules.map(rule => (
        <MerchandisingRule
          key={rule.id}
          rule={rule}
          onRuleClick={onRuleClick}
          onRuleToggle={onRuleToggle}
          className={rule.id === selectedRuleId ? 'selected-rule' : ''}
        />
      ))}
    </MerchandisingRulesListContainer>
  );
};
