import { createAsyncThunk } from '@reduxjs/toolkit';
import { Auth } from 'src/services';

const authService = new Auth();

export const verifyOneTimeLinkActions = {
  verifyToken: createAsyncThunk(
    'VerifyOneTimeLink/VerifyToken',
    async (token: string, { rejectWithValue }) => {
      try {
        const { user, twoFactorAuthRequired } = await authService.verifyToken(token);

        return { user, twoFactorAuthRequired };
      } catch (error) {
        console.error(error);
        return rejectWithValue({ error });
      }
    }
  ),
};
