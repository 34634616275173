import React, { Fragment } from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch } from 'src/components-bl/types';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import { Notification } from 'src/services';
import { NotificationCard } from '../NotificationCard';
import {
  EmptyNotificationsMessageStyled,
  NotificationsCardDividerStyled,
  NotificationsTabStyled,
} from './NotificationsTab.styles';

export interface NotificationsTabProps extends RoutedComponentProps {
  shopId?: number;
  notifications?: Notification[];
  emptyNotificationsMessage: string;
  dispatch: Dispatch;
  onClosePopup: () => void;
  className?: string;
}

export function NotificationsTab({
  shopId,
  notifications,
  emptyNotificationsMessage,
  dispatch,
  permittedRouteMap,
  onClosePopup,
  className,
}: NotificationsTabProps): JSX.Element {
  return (
    <NotificationsTabStyled className={className}>
      {notifications && notifications.length > 0 ? (
        notifications?.map((notification, index) => (
          <Fragment key={notification.id}>
            <NotificationCard
              shopId={shopId}
              notification={notification}
              dispatch={dispatch}
              permittedRouteMap={permittedRouteMap}
              onClosePopup={onClosePopup}
            />
            {index !== notifications.length - 1 && <NotificationsCardDividerStyled />}
          </Fragment>
        ))
      ) : (
        <EmptyNotificationsMessageStyled
          type={TypographyType.Paragraph}
          variant={TypographyVariant.MediumRegular}
        >
          {emptyNotificationsMessage}
        </EmptyNotificationsMessageStyled>
      )}
    </NotificationsTabStyled>
  );
}
