import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { visualEditorActions } from '../state';
import { Dispatch } from '../../../components-dummy/types';

export const useLocationListener = (appDispatch: Dispatch) => {
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      appDispatch(visualEditorActions.locationChange({ search: location.search }));
    }
  }, [location.search, appDispatch]);
};
