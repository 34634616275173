import { versioningService } from 'src/services';
import { Dispatch } from '../../../types';
import {
  PublishStatusIndicationActionTypes,
  PublishStatusIndicationGetDraftRequestActionPayload,
  PublishStatusIndicationGetDraftRequestAction,
  PublishStatusIndicationGetDraftSuccessActionPayload,
  PublishStatusIndicationGetDraftSuccessAction,
  PublishStatusIndicationGetDraftErrorActionPayload,
  PublishStatusIndicationGetDraftErrorAction,
  PublishStatusIndicationDiscardDraftRequestActionPayload,
  PublishStatusIndicationDiscardDraftRequestAction,
  PublishStatusIndicationDiscardDraftSuccessAction,
  PublishStatusIndicationDiscardDraftErrorActionPayload,
  PublishStatusIndicationDiscardDraftErrorAction,
  PublishStatusIndicationPublishDraftRequestActionPayload,
  PublishStatusIndicationPublishDraftRequestAction,
  PublishStatusIndicationPublishDraftSuccessActionPayload,
  PublishStatusIndicationPublishDraftSuccessAction,
  PublishStatusIndicationPublishDraftErrorActionPayload,
  PublishStatusIndicationPublishDraftErrorAction,
  DiscardDraftArguments,
  PublishDraftArguments,
  PublishStatusIndicationDiscardDraftSuccessActionPayload,
} from './types';

const publishStatusIndicationActions = {
  getDraftRequest(
    payload: PublishStatusIndicationGetDraftRequestActionPayload
  ): PublishStatusIndicationGetDraftRequestAction {
    return {
      type: PublishStatusIndicationActionTypes.GetDraftRequest,
      payload,
    };
  },

  getDraftSuccess(
    payload: PublishStatusIndicationGetDraftSuccessActionPayload
  ): PublishStatusIndicationGetDraftSuccessAction {
    return {
      type: PublishStatusIndicationActionTypes.GetDraftSuccess,
      payload,
    };
  },

  getDraftError(
    payload: PublishStatusIndicationGetDraftErrorActionPayload
  ): PublishStatusIndicationGetDraftErrorAction {
    return {
      type: PublishStatusIndicationActionTypes.GetDraftError,
      payload,
    };
  },
  discardDraftRequest(
    payload: PublishStatusIndicationDiscardDraftRequestActionPayload
  ): PublishStatusIndicationDiscardDraftRequestAction {
    return {
      type: PublishStatusIndicationActionTypes.DiscardDraftRequest,
      payload,
    };
  },

  discardDraftSuccess(
    payload: PublishStatusIndicationDiscardDraftSuccessActionPayload
  ): PublishStatusIndicationDiscardDraftSuccessAction {
    return {
      type: PublishStatusIndicationActionTypes.DiscardDraftSuccess,
      payload,
    };
  },

  discardDraftError(
    payload: PublishStatusIndicationDiscardDraftErrorActionPayload
  ): PublishStatusIndicationDiscardDraftErrorAction {
    return {
      type: PublishStatusIndicationActionTypes.DiscardDraftError,
      payload,
    };
  },
  publishDraftRequest(
    payload: PublishStatusIndicationPublishDraftRequestActionPayload
  ): PublishStatusIndicationPublishDraftRequestAction {
    return {
      type: PublishStatusIndicationActionTypes.PublishDraftRequest,
      payload,
    };
  },

  publishDraftSuccess(
    payload: PublishStatusIndicationPublishDraftSuccessActionPayload
  ): PublishStatusIndicationPublishDraftSuccessAction {
    return {
      type: PublishStatusIndicationActionTypes.PublishDraftSuccess,
      payload,
    };
  },

  publishDraftError(
    payload: PublishStatusIndicationPublishDraftErrorActionPayload
  ): PublishStatusIndicationPublishDraftErrorAction {
    return {
      type: PublishStatusIndicationActionTypes.PublishDraftError,
      payload,
    };
  },
};

export const publishStatusIndicationActionMethods = {
  getDraft({ shopId, slim }: PublishStatusIndicationGetDraftRequestActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(publishStatusIndicationActions.getDraftRequest({ shopId, slim }));
        const draft = await versioningService.getShopDraft({ shopId, slim });
        dispatch(publishStatusIndicationActions.getDraftSuccess({ draft }));
      } catch (error) {
        dispatch(publishStatusIndicationActions.getDraftError({ error }));
      }
    };
  },

  resetDraft(): PublishStatusIndicationGetDraftSuccessAction {
    return publishStatusIndicationActions.getDraftSuccess({ draft: undefined });
  },

  discardDraft({
    isAllSelected,
    resetPage,
    selectedDomainGroups,
    shopId,
    totalChanges,
  }: DiscardDraftArguments) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(publishStatusIndicationActions.discardDraftRequest({ shopId }));
        await versioningService.discardShopDraft(shopId, selectedDomainGroups);

        const customMessage = isAllSelected
          ? 'Successfully discarded the draft'
          : `Successfully discarded ${totalChanges} changes`;
        dispatch(publishStatusIndicationActions.discardDraftSuccess({ customMessage }));
        resetPage();
      } catch (error) {
        const customMessage = isAllSelected
          ? 'Failed to discard the draft'
          : `Failed to discard ${totalChanges} changes`;
        dispatch(publishStatusIndicationActions.discardDraftError({ error, customMessage }));
      }
    };
  },

  publishDraft({
    isAllSelected,
    shopId,
    selectedDomainGroups,
    totalChanges,
    closeModal,
  }: PublishDraftArguments) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(
          publishStatusIndicationActions.publishDraftRequest({ shopId, selectedDomainGroups })
        );
        const publishedVersion = await versioningService.createVersion(
          shopId,
          selectedDomainGroups
        );

        const customMessage = isAllSelected
          ? 'Successfully published changes'
          : `Successfully published ${totalChanges} changes`;
        dispatch(
          publishStatusIndicationActions.publishDraftSuccess({
            publishedVersion,
            customMessage,
          })
        );
        closeModal();
      } catch (error) {
        dispatch(publishStatusIndicationActions.publishDraftError({ error }));
      }
    };
  },
};
