import { useParams } from 'react-router';
import { useAppSelector } from '../../../../hooks';
import { useMerchRulesIndicators } from '../../hooks';
import { PreviewMerchRule } from '../../types';
import { VisualEditorParams } from '../../types/params';

export const useGetSelectedMerchRule = ({
  selectedRule,
}: {
  selectedRule: PreviewMerchRule | null;
}): { selectedMerchRule: PreviewMerchRule } => {
  const { selectedExperience } = useAppSelector(({ visualEditor }) => visualEditor);
  const { merchandisingRules } = useMerchRulesIndicators(selectedExperience);

  const { ruleId } = useParams<Pick<VisualEditorParams, 'ruleId'>>();
  const selectedRuleByParams = [...(merchandisingRules || [])].find(rule => rule.id === ruleId);

  const selectedMerchRule = selectedRule || selectedRuleByParams;

  return { selectedMerchRule };
};
