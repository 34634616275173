import React from 'react';
import { Page } from 'src/components-dummy';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { VersionsTable } from 'src/components-bl/Versioning';

export const VersionsListPage = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const versions = useAppSelector(state => state.versioning.versions);
  const shopId = useAppSelector(state => state.shop.current?.shopId);

  return (
    <>
      <Page.Header>
        <Page.Title>Manage Versions</Page.Title>
        <Page.SubTitle>
          We display shop versions up to a month old, or up to five versions if no changes were made
          in the last month
        </Page.SubTitle>
      </Page.Header>
      <Page.Content>
        {shopId ? <VersionsTable versions={versions} dispatch={dispatch} shopId={shopId} /> : null}
      </Page.Content>
    </>
  );
};
