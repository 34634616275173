import styled from '@emotion/styled';
import { Pagination, PaginationItem } from '@mui/material';
import { Icon } from '../Icon';
import { NumericInput } from '../NumericInput';

export const PaginationStyled = styled(Pagination)`
  .Mui-selected {
    background-color: ${({ theme }) => theme.palette.common.black} !important;
    color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const ButtonStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;

  span:first-of-type {
    margin-right: 10px;
  }
`;

export const PreviousButtonStyled = styled(ButtonStyled)`
  .syte-basic-icon {
    transform: rotate(180deg);
    margin-right: 10px;
  }
`;

export const PaginationItemStyled = styled(PaginationItem)`
  opacity: 1 !important;
  transition: 0.4s opacity;
  &.MuiPaginationItem-previousNext.Mui-disabled {
    opacity: 0 !important;
  }
`;

/**
 * PaginationItemsPerPage
 */
export const PaginationItemsPerPageStyled = styled.div`
  & > span:first-of-type {
    margin-right: 4px;
  }

  display: flex;
  align-items: center;
`;

export const PaginationWithItemsPerPageStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

/**
 * PaginationGoToPageByText
 */
export const PaginationGoToPageByTextStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  .input-base {
    width: 64px;
    .input-base-wrapper {
      height: initial;
      padding: 7px 12px;
    }
  }

  .MuiButtonBase-root {
    padding: 8px 4px;
    min-width: 32px;
  }

  button:disabled {
    svg {
      & > path {
        stroke: ${({ theme }) => theme.palette.custom['gray-disabled']};
      }
    }
  }
`;

export const ArrowIconStyled = styled(Icon)`
  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-20']};
  }
  margin-left: 6px;

  &:hover path {
    stroke: ${({ theme }) => theme.palette.common.black};
  }
`;

export const NumericInputStyled = styled(NumericInput)`
  background: ${({ theme }) => theme.palette.custom['gray-80']};
`;
