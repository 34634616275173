import { ShopCardActionTypes } from 'src/components-bl';
import { Dispatch } from 'src/components-bl/types';
import { Shops } from 'src/services';
import {
  ShopCardDeleteShopRequestActionPayload,
  ShopCardDeleteShopRequestAction,
  ShopCardDeleteShopSuccessActionPayload,
  ShopCardDeleteShopSuccessAction,
  ShopCardDeleteShopErrorActionPayload,
  ShopCardDeleteShopErrorAction,
  // ShopCardArchiveShopRequestActionPayload,
  // ShopCardArchiveShopRequestAction,
  // ShopCardArchiveShopSuccessActionPayload,
  // ShopCardArchiveShopSuccessAction,
  // ShopCardArchiveShopErrorActionPayload,
  // ShopCardArchiveShopErrorAction,
  // ShopCardRestoreShopRequestActionPayload,
  // ShopCardRestoreShopRequestAction,
  // ShopCardRestoreShopSuccessActionPayload,
  // ShopCardRestoreShopSuccessAction,
  // ShopCardRestoreShopErrorActionPayload,
  // ShopCardRestoreShopErrorAction,
} from './types';

const shopCardActions = {
  deleteShopRequest(
    payload: ShopCardDeleteShopRequestActionPayload
  ): ShopCardDeleteShopRequestAction {
    return {
      type: ShopCardActionTypes.DeleteShopRequest,
      payload,
    };
  },

  deleteShopSuccess(
    payload: ShopCardDeleteShopSuccessActionPayload
  ): ShopCardDeleteShopSuccessAction {
    return {
      type: ShopCardActionTypes.DeleteShopSuccess,
      payload,
    };
  },

  deleteShopError(payload: ShopCardDeleteShopErrorActionPayload): ShopCardDeleteShopErrorAction {
    return {
      type: ShopCardActionTypes.DeleteShopError,
      payload,
    };
  },

  // archiveShopRequest(
  //   payload: ShopCardArchiveShopRequestActionPayload
  // ): ShopCardArchiveShopRequestAction {
  //   return {
  //     type: ShopCardActionTypes.ArchiveShopRequest,
  //     payload,
  //   };
  // },

  // archiveShopSuccess(
  //   payload: ShopCardArchiveShopSuccessActionPayload
  // ): ShopCardArchiveShopSuccessAction {
  //   return {
  //     type: ShopCardActionTypes.ArchiveShopSuccess,
  //     payload,
  //   };
  // },

  // archiveShopError(payload: ShopCardArchiveShopErrorActionPayload): ShopCardArchiveShopErrorAction {
  //   return {
  //     type: ShopCardActionTypes.ArchiveShopError,
  //     payload,
  //   };
  // },

  // restoreShopRequest(
  //   payload: ShopCardRestoreShopRequestActionPayload
  // ): ShopCardRestoreShopRequestAction {
  //   return {
  //     type: ShopCardActionTypes.RestoreShopRequest,
  //     payload,
  //   };
  // },

  // restoreShopSuccess(
  //   payload: ShopCardRestoreShopSuccessActionPayload
  // ): ShopCardRestoreShopSuccessAction {
  //   return {
  //     type: ShopCardActionTypes.RestoreShopSuccess,
  //     payload,
  //   };
  // },

  // restoreShopError(payload: ShopCardRestoreShopErrorActionPayload): ShopCardRestoreShopErrorAction {
  //   return {
  //     type: ShopCardActionTypes.RestoreShopError,
  //     payload,
  //   };
  // },
};

export const shopCardActionMethods = {
  deleteShop(payload: ShopCardDeleteShopRequestActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        const service = new Shops();
        dispatch(shopCardActions.deleteShopRequest(payload));
        await service.deleteShop(payload.shopId);
        const shops = await service.getShops(payload.accountId);
        dispatch(shopCardActions.deleteShopSuccess({ deletedShopId: payload.shopId, shops }));
      } catch (error) {
        dispatch(shopCardActions.deleteShopError({ error }));
      }
    };
  },
  // archiveShop(payload: ShopCardArchiveShopRequestActionPayload) {
  //   return async (dispatch: Dispatch): Promise<void> => {
  //     try {
  //       const service = new Shops();
  //       dispatch(shopCardActions.archiveShopRequest(payload));
  //       const updatedShop = await service.updateGeneralSettings({
  //         shopId: payload.shopId,
  //         generalSettings: { enabled: false },
  //       });
  //       const shops = await service.getShops(payload.accountId);
  //       dispatch(shopCardActions.archiveShopSuccess({ updatedShop, shops }));
  //     } catch (error) {
  //       dispatch(shopCardActions.archiveShopError({ error }));
  //     }
  //   };
  // },
  // restoreShop(payload: ShopCardRestoreShopRequestActionPayload) {
  //   return async (dispatch: Dispatch): Promise<void> => {
  //     try {
  //       const service = new Shops();
  //       dispatch(shopCardActions.restoreShopRequest(payload));
  //       const updatedShop = await service.updateGeneralSettings({
  //         shopId: payload.shopId,
  //         input: { enabled: true },
  //       });
  //       const shops = await service.getShops(payload.accountId);
  //       dispatch(shopCardActions.restoreShopSuccess({ updatedShop, shops }));
  //     } catch (error) {
  //       dispatch(shopCardActions.restoreShopError({ error }));
  //     }
  //   };
  // },
};
