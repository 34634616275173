import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Page, BackButtonTitle } from 'src/components-dummy';
import { useAppSelector } from 'src/hooks';
import { DiscoveryBannerSettings } from 'src/components-bl';
import { ShopRoutes } from '../../types/enums/shop-routes';
import './DiscoveryBannerContainer.scss';

export const DiscoveryBannerContainer = (): JSX.Element | null => {
  const formHeaderRef = useRef<HTMLDivElement>(null);

  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const discoveryBannerSettings = useAppSelector(state => state.discoveryBannerSettings);
  const dispatch = useDispatch();
  const navigationPath = `/shops/${shopId}/${ShopRoutes.ShopperExperiences}`;
  return (
    <Page className='discover-banner-container'>
      <Page.Header>
        <BackButtonTitle path={navigationPath}>Discovery Banner</BackButtonTitle>
        <div className='discover-banner-buttons-section' ref={formHeaderRef} />
      </Page.Header>
      <Page.Content>
        {shopId && (
          <DiscoveryBannerSettings
            discoveryBannerSettings={discoveryBannerSettings}
            shopId={shopId}
            formHeaderElementRef={formHeaderRef}
            dispatch={dispatch}
          />
        )}
      </Page.Content>
    </Page>
  );
};
