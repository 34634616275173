import { Experiment, EditableExperiment } from 'src/services';

export enum ExperimentFormActionTypes {
  UpdateExperimentRequest = 'ExperimentForm/UpdateExperimentRequest',
  UpdateExperimentSuccess = 'ExperimentForm/UpdateExperimentSuccess',
  UpdateExperimentError = 'ExperimentForm/UpdateExperimentError',
  NotifyIsDirty = 'ExperimentForm/NotifyIsDirty',
}

export interface ExperimentFormUpdateExperimentRequestActionPayload {
  shopId: number;
  experiment: EditableExperiment;
}

export interface ExperimentFormUpdateExperimentSuccessActionPayload {
  experiment: Experiment;
}

export interface ExperimentFormUpdateExperimentErrorActionPayload {
  error: unknown;
}

export interface ExperimentFormUpdateExperimentRequestAction {
  type: typeof ExperimentFormActionTypes.UpdateExperimentRequest;
  payload: ExperimentFormUpdateExperimentRequestActionPayload;
}

export interface ExperimentFormUpdateExperimentSuccessAction {
  type: typeof ExperimentFormActionTypes.UpdateExperimentSuccess;
  payload: ExperimentFormUpdateExperimentSuccessActionPayload;
}

export interface ExperimentFormUpdateExperimentErrorAction {
  type: typeof ExperimentFormActionTypes.UpdateExperimentError;
  payload: ExperimentFormUpdateExperimentErrorActionPayload;
}

export interface ExperimentFormNotifyIsDirtyActionPayload {
  isDirty: boolean;
}

export interface ExperimentFormNotifyIsDirtyAction {
  type: typeof ExperimentFormActionTypes.NotifyIsDirty;
  payload: ExperimentFormNotifyIsDirtyActionPayload;
}

export type ExperimentFormAction =
  | ExperimentFormUpdateExperimentRequestAction
  | ExperimentFormUpdateExperimentSuccessAction
  | ExperimentFormUpdateExperimentErrorAction
  | ExperimentFormNotifyIsDirtyAction;
