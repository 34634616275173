import styled from '@emotion/styled';
import { OverlayModal, SearchInput } from 'src/components-dummy';
import { TableBodyRowCellStyled } from '../../components-dummy/TableV2/TableV2.style';

export const OverlayModalStyled = styled(OverlayModal)`
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AdminSearchModalStyled = styled.dialog`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 90%;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 24px;
  border: none;
`;

export const SearchInputStyled = styled(SearchInput)`
  margin: 24px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 255px;
  align-self: center;
  text-align: center;
  margin: auto;
`;

export const ContentImageStyled = styled.img`
  width: 100%;
  max-width: 305px;
  align-self: center;
`;

export const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 36px;
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
`;

export const AccordionTriggerGroup = styled.div`
  color: #1e1e1e;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 18px;

  svg {
    &.open {
      transform: rotate(-90deg);
      height: 16px;
      width: 16px;
    }
    &.closed {
      transform: rotate(90deg);
      height: 16px;
      width: 16px;
    }
  }

  &.shops {
    color: #cf24dd;
  }
  &.accounts {
    color: #28a745;
  }
  &.users {
    color: #f3b61f;
  }
  &.catalogs {
    color: #1fc2f3;
  }
`;

export const TableBodyRowCell = styled(TableBodyRowCellStyled)`
  cursor: pointer;
`;

export const RecentSearchesContainer = styled.section`
  display: flex;
  flex-direction: column;
  color: #1e1e1e;
  margin: 24px;
`;

export const RecentSearchesHeader = styled.div`
  display: flex;
  gap: 6px;
`;

export const RecentSearchesResults = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const RecentSearchesResult = styled.li`
  display: flex;
  gap: 6px;
  margin: 10px 10px 10px 20px;
  cursor: pointer;
  color: #6e6e71;
  svg {
    width: 16px;
    height: 16px;
  }
  &:focus {
    color: #0073ff;
    svg {
      path {
        fill: #0073ff;
      }
    }
  }
`;
