import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Page, Button, AvailableIcons } from 'src/components-dummy';
import { UserList, UserForm, userListActionMethods } from 'src/components-bl';
import { useAppSelector } from '../../hooks';
import './Team.scss';

export const Team = (): JSX.Element => {
  const dispatch = useDispatch();
  const formButtonRef = useRef<HTMLDivElement>(null);
  const [showCreateUserForm, setShowCreateUserForm] = useState(false);

  const { accountId, selectedUser, users, editingUser } = useAppSelector(state => {
    return {
      accountId: state.account.current?.accountId,
      selectedUser: state.userList.current,
      users: state.userList.users,
      editingUser: state.global.loggedInUser,
    };
  });

  const handleAddUser = () => {
    dispatch(userListActionMethods.userSelected(undefined));
    setShowCreateUserForm(true);
  };

  const onSubmit = () => {
    setShowCreateUserForm(false);
  };

  return (
    <Page className='syte-team-container'>
      <Page.Header className='syte-team-container-header'>
        <Page.Title>Team</Page.Title>
        <div className='syte-team-container-header'>
          <Button
            startIcon={AvailableIcons.Add}
            variant='secondary'
            className='create-user-button'
            onClick={handleAddUser}
          >
            Add User
          </Button>
          <div className='ml-auto syte-team-container-save-button' ref={formButtonRef} />
        </div>
      </Page.Header>
      <Page.Content className='syte-team-container-content'>
        {accountId && (
          <>
            <UserList
              users={users}
              accountId={accountId}
              selectedUserId={selectedUser?.userId}
              onAddUser={handleAddUser}
              dispatch={dispatch}
              loggedInUser={editingUser}
            />
            {editingUser && (!!selectedUser || showCreateUserForm) && (
              <UserForm
                user={selectedUser}
                accountId={accountId}
                dispatch={dispatch}
                editingUser={editingUser}
                formSaveButtonRef={formButtonRef}
                onSubmit={onSubmit}
              />
            )}
          </>
        )}
      </Page.Content>
    </Page>
  );
};
