import React, { Ref } from 'react';
import MuiMenuItem, { MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem';
import { EllipsisWithTooltip } from '../EllipsisWithToolTip';
import { Typography } from '../Typography';

const isChildInstanceOfTypography = (child: React.ReactChild) =>
  React.isValidElement(child) && child.type === Typography;

interface MenuItemProps extends MuiMenuItemProps {
  children: React.ReactChild | React.ReactChild[];
  value?: string | number | readonly string[] | undefined;
  className?: string;
  selected?: boolean;
  text?: string;
  prefixId?: string;
}

/**
 * Main
 */
export const MenuItem = React.forwardRef(
  ({ children, prefixId, ...rest }: MenuItemProps, ref: Ref<HTMLLIElement>) => {
    let content = children;

    if (typeof children === 'string') {
      content = (
        <EllipsisWithTooltip tooltipPosition='right center' tooltipText={children}>
          {children as string}
        </EllipsisWithTooltip>
      );
    }

    if (!Array.isArray(children) && isChildInstanceOfTypography(children)) {
      content = (
        <EllipsisWithTooltip
          tooltipPosition='right center'
          tooltipText={(children as any)?.props?.children}
        >
          {children}
        </EllipsisWithTooltip>
      );
    }

    return (
      <MuiMenuItem data-id={prefixId} {...rest} ref={ref}>
        {content}
      </MuiMenuItem>
    );
  }
);
