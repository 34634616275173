import styled from '@emotion/styled';
import { ConfirmationDialog, Select } from 'src/components-dummy';
import { FileUploader } from 'src/components-dummy/FileUploader';

export const FileUploaderCSVStyled = styled(FileUploader.CSV)`
  margin-top: 48px;
`;

export const ExportFileModalStyled = styled(ConfirmationDialog)`
  width: 600px;
`;

export const SelectStyled = styled(Select)`
  margin-top: 32px;
  max-width: 326px;
`;

export const DeepTagReportExportReportCsvModalStyled = styled.div`
  width: 493px;
`;

export const SubTitleStyled = styled.div`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const CheckboxWithTextStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const RegenerateCheckboxesStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 24px;
  cursor: pointer;
`;
