import React from 'react';
import {
  AvailableIcons,
  Icon,
  TextBox,
  TextBoxProps,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { FormGroup } from '../Form';

interface Props {
  value: string;
  onChange: TextBoxProps['onChange'];
  onDelete: VoidFunction;
  error?: boolean | string;
}

export function CustomImageForm({ value, onChange, onDelete, error }: Props): JSX.Element {
  return (
    <FormGroup style={{ marginTop: '8px' }}>
      <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
        Enter optional product image URL
      </Typography>
      <TextBox
        value={value}
        onChange={onChange}
        placeholder='Enter image URL'
        className='custom-image-textbox'
        error={error}
      >
        <Icon className='remove-image-url' name={AvailableIcons.TrashCan} onClick={onDelete} />
      </TextBox>
    </FormGroup>
  );
}
