import { AccountTypes } from 'src/services';

export enum CreateAccountFormActionTypes {
  CreateAccountRequest = 'CreateAccountForm/CreateAccountRequest',
  CreateAccountSuccess = 'CreateAccountForm/CreateAccountSuccess',
  CreateAccountError = 'CreateAccountForm/CreateAccountError',
}

export interface CreateAccountFormCreateAccountRequestActionPayload {
  name: string;
}

export interface CreateAccountFormCreateAccountSuccessActionPayload {
  account: AccountTypes.Account;
}

export interface CreateAccountFormCreateAccountErrorActionPayload {
  error: unknown;
}

export interface CreateAccountFormCreateAccountRequestAction {
  type: typeof CreateAccountFormActionTypes.CreateAccountRequest;
  payload: CreateAccountFormCreateAccountRequestActionPayload;
}

export interface CreateAccountFormCreateAccountSuccessAction {
  type: typeof CreateAccountFormActionTypes.CreateAccountSuccess;
  payload: CreateAccountFormCreateAccountSuccessActionPayload;
}

export interface CreateAccountFormCreateAccountErrorAction {
  type: typeof CreateAccountFormActionTypes.CreateAccountError;
  payload: CreateAccountFormCreateAccountErrorActionPayload;
}

export type CreateAccountFormAction =
  | CreateAccountFormCreateAccountRequestAction
  | CreateAccountFormCreateAccountSuccessAction
  | CreateAccountFormCreateAccountErrorAction;
