export interface LastPathSegmentMappings {
  [key: string]: string;
}

export const routeNameMappings: LastPathSegmentMappings = {
  'collection-rules': 'Edit Collection',
  'merchandising-rules': 'Merchandising Rules',
  'ranking-strategy': 'Ranking Strategy',
  'collection-settings': 'Summary',
};

export const getLastPathSegment = (pathname: string, mappings: LastPathSegmentMappings) => {
  const segments = pathname.split('/').filter(Boolean);
  if (segments[segments.length - 1] === 'collections') {
    return undefined;
  }
  return mappings[segments[segments.length - 1]] || segments[segments.length - 1];
};
