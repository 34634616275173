import React from 'react';
import { MetaTypes } from 'src/services';
import { BackgroundImageStyled } from '../Login.styles';

export interface DynamicContentProps {
  metadata: MetaTypes.IMetaPublic | undefined;
}
const fallbackMetadata: MetaTypes.IMetaPublic = {
  mainImageUrl: 'images/main-page.jpg',
  secondaryImageUrl: 'images/main-page2.jpg',
  targetUrl: 'https://www.syte.ai/ebooks/product-discovery/state-of-ecommerce-discovery-2023/',
};

export const DynamicContent = ({
  metadata = fallbackMetadata,
}: DynamicContentProps): JSX.Element => {
  return (
    <a href={metadata.targetUrl} target='_blank' rel='noopener noreferrer'>
      <BackgroundImageStyled
        mainImageUrl={metadata.mainImageUrl}
        secondaryImageUrl={metadata.secondaryImageUrl}
      />
    </a>
  );
};
