import React from 'react';
import { UiTemplatesList, UiTemplatesProps } from '../../../components-bl';
import { useAppSelector } from '../../../hooks';
import './PageContent.scss';

export const UiTemplatesMain: React.FC<UiTemplatesProps> = ({ shopId, dispatch, openPopup }) => {
  const list = useAppSelector(state => state.uiTemplates.templates);
  return (
    <main className='ui-templates-main'>
      <UiTemplatesList dispatch={dispatch} shopId={shopId} list={list} openPopup={openPopup} />
    </main>
  );
};
