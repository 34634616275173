import React, { ReactNode, useCallback } from 'react';
import { generatePath } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch } from 'src/components-bl/types';
import { Breadcrumbs } from 'src/components-dummy';
import { collectionBreadCrumbsActions } from './CollectionBreadCrumbs.actions';

interface CollectionBreadCrumbsProps extends RoutedComponentProps {
  children?: JSX.Element | JSX.Element[] | ReactNode | ReactNode[];
  dispatch: Dispatch;
  shopId: number;
  pageTitle?: string;
  collectionName?: string;
  collectionId?: string;
}

export const CollectionBreadCrumbs = ({
  children,
  dispatch,
  permittedRouteMap,
  shopId,
  pageTitle = '',
  collectionName = '',
  collectionId = '',
}: CollectionBreadCrumbsProps): JSX.Element => {
  const collectionsMainLink = permittedRouteMap.collections
    ? generatePath(permittedRouteMap.collections.path, { shopId })
    : '';
  const collectionsSummaryLink = permittedRouteMap.editCollectionSettings
    ? generatePath(permittedRouteMap.editCollectionSettings.path, { shopId, collectionId })
    : '';

  const onLinkClick = useCallback(
    (link: string) => {
      dispatch(collectionBreadCrumbsActions.navigateTo({ navigateTo: link }));
    },
    [dispatch]
  );

  return (
    <Breadcrumbs>
      <Breadcrumbs.Link
        displayName='Collections'
        href={collectionsMainLink}
        onNavigate={onLinkClick}
      />
      {collectionName ? (
        <Breadcrumbs.Link
          displayName={collectionName}
          href={collectionsSummaryLink}
          onNavigate={onLinkClick}
        />
      ) : (
        <></>
      )}
      <Breadcrumbs.Current>{pageTitle || children}</Breadcrumbs.Current>
    </Breadcrumbs>
  );
};
