import styled from '@emotion/styled';
import { FeatureToggle } from './FeatureToggle';

export const FeatureToggleStyled: any = styled(FeatureToggle)`
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  padding: 20px;
`;
