import React, { useMemo } from 'react';
import { ILexiconAttribute } from 'src/services/src/service/types/lexicon';
import { MultiSelectOption } from 'src/components-dummy/MultiSelectMenu';
import { AvailableIcons, Icon, Button } from 'src/components-dummy';
import { DeepTagProductMultipleSelect } from './DeepTagProductMultipleSelect';

/**
 * Sub component
 */
const AddTagButton = ({
  disabled = false,
  isLoading,
}: {
  disabled?: boolean;
  isLoading: boolean;
}) => {
  return (
    <Button variant='tertiary' disabled={disabled} size='small' loading={!!isLoading}>
      <Icon name={AvailableIcons.Plus} />
      Add tag
    </Button>
  );
};

/**
 * Main
 */
interface DeepTagProductAddTagsProps {
  onApply: (attributes: string[]) => void;
  lexiconAttributes?: Record<string, ILexiconAttribute>;
  disabled: boolean;
  isLoadingLexicon: boolean;
}

export const DeepTagProductAddTagWithMultiSelect = ({
  onApply,
  lexiconAttributes = {},
  disabled,
  isLoadingLexicon,
}: DeepTagProductAddTagsProps): JSX.Element => {
  const handleOnSelectAttributes = (selectedValues: string[]) => {
    onApply(selectedValues);
  };

  const options = useMemo(() => {
    return Object.keys(lexiconAttributes).reduce(
      (accumulator: MultiSelectOption[], attributeKey: string) => {
        const attributeItem: ILexiconAttribute = lexiconAttributes[attributeKey];
        const attributeItemText = attributeItem.customTranslation || attributeItem.translation;

        accumulator.push({ text: attributeItemText, value: attributeItem.key });

        return accumulator;
      },
      []
    );
  }, [lexiconAttributes]);

  if (disabled) {
    return <AddTagButton isLoading={isLoadingLexicon} disabled={disabled} />;
  }

  return (
    <DeepTagProductMultipleSelect
      filterKey='attributes'
      filterState={{ isApplied: false, value: [] }}
      searchPlaceholder='Search...'
      options={options}
      enableSearch
      enableToggleAll
      labelText='Select attribute'
      TriggerComponent={<AddTagButton isLoading={isLoadingLexicon} />}
      onApply={handleOnSelectAttributes}
    />
  );
};
