import React, { MutableRefObject } from 'react';
import { isString } from 'lodash';
import { AutoCompleteOption, AutoCompleteProps, InputStartAdornment, Option } from './AutoComplete';
import { AutoCompleteSingleValueStyled } from './AutoCompleteSingleValue.styles';

export interface AutoCompleteSingleValueProps
  extends Omit<AutoCompleteProps, 'multiple' | 'onChange' | 'selectedValues' | 'textBoxValue'> {
  onChange?: (value: string) => void;
  onSelectOption?: (selectedOption?: AutoCompleteOption) => void;
  selectedValue?: string;
  inputRef?: MutableRefObject<any> | null;
}

export const AutoCompleteSingleValue = ({
  errored,
  options,
  freeSolo,
  selectedValue,
  onChange: onChangeExternal,
  onSelectOption,
  disabled,
  className,
  placeholder = 'Type here...',
  inputRef,
  onTextBoxChange,
  paperProps,
  onBlur,
  onKeyDown,
  ...rest
}: AutoCompleteSingleValueProps): JSX.Element => {
  const onChange = (newValue: string | undefined) => {
    onChangeExternal?.(isString(newValue) ? newValue : '');
  };

  const onAutoCompleteChange = (newValue: AutoCompleteOption) => {
    onSelectOption?.(newValue);
  };

  const onTextBoxChangeHandler = (e: any) => {
    onTextBoxChange?.(e);
    onChange(e.target.value);
  };

  return (
    <AutoCompleteSingleValueStyled
      {...rest}
      inputRef={inputRef}
      errored={errored}
      options={options}
      onTextBoxChange={onTextBoxChangeHandler}
      freeSolo={freeSolo}
      multiple={false}
      selectedValues={selectedValue as unknown as string[]}
      onChange={onAutoCompleteChange as any}
      onBlur={onBlur}
      textBoxValue={selectedValue}
      disabled={disabled}
      className={className}
      placeholder={placeholder}
      paperProps={paperProps}
      onKeyDown={onKeyDown}
    />
  );
};

AutoCompleteSingleValue.Option = Option;
AutoCompleteSingleValue.InputStartAdornment = InputStartAdornment;
