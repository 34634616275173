import React from 'react';
import styled from '@emotion/styled';
import { AvailableIcons, Icon } from '../../../components-dummy';

const LinkWrapper = styled.div`
  color: #0073ff;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 4px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  svg {
    height: 12px;
    width: 12px;
  }
`;

interface Props {
  showIcon?: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  children: React.ReactNode;
}

export function ExternalLink({ showIcon, onClick, children }: Props): JSX.Element {
  return (
    <LinkWrapper onClick={onClick}>
      <span>{children}</span>
      {showIcon && <Icon name={AvailableIcons.ExternalLink} />}
    </LinkWrapper>
  );
}
