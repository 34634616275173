import React from 'react';
import { AvailableIcons, IconIndication, Switch } from 'src/components-dummy';
import { SwitchSizes } from 'src/components-dummy/Switch/Switch.config';
import { RuleActiveCellStyled, IconsWrapperStyled } from './RuleActiveCell.styles';

export interface RuleActiveCellProps {
  onActiveChange: (checked: boolean) => void;
  isActive: boolean;
  isPublished: boolean;
  isUsingInvalidDataField: boolean;
  isUsingInvalidRegion: boolean;
  isInvalidEmptyRegions: boolean;
  disabled?: boolean;
}

export const RuleActiveCell = ({
  isActive,
  isPublished,
  isUsingInvalidDataField,
  isUsingInvalidRegion,
  isInvalidEmptyRegions,
  disabled,
  onActiveChange,
}: RuleActiveCellProps): JSX.Element => {
  const showIcons =
    isUsingInvalidDataField || !isPublished || isUsingInvalidRegion || isInvalidEmptyRegions;
  const shouldDisableActiveSwitch =
    disabled || isUsingInvalidDataField || isUsingInvalidRegion || isInvalidEmptyRegions;
  return (
    <RuleActiveCellStyled>
      <Switch
        checked={isActive}
        size={SwitchSizes.Small}
        onChange={onActiveChange}
        disabled={shouldDisableActiveSwitch}
      />
      {showIcons && (
        <IconsWrapperStyled>
          {isUsingInvalidDataField && (
            <IconIndication
              tooltipValue='The rule is using an invalid data field'
              iconName={AvailableIcons.CircleWarning}
            />
          )}
          {isUsingInvalidRegion && (
            <IconIndication
              tooltipValue='The rule is using an invalid region'
              iconName={AvailableIcons.CircleWarning}
            />
          )}
          {isInvalidEmptyRegions && (
            <IconIndication
              tooltipValue='The rule should have at least one region'
              iconName={AvailableIcons.CircleWarning}
            />
          )}
          {!isPublished && (
            <IconIndication
              tooltipValue='Rule is not published'
              iconName={AvailableIcons.CloudError2}
            />
          )}
        </IconsWrapperStyled>
      )}
    </RuleActiveCellStyled>
  );
};
