import React, { useCallback, useState } from 'react';
import { NumericInput as NumericInputBase, NumericInputProps } from 'src/components-dummy';

export function NumericInput({ value, onChange, ...rest }: NumericInputProps): JSX.Element {
  const [inputValue, setInputValue] = useState(value);

  const onBlur = useCallback(() => {
    if (inputValue !== value) {
      onChange?.(inputValue);
    }
  }, [inputValue, value, onChange]);

  return <NumericInputBase value={inputValue} onChange={setInputValue} onBlur={onBlur} {...rest} />;
}
