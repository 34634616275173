/* --= Action Types =-- */
export enum UseKnnActionTypes {
  GetUseKnnRequest = 'UseKnn/GetUseKnnRequest',
  GetUseKnnSuccess = 'UseKnn/GetUseKnnSuccessAction',
  GetUseKnnError = 'UseKnn/GetUseKnnError',
  UpdateUseKnnRequest = 'UseKnn/UpdateUseKnnRequest',
  UpdateUseKnnSuccess = 'UseKnn/UpdateUseKnnSuccess',
  UpdateUseKnnError = 'UseKnn/UpdateUseKnnError',
  NotifyUseKnnIsDirty = 'UseKnn/NotifyIsDirty',
}

interface ShopIdHolder {
  shopId: number;
}

interface VariantIdHolder {
  selectedVariantId?: string;
}

interface UseKnnHolder {
  useKnn: boolean;
}

interface ErrorHolder {
  error: unknown;
}

export interface UpdateUseKnnPayload extends ShopIdHolder, VariantIdHolder, UseKnnHolder {}
export interface GetUseKnnArgs extends ShopIdHolder, VariantIdHolder {}
export type GetUseKnnRequestPayload = ShopIdHolder;
export interface GetUseKnnSuccessPayload extends ShopIdHolder, UseKnnHolder {}
export type GetUseKnnErrorPayload = ErrorHolder;
export interface UpdateUseKnnRequestPayload extends ShopIdHolder, UseKnnHolder {}
export interface UpdateUseKnnSuccessPayload extends ShopIdHolder, UseKnnHolder {}
export type UpdateUseKnnErrorPayload = ErrorHolder;

export interface NotifyUseKnnIsDirtyActionPayload {
  isDirty: boolean;
}

/* --= Action Interfaces =-- */
export interface GetUseKnnRequestAction {
  type: typeof UseKnnActionTypes.GetUseKnnRequest;
  payload: GetUseKnnRequestPayload;
}

export interface GetUseKnnSuccessAction {
  type: typeof UseKnnActionTypes.GetUseKnnSuccess;
  payload: GetUseKnnSuccessPayload;
}

export interface GetUseKnnErrorAction {
  type: typeof UseKnnActionTypes.GetUseKnnError;
  payload: GetUseKnnErrorPayload;
}

export interface UpdateUseKnnRequestAction {
  type: typeof UseKnnActionTypes.UpdateUseKnnRequest;
  payload: UpdateUseKnnRequestPayload;
}

export interface UpdateUseKnnSuccessAction {
  type: typeof UseKnnActionTypes.UpdateUseKnnSuccess;
  payload: UpdateUseKnnSuccessPayload;
}

export interface UpdateUseKnnErrorAction {
  type: typeof UseKnnActionTypes.UpdateUseKnnError;
  payload: UpdateUseKnnErrorPayload;
}

export interface NotifyUseKnnIsDirtyAction {
  type: typeof UseKnnActionTypes.NotifyUseKnnIsDirty;
  payload: NotifyUseKnnIsDirtyActionPayload;
}

export type UseKnnAction =
  | GetUseKnnRequestAction
  | GetUseKnnSuccessAction
  | GetUseKnnErrorAction
  | UpdateUseKnnRequestAction
  | UpdateUseKnnSuccessAction
  | UpdateUseKnnErrorAction
  | NotifyUseKnnIsDirtyAction;
