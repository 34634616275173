import { useMemo } from 'react';
import { useAppSelector } from '../../hooks';

export const useHasDraft = (): boolean => {
  const draft = useAppSelector(({ versioning }) => versioning.draft);

  return useMemo(() => {
    if (!draft) {
      return false;
    }
    return !!draft.changesSummary.changedEntities.length;
  }, [draft]);
};
