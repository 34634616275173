import { ApiServiceBase } from '../api-service-base';
import * as mapper from './experiments-mapper';
import { Experiment, ExperimentSlim, ExperimentSettings, ExperimentStatus } from '../types';
import { buildCreateExperimentPayload } from './experiment-builder';
import {
  GetExperimentsArguments,
  GetExperimentSettingsArguments,
  GetExperimentArguments,
  UpdateExperimentArguments,
  UpdateExperimentSettingsArguments,
  DeleteExperimentArguments,
  CreateExperimentArguments,
} from './types';

export class Experiments extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getExperimentsPath(shopId: number): string {
    return `${this.serviceBaseUri}/${shopId}/experiments`;
  }

  private getVariantResetFeaturePath(
    shopId: number,
    variantId: string,
    experimentId: string,
    feature: string
  ) {
    return `${this.serviceBaseUri}/${shopId}/experiments/${experimentId}/variants/${variantId}/${feature}`;
  }

  async getExperiments({
    shopId,
    slim = true,
    status,
  }: GetExperimentsArguments): Promise<Array<Experiment | ExperimentSlim>> {
    const url = `${this.getExperimentsPath(shopId)}`;
    const params = { slim, status };
    const response = await this.httpService.get({ url, requestConfig: { params } });
    return mapper.mapExperimentsResponse(response);
  }

  async getExperiment({
    shopId,
    id,
    slim = true,
  }: GetExperimentArguments): Promise<Experiment | ExperimentSlim> {
    const url = `${this.getExperimentsPath(shopId)}/${id}`;
    const params = { slim };
    const response = await this.httpService.get({ url, requestConfig: { params } });
    return mapper.mapExperimentResponse(response);
  }

  async getExperimentSettings({
    shopId,
  }: GetExperimentSettingsArguments): Promise<ExperimentSettings> {
    const url = `${this.getExperimentsPath(shopId)}/settings`;
    const response = await this.httpService.get({ url });
    return mapper.mapExperimentSettingsResponse(response);
  }

  async updateExperimentSettings({
    shopId,
    ...settings
  }: UpdateExperimentSettingsArguments): Promise<ExperimentSettings> {
    const url = `${this.getExperimentsPath(shopId)}/settings`;
    const response = await this.httpService.patch({ url, data: settings });
    return mapper.mapExperimentSettingsResponse(response);
  }

  async deleteExperiment({ shopId, id }: DeleteExperimentArguments): Promise<void> {
    const url = `${this.getExperimentsPath(shopId)}/${id}`;
    await this.httpService.delete({ url });
  }

  async createExperiment({ shopId, name }: CreateExperimentArguments): Promise<Experiment> {
    const url = `${this.getExperimentsPath(shopId)}`;
    const payload = buildCreateExperimentPayload(name);
    const response = await this.httpService.post({ url, data: payload });
    return mapper.mapCreateExperimentResponse(response);
  }

  async updateExperiment({
    shopId,
    experiment,
  }: UpdateExperimentArguments): Promise<Experiment | ExperimentSlim> {
    const url = `${this.getExperimentsPath(shopId)}/${experiment.id}`;
    const response = await this.httpService.patch({ url, data: experiment });
    return mapper.mapExperimentResponse(response);
  }

  async getRunningExperiment({
    shopId,
    slim = true,
  }: GetExperimentsArguments): Promise<Experiment | ExperimentSlim | undefined> {
    const runningExperiments = await this.getExperiments({
      shopId,
      slim,
      status: ExperimentStatus.Running,
    });

    const result = runningExperiments?.[0];

    return result;
  }
}

export const experimentService = new Experiments();
