import Joi from 'joi';
import { CreateAccountFormState } from './types';

export const initialFormState: CreateAccountFormState = {
  name: '',
};

export const formValidationSchema = {
  name: Joi.string().min(3).required(),
};
