import {
  EditableFilterItem,
  FilterItemDBStructure,
  FilterStatusPropertyName,
  FilterType,
} from '../types';
import { isNewlyCreated, determineFilterStatus } from './add-item-utils';

export function discardItemChanges(
  listDraft: EditableFilterItem[],
  { itemKey, originalItem }: { itemKey: string; originalItem: FilterItemDBStructure | undefined }
): EditableFilterItem[] {
  if (isNewlyCreated(itemKey)) {
    return listDraft.filter(item => item.key !== itemKey);
  }
  const updatedFiltersList = [...listDraft];
  const itemToRevert = listDraft.find(item => item.key === itemKey);
  if (itemToRevert && originalItem) {
    const editableOriginalItem = {
      ...originalItem,
      enabled: itemToRevert.enabled,
      [FilterStatusPropertyName]: determineFilterStatus(itemToRevert),
      isTypeNumeric: originalItem.type !== FilterType.text,
    };
    updatedFiltersList.splice(listDraft.indexOf(itemToRevert), 1, editableOriginalItem);
  }
  return updatedFiltersList;
}
