import React, { ReactNode } from 'react';

export interface PageSideBarProps {
  children: ReactNode | ReactNode[];
  className?: string;
}

export const PageSideBar = ({ children, className }: PageSideBarProps): JSX.Element => {
  return <div className={className}>{children}</div>;
};
