import React from 'react';
import { format } from 'date-fns';
import { startCase } from 'lodash';
import { DiffChangeValue, DiffChange, ShopDataField } from 'src/services';
import { DiffCardChangeValue, DiffCardDisplayName } from '../../DiffsCard';
import { DiffSchemaField, FieldValueWithEntity } from '../types';

const dateFormat = 'dd-MM-yyyy HH:mm';
const DEFAULT_EMPTY_VALUE = 'N/A';

function getDefaultStringValue(value: DiffChangeValue): DiffCardChangeValue {
  const isEmpty =
    value === undefined || value === null || value === '' || (value as Array<unknown>).length === 0;
  let result: DiffCardChangeValue;

  if (isEmpty) {
    result = DEFAULT_EMPTY_VALUE;
  } else if (Array.isArray(value)) {
    result = value.toString();
  } else if (typeof (value as Date).getMonth === 'function') {
    result = format(value as Date, dateFormat);
  } else {
    result = String(value);
  }

  return result;
}

export const diffValueDisplayHelpers = {
  getFieldDisplayValue(
    fieldSchema: DiffSchemaField,
    changedEntity: FieldValueWithEntity,
    defaultBold = false,
    dataFields?: ShopDataField[]
  ): DiffCardChangeValue {
    const entityValue = changedEntity.entity
      ? fieldSchema.getValue?.({ ...changedEntity, dataFields })
      : undefined;
    let value = entityValue ?? getDefaultStringValue(changedEntity?.value);

    if (defaultBold && typeof value === 'string') {
      value = <strong>{value}</strong>;
    }

    return value;
  },
  getFieldDisplayName(
    fieldSchema: DiffSchemaField,
    diff: DiffChange,
    oldEntity: unknown,
    newEntity: unknown,
    dataFields?: ShopDataField[]
  ): DiffCardDisplayName {
    const displayName =
      fieldSchema.getDisplayName?.(diff, oldEntity, newEntity, dataFields) ||
      startCase(fieldSchema.displayName);

    return displayName;
  },
  convertArrayToDisplayString(array: Array<string | number | boolean>): string {
    const joinedItemsString = array?.join(', ');
    const text = joinedItemsString ? `[${joinedItemsString}]` : '';
    return text;
  },
  DEFAULT_EMPTY_VALUE,
};
