import styled from '@emotion/styled';
import { TableV2 } from 'src/components-dummy';

export const TableWrapperStyled = styled.div`
  max-width: 560px;
  margin-top: 24px;
  margin-bottom: 3px;
`;

export const TableHeaderStyled = styled(TableV2.Head)`
  background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  & > div {
    padding: 10px 12px;
  }
`;
