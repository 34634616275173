import React from 'react';
import { Slider } from 'src/components-dummy/Slider';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import { SliderRowStyled, TypographyStyled } from './SliderRow.styles';

interface SliderRowProps {
  min: number;
  max: number;
  value: number;
  onChange: (newValue: number) => void;
}

export const SliderRow = ({ min, max, value, onChange }: SliderRowProps): JSX.Element => {
  return (
    <SliderRowStyled>
      <TypographyStyled type={TypographyType.Paragraph} variant={TypographyVariant.SmallRegular}>
        {min}
      </TypographyStyled>
      <Slider
        defaultValue={value}
        onChange={(_, newValue) => onChange(newValue as number)}
        min={min}
        max={max}
        step={1}
        valueLabelDisplay='on'
      />
      <TypographyStyled type={TypographyType.Paragraph} variant={TypographyVariant.SmallRegular}>
        {max}
      </TypographyStyled>
    </SliderRowStyled>
  );
};
