import React from 'react';
import { TabProps as TabMUIProps } from '@mui/material/Tab';
import { TabV2Styled } from './TabsV2.style';

/**
 * Custom Props (extends TabMUIProps)
 */
type TabProps = TabMUIProps;

export const TabV2 = ({ label, children, ...rest }: TabProps): JSX.Element => {
  return (
    <TabV2Styled label={label} {...rest}>
      {children}
    </TabV2Styled>
  );
};
