import { ReactNode, RefObject } from 'react';
import { UpdateReqStatus } from 'src/app-state-types';
import {
  CatalogFiltersSettings,
  DeepTagsSettings,
  EditableFilterItem,
  FilterItemDBStructure,
  FilterItemProperty,
  FilterItemPropertyValue,
} from 'src/services/src/service/types/filters';
import { ShopDataField } from 'src/services/src/service/types';
import { AvailableIcons } from '../../components-dummy';
import { FormApiRef } from '../AugmentedSearch';

export type AdditionalSettingsFields = CatalogFiltersSettings | DeepTagsSettings;

export type ListDraft = {
  settings: AdditionalSettingsFields;
  items: EditableFilterItem[];
};

export interface ListOperations {
  allowDeleteItem: boolean;
  allowAddItem: boolean;
  allowDisableAll: boolean;
  uniqueFields?: FilterItemProperty[];
}

export enum ChangeLogActions {
  AddItem = 'AddItem',
  DeleteItem = 'DeleteItem',
  UpdateItem = 'UpdateItem',
  UpdateList = 'UpdateList',
}
export interface ChangeLog {
  items: EditableFilterItem[];
}

interface FiltersListMessages {
  mainTitle: {
    text: string;
    tooltip?: {
      enabled: boolean;
      text: string | ReactNode;
      icon: AvailableIcons;
    };
  };
  delete?: {
    title: string;
    body?: string;
  };
  disable: {
    title: string;
    body?: string;
  };
}
export interface FiltersListProps {
  itemsList: FilterItemDBStructure[];
  isDragAndDropEnabled: boolean;
  messages: FiltersListMessages;
  isDirtyDispatcher: (isDirty: boolean) => void;
  hasExternalDiff: boolean;
  externalChanges?: AdditionalSettingsFields;
  listActionOptions: ListOperations;
  children?: JSX.Element | JSX.Element[];
  onSubmit: (data: EditableFilterItem[], diff: FilterItemDBStructure[]) => void;
  counterFormatter?: (total: number, active: number) => string;
  rawDataFields: ShopDataField[];
  unSavedDataFields: ShopDataField[];
  syncItemsCount?: (active: number) => void;
  updateStatus: UpdateReqStatus;
  shouldIncludeDisplayName: boolean;
  formApiRef: RefObject<FormApiRef>;
  onSubmitStatusChange: (canSubmit: boolean) => void;
}

export interface ListReducerState {
  items: EditableFilterItem[];
  lastRegisteredAction?: ChangeLogActions;
}

export interface OnChangePayload {
  changedProperty: keyof EditableFilterItem;
  newValue: FilterItemPropertyValue;
}

export * from 'src/services/src/service/types/filters';
export * from 'src/components-bl/helpers/form-validation';
export * from 'src/services/src/service/types/filters';
