import React from 'react';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

interface DeleteFilterConfirmationDialogProps {
  onCloseDeleteDialog: () => void;
  onDelete: () => void;
}

export const DeleteFilterConfirmationDialog = ({
  onCloseDeleteDialog,
  onDelete,
}: DeleteFilterConfirmationDialogProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onCloseDeleteDialog}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>You are about to delete filter</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        Deleting a filter, will remove it from your filters list.
        <br />
        Would you still like to proceed and delete this filter ?
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='destructive' onClick={onDelete}>
            Yes, Delete Filter
          </Button>
          <Button variant='outlined' onClick={onCloseDeleteDialog}>
            No, Let&apos;s Go Back
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
