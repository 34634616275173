import React, { ReactNode } from 'react';
import { useUserPermissions } from '../../hooks/useUserPermissions';

interface Props {
  children: ReactNode;
}

export const SyteAdminContent = ({ children }: Props): JSX.Element | null => {
  const { isSyteAdmin } = useUserPermissions();
  if (!isSyteAdmin) return null;
  return <>{children}</>;
};
