import { useEffect, useRef, useState } from 'react';
import { useDebounce } from '../../components-dummy';
import recentSearches from './recent-searches';
import { AdminSearchService } from '../../services/src/service/admin-search';
import { AdminSearchResults } from '../../services/src/service/types/admin-search/admin-search-results';

const adminSearchService = new AdminSearchService();

interface UseAdminSearchProps {
  onResults: (results: AdminSearchResults | null) => void;
  isNoResults: boolean;
}

interface UseAdminSearchReturn {
  searchTerm: string;
  onChange: (value: string) => void;
  onReset: VoidFunction;
}

export const useAdminSearch = ({
  onResults,
  isNoResults,
}: UseAdminSearchProps): UseAdminSearchReturn => {
  const [searchTerm, setSearchTerm] = useState('');
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  const searchDebounced = useDebounce(async (newSearch: string): Promise<any> => {
    let results: AdminSearchResults | null = null;

    try {
      if (newSearch.length > 1) {
        results = await adminSearchService.search(newSearch);
      }
    } catch (error) {
      console.error(error);
    }

    onResults(results || null);
  }, 300);

  const onChange = async (value: string) => {
    setSearchTerm(value);
    searchDebounced(value);
  };

  const onReset = () => {
    setSearchTerm('');
  };

  useEffect(() => {
    if (!isNoResults) {
      recentSearches.write(searchTerm);
    }
  }, [isNoResults, searchTerm]);

  return {
    searchTerm,
    onChange,
    onReset,
  };
};
