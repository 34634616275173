/* eslint no-param-reassign:0 */
import { isEqual } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationType } from '../../../components-dummy';
import { ResponseValue } from '../../socialDiscovery/types/common.types';
import { IStoriesSettingsResponse } from '../../../services/src/service/stories/stories-service';
import {
  IEditableStoriesSettings,
  IStoriesSettings,
  StoriesActionTypes,
  StoriesState,
  StoriesStateType,
} from '../storiesPage.types';

const initialState = {
  type: StoriesStateType.fetching,
} as StoriesState;

type ResolvedPayload = PayloadAction<ResponseValue<IStoriesSettingsResponse>>;

const getEditableSettings = ({
  numberOfResultsToDisplay,
  storiesStrategy,
}: IStoriesSettings): IEditableStoriesSettings => {
  return { numberOfResultsToDisplay, storiesStrategy };
};

export const storiesSettingsSlice = createSlice({
  name: 'stories/storiesPageSlice',
  initialState,
  reducers: {
    resolveStoriesSettings: (state: StoriesState, { payload }: ResolvedPayload) => {
      if (payload.error !== null) {
        state.type = StoriesStateType.error;
        state.message = {
          type: NotificationType.Error,
          messageKey: StoriesActionTypes.GeneralError,
        };
        return;
      }
      state.type = StoriesStateType.noChanges;
      state.generalSettings = payload.data.settings;
    },
    resolveSavedSettings: (state: StoriesState, { payload }: ResolvedPayload) => {
      if (payload.error !== null) {
        state.type = StoriesStateType.dirty;
        state.message = {
          type: NotificationType.Error,
          messageKey: StoriesActionTypes.GeneralError,
        };
        return;
      }
      state.type = StoriesStateType.noChanges;
      state.generalSettings = payload.data.settings;
      state.message = {
        messageKey: StoriesActionTypes.SaveSettings,
        type: NotificationType.Success,
      };
    },

    updateSettings: (state: StoriesState, action: PayloadAction<IEditableStoriesSettings>) => {
      state.editedSettings = action.payload;
      state.type = isEqual(getEditableSettings(state.generalSettings), action.payload)
        ? StoriesStateType.noChanges
        : StoriesStateType.dirty;
    },
    setIsFetching: (state: StoriesState) => {
      state.type = StoriesStateType.fetching;
    },
    setIsSaving: (state: StoriesState) => {
      state.type = StoriesStateType.saving;
    },
    leavePage: (state: StoriesState) => {
      if (state.type !== StoriesStateType.noChanges) {
        state.message = {
          type: NotificationType.Warning,
          messageKey: StoriesActionTypes.ActionWarning,
        };
        return;
      }
      state.type = StoriesStateType.leaving;
    },
  },
});
