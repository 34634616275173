import React, { RefObject, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { isEqual } from 'lodash';
import { Dispatch } from 'src/components-bl/types';
import { Skeleton } from 'src/components-dummy';
import { AugmentedSearchGeneralSettings } from 'src/services';
import { RedirectRulesCard } from './components';
import { CardsWrapperStyled } from './AugmentedSearchSettings.styles';
import { augmentedSearchSettingsActions } from './augmentedSearchSettings.actions';
import { FormApiRef } from '../types';

interface AugmentedSearchSettingsProps {
  shopId?: number;
  augmentedSearchSettings?: AugmentedSearchGeneralSettings;
  formApiRef: RefObject<FormApiRef>;
  dispatch: Dispatch;
  onSubmitStatusChange: (canSubmit: boolean) => void;
}

export function AugmentedSearchSettings({
  shopId,
  augmentedSearchSettings,
  formApiRef,
  dispatch,
  onSubmitStatusChange,
}: AugmentedSearchSettingsProps): JSX.Element {
  const [settingsDraft, setSettingsDraft] = useState(augmentedSearchSettings);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (shopId) {
      dispatch(augmentedSearchSettingsActions.getGeneralSettings({ shopId }));
    }

    return () => {
      augmentedSearchSettingsActions.resetGeneralSettings();
    };
  }, [shopId, dispatch]);

  useEffect(() => {
    const isFormDirty = !isEqual(augmentedSearchSettings, settingsDraft);

    setIsDirty(isFormDirty);
  }, [augmentedSearchSettings, settingsDraft, setIsDirty]);

  useEffect(() => {
    onSubmitStatusChange(isDirty);
    dispatch(augmentedSearchSettingsActions.notifyIsDirty({ isDirty }));
  }, [isDirty, dispatch, onSubmitStatusChange]);

  useEffect(() => {
    setSettingsDraft(augmentedSearchSettings);
  }, [augmentedSearchSettings, setSettingsDraft]);

  const onSettingsChange = useCallback(
    (partialSettings: Partial<AugmentedSearchGeneralSettings>) => {
      setSettingsDraft(previousSettings => {
        if (previousSettings) {
          return { ...previousSettings, ...partialSettings };
        }
        return undefined;
      });
    },
    [setSettingsDraft]
  );

  const onSubmit = useCallback(async () => {
    if (shopId && settingsDraft) {
      dispatch(
        augmentedSearchSettingsActions.updateGeneralSettings({
          shopId,
          generalSettings: settingsDraft,
        })
      );
    }
  }, [shopId, settingsDraft, dispatch]);

  useImperativeHandle(
    formApiRef,
    () => {
      return {
        submit: onSubmit,
      };
    },
    [onSubmit]
  );

  return (
    <>
      {shopId && settingsDraft ? (
        <CardsWrapperStyled>
          <RedirectRulesCard
            augmentedSearchSettingsDraft={settingsDraft}
            onChange={onSettingsChange}
          />
        </CardsWrapperStyled>
      ) : (
        <Skeleton height={200} width={700} variant='rounded' />
      )}
    </>
  );
}
