import { RecEngines } from 'src/services';
import { Dispatch } from 'src/components-bl/types';
import {
  UpdateRecEnginesGeneralSettingsPayload,
  GetRecEnginesGeneralSettingsArgs,
  GetRecEnginesGeneralSettingsRequestPayload,
  GetRecEnginesGeneralSettingsSuccessPayload,
  GetRecEnginesGeneralSettingsErrorPayload,
  UpdateRecEnginesGeneralSettingsRequestPayload,
  UpdateRecEnginesGeneralSettingsSuccessPayload,
  UpdateRecEnginesGeneralSettingsErrorPayload,
  RecEnginesActionTypes,
  GetRecEnginesGeneralSettingsRequestAction,
  GetRecEnginesGeneralSettingsErrorAction,
  GetRecEnginesGeneralSettingsSuccessAction,
  UpdateRecEnginesGeneralSettingsRequestAction,
  UpdateRecEnginesGeneralSettingsSuccessAction,
  UpdateRecEnginesGeneralSettingsErrorAction,
  NotifyRecEnginesGeneralSettingsIsDirtyAction,
  NotifyRecEnginesGeneralSettingsIsDirtyActionPayload,
} from './types';

const recEnginesService = new RecEngines();

/* --= Actions =-- */
const recEnginesGeneralSettingsActions = {
  getRecEnginesGeneralSettingsRequest: (
    payload: GetRecEnginesGeneralSettingsRequestPayload
  ): GetRecEnginesGeneralSettingsRequestAction => {
    return {
      type: RecEnginesActionTypes.GetRecEnginesGeneralSettingsRequest,
      payload,
    };
  },
  getRecEnginesGeneralSettingsSuccess: (
    payload: GetRecEnginesGeneralSettingsSuccessPayload
  ): GetRecEnginesGeneralSettingsSuccessAction => {
    return {
      type: RecEnginesActionTypes.GetRecEnginesGeneralSettingsSuccess,
      payload,
    };
  },
  getRecEnginesGeneralSettingsError: ({
    error,
  }: GetRecEnginesGeneralSettingsErrorPayload): GetRecEnginesGeneralSettingsErrorAction => {
    return {
      type: RecEnginesActionTypes.GetRecEnginesGeneralSettingsError,
      payload: { error },
    };
  },
  updateRecEnginesGeneralSettingsRequest: (
    payload: UpdateRecEnginesGeneralSettingsRequestPayload
  ): UpdateRecEnginesGeneralSettingsRequestAction => {
    return {
      type: RecEnginesActionTypes.UpdateRecEnginesGeneralSettingsRequest,
      payload,
    };
  },
  updateRecEnginesGeneralSettingsSuccess: (
    payload: UpdateRecEnginesGeneralSettingsSuccessPayload
  ): UpdateRecEnginesGeneralSettingsSuccessAction => {
    return {
      type: RecEnginesActionTypes.UpdateRecEnginesGeneralSettingsSuccess,
      payload,
    };
  },
  updateRecEnginesGeneralSettingsError: (
    payload: UpdateRecEnginesGeneralSettingsErrorPayload
  ): UpdateRecEnginesGeneralSettingsErrorAction => {
    return {
      type: RecEnginesActionTypes.UpdateRecEnginesGeneralSettingsError,
      payload,
    };
  },

  notifyIsDirtyState: (
    payload: NotifyRecEnginesGeneralSettingsIsDirtyActionPayload
  ): NotifyRecEnginesGeneralSettingsIsDirtyAction => {
    return {
      type: RecEnginesActionTypes.NotifyRecEnginesGeneralSettingsIsDirty,
      payload,
    };
  },
};

/* --= Methods =-- */

export const recEnginesGeneralSettingsActionsMethods = {
  notifyIsDirty:
    ({ isDirty }: NotifyRecEnginesGeneralSettingsIsDirtyActionPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(recEnginesGeneralSettingsActions.notifyIsDirtyState({ isDirty }));
    },

  fetchRecEnginesGeneralSettings:
    ({ shopId, variantId }: GetRecEnginesGeneralSettingsArgs) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(recEnginesGeneralSettingsActions.getRecEnginesGeneralSettingsRequest({ shopId }));
        const {
          offerNavOpenResultsInNewTabDesktop,
          offerNavOpenResultsInNewTabMobile,
          overrideImageUrl,
          removeDuplicates,
          addToCart,
        } = await recEnginesService.get({
          shopId,
          variantId,
        });
        dispatch(
          recEnginesGeneralSettingsActions.getRecEnginesGeneralSettingsSuccess({
            shopId,
            offerNavOpenResultsInNewTabDesktop,
            offerNavOpenResultsInNewTabMobile,
            overrideImageUrl,
            removeDuplicates,
            addToCart,
          })
        );
      } catch (error) {
        dispatch(recEnginesGeneralSettingsActions.getRecEnginesGeneralSettingsError({ error }));
      }
    },

  updateRecEnginesGeneralSettings:
    ({
      shopId,
      variantId,
      offerNavOpenResultsInNewTabDesktop,
      offerNavOpenResultsInNewTabMobile,
      overrideImageUrl,
      removeDuplicates,
      addToCart,
    }: UpdateRecEnginesGeneralSettingsPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(
          recEnginesGeneralSettingsActions.updateRecEnginesGeneralSettingsRequest({
            shopId,
            offerNavOpenResultsInNewTabDesktop,
            offerNavOpenResultsInNewTabMobile,
            overrideImageUrl,
            removeDuplicates,
            addToCart,
          })
        );

        await recEnginesService.update({
          shopId,
          variantId,
          offerNavOpenResultsInNewTabDesktop,
          offerNavOpenResultsInNewTabMobile,
          overrideImageUrl,
          removeDuplicates,
          addToCart,
        });

        dispatch(
          recEnginesGeneralSettingsActions.updateRecEnginesGeneralSettingsSuccess({
            shopId,
            offerNavOpenResultsInNewTabDesktop,
            offerNavOpenResultsInNewTabMobile,
            overrideImageUrl,
            removeDuplicates,
            addToCart,
          })
        );
      } catch (error) {
        dispatch(recEnginesGeneralSettingsActions.updateRecEnginesGeneralSettingsError({ error }));
      }
    },
};
