import React, { useEffect } from 'react';
import {
  ArrowIconStyled,
  NumericInputStyled,
  PaginationGoToPageByTextStyled,
} from './Pagination.styles';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import { Button } from '../Button';
import { AvailableIcons } from '../Icon';

const ENTER = [13, 'Enter'];

export interface OnPageChangeArguments {
  pageNumber: number;
  skip: number;
}

export interface PaginationGoToPageByTextProps {
  totalItems: number;
  itemsPerPage?: number;
  onPageChange: ({ pageNumber, skip }: OnPageChangeArguments) => void;
}

export const PaginationGoToPageByText = ({
  totalItems,
  itemsPerPage = 10,
  onPageChange,
}: PaginationGoToPageByTextProps): JSX.Element => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [pageTarget, setPageTarget] = React.useState<undefined | number>(undefined);

  const onPageNumberChange = React.useCallback(value => {
    setPageTarget(value);
  }, []);

  const onGoToPageClick = React.useCallback(() => {
    if (pageTarget) {
      onPageChange({ pageNumber: pageTarget, skip: itemsPerPage * (pageTarget - 1) });
    }
  }, [itemsPerPage, pageTarget]);

  const onNumericInputKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const nativeEvent = event.nativeEvent as KeyboardEvent;

      if (ENTER.includes(nativeEvent.code)) {
        onGoToPageClick();
        (event.target as HTMLInputElement).blur();
      }
    },
    [onGoToPageClick]
  );

  useEffect(() => {
    // Reset on changed values
    setPageTarget(undefined);
  }, [itemsPerPage, totalItems]);

  return (
    <PaginationGoToPageByTextStyled>
      <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
        Go to page:
      </Typography>
      <NumericInputStyled
        value={pageTarget}
        maxNumericValue={totalPages}
        onChange={onPageNumberChange}
        minNumericValue={1}
        onKeyDown={onNumericInputKeyDown}
      />
      <Button variant='tertiary' onClick={onGoToPageClick} disabled={!pageTarget}>
        Go
        <ArrowIconStyled name={AvailableIcons.ArrowRounded} />
      </Button>
    </PaginationGoToPageByTextStyled>
  );
};
