import { dataFieldsService, FieldType, Filters } from 'src/services';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

const filtersService = new Filters();

export const filterSetsActions = {
  getFilters: createAsyncThunk(
    'FiltersSets/GetRequest',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const fieldsMetadata = await filtersService.getFilterSets(shopId);
        return fieldsMetadata;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  resetFiltersSetsState: createAction('FiltersSets/ResetFiltersSetsState'),
  getDataFields: createAsyncThunk(
    'FiltersSets/GetDataFields',
    async (
      { shopId, fieldType }: { shopId: number; fieldType: FieldType },
      { rejectWithValue }
    ) => {
      try {
        const dataFields = await dataFieldsService.getDataFields({ shopId, fieldType });
        return { shopId, dataFields };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
