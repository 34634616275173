import React from 'react';
import { AvailableIcons, Icon, Link, Page, TypographyVariant } from 'src/components-dummy';
import { ICatalogPlanAPI, IShopSftpSettings } from 'src/services';
import { CatalogForm, CatalogFormProps, CatalogApiRef, catalogFormSkeleton } from '../CatalogForm';
import { CatalogFormPageStyled } from './CatalogFormPage.styles';

export interface CatalogFormPageProps extends CatalogFormProps {
  formApiRef: React.Ref<CatalogApiRef>;
  selectedCatalog?: ICatalogPlanAPI;
  shopSFTPSettings?: IShopSftpSettings;
  existedCatalogNames?: Record<string, boolean>;
  onNotifyCopyOnClipboard: () => void;
}

const CREATE_TITLE = 'Create new catalogue';
const EDIT_TITLE = 'Edit catalogue';

export const CatalogFormPage = ({
  shopId,
  shopName,
  shopSFTPSettingsCredentials,
  formApiRef,
  mode,
  selectedCatalog,
  onNotifyCopyOnClipboard,
  onFormStatusChange,
  existedCatalogNames,
  dispatch,
}: CatalogFormPageProps): JSX.Element => {
  let shouldShowForm;

  if (mode === 'edit') {
    shouldShowForm = shopId && !!selectedCatalog;
  } else {
    shouldShowForm = shopId;
  }

  return (
    <CatalogFormPageStyled layout='default'>
      <Page.Header>
        <Page.Title variant={TypographyVariant.SmallBold}>
          {mode === 'create' ? CREATE_TITLE : EDIT_TITLE}
        </Page.Title>
        <Link href='https://syte-knowledge-center.refined.site/space/SI/2738290693/Introduction+to+Products+Feed'>
          <Link.Text>How to set a new Syte Catalogue</Link.Text>
          <Icon name={AvailableIcons.ExternalLink} />
        </Link>
      </Page.Header>
      <Page.Content>
        {shouldShowForm ? (
          <CatalogForm
            mode={mode}
            shopId={shopId}
            shopName={shopName}
            dispatch={dispatch}
            existedCatalogNames={existedCatalogNames}
            shopSFTPSettingsCredentials={shopSFTPSettingsCredentials}
            ref={formApiRef}
            catalog={selectedCatalog}
            onFormStatusChange={onFormStatusChange}
            onNotifyCopyOnClipboard={onNotifyCopyOnClipboard}
          />
        ) : (
          catalogFormSkeleton
        )}
      </Page.Content>
    </CatalogFormPageStyled>
  );
};
