import React from 'react';
import {
  AccordionItemContentProps,
  AccordionItemHeaderProps,
  AccordionItemProps,
  AccordionItemTriggerProps,
} from '../types';

export const AccordionItemTrigger = ({ children }: AccordionItemTriggerProps): JSX.Element => {
  return <>{children}</>;
};

export const AccordionItemHeader = ({ children }: AccordionItemHeaderProps): JSX.Element => {
  return <>{children}</>;
};

export const AccordionItemContent = ({ children }: AccordionItemContentProps): JSX.Element => {
  return <>{children}</>;
};

export const AccordionItem = ({ children }: AccordionItemProps): JSX.Element => {
  return <>{children}</>;
};

AccordionItemHeader.Trigger = AccordionItemTrigger;
AccordionItem.Header = AccordionItemHeader;
AccordionItem.Content = AccordionItemContent;
