import { ActionType, ModerationModalReducer, StateType } from './types';

export const moderationModalReducer: ModerationModalReducer = (state, action) => {
  switch (state.type) {
    case StateType.hasNoSelectedPosts:
      if (action.type === ActionType.addPost) {
        return { type: StateType.hasSelectedPosts, selectedPosts: [action.payload] };
      }
      return state;
    case StateType.hasSelectedPosts:
      if (action.type === ActionType.resetState) {
        return { type: StateType.hasNoSelectedPosts, selectedPosts: null };
      }
      if (action.type === ActionType.removePost) {
        const selectedPosts = state.selectedPosts.filter(id => id !== action.payload);
        if (!selectedPosts.length)
          return { type: StateType.hasNoSelectedPosts, selectedPosts: null };
        return { selectedPosts, type: StateType.hasSelectedPosts };
      }
      if (action.type === ActionType.addPost) {
        const selectedPosts = [...state.selectedPosts, action.payload];
        return { selectedPosts, type: StateType.hasSelectedPosts };
      }
      return state;
    default:
      return state;
  }
};
