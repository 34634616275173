import React from 'react';

import { AvailableIcons, Button } from '../../../components-dummy';
import { useOpenEditor } from '../useOpenEditor';

import type { UiTemplatesProps } from '../types';

export const EditButton = ({ shopId, dispatch, openPopup }: UiTemplatesProps): JSX.Element => {
  const { open } = useOpenEditor({ shopId, dispatch, openPopup });

  return (
    <Button startIcon={AvailableIcons.AddLight} onClick={open}>
      Start Edit
    </Button>
  );
};
