import React from 'react';
import { Typography, TypographyType, TypographyVariant } from '../../../../components-dummy';
import { ProductSection } from './ProductSection';
import { mapObjectEntries } from './mapObjectEntries';
import { ProductDataStyled, TitleStyled } from './ProductDetails.styled';

const accordionId = 'product-identification';

export enum ProductIdentification {
  sku = 'SKU',
  sizeSku = 'Size SKU',
  colorSku = 'Color SKU',
  parentSku = 'Parent SKU',
}

interface Props {
  skus?: {
    sku?: string;
    sizeSku?: string;
    colorSku?: string;
    parentSku?: string;
  };
}

export const ProductIdentificationSection = ({ skus }: Props) => {
  const productIdentificationToDisplay = mapObjectEntries({
    obj: skus,
    mapping: ProductIdentification,
  });

  return (
    <ProductSection title='Product Identification' accordionId={accordionId}>
      {productIdentificationToDisplay.map(({ displayName, value }) => (
        <ProductDataStyled key={displayName}>
          <TitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
            {displayName}
          </TitleStyled>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            {value}
          </Typography>
        </ProductDataStyled>
      ))}
    </ProductSection>
  );
};
