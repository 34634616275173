import { SubDomainGroupBase } from 'src/components-bl';
import { AvailableIcons } from 'src/components-dummy';
import { CustomInspirationsGallery, DomainEntityPath } from 'src/services';
import { DomainGroup } from '../../types';
import { customInspirationsGalleryDiffsSchema } from './custom-inspirations-galleries-diff-schema';

type EntityType = CustomInspirationsGallery;

export const customInspirationsGalleryDomainGroup: DomainGroup & SubDomainGroupBase<EntityType> = {
  domainKey: DomainEntityPath.CustomInspirationsGallery,
  title: 'Custom Inspirations Galleries',
  icon: AvailableIcons.DottedCircle,
  getCardTitle: ({ newEntity, oldEntity }): string => {
    const gallery = newEntity ?? oldEntity;

    return gallery?.galleryName || '';
  },
  fieldsSchema: customInspirationsGalleryDiffsSchema,
};
