/* --= Action Types =-- */
export enum EnableUiTestActionTypes {
  GetEnableUiTestRequest = 'EnableUiTest/GetEnableUiTestRequest',
  GetEnableUiTestSuccess = 'EnableUiTest/GetEnableUiTestSuccessAction',
  GetEnableUiTestError = 'EnableUiTest/GetEnableUiTestError',
  UpdateEnableUiTestRequest = 'EnableUiTest/UpdateEnableUiTestRequest',
  UpdateEnableUiTestSuccess = 'EnableUiTest/UpdateEnableUiTestSuccess',
  UpdateEnableUiTestError = 'EnableUiTest/UpdateEnableUiTestError',
  NotifyEnableUiTestIsDirty = 'EnableUiTest/NotifyIsDirty',
  PartialEnableUiTestUpdate = 'EnableUiTest/PartialEnableUiTestUpdate',
  NavigateTo = 'EnableUiTest/NavigateTo',
}

export interface UpdateEnableUiTestPayload {
  shopId: number;
  selectedVariantId?: string;
  enableUiTest: boolean;
}

export interface GetEnableUiTestArgs {
  shopId: number;
  selectedVariantId?: string;
}

export interface GetEnableUiTestRequestPayload {
  shopId: number;
}

export interface GetEnableUiTestSuccessPayload {
  shopId: number;
  enableUiTest: boolean;
}

export interface GetEnableUiTestErrorPayload {
  error: unknown;
}

export interface UpdateEnableUiTestRequestPayload {
  shopId: number;
  enableUiTest: boolean;
}

export interface UpdateEnableUiTestSuccessPayload {
  shopId: number;
  enableUiTest: boolean;
}

export interface UpdateEnableUiTestErrorPayload {
  error: unknown;
}

export interface NotifyEnableUiTestIsDirtyActionPayload {
  isDirty: boolean;
}

/* --= Action Interfaces =-- */

export interface GetEnableUiTestRequestAction {
  type: typeof EnableUiTestActionTypes.GetEnableUiTestRequest;
  payload: GetEnableUiTestRequestPayload;
}

export interface GetEnableUiTestSuccessAction {
  type: typeof EnableUiTestActionTypes.GetEnableUiTestSuccess;
  payload: GetEnableUiTestSuccessPayload;
}

export interface GetEnableUiTestErrorAction {
  type: typeof EnableUiTestActionTypes.GetEnableUiTestError;
  payload: GetEnableUiTestErrorPayload;
}

export interface UpdateEnableUiTestRequestAction {
  type: typeof EnableUiTestActionTypes.UpdateEnableUiTestRequest;
  payload: UpdateEnableUiTestRequestPayload;
}

export interface UpdateEnableUiTestSuccessAction {
  type: typeof EnableUiTestActionTypes.UpdateEnableUiTestSuccess;
  payload: UpdateEnableUiTestSuccessPayload;
}

export interface UpdateEnableUiTestErrorAction {
  type: typeof EnableUiTestActionTypes.UpdateEnableUiTestError;
  payload: UpdateEnableUiTestErrorPayload;
}

export interface NotifyEnableUiTestIsDirtyAction {
  type: typeof EnableUiTestActionTypes.NotifyEnableUiTestIsDirty;
  payload: NotifyEnableUiTestIsDirtyActionPayload;
}

export type EnableUiTestAction =
  | GetEnableUiTestRequestAction
  | GetEnableUiTestSuccessAction
  | GetEnableUiTestErrorAction
  | UpdateEnableUiTestRequestAction
  | UpdateEnableUiTestSuccessAction
  | UpdateEnableUiTestErrorAction
  | NotifyEnableUiTestIsDirtyAction;
