import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  UsePublishStatusIndicationReturnType,
  PublishStatusIndicationArguments,
  PublishStatusIndicationMode,
  PublishDiscardParameters,
} from './types';
import { publishStatusIndicationActionMethods } from './Actions';
import { modeToConfigMap } from './constants';

export const NotificationIsClosed = (shopId: number): string =>
  `NotificationShopIsUpToDateHasBeenClosed_${shopId}`;

export const usePublishStatusIndication = ({
  shopId,
  dispatch,
  draft,
  mode,
}: PublishStatusIndicationArguments): UsePublishStatusIndicationReturnType => {
  const { go } = useHistory();
  const [modalIsOpened, setModalIsOpened] = useState(false);

  const hasDraft = useMemo(() => {
    if (!draft) {
      return false;
    }
    return !!draft.changesSummary.changedEntities.length;
  }, [draft]);

  const actualMode =
    mode === PublishStatusIndicationMode.CanPublish && !hasDraft
      ? PublishStatusIndicationMode.UpToDate
      : mode;

  const modeConfig = modeToConfigMap[actualMode];

  const onDiscardDraft = ({
    isAllSelected,
    selectedDomainGroups,
    totalChanges,
  }: PublishDiscardParameters) => {
    localStorage.removeItem(NotificationIsClosed(shopId));
    dispatch(
      publishStatusIndicationActionMethods.discardDraft({
        shopId,
        resetPage: () => go(0),
        selectedDomainGroups,
        isAllSelected,
        totalChanges,
      })
    );
  };

  const onPublishDraft = async ({
    isAllSelected,
    selectedDomainGroups,
    totalChanges,
  }: PublishDiscardParameters) => {
    localStorage.removeItem(NotificationIsClosed(shopId));

    await dispatch(
      publishStatusIndicationActionMethods.publishDraft({
        isAllSelected,
        selectedDomainGroups,
        shopId,
        totalChanges,
        closeModal: () => setModalIsOpened(false),
      })
    );
  };

  useEffect(() => {
    if (shopId) {
      dispatch(publishStatusIndicationActionMethods.getDraft({ shopId, slim: false }));
    } else {
      dispatch(publishStatusIndicationActionMethods.resetDraft());
    }
  }, [shopId]);

  useEffect(() => {
    if (!hasDraft) {
      setModalIsOpened(false);
    }
  }, [hasDraft]);

  return {
    onDiscardDraft,
    onPublishDraft,
    modalIsOpened,
    setModalIsOpened,
    modeConfig,
    actualMode,
  };
};
