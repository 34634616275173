import type { KeyboardEvent } from 'react';

const KEYS = {
  enter: 'Enter',
  space: 'Space',
  tab: 'Tab',
  left: 'ArrowLeft',
  right: 'ArrowRight',
  up: 'ArrowUp',
  down: 'ArrowDown',
  escape: 'Escape',
};

export const handleKeyboardSelect = (
  e: KeyboardEvent,
  callback: (params?: any) => void,
  cbParams: any
): void => {
  const keyIdentifier = e.key || e.nativeEvent?.code;
  if (keyIdentifier === KEYS.space || keyIdentifier === KEYS.enter) {
    e.preventDefault();
    e.stopPropagation();
    const args = Array.isArray(cbParams) ? cbParams : [cbParams];
    callback(...args);
  }
};

export const handleFocusTrap = (e: KeyboardEvent, container: HTMLElement | null): void => {
  if (!container) {
    return;
  }
  const focusable = container.querySelectorAll('[tabindex="0"]');
  const firstFocusable = focusable[0];
  const lastFocusable = focusable[focusable.length - 1];
  const navigatingBack = e.shiftKey && e.key === KEYS.tab;
  const navigatingFwd = e.key === KEYS.tab && !e.shiftKey;
  if (navigatingBack) {
    if (document.activeElement === firstFocusable) {
      (lastFocusable as HTMLElement)?.focus();
      e.preventDefault();
    }
  }
  if (navigatingFwd) {
    if (document.activeElement === lastFocusable) {
      (firstFocusable as HTMLElement)?.focus();
      e.preventDefault();
    }
  }
};

export const handleKeyboardEvents = (
  e: KeyboardEvent,
  callback: (params: any) => void,
  cbParams: any,
  container: HTMLElement | null
): void => {
  handleKeyboardSelect(e, callback, cbParams);
  handleFocusTrap(e, container);
};
