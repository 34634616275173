import { AppDispatch } from 'src/app-types';
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { AIModelService, AIModelShopService } from 'src/services';
import { AIModel } from 'src/services/src/service/ai-models/types';

export const DEFAULT_MODEL: AIModel = { id: 'default', name: 'Default Model' };

export const shopAiModelSettingsActions = {
  notifyIsDirty: createAction<{ isDirty: boolean }>('ShopAIModel/NotifyIsDirty'),
  updateAiModel: createAsyncThunk(
    'AIModels/UpdateShopAIModel',
    async (
      {
        shopId,
        aiModelId,
        isUsingDefaultAIModel,
        aiModelList,
      }: {
        shopId: number;
        aiModelId: string;
        isUsingDefaultAIModel: boolean;
        aiModelList: AIModel[] | undefined;
      },
      { rejectWithValue }
    ) => {
      try {
        const aiModelService = new AIModelShopService();
        const shopAIModel = await aiModelService.updateAIModelPerShop({
          shopId,
          aiModelId,
          isUsingDefaultAIModel,
        });

        let aiModel: AIModel | undefined;

        if (shopAIModel.isUsingDefaultAIModel) {
          aiModel = DEFAULT_MODEL;
        } else {
          aiModel = aiModelList?.find(model => model.id === shopAIModel.aiModelId);
        }
        return aiModel;
      } catch (error) {
        console.error(error);
        return rejectWithValue({ shopId, error });
      }
    }
  ),
  getAIModelListWithShopModel: createAsyncThunk(
    'AIModels/GetAIModelListWithShopModel',
    async (
      {
        shopId,
      }: {
        shopId: number;
      },
      { rejectWithValue }
    ) => {
      try {
        const aiModelService = new AIModelService();
        const shopAiModelService = new AIModelShopService();

        const [aiModelList, shopAIModel] = await Promise.all([
          aiModelService.getAiModelsList(),
          shopAiModelService.getAIModelPerShop({
            shopId,
          }),
        ]);

        let aiModel: AIModel | undefined;

        if (shopAIModel.isUsingDefaultAIModel) {
          aiModel = DEFAULT_MODEL;
        } else {
          aiModel = aiModelList.find(modelItem => modelItem.id === shopAIModel?.aiModelId);
        }

        return { aiModel, aiModelList };
      } catch (error) {
        console.error(error);
        return rejectWithValue({ shopId, error });
      }
    }
  ),
};

export enum ShopSettingsContainerActionTypes {
  NavigateTo = 'ShopSettingsContainerActions/NavigateTo',
}

export interface ShopSettingsContainerActionsNavigateToActionPayload {
  navigateTo: string;
}

export interface ShopSettingsContainerActionsNavigateToAction {
  type: typeof ShopSettingsContainerActionTypes.NavigateTo;
  payload: ShopSettingsContainerActionsNavigateToActionPayload;
}

export type ShopSettingsContainerAction = ShopSettingsContainerActionsNavigateToAction;

export const shopSettingsContainerActions = {
  navigateTo(
    payload: ShopSettingsContainerActionsNavigateToActionPayload
  ): ShopSettingsContainerActionsNavigateToAction {
    return {
      type: ShopSettingsContainerActionTypes.NavigateTo,
      payload,
    };
  },
};

export const shopSettingsContainerActionMethods = {
  navigateTo(payload: ShopSettingsContainerActionsNavigateToActionPayload) {
    return (dispatch: AppDispatch): void => {
      dispatch(shopSettingsContainerActions.navigateTo(payload));
    };
  },
};
