import { ExperimentSlim } from 'src/services';

export enum ExperimentCardActionTypes {
  SelectExperiment = 'ExperimentCard/SelectExperiment',
  DeleteExperimentRequest = 'ExperimentCard/DeleteExperimentRequest',
  DeleteExperimentSuccess = 'ExperimentCard/DeleteExperimentSuccess',
  DeleteExperimentError = 'ExperimentCard/DeleteExperimentError',
  NavigateTo = 'ExperimentCard/NavigateTo',
}

export interface ExperimentCardNavigateToActionPayload {
  navigateTo: string;
}

export interface ExperimentCardNavigateToAction {
  type: typeof ExperimentCardActionTypes.NavigateTo;
  payload: ExperimentCardNavigateToActionPayload;
}

export interface ExperimentCardActionPayload {
  id: string;
  shopId: number;
  experiment?: ExperimentSlim;
  experiments?: ExperimentSlim[];
}

export interface ExperimentCardErrorActionPayload {
  error: unknown;
}

export interface ExperimentCardSelectExperimentAction {
  type: typeof ExperimentCardActionTypes.SelectExperiment;
  payload: ExperimentCardActionPayload;
}

export interface ExperimentCardDeleteExperimentRequestAction {
  type: typeof ExperimentCardActionTypes.DeleteExperimentRequest;
  payload: ExperimentCardActionPayload;
}

export interface ExperimentCardDeleteExperimentSuccessAction {
  type: typeof ExperimentCardActionTypes.DeleteExperimentSuccess;
  payload: ExperimentCardActionPayload;
}

export interface ExperimentCardDeleteExperimentErrorAction {
  type: typeof ExperimentCardActionTypes.DeleteExperimentError;
  payload: ExperimentCardErrorActionPayload;
}

export type ExperimentCardAction =
  | ExperimentCardSelectExperimentAction
  | ExperimentCardDeleteExperimentRequestAction
  | ExperimentCardDeleteExperimentSuccessAction
  | ExperimentCardDeleteExperimentErrorAction
  | ExperimentCardNavigateToAction;
