import React, { useMemo } from 'react';
import { DeepTagStatusEnum, ILexiconValue, DeepTagSourceEnum } from 'src/services';
import {
  AvailableIcons,
  EllipsisWithTooltip,
  Typography,
  TypographyType,
  TypographyVariant,
  Button,
  Icon,
  Tooltip,
} from 'src/components-dummy';
import { isGeneratedAttribute } from '../../../DeepTagsProductForm.helpers';
import { DeepTagProductSingleMenuSelect } from '../EditTags/DeepTagProductSingleMenuSelect';
import {
  AttributeNameStyled,
  AttributeStyled,
  IconStyled,
  RemoveButtonWrapperStyled,
  TagStyled,
  ValueStyled,
} from './DeepTagProductTags.style';
import {
  DeepTagForm,
  DeepTagLexiconAttributeItemForm,
  DeepTagLexiconValueItemForm,
  OnGeneratedAttributeEdit,
  OnRemoveOrRestoreTag,
} from '../../../DeepTagsProductForm.types';
import { DeepTagStatus } from './DeepTagStatus';

const TAG_ICON_TOOLTIP_TEXT = {
  AI_TAG: 'AI Tags',
  THEMATIC_TAG: 'Thematic Tags',
};

interface DeepTagProductTagProps {
  lexiconAttributeValues?: { [valueKey: string]: ILexiconValue };
  attribute: DeepTagLexiconAttributeItemForm;
  value: DeepTagLexiconValueItemForm;
  tagSource: DeepTagSourceEnum;
  status: DeepTagForm['status'];
  tagToRemove: Partial<DeepTagForm> | undefined;
  onRemoveTag: OnRemoveOrRestoreTag;
  hasAddedInForm: DeepTagForm['hasAddedInForm'];
  shouldHideEditIndication: boolean;
  onGeneratedAttributeEdit?: OnGeneratedAttributeEdit;
  onAttributeValueChange: ({
    valueKey,
    attributeKey,
  }: {
    valueKey: string;
    attributeKey: string;
  }) => void;
}

export const DeepTagProductTag = ({
  attribute,
  value,
  tagToRemove,
  tagSource,
  onAttributeValueChange,
  onGeneratedAttributeEdit,
  onRemoveTag,
  hasAddedInForm,
  lexiconAttributeValues,
  status,
  shouldHideEditIndication,
}: DeepTagProductTagProps): JSX.Element => {
  const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null);
  const isGeneratedTag = isGeneratedAttribute(attribute);

  const options = useMemo(
    () =>
      lexiconAttributeValues
        ? Object.keys(lexiconAttributeValues || []).map(valueKey => {
            return {
              value: valueKey,
              text: lexiconAttributeValues[valueKey].translation,
            };
          })
        : [],
    [lexiconAttributeValues]
  );

  return (
    <DeepTagProductSingleMenuSelect
      key={`${attribute.translation}-${value?.translation}`}
      setTriggerComponent={(onAnchorElementSet: (element: HTMLDivElement) => void) => {
        const setAnchorRef = () => onAnchorElementSet(containerRef as HTMLDivElement);

        const createEditButton = (): JSX.Element | null => {
          if (status !== DeepTagStatusEnum.Deleted && tagSource !== DeepTagSourceEnum.Thematic) {
            return isGeneratedTag ? (
              <IconStyled
                name={AvailableIcons.EditV2}
                onClick={() => onGeneratedAttributeEdit?.(attribute.key)}
              />
            ) : (
              <IconStyled
                name={AvailableIcons.EditV2}
                onClick={containerRef ? setAnchorRef : undefined}
              />
            );
          }

          return null;
        };

        const createRemoveRestoreButton = (): JSX.Element => {
          if (status === DeepTagStatusEnum.Deleted) {
            return (
              <Button
                variant='tertiary'
                size='tiny'
                onClick={onRemoveTag({ attribute, hasAddedInForm: !!hasAddedInForm, status })}
              >
                Restore
              </Button>
            );
          }

          return (
            <IconStyled
              name={AvailableIcons.TrashCan}
              onClick={onRemoveTag({ attribute, hasAddedInForm: !!hasAddedInForm, status })}
            />
          );
        };

        const createTagSourceIcon = (): JSX.Element | null => {
          switch (tagSource) {
            case DeepTagSourceEnum.Lexicon:
              return (
                <Tooltip value={TAG_ICON_TOOLTIP_TEXT.AI_TAG}>
                  <Icon name={AvailableIcons.AITag} />
                </Tooltip>
              );
            case DeepTagSourceEnum.Thematic:
              return (
                <Tooltip value={TAG_ICON_TOOLTIP_TEXT.THEMATIC_TAG}>
                  <Icon name={AvailableIcons.ThematicTag} />
                </Tooltip>
              );
            default:
              return null;
          }
        };

        return (
          <TagStyled key={`${attribute.translation}-${value?.translation}`} isClickable>
            <div onClick={!isGeneratedTag ? setAnchorRef : undefined} ref={setContainerRef}>
              <AttributeNameStyled isRemoved={status === DeepTagStatusEnum.Deleted}>
                <Typography type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                  <EllipsisWithTooltip tooltipText={attribute.translation || ''}>
                    {attribute.translation}
                  </EllipsisWithTooltip>
                </Typography>
                {createTagSourceIcon()}
              </AttributeNameStyled>
              <AttributeStyled>
                <ValueStyled
                  type={TypographyType.Body}
                  variant={TypographyVariant.MediumMedium}
                  isRemoved={status === DeepTagStatusEnum.Deleted}
                  isMissingValue={!value.translation}
                >
                  <EllipsisWithTooltip tooltipText={value.translation || ''}>
                    {value.translation || 'Please select value'}
                  </EllipsisWithTooltip>
                </ValueStyled>
              </AttributeStyled>
            </div>

            <RemoveButtonWrapperStyled shouldShow={tagToRemove?.attribute === attribute}>
              {createEditButton()}
              {createRemoveRestoreButton()}
            </RemoveButtonWrapperStyled>

            {tagToRemove?.attribute !== attribute && (
              <DeepTagStatus status={status} shouldHideEditIndication={shouldHideEditIndication} />
            )}
          </TagStyled>
        );
      }}
      onChange={selectedValueKey => {
        onAttributeValueChange({
          attributeKey: attribute.key,
          valueKey: selectedValueKey,
        });
      }}
      options={options}
      isMenuSelectEnabled={!isGeneratedTag}
      currentValue={value.key}
    />
  );
};
