import { EditableFilterItem, FilterItemDBStructure } from './types';

export enum FiltersListActionTypes {
  Init = 'FiltersList/InitForm',
  AddNewItem = 'FiltersList/AddNewItem',
  SaveItem = 'FiltersList/SaveItem',
  DismissItemForm = 'FiltersList/DismissItemForm',
  DeleteItem = 'FiltersList/DeleteItem',
  UpdateList = 'FiltersList/UpdateList',
}

export type DismissItemActionPayload = {
  itemKey: string;
  originalItem: FilterItemDBStructure | undefined;
};
type DeleteItemActionPayload = string;
type SaveItemActionPayload = {
  item: EditableFilterItem;
  cb: (newItemKey: string) => void;
};
interface UpdateListAction {
  type: FiltersListActionTypes.UpdateList;
  payload: EditableFilterItem[];
}
interface FiltersFormInitAction {
  type: FiltersListActionTypes.Init;
  payload: EditableFilterItem[];
}
interface FiltersAddNewItemAction {
  type: FiltersListActionTypes.AddNewItem;
  payload: (newItem: EditableFilterItem) => void;
}
interface FiltersSaveItemAction {
  type: FiltersListActionTypes.SaveItem;
  payload: SaveItemActionPayload;
}
interface DismissItemFormAction {
  type: FiltersListActionTypes.DismissItemForm;
  payload: DismissItemActionPayload;
}
interface DeleteItemAction {
  type: FiltersListActionTypes.DeleteItem;
  payload: DeleteItemActionPayload;
}

export type FiltersListAction =
  | UpdateListAction
  | FiltersFormInitAction
  | FiltersAddNewItemAction
  | FiltersSaveItemAction
  | DismissItemFormAction
  | DeleteItemAction;

export const filtersListActions = {
  formInit: (payload: EditableFilterItem[]): FiltersListAction => {
    return { type: FiltersListActionTypes.Init, payload };
  },
  addNew: (payload: (newItem: EditableFilterItem) => void): FiltersListAction => {
    return { type: FiltersListActionTypes.AddNewItem, payload };
  },
  saveItem: (payload: SaveItemActionPayload): FiltersListAction => {
    return { type: FiltersListActionTypes.SaveItem, payload };
  },
  dismissItemForm: (payload: DismissItemActionPayload): FiltersListAction => {
    return { type: FiltersListActionTypes.DismissItemForm, payload };
  },
  deleteItem: (payload: DeleteItemActionPayload): FiltersListAction => {
    return { type: FiltersListActionTypes.DeleteItem, payload };
  },
  updateList: (payload: EditableFilterItem[]): FiltersListAction => {
    return { type: FiltersListActionTypes.UpdateList, payload };
  },
};
