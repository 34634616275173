import React, { MouseEvent, useCallback, useState } from 'react';
import { Row } from 'react-table';
import { TableV2, TableV2InstanceProps } from 'src/components-dummy';
import { Dispatch } from 'src/components-bl/types';
import { useShowVersionReviewModal } from 'src/components-bl/Versioning/ReviewVersion';
import { IVersionsTableRow } from '../../types';
import { versionsTableActions } from '../../Actions';
import { tableColumns } from '../../tableColumns';
import { RestoreVersionConfirmationDialog } from '../RestoreVersionConfirmationDialog';
import { TableBodyRowCellStyled } from '../../VersionsTable.styles';
import { RowActionsMenu } from '../RowActionsMenu';
import { BadgeStyled } from './VersionTableRow.styles';

interface VersionsTableRowProps {
  row: Row<IVersionsTableRow>;
  shopId: number;
  dispatch: Dispatch;
}

export const VersionsTableRow = ({ row, shopId, dispatch }: VersionsTableRowProps): JSX.Element => {
  const { isProduction: isProductionVersion, hasChangesSummary } = row.original;

  const canRestore = isProductionVersion === false;

  const shouldShowActionMenu = hasChangesSummary || canRestore;

  const [showRestoreDialog, setShowRestoreDialog] = useState(false);

  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);

  const { getVersion } = useShowVersionReviewModal({ shopId, dispatch });

  const onMenuClose = useCallback(() => {
    setMenuAnchorElement(null);
  }, [setMenuAnchorElement]);

  const onReviewVersionClick = useCallback(() => {
    onMenuClose();
    getVersion(row.original.id);
  }, [row.original.id, onMenuClose, getVersion]);

  const onMenuClicked = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setMenuAnchorElement(event.currentTarget);
    },
    [setMenuAnchorElement]
  );

  const onVersionRestoreConfirm = useCallback(() => {
    dispatch(versionsTableActions.restoreFromVersion({ shopId, versionId: row.original.id }));
    setShowRestoreDialog(false);
  }, [dispatch, shopId, row.original.id]);

  const onRestoreVersionClick = useCallback(() => {
    setShowRestoreDialog(true);
  }, [setShowRestoreDialog]);

  const onCloseRestoreDialog = useCallback(() => {
    setShowRestoreDialog(false);
  }, [setShowRestoreDialog]);

  const renderCell = ({
    cell,
  }: {
    cell: TableV2InstanceProps<IVersionsTableRow>['rows'][0]['cells'][0];
  }) => {
    switch (cell.column.id) {
      case tableColumns.rowActions.accessor: {
        return shouldShowActionMenu ? (
          <RowActionsMenu
            menuAnchorElement={menuAnchorElement}
            onMenuClicked={onMenuClicked}
            onMenuClose={onMenuClose}
            onRestoreVersionClick={canRestore ? onRestoreVersionClick : undefined}
            onReviewVersionClick={hasChangesSummary ? onReviewVersionClick : undefined}
          />
        ) : null;
      }
      case tableColumns.id.accessor: {
        const versionDisplayName = `Version ${row.original.publicationVersion}`;
        return (
          <>
            <TableV2.BodyRowCellText>{versionDisplayName}</TableV2.BodyRowCellText>
            {isProductionVersion && <BadgeStyled text='Production' />}
          </>
        );
      }
      default:
        return <TableV2.BodyRowCellText>{cell.value}</TableV2.BodyRowCellText>;
    }
  };

  return (
    <>
      {showRestoreDialog && (
        <RestoreVersionConfirmationDialog
          onCloseDialog={onCloseRestoreDialog}
          onRestore={onVersionRestoreConfirm}
        />
      )}
      <TableV2.BodyRow {...row.getRowProps()} key={row.original.id} className='version-table-row'>
        {row.cells.map(cell => {
          const propName = cell.column.id;

          return (
            <TableBodyRowCellStyled
              {...cell.getCellProps()}
              key={`${cell.column.id}-${cell.value}`}
              className='version-table-cell'
              data-id={`${row.original.id}-${propName}`}
              cellName={propName}
              isResizable
            >
              {renderCell({ cell })}
            </TableBodyRowCellStyled>
          );
        })}
      </TableV2.BodyRow>
    </>
  );
};
