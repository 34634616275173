import { ShopDataField, FeedDataFieldType } from 'src/services/src/service/types';
import { EditableFilterItem, BucketsOrderSort, BucketsOrderType } from '../types';

export function dataSourceChangeHandler(
  itemDraft: EditableFilterItem,
  { name, types }: ShopDataField
): EditableFilterItem {
  const isTypeNumeric = types.some(
    t =>
      t === FeedDataFieldType.Long ||
      t === FeedDataFieldType.Integer ||
      t === FeedDataFieldType.Float
  );
  const shouldResetTypeDependentFields = isTypeNumeric !== itemDraft.isTypeNumeric;
  return {
    ...itemDraft,
    dataSource: name,
    displayNameKey: itemDraft.displayNameKey || '',
    displayType: shouldResetTypeDependentFields ? undefined : itemDraft.displayType,
    bucketsOrder: shouldResetTypeDependentFields
      ? { sort: BucketsOrderSort.desc, type: BucketsOrderType.count }
      : itemDraft.bucketsOrder,
    isTypeNumeric,
  };
}
