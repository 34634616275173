import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ValidationErrorKey } from 'src/containers/ToastManager/ActionsNotifications/validation-error-key';
import {
  ImageTagsDetectionEndedEventData,
  ImageTagsDetectionStartedEventData,
  GalleryTagsDetectionStartedEventData,
  GalleryTagsDetectionEndedEventData,
  galleriesService,
} from 'src/services/src/service/galleries';

export const useGalleriesWSActions = {
  imageTagsDetectionStarted: createAction<ImageTagsDetectionStartedEventData>(
    'UseGalleriesWS/ImageTagsDetectionStarted'
  ),
  imageTagsDetectionEnded: createAction<ImageTagsDetectionEndedEventData>(
    'UseGalleriesWS/ImageTagsDetectionEnded'
  ),
  galleryTagsDetectionStarted: createAction<GalleryTagsDetectionStartedEventData>(
    'UseGalleriesWS/GalleryTagsDetectionStarted'
  ),
  galleryTagsDetectionEnded: createAction<GalleryTagsDetectionEndedEventData>(
    'UseGalleriesWS/GalleryTagsDetectionEnded'
  ),
  notification: createAction<{ customMessage: string }>('UseGalleriesWS/Notification'),
  errorNotification: createAction<{ error: { errorKey: ValidationErrorKey } }>(
    'UseGalleriesWS/ErrorNotification'
  ),
  getGallery: createAsyncThunk(
    'UseGalleriesWS/GetGallery',
    async (
      { shopId, galleryId, galleryType }: Parameters<typeof galleriesService.getGallery>[0],
      { rejectWithValue }
    ) => {
      try {
        const gallery = await galleriesService.getGallery({
          shopId,
          galleryId,
          galleryType,
        });
        return gallery;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
