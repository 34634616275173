import { v4 as uuidV4 } from 'uuid';
import { FieldType, MerchandisingRuleTypes, SyteProductTypes } from '../types';

export const mockMerchandisingRule: MerchandisingRuleTypes.MerchandiseRule = {
  active: false,
  name: 'aug',
  weight: 50,
  action: MerchandisingRuleTypes.RuleAction.Promote,
  subRules: [
    {
      field: 'description',
      fieldType: FieldType.CatalogField,
      values: ['123'],
      enabled: true,
      subType: MerchandisingRuleTypes.MerchandisingRuleSubType.EqualsSource,
    },
    {
      field: 'description',
      fieldType: FieldType.CatalogField,
      values: ['123'],
      enabled: true,
      subType: MerchandisingRuleTypes.MerchandisingRuleSubType.EqualsSource,
    },
    {
      field: 'description',
      fieldType: FieldType.CatalogField,
      values: ['123'],
      enabled: true,
      subType: MerchandisingRuleTypes.MerchandisingRuleSubType.EqualsSource,
    },
  ],
  product: SyteProductTypes.SyteProductType.AugmentedSearch,
  sourceCondition: [{ enabled: false, values: [], fieldType: FieldType.CatalogField }],
  searchCondition: { enabled: false, terms: [] },
  id: '6143275c3b15aaf516c8ebc7',
  appliedDateRange: {
    startDate: new Date(),
    endDate: new Date(),
    timeZone: 'Asia/Hovd',
  },
  regions: ['US', 'BE'],
};

export function generateMockMerchandisingRule(): MerchandisingRuleTypes.MerchandiseRule {
  return { ...mockMerchandisingRule, name: uuidV4().toString() };
}
