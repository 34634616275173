import React, { useCallback, useState } from 'react';
import {
  AvailableIcons,
  Button,
  Icon,
  TextBox,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { copyToClipboard } from 'src/utils';
import { UpdateApiKeyProps, shopApiKeysSettingsActions } from 'src/containers';
import { getDateTomorrow } from 'src/services/src/common/utils';
import { useValidateSchema } from 'src/hooks';
import { getUTCTimeForDatePicker } from 'src/components-dummy/TimePicker/TimePicker.helpers';
import {
  ModalContentStyled,
  ModalStyled,
  ModalTitleStyled,
  ModalFooterStyled,
  LinkButtonAddExpirationStyled,
  TextBoxApiKeyStyled,
  ExpirationSectionStyled,
  LinkButtonRemoveExpirationStyled,
  RemoveExpirationSpanStyled,
  ErrorsMessagesWrapperStyled,
  ErrorLabelStyled,
} from './ShopApiKeysSettingsModal.styled';
import { RuleDateTimePicker } from '../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormScheduleDateSection/components/RuleDateTimePicker';
import { ApiKeyFormRule, ShopApiKeysSettingsUpdateModalProps } from './types';
import { apiKeyValidationSchema } from './validation-schema';

export const ShopApiKeysUpdateSettingsModal = ({
  onModalClose,
  shopId,
  dispatch,
  selectedApiKey,
  apiKeys,
}: ShopApiKeysSettingsUpdateModalProps): JSX.Element => {
  const [label, setLabel] = useState(selectedApiKey.label);
  const [expirationDateTime, setExpirationDateTime] = useState<Date | null>(
    selectedApiKey.expiration ? selectedApiKey.expiration : null
  );
  const existingLabels = apiKeys
    .filter(apiKey => apiKey.id !== selectedApiKey.id)
    .map(apiKey => apiKey.label);

  const { errors, validate, isValid } = useValidateSchema<ApiKeyFormRule>({
    schema: apiKeyValidationSchema,
    validateOnStart: false,
    initialData: selectedApiKey.expiration
      ? { expiration: selectedApiKey.expiration, label }
      : { label },
  });

  const labelIsDirty = label !== selectedApiKey.label;
  const expirationIsDirty = expirationDateTime !== selectedApiKey.expiration;
  const isDirty = labelIsDirty || expirationIsDirty;

  const onUpdateApiKeyButtonClicked = useCallback(async () => {
    if (isValid && shopId) {
      const updateApiKeyMetaData = {
        shopId,
        apiKeyId: selectedApiKey.id,
      } as UpdateApiKeyProps;

      if (label !== selectedApiKey.label) updateApiKeyMetaData.label = label;
      if (
        (expirationDateTime && expirationDateTime !== selectedApiKey.expiration) ||
        (selectedApiKey.expiration && !expirationDateTime)
      )
        updateApiKeyMetaData.expiration = expirationDateTime;

      await dispatch(shopApiKeysSettingsActions.updateApiKey(updateApiKeyMetaData));
      await dispatch(shopApiKeysSettingsActions.getApiKeys({ shopId }));
      onModalClose();
    }
  }, [isValid, shopId, label, expirationDateTime]);

  const onAddExpirationDateToKeyButtonClick = useCallback(async () => {
    const dateTomorrow = getDateTomorrow();
    setExpirationDateTime(dateTomorrow);
    validate({ dataToValidate: { label, expiration: dateTomorrow }, context: { existingLabels } });
  }, [label, existingLabels, expirationDateTime]);

  const onRemoveExpirationDateButtonClick = useCallback(async () => {
    setExpirationDateTime(null);
    validate({ dataToValidate: { label }, context: { existingLabels } });
  }, [label, existingLabels, expirationDateTime]);

  const onExpiredDateTimeChanged = useCallback(
    async (dateTime: Date | null) => {
      if (dateTime) {
        setExpirationDateTime(dateTime);
        validate({ dataToValidate: { label, expiration: dateTime }, context: { existingLabels } });
      }
    },
    [label, existingLabels, expirationDateTime, setExpirationDateTime]
  );

  const onCopyToClipboard = (apiKey: string) => {
    copyToClipboard(apiKey);
    dispatch(shopApiKeysSettingsActions.copyToClipboard());
  };

  return (
    <ModalStyled isOpened onClickOutside={onModalClose}>
      <>
        <ModalTitleStyled>
          <Typography type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
            API Key details
          </Typography>
        </ModalTitleStyled>
        <ModalContentStyled>
          {errors.label ? (
            <ErrorsMessagesWrapperStyled>
              <ErrorLabelStyled> {errors.label.message} </ErrorLabelStyled>{' '}
            </ErrorsMessagesWrapperStyled>
          ) : null}
          <TextBox
            error={!!errors?.label}
            label='API Key Label'
            onChange={newLabel => {
              setLabel(newLabel);
              validate({
                dataToValidate: { label: newLabel, expiration: expirationDateTime },
                context: { existingLabels },
              });
            }}
            placeholder='Write here'
            value={label}
          />
          <TextBoxApiKeyStyled value={selectedApiKey.key} disabled>
            <Icon
              name={AvailableIcons.Duplicate}
              onClick={() => onCopyToClipboard(selectedApiKey.key)}
            />
          </TextBoxApiKeyStyled>
          {expirationDateTime ? (
            <ExpirationSectionStyled>
              <RuleDateTimePicker
                value={expirationDateTime}
                minDate={getUTCTimeForDatePicker(expirationDateTime)}
                minTime={getUTCTimeForDatePicker(expirationDateTime)}
                onChange={onExpiredDateTimeChanged}
                dateTitle='Date'
                timeTitle='Time (UTC+0)'
              />
              <LinkButtonRemoveExpirationStyled onClick={onRemoveExpirationDateButtonClick}>
                <Icon name={AvailableIcons.TrashCan} />
                <RemoveExpirationSpanStyled>Remove expiration date</RemoveExpirationSpanStyled>
              </LinkButtonRemoveExpirationStyled>
            </ExpirationSectionStyled>
          ) : (
            <LinkButtonAddExpirationStyled onClick={onAddExpirationDateToKeyButtonClick}>
              <Icon name={AvailableIcons.Plus} /> Add expiration date to key
            </LinkButtonAddExpirationStyled>
          )}
        </ModalContentStyled>
        <ModalFooterStyled>
          <Button variant='tertiary' onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            variant='primary'
            onClick={onUpdateApiKeyButtonClicked}
            disabled={!isValid || !isDirty}
          >
            Save
          </Button>
        </ModalFooterStyled>
      </>
    </ModalStyled>
  );
};
