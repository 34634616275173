import { useCallback, useEffect } from 'react';
import { dataFieldsWebSocketService, DataFieldUpdateMessage } from 'src/services';
import { Dispatch } from 'src/components-bl';
import { useDataFieldsWSActions } from './useDataFieldsWS.actions';

export const useDataFieldsWS = ({
  dispatch,
  shopId,
}: {
  dispatch: Dispatch;
  shopId: number | undefined;
}): void => {
  const issueToastNotification = ({
    eventIssuedByCurrentUser,
    isSystemEvent,
  }: Pick<DataFieldUpdateMessage, 'eventIssuedByCurrentUser' | 'topicKey' | 'isSystemEvent'>) => {
    if (eventIssuedByCurrentUser) return;

    let customMessage;

    if (isSystemEvent) {
      customMessage = 'Data fields synced';
    } else {
      customMessage = 'Data fields updated';
    }

    dispatch(useDataFieldsWSActions.dataFieldsNotification({ customMessage }));
  };

  const onDataFieldsUpdate = useCallback(
    (payload: DataFieldUpdateMessage) => {
      if (payload.data.shopId === shopId) {
        dispatch(useDataFieldsWSActions.dataFieldsUpdated(payload.data));

        issueToastNotification(payload);
      }
    },
    [shopId]
  );

  useEffect(() => {
    if (shopId) {
      dataFieldsWebSocketService.subscribeToDataFieldUpdateEvents(onDataFieldsUpdate);
    }

    return () => {
      dataFieldsWebSocketService.clearListeners();
    };
  }, [shopId]);
};
