import React, { MouseEvent, useCallback, useRef, useState } from 'react';
import { Dispatch } from 'src/components-bl';
import { DataFieldsValuesList } from 'src/components-bl/DataFields';
import { PopUp } from 'src/components-dummy';
import { copyToClipboard } from 'src/utils';
import { dataFieldValuesAutoSuggestActions } from './DataFieldValuesAutoSuggest.actions';
import { DataFieldsValuesAutoCompleteStyled } from './DataFieldValuesAutoSuggest.styles';

interface DataFieldValuesAutoSuggestProps {
  error?: boolean;
  disabled?: boolean;
  onChange: (values: string | string[]) => void;
  selectedValues: string[];
  dispatch: Dispatch;
  shopId: number;
  dataFieldName?: string;
  isMultiSelect?: boolean;
}

export const DataFieldValuesAutoSuggest = ({
  error,
  disabled,
  onChange,
  selectedValues,
  dispatch,
  dataFieldName,
  shopId,
  isMultiSelect = false,
}: DataFieldValuesAutoSuggestProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>();

  const onTagClick = useCallback(
    (tagValue: string, event?: MouseEvent) => {
      event?.stopPropagation();
      event?.preventDefault();

      copyToClipboard(tagValue);

      dispatch(
        dataFieldValuesAutoSuggestActions.notification({
          customMessage: 'Value copied to clipboard',
        })
      );
    },
    [dispatch]
  );

  const [showPopUp, setShowPopUp] = useState(false);

  const closePopUp = useCallback(() => {
    setShowPopUp(false);
  }, [setShowPopUp]);

  const onValuesChange = useCallback(
    (newValues: string[]) => {
      onChange(newValues);
      closePopUp();
    },
    [onChange, closePopUp]
  );

  return (
    <PopUp
      show={showPopUp}
      onShow={setShowPopUp}
      hideOnScroll={false}
      position='bottom left'
      elementsToFreezeOnActive={['.contentContainer', '.merchandising-rules-form-page']}
      enforceShowOnTriggerOnly
      closeOnTriggerClick={false}
    >
      <PopUp.Trigger>
        <DataFieldsValuesAutoCompleteStyled
          className='data-fields-auto-complete'
          errored={error}
          selectedValues={selectedValues}
          textBoxValue={isMultiSelect ? undefined : selectedValues[0]}
          onChange={onChange}
          disabled={disabled}
          options={[]}
          multiple={isMultiSelect}
          onTagClick={onTagClick}
          autoFocus
          inputRef={inputRef}
          readOnly
          allowDeleteTagInReadOnly
        />
      </PopUp.Trigger>
      <PopUp.Content>
        <DataFieldsValuesList
          dataField={dataFieldName}
          onSelect={onValuesChange}
          shopId={shopId}
          onCancel={closePopUp}
          selectedValues={selectedValues}
          isMultiSelect={isMultiSelect}
          allowFreeText
        />
      </PopUp.Content>
    </PopUp>
  );
};
