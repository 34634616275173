import React, { useCallback } from 'react';
import { CustomInspirationsGalleryLayoutType } from 'src/services';
import { CustomInspirationGalleryDraft } from '../../../../types';
import { GridLayoutSectionStyled, GridLayoutTypesRow } from './GridLayoutSection.styles';
import { LayoutSpecificConfigurations, LayoutTypeImage } from './components';

interface GridLayoutSectionProps {
  gallery: CustomInspirationGalleryDraft | undefined;
  onChange: (partialDraft: Partial<CustomInspirationGalleryDraft>) => void;
}

const layoutTypeToDefaults: Record<
  CustomInspirationsGalleryLayoutType,
  CustomInspirationGalleryDraft['layout']
> = {
  [CustomInspirationsGalleryLayoutType.Carousel]: {
    type: CustomInspirationsGalleryLayoutType.Carousel,
    carouselNumberOfImages: 3,
  } as CustomInspirationGalleryDraft<CustomInspirationsGalleryLayoutType.Carousel>['layout'],
  [CustomInspirationsGalleryLayoutType.Grid]: {
    type: CustomInspirationsGalleryLayoutType.Grid,
    gridImagesPerRow: 3,
    gridNumberOfRows: 3,
  } as CustomInspirationGalleryDraft<CustomInspirationsGalleryLayoutType.Grid>['layout'],
  [CustomInspirationsGalleryLayoutType.Collage]: {
    type: CustomInspirationsGalleryLayoutType.Collage,
  } as CustomInspirationGalleryDraft<CustomInspirationsGalleryLayoutType.Collage>['layout'],
};

export const GridLayoutSection = ({
  gallery,
  onChange,
}: GridLayoutSectionProps): JSX.Element | null => {
  const onLayoutChange = useCallback(
    (partialLayout: Partial<CustomInspirationGalleryDraft['layout']>) => {
      if (!gallery?.layout) return;

      let updatedLayout = {
        ...gallery.layout,
        ...partialLayout,
      } as CustomInspirationGalleryDraft['layout'];

      if (partialLayout.type) {
        const isTypeChange = partialLayout.type !== gallery.layout.type;

        if (isTypeChange) {
          updatedLayout = { ...layoutTypeToDefaults[partialLayout.type] };
        }
      }

      onChange({ layout: updatedLayout });
    },
    [gallery?.layout, onChange]
  );

  const onSelectGridType = useCallback(
    (newType: CustomInspirationsGalleryLayoutType) => {
      onLayoutChange({ type: newType });
    },
    [onLayoutChange]
  );

  if (!gallery?.layout) return null;

  return (
    <GridLayoutSectionStyled>
      <GridLayoutTypesRow>
        <LayoutTypeImage
          type={CustomInspirationsGalleryLayoutType.Grid}
          onSelect={onSelectGridType}
          currentSelected={gallery?.layout.type}
        />

        <LayoutTypeImage
          type={CustomInspirationsGalleryLayoutType.Carousel}
          onSelect={onSelectGridType}
          currentSelected={gallery?.layout.type}
          disabled
        />

        <LayoutTypeImage
          type={CustomInspirationsGalleryLayoutType.Collage}
          onSelect={onSelectGridType}
          currentSelected={gallery?.layout.type}
          disabled
        />
      </GridLayoutTypesRow>

      <LayoutSpecificConfigurations layout={gallery?.layout} onChange={onLayoutChange} />
    </GridLayoutSectionStyled>
  );
};
