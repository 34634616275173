import React from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { LinkButton } from 'src/components-dummy/LinkButton';
import { useAppDispatch } from 'src/hooks';
import { shopPageNotFoundContainerActions } from './ShopPageNotFoundContainer.actions';

type ShopPageNotFoundContainerProps = RoutedComponentProps;

// A quick copy of NotFoundPage with small adjustments  + shop link. copied to keep same styling.
// TODO: use proper Typography, separated styles file and shared style with NotFoundPage
export const ShopPageNotFoundContainer = ({
  permittedRouteMap,
}: ShopPageNotFoundContainerProps): JSX.Element => {
  const dispatch = useAppDispatch();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        textAlign: 'center',
        paddingTop: '10vh',
      }}
    >
      <h1 style={{ fontSize: '24px', marginBottom: '20px' }}>
        Page Not Found<small style={{ display: 'block' }}>or</small>You have no access permission
      </h1>
      <div>
        <LinkButton
          onClick={() => {
            if (permittedRouteMap?.shopList?.path) {
              dispatch(
                shopPageNotFoundContainerActions.navigateTo({
                  navigateTo: permittedRouteMap.shopList.path,
                })
              );
            }
          }}
        >
          <Typography type={TypographyType.Heading} variant={TypographyVariant.MediumMedium}>
            Back to Shop List
          </Typography>
        </LinkButton>
      </div>
    </div>
  );
};
