import React, { useCallback, useEffect, useState } from 'react';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import { Dispatch } from 'src/components-bl';
import {
  AutoCompleteWrapperStyled,
  ErrorLabelStyled,
  TextWithAutoCompleteStyled,
  TypographyTextStyled,
} from './DeepTagReportProductsEvenLabelBulk.style';
import { ErrorsMessagesWrapperStyled } from '../../DeepTagsReportProductsBulkLabelModal.style';
import { DeepTagReportsLabelAutoComplete } from '../DeepTagReportsLabelAutoComplete/DeepTagReportsLabelAutoComplete';

interface DeepTagReportProductsEvenLabelBulkProps {
  totalAvailableProductsToAssign: number;
  selectedValues: string[];
  onIsFormValidChange: (isValid: boolean) => void;
  onSelectedValuesChange: (labelIds: string[]) => void;
  isIncludeLabeledItems: boolean;
  dispatch: Dispatch;
}

export const DeepTagReportProductsEvenLabelBulk = React.memo(
  ({
    totalAvailableProductsToAssign,
    selectedValues,
    isIncludeLabeledItems,
    onIsFormValidChange,
    onSelectedValuesChange,
    dispatch,
  }: DeepTagReportProductsEvenLabelBulkProps): JSX.Element => {
    const [errorValidationMessages, setErrorValidationMessages] = useState<string[]>([]);

    const onErrorsChange = useCallback((errors: string[]) => {
      setErrorValidationMessages(errors);
    }, []);

    useEffect(() => {
      if (!isIncludeLabeledItems && !totalAvailableProductsToAssign) {
        onIsFormValidChange(false);
      }

      if (
        isIncludeLabeledItems &&
        totalAvailableProductsToAssign > 0 &&
        !errorValidationMessages.length
      ) {
        onIsFormValidChange(true);
      }
    }, [isIncludeLabeledItems, totalAvailableProductsToAssign, errorValidationMessages]);

    useEffect(() => {
      onIsFormValidChange(!errorValidationMessages.length);
    }, [errorValidationMessages]);

    return (
      <>
        {errorValidationMessages.length > 0 && (
          <ErrorsMessagesWrapperStyled>
            {errorValidationMessages.map(message => (
              <ErrorLabelStyled key={message}>{message}</ErrorLabelStyled>
            ))}
          </ErrorsMessagesWrapperStyled>
        )}
        <TextWithAutoCompleteStyled>
          <TypographyTextStyled
            type={TypographyType.Paragraph}
            variant={TypographyVariant.MediumRegular}
          >{`Split ${totalAvailableProductsToAssign} ${
            isIncludeLabeledItems ? '' : 'unlabeled'
          } product between`}</TypographyTextStyled>
          <AutoCompleteWrapperStyled>
            <DeepTagReportsLabelAutoComplete
              freeSolo
              multiple
              dispatch={dispatch}
              selectedValues={selectedValues}
              setSelectedValues={onSelectedValuesChange}
              placeholder='Type to select or insert a label'
              onErrors={onErrorsChange}
              isError={!!errorValidationMessages.length}
              disabled={totalAvailableProductsToAssign === 0}
            />
          </AutoCompleteWrapperStyled>
        </TextWithAutoCompleteStyled>
      </>
    );
  }
);
