import React from 'react';
import {
  Tooltip,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
  Switch,
} from 'src/components-dummy';
import { AvailableIcons } from 'src/components-dummy/Icon/AvailableIcons';
import { RecEnginesGeneralSettingsType } from 'src/services';
import './OpenNewTabSettings.scss';

export interface OpenNewTabSettingsProps {
  generalSettings: RecEnginesGeneralSettingsType.RecEnginesGeneralSettings;
  onChange: (args: Partial<RecEnginesGeneralSettingsType.RecEnginesGeneralSettings>) => void;
  disabled?: boolean;
}

export const OpenNewTabSettings = ({
  generalSettings,
  onChange,
  disabled = false,
}: OpenNewTabSettingsProps): JSX.Element | null => {
  return (
    <div className='open-new-tab-settings'>
      <Typography
        type={TypographyType.Body}
        variant={TypographyVariant.MediumRegular}
        className='results-display-title'
      >
        Results Display
      </Typography>
      <Typography
        type={TypographyType.Body}
        variant={TypographyVariant.MediumRegular}
        className='toggles-title'
      >
        Open results in a new tab
      </Typography>
      <div className='open-new-tab-toggles'>
        <div className='mobile-toggle'>
          <Switch
            disabled={disabled}
            checked={generalSettings.offerNavOpenResultsInNewTabMobile}
            onChange={() => {
              onChange({
                offerNavOpenResultsInNewTabMobile:
                  !generalSettings.offerNavOpenResultsInNewTabMobile,
              });
            }}
            className='mobile-new-tab-toggle'
          >
            <Switch.TitleTemplate>
              <Tooltip value='Mobile'>
                <Icon
                  name={
                    generalSettings.offerNavOpenResultsInNewTabMobile
                      ? AvailableIcons.MobileOn
                      : AvailableIcons.MobileOff
                  }
                />
              </Tooltip>
            </Switch.TitleTemplate>
          </Switch>
        </div>
        <div className='desktop-toggle'>
          <Switch
            disabled={disabled}
            checked={generalSettings.offerNavOpenResultsInNewTabDesktop}
            onChange={() => {
              onChange({
                offerNavOpenResultsInNewTabDesktop:
                  !generalSettings.offerNavOpenResultsInNewTabDesktop,
              });
            }}
            className='desktop-new-tab-toggle'
          >
            <Switch.TitleTemplate>
              <Tooltip value='Desktop'>
                <Icon
                  name={
                    generalSettings.offerNavOpenResultsInNewTabDesktop
                      ? AvailableIcons.DesktopOn
                      : AvailableIcons.DesktopOff
                  }
                />
              </Tooltip>
            </Switch.TitleTemplate>
          </Switch>
        </div>
      </div>
    </div>
  );
};
