import { useEffect, useMemo } from 'react';
import { compareDesc } from 'date-fns';
import { experimentListActionMethods } from './Actions';
import { UseExperimentListArguments, UseExperimentListReturnType } from './types';
import { PRODUCTION_FIELD_NAME } from '../ExperimentVariantNameCell/constants';

const TOTAL_ALLOCATION = 100;

export const useExperimentList = ({
  experiments,
  shopId,
  dispatch,
}: UseExperimentListArguments): UseExperimentListReturnType => {
  useEffect(() => {
    if (shopId) {
      dispatch(experimentListActionMethods.getExperiments({ shopId }));
    }
  }, [shopId]);

  const experimentItems = useMemo(() => {
    const convertedExperiments = experiments?.map(experiment => {
      const productionAllocation = experiment.variants.reduce(
        (acc, variant) => acc - variant.trafficAllocation,
        TOTAL_ALLOCATION
      );

      return {
        ...experiment,
        variantsDisplayList: [
          {
            id: PRODUCTION_FIELD_NAME,
            type: PRODUCTION_FIELD_NAME,
            name: 'Production',
            trafficAllocation: productionAllocation,
          },
          ...experiment.variants,
        ],
      };
    });

    const convertedExperimentsSortedByDateDesc = convertedExperiments?.sort((a, b) => {
      return compareDesc(a.createdAt, b.createdAt);
    });

    return convertedExperimentsSortedByDateDesc || [];
  }, [experiments, shopId]);

  return { experimentItems };
};
