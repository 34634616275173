import { useCallback, useMemo, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { useVisualEditorProvider } from '../context';
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll';
import { visualEditorFetchOffersActions } from '../state';
import { BrowsePlpPayload, ResultsScreen } from '../types';

export const usePreviewPLP = (shopId: number): Partial<ResultsScreen> => {
  const { total, loading, error, offers } = useAppSelector(({ visualEditor }) => visualEditor);
  const { dispatchOffersThunk, getPayload } = useVisualEditorProvider();
  const [showLoadingResults, setShowLoadingResults] = useState<boolean>(false);

  const onRefresh = useCallback(() => {
    const payload = getPayload<BrowsePlpPayload>();
    if (!payload) return;
    setShowLoadingResults(true);
    dispatchOffersThunk(visualEditorFetchOffersActions.getBrowsePLPItems, { ...payload, from: 0 })
      .unwrap()
      .finally(() => setShowLoadingResults(false));
  }, [dispatchOffersThunk, getPayload]);

  const onLoadMore = useCallback(() => {
    const payload = getPayload<BrowsePlpPayload>();
    if (!offers.length || !payload) return;
    dispatchOffersThunk(visualEditorFetchOffersActions.getBrowsePLPItems, {
      ...payload,
      from: offers.length,
      shopId,
    });
  }, [getPayload, offers.length, dispatchOffersThunk]);

  const hasNextPage = !error && !!offers.length && offers.length < total;
  const showLoader = hasNextPage;

  const [sentryRef, { rootRef, isVisible }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    disabled: error,
    delayInMs: 300,
  });

  const infiniteScroll = useMemo(
    () => ({ sentryRef, rootRef, showLoader }),
    [sentryRef, rootRef, showLoader]
  );

  const displayedOffers = showLoadingResults ? [] : offers;
  const disableRefresh = loading || isVisible;

  return {
    onRefresh,
    displayedOffers,
    disableRefresh,
    infiniteScroll,
  };
};
