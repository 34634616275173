import React, {
  MouseEventHandler,
  useState,
  useCallback,
  useRef,
  useLayoutEffect,
  MouseEvent,
} from 'react';
import { Chip, ChipProps } from '../Chip';
import { AvailableIcons, Icon } from '../Icon';
import { Popover } from '../Popover';
import { TypographyVariant } from '../Typography';
import {
  AutoCompleteExpandableChipStyled,
  PopoverContentStyled,
  PopoverIconWrapperStyled,
  TypographyStyled,
} from './AutoCompleteExpandableChip.styles';

interface AutoCompleteExpandableChipProps {
  onTagClick?: (tagValue: string, event?: MouseEvent) => void;
}

export function AutoCompleteExpandableChip({
  label,
  onTagClick,
  ...props
}: AutoCompleteExpandableChipProps & ChipProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [contentWidth, setContentWidth] = useState<number>();

  useLayoutEffect(() => {
    const expandedWidthOffsetSize = 10;

    const containerWidth = containerRef.current?.offsetWidth;
    const targetContentWidth = containerWidth
      ? containerWidth + expandedWidthOffsetSize
      : undefined;

    setContentWidth(targetContentWidth);
  }, []);

  const onClick: MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      setAnchorEl(event.currentTarget);
      onTagClick?.(label as string, event);
    },
    [label, onTagClick, setAnchorEl]
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const open = Boolean(anchorEl);

  const onExpandedChipClick: MouseEventHandler<HTMLDivElement> = useCallback(event => {
    event.stopPropagation();
  }, []);

  return (
    <AutoCompleteExpandableChipStyled ref={containerRef}>
      <Chip {...props} label={label} onClick={onClick} />
      <Popover
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <PopoverContentStyled contentWidth={contentWidth} onClick={onExpandedChipClick}>
          <TypographyStyled variant={TypographyVariant.MediumRegular}>{label}</TypographyStyled>
          <PopoverIconWrapperStyled onClick={onClose}>
            <Icon name={AvailableIcons.Close} />
          </PopoverIconWrapperStyled>
        </PopoverContentStyled>
      </Popover>
    </AutoCompleteExpandableChipStyled>
  );
}
