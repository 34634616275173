import { IndicatorColor } from 'src/components-dummy';
import { VariantType } from 'src/services';

interface VariantProperties {
  name?: string;
  subTitle: string;
  color: IndicatorColor;
}

export const PRODUCTION_FIELD_NAME = 'production';

export const variantTypeProperties: Record<VariantType | string, VariantProperties> = {
  [VariantType.Control]: {
    subTitle: 'Will be used as the baseline (Control)',
    color: IndicatorColor.Blue,
  },
  [VariantType.Test]: {
    subTitle: 'Will be used as the test group',
    color: IndicatorColor.Lilac,
  },
  [PRODUCTION_FIELD_NAME]: {
    name: 'Unallocated',
    subTitle: 'The ongoing shop configuration',
    color: IndicatorColor.DarkPurple,
  },
};
