/**
 * Insecure-context ready function for copying text to clipboard
 */
export function copyToClipboard(text: string): Promise<void> {
  // navigator.clipboard is not available in insecure context or internet explorer
  if (navigator.clipboard !== undefined) {
    return navigator.clipboard.writeText(text);
  }

  // Put the text to copy into a <span>
  const span = document.createElement('span');
  span.textContent = text;

  // Preserve consecutive spaces and newlines
  span.style.whiteSpace = 'pre';
  span.style.webkitUserSelect = 'auto';
  span.style.userSelect = 'all';

  // Add the <span> to the page
  document.body.appendChild(span);

  // Make a selection object representing the range of text selected by the user
  const selection = window.getSelection();
  const range = window.document.createRange();
  selection?.removeAllRanges();
  range.selectNode(span);
  selection?.addRange(range);

  // Copy text to the clipboard
  return new Promise((resolve, reject) => {
    let success = false;

    let errorObj;
    try {
      success = window.document.execCommand('copy');
    } catch (error) {
      errorObj = error;
    } finally {
      // Cleanup
      selection?.removeAllRanges();
      window.document.body.removeChild(span);
    }

    if (success) {
      resolve();
    } else {
      reject(errorObj);
    }
  });
}
