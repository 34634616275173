import React, { useMemo } from 'react';
import { AvailableIcons, Tooltip } from 'src/components-dummy';
import { Dispatch } from 'src/components-bl/types';
import { ExperimentSlim, ExperimentStatus } from 'src/services';
import { experimentActionButtonsActionMethods } from './Actions';
import { ButtonStyled } from './ExperimentActionButtons.styles';

interface ExperimentsActionButtonsProps {
  experiment?: ExperimentSlim;
  runningExperimentId?: string;
  dispatch: Dispatch;
  shopId: number;
  setShowUseUuidDialog: (newValue: boolean) => void;
}

export const ExperimentsActionButtons = ({
  experiment,
  runningExperimentId,
  shopId,
  dispatch,
  setShowUseUuidDialog,
}: ExperimentsActionButtonsProps): JSX.Element | null => {
  const isStartButtonEnabled =
    !!experiment &&
    experiment.status === ExperimentStatus.Created &&
    (!runningExperimentId || runningExperimentId === experiment.id);

  const onStopExperiment = () => {
    if (experiment && shopId) {
      dispatch(
        experimentActionButtonsActionMethods.stopExperiment({
          shopId,
          id: experiment.id,
          experiment,
        })
      );
    }
  };

  const runButton = (
    <Tooltip value='An Experiment is already running' disabled={isStartButtonEnabled}>
      <ButtonStyled
        variant='primary'
        disabled={!isStartButtonEnabled}
        onClick={() => setShowUseUuidDialog(true)}
        startIcon={AvailableIcons.Start}
      >
        Start
      </ButtonStyled>
    </Tooltip>
  );

  const stopButton = (
    <ButtonStyled variant='primary' onClick={onStopExperiment} startIcon={AvailableIcons.Stop}>
      End
    </ButtonStyled>
  );

  const experimentActionButtons = useMemo(() => {
    let changeStatusButton: JSX.Element | string = <div />;
    if (experiment?.status === ExperimentStatus.Created) {
      changeStatusButton = runButton;
    } else if (experiment?.status === ExperimentStatus.Running) {
      changeStatusButton = stopButton;
    } else if (experiment?.status === ExperimentStatus.Ended) {
      return null;
    } else {
      return runButton;
    }
    return changeStatusButton;
  }, [runningExperimentId, experiment?.status]);

  return experimentActionButtons;
};
