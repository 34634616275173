import React, { useCallback, useEffect, useState } from 'react';
import {
  AvailableIcons,
  EllipsisWithTooltip,
  Page,
  PaginationWithItemsPerPage,
  SkeletonHorizontalBlocks,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import {
  ICatalogUploadErrorsPaginated,
  CatalogUploadErrorsPaginationWithFilters,
  ICatalogUploadErrorsFilters,
} from 'src/services/src/service/types/catalog-manager/catalog-manager';
import { css } from '@emotion/react';
import { PAGINATION_DEFAULT_CONFIG } from 'src/containers/CatalogManager/CatalogUploadErrorReportListContainer/CatalogUploadErrorReportListContainer.config';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'src/components-bl/CatalogManager/CatalogManager.config';
import {
  TitleContextStyled,
  TitleMainStyled,
} from 'src/components-bl/CatalogManager/CatalogManager.styles';
import {
  BackIconStyled,
  PageStyled,
  PageTitlesWrapper,
} from './CatalogUploadErrorReportListPage.styles';
import { CatalogUploadErrorReportList } from '../CatalogUploadErrorReportList/CatalogUploadErrorReportList';
import { CatalogUploadErrorReportFilters } from '../CatalogUploadErrorReportFilters/CatalogUploadErrorReportFilters';

export interface CatalogUploadErrorReportsPageProps {
  catalogUploadErrorReports?: ICatalogUploadErrorsPaginated;
  onFiltersOrPaginationChange: ({
    skip,
    limit,
    categories,
    errorDescriptions,
    actions,
  }: CatalogUploadErrorsPaginationWithFilters) => void;
  navigateToUploadLogsList: () => void;
  paginationDefaultConfig: {
    skip: number;
    limit: number;
  };
  isAdminUser: boolean;
  catalogName: string;
  feedRunStartDate?: Date;
}

export const CatalogUploadErrorReportsPage = ({
  catalogUploadErrorReports,
  onFiltersOrPaginationChange,
  navigateToUploadLogsList,
  paginationDefaultConfig,
  feedRunStartDate,
  catalogName,
  isAdminUser,
}: CatalogUploadErrorReportsPageProps): JSX.Element => {
  const currentTotalItemsCount = catalogUploadErrorReports?.totalCount || 0;

  const [paginationWithFilters, setPaginationWithFilters] =
    useState<CatalogUploadErrorsPaginationWithFilters>({
      skip: paginationDefaultConfig.skip,
      limit: paginationDefaultConfig.limit,
    });

  const onPaginationChange = useCallback((changes: { skip: number; limit: number }) => {
    const newPaginationState = { skip: changes.skip, limit: changes.limit };

    setPaginationWithFilters(prevState => {
      return {
        ...prevState,
        ...newPaginationState,
      };
    });
  }, []);

  const onFiltersChange = useCallback((selectedFiltersChanges: ICatalogUploadErrorsFilters) => {
    setPaginationWithFilters(prevState => {
      return {
        skip: PAGINATION_DEFAULT_CONFIG.skip,
        limit: prevState.limit,
        ...selectedFiltersChanges,
      };
    });
  }, []);

  useEffect(() => {
    onFiltersOrPaginationChange(paginationWithFilters);
  }, [paginationWithFilters, onFiltersOrPaginationChange]);

  const formattedFeedRunStartDate = feedRunStartDate ? format(feedRunStartDate, DATE_FORMAT) : '';

  const preMainTitleText = `Catalog Manager > Feed Uploads Log - ${catalogName} > `;
  const dateText = `${formattedFeedRunStartDate ? ` - ${formattedFeedRunStartDate}` : ''}`;
  const mainTitleText = `Feed Upload Error Log ${dateText}`;

  const fullTitleText = preMainTitleText + mainTitleText;

  return (
    <PageStyled>
      <Page.Header>
        <PageTitlesWrapper>
          <BackIconStyled name={AvailableIcons.ArrowRounded} onClick={navigateToUploadLogsList} />
          <EllipsisWithTooltip tooltipText={fullTitleText} key={Math.random()}>
            <TitleMainStyled
              type={TypographyType.Heading}
              variant={TypographyVariant.ExtraSmallBold}
              className='syte-page-title'
            >
              <TitleContextStyled>{preMainTitleText}</TitleContextStyled>
              {mainTitleText}
            </TitleMainStyled>
          </EllipsisWithTooltip>
        </PageTitlesWrapper>
      </Page.Header>
      <Page.Content>
        {catalogUploadErrorReports ? (
          <>
            <div>
              <CatalogUploadErrorReportFilters
                onChange={onFiltersChange}
                filterOptions={catalogUploadErrorReports.filterOptions}
                totalProducts={currentTotalItemsCount}
              />
              <CatalogUploadErrorReportList
                catalogUploadErrorReports={catalogUploadErrorReports}
                isAdminUser={isAdminUser}
              />
            </div>
            <div
              css={css`
                display: flex;
                justify-content: flex-end;
                padding-bottom: 24px;
                padding-top: 12px;
              `}
            >
              <PaginationWithItemsPerPage
                skip={paginationWithFilters.skip}
                limit={paginationWithFilters.limit} // limit
                totalItemsCount={currentTotalItemsCount}
                pagesToShow={5} // total pages range numbers in pagination nav
                onPaginationChange={onPaginationChange}
              />
            </div>
          </>
        ) : (
          <SkeletonHorizontalBlocks />
        )}
      </Page.Content>
    </PageStyled>
  );
};
