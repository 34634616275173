import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const TypographyWrapperStyled = styled.div`
  margin-top: 24px;
`;

export const HelpWrapperStyled = styled.div`
  margin: 40px 0 124px 0;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const HelpTypographyStyled = styled(Typography)`
  margin-top: 4px;
`;
