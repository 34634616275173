import { DeepTagStatusEnum, ILexiconAttribute, ILexiconValue } from 'src/services';
import { DeepTag } from 'src/services/src/service/types/deep-tag-reports';
import {
  GENERATED_DESCRIPTION,
  GENERATED_TITLE,
} from './components/DeepTagGenerativeAIForm/DeepTagsGenerativeAIForm.config';
import { DeepTagForm } from './DeepTagsProductForm.types';

export const updateTagsFormDataWithNewValue = ({
  selectedValueItem,
  tagsFormData,
  apiTagsByAttributeMap,
  selectedAttribute,
}: {
  selectedValueItem: Omit<ILexiconValue, 'verticals'>;
  tagsFormData: DeepTagForm[];
  apiTagsByAttributeMap: Record<string, DeepTag>;
  selectedAttribute: string;
}): DeepTag[] => {
  const result = tagsFormData.reduce((accumulator: any, tag: DeepTagForm) => {
    // Do not include removed tag
    if (tag.status === DeepTagStatusEnum.Deleted) {
      return accumulator;
    }

    const lexiconSelectedValue = selectedValueItem.translation;
    let status;

    // Originally tags from server
    const originallyTagAttribute = apiTagsByAttributeMap[tag.attribute.key];

    if (tag.status === DeepTagStatusEnum.New) {
      status = tag.status;
    }

    if (tag.status === DeepTagStatusEnum.Original || tag.status === DeepTagStatusEnum.Edited) {
      const hasChanged =
        originallyTagAttribute.status === DeepTagStatusEnum.Edited ||
        originallyTagAttribute.value.translation !== lexiconSelectedValue;

      status = hasChanged ? DeepTagStatusEnum.Edited : DeepTagStatusEnum.Original;
    }

    accumulator.push(
      tag.attribute.key === selectedAttribute
        ? {
            ...tag,
            value: {
              key: selectedValueItem.key,
              translation: lexiconSelectedValue,
            },
            status,
          }
        : tag
    );

    return accumulator;
  }, []);

  return result;
};

/**
 * Compare form data tags against tags from server - for dirty form indication
 */
export const getTagsDiff = ({
  newFormData,
  apiTagsByAttributeMap,
}: {
  newFormData: DeepTagForm[];
  apiTagsByAttributeMap: Record<string, DeepTag>;
}): DeepTagForm[] => {
  return newFormData.reduce((accumulator: DeepTagForm[], formTag) => {
    const apiTagsByAttribute: DeepTag | undefined = apiTagsByAttributeMap[formTag.attribute.key];

    // New form tag (not exists in originalData)
    if (formTag.hasAddedInForm && !apiTagsByAttribute) {
      accumulator.push(formTag);
      return accumulator;
    }

    const hasTagChanged = apiTagsByAttribute?.value.translation !== formTag.value.translation;
    const hasStatusChanged = apiTagsByAttribute?.status !== formTag.status;

    if (hasTagChanged || hasStatusChanged) {
      accumulator.push(formTag);
    }

    return accumulator;
  }, []);
};

/**
 * Get unused attributes from lexicon attributes -
 * not to have doubled attributes - for add new tag
 */
export const extractUnusedAttributesFromLexicon = ({
  lexiconAttributes,
  tagsFormData,
}: {
  lexiconAttributes: Record<string, ILexiconAttribute>;
  tagsFormData: DeepTagForm[];
}): Record<string, ILexiconAttribute> => {
  const tagsFormDataMap = tagsFormData.reduce((accumulator: Record<string, boolean>, tag) => {
    accumulator[tag.attribute.key] = true;
    return accumulator;
  }, {});

  // Filter out of lexicon attributes map - used attributes
  return Object.keys(lexiconAttributes).reduce((accumulator: any, lexiconAttributeKey: string) => {
    const lexiconAttribute = lexiconAttributes[lexiconAttributeKey];
    const isTagAlreadyInList = tagsFormDataMap?.[lexiconAttribute.key || -1];

    if (!isTagAlreadyInList) {
      accumulator[lexiconAttributeKey] = lexiconAttribute;
    }

    return accumulator;
  }, {});
};

export const isGeneratedAttribute = (attribute: DeepTag['attribute']): boolean => {
  return attribute.key === GENERATED_DESCRIPTION || attribute.key === GENERATED_TITLE;
};
