import styled from '@emotion/styled';

export const MerchandisingRulesListContainer = styled.div`
  padding-right: 8px;
  max-height: 220px;
  min-height: 64px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
