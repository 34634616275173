import { SyteProductType } from 'src/services/src/service/types/syte-products/syte-products-types';
import { dataFieldsService, Ranking, RankingStrategyTypes } from 'src/services';
import { Dispatch } from 'src/components-bl/types';
import {
  UpdateWeightsPayload,
  GetRankingStrategyArgs,
  GetWeightsRequestPayload,
  GetWeightsSuccessPayload,
  GetWeightsErrorPayload,
  UpdateRankingWeightsRequestPayload,
  UpdateRankingWeightsSuccessPayload,
  UpdateRankingWeightsErrorPayload,
  RankingStrategyActionTypes,
  SetSelectedSyteProductAction,
  GetWeightsRequestAction,
  GetWeightsErrorAction,
  GetWeightsSuccessAction,
  UpdateRankingWeightsRequestAction,
  UpdateRankingWeightsSuccessAction,
  UpdateRankingWeightsErrorAction,
  NotifyIsDirtyAction,
  NotifyIsDirtyActionPayload,
  GetDataFieldsRequestAction,
  GetDataFieldsSuccessAction,
  GetDataFieldsErrorAction,
  GetDataFieldsRequestActionPayload,
  GetDataFieldsSuccessActionPayload,
  GetDataFieldsErrorActionPayload,
} from './types';

const rankingService = new Ranking();

/* --= Actions =-- */
const rankingStrategyActions = {
  setSyteProduct: (syteProduct: SyteProductType): SetSelectedSyteProductAction => {
    return {
      type: RankingStrategyActionTypes.SetSelectedSyteProduct,
      payload: syteProduct,
    };
  },
  getWeightsRequest: (payload: GetWeightsRequestPayload): GetWeightsRequestAction => {
    return {
      type: RankingStrategyActionTypes.GetWeightsRequest,
      payload,
    };
  },
  getWeightsSuccess: (payload: GetWeightsSuccessPayload): GetWeightsSuccessAction => {
    return {
      type: RankingStrategyActionTypes.GetWeightsSuccess,
      payload,
    };
  },
  getWeightsError: ({ error }: GetWeightsErrorPayload): GetWeightsErrorAction => {
    return {
      type: RankingStrategyActionTypes.GetWeightsError,
      payload: { error },
    };
  },
  updateRankingWeightsRequest: (
    payload: UpdateRankingWeightsRequestPayload
  ): UpdateRankingWeightsRequestAction => {
    return {
      type: RankingStrategyActionTypes.UpdateRankingWeightsRequest,
      payload,
    };
  },
  updateRankingWeightsSuccess: (
    payload: UpdateRankingWeightsSuccessPayload
  ): UpdateRankingWeightsSuccessAction => {
    return {
      type: RankingStrategyActionTypes.UpdateRankingWeightsSuccess,
      payload,
    };
  },
  updateRankingWeightsError: (
    payload: UpdateRankingWeightsErrorPayload
  ): UpdateRankingWeightsErrorAction => {
    return {
      type: RankingStrategyActionTypes.UpdateRankingWeightsError,
      payload,
    };
  },

  notifyIsDirtyState: (payload: NotifyIsDirtyActionPayload): NotifyIsDirtyAction => {
    return {
      type: RankingStrategyActionTypes.NotifyIsDirty,
      payload,
    };
  },

  getDataFieldsRequest(payload: GetDataFieldsRequestActionPayload): GetDataFieldsRequestAction {
    return {
      type: RankingStrategyActionTypes.GetDataFieldsRequest,
      payload,
    };
  },

  getDataFieldsSuccess(payload: GetDataFieldsSuccessActionPayload): GetDataFieldsSuccessAction {
    return {
      type: RankingStrategyActionTypes.GetDataFieldsSuccess,
      payload,
    };
  },

  getDataFieldsError(payload: GetDataFieldsErrorActionPayload): GetDataFieldsErrorAction {
    return {
      type: RankingStrategyActionTypes.GetDataFieldsError,
      payload,
    };
  },
};

/* --= Methods =-- */
const privateMethods = {
  ...rankingStrategyActions,
};

export const rankingStrategyActionsMethods = {
  setSyteProduct: (syteProduct: SyteProductType): SetSelectedSyteProductAction => {
    return rankingStrategyActions.setSyteProduct(syteProduct);
  },

  notifyIsDirty:
    ({ isDirty }: NotifyIsDirtyActionPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(privateMethods.notifyIsDirtyState({ isDirty }));
    },

  fetchWeights:
    ({ shopId, syteProduct, variantId, entityId }: GetRankingStrategyArgs) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(privateMethods.getWeightsRequest({ shopId, syteProduct }));
        const { weights, type } = await rankingService.get({
          shopId,
          syteProduct,
          variantId,
          entityId,
        });
        dispatch(privateMethods.getWeightsSuccess({ weights, syteProduct, shopId, type }));
      } catch (error) {
        dispatch(privateMethods.getWeightsError({ error }));
      }
    },

  updateWeights:
    ({ shopId, syteProduct, weights, variantId, entityId, type }: UpdateWeightsPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(privateMethods.updateRankingWeightsRequest({ shopId, syteProduct, weights }));

        await rankingService.update({
          shopId,
          syteProduct,
          weights: weights as RankingStrategyTypes.RankingStrategyWeight[],
          variantId,
          entityId,
          type,
        });

        dispatch(
          privateMethods.updateRankingWeightsSuccess({
            shopId,
            syteProduct,
            weights,
            type,
          })
        );
      } catch (error) {
        dispatch(privateMethods.updateRankingWeightsError({ error }));
      }
    },

  getDataFields(payload: GetDataFieldsRequestActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(rankingStrategyActions.getDataFieldsRequest(payload));
        const dataFields = await dataFieldsService.getDataFields(payload);
        dispatch(
          rankingStrategyActions.getDataFieldsSuccess({ shopId: payload.shopId, dataFields })
        );
      } catch (error) {
        dispatch(rankingStrategyActions.getDataFieldsError({ error }));
      }
    };
  },
};
