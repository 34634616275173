import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { merchandisingRulesService } from 'src/services';
import {
  GetRulesArguments,
  GetRulesOverviewArguments,
} from 'src/services/src/service/merchandising-rules/types';

export const merchandisingRulesListPageActions = {
  getRules: createAsyncThunk(
    'MerchandisingRulesListPage/GetMerchandisingRules',
    async (parameters: GetRulesArguments, { rejectWithValue }) => {
      try {
        const response = await merchandisingRulesService.getRules(parameters);
        return response;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  getRulesOverview: createAsyncThunk(
    'MerchandisingRulesListPage/GetMerchandisingRulesOverview',
    async (parameters: GetRulesOverviewArguments, { rejectWithValue }) => {
      try {
        const response = await merchandisingRulesService.getRulesOverview(parameters);
        return response;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  resetRules: createAction('MerchandisingRulesListPage/ResetRules'),
};
