import { useState, useCallback, useEffect } from 'react';

export enum SelectAllModes {
  AllSelected = 'all-selected',
  SomeSelected = 'some-selected',
}

export interface SelectAllState {
  mode: SelectAllModes;
  checked: boolean;
}

const defaultSelectAllState: SelectAllState = { checked: false, mode: SelectAllModes.SomeSelected };

export const useSelectAll = (): {
  onSelectRow: (params: { rowId: string; isSelected: boolean }) => void;
  includedIds: Set<string>;
  excludedIds: Set<string>;
  onSelectAll: (selected: boolean) => void;
  onUnselectAll: () => void;
  selectAllState: SelectAllState;
} => {
  const [includedIds, setIncludedIds] = useState<Set<string>>(new Set());
  const [excludedIds, setExcludedIds] = useState<Set<string>>(new Set());
  const [selectAllState, setSelectAllState] = useState<SelectAllState>(defaultSelectAllState);

  const onSelectRow = useCallback(
    ({ rowId, isSelected }: { rowId: string; isSelected: boolean }) => {
      const isSelectAllModeActive =
        selectAllState.mode === SelectAllModes.AllSelected && selectAllState.checked;

      if (isSelectAllModeActive) {
        setExcludedIds(prevExcludedIds => {
          const updatedExcludedIds = new Set(prevExcludedIds);

          if (isSelected) {
            updatedExcludedIds.delete(rowId);
          } else {
            updatedExcludedIds.add(rowId);
          }

          return updatedExcludedIds;
        });
      } else {
        setIncludedIds(prevIncludedIds => {
          const updatedIncludedIds = new Set(prevIncludedIds);

          if (isSelected) {
            updatedIncludedIds.add(rowId);
          } else {
            updatedIncludedIds.delete(rowId);
          }

          return updatedIncludedIds;
        });
      }
    },
    [selectAllState]
  );

  useEffect(() => {
    const isAtLeastOneRowChecked = includedIds.size > 0;
    setSelectAllState(prevSelectAllState => ({
      ...prevSelectAllState,
      checked: isAtLeastOneRowChecked,
    }));
  }, [includedIds]);

  const onUnselectAll = useCallback(() => {
    setIncludedIds(new Set());
    setExcludedIds(new Set());
    setSelectAllState(defaultSelectAllState);
  }, []);

  const onSelectAll = useCallback(
    (isSelected: boolean) => {
      if (!isSelected) {
        onUnselectAll();
        return;
      }

      setSelectAllState({ checked: true, mode: SelectAllModes.AllSelected });
    },
    [onUnselectAll]
  );

  return {
    onSelectRow,
    includedIds,
    excludedIds,
    onUnselectAll,
    onSelectAll,
    selectAllState,
  };
};
