import styled from '@emotion/styled';
import MuiMenu from '@mui/material/Menu';
import { Typography } from '../Typography';

export const LabelStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-30']};
  margin-left: 20px;
`;

export const MenuStyled = styled(MuiMenu)`
  .MuiPaper-root {
    width: 226px;
    max-height: 300px;
  }
`;
