import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { reduceErrorsByPrefix } from '../../../../../../../utils';
import {
  DataFieldLookupTable,
  DataFieldLookupTableValue,
} from '../../../../useDataFieldsLookupTable';
import { MerchandisingRuleTypes } from '../../../../../../../services';
import { Dispatch } from '../../../../../../types';
import { RuleDraft, RuleDraftCondition } from '../../../MerchandisingRuleForm.config';
import { RedirectRulesInputs } from './RedirectRuleInputs';
import {
  ConditionRowDataStyled,
  ConditionRowErrors,
  ConditionRowStyled,
  ConditionText,
} from '../RuleFormConditionsSection.styles';
import {
  ActionIcon,
  AvailableIcons,
  TypographyType,
  TypographyVariant,
} from '../../../../../../../components-dummy';
import { getConditionTextPrefix } from '../RuleFormConditionsSection.helpers';
import { DataFieldsCondition } from '../../../../DataFieldsCondition/DataFieldsCondition';
import { DataFieldConditionDataType } from '../../../../DataFieldsCondition/DataFieldsCondition.types';
import {
  PinToPositionApiHandle,
  PinToPositionRulesInputs,
} from '../PinToPosition/PinToPositionRulesInputs';
import { mapErrorMessages } from '../../../../../mapErrorMessages';

interface Props {
  conditions: RuleDraftCondition[];
  errors: any; // TODO
  shopId: number;
  dataFieldsLookupTable: DataFieldLookupTable | undefined;
  getDataFieldByName: (name: string | undefined) => DataFieldLookupTableValue | undefined;
  conditionTypes: MerchandisingRuleTypes.MerchandisingRuleSubType[];
  dispatch: Dispatch;
  isReadOnly?: boolean;
  onConditionChange: (changedCondition: DataFieldConditionDataType) => void;
  onDeleteCondition: (conditionToDelete: RuleDraftCondition) => void;
  isRedirectRuleEnabled: boolean;
  isPinToPositionRuleEnabled: boolean;
  onFieldChange: (
    data: Partial<Pick<RuleDraft, 'action' | 'weight' | 'subRules' | 'redirectRule'>>
  ) => void;
  redirectRule?: MerchandisingRuleTypes.IMerchandisingRuleRedirectRule | null;
  isAiTagsEnabled: boolean;
  p2pApiRef: React.Ref<PinToPositionApiHandle>;
}

export const Conditions = ({
  conditions,
  errors,
  shopId,
  dataFieldsLookupTable,
  getDataFieldByName,
  conditionTypes,
  dispatch,
  isReadOnly,
  onConditionChange,
  onDeleteCondition,
  isRedirectRuleEnabled,
  isPinToPositionRuleEnabled,
  onFieldChange,
  redirectRule,
  isAiTagsEnabled,
  p2pApiRef,
}: Props): JSX.Element => {
  if (isRedirectRuleEnabled) {
    return (
      <RedirectRulesInputs
        errors={errors}
        onFieldChange={onFieldChange}
        redirectRule={redirectRule}
        isReadOnly={isReadOnly}
      />
    );
  }
  if (isPinToPositionRuleEnabled) {
    return (
      <DndProvider backend={HTML5Backend}>
        <PinToPositionRulesInputs
          conditions={conditions}
          onConditionChange={onConditionChange}
          onDeleteCondition={onDeleteCondition}
          getDataFieldByName={getDataFieldByName}
          onFieldChange={onFieldChange}
          dispatch={dispatch}
          shopId={shopId}
          errors={errors}
          ref={p2pApiRef}
        />
      </DndProvider>
    );
  }
  return (
    <>
      {conditions.map((condition, index) => {
        const localErrors = reduceErrorsByPrefix({
          errors,
          prefix: `subRules.${index}`,
        });

        const rowErrorLabels = mapErrorMessages(localErrors);

        return (
          <ConditionRowStyled key={condition.tempId}>
            <ConditionRowDataStyled>
              <ConditionText type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                {getConditionTextPrefix(index)}
              </ConditionText>

              <DataFieldsCondition
                shopId={shopId}
                data={condition}
                dataFieldsLookupTable={dataFieldsLookupTable}
                getDataFieldByName={getDataFieldByName}
                conditionTypes={conditionTypes}
                onChange={onConditionChange}
                errors={localErrors}
                dispatch={dispatch}
                disabled={isReadOnly}
                isAiTagsEnabled={isAiTagsEnabled}
              />
              {!isReadOnly && (
                <ActionIcon
                  iconName={AvailableIcons.TrashCan}
                  onClick={() => onDeleteCondition(condition)}
                />
              )}
            </ConditionRowDataStyled>
            {rowErrorLabels.length > 0 ? (
              <ConditionRowErrors>{rowErrorLabels}</ConditionRowErrors>
            ) : null}
          </ConditionRowStyled>
        );
      })}
    </>
  );
};
