import { useEffect, useReducer } from 'react';
import { useStoriesProvider } from 'src/containers/Stories/StoriesProvider';
import { createToastNotification, notifyIsDirty } from '../Actions/storiesActions';
import { storiesSettingsSlice } from './storiesPage.reducer';
import { IEditableStoriesSettings, StoriesStateType } from '../storiesPage.types';
import { useAppSelector } from '../../../hooks';
import { useAppNavigation } from '../../../hooks/useAppNavigation';
import { ShopRoutes } from '../../../types/enums/shop-routes';

const { reducer, getInitialState, actions } = storiesSettingsSlice;
export const useStoriesPageState = () => {
  const { service, appDispatch } = useStoriesProvider();
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const { isDirty } = useAppSelector(({ global }) => global);
  const navigateTo = useAppNavigation();

  const fetchStoriesSettings = async () => {
    dispatch(actions.setIsFetching());
    const response = await service.getStoriesSettings();
    dispatch(actions.resolveStoriesSettings(response));
  };

  const saveSettings = async () => {
    if (state.type !== StoriesStateType.dirty) return;
    dispatch(actions.setIsSaving());
    const generalSettings = {
      numberOfResultsToDisplay: state.editedSettings.numberOfResultsToDisplay,
      storiesStrategy: state.editedSettings.storiesStrategy.name,
    };
    const response = await service.updateStoriesSettings({ generalSettings });
    dispatch(actions.resolveSavedSettings(response));
  };

  const updateSettings = (newSettings: IEditableStoriesSettings) => {
    if (state.type !== StoriesStateType.noChanges && state.type !== StoriesStateType.dirty) return;
    dispatch(actions.updateSettings(newSettings));
  };

  const saveSettingsAndLeave = () => {
    saveSettings().then(() => dispatch(actions.leavePage()));
  };

  useEffect(() => {
    fetchStoriesSettings();
  }, [service]);

  useEffect(() => {
    if (state.message) {
      appDispatch(
        createToastNotification({
          type: state.message.type,
          messageKey: state.message.messageKey,
          timestamp: new Date().getTime(),
        })
      );
    }
  }, [state.message]);

  useEffect(() => {
    if (state.type === StoriesStateType.dirty || state.type === StoriesStateType.noChanges) {
      appDispatch(notifyIsDirty(state.type === StoriesStateType.dirty));
    }
  }, [state.type]);

  useEffect(() => {
    if (!isDirty && state.type === StoriesStateType.leaving) {
      navigateTo(ShopRoutes.SocialDiscovery);
    }
  }, [isDirty, state.type]);

  return {
    state,
    saveSettings,
    updateSettings,
    saveSettingsAndLeave,
  };
};
