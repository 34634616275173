import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AvailableIcons,
  BlurredImage,
  ImageSize,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { CustomInspirationsGalleryImage, ImageTag } from 'src/services';
import { Dispatch } from 'src/components-bl/types';
import {
  AcceptSuggestionsButtonStyled,
  AIButtonStyled,
  ImageFooterStyled,
  EditModeTypographyStyled,
  TaggableImageBackgroundStyled,
  TaggableImageWrapperStyled,
} from './TaggableImage.styles';
import { ProductTags } from '../../../components';
import { useFetchProductsInBulk } from '../../../hooks/useFetchProductsInBulk';
import { GalleryMapper } from '../../../EditCustomInspirationsGalleryPage/mapper';

interface TaggableImageProps {
  image: CustomInspirationsGalleryImage;
  onImageErrorStatusChange: (status: { hasError: boolean }) => void;
  onChange: (partialImage: Partial<Omit<CustomInspirationsGalleryImage, 'id'>>) => void;
  dispatch: Dispatch;
  shopId: number;
  removeTagById: (id: string) => void;
  showTags: boolean;
  startImageTagsDetection: () => void;
  onApplyAiSuggestedTags: () => void;
}

export function TaggableImage({
  image,
  dispatch,
  shopId,
  onImageErrorStatusChange,
  onChange,
  removeTagById,
  showTags,
  startImageTagsDetection,
  onApplyAiSuggestedTags,
}: TaggableImageProps): JSX.Element {
  const imageRef = useRef(null);
  const [currentImageSize, setCurrentImageSize] = useState<ImageSize | null>(null);

  const onAddNewTag = useCallback(
    (newTag: ImageTag) => {
      const newTags = [...image.tags, newTag];

      onChange({ tags: newTags });
    },
    [onChange, image]
  );

  const hasAiTagsToApply = useMemo(() => image.aiSuggestedTags.length > 0, [image]);

  const { productInformationLookup, fetchProductsInBulk, resetOptions, loading } =
    useFetchProductsInBulk({
      dispatch,
      shopId,
      initialLoading: true,
    });

  useEffect(() => {
    resetOptions();

    const skus = GalleryMapper.getUniqueSkusFromImages([image]);

    fetchProductsInBulk(skus);
  }, [image]);

  return (
    <TaggableImageBackgroundStyled>
      <EditModeTypographyStyled
        type={TypographyType.Body}
        variant={TypographyVariant.MediumRegular}
      >
        Click on the image to start tagging manually
      </EditModeTypographyStyled>

      <TaggableImageWrapperStyled isClickable>
        <ProductTags
          image={image}
          imageRef={imageRef}
          imageSize={currentImageSize}
          dispatch={dispatch}
          shopId={shopId}
          onAddNewTag={onAddNewTag}
          removeTagById={removeTagById}
          canAddNewTags
          showTags={showTags}
          canDeleteTags
          productInformationLookup={productInformationLookup}
          loading={loading}
        />
        <BlurredImage
          imageSrc={image.imageUrl}
          onImageErrorStatusChange={onImageErrorStatusChange}
          onImageSizeChange={setCurrentImageSize}
          ref={imageRef}
        />
      </TaggableImageWrapperStyled>
      <ImageFooterStyled>
        {hasAiTagsToApply ? (
          <AcceptSuggestionsButtonStyled
            variant='secondary'
            size='tiny'
            onClick={onApplyAiSuggestedTags}
            startIcon={AvailableIcons.CheckRounded}
          >
            Accept Suggestions
          </AcceptSuggestionsButtonStyled>
        ) : (
          <AIButtonStyled
            variant='primary'
            size='tiny'
            onClick={startImageTagsDetection}
            startIcon={AvailableIcons.Stars}
            disabled={image.isDetectingTags}
          >
            Generate Tags
          </AIButtonStyled>
        )}
      </ImageFooterStyled>
    </TaggableImageBackgroundStyled>
  );
}
