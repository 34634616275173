import React, { MouseEventHandler, useCallback } from 'react';
import { SalesforceAccount } from 'src/services/src/service/salesforce/types';
import { PlaceholderTypography, AccountPreviewStyled } from './styles';
import { LinkButton } from 'src/components-dummy/LinkButton';
import { Tooltip } from 'src/components-dummy';
import { NO_SALESFORCE_ID } from '../../constants';

interface AccountPreviewProps {
  account?: SalesforceAccount;
}

function getAccountAsText(account: SalesforceAccount): string {
  let result = '';

  if (account.accountName) {
    result = `${account.accountName} (`;
  }

  if (account.salesforceAccountId) {
    result = `${result}${account.salesforceAccountId}`;
  }

  if (account.accountName) {
    result = `${result})`;
  }

  if (account.accountStatus) {
    result = `${result}, ${account.accountStatus}`;
  }

  if (result.length === 0) {
    return NO_SALESFORCE_ID;
  }

  return result;
}

export const AccountPreview = ({ account }: AccountPreviewProps): JSX.Element => {
  if (!account) {
    return <PlaceholderTypography>Select account ID</PlaceholderTypography>;
  }

  const onNavigateToAccountClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
    ev => {
      ev.stopPropagation();
      ev.preventDefault();

      if (!account) return;

      const url = `https://syte.lightning.force.com/lightning/r/Account/${account.salesforceAccountId}/view`;
      window.open(url, '_blank').focus();
    },
    [account]
  );

  return (
    <AccountPreviewStyled>
      <Tooltip hoverable value={getAccountAsText(account)}>
        {account.accountName && `${account.accountName} (`}

        <LinkButton onClick={onNavigateToAccountClick}>{account.salesforceAccountId}</LinkButton>

        {account.accountName && `), `}

        {account.accountStatus && account.accountStatus}
      </Tooltip>
    </AccountPreviewStyled>
  );
};
