import { Dispatch } from '../../types';
import { IStoriesService } from '../../../services/src/service/stories/stories-service';
import { storiesSettingsActions } from './storiesSettingsActions';

export const storiesSettingsActionMethods = {
  getStoriesSettings(service: IStoriesService) {
    return async (dispatch: Dispatch): Promise<void> => {
      const response = await service.getStoriesSettings();
      if (response.error !== null) {
        dispatch(storiesSettingsActions.getStoriesSettingsError(response.error));
        return;
      }
      const { numberOfResultsToDisplay, storiesStrategy } = response.data.settings;
      dispatch(
        storiesSettingsActions.resolveStoriesSettings({
          numberOfResultsToDisplay,
          storiesStrategy,
        })
      );
    };
  },
};
