import { GetRecEnginesGeneralSettingsArgs } from 'src/components-bl/RecEnginesSettings/components/RecEnginesGeneralSettingsForm/Actions/types';
import * as mapper from './mapper';
import * as builder from './builder';
import { UpdateRecEnginesGeneralSettingsArguments } from './types';
import { RecEnginesGeneralSettingsType } from '../types';
import { ApiServiceBase } from '../api-service-base';

export class RecEngines extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl({ shopId, variantId }: GetRecEnginesGeneralSettingsArgs): string {
    return `${this.serviceBaseUri}/${shopId}/recommendation-engines/general-settings${
      variantId ? `?variantId=${variantId}` : ''
    }`;
  }

  async get({
    shopId,
    variantId,
  }: GetRecEnginesGeneralSettingsArgs): Promise<RecEnginesGeneralSettingsType.RecEnginesGeneralSettings> {
    const url = this.getUrl({ shopId, variantId });
    const response = await this.httpService.get({ url });
    return mapper.recEnginesGeneralSettingsResponse(response);
  }

  async update({
    shopId,
    variantId,
    ...payload
  }: UpdateRecEnginesGeneralSettingsArguments): Promise<RecEnginesGeneralSettingsType.RecEnginesGeneralSettings> {
    const url = this.getUrl({ shopId, variantId });

    const data = builder.buildRecEnginesGeneralSettingsPayload(payload, shopId);
    const response = await this.httpService.patch({ url, data });
    return mapper.recEnginesGeneralSettingsResponse(response);
  }
}
