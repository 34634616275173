import { useMemo, useState } from 'react';
import { Collection, CollectionsSortingOptionValue } from 'src/components-bl';
import { sortArray } from '../../utils';
import { useFilterItems } from '../../hooks';

function sortCollections(
  initialArray: Collection[],
  sortingDropdownValue: CollectionsSortingOptionValue
): Collection[] {
  return sortArray<Collection>({
    items: initialArray,
    sortBy: collection =>
      sortingDropdownValue.property === 'name'
        ? collection.name.toLowerCase()
        : collection.updatedAt,
    option: sortingDropdownValue.option,
  });
}

interface UseSortedAndFilteredCollectionsArguments {
  sortingValue: CollectionsSortingOptionValue;
  collections: Collection[];
}

interface UseSortedAndFilteredCollectionsReturnType {
  filteredItems: Collection[];
  sortedCollections: Collection[];
  searchInputValue: string;
  onSearchInputChange: (value: string) => void;
}

export const useSortedAndFilteredCollections = ({
  sortingValue,
  collections,
}: UseSortedAndFilteredCollectionsArguments): UseSortedAndFilteredCollectionsReturnType => {
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const onSearchInputChange = (value: string): void => setSearchInputValue(value);

  const sortedCollections = useMemo(() => {
    return sortCollections(collections, sortingValue);
  }, [sortingValue, collections]);

  const { filteredItems } = useFilterItems({
    items: sortedCollections,
    searchableFields: ['name'],
    idField: 'name',
    filterText: searchInputValue,
  });

  return { filteredItems, sortedCollections, searchInputValue, onSearchInputChange };
};
