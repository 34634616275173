import React, { useEffect } from 'react';
import { Dispatch } from 'src/components-bl';
import { useGetQueryParam } from 'src/hooks/use-get-query-param';
import { verifyOneTimeLinkActions } from './verify-one-time-link.actions';

interface VerifyOneTimeLinkContainerProps {
  dispatch: Dispatch;
  goToLogin: () => void;
}

export function VerifyOneTimeLinkContainer({
  dispatch,
  goToLogin,
}: VerifyOneTimeLinkContainerProps): JSX.Element {
  const token = useGetQueryParam('token');

  useEffect(() => {
    (dispatch(verifyOneTimeLinkActions.verifyToken(token)) as any).unwrap().catch(() => {
      goToLogin();
    });
  }, [token, dispatch]);

  return <></>;
}
