import { PersonalizationSettingsTypes } from 'src/services';
import { EntityDiffSchema } from '../../types';
import { diffValueDisplayHelpers } from '../value-helpers';

type PersonalizationDiffSchema = PersonalizationSettingsTypes.PersonalizationSettings;

export const personalizationDiffSchema: EntityDiffSchema<
  PersonalizationDiffSchema,
  PersonalizationSettingsTypes.PersonalizationSettings
> = {
  numberOfResultsToDisplay: {
    displayName: 'Number Of Results To Display',
  },
  personalizationFallbackMethod: {
    displayName: 'Personalization Fallback Method',
  },
  useSessionSkusFromPageViewEvent: {
    displayName: 'Use Session Skus From Page View Event',
  },
  addToCart: {
    displayName: 'Add To Cart',
    getValue({ entity }: { entity: PersonalizationSettingsTypes.PersonalizationSettings }): string {
      return entity?.addToCart?.active ? 'true' : diffValueDisplayHelpers.DEFAULT_EMPTY_VALUE;
    },
  },
  personalizationFallbackField: {
    displayName: 'Personalization Fallback Field',
  },
  sortingOrder: {
    displayName: 'Sorting Order',
  },
};
