import styled from '@emotion/styled';
import { DialogModal, DialogModalContent, DialogModalFooter } from 'src/components-dummy';

export const ExportLexiconModalStyled = styled(DialogModal)`
  .MuiPaper-root {
    width: 500px;
    min-width: 500px;
  }
`;

export const DialogModalContentStyled = styled(DialogModalContent)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  min-height: 80px;
`;

export const DialogModalFooterStyled = styled(DialogModalFooter)`
  gap: 3px;
`;
