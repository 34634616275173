import { createAction } from '@reduxjs/toolkit';

/**
 * Actions
 */
export const connectExistingCatalogModalContainerActions = {
  navigateTo: createAction<{ navigateTo: string }>(
    'ConnectExistingCatalogModalContainer/navigateTo'
  ),
};
