import React from 'react';
import { AppRoute, AppSwitch, RoutedComponentProps } from 'src/app-routes';
import { GalleriesTablePage } from 'src/components-bl/Galleries';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { CreateGalleryContainer } from '../CreateGalleryContainer';

interface GalleriesTableContainerProps extends RoutedComponentProps {
  shopId: number;
}

export const GalleriesTableContainer = ({
  shopId,
  permittedRouteMap,
}: GalleriesTableContainerProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const galleries = useAppSelector(state => state.galleries.galleries);

  return (
    <>
      <AppSwitch>
        <AppRoute
          route={permittedRouteMap.createGallery}
          Component={CreateGalleryContainer}
          componentProps={{ permittedRouteMap, shopId }}
          exact
        />
      </AppSwitch>

      <GalleriesTablePage
        dispatch={dispatch}
        shopId={shopId}
        permittedRouteMap={permittedRouteMap}
        galleries={galleries}
      />
    </>
  );
};
