import { RecEnginesGeneralSettingsType } from 'src/services';
import { AddToCartVariants } from '../../../../../app-state-types/reducer-state-types/add-to-cart';
/* --= Action Types =-- */
export enum RecEnginesActionTypes {
  GetRecEnginesGeneralSettingsRequest = 'RecEngines/GetRecEnginesGeneralSettingsRequest',
  GetRecEnginesGeneralSettingsSuccess = 'RecEngines/GetRecEnginesGeneralSettingsSuccessAction',
  GetRecEnginesGeneralSettingsError = 'RecEngines/GetRecEnginesGeneralSettingsError',
  UpdateRecEnginesGeneralSettingsRequest = 'RecEngines/UpdateRecEnginesGeneralSettingsRequest',
  UpdateRecEnginesGeneralSettingsSuccess = 'RecEngines/UpdateRecEnginesGeneralSettingsSuccess',
  UpdateRecEnginesGeneralSettingsError = 'RecEngines/UpdateRecEnginesGeneralSettingsError',
  NotifyRecEnginesGeneralSettingsIsDirty = 'RecEngines/NotifyIsDirty',
  PartialRecEnginesGeneralSettingsUpdate = 'RecEngines/PartialRecEnginesGeneralSettingsUpdate',
  NavigateTo = 'RecEngines/NavigateTo',
}
export interface UpdateRecEnginesGeneralSettingsPayload {
  shopId: number;
  variantId?: string;
  offerNavOpenResultsInNewTabDesktop: boolean;
  offerNavOpenResultsInNewTabMobile: boolean;
  overrideImageUrl: {
    active: boolean;
    attribute: string;
    selector: string;
  };
  removeDuplicates: {
    enable: boolean;
    prioritizedCarousel: string[];
  };
  addToCart: AddToCartVariants;
}

export interface GetRecEnginesGeneralSettingsArgs {
  shopId: number;
  variantId?: string;
}

export interface GetRecEnginesGeneralSettingsRequestPayload {
  shopId: number;
}

export interface GetRecEnginesGeneralSettingsSuccessPayload {
  shopId: number;
  offerNavOpenResultsInNewTabDesktop: boolean;
  offerNavOpenResultsInNewTabMobile: boolean;
  overrideImageUrl: {
    active: boolean;
    attribute: string;
    selector: string;
  };
  removeDuplicates: {
    enable: boolean;
    prioritizedCarousel: string[];
  };
  addToCart: AddToCartVariants;
}

export interface GetRecEnginesGeneralSettingsErrorPayload {
  error: unknown;
}
export interface UpdateRecEnginesGeneralSettingsRequestPayload {
  shopId: number;
  offerNavOpenResultsInNewTabDesktop: boolean;
  offerNavOpenResultsInNewTabMobile: boolean;
  overrideImageUrl: {
    active: boolean;
    attribute: string;
    selector: string;
  };
  removeDuplicates: {
    enable: boolean;
    prioritizedCarousel: string[];
  };
  addToCart: AddToCartVariants;
}

export interface UpdateRecEnginesGeneralSettingsSuccessPayload {
  shopId: number;
  offerNavOpenResultsInNewTabDesktop: boolean;
  offerNavOpenResultsInNewTabMobile: boolean;
  overrideImageUrl: {
    active: boolean;
    attribute: string;
    selector: string;
  };
  removeDuplicates: {
    enable: boolean;
    prioritizedCarousel: string[];
  };
  addToCart: AddToCartVariants;
}

export interface UpdateRecEnginesGeneralSettingsErrorPayload {
  error: unknown;
}

export interface NotifyRecEnginesGeneralSettingsIsDirtyActionPayload {
  isDirty: boolean;
}

export type PartialRecEnginesGeneralSettingsUpdateActionPayload =
  Partial<RecEnginesGeneralSettingsType.RecEnginesGeneralSettings>;

/* --= Action Interfaces =-- */

export interface GetRecEnginesGeneralSettingsRequestAction {
  type: typeof RecEnginesActionTypes.GetRecEnginesGeneralSettingsRequest;
  payload: GetRecEnginesGeneralSettingsRequestPayload;
}

export interface GetRecEnginesGeneralSettingsSuccessAction {
  type: typeof RecEnginesActionTypes.GetRecEnginesGeneralSettingsSuccess;
  payload: GetRecEnginesGeneralSettingsSuccessPayload;
}

export interface GetRecEnginesGeneralSettingsErrorAction {
  type: typeof RecEnginesActionTypes.GetRecEnginesGeneralSettingsError;
  payload: GetRecEnginesGeneralSettingsErrorPayload;
}

export interface UpdateRecEnginesGeneralSettingsRequestAction {
  type: typeof RecEnginesActionTypes.UpdateRecEnginesGeneralSettingsRequest;
  payload: UpdateRecEnginesGeneralSettingsRequestPayload;
}

export interface UpdateRecEnginesGeneralSettingsSuccessAction {
  type: typeof RecEnginesActionTypes.UpdateRecEnginesGeneralSettingsSuccess;
  payload: UpdateRecEnginesGeneralSettingsSuccessPayload;
}

export interface UpdateRecEnginesGeneralSettingsErrorAction {
  type: typeof RecEnginesActionTypes.UpdateRecEnginesGeneralSettingsError;
  payload: UpdateRecEnginesGeneralSettingsErrorPayload;
}

export interface NotifyRecEnginesGeneralSettingsIsDirtyAction {
  type: typeof RecEnginesActionTypes.NotifyRecEnginesGeneralSettingsIsDirty;
  payload: NotifyRecEnginesGeneralSettingsIsDirtyActionPayload;
}

export interface PartialRecEnginesGeneralSettingsUpdateAction {
  type: RecEnginesActionTypes.PartialRecEnginesGeneralSettingsUpdate;
  payload: PartialRecEnginesGeneralSettingsUpdateActionPayload;
}

export type RecEnginesGeneralSettingsAction =
  | GetRecEnginesGeneralSettingsRequestAction
  | GetRecEnginesGeneralSettingsSuccessAction
  | GetRecEnginesGeneralSettingsErrorAction
  | UpdateRecEnginesGeneralSettingsRequestAction
  | UpdateRecEnginesGeneralSettingsSuccessAction
  | UpdateRecEnginesGeneralSettingsErrorAction
  | NotifyRecEnginesGeneralSettingsIsDirtyAction
  | PartialRecEnginesGeneralSettingsUpdateAction;
