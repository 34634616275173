import React from 'react';
import './PostOrder.scss';
import MuiButton from '@mui/material/Button';
import {
  Icon,
  AvailableIcons,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';

import styled from '@emotion/styled';

export interface PostOrderProps {
  itemsCount: number;
  currentIndex: number;
  onChange: (index: number) => void;
}

export const PostOrderButton = styled(MuiButton)`
  background-color: ${({ theme }) => theme.palette.custom.white};
  color: ${({ theme }) => theme.palette.common.black};
  width: 152px;
  height: 32px;
  padding: 8px 12px;
  font-family: Inter !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: unset;
  box-shadow: none !important;
  border-color: ${({ theme }) => theme.palette.custom.white} !important;
  &:hover {
    background-color: ${({ theme }) => theme.palette.custom.white} !important;
    border-color: ${({ theme }) => theme.palette.custom['primary-main']} !important;
  }
  &:disabled {
    background-color: ${({ theme }) => theme.palette.custom.white} !important;
    border-color: ${({ theme }) => theme.palette.custom.white} !important;
    color: ${({ theme }) => theme.palette.custom['gray-50']};
    .syte-basic-icon {
      path {
        stroke: currentColor;
      }
    }
  }
`;

export const PostOrder = ({ itemsCount, currentIndex, onChange }: PostOrderProps): JSX.Element => {
  const isLeftButtonEnabled = currentIndex > 0;
  const isRightButtonEnabled = currentIndex < itemsCount - 1;
  return (
    <div data-testid='post-order'>
      <Typography
        className='color-black'
        type={TypographyType.Body}
        variant={TypographyVariant.MediumBold}
      >
        Change story order
      </Typography>
      <div className='post-order'>
        <PostOrderButton
          disabled={!isLeftButtonEnabled}
          variant='outlined'
          onClick={() => onChange(currentIndex - 1)}
          startIcon={<Icon name={AvailableIcons.ArrowLeft} />}
        >
          Move to left
        </PostOrderButton>

        <PostOrderButton
          disabled={!isRightButtonEnabled}
          variant='outlined'
          onClick={() => onChange(currentIndex + 1)}
          endIcon={<Icon name={AvailableIcons.ArrowRight} />}
        >
          Move to right
        </PostOrderButton>
      </div>
    </div>
  );
};
