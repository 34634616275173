import styled from '@emotion/styled';
import { Button, FormCard, Typography } from 'src/components-dummy';

export type CodeColorOption = 'green' | 'pink' | 'blue' | 'black';

export const JsSnippetSectionStyled = styled(FormCard)`
  max-width: 752px;
  margin-top: 24px;
`;

export const JsSnippetSubTitleStyled = styled(Typography)``;

export const JsSnippetCaptionStyled = styled(Typography)`
  margin: 10px 0;
`;

export const CodeTypographyColored = styled(Typography)<{
  color: CodeColorOption;
}>`
  color: ${({ theme, color }) => {
    switch (color) {
      case 'green':
        return theme.palette.custom['green-20'];
      case 'blue':
        return theme.palette.custom['primary-main'];
      case 'pink':
        return theme.palette.custom.pink;
      case 'black':
      default:
        return theme.palette.common.black;
    }
  }};
`;

export const CodeSectionStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  border-radius: 8px;
  position: relative;
  padding: 41px 24px 24px;
  margin-top: 15px;
`;

export const CopyButtonStyled = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ theme }) => theme.palette.custom['gray-20']};
  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-20']};
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom['gray-60']};
  }
`;
