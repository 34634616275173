import React from 'react';
import { PaginationProps as PaginationMuiProps } from '@mui/material';
import {
  Typography,
  TypographyType,
  TypographyVariant,
  Icon,
  AvailableIcons,
} from 'src/components-dummy';
import {
  PaginationStyled,
  ButtonStyled,
  PreviousButtonStyled,
  PaginationItemStyled,
} from './Pagination.styles';

const NextButton = () => {
  return (
    <ButtonStyled>
      <Typography type={TypographyType.Button} variant={TypographyVariant.MediumMedium}>
        Next
      </Typography>
      <Icon name={AvailableIcons.ArrowRounded} />
    </ButtonStyled>
  );
};

const PreviousButton = () => {
  return (
    <PreviousButtonStyled>
      <Icon name={AvailableIcons.Arrow} />
      <Typography type={TypographyType.Button} variant={TypographyVariant.MediumMedium}>
        Prev
      </Typography>
    </PreviousButtonStyled>
  );
};

export type PaginationProps = Omit<PaginationMuiProps, 'count' | 'page'> & {
  skip: number;
  totalItemsCount: number;
  itemsPerPage?: number;
  pagesToShow?: number;
  onPageChange?: (numberOfItemsToSkip: number) => void;
};

export const Pagination = ({
  skip,
  totalItemsCount,
  itemsPerPage = 10,
  pagesToShow = 5,
  onPageChange: onPageChangeCallBack,
  ...rest
}: PaginationProps): JSX.Element => {
  const availablePagesCount = Math.ceil(totalItemsCount / itemsPerPage);
  const siblingCount = Math.min(availablePagesCount, Math.floor((pagesToShow - 1) / 2));
  const currentPage = Math.floor(skip / itemsPerPage) + 1;

  const onPageChange = (_: React.ChangeEvent<unknown>, pageNumber: number) => {
    const itemsToSkip = (pageNumber - 1) * itemsPerPage;
    onPageChangeCallBack?.(itemsToSkip);
  };

  return (
    <PaginationStyled
      {...rest}
      count={availablePagesCount}
      page={currentPage}
      onChange={onPageChange}
      shape='rounded'
      boundaryCount={1}
      siblingCount={siblingCount}
      renderItem={item => (
        <PaginationItemStyled
          components={{ next: NextButton, previous: PreviousButton }}
          {...item}
        />
      )}
    />
  );
};
