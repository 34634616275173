import React, { useEffect, useState } from 'react';
import {
  AvailableIcons,
  Typography,
  TypographyType,
  TypographyVariant,
  Icon,
} from 'src/components-dummy';
import { usePublishStatusIndication, NotificationIsClosed } from './usePublishStatusIndication';
import { ReviewBeforePublishModal } from './ReviewBeforePublishModal';
import { PublishStatusIndicationProps, PublishStatusIndicationMode } from './types';
import './PublishStatusIndication.scss';
import {
  PublishedNotificationBannerContainer,
  PublishedNotificationBannerIcon,
  PublishedNotificationBannerCloseIconContainer,
  PublishedNotificationBannerReviewPublish,
  PublishedNotificationBannerReviewPublishContainer,
} from './PublishNotificationBanner.styles';
import { FieldType } from 'src/services';
import { publishNotificationBannerActions } from './Actions/PublishNotificationBannerActions';

export const PublishNotificationBanner = ({
  dispatch,
  shopId,
  draft,
  mode,
  text,
  dataFields,
  runningExperiment,
}: PublishStatusIndicationProps): JSX.Element => {
  const [hideBanner, setHideBanner] = useState(
    localStorage.getItem(NotificationIsClosed(shopId)) === 'true'
  );
  const settings = usePublishStatusIndication({
    dispatch,
    shopId,
    draft,
    mode,
  });

  useEffect(() => {
    dispatch(
      publishNotificationBannerActions.getDataFields({
        shopId,
        fieldType: FieldType.CatalogField,
      })
    );
  }, [shopId]);

  const {
    modalIsOpened,
    setModalIsOpened,
    onDiscardDraft,
    onPublishDraft,
    modeConfig,
    actualMode,
  } = settings;

  useEffect(() => {
    setHideBanner(localStorage.getItem(NotificationIsClosed(shopId)) === 'true');
  }, [actualMode]);

  return !hideBanner || actualMode === PublishStatusIndicationMode.CanPublish ? (
    <PublishedNotificationBannerContainer notificationType={modeConfig.notificationType}>
      <div>
        <PublishedNotificationBannerIcon
          name={modeConfig && modeConfig.icon ? modeConfig.icon : AvailableIcons.CloudSuccess}
        />
      </div>
      <PublishedNotificationBannerReviewPublishContainer>
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
          {text || modeConfig.text} &nbsp;
          {actualMode === PublishStatusIndicationMode.CanPublish && (
            <>
              <PublishedNotificationBannerReviewPublish onClick={() => setModalIsOpened(true)}>
                Review & Publish
              </PublishedNotificationBannerReviewPublish>
              <ReviewBeforePublishModal
                draft={draft}
                show={modalIsOpened}
                dataFields={dataFields}
                onDiscardDraft={onDiscardDraft}
                onPublishDraft={onPublishDraft}
                setModalIsOpened={setModalIsOpened}
                runningExperiment={runningExperiment}
              />
            </>
          )}
        </Typography>
      </PublishedNotificationBannerReviewPublishContainer>
      {actualMode === PublishStatusIndicationMode.UpToDate && (
        <PublishedNotificationBannerCloseIconContainer>
          <Icon
            onClick={() => {
              localStorage.setItem(NotificationIsClosed(shopId), 'true');
              setHideBanner(true);
            }}
            name={AvailableIcons.Close}
          />
        </PublishedNotificationBannerCloseIconContainer>
      )}
    </PublishedNotificationBannerContainer>
  ) : (
    <></>
  );
};
