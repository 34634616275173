export enum FormNames {
  LoginForm = 'loginForm',
  ForgotPasswordForm = 'forgotPasswordForm',
  EmailLinkVerificationForm = 'emailLinkVerificationForm',
}

export interface LoginFormArguments {
  goToForgotPasswordForm: (email: string | undefined) => void;
}

export interface ForgotPasswordFormArguments {
  goToLoginForm: () => void;
  email?: string;
}

export enum LoginFormKeys {
  Email = 'email',
  Password = 'password',
}

export interface ILoginForm {
  email: string;
  password: string;
}

export interface IForgotPasswordForm {
  email: string;
}
