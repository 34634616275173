import { DeepTagReportWithMetaData, DomainEntityPath } from '../types';
import { DeepTagReportsMapper } from './deep-tag-reports.mapper';
import { DomainEventsWebSocketServiceBase, DomainEvent } from '../domain-events';

type DeleteReportPayload = { reportId: string; shopId: number };
type UpdateReportPayload = { report: DeepTagReportWithMetaData };

export enum DeepTagReportEventTopicNames {
  Create = 'deep_tag_report:create',
  Update = 'deep_tag_report:update',
  IsReady = 'deep_tag_report:is-ready',
  Delete = 'deep_tag_report:delete',
}

export type DeepTagReportUpdateEvent = DomainEvent<
  UpdateReportPayload,
  DeepTagReportEventTopicNames
>;
export type DeepTagReportDeleteEvent = DomainEvent<
  DeleteReportPayload,
  DeepTagReportEventTopicNames
>;

class DeepTagReportsWebSocketService extends DomainEventsWebSocketServiceBase<DeepTagReportEventTopicNames> {
  domainEntity = DomainEntityPath.DeepTagReport;

  subscribeToReportCreateEvents(onReportCreate: (payload: DeepTagReportUpdateEvent) => void): void {
    this.subscribeToEvents({
      topicKey: DeepTagReportEventTopicNames.Create,
      callback: (response: DeepTagReportUpdateEvent) => {
        const mappedReport = DeepTagReportsMapper.mapReportWithMetaData({
          data: response.data.report,
        } as any);

        response.data.report = mappedReport;

        onReportCreate(response);
      },
    });
  }

  subscribeToReportUpdateEvents(onReportUpdate: (payload: DeepTagReportUpdateEvent) => void): void {
    this.subscribeToEvents({
      topicKey: DeepTagReportEventTopicNames.Update,
      callback: (response: DeepTagReportUpdateEvent) => {
        const mappedReport = DeepTagReportsMapper.mapReportWithMetaData({
          data: response.data.report,
        } as any);

        response.data.report = mappedReport;

        onReportUpdate(response);
      },
    });
  }

  subscribeToReportReadyEvents(onReportReady: (payload: DeepTagReportUpdateEvent) => void): void {
    this.subscribeToEvents({
      topicKey: DeepTagReportEventTopicNames.IsReady,
      callback: (response: DeepTagReportUpdateEvent) => {
        const mappedReport = DeepTagReportsMapper.mapReportWithMetaData({
          data: response.data.report,
        } as any);

        response.data.report = mappedReport;

        onReportReady(response);
      },
    });
  }

  subscribeToReportDeleteEvents(onReportDelete: (payload: DeepTagReportDeleteEvent) => void): void {
    this.subscribeToEvents({
      topicKey: DeepTagReportEventTopicNames.Delete,
      callback: onReportDelete,
    });
  }
}

export const deepTagReportsWebSocketService = new DeepTagReportsWebSocketService();
