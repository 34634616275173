import { ValidationResult, ValidationErrorItem } from 'joi';
import { arrayToDotNotationPath } from './array-to-dot-notation-path';

export type ParseJoiValidateResponse<T> = {
  [key in keyof Partial<T> | string]: ValidationErrorItem;
};

export const parseJoiValidateResponse = <T>(
  response: ValidationResult
): ParseJoiValidateResponse<T> => {
  const initialErrors = {} as ParseJoiValidateResponse<T>;

  const parsedErrors = response.error?.details.reduce((result, current) => {
    const key = arrayToDotNotationPath(current.path);
    const error = { [key]: current };

    return { ...result, ...error };
  }, initialErrors);

  return parsedErrors || initialErrors;
};

export const reduceErrorsByPrefix = <T>({
  errors,
  prefix,
  errorKey = '',
}: {
  errors: any;
  prefix: string;
  errorKey?: string;
}): ParseJoiValidateResponse<T> => {
  const regex = new RegExp(`${prefix}.?(.*)`);

  const trimmedErrors = Object.keys(errors || {}).reduce(
    (accumulator, key) => {
      const errorMatch = regex.exec(key);

      if (errorMatch) {
        const newErrorKey = errorMatch[1] || errorKey;
        accumulator[newErrorKey] = errors[key];
      }

      return accumulator;
    },
    {} as Record<string, any>
  );

  return trimmedErrors as ParseJoiValidateResponse<T>;
};
