import React, { useRef, MutableRefObject, KeyboardEvent } from 'react';
import { useCounterId } from '../hooks';
import { TypographyType, TypographyVariant } from '../Typography';
import { NumberFormatStyled, SymbolTypographyStyled } from './NumericInputStyled';

export interface NumericInputBaseProps {
  value?: number;
  onChange: (value: number | undefined) => void;
  onBlur?: () => void;
  staticSymbol?: string;
  error?: boolean;
  decimals?: number;
  thousandSeparator?: string;
  minNumericValue?: number;
  maxNumericValue?: number;
  placeholder?: string;
  disabled?: boolean;
  prefixId?: string;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  autoFocus?: boolean;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
}

export const NumericInputBase = ({
  onChange,
  onBlur,
  value,
  staticSymbol,
  placeholder,
  decimals = 0,
  thousandSeparator = ',',
  minNumericValue = Number.MIN_SAFE_INTEGER,
  maxNumericValue = Number.MAX_SAFE_INTEGER,
  disabled,
  prefixId,
  onKeyDown,
  autoComplete,
  autoFocus,
  inputRef,
}: NumericInputBaseProps): JSX.Element => {
  const inputId = useCounterId();
  const valueObjectRef = useRef<number | undefined>(undefined);

  const onNumberFormatChange = () => {
    onChange(valueObjectRef.current);
  };

  return (
    <>
      <NumberFormatStyled
        onValueChange={valueObject => {
          valueObjectRef.current = valueObject.floatValue;
        }}
        onChange={onNumberFormatChange}
        onBlur={onBlur}
        value={value ?? ''}
        decimalScale={decimals}
        isAllowed={values => {
          const { floatValue } = values;
          return (
            floatValue === undefined ||
            (floatValue >= Number.MIN_SAFE_INTEGER &&
              minNumericValue !== undefined &&
              floatValue >= minNumericValue &&
              floatValue <= Number.MAX_SAFE_INTEGER &&
              maxNumericValue !== undefined &&
              floatValue <= maxNumericValue)
          );
        }}
        allowNegative={false}
        type='text'
        thousandSeparator={thousandSeparator}
        placeholder={placeholder}
        getInputRef={(el: HTMLInputElement) => {
          if (inputRef) {
            // eslint-disable-next-line no-param-reassign
            inputRef.current = el;
          }
        }}
        id={inputId}
        disabled={disabled}
        data-id={prefixId}
        onKeyDown={onKeyDown}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
      />
      {staticSymbol && (
        <SymbolTypographyStyled
          type={TypographyType.Paragraph}
          variant={TypographyVariant.MediumRegular}
        >
          {staticSymbol}
        </SymbolTypographyStyled>
      )}
    </>
  );
};
