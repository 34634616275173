import React, { MouseEvent, useCallback, useState } from 'react';
import { Row } from 'react-table';
import { TableV2 } from 'src/components-dummy';
import { RowActionsMenu } from './RowActionsMenu';
import { tableColumns } from './tableColumns';
import { IFilterTableBodyRow } from './types';
import { DeleteFilterConfirmationDialog } from './DeleteFilterConfirmationDialog';
import { TableBodyRowCellStyled } from './FiltersTableRow.styles';
import { DragHandleCell } from './DragHandleCell';

interface FilterTableRowProps {
  row: Row<IFilterTableBodyRow>;
  disableDrag: boolean;
  onEnableClick: (filterId: string, enabled: boolean) => void;
  onDeleteFilter: (catalogId: string) => void;
  navigateToEditFilter: (filterId: string) => void;
  onRowDrag: (sourceIndex: number, targetIndex: number) => void;
  onRowDrop: () => void;
}

export const FilterTableRow = ({
  row,
  disableDrag,
  onEnableClick,
  onDeleteFilter,
  navigateToEditFilter,
  onRowDrag,
  onRowDrop,
}: FilterTableRowProps): JSX.Element => {
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);

  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);

  const onMenuClicked = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setMenuAnchorElement(event.currentTarget);
    },
    [setMenuAnchorElement]
  );

  const onMenuClose = useCallback(() => {
    setMenuAnchorElement(null);
  }, [setMenuAnchorElement]);

  const onEnableFilterClick = () => onEnableClick(row.original.id, !row.original.enabled);

  const onEditFilterClick = () => {
    navigateToEditFilter(row.original.id);
  };

  const onFilterDeleteConfirm = () => {
    onDeleteFilter(row.original.id);
    setShowDeleteConfirmationDialog(false);
  };

  const onOpenDeleteDialog = useCallback(() => {
    onMenuClose();
    setShowDeleteConfirmationDialog(true);
  }, [onMenuClose]);

  const onCloseDeleteDialog = useCallback(() => {
    onMenuClose();
    setShowDeleteConfirmationDialog(false);
  }, []);

  return (
    <>
      {showDeleteConfirmationDialog && (
        <DeleteFilterConfirmationDialog
          onCloseDeleteDialog={onCloseDeleteDialog}
          onDelete={onFilterDeleteConfirm}
        />
      )}
      <TableV2.DraggableBodyRow
        {...row.getRowProps()}
        className='filter-table-row'
        index={row.index}
        disableDrag={disableDrag}
        onRowDrag={onRowDrag}
        onRowDrop={onRowDrop}
      >
        {row.cells.map(cell => {
          const propName = cell.column.id;

          if (propName === tableColumns.rowActions.accessor) {
            return (
              <RowActionsMenu
                key={propName}
                menuAnchorElement={menuAnchorElement}
                onMenuClicked={onMenuClicked}
                onMenuClose={onMenuClose}
                enabled={row.original.enabled}
                onDeleteClick={onOpenDeleteDialog}
                onEnableClick={onEnableFilterClick}
                onEditClick={onEditFilterClick}
              />
            );
          }

          if (propName === tableColumns.positionHandle.accessor) {
            return (
              <TableV2.DraggableBodyRow.DragHandleCell key={propName}>
                <TableBodyRowCellStyled
                  {...cell.getCellProps()}
                  width={tableColumns[propName].width}
                  minWidth={cell.column.minWidth}
                  className='filter-table-cell'
                  data-id={`${row.original.dataSource}-${propName}`}
                  cellName={propName}
                  shouldBeGrayedOut={row.original.enabled}
                >
                  <DragHandleCell
                    isUsingInvalidDataField={row.original.isUsingInvalidDataField}
                    disabled={disableDrag}
                  />
                </TableBodyRowCellStyled>
              </TableV2.DraggableBodyRow.DragHandleCell>
            );
          }

          return (
            <TableBodyRowCellStyled
              {...cell.getCellProps()}
              key={propName}
              width={tableColumns[propName].width}
              minWidth={cell.column.minWidth}
              className='filter-table-cell'
              data-id={`${row.original.dataSource}-${propName}`}
              cellName={propName}
              shouldBeGrayedOut={!row.original.enabled}
              onClick={
                propName === tableColumns.sourceName.accessor ? onEditFilterClick : undefined
              }
            >
              <TableV2.BodyRowCellText>{cell.value}</TableV2.BodyRowCellText>
            </TableBodyRowCellStyled>
          );
        })}
      </TableV2.DraggableBodyRow>
    </>
  );
};
