import styled from '@emotion/styled';
import { Icon, MenuItem, TableV2 } from 'src/components-dummy';

export const MenuActionsStyled = styled.div`
  display: flex;
  justify-content: end;

  .MuiButtonBase-root {
    height: 32px;
    align-items: end !important;
  }
`;

export const MenuItemIconStyled = styled(Icon)`
  margin-right: 12px;
`;

export const MenuItemDeleteStyled = styled(MenuItem)`
  color: ${({ theme }) => theme.palette.custom.red};

  svg > path {
    stroke: ${({ theme }) => theme.palette.custom.red};
  }
`;

export const TableBodyStyled = styled(TableV2.Body)`
  max-height: calc(100vh - 380px);
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};
`;

export const TableWrapperStyled = styled.div`
  min-width: 560px;
`;
