import { v4 as uuidV4 } from 'uuid';
import { ILexiconRule } from 'src/services';
import { UpdateLexiconRuleArguments } from 'src/services/src/service/lexicon/types';
import {
  ILexiconRuleDraft,
  TagFields,
  TagPart,
  AttributeTagPart,
  ValueTagPart,
  CategoryTagPart,
  ILexiconRuleConditionsGroupDraft,
} from './LexiconRuleForm.types';
import { getInitialLexiconRuleDraft } from './constants';

export function mapLexiconRuleDraft(lexiconRule: ILexiconRule): ILexiconRuleDraft {
  const tagParts: TagPart[] = [];

  if (lexiconRule.category) {
    tagParts.push({ ...lexiconRule.category, field: TagFields.Category, id: Math.random() });
  }

  if (lexiconRule.attribute) {
    tagParts.push({ ...lexiconRule.attribute, field: TagFields.Attribute, id: Math.random() });
  }

  if (lexiconRule.value) {
    tagParts.push({ ...lexiconRule.value, field: TagFields.Value, id: Math.random() });
  }

  return {
    tagParts,
    conditionsGroups: lexiconRule.conditionsGroups.map(group => {
      const groupId = uuidV4();
      return {
        id: groupId,
        conditions: group.conditions.map(condition => {
          const conditionId = uuidV4();
          return {
            ...condition,
            id: conditionId,
          };
        }),
      };
    }),
  };
}

export function getTagPartByField<T = TagPart>(
  tagParts: TagPart[],
  field: TagFields
): T | undefined {
  return tagParts.find(part => part.field === field) as unknown as T | undefined;
}

function omitIdFromConditions(
  conditionGroups: ILexiconRuleConditionsGroupDraft[]
): ILexiconRule['conditionsGroups'] {
  return conditionGroups.map(({ conditions }) => {
    return {
      conditions: conditions.map(({ id: _id, ...condition }) => {
        return condition;
      }),
    };
  });
}

export function mapLexiconRuleDraftToPartialApi(
  lexiconRuleDraft: ILexiconRuleDraft & { include: boolean }
): UpdateLexiconRuleArguments['partialRuleToUpdate'] {
  const category =
    getTagPartByField<CategoryTagPart>(lexiconRuleDraft.tagParts, TagFields.Category) || null;

  const attribute =
    getTagPartByField<AttributeTagPart>(lexiconRuleDraft.tagParts, TagFields.Attribute) || null;

  const value = getTagPartByField<ValueTagPart>(lexiconRuleDraft.tagParts, TagFields.Value) || null;

  return {
    include: lexiconRuleDraft.include,
    category,
    attribute,
    value,
    conditionsGroups: omitIdFromConditions(lexiconRuleDraft.conditionsGroups),
  };
}

export function mapLexiconRuleDraftToApi(
  lexiconRuleDraft: ILexiconRuleDraft & { include: boolean }
): Omit<ILexiconRule, 'id' | 'createdAt' | 'updatedAt'> {
  const category = getTagPartByField<CategoryTagPart>(
    lexiconRuleDraft.tagParts,
    TagFields.Category
  );

  const attribute = getTagPartByField<AttributeTagPart>(
    lexiconRuleDraft.tagParts,
    TagFields.Attribute
  );

  const value = getTagPartByField<ValueTagPart>(lexiconRuleDraft.tagParts, TagFields.Value);

  return {
    include: lexiconRuleDraft.include,
    category,
    attribute,
    value,
    conditionsGroups: omitIdFromConditions(lexiconRuleDraft.conditionsGroups),
  };
}

function getUniqueCategoriesFromRule(rule: ILexiconRuleDraft): string[] {
  const uniqueCategories = new Set<string>();

  for (const group of rule.conditionsGroups) {
    for (const { categoryKey } of group.conditions) {
      if (categoryKey && categoryKey.length > 0) {
        uniqueCategories.add(categoryKey);
      }
    }
  }

  const category = getTagPartByField<CategoryTagPart>(rule.tagParts, TagFields.Category);

  if (category?.categoryKey && category.categoryKey.length > 0) {
    uniqueCategories.add(category.categoryKey);
  }

  return [...uniqueCategories];
}

export function getChangingCategoryKey({
  previousDraft,
  currentDraft,
}: {
  previousDraft: ILexiconRuleDraft;
  currentDraft: ILexiconRuleDraft;
}): string | undefined {
  const previousCategoryKeys = getUniqueCategoriesFromRule(previousDraft);
  const categoryCategoryKeys = getUniqueCategoriesFromRule(currentDraft);

  const allStatesHaveCategoryKeys =
    categoryCategoryKeys.length > 0 && previousCategoryKeys.length > 0;

  if (!allStatesHaveCategoryKeys) {
    return undefined;
  }

  for (const categoryKey of categoryCategoryKeys) {
    if (!previousCategoryKeys.includes(categoryKey)) {
      return categoryKey;
    }
  }

  return undefined;
}

export function getCategoryKeyFromDraft(draft: ILexiconRuleDraft): string | undefined {
  for (const group of draft.conditionsGroups) {
    for (const { categoryKey } of group.conditions) {
      if (categoryKey && categoryKey.length > 0) {
        return categoryKey;
      }
    }
  }

  const category = getTagPartByField<CategoryTagPart>(draft.tagParts, TagFields.Category);

  if (!category?.categoryKey) {
    return undefined;
  }

  return category.categoryKey.length > 0 ? category.categoryKey : undefined;
}

export function calculateNewRuleDraftState({
  currentState,
  partialNewState,
  skipNextCategoryKeyCheck = false,
}: {
  currentState: ILexiconRuleDraft;
  partialNewState: Partial<ILexiconRuleDraft>;
  skipNextCategoryKeyCheck?: boolean;
}): { updatedState: ILexiconRuleDraft; resetStateToCategoryKey: string | undefined } {
  const updatedState = { ...currentState, ...partialNewState };

  // clean up groups with no rules
  updatedState.conditionsGroups = updatedState.conditionsGroups.filter(
    conditionGroup => conditionGroup.conditions.length > 0
  );

  if (skipNextCategoryKeyCheck === false) {
    const nextCategoryKey = getChangingCategoryKey({
      previousDraft: currentState,
      currentDraft: updatedState,
    });

    if (nextCategoryKey) {
      return { updatedState: currentState, resetStateToCategoryKey: nextCategoryKey };
    }
  }

  const ruleCategoryKey = getCategoryKeyFromDraft(updatedState);

  if (ruleCategoryKey) {
    updatedState.tagParts = updatedState.tagParts.map(tagPart => {
      if (tagPart.field === TagFields.Category) {
        return {
          ...tagPart,
          categoryKey: ruleCategoryKey,
        };
      }
      return tagPart;
    });

    updatedState.conditionsGroups = updatedState.conditionsGroups.map(group => ({
      ...group,
      conditions: group.conditions.map(condition => ({
        ...condition,
        categoryKey: ruleCategoryKey,
      })),
    }));
  }

  return { updatedState, resetStateToCategoryKey: undefined };
}

export function applyNextCategoryKeyToEmptyRule({
  previousDraft,
  nextCategoryKey,
}: {
  previousDraft: ILexiconRuleDraft;
  nextCategoryKey: string;
}): ILexiconRuleDraft {
  const newRule = { ...getInitialLexiconRuleDraft() };

  newRule.conditionsGroups[0].conditions[0].categoryKey = nextCategoryKey;

  const previousStateCategoryPart = getTagPartByField<CategoryTagPart>(
    previousDraft.tagParts,
    TagFields.Category
  );

  if (previousStateCategoryPart) {
    newRule.tagParts = [
      {
        ...newRule.tagParts[0],
        field: TagFields.Category,
        categoryKey: nextCategoryKey,
        customTranslation: '',
      },
    ];
  }

  return newRule;
}
