import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const LabelStyled = styled.label`
  display: block;
  max-width: 440px;
`;

export const RuleFormFieldTextStyled = styled(Typography)`
  display: block;
  margin-bottom: 8px;
`;
