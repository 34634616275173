import { Shops } from 'src/services/src/service/shops/shops';
import { Dispatch } from 'src/components-bl/types';
import {
  UpdateUseKnnPayload,
  GetUseKnnArgs,
  GetUseKnnRequestPayload,
  GetUseKnnSuccessPayload,
  GetUseKnnErrorPayload,
  UpdateUseKnnRequestPayload,
  UpdateUseKnnSuccessPayload,
  UpdateUseKnnErrorPayload,
  UseKnnActionTypes,
  GetUseKnnRequestAction,
  GetUseKnnErrorAction,
  GetUseKnnSuccessAction,
  UpdateUseKnnRequestAction,
  UpdateUseKnnSuccessAction,
  UpdateUseKnnErrorAction,
  NotifyUseKnnIsDirtyAction,
  NotifyUseKnnIsDirtyActionPayload,
} from './types';

const shopService = new Shops();

/* --= Actions =-- */
const useKnnActions = {
  getUseKnnRequest: (payload: GetUseKnnRequestPayload): GetUseKnnRequestAction => {
    return {
      type: UseKnnActionTypes.GetUseKnnRequest,
      payload,
    };
  },
  getUseKnnSuccess: (payload: GetUseKnnSuccessPayload): GetUseKnnSuccessAction => {
    return {
      type: UseKnnActionTypes.GetUseKnnSuccess,
      payload,
    };
  },
  getUseKnnError: ({ error }: GetUseKnnErrorPayload): GetUseKnnErrorAction => {
    return {
      type: UseKnnActionTypes.GetUseKnnError,
      payload: { error },
    };
  },
  updateUseKnnRequest: (payload: UpdateUseKnnRequestPayload): UpdateUseKnnRequestAction => {
    return {
      type: UseKnnActionTypes.UpdateUseKnnRequest,
      payload,
    };
  },
  updateUseKnnSuccess: (payload: UpdateUseKnnSuccessPayload): UpdateUseKnnSuccessAction => {
    return {
      type: UseKnnActionTypes.UpdateUseKnnSuccess,
      payload,
    };
  },
  updateUseKnnError: (payload: UpdateUseKnnErrorPayload): UpdateUseKnnErrorAction => {
    return {
      type: UseKnnActionTypes.UpdateUseKnnError,
      payload,
    };
  },

  notifyIsDirtyState: (payload: NotifyUseKnnIsDirtyActionPayload): NotifyUseKnnIsDirtyAction => {
    return {
      type: UseKnnActionTypes.NotifyUseKnnIsDirty,
      payload,
    };
  },
};

/* --= Methods =-- */
export const useKnnActionsMethods = {
  notifyIsDirty:
    ({ isDirty }: NotifyUseKnnIsDirtyActionPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(useKnnActions.notifyIsDirtyState({ isDirty }));
    },

  fetchUseKnn:
    ({ shopId, selectedVariantId }: GetUseKnnArgs) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(useKnnActions.getUseKnnRequest({ shopId }));
        const { useKnn } = await shopService.getUseKnn(shopId, selectedVariantId);
        dispatch(
          useKnnActions.getUseKnnSuccess({
            shopId,
            useKnn,
          })
        );
      } catch (error) {
        dispatch(useKnnActions.getUseKnnError({ error }));
      }
    },

  updateUseKnn:
    ({ shopId, selectedVariantId, useKnn }: UpdateUseKnnPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(
          useKnnActions.updateUseKnnRequest({
            shopId,
            useKnn,
          })
        );

        await shopService.updateUseKnn({
          shopId,
          selectedVariantId,
          useKnn,
        });

        dispatch(
          useKnnActions.updateUseKnnSuccess({
            shopId,
            useKnn,
          })
        );
      } catch (error) {
        dispatch(useKnnActions.updateUseKnnError({ error }));
      }
    },
};
