import React from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { AdminSettingsForms } from 'src/components-bl';
import { useAppSelector } from 'src/hooks';

export const AdminSettings = (): JSX.Element | null => {
  const { shopId, featureToggles, runningExperimentId } = useAppSelector(
    state => ({
      featureToggles: state.shop.featureToggles,
      runningExperimentId: state.experiments.runningExperiment?.id,
      shopId: state.shop.current?.shopId,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  return shopId ? (
    <AdminSettingsForms
      dispatch={dispatch}
      featureToggles={featureToggles}
      runningExperimentId={runningExperimentId}
      shopId={shopId}
    />
  ) : null;
};
