import React, { ReactNode } from 'react';
import { FooterStyled } from './RuleFormFooter.styles';

export interface RuleFormFooterProps {
  children: ReactNode | ReactNode[];
  className?: string;
}

export const RuleFormFooter = ({ children, className }: RuleFormFooterProps): JSX.Element => {
  return <FooterStyled className={className}>{children}</FooterStyled>;
};
