import { UserTypes } from '../types';
import { AuthResponse } from '../types/auth/auth';
import { UsersMapper } from '../users/users-mapper';

export class AuthMapper {
  static mapAuthResponse({
    user,
    twoFactorAuthRequired,
  }: AuthResponse<UserTypes.UserBackEnd>): AuthResponse {
    return {
      user: user ? UsersMapper.mapBackendUser(user) : undefined,
      twoFactorAuthRequired,
    };
  }
}
