/* eslint-disable react/no-unescaped-entities */
import { css } from '@emotion/react';
import React, { useCallback } from 'react';
import { TextBox, Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { CatalogFormField } from '../CatelogFormField/CatalogFormField';
import { CatalogFormSection } from '../CatalogFormSection/CatalogFormSection';
import {
  ErrorLabelStyled,
  ErrorsMessagesWrapperStyled,
  SubTitleStyled,
} from '../../CatalogForm.styles';
import { CatalogFormDraft } from '../../CatalogForm.config';

export interface CatalogFormGeneralSectionProps {
  onFieldChange: (partialData: Pick<CatalogFormDraft, 'name'>) => void;
  isNameReadOnly: boolean;
  errors?: any;
  name: string;
}

export const CatalogFormGeneralSection = React.memo(
  ({
    name,
    isNameReadOnly,
    onFieldChange,
    errors,
  }: CatalogFormGeneralSectionProps): JSX.Element => {
    const errorMessage = errors?.message;

    const onNameChange = useCallback(
      (value: any) => {
        onFieldChange({ name: value });
      },
      [onFieldChange]
    );

    return (
      <CatalogFormSection>
        <CatalogFormSection.Header>General</CatalogFormSection.Header>

        {errorMessage ? (
          <ErrorsMessagesWrapperStyled>
            <ErrorLabelStyled> {errorMessage} </ErrorLabelStyled>{' '}
          </ErrorsMessagesWrapperStyled>
        ) : null}

        <CatalogFormField
          css={css`
            margin-top: 18px;
          `}
        >
          <div
            css={css`
              margin-bottom: 8px;
            `}
          >
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
              {isNameReadOnly ? 'Catalogue name' : 'Name your catalogue'}
            </Typography>
            {!isNameReadOnly && (
              <SubTitleStyled type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                Don't use spaces or special characters
              </SubTitleStyled>
            )}
          </div>
          <TextBox
            value={name || ''}
            placeholder='catalogue_name'
            onChange={onNameChange}
            error={!!errors}
            disabled={isNameReadOnly}
          />
        </CatalogFormField>
      </CatalogFormSection>
    );
  }
);
