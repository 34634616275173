import { useEffect } from 'react';
import { collectionsListActionMethods } from './actions';
import type { UseCollectionsListArguments } from './types';

export const useGetCollectionsList = ({
  shopId,
  dispatch,
  variantId,
}: UseCollectionsListArguments): void => {
  useEffect(() => {
    if (shopId) {
      dispatch(collectionsListActionMethods.getCollectionsList({ shopId, variantId }));
    }
  }, [shopId]);
};
