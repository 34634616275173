/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-rest-params */
import { useEffect, useRef } from 'react';
import { UserTypes } from 'src/services';

const shouldEnableHotjar = process.env.REACT_APP_INJECT_HOTJAR === 'true';

export const useInjectHotjar = ({ user }: { user: UserTypes.User }): void => {
  if (!shouldEnableHotjar) return;

  let { current: script } = useRef<HTMLScriptElement | null>(null);

  function injectHotjar(h: any, o: any, t: any, j: any, a?: any, r?: any) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 3366035, hjsv: 6 };

    a = o.getElementsByTagName('head')[0];

    r = o.createElement('script');
    r.async = true;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;

    a.appendChild(r);

    script = r;
  }

  useEffect(() => {
    if (user.role !== UserTypes.UserRoles.SyteAdmin && !script) {
      injectHotjar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
  }, [user.userId]);
};
