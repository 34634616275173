import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { useIsAnimating } from 'src/hooks';
import {
  Button,
  Dialog,
  Icon,
  AvailableIcons,
  Notification,
  NotificationType,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import syteIcon from '../../images/syteIcon.svg';
import './IntroScreen.scss';

export interface IntroScreenProps {
  show: boolean;
}

const changes = [
  {
    title: 'Navigation Panel',
    description:
      'With the new side panel menu, it’s easier than ever to hop between shops, products, and configuration settings.',
  },
  {
    title: 'Draft',
    description: (
      <>
        <span>
          New configurations and changes made to existing settings will now <br />
          be saved as drafts prior to publishing.
        </span>
        <br />
        <br />
        <span>After pressing the “save” button on a new setting or update,</span>
        <div className='desc-img-smaple'>
          <span>
            you can then choose to discard or publish the drafted change using the buttons on the
            top bar.
          </span>
          <Notification
            className='syte-intro-screen-unpublished-changes-notification'
            mode={NotificationType.Warning}
            icon={AvailableIcons.CloudError2}
          >
            Unpublished changes
          </Notification>
        </div>
      </>
    ),
  },
];

export const IntroScreen = ({ show }: IntroScreenProps): JSX.Element => {
  const [isOpened, setIsOpened] = useState(show);
  const [isClosing, setIsClosing] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const onAnimationEnd = () => {
    setIsOpened(false);
  };

  const onAnimationStart = () => {
    setIsClosing(true);
  };

  useIsAnimating({ ref, onAnimationEnd, onAnimationStart });

  return (
    <Dialog
      show={isOpened}
      onClose={onAnimationStart}
      ref={ref}
      className={classNames('syte-intro-screen', { 'is-closing': isClosing })}
    >
      <Dialog.Header>
        <img className='syte-intro-img' src={syteIcon} alt='SyteIcon' />
        <h1 className='syte-intro-header'>
          {/* Welcome to the Console!<small>Introducing a new and improved Look & Feel.</small> */}
          We’ve made some upgrades to the Discovery Console!
        </h1>
      </Dialog.Header>
      <Dialog.Body>
        <div className='syte-intro-screen-body'>
          <div className='syte-intro-screen-body-main-block'>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              What’s new?
            </Typography>
          </div>
          <ul className='syte-intro-screen-body-list'>
            {changes.map(({ title, description }) => {
              return (
                <li className='syte-intro-screen-body-list-item' key={title}>
                  <Icon name={AvailableIcons.Valid} />
                  <div className='syte-intro-screen-body-list-item-title'>
                    <Typography
                      type={TypographyType.Body}
                      variant={TypographyVariant.MediumRegular}
                    >
                      {title}
                    </Typography>
                  </div>

                  <Typography
                    type={TypographyType.Body}
                    variant={TypographyVariant.SmallRegular}
                    className='syte-intro-screen-body-list-item-description'
                  >
                    {description}
                  </Typography>
                </li>
              );
            })}
          </ul>
        </div>
      </Dialog.Body>
      <Dialog.ActionsFooter>
        <a
          target='blank'
          href='https://guidelines.syte.ai/5c8a9b7e5/p/220f23-new-look--feel/b/0773ca'
          className='syte-intro-screen-body-link'
        >
          Click here to learn more
        </a>
        <Button variant='primary' onClick={onAnimationStart}>
          Got it
        </Button>
      </Dialog.ActionsFooter>
    </Dialog>
  );
};
