import React from 'react';
import { Row } from 'react-table';
import { TableV2, TableV2InstanceProps } from 'src/components-dummy';
import {
  GalleryImageUrlCell,
  TableBodyRowCellStyled,
} from './InspirationalImagesGalleryTable.styles';
import { tableColumns } from './tableColumns';
import { InspirationalImagesGalleryTableRow } from './types';

interface GalleryTableRowProps {
  row: Row<InspirationalImagesGalleryTableRow>;
}

export function GalleryTableRow({ row }: GalleryTableRowProps): JSX.Element {
  const renderCell = ({
    cell,
  }: {
    cell: TableV2InstanceProps<InspirationalImagesGalleryTableRow>['rows'][0]['cells'][0];
  }) => {
    switch (cell.column.id) {
      case tableColumns.skus.accessor: {
        return <TableV2.BodyRowCellText>{cell.value.join(',')}</TableV2.BodyRowCellText>;
      }
      case tableColumns.imageUrl.accessor: {
        return (
          <GalleryImageUrlCell href={cell.value} target='_blank' rel='noreferrer'>
            <TableV2.BodyRowCellText>{cell.value}</TableV2.BodyRowCellText>
          </GalleryImageUrlCell>
        );
      }
      default:
        return <TableV2.BodyRowCellText>{cell.value}</TableV2.BodyRowCellText>;
    }
  };

  return (
    <TableV2.BodyRow {...row.getRowProps()} className='inspirational-images-gallery-table-row'>
      {row.cells.map(cell => {
        const propName = cell.column.id;

        return (
          <TableBodyRowCellStyled
            {...cell.getCellProps()}
            key={`${cell.column.id}-${cell.value}`}
            className='inspirational-images-gallery-table-cell'
            data-id={`${row.original.imageUrl}-${propName}`}
            isResizable
          >
            {renderCell({ cell })}
          </TableBodyRowCellStyled>
        );
      })}
    </TableV2.BodyRow>
  );
}
