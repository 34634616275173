import React, { useEffect } from 'react';
import { Link } from 'src/components-dummy';
import { reduceErrorsByPrefix } from 'src/utils';
import { UseDataFieldsLookupTableArgumentsReturnType } from 'src/components-bl/MerchandisingRules/components/useDataFieldsLookupTable';
import { Dispatch } from 'src/components-bl';
import { ConditionsGroup } from '../../ConditionsGroup/ConditionsGroup';
import { ConditionRowErrors, ConditionRowStyled } from '../RuleFormConditionsSection.styles';
import { RuleDraft, RuleDraftCondition } from '../../../MerchandisingRuleForm.config';
import { RuleConditionsAddGroup } from '../../RuleConditionsAddGroup/RuleConditionsAddGroup';
import { ruleFormMappers } from '../../../MerchandisingRuleForm.mappers';
import { mapErrorMessages } from '../../../../../mapErrorMessages';
import { DataFieldConditionDataType } from '../../../../DataFieldsCondition/DataFieldsCondition.types';
import { CategoryPageCondition } from '../CategoryPageCondition';
import { visualEditorActions } from '../../../../../../VisualEditor/state/visual-editor.actions';
import { CategoryFilterRule } from '../../../../../../VisualEditor/types/category-filter-rule';

const TITLE_TEXT = 'Condition (Category Page)';
const SUB_TITLE_TEXT = 'Add a condition to trigger this rule for specific category pages';

export interface ConditionsFilterByGroupProps extends UseDataFieldsLookupTableArgumentsReturnType {
  conditions: RuleDraftCondition[] | undefined;
  onFieldChange: (
    data: Partial<Pick<RuleDraft, 'action' | 'weight' | 'filterByCondition'>>
  ) => void;
  errors: any; // TODO
  dispatch: Dispatch;
  shopId: number;
  isReadOnly?: boolean;
  categoryFilterRule?: CategoryFilterRule;
}

export const ConditionsFilterByGroup = React.memo(
  ({
    conditions,
    errors,
    dispatch,
    shopId,
    isReadOnly,
    onFieldChange,
    categoryFilterRule,
  }: ConditionsFilterByGroupProps): JSX.Element => {
    useEffect(() => {
      dispatch(visualEditorActions.getCategoryFilterRule({ shopId }));
    }, [shopId]);

    const onConditionChange = (changedCondition: DataFieldConditionDataType) => {
      const updatedConditions = (conditions || []).map(condition =>
        condition.tempId === changedCondition.tempId ? changedCondition : condition
      );

      onFieldChange({
        filterByCondition: updatedConditions.length ? updatedConditions : undefined,
      });
    };

    const onAddNewCondition = () => {
      const updatedConditions = [
        ...(conditions || []),
        ruleFormMappers.createEmptyCategoryPageCondition(categoryFilterRule),
      ];
      onFieldChange({ filterByCondition: updatedConditions });
    };

    const onDeleteCondition = (conditionToDelete: RuleDraftCondition) => {
      const updatedConditions = conditions?.filter(
        condition => condition.tempId !== conditionToDelete.tempId
      );

      const newFilterByCondition = updatedConditions?.length ? updatedConditions : undefined;

      onFieldChange({ filterByCondition: newFilterByCondition });
    };

    const isInAddMode = !conditions?.length;

    return isInAddMode && !isReadOnly ? (
      <RuleConditionsAddGroup
        title={TITLE_TEXT}
        subTitle={SUB_TITLE_TEXT}
        onClick={onAddNewCondition}
      />
    ) : (
      <ConditionsGroup>
        <ConditionsGroup.Title>Select category pages</ConditionsGroup.Title>
        <ConditionsGroup.SubTitle>
          Trigger the rule for the following category pages.{' '}
          <Link href='https://support.syte.ai/space/ET/2814115841/Category+Pages+(Filtered+Collections)#What-is-a-category-page-identifier?'>
            Learn more
          </Link>{' '}
          on how to set up your category page identifier.
        </ConditionsGroup.SubTitle>
        <ConditionsGroup.Content>
          {conditions?.map((condition, index) => {
            const localErrors = reduceErrorsByPrefix({
              errors,
              prefix: `filterByCondition.${index}`,
            });

            const rowErrorLabels = mapErrorMessages(localErrors);
            const isError = errors[`filterByCondition.${index}.values`];

            return (
              <ConditionRowStyled key={condition.tempId}>
                <CategoryPageCondition
                  condition={condition}
                  isError={isError}
                  isReadOnly={isReadOnly}
                  onConditionChange={onConditionChange}
                  onDeleteCondition={onDeleteCondition}
                  categoryFilterRule={categoryFilterRule}
                />
                {rowErrorLabels.length > 0 ? (
                  <ConditionRowErrors>{rowErrorLabels}</ConditionRowErrors>
                ) : null}
              </ConditionRowStyled>
            );
          })}
        </ConditionsGroup.Content>
      </ConditionsGroup>
    );
  }
);
