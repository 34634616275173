export enum ExperimentStatus {
  Created = 'created',
  Running = 'running',
  Ended = 'ended',
}

export enum ExperimentMetric {
  AverageOrderValue = 'average_order_value',
  AverageRevenuePerUser = 'average_revenue_per_user',
  UserConversion = 'user_conversion',
}

export enum VariantType {
  Control = 'control',
  Test = 'test',
}
