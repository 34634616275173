import React from 'react';
import {
  AvailableIcons,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy';
import { useUpdateCategoryPage } from './useUpdateCategoryPage';
import { PreviewMerchRule } from '../../types';
import { CategoryPage, HighlightedText, Info, Line } from './SideMenu.styles';

const DEFAULT_CATEGORY_PAGE = 'Any category page';

export const Trigger = ({
  selectedRule,
  shopId,
  browsePLPValue,
}: {
  selectedRule: PreviewMerchRule;
  shopId: number;
  browsePLPValue: string;
}): JSX.Element => {
  const category = selectedRule?.filterByCondition?.[0]?.values[0] as string;
  const categoryPage = category || browsePLPValue;

  useUpdateCategoryPage(shopId, categoryPage);

  const categoryToDisplay = category || DEFAULT_CATEGORY_PAGE;

  return (
    <>
      <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
        Trigger
      </Typography>
      <CategoryPage type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
        Category page is
        <HighlightedText type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
          {categoryToDisplay}
        </HighlightedText>
      </CategoryPage>
      <Line />
      {selectedRule.isApplied ? (
        <Info>
          <Icon name={AvailableIcons.TooltipQuestionmark} />
          <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
            Additional saved rules are applied on this trigger
          </Typography>
        </Info>
      ) : null}
    </>
  );
};
