import styled from '@emotion/styled';
import { Icon } from '../Icon';

export const SelectCaretIconStyled = styled(Icon)<{ isOpened: boolean }>`
  position: absolute;
  pointer-events: none;
  right: 17px;
  stroke: #1e1e1e;

  ${({ isOpened }) => (isOpened ? 'transform: rotate(180deg)' : '')}
`;
