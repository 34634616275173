import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Icon } from 'src/components-dummy';

export const DeepTagReportProductsCustomLabelBulkStyled = styled.div`
  margin-top: 4px;
`;

export const ConfirmationDialogStyled = styled.div`
  overflow: hidden;
`;

/**
 * Form Item
 */
export const LabelItemStyled = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ItemContentStyled = styled.div`
  display: flex;
  flex: 1;
  max-width: 600px;
`;

export const ItemContentSectionStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 8px;
`;

export const ItemAutoCompleteWrapper = styled.div<{ isErrored: boolean }>`
  .MuiOutlinedInput-root {
    background-color: ${({ theme }) => theme.palette.common.white};
    padding-bottom: 0;
    padding-right: 8px !important;
  }

  .MuiInputBase-root.MuiOutlinedInput-root .MuiAutocomplete-input {
    padding-top: 2px;
    background-color: ${({ theme }) => theme.palette.common.white};
    min-width: 40px;
  }

  .MuiAutocomplete-root {
    &:not(:hover):not(.Mui-focused) {
      border: 1px solid #c5c5c5;
    }

    .MuiChip-label {
      font-size: 10px;
    }

    width: initial;
    background-color: ${({ theme }) => theme.palette.common.white};

    .MuiChip-filled {
      background-color: ${({ theme }) => theme.palette.custom['gray-70']};
    }
  }

  ${({ isErrored: shouldShowRedBorder }) =>
    shouldShowRedBorder &&
    css`
      .MuiAutocomplete-root {
        border: solid 1px #dc3545 !important; // Important to override other styles
      }
    `}
`;

export const ItemContentSectionLabelStyled = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const IconTrashStyled = styled(Icon)`
  margin-bottom: 12px;
  width: 16px;
  height: 16px;
  path {
    stroke: #6e6e71;
  }
`;

export const DeleteButtonWrapperStyled = styled.div`
  display: flex;
  width: 20px;
  align-items: end;
`;

/**
 * List Items
 */
export const ListItems = styled.div`
  display: flex;
  margin: 8px 0;
  flex-direction: column;
  & > div:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const AddItemButtonWrapperStyled = styled.div<{ disabled: boolean }>`
  button {
    align-items: center;
    color: ${({ theme }) => theme.palette.custom['primary-main']};

    svg {
      margin-right: 3px;
      & > path {
        stroke: ${({ theme, disabled }) =>
          disabled ? theme.palette.custom['gray-disabled'] : theme.palette.custom['primary-main']};
        fill: ${({ theme, disabled }) =>
          disabled ? theme.palette.custom['gray-disabled'] : theme.palette.custom['primary-main']};
      }
    }

    span {
      display: flex;
      align-items: center;
    }
  }
`;
