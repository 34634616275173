import React, { useRef } from 'react';
import { Product } from 'src/components-bl/socialDiscovery/types/entities.types';
import styled from '@emotion/styled';
import { useSKUState } from 'src/components-bl/socialDiscovery/AddSKU/useSKUState/useSKUState';

import { SkuState } from 'src/components-bl/socialDiscovery/AddSKU/useSKUState/types';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { SKUInput } from './SKUInput';
import { useSocialDiscoveryProvider } from '../../SocialDiscoveryProvider';

export const AddProductContainer = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  width: fit-content;
  margin: auto;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
`;

interface AddProductProps {
  onProductFetched: (product: Product) => void;
  onClickOutside: () => void;
}

export const AddProduct = ({ onProductFetched, onClickOutside }: AddProductProps) => {
  const { service } = useSocialDiscoveryProvider();
  const { SKUState, editSKU, fetchSKU } = useSKUState(service, onProductFetched);
  const ref = useRef(null);

  const handleClickOutside = () => {
    if (SKUState.type !== SkuState.Loading) {
      onClickOutside();
    }
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <AddProductContainer ref={ref} className='add-product-container'>
      <SKUInput
        inputValue={SKUState.sku}
        skuState={SKUState.type}
        errorMessage={SKUState.error?.message || null}
        onSubmit={fetchSKU}
        onChange={editSKU}
      />
    </AddProductContainer>
  );
};
