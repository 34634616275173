import React from 'react';
import {
  NumericInput,
  Button,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import './SimilarItemsSettings.scss';
import { SimilarItemsSettingsProps } from './types';
import { useSimilarItemsSettingsDraft } from './useSimilarItemsSettingsDraft';
import { createOptionalPortal } from '../helpers';
import { ConfigurationNote } from '../../components-dummy/Labels/ConfigurationNote';
import { ConfigurationVariant } from '../../components-dummy/Labels/configuration-variant';
import { useAppSelector } from '../../hooks';
import { AddToCartForm } from '../AddToCartSettings/AddToCartForm';
import { TextSimilarityToggle } from '../TextSimilarityToggle';

const NUMERIC_INPUT_DECIMAL_PLACES = 3;

export const SimilarItemsSettings = ({
  shopId,
  similarItemsSettings,
  selectedVariantId,
  formHeaderElementRef,
  onIsDirty,
  dispatch,
  disabled = false,
  shouldRefetch,
  isUserAdmin,
}: SimilarItemsSettingsProps): JSX.Element | null => {
  const addToCartEnabled = useAppSelector(({ shop }) => !!shop.featureToggles?.addToCart?.enabled);
  const similarItems = useSimilarItemsSettingsDraft({
    similarItemsSettings,
    dispatch,
    shopId,
    selectedVariantId,
    onIsDirty,
    shouldRefetch,
  });

  const {
    draftSimilarItemsSettings,
    updateAddToCartConfig,
    isValid,
    isDirty,
    onSubmit,
    onDiscard,
    onChange,
    onUpdateTextSimilarity,
    errors,
  } = similarItems;

  const formButtons = !disabled && (
    <>
      <Button onClick={onDiscard} variant='tertiary' disabled={!isDirty}>
        Discard
      </Button>
      <Button onClick={onSubmit} disabled={!(isValid && isDirty)} variant='primary'>
        {selectedVariantId ? 'Apply' : 'Save'}
      </Button>
    </>
  );

  const formButtonsSection = createOptionalPortal({
    portalContent: disabled ? <></> : formButtons,
    targetContainerRef: formHeaderElementRef,
    fallback: <div className='ranking-button-wrapper'>{formButtons}</div>,
  });

  return (
    <>
      <div className='similar-items-settings-container-wrapper'>
        <div className='similar-items-settings-container'>
          <Typography
            type={TypographyType.Body}
            variant={TypographyVariant.MediumRegular}
            className='results-display-title'
          >
            Results Display
          </Typography>
          <div className='similar-items-results-to-display'>
            <span className='results-to-display-title'>
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                Number of results to display (3-20)
              </Typography>
            </span>
            <div className='results-to-display-input-wrapper'>
              <NumericInput
                disabled={disabled}
                error={!!errors.numberOfResultsToDisplay}
                value={draftSimilarItemsSettings.numberOfResultsToDisplay}
                onChange={value => {
                  onChange({ numberOfResultsToDisplay: value });
                }}
                className='results-to-display-input'
                decimals={NUMERIC_INPUT_DECIMAL_PLACES}
              />
              {!!errors.numberOfResultsToDisplay && (
                <span className='results-to-display-input-error'>
                  <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                    Please enter a number between 3 to 20
                  </Typography>
                </span>
              )}
            </div>
          </div>
          {formButtonsSection}
        </div>
      </div>
      <ConfigurationNote variant={ConfigurationVariant.allAboveToNonApiUsers} />
      <AddToCartForm
        showSettingsForm={addToCartEnabled}
        addToCart={draftSimilarItemsSettings.addToCart}
        onChange={updateAddToCartConfig}
        shopId={shopId}
      />
      {isUserAdmin && (
        <TextSimilarityToggle
          enableTextSimilarity={draftSimilarItemsSettings.enableTextSimilarity}
          shopId={shopId}
          dispatch={dispatch}
          onChange={onUpdateTextSimilarity}
        />
      )}
    </>
  );
};
