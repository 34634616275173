import React from 'react';
import {
  TypographyVariant,
  TypographyType,
  Link,
  Icon,
  AvailableIcons,
} from 'src/components-dummy';
import {
  DeepTagReportHowToUploadStyled,
  TypographyStyled,
} from '../DeepTagReportHowToUpload.styles';

export const DeepTagReportHowToUploadTrigger = (): JSX.Element => {
  return (
    <DeepTagReportHowToUploadStyled>
      <Link href='https://support.syte.ai/space/SI/3067084896/Tags+Editor%3A+User+Guide'>
        <TypographyStyled variant={TypographyVariant.SmallRegular} type={TypographyType.Paragraph}>
          How to Start using tag enrichment?
        </TypographyStyled>
        <Icon name={AvailableIcons.ArrowRec} />
      </Link>
    </DeepTagReportHowToUploadStyled>
  );
};
