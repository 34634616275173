/* eslint-disable no-param-reassign */
import { v4 } from 'uuid';
import { Filter, FilterSortBy, FilterValuesSorting } from 'src/services';
import { FilterDraft } from './types';
import { MAX_ALLOWED_CUSTOM_ORDER_VALUES, initialDraftState } from './constants';

export class FilterFormMapper {
  static addEmptyCustomValueIfNeeded(valuesSorting: FilterDraft['valuesSorting']): void {
    const isCustomOrderSortBy = valuesSorting?.sortBy === FilterSortBy.CustomOrder;

    if (isCustomOrderSortBy && valuesSorting) {
      const values = valuesSorting.customOrder || [];
      const nonEmptyValues = values.filter(({ value }) => !!value);

      const reachedMaximum = nonEmptyValues.length >= MAX_ALLOWED_CUSTOM_ORDER_VALUES;

      if (!reachedMaximum) {
        valuesSorting.customOrder = [...nonEmptyValues, { id: v4(), value: undefined }];
      } else {
        valuesSorting.customOrder = nonEmptyValues;
      }
    }
  }

  static mapValuesSortingToDraft(
    valuesSorting: Filter['valuesSorting']
  ): FilterDraft['valuesSorting'] {
    if (!valuesSorting) return undefined;

    const mappedValuesSorting = {
      ...valuesSorting,
      customOrder: valuesSorting.customOrder?.map(value => ({
        value,
        id: v4(),
      })),
    };

    this.addEmptyCustomValueIfNeeded(mappedValuesSorting);

    return mappedValuesSorting;
  }

  static mapFilterToDraft(filter: Filter | undefined): FilterDraft {
    if (!filter) {
      return initialDraftState;
    }

    return {
      ...filter,
      valuesSorting: this.mapValuesSortingToDraft(filter.valuesSorting),
    };
  }

  static mapDraftToFilter(filterDraft: FilterDraft): Omit<Filter, 'createdAt' | 'updatedAt'> {
    const isCustomSorting = filterDraft.valuesSorting?.sortBy === FilterSortBy.CustomOrder;
    const mappedValuesSorting = filterDraft.valuesSorting
      ? ({
          ...filterDraft.valuesSorting,
          customOrder: isCustomSorting
            ? filterDraft.valuesSorting.customOrder
                ?.map(customOrder => customOrder.value)
                .filter(value => !!value)
            : undefined,
        } as FilterValuesSorting)
      : undefined;

    return {
      ...filterDraft,
      valuesSorting: mappedValuesSorting,
    } as Omit<Filter, 'createdAt' | 'updatedAt'>;
  }
}
