import React from 'react';
import { TableV2, TableV2InstanceProps } from '../../../../../components-dummy';
import { TableHeadRow } from './types/table-head-row';
import { HeadRowStyled } from './VisualEditorTable.styled';

interface Props {
  headers: TableV2InstanceProps<any>['headerGroups'][0]['headers'];
  columns: TableHeadRow[];
}

export const TableHeaderColumns: React.FC<Props> = ({ headers }) => {
  const renderHeaderColumn = ({ column }: { column: any }) => {
    return <TableV2.HeadRowCellText>{column.render('header')}</TableV2.HeadRowCellText>;
  };

  return (
    <>
      {headers.map(column => (
        <HeadRowStyled
          {...column.getHeaderProps()}
          width={column.width as string}
          minWidth={column.minWidth}
          key={column.id}
        >
          {renderHeaderColumn({ column })}
        </HeadRowStyled>
      ))}
    </>
  );
};
