import { PreviewMerchRule } from '../types';

export const compareMerchRules = (a: PreviewMerchRule, b: PreviewMerchRule): number => {
  const aStatus = [a.isApplied, a.active && !a.isApplied, !a.active, !a.error];
  const bStatus = [b.isApplied, b.active && !b.isApplied, !b.active, !b.error];

  for (let i = 0; i < 4; i++) {
    if (aStatus[i] !== bStatus[i]) {
      return aStatus[i] ? -1 : 1;
    }
  }

  return 0;
};
