import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { ConfirmationDialog, Button } from 'src/components-dummy';
import { isEqual } from 'lodash';
import { DeepTagsReportsConfiguration, IShopSftpSettings, MetaTypes } from 'src/services';
import { Dispatch } from '../../../types';
import { deepTagReportSFTPSettingsModalActions } from './DeepTagReportSFTPSettingsModal.actions';
import { DeepTagReportSettingsSection } from '../DeepTagReportsSettingsSection/DeepTagReportSettingsSection';
import { SFTPCredentials } from '../DeepTagReportHowToUpload/Modal/SFTPCredentials';
import { ModalStaticInfo } from '../DeepTagReportHowToUpload/Modal/ModalStaticInfo';
import { ConfirmationDialogStyled } from './DeepTagReportSFTPSettingsModal.styles';

const SFTP_HOST = 'uploads.wearesyte.com';
const SFTP_PORT = '2323';
const SFTP_DIRECTORY_PATH = '/tagging/input';
export interface DeepTagReportSFTPSettingsModalProps {
  onCancel: () => void;
  shopId: number;
  dispatch: Dispatch;
  configuration: DeepTagsReportsConfiguration;
  sftpSettings: IShopSftpSettings | undefined;
  languages: Pick<MetaTypes.Language, 'locale' | 'displayName'>[];
}

export const DeepTagReportSFTPSettingsModal = ({
  onCancel,
  shopId,
  dispatch,
  configuration,
  sftpSettings,
  languages,
}: DeepTagReportSFTPSettingsModalProps): JSX.Element => {
  const [formState, setFormState] = useState(configuration);

  const onFormStateChange = (partial: Partial<DeepTagsReportsConfiguration>) => {
    setFormState({ ...formState, ...partial });
  };

  const isDirty = useMemo(() => {
    return !isEqual(formState, configuration);
  }, [configuration, formState]);

  const onApply = useCallback(() => {
    if (isDirty) {
      dispatch(deepTagReportSFTPSettingsModalActions.updateConfiguration({ ...formState, shopId }));
    }
  }, [isDirty, formState, shopId]);

  useEffect(() => {
    setFormState(configuration);
  }, [configuration]);

  useEffect(() => {
    dispatch(deepTagReportSFTPSettingsModalActions.updateIsDirty({ isDirty }));
  }, [isDirty]);

  return (
    <ConfirmationDialogStyled onCancel={onCancel}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>SFTP Settings</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        <ModalStaticInfo directoryPath={SFTP_DIRECTORY_PATH} />
        <DeepTagReportSettingsSection
          locale={formState.locale}
          recognitionType={formState.recognitionType}
          languages={languages}
          onChange={onFormStateChange}
        />
        <SFTPCredentials
          settings={sftpSettings}
          port={SFTP_PORT}
          host={SFTP_HOST}
          directoryPath={SFTP_DIRECTORY_PATH}
        />
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <Button variant='primary' onClick={onApply} disabled={!isDirty}>
          Apply
        </Button>
        <Button variant='tertiary' onClick={onCancel}>
          Cancel
        </Button>
      </ConfirmationDialog.Footer>
    </ConfirmationDialogStyled>
  );
};
