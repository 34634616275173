import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { AppNotification } from 'src/app-types';
import {
  DeepTagReportsService,
  DeepTagReportStatus,
  ExportDeepTagReportArguments,
  RegenerateProductsTagsArguments,
} from 'src/services';

export const deepTagsReportProductPageHeaderActions = {
  createToast: createAction<Pick<AppNotification, 'customMessage' | 'type'>>(
    'DeepTagsReportProductPageHeader/CreateToast'
  ),
  updateReport: createAsyncThunk(
    'DeepTagsReportProductPageHeader/UpdateReport',
    async (
      {
        shopId,
        deepTagReportId,
        status,
        customName,
      }: {
        shopId: number;
        deepTagReportId: string;
        status?: DeepTagReportStatus;
        customName?: string;
      },
      { rejectWithValue }
    ) => {
      try {
        const updatedReport = await new DeepTagReportsService().updateReport({
          shopId,
          deepTagReportId,
          status,
          customName,
        });

        return updatedReport;
      } catch (error) {
        console.error(error);
        return rejectWithValue({ shopId, deepTagReportId, status, error });
      }
    }
  ),

  exportReportToFile: createAsyncThunk(
    'DeepTagsReportProductPageHeader/exportReportToFile',
    async (
      {
        shopId,
        reportId,
        customExportLocale,
        regenerateTitles,
        regenerateDescriptions,
        regenerateThematics,
      }: ExportDeepTagReportArguments,
      { rejectWithValue }
    ) => {
      try {
        const updatedReport = await new DeepTagReportsService().exportReportToFile({
          shopId,
          reportId,
          customExportLocale,
          regenerateTitles,
          regenerateDescriptions,
          regenerateThematics,
        });

        return updatedReport;
      } catch (error) {
        console.error(error);
        return rejectWithValue({ shopId, deepTagReportId: reportId, error });
      }
    }
  ),

  regenerateProductsTags: createAsyncThunk(
    'DeepTagsReportProductPageHeader/regenerateProductsTags',
    async (
      { shopId, reportId, locale, filters, data }: RegenerateProductsTagsArguments,
      { rejectWithValue }
    ) => {
      try {
        const updatedReport = await new DeepTagReportsService().regenerateProductsTags({
          shopId,
          reportId,
          locale,
          filters,
          data,
        });

        return updatedReport;
      } catch (error) {
        return rejectWithValue({ shopId, deepTagReportId: reportId, error });
      }
    }
  ),

  notification: createAction<Pick<AppNotification, 'customMessage' | 'type'>>(
    'DeepTagsReportProductPageHeader/CreateToast'
  ),

  exportReportToSFTP: createAsyncThunk(
    'DeepTagsReportProductPageHeader/exportReportToSFTP',
    async (
      {
        shopId,
        reportId,
        regenerateTitles,
        regenerateDescriptions,
        regenerateThematics,
      }: ExportDeepTagReportArguments,
      { rejectWithValue }
    ) => {
      try {
        const updatedReport = await new DeepTagReportsService().exportReportToSFTP({
          shopId,
          reportId,
          regenerateTitles,
          regenerateDescriptions,
          regenerateThematics,
        });

        return updatedReport;
      } catch (error) {
        console.error(error);
        return rejectWithValue({ shopId, deepTagReportId: reportId, error });
      }
    }
  ),

  navigateTo: createAction<{ navigateTo: string }>('DeepTagsReportProductPageHeader/navigateTo'),
};
