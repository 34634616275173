import { useCallback, useEffect, useRef, useState } from 'react';
import { useAnimationFrame } from './useAnimationFrame';

interface UseAnimateNumberProps {
  totalSteps?: number;
  value: number;
  initialValue?: number;
  decimals?: number;
}

function format({ value, decimals }: { value: number; decimals: number }): number {
  return (value.toFixed(decimals) as unknown as number) * 1;
}

export function easeInOut(input: number): number {
  return input * input * (3.0 - 2.0 * input);
}

export const useAnimateNumber = ({
  totalSteps = 1,
  value,
  initialValue = 0,
  decimals = 0,
}: UseAnimateNumberProps): number => {
  const [renderValue, setRenderValue] = useState(initialValue);

  const targetValueRef = useRef(value);

  const totalStepsRef = useRef(totalSteps);

  const currentValueRef = useRef(initialValue);

  const stepsCounterRef = useRef(0);

  const callback = useCallback(() => {
    if (currentValueRef.current === targetValueRef.current) {
      stepsCounterRef.current = 0;
      return false;
    }

    if (stepsCounterRef.current >= totalStepsRef.current) {
      return true;
    }

    stepsCounterRef.current += 1;

    const easyInOutFactor = easeInOut(stepsCounterRef.current / totalSteps);

    let nextValueToRender;
    if (targetValueRef.current > currentValueRef.current) {
      const nextValueRaw =
        currentValueRef.current +
        (targetValueRef.current - currentValueRef.current) * easyInOutFactor;

      nextValueToRender = format({ value: nextValueRaw, decimals });
    } else {
      const nextValueRaw =
        currentValueRef.current -
        (currentValueRef.current - targetValueRef.current) * easyInOutFactor;

      nextValueToRender = format({ value: currentValueRef.current - nextValueRaw, decimals });
    }

    if (nextValueToRender === currentValueRef.current) {
      return true;
    }

    setRenderValue(nextValueToRender);
    currentValueRef.current = nextValueToRender;
    return true;
  }, []);

  const { start } = useAnimationFrame(callback);

  useEffect(() => {
    if (value !== targetValueRef.current) {
      start();
      targetValueRef.current = value;
    }
  }, [value]);

  useEffect(() => {
    totalStepsRef.current = Math.max(totalSteps, 1);
    stepsCounterRef.current = 0;
    currentValueRef.current = targetValueRef.current;
  }, [totalSteps]);

  return renderValue;
};
