import React from 'react';
import { FileUploadProps } from '../types';
import { FileCard } from './FileCard';

type FileCardsProps = Omit<
  FileUploadProps,
  'accept' | 'maxFiles' | 'maxSizeMb' | 'className' | 'children' | 'onError'
>;

export function FileCards({
  disabled,
  fileCardIconName,
  onChange,
  selectedFiles,
}: FileCardsProps): JSX.Element {
  return (
    <div>
      {selectedFiles?.map(file => (
        <FileCard
          key={file.name}
          disabled={disabled}
          fileCardIconName={fileCardIconName}
          fileName={file.name}
          onCloseClick={() => {
            const newFilesState = selectedFiles.filter(f => f.name !== file.name);
            onChange(newFilesState);
          }}
        />
      ))}
    </div>
  );
}
