import styled from '@emotion/styled';
import { Button } from '../Button';

export const PopoverStyled = styled.div`
  width: 600px;
  padding: 24px 24px 24px 24px;
`;

export const TriggerButtonStyled = styled(Button)`
  .MuiButton-endIcon {
    margin-left: 3px;

    path {
      stroke: ${({ theme }) => theme.palette.common.black};
    }
  }
`;
