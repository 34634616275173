import React from 'react';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog';
import { Button } from 'src/components-dummy/Button';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

interface DeleteThematicTagsModalProps {
  onCancel: () => void;
  onDeleteClick: () => void;
  tagsCount: number;
}

export const DeleteThematicTagsModal = ({
  onCancel,
  onDeleteClick,
  tagsCount,
}: DeleteThematicTagsModalProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onCancel}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Delete tags</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        <Typography type={TypographyType.Body} variant={TypographyVariant.LargeMedium}>
          Are you sure you want to delete the {tagsCount} selected
          {tagsCount === 1 ? ' tag' : ' tags'}?
        </Typography>
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onDeleteClick}>
            Delete {tagsCount === 1 ? ' tag' : ' tags'}
          </Button>
          <Button variant='outlined' onClick={onCancel}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
