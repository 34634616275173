import { useCallback, useEffect } from 'react';
import {
  ClearListener,
  DataFieldUpdateMessage,
  MerchandisingRuleDeleteEvent,
  MerchandisingRulesBulkUpdateEvent,
  ShopVersionDraftUpdateEvent,
  dataFieldsWebSocketService,
  merchandisingRulesWebSocketService,
  shopVersionWebSocketService,
} from 'src/services';
import { Dispatch } from 'src/components-bl/types';
import { merchandisingRulesListPageActions } from './MerchandisingRulesListPage.actions';

export const useUpdateMerchandisingRulesList = ({
  shopId,
  dispatch,
  variantId,
  entityId,
  refreshList,
}: {
  dispatch: Dispatch;
  shopId: number;
  variantId: string | undefined;
  entityId: string | undefined;
  refreshList: () => void;
}): void => {
  const fetchOverview = useCallback(() => {
    dispatch(
      merchandisingRulesListPageActions.getRulesOverview({
        shopId,
        variantId,
        entityId,
      })
    );
  }, [dispatch, shopId, variantId, entityId]);

  const onRuleUpdate = useCallback(
    (payload: MerchandisingRulesBulkUpdateEvent) => {
      if (shopId === payload.data.shopId) {
        if (payload.data.variantId === variantId) {
          fetchOverview();
          refreshList();
        }
      }
    },
    [refreshList, shopId, variantId, fetchOverview]
  );

  const onRuleDelete = useCallback(
    (payload: MerchandisingRuleDeleteEvent) => {
      if (shopId === payload.data.shopId) {
        if (payload.data.variantId === variantId) {
          fetchOverview();
          refreshList();
        }
      }
    },
    [refreshList, shopId, variantId, fetchOverview]
  );

  const onDraftUpdate = useCallback(
    (payload: ShopVersionDraftUpdateEvent) => {
      if (payload.data.shopId === shopId) {
        refreshList();
      }
    },
    [shopId, refreshList]
  );

  const onDataFieldsUpdate = useCallback(
    (payload: DataFieldUpdateMessage) => {
      if (payload.data.shopId === shopId) {
        refreshList();
      }
    },
    [shopId, refreshList]
  );

  useEffect(() => {
    const resetListeners: ClearListener[] = [];

    resetListeners.push(
      merchandisingRulesWebSocketService.subscribeToRuleCreateEvents(onRuleUpdate)
    );

    resetListeners.push(
      merchandisingRulesWebSocketService.subscribeToRuleUpdateEvents(onRuleUpdate)
    );

    resetListeners.push(
      merchandisingRulesWebSocketService.subscribeToRuleDeleteEvents(onRuleDelete)
    );

    resetListeners.push(
      merchandisingRulesWebSocketService.subscribeToRulesBulkUpdateEvents(onRuleUpdate)
    );

    resetListeners.push(shopVersionWebSocketService.subscribeToDraftUpdateEvents(onDraftUpdate));

    resetListeners.push(
      dataFieldsWebSocketService.subscribeToDataFieldUpdateEvents(onDataFieldsUpdate)
    );

    return () => {
      for (const clearListener of resetListeners) {
        clearListener();
      }
    };
  }, [onRuleUpdate, onRuleDelete, onDraftUpdate, onDataFieldsUpdate]);
};
