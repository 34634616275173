import React, { useCallback, useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { Dispatch } from 'src/components-bl/types/dispatch';
import { BackDropWithLoader } from 'src/components-dummy/Backdrop/BackdropWithLoader';
import { TableV2, TableRow } from 'src/components-dummy/TableV2';
import {
  combineActionAndWeightText,
  getProductName,
} from 'src/components-bl/MerchandisingRules/merchandisingRules.helpers';
import { format } from 'date-fns';
import { RouteSettings } from 'src/app-routes';
import { MerchandisingRuleWithExtraIndications } from '../../MerchandisingRulesFilters';
import { MerchandisingRulesListActions } from '../Actions/MerchandisingRulesList.actions';
import { tableColumns } from '../MerchandisingRulesList.config';
import { IMerchandisingRuleTableBodyRow } from '../types';
import { RuleItemRowActionsMenu } from './RuleItemRowActionsMenu';
import { DeleteRuleConfirmationDialog } from './DeleteRuleConfirmationDialog';
import { RuleActiveCell } from './RuleActiveCell';
import { ButtonDescriptionStyled } from './RuleActiveCell.styles';

const getFormattedCurrentTime = () => {
  return format(new Date(), 'HH:mm:ss');
};

interface RuleItemRowProps<T extends Record<string, any>> {
  row: TableRow<T>;
  merchandisingRulesEditPageRoute?: RouteSettings;
  itemOriginalData: MerchandisingRuleWithExtraIndications;
  onRuleSelection?: (ruleId: string) => void;
  navigateToRuleCreationPage: () => void;
  shopId: number;
  selectedVariantId?: string;
  dispatch: Dispatch;
  isReadOnly?: boolean;
}

export const RuleItemRow = ({
  row,
  merchandisingRulesEditPageRoute,
  onRuleSelection,
  dispatch,
  shopId,
  selectedVariantId,
  itemOriginalData,
  isReadOnly,
  navigateToRuleCreationPage,
}: RuleItemRowProps<IMerchandisingRuleTableBodyRow>): JSX.Element => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | undefined>(undefined);
  const [isInProcess, setIsInProcess] = useState<boolean>(false);
  const [isActive, setIsActive] = useState(itemOriginalData.active);
  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState<boolean>(false);

  const menuOpened = !!menuAnchorEl;

  const onOptionClick = () => setMenuAnchorEl(undefined);

  const onTriggerClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const onOpenDeleteConfirmation = useCallback(
    event => {
      event.stopPropagation();
      onOptionClick();

      setShowDeleteConfirmationDialog(true);
    },
    [itemOriginalData.id]
  );

  const onCloseDeleteDialog = useCallback(() => {
    setShowDeleteConfirmationDialog(false);
  }, []);

  const onMenuClose = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(undefined);
  }, []);

  const onEditClick = useCallback(
    event => {
      event.stopPropagation();
      onOptionClick();

      /**
       * Callback function instead of "navigateToRule"
       */
      if (onRuleSelection) {
        onRuleSelection(itemOriginalData.id);
      } else if (merchandisingRulesEditPageRoute && shopId) {
        dispatch(
          MerchandisingRulesListActions.navigateToRule({
            navigateTo: generatePath(merchandisingRulesEditPageRoute.path, {
              ruleId: row.original.id,
              shopId,
            }),
          })
        );
      }
    },
    [itemOriginalData.id, shopId]
  );

  const onDuplicateClick = useCallback(
    async event => {
      event.stopPropagation();
      onOptionClick();

      const ruleDataToDuplicate = {
        ...itemOriginalData,
        name: `${itemOriginalData.name} (${getFormattedCurrentTime()})`,
      };

      dispatch(
        MerchandisingRulesListActions.setDuplicateRuleDraft({
          rule: ruleDataToDuplicate,
        })
      );

      navigateToRuleCreationPage();

      setIsInProcess(false);
    },
    [itemOriginalData, isActive]
  );

  const onDelete = useCallback(
    async event => {
      event.stopPropagation();
      onOptionClick();
      setShowDeleteConfirmationDialog(false);

      setIsInProcess(true);

      await dispatch(
        MerchandisingRulesListActions.deleteRule({
          shopId,
          ruleId: itemOriginalData.id,
          variantId: selectedVariantId,
        })
      );
    },
    [itemOriginalData.id]
  );

  const onActiveChange = useCallback(
    async (isChecked: boolean) => {
      const ruleDataToUpdate = {
        ...itemOriginalData,
        active: isChecked,
      };

      setIsActive(isChecked);

      const response = await dispatch(
        MerchandisingRulesListActions.updateRule({
          shopId,
          rule: ruleDataToUpdate,
          variantId: selectedVariantId,
        })
      );

      if ((response as any)?.error) {
        setIsActive(!isChecked);
      }
    },
    [row, isActive]
  );

  const renderRowDataContent = useCallback(
    (cell: any) => {
      const propName = cell.column.id;
      const cellValue = cell.value;

      switch (propName) {
        case tableColumns.active.accessor: {
          return (
            <RuleActiveCell
              isPublished={itemOriginalData.isPublished}
              isUsingInvalidDataField={itemOriginalData.isUsingInvalidDataField}
              isUsingInvalidRegion={!itemOriginalData.hasOnlyValidRegions}
              isInvalidEmptyRegions={itemOriginalData.isInvalidEmptyRegions}
              onActiveChange={onActiveChange}
              isActive={isActive}
              disabled={isReadOnly}
            />
          );
        }
        case tableColumns.rowActions.accessor:
          return (
            <RuleItemRowActionsMenu
              isMenuOpened={!!menuOpened}
              menuAnchorElement={menuAnchorEl}
              onTriggerClick={onTriggerClick}
              onMenuClose={onMenuClose}
              onEditClick={onEditClick}
              onDuplicateClick={
                itemOriginalData.isUsingInvalidDataField || !itemOriginalData.hasOnlyValidRegions
                  ? undefined
                  : onDuplicateClick
              }
              onDeleteClick={onOpenDeleteConfirmation}
              isReadOnly={isReadOnly}
            />
          );
        case tableColumns.type.accessor: {
          const typeText = combineActionAndWeightText({
            action: cellValue,
            weight: itemOriginalData.weight,
          });

          return <TableV2.BodyRowCellText key={cell.column.id}>{typeText}</TableV2.BodyRowCellText>;
        }
        case tableColumns.product.accessor: {
          const productName = getProductName(cellValue, itemOriginalData.entityId);
          return (
            <TableV2.BodyRowCellText key={cell.column.id}>{productName}</TableV2.BodyRowCellText>
          );
        }
        case tableColumns.title.accessor: {
          return (
            <ButtonDescriptionStyled variant='primary' onClick={onEditClick}>
              <TableV2.BodyRowCellText key={cell.column.id}>{cellValue}</TableV2.BodyRowCellText>
            </ButtonDescriptionStyled>
          );
        }
        default:
          return (
            <TableV2.BodyRowCellText key={cell.column.id}>{cellValue}</TableV2.BodyRowCellText>
          );
      }
    },
    [row, menuOpened, isActive]
  );

  useEffect(() => {
    if (isActive !== itemOriginalData.active) {
      setIsActive(itemOriginalData.active);
    }
  }, [itemOriginalData.active]);

  return (
    <>
      {isInProcess && <BackDropWithLoader />}
      {showDeleteConfirmationDialog && (
        <DeleteRuleConfirmationDialog
          onCloseDeleteDialog={onCloseDeleteDialog}
          onDelete={onDelete}
        />
      )}
      <TableV2.BodyRow {...row.getRowProps()} key={row.original.id}>
        {row.cells.map(cell => {
          return (
            <TableV2.BodyRowCell
              {...cell.getCellProps()}
              key={cell.column.id}
              isResizable={cell.column.id !== tableColumns.active.accessor}
            >
              {renderRowDataContent(cell)}
            </TableV2.BodyRowCell>
          );
        })}
      </TableV2.BodyRow>
    </>
  );
};
