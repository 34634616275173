import { createAsyncThunk } from '@reduxjs/toolkit';
import { lexiconService } from 'src/services';
import { requestWasCancelledByUser } from 'src/services/src/service/httpService';
import { ApiError } from 'src/services/src/service/httpService-types';

export const exportLexiconModalActions = {
  exportLexicon: createAsyncThunk(
    'ExportLexiconModal/ExportLexicon',
    async (
      {
        shopId,
        locale,
        requestId,
        cancellationSignal,
      }: { shopId: number; locale: string; requestId: string; cancellationSignal?: AbortSignal },
      { rejectWithValue }
    ) => {
      try {
        await lexiconService.exportLexicon({
          shopId,
          locale,
          requestId,
          cancellationSignal,
        });

        return undefined;
      } catch (error) {
        return requestWasCancelledByUser(error as ApiError)
          ? undefined
          : rejectWithValue({ error, requestId });
      }
    }
  ),
};
