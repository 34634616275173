import {
  ToastActionType,
  ToastEnqueueAction,
  ToastDequeueAction,
  ToastEnqueueActionPayload,
  ToastRemoveAction,
  ToastRemoveActionPayload,
  ToastClearAction,
} from './types';

export const toastActions = {
  enqueue: ({ toast }: ToastEnqueueActionPayload): ToastEnqueueAction => {
    return {
      type: ToastActionType.Enqueue,
      payload: toast,
    };
  },
  dequeue: (): ToastDequeueAction => {
    return {
      type: ToastActionType.Dequeue,
      payload: undefined,
    };
  },
  remove: ({ toast }: ToastRemoveActionPayload): ToastRemoveAction => {
    return {
      type: ToastActionType.Remove,
      payload: toast,
    };
  },
  clear: (): ToastClearAction => {
    return {
      type: ToastActionType.Clear,
      payload: undefined,
    };
  },
};
