import React from 'react';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog';
import { Button } from 'src/components-dummy/Button';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';

interface RestoreTagsModalModalProps {
  onCancel: () => void;
  onRestoreClick: () => void;
  tagsCount: number;
}

export const RestoreTagsModal = ({
  onCancel,
  onRestoreClick,
  tagsCount,
}: RestoreTagsModalModalProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onCancel}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Restore tags</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        <Typography type={TypographyType.Body} variant={TypographyVariant.LargeMedium}>
          Are you sure you want to restore the {tagsCount} selected
          {tagsCount === 1 ? ' tag' : ' tags'}?
        </Typography>
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <Button variant='primary' onClick={onRestoreClick}>
          Restore {tagsCount === 1 ? ' tag' : ' tags'}
        </Button>
        <Button variant='tertiary' onClick={onCancel}>
          Close
        </Button>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
