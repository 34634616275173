import { useCallback, useEffect } from 'react';
import {
  notificationsWebSocketService,
  NotificationViewedEvent,
  NotificationCreateEvent,
  NotificationDeleteEvent,
  NotificationUnreadTotalCountEvent,
} from 'src/services';
import { Dispatch } from 'src/components-bl';
import { useNotificationsWSActions } from './useNotificationsWS.actions';

function shouldAcceptNotification({
  targetShopId,
  currentShopId,
}: {
  targetShopId: number | undefined;
  currentShopId: number | undefined;
}): boolean {
  if (!targetShopId) {
    return true;
  }
  return targetShopId === currentShopId;
}

export const useNotificationsWS = ({
  dispatch,
  shopId,
}: {
  dispatch: Dispatch;
  shopId: number | undefined;
}): void => {
  const onNotificationCreate = useCallback(
    (payload: NotificationCreateEvent) => {
      if (
        shouldAcceptNotification({
          targetShopId: payload.data.shopId,
          currentShopId: shopId,
        })
      ) {
        dispatch(useNotificationsWSActions.create({ notification: payload.data }));
      }
    },
    [dispatch, shopId]
  );

  const onNotificationView = useCallback(
    (payload: NotificationViewedEvent) => {
      dispatch(useNotificationsWSActions.view({ notificationIds: payload.data.notificationIds }));
    },
    [dispatch]
  );

  const onNotificationDelete = useCallback(
    (payload: NotificationDeleteEvent) => {
      dispatch(useNotificationsWSActions.delete({ notificationIds: payload.data.notificationIds }));
    },
    [dispatch]
  );

  const onUnreadCountTotalEvent = useCallback(
    (payload: NotificationUnreadTotalCountEvent) => {
      dispatch(useNotificationsWSActions.unreadCount({ unreadCount: payload.data.unreadCount }));
    },
    [dispatch]
  );

  useEffect(() => {
    notificationsWebSocketService.subscribeToNotificationCreateEvents(onNotificationCreate);
    notificationsWebSocketService.subscribeToNotificationViewEvents(onNotificationView);
    notificationsWebSocketService.subscribeToNotificationDeleteEvents(onNotificationDelete);
    notificationsWebSocketService.subscribeToUnreadCountEvents(onUnreadCountTotalEvent);

    return () => {
      notificationsWebSocketService.clearListeners();
    };
  }, [
    shopId,
    onNotificationCreate,
    onNotificationView,
    onNotificationDelete,
    onUnreadCountTotalEvent,
  ]);
};
