import React from 'react';

export const useScrollUtils = () => {
  const lockScroll = React.useCallback((containerName: string) => {
    const container: HTMLDivElement | null = document?.querySelector(containerName);
    if (container && container.style) {
      container.style.overflow = 'hidden';
    }
  }, []);

  const unlockScroll = React.useCallback((containerName: string) => {
    const container: HTMLDivElement | null = document?.querySelector(containerName);
    if (container && container.style) {
      container.style.overflow = 'auto';
    }
  }, []);

  return {
    lockScroll,
    unlockScroll,
  };
};
