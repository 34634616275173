import { Dispatch } from '../../../../../types';
import { lexiconRuleFormActions } from '../LexiconRuleForm.actions';

export function fetchAttributesByCategory({
  categories,
  dispatch,
  locale,
  shopId,
}: {
  categories: string[];
  dispatch: Dispatch;
  locale: string;
  shopId: number;
}): Promise<string[][]> {
  return Promise.all(
    categories.map(async category => {
      try {
        const { availableValues } = await (
          dispatch(
            lexiconRuleFormActions.getAvailableKeys({
              shopId,
              locale,
              searchTerm: '',
              tagField: 'attribute',
              categories: [category],
            })
          ) as any
        ).unwrap();
        return availableValues;
      } catch (error) {
        console.error(error);
        return [];
      }
    })
  );
}
