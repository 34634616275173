import {
  NestedMultiSelectOption,
  NestedMultiSelectOptionsPerParent,
  NestedSelectedOptionsPerParent,
} from './types';

export function mapNestedMultiSelectOptionsToOptionsPerParent(
  options: NestedMultiSelectOption[]
): NestedMultiSelectOptionsPerParent {
  return options.reduce(
    (
      optionsPerParent: NestedMultiSelectOptionsPerParent,
      currentOption: NestedMultiSelectOption
    ) => {
      const { parentTitle, text, value } = currentOption;

      return {
        ...optionsPerParent,
        [parentTitle]: [...(optionsPerParent[parentTitle] || []), { text, value }],
      };
    },
    {}
  );
}

export function mapNestedSelectedOptionsToSelectedOptionsPerParent(
  selectedOptions: NestedMultiSelectOption[]
): NestedSelectedOptionsPerParent {
  return selectedOptions.reduce(
    (
      selectedOptionsPerParent: NestedSelectedOptionsPerParent,
      currentOption: NestedMultiSelectOption
    ) => {
      const { parentTitle, value } = currentOption;

      return {
        ...selectedOptionsPerParent,
        [parentTitle]: [...(selectedOptionsPerParent[parentTitle] || []), value],
      };
    },
    {}
  );
}

export function mapParentTitlesToConsistentValue<T>({
  parentTitles,
  value,
}: {
  parentTitles: string[];
  value: T;
}): { [key: string]: T } {
  return parentTitles.reduce((mappedTitles, currentParentTitle) => {
    return {
      ...mappedTitles,
      [currentParentTitle]: value,
    };
  }, {});
}
