import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Accounts } from 'src/services';
import { TwoFactorAuthenticationConfig } from 'src/services/src/service/types/accounts/account';

const accountService = new Accounts();

export const account2FAActions = {
  updateAccount2Fa: createAsyncThunk(
    'Account2FA/UpdateAccount2FA',
    async (
      {
        accountId,
        twoFactorAuthentication,
      }: { accountId: number; twoFactorAuthentication: TwoFactorAuthenticationConfig },
      { rejectWithValue }
    ) => {
      try {
        const updatedAccount = await accountService.updateAccount({
          accountId,
          data: { twoFactorAuthentication },
        });
        return { account: updatedAccount };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  notifyIsDirty: createAction<{ isDirty: boolean }>('Account2FA/NotifyIsDirty'),
};
