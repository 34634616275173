import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { CardItem, TagsInput, Typography } from 'src/components-dummy';

export const SubTitleStyled = styled(Typography)`
  display: block;
  margin-top: 4px;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const TagsInputStyled = styled(TagsInput)`
  &:hover,
  &:focus,
  &:focus-within {
    border-color: ${({ theme, disabled }) =>
      disabled ? '' : theme.palette.common.black} !important;
  }

  input {
    &::placeholder {
      color: ${({ theme, disabled }) =>
        disabled ? theme.palette.custom['gray-20'] : theme.palette.common.black};
    }
  }

  .tag-label {
    cursor: pointer;

    ${({ disabled }) => (disabled ? '' : 'cursor: pointer;')}

    .MuiChip-label {
      color: ${({ theme, disabled }) =>
        disabled ? theme.palette.custom['gray-20'] : theme.palette.common.black};
    }

    .delete-icon {
      path {
        stroke: ${({ theme }) => theme.palette.common.black};
      }
    }
  }
`;

export const RegionDropdownContentStyled = styled(CardItem)`
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: 8px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  width: 440px;

  .MuiMenuItem-root {
    background-color: unset !important;
  }
`;

export const ruleFormFieldCss = css`
  .syte-popup-trigger {
    width: 100%;
  }
`;
