import { RankingStrategyTypes } from 'src/services';
import { EntityDiffSchema } from '../../types';

type RankingDiffSchema = RankingStrategyTypes.RankingStrategyWeight;

export const rankingDiffSchema: EntityDiffSchema<
  RankingDiffSchema,
  RankingStrategyTypes.RankingStrategyWeight
> = {
  enabled: {
    displayName: 'Enabled',
  },
  order: {
    displayName: 'Order',
    getValue({ entity }) {
      return entity.order === RankingStrategyTypes.RankingOrder.Asc ? 'Low to high' : 'High to low';
    },
  },
};
