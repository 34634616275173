import React from 'react';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

interface ChangePrimaryConfirmationDialogProps {
  onCloseDialog: () => void;
  onApproveSubmit?: () => void;
}

export const ShopDeepTagReportsSettingsToggleConfirmation = ({
  onCloseDialog,
  onApproveSubmit,
}: ChangePrimaryConfirmationDialogProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onCloseDialog}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Hold On</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        The below toggles control if the system will generate a content for this shop automatically
        or not, in the DT report, Tags API, and Tags Editor. Please make sure that before clicking,
        the customer has a business agreement for this.
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onApproveSubmit}>
            Approve
          </Button>
          <Button variant='tertiary' onClick={onCloseDialog}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
