import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetDeepTagReportProductsActionArguments } from 'src/containers/DeepTagReports/components';
import {
  DeepTagReportProductFilters,
  DeepTagReportsGeneralConfigurationService,
  DeepTagReportsService,
  DeepTagsReportsCustomBulkAssignLabels,
  DeepTagsReportsEvenBulkAssignLabels,
  GetDeepTagReportProductsArguments,
} from 'src/services';

const deepTagReportsGeneralConfigurationService = new DeepTagReportsGeneralConfigurationService();

const deepTagReportsService = new DeepTagReportsService();

export const deepTagsReportProductsBulkLabelActions = {
  getLabelsGeneralConfiguration: createAsyncThunk(
    'DeepTagsReportProductsBulkLabelActions/GetGeneralConfigurationLabels',
    async (_, { rejectWithValue }) => {
      try {
        const generalConfigurationLabels =
          await deepTagReportsGeneralConfigurationService.getLabels();
        return { generalConfigurationLabels };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  createLabelsGeneralConfiguration: createAsyncThunk(
    'DeepTagsReportProductsBulkLabelActions/CreateGeneralConfigurationLabels',
    async ({ labelName }: { labelName: string }, { rejectWithValue }) => {
      try {
        const createdLabel = await deepTagReportsGeneralConfigurationService.createLabel(labelName);
        return { createdLabel };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  bulkEvenAssignProductLabels: createAsyncThunk(
    'DeepTagsReportProductsBulkLabelActions/bulkEvenAssignProductLabels',
    async (
      {
        reportId,
        shopId,
        labels,
        filters,
        shouldOnlyIncludeUnlabeled,
      }: {
        shopId: number;
        reportId: string;
        labels: DeepTagsReportsEvenBulkAssignLabels[];
        filters: DeepTagReportProductFilters;
        shouldOnlyIncludeUnlabeled: boolean;
      },
      { rejectWithValue }
    ) => {
      try {
        const generalConfigurationLabels = await deepTagReportsService.bulkEvenAssignProductLabels({
          reportId,
          shopId,
          labels,
          filters,
          shouldOnlyIncludeUnlabeled,
        });

        return { generalConfigurationLabels };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  bulkCustomAssignProductLabels: createAsyncThunk(
    'DeepTagsReportProductsBulkLabelActions/bulkCustomAssignProductLabels',
    async (
      {
        reportId,
        shopId,
        totalProductsPerLabel,
        filters,
        shouldOnlyIncludeUnlabeled,
      }: {
        shopId: number;
        reportId: string;
        totalProductsPerLabel: DeepTagsReportsCustomBulkAssignLabels[];
        filters: DeepTagReportProductFilters;
        shouldOnlyIncludeUnlabeled: boolean;
      },
      { rejectWithValue }
    ) => {
      try {
        const generalConfigurationLabels =
          await deepTagReportsService.bulkCustomAssignProductLabels({
            reportId,
            shopId,
            filters,
            shouldOnlyIncludeUnlabeled,
            totalProductsPerLabel,
          });
        return { generalConfigurationLabels };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getReportProducts: createAsyncThunk(
    'DeepTagsReportProductsBulkLabelActions/GetReportProducts',
    async (
      {
        shopId,
        reportId,
        locale,
        ...filters
      }: GetDeepTagReportProductsActionArguments &
        Pick<GetDeepTagReportProductsArguments, 'skip' | 'limit'> &
        DeepTagReportProductFilters,
      { rejectWithValue }
    ) => {
      try {
        const productsPagination = await deepTagReportsService.getReportProducts({
          shopId,
          locale,
          reportId,
          ...filters,
        });
        return productsPagination;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
