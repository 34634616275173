import React, { useEffect, useCallback } from 'react';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import { MenuItem } from '../MenuItem';
import {
  MultiSelectMenuListStyled,
  EllipsisWithTooltipStyled,
  CheckboxStyled,
} from './MultiSelectMenu.styles';
import {
  MenuItemStyled,
  MenuItemWrapperStyled,
  SelectCaretIconStyled,
} from './NestedMultiSelectMenu.styles';
import { NestedMultiSelectMenuItemProps } from './types';
import { useMultiSelect } from './use-multi-select';
import { AvailableIcons } from '../Icon';

export const NestedMultiSelectMenuItem = ({
  options,
  enableSelectAll,
  selectAllText,
  onChange,
  selectedOptions,
  onSelectAllOptionChange,
  onToggleNestedOptionsVisible,
  isOptionsVisible,
}: NestedMultiSelectMenuItemProps): JSX.Element => {
  const {
    handleToggleAllClick,
    selectAllOptionState,
    selectedAllOption,
    selectedOptionsState,
    handleItemClick,
  } = useMultiSelect({
    options,
    enableSelectAll,
    selectAllText,
    onChange,
    selectedOptions,
  });

  const toggleNestedOptionsVisible = useCallback(() => {
    onToggleNestedOptionsVisible({
      isVisible: !isOptionsVisible,
      parentTitle: selectedAllOption.text,
    });
  }, [isOptionsVisible, onToggleNestedOptionsVisible, selectAllText]);

  useEffect(() => {
    onSelectAllOptionChange({
      isSelected: selectAllOptionState,
      parentTitle: selectedAllOption.text,
    });
  }, [selectAllOptionState, onSelectAllOptionChange, selectAllText]);

  return (
    <MultiSelectMenuListStyled enableScroll={false}>
      {enableSelectAll && (
        <MenuItemWrapperStyled>
          <SelectCaretIconStyled
            name={AvailableIcons.SelectCaret}
            onClick={toggleNestedOptionsVisible}
          />
          <MenuItem onClick={handleToggleAllClick}>
            <CheckboxStyled checked={selectAllOptionState} />
            <EllipsisWithTooltipStyled tooltipText={selectedAllOption.text}>
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                {selectedAllOption.text}
              </Typography>
            </EllipsisWithTooltipStyled>
          </MenuItem>
        </MenuItemWrapperStyled>
      )}
      {isOptionsVisible &&
        options.map(option => {
          const isSelected = selectedOptionsState.has(option.value);
          return (
            <MenuItemStyled key={option.value} onClick={() => handleItemClick(option.value)}>
              <CheckboxStyled checked={isSelected} />
              <EllipsisWithTooltipStyled tooltipText={option.text}>
                <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                  {option.text}
                </Typography>
              </EllipsisWithTooltipStyled>
            </MenuItemStyled>
          );
        })}
    </MultiSelectMenuListStyled>
  );
};
