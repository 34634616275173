import styled from '@emotion/styled';
import { Icon } from 'src/components-dummy';

export const ExpandableSectionTriggerStyled = styled.div<{ isDisabled: boolean }>`
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.palette.custom['gray-30'] : theme.palette.common.black};
  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.palette.custom['gray-80'] : theme.palette.common.white};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  margin-top: 20px;

  ${({ isDisabled }) => (isDisabled ? `user-select: none;` : '')}
`;

export const TitleContainerStyled = styled.div`
  display: flex;
  gap: 5px;
`;

export const IconStyled = styled(Icon)<{ rotateDegrees: number }>`
  margin-left: auto;
  transform: rotate(${({ rotateDegrees }) => rotateDegrees}deg);
  transition: transform 0.3s;
`;
