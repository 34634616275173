import {
  CollectionsListActionPayload,
  CollectionsListActionTypes,
  CollectionsListErrorActionPayload,
  CollectionsListGetCollectionsErrorAction,
  CollectionsListGetCollectionsRequestAction,
  CollectionsListGetCollectionsSuccessAction,
  CollectionsListUpdateCollectionsErrorAction,
  UpdateCollectionsListErrorActionPayload,
} from './types';
import { Dispatch } from '../../../types';
import { collectionsService } from '../../../../services/src/service/collections';

const collectionsListActions = {
  getCollectionsRequest(
    payload: CollectionsListActionPayload
  ): CollectionsListGetCollectionsRequestAction {
    return {
      type: CollectionsListActionTypes.GetCollectionsRequest,
      payload,
    };
  },

  getCollectionsSuccess(
    payload: CollectionsListActionPayload
  ): CollectionsListGetCollectionsSuccessAction {
    return {
      type: CollectionsListActionTypes.GetCollectionsSuccess,
      payload,
    };
  },

  getCollectionsError(
    payload: CollectionsListErrorActionPayload
  ): CollectionsListGetCollectionsErrorAction {
    return {
      type: CollectionsListActionTypes.GetCollectionsError,
      payload,
    };
  },

  updateCollectionsError(
    payload: UpdateCollectionsListErrorActionPayload
  ): CollectionsListUpdateCollectionsErrorAction {
    return {
      type: CollectionsListActionTypes.UpdateCollectionsError,
      payload,
    };
  },
};

export const collectionsListActionMethods = {
  getCollectionsList(payload: CollectionsListActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(collectionsListActions.getCollectionsRequest(payload));
        const collections = await collectionsService.getCollections({
          shopId: payload.shopId,
          variantId: payload?.variantId,
        });
        dispatch(
          collectionsListActions.getCollectionsSuccess({
            ...payload,
            collections,
          })
        );
      } catch (error) {
        dispatch(collectionsListActions.getCollectionsError({ error }));
      }
    };
  },

  updateCollectionsError(
    payload: UpdateCollectionsListErrorActionPayload
  ): CollectionsListUpdateCollectionsErrorAction {
    return collectionsListActions.updateCollectionsError(payload);
  },
};
