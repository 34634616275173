import { RecentlyViewedSettingsTypes } from 'src/services';
import { DomainForRendering } from '../../types';
import { recentlyViewedDiffSchema } from './recently-viewed-diff-schema';

export const recentlyViewedSubDomainGroup: DomainForRendering<RecentlyViewedSettingsTypes.RecentlyViewedSettings> =
  {
    getCardTitle: () => {
      return 'General Settings';
    },
    fieldsSchema: recentlyViewedDiffSchema,
  };
