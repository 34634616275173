import styled from '@emotion/styled';
import { ConfirmationDialog } from 'src/components-dummy';

export const ConfirmationDialogStyled = styled(ConfirmationDialog)`
  ${ConfirmationDialog.Content} {
    padding-bottom: 8px;
    overflow-y: auto !important;
    max-height: 580px;
  }
`;
