import styled from '@emotion/styled';
import { Button } from 'src/components-dummy';

export const LexiconBulkActionsRowStyles = styled.div<{ show: boolean }>`
  display: flex;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  justify-content: space-between;
  height: ${({ show }) => (show ? '72px' : '0px')};
  padding: ${({ show }) => (show ? '16px' : 'initial')};
  transition: height 0.1s;
  border-bottom: 1px solid ${({ theme }) => theme.palette.custom['gray-70']};
`;

export const VerticalSeparator = styled.div`
  margin: 0 16px;
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.custom['gray-30']};
`;

export const SelectedCountButtonStyled = styled(Button)`
  .syte-basic-icon {
    width: 12px;
  }
  path {
    stroke: ${({ theme }) => theme.palette.common.white} !important;
  }
`;

// eslint-disable-next-line prettier/prettier
export const ActionButtonStyled = styled(Button)<{ marginRight: number }>`
  margin-right: ${({ marginRight }) => marginRight}px;
`;
