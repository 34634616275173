import { useCallback } from 'react';
import { UseFieldDropdownProps, UseFieldDropdownReturnType } from './types';
import { useMappedSelectValues } from '../../../../hooks/useMappedSelectValues';
import { getFieldFromDataFields } from '../helpers/getFieldFromDataFields';

export const useFieldDropdown = ({
  data,
  onChange,
  dataFields,
}: UseFieldDropdownProps): UseFieldDropdownReturnType => {
  const dataFieldsDropdownOptions = useMappedSelectValues(dataFields ?? []);

  const onFieldChange = useCallback(
    (fieldName?: string) => {
      const field = getFieldFromDataFields(fieldName, dataFields);
      onChange({ ...data, ...(field && { field: field.name, operator: undefined, values: [] }) });
    },
    [dataFields, onChange]
  );

  return {
    dataFieldsDropdownOptions,
    onFieldChange,
  };
};
