import styled from '@emotion/styled';
import { Page } from 'src/components-dummy';

export const ShopSettingsPageStyled = styled(Page)`
  .main {
    overflow-y: auto;
  }

  .syte-page-content {
    overflow: initial;
  }
`;

export const DataFieldsSubtitleStyled = styled(Page.SubTitle)`
  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  a {
    color: ${props => props.theme.palette.custom['primary-main']};
  }
`;
