/* eslint-disable no-param-reassign */
import { sortBy } from 'lodash';
import { RuleDraftCondition } from '../../../MerchandisingRuleForm.config';

function renumberLowerPinnedPositions({
  movedConditionTargetIndex,
  rearrangedConditions,
}: {
  movedConditionTargetIndex: number;
  rearrangedConditions: RuleDraftCondition[];
}): void {
  for (let i = movedConditionTargetIndex + 1; i < rearrangedConditions.length; i++) {
    if (
      rearrangedConditions[i].position &&
      rearrangedConditions[i].position === rearrangedConditions[i - 1]?.position
    ) {
      (rearrangedConditions[i].position as number)++;
    } else {
      return;
    }
  }
}

function renumberHigherPinnedPositions({
  movedConditionTargetIndex,
  rearrangedConditions,
}: {
  movedConditionTargetIndex: number;
  rearrangedConditions: RuleDraftCondition[];
}): void {
  for (let i = movedConditionTargetIndex - 1; i >= 0; i--) {
    if (
      rearrangedConditions[i].position &&
      rearrangedConditions[i].position === rearrangedConditions[i + 1]?.position
    ) {
      (rearrangedConditions[i].position as number)--;
    } else {
      return;
    }
  }
}

export function renumberPinnedPositions({
  movedConditionId,
  previousConditions,
  rearrangedConditions,
  maxItemsLimit,
}: {
  maxItemsLimit: number;
  movedConditionId?: number | string;
  previousConditions: RuleDraftCondition[];
  rearrangedConditions: RuleDraftCondition[];
}): RuleDraftCondition[] {
  if (!movedConditionId) {
    return previousConditions;
  }

  const movedConditionSourceIndex = previousConditions.findIndex(
    condition => condition.tempId === movedConditionId
  );
  const movedConditionTargetIndex = rearrangedConditions.findIndex(
    condition => condition.tempId === movedConditionId
  );

  const movedCondition = rearrangedConditions[movedConditionTargetIndex];
  const replacedCondition = previousConditions[movedConditionTargetIndex];

  const isMovedUpwards = movedConditionSourceIndex > movedConditionTargetIndex;

  let skipRenumber = false;

  if (isMovedUpwards) {
    skipRenumber = Boolean(
      movedCondition.position &&
        replacedCondition.position &&
        movedCondition.position < replacedCondition.position
    );
  } else {
    skipRenumber = Boolean(
      movedCondition.position &&
        replacedCondition.position &&
        movedCondition.position > replacedCondition.position
    );
  }

  if (skipRenumber) {
    return rearrangedConditions;
  }

  movedCondition.position = replacedCondition.position;

  if (isMovedUpwards) {
    renumberLowerPinnedPositions({ movedConditionTargetIndex, rearrangedConditions });
  } else {
    renumberHigherPinnedPositions({ movedConditionTargetIndex, rearrangedConditions });
  }

  rearrangedConditions.splice(maxItemsLimit);

  return rearrangedConditions;
}

function findIndexOfHigherPosition({
  conditions,
  position,
}: {
  conditions: RuleDraftCondition[];
  position: number;
}): number {
  let index = conditions.length + 1;

  for (let i = conditions.length - 1; i >= 0; i--) {
    const currentPosition = conditions[i].position;

    if (currentPosition !== undefined && currentPosition >= position) {
      index = i;
    } else {
      break;
    }
  }

  return index;
}

export function findPositionIndexToInsertAt({
  fallbackIndex,
  conditions,
  position,
  matchInclusive,
}: {
  conditions: RuleDraftCondition[];
  position: number;
  matchInclusive: boolean;
  fallbackIndex: number;
}): number {
  for (let i = 0; i < conditions.length; i++) {
    const currentPosition = conditions[i].position;

    const isAMatch = matchInclusive
      ? currentPosition !== undefined && currentPosition >= position
      : currentPosition !== undefined && currentPosition > position;

    if (isAMatch) {
      return i;
    }
  }

  return fallbackIndex;
}

function increasePositionValues({
  conditions,
  lengthLimit,
  basePositionValue,
}: {
  conditions: RuleDraftCondition[];
  lengthLimit: number;
  basePositionValue: number;
}): RuleDraftCondition[] {
  const resultConditions = [];
  const takeConditionsUpToIndex = Math.min(conditions.length, lengthLimit);

  let highestPinValue = basePositionValue;

  for (let i = 0; i < takeConditionsUpToIndex; i++) {
    const condition = conditions[i];

    if (condition.position && condition.position <= highestPinValue) {
      highestPinValue += 1;

      condition.position = highestPinValue;
    }

    resultConditions.push({ ...condition });
  }

  return resultConditions;
}

export function insertAndReorderPins({
  pinsToInsert,
  previousConditions,
  maxLimitOfItems,
}: {
  pinsToInsert: RuleDraftCondition[];
  previousConditions: RuleDraftCondition[];
  maxLimitOfItems: number;
}): RuleDraftCondition[] {
  if (previousConditions.length === 0) {
    return pinsToInsert;
  }

  const originalConditionsSorted = sortBy(previousConditions, ({ position }) => position);

  const firstPositionToInsert = pinsToInsert[0].position;

  if (firstPositionToInsert === undefined) {
    return previousConditions;
  }

  const insertAtIndex = findIndexOfHigherPosition({
    conditions: originalConditionsSorted,
    position: firstPositionToInsert,
  });

  const secondConditionsPart = originalConditionsSorted.splice(insertAtIndex);

  const concatenatedPins = originalConditionsSorted.concat(pinsToInsert);

  if (concatenatedPins.length > maxLimitOfItems) {
    concatenatedPins.splice(maxLimitOfItems);
    return concatenatedPins;
  }

  if (secondConditionsPart.length === 0) {
    return concatenatedPins;
  }

  const highestPinValue = concatenatedPins[concatenatedPins.length - 1].position;

  if (highestPinValue === undefined) {
    return concatenatedPins;
  }

  const secondConditionPartWithCorrectPositions = increasePositionValues({
    conditions: secondConditionsPart,
    basePositionValue: highestPinValue,
    lengthLimit: maxLimitOfItems - concatenatedPins.length,
  });

  return concatenatedPins.concat(secondConditionPartWithCorrectPositions);
}

const adjustPositions = (items: RuleDraftCondition[]): RuleDraftCondition[] => {
  for (let i = 1; i < items.length; i++) {
    if (items[i].position === items[i - 1].position) {
      (items[i].position as number) += 1;
    }
  }
  return items;
};

const removeDuplicates = (
  items: RuleDraftCondition[],
  newItem: RuleDraftCondition,
  shouldAdjustPositions: boolean
): RuleDraftCondition[] => {
  const map = new Map(items.map(item => [item.tempId, item]));
  map.set(newItem.tempId, newItem);
  const updatedItems = Array.from(map.values());

  if (shouldAdjustPositions) {
    return adjustPositions(updatedItems);
  }

  return updatedItems;
};

export const updateRuleConditions = ({
  movedConditionId,
  previousConditions,
  rearrangedConditions,
}: {
  movedConditionId?: number | string;
  previousConditions: RuleDraftCondition[];
  rearrangedConditions: RuleDraftCondition[];
}): RuleDraftCondition[] => {
  const movedConditionTargetIndex = rearrangedConditions.findIndex(
    condition => condition.tempId === movedConditionId
  );

  const movedCondition = rearrangedConditions[movedConditionTargetIndex];

  const conditionIndexWithTheSamePosition = previousConditions.findIndex(
    condition => condition.position === movedCondition.position
  );

  const isPositionDuplicate = conditionIndexWithTheSamePosition !== -1;

  const updatedItems = removeDuplicates(rearrangedConditions, movedCondition, isPositionDuplicate);

  return sortBy(updatedItems, 'position');
};
