import { EditExperimentContainerAction } from 'src/containers';
import { ShopTheLookSettingsReducerState } from '../app-state-types';
import {
  ShopTheLookSettingsAction,
  ShopTheLookActionTypes,
  VariantPanelAction,
} from '../components-bl';
import { DEFAULT_ADD_MULTIPLE_TO_CART } from '../components-bl/AddToCartSettings/defaults/default-add-multiple-to-cart';

const initialState: ShopTheLookSettingsReducerState = {
  numberOfResultsToDisplay: 12,
  includeMainProductInCarousel: false,
  shuffleResults: false,
  completeTheLookFallbackMethod: 'None',
  shouldRefetch: false,
  addToCart: DEFAULT_ADD_MULTIPLE_TO_CART,
};

export function shopTheLookSettingsReducer(
  state: ShopTheLookSettingsReducerState = initialState,
  action?: ShopTheLookSettingsAction | EditExperimentContainerAction | VariantPanelAction
): ShopTheLookSettingsReducerState {
  switch (action?.type) {
    case ShopTheLookActionTypes.GetShopTheLookSettingsSuccess: {
      const {
        numberOfResultsToDisplay,
        includeMainProductInCarousel,
        shuffleResults,
        completeTheLookFallbackMethod,
        addToCart,
      } = action.payload;
      return {
        ...state,
        numberOfResultsToDisplay,
        includeMainProductInCarousel,
        shuffleResults,
        completeTheLookFallbackMethod,
        addToCart,
        shouldRefetch: false,
      };
    }
    case ShopTheLookActionTypes.GetShopTheLookSettingsRequest:
      return { ...state };
    case ShopTheLookActionTypes.GetShopTheLookSettingsError:
      return { ...state };
    case ShopTheLookActionTypes.UpdateShopTheLookSettingsSuccess: {
      const {
        numberOfResultsToDisplay,
        includeMainProductInCarousel,
        shuffleResults,
        completeTheLookFallbackMethod,
        addToCart,
      } = action.payload;
      const updatedSettings = {
        ...state,
        numberOfResultsToDisplay,
        includeMainProductInCarousel,
        shuffleResults,
        completeTheLookFallbackMethod,
        addToCart,
      };
      return {
        ...state,
        numberOfResultsToDisplay: updatedSettings.numberOfResultsToDisplay,
        includeMainProductInCarousel: updatedSettings.includeMainProductInCarousel,
        shuffleResults: updatedSettings.shuffleResults,
        completeTheLookFallbackMethod: updatedSettings.completeTheLookFallbackMethod,
        addToCart: updatedSettings.addToCart,
      };
    }
    default:
      return state;
  }
}
