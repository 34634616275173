import React from 'react';
import { useEditPostsPageState } from '../../../../components-bl/socialDiscovery/EditSocialCollection/useEditPostsPageState/useEditPostsPageState';
import { Page } from '../../../../components-dummy';
import { EditPostsHeader } from './EditPostsHeader';
import { EditPosts } from './EditPosts';
import { StateType } from '../../../../components-bl/socialDiscovery/EditSocialCollection/useEditPostsPageState/reducer';

export const EditPostsPage = (): JSX.Element | null => {
  const { state, updatePosts, savePosts } = useEditPostsPageState();
  if (state.type === StateType.fetching) return null;
  const disabled = state.type === StateType.saving || state.type === StateType.noChanges;
  return (
    <Page className='edit-posts-page'>
      <Page.Header>
        <EditPostsHeader disabled={disabled} onSave={savePosts} />
      </Page.Header>
      <Page.Content>
        <EditPosts publicPosts={state.publicPosts} onChange={updatePosts} />
      </Page.Content>
    </Page>
  );
};
