import React from 'react';
import { TextBox } from '../../TextBox';
import { EditableTextBoxCellProps } from '../types';
import { useEditableCell } from '../hooks';

const COMPONENT_NAME = 'EditableTextBoxCell';

export const EditableTextBoxCell = <T extends Record<string, unknown>>({
  value,
  row: { index },
  column: { id, cellProps },
  updateData,
  placeholder,
  ...rest
}: EditableTextBoxCellProps<T>): JSX.Element => {
  const { currentValue, onChange } = useEditableCell<string | undefined>({
    rowIndex: index,
    columnId: id as string,
    value,
    updateData,
  });

  const dataId =
    cellProps && cellProps.prefixId ? `${cellProps.prefixId}_${COMPONENT_NAME}_index_${index}` : '';
  return (
    <TextBox
      prefixId={dataId}
      className='syte-table-editable-cell'
      placeholder={placeholder}
      value={(currentValue as string) ?? ''}
      onChange={onChange}
      {...rest}
    />
  );
};
