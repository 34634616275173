import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { Page, SidePanel, NavButton, Icon, AvailableIcons } from 'src/components-dummy';
import { useAppSelector } from 'src/hooks';
import { RoutedComponentProps } from 'src/app-routes';
import { ExperimentsActionButtons } from 'src/components-bl/Experiment/ExperimentActionButtons';
import { ExperimentGoToDashboardButton } from 'src/components-bl/Experiment/ExperimentGoToDashboardButton';
import { UseUuidModal } from 'src/components-bl/Experiment/ExperimentCard/UseUuidModal';
import { useExperimentCard } from 'src/components-bl/Experiment/ExperimentCard/useExperimentCard';
import { ExperimentSlim } from 'src/services';
import { editExperimentContainerActionMethods } from './Actions';
import { ConnectedExperimentForm, ConnectedVariantPanel } from './components';
import './EditExperimentContainer.scss';

type EditExperimentContainerProps = RoutedComponentProps;

export const EditExperimentContainer = ({
  permittedRouteMap,
}: EditExperimentContainerProps): JSX.Element => {
  const dispatch = useDispatch();
  const [applyButtonRef, setApplyButtonRef] = useState<HTMLDivElement | null>(null);
  const [showUseUuidDialog, setShowUseUuidDialog] = useState(false);
  const selectedVariantId = useAppSelector(state => state.experiments.selectedVariantId);
  const selectedExperiment = useAppSelector(state => state.experiments.selectedExperiment);
  const shopId = useAppSelector(state => state.shop.current?.shopId) as number;
  const runningExperimentId = useAppSelector(state => state.experiments.runningExperiment?.id);

  const { onRunExperiment } = useExperimentCard({
    experiment: selectedExperiment as ExperimentSlim,
    runningExperimentId,
    dispatch,
    shopId,
    experimentFormRoute: permittedRouteMap.editExperiment?.path,
    setShowUseUuidDialog,
  });

  const experimentsListPath =
    (permittedRouteMap.experiments?.path &&
      shopId &&
      generatePath(permittedRouteMap.experiments?.path, { shopId })) ||
    undefined;

  const onClose = () => {
    dispatch(editExperimentContainerActionMethods.closeVariantPanel());
  };

  return (
    <>
      <Page>
        <Page.Header className='syte-experiments-form-container-header'>
          <NavButton
            className='syte-experiments-form-container-header-back-button'
            href={experimentsListPath}
            onBeforeNavigation={href => {
              dispatch(editExperimentContainerActionMethods.navigateTo({ navigateTo: href }));
            }}
          >
            <Icon name={AvailableIcons.Back} />
          </NavButton>
          <Page.Title>{`EXPERIMENT : ${selectedExperiment?.name || ''}`}</Page.Title>
          <div className='syte-experiments-form-container-header-buttons'>
            <ExperimentGoToDashboardButton />
            <div className='syte-experiments-form-container-header-buttons-action'>
              <ExperimentsActionButtons
                runningExperimentId={runningExperimentId}
                shopId={shopId as number}
                dispatch={dispatch}
                experiment={selectedExperiment}
                setShowUseUuidDialog={setShowUseUuidDialog}
              />
            </div>

            <div ref={element => setApplyButtonRef(element)} />
          </div>
        </Page.Header>
        <Page.Content>
          <ConnectedExperimentForm
            applyButtonElement={applyButtonRef}
            permittedRouteMap={permittedRouteMap}
          />
          <SidePanel opened={!!selectedVariantId} onClose={onClose} wide closeOnClickOutside>
            <ConnectedVariantPanel />
          </SidePanel>
        </Page.Content>
      </Page>
      {showUseUuidDialog && (
        <UseUuidModal
          onRunExperiment={onRunExperiment}
          setShowUseUuidDialog={setShowUseUuidDialog}
        />
      )}
    </>
  );
};
