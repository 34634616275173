import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useParams } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { useAppSelector } from 'src/hooks';
import { ShopApiKeysUpdateSettingsModal } from 'src/components-bl/ApiKeysSettings/ShopApiKeysUpdateSettingsModal';
import { shopApiKeysSettingsModalContainerActions } from 'src/containers';

export const ShopApiKeysUpdateSettingsModalContainer = ({
  permittedRouteMap,
}: RoutedComponentProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const { selectedApiKey, apiKeys } = useAppSelector(state => state.apiKeys);

  const { apiKeyId } = useParams<{ apiKeyId: string }>();

  const onModalClose = useCallback(() => {
    if (permittedRouteMap.shopSettingsGeneral && shopId) {
      dispatch(
        shopApiKeysSettingsModalContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.shopSettingsGeneral.path, {
            shopId,
          }),
        })
      );
      dispatch(shopApiKeysSettingsModalContainerActions.resetSelectedApiKey());
    }
  }, [permittedRouteMap, shopId, dispatch]);

  useEffect(() => {
    if (shopId && apiKeyId) {
      (dispatch(shopApiKeysSettingsModalContainerActions.getApiKey({ shopId, apiKeyId })) as any)
        .unwrap()
        .catch((error: unknown) => {
          console.error(error);
          onModalClose();
        });
    }
  }, [shopId, apiKeyId, dispatch, onModalClose]);

  return (
    selectedApiKey && (
      <ShopApiKeysUpdateSettingsModal
        onModalClose={onModalClose}
        shopId={shopId}
        selectedApiKey={selectedApiKey}
        dispatch={dispatch}
        apiKeys={apiKeys}
      />
    )
  );
};
