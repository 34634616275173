import { createAction } from '@reduxjs/toolkit';
import { ToastNotification } from 'src/services/src/service/social-discovery/types';

export const notifyIsDirty = (isDirty: boolean) => {
  return {
    type: 'SocialDiscovery/NotifyIsDirty',
    payload: { isDirty },
  };
};

export const createToastNotification = createAction<ToastNotification>(
  'SocialDiscovery/CreateToast'
);
