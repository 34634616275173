import { BoughtTogetherGeneralSettings } from 'src/services/src/service/bought-together/bought-together';
import { Dispatch } from 'src/components-bl/types';
import { dataFieldsService } from 'src/services';
import {
  UpdateBoughtTogetherSettingsPayload,
  GetBoughtTogetherSettingsArgs,
  GetBoughtTogetherSettingsRequestPayload,
  GetBoughtTogetherSettingsSuccessPayload,
  GetBoughtTogetherSettingsErrorPayload,
  UpdateBoughtTogetherSettingsRequestPayload,
  UpdateBoughtTogetherSettingsSuccessPayload,
  UpdateBoughtTogetherSettingsErrorPayload,
  BoughtTogetherActionTypes,
  GetBoughtTogetherSettingsRequestAction,
  GetBoughtTogetherSettingsErrorAction,
  GetBoughtTogetherSettingsSuccessAction,
  UpdateBoughtTogetherSettingsRequestAction,
  UpdateBoughtTogetherSettingsSuccessAction,
  UpdateBoughtTogetherSettingsErrorAction,
  NotifyBoughtTogetherSettingsIsDirtyAction,
  NotifyBoughtTogetherSettingsIsDirtyActionPayload,
  GetBoughtTogetherDataFieldsRequestPayload,
  GetBoughtTogetherDataFieldsRequestAction,
  GetBoughtTogetherDataFieldsSuccessPayload,
  GetBoughtTogetherDataFieldsSuccessAction,
  GetBoughtTogetherDataFieldsErrorPayload,
  GetBoughtTogetherDataFieldsErrorAction,
  GetBoughtTogetherDataFieldsArgs,
} from './types';

const boughtTogetherService = new BoughtTogetherGeneralSettings();

/* --= Actions =-- */
const boughtTogetherSettingsActions = {
  getBoughtTogetherSettingsRequest: (
    payload: GetBoughtTogetherSettingsRequestPayload
  ): GetBoughtTogetherSettingsRequestAction => {
    return {
      type: BoughtTogetherActionTypes.GetBoughtTogetherSettingsRequest,
      payload,
    };
  },
  getBoughtTogetherSettingsSuccess: (
    payload: GetBoughtTogetherSettingsSuccessPayload
  ): GetBoughtTogetherSettingsSuccessAction => {
    return {
      type: BoughtTogetherActionTypes.GetBoughtTogetherSettingsSuccess,
      payload,
    };
  },
  getBoughtTogetherSettingsError: ({
    error,
  }: GetBoughtTogetherSettingsErrorPayload): GetBoughtTogetherSettingsErrorAction => {
    return {
      type: BoughtTogetherActionTypes.GetBoughtTogetherSettingsError,
      payload: { error },
    };
  },
  updateBoughtTogetherSettingsRequest: (
    payload: UpdateBoughtTogetherSettingsRequestPayload
  ): UpdateBoughtTogetherSettingsRequestAction => {
    return {
      type: BoughtTogetherActionTypes.UpdateBoughtTogetherSettingsRequest,
      payload,
    };
  },
  updateBoughtTogetherSettingsSuccess: (
    payload: UpdateBoughtTogetherSettingsSuccessPayload
  ): UpdateBoughtTogetherSettingsSuccessAction => {
    return {
      type: BoughtTogetherActionTypes.UpdateBoughtTogetherSettingsSuccess,
      payload,
    };
  },
  updateBoughtTogetherSettingsError: (
    payload: UpdateBoughtTogetherSettingsErrorPayload
  ): UpdateBoughtTogetherSettingsErrorAction => {
    return {
      type: BoughtTogetherActionTypes.UpdateBoughtTogetherSettingsError,
      payload,
    };
  },

  notifyIsDirtyState: (
    payload: NotifyBoughtTogetherSettingsIsDirtyActionPayload
  ): NotifyBoughtTogetherSettingsIsDirtyAction => {
    return {
      type: BoughtTogetherActionTypes.NotifyBoughtTogetherSettingsIsDirty,
      payload,
    };
  },

  getDataFieldsRequest: (
    payload: GetBoughtTogetherDataFieldsRequestPayload
  ): GetBoughtTogetherDataFieldsRequestAction => {
    return {
      type: BoughtTogetherActionTypes.GetDataFieldsRequest,
      payload,
    };
  },
  getDataFieldsSuccess: (
    payload: GetBoughtTogetherDataFieldsSuccessPayload
  ): GetBoughtTogetherDataFieldsSuccessAction => {
    return {
      type: BoughtTogetherActionTypes.GetDataFieldsSuccess,
      payload,
    };
  },
  getDataFieldsError: ({
    error,
  }: GetBoughtTogetherDataFieldsErrorPayload): GetBoughtTogetherDataFieldsErrorAction => {
    return {
      type: BoughtTogetherActionTypes.GetDataFieldsError,
      payload: { error },
    };
  },
};

/* --= Methods =-- */
export const boughtTogetherSettingsActionsMethods = {
  notifyIsDirty:
    ({ isDirty }: NotifyBoughtTogetherSettingsIsDirtyActionPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch(boughtTogetherSettingsActions.notifyIsDirtyState({ isDirty }));
    },

  fetchBoughtTogetherSettings:
    ({ shopId, variantId }: GetBoughtTogetherSettingsArgs) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(boughtTogetherSettingsActions.getBoughtTogetherSettingsRequest({ shopId }));
        const {
          numberOfResultsToDisplay,
          modelType,
          boughtTogetherFallbackMethod,
          addToCart,
          boughtTogetherFallbackField,
          sortingOrder,
        } = await boughtTogetherService.get({
          shopId,
          variantId,
        });
        dispatch(
          boughtTogetherSettingsActions.getBoughtTogetherSettingsSuccess({
            shopId,
            numberOfResultsToDisplay,
            modelType,
            boughtTogetherFallbackMethod,
            addToCart,
            boughtTogetherFallbackField,
            sortingOrder,
          })
        );
      } catch (error) {
        dispatch(boughtTogetherSettingsActions.getBoughtTogetherSettingsError({ error }));
      }
    },

  updateBoughtTogetherSettings:
    ({
      shopId,
      variantId,
      numberOfResultsToDisplay,
      modelType,
      boughtTogetherFallbackMethod,
      addToCart,
      boughtTogetherFallbackField,
      sortingOrder,
    }: UpdateBoughtTogetherSettingsPayload) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(
          boughtTogetherSettingsActions.updateBoughtTogetherSettingsRequest({
            shopId,
            numberOfResultsToDisplay,
            modelType,
            boughtTogetherFallbackMethod,
            addToCart,
            boughtTogetherFallbackField,
            sortingOrder,
          })
        );

        await boughtTogetherService.update({
          shopId,
          variantId,
          numberOfResultsToDisplay,
          modelType,
          boughtTogetherFallbackMethod,
          addToCart,
          boughtTogetherFallbackField,
          sortingOrder,
        });

        dispatch(
          boughtTogetherSettingsActions.updateBoughtTogetherSettingsSuccess({
            shopId,
            numberOfResultsToDisplay,
            modelType,
            boughtTogetherFallbackMethod,
            addToCart,
            boughtTogetherFallbackField,
            sortingOrder,
          })
        );
      } catch (error) {
        dispatch(boughtTogetherSettingsActions.updateBoughtTogetherSettingsError({ error }));
      }
    },
  getDataFields:
    ({ shopId, fieldType }: GetBoughtTogetherDataFieldsArgs) =>
    async (dispatch: Dispatch): Promise<void> => {
      try {
        dispatch(boughtTogetherSettingsActions.getDataFieldsRequest({ shopId }));
        const dataFields = await dataFieldsService.getDataFields({ shopId, fieldType });
        dispatch(boughtTogetherSettingsActions.getDataFieldsSuccess({ shopId, dataFields }));
      } catch (error) {
        dispatch(boughtTogetherSettingsActions.getDataFieldsError({ error }));
      }
    },
};
