import React, { useEffect } from 'react';
import {
  ReadOnlyTextStyled,
  SpinnerWrapperStyled,
  TextBoxWrapperStyled,
} from './EditableText.style';
import { TextBox } from '../TextBox';
import { UseEditableTextResponse, useEditableText } from './useEditableText';
import { Tooltip } from '../Tooltip';
import { Spinner } from '../Spinner';
import { PopupPosition } from '../PopUp';

/**
 * Constants
 */
const INPUT_TEXT_ELEMENT_TYPE = 'input-text';

type ElementType = typeof INPUT_TEXT_ELEMENT_TYPE;

/**
 * Main
 */
export interface EditableTextProps {
  currentText: string;
  onChange: ({
    value,
    resetValue,
  }: {
    value: string;
    resetValue: UseEditableTextResponse['resetValue'];
  }) => void;
  onValidateTextInput?: (inputValue: string) => boolean; // Callback to validate text during change in input text
  renderText: ({ editedText }: { editedText?: string }) => JSX.Element;
  elementType?: ElementType;
  isReadOnly?: boolean;
  readOnlyHoverTooltipText?: string;
  isWithDynamicWidth?: boolean;
  dynamicWidthMultiplier?: number;
  isLoading?: boolean;
  toolTipPosition?: PopupPosition;
}

export const EditableText = ({
  currentText,
  onChange,
  onValidateTextInput,
  renderText,
  elementType = 'input-text',
  readOnlyHoverTooltipText = 'Rename',
  isReadOnly = false,
  isWithDynamicWidth = false,
  dynamicWidthMultiplier = 9 /* Depends on the font size - affects on dynamic width input size */,
  isLoading = false,
  toolTipPosition = 'bottom center',
}: EditableTextProps): JSX.Element => {
  const {
    onToggleEditMode,
    onChangeHandler,
    isInEditMode,
    tempText,
    editedText,
    setInputRef,
    setContainerRef,
    setIsValid,
    isValid,
    resetValue,
  } = useEditableText({ currentText, onValidateTextInput });

  const onSetInputRef = (element: HTMLInputElement | null) => {
    if (element === null) {
      setInputRef(element);
      return;
    }

    switch (elementType) {
      case INPUT_TEXT_ELEMENT_TYPE: {
        if (element.tagName === 'INPUT') {
          const inputElement = element as HTMLInputElement;
          setInputRef(inputElement);
        } else {
          const inputElement = element.querySelector('input') as HTMLInputElement | null;
          setInputRef(inputElement);
        }
        break;
      }
      default: {
        setInputRef(null);
      }
    }
  };

  const handleChangeTextInput = (value: string) => {
    if (onValidateTextInput) {
      const isCurrentTextValid = onValidateTextInput?.(value);

      onChangeHandler(value);

      if (!isCurrentTextValid) {
        setIsValid(false);
        return;
      }

      /* Clear falsy isValid if now valid */
      if (!isValid && isCurrentTextValid) {
        setIsValid(true);
      }

      return;
    }

    /* Change text in hook */
    onChangeHandler(value);
  };

  useEffect(() => {
    /* call onChange only if value has changed */
    if (currentText !== editedText) {
      onChange({ value: editedText?.trim() || '', resetValue });
    }
  }, [editedText, currentText, resetValue]);

  const onReadOnlyTextClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    onToggleEditMode(true);
  };

  const onBlur = () => {
    onToggleEditMode(false);
  };

  if (isReadOnly) {
    return <ReadOnlyTextStyled>{renderText({ editedText })}</ReadOnlyTextStyled>;
  }

  return (
    <div ref={setContainerRef} className='editable-text'>
      {!isInEditMode || isLoading ? (
        <Tooltip value={readOnlyHoverTooltipText} position={toolTipPosition}>
          <ReadOnlyTextStyled
            onClick={onReadOnlyTextClick}
            onMouseDown={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            {renderText({ editedText })}
            {isLoading && (
              <SpinnerWrapperStyled>
                <Spinner size={10} />
              </SpinnerWrapperStyled>
            )}
          </ReadOnlyTextStyled>
        </Tooltip>
      ) : (
        <TextBoxWrapperStyled
          width={
            isWithDynamicWidth && tempText?.length
              ? `${tempText?.length * dynamicWidthMultiplier + 1}px`
              : undefined
          }
        >
          <TextBox
            ref={onSetInputRef}
            value={tempText}
            onChange={handleChangeTextInput}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onMouseDown={e => {
              e.stopPropagation();
            }}
            onBlur={onBlur}
            error={!isValid}
          />
        </TextBoxWrapperStyled>
      )}
    </div>
  );
};
