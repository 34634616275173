import React, { useMemo } from 'react';
import { generatePath } from 'react-router';
import { TableV2, TableV2InstanceProps } from 'src/components-dummy';
import { AdminSearchCatalogResult } from 'src/services/src/service/types/admin-search/admin-search-results';
import { Dispatch } from '../../../types';
import { adminSearchModalActions } from '../../AdminSearchModal.actions';
import { TableHeaderColumns } from '../TableHeaderColumns';
import { TableRow } from './TableRow';
import { tableColumns } from './tableColumns';

interface CatalogsTableProps {
  catalogs: AdminSearchCatalogResult[];
  catalogsDefaultRoute: string;
  dispatch: Dispatch;
  onSelect: VoidFunction;
}

export function CatalogsTable({
  catalogs,
  catalogsDefaultRoute,
  dispatch,
  onSelect,
}: CatalogsTableProps): JSX.Element {
  const tableData: AdminSearchCatalogResult[] = useMemo(() => {
    return catalogs.map(catalog => {
      const {
        shopId,
        shopName,
        name,
        isPrimary,
        isAugmentedSearchCatalog,
        isMultiLocale,
        createdAt,
      } = catalog;

      return {
        shopId,
        shopName,
        name,
        isPrimary,
        isAugmentedSearchCatalog,
        isMultiLocale,
        createdAt,
      };
    });
  }, [catalogs]);

  const columns = useMemo(() => Object.values(tableColumns), [tableColumns]);

  const renderBody = ({
    rows,
    prepareRow,
  }: Pick<
    TableV2InstanceProps<AdminSearchCatalogResult>,
    'rows' | 'prepareRow'
  >): JSX.Element[] => {
    return rows.map(row => {
      prepareRow(row);
      const onClick = () => {
        dispatch(
          adminSearchModalActions.navigateTo({
            navigateTo: generatePath(catalogsDefaultRoute, { shopId: row.original.shopId }),
          })
        );
        onSelect();
      };
      return (
        <TableRow
          key={`${row.original.shopId}-${row.original.name}-${row.original.createdAt}-${row.original.isPrimary}`}
          row={row}
          onClick={onClick}
        />
      );
    });
  };

  return (
    <TableV2<AdminSearchCatalogResult> options={{ columns, data: tableData }}>
      {({ getTableBodyProps, headerGroups, rows, prepareRow }) => {
        const headerGroup = headerGroups[0];

        return (
          <>
            <TableV2.Head {...headerGroup.getHeaderGroupProps()}>
              <TableHeaderColumns headers={headerGroup.headers} columns={columns} />
            </TableV2.Head>
            <TableV2.Body {...getTableBodyProps()}>{renderBody({ rows, prepareRow })}</TableV2.Body>
          </>
        );
      }}
    </TableV2>
  );
}
