import React, { MouseEventHandler } from 'react';
import { AvailableIcons, TypographyType, TypographyVariant } from 'src/components-dummy';
import { AddTagsButtonStyled, TypographyStyled } from './AddTagsButton.styles';
import { ButtonIconStyled } from './shared.styles';

interface AddTagsButtonButtonProps {
  onClick: MouseEventHandler<HTMLDivElement>;
}

export function AddTagsButton({ onClick }: AddTagsButtonButtonProps): JSX.Element {
  return (
    <AddTagsButtonStyled onClick={onClick}>
      <ButtonIconStyled name={AvailableIcons.AddTag} />
      <TypographyStyled variant={TypographyVariant.SmallRegular} type={TypographyType.Body}>
        Tag Image
      </TypographyStyled>
    </AddTagsButtonStyled>
  );
}
