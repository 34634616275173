import React from 'react';
import { useDispatch } from 'react-redux';
import { Page } from 'src/components-dummy';
import { useAppSelector } from 'src/hooks';
import { Account2FAForm } from 'src/components-bl/Account2FA';
import { AccountSettingsPageStyled } from './AccountSettingsContainer.styles';

export function AccountSettingsContainer(): JSX.Element {
  const currentAccount = useAppSelector(state => state.account.current);

  const dispatch = useDispatch();

  return (
    <AccountSettingsPageStyled>
      <Page.Header>
        <Page.Title>Account Settings</Page.Title>
        <Page.SubTitle>
          Admin only settings for internal configurations of the account
        </Page.SubTitle>
      </Page.Header>
      <Page.Content>
        {currentAccount && <Account2FAForm account={currentAccount} dispatch={dispatch} />}
      </Page.Content>
    </AccountSettingsPageStyled>
  );
}
