import React, { useEffect, useState } from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch } from 'src/components-bl/types';
import { Page } from 'src/components-dummy';
import { GalleryType, InspirationalImagesGallery } from 'src/services';
import { GalleryFormFooter, GalleryForm } from '../../GalleryForm';
import { useGalleryForm } from '../../GalleryForm/hooks/useGalleryForm';
import { GalleryBreadCrumbs } from '../../GalleryBreadCrumbs';
import { editInspirationalImagesGalleryPageActions } from './Actions';
import { ButtonStyled, GalleryFormPageStyled } from '../../GalleryForm/GalleryForm.styles';

interface EditInspirationalImagesGalleryPageProps extends RoutedComponentProps {
  shopId: number;
  dispatch: Dispatch;
  galleryId: string;
  gallery?: InspirationalImagesGallery;
}

export function EditInspirationalImagesGalleryPage({
  shopId,
  dispatch,
  galleryId,
  gallery,
  permittedRouteMap,
}: EditInspirationalImagesGalleryPageProps): JSX.Element {
  const [canSubmitForm, setCanSubmitForm] = useState(false);

  const { formApiRef, isSubmitInProgress, onSubmit, navigateToList } = useGalleryForm({
    dispatch,
    permittedRouteMap,
    shopId,
  });

  useEffect(() => {
    dispatch(
      editInspirationalImagesGalleryPageActions.getGallery({
        shopId,
        galleryId,
        galleryType: GalleryType.InspirationalImage,
      })
    );

    return () => {
      dispatch(editInspirationalImagesGalleryPageActions.resetCurrentGallery());
    };
  }, [shopId, galleryId, dispatch]);

  return (
    <>
      <GalleryFormPageStyled>
        <Page.Header>
          <GalleryBreadCrumbs
            dispatch={dispatch}
            permittedRouteMap={permittedRouteMap}
            shopId={shopId}
            showGalleriesLink
          >
            {Boolean(gallery) && <>Edit gallery - {gallery?.galleryName}</>}
          </GalleryBreadCrumbs>
        </Page.Header>
        <Page.Content>
          <GalleryForm
            shopId={shopId}
            dispatch={dispatch}
            gallery={gallery}
            onFormStatusChange={setCanSubmitForm}
            permittedRouteMap={permittedRouteMap}
            ref={formApiRef}
          />
        </Page.Content>
      </GalleryFormPageStyled>

      <GalleryFormFooter>
        <ButtonStyled disabled={isSubmitInProgress} variant='tertiary' onClick={navigateToList}>
          Cancel
        </ButtonStyled>
        <ButtonStyled
          disabled={!canSubmitForm}
          loading={isSubmitInProgress}
          onClick={onSubmit}
          variant='primary'
        >
          Save
        </ButtonStyled>
      </GalleryFormFooter>
    </>
  );
}
