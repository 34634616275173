import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { galleriesService } from 'src/services/src/service/galleries';

export const galleryFormActions = {
  navigateTo: createAction<{ navigateTo: string }>('GalleryForm/NavigateTo'),
  notifyIsDirty: createAction<{ isDirty: boolean }>('GalleryForm/NotifyIsDirty'),
  updateInspirationalImagesGallery: createAsyncThunk(
    'GalleryForm/UpdateInspirationalImagesGallery',
    async (
      {
        shopId,
        galleryId,
        inspirationalImagesFile,
      }: Parameters<typeof galleriesService.updateInspirationalImagesGallery>[0],
      { rejectWithValue }
    ) => {
      try {
        const updatedGallery = await galleriesService.updateInspirationalImagesGallery({
          shopId,
          galleryId,
          inspirationalImagesFile,
        });
        return updatedGallery;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
