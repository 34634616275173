import React, { RefObject, forwardRef } from 'react';
import { AugmentedSearchSettings, FormApiRef } from 'src/components-bl';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { useAppSelector } from 'src/hooks';
import { ContainerComponentProps } from 'src/containers/AugSearchFilters/augmented-search-filters.types';
import { PageHeaderStyled } from '../AugmentedSearchContainer.styles';

export const AugmentedSearchSettingsContainer = forwardRef<FormApiRef, ContainerComponentProps>(
  ({ shopId, dispatch, onSubmitStatusChange }, ref) => {
    const augmentedSearchSettings = useAppSelector(state => state.augmentedSearch.generalSettings);

    return (
      <>
        <PageHeaderStyled>
          <Typography type={TypographyType.Heading} variant={TypographyVariant.ExtraSmallBold}>
            Search Settings
          </Typography>
        </PageHeaderStyled>
        <AugmentedSearchSettings
          augmentedSearchSettings={augmentedSearchSettings}
          formApiRef={ref as RefObject<FormApiRef>}
          shopId={shopId}
          dispatch={dispatch}
          onSubmitStatusChange={onSubmitStatusChange}
        />
      </>
    );
  }
);
