import styled from '@emotion/styled';
import { Page } from 'src/components-dummy';

export const RuleFormPage = styled(Page)`
  .syte-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
  }
`;
