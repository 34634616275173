import React from 'react';
import { useDispatch } from 'react-redux';
import { GeneralSettings } from 'src/components-bl';
import { useAppSelector } from 'src/hooks';
import { UserRoles } from 'src/services/src/service/types/users';

export const ShopGeneralSettings = (): JSX.Element => {
  const dispatch = useDispatch();
  const { current: currentShop } = useAppSelector(state => state.shop);

  const userRole = useAppSelector(state => state.global.loggedInUser?.role);

  const isAdminUser = userRole === UserRoles.SyteAdmin;

  return <GeneralSettings shop={currentShop} dispatch={dispatch} isAdmin={isAdminUser} />;
};
