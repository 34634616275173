import React from 'react';
import styled from '@emotion/styled';
import Slider, { SliderThumb as MuiSliderThumb } from '@mui/material/Slider';
import { Typography, TypographyProps } from '../Typography';

export const SliderStyled = styled.div`
  width: 100%;
  padding: 3em 3em 0 3em;
`;

export const SliderInputStyled = styled(Slider)`
  color: ${({ theme }) => theme.palette.custom['primary-main']};

  .MuiSlider-mark {
    display: none;
  }

  .MuiSlider-rail {
    color: ${({ theme }) => theme.palette.custom['gray-50']};
  }
`;

export const SliderThumbStyled = styled(MuiSliderThumb)`
  box-shadow: none !important;

  &:before {
    background-color: ${({ theme }) => theme.palette.common.white};
    border: 4px solid currentColor;
    box-shadow: none !important;
  }
`;

export const TypographyStyled = styled((props: TypographyProps) => <Typography {...props} />)<{
  disabled: boolean;
}>`
  color: ${({ disabled, theme }) =>
    disabled ? theme.palette.custom['gray-30'] : theme.palette.common.black};
`;

export const MarkLabelStyled = styled.span`
  position: absolute;
  transform: translateX(-50%);
  top: 24px;
`;
