import { useEffect, useState, useCallback, useMemo } from 'react';
import {
  DeepTagAPI,
  DeepTagStatusEnum,
  IDeepTagReportProduct,
} from 'src/services/src/service/types';
import { isTagsFormDirty as isTagsFormDirtyFunction } from './DeepTagsProductReviewAndEditTagsSection.helpers';
import { DeepTagForm } from '../../DeepTagsProductForm.types';

/**
 * Types
 */
export interface IProductMainTagsFormData {
  tags: DeepTagForm[];
}

export type OnChangeMainProductTagsFormData = ({
  tags,
}: {
  tags: IProductMainTagsFormData['tags'];
}) => void;

export type OnSetCategoryHasChanged = (hasChanged: boolean) => void;

/**
 * Main
 */
export interface IUseDeepTagsMainProductTagsFormStateResponse {
  productTagItemsFormData: IProductMainTagsFormData['tags'];
  onChangeMainProductTagsFormData: OnChangeMainProductTagsFormData;
  isMainProductTagsFormDirty: boolean;
}

interface IUseDeepTagProductMainFormState {
  productMainTagsFromAPI: IDeepTagReportProduct['tags'];
}

export const useDeepTagsMainProductForm = ({
  productMainTagsFromAPI,
}: IUseDeepTagProductMainFormState): IUseDeepTagsMainProductTagsFormStateResponse => {
  const [isMainProductTagsFormDirty, setIsMainProductTagsFormDirty] = useState(false);
  const [productTagItemsFormData, setProductTagItemsFormData] = useState<
    IProductMainTagsFormData['tags']
  >([]);

  const productMainTagsAPIMap = useMemo(() => {
    return (productMainTagsFromAPI || []).reduce((prevTags: Record<string, DeepTagAPI>, tag) => {
      // eslint-disable-next-line no-param-reassign
      prevTags[tag.attribute.key] = tag;
      return prevTags;
    }, {});
  }, [productMainTagsFromAPI]);

  useEffect(() => {
    const tagsWithoutDeleteStatus = (productMainTagsFromAPI || []).filter(
      tag => tag.status !== DeepTagStatusEnum.Deleted
    );

    // Reset state
    setProductTagItemsFormData(tagsWithoutDeleteStatus);
    setIsMainProductTagsFormDirty(false);
  }, [productMainTagsFromAPI]);

  const onChangeItemTagsFormData = useCallback(
    ({
      tags: updatedTags,
    }: {
      tags: IUseDeepTagsMainProductTagsFormStateResponse['productTagItemsFormData'];
    }) => {
      const isTagsFormDirtyValue = isTagsFormDirtyFunction({
        newFormData: updatedTags,
        apiTagsByAttributeMap: productMainTagsAPIMap,
        apiTagsList: productMainTagsFromAPI,
      });

      setProductTagItemsFormData(updatedTags);
      setIsMainProductTagsFormDirty(isTagsFormDirtyValue);
    },
    [productMainTagsAPIMap, productMainTagsFromAPI]
  );

  return {
    productTagItemsFormData,
    onChangeMainProductTagsFormData: onChangeItemTagsFormData,
    isMainProductTagsFormDirty,
  };
};
