import React from 'react';
import { lowerCase, startCase } from 'lodash';
import { CustomInspirationsGallery, DiffChange, ImageTag } from 'src/services';
import { DiffCardChangeValue } from 'src/components-bl/FeatureChanges/DiffsCard';
import {
  SyteProductPlacement,
  syteProductPlacementToDisplayName,
} from 'src/services/src/service/types/syte-products';
import { EntityDiffSchema } from '../../types';
import { ImageLink } from './image-url';

const mongoIdRegexPattern = '[0-9a-fA-F]{24}';

function getImageFieldDisplayName(
  title: string,
  diff: DiffChange,
  oldEntity: CustomInspirationsGallery,
  newEntity: CustomInspirationsGallery
): JSX.Element {
  const entity = newEntity || oldEntity;

  const regexp = /images.(.*)\.(.*)$/;
  const match = diff.valuePath.match(regexp);

  if (!match) {
    return <>{title}</>;
  }

  const targetImage = entity.images.find(image => image.id === match[1]);

  if (targetImage) {
    return (
      <>
        <ImageLink imageUrl={targetImage.imageUrl} /> - {title}
      </>
    );
  }
  return <>{title}</>;
}

export const customInspirationsGalleryDiffsSchema: EntityDiffSchema<
  CustomInspirationsGallery & Record<string, unknown>,
  CustomInspirationsGallery
> = {
  displayTitle: {
    displayName: 'Title',
  },
  'layout.type': {
    displayName: 'Layout',
    getValue({ entity }: { entity: CustomInspirationsGallery }): DiffCardChangeValue {
      return startCase(lowerCase(entity.layout.type));
    },
  },
  'layout.gridImagesPerRow': {
    displayName: 'Number of images per row',
  },
  'layout.gridNumberOfRows': {
    displayName: 'Number of rows',
  },
  'layout.carouselNumberOfImages': {
    displayName: 'Number of images',
  },
  placement: {
    displayName: 'Placement',
    getValue({ value }: { value: unknown }): string {
      return syteProductPlacementToDisplayName[value as SyteProductPlacement] || '';
    },
  },
  imageIds: {
    displayName: 'Image List changed',
    getValue(): string {
      return '';
    },
  },
  [`images.${mongoIdRegexPattern}$`]: {
    shouldSkipField: () => true,
    displayName: 'Image',
  },
  [`images.${mongoIdRegexPattern}.imageUrl$`]: {
    displayName: 'Image',
    getValue({ value }: { value: unknown }): JSX.Element {
      try {
        return <ImageLink imageUrl={value as string} />;
      } catch {
        return <>N/A</>;
      }
    },
  },
  [`images.${mongoIdRegexPattern}.altText`]: {
    displayName: 'Atl Text',
    getDisplayName: (
      diff: DiffChange,
      oldEntity: CustomInspirationsGallery,
      newEntity: CustomInspirationsGallery
    ) => getImageFieldDisplayName('Atl Text', diff, oldEntity, newEntity),
  },
  [`images.${mongoIdRegexPattern}.caption`]: {
    displayName: 'Caption',
    getDisplayName: (
      diff: DiffChange,
      oldEntity: CustomInspirationsGallery,
      newEntity: CustomInspirationsGallery
    ) => getImageFieldDisplayName('Caption', diff, oldEntity, newEntity),
  },
  [`images.${mongoIdRegexPattern}.tags$`]: {
    displayName: 'Tag',
    getDisplayName: (
      diff: DiffChange,
      oldEntity: CustomInspirationsGallery,
      newEntity: CustomInspirationsGallery
    ) => getImageFieldDisplayName('Tags Change', diff, oldEntity, newEntity),
    getValue({ value }: { value: unknown }): string {
      try {
        const tags: ImageTag[] = JSON.parse(value as string);
        return tags.map(tag => tag.title).join(', ');
      } catch {
        return 'N/A';
      }
    },
  },
};
