import React, { useMemo } from 'react';
import { TableV2, TableV2InstanceProps } from '../../../../components-dummy';
import { TableHeaderColumns } from '../TableHeaderColumns';
import { TableRow } from './TableRow';
import { adminSearchModalActions } from '../../AdminSearchModal.actions';
import { tableColumns } from './tableColumns';
import { AdminSearchAccountResult } from '../../../../services/src/service/types/admin-search/admin-search-results';
import { Dispatch } from '../../../types';

interface AccountsTableProps {
  accounts: AdminSearchAccountResult[];
  dispatch: Dispatch;
  onSelect: VoidFunction;
  accountDefaultRoute: string;
}

export const AccountsTable = ({
  accounts,
  dispatch,
  onSelect,
  accountDefaultRoute,
}: AccountsTableProps): JSX.Element => {
  const tableData: AdminSearchAccountResult[] = useMemo(() => {
    return accounts.map(account => {
      const { accountId, accountName, isArchived, createdAt } = account;

      return {
        accountId,
        accountName,
        isArchived,
        createdAt,
      };
    });
  }, [accounts]);

  const columns = useMemo(() => Object.values(tableColumns), [tableColumns]);

  const renderBody = ({
    rows,
    prepareRow,
  }: Pick<
    TableV2InstanceProps<AdminSearchAccountResult>,
    'rows' | 'prepareRow'
  >): JSX.Element[] => {
    return rows.map(row => {
      prepareRow(row);
      const onClick = () => {
        dispatch(
          adminSearchModalActions.navigateToAccount({
            accountId: row.original.accountId,
            navigateTo: accountDefaultRoute,
          })
        );
        onSelect();
      };
      return <TableRow key={row.original.accountId} row={row} onClick={onClick} />;
    });
  };

  return (
    <TableV2<AdminSearchAccountResult> options={{ columns, data: tableData }}>
      {({ getTableBodyProps, headerGroups, rows, prepareRow }) => {
        const headerGroup = headerGroups[0];

        return (
          <>
            <TableV2.Head {...headerGroup.getHeaderGroupProps()}>
              <TableHeaderColumns headers={headerGroup.headers} columns={columns} />
            </TableV2.Head>
            <TableV2.Body {...getTableBodyProps()}>{renderBody({ rows, prepareRow })}</TableV2.Body>
          </>
        );
      }}
    </TableV2>
  );
};
