import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { galleriesService } from 'src/services/src/service/galleries';

export const createGalleryFormActions = {
  navigateTo: createAction<{ navigateTo: string }>('CreateGalleryForm/NavigateTo'),
  notifyIsDirty: createAction<{ isDirty: boolean }>('CreateGalleryForm/NotifyIsDirty'),
  createGallery: createAsyncThunk(
    'CreateGalleryForm/CreateGallery',
    async (
      { shopId, galleryName, galleryType }: Parameters<typeof galleriesService.createGallery>[0],
      { rejectWithValue }
    ) => {
      try {
        const createdGallery = await galleriesService.createGallery({
          shopId,
          galleryName,
          galleryType,
        });
        return createdGallery;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
