import { AvailableIcons } from 'src/components-dummy';
import { DomainEntityPath } from 'src/services';
import { ParentDomainBase } from '../../types';
import { rankingSubDomainGroup } from '../ranking-sub-domain-group';
import { shopTheLookSettingsSubDomainGroup } from './shop-the-look-settings-sub-domain-group';

export const shopTheLookDomainGroup: ParentDomainBase = {
  domainKey: DomainEntityPath.ShopTheLook,
  title: 'Shop The Look',
  icon: AvailableIcons.HollowStar,
  subDomains: [
    { ...rankingSubDomainGroup, domainKey: DomainEntityPath.ShopTheLookRanking },
    { ...shopTheLookSettingsSubDomainGroup, domainKey: DomainEntityPath.ShopTheLookSettings },
  ],
};
