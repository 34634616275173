import React from 'react';
import classNames from 'classnames';
import { Switch, SwitchProps } from '../Switch';
import { useFindComponentByType } from '../hooks';
import { ContentTemplate } from './ControlledContentTemplate';
import './ControlledContentToggle.scss';

export interface ControlledContentToggleProps extends SwitchProps {
  className?: string;
  toggleClassName?: string;
}
export const ControlledContentToggle = ({
  children,
  className,
  toggleClassName,
  checked,
  onChange,
}: ControlledContentToggleProps): JSX.Element => {
  const controlledContentComponent = useFindComponentByType({ type: ContentTemplate, children })
    ?.props.children;

  return (
    <div className={classNames('syte-controlled-content-toggle', className)}>
      <Switch checked={checked} onChange={onChange} className={toggleClassName}>
        {children}
      </Switch>
      {controlledContentComponent && (
        <div className={classNames('syte-controlled-content-toggle-content', { hidden: !checked })}>
          {controlledContentComponent}
        </div>
      )}
    </div>
  );
};

ControlledContentToggle.ContentTemplate = ContentTemplate;
ControlledContentToggle.ContentTemplate = ContentTemplate;
