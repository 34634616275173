import React from 'react';
import classNames from 'classnames';
import { Icon, AvailableIcons } from '../Icon';
import { Tooltip, TooltipProps } from '../Tooltip';
import './WarningIndication.scss';

interface WarningIndicationProps extends Pick<TooltipProps, 'position'> {
  disableTooltip: boolean;
  tooltipValue: string;
  show: boolean;
  // eslint-disable-next-line react/require-default-props
  className?: string;
}

export const WarningIndication = ({
  disableTooltip,
  tooltipValue,
  show,
  className,
  position,
}: WarningIndicationProps): JSX.Element => {
  return (
    <Tooltip value={tooltipValue} disabled={disableTooltip} position={position}>
      <Icon
        name={AvailableIcons.Error}
        className={classNames('warning-indication-icon', className, { show })}
      />
    </Tooltip>
  );
};
