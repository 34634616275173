import { GenericExtendsFallback } from '../../base';
import { UniquenessConfig } from '../types';
import { collectListValues } from './collect-list-values';

const defaultErrorMsgFormatter = (duplicatedVal: string): string =>
  `${duplicatedVal} already exists in this context`;

export type ListDuplicationState<T extends GenericExtendsFallback> = Record<
  keyof Partial<T>,
  string
>;

export function isFieldValueExistsInContext<T extends GenericExtendsFallback>(
  list: T[],
  itemToValidate: T,
  fieldToValidate: keyof T,
  itemKeyAttribute: keyof T,
  { isCaseSensitive, errorMsgFormatter = defaultErrorMsgFormatter }: UniquenessConfig
): string | undefined {
  const extractValueToCompare = String(itemToValidate[fieldToValidate]);
  const valueToCompare = isCaseSensitive
    ? extractValueToCompare
    : extractValueToCompare.toLowerCase();
  const collectedValues = collectListValues(
    list,
    fieldToValidate,
    itemToValidate,
    itemKeyAttribute,
    isCaseSensitive
  );
  return collectedValues.includes(valueToCompare) ? errorMsgFormatter(valueToCompare) : undefined;
}
