import React, { useCallback, useEffect, useState } from 'react';
import {
  Pagination,
  SelectType,
  MenuItem,
  SelectOnChangeEvent,
  Typography,
  TypographyType,
  TypographyVariant,
  PaginationGoToPageByText,
} from 'src/components-dummy';
import { Dispatch } from 'src/components-bl/types';
import {
  PaginationRowStyled,
  LimitDropDownStyled,
  SelectStyled,
  GroupStyled,
} from './DeepTagReportProductPagination.styles';
import { PaginationState } from './types';
import { deepTagReportProductPaginationActions } from './Actions';

interface DeepTagReportProductPaginationProps {
  skip?: number;
  limit?: number;
  dispatch: Dispatch;
  totalProducts?: number;
}
const limitOptions: number[] = [10, 20, 30, 40, 50, 100];

export const DeepTagReportProductPagination = ({
  skip,
  limit,
  dispatch,
  totalProducts,
}: DeepTagReportProductPaginationProps): JSX.Element => {
  const [pagination, setPagination] = useState({ skip, limit, totalProducts });

  const handleChange = useCallback(
    (partialState: Partial<PaginationState>) => {
      const stateCopy = { ...pagination, ...partialState };
      setPagination(stateCopy);
      dispatch(deepTagReportProductPaginationActions.updatePagination(partialState));
    },
    [dispatch, pagination]
  );

  const handlePageChange = useCallback(
    (newSkipCount: number) => {
      handleChange({ skip: newSkipCount });
    },
    [handleChange]
  );

  const handleLimitChange: SelectOnChangeEvent = useCallback(
    event => {
      // reset skip when limit is changed
      handleChange({ limit: event.target.value as unknown as number, skip: 0 });
    },
    [handleChange]
  );

  const onPageChangeByText = useCallback(
    args => {
      handlePageChange(args.skip);
    },
    [handlePageChange]
  );

  useEffect(() => {
    const newState = { ...pagination, skip, limit };
    if (totalProducts !== undefined) {
      newState.totalProducts = totalProducts;
    }
    setPagination(newState);
  }, [skip, limit, totalProducts]);

  return (
    <PaginationRowStyled className='deep-tag-reports-product-pagination'>
      <LimitDropDownStyled>
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
          Items per page:
        </Typography>
        <SelectStyled
          className='limit-input'
          type={SelectType.Menu}
          value={pagination.limit}
          onChange={handleLimitChange}
        >
          {limitOptions.map(limitOption => (
            <MenuItem key={limitOption.toString()} value={limitOption}>
              {limitOption}
            </MenuItem>
          ))}
        </SelectStyled>
      </LimitDropDownStyled>

      <GroupStyled>
        <PaginationGoToPageByText
          totalItems={pagination.totalProducts || 0}
          itemsPerPage={pagination.limit}
          onPageChange={onPageChangeByText}
        />
        <Pagination
          skip={pagination.skip || 0}
          itemsPerPage={pagination.limit}
          pagesToShow={3}
          totalItemsCount={pagination.totalProducts || 0}
          onPageChange={handlePageChange}
        />
      </GroupStyled>
    </PaginationRowStyled>
  );
};
