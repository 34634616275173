import { useEffect, useMemo, useReducer } from 'react';
import { createEditStoriesSettingsReducer } from './editStoriesSettings.reducer';
import { IEditableStoriesSettings, IStoriesSettings } from '../storiesPage.types';

interface Props {
  settings: IStoriesSettings;
  onChange: (settings: IEditableStoriesSettings) => void;
}

export const useEditStoriesSettings = ({ settings, onChange }: Props) => {
  const { reducer, getInitialState, actions } = useMemo(
    () =>
      createEditStoriesSettingsReducer({
        storiesStrategy: settings.storiesStrategy,
        numberOfResultsToDisplay: settings.numberOfResultsToDisplay,
      }),
    [settings]
  );
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const changeStrategy = (strategyName: string) => {
    const newStrategy = settings.strategies.find(strategy => strategy.name === strategyName);
    if (newStrategy) dispatch(actions.setStrategy(newStrategy));
  };

  const changeNumberOfResultsInView = (newNumber: number) => {
    if (newNumber <= 0) return;
    dispatch(actions.setNumberOfResultsToDisplay(newNumber));
  };

  useEffect(() => {
    onChange(state);
  }, [state]);

  return {
    state,
    settings,
    changeStrategy,
    changeNumberOfResultsInView,
  };
};
