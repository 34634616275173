import { useCallback, useState } from 'react';

interface UseOpenProductDetailsModalReturnType {
  isProductDetailsModalOpen: boolean;
  openProductDetailsModal: (sku?: string) => void;
  closeProductDetailsModal: VoidFunction;
  selectedSku?: string;
}

export const useOpenProductDetailsModal = (): UseOpenProductDetailsModalReturnType => {
  const [isProductDetailsModalOpen, setIsProductDetailsModalOpen] = useState(false);
  const [selectedSku, setSelectedSku] = useState(undefined);

  const openProductDetailsModal = useCallback(
    (sku?: string) => {
      setIsProductDetailsModalOpen(true);
      setSelectedSku(sku);
    },
    [setIsProductDetailsModalOpen, setSelectedSku]
  );

  const closeProductDetailsModal = useCallback(() => {
    setIsProductDetailsModalOpen(false);
    setSelectedSku(undefined);
  }, [setIsProductDetailsModalOpen, setSelectedSku]);

  return {
    isProductDetailsModalOpen,
    openProductDetailsModal,
    closeProductDetailsModal,
    selectedSku,
  };
};
