import React from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { useAppSelector } from 'src/hooks';
import { RoutedComponentProps } from 'src/app-routes';
import { DeepTagReportHowToUploadModal } from 'src/components-bl/DeepTagReports/components/DeepTagReportHowToUpload';
import { deepTagReportHowToModalContainerActions } from './DeepTagReportHowToModalContainer.actions';

type DeepTagReportHowToModalContainerProps = RoutedComponentProps;

export const DeepTagReportHowTodModalContainer = ({
  permittedRouteMap,
}: DeepTagReportHowToModalContainerProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const selectedShopId = useAppSelector(state => state.shop.current?.shopId);

  const onModalClose = () => {
    if (permittedRouteMap.deepTagReports && selectedShopId) {
      dispatch(
        deepTagReportHowToModalContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.deepTagReports.path, {
            shopId: selectedShopId,
          }),
        })
      );
    }
  };

  return <DeepTagReportHowToUploadModal onModalClose={onModalClose} />;
};
