import styled from '@emotion/styled';
import { CardItem } from 'src/components-dummy';

export const DeepTagsMultiSelectTriggerButtonStyled = styled.div<{
  isClickable?: boolean;
  clicked?: boolean;
}>`
  background-color: ${({ theme, isClickable, clicked }) => {
    if (isClickable && clicked) {
      return theme.palette.custom['primary-90'];
    }
    return theme.palette.custom.white;
  }};
  transition: background-color 0.1s;
  user-select: none;

  ${({ isClickable }) => (isClickable ? 'cursor: pointer' : '')}

  &:hover {
    background-color: ${({ theme }) => {
      return theme.palette.custom['primary-90'];
    }};
  }
`;

export const DeepTagMultiSelectWrapperStyled = styled(CardItem)`
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  width: 182px;
  padding: 16px 8px 4px 8px;

  .input-base-wrapper {
    background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  }

  &:hover {
    border-color: ${({ theme }) => theme.palette.custom['gray-60']};
  }
`;

export const DeepTagMultiSelectContentStyled = styled.div`
  p:first-of-type {
    padding-bottom: 10px;
    padding-left: 10px;
  }
`;
