import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
  ExperimentListSection,
  CreateExperimentForm,
  ExperimentGoToDashboardButton,
} from 'src/components-bl';
import { Page, Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import './ExperimentsContainer.scss';
import { RoutedComponentProps } from 'src/app-routes';

type ExperimentsContainerProps = RoutedComponentProps;

const ConnectedExperimentsListSection = ({
  permittedRouteMap,
}: ExperimentsContainerProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const { experiments, selectedExperimentId, runningExperiment } = useAppSelector(
    state => state.experiments
  );
  const shopId = useAppSelector(state => state.shop.current?.shopId);

  return shopId ? (
    <ExperimentListSection
      experiments={experiments}
      dispatch={dispatch}
      shopId={shopId}
      experimentFormRoute={permittedRouteMap.editExperiment?.path}
      selectedExperimentId={selectedExperimentId}
      runningExperimentId={runningExperiment?.id}
    />
  ) : null;
};

const ConnectedCreateExperimentForm = ({
  permittedRouteMap,
}: ExperimentsContainerProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const shopId = useAppSelector(state => state.shop.current?.shopId);

  return shopId ? (
    <CreateExperimentForm
      shopId={shopId}
      dispatch={dispatch}
      editExperimentFormRoute={permittedRouteMap.editExperiment?.path}
    />
  ) : null;
};

export const ExperimentsContainer = ({
  permittedRouteMap,
}: ExperimentsContainerProps): JSX.Element => {
  return (
    <div className='syte-experiments-container'>
      <Page>
        <Page.Header className='syte-experiments-container-header'>
          <Page.Title>
            <Typography
              className='syte-experiments-container-title'
              type={TypographyType.Heading}
              variant={TypographyVariant.ExtraSmallBold}
            >
              Experiments
            </Typography>
          </Page.Title>
          <div className='syte-experiments-container-header-buttons'>
            <ExperimentGoToDashboardButton />
            <ConnectedCreateExperimentForm permittedRouteMap={permittedRouteMap} />
          </div>
        </Page.Header>
        <Page.Content>
          <ConnectedExperimentsListSection permittedRouteMap={permittedRouteMap} />
        </Page.Content>
      </Page>
    </div>
  );
};
