import React, { SyntheticEvent, useCallback, useState } from 'react';
import { Row } from 'react-table';
import { RelevancyTuningItem } from 'src/services';
import {
  PopoverStyled,
  PrioritySliderContent,
  SliderStyled,
  SliderTitleStyled,
} from './RelevancyTuningTable.styles';
import { sliderMarks } from './constants';

function getValueLabelText(value: number): string {
  if (value === 50) {
    return 'Lowest';
  }

  if (value === 250) {
    return 'Low';
  }

  if (value === 500) {
    return 'Mid';
  }

  if (value === 750) {
    return 'High';
  }

  if (value === 1000) {
    return 'Highest';
  }

  return `${value}`;
}

interface PrioritySliderCellProps {
  prioritySliderAnchorEl: HTMLDivElement | null;
  row: Row<RelevancyTuningItem>;
  onClose: () => void;
  onCommitChange: (value: number) => void;
}

export function PrioritySliderCell({
  row,
  prioritySliderAnchorEl,
  onClose,
  onCommitChange,
}: PrioritySliderCellProps): JSX.Element {
  const isOpen = Boolean(prioritySliderAnchorEl);

  const [sliderValue, setSliderValue] = useState(row.original.priority);

  const onSliderChange = useCallback(
    (_event: Event, value: number | number[]) => {
      if (typeof value === 'number') {
        setSliderValue(value);
      }
    },
    [setSliderValue]
  );

  const onSliderChangeCommitted = useCallback(
    (_event: Event | SyntheticEvent<Element, Event>, value: number | number[]) => {
      if (typeof value === 'number') {
        onCommitChange(value);
      }
    },
    [onCommitChange]
  );

  return (
    <PopoverStyled
      open={isOpen}
      onClose={onClose}
      anchorEl={prioritySliderAnchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      sx={{}}
    >
      <PrioritySliderContent>
        <SliderTitleStyled>Select priority</SliderTitleStyled>
        <SliderStyled
          value={sliderValue}
          step={50}
          min={50}
          max={1000}
          marks={sliderMarks}
          valueLabelDisplay='auto'
          valueLabelFormat={getValueLabelText}
          onChange={onSliderChange}
          onChangeCommitted={onSliderChangeCommitted}
        />
      </PrioritySliderContent>
    </PopoverStyled>
  );
}
