import React from 'react';
import './SocialPostTile.scss';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { formatDateToNow } from 'src/utils/format-date-to-now';

export interface SocialPostTileProps {
  imageSrc: string;
  account: string;
  date: Date;
  isSelected?: boolean;
  onClick?: () => void;
}

export const SocialPostTile = ({
  imageSrc,
  account,
  date,
  isSelected = false,
  onClick,
}: SocialPostTileProps): JSX.Element => {
  const dateToNow = formatDateToNow(date);

  return (
    <div className='social-post-tile' onClick={() => onClick?.()} data-testid='social-post-tile'>
      <div className={`image-container ${isSelected ? 'selected' : ''}`}>
        <img src={imageSrc} alt='social-post-tile' />
      </div>
      <div className='footer'>
        <Typography
          type={TypographyType.Body}
          variant={TypographyVariant.SmallRegular}
          className='account'
        >
          @{account}
        </Typography>
        <Typography
          type={TypographyType.Body}
          variant={TypographyVariant.SmallRegular}
          className='date'
        >
          {dateToNow}
        </Typography>
      </div>
    </div>
  );
};
