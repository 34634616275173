import styled from '@emotion/styled';
import { Button, Page, Typography, Icon } from 'src/components-dummy';

export const ButtonStyled = styled(Button)`
  padding-left: 32px;
  padding-right: 32px;
`;

export const TitlesWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-content: center;
`;

export const EditFilterPageStyled = styled(Page)`
  min-height: 100%;
  background-color: ${({ theme }) => theme.palette.custom['gray-80']};

  width: 100%;
  height: 100%;
  padding-bottom: 100px;
  overflow: auto;

  .syte-page-header {
    border-bottom: unset;
  }
`;

export const GrayedOutTitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-20']};
  align-self: center;
  margin-right: 5px;
`;

export const LearnMoreLink = styled(Page.SubTitle)`
  margin-left: auto;
  width: unset !important;
  margin-top: unset !important;
  color: ${({ theme }) => theme.palette.custom['primary-main']} !important;

  a {
    display: flex;
  }
`;

export const LinkIconStyled = styled(Icon)`
  margin-left: 4px;
`;
