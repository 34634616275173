import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useParams } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { useAppSelector, usePreviousState } from 'src/hooks';
import { RuleFormFooter } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm/RuleFormFooter/RuleFormFooter';
import { RuleApiRef } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm';
import { CatalogFormPage } from 'src/components-bl/CatalogManager/CatalogFormPage';
import { toastActions } from 'src/containers/ToastManager';
import { NotificationType } from 'src/components-dummy/Notification';
import { IShopCatalog } from 'src/services/src/service/types/catalog-manager';
import { catalogFormContainerActions } from './CatalogFormContainer.actions';
import { ButtonStyled } from './CatalogFormContainer.styles';

const CREATE = 'create';
const EDIT = 'edit';

export const CatalogFormContainer = ({
  permittedRouteMap,
}: RoutedComponentProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const { shopId, shopName } = useAppSelector(state => state.shop.current) || {};

  const { catalogs } = useAppSelector(state => state.catalogManager) || {};

  const selectedCatalog = useAppSelector(state => state.catalogManager.selectedPlanCatalog);
  const shopSFTPSettingsCredentials = useAppSelector(state => state.shopSFTPSettings.settings);

  const previousShopId = usePreviousState(shopId);

  const { catalogName } = useParams<{ catalogName: string }>();

  const formMode = !catalogName ? CREATE : EDIT;

  const [formStatus, setFormStatus] = useState({ canSubmit: false });

  const formApiRef = useRef(null as RuleApiRef | null);

  const navigateToList = () => {
    if (permittedRouteMap.catalogManager?.path && shopId) {
      const path = generatePath(permittedRouteMap.catalogManager?.path, { shopId });
      dispatch(
        catalogFormContainerActions.navigateTo({
          navigateTo: path,
        })
      );
    }
  };

  useEffect(() => {
    if (previousShopId && shopId !== previousShopId && shopId) {
      navigateToList();
    }
  }, [shopId]);

  useEffect(() => {
    if (shopId) {
      const fetchSftpSettings = async () => {
        await dispatch(catalogFormContainerActions.getShopSFTPSettings({ shopId }));
      };

      fetchSftpSettings();

      if (catalogName) {
        const fetch = async () => {
          try {
            await (
              dispatch(catalogFormContainerActions.getSingleCatalog({ shopId, catalogName })) as any
            ).unwrap();
          } catch (error) {
            console.error(error);
            navigateToList();
          }
        };

        fetch();
      }
    }
  }, [catalogName, shopId]);

  const onNotifyCopyOnClipboard = () => {
    const message = 'SFTP Credentials copied to clipboard';
    dispatch(
      toastActions.enqueue({
        toast: {
          type: NotificationType.Success,
          customMessage: message,
          messageKey: message,
          timestamp: new Date().getTime(),
        },
      })
    );
  };

  const existedCatalogNamesMap: Record<string, boolean> | undefined = useMemo(
    () =>
      catalogs?.reduce((prev: any, catalog: IShopCatalog) => {
        // eslint-disable-next-line no-param-reassign
        prev[catalog.name] = true;
        return prev;
      }, {}),
    [catalogs]
  );

  return shopId ? (
    <>
      <CatalogFormPage
        shopId={shopId}
        shopName={shopName}
        dispatch={dispatch}
        existedCatalogNames={existedCatalogNamesMap}
        formApiRef={formApiRef}
        selectedCatalog={selectedCatalog}
        shopSFTPSettingsCredentials={shopSFTPSettingsCredentials}
        mode={formMode}
        onFormStatusChange={setFormStatus}
        onNotifyCopyOnClipboard={onNotifyCopyOnClipboard}
      />
      <RuleFormFooter>
        <ButtonStyled
          variant='tertiary'
          onClick={() => {
            navigateToList();
          }}
        >
          Cancel
        </ButtonStyled>
        <ButtonStyled
          disabled={!formStatus.canSubmit}
          variant='primary'
          onClick={async () => {
            if (!formApiRef.current) {
              return;
            }

            try {
              await formApiRef.current.submit();

              navigateToList();
            } catch (error) {
              console.error('CatalogFormContainer', error);
            }
          }}
        >
          {formMode === CREATE ? 'Create' : 'Update'}
        </ButtonStyled>
      </RuleFormFooter>
    </>
  ) : null;
};
