import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  FormCard,
  Switch,
  SwitchSizes,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { ShopProductsAPIv2Toggles } from 'src/services/src/service/types/shops';
import { Dispatch } from '../types';
import { shopFeatureTogglesDeepTagsToggleActions } from './Actions';
import {
  APISettingsContainerStyled,
  SettingToggleStyled,
  StyledSkeleton,
} from './APISettingsForm.styles';

function LoadingSkeleton() {
  return (
    <>
      <StyledSkeleton height={40} variant='rounded' />
      <StyledSkeleton height={40} variant='rounded' />
    </>
  );
}

interface APISettingsFormProps {
  dispatch: Dispatch;
  productsAPIv2Toggles?: ShopProductsAPIv2Toggles;
  shopId: number;
}

export function APISettingsForm({
  dispatch,
  productsAPIv2Toggles,
  shopId,
}: APISettingsFormProps): JSX.Element {
  const [isDeepTagsPermitted, setIsDeepTagsPermitted] = useState(
    productsAPIv2Toggles?.isDeepTagsPermitted || false
  );

  const [isTaggingEnabled, setIsTaggingEnabled] = useState(
    productsAPIv2Toggles?.isTaggingEnabled || false
  );

  const isLoaded = productsAPIv2Toggles?.isDeepTagsPermitted !== undefined;
  const isDirty =
    isLoaded &&
    (isDeepTagsPermitted !== productsAPIv2Toggles?.isDeepTagsPermitted ||
      isTaggingEnabled !== productsAPIv2Toggles?.isTaggingEnabled);

  useEffect(() => {
    if (shopId) {
      dispatch(
        shopFeatureTogglesDeepTagsToggleActions.getShopProductsDeepTagsPermission({ shopId })
      );
    }
  }, [shopId, dispatch]);

  useEffect(() => {
    dispatch(shopFeatureTogglesDeepTagsToggleActions.notifyIsDirty({ isDirty }));
  }, [isDirty, dispatch]);

  useEffect(() => {
    setIsDeepTagsPermitted(productsAPIv2Toggles?.isDeepTagsPermitted || false);
  }, [productsAPIv2Toggles?.isDeepTagsPermitted]);

  useEffect(() => {
    setIsTaggingEnabled(productsAPIv2Toggles?.isTaggingEnabled || false);
  }, [productsAPIv2Toggles?.isTaggingEnabled]);

  const onProductsDeepTagToggleChange = useCallback(
    (checked: boolean) => {
      setIsDeepTagsPermitted(checked);
    },
    [setIsDeepTagsPermitted]
  );

  const onEnableTaggingToggleChange = useCallback(
    (checked: boolean) => {
      setIsTaggingEnabled(checked);
    },
    [setIsTaggingEnabled]
  );

  const onSubmit = useCallback(() => {
    dispatch(
      shopFeatureTogglesDeepTagsToggleActions.updateShopProductsDeepTagsPermission({
        shopId,
        isDeepTagsPermitted,
        isTaggingEnabled,
      })
    );
  }, [isDeepTagsPermitted, isTaggingEnabled, shopId, dispatch]);

  return (
    <APISettingsContainerStyled>
      <FormCard>
        <FormCard.Title>API Settings</FormCard.Title>
        <FormCard.Button>
          <Button onClick={onSubmit} variant='primary' disabled={!isDirty}>
            Save changes
          </Button>
        </FormCard.Button>
        <FormCard.Content>
          {isLoaded ? (
            <>
              <SettingToggleStyled>
                <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Body}>
                  Expose deeptags in Products API v2.0
                </Typography>
                <Switch
                  size={SwitchSizes.Large}
                  onChange={onProductsDeepTagToggleChange}
                  checked={isDeepTagsPermitted}
                />
              </SettingToggleStyled>
              <SettingToggleStyled>
                <Typography variant={TypographyVariant.SmallRegular} type={TypographyType.Body}>
                  Enable Tagging API v2.0
                </Typography>
                <Switch
                  size={SwitchSizes.Large}
                  onChange={onEnableTaggingToggleChange}
                  checked={isTaggingEnabled}
                />
              </SettingToggleStyled>
            </>
          ) : (
            <LoadingSkeleton />
          )}
        </FormCard.Content>
      </FormCard>
    </APISettingsContainerStyled>
  );
}
