import React from 'react';
import {
  Button,
  MenuItem,
  NumericInput,
  Select,
  SelectOnChangeEvent,
  SelectType,
  Switch,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import './PersonalizationSettings.scss';
import { PersonalizationSettingsProps } from './types';
import { usePersonalizationSettingsDraft } from './usePersonalizationSettingsDraft';
import { createOptionalPortal } from '../helpers';
import { ConfigurationNote } from '../../components-dummy/Labels/ConfigurationNote';
import { ConfigurationVariant } from '../../components-dummy/Labels/configuration-variant';
import { SyteAdminContent } from '../../containers/SyteAdminContent/SyteAdminContent';
import { useAppSelector } from '../../hooks';
import { AddToCartForm } from '../AddToCartSettings/AddToCartForm';
import { PersonalizationFallbackMethod } from '../../types/enums/personalization-fallback-method';
import { SortingOrder } from '../../types/enums/sorting-order';

const NUMERIC_INPUT_DECIMAL_PLACES = 3;

export const conditionValueDropDownOptions = [
  { value: PersonalizationFallbackMethod.BestSellers, text: 'Best Sellers' },
  { value: PersonalizationFallbackMethod.CustomFallback, text: 'Custom Fallback' },
  { value: PersonalizationFallbackMethod.None, text: 'None' },
];

const orderOptions = [
  { value: SortingOrder.Desc, text: 'Descending' },
  { value: SortingOrder.Asc, text: 'Ascending' },
];

export const PersonalizationSettings = ({
  shopId,
  personalizationSettings,
  selectedVariantId,
  formHeaderElementRef,
  onIsDirty,
  dispatch,
  disabled = false,
  shouldRefetch,
  dataFields,
}: PersonalizationSettingsProps): JSX.Element | null => {
  const addToCartEnabled = useAppSelector(({ shop }) => !!shop.featureToggles?.addToCart?.enabled);

  const personalization = usePersonalizationSettingsDraft({
    personalizationSettings,
    dispatch,
    shopId,
    selectedVariantId,
    onIsDirty,
    shouldRefetch,
    dataFields,
  });

  const {
    draftPersonalizationSettings,
    isValid,
    isDirty,
    onSubmit,
    onDiscard,
    onChange,
    errors,
    dataFieldsDropdownOptions,
  } = personalization;

  const formButtons = !disabled && (
    <>
      <Button onClick={onDiscard} variant='tertiary' disabled={!isDirty}>
        Discard
      </Button>
      <Button onClick={onSubmit} disabled={!(isValid && isDirty)} variant='primary'>
        {selectedVariantId ? 'Apply' : 'Save'}
      </Button>
    </>
  );

  const formButtonsSection = createOptionalPortal({
    portalContent: disabled ? <></> : formButtons,
    targetContainerRef: formHeaderElementRef,
    fallback: <div className='ranking-button-wrapper'>{formButtons}</div>,
  });

  const handleDataFieldValueChange: SelectOnChangeEvent = event => {
    onChange({ personalizationFallbackField: event.target.value });
  };

  const handleSortingOrderChange: SelectOnChangeEvent = event => {
    onChange({ sortingOrder: event.target.value as SortingOrder });
  };

  const isCustomFallback =
    draftPersonalizationSettings.personalizationFallbackMethod ===
    PersonalizationFallbackMethod.CustomFallback;

  const handleConditionValueChange: SelectOnChangeEvent = event => {
    onChange({
      personalizationFallbackMethod: event.target.value as PersonalizationFallbackMethod,
      personalizationFallbackField: !isCustomFallback
        ? undefined
        : draftPersonalizationSettings.personalizationFallbackField,
      sortingOrder: !isCustomFallback ? undefined : draftPersonalizationSettings.sortingOrder,
    });
  };

  return (
    <div className='personalization-settings'>
      <div className='personalization-settings-form'>
        <div className='form-container'>
          <Typography
            type={TypographyType.Body}
            variant={TypographyVariant.MediumRegular}
            className='form-title'
          >
            Results Display
          </Typography>

          <div className='divider flex-item row h-gap'>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Number of results to display (3-20)
            </Typography>
            <div className='input-wrapper'>
              <NumericInput
                disabled={disabled}
                error={!!errors.numberOfResultsToDisplay}
                value={draftPersonalizationSettings.numberOfResultsToDisplay}
                onChange={value => {
                  onChange({ numberOfResultsToDisplay: value });
                }}
                className='results-to-display-input'
                decimals={NUMERIC_INPUT_DECIMAL_PLACES}
              />
              {!!errors.numberOfResultsToDisplay && (
                <Typography
                  type={TypographyType.Body}
                  variant={TypographyVariant.MediumRegular}
                  className='results-to-display-input-error'
                >
                  Please enter a number between 3 to 20
                </Typography>
              )}
            </div>
          </div>
          <SyteAdminContent>
            <div className='flex-item row separator'>
              <Switch
                disabled={disabled}
                checked={draftPersonalizationSettings.useSessionSkusFromPageViewEvent}
                onChange={() => {
                  onChange({
                    useSessionSkusFromPageViewEvent:
                      !draftPersonalizationSettings.useSessionSkusFromPageViewEvent,
                  });
                }}
              >
                <Switch.TitleTemplate>
                  <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                    Track session history (session skus) from page view event
                  </Typography>
                </Switch.TitleTemplate>
              </Switch>
            </div>
          </SyteAdminContent>
        </div>
      </div>
      <ConfigurationNote variant={ConfigurationVariant.theAboveToNonApiUsers} />

      <div className='personalization-settings-form fallback'>
        <div className='form-container'>
          <Typography
            type={TypographyType.Body}
            variant={TypographyVariant.MediumRegular}
            className='form-title'
          >
            Fallback
          </Typography>
        </div>

        <div className='divider flex-item column'>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
            Fallback method (used in case of no results)
          </Typography>
          <Select
            className='form-select-sm'
            type={SelectType.Menu}
            disabled={disabled}
            value={draftPersonalizationSettings.personalizationFallbackMethod}
            onChange={handleConditionValueChange}
          >
            {conditionValueDropDownOptions.map((option: { value: string; text: string }) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Select>
          {isCustomFallback && (
            <>
              <Select
                className='form-select-sm'
                type={SelectType.Menu}
                value={draftPersonalizationSettings.personalizationFallbackField}
                onChange={handleDataFieldValueChange}
              >
                {dataFieldsDropdownOptions.map((option: { value: string; text: string }) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
              <Select
                className='form-select-sm'
                type={SelectType.Menu}
                value={draftPersonalizationSettings.sortingOrder || SortingOrder.Desc}
                onChange={handleSortingOrderChange}
              >
                {orderOptions.map((option: { value: string; text: string }) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          {formButtonsSection}
        </div>
      </div>
      <ConfigurationNote variant={ConfigurationVariant.theAboveToAllClients} />
      <AddToCartForm
        showSettingsForm={addToCartEnabled}
        addToCart={draftPersonalizationSettings.addToCart}
        onChange={addToCart => onChange({ addToCart })}
        shopId={shopId}
      />
    </div>
  );
};
