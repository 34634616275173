import styled from '@emotion/styled';
import { Icon, Typography } from 'src/components-dummy';

export const IconStyled = styled(Icon)<{ selected: boolean; disabled: boolean }>`
  width: 96px;
  margin-bottom: 6px;

  ${({ selected, theme, disabled }) => {
    if (selected) {
      return `
          rect:first-of-type {
            fill: ${theme.palette.custom['primary-main']};
          }
          
          rect {
            fill: ${theme.palette.custom['primary-light']};
          }
        `;
    }
    if (disabled) {
      return `
        rect:first-of-type {
          fill: ${theme.palette.custom['gray-80']};
        }
        
        rect {
          fill: ${theme.palette.custom['gray-50']};
        }
      `;
    }
    return `
        rect:first-of-type {
          fill: ${theme.palette.custom['primary-light']};
        }
      
        rect {
          fill: ${theme.palette.custom['primary-main']};
        }
      `;
  }}
`;

export const TypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  user-select: none;
`;

export const IconWrapper = styled.div<{ disabled: boolean }>`
  ${({ disabled }) => !disabled && 'cursor: pointer;'}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
`;

export const ComingSoonBadge = styled.div`
  position: absolute;
  top: -10px;
  background-color: ${({ theme }) => theme.palette.custom.magenta};
  border-radius: 4px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ComingSoonTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  user-select: none;
`;
