import { useState } from 'react';
import { generatePath } from 'react-router';
import { experimentCardActionMethods } from './Actions';
import { ExperimentCardProps, UseExperimentCardReturnType } from './types';
import { experimentActionButtonsActionMethods } from '../ExperimentActionButtons/Actions';

export const useExperimentCard = ({
  shopId,
  experiment,
  experimentFormRoute,
  dispatch,
  setShowUseUuidDialog,
}: ExperimentCardProps & {
  setShowUseUuidDialog: (newValue: boolean) => void;
}): UseExperimentCardReturnType => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const onEditExperiment = () => {
    if (experimentFormRoute) {
      dispatch(
        experimentCardActionMethods.navigateTo({
          navigateTo: generatePath(experimentFormRoute, {
            experimentId: experiment.id,
            shopId,
          }),
        })
      );
    }
  };

  const onDeleteExperiment = (): void => {
    dispatch(experimentCardActionMethods.deleteExperiment({ shopId, id: experiment.id }));
    setShowDeleteDialog(false);
  };

  const onDeleteDialogClose = (): void => {
    setShowDeleteDialog(false);
  };

  const onRunExperiment = () => {
    if (experiment && shopId) {
      dispatch(
        experimentActionButtonsActionMethods.runExperiment({
          shopId,
          id: experiment.id,
          experiment,
        })
      );
      setShowUseUuidDialog(false);
    }
  };

  return {
    showDeleteDialog,
    setShowDeleteDialog,
    onEditExperiment,
    onDeleteExperiment,
    onDeleteDialogClose,
    onRunExperiment,
  };
};
