import { Types } from 'src/components-bl';
import { FeedsMetadata, HttpStatusCode } from '../../services';
import { FeedMetadataPartial } from '../../services/src/service/types/feeds-metadata/feeds-metadata';

const feedsMetadataService = new FeedsMetadata();

type RequestPayload = { shopId: number | undefined };
type ErrorPayload = {
  error: unknown;
};

export enum FeedsMetadataActionTypes {
  DeepTagsRequest = 'FeedsMetadata/DeepTagsRequest',
  DeepTagsSuccess = 'FeedsMetadata/DeepTagsSuccess',
  DeepTagsError = 'FeedsMetadata/DeepTagsError',
  DeepTagsNotFoundError = 'FeedsMetadata/DeepTagsNotFoundError',
}

interface DeepTagsRequestAction {
  type: typeof FeedsMetadataActionTypes.DeepTagsRequest;
  payload: RequestPayload;
}

interface DeepTagsSuccessAction {
  type: typeof FeedsMetadataActionTypes.DeepTagsSuccess;
  payload: FeedMetadataPartial;
}

interface DeepTagsErrorAction {
  type: typeof FeedsMetadataActionTypes.DeepTagsError;
  payload: ErrorPayload;
}

interface DeepTagsNotFoundErrorAction {
  type: typeof FeedsMetadataActionTypes.DeepTagsNotFoundError;
  payload: ErrorPayload;
}

export type FeedsMetadataAction =
  | DeepTagsRequestAction
  | DeepTagsSuccessAction
  | DeepTagsErrorAction
  | DeepTagsNotFoundErrorAction;

export const feedsMetadataActions = {
  deepTagsRequest: (payload: RequestPayload): DeepTagsRequestAction => {
    return {
      type: FeedsMetadataActionTypes.DeepTagsRequest,
      payload,
    };
  },
  deepTagsSuccess: (payload: FeedMetadataPartial): DeepTagsSuccessAction => {
    return {
      type: FeedsMetadataActionTypes.DeepTagsSuccess,
      payload,
    };
  },
  deepTagsError: (payload: ErrorPayload): DeepTagsErrorAction => {
    return {
      type: FeedsMetadataActionTypes.DeepTagsError,
      payload,
    };
  },
  deepTagsNotFoundError: (payload: ErrorPayload): DeepTagsNotFoundErrorAction => {
    return {
      type: FeedsMetadataActionTypes.DeepTagsNotFoundError,
      payload,
    };
  },
};

export const fetchDeepTagsMetadata =
  (shopId: RequestPayload) =>
  async (dispatch: Types.Dispatch): Promise<void> => {
    dispatch(feedsMetadataActions.deepTagsRequest(shopId));
    if (!shopId.shopId) {
      dispatch(feedsMetadataActions.deepTagsError({ error: 'No shopId Provided' }));
      return;
    }
    try {
      const fieldsMetadata = await feedsMetadataService.deeptags(shopId.shopId);
      dispatch(feedsMetadataActions.deepTagsSuccess(fieldsMetadata));
    } catch (error) {
      if (error.code === HttpStatusCode.NotFound) {
        dispatch(feedsMetadataActions.deepTagsNotFoundError({ error }));
      } else {
        dispatch(feedsMetadataActions.deepTagsError({ error }));
      }
    }
  };
