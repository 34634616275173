import { AccountTypes } from '../types';
import { ApiServiceBase } from '../api-service-base';
import { AccountsMapper } from './accounts-mapper';

export class Accounts extends ApiServiceBase {
  constructor() {
    super('accounts');
  }

  async getAccount(accountId: number): Promise<AccountTypes.Account> {
    const url = `${this.serviceBaseUri}/${accountId}`;
    const response = await this.httpService.get({ url });
    return AccountsMapper.map(response);
  }

  async createAccount(name: string): Promise<AccountTypes.Account> {
    const response = await this.httpService.post({
      url: this.serviceBaseUri,
      data: { accountName: name },
    });
    return AccountsMapper.map(response);
  }

  async searchAccounts({
    searchTerm,
    includeArchived = false,
    skip = 0,
    limit = 10,
  }: AccountTypes.SearchAccountsArgs): Promise<Array<AccountTypes.AccountSearchResult>> {
    const params = {
      searchTerm,
      activeOnly: includeArchived,
      skip,
      limit,
    };
    const response = await this.httpService.get({
      url: this.serviceBaseUri,
      requestConfig: { params },
    });
    return AccountsMapper.mapSearchResults(response);
  }

  async updateAccount({
    accountId,
    data,
  }: AccountTypes.UpdateAccountArgs): Promise<AccountTypes.Account> {
    const url = `${this.serviceBaseUri}/${accountId}`;
    const response = await this.httpService.put({ url, data });
    return AccountsMapper.map(response);
  }
}
