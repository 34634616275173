import React from 'react';

import { Page } from '../../../components-dummy';
import { EditButton } from '../EditButton';

import { UiTemplatesProps } from '../types';

import './Header.scss';

export const UiTemplatesHeader: React.FC<UiTemplatesProps> = ({ shopId, dispatch, openPopup }) => {
  return (
    <header className='ui-templates-header'>
      <Page.Title>UI Templates</Page.Title>
      <EditButton shopId={shopId} dispatch={dispatch} openPopup={openPopup} />
    </header>
  );
};
