import React from 'react';
import styled from '@emotion/styled';
import { FiltersRow, FiltersRowProps } from 'src/components-dummy';

export const FiltersWrapperStyled = styled.div`
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  border-radius: 8px;
`;

export const FiltersRowStyled = styled((props: FiltersRowProps) => <FiltersRow {...props} />)`
  margin: 16px 0;
`;
