/* eslint-disable react/jsx-curly-brace-presence */
import React, { useState, useCallback } from 'react';
import { RegenerateProductsTags } from 'src/services';
import {
  Button,
  Checkbox,
  ConfirmationDialog,
  DialogModalContent,
  DialogModalFooter,
  DialogModalHeader,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { BackDropWithLoader } from 'src/components-dummy/Backdrop/BackdropWithLoader';
import {
  CheckboxWithTextStyled,
  DeepTagReportRegenerateTagsModalStyled,
  RegenerateCheckboxesStyled,
  SubTitleStyled,
} from './DeepTagReportRegenerateModal.styles';

const SUBTITLE_CONTENT_GEN_PERMITTED =
  'This option allows you to update in mass thematic tags and/or the titles and descriptions that they system generates.';
const SUBTITLE_CONTENT_GEN_NOT_PERMITTED =
  'This option allows you to update in mass thematic tags.';

interface RegenerateFormState {
  title: boolean;
  description: boolean;
  thematics: boolean;
}

export interface DeepTagReportRegenerateTagsModalProps {
  isContentGenerationPermitted: boolean;
  onRegenerateTags: ({
    regenerateAllTitles,
    regenerateAllDescriptions,
    regenerateThematics,
  }: RegenerateProductsTags) => void;
  onCancel: () => void;
}

export const DeepTagReportRegenerateTagsModal = ({
  isContentGenerationPermitted,
  onRegenerateTags,
  onCancel,
}: DeepTagReportRegenerateTagsModalProps): JSX.Element => {
  const [isInProcess, setIsInProcess] = useState(false);
  const [formData, setFormData] = useState<RegenerateFormState>({
    title: false,
    description: false,
    thematics: false,
  });

  const onApply = useCallback(async () => {
    try {
      setIsInProcess(true);
      await onRegenerateTags({
        regenerateAllTitles: formData.title,
        regenerateAllDescriptions: formData.description,
        regenerateThematics: formData.thematics,
      });
    } finally {
      setIsInProcess(false);
    }
  }, [onRegenerateTags, formData.title, formData.description, formData.thematics]);

  const onChange = useCallback(
    (type: 'title' | 'description' | 'thematics') => () => {
      setFormData(state => ({ ...state, [type]: !state[type] }));
    },
    []
  );

  const isValid = formData.description || formData.title || formData.thematics;

  const subTitleText = isContentGenerationPermitted
    ? SUBTITLE_CONTENT_GEN_PERMITTED
    : SUBTITLE_CONTENT_GEN_NOT_PERMITTED;

  return (
    <>
      <DeepTagReportRegenerateTagsModalStyled open onClose={onCancel}>
        <>
          {isInProcess && <BackDropWithLoader />}
          <DialogModalHeader>
            <ConfirmationDialog.Title>Regenerate multiple items</ConfirmationDialog.Title>
          </DialogModalHeader>
          <DialogModalContent>
            <SubTitleStyled>
              <Typography type={TypographyType.Paragraph} variant={TypographyVariant.MediumRegular}>
                {subTitleText}
              </Typography>
            </SubTitleStyled>
            <RegenerateCheckboxesStyled>
              {isContentGenerationPermitted && (
                <>
                  <CheckboxWithTextStyled key='title' onClick={onChange('title')}>
                    <Checkbox checked={formData.title} />
                    <Typography
                      variant={TypographyVariant.MediumRegular}
                      type={TypographyType.Paragraph}
                    >
                      (Re)Generate all the titles
                    </Typography>
                  </CheckboxWithTextStyled>
                  <CheckboxWithTextStyled key='description' onClick={onChange('description')}>
                    <Checkbox checked={formData.description} />
                    <Typography
                      variant={TypographyVariant.MediumRegular}
                      type={TypographyType.Paragraph}
                    >
                      (Re)Generate all the descriptions
                    </Typography>
                  </CheckboxWithTextStyled>
                </>
              )}
              <CheckboxWithTextStyled key='thematics' onClick={onChange('thematics')}>
                <Checkbox checked={formData.thematics} />
                <Typography
                  variant={TypographyVariant.MediumRegular}
                  type={TypographyType.Paragraph}
                >
                  (Re)Generate all the thematics tags
                </Typography>
              </CheckboxWithTextStyled>
            </RegenerateCheckboxesStyled>
          </DialogModalContent>
          <DialogModalFooter>
            <Button variant='primary' onClick={onApply} disabled={!isValid || isInProcess}>
              Apply
            </Button>
            <Button variant='tertiary' onClick={onCancel}>
              Cancel
            </Button>
          </DialogModalFooter>
        </>
      </DeepTagReportRegenerateTagsModalStyled>
    </>
  );
};
