import { ReactNode } from 'react';
import { AvailableIcons } from '../Icon';

export enum NotificationType {
  Error = 'error',
  Success = 'success',
  Warning = 'warning',
  Info = 'info',
}

export interface NotificationProps {
  children: ReactNode | ReactNode[];
  mode?: NotificationType | undefined;
  className?: string;
  closeable?: boolean;
  onClose?: () => void;
  icon?: AvailableIcons;
  onClick?: () => void;
}

export const notificationIcons = {
  [NotificationType.Error]: AvailableIcons.Error,
  [NotificationType.Success]: AvailableIcons.Valid,
  [NotificationType.Warning]: AvailableIcons.Warning,
  [NotificationType.Info]: AvailableIcons.Star,
};
