import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { SyteLayout, filtersService } from 'src/services';

export const createFilterPageActions = {
  navigateTo: createAction<{ navigateTo: string }>('CreateFilterPage/NavigateTo'),
  errorNotification: createAction<{ customMessage: string }>('CreateFilterPage/ErrorNotification'),
  getFilterSet: createAsyncThunk(
    'FiltersContainer/GetFilterSet',
    async (
      { shopId, syteLayout }: { shopId: number; syteLayout: SyteLayout },
      { rejectWithValue }
    ) => {
      try {
        const filterSet = await filtersService.getFilterSet({ shopId, syteLayout });
        return { filterSet };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
