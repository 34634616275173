import React, { useCallback, useState } from 'react';
import { PopUp } from 'src/components-dummy';
import { SalesforceAccount } from 'src/services/src/service/salesforce/types';
import { PaginatedList } from './PaginatedList';
import { AccountTextBox } from './AccountTextBox';
import { PopupStyled } from './styles';

interface SalesforceAccountAutocompleteProps {
  error?: string;
  disabled?: boolean;
  onSelectAccount: (account: SalesforceAccount) => void;
  selectedAccount?: SalesforceAccount;
}

export const SalesforceAccountAutocomplete = ({
  error,
  disabled,
  onSelectAccount,
  selectedAccount,
}: SalesforceAccountAutocompleteProps): JSX.Element => {
  const [showPopUp, setShowPopUp] = useState(false);

  const closePopUp = useCallback(() => {
    setShowPopUp(false);
  }, [setShowPopUp]);

  const onAccountSelect = useCallback(
    (newAccount: SalesforceAccount) => {
      onSelectAccount(newAccount);
      closePopUp();
    },
    [onSelectAccount, closePopUp]
  );

  return (
    <PopupStyled
      show={showPopUp}
      onShow={setShowPopUp}
      hideOnScroll={false}
      position='bottom left'
      enforceShowOnTriggerOnly
      closeOnTriggerClick={false}
      disabled={disabled}
    >
      <PopUp.Trigger>
        <AccountTextBox disabled={disabled} error={error} account={selectedAccount} />
      </PopUp.Trigger>
      <PopUp.Content>
        <PaginatedList onSelectAccount={onAccountSelect} />
      </PopUp.Content>
    </PopupStyled>
  );
};
