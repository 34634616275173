import { createAsyncThunk } from '@reduxjs/toolkit';
import { dataFieldsService, DataFieldsService } from 'src/services';

export const skuAutoSuggestionActions = {
  getValues: createAsyncThunk(
    'SkuAutoSuggestion/GetDataFieldValues',
    async (
      {
        shopId,
        searchTerm,
        primaryDataField,
        additionalDataFields,
        limit,
        after,
        fieldsToReturn,
      }: Parameters<DataFieldsService['searchFeedDataFieldValues']>[0],
      { rejectWithValue }
    ) => {
      try {
        const dataFieldValues = await dataFieldsService.searchFeedDataFieldValues({
          shopId,
          primaryDataField,
          additionalDataFields,
          searchTerm,
          limit,
          after,
          fieldsToReturn,
        });

        return dataFieldValues;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getProducts: createAsyncThunk(
    'SkuAutoSuggestion/GetProducts',
    async (
      parameters: Parameters<DataFieldsService['getFeedProducts']>[0],
      { rejectWithValue }
    ) => {
      try {
        const products = await dataFieldsService.getFeedProducts(parameters);

        return products;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
