import React, { useEffect } from 'react';
import { visualEditorActions } from '../../state';
import { useAppSelector } from '../../../../hooks';
import { Dispatch } from '../../../../components-dummy/types';
import { DialogModalWithPagination } from '../../../../components-dummy/DialogModalWithPagination/DialogModalWithPagination';
import { ProductDetailsSkeleton } from './ProductDetails.skeleton';
import { ProductDetailsError } from './ProductDetailsError';
import { ProductDetails } from './ProductDetails';

interface ProductDetailsModalProps {
  appDispatch: Dispatch;
  shopId: number;
  isModalOpen: boolean;
  closeProductDetailsModal: VoidFunction;
  sku?: string;
}

export const ProductDetailsModal = ({
  appDispatch,
  shopId,
  isModalOpen,
  closeProductDetailsModal,
  sku,
}: ProductDetailsModalProps) => {
  const { data: product, loading, error } = useAppSelector(state => state.visualEditor.product);

  useEffect(() => {
    appDispatch(visualEditorActions.getProduct({ shopId, sku }));
  }, [shopId, sku]);

  return (
    <DialogModalWithPagination open={isModalOpen} onClose={closeProductDetailsModal}>
      <>
        {loading && <ProductDetailsSkeleton />}
        {error && <ProductDetailsError onClose={closeProductDetailsModal} />}
        {!loading && !error && product && (
          <ProductDetails product={product} onClose={closeProductDetailsModal} />
        )}
      </>
    </DialogModalWithPagination>
  );
};
