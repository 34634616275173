import React, { ReactNode } from 'react';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import { LabelStyled, LabelTextStyled } from './GalleryDetailsSection.styles';

export interface GalleryDetailsFieldProps {
  children: JSX.Element | JSX.Element[] | ReactNode | ReactNode[];
  className?: string;
}

export function GalleryDetailsField({
  children,
  className,
}: GalleryDetailsFieldProps): JSX.Element {
  return <LabelStyled className={className}>{children}</LabelStyled>;
}

function GalleryDetailsLabelText({ children, className }: GalleryDetailsFieldProps): JSX.Element {
  return (
    <LabelTextStyled
      type={TypographyType.Body}
      variant={TypographyVariant.SmallMedium}
      className={className}
    >
      {children}
    </LabelTextStyled>
  );
}

GalleryDetailsField.LabelText = GalleryDetailsLabelText;
