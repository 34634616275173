import React, { useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import { ParseJoiValidateResponse } from 'src/utils';
import {
  NumericInput,
  TextBox,
  Typography,
  TypographyType,
  TypographyVariant,
  Icon,
  InfoModal,
} from 'src/components-dummy';
import { AvailableIcons } from 'src/components-dummy/Icon/AvailableIcons';
import { useOnClickOutside } from 'src/hooks/useOnClickOutside';
import { DiscoveryBannerGeneralSettings } from 'src/services/src/service/types/syte-products/discovery-banner';
import button from '../../Assets/images/button.jpg';
import { DiscoveryBannerSettingsRecord } from '../../types';
import { handleUpdateColor } from '../../colorToHex';

import '../../DiscoveryBannerSettings.scss';

export interface CtaButtonProps {
  discoveryBannerSettings: DiscoveryBannerGeneralSettings['generalSettings'];
  onChange: (args: Partial<DiscoveryBannerGeneralSettings['generalSettings']['button']>) => void;
  disabled?: boolean;
  errors: ParseJoiValidateResponse<DiscoveryBannerSettingsRecord>;
}

export const CtaButton = ({
  discoveryBannerSettings,
  onChange,
  disabled = false,
  errors,
}: CtaButtonProps): JSX.Element | null => {
  const [showButtonBackgroundColorPicker, setShowButtonBackgroundColorPicker] = useState(false);
  const [showButtonFontColorPicker, setShowButtonFontColorPicker] = useState(false);
  const [showButton, setButtonModalIsOpened] = useState(false);
  const onButtonModalClose = () => {
    setButtonModalIsOpened(false);
  };
  const buttonBackgroundColorRef = useRef(null);
  useOnClickOutside(buttonBackgroundColorRef, () => {
    setShowButtonBackgroundColorPicker(false);
  });
  const buttonFontColorRef = useRef(null);
  useOnClickOutside(buttonFontColorRef, () => {
    setShowButtonFontColorPicker(false);
  });
  return (
    <div className='discovery-banner-settings-container-wrapper'>
      <div className='discovery-banner-settings-container-title'>
        <Typography variant={TypographyVariant.MediumBold} type={TypographyType.Body}>
          CTA button
        </Typography>
        <button
          type='button'
          className='discovery-banner-cta-button-modal'
          onClick={() => setButtonModalIsOpened(true)}
        >
          <Typography
            className='discovery-banner-cta-button-tooltip'
            variant={TypographyVariant.SmallRegular}
          >
            What is Shop similar button
          </Typography>
          <Icon
            className='discovery-banner-cta-button-tooltip-icon'
            name={AvailableIcons.TooltipQuestionmarkBlue}
          />
        </button>
        <InfoModal
          isOpened={showButton}
          className='button-example-modal'
          closeBtnTxt='Close'
          onClickOutside={onButtonModalClose}
          title='What is Shop similar button?'
        >
          <img className='button-example-modal-image' src={button} alt='buttonExample' />
        </InfoModal>
      </div>
      <div className='discovery-banner-settings-container button-settings'>
        <div className='button-background-color' ref={buttonBackgroundColorRef}>
          <TextBox
            label='Button Background Color'
            placeholder={discoveryBannerSettings.button.buttonBackgroundColor}
            error={!!errors.name}
            className='button-background-color'
            value={discoveryBannerSettings.button.buttonBackgroundColor}
            fullWidth
            onChange={backgroundColor => {
              onChange({ buttonBackgroundColor: backgroundColor });
            }}
            disabled={disabled}
          >
            <button
              type='button'
              aria-label='background-color-mock'
              className='background-color-mock'
              onClick={() => setShowButtonBackgroundColorPicker(!showButtonBackgroundColorPicker)}
              style={{
                backgroundColor: discoveryBannerSettings.button.buttonBackgroundColor,
              }}
              disabled={disabled}
            />
            {showButtonBackgroundColorPicker && (
              <ChromePicker
                className='background-color-picker'
                color={discoveryBannerSettings.button.buttonBackgroundColor}
                onChange={updatedColor => {
                  handleUpdateColor(updatedColor, newColor => {
                    onChange({ buttonBackgroundColor: newColor });
                  });
                }}
              />
            )}
          </TextBox>
        </div>
        <div className='button-font-color' ref={buttonFontColorRef}>
          <TextBox
            label='Button Text Color'
            placeholder={discoveryBannerSettings.button.buttonColor}
            error={!!errors.name}
            className='button-font-color'
            value={discoveryBannerSettings.button.buttonColor}
            fullWidth
            onChange={fontColor => {
              onChange({ buttonColor: fontColor });
            }}
            disabled={disabled}
          >
            <button
              type='button'
              aria-label='background-color-mock'
              className='font-color-mock'
              onClick={() => setShowButtonFontColorPicker(!showButtonFontColorPicker)}
              style={{
                backgroundColor: discoveryBannerSettings.button.buttonColor,
              }}
              disabled={disabled}
            />
            {showButtonFontColorPicker && (
              <ChromePicker
                className='font-color-picker'
                color={discoveryBannerSettings.button.buttonColor}
                onChange={updatedColor => {
                  handleUpdateColor(updatedColor, newColor => {
                    onChange({ buttonColor: newColor });
                  });
                }}
              />
            )}
          </TextBox>
        </div>
        <div className='button-font-family'>
          <TextBox
            label='Button Font Family'
            placeholder='sans-serif'
            error={!!errors.name}
            className='heading-font-family'
            value={discoveryBannerSettings.button.buttonFontFamily}
            fullWidth
            onChange={fontFamily => {
              onChange({ buttonFontFamily: fontFamily });
            }}
            disabled={disabled}
          />
        </div>
        <div className='button-font-size'>
          <span className='button-font-size-title'>
            <Typography variant={TypographyVariant.MediumRegular} type={TypographyType.Body}>
              Button Font Size
            </Typography>
          </span>
          <div className='button-font-size-input-wrapper'>
            <NumericInput
              placeholder='24'
              error={!!errors.numberOfResultsToDisplay}
              value={discoveryBannerSettings.button.buttonFontSize}
              onChange={value => {
                onChange({ buttonFontSize: value });
              }}
              className='button-font-size-input'
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
