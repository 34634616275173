import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Page, BackButtonTitle } from 'src/components-dummy';
import { useAppSelector } from 'src/hooks';
import { RecentlyViewedSettings } from 'src/components-bl';
import { ShopRoutes } from '../../types/enums/shop-routes';
import './RecentlyViewedContainer.scss';

export function RecentlyViewedContainer(): JSX.Element | null {
  const formHeaderRef = useRef<HTMLDivElement>(null);
  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const recentlyViewedSettings = useAppSelector(state => state.recentlyViewedSettings);
  const dispatch = useDispatch();
  const navigationPath = `/shops/${shopId}/${ShopRoutes.ShopperExperiences}`;

  return (
    <Page className='recently-viewed-container'>
      <Page.Header>
        <BackButtonTitle path={navigationPath}>Recently Viewed</BackButtonTitle>
        <div className='recently-viewed-buttons-section' ref={formHeaderRef} />
      </Page.Header>
      <Page.Content>
        {shopId && (
          <RecentlyViewedSettings
            recentlyViewedSettings={recentlyViewedSettings}
            shopId={shopId}
            formHeaderElementRef={formHeaderRef}
            dispatch={dispatch}
          />
        )}
      </Page.Content>
    </Page>
  );
}
