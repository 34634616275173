import React, { useCallback, useState } from 'react';
import { AvailableIcons } from 'src/components-dummy';
import { DeleteButtonStyled } from '../BulkActions.styles';
import { BaseBulkActionProps } from '../BulkActions.types';
import { lexiconBulkActions } from '../LexiconBulkAction.actions';
import { DeleteThematicTagsModal } from './DeleteThematicTagsModal';

interface LexiconBulkActionDeleteProps extends BaseBulkActionProps {
  isSyteAdmin: boolean;
}

export const LexiconBulkActionDelete = ({
  targetItemsCounts,
  matchTags,
  excludeTags,
  shopId,
  locale,
  filters,
  dispatch,
  targetAllTags,
  onActionSuccess,
  onActionFail,
  onActionStart,
  isSyteAdmin,
  tagType,
}: LexiconBulkActionDeleteProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const onDeleteClick = useCallback(() => {
    onActionStart();

    if (targetAllTags) {
      (
        dispatch(
          lexiconBulkActions.deleteAllTags({
            shopId,
            locale,
            excludeTags: [...excludeTags],
            filters,
            tagType,
          })
        ) as any
      )
        .unwrap()
        .then(() => {
          onActionSuccess();
        })
        .catch((error: Error) => {
          console.error(error);
          onActionFail();
        });
    } else {
      (
        dispatch(
          lexiconBulkActions.deleteTags({
            shopId,
            locale,
            matchTags: [...matchTags],
            tagType,
          })
        ) as any
      )
        .unwrap()
        .then(() => {
          onActionSuccess();
        })
        .catch((error: Error) => {
          console.error(error);
          onActionFail();
        });
    }

    setShowModal(false);
  }, [
    dispatch,
    shopId,
    locale,
    targetAllTags,
    matchTags,
    excludeTags,
    filters,
    onActionSuccess,
    onActionStart,
    onActionFail,
    tagType,
  ]);

  return (
    <>
      {isSyteAdmin && (
        <DeleteButtonStyled
          variant='destructive'
          onClick={openModal}
          startIcon={AvailableIcons.TrashCan}
        >
          Delete
        </DeleteButtonStyled>
      )}

      {showModal && (
        <DeleteThematicTagsModal
          onCancel={closeModal}
          tagsCount={targetItemsCounts}
          onDeleteClick={onDeleteClick}
        />
      )}
    </>
  );
};
