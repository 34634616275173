import { Dispatch } from 'src/components-bl';
import { Shops } from 'src/services';
import {
  ShopFeatureTogglesFormActionTypes,
  ShopFeatureTogglesFormGetFeatureTogglesErrorAction,
  ShopFeatureTogglesFormGetFeatureTogglesErrorActionPayload,
  ShopFeatureTogglesFormGetFeatureTogglesRequestAction,
  ShopFeatureTogglesFormGetFeatureTogglesRequestActionPayload,
  ShopFeatureTogglesFormGetFeatureTogglesSuccessAction,
  ShopFeatureTogglesFormGetFeatureTogglesSuccessActionPayload,
  ShopFeatureTogglesFormNotifyIsDirtyAction,
  ShopFeatureTogglesFormNotifyIsDirtyActionPayload,
  ShopFeatureTogglesFormUpdateFeatureTogglesErrorAction,
  ShopFeatureTogglesFormUpdateFeatureTogglesErrorActionPayload,
  ShopFeatureTogglesFormUpdateFeatureTogglesRequestAction,
  ShopFeatureTogglesFormUpdateFeatureTogglesRequestActionPayload,
  ShopFeatureTogglesFormUpdateFeatureTogglesSuccessAction,
  ShopFeatureTogglesFormUpdateFeatureTogglesSuccessActionPayload,
} from './types';

const shopFeatureTogglesActions = {
  getFeatureTogglesRequest(
    payload: ShopFeatureTogglesFormGetFeatureTogglesRequestActionPayload
  ): ShopFeatureTogglesFormGetFeatureTogglesRequestAction {
    return {
      type: ShopFeatureTogglesFormActionTypes.GetFeatureTogglesRequest,
      payload,
    };
  },

  getFeatureTogglesSuccess(
    payload: ShopFeatureTogglesFormGetFeatureTogglesSuccessActionPayload
  ): ShopFeatureTogglesFormGetFeatureTogglesSuccessAction {
    return {
      type: ShopFeatureTogglesFormActionTypes.GetFeatureTogglesSuccess,
      payload,
    };
  },

  getFeatureTogglesError(
    payload: ShopFeatureTogglesFormGetFeatureTogglesErrorActionPayload
  ): ShopFeatureTogglesFormGetFeatureTogglesErrorAction {
    return {
      type: ShopFeatureTogglesFormActionTypes.GetFeatureTogglesError,
      payload,
    };
  },

  updateFeatureTogglesRequest(
    payload: ShopFeatureTogglesFormUpdateFeatureTogglesRequestActionPayload
  ): ShopFeatureTogglesFormUpdateFeatureTogglesRequestAction {
    return {
      type: ShopFeatureTogglesFormActionTypes.UpdateFeatureTogglesRequest,
      payload,
    };
  },

  updateFeatureTogglesSuccess(
    payload: ShopFeatureTogglesFormUpdateFeatureTogglesSuccessActionPayload
  ): ShopFeatureTogglesFormUpdateFeatureTogglesSuccessAction {
    return {
      type: ShopFeatureTogglesFormActionTypes.UpdateFeatureTogglesSuccess,
      payload,
    };
  },

  updateFeatureTogglesError(
    payload: ShopFeatureTogglesFormUpdateFeatureTogglesErrorActionPayload
  ): ShopFeatureTogglesFormUpdateFeatureTogglesErrorAction {
    return {
      type: ShopFeatureTogglesFormActionTypes.UpdateFeatureTogglesError,
      payload,
    };
  },

  notifyIsDirty(
    payload: ShopFeatureTogglesFormNotifyIsDirtyActionPayload
  ): ShopFeatureTogglesFormNotifyIsDirtyAction {
    return {
      type: ShopFeatureTogglesFormActionTypes.NotifyIsDirty,
      payload,
    };
  },
};

export const shopFeatureTogglesActionMethods = {
  getFeatureToggles(payload: ShopFeatureTogglesFormGetFeatureTogglesRequestActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        const shopService = new Shops();

        dispatch(shopFeatureTogglesActions.getFeatureTogglesRequest(payload));
        const featureToggles = await shopService.getFeatureToggles(payload.shopId);

        dispatch(shopFeatureTogglesActions.getFeatureTogglesSuccess({ featureToggles }));
      } catch (error) {
        dispatch(shopFeatureTogglesActions.getFeatureTogglesError({ error }));
      }
    };
  },

  updateFeatureToggles(payload: ShopFeatureTogglesFormUpdateFeatureTogglesRequestActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        const shopService = new Shops();

        dispatch(shopFeatureTogglesActions.updateFeatureTogglesRequest(payload));
        const featureToggles = await shopService.updateFeatureToggles(payload);

        dispatch(
          shopFeatureTogglesActions.updateFeatureTogglesSuccess({
            featureToggles,
          })
        );
      } catch (error) {
        dispatch(shopFeatureTogglesActions.updateFeatureTogglesError({ error }));
      }
    };
  },

  notifyIsDirty(payload: ShopFeatureTogglesFormNotifyIsDirtyActionPayload) {
    return (dispatch: Dispatch): void => {
      dispatch(shopFeatureTogglesActions.notifyIsDirty(payload));
    };
  },
};
