import { AiSuggestedImageTag, DomainEntityPath } from '../types';
import { DomainEventsWebSocketServiceBase, DomainEvent } from '../domain-events';
import { ClearListener } from '../web-socket.service';
import { ValidationErrorKey } from 'src/containers/ToastManager/ActionsNotifications/validation-error-key';

export enum CustomInspirationsGalleryEventTopicNames {
  TagsDetectionOnImageStarted = 'custom-inspirations-gallery:image:tags-detection-started',
  TagsDetectionOnImageEnded = 'custom-inspirations-gallery:image:tags-detection-ended',
  TagsDetectionStarted = 'custom-inspirations-gallery:tags-detection-started',
  TagsDetectionEnded = 'custom-inspirations-gallery:tags-detection-ended',
}

export type ImageTagsDetectionStartedEventData = {
  shopId: number;
  galleryId: string;
  imageId: string;
};

export type ImageTagsDetectionEndedEventData = {
  shopId: number;
  galleryId: string;
  imageId: string;
  errorKey?: ValidationErrorKey;
  aiSuggestedTags: AiSuggestedImageTag[];
};

export type GalleryTagsDetectionStartedEventData = {
  shopId: number;
  galleryId: string;
};

export type GalleryTagsDetectionEndedEventData = {
  shopId: number;
  galleryId: string;
  errorKey?: ValidationErrorKey;
  aiSuggestedTagsCount: number;
};

export type CustomInspirationsImageDetectionStartedEvent = DomainEvent<
  ImageTagsDetectionStartedEventData,
  CustomInspirationsGalleryEventTopicNames
>;

export type CustomInspirationsImageDetectionEndedEvent = DomainEvent<
  ImageTagsDetectionEndedEventData,
  CustomInspirationsGalleryEventTopicNames
>;

export type CustomInspirationsGalleryDetectionStartedEvent = DomainEvent<
  GalleryTagsDetectionStartedEventData,
  CustomInspirationsGalleryEventTopicNames
>;

export type CustomInspirationsGalleryDetectionEndedEvent = DomainEvent<
  GalleryTagsDetectionEndedEventData,
  CustomInspirationsGalleryEventTopicNames
>;

export class GalleriesWebSocketService extends DomainEventsWebSocketServiceBase<CustomInspirationsGalleryEventTopicNames> {
  domainEntity = DomainEntityPath.CustomInspirationsGallery;

  subscribeToImageTagsDetectionStartedEvents(
    callback: (payload: CustomInspirationsImageDetectionStartedEvent) => void
  ): ClearListener {
    return this.subscribeToEvents({
      topicKey: CustomInspirationsGalleryEventTopicNames.TagsDetectionOnImageStarted,
      callback,
    });
  }

  subscribeToImageTagsDetectionEndedEvents(
    callback: (payload: CustomInspirationsImageDetectionEndedEvent) => void
  ): ClearListener {
    return this.subscribeToEvents({
      topicKey: CustomInspirationsGalleryEventTopicNames.TagsDetectionOnImageEnded,
      callback,
    });
  }

  subscribeToGalleryTagsDetectionStartedEvents(
    callback: (payload: CustomInspirationsGalleryDetectionStartedEvent) => void
  ): ClearListener {
    return this.subscribeToEvents({
      topicKey: CustomInspirationsGalleryEventTopicNames.TagsDetectionStarted,
      callback,
    });
  }

  subscribeToGalleryTagsDetectionEndedEvents(
    callback: (payload: CustomInspirationsGalleryDetectionEndedEvent) => void
  ): ClearListener {
    return this.subscribeToEvents({
      topicKey: CustomInspirationsGalleryEventTopicNames.TagsDetectionEnded,
      callback,
    });
  }
}

export const galleriesWebSocketService = new GalleriesWebSocketService();
