import { DomainEntityPath } from '../types';

import { DomainEventsWebSocketServiceBase, DomainEvent } from '../domain-events';
import { ClearListener } from '../web-socket.service';

export enum ShopVersionEventTopicNames {
  UpdateDraft = 'versions:draft-update',
  DiscardDraft = 'versions:draft-discarded',
}

type ShopVersionDraftEventPayload = {
  shopId: number;
};

export type ShopVersionDraftUpdateEvent = DomainEvent<
  ShopVersionDraftEventPayload,
  ShopVersionEventTopicNames
>;

export class ShopVersionWebSocketService extends DomainEventsWebSocketServiceBase<ShopVersionEventTopicNames> {
  domainEntity = DomainEntityPath.Versions;

  subscribeToDraftUpdateEvents(
    onDraftUpdate: (payload: ShopVersionDraftUpdateEvent) => void
  ): ClearListener {
    return this.subscribeToEvents({
      topicKey: ShopVersionEventTopicNames.UpdateDraft,
      callback: onDraftUpdate,
    });
  }

  subscribeToDraftDiscardEvents(
    onDraftDiscard: (payload: ShopVersionDraftUpdateEvent) => void
  ): ClearListener {
    return this.subscribeToEvents({
      topicKey: ShopVersionEventTopicNames.DiscardDraft,
      callback: onDraftDiscard,
    });
  }
}

export const shopVersionWebSocketService = new ShopVersionWebSocketService();
