import styled from '@emotion/styled';

export const BlurredImageContainerStyled = styled.div`
  position: relative;

  .syte-blurred-image-not-found {
    border-radius: 8px;
  }

  .syte-blurred-image-blur-container {
    aspect-ratio: 1 / 1;
    border-radius: 8px;

    .syte-blurred-image-blur {
      border-radius: 8px;
    }

    .syte-blurred-image-container {
      border-radius: 8px;

      .syte-blurred-image {
        border-radius: 4px;
      }
    }
  }
`;
