import { ShopDataField } from '../data-fields';

export interface Feed {
  name: string;
  aiModel?: string;
}

export interface FeedMetadataPartial {
  feeds?: Feed[];
  items?: ShopDataField[];
}

export enum Route {
  DeepTags = 'deep-tags',
}

export interface FeedMetadata {
  deeptags?: FeedMetadataPartial;
}
