import React, { useMemo } from 'react';
import { LexiconRuleForm, LexiconRuleFormProps } from '../LexiconRuleForm';
import { LexiconRuleFormSkeleton } from '../LexiconRuleForm/components';
import { LexiconRuleModalStyled } from './LexiconRuleModal.styles';

export interface LexiconRuleModalProps
  extends Pick<LexiconRuleFormProps, 'mode' | 'shopId' | 'locale' | 'dispatch' | 'lexiconRule'> {
  onClose: () => void;
  onSuccess: () => void;
}

export const LexiconRuleModal = React.memo(
  ({
    mode,
    shopId,
    locale,
    onClose,
    onSuccess,
    dispatch,
    lexiconRule,
  }: LexiconRuleModalProps): JSX.Element => {
    const isLoading = useMemo(() => mode === 'edit' && !lexiconRule, [mode, lexiconRule]);

    return (
      <LexiconRuleModalStyled open onClose={onClose}>
        {isLoading ? (
          <LexiconRuleFormSkeleton />
        ) : (
          <LexiconRuleForm
            mode={mode}
            shopId={shopId}
            locale={locale}
            onClose={onClose}
            dispatch={dispatch}
            lexiconRule={lexiconRule}
            onSuccess={onSuccess}
          />
        )}
      </LexiconRuleModalStyled>
    );
  }
);
