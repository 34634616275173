import React from 'react';
import { Checkbox } from '../../CheckBox';
import { EditableCheckBoxCellProps } from '../types';
import { useEditableCell } from '../hooks';

const COMPONENT_NAME = 'EditableCheckBoxCell';

export const EditableCheckBoxCell = <T extends Record<string, unknown>>({
  value,
  row: { index },
  column: { id, disabled, cellProps },
  updateData,
  ...rest
}: EditableCheckBoxCellProps<T>): JSX.Element => {
  const dataId =
    cellProps && cellProps.prefixId ? `${cellProps.prefixId}_${COMPONENT_NAME}_index_${index}` : '';

  const { currentValue, onChange } = useEditableCell<boolean | undefined>({
    rowIndex: index,
    columnId: id as string,
    value,
    updateData,
  });

  return (
    <Checkbox
      prefixId={dataId}
      checked={currentValue}
      onChange={onChange}
      disabled={disabled}
      {...rest}
    />
  );
};
