import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, Redirect, useRouteMatch, useLocation } from 'react-router-dom';
import { AppRoute, AppSwitch, RoutedComponentProps } from 'src/app-routes';
import { collectionNavigationActionMethods } from 'src/components-bl';
import { AvailableIcons, Page, PageSideNav } from 'src/components-dummy';
import { CollectionBreadCrumbs } from 'src/components-bl/Collections/CollectionBreadCrumbs';
import { usePreviewModal } from 'src/components-bl/VisualEditor/hooks';
import { visualEditorActions } from 'src/components-bl/VisualEditor/state';
import { ShopFeature } from 'src/app-types';
import { CollectionSummary } from 'src/components-bl/Collections/EditCollectionRules/CollectionSummary/CollectionSummary';
import { EditCollectionRulesContainer } from '../../components-bl/Collections/EditCollectionRules/CollectionRules/CollectionRules/EditCollectionRulesContainer';
import { EditRankingStrategyContainer, EditMerchandisingRulesContainer } from './components';
import { PageNotFoundContainer } from '../PageNotFoundContainer';
import { useAppSelector } from '../../hooks';
import { editCollectionActionMethods } from './actions';
import { getLastPathSegment, routeNameMappings } from './actions/editCollectionLinks';
import { ComponentRoute } from './types';
import {
  EditCollectionContainerStyled,
  LearnMoreLink,
  LinkIconStyled,
  PreviewButton,
  PreviewLinkWrapper,
} from './EditCollectionContainer.styles';

const learnMoreLink = 'https://support.syte.ai/space/SP/2735768822/C.+Managing+Collections';

type EditCollectionContainerProps = RoutedComponentProps & { shopId: number };

export const EditCollectionContainer: React.FC<EditCollectionContainerProps> = ({
  permittedRouteMap,
  shopId,
}) => {
  const dispatch = useDispatch();
  const formHeaderRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const displayName = getLastPathSegment(location.pathname, routeNameMappings);

  const {
    params: { collectionId },
  } = useRouteMatch<{ collectionId: string }>();
  const { currentCollection } = useAppSelector(state => state.collections);
  const { openModal } = usePreviewModal({ shopId, permittedRouteMap });

  const handlePreviewButtonClick = useCallback(() => {
    if (!currentCollection) return;
    openModal();
    dispatch(visualEditorActions.changeExperience(ShopFeature.Collections));
    dispatch(visualEditorActions.setCollection(currentCollection));
  }, [currentCollection]);

  useEffect(() => {
    if (shopId) {
      dispatch(
        editCollectionActionMethods.getCollection({
          shopId,
          collectionId,
        })
      );
    }
  }, [shopId]);

  const relevantRoutes = useMemo(() => {
    const props = {
      shopId,
      currentCollection,
      formHeaderRef,
      dispatch,
    };

    const routes = [
      {
        route: permittedRouteMap.editCollectionRules,
        Component: EditCollectionRulesContainer,
        props,
      },
      {
        route: permittedRouteMap.editCollectionMerchandisingRules,
        Component: EditMerchandisingRulesContainer,
        props,
      },
      {
        route: permittedRouteMap.editCollectionRankingStrategy,
        Component: EditRankingStrategyContainer,
        props,
      },
      {
        route: permittedRouteMap.editCollectionSettings,
        Component: CollectionSummary,
        props,
      },
    ].filter(routeComponent => Boolean(routeComponent.route)) as ComponentRoute[];
    return routes;
  }, [
    permittedRouteMap.editCollectionRules,
    permittedRouteMap.editCollectionMerchandisingRules,
    permittedRouteMap.editCollectionRankingStrategy,
    permittedRouteMap.editCollectionSettings,
    formHeaderRef.current,
    shopId,
    currentCollection,
  ]);

  const routeComponents = useMemo(
    () =>
      relevantRoutes.map(({ route, Component, props }) => (
        <AppRoute key={route.path} route={route} Component={Component} componentProps={props} />
      )),
    [relevantRoutes]
  );

  const sideNavRoutes = useMemo(
    () => [
      {
        routes: relevantRoutes.map(({ route }) => ({
          url: generatePath(route.path, { collectionId, shopId }),
          title: route.title,
          pathTemplate: route.path,
        })),
      },
    ],
    [relevantRoutes, shopId]
  );

  const onNavigate = useCallback(
    url => {
      dispatch(collectionNavigationActionMethods.navigateTo({ navigateTo: url }));
    },
    [dispatch]
  );

  if (!currentCollection?.id) {
    return <PageNotFoundContainer />;
  }

  return (
    <EditCollectionContainerStyled layout='sidebar'>
      <Page.Header>
        <CollectionBreadCrumbs
          shopId={shopId}
          dispatch={dispatch}
          permittedRouteMap={permittedRouteMap}
          pageTitle={displayName}
          collectionName={currentCollection?.name}
          collectionId={currentCollection?.id}
        />
        <div ref={formHeaderRef} />
        <PreviewLinkWrapper>
          <LearnMoreLink>
            <a href={learnMoreLink} target='_blank' rel='noreferrer'>
              Learn more about Syte Collections
              <LinkIconStyled name={AvailableIcons.ArrowRec} />
            </a>
            <PreviewButton
              variant='primary'
              startIcon={AvailableIcons.PreviewButton}
              onClick={handlePreviewButtonClick}
            >
              Preview
            </PreviewButton>
          </LearnMoreLink>
        </PreviewLinkWrapper>
      </Page.Header>
      <Page.SideBar>
        <PageSideNav routeGroups={sideNavRoutes} handleNavigate={onNavigate} />
      </Page.SideBar>
      <Page.Content className='collections'>
        <AppSwitch>
          {routeComponents}
          {permittedRouteMap.editCollectionRules && (
            <Redirect to={permittedRouteMap.editCollectionRules.path} />
          )}
        </AppSwitch>
      </Page.Content>
    </EditCollectionContainerStyled>
  );
};
