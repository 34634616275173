import { FilterDataSource, FilterSortBy, FilterType } from 'src/services';
import Joi, { AnySchema } from 'joi';
import { getStringEnumValues } from 'src/utils';
import { CustomOrderValue, FilterDraft } from './types';

export const MAX_ALLOWED_CUSTOM_ORDER_VALUES = 500;

export const defaultValuesSorting = {
  sortBy: FilterSortBy.ResultsCount,
};

export const initialDraftState: FilterDraft = {
  enabled: true,
  dataSource: FilterDataSource.Catalog,
  sourceField: undefined,
  valuesSorting: undefined,
};

export enum FilterFormValidationKeys {
  DataFieldIsTaken = 'data-field-is-taken',
  DataFieldIsNotAvailable = 'data-field-is-not-available',
  MaxValuesReached = 'max-values-reached',
  AtLeastOneNonEmptyValueRequired = 'at-least-one-non-empty-value-required',
}

const customOrderSchema = Joi.array()
  .items(
    Joi.object().keys({
      value: Joi.string(),
    })
  )
  .custom((value, { original: values, error }): any => {
    const nonEmptyValues = values.filter((option: CustomOrderValue) => !!option.value);

    if (nonEmptyValues.length > MAX_ALLOWED_CUSTOM_ORDER_VALUES) {
      return error(FilterFormValidationKeys.MaxValuesReached);
    }

    if (nonEmptyValues.length === 0) {
      return error(FilterFormValidationKeys.AtLeastOneNonEmptyValueRequired);
    }
    return value;
  })
  .unique((a, b) => a.value === b.value)
  .messages({
    'array.unique': 'Duplicate values are not allowed',
    'any.required': `Please, add at least one value`,
    [FilterFormValidationKeys.MaxValuesReached]: `Maximum ${MAX_ALLOWED_CUSTOM_ORDER_VALUES} values allowed`,
    [FilterFormValidationKeys.AtLeastOneNonEmptyValueRequired]: `Please, select at least one value`,
  });

const valuesSortingValidationSchema = {
  sortBy: Joi.string()
    .required()
    .valid(...getStringEnumValues(FilterSortBy)),
  customOrder: Joi.alternatives().conditional('sortBy', {
    is: FilterSortBy.CustomOrder,
    then: customOrderSchema.min(1).required(),
    otherwise: customOrderSchema.optional(),
  }),
};

export const formValidationSchema: Record<keyof Omit<FilterDraft, 'id'>, AnySchema> = {
  dataSource: Joi.string()
    .required()
    .valid(...getStringEnumValues(FilterDataSource)),
  enabled: Joi.boolean(),
  sourceField: Joi.alternatives()
    .conditional('dataSource', {
      is: FilterDataSource.Catalog,
      then: Joi.string()
        .trim()
        .allow('')
        .custom((value, { prefs: { context }, error }): any => {
          const isValidSource = context?.validCatalogSourceFields?.includes(value);

          if (!isValidSource) return error(FilterFormValidationKeys.DataFieldIsNotAvailable);

          const shouldValidateDataFieldIsNotTaken = !!context?.dataFieldsFromOtherFilters;

          if (shouldValidateDataFieldIsNotTaken) {
            const currentFieldIsAlreadyTaken = context?.dataFieldsFromOtherFilters?.has(value);

            if (currentFieldIsAlreadyTaken) return error(FilterFormValidationKeys.DataFieldIsTaken);
          }

          return value;
        })
        .required(),
      otherwise: Joi.string(),
    })
    .messages({
      'any.required': 'Source field can not be empty',
      [FilterFormValidationKeys.DataFieldIsNotAvailable]: 'Source field is not available anymore',
      [FilterFormValidationKeys.DataFieldIsTaken]: 'Source field is used in another filter',
    }),

  valuesSorting: Joi.object().keys(valuesSortingValidationSchema).optional(),
  type: Joi.required().valid(...getStringEnumValues(FilterType)),
};
