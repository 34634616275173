import React, { useCallback, useMemo } from 'react';
import { MenuItem, SelectType } from 'src/components-dummy';
import { TagFieldSelectStyled } from './TagRow.styles';
import { TagFields } from '../../../LexiconRuleForm.types';

interface TagFieldSelectProps {
  tagField?: TagFields;
  onChange: (newTagField: TagFields) => void;
  error: string | undefined;
  availableFields: TagFields[];
}

export const TagFieldSelect = ({
  tagField,
  onChange,
  error,
  availableFields,
}: TagFieldSelectProps): JSX.Element => {
  const onSelectChange = useCallback(
    event => {
      onChange(event.target.value as TagFields);
    },
    [onChange]
  );

  const options = useMemo(() => {
    return Object.values(TagFields)
      .filter(field => availableFields.includes(field))
      .map(field => (
        <MenuItem key={field} value={field}>
          {field}
        </MenuItem>
      ));
  }, [availableFields]);

  return (
    <TagFieldSelectStyled
      type={SelectType.Menu}
      value={tagField}
      onChange={onSelectChange}
      placeholder='Select...'
      isError={!!error}
      disabled={availableFields.length === 0}
    >
      {options}
    </TagFieldSelectStyled>
  );
};
