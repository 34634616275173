import React from 'react';
import { TypographyProps, TypographyType, TypographyVariant } from 'src/components-dummy';
import {
  CatalogFormSectionStyled,
  CatalogFormSectionHeaderStyled,
  CatalogFormSectionHeaderSubtitleStyled,
} from './CatalogFormSection.styles';

export type RuleFormSectionHeaderProps = TypographyProps;

export const CatalogFormSectionHeader = ({
  children,
  ...rest
}: RuleFormSectionHeaderProps): JSX.Element => {
  return (
    <CatalogFormSectionHeaderStyled
      type={TypographyType.Body}
      variant={TypographyVariant.LargeBold}
      {...rest}
    >
      {children}
    </CatalogFormSectionHeaderStyled>
  );
};

export const CatalogFormSectionHeaderSubtitle = ({
  children,
  ...rest
}: RuleFormSectionHeaderProps): JSX.Element => {
  return (
    <CatalogFormSectionHeaderSubtitleStyled
      type={TypographyType.Body}
      variant={TypographyVariant.LargeBold}
      {...rest}
    >
      {children}
    </CatalogFormSectionHeaderSubtitleStyled>
  );
};

export const CatalogFormSection = CatalogFormSectionStyled as typeof CatalogFormSectionStyled & {
  Header: typeof CatalogFormSectionHeader;
};

CatalogFormSection.Header = CatalogFormSectionHeader;
