import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const ProductCardStyled = styled.div`
  display: flex;
  position: relative;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-80']};
  border-radius: 12px;
  overflow: hidden;
  height: 200px;
  padding-right: 24px;

  &:hover {
    box-shadow:
      0px 8px 8px -4px rgba(0, 0, 0, 0.08),
      0px 4px 6px -4px rgba(0, 0, 0, 0.12);
  }
`;

export const ProductImageContainerStyled = styled.div`
  width: 200px;
  height: 100%;
  min-width: 200px;
  background-color: ${({ theme }) => theme.palette.custom['gray-80']};
  display: flex;
  background: ${({ theme }) => theme.palette.custom.white};
  cursor: pointer;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const ProductDetailsSectionStyled = styled.div`
  flex: 1;
  padding-top: 24px;
  padding-bottom: 35px;
  padding-left: 20px;
  min-width: 0; /** needed for parent flex calculations, to support child ellipsis. otherwise need width in px */
  cursor: pointer;
`;

export const ProductTitleWrapper = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
`;

export const SKUTextStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-30']};
  display: block;
`;

export const TagsContainerStyled = styled.div`
  display: grid;
  column-gap: 58px;
  row-gap: 11.86px;
  grid-auto-flow: column;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const TagItemStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const UpdatedAtTextStyled = styled(Typography)`
  display: block;
  /* position: absolute;
  top: 24px;
  right: 24px; */
  color: ${({ theme }) => theme.palette.custom['gray-20']};
`;

export const TagStatusLabelStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-30']};
  margin-left: 4px;
  font-style: italic;
`;

export const ProductActionsAndDetailsStyled = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ProductActionsAndDetailsWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 32px;
  align-items: center;
`;
