import styled from '@emotion/styled';
import { ActionIcon, AutoComplete, Button, Icon, Select, Typography } from 'src/components-dummy';

export const ConditionGroupStyled = styled.div``;

export const ConditionGroupsStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddNewConditionGroupButtonStyled = styled(Button)`
  color: ${({ theme }) => theme.palette.custom['primary-main']};
  max-width: fit-content;
  margin-top: 16px;
  margin-bottom: 32px;

  .syte-basic-icon {
    path {
      stroke: ${({ theme }) => theme.palette.custom['primary-main']};
    }
  }
`;

export const OrIndicationButtonStyled = styled(Button)`
  min-width: 50px;
  margin-bottom: 40px;

  .syte-basic-icon {
    width: 9px;
    height: 9px;

    path {
      stroke: ${({ theme }) => theme.palette.common.white};
    }
  }
`;

export const ConditionRuleStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 40px;
`;

export const ConditionRulePrefixTypographyStyled = styled(Typography)`
  min-width: 50px;
  width: 100px;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const ConditionActionIconWrapperStyled = styled(ActionIcon)`
  margin-right: 4px;
`;

export const ConditionActionIconStyled = styled(Icon)`
  path {
    stroke: ${({ theme }) => theme.palette.custom['gray-20']};
  }
`;

export const LexiconTagSelectStyled = styled(Select)`
  margin-right: 4px;

  .MuiInputBase-root {
    display: flex;
  }

  .syte-error-label {
    position: absolute;
  }
`;

export const LexiconTagAutoCompleteStyled = styled(AutoComplete)`
  margin-right: 4px;
`;
