import React, { useState, useEffect, useMemo } from 'react';
import {
  Typography,
  TypographyType,
  TypographyVariant,
  PopUp,
  AvailableIcons,
  MultiSelectMenuWithSearch,
  Button,
  MultiSelectMenu,
  MultiSelectValue,
  EllipsisWithTooltip,
} from 'src/components-dummy';
import { sortBy } from 'lodash';
import {
  FilterTagStyled,
  FilterTagFooterStyled,
  FilterTagContentStyled,
  RemoveFilterTagIconStyled,
} from '../FiltersRow.styles';
import { MultiSelectFilterTagProps } from '../types';
import {
  PopupHeaderStyled,
  PopupHeaderTypographyStyled,
  MultiSelectTagLabelStyled,
  MultiSelectTagContentStyled,
} from './MultipleSelectFilterTag.styles';

const MAX_SELECTED_TO_SHOW = 2;

const PopupHeader = ({ options, title }: { options: string[]; title: string }): JSX.Element => {
  const tooltipText = options.join(', ');
  return (
    <PopupHeaderStyled>
      <EllipsisWithTooltip tooltipText={tooltipText}>{tooltipText}</EllipsisWithTooltip>
      <PopupHeaderTypographyStyled
        type={TypographyType.Paragraph}
        variant={TypographyVariant.MediumRegular}
        isTitle={false}
      >
        {'>'}
      </PopupHeaderTypographyStyled>
      <PopupHeaderTypographyStyled
        type={TypographyType.Paragraph}
        variant={TypographyVariant.MediumRegular}
        isTitle
      >
        {title}
      </PopupHeaderTypographyStyled>
    </PopupHeaderStyled>
  );
};

export const MultipleSelectFilterTag = ({
  filterKey,
  onChange,
  onRemoveFilter,
  filterState,
  searchPlaceholder,
  options,
  enableSearch,
  enableToggleAll,
  shouldTriggerOpen,
  labelText,
  shouldAlwaysShowSelectionItemsText, // don't show all values text.
  applyText = 'Apply',
  selectedParentOptions,
  headerTitle = 'Filter',
  onCloseFilter,
  tagPrefix,
}: MultiSelectFilterTagProps): JSX.Element => {
  const [searchInputRef, setSearchInputRef] = useState<HTMLInputElement | null>(null);

  const valueToTextMap = useMemo(() => {
    return options.reduce(
      (accumulator, option) => {
        accumulator[option.value] = option.text;

        return accumulator;
      },
      {} as Record<MultiSelectValue, string>
    );
  }, [options]);

  const initialSelectedOptions = filterState?.value ? [...(filterState.value as string[])] : [];

  const selectOptionsSorted = useMemo(() => {
    if (enableToggleAll) {
      const selectedOptionsSet = new Set(initialSelectedOptions);
      return sortBy(options, option => `${!selectedOptionsSet.has(option.value)}${option.value}`);
    }
    return options;
  }, [initialSelectedOptions, options]);

  const [selectedOptions, setSelectedOptions] = useState<string[]>(initialSelectedOptions);
  const [shouldShowPopup, setShouldShowPopup] = useState(false);

  const selectedCount = filterState?.value?.length || 0;

  const selectedTagsText = useMemo(() => {
    const areAllSelected = !shouldAlwaysShowSelectionItemsText && selectedCount === options.length;

    const optionsToShow =
      filterState?.value?.slice(0, MAX_SELECTED_TO_SHOW).map(optionValue => {
        const text = valueToTextMap[optionValue];
        return text;
      }) || [];

    const hasMoreOptionsSelected = selectedCount > MAX_SELECTED_TO_SHOW;

    let resultText = optionsToShow.length ? optionsToShow.join(',') : '';
    if (areAllSelected) {
      resultText = 'All Values';
    } else if (hasMoreOptionsSelected) {
      resultText = `${resultText},...`;
    }
    return resultText;
  }, [filterState, valueToTextMap, options]);

  const onMultipleSelectChange = (newOptions: string[]) => {
    setSelectedOptions([...newOptions]);
  };

  const onRemoveTag = () => {
    onRemoveFilter?.(filterKey);
  };

  const onShowPopupChange = (show: boolean) => {
    setShouldShowPopup(show);

    if (!show && filterState?.isApplied === false) {
      onRemoveTag();
    } else if (!show) {
      onCloseFilter?.();
    }
  };

  const onClose = () => {
    setShouldShowPopup(false);
    onCloseFilter?.();
  };

  const onSave = () => {
    onChange({ [filterKey]: { value: selectedOptions, isApplied: true } });
    onClose();
  };

  const onCancel = () => {
    onShowPopupChange(false);
    setSelectedOptions(initialSelectedOptions);
    onClose();
  };

  const popupHeader = selectedParentOptions?.length ? (
    <PopupHeader options={selectedParentOptions} title={headerTitle} />
  ) : null;

  // Sync incoming state with internal
  useEffect(() => {
    const currentStateSet = new Set(selectedOptions);

    const filteredOptionsByAvailability = initialSelectedOptions.filter(
      optionValue => valueToTextMap[optionValue]
    );

    const incomingStateIsEqual =
      currentStateSet.size === filteredOptionsByAvailability.length &&
      filteredOptionsByAvailability.every(option => currentStateSet.has(option));

    if (!incomingStateIsEqual) {
      setSelectedOptions(initialSelectedOptions);
      onChange({
        [filterKey]: {
          value: filteredOptionsByAvailability,
          isApplied: true,
        },
      });
    }
  }, [filterState]);

  useEffect(() => {
    if (shouldTriggerOpen) {
      setShouldShowPopup(true);
    }
  }, [shouldTriggerOpen]);

  useEffect(() => {
    const shouldFocusOnSearch =
      enableSearch && shouldTriggerOpen && searchInputRef && selectedOptions.length === 0;

    if (shouldFocusOnSearch) {
      searchInputRef.focus();
    }
  }, [searchInputRef]);

  return (
    <PopUp
      show={shouldShowPopup}
      onShow={onShowPopupChange}
      closeOnTriggerClick={false}
      hideOnScroll={false}
      position='bottom left'
    >
      <PopUp.Trigger>
        <FilterTagStyled isClickable clicked={shouldShowPopup}>
          <Typography type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
            {tagPrefix || 'Value is'}
          </Typography>
          {filterState?.isApplied && (
            <EllipsisWithTooltip key={selectedTagsText} tooltipText={selectedTagsText || ''}>
              <Typography type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
                {selectedTagsText}
              </Typography>
            </EllipsisWithTooltip>
          )}
          <RemoveFilterTagIconStyled name={AvailableIcons.Close} onClick={onRemoveTag} />
        </FilterTagStyled>
      </PopUp.Trigger>

      <PopUp.Content>
        <FilterTagContentStyled>
          <MultiSelectTagContentStyled>
            {popupHeader ||
              (labelText && (
                <MultiSelectTagLabelStyled
                  type={TypographyType.Paragraph}
                  variant={TypographyVariant.SmallRegular}
                >
                  {labelText}
                </MultiSelectTagLabelStyled>
              ))}

            {enableSearch ? (
              <MultiSelectMenuWithSearch
                searchPlaceholder={searchPlaceholder}
                options={selectOptionsSorted}
                selectedOptions={selectedOptions}
                onChange={onMultipleSelectChange}
                enableSelectAll={enableToggleAll}
                searchInputRef={setSearchInputRef}
              />
            ) : (
              <MultiSelectMenu
                options={selectOptionsSorted}
                selectedOptions={selectedOptions}
                onChange={onMultipleSelectChange}
                enableSelectAll={enableToggleAll}
              />
            )}
          </MultiSelectTagContentStyled>
          <FilterTagFooterStyled>
            <Button variant='tertiary' onClick={onCancel}>
              Cancel
            </Button>
            <Button onClick={onSave} disabled={selectedOptions.length === 0} variant='primary'>
              {applyText}
            </Button>
          </FilterTagFooterStyled>
        </FilterTagContentStyled>
      </PopUp.Content>
    </PopUp>
  );
};
