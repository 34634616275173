import { PersonalizationSettingsTypes } from '../types';

export const buildPersonalizationSettingsPayload = (
  payload: Partial<PersonalizationSettingsTypes.PersonalizationSettings>
): Partial<PersonalizationSettingsTypes.PersonalizationSettings> => {
  const payloadData = {
    numberOfResultsToDisplay: payload.numberOfResultsToDisplay,
    personalizationFallbackMethod: payload.personalizationFallbackMethod,
    useSessionSkusFromPageViewEvent: payload.useSessionSkusFromPageViewEvent,
    addToCart: payload.addToCart,
    personalizationFallbackField: payload.personalizationFallbackField,
    sortingOrder: payload.sortingOrder,
  };

  return payloadData;
};
