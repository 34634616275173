import { Dispatch } from 'src/components-bl';
import { experimentService, ExperimentStatus } from 'src/services';
import {
  ExperimentButtonsActionPayload,
  ExperimentButtonsActionTypes,
  ExperimentButtonsErrorActionPayload,
  ExperimentButtonsRunExperimentErrorAction,
  ExperimentButtonsRunExperimentRequestAction,
  ExperimentButtonsRunExperimentSuccessAction,
  ExperimentButtonsStopExperimentErrorAction,
  ExperimentButtonsStopExperimentRequestAction,
  ExperimentButtonsStopExperimentSuccessAction,
} from './types';

const experimentActionButtonsActions = {
  runExperimentRequest(
    payload: ExperimentButtonsActionPayload
  ): ExperimentButtonsRunExperimentRequestAction {
    return {
      type: ExperimentButtonsActionTypes.RunExperimentRequest,
      payload,
    };
  },

  runExperimentSuccess(
    payload: ExperimentButtonsActionPayload
  ): ExperimentButtonsRunExperimentSuccessAction {
    return {
      type: ExperimentButtonsActionTypes.RunExperimentSuccess,
      payload,
    };
  },

  runExperimentError(
    payload: ExperimentButtonsErrorActionPayload
  ): ExperimentButtonsRunExperimentErrorAction {
    return {
      type: ExperimentButtonsActionTypes.RunExperimentError,
      payload,
    };
  },

  stopExperimentRequest(
    payload: ExperimentButtonsActionPayload
  ): ExperimentButtonsStopExperimentRequestAction {
    return {
      type: ExperimentButtonsActionTypes.StopExperimentRequest,
      payload,
    };
  },

  stopExperimentSuccess(
    payload: ExperimentButtonsActionPayload
  ): ExperimentButtonsStopExperimentSuccessAction {
    return {
      type: ExperimentButtonsActionTypes.StopExperimentSuccess,
      payload,
    };
  },

  stopExperimentError(
    payload: ExperimentButtonsErrorActionPayload
  ): ExperimentButtonsStopExperimentErrorAction {
    return {
      type: ExperimentButtonsActionTypes.StopExperimentError,
      payload,
    };
  },
};

export const experimentActionButtonsActionMethods = {
  runExperiment(payload: ExperimentButtonsActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      if (!payload.experiment) {
        return;
      }
      try {
        dispatch(experimentActionButtonsActions.runExperimentRequest(payload));
        const updatedExperiment = await experimentService.updateExperiment({
          ...payload,
          experiment: { id: payload.id, status: ExperimentStatus.Running },
        });
        dispatch(
          experimentActionButtonsActions.runExperimentSuccess({
            ...payload,
            experiment: updatedExperiment,
          })
        );
      } catch (error) {
        dispatch(experimentActionButtonsActions.runExperimentError({ error }));
      }
    };
  },

  stopExperiment(payload: ExperimentButtonsActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      if (!payload.experiment) {
        return;
      }
      try {
        dispatch(experimentActionButtonsActions.stopExperimentRequest(payload));
        const updatedExperiment = await experimentService.updateExperiment({
          ...payload,
          experiment: { id: payload.id, status: ExperimentStatus.Ended },
        });
        dispatch(
          experimentActionButtonsActions.stopExperimentSuccess({
            ...payload,
            experiment: updatedExperiment,
          })
        );
      } catch (error) {
        dispatch(experimentActionButtonsActions.stopExperimentError({ error }));
      }
    };
  },
};
