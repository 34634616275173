import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { isEqual, isString } from 'lodash';
import { FiltersRowStyled } from './FiltersRow.styles';
import { useFindComponentsByType, useDebounce } from '../hooks';
import {
  FiltersRowProps,
  FilterTagProps,
  AppliedFilters,
  FiltersState,
  FilterOption,
  FilterConfigsMap,
  FilterState,
} from './types';
import { FilterTagFactory, AddFilter } from './components';

const Filter = (_: FilterTagProps<any>): null => {
  return null;
};

export function getAppliedFiltersWithValue(filters: FiltersState): AppliedFilters {
  return Object.keys(filters).reduce((accumulator, filterKey) => {
    const filter = filters[filterKey];

    if (filter?.isApplied) {
      const filterValue = filter.value;

      let filterIsEmpty = false;

      if (filterValue === undefined) {
        filterIsEmpty = true;
      } else if (Array.isArray(filterValue) && filterValue.length === 0) {
        filterIsEmpty = true;
      } else if (isString(filterValue) && filterValue.trim().length === 0) {
        filterIsEmpty = true;
      }

      if (!filterIsEmpty) {
        accumulator[filterKey] = filter.value;
      }
    }
    return accumulator;
  }, {} as AppliedFilters);
}

function sortFilterKeys({
  a,
  b,
  configMap,
}: {
  a: string;
  b: string;
  configMap: FilterConfigsMap;
}): number {
  return configMap[a].index > configMap[b].index ? 1 : -1;
}

function getNewFilterDefault(_: string): FilterState {
  return {
    isApplied: false,
    value: undefined,
  };
}

export const FiltersRow = ({
  children,
  onChange,
  filters = {},
  getNewFilter = getNewFilterDefault,
}: FiltersRowProps): JSX.Element => {
  const filterTags = useFindComponentsByType({ type: Filter, children });

  const filterConfigsMap = useMemo(() => {
    const result = filterTags.reduce((accumulator, current, index) => {
      const props = current.props as FilterTagProps<any>;

      accumulator[props.uniqueFilterKey] = { ...props, index };

      return accumulator;
    }, {} as FilterConfigsMap);

    return result;
  }, [filterTags]);

  const filtersOptions = useMemo(() => {
    const allOptions = Object.keys(filterConfigsMap).map(key => {
      return {
        key,
        displayName: filterConfigsMap[key].menuName,
      };
    });

    const keysToDisplay = allOptions.filter(option => !!option.displayName) as FilterOption[];

    return keysToDisplay.sort((a, b) =>
      sortFilterKeys({ a: a.key, b: b.key, configMap: filterConfigsMap })
    );
  }, [filterConfigsMap]);

  const [filtersState, setFiltersState] = useState(filters);
  const [openedFilterKey, setOpenedFilterKey] = useState<string | undefined>(undefined);

  const onFilterChange = useCallback(
    (partialState: FiltersState) => {
      const updatedState = { ...filtersState, ...partialState };
      setFiltersState(updatedState);
    },
    [filtersState]
  );

  const onAddFilter = (filterKey: string) => {
    const isNewFilter = filtersState[filterKey] === undefined;

    if (isNewFilter) {
      onFilterChange({
        [filterKey]: getNewFilter(filterKey),
      });
    }
    setOpenedFilterKey(filterKey);
  };

  const onRemoveFilter = (filterKey: string) => {
    const stateCopy = { ...filtersState };

    delete stateCopy[filterKey];
    setFiltersState(stateCopy);
  };

  const onCloseFilter = () => {
    setOpenedFilterKey(undefined);
  };

  const debouncedOnChange = useDebounce(onChange, 350);

  useEffect(() => {
    debouncedOnChange(filtersState);
  }, [filtersState]);

  useEffect(() => {
    const shouldSyncState = !isEqual(filters, filtersState);

    if (shouldSyncState) {
      setFiltersState(filters);
      setOpenedFilterKey(undefined);
    }
  }, [filters]);

  const filtersTagsKeysToShow = Object.keys(filtersState).sort((a, b) =>
    sortFilterKeys({ a, b, configMap: filterConfigsMap })
  );

  return (
    <FiltersRowStyled>
      {filtersTagsKeysToShow.map(filterKey => {
        const filterConfig = filterConfigsMap[filterKey];

        return (
          <FilterTagFactory
            key={filterConfig.uniqueFilterKey}
            type={filterConfig.type}
            tagPrefix={filterConfig.tagPrefix}
            menuName={filterConfig.menuName}
            componentConfig={filterConfig.componentConfig}
            filterKey={filterKey}
            filterState={filtersState[filterKey]}
            onChange={onFilterChange}
            onRemoveFilter={onRemoveFilter}
            onCloseFilter={onCloseFilter}
            shouldTriggerOpen={openedFilterKey === filterKey}
          />
        );
      })}
      <AddFilter onAddFilter={onAddFilter} filtersOptions={filtersOptions} />
    </FiltersRowStyled>
  );
};

FiltersRow.Filter = Filter;
