import { useSelector } from 'react-redux';
import { ShopDataField } from 'src/services/src/service/types';
import { useEffect, useState } from 'react';
import { RootReducerState } from 'src/app-state-types';
import {
  BucketsOrderSort,
  BucketsOrderType,
  FilterItemDBStructure,
  FilterType,
  TextTypeDisplayOptions,
} from './types';
import { feedsMetadataActions, fetchDeepTagsMetadata } from './feed-metadata-actions';
import { Dispatch } from '../types';

export function generateFilterItemFromDataField(
  dataField: ShopDataField,
  index: number
): FilterItemDBStructure {
  return {
    key: dataField.name,
    enabled: true,
    position: index + 1,
    displayNameKey: dataField.displayName,
    dataSource: dataField.name,
    type: FilterType.text,
    displayType: TextTypeDisplayOptions.list,
    bucketsOrder: {
      type: BucketsOrderType.count,
      sort: BucketsOrderSort.desc,
    },
    numBuckets: 4,
    minBucketDelta: 5,
  };
}

export function mergeDataFieldsWithSavedData(
  savedItems: FilterItemDBStructure[],
  dataFields: ShopDataField[]
): FilterItemDBStructure[] {
  const savedDeepTagsKeys = savedItems ? savedItems.map(savedDT => savedDT.key) : [];
  const initialAcc: FilterItemDBStructure[] = savedItems?.length ? [...savedItems] : [];
  return dataFields?.reduce((acc, df, index) => {
    if (!savedDeepTagsKeys.includes(df.name)) {
      return [...acc, generateFilterItemFromDataField(df, index)];
    }
    return acc;
  }, initialAcc);
}
export function useMergedDeepTagItems(
  savedItems: FilterItemDBStructure[],
  shopId: number,
  dispatcher: Dispatch
): FilterItemDBStructure[] {
  useEffect(() => {
    dispatcher(fetchDeepTagsMetadata({ shopId }));
  }, []);
  const [mergedItems, setMergedItems] = useState<FilterItemDBStructure[]>([]);
  const {
    feedsMetadata: { deeptags },
  } = useSelector((state: RootReducerState) => state);
  useEffect(() => {
    if (!savedItems || !deeptags.items) return;
    const mergedData = mergeDataFieldsWithSavedData(savedItems, deeptags.items);
    if (mergedData.length === 0) {
      dispatcher(feedsMetadataActions.deepTagsNotFoundError({ error: 'Deeptags not found' }));
    }
    setMergedItems(mergedData);
  }, [deeptags, savedItems]);
  return mergedItems;
}
