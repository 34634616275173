import styled from '@emotion/styled';

export const CollectionRuleStyled = styled.div`
  display: flex;
  padding: 4px 0;
  overflow-x: auto;
  gap: 14px;
  width: 100%;

  .syte-popup-trigger,
  .data-fields-auto-complete {
    width: 100%;
  }
`;
