import React, { useCallback, useState } from 'react';
import { useTheme } from '@emotion/react';
import { generatePath } from 'react-router';
import { format } from 'date-fns';
import {
  DeepTagReportStatus,
  DeepTagReportWithMetaData,
  DeepTagReportsGeneralConfigurationLabel,
  ExportDeepTagReportArguments,
  IFilterKeyObject,
  MetaTypes,
  RegenerateProductsTags,
} from 'src/services';
import {
  AvailableIcons,
  Page,
  SelectOnChangeEvent,
  Typography,
  TypographyType,
  TypographyVariant,
  EllipsisWithTooltip,
  NotificationType,
} from 'src/components-dummy';
import { RoutedComponentProps } from 'src/app-routes';
import { ProductRouteFilters } from 'src/containers/DeepTagReports/components/DeepTagReportProductsContainer/components/types';
import { omit } from 'lodash';
import { Dispatch } from '../../../types';
import {
  SubTitleRowStyled,
  MetaDataTextStyled,
  BulletStyled,
  ButtonsContainerStyled,
  TitleRowStyled,
  BackIconStyled,
  DeepTagReportEditableNameWrapper,
} from './DeepTagReportProductPageHeader.styles';
import { DeepTagReportStatusSelect } from '../DeepTagReportStatusSelect';
import { deepTagsReportProductPageHeaderActions } from './DeepTagReportProductPageHeader.actions';
import { DeepTagsReportProductsBulkLabelModal } from '../DeepTagReportProductsBulkLabelModal';
import { DeepTagReportExportReportCsvModal } from '../DeepTagReportExportReportCsvModal/DeepTagReportExportReportCsvModal';
import { DeepTagReportEditableName } from '../DeepTagReportEditableName/DeepTagReportEditableName';
import { DeepTagProductsPageActionsMenu } from './components/DeepTagProductsPageActionsMenu/DeepTagProductsPageActionsMenu';
import { DeepTagReportRegenerateTagsModal } from '../DeepTagReportRegenerateTagsModal/DeepTagReportRegenerateModal';

type ReportDetails = Pick<
  DeepTagReportWithMetaData,
  'sourceFileName' | 'status' | 'totalProducts' | 'createdAt' | 'locale'
>;

interface BulkRegenerateTagsResult {
  totalProducts: number;
  totalProductsWithGenerativeAIFailures: number;
  totalProductsWithRegeneratedThematicsFailures: number;
}

export interface DeepTagReportProductPageHeaderProps extends ReportDetails, RoutedComponentProps {
  shopId: number;
  reportId: string;
  reportCustomName?: string;
  locale: string;
  dispatch: Dispatch;
  localeToDisplayNameMap: Record<string, string>;
  isLexiconEnabled: boolean | undefined;
  generalConfigurationLabels: DeepTagReportsGeneralConfigurationLabel[];
  totalPerProductLabel: Record<string, IFilterKeyObject>;
  getCleanedFiltersWithPagination: () => ProductRouteFilters;
  getProductsWithFilters: () => void;
  supportedLanguagesInShop?: Pick<MetaTypes.Language, 'locale' | 'displayName'>[];
  isContentGenerationPermitted: boolean;
}

export const DeepTagReportProductPageHeader = ({
  shopId,
  reportId,
  reportCustomName,
  sourceFileName,
  status,
  totalProducts,
  createdAt,
  dispatch,
  locale,
  localeToDisplayNameMap,
  generalConfigurationLabels,
  permittedRouteMap,
  totalPerProductLabel,
  isLexiconEnabled,
  getCleanedFiltersWithPagination,
  getProductsWithFilters,
  supportedLanguagesInShop,
  isContentGenerationPermitted,
}: DeepTagReportProductPageHeaderProps): JSX.Element => {
  const theme = useTheme();
  const [shouldShowBulkAssignProductLabel, setShouldShowBulkAssignProductLabel] = useState(false);
  const [shouldShowExportModal, setShouldShowExportModal] = useState(false);
  const [shouldShowRegenerateTagsModal, setShouldShowRegenerateTagsModal] = useState(false);

  const onReportStatusChanged: SelectOnChangeEvent = useCallback(
    event => {
      const newStatus = event.target.value as DeepTagReportStatus;
      dispatch(
        deepTagsReportProductPageHeaderActions.updateReport({
          shopId,
          deepTagReportId: reportId,
          status: newStatus,
        })
      );
    },
    [shopId, reportId]
  );

  const onReportNameChange = useCallback(
    async ({ customName }: { customName: string }) => {
      return dispatch(
        deepTagsReportProductPageHeaderActions.updateReport({
          shopId,
          deepTagReportId: reportId,
          customName,
        })
      );
    },
    [reportId, shopId]
  );

  // TODO: loading indication. block multi click while processing
  // const onExportSFTPClick = useCallback(() => {
  //   dispatch(
  //     deepTagsReportProductPageHeaderActions.exportReportToSFTP({
  //       shopId,
  //       deepTagReportId: reportId,
  //     })
  //   );
  // }, [shopId, reportId]);

  const onExportFileClick = useCallback(
    async (
      formValues: { locale: string } & Pick<
        ExportDeepTagReportArguments,
        'regenerateTitles' | 'regenerateDescriptions' | 'regenerateThematics'
      >
    ) => {
      await dispatch(
        deepTagsReportProductPageHeaderActions.exportReportToFile({
          shopId,
          reportId,
          customExportLocale: formValues.locale !== locale ? formValues.locale : undefined,
          regenerateTitles: formValues.regenerateTitles,
          regenerateDescriptions: formValues.regenerateDescriptions,
          regenerateThematics: formValues.regenerateThematics,
        })
      );

      setShouldShowExportModal(false);
    },
    [dispatch, shopId, reportId, locale]
  );

  const onRegenerateTagsClick = useCallback(
    async (data: RegenerateProductsTags) => {
      const filtersWithPagination = getCleanedFiltersWithPagination();
      const filters = omit(filtersWithPagination, ['skip', 'limit']);

      const response = (await dispatch(
        deepTagsReportProductPageHeaderActions.regenerateProductsTags({
          shopId,
          reportId,
          locale,
          filters,
          data,
        })
      )) as unknown as { error?: any; payload?: { data: BulkRegenerateTagsResult } };

      getProductsWithFilters();
      setShouldShowRegenerateTagsModal(false);

      if (!response.error && response.payload?.data) {
        const {
          totalProductsWithRegeneratedThematicsFailures,
          totalProductsWithGenerativeAIFailures,
        } = response.payload?.data;

        const hasSomeFailures =
          totalProductsWithRegeneratedThematicsFailures || totalProductsWithGenerativeAIFailures;

        let customMessage = 'Not all regenerates has been successful: ';

        if (totalProductsWithGenerativeAIFailures) {
          customMessage += `Failed to regenerate title/description for - ${totalProductsWithGenerativeAIFailures} products; `;
        }

        if (totalProductsWithRegeneratedThematicsFailures) {
          customMessage += `Failed to regenerate Thematic tags for - ${totalProductsWithRegeneratedThematicsFailures} products; `;
        }

        if (hasSomeFailures) {
          dispatch(
            deepTagsReportProductPageHeaderActions.createToast({
              type: NotificationType.Warning,
              customMessage,
            })
          );
        }
      }

      return response;
    },
    [getCleanedFiltersWithPagination, dispatch, shopId, reportId, locale, getProductsWithFilters]
  );

  const onBackButtonClick = useCallback(() => {
    if (permittedRouteMap.deepTagReports && shopId) {
      dispatch(
        deepTagsReportProductPageHeaderActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.deepTagReports.path, { shopId }),
        })
      );
    }
  }, [dispatch, permittedRouteMap.deepTagReports, shopId]);

  const onAssignToClick = useCallback(() => {
    setShouldShowBulkAssignProductLabel(state => !state);
  }, []);

  const onCloseAssignModal = useCallback(() => {
    setShouldShowBulkAssignProductLabel(false);
  }, []);

  const onBulkAssignmentSuccess = useCallback(() => {
    setShouldShowBulkAssignProductLabel(false);
  }, []);

  const onRegenerateAllModalToggle = useCallback(() => {
    setShouldShowRegenerateTagsModal(state => !state);
  }, []);

  const createReportNameComponent = () => {
    const reportName =
      reportCustomName || `${totalProducts.toLocaleString('en-US')} products processed`;

    return (
      <DeepTagReportEditableNameWrapper>
        <DeepTagReportEditableName
          name={reportName}
          onChange={onReportNameChange}
          dynamicWidthMultiplier={12}
          renderText={({ editedText }) => {
            return (
              <Typography type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
                {editedText}
              </Typography>
            );
          }}
        />
      </DeepTagReportEditableNameWrapper>
    );
  };

  return (
    <>
      {shouldShowExportModal && (
        <DeepTagReportExportReportCsvModal
          languages={supportedLanguagesInShop}
          locale={locale}
          onExportFile={onExportFileClick}
          isContentGenerationPermitted={isContentGenerationPermitted}
          onCancel={() => setShouldShowExportModal(false)}
        />
      )}
      {shouldShowBulkAssignProductLabel && (
        <DeepTagsReportProductsBulkLabelModal
          generalConfigurationLabels={generalConfigurationLabels}
          totalPerProductLabel={totalPerProductLabel}
          getCleanedFiltersWithPagination={getCleanedFiltersWithPagination}
          reportId={reportId}
          shopId={shopId}
          locale={locale}
          dispatch={dispatch}
          onContinue={onBulkAssignmentSuccess}
          onCancel={onCloseAssignModal}
        />
      )}
      {shouldShowRegenerateTagsModal && (
        <DeepTagReportRegenerateTagsModal
          onRegenerateTags={onRegenerateTagsClick}
          onCancel={onRegenerateAllModalToggle}
          isContentGenerationPermitted={isContentGenerationPermitted}
        />
      )}
      <SubTitleRowStyled>
        <div>
          <TitleRowStyled>
            <BackIconStyled name={AvailableIcons.ArrowRounded} onClick={onBackButtonClick} />
            <Page.Title variant={TypographyVariant.SmallBold}>
              {createReportNameComponent()}
            </Page.Title>
          </TitleRowStyled>
          <MetaDataTextStyled>
            <EllipsisWithTooltip
              tooltipText={sourceFileName}
              className='input-file-name-tooltip'
              maxWidth={215}
            >
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                {sourceFileName}
              </Typography>
            </EllipsisWithTooltip>
            <BulletStyled size={6} fillColor={theme.palette.custom['gray-60']} />
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Lexicon: {localeToDisplayNameMap[locale] || locale}
            </Typography>
            <BulletStyled size={6} fillColor={theme.palette.custom['gray-60']} />
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              added at: {format(createdAt, 'dd.MM.yyyy')}
            </Typography>
          </MetaDataTextStyled>
          <br />
          {isLexiconEnabled === false ? (
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
              The Lexicon for this Deep Tags Report is disabled. Please contact your Success
              Manager.
            </Typography>
          ) : undefined}
        </div>

        <ButtonsContainerStyled>
          <DeepTagProductsPageActionsMenu
            onExportToCSV={() => setShouldShowExportModal(true)}
            onAssignToClick={onAssignToClick}
            onRegenerateAll={onRegenerateAllModalToggle}
          />
          &nbsp;
          <DeepTagReportStatusSelect value={status} onChange={onReportStatusChanged} isSizeBig />
        </ButtonsContainerStyled>
      </SubTitleRowStyled>
    </>
  );
};
