import { useState, useEffect } from 'react';
import { useIsAnimating, UseIsAnimatingArguments } from './useIsAnimating';

export interface UseTransitionedUmount<T> extends Pick<UseIsAnimatingArguments<T>, 'ref'> {
  show?: boolean;
}
export interface UseTransitionedUnmountReturnType {
  isMounted: boolean;
  isClosing: boolean;
}

export const useTransitionedUnmount = <T>({
  show,
  ref,
}: UseTransitionedUmount<T>): UseTransitionedUnmountReturnType => {
  const [isMounted, setIsMounted] = useState(!!show);
  const [isClosing, setIsClosing] = useState(false);

  const onAnimationEnd = () => {
    setIsMounted(false);
  };

  const onAnimationStart = () => {
    setIsClosing(true);
  };

  useIsAnimating({ ref, onAnimationEnd, onAnimationStart });

  useEffect(() => {
    if (show === isMounted) return;

    if (!isMounted && show) {
      setIsMounted(true);
      setIsClosing(false);
    } else {
      setIsClosing(true);
    }
  }, [show]);

  return { isClosing, isMounted };
};
