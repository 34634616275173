import React, { useState } from 'react';
import { format } from 'date-fns';
import { ExperimentStatus } from 'src/services';
import {
  CardItem,
  PopupMenu,
  PopUpMenuItem,
  ConfirmationDialog,
  Button,
  Typography,
  TypographyVariant,
  TypographyType,
} from 'src/components-dummy';
import classNames from 'classnames';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';
import { useExperimentCard } from './useExperimentCard';
import { ExperimentCardProps } from './types';
import { experimentMetricTranslations } from '../constants';
import './ExperimentCard.scss';
import { getExperimentStatusClass } from '../experiment-helpers';
import { ExperimentsActionButtons } from '../ExperimentActionButtons';
import { UseUuidModal } from './UseUuidModal';

const dateFormat = 'dd-MM-yyyy HH:mm';

export const ExperimentCard = ({
  experiment,
  runningExperimentId,
  dispatch,
  shopId,
  experimentFormRoute,
}: ExperimentCardProps): JSX.Element => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showUseUuidDialog, setShowUseUuidDialog] = useState(false);
  const { onEditExperiment, onDeleteExperiment, onDeleteDialogClose, onRunExperiment } =
    useExperimentCard({
      experiment,
      runningExperimentId,
      dispatch,
      shopId,
      experimentFormRoute,
      setShowUseUuidDialog,
    });

  const canExperimentBeDeleted =
    experiment.status !== ExperimentStatus.Running && experiment.status !== ExperimentStatus.Ended;

  const experimentMenu = (
    <PopupMenu position='left center' closeOnContentClick>
      <PopUpMenuItem onClick={onEditExperiment}>
        {experiment.status === ExperimentStatus.Ended ? 'View Experiment' : 'Edit Experiment'}
      </PopUpMenuItem>
      {canExperimentBeDeleted ? (
        <PopUpMenuItem onClick={() => setShowDeleteDialog(true)}>Delete Experiment</PopUpMenuItem>
      ) : (
        <></>
      )}
    </PopupMenu>
  );

  function formatExperimentDate(date: Date | undefined | null) {
    return date ? format(date, dateFormat) : '-';
  }

  return (
    <CardItem className='syte-experiment-card'>
      <div
        className={classNames(
          'syte-experiment-card-status',
          getExperimentStatusClass(experiment.status)
        )}
      >
        <Typography
          type={TypographyType.Body}
          variant={TypographyVariant.SmallRegular}
          className='syte-experiment-card-status-text'
        >
          {experiment.status}
        </Typography>
      </div>
      <div className='syte-experiment-card-content'>
        <div className='syte-experiment-card-cell'>
          <div className='syte-experiment-card-cell-label'>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              Experiment Name
            </Typography>
          </div>
          <div className='syte-experiment-card-cell-field'>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              {experiment.name}
            </Typography>
          </div>
        </div>
        <div className='syte-experiment-card-cell'>
          <div className='syte-experiment-card-cell-label'>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              Created At
            </Typography>
          </div>
          <div className='syte-experiment-card-cell-field'>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              {formatExperimentDate(experiment.createdAt)}
            </Typography>
          </div>
        </div>
        <div className='syte-experiment-card-cell'>
          <div className='syte-experiment-card-cell-label'>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              Start Date
            </Typography>
          </div>
          <div className='syte-experiment-card-cell-field'>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              {formatExperimentDate(experiment.startedAt)}
            </Typography>
          </div>
        </div>
        <div className='syte-experiment-card-cell'>
          <div className='syte-experiment-card-cell-label'>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              End Date
            </Typography>
          </div>
          <div className='syte-experiment-card-cell-field'>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              {formatExperimentDate(experiment.endedAt)}
            </Typography>
          </div>
        </div>
        <div className='syte-experiment-card-cell'>
          <div className='syte-experiment-card-cell-label'>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              Metric
            </Typography>
          </div>
          <div className='syte-experiment-card-cell-field'>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              {experimentMetricTranslations[experiment.metric]}
            </Typography>
          </div>
        </div>
        <div className='syte-experiment-card-cell'>
          <ExperimentsActionButtons
            runningExperimentId={runningExperimentId}
            dispatch={dispatch}
            experiment={experiment}
            shopId={shopId}
            setShowUseUuidDialog={setShowUseUuidDialog}
          />
        </div>
        <div className='syte-experiment-card-cell'>{experimentMenu}</div>
        {showDeleteDialog && (
          <ConfirmationDialog onCancel={onDeleteDialogClose}>
            <ConfirmationDialog.Header>
              <ConfirmationDialog.Title>Hold on</ConfirmationDialog.Title>
            </ConfirmationDialog.Header>
            <ConfirmationDialog.Content>
              Are you sure you want to delete this experiment?
            </ConfirmationDialog.Content>
            <ConfirmationDialog.Footer>
              <ButtonsContainer>
                <Button variant='primary' onClick={onDeleteExperiment}>
                  Delete
                </Button>
                <Button variant='outlined' onClick={() => setShowDeleteDialog(false)}>
                  Cancel
                </Button>
              </ButtonsContainer>
            </ConfirmationDialog.Footer>
          </ConfirmationDialog>
        )}
        {showUseUuidDialog && (
          <UseUuidModal
            onRunExperiment={onRunExperiment}
            setShowUseUuidDialog={setShowUseUuidDialog}
          />
        )}
      </div>
    </CardItem>
  );
};
