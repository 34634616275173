import styled from '@emotion/styled';

export const InitialLetterStyled = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: bold;
  width: 35px !important;
  min-width: 35px !important;
  height: 35px !important;
  text-align: center;
  line-height: 35px;
  overflow: hidden;

  &-wrapper {
    width: 100%;
    height: 100%;
  }
`;

export const InitialLetterWrapperStyled = styled.div<{ backgroundColor?: string; color?: string }>`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.palette.custom['orange-20']};
  color: ${({ color, theme }) => color || theme.palette.custom.bordeaux};
`;
