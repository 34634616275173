import { useMemo, useState } from 'react';
import { useFilterItems } from 'src/hooks';
import { UseExperimentListSectionArguments, UseExperimentListSectionReturnType } from './types';
import { SelectExperimentStatus } from './constants';

export const useExperimentListSection = ({
  experiments,
}: UseExperimentListSectionArguments): UseExperimentListSectionReturnType => {
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const [statusDropdownValue, onStatusDropdownChange] = useState<SelectExperimentStatus>(
    SelectExperimentStatus.All
  );

  const onSearchInputChange = (value: string): void => setSearchInputValue(value);

  const experimentsFilteredByStatus = useMemo(
    () =>
      statusDropdownValue === SelectExperimentStatus.All
        ? experiments
        : experiments?.filter(experiment => experiment.status === (statusDropdownValue as any)),
    [experiments, statusDropdownValue]
  );

  const { filteredItems: experimentsFilteredByName } = useFilterItems({
    items: experimentsFilteredByStatus || [],
    searchableFields: ['name'],
    idField: 'id',
    filterText: searchInputValue,
  });

  return {
    filteredExperiments: experiments ? experimentsFilteredByName : undefined,
    searchInputValue,
    onSearchInputChange,
    statusDropdownValue,
    onStatusDropdownChange,
  };
};
