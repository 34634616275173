import React from 'react';
import { useDispatch } from 'react-redux';
import { Rule, RuleGroup } from '../../../types';
import { useRuleValue } from './useRuleValue';
import { ConditionValueInput } from '../../../../MerchandisingRules/components/DataFieldsCondition/ConditionValueInput';
import { ParseJoiValidateResponse } from '../../../../../utils';
import { ShopDataField } from '../../../../../services';

interface RuleValueProps {
  data: Rule;
  onChange: (rule: Rule) => void;
  shopId: number;
  dataFields: ShopDataField[] | undefined;
  errors: ParseJoiValidateResponse<{ ruleGroups: RuleGroup[] }>;
}

export const RuleValue: React.FC<RuleValueProps> = ({
  data,
  shopId,
  onChange,
  dataFields,
  errors,
}) => {
  const { getDataFieldByName, selectedDataField, conditionData, onValuesChange } = useRuleValue({
    data,
    onChange,
    dataFields,
  });
  const dispatch = useDispatch();
  return (
    <ConditionValueInput
      getDataFieldByName={getDataFieldByName}
      dataFieldTypes={selectedDataField?.types}
      conditionData={conditionData}
      onChange={onValuesChange}
      errors={errors}
      dispatch={dispatch}
      shopId={shopId}
    />
  );
};
