import styled from '@emotion/styled';
import { Button, Page } from 'src/components-dummy';

const FOOTER_HEIGHT_PX = 65;

export const AugmentedSearchContainerPageStyled = styled(Page)`
  background-color: ${({ theme }) => theme.palette.custom['gray-90']};

  .syte-page-header {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas: 'title submit' 'tabs tabs';
    .syte-page-title {
      grid-area: title;
    }

    button.syte-aug-search-filters__submit-button {
      grid-area: submit;
      width: 100%;
    }
    .syte-tabs {
      grid-area: tabs;
    }
  }
  .main {
    .syte-page-content {
      height: calc(100% - ${FOOTER_HEIGHT_PX}px) !important;
    }
  }
  .syte-page-content {
    padding: 0 0 ${FOOTER_HEIGHT_PX}px 0;
    &,
    > .syte-filters-list,
    div[data-rbd-droppable-id='syte-droppable-list'] {
      overflow-y: visible;
    }
  }
  .syte-filters-list {
    &__header {
      > .toggle-use-display-name {
        align-items: flex-end;
        display: flex;
        margin: 15px 0;
      }

      > .order-method-field {
        width: 50%;
      }
    }
  }
`;

export const PageHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const HeaderTitlesWrapperStyled = styled.div``;

export const PreviewButtonStyled = styled(Button)`
  min-width: 98px;
  margin-left: 25px;
`;

export const FooterStyled = styled.footer`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  width: 100%;
  height: ${FOOTER_HEIGHT_PX}px;
  bottom: 0;
  left: 0;
  padding: 0 24px;
  z-index: 10;
  background: ${({ theme }) => theme.palette.common.white};
  > button + button {
    margin-left: 8px;
  }
`;

export const ButtonStyled = styled(Button)`
  padding-left: 32px;
  padding-right: 32px;
`;
