import { ShopCatalogVertical, genderTypes } from 'src/services/src/service/types/catalog-manager';

export const genderTypeToText = {
  [genderTypes.baby_boy]: 'Baby boy',
  [genderTypes.baby_girl]: 'Baby girl',
  [genderTypes.boy]: 'Boy',
  [genderTypes.female]: 'Female',
  [genderTypes.girl]: 'Girl',
  [genderTypes.male]: 'Male',
  [genderTypes.hd]: 'HD',
} as const;

export type SFTPServerFormSettings = {
  type: string;
  host?: string;
  port?: string;
  path?: string;
  authentication: boolean;
  fileName?: string;
  username?: string;
  password?: string;
};

export type WGETServerFormSettings = {
  dataSourceURL: string;
  authentication: boolean;
  username?: string;
  password?: string;
};

export interface ICatalogFormScheduling {
  dailyTime?: {
    hour: number;
    minutes: number;
  };
  timeIntervals?: {
    hours: string;
    minutes: string;
  };
}

export interface CatalogFormDraft {
  name: string;
  verticals: ShopCatalogVertical[];
  genders: string[];
  productsFile?: File;
  isUsingCustomSftp?: boolean;
  selectedDataSource: DataSourceTypesEnum;
  selectedImportMethod?: ImportMethodTypesEnum;
  sftpServerSettings?: SFTPServerFormSettings;
  wgetServerSettings?: WGETServerFormSettings;
  selectedScheduleType?: SchedulingTypesEnum;
  scheduling?: ICatalogFormScheduling;
}

export enum DataSourceTypesEnum {
  manual_add_products = 'manual_add_products',
  use_product_feed = 'use_product_feed',
}

export enum ImportMethodTypesEnum {
  upload_sftp_server = 'upload_sftp_server',
  upload_wget_server = 'upload_wget_server',
  pull_from_url = 'pull_from_url',
}

export enum SchedulingTypesEnum {
  daily_time = 'daily_time',
  time_intervals = 'time_intervals',
  none = 'none',
}

export const AUTHENTICATION = {
  required: { key: 'required', value: true },
  noneRequired: { key: 'noneRequired', value: false },
};

/**
 * SFTP
 */
export const SFTP_DEFAULT_VALUES = {
  authentication: AUTHENTICATION.required.value,
  hostname: 'uploads.wearesyte.com',
  fileName: '',
  port: '2323',
  path: '/feeds',
};

export const formModesEnum = {
  create: 'create',
  edit: 'edit',
};

export const DEFAULT_DAILY_TIME = new Date(0, 0, 0, 22, 0);
