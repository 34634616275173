import { escapeRegExp, get } from 'lodash';
import { useMemo } from 'react';

const preProcessSearchTerm = (text: string) =>
  text.toString().trim().replace(/\s/g, '').toLowerCase();

const itemIsMatch = <T>({
  item,
  searchValue,
  idField,
  itemsSearchableValuesMap,
}: ItemIsMatchArguments<T>): boolean => {
  const id = item[idField] as unknown as string;

  const searchValues = itemsSearchableValuesMap[id];
  const searchRegExp = new RegExp(escapeRegExp(escapeRegExp(searchValue)), 'gi');

  return searchValues.some(val => searchRegExp.test(val));
};

export interface UseFilterItemsArguments<T> {
  items: T[];
  searchableFields: Array<keyof T | string>;
  idField: keyof T;
  filterText: string;
}

interface ItemIsMatchArguments<T> {
  item: T;
  searchValue: string;
  idField: keyof T;
  itemsSearchableValuesMap: Record<string, string[]>;
}

export interface UseFilterItemsReturnType<T> {
  filteredItems: T[];
}

export const useFilterItems = <T>({
  items,
  searchableFields,
  idField,
  filterText = '',
}: UseFilterItemsArguments<T>): UseFilterItemsReturnType<T> => {
  const itemsSearchableValuesMap = useMemo(() => {
    return items.reduce(
      (result, current) => {
        const id = current[idField] as unknown as string;
        return {
          ...result,
          [id]: searchableFields
            .filter(fieldName => !!get(current, fieldName))
            .map(field => {
              const searchRegex = preProcessSearchTerm(get(current, field) as unknown as string);

              const escapedRegex = escapeRegExp(searchRegex);
              return escapedRegex;
            }),
        };
      },
      {} as Record<string, string[]>
    );
  }, [items, searchableFields]);

  const filteredItems = useMemo(() => {
    const processedInput = preProcessSearchTerm(filterText);

    if (!processedInput.length) return items;

    return items.filter(item =>
      itemIsMatch({ item, searchValue: processedInput, idField, itemsSearchableValuesMap })
    );
  }, [items, filterText, itemsSearchableValuesMap]);

  return { filteredItems };
};
