import React from 'react';
import { EllipsisMenuButton } from 'src/components-dummy/EllipsisMenuButton';
import { Menu, MenuItem } from 'src/components-dummy';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy/Typography';
import { MenuActionsStyled, MenuItemDeleteStyled } from '../LexiconTable.styles';

/**
 * Configuration
 */
const menuConfiguration = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: -4,
    horizontal: 'right',
  },
};

/**
 * Main
 */
interface LexiconItemRowActionsMenuProps {
  isMenuOpened: boolean;
  menuAnchorElement: HTMLElement | undefined;
  isOn: boolean;
  onTriggerClick: (event: React.MouseEvent<HTMLElement>) => void;
  onMenuClose: (event: React.MouseEvent<HTMLElement>) => void;
  onRestore?: (event: React.MouseEvent<HTMLElement>) => void;
  onToggleOff?: (event: React.MouseEvent<HTMLElement>) => void;
  onEditThematicTag?: (event: React.MouseEvent<HTMLElement>) => void;
  onDeleteThematicTag?: (event: React.MouseEvent<HTMLElement>) => void;
  isThematic: boolean;
  isLexiconRule: boolean;
  onEditLexiconRule?: (event: React.MouseEvent<HTMLElement>) => void;
  onDeleteLexiconRule?: (event: React.MouseEvent<HTMLElement>) => void;
  isSyteAdmin: boolean;
}

export const LexiconItemRowActionsMenu = ({
  isMenuOpened,
  menuAnchorElement,
  isOn,
  onTriggerClick,
  onMenuClose,
  onRestore,
  onToggleOff,
  onEditThematicTag,
  onDeleteThematicTag,
  isThematic,
  isLexiconRule,
  onEditLexiconRule,
  onDeleteLexiconRule,
  isSyteAdmin,
}: LexiconItemRowActionsMenuProps): JSX.Element => {
  const turnOffMenuItem = (
    <MenuItemDeleteStyled key='toggleOn' value='toggleOn' onClick={onToggleOff}>
      <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
        Turn {isOn ? 'off' : 'on'}
      </Typography>
    </MenuItemDeleteStyled>
  );

  let actionContent;

  if (isThematic) {
    actionContent = [
      <MenuItem key='edit' value='edit' onClick={onEditThematicTag}>
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
          Edit
        </Typography>
      </MenuItem>,
      turnOffMenuItem,
      isSyteAdmin && (
        <MenuItemDeleteStyled key='delete' value='delete' onClick={onDeleteThematicTag}>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
            Delete
          </Typography>
        </MenuItemDeleteStyled>
      ),
    ];
  } else if (isLexiconRule) {
    actionContent = [
      <MenuItem key='edit' value='edit' onClick={onEditLexiconRule}>
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
          Edit Rule
        </Typography>
      </MenuItem>,
      turnOffMenuItem,
      isSyteAdmin && (
        <MenuItemDeleteStyled key='delete' value='delete' onClick={onDeleteLexiconRule}>
          <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
            Delete
          </Typography>
        </MenuItemDeleteStyled>
      ),
    ];
  } else {
    actionContent = [
      <MenuItem key='restore' value='restore' onClick={onRestore} disabled={!onRestore}>
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
          Restore tag
        </Typography>
      </MenuItem>,
      turnOffMenuItem,
    ];
  }

  return (
    <MenuActionsStyled>
      <EllipsisMenuButton onClick={onTriggerClick} isActive={!!menuAnchorElement} />
      <Menu
        open={isMenuOpened}
        anchorEl={menuAnchorElement}
        onClose={onMenuClose}
        anchorOrigin={menuConfiguration.anchorOrigin as any}
        transformOrigin={menuConfiguration.transformOrigin as any}
      >
        {actionContent}
      </Menu>
    </MenuActionsStyled>
  );
};
