import {
  EditableFilterItem,
  TextTypeDisplayOptions,
  NumericTypeDisplayOptions,
  FilterStatusOption,
  FilterType,
} from 'src/services/src/service/types/filters';
import { ShopDataField } from 'src/services/src/service/types';
import { OnChangePayload } from 'src/components-bl/FiltersList';
import { ItemValidityMap } from '../helpers/form-validation';

export * from 'src/services/src/service/types/filters';
export * from 'src/services/src/service/types/shops';
export * from 'src/components-bl/FiltersList';

export interface FilterCardHeaderProps {
  filterItem: EditableFilterItem;
  isDraggable: boolean;
  dismissChanges: () => void;
  saveItem: () => void;
  setIsFormOpen: (isOpen: boolean) => void;
  toggleVisibility: () => void;
  deleteItem?: () => void;
  isFormExpended: boolean;
  messages: {
    delete?: {
      title: string;
      body?: string;
    };
    disable: {
      title: string;
      body?: string;
    };
  };
  isHiddenFilterSoftAlertShown: boolean;
}

export const mapDisplayTypeToFilterType = {
  [TextTypeDisplayOptions.list]: FilterType.text,
  [TextTypeDisplayOptions.tags]: FilterType.text,
  [TextTypeDisplayOptions.swatches]: FilterType.text,
  [NumericTypeDisplayOptions.slider]: FilterType.range,
  [NumericTypeDisplayOptions.rangeGroups]: FilterType.buckets,
};

export enum FieldToValidate {
  dataSource = 'dataSource',
  displayNameKey = 'displayNameKey',
  displayType = 'displayType',
  numBuckets = 'numBuckets',
  minBucketDelta = 'minBucketDelta',
  bucketsOrder = 'bucketsOrder',
}

export type ValidationErrors = Record<FieldToValidate, string[]>;

export interface FilterCardProps {
  filterItem: EditableFilterItem;
  status: FilterStatusOption;
  className?: string;
  isDraggable?: boolean;
  dismissChanges: (itemKey: string) => void;
  toggleItemVisibility: () => void;
  saveItem: (itemKey: string) => void;
  onItemChanged: (updates: OnChangePayload) => void;
  setIsFormOpen: (isOpen: boolean) => void;
  deleteItem?: () => void;
  isFormExpended: boolean;
  isHiddenFilterSoftAlertShown: (itemKey: string) => boolean;
  externalErrors?: ItemValidityMap<EditableFilterItem>;
  dataFields: ShopDataField[];
  messages: {
    delete?: {
      title: string;
      body?: string;
    };
    disable: {
      title: string;
      body?: string;
    };
  };
  shouldIncludeDisplayName: boolean;
  errors: ItemValidityMap<EditableFilterItem> | undefined;
}
