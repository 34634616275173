import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { GetShopDraftArguments, versioningService } from 'src/services';

/**
 * Actions
 */
export const useShopVersionsWSActions = {
  useShopVersionsWSNotification: createAction<{ customMessage: string }>(
    'UseShopVersionsWS/ShopVersionNotification'
  ),
  getDraft: createAsyncThunk(
    'UseShopVersionsWS/GetDraft',
    async ({ shopId, slim }: GetShopDraftArguments, { rejectWithValue }) => {
      try {
        const draft = await versioningService.getShopDraft({ shopId, slim });

        return draft;
      } catch (error) {
        console.error(error);
        return rejectWithValue({ error });
      }
    }
  ),
};
