import React, { useMemo } from 'react';
import { TableV2, TableV2InstanceProps } from '../../../../components-dummy';
import { TableHeaderColumns } from '../TableHeaderColumns';
import { TableRow } from './TableRow';
import { adminSearchModalActions } from '../../AdminSearchModal.actions';
import { tableColumns } from './tableColumns';
import { AdminSearchUserResult } from '../../../../services/src/service/types/admin-search/admin-search-results';
import { Dispatch } from '../../../types';
import { AdminSearchUser } from '../types';

interface UsersTableProps {
  users: AdminSearchUserResult[];
  dispatch: Dispatch;
  onSelect: VoidFunction;
  userDefaultRoute: string;
}

export const UsersTable = ({
  users,
  dispatch,
  onSelect,
  userDefaultRoute,
}: UsersTableProps): JSX.Element => {
  const tableData: AdminSearchUser[] = useMemo(() => {
    return users.map(user => {
      const { userId, email, accountId, accountName, online } = user;

      return {
        userId,
        email,
        accountId,
        accountName,
        online,
      };
    });
  }, [users]);

  const columns = useMemo(() => Object.values(tableColumns), [tableColumns]);

  const renderBody = ({
    rows,
    prepareRow,
  }: Pick<TableV2InstanceProps<AdminSearchUser>, 'rows' | 'prepareRow'>): JSX.Element[] => {
    return rows.map(row => {
      prepareRow(row);
      const onClick = () => {
        dispatch(
          adminSearchModalActions.navigateToUser({
            userId: row.original.userId,
            accountId: row.original.accountId,
            navigateTo: userDefaultRoute,
          })
        );
        onSelect();
      };
      return <TableRow key={row.original.userId} row={row} onClick={onClick} />;
    });
  };

  return (
    <TableV2<AdminSearchUser> options={{ columns, data: tableData }}>
      {({ getTableBodyProps, headerGroups, rows, prepareRow }) => {
        const headerGroup = headerGroups[0];

        return (
          <>
            <TableV2.Head {...headerGroup.getHeaderGroupProps()}>
              <TableHeaderColumns headers={headerGroup.headers} columns={columns} />
            </TableV2.Head>
            <TableV2.Body {...getTableBodyProps()}>{renderBody({ rows, prepareRow })}</TableV2.Body>
          </>
        );
      }}
    </TableV2>
  );
};
