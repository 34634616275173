import React, { useCallback, useEffect } from 'react';
import { PreviewTypeEnum } from 'src/app-types/enums/versioning';
import { Dispatch } from 'src/components-bl';
import { ShopVersion } from 'src/services';
import { NotificationType } from 'src/components-dummy';
import { ChangesModal } from '../ChangesModal/ChangesModal';
import { useShowVersionReviewModal } from './useShowReviewVersionModal';
import { reviewVersionActions } from './Actions';

export interface ReviewVersionProps {
  version: ShopVersion | undefined;
  dispatch: Dispatch;
  shopId: number;
}

export const ReviewVersion = ({ shopId, version, dispatch }: ReviewVersionProps): JSX.Element => {
  const { reset } = useShowVersionReviewModal({ shopId, dispatch });

  const issueNotification = useCallback(
    (customMessage: string) => {
      dispatch(reviewVersionActions.createToast({ customMessage, type: NotificationType.Warning }));
    },
    [dispatch]
  );

  const setModalIsOpened = useCallback(
    (isOpened: boolean) => {
      if (!isOpened) {
        reset();
      }
    },
    [reset]
  );

  useEffect(() => {
    if (!version) {
      return;
    }

    if (version.changesSummary.length === 0) {
      issueNotification(`There are no changes in version: ${version.publicationVersion}`);

      reset();
    }
  }, [version]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <ChangesModal
      show
      changesSummary={version?.changesSummary}
      setModalIsOpened={setModalIsOpened}
      previewType={PreviewTypeEnum.REVIEW_VERSION}
      loading={version === undefined}
    >
      <ChangesModal.Header>
        <h2 className='first-row'>REVIEW VERSION {version?.publicationVersion}</h2>
        <span className='second-row'>
          View all changes that were made in the version, compared to the previous one.
        </span>
      </ChangesModal.Header>
    </ChangesModal>
  );
};
