import { ParentDomainBase } from '../../types';
import { DomainEntityPath } from '../../../../../services';
import { AvailableIcons } from '../../../../../components-dummy';
import { rankingSubDomainGroup } from '../ranking-sub-domain-group';
import { collectionsSubDomainGroup } from './collections-sub-domain-group';

export const collectionsDomainGroup: ParentDomainBase = {
  domainKey: DomainEntityPath.Collections,
  title: 'Collections',
  icon: AvailableIcons.Collections,
  subDomains: [
    {
      ...collectionsSubDomainGroup,
      domainKey: DomainEntityPath.Collections,
    },
    {
      ...rankingSubDomainGroup,
      domainKey: DomainEntityPath.CollectionRanking,
    },
  ],
};
