import React from 'react';
import { Page } from 'src/components-dummy';
import { shallowEqual } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { DataFieldsTableForm } from 'src/components-bl/DataFields';
import { UserRoles } from 'src/services/src/service/types/users';
import { DataFieldsSubtitleStyled } from '../ShopSettings.styles';

export const DataFieldsPage = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { dataFields, shopId } = useAppSelector(state => {
    return {
      dataFields: state.dataFields.dataFields,
      shopId: state.shop.current?.shopId,
    };
  }, shallowEqual);

  const role = useAppSelector(state => state.global.loggedInUser?.role);
  const isSyteAdmin = role === UserRoles.SyteAdmin;

  return (
    <>
      <Page.Header>
        <Page.Title>Data Fields</Page.Title>
        <DataFieldsSubtitleStyled>
          Manage your shop&apos;s Data Fields
          <a
            href='https://syte-knowledge-center.refined.site/space/SI/2738880533/Data+Fields'
            target='_blank'
            rel='noreferrer'
          >
            Learn about managing data fields ↗
          </a>
        </DataFieldsSubtitleStyled>
      </Page.Header>
      <Page.Content>
        <DataFieldsTableForm
          dataFields={dataFields}
          dispatch={dispatch}
          shopId={shopId}
          isSyteAdmin={isSyteAdmin}
        />
      </Page.Content>
    </>
  );
};
