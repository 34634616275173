import { parseISODate } from 'src/utils/parse-iso-date';
import { IApiKey, IApiKeyRaw } from '../types/shops/api-keys';

export function mapApiKey(apiKeyData: IApiKeyRaw): IApiKey {
  return {
    ...apiKeyData,
    createdAt: parseISODate(apiKeyData.createdAt),
    expiration: apiKeyData.expiration ? parseISODate(apiKeyData.expiration) : undefined,
  } as IApiKey;
}
