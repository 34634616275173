import React from 'react';
import { Button, Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import './UserListEmptyResults.scss';

export interface UserListEmptyResultsProps {
  onClick?: () => void;
}

export const UserListEmptyResults = ({ onClick }: UserListEmptyResultsProps): JSX.Element => {
  return (
    <div className='syte-user-list-empty-results'>
      <Typography
        className='syte-user-list-empty-results-title'
        type={TypographyType.Body}
        variant={TypographyVariant.SmallBold}
      >
        Wow... so empty in here...
      </Typography>
      <p className='syte-user-list-empty-results-text'>
        <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
          This account has no users that you can view
          <br />
          but hey, we can easily fix that. Just create one!
        </Typography>
      </p>

      <Button variant='secondary' onClick={onClick}>
        Add user
      </Button>
    </div>
  );
};
