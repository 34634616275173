import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { history } from './history';
import { createRootReducer } from './rootReducer';
import { middlewares } from './middlewares/middlewares';
import { AppStore } from './app-types';
import ErrorHandlerNavigator from './errorHandler';

export const store: AppStore = configureStore({
  reducer: createRootReducer(history),
  middleware: [
    routerMiddleware(history),
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
    ...middlewares(history),
  ],
  devTools: process.env.NODE_ENV !== 'production',
});

const errorHandlerNavigator = new ErrorHandlerNavigator();

const onStateChangeForErrorHandling = () => {
  errorHandlerNavigator.onStateChange(store);
};

store.subscribe(() => onStateChangeForErrorHandling());
