import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const RuleConditionsGroupStyled = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  border-radius: 12px;
  padding: 24px;
  margin-top: 16px;
  background-color: #ffffff;
`;

export const SubtitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-20']};
  margin-bottom: 16px;
  margin-top: 3px;
  display: block;
`;

export const ContentStyled = styled.div`
  margin-top: 16px;
  > * + * {
    margin-top: 24px;
  }
`;
