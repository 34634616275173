import React from 'react';
import styled from '@emotion/styled';
import {
  Typography,
  TypographyProps,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';

export const SubTitleStyled = styled(Typography)`
  display: block;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const InfoStyled = styled(Typography)`
  display: block;
  color: ${({ theme }) => theme.palette.custom['gray-20']};
`;

export const RadioButtonSectionStyled = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  border-radius: 12px;

  label.MuiFormControlLabel-root {
    padding: 24px;
    margin: 0;
  }

  & > div:nth-child(2) {
    padding: 0 24px 24px 24px;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const RadioButtonsContainerStyled = styled.div`
  display: flex;
  margin-top: 16px;
  border-radius: 12px;
  flex-direction: column;
  padding: 24px auto;

  .MuiFormGroup-root {
    flex: 1;
  }

  span.MuiButtonBase-root.MuiRadio-root {
    margin-bottom: 0;
  }

  .MuiFormControlLabel-label {
    padding-left: 4px;
  }
`;

export const ErrorsMessagesWrapperStyled = styled.div`
  margin-bottom: 16px;
`;

export const ErrorLabelStyled = styled((props: TypographyProps) => (
  <Typography {...props} type={TypographyType.Paragraph} variant={TypographyVariant.SmallRegular} />
))`
  color: ${({ theme }) => theme.palette.custom.red};
  margin-top: 4px;
`;
