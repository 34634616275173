import React from 'react';
import { Typography, TypographyVariant, TypographyType } from 'src/components-dummy';
import { TemplateLinkStyled } from '../DeepTagReportHowToUpload.styles';
import {
  SFTPStepsContainerStyled,
  UploadStepTypographyStyled,
} from './DeepTagReportHowToUploadModal.styles';

const exampleFilePath = `${process.env.PUBLIC_URL}/files/deep-tags-reports/upload_file_example.csv`;

const downloadFile = (
  <TemplateLinkStyled key='link' href={exampleFilePath}>
    (See template)
  </TemplateLinkStyled>
);
export const ModalStaticInfo = ({ directoryPath }: { directoryPath: string }): JSX.Element => {
  const uploadSteps = [
    'Use your preferred SFTP client and log in using the credentials listed below..',
    `Place a file under the ${directoryPath} folder.`,
  ];

  return (
    <SFTPStepsContainerStyled>
      <Typography type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
        How to upload catalog to SFTP server?
      </Typography>
      {uploadSteps.map((stepInfo, index) => {
        return (
          <UploadStepTypographyStyled
            type={TypographyType.Paragraph}
            variant={TypographyVariant.MediumRegular}
            key={index.toString()}
          >
            {index + 1}. {stepInfo}
          </UploadStepTypographyStyled>
        );
      })}
      <UploadStepTypographyStyled
        type={TypographyType.Paragraph}
        variant={TypographyVariant.MediumRegular}
      >
        3. The file should be of type CSV (comma delimited) , UTF-8 format. {downloadFile}
      </UploadStepTypographyStyled>
      <UploadStepTypographyStyled
        type={TypographyType.Paragraph}
        variant={TypographyVariant.MediumRegular}
      >
        4. Once the file is placed, Syte will begin tagging the file. You will be notified once the
        products are enriched.
      </UploadStepTypographyStyled>
    </SFTPStepsContainerStyled>
  );
};
