import React, { HTMLAttributes, useCallback } from 'react';
import { debounce } from 'lodash';
import { isChildComponentOfType } from 'src/components-dummy/helpers';
import { useFindComponentByType } from 'src/components-dummy/hooks';
import { useDragAndDrop } from 'src/hooks/useDragAndDrop';
import { DraggableBodyRowStyled } from '../TableV2.style';

const TABLE_ROW_DRAG_DROP_TYPE = 'TABLE_ROW';

function DragHandleCell({ children, tableRowRef }: { children: JSX.Element; tableRowRef?: any }) {
  return <>{React.cloneElement(children, { ref: tableRowRef })}</>;
}

interface DraggableBodyRowProps {
  children: JSX.Element[];
  index: number;
  disableDrag: boolean;
  onRowDrag: (sourceIndex: number, targetIndex: number) => void;
  onRowDrop: () => void;
}

export function DraggableBodyRow({
  children,
  index: rowIndex,
  disableDrag,
  onRowDrag,
  onRowDrop,
  ...props
}: DraggableBodyRowProps & HTMLAttributes<HTMLDivElement>): JSX.Element {
  const debouncedOnRowDrag = useCallback(debounce(onRowDrag, 100), [onRowDrag]);

  const {
    dragElementRef: tableRowRef,
    dragPreviewRef,
    isDragging,
  } = useDragAndDrop<HTMLDivElement>({
    acceptKey: TABLE_ROW_DRAG_DROP_TYPE,
    disableDrag,
    index: rowIndex,
    onDrag: debouncedOnRowDrag,
    onDrop: onRowDrop,
  });

  const dragHandleCell = useFindComponentByType({ type: DragHandleCell, children });

  return (
    <DraggableBodyRowStyled
      isDragging={isDragging}
      ref={dragHandleCell ? dragPreviewRef : tableRowRef}
      {...props}
    >
      {React.Children.map(children, child => {
        const isDragHandleCell = isChildComponentOfType(child, DragHandleCell);
        if (isDragHandleCell) {
          return React.cloneElement(child as React.ReactElement, { tableRowRef });
        }
        return child;
      })}
    </DraggableBodyRowStyled>
  );
}

DraggableBodyRow.DragHandleCell = DragHandleCell;
