type SocialSource = {
  termValue: string;
};

type CommonPost = {
  id: number;
  external_id: string;
  imageUrl: string;
};

export type UnpublishedPost = CommonPost & {
  publishedAt: number;
  source: SocialSource;
};

export type PublicPost = CommonPost & {
  assignedProducts: AssignedProduct[];
};

export type Coordinates = {
  x: number;
  y: number;
};

export enum ProductType {
  inStock = 'inStock',
  outOfStock = 'outOfStock',
  notIndexed = 'notInFeed',
}

type BaseProduct = {
  sku: string;
  title: string;
  formattedPrice: string;
  offerLink: string;
};

export type InStockProduct = BaseProduct & {
  type: ProductType.inStock;
};

export type OutOfStockProduct = BaseProduct & {
  type: ProductType.outOfStock;
};

export type NotIndexedProduct = {
  sku: string;
  type: ProductType.notIndexed;
};

export type Product = InStockProduct | OutOfStockProduct | NotIndexedProduct;

export type AssignedProduct = Product & { coordinates: Coordinates };

export enum CollectionType {
  ConnectedCollection = 'connected_collection',
  UnConnectedCollection = 'unconnected_collection',
}
