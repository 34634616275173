import React, { ReactNode } from 'react';

export interface PageFooterProps {
  children: ReactNode | ReactNode[];
  className?: string;
}

export const PageFooter = ({ children, className }: PageFooterProps): JSX.Element => {
  return <div className={className}>{children}</div>;
};
