import React from 'react';
import { AvailableIcons } from 'src/components-dummy/Icon';
import { EllipsisWithTooltip } from 'src/components-dummy/EllipsisWithToolTip';
import { Typography, TypographyType, TypographyVariant } from '../../Typography';
import { TableSortIconStyled, TableHeadRowCellTextStyled } from '../TableV2.style';

export const TableHeadRowCellText = ({
  children,
  isSortAble,
  sortAscending,
  onClick,
}: {
  children: string | React.ReactNode;
  isSortAble?: boolean;
  sortAscending?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}): JSX.Element => {
  const isActive = sortAscending !== undefined;

  return (
    <TableHeadRowCellTextStyled
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      isClickable={!!isSortAble}
    >
      {typeof children === 'string' ? (
        <EllipsisWithTooltip tooltipText={children}>
          <Typography type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
            {children}
          </Typography>
        </EllipsisWithTooltip>
      ) : (
        <Typography type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
          {children}
        </Typography>
      )}
      {isSortAble && (
        <TableSortIconStyled
          name={AvailableIcons.Arrow}
          sortAscending={sortAscending || false}
          isActive={isActive}
        />
      )}
    </TableHeadRowCellTextStyled>
  );
};
