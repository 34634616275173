import { ApiServiceBase } from '../api-service-base';
import { AdminSearchResults } from '../types/admin-search/admin-search-results';

export class AdminSearchService extends ApiServiceBase {
  constructor() {
    super('admin-search');
  }

  async search(searchTerm: string): Promise<AdminSearchResults> {
    const url = this.serviceBaseUri;
    const response = await this.httpService.get({ url, requestConfig: { params: { searchTerm } } });

    const result: AdminSearchResults = {
      shops: response.data?.shops || [],
      accounts: response.data?.accounts || [],
      users: response.data?.users || [],
      catalogs: response.data?.catalogs || [],
    };

    return result;
  }
}
