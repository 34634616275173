import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  MenuItem,
  Typography,
  TypographyProps,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';

/** *****************
 *   comments List
 ****************** */

export const CommentsFormStyled = styled.div`
  padding: 24px 16px 24px 24px;
`;

export const CommentsFormButtonWrapperStyled = styled.div<{ isActive: boolean }>`
  button {
    background-color: ${({ theme, isActive }) => {
      return isActive ? theme.palette.custom['primary-light'] : theme.palette.custom['gray-80'];
    }};
    & > span:first-of-type {
      display: flex;
      align-items: center;
    }
  }
`;

export const CommentsFormButtonIconWithBadgeStyled = styled.div`
  display: flex;
  margin-right: -5px;
  min-width: 25px;

  span:first-of-type {
    background: ${({ theme }) => theme.palette.custom['gray-10']};
    color: ${({ theme }) => theme.palette.custom.white};
    border-radius: 52px;
    padding: 0px 2px;
    font-size: 8px;
    position: relative;
    left: -9px;
    bottom: -4px;
    min-width: 12px;
    height: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CommentsFormListStyled = styled.div`
  display: flex;
  width: 471px;
  flex-direction: column;
  align-items: flex-start;
  max-height: 500px;
  overflow: auto;
  padding-right: 8px;
  gap: 24px;
`;

export const CommentsFormButtonsSectionStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  display: flex;
  align-items: flex-start;
  justify-content: end;
  padding-top: 6px;
  padding-right: 8px;
  gap: 4px;
`;

/** ******************
 *   Comment - Item
 ******************** */
export const CommentItemStyled = styled.div`
  padding-bottom: 20px;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.custom['gray-50']};
  }
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CommentItemHeaderStyled = styled.div`
  display: flex;
  padding-bottom: 16px;
  justify-content: space-between;
`;

export const CommentItemHeaderDetailsWithThumbnailStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CommentItemHeaderDetailsTextStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CommentItemHeaderDetailsTextTimeStyled = styled.div`
  & > span {
    color: ${({ theme }) => theme.palette.custom['gray-20']};
  }
`;

export const CommentItemHeaderDetailsNameStyled = styled.div``;

export const EditableContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
  display: flex;
  background: ${({ theme }) => theme.palette.common.white};
  max-height: calc(100vh - 496px);

  [contentEditable='true']:empty:not(:focus):before {
    content: attr(placeholder);
    color: ${({ theme }) => theme.palette.custom['gray-20']};
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  & > div {
    height: fit-content;
    padding: 0 12px 0px 0px;
    overflow: auto;
  }
`;

export const EditableContentWrapperStyled = styled.div<{ isInEditMode: boolean; isError: boolean }>`
  padding: 12px 16px;
  border-radius: 6px;

  ${({ isInEditMode, isError, theme }) => {
    if (isError) {
      return css`
        border: 1px solid ${theme.palette.custom.red};
      `;
    }
    if (isInEditMode) {
      return css`
        border: 1px solid ${theme.palette.custom['gray-50']};
      `;
    }

    return '';
  }}
`;

export const EditableTextSectionStyled = styled.div`
  padding: 16px 16px 8px 16px;
`;

export const CommentItemFooterButtonsStyled = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  display: flex;
  align-items: flex-start;
  justify-content: end;
  gap: 4px;
`;

/** **************************
 *   Comment - Menu Actions
 **************************** */
export const MenuActionsStyled = styled.div`
  display: flex;
  justify-content: end;

  & > div:first-of-type {
    padding: 8px 0;
  }

  .MuiButtonBase-root {
    height: 32px;
    align-items: end !important;
  }
`;

interface MenuItemStyledProps {
  disabled?: boolean;
  shouldHighlightRed?: boolean;
}

export const MenuItemStyled = styled(MenuItem)<MenuItemStyledProps>`
  color: ${({ theme, disabled = false, shouldHighlightRed = false }) => {
    if (disabled) {
      return theme.palette.custom['gray-20'];
    }

    if (shouldHighlightRed) {
      return theme.palette.custom.red;
    }

    return theme.palette.common.black;
  }};
`;

/** ********
 * Errors
 ******** */
export const ErrorTextStyled = styled((props: TypographyProps) => (
  <Typography {...props} type={TypographyType.Paragraph} variant={TypographyVariant.SmallRegular} />
))`
  color: ${({ theme }) => theme.palette.custom.red};
  margin-top: 4px;
  margin-bottom: 16px;
`;
