import { ItemValidityMap, GenericExtendsFallback, ValidatorsDict } from '../base';
import {
  ListValidityMap,
  ValidatorsCollection,
  UniqueFieldsConfig,
  ItemUniquenessValidationState,
} from './types';
import { isFieldValueExistsInContext } from './methods/validate-list-for-duplications';
import { FormValidator } from '../single/form-validator';

const warnings = {
  missingEntry: (itemKey: string) =>
    console.warn(
      `No validator exists for ${itemKey}. You can add initial data to the class constructor, or use the "addInstance" method to register the item at any time.`
    ),
  missingKey: () =>
    console.warn(
      'ListFormValidator - add list item validator aborted - key attribute value is invalid'
    ),
  missingDuplicationsOptions: () =>
    console.warn(
      'Options must be provided to the class constructor, in order to validate for duplications'
    ),
};
export class ListFormValidator<T extends GenericExtendsFallback> {
  private validatorsCollection: ValidatorsCollection<T> = {} as ValidatorsCollection<T>;

  private validationScheme: ValidatorsDict<T>;

  private itemKeyAttribute: keyof T;

  private uniqueFields: UniqueFieldsConfig<T> | undefined;

  private duplicationState: Record<string, ItemUniquenessValidationState<T>> = {};

  constructor(
    validationsConfig: ValidatorsDict<T>,
    itemKeyAttribute: keyof T,
    initialData?: T[],
    uniqueFields?: UniqueFieldsConfig<T>
  ) {
    this.itemKeyAttribute = itemKeyAttribute;
    this.uniqueFields = uniqueFields;
    if (!validationsConfig[itemKeyAttribute].isRequired) {
      throw new Error(
        `Failed to init ListFormValidator, ${String(itemKeyAttribute)} must be a required field`
      );
    }
    this.validationScheme = validationsConfig;
    this.isItemContainErrors = this.isItemContainErrors.bind(this);
    this.isValid = this.isValid.bind(this);
    this.addInstance = this.addInstance.bind(this);
    if (initialData?.length) {
      initialData.forEach(this.addInstance);
    }
  }

  addInstance(itemData: Partial<T>): void {
    const itemKey = itemData[this.itemKeyAttribute];
    if (!itemKey) {
      warnings.missingKey();
      return;
    }
    this.validatorsCollection = {
      ...this.validatorsCollection,
      [String(itemKey)]: new FormValidator(this.validationScheme),
    };
  }

  refresh(newData: T[]): void {
    this.validatorsCollection = {};
    newData.forEach(this.addInstance);
  }

  removeInstance(itemKey: string): void {
    const updatedValidatorsCollection = { ...this.validatorsCollection };
    delete updatedValidatorsCollection[itemKey];
    this.validatorsCollection = updatedValidatorsCollection;
  }

  get state(): ListValidityMap<T> {
    const accState: ListValidityMap<T> = {};
    return Object.entries(this.validatorsCollection).reduce((acc, [itemKey, instance]) => {
      const itemDuplicationState = this.duplicationState[itemKey] || {};
      const combinedState = Object.keys(instance.state).reduce((itemStateAcc, field) => {
        return {
          ...itemStateAcc,
          [field]: {
            ...instance.state[field],
            valueValidation: itemDuplicationState[field] || instance.state[field]?.valueValidation,
          },
        };
      }, instance.state);
      return {
        ...acc,
        [itemKey]: combinedState,
      };
    }, accState);
  }

  reset(itemKey?: string): void {
    if (itemKey) {
      this.validatorsCollection[itemKey].reset();
    } else {
      Object.values(this.validatorsCollection).forEach(item => item.reset());
    }
  }

  resetDuplicationState(itemKey?: string): void {
    if (itemKey) {
      const purgedState = { ...this.duplicationState };
      delete purgedState[itemKey];
      this.duplicationState = purgedState;
      this.validatorsCollection[itemKey].reset();
    } else {
      this.duplicationState = {};
    }
  }

  validateList(list: Partial<T>[]): void {
    Object.keys(this.validatorsCollection).forEach(itemKey => {
      this.validateListItem(
        String(itemKey),
        list.find(li => li[this.itemKeyAttribute] === itemKey) || {}
      );
    });
  }

  validateListItem(itemKey: string, item: Partial<T>, properties?: (keyof T)[]): void {
    this.resetDuplicationState(itemKey);
    const validator = this.validatorsCollection[itemKey];
    if (!validator) {
      warnings.missingEntry(itemKey);
      return;
    }
    if (properties) {
      properties.forEach(prop => validator.validate(prop, item[prop]));
    } else {
      validator.validateAll(item);
    }
  }

  private isItemContainErrors(itemKey: string): boolean {
    const itemValidityState: ItemValidityMap<T> = this.state[itemKey];
    if (!itemValidityState) {
      warnings.missingEntry(itemKey);
      return false;
    }
    const haveErrors = Object.values(itemValidityState).some(
      errors => errors?.required || errors?.valueValidation?.length
    );
    return haveErrors;
  }

  isValid(itemKey?: string): boolean {
    if (itemKey) {
      return !this.isItemContainErrors(itemKey);
    }
    return !Object.keys(this.state).some(this.isItemContainErrors);
  }

  validateListForDuplication(list: T[], itemToValidate: T): void {
    if (!this.uniqueFields) {
      warnings.missingDuplicationsOptions();
      return;
    }
    Object.entries(this.uniqueFields).forEach(([fieldToValidate, config]) => {
      const duplicationError = isFieldValueExistsInContext(
        list,
        itemToValidate,
        fieldToValidate,
        this.itemKeyAttribute,
        config
      );
      if (duplicationError) {
        const itemKey = String(itemToValidate[this.itemKeyAttribute]);
        const itemDuplicationState = {
          ...this.duplicationState[itemKey],
          [fieldToValidate]: duplicationError,
        };
        this.duplicationState[itemKey] = itemDuplicationState;
      }
    });
  }
}
