import React from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import CircularProgressMUI from '@mui/material/CircularProgress';

/**
 * Enums
 */
export enum CircularProgressSizes {
  ExtraSmall = 'extraSmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'Large',
  ExtraLarge = 'ExtraLarge',
}

export const CircularProgressValueBySize = {
  [CircularProgressSizes.ExtraSmall]: 16,
  [CircularProgressSizes.Small]: 24,
  [CircularProgressSizes.Medium]: 32,
  [CircularProgressSizes.Large]: 48,
  [CircularProgressSizes.ExtraLarge]: 64,
};

/**
 * Main
 */
export interface CircularProgressBaseProps {
  sx?: SxProps<Theme>;
  thickness?: number;
  value?: number;
  variant?: 'determinate' | 'indeterminate';
  size?: number | string;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit';
  disableShrink?: boolean;
}

export const CircularProgressBase = ({
  color: _color = 'primary',
  size = CircularProgressValueBySize.ExtraLarge,
  thickness = 3,
  ...rest
}: CircularProgressBaseProps): JSX.Element => {
  return <CircularProgressMUI size={size} thickness={thickness} {...rest} />;
};
