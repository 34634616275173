import { AssetResponse, assetsService } from '../../../services/src/service/assets';
import type { CrossBrowserApiExecutor } from '../../../services/src/service/cross-browser-api';

import type { Dispatch } from '../../types';
import { UiTemplateAction, uiTemplateActionMethods, UiTemplateActionTypes } from './actions';

import type { UiTemplate } from '../types';

export const ApiName = 'SyteUiEditor';

interface UiEditorApi extends CrossBrowserApiExecutor {
  isEditorEnabled: () => boolean;
  getTemplate: (name: string) => Promise<UiTemplate | undefined>;
  saveTemplate: (template: UiTemplate) => Promise<UiTemplate>;
  getTargetTemplate: () => string | undefined;
  uploadFile: (file: File) => Promise<AssetResponse>;
}

interface Params {
  dispatch: Dispatch;
  shopId: number;
  targetTemplate?: string;
}

export function createUiEditorApi({ dispatch, shopId, targetTemplate }: Params): UiEditorApi {
  return {
    isEditorEnabled: () => true,

    getTargetTemplate: () => targetTemplate,

    getTemplate: async (name: string) => {
      let result: UiTemplate | undefined;
      const interceptor = (action: UiTemplateAction) => {
        if (action.type === UiTemplateActionTypes.GetUiTemplateSuccess) {
          result = action.payload;
        }
        dispatch(action);
      };
      const action = uiTemplateActionMethods.getUiTemplate({ shopId, name });
      await action(interceptor);

      return result;
    },

    saveTemplate: async (template: UiTemplate) => {
      let result: UiTemplate | undefined;
      const interceptor = (action: UiTemplateAction) => {
        if (action.type === UiTemplateActionTypes.SaveUiTemplateSuccess) {
          result = action.payload;
        }
        dispatch(action);
      };
      const action = uiTemplateActionMethods.saveUiTemplate({ ...template, shopId });
      await action(interceptor);

      if (!result) {
        throw new Error('Save of UiTemplate failed');
      }

      return result;
    },

    uploadFile: async (file: File) => {
      return assetsService.uploadAsset(file);
    },
  };
}
