import styled from '@emotion/styled';
import { TableV2 } from 'src/components-dummy';

export const TableWrapperStyled = styled.div`
  min-width: 560px;
`;

export const TableBodyRowCellStyled = styled(TableV2.BodyRowCell)`
  align-items: center;
  color: ${({ theme }) => theme.palette.common.black};
  display: flex;
  min-height: 64px;
  text-overflow: ellipsis;
`;

export const GalleryImageUrlCell = styled.a`
  display: inline-grid;
  text-overflow: ellipsis;
  * {
    color: ${({ theme }) => theme.palette.custom['primary-main']};
    text-decoration: underline;
  }
`;
