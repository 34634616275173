import React from 'react';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

interface SourceFieldChangeConfirmationDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export const SourceFieldChangeConfirmationDialog = ({
  onConfirm,
  onCancel,
}: SourceFieldChangeConfirmationDialogProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onCancel}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Changing filter data source</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        You are about to change the filter data source. Note that you may lose some settings, like
        custom arrangement.
        <br />
        Would you like to proceed?
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onConfirm}>
            Yes, change data source
          </Button>
          <Button variant='outlined' onClick={onCancel}>
            No
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
