import { SimilarItemsSettingsTypes } from 'src/services';
import { DomainForRendering } from '../../types';
import { similarItemsDiffSchema } from './similar-items-settings-diff-schema';

export const similarItemsSettingsSubDomainGroup: DomainForRendering<SimilarItemsSettingsTypes.SimilarItemsSettings> =
  {
    getCardTitle: () => {
      return 'General Settings';
    },
    fieldsSchema: similarItemsDiffSchema,
  };
