import React from 'react';
import { Box } from 'src/components-dummy/Box';
import { Skeleton } from 'src/components-dummy/Skeleton';
import { SkeletonVerticalBlocksWithTitle } from 'src/components-dummy/Skeleton/skeletonCompositions';
import { DeepTagsProductStyled, MainStyled } from '../DeepTagsProductForm.style';

export const DeepTagsProductFormSkeleton = (): JSX.Element => {
  return (
    <DeepTagsProductStyled>
      <Box sx={{ padding: '16px 24px' }}>
        <Skeleton width='20%' />
        <Skeleton height={32} width='40%' />
      </Box>
      <MainStyled>
        <SkeletonVerticalBlocksWithTitle
          itemProps={{ height: 300, width: '90%' }}
          sx={{ padding: '20px 25px 20px 50px', flex: 6 }}
        />
        <SkeletonVerticalBlocksWithTitle
          itemProps={{ height: 32 }}
          spacing={2}
          sx={{ padding: '25px 30px 0 0', flex: 4 }}
        />
        <MainStyled />
      </MainStyled>
    </DeepTagsProductStyled>
  );
};
