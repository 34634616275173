import React, { useCallback, useEffect, useState } from 'react';
import { generatePath } from 'react-router';
import { AvailableIcons, Page, Tooltip } from 'src/components-dummy';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch } from 'src/components-bl/types';
import { Filter, SyteLayout } from 'src/services';
import { DataFieldLookupTable } from 'src/components-bl/hooks/useDataFieldsLookupTable';
import { AddNewFilterButton, FiltersListPageStyled } from './FiltersListPage.styles';
import { filtersListPageActions } from './Actions';
import { FiltersListPageContent } from './FiltersListPageContent';

interface FiltersListPageProps extends RoutedComponentProps {
  shopId?: number;
  hasReachedMaxAllowedFiltersLimit: boolean | undefined;
  syteLayout: SyteLayout;
  dispatch: Dispatch;
  filters: Filter[] | undefined;
  dataFieldsLookupTable: DataFieldLookupTable | undefined;
}

export const FiltersListPage = ({
  filters,
  shopId,
  syteLayout,
  dispatch,
  permittedRouteMap,
  hasReachedMaxAllowedFiltersLimit,
  dataFieldsLookupTable,
}: FiltersListPageProps): JSX.Element => {
  const [fetchFiltersIsInProgress, setFetchFiltersIsInProgress] = useState(false);

  const navigateToCreateNewFilter = useCallback(() => {
    if (permittedRouteMap.createNewFilter && shopId) {
      dispatch(
        filtersListPageActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.createNewFilter.path, { shopId, syteLayout }),
        })
      );
    }
  }, [shopId, permittedRouteMap.createNewFilter, syteLayout, dispatch]);

  const onEnableFilter = useCallback(
    (filterId: string, enabled: boolean) => {
      if (shopId) {
        dispatch(
          filtersListPageActions.updateFilter({
            shopId,
            syteLayout,
            filterId,
            filterPayload: {
              enabled,
            },
          })
        );
      }
    },
    [shopId, syteLayout, dispatch]
  );

  const onDeleteFilter = useCallback(
    async (filterId: string) => {
      if (shopId) {
        dispatch(filtersListPageActions.deleteFilter({ shopId, syteLayout, filterId }));
      }
    },
    [shopId, syteLayout, dispatch]
  );

  const navigateToEditFilter = useCallback(
    (filterId: string) => {
      if (permittedRouteMap.editFilter && shopId) {
        dispatch(
          filtersListPageActions.navigateTo({
            navigateTo: generatePath(permittedRouteMap.editFilter.path, {
              shopId,
              syteLayout,
              filterId,
            }),
          })
        );
      }
    },
    [permittedRouteMap.editFilter, shopId, syteLayout]
  );

  useEffect(() => {
    if (!shopId) return;

    setFetchFiltersIsInProgress(true);
    // Array of filters is under filter set.
    (dispatch(filtersListPageActions.getFilterSet({ shopId, syteLayout })) as any)
      .unwrap()
      .then(() => {
        setFetchFiltersIsInProgress(false);
      })
      .catch(() => {
        setFetchFiltersIsInProgress(false);
      });
  }, [shopId, dispatch, syteLayout]);

  useEffect(() => {
    return () => dispatch(filtersListPageActions.resetFilters());
  }, []);

  return (
    <FiltersListPageStyled>
      <Page.Header>
        <Page.Title>Filters</Page.Title>
        <Tooltip
          value="You've reached the limit for active filters"
          disabled={!hasReachedMaxAllowedFiltersLimit}
        >
          <AddNewFilterButton
            variant='primary'
            disabled={hasReachedMaxAllowedFiltersLimit}
            startIcon={AvailableIcons.Add}
            onClick={navigateToCreateNewFilter}
          >
            Add New Filter
          </AddNewFilterButton>
        </Tooltip>
      </Page.Header>
      <Page.Content>
        <FiltersListPageContent
          filters={filters}
          shopId={shopId}
          syteLayout={syteLayout}
          navigateToCreateFilter={navigateToCreateNewFilter}
          onEnableFilter={onEnableFilter}
          onDeleteFilter={onDeleteFilter}
          navigateToEditFilter={navigateToEditFilter}
          fetchFiltersInProgress={fetchFiltersIsInProgress}
          dataFieldsLookupTable={dataFieldsLookupTable}
          dispatch={dispatch}
        />
      </Page.Content>
    </FiltersListPageStyled>
  );
};
