import { createAction } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { APITypes } from 'src/services';

/* --= Action Types =-- */
export enum NavMidlTypes {
  LogoutRequest = 'NavMidlTypes/LogoutRequest',
  LogoutSuccess = 'NavMidlTypes/LogoutSuccess',
  LogoutError = 'NavMidlTypes/LogoutError',
}

/* --= Action Interfaces =-- */

interface LogoutRequestAction {
  type: typeof NavMidlTypes.LogoutRequest;
}

interface LogoutSuccessAction {
  type: typeof NavMidlTypes.LogoutSuccess;
}

interface LogoutErrorAction {
  type: typeof NavMidlTypes.LogoutError;
  payload: { error: APITypes.ApiError | unknown };
}

export type NavMidlAction = LogoutRequestAction | LogoutSuccessAction | LogoutErrorAction;

/* --= Actions =-- */
export const navigationActions = {
  confirmNav: createAction<Action | undefined>('NavigationMiddleware/ConfirmNav'),
  logoutRequest: (): LogoutRequestAction => {
    return {
      type: NavMidlTypes.LogoutRequest,
    };
  },
  logoutSuccess: (): LogoutSuccessAction => {
    return {
      type: NavMidlTypes.LogoutSuccess,
    };
  },
  logoutError: (error: APITypes.ApiError | unknown): LogoutErrorAction => {
    return {
      type: NavMidlTypes.LogoutError,
      payload: { error },
    };
  },
  // used to navigate after navigateToShop
  navigateToShop: createAction<{ navigateTo: string; shopId: number | undefined }>(
    'NavigationMiddleware/NavigateToShop'
  ),
};
