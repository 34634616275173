import { Middleware } from 'redux';
import { toastMiddleware } from './toast-middleware';
import { navigateMiddleware } from './navigate-middleware';
import { syncParamsWithStateMiddleware } from './sync-params-with-state-middleware/sync-params-with-state-middleware';

export const middlewares = (history: any): Middleware[] => {
  return [
    toastMiddleware,
    navigateMiddleware(history),
    syncParamsWithStateMiddleware,
  ] as Middleware[];
};
