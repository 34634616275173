import React, { useMemo } from 'react';
import { css } from '@mui/material';
import { Typography, TypographyVariant, TypographyType } from 'src/components-dummy';
import { Slider } from 'src/components-dummy/Slider';
import { PopoverInput } from 'src/components-dummy/PopoverInput';
import {
  MAX_LOWEST,
  RULE_WEIGHT_STEPS,
} from 'src/components-bl/MerchandisingRules/merchandisingRules.config';
import { getWeightTranslation } from 'src/components-bl/MerchandisingRules/merchandisingRules.helpers';
import { startCase } from 'lodash';

export interface WeightSliderPopoverProps {
  weight: number;
  onFieldChange: ({ weight }: { weight: number }) => void;
  isReadOnly?: boolean;
}

const STEP_INTERVAL = 10;

export const WeightSliderPopover = ({
  weight,
  isReadOnly,
  onFieldChange,
}: WeightSliderPopoverProps): JSX.Element => {
  const weightSteps = useMemo(
    () =>
      RULE_WEIGHT_STEPS.map(weightStep => ({
        value: weightStep.maxValue,
        label: startCase(weightStep.name),
      })),
    []
  );

  return (
    <PopoverInput<number>
      defaultValue={weight}
      onChanged={value => onFieldChange({ weight: value })}
      isReadOnly={isReadOnly}
    >
      {({ open, inputValue, setInputValue }) => {
        return (
          <>
            <PopoverInput.TriggerButton onClick={open} disabled={isReadOnly}>
              {weight ? getWeightTranslation(weight) : 'Volume'}
            </PopoverInput.TriggerButton>
            <PopoverInput.Content>
              <Typography
                type={TypographyType.Body}
                variant={TypographyVariant.SmallMedium}
                css={css`
                  display: block;
                  margin-bottom: 20px;
                `}
              >
                Select Volume
              </Typography>
              <Slider
                defaultValue={inputValue}
                onChange={(_, value) => setInputValue(value as number)}
                min={MAX_LOWEST}
                aria-label='Volume'
                step={STEP_INTERVAL}
                valueLabelDisplay='on'
                marks={weightSteps}
              />
            </PopoverInput.Content>
          </>
        );
      }}
    </PopoverInput>
  );
};
