import React, { useState, useCallback } from 'react';
import { ChangesFilterValues } from 'src/services';
import {
  Typography,
  TypographyType,
  TypographyVariant,
  PopUp,
  AvailableIcons,
  MultiSelectMenu,
  MultiSelectOption,
} from 'src/components-dummy';
import {
  MultiSelectMenuWrapperStyled,
  CheckIconStyled,
  LexiconChangesIconStyled,
  EllipsisWithTooltipStyled,
} from '../LexiconTable.styles';
import { CHANGES_FILTER_ALL_VALUES } from '../LexiconTable.config';
import { isChangesFilterActive } from '../LexiconTable.helpers';

const optionValueToTextMap: Record<ChangesFilterValues, string> = {
  [ChangesFilterValues.Renamed]: 'Show renamed',
  [ChangesFilterValues.Original]: 'Show original',
  [ChangesFilterValues.On]: 'Show turned on',
  [ChangesFilterValues.Off]: 'Show turned off',
  [ChangesFilterValues.Draft]: 'Show draft',
  [ChangesFilterValues.Published]: 'Show published',
};

const options: MultiSelectOption[] = CHANGES_FILTER_ALL_VALUES.map(key => {
  return {
    text: optionValueToTextMap[key],
    value: key,
  };
});

export interface LexiconChangesFilterProps {
  onChange: (newState: ChangesFilterValues[]) => void;
  selectedValues: ChangesFilterValues[];
}

export const LexiconChangesFilter = ({
  onChange,
  selectedValues,
}: LexiconChangesFilterProps): JSX.Element => {
  const [shouldShowPopup, setShouldShowPopup] = useState(false);

  const onTriggerClick = useCallback(() => {
    setShouldShowPopup(show => !show);
  }, [setShouldShowPopup]);

  const onMultipleSelectChange = useCallback(
    newOptions => {
      onChange(newOptions as ChangesFilterValues[]);
    },
    [onChange]
  );

  const isActive = isChangesFilterActive(selectedValues);

  return (
    <PopUp
      show={shouldShowPopup}
      onShow={setShouldShowPopup}
      closeOnTriggerClick={false}
      hideOnScroll={false}
      position='bottom left'
    >
      <PopUp.Trigger>
        <LexiconChangesIconStyled
          name={AvailableIcons.Filter}
          onClick={onTriggerClick}
          isFilterActive={isActive}
        />
      </PopUp.Trigger>

      <PopUp.Content>
        <MultiSelectMenuWrapperStyled>
          <MultiSelectMenu
            options={options}
            selectedOptions={selectedValues}
            onChange={onMultipleSelectChange}
            enableSelectAll
            selectAllText='Select all'
          >
            <MultiSelectMenu.OptionTemplate>
              {(option: MultiSelectOption, isChecked: boolean) => (
                <>
                  <CheckIconStyled checked={isChecked} name={AvailableIcons.CheckRounded} />
                  <EllipsisWithTooltipStyled tooltipText={option.text}>
                    <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                      {option.text}
                    </Typography>
                  </EllipsisWithTooltipStyled>
                </>
              )}
            </MultiSelectMenu.OptionTemplate>
          </MultiSelectMenu>
        </MultiSelectMenuWrapperStyled>
      </PopUp.Content>
    </PopUp>
  );
};
