import { createAsyncThunk } from '@reduxjs/toolkit';
import { dataFieldsService, FieldType } from '../../../../../services';

export const editCollectionRulesContainerActions = {
  getDataFields: createAsyncThunk(
    'EditCollectionRulesContainer/GetDataFields',
    async (
      { shopId, fieldType = FieldType.CatalogField }: { shopId: number; fieldType?: FieldType },
      { rejectWithValue }
    ) => {
      try {
        const dataFields = await dataFieldsService.getDataFields({ shopId, fieldType });
        return { dataFields };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
