import classNames from 'classnames';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Tooltip, Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { SideNavItemProps, SideNavRoute } from '../types';

export const SideNavItem = ({
  item: sideNavItem,
  isMinimized,
  onNavigate,
}: SideNavItemProps): JSX.Element => {
  const match = useRouteMatch({ path: sideNavItem.pathTemplate });
  const isActive = !!match;
  const { title, icon, className: routeClassName } = sideNavItem;

  const { url } = sideNavItem as SideNavRoute;

  const navLink = (
    <NavLink
      key={title}
      to={url || ''}
      isActive={() => isActive}
      className={classNames(
        'syte-side-navigation-link',
        { 'syte-side-navigation-route': !!url },
        {
          minimized: isMinimized,
        }
      )}
      activeClassName='syte-side-navigation-selected-link'
      onClick={ev => {
        ev.preventDefault();
        if (url) {
          onNavigate?.(url);
        }
      }}
    >
      <Tooltip value={title} position='right center' disabled={!isMinimized}>
        {icon || null}
        {!isMinimized && (
          <Typography
            type={TypographyType.Body}
            variant={TypographyVariant.MediumRegular}
            className='syte-side-navigation-item-title'
          >
            {title}
          </Typography>
        )}
      </Tooltip>
    </NavLink>
  );

  return (
    <div key={url} className={classNames('syte-side-navigation-item', routeClassName)}>
      {navLink}
    </div>
  );
};
