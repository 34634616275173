import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';

export interface PageTitleProps {
  children: ReactNode | ReactNode[];
  className?: string;
  variant?: TypographyVariant;
}

export const PageTitle = ({
  children,
  className,
  variant = TypographyVariant.ExtraSmallBold,
}: PageTitleProps): JSX.Element => {
  return (
    <Typography
      className={classNames('syte-page-title', className)}
      type={TypographyType.Heading}
      variant={variant}
    >
      {children}
    </Typography>
  );
};
