import React from 'react';
import {
  Tooltip,
  TextBox,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
  Switch,
} from 'src/components-dummy';
import { AvailableIcons } from 'src/components-dummy/Icon/AvailableIcons';
import { RecEnginesGeneralSettingsType } from 'src/services';
import './OverrideSettings.scss';

export interface OverrideSettingsProps {
  generalSettings: RecEnginesGeneralSettingsType.RecEnginesGeneralSettings;
  onChange: (
    args: Partial<RecEnginesGeneralSettingsType.RecEnginesGeneralSettings['overrideImageUrl']>
  ) => void;
  disabled?: boolean;
}

export const OverrideSettings = ({
  generalSettings,
  onChange,
  disabled = false,
}: OverrideSettingsProps): JSX.Element | null => {
  return (
    <div>
      <div className='override-selesctors-settings'>
        <Switch
          disabled={disabled}
          checked={generalSettings.overrideImageUrl?.active}
          onChange={() => {
            onChange({
              active: !generalSettings.overrideImageUrl?.active,
            });
          }}
          className='override-settings-toggle'
        >
          <Switch.TitleTemplate
            className={`override-settings-text ${
              generalSettings.overrideImageUrl?.active ? '' : 'disabled'
            }`}
          >
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Override div Image Url (for fallback) with selector:
            </Typography>
          </Switch.TitleTemplate>
        </Switch>
        <div className='override-settings-tooltip'>
          <Tooltip value='Use to override  data-image-src in Syte carousel div.'>
            <Icon name={AvailableIcons.TooltipQuestionmark} />
          </Tooltip>
        </div>
      </div>

      <div className='override-settings-textboxes'>
        <TextBox
          label='Image Url Selector'
          value={generalSettings.overrideImageUrl?.selector}
          onChange={text => {
            onChange({
              selector: text,
            });
          }}
          className='image-url-selector-input'
          error={
            generalSettings.overrideImageUrl?.active &&
            generalSettings.overrideImageUrl?.selector === ''
          }
          disabled={!generalSettings.overrideImageUrl?.active || disabled}
        />

        <TextBox
          label='Image Url Attribute'
          value={generalSettings.overrideImageUrl?.attribute}
          onChange={text => {
            onChange({
              attribute: text,
            });
          }}
          className='image-url-attribute-input'
          error={
            generalSettings.overrideImageUrl?.active &&
            generalSettings.overrideImageUrl?.attribute === ''
          }
          disabled={!generalSettings.overrideImageUrl?.active || disabled}
        />
      </div>
    </div>
  );
};
