import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useParams } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { useAppSelector, usePreviousState } from 'src/hooks';
import { CatalogFeedUploadLogPage } from 'src/components-bl/CatalogManager/CatalogFeedUploadLog/components/ListPage/CatalogFeedUploadLogPage';
import { UserRoles } from 'src/services/src/service/types/users';
import { CatalogFeedUploadLogContainerActions } from './CatalogFeedUploadLogContainer.actions';

export const CatalogFeedUploadLogContainer = ({
  permittedRouteMap,
}: RoutedComponentProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const { shopId } = useAppSelector(state => state.shop.current) || {};
  const { catalogFeedUploadLogs } = useAppSelector(state => state.catalogManager) || {};
  const previousShopId = usePreviousState(shopId);
  const userRole = useAppSelector(state => state.global.loggedInUser?.role);

  const { catalogName } = useParams<{ catalogName: string }>();

  const navigateToCatalogsManagerList = useCallback(() => {
    if (permittedRouteMap.catalogManager?.path && shopId) {
      const path = generatePath(permittedRouteMap.catalogManager?.path, { shopId });

      dispatch(
        CatalogFeedUploadLogContainerActions.navigateTo({
          navigateTo: path,
        })
      );
    }
  }, [permittedRouteMap.catalogManager?.path, shopId]);

  const navigateToFeedErrorReport = useCallback(
    (jobId: string) => {
      if (permittedRouteMap.catalogManagerUploadErrorReportList?.path && shopId) {
        const path = generatePath(permittedRouteMap.catalogManagerUploadErrorReportList?.path, {
          shopId,
          catalogName,
          jobId,
        });

        dispatch(
          CatalogFeedUploadLogContainerActions.navigateTo({
            navigateTo: path,
          })
        );
      }
    },
    [permittedRouteMap.catalogManagerUploadErrorReportList?.path, shopId, catalogName]
  );

  useEffect(() => {
    if (previousShopId && shopId !== previousShopId && shopId) {
      navigateToCatalogsManagerList();
    }
  }, [navigateToCatalogsManagerList, previousShopId, shopId]);

  useEffect(() => {
    if (shopId) {
      if (catalogName) {
        const fetch = async () => {
          try {
            await (
              dispatch(
                CatalogFeedUploadLogContainerActions.getCatalogFeedUploadFileContent({
                  shopId,
                  catalogName,
                })
              ) as any
            ).unwrap();
          } catch (error) {
            console.error(error);
            navigateToCatalogsManagerList();
          }
        };

        fetch();
      }
    }
  }, [catalogName, dispatch, navigateToCatalogsManagerList, shopId]);

  if (!shopId) {
    return null;
  }

  const isAdminUser = userRole === UserRoles.SyteAdmin;

  return (
    <CatalogFeedUploadLogPage
      catalogFeedUploadLogs={catalogFeedUploadLogs}
      navigateToFeedErrorReport={navigateToFeedErrorReport}
      navigateToCatalogsList={navigateToCatalogsManagerList}
      isAdminUser={isAdminUser}
      catalogName={catalogName}
      shopId={shopId}
      dispatch={dispatch}
    />
  );
};
