import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  DeepTagAPI,
  DeepTagReportProductDataCategory,
  DeepTagReportProductDataParentCategory,
  DeepTagStatusEnum,
  IDeepTagReportProduct,
  ILexiconCategories,
  ILexiconCategory,
} from 'src/services/src/service/types';
import { isTagsFormDirty } from './DeepTagsProductReviewAndEditTagsSection.helpers';
import { DeepTagForm } from '../../DeepTagsProductForm.types';

/**
 * Types
 */
export interface ISubProductFormData {
  id: string;
  tags: DeepTagForm[];
  category: DeepTagReportProductDataCategory;
  parentCategory: DeepTagReportProductDataParentCategory;
  hasChangedCategory: boolean;
  isDirty: boolean;
}

interface ISubProductItemsFormData {
  [id: string]: ISubProductFormData;
}

export type OnChangeSubProductTagsFormData = ({
  tags,
}: {
  tags: ISubProductFormData['tags'];
}) => void;

export type OnChangeCategoryFormData = ({
  category,
}: {
  category: ISubProductFormData['category'];
}) => void;

export type OnSetCategoryHasChanged = (hasChanged: boolean) => void;

const DEFAULT_PARENT_CATEGORY_CHANGE = {
  name: undefined,
  translation: '',
  isEdited: true,
};

/**
 * Main
 */
export interface IUseDeepTagsSubProductsFormResponse {
  subProductItemsFormData: ISubProductItemsFormData;
  onChangeSubProductTagsFormData: (id: string) => OnChangeSubProductTagsFormData;
  onChangeSubProductCategoryFormData: (id: string) => OnChangeCategoryFormData;
  onSetSubProductCategoryHasChangedFormData: (id: string) => OnSetCategoryHasChanged;
  isSubProductsFormValid: boolean;
}

type ProductDataListItemFromAPIMap = Pick<
  IDeepTagReportProduct['data'][0],
  'category' | 'parentCategory'
> & { tags: Record<string, DeepTagAPI> };

interface IUseDeepTagsSubProductsForm {
  productDataListFromAPI: IDeepTagReportProduct['data'];
  lexiconCategories?: ILexiconCategories;
}

export const useDeepTagsSubProductsForm = ({
  productDataListFromAPI,
  lexiconCategories,
}: IUseDeepTagsSubProductsForm): IUseDeepTagsSubProductsFormResponse => {
  const [subProductItemsFormData, setSubProductItemsFormData] = useState<ISubProductItemsFormData>(
    {}
  );

  const productDataListFromAPIMap: Record<string, ProductDataListItemFromAPIMap> = useMemo(() => {
    return productDataListFromAPI.reduce((prevSubProduct: Record<string, any>, subProduct) => {
      // eslint-disable-next-line no-param-reassign
      prevSubProduct[subProduct.id] = {
        category: subProduct.category,
        parentCategory: subProduct.parentCategory,
        tags: subProduct.tags.reduce((prevTags: Record<string, DeepTagAPI>, tag) => {
          // eslint-disable-next-line no-param-reassign
          prevTags[tag.attribute.key] = tag;
          return prevTags;
        }, {}),
      };
      return prevSubProduct;
    }, {});
  }, [productDataListFromAPI]);

  useEffect(() => {
    const mappedProductDataList = productDataListFromAPI.reduce(
      (prev: ISubProductItemsFormData, dataObject) => {
        const { id, tags, category, parentCategory } = dataObject;

        const tagsWithoutDeleted = tags.filter(tag => tag.status !== DeepTagStatusEnum.Deleted);
        // eslint-disable-next-line no-param-reassign
        prev[id] = {
          id,
          tags: tagsWithoutDeleted,
          category,
          parentCategory,
          hasChangedCategory: false,
          isDirty: false,
        };

        return prev;
      },
      {}
    );

    setSubProductItemsFormData(mappedProductDataList);
  }, [productDataListFromAPI]);

  const onChangeItemTagsFormData = useCallback(
    (id: string) =>
      ({ tags: updatedTags }: { tags: ISubProductFormData['tags'] }) => {
        setSubProductItemsFormData(prevState => {
          if (!prevState) return prevState;

          const isSubProductDirtyValue = isTagsFormDirty({
            newFormData: updatedTags,
            apiTagsByAttributeMap: productDataListFromAPIMap[id].tags,
            apiTagsList: productDataListFromAPI.find(dataObj => dataObj.id === id)?.tags || [],
          });

          return {
            ...prevState,
            [id]: {
              ...prevState[id],
              isDirty: isSubProductDirtyValue || prevState[id].hasChangedCategory,
              tags: updatedTags,
            },
          };
        });
      },
    [productDataListFromAPI, productDataListFromAPIMap]
  );

  /**
   * 1. Preserve form tags which are existed (by key) in the lexicon of the new category.
   * 2. update translation of attribute/value of the new category.
   */
  const extractFormTagsForCategory = ({
    newLexiconCategory,
    formTags,
  }: {
    newLexiconCategory: ILexiconCategory | undefined;
    formTags: ISubProductFormData['tags'];
  }): ISubProductFormData['tags'] => {
    // New category not exist in Lexicon (probably custom category name)
    if (!newLexiconCategory) {
      return [];
    }

    return formTags.reduce(
      (prev: ISubProductFormData['tags'], formTag: ISubProductFormData['tags'][0]) => {
        const newLexiconTagAttribute = newLexiconCategory.attributes[formTag.attribute.key];

        // Exclude the tag if the attribute does not exist in the new lexicon category
        if (!newLexiconTagAttribute) {
          return prev;
        }

        const newLexiconTagValue = newLexiconTagAttribute.values[formTag.value.key || ''];

        // Exclude the tag if the value does not exist in the new lexicon category
        if (!newLexiconTagValue) {
          return prev;
        }

        const updatedTag: ISubProductFormData['tags'][0] = {
          ...formTag,
          attribute: {
            ...formTag.attribute,
            translation: newLexiconTagAttribute.translation, // update translation according to new category
          },
          value: {
            ...formTag.value,
            translation: newLexiconTagValue.translation, // update translation according to new category
          },
        };

        prev.push(updatedTag);
        return prev;
      },
      []
    );
  };

  const onChangeItemCategoryFormData = useCallback(
    (id: string) =>
      ({ category: updatedCategory }: { category: ISubProductFormData['category'] }) => {
        setSubProductItemsFormData(prevState => {
          if (!prevState) return prevState;

          const hasCategoryChangedAgainstAPIState =
            updatedCategory.translation !== productDataListFromAPIMap[id].category.translation;

          const hasCategoryChangedAgainstPreviousState =
            updatedCategory.translation !== prevState[id].category.translation;

          const hasCategoryChanged =
            hasCategoryChangedAgainstAPIState || hasCategoryChangedAgainstPreviousState;

          const parentCategory = hasCategoryChangedAgainstAPIState
            ? DEFAULT_PARENT_CATEGORY_CHANGE
            : productDataListFromAPIMap[id].parentCategory;

          const commonNewCategoryFormTags = extractFormTagsForCategory({
            newLexiconCategory: lexiconCategories?.[updatedCategory.name || -1],
            formTags: prevState[id].tags,
          });

          if (hasCategoryChanged) {
            return {
              ...prevState,
              [id]: {
                ...prevState[id],
                category: updatedCategory,
                parentCategory,
                hasChangedCategory: hasCategoryChanged,
                tags: commonNewCategoryFormTags,
                isDirty: !!prevState[id].isDirty || hasCategoryChanged,
              },
            };
          }

          return prevState;
        });
      },
    [lexiconCategories, productDataListFromAPIMap]
  );

  const onSetCategoryHasChangedFormData = useCallback(
    (id: string) => (hasChanged: boolean) => {
      setSubProductItemsFormData(prevState => {
        if (!prevState) return prevState;

        return {
          ...prevState,
          [id]: {
            ...prevState[id],
            hasChangedCategory: hasChanged,
          },
        };
      });
    },
    []
  );

  const isSubProductsFormValid = useMemo(() => {
    const isAllDataListTagsValid = Object.values(subProductItemsFormData).every(dataObject => {
      return (dataObject?.tags || []).every(tag => {
        const isTagValueFulfilled = tag.value.key && tag.value.translation;

        return !!isTagValueFulfilled;
      });
    });

    return isAllDataListTagsValid;
  }, [subProductItemsFormData]);

  return {
    subProductItemsFormData,
    onChangeSubProductTagsFormData: onChangeItemTagsFormData,
    onChangeSubProductCategoryFormData: onChangeItemCategoryFormData,
    onSetSubProductCategoryHasChangedFormData: onSetCategoryHasChangedFormData,
    isSubProductsFormValid,
  };
};
