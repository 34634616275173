import { DomainEntityPath } from '../types';
import { DomainEventsWebSocketServiceBase, DomainEvent } from '../domain-events';

export enum LexiconEventTopicNames {
  ImportExportProgress = 'lexicon:import-export-progress',
  MetadataChanged = 'lexicon:metadata-changed',
}

export type LexiconImportExportProgressMessage = DomainEvent<
  {
    progress: number;
    shopId: number;
    locale: string;
    requestId: string;
  },
  LexiconEventTopicNames
>;

export type LexiconMetadataChangedMessage = DomainEvent<
  { shopId: number; locales: string[] },
  LexiconEventTopicNames
>;

class LexiconWebSocketService extends DomainEventsWebSocketServiceBase<LexiconEventTopicNames> {
  domainEntity = DomainEntityPath.Lexicon;

  subscribeToImportExportProgressEvents(
    onProgressChange: (payload: LexiconImportExportProgressMessage) => void
  ): void {
    this.subscribeToEvents({
      topicKey: LexiconEventTopicNames.ImportExportProgress,
      callback: onProgressChange,
    });
  }

  subscribeToMetadataChangeEvents(
    onMetadataMessage: (payload: LexiconMetadataChangedMessage) => void
  ): void {
    this.subscribeToEvents({
      topicKey: LexiconEventTopicNames.MetadataChanged,
      callback: onMetadataMessage,
    });
  }
}

export const lexiconWebSocketService = new LexiconWebSocketService();
