import styled from '@emotion/styled';

export const ReadOnlyTextStyled = styled.div`
  display: flex;
  max-width: 100%;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.custom['gray-10']};
  }
`;

export const TextBoxWrapperStyled = styled.div<{ width?: string }>`
  .input-base-wrapper {
    padding: 2px;
    border-radius: 4px;
    height: initial;
  }

  input {
    padding: 5px 0 5px;
    letter-spacing: 0em;
    width: ${({ width }) =>
      width || 'auto'}; // Set the width property based on the passed width or default to 'auto'
  }
`;

export const SpinnerWrapperStyled = styled.div<{ width?: string }>`
  display: flex;
  padding: 0 6px;
`;
