import './EditPosts.scss';
import React from 'react';
import { PostsBar } from '../PostsBar/PostsBar';
import { ModerationModal } from '../ModerationModal/ModerationModal';
import { useEditPostsState } from '../../../../components-bl/socialDiscovery/EditSocialCollection/useEditPostsState/useSocialCollectionState';
import { PublicPost } from '../../../../components-bl/socialDiscovery/types/entities.types';
import { StateType } from '../../../../components-bl/socialDiscovery/EditSocialCollection/useEditPostsState/types';
import { EditPost } from '../EditPost/EditPost';
import { PostOrder } from '../PostOrder/PostOrder';
import { PostPreview } from '../PostPreview/PostPreview';
import { useSocialDiscoveryProvider } from '../../SocialDiscoveryProvider';

interface EditPostsProps {
  publicPosts: PublicPost[];
  onChange: (updatedPosts: PublicPost[]) => void;
}

export const EditPosts = ({ publicPosts, onChange }: EditPostsProps): JSX.Element => {
  const { service, appDispatch } = useSocialDiscoveryProvider();
  const {
    state,
    selectPost,
    addPublicPosts,
    removePost,
    openModerationModal,
    closeModerationModal,
    changePostOrder,
    setAssignedProducts,
  } = useEditPostsState(service, appDispatch, publicPosts, onChange);

  const key = state.editedPosts.length ? state.editedPosts[state.selectedPostIndex].id : 'no-post';

  return (
    <div className='edit-posts-content' data-testid='edit-posts'>
      <PostsBar
        socialPosts={state.editedPosts}
        selectedPost={state.selectedPostIndex}
        onAddPosts={openModerationModal}
        onSelectPost={selectPost}
        onDeletePost={removePost}
      />
      <EditPost currentIndex={state.selectedPostIndex}>
        <PostOrder
          itemsCount={state.editedPosts.length}
          currentIndex={state.selectedPostIndex}
          onChange={changePostOrder}
        />
        <PostPreview
          key={key}
          post={state.editedPosts[state.selectedPostIndex]}
          onTagsChange={setAssignedProducts}
        />
      </EditPost>
      <ModerationModal
        show={state.type === StateType.moderationModalOpen}
        moderatedPosts={state.moderatedPosts}
        onClose={closeModerationModal}
        onSubmit={addPublicPosts}
      />
    </div>
  );
};
