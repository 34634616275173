import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEqual, difference, differenceWith, isEmpty, omit } from 'lodash';
import { RootReducerState, UpdateReqStatus } from 'src/app-state-types';
import { EditableFilterItem } from '../types';
import { IsIncludedIndicator } from '../../../components-dummy';

interface UseListChangeTracker {
  (
    initialData: EditableFilterItem[],
    dispatcher: (val: boolean) => void,
    hasExternalDiff: boolean,
    updateStatus: UpdateReqStatus
  ): {
    isDirty: boolean;
    registerListChange: (updatedList: EditableFilterItem[]) => void;
    resetChangeLog: (savedData: EditableFilterItem[]) => void;
    diff: EditableFilterItem[];
    getItemDiff: (itemKey: string) => Partial<EditableFilterItem> | undefined;
  };
}

export const useListChangeTracker: UseListChangeTracker = (
  initialData,
  dispatcher,
  hasExternalDiff,
  updateStatus
) => {
  const {
    global: { isDirty },
  } = useSelector((state: RootReducerState) => state);
  const [listChangeTracker, setListChangeTracker] = useState<EditableFilterItem[]>([]);
  const sanitizationFields: string[] = ['_id', IsIncludedIndicator, 'filterStatus'];
  const sanitizeList = (list: EditableFilterItem[]) => {
    const sanitizedList = list.map(item => {
      return omit(item, sanitizationFields);
    });
    return sanitizedList as EditableFilterItem[];
  };
  const [latestVersion, setLatestVersion] = useState<EditableFilterItem[]>(
    sanitizeList(initialData)
  );

  useEffect(() => {
    setLatestVersion(sanitizeList(initialData));
  }, [initialData]);

  useEffect(() => {
    if ([UpdateReqStatus.Pending, UpdateReqStatus.Failed].includes(updateStatus)) return;
    const haveDiff = Boolean(listChangeTracker.length) || hasExternalDiff;
    dispatcher(haveDiff);
  }, [listChangeTracker, hasExternalDiff, updateStatus]);

  const registerListChange = (updatedList: EditableFilterItem[]) => {
    const latestVersionForCompare = sanitizeList(latestVersion);
    const updatedListForCompare = sanitizeList(updatedList);
    const changes =
      updatedListForCompare.length === latestVersionForCompare.length
        ? differenceWith(updatedListForCompare, latestVersionForCompare, isEqual)
        : difference(updatedListForCompare, latestVersionForCompare);
    setListChangeTracker(changes);
  };

  const resetChangeLog = (savedData: EditableFilterItem[]) => {
    setLatestVersion(savedData);
    setListChangeTracker([]);
    dispatcher(false);
  };

  const getItemDiff = (itemKey: string): Partial<EditableFilterItem> | undefined => {
    const completeItemDiff = listChangeTracker.find(li => li.key === itemKey);
    if (!completeItemDiff) return undefined;
    const initialItem = latestVersion.find(li => li.key === itemKey);
    if (!initialItem) return completeItemDiff;
    const diffAcc = {} as Partial<EditableFilterItem>;
    const purgedItemDiff = omit(completeItemDiff, sanitizationFields) as EditableFilterItem;
    const purgedInitialItem = omit(initialItem, sanitizationFields) as EditableFilterItem;
    return Object.keys(purgedItemDiff).reduce((diff, key) => {
      if (purgedInitialItem[key] === purgedItemDiff[key]) return diff;
      return {
        ...diff,
        [key]: completeItemDiff[key],
      };
    }, diffAcc);
  };

  return {
    isDirty,
    registerListChange,
    resetChangeLog,
    diff: listChangeTracker,
    getItemDiff,
  };
};

interface UseSettingsChangeTrackerReturns<T> {
  isDirty: boolean;
  registerSettingsChange: (updatedSettings: T) => void;
  resetChangeLog: (savedData: T) => void;
  diff: Partial<T> | undefined;
}

export const useSettingsChangeTracker = <T extends Record<string, unknown>>(
  initialData: T
): UseSettingsChangeTrackerReturns<T> => {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [latestVersion, setLatestVersion] = useState<T>(initialData);
  const [settingsChangeTracker, setSettingsChangeTracker] = useState<T>();

  useEffect(() => {
    const haveDiff = !isEmpty(settingsChangeTracker);
    setIsDirty(haveDiff);
  }, [settingsChangeTracker]);

  const registerSettingsChange = (updatedSettings: T) => {
    const changes: T = differenceWith([latestVersion], [updatedSettings], isEqual)[0];
    setSettingsChangeTracker(changes);
  };

  const resetChangeLog = (savedData: T) => {
    setLatestVersion(savedData);
    setSettingsChangeTracker(undefined);
  };

  return {
    isDirty,
    registerSettingsChange,
    resetChangeLog,
    diff: settingsChangeTracker,
  };
};
