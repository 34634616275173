import { useState, useEffect } from 'react';
import { EditableFilterItem } from 'src/services/src/service/types/filters';

export const useItemsCount = (
  items: EditableFilterItem[]
): { itemsCount: number; activeItemsCount: number } => {
  const [itemsCount, setItemsCount] = useState<number>(items.length);
  const [activeItemsCount, setActiveItemsCount] = useState<number>(
    items.filter(item => item.enabled).length
  );
  useEffect(() => {
    setItemsCount(items.length);
    setActiveItemsCount(items.filter(item => item.enabled).length);
  }, [items]);

  return { itemsCount, activeItemsCount };
};
