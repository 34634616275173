import React from 'react';
import './DisconnectConfirmationModal.scss';
import { OverlayModal } from '../../../../components-dummy/OverlayModal';
import {
  Typography,
  TypographyType,
  TypographyVariant,
} from '../../../../components-dummy/Typography';
import { Button } from '../../../../components-dummy/Button';
import { useConnectionStateProvider } from '../../../../components-bl/socialDiscovery/socialConnection/ConnectionStateProvider';
import { StateType } from '../../../../components-bl/socialDiscovery/socialConnection/useSocialConnectionState/types';

export const DisconnectConfirmationModal = ({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) => {
  const { state, disconnect } = useConnectionStateProvider();
  return (
    <OverlayModal className='syte-dialog' show={show}>
      <div className='disconnect-confirmation-modal'>
        <div className='modal-header'>
          <Typography type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
            Disconnect social source
          </Typography>
        </div>
        <div className='modal-body'>
          All posts and tags related to this social source will be disconnected from your shop
        </div>
        <div className='modal-footer'>
          <Button
            disabled={state.type === StateType.disconnecting}
            variant='tertiary'
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={state.type === StateType.disconnecting}
            variant='tertiary'
            className='disconnect-btn'
            onClick={disconnect}
          >
            Disconnect
          </Button>
        </div>
      </div>
    </OverlayModal>
  );
};
