import React from 'react';
import './BackButtonTitle.scss';
import { Icon, AvailableIcons } from 'src/components-dummy';
import { PageTitle } from '../Page/Components/PageTitle';
import { useAppNavigation } from '../../hooks/useAppNavigation';

interface Props {
  path: string;
  children: React.ReactNode;
  className?: string;
}

export const BackButtonTitle = ({ path, children, className }: Props): JSX.Element => {
  const navigateTo = useAppNavigation();
  return (
    <div className='back-button-title-container'>
      <Icon
        className='back-button-title-icon'
        name={AvailableIcons.ChevronLeft}
        onClick={() => navigateTo(path)}
      />
      <PageTitle className={className}>{children}</PageTitle>
    </div>
  );
};
