import React from 'react';
import { DeepTagStatusEnum, ILexiconCategory, IDeepTagsProductDataItem } from 'src/services';
import {
  EllipsisWithTooltip,
  Typography,
  TypographyType,
  TypographyVariant,
  AvailableIcons,
} from 'src/components-dummy';
import { SkeletonDefaultContent } from 'src/components-dummy/Skeleton/skeletonCompositions';
import {
  AttributeNameStyled,
  ValueStyled,
  TagStyled,
  SubProductTagsListStyled,
  AttributeStyled,
  RemoveButtonWrapperStyled,
  IconStyled,
} from './DeepTagProductTags.style';
import { DeepTagProductTag } from './DeepTagProductTag';
import { DeepTagStatus } from './DeepTagStatus';
import {
  DeepTagForm,
  OnGeneratedAttributeEdit,
  OnRemoveOrRestoreTag,
} from '../../../DeepTagsProductForm.types';

export interface DeepTagProductTagsListProps {
  tagsFormData: DeepTagForm[];
  category: IDeepTagsProductDataItem['category'];
  parentCategory: IDeepTagsProductDataItem['parentCategory'];
  hasChangedCategory: boolean;
  onEditCategory?: (shouldEditCategory: boolean) => void;
  onRemoveTag: OnRemoveOrRestoreTag;
  lexiconCategoryAttributes?: ILexiconCategory['attributes'];
  onAttributeValueChange: ({
    valueKey,
    attributeKey,
  }: {
    valueKey: string;
    attributeKey: string;
  }) => void;
  tagToRemove: Partial<DeepTagForm> | undefined;
  shouldHideEditIndication: boolean;
  onGeneratedAttributeEdit?: OnGeneratedAttributeEdit;
}

export const DeepTagSubProductTagsList = ({
  tagsFormData,
  hasChangedCategory,
  onAttributeValueChange,
  onEditCategory,
  onRemoveTag,
  onGeneratedAttributeEdit,
  category,
  parentCategory,
  lexiconCategoryAttributes,
  tagToRemove,
  shouldHideEditIndication,
}: DeepTagProductTagsListProps): JSX.Element => {
  return (
    <SubProductTagsListStyled>
      <TagStyled key={`Parent-category-${parentCategory.name}`}>
        <AttributeStyled>
          <AttributeNameStyled>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
              <EllipsisWithTooltip tooltipText='Parent category'>
                Parent category
              </EllipsisWithTooltip>
            </Typography>
          </AttributeNameStyled>
          <ValueStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
            <EllipsisWithTooltip tooltipText={parentCategory.translation || ''}>
              {parentCategory.translation || ''}
            </EllipsisWithTooltip>
          </ValueStyled>
        </AttributeStyled>
      </TagStyled>
      <TagStyled
        key={category.name}
        onClick={onEditCategory ? () => onEditCategory(true) : undefined}
        isClickable
      >
        <AttributeStyled>
          <AttributeNameStyled>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
              <EllipsisWithTooltip tooltipText='Category'>Category</EllipsisWithTooltip>
            </Typography>
          </AttributeNameStyled>
          <ValueStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
            <EllipsisWithTooltip tooltipText={category.translation || ''}>
              {category.translation || ''}
            </EllipsisWithTooltip>
          </ValueStyled>
        </AttributeStyled>
        <RemoveButtonWrapperStyled shouldShow={false}>
          {!!onEditCategory && (
            <IconStyled name={AvailableIcons.EditV2} onClick={() => onEditCategory(true)} />
          )}
        </RemoveButtonWrapperStyled>
        {hasChangedCategory && (
          <DeepTagStatus
            status={DeepTagStatusEnum.Edited}
            shouldHideEditIndication={shouldHideEditIndication}
          />
        )}
      </TagStyled>
      {tagsFormData ? (
        <>
          {/* Tags form list */}
          {tagsFormData.map(
            ({ attribute, value, status, source, hasAddedInForm }: DeepTagForm): JSX.Element => {
              return (
                <DeepTagProductTag
                  key={attribute.key}
                  attribute={attribute}
                  tagSource={source}
                  value={value}
                  status={status}
                  onGeneratedAttributeEdit={onGeneratedAttributeEdit}
                  tagToRemove={tagToRemove}
                  onRemoveTag={onRemoveTag}
                  hasAddedInForm={!!hasAddedInForm}
                  onAttributeValueChange={onAttributeValueChange}
                  lexiconAttributeValues={lexiconCategoryAttributes?.[attribute.key]?.values}
                  shouldHideEditIndication={shouldHideEditIndication}
                />
              );
            }
          )}
        </>
      ) : (
        <SkeletonDefaultContent />
      )}
    </SubProductTagsListStyled>
  );
};
