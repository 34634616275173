import React, { useEffect, useMemo, useState } from 'react';
import { orderBy } from 'lodash';
import { List, ListItemProps, SearchInput } from 'src/components-dummy';
import { UserTypes } from 'src/services';
import { useFilterItems } from 'src/hooks';
import { UserCard } from '../UserCard';
import { UserListEmptyResults } from '../UserListEmptyResults';
import { userListActionMethods } from './Actions';
import { UserListProps } from './types';
import './UserList.scss';

export const UserList = ({
  accountId,
  users,
  selectedUserId,
  onAddUser,
  dispatch,
  loggedInUser,
}: UserListProps): JSX.Element => {
  const [searchInputValue, setSearchInputValue] = useState<string>('');

  const onSearchInputChange = (value: string): void => setSearchInputValue(value);

  const handleSelectUser = (user: UserTypes.User) => {
    dispatch(userListActionMethods.userSelected(user.userId));
  };

  const handleDeleteUser = (user: UserTypes.User) => {
    dispatch(userListActionMethods.deleteUser(user.userId));
  };

  const { filteredItems } = useFilterItems({
    items: users,
    searchableFields: ['name', 'email', 'userId'],
    idField: 'userId',
    filterText: searchInputValue,
  });

  const sortedUsers = useMemo(() => {
    return orderBy(filteredItems, ['online', 'email'], 'desc');
  }, [filteredItems]);

  useEffect(() => {
    if (accountId) {
      dispatch(userListActionMethods.getAllUsers(accountId));
    }
  }, [accountId]);

  return (
    <div className='syte-user-list'>
      <SearchInput
        className='syte-user-list-search'
        placeholder='Search for a user'
        value={searchInputValue}
        onChange={onSearchInputChange}
      />
      <div className='syte-user-list-wrapper'>
        <List
          idField='userId'
          items={sortedUsers}
          selectedId={selectedUserId}
          onItemSelected={user => handleSelectUser(user)}
        >
          <List.CardTemplate>
            {(user: UserTypes.User, { isSelected }: ListItemProps): JSX.Element => (
              <UserCard
                user={user}
                onDelete={handleDeleteUser}
                loggedInUser={loggedInUser}
                isSelected={isSelected}
              />
            )}
          </List.CardTemplate>
          <List.EmptyTemplate>
            <UserListEmptyResults onClick={onAddUser} />
          </List.EmptyTemplate>
        </List>
      </div>
    </div>
  );
};
