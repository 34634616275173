import { useTheme } from '@emotion/react';
import { format, differenceInSeconds } from 'date-fns';
import React, { useMemo, forwardRef } from 'react';
import {
  AvailableIcons,
  EllipsisWithTooltip,
  Icon,
  SelectOnChangeEvent,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import {
  DeepTag,
  DeepTagProductStatusType,
  DeepTagReportsGeneralConfigurationLabel,
  DeepTagScoreType,
  DeepTagStatusEnum,
  IDeepTagsProductDataItem,
} from 'src/services';
import { getScoreTypeColor } from '../../helpers';
import { Bullet } from '../Bullet';
import {
  ProductCardStyled,
  ProductDetailsSectionStyled,
  ProductImageContainerStyled,
  ProductTitleWrapper,
  SKUTextStyled,
  TagItemStyled,
  TagsContainerStyled,
  UpdatedAtTextStyled,
  TagStatusLabelStyled,
  ProductActionsAndDetailsStyled,
  ProductActionsAndDetailsWrapperStyled,
} from './ProductCard.styles';
import { DeepTagProductStatusSelect } from '../DeepTagProductStatusSelect';
import { DeepTagsReportsProductLabelAutoComplete } from '../DeepTagsReportsProductLabelAutoComplete/DeepTagsReportsProductLabelAutoComplete';

export type ProductCardTag = Pick<DeepTag, 'attribute' | 'value' | 'scoreType' | 'status'>;

const TagStatusLabel = ({
  status,
  shouldHideEditIndication,
}: {
  status: DeepTagStatusEnum;
  shouldHideEditIndication: boolean;
}): JSX.Element | null => {
  let statusText;

  if (shouldHideEditIndication) {
    return null;
  }

  if (status === DeepTagStatusEnum.Edited) {
    statusText = 'edited';
  } else if (status === DeepTagStatusEnum.New) {
    statusText = 'Added by user';
  }

  return statusText ? (
    <TagStatusLabelStyled type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
      ({statusText})
    </TagStatusLabelStyled>
  ) : null;
};

export interface ProductCardProps {
  sku: string;
  title: string | undefined;
  imageUrl: string | undefined;
  tags: ProductCardTag[];
  status: DeepTagProductStatusType;
  data: IDeepTagsProductDataItem[];
  generalConfigurationLabels: DeepTagReportsGeneralConfigurationLabel[];
  labels?: string[];
  shopId: number;
  reportId: string;
  productId: string;
  locale: string;
  dispatch?: any;
  selectedScoreTypes: DeepTagScoreType[];
  updatedAt?: Date;
  createdAt?: Date;
  shouldHideEditIndication: boolean;
  onClick?: () => void;
  onProductStatusChange: SelectOnChangeEvent;
  onLabelChangeSuccess: () => void;
}

const MAX_DISPLAYED_TAGS = 9;

// TODO: tags columns fixed size? waiting for itamar
// TODO: days ago
// TODO: scroll bar to be on page, not directly on list

export function getEditedAtText(updatedAt: Date | undefined, createdAt: Date | undefined): string {
  let resultText = 'Edited ';

  if (!updatedAt) {
    return resultText;
  }
  if (createdAt && differenceInSeconds(updatedAt, createdAt) === 0) {
    return '';
  }
  const now = new Date();

  const timeDiffInSeconds = differenceInSeconds(now, updatedAt);
  const timeDiffInMinutes = timeDiffInSeconds / 60;
  const timeDiffInHours = timeDiffInMinutes / 60;
  const timeDiffInDays = timeDiffInHours / 24;

  if (timeDiffInSeconds < 60) {
    resultText += `${timeDiffInSeconds}sec ago`;
  } else if (timeDiffInMinutes < 60) {
    resultText += `${timeDiffInMinutes.toFixed()}min ago`;
  } else if (timeDiffInHours < 24) {
    resultText += `${timeDiffInHours.toFixed()}h ago`;
  } else if (timeDiffInDays < 7) {
    resultText += `${timeDiffInDays.toFixed()}d ago`;
  } else {
    resultText += format(updatedAt, "' 'LLLL d 'at' h:mm a");
  }

  return resultText;
}

export const ProductCard = forwardRef<HTMLDivElement, ProductCardProps>(
  (
    {
      reportId,
      shopId,
      labels,
      locale,
      productId,
      title,
      sku,
      imageUrl,
      status = DeepTagProductStatusType.New,
      tags: productTags,
      selectedScoreTypes,
      createdAt,
      updatedAt,
      onClick,
      onProductStatusChange,
      onLabelChangeSuccess,
      generalConfigurationLabels,
      data,
      dispatch,
      shouldHideEditIndication,
    },
    ref
  ): JSX.Element => {
    const theme = useTheme();

    const allTags = useMemo(() => {
      const allDataTags = data.reduce((prev: DeepTag[], next) => {
        const dataObjTagsNoneDeleted = next.tags.filter(
          tag => tag.status !== DeepTagStatusEnum.Deleted
        );

        return prev.concat(dataObjTagsNoneDeleted || []);
      }, []);

      return productTags.concat(allDataTags);
    }, [productTags, data]);

    const categories = useMemo(() => {
      return data.map(dataObject => dataObject.category);
    }, [data]);

    const totalCategories = categories.length;

    // add category tag to total
    const totalTags = allTags.length + totalCategories;

    const showMoreTagsIndication = totalTags > MAX_DISPLAYED_TAGS;
    const tagsToRenderCount = showMoreTagsIndication ? MAX_DISPLAYED_TAGS - 1 : MAX_DISPLAYED_TAGS;
    const moreTagsCount = totalTags - tagsToRenderCount;

    const tagsToRender = useMemo(() => {
      const relevantTags = allTags.slice(0, tagsToRenderCount - totalCategories);

      const mappedTags = relevantTags.map(tag => {
        const { attribute, value, scoreType, status: tagStatus } = tag;
        const isValueScoreTypeSelected = scoreType && selectedScoreTypes?.includes(scoreType);
        const isEdited = tagStatus !== DeepTagStatusEnum.Original;

        const iconColor = getScoreTypeColor({
          theme,
          scoreType: isValueScoreTypeSelected ? scoreType : undefined,
          isEdited,
        });

        return {
          attribute: attribute.translation,
          value: value.translation,
          key: `${attribute.translation}-${value}`,
          iconColor,
          isEdited,
          status: tagStatus,
        };
      });

      const categoryNameTags = categories
        .filter(x => !!x)
        .map(category => {
          return {
            attribute: 'Category',
            value: category.translation || '',
            key: `category-${category.name || ''}`,
            iconColor: getScoreTypeColor({ theme, scoreType: undefined }),
            isEdited: category.isEdited,
            status: category.isEdited ? DeepTagStatusEnum.Edited : DeepTagStatusEnum.Original,
          };
        });

      return [...categoryNameTags, ...mappedTags];
    }, [tagsToRenderCount, categories, allTags, selectedScoreTypes, theme, totalCategories]);

    const updatedAtText = getEditedAtText(updatedAt, createdAt);

    return (
      <ProductCardStyled onClick={onClick} ref={ref}>
        <ProductImageContainerStyled>
          {imageUrl && <img src={imageUrl} alt={title || 'product image'} />}
        </ProductImageContainerStyled>
        <ProductDetailsSectionStyled>
          <div>
            <ProductTitleWrapper>
              <EllipsisWithTooltip tooltipText={title || ''}>
                <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
                  {title || '(no title)'}
                </Typography>
              </EllipsisWithTooltip>
            </ProductTitleWrapper>
            <ProductActionsAndDetailsWrapperStyled>
              <SKUTextStyled
                type={TypographyType.Body}
                variant={TypographyVariant.ExtraSmallRegular}
              >
                {sku || '(no SKU)'}
              </SKUTextStyled>
              <ProductActionsAndDetailsStyled>
                <DeepTagProductStatusSelect value={status} onChange={onProductStatusChange} />
                <Icon name={AvailableIcons.DotSeparatorBig} />
                <Typography
                  type={TypographyType.Body}
                  variant={TypographyVariant.ExtraSmallRegular}
                >
                  Label:{' '}
                </Typography>
                <DeepTagsReportsProductLabelAutoComplete
                  reportId={reportId}
                  shopId={shopId}
                  productId={productId}
                  locale={locale}
                  productCurrentLabelIds={labels}
                  generalConfigurationLabels={generalConfigurationLabels}
                  onLabelChangeSuccess={onLabelChangeSuccess}
                  dispatch={dispatch}
                />
                <Icon name={AvailableIcons.DotSeparatorBig} />
                <UpdatedAtTextStyled
                  type={TypographyType.Body}
                  variant={TypographyVariant.ExtraSmallRegular}
                >
                  {updatedAtText}
                </UpdatedAtTextStyled>
              </ProductActionsAndDetailsStyled>
            </ProductActionsAndDetailsWrapperStyled>
          </div>
          <TagsContainerStyled>
            {tagsToRender.map(tag => (
              <TagItemStyled key={`${tag.attribute}-${tag.value}`}>
                <Bullet fillColor={tag.iconColor} size={6} />
                <EllipsisWithTooltip
                  tooltipText={`${tag.attribute} is ${tag.value}`}
                  maxWidth={105}
                >
                  <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                    {tag.value}
                  </Typography>
                </EllipsisWithTooltip>
                {tag.isEdited && (
                  <TagStatusLabel
                    status={tag.status}
                    shouldHideEditIndication={shouldHideEditIndication}
                  />
                )}
              </TagItemStyled>
            ))}
            {showMoreTagsIndication && (
              <div>
                <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
                  {`${moreTagsCount} more tags`}
                </Typography>
              </div>
            )}
          </TagsContainerStyled>
        </ProductDetailsSectionStyled>
      </ProductCardStyled>
    );
  }
);
