import {
  UiTemplatesSetShopUrlAction,
  UiTemplatesShopUrlActionPayload,
  UiTemplatesShopUrlActionTypes,
} from './types';
import type { Dispatch } from '../../../types';

const uiTemplatesShopUrlActions = {
  setShopUrl(payload: UiTemplatesShopUrlActionPayload): UiTemplatesSetShopUrlAction {
    return {
      type: UiTemplatesShopUrlActionTypes.SetShopUrl,
      payload,
    };
  },
};

export const uiTemplatesShopUrlActionMethods = {
  setShopUrl(payload: UiTemplatesShopUrlActionPayload) {
    return async (dispatch: Dispatch): Promise<void> => {
      dispatch(uiTemplatesShopUrlActions.setShopUrl(payload));
    };
  },
};
