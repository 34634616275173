import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { AvailableIcons, Icon } from 'src/components-dummy';
import { Notification } from 'src/services';
import { Dispatch } from '../types';
import { NotificationsPopup } from './components';
import { notificationsActions } from './notifications.actions';
import { NotificationsIconWrapperStyled, TooltipNotificationsStyled } from './Notifications.styles';
import { UnreadCount } from './components/UnreadCount/UnreadCount';

interface NotificationsProps extends RoutedComponentProps {
  shopId?: number;
  notifications?: Notification[];
  unreadNotifications?: Notification[];
  unreadCount?: number;
  dispatch: Dispatch;
}

export function Notifications({
  shopId,
  notifications,
  unreadNotifications,
  dispatch,
  unreadCount,
  permittedRouteMap,
}: NotificationsProps): JSX.Element {
  const [popoverAnchorElement, setPopoverAnchorElement] = useState<HTMLDivElement | null>(null);

  const openNotificationsPopup: MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      setPopoverAnchorElement(event.currentTarget);
    },
    [setPopoverAnchorElement]
  );

  const closeNotificationsPopup = useCallback(() => {
    setPopoverAnchorElement(null);
  }, [setPopoverAnchorElement]);

  useEffect(() => {
    dispatch(notificationsActions.getNotifications({ shopId, limit: 20, skip: 0 }));
    dispatch(notificationsActions.getNotifications({ shopId, limit: 20, skip: 0, viewed: false }));
  }, [shopId, dispatch]);

  return (
    <>
      <NotificationsIconWrapperStyled onClick={openNotificationsPopup}>
        <TooltipNotificationsStyled value='Notifications' position='bottom center'>
          <UnreadCount count={unreadCount} />
          <Icon name={AvailableIcons.NotificationsBell} />
        </TooltipNotificationsStyled>
      </NotificationsIconWrapperStyled>
      <NotificationsPopup
        shopId={shopId}
        notifications={notifications}
        unreadNotifications={unreadNotifications}
        dispatch={dispatch}
        popoverAnchorElement={popoverAnchorElement}
        onClose={closeNotificationsPopup}
        unreadCount={unreadCount}
        permittedRouteMap={permittedRouteMap}
      />
    </>
  );
}
