/* eslint no-param-reassign:0 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEditableStoriesSettings } from '../storiesPage.types';
import { IEnhancedStrategy } from '../../../types';

type State = IEditableStoriesSettings;
export const createEditStoriesSettingsReducer = (settings: IEditableStoriesSettings) => {
  return createSlice({
    name: 'stories/editStoriesSettings',
    initialState: settings,
    reducers: {
      setStrategy: (state: State, action: PayloadAction<IEnhancedStrategy>) => {
        state.storiesStrategy = action.payload;
      },
      setNumberOfResultsToDisplay: (state: State, action: PayloadAction<number>) => {
        state.numberOfResultsToDisplay = action.payload;
      },
    },
  });
};
