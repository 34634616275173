import React from 'react';
import {
  NumericInput,
  Button,
  Tooltip,
  Icon,
  AvailableIcons,
  Typography,
  TypographyType,
  TypographyVariant,
  Switch,
  Select,
  SelectType,
  SelectOnChangeEvent,
  MenuItem,
} from 'src/components-dummy';
import './ShopTheLookSettings.scss';
import { getStringEnumValues } from 'src/utils';
import { ShopTheLookSettingsProps } from './types';
import { useShopTheLookSettingsDraft } from './useShopTheLookSettingsDraft';
import { createOptionalPortal } from '../helpers';
import { SyteAdminContent } from '../../containers/SyteAdminContent/SyteAdminContent';
import { ConfigurationNote } from '../../components-dummy/Labels/ConfigurationNote';
import { ConfigurationVariant } from '../../components-dummy/Labels/configuration-variant';
import { AddMultipleToCartForm } from '../AddToCartSettings/AddMultipleToCartForm';
import { useAppSelector } from '../../hooks';

export enum FallbackOptions {
  Personalization = 'Personalization',
  None = 'None',
}

const dropDownOptions = getStringEnumValues(FallbackOptions).map(option => {
  return {
    value: option,
    text: option,
  };
});

export const ShopTheLookSettings = ({
  shopId,
  shopTheLookSettings,
  selectedVariantId,
  formHeaderElementRef,
  onIsDirty,
  dispatch,
  disabled = false,
  shouldRefetch,
}: ShopTheLookSettingsProps): JSX.Element | null => {
  const addToCartEnabled = useAppSelector(({ shop }) => !!shop.featureToggles?.addToCart?.enabled);
  const shopTheLook = useShopTheLookSettingsDraft({
    shopTheLookSettings,
    dispatch,
    shopId,
    selectedVariantId,
    onIsDirty,
    shouldRefetch,
  });

  const { draftShopTheLookSettings, isValid, isDirty, onSubmit, onDiscard, onChange, errors } =
    shopTheLook;

  const formButtons = !disabled && (
    <>
      <Button onClick={onDiscard} variant='tertiary' disabled={!isDirty}>
        Discard
      </Button>
      <Button onClick={onSubmit} disabled={!(isValid && isDirty)} variant='primary'>
        {selectedVariantId ? 'Apply' : 'Save'}
      </Button>
    </>
  );

  const formButtonsSection = createOptionalPortal({
    portalContent: disabled ? <></> : formButtons,
    targetContainerRef: formHeaderElementRef,
    fallback: <div className='ranking-button-wrapper'>{formButtons}</div>,
  });

  const handleChange: SelectOnChangeEvent = event =>
    onChange({ completeTheLookFallbackMethod: event.target.value });

  return (
    <>
      <div className='shop-the-look-settings-container-wrapper'>
        <div className='shop-the-look-general-settings'>
          <div className='shop-the-look-general-settings-container'>
            <Typography
              type={TypographyType.Body}
              variant={TypographyVariant.MediumRegular}
              className='results-display-title'
            >
              Results Display
            </Typography>
            <div className='shop-the-look-results-to-display'>
              <Typography
                type={TypographyType.Body}
                variant={TypographyVariant.MediumRegular}
                className='results-to-display-title'
              >
                Number of results to display (3-50)
              </Typography>
              <div className='results-to-display-input-wrapper'>
                <NumericInput
                  disabled={disabled}
                  error={!!errors.numberOfResultsToDisplay}
                  value={draftShopTheLookSettings.numberOfResultsToDisplay}
                  onChange={value => {
                    onChange({ numberOfResultsToDisplay: value });
                  }}
                  className='results-to-display-input'
                />
                {!!errors.numberOfResultsToDisplay && (
                  <span className='results-to-display-input-error'>
                    <Typography
                      type={TypographyType.Body}
                      variant={TypographyVariant.MediumRegular}
                    >
                      Please enter a number between 3 to 50
                    </Typography>
                  </span>
                )}
              </div>
            </div>
            <SyteAdminContent>
              <div className='shop-the-look-fallback-method'>
                <span className='fallback-method-title'>
                  <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                    Fallback method (if only one or none items are detected)
                  </Typography>
                  <div className='fallback-method-title-tooltip'>
                    <Tooltip value='Shop the look shows results for multiple items found in the image. If one or none items are found, replace the results by choosing an alternative strategy.'>
                      <Icon name={AvailableIcons.TooltipQuestionmark} />
                    </Tooltip>
                  </div>
                </span>
                <Select
                  type={SelectType.Menu}
                  disabled={disabled}
                  value={
                    dropDownOptions.filter(
                      fallBackMethod =>
                        fallBackMethod.text ===
                        draftShopTheLookSettings.completeTheLookFallbackMethod
                    )[0].value
                  }
                  className='fallback-method-dropdown'
                  onChange={handleChange}
                >
                  {dropDownOptions.map((option: { value: string; text: string }) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </SyteAdminContent>
          </div>
        </div>

        <div className='shop-the-look-results-display-settings'>
          <div className='shop-the-look-results-display-settings-container'>
            <Typography
              type={TypographyType.Body}
              variant={TypographyVariant.MediumRegular}
              className='results-sorting-title'
            >
              Results Sorting
            </Typography>
            <div className='shop-the-look-include-main-product'>
              <Switch
                disabled={disabled}
                checked={draftShopTheLookSettings.includeMainProductInCarousel}
                onChange={() => {
                  onChange({
                    includeMainProductInCarousel:
                      !draftShopTheLookSettings.includeMainProductInCarousel,
                  });
                }}
                className='include-main-product-toggle'
              >
                <Switch.TitleTemplate className='include-main-product-text'>
                  <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                    Show results for the main product category
                  </Typography>
                </Switch.TitleTemplate>
              </Switch>

              <div className='include-main-product-tooltip'>
                <Tooltip value='If Jeans is main product and shirt is also part of the look - so jeans will be presented as well'>
                  <Icon name={AvailableIcons.TooltipQuestionmark} />
                </Tooltip>
              </div>
            </div>
            <div className='shop-the-look-shuffle-results'>
              <Switch
                disabled={disabled}
                checked={draftShopTheLookSettings.shuffleResults}
                onChange={() => {
                  onChange({
                    shuffleResults: !draftShopTheLookSettings.shuffleResults,
                  });
                }}
                className='shuffle-results-toggle'
              >
                <Switch.TitleTemplate className='shuffle-results-text'>
                  <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
                    Sort results by Look ‘set’
                  </Typography>
                </Switch.TitleTemplate>
              </Switch>
            </div>
          </div>
        </div>
      </div>
      <ConfigurationNote variant={ConfigurationVariant.allAboveToNonApiUsers} />
      <AddMultipleToCartForm
        showSettingsForm={addToCartEnabled}
        addToCart={draftShopTheLookSettings.addToCart}
        onChange={addToCart => onChange({ addToCart })}
        shopId={shopId}
      />
      {formButtonsSection}
    </>
  );
};
