import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';
import { Card } from 'src/components-dummy/Card';

export const CatalogFormSectionStyled = styled(Card)`
  padding: 32px 32px 32px 32px;
  margin-bottom: 24px;
  min-width: 250px;
  max-width: 1200px;
`;

export const CatalogFormSectionHeaderStyled = styled(Typography)`
  margin-bottom: 8px;
  display: block;
`;

export const CatalogFormSectionHeaderSubtitleStyled = styled(Typography)`
  margin-bottom: 16px;
  display: block;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;
