import styled from '@emotion/styled';

export const StyledProductTagTitle = styled.div`
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledProductTagBody = styled.div`
  display: flex;
  cursor: grab;
  flex-direction: column;
  padding: 12px 16px;
  gap: 4px;
  &:active {
    cursor: grabbing;
  }
`;

export const StyledProductTagPrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const StyledProductSKUContainer = styled.div`
  min-width: 80px;
  display: none;
  height: 24px;
  border-top: 1px solid;
  border-color: #00000040;

  .syte-popup-trigger {
    overflow: hidden;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const StyledProductSKU = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  padding: 4px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 4px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledProductOOS = styled.div`
  display: flex;
  justify-content: center;
  background-color: #dc3545;
  padding: 1px 4px;
`;

export const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
`;

export const StyledProductTag = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: fit-content;
  color: white;
  user-select: none;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(55px);
  overflow: hidden;
  max-width: 200px;
  cursor: default;
`;
