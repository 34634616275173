import React from 'react';

interface PaginationStatusTextProps {
  skip: number;
  limit: number;
  totalItems: number;
}

export const PaginationStatusText = ({
  skip,
  limit,
  totalItems,
}: PaginationStatusTextProps): JSX.Element | null => {
  if (!totalItems) return null;

  const start = skip + 1;

  const leftItems = totalItems - skip;

  const end = leftItems > 0 && leftItems > limit ? skip + limit : totalItems;

  return <>{`${start}-${end} of ${totalItems}`}</>;
};
