/* eslint-disable no-continue */
import React, { useCallback, useEffect, useState } from 'react';
import { Dispatch } from 'src/components-bl';
import { MerchandisingRuleTypes, ShopDataField } from 'src/services';
import { usePreviousState } from 'src/hooks';
import {
  Page,
  Button,
  getAppliedFiltersWithValue,
  PaginationWithItemsPerPage,
  AppliedFilters,
} from 'src/components-dummy';
import { RouteSettings } from 'src/app-routes';
import { isEmpty, isEqual } from 'lodash';
import { merchandisingRulesActions } from '../../merchandisingRules.actions';
import {
  MerchandisingRulesFilters,
  MerchandisingRulesFiltersState,
  MerchandisingRulesList,
} from './components';
import {
  PageStyled,
  PaginationContainer,
  StyledSkeleton,
} from './MerchandisingRulesListPage.styles';
import { UseDataFieldsLookupTableArgumentsReturnType } from '../useDataFieldsLookupTable';
import { merchandisingRulesListPageActions } from './MerchandisingRulesListPage.actions';
import { useValidateMerchandisingRuleLimit } from '../MaxMerchandisingRulesLimit/use-validate-merchandising-rule-limit';
import { MaxMerchandisingRulesLimitDialog } from '../MaxMerchandisingRulesLimit';
import { useUpdateMerchandisingRulesList } from './useUpdateMerchandisingRulesList';

function LoadingSkeleton() {
  return (
    <>
      <StyledSkeleton height={40} variant='rounded' />
      <StyledSkeleton height={20} variant='rounded' />
      <StyledSkeleton height={20} variant='rounded' />
    </>
  );
}

export interface MerchandisingRulesListPageProps
  extends Pick<UseDataFieldsLookupTableArgumentsReturnType, 'dataFieldsLookupTable'> {
  onCreateRule: () => void;
  shopId: number;
  rules?: (MerchandisingRuleTypes.MerchandiseRule & MerchandisingRuleTypes.ExtraIndications)[];
  overview?: MerchandisingRuleTypes.Overview;
  totalRulesCount?: number;
  //   for experiment support
  selectedVariantId?: string;
  dispatch: Dispatch;
  entityId?: string;
  dataFields?: ShopDataField[];
  merchandisingRulesEditPageRoute?: RouteSettings;
  onRuleSelection?: (ruleId: string) => void;
  isReadOnly?: boolean;
  availableRegions?: string[];
  filtersState: MerchandisingRulesFiltersState;
  setFiltersState: (value: MerchandisingRulesFiltersState) => void;
}

function calculateAppliedFilters(filtersState: MerchandisingRulesFiltersState): {
  filters: AppliedFilters;
  isEmpty: boolean;
} {
  const filters = getAppliedFiltersWithValue(filtersState);

  return {
    filters,
    isEmpty: isEmpty(filters),
  };
}

export const MerchandisingRulesListPage = ({
  onCreateRule,
  shopId,
  rules,
  overview,
  selectedVariantId,
  dispatch,
  entityId,
  dataFields,
  onRuleSelection,
  merchandisingRulesEditPageRoute,
  isReadOnly,
  availableRegions,
  filtersState,
  setFiltersState,
  totalRulesCount,
}: MerchandisingRulesListPageProps): JSX.Element => {
  const [paginationState, setPaginationState] = useState<{ skip: number; limit: number }>({
    skip: 0,
    limit: 50,
  });

  const [appliedFiltersState, setAppliedFiltersState] = useState(
    calculateAppliedFilters(filtersState)
  );

  const onPaginationChange = useCallback(({ skip, limit }: { skip: number; limit: number }) => {
    setPaginationState({ skip, limit });
  }, []);

  const previousShopId = usePreviousState(shopId);
  const previousSelectedVariantId = usePreviousState(selectedVariantId);
  const previousEntityId = usePreviousState(entityId);

  const resetSkip = useCallback(() => {
    setPaginationState({ skip: 0, limit: paginationState.limit });
  }, [setPaginationState, paginationState.limit]);

  const {
    showRuleLimitReachedDialog,
    onRuleLimitDialogClose,
    onCreateRuleButtonClick,
    validateMerchandisingRuleLimit,
  } = useValidateMerchandisingRuleLimit({
    shopId,
    selectedVariantId,
    createMerchandisingRule: onCreateRule,
    dispatch,
  });

  const fetchRules = useCallback(() => {
    dispatch(
      merchandisingRulesListPageActions.getRules({
        shopId,
        variantId: selectedVariantId,
        entityId,
        ...paginationState,
        ...appliedFiltersState.filters,
      })
    );
    validateMerchandisingRuleLimit();
  }, [
    dispatch,
    shopId,
    selectedVariantId,
    entityId,
    paginationState,
    appliedFiltersState,
    validateMerchandisingRuleLimit,
  ]);

  useUpdateMerchandisingRulesList({
    dispatch,
    shopId,
    variantId: selectedVariantId,
    entityId,
    refreshList: fetchRules,
  });

  useEffect(() => {
    if (
      shopId !== previousShopId ||
      selectedVariantId !== previousSelectedVariantId ||
      entityId !== previousEntityId
    ) {
      fetchRules();
    }
  }, [shopId, selectedVariantId, entityId]);

  useEffect(() => {
    fetchRules();
  }, [fetchRules]);

  useEffect(() => {
    return () => {
      dispatch(merchandisingRulesListPageActions.resetRules());
    };
  }, []);

  useEffect(() => {
    if (shopId) {
      dispatch(
        merchandisingRulesActions.getShopLocales({
          shopId,
        })
      );
    }
  }, [shopId]);

  useEffect(() => {
    const newState = calculateAppliedFilters(filtersState);

    const filtersChanged = !isEqual(newState, appliedFiltersState);

    if (filtersChanged) {
      setAppliedFiltersState(newState);

      resetSkip();
    }
  }, [filtersState]);

  const isRulesListReady = availableRegions && rules;

  return (
    <PageStyled>
      <Page.Header>
        <Page.Title>Rules Management</Page.Title>
        {!isReadOnly && (
          <Button variant='primary' onClick={onCreateRuleButtonClick}>
            New rule
          </Button>
        )}
      </Page.Header>
      <Page.Content>
        <MerchandisingRulesFilters
          onChange={setFiltersState}
          filters={filtersState}
          dispatch={dispatch}
          shopId={shopId}
          overview={overview}
          selectedVariantId={selectedVariantId}
          entityId={entityId}
        />
        {isRulesListReady ? (
          <>
            <MerchandisingRulesList
              shopId={shopId}
              rules={rules}
              selectedVariantId={selectedVariantId}
              dispatch={dispatch}
              dataFields={dataFields}
              onRuleSelection={onRuleSelection}
              onCreateRuleButtonClick={onCreateRuleButtonClick}
              merchandisingRulesEditPageRoute={merchandisingRulesEditPageRoute}
              isReadOnly={isReadOnly}
              areFiltersApplied={!appliedFiltersState.isEmpty}
            />
            <PaginationContainer>
              <PaginationWithItemsPerPage
                skip={paginationState.skip}
                limit={paginationState.limit}
                totalItemsCount={totalRulesCount ?? 0}
                pagesToShow={5}
                onPaginationChange={onPaginationChange}
              />
            </PaginationContainer>
          </>
        ) : (
          <LoadingSkeleton />
        )}
        {showRuleLimitReachedDialog && (
          <MaxMerchandisingRulesLimitDialog onClose={onRuleLimitDialogClose} />
        )}
      </Page.Content>
    </PageStyled>
  );
};
