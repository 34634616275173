import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { filtersService } from 'src/services';

export const filtersFormActions = {
  notification: createAction<{ customMessage: string }>('FiltersForm/Notification'),
  notifyIsDirty: createAction<{ isDirty: boolean }>('FiltersForm/NotifyIsDirty'),
  resetSelectedFilter: createAction('FiltersForm/ResetSelectedFilter'),
  createFilter: createAsyncThunk(
    'FiltersForm/CreateFilter',
    async (
      { shopId, syteLayout, filterPayload }: Parameters<typeof filtersService.createFilter>[0],
      { rejectWithValue }
    ) => {
      try {
        const createdFilter = await filtersService.createFilter({
          shopId,
          syteLayout,
          filterPayload,
        });
        return createdFilter;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateFilter: createAsyncThunk(
    'FiltersForm/UpdateFilter',
    async (
      {
        shopId,
        syteLayout,
        filterPayload,
        filterId,
      }: Parameters<typeof filtersService.updateFilter>[0],
      { rejectWithValue }
    ) => {
      try {
        const updatedFilter = await filtersService.updateFilter({
          shopId,
          syteLayout,
          filterPayload,
          filterId,
        });
        return updatedFilter;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
