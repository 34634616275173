import Joi, { AnySchema } from 'joi';

export interface CategoryFreeTextDraft {
  categoryName?: string;
}

export const DeepTagsChangeCategoryFormFreeTextSchema = ({
  formState,
}: {
  formState?: CategoryFreeTextDraft;
}): Record<keyof Partial<CategoryFreeTextDraft>, AnySchema> => {
  const validationSchema = {};

  if (formState?.categoryName) {
    Object.assign(validationSchema, {
      categoryName: Joi.string().label('category').required().trim().min(1).max(50).messages({
        'string.empty': 'Category name can not be empty',
        'string.min': 'Category name should be at least 1 characters long',
        'string.max': 'Category name should be maximum 50 characters long',
      }),
    });
  }

  const result = validationSchema as unknown as Record<
    keyof Partial<CategoryFreeTextDraft>,
    AnySchema
  >;

  return result;
};
