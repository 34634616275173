import React from 'react';
import { EllipsisMenuButton } from 'src/components-dummy/EllipsisMenuButton';
import { AvailableIcons } from 'src/components-dummy/Icon/AvailableIcons';
import { Menu, MenuItem } from 'src/components-dummy';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy/Typography';
import {
  MenuItemIconStyled,
  MenuActionsStyled,
  MenuItemDeleteStyled,
} from '../MerchandisingRulesList.styles';

/**
 * Configuration
 */
const menuConfiguration = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: -4,
    horizontal: 'right',
  },
};

/**
 * Main
 */
interface RuleItemRowActionsMenuProps {
  isMenuOpened: boolean;
  menuAnchorElement: HTMLElement | undefined;
  onTriggerClick: (event: React.MouseEvent<HTMLElement>) => void;
  onMenuClose: (event: React.MouseEvent<HTMLElement>) => void;
  onEditClick: (event: React.MouseEvent<HTMLElement>) => void;
  onDuplicateClick?: (event: React.MouseEvent<HTMLElement>) => Promise<void>;
  onDeleteClick: (event: React.MouseEvent<HTMLElement>) => void;
  isReadOnly?: boolean;
}

export const RuleItemRowActionsMenu = ({
  isMenuOpened,
  menuAnchorElement,
  onTriggerClick,
  onMenuClose,
  onEditClick,
  onDuplicateClick,
  onDeleteClick,
  isReadOnly,
}: RuleItemRowActionsMenuProps): JSX.Element => {
  const menuItems = [
    <MenuItem key='Edit' value='edit' onClick={onEditClick}>
      {isReadOnly ? (
        <MenuItemIconStyled name={AvailableIcons.Eye} />
      ) : (
        <MenuItemIconStyled name={AvailableIcons.Edit} />
      )}
      <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
        {isReadOnly ? 'View' : 'Edit'}
      </Typography>
    </MenuItem>,
  ];

  if (onDuplicateClick && !isReadOnly) {
    menuItems.push(
      <MenuItem key='duplicate' value='duplicate' onClick={onDuplicateClick}>
        <MenuItemIconStyled name={AvailableIcons.Duplicate} />
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
          Duplicate
        </Typography>
      </MenuItem>
    );
  }

  if (onDeleteClick && !isReadOnly) {
    menuItems.push(
      <MenuItemDeleteStyled key='delete' value='delete' onClick={onDeleteClick}>
        <MenuItemIconStyled name={AvailableIcons.TrashCan} />
        <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
          Delete
        </Typography>
      </MenuItemDeleteStyled>
    );
  }

  return (
    <MenuActionsStyled>
      <EllipsisMenuButton onClick={onTriggerClick} isActive={!!menuAnchorElement} />
      <Menu
        open={isMenuOpened}
        anchorEl={menuAnchorElement}
        onClose={onMenuClose}
        anchorOrigin={menuConfiguration.anchorOrigin as any}
        transformOrigin={menuConfiguration.transformOrigin as any}
      >
        {menuItems}
      </Menu>
    </MenuActionsStyled>
  );
};
