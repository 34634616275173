import React from 'react';
import { circularProgressClasses } from '@mui/material/CircularProgress';
import {
  CircularProgressBase,
  CircularProgressBaseProps,
  CircularProgressSizes,
  CircularProgressValueBySize,
} from './CircularProgressBase';
import { Box } from '../Box/Box';
import { TypographyType, TypographyVariant } from '../Typography';
import { LoadingTextStyled } from './CircularProgress.styles';

/**
 * Enums
 */
const CircularProgressTextMarginBySize = {
  [CircularProgressSizes.ExtraSmall]: 5,
  [CircularProgressSizes.Small]: 7,
  [CircularProgressSizes.Medium]: 10,
  [CircularProgressSizes.Large]: 6,
  [CircularProgressSizes.ExtraLarge]: 12,
};

/**
 * Main
 * TODO: Currently loader text is in same row as the icon,. if needed below, can add different mode. text under teh icon is more for full page loader, and in same row when it's within a component
 */
export interface CircularProgressProps extends CircularProgressBaseProps {
  size?: CircularProgressSizes;
  text?: string;
  children?: React.ReactElement;
  layout?: 'horizontal' | 'vertical';
}

export const CircularProgress = React.memo(
  ({
    color: _color = 'primary',
    size = CircularProgressSizes.Large,
    thickness = 4,
    text,
    layout = 'vertical',
    ...rest
  }: CircularProgressProps): JSX.Element => {
    return (
      <Box
        sx={{
          position: 'relative',
          display: 'inline-flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgressBase
            variant='determinate'
            sx={{
              color: theme => theme.palette.custom['primary-main'],
            }}
            size={CircularProgressValueBySize[size]}
            thickness={thickness}
            {...rest}
            value={100}
          />
          <CircularProgressBase
            variant='indeterminate'
            disableShrink
            sx={{
              color: theme => theme.palette.custom.tertiary,
              animationDuration: '700ms',
              position: 'absolute',
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            }}
            size={CircularProgressValueBySize[size]}
            thickness={thickness}
            {...rest}
          />
        </Box>
        {rest.children ||
          (text ? (
            <Box
              sx={
                layout === 'vertical'
                  ? { marginTop: `${CircularProgressTextMarginBySize[size]}px` }
                  : { marginLeft: '1em' }
              }
            >
              <LoadingTextStyled
                type={TypographyType.Body}
                variant={TypographyVariant.MediumRegular}
              >
                {text}
              </LoadingTextStyled>
            </Box>
          ) : null)}
      </Box>
    );
  }
);
