/* eslint-disable no-param-reassign */
import React from 'react';
import classNames from 'classnames';
import { SliderProps as MuiSliderProp } from '@mui/material/Slider';
import {
  SliderStyled,
  SliderInputStyled,
  SliderThumbStyled,
  MarkLabelStyled,
  TypographyStyled,
} from './Slider.styles';
import { TypographyType, TypographyVariant } from '../Typography';

export interface SliderProps extends MuiSliderProp {
  className?: string;
}

const DefaultThumbComponent = ({ children, ...props }: React.HTMLAttributes<unknown>) => {
  return <SliderThumbStyled {...props}>{children}</SliderThumbStyled>;
};

const MarkLabel = ({ children, ...props }: React.HTMLAttributes<unknown>) => {
  return (
    <MarkLabelStyled {...props}>
      <TypographyStyled
        variant={TypographyVariant.SmallRegular}
        type={TypographyType.Body}
        disabled={!(props as any).markLabelActive}
      >
        {children}
      </TypographyStyled>
    </MarkLabelStyled>
  );
};

export const Slider = ({ className, ...rest }: SliderProps): JSX.Element => {
  return (
    <SliderStyled>
      <SliderInputStyled
        className={classNames('syte-slider', className)}
        slots={{ thumb: DefaultThumbComponent, markLabel: MarkLabel }}
        {...rest}
      />
    </SliderStyled>
  );
};
