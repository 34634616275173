import styled from '@emotion/styled';
import { Icon, TableV2 } from 'src/components-dummy';
import { tableColumns } from './tableColumns';

export const TableBodyRowCellStyled = styled(TableV2.BodyRowCell)<{
  cellName: string;
  shouldBeGrayedOut: boolean;
}>`
  display: flex;
  color: ${({ cellName, theme, shouldBeGrayedOut }) =>
    // eslint-disable-next-line no-nested-ternary
    cellName === tableColumns.sourceName.accessor
      ? theme.palette.custom['primary-main']
      : shouldBeGrayedOut
        ? theme.palette.custom['gray-20']
        : theme.palette.common.black};
  &:hover {
    ${({ cellName }) =>
      cellName === tableColumns.sourceName.accessor &&
      `
      text-decoration: underline;
      cursor: pointer;
      `}
  }
`;

export const TableWrapperStyled = styled.div`
  min-width: 560px;
`;

export const TableHeaderStyled = styled(TableV2.Head)`
  background-color: ${({ theme }) => theme.palette.custom['gray-90']};
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  & > div {
    padding: 10px 12px;
  }
`;

export const FilterActiveCellStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const DragHandleWrapperStyled = styled.div`
  display: flex;
  justify-content: left;
  align-content: center;
  align-items: center;
  flex-direction: row;

  & > div {
    position: relative;
  }
`;

export const DragHandleStyled = styled(Icon)<{ disabled: boolean }>`
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'grab')};
  }
  &:active {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'grabbing')};
  }
`;

export const IconsWrapperStyled = styled.div`
  display: flex;

  svg {
    width: 16px;
    height: 16px;
    align-self: center;
  }
  .syte-popup-trigger {
    margin-left: 4px;
  }
`;
