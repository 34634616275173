import { useEffect, useMemo, useReducer } from 'react';
import { AssignedProduct, Coordinates, Product } from '../../types/entities.types';
import { createReducer } from './reducer';
import { Size, StateType } from './types';

export const useProductTagsState = (
  assignedProducts: AssignedProduct[],
  onChange: (assignedProducts: AssignedProduct[]) => void
) => {
  const { reducer, getInitialState, actions } = useMemo(
    () => createReducer(assignedProducts),
    [assignedProducts]
  );

  const [state, dispatch] = useReducer(reducer, getInitialState());

  useEffect(() => {
    onChange(state.assignedProducts);
  }, [state.assignedProducts]);

  const setPostSize = (size: Size) => {
    dispatch(actions.setPostSize(size));
  };

  const dragStart = () => {
    if (state.type !== StateType.idle) return;
    dispatch(actions.dragStart());
  };

  const setCoordinates = (productIndex: number, coordinates: Coordinates) => {
    dispatch(actions.setCoordinates({ productIndex, coordinates }));
  };

  const fixCoordinates = (productIndex: number, tagSize: Size) => {
    if (!state.postSize) return;

    dispatch(actions.fixTagCoordinates({ productIndex, tagSize }));
  };

  const removeProduct = (productIndex: number) => {
    if (state.type !== StateType.idle || !state.assignedProducts.length) return;
    dispatch(actions.removeProduct(productIndex));
    onChange(state.assignedProducts);
  };

  const addProduct = (product: Product) => {
    if (state.type !== StateType.inputActive) return;

    dispatch(actions.addProduct(product));
    dispatch(actions.closeInput());
  };

  const openInput = (pixelPosition: Coordinates) => {
    if (state.type !== StateType.idle) return;
    dispatch(actions.openInput(pixelPosition));
  };

  const closeInput = () => {
    if (state.type !== StateType.inputActive) return;
    dispatch(actions.closeInput());
  };

  return {
    state,
    openInput,
    closeInput,
    removeProduct,
    addProduct,
    dragStart,
    setCoordinates,
    fixCoordinates,
    setPostSize,
  };
};
