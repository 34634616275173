import type { Dispatch } from '../../types';
import { Collection } from '../types';
import { SortOption } from '../../../utils';

export type UseCollectionsListArguments = {
  shopId: number;
  dispatch: Dispatch;
  variantId?: string;
};

export interface CollectionsTableBodyRow {
  id: string;
  name: Collection['name'];
  lastUpdated: string;
}

export interface CollectionsTableHeadRow {
  header: string;
  accessor: string;
  width: string;
  minWidth?: number;
  maxWidth?: number;
}

export enum SortBy {
  Name = 'name',
  LastUpdated = 'lastUpdated',
}

export interface TableSortState {
  columnName: SortBy;
  sortAscending: boolean;
}

export type CollectionsSortingOptionValue = {
  property: keyof Collection;
  option: SortOption;
};
