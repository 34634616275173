import React from 'react';
import { useParams } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { EditInspirationalImagesGalleryPage } from 'src/components-bl/Galleries';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { GalleryType, InspirationalImagesGallery } from 'src/services';

interface EditInspirationalImagesGalleryContainerProps extends RoutedComponentProps {
  shopId: number;
}

export const EditInspirationalImagesGalleryContainer = ({
  shopId,
  permittedRouteMap,
}: EditInspirationalImagesGalleryContainerProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { galleryId } = useParams<{ galleryId: string }>();

  const gallery = useAppSelector(state =>
    state.galleries.currentGallery?.galleryType === GalleryType.InspirationalImage
      ? (state.galleries.currentGallery as InspirationalImagesGallery)
      : undefined
  );

  return (
    <EditInspirationalImagesGalleryPage
      dispatch={dispatch}
      gallery={gallery}
      galleryId={galleryId}
      permittedRouteMap={permittedRouteMap}
      shopId={shopId}
    />
  );
};
