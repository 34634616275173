import React from 'react';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';

interface ToggleDeleteTagConfirmationDialogProps {
  onClose: () => void;
  onConfirmedAction: (event: React.MouseEvent<HTMLElement>) => void;
  tagType?: LexiconTagType;
}

export const ToggleDeleteTagConfirmationDialog = ({
  onClose,
  onConfirmedAction,
  tagType,
}: ToggleDeleteTagConfirmationDialogProps): JSX.Element => {
  let title;
  if (tagType === LexiconTagType.ThematicTags) {
    title = 'Delete thematic tag';
  } else if (tagType === LexiconTagType.RenameRules) {
    title = 'Delete lexicon rule tag';
  } else {
    title = 'Delete tag';
  }

  return (
    <ConfirmationDialog onCancel={onClose}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>{title}</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        Are you sure you want to delete this tag?
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='primary' onClick={onConfirmedAction}>
            Delete
          </Button>
          <Button variant='outlined' onClick={onClose}>
            Cancel
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
