import { DeepTagsSettings, ValidationErrors, FieldToValidate } from './types';

export const fieldsToValidate = Object.values(FieldToValidate) as string[];

export const getInitialValidationsState = (
  initialValues: DeepTagsSettings | undefined
): ValidationErrors => {
  const initialErrorsState: ValidationErrors = Object.keys(initialValues || {}).reduce(
    (acc, field) => {
      if (fieldsToValidate.includes(field)) {
        return { ...acc, [field as FieldToValidate]: '' };
      }
      return acc;
    },
    {}
  ) as ValidationErrors;
  return initialErrorsState;
};

interface ValidationFunc {
  (val: number | string | undefined): boolean;
}
interface Validator {
  testIsValid: ValidationFunc;
  errorMsg: string;
}

const requiredValidator: Validator = {
  testIsValid: inputVal => inputVal !== null && inputVal !== undefined,
  errorMsg: 'Required field',
};

const validatorsDict: Record<FieldToValidate, Validator[]> = {
  minBuckets: [
    requiredValidator,
    {
      testIsValid: inputVal => Number(inputVal) > 0,
      errorMsg: 'Minimal results to display filter should be greater than 0',
    },
  ],
};

export function validations(key: FieldToValidate, val: string | number | undefined): string[] {
  const validators: Validator[] = validatorsDict[key];
  const errors: string[] = [];
  validators.forEach(validator => {
    if (!validator.testIsValid(val)) {
      errors.push(validator.errorMsg);
    }
  });
  return errors;
}
