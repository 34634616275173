import React, { ReactNode } from 'react';
import { TypographyType, TypographyVariant } from 'src/components-dummy/Typography';
import { BreadcrumbCurrentStyled } from '../Breadcrumbs.styles';

export interface BreadcrumbCurrentProps {
  children: JSX.Element | JSX.Element[] | ReactNode | ReactNode[];
}

export const BreadcrumbCurrent = ({ children }: BreadcrumbCurrentProps): JSX.Element => {
  return (
    <BreadcrumbCurrentStyled
      variant={TypographyVariant.ExtraSmallBold}
      type={TypographyType.Heading}
    >
      {children}
    </BreadcrumbCurrentStyled>
  );
};
