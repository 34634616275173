import { Dispatch } from '../../../../types';

export enum VariantsTableActionTypes {
  SelectVariant = 'VariantsTable/SelectVariant',
}

export interface VariantsTableSelectVariantActionPayload {
  variantId: string | undefined;
}

export interface VariantsTableSelectVariantAction {
  type: typeof VariantsTableActionTypes.SelectVariant;
  payload: VariantsTableSelectVariantActionPayload;
}

export type VariantsTableAction = VariantsTableSelectVariantAction;

const variantsTableActions = {
  selectVariant(
    payload: VariantsTableSelectVariantActionPayload
  ): VariantsTableSelectVariantAction {
    return {
      type: VariantsTableActionTypes.SelectVariant,
      payload,
    };
  },
};

export const variantsTableActionMethods = {
  selectVariant(payload: VariantsTableSelectVariantActionPayload) {
    return (dispatch: Dispatch): void => {
      dispatch(variantsTableActions.selectVariant(payload));
    };
  },
};
