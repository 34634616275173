import React, { useMemo, useCallback } from 'react';
import { MenuItem } from '../../MenuItem';
import { Select, SelectType, SelectOnChangeEvent } from '../../Select';
import { RedOnlyDisabled } from './EditableDropDownCell.style';
import { EditableDropDownCellProps } from '../types';
import { useEditableCell } from '../hooks';

type DropDownOption = { value: string; text: string; isDisabled: boolean };

const COMPONENT_NAME = 'EditableDropDownCell';

export const EditableDropDownCell = <T extends Record<string, unknown>>({
  value,
  row: { index },
  column,
  updateData,
  placeholder,
  ...rest
}: EditableDropDownCellProps<T>): JSX.Element => {
  const {
    id,
    cellProps: { options, disabled, rowRef, prefixId },
  }: any = column;

  const dataId = prefixId ? `${prefixId}_${COMPONENT_NAME}_index_${index}` : '';

  const { currentValue, onChange } = useEditableCell({
    rowIndex: index,
    columnId: id as string,
    value,
    updateData,
  });

  const scrollDown = () => {
    rowRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  const isDisabled = disabled || rest.disabled;
  const dropdownOptions = options || rest.options;

  const handleOnChange: SelectOnChangeEvent = event => {
    onChange(event.target.value);
  };

  // Check whether value exists in drop down options
  const isCurrentValueInOptions = useMemo(
    () => dropdownOptions.find((item: DropDownOption) => item.value === currentValue),
    [dropdownOptions, currentValue]
  );

  const getCurrentValueText = useCallback(
    () => dropdownOptions.find((item: DropDownOption) => item.value === value)?.text,
    [dropdownOptions, value]
  );

  return (
    <>
      {isDisabled ? (
        <RedOnlyDisabled>{getCurrentValueText() || placeholder}</RedOnlyDisabled>
      ) : (
        <Select
          type={SelectType.Primary}
          value={isCurrentValueInOptions ? (currentValue as string) : undefined}
          disabled={isDisabled}
          placeholder={placeholder}
          onChange={handleOnChange}
          isError={!!rest.error}
          errorMessage={typeof rest.error !== 'boolean' ? rest.error : undefined}
          onOpen={scrollDown}
          className='syte-table-editable-cell'
          prefixId={dataId}
        >
          {dropdownOptions.map((option: { value: string; text: string; isDisabled: boolean }) => (
            <MenuItem key={option.value} value={option.value} disabled={option.isDisabled}>
              {option.text}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};
