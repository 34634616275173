import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { DeepTagProductStatusType, DeepTagReportsService } from 'src/services';

const deepTagReportsService = new DeepTagReportsService();

export const DeepTagReportProductListActions = {
  navigateTo: createAction<{ navigateTo: string }>('DeepTagsReportProductList/navigateTo'),
  updateProduct: createAsyncThunk(
    'DeepTagsProductContainer/UpdateProduct',
    async (
      {
        shopId,
        locale,
        reportId,
        productId,
        status,
      }: {
        shopId: number;
        reportId: string;
        productId: string;
        locale: string;
        status?: DeepTagProductStatusType;
      },
      { rejectWithValue }
    ) => {
      try {
        const productToUpdateRequest = {
          shopId,
          locale,
          reportId,
          productId,
          status,
        };

        const product = await deepTagReportsService.updateReportProduct(productToUpdateRequest);

        return { product };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
