import { DataFieldToDisplay } from '../../../../types/data-field-to-display';

export const buildDefaultItem = (dataFieldsToDisplay: DataFieldToDisplay[]): DataFieldToDisplay => {
  return {
    id: Date.now().toString(),
    value: '',
    text: '',
    position: dataFieldsToDisplay.length,
  };
};
