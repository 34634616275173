import React from 'react';
import { SearchInputStyled } from '../AdminSearchModal.styles';

export interface AdminSearchInputProps {
  searchTerm: string;
  onChange: (value: string) => void;
  onReset: VoidFunction;
  inputRef: React.MutableRefObject<HTMLInputElement | null> | undefined;
}

export const AdminSearchInput = ({
  searchTerm,
  onChange,
  onReset,
  inputRef,
}: AdminSearchInputProps): JSX.Element => {
  return (
    <SearchInputStyled
      value={searchTerm}
      onChange={onChange}
      onBlur={onReset}
      placeholder='Search for account, shop, user or catalog'
      autoFocus
      inputRef={inputRef}
    />
  );
};
