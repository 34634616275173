import { isEqual } from 'lodash';
import { useState, useEffect, useCallback, RefObject } from 'react';

export type ContainerDimensions = Omit<DOMRect, 'toJSON'>;

const initialState: ContainerDimensions = {
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  x: 0,
  y: 0,
};

export interface UseContainerDimensionsArguments {
  ref: RefObject<HTMLHeadingElement>;
}

export interface UseContainerDimensionsReturnType {
  dimensions: ContainerDimensions;
  updatePosition: () => void;
}

export const useContainerDimensions = ({
  ref,
}: UseContainerDimensionsArguments): UseContainerDimensionsReturnType => {
  const getDimensions = useCallback(() => {
    if (!ref.current) {
      return initialState;
    }
    const rect = ref.current.getBoundingClientRect().toJSON();

    return rect;
  }, [ref.current]);

  const [dimensions, setDimensions] = useState(initialState);

  const updatePosition = useCallback(() => {
    const newDimensions = getDimensions();
    const shouldUpdateState = !isEqual(newDimensions, dimensions);

    if (shouldUpdateState) {
      setDimensions(newDimensions);
    }
  }, [getDimensions]);

  useEffect(() => {
    if (ref.current) {
      updatePosition();
      ref.current.addEventListener('click', updatePosition);
      ref.current.addEventListener('resize', updatePosition);
      ref.current.addEventListener('transitionend', updatePosition);
    }

    window.addEventListener('resize', updatePosition);
    window.addEventListener('scroll', updatePosition, true);

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('click', updatePosition);
        ref.current.removeEventListener('resize', updatePosition);
        ref.current.removeEventListener('transitionend', updatePosition);
      }
      window.removeEventListener('resize', updatePosition);
      window.removeEventListener('scroll', updatePosition, true);
    };
  }, [ref]);

  return { dimensions, updatePosition };
};
