import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  FormCard,
  Typography,
  TypographyVariant,
  TypographyType,
  TabsV2,
  Box,
  TabV2,
} from 'src/components-dummy';
import { IShopGeneratedDataSettings, ShopTypes } from 'src/services';
import {
  ShopDeepTagReportsSettingsContainerStyled,
  SubTitleWrapper,
} from './ShopDeepTagReportsSettings.styles';
import { ShopDeepTagReportsSettingsToggleConfirmation } from './components/ShopDeepTagReportsSettingsToggleConfirmation';
import { DESCRIPTION, TITLE } from './ShopDeepTagReportsSettings.config';
import { ShopDeepTagReportsSettingsTabContent } from './components/ShopDeepTagReportsSettingsTabContent';

export const INDEX_BY_TAB_NAME: { [key: string]: number } = {
  [TITLE]: 0,
  [DESCRIPTION]: 1,
};

interface IFormState {
  title: { isAutoGenerate: boolean };
  description: { isAutoGenerate: boolean };
}

export interface ShopDeepTagReportsSettingsProps {
  shop: ShopTypes.ShopBase | undefined;
  titleIsAutoGenerate?: boolean;
  descriptionIsAutoGenerate?: boolean;
  isLoading: boolean;
  onUpdate: (data: IShopGeneratedDataSettings) => void;
}

let actionOnConfirm: undefined | (() => void);
export const ShopDeepTagReportsSettings = ({
  shop,
  titleIsAutoGenerate,
  descriptionIsAutoGenerate,
  onUpdate,
}: ShopDeepTagReportsSettingsProps): JSX.Element => {
  const [formState, setFormState] = useState<IFormState>({
    title: { isAutoGenerate: !!titleIsAutoGenerate },
    description: { isAutoGenerate: !!descriptionIsAutoGenerate },
  });
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [shouldShowConfirmation, setShouldShowConfirmation] = useState<boolean>(false);

  const onSubmit = useCallback(async () => {
    await onUpdate({
      title: { isAutoGenerate: formState.title.isAutoGenerate },
      description: { isAutoGenerate: formState.description.isAutoGenerate },
    });
  }, [shop?.shopId, formState]);

  useEffect(() => {
    setFormState({
      title: { isAutoGenerate: !!titleIsAutoGenerate },
      description: { isAutoGenerate: !!descriptionIsAutoGenerate },
    });
  }, [titleIsAutoGenerate, descriptionIsAutoGenerate]);

  const resetToggleConfirmation = () => {
    setShouldShowConfirmation(false);
    actionOnConfirm = undefined;
  };

  const onIsAutoGenerateChange =
    (type: typeof TITLE | typeof DESCRIPTION) => (isAutoGenerate: boolean) => {
      const actionChange = () => {
        setFormState(state => ({
          ...state,
          [type]: { isAutoGenerate },
        }));
        resetToggleConfirmation();
      };

      if (isAutoGenerate) {
        actionOnConfirm = actionChange;
        setShouldShowConfirmation(true);
      } else {
        actionChange();
      }
    };

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const isDirty = useMemo(() => {
    if (formState.title.isAutoGenerate !== !!titleIsAutoGenerate) {
      return true;
    }

    if (formState.description.isAutoGenerate !== !!descriptionIsAutoGenerate) {
      return true;
    }

    return false;
  }, [formState, titleIsAutoGenerate, descriptionIsAutoGenerate]);

  return (
    <ShopDeepTagReportsSettingsContainerStyled>
      {shouldShowConfirmation && (
        <ShopDeepTagReportsSettingsToggleConfirmation
          onApproveSubmit={actionOnConfirm}
          onCloseDialog={resetToggleConfirmation}
        />
      )}
      <FormCard>
        <FormCard.Title>Generative Content</FormCard.Title>
        <FormCard.Button>
          <Button onClick={onSubmit} variant='primary' disabled={!isDirty}>
            Save changes
          </Button>
        </FormCard.Button>

        <FormCard.Content>
          <SubTitleWrapper>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
              Automate Generation
            </Typography>
          </SubTitleWrapper>

          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '16px' }}>
            <TabsV2 value={selectedTabIndex} onChange={onTabChange}>
              <TabV2
                label={
                  <Typography type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                    Title
                  </Typography>
                }
              />
              <TabV2
                label={
                  <Typography type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                    Description
                  </Typography>
                }
              />
            </TabsV2>
          </Box>
          <>
            {selectedTabIndex === INDEX_BY_TAB_NAME[TITLE] ? (
              <ShopDeepTagReportsSettingsTabContent
                title='Generate titles automatically'
                onChange={onIsAutoGenerateChange(TITLE)}
                isChecked={formState.title.isAutoGenerate}
              />
            ) : (
              <ShopDeepTagReportsSettingsTabContent
                title='Generate descriptions automatically'
                onChange={onIsAutoGenerateChange(DESCRIPTION)}
                isChecked={formState.description.isAutoGenerate}
              />
            )}
          </>
        </FormCard.Content>
      </FormCard>
    </ShopDeepTagReportsSettingsContainerStyled>
  );
};
