import { AppNotification } from 'src/app-types';
import { NotificationType } from 'src/components-dummy';
import { actionsToNotificationsMap } from './actions-notifications-map';
import { ErrorTranslationMap } from '../types';

function getDefaultNotificationMessage(toast: AppNotification) {
  switch (toast.type) {
    case NotificationType.Error:
      return 'Something went wrong. Please, reload page and try again';
    case NotificationType.Success:
      return 'Action succeeded';
    case NotificationType.Warning:
      return 'Action finished with warning';
    default:
      return '';
  }
}

export function getNotificationMessage(toast: AppNotification): string {
  const { errorKey, messageKey, customMessage } = toast;

  if (customMessage) return customMessage;

  const message = actionsToNotificationsMap[messageKey];

  let notificationMessage: string;

  if (typeof message === 'object') {
    const messageObject = message as ErrorTranslationMap;
    notificationMessage = errorKey ? messageObject[errorKey] : '';

    if (!notificationMessage) {
      if (!errorKey) {
        console.warn(
          `toast notification actionType ${messageKey} has no translation for errorKey ${errorKey}. toast timestamp: ${toast.timestamp}, toast type: ${toast.type}`
        );
      }

      notificationMessage = (message as ErrorTranslationMap).default;
    }
  } else {
    notificationMessage = message;
  }

  if (!notificationMessage) {
    console.warn(
      `toast notification actionType ${messageKey} has no translation. toast timestamp: ${toast.timestamp}, toast type: ${toast.type}`
    );

    notificationMessage = getDefaultNotificationMessage(toast);
  }

  return notificationMessage;
}
