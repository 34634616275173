import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { visualEditorActions } from '../state';
import { useAppSelector } from '../../../hooks';
import { MANDATORY_DATA_FIELDS_TO_DISPLAY } from '../consts/mandatory-data-fields-to-display';
import { UniqueByField } from '../consts/uniqueByField';

interface UseGetDataFieldsToDisplayReturn {
  fields: string;
}

export const useGetDataFieldsToDisplay = ({
  shopId,
}: {
  shopId: number;
}): UseGetDataFieldsToDisplayReturn => {
  const { dataFieldsToDisplay } = useAppSelector(({ visualEditor }) => visualEditor);
  const [fields, setFields] = useState<string>('');
  const appDispatch = useDispatch();

  useEffect(() => {
    appDispatch(visualEditorActions.getVisualEditorSettings({ shopId }));
  }, [shopId]);

  useEffect(() => {
    const fieldValues = [
      ...MANDATORY_DATA_FIELDS_TO_DISPLAY,
      UniqueByField.ParentSku,
      ...dataFieldsToDisplay.map(field => field.value),
    ].join(',');
    setFields(fieldValues);
  }, [dataFieldsToDisplay]);

  return { fields };
};
