import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { CatalogManagerService, ShopSFTPSettingsService } from 'src/services';

const catalogManagerService = new CatalogManagerService();

export const catalogFormContainerActions = {
  navigateTo: createAction<{ navigateTo: string }>('CatalogFormContainer/NavigateTo'),
  getSingleCatalog: createAsyncThunk(
    'CatalogFormContainerActions/GetCatalog',
    async (
      { shopId, catalogName }: { shopId: number; catalogName: string },
      { rejectWithValue }
    ) => {
      try {
        const selectedCatalog = await catalogManagerService.getCatalogPlan({
          shopId,
          catalogName,
        });

        return { selectedCatalog };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  getShopSFTPSettings: createAsyncThunk(
    'CatalogFormActions/getShopSFTPSettings',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const settings = await new ShopSFTPSettingsService().getSettings(shopId);
        return { settings };
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  ),
};
