import React from 'react';
import { DeepTagStatusEnum } from 'src/services/src/service/types';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import { ScoreTypeEditedStyled } from './DeepTagProductTags.style';

const TextByStatus = {
  [DeepTagStatusEnum.New]: 'Added by user',
  [DeepTagStatusEnum.Edited]: 'Edited',
  [DeepTagStatusEnum.Deleted]: 'Removed',
};

export const DeepTagStatus = ({
  status,
  shouldHideEditIndication,
}: {
  status: DeepTagStatusEnum;
  shouldHideEditIndication: boolean;
}): JSX.Element | null => {
  if (status === DeepTagStatusEnum.Original) {
    return null;
  }

  if (shouldHideEditIndication && status !== DeepTagStatusEnum.Deleted) {
    return null;
  }

  return (
    <>
      <div />
      <ScoreTypeEditedStyled>
        <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
          {TextByStatus[status]}
        </Typography>
      </ScoreTypeEditedStyled>
    </>
  );
};
