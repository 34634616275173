import React from 'react';
import SVG from 'react-inlinesvg';
import classNames from 'classnames';
import { AvailableIcons } from './AvailableIcons';
import './Icon.scss';

const icons = require.context('../../assets/icons', true, /\.svg$/);

export interface IconProps {
  name: AvailableIcons;
  className?: string;
  css?: any;
  onClick?: (ev: React.MouseEvent) => void;
}

export const Icon = ({ name, className, onClick }: IconProps): JSX.Element => {
  const onIconClick = (ev: React.MouseEvent<SVGElement, MouseEvent>) => {
    onClick?.(ev);
  };

  let iconName: string = name;

  try {
    iconName = icons(`./${name}.svg`);
  } catch (e) {
    console.error(e);
    iconName = icons(`./${AvailableIcons.Empty}.svg`);
  }

  return (
    <SVG
      src={`${iconName}`}
      className={classNames(
        `syte-basic-icon`,
        { 'syte-basic-icon-clickable': !!onClick },
        className
      )}
      onClick={onIconClick}
    />
  );
};
