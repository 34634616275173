import React, { useRef, useEffect, useState, MutableRefObject } from 'react';
import classNames from 'classnames';
import { Notification } from '../Notification';
import { ToastProps } from './types';
import './Toast.scss';
import { AvailableIcons } from '../Icon';

export const Toast = ({
  children,
  mode,
  expireInSeconds,
  className,
  onClose,
}: ToastProps): JSX.Element => {
  const timeout: MutableRefObject<NodeJS.Timeout | undefined> = useRef();
  const [outAnimation, setOutAnimation] = useState<boolean>(false);

  function handleClose(): void {
    if (onClose) {
      onClose();
    }
  }

  function closeDelayed(): void {
    setOutAnimation(true);
    setTimeout((): void => {
      handleClose();
    }, 500);
  }

  useEffect((): (() => void) => {
    if (expireInSeconds) {
      timeout.current = setTimeout(() => closeDelayed(), expireInSeconds * 1000);
    }
    return (): void => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return (
    <div
      role='presentation'
      className={classNames(
        'syte-toast',
        `syte-toast-${mode}`,
        outAnimation && 'syte-toast-remove',
        className
      )}
    >
      <Notification
        mode={mode}
        onClose={handleClose}
        closeable
        icon={mode === 'warning' ? AvailableIcons.WarningOutline : undefined}
      >
        {children}
      </Notification>
    </div>
  );
};
