import { AvailableIcons } from 'src/components-dummy';
import { DomainEntityPath } from 'src/services';
import { ParentDomainBase } from '../../types';
import { rankingSubDomainGroup } from '../ranking-sub-domain-group';

export const collectionSettingsDomainGroup: ParentDomainBase = {
  ...rankingSubDomainGroup,
  domainKey: DomainEntityPath.CollectionSettings,
  title: 'Global Collection Settings',
  icon: AvailableIcons.Collections,
  subDomains: [
    {
      ...rankingSubDomainGroup,
      domainKey: DomainEntityPath.CollectionGlobalRanking,
    },
  ],
};
