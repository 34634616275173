import React, { useCallback, useImperativeHandle } from 'react';
import { generatePath } from 'react-router-dom';
import { IShopCatalog } from 'src/services';
import { NotificationCardMessageTypography } from '../common';
import { NotificationCardMessageProps } from '../types';
import { getUserDisplayName } from '../utils';

export function ShopCatalogConnectMessage({
  notification,
  shopId,
  permittedRouteMap,
  navigateTo,
  cardRef,
}: NotificationCardMessageProps<IShopCatalog>): JSX.Element {
  const onNotificationClick = useCallback(() => {
    if (permittedRouteMap.catalogManagerFeedUploadLog?.path) {
      const catalogUploadLogsPath = generatePath(
        permittedRouteMap.catalogManagerFeedUploadLog.path,
        {
          shopId,
          catalogName: notification.content.name,
        }
      );
      navigateTo(catalogUploadLogsPath);
    }
  }, [
    shopId,
    notification.content.name,
    navigateTo,
    permittedRouteMap.catalogManagerFeedUploadLog?.path,
  ]);

  useImperativeHandle(cardRef, () => ({
    onNotificationClick,
  }));

  return (
    <NotificationCardMessageTypography>
      {getUserDisplayName(notification.createdByUser)} connected a new catalog.
    </NotificationCardMessageTypography>
  );
}
