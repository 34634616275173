import styled from '@emotion/styled';

export const SettingsSectionStyled = styled.div`
  margin-top: 4px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-80']};
  background: ${({ theme }) => theme.palette.custom['gray-120']};
`;

export const SimilarProductToggleStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 14px;
  gap: 8px;
`;
