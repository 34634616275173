import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const PopupHeaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  user-select: none;

  .ellipsis-with-tooltip-content,
  .ellipsis-with-tooltip {
    color: ${({ theme }) => theme.palette.custom['gray-20']};
  }
`;

export const PopupHeaderTypographyStyled = styled(Typography)<{ isTitle: boolean }>`
  margin-left: 6px;

  ${({ theme, isTitle }) =>
    !isTitle
      ? `color:${theme.palette.custom['gray-20']};
         transform: translateY(-2px);`
      : 'transform: translateY(-1px);'}
`;

export const MultiSelectTagLabelStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const MultiSelectTagContentStyled = styled.div`
  padding: 12px;

  p:first-of-type {
    padding-bottom: 10px;
    padding-left: 10px;
  }
`;
