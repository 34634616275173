import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { ActionIcon } from '../ActionIcon';
import { PopUp, PopupProps } from '../PopUp';
import './PopupMenu.scss';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import { AvailableIcons } from '../Icon';

export interface PopupMenuProps extends PopupProps {
  text?: string;
}

export interface PopUpMenuItemProps {
  children: ReactNode;
  onClick?: () => void;
  className?: string;
}

export const PopUpMenuItem = ({
  children,
  onClick,
  className,
}: PopUpMenuItemProps): JSX.Element => {
  return (
    <li onClick={onClick} className={classNames('syte-popup-menu-item', className)}>
      {children}
    </li>
  );
};

export const PopupMenu = ({
  text,
  children,
  className,
  ...popupProps
}: PopupMenuProps): JSX.Element => {
  return (
    <PopUp {...popupProps} className={classNames('syte-popup-menu', className)}>
      <PopUp.Trigger className='syte-popup-menu-wrapper'>
        {text && (
          <Typography
            type={TypographyType.Body}
            variant={TypographyVariant.MediumRegular}
            className='syte-popup-menu-text'
          >
            {text}
          </Typography>
        )}
        <ActionIcon iconName={AvailableIcons.EllipsisMenu} className='syte-popup-menu-trigger' />
      </PopUp.Trigger>
      <PopUp.Content>
        <ul className='syte-popup-menu-content'>{children}</ul>
      </PopUp.Content>
    </PopUp>
  );
};
