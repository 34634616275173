import styled from '@emotion/styled';
import React, { CSSProperties, SyntheticEvent, useState, MouseEvent } from 'react';
import { Size } from 'src/components-bl/socialDiscovery/ProductTags/useProductTagsState/types';
import { StyledBlur, StyledBlurContainer, StyledImageContainer } from './styledComponents';

export interface StoryImageProps {
  imageSrc: string;
  onClick: (e: MouseEvent<HTMLImageElement>) => void;
  onLoad: (postSize: Size) => void;
  children: JSX.Element;
}

export const StyledImage = styled.img`
  width: 100%;

  &.landscape {
    height: auto;
  }
  &.portrait {
    object-fit: cover;
    height: 100%;
  }
`;

export const StoryImage = ({
  imageSrc,
  onClick,
  onLoad,
  children,
}: StoryImageProps): JSX.Element => {
  const containerStyles = { backgroundImage: `url(${imageSrc})` } as CSSProperties;
  const [isLandscape, setIsLandscape] = useState<boolean>(true);

  const onImageChange = (e: SyntheticEvent<HTMLImageElement>) => {
    const { width, height, naturalHeight, naturalWidth } = e.currentTarget;
    setIsLandscape(naturalHeight <= naturalWidth);
    onLoad({ width, height });
  };

  return (
    <StyledBlurContainer style={containerStyles}>
      <StyledBlur />
      <StyledImageContainer isLandscape={isLandscape}>
        <StyledImage
          src={imageSrc}
          onClick={onClick}
          alt='story-post'
          onLoad={onImageChange}
          className={isLandscape ? 'landscape' : 'portrait'}
        />
        {children}
      </StyledImageContainer>
    </StyledBlurContainer>
  );
};
