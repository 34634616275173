import React, { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { generatePath, useParams } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { useAppSelector, usePreviousState } from 'src/hooks';
import { CatalogExplorerContainerActions } from './CatalogExplorerContainer.actions';
import { CatalogExplorerPage } from 'src/components-bl/CatalogManager/CatalogExplorer/CatalogExplorerPage';
import { IShopCatalog } from 'src/services';

export const CatalogExplorerContainer = ({
  permittedRouteMap,
}: RoutedComponentProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const {
    catalogs,
    catalogProducts,
    totalCatalogProducts,
    totalFilteredProducts,
    shopId,
    shopDataFields,
    languages,
  } =
    useAppSelector(
      state => ({
        catalogs: state.catalogManager.catalogs,
        catalogProducts: state.catalogManager.catalogExplorer.products,
        totalCatalogProducts: state.catalogManager.catalogExplorer.totalCatalogProducts,
        totalFilteredProducts: state.catalogManager.catalogExplorer.totalFilteredProducts,
        shopId: state.shop.current.shopId,
        shopDataFields: state.dataFields.dataFields,
        languages: state.meta.languages,
      }),
      shallowEqual
    ) || {};

  const previousShopId = usePreviousState(shopId);
  const { catalogName } = useParams<{ catalogName: string }>();

  const navigateToCatalogsManagerList = useCallback(() => {
    if (permittedRouteMap.catalogManager?.path && shopId) {
      const path = generatePath(permittedRouteMap.catalogManager?.path, { shopId });

      dispatch(
        CatalogExplorerContainerActions.navigateTo({
          navigateTo: path,
        })
      );
    }
  }, [permittedRouteMap.catalogManager?.path, shopId]);

  useEffect(() => {
    if (previousShopId && shopId && shopId !== previousShopId) {
      navigateToCatalogsManagerList();
    }
  }, [navigateToCatalogsManagerList, previousShopId, shopId]);

  useEffect(() => {
    if (!shopDataFields) {
      dispatch(CatalogExplorerContainerActions.getDataFields({ shopId }));
    }
  }, [shopDataFields]);

  useEffect(() => {
    if (previousShopId && previousShopId !== shopId) {
      dispatch(CatalogExplorerContainerActions.getDataFields({ shopId }));
    }
  }, [shopId, previousShopId]);

  useEffect(() => {
    const catalogExistsForShop = catalogs?.some(catalog => catalog.name == catalogName);

    if (catalogs && !catalogExistsForShop) {
      console.warn(`Catalog - ${catalogName} doesn't exist in shop`);
      navigateToCatalogsManagerList();
    }
  }, [catalogs, catalogName]);

  if (!shopId) {
    return null;
  }

  const selectedCatalog: IShopCatalog | undefined = useMemo(() => {
    return catalogs?.find(catalog => catalog.name === catalogName);
  }, [catalogs]);

  const languageMetadata = useMemo(
    () => languages.find(language => language.locale === selectedCatalog?.defaultLocale),
    [selectedCatalog, languages]
  );

  return (
    <CatalogExplorerPage
      catalogProducts={catalogProducts}
      totalCatalogProducts={totalCatalogProducts}
      totalFilteredProducts={totalFilteredProducts}
      catalog={selectedCatalog}
      shopDataFields={shopDataFields}
      navigateToCatalogsList={navigateToCatalogsManagerList}
      catalogName={catalogName}
      shopId={shopId}
      dispatch={dispatch}
      languageMetadata={languageMetadata}
    />
  );
};
