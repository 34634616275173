import { createReducer, isAnyOf } from '@reduxjs/toolkit';
import { VariantPanelAction } from 'src/components-bl';
import { MerchandisingRulesListActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesListPage/components/MerchandisingRulesList/Actions/MerchandisingRulesList.actions';
import { merchandisingRulesFormActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm';
import { merchandisingRulesListPageActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesListPage/MerchandisingRulesListPage.actions';
import { EditExperimentContainerAction } from 'src/containers';
import { editMerchandisingRuleContainerActions } from 'src/containers/MerchandisingRules/EditMerchandisingRuleContainer';
import { useMerchandisingRulesWSActions } from 'src/containers/MerchandisingRules';
import { merchandisingRulesManagementActions } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesManagement/MerchandisingRulesManagement.actions';
import { RulePages } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesManagement';
import { MerchandisingRulesReducerState } from '../app-state-types';

const initialState: MerchandisingRulesReducerState = {
  selectedRule: undefined,
  duplicateRuleDraft: undefined,
  rules: undefined,
  isFormCreation: false,
  shouldRefetch: false,
  currentPage: RulePages.List,
  overview: undefined,
};

export type MerchandisingRuleActionType = EditExperimentContainerAction | VariantPanelAction;

export const merchandisingRulesReducer = createReducer(initialState, builder => {
  builder
    .addCase(merchandisingRulesManagementActions.getRule.fulfilled, (state, action) => {
      return { ...state, selectedRule: action.payload.rule };
    })
    .addCase(merchandisingRulesManagementActions.changePage, (state, action) => {
      return { ...state, currentPage: action.payload.page };
    })
    .addCase(editMerchandisingRuleContainerActions.getRule.fulfilled, (state, action) => {
      return { ...state, selectedRule: action.payload.rule };
    })
    .addCase(merchandisingRulesListPageActions.getRules.fulfilled, (state, action) => {
      return {
        ...state,
        rules: action.payload.merchandisingRules,
        totalCount: action.payload.pagination.totalCount,
      };
    })
    .addCase(MerchandisingRulesListActions.setDuplicateRuleDraft, (state, action) => {
      return { ...state, duplicateRuleDraft: action.payload.rule };
    })
    .addCase(merchandisingRulesFormActions.reset, state => {
      return { ...state, selectedRule: undefined, duplicateRuleDraft: undefined };
    })
    .addCase(merchandisingRulesListPageActions.resetRules, state => {
      return { ...state, rules: initialState.rules };
    })
    .addMatcher(
      isAnyOf(
        merchandisingRulesFormActions.getRulesOverview.fulfilled,
        merchandisingRulesListPageActions.getRulesOverview.fulfilled
      ),
      (state, action) => {
        return { ...state, overview: action.payload };
      }
    )
    .addMatcher(
      isAnyOf(
        MerchandisingRulesListActions.deleteRule.fulfilled,
        useMerchandisingRulesWSActions.delete
      ),
      (state, action) => {
        const rules = (state.rules || []).filter(rule => rule.id !== action.payload.ruleId);

        return {
          ...state,
          rules,
        };
      }
    );
});
