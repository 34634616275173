/* eslint-disable unused-imports/no-unused-vars-ts */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useCallback, useMemo } from 'react';
import {
  AvailableIcons,
  TabsV2,
  TabV2,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { Box } from 'src/components-dummy/Box';
import {
  DeepTag,
  DeepTagAPI,
  DeepTagsReportsGeneratedText,
  DeepTagsReportsGenerativeAIFieldType,
  DescriptionTextLengthEnum,
  IShopGeneratedDataSettings,
  DeepTagSourceEnum,
  TitleTextLengthEnum,
  ToneOfVoiceTypes,
  DeepTagsGenerativeAISubProduct,
} from 'src/services/src';
import { usePreviousState } from 'src/hooks/usePreviousState';
import { DeepTagStatusEnum } from 'src/services';
import { BackDropWithLoader } from 'src/components-dummy/Backdrop/BackdropWithLoader';
import { css } from '@emotion/react';
import {
  HeaderStyled,
  ProductTitle,
  HeaderGenerativeProductStyled,
} from '../../DeepTagsProductForm.style';
import {
  BackIconStyled,
  GenericContainerStyled,
  MainStyled,
} from './DeepTagsGenerativeAIForm.style';
import {
  toneOfVoiceValueToText,
  DESCRIPTION,
  TITLE,
  INDEX_BY_TAB_NAME,
  GENERATED_DESCRIPTION,
  GENERATED_TITLE,
  titleTextLengthValueToText,
  descriptionTextLengthValueToText,
} from './DeepTagsGenerativeAIForm.config';
import { TitleGeneratedForm } from './TitleGeneratedForm';
import { DescriptionGeneratedForm } from './DescriptionGeneratedForm';
import { DeepTagsProductReviewAndEditTagsSectionProps } from '../DeepTagsProductReviewAndEditTagsSection/DeepTagsProductReviewAndEditTagsSection';
import { GenerateTextResponse, OnIsDirtyChange } from '../../DeepTagsProductForm.types';
import { DeepTagsGenerativeAIPromptModal } from './DeepTagsGenerativeAIPromptModal';

export type OnGeneratedText = ({
  subProducts,
  fieldType,
  toneOfVoice,
  textLength,
}: {
  subProducts: DeepTagsGenerativeAISubProduct[];
  fieldType: DeepTagsReportsGenerativeAIFieldType;
  toneOfVoice?: ToneOfVoiceTypes;
  textLength: DescriptionTextLengthEnum | TitleTextLengthEnum;
}) => Promise<DeepTagsReportsGeneratedText>;

interface IFormData {
  description: {
    text: string;
    toneOfVoice: ToneOfVoiceTypes;
    textLength: DescriptionTextLengthEnum;
  };
  title: {
    text: string;
    textLength: TitleTextLengthEnum;
  };
}
interface DeepTagsGenerativeAIProps {
  subProducts: DeepTagsGenerativeAISubProduct[];
  mainProductTags: DeepTag[];
  generatedDataSettings?: IShopGeneratedDataSettings;
  customGeneratedAITabSelection?: string;
  resetCustomGeneratedAISelectionTab: () => void;
  backToMainScreen: () => void;
  onGeneratedText: OnGeneratedText;
  onSaveChanges: DeepTagsProductReviewAndEditTagsSectionProps['onSaveChanges'];
  onSaveGeneratedDataSettings: ({
    shopGeneratedDataSettings,
  }: {
    shopGeneratedDataSettings: IShopGeneratedDataSettings;
  }) => void;

  onIsDirtyChange: OnIsDirtyChange;
}

export const DeepTagsGenerativeAI = ({
  subProducts,
  mainProductTags,
  generatedDataSettings,
  customGeneratedAITabSelection,
  onSaveChanges,
  onSaveGeneratedDataSettings,
  backToMainScreen,
  resetCustomGeneratedAISelectionTab,
  onGeneratedText: onGetGeneratedText,
  onIsDirtyChange,
}: DeepTagsGenerativeAIProps): JSX.Element => {
  const initTabIndexSelection =
    customGeneratedAITabSelection === GENERATED_TITLE
      ? INDEX_BY_TAB_NAME[TITLE]
      : INDEX_BY_TAB_NAME[DESCRIPTION];

  const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(initTabIndexSelection);
  const [isInEditMode, setIsInEditMode] = React.useState(false);
  const [isInGenerateProcess, setIsInGenerateProcess] = React.useState(false);
  const [isSavingProcess, setIsSavingProcess] = React.useState(false);

  const [isRequestPromptModalOpened, setIsRequestPromptModalOpened] = React.useState(false);

  const [descriptionRequestPromptText, setDescriptionRequestPrompt] = React.useState<
    string | undefined
  >(undefined);

  const [titleRequestPromptText, setTitleRequestPromptText] = React.useState<string | undefined>(
    undefined
  );

  const existedGeneratedTags = useMemo(() => {
    return mainProductTags.reduce(
      (prev: Record<string, DeepTag>, next: DeepTag): Record<string, DeepTag> => {
        if (next.attribute.key === GENERATED_TITLE) {
          // eslint-disable-next-line no-param-reassign
          prev[GENERATED_TITLE] = next;
        }

        if (next.attribute.key === GENERATED_DESCRIPTION) {
          // eslint-disable-next-line no-param-reassign
          prev[GENERATED_DESCRIPTION] = next;
        }

        return prev;
      },
      {}
    );
  }, [mainProductTags]);

  const previousSelectedTab = usePreviousState(selectedTabIndex);

  const [formData, setFormData] = React.useState<IFormData>({
    title: {
      text: existedGeneratedTags[GENERATED_TITLE]?.value.translation || '',
      textLength:
        (generatedDataSettings?.title?.textLength &&
          titleTextLengthValueToText[generatedDataSettings?.title?.textLength]?.value) ||
        TitleTextLengthEnum.UpTo5Words,
    },
    description: {
      text: existedGeneratedTags[GENERATED_DESCRIPTION]?.value.translation || '',
      toneOfVoice:
        generatedDataSettings?.description?.toneOfVoice ||
        toneOfVoiceValueToText.assertion_tone.value,
      textLength:
        (generatedDataSettings?.description?.textLength &&
          descriptionTextLengthValueToText[generatedDataSettings?.description?.textLength]
            ?.value) ||
        DescriptionTextLengthEnum.TwoParagraphs,
    },
  });

  const prevFormState = usePreviousState(formData);

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  const onSaveChangesClick = async () => {
    setIsSavingProcess(true);
    const tagsListToUpdate = [...mainProductTags];

    const baseTagProps = {
      hasAddedInForm: true,
      source: DeepTagSourceEnum.Generated,
      status: DeepTagStatusEnum.New,
    };

    if (formData.title.text) {
      const titleTag: DeepTagAPI = {
        ...baseTagProps,
        attribute: {
          key: GENERATED_TITLE,
          translation:
            existedGeneratedTags[GENERATED_TITLE]?.attribute.translation || GENERATED_TITLE,
        },
        value: { key: formData.title.text, translation: formData.title.text },
      };

      tagsListToUpdate.push(titleTag);
    }

    if (formData.description.text) {
      const descriptionTag: DeepTagAPI = {
        ...baseTagProps,
        attribute: {
          key: GENERATED_DESCRIPTION,
          translation:
            existedGeneratedTags[GENERATED_DESCRIPTION]?.attribute.translation ||
            GENERATED_DESCRIPTION,
        },
        value: { key: formData.description.text, translation: formData.description.text },
      };

      tagsListToUpdate.push(descriptionTag);
    }

    const promiseSaveTags = onSaveChanges({
      partialProduct: { tags: tagsListToUpdate },
    }) as any;

    const promiseSaveGeneratedSettings = onSaveGeneratedDataSettings({
      shopGeneratedDataSettings: {
        title: {
          textLength: formData.title.textLength,
        },
        description: {
          toneOfVoice: formData.description.toneOfVoice,
          textLength: formData.description.textLength,
        },
      },
    }) as any;

    const [responseSaveTags, responseSaveGeneratedSettings] = await Promise.all([
      promiseSaveTags,
      promiseSaveGeneratedSettings,
    ]);

    setIsSavingProcess(false);

    if ((responseSaveTags.error as any) || (responseSaveGeneratedSettings.error as any)) {
      if (responseSaveTags.error) {
        console.error('Unable to save tags due to:', responseSaveTags.error);
      }

      if (responseSaveGeneratedSettings.error) {
        console.error('Unable to save generated data due to:', responseSaveGeneratedSettings.error);
      }
    } else {
      onIsDirtyChange(false);
    }
  };

  /**
   * Title - methods
   */
  const onTitleEditKeepEditedState = (value: string) => {
    setFormData(prevState => ({ ...prevState, title: { ...prevState.title, text: value } }));
    setIsInEditMode(false);
  };

  const onTitleTextLengthChange = (value: string) => {
    setFormData((prevState: IFormData): IFormData => {
      return {
        ...prevState,
        title: {
          ...prevState.title,
          textLength: value as TitleTextLengthEnum,
        },
      };
    });
  };

  /**
   * Description - methods
   */
  const onDescriptionKeepEditedState = (value: string) => {
    setFormData(prevState => ({
      ...prevState,
      description: { ...prevState.description, text: value },
    }));
    setIsInEditMode(false);
  };

  const onDescriptionToneOfVoiceChange = (value: string) => {
    setFormData((prevState: IFormData): IFormData => {
      return {
        ...prevState,
        description: {
          ...prevState.description,
          toneOfVoice: value as ToneOfVoiceTypes,
        },
      };
    });
  };

  const onDescriptionTextLengthChange = (value: string) => {
    setFormData((prevState: IFormData): IFormData => {
      return {
        ...prevState,
        description: {
          ...prevState.description,
          textLength: value as DescriptionTextLengthEnum,
        },
      };
    });
  };

  /**
   * Generate
   */
  const generateTitleAndSetState = useCallback(async (): Promise<void> => {
    setIsInGenerateProcess(true);

    setTitleRequestPromptText('');

    const generatedTextResult = (await onGetGeneratedText({
      subProducts,
      fieldType: DeepTagsReportsGenerativeAIFieldType.Title,
      textLength: formData.title.textLength,
    })) as any;

    setIsInGenerateProcess(false);

    if (generatedTextResult.error) {
      console.error(`Unable to update due to:, ${generatedTextResult.payload.error}`);
    } else {
      if (generatedTextResult) {
        setTitleRequestPromptText(
          (generatedTextResult as GenerateTextResponse).request_prompt_text
        );
      }

      setFormData((prevState: IFormData): IFormData => {
        return {
          ...prevState,
          title: {
            ...prevState.title,
            text: generatedTextResult?.text?.trim() || '',
          },
        };
      });
    }
  }, [formData.title.textLength, onGetGeneratedText, subProducts]);

  const generateDescriptionAndSetState = useCallback(async (): Promise<void> => {
    setIsInGenerateProcess(true);
    setDescriptionRequestPrompt(undefined);

    const generatedTextResult = (await onGetGeneratedText({
      subProducts,
      toneOfVoice: formData.description.toneOfVoice,
      textLength: formData.description.textLength,
      fieldType: DeepTagsReportsGenerativeAIFieldType.Description,
    })) as any;

    setIsInGenerateProcess(false);

    if (generatedTextResult.error) {
      console.error(`Unable to update due to:, ${generatedTextResult.payload.error}`);
    } else {
      if (generatedTextResult) {
        setDescriptionRequestPrompt(
          (generatedTextResult as GenerateTextResponse).request_prompt_text
        );
      }

      setFormData((prevState: IFormData): IFormData => {
        return {
          ...prevState,
          description: {
            ...prevState.description,
            text: generatedTextResult?.text?.trim() || '',
          },
        };
      });
    }
  }, [
    formData.description.textLength,
    formData.description.toneOfVoice,
    onGetGeneratedText,
    subProducts,
  ]);

  const onToggleShowRequestPrompt = () => {
    setIsRequestPromptModalOpened(isOpened => !isOpened);
  };

  /**
   * ON change Tab - (description / title)
   */
  useEffect(() => {
    const run = async () => {
      if (selectedTabIndex === INDEX_BY_TAB_NAME[TITLE] && !formData.title.text) {
        generateTitleAndSetState();
      }
      if (selectedTabIndex === INDEX_BY_TAB_NAME[DESCRIPTION] && !formData.description.text) {
        generateDescriptionAndSetState();
      }
    };

    if (selectedTabIndex !== previousSelectedTab) {
      run();
    }
  }, [
    selectedTabIndex,
    previousSelectedTab,
    formData.title.text,
    formData.description.text,
    generateTitleAndSetState,
    generateDescriptionAndSetState,
  ]);

  // Title - ON change - textLength / toneOfVoice.
  useEffect(() => {
    if (
      // verify - callback wasn't invoked due to - tab change
      selectedTabIndex === INDEX_BY_TAB_NAME[TITLE] &&
      previousSelectedTab === INDEX_BY_TAB_NAME[TITLE]
    ) {
      const hasTextLengthChanged = prevFormState?.title?.textLength !== formData.title.textLength;

      if (hasTextLengthChanged) {
        generateTitleAndSetState();
      }
    }
  }, [
    formData.title.textLength,
    generateTitleAndSetState,
    prevFormState?.title,
    selectedTabIndex,
    previousSelectedTab,
  ]);

  // Description - ON change - textLength / toneOfVoice
  useEffect(() => {
    if (
      // verify - callback wasn't invoked due to - tab change
      selectedTabIndex === INDEX_BY_TAB_NAME[DESCRIPTION] &&
      previousSelectedTab === INDEX_BY_TAB_NAME[DESCRIPTION]
    ) {
      const hasTextLengthChanged =
        prevFormState?.description?.textLength !== formData.description.textLength;

      const hasToneOfVoiceChanged =
        prevFormState?.description?.toneOfVoice !== formData.description.toneOfVoice;

      if (hasTextLengthChanged || hasToneOfVoiceChanged) {
        generateDescriptionAndSetState();
      }
    }
  }, [
    formData.description.textLength,
    formData.description.toneOfVoice,
    generateDescriptionAndSetState,
    prevFormState?.description,
    selectedTabIndex,
    previousSelectedTab,
  ]);

  useEffect(() => {
    return () => {
      resetCustomGeneratedAISelectionTab();
    };
  }, []);

  const promptText =
    selectedTabIndex === INDEX_BY_TAB_NAME[TITLE]
      ? titleRequestPromptText
      : descriptionRequestPromptText;

  return (
    <>
      {isSavingProcess && <BackDropWithLoader />}
      {isRequestPromptModalOpened && promptText && (
        <DeepTagsGenerativeAIPromptModal
          onCancel={onToggleShowRequestPrompt}
          promptText={promptText}
        />
      )}

      <GenericContainerStyled>
        <HeaderStyled>
          <HeaderGenerativeProductStyled>
            <header>
              <BackIconStyled name={AvailableIcons.ArrowRounded} onClick={backToMainScreen} />
              <ProductTitle type={TypographyType.Body} variant={TypographyVariant.MediumBold}>
                Generative AI:
              </ProductTitle>
            </header>
            <footer
              css={css`
                margin-top: 16px;
              `}
            >
              <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
                All product titles and descriptions are fully editable below.
              </Typography>
            </footer>
          </HeaderGenerativeProductStyled>
        </HeaderStyled>
        <MainStyled>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabsV2 value={selectedTabIndex} onChange={onTabChange}>
              <TabV2
                label={
                  <Typography type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                    Description
                  </Typography>
                }
                disabled={selectedTabIndex !== INDEX_BY_TAB_NAME[DESCRIPTION] && isInEditMode}
              />
              <TabV2
                label={
                  <Typography type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                    Title
                  </Typography>
                }
                disabled={selectedTabIndex !== INDEX_BY_TAB_NAME[TITLE] && isInEditMode}
              />
            </TabsV2>
          </Box>
          {selectedTabIndex === INDEX_BY_TAB_NAME[TITLE] && (
            <TitleGeneratedForm
              title={formData.title.text}
              textLength={formData.title.textLength}
              onSaveChanges={onSaveChangesClick}
              onTitleEditKeepEditedState={onTitleEditKeepEditedState}
              backToMainScreen={backToMainScreen}
              isInGenerateProcess={isInGenerateProcess}
              isInEditMode={isInEditMode}
              setIsInEditMode={setIsInEditMode}
              onRegenerateButtonClick={generateTitleAndSetState}
              onTextLengthChange={onTitleTextLengthChange}
              onToggleShowRequestPrompt={
                titleRequestPromptText ? onToggleShowRequestPrompt : undefined
              }
            />
          )}
          {selectedTabIndex === INDEX_BY_TAB_NAME[DESCRIPTION] && (
            <DescriptionGeneratedForm
              description={formData.description.text}
              toneOfVoice={formData.description.toneOfVoice}
              textLength={formData.description.textLength}
              onSaveChanges={onSaveChangesClick}
              onDescriptionKeepEditedState={onDescriptionKeepEditedState}
              backToMainScreen={backToMainScreen}
              isInGenerateProcess={isInGenerateProcess}
              isInEditMode={isInEditMode}
              setIsInEditMode={setIsInEditMode}
              onRegenerateButtonClick={generateDescriptionAndSetState}
              onToneOfVoiceChange={onDescriptionToneOfVoiceChange}
              onTextLengthChange={onDescriptionTextLengthChange}
              onToggleShowRequestPrompt={
                descriptionRequestPromptText ? onToggleShowRequestPrompt : undefined
              }
              requestPromptText={descriptionRequestPromptText}
            />
          )}
        </MainStyled>
      </GenericContainerStyled>
    </>
  );
};
