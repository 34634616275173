/* eslint-disable react/jsx-curly-brace-presence */
import React, { useState, useCallback } from 'react';
import { ExportDeepTagReportArguments, MetaTypes } from 'src/services';
import {
  AvailableIcons,
  Box,
  Button,
  Checkbox,
  ConfirmationDialog,
  DialogModal,
  DialogModalContent,
  DialogModalFooter,
  DialogModalHeader,
  Icon,
  MenuItem,
  SelectType,
  Tooltip,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { BackDropWithLoader } from 'src/components-dummy/Backdrop/BackdropWithLoader';
import {
  CheckboxWithTextStyled,
  DeepTagReportExportReportCsvModalStyled,
  RegenerateCheckboxesStyled,
  SelectStyled,
  SubTitleStyled,
} from './DeepTagReportExportReportCsvModal.styles';

interface UploadFormState {
  locale: string;
}

export interface DeepTagReportExportReportCsvModalProps {
  locale: string;
  languages?: Pick<MetaTypes.Language, 'locale' | 'displayName'>[];
  isContentGenerationPermitted: boolean;
  onExportFile: ({
    locale,
    regenerateTitles,
    regenerateDescriptions,
    regenerateThematics,
  }: { locale: string } & Pick<
    ExportDeepTagReportArguments,
    'regenerateTitles' | 'regenerateDescriptions' | 'regenerateThematics'
  >) => void;
  onCancel: () => void;
}

export const DeepTagReportExportReportCsvModal = ({
  languages = [],
  locale,
  isContentGenerationPermitted,
  onExportFile,
  onCancel,
}: DeepTagReportExportReportCsvModalProps): JSX.Element => {
  const hasNoEnabledLexicons = languages.length === 0;
  const [isInProcess, setIsInProcess] = useState(false);
  const [formState, setFormState] = useState({
    locale: locale || languages?.[0]?.locale || 'en_US',
  });

  const [formData, setFormData] = useState({ title: false, description: false, thematics: false });

  const emptyLexiconsErrorMessage =
    languages.length === 0 ? 'This shop has no lexicons' : undefined;

  const disableInputs = hasNoEnabledLexicons;
  const isValid = !hasNoEnabledLexicons;

  const onFormStateChange = (partial: Partial<UploadFormState>) => {
    setFormState({ ...formState, ...partial });
  };

  const onApply = useCallback(async () => {
    try {
      setIsInProcess(true);
      await onExportFile({
        locale: formState.locale,
        regenerateTitles: formData.title,
        regenerateDescriptions: formData.description,
        regenerateThematics: formData.thematics,
      });
    } finally {
      setIsInProcess(false);
    }
  }, [onExportFile, formState.locale, formData.title, formData.description, formData.thematics]);

  const onChange = useCallback(
    (type: 'title' | 'description' | 'thematics') => () => {
      setFormData(state => ({ ...state, [type]: !state[type] }));
    },
    []
  );

  return (
    <>
      <DialogModal open onClose={onCancel}>
        <>
          {isInProcess && <BackDropWithLoader />}
          <DeepTagReportExportReportCsvModalStyled>
            <>
              <DialogModalHeader>
                <ConfirmationDialog.Title>Export to CSV</ConfirmationDialog.Title>
              </DialogModalHeader>
              <DialogModalContent>
                <SubTitleStyled>
                  <Typography
                    type={TypographyType.Paragraph}
                    variant={TypographyVariant.MediumRegular}
                  >
                    The output can be provided in each one of the languages
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
                    <Typography
                      type={TypographyType.Paragraph}
                      variant={TypographyVariant.MediumRegular}
                    >
                      defined in the Lexicon Manager
                    </Typography>
                    <Tooltip value='Note that when moving from one lexicon to another, there may be different lexicon settings that could lead to output differences. The output will include titles, generated content, and edited categories in the original locale.'>
                      <Icon name={AvailableIcons.InformationV2} />
                    </Tooltip>
                  </Box>
                </SubTitleStyled>
                <SelectStyled
                  type={SelectType.Primary}
                  value={formState.locale}
                  label='Lexicon'
                  disabled={disableInputs}
                  onChange={event => onFormStateChange({ locale: event.target.value })} // onLexiconChange(event.target.value)}
                  errorMessage={emptyLexiconsErrorMessage}
                >
                  {languages.map(language => (
                    <MenuItem key={language.locale} value={language.locale}>
                      {language.displayName}
                    </MenuItem>
                  ))}
                </SelectStyled>
                <RegenerateCheckboxesStyled>
                  {isContentGenerationPermitted && (
                    <>
                      <CheckboxWithTextStyled key='title' onClick={onChange('title')}>
                        <Checkbox checked={formData.title} />
                        <Typography
                          variant={TypographyVariant.MediumRegular}
                          type={TypographyType.Paragraph}
                        >
                          (Re)Generate all the titles upon export
                        </Typography>
                        <Tooltip
                          value='After changing the tags, it is possible to regenerate
the title manually or click here for a mass regeneration
that will consider all the changes made to the tags
(additions, deletions and/or value changes).'
                        >
                          <Icon name={AvailableIcons.InformationV2} />
                        </Tooltip>
                      </CheckboxWithTextStyled>
                      <CheckboxWithTextStyled key='description' onClick={onChange('description')}>
                        <Checkbox checked={formData.description} />
                        <Typography
                          variant={TypographyVariant.MediumRegular}
                          type={TypographyType.Paragraph}
                        >
                          (Re)Generate all the descriptions upon export
                        </Typography>
                        <Tooltip
                          value='After changing the tags, it is possible to regenerate the
                      description manually or click here for a mass regeneration
                      that will consider all the changes made to the tags
                      (additions, deletions and/or value changes).'
                        >
                          <Icon name={AvailableIcons.InformationV2} />
                        </Tooltip>
                      </CheckboxWithTextStyled>
                    </>
                  )}
                  <CheckboxWithTextStyled key='thematics' onClick={onChange('thematics')}>
                    <Checkbox checked={formData.thematics} />
                    <Typography
                      variant={TypographyVariant.MediumRegular}
                      type={TypographyType.Paragraph}
                    >
                      (Re)Generate all the thematics tags upon export
                    </Typography>
                  </CheckboxWithTextStyled>
                </RegenerateCheckboxesStyled>
              </DialogModalContent>
              <DialogModalFooter>
                <Button variant='primary' onClick={onApply} disabled={!isValid}>
                  Export File
                </Button>
                <Button variant='tertiary' onClick={onCancel}>
                  Cancel
                </Button>
              </DialogModalFooter>
            </>
          </DeepTagReportExportReportCsvModalStyled>
        </>
      </DialogModal>
    </>
  );
};
