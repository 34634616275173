import React from 'react';
import { BoughtTogetherSettingsTypes } from 'src/services';
import { diffValueDisplayHelpers } from '../value-helpers';
import { EntityDiffSchema } from '../../types';

type BoughtTogetherDiffSchema = BoughtTogetherSettingsTypes.BoughtTogetherSettings;

export const boughtTogetherDiffSchema: EntityDiffSchema<
  BoughtTogetherDiffSchema,
  BoughtTogetherSettingsTypes.BoughtTogetherSettings
> = {
  numberOfResultsToDisplay: {
    displayName: 'Number Of Results To Display',
  },
  modelType: {
    displayName: 'Model Type',
  },
  boughtTogetherFallbackMethod: {
    displayName: 'Bought Together Fallback Method',
  },
  boughtTogetherFallbackField: {
    displayName: 'Bought Together Fallback Field',
  },
  sortingOrder: {
    displayName: 'Sorting Order',
  },
  addToCart: {
    displayName: 'Add To Cart',
    getValue({
      entity,
    }: {
      entity: BoughtTogetherSettingsTypes.BoughtTogetherSettings;
    }): string | JSX.Element {
      const isActive = entity?.addToCart?.active;
      const isBundle = entity?.addToCart?.isBundle;
      return isActive ? (
        <>
          Active: {String(isActive)}
          <br />
          {isBundle ? <p>Bundle: {String(isBundle)}</p> : null}
        </>
      ) : (
        diffValueDisplayHelpers.DEFAULT_EMPTY_VALUE
      );
    },
  },
};
