import React, { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { UserProfileThumbnail } from 'src/components-bl/UserManagement/components/UserProfileThumbnail';
import { UserTypes } from 'src/services/src/service/types';
import { Button, Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import Joi, { ValidationError } from 'joi';
import { formatDateToNow } from 'src/utils/format-date-to-now';
import {
  CommentItemStyled,
  CommentItemHeaderStyled,
  CommentItemHeaderDetailsWithThumbnailStyled,
  EditableContentStyled,
  EditableContentWrapperStyled,
  CommentItemHeaderDetailsTextStyled,
  CommentItemHeaderDetailsTextTimeStyled,
  CommentItemFooterButtonsStyled,
  ErrorTextStyled,
} from './DeepTagProductComments.styles';
import { DeepTagProductCommentActionsMenu } from './DeepTagProductCommentActionsMenu';
import { formatDateTime } from './DeepTagProductComments.helpers';
import { IProductFormCommentItem } from './DeepTagProductComments.types';
import {
  COMMENT_ITEM_EDITABLE_DIV_TEST_ID,
  COMMENT_ITEM_NEW_COMMENT_BUTTON_TEST_ID,
  COMMENT_ITEM_SAVE_BUTTON_TEST_ID,
  COMMENT_ITEM_TEST_ID,
} from './DeepTagProductComments.constants';

/**
 * Validator
 */
const MAX_CHARS = 1000;
const MIN_CHARS = 1;

const textValidator = () =>
  Joi.string()
    .required()
    .trim()
    .min(MIN_CHARS)
    .max(MAX_CHARS)
    .messages({
      'string.empty': 'Comment text can not be empty',
      'string.min': `Comment text should be at least ${MIN_CHARS} characters long`,
      'string.max': `Comment text should be maximum ${MAX_CHARS} characters long`,
    });

/**
 * Main
 */
interface DeepTagProductCommentItemProps {
  currentUser: UserTypes.User;
  comment: IProductFormCommentItem;
  onCreateComment: (comment: IProductFormCommentItem) => any;
  onUpdateComment: (comment: IProductFormCommentItem) => any;
  onDeleteComment: (comment: IProductFormCommentItem) => any;
  onChange: () => void;
}

export const DeepTagProductCommentItem = ({
  currentUser,
  comment,
  onCreateComment,
  onUpdateComment,
  onDeleteComment,
  onChange,
}: DeepTagProductCommentItemProps): JSX.Element => {
  const isComponentMountedRef = useRef<boolean>(true);
  const tempText = React.useRef(''); // For edit mode
  const isNewItem = !comment.createdAt;
  const [validationErrors, setValidationErrors] = useState<ValidationError | null | undefined>(
    undefined
  );
  const [isInProcess, setIsInProcess] = useState<boolean>(false);
  const [isInEditMode, setIsInEditMode] = useState<boolean>(isNewItem);
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);

  const formattedCreatedAt = useMemo(() => {
    if (comment.createdAt) {
      const humanizedUpdatedAt = formatDateToNow(new Date(comment.createdAt));
      return `${formatDateTime(comment.createdAt)} ∙ ${humanizedUpdatedAt}`;
    }
    return undefined;
  }, [comment.createdAt]);

  const formattedUpdatedAt = useMemo(() => {
    if (comment.updatedAt && comment.createdAt !== comment.updatedAt) {
      const humanizedUpdatedAt = formatDateToNow(new Date(comment.updatedAt));
      return `Edited ${humanizedUpdatedAt}`;
    }
    return undefined;
  }, [comment.updatedAt, comment.createdAt]);

  const onMenuClicked = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setMenuAnchorElement(event.currentTarget);
    },
    [setMenuAnchorElement]
  );

  const onMenuClose = useCallback(() => {
    setMenuAnchorElement(null);
  }, [setMenuAnchorElement]);

  const validateText = useCallback(text => {
    const { error } = textValidator().validate(text, { abortEarly: false });

    setValidationErrors(error || null);
  }, []);

  const isUserComment = currentUser.userId === comment.userId;

  const handleEditText = useCallback(
    (event: ContentEditableEvent) => {
      tempText.current = event.currentTarget.textContent as string;

      validateText(tempText.current);
    },
    [validateText]
  );

  const handleCancelChanges = useCallback(() => {
    tempText.current = comment.text;
    setIsInEditMode(false);

    validateText(tempText.current);
  }, [comment.text, validateText]);

  const handleUpdateComment = async () => {
    if (comment.text === tempText.current.trim()) {
      setIsInEditMode(false);
      return;
    }

    setIsInProcess(true);

    try {
      const result = await onUpdateComment({ ...comment, text: tempText.current.trim() });

      if (!result?.error) {
        setIsInEditMode(false);
        await onChange();
      }
    } finally {
      if (isComponentMountedRef.current) {
        setIsInProcess(false);
      }
    }
  };

  const handleCreateComment = useCallback(async () => {
    if (comment.text === tempText.current.trim()) {
      setIsInEditMode(false);
      return;
    }

    setIsInProcess(true);

    try {
      const result = await onCreateComment({ ...comment, text: tempText.current.trim() });

      if (!result?.error) {
        await onChange();
      }
    } finally {
      if (isComponentMountedRef.current) {
        setIsInProcess(false);
      }
    }
  }, [comment]);

  const handleDeleteChanges = useCallback(async () => {
    const isNew = !comment.createdAt;

    if (isNew) {
      tempText.current = '';
    }

    setIsInProcess(true);

    try {
      const result = await onDeleteComment(comment);

      if (!result?.error) {
        await onChange();
      }
    } finally {
      if (isComponentMountedRef.current) {
        setIsInProcess(false);
      }
    }
  }, [comment]);

  const onToggleEditMode = useCallback(() => {
    setIsInEditMode(prevState => {
      const isInEditModeNewState = !prevState;

      if (isInEditModeNewState === false) {
        // Edit button can't exit edit mode (only from editable EditableContent - save/cancel)
        return prevState;
      }

      tempText.current = comment.text;

      return isInEditModeNewState;
    });
  }, [comment.text]);

  useEffect(() => {
    tempText.current = comment.text;
  }, [comment.text]);

  useEffect(() => {
    return () => {
      isComponentMountedRef.current = false;
    };
  }, []);

  const errorMessage = useMemo((): JSX.Element | null => {
    if (!validationErrors) return null;

    if (isNewItem && ['string.empty', 'string.min'].includes(validationErrors?.details[0].type)) {
      return null;
    }

    return <ErrorTextStyled>{validationErrors?.details[0].message}</ErrorTextStyled>;
  }, [isNewItem, validationErrors]);

  return (
    <CommentItemStyled data-testid={COMMENT_ITEM_TEST_ID}>
      <CommentItemHeaderStyled>
        <CommentItemHeaderDetailsWithThumbnailStyled>
          <UserProfileThumbnail displayName={comment.userEmail} thumbnailSize={40} />
          <CommentItemHeaderDetailsTextStyled>
            <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
              {(comment.userFullName || '').trim() || comment.userEmail}
            </Typography>
            {formattedCreatedAt && (
              <CommentItemHeaderDetailsTextTimeStyled>
                <Typography
                  type={TypographyType.Body}
                  variant={TypographyVariant.ExtraSmallRegular}
                >
                  {formattedCreatedAt}
                </Typography>
                {formattedUpdatedAt && (
                  <Typography
                    type={TypographyType.Body}
                    variant={TypographyVariant.ExtraSmallRegular}
                  >
                    {' '}
                    ∙ {formattedUpdatedAt}
                  </Typography>
                )}
              </CommentItemHeaderDetailsTextTimeStyled>
            )}
          </CommentItemHeaderDetailsTextStyled>
        </CommentItemHeaderDetailsWithThumbnailStyled>

        {/* Actions */}
        {isUserComment && !isNewItem && (
          <DeepTagProductCommentActionsMenu
            menuAnchorElement={menuAnchorElement}
            onEditClick={onToggleEditMode}
            onDeleteClick={handleDeleteChanges}
            onMenuClicked={onMenuClicked}
            onMenuClose={onMenuClose}
          />
        )}
      </CommentItemHeaderStyled>

      <EditableContentWrapperStyled isInEditMode={isInEditMode} isError={!!errorMessage}>
        <EditableContentStyled>
          <ContentEditable
            data-testid={COMMENT_ITEM_EDITABLE_DIV_TEST_ID}
            html={isInEditMode ? tempText.current : comment.text}
            placeholder={isNewItem ? 'Comment or add additional text' : ''}
            disabled={!isInEditMode}
            onChange={handleEditText}
            onKeyDown={e => {
              e.stopPropagation();
            }}
            tagName='div'
          />
        </EditableContentStyled>

        {/* Error Message */}
        {errorMessage}

        {/* Buttons */}
        {isInEditMode && (
          <CommentItemFooterButtonsStyled>
            {isNewItem ? (
              <Button
                loading={isInProcess}
                variant='primary'
                disabled={!!validationErrors || !tempText.current}
                onClick={handleCreateComment}
                size='small'
                prefixId={COMMENT_ITEM_NEW_COMMENT_BUTTON_TEST_ID}
              >
                Comment
              </Button>
            ) : (
              <>
                <Button
                  loading={isInProcess}
                  variant='primary'
                  onClick={handleUpdateComment}
                  disabled={!!validationErrors}
                  size='small'
                  prefixId={COMMENT_ITEM_SAVE_BUTTON_TEST_ID}
                >
                  Save
                </Button>
                <Button variant='secondary' onClick={handleCancelChanges} size='small'>
                  Cancel
                </Button>
              </>
            )}
          </CommentItemFooterButtonsStyled>
        )}
      </EditableContentWrapperStyled>
    </CommentItemStyled>
  );
};
