/* eslint-disable react/no-unescaped-entities */
import { css } from '@emotion/react';
import React, { useMemo } from 'react';
import {
  TypographyType,
  TypographyVariant,
  Box,
  RadioGroup,
  Typography,
} from 'src/components-dummy';
import { CatalogFormSection } from '../CatalogFormSection/CatalogFormSection';
import {
  ErrorLabelStyled,
  RadioButtonSectionStyled,
  RadioButtonsContainerStyled,
  SubTitleStyled,
} from '../../CatalogForm.styles';
import { CatalogFormDraft, SchedulingTypesEnum } from '../../CatalogForm.config';
import { CatalogFormDailyTimeSchedule } from './CatalogFormDailyTimeSchedule';
import {
  CatalogFormTimeIntervals,
  DEFAULT_TIME_INTERVALS_IN_HOURS,
  DEFAULT_TIME_INTERVALS_IN_MINUTES,
} from './CatalogFormTimeIntervals';
import { buildDefaultScheduling } from '../../CatalogForm.mappers';

export interface CatalogFormSchedulingSectionProps {
  onFieldChange: (partialData: Partial<CatalogFormDraft>) => void;
  selectedScheduleType: SchedulingTypesEnum;
  scheduling?: CatalogFormDraft['scheduling'];
  errors: any;
}

export const CatalogFormSchedulingSection = React.memo(
  ({
    onFieldChange,
    selectedScheduleType = SchedulingTypesEnum.daily_time,
    scheduling,
    errors,
  }: CatalogFormSchedulingSectionProps): JSX.Element => {
    const errorsMessagesList = useMemo(() => {
      return Object.keys(errors).map(errorKey => {
        const error = errors[errorKey];
        return <ErrorLabelStyled key={errorKey}> {error.message} </ErrorLabelStyled>;
      });
    }, [errors]);

    const onRadioButtonChange = (type: SchedulingTypesEnum) => {
      switch (type) {
        case SchedulingTypesEnum.daily_time: {
          onFieldChange({
            selectedScheduleType: type,
            scheduling: buildDefaultScheduling(),
          });
          break;
        }
        case SchedulingTypesEnum.time_intervals: {
          onFieldChange({
            selectedScheduleType: type,
            scheduling: {
              timeIntervals: {
                hours: DEFAULT_TIME_INTERVALS_IN_HOURS,
                minutes: DEFAULT_TIME_INTERVALS_IN_MINUTES,
              },
            },
          });
          break;
        }
        case SchedulingTypesEnum.none:
        default: {
          onFieldChange({ selectedScheduleType: type, scheduling: undefined });
        }
      }
    };

    return (
      <CatalogFormSection>
        <CatalogFormSection.Header>Define Feed Schedule</CatalogFormSection.Header>
        {!!errorsMessagesList.length && errorsMessagesList}
        <SubTitleStyled
          css={css`
            margin-bottom: 6px;
          `}
          type={TypographyType.Body}
          variant={TypographyVariant.MediumRegular}
        >
          Set up the schedule in which Syte will pull product updates to your catalog
        </SubTitleStyled>
        <RadioButtonsContainerStyled>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {/** ********************* Daily Time ************************** */}
            <RadioButtonSectionStyled>
              <RadioGroup
                selectedValue={selectedScheduleType}
                onChange={onRadioButtonChange}
                disabled={false}
                key={SchedulingTypesEnum.daily_time}
                options={[
                  {
                    value: SchedulingTypesEnum.daily_time,
                    label: (
                      <Typography
                        type={TypographyType.Body}
                        variant={TypographyVariant.MediumRegular}
                      >
                        Run Daily, at
                      </Typography>
                    ),
                  },
                ]}
              />
              {selectedScheduleType === SchedulingTypesEnum.daily_time && (
                <CatalogFormDailyTimeSchedule
                  dailyTime={scheduling?.dailyTime}
                  onFieldChange={onFieldChange}
                />
              )}
            </RadioButtonSectionStyled>
            {/** ********************* Time Intervals ************************** */}
            <RadioButtonSectionStyled>
              <RadioGroup
                selectedValue={selectedScheduleType}
                onChange={onRadioButtonChange}
                disabled={false}
                key={SchedulingTypesEnum.time_intervals}
                options={[
                  {
                    value: SchedulingTypesEnum.time_intervals,
                    label: (
                      <Typography
                        type={TypographyType.Body}
                        variant={TypographyVariant.MediumRegular}
                      >
                        Run Hourly, every
                      </Typography>
                    ),
                  },
                ]}
              />

              {selectedScheduleType === SchedulingTypesEnum.time_intervals && (
                <CatalogFormTimeIntervals
                  timeIntervals={scheduling?.timeIntervals}
                  onFieldChange={onFieldChange}
                  errors={errors}
                />
              )}
            </RadioButtonSectionStyled>
            {/** ********************* Not scheduled ************************** */}
            <RadioButtonSectionStyled>
              <RadioGroup
                selectedValue={selectedScheduleType}
                onChange={onRadioButtonChange}
                disabled={false}
                key={SchedulingTypesEnum.none}
                options={[
                  {
                    value: SchedulingTypesEnum.none,
                    label: (
                      <Typography
                        type={TypographyType.Body}
                        variant={TypographyVariant.MediumRegular}
                      >
                        Run once
                      </Typography>
                    ),
                  },
                ]}
              />
            </RadioButtonSectionStyled>
          </Box>
        </RadioButtonsContainerStyled>
      </CatalogFormSection>
    );
  }
);
