import React from 'react';
import {
  AvailableIcons,
  EllipsisWithTooltip,
  Page,
  SkeletonHorizontalBlocks,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { ICatalogFeedUploadLog } from 'src/services/src/service/types/catalog-manager/catalog-manager';
import { BackIconStyled } from 'src/components-bl/MerchandisingRules/components/EditMerchandisingRulePage/EditMerchandisingRulePage.style';
import {
  TitleContextStyled,
  TitleMainStyled,
} from 'src/components-bl/CatalogManager/CatalogManager.styles';
import { Dispatch } from 'src/components-bl/types';
import { CatalogFeedUploadLogList } from '../CatalogFeedUploadLogList/CatalogFeedUploadLogList';
import { PageStyled, PageTitlesWrapper } from './CatalogFeedUploadLogPage.styles';

export interface CatalogFeedUploadLogPageProps {
  catalogFeedUploadLogs?: ICatalogFeedUploadLog[];
  navigateToFeedErrorReport: (startDateInMilliseconds: string) => void;
  navigateToCatalogsList: () => void;
  isAdminUser: boolean;
  catalogName: string;
  shopId: number;
  dispatch: Dispatch;
}

export const CatalogFeedUploadLogPage = ({
  catalogFeedUploadLogs,
  navigateToFeedErrorReport,
  navigateToCatalogsList,
  isAdminUser,
  catalogName,
  shopId,
  dispatch,
}: CatalogFeedUploadLogPageProps): JSX.Element => {
  const contextPreTitle = 'Catalog Manager > ';
  const mainTitleText = `Feed Uploads Log - ${catalogName}`;

  const fullTitleText = contextPreTitle + mainTitleText;

  return (
    <PageStyled>
      <Page.Header>
        <PageTitlesWrapper>
          <BackIconStyled name={AvailableIcons.ArrowRounded} onClick={navigateToCatalogsList} />
          <EllipsisWithTooltip tooltipText={fullTitleText} key={Math.random()}>
            <TitleMainStyled
              type={TypographyType.Heading}
              variant={TypographyVariant.ExtraSmallBold}
              className='syte-page-title'
            >
              <TitleContextStyled>{contextPreTitle}</TitleContextStyled>
              {mainTitleText}
            </TitleMainStyled>
          </EllipsisWithTooltip>
        </PageTitlesWrapper>
      </Page.Header>
      <Page.Content>
        {catalogFeedUploadLogs ? (
          <CatalogFeedUploadLogList
            catalogFeedUploadLogs={catalogFeedUploadLogs}
            navigateToFeedErrorReport={navigateToFeedErrorReport}
            isAdminUser={isAdminUser}
            dispatch={dispatch}
            shopId={shopId}
            catalogName={catalogName}
          />
        ) : (
          <SkeletonHorizontalBlocks />
        )}
      </Page.Content>
    </PageStyled>
  );
};
