import { parseISO } from 'date-fns';

type ParseOptions = Parameters<typeof parseISO>[1];

/** parseISO that also supports undefined so no need to check for undefined from the caller.
 * return type is Date if input is string, and Date | undefined if input is undefined.
 * uses multiple overloads for typing.
 *  */
export function parseISODate(dateString: string, options?: ParseOptions): Date;
export function parseISODate(
  dateString: string | undefined,
  options?: ParseOptions
): Date | undefined;
export function parseISODate(
  dateString: string | undefined,
  options?: ParseOptions
): Date | undefined {
  return dateString ? parseISO(dateString, options) : undefined;
}
