import styled from '@emotion/styled';
import { AutoCompleteSingleValue, PopUp } from 'src/components-dummy';

export const SkuAutoSuggestionStyled = styled.div`
  .syte-popup-trigger {
    width: 100%;
  }

  max-width: 100%;
  width: 100% !important;
  min-width: 35%;
`;

export const ImageStyled = styled.img`
  height: 100%;
`;

export const StartAdornmentStyled = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AutoCompleteStyled = styled(AutoCompleteSingleValue)`
  width: 100%;
`;

export const PopUpStyled = styled(PopUp)`
  &:before {
    display: none;
  }
`;
