import React, { useCallback, useEffect } from 'react';
import { generatePath } from 'react-router';
import { CustomInspirationsGallery, GalleryType, InspirationalImagesGallery } from 'src/services';
import { Dispatch } from 'src/components-bl/types';
import { RoutedComponentProps } from 'src/app-routes';
import { Button, Page } from 'src/components-dummy';
import { GalleryBreadCrumbs } from '../GalleryBreadCrumbs';
import { GalleriesTable, GalleriesTableSkeleton } from './components';
import { galleriesTablePageActions } from './GalleriesTablePage.actions';
import { GalleriesTablePageStyled, PageTitlesWrapper } from './GalleriesTablePage.styles';

interface GalleriesTablePageProps extends RoutedComponentProps {
  shopId: number;
  galleries: (InspirationalImagesGallery | CustomInspirationsGallery)[] | undefined;
  dispatch: Dispatch;
}

export const GalleriesTablePage = ({
  shopId,
  galleries,
  dispatch,
  permittedRouteMap,
}: GalleriesTablePageProps): JSX.Element => {
  const isLoadingGalleries = galleries === undefined;
  const hasGalleries = !!galleries && galleries.length > 0;

  const onCreateGalleryClick = useCallback(() => {
    if (!permittedRouteMap.createGallery) return;

    dispatch(
      galleriesTablePageActions.navigateTo({
        navigateTo: generatePath(permittedRouteMap.createGallery.path, { shopId }),
      })
    );
  }, [shopId, permittedRouteMap, dispatch]);

  const navigateToViewInspirationImagesGallery = useCallback(
    (galleryId: string) => {
      if (!permittedRouteMap.viewInspirationalGallery) {
        return;
      }

      dispatch(
        galleriesTablePageActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.viewInspirationalGallery.path, {
            shopId,
            galleryId,
          }),
        })
      );
    },
    [dispatch, shopId, permittedRouteMap.viewInspirationalGallery]
  );

  const navigateToEditInspirationImagesGallery = useCallback(
    (galleryId: string) => {
      if (!permittedRouteMap.editInspirationalGallery) {
        return;
      }

      dispatch(
        galleriesTablePageActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.editInspirationalGallery.path, {
            shopId,
            galleryId,
          }),
        })
      );
    },
    [dispatch, shopId, permittedRouteMap.editInspirationalGallery]
  );

  const navigateToEditCustomInspirationsGallery = useCallback(
    (galleryId: string) => {
      if (!permittedRouteMap.editCustomInspirationsGallery) {
        return;
      }

      dispatch(
        galleriesTablePageActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.editCustomInspirationsGallery.path, {
            shopId,
            galleryId,
          }),
        })
      );
    },
    [dispatch, shopId, permittedRouteMap.editCustomInspirationsGallery]
  );

  const onEditGalleryClick = useCallback(
    (galleryId: string, galleryType: GalleryType) => {
      if (galleryType === GalleryType.InspirationalImage) {
        navigateToEditInspirationImagesGallery(galleryId);
      } else if (galleryType === GalleryType.CustomInspirations) {
        navigateToEditCustomInspirationsGallery(galleryId);
      }
    },
    [navigateToEditInspirationImagesGallery, navigateToEditCustomInspirationsGallery]
  );

  const onViewGalleryClick = useCallback(
    (galleryId: string, galleryType: GalleryType) => {
      if (galleryType === GalleryType.InspirationalImage) {
        navigateToViewInspirationImagesGallery(galleryId);
      } else if (galleryType === GalleryType.CustomInspirations) {
        navigateToEditCustomInspirationsGallery(galleryId);
      }
    },
    [navigateToViewInspirationImagesGallery, navigateToEditCustomInspirationsGallery]
  );

  const onDeleteGallery = useCallback(
    (galleryId: string, galleryType: GalleryType) => {
      dispatch(galleriesTablePageActions.deleteGallery({ shopId, galleryId, galleryType }));
    },
    [shopId, dispatch]
  );

  useEffect(() => {
    dispatch(galleriesTablePageActions.getGalleries({ shopId }));
  }, [dispatch, shopId]);

  return (
    <GalleriesTablePageStyled>
      <Page.Header>
        <PageTitlesWrapper>
          <GalleryBreadCrumbs
            shopId={shopId}
            permittedRouteMap={permittedRouteMap}
            dispatch={dispatch}
          >
            Shoppable Galleries
          </GalleryBreadCrumbs>
          {hasGalleries && (
            <Page.SubTitle>Manage and review your galleries setup and health. </Page.SubTitle>
          )}
        </PageTitlesWrapper>

        {hasGalleries && (
          <Button variant='primary' onClick={onCreateGalleryClick}>
            Create New Gallery
          </Button>
        )}
      </Page.Header>
      <Page.Content>
        {isLoadingGalleries ? (
          <GalleriesTableSkeleton />
        ) : (
          <GalleriesTable
            galleries={galleries}
            navigateToCreateGalleryPage={onCreateGalleryClick}
            navigateToEditGalleryPage={onEditGalleryClick}
            deleteGallery={onDeleteGallery}
            navigateToGalleryPage={onViewGalleryClick}
          />
        )}
      </Page.Content>
    </GalleriesTablePageStyled>
  );
};
