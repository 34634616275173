import {
  ValidatorsDict,
  ValidatorSuite,
  BasicErrorsStructure,
  GenericExtendsFallback,
} from './types';
import { requiredValidator } from './required-validator';

export class BasicValidator<T extends GenericExtendsFallback> {
  validatorsDict: ValidatorsDict<T>;

  constructor(validatorsDict: ValidatorsDict<T>) {
    this.validatorsDict = validatorsDict;
  }

  validateProperty(property: keyof T, val: unknown): BasicErrorsStructure {
    const validators: ValidatorSuite = this.validatorsDict[property];
    const errors: BasicErrorsStructure = {};
    if (!validators) return errors;
    if (validators.isRequired) {
      errors.required = requiredValidator.testIsValid(val)
        ? undefined
        : requiredValidator.errorMsg();
    }
    if (validators.tests.length) {
      const valueValidationErrors: string[] = [];
      validators.tests.forEach(validator => {
        if (!validator.testIsValid(val)) {
          valueValidationErrors.push(validator.errorMsg());
        }
      });
      errors.valueValidation = valueValidationErrors.length ? valueValidationErrors : undefined;
    }
    return errors;
  }
}
