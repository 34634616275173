import { AxiosResponse } from 'axios';
import { MetaTypes } from '../types';

export const metaMapper = {
  mapLanguages({ data }: AxiosResponse): MetaTypes.Language[] {
    let regionDisplayNameTranslator: any;
    let languageDisplayNameTranslator: any;
    try {
      regionDisplayNameTranslator = new (Intl as any).DisplayNames(['en'], { type: 'region' }); // typescript doesn't recognize Intl
      languageDisplayNameTranslator = new (Intl as any).DisplayNames(['en'], {
        type: 'language',
      });
    } catch (error) {
      console.error(error);
    }

    const mappedLanguages =
      data.languages?.map((syteLanguage: MetaTypes.Language) => {
        const [languagePart, regionPart] = syteLanguage.locale?.split('_');
        const fallBackName = syteLanguage.displayName;
        let displayName: string;
        try {
          const regionName = regionDisplayNameTranslator?.of(regionPart);
          const languageName = languageDisplayNameTranslator?.of(languagePart);
          displayName =
            languageName && regionName ? `${languageName} - ${regionName}` : fallBackName;
        } catch (error) {
          console.error(error);
          displayName = fallBackName;
        }

        return {
          ...syteLanguage,
          displayName,
        };
      }) || [];

    return mappedLanguages;
  },
  mapSyteProductTypes({ data }: AxiosResponse): MetaTypes.MetaSyteProductType[] {
    return data.syteProducts || [];
  },
  mapPublicMetadata({ data }: AxiosResponse): MetaTypes.IMetaPublic {
    return data;
  },
};
