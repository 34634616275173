import { DeepTagReportFiltersState } from 'src/components-bl/DeepTagReports/components/DeepTagReportFilters/types';
import { getAppliedFiltersWithValue } from 'src/components-dummy';
import { ProductRouteFilters } from './components/DeepTagReportProductsContainer/components/types';

export const getCleanedFiltersWithPagination = ({
  paginationFilters,
  filters,
}: {
  paginationFilters: { skip?: number; limit?: number };
  filters: DeepTagReportFiltersState;
}): ProductRouteFilters => {
  const filtersWithPagination = {
    ...filters,
    skip: { value: paginationFilters.skip, isApplied: true },
    limit: { value: paginationFilters.limit, isApplied: true },
  };

  return getAppliedFiltersWithValue(filtersWithPagination);
};
