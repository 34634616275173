import React from 'react';
import './InfoScreen.scss';

import { ContentType } from '../../types';
import { Spinner } from '../../../../components-dummy';
import { SelectShopperExperience } from './SelectShopperExperience';
import { NoActiveCollection } from './NoActiveCollection';
import { Collection } from '../../../Collections';
import { Layout } from '../../consts/layout';
import { NoResults } from './NoResults';

interface InfoScreenProps {
  shopId: number;
  loading: boolean;
  contentType: ContentType;
  collection: Collection | null;
  layout: Layout;
  onLayoutChange: (view: Layout) => void;
  onRefresh?: VoidFunction;
  itemsCount: number;
  totalItemsCount: number;
  disableRefresh: boolean;
  shouldDisplayItemsCount: boolean;
}

export const InfoScreen = ({
  shopId,
  loading,
  contentType,
  collection,
  layout,
  onLayoutChange,
  onRefresh,
  itemsCount,
  totalItemsCount,
  disableRefresh,
  shouldDisplayItemsCount,
}: InfoScreenProps): JSX.Element => {
  if (loading) {
    return <Spinner />;
  }

  switch (contentType) {
    case ContentType.NoActiveCollection:
      return <NoActiveCollection shopId={shopId} />;
    case ContentType.NoResults:
      return (
        <NoResults
          shopId={shopId}
          layout={layout}
          onRefresh={onRefresh}
          itemsCount={itemsCount}
          totalItemsCount={totalItemsCount}
          disableRefresh={disableRefresh}
          onLayoutChange={onLayoutChange}
          collection={collection}
          shouldDisplayItemsCount={shouldDisplayItemsCount}
        />
      );
    case ContentType.SelectExperience:
    default:
      return <SelectShopperExperience />;
  }
};
