import React from 'react';
import { Button } from 'src/components-dummy/Button';
import { ConfirmationDialog } from 'src/components-dummy/ConfirmationDialog';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';

interface DeleteCatalogConfirmationDialogProps {
  onCloseDeleteDialog: () => void;
  onDelete: () => void;
}

export const DeleteCatalogConfirmationDialog = ({
  onCloseDeleteDialog,
  onDelete,
}: DeleteCatalogConfirmationDialogProps): JSX.Element => {
  return (
    <ConfirmationDialog onCancel={onCloseDeleteDialog}>
      <ConfirmationDialog.Header>
        <ConfirmationDialog.Title>Delete Catalog</ConfirmationDialog.Title>
      </ConfirmationDialog.Header>
      <ConfirmationDialog.Content>
        Are you sure you want to delete this catalog?
      </ConfirmationDialog.Content>
      <ConfirmationDialog.Footer>
        <ButtonsContainer>
          <Button variant='destructive' onClick={onDelete}>
            Yes, Delete Catalog
          </Button>
          <Button variant='outlined' onClick={onCloseDeleteDialog}>
            No, Go Back
          </Button>
        </ButtonsContainer>
      </ConfirmationDialog.Footer>
    </ConfirmationDialog>
  );
};
