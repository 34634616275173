import React from 'react';
import { useTheme } from '@emotion/react';
import {
  SelectType,
  MenuItem,
  SelectOnChangeEvent,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { DeepTagProductStatusType } from 'src/services';
import { DeepTagProductStatusSelectStyled } from './DeepTagProductStatusSelect.styles';
import { getColorByStatus, statusDropDownValues } from './DeepTagProductStatusSelect.config';

interface DeepTagProductStatusSelectProps {
  value: DeepTagProductStatusType;
  showText?: boolean;
  onChange: SelectOnChangeEvent;
  MenuProps?: unknown;
  isDisabled?: boolean;
}

export const DeepTagProductStatusSelect = ({
  value,
  showText,
  onChange,
  MenuProps,
  isDisabled = false,
}: DeepTagProductStatusSelectProps): JSX.Element => {
  const shouldShowText = showText !== undefined ? showText : true;

  const theme = useTheme();

  const selectedColor = getColorByStatus(theme, value);

  return (
    <DeepTagProductStatusSelectStyled
      value={shouldShowText ? value : ''}
      placeholder=''
      color={selectedColor}
      type={SelectType.Menu}
      onChange={onChange}
      MenuProps={MenuProps}
      disabled={isDisabled}
    >
      {statusDropDownValues.map((option: { value?: string; text: string }) => (
        <MenuItem key={option.value} value={option.value}>
          <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
            {option.text}
          </Typography>
        </MenuItem>
      ))}
    </DeepTagProductStatusSelectStyled>
  );
};
