import React, { MouseEvent, useCallback } from 'react';
import {
  EllipsisMenuButton,
  Menu,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { MenuActionsStyled, MenuItemStyled } from './DeepTagProductComments.styles';
import { COMMENT_ITEM_TEST_ID } from './DeepTagProductComments.constants';

export const MENU_ACTIONS_TEST_ID = `${COMMENT_ITEM_TEST_ID}-actions`;
export const MENU_ACTIONS_DELETE_TEST_ID = `${MENU_ACTIONS_TEST_ID}-delete`;
export const MENU_ACTIONS_EDIT_TEST_ID = `${MENU_ACTIONS_TEST_ID}-edit`;

interface DeepTagProductCommentActionsMenuProps {
  menuAnchorElement: HTMLElement | null;
  onMenuClicked: (event: MouseEvent<HTMLElement>) => void;
  onMenuClose: () => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
}

export const DeepTagProductCommentActionsMenu = ({
  menuAnchorElement,
  onMenuClicked,
  onMenuClose,
  onEditClick,
  onDeleteClick,
}: DeepTagProductCommentActionsMenuProps): JSX.Element => {
  const isOpen = !!menuAnchorElement;

  const onItemClick = useCallback(
    onClick => {
      onClick();
      onMenuClose();
    },
    [onMenuClose]
  );

  return (
    <MenuActionsStyled data-testid={MENU_ACTIONS_TEST_ID}>
      <EllipsisMenuButton onClick={onMenuClicked} isActive={isOpen} />
      <Menu
        open={isOpen}
        anchorEl={menuAnchorElement}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: 'right',
        }}
      >
        {!!onEditClick && (
          <MenuItemStyled
            data-testid={MENU_ACTIONS_EDIT_TEST_ID}
            key='edit'
            onClick={() => onItemClick(onEditClick)}
          >
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Edit
            </Typography>
          </MenuItemStyled>
        )}
        {!!onDeleteClick && (
          <MenuItemStyled
            data-testid={MENU_ACTIONS_DELETE_TEST_ID}
            key='delete'
            onClick={() => onItemClick(onDeleteClick)}
          >
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Delete
            </Typography>
          </MenuItemStyled>
        )}
      </Menu>
    </MenuActionsStyled>
  );
};
