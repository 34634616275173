import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { Button } from 'src/components-dummy';
import { useIsInViewPort } from 'src/hooks';
import { dataFieldsService } from 'src/services';
import {
  BackdropStyled,
  DataFieldsValuesListStyled,
  NotFoundTypography,
  SearchInputStyled,
  SpinnerStyled,
  ValuesFooterStyled,
  ValuesWrapper,
} from './DataFieldValuesList.styles';
import { DataFieldMultiSelectMenu } from './DataFieldMultiSelectMenu';
import { DataFieldSingleMenuList } from './DataFieldSingleMenuList';

const NotFound = (): JSX.Element => {
  return <NotFoundTypography>Could not find any values</NotFoundTypography>;
};

interface DataFieldsValuesListProps {
  dataField: string;
  shopId: number;
  onSelect: (selectedValues: string[]) => void;
  onCancel: () => void;
  selectedValues: string[];
  isMultiSelect?: boolean;
  allowFreeText?: boolean;
}

const PAGE_SIZE = 50;

const debouncedMethodCall = debounce(func => {
  func();
}, 500);

const SEARCH_TEXT_OPTION_PREFIX = 'searchText.';

const getSearchTextOptionValue = (value: string) => `${SEARCH_TEXT_OPTION_PREFIX}${value}`;

const isSearchTextOptionValue = (value: string) => value.includes(SEARCH_TEXT_OPTION_PREFIX);

export const DataFieldsValuesList = ({
  dataField,
  shopId,
  onSelect,
  onCancel,
  selectedValues: skipValues,
  isMultiSelect = false,
  allowFreeText = false,
}: DataFieldsValuesListProps): JSX.Element => {
  const fetchValuesCancellationRef = useRef(null as AbortController | null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const { isInView, setRef } = useIsInViewPort({});

  const [searchText, setSearchText] = useState('');
  const [values, setValues] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentAfter, setCurrentAfter] = useState<Record<string, string> | undefined>(undefined);
  const [needToLoadMore, setNeedToLoadMore] = useState(true);

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const showNotFoundIndication = useMemo(
    () => values.length === 0 && isLoading === false && searchText.length > 0,
    [values, isLoading, searchText]
  );

  const onValuesSelectChange = useCallback(
    (newSelectedValues: string[]) => {
      if (isMultiSelect) {
        const selectedValuesWithCurrentSearchText = newSelectedValues.filter(value => {
          return !isSearchTextOptionValue(value) || value === getSearchTextOptionValue(searchText);
        });
        setSelectedValues(selectedValuesWithCurrentSearchText);
      } else {
        onSelect(newSelectedValues);
      }
    },
    [isMultiSelect, searchText, onSelect, setSelectedValues]
  );

  const onSave = useCallback(() => {
    const selectedValuesMapped = selectedValues.map(value =>
      isSearchTextOptionValue(value) ? value.slice(SEARCH_TEXT_OPTION_PREFIX.length) : value
    );
    onSelect([...skipValues, ...selectedValuesMapped]);
  }, [onSelect, skipValues, selectedValues]);

  const hasSearchValue = useMemo(() => searchText.length > 0, [searchText]);

  const valuesOptionsMapped = useMemo(() => {
    const searchResultsValuesMapped = values.map(value => ({
      text: value,
      value,
    }));

    const freeTextValueMapped =
      allowFreeText && isMultiSelect && hasSearchValue
        ? [{ text: `Select: ${searchText}`, value: getSearchTextOptionValue(searchText) }]
        : [];

    return [...freeTextValueMapped, ...searchResultsValuesMapped];
  }, [isMultiSelect, hasSearchValue, searchText, values]);

  const cancelFetchValues = () => {
    if (!fetchValuesCancellationRef.current) {
      return;
    }

    try {
      fetchValuesCancellationRef.current.abort();
    } catch (error) {
      console.error(error);
    } finally {
      fetchValuesCancellationRef.current = null;
    }
  };

  const loadDataFields = useCallback(
    ({
      after = currentAfter,
      shouldLoadMore = needToLoadMore,
      previousValues = values,
    }: {
      after?: Record<string, string>;
      shouldLoadMore?: boolean;
      previousValues?: string[];
    }) => {
      debouncedMethodCall(async () => {
        if (!shouldLoadMore) {
          return;
        }

        setIsLoading(true);
        cancelFetchValues();

        fetchValuesCancellationRef.current = new AbortController();

        try {
          const { values: newDataFieldValues, after: newAfter } =
            await dataFieldsService.searchFeedDataFieldValues({
              shopId,
              limit: PAGE_SIZE,
              after,
              searchTerm: searchText.trim(),
              omitValues: skipValues,
              primaryDataField: dataField,
              cancellationSignal: fetchValuesCancellationRef.current.signal,
            });
          const updatedState = [
            ...previousValues,
            ...newDataFieldValues.map(dataFieldValue => dataFieldValue[dataField]).filter(Boolean),
          ];

          setValues(updatedState);

          const hasReachedEnd = newDataFieldValues.length === 0;
          setNeedToLoadMore(!hasReachedEnd);
          setCurrentAfter(newAfter);
        } catch (error) {
          console.error(error);
        }
        setIsLoading(false);
      });
    },
    [
      values,
      setIsLoading,
      setValues,
      shopId,
      dataField,
      skipValues,
      searchText,
      needToLoadMore,
      currentAfter,
      setCurrentAfter,
      setNeedToLoadMore,
    ]
  );

  useEffect(() => {
    setSearchText('');
    setSelectedValues([]);
  }, [dataField]);

  useEffect(() => {
    setValues([]);
    setNeedToLoadMore(true);
    setCurrentAfter(undefined);

    loadDataFields({
      after: undefined,
      shouldLoadMore: true,
      previousValues: [],
    });
  }, [searchText, setNeedToLoadMore, setCurrentAfter, setValues]);

  useEffect(() => {
    if (isInView) {
      loadDataFields?.({});
    }
  }, [isInView]);

  useEffect(() => {
    searchInputRef.current?.focus();

    return () => cancelFetchValues();
  }, []);

  return (
    <DataFieldsValuesListStyled>
      <SearchInputStyled
        value={searchText}
        onChange={setSearchText}
        placeholder='Search value'
        className='data-fields-values-search-input'
        inputRef={searchInputRef}
      />
      {isLoading && <SpinnerStyled />}

      <ValuesWrapper>
        {isMultiSelect ? (
          <DataFieldMultiSelectMenu
            allowFreeText={allowFreeText}
            hasSearchValue={hasSearchValue}
            options={valuesOptionsMapped}
            selectedOptions={selectedValues}
            onChange={onValuesSelectChange}
            setRef={setRef}
          />
        ) : (
          <DataFieldSingleMenuList
            options={valuesOptionsMapped}
            onChange={onValuesSelectChange}
            setRef={setRef}
          />
        )}
        {showNotFoundIndication && <NotFound />}
        {isLoading && <BackdropStyled />}
      </ValuesWrapper>
      {isMultiSelect && (
        <ValuesFooterStyled>
          <Button variant='secondary' onClick={onCancel}>
            Cancel
          </Button>
          <Button variant='primary' disabled={selectedValues.length === 0} onClick={onSave}>
            Ok
          </Button>
        </ValuesFooterStyled>
      )}
    </DataFieldsValuesListStyled>
  );
};
