import React from 'react';
import { format as doFormat } from 'date-fns';
import { Dialog, Button } from 'src/components-dummy';
import { Card } from 'src/components-dummy/Card';
import type { Dispatch } from '../../types';
import { UiTemplate } from '../types';
import { Cell, Menu } from './components';
import { ColumnName } from './types';
import { useLogic } from './useLogic';
import './Item.scss';

interface Props {
  template: UiTemplate;
  dispatch: Dispatch;
  openPopup: () => void;
}

const dateFormat = 'dd-MM-yyyy HH:mm';

function format(date: Date | null) {
  return date ? doFormat(date, dateFormat) : null;
}

export const Item: React.FC<Props> = ({ template, dispatch, openPopup }) => {
  const { ref, showDeleteDialog, closeDeleteDialog, onDeleteClicked, onDelete, onEditClicked } =
    useLogic<HTMLDivElement>({
      template,
      dispatch,
      openPopup,
    });

  const onConfirmDelete = (): void => {
    onDelete();
    closeDeleteDialog();
  };

  return (
    <Card className='ui-template-card'>
      <div className='ui-template-card-content' ref={ref}>
        <Cell title={ColumnName.Name} value={template.name} />
        <Cell title={ColumnName.Created} value={format(template.createdAt)} />
        <Cell title={ColumnName.LastModified} value={format(template.updatedAt)} />
        <Menu onDeleteClicked={onDeleteClicked} onEditClicked={onEditClicked} />
        <Dialog show={showDeleteDialog} onClose={closeDeleteDialog}>
          <Dialog.Header>Attention!</Dialog.Header>
          <Dialog.Body>
            Are you sure you want to delete the template &apos;{template.name}&apos;?
            <br />
            This template is published in production and this action could affect it.
          </Dialog.Body>
          <Dialog.ActionsFooter>
            <Button variant='primary' onClick={onConfirmDelete}>
              Confirm
            </Button>
            <Button variant='secondary' onClick={closeDeleteDialog}>
              Cancel
            </Button>
          </Dialog.ActionsFooter>
        </Dialog>
      </div>
    </Card>
  );
};
