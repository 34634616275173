import { ShopTheLookSettingsTypes } from '../types';

export const buildShopTheLookSettingsPayload = (
  payload: Partial<ShopTheLookSettingsTypes.ShopTheLookSettings>
): Partial<ShopTheLookSettingsTypes.ShopTheLookSettings> => {
  const payloadData = {
    numberOfResultsToDisplay: payload.numberOfResultsToDisplay,
    includeMainProductInCarousel: payload.includeMainProductInCarousel,
    shuffleResults: payload.shuffleResults,
    completeTheLookFallbackMethod: payload.completeTheLookFallbackMethod,
    addToCart: payload.addToCart,
  };

  return payloadData;
};
