import { PersonalizationSettingsTypes } from 'src/services';
import { DomainForRendering } from '../../types';
import { personalizationDiffSchema } from './personalization-settings-diff-schema';

export const personalizationSettingsSubDomainGroup: DomainForRendering<PersonalizationSettingsTypes.PersonalizationSettings> =
  {
    getCardTitle: () => {
      return 'General Settings';
    },
    fieldsSchema: personalizationDiffSchema,
  };
