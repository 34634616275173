import React, { useState, useMemo } from 'react';
import './Table.scss';
import classNames from 'classnames';
import {
  useTable,
  useAsyncDebounce,
  useSortBy,
  useGlobalFilter,
  UseFiltersColumnProps,
  useFilters,
  useFlexLayout,
  useResizeColumns,
  HeaderProps,
  Row,
} from 'react-table';
import { TextBox } from '../TextBox';
import { ActionIcon } from '../ActionIcon';
import { useFindComponentByType, useFindComponentsByType } from '../hooks';
import { textFilter, fuzzyTextFilter, numericTextFilter } from './filters';
import { TableProps, TableInstance, GlobalFilterProps } from './types';
import { EmptyTable, EditableTextBoxCell, Column, CustomCell } from './Components';
import { AvailableIcons } from '../Icon';

const DefaultHeader = <T extends Record<string, unknown>>({
  column,
}: HeaderProps<T>): JSX.Element => <>{column.id && column.id}</>;

export const GlobalFilter = <T extends Record<string, unknown>>({
  preGlobalFilteredRows,
  value,
  setGlobalFilter,
}: GlobalFilterProps<T>): JSX.Element => {
  const count = preGlobalFilteredRows.length;
  const [currentValue, setCurrentValue] = useState(value);
  const onChange = useAsyncDebounce(text => {
    setGlobalFilter(text || undefined);
  }, 200);

  return (
    <div className='syte-table-filter-global'>
      <TextBox
        placeholder={`Search ${count} records`}
        value={currentValue ?? ''}
        onChange={text => {
          setCurrentValue(text);
          onChange(text);
        }}
      />
    </div>
  );
};

// default UI for filtering
export const DefaultColumnFilter = <T extends Record<string, unknown>>({
  filterValue,
  preFilteredRows,
  setFilter,
}: UseFiltersColumnProps<T>): JSX.Element => {
  const count = preFilteredRows.length;

  const onChange = useAsyncDebounce(text => {
    setFilter(text || undefined);
  }, 200);

  return (
    <div className='syte-table-filter-column-text'>
      <TextBox
        placeholder={`Search ${count} records`}
        value={filterValue}
        onChange={text => {
          onChange(text);
          setFilter(text);
        }}
      />
    </div>
  );
};

const defaultPropGetter = () => ({});

export const Table = <T extends Record<string, unknown>>({
  data,
  className,
  children = [],
  updateData,
  disableFilters = true,
  disableGlobalFilter = true,
  disableSortBy = true,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  rowRef,
  enableRowDelete = false,
  defaultCanSort = false,
  enableResizing = false,
  disabled = false,
  setDataState,
}: TableProps<T>): JSX.Element => {
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilter,
      numeric: numericTextFilter,
      text: textFilter,
    }),
    []
  );

  const deleteRow = ({ rowIndex, tableData }: { rowIndex: number; tableData: T[] }) => {
    const old = [...tableData];
    old.splice(rowIndex, 1);
    setDataState(old);
  };

  const defaultColumn: Record<string, unknown> = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      Header: DefaultHeader,
      Cell: EditableTextBoxCell,
      disableFilters: true,
    }),
    []
  );

  const combineColumnAndCellProps = (child: JSX.Element) => {
    let props = { ...child.props };
    if (child.props.children) {
      const parentChildren = (child.props.children.length && child.props.children) || [
        child.props.children,
      ];
      const customCell = useFindComponentByType({ type: CustomCell, children: parentChildren });
      if (customCell) {
        props = { ...props, Cell: (d: any) => customCell.props.children(d) };
      }
    }
    return props;
  };
  const columns = useMemo(
    () =>
      useFindComponentsByType({ type: Column, children }).map(child =>
        combineColumnAndCellProps(child)
      ),
    []
  );

  const EmptyTableComponent = useFindComponentByType({ type: EmptyTable, children }) || (
    <h1 className='syte-table-empty-table'>No data</h1>
  );

  const hooks = [useFilters, useGlobalFilter, useSortBy, useFlexLayout, useResizeColumns];
  const tableOptions = {
    data,
    columns,
    defaultColumn,
    filterTypes,
    disableGlobalFilter,
    updateData,
    disableFilters,
    disableSortBy,
    defaultCanSort,
    disabled,
  };

  const tableInstance: TableInstance<T> = useTable<T>(tableOptions, ...hooks, tableHooks => {
    if (enableRowDelete) {
      tableHooks.visibleColumns.push(tableColumns => [
        ...tableColumns,
        {
          id: 'delete',
          Header: '',
          Cell: ({ row: { index }, data: tableData }: { row: Row<T>; data: T[] }) => {
            return (
              <ActionIcon
                className='delete-row-icon'
                iconName={AvailableIcons.TrashCan}
                onClick={() => deleteRow({ rowIndex: index, tableData })}
              />
            );
          },
          className: 'delete-column',
          width: 14,
          disableSorting: true,
          disableResizing: true,
          disableFilters: true,
        } as any,
      ]);
    }
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = tableInstance;

  const Rows = rows.map(row => {
    prepareRow(row);

    return (
      <div {...row.getRowProps()} className='tr' key={row.id} ref={rowRef}>
        {row.cells.map((cell, i) => {
          const cellKey = `${row.id}_${cell.column.id}`;
          const noBorder = i === row.cells.length - 2;
          const cellWithTableData = { ...cell, tableData: data };

          return (
            <div
              {...cell.getCellProps([
                {
                  className: classNames('td', (cell as any).column.className, {
                    'no-divider': noBorder,
                  }),
                  style: (cell as any).column.style,
                },
                getColumnProps(cell.column),
              ])}
              key={cellKey}
            >
              {cell.render('Cell', getCellProps(cellWithTableData))}
            </div>
          );
        })}
      </div>
    );
  });

  return (
    <div {...getTableProps()} className={classNames('syte-table', className)}>
      {!disableGlobalFilter && (
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          value={(state as any).globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      <div className='syte-table-header'>
        {headerGroups.map(headerGroup => {
          const headerGroupKey = `headerGroup_${headerGroup.headers[0]?.id}`;
          return (
            <div {...headerGroup.getHeaderGroupProps()} key={headerGroupKey} className='tr'>
              {headerGroup.headers.map((columnHeader, i) => {
                const noBorder = i === headerGroup.headers.length - 2;
                return (
                  <div
                    {...columnHeader.getHeaderProps([
                      {
                        className: classNames('th', (columnHeader as any).className, {
                          'no-divider': noBorder,
                        }),
                        style: (columnHeader as any).style,
                      },
                      getColumnProps(columnHeader),
                      getHeaderProps(columnHeader),
                      (columnHeader as any).canSort && (columnHeader as any).getSortByToggleProps(),
                    ])}
                    key={columnHeader.id}
                  >
                    {columnHeader.render('Header')}
                    {enableResizing && (columnHeader as any).canResize && (
                      <div
                        {...(columnHeader as any).getResizerProps()}
                        className={classNames('resizer', {
                          isResizing: (columnHeader as any).isResizing,
                        })}
                      />
                    )}
                    {!(columnHeader as any).disableFilters &&
                      (columnHeader as any).canFilter &&
                      columnHeader.render('Filter')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      <div {...getTableBodyProps()} className='syte-table-body'>
        {rows.length > 0 ? Rows : EmptyTableComponent}
      </div>
    </div>
  );
};
