/* eslint-disable react/no-unescaped-entities */
import { css } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import { Box, TimeZoneDropdown, TextBox } from 'src/components-dummy';
import { debounce } from 'lodash';
import { CatalogFormDraft, ICatalogFormScheduling } from '../../CatalogForm.config';

/**
 * Helpers & constants
 */
export const DEFAULT_TIME_INTERVALS_IN_HOURS = '6';
export const DEFAULT_TIME_INTERVALS_IN_MINUTES = '00';

const UTC_TZ = 'Etc/UTC';

const DEBOUNCE_TIME = 250;

const ARROW_UP = 'ArrowUp';
const ARROW_DOWN = 'ArrowDown';

const MAX_HOUR_NUMBER = 23;
const MAX_MINUTES_NUMBER = 59;

const removeNonDigits = (value: string) => value.replace(/\D/g, '');
const convertTo2Digits = (value: string) => value.padStart(2, '0');

/**
 * Main
 */
export interface CatalogFormTimeIntervalsProps {
  onFieldChange: (partialData: Partial<CatalogFormDraft>) => void;
  timeIntervals?: ICatalogFormScheduling['timeIntervals'];
  errors: any;
}

export const CatalogFormTimeIntervals = React.memo(
  ({ onFieldChange, timeIntervals, errors }: CatalogFormTimeIntervalsProps): JSX.Element => {
    const [hoursState, setsHoursState] = useState<string>(timeIntervals?.hours || '');
    const [minutesState, setMinutesState] = useState(timeIntervals?.minutes || '');

    /**
     * Hours
     */
    const onHoursUpdateChange = useCallback(
      debounce(value => {
        onFieldChange({
          scheduling: {
            timeIntervals: {
              hours: value,
              minutes: timeIntervals?.minutes || DEFAULT_TIME_INTERVALS_IN_MINUTES,
            },
          },
        });
      }, DEBOUNCE_TIME),
      [timeIntervals?.minutes]
    );

    const onHoursChange = (value: string) => {
      let cleanedValue = removeNonDigits(value) || '0';

      const cleanedValueNumber = Number.parseInt(cleanedValue, 10);

      // max hours
      if (cleanedValueNumber > MAX_HOUR_NUMBER) cleanedValue = MAX_HOUR_NUMBER.toString();

      setsHoursState(cleanedValueNumber.toString());
      onHoursUpdateChange(cleanedValueNumber.toString());
    };

    const handleHoursKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case ARROW_UP: {
          e.preventDefault();
          const incrementedHours = (Number.parseInt(hoursState, 10) + 1) % 24;
          onHoursChange(incrementedHours.toString());
          break;
        }
        case ARROW_DOWN: {
          e.preventDefault();
          const decrementedHours =
            Number.parseInt(hoursState, 10) === 0 ? 23 : Number.parseInt(hoursState, 10) - 1;
          onHoursChange(decrementedHours.toString());
          break;
        }
        default:
          break;
      }
    };

    /**
     * Minutes
     */
    const onMinutesUpdateChange = useCallback(
      debounce((value: string) => {
        const cleanedValue = removeNonDigits(value);

        onFieldChange({
          scheduling: {
            timeIntervals: {
              hours: timeIntervals?.hours || '0',
              minutes: convertTo2Digits(cleanedValue),
            },
          },
        });
      }, DEBOUNCE_TIME),
      [timeIntervals?.hours]
    );

    const onMinutesChange = (value: string) => {
      let cleanedValue = removeNonDigits(value);

      // max minutes
      if (Number.parseInt(cleanedValue, 10) > MAX_MINUTES_NUMBER)
        cleanedValue = MAX_MINUTES_NUMBER.toString();

      setMinutesState(cleanedValue || '0');
      onMinutesUpdateChange(cleanedValue);
    };

    const handleMinutesKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case ARROW_UP: {
          e.preventDefault();
          const incrementedMinutes = (Number.parseInt(minutesState, 10) + 1) % 60;
          onMinutesChange(convertTo2Digits(incrementedMinutes.toString()));
          break;
        }
        case ARROW_DOWN: {
          e.preventDefault();
          const decrementedMinutes =
            Number.parseInt(minutesState, 10) === 0 ? 59 : Number.parseInt(minutesState, 10) - 1;
          onMinutesChange(convertTo2Digits(decrementedMinutes.toString()));
          break;
        }
        default:
          break;
      }
    };

    useEffect(() => {
      setsHoursState(timeIntervals?.hours || DEFAULT_TIME_INTERVALS_IN_HOURS);
    }, [timeIntervals?.hours]);

    useEffect(() => {
      setMinutesState(timeIntervals?.minutes || DEFAULT_TIME_INTERVALS_IN_MINUTES);
    }, [timeIntervals?.minutes]);

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <TextBox
          value={hoursState}
          label='Hours'
          maxLength={2}
          onChange={onHoursChange}
          onKeyDown={handleHoursKeyPress}
          error={errors['scheduling.timeIntervals.hours']}
          css={css`
            width: 47px;
            .input-base-wrapper {
              padding: 12px;
            }
          `}
        />
        <TextBox
          value={minutesState}
          label='Minutes'
          maxLength={2}
          onChange={onMinutesChange}
          onKeyDown={handleMinutesKeyPress}
          css={css`
            width: 64px;
            min-width: 64px;
            .input-base-wrapper {
              padding: 12px;
            }
          `}
        />
        <TimeZoneDropdown
          label='Timezone'
          value={UTC_TZ}
          disabled
          css={css`
            width: 217px;
            .MuiInputLabel-root {
              position: relative;
              top: -4px;
              margin-bottom: 6px;
            }
          `}
        />
      </Box>
    );
  }
);
