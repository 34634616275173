import { useCallback, useEffect, useState } from 'react';
import { Dispatch } from 'src/components-bl';
import {
  AddToCartGeneral,
  ResultsModalGeneralSettings,
} from 'src/services/src/service/results-modal/types';

import { useAppSelector } from '../../../hooks';
import { ResultsModalActions } from './Actions';
import { isDataDirty } from '../../../utils';

interface UseResultsModalGeneralSettingsProps {
  shopId: number;
  selectedVariantId?: string;
  dispatch: Dispatch;
}

interface UseResultsModalGeneralSettingsReturn {
  draftGeneralSettings: ResultsModalGeneralSettings;
  isDirty: boolean;
  onChangeAddToCart: (partialAddToCart: Partial<AddToCartGeneral>) => void;
  onChangeTextSimilarity: (enableTextSimilarity: boolean) => void;
  onSubmit: () => void;
}

export const useResultsModalGeneralSettings = ({
  shopId,
  selectedVariantId,
  dispatch,
}: UseResultsModalGeneralSettingsProps): UseResultsModalGeneralSettingsReturn => {
  const { generalSettings } = useAppSelector(state => state.resultsModal);

  const [draftGeneralSettings, setDraftGeneralSettings] = useState(generalSettings);

  const isDirty = isDataDirty(generalSettings, draftGeneralSettings);

  useEffect(() => {
    dispatch(
      ResultsModalActions.getResultsModalGeneralSettings({ shopId, variantId: selectedVariantId })
    );
  }, [shopId, selectedVariantId]);

  const onChange = useCallback(
    (partialSettings: Partial<ResultsModalGeneralSettings>) => {
      const updatedState = { ...draftGeneralSettings, ...partialSettings };
      setDraftGeneralSettings(updatedState);
    },
    [draftGeneralSettings, setDraftGeneralSettings]
  );

  const onChangeAddToCart = useCallback(
    (partialAddToCart: Partial<AddToCartGeneral>) => {
      onChange({ addToCart: { ...draftGeneralSettings.addToCart, ...partialAddToCart } });
    },
    [draftGeneralSettings, onChange]
  );

  const onChangeTextSimilarity = useCallback(
    (enableTextSimilarity: boolean) => onChange({ ...draftGeneralSettings, enableTextSimilarity }),
    [draftGeneralSettings, onChange]
  );

  const onSubmit = (): void => {
    dispatch(
      ResultsModalActions.updateResultsModalGeneralSettings({
        shopId,
        variantId: selectedVariantId,
        payload: { generalSettings: draftGeneralSettings },
      })
    );
  };

  useEffect((): void => {
    setDraftGeneralSettings(generalSettings);
  }, [generalSettings]);

  return { draftGeneralSettings, isDirty, onChangeAddToCart, onChangeTextSimilarity, onSubmit };
};
