import React from 'react';
import { Page, BackButtonTitle } from 'src/components-dummy';
import { ConnectIG } from './components/ConnectIG/ConnectIG';
import { EditPostsPage } from './components/EditPosts/EditPostsPage';
import { useSocialConnectionState } from '../../components-bl/socialDiscovery/socialConnection/useSocialConnectionState/useSocialConnectionState';
import { StateType } from '../../components-bl/socialDiscovery/socialConnection/useSocialConnectionState/types';
import { ConnectionStateProvider } from '../../components-bl/socialDiscovery/socialConnection/ConnectionStateProvider';
import { ShopRoutes } from '../../types/enums/shop-routes';
import './SocialDiscoveryPage.scss';

export const SocialDiscoveryPage = ({ shopId }: { shopId: number }) => {
  const { state, openConnectionLink, checkConnectionStatus, disconnectSocialCollection } =
    useSocialConnectionState();

  if (state.type === StateType.fetchingStatus) return null;
  if (state.type === StateType.connected || state.type === StateType.disconnecting)
    return (
      <ConnectionStateProvider state={state} disconnect={disconnectSocialCollection}>
        <EditPostsPage />
      </ConnectionStateProvider>
    );

  const isLoading =
    state.type === StateType.reFetchingStatus || state.type === StateType.fetchingLink;

  const navigationPath = `/shops/${shopId}/${ShopRoutes.Stories}`;

  return (
    <>
      <Page>
        <Page.Header className='syte-social-discovery-page-header'>
          <BackButtonTitle path={navigationPath}>Social Discovery</BackButtonTitle>
        </Page.Header>
        <Page.Content>
          <ConnectIG
            isLoading={isLoading}
            hasConnectionLink={!!state.connectionLink}
            onConnect={openConnectionLink}
            onStart={checkConnectionStatus}
          />
        </Page.Content>
      </Page>
    </>
  );
};
