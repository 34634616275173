import React, { useCallback } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { MenuItem, Select, SelectType } from '../../../components-dummy';
import { getStringEnumValues } from '../../../utils';
import { rankingStrategyTypeMapper } from './rankingTypeMapper';
import { RankingType } from './types';

interface IRankingStrategyTypeProps {
  type: string;
  onChange: (type: RankingType) => void;
  className: string;
}

export const RankingStrategyType: React.FC<IRankingStrategyTypeProps> = ({
  type,
  onChange,
  className,
}) => {
  const handleOnChange = useCallback((e: SelectChangeEvent) => {
    onChange(e.target.value as RankingType);
  }, []);
  return (
    <div className={className}>
      <Select
        type={SelectType.Primary}
        value={type}
        placeholder='Ranking Type'
        onChange={handleOnChange}
        label='Ranking Method'
      >
        {getStringEnumValues(RankingType).map((option: string) => (
          <MenuItem key={option} value={option}>
            {rankingStrategyTypeMapper[option]?.text ?? option}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
