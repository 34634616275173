import React from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { Notifications } from 'src/components-bl';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useNotificationsWS } from './useNotificationsWS';

interface NotificationsContainerProps extends RoutedComponentProps {
  shopId?: number;
}

export function NotificationsContainer({
  shopId,
  permittedRouteMap,
}: NotificationsContainerProps): JSX.Element {
  const dispatch = useAppDispatch();

  const { notifications, unreadNotifications, unreadCount } = useAppSelector(
    state => state.notifications
  );

  useNotificationsWS({ shopId, dispatch });

  return (
    <Notifications
      shopId={shopId}
      notifications={notifications}
      unreadNotifications={unreadNotifications}
      dispatch={dispatch}
      unreadCount={unreadCount}
      permittedRouteMap={permittedRouteMap}
    />
  );
}
