import React from 'react';
import { Rule, RuleGroup } from '../../../types';
import { OnDeleteRuleGroupCallback } from '../types';
import { CollectionRule } from '../CollectionRule';
import { TypographyType, TypographyVariant } from '../../../../../components-dummy';
import { getConditionTextPrefix } from '../../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormConditionsSection/RuleFormConditionsSection.helpers';
import { ParseJoiValidateResponse, reduceErrorsByPrefix } from '../../../../../utils';
import { mapErrorMessages } from '../../../../MerchandisingRules/mapErrorMessages';
import {
  ConditionRowErrors,
  ConditionText,
} from '../../../../MerchandisingRules/components/MerchandisingRuleForm/components/RuleFormConditionsSection/RuleFormConditionsSection.styles';
import { CollectionRuleWrapperStyled } from './CollectionRuleWrapper.styles';
import { CollectionRulesListStyled } from './CollectionRulesList.styles';
import { ShopDataField } from '../../../../../services';

interface RulesProps {
  shopId: number;
  rules: Rule[];
  ruleGroupIndex: number;
  ruleGroupId: string;
  onDeleteRuleGroup: OnDeleteRuleGroupCallback;
  addRule: () => void;
  deleteRule: (index: number) => void;
  onChange: (ruleIndex: number, rule: Rule) => void;
  dataFields: ShopDataField[] | undefined;
  errors: ParseJoiValidateResponse<{ ruleGroups: RuleGroup[] }>;
}

export const CollectionRulesList: React.FC<RulesProps> = ({
  rules,
  ruleGroupIndex,
  ruleGroupId,
  onDeleteRuleGroup,
  addRule,
  deleteRule,
  onChange,
  dataFields,
  shopId,
  errors,
}) => {
  const isOnlyOneRule = rules.length === 1;
  return (
    <>
      {rules.map((rule, index) => {
        const rulesErrors = reduceErrorsByPrefix({
          errors,
          prefix: `ruleGroups.${ruleGroupIndex}.rules.${index}`,
        });

        const rowErrorLabels = mapErrorMessages(rulesErrors);

        return (
          <CollectionRulesListStyled key={rule.id}>
            <CollectionRuleWrapperStyled>
              <ConditionText type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                {getConditionTextPrefix(index)}
              </ConditionText>
              <CollectionRule
                shopId={shopId}
                index={index}
                data={rule}
                ruleGroupId={ruleGroupId}
                onDeleteRuleGroup={onDeleteRuleGroup}
                addRule={addRule}
                deleteRule={deleteRule}
                onChange={onChange}
                dataFields={dataFields}
                isOnlyOneRule={isOnlyOneRule}
                errors={rulesErrors}
              />
            </CollectionRuleWrapperStyled>
            {rowErrorLabels.length > 0 ? (
              <ConditionRowErrors>{rowErrorLabels}</ConditionRowErrors>
            ) : null}
          </CollectionRulesListStyled>
        );
      })}
    </>
  );
};
