import React, { useMemo } from 'react';
import './GroupedList.scss';
import classNames from 'classnames';
import { groupBy, isFunction } from 'lodash';
import { ChildrenProp, GenericObject } from '../types';
import { useFindComponentByType } from '../hooks';
import { EmptyTemplate } from './EmptyTemplate';
import { CardTemplate } from './CardTemplate';
import { GroupHeaderTemplate } from './GroupHeaderTemplate';

export type SortFunc<T> = (firstItem: T, secondItem: T) => number;

export interface GroupedListProps<T> extends ChildrenProp {
  items?: GenericObject<T>[];
  itemClass?: string;
  idField: keyof T;
  selectedId?: number | string;
  onItemSelected: (item: T) => void;
  groupByField: keyof T;
  className?: string;
  groupSortBy?: (firstTitle: string, secondTitle: string) => number;
  itemSortBy?: SortFunc<T> | keyof T;
}

export const GroupedList = <T extends GenericObject<T>>({
  items,
  itemClass,
  children,
  idField,
  selectedId,
  onItemSelected,
  groupByField,
  className,
  groupSortBy,
  itemSortBy,
}: GroupedListProps<T>): JSX.Element => {
  const groupItemsByField = (itemSortBy as keyof T) || idField;

  const defaultItemSortBy = (a: T, b: T): number => {
    if (a[groupItemsByField] > b[groupItemsByField]) return 1;

    if (a[groupItemsByField] < b[groupItemsByField]) return -1;

    return 0;
  };

  const emptyTemplate = useFindComponentByType({ type: EmptyTemplate, children })?.props.children;
  const cardTemplate = useFindComponentByType({ type: CardTemplate, children })?.props.children;
  const groupHeaderTemplate = useFindComponentByType({ type: GroupHeaderTemplate, children })?.props
    .children;

  const NoResultsWrapped = (
    <div className='syte-grouped-list-no-results'>
      {emptyTemplate || <h1>Wow...so empty in here...</h1>}
    </div>
  );

  const groupedData = groupBy(items, groupByField);

  const itemSortMethod = isFunction(itemSortBy) ? (itemSortBy as SortFunc<T>) : defaultItemSortBy;

  const GroupHeader = (groupKey: string): JSX.Element => (
    <li className='group-header' key={groupKey}>
      {groupHeaderTemplate(groupedData[groupKey][0])}
    </li>
  );

  const GroupItem = (item: GenericObject<T>): JSX.Element => (
    <li
      className={classNames(
        'syte-grouped-list-item',
        { 'syte-grouped-list-item-selected': item[idField as keyof T] === selectedId },
        itemClass
      )}
      key={item[idField as keyof T]}
      onClick={() => onItemSelected(item)}
    >
      {cardTemplate(item)}
    </li>
  );

  const listItemElements: JSX.Element[] = useMemo(() => {
    const result: JSX.Element[] = [];
    if (cardTemplate && groupHeaderTemplate && groupedData && groupHeaderTemplate.length > 0) {
      const groupKeys = Object.keys(groupedData);
      if (groupSortBy) {
        groupKeys.sort(groupSortBy);
      }

      for (const groupKey of groupKeys) {
        const groupItems = groupedData[groupKey].sort(itemSortMethod);
        if (itemSortBy) {
          groupItems.sort(itemSortMethod);
        }

        const groupHeader = GroupHeader(groupKey);

        result.push(groupHeader);

        for (const item of groupItems) {
          const groupItem = GroupItem(item);

          result.push(groupItem);
        }
      }
    }
    return result;
  }, [groupedData]);

  return (
    <ul className={classNames('syte-grouped-list', className)}>
      {listItemElements.length > 0 ? listItemElements : NoResultsWrapped}
    </ul>
  );
};

GroupedList.EmptyTemplate = EmptyTemplate;
GroupedList.CardTemplate = CardTemplate;
GroupedList.GroupHeaderTemplate = GroupHeaderTemplate;
