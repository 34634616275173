import React from 'react';
import { CreateCollectionButton } from '../CreateCollectionButton';
import { CollectionsHeaderWrapper, CollectionTitle } from './CollectionsHeader.styles';

interface CollectionsHeaderProps {
  onCreateDefaultCollection?: VoidFunction;
}

export const CollectionsHeader = ({ onCreateDefaultCollection }: CollectionsHeaderProps) => {
  return (
    <CollectionsHeaderWrapper>
      <CollectionTitle>Collections</CollectionTitle>
      <CreateCollectionButton
        onCreateDefaultCollection={onCreateDefaultCollection}
        buttonText='Create collection'
      />
    </CollectionsHeaderWrapper>
  );
};
