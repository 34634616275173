/* eslint-disable no-param-reassign */
import { useState, useMemo, useCallback, useEffect } from 'react';
import Joi from 'joi';
import { parseJoiValidateResponse, ParseJoiValidateResponse } from '../utils';

export type SchemaType<T> = Record<keyof T, Joi.AnySchema | Joi.Reference>;

export interface ValidateArguments<T> extends Joi.ValidationOptions {
  dataToValidate: T;
}

export interface ValidatePartialArguments<T> extends Joi.ValidationOptions {
  dataToValidate: { [K in keyof Partial<T>]: T[K] };
}

export interface UseValidateSchemaReturnType<T> {
  isValid: boolean;
  errors: ParseJoiValidateResponse<T>;
  validate: (args: ValidateArguments<T>) => void;
  syncedValidation: (args: ValidateArguments<T>) => ParseJoiValidateResponse<T>;
  validatePartial: (args: ValidatePartialArguments<T>) => void;
  resetValidationState: () => void;
}

export interface UseValidateSchemaArguments<T> {
  schema: SchemaType<T>;
  validateOnStart?: boolean;
  initialData?: T;
  options?: Joi.ValidationOptions;
}

export type CompileJoiSchemaArguments<T> = Pick<
  UseValidateSchemaArguments<T>,
  'schema' | 'options'
>;

export interface ValidateSchemaArguments<T> extends ValidateArguments<T>, Joi.ValidationOptions {
  schema: Joi.ObjectSchema<TemplateStringsArray>;
}

type UseValidateSchemaState<T> = Pick<UseValidateSchemaReturnType<T>, 'isValid' | 'errors'>;

const defaultSchemaOptions = { abortEarly: false, allowUnknown: true };

export const compileJoiSchema = <T>({
  schema,
  options = defaultSchemaOptions,
}: CompileJoiSchemaArguments<T>): Joi.ObjectSchema<T> => {
  return Joi.object(schema).options(options);
};

export const validateSchema = <T>({
  dataToValidate,
  schema,
  ...options
}: ValidateSchemaArguments<T>): ParseJoiValidateResponse<T> => {
  const rawErrors = schema.validate(dataToValidate, options);
  return parseJoiValidateResponse<T>(rawErrors);
};

const getIsValidValue = <T>({
  errors,
}: Pick<UseValidateSchemaReturnType<T>, 'errors'>): boolean => {
  return Object.keys(errors).length === 0;
};

export const useValidateSchema = <T>({
  schema,
  validateOnStart = false,
  initialData,
  options = defaultSchemaOptions,
}: UseValidateSchemaArguments<T>): UseValidateSchemaReturnType<T> => {
  const joiObject = useMemo(() => compileJoiSchema({ schema, options }), [schema, options]);

  const initialErrorsState =
    validateOnStart && initialData
      ? validateSchema({ schema: joiObject, dataToValidate: initialData })
      : ({} as ParseJoiValidateResponse<T>);

  const initialIsValidState = validateOnStart
    ? getIsValidValue({ errors: initialErrorsState })
    : false;

  const initialState = {
    errors: initialErrorsState,
    isValid: initialIsValidState,
  };

  const [state, setState] = useState<UseValidateSchemaState<T>>(initialState);

  const updateStatePartial = (partialState: Partial<UseValidateSchemaState<T>>): void => {
    const updatedState = { ...state, ...partialState };

    setState(updatedState);
  };

  const updateErrorsWithSideEffects = ({ errors }: Pick<UseValidateSchemaState<T>, 'errors'>) => {
    const updatedIsValid = getIsValidValue({ errors });

    updateStatePartial({ errors, isValid: updatedIsValid });
  };

  const validate = useCallback(
    ({ dataToValidate, ...validateOptions }: ValidateArguments<T>): void => {
      const parsedErrors = validateSchema({
        dataToValidate,
        schema: joiObject,
        ...validateOptions,
      });

      updateErrorsWithSideEffects({ errors: parsedErrors });
    },
    [joiObject]
  );

  const syncedValidation = useCallback(
    ({ dataToValidate, ...validateOptions }: ValidateArguments<T>): ParseJoiValidateResponse<T> => {
      const parsedErrors = validateSchema({
        dataToValidate,
        schema: joiObject,
        ...validateOptions,
      });
      updateErrorsWithSideEffects({ errors: parsedErrors });
      return parsedErrors;
    },
    [joiObject]
  );

  const validatePartial = ({
    dataToValidate,
    ...validateOptions
  }: ValidatePartialArguments<T>): void => {
    const partialSchemaKeys = Object.keys(dataToValidate) as (keyof T)[];

    const schemaConfig = partialSchemaKeys.reduce(
      (result, current) => {
        const propertySchema = schema[current] as Joi.ObjectSchema<T>;
        return { ...result, [current]: propertySchema };
      },
      {} as { [K in keyof Partial<T>]: Joi.AnySchema }
    );

    const schemaObject = Joi.object(schemaConfig).options(options);

    const parsedErrors = validateSchema({
      dataToValidate,
      schema: schemaObject,
      ...validateOptions,
    });

    const updatedErrors = partialSchemaKeys.reduce(
      (result, current) => {
        const errorValue = parsedErrors[current];
        if (errorValue) {
          result[current] = errorValue;
        } else if (result[current]) {
          delete result[current];
        }

        return result;
      },
      { ...state.errors }
    );

    updateErrorsWithSideEffects({ errors: updatedErrors });
  };

  const resetValidationState = (): void => {
    updateStatePartial(initialState);
  };

  useEffect(() => {
    if (initialData && validateOnStart) {
      validate({ dataToValidate: initialData });
    }
  }, [initialData, validateOnStart]);

  return {
    validate,
    syncedValidation,
    validatePartial,
    resetValidationState,
    ...state,
  };
};
