import { EditExperimentContainerAction } from 'src/containers';
import { SimilarItemsSettingsReducerState } from '../app-state-types';
import {
  SimilarItemsSettingsAction,
  SimilarItemsActionTypes,
  VariantPanelAction,
} from '../components-bl';

const initialState: SimilarItemsSettingsReducerState = {
  numberOfResultsToDisplay: 12,
  shouldRefetch: false,
  addToCart: { active: false },
  enableTextSimilarity: false,
};

export function similarItemsSettingsReducer(
  state: SimilarItemsSettingsReducerState = initialState,
  action?: SimilarItemsSettingsAction | EditExperimentContainerAction | VariantPanelAction
): SimilarItemsSettingsReducerState {
  switch (action?.type) {
    case SimilarItemsActionTypes.GetSimilarItemsSettingsSuccess: {
      const { numberOfResultsToDisplay, addToCart, enableTextSimilarity } = action.payload;
      return {
        ...state,
        numberOfResultsToDisplay,
        addToCart,
        shouldRefetch: false,
        enableTextSimilarity,
      };
    }
    case SimilarItemsActionTypes.GetSimilarItemsSettingsRequest:
      return { ...state };
    case SimilarItemsActionTypes.GetSimilarItemsSettingsError:
      return { ...state };
    case SimilarItemsActionTypes.UpdateSimilarItemsSettingsSuccess: {
      const { numberOfResultsToDisplay, addToCart, enableTextSimilarity } = action.payload;

      return {
        ...state,
        numberOfResultsToDisplay,
        addToCart,
        enableTextSimilarity,
      };
    }
    default:
      return state;
  }
}
