import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Shops } from 'src/services';

const shopService = new Shops();

export const shopFeatureTogglesDeepTagsToggleActions = {
  notifyIsDirty: createAction<{ isDirty: boolean }>(
    'shopFeatureTogglesDeepTagsToggleActions/NotifyIsDirty'
  ),
  getShopProductsDeepTagsPermission: createAsyncThunk(
    'shopFeatureTogglesDeepTagsToggleActions/getShopProductsDeepTagsPermission',
    async (
      {
        shopId,
      }: {
        shopId: number;
      },
      { rejectWithValue }
    ) => {
      try {
        const deepTagsPermissionResponse =
          await shopService.getProductsDeepTagsPermissionsToggle(shopId);

        return deepTagsPermissionResponse;
      } catch (error) {
        console.error(error);
        return rejectWithValue({ shopId, error });
      }
    }
  ),
  updateShopProductsDeepTagsPermission: createAsyncThunk(
    'shopFeatureTogglesDeepTagsToggleActions/updateShopProductsDeepTagsPermission',
    async (
      {
        shopId,
        isDeepTagsPermitted,
        isTaggingEnabled,
      }: {
        shopId: number;
        isDeepTagsPermitted: boolean;
        isTaggingEnabled: boolean;
      },
      { rejectWithValue }
    ) => {
      try {
        const updatedDeepTagsPermission = await shopService.updateProductsDeepTagsPermissionsToggle(
          {
            shopId,
            isDeepTagsPermitted,
            isTaggingEnabled,
          }
        );

        return updatedDeepTagsPermission;
      } catch (error) {
        console.error(error);
        return rejectWithValue({ shopId, error });
      }
    }
  ),
};
