import React, { useCallback, useMemo } from 'react';
import { ParseJoiValidateResponse, reduceErrorsByPrefix } from 'src/utils';
import { AvailableIcons } from 'src/components-dummy';
import {
  LexiconChangeRuleSectionStyled,
  AddNewRowButtonStyled,
} from './LexiconChangeRuleSection.styles';
import { ILexiconRuleDraft, TagFields, TagPart } from '../../LexiconRuleForm.types';
import { TagRowFactory, TagRowFactoryProps } from './components';
import { getCategoryKeyFromDraft } from '../../mapper';

const allTagFields = Object.keys(TagFields) as TagFields[];

export interface LexiconChangeRuleSectionProps
  extends Pick<
    TagRowFactoryProps,
    'availableCategoryKeys' | 'availableAttributeKeys' | 'availableValueKeys' | 'conditionsGroups'
  > {
  draftRule: ILexiconRuleDraft;
  onChange: (partialState: Partial<ILexiconRuleDraft>) => void;
  errors: ParseJoiValidateResponse<ILexiconRuleDraft>;
}

export const LexiconChangeRuleSection = ({
  errors,
  onChange,
  availableCategoryKeys,
  availableAttributeKeys,
  availableValueKeys,
  conditionsGroups,
  draftRule,
}: LexiconChangeRuleSectionProps): JSX.Element => {
  const onTagChange = useCallback(
    (updatedTag: TagPart) => {
      const updatedState = draftRule.tagParts.map(tag => {
        if (tag.id === updatedTag.id) {
          return updatedTag;
        }
        return tag;
      });
      onChange({ tagParts: updatedState });
    },
    [onChange, draftRule.tagParts]
  );

  const onDeleteTag = useCallback(
    (id: number) => {
      const updatedState = draftRule.tagParts.filter(tag => tag.id !== id);
      onChange({ tagParts: updatedState });
    },
    [onChange, draftRule.tagParts]
  );

  const availableFields = useMemo(() => {
    const selected = draftRule.tagParts.map(tag => tag.field);

    return allTagFields.filter(field => !selected.includes(field));
  }, [draftRule.tagParts]);

  const onAddNewRow = useCallback(() => {
    if (availableFields.length === 0) return;

    const ruleCategoryKey = getCategoryKeyFromDraft(draftRule);

    const newTagField = availableFields[0];

    let newTag: TagPart;

    switch (newTagField) {
      case TagFields.Attribute: {
        newTag = {
          id: Math.random(),
          field: TagFields.Attribute,
          attributeKey: '',
          customTranslation: '',
        };
        break;
      }
      case TagFields.Value: {
        newTag = {
          id: Math.random(),
          field: TagFields.Value,
          attributeKey: '',
          valueKey: '',
          customTranslation: '',
        };
        break;
      }
      case TagFields.Category:
      default: {
        newTag = {
          id: Math.random(),
          field: TagFields.Category,
          categoryKey: ruleCategoryKey,
          customTranslation: '',
        };
        break;
      }
    }
    const withoutEmptyRows = draftRule.tagParts.filter(part => !!part.field);
    const updatedState = [...withoutEmptyRows, newTag];

    onChange({ tagParts: updatedState });
  }, [draftRule, availableFields, onChange]);

  const enableAddNewRow = availableFields.length > 0;

  return (
    <LexiconChangeRuleSectionStyled>
      {draftRule.tagParts.map((tag, index) => {
        const tagErrors = reduceErrorsByPrefix({ errors, prefix: `tagParts.${index}` });
        return (
          <TagRowFactory
            tag={tag}
            onChange={onTagChange}
            onDelete={onDeleteTag}
            availableCategoryKeys={availableCategoryKeys}
            availableAttributeKeys={availableAttributeKeys}
            availableValueKeys={availableValueKeys}
            conditionsGroups={conditionsGroups}
            key={tag.id}
            errors={tagErrors}
            availableFields={availableFields}
            isFirstRow={index === 0}
            isTheOnlyRow={draftRule.tagParts.length === 1}
          />
        );
      })}
      {enableAddNewRow && (
        <AddNewRowButtonStyled
          onClick={onAddNewRow}
          variant='tertiary'
          startIcon={AvailableIcons.Plus}
        >
          Add
        </AddNewRowButtonStyled>
      )}
    </LexiconChangeRuleSectionStyled>
  );
};
