import Joi, { AnySchema } from 'joi';
import { FormState } from './types';
import { MAX_POSITION_VALUE, MIN_POSITION_VALUE } from '../constants';

export const formValidationSchema: Record<keyof FormState, AnySchema> = {
  skus: Joi.array()
    .items(Joi.string())
    .unique()
    .min(MIN_POSITION_VALUE)
    .max(Joi.ref('maxItemsLimit'))
    .required()
    .messages({
      'array.unique': 'All skus should be unique',
      'array.min': 'At least one sku is required',
      'array.max': `You can only pin items up to the 250th position. Please reduce the number of items or adjust the starting position accordingly.`,
      'any.required': 'Please, add at least one sku',
    }),
  startIndex: Joi.number().min(MIN_POSITION_VALUE).max(MAX_POSITION_VALUE).required().messages({
    'any.required': `Please, input starting position`,
  }),
  maxItemsLimit: Joi.number().min(MIN_POSITION_VALUE).max(MAX_POSITION_VALUE).required(),
};
