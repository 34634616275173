import React, { useCallback } from 'react';
import { ParseJoiValidateResponse } from 'src/utils';
import { MenuItem, SelectType, TypographyType, TypographyVariant } from 'src/components-dummy';
import { CategoryTagPart } from '../../../LexiconRuleForm.types';
import { LexiconTagSelectStyled } from '../LexiconChangeRuleSection.styles';
import { CustomTranslationTextBox, TagRowTypographyStyled } from './TagRow.styles';

interface CategoryRowProps {
  tag: CategoryTagPart;
  errors: ParseJoiValidateResponse<CategoryTagPart>;
  onChange: (updatedTag: CategoryTagPart) => void;
  availableCategoryKeys: string[];
}

export const CategoryRow = ({
  tag,
  errors,
  onChange,
  availableCategoryKeys,
}: CategoryRowProps): JSX.Element => {
  const onTagChange = useCallback(
    (updatedCategory: Partial<CategoryTagPart>) => {
      const updatedState = { ...tag, ...updatedCategory };
      onChange(updatedState);
    },
    [tag, onChange]
  );

  const onCategoryKeyChange = useCallback(
    event => {
      onTagChange({ categoryKey: event.target.value, customTranslation: '' });
    },
    [onTagChange]
  );

  const onCategoryTranslationChange = useCallback(
    newTranslation => {
      onTagChange({ customTranslation: newTranslation });
    },
    [onTagChange]
  );
  return (
    <>
      <LexiconTagSelectStyled
        type={SelectType.Menu}
        placeholder='Category'
        value={tag.categoryKey}
        onChange={onCategoryKeyChange}
        errorMessage={errors.categoryKey?.message}
        autoFocus
      >
        {availableCategoryKeys.map(categoryKey => (
          <MenuItem key={categoryKey} value={categoryKey}>
            {categoryKey}
          </MenuItem>
        ))}
      </LexiconTagSelectStyled>
      {tag.categoryKey && !errors.categoryKey && (
        <>
          <TagRowTypographyStyled
            type={TypographyType.Body}
            variant={TypographyVariant.MediumMedium}
          >
            to
          </TagRowTypographyStyled>
          <CustomTranslationTextBox
            placeholder='type category'
            onChange={onCategoryTranslationChange}
            value={tag.customTranslation}
            error={errors.customTranslation?.message}
          />
        </>
      )}
    </>
  );
};
