import React, { useCallback, useMemo, useRef } from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { Dispatch } from 'src/components-bl/types';
import { UserThumbnailElement } from 'src/components-bl/UserAvatarList/components';
import { Notification, NotificationSeverity } from 'src/services';
import { Tooltip } from 'src/components-dummy';
import { getNotificationCardMessageComponent } from './components';
import { notificationCardActions } from './notificationCard.actions';
import {
  CardActionsMenuStyled,
  DateSectionStyled,
  NotificationCardStyled,
  SeverityIndicationStyled,
  SeverityIndicationWrapperStyled,
} from './NotificationCard.styles';
import { NotificationCardApiRef } from './components/NotificationCardMessage/components';

const severityToDisplayName: Record<NotificationSeverity, string> = {
  [NotificationSeverity.High]: 'High priority',
  [NotificationSeverity.Medium]: 'Medium priority',
  [NotificationSeverity.Low]: 'Low priority',
};

interface NotificationCardProps extends RoutedComponentProps {
  shopId?: number;
  notification: Notification;
  dispatch: Dispatch;
  onClosePopup: () => void;
}

export function NotificationCard({
  shopId,
  notification,
  dispatch,
  permittedRouteMap,
  onClosePopup,
}: NotificationCardProps): JSX.Element {
  const onReadNotification = useCallback(() => {
    if (notification.isViewed) return;

    dispatch(
      notificationCardActions.updateNotification({
        shopId,
        notificationId: notification.id,
        isViewed: true,
      })
    );
  }, [shopId, notification.id, notification.isViewed, dispatch]);

  const onDeleteNotification = useCallback(() => {
    dispatch(
      notificationCardActions.deleteNotification({ shopId, notificationId: notification.id })
    );
  }, [shopId, notification.id, dispatch]);

  const navigateTo = useCallback(
    (path: string) => {
      dispatch(notificationCardActions.navigateTo({ navigateTo: path }));

      onClosePopup();
    },
    [dispatch, onClosePopup]
  );

  const customComponentRef = useRef<NotificationCardApiRef | null>(null);

  const onNotificationClick = useCallback(() => {
    customComponentRef.current?.onNotificationClick?.();
    onReadNotification();
    onClosePopup();
  }, [onClosePopup, onReadNotification, customComponentRef]);

  const NotificationCardMessage = useMemo(
    () =>
      getNotificationCardMessageComponent({
        domainEntity: notification.domainEntity,
        type: notification.type,
      }),
    [notification.domainEntity, notification.type]
  );

  return (
    <NotificationCardStyled isViewed={notification.isViewed} onClick={onNotificationClick}>
      <UserThumbnailElement
        user={
          notification.createdByUser
            ? { ...notification.createdByUser, isMe: false, isEditing: false }
            : undefined
        }
        enableTooltip={false}
        dispatch={dispatch}
        myProfilePath={undefined}
        isMenuOpen={false}
      />
      <NotificationCardMessage
        shopId={shopId}
        notification={notification}
        dispatch={dispatch}
        permittedRouteMap={permittedRouteMap}
        navigateTo={navigateTo}
        closeNotificationsPopUp={onClosePopup}
        cardRef={customComponentRef}
      />

      <SeverityIndicationWrapperStyled>
        <Tooltip value={severityToDisplayName[notification.severity]}>
          <SeverityIndicationStyled severity={notification.severity} />
        </Tooltip>
      </SeverityIndicationWrapperStyled>

      <CardActionsMenuStyled
        onReadClick={notification.isViewed ? undefined : onReadNotification}
        onDeleteClick={onDeleteNotification}
      />
      <DateSectionStyled createdAt={notification.createdAt} />
    </NotificationCardStyled>
  );
}
