export const getTimeWithCurrentDate = (date: Date): Date => {
  const now = new Date();
  return new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );
};

export const checkIsOutOfMinRange = ({
  selectedDate,
  minTime,
}: {
  selectedDate?: Date | null;
  minTime?: Date;
}): boolean => {
  const isOutOfRange =
    selectedDate &&
    minTime &&
    getTimeWithCurrentDate(selectedDate) < getTimeWithCurrentDate(minTime);

  return !!isOutOfRange;
};

export const checkIsOutOfMaxRange = ({
  selectedDate,
  maxTime,
}: {
  selectedDate?: Date | null;
  maxTime?: Date;
}): boolean => {
  const isOutOfRange =
    selectedDate &&
    maxTime &&
    getTimeWithCurrentDate(selectedDate) > getTimeWithCurrentDate(maxTime);

  return !!isOutOfRange;
};

export const checkIsValidDate = (selectedDate?: Date | null): boolean => {
  if (!selectedDate) return false;
  const isTimeNaN = Number.isNaN(selectedDate?.getTime());
  return !isTimeNaN;
};

export const isValueValid = ({
  selectedDate,
  minTime,
  maxTime,
}: {
  selectedDate?: Date | null;
  minTime?: Date;
  maxTime?: Date;
}): boolean => {
  const isValidDate = checkIsValidDate(selectedDate);
  const isOutOfMinRange = checkIsOutOfMinRange({ selectedDate, minTime });
  const isOutOfMaxRange = checkIsOutOfMaxRange({ selectedDate, maxTime });

  const isValid = isValidDate && !isOutOfMinRange && !isOutOfMaxRange;
  return isValid;
};

// Create a new date base on original date - local time zone minutes.
// Example: 2022-12-01-T14:00:00Z and local GMT+2 =>  2022-12-01-T12:00:00Z
export const subtractLocalTimeZone = (dateWithTimeZone: Date): Date => {
  const dateIgnoreUtc = new Date(dateWithTimeZone);

  dateIgnoreUtc.setMinutes(dateIgnoreUtc.getMinutes() - dateIgnoreUtc.getTimezoneOffset());

  return dateIgnoreUtc;
};

// Create a new date base on original date + local time zone minutes.
// Example: 2022-12-01-T14:00:00Z and local GMT+2 =>  2022-12-01-T16:00:00Z
export const addLocalTimeZone = (subtractedDate: Date): Date => {
  const dateForAppend = new Date(subtractedDate);

  dateForAppend.setMinutes(dateForAppend.getMinutes() + dateForAppend.getTimezoneOffset());

  return dateForAppend;
};

export const getUTCTimeForDatePicker = (expirationDateTime: Date | null): Date => {
  const now = new Date();
  if (expirationDateTime?.toLocaleDateString() === now.toLocaleDateString()) {
    return addLocalTimeZone(new Date());
  }

  now.setHours(0, 0, 0, 0);
  return now;
};
