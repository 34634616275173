import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  AvailableIcons,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { AdminSearchResults } from 'src/services/src/service/types/admin-search/admin-search-results';
import SearchForSomething from 'src/assets/admin-search-empty-state.svg';
import NoResults from 'src/assets/admin-search-no-results.svg';
import {
  AdminSearchModalStyled,
  Content,
  ContentImageStyled,
  Header,
  OverlayModalStyled,
} from './AdminSearchModal.styles';
import {
  AdminSearchResultsSection,
  AdminSearchResultsSectionProps,
} from './components/AdminSearchResultsSection';
import { AdminSearchInput } from './components/AdminSearchInput';
import { Dispatch } from '../types';
import recentSearches from './recent-searches';
import { useAdminSearch } from './useAdminSearch';
import { RecentSearches } from './components/RecentSearches';

export interface AdminSearchProps
  extends Pick<
    AdminSearchResultsSectionProps,
    'shopDefaultRoute' | 'accountDefaultRoute' | 'userDefaultRoute' | 'catalogsDefaultRoute'
  > {
  show: boolean;
  results?: AdminSearchResults;
  onClose?: () => void;
  dispatch: Dispatch;
}

export const AdminSearchModal = ({
  show,
  shopDefaultRoute,
  accountDefaultRoute,
  userDefaultRoute,
  catalogsDefaultRoute,
  dispatch,
  onClose,
}: AdminSearchProps): JSX.Element => {
  const [results, setResults] = useState<AdminSearchResults | null>(null);

  const inputRef = useRef(null);
  const modalRef = useRef(null);

  const onKeyUp = useCallback(
    (ev: KeyboardEvent) => {
      switch (ev.key) {
        case 'Escape':
          onClose?.();
          break;
        default:
          break;
      }
    },
    [onClose]
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyUp);
    return () => {
      window.removeEventListener('keydown', onKeyUp);
    };
  }, []);

  useEffect(() => {
    setResults(null);
  }, [show, setResults]);

  const onResults = useCallback(
    (newResults: any) => {
      setResults(newResults);
    },
    [setResults]
  );

  const onSelect = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const recent = recentSearches.read();

  const isEmptyState = !results && !recent.length;
  const isRecent = !results && recent.length !== 0;
  const isNoResults = results ? Object.values(results).every(res => res.length === 0) : false;

  const { searchTerm, onChange, onReset } = useAdminSearch({ onResults, isNoResults });

  const handleRecentClick = useCallback(
    (value: string) => {
      onChange(value);
      onReset();
    },
    [onChange, onReset]
  );

  return (
    <OverlayModalStyled show={show}>
      <AdminSearchModalStyled ref={modalRef}>
        <Header>
          <Typography type={TypographyType.Heading} variant={TypographyVariant.MediumBold}>
            Admin Search
          </Typography>
          <Icon name={AvailableIcons.Close} onClick={onClose} />
        </Header>
        <AdminSearchInput
          searchTerm={searchTerm}
          onChange={onChange}
          onReset={onReset}
          inputRef={inputRef}
        />
        {isEmptyState && (
          <Content>
            <ContentImageStyled src={SearchForSomething} alt='Search for something' />
            <Typography type={TypographyType.Body} variant={TypographyVariant.LargeRegular}>
              <b>Search for something</b>
              <br />
              Search for account, shop, <br /> user or catalog to display results
            </Typography>
          </Content>
        )}
        {isRecent && (
          <RecentSearches
            recent={recent}
            handleRecentClick={handleRecentClick}
            modalRef={modalRef}
          />
        )}
        {isNoResults && (
          <Content>
            <ContentImageStyled src={NoResults} alt='No results found' />
            <Typography type={TypographyType.Body} variant={TypographyVariant.LargeRegular}>
              <b>No results found</b>
              <br />
              Try adjusting your search <br /> to find what you are looking for
            </Typography>
          </Content>
        )}
        <AdminSearchResultsSection
          shops={results?.shops}
          accounts={results?.accounts}
          users={results?.users}
          catalogs={results?.catalogs}
          dispatch={dispatch}
          onSelect={onSelect}
          shopDefaultRoute={shopDefaultRoute}
          accountDefaultRoute={accountDefaultRoute}
          userDefaultRoute={userDefaultRoute}
          catalogsDefaultRoute={catalogsDefaultRoute}
        />
      </AdminSearchModalStyled>
    </OverlayModalStyled>
  );
};
