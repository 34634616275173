import React, { useEffect } from 'react';
import classNames from 'classnames';
import { TextBoxProps } from '../TextBox';
import { NumericInputStyled } from './NumericInputStyled';
import { NumericInputBase, NumericInputBaseProps } from './NumericInputBase';

export interface NumericInputProps
  extends Omit<TextBoxProps, 'value' | 'type' | 'onChange' | 'children' | 'onBlur'>,
    Omit<NumericInputBaseProps, 'error'> {
  prefixId?: string;
  minNumericValue?: number;
}

export const NumericInput = ({
  onChange,
  onBlur,
  value,
  staticSymbol,
  className,
  placeholder,
  disabled,
  error,
  label,
  decimals = 0,
  thousandSeparator = ',',
  minNumericValue,
  maxNumericValue,
  prefixId,
  onClick,
  fullWidth,
  success,
  onKeyDown,
  autoComplete,
  autoFocus,
  inputRef,
}: NumericInputProps): JSX.Element => {
  useEffect(() => {
    if (autoFocus && !value) {
      inputRef?.current?.focus();
    }
  }, [autoFocus, value, inputRef]);

  return (
    <NumericInputStyled
      className={classNames('syte-numeric-input', className, { disabled }, { error })}
      disabled={disabled}
      error={error}
      success={success}
      label={label}
      onClick={onClick}
      fullWidth={fullWidth}
    >
      <NumericInputBase
        onChange={onChange}
        prefixId={prefixId}
        onBlur={onBlur}
        value={value}
        staticSymbol={staticSymbol}
        placeholder={placeholder}
        decimals={decimals}
        thousandSeparator={thousandSeparator}
        minNumericValue={minNumericValue}
        maxNumericValue={maxNumericValue}
        disabled={disabled}
        inputRef={inputRef}
        onKeyDown={onKeyDown}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
      />
    </NumericInputStyled>
  );
};
