import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Icon, MenuItem, Typography, EllipsisWithTooltip } from 'src/components-dummy';

export const CardTagsReportItemLayoutStyled = styled.div<{ shouldShowPointer: boolean }>`
  display: grid;
  grid-template-columns: 30fr 2fr 2.3fr 1fr;
  gap: 8px;
  min-height: 96px;
  max-width: 912px;
  width: 100%;
  background: #fff;
  border: solid 1px #ebebeb;
  border-radius: 8px;
  margin-bottom: 20px;
  align-items: center;
  position: relative;
  padding-top: 24px;
  padding-bottom: 24px;
  ${({ shouldShowPointer }) =>
    shouldShowPointer &&
    css`
      cursor: pointer;
    `}

  &:hover {
    border: 1px solid #c5c5c5;
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.8);
    .report-list-actions-menu-btn {
      visibility: visible;
    }
  }
`;

export const CardTagsReportSummaryStyled = styled.div`
  grid-column-start: 1;
  padding-left: 32px;
  overflow: hidden;
`;

export const CardTagsReportItemTitleStyled = styled.div`
  display: flex;
  align-items: baseline;
  width: Calc(100% - 16px);
`;

export const CardTagsReportStatusStyled = styled.div`
  grid-column-start: 2;
`;
export const CardTagsReportActionsStyled = styled.div`
  display: flex;
  justify-content: end;

  .MuiButtonBase-root {
    height: 32px;
    align-items: end !important;
  }
`;

export const CardTagsReportItemDetailsStyled = styled.div`
  display: flex;
  align-items: center;
  color: #6e6e71;
  margin-top: 8px;
  .deep-tag-reports-details-dot {
    margin: 0 8px;
  }

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ellipsis-with-tooltip {
    display: flex;
    max-width: 200px;
    width: unset;
  }
`;

export const IconReportMenuItemStyled = styled(Icon)`
  margin-right: 12px;
`;

export const ActionCaretIconStyled = styled(Icon)`
  position: absolute;
  pointer-events: none;
  right: 10px;
  stroke: #1e1e1e;
`;

export const ProcessingReportTitleStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-20']};
`;

export const ErrorTitleTextStyled = styled.div`
  color: ${({ theme }) => theme.palette.custom.red};
  margin-left: 8px;
`;

export const ErrorTitleWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ErrorIconWrapperStyled = styled.div`
  flex-basis: 16px;
  position: relative;
  top: 3px;
`;

export const MenuItemDeleteStyled = styled(MenuItem)`
  color: ${({ theme }) => theme.palette.custom.red};

  svg > path {
    stroke: ${({ theme }) => theme.palette.custom.red};
  }
`;

export const EllipsisWithTooltipStyled = styled(EllipsisWithTooltip)`
  width: 500px;
`;

export const DeepTagEditableReportNameWrapperStyled = styled.div`
  overflow: auto;
  .input-base-wrapper {
    max-width: 400px;
    padding: 2.5px 2px !important;
    border-radius: 4px;
    height: initial;
  }

  .MuiInputBase-root {
    font-size: 16px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
`;
