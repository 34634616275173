import React, { useCallback } from 'react';
import { TypographyType, TypographyVariant } from 'src/components-dummy';
import FolderImageSrc from 'src/assets/folder.png';
import {
  CardStyled,
  ImageWrapper,
  ImageStyled,
  TextWrapper,
  TitleStyled,
  SubtitleStyled,
  ButtonStyled,
} from './MissingAugmentedSearchCatalogCard.styles';

const CONTACT_SUPPORT_URL = 'https://syte-ai.atlassian.net/servicedesk/customer/portal/5';

export function MissingAugmentedSearchCatalogCard(): JSX.Element {
  const onContactSupportButtonClick = useCallback(() => {
    window.open(CONTACT_SUPPORT_URL, '_blank');
  }, []);

  return (
    <CardStyled>
      <ImageWrapper>
        <ImageStyled src={FolderImageSrc} alt='No text search catalog configured' />
      </ImageWrapper>
      <TextWrapper>
        <TitleStyled variant={TypographyVariant.LargeMedium} type={TypographyType.Body}>
          No Search Catalog Configured
        </TitleStyled>

        <SubtitleStyled variant={TypographyVariant.MediumRegular} type={TypographyType.Paragraph}>
          You cannot perform changes as no text search catalog is connected.
        </SubtitleStyled>
        <SubtitleStyled variant={TypographyVariant.MediumRegular} type={TypographyType.Paragraph}>
          Please contact your account manager to configure your text search catalog.
        </SubtitleStyled>
        <ButtonStyled variant='primary' onClick={onContactSupportButtonClick}>
          Contact Support
        </ButtonStyled>
      </TextWrapper>
    </CardStyled>
  );
}
