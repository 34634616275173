import React from 'react';
import {
  Typography,
  TypographyVariant,
  TypographyType,
  Icon,
  AvailableIcons,
  Tooltip,
} from 'src/components-dummy';
import { IShopSftpSettings } from 'src/services';
import { copyToClipboard } from 'src/utils';
import {
  SFTPCredentialsStyled,
  InfoRowStyled,
  InfoRowLabelStyled,
  InfoRowValueWrapperStyled,
  InfoRowsStyled,
  InfoRowValueStyled,
} from './DeepTagReportHowToUploadModal.styles';

interface InfoRowProps {
  label: string;
  value: string;
  display?: string;
}

const InfoRow = ({ label, value, display }: InfoRowProps): JSX.Element => {
  const onIconClick = async () => {
    copyToClipboard(value);
  };

  return (
    <InfoRowStyled>
      <InfoRowLabelStyled type={TypographyType.Paragraph} variant={TypographyVariant.MediumMedium}>
        {label}
      </InfoRowLabelStyled>
      <InfoRowValueWrapperStyled>
        <InfoRowValueStyled
          type={TypographyType.Paragraph}
          variant={TypographyVariant.MediumMedium}
        >
          {display || value}
        </InfoRowValueStyled>
        <Tooltip value='Copied' openOnTriggerClick intervalHideMillisecond={1000}>
          <Icon name={AvailableIcons.Documents} onClick={onIconClick} />
        </Tooltip>
      </InfoRowValueWrapperStyled>
    </InfoRowStyled>
  );
};

export interface SFTPCredentialsProps {
  settings?: IShopSftpSettings;
  host: string;
  port: string;
  directoryPath: string;
}

export const SFTPCredentials = ({
  settings,
  host,
  port,
  directoryPath,
}: SFTPCredentialsProps): JSX.Element => {
  const rows = [
    {
      label: 'SFTP address',
      value: host,
    },
    {
      label: 'Port',
      value: port,
    },
    {
      label: 'Folder',
      value: directoryPath,
    },
    {
      label: 'Username',
      value: settings?.userName || '',
    },
    {
      display: '*******',
      label: 'Password',
      value: settings?.password || '',
    },
  ];

  return (
    <SFTPCredentialsStyled>
      <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
        SFTP User credentials
      </Typography>
      <InfoRowsStyled>
        {rows.map(row => {
          return <InfoRow key={row.label} {...row} />;
        })}
      </InfoRowsStyled>
    </SFTPCredentialsStyled>
  );
};
