import { SimilarItemsSettingsTypes } from 'src/services';
import { diffValueDisplayHelpers } from '../value-helpers';
import { EntityDiffSchema } from '../../types';

type SimilarItemsDiffSchema = SimilarItemsSettingsTypes.SimilarItemsSettings;

export const similarItemsDiffSchema: EntityDiffSchema<
  SimilarItemsDiffSchema,
  SimilarItemsSettingsTypes.SimilarItemsSettings
> = {
  numberOfResultsToDisplay: {
    displayName: 'Number Of Results To Display',
  },
  addToCart: {
    displayName: 'Add To Cart',
    getValue({ entity }: { entity: SimilarItemsSettingsTypes.SimilarItemsSettings }): string {
      return entity?.addToCart?.active ? 'true' : diffValueDisplayHelpers.DEFAULT_EMPTY_VALUE;
    },
  },
};
