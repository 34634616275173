import { DeepTagReport, DeepTagReportStatus, DeepTagReportWithMetaData } from '../types';
import { Vertical } from '../types/constants';

const mockNames = [
  'Adelio',
  'Adlai',
  'Adolf',
  'Adonai',
  'Adoniah',
  'Adri',
  'Adriano',
  'Adwin',
  'Aidric',
  'Aimon',
  'Airell',
  'Ajani',
  'Ajax',
  'Ajay',
  'Akio',
  'Bartholomew',
  'Basil',
  'Bastien',
  'Baxter',
  'Beauregard',
  'Beck',
  'Bela',
  'Benjy',
  'Benoit',
  'Berke',
  'Bernard',
  'Bingham',
  'Birch',
  'Blade',
  'Boaz',
];

const generateId = () => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  for (let i = 0; i < 12; i++) {
    result += characters.charAt(Math.floor(Math.random() * 36));
  }
  return result;
};

function randomInt(min = 0, max = 10) {
  const minimum = Math.ceil(min);
  const maximum = Math.floor(max);
  return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
}

export const generateMockReport = (): DeepTagReport => {
  const approver = `${mockNames[Math.floor(Math.random() * mockNames.length)]}@gmail.com`;
  const reviewer = `${mockNames[Math.floor(Math.random() * mockNames.length)]}@gmail.com`;

  const createdAt = new Date();
  createdAt.setDate(createdAt.getDate() - randomInt(1, 20));

  const updatedAt = new Date();
  updatedAt.setDate(updatedAt.getDate() - randomInt(1, 20));

  return {
    id: generateId(),
    createdAt,
    shopId: 123,
    totalProducts: randomInt(5000, 20000),
    sourceFileName: 'report-input.csv',
    status: DeepTagReportStatus.New,
    locale: 'en_US',
    approver,
    reviewer,
    updatedAt,
  };
};

export const generateMockReportWithMetaData = (): DeepTagReportWithMetaData => {
  const report = generateMockReport();
  return {
    ...report,
    verticals: Object.values(Vertical) as Vertical[],
    locale: 'en_US',
  };
};
