import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { CatalogManagerService } from 'src/services';

const catalogManagerService = new CatalogManagerService();

export const catalogsListPageActions = {
  getCatalogs: createAsyncThunk(
    'CatalogsListPage/GetCatalogs',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const catalogs = await catalogManagerService.getCatalogs(shopId);
        return { catalogs };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  setCatalogAsPrimary: createAsyncThunk(
    'CatalogsListPage/SetCatalogAsPrimary',
    async ({ shopId, id }: { shopId: number; id: string }, { rejectWithValue }) => {
      try {
        const catalog = await catalogManagerService.updateCatalog({ id, shopId, isPrimary: true });
        return catalog;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  deleteCatalog: createAsyncThunk(
    'CatalogsListPage/DeleteCatalog',
    async ({ shopId, id }: { shopId: number; id: string }, { rejectWithValue }) => {
      try {
        const catalog = await catalogManagerService.deleteCatalog({ id, shopId });
        return catalog;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  navigateTo: createAction<{ navigateTo: string }>('CatalogsListPage/NavigateTo'),
};
