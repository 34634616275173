import styled from '@emotion/styled';
import { Button } from 'src/components-dummy';

export const ButtonStyled = styled(Button)`
  background-color: ${({ theme }) => theme.palette.custom.red};

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom['red-10']};
  }
`;
