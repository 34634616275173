import { useEffect, useMemo, useState } from 'react';
import { AvailableFilterKeysMap } from 'src/services/src/service/types/deep-tag-reports';
import { DeepTagReportFiltersState } from './types';

interface UseMissingAttributeValuePairsFilterProps {
  attributeValuePairs: AvailableFilterKeysMap['attributeValuePairs'];
  missingAttributeFilterState: DeepTagReportFiltersState['missingAttributeValuePairs'];
}

export const useMissingAttributeValuePairsFilter = ({
  attributeValuePairs,
  missingAttributeFilterState,
}: UseMissingAttributeValuePairsFilterProps): { value: string; text: string }[] => {
  const [attributeValuePairsOptionsInitial, setAttributeValuePairsOptionsInitial] = useState<
    { text: string; value: string }[]
  >([]);

  useEffect(() => {
    if (!attributeValuePairsOptionsInitial.length && Object.keys(attributeValuePairs).length) {
      const attributeValuePairKeys = Object.keys(attributeValuePairs);

      const attributeValueInitial = attributeValuePairKeys.map(key => {
        const attributeKeyItem = attributeValuePairs[key];
        const [attributeName, valueName] = attributeKeyItem.name.split(':');
        const text = `${attributeName} - ${valueName}`;

        return {
          text,
          value: key,
        };
      });

      setAttributeValuePairsOptionsInitial(attributeValueInitial);
    }
  }, [attributeValuePairs, attributeValuePairsOptionsInitial.length]);

  const { value: missingAttributeFilterStateValues } = missingAttributeFilterState || {};

  const missingAttributeValuePairsFilterOptions = useMemo(() => {
    const missingAttributeValueFilterStateSet = new Set(missingAttributeFilterStateValues);

    return attributeValuePairsOptionsInitial.filter(item => {
      const { value } = item;

      // Include only the new available `attributeValuePairs` (FilterKeysMap) & items already in the state.
      return attributeValuePairs[value] || missingAttributeValueFilterStateSet.has(value);
    });
  }, [attributeValuePairs, attributeValuePairsOptionsInitial, missingAttributeFilterStateValues]);

  return missingAttributeValuePairsFilterOptions;
};
