import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useParams } from 'react-router';
import { useAppSelector, usePreviousState } from 'src/hooks';
import { Page } from 'src/components-dummy';
import { RoutedComponentProps } from 'src/app-routes';
import { deepTagReportProductsContainerActions } from './Actions';
import {
  ConnectedDeepTagReportProductsPageContent,
  ConnectedDeepTagReportProductsPageContentRefMethods,
  ConnectedDeepTagReportProductsPageHeader,
} from './components';
import { DeepTagReportProductsContainerStyled } from './DeepTagReportProductsContainer.styles';

type DeepTagReportProductsContainerProps = RoutedComponentProps;

export const DeepTagReportProductsContainer = ({
  permittedRouteMap,
}: DeepTagReportProductsContainerProps): JSX.Element => {
  const dispatch = useDispatch();
  const childRef = useRef<ConnectedDeepTagReportProductsPageContentRefMethods | null>(null);
  const [lexiconSettings, setLexiconSettings] = useState<{ locales: string[] } | undefined>(
    undefined
  );

  const { reportId } = useParams<{ reportId: string; productId: string }>();

  const { shopId } = useAppSelector(state => ({
    shopId: state.shop.current?.shopId,
    selectedReport: state.deepTagReports.selectedReport,
  }));

  const prevShopId = usePreviousState(shopId);

  useEffect(() => {
    if (shopId) {
      dispatch(
        deepTagReportProductsContainerActions.getReport({
          shopId,
          reportId,
        })
      );

      (dispatch(deepTagReportProductsContainerActions.getLexiconSettings({ shopId })) as any)
        .unwrap()
        .then((settings: { locales: string[] }) => {
          setLexiconSettings(settings);
        })
        .catch((error: unknown) => {
          console.error(error);
        });
    }
  }, [reportId, shopId]);

  useEffect(() => {
    const shouldNavigate =
      prevShopId && shopId && shopId !== prevShopId && permittedRouteMap.deepTagReports;

    if (shouldNavigate) {
      dispatch(
        deepTagReportProductsContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.deepTagReports.path, { shopId }),
        })
      );
    }
  }, [shopId, permittedRouteMap.deepTagReports, prevShopId]);

  const getCleanedFiltersWithPagination = useCallback(() => {
    return childRef.current?.getCleanedFiltersWithPagination() || {};
  }, []);

  const getProductsWithFilters = async () => {
    return childRef.current?.getReportProductsWithFilters();
  };

  return (
    <DeepTagReportProductsContainerStyled
      className='deep-tag-report-products-container'
      layout='centered'
    >
      <Page.Header>
        <ConnectedDeepTagReportProductsPageHeader
          permittedRouteMap={permittedRouteMap}
          getCleanedFiltersWithPagination={getCleanedFiltersWithPagination}
          lexiconSettings={lexiconSettings?.locales}
          getProductsWithFilters={getProductsWithFilters}
        />
      </Page.Header>
      <Page.Content>
        <ConnectedDeepTagReportProductsPageContent
          permittedRouteMap={permittedRouteMap}
          ref={childRef}
        />
      </Page.Content>
      <></>
    </DeepTagReportProductsContainerStyled>
  );
};
