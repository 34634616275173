import { createAsyncThunk } from '@reduxjs/toolkit';
import { lexiconService, LexiconTagField, ILexiconTagsFilters } from 'src/services';
import { ExcludeOrMatchTagKeys, LexiconTagType } from 'src/services/src/service/lexicon/types';

export const lexiconBulkActions = {
  rename: createAsyncThunk(
    'LexiconBulkAction/Rename',
    async (
      args: {
        shopId: number;
        locale: string;
        tagType: LexiconTagType;
        matchTags: ExcludeOrMatchTagKeys['matchTags'];
        tagField: LexiconTagField;
        customTranslation: string;
      },
      { rejectWithValue }
    ) => {
      try {
        await lexiconService.bulkActionRenameTags(args);
        return undefined;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  renameAll: createAsyncThunk(
    'LexiconBulkAction/RenameAll',
    async (
      args: {
        shopId: number;
        locale: string;
        tagType: LexiconTagType;
        excludeTags: ExcludeOrMatchTagKeys['excludeTags'];
        tagField: LexiconTagField;
        customTranslation: string;
        filters: Partial<ILexiconTagsFilters>;
      },
      { rejectWithValue }
    ) => {
      try {
        await lexiconService.bulkActionRenameAllTags(args);
        return undefined;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  include: createAsyncThunk(
    'LexiconBulkAction/Include',
    async (
      args: {
        shopId: number;
        locale: string;
        tagType: LexiconTagType;
        matchTags: ExcludeOrMatchTagKeys['matchTags'];
        include: boolean;
      },
      { rejectWithValue }
    ) => {
      try {
        await lexiconService.bulkActionIncludeTags(args);
        return undefined;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  includeAll: createAsyncThunk(
    'LexiconBulkAction/IncludeAll',
    async (
      args: {
        shopId: number;
        locale: string;
        tagType: LexiconTagType;
        excludeTags: ExcludeOrMatchTagKeys['excludeTags'];
        include: boolean;
        filters: Partial<ILexiconTagsFilters>;
      },
      { rejectWithValue }
    ) => {
      try {
        await lexiconService.bulkActionIncludeAllTags({
          ...args,
        });
        return undefined;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  restore: createAsyncThunk(
    'LexiconBulkAction/Restore',
    async (
      args: {
        shopId: number;
        locale: string;
        matchTags: ExcludeOrMatchTagKeys['matchTags'];
      },
      { rejectWithValue }
    ) => {
      try {
        await lexiconService.bulkActionRestoreTags(args);
        return undefined;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  restoreAll: createAsyncThunk(
    'LexiconBulkAction/RestoreAll',
    async (
      args: {
        shopId: number;
        locale: string;
        excludeTags: ExcludeOrMatchTagKeys['excludeTags'];
        filters: Partial<ILexiconTagsFilters>;
      },
      { rejectWithValue }
    ) => {
      try {
        await lexiconService.bulkActionRestoreAllTags(args);
        return undefined;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  deleteTags: createAsyncThunk(
    'LexiconBulkAction/',
    async (
      args: {
        shopId: number;
        locale: string;
        matchTags: ExcludeOrMatchTagKeys['matchTags'];
        tagType: LexiconTagType;
      },
      { rejectWithValue }
    ) => {
      try {
        await lexiconService.bulkActionDeleteTags(args);
        return undefined;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  deleteAllTags: createAsyncThunk(
    'LexiconBulkAction/',
    async (
      args: {
        shopId: number;
        locale: string;
        excludeTags: ExcludeOrMatchTagKeys['excludeTags'];
        filters: Partial<ILexiconTagsFilters>;
        tagType: LexiconTagType;
      },
      { rejectWithValue }
    ) => {
      try {
        await lexiconService.bulkActionDeleteAllTags(args);
        return undefined;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
