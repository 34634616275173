import { useCallback, useRef, useState, RefObject } from 'react';
import { FormApiRef } from 'src/components-bl';

export function useAugmentedSearchForm(): {
  formApiRef: RefObject<FormApiRef>;
  canSubmit: boolean;
  setCanSubmit: (canSubmit: boolean) => void;
  onSubmit: () => Promise<void>;
} {
  const [canSubmit, setCanSubmit] = useState(false);

  const formApiRef = useRef<FormApiRef>(null);

  const onSubmit = useCallback(async () => {
    if (!formApiRef.current) {
      return;
    }

    try {
      await formApiRef.current.submit();
    } catch (error) {
      console.error('useAugmentedSearchForm', error);
    }
  }, []);

  return { onSubmit, formApiRef, canSubmit, setCanSubmit };
}
