import React from 'react';
import classNames from 'classnames';
import { PopUp, Button, TextBox, CardItem, AvailableIcons } from 'src/components-dummy';
import { useCreateAccountForm } from './useCreateAccountForm';
import { CreateAccountFormProps } from './types';
import './CreateAccountForm.scss';

export const CreateAccountForm = ({ dispatch, className }: CreateAccountFormProps): JSX.Element => {
  const {
    state,
    onSubmit,
    errors,
    onChange,
    isValid,
    shouldShowFormPopup,
    setShouldShowFormPopup,
  } = useCreateAccountForm({ dispatch });
  return (
    <div className={classNames('syte-create-account-form', className)}>
      <PopUp
        show={shouldShowFormPopup}
        onShow={setShouldShowFormPopup}
        hideOnScroll={false}
        position='bottom left'
      >
        <PopUp.Trigger>
          <Button
            startIcon={AvailableIcons.Add}
            variant='secondary'
            className='create-account-button'
          >
            Create Account
          </Button>
        </PopUp.Trigger>
        <PopUp.Content>
          <CardItem className='syte-create-account-form-popup-content'>
            <TextBox
              label='Account Name'
              value={state.name}
              error={!!errors.name}
              onChange={name => onChange({ name })}
              className='syte-create-account-form-text-input'
            />
            <Button onClick={onSubmit} variant='primary' disabled={!isValid}>
              Save
            </Button>
          </CardItem>
        </PopUp.Content>
      </PopUp>
    </div>
  );
};
