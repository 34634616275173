import { ShopsTableHeadRow } from '../types';

export const tableColumns: Record<string, ShopsTableHeadRow> = {
  shopId: {
    header: 'Shop Id',
    accessor: 'shopId',
    width: '20%',
  },
  shopName: {
    header: 'Shop Name',
    accessor: 'shopName',
    width: '20%',
  },
  accountId: {
    header: 'Account Id',
    accessor: 'accountId',
    width: '20%',
  },
  accountName: {
    header: 'Account Name',
    accessor: 'accountName',
    width: '20%',
  },
  createdAt: {
    header: 'Created At',
    accessor: 'createdAt',
    width: '20%',
  },
};
