import React from 'react';
import { useFieldDropdown } from './useFieldDropdown';
import { Select, SelectType, MenuItem, SelectOnChangeEvent } from '../../../../../components-dummy';
import { CategoryFilterRule } from '../../../types/category-filter-rule';

interface FieldDropdownProps {
  data?: CategoryFilterRule;
  onChange: (rule: CategoryFilterRule) => void;
}

export const FieldDropdown: React.FC<FieldDropdownProps> = ({ data, onChange }) => {
  const { dataFieldsDropdownOptions, onFieldChange } = useFieldDropdown({
    data,
    onChange,
  });

  const handleChange: SelectOnChangeEvent = event => {
    onFieldChange(event.target.value);
  };

  return (
    <Select type={SelectType.Menu} value={data?.field} onChange={handleChange}>
      {dataFieldsDropdownOptions.map((option: { value: string; text: string }) => (
        <MenuItem key={option.value} value={option.value}>
          {option.text}
        </MenuItem>
      ))}
    </Select>
  );
};
