import React, { useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import {
  ActionIcon,
  AvailableIcons,
  TypographyType,
  TypographyVariant,
} from '../../../../../../components-dummy';
import {
  dataFieldsToPartialSubRule,
  partialSubRuleToDataFields,
} from '../../../DataFieldsCondition/DataFieldsCondition.helpers';
import {
  DataFieldConditionDataInnerType,
  DataFieldConditionDataType,
} from '../../../DataFieldsCondition/DataFieldsCondition.types';
import { CategoryFilterRule } from '../../../../../VisualEditor/types/category-filter-rule';
import { MerchandisingRuleTypes } from '../../../../../../services';
import { RuleDraftCondition } from '../../MerchandisingRuleForm.config';
import {
  ConditionRowDataStyled,
  TextBoxLabel,
  TextBoxStyled,
} from './RuleFormConditionsSection.styles';

interface ConditionProps {
  condition: RuleDraftCondition;
  isError: boolean;
  onConditionChange: (changedCondition: DataFieldConditionDataType) => void;
  onDeleteCondition: (conditionToDelete: RuleDraftCondition) => void;
  isReadOnly?: boolean;
  categoryFilterRule?: CategoryFilterRule;
}

export const CategoryPageCondition = ({
  condition,
  isError,
  onConditionChange,
  onDeleteCondition,
  isReadOnly,
  categoryFilterRule,
}: ConditionProps): JSX.Element => {
  const conditionData = useMemo(() => partialSubRuleToDataFields(condition), [condition]);

  const onStateChange = useCallback(
    (partialData: Partial<DataFieldConditionDataInnerType>) => {
      const updatedState = { ...conditionData, ...partialData };
      if (!isEqual(conditionData, updatedState)) {
        onConditionChange?.(dataFieldsToPartialSubRule(updatedState));
      }
    },
    [conditionData, onConditionChange]
  );

  const onCategoryChange = useCallback(
    value => {
      onStateChange({
        conditionValue: [value],
        dataField: categoryFilterRule?.field || 'categories',
        conditionType:
          categoryFilterRule?.operator || MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
      });
    },
    [categoryFilterRule, onStateChange]
  );

  const [categoryPageValue] = conditionData.conditionValue as string[];

  return (
    <ConditionRowDataStyled>
      <TextBoxLabel type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
        Category Page is
      </TextBoxLabel>
      <TextBoxStyled
        value={categoryPageValue}
        onChange={onCategoryChange}
        placeholder='Type category here...'
        error={isError}
      />
      {!isReadOnly && (
        <ActionIcon
          iconName={AvailableIcons.TrashCan}
          onClick={() => onDeleteCondition(condition)}
        />
      )}
    </ConditionRowDataStyled>
  );
};
