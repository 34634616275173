import { useCallback, useEffect, useRef } from 'react';

export const useTimeout = (callback: VoidFunction, delay: number): VoidFunction => {
  const timerRef = useRef<number | undefined>();

  const delayedCallback = useCallback(() => {
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(callback, delay);
  }, [callback, delay]);

  useEffect(() => {
    return () => window.clearTimeout(timerRef.current);
  }, []);

  return delayedCallback;
};
