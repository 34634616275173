import React, { MouseEventHandler, useMemo } from 'react';
import classNames from 'classnames';
import { AvailableIcons } from '../Icon';
import { Keys } from '../../types';
import { useTagsInput } from './useTagsInput';
import { TagLabelProps, TagsInputProps } from './types';
import './TagsInput.scss';
import { DeleteIconStyled, ChipStyled, EllipsisWithTooltipStyled } from './TagsInput.styles';
import { Tooltip } from '../Tooltip';

const defaultDelimiters = [Keys.Enter, Keys.Comma];

const TagLabel = ({
  tag,
  onDelete,
  className,
  isAdditionalTagsLabel,
  toolTipText,
}: TagLabelProps): JSX.Element => {
  const onDeleteClick: MouseEventHandler | undefined = onDelete
    ? ev => {
        ev.stopPropagation();
        onDelete(tag.index);
      }
    : undefined;

  return (
    <ChipStyled
      label={
        isAdditionalTagsLabel ? (
          <Tooltip value={toolTipText} position='top center'>
            {tag.value}
          </Tooltip>
        ) : (
          <EllipsisWithTooltipStyled
            key={tag.value + tag.index}
            tooltipText={toolTipText || tag.value}
          >
            {tag.value}
          </EllipsisWithTooltipStyled>
        )
      }
      onDelete={onDeleteClick}
      className={classNames('tag-label', className)}
      deleteIcon={<DeleteIconStyled name={AvailableIcons.Close} className='delete-icon' />}
    />
  );
};

export const TagsInput = ({
  tags: values,
  className,
  onChange,
  delimiters = defaultDelimiters,
  allowDuplicates = true,
  caseSensitive = false,
  disabled,
  error,
  maxTagsToDisplay,
  placeholder = 'Type ...',
}: TagsInputProps): JSX.Element => {
  const { onInputChange, onInputKeyDown, onInputBlur, tags, deleteTag, inputValue } = useTagsInput({
    values,
    allowDuplicates,
    caseSensitive,
    delimiters,
    onChange,
    disabled,
    maxTagsToDisplay,
  });

  const numberOfAdditionalTags = values.length - tags.length;

  const titleTextForAdditionalValues = values.slice(tags.length);

  const shouldShowNumberOfAdditionalTags = numberOfAdditionalTags > 0;

  const numberOfAdditionalItemsTag = useMemo(
    () =>
      shouldShowNumberOfAdditionalTags ? (
        <TagLabel
          tag={{
            value: `+${numberOfAdditionalTags}`,
            id: `+${numberOfAdditionalTags}`,
            index: tags.length,
          }}
          isAdditionalTagsLabel
          toolTipText={titleTextForAdditionalValues.join(', ')}
          key='additional-items-tag'
          className='additional-items-tag'
        />
      ) : null,
    [numberOfAdditionalTags, tags, shouldShowNumberOfAdditionalTags]
  );

  return (
    <div
      className={classNames('syte-tags-input', className, 'syte-input-base', { error, disabled })}
    >
      {[
        ...tags.map((tag, index) => (
          <TagLabel tag={tag} key={index.toString() + tag.value} onDelete={deleteTag} />
        )),
        numberOfAdditionalItemsTag,
      ]}
      <input
        type='text'
        value={inputValue}
        placeholder={tags.length > 0 ? '' : placeholder}
        onChange={onInputChange}
        onKeyDown={onInputKeyDown}
        onBlur={onInputBlur}
        className='input'
        disabled={disabled}
      />
    </div>
  );
};
