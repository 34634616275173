import { FilterValue, IdType, Row } from 'react-table';

export function textFilter<T extends Record<string, unknown>>(
  rows: Array<Row<T>>,
  id: IdType<T>,
  filterValue: FilterValue
): Array<Row<T>> {
  return rows.filter(row => {
    const rowValue = row.values[id];
    return rowValue !== undefined
      ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
      : true;
  });
}

// Let the table remove the filter if the string is empty
textFilter.autoRemove = (val: any) => !val;
