import { ImageTag } from 'src/services';

export function calculatePosition({
  position,
  max,
  min,
}: {
  position: number;
  max: number;
  min: number;
}): number {
  return Math.max(Math.min(position, max), min);
}

export function imageTagToDisplayValue(option: Pick<ImageTag, 'sku' | 'title'>): string {
  return `${option.title} (${option.sku})`;
}
