import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AvailableIcons,
  Button,
  Link,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { isEqual } from 'lodash';
import { useValidateSchema } from 'src/hooks';
import { BackDropWithLoader } from 'src/components-dummy/Backdrop/BackdropWithLoader';
import {
  ThematicTagFormStyled,
  HeaderStyled,
  MainStyled,
  HeaderActionButtonsStyled,
  DescriptionStyled,
  LinkStyled,
  LinkIconStyled,
} from './ThematicTagForm.styles';
import { FormSection } from './components/FormSection';
import * as mapper from './mapper';
import { ThematicTagFormProps, IThematicTagDraft } from './ThematicTagForm.types';
import { thematicTagFormActions } from './ThematicTagForm.actions';
import { thematicTagValidationSchema, initialThematicTagDraft } from './constants';
import { ThematicTagRow, ConditionGroups } from './components';
import { useAvailableAttributes } from './use-available-attributes';

export const ThematicTagForm = React.memo(
  ({
    shopId,
    mode,
    onClose,
    thematicTag,
    dispatch,
    locale,
    onSuccess,
  }: ThematicTagFormProps): JSX.Element => {
    const [isSubmitInProgress, setIsSubmitInProgress] = useState(false);

    const [availableCategoryKeys, setAvailableCategoryKeys] = useState<string[]>([]);

    const [isDirty, setIsDirty] = useState(false);

    const mappedTagDraftInitial: IThematicTagDraft = useMemo(() => {
      return thematicTag ? mapper.mapThematicTagDraft(thematicTag) : initialThematicTagDraft;
    }, [thematicTag]);

    const [thematicTagDraftState, setThematicTagDraftState] = useState(mappedTagDraftInitial);

    const { availableAttributeKeysByCategory } = useAvailableAttributes({
      dispatch,
      locale,
      shopId,
      thematicTagDraftState,
    });

    const { errors, validate, isValid } = useValidateSchema<IThematicTagDraft>({
      schema: thematicTagValidationSchema,
      validateOnStart: false,
      initialData: mappedTagDraftInitial,
    });

    const onChange = useCallback(
      (partialNewState: Partial<IThematicTagDraft>) => {
        setThematicTagDraftState((currentState: IThematicTagDraft) => {
          const { updatedState } = mapper.calculateNewThematicDraftState({
            currentState,
            partialNewState,
          });

          validate({ dataToValidate: updatedState });

          const newIsDirty = !isEqual(mappedTagDraftInitial, updatedState);
          setIsDirty(newIsDirty);

          return updatedState;
        });
      },
      [validate, mappedTagDraftInitial]
    );

    const canSubmit = useMemo(() => isDirty && isValid, [isValid, isDirty]);

    const isCreateMode = mode === 'create';

    const onSubmit = useCallback(() => {
      if (!canSubmit || !thematicTagDraftState) return;

      setIsSubmitInProgress(true);

      if (isCreateMode) {
        (
          dispatch(
            thematicTagFormActions.createThematicTag({
              shopId,
              locale,
              payload: mapper.mapThematicTagDraftToApi({ ...thematicTagDraftState, include: true }),
            })
          ) as any
        )
          .unwrap()
          .then(() => {
            setIsDirty(false);

            dispatch(thematicTagFormActions.notifyIsDirty({ isDirty: false }));

            setIsSubmitInProgress(false);
            onClose();

            onSuccess();
          })
          .catch((error: unknown) => {
            console.error(error);
            setIsSubmitInProgress(false);
          });
      } else if (thematicTag?.id) {
        (
          dispatch(
            thematicTagFormActions.updateThematicTag({
              shopId,
              locale,
              id: thematicTag.id,
              partialTagToUpdate: mapper.mapThematicTagDraftToApi({
                ...thematicTagDraftState,
                include: thematicTag.include,
              }),
            })
          ) as any
        )
          .unwrap()
          .then(() => {
            setIsDirty(false);

            dispatch(thematicTagFormActions.notifyIsDirty({ isDirty: false }));

            setIsSubmitInProgress(false);
            onClose();

            onSuccess();
          })
          .catch((error: unknown) => {
            console.error(error);
            setIsSubmitInProgress(false);
          });
      }
    }, [thematicTagDraftState, canSubmit, isCreateMode, shopId, locale, onClose, onSuccess]);

    useEffect(() => {
      if (!thematicTag) return;

      const mappedTag = mapper.mapThematicTagDraft(thematicTag);

      setThematicTagDraftState(mappedTag);
      setIsDirty(false);
    }, [thematicTag]);

    useEffect(() => {
      dispatch(thematicTagFormActions.notifyIsDirty({ isDirty }));
    }, [isDirty]);

    // Fetch available category keys
    useEffect(() => {
      (
        dispatch(
          thematicTagFormActions.getAvailableKeys({
            shopId,
            locale,
            searchTerm: '',
            tagField: 'category',
          })
        ) as any
      )
        .unwrap()
        .then(({ availableValues }: { availableValues: string[] }) => {
          setAvailableCategoryKeys(availableValues);
        })
        .catch((error: unknown) => {
          console.error(error);
          setAvailableCategoryKeys([]);
        });
    }, [shopId, locale]);

    return (
      <>
        {isSubmitInProgress && <BackDropWithLoader />}

        <ThematicTagFormStyled>
          <HeaderStyled>
            <Typography type={TypographyType.Heading} variant={TypographyVariant.SmallBold}>
              {isCreateMode ? 'Create' : 'Edit'} thematic tag
            </Typography>
            <HeaderActionButtonsStyled>
              <Button variant='tertiary' onClick={onClose}>
                Cancel
              </Button>
              <Button variant='primary' onClick={onSubmit} disabled={!canSubmit}>
                {isCreateMode ? 'Create and close' : 'Save and close'}
              </Button>
            </HeaderActionButtonsStyled>
          </HeaderStyled>
          <MainStyled>
            <DescriptionStyled>
              <Typography type={TypographyType.Body} variant={TypographyVariant.LargeBold}>
                Thematic tags, how to create them?
              </Typography>
              <Typography type={TypographyType.Paragraph} variant={TypographyVariant.LargeBold}>
                To create a new thematic tag, define a rule set based on existing source tags.
              </Typography>
              <LinkStyled href='https://support.syte.ai/space/ET/2924609542/How+to+use+the+Thematic+Tags'>
                <Link.Text type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                  Learn more about thematic tags
                  <LinkIconStyled name={AvailableIcons.ArrowRec} />
                </Link.Text>
              </LinkStyled>
            </DescriptionStyled>

            <FormSection
              title='1. Construct your tag'
              subTitle='You can name the tag based on the source tag or create a custom name'
            >
              <ThematicTagRow
                errors={errors}
                category={thematicTagDraftState.category}
                attribute={thematicTagDraftState.attribute}
                value={thematicTagDraftState.value}
                onChange={onChange}
                shopId={shopId}
                locale={locale}
                dispatch={dispatch}
              />
            </FormSection>
            <FormSection title='2. Define the rules for creating your thematic tag:'>
              <ConditionGroups
                errors={errors}
                conditionsGroups={thematicTagDraftState.conditionsGroups}
                onChange={onChange}
                availableCategoryKeys={availableCategoryKeys}
                availableAttributeKeysByCategory={availableAttributeKeysByCategory}
                shopId={shopId}
                locale={locale}
                dispatch={dispatch}
              />
            </FormSection>
          </MainStyled>
        </ThematicTagFormStyled>
      </>
    );
  }
);
