import { FiltersConfigTypes } from 'src/services';
import { DomainForRendering } from '../../types';
import { filterSetItemsDiffSchema } from './filters-set-diff-schema';

const catalogFilterTitle = 'Catalog Filters';
export const filterSetItemsSubDomainGroup: DomainForRendering<
  FiltersConfigTypes.CatalogFiltersSettings | FiltersConfigTypes.FilterItemDBStructure
> = {
  getCardTitle: ({ newEntity, oldEntity, entityPath }) => {
    const filterSetEntity = newEntity ?? oldEntity;
    const titleResult = [`Filters`];
    switch (entityPath) {
      case 'filters.items': {
        titleResult.push(catalogFilterTitle);
        titleResult.push((`"${filterSetEntity?.displayNameKey}"` as string) || 'unknown');
        break;
      }
      case 'filters.settings': {
        titleResult.push(catalogFilterTitle);
        titleResult.push('Settings');
        break;
      }
      case 'settings': {
        titleResult.push('General Settings');
        break;
      }
      case 'deepTags.settings': {
        titleResult.push('Syte Generated Filters');
        titleResult.push('Settings');
        break;
      }
      case 'deepTags.items': {
        titleResult.push('Syte Generated Filters');
        titleResult.push((`"${filterSetEntity?.displayNameKey}"` as string) || 'unknown');
        break;
      }
      default:
        break;
    }

    return titleResult.join(' > ');
  },
  fieldsSchema: filterSetItemsDiffSchema,
};
