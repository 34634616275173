import React, { MutableRefObject, useMemo } from 'react';
import { Dispatch } from 'src/components-bl/types';
import { ImageSize } from 'src/components-dummy';
import { CustomInspirationsGalleryImage, ImageTag } from 'src/services';
import { AddNewTagsPopUp, ProductTagPopUp, TagWithAppliedSuggestion } from './components';
import { SkuConfiguration } from 'src/components-bl/ProductCard/ProductCard';

export interface ProductTagsProps {
  image: CustomInspirationsGalleryImage;
  dispatch: Dispatch;
  shopId: number;
  onAddNewTag?: (newTag: ImageTag) => void;
  removeTagById?: (id: string) => void;
  canAddNewTags: boolean;
  imageRef: MutableRefObject<HTMLImageElement | null>;
  imageSize: ImageSize | null;
  showTags: boolean;
  canDeleteTags: boolean;
  loading: boolean;
  productInformationLookup: Record<string, SkuConfiguration>;
}

export const ProductTags = ({
  image,
  dispatch,
  shopId,
  onAddNewTag,
  canAddNewTags,
  imageRef,
  imageSize,
  removeTagById,
  showTags,
  canDeleteTags,
  loading,
  productInformationLookup,
}: ProductTagsProps): JSX.Element => {
  const allTags: TagWithAppliedSuggestion[] = useMemo(() => {
    const mappedAiTags = image.aiSuggestedTags.map(tag => ({
      ...tag,
      isAIDetected: true,
      isApplied: false,
    }));

    const mappedRegularTags = image.tags.map(tag => ({ ...tag, isApplied: true }));

    return [...mappedRegularTags, ...mappedAiTags];
  }, [image]);

  return (
    <>
      {canAddNewTags && !!onAddNewTag ? (
        <AddNewTagsPopUp
          image={image}
          dispatch={dispatch}
          shopId={shopId}
          onAddNewTag={onAddNewTag}
          imageRef={imageRef}
          imageSize={imageSize}
        />
      ) : null}

      {showTags &&
        allTags.map(tag => {
          const onRemove = () => removeTagById?.(tag.id);
          return (
            <ProductTagPopUp
              key={tag.id}
              tag={tag}
              onRemove={onRemove}
              enableDelete={canDeleteTags}
              imageSize={imageSize}
              showPopUp={false}
              loading={loading}
              productInformationLookup={productInformationLookup}
            />
          );
        })}
    </>
  );
};
