import styled from '@emotion/styled';
import { Icon, Typography } from 'src/components-dummy';

export const DeepTagsProductStyled = styled.div`
  height: 100%;
`;

/**
 * Header
 */
export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
`;

export const MainHeaderProductStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;

  & > div:first-of-type {
    flex: 1;
    overflow: auto;
  }

  header {
    color: ${({ theme }) => theme.palette.common.black};
  }

  footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${({ theme }) => theme.palette.custom['gray-10']};
  }
`;

export const HeaderGenerativeProductStyled = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

  header {
    color: ${({ theme }) => theme.palette.common.black};
  }

  footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${({ theme }) => theme.palette.custom['gray-10']};
  }
`;

export const FooterActionsStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 32px 16px 24px;
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  gap: 8px;

  & > button {
    margin-left: 8px;
  }
`;

export const HeaderSeparatorStyled = styled.div`
  margin: 0 4px;
`;

export const TagsListContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
`;

export const ProductTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
  padding-bottom: 8px;

  & > div:first-of-type {
    min-width: 0px;
  }
`;

/**
 * Editable Title
 */
export const DeepTagProductEditableTitleWrapper = styled.div`
  margin-bottom: 8px;
  display: flex;

  .input-base-wrapper {
    padding: 0 !important;
    height: 26px !important;
  }

  input {
    padding: 3px 2px 4px 2px !important;
    font-style: normal;
    font-size: 14px;
    height: 19px !important;
    font-weight: 700;
    line-height: 19px !important;
    font-family: 'Inter' !important;
    letter-spacing: 0em;
  }
`;

/**
 * Main (content)
 */
export const MainStyled = styled.div`
  display: flex;
  height: Calc(100vh - 163px);
`;

/**
 * Images container
 */
export const ImagesContainerStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.common.black};
  flex: 5;
  align-items: center;
  height: auto;
`;

export const ImagesContainerTitleStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 6px;
  padding: 16px 40px 16px 40px;
  color: ${({ theme }) => theme.palette.common.white};
`;
export const ImagesListStyled = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: calc(100vh - 215px);
  padding: 0 40px;

  &:hover {
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px ${({ theme }) => theme.palette.custom['gray-10']};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.palette.custom['gray-10']};
      border-radius: 10px;
    }
  }
`;

export const ImageWrapperStyled = styled.div`
  width: 100%;
  min-width: 200px;
  margin-bottom: 8px;

  > img {
    width: 100%;
    object-fit: cover;
  }
`;

/**
 * Tags container (right side panel)
 */
export const TagsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
  min-width: 420px;
  height: 100%;
`;

export const TagsContainerHeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px 8px 40px;

  & > span:first-of-type {
    color: ${({ theme }) => theme.palette.common.black};
  }
`;

export const ProductActionsAndDetailsStyled = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const CloseXButtonStyled = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-left: 4px;

  path {
    stroke: ${({ theme }) => theme.palette.common.black};
  }
`;
