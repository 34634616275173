import React, { useCallback, useState } from 'react';

import { TableV2, TableV2InstanceProps } from '../../../components-dummy';
import { CollectionsTableSortableColumnHeader } from './CollectionsTableSortableColumnHeader';

import {
  CollectionsSortingOptionValue,
  CollectionsTableHeadRow,
  SortBy,
  TableSortState,
} from './types';

import { tableColumns } from './consts';

interface Props {
  headers: TableV2InstanceProps<any>['headerGroups'][0]['headers'];
  columns: CollectionsTableHeadRow[];
  handleSortingValue: (sortingValue: CollectionsSortingOptionValue) => void;
}

export const CollectionsTableHeaderColumns: React.FC<Props> = ({
  headers,
  columns,
  handleSortingValue,
}) => {
  const initialSortOptions: TableSortState = {
    columnName: SortBy.LastUpdated,
    sortAscending: true,
  };

  const [sortOptions, setSortOptions] = useState(initialSortOptions);

  const onSortChange = useCallback(
    (newSortState: TableSortState) => {
      setSortOptions({
        columnName: newSortState.columnName,
        sortAscending: newSortState.sortAscending,
      });
    },
    [setSortOptions]
  );

  const renderHeaderColumn = ({ column }: { column: any }) => {
    switch (column.id) {
      case tableColumns.name.accessor:
      case tableColumns.lastUpdated.accessor: {
        const sortAscending =
          sortOptions.columnName === column.id ? sortOptions.sortAscending : undefined;

        return (
          <CollectionsTableSortableColumnHeader
            column={column}
            sortAscending={sortAscending}
            onSortChange={onSortChange}
            handleSortingValue={handleSortingValue}
          />
        );
      }

      default:
        return <TableV2.HeadRowCellText>{column.render('header')}</TableV2.HeadRowCellText>;
    }
  };

  return (
    <>
      {headers.map((column, index) => (
        <TableV2.HeadRow
          {...column.getHeaderProps()}
          width={columns[index].width}
          minWidth={columns[index].minWidth}
          key={column.id}
        >
          {renderHeaderColumn({ column })}
        </TableV2.HeadRow>
      ))}
    </>
  );
};
