import React, { useCallback } from 'react';
import {
  AvailableIcons,
  Checkbox,
  TableV2,
  TableV2InstanceProps,
  SelectAllState,
} from 'src/components-dummy';
import { ChangesFilterValues } from 'src/services';
import { getTableColumns } from '../LexiconTable.config';
import { LexiconChangesHeaderStyled } from '../LexiconTable.styles';
import { ILexiconTableSortState, LexiconTableHeaderMenuFilters } from '../types';
import { LexiconChangesFilter } from './LexiconChangesFilter';
import { LexiconTableSortableColumnHeader, OnSortChange } from './LexiconTableSortableColumnHeader';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';

export interface LexiconTableHeaderColumnsProps {
  headers: TableV2InstanceProps<any>['headerGroups'][0]['headers'];
  selectAllState: SelectAllState;
  onSelectAll: (isSelected: boolean) => void;
  onHeaderMenuFiltersChange: (args: LexiconTableHeaderMenuFilters) => void;
  menuFilters: LexiconTableHeaderMenuFilters;
  excludedIds: Set<string>;
  includedIds: Set<string>;
  sortState: ILexiconTableSortState;
  tagType: LexiconTagType;
  onSortChange: OnSortChange;
}

export const LexiconTableHeaderColumns = ({
  headers,
  selectAllState,
  onSelectAll,
  onHeaderMenuFiltersChange,
  menuFilters,
  includedIds,
  excludedIds,
  sortState,
  tagType,
  onSortChange,
}: LexiconTableHeaderColumnsProps): JSX.Element => {
  const tableColumns = getTableColumns({
    tagType,
  });

  const onChangesFilterChanged = useCallback(
    (values: ChangesFilterValues[]) => {
      onHeaderMenuFiltersChange({ changes: values });
    },
    [onHeaderMenuFiltersChange]
  );

  const renderHeaderColumn = ({ column }: { column: any }) => {
    switch (column.id) {
      case tableColumns.selected.accessor: {
        const checkedIcon =
          includedIds.size + excludedIds.size > 0 ? AvailableIcons.Minus : undefined;

        return (
          <TableV2.HeadRowCellText>
            <Checkbox
              checked={selectAllState.checked}
              onChange={onSelectAll}
              iconName={checkedIcon}
            />
          </TableV2.HeadRowCellText>
        );
      }

      case tableColumns.changes.accessor:
        return (
          <LexiconChangesHeaderStyled>
            <TableV2.HeadRowCellText>{column.render('Header')}</TableV2.HeadRowCellText>
            <LexiconChangesFilter
              onChange={onChangesFilterChanged}
              selectedValues={menuFilters.changes}
            />
          </LexiconChangesHeaderStyled>
        );
      case tableColumns.category.accessor:
      case tableColumns.attribute.accessor:
      case tableColumns.value.accessor:
      case tableColumns.verticals?.accessor: {
        const sortAscending =
          sortState.columnName === column.id ? sortState.sortAscending : undefined;

        return (
          <LexiconTableSortableColumnHeader
            column={column}
            sortAscending={sortAscending}
            onSortChange={onSortChange}
          />
        );
      }

      case tableColumns.masterTag.accessor: {
        const sortAscending =
          sortState.columnName === column.id ? sortState.sortAscending : undefined;

        return (
          <LexiconTableSortableColumnHeader
            column={column}
            sortAscending={sortAscending}
            onSortChange={onSortChange}
            tooltipText='These values are original values from Syte that provide the base of your lexicon.'
          />
        );
      }

      default:
        return <TableV2.HeadRowCellText>{column.render('Header')}</TableV2.HeadRowCellText>;
    }
  };

  return (
    <>
      {headers.map(column => (
        <TableV2.HeadRow
          {...column.getHeaderProps()}
          width={column.width as string}
          minWidth={column.minWidth}
          maxWidth={(tableColumns as any)[column.id].maxWidth}
          key={column.id}
        >
          {renderHeaderColumn({ column })}
        </TableV2.HeadRow>
      ))}
    </>
  );
};
