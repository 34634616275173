import React, { useCallback, useEffect, useState, useRef } from 'react';
import { DeepTagReportsGeneralConfigurationLabel } from 'src/services/src/service/types/deep-tag-reports';
import { Dispatch } from 'src/components-bl';
import { debounce } from 'lodash';
import {
  AutoCompleteOption,
  AutocompleteRenderGetTagProps,
} from 'src/components-dummy/AutoComplete';
import { Chip } from 'src/components-dummy/Chip';
import { DeepTagsReportsProductLabelAutoCompleteActions } from './Actions/DeepTagsReportsProductLabelAutoComplete.actions';
import { AutoCompleteWrapperStyled } from './DeepTagsReportsProductLabelAutoComplete.style';
import { DeepTagReportsLabelAutoComplete } from '../DeepTagReportProductsBulkLabelModal/components/DeepTagReportsLabelAutoComplete/DeepTagReportsLabelAutoComplete';
import { DeepTagsReportsProductLabelReadOnly } from './components/DeepTagsReportsProductLabelReadOnly';
import { MAX_TAGS_TO_SHOW } from './DeepTagsReportsProductLabelAutoComplete.config';

const UPDATE_PRODUCT_DEBOUNCE_TIME = 150;
const ESCAPE_KEY = [27, 'Escape'];

export interface DeepTagsReportsSingleLabelAutoCompleteProps {
  dispatch: Dispatch;
  generalConfigurationLabels: DeepTagReportsGeneralConfigurationLabel[];
  shopId: number;
  reportId: string;
  productId: string;
  defaultLabelValue?: string;
  productCurrentLabelIds?: string[];
  locale: string;
  onLabelChangeSuccess?: () => void; // callback for additional actions
}

export const DeepTagsReportsProductLabelAutoComplete = ({
  shopId,
  reportId,
  productId,
  locale,
  productCurrentLabelIds,
  generalConfigurationLabels,
  onLabelChangeSuccess,
  dispatch,
}: DeepTagsReportsSingleLabelAutoCompleteProps): JSX.Element => {
  const [selectedValues, setSelectedValues] = useState<string[]>(productCurrentLabelIds || []);
  const [errorValidationMessages, setErrorValidationMessages] = useState<string[]>([]);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const autoCompleteRef = useRef<HTMLDivElement>(null);

  const updateProduct = useCallback(
    debounce(async (labelIds: string[]): Promise<void> => {
      const thunkPromiseResponse: any = await dispatch(
        DeepTagsReportsProductLabelAutoCompleteActions.updateProduct({
          shopId,
          locale,
          reportId,
          productId,
          labels: labelIds,
        })
      );

      if (thunkPromiseResponse?.payload?.product) {
        onLabelChangeSuccess?.();
      }
    }, UPDATE_PRODUCT_DEBOUNCE_TIME),
    [dispatch, locale, productId, reportId, shopId]
  );

  const onSelectedValuesChange = useCallback(
    (selectedLabelIds: string[]) => {
      setSelectedValues(selectedLabelIds);
      updateProduct(selectedLabelIds);
    },
    [updateProduct]
  );

  const onErrorsChange = useCallback((errors: string[]) => {
    setErrorValidationMessages(errors);
  }, []);

  const renderTags = useCallback(
    (value: AutoCompleteOption[], getTagProps: AutocompleteRenderGetTagProps) => {
      const totalLeft = value.length - MAX_TAGS_TO_SHOW;
      const labelsToShow = value.slice(0, MAX_TAGS_TO_SHOW);

      const chipsToShow = labelsToShow.map((tag, index) => {
        return <Chip {...getTagProps({ index })} key={tag.value} label={tag.title} />;
      });

      const additionalTags = () => {
        if (value.length > MAX_TAGS_TO_SHOW) {
          return [
            <Chip
              {...getTagProps({ index: chipsToShow.length })}
              key={value[value.length - 1].value}
              label={`+${totalLeft}`}
            />,
          ];
        }
        return [];
      };

      return [...chipsToShow, ...additionalTags()];
    },
    []
  );

  const onToggleIsReadOnly = useCallback(() => {
    setIsReadOnly(prevState => !prevState);
  }, []);

  const onAutoCompleteKeyDown = useCallback(
    (event: React.KeyboardEvent & { code: string }) => {
      if (ESCAPE_KEY.includes(event.key) || ESCAPE_KEY.includes(event.code)) {
        onToggleIsReadOnly();
      }
    },
    [onToggleIsReadOnly]
  );

  const handleClickOutsideComponent = useCallback(
    (event: MouseEvent) => {
      if (isReadOnly) return;

      const target = event.target as Element;

      if (autoCompleteRef.current && !autoCompleteRef.current.contains(target)) {
        if (
          // Exceptions - (only possible to distinguish with classes..)
          target.classList.contains('MuiAutocomplete-option') ||
          target.classList.contains('ellipsis-with-tooltip')
        ) {
          return;
        }

        // Set state after the current event loop
        setTimeout(() => {
          setIsReadOnly(true);
        }, 0);
      }
    },
    [isReadOnly]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideComponent, true);
    return () => {
      document.removeEventListener('click', handleClickOutsideComponent, true);
    };
  }, [handleClickOutsideComponent]);

  useEffect(() => {
    setSelectedValues(productCurrentLabelIds || []);
  }, [productCurrentLabelIds]);

  return (
    <AutoCompleteWrapperStyled>
      {isReadOnly ? (
        <DeepTagsReportsProductLabelReadOnly
          selectedValues={selectedValues}
          generalConfigurationLabels={generalConfigurationLabels}
          onToggleReadOnly={onToggleIsReadOnly}
        />
      ) : (
        <div ref={autoCompleteRef}>
          <DeepTagReportsLabelAutoComplete
            freeSolo
            multiple
            dispatch={dispatch}
            setSelectedValues={onSelectedValuesChange}
            selectedValues={selectedValues}
            placeholder='Select a value'
            onErrors={onErrorsChange}
            isError={!!errorValidationMessages.length}
            renderTags={renderTags}
            onAutoCompleteBlur={onToggleIsReadOnly}
            onKeyDown={onAutoCompleteKeyDown}
            disabled={false}
          />
        </div>
      )}
    </AutoCompleteWrapperStyled>
  );
};
