import React from 'react';
import { RoutedComponentProps } from 'src/app-routes';
import { FiltersListPage } from 'src/components-bl/Filters/components';
import { useDataFieldsBySupportedFeature } from 'src/components-bl/hooks';
import { SyteLayout, ShopDataFieldsSupportedFeatures } from 'src/services';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useDataFieldsLookupTable } from 'src/components-bl/hooks/useDataFieldsLookupTable';

interface FiltersListContainerProps extends RoutedComponentProps {
  syteLayout: SyteLayout;
  hasReachedMaxAllowedFiltersLimit: boolean | undefined;
}

export const FiltersListContainer = ({
  syteLayout,
  permittedRouteMap,
  hasReachedMaxAllowedFiltersLimit,
}: FiltersListContainerProps): JSX.Element => {
  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const filters = useAppSelector(state => state.filters.filters);
  const dataFields = useAppSelector(state => state.dataFields.dataFields);

  const dispatch = useAppDispatch();

  const { supportedDataFields } = useDataFieldsBySupportedFeature({
    dataFields,
    feature: ShopDataFieldsSupportedFeatures.Filters,
  });

  const { dataFieldsLookupTable } = useDataFieldsLookupTable({ dataFields: supportedDataFields });

  return (
    <FiltersListPage
      filters={filters}
      shopId={shopId}
      syteLayout={syteLayout}
      dispatch={dispatch}
      permittedRouteMap={permittedRouteMap}
      hasReachedMaxAllowedFiltersLimit={hasReachedMaxAllowedFiltersLimit}
      dataFieldsLookupTable={dataFieldsLookupTable}
    />
  );
};
