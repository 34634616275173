import React, { useMemo, useEffect } from 'react';
import { generatePath } from 'react-router';
import { useDispatch } from 'react-redux';
import { MerchandisingRulesListPage } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesListPage';
import { useDataFieldsLookupTable } from 'src/components-bl/MerchandisingRules/components/useDataFieldsLookupTable';
import { MerchandisingRulesFiltersState } from 'src/components-bl/MerchandisingRules/components/MerchandisingRulesListPage/components';
import { ShopDataFieldsSupportedFeatures } from 'src/services';
import { useDataFieldsBySupportedFeature } from 'src/components-bl/hooks';
import { PermittedRouteMap } from 'src/app-routes';
import { useAppSelector } from 'src/hooks';
import { merchandisingRulesListContainerActions } from './MerchandisingRulesListContainer.actions';

interface MerchandisingRulesListPageProps {
  permittedRouteMap: PermittedRouteMap;
  filtersState: MerchandisingRulesFiltersState;
  setFiltersState: (value: MerchandisingRulesFiltersState) => void;
}

export const MerchandisingRulesListContainer = ({
  permittedRouteMap,
  filtersState,
  setFiltersState,
}: MerchandisingRulesListPageProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const rules = useAppSelector(state => state.merchandisingRules.rules);
  const totalRulesCount = useAppSelector(state => state.merchandisingRules.totalCount);

  const overview = useAppSelector(state => state.merchandisingRules.overview);
  const dataFields = useAppSelector(state => state.dataFields.dataFields);
  const availableRegions = useAppSelector(state => state.shop.availableRegions);

  const onCreateRule = () => {
    if (permittedRouteMap.createMerchandisingRule && shopId) {
      dispatch(
        merchandisingRulesListContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.createMerchandisingRule.path, { shopId }),
        })
      );
    }
  };

  const { supportedDataFields } = useDataFieldsBySupportedFeature({
    dataFields,
    feature: ShopDataFieldsSupportedFeatures.MerchandisingRules,
  });

  const dataFieldsLookupTable = useDataFieldsLookupTable({ dataFields: supportedDataFields });

  const isDataFieldsLookupReady: boolean = useMemo(() => {
    return dataFieldsLookupTable.dataFieldsLookupTable !== undefined;
  }, [dataFieldsLookupTable.dataFieldsLookupTable]);

  useEffect(() => {
    if (shopId) {
      dispatch(
        merchandisingRulesListContainerActions.getDataFields({
          shopId,
        })
      );
    }
  }, [shopId]);

  return shopId && isDataFieldsLookupReady ? (
    <MerchandisingRulesListPage
      onCreateRule={onCreateRule}
      shopId={shopId}
      rules={rules}
      overview={overview}
      dispatch={dispatch}
      dataFields={dataFields}
      merchandisingRulesEditPageRoute={permittedRouteMap.editMerchandisingRule}
      availableRegions={availableRegions}
      filtersState={filtersState}
      setFiltersState={setFiltersState}
      totalRulesCount={totalRulesCount}
      {...dataFieldsLookupTable}
    />
  ) : null;
};
