import { useCallback } from 'react';

import { useAppSelector } from '../../hooks';
import { useWysiwyg } from './Item/useWysiwyg';

import type { UiTemplatesProps } from './types';

interface ReturnType {
  open: () => void;
}
export const useOpenEditor = ({
  shopId,
  dispatch,
  openPopup,
  targetTemplate,
}: UiTemplatesProps): ReturnType => {
  const shopUrl = useAppSelector(state => state.shop.current?.shopUrl);

  const { openEditor } = useWysiwyg({ dispatch, shopId, targetTemplate });

  const open = useCallback(() => {
    if (!shopUrl) {
      openPopup();
    }
    openEditor();
  }, [shopUrl, openPopup, openEditor]);

  return { open };
};
