import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import {
  GetTypographyConfigOrDefaultArguments,
  StyledElementProps,
  TypographyType,
  TypographyVariant,
  VariantStyleByTypeMap,
} from './types';

export const defaultType = TypographyType.Body;

export const BaseTypography = css`
  font-style: normal;
`;

export const getTypographyConfig = ({
  weight,
  size,
  lineHeight,
  letterSpacing,
}: {
  size: number;
  weight: number;
  lineHeight: number;
  letterSpacing: number;
}): SerializedStyles => {
  return css`
    ${BaseTypography as any}
    font-size: ${size}px;
    font-weight: ${weight};
    line-height: ${lineHeight}px;
    font-family: 'Inter' !important;
    letter-spacing: ${letterSpacing}em;
  `;
};

export const typeToStylesMap: VariantStyleByTypeMap = {
  [TypographyType.Body]: {
    [TypographyVariant.LargeBold]: getTypographyConfig({
      weight: 700,
      size: 16,
      lineHeight: 16,
      letterSpacing: 0,
    }),
    [TypographyVariant.LargeMedium]: getTypographyConfig({
      weight: 500,
      size: 16,
      lineHeight: 24,
      letterSpacing: 0,
    }),
    [TypographyVariant.LargeRegular]: getTypographyConfig({
      weight: 400,
      size: 16,
      lineHeight: 24,
      letterSpacing: 0,
    }),

    [TypographyVariant.MediumBold]: getTypographyConfig({
      weight: 700,
      size: 14,
      lineHeight: 16,
      letterSpacing: 0,
    }),
    [TypographyVariant.MediumMedium]: getTypographyConfig({
      weight: 500,
      size: 14,
      lineHeight: 16,
      letterSpacing: 0,
    }),
    [TypographyVariant.MediumRegular]: getTypographyConfig({
      weight: 400,
      size: 14,
      lineHeight: 16,
      letterSpacing: 0,
    }),

    [TypographyVariant.SmallBold]: getTypographyConfig({
      weight: 700,
      size: 12,
      lineHeight: 16,
      letterSpacing: 0,
    }),
    [TypographyVariant.SmallMedium]: getTypographyConfig({
      weight: 500,
      size: 12,
      lineHeight: 16,
      letterSpacing: 0,
    }),
    [TypographyVariant.SmallRegular]: getTypographyConfig({
      weight: 400,
      size: 12,
      lineHeight: 16,
      letterSpacing: 0,
    }),

    [TypographyVariant.ExtraSmallBold]: getTypographyConfig({
      weight: 700,
      size: 10,
      lineHeight: 16,
      letterSpacing: 0,
    }),
    [TypographyVariant.ExtraSmallMedium]: getTypographyConfig({
      weight: 600,
      size: 10,
      lineHeight: 16,
      letterSpacing: 0,
    }),
    [TypographyVariant.ExtraSmallRegular]: getTypographyConfig({
      weight: 400,
      size: 10,
      lineHeight: 16,
      letterSpacing: 0,
    }),
  },
  [TypographyType.Button]: {
    [TypographyVariant.MediumMedium]: getTypographyConfig({
      weight: 500,
      size: 14,
      lineHeight: 16,
      letterSpacing: 0,
    }),
    [TypographyVariant.SmallMedium]: getTypographyConfig({
      weight: 500,
      size: 12,
      lineHeight: 16,
      letterSpacing: 0,
    }),
  },
  [TypographyType.Heading]: {
    [TypographyVariant.ExtraLargeBold]: getTypographyConfig({
      weight: 800,
      size: 64,
      lineHeight: 80,
      letterSpacing: -0.02,
    }),
    [TypographyVariant.LargeBold]: getTypographyConfig({
      weight: 700,
      size: 32,
      lineHeight: 48,
      letterSpacing: -0.02,
    }),
    [TypographyVariant.MediumBold]: getTypographyConfig({
      weight: 700,
      size: 28,
      lineHeight: 32,
      letterSpacing: -0.01,
    }),
    [TypographyVariant.SmallBold]: getTypographyConfig({
      weight: 700,
      size: 22,
      lineHeight: 32,
      letterSpacing: -0.02,
    }),
    [TypographyVariant.ExtraSmallBold]: getTypographyConfig({
      weight: 700,
      size: 20,
      lineHeight: 32,
      letterSpacing: 0,
    }),
  },
  [TypographyType.Paragraph]: {
    [TypographyVariant.LargeRegular]: getTypographyConfig({
      weight: 400,
      size: 16,
      lineHeight: 24,
      letterSpacing: 0,
    }),
    [TypographyVariant.MediumRegular]: getTypographyConfig({
      weight: 400,
      size: 14,
      lineHeight: 22,
      letterSpacing: 0,
    }),
    [TypographyVariant.SmallRegular]: getTypographyConfig({
      weight: 400,
      size: 12,
      lineHeight: 20,
      letterSpacing: 0,
    }),
  },
};

export const defaultVariantByTypeMap: Record<TypographyType, TypographyVariant> = {
  [TypographyType.Body]: TypographyVariant.MediumRegular,
  [TypographyType.Button]: TypographyVariant.MediumMedium,
  [TypographyType.Heading]: TypographyVariant.MediumBold,
  [TypographyType.Paragraph]: TypographyVariant.MediumRegular,
};

export const getTypographyConfigOrDefault = ({
  variant,
  type,
}: GetTypographyConfigOrDefaultArguments): SerializedStyles => {
  const defaultVariant = defaultVariantByTypeMap[type];
  const fallBackStyles = typeToStylesMap[type][defaultVariant] as SerializedStyles;
  const variantStyles = variant && typeToStylesMap[type][variant];

  if (!variantStyles) return fallBackStyles;
  return variantStyles;
};

export const H1Styled = styled.h1(
  ({ variant, type = defaultType, className }: StyledElementProps) => {
    const styles = getTypographyConfigOrDefault({ variant, type, className });

    return styles;
  }
);

export const SpanStyled = styled.span(
  ({ variant, type = defaultType, className }: StyledElementProps) => {
    const styles = getTypographyConfigOrDefault({ variant, type, className });

    return styles;
  }
);

export const ParagraphStyled = styled.p(
  ({ variant, type = defaultType, className }: StyledElementProps) => {
    const styles = getTypographyConfigOrDefault({ variant, type, className });

    return styles;
  }
);
