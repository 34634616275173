/* eslint-disable react/no-unescaped-entities */
import { css } from '@emotion/react';
import React from 'react';
import {
  AvailableIcons,
  Checkbox,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { Box } from 'src/components-dummy/Box';
import { InfoStyled } from '../../CatalogForm.styles';
import {
  VerticalSelectContainerStyled,
  VerticalSelectHeaderStyled,
} from './CatalogFormVerticalsSection.styles';

export interface CatalogFormVerticalItemProps {
  title: string;
  description: string;
  iconName?: AvailableIcons;
  isChecked: boolean;
  onClick: () => void;
}

export const CatalogFormVerticalItem = React.memo(
  ({
    title,
    description,
    iconName,
    isChecked,
    onClick,
  }: CatalogFormVerticalItemProps): JSX.Element => {
    return (
      <VerticalSelectContainerStyled isChecked={isChecked} onClick={onClick}>
        <Checkbox checked={isChecked} />
        <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '16px' }}>
          <VerticalSelectHeaderStyled>
            {iconName && <Icon name={iconName} />}
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              {title}
            </Typography>
          </VerticalSelectHeaderStyled>

          <InfoStyled
            css={css`
              margin-bottom: 6px;
            `}
            type={TypographyType.Body}
            variant={TypographyVariant.SmallMedium}
          >
            {description}
          </InfoStyled>
        </Box>
      </VerticalSelectContainerStyled>
    );
  }
);
