import styled from '@emotion/styled';
import { Icon, SectionBox, Typography } from 'src/components-dummy';

export const ApiKeysContainerStyled = styled.div`
  max-width: 752px;
  padding-top: 24px;
`;

export const AddApiKeyBtnDisabledStyled = styled(Typography)`
  display: flex;
  text-align: center;
  color: #878787;
  path {
    stroke: #878787;
  }
`;

export const SectionBoxApiKeyStyled = styled(SectionBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
`;

export const ApiKeyRowStyled = styled.div`
  display: flex;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  border-radius: 12px;
  padding: 16px;
  margin-top: 16px;
`;

export const ApiKeyMetaDataStyled = styled.div`
  width: 50%;
`;

export const ApiKeyLabelStyled = styled(Typography)<{ color: string }>`
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${({ color }) => color};
`;

export const ApiKeyLabelWithPointerStyled = styled.span`
  cursor: pointer;
`;

export const ApiKeyCreatedStyled = styled(Typography)`
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #6e6e71;
`;

export const CopyDeleteIconsDivStyled = styled.div`
  width: 50%;
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const IconDotSplitterStyled = styled(Icon)`
  margin: 0 4px 0 4px;
`;

export const IconCopyStyled = styled(Icon)<{ color: string }>`
  margin-right: 10px;
  path {
    stroke: ${({ color }) => color};
  }
`;

export const IconLockStyled = styled(Icon)`
  margin-left: 5px;
`;

export const IconTrashStyled = styled(Icon)`
  path {
    stroke: #1e1e1e;
  }
`;
