import styled from '@emotion/styled';
import { TableV2, Typography } from 'src/components-dummy';
import { Skeleton } from 'src/components-dummy/Skeleton';

export const DataFieldsTableFormStyled = styled.div`
  .syte-form-card-button {
    margin-left: auto;
  }
`;

export const OriginalNameCellTextStyled = styled(TableV2.BodyRowCellText)`
  color: ${({ theme }) => theme.palette.custom['primary-main']};
`;

export const DataFieldTableRowStyled = styled(TableV2.BodyRow)`
  padding: 10px 0;
`;

export const DataFieldTableRowSkeletonStyled = styled(Skeleton)`
  margin-bottom: 20px;
`;

export const NoDataFieldsTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['red-80']};
`;
