import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from 'src/components-dummy';

export const VerticalSelectContainerStyled = styled.div<{ isChecked: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 32px;
  height: 136px;
  min-width: 210px;
  align-items: flex-start;
  padding: 24px 16px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 12px;
  border: 1px solid;
  border-color: ${({ theme, isChecked }) =>
    isChecked ? theme.palette.custom['gray-60'] : theme.palette.custom['primary-80']};

  cursor: pointer;

  ${({ isChecked, theme }) =>
    isChecked &&
    css`box-shadow: 
    0px 0px 0px 1.5px ${theme.palette.custom['primary-main']}, 0px 0px 0px 2.5px ${theme.palette.custom['primary-90']}};`}
`;

export const ImportMethodRadioButtonsContainerStyled = styled.div`
  display: flex;
  margin-top: 16px;
  border-radius: 12px;
  flex-direction: column;
  padding: 24px auto;

  .MuiFormGroup-root {
    flex: 1;
  }

  span.MuiButtonBase-root.MuiRadio-root {
    padding-top: 2px;
    margin-bottom: 0;
  }

  .MuiFormControlLabel-label {
    padding-left: 4px;
  }
`;

export const ButtonCopyClipboardStyled = styled(Button)`
  flex: 1;
  margin-top: 24px;

  &:not([disabled]) {
    svg > path {
      stroke: white;
    }
  }

  &:disabled {
    svg > path {
      stroke: ${({ theme }) => theme.palette.custom['gray-50']};
    }
  }

  & > span {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;
  }
`;

export const ImportedMethodsSectionStyled = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  padding: 24px;
  border-radius: 12px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;
