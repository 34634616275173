import { ShopTypes } from 'src/services';

export enum ShopFeatureTogglesFormActionTypes {
  GetFeatureTogglesRequest = 'ShopFeatureTogglesForm/GetFeatureTogglesRequest',
  GetFeatureTogglesSuccess = 'ShopFeatureTogglesForm/GetFeatureTogglesSuccess',
  GetFeatureTogglesError = 'ShopFeatureTogglesForm/GetFeatureTogglesError',
  UpdateFeatureTogglesRequest = 'ShopFeatureTogglesForm/UpdateFeatureTogglesRequest',
  UpdateFeatureTogglesSuccess = 'ShopFeatureTogglesForm/UpdateFeatureTogglesSuccess',
  UpdateFeatureTogglesError = 'ShopFeatureTogglesForm/UpdateFeatureTogglesError',
  NotifyIsDirty = 'ShopFeatureTogglesForm/NotifyIsDirty',
}

export interface ShopFeatureTogglesFormGetFeatureTogglesRequestActionPayload {
  shopId: number;
}

export interface ShopFeatureTogglesFormGetFeatureTogglesSuccessActionPayload {
  featureToggles: ShopTypes.ShopFeatureToggles;
}

export interface ShopFeatureTogglesFormGetFeatureTogglesErrorActionPayload {
  error: unknown;
}

export interface ShopFeatureTogglesFormNotifyIsDirtyActionPayload {
  isDirty: boolean;
}

export type ShopFeatureTogglesFormUpdateFeatureTogglesRequestActionPayload =
  ShopTypes.UpdateFeatureToggles;
export type ShopFeatureTogglesFormUpdateFeatureTogglesSuccessActionPayload =
  ShopFeatureTogglesFormGetFeatureTogglesSuccessActionPayload;

export type ShopFeatureTogglesFormUpdateFeatureTogglesErrorActionPayload =
  ShopFeatureTogglesFormGetFeatureTogglesErrorActionPayload;

export interface ShopFeatureTogglesFormGetFeatureTogglesRequestAction {
  type: typeof ShopFeatureTogglesFormActionTypes.GetFeatureTogglesRequest;
  payload: ShopFeatureTogglesFormGetFeatureTogglesRequestActionPayload;
}

export interface ShopFeatureTogglesFormGetFeatureTogglesSuccessAction {
  type: typeof ShopFeatureTogglesFormActionTypes.GetFeatureTogglesSuccess;
  payload: ShopFeatureTogglesFormGetFeatureTogglesSuccessActionPayload;
}

export interface ShopFeatureTogglesFormGetFeatureTogglesErrorAction {
  type: typeof ShopFeatureTogglesFormActionTypes.GetFeatureTogglesError;
  payload: ShopFeatureTogglesFormGetFeatureTogglesErrorActionPayload;
}

export interface ShopFeatureTogglesFormUpdateFeatureTogglesRequestAction {
  type: typeof ShopFeatureTogglesFormActionTypes.UpdateFeatureTogglesRequest;
  payload: ShopFeatureTogglesFormUpdateFeatureTogglesRequestActionPayload;
}

export interface ShopFeatureTogglesFormUpdateFeatureTogglesSuccessAction {
  type: typeof ShopFeatureTogglesFormActionTypes.UpdateFeatureTogglesSuccess;
  payload: ShopFeatureTogglesFormUpdateFeatureTogglesSuccessActionPayload;
}

export interface ShopFeatureTogglesFormUpdateFeatureTogglesErrorAction {
  type: typeof ShopFeatureTogglesFormActionTypes.UpdateFeatureTogglesError;
  payload: ShopFeatureTogglesFormUpdateFeatureTogglesErrorActionPayload;
}

export interface ShopFeatureTogglesFormNotifyIsDirtyAction {
  type: typeof ShopFeatureTogglesFormActionTypes.NotifyIsDirty;
  payload: ShopFeatureTogglesFormNotifyIsDirtyActionPayload;
}

export type ShopFeatureTogglesFormAction =
  | ShopFeatureTogglesFormGetFeatureTogglesRequestAction
  | ShopFeatureTogglesFormGetFeatureTogglesSuccessAction
  | ShopFeatureTogglesFormGetFeatureTogglesErrorAction
  | ShopFeatureTogglesFormUpdateFeatureTogglesRequestAction
  | ShopFeatureTogglesFormUpdateFeatureTogglesSuccessAction
  | ShopFeatureTogglesFormUpdateFeatureTogglesErrorAction
  | ShopFeatureTogglesFormNotifyIsDirtyAction;
