import React, { useMemo, useState, useCallback } from 'react';
import { Dispatch } from 'src/components-bl/types';
import {
  DeepTag,
  DeepTagScoreType,
  DeepTagStatusEnum,
  ILexiconAttribute,
  ILexiconCategory,
  DeepTagSourceEnum,
} from 'src/services';
import {
  updateTagsFormDataWithNewValue,
  extractUnusedAttributesFromLexicon,
} from '../../DeepTagsProductForm.helpers';
import { OnChangeSubProductTagsFormData } from '../DeepTagsProductReviewAndEditTagsSection/useDeepTagsSubProductsForm';
import { DeepTagProductTagsListProps } from './DeepTagProductTags/DeepTagSubProductTagsList';
import { DeepTagForm, OnRemoveOrRestoreTag } from '../../DeepTagsProductForm.types';

type TagToRemove = Partial<DeepTagForm> | undefined;

interface UseDeepTagsManagementResponse {
  hasUnusedAttributesFromLexicon: boolean;
  unusedAttributesFromLexicon: Record<string, ILexiconAttribute>;
  tagToRemove: TagToRemove;
  onAddTagAttributesSelect: (attributes: string[]) => void;
  onAttributeValueChange: DeepTagProductTagsListProps['onAttributeValueChange'];
  onRemoveOrRestoreClick: OnRemoveOrRestoreTag;
  onConfirmedRemoveTag: () => void;
  onCancelRemoveTag: () => void;
}

interface UseDeepTagsManagementProps {
  tagsFromServer: DeepTag[];
  formTags?: DeepTagForm[];
  productCategoryLexicon?: ILexiconCategory;
  isDirty: boolean;
  onChangeTags: OnChangeSubProductTagsFormData;
  dispatch: Dispatch;
}

/**
 * Manages DeepTags current opened form actions upon received formTags.
 * Changes the formTags state and passes it to "onChangeTags" for storing state form changes.
 */
export const useDeepTagsManagement = ({
  tagsFromServer,
  formTags,
  productCategoryLexicon,
  onChangeTags,
}: UseDeepTagsManagementProps): UseDeepTagsManagementResponse => {
  const [tagToRemove, setTagToRemove] = useState<TagToRemove>(undefined);

  /**
   * Tag by attribute key/value - tags from server
   */
  const apiTagsByAttributeMap = useMemo(() => {
    return tagsFromServer.reduce((accumulator: Record<string, DeepTag>, nextTag) => {
      accumulator[nextTag.attribute.key] = nextTag;
      return accumulator;
    }, {});
  }, [tagsFromServer]);

  /**
   * Update `tagsFormData` state with updates & setDirty incase it's needed
   */
  const setTagsFormDataAndIsDirty = useCallback(
    (updatedTagsFormData: DeepTagForm[]) => {
      onChangeTags({ tags: updatedTagsFormData });
    },
    [onChangeTags]
  );

  const onAttributeValueChange: DeepTagProductTagsListProps['onAttributeValueChange'] = ({
    valueKey,
    attributeKey,
  }) => {
    const selectedValue = productCategoryLexicon?.attributes[attributeKey].values[valueKey];
    if (selectedValue) {
      const newFormDataTagsState = updateTagsFormDataWithNewValue({
        selectedValueItem: selectedValue,
        tagsFormData: formTags || [],
        apiTagsByAttributeMap,
        selectedAttribute: attributeKey,
      });

      setTagsFormDataAndIsDirty(newFormDataTagsState);
    }
  };

  /**
   * Adds to the form state - attribute with empty value.
   * @param attributes - selected attributes from lexicon - add to form
   */
  const onAddTagAttributesSelect = (attributes: string[]) => {
    const newFormAttributes = attributes.map((attribute: string): DeepTagForm => {
      const lexiconAttribute = productCategoryLexicon?.attributes?.[attribute];

      const newTagBase: Pick<DeepTagForm, 'hasAddedInForm' | 'status' | 'score' | 'scoreType'> = {
        hasAddedInForm: true,
        status: DeepTagStatusEnum.New,
        score: 1,
        scoreType: DeepTagScoreType.High,
      };

      const newTag: DeepTagForm = {
        source: lexiconAttribute?.isThematic
          ? DeepTagSourceEnum.Thematic
          : DeepTagSourceEnum.Lexicon,
        attribute: {
          key: lexiconAttribute?.key as string,
          translation: lexiconAttribute?.translation as string,
        },
        value: {
          key: undefined,
          translation: undefined,
        },
        ...newTagBase,
      };

      return newTag;
    });

    const newFormDataTagsState = [...(formTags || []), ...newFormAttributes];

    setTagsFormDataAndIsDirty(newFormDataTagsState);
  };

  /**
   * New - Added in the form -> will be filtered out.
   * Existed - will be added to `tagToRemove` for confirmation dialog.
   * Restore deleted - change status from tag - "deleted" to original tag status from API.
   */
  const onRemoveOrRestoreClick: OnRemoveOrRestoreTag = useCallback(
    ({ attribute, hasAddedInForm, status }) =>
      (event: React.SyntheticEvent) => {
        event.stopPropagation();

        const shouldRestore = status === DeepTagStatusEnum.Deleted;

        // Remove tag completely (just added in form)
        if (hasAddedInForm) {
          const updatedTagsFormData = (formTags || []).filter(
            tag => tag.attribute.key !== attribute.key
          );

          setTagsFormDataAndIsDirty(updatedTagsFormData);
          return;
        }

        // Restore (replace with tag from server)
        if (shouldRestore) {
          const updatedTagsFormData = (formTags || []).map(tag => {
            return tag.attribute.key === attribute.key
              ? {
                  ...apiTagsByAttributeMap[tag.attribute.key],
                }
              : tag;
          });

          setTagsFormDataAndIsDirty(updatedTagsFormData);
          setTagToRemove(undefined);
          return;
        }

        // Add tag to remove (for - confirmation dialog & item indication)
        setTagToRemove({ attribute, hasAddedInForm, status });
      },
    [apiTagsByAttributeMap, formTags, setTagsFormDataAndIsDirty]
  );

  const onConfirmedRemoveTag = useCallback(() => {
    const { attribute } = tagToRemove || {};

    // If tag has not been added just now - mark it as status 'deleted'
    const updatedTagsFormData = (formTags || []).map(tag => {
      return tag.attribute === attribute
        ? {
            ...tag,
            status: DeepTagStatusEnum.Deleted,
          }
        : tag;
    });

    setTagsFormDataAndIsDirty(updatedTagsFormData);
    setTagToRemove(undefined);
  }, [formTags, setTagsFormDataAndIsDirty, tagToRemove]);

  const onCancelRemoveTag = () => {
    setTagToRemove(undefined);
  };

  /**
   * Retrieves the unused attributes (in category) out of the existed in form
   */
  const unusedAttributesFromLexicon: Record<string, ILexiconAttribute> = useMemo(() => {
    const unusedAttributes = extractUnusedAttributesFromLexicon({
      tagsFormData: formTags || [],
      lexiconAttributes: productCategoryLexicon?.attributes || {},
    });

    return unusedAttributes;
  }, [formTags, productCategoryLexicon?.attributes]);

  const hasUnusedAttributesFromLexicon = useMemo(() => {
    return Object.keys(unusedAttributesFromLexicon).length > 0;
  }, [unusedAttributesFromLexicon]);

  return {
    hasUnusedAttributesFromLexicon,
    unusedAttributesFromLexicon,
    tagToRemove,
    onAddTagAttributesSelect,
    onAttributeValueChange,
    onRemoveOrRestoreClick,
    onConfirmedRemoveTag,
    onCancelRemoveTag,
  };
};
