import { AdData, MerchandisingRuleTypes, PreviewFeature } from 'src/services';
import { IndexedProductOffers, PreviewMerchRule, ProductOffers } from '../types';
import { MerchandisingRuleWithExtraIndications } from '../../MerchandisingRules/components/MerchandisingRulesListPage/components';
import { MerchandiseRule } from '../../../services/src/service/types/shops';
import { compareMerchRules } from './compare-rules';
import { ShopFeature } from '../../../app-types';
import { SyteProductType } from '../../../services/src/service/types/syte-products';

export class VisualEditorMapper {
  static mapOffersData({
    offers,
    total,
    merchRulesDebugData,
    originalRequest,
  }: ProductOffers): IndexedProductOffers {
    const resultOffers = offers.map((offer: AdData, i: number) => {
      return { ...offer, index: i + 1 };
    });

    return { offers: resultOffers, total, merchRulesDebugData, originalRequest };
  }

  static addPreviewIndicators(
    merchRules: MerchandisingRuleWithExtraIndications[] | null,
    isRuleInvalid: (rule: MerchandisingRuleWithExtraIndications) => boolean,
    isRuleApplied: (rule: MerchandisingRuleWithExtraIndications) => boolean
  ): PreviewMerchRule[] | null {
    if (!merchRules) return null;
    return merchRules
      .map(rule => {
        return {
          ...rule,
          isApplied: isRuleApplied(rule),
          error: isRuleInvalid(rule),
        };
      })
      .sort(compareMerchRules);
  }

  static addDefaultIndicators(
    merchRules: MerchandiseRule[] | null,
    isRuleApplied: (rule: MerchandiseRule) => boolean
  ): PreviewMerchRule[] | null {
    if (!merchRules) return null;
    return merchRules
      .map(rule => {
        return {
          ...rule,
          isPublished: true,
          isUsingInvalidDataField: false,
          hasOnlyValidRegions: false,
          isInvalidEmptyRegions: false,
          isApplied: isRuleApplied(rule),
          error: false,
        };
      })
      .sort(compareMerchRules);
  }

  static convertFeatureToMerchProduct(
    feature: PreviewFeature | null
  ): MerchandisingRuleTypes.MerchandisingRuleProduct | undefined {
    switch (feature) {
      case ShopFeature.SimilarItems:
        return SyteProductType.SimilarItems;
      case ShopFeature.Collections:
        return SyteProductType.Collections;
      case ShopFeature.DiscoveryButton:
        return SyteProductType.DiscoveryButton;
      case ShopFeature.ShopTheLook:
        return SyteProductType.ShopTheLook;
      case ShopFeature.BrowsePLP:
        return SyteProductType.Collections;
      case ShopFeature.AugmentedSearch:
        return SyteProductType.AugmentedSearch;
      default:
        return undefined;
    }
  }
}
