import React from 'react';
import classNames from 'classnames';
import { times } from 'lodash';
import { useFindComponentByType } from '../hooks';
import { Icon, AvailableIcons } from '../Icon';
import { SideNavArguments, SideNavGroups } from './types';
import * as Actions from './Actions/SideNavActions';
import './SideNav.scss';
import { SideNavItemSkeleton } from './components/SideNavItemSkeleton';
import { SideNavListItem } from './components/SideNavListItem';
import { SideNavGroup } from './components/SideNavGroup';

const ShopSelector = ({ children }: { children: JSX.Element }): JSX.Element => children;

const SKELETON_ITEMS_COUNT = 10;

export const SideNav = ({
  dispatch,
  routes,
  className,
  isMinimized,
  setMinimized,
  children,
  showShopSearch,
  isLoading,
}: SideNavArguments): JSX.Element => {
  const handleNavigate = (path: string | undefined) => {
    if (path) {
      dispatch(Actions.navigateTo(path));
    }
  };

  const shopSelector = useFindComponentByType({ children, type: ShopSelector });
  const groups = Object.values(SideNavGroups);
  const isGroupRoutes = routes.some(route => Boolean(route.group));

  return (
    <div className={classNames('syte-side-navigation', className, { minimized: isMinimized })}>
      <div className='open-close-button' onClick={setMinimized}>
        <div className='open-close-button-rounded-corner sm' />
        <div className='open-close-button-rounded-corner lg' />
        <Icon
          name={AvailableIcons.Back}
          className={classNames('arrow-button-icon', { minimized: isMinimized })}
        />
      </div>

      {showShopSearch && shopSelector && !isMinimized && shopSelector}
      <div className='syte-side-navigation-items'>
        <ul className='syte-side-navigation-items-list'>
          {isLoading &&
            times(SKELETON_ITEMS_COUNT, index => (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index}>
                <SideNavItemSkeleton key={index} />
              </li>
            ))}
          {!isLoading &&
            (isGroupRoutes
              ? groups.map(group => (
                  <SideNavGroup
                    key={group}
                    group={group}
                    routes={routes}
                    isMinimized={isMinimized}
                    handleNavigate={handleNavigate}
                    setMinimized={setMinimized}
                  />
                ))
              : routes.map(route => (
                  <SideNavListItem
                    key={route.title}
                    route={route}
                    isMinimized={isMinimized}
                    handleNavigate={handleNavigate}
                    setMinimized={setMinimized}
                  />
                )))}
        </ul>
      </div>
    </div>
  );
};

SideNav.ShopSelector = ShopSelector;
