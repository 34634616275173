import { useState, useEffect, MutableRefObject, useCallback } from 'react';

export interface UseIsAnimatingArguments<T> {
  ref: MutableRefObject<T>;
  onAnimationStart?: () => void;
  onAnimationEnd?: () => void;
}

export interface UseIsAnimatingReturnType {
  isAnimating: boolean;
}

export const useIsAnimating = <T>({
  ref,
  onAnimationEnd,
  onAnimationStart,
}: UseIsAnimatingArguments<T>): UseIsAnimatingReturnType => {
  const [isAnimating, setIsAnimating] = useState(false);

  const onTransitionStart = useCallback(() => {
    setIsAnimating(true);
    onAnimationStart?.();
  }, [ref.current, onAnimationStart]);

  const onTransitionEnd = useCallback(() => {
    setIsAnimating(false);
    onAnimationEnd?.();
  }, [ref.current, onAnimationEnd]);

  useEffect(() => {
    if (ref.current) {
      (ref.current as any).addEventListener('transitionstart', onTransitionStart);
      (ref.current as any).addEventListener('transitionend', onTransitionEnd);
    }

    return () => {
      if (ref.current) {
        (ref.current as any).removeEventListener('transitionstart', onTransitionStart);
        (ref.current as any).removeEventListener('transitionend', onTransitionEnd);
      }
    };
  }, [ref.current, onTransitionEnd, onTransitionStart]);

  return { isAnimating };
};
