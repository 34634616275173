import styled from '@emotion/styled';
import { EllipsisWithTooltip, Icon, TableV2 } from 'src/components-dummy';

export const DeleteCellStyled = styled(Icon)``;

export const TableRowCellStyled = styled(TableV2.BodyRowCell)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
`;

export const EllipsisWithTooltipStyled = styled(EllipsisWithTooltip)<{
  shouldBeGrayedOut: boolean;
}>`
  color: ${({ theme, shouldBeGrayedOut }) =>
    shouldBeGrayedOut ? theme.palette.custom['gray-30'] : theme.palette.common.black};

  cursor: pointer;
  width: 100%;
  user-select: none;
`;

export const ValueCellStyled = styled.div`
  display: flex;
  width: 100%;

  .syte-popup-trigger {
    display: flex;
    justify-content: center;
    flex-direction: column;

    width: 100%;
    height: 40px;
  }
`;
