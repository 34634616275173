import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router';
import { RoutedComponentProps } from 'src/app-routes';
import { ShopFeature } from 'src/app-types';
import { ShopDataFieldsSupportedFeatures } from 'src/services';
import { useAppSelector, usePreviousState, useIsFeaturePermitted } from 'src/hooks';
import { CreateMerchandisingRulePage } from 'src/components-bl/MerchandisingRules/components/CreateMerchandisingRulePage';
import { RuleFormFooter } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm/RuleFormFooter/RuleFormFooter';
import { useDataFieldsBySupportedFeature } from 'src/components-bl/hooks';
import { RuleApiRef } from 'src/components-bl/MerchandisingRules/components/MerchandisingRuleForm';
import { useDataFieldsLookupTable } from 'src/components-bl/MerchandisingRules/components/useDataFieldsLookupTable';
import { createMerchandisingRuleContainerActions } from './CreateMerchandisingRuleContainer.actions';
import { ButtonStyled } from './CreateMerchandisingRuleContainer.styles';

export const CreateMerchandisingRuleContainer = ({
  permittedRouteMap,
}: RoutedComponentProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const shopId = useAppSelector(state => state.shop.current?.shopId);
  const previousShopId = usePreviousState(shopId);
  const dataFields = useAppSelector(state => state.dataFields.dataFields);
  const overview = useAppSelector(state => state.merchandisingRules.overview);
  const duplicateRuleDraft = useAppSelector(state => state.merchandisingRules.duplicateRuleDraft);
  const availableRegions = useAppSelector(state => state.shop.availableRegions);
  const isAiTagsEnabled = useIsFeaturePermitted(ShopFeature.MerchandisingRulesAiTags);
  const categoryFilterRule = useAppSelector(state => state.visualEditor.categoryFilterRule);
  const featureToggles = useAppSelector(state => state.shop.featureToggles);

  const [formStatus, setFormStatus] = useState<{
    canSubmit: boolean;
    isDirty?: boolean;
    isValid?: boolean;
    isSubmitting?: boolean;
  }>({ canSubmit: false, isSubmitting: false });

  const formApiRef = useRef(null as RuleApiRef | null);

  const navigateToList = () => {
    if (permittedRouteMap.merchandisingRules?.path && shopId) {
      dispatch(
        createMerchandisingRuleContainerActions.navigateTo({
          navigateTo: generatePath(permittedRouteMap.merchandisingRules?.path, { shopId }),
        })
      );
    }
  };

  useEffect(() => {
    if (
      previousShopId &&
      shopId !== previousShopId &&
      permittedRouteMap.merchandisingRules &&
      shopId
    ) {
      navigateToList();
    } else if (shopId) {
      dispatch(
        createMerchandisingRuleContainerActions.getDataFields({
          shopId,
        })
      );
    }
  }, [shopId]);

  const { supportedDataFields } = useDataFieldsBySupportedFeature({
    dataFields,
    feature: ShopDataFieldsSupportedFeatures.MerchandisingRules,
  });

  const { dataFieldsLookupTable, getDataFieldByName } = useDataFieldsLookupTable({
    dataFields: supportedDataFields,
  });

  return shopId ? (
    <>
      <CreateMerchandisingRulePage
        shopId={shopId}
        dispatch={dispatch}
        dataFieldsLookupTable={dataFieldsLookupTable}
        getDataFieldByName={getDataFieldByName}
        formApiRef={formApiRef}
        onFormStatusChange={setFormStatus}
        kpiOptions={overview?.kpis}
        rule={duplicateRuleDraft}
        availableRegions={availableRegions}
        isAiTagsEnabled={isAiTagsEnabled}
        categoryFilterRule={categoryFilterRule}
        featureToggles={featureToggles}
      />
      <RuleFormFooter>
        <ButtonStyled
          variant='tertiary'
          onClick={() => {
            navigateToList();
          }}
        >
          Cancel
        </ButtonStyled>
        <ButtonStyled
          disabled={!formStatus.canSubmit}
          variant='primary'
          onClick={async () => {
            if (!formApiRef.current) {
              return;
            }

            setFormStatus(status => ({ ...status, isSubmitting: true }));

            try {
              await formApiRef.current.submit();

              navigateToList();
            } catch (error) {
              console.error('CreateMerchandisingRuleContainer', error);
            }

            setFormStatus(status => ({ ...status, isSubmitting: false }));
          }}
          loading={formStatus.isSubmitting}
        >
          Create
        </ButtonStyled>
      </RuleFormFooter>
    </>
  ) : null;
};
