import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  TypographyType,
  TypographyVariant,
  AvailableIcons,
  Menu,
  MenuItem,
} from 'src/components-dummy';
import { FilterTagStyled, RemoveFilterTagIconStyled } from '../FiltersRow.styles';
import { BooleanFilterTagProps } from '../types';

export const BooleanFilterTag = ({
  filterKey,
  onRemoveFilter,
  isEditable,
  options,
  enabledTagText = '',
  disabledTagText = '',
  filterState,
  onChange,
  shouldTriggerOpen,
  onCloseFilter,
  tagPrefix,
}: BooleanFilterTagProps): JSX.Element => {
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(null);

  const menuOpened = !!menuAnchorEl;

  const onTriggerClick = () => {
    if (isEditable) {
      setMenuAnchorEl(triggerRef.current);
    }
  };

  const onClose = () => {
    setMenuAnchorEl(null);

    if (filterState?.isApplied === false) {
      onRemoveFilter?.(filterKey);
    } else {
      onCloseFilter?.();
    }
  };

  const onOptionClick = (value: boolean) => {
    onClose();
    onChange({ [filterKey]: { value, isApplied: true } });
  };

  useEffect(() => {
    if (isEditable && shouldTriggerOpen) {
      setMenuAnchorEl(triggerRef.current);
    }
  }, [shouldTriggerOpen]);

  return (
    <>
      <FilterTagStyled isClickable={isEditable} onClick={onTriggerClick} ref={triggerRef}>
        <Typography type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
          {tagPrefix || 'Value is'}
        </Typography>

        {filterState?.isApplied && (
          <Typography type={TypographyType.Body} variant={TypographyVariant.SmallBold}>
            {filterState?.value ? enabledTagText : disabledTagText}
          </Typography>
        )}
        <RemoveFilterTagIconStyled
          name={AvailableIcons.Close}
          onClick={() => onRemoveFilter?.(filterKey)}
        />
      </FilterTagStyled>
      <Menu open={menuOpened} anchorEl={menuAnchorEl} onClose={onClose}>
        {(options || []).map(option => {
          return (
            <MenuItem
              key={option.text}
              value={`${option.value}`}
              onClick={() => onOptionClick(option.value)}
            >
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                {option.text}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
