import React from 'react';
import startCase from 'lodash/startCase';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';
import {
  Tooltip,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
  Switch,
} from 'src/components-dummy';

import { AvailableIcons } from 'src/components-dummy/Icon/AvailableIcons';
import { RecEnginesGeneralSettingsType } from 'src/services';
import './MultipleRecPerPageSettings.scss';

export interface MultipleRecPerPageSettingsProps {
  generalSettings: RecEnginesGeneralSettingsType.RecEnginesGeneralSettings;
  onChange: (args: Partial<RecEnginesGeneralSettingsType.RemoveDuplicates>) => void;
  disabled?: boolean;
}

const SortableItem = SortableElement(
  ({ sortIndex, value }: { sortIndex: number; value: string }) => (
    <li className='drag-and-drop-list-item'>
      <span className='drag-and-drop-list-item-index'>{sortIndex + 1}</span>
      <span className='drag-and-drop-list-item-value'>{value}</span>
    </li>
  )
);

const SortableList = SortableContainer(
  ({ items, removeDuplicateResults }: { items: string[]; removeDuplicateResults: boolean }) => {
    return (
      <ul className={`drag-and-drop-list ${removeDuplicateResults ? '' : 'disabled'}`}>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value}`}
            index={index}
            sortIndex={index}
            value={value}
            disabled={!removeDuplicateResults}
          />
        ))}
      </ul>
    );
  }
);

export const MultipleRecPerPageSettings = ({
  generalSettings,
  onChange,
  disabled = false,
}: MultipleRecPerPageSettingsProps): JSX.Element | null => {
  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    onChange({
      prioritizedCarousel: arrayMove(
        generalSettings.removeDuplicates.prioritizedCarousel,
        oldIndex,
        newIndex
      ),
    });
  };

  return (
    <div className='multiple-rec-per-page-settings'>
      <span className='multiple-rec-per-page-settings-title'>
        Multiple Recommendations per page
      </span>
      <div className='multiple-rec-per-page-settings-enabler'>
        <Switch
          disabled={disabled}
          checked={generalSettings.removeDuplicates.enable}
          onChange={() => {
            onChange({ enable: !generalSettings.removeDuplicates.enable });
          }}
          className='multiple-rec-per-page-settings-toggle'
        >
          <Switch.TitleTemplate className='multiple-rec-per-page-settings-text'>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Remove duplicate results
            </Typography>
          </Switch.TitleTemplate>
        </Switch>

        <div className='multiple-rec-per-page-settings-tooltip'>
          <Tooltip value='Preventing identical results to appear on the same page'>
            <Icon name={AvailableIcons.TooltipQuestionmark} />
          </Tooltip>
        </div>
      </div>
      <div className='duplication-drag-and-drop-tooltip'>
        <Typography
          type={TypographyType.Body}
          variant={TypographyVariant.MediumRegular}
          className={`duplication-drag-and-drop-tooltip-title ${disabled ? '' : 'disabled'}`}
        >
          Prioritize where to display duplicate results
        </Typography>
        <Tooltip value='The full set of results will appear on one carousel according to the priority'>
          <Icon name={AvailableIcons.TooltipQuestionmark} />
        </Tooltip>
      </div>

      <SortableList
        items={generalSettings.removeDuplicates.prioritizedCarousel.map(product =>
          startCase(product)
        )}
        onSortEnd={onSortEnd}
        removeDuplicateResults={generalSettings.removeDuplicates.enable}
      />
    </div>
  );
};
