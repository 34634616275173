import { useState, useEffect } from 'react';

export type ToggleCollapse = (expended: boolean) => void;
interface UseControlledCollapse {
  (
    externalState?: boolean,
    onChangeCallback?: ToggleCollapse,
    preventDefaultCollapseOnClick?: boolean
  ): {
    toggleCollapse: ToggleCollapse;
    isExpended: boolean;
  };
}
export const useControlledCollapse: UseControlledCollapse = (
  externalState = false,
  onChangeCallback?,
  preventDefaultCollapseOnClick = false
) => {
  const [isExpended, setIsExpended] = useState<boolean>(externalState);
  const toggleCollapse: ToggleCollapse = expended => {
    if (preventDefaultCollapseOnClick) return;
    setIsExpended(expended);
    if (onChangeCallback) {
      onChangeCallback(expended);
    }
  };
  useEffect(() => {
    if (externalState !== isExpended) {
      setIsExpended(externalState);
    }
  }, [externalState]);
  return { toggleCollapse, isExpended };
};
