import React from 'react';
import styled from '@emotion/styled';
import { SearchInput, SearchInputProps } from '../../../../components-dummy';

export const SearchInputStyled = styled((props: SearchInputProps) => <SearchInput {...props} />)`
  .input-base-wrapper {
    height: 40px;
    background: #f2f2f2;
    padding: 12px 8px;
    border: 0;

    &:focus,
    &:focus-within {
      outline: none !important;
      border: 0 !important;
    }
  }

  .syte-text-box-content {
    align-self: center;
  }
`;
