import React, { useRef } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useAppSelector } from 'src/hooks';
import { RoutedComponentProps } from 'src/app-routes';
import { LexiconTablePage } from 'src/components-bl/Lexicon/components/LexiconsTablePage/LexiconTablePage';
import { LexiconTableApiRef } from 'src/components-bl';
import { useLexiconWS } from 'src/components-bl/Lexicon/hooks/useLexiconWS';
import { useValidateLocale } from '../../hooks';
import { LexiconTablePageModalsSwitch } from './LexiconTablePageModalsSwitch';

export const LexiconTablePageContainer = ({
  permittedRouteMap,
  shopId,
}: RoutedComponentProps & { shopId: number | undefined }): JSX.Element | null => {
  const dispatch = useDispatch();

  const tableRef = useRef<LexiconTableApiRef | null>(null);

  const langMeta = useValidateLocale({ permittedRouteMap, dispatch, shopId });

  const userRole = useAppSelector(state => state.global.loggedInUser?.role);

  const lexiconItems = useAppSelector(state => state.lexicon.items, shallowEqual);
  const tagType = useAppSelector(state => state.lexicon.currentSelectedTagType);
  const lexiconTotalItems = useAppSelector(state => state.lexicon.totalItems, shallowEqual);
  const lexiconMetaData = useAppSelector(state => state.lexicon.metaData, shallowEqual);
  const showApplyAll = useAppSelector(state => state.lexicon.showApplyAll, shallowEqual);
  const importExportFileState = useAppSelector(
    state => state.lexicon.importExportFile,
    shallowEqual
  );

  useLexiconWS({
    dispatch,
    shopId,
    requestId: importExportFileState?.requestId,
    locale: langMeta?.locale,
  });

  if (!shopId || !langMeta?.locale) {
    return null;
  }

  return (
    <>
      <LexiconTablePageModalsSwitch
        permittedRouteMap={permittedRouteMap}
        refetchLexiconTags={tableRef.current?.refetchTagsOnCurrentPage}
      />
      <LexiconTablePage
        shopId={shopId}
        locale={langMeta.locale}
        lexiconItems={lexiconItems}
        lexiconTotalItems={lexiconTotalItems}
        dispatch={dispatch}
        displayName={langMeta?.displayName}
        iconName={langMeta?.iconName}
        lexiconMetadata={lexiconMetaData}
        showApplyAll={showApplyAll}
        lexiconsRoute={permittedRouteMap.lexicons?.path}
        lexiconTagsRoute={permittedRouteMap.lexiconCategories?.path}
        createThematicTagRoute={permittedRouteMap.createThematicTag?.path}
        createLexiconRuleRoute={permittedRouteMap.createLexiconRule?.path}
        editThematicTagRoute={permittedRouteMap.editThematicTag?.path}
        editLexiconRuleRoute={permittedRouteMap.editLexiconRule?.path}
        importLexiconRoute={permittedRouteMap.importLexicon?.path}
        exportLexiconRoute={permittedRouteMap.exportLexicon?.path}
        tableRef={tableRef}
        userRole={userRole}
        tagType={tagType}
      />
    </>
  );
};
