import { GalleryType } from 'src/services';
import Joi, { AnySchema } from 'joi';
import { getStringEnumValues } from 'src/utils';
import { GalleryDetailsDraft } from './types';

export const initialDraftState: GalleryDetailsDraft = {
  galleryName: '',
  galleryType: undefined,
};

export const galleryDetailsValidationSchema: Record<keyof GalleryDetailsDraft, AnySchema> = {
  galleryName: Joi.string()
    .required()
    .messages({ 'string.empty': 'Please enter a name for this gallery' }),
  galleryType: Joi.string()
    .required()
    .valid(...getStringEnumValues(GalleryType))
    .messages({
      'any.required': 'Please select a type for this gallery',
    }),
};
