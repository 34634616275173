import styled from '@emotion/styled';
import { RuleFormField } from '../../RuleFormField/RuleFormField';

export const TimePickerStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const DateFieldStyled = styled(RuleFormField)`
  margin-right: 8px;
`;

export const DateTimeRowStyled = styled.div`
  display: flex;
  + div {
    margin-top: 32px;
  }
`;
