import { useEffect } from 'react';
import { Dispatch } from 'src/components-bl';
import {
  CustomInspirationsImageDetectionEndedEvent,
  CustomInspirationsImageDetectionStartedEvent,
  CustomInspirationsGalleryDetectionStartedEvent,
  CustomInspirationsGalleryDetectionEndedEvent,
  galleriesWebSocketService,
} from 'src/services/src/service/galleries';
import { useGalleriesWSActions } from './useGalleriesWS.actions';
import { GalleryType } from 'src/services';

export const useGalleriesWS = ({
  dispatch,
  shopId,
  selectedGalleryId,
}: {
  dispatch: Dispatch;
  shopId: number | undefined;
  selectedGalleryId: string | undefined;
}): void => {
  const onDetectionProcessEnd = (aiSuggestedTagsCount: number) => {
    if (aiSuggestedTagsCount === 0) {
      dispatch(
        useGalleriesWSActions.notification({
          customMessage:
            'The suggestions generation process has been completed, and no suggestions were found.',
        })
      );
    } else if (aiSuggestedTagsCount === 1) {
      dispatch(
        useGalleriesWSActions.notification({
          customMessage:
            'The suggestion generation process has been completed, and 1 suggestion was found.',
        })
      );
    } else if (aiSuggestedTagsCount > 1) {
      dispatch(
        useGalleriesWSActions.notification({
          customMessage: `The suggestion generation process has been completed, and ${aiSuggestedTagsCount} suggestions were found.`,
        })
      );
    }
  };

  const onImageTagsDetectionStart = (payload: CustomInspirationsImageDetectionStartedEvent) => {
    if (shopId === payload.data.shopId && payload.data.galleryId === selectedGalleryId) {
      dispatch(useGalleriesWSActions.imageTagsDetectionStarted(payload.data));
    }
  };

  const onGalleryTagsDetectionStart = (payload: CustomInspirationsGalleryDetectionStartedEvent) => {
    if (shopId === payload.data.shopId && payload.data.galleryId === selectedGalleryId) {
      dispatch(useGalleriesWSActions.galleryTagsDetectionStarted(payload.data));
    }
  };

  const onImageTagsDetectionEnd = (payload: CustomInspirationsImageDetectionEndedEvent) => {
    if (shopId === payload.data.shopId && payload.data.galleryId === selectedGalleryId) {
      dispatch(useGalleriesWSActions.imageTagsDetectionEnded(payload.data));

      if (payload.data.errorKey) {
        dispatch(
          useGalleriesWSActions.errorNotification({ error: { errorKey: payload.data.errorKey } })
        );
      } else {
        onDetectionProcessEnd(payload.data.aiSuggestedTags.length);
      }
    }
  };

  const onGalleryTagsDetectionEnd = (payload: CustomInspirationsGalleryDetectionEndedEvent) => {
    if (shopId === payload.data.shopId && payload.data.galleryId === selectedGalleryId) {
      dispatch(useGalleriesWSActions.galleryTagsDetectionEnded(payload.data));

      dispatch(
        useGalleriesWSActions.getGallery({
          galleryId: selectedGalleryId,
          shopId,
          galleryType: GalleryType.CustomInspirations,
        })
      );

      if (payload.data.errorKey) {
        dispatch(
          useGalleriesWSActions.errorNotification({ error: { errorKey: payload.data.errorKey } })
        );
      } else {
        onDetectionProcessEnd(payload.data.aiSuggestedTagsCount);
      }
    }
  };

  useEffect(() => {
    galleriesWebSocketService.subscribeToImageTagsDetectionStartedEvents(onImageTagsDetectionStart);
    galleriesWebSocketService.subscribeToImageTagsDetectionEndedEvents(onImageTagsDetectionEnd);

    galleriesWebSocketService.subscribeToGalleryTagsDetectionStartedEvents(
      onGalleryTagsDetectionStart
    );

    galleriesWebSocketService.subscribeToGalleryTagsDetectionEndedEvents(onGalleryTagsDetectionEnd);

    return () => {
      galleriesWebSocketService.clearListeners();
    };
  }, [shopId, selectedGalleryId]);
};
