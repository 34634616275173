/* eslint-disable no-param-reassign */
import { useCallback, useMemo } from 'react';
import { ShopTypes, ShopDataField } from 'src/services';

export interface UseDataFieldsLookupTableArguments {
  dataFields?: ShopDataField[];
}

export interface DataFieldLookupTableValue extends ShopDataField {
  conditionTypes?: ShopTypes.MerchandisingRuleSubType[];
}

export type DataFieldLookupTable = Record<string, DataFieldLookupTableValue>;

export interface UseDataFieldsLookupTableArgumentsReturnType {
  getDataFieldByName: (name: string | undefined) => DataFieldLookupTableValue | undefined;
  dataFieldsLookupTable: DataFieldLookupTable | undefined;
}

export const useDataFieldsLookupTable = ({
  dataFields,
}: UseDataFieldsLookupTableArguments): UseDataFieldsLookupTableArgumentsReturnType => {
  const dataFieldsLookupTable = useMemo(() => {
    return dataFields?.reduce((result, current) => {
      result[current.name] = { ...current };

      return result;
    }, {} as DataFieldLookupTable);
  }, [dataFields]);

  const getDataFieldByName = useCallback(
    (name: string | undefined) => {
      if (!name) {
        return undefined;
      }
      return dataFieldsLookupTable?.[name];
    },
    [dataFieldsLookupTable]
  );

  return {
    getDataFieldByName,
    dataFieldsLookupTable,
  };
};
