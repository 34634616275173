import React from 'react';
import { Link, Typography, TypographyType, TypographyVariant } from '../../../../components-dummy';

interface Props {
  shopId: number;
  collectionId?: string;
}

export function NoResultsFound({ shopId, collectionId }: Props): JSX.Element {
  const editCollectionLink = `/shops/${shopId}/collections/edit/${collectionId}/collection-rules`;
  const merchRulesLink = collectionId
    ? `/shops/${shopId}/collections/edit/${collectionId}/merchandising-rules`
    : `/shops/${shopId}/merchandising-rules`;

  return (
    <div className='visual-editor-content-text-no-results'>
      <Typography variant={TypographyVariant.ExtraSmallBold} type={TypographyType.Heading}>
        No results found
      </Typography>
      <Typography variant={TypographyVariant.LargeRegular} type={TypographyType.Body}>
        To get the desired outcomes, try one of the following actions:
      </Typography>
      <Typography
        className='visual-editor-content-text-no-results-suggestions'
        variant={TypographyVariant.LargeRegular}
        type={TypographyType.Body}
      >
        {collectionId ? (
          <>
            1. Add/edit your <Link href={editCollectionLink}>Collection</Link>.
          </>
        ) : (
          <>1. Enter a different SKU or product name.</>
        )}
      </Typography>
      <Typography
        className='visual-editor-content-text-no-results-suggestions'
        variant={TypographyVariant.LargeRegular}
        type={TypographyType.Body}
      >
        2. Disable/edit <Link href={merchRulesLink}>Merchandising rules</Link>.
      </Typography>
    </div>
  );
}
