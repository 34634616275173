import styled from '@emotion/styled';
import { Link, Typography } from 'src/components-dummy';
import { CodeSection } from 'src/components-dummy/CodeSection';

export const ImplementationSectionStyled = styled.div`
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.custom['gray-80']};
  background: ${({ theme }) => theme.palette.custom['gray-120']};

  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 4px;
`;

export const MainTypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  user-select: none;
`;

export const SubTypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  user-select: none;
`;

export const CodeSectionStyled = styled(CodeSection)`
  margin-top: unset;
`;

export const LinkStyled = styled(Link)`
  text-decoration: underline !important;
`;
