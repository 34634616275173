import React from 'react';
import classNames from 'classnames';

import { MenuItem, Select, SelectOnChangeEvent, SelectType } from 'src/components-dummy';
import { INPUT_CLASS_NAME } from '../consts';
import { conditionValueSelectBooleanOptions } from '../DataFieldsCondition.helpers';

interface DataFieldsBooleanSelectProps {
  disabled?: boolean;
  isError: boolean;
  value: string;
  onChange: SelectOnChangeEvent;
}

export function DataFieldsBooleanSelect({
  disabled,
  isError,
  value,
  onChange,
}: DataFieldsBooleanSelectProps): JSX.Element {
  return (
    <Select
      type={SelectType.Primary}
      isError={isError}
      value={value}
      onChange={onChange}
      placeholder='Select ...'
      disabled={disabled}
      className={classNames(INPUT_CLASS_NAME, 'condition-value-input-boolean-dropdown')}
      autoFocus
    >
      {conditionValueSelectBooleanOptions.map((option: { value: boolean; text: string }) => {
        const itemValue = `${option.value}`;
        return (
          <MenuItem key={itemValue} value={itemValue}>
            {`${option.text}`}
          </MenuItem>
        );
      })}
    </Select>
  );
}
