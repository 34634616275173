import React, { useRef } from 'react';
import { css } from '@emotion/react';
import {
  TypographyType,
  TypographyVariant,
  AutoComplete,
  ActionIcon,
  AvailableIcons,
  AutoCompleteOption,
} from 'src/components-dummy';
import { reduceErrorsByPrefix } from 'src/utils';
import { ConditionsGroup } from '../../ConditionsGroup/ConditionsGroup';
import {
  ConditionRowStyled,
  ConditionRowDataStyled,
  ConditionText,
  ConditionRowErrors,
} from '../RuleFormConditionsSection.styles';
import { RuleDraft } from '../../../MerchandisingRuleForm.config';
import { RuleConditionsAddGroup } from '../../RuleConditionsAddGroup/RuleConditionsAddGroup';
import { mapErrorMessages } from '../../../../../mapErrorMessages';

const TITLE_TEXT = 'Search Condition';
const SUB_TITLE_TEXT = 'Apply conditions to specific search terms';

export interface ConditionSearchTermsGroupProps {
  shopId: number;
  searchCondition: RuleDraft['searchCondition'];
  onFieldChange: (data: Partial<Pick<RuleDraft, 'searchCondition'>>) => void;
  errors: any; // TODO
  isReadOnly?: boolean;
  isRedirectModeEnabled?: boolean;
}

const NO_OPTIONS: AutoCompleteOption[] = [];

export const ConditionSearchTermsGroup = React.memo(
  ({
    searchCondition,
    onFieldChange,
    isReadOnly,
    errors,
    isRedirectModeEnabled = false,
  }: ConditionSearchTermsGroupProps): JSX.Element => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const onChangeCondition = (values: string[]) => {
      onFieldChange({ searchCondition: { ...searchCondition, terms: values } });
    };

    const onAddNewCondition = () => {
      onFieldChange({ searchCondition: { terms: [], enabled: true } });
    };

    const onDeleteCondition = () => {
      onFieldChange({ searchCondition: { terms: [], enabled: false } });
    };

    const isInAddMode = !searchCondition.enabled;

    const localErrors = reduceErrorsByPrefix({
      errors,
      prefix: 'searchCondition',
    });

    const rowErrorLabels = mapErrorMessages(localErrors);

    const showTrashIcon = !isReadOnly && !isRedirectModeEnabled;

    return isInAddMode && !isReadOnly ? (
      <>
        <RuleConditionsAddGroup
          title={TITLE_TEXT}
          subTitle={SUB_TITLE_TEXT}
          onClick={onAddNewCondition}
        />
        {rowErrorLabels.length > 0 ? (
          <ConditionRowErrors>{rowErrorLabels}</ConditionRowErrors>
        ) : null}
      </>
    ) : (
      <ConditionsGroup>
        <ConditionsGroup.Title>{TITLE_TEXT}</ConditionsGroup.Title>
        <ConditionsGroup.SubTitle>{SUB_TITLE_TEXT}</ConditionsGroup.SubTitle>
        <ConditionsGroup.Content>
          <ConditionRowStyled>
            <ConditionRowDataStyled>
              <ConditionText type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
                Search terms
              </ConditionText>
              <AutoComplete
                freeSolo
                multiple
                selectedValues={searchCondition.terms}
                options={NO_OPTIONS}
                onChange={onChangeCondition}
                errored={!!localErrors.terms}
                css={css`
                  width: 100%;
                  flex: 1;
                  margin-left: 16px !important;
                `}
                disabled={isReadOnly}
                autoFocus
                inputRef={inputRef}
              />
              {showTrashIcon && (
                <ActionIcon
                  iconName={AvailableIcons.TrashCan}
                  onClick={() => onDeleteCondition()}
                />
              )}
            </ConditionRowDataStyled>
            {rowErrorLabels.length > 0 ? (
              <ConditionRowErrors>{rowErrorLabels}</ConditionRowErrors>
            ) : null}
          </ConditionRowStyled>
        </ConditionsGroup.Content>
      </ConditionsGroup>
    );
  }
);
