import { ApiServiceBase } from '../api-service-base';
import type { AssetResponse } from './types';
import { mapAssetResponse } from './mapper';

export class AssetsService extends ApiServiceBase {
  constructor() {
    super('assets');
  }

  async uploadAsset(file: File): Promise<AssetResponse> {
    const blob = await new Response(file).blob();
    const data = new FormData();
    data.append('asset', blob, file.name);
    const response = await this.httpService.put({ url: this.serviceBaseUri, data });

    return mapAssetResponse(response);
  }
}

export const assetsService = new AssetsService();
