export const queryKey = 'expanded';

export function getQueryStringParams(imageSettingSections: string[]): string {
  const sortedSections = imageSettingSections.sort();

  const queryParams = sortedSections.reduce((params, section) => {
    params.append(queryKey, section);
    return params;
  }, new URLSearchParams());

  return queryParams.toString();
}
