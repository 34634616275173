import React from 'react';
import { Tooltip, TableV2 } from 'src/components-dummy';
import { ILexiconTableSortState } from '../types';

export type OnSortChange = (properties: ILexiconTableSortState) => void;

export const LexiconTableSortableColumnHeader = ({
  column,
  sortAscending,
  onSortChange,
  tooltipText,
}: {
  sortAscending?: boolean;
  column: any;
  onSortChange: (properties: ILexiconTableSortState) => void;
  tooltipText?: string;
}): JSX.Element => {
  const onSortChangeInternal = () => {
    onSortChange({
      columnName: column.id,
      sortAscending: !sortAscending,
    });
  };

  return (
    <TableV2.HeadRowCellText
      isSortAble
      sortAscending={sortAscending}
      onClick={onSortChangeInternal}
    >
      {tooltipText ? (
        <Tooltip value={tooltipText}>{column.render('Header')}</Tooltip>
      ) : (
        column.render('Header')
      )}
    </TableV2.HeadRowCellText>
  );
};
