import React, { ReactNode } from 'react';

export const CardHeader = ({ children }: { children: ReactNode | ReactNode[] }): JSX.Element => {
  return <>{children}</>;
};

export const CollapseableContent = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}): JSX.Element => {
  return <>{children}</>;
};
