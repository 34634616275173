import React from 'react';
import {
  CardItem,
  Collapse,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import classNames from 'classnames';
import { useControlledCollapse, useFindComponentByType } from '../hooks';
import { CardHeader, CollapseableContent, QuickActionsMenu, HeaderCell } from './components';
import { CollapsableEntityCardProps } from './types';
import './CollapsableEntityCard.scss';

export const CollapsableEntityCard = ({
  entityId,
  status,
  additionalClassName = '',
  opened = false,
  onToggleExpend,
  actionMenu,
  preventDefaultCollapseOnClick = false,
  children,
}: CollapsableEntityCardProps): JSX.Element => {
  const { toggleCollapse, isExpended } = useControlledCollapse(
    opened,
    onToggleExpend,
    preventDefaultCollapseOnClick
  );
  const header = useFindComponentByType({ type: CardHeader, children });
  const collapseableContent = useFindComponentByType({ type: CollapseableContent, children });

  return (
    <Collapse
      className={classNames(
        'syte-entity-card',
        preventDefaultCollapseOnClick && 'disabled',
        additionalClassName
      )}
      expanded={isExpended}
      onExpandChange={toggleCollapse}
      key={entityId}
      disabled={preventDefaultCollapseOnClick}
    >
      <Collapse.Header>
        <CardItem className={classNames(header?.props.className)}>
          {status ? (
            <div className={classNames('syte-entity-card-status', status)}>
              <Typography
                type={TypographyType.Body}
                variant={TypographyVariant.SmallRegular}
                className='syte-entity-card-status-text'
              >
                {status}
              </Typography>
            </div>
          ) : null}
          {header?.props.children}
          {actionMenu ? <QuickActionsMenu {...actionMenu} /> : null}
        </CardItem>
      </Collapse.Header>
      <Collapse.Content className={classNames(collapseableContent?.props.className)}>
        {collapseableContent?.props.children}
      </Collapse.Content>
    </Collapse>
  );
};

CollapsableEntityCard.Header = CardHeader;
CollapsableEntityCard.HeaderCell = HeaderCell;
CollapsableEntityCard.Content = CollapseableContent;
