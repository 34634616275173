import React from 'react';
import { Row } from 'react-table';
import { TableV2 } from '../../../../../components-dummy';
import { IndexedOffer } from '../../../types';
import { TableHeadRow } from './types/table-head-row';
import { AdData } from '../../../../../services';
import {
  RowStyled,
  ProductImageStyled,
  TableBodyRowCellAlignedCenterStyled,
  TableBodyRowCellStyled,
} from './VisualEditorTable.styled';

interface TableRowProps {
  row: Row<IndexedOffer>;
  tableColumns: Record<string, TableHeadRow>;
  openProductDetailsModal: (sku?: string) => void;
}

export const TableRow = ({
  row,
  tableColumns,
  openProductDetailsModal,
}: TableRowProps): JSX.Element => {
  return (
    <RowStyled onClick={() => openProductDetailsModal(row.original.sku)}>
      <TableV2.BodyRow {...row.getRowProps()} key={row.original.id}>
        {row.cells.map(cell => {
          const propName = cell.column.id;

          if (propName === tableColumns.index.accessor) {
            return (
              <TableBodyRowCellAlignedCenterStyled
                {...cell.getCellProps()}
                key={propName}
                width={cell.column.width as string}
                minWidth={cell.column.minWidth}
              >
                <TableV2.BodyRowCellText>{cell.value}</TableV2.BodyRowCellText>
              </TableBodyRowCellAlignedCenterStyled>
            );
          }

          if (propName === tableColumns.imageUrl.accessor) {
            return (
              <TableBodyRowCellAlignedCenterStyled
                {...cell.getCellProps()}
                key={propName}
                width={cell.column.width as string}
                minWidth={cell.column.minWidth}
              >
                <ProductImageStyled src={cell.value} alt='product' />
              </TableBodyRowCellAlignedCenterStyled>
            );
          }

          const cellValue = cell.value || row.original[propName as keyof AdData];

          return (
            <TableBodyRowCellStyled
              {...cell.getCellProps()}
              key={propName}
              width={cell.column.width as string}
              minWidth={cell.column.minWidth}
            >
              <TableV2.BodyRowCellText>{cellValue}</TableV2.BodyRowCellText>
            </TableBodyRowCellStyled>
          );
        })}
      </TableV2.BodyRow>
    </RowStyled>
  );
};
