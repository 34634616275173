import { useEffect, useMemo, useReducer } from 'react';
import { ISocialCollectionService } from '../../../../services/src/service/social-discovery/social-collection-service';
import { AssignedProduct, PublicPost, UnpublishedPost } from '../../types/entities.types';
import { AppDispatch } from '../../../../app-types';
import { createReducer } from './reducer';
import { NotificationType } from '../../../../components-dummy';
import { SocialDiscoveryActionTypes } from '../../../../services/src/service/social-discovery/types';
import { StateType } from './types';
import { createToastNotification } from '../../Actions/SocialDiscoveryActions';

export const useEditPostsState = (
  service: ISocialCollectionService,
  appDispatch: AppDispatch,
  publicPosts: PublicPost[],
  onChange: (posts: PublicPost[]) => void
) => {
  const { reducer, getInitialState, actions } = useMemo(
    () => createReducer(publicPosts),
    [publicPosts]
  );

  const [state, dispatch] = useReducer(reducer, getInitialState());

  const changePostOrder = (newIndex: number) => {
    if (state.type !== StateType.idle || state.editedPosts.length < 2) return;
    dispatch(actions.changeOrder(newIndex));
  };

  const setAssignedProducts = (assignedProducts: AssignedProduct[]) => {
    if (state.type !== StateType.idle || state.selectedPostIndex === null) return;
    dispatch(actions.setAssignedProducts(assignedProducts));
  };

  const selectPost = (tabName: string) => {
    if (state.editedPosts.length === 0) return;
    dispatch(actions.selectPost(Number(tabName)));
  };

  const removePost = (index: number) => {
    if (state.type !== StateType.idle) return;
    dispatch(actions.removePost(index));
  };

  const addPublicPosts = (selectedPosts: UnpublishedPost[]) => {
    if (state.type !== StateType.moderationModalOpen) return;
    const publicPostsFromUnpublished = selectedPosts.map(
      (unpublishedPost): PublicPost => ({
        id: unpublishedPost.id,
        external_id: unpublishedPost.external_id,
        imageUrl: unpublishedPost.imageUrl,
        assignedProducts: [],
      })
    );
    dispatch(actions.addPosts(publicPostsFromUnpublished));
  };

  const closeModerationModal = () => {
    if (state.type !== StateType.moderationModalOpen) return;
    dispatch(actions.closeModerationModal());
  };

  const openModerationModal = async () => {
    if (state.type !== StateType.idle) return;
    dispatch(actions.setIsFetching());
    const publicPostIds = state.editedPosts.map(post => post.id);
    const response = await service.getUnpublishedPosts({ publicPostIds });
    dispatch(actions.resolveModeratedPosts(response));
  };

  useEffect(() => {
    onChange(state.editedPosts);
  }, [state.editedPosts]);

  useEffect(() => {
    if (state.error) {
      appDispatch(
        createToastNotification({
          type: NotificationType.Error,
          messageKey: SocialDiscoveryActionTypes.GeneralError,
          timestamp: new Date().getTime(),
        })
      );
    }
  }, [state.error]);

  return {
    state,
    removePost,
    selectPost,
    addPublicPosts,
    changePostOrder,
    setAssignedProducts,
    openModerationModal,
    closeModerationModal,
  };
};
