import { useEffect, useRef, useState } from 'react';
import { ListFormValidator, ValidatorsDict } from 'src/components-bl/helpers/form-validation';
import { EditableFilterItem } from '../types';

const displayNameValidationSchema: ValidatorsDict<{ displayNameKey: string | undefined }> = {
  displayNameKey: {
    isRequired: true,
    tests: [
      {
        testIsValid: inputVal =>
          typeof inputVal === 'string' && /^[a-zA-Z ]+$/.test(inputVal.trim()),
        errorMsg: () =>
          "'Filter Name' cannot include special characters or numbers, please use only letters.",
      },
      {
        testIsValid: inputVal => typeof inputVal === 'string' && inputVal.length <= 30,
        errorMsg: () => "'Filter Name' exceeds the maximum length of 30 characters.",
      },
    ],
  },
};

const getValidatorsDict = ({
  shouldIncludeDisplayName,
}: {
  shouldIncludeDisplayName: boolean;
}): ValidatorsDict<EditableFilterItem> => {
  const displayNameKeyValidation = shouldIncludeDisplayName ? displayNameValidationSchema : {};
  return {
    dataSource: { isRequired: true, tests: [] },
    ...displayNameKeyValidation,
    displayType: { isRequired: true, tests: [] },
    bucketsOrder: { isRequired: true, tests: [] },
    type: { isRequired: false, tests: [] },
    key: { isRequired: true, tests: [] },
    enabled: { isRequired: false, tests: [] },
    position: { isRequired: false, tests: [] },
    showRangeInput: { isRequired: false, tests: [] },
    colorShape: { isRequired: false, tests: [] },
    isTypeNumeric: { isRequired: false, tests: [] },
    filterStatus: { isRequired: false, tests: [] },
    numBuckets: { isRequired: true, tests: [] },
    minBucketDelta: { isRequired: true, tests: [] },
  };
};

interface UseFiltersListValidatorReturnType {
  validator: ListFormValidator<EditableFilterItem>;
  setValidatedItem: (itemKey: string) => void;
}
export const useFiltersListValidator = ({
  listDraft,
  shouldIncludeDisplayName,
}: {
  listDraft: EditableFilterItem[];
  shouldIncludeDisplayName: boolean;
}): UseFiltersListValidatorReturnType => {
  const [validatedItem, setValidatedItem] = useState<string | undefined>();
  const validator = useRef(
    new ListFormValidator<EditableFilterItem>(
      getValidatorsDict({ shouldIncludeDisplayName }),
      'key',
      listDraft,
      {
        key: { isCaseSensitive: true },
        dataSource: { isCaseSensitive: true },
        displayNameKey: { isCaseSensitive: true },
      }
    )
  );

  useEffect(() => {
    if (validatedItem && validator.current) {
      const item = listDraft.find(li => li.key === validatedItem);
      if (!item) return;
      validator.current.validateListItem(item.key, item);
      setValidatedItem(undefined);
    }
  }, [listDraft]);

  return { validator: validator.current, setValidatedItem };
};
