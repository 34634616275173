import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { notificationsService } from 'src/services';

export const notificationCardActions = {
  navigateTo: createAction<{ navigateTo: string }>('NotificationCard/NavigateTo'),

  updateNotification: createAsyncThunk(
    'NotificationCard/UpdateNotification',
    async (
      {
        shopId,
        notificationId,
        isViewed,
      }: { shopId?: number; notificationId: string; isViewed: boolean },
      { rejectWithValue }
    ) => {
      try {
        await notificationsService.updateNotificationById({
          shopId,
          notificationId,
          isViewed,
        });

        return null;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  deleteNotification: createAsyncThunk(
    'NotificationCard/DeleteNotification',
    async (
      { shopId, notificationId }: { shopId?: number; notificationId: string },
      { rejectWithValue }
    ) => {
      try {
        await notificationsService.deleteNotificationById({
          shopId,
          notificationId,
        });

        return null;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
