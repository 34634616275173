import React from 'react';
import { NotificationCardMessageTypography } from '../common';
import { NotificationCardMessageProps } from '../types';

export function DefaultNotificationCardMessage({
  notification,
}: NotificationCardMessageProps): JSX.Element {
  return (
    <NotificationCardMessageTypography>{notification.message}</NotificationCardMessageTypography>
  );
}
