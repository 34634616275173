import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { dataFieldsService, FieldType, MerchandisingRules } from 'src/services';

const merchandisingRulesService = new MerchandisingRules();

/**
 * Actions
 */
export const editMerchandisingRuleContainerActions = {
  navigateTo: createAction<{ navigateTo: string }>('EditMerchandisingRuleContainer/NavigateTo'),
  getRule: createAsyncThunk(
    'EditMerchandisingRuleContainer/GetRule',
    async (
      { shopId, fieldType, ruleId }: { shopId: number; fieldType?: FieldType; ruleId: string },
      { rejectWithValue }
    ) => {
      try {
        const rule = await merchandisingRulesService.getRule({ shopId, ruleId });
        const dataFields = await dataFieldsService.getDataFields({ shopId, fieldType });

        return { rule, dataFields };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
