import React, { useMemo, useState } from 'react';
import { Dispatch } from 'src/components-bl/types';
import { Typography, TypographyType, TypographyVariant, Tooltip } from 'src/components-dummy';
import { ILexiconCategories, ILexiconCategory } from 'src/services';
import {
  DeepTagReportProductDataCategory,
  IDeepTagReportProduct,
  IDeepTagsProductDataItem,
} from 'src/services/src/service/types/deep-tag-reports';
import { BackDropWithLoader } from 'src/components-dummy/Backdrop/BackdropWithLoader';
import {
  TagsContainerHeaderStyled,
  TagsListContainerStyled,
} from '../../DeepTagsProductForm.style';
import { DeepTagSubProductTagsList } from './DeepTagProductTags/DeepTagSubProductTagsList';
import { RemoveTagModal } from '../RemoveTagModal';
import { DeepTagProductAddTagWithMultiSelect } from './AddTags/DeepTagProductAddTagsSelect';
import {
  AddTagButtonWrapper,
  MainProductTagsListSectionStyled,
} from './DeepTagProductTags/DeepTagProductTags.style';
import { DeepTagsChangeCategoryConfirmation } from './DeepTagsChangeCategory/DeepTagsChangeCategoryConfirmation';
import { DeepTagsChangeCategoryForm } from './DeepTagsChangeCategory/DeepTagsChangeCategoryForm';
import {
  ISubProductFormData,
  OnChangeCategoryFormData,
  OnChangeSubProductTagsFormData,
  OnSetCategoryHasChanged,
} from '../DeepTagsProductReviewAndEditTagsSection/useDeepTagsSubProductsForm';
import { OnChangeMainProductTagsFormData } from '../DeepTagsProductReviewAndEditTagsSection/useDeepTagsMainProductForm';
import { useDeepTagsManagement } from './useDeepTagsManagement';
import { DeepTagMainProductTagsList } from './DeepTagProductTags/DeepTagMainProductTagsList';
import { DeepTagForm, OnGeneratedAttributeEdit } from '../../DeepTagsProductForm.types';

/**
 * Types
 */
export interface DeepTagsMainListFormProps {
  reportLocale: string;
  lexiconCategories?: ILexiconCategories;
  isDirty: boolean;
  subProductFormData?: ISubProductFormData;
  subProductOriginal: IDeepTagsProductDataItem;
  productMainTagsOriginal: IDeepTagReportProduct['tags'];
  productFormDataTags: DeepTagForm[];
  dispatch: Dispatch;
  onCancel: () => void;
  onChangeSubProductTags: OnChangeSubProductTagsFormData;
  onChangeMainProductTags: OnChangeMainProductTagsFormData;
  onChangeCategory: OnChangeCategoryFormData;
  onGeneratedAttributeEdit?: OnGeneratedAttributeEdit;
  isInProcess: boolean;
  onSetCategoryHasChanged: OnSetCategoryHasChanged;
  isLexiconEnabled: boolean | undefined;
  isLoadingLexicon: boolean;
  shouldHideEditIndication: boolean;
}

/**
 * Main
 */
export const DeepTagsMainListForm = React.memo(
  ({
    subProductOriginal,
    subProductFormData,
    productMainTagsOriginal,
    productFormDataTags,
    isDirty,
    lexiconCategories,
    reportLocale,
    isInProcess,
    dispatch,
    onChangeSubProductTags,
    onChangeMainProductTags,
    onChangeCategory,
    onSetCategoryHasChanged,
    onGeneratedAttributeEdit,
    isLexiconEnabled,
    isLoadingLexicon,
    shouldHideEditIndication,
  }: DeepTagsMainListFormProps): JSX.Element => {
    const [shouldShowCategoryChangeConfirmation, setShouldShowCategoryChangeConfirmation] =
      useState(false);

    const productCategoryLexicon: ILexiconCategory | undefined = useMemo(
      () => lexiconCategories?.[subProductFormData?.category?.name || -1],
      [lexiconCategories, subProductFormData?.category.name]
    );

    const subProductTagsManagement = useDeepTagsManagement({
      tagsFromServer: subProductOriginal.tags,
      formTags: subProductFormData?.tags,
      productCategoryLexicon,
      isDirty,
      onChangeTags: onChangeSubProductTags,
      dispatch,
    });

    const mainProductTagsManagement = useDeepTagsManagement({
      tagsFromServer: productMainTagsOriginal,
      formTags: productFormDataTags,
      productCategoryLexicon,
      isDirty,
      onChangeTags: onChangeMainProductTags,
      dispatch,
    });

    const [shouldShowChangeCategoryModal, setShouldShowChangeCategoryModal] =
      useState<boolean>(false);

    const onChangeCategoryConfirm = () => {
      setShouldShowChangeCategoryModal(true);
      setShouldShowCategoryChangeConfirmation(false);
    };

    const onSelectCategory = (
      updatedCategory: Omit<DeepTagReportProductDataCategory, 'isEdited'>
    ): void => {
      onSetCategoryHasChanged(true);

      onChangeCategory({
        category: {
          name: updatedCategory.name,
          translation: updatedCategory.translation,
          isEdited: true,
        },
      });

      setShouldShowChangeCategoryModal(false);
    };

    const onCancelCategoryChange = () => {
      onSetCategoryHasChanged(false);
      setShouldShowChangeCategoryModal(false);
    };

    /**
     *  - When category is free text -> translation will be filled BUT category.name will be undefined DISABLED (NO available tags for add/edit)
     *  - When category is NO category -> the same - won't have category.name & category.translation - DISABLED (NO available tags for add/edit)
     */
    const isAddTagsDisabled =
      !isLexiconEnabled ||
      !subProductFormData?.category.name ||
      !subProductTagsManagement.hasUnusedAttributesFromLexicon;

    return (
      <>
        {isInProcess && <BackDropWithLoader />}
        {shouldShowCategoryChangeConfirmation && (
          <DeepTagsChangeCategoryConfirmation
            onApproveSubmit={onChangeCategoryConfirm}
            onCloseDialog={() => setShouldShowCategoryChangeConfirmation(false)}
          />
        )}
        <>
          <TagsContainerHeaderStyled>
            <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
              Review and edit tags
            </Typography>
            <AddTagButtonWrapper>
              <Tooltip
                value={
                  (isLexiconEnabled === false && 'The lexicon is disabled') ||
                  (isLoadingLexicon && 'Loading lexicon tags...') ||
                  (!subProductFormData?.category.name && "Category doesn't support lexicon") ||
                  (!!productCategoryLexicon &&
                    !subProductTagsManagement.hasUnusedAttributesFromLexicon &&
                    'There are no additional tags to add') ||
                  ''
                }
                disabled={!isAddTagsDisabled}
              >
                <DeepTagProductAddTagWithMultiSelect
                  onApply={subProductTagsManagement.onAddTagAttributesSelect}
                  lexiconAttributes={subProductTagsManagement.unusedAttributesFromLexicon}
                  disabled={isAddTagsDisabled}
                  isLoadingLexicon={isLoadingLexicon}
                />
              </Tooltip>
            </AddTagButtonWrapper>
          </TagsContainerHeaderStyled>
          <TagsListContainerStyled>
            <DeepTagSubProductTagsList
              tagsFormData={subProductFormData?.tags || []}
              category={
                subProductFormData?.category || {
                  name: '',
                  translation: '',
                  isEdited: false,
                }
              }
              parentCategory={
                subProductFormData?.parentCategory || {
                  name: '',
                  translation: '',
                  isEdited: false,
                }
              }
              hasChangedCategory={!!subProductFormData?.hasChangedCategory}
              onEditCategory={
                isLexiconEnabled === true
                  ? (shouldEdit: boolean) => {
                      setShouldShowCategoryChangeConfirmation(shouldEdit);
                    }
                  : undefined
              }
              onAttributeValueChange={subProductTagsManagement.onAttributeValueChange}
              onRemoveTag={subProductTagsManagement.onRemoveOrRestoreClick}
              lexiconCategoryAttributes={productCategoryLexicon?.attributes}
              tagToRemove={subProductTagsManagement.tagToRemove}
              shouldHideEditIndication={shouldHideEditIndication}
            />
            {productFormDataTags.length > 0 && (
              <MainProductTagsListSectionStyled>
                <DeepTagMainProductTagsList
                  tagsFormData={productFormDataTags}
                  onRemoveTag={mainProductTagsManagement.onRemoveOrRestoreClick}
                  onAttributeValueChange={mainProductTagsManagement.onAttributeValueChange}
                  onGeneratedAttributeEdit={onGeneratedAttributeEdit}
                  tagToRemove={mainProductTagsManagement.tagToRemove}
                  shouldHideEditIndication={shouldHideEditIndication}
                />
              </MainProductTagsListSectionStyled>
            )}
          </TagsListContainerStyled>
        </>

        {/* Modals */}
        {shouldShowChangeCategoryModal && (
          <DeepTagsChangeCategoryForm
            onCloseDialog={onCancelCategoryChange}
            onSubmit={onSelectCategory}
            currentCategory={subProductOriginal.category}
            reportLocale={reportLocale}
            originalData={subProductOriginal.originalData}
            lexiconCategories={lexiconCategories}
          />
        )}
        {subProductTagsManagement.tagToRemove && (
          <RemoveTagModal
            onCancel={subProductTagsManagement.onCancelRemoveTag}
            onRemoveTag={subProductTagsManagement.onConfirmedRemoveTag}
          />
        )}
        {mainProductTagsManagement.tagToRemove && (
          <RemoveTagModal
            onCancel={mainProductTagsManagement.onCancelRemoveTag}
            onRemoveTag={mainProductTagsManagement.onConfirmedRemoveTag}
          />
        )}
      </>
    );
  }
);
