import React from 'react';
import { AvailableIcons, Icon } from '../../../../components-dummy';
import './AddPost.scss';

export interface PlusButtonProps {
  onClick: () => void;
}

export const PlusButton = ({ onClick }: { onClick: () => void }): JSX.Element => {
  return (
    <button type='button' className='add-post' onClick={onClick} data-testid='add-post'>
      <div className='bg-container'>
        <Icon name={AvailableIcons.Add} />
      </div>
    </button>
  );
};
