import {
  GetPersonalizationSettingsArgs,
  UpdatePersonalizationSettingsPayload,
} from 'src/components-bl/Personalization/Actions/types';
import * as mapper from './mapper';
import * as builder from './builder';
import { PersonalizationSettingsTypes } from '../types';
import { ApiServiceBase } from '../api-service-base';

export class PersonalizationGeneralSettings extends ApiServiceBase {
  constructor() {
    super('shops');
  }

  private getUrl({ shopId, variantId }: GetPersonalizationSettingsArgs): string {
    return `${this.serviceBaseUri}/${shopId}/syte-products/personalization/general-settings${
      variantId ? `?variantId=${variantId}` : ''
    }`;
  }

  async get({
    shopId,
    variantId,
  }: GetPersonalizationSettingsArgs): Promise<PersonalizationSettingsTypes.PersonalizationSettings> {
    const url = this.getUrl({ shopId, variantId });
    const response = await this.httpService.get({ url });
    return mapper.personalizationSettingsResponse(response);
  }

  async update({
    shopId,
    variantId,
    ...payload
  }: UpdatePersonalizationSettingsPayload): Promise<PersonalizationSettingsTypes.PersonalizationSettings> {
    const url = this.getUrl({ shopId, variantId });

    const data = builder.buildPersonalizationSettingsPayload(payload);
    const response = await this.httpService.patch({ url, data });
    return mapper.personalizationSettingsResponse(response);
  }
}
