import React, { useCallback, useMemo, useState } from 'react';
import { startCase } from 'lodash';
import {
  AvailableIcons,
  Button,
  ConfirmationDialog,
  Icon,
  Tooltip,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { PreviewTypeEnum } from 'src/app-types/enums/versioning';
import { separateRelatedDomainKeysToDomainEntityPath } from 'src/components-bl/FeatureChanges/ChangesPreview/domain-helpers';
import { ButtonsContainer } from 'src/components-dummy/ConfirmationDialog/ConfirmationDialog.style';
import { BackDropWithLoader } from 'src/components-dummy/Backdrop/BackdropWithLoader';
import { ReviewBeforePublishModalProps, DraftActions } from './types';
import { ChangesModal } from '../ChangesModal/ChangesModal';
import { DomainGroupsSelection } from '../types';

export const ReviewBeforePublishModal = ({
  show,
  draft,
  onDiscardDraft,
  onPublishDraft,
  setModalIsOpened,
  runningExperiment,
  dataFields,
}: ReviewBeforePublishModalProps): JSX.Element => {
  const [isInProgress, setIsInProgress] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [clickedAction, setClickedAction] = useState(DraftActions.Discard);

  const [domainGroupsSelection, setDomainGroupsSelection] = useState<DomainGroupsSelection>({
    combinedSelectedDomainGroups: [],
    allGroupsWithChangesSelected: false,
  });

  const selectedDomainGroups = useMemo(
    () =>
      separateRelatedDomainKeysToDomainEntityPath(
        domainGroupsSelection.combinedSelectedDomainGroups
      ),
    [domainGroupsSelection.combinedSelectedDomainGroups]
  );

  const totalChanges = useMemo(
    () =>
      draft?.changesSummary.changedEntities.reduce((numberOfChanges, currentEntity) => {
        return selectedDomainGroups.includes(currentEntity.domain)
          ? numberOfChanges + 1
          : numberOfChanges;
      }, 0) || 0,
    [selectedDomainGroups, draft?.changesSummary]
  );

  const onDomainGroupSelected = useCallback(
    (partialSelection: Partial<DomainGroupsSelection>) => {
      setDomainGroupsSelection(selection => ({ ...selection, ...partialSelection }));
    },
    [setDomainGroupsSelection]
  );

  const dialogConfigs = {
    [DraftActions.Publish]: {
      onProceed: async () => {
        setShowDialog(false);
        setIsInProgress(true);

        await onPublishDraft({
          isAllSelected: domainGroupsSelection.allGroupsWithChangesSelected,
          selectedDomainGroups,
          totalChanges,
        });

        setDomainGroupsSelection({
          combinedSelectedDomainGroups: [],
          allGroupsWithChangesSelected: false,
        });
        setIsInProgress(false);
      },
      bodyText: `Are you sure you want to publish ${totalChanges} changes to production?`,
    },
    [DraftActions.Discard]: {
      onProceed: () => {
        onDiscardDraft({
          isAllSelected: domainGroupsSelection.allGroupsWithChangesSelected,
          selectedDomainGroups,
          totalChanges,
        });
        setShowDialog(false);
      },
      bodyText: `You are about to discard ${totalChanges} changes. Application will be reloaded`,
    },
  };

  const onDialogClose = () => {
    setShowDialog(false);
  };

  const onPopUpButtonClick = (actionName: DraftActions) => () => {
    setClickedAction(actionName);
    setShowDialog(true);
  };

  const allocatedTrafficPercentage = useMemo(() => {
    return runningExperiment?.variants?.reduce(
      (sumAllocatedTraffic, currentVariant) =>
        sumAllocatedTraffic + currentVariant.trafficAllocation,
      0
    );
  }, [runningExperiment]);

  const isAtLeastOneGroupSelected = domainGroupsSelection.combinedSelectedDomainGroups.length > 0;
  const numberOfChangesText = domainGroupsSelection.allGroupsWithChangesSelected
    ? 'All'
    : totalChanges;

  return (
    <>
      <ChangesModal
        show={show}
        changesSummary={draft?.changesSummary.changedEntities}
        domainGroupsSelection={domainGroupsSelection}
        onDomainGroupSelected={onDomainGroupSelected}
        setModalIsOpened={setModalIsOpened}
        dataFields={dataFields}
        previewType={PreviewTypeEnum.REVIEW_BEFORE_PUBLISH}
      >
        <ChangesModal.Header>
          <Typography
            className='first-row'
            type={TypographyType.Heading}
            variant={TypographyVariant.ExtraSmallBold}
          >
            Review before publish
          </Typography>

          {runningExperiment && (
            <div className='experiments-notification'>
              <Typography type={TypographyType.Body} variant={TypographyVariant.SmallRegular}>
                You have a running <strong>experiment</strong>. Any change you publish while your
                experiment is running will not be applicable to the users who are allocated for the
                experiment <strong>({allocatedTrafficPercentage}% of users).</strong>
              </Typography>
              <Tooltip value={`Experiment name: ${runningExperiment.name}`}>
                <Icon name={AvailableIcons.TooltipQuestionmark} />
              </Tooltip>
            </div>
          )}
        </ChangesModal.Header>
        <ChangesModal.Footer>
          {isInProgress && <BackDropWithLoader />}
          <Button
            variant='secondary'
            className='cancel-button'
            onClick={onPopUpButtonClick(DraftActions.Discard)}
            disabled={!isAtLeastOneGroupSelected}
          >
            Discard {isAtLeastOneGroupSelected && `(${numberOfChangesText})`}
          </Button>
          <Button
            variant='primary'
            className='publish-button'
            onClick={onPopUpButtonClick(DraftActions.Publish)}
            disabled={!isAtLeastOneGroupSelected}
          >
            Publish {isAtLeastOneGroupSelected && `(${numberOfChangesText})`}
          </Button>
        </ChangesModal.Footer>
      </ChangesModal>
      {showDialog && (
        <ConfirmationDialog onCancel={onDialogClose}>
          <ConfirmationDialog.Header>
            <ConfirmationDialog.Title>Hold on</ConfirmationDialog.Title>
          </ConfirmationDialog.Header>
          <ConfirmationDialog.Content>
            {dialogConfigs[clickedAction].bodyText}
          </ConfirmationDialog.Content>
          <ConfirmationDialog.Footer>
            <ButtonsContainer>
              <Button variant='primary' onClick={dialogConfigs[clickedAction].onProceed}>
                {startCase(clickedAction)}
              </Button>
              <Button variant='outlined' onClick={onDialogClose}>
                Cancel
              </Button>
            </ButtonsContainer>
          </ConfirmationDialog.Footer>
        </ConfirmationDialog>
      )}
    </>
  );
};
