import { ChangesFilterValues } from 'src/services';
import { getStringEnumValues } from 'src/utils';
import { ILexiconRuleTableHeadRow, ILexiconTableBodyRow } from './types';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';

export type ColumnNames = keyof Omit<ILexiconTableBodyRow, 'id'>;

const mainColumnsMinWidth = 100;

export const CHANGES_FILTER_ALL_VALUES = getStringEnumValues(ChangesFilterValues);

export const tableColumns = {
  selected: {
    Header: 'Selected',
    accessor: 'selected',
    width: '5%',
    minWidth: 45,
    maxWidth: 70,
  },
  category: {
    Header: 'Category',
    accessor: 'category',
    width: '20%',
    minWidth: mainColumnsMinWidth,
  },
  attribute: {
    Header: 'Attribute',
    accessor: 'attribute',
    width: '20%',
    minWidth: mainColumnsMinWidth,
  },
  value: {
    Header: 'Value',
    accessor: 'value',
    width: '20%',
    minWidth: mainColumnsMinWidth,
  },
  masterTag: {
    Header: 'Source Tag',
    accessor: 'masterTag',
    width: '20%',
    minWidth: mainColumnsMinWidth,
  },
  changes: {
    Header: 'Status',
    accessor: 'changes',
    width: '15%',
    minWidth: 100,
  },
  actions: {
    Header: 'Actions',
    accessor: 'actions',
    width: '12%',
    maxWidth: 70,
  },
};

export type LexiconTableColumnType = Record<
  keyof Omit<ILexiconTableBodyRow, 'id' | 'originalData' | 'verticals'>,
  ILexiconRuleTableHeadRow
> & {
  verticals?: ILexiconRuleTableHeadRow;
};

export const getTableColumns = ({
  tagType,
}: {
  tagType: LexiconTagType;
}): LexiconTableColumnType => {
  return {
    selected: {
      Header: 'Selected',
      accessor: 'selected',
      width: '5%',
      minWidth: 45,
      maxWidth: 70,
    },
    ...(tagType === LexiconTagType.LexiconTags
      ? {
          verticals: {
            Header: 'Vertical',
            accessor: 'verticals',
            width: '20%',
            minWidth: mainColumnsMinWidth,
          },
        }
      : {}),
    category: {
      Header: 'Category',
      accessor: 'category',
      width: '20%',
      minWidth: mainColumnsMinWidth,
    },
    attribute: {
      Header: 'Attribute',
      accessor: 'attribute',
      width: '20%',
      minWidth: mainColumnsMinWidth,
    },
    value: {
      Header: 'Value',
      accessor: 'value',
      width: '20%',
      minWidth: mainColumnsMinWidth,
    },
    masterTag: {
      Header: 'Source Tag',
      accessor: 'masterTag',
      width: '20%',
      minWidth: mainColumnsMinWidth,
    },
    changes: {
      Header: 'Status',
      accessor: 'changes',
      width: '15%',
      minWidth: 100,
    },
    actions: {
      Header: 'Actions',
      accessor: 'actions',
      width: '12%',
      maxWidth: 70,
    },
  };
};
