import React from 'react';
import { ProductDetailsSection } from './ProductDetailsSection';
import { ProductIdentificationSection } from './ProductIdentificationSection';
import { ProductImagesSection } from './ProductImagesSection';
import { OriginalDataSection } from './OriginalDataSection';

interface SummaryTabProps {
  details: Record<string, any>;
  skus: {
    sku?: string;
    sizeSku?: string;
    colorSku?: string;
    parentSku?: string;
  };
  imagesToDisplay: {
    displayName: string;
    value: string | string[];
  }[];
  originalData: Record<string, any>;
}

export const SummaryTab = ({ details, skus, imagesToDisplay, originalData }: SummaryTabProps) => {
  return (
    <>
      <ProductDetailsSection details={details} />
      <ProductIdentificationSection skus={skus} />
      <ProductImagesSection imagesToDisplay={imagesToDisplay} />
      <OriginalDataSection originalData={originalData} />
    </>
  );
};
