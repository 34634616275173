import { useEffect, useState, useCallback } from 'react';
import { useValidateSchema } from 'src/hooks';
import { RecentlyViewedSettingsReducerState } from 'src/app-state-types';
import {
  UseRecentlyViewedSettingsDraftArgs,
  UseRecentlyViewedSettingsDraft,
  RecentlyViewedSettingsRecord,
} from './types';
import { recentlyViewedSettingsValidationSchema } from './constants';
import { recentlyViewedSettingsActionsMethods } from './Actions';
import { isDataDirty } from '../../utils';

export function useRecentlyViewedSettingsDraft({
  recentlyViewedSettings,
  dispatch,
  shopId,
  selectedVariantId,
  onIsDirty,
  shouldRefetch,
}: UseRecentlyViewedSettingsDraftArgs): UseRecentlyViewedSettingsDraft {
  const [draftRecentlyViewedSettings, setDraftRecentlyViewedSettings] =
    useState(recentlyViewedSettings);

  const { errors, validate, isValid } = useValidateSchema<RecentlyViewedSettingsRecord>({
    schema: recentlyViewedSettingsValidationSchema,
  });

  const isDirty = isDataDirty(recentlyViewedSettings, draftRecentlyViewedSettings);

  const onSubmit = (): void => {
    if (isValid) {
      dispatch(
        recentlyViewedSettingsActionsMethods.updateRecentlyViewedSettings({
          shopId,
          variantId: selectedVariantId,
          showPreviouslyPurchasedItems: draftRecentlyViewedSettings.showPreviouslyPurchasedItems,
          showOutOfStockItems: draftRecentlyViewedSettings.showOutOfStockItems,
          numberOfResultsToDisplay: draftRecentlyViewedSettings.numberOfResultsToDisplay,
          numberOfRecentDaysToDisplay: draftRecentlyViewedSettings.numberOfRecentDaysToDisplay,
          addToCart: draftRecentlyViewedSettings.addToCart,
        })
      );
    }
  };

  const onDiscard = (): void => {
    setDraftRecentlyViewedSettings(recentlyViewedSettings);
  };

  const onChange = (partialSettings: Partial<RecentlyViewedSettingsReducerState>) => {
    const updatedState = { ...draftRecentlyViewedSettings, ...partialSettings };
    validate({ dataToValidate: updatedState });
    setDraftRecentlyViewedSettings(updatedState);
  };

  const fetchRecentlyViewedSettings = useCallback(() => {
    dispatch(
      recentlyViewedSettingsActionsMethods.fetchRecentlyViewedSettings({
        shopId,
        variantId: selectedVariantId,
      })
    );
  }, [shopId, selectedVariantId]);

  useEffect((): void => {
    setDraftRecentlyViewedSettings(recentlyViewedSettings);
  }, [recentlyViewedSettings]);

  useEffect(() => {
    const payload = { isDirty: !!isDirty };
    if (onIsDirty) {
      onIsDirty(payload);
    } else {
      dispatch(recentlyViewedSettingsActionsMethods.notifyIsDirty(payload));
    }
  }, [isDirty]);

  useEffect(() => {
    fetchRecentlyViewedSettings();
  }, [fetchRecentlyViewedSettings]);

  useEffect(() => {
    if (shouldRefetch) {
      fetchRecentlyViewedSettings();
    }
  }, [shouldRefetch]);

  return {
    draftRecentlyViewedSettings,
    errors,
    isValid,
    isDirty,
    onSubmit,
    onDiscard,
    onChange,
  };
}
