import { MerchandisingRuleTypes, FeedDataFieldType, FieldType } from 'src/services';
import {
  DataFieldConditionDataInnerType,
  DataFieldConditionDataType,
  ConditionValueInnerType,
} from './DataFieldsCondition.types';

const HAS_VALUE = 'HAS_VALUE';
const EQUALS_SOURCE = 'EQUALS_SOURCE';

export function isBooleanDataField({
  types = [],
}: {
  types: FeedDataFieldType[] | undefined;
}): boolean {
  return types.includes(FeedDataFieldType.Boolean);
}

const numericDataFieldTypes = [
  FeedDataFieldType.Float,
  FeedDataFieldType.Integer,
  FeedDataFieldType.Long,
];

export function isNumericDataField({
  types = [],
}: {
  types: FeedDataFieldType[] | undefined;
}): boolean {
  return types.some(type => numericDataFieldTypes.includes(type));
}

export function dataFieldsToPartialSubRule(
  dataFieldData: DataFieldConditionDataInnerType
): DataFieldConditionDataType {
  return {
    field: dataFieldData.dataField,
    fieldType: dataFieldData.fieldType || ('' as FieldType),
    subType: dataFieldData.conditionType as MerchandisingRuleTypes.MerchandisingRuleSubType,
    values: dataFieldData.conditionValue,
    position: dataFieldData.position,
    tempId: dataFieldData.tempId,
  };
}

export const numericValuesIndexesMap = {
  Min: 0,
  Max: 1,
} as const;

export function convertConditionValue(
  type: typeof MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain,
  values: MerchandisingRuleTypes.SubRuleOptionalValues,
  dataFieldTypes?: FeedDataFieldType[]
): string[] | string;

export function convertConditionValue(
  type: typeof MerchandisingRuleTypes.MerchandisingRuleSubType.Contains,
  values: MerchandisingRuleTypes.SubRuleOptionalValues
): string[];

export function convertConditionValue(
  type: typeof MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
  values: MerchandisingRuleTypes.SubRuleOptionalValues,
  dataFieldTypes?: FeedDataFieldType[]
): string[] | string;

export function convertConditionValue(
  type: typeof MerchandisingRuleTypes.MerchandisingRuleSubType.Equals,
  values: MerchandisingRuleTypes.SubRuleOptionalValues,
  dataFieldTypes?: FeedDataFieldType[]
): boolean;

export function convertConditionValue(
  type:
    | typeof MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan
    | typeof MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan,
  values: MerchandisingRuleTypes.SubRuleOptionalValues
): number;

export function convertConditionValue(
  type:
    | typeof MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween
    | typeof MerchandisingRuleTypes.MerchandisingRuleSubType.RelativeRange,
  values: MerchandisingRuleTypes.SubRuleOptionalValues
): [number, number];

export function convertConditionValue(
  type: MerchandisingRuleTypes.MerchandisingRuleSubType,
  values: MerchandisingRuleTypes.SubRuleOptionalValues,
  dataFieldTypes?: FeedDataFieldType[]
): ConditionValueInnerType {
  let result: ConditionValueInnerType;
  switch (type) {
    case MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain:
      if (isBooleanDataField({ types: dataFieldTypes })) {
        result = typeof values?.[0] === 'boolean' ? values?.[0] : true;
      } else if (isNumericDataField({ types: dataFieldTypes })) {
        result = values?.[0] || '';
      } else {
        result = values as string[];
      }
      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.Contains:
      if (isBooleanDataField({ types: dataFieldTypes })) {
        result = typeof values?.[0] === 'boolean' ? values?.[0] : true;
      } else {
        result = values as string[];
      }
      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.Equals:
      if (isBooleanDataField({ types: dataFieldTypes })) {
        result = typeof values?.[0] === 'boolean' ? values?.[0] : true;
      } else if (isNumericDataField({ types: dataFieldTypes })) {
        result = values?.[0] || '';
      } else {
        result = values as string[];
      }
      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan:
      result = Number(values?.[numericValuesIndexesMap.Max]);
      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan:
      result = Number(values?.[numericValuesIndexesMap.Min]);
      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween:
    case MerchandisingRuleTypes.MerchandisingRuleSubType.RelativeRange:
      {
        const min = Number(values?.[numericValuesIndexesMap.Min]);
        const max = Number(values?.[numericValuesIndexesMap.Max]);
        result = [min, max];
      }
      break;
    default:
      result = [];
  }

  return result;
}

export function convertConditionValueToRaw(
  subType: MerchandisingRuleTypes.MerchandisingRuleSubType | undefined,
  value: ConditionValueInnerType,
  dataFieldTypes?: FeedDataFieldType[]
): MerchandisingRuleTypes.SubRuleOptionalValues {
  type ResultType = MerchandisingRuleTypes.SubRuleOptionalValues;

  let result: ResultType;

  switch (subType) {
    case MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain:
      if (isBooleanDataField({ types: dataFieldTypes })) {
        result = (typeof value === 'boolean' ? [value] : [true]) as ResultType;
      } else if (isNumericDataField({ types: dataFieldTypes })) {
        result = (value ? [value] : []) as ResultType;
      } else {
        result = value as ResultType;
      }

      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.Contains:
      result = value as ResultType;
      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.Equals:
      if (isBooleanDataField({ types: dataFieldTypes })) {
        result = (typeof value === 'boolean' ? [value] : [true]) as ResultType;
      } else if (isNumericDataField({ types: dataFieldTypes })) {
        result = (value ? [value] : []) as ResultType;
      } else if (!value) {
        result = [] as ResultType;
      } else {
        result = typeof value === 'string' ? [value] : (value as ResultType);
      }
      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan:
      {
        const values: ResultType = [null, null];
        values[numericValuesIndexesMap.Min] = value as number;
        result = values as ResultType;
      }
      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan:
      {
        const values: ResultType = [null, null];
        values[numericValuesIndexesMap.Max] = value as number;
        result = values as ResultType;
      }
      break;
    case MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween:
    case MerchandisingRuleTypes.MerchandisingRuleSubType.RelativeRange:
      result = value as ResultType;
      break;
    default:
      result = [];
  }

  return result;
}

export function partialSubRuleToDataFields(
  data: DataFieldConditionDataType
): DataFieldConditionDataInnerType {
  return {
    dataField: data.field,
    fieldType: data.fieldType,
    conditionType: data.subType,
    conditionValue: data.values,
    position: data.position,
    tempId: data.tempId,
  };
}

export const defaultConditionTypeValueMapRecord: Record<
  MerchandisingRuleTypes.MerchandisingRuleSubType,
  MerchandisingRuleTypes.SubRuleOptionalValues
> = {
  [MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotContain]: [],
  [MerchandisingRuleTypes.MerchandisingRuleSubType.Contains]: [],
  [MerchandisingRuleTypes.MerchandisingRuleSubType.Equals]: [],
  [MerchandisingRuleTypes.MerchandisingRuleSubType.LowerThan]: [null, null],
  [MerchandisingRuleTypes.MerchandisingRuleSubType.HigherThan]: [null, null],
  [MerchandisingRuleTypes.MerchandisingRuleSubType.RelativeRange]: [null, null],
  [MerchandisingRuleTypes.MerchandisingRuleSubType.IsBetween]: [null, null],
  [MerchandisingRuleTypes.MerchandisingRuleSubType.EqualsSource]: [],
  [MerchandisingRuleTypes.MerchandisingRuleSubType.HasValue]: [],
  [MerchandisingRuleTypes.MerchandisingRuleSubType.DoesNotHaveValue]: [],
};

export function getDefaultConditionValue(
  conditionType: MerchandisingRuleTypes.MerchandisingRuleSubType | undefined,
  dataFieldTypes?: FeedDataFieldType[]
): MerchandisingRuleTypes.SubRuleOptionalValues {
  if (!conditionType) {
    return [];
  }

  if (conditionType === HAS_VALUE || conditionType === EQUALS_SOURCE) {
    return defaultConditionTypeValueMapRecord[conditionType];
  }

  return isBooleanDataField({ types: dataFieldTypes })
    ? [true]
    : defaultConditionTypeValueMapRecord[conditionType];
}

export const conditionValueSelectBooleanOptions = [
  { value: true, text: 'True' },
  { value: false, text: 'False' },
];
