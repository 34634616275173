import React, { useCallback, useState } from 'react';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box } from '@mui/material';
import { Button } from '../Button';
import { DatePickerTitleStyled } from './DateRangePicker.style';

/**
 * Utils
 */

const isDateInstance = (value: Date | null | undefined) => value instanceof Date;

const getStartDayOfStartDate = (date: Date): Date => {
  date.setHours(0, 0, 0, 0);
  return date;
};

const getEndDayOfEndDate = (date: Date): Date => {
  date.setHours(23, 59, 59, 999);
  return date;
};

/**
 * Main
 */
export interface DateRangePickerWithButtonsContentProps {
  startDate: Date | null;
  endDate: Date | null;
  onApply: ({ startDate, endDate }: { startDate: Date | null; endDate: Date | null }) => void;
}

export const DateRangePickerWithButtonsContent = ({
  onApply,
  startDate = null,
  endDate = null,
}: DateRangePickerWithButtonsContentProps): JSX.Element => {
  const startDateOriginalValue = isDateInstance(startDate)
    ? getStartDayOfStartDate(startDate as Date)
    : startDate;

  const endDateOriginalValue = isDateInstance(endDate)
    ? getStartDayOfStartDate(endDate as Date)
    : startDate;

  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(startDateOriginalValue);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(endDateOriginalValue);

  const handleApply = () => {
    const data = {
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    };

    onApply(data);
  };

  const handleReset = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };

  const onStartDateChange = (value: Date | null) => {
    const isNewValueDateValue = isDateInstance(value);
    const newStartDate = isNewValueDateValue ? getStartDayOfStartDate(value as Date) : value;

    setSelectedStartDate(newStartDate);

    // new start date & selected end date both are dates (not null)
    const areAllDateInstances = isNewValueDateValue && isDateInstance(selectedEndDate);

    // Incase start date is later than end date -> we'll set end date for same date as start date
    if (areAllDateInstances && (newStartDate as Date) > (selectedEndDate as Date)) {
      setSelectedEndDate(getEndDayOfEndDate(newStartDate as Date));
    }
  };

  const onEndDateChange = (value: Date | null) => {
    const newEndDate = isDateInstance(value) ? getEndDayOfEndDate(value as Date) : value;

    setSelectedEndDate(newEndDate);
  };

  const renderEmptyDiv = useCallback(
    () => <div style={{ display: 'none' }} aria-hidden='true' />,
    []
  );

  const hasStartAndEndDates = selectedStartDate instanceof Date && selectedEndDate instanceof Date;

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ display: 'flex' }}>
          <div>
            <DatePickerTitleStyled>Start Date</DatePickerTitleStyled>
            <StaticDatePicker
              displayStaticWrapperAs='desktop'
              value={selectedStartDate}
              onChange={onStartDateChange}
              renderInput={renderEmptyDiv}
            />
          </div>
          <div>
            <DatePickerTitleStyled>End Date</DatePickerTitleStyled>
            <StaticDatePicker
              displayStaticWrapperAs='desktop'
              value={selectedEndDate}
              onChange={onEndDateChange}
              minDate={isDateInstance(selectedStartDate) ? (selectedStartDate as Date) : undefined}
              renderInput={renderEmptyDiv}
            />
          </div>
        </Box>
      </LocalizationProvider>
      <Box sx={{ display: 'flex', alignSelf: 'end', padding: '0 16px 0 0' }}>
        <Button variant='secondary' onClick={handleReset}>
          Clear
        </Button>
        &nbsp; &nbsp;
        <Button variant='primary' onClick={handleApply} disabled={!hasStartAndEndDates}>
          Done
        </Button>
      </Box>
    </>
  );
};
