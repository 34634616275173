import { useReducer, useEffect, useState, useRef, useImperativeHandle, RefObject } from 'react';
import { isEqual } from 'lodash';
import { SingleFormValidator, ItemValidityMap } from 'src/components-bl/helpers/form-validation';
import { FilterSetSettings } from 'src/services/src/service/types/filters';
import { validatorsDict } from './form-validations';
import {
  shouldResetValue,
  FieldsToTriggerReset,
  ValueTypeToTriggerReset,
} from './fields-dependency-sync';
import { filterSettingsFormActions, FilterSettingsFormAction } from './Actions';
import { FormApiRef } from '../AugmentedSearch';

const filterSetSettingsReducer = (
  state: FilterSetSettings,
  action: FilterSettingsFormAction
): FilterSetSettings => ({ ...state, ...action.payload });
interface UseFilterSetSettingsFormReturnType {
  formDraft: FilterSetSettings;
  updateForm: (updates: Partial<FilterSetSettings>) => void;
  errors: ItemValidityMap<FilterSetSettings>;
}

export const useFilterSetSettingsForm = (
  initialSettings: FilterSetSettings,
  isDirtyDispatcher: (isDirty: boolean) => void,
  onSubmit: (formData: FilterSetSettings) => void,
  ref: RefObject<FormApiRef>,
  onSubmitStatusChange: (canSubmit: boolean) => void
): UseFilterSetSettingsFormReturnType => {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const validator = useRef(new SingleFormValidator(validatorsDict));
  const [formDraft, dispatcher] = useReducer(filterSetSettingsReducer, initialSettings);
  useEffect(() => {
    if (initialSettings) {
      dispatcher(filterSettingsFormActions.formInit(initialSettings));
      validator.current?.reset();
      isDirtyDispatcher(false);
      setIsDirty(false);
    }
  }, [initialSettings]);
  useEffect(() => {
    const hasDiff = !isEqual(initialSettings, formDraft);
    if (hasDiff !== isDirty) {
      isDirtyDispatcher(hasDiff);
      setIsDirty(hasDiff);
    }
  }, [formDraft]);

  useEffect(() => {
    onSubmitStatusChange(isDirty);
  }, [isDirty, onSubmitStatusChange]);

  const updateForm = (updates: Partial<FilterSetSettings>) => {
    const fieldsToTriggerReset = Object.values(FieldsToTriggerReset) as string[];
    const updatedData: FilterSetSettings = { ...formDraft, ...updates };

    validator.current?.validateAll(updatedData);
    Object.keys(updates).forEach(key => {
      if (fieldsToTriggerReset.includes(key)) {
        const field = key as FieldsToTriggerReset;
        const value = updates[field] as ValueTypeToTriggerReset;
        const fieldToReset = shouldResetValue(field, value, validator.current?.state);
        if (fieldToReset) {
          updatedData[fieldToReset] = initialSettings[fieldToReset];
          validator.current?.reset(fieldToReset);
        }
      }
    });
    dispatcher(filterSettingsFormActions.formUpdate(updatedData));
  };
  const handleSubmit = () => {
    if (validator.current?.isValid) {
      onSubmit(formDraft);
    }
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        submit: async () => handleSubmit(),
      };
    },
    [handleSubmit]
  );

  return {
    formDraft,
    updateForm,
    errors: validator.current?.state,
  };
};
