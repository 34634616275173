import React from 'react';
import { MenuItem } from 'src/components-dummy/MenuItem';
import { Select, SelectType } from 'src/components-dummy/Select';
import { css } from '@emotion/react';
import { AUTHENTICATION } from '../../CatalogForm.config';

export const AuthenticationSelect = ({
  value,
  onChange,
  isDisabled = false,
}: {
  value: boolean;
  onChange: (e: any) => void;
  isDisabled?: boolean;
}): JSX.Element => {
  return (
    <Select
      type={SelectType.Primary}
      label='Authentication'
      value={value ? AUTHENTICATION.required.key : AUTHENTICATION.noneRequired.key}
      onChange={onChange}
      disabled={!!isDisabled}
      css={css`
        min-width: 132px;
      `}
    >
      <MenuItem key='required' value={AUTHENTICATION.required.key}>
        Required
      </MenuItem>
      <MenuItem key='none-required' value={AUTHENTICATION.noneRequired.key}>
        Not Required
      </MenuItem>
    </Select>
  );
};
