import React, { MouseEvent } from 'react';
import {
  AssignedProduct,
  Coordinates,
  PublicPost,
} from 'src/components-bl/socialDiscovery/types/entities.types';
import styled from '@emotion/styled';
import { useProductTagsState } from 'src/components-bl/socialDiscovery/ProductTags/useProductTagsState/useProductTagsState';
import { StateType } from 'src/components-bl/socialDiscovery/ProductTags/useProductTagsState/types';

import { ProductTag } from '../ProductTag/ProductTag';
import { StoryImage } from '../StoryImage/StoryImage';
import { ProductTagContainer } from './ProductTagContainer';
import { calculateProductTagPixelPosition } from './calculateProductTagPosition';
import { NoTagsInfo } from './NoTagsInfo';
import { AddProduct } from '../SKUInput/AddProduct';

export const StyledPostPreview = styled.div<{
  inputTop?: number;
}>`
  position: relative;
  width: 320px;
  height: 540px;
  margin: 10px;

  .add-product-container {
    top: ${props => (props.inputTop === undefined ? 20 : props.inputTop)}%;
  }
`;

interface PostPreviewProps {
  post: PublicPost;
  onTagsChange: (updatedAssignedProducts: AssignedProduct[]) => void;
}

export const PostPreview = ({ post, onTagsChange }: PostPreviewProps): JSX.Element => {
  const {
    state,
    openInput,
    closeInput,
    dragStart,
    setCoordinates,
    fixCoordinates,
    addProduct,
    removeProduct,
    setPostSize,
  } = useProductTagsState(post.assignedProducts, onTagsChange);

  const { assignedProducts } = state;

  const handleOpenAddProduct = (e: MouseEvent<HTMLImageElement>): void => {
    if (state.type === StateType.inputActive) return;

    e.stopPropagation();
    const pointerEvent = e.nativeEvent;
    openInput({ x: pointerEvent.offsetX, y: pointerEvent.offsetY });
  };

  return (
    <StyledPostPreview inputTop={state.tagCoordinates?.y}>
      {!assignedProducts.length && <NoTagsInfo />}
      <StoryImage imageSrc={post.imageUrl} onClick={handleOpenAddProduct} onLoad={setPostSize}>
        <>
          {state.type !== StateType.loadingImage &&
            post.assignedProducts.map((assignedProduct, i) => {
              return (
                <ProductTagContainer
                  key={assignedProduct.sku}
                  position={calculateProductTagPixelPosition(
                    state.postSize,
                    assignedProduct.coordinates
                  )}
                  onDragStart={dragStart}
                  onDragEnd={(coordinates: Coordinates) => setCoordinates(i, coordinates)}
                  onRender={size => fixCoordinates(i, size)}
                >
                  <ProductTag product={assignedProduct} onDelete={() => removeProduct(i)} />
                </ProductTagContainer>
              );
            })}

          {state.type === StateType.inputActive && (
            <AddProduct onClickOutside={closeInput} onProductFetched={addProduct} />
          )}
        </>
      </StoryImage>
    </StyledPostPreview>
  );
};
