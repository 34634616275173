import React, { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useAppSelector } from 'src/hooks';

import { BackButtonTitle, Page, Tab, Tabs } from '../../components-dummy';
import { ResultsModalGeneralSettings } from './components';

import { AppRoute, AppSwitch, RoutedComponentProps } from '../../app-routes';

import { ShopRoutes } from '../../types/enums/shop-routes';
import { ComponentRoute } from './types';

import './ResultsModalSettings.scss';

export type ResultsModalSettingsProps = RoutedComponentProps & { shopId: number };

export const ResultsModalSettings: React.FC<ResultsModalSettingsProps> = ({
  permittedRouteMap,
  shopId,
}) => {
  const role = useAppSelector(state => state.global.loggedInUser?.role);

  const formHeaderRef = useRef<HTMLDivElement>(null);
  const navigationPath = `/shops/${shopId}/${ShopRoutes.ShopperExperiences}`;

  const dispatch = useDispatch();

  const relevantRoutes = useMemo(() => {
    const props = {
      shopId,
      formHeaderRef,
      role,
      dispatch,
    };

    const routes = [
      {
        route: permittedRouteMap.resultsModalGeneralSettings,
        Component: ResultsModalGeneralSettings,
        props,
      },
    ].filter(routeComponent => Boolean(routeComponent.route)) as ComponentRoute[];

    return routes;
  }, [permittedRouteMap.resultsModalGeneralSettings, shopId, role, dispatch]);

  const tabs = useMemo(
    () =>
      relevantRoutes.map(({ route }) => (
        <Tab uniqueId={route.path} key={route.path} href={generatePath(route.path, { shopId })}>
          {route.title}
        </Tab>
      )),
    [relevantRoutes]
  );

  const routeComponents = useMemo(
    () =>
      relevantRoutes.map(({ route, Component, props }) => (
        <AppRoute key={route.path} route={route} Component={Component} componentProps={props} />
      )),
    [relevantRoutes]
  );

  return (
    <Page className='results-modal-settings'>
      <Page.Header>
        <BackButtonTitle path={navigationPath}>Results Modal Settings</BackButtonTitle>
        <div className='results-modal-settings-submit-button' ref={formHeaderRef} />
        <Tabs>{tabs}</Tabs>
      </Page.Header>
      <Page.Content>
        <AppSwitch>{routeComponents}</AppSwitch>
      </Page.Content>
    </Page>
  );
};
