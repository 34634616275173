import React, { useState, useMemo, useEffect } from 'react';
import { isEqual } from 'lodash';
import './ListActionBar.scss';
import { Select, MenuItem, SelectType, SelectOnChangeEvent } from 'src/components-dummy';
import { AvailableIcons } from '../Icon';
import { SearchInput } from '../SearchInput';
import { Button } from '../Button';
import { ListActionBarProps, IsIncludedIndicator, FilterBy } from './types';

export const ListActionBar = <T extends Record<string, unknown>>({
  addButton,
  searchableTargetFields,
  filterBy,
  itemsList,
  handleFilterChange = () => undefined,
}: ListActionBarProps<T>): JSX.Element => {
  const dropdownOptions = useMemo(() => {
    const generateDropdownOptionsBasedOnFilterField = (): string[] => {
      if (filterBy.property) {
        return itemsList.reduce(
          (acc, item) => {
            const filterValue = item[filterBy.property];
            if (typeof filterValue === 'string') {
              if (!acc.includes(filterValue)) acc.push(filterValue);
            } else {
              console.warn(`The supplied filter option value (${filterValue}) is not a string`);
            }
            return acc;
          },
          ['All']
        );
      }
      return ['All'];
    };
    const dropdownOptionsArr = generateDropdownOptionsBasedOnFilterField();
    return [...dropdownOptionsArr] as const;
  }, [itemsList]);
  type SelectOptionType = (typeof dropdownOptions)[number];
  const [searchValue, setSearchInputValue] = useState<string>('');
  const [filterValue, setFilterValue] = useState<SelectOptionType>('All');
  const applyFilter = (filterByObj: FilterBy, filterInputValue: string): T[] => {
    const filteredList = itemsList.map((item: T) => {
      const updatedItem: Record<string, any> = { ...item };
      const itemFilterStatus = item[filterByObj.property];
      const excludeFilterValue = filterByObj.excludedValues?.includes(itemFilterStatus as string);
      const isAllSelected = filterInputValue === 'All';
      const valuesMatch = itemFilterStatus === filterInputValue;
      const isIncluded = isAllSelected || excludeFilterValue || valuesMatch;
      updatedItem[IsIncludedIndicator] = isIncluded;
      return updatedItem;
    });
    return filteredList as T[];
  };
  const applySearch = (items: T[], searchFields: string[], searchInputValue: string): T[] => {
    const trimmedSearchTerm = searchInputValue.trim().toLowerCase();
    const searchedList = items.map((item: T) => {
      const updatedItem: Record<string, any> = { ...item };
      if (item[IsIncludedIndicator]) {
        const joinedSearchString = searchFields.reduce((acc, searchField) => {
          const searchFieldValue = item[searchField] as string;
          if (searchFieldValue) {
            return acc.concat('_', searchFieldValue.toLocaleLowerCase());
          }
          return acc;
        }, '');
        updatedItem[IsIncludedIndicator] = joinedSearchString.includes(trimmedSearchTerm);
      }
      return updatedItem;
    });
    return searchedList as T[];
  };
  const applySearchAndFilter = (searchInputValue: string, filterInputValue: string): T[] => {
    const filteredList = filterBy.property ? applyFilter(filterBy, filterInputValue) : itemsList;
    const searchedList = searchableTargetFields
      ? applySearch(filteredList, searchableTargetFields, searchInputValue)
      : filteredList;
    return searchedList;
  };
  useEffect(() => {
    const filteredList = applySearchAndFilter(searchValue, filterValue);
    if (!isEqual(filteredList, itemsList)) handleFilterChange(filteredList);
  }, [searchValue, filterValue, itemsList]);

  const convertSearchValue: SelectOnChangeEvent = event => setFilterValue(event.target.value);

  return (
    <div className='syte-list-action-bar'>
      <div className='syte-list-action-bar__controls'>
        {searchableTargetFields && (
          <SearchInput
            placeholder='Search'
            className='search-input'
            value={searchValue}
            onChange={setSearchInputValue}
          />
        )}
        {filterBy.property && (
          <Select
            type={SelectType.Menu}
            value={filterValue}
            onChange={convertSearchValue}
            className='syte-drop-down'
          >
            {dropdownOptions.map((option: SelectOptionType) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        )}
        {addButton && (
          <Button startIcon={AvailableIcons.Add} onClick={addButton.cb} variant='secondary'>
            {addButton.label}
          </Button>
        )}
      </div>
    </div>
  );
};
