import { useCallback, useRef, useState } from 'react';

export type StateController<T> = {
  value: T | null;
  setValue: (value: T) => void;
  reset: VoidFunction;
  setResetFlag: (value: boolean) => void;
  shouldReset: boolean;
};

export const useStateControllerRef = <T>(): StateController<T> => {
  const stateRef = useRef<T | null>(null);

  const [shouldReset, setShouldReset] = useState(false);

  const reset = useCallback(() => {
    stateRef.current = null;
  }, []);

  const setValue = useCallback((value: T) => {
    stateRef.current = value;
  }, []);

  const setResetFlag = useCallback((flag: boolean) => {
    setShouldReset(flag);
  }, []);

  return { value: stateRef.current, setValue, reset, setResetFlag, shouldReset };
};
