import React, { useEffect, useState } from 'react';
import {
  FiltersList,
  EditableFilterItem,
  SelectFiltersOrderMethod,
  ToggleUseFiltersDisplayName,
} from 'src/components-bl';
import { UserRoles } from 'src/services/src/service/types/users';
import {
  CatalogFiltersConfig,
  FilterItemDBStructure,
  OrderMethod,
} from 'src/services/src/service/types/filters';
import { CatalogFiltersConfigProps } from './types';
import { AvailableIcons } from '../../components-dummy';

export const CatalogFilters = ({
  onSubmitDispatcher,
  shopId,
  filterSet,
  isDirtyDispatcher,
  rawDataFields,
  unSavedDataFields,
  updateStatus,
  userRole,
  formApiRef,
  onSubmitStatusChange,
}: CatalogFiltersConfigProps): JSX.Element => {
  const [orderMethod, setOrderMethod] = useState<OrderMethod | undefined>(
    filterSet?.filters?.settings?.order
  );

  const [useDisplayName, setUseDisplayName] = useState(
    filterSet?.filters?.settings?.useDisplayName || false
  );

  const onSubmit = (payload: EditableFilterItem[]) => {
    const filters: CatalogFiltersConfig = {
      settings: { order: orderMethod || filterSet?.filters?.settings?.order, useDisplayName },
      items: payload as FilterItemDBStructure[],
    };
    onSubmitDispatcher({
      shopId,
      data: { ...filterSet, filters },
    });
    setOrderMethod(undefined);
  };

  useEffect(() => {
    setOrderMethod(filterSet?.filters?.settings?.order);
    setUseDisplayName(filterSet?.filters?.settings?.useDisplayName);
  }, [filterSet]);

  const [activeFiltersCount, setActiveFiltersCount] = useState<number>(
    filterSet?.filters?.items?.filter(item => item.enabled).length
  );

  const isAdditionalSettingsDirty =
    orderMethod !== filterSet?.filters?.settings?.order ||
    useDisplayName !== filterSet?.filters?.settings?.useDisplayName;

  const isAdminUser = userRole === UserRoles.SyteAdmin;

  return filterSet?.filters ? (
    <FiltersList
      onSubmit={onSubmit}
      itemsList={filterSet.filters.items}
      isDragAndDropEnabled={orderMethod === OrderMethod.manual}
      messages={{
        mainTitle: {
          text: 'Catalogue Filters List',
          tooltip: {
            enabled: activeFiltersCount === 0,
            text: 'Filter set must have at least 1 visible filter',
            icon: AvailableIcons.Warning,
          },
        },
        disable: { title: 'Catalogue Filter Visibility' },
        delete: { title: 'Catalogue Filter Deletion' },
      }}
      syncItemsCount={setActiveFiltersCount}
      isDirtyDispatcher={isDirtyDispatcher}
      hasExternalDiff={isAdditionalSettingsDirty}
      updateStatus={updateStatus}
      listActionOptions={{
        allowAddItem: true,
        allowDeleteItem: true,
        allowDisableAll: false,
        uniqueFields: ['dataSource', 'displayNameKey'],
      }}
      rawDataFields={rawDataFields}
      unSavedDataFields={unSavedDataFields}
      shouldIncludeDisplayName
      formApiRef={formApiRef}
      onSubmitStatusChange={onSubmitStatusChange}
    >
      <FiltersList.AdditionalSettings>
        {isAdminUser && (
          <ToggleUseFiltersDisplayName enabled={useDisplayName} onChange={setUseDisplayName} />
        )}
        <SelectFiltersOrderMethod
          orderMethod={orderMethod}
          dispatcher={setOrderMethod}
          listType='catalog'
        />
      </FiltersList.AdditionalSettings>
    </FiltersList>
  ) : (
    <></>
  );
};
