import React, { useMemo } from 'react';
import { RadioProps as RadioPropsMui } from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import { LabelWrapperStyled, LabelTextStyled } from './RadioGroup.styles';
import { RadioButtonStyled } from './RadioButton.styles';

export interface RadioProps {
  label?: string | JSX.Element;
  checked?: RadioPropsMui['checked']; // or just - checked?: boolean;
  value: string | number;
  disabled?: RadioPropsMui['disabled']; // or just - disabled?: boolean;
}

const RadioLabelText = ({ label }: { label: string }) => {
  return (
    <LabelTextStyled>
      <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
        {label}
      </Typography>
    </LabelTextStyled>
  );
};
export const RadioButton = ({ checked, value, label, disabled }: RadioProps): JSX.Element => {
  const radioLabel = useMemo(() => {
    return (
      <LabelWrapperStyled>
        {typeof label === 'string' || typeof label === 'number' ? (
          <RadioLabelText label={label} />
        ) : (
          label
        )}
      </LabelWrapperStyled>
    );
  }, [label]);

  return (
    <FormControlLabel
      disabled={disabled}
      checked={checked}
      value={value}
      control={<RadioButtonStyled disableRipple />}
      label={radioLabel}
    />
  );
};

RadioButton.LabelText = RadioLabelText;
