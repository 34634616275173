import React from 'react';
import classnames from 'classnames';
import { useFindComponentByType } from '../hooks';
import './Page.scss';
import { PageContent } from './Components/PageContent';
import { PageHeader } from './Components/PageHeader';
import { PageTitle } from './Components/PageTitle';
import { PageSubTitle } from './Components/PageSubTitle';
import { PageCenteredStyle, PageDefaultStyled, PageSideBarStyled } from './Page.styles';
import { PageFooter } from './Components/PageFooter';
import { PageSideBar } from './Components/PageSideBar';

export interface PageProps {
  children: JSX.Element[];
  className?: string;
  layout?: 'centered' | 'sidebar' | 'default'; // TODO: add more layouts
}

export const Page = ({ children, className, layout }: PageProps): JSX.Element => {
  const pageHeader = useFindComponentByType({ type: PageHeader, children });
  const pageContent = useFindComponentByType({ type: PageContent, children });
  const pageFooter = useFindComponentByType({ type: PageFooter, children });
  const pageSideBar = useFindComponentByType({ type: PageSideBar, children });

  if (layout) {
    switch (layout) {
      case 'centered':
        return (
          <PageCenteredStyle className={className}>
            {pageHeader}
            {pageContent}
          </PageCenteredStyle>
        );
      case 'sidebar':
        return (
          <PageSideBarStyled className={className}>
            <div className='sidebar'>{pageSideBar}</div>
            <div className='main'>
              {pageHeader}
              {pageContent}
            </div>
          </PageSideBarStyled>
        );
      case 'default':
      default:
        return (
          <PageDefaultStyled className={className}>
            {pageHeader}
            {pageContent}
          </PageDefaultStyled>
        );
    }
  }

  return (
    <div className={classnames('syte-page', className)}>
      {pageHeader}
      {pageContent}
      {pageFooter}
    </div>
  );
};

Page.Header = PageHeader;
Page.Content = PageContent;
Page.Title = PageTitle;
Page.SubTitle = PageSubTitle;
Page.Footer = PageFooter;
Page.SideBar = PageSideBar;
