import React, { useState } from 'react';
import {
  Button,
  Typography,
  TypographyType,
  TypographyVariant,
  Icon,
  InfoModal,
} from 'src/components-dummy';
import './DiscoveryBannerSettings.scss';
import { AvailableIcons } from 'src/components-dummy/Icon/AvailableIcons';
import bannerExample from './Assets/images/bannerExample.jpg';
import { BannerSettings } from './components/BannerSettings';
import { CameraIcon } from './components/CameraIcon';
import { Heading } from './components/Heading';
import { CtaButton } from './components/CtaButton';

import { DiscoveryBannerSettingsProps } from './types';
import { useDiscoveryBannerSettingsDraft } from './useDiscoveryBannerSettingsDraft';
import { createOptionalPortal } from '../helpers';

export const DiscoveryBannerSettings = ({
  shopId,
  discoveryBannerSettings,
  selectedVariantId,
  formHeaderElementRef,
  onIsDirty,
  dispatch,
  disabled = false,
  shouldRefetch,
}: DiscoveryBannerSettingsProps): JSX.Element | null => {
  const discoveryBanner = useDiscoveryBannerSettingsDraft({
    discoveryBannerSettings,
    dispatch,
    shopId,
    selectedVariantId,
    onIsDirty,
    shouldRefetch,
  });

  const {
    draftDiscoveryBannerSettings,
    isValid,
    isDirty,
    onSubmit,
    onDiscard,
    onChangeGeneral,
    onChangeHeading,
    onChangeButton,
    onChangeIcon,
    errors,
  } = discoveryBanner;

  const formButtons = disabled ? (
    <></>
  ) : (
    <>
      <Button onClick={onDiscard} variant='tertiary' disabled={!isDirty}>
        Discard
      </Button>
      <Button onClick={onSubmit} disabled={!(isValid && isDirty)} variant='primary'>
        {selectedVariantId ? 'Apply' : 'Save'}
      </Button>
    </>
  );

  const formButtonsSection = createOptionalPortal({
    portalContent: disabled ? <></> : formButtons,
    targetContainerRef: formHeaderElementRef,
    fallback: <div className='ranking-button-wrapper'>{formButtons}</div>,
  });

  const [showSettingsExample, setSettingsExampleModalIsOpened] = useState(false);

  const onSettingsExampleModalClose = () => {
    setSettingsExampleModalIsOpened(false);
  };

  return (
    <div className='discovery-banner-settings'>
      <Typography
        className='discovery-banner-settings-explanation'
        variant={TypographyVariant.MediumRegular}
        type={TypographyType.Paragraph}
      >
        Add Discovery Banners to make it easier for your shoppers to discover similar items.
        <br />
        (Our discovery banner uses Syte&apos;s visual AI technology)
      </Typography>

      <button
        type='button'
        className='discovery-banner-example-modal'
        onClick={() => setSettingsExampleModalIsOpened(true)}
      >
        <Typography
          className='discovery-banner-see-example-link'
          variant={TypographyVariant.MediumRegular}
          type={TypographyType.Button}
        >
          See example
        </Typography>
        <Icon className='discovery-banner-external-link-icon' name={AvailableIcons.ExternalLink} />
      </button>

      <InfoModal
        isOpened={showSettingsExample}
        className='discovery-banner-settings-example-modal'
        closeBtnTxt='Close'
        onClickOutside={onSettingsExampleModalClose}
      >
        <img
          className='discovery-banner-example-modal-image'
          src={bannerExample}
          alt='bannerExample'
        />
      </InfoModal>

      <BannerSettings
        discoveryBannerSettings={draftDiscoveryBannerSettings}
        onChange={onChangeGeneral}
        disabled={disabled}
        errors={errors}
      />

      <CameraIcon
        discoveryBannerSettings={draftDiscoveryBannerSettings}
        onChange={onChangeIcon}
        disabled={disabled}
        errors={errors}
      />

      <Heading
        discoveryBannerSettings={draftDiscoveryBannerSettings}
        onChange={onChangeHeading}
        disabled={disabled}
        errors={errors}
      />

      <CtaButton
        discoveryBannerSettings={draftDiscoveryBannerSettings}
        onChange={onChangeButton}
        disabled={disabled}
        errors={errors}
      />

      {formButtonsSection}
    </div>
  );
};
