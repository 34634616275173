import { ShopFeature } from 'src/app-types/enums';
import { UserTypes } from 'src/services';

export const accountListRoutes = {
  accountList: {
    title: 'Account List',
    path: `/account-list`,
  },
};

export const accountRoutes = {
  shopList: {
    title: 'Shops',
    path: `/shop-list`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
  },
  createNewShop: {
    title: 'Create new Shop',
    path: `/shop-list/new`,
    permissions: { [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write },
  },
  team: {
    title: 'Team',
    path: `/team`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
  },
  myProfile: {
    title: 'My profile',
    path: `/my-profile`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
  },
  accountSettings: {
    title: 'Account Settings',
    path: `/account-settings`,
    permissions: { [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write },
  },
};

export const shopSettingsSubRoutes = {
  shopSettingsGeneral: {
    title: 'General',
    path: `/shops/:shopId/settings/general`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
  },
  shopSettingsGeneralCreateApiKey: {
    title: 'Create API Key',
    path: `/shops/:shopId/settings/general/api-key/create`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
  },
  shopSettingsGeneralUpdateApiKey: {
    title: 'Update API Key',
    path: `/shops/:shopId/settings/general/api-key/update/:apiKeyId`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
  },
  dataFields: {
    title: 'Data Fields',
    path: `/shops/:shopId/settings/data-fields`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
  },
  adminSettings: {
    title: 'Admin Settings',
    path: `/shops/:shopId/settings/admin-settings`,
    permissions: { [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write },
  },
  aiTagging: {
    title: 'AI Tagging',
    path: `/shops/:shopId/settings/ai-tagging`,
    feature: ShopFeature.Lexicon,
    permissions: { [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write },
  },
  versionsList: {
    title: 'Manage Versions',
    path: `/shops/:shopId/settings/versions`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
    },
  },
};

export const shopRoutes = {
  shopSettings: {
    path: `/shops/:shopId/settings/`,
    title: 'Shop Settings',
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
  },
  shopperExperiences: {
    title: 'Shopper Experiences',
    path: `/shops/:shopId/shopper-experiences`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
  },
  merchandisingRules: {
    title: 'Merchandising Rules',
    path: `/shops/:shopId/merchandising-rules`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Delete },
  },
  createMerchandisingRule: {
    title: 'Merchandising Rules',
    path: `/shops/:shopId/merchandising-rules/new`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Delete },
    getRootRoute(): RouteSettingsBase {
      return shopRoutes.merchandisingRules;
    },
  },
  editMerchandisingRule: {
    title: 'Merchandising Rules',
    path: `/shops/:shopId/merchandising-rules/:ruleId`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Delete },
    getRootRoute(): RouteSettingsBase {
      return shopRoutes.merchandisingRules;
    },
  },
  ranking: {
    title: 'Ranking and Sorting',
    path: `/shops/:shopId/ranking-strategy`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
  },
  recEngines: {
    title: 'Recommendation Engines',
    path: '/shops/:shopId/recommendation-engines',
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Delete },
  },
  shopTheLook: {
    title: 'Shop The Look',
    path: `/shops/:shopId/recommendation-engines/shop-the-look`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    feature: ShopFeature.ShopTheLook,
  },
  boughtTogether: {
    title: 'Bought Together',
    path: `/shops/:shopId/recommendation-engines/bought-together`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    feature: ShopFeature.FrequentlyBoughtTogether,
  },
  recentlyViewed: {
    title: 'Recently Viewed',
    path: `/shops/:shopId/recommendation-engines/recently-viewed`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    feature: ShopFeature.RecentlyViewed,
  },
  personalization: {
    title: 'Personalization',
    path: `/shops/:shopId/recommendation-engines/personalization`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    feature: ShopFeature.Personalization,
  },
  similarItems: {
    title: 'Similar Items',
    path: `/shops/:shopId/recommendation-engines/similar-items`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    feature: ShopFeature.SimilarItems,
  },
  recEnginesGeneralSettings: {
    title: 'General Settings',
    path: `/shops/:shopId/recommendation-engines/general-settings`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
  },
  resultsModalGeneralSettings: {
    title: 'General Settings',
    path: `/shops/:shopId/results-modal/general-settings`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
  },
  visualSearch: {
    title: 'Visual Search',
    path: '/shops/:shopId/visual-search',
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Delete },
  },
  discoveryBanner: {
    title: 'Discovery Banner',
    path: `/shops/:shopId/visual-search/discovery-banner`,
    feature: ShopFeature.DiscoveryBanner,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
  },
};

export const experimentRoutes = {
  experiments: {
    title: 'A/B tests',
    path: `/shops/:shopId/experiments`,
    feature: ShopFeature.Experiments,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Delete },
  },
  editExperiment: {
    title: 'Edit Experiment',
    path: `/shops/:shopId/experiments/:experimentId`,
    feature: ShopFeature.Experiments,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Delete },
    getRootRoute(): RouteSettingsBase {
      return experimentRoutes.experiments;
    },
  },
};

const augmentedSearchRoutes = {
  augmentedSearch: {
    title: 'Text Search',
    path: '/shops/:shopId/text-search',
    feature: ShopFeature.AugmentedSearch,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Delete },
  },
  augmentedSearchSettings: {
    title: 'Search Settings',
    path: '/shops/:shopId/text-search/search-settings',
    feature: ShopFeature.AugmentedSearch,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Delete },
  },
  augmentedSearchRelevancyTuning: {
    title: 'Search Relevancy Tuning',
    path: '/shops/:shopId/text-search/relevancy-tuning',
    feature: ShopFeature.AugmentedSearch,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
  },
  augmentedSearchAutoSuggest: {
    title: 'Auto Suggest',
    path: '/shops/:shopId/text-search/auto-suggest',
    feature: ShopFeature.AugmentedSearch,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Delete },
  },
  augmentedSearchResultsPage: {
    title: 'Results Page',
    path: '/shops/:shopId/text-search/results-page',
    feature: ShopFeature.AugmentedSearch,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Delete },
  },
  augmentedSearchFiltersSets: {
    title: 'Filters',
    path: '/shops/:shopId/text-search/filters',
    feature: ShopFeature.AugmentedSearch,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Delete },
    shopIdsBlackList: [9423, 8594, 7816, 9374],
  },
  augmentedSearchFilters: {
    title: 'Filters Set',
    path: '/shops/:shopId/text-search/filters/:filterSetId',
    feature: ShopFeature.AugmentedSearch,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Delete },
    shopIdsBlackList: [9423, 8594, 7816, 9374],
    getRootRoute(): RouteSettingsBase {
      return augmentedSearchRoutes.augmentedSearchFiltersSets;
    },
  },
};

export const augmentedSearchFiltersSubRoutes = {
  filtersSettings: {
    title: 'Filters Settings',
    path: `/shops/:shopId/text-search/filters/:filterSetId/general-settings`,
    feature: ShopFeature.AugmentedSearch,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Delete },
  },
  catalogFilters: {
    title: 'Catalogue Filters',
    path: `/shops/:shopId/text-search/filters/:filterSetId/catalog-filters`,
    feature: ShopFeature.AugmentedSearch,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Delete },
  },
  deepTags: {
    title: 'AI Tags Filters',
    path: `/shops/:shopId/text-search/filters/:filterSetId/syte-generated-filters`,
    feature: ShopFeature.AugmentedSearch,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Delete },
  },
};

export const collectionsRoutes = {
  collections: {
    title: 'Collections',
    path: `/shops/:shopId/collections`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    feature: ShopFeature.Collections,
  },
  createCollection: {
    title: 'Collection setup',
    path: `/shops/:shopId/collections/new`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    getRootRoute(): RouteSettingsBase {
      return collectionsRoutes.collections;
    },
    feature: ShopFeature.Collections,
  },
  editCollection: {
    title: 'Edit Collection',
    path: `/shops/:shopId/collections/edit/:collectionId`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    getRootRoute(): RouteSettingsBase {
      return collectionsRoutes.collections;
    },
    feature: ShopFeature.Collections,
  },
  editCollectionRules: {
    title: 'Collection setup',
    path: `/shops/:shopId/collections/edit/:collectionId/collection-rules`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    getRootRoute(): RouteSettingsBase {
      return collectionsRoutes.collections;
    },
    feature: ShopFeature.Collections,
  },
  editCollectionMerchandisingRules: {
    title: 'Merchandising Rules',
    path: `/shops/:shopId/collections/edit/:collectionId/merchandising-rules`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    getRootRoute(): RouteSettingsBase {
      return collectionsRoutes.collections;
    },
    feature: ShopFeature.Collections,
  },
  editCollectionRankingStrategy: {
    title: 'Ranking strategy',
    path: `/shops/:shopId/collections/edit/:collectionId/ranking-strategy`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    getRootRoute(): RouteSettingsBase {
      return collectionsRoutes.collections;
    },
    feature: ShopFeature.Collections,
  },
  editCollectionSettings: {
    title: 'Summary',
    path: `/shops/:shopId/collections/edit/:collectionId/collection-settings`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    getRootRoute(): RouteSettingsBase {
      return collectionsRoutes.collections;
    },
    feature: ShopFeature.Collections,
  },
};

export const discoveryStoriesRoutes = {
  stories: {
    title: 'Stories',
    path: `/shops/:shopId/stories`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    feature: ShopFeature.DiscoveryStories,
  },
};

export const deepTagReportsRoutes = {
  deepTagReports: {
    title: 'Tags Editor',
    path: `/shops/:shopId/tags-enrichment`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    feature: ShopFeature.DeepTagReports,
  },
  deepTagReportProducts: {
    title: 'Tags Enrichment Report',
    path: `/shops/:shopId/tags-enrichment/reports/:reportId/products`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    feature: ShopFeature.DeepTagReports,
    getRootRoute(): RouteSettingsBase {
      return deepTagReportsRoutes.deepTagReports;
    },
  },
  deepTagReportProduct: {
    title: 'Tags Enrichment Report Product',
    path: `/shops/:shopId/tags-enrichment/reports/:reportId/products/:productId`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    feature: ShopFeature.DeepTagReports,
    getRootRoute(): RouteSettingsBase {
      return deepTagReportsRoutes.deepTagReports;
    },
  },
  deepTagReportsConfiguration: {
    title: 'Tags Enrichment Report Configuration',
    path: `/shops/:shopId/tags-enrichment/configuration`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    feature: ShopFeature.DeepTagReports,
  },
  deepTagReportsFileUpload: {
    title: 'Tags Enrichment File Upload',
    path: `/shops/:shopId/tags-enrichment/file-upload`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    feature: ShopFeature.DeepTagReports,
  },
  deepTagReportsSFTPsettings: {
    title: 'Tags Enrichment Report SFTP settings',
    path: `/shops/:shopId/tags-enrichment/sftp-settings`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    feature: ShopFeature.DeepTagReports,
  },
};
export const socialDiscoveryRoutes = {
  socialDiscovery: {
    title: 'Social Discovery',
    path: `/shops/:shopId/social-discovery`,
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
    feature: ShopFeature.SocialDiscovery,
  },
};

export const lexiconRoutes = {
  lexicons: {
    title: 'Lexicon',
    path: `/shops/:shopId/lexicons`,
    feature: ShopFeature.Lexicon,
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Read,
    },
  },
  lexiconLanguages: {
    title: 'Lexicon',
    path: `/shops/:shopId/lexicons/languages`,
    feature: ShopFeature.Lexicon,
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Read,
    },
    getRootRoute(): RouteSettingsBase {
      return lexiconRoutes.lexicons;
    },
  },
  lexiconCategories: {
    title: 'Lexicon Categories Overview',
    path: `/shops/:shopId/lexicons/:locale/:tagType`,
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.Lexicon,
    getRootRoute(): RouteSettingsBase {
      return lexiconRoutes.lexicons;
    },
  },
  createThematicTag: {
    title: 'Create thematic tag',
    path: `/shops/:shopId/lexicons/:locale/thematic-tags/create`,
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.Lexicon,
    getRootRoute(): RouteSettingsBase {
      return lexiconRoutes.lexicons;
    },
  },
  editThematicTag: {
    title: 'Edit thematic tag',
    path: `/shops/:shopId/lexicons/:locale/thematic-tags/:id`,
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.Lexicon,
    getRootRoute(): RouteSettingsBase {
      return lexiconRoutes.lexicons;
    },
  },
  createLexiconRule: {
    title: 'Create lexicon rule',
    path: `/shops/:shopId/lexicons/:locale/lexicon-rules/create`,
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.Lexicon,
    getRootRoute(): RouteSettingsBase {
      return lexiconRoutes.lexicons;
    },
  },
  editLexiconRule: {
    title: 'Edit lexicon rule',
    path: `/shops/:shopId/lexicons/:locale/lexicon-rules/:id`,
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.Lexicon,
    getRootRoute(): RouteSettingsBase {
      return lexiconRoutes.lexicons;
    },
  },
  importLexicon: {
    title: 'Import lexicon',
    path: `/shops/:shopId/lexicons/:locale/:tagType/import`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.Lexicon,
    getRootRoute(): RouteSettingsBase {
      return lexiconRoutes.lexicons;
    },
  },
  exportLexicon: {
    title: 'Export lexicon',
    path: `/shops/:shopId/lexicons/:locale/:tagType/export`,
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.Lexicon,
    getRootRoute(): RouteSettingsBase {
      return lexiconRoutes.lexicons;
    },
  },
};

export const uiTemplateRoutes = {
  uiTemplates: {
    title: 'UI Templates',
    path: `/shops/:shopId/ui-templates`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.UiTemplates,
  },
};

export const useKnnRoutes = {
  useKnn: {
    title: 'Use OpenSearch KNN',
    path: `/shops/:shopId/useKnn`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
    },
  },
};

export const catalogManagerRoutes = {
  catalogManager: {
    title: 'Catalogue Manager',
    path: `/shops/:shopId/catalog-manager`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Read,
    },
    feature: ShopFeature.CatalogManager,
  },
  catalogManagerList: {
    title: 'Catalogue Manager',
    path: `/shops/:shopId/catalog-manager/list`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Read,
    },
    feature: ShopFeature.CatalogManager,
    getRootRoute(): RouteSettingsBase {
      return catalogManagerRoutes.catalogManager;
    },
  },
  connectExistingCatalog: {
    title: 'Connect existing Catalogue',
    path: `/shops/:shopId/catalog-manager/list/connect`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.CatalogManager,
    getRootRoute(): RouteSettingsBase {
      return catalogManagerRoutes.catalogManagerList;
    },
  },
  editExistingCatalog: {
    title: 'Edit existing Catalogue',
    path: `/shops/:shopId/catalog-manager/list/edit-existing/:id`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.CatalogManager,
    getRootRoute(): RouteSettingsBase {
      return catalogManagerRoutes.catalogManagerList;
    },
  },
  catalogManagerCreateCatalog: {
    title: 'Catalogue Manager',
    path: `/shops/:shopId/catalog-manager/new`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.CatalogManager,
    getRootRoute(): RouteSettingsBase {
      return catalogManagerRoutes.catalogManager;
    },
  },
  catalogManagerUpdateCatalog: {
    title: 'Catalogue Manager',
    path: `/shops/:shopId/catalog-manager/:catalogName`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.CatalogManager,
    getRootRoute(): RouteSettingsBase {
      return catalogManagerRoutes.catalogManager;
    },
  },
  catalogManagerFeedUploadLog: {
    title: 'Catalogue Feed Upload Log',
    path: `/shops/:shopId/catalog-manager/:catalogName/upload-logs`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Read,
    },
    feature: ShopFeature.CatalogManager,
    getRootRoute(): RouteSettingsBase {
      return catalogManagerRoutes.catalogManager;
    },
  },
  catalogExplorer: {
    title: 'Catalogue Explorer',
    path: `/shops/:shopId/catalog-manager/:catalogName/catalog-explorer`,
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Read,
    },
    feature: ShopFeature.CatalogManager,
    getRootRoute(): RouteSettingsBase {
      return catalogManagerRoutes.catalogManager;
    },
  },
  catalogManagerUploadErrorReportList: {
    title: 'Upload error reports list',
    path: `/shops/:shopId/catalog-manager/:catalogName/upload-logs/:jobId/errorReport`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Read,
    },
    feature: ShopFeature.CatalogManager,
    getRootRoute(): RouteSettingsBase {
      return catalogManagerRoutes.catalogManager;
    },
  },
};

export const filtersRoutes = {
  filters: {
    title: 'Filters',
    path: `/shops/:shopId/layout/:syteLayout/filters`,
    feature: ShopFeature.Filters,
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Read,
    },
  },
  filtersList: {
    title: 'Filters list',
    path: `/shops/:shopId/layout/:syteLayout/filters/list`,
    feature: ShopFeature.Filters,
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Read,
    },
    getRootRoute(): RouteSettingsBase {
      return filtersRoutes.filters;
    },
  },
  createNewFilter: {
    title: 'Create new filters',
    path: `/shops/:shopId/layout/:syteLayout/filters/new`,
    feature: ShopFeature.Filters,
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Read,
    },
    getRootRoute(): RouteSettingsBase {
      return filtersRoutes.filters;
    },
  },
  editFilter: {
    title: 'Edit filter',
    path: `/shops/:shopId/layout/:syteLayout/filters/:filterId`,
    feature: ShopFeature.Filters,
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Read,
    },
    getRootRoute(): RouteSettingsBase {
      return filtersRoutes.filters;
    },
  },
};

export const galleriesRoutes = {
  galleries: {
    title: 'Galleries',
    path: `/shops/:shopId/galleries`,
    feature: ShopFeature.Galleries,
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Read,
    },
  },
  galleriesList: {
    title: 'Galleries Table',
    path: `/shops/:shopId/galleries/list`,
    feature: ShopFeature.Galleries,
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Read,
    },
    getRootRoute(): RouteSettingsBase {
      return galleriesRoutes.galleries;
    },
  },
  createGallery: {
    title: 'New Gallery',
    path: `/shops/:shopId/galleries/list/new`,
    feature: ShopFeature.Galleries,
    getRootRoute(): RouteSettingsBase {
      return galleriesRoutes.galleries;
    },
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
  },
  editInspirationalGallery: {
    title: 'Edit Gallery',
    path: `/shops/:shopId/galleries/inspirational_images/:galleryId/edit`,
    feature: ShopFeature.Galleries,
    getRootRoute(): RouteSettingsBase {
      return galleriesRoutes.galleries;
    },
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
  },
  viewInspirationalGallery: {
    title: 'View Gallery',
    path: `/shops/:shopId/galleries/inspirational_images/:galleryId`,
    feature: ShopFeature.Galleries,
    getRootRoute(): RouteSettingsBase {
      return galleriesRoutes.galleries;
    },
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Read,
    },
  },
  editCustomInspirationsGallery: {
    title: 'Edit Gallery',
    path: `/shops/:shopId/galleries/custom_inspirations/:galleryId/edit`,
    feature: ShopFeature.Galleries,
    getRootRoute(): RouteSettingsBase {
      return galleriesRoutes.galleries;
    },
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
  },
  addImagesToCustomInspirationsGallery: {
    title: 'Edit Gallery',
    path: `/shops/:shopId/galleries/custom_inspirations/:galleryId/edit/add-images`,
    feature: ShopFeature.Galleries,
    getRootRoute(): RouteSettingsBase {
      return galleriesRoutes.galleries;
    },
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
  },
  tagImageInCustomInspirationsGallery: {
    title: 'Edit Gallery',
    path: `/shops/:shopId/galleries/custom_inspirations/:galleryId/edit/tag-image/:imageId`,
    feature: ShopFeature.Galleries,
    getRootRoute(): RouteSettingsBase {
      return galleriesRoutes.galleries;
    },
    permissions: {
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
  },
};

export const visualEditorRoutes = {
  visualEditor: {
    title: 'Visual Editor',
    path: `/shops/:shopId/visual-editor`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.Preview,
  },
  visualEditorEdit: {
    title: 'Visual Editor Edit',
    path: `/shops/:shopId/visual-editor/edit/merchandising-rules/:ruleId`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.Preview,
  },
  visualEditorEditMerchandisingRule: {
    title: 'Visual Editor Edit Merchandising Rule',
    path: `/shops/:shopId/visual-editor/merchandising-rules/:ruleId`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.Preview,
    getRootRoute(): RouteSettingsBase {
      return visualEditorRoutes.visualEditor;
    },
  },
  visualEditorCreateMerchandisingRule: {
    title: 'Visual Editor Create Merchandising Rule',
    path: `/shops/:shopId/visual-editor/merchandising-rules/new`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.Preview,
    getRootRoute(): RouteSettingsBase {
      return visualEditorRoutes.visualEditor;
    },
  },
  visualEditorEditRankingStrategy: {
    title: 'Visual Editor Edit Ranking Strategy',
    path: `/shops/:shopId/visual-editor/ranking-strategy`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.Preview,
    getRootRoute(): RouteSettingsBase {
      return visualEditorRoutes.visualEditor;
    },
  },
  visualEditorEditRelevancyTuning: {
    title: 'Visual Editor Edit Relevancy Tuning',
    path: `/shops/:shopId/visual-editor/relevancy-tuning`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.Preview,
    getRootRoute(): RouteSettingsBase {
      return visualEditorRoutes.visualEditor;
    },
  },
  visualEditorEditCategoryPage: {
    title: 'Visual Editor Edit Category Page',
    path: `/shops/:shopId/visual-editor/category-page`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.Preview,
    getRootRoute(): RouteSettingsBase {
      return visualEditorRoutes.visualEditor;
    },
  },
  visualEditorEditCollectionRules: {
    title: 'Visual Editor Edit Collection Rules',
    path: `/shops/:shopId/visual-editor/collection-rules/:collectionId`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.Preview,
    getRootRoute(): RouteSettingsBase {
      return visualEditorRoutes.visualEditor;
    },
  },
  visualEditorCreateCollectionRules: {
    title: 'Visual Editor Create Collection Rules',
    path: `/shops/:shopId/visual-editor/collection-rules/new`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.Preview,
    getRootRoute(): RouteSettingsBase {
      return visualEditorRoutes.visualEditor;
    },
  },
  visualEditorEditStrategy: {
    title: 'Visual Editor Edit Strategy',
    path: `/shops/:shopId/visual-editor/edit/merchandising-rules/:ruleId/edit-strategy`,
    permissions: {
      [UserTypes.UserRoles.SyteAdmin]: UserTypes.PermissionAction.Write,
      [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write,
    },
    feature: ShopFeature.Preview,
    getRootRoute(): RouteSettingsBase {
      return visualEditorRoutes.visualEditor;
    },
  },
};

export const baseAppRouteMap: RouteMap = {
  login: {
    title: 'Login',
    path: `/login`,
  },
  verifyOneTimeLink: {
    title: 'Verify One Time Link',
    path: `/login/verify/link`,
  },
  resetPassword: {
    title: 'Reset Password',
    path: `/reset-password`,
  },
  createInitialPassword: {
    title: 'Create Password',
    path: `/create-password`,
  },
  shopRoutes: {
    path: `/shops/:shopId`,
    title: 'Shop routes',
    permissions: { [UserTypes.UserRoles.ClientAdmin]: UserTypes.PermissionAction.Write },
  },
  ...accountListRoutes,
  ...accountRoutes,
  ...shopRoutes,
  ...shopSettingsSubRoutes,
  ...experimentRoutes,
  ...augmentedSearchRoutes,
  ...augmentedSearchFiltersSubRoutes,
  ...deepTagReportsRoutes,
  ...collectionsRoutes,
  ...lexiconRoutes,
  ...socialDiscoveryRoutes,
  ...uiTemplateRoutes,
  ...catalogManagerRoutes,
  ...discoveryStoriesRoutes,
  ...filtersRoutes,
  ...galleriesRoutes,
  ...visualEditorRoutes,
};

export const baseAppRoutes: RouteSettings[] = Object.keys(baseAppRouteMap).reduce(
  (accumulator, current) => {
    accumulator.push({
      ...(baseAppRouteMap as RouteMap)[current],
      routeKey: current,
    });
    return accumulator;
  },
  [] as RouteSettings[]
);

/*
  Ordered by length so more specific routes will match first.

  Example: [
    /shops/:shopId/merchandising-rules/:ruleId ,
    /shops/:shopId/merchandising-rules
    /shops/:shopId
  ]

  Use baseAppRoutesPaths.find() to get most specific route first.
*/
export const baseAppRoutesPaths = baseAppRoutes
  .map(route => route.path)
  .sort()
  .reverse();

export const baseAppRoutesByPath: RouteMap = baseAppRoutes.reduce((accumulator, current) => {
  accumulator[current.path] = current;
  return accumulator;
}, {} as RouteMap);

export interface RouteSettingsBase {
  title: string;
  path: string;
  feature?: ShopFeature;
  permissions?: Partial<Record<UserTypes.UserRoles, UserTypes.PermissionAction>>;
  shopIdsBlackList?: number[];
  /**
   Used as fallback when navigating to a route without sub entity params,
   @description  for example when we want to switch a param in current URL, but only have shopId (1234), the current url is /shops/5555/experiments/abcd. The expected new url is /shops/1234/experiments, because shop 1234 doesn't have experiment "abcd" */
  getRootRoute?(): RouteSettingsBase;
}

export interface RouteSettings extends RouteSettingsBase {
  routeKey: string;
}

export type RouteKey = string;
export type RouteMap = Record<RouteKey, RouteSettingsBase>;
export type PermittedRouteMap = Partial<Record<RouteKey, RouteSettings>>;

export interface RoutedComponentProps {
  permittedRouteMap: PermittedRouteMap;
}
