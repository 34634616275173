import React, { useMemo } from 'react';
import { TableV2, TableV2InstanceProps } from '../../../../components-dummy';
import { TableHeaderColumns } from '../TableHeaderColumns';
import { TableRow } from './TableRow';
import { adminSearchModalActions } from '../../AdminSearchModal.actions';
import { tableColumns } from './tableColumns';
import { AdminSearchShopResult } from '../../../../services/src/service/types/admin-search/admin-search-results';
import { Dispatch } from '../../../types';

interface ShopsTableProps {
  shops: AdminSearchShopResult[];
  dispatch: Dispatch;
  onSelect: VoidFunction;
}

export const ShopsTable = ({ shops, dispatch, onSelect }: ShopsTableProps): JSX.Element => {
  const tableData: AdminSearchShopResult[] = useMemo(() => {
    return shops.map(shop => {
      const { shopId, shopName, accountId, accountName, createdAt } = shop;

      return {
        shopId,
        shopName,
        accountId,
        accountName,
        createdAt,
      };
    });
  }, [shops]);

  const columns = useMemo(() => Object.values(tableColumns), [tableColumns]);

  const renderBody = ({
    rows,
    prepareRow,
  }: Pick<TableV2InstanceProps<AdminSearchShopResult>, 'rows' | 'prepareRow'>): JSX.Element[] => {
    return rows.map(row => {
      prepareRow(row);
      const onClick = () => {
        dispatch(
          adminSearchModalActions.navigateToShop({
            shopId: row.original.shopId,
          })
        );
        onSelect();
      };
      return <TableRow key={row.original.shopId} row={row} onClick={onClick} />;
    });
  };

  return (
    <TableV2<AdminSearchShopResult> options={{ columns, data: tableData }}>
      {({ getTableBodyProps, headerGroups, rows, prepareRow }) => {
        const headerGroup = headerGroups[0];

        return (
          <>
            <TableV2.Head {...headerGroup.getHeaderGroupProps()}>
              <TableHeaderColumns headers={headerGroup.headers} columns={columns} />
            </TableV2.Head>
            <TableV2.Body {...getTableBodyProps()}>{renderBody({ rows, prepareRow })}</TableV2.Body>
          </>
        );
      }}
    </TableV2>
  );
};
