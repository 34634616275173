import React from 'react';
import { Tooltip, TypographyType, TypographyVariant } from '../../../../components-dummy';
import { ProductSection } from './ProductSection';
import { ValueToDisplayByDataType } from './ValueToDisplayByDataType';
import { ProductDataStyled, TitleStyled } from './ProductDetails.styled';

const accordionId = 'original-data';

interface Props {
  originalData?: Record<string, any>;
}

export const OriginalDataSection = ({ originalData }: Props) => {
  return (
    <ProductSection title='Original Data' accordionId={accordionId}>
      {Object.entries(originalData).map(([key, value]) => {
        const originalDataDisplayName = `original_data.${key}`;
        return (
          <ProductDataStyled key={key}>
            <TitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
              <Tooltip value={originalDataDisplayName}>{key}</Tooltip>
            </TitleStyled>
            <ValueToDisplayByDataType value={value} />
          </ProductDataStyled>
        );
      })}
    </ProductSection>
  );
};
