import { BoughtTogetherSettingsTypes } from 'src/services';
import { DomainForRendering } from '../../types';
import { boughtTogetherDiffSchema } from './bought-together-diff-schema';

export const boughtTogetherSubDomainGroup: DomainForRendering<BoughtTogetherSettingsTypes.BoughtTogetherSettings> =
  {
    getCardTitle: () => {
      return 'General Settings';
    },
    fieldsSchema: boughtTogetherDiffSchema,
  };
