import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Shops, Accounts, AccountTypes, ShopTypes } from 'src/services';

const shopsService = new Shops();
const accountsService = new Accounts();

export const shopRoutesActions = {
  clearCurrentShop: createAction('ShopRouteActions/ClearCurrentShop'),
  loadShop: createAsyncThunk(
    'ShopRoutesActions/LoadShop',
    async (
      {
        targetShopId,
        currentAccount,
      }: { targetShopId: number; currentAccount: AccountTypes.Account },
      { rejectWithValue, dispatch }
    ) => {
      try {
        // if target shop already in reducer, just set is as current
        const targetShopInReducer = currentAccount.shops.find(
          accountShop => accountShop.shopId === targetShopId
        );

        if (targetShopInReducer) {
          const featureToggles = await shopsService.getFeatureToggles(targetShopId);

          dispatch(shopRoutesActions.selectShop({ shop: targetShopInReducer, featureToggles }));
          return { targetShop: targetShopInReducer, targetAccount: currentAccount };
        }

        // if shop not in reducer, fetch it from API (not latest shop list or different account
        const targetShop = await shopsService.getShop(targetShopId);
        const featureToggles = await shopsService.getFeatureToggles(targetShopId);

        // fetch target shop account in case it's different
        // or rare case when target shop is not in reducer, and need to update shop list in reducer
        const targetAccount = await accountsService.getAccount(targetShop.accountId);

        dispatch(
          shopRoutesActions.selectShopAndAccount({
            shop: targetShop,
            account: targetAccount,
            featureToggles,
          })
        );

        return { targetShop, targetAccount };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),

  selectShop: createAction<{ shop: ShopTypes.Shop; featureToggles: ShopTypes.ShopFeatureToggles }>(
    'ShopRoutesActions/SelectShop'
  ),
  selectShopAndAccount: createAction<{
    shop: ShopTypes.Shop;
    featureToggles: ShopTypes.ShopFeatureToggles;
    account: AccountTypes.Account;
  }>('ShopRoutesActions/selectShopAndAccount'),
  selectAccount: createAction<{ account: AccountTypes.Account }>('ShopRoutesActions/SelectAccount'),
  setAppLoading: createAction<{ appLoading: boolean }>('ShopRoutesActions/SetAppLoading'),
  navigateTo: createAction<{ navigateTo: string }>('ShopRoutesActions/navigateTo'),
  setIsShopLoading: createAction<{ isShopLoading: boolean }>('ShopRoutesActions/setIsShopLoading'),
};
