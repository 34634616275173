import styled from '@emotion/styled';
import { Page } from 'src/components-dummy';

export const DeepTagReportProductsContainerStyled = styled(Page)`
  .syte-page-content {
    overflow: unset;
  }
`;

export const DeepTagReportContentStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
