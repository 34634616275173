import { Select } from 'src/components-dummy';
import styled from '@emotion/styled';

export const PaginationRowStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 40px;
`;

export const SelectStyled = styled(Select)`
  .MuiSelect-select {
    padding-right: 32px !important;
  }
`;

export const LimitDropDownStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span:first-of-type {
    margin-right: 5px;
  }
`;

export const GroupStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 48px;
`;
