import React, { useMemo } from 'react';
import {
  MenuItem,
  Select,
  SelectType,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { SUPPORTED_SYTE_PRODUCTS } from '../../../../merchandisingRules.config';
import { MerchandiseRule, ShopFeatureToggles } from 'src/services/src/service/types/shops';
import { SyteProductType } from 'src/services/src/service/types/constants/syte-product-types';
import { RuleFormField } from '../RuleFormField/RuleFormField';
import { RuleFormSection } from '../RuleFormSection/RuleFormSection';
import { getProductName } from '../../../../merchandisingRules.helpers';
import { getSupportedSyteProducts } from '../../../../../helpers/get-supported-syte-products';
import { BottomSubTitleStyled, SubTitleStyled } from './RuleFormSyteProductSection.styles';

export type RuleFormSyteProductSectionFields = Partial<Pick<MerchandiseRule, 'product'>>;

export interface RuleFormSyteProductSectionProps extends RuleFormSyteProductSectionFields {
  onFieldChange: (partialData: Partial<RuleFormSyteProductSectionFields>) => void;
  isReadOnly?: boolean;
  errors: any;
  entityId?: string;
  featureToggles: ShopFeatureToggles;
}

export const RuleFormSyteProductSection = React.memo(
  ({
    product,
    onFieldChange,
    isReadOnly,
    errors,
    entityId,
    featureToggles,
  }: RuleFormSyteProductSectionProps): JSX.Element => {
    const supportedSyteProducts = getSupportedSyteProducts(featureToggles, SUPPORTED_SYTE_PRODUCTS);

    const syteProductOptions = useMemo(
      () =>
        supportedSyteProducts.map(productKey => {
          return (
            <MenuItem key={productKey} value={productKey}>
              {getProductName(productKey as SyteProductType, entityId)}
            </MenuItem>
          );
        }),
      []
    );

    const productName = getProductName(product as SyteProductType, entityId);

    return (
      <RuleFormSection>
        <RuleFormSection.Header>
          Apply rule for Syte product
          <SubTitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
            Select the product you want to apply the rule for
          </SubTitleStyled>
        </RuleFormSection.Header>
        <RuleFormField>
          <Select
            type={SelectType.Primary}
            value={productName}
            disabled={isReadOnly}
            onChange={ev => {
              onFieldChange({ product: ev.target.value as SyteProductType });
            }}
            isError={!!errors.product}
            errorMessage={errors?.product?.message}
            autoFocus={false}
          >
            {syteProductOptions}
          </Select>
        </RuleFormField>
        {!isReadOnly && (
          <BottomSubTitleStyled type={TypographyType.Body} variant={TypographyVariant.SmallMedium}>
            When switching the product field, the condition section will be removed due to the
            different requirements for each product.
          </BottomSubTitleStyled>
        )}
      </RuleFormSection>
    );
  }
);
