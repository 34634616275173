import { DiscoveryBannerSettingsTypes } from '../types';

export const buildDiscoveryBannerSettingsPayload = (
  payload: Partial<DiscoveryBannerSettingsTypes.DiscoveryBannerSettings>
): Partial<DiscoveryBannerSettingsTypes.DiscoveryBannerSettings> => {
  const payloadData = {
    general: payload.general,
    banner: payload.banner,
    button: payload.button,
    icon: payload.icon,
  };

  return payloadData;
};
