import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { SearchInput } from '../SearchInput';
import { EllipsisWithTooltip } from '../EllipsisWithToolTip';
import { Checkbox } from '../CheckBox';

export const EllipsisWithTooltipStyled = styled(EllipsisWithTooltip)`
  max-width: 80%;
`;

export const MultiSelectMenuListStyled = styled.ul<{ enableScroll: boolean }>`
  width: 100%;
  padding: 0;

  ${({ enableScroll }) =>
    enableScroll &&
    css`
      height: 255px;
      overflow-y: hidden;
      overflow-x: hidden;

      &:hover {
        overflow-y: scroll;
      }
    `}

  list-style-type: none;
  margin: 0;

  li {
    margin-top: 2px;
    padding: 9px !important;
    padding-top: 10px !important;
    border-radius: 4px;
  }

  li:hover {
    background: ${({ theme }) => theme.palette.custom['gray-90']} !important;
  }

  .Mui-selected,
  .Mui-selected:hover {
    background-color: unset;
  }
`;

export const MultiSelectMenuWithSearchStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SearchInputStyled = styled(SearchInput)`
  padding-bottom: 5px;
`;

export const CheckboxStyled = styled(Checkbox)<{ checked: boolean }>`
  margin-top: -3px;
  margin-right: 8px;
`;
