import styled from '@emotion/styled';
import { Button, SectionBox, Select } from 'src/components-dummy';

export const ShopAIModelSettingsContainerStyled = styled.div`
  max-width: 752px;
  padding-top: 32px;
`;

export const ModelContainerStyled = styled.div`
  margin-top: 20px;
  width: 240px;
  height: auto;
`;

export const UpdateButtonStyled = styled(Button)`
  margin-top: 20px;
  width: 240px;
`;

export const SectionBoxStyled = styled(SectionBox)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
`;

export const SelectLexiconLanguagesStyled = styled(Select)`
  width: 259px;
`;
