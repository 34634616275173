import React, { useEffect, useMemo } from 'react';
import { ShopFeature } from 'src/app-types';
import { AllowlistForm } from './AllowlistForm';
import { FeaturePermissionsForm } from './FeaturePermissionsForm';
import { AdminSettingsFormsContainer } from './AdminSettingsForms.styles';
import { shopFeatureTogglesActionMethods } from './Actions';
import { AdminSettingsFormsProps } from './types';

export const AdminSettingsForms = ({
  dispatch,
  featureToggles,
  runningExperimentId,
  shopId,
}: AdminSettingsFormsProps): JSX.Element | null => {
  useEffect(() => {
    if (shopId) {
      dispatch(
        shopFeatureTogglesActionMethods.getFeatureToggles({
          shopId,
        })
      );
    }
  }, [shopId, dispatch]);

  const featureTogglesForPermissionsFrom = useMemo(
    () => ({
      ...featureToggles,
      [ShopFeature.apiDomainsWhitelist]: undefined,
      [ShopFeature.imageDownloadWhitelist]: undefined,
    }),
    [featureToggles]
  );

  const featureTogglesForAllowList = useMemo(
    () => ({
      [ShopFeature.apiDomainsWhitelist]: featureToggles?.apiDomainsWhitelist,
      [ShopFeature.imageDownloadWhitelist]: featureToggles?.imageDownloadWhitelist,
    }),
    [featureToggles]
  );

  return (
    <AdminSettingsFormsContainer>
      <FeaturePermissionsForm
        dispatch={dispatch}
        featureToggles={featureTogglesForPermissionsFrom}
        runningExperimentId={runningExperimentId}
        shopId={shopId}
      />
      <AllowlistForm
        dispatch={dispatch}
        featureToggles={featureTogglesForAllowList}
        shopId={shopId}
      />
    </AdminSettingsFormsContainer>
  );
};
