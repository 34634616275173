import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AutoComplete,
  AutoCompleteOption,
  AutocompleteRenderGetTagProps,
  RenderTagsType,
} from 'src/components-dummy';
import { DeepTagReportsGeneralConfigurationLabel } from 'src/services/src/service/types/deep-tag-reports';
import { Dispatch } from 'src/components-bl';
import Joi, { ValidationError } from 'joi';
import { ItemAutoCompleteWrapper } from '../DeepTagReportProductsCustomLabelBulk/DeepTagReportProductsCustomLabelBulk.style';
import { deepTagsReportProductsBulkLabelActions } from '../../DeepTagsReportProductsBulkLabelModal.actions';

type AutoCompleteRenderTags = (
  value: AutoCompleteOption[],
  getTagProps: AutocompleteRenderGetTagProps
) => void;

/**
 * Validator
 */
const textValidator = () =>
  Joi.string().label('labelName').required().trim().min(1).max(10).messages({
    'string.empty': 'Label name can not be empty',
    'string.min': 'Label name should be at least 1 characters long',
    'string.max': 'Label name should be maximum 10 characters long',
  });

/**
 * Main
 */
export interface DeepTagReportsLabelAutoCompleteProps {
  freeSolo: boolean;
  multiple: boolean;
  selectedValues: string[];
  maxSelection?: number;
  disabled: boolean;
  placeholder?: string;
  onErrors?: (errors: string[]) => void;
  onAutoCompleteBlur?: () => void;
  isError: boolean;
  setSelectedValues:
    | React.Dispatch<React.SetStateAction<string[]>>
    | ((selected: string[]) => void);
  dispatch: Dispatch;
  renderTags?: AutoCompleteRenderTags;
  limitTags?: number;
  onKeyDown?: React.KeyboardEventHandler;
  autoFocus?: boolean;
}

export const DeepTagReportsLabelAutoComplete = React.memo(
  ({
    freeSolo,
    multiple,
    maxSelection,
    selectedValues,
    disabled,
    placeholder = 'Type to select or insert a label',
    setSelectedValues,
    onAutoCompleteBlur,
    renderTags,
    isError,
    onErrors,
    dispatch,
    limitTags,
    onKeyDown,
    autoFocus,
  }: DeepTagReportsLabelAutoCompleteProps): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [textBoxValue, setTextBoxValue] = useState<string | undefined>(undefined);
    const thunkCreateTagPromiseRef = useRef(null as { abort: () => void } | null);
    const [generalConfigurationLabels, setGeneralConfigurationLabels] = useState<
      DeepTagReportsGeneralConfigurationLabel[]
    >([]);

    const textErrorValidation: ValidationError['details'] = useMemo(() => {
      // Ignore empty text
      if (!textBoxValue) {
        return [];
      }

      const { error } = textValidator().validate(textBoxValue, { abortEarly: false });
      const errorDetails = error?.details;

      if (errorDetails) {
        return errorDetails;
      }

      return [];
    }, [textBoxValue]);

    const fetchLabelOptions = useCallback(() => {
      const thunkPromise: any = dispatch(
        deepTagsReportProductsBulkLabelActions.getLabelsGeneralConfiguration()
      );

      thunkPromise
        .unwrap()
        .then((data: { generalConfigurationLabels: DeepTagReportsGeneralConfigurationLabel[] }) => {
          setGeneralConfigurationLabels(data.generalConfigurationLabels);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, []);

    const autoCompleteOptions: AutoCompleteOption[] = useMemo(() => {
      return generalConfigurationLabels.map(labelObject => {
        return {
          title: labelObject.name,
          value: labelObject.id,
        };
      });
    }, [generalConfigurationLabels]);

    const onChangeAutoComplete = useCallback(
      (values: string[]): void => {
        if (textErrorValidation.length) {
          return;
        }

        if (maxSelection && values.length > maxSelection) {
          return;
        }

        const valuesSet = new Set(values);
        const uniqueValues = Array.from(valuesSet);

        const lastValue = values[values.length - 1];

        const isValueExistsInGeneralLabelIds = () => {
          return !!generalConfigurationLabels.find(generalLabels => generalLabels.id === lastValue);
        };

        const isEmpty = !uniqueValues.length;

        // Clean errors
        onErrors?.([]);

        if (isEmpty || isValueExistsInGeneralLabelIds()) {
          setSelectedValues(uniqueValues);
          return;
        }

        // Empty array
        if (!uniqueValues.length) {
          return;
        }

        setIsLoading(true);

        /**
         * Create a new label
         */
        const thunkPromise: any = dispatch(
          deepTagsReportProductsBulkLabelActions.createLabelsGeneralConfiguration({
            labelName: lastValue,
          })
        );

        thunkCreateTagPromiseRef.current = thunkPromise;

        thunkPromise
          .unwrap()
          .then((data: { createdLabel: DeepTagReportsGeneralConfigurationLabel }) => {
            const valuesWithoutLast = uniqueValues.slice(0, uniqueValues.length - 1);
            setSelectedValues([...valuesWithoutLast, data.createdLabel.id]);

            // Refresh labels list
            fetchLabelOptions();
          })
          .catch((apiError: Error) => {
            console.error('apiError:', apiError);
          })
          .finally(() => {
            setIsLoading(false);
          });
      },
      [
        generalConfigurationLabels,
        textErrorValidation.length,
        onErrors,
        maxSelection,
        fetchLabelOptions,
        setSelectedValues,
        dispatch,
      ]
    );

    const onTextBoxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value;
      setTextBoxValue(text);
    }, []);

    const onOpen = useCallback(() => {}, []);

    useEffect(() => {
      fetchLabelOptions();
      return () => {
        thunkCreateTagPromiseRef.current?.abort();
      };
    }, [fetchLabelOptions]);

    useEffect(() => {
      if (textErrorValidation) {
        onErrors?.(textErrorValidation.map(error => error.message));
      } else {
        onErrors?.([]);
      }
    }, [textErrorValidation]);

    return (
      <ItemAutoCompleteWrapper
        isErrored={!!textErrorValidation.length || isError}
        onClick={(event?: any) => event?.stopPropagation()}
      >
        <AutoComplete
          freeSolo={freeSolo}
          multiple={multiple}
          loading={isLoading}
          placeholder={placeholder}
          options={autoCompleteOptions}
          textBoxValue={textBoxValue || ''}
          onTextBoxChange={onTextBoxChange}
          onChange={onChangeAutoComplete}
          selectedValues={!autoCompleteOptions.length ? [] : selectedValues}
          disabled={disabled}
          renderTags={renderTags as unknown as RenderTagsType}
          onOpen={onOpen}
          onBlur={onAutoCompleteBlur}
          openPopUp
          onKeyDown={onKeyDown}
          limitTags={limitTags}
          autoFocus={autoFocus}
        />
      </ItemAutoCompleteWrapper>
    );
  }
);
