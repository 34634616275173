import { GenericExtendsFallback } from '../../base';

export function collectListValues<T extends GenericExtendsFallback>(
  list: T[],
  field: keyof T,
  excludeItem: T,
  itemKeyAttribute: keyof T,
  isCaseSensitive: boolean
): string[] {
  const valuesToCompare: string[] = [];
  return list.reduce((acc, item) => {
    if (!item[field] || item[itemKeyAttribute] === excludeItem[itemKeyAttribute]) {
      return acc;
    }
    const addedItemList = isCaseSensitive ? String(item[field]) : String(item[field]).toLowerCase();
    return [...acc, addedItemList];
  }, valuesToCompare);
}
