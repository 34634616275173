import styled from '@emotion/styled';
import { NotificationSeverity } from 'src/services';
import { CardActionsMenu, DateSection } from './components';

export const NotificationCardStyled = styled.div<{ isViewed: boolean }>`
  padding: 10px;
  margin: 14px 0;
  border-radius: 4px;
  gap: 8px;
  ${({ isViewed }) => !isViewed && 'background-color: #E5F1FFB2;'}
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'avatar message severity'
    '. date action';

  &:hover {
    background-color: ${({ theme }) => theme.palette.custom['gray-80']};
    cursor: pointer;
  }
`;

export const SeverityIndicationWrapperStyled = styled.div`
  grid-area: severity;
  width: 8px;
  height: 8px;
  justify-self: center;

  .syte-popup-trigger {
    margin-top: 6px;
    width: 8px;
    height: 8px;
  }
`;

export const SeverityIndicationStyled = styled.div<{ severity: NotificationSeverity }>`
  width: 100%;
  height: 100%;

  border-radius: 50%;
  background-color: ${({ theme, severity }) => {
    if (severity === NotificationSeverity.High) {
      return theme.palette.custom.red;
    }

    if (severity === NotificationSeverity.Medium) {
      return theme.palette.custom.yellow;
    }

    return theme.palette.custom['primary-main'];
  }};
`;

export const CardActionsMenuStyled = styled(CardActionsMenu)`
  grid-area: action;
  justify-self: center;

  & > div {
    padding: 4px;
  }
`;

export const DateSectionStyled = styled(DateSection)`
  grid-area: date;
  align-self: end;
`;
