import { push } from 'connected-react-router';
import { Accounts } from 'src/services';
import { Dispatch } from '../../../../types';
import {
  SelectAccountAction,
  SelectAccountActionPayload,
  AccountListActionTypes,
  GetAccountRequestAction,
  GetAccountRequestActionPayload,
  GetAccountErrorAction,
  GetAccountErrorActionPayload,
  GetAccountSuccessAction,
  GetAccountSuccessActionPayload,
  SelectAccountArguments,
} from './types';

const accountService = new Accounts();

/* --= Actions =-- */
export const accountListActions = {
  selectAccount: (payload: SelectAccountActionPayload): SelectAccountAction => {
    return {
      type: AccountListActionTypes.SelectAccount,
      payload,
    };
  },
  getAccountRequest: (payload: GetAccountRequestActionPayload): GetAccountRequestAction => {
    return {
      type: AccountListActionTypes.GetAccountRequest,
      payload,
    };
  },
  getAccountError: (payload: GetAccountErrorActionPayload): GetAccountErrorAction => {
    return {
      type: AccountListActionTypes.GetAccountError,
      payload,
    };
  },
  getAccountSuccess: (payload: GetAccountSuccessActionPayload): GetAccountSuccessAction => {
    return {
      type: AccountListActionTypes.GetAccountSuccess,
      payload,
    };
  },
};

/* --= Methods =-- */
export const selectAccount =
  ({ accountId, routeToPush }: SelectAccountArguments) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(accountListActions.getAccountRequest({ accountId }));
    try {
      const account = await accountService.getAccount(accountId);
      dispatch(accountListActions.getAccountSuccess({ account }));
      dispatch(accountListActions.selectAccount({ account }));

      if (routeToPush) {
        dispatch(push(routeToPush));
      }
    } catch (error) {
      dispatch(accountListActions.getAccountError({ error }));
    }
  };
