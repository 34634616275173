import styled from '@emotion/styled';
import { Tooltip, Typography } from 'src/components-dummy';

export const ImageNotFoundCardStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.custom['gray-70']};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  border-radius: 4px;
  position: absolute;
`;

export const TypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.custom['gray-10']};
  margin-top: 5px;
`;

export const TooltipStyled = styled(Tooltip)`
  .syte-popup-content {
    max-width: 170px;
    text-align: center;
  }
`;
