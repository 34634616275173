import styled from '@emotion/styled';
import { ConfirmationDialog } from 'src/components-dummy';

export const ShopCreationFormStyled = styled(ConfirmationDialog)`
  width: 600px;
`;

export const ContentStyled = styled(ConfirmationDialog.Content)`
  gap: 24px;
`;

export const FooterStyled = styled(ConfirmationDialog.Footer)`
  gap: 4px;
`;
