/* --= Action Types =-- */

import { AddToCart } from '../../../app-state-types/reducer-state-types/add-to-cart';
import { FieldType, ShopDataField } from '../../../services/src/service/types';
import { PersonalizationFallbackMethod } from '../../../types/enums/personalization-fallback-method';
import { SortingOrder } from '../../../types/enums/sorting-order';

export enum PersonalizationActionTypes {
  GetPersonalizationSettingsRequest = 'Personalization/GetPersonalizationSettingsRequest',
  GetPersonalizationSettingsSuccess = 'Personalization/GetPersonalizationSettingsSuccessAction',
  GetPersonalizationSettingsError = 'Personalization/GetPersonalizationSettingsError',
  UpdatePersonalizationSettingsRequest = 'Personalization/UpdatePersonalizationSettingsRequest',
  UpdatePersonalizationSettingsSuccess = 'Personalization/UpdatePersonalizationSettingsSuccess',
  UpdatePersonalizationSettingsError = 'Personalization/UpdatePersonalizationSettingsError',
  NotifyPersonalizationSettingsIsDirty = 'Personalization/NotifyIsDirty',
  NavigateTo = 'Personalization/NavigateTo',
  GetDataFieldsRequest = 'Personalization/GetDataFieldsRequest',
  GetDataFieldsSuccess = 'Personalization/GetDataFieldsSuccess',
  GetDataFieldsError = 'Personalization/GetDataFieldsError',
}
export interface UpdatePersonalizationSettingsPayload {
  shopId: number;
  variantId?: string;
  numberOfResultsToDisplay: number;
  personalizationFallbackMethod: PersonalizationFallbackMethod;
  useSessionSkusFromPageViewEvent: boolean;
  addToCart: AddToCart;
  personalizationFallbackField?: string;
  sortingOrder?: SortingOrder;
}

export interface GetPersonalizationSettingsArgs {
  shopId: number;
  variantId?: string;
}

export interface GetPersonalizationDataFieldsArgs {
  shopId: number;
  fieldType: FieldType;
}

export interface GetPersonalizationSettingsRequestPayload {
  shopId: number;
}

export interface GetPersonalizationSettingsSuccessPayload {
  shopId: number;
  numberOfResultsToDisplay: number;
  personalizationFallbackMethod: PersonalizationFallbackMethod;
  useSessionSkusFromPageViewEvent: boolean;
  addToCart: AddToCart;
  personalizationFallbackField?: string;
  sortingOrder?: SortingOrder;
}

export interface GetPersonalizationSettingsErrorPayload {
  error: unknown;
}
export interface UpdatePersonalizationSettingsRequestPayload {
  shopId: number;
  numberOfResultsToDisplay: number;
  personalizationFallbackMethod: PersonalizationFallbackMethod;
  useSessionSkusFromPageViewEvent: boolean;
  addToCart: AddToCart;
  personalizationFallbackField?: string;
  sortingOrder?: SortingOrder;
}

export interface UpdatePersonalizationSettingsSuccessPayload {
  shopId: number;
  numberOfResultsToDisplay: number;
  personalizationFallbackMethod: PersonalizationFallbackMethod;
  useSessionSkusFromPageViewEvent: boolean;
  addToCart: AddToCart;
  personalizationFallbackField?: string;
  sortingOrder?: SortingOrder;
}

export interface UpdatePersonalizationSettingsErrorPayload {
  error: unknown;
}

export interface NotifyPersonalizationSettingsIsDirtyActionPayload {
  isDirty: boolean;
}

export interface GetPersonalizationDataFieldsRequestPayload {
  shopId: number;
}

export interface GetPersonalizationDataFieldsSuccessPayload {
  shopId: number;
  dataFields: ShopDataField[];
}

export interface GetPersonalizationDataFieldsErrorPayload {
  error: unknown;
}

/* --= Action Interfaces =-- */

export interface GetPersonalizationSettingsRequestAction {
  type: typeof PersonalizationActionTypes.GetPersonalizationSettingsRequest;
  payload: GetPersonalizationSettingsRequestPayload;
}

export interface GetPersonalizationSettingsSuccessAction {
  type: typeof PersonalizationActionTypes.GetPersonalizationSettingsSuccess;
  payload: GetPersonalizationSettingsSuccessPayload;
}

export interface GetPersonalizationSettingsErrorAction {
  type: typeof PersonalizationActionTypes.GetPersonalizationSettingsError;
  payload: GetPersonalizationSettingsErrorPayload;
}

export interface UpdatePersonalizationSettingsRequestAction {
  type: typeof PersonalizationActionTypes.UpdatePersonalizationSettingsRequest;
  payload: UpdatePersonalizationSettingsRequestPayload;
}

export interface UpdatePersonalizationSettingsSuccessAction {
  type: typeof PersonalizationActionTypes.UpdatePersonalizationSettingsSuccess;
  payload: UpdatePersonalizationSettingsSuccessPayload;
}

export interface UpdatePersonalizationSettingsErrorAction {
  type: typeof PersonalizationActionTypes.UpdatePersonalizationSettingsError;
  payload: UpdatePersonalizationSettingsErrorPayload;
}

export interface NotifyPersonalizationSettingsIsDirtyAction {
  type: typeof PersonalizationActionTypes.NotifyPersonalizationSettingsIsDirty;
  payload: NotifyPersonalizationSettingsIsDirtyActionPayload;
}

export interface GetPersonalizationDataFieldsRequestAction {
  type: typeof PersonalizationActionTypes.GetDataFieldsRequest;
  payload: GetPersonalizationDataFieldsRequestPayload;
}

export interface GetPersonalizationDataFieldsSuccessAction {
  type: typeof PersonalizationActionTypes.GetDataFieldsSuccess;
  payload: GetPersonalizationDataFieldsSuccessPayload;
}

export interface GetPersonalizationDataFieldsErrorAction {
  type: typeof PersonalizationActionTypes.GetDataFieldsError;
  payload: GetPersonalizationDataFieldsErrorPayload;
}

export type PersonalizationSettingsAction =
  | GetPersonalizationSettingsRequestAction
  | GetPersonalizationSettingsSuccessAction
  | GetPersonalizationSettingsErrorAction
  | UpdatePersonalizationSettingsRequestAction
  | UpdatePersonalizationSettingsSuccessAction
  | UpdatePersonalizationSettingsErrorAction
  | NotifyPersonalizationSettingsIsDirtyAction
  | GetPersonalizationDataFieldsRequestAction
  | GetPersonalizationDataFieldsSuccessAction
  | GetPersonalizationDataFieldsErrorAction;
