import { orderBy } from 'lodash';

export enum SortOption {
  Asc = 'asc',
  Desc = 'desc',
}

export interface SortArrayArguments<T> {
  items: T[];
  sortBy: Array<keyof T> | ((value: T) => unknown);
  option: SortOption;
}
export function sortArray<T>({ items, sortBy, option }: SortArrayArguments<T>): T[] {
  const result = orderBy(items, sortBy, [option]);

  return result;
}
