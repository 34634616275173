import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { catalogManagerService } from 'src/services';

export const augmentedSearchContainerActions = {
  navigateTo: createAction<{ navigateTo: string }>('AugmentedSearchContainer/NavigateTo'),
  getShopCatalogs: createAsyncThunk(
    'AugmentedSearchContainer/GetShopCatalogs',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const shopCatalogs = await catalogManagerService.getCatalogs(shopId);

        return shopCatalogs;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
