export enum SwitchSizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'Large',
}

interface SizeProportions {
  width: number;
  height: number;
  thumbSize: number;
}

export const SwitchProportionsBySize: Record<string, SizeProportions> = {
  [SwitchSizes.Small]: { width: 34, height: 16, thumbSize: 12 },
  [SwitchSizes.Medium]: { width: 40, height: 24, thumbSize: 20 },
  [SwitchSizes.Large]: { width: 64, height: 32, thumbSize: 26 },
};
