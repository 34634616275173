import styled from '@emotion/styled';
import { Button } from 'src/components-dummy';

const FOOTER_HEIGHT_PX = 65;

export const EditRelevancyTuningWrapperStyled = styled.div`
  background-color: ${({ theme }) => theme.palette.custom['gray-90']};
  height: calc(100% - ${FOOTER_HEIGHT_PX}px);
  overflow: auto;
  padding: 32px;
  width: 100%;
`;

export const FooterStyled = styled.footer`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
  width: 100%;
  height: ${FOOTER_HEIGHT_PX}px;
  bottom: 0;
  left: 0;
  padding: 0 24px;
  z-index: 10;
  background: ${({ theme }) => theme.palette.common.white};
  > button + button {
    margin-left: 8px;
  }
`;

export const ButtonStyled = styled(Button)`
  padding-left: 32px;
  padding-right: 32px;
`;
