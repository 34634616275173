import React, { useMemo } from 'react';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import {
  ISocialCollectionService,
  SocialCollectionService,
} from '../../services/src/service/social-discovery/social-collection-service';
import { SocialDiscoveryProvider } from './SocialDiscoveryProvider';

import { SocialDiscoveryPage } from './SocialDiscoveryPage';
import { PageNotFoundContainer } from '../PageNotFoundContainer';
import { useSocialDiscoveryPageGuard } from './useSocialDiscoveryPageGuard';

export interface ISocialDiscoveryProps {
  shopId: number;
}

export const SocialDiscovery: React.FC<ISocialDiscoveryProps> = ({ shopId }) => {
  const dispatch = useAppDispatch();
  const service = useMemo(() => new SocialCollectionService(shopId), [shopId]);
  const canAccessPage = useSocialDiscoveryPageGuard({ shopId, dispatch });
  if (!canAccessPage) return <PageNotFoundContainer />;
  return (
    <SocialDiscoveryProvider service={service as ISocialCollectionService} appDispatch={dispatch}>
      <SocialDiscoveryPage shopId={shopId} />
    </SocialDiscoveryProvider>
  );
};
