import styled from '@emotion/styled';
import { TableV2 } from 'src/components-dummy';
import { Skeleton } from 'src/components-dummy/Skeleton';
import { tableColumns } from './tableColumns';

export const StyledSkeleton = styled(Skeleton)`
  margin: 10px;
`;

export const TableWrapperStyled = styled.div`
  min-width: 560px;
`;

export const TableBodyRowCellStyled = styled(TableV2.BodyRowCell)<{ cellName: string }>`
  min-height: 64px;
  align-items: center;
  display: flex;

  ${({ cellName }) =>
    cellName === tableColumns.rowActions.accessor ? 'justify-content: flex-end;' : ''}
`;
