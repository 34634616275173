import React from 'react';
import styled from '@emotion/styled';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import { MenuSelectProps, Select, SelectType } from '../Select';
import { MenuItem } from '../MenuItem';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface Props extends Omit<MenuSelectProps, 'onChange'> {
  label: string;
  items: Array<{ value: string; label: string }>;
  onChange: (value: string) => void;
}

export function FormSelect({ label, items, onChange, ...selectProps }: Props) {
  return (
    <FlexContainer className='form-select-container'>
      <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
        {label}
      </Typography>
      <Select {...selectProps} type={SelectType.Menu} onChange={e => onChange(e.target.value)}>
        {items.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FlexContainer>
  );
}
