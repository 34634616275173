import React from 'react';
import { Box, PopoverOrigin } from '@mui/material';
import { CustomPopoverStyled } from './DateRangePicker.style';
import { DateRangePickerWithButtonsContent } from './DateRangePickerWithButtonsContent';

/**
 * Main
 */
interface DateRangePickerWithButtonsModalProps {
  startDate: Date | null;
  endDate: Date | null;
  anchorEl: Element | null;
  onApply: ({ startDate, endDate }: { startDate: Date | null; endDate: Date | null }) => void;
  onClose: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}
export const DateRangePickerWithButtonsModal = ({
  onApply,
  onClose,
  startDate,
  endDate,
  anchorEl,
  anchorOrigin,
  transformOrigin,
}: DateRangePickerWithButtonsModalProps): JSX.Element => {
  const open = !!anchorEl;

  return (
    <div>
      <CustomPopoverStyled
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={
          anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'left',
          }
        }
        transformOrigin={
          transformOrigin || {
            vertical: 'top',
            horizontal: 'left',
          }
        }
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 'fit-content',
            borderRadius: '6px',
            padding: '0 0 16px 0',
          }}
        >
          <DateRangePickerWithButtonsContent
            onApply={onApply}
            startDate={startDate}
            endDate={endDate}
          />
        </Box>
      </CustomPopoverStyled>
    </div>
  );
};
