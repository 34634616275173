import React, { useEffect } from 'react';
import {
  DraggableList,
  ListActionBar,
  IsIncludedIndicator,
  useFindComponentByType,
  Tooltip,
  Icon,
  Typography,
  TypographyType,
  TypographyVariant,
} from 'src/components-dummy';
import { FilterCard, FilterStatusOption } from 'src/components-bl';
import { FiltersListProps, EditableFilterItem, FilterStatusPropertyName } from './types';
import { useFiltersListManager } from './hooks/use-filters-list-manager';
import { useItemsCount } from './hooks/use-items-count';
import { AdditionalSettings } from './named-children';
import './FiltersList.scss';

export const FiltersList = ({
  itemsList,
  isDirtyDispatcher,
  hasExternalDiff,
  children,
  listActionOptions,
  onSubmit,
  counterFormatter,
  isDragAndDropEnabled,
  messages,
  rawDataFields,
  unSavedDataFields,
  syncItemsCount,
  updateStatus,
  shouldIncludeDisplayName,
  formApiRef,
  onSubmitStatusChange,
}: FiltersListProps): JSX.Element => {
  const additionalSettingsFormSection = useFindComponentByType({
    type: AdditionalSettings,
    children,
  });
  const {
    formActions,
    expendedItems,
    setIsFormOpen,
    discardItemChanges,
    handleFilterChange,
    listValidity,
    addItem,
    saveItem,
    listDraft,
    isHiddenFilterSoftAlertShown,
    dataFields,
  } = useFiltersListManager(
    itemsList,
    onSubmit,
    isDirtyDispatcher,
    hasExternalDiff,
    listActionOptions,
    updateStatus,
    rawDataFields,
    unSavedDataFields,
    shouldIncludeDisplayName,
    formApiRef,
    onSubmitStatusChange
  );
  const { itemsCount, activeItemsCount } = useItemsCount(listDraft);

  useEffect(() => {
    if (syncItemsCount) {
      syncItemsCount(activeItemsCount);
    }
  }, [activeItemsCount]);

  return (
    <div className='syte-filters-list'>
      <div className='syte-filters-list__header'>{additionalSettingsFormSection}</div>
      <div className='syte-filters-list__title'>
        <Typography
          className='syte-filters-list__title'
          type={TypographyType.Paragraph}
          variant={TypographyVariant.MediumRegular}
        >
          {messages.mainTitle.text}
        </Typography>
        {messages.mainTitle.tooltip && messages.mainTitle.tooltip.enabled ? (
          <Tooltip value={messages.mainTitle.tooltip.text} position='bottom center'>
            <Icon name={messages.mainTitle.tooltip.icon} />
          </Tooltip>
        ) : null}
      </div>
      <ListActionBar<EditableFilterItem>
        addButton={
          listActionOptions.allowAddItem ? { label: 'Add new filter', cb: addItem } : undefined
        }
        searchableTargetFields={['displayNameKey']}
        filterBy={{
          property: FilterStatusPropertyName,
          excludedValues: [FilterStatusOption.New],
        }}
        itemsList={listDraft}
        handleFilterChange={handleFilterChange}
      />

      <p className='filters-list-counter'>
        {counterFormatter ? counterFormatter(itemsCount, activeItemsCount) : null}
      </p>
      <DraggableList onChange={formActions.onOrderChanged} isDragDisabled={!isDragAndDropEnabled}>
        {listDraft.map(item => {
          return (
            <FilterCard
              key={item.key}
              status={item[FilterStatusPropertyName]}
              className={item[IsIncludedIndicator] ? '' : 'hidden'}
              filterItem={item}
              isDraggable={isDragAndDropEnabled}
              dismissChanges={discardItemChanges}
              saveItem={saveItem}
              onItemChanged={updates => formActions.itemChangeHandler(item.key, updates)}
              toggleItemVisibility={() => formActions.toggleItemVisibility(item.key)}
              isFormExpended={expendedItems[item.key]}
              setIsFormOpen={isOpen => setIsFormOpen(item.key, isOpen)}
              deleteItem={
                listActionOptions.allowDeleteItem
                  ? () => formActions.deleteItem(item.key)
                  : undefined
              }
              isHiddenFilterSoftAlertShown={isHiddenFilterSoftAlertShown}
              dataFields={dataFields}
              messages={{
                disable: messages.disable,
                delete: messages.delete,
              }}
              shouldIncludeDisplayName={shouldIncludeDisplayName}
              errors={listValidity[item.key]}
            />
          );
        })}
      </DraggableList>
    </div>
  );
};

FiltersList.AdditionalSettings = AdditionalSettings;
