import React from 'react';
import { TableHeaderCellResizableCursorStyled } from '../TableV2.style';

export const TableHeaderCellResizableCursor = ({
  column,
}: {
  column: Record<string, any>;
}): JSX.Element => {
  return (
    <TableHeaderCellResizableCursorStyled
      {...column.getResizerProps()}
      className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
      onClick={event => {
        event.stopPropagation();
      }}
      onChange={event => {
        event.stopPropagation();
      }}
    />
  );
};
