import React from 'react';
import styled from '@emotion/styled';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import { Tooltip } from '../Tooltip';
import { AvailableIcons, Icon } from '../Icon';

interface FormTitleProps {
  children: React.ReactText;
  tip?: string | React.ReactNode;
}

const StyledTitleContainer = styled.div`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export function FormTitle({ children, tip }: FormTitleProps) {
  return (
    <StyledTitleContainer>
      <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
        {children}
      </Typography>
      {!!tip && (
        <Tooltip value={tip} contentClickPreventDefault={false}>
          <Icon name={AvailableIcons.TooltipQuestionmark} />
        </Tooltip>
      )}
    </StyledTitleContainer>
  );
}
