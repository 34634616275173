import React, { useCallback } from 'react';
import { ParseJoiValidateResponse } from 'src/utils';
import { MenuItem, SelectType, TypographyType, TypographyVariant } from 'src/components-dummy';
import { AttributeTagPart, ValueTagPart } from '../../../LexiconRuleForm.types';
import { LexiconTagSelectStyled } from '../LexiconChangeRuleSection.styles';
import { CustomTranslationTextBox, TagRowTypographyStyled } from './TagRow.styles';

interface ValueRowProps {
  tag: ValueTagPart;
  errors: ParseJoiValidateResponse<AttributeTagPart>;
  onChange: (updatedTag: ValueTagPart) => void;
  availableAttributeKeys: string[];
  availableValueKeys: string[];
}

export const ValueRow = ({
  tag,
  errors,
  onChange,
  availableAttributeKeys,
  availableValueKeys,
}: ValueRowProps): JSX.Element => {
  const onTagChange = useCallback(
    (updatedTag: Partial<ValueTagPart>) => {
      const updatedState = { ...tag, ...updatedTag };
      onChange(updatedState);
    },
    [tag, onChange]
  );

  const onAttributeKeyChange = useCallback(
    event => {
      onTagChange({ attributeKey: event.target.value, valueKey: '', customTranslation: '' });
    },
    [onTagChange]
  );

  const onValueKeyChange = useCallback(
    event => {
      onTagChange({ valueKey: event.target.value, customTranslation: '' });
    },
    [onTagChange]
  );

  const onValueTranslationChange = useCallback(
    newTranslation => {
      onTagChange({ customTranslation: newTranslation });
    },
    [onTagChange]
  );

  const showValueKey = tag.attributeKey && !errors.attributeKey;
  const showValueTranslation = showValueKey && tag.valueKey && !errors.valueKey;

  return (
    <>
      <TagRowTypographyStyled type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
        of attribute
      </TagRowTypographyStyled>
      <LexiconTagSelectStyled
        type={SelectType.Menu}
        placeholder='Attribute'
        value={tag.attributeKey}
        onChange={onAttributeKeyChange}
        errorMessage={errors.attributeKey?.message}
        autoFocus
      >
        {availableAttributeKeys.map(attributeKey => (
          <MenuItem key={attributeKey} value={attributeKey}>
            {attributeKey}
          </MenuItem>
        ))}
      </LexiconTagSelectStyled>
      {showValueKey && (
        <>
          <TagRowTypographyStyled
            type={TypographyType.Body}
            variant={TypographyVariant.MediumMedium}
          >
            value
          </TagRowTypographyStyled>
          <LexiconTagSelectStyled
            type={SelectType.Menu}
            placeholder='Value'
            value={tag.valueKey}
            onChange={onValueKeyChange}
            errorMessage={errors.valueKey?.message}
            autoFocus
          >
            {availableValueKeys.map(valueKey => (
              <MenuItem key={valueKey} value={valueKey}>
                {valueKey}
              </MenuItem>
            ))}
          </LexiconTagSelectStyled>
        </>
      )}
      {showValueTranslation && (
        <>
          <TagRowTypographyStyled
            type={TypographyType.Body}
            variant={TypographyVariant.MediumMedium}
          >
            to
          </TagRowTypographyStyled>
          <CustomTranslationTextBox
            placeholder='type value'
            onChange={onValueTranslationChange}
            value={tag.customTranslation}
            error={errors.customTranslation?.message}
          />
        </>
      )}
    </>
  );
};
