import styled from '@emotion/styled';
import { Typography } from '../Typography';
import { LinkButton } from '../LinkButton';
import { EllipsisWithTooltip } from '../EllipsisWithToolTip';

export const FileUploaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;

  /* Greys/Grey 90 (Background) */

  background: ${({ theme }) => {
    return theme.palette.custom['gray-90'];
  }};
  /* Greys/Grey 60 ( Container stroke) */

  border: 1px dashed
    ${({ theme }) => {
      return theme.palette.custom['gray-60'];
    }};
  border-radius: 4px;
`;

export const UploaderTitleStyled = styled(Typography)`
  margin-top: 9px;
  margin-bottom: 4px;
`;

export const FileErrorMessageStyled = styled.div<{ visible: boolean }>`
  background-color: ${({ theme }) => theme.palette.custom['red-90']};
  color: ${({ theme }) => theme.palette.custom.red};
  width: 100%;
  margin-bottom: 33px;
  padding: 4px 0px;
  text-align: center;
  visibility: ${props =>
    props.visible ? 'visible' : 'hidden'}; /** use visibility to retain height */
`;

export const UploadButtonErrorMessageStyled = styled.div<{ visible: boolean }>`
  color: ${({ theme }) => theme.palette.custom.red};
  width: 100%;
  padding: 4px 0px;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

export const DownloadExampleLinkButtonStyled = styled(LinkButton)`
  display: flex;
  align-items: center;
  margin-top: 32px;
  svg {
    margin-right: 5.5px;
    path {
      stroke: ${({ theme }) => {
        return theme.palette.custom['primary-main'];
      }} !important;
    }
  }
`;

export const FileCardNameStyled = styled(EllipsisWithTooltip)`
  width: 100%;
`;
