import { RuleGroup } from 'src/components-bl/Collections';
import { CollectionRulesDiff } from './types';

export function calculateDiff(
  originalRuleGroups: RuleGroup[],
  editedRuleGroups: RuleGroup[]
): CollectionRulesDiff {
  const removedRuleGroupsIds = originalRuleGroups
    .map(ruleGroup => ruleGroup.id)
    .filter(ruleGroupId => !editedRuleGroups.some(ruleGroup => ruleGroup.id === ruleGroupId));

  const addedRuleGroups = editedRuleGroups.filter(
    editedRuleGroup =>
      !originalRuleGroups.some(originalRuleGroup => editedRuleGroup.id === originalRuleGroup.id)
  );

  const modifiedRuleGroups = editedRuleGroups.filter(
    ruleGroup =>
      ruleGroup.isModified &&
      !addedRuleGroups.some(addedRuleGroup => addedRuleGroup.id === ruleGroup.id)
  );

  return {
    ...(removedRuleGroupsIds.length > 0 ? { removedRuleGroupsIds } : {}),
    ...(addedRuleGroups.length > 0 ? { addedRuleGroups } : {}),
    ...(modifiedRuleGroups.length > 0 ? { modifiedRuleGroups } : {}),
  };
}
