import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  merchandisingRulesFormActions,
  UpdateMerchRuleArgs,
} from '../../MerchandisingRules/components/MerchandisingRuleForm';
import { PreviewMerchRule } from '../types';
import { PermittedRouteMap } from '../../../app-routes';
import { visualEditorActions } from '../state';
import { useNavigateToVisualEditorRoute } from './useNavigateToVisualEditorRoute';

export const useEditStrategy = ({
  shopId,
  selectedRule,
  permittedRouteMap,
}: {
  shopId: number;
  selectedRule: PreviewMerchRule | null;
  permittedRouteMap: PermittedRouteMap;
}) => {
  const dispatch = useDispatch();

  const { navigateToVisualEditorRoute } = useNavigateToVisualEditorRoute({
    route: permittedRouteMap?.visualEditorEdit,
  });

  const closeStrategyDrawer = useCallback(() => {
    if (!selectedRule) return;
    dispatch(visualEditorActions.notifyIsDirty({ isDirty: false }));
    navigateToVisualEditorRoute({ shopId, ruleId: selectedRule.id });
  }, [dispatch, navigateToVisualEditorRoute, selectedRule, shopId]);

  const submitStrategyForm = useCallback(
    (payload: UpdateMerchRuleArgs) => {
      (dispatch(merchandisingRulesFormActions.updateAndGetMerchRule(payload)) as any)
        .unwrap()
        .then(({ rule }: { rule: PreviewMerchRule }) => {
          dispatch(visualEditorActions.updateRule(rule));
          dispatch(visualEditorActions.setSelectedRule(rule));
          closeStrategyDrawer();
        });
    },
    [closeStrategyDrawer, dispatch]
  );

  return {
    closeStrategyDrawer,
    submitStrategyForm,
  };
};
