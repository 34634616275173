import { ShopsTableHeadRow } from '../types';

export const tableColumns: Record<string, ShopsTableHeadRow> = {
  userId: {
    header: 'User Id',
    accessor: 'userId',
    width: '20%',
  },
  email: {
    header: 'Email',
    accessor: 'email',
    width: '20%',
  },
  online: {
    header: 'Online',
    accessor: 'online',
    width: '20%',
  },
  accountId: {
    header: 'Account Id',
    accessor: 'accountId',
    width: '20%',
  },
  accountName: {
    header: 'Account Name',
    accessor: 'accountName',
    width: '20%',
  },
};
