import React, { useCallback, useState } from 'react';
import { AvailableIcons, SelectAllModes } from 'src/components-dummy';
import { BackDropWithLoader } from 'src/components-dummy/Backdrop/BackdropWithLoader';
import { ILexiconTagsPaginationAPI } from 'src/services/src/service/types/lexicon';
import { Box } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import { LexiconTagType } from 'src/services/src/service/lexicon/types';
import { LexiconTableHeaderColumnsProps } from '../LexiconTableHeaderColumns';
import {
  LexiconBulkActionsRowStyles,
  VerticalSeparator,
  SelectedCountButtonStyled,
} from './LexiconBulkActionsRow.styles';
import {
  LexiconBulkActionInclude,
  LexiconBulkActionRename,
  LexiconBulkActionRestore,
  BaseBulkActionProps,
} from '../BulkActions';
import { LexiconBulkActionDelete } from '../BulkActions/LexiconBulkActionDelete';

interface LexiconBulkActionsRowProps
  extends Pick<LexiconTableHeaderColumnsProps, 'selectAllState'>,
    Omit<
      BaseBulkActionProps,
      | 'targetItemsCounts'
      | 'targetAllTags'
      | 'onActionSuccess'
      | 'onActionStart'
      | 'onActionFail'
      | 'matchTags'
      | 'excludeTags'
    > {
  includedTagKeys: Set<string>;
  excludedTagKeys: Set<string>;
  onUnselectAll?: () => void;
  onBulkActionSuccess: () => Promise<ILexiconTagsPaginationAPI>;
  totalItemsCount: number;
  isSyteAdmin: boolean;
  tagType: LexiconTagType;
}

function getSelectedCount({
  totalCount,
  includedCount,
  excludedCount,
  selectAllState,
}: { totalCount: number; includedCount: number; excludedCount: number } & Pick<
  LexiconTableHeaderColumnsProps,
  'selectAllState'
>): number {
  if (selectAllState.mode === SelectAllModes.AllSelected) {
    return totalCount - excludedCount;
  }
  return includedCount;
}

export const LexiconBulkActionsRow = React.memo(
  ({
    includedTagKeys,
    excludedTagKeys,
    selectAllState,
    totalItemsCount,
    onUnselectAll,
    onBulkActionSuccess,
    shopId,
    locale,
    tagType,
    filters,
    dispatch,
    isSyteAdmin,
  }: LexiconBulkActionsRowProps): JSX.Element => {
    const [isInProcess, setIsInProcess] = useState(false);

    const selectedCount = getSelectedCount({
      totalCount: totalItemsCount,
      includedCount: includedTagKeys.size,
      excludedCount: excludedTagKeys.size,
      selectAllState,
    });

    const selectedText = `${selectedCount} selected`;

    const targetAllTags = selectAllState.mode === SelectAllModes.AllSelected;

    const onActionStart = useCallback(() => {
      setIsInProcess(true);
    }, []);

    const onActionFail = useCallback(() => {
      setIsInProcess(false);
    }, []);

    const onActionSuccess = useCallback(async () => {
      try {
        await onBulkActionSuccess();
      } finally {
        setIsInProcess(false);
      }
    }, [onBulkActionSuccess, setIsInProcess]);

    const isLexiconTagType = tagType === LexiconTagType.LexiconTags;

    const hasSelectedTags = selectedCount > 0;

    return (
      <>
        {isInProcess && <BackDropWithLoader />}
        <>
          <LexiconBulkActionsRowStyles show={selectAllState.checked}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <SelectedCountButtonStyled
                variant='primary'
                endIcon={AvailableIcons.Close}
                onClick={onUnselectAll}
              >
                {selectedText}
              </SelectedCountButtonStyled>
              <VerticalSeparator />
              <LexiconBulkActionInclude
                shopId={shopId}
                locale={locale}
                tagType={tagType}
                filters={filters}
                targetItemsCounts={selectedCount}
                dispatch={dispatch}
                matchTags={includedTagKeys}
                excludeTags={excludedTagKeys}
                targetAllTags={targetAllTags}
                onActionSuccess={onActionSuccess}
                onActionFail={onActionFail}
                onActionStart={onActionStart}
              />
              <LexiconBulkActionRename
                shopId={shopId}
                locale={locale}
                tagType={tagType}
                filters={filters}
                dispatch={dispatch}
                targetItemsCounts={selectedCount}
                matchTags={includedTagKeys}
                excludeTags={excludedTagKeys}
                targetAllTags={targetAllTags}
                onActionSuccess={onActionSuccess}
                onActionFail={onActionFail}
                onActionStart={onActionStart}
              />
              <CSSTransition
                in={hasSelectedTags && isLexiconTagType}
                unmountOnExit
                timeout={{ enter: 0, exit: 250 }}
              >
                <LexiconBulkActionRestore
                  shopId={shopId}
                  locale={locale}
                  filters={filters}
                  targetItemsCounts={selectedCount}
                  dispatch={dispatch}
                  matchTags={includedTagKeys}
                  excludeTags={excludedTagKeys}
                  targetAllTags={targetAllTags}
                  onActionSuccess={onActionSuccess}
                  onActionFail={onActionFail}
                  onActionStart={onActionStart}
                />
              </CSSTransition>
            </Box>
            <CSSTransition
              in={hasSelectedTags && !isLexiconTagType}
              unmountOnExit
              timeout={{ enter: 0, exit: 300 }}
            >
              <LexiconBulkActionDelete
                shopId={shopId}
                locale={locale}
                tagType={tagType}
                filters={filters}
                targetItemsCounts={selectedCount}
                dispatch={dispatch}
                matchTags={includedTagKeys}
                excludeTags={excludedTagKeys}
                targetAllTags={targetAllTags}
                onActionSuccess={onActionSuccess}
                onActionFail={onActionFail}
                onActionStart={onActionStart}
                isSyteAdmin={isSyteAdmin}
              />
            </CSSTransition>
          </LexiconBulkActionsRowStyles>
        </>
      </>
    );
  }
);
