import { AxiosResponse } from 'axios';
import { BoughtTogetherSettingsTypes } from '../types';

export const boughtTogetherSettingsResponse = ({
  data,
}: AxiosResponse): BoughtTogetherSettingsTypes.BoughtTogetherSettings => {
  return {
    numberOfResultsToDisplay: data.numberOfResultsToDisplay,
    modelType: data.modelType,
    boughtTogetherFallbackMethod: data.boughtTogetherFallbackMethod,
    addToCart: data.addToCart,
    boughtTogetherFallbackField: data.boughtTogetherFallbackField,
    sortingOrder: data.sortingOrder,
  };
};
