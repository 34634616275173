import styled from '@emotion/styled';
import { Button } from 'src/components-dummy';

export const GeneralSettingsContainerStyled = styled.div`
  max-width: 752px;
`;

export const GeneralSettingsCardContentStyled = styled.div`
  max-width: 324px;

  .syte-text-box,
  .shop-type-select {
    margin: 0 0 20px 0;
  }

  .syte-popup-trigger {
    width: 100%;
  }
`;

export const ModelContainerStyled = styled.div`
  margin-top: 20px;
  width: 240px;
  height: auto;
`;

export const UpdateButtonStyled = styled(Button)`
  margin-top: 20px;
  width: 240px;
`;
