import { SyteLayout } from './syte-layout';

export enum FilterDataSource {
  Catalog = 'catalog',
  DeepTags = 'deepTags',
}

export enum FilterType {
  Range = 'range',
  Text = 'text',
}

export enum FilterSortBy {
  ResultsCount = 'results_count',
  AlphabeticalAscending = 'alphabetical_ascending',
  AlphabeticalDescending = 'alphabetical_descending',
  CustomOrder = 'custom_order',
}

export interface FilterValuesSorting {
  sortBy: FilterSortBy;
  customOrder?: string[];
}

export interface Filter {
  id: string;
  enabled: boolean;
  dataSource: FilterDataSource;
  sourceField: string;
  type: FilterType;
  valuesSorting?: FilterValuesSorting;
  createdAt: Date;
  updatedAt: Date;
}

export interface FilterAPI extends Omit<Filter, 'createdAt' | 'updatedAt'> {
  createdAt: string;
  updatedAt: string;
}

export interface FilterSet {
  id: string;
  shopId: number;
  layout: SyteLayout;
  filters: Filter[];
  createdAt: Date;
  updatedAt: Date;
}

export interface FilterSetAPI extends Omit<FilterSet, 'createdAt' | 'updatedAt' | 'filters'> {
  createdAt: string;
  updatedAt: string;
  filters: FilterAPI[];
}
