import { ShopFeature } from 'src/app-types';
import { ShopFeatureToggleWithPermittedRoles } from 'src/components-bl/FilterCard/types';
import { UserRoles } from 'src/services/src/service/types/users';
import { useAppSelector } from './useAppSelector';

export function useIsFeaturePermitted(feature: ShopFeature): boolean {
  const userRole =
    useAppSelector(state => state.global.loggedInUser?.role) || UserRoles.ClientAdmin;
  const featureToggles = useAppSelector(state => state.shop.featureToggles);

  const featureToggle = featureToggles?.[feature];

  if (!featureToggle?.enabled) {
    return false;
  }

  return 'permittedRoles' in featureToggle
    ? Boolean(
        (featureToggle as ShopFeatureToggleWithPermittedRoles).permittedRoles?.includes(userRole)
      )
    : true;
}
