import React, { useState, useMemo, useCallback } from 'react';
import { Typography, TypographyType, TypographyVariant } from '../Typography';
import { MenuItem } from '../MenuItem';
import {
  MultiSelectMenuListStyled,
  EllipsisWithTooltipStyled,
  CheckboxStyled,
} from './MultiSelectMenu.styles';
import { NestedMultiSelectMenuProps } from './types';
import { selectAllOption, ITEMS_TO_SHOW_WITHOUT_SCROLL } from './use-multi-select';
import { NestedMultiSelectMenuItem } from './NestedMultiSelectMenuItem';
import { MenuItemWrapperStyled } from './NestedMultiSelectMenu.styles';
import {
  mapNestedMultiSelectOptionsToOptionsPerParent,
  mapNestedSelectedOptionsToSelectedOptionsPerParent,
  mapParentTitlesToConsistentValue,
} from './mappers';

export const NestedMultiSelectMenu = ({
  options,
  selectedOptions,
  enableSelectAll,
  selectAllText,
  parentOptionsOrderPriority,
  onChange,
}: NestedMultiSelectMenuProps): JSX.Element => {
  const optionsPerParent = useMemo(() => {
    return mapNestedMultiSelectOptionsToOptionsPerParent(options);
  }, [options]);

  const mappedSelectedOptionsPerParent = useMemo(() => {
    return mapNestedSelectedOptionsToSelectedOptionsPerParent(selectedOptions);
  }, [selectedOptions]);

  const availableParentTitles = useMemo(() => {
    return parentOptionsOrderPriority
      ? Object.keys(optionsPerParent).sort(
          (a, b) => parentOptionsOrderPriority[a] - parentOptionsOrderPriority[b]
        )
      : Object.keys(optionsPerParent);
  }, [optionsPerParent]);

  const [selectedAllOptionsState, setSelectedAllOptionsState] = useState(
    mapParentTitlesToConsistentValue({ parentTitles: availableParentTitles, value: false })
  );

  const [areNestedOptionsVisible, setAreNestedOptionsVisible] = useState(
    mapParentTitlesToConsistentValue({ parentTitles: availableParentTitles, value: false })
  );

  const onToggleNestedOptionsVisible = useCallback(
    ({ isVisible, parentTitle }: { isVisible: boolean; parentTitle: string }) => {
      setAreNestedOptionsVisible({ ...areNestedOptionsVisible, [parentTitle]: isVisible });
    },
    [areNestedOptionsVisible, setAreNestedOptionsVisible]
  );

  const onSelectAllOptionChange = useCallback(
    ({ isSelected, parentTitle }: { isSelected: boolean; parentTitle: string }) => {
      if (selectedAllOptionsState[parentTitle] !== isSelected) {
        setSelectedAllOptionsState({ ...selectedAllOptionsState, [parentTitle]: isSelected });
      }
    },
    [selectedAllOptionsState, setSelectedAllOptionsState]
  );

  const isAllOptionsSelected = useMemo(() => {
    return Object.values(selectedAllOptionsState).every(Boolean);
  }, [selectedAllOptionsState]);

  const handleToggleAllClick = useCallback(() => {
    onChange?.(isAllOptionsSelected ? [] : [...options.map(option => option.value)]);
  }, [onChange, isAllOptionsSelected]);

  const selectedAllOption = { ...selectAllOption, text: selectAllText || selectAllOption.text };

  const enableScroll = useMemo(() => {
    const areNestedOptionsVisibleEntries = Object.entries(areNestedOptionsVisible);
    const baseNumberOfOptions = areNestedOptionsVisibleEntries.length + 1;

    const numberOfVisibleOptions = areNestedOptionsVisibleEntries.reduce(
      (sumOfVisibleOptions, currentEntry) => {
        const [parentTitle, isOptionsVisible] = currentEntry;
        return isOptionsVisible && optionsPerParent[parentTitle]
          ? sumOfVisibleOptions + optionsPerParent[parentTitle].length
          : sumOfVisibleOptions;
      },
      baseNumberOfOptions
    );

    return numberOfVisibleOptions > ITEMS_TO_SHOW_WITHOUT_SCROLL;
  }, [areNestedOptionsVisible, optionsPerParent]);

  return (
    <MultiSelectMenuListStyled enableScroll={enableScroll}>
      {enableSelectAll && (
        <MenuItemWrapperStyled>
          <MenuItem onClick={handleToggleAllClick}>
            <CheckboxStyled checked={isAllOptionsSelected} />
            <EllipsisWithTooltipStyled tooltipText={selectedAllOption.text}>
              <Typography type={TypographyType.Body} variant={TypographyVariant.MediumMedium}>
                {selectedAllOption.text}
              </Typography>
            </EllipsisWithTooltipStyled>
          </MenuItem>
        </MenuItemWrapperStyled>
      )}
      {availableParentTitles.map(parentTitle => (
        <NestedMultiSelectMenuItem
          key={parentTitle}
          options={optionsPerParent[parentTitle]}
          enableSelectAll={enableSelectAll}
          selectAllText={parentTitle}
          onChange={onChange}
          selectedOptions={mappedSelectedOptionsPerParent[parentTitle]}
          onSelectAllOptionChange={onSelectAllOptionChange}
          onToggleNestedOptionsVisible={onToggleNestedOptionsVisible}
          isOptionsVisible={areNestedOptionsVisible[parentTitle]}
        />
      ))}
    </MultiSelectMenuListStyled>
  );
};
