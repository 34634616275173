import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import {
  CheckboxStyled,
  IconStyled,
  CheckboxContainerStyled,
  TypographyStyled,
  InvisibleCheckboxInput,
} from './Checkbox.styles';
import { AvailableIcons } from '../Icon';
import { TypographyType, TypographyVariant } from '../Typography';

export interface CheckboxProps {
  checked?: boolean;
  label?: string;
  name?: string;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
  onBlur?: () => void;
  className?: string;
  prefixId?: string;
  iconName?: AvailableIcons;
}

export const Checkbox = ({
  checked,
  label,
  disabled,
  onChange,
  className,
  onBlur,
  prefixId,
  iconName = AvailableIcons.CheckRounded,
}: CheckboxProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState(!!checked);

  const onCheckBoxClick = () => {
    if (!disabled) {
      setIsChecked(!isChecked);
      onChange?.(!isChecked);
    }
  };

  useEffect(() => {
    if (checked !== isChecked) {
      setIsChecked(!!checked);
    }
  }, [checked]);

  return (
    <CheckboxContainerStyled
      data-id={prefixId}
      className={classNames('syte-checkbox', className)}
      disabled={!!disabled}
      checked={isChecked}
      onBlur={onBlur}
    >
      <InvisibleCheckboxInput
        type='checkbox'
        checked={isChecked}
        onChange={onCheckBoxClick}
        onClick={onCheckBoxClick}
        disabled={disabled}
      />
      <CheckboxStyled checked={isChecked} disabled={!!disabled}>
        <IconStyled name={iconName} checked={isChecked} />
      </CheckboxStyled>

      {label && (
        <TypographyStyled
          type={TypographyType.Body}
          variant={TypographyVariant.MediumMedium}
          disabled={!!disabled}
        >
          {label}
        </TypographyStyled>
      )}
    </CheckboxContainerStyled>
  );
};
