import { useEffect } from 'react';
import {
  catalogManagerWebSocketService,
  IShopCatalogUpdateEvent,
  IShopCatalogDeleteEvent,
} from 'src/services';
import { Dispatch } from 'src/components-bl';
import { useCatalogManagerWSActions } from './useCatalogManagerWS.actions';

export const useCatalogManagerWS = ({
  dispatch,
  shopId,
}: {
  dispatch: Dispatch;
  shopId: number | undefined;
}): void => {
  const issueToastNotification = (
    payload: IShopCatalogUpdateEvent | IShopCatalogDeleteEvent,
    customMessage: string
  ) => {
    if (payload.eventIssuedByCurrentUser) return;

    dispatch(useCatalogManagerWSActions.notification({ customMessage }));
  };

  const onCatalogCreate = (payload: IShopCatalogUpdateEvent) => {
    if (shopId === payload.data.shopId) {
      dispatch(useCatalogManagerWSActions.create({ catalog: payload.data.catalog }));

      const title = payload.data.catalog.name;

      const customMessage = `New shop catalogue was created: ${title}`;

      issueToastNotification(payload, customMessage);
    }
  };

  const onCatalogUpdate = (payload: IShopCatalogUpdateEvent) => {
    if (shopId === payload.data.shopId) {
      dispatch(useCatalogManagerWSActions.update({ catalog: payload.data.catalog }));

      const title = payload.data.catalog.name;

      const customMessage = `Shop catalogue was updated: ${title}`;

      issueToastNotification(payload, customMessage);
    }
  };

  const onCatalogDelete = (payload: IShopCatalogDeleteEvent) => {
    if (shopId === payload.data.shopId) {
      dispatch(useCatalogManagerWSActions.delete({ id: payload.data.id }));

      const customMessage = 'Shop catalogue was deleted';

      issueToastNotification(payload, customMessage);
    }
  };

  useEffect(() => {
    catalogManagerWebSocketService.subscribeToCatalogCreateEvents(onCatalogCreate);
    catalogManagerWebSocketService.subscribeToCatalogUpdateEvents(onCatalogUpdate);
    catalogManagerWebSocketService.subscribeToCatalogDeleteEvents(onCatalogDelete);

    return () => {
      catalogManagerWebSocketService.clearListeners();
    };
  }, [shopId]);
};
