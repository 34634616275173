import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { MerchandisingRulesTitle } from './MerchandisingRulesTitle';
import { MerchandisingRuleAction } from './MerchandisingRuleAction';
import { combineActionAndWeightText } from '../../../MerchandisingRules/merchandisingRules.helpers';
import { MerchandisingRuleCard } from './MerchandisingRuleCard';
import { AvailableIcons, IconIndication, Switch, SwitchSizes } from '../../../../components-dummy';
import { PreviewMerchRule } from '../../types';

const IconWrapper = styled.div`
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const MerchandisingRuleFooter = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;
`;

const MerchandisingRuleInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 9;
  gap: 4px;
`;

const RuleActionItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  className?: string;
  rule: PreviewMerchRule;
  onRuleClick: (rule: PreviewMerchRule) => void;
  onRuleToggle: (rule: PreviewMerchRule) => void;
}

export function MerchandisingRule({
  rule,
  onRuleClick,
  onRuleToggle,
  className,
}: Props): JSX.Element {
  const memoizedOnRuleClick = useCallback(() => onRuleClick(rule), [onRuleClick, rule]);
  const memoizedOnRuleToggle = useCallback(
    (active: boolean) => onRuleToggle({ ...rule, active }),
    [onRuleToggle, rule]
  );

  return (
    <MerchandisingRuleCard
      active={rule.active}
      isApplied={rule.isApplied}
      onClick={memoizedOnRuleClick}
      className={className}
    >
      <MerchandisingRuleInfo>
        <MerchandisingRulesTitle>{rule.name}</MerchandisingRulesTitle>
        <MerchandisingRuleFooter>
          <MerchandisingRuleAction>
            {combineActionAndWeightText({ action: rule.action, weight: rule.weight })}
          </MerchandisingRuleAction>
          <IconWrapper>
            {rule.error && (
              <IconIndication tooltipValue='Invalid Rule' iconName={AvailableIcons.CircleWarning} />
            )}
            {!rule.isPublished && (
              <IconIndication
                tooltipValue='Unpublished Rule'
                iconName={AvailableIcons.CloudError2}
              />
            )}
          </IconWrapper>
        </MerchandisingRuleFooter>
      </MerchandisingRuleInfo>
      <RuleActionItemContainer>
        <Switch
          disabled={rule.error}
          checked={rule.active}
          onChange={memoizedOnRuleToggle}
          size={SwitchSizes.Small}
        />
      </RuleActionItemContainer>
    </MerchandisingRuleCard>
  );
}
