import { Accounts, Users, UserTypes, APITypes } from 'src/services';
import { Dispatch } from 'src/components-bl/types';

/* --= Action Types =-- */
export enum UserListActionTypes {
  UserSelected = 'UserList/UserSelected',
  DeleteUserRequest = 'UserList/DeleteUser_Request',
  DeleteUserError = 'UserList/DeleteUser_Error',
  DeleteUserSuccess = 'UserList/DeleteUser_Success',
  GetAllUsersRequest = 'UserList/GetAllUsers_Request',
  GetAllUsersSuccess = 'UserList/GetAllUsers_Success',
  GetAllUsersError = 'UserList/GetAllUsers_Error',
}

/* --= Action Interfaces =-- */
interface UserSelectedAction {
  type: typeof UserListActionTypes.UserSelected;
  payload: { userId: number | undefined };
}
interface DeleteUserRequestAction {
  type: typeof UserListActionTypes.DeleteUserRequest;
  payload: { userId: number };
}
interface DeleteUserSuccessAction {
  type: typeof UserListActionTypes.DeleteUserSuccess;
  payload: { userId: number };
}
interface DeleteUserErrorAction {
  type: typeof UserListActionTypes.DeleteUserError;
  payload: { error: APITypes.ApiError };
}
interface GetAllUsersRequestAction {
  type: typeof UserListActionTypes.GetAllUsersRequest;
  payload: { accountId: number };
}

interface GetAllUsersSuccessAction {
  type: typeof UserListActionTypes.GetAllUsersSuccess;
  payload: { users: UserTypes.User[] };
}
interface GetAllUsersErrorAction {
  type: typeof UserListActionTypes.GetAllUsersError;
  payload: { error: APITypes.ApiError };
}

export type UserListAction =
  | UserSelectedAction
  | DeleteUserRequestAction
  | DeleteUserSuccessAction
  | DeleteUserErrorAction
  | GetAllUsersRequestAction
  | GetAllUsersSuccessAction
  | GetAllUsersErrorAction;

/* --= Actions =-- */
export const userListActions = {
  userSelected: (userId: number | undefined): UserSelectedAction => {
    return {
      type: UserListActionTypes.UserSelected,
      payload: { userId },
    };
  },
  deleteUserRequest: (userId: number): DeleteUserRequestAction => {
    return {
      type: UserListActionTypes.DeleteUserRequest,
      payload: { userId },
    };
  },
  deleteUserSuccess: (userId: number): DeleteUserSuccessAction => {
    return {
      type: UserListActionTypes.DeleteUserSuccess,
      payload: { userId },
    };
  },
  deleteUserError: (error: APITypes.ApiError): DeleteUserErrorAction => {
    return {
      type: UserListActionTypes.DeleteUserError,
      payload: { error },
    };
  },
  getAllUsersRequest: (accountId: number): GetAllUsersRequestAction => {
    return {
      type: UserListActionTypes.GetAllUsersRequest,
      payload: { accountId },
    };
  },
  getAllUsersSuccess: (users: UserTypes.User[]): GetAllUsersSuccessAction => {
    return {
      type: UserListActionTypes.GetAllUsersSuccess,
      payload: { users },
    };
  },
  getAllUsersError: (error: APITypes.ApiError): GetAllUsersErrorAction => {
    return {
      type: UserListActionTypes.GetAllUsersError,
      payload: { error },
    };
  },
};

/* --= Methods =-- */
export const userListActionMethods = {
  userSelected(userId: number | undefined): UserSelectedAction {
    return userListActions.userSelected(userId);
  },

  deleteUser(userId: number): Dispatch {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        const userService = new Users();
        dispatch(userListActions.deleteUserRequest(userId));
        await userService.deleteUser(userId);
        dispatch(userListActions.deleteUserSuccess(userId));
      } catch (error) {
        dispatch(userListActions.deleteUserError(error as APITypes.ApiError));
      }
    };
  },

  getAllUsers(accountId: number): Dispatch {
    return async (dispatch: Dispatch): Promise<void> => {
      try {
        const accountService = new Accounts();
        dispatch(userListActions.getAllUsersRequest(accountId));
        const accountResult = await accountService.getAccount(accountId);
        dispatch(userListActions.getAllUsersSuccess(accountResult?.users ?? []));
      } catch (error) {
        dispatch(userListActions.getAllUsersError(error as APITypes.ApiError));
      }
    };
  },
};
