import { createAsyncThunk } from '@reduxjs/toolkit';
import { catalogManagerService } from 'src/services';

export const merchandisingRulesActions = {
  getShopLocales: createAsyncThunk(
    'MerchandisingRules/GetShopLocales',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const locales = await catalogManagerService.getAvailableLocales({
          shopId,
        });
        return locales;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
