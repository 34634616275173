export interface RGBColor {
  r: number;
  g: number;
  b: number;
  a?: number;
}

export const componentToHex = (c: number): string => {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

export const handleUpdateColor = (
  updatedColor: { rgb: RGBColor },
  onChange: (color: string) => void
): void => {
  const { r, g, b, a } = updatedColor.rgb;
  const alphaHex = a !== undefined ? Math.round(a * 255).toString(16) : '';
  const newColor = `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}${alphaHex}`;
  onChange(newColor);
};
