/* eslint-disable class-methods-use-this */
import { AxiosResponse } from 'axios';
import { IShopGeneratedDataSettings } from '../types';

export class ShopGeneratedDataMapper {
  static mapGeneratedDataSettings({ data }: AxiosResponse): IShopGeneratedDataSettings {
    return {
      description: {
        isAutoGenerate: !!data?.description?.isAutoGenerate,
        toneOfVoice: data?.description?.toneOfVoice,
        textLength: data?.description?.textLength,
      },
      title: {
        isAutoGenerate: !!data?.title?.isAutoGenerate,
        toneOfVoice: data?.title?.toneOfVoice,
        textLength: data?.title?.textLength,
      },
    };
  }
}
