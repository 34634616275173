import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiKeys } from 'src/services';
import { UpdateApiKeyProps } from './types';

const apiKeysSettingsService = new ApiKeys();

export const shopApiKeysSettingsActions = {
  getApiKeys: createAsyncThunk(
    'ShopGeneralSettings/Get.ApiKeys',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const { apiKeys } = await apiKeysSettingsService.getApiKeys(shopId);
        return apiKeys;
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  createApiKey: createAsyncThunk(
    'ShopGeneralSettings/Create.ApiKeys',
    async (
      { shopId, label, expiration }: { shopId: number; label: string; expiration?: Date },
      { rejectWithValue }
    ) => {
      try {
        return await apiKeysSettingsService.createApiKey(
          shopId,
          expiration ? { label, expiration } : { label }
        );
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  updateApiKey: createAsyncThunk(
    'ShopGeneralSettings/Update.ApiKeys',
    async ({ shopId, apiKeyId, label, expiration }: UpdateApiKeyProps, { rejectWithValue }) => {
      try {
        return await apiKeysSettingsService.updateApiKey(shopId, apiKeyId, { label, expiration });
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  deleteApiKey: createAsyncThunk(
    'ShopGeneralSettings/Delete.ApiKeys',
    async ({ shopId, apiKeyId }: { shopId: number; apiKeyId: string }, { rejectWithValue }) => {
      try {
        return await apiKeysSettingsService.deleteApiKey(shopId, apiKeyId);
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
  copyToClipboard: createAsyncThunk('ShopGeneralSettings/Copy.ApiKeys', async () => true),
};
