import React, { useCallback } from 'react';
import { SelectOnChangeEvent, SelectType, Tooltip } from 'src/components-dummy';
import { FilterSortBy, sortByTranslations } from 'src/services';
import { MAX_ALLOWED_ACTIVE_CUSTOM_ORDER_FILTERS } from '../../../../constants';
import { MenuItemStyled, SortOptionsBySelectStyled } from './DisplayOptionsSection.styles';

interface SortOptionsBySelectProps {
  sortBy: FilterSortBy | undefined;
  customOrderOptionDisabled: boolean;
  onChange: (updatedSortBy: FilterSortBy) => void;
  error: string | undefined;
}

const sortByOptions = Object.entries(sortByTranslations).map(([value, text]) => ({ text, value }));

export const SortOptionsBySelect = ({
  sortBy,
  customOrderOptionDisabled,
  onChange,
  error,
}: SortOptionsBySelectProps): JSX.Element => {
  const isMenuItemDisabled = useCallback(
    (menuItemValue: FilterSortBy) =>
      menuItemValue === FilterSortBy.CustomOrder && customOrderOptionDisabled,
    [customOrderOptionDisabled]
  );

  const onSelect: SelectOnChangeEvent = useCallback(
    event => {
      const newValue = event.target.value as FilterSortBy;

      if (isMenuItemDisabled(newValue)) {
        return;
      }

      onChange(newValue);
    },
    [onChange, isMenuItemDisabled]
  );

  return (
    <SortOptionsBySelectStyled
      type={SelectType.Primary}
      label='Arrange filter values by'
      value={sortBy}
      onChange={onSelect}
      isError={!!error}
      errorMessage={error}
    >
      {sortByOptions.map(({ text, value }) => {
        const menuItemDisabled = isMenuItemDisabled(value as FilterSortBy);
        return (
          <MenuItemStyled key={value} value={value} isDisabled={menuItemDisabled}>
            <Tooltip
              value={`The maximum limit for filters with custom arrangement is ${MAX_ALLOWED_ACTIVE_CUSTOM_ORDER_FILTERS}`}
              position='bottom center'
              disabled={!menuItemDisabled}
            >
              {text}
            </Tooltip>
          </MenuItemStyled>
        );
      })}
    </SortOptionsBySelectStyled>
  );
};
