import { createAsyncThunk } from '@reduxjs/toolkit';
import { MetaLanguage } from 'src/app-state-types';
import { LexiconMetadataView } from 'src/components-bl/Lexicon/Lexicon.types';
import { lexiconService } from 'src/services';

/** Shared lexicon action creates.
 *  Using createAsyncThunk that allows using same action logic with custom action type, to distinguish actions */
export const lexiconSharedActionCreators = {
  getLexiconMetadata(actionType: string) {
    return createAsyncThunk(
      actionType,
      async (
        { shopId, lexiconLanguages }: { shopId: number; lexiconLanguages: MetaLanguage[] },
        { rejectWithValue }
      ) => {
        try {
          const lexiconsMetadataList = await lexiconService.getLexiconMetadataList({ shopId });

          const lexiconMetadataListWithLanguage = lexiconsMetadataList.map(metadata => {
            const lexiconLanguage = lexiconLanguages.find(
              language => language.locale === metadata.locale
            );

            const lexiconView: LexiconMetadataView = {
              ...metadata,
              iconName: lexiconLanguage?.iconName,
              displayName: lexiconLanguage?.displayName || metadata.locale,
            };

            return lexiconView;
          });

          return { lexiconMetadataList: lexiconMetadataListWithLanguage };
        } catch (error) {
          return rejectWithValue({ error });
        }
      }
    );
  },
};
