import React from 'react';
import { AvailableIcons } from 'src/components-dummy/Icon';
import {
  ProductImageContainer,
  ProductIndex,
  ProductIsPinnedIndicator,
  StyledImage,
} from './ProductTile.styles';

interface ProductImageProps {
  imageUrl: string;
  index: number;
  isPinned?: boolean;
}

export function ProductImage({ imageUrl, index, isPinned }: ProductImageProps): JSX.Element {
  return (
    <ProductImageContainer>
      <StyledImage src={imageUrl} alt='Product thumb' />
      <ProductIndex>{index}</ProductIndex>
      {isPinned && <ProductIsPinnedIndicator name={AvailableIcons.Pin} />}
    </ProductImageContainer>
  );
}
