import React from 'react';
import { css } from '@emotion/react';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy/Typography';
import { EditedTextStyled } from '../LexiconTable.styles';

export const LexiconStatusText = ({ status }: { status: 'edited' | 'draft' }): JSX.Element => (
  <EditedTextStyled
    css={css`
      margin-left: 4px;
    `}
  >
    <Typography type={TypographyType.Body} variant={TypographyVariant.MediumRegular}>
      ({status})
    </Typography>
  </EditedTextStyled>
);
