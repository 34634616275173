import { generatePath } from 'react-router-dom';
import { collectionNavigationActionMethods, Dispatch } from '../../components-bl';
import { collectionsRoutes } from '../../app-routes';

interface UseEditCollection {
  shopId: number;
  dispatch: Dispatch;
}

type EditCollectionCallback = (dispatch: Dispatch) => void;

interface UseEditCollectionReturnType {
  onEditCollection: (collectionId: string) => void;
}

export const useEditCollection = ({
  shopId,
  dispatch,
}: UseEditCollection): UseEditCollectionReturnType => {
  const editCollection = (cb: EditCollectionCallback) => {
    cb(dispatch);
  };

  const navigateToEditCollection = (collectionId: string) => {
    dispatch(
      collectionNavigationActionMethods.navigateTo({
        navigateTo: generatePath(collectionsRoutes.editCollectionRules.path, {
          collectionId,
          shopId,
        }),
      })
    );
  };

  const onEditCollection = (collectionId: string) => {
    editCollection(() => navigateToEditCollection(collectionId));
  };

  return {
    onEditCollection,
  };
};
