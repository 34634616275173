import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SearchInput } from '../SearchInput';

export const SelectModalContentStyled = styled.div`
  width: 560px;
  border-radius: 16px;
`;

/**
 * Header
 */
export const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 11px 24px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.custom['gray-60']};
`;

/**
 * Content
 */
export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(70vh - 187px);
  /* min-height: 340px; */
  padding: 16px 40px;
  margin-top: 12px;
`;

export const SelectValueTextStyled = styled.div`
  padding-top: 12px;
  margin-top: 12px;
`;

export const ItemsListStyled = styled.ul<{ enableScroll?: boolean }>`
  margin-top: 12px;
  /* height: 208px; */
  max-height: 190px;
  padding: 0;
  overflow-y: hidden;
  overflow-x: hidden;

  ${({ enableScroll }) =>
    enableScroll &&
    css`
      &:hover {
        overflow-y: scroll;
      }
    `}
`;

/**
 * Items
 */
export const ItemStyled = styled.li<{ isSelected?: boolean }>`
  padding: 12px;
  cursor: pointer;
  list-style-type: none;

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background: ${theme.palette.custom['primary-90']};
    `}

  &:hover {
    background: ${({ theme }) => theme.palette.custom['primary-90']};
  }

  &:active {
    background: ${({ theme }) => theme.palette.custom['primary-80']};
  }
`;

export const MessageStyled = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.custom['gray-30']};

  & > * {
    margin-bottom: 8px;
  }
`;

/**
 * Search
 */
export const SearchInputStyled = styled(SearchInput)``;

export const SpinnerWrapperStyled = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-content: center;

  .MuiBox-root {
    justify-content: center;
  }
`;
