import styled from '@emotion/styled';
import { Accordion, Button, Skeleton } from 'src/components-dummy';

const sidePaddingSize = '20px';
const headerSize = '72px';

export const EditGallerySideBarStyled = styled.div`
  width: 100%;
  height: 100%;
  box-shadow:
    0px 6px 12px -6px rgba(0, 0, 0, 0.12),
    0px 8px 24px -4px rgba(0, 0, 0, 0.08);

  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const SideBarHeader = styled.div`
  height: ${headerSize};
  padding: 16px;
  margin: 0 ${sidePaddingSize};

  border-bottom: 1px solid ${({ theme }) => theme.palette.custom['gray-80']};

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSkeleton = styled(Skeleton)`
  margin-top: 24px;
`;

export const BackButtonStyled = styled(Button)`
  color: ${({ theme }) => theme.palette.custom['primary-main']};

  .MuiButton-startIcon {
    width: 16px;
  }

  &:hover {
    background-color: transparent;
  }
`;

export const AccordionStyled = styled(Accordion)`
  padding: 0 ${sidePaddingSize};
  overflow-y: scroll;
  max-height: calc(100% - ${headerSize} - 5px);

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.custom['gray-70']};
  }

  ::-webkit-scrollbar-track {
    background: rgba(235, 235, 235, 0.5);
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
`;
