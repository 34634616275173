import { useEffect } from 'react';
import {
  merchandisingRulesWebSocketService,
  MerchandisingRuleDeleteEvent,
  MerchandisingRuleUpdateEvent,
} from 'src/services';
import { Dispatch } from 'src/components-bl';
import { useMerchandisingRulesWSActions } from './useMerchandisingRulesWS.actions';

export const useMerchandisingRulesWS = ({
  dispatch,
  shopId,
  selectedVariantId,
}: {
  dispatch: Dispatch;
  shopId: number | undefined;
  selectedVariantId: string | undefined;
}): void => {
  const issueToastNotification = (
    payload: MerchandisingRuleDeleteEvent | MerchandisingRuleUpdateEvent,
    customMessage: string
  ) => {
    if (payload.eventIssuedByCurrentUser) return;

    dispatch(useMerchandisingRulesWSActions.notification({ customMessage }));
  };

  const onRuleCreate = (payload: MerchandisingRuleUpdateEvent) => {
    if (shopId === payload.data.shopId) {
      const title = (payload as MerchandisingRuleUpdateEvent).data.rule.name;

      let customMessage = `New merchandising rule was created: ${title}`;

      if (payload.data.variantId) {
        customMessage = `New merchandising rule '${title}' was created in experiment`;
      } else if (payload.data.rule.entityId) {
        customMessage = `New merchandising rule '${title}' was created in collections`;
      }

      issueToastNotification(payload, customMessage);
    }
  };

  const onRuleUpdate = (payload: MerchandisingRuleUpdateEvent) => {
    if (shopId === payload.data.shopId) {
      const title = (payload as MerchandisingRuleUpdateEvent).data.rule.name;

      let customMessage = `Merchandising rule was updated: ${title}`;

      if (payload.data.variantId) {
        customMessage = `Merchandising rule '${title}' was updated in experiment`;
      } else if (payload.data.rule.entityId) {
        customMessage = `Merchandising rule '${title}' was updated in collections`;
      }

      issueToastNotification(payload, customMessage);
    }
  };

  const onRuleDelete = (payload: MerchandisingRuleDeleteEvent) => {
    if (shopId === payload.data.shopId) {
      if (payload.data.variantId === selectedVariantId) {
        dispatch(useMerchandisingRulesWSActions.delete({ ruleId: payload.data.ruleId }));
      }

      let customMessage = `Merchandising rule was deleted`;

      if (payload.data.variantId) {
        customMessage = `Merchandising rule was deleted in experiment`;
      }

      issueToastNotification(payload, customMessage);
    }
  };

  useEffect(() => {
    merchandisingRulesWebSocketService.subscribeToRuleCreateEvents(onRuleCreate);
    merchandisingRulesWebSocketService.subscribeToRuleUpdateEvents(onRuleUpdate);
    merchandisingRulesWebSocketService.subscribeToRuleDeleteEvents(onRuleDelete);

    return () => {
      merchandisingRulesWebSocketService.clearListeners();
    };
  }, [shopId, selectedVariantId]);
};
