import { EnableUiTest } from 'src/services/src/service/types/shops/shop';
import { EntityDiffSchema } from '../../types';

type EnableUiTestDiffSchema = EnableUiTest;

export const enableUiTestDiffSchema: EntityDiffSchema<EnableUiTestDiffSchema, EnableUiTest> = {
  enableUiTest: {
    displayName: 'Ui Test',
  },
};
