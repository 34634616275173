import React from 'react';
import type { ColumnName } from '../types';
import './Cell.scss';

interface Props {
  title: ColumnName;
  value: string | number | null;
}

export const Cell: React.FC<Props> = ({ title, value }) => (
  <div className='ui-template-card-cell'>
    <div className='ui-template-card-cell-title'>{title}</div>
    <div className='ui-template-card-cell-value'>{value}</div>
  </div>
);
