import React, { useEffect, useState } from 'react';
import { Typography, TypographyType, TypographyVariant } from 'src/components-dummy';
import {
  FormBody,
  FormContainer,
  FormItem,
  FormSwitch,
  FormTitle,
  Tooltip,
} from 'src/components-dummy/SettingsForm';
import { Dispatch } from '../types';
import { textSimilarityToggleActions } from './TextSimilarityToggle.actions';
import { TextSimilarityDescriptionContainer } from './TextSimilarityToggle.styles';

interface TextSimilarityToggleProps {
  enableTextSimilarity: boolean;
  shopId: number;
  dispatch: Dispatch;
  onChange: (enableTextSimilarity: boolean) => void;
}

export function TextSimilarityToggle({
  enableTextSimilarity,
  shopId,
  dispatch,
  onChange,
}: TextSimilarityToggleProps): JSX.Element {
  const [isTextSimilarityAvailable, setIsTextSimilarityAvailable] = useState(false);

  const disabled = !isTextSimilarityAvailable;

  useEffect(() => {
    (dispatch(textSimilarityToggleActions.getTextSimilarity({ shopId })) as any)
      .unwrap()
      .then((isTextSimilarityAvailableResponse: boolean) =>
        setIsTextSimilarityAvailable(isTextSimilarityAvailableResponse)
      )
      .catch((error: any) => {
        // eslint-disable-next-line no-console
        console.error('TextSimilarityToggle', error);
      });
  }, [shopId, dispatch, setIsTextSimilarityAvailable]);

  const tooltip = (
    <Tooltip.Text>
      Text similarity should be enabled in a primary catalog in order to enable it here.
    </Tooltip.Text>
  );

  return (
    <FormContainer>
      <FormTitle tip={disabled ? tooltip : null}>Text Similarity</FormTitle>
      <FormBody divider>
        <FormItem>
          <FormSwitch
            label='Enable text similarity'
            checked={enableTextSimilarity}
            onChange={onChange}
            disabled={disabled}
          />
        </FormItem>
        <FormItem>
          <TextSimilarityDescriptionContainer>
            <Typography type={TypographyType.Paragraph} variant={TypographyVariant.SmallRegular}>
              Enable &apos;Text Similarity&apos; to take into account item titles when assessing
              similarity.
            </Typography>
            <Typography type={TypographyType.Paragraph} variant={TypographyVariant.SmallRegular}>
              Note that enabling this option will temporarily disable merchandising rules for this
              specific experience.
            </Typography>
          </TextSimilarityDescriptionContainer>
        </FormItem>
      </FormBody>
    </FormContainer>
  );
}
