import { addSeparatorsToNumber } from '../CatalogExplorer.helpers';

export const buildSelectedOptionObject = ({
  value,
  total,
}: {
  value: string | number;
  total: number;
}): { value: string; text: string } => {
  return {
    value: value.toString(),
    text: `${value} (${addSeparatorsToNumber(total)})`,
  };
};
