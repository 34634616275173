import Joi from 'joi';

export const overrideImageUrlValidationSchema = {
  active: Joi.boolean().required(),
  attribute: Joi.when('active', {
    is: Joi.boolean().valid('true'),
    then: Joi.string().required(),
  }),
  selector: Joi.when('active', {
    is: Joi.boolean().valid('true'),
    then: Joi.string().required(),
  }),
};
export const removeDuplicatesValidationSchema = {
  enable: Joi.boolean().required(),
  prioritizedCarousel: Joi.array().items(Joi.string()).required(),
};

export const addToCartValidationSchema = Joi.object({
  colorVariants: Joi.object({
    active: Joi.boolean().required(),
    displayShape: Joi.when('active', {
      is: true,
      then: Joi.string().required(),
    }),
  }),
  sizeVariants: Joi.object({
    active: Joi.boolean().required(),
    displayType: Joi.when('active', {
      is: true,
      then: Joi.string().required(),
    }),
  }),
});

export const recEnginesGeneralSettingsValidationSchema = {
  offerNavOpenResultsInNewTabDesktop: Joi.boolean().required(),
  offerNavOpenResultsInNewTabMobile: Joi.boolean().required(),
  overrideImageUrl: Joi.object().keys(overrideImageUrlValidationSchema),
  removeDuplicates: Joi.object().keys(removeDuplicatesValidationSchema),
  addToCart: addToCartValidationSchema,
};
