import React from 'react';
import { TableV2, TableV2InstanceProps } from '../../../components-dummy';
import { ShopsTableHeadRow } from './types';

interface Props {
  headers: TableV2InstanceProps<ShopsTableHeadRow>['headerGroups'][0]['headers'];
  columns: ShopsTableHeadRow[];
}

export const TableHeaderColumns: React.FC<Props> = ({ headers, columns }) => {
  return (
    <>
      {headers.map((column, index) => (
        <TableV2.HeadRow
          {...column.getHeaderProps()}
          width={columns[index].width}
          minWidth={columns[index].minWidth}
          key={column.id}
        >
          <TableV2.HeadRowCellText>{column.render('header')}</TableV2.HeadRowCellText>
        </TableV2.HeadRow>
      ))}
    </>
  );
};
