import React from 'react';
import { Accordion } from 'src/components-dummy';
import { ExperimentCard } from '../ExperimentCard';
import { useExperimentList } from './useExperimentList';
import { ExperimentListProps } from './types';
import './ExperimentList.scss';
import { ExperimentCardVariantTable } from './components';

export const ExperimentList = ({
  experiments,
  shopId,
  dispatch,
  runningExperimentId,
  searchQuery,
  experimentFormRoute,
}: ExperimentListProps): JSX.Element => {
  const { experimentItems } = useExperimentList({
    experiments,
    shopId,
    dispatch,
  });

  const accordionItems = experimentItems.length
    ? experimentItems.map(experiment => {
        return (
          <Accordion.Item
            id={experiment.id}
            key={experiment.id}
            className='syte-experiments-list-accordion-item'
          >
            <Accordion.Item.Header>
              <ExperimentCard
                experiment={experiment}
                dispatch={dispatch}
                shopId={shopId}
                runningExperimentId={runningExperimentId}
                experimentFormRoute={experimentFormRoute}
              />
            </Accordion.Item.Header>
            <Accordion.Item.Content>
              <ExperimentCardVariantTable experimentItem={experiment} />
            </Accordion.Item.Content>
          </Accordion.Item>
        );
      })
    : [];

  return (
    <div className='syte-experiments-list'>
      {experimentItems.length ? (
        <Accordion expandedIds={experimentItems[0].id}>{accordionItems}</Accordion>
      ) : (
        <div className='no-experiments'>
          {searchQuery?.trim().length ? (
            <>
              No experiments found.
              <br />
              Try different ID or publication number
            </>
          ) : (
            'There are no experiments yet'
          )}
        </div>
      )}
    </div>
  );
};
