import React from 'react';
import Joi from 'joi';
import { EditableText, EditableTextProps } from 'src/components-dummy';
import {
  DeepTagProductEditableTitleStyled,
  ErrorLabelStyled,
  ErrorsMessagesWrapperStyled,
} from './DeepTagProductEditableTitle.style';

/**
 * Constants / Validators
 */
const MIN_CHARACTERS = 2;
const MAX_CHARACTERS = 75;

const MIN_CHARACTERS_MESSAGE = `Product title should be at least ${MIN_CHARACTERS} characters long`;

const textValidator = () =>
  Joi.string()
    .required()
    .trim()
    .min(MIN_CHARACTERS)
    .max(MAX_CHARACTERS)
    .messages({
      'string.empty': MIN_CHARACTERS_MESSAGE,
      'string.min': MIN_CHARACTERS_MESSAGE,
      'string.max': `Product title is limited to ${MAX_CHARACTERS} characters`,
    });

/**
 * Main
 */
interface DeepTagProductEditableTitleProps {
  title: string;
  dynamicWidthMultiplier: number;
  onChange: ({ value }: { value: string }) => Promise<unknown>;
  renderText: EditableTextProps['renderText'];
}

export const DeepTagProductEditableTitle = React.memo(
  ({
    title,
    onChange,
    dynamicWidthMultiplier,
    renderText,
  }: DeepTagProductEditableTitleProps): JSX.Element => {
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [isInProcess, setIsInProcess] = React.useState<boolean>(false);

    const onValidateTextInput = (inputText: string): boolean => {
      const validation = textValidator().validate(inputText || '');

      if (validation.error) {
        setErrorMessage(validation.error.message);
        return false;
      }

      /* Clear error message */
      if (errorMessage) {
        setErrorMessage(undefined);
      }

      return true;
    };

    const handleChange: EditableTextProps['onChange'] = async ({ value, resetValue }) => {
      if (value === title) {
        return;
      }

      setIsInProcess(true);

      try {
        const response = await (onChange({ value }) as any);

        if (response.error) {
          resetValue();
        }
      } catch (error: unknown) {
        resetValue();
      } finally {
        setIsInProcess(false);
      }
    };

    return (
      <DeepTagProductEditableTitleStyled>
        <EditableText
          key={title}
          currentText={title || ''}
          onChange={handleChange}
          onValidateTextInput={onValidateTextInput}
          isWithDynamicWidth
          readOnlyHoverTooltipText={isInProcess ? 'Updating value' : 'Rename'}
          renderText={renderText}
          dynamicWidthMultiplier={dynamicWidthMultiplier}
          isLoading={isInProcess}
        />
        {errorMessage && (
          <ErrorsMessagesWrapperStyled>
            <ErrorLabelStyled> {errorMessage}</ErrorLabelStyled>
          </ErrorsMessagesWrapperStyled>
        )}
      </DeepTagProductEditableTitleStyled>
    );
  }
);
