import { useCallback } from 'react';
import { Dispatch } from 'src/components-bl/types';
import { reviewVersionActions } from './Actions';

export interface UseShowVersionReviewModalProps {
  dispatch: Dispatch;
  shopId: number | undefined;
}

export const useShowVersionReviewModal = ({
  dispatch,
  shopId,
}: UseShowVersionReviewModalProps): {
  getVersion: (id: string) => void;
  reset: () => void;
} => {
  const reset = useCallback(() => {
    dispatch(reviewVersionActions.resetSelectedVersion());
  }, [dispatch]);

  const getVersion = useCallback(
    async (versionId: string) => {
      if (!shopId) {
        console.warn(`useShowVersionReviewModal - getVersion(): shopId is missing. Skipping`);
        return;
      }
      try {
        await (dispatch(reviewVersionActions.getVersion({ shopId, versionId })) as any).unwrap();
      } catch (error) {
        reset();
      }
    },
    [shopId, dispatch, reset]
  );

  return { getVersion, reset };
};
