import { ExperimentStatus } from 'src/services';

export function getExperimentStatusClass(experimentStatus: ExperimentStatus): string {
  switch (experimentStatus) {
    case ExperimentStatus.Created:
      return 'experiment-status-color-created';
    case ExperimentStatus.Running:
      return 'experiment-status-color-running';
    case ExperimentStatus.Ended:
      return 'experiment-status-color-ended';
    default:
      return '';
  }
}
