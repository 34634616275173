import { useReducer, useEffect, useMemo, useState, useImperativeHandle, RefObject } from 'react';
import { ReOrderedList } from 'src/components-dummy';
import { UpdateReqStatus } from 'src/app-state-types';
import { ListValidityMap } from 'src/components-bl/helpers/form-validation';
import { FormApiRef } from 'src/components-bl/AugmentedSearch';
import { useFiltersListValidator } from './use-filters-list-validator';
import {
  EditableFilterItem,
  FilterItemDBStructure,
  ListOperations,
  OnChangePayload,
} from '../types';

import { useListChangeTracker } from './use-change-tracker';
import { useExpendableList, ExpendedItemsMap } from './use-expendable-list';
import { filtersListReducer } from '../filters-list-reducer';
import { filtersListActions } from '../filters-list-actions';
import { getFormDraftFromSavedData, onFormSubmit, onItemChange } from '../methods';
import { ShopDataField } from '../../../services/src/service/types';

interface FormActions {
  onOrderChanged: (newList: ReOrderedList) => void;
  deleteItem: (itemKey: string) => void;
  toggleItemVisibility: (itemKey: string) => void;
  itemChangeHandler: (itemKey: string, updates: OnChangePayload) => void;
}
interface UseFiltersListManagerReturns {
  formActions: FormActions;
  handleFilterChange: (updatedFiltersList: EditableFilterItem[]) => void;
  discardItemChanges: (itemKey: string) => void;
  listValidity: ListValidityMap<EditableFilterItem>;
  expendedItems: ExpendedItemsMap;
  setIsFormOpen: (itemKey: string, isOpen: boolean) => void;
  saveItem: (itemKey: string) => void;
  addItem: () => void;
  listDraft: EditableFilterItem[];
  isHiddenFilterSoftAlertShown: (itemKey: string) => boolean;
  dataFields: ShopDataField[];
}

export const useFiltersListManager = (
  initialListItems: FilterItemDBStructure[],
  onSubmit: (listDraft: EditableFilterItem[], diff: FilterItemDBStructure[]) => void,
  isDirtyDispatcher: (val: boolean) => void,
  hasExternalDiff: boolean,
  listActionOptions: ListOperations,
  updateState: UpdateReqStatus,
  rawDataFields: ShopDataField[],
  unSavedDataFields: ShopDataField[],
  shouldIncludeDisplayName: boolean,
  formApiRef: RefObject<FormApiRef>,
  onSubmitStatusChange: (canSubmit: boolean) => void
): UseFiltersListManagerReturns => {
  const [dataFields, setDataFields] = useState(unSavedDataFields);
  useEffect(() => {
    setDataFields(unSavedDataFields);
  }, [unSavedDataFields]);
  const editableItems = useMemo(
    () => getFormDraftFromSavedData(initialListItems),
    [initialListItems]
  );
  const [listDraft, dispatcher] = useReducer(filtersListReducer, {
    items: editableItems,
  });
  const { validator, setValidatedItem } = useFiltersListValidator({
    listDraft: listDraft.items,
    shouldIncludeDisplayName,
  });
  const { expendedItems, toggleIsItemExpended, closeAll } = useExpendableList(listDraft.items);
  const { isDirty, registerListChange, diff, getItemDiff } = useListChangeTracker(
    editableItems,
    isDirtyDispatcher,
    hasExternalDiff,
    updateState
  );

  useEffect(() => {
    onSubmitStatusChange(isDirty);
  }, [isDirty, onSubmitStatusChange]);

  useEffect(() => {
    closeAll();
    dispatcher(filtersListActions.formInit(editableItems));
    validator.refresh(editableItems);
  }, [initialListItems]);
  useEffect(() => {
    if (listDraft.lastRegisteredAction) {
      registerListChange(listDraft.items);
    }
  }, [listDraft]);

  const onOrderChanged = (newList: ReOrderedList) => {
    const updatedList: EditableFilterItem[] = [];
    newList.forEach(({ key }, index) => {
      const nextListItem = listDraft.items.find(filter => filter.key === key);
      if (nextListItem) {
        updatedList.push({ ...nextListItem, position: index + 1 });
      }
    });
    dispatcher(filtersListActions.updateList(updatedList));
  };

  const itemChangeHandler = (itemKey: string, updates: OnChangePayload) => {
    const { updatedList, updatedItem } = onItemChange(listDraft.items, itemKey, updates);
    if (updatedItem) {
      validator.validateListItem(itemKey, updatedItem, [updates.changedProperty]);
    }
    dispatcher(filtersListActions.updateList(updatedList));
  };

  const toggleItemVisibility = (itemKey: string): void => {
    const item = listDraft.items.find(li => li.key === itemKey);
    itemChangeHandler(itemKey, { changedProperty: 'enabled', newValue: !item?.enabled });
  };

  const deleteItem = (itemKey: string) => {
    const item = listDraft.items.find(li => li.key === itemKey);
    const dataSource = item?.dataSource;
    const rawDataField = rawDataFields.find(({ name }) => name === dataSource);
    const updatedDataFields = dataFields;
    updatedDataFields.push(rawDataField as ShopDataField);
    setDataFields(updatedDataFields);
    dispatcher(filtersListActions.deleteItem(itemKey));
    validator.removeInstance(itemKey);
  };

  const onSubmitClicked = () => {
    const { shouldPerformSubmit, formData } = onFormSubmit(
      listDraft.items,
      listActionOptions.allowDisableAll,
      validator.isValid
    );
    if (shouldPerformSubmit) {
      onSubmit(formData, diff as FilterItemDBStructure[]);
    }
  };

  useImperativeHandle(
    formApiRef,
    () => {
      return {
        submit: async () => onSubmitClicked(),
      };
    },
    [onSubmitClicked]
  );

  const handleListSearch = (list: EditableFilterItem[]) => {
    dispatcher(filtersListActions.updateList(list));
  };

  const addItem = () => {
    dispatcher(
      filtersListActions.addNew(newItem => {
        toggleIsItemExpended(newItem.key, true);
        validator.addInstance(newItem);
      })
    );
  };

  const discardItemChanges = (itemKey: string) => {
    const originalItem = initialListItems.find(li => li.key === itemKey);
    dispatcher(filtersListActions.dismissItemForm({ itemKey, originalItem }));
    validator.reset(itemKey);
    toggleIsItemExpended(itemKey, false);
  };

  const saveItem = (itemKey: string) => {
    const item = listDraft.items.find(li => li.key === itemKey);
    if (!item) return;
    setValidatedItem(item.key);
    validator.validateListItem(item.key, item);
    validator.validateListForDuplication(listDraft.items, item);
    if (validator.isValid(itemKey)) {
      const { dataSource } = item;
      const updatedDataFields = dataFields.filter(({ name }) => name !== dataSource);
      setDataFields(updatedDataFields);
      dispatcher(
        filtersListActions.saveItem({
          item,
          cb: savedItemKey => {
            toggleIsItemExpended(savedItemKey, false);
          },
        })
      );
    }
  };

  const isHiddenFilterSoftAlertShown = (itemKey: string): boolean => {
    const isItemDisabled = listDraft.items.find(li => li.key === itemKey)?.enabled === false;
    if (!isDirty || !isItemDisabled) return false;
    const itemDiff = getItemDiff(itemKey) || {};
    const isDiffRelevant = Object.keys(itemDiff).filter(field => field !== 'enabled').length > 0;
    return isItemDisabled && isDiffRelevant;
  };

  return {
    formActions: {
      onOrderChanged,
      deleteItem,
      itemChangeHandler,
      toggleItemVisibility,
    },
    expendedItems,
    discardItemChanges,
    setIsFormOpen: toggleIsItemExpended,
    handleFilterChange: handleListSearch,
    listValidity: validator.state,
    addItem,
    saveItem,
    listDraft: listDraft.items,
    isHiddenFilterSoftAlertShown,
    dataFields,
  };
};
