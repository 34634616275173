import React from 'react';
import styled from '@emotion/styled';
import {
  Icon,
  Typography,
  TypographyProps,
  TypographyType,
  TypographyVariant,
} from '../../../../../components-dummy';

export const DataFieldsListStyled = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #dcdcdc;
  border-radius: 12px;
  padding: 16px;
  margin-top: 16px;
  gap: 16px;
`;

export const DataFieldsItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin: 6px 0;
`;

export const DataFieldsItemRow = styled.div`
  display: flex;
  align-items: center;
`;

export const DataFieldsItemRowErrors = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 0 40px;
`;

export const IconStyled = styled(Icon)`
  width: 40px;
`;

export const ErrorLabelStyled = styled((props: TypographyProps) => (
  <Typography {...props} type={TypographyType.Paragraph} variant={TypographyVariant.SmallRegular} />
))`
  color: ${({ theme }) => theme.palette.custom.red};
  margin-top: 8px;
`;

export const DataFieldsListContainer = styled.div`
  margin-top: 24px;
`;
