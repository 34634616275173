import { createAsyncThunk } from '@reduxjs/toolkit';
import { CatalogManagerService } from 'src/services';

const catalogManagerService = new CatalogManagerService();

export const catalogManagerContainerActions = {
  getCatalogs: createAsyncThunk(
    'CatalogManagerContainerActions/GetCatalogs',
    async ({ shopId }: { shopId: number }, { rejectWithValue }) => {
      try {
        const catalogs = await catalogManagerService.getCatalogs(shopId);
        return { catalogs };
      } catch (error) {
        return rejectWithValue({ error });
      }
    }
  ),
};
