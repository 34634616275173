import { useEffect, useRef, useState } from 'react';

export interface UseInViewPortArguments {
  options?: IntersectionObserverInit;
}

export interface UseInViewPortReturnType {
  isInView: boolean;
  setRef: (el: Element | null) => void;
  ref: Element | null;
}

export const useIsInViewPort = ({ options }: UseInViewPortArguments): UseInViewPortReturnType => {
  const [ref, setRef] = useState<Element | null>(null);
  const [isInView, setIsInView] = useState(false);
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new IntersectionObserver(([entry]) => {
      setIsInView(entry.isIntersecting);
    }, options);

    if (ref) {
      observerRef.current.observe(ref);
    }
    return () => {
      if (ref && observerRef.current) {
        observerRef.current.unobserve(ref);
      }

      observerRef.current?.disconnect();
    };
  }, [ref, options]);

  return { isInView, setRef, ref };
};
