import { MutableRefObject, useEffect, useRef } from 'react';

/** Hook that returns a ref that determines if component has already mounted and if it's unmounting
 * hasMounted is intended for useEffects that need to check if it was first time they were triggered, since they always trigger on mounting and on changes.
 * isUnmounting is intended for async operations that need to check whether to update a locale state or not, to avoid errors.
 * Note this is not a state, but a ref, and doesn't trigger rerenders.
 * Try find other solutions before using this hook. such as cases
 */
export const useMountRef = (): MutableRefObject<{
  hasMounted: boolean;
  hasUnmounted: boolean;
}> => {
  const ref = useRef({ hasMounted: false, hasUnmounted: false });

  useEffect(() => {
    ref.current.hasMounted = true;

    return () => {
      ref.current.hasUnmounted = true;
    };
  }, []);

  return ref;
};
