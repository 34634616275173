export enum GalleryType {
  InspirationalImage = 'inspirational_images',
  CustomInspirations = 'custom_inspirations',
}

export interface IGalleryImage {
  imageUrl: string;
  productIds: string[];
}

export interface InspirationalImagesGallery {
  id: string;
  galleryName: string;
  galleryType: GalleryType;
  images: IGalleryImage[];
  updatedAt: Date;
}
