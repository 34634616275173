import { DataFieldToDisplay } from '../../../../types/data-field-to-display';
import { ShopDataField } from '../../../../../../services';

export const filterArray = (
  filterCriteria: DataFieldToDisplay[],
  data?: ShopDataField[]
): ShopDataField[] => {
  if (!data) return [];
  const filterValues = filterCriteria.map(criteria => criteria.value);
  return data.filter(item => !filterValues.includes(item.name));
};
