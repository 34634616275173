import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { versioningService } from 'src/services';

export const versionsTableActions = {
  getShopVersions: createAsyncThunk(
    'VersionsTable/GetShopVersions',
    async (shopId: number, { rejectWithValue }) => {
      try {
        const versions = await versioningService.getShopVersions({ shopId, slim: true });

        return versions;
      } catch (error) {
        console.error(error);
        return rejectWithValue({ shopId, error });
      }
    }
  ),
  resetVersions: createAction('VersionsTable/ResetVersions'),
  navigateTo: createAction<{ navigateTo: string }>('VersionsTable/NavigateTo'),
  restoreFromVersion: createAsyncThunk(
    'VersionsTable/RestoreFromVersion',
    async ({ versionId, shopId }: { shopId: number; versionId: string }, { rejectWithValue }) => {
      try {
        const draft = await versioningService.restoreFromVersion({
          shopId,
          id: versionId,
        });

        return draft;
      } catch (error) {
        console.error(error);
        return rejectWithValue({ shopId, error });
      }
    }
  ),
};
