import React from 'react';
import classNames from 'classnames';
import { AvailableIcons, Icon } from '../../../../components-dummy';
import './PostThumbnail.scss';

export interface PostThumbnailProps {
  imgSrc: string;
  isSelected?: boolean;
  onDelete: () => void;
}

export const PostThumbnail = ({
  imgSrc,
  onDelete,
  isSelected,
}: PostThumbnailProps): JSX.Element => {
  const handleDeleteIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete();
  };
  return (
    <div
      className={classNames('story-container', { selected: isSelected })}
      data-testid='story-container'
    >
      <img src={imgSrc} className='avatar-image' alt='Post thumbnail' />
      <div className='action-icon' onClick={handleDeleteIconClick} data-testid='delete-story'>
        <Icon name={AvailableIcons.TrashCan} />
      </div>
    </div>
  );
};
