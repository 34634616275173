export enum LexiconCategoryEditableTextActionTypes {
  NavigateTo = 'LexiconCategoryEditableText/NavigateTo',
}

export interface LexiconCategoryEditableTextNavigateToActionPayload {
  navigateTo: string;
}

export interface LexiconCategoryEditableTextNavigateToAction {
  type: typeof LexiconCategoryEditableTextActionTypes.NavigateTo;
  payload: LexiconCategoryEditableTextNavigateToActionPayload;
}

export type LexiconCategoryEditableTextAction = LexiconCategoryEditableTextNavigateToAction;
