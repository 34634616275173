import styled from '@emotion/styled';
import { Typography } from 'src/components-dummy';

export const EmptyStateStyled = styled.div<{ hide: boolean }>`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.common.white};
  max-width: 976px;
  height: 466px;

  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const ImageStyled = styled.img`
  width: 100%;
  max-width: 690px;
`;

export const TextWrapper = styled.div`
  justify-content: center;
  align-items: center;
  max-width: 350px;

  margin-right: 35px;
`;

export const SubTitleStyled = styled(Typography)`
  margin: 16px 0;
  color: ${({ theme }) => theme.palette.custom['gray-10']};
`;

export const ButtonsWrapperStyled = styled.div`
  display: flex;
  gap: 12px;
  justify-content: right;
`;
