import React from 'react';
import { useOperatorDropdown } from './useOperatorDropdown';
import { Select, SelectType, MenuItem, SelectOnChangeEvent } from '../../../../../components-dummy';
import { CategoryFilterRule } from '../../../types/category-filter-rule';

interface OperatorDropdownProps {
  data?: CategoryFilterRule;
  onChange: (rule: CategoryFilterRule) => void;
}

export const OperatorDropdown: React.FC<OperatorDropdownProps> = ({ data, onChange }) => {
  const { conditionDropDownValues, onOperatorChange } = useOperatorDropdown({
    data,
    onChange,
  });

  const handleChange: SelectOnChangeEvent = event => {
    onOperatorChange(event.target.value as CategoryFilterRule['operator']);
  };

  return (
    <Select
      type={SelectType.Menu}
      value={data?.operator}
      onChange={handleChange}
      disabled={!data?.field}
    >
      {conditionDropDownValues.map((option: { value?: string; text: string }) => (
        <MenuItem key={option.value} value={option.value}>
          {option.text}
        </MenuItem>
      ))}
    </Select>
  );
};
