import React, { useState, useEffect, useMemo } from 'react';
import {
  TypographyType,
  TypographyVariant,
  PopUp,
  MultiSelectMenuWithSearch,
  Button,
  MultiSelectValue,
  FilterTagFooterStyled,
  MultiSelectFilterTagProps,
  PopupPosition,
} from 'src/components-dummy';
import { sortBy } from 'lodash';
import { MultiSelectTagLabelStyled } from 'src/components-dummy/FiltersRow/components';
import {
  DeepTagsMultiSelectTriggerButtonStyled,
  DeepTagMultiSelectWrapperStyled,
  DeepTagMultiSelectContentStyled,
} from './DeepTagsMultiSelect.style';

export interface DeepTagProductMultipleSelectProps
  extends Pick<
    MultiSelectFilterTagProps,
    | 'filterKey'
    | 'filterState'
    | 'searchPlaceholder'
    | 'options'
    | 'enableSearch'
    | 'enableSearch'
    | 'enableToggleAll'
    | 'shouldTriggerOpen'
    | 'labelText'
  > {
  position?: PopupPosition;
  TriggerComponent: React.ReactElement;
  onApply: (data: string[]) => void;
}

export const DeepTagProductMultipleSelect = ({
  filterKey,
  onApply,
  filterState,
  searchPlaceholder,
  options,
  enableSearch,
  enableToggleAll,
  shouldTriggerOpen,
  labelText,
  position = 'bottom right',
  TriggerComponent,
}: DeepTagProductMultipleSelectProps): JSX.Element => {
  const [searchInputRef, setSearchInputRef] = useState<HTMLInputElement | null>(null);

  const valueToTextMap = useMemo(() => {
    return options.reduce(
      (accumulator, option) => {
        accumulator[option.value] = option.text;

        return accumulator;
      },
      {} as Record<MultiSelectValue, string>
    );
  }, [options]);

  const initialSelectedOptions = useMemo(
    () => (filterState?.value ? [...(filterState.value as string[])] : []),
    [filterState?.value]
  );

  const selectOptionsSorted = useMemo(() => {
    if (enableToggleAll) {
      const selectedOptionsSet = new Set(initialSelectedOptions);
      return sortBy(options, option => `${!selectedOptionsSet.has(option.value)}${option.value}`);
    }
    return options;
  }, [initialSelectedOptions, options, enableToggleAll]);

  const [selectedOptions, setSelectedOptions] = useState<string[]>(initialSelectedOptions);
  const [shouldShowPopup, setShouldShowPopup] = useState(false);

  const onMultipleSelectChange = (newOptions: string[]) => {
    setSelectedOptions([...newOptions]);
  };

  const onShowPopupChange = (show: boolean) => {
    setShouldShowPopup(show);

    if (!show) {
      setSelectedOptions([]);
    }
  };

  const onClose = () => {
    setShouldShowPopup(false);
  };

  const onSave = () => {
    onApply(selectedOptions);
    onClose();
  };

  const onCancel = () => {
    onShowPopupChange(false);
    setSelectedOptions(initialSelectedOptions);
    onClose();
  };

  // Sync incoming state with internal
  useEffect(() => {
    const currentStateSet = new Set(selectedOptions);

    const filteredOptionsByAvailability = initialSelectedOptions.filter(
      optionValue => valueToTextMap[optionValue]
    );

    const incomingStateIsEqual =
      currentStateSet.size === filteredOptionsByAvailability.length &&
      filteredOptionsByAvailability.every(option => currentStateSet.has(option));

    if (!incomingStateIsEqual) {
      setSelectedOptions(initialSelectedOptions);
    }
  }, [filterState, filterKey, valueToTextMap, initialSelectedOptions]);

  useEffect(() => {
    if (shouldTriggerOpen) {
      setShouldShowPopup(true);
    }
  }, [shouldTriggerOpen]);

  useEffect(() => {
    const shouldFocusOnSearch =
      enableSearch && shouldTriggerOpen && searchInputRef && selectedOptions.length === 0;

    if (shouldFocusOnSearch) {
      searchInputRef.focus();
    }
  }, [searchInputRef, selectedOptions.length, enableSearch, shouldTriggerOpen]);

  return (
    <PopUp
      show={shouldShowPopup}
      onShow={onShowPopupChange}
      closeOnTriggerClick={false}
      hideOnScroll={false}
      position={position}
    >
      <PopUp.Trigger>
        <DeepTagsMultiSelectTriggerButtonStyled isClickable clicked={shouldShowPopup}>
          {TriggerComponent}
        </DeepTagsMultiSelectTriggerButtonStyled>
      </PopUp.Trigger>

      <PopUp.Content>
        <DeepTagMultiSelectWrapperStyled>
          <DeepTagMultiSelectContentStyled>
            <MultiSelectTagLabelStyled
              type={TypographyType.Paragraph}
              variant={TypographyVariant.SmallRegular}
            >
              {labelText}
            </MultiSelectTagLabelStyled>
            <MultiSelectMenuWithSearch
              searchPlaceholder={searchPlaceholder}
              options={selectOptionsSorted}
              selectedOptions={selectedOptions}
              onChange={onMultipleSelectChange}
              enableSelectAll={enableToggleAll}
              searchInputRef={setSearchInputRef}
            />
          </DeepTagMultiSelectContentStyled>
          <FilterTagFooterStyled>
            <Button variant='tertiary' onClick={onCancel}>
              Cancel
            </Button>
            <Button onClick={onSave} disabled={selectedOptions.length === 0} variant='primary'>
              Apply
            </Button>
          </FilterTagFooterStyled>
        </DeepTagMultiSelectWrapperStyled>
      </PopUp.Content>
    </PopUp>
  );
};
