import { useEffect, useState, useMemo, useCallback } from 'react';
import { filter, debounce } from 'lodash';
import { useHistory, useLocation } from 'react-router';
import { sortArray, SortOption } from 'src/utils';
import { AccountTypes } from 'src/services';
import { searchAccounts } from './Actions';
import {
  UseAccountListSectionArguments,
  UseAccountListSectionReturnType,
  PushNewUrlArgs,
  SearchState,
} from './types';

const accountsLimit = 50;
const searchInputLengthLimit = 70;

const queryStringKey = 'accountSearch';

function trimSearchQuery(input: string) {
  return input.substring(0, searchInputLengthLimit);
}
export const useAccountListSection = ({
  accounts = [],
  dispatch,
}: UseAccountListSectionArguments): UseAccountListSectionReturnType => {
  const { push } = useHistory();
  const { pathname, search: queryString } = useLocation();
  const queryObject = new URLSearchParams(queryString);
  const currentQuery = queryObject.get(queryStringKey);
  const decodedQuery = currentQuery ? decodeURIComponent(currentQuery) : '';
  const trimmedQuery = trimSearchQuery(decodedQuery);

  const initialSearchState: SearchState = {
    searchQuery: trimmedQuery,
    includeArchived: true,
    sort: SortOption.Asc,
  };

  const [searchState, setSearchState] = useState(initialSearchState);

  const pushNewUrl = ({
    pathname: targetPathname,
    queryString: targetQueryString,
  }: PushNewUrlArgs): void => {
    const path = targetQueryString ? `${targetPathname}?${targetQueryString}` : pathname;
    push(path);
  };

  const pushIdToUrl = (id: string) => {
    if (id !== decodedQuery) {
      queryObject.set(queryStringKey, encodeURIComponent(id.trim()));

      const updateQueryParamsString = queryObject.toString();

      pushNewUrl({ pathname, queryString: updateQueryParamsString });
    }
  };

  const onSearchStateChange = (partialState: Partial<SearchState>) => {
    const updatedState = { ...searchState, ...partialState };

    if (partialState.searchQuery !== undefined) {
      const trimmedSearchQuery = trimSearchQuery(partialState.searchQuery);
      updatedState.searchQuery = trimmedSearchQuery;
    }

    setSearchState(updatedState);

    if (partialState.searchQuery !== undefined) {
      pushIdToUrl(updatedState.searchQuery);
    }
  };

  const loadMoreAccounts = useCallback(() => {
    const needToLoadMore = accounts.length % accountsLimit === 0;
    if (!needToLoadMore) {
      return;
    }

    const trimmedSearchInput = searchState.searchQuery.trim();
    dispatch(
      searchAccounts({
        searchTerm: trimmedSearchInput,
        includeArchived: searchState.includeArchived,
        limit: accountsLimit,
        skip: accounts.length,
        isLoadMore: true,
      })
    );
  }, [searchState, accounts]);

  const sortedAccounts = useMemo(() => {
    return sortArray({
      items: accounts,
      sortBy: ['accountName'],
      option: searchState.sort,
    });
  }, [searchState.sort, accounts]);

  const filteredAccounts = useMemo(() => {
    if (searchState.includeArchived) {
      return filter(
        sortedAccounts,
        acc => acc.accountStatus !== AccountTypes.AccountStatus.Archived
      );
    }
    return sortedAccounts;
  }, [searchState.includeArchived, sortedAccounts]);

  const debouncedSearch = useCallback(
    debounce(() => {
      const trimmedSearchInput = searchState.searchQuery.trim();
      dispatch(
        searchAccounts({
          searchTerm: trimmedSearchInput,
          includeArchived: searchState.includeArchived,
          limit: accountsLimit,
        })
      );
    }, 750),
    [searchState.searchQuery, searchState.includeArchived]
  );

  useEffect(() => {
    debouncedSearch();
  }, [searchState.searchQuery, searchState.includeArchived]);

  return {
    accounts: filteredAccounts,
    loadMoreAccounts,
    onSearchStateChange,
    searchState,
  };
};
