import { ShopFeature } from '../../../app-types';
import { usePreviewCollections } from './usePreviewCollections';
import { PreviewFeature } from '../../../services';
import { PreviewScreenModel } from '../types';
import { usePreviewPLP } from './usePreviewPLP';
import { usePreviewTextSearch } from './usePreviewTextSearch';

export const usePreviewExperience: PreviewScreenModel = (
  shopId: number,
  experience: PreviewFeature | null
) => {
  const collectionsModel = usePreviewCollections(shopId);
  const browsePlpModel = usePreviewPLP(shopId);
  const textSearchModel = usePreviewTextSearch(shopId);

  switch (experience) {
    case ShopFeature.Collections:
      return collectionsModel;
    case ShopFeature.BrowsePLP:
      return browsePlpModel;
    case ShopFeature.AugmentedSearch:
      return textSearchModel;
    default:
      return {} as any;
  }
};
