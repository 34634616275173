import React, { memo, useCallback, useEffect, useState } from 'react';
import { css, Global, useTheme } from '@emotion/react';
import { AutoCompleteOption, AutoCompleteSingleValue, PopUp } from 'src/components-dummy';
import { Option } from 'src/components-bl/VisualEditor/components/AutoComplete';
import { debounce } from 'lodash';
import { SkuAutoSuggestionProps } from './types';
import { InputStartAdornment } from './InputStartAdornment';
import {
  AutoCompleteStyled,
  PopUpStyled,
  SkuAutoSuggestionStyled,
} from './SkuAutoSuggestion.styles';
import { useFetchProducts } from './useFetchProducts';
import { ProductCard, SkuConfiguration } from 'src/components-bl/ProductCard/ProductCard';

export function getSkuConfigurationFromOptions({
  sku,
  options,
  uniqueByField = 'sku',
}: {
  sku: string;
  options: AutoCompleteOption<string, SkuConfiguration>[];
  uniqueByField: keyof SkuConfiguration;
}): SkuConfiguration | undefined {
  return options.find(({ data }) => data?.[uniqueByField] === sku)?.data as
    | SkuConfiguration
    | undefined;
}

export const SkuAutoSuggestion = memo(
  ({
    shopId,
    sku = '',
    dispatch,
    onChange,
    error,
    skuConfiguration: externalSkuConfiguration,
  }: SkuAutoSuggestionProps): JSX.Element => {
    const { options, loading, uniqueByField, resetOptions, fetchDataFieldOptionsDebounced } =
      useFetchProducts({ shopId, dispatch });

    const isLoadingSkuConfiguration = loading || externalSkuConfiguration === undefined;

    const [textBoxValue, setTextBoxValue] = useState(sku);
    const [shouldShowPopup, setShouldShowPopup] = useState(false);

    const theme = useTheme();

    const [selectedSkuConfiguration, setSelectedSkuConfiguration] = useState<
      SkuConfiguration | undefined
    >(undefined);

    const [skuExists, setSkuExists] = useState<boolean | undefined>(undefined);

    const onStateUpdateDebounced = useCallback(
      debounce((newSku: string) => {
        onChange(newSku);
      }, 500),
      [onChange]
    );

    const onSkuSelect = useCallback(
      (newSku: string) => {
        if (!uniqueByField) {
          return;
        }

        const configuration = getSkuConfigurationFromOptions({
          options,
          sku: newSku,
          uniqueByField,
        });

        const newSkuExists = !!configuration;

        if (configuration) {
          setSelectedSkuConfiguration(configuration);
        }

        setSkuExists(newSkuExists);
        onStateUpdateDebounced(newSku);
        setTextBoxValue(newSku);
      },
      [options, setSelectedSkuConfiguration, onStateUpdateDebounced, setTextBoxValue, uniqueByField]
    );

    const onTextBoxChange = useCallback(
      (input: string) => {
        setTextBoxValue(input);
        setSelectedSkuConfiguration(undefined);
        setSkuExists(false);

        const searchValueTrimmed = input.trim();

        onStateUpdateDebounced(input);

        resetOptions();

        fetchDataFieldOptionsDebounced({
          searchTerm: searchValueTrimmed,
        });
      },
      [fetchDataFieldOptionsDebounced, onStateUpdateDebounced, resetOptions]
    );

    useEffect(() => {
      if (!sku) {
        setSelectedSkuConfiguration(undefined);
      }

      if (
        selectedSkuConfiguration &&
        uniqueByField &&
        selectedSkuConfiguration[uniqueByField] !== sku
      ) {
        setSelectedSkuConfiguration(undefined);
      }

      if (sku !== textBoxValue) {
        setTextBoxValue(sku);
      }
    }, [sku]);

    useEffect(() => {
      if (externalSkuConfiguration === undefined) {
        return;
      }

      setSkuExists(!!externalSkuConfiguration);

      if (externalSkuConfiguration) {
        setSelectedSkuConfiguration(externalSkuConfiguration);
      }
    }, [externalSkuConfiguration]);

    const autoCompleteError = error || skuExists === false;

    const autoCompleteComponent = (
      <AutoCompleteStyled
        closeOnSingleMatch
        placeholder='Type here...'
        selectedValue={textBoxValue}
        onChange={onTextBoxChange}
        onSelectOption={onSkuSelect as any}
        options={options}
        freeSolo={false}
        loading={isLoadingSkuConfiguration}
        filterOptions={autoCompleteOptions => autoCompleteOptions}
        errored={autoCompleteError}
      >
        <AutoCompleteSingleValue.InputStartAdornment>
          <InputStartAdornment
            imageUrl={selectedSkuConfiguration?.imageUrl}
            loading={isLoadingSkuConfiguration}
            error={autoCompleteError}
          />
        </AutoCompleteSingleValue.InputStartAdornment>
        <AutoCompleteSingleValue.Option>{Option}</AutoCompleteSingleValue.Option>
      </AutoCompleteStyled>
    );

    return (
      <SkuAutoSuggestionStyled>
        <Global
          styles={css`
            .syte-merchandising-rule-sku-autosuggestion::before {
              background-color: ${theme.palette.custom['gray-90']} !important;
              box-shadow: unset !important;
              visibility: visible !important;
            }
          `}
        />
        <PopUpStyled
          show={shouldShowPopup}
          className='syte-merchandising-rule-sku-autosuggestion'
          onShow={setShouldShowPopup}
          closeOnTriggerClick={false}
          hideOnScroll={false}
          triggerEvent='hover'
          position='bottom left'
          contentClickPreventDefault={false}
          hoverable
        >
          <PopUp.Trigger>{autoCompleteComponent}</PopUp.Trigger>

          <PopUp.Content>
            <ProductCard
              loading={isLoadingSkuConfiguration}
              skuConfiguration={selectedSkuConfiguration}
              isApplied
              isAIDetected={false}
              enableDelete={false}
              isError={!skuExists}
            />
          </PopUp.Content>
        </PopUpStyled>
      </SkuAutoSuggestionStyled>
    );
  }
);
