import React, { ReactNode } from 'react';
import { Pathname } from 'history';
import { matchPath } from 'react-router';
import { AppHeaderComponentName, appHeaderComponentRoutes } from '../constants';

export interface AppHeaderItemProps {
  children: ReactNode;
  componentName: AppHeaderComponentName;
  currentUrl: Pathname;
}

function shouldRender(componentName: AppHeaderComponentName, currentUrl: Pathname): boolean {
  const componentUrls = appHeaderComponentRoutes[componentName];
  const match = matchPath(currentUrl, {
    path: componentUrls,
  });
  return !!match;
}

export const AppHeaderItem = ({
  currentUrl,
  componentName,
  children,
}: AppHeaderItemProps): JSX.Element => {
  return shouldRender(componentName, currentUrl) ? <>{children}</> : <></>;
};
